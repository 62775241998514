import {GETALL_PAYSCHEMES_LIST, GETALL_PAYSCHEMES_LIST_SUCCESSFULL} from './actionType'

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    paySchemeList: [],
  };

  const PaySchemeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GETALL_PAYSCHEMES_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case GETALL_PAYSCHEMES_LIST_SUCCESSFULL:
            state = {
              ...state,
              paySchemeList: action.payload.response,
              loading: true,
            };
            break;
          default:
            state = { ...state };
            break;
            

    }
    return state;
  }

  export default PaySchemeReducer;