import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextFieldNormal from "../../components/TextField/TextFieldNormal";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DynamicSelect from "../../components/Dropdown/Master/DynamicSelectMasters";
import {
  getBranchList,
  getStatusList,
} from "../../store/Global/DropDownApis/actions";
import { serverApi } from "../../helpers/Consts";
import {
  getCustomerList,
  getProjectList,
} from "../../store/StoreModule/MaterialRequest/actions";
import { getIndentListMaster } from "../../store/Master/ItemMaster/actions";
import moment from "moment";

class StoreModuleFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      Status: "",
      branchListData: [], // array for branch list
      expenseTypeList: [], // array for expense type list
      projectList: [], // array for project list
      branchId: "",
      issueNo: "",
      customerId: "",
      fromDate: moment().day(-7).format("YYYY-MM-DD").toString(),
      toDate: moment().format("YYYY-MM-DD").toString(),
      projectId: "",
      expenseTypeId: "",
      statusId: "",
      statusList: [], // array for status list
    };
  }

  componentDidMount() {
    this.props.getBranchList(
      serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
      this.props.history
    );

    this.props.getIndentListMaster(
      serverApi.INDENT_TYPE_LIST_MASTERS,
      this.props.history
    );

    // calling API for status List
    this.props.getStatusList(serverApi.GET_STATUS_LIST, this.props.history);
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  // function use to get all the payload and send the data to the props for filter...
  handleSubmit = () => {
    var data = {
      branchId: this.state.branchId,
      companyId: localStorage.getItem("companyId"),
      customerId: this.state.customerId,
      erpType: "2",
      exeType: this.state.expenseTypeId,
      finnacialYear: localStorage.getItem("acadamicYear"),
      fromDate: this.state.fromDate,
      hdrId: this.state.issueNo,
      projectId: this.state.projectId,
      status: this.state.statusId,
      toDate: this.state.toDate,
      startIndex: 1,
      lastIndex: 10,
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleClear = () => {
    this.setState({
      department: "",
      mDeptCode: "",
    });
  };

  handleChange = (key) => (event) => {
    if (key === "issueNo") {
      this.setState({
        issueNo: event,
      });
    }
  };

  componentWillReceiveProps = (props) => {
    if (props.branchList) {
      if (props.branchList.data) {
        this.setState({
          branchListData: props.branchList.data,
        });
      }
    }
    if (props.customerList) {
      if (props.customerList.data) {
        this.setState({
          customerList: props.customerList.data,
        });
      }
    }

    if (props.projectList) {
      if (props.projectList.data) {
        if (props.projectList.data.length > 0) {
          this.setState({
            projectList: props.projectList.data,
          });
        }
      }
    }

    if (props.Indent_List_Master) {
      if (props.Indent_List_Master.data) {
        if (props.Indent_List_Master.data.length > 0) {
          this.setState({
            expenseTypeList: props.Indent_List_Master.data,
          });
        }
      }
    }

    if (props.statusList) {
      if (props.statusList.data) {
        if (props.statusList.data.length > 0) {
          this.setState({
            statusList: props.statusList.data,
          });
        }
      }
    }
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "branch") {
      this.props.getCustomerList(
        serverApi.CUSTOMER_LIST +
          localStorage.getItem("companyId") +
          "/" +
          selectedValue
      );
      this.props.getProjectList(
        serverApi.PROJECT_LIST +
          localStorage.getItem("companyId") +
          "/customer/0/branch/" +
          selectedValue
      );
      this.setState({
        branchId: selectedValue,
      });
    }
    if (name === "customer") {
      this.setState({
        customerId: selectedValue,
      });
    }
    if (name === "expenseType") {
      this.setState({
        expenseTypeId: selectedValue,
      });
    }
    if (name === "project") {
      this.setState({
        projectId: selectedValue,
      });
    }
    if (name === "status") {
      this.setState({
        statusId: selectedValue,
      });
    }
  };

  handleSelectDate = (e, name) => {
    if (name === "FromDate") {
      let date = moment(e).format();
      let formattedFromDate = date.split("T", 1).toString();

      this.setState({
        fromDate: formattedFromDate,
      });
    }

    if (name === "ToDate") {
      let date = moment(e).format();
      let formattedToDate = date.split("T", 1).toString();

      this.setState({
        toDate: formattedToDate,
      });
    }
  };

  render() {
    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid
            spacing={0}
          >
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
              </div>
            </div>
            <Grid item xs={12} className="filterBlockContainer">
              <Box gridColumn="span 3">
                <div className="filterDateBlock">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>From Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.fromDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "FromDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>To Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.toDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "ToDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="toDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <TextFieldNormal
                    label="Issue No."
                    value={this.state.issueNo}
                    placeholder={"Enter here"}
                    onChange={this.handleChange("issueNo")}
                  />
                </div>
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={this.state.branchListData}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handleSelectChange}
                    selected={this.state.branchId}
                    update={this.state.branchId ? 1 : 0}
                    name="branch"
                    label="Branch"
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={
                      this.state.branchId !== "" ? this.state.customerList : []
                    }
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handleSelectChange}
                    selected={this.state.customerId}
                    update={this.state.customerId ? 1 : 0}
                    name="customer"
                    label="Customer Name"
                  />
                </div>
              </Box>

              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={
                      this.state.branchId !== "" ? this.state.projectList : []
                    }
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handleSelectChange}
                    selected={this.state.projectId}
                    update={this.state.projectId ? 1 : 0}
                    name="project"
                    label="project"
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={this.state.statusList}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handleSelectChange}
                    selected={this.state.statusId}
                    update={this.state.statusId ? 1 : 0}
                    name="status"
                    label="Status"
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={this.state.expenseTypeList}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handleSelectChange}
                    selected={this.state.expenseTypeId}
                    update={this.state.expenseTypeId ? 1 : 0}
                    name="expenseType"
                    label="Expense Type"
                  />
                </div>
              </Box>

              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Search"}
                  className="greenButton"
                  handleClick={this.handleSubmit}
                  type="sumbit"
                />
              </div>
            </Grid>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  const { branchList, statusList } = state.DropDownListReducer;
  const { customerList, projectList } = state.Material_Request_List;
  const { Indent_List_Master } = state.ItemListReducer;

  return {
    branchList,
    customerList,
    Indent_List_Master,
    projectList,
    statusList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getBranchList,
    getCustomerList,
    getStatusList,
    getProjectList,
    getIndentListMaster,
  })(StoreModuleFilter)
);
