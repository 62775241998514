import {TRAVEL_CATAGERIES_LIST,TRAVEL_CATAGERIES_LIST_SUCCESSFULL,CREATE_TRAVEL_CATAGERIES_SUCCESSFULL,CREATE_TRAVEL_CATAGERIES,
    UPDATE_TRAVEL_CATAGERIES,UPDATE_TRAVEL_CATAGERIES_SUCCESSFULL,CREATE_NEW_CUSTOMER_MASTER,CREATE_NEW_CUSTOMER_MASTER_SUCCESSFULL,
    API_ERRORS,} from './actionTypes';

export const travelCatageriesList = (url,data,history) =>{
    return {
        type:TRAVEL_CATAGERIES_LIST,
        payload:{url,data,history}
    }
}
export const travelCatageriesListSuccessfull = (data)=>{
    return {
        type:TRAVEL_CATAGERIES_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createTravelCatageries = (url, data, history) => {
    return {
        type: CREATE_TRAVEL_CATAGERIES,
        payload: { url, data, history }
    }
}

export const createTravelCatageriesSuccessfull = (data) => {
    return {
        type: CREATE_TRAVEL_CATAGERIES_SUCCESSFULL,
        payload: data
    }
}
export const updateTravelCatageries = (url, data, history) => {
    return {
        type: UPDATE_TRAVEL_CATAGERIES,
        payload: { url, data, history }
    }
}

export const updateTravelCatageriesSuccessfull = (data) => {
    return {
        type: UPDATE_TRAVEL_CATAGERIES_SUCCESSFULL,
        payload: data
    }
}
// CUSTOMER MASTER
export const createCustomerMaster = (url,data,history) =>{
    return {
        type:CREATE_NEW_CUSTOMER_MASTER,
        payload:{url,data,history}
    }
}

export const CreateCustomerMasterSuccessfull = (data)=>{
    return {
        type:CREATE_NEW_CUSTOMER_MASTER_SUCCESSFULL,
        payload:data
    }
}

export const apiErrors = (error) => {
    return {
        type: API_ERRORS,
        payload: error,
    };
};