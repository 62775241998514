import React, { Component } from "react";
import { Grid } from "@mui/material";
import Link from "@mui/material/Link";
import CustomButton from "../../../components/Buttons/Buttons";
import FillDetails from "../../Master/PackageMaster/FillDetails";
import AddItems from "../PackageMaster/AddItems";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class CreatePackageMaster extends Component {
  constructor() {
    super();
    this.state = {
      currentStep: 1,
    };
  }

  onClickNext = () => {
    const { currentStep } = this.state;
    this.setState({
      currentStep: currentStep + 1,
    });
  };

  onClickBefore = () => {
    const { currentStep } = this.state;
    if (currentStep > 1) {
      this.setState({
        currentStep: currentStep - 1,
      });
    } else {
      this.props.history.push("/packagemaster");
    }
  };
  handleSelectChange = (selectedValue, selectedName, name) => {};
  render() {
    const { currentStep } = this.state;
    return (
      <div>
        <Grid className="packageMasterContainer">
          <Grid>
            {currentStep === 1 && (
              <>
                <FillDetails onSelectDep={this.handleSelectChange} />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="greenButton"
                    handleClick={this.onClickNext}
                    type="sumbit"
                  />
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                <AddItems />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={"Save"}
                    className="greenButton"
                    type="sumbit"
                  />
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {};

export default withRouter(connect(mapStatetoProps, {})(CreatePackageMaster));
