import {
  SUPPLIER_MASTER_LIST,
  UPDATE_SUPPLIER_MASTER,
  SUPPLIER_MASTER_VIEW_BY_ID,
  SUPPLIER_MASTER_LIST_SUCCESSFULL,
  UPDATE_SUPPLIER_MASTER_SUCCESSFULL,
  SUPPLIER_MASTER_VIEW_BY_ID_SUCCESSFULL,
  API_ERRORS,
  SUPPLIER_LINE_ITEMS,
  CREATE_SUPPLIER_MASTER,
  CREATE_SUPPLIER_MASTER_SUCCESSFULL,
  GET_ALL_FILES,
  GET_ALL_FILES_SUCCESSFULL,
  DELETE_SUPPLIER_FILES,
  DELETE_SUPPLIER_FILES_SUCCESSFULL,
  UPLOAD_SUPPLIER_FILES,
  UPLOAD_SUPPLIER_FILES_SUCCESSFULL,
  SUPPLIER_FILLDETAILS,
  SUPPLIER_TYPES_LIST,
  SUPPLIER_TYPES_LIST_SUCCESSFULL,
  CLEAR_ALL_FILES,
  GET_ALL_ENTITY_TYPES_SUCCESSFULL,
  GET_ALL_ENTITY_TYPES,
  SUPPLIER_GST_VERIFICATION,
  SUPPLIER_GST_VERIFICATION_SUCCESSFULL,
} from "./actionTypes";

export const getSupplierMasterList = (url, data, history) => {
  return {
    type: SUPPLIER_MASTER_LIST,
    payload: { url, data, history },
  };
};
export const SupplierMasterListSuccessfull = (data) => {
  return {
    type: SUPPLIER_MASTER_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const SupplierLineItemsProps = (data) => {
  return {
    type: SUPPLIER_LINE_ITEMS,
    payload: data,
  };
};
export const SupplierFilldetailsProps = (data) => {
  return {
    type: SUPPLIER_FILLDETAILS,
    payload: data,
  };
};
export const supplierMasterViewById = (url, data, history) => {
  return {
    type: SUPPLIER_MASTER_VIEW_BY_ID,
    payload: { url, data, history },
  };
};
export const SupplierMasterViewByIdSuccessfull = (data) => {
  return {
    type: SUPPLIER_MASTER_VIEW_BY_ID_SUCCESSFULL,
    payload: data,
  };
};
// supplier Master
export const createSupplierMaster = (url, data, history) => {
  return {
    type: CREATE_SUPPLIER_MASTER,
    payload: { url, data, history },
  };
};
export const CreateSupplierMasterSuccessfull = (data) => {
  return {
    type: CREATE_SUPPLIER_MASTER_SUCCESSFULL,
    payload: data,
  };
};
export const updateSupplierMaster = (url, data, history) => {
  return {
    type: UPDATE_SUPPLIER_MASTER,
    payload: { url, data, history },
  };
};
export const UpdateSupplierMasterSuccessfull = (data) => {
  return {
    type: UPDATE_SUPPLIER_MASTER_SUCCESSFULL,
    payload: data,
  };
};

export const getSuppFilesData = (url, history) => {
  return {
    type: GET_ALL_FILES,
    payload: { url, history },
  };
};

export const getSuppFilesDataSuccessfull = (data) => {
  return {
    type: GET_ALL_FILES_SUCCESSFULL,
    payload: data,
  };
};
export const getSuppFilesClear = (data) => {
  return {
    type: CLEAR_ALL_FILES,
    payload: data,
  };
};
export const deleteSupplierMasterFiles = (url, suppId, history) => {
  return {
    type: DELETE_SUPPLIER_FILES,
    payload: { url, suppId, history },
  };
};

export const deleteSupplierMasterFilesSuccessfull = (data) => {
  return {
    type: DELETE_SUPPLIER_FILES_SUCCESSFULL,
    payload: data,
  };
};
export const UploadSupplierMasterFiles = (url, data, suppId, history) => {
  return {
    type: UPLOAD_SUPPLIER_FILES,
    payload: { url, data, suppId, history },
  };
};

export const UploadSupplierMasterFilesSuccessfull = (data) => {
  return {
    type: UPLOAD_SUPPLIER_FILES_SUCCESSFULL,
    payload: data,
  };
};

export const supplierTypesList = (url, data, history) => {
  return {
    type: SUPPLIER_TYPES_LIST,
    payload: { url, data, history },
  };
};

export const supplierTypesListSuccessfull = (data) => {
  return {
    type: SUPPLIER_TYPES_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getAllEntityTypes = (url, history) => {
  return {
    type: GET_ALL_ENTITY_TYPES,
    payload: { url, history },
  };
};

export const getAllEntityTypesSuccessfull = (data) => {
  return {
    type: GET_ALL_ENTITY_TYPES_SUCCESSFULL,
    payload: data,
  };
};

export const getSupplierGstVerification = (url, id, history) => {
  return {
    type: SUPPLIER_GST_VERIFICATION,
    payload: { url, id, history },
  };
};

export const gstSupplierVerificationSuccessfull = (data) => {
  return {
    type: SUPPLIER_GST_VERIFICATION_SUCCESSFULL,
    payload: data,
  };
};
export const apiErrors = (error) => {
  return {
    type: API_ERRORS,
    payload: error,
  };
};
