import React, { useState, useEffect } from "react";
import {
  Grid,
  Step,
  Stepper,
  StepLabel,
  Box,
  Link as MuiLink,
} from "@mui/material";
import Link from "@mui/material/Link";
import CustomButton from "../../../components/Buttons/Buttons";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import cons, { serverApi } from "../../../helpers/Consts";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { getBankAccountList } from "../../../store/Sales/DeliveryOrder/actions";
import {
  getSoDropDown,
  DeliveryOrderJuteHessianHeaders,
  getAllTransporter,
} from "../../../store/Sales/DeliveryOrder/actions";
import {
  SalesOrderViewById,
  SalesOrderViewByIdForJuteHessian,
} from "../../../store/Sales/DeliveryOrder/actions";
import {
  getBranchList,
  getCustomerList,
  getBillingShippingList,
} from "../../../store/Global/DropDownApis/actions";
import { getQuotationBroker } from "../../../store/Sales/Qutation/actions";
import FormPopup from "../../../components/FormlPopup/FormPopup";
import { isEmpty } from "../../../Utilities/helper";

import moment from "moment";
import swal from "sweetalert";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import { SalesGstCalculations } from "../../../Utilities/SalesGstCalculation";
const JuteHessianFillDetails = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [LineItems, setLineItems] = useState([]);
  const [HeaderData, setHeaderData] = useState({});
  const [invoiceData, setinvoiceData] = useState(props.invoiceData);
  const [SoDropdownVal, setSoDropdownVal] = useState([]);
  const [branchListData, setbranchListData] = useState([]);
  const [bankListData,setbankListData]=useState([]);
  const [brokerData, setbrokerData] = useState([]);
  const [CustomerData, setCustomerData] = useState([]);
  const [billingAddressData, setbillingAddressData] = useState([]);
  const [shippingAddressData, setshippingAddressData] = useState([]);
  const [transporterData, settransporterData] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [backDaysAllowable, setBackDaysAllowable] = useState("");
  const [futureDaysAllowable, setFutureDaysAllowable] = useState("");
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));

  const { SrDropDownData, TransporterList, DoJuteHessianHeaders, bankAccountList,DoJuteHessianLineItems } = useSelector(
    (state) => state.DeliveryOrderReducer
  );
  const { BrokerData } = useSelector((state) => state.QuotationReducer);

  useEffect(() => {
    setLineItems(DoJuteHessianLineItems)
    setHeaderData(DoJuteHessianHeaders);
  }, [DoJuteHessianHeaders]);
  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };
  const handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "deliveryTerms") {

      setHeaderData((prev) => ({
        ...prev,
        deliveryTerms: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        deliveryTerms: selectedName,
      };
      dispatch(DeliveryOrderJuteHessianHeaders(UpdatedRec));
        }
    if (name == "salesOrderId") {
      setHeaderData((prev) => ({
        ...prev,
        salesOrderId: selectedValue,
        salesOrderNumber: selectedName,
        transporter: "",
        transporterName: "",
        transporterAddress: "",
        transporterStateCode: "",
        transporterStateName: "",
        transporterGst: "",
      }));
      const UpdatedRec = {
        ...HeaderData,
        salesOrderId: selectedValue,
        salesOrderNumber: selectedName,
        transporter: "",
        transporterName: "",
        transporterAddress: "",
        transporterStateCode: "",
        transporterStateName: "",
        transporterGst: "",
      };
      dispatch(DeliveryOrderJuteHessianHeaders(UpdatedRec));
      dispatch(
        SalesOrderViewByIdForJuteHessian(
          serverApi.SALES_ORDER_VIEW_BYID +
            "4/" +
            selectedValue +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).userId,
          history
        )
      );
    }
    if (name == "invoiceType") {
      setHeaderData((prev) => ({
        ...prev,
        invoiceType: selectedValue,
      }));
      const UpdatedRec = {
        ...HeaderData,
        invoiceType: selectedValue,
      };
      dispatch(DeliveryOrderJuteHessianHeaders(UpdatedRec));
    }
    if (name == "branch") {
      setHeaderData((prev) => ({
        ...prev,
        branch: selectedValue,
        branchName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        branch: selectedValue,
        branchName: selectedName,
      };
      dispatch(DeliveryOrderJuteHessianHeaders(UpdatedRec));
    }
    if (name === "bankId") {
      var Data = selectedName.split("^", 5);
      setHeaderData((prev) => ({
        ...prev,
        bankId: selectedValue,
        bankName: Data[0],
        accountNo:selectedValue ===0?"": Data[1],
        ifscCode:selectedValue ===0?"": Data[2],
        micrCode:selectedValue ===0?"": Data[3],
        bankBranch: selectedValue ===0?"": Data[4],
      }));
      const UpdatedRec = {
        ...HeaderData,
        bankId: selectedValue,
        bankName: Data[0],
        accountNo: Data[1],
        ifscCode:Data[2],
        micrCode: Data[3],
        bankBranch: Data[4],
      };
      dispatch(DeliveryOrderJuteHessianHeaders(UpdatedRec));
    }
    if (name == "customerId") {
      setHeaderData((prev) => ({
        ...prev,
        customerId: selectedValue,
        customerName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        customerId: selectedValue,
        customerName: selectedName,
      };

      dispatch(DeliveryOrderJuteHessianHeaders(UpdatedRec));
      let TaxPer = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: 2,
        },
        "DO_HESSIAN"
      );
      handleCalculationCustomer(TaxPer,selectedValue,selectedName);
      dispatch(
        getBillingShippingList(
          serverApi.GET_ALL_BILLING_SHIPPING +
            selectedValue +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).cipher+
            "?isForSalesModule=1",
          history
        )
      );
    }
    if (name == "brokerId") {
      setHeaderData((prev) => ({
        ...prev,
        brokerId: selectedValue,
        brokerName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        brokerId: selectedValue,
      };
      dispatch(DeliveryOrderJuteHessianHeaders(UpdatedRec));
    }
    if (name === "billingTo") {
      
      if (billingShippingList.data.customerLineitems) {
        billingShippingList.data.customerLineitems.map((row) => {
          if(selectedValue === row.customerLineId){
            setHeaderData((prev) => ({
              ...prev,
              billingTo: selectedValue,
              billingToAddress: selectedName,
              billingState : row.state,
              billingToGST: row.gstNo,
              billingContactNo: row.contactNo,
              billingContactPerson: row.contactPerson,
              billingToEmail:billingShippingList.data.customerHdr.emailId,
                
            }));
            const UpdatedRec = {
              ...HeaderData,
              billingTo: selectedValue,
              billingToAddress: selectedName,
              billingState : row.state,
              billingToGST: row.gstNo,
              billingContactNo: row.contactNo,
              billingContactPerson: row.contactPerson,
              billingToEmail:billingShippingList.data.customerHdr.emailId,
            };
            dispatch(DeliveryOrderJuteHessianHeaders(UpdatedRec));
          }
        })
      }
    }
    if (name === "shippingTo") {
      if (billingShippingList.data.customerLineitems) {
        billingShippingList.data.customerLineitems.map((row) => {
          if(selectedValue === row.customerLineId){
            setHeaderData((prev) => ({
              ...prev,
              shippingTo: selectedValue,
              shippingToAddress: selectedName,
              shippingState : row.state,
              shippingToGST: row.gstNo,
              shippingContactNo: row.contactNo,
              shippingContactPerson: row.contactPerson,
              shippingToEmail:billingShippingList.data.customerHdr.emailId,
                       
            }));
            const UpdatedRec = {
              ...HeaderData,
              shippingTo: selectedValue,
              shippingToAddress: selectedName,
              shippingState : row.state,
              shippingToGST: row.gstNo,
              shippingContactNo: row.contactNo,
              shippingContactPerson: row.contactPerson,
              shippingToEmail:billingShippingList.data.customerHdr.emailId,
            };
            dispatch(DeliveryOrderJuteHessianHeaders(UpdatedRec));
          }
        })
      }
    }

    if (name == "taxType") {
      setHeaderData((prev) => ({
        ...prev,
        taxType: selectedValue,
      }));
      const UpdatedRec = {
        ...HeaderData,
        taxType: selectedValue,
      };
      dispatch(DeliveryOrderJuteHessianHeaders(UpdatedRec));
    }
    if ((name === "transporter")) {
      var data = selectedName.split("^");
      setHeaderData((prev) => ({
        ...prev,
        transporter: selectedValue,
        transporterName:data[0],
        transporterAddress: data[1],
        transporterStateCode: data[3],
        transporterStateName: data[2],
        transporterGst: data[4],
      }));
      const UpdatedRec = {
        ...HeaderData,
        transporter: selectedValue,
        transporterName:data[0],
        transporterAddress: data[1],
        transporterStateCode: data[3],
        transporterStateName: data[2],
        transporterGst: data[4],
      };
      dispatch(DeliveryOrderJuteHessianHeaders(UpdatedRec));
    }
  };
  const { branchList, customerList, billingShippingList } = useSelector(
    (state) => state.DropDownListReducer
  );

  const handleCalculationCustomer = (Calculations,selectedValue,selectedName) => {
    setLineItems(Calculations.lineItems);
     dispatch(DeliveryOrderJuteHessianHeaders(Calculations.lineItems));
 
     let Headerdata = {
       ...DoJuteHessianHeaders,
       totalIGST: Calculations.totalIGST,
       totalCGST: Calculations.totalCGST,
       totalSGST: Calculations.totalSGST,
       taxTotal: Calculations.taxTotal,
       TotalAmount: Calculations.TotalAmount,
       netTotal: Calculations.netTotal,
       subTotal: Calculations.subTotal,
       customerId: selectedValue,
       customerName: selectedName,
     };
     setbillingAddressData([])
     setshippingAddressData([])
     setHeaderData((prev) => ({
       ...prev,
       totalIGST: Calculations.totalIGST,
       totalCGST: Calculations.totalCGST,
       totalSGST: Calculations.totalSGST,
       taxTotal: Calculations.taxTotal,
       TotalAmount: Calculations.TotalAmount,
       netTotal: Calculations.netTotal,
       subTotal: Calculations.subTotal,
       customerId: selectedValue,
       customerName: selectedName,
 
     }));
     dispatch(DeliveryOrderJuteHessianHeaders(Headerdata));
   };

  useEffect(() => {
    const UpdatedRec = {
      ...DoJuteHessianHeaders,
      invoiceType: props.invoiceType,
    };
    setHeaderData(UpdatedRec);
  }, [DoJuteHessianHeaders]);

  const handleSelect_Date = (e, name) => {
    if (name === "deliveryDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      if(Date<HeaderData.salesOrderDate){
        swal('Delivery Order Date Should be greater than Sales Order Date')
        setHeaderData((prev) => ({
          ...prev,
          deliveryDate: null,
        }));
        const UpdatedRec = {
          ...HeaderData,
          deliveryDate: null,
        };
        dispatch(DeliveryOrderJuteHessianHeaders(UpdatedRec));
      }else{
        setHeaderData((prev) => ({
          ...prev,
          deliveryDate: Date,
        }));
        const UpdatedRec = {
          ...HeaderData,
          deliveryDate: Date,
        };
        dispatch(DeliveryOrderJuteHessianHeaders(UpdatedRec));
      }
    
    }
  };
  const onhandlechange = (key) => (event) => {
    if (key === "quotationNumber") {
      setHeaderData((prev) => ({
        ...prev,
        quotationNumber: event,
      }));
      const UpdatedRec = {
        ...DoJuteHessianHeaders,
        quotationNumber: event,
      };
      dispatch(DeliveryOrderJuteHessianHeaders(UpdatedRec));
    }
    if (key === "transporter") {
      setHeaderData((prev) => ({
        ...prev,
        transporter: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        transporter: event,
      };
      dispatch(DeliveryOrderJuteHessianHeaders(UpdatedRec));
    }
    if (key === "vehicleNo") {
      setHeaderData((prev) => ({
        ...prev,
        vehicleNo: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        vehicleNo: event,
      };
      dispatch(DeliveryOrderJuteHessianHeaders(UpdatedRec));
    }
  };
  useEffect(() => {
    dispatch(
      getSoDropDown(
        serverApi.SALES_ORDER_DROPDOWN +
          props.invoiceType +
          "/" +
          localStorage.getItem("companyId")
      )
    );
    dispatch(
      getBranchList(
        serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
        history
      )
    );
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
    };
    dispatch(getCustomerList(serverApi.GET_ALL_CUSTOMERS, data, history));
    dispatch(getBankAccountList(serverApi.GET_ALL_BANK_ACCOUNT_LIST, data,history));
    dispatch(
      getQuotationBroker(
        serverApi.GET_ALL_BROKER_LIST +
          localStorage.getItem("companyId") +
          "/SALE",
        history
      )
    );
    dispatch(
      getAllTransporter(
        serverApi.GET_ALL_TRANSPORTER_MASTER_LIST +
          localStorage.getItem("companyId")
      )
    );
    const currentMonth = moment().format('MM');
    const currentDate = moment().format('DD');
    const fiscalYearStart = moment(`${localStorage.getItem("acadamicYear")}-${currentMonth}-${currentDate}`);
    const today = moment();
    let dateValue;
    if (today.isBefore(fiscalYearStart)) {
      dateValue = today.format("YYYY-MM-DD");
    } else {
      dateValue = fiscalYearStart.format("YYYY-MM-DD");
    }

    if (userDit) {
      userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              if (item.backDaysAllowable !== undefined) {
                let backDaysAllowable = JSON.parse(item.backDaysAllowable);
                let backDays = backDaysAllowable.bdaystype;
                if (Number(backDays) === 1) {
                  backDays = 7;
                } else if (Number(backDays) === 2) {
                  backDays = 14;
                } else if (Number(backDays) === 3) {
                  backDays = "";
                } else if (Number(backDays) === 4) {
                  backDays = backDaysAllowable.otherBackDateValue;
                } else {
                  backDays = backDaysAllowable.bdaystype;
                }
                let minDate = moment(dateValue).subtract(
                  Number(backDays),

                  "days"
                );
                let setMinDate = moment(minDate).format("YYYY-MM-DD");
                if(backDays !==""){
                setBackDaysAllowable(setMinDate);
                }
              }
              if (item.futureDaysAllowable !== undefined) {
                let futureDaysAllowable = JSON.parse(item.futureDaysAllowable);
                let futureDays = futureDaysAllowable.fdaystype;
                if (Number(futureDays) === 1) {
                  futureDays = 7;
                } else if (Number(futureDays) === 2) {
                  futureDays = 14;
                } else if (Number(futureDays) === 3) {
                  futureDays = "";
                } else if (Number(futureDays) === 4) {
                  futureDays = futureDaysAllowable.othervalue;
                } else {
                  futureDays = futureDaysAllowable.fdaystype;
                }
                let maxDate = moment(dateValue).add(
                  Number(futureDays),

                  "days"
                );
                let setMaxDate = moment(maxDate).format("YYYY-MM-DD");
                if(futureDays!==""){
                setFutureDaysAllowable(setMaxDate);
                }
              }
            });
            setHeaderData((prev) => ({
              ...prev,
              // deliveryDate: dateValue,
            }));
            const UpdatedRec = {
              ...DoJuteHessianHeaders,
              // deliveryDate: dateValue,
            };
            dispatch(DeliveryOrderJuteHessianHeaders(UpdatedRec));

          }
      });
    }
  }, []);
  useEffect(() => {
    if (SrDropDownData) {
      if (SrDropDownData.data) {
        let List = [];
        SrDropDownData.data.map((item) => {
          List.push({
            value: item.id,
            name: item.Value,
            label: item.Value,
          });
        });
        setSoDropdownVal(List);
      }
    }
    if (branchList) {
      if (branchList.data) {
        if (branchList.data.length !== 0) {
          let List = branchList.data.filter((item) => item.value !== 0);
          setbranchListData(List);
        }
      }
    }
    if (customerList) {
      if (customerList.data) {
        if (customerList.data.length !== 0) {
          var CusList = [];
          customerList.data.map((item) => {
            CusList.push({
              value: item.id,
              name: item.name,
              label: item.name,
            });
          });
          setCustomerData(CusList);
        }
      }
    }
    if (bankAccountList) {
      if (bankAccountList.data) {
        if (bankAccountList.data.length !== 0) {
          var BankList = [];
          // Commenting out this line because the selected option should not be shown in the dropdown
          // BankList.push(bankSelect)
          bankAccountList.data.data.map((item) => {
            BankList.push({
              value: item.id,
              name:
              item.bankName +
              "^" +
              item.accountNo +
              "^" +
              item.ifscCode +
              "^" +
              item.micrCode +
              "^" +
              item.branch,
              label: item.bankName,
            });
          });
          setbankListData(BankList);
        }
      }
    }
    if (BrokerData) {
      if (BrokerData.data) {
        setbrokerData(BrokerData.data);
      }
    }
    if (TransporterList) {
      if (TransporterList.data) {
        if (TransporterList.data.data) {
          let TransporterLists = TransporterList.data.data.map((item) => ({
          
            name:
              item.transporterName +
              "^" +
              item.transporterAddress +
              "^" +
              item.stateName +
              "^" +
              item.stateCode +
              "^" +
              item.transporterGst,
            label: item.transporterName,
            value: item.transporterId,
          }));
          settransporterData(TransporterLists);
        }
      }
    }
  }, [SrDropDownData, branchList, customerList, BrokerData, TransporterList]);
  const isEmptyObj = (myObject) => {
    const keys = Object.keys(myObject);
    return keys.length === 0;
  };
  useEffect(() => {
    if (billingShippingList) {
      if (billingShippingList.data) {
        if (billingShippingList.data.customerLineitems) {
          if (billingShippingList.data.customerLineitems.length !== 0) {
            var list = [];
            let billingAddressList = [];
            let shippingaddressList = [];
            let HessianHeaderObj=DoJuteHessianHeaders
            billingShippingList.data.customerLineitems.map((prop, i) => {
              if (prop.addressType === 2 || prop.addressType === null) {
                if (
                  (prop.customerLineId === DoJuteHessianHeaders.shippingTo &&
                    prop.isActive === 0) ||
                  prop.isActive !== 0
                ) {
                  billingAddressList.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
              list.push({
                value: prop.customerLineId,
                label: prop.address,
                name: prop.address,
              });
            }
          }
            if (prop.addressType === 1 || prop.addressType === null|| prop.addressType === 2) {
              if (
                (prop.customerLineId === DoJuteHessianHeaders.shippingTo &&
                  prop.isActive === 0) ||
                prop.isActive !== 0
              ) {
                shippingaddressList.push({
                  value: prop.customerLineId,
                  label: prop.address,
                  name: prop.address,
                });
                list.push({
                  value: prop.customerLineId,
                  label: prop.address,
                  name: prop.address,
                });
              }
            }
            });

            if(DoJuteHessianHeaders.customerResponse){
              billingShippingList.data.customerLineitems.map((prop, i) => {
                if (billingAddressList.length !== 0 &&  HeaderData.customerId !== "" &&
                HeaderData.customerId !== null) {
                  billingAddressList.map((billingdata, ind) => {
                    if (ind === 0) {
                      if (!isEmptyObj(HeaderData)) {
                        if (
                          HeaderData.customerId !== "" ||
                          HeaderData.customerId !== null
                        ) {
                          if (prop.customerLineId === billingdata.value) {
                            setHeaderData((prev) => ({
                              ...prev,
                              ContactNumber: billingShippingList.data.customerHdr.contactNo,
                              ContactPerson:
                                billingShippingList.data.customerHdr.contactPerson,
                              billingTo: prop.customerLineId,
                              billingToAddress: prop.address,
                              billingToGST: prop.gstNo,
                              billingContactNo: prop.contactNo,
                              billingContactPerson: prop.contactPerson,
                              billingToEmail: billingShippingList.data.customerHdr.emailId,
                              billingState : prop.state,
                              taxType: 2,
                            }));
                            const UpdatedRec = {
                              ...HessianHeaderObj,
                              ContactNumber: billingShippingList.data.customerHdr.contactNo,
                              ContactPerson:
                                billingShippingList.data.customerHdr.contactPerson,
                              billingTo: prop.customerLineId,
                              billingToAddress: prop.address,
                              billingToGST: prop.gstNo,
                              billingContactNo: prop.contactNo,
                              billingContactPerson: prop.contactPerson,
                              billingToEmail: billingShippingList.data.customerHdr.emailId,
                              billingState : prop.state,
                              taxType: 2,
                            };
                            HessianHeaderObj = UpdatedRec
                          }
                        }else{
                          setHeaderData((prev) => ({
                            ...prev,
                            ContactNumber: "",
                            ContactPerson: "",
                            billingTo: "",
                            billingToAddress: "",
                            billingToGST: "",
                            billingContactNo: "",
                            billingContactPerson: "",
                            billingToEmail: "",
                            billingState : "",
                            taxType: "",
                          }));
                          const UpdatedRec = {
                            ...HessianHeaderObj,
                            ContactNumber: "",
                            ContactPerson: "",
                            billingTo: "",
                            billingToAddress: "",
                            billingToGST: "",
                            billingContactNo: "",
                            billingContactPerson: "",
                            billingToEmail: "",
                            billingState : "",
                            taxType: "",
                          };
                          HessianHeaderObj = UpdatedRec
                        }
                      }
                    }
                  });
                } else {
                  setHeaderData((prev) => ({
                    ...prev,
                    ContactNumber: "",
                    ContactPerson: "",
                    billingTo: "",
                    billingToAddress: "",
                    billingToGST: "",
                    billingContactNo: "",
                    billingContactPerson: "",
                    billingToEmail: "",
                    billingState : "",
                    taxType: "",
                  }));
                  const UpdatedRec = {
                    ...HessianHeaderObj,
                    ContactNumber: "",
                    ContactPerson: "",
                    billingTo: "",
                    billingToAddress: "",
                    billingToGST: "",
                    billingContactNo: "",
                    billingContactPerson: "",
                    billingToEmail: "",
                    billingState : "",
                    taxType: "",
                  };
                  HessianHeaderObj = UpdatedRec
    
                }
              })
            }
            if(DoJuteHessianHeaders.customerResponse){
              billingShippingList.data.customerLineitems.map((prop, i) => {
                if (shippingaddressList.length !== 0 &&  HeaderData.customerId !== "" &&
                HeaderData.customerId !== null) {
                  shippingaddressList.map((shippingdata, ind) => {
                    if (ind === 0) {
                      if (!isEmptyObj(HeaderData)) {
                        if (
                          HeaderData.customerId !== "" ||
                          HeaderData.customerId !== null
                        ) {
                          if (prop.customerLineId === shippingdata.value) {
                            setHeaderData((prev) => ({
                              ...prev,
                              ContactNumber: billingShippingList.data.customerHdr.contactNo,
                              ContactPerson:
                                billingShippingList.data.customerHdr.contactPerson,
                              shippingTo: prop.customerLineId,
                              shippingToAddress: prop.address,
                              shippingToGST: prop.gstNo,
                              shippingContactNo: prop.contactNo,
                              shippingContactPerson: prop.contactPerson,
                              shippingToEmail: billingShippingList.data.customerHdr.emailId,
                              shippingState :prop.state,
                              taxType: 2,
                            }));
                            const UpdatedRec = {
                              ...HessianHeaderObj,
                              ContactNumber: billingShippingList.data.customerHdr.contactNo,
                              ContactPerson:
                                billingShippingList.data.customerHdr.contactPerson,
                              shippingTo: prop.customerLineId,
                              shippingToAddress: prop.address,
                              shippingToGST: prop.gstNo,
                              shippingContactNo: prop.contactNo,
                              shippingContactPerson: prop.contactPerson,
                              shippingToEmail: billingShippingList.data.customerHdr.emailId,
                              shippingState :prop.state,
                              taxType: 2,
                            };
                            HessianHeaderObj = UpdatedRec
                          }
                        }else{
                          setHeaderData((prev) => ({
                            ...prev,
                            ContactNumber: "",
                            ContactPerson: "",
                            shippingTo: "",
                            shippingToAddress: "",
                            shippingToGST: "",
                            shippingContactNo: "",
                            shippingContactPerson: "",
                            shippingToEmail: "",
                            shippingState :"",
                            taxType: "",
                          }));
                          const UpdatedRec = {
                            ...HessianHeaderObj,
                            ContactNumber: "",
                            ContactPerson: "",
                            shippingTo: "",
                            shippingToAddress: "",
                            shippingToGST: "",
                            shippingContactNo: "",
                            shippingContactPerson: "",
                            shippingToEmail: "",
                            shippingState :"",
                            taxType: "",
                          };
                          HessianHeaderObj = UpdatedRec
                        }
                      }
                    }
                  });
                }  else {
                  setHeaderData((prev) => ({
                    ...prev,
                    ContactNumber: "",
                    ContactPerson: "",
                    shippingTo: "",
                    shippingToAddress: "",
                    shippingToGST: "",
                    shippingContactNo: "",
                    shippingContactPerson: "",
                    shippingToEmail: "",
                    shippingState :"",
                    taxType: "",
                  }));
                  const UpdatedRec = {
                    ...HessianHeaderObj,
                    ContactNumber: "",
                    ContactPerson: "",
                    shippingTo: "",
                    shippingToAddress: "",
                    shippingToGST: "",
                    shippingContactNo: "",
                    shippingContactPerson: "",
                    shippingToEmail: "",
                    shippingState :"",
                    taxType: "",
                  };
                  HessianHeaderObj = UpdatedRec
                }
              });
            }

            if (!isEmpty(DoJuteHessianHeaders.customerId)) {
              dispatch(DeliveryOrderJuteHessianHeaders(HessianHeaderObj));
              setbillingAddressData(billingAddressList);
              setshippingAddressData(shippingaddressList);
            }
          }
        }
      }
    }
  }, [billingShippingList]);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <div>
        <div className="consoleFormContainer">
          <h5>Jute Hessian Fill Details </h5>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                      {props.locationState.state === undefined ? (
                        <DynamicSelect
                          update={HeaderData.salesOrderId ? 1 : 0}
                          arrayOfData={SoDropdownVal}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={handlePropSelectChange}
                          isDropdownOpen={isDropdownOpen}
                          handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.salesOrderId}
                        label="Sales Order"
                        name="salesOrderId"
                          required
                        />
                      ) : (
                        <TextFieldNormal
                          label="Sales Order"
                          value={HeaderData.salesOrderNumber}
                          onChange={onhandlechange("salesOrderNumber")}
                          disabled={true}
                        />
                      )}
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>Sales Order Date</label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            disabled={true}
                            name="salesOrderDate"
                            inputFormat="dd-MM-yyyy"
                            id="salesOrderDate"
                            value={HeaderData.salesOrderDate}
                            fullWidth
                            onChange={(e) =>
                              handleSelect_Date(e, "salesOrderDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                                style={{
                                  backgroundColor: "#ccd6db",
                                }}
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  style={{
                                    backgroundColor: "#ccd6db",
                                  }}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.branch ? 1 : 0}
                        arrayOfData={branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.branch}
                        label="Branch"
                        name="branch"
                        required
                        isDisabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Delivery Order Date{" "}
                            <span className="starcolor">*</span>
                          </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="deliveryDate"
                            inputFormat="dd-MM-yyyy"
                            id="deliveryDate"
                            minDate={
                              backDaysAllowable !== ""
                                ? backDaysAllowable
                                :HeaderData.deliveryDate
                            }
                            maxDate={
                              futureDaysAllowable !== ""
                                ? futureDaysAllowable
                                : HeaderData.deliveryDate
                            }
                            value={HeaderData.deliveryDate}
                            fullWidth
                            onChange={(e) =>
                              handleSelect_Date(e, "deliveryDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.brokerId ? 1 : 0}
                        arrayOfData={brokerData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.brokerId}
                        label="Broker"
                        name="brokerId"
                        // isDisabled={HeaderData.salesOrderId ==="" ? true :  HeaderData.disableBroker ===true ? true:false}
                        isDisabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.customerId ? 1 : 0}
                        arrayOfData={CustomerData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.customerId}
                        label="Customer/Consignee"
                        name="customerId"
                        required
                        isDisabled={HeaderData.salesOrderId ==="" ? true :  HeaderData.disableCustomer ===true ? true:false}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.billingTo ? 1 : 0}
                        arrayOfData={billingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.billingTo}
                        label="Billing To"
                        name="billingTo"
                        required
                        isDisabled={isEmpty(HeaderData.customerId)}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.shippingTo ? 1 : 0}
                        arrayOfData={shippingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.shippingTo}
                        label="Shipping To"
                        name="shippingTo"
                        required
                        isDisabled={isEmpty(HeaderData.customerId)}
                      />
                    </div>
                    {!isEmpty(HeaderData.customerId) && (

                    <span
                      onClick={openPopup}
                      style={{
                        color: "red",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 100,
                        fontSize: "15px",
                        marginTop: "5px",
                        cursor: "pointer",
                        padding: "5px",
                      }}>
                        Add a new Customer Branch Address
                    </span>
                    )}
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Billing State"
                      value={HeaderData.billingState}
                      onChange={onhandlechange("billingState")}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Shipping State"
                      value={HeaderData.shippingState}
                      onChange={onhandlechange("shippingState")}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.taxType ? 1 : 0}
                        arrayOfData={taxTypeData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.taxType}
                        label="Tax type"
                        name="taxType"
                        required
                        isDisabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.bankId ? 1 : 0}
                        arrayOfData={bankListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.bankId}
                        name="bankId"
                        label="Bank Name"
                        required
                     />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelectNew
                      update={HeaderData.deliveryTerms ? 1 : 0}
                      arrayOfData={DeliveryTermsData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.deliveryTerms}
                      name="deliveryTerms"
                      label="Delivery Terms"
                      isDisabled={true}
                      required={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Broker %"
                      value={HeaderData.brokerCommisionPercent}
                      onChange={onhandlechange("brokerCommisionPercent")}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelect
                      update={HeaderData.transporter ? 1 : 0}
                      arrayOfData={transporterData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.transporter}
                      label="Transporter Name"
                      name="transporter"
                      required
                      // isDisabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Transporter Address"
                      value={HeaderData.transporterAddress}
                      type="text"
                      disabled="true"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Transporter State Name"
                      value={HeaderData.transporterStateName}
                      type="text"
                      disabled="true"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Transporter State Code"
                      value={HeaderData.transporterStateCode}
                      type="text"
                      disabled="true"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Vehicle No"
                      value={HeaderData.vehicleNo}
                      onChange={onhandlechange("vehicleNo")}
                      caps='ON'
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <FormPopup isOpen={isPopupOpen} onClose={closePopup} customerData={billingShippingList} customerId={DoJuteHessianHeaders.customerId}/>
      </div>
    </>
  );
};
const taxTypeData = [
  {
    value: 1,
    name: "IGST",
    label: "IGST",
  },
  {
    value: 2,
    name: "CGST & SGST",
    label: "CGST & SGST",
  },
];
const DeliveryTermsData = [
  {
    value: 1,
    label: "Ex-Works",
    name: "Ex-Works",
  },
  {
    value: 2,
    label: "Delivery to Destination",
    name: "Delivery to Destination",
  },
];
const bankSelect = {
  value: "",
  name: "Select",
  label: "Select",
}
export default JuteHessianFillDetails;
