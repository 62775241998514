import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
// import DynamicSelect from "../Dropdown/DynamicSelect";
import DynamicSelect from "../../components/Dropdown/Master/DynamicSelectMasters";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DesktopDatePicker from "@mui/lab/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { serverApi } from "../../helpers/Consts";
import { getStatusList } from "../../store/Global/DropDownApis/actions";

class PayRegisterFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      Status: null,
      fromDate: "",
      toDate: "",
      StatusValue: "",
      statusoptions: [],
    };
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  handleSelectDate = (e, name) => {
    if (name === "FromDate") {
      let date = moment(e).format();
      let formattedFromDate = date.split("T", 1).toString();
      this.setState({
        fromDate: formattedFromDate,
      });
    }
    if (name === "ToDate") {
      let date = moment(e).format();
      let formattedToDate = date.split("T", 1).toString();
      this.setState({
        toDate: formattedToDate,
      });
    }
  };
  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "Status") {
      this.setState({
        StatusValue: selectedValue,
      });
    }
  };
  handleSubmit = () => {
    const data = {
      fromDate: this.state.fromDate,
      lastIndex: 10,
      startIndex: 1,
      status: this.state.StatusValue,
      toDate: this.state.toDate,
    };
    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };
  componentDidMount() {
    this.props.getStatusList(serverApi.GET_STATUS_LIST, this.props.history);
  }
  componentWillReceiveProps(props) {
    if (props.statusList) {
      this.setState({
        statusoptions: props.statusList.data,
      });
    }
  }
  render() {
    return (
      <>
        <Box>
          <Button onClick={this.handleClickOpen}>
            <Tooltip title="Filter">
              <FilterListOutlinedIcon />
            </Tooltip>
          </Button>
          <Dialog
            open={this.state.open}
            PaperProps={{
              sx: {
                position: " fixed",
                top: 0,
                right: 0,
                margin: 0,
                bottom: 0,
                maxHeight: "100vh",
              },
            }}
            keepMounted
            onClose={this.handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <Grid spacing={0}>
              <div className="consoleTextFieldBlock">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div className="filterBlockHeader">
                    <FilterListOutlinedIcon />
                    <span>Filter</span>
                  </div>
                </div>
              </div>
              <Grid item xs={12} className="filterBlockContainer">
                <Box gridColumn="span 3">
                  <div className="filterDateBlock">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>From</label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="dd-MM-yyyy"
                            name="dateOne"
                            value={this.state.fromDate}
                            fullWidth
                            onChange={(e) =>
                              this.handleSelectDate(e, "FromDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="fromDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>To</label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="dd-MM-yyyy"
                            name="dateOne"
                            value={this.state.toDate}
                            fullWidth
                            onChange={(e) => this.handleSelectDate(e, "ToDate")}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="toDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={statusoptions}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      selected={this.state.StatusValue}
                      onSelectChange={this.handleSelectChange}
                      name="Status"
                      label="Status"
                    />
                  </div>
                </Box>

                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Search"}
                    className="greenButton"
                    handleClick={this.handleSubmit}
                    type="sumbit"
                  />
                </div>
              </Grid>
            </Grid>
          </Dialog>
        </Box>
      </>
    );
  }
}
const statusoptions = [
  { value: "", name: "select...", label: "select..." },
  { value: 2, name: "INPROGRESS", label: "INPROGRESS" },
  { value: 3, name: "APPROVED", label: "APPROVED" },
  { value: 4, name: "REJECTED", label: "REJECTED" },
  { value: 28, name: "PROCESSED", label: "PROCESSED" },
  { value: 30, name: "DATA COLLECTED", label: "DATA COLLECTED" },
  { value: 32, name: "LOCKED", label: "LOCKED" },
];
const mapStatetoProps = (state) => {
  const { statusList } = state.DropDownListReducer;
  return { statusList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getStatusList,
    //   getBranchList,
  })(PayRegisterFilter)
);
