/** */
import React, { Component } from "react";
import { Button } from "@mui/material";
import panUploadIcon from "../../assets/images/panUploadIcon.png";
import DeleteIcon from "../../assets/images/picEditIcon.png";
import TickIcon from "../../assets/images/fileUploadedTick.png";
import { serverApi } from "../../helpers/Consts";
import { postUploadFileList } from "../../store/MyProfile/UploadFile/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      imagePreview_Url: "",
      fileUpload: "",
      imagePreview: "d-none",
      uploadFileName: "d-none",
      uploadFileBlock: "d-block",
      userDit: JSON.parse(localStorage.getItem('authUser')),
      companyId: localStorage.getItem('companyId'),
    };
    this.handleselectedFile = this.handleselectedFile.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
  }

  handleselectedFile = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let fileExt =  file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 1);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", this.props.taskUniqueId);
    formData.append("createdBy", this.state.userDit.userId);
    formData.append("fileCompanyId", this.state.companyId);
      this.setState({
        fileName: file.name,
        fileExtension: file.name.split(".").pop(),
      });
    this.setState({
      selectedFile: e.target.files[0],
      selectedFileName: e.target.files[0].name,
      uploadFileName: "d-block",
      uploadFileBlock: "d-none",
    });
    this.props.postUploadFileList(serverApi.UPLOADFILE,formData,this.props.history);
  };
  handleDeleteFile = () => {
    this.setState({
      selectedFile: "",
      selectedFileName: "",
      uploadFileName: "d-none",
      uploadFileBlock: "d-block",
    });
  };

  render() {
    return (
      <div>
        {this.props.label && <label>&nbsp;</label>}
        <div className="ducumentUploadBlock">
          <div
            className={"documentUploadContent " + this.state.uploadFileBlock}
          >
            <input
              type="file"
              name="file"
              id="file-upload"
              onChange={this.handleselectedFile}
              className="hiddenInput"
            />
            <label htmlFor="file-upload" style={{ display: "flex" }}>
              <Button
                variant="contained"
                component="span"
                className="documentUpload"
              >
                <img src={panUploadIcon} alt="" /> {this.props.label}
              </Button>
            </label>
          </div>
          <div className={this.state.uploadFileName }>
            <div className="uploadedInfo">
              <img src={TickIcon} alt="" className="tickIcon" />
              <div className="selectedFileName">
                {this.state.selectedFileName}
              </div>
              <button onClick={this.handleDeleteFile} className="deleteButton">
                <img src={DeleteIcon} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStatetoProps = (state) => {
  const { Upload_File_List } = state.UploadFileReducer;
  return { Upload_File_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    postUploadFileList,
  })(ImageUpload)
);
