import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Grid, Box } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/JuteProduction/DynamicSelect";
import Datetime from "react-datetime";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Button from "@mui/material/Button";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import moment from "moment";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
  getProcessMasterList,
  getMachineDetailsAction,
  addFinishingEntry,
} from "../../../store/JuteProduction/FinishingEntry/actions";
import {
  getMachineNumber,
  getSpell,
} from "../../../store/HRMS/AttendanceCalender/actions";
import { getWorkerList } from "../../../store/HRMS/ExpenseBooking/actions";
import InputAdormentSearch from "../../../components/InputAdorment/InputAdormentSearch";
import searchIcon from "../../../assets/images/search.png";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import CustomButton from "../../../components/Buttons/Buttons";

import swal from "sweetalert";
import { validate } from "../../../Utilities/Validations";

class CreateFinishingEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProcessMasterContent: [],
      userDit: JSON.parse(localStorage.getItem("authUser")),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      spellContent: [],
      machineDetailsData: [],
      ebno: "",
      employee_name: "",
      employee_dept: "",
      employee_occuption: "",
      employeeDetailsBlock: "none",
      machineDdBlock: "none",
      productionInputBlock: "none",
      ebNumberInput: "block",
      time: moment().format().split("T"),
      prodTypeValue: 0,
      production: "",
      inDate: new Date(),
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getProcessMasterList(
      serverApi.GET_PROCESS_MASTER_LIST +
        localStorage.getItem("companyId") +
        "/" +
        this.state.cipher,
      this.props.history
    );
    this.props.getSpell(
      serverApi.SPELL +
        localStorage.getItem("companyId") +
        "/" +
        this.state.cipher,
      this.props.history
    );
  }

  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    this.props.headerData[key] = event;
    if (key === "sourceMrNumber") {
      this.setState({ [key]: event });
    } else {
      this.setState({ [key]: event.target.value });
    }
  };
  handlePropSelectChange = (
    selectedValue,
    selectedName,
    name,
    prodTypeValue
  ) => {
    this.setState({ [name]: selectedValue });
    if (name === "WorkType") {
      if (prodTypeValue === 2) {
        // debugger;
        const data = {
          companyId: localStorage.getItem("companyId"),
          machineType1: selectedName,
          machineType2: 0,
        };
        this.props.getMachineDetailsAction(
          serverApi.GET_ALL_MACHINE_DETAILS_DROPDOWN,
          data,
          this.props.history
        );
      } else {
        this.setState({
          machineId: "",
          production: "",
        });
      }

      this.setState({
        prodTypeValue: prodTypeValue,
        WorkType: selectedValue,
      });
    }
    if (name === "machineId") {
      this.setState({
        machineId: selectedValue,
      });
    }
    if (name === "Spell") {
      this.setState({
        spell_name: selectedValue,
      });
    }
  };
  handleChange = (key) => (event) => {
    this.setState({ [key]: event });

    if (key === "ebno") {
      this.setState({
        ebno: event,
      });
    }

    if (key === "production") {
      this.setState({
        production: event,
      });
    }
  };
  onClick = () => {
    // debugger;
    this.props.getWorkerList(
      serverApi.WORKER_LIST +
        this.state.ebno +
        "/" +
        localStorage.getItem("companyId") +
        "/" +
        this.state.userDit.userId,
      this.props.history
    );
    if (
      this.state.employeeDetailsBlock === "none" &&
      this.state.productionInputBlock === "none"
    ) {
      this.setState({
        employeeDetailsBlock: "block",
        productionInputBlock: "block",
      });
    }
  };
  handleKeyPress = (event) => {
    if (event.code === "Enter") {
      this.props.getWorkerList(
        serverApi.WORKER_LIST +
          this.state.ebno +
          "/" +
          localStorage.getItem("companyId") +
          "/" +
          this.state.userDit.userId,
        this.props.history
      );
    }
    if (this.state.employeeDetailsBlock === "none") {
      this.setState({ employeeDetailsBlock: "block" });
    }
  };
  componentWillReceiveProps(props) {
    if (props.GetProcessMasterListData.data) {
      let processMasterList = [];
      let obj = {};
      let list = props.GetProcessMasterListData.data.filter((props) => {
        if (props.value !== 0) {
          return props;
        }
      });
      let workTypeData = list.length;
      if (workTypeData > 0) {
        list.map((item) => {
          obj = {
            label: item.label,
            value: item.value,
            name: item.machineType,
            variant: item.prodType,
          };
          processMasterList.push(obj);
        });
      }
      this.setState({
        ProcessMasterContent: processMasterList,
      });
    }
    if (props.spell) {
      if (props.spell) {
        let list = props.spell.filter((prop) => {
          if (prop.value !== "0") {
            return prop;
          }
        });
        this.setState({
          spellContent: list, // updating state name list dropdown
        });
      }
    }
    if (props.worker_List.data) {
      this.setState({
        employee_name: props.worker_List.data.workerName,
        employee_dept: props.worker_List.data.deptIdDesc,
        employee_occuption: props.worker_List.data.designationIdDesc,
      });
    }
    if (props.GetAllMachineDetailsArray.length > 0) {
      let MachineNumberDetails = [];
      let machineObj = {};
      props.GetAllMachineDetailsArray.map((item) => {
        machineObj = {
          label: item.machineName,
          value: item.machineId,
          name: item.machineName,
        };
        MachineNumberDetails.push(machineObj);
      });
      this.setState({
        machineDetailsData: MachineNumberDetails,
      });
    }
    // setting the ebNo empty when prodTypevalue is selected 2
    if (this.state.prodTypeValue == 2) {
      this.state.ebno = "";
    }
  }
  onClickCancel = () => {};

  handleSelectDate = (e, name) => {
    if (name === "entryDate") {
      var date = e.getTime();

      this.setState({
        inDate: e,
      });
    }

    if (name === "time") {
      let actualtime = moment(e).format();
      let formattedTime = actualtime.split("T", 2);

      this.setState({
        time: formattedTime,
      });
    }
  };

  onClickCreate = () => {
    let time = this.state.time[1].split("+");
    var date = new Date();

    var userId = JSON.parse(localStorage.getItem("authUser"));
    var data = {
      spell: this.state.spell_name,
      workType: this.state.WorkType,
      ebNo: this.state.ebno,
      entryDate: this.state.inDate.getTime(),
      production: this.state.production,
      createdBy: userId.userId,
      companyId: localStorage.getItem("companyId"),
      cipher: userId.cipher,
      machineId: this.state.machineId,
    };

    const validateFields = [
      { field: this.state.spell_name, msg: "Spell Name", type: 2 },
      { field: this.state.WorkType, msg: "Work Type", type: 2 },
      { field: this.state.production, msg: "Production", type: 1 },
    ];

    let validObj = validate(validateFields);
    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message}`);
      }
      if (validObj.type === 2) {
        swal(`Please Select ${validObj.message}`);
      }
    } else {
      this.props.addFinishingEntry(
        serverApi.ADD_FINISHING_ENTRY,
        data,
        this.props.history
      );
    }
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    if (this.state.prodTypeValue == 2) {
      this.state.ebno = "";
      this.state.employee_name = "";
      this.state.employee_dept = "";
      this.state.employee_occuption = "";
    }

    return (
      <div className="finishingEntriesContainer">
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div>
                      <div className="TextFieldBlock">
                        <label>
                          Date<div className="mandatoryField">*</div>
                        </label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          value={this.state.inDate}
                          fullWidth
                          onChange={(e) =>
                            this.handleSelectDate(e, "entryDate")
                          }
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="TextFieldBlock">
                      <label>
                        Time<span className="starcolor">*</span>
                      </label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="Time"
                        name="time"
                        value={this.state.time}
                        onChange={(e) => this.handleSelectDate(e, "time")}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input ref={inputRef} {...inputProps} />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={
                          this.state.spellContent
                            ? this.state.spellContent.map((item) => ({
                                label: item.label,
                                value: item.value,
                                name: item.name,
                              }))
                            : ""
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.spell_name}
                        name="Spell"
                        label="Spell"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.ProcessMasterContent}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.WorkType}
                        name="WorkType"
                        label="Work Type"
                        update={
                          this.props.location.state !== undefined ? "" : 1
                        }
                        required
                        isDisabled={
                          this.state.spell_name === "" ||
                          this.state.spell_name === undefined ||
                          this.state.spell_name.length === 0
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>

                  {this.state.prodTypeValue === 2 ? (
                    ""
                  ) : (
                    <>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <InputAdormentSearch
                            imgUrl={searchIcon}
                            label="EB Number"
                            handleChange={this.handleChange("ebno")}
                            onClick={this.onClick}
                            value={this.state.ebno}
                            maxLength={15}
                            placeholder="Enter EB No."
                            disabled={
                              this.state.WorkType === undefined ? true : false
                            }
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 3" style={{}}>
                        <TextFieldNormal
                          value={this.state.production}
                          caps="OFF"
                          type="number"
                          onChange={this.handleChange("production")}
                          label="Production"
                          required
                          disabled={
                            this.state.WorkType === undefined ? true : false
                          }
                        />
                      </Box>
                    </>
                  )}
                </Box>

                {this.props.worker_List.status === true ? (
                  <>
                    {this.state.prodTypeValue === 2 ? (
                      ""
                    ) : (
                      <div
                        className="employeeDetailsBlock"
                        style={{ display: this.state.employeeDetailsBlock }}
                      >
                        <Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={2}
                          className="employeeDetailsContent"
                        >
                          <Box gridColumn="span 4">
                            {this.state.employee_name !== "" ? "Name :" : ""}{" "}
                            <span>{this.state.employee_name}</span>
                          </Box>
                          <Box gridColumn="span 4">
                            {this.state.employee_dept !== ""
                              ? "Department :"
                              : ""}{" "}
                            <span>{this.state.employee_dept}</span>
                          </Box>
                          <Box gridColumn="span 4">
                            {this.state.employee_occuption !== ""
                              ? "Occupation :"
                              : ""}
                            <span>{this.state.employee_occuption}</span>
                          </Box>
                        </Box>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
                {this.state.prodTypeValue === 2 ? (
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                    className="employeeDetailsContent"
                  >
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.machineDetailsData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.machineId}
                          name="machineId"
                          label="Machine Number"
                        />
                      </div>
                    </Box>

                    <Box gridColumn="span 3" style={{}}>
                      <TextFieldNormal
                        value={this.state.production}
                        caps="OFF"
                        type="number"
                        onChange={this.handleChange("production")}
                        label="Production"
                        required
                      />
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <div className="consoleFormButtonBlock">
              <Link to="/ad_finishing_entry">
                <CustomButton
                  label={"Cancel"}
                  className="greenBorderButton"
                  handleClick={this.onClickCancel}
                />
              </Link>
              <CustomButton
                label={"Create"}
                className="greenButton"
                handleClick={this.onClickCreate}
                type="sumbit"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    GetProcessMasterListData,
    GetAllMachineDetailsArray,
    add_finishing_entry_list,
  } = state.FinishingEntryListReducer;
  const { spell } = state.AttendanceReducer;
  const { worker_List } = state.ExpenseBookingReducer;

  return {
    GetProcessMasterListData,
    GetAllMachineDetailsArray,
    spell,
    worker_List,
    add_finishing_entry_list,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getProcessMasterList,
    getSpell,
    getWorkerList,
    getMachineDetailsAction,
    addFinishingEntry,
  })(CreateFinishingEntry)
);
