import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import DynamicSelect from "../../components/Dropdown/Master/DynamicSelectMasters";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import TextFieldNormal from "../../components/TextField/TextFieldNormal";
import { getBranchList } from "../../store/Global/DropDownApis/actions";
import { getProjectList } from "../../store/Global/DropDownApis/actions";
import { getStatusList } from "../../store/Global/DropDownApis/actions";
import { getSupplierList } from "../../store/Global/DropDownApis/actions";
import { serverApi } from "../../helpers/Consts";
class DebitCreditNoteFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      projectListData: [],
      branchListData: [],
      supplierListData: [],
      statusListData: [],
      projectId: "",
      branchId: "",
      fromDate:moment().day(-7).format("YYYY-MM-DD"),
      toDate:moment().format("YYYY-MM-DD"),
      supplier: "",
      status:"",
      PoNO: "",
      challanNo:"",
      grnNo:"",
      drcrNo:"",
      srNo:"",
    };
  }

  componentDidMount() {
    const financialYearStartMonth = 3; // Assuming financial year starts in April
    let financialYearStart = moment()
      .year(localStorage.getItem("acadamicYear"))
      .month(financialYearStartMonth)
      .startOf("month");

    const fromDate = financialYearStart.day(-7).format("YYYY-MM-DD");
    const toDate = moment().format("YYYY-MM-DD");

    this.setState({
      fromDate:fromDate,
      toDate:toDate ,
    })
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: this.state.companyId,
    };
    this.props.getSupplierList(
      serverApi.GET_ALL_SUPPLIERS_LIST +localStorage.getItem("companyId"),
      this.state.history
    );
    this.props.getProjectList(
      serverApi.GET_ALL_PROJECTS,
      data,
      this.props.history
    );
    this.props.getBranchList(
      serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
      this.props.history
    );
    this.props.getStatusList(serverApi.GET_STATUS_LIST, this.props.history);
  }

  componentWillReceiveProps(props) {
    if (props.projectList && Array.isArray(props.projectList.data)) {
      let data = props.projectList.data
      if (Array.isArray(data) || (typeof data === "object" && data !== null)) {
      const newObj ={
        value:"",
        name:"Select...",
        label:"Select..."
      }
      const newArray = [
        newObj,
        ...(Array.isArray(data) ? data : Object.values(data)),
      ];
        this.setState({
          projectListData: newArray,
        });
      }
    }
    if (props.branchList) {
      this.setState({
        branchListData: props.branchList.data,
      });
    }
    if (props.statusList) {
      this.setState({
        statusListData: props.statusList.data,
      });
    }
    if (props.supplierList) {
      this.setState({
        supplierListData: props.supplierList.data,
      });
    }
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "project") {
      this.setState({
        projectId: selectedValue,
      });
    }
    if (name === "Supplier") {
      this.setState({
        supplier: selectedValue,
      });
    }
    if (name === "branch") {
      this.setState({
        branchId: selectedValue,
      });
    }
    if(name === "status"){
      this.setState({
        status: selectedValue,
      })
    }
    
  };
  handleSelectDate = (e, name) => {
    if (name === "FromDate") {
      let date = moment(e).format();
      let formattedFromDate = date.split("T", 1).toString();
      this.setState({
        fromDate: formattedFromDate,
      });
    }

    if (name === "ToDate") {
      let date = moment(e).format();
      let formattedToDate = date.split("T", 1).toString();
      this.setState({
        toDate: formattedToDate,
      });
    }
  };

  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "srNo") {
      this.setState({
        srNo: event,
      });
    }
    if (key === "drcrNo") {
      this.setState({
        drcrNo: event,
      });
    }
    if (key === "challanNo") {
        this.setState({
            challanNo: event,
        });
      }
      if (key === "grnNo") {  
        this.setState({
            grnNo: event,
        });
      }
  };

  handleSubmit = () => {
    var data = {
      fromDate: this.state.fromDate=== "" ? "" : moment(this.state.fromDate, "YYYY-MM-DD").format(
        "DD-MM-YYYY"
      ),
      toDate: this.state.fromDate=== "" ? "" : moment(this.state.toDate, "YYYY-MM-DD").format(
        "DD-MM-YYYY"
      ),
      grnNo:this.state.grnNo,
      supplier:this.state.supplier,
      branchId: this.state.branchId,
      projectId: this.state.projectId,
      drcrNo:this.state.drcrNo,
      srNo:this.state.srNo,
      status:this.state.status,
    };
    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid spacing={0}>
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
              </div>
            </div>
            <div className="procurementFilter">
              <Grid container spacing={1} className="filterBlockContainer">
                <Grid item xs={6}>
                  <div className="filterDateBlock">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>From Date</label>
                      </div>
                      {/* <ConsoleDatePicker /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.fromDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "FromDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="fromDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="filterDateBlock">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>To Date</label>
                      </div>
                      {/* <ConsoleDatePicker /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.toDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "ToDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="fromDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      value={this.state.srNo}
                      placeholder={"Enter here"}
                      label="SR No"
                      caps="OFF"
                      onChange={this.handleChange("srNo")}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      value={this.state.drcrNo}
                      placeholder={"Enter here"}
                      label="DRCR No"
                      caps="OFF"
                      onChange={this.handleChange("drcrNo")}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={
                        this.state.supplierListData &&
                        this.state.supplierListData.map((item) => ({
                          label: item.label,
                          value: item.value,
                          name: item.name,
                        }))
                      }
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.supplier}
                      name="Supplier"
                      label="Supplier"
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={
                        this.state.projectListData &&
                        this.state.projectListData.map((item) => ({
                          label: item.name,
                          value: item.projectId,
                          name: item.name,
                        }))
                      }
                      selected={this.state.projectId}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      name="project"
                      label="Project"
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.branchListData}
                      selected={this.state.branchId}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      name="branch"
                      label="Branch"
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.statusListData}
                      selected={this.state.status}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      name="status"
                      label="Status"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="consoleFormButtonBlock">
              <CustomButton
                label={"Search"}
                className="greenButton"
                handleClick={this.handleSubmit}
                type="sumbit"
              />
            </div>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}
const mapStatetoProps = (state) => {
  const { indentList, indentListColumns } = state.Indent;

  const { branchList, projectList, statusList, supplierList } =
    state.DropDownListReducer;
  return {
    indentList,
    indentListColumns,
    projectList,
    branchList,
    statusList,
    supplierList,
  };
};
export default withRouter(
  connect(mapStatetoProps, {
    getBranchList,
    getProjectList,
    getStatusList,
    getSupplierList,
  })(DebitCreditNoteFilter)
);
