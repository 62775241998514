import React, { Component } from "react";
import { getAcadamicYears } from "../../store/auth/FinancialYear/actions";
import {getTenantId} from "../../helpers/Consts";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let tenantId =  getTenantId(window.location.hostname).split('.')[0];
    let tenantName = tenantId+".vowerp.com"
    return (
      <>
        <div className="clearfix"></div>
        <div className="footerBlock">
          <p>Copyright &copy; {new Date().getFullYear()} {tenantName}</p>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};
export default withRouter(
  connect(mapStatetoProps, {
    getAcadamicYears,
  })(Footer)
);
