import React, { Component } from "react";
import InwardLineItem from "./IN_WR_LineItem";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import { Grid, Step, Stepper, StepLabel } from "@mui/material";
import CustomButton from "../../../components/Buttons/Buttons";
import Link from "@mui/material/Link";
import InwardFillDetail from "./FillDetails";
import InwardPreview from "./InwardPreview";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import moment from "moment";
import {
  InwardLineItemsProps,
  CreateInward,
  UpdateStore,
  UpdateInward,
} from "../../../store/Purchase/Inward/actions";
import { ApprovalHierarchy } from "../../../store/HRMS/EmployeeDataBase/actions";
import swal from "sweetalert";
import MaterialInspecLineItems from "../Material_Inspection/MaterialInspecLineItems";
import StoreRecepitLineItems from "../Store_Receipt/StoreRecepitLineItems";
import BillPassLineItems from "../Bill_Pass/BillPassLineItems";
import MIPreview from "../Material_Inspection/MIPreview";
import StoreReciptPreview from "../Store_Receipt/StoreReciptPreview";
import BillPassPreview from "../Bill_Pass/BillPassPreview";
import WorkReportPreview from "./WorkReportPreview";
import WorkReportLineitems from "./WorkReportLineitems";
import { LineItemsValidtions } from "../../../Utilities/LineItemsValidtions";
import { isEmpty, limitDecimals } from "../../../Utilities/helper";

class CreateInwardEntry extends Component {
  constructor() {
    super();
    this.state = {
      steps: [
        {
          title: "Select Option",
          icon: "http://vowerp.com/MailImages/assets/stepperSelection-00.png",
          activeIcon:
            "http://vowerp.com/MailImages/assets/stepperSelectionActive-00.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Fill Details",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Add Items",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Preview",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive03.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
      ],
      userDit: JSON.parse(localStorage.getItem("authUser")),
      currentStep: 1,
      inwardTypeId: 0,
      pathname: "",
      POType: 0,
      source: "",
      alignment: "left",
      RejectButton: false,
      UpdateButton: false,
      ApproveButton: false,
      CancelButton: true,
      showPoButton: false,
      locationState: "",
      InwardFillDetails: {
        inwardNo: "",
        inwardDate: moment().format().split("T")[0],
        inwardtime: moment().format().split("T")[1],
        storeReceiptDate: moment().format().split("T")[0],
        inwardId: "",
        challanno: "",
        challannoDate: moment().format().split("T")[0],
        branchId: "",
        projectId: "",
        supplierId: "",
        supplierName: "",
        source: "",
        recordType: "",
        companyId: localStorage.getItem("companyId"),
        internalNote: "",
        createdBy: "",
        isCommitted: false,
        inwardDetails: "",
        consignmentNumber: "",
        consignmentDate: moment().format().split("T")[0],
        vehicleNumber: "",
        driverContactNumber: "",
        driverName: "",
        make:"",
        remarks: "",
        taxType: "",
        taxTypeDesc: "",
        goodsType: "GRN",
        invoiceNumber: "",
        invoiceDate: moment().format().split("T")[0],
        ewaybillno: "",
        ewaybillnoDate: moment().format().split("T")[0],
        customerId: "",
        status: "",
        bpStatusName: "",
        statusName: "",
        srStatusName: "",
        srStatusId: "",
        billpassStatusId: "",
        PoItem: "",
        challanTypeNo: "",
        srDate: "",
        SRPrintNO: "",
        GateEntryNo: "",
        TCSPercentage: "",
        AdvancePercentage: "",
        AdvanceAmount: "",
        termsNcondition: "",
        selectTermsNconditions: "",
        billpassDate: moment().format().split("T")[0],
        billPassNo: "",
        SrNo: "",
        poSequence: "",
        paymentDueDate: moment().format().split("T")[0],
        invoiceAmount: "",
        invoiceRecvdDate: moment().format().split("T")[0],
        tcsPercentage: "",
        tcsPercentageValue: "",
        tdsPercentageValue: "",
        tcsTdsValue: "",
        tcsTds: "",
        tdsPercentage: "",
        tcsAmount: 0,
        tdsReason: null,
        tdsAmount: 0,
        warehouseno: "",
        ledger: "",
        ledgerValue: "",
        tdsPayable: "N",
        totalAmount: 0,
        totalCGST: 0,
        totalSGST: 0,
        totalIGST: 0,
        netTotal: 0,
        roundOffValue: "",
        gstInvoiceType: "",
        itcApplicable: "",
        discountedAmount: "",
        discountedRate:"",
      },
      Inward_LineItems: [],
      showInwardFields: false,
      showWRFields: false,
      showMIFields: false,
      showSRFields: false,
      showBPFields: false,
      validation: false,
    };
  }

  componentDidMount() {
    this.setState({
      locationState: this.props.location,
    });
    if (this.props.location.state !== undefined) {
      this.setState({
        currentStep: 4,
      });
      if (this.props.location.state.rowData) {
        let taskId = 0;
        if (this.props.location.pathname === "/create_material_inspection") {
          taskId = 34;
        }
        if (this.props.location.pathname === "/create_WorkReport") {
          taskId = 38;
        }
        if (this.props.location.pathname === "/create_inward") {
          taskId = 34;
        }
        if (this.props.location.pathname === "/create_store_bill_pass") {
          taskId = 7;
        }
        if (this.props.location.pathname === "/Create_store_receipt") {
          taskId = 3;
        }
        let statusId =
          taskId === 3
            ? this.props.location.state.rowData.srStatusId
            : taskId === 7
            ? this.props.location.state.rowData.billpassStatusId
            : this.props.location.state.rowData.status;
        this.props.ApprovalHierarchy(
          serverApi.APPROVAL_BUTTON +
            JSON.parse(localStorage.getItem("authUser")).userId +
            "/" +
            taskId +
            "/company/" +
            this.props.location.state.rowData.companyId +
            "/branch/" +
            this.props.location.state.rowData.branchId +
            "/status/" +
            statusId,
          this.props.history
        );
        showSpinner();

        let source = this.props.location.state.rowData.source;
        let rowData = this.props.location.state.rowData;
        let inwardDetails =
          this.props.location.state.rowData.inwardDetails === null
            ? []
            : this.props.location.state.rowData.inwardDetails;
        if (this.props.location.state.rowData.inwardDetails) {
          inwardDetails = this.props.location.state.rowData.inwardDetails.map(
            (item) => {
              return {
                id: item.id,
                lineItemId: item.id,
                departmentId: item.departmentId,
                departmentName: item.departmentName,
                itemCode: item.itemCode,
                itemId: item.itemId,
                itemName: item.itemName,
                inwardSequenceNo: rowData.inwardSequenceNo,
                itemGroupId: item.itemGroup,
                itemGroupName: item.itemGroupName,
                itemList: item.itemList,
                itemGroupList: item.itemGroupList,
                rate: item.rate,
                budgetHeadId: item.budgetHeadId,
                budgetHeadName: item.budgetHeadName,
                inwardQty: item.inwardQty,
                uomCode: item.uom ? item.uom : item.uomCode,
                description: item.itemName,
                make: item.make,
                remarks: item.remarks,
                poId: item.poId,
                poDetailsId: item.poDetailsId,
                oldQty: item.oldQty,
                hdrStatus: item.hdrStatus,
                isActive: item.isActive,
                tax: item.tax,
                igstPercentage: rowData.taxType === 1 ? item.igstPercentage : 0,
                cgstPercentage: rowData.taxType === 2 ? item.cgstPercentage : 0,
                sgstPercentage: rowData.taxType === 2 ? item.sgstPercentage : 0,
                hsnCode: item.hsnCode,
                taxPercentage: item.taxPercentage,
                remainingQuantity: isEmpty(item.remainingQuantity)
                  ? item.inwardQty
                  : item.remainingQuantity,
                amount: item.amount,
                discountMode: item.discountMode,
                discountPercentage: item.discount,
                approvedQty: isEmpty(item.approvedQty)
                ? item.inwardQty
                : item.approvedQty,
                rejectedQty: item.rejectedQty,
                status: item.status,
                warehouseno: item.warehouseno,
                dispatchQty: item.dispatchQty,
                reason: item.reason,
                metadata: item.metadata,
                cgstAmount: item.cgstAmount,
                igstAmount: item.igstAmount,
                sgstAmount: item.sgstAmount,
                roundOffValue: item.roundOffValue,
                discount: item.discount,
                discountedRate: item.discountedRate,
                discountedAmount: item.discountedAmount,
                debitnoteSequnce: item.debitnoteSequnce,
                debitnoteValue: item.debitnoteValue,
                creditnoteValue: item.creditnoteValue,
                debitnoteQty: item.debitnoteQty,
                creditnoteQty: item.creditnoteQty,
                debitnoteCgstTax: item.debitnoteCgstTax,
                debitnoteIgstTax: item.debitnoteIgstTax,
                creditnoteIgstPercentage: item.creditnoteIgstPercentage,
                creditnoteCgstPercentage: item.creditnoteCgstPercentage,
                debitnoteSgstTax: item.debitnoteSgstTax,
                debitnoteSgstPercentage: item.debitnoteSgstPercentage,
                creditnoteSgstTax: item.creditnoteSgstTax,
                creditnoteSgstPercentage: item.creditnoteSgstPercentage,
                debitnoteRate: item.debitnoteRate,
                creditnoteRate: item.creditnoteRate,
                inspectionCheck: item.inspectionCheck,
                inwardId: item.inwardId,
                itemGroup: item.itemGroup,
                poQty: item.poQty,
                poRcdQty: item.poRcdQty,
                ctaxAmount: item.ctaxAmount,
                staxAmount: item.staxAmount,
                itaxAmount: item.itaxAmount,
                itaxPer: item.itaxPer,
                ctaxPer: item.ctaxPer,
                staxPer: item.staxPer,
                poSequence: item.poSequence,
                balanceQuantity: item.balanceQuantity,
              };
            }
          );
        }
        if (
          (source === "WITHOUTPO" || source === "WITHOUTWO") &&
          !(
            this.props.location.pathname === "/create_material_inspection" ||
            this.props.location.pathname === "/Create_store_receipt"
          )
        ) {
          let Inward_LineItems = {
            id: 1,
            lineItemId: 1,
            departmentId: "",
            departmentName: "",
            itemCode: "",
            itemId: "",
            itemGroupId: "",
            itemGroupName: "",
            itemList: [],
            itemGroupList: [],
            rate: 0,
            budgetHeadId: "",
            budgetHeadName: "",
            inwardQty: 0,
            uomCode: "",
            description: "",
            poId: "",
            poDetailsId: "",
            oldQty: 0,
            qty: 0,
            hdrStatus: 1,
            isActive: 1,
            tax: "",
            igstPercentage: 0,
            cgstPercentage: 0,
            sgstPercentage: 0,
            make:"",
            remarks:"",
            discountedAmount:"",
            discountedRate:"",
            hsnCode: "",
            taxPercentage: "",
            remainingQuantity: 0,
            amount: 0,
            discountMode: 0,
            discountPercentage: 0,
            inwardSequenceNo: "",
          };

          inwardDetails.push(Inward_LineItems);
          this.props.InwardLineItemsProps(inwardDetails);
        } else {
          this.props.InwardLineItemsProps(inwardDetails);
        }

        this.setState({
          source: rowData.source,
          InwardFillDetails: {
            inwardNo: rowData.inwardSequenceNo,
            inwardDate: moment(rowData.inwardDate, "DD-MM-YYYY").format(
              "YYYY-MM-DD"
            ),
            inwardtime: moment().format(),
            challanno: rowData.challanno,
            inwardId: rowData.inwardId,
            billpassStatusId: rowData.billpassStatusId,
            challannoDate:
              rowData.challannoDate !== null
                ? moment(rowData.challannoDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : moment().format().split("T")[0],
            branchId: rowData.branchId,
            projectId: rowData.projectId,
            supplierId: rowData.supplierId,
            supplierName: rowData.supplierName,
            source: rowData.source,
            recordType: rowData.recordType,
            companyId: localStorage.getItem("companyId"),
            internalNote: rowData.internalNote,
            createdBy: rowData.createdBy,
            isCommitted: rowData.isCommitted,
            inwardDetails: inwardDetails,
            consignmentNumber: rowData.consignmentNumber,
            consignmentDate:
              rowData.consignmentDate !== null
                ? moment(rowData.consignmentDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : moment().format().split("T")[0],
            vehicleNumber: rowData.vehicleNumber,
            driverContactNumber: rowData.driverContactNumber,
            driverName: rowData.driverContactNumber,
            remarks: rowData.remarks,
            taxType: rowData.taxType,
            taxTypeDesc: rowData.taxTypeDesc,
            goodsType: rowData.recordType,
            invoiceNumber: rowData.invoiceNumber,
            invoiceDate: rowData.invoiceDate !== null
            ? moment(rowData.invoiceDate, "DD-MM-YYYY").format(
              "YYYY-MM-DD"
              )
            : moment().format().split("T")[0],
            ewaybillno: rowData.ewaybillno,
            ewaybillnoDate: rowData.ewaybillnoDate
              ? moment(rowData.ewaybillnoDate, "DD-MM-YYYY").format(
                  "YYYY-MM-DD"
                )
              : moment().format().split("T")[0],
            customerId: rowData.customerId,
            status: rowData.status,
            bpStatusName: rowData.bpStatusName,
            statusName: rowData.statusName,
            srStatusName: rowData.srStatusName,
            srStatusId: rowData.srStatusId,
            PoItem: rowData.poId,
            challanTypeNo: rowData.source,
            srDate: moment(rowData.storeReceiptDate, "DD-MM-YYYY").format(
              "YYYY-MM-DD"
            ),
            SRPrintNO: rowData.storeReceiptNo,
            storeReceiptDate:
              rowData.storeReceiptDate !== null
                ? moment(rowData.storeReceiptDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : moment().format().split("T")[0],
            GateEntryNo: rowData.inwardSequenceNo,
            poSequence: rowData.poSequence,
            tcsPercentage: rowData.tcsPercentage,
            tcsPercentageValue: rowData.tcsPercentage,
            ledger: rowData.ledgerName,
            ledgerValue: rowData.ledgerId,
            AdvancePercentage: "",
            AdvanceAmount: "",
            termsNcondition: "",
            selectTermsNconditions: "",
            internalNotes: rowData.internalNote,
            billpassDate:
              rowData.billpassDate !== null
                ? moment(rowData.billpassDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : moment().format().split("T")[0],
            billPassNo: rowData.billpassNumber,
            SrNo: rowData.storeReceiptNo,
            paymentDueDate:
              rowData.invoiceDueDate !== null
                ? moment(rowData.invoiceDueDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : moment().format().split("T")[0],
            invoiceAmount: rowData.invoiceAmount,
            invoiceRecvdDate:
              rowData.invoiceRecvdDate === null
                ? this.state.InwardFillDetails.invoiceRecvdDate
                : moment(rowData.invoiceRecvdDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  ),
            tdsReason: rowData.tdsReason,
            tdsAmount: rowData.tdsAmount,
            tdsPayable: rowData.tdsPayable,
            roundOffValue: rowData.roundOffValue,
            tdsPercentageValue: rowData.tdsPercentage,
            tdsPercentage: rowData.tdsPercentage,
            tcsTdsValue: rowData.tcsTdsValue,
            tcsTds: rowData.tcsTds,
            tcsAmount: Number(rowData.tcsAmount),
            totalAmount: rowData.totalAmount,
            gstInvoiceType: rowData.gstInvoiceType,
            itcApplicable: rowData.itcApplicable,
            make:rowData.make,
            remarks:rowData.remarks,
            discountedAmount:rowData.discountedAmount,
            discountedRate:rowData.discountedRate,
          },
        });

        this.props.InwardLineItemsProps(inwardDetails);
      }
    } else {
      let Inward_LineItems = [
        {
          id: 1,
          lineItemId: 1,
          departmentId: "",
          departmentName: "",
          itemCode: "",
          itemId: "",
          itemGroupId: "",
          itemGroupName: "",
          itemList: [],
          itemGroupList: [],
          rate: 0,
          budgetHeadId: "",
          budgetHeadName: "",
          inwardQty: 0,
          uomCode: "",
          description: "",
          make: "",
          remarks: "",
          discountedAmount:"",
          discountedRate:"",
          poId: "",
          poDetailsId: "",
          oldQty: 0,
          qty: 0,
          hdrStatus: 1,
          isActive: 1,
          tax: "",
          igstPercentage: 0,
          cgstPercentage: 0,
          sgstPercentage: 0,
          hsnCode: "",
          taxPercentage: "",
          remainingQuantity: 0,
          amount: 0,
          discountMode: 0,
          discountPercentage: 0,
          inwardSequenceNo: "",
        },
      ];

      this.props.InwardLineItemsProps(Inward_LineItems);
    }

    if (JSON.parse(localStorage.getItem("Configuration"))) {
      if (
        JSON.parse(localStorage.getItem("Configuration")).configList.length !==
        0
      ) {
        JSON.parse(localStorage.getItem("Configuration")).configList.map(
          (item) => {
            if (item.configParameter == "inwardPoConfig") {
              if (item.configValue == 1) {
                this.setState({
                  showPoButton: true,
                });
              } else {
                this.setState({
                  showPoButton: false,
                });
              }
            }
          }
        );
      }
    }
  }

  componentWillReceiveProps(props) {
    if (props.Inward_LineItems) {
      this.setState({
        Inward_LineItems: props.Inward_LineItems,
      });
    }
    if (this.props.location.pathname) {
      if (this.props.location.pathname === "/create_inward") {
        this.setState({
          pathname: "PO",
        });
      } else {
        this.setState({
          pathname: "WO",
        });
      }
    }

    if (props.approval_hierarchy) {
      if (props.approval_hierarchy.data) {
        hideSpinner();
        this.setState({
          ApproveButton: props.approval_hierarchy.data.approveButton,
        });
      }
    }
  }

  QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;
    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };
  onClicknextBtn = () => {
    const { currentStep } = this.state;
    let valid = this.validateForms();
    if (currentStep === 2) {
      if (valid === true) {
        this.setState({
          currentStep: currentStep + 1,
        });
      }
      //  else {
      //   swal("Please select all the mandatory fields");
      // }
    } else if (currentStep === 3) {
      this.validateLineItems();
    } else {
      this.setState({
        currentStep: currentStep + 1,
      });
    }
  };

  onClickCancel = () => {
    const { currentStep } = this.state;
    if (currentStep === 2) {
      let url =
        this.props.location.pathname === "/create_inward"
          ? "/security_store_gate_entry"
          : this.props.location.pathname === "/create_WorkReport"
          ? "/workReport"
          : this.props.location.pathname === "/create_material_inspection"
          ? "/store_material_inspection"
          : this.props.location.pathname === "/Create_store_receipt"
          ? "/store_receipt"
          : "/store_bill_pass";
      // if (this.props.location.state !== undefined) {
      this.props.history.push(url);
      // } else {
      //   this.setState({
      //     currentStep: currentStep - 1,
      //   });
      // }
    } else {
      this.setState({
        currentStep: currentStep - 1,
      });
    }
  };
  validateForms = () => {
    let data = this.state.InwardFillDetails;
    if (this.props.location.pathname === "/create_inward") {
      if (this.state.currentStep === 2) {
        if (this.state.source === "WITHPO") {
          if (this.state.userDit.roleId !== 100) {
            if (data.supplierId === "" || data.supplierId === "0") {
              swal("Please select Supplier");
              return false;
            }
          }
          if (data.PoItem === "") {
            swal("Please select PO List");
            return false;
          }
        }
        if (data.inwardDate === "") {
          swal("Please the Inward Date");
          return false;
        }
        if (data.inwardtime === "") {
          swal("Please the Inward Time");
          return false;
        }
        if (this.state.userDit.roleId !== 100) {
          if (data.supplierId === "" || data.supplierId === "0") {
            swal("Please select Supplier");
            return false;
          }
        }
        if (data.branchId === "" || data.branchId === 0) {
          swal("Please select the Branch");
          return false;
        }
        if (data.projectId === "" || data.projectId === 0) {
          swal("Please select the Project");
          return false;
        }
        if (data.goodsType === "") {
          swal("Please the Goods Type");
          return false;
        }
        if (data.taxType === "" || data.taxType === null) {
          swal("Please select the Tax Type");
          return false;
        }
        if (this.state.userDit.roleId === 100) {
          // if (data.invoiceNumber === "" || data.invoiceNumber === null) {
          //   swal("Please enter the Invoice Number");
          //   return false;
          // }
          if (data.invoiceDate === "" || data.invoiceDate === null) {
            swal("Please enter the Invoice Date");
            return false;
          }
          if (data.ewaybillno === "" || data.ewaybillno === null) {
            swal("Please enter the eway bill no");
            return false;
          }
          if (data.ewaybillnoDate === "" || data.ewaybillnoDate === null) {
            swal("Please enter the eway bill no Date");
            return false;
          }
        }
      }
      return true;
    }
    if (this.props.location.pathname === "/create_WorkReport") {
      if (this.state.currentStep === 2) {
        if (this.state.currentStep === 2) {
          if (this.state.source === "WITHWO") {
            if (this.state.userDit.roleId !== 100) {
              if (data.supplierId === "" || data.supplierId === "0") {
                swal("Please select Supplier");
                return false;
              }
            }
            if (data.PoItem === "") {
              swal("Please select WO");
              return false;
            }
          }

          if (data.inwardDate === "") {
            swal("Please the Inward Date");
            return false;
          }
          if (data.inwardtime === "") {
            swal("Please the Inward Time");
            return false;
          }
          if (this.state.userDit.roleId !== 100) {
            if (data.supplierId === "" || data.supplierId === "0") {
              swal("Please select the Supplier");
              return false;
            }
          }
          if (data.branchId === "" || data.branchId === 0) {
            swal("Please select the Branch");
            return false;
          }
          if (data.projectId === "" || data.projectId === 0) {
            swal("Please select the Project");
            return false;
          }
          if (data.goodsType === "") {
            swal("Please select the Goods Type");
            return false;
          }
          if (data.taxType === "" || data.taxType === null) {
            swal("Please select the Tax Type");
            return false;
          }
          if (this.state.userDit.roleId === 100) {
            if (data.invoiceNumber === "" || data.invoiceNumber === null) {
              swal("Please enter the Invoice Number");
              return false;
            }
            if (data.invoiceDate === "" || data.invoiceDate === null) {
              swal("Please enter the Invoice Date");
              return false;
            }
            if (data.ewaybillno === "" || data.ewaybillno === null) {
              swal("Please enter the eway bill no");
              return false;
            }
            if (data.ewaybillnoDate === "" || data.ewaybillnoDate === null) {
              swal("Please enter the eway bill no Date");
              return false;
            }
          }
        }
        return true;
      }
    }
    if (this.props.location.pathname === "/create_material_inspection") {
      if (this.state.currentStep === 2) {
        if (data.supplierId === "") {
          swal("Please select Supplier");
          return false;
        }
        if (data.inwardDate === "") {
          swal("Please the Inward Date");
          return false;
        }
        if (data.inwardNo === "") {
          swal("Please the Inward Number");
          return false;
        }
        if (data.challanTypeNo === "") {
          swal("Please the Challan Type Number");
          return false;
        }
        if (data.branchId === "" || data.branchId === 0) {
          swal("Please select the Branch");
          return false;
        }
        if (data.projectId === "" || data.projectId === 0) {
          swal("Please select the Project");
          return false;
        }
        return true;
      }
    }
    if (this.props.location.pathname === "/create_store_bill_pass") {
      let BillPassdata = {
        billPassNo: this.state.InwardFillDetails.billPassNo,
        billpassDate: this.state.InwardFillDetails.billpassDate,
        supplier: this.state.InwardFillDetails.supplierId,
        gateEntryNo: this.state.InwardFillDetails.inwardNo,
        branch: this.state.InwardFillDetails.branchId,
        project: this.state.InwardFillDetails.projectId,
        goodsType: this.state.InwardFillDetails.goodsType,
      };
      if (this.state.currentStep === 2) {
        if (BillPassdata.supplier === "") {
          swal("Please select Supplier");
          return false;
        }
        if (BillPassdata.gateEntryNo === "") {
          swal("Please the Inward Number");
          return false;
        }
        if (
          BillPassdata.billpassDate === "" ||
          BillPassdata.billpassDate === undefined
        ) {
          swal("Please the Inward Number");
          return false;
        }
        if (BillPassdata.branchId === "" || data.branchId === 0) {
          swal("Please select the Branch");
          return false;
        }
        if (BillPassdata.projectId === "" || data.projectId === 0) {
          swal("Please select the Project");
          return false;
        }
        if (BillPassdata.goodsType === "") {
          swal("Please the Goods Type");
          return false;
        }
        return true;
      }
    }
    if (this.props.location.pathname === "/Create_store_receipt") {
      if (this.state.currentStep === 2) {
        if (data.supplierId === "") {
          swal("Please select Supplier");
          return false;
        }
        if (data.GateEntryNo === "") {
          swal("Please the Inward Number");
          return false;
        }
        if (data.taxType === "") {
          swal("Please the Challan Type Number");
          return false;
        }
        if (data.branchId === "" || data.branchId === 0) {
          swal("Please select the Branch");
          return false;
        }
        if (data.projectId === "" || data.projectId === 0) {
          swal("Please select the Project");
          return false;
        }
        if (data.goodsType === "") {
          swal("Please the Goods Type");
          return false;
        }
        return true;
      }
    }
  };

  validateLineItems = () => {
    const { currentStep } = this.state;
    let validObj = false;
    let lastRow =
      this.props.Inward_LineItems[this.props.Inward_LineItems.length - 1];
    let inwardLineItems = this.props.Inward_LineItems.filter(
      (item) => item.isActive !== 0 && item.approvedQty !== 0
    );
 
    for (var i = 0; i <= inwardLineItems.length - 1; i++) {
      if (this.props.location.pathname === "/create_inward") {
        if (this.state.source === "WITHOUTPO") {
          const validateFields = [
            {
              field: inwardLineItems[i].departmentId,
              msg: "Department",
              type: 2,
            },
            {
              field: inwardLineItems[i].budgetHeadId,
              msg: "Budget Head",
              type: 2,
            },
            {
              field: inwardLineItems[i].itemGroupId,
              msg: "Item Group",
              type: 2,
            },
            { field: inwardLineItems[i].itemId, msg: "Item", type: 2 },
            { field: inwardLineItems[i].inwardQty, msg: "Quantity", type: 2 },
            { field: inwardLineItems[i].rate, msg: "Rate", type: 2 },
          ];

          const validateFieldsOtherCharges = [
            { field: inwardLineItems[i].itemId, msg: "Item", type: 2 },
          ];
          if (Number(inwardLineItems[i].itemGroupId) === 997) {
            if (inwardLineItems.length === 1) {
              validObj = LineItemsValidtions(validateFieldsOtherCharges);
              if (validObj) {
                break;
              }
            } else {
              if (inwardLineItems[i] !== lastRow) {
                validObj = LineItemsValidtions(validateFieldsOtherCharges);
                if (validObj) {
                  break;
                }
              }
            }
          } else {
            if (inwardLineItems.length === 1) {
              validObj = LineItemsValidtions(validateFields);
              if (validObj) {
                break;
              }
            } else {
              if (inwardLineItems[i] !== lastRow) {
                validObj = LineItemsValidtions(validateFields);
                if (validObj) {
                  break;
                }
              }
            }
          }
        } else {
          if (this.state.source === "WITHPO") {
            const validateFields = [
              { field: inwardLineItems[i].inwardQty, msg: "Quantity", type: 2 },
              { field: inwardLineItems[i].rate, msg: "Rate", type: 2 },
            ];

            if (inwardLineItems[i].itemGroup === "997") {
              validObj = false;
            } else {
              if (inwardLineItems.length === 1) {
                validObj = LineItemsValidtions(validateFields);
                if (validObj) {
                  break;
                }
              } else {
                if (inwardLineItems[i] !== lastRow) {
                  validObj = LineItemsValidtions(validateFields);
                  if (validObj) {
                    break;
                  }
                }
              }
            }
          }
        }
      } else if (
        this.props.location.pathname === "/create_material_inspection"
      ) {
        if (inwardLineItems[i].rejectedQty > 0) {
          if (
            inwardLineItems[i].reason === null ||
            inwardLineItems[i].reason === ""
          ) {
            swal("Please Enter the Resaon");
            return false;
          }
        }
      } else if (this.props.location.pathname === "/create_WorkReport") {
        if (this.state.source === "WITHOUTWO") {
          const validateFields = [
            {
              field: inwardLineItems[i].departmentId,
              msg: "Department",
              type: 2,
            },
            {
              field: inwardLineItems[i].budgetHeadId,
              msg: "Budget Head",
              type: 2,
            },
            {
              field: inwardLineItems[i].itemGroupId,
              msg: "Item Group",
              type: 2,
            },
            { field: inwardLineItems[i].itemId, msg: "Item", type: 2 },
            { field: inwardLineItems[i].inwardQty, msg: "Quantity", type: 2 },
            { field: inwardLineItems[i].rate, msg: "Rate", type: 2 },
          ];

          const validateFieldsOtherCharges = [
            { field: inwardLineItems[i].itemId, msg: "Item", type: 2 },
          ];

          if (inwardLineItems[i].itemGroupId === 997) {
            if (inwardLineItems.length === 1) {
              validObj = LineItemsValidtions(validateFieldsOtherCharges);
              if (validObj) {
                break;
              }
            } else {
              if (inwardLineItems[i] !== lastRow) {
                validObj = LineItemsValidtions(validateFieldsOtherCharges);
                if (validObj) {
                  break;
                }
              }
            }
          } else if (inwardLineItems.length === 1) {
            validObj = LineItemsValidtions(validateFields);
            if (validObj) {
              break;
            }
          } else {
            if (inwardLineItems[i] !== lastRow) {
              validObj = LineItemsValidtions(validateFields);
              if (validObj) {
                break;
              }
            }
          }
        } else {
          if (this.state.source === "WITHWO") {
            const validateFields = [
              { field: inwardLineItems[i].inwardQty, msg: "Quantity", type: 2 },
              { field: inwardLineItems[i].rate, msg: "Rate", type: 2 },
            ];
            if (inwardLineItems.length === 1) {
              validObj = LineItemsValidtions(validateFields);
              if (validObj) {
                break;
              }
            } else {
              if (inwardLineItems[i] !== lastRow) {
                validObj = LineItemsValidtions(validateFields);
                if (validObj) {
                  break;
                }
              }
            }
          }
        }
      } else if (this.props.location.pathname === "/Create_store_receipt") {
        console.log('inwardLineItems[i].warehouseno',inwardLineItems[i].warehouseno)
        const validateFields = [
          { field: inwardLineItems[i].warehouseno, msg: "Store No", type: 2 },
        ];

        validObj = LineItemsValidtions(validateFields);
        if (validObj) {
          break;
        }

        //Commented this line because it is not checking the Last line item validation 

        // if (inwardLineItems.length === 1) {
        //   validObj = LineItemsValidtions(validateFields);
        //   if (validObj) {
        //     break;
        //   }
        // } 
        // else {
        //   if (inwardLineItems[i] !== lastRow) {
        //     validObj = LineItemsValidtions(validateFields);
        //     if (validObj) {
        //       break;
        //     }
        //   }
        // }
      } else if (this.props.location.pathname === "/create_store_bill_pass") {
        if (
          this.props.filesofInvoice ||
          this.props.filesofInvoice !== undefined
        ) {
          if (this.props.filesofInvoice.data) {
            let filesList = this.props.filesofInvoice.data;
            if (filesList.data.length <= 0) {
              swal("Please Upload The Invoice Document!");
              return false;
            } else {
              if (this.state.InwardFillDetails.invoiceNumber === "") {
                swal("Please Enter The Invoice Number..!");
                return false;
              }
              if (
                this.state.InwardFillDetails.invoiceDate === "" ||
                this.state.InwardFillDetails.invoiceDate === null
              ) {
                swal("Please Enter The Invoice Date..!");
                return false;
              }
              if (this.state.InwardFillDetails.invoiceAmount === "") {
                swal("Please Enter The Invoice Amount..!");
                return false;
              }
              if (
                this.state.InwardFillDetails.invoiceRecvdDate === "" ||
                this.state.InwardFillDetails.invoiceRecvdDate === null
              ) {
                swal("Please Enter The Invoice Recived_Date..!");
                return false;
              }
              // Commenting the tds logic for future use

              // if (
              //   this.state.InwardFillDetails.tdsPayable === "" ||
              //   this.state.InwardFillDetails.tdsPayable == null
              // ) {
              //   swal("Please Select The Invoice TDS Payable..!");
              //   return false;
              // } else if (this.state.InwardFillDetails.tdsPayable === "Y") {
              //   if (
              //     this.state.InwardFillDetails.tdsAmount === "" ||
              //     this.state.InwardFillDetails.tdsAmount == null
              //   ) {
              //     swal("Please Enter The Invoice TDS Amount..!");
              //     return false;
              //   }
              // }
            }
            if (
              Number(this.state.InwardFillDetails.invoiceAmount) !==
              this.state.InwardFillDetails.netTotal
            ) {
              swal("The net total and invoice amount should be equal!!");
            } else {
              this.setState({
                currentStep: this.state.currentStep + 1,
              });
            }
          }
        } else {
          swal("Please Upload The Invoice Document!");
          return false;
        }
      }
    }
    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message}`);
      }
      if (validObj.type === 2) {
        swal(`Please Select ${validObj.message}`);
      }
    } else {
      this.setState({
        currentStep: currentStep + 1,
      });
    }
  };

  handleAlignment = (
    event,
    newAlignment
  ) => {
    this.setState({
      alignment: newAlignment,
    });
    if (newAlignment !== null) {
      if (newAlignment === "1") {
        this.setState({
          source: "WITHPO",
          POType: 1,
          poSourceType: 1,
        });
      } else if (newAlignment === "2") {
        this.setState({
          source: "WITHOUTPO",
          POType: 2,
          poSourceType: 2,
        });
      } else if (newAlignment === "3") {
        this.setState({
          source: "WITHWO",
          POType: 3,
          poSourceType: 3,
        });
      } else {
        this.setState({
          source: "WITHOUTWO",
          POType: 4,
          poSourceType: 4,
        });
      }
    } else {
      this.setState({
        POType: null,
      });
    }
  };

  onCreateRecord = () => {
    if (this.props.Inward_LineItems) {
      this.setState({
        Inward_LineItems: this.props.Inward_LineItems,
      });
    }
    const InwardFillDetails = this.state.InwardFillDetails;
    let FilterInward_LineItems = this.state.Inward_LineItems.filter((item) => {
      if (
        (this.state.source === "WITHOUTPO" ||
          this.state.source === "WITHOUTWO") &&
        Number(item.itemGroupId) !== null &&
        Number(item.itemGroupId) === 997
      ) {
        if (item.isActive !== 0) {
          return item;
        }
      } else {
        if (item.departmentId !== "" && item.isActive !== 0) {
          return item;
        }
      }
    });

    if (this.state.locationState.state !== undefined) {
      FilterInward_LineItems.sort((a, b) =>
        a.lineItemId > b.lineItemId ? -1 : 1
      );
    }

    const Inward_LineItems = FilterInward_LineItems.map((item) => {
      if (this.state.source === "WITHPO" || this.state.source === "WITHWO") {
        return {
          budgetHeadId: item.budgetHeadId === null ? "null" : item.budgetHeadId,
          cgstPercentage:
            Number(InwardFillDetails.taxType) === 2
              ? Number(item.taxPercentage) / 2
              : 0,
          departmentId: item.departmentId,
          description: item.description,
          make : item.make, 
          remarks : item.remarks,
          hdrStatus: 1,
          hsnCode: item.hsnCode,
          igstPercentage:
            Number(InwardFillDetails.taxType) === 1
              ? this.state.source === "WITHPO"
                ? Number(item.igstPercentage)
                : item.igstPercentage.toString()
              : 0,
          // discount: item.discountPercentage,
          // discountMode: item.discountMode,
          discount:
            item.discountPercentage === ""
              ? 0.0
              : Number(item.discountPercentage).toFixed(2),
          discountMode:
            item.discountMode === "" || item.discountMode === null
              ? 0
              : item.discountMode,
          discountedRate: limitDecimals(Number(item.rate) * Number(item.inwardQty)-Number(item.discountPercentage),2),
          discountedAmount:limitDecimals(Number(item.rate) * Number(item.inwardQty)-Number(item.discountPercentage),2),
          inwardQty: item.inwardQty,
          installationAmount: limitDecimals(Number(item.rate) * Number(item.inwardQty),2),
          installationRate:
            item.installationRate === null ? "" : item.installationRate,
          isActive: item.isActive,
          itemGroupId: item.itemGroupId,
          poDetailsId: item.poDetailsId,
          poId: item.poId,
          itemId: Number(item.itemId),
          oldQty: item.oldQty === null ? 0 : item.oldQty,
          rate:
            this.state.source === "WITHPO" ? item.rate : item.rate.toString(),
          sgstPercentage:
            InwardFillDetails.taxType === 2
              ? Number(item.taxPercentage) / 2
              : 0,
          tax: item.tax,
          taxPercentage: Number(item.taxPercentage),
          uom: item.uomCode,
        };
      } else {
        return {
          budgetHeadId: item.budgetHeadId === null ? "null" : item.budgetHeadId,
          cgstPercentage:
            Number(InwardFillDetails.taxType) === 2
              ? Number(item.taxPercentage) / 2
              : 0,
          departmentId: item.departmentId,
          description: item.remarks,
          hdrStatus: 1,
          hsnCode: item.hsnCode,
          igstPercentage:
            Number(InwardFillDetails.taxType) === 1
              ? this.state.source === "WITHPO"
                ? Number(item.igstPercentage)
                : item.igstPercentage.toString()
              : 0,
          // discount:
          //   item.discountPercentage !== undefined
          //     ? item.discountPercentage
          //     : null,
          // discountMode:
          //   item.discountMode !== undefined ? item.discountMode : null,

          discount:
            item.discountPercentage === ""
              ? 0.0
              : Number(item.discountPercentage).toFixed(2),
          discountMode:
            item.discountMode === "" || item.discountMode === null
              ? 0
              : item.discountMode,
           discountedRate: item.discountedRate,
           discountedAmount: item.discountedAmount,
          make:item.make,
          remarks:item.remarks,
          inwardQty: item.inwardQty,
          isActive: item.isActive,
          itemGroupId: item.itemGroupId,
          itemId: Number(item.itemId),
          oldQty: item.oldQty === null ? 0 : item.oldQty,
          rate:
            this.state.source === "WITHPO" ? item.rate : item.rate.toString(),
          sgstPercentage:
            Number(InwardFillDetails.taxType) === 2
              ? Number(item.taxPercentage) / 2
              : 0,
          tax: item.tax,
          taxPercentage:
            Number(InwardFillDetails.taxType) !== 4
              ? Number(item.taxPercentage)
              : 0,
          uom: item.uomCode,
        };
      }
    });
    //payload to create Inward
    const inwardData = {
      inwardDate: moment(InwardFillDetails.inwardDate).format("DD-MM-YYYY"),
      challanno: InwardFillDetails.challanno,
      challannoDate: InwardFillDetails.challannoDate
        ? moment(InwardFillDetails.challannoDate).format("DD-MM-YYYY")
        : "",
      branchId: InwardFillDetails.branchId,
      projectId: InwardFillDetails.projectId,
      supplierId:
        this.state.userDit.roleId === 100
          ? this.state.userDit.suppCode
          : InwardFillDetails.supplierId,
      source: this.state.source,
      recordType:
        this.props.location.pathname === "/create_inward"
          ? "GRN"
          : this.props.location.pathname === "/create_WorkReport"
          ? "SRN"
          : this.props.location.pathname === "/Create_store_receipt"
          ? "SR"
          : this.props.location.pathname === "/create_store_bill_pass"
          ? "BP"
          : this.props.location.pathname === "/create_material_inspection"
          ? "GRN"
          : "",
      companyId: localStorage.getItem("companyId"),
      internalNote: InwardFillDetails.internalNote,
      createdBy: this.state.userDit.userId.toString(),
      isCommitted: false,
      inwardDetails: Inward_LineItems,
      consignmentNumber: InwardFillDetails.consignmentNumber,
      consignmentDate: InwardFillDetails.consignmentDate
        ? moment(InwardFillDetails.consignmentDate).format("DD-MM-YYYY")
        : "",
      vehicleNumber: InwardFillDetails.vehicleNumber,
      driverContactNumber: InwardFillDetails.driverName,
      remarks: InwardFillDetails.remarks,
      taxType: InwardFillDetails.taxType,
      taxTypeDesc: InwardFillDetails.taxTypeDesc,
      invoiceNumber: InwardFillDetails.invoiceNumber,
      invoiceDate: InwardFillDetails.invoiceDate
        ? moment(InwardFillDetails.invoiceDate).format("DD-MM-YYYY")
        : "",
      ewaybillno: InwardFillDetails.ewaybillno,
      ewaybillnoDate: InwardFillDetails.ewaybillnoDate
        ? moment(InwardFillDetails.ewaybillnoDate).format("DD-MM-YYYY")
        : "",
      customerId: InwardFillDetails.customerId,
      status:
        this.state.userDit.roleId === 100
          ? "36"
          : InwardFillDetails.status === ""
          ? "1"
          : InwardFillDetails.status,
      totalAmount: limitDecimals(InwardFillDetails.netTotal,2),
      netTotal: limitDecimals(InwardFillDetails.totalAmount,2),
      make:InwardFillDetails.make,
      discountedAmount: limitDecimals(InwardFillDetails.discountedAmount,2),
      discountedRate: limitDecimals(InwardFillDetails.discountedRate,2),
      // status: InwardFillDetails.status === "" ? "1" : InwardFillDetails.status,
    };
    //payload to create work report
    const workReportData = {
      inwardDate: moment(InwardFillDetails.inwardDate).format("DD-MM-YYYY"),
      challanno: InwardFillDetails.challanno,
      challannoDate: InwardFillDetails.challannoDate
        ? moment(InwardFillDetails.challannoDate).format("DD-MM-YYYY")
        : "",
      branchId: InwardFillDetails.branchId,
      projectId: InwardFillDetails.projectId,
      supplierId:
        this.state.userDit.roleId === 100
          ? this.state.userDit.suppCode
          : InwardFillDetails.supplierId,
      source: this.state.source,
      recordType:
        this.props.location.pathname === "/create_inward"
          ? "GRN"
          : this.props.location.pathname === "/create_WorkReport"
          ? "SRN"
          : this.props.location.pathname === "/Create_store_receipt"
          ? "SR"
          : this.props.location.pathname === "/create_store_bill_pass"
          ? "BP"
          : this.props.location.pathname === "/create_material_inspection"
          ? "GRN"
          : "",
      companyId: localStorage.getItem("companyId"),
      internalNote: InwardFillDetails.internalNote,
      createdBy: this.state.userDit.userId.toString(),
      isCommitted: false,
      inwardDetails: Inward_LineItems,
      consignmentNumber: InwardFillDetails.consignmentNumber,
      creditnoteValue: null,
      consignmentDate: InwardFillDetails.consignmentDate
        ? moment(InwardFillDetails.consignmentDate).format("DD-MM-YYYY")
        : "",
      vehicleNumber: InwardFillDetails.vehicleNumber,
      driverContactNumber: InwardFillDetails.driverName,
      remarks: InwardFillDetails.remarks,
      taxType: InwardFillDetails.taxType,
      taxTypeDesc: InwardFillDetails.taxTypeDesc,
      invoiceNumber: InwardFillDetails.invoiceNumber,
      invoiceDate: InwardFillDetails.invoiceDate
        ? moment(InwardFillDetails.invoiceDate).format("DD-MM-YYYY")
        : "",
      ewaybillno: InwardFillDetails.ewaybillno,
      ewaybillnoDate: InwardFillDetails.ewaybillnoDate
        ? moment(InwardFillDetails.ewaybillnoDate).format("DD-MM-YYYY")
        : "",
      customerId: InwardFillDetails.customerId,
      status:
        this.state.userDit.roleId === 100
          ? "21"
          : InwardFillDetails.status === ""
          ? "1"
          : InwardFillDetails.status, /////logic for vendor login ,
      // status: InwardFillDetails.status === "" ? "1" : InwardFillDetails.status,
      netTotal: InwardFillDetails.netTotal,
      totalAmount: InwardFillDetails.totalAmount,
    };

    if (this.props.location.pathname === "/create_inward") {
      let url = "/security_store_gate_entry";
      // if (inwardData !==undefined) {
      this.props.CreateInward(
        serverApi.CREATE_INWARD,
        inwardData,
        this.props.history,
        url
      );
      // }
    } else if (this.props.location.pathname === "/create_WorkReport") {
      let url = "/workReport";
      this.props.CreateInward(
        serverApi.CREATE_INWARD,
        workReportData,
        this.props.history,
        url
      );
    }
  };

  onUpdateInward = (Status) => {
    if (this.props.Inward_LineItems) {
      this.setState({
        Inward_LineItems: this.props.Inward_LineItems,
      });
    }
    const InwardFillDetails = this.state.InwardFillDetails;

    const FilterInward_LineItems = this.state.Inward_LineItems.filter(
      (item) => {
        if (
          this.state.source === "WITHOUTPO" &&
          Number(item.itemGroupId) !== null &&
          Number(item.itemGroupId) === 997
        ) {
          return item;
        } else {
          if (item.departmentId !== "") {
            return item;
          }
        }
      }
    );

    const Inward_LineItems = FilterInward_LineItems.map((item) => {
      if (
        this.props.location.pathname === "/create_material_inspection" ||
        this.props.location.pathname === "/create_WorkReport"
      ) {
        return {
          approvedQty:
            item.itemGroup === "997"
              ? 1
              : item.approvedQty === undefined || item.approvedQty === null
              ? item.inwardQty
              : item.approvedQty,
          budgetHeadId:
            item.budgetHeadId !== null
              ? item.budgetHeadId.toString()
              : item.budgetHeadId,
          departmentId: item.departmentId,
          description: item.description,
          // discount: item.discount !== undefined ? item.discount : null,
          // discountMode:
          //   item.discountMode !== undefined ? item.discountMode : null,

          discount:
            item.discountPercentage === ""
              ? 0.0
              : Number(item.discountPercentage).toFixed(2),
          discountedRate: item.discountedRate,
          discountedAmount: item.discountedAmount,
          discountMode:
            item.discountMode === "" || item.discountMode === null
              ? 0
              : item.discountMode,

          hsnCode: item.hsnCode,
          id: item.lineItemId,
          inwardId: item.inwardId,
          inwardQty: item.inwardQty,
          itemGroupId: item.itemGroup,
          itemId: item?.itemId?.toString(),
          oldQty: item.inwardQty,
          poId: item.poId,
          rate: item.rate,
          reason: item.reason,
          rejectedQty: item.rejectedQty === null ? 0 : item.rejectedQty,
          status: item.status,
          uom: item.uomCode ? item.uomCode : item.uom,
          cgstPercentage: item.cgstPercentage,
          igstPercentage: item.igstPercentage,
          poDetailsId: item.poDetailsId,
          sgstPercentage: item.sgstPercentage,
          tax: item.tax,
          taxPercentage: item.taxPercentage,
          isActive: item.isActive,
          make: item.make,
          remarks: item.remarks,
          discountedRate: item.discountedRate,
          discountedAmount: item.discountedAmount,
        };
      } else {
        console.log(item, "item.itemId")
        return {
          id: item.lineItemId,
          budgetHeadId: item.budgetHeadId,
          cgstPercentage: item.cgstPercentage,
          departmentId: item.departmentId,
          description: item.description,
          // hdrStatus: item.hdrStatus,

          // discount:
          //   item.discountPercentage !== undefined
          //     ? item.discountPercentage
          //     : item.discount,
          // discountMode:
          //   item.discountMode !== undefined ? item.discountMode : null,

          discount:
            item.discountPercentage === ""
              ? 0.0
              : Number(item.discountPercentage).toFixed(2),
          discountMode:
            item.discountMode === "" || item.discountMode === null
              ? 0
              : item.discountMode,
          discountedRate: item.discountedRate,
          discountedAmount: item.discountedAmount,
          remarks:item.remarks,
          make: item.make,
          igstPercentage: item.igstPercentage,
          inwardQty: item.inwardQty,
          isActive: item.isActive,
          itemGroupId: item.itemGroup,
          itemId: item?.itemId?.toString(),
          oldQty: item.inwardQty,
          poId: item.poId,
          rate: item.rate,
          sgstPercentage: item.sgstPercentage,
          tax: item.tax,
          taxPercentage: item.taxPercentage,
          uom: item.uomCode ? item.uomCode : item.uom,
          poDetailsId: item.poDetailsId,
          hsnCode: item.hsnCode,
          
        };
      }
    });
    // toUpdate the inward
    let inwardUpdateData = {
      id: this.state.locationState.state.rowData.id,
      inwardDate: moment(InwardFillDetails.inwardDate).format("DD-MM-YYYY"),
      challanno: InwardFillDetails.challanno,
      challannoDate: moment(InwardFillDetails.challannoDate).format(
        "DD-MM-YYYY"
      ),
      branchId: InwardFillDetails.branchId,
      projectId: InwardFillDetails.projectId,
      supplierId:
        this.state.userDit.roleId === 100
          ? this.state.userDit.suppCode
          : InwardFillDetails.supplierId,
      source: this.state.source,
      recordType:
        this.props.location.pathname === "/create_inward"
          ? "GRN"
          : this.props.location.pathname === "/create_WorkReport"
          ? "SRN"
          : this.props.location.pathname === "/Create_store_receipt"
          ? "SR"
          : this.props.location.pathname === "/create_store_bill_pass"
          ? "BP"
          : this.props.location.pathname === "/create_material_inspection"
          ? "GRN"
          : "",
      companyId: localStorage.getItem("companyId"),
      internalNote: InwardFillDetails.internalNote,
      lastModifiedBy: this.state.userDit.userId.toString(),
      inwardSequenceNo: this.state.locationState.state.rowData.inwardSequenceNo,
      isCommitted: false,
      inwardDetails: Inward_LineItems,
      consignmentNumber: InwardFillDetails.consignmentNumber,
      consignmentDate: moment(InwardFillDetails.consignmentDate).format(
        "DD-MM-YYYY"
      ),
      vehicleNumber: InwardFillDetails.vehicleNumber,
      driverContactNumber: InwardFillDetails.driverName,
      remarks: InwardFillDetails.remarks,
      taxType: InwardFillDetails.taxType,
      taxTypeDesc: InwardFillDetails.taxTypeDesc,
      invoiceNumber: InwardFillDetails.invoiceNumber,
      invoiceDate: moment(InwardFillDetails.invoiceDate).format(
        "DD-MM-YYYY"
      ),
      ewaybillno: InwardFillDetails.ewaybillno,
      ewaybillnoDate: moment(InwardFillDetails.ewaybillnoDate).format(
        "DD-MM-YYYY"
      ),
      customerId: InwardFillDetails.customerId,
      status: "",
      acceptStatus: "N",
      creditnoteValue: null,
      totalAmount: InwardFillDetails.netTotal,
      netTotal: InwardFillDetails.totalAmount,
      make:InwardFillDetails.make,
      remarks:InwardFillDetails.remarks,
      discountedRate:InwardFillDetails.discountedRate,
      discountedAmount:InwardFillDetails.discountedAmount,
    };

    // payload to update the workReport
    let workReportData = {
      id: this.state.locationState.state.rowData.id,
      inwardDate: moment(InwardFillDetails.inwardDate).format("DD-MM-YYYY"),
      challanno: InwardFillDetails.challanno,
      challannoDate: moment(InwardFillDetails.challannoDate).format(
        "DD-MM-YYYY"
      ),
      branchId: InwardFillDetails.branchId,
      projectId: InwardFillDetails.projectId,
      supplierId:
        this.state.userDit.roleId === 100
          ? this.state.userDit.suppCode
          : InwardFillDetails.supplierId.toString(),
      source: this.state.source,
      recordType:
        this.props.location.pathname === "/create_inward"
          ? "GRN"
          : this.props.location.pathname === "/create_WorkReport"
          ? "SRN"
          : this.props.location.pathname === "/Create_store_receipt"
          ? "SR"
          : this.props.location.pathname === "/create_store_bill_pass"
          ? "BP"
          : this.props.location.pathname === "/create_material_inspection"
          ? "GRN"
          : "",

      companyId: localStorage.getItem("companyId"),
      internalNote: InwardFillDetails.internalNote,
      lastModifiedBy: this.state.userDit.userId.toString(),
      inwardSequenceNo: this.state.locationState.state.rowData.inwardSequenceNo,
      isCommitted: false,
      inwardDetails: Inward_LineItems,
      consignmentNumber: InwardFillDetails.consignmentNumber,
      consignmentDate: moment(InwardFillDetails.consignmentDate).format(
        "DD-MM-YYYY"
      ),
      vehicleNumber: InwardFillDetails.vehicleNumber,
      driverContactNumber: InwardFillDetails.driverName,
      remarks: InwardFillDetails.remarks,
      taxType: InwardFillDetails.taxType,
      taxTypeDesc: InwardFillDetails.taxTypeDesc,
      invoiceNumber: InwardFillDetails.invoiceNumber,
      invoiceDate: moment(InwardFillDetails.invoiceDate).format("DD-MM-YYYY"),
      ewaybillno: InwardFillDetails.ewaybillno,
      ewaybillnoDate: moment(InwardFillDetails.ewaybillnoDate).format(
        "DD-MM-YYYY"
      ),
      customerId: InwardFillDetails.customerId,
      status: Status,
      acceptStatus: "N",
      creditnoteValue: null,
      netTotal: InwardFillDetails.netTotal,
      totalAmount: InwardFillDetails.totalAmount,
    };

    // to update the Material request
    let MIdata = {
      id: this.state.locationState.state.rowData.id,
      inwardDate: moment(InwardFillDetails.inwardDate).format("DD-MM-YYYY"),
      challanno: InwardFillDetails.challanno,
      challannoDate: moment(InwardFillDetails.challannoDate).format(
        "DD-MM-YYYY"
      ),
      branchId: InwardFillDetails.branchId,
      projectId: InwardFillDetails.projectId,
      supplierId: InwardFillDetails.supplierId.toString(),
      source: this.state.source,
      recordType:
        this.props.location.pathname === "/create_inward"
          ? "GRN"
          : this.props.location.pathname === "/create_WorkReport"
          ? "SRN"
          : this.props.location.pathname === "/Create_store_receipt"
          ? "SR"
          : this.props.location.pathname === "/create_store_bill_pass"
          ? "BP"
          : this.props.location.pathname === "/create_material_inspection"
          ? "GRN"
          : "",
      companyId: localStorage.getItem("companyId"),
      internalNote: InwardFillDetails.internalNote,
      lastModifiedBy: this.state.userDit.userId.toString(),
      inwardSequenceNo: this.state.locationState.state.rowData.inwardSequenceNo,
      userName: this.state.userDit.userId.toString(),
      cipher: this.state.userDit.cipher,
      inwardDetails: Inward_LineItems,
      status: Status,
      storeReceiptDate: moment(InwardFillDetails.storeReceiptDate).format(
        "DD-MM-YYYY"
      ),
      totalAmount: InwardFillDetails.totalAmount,
    };

    /**
     * to update the Store receipt and to update the billpass
     * */
    let Billpassdata = {
      billpassStatusId: Status,
      id: this.state.locationState.state.rowData.id,
      invoiceNumber: InwardFillDetails.invoiceNumber,
      invoiceDate: moment(InwardFillDetails.invoiceDate, 'DD-MM-YYYY', true).isValid()
      ? InwardFillDetails.invoiceDate
      : moment(InwardFillDetails.invoiceDate).format("DD-MM-YYYY"),
      invoiceAmount: Number(InwardFillDetails.invoiceAmount),
      invoiceRecvdDate: moment(InwardFillDetails.invoiceRecvdDate).format(
        "DD-MM-YYYY"
      ),
      invoiceDueDate: moment(InwardFillDetails.paymentDueDate).format(
        "DD-MM-YYYY"
      ),
      challanno: InwardFillDetails.challanno,
      staus: InwardFillDetails.status,
      companyId: localStorage.getItem("companyId"),
      tdsPayable: InwardFillDetails.tdsPayable,
      tdsReason: InwardFillDetails.tdsReason,
      tdsAmount: InwardFillDetails.tdsAmount,
      tcsTds: InwardFillDetails.tcsTds,
      branchId: InwardFillDetails.branchId,
      tcsPercentage: InwardFillDetails.tcsPercentageValue,
      tdsPercentage: InwardFillDetails.tdsPercentageValue,
      ledger: InwardFillDetails.ledgerValue,
      tcsAmount: InwardFillDetails.tcsAmount,
      gstInvoiceType: InwardFillDetails.gstInvoiceType,
      itcApplicable: InwardFillDetails.itcApplicable,
      roundOffValue: InwardFillDetails.roundOffValue,
      lastModifiedBy: this.state.userDit.userId.toString(),
      recordType: "GRN",
      vehicleNumber: InwardFillDetails.vehicleNumber,
      inwardDetails: FilterInward_LineItems.map((item) => {
        return {
          id: item.id,
          hsnCode: item.hsnCode,
          status: item.status,
        };
      }),
    };
    if (this.props.location.pathname === "/create_inward") {
      this.props.UpdateInward(
        serverApi.UPDATE_INWARD,
        inwardUpdateData,
        this.props.history,
        "/security_store_gate_entry"
      );
    } else if (this.props.location.pathname === "/create_WorkReport") {
      this.props.UpdateInward(
        serverApi.UPDATE_INWARD,
        workReportData,
        this.props.history,
        "/workReport"
      );
    } else if (this.props.location.pathname === "/Create_store_receipt") {
      let SRdata = {
        id: this.state.locationState.state.rowData.id,
        companyId: localStorage.getItem("companyId"),
        internalNote: InwardFillDetails.internalNote,
        isCommited: false,
        remarks: InwardFillDetails.remarks,
        srStatusId: Status,
        inspectionChk: "Y",
        tcsPercentage: InwardFillDetails.tcsPercentageValue,
        ledger: InwardFillDetails.ledgerValue,
        branchId: InwardFillDetails.branchId,
        status: Status === "4" ? "4" : InwardFillDetails.status,
        recordType: "GRN",
        projectId: InwardFillDetails.projectId,
        lastModifiedBy: this.state.userDit.userId,
        roundOffValue: InwardFillDetails.roundOffValue,
        tcsAmount: InwardFillDetails.tcsAmount,
        tdsAmount: InwardFillDetails.tdsAmount,
        tdsPercentage: InwardFillDetails.tdsPercentageValue,
        tcsTds: InwardFillDetails.tcsTds,
        payableAmount: InwardFillDetails.payableAmount,
        totalAmount: InwardFillDetails.totalAmount,
        vehicleNumber: InwardFillDetails.vehicleNumber,
        challanno: InwardFillDetails.challanno,
        inwardDetails: FilterInward_LineItems.filter(item => item.isActive).map((item) => {
          return {
            id: item.lineItemId,
            inwardId: item.inwardId,
            hsnCode: item.hsnCode,
            warehouseno: item.warehouseno,
            approvedQty: item.approvedQty,
            status: item.status,
            isActive: item.isActive,
            budgetHeadId: item.budgetHeadId,
            itemId: item.itemId,
          };
        }),
      };
      let valid = false;

      let SRdataDetails = SRdata.inwardDetails.filter(
        (item) => item.isActive
      );

      SRdataDetails.map((item) => {
        if (
          item.warehouseno !== "" &&
          item.warehouseno !== null &&
          item.warehouseno !== 0
        ) {
          valid = false;
        } else {
          valid = true;
        }
      });
      if (valid === true) {
        swal("Please Enter Store No!");
      } else {
        this.props.UpdateInward(
          serverApi.UPDATE_STORE,
          SRdata,
          this.props.history,
          "/store_receipt"
        );
      }
    } else if (this.props.location.pathname === "/create_material_inspection") {
      this.props.UpdateInward(
        serverApi.UPDATE_MATERIAL_INSPECTION,
        MIdata,
        this.props.history,
        "/store_material_inspection"
      );
    }

    if (this.props.location.pathname === "/create_store_bill_pass") {
      if (this.props.filesofInvoice.data) {
        let filesList = this.props.filesofInvoice.data;
        if (filesList.data.length > 0) {
          if (
            filesList.data.length > 0 &&
            this.state.InwardFillDetails.invoiceNumber !== "" &&
            this.state.InwardFillDetails.invoiceDate !== "" &&
            this.state.InwardFillDetails.invoiceAmount !== "" &&
            this.state.InwardFillDetails.invoiceRecvdDate !== ""
          ) {
            if (
              Number(this.state.InwardFillDetails.invoiceAmount) !==
              this.state.InwardFillDetails.netTotal
            ) {
              swal("The net total and invoice amount should be equal!!");
            } else {
              this.props.UpdateInward(
                serverApi.UPDATE_STORE,
                Billpassdata,
                this.props.history,
                "/store_bill_pass"
              );
            }
          } else {
            swal("Please select all the mandatory fields");
          }
        } else {
          swal("Please Upload Invoice File in the Lineitems");
        }
      }
    }
  };

  onClickApprove = (Status) => {
    swal({
      title: "Are you sure?",
      text: "Want to Approve",
      icon: "warning",
      buttons: true,
      // dangerMode: true,
    }).then((WillApprove) => {
      if (WillApprove) {
        this.onUpdateInward(Status);
      }
    });
  };

  onClickReject = (Status) => {
    swal({
      title: "Are you sure?",
      text: "Want to Reject",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((WillReject) => {
      if (WillReject) {
        this.onUpdateInward(Status);
      }
    });
  };
  handleCurrentStep = () => {
    this.setState({
      currentStep: 2,
    });
  };

  render() {
    const {
      steps,
      currentStep,
      showInwardFields,
      showSRFields,
      showWRFields,
      showBPFields,
      showMIFields,
    } = this.state;
    const ShowFields = {
      showInwardFields: showInwardFields,
      showWRFields: showWRFields,
      showMIFields: showMIFields,
      showSRFields: showSRFields,
      showBPFields: showBPFields,
    };

    return (
      <div>
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            <div className="iconsBlock">
              <Stepper alternativeLabel activeStep={currentStep}>
                {steps.map((prop) => (
                  <Step key={prop.title}>
                    <StepLabel
                      StepIconComponent={(Iconprops) => {
                        return this.QontoStepIcon(
                          Iconprops,
                          prop.icon,
                          prop.activeIcon,
                          prop.title
                        );
                      }}
                    ></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {currentStep === 1 && (
              <>
                <div>
                  <div className="consoleFormButtonBlock">
                    <h4>
                      How do you want to create the{" "}
                      {this.props.location.pathname === "/create_WorkReport"
                        ? "Work Report"
                        : this.state.userDit.roleId === 100
                        ? "Dispatch ?"
                        : "Inward Entry ?"}
                    </h4>
                  </div>
                  <div className="consoleFormButtonBlock">
                    <ToggleButtonGroup
                      value={this.state.alignment}
                      exclusive
                      onChange={this.handleAlignment}
                      aria-label="text alignment"
                      className="btnToggleforIndent"
                    >
                      {this.state.pathname === "PO" ? (
                        <ToggleButton value="1" aria-label="centered">
                          <span>With PO</span>
                        </ToggleButton>
                      ) : (
                        ""
                      )}
                      {this.state.pathname === "PO" &&
                      this.state.showPoButton ? (
                        this.state.userDit.roleId === 100 ? (
                          ""
                        ) : (
                          <ToggleButton value="2" aria-label="centered">
                            <span>Without PO</span>
                          </ToggleButton>
                        )
                      ) : (
                        ""
                      )}
                      {this.state.pathname === "WO" ? (
                        <ToggleButton value="3" aria-label="centered">
                          <span>With WO</span>
                        </ToggleButton>
                      ) : (
                        ""
                      )}
                      {this.state.pathname === "WO" &&
                      this.state.showPoButton ? (
                        <ToggleButton value="4" aria-label="centered">
                          <span>Without WO</span>
                        </ToggleButton>
                      ) : (
                        ""
                      )}
                    </ToggleButtonGroup>
                  </div>
                </div>
                <div className="consoleFormButtonBlock">
                  {this.state.POType === 0 || this.state.POType === null ? (
                    <CustomButton
                      label={"Next"}
                      className="disabled"
                      handleClick={this.onClicknextBtn}
                      type="sumbit"
                      disabled={true}
                    />
                  ) : (
                    <CustomButton
                      label={"Next"}
                      className="greenButton"
                      handleClick={() => {
                        this.onClicknextBtn();
                      }}
                      type="sumbit"
                      disabled={false}
                    />
                  )}
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                <InwardFillDetail
                  InwardFillDetails={this.state.InwardFillDetails}
                  source={this.state.source}
                  ShowFields={ShowFields}
                  locationState={this.state.locationState}
                />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={this.onClickCancel}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="greenButton"
                    handleClick={() => {
                      this.onClicknextBtn();
                    }}
                    type="sumbit"
                  />
                </div>
              </>
            )}
            {currentStep === 3 && (
              <>
                {this.state.locationState.pathname ===
                "/create_material_inspection" ? (
                  <MaterialInspecLineItems
                    InwardFillDetails={this.state.InwardFillDetails}
                    locationState={this.state.locationState}
                  />
                ) : this.state.locationState.pathname ===
                  "/Create_store_receipt" ? (
                  <StoreRecepitLineItems
                    InwardFillDetails={this.state.InwardFillDetails}
                    locationState={this.state.locationState}
                  />
                ) : this.state.locationState.pathname ===
                  "/create_store_bill_pass" ? (
                  <BillPassLineItems
                    InwardFillDetails={this.state.InwardFillDetails}
                    locationState={this.state.locationState}
                  />
                ) : this.state.locationState.pathname ===
                    "/create_WorkReport" &&
                  this.state.locationState.state !== undefined ? (
                  <WorkReportLineitems
                    InwardFillDetails={this.state.InwardFillDetails}
                    source={this.state.source}
                    locationState={this.state.locationState}
                  />
                ) : (
                  <InwardLineItem
                    InwardFillDetails={this.state.InwardFillDetails}
                    source={this.state.source}
                    locationState={this.state.locationState}
                  />
                )}
                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Back"}
                    className="greenBorderButton"
                    handleClick={this.onClickCancel}
                    type="sumbit"
                  />
                  <CustomButton
                    label={"Next"}
                    className="greenButton"
                    handleClick={this.onClicknextBtn}
                    type="sumbit"
                  />
                </div>
              </>
            )}
            {currentStep === 4 && (
              <>
                {this.state.locationState.pathname ===
                "/create_material_inspection" ? (
                  <MIPreview
                    InwardFillDetails={this.state.InwardFillDetails}
                    handleCurrentStep={this.handleCurrentStep}
                    locationState={this.state.locationState}
                  />
                ) : this.state.locationState.pathname === "/create_inward" ? (
                  <InwardPreview
                    InwardFillDetails={this.state.InwardFillDetails}
                    handleCurrentStep={this.handleCurrentStep}
                    source={this.state.source}
                    locationState={this.state.locationState}
                  />
                ) : this.state.locationState.pathname ===
                  "/Create_store_receipt" ? (
                  <StoreReciptPreview
                    InwardFillDetails={this.state.InwardFillDetails}
                    handleCurrentStep={this.handleCurrentStep}
                    locationState={this.state.locationState}
                  />
                ) : this.state.locationState.pathname ===
                  "/create_store_bill_pass" ? (
                  <BillPassPreview
                    InwardFillDetails={this.state.InwardFillDetails}
                    handleCurrentStep={this.handleCurrentStep}
                    locationState={this.state.locationState}
                  />
                ) : this.state.locationState.pathname ===
                  "/create_WorkReport" ? (
                  <WorkReportPreview
                    InwardFillDetails={this.state.InwardFillDetails}
                    handleCurrentStep={this.handleCurrentStep}
                    source={this.state.source}
                    locationState={this.state.locationState}
                  />
                ) : (
                  ""
                )}
                <div className="consoleFormButtonBlock">
                  {this.state.locationState.pathname === "/create_inward" &&
                    this.state.locationState.state === undefined && (
                      <>
                        <CustomButton
                          label={"Back"}
                          className="greenBorderButton"
                          handleClick={this.onClickCancel}
                          type="sumbit"
                        />
                        <CustomButton
                          label={"Cancel"}
                          className="greenBorderButton"
                          handleClick={() => {
                            this.props.history.push(
                              "/security_store_gate_entry"
                            );
                          }}
                          type="sumbit"
                        />
                        <CustomButton
                          label={"Create"}
                          className="greenButton"
                          handleClick={() => {
                            this.onCreateRecord();
                          }}
                        />
                      </>
                    )}
                  {this.state.locationState.pathname === "/create_inward" &&
                    this.state.locationState.state !== undefined &&
                    this.state.InwardFillDetails.statusName !== "APPROVED" &&
                    this.state.InwardFillDetails.statusName !== "REJECTED" && (
                      <>
                        <CustomButton
                          label={"Cancel"}
                          className="greenBorderButton"
                          handleClick={() => {
                            this.props.history.push(
                              "/security_store_gate_entry"
                            );
                          }}
                          type="sumbit"
                        />
                        <CustomButton
                          label={"Update"}
                          className="greenButton"
                          handleClick={() => {
                            this.onUpdateInward("");
                          }}
                        />
                      </>
                    )}
                  {this.state.locationState.pathname ===
                    "/create_WorkReport" && (
                    <>
                      {this.state.locationState.state === undefined && (
                        <>
                          <CustomButton
                            label={"Back"}
                            className="greenBorderButton"
                            handleClick={this.onClickCancel}
                            type="sumbit"
                          />
                          <CustomButton
                            label={"Cancel"}
                            className="greenBorderButton"
                            handleClick={() => {
                              this.props.history.push("/workReport");
                            }}
                            type="sumbit"
                          />
                          <CustomButton
                            label={"Create"}
                            className="greenButton"
                            handleClick={() => {
                              this.onCreateRecord();
                            }}
                          />
                        </>
                      )}
                      {this.state.locationState.state !== undefined &&
                        this.state.ApproveButton === true && (
                          <>
                            <CustomButton
                              label={"Cancel"}
                              className="greenBorderButton"
                              handleClick={() => {
                                this.props.history.push("/workReport");
                              }}
                              type="sumbit"
                            />
                            {/* workReport update */}
                            <CustomButton
                              label={"Update"}
                              className="greenButton"
                              handleClick={() => {
                                this.onUpdateInward("");
                              }}
                            />

                            <CustomButton
                              label={"Reject"}
                              className="rejected"
                              handleClick={() => {
                                this.onClickReject("4");
                              }}
                              type="sumbit"
                            />
                            <CustomButton
                              label={"Approve"}
                              className="approved"
                              handleClick={() => {
                                this.onClickApprove(
                                  this.state.InwardFillDetails.status
                                );
                              }}
                            />
                          </>
                        )}
                      {/* for vendor login */}
                      {this.state.locationState.state !== undefined &&
                        this.state.userDit.roleId === 100 && (
                          <>
                            <CustomButton
                              label={"Cancel"}
                              className="greenBorderButton"
                              handleClick={() => {
                                this.props.history.push("/workReport");
                              }}
                              type="sumbit"
                            />
                            <CustomButton
                              label={"Update"}
                              className="greenButton"
                              handleClick={() => {
                                this.onUpdateInward("");
                              }}
                            />
                          </>
                        )}
                    </>
                  )}
                  {this.state.locationState.pathname ===
                    "/create_material_inspection" &&
                    this.state.ApproveButton === true && (
                      <>
                        <CustomButton
                          label={"Cancel"}
                          className="greenBorderButton"
                          handleClick={() => {
                            this.props.history.push(
                              "/store_material_inspection"
                            );
                          }}
                          type="sumbit"
                        />

                        <CustomButton
                          label={"Update"}
                          className="greenButton"
                          handleClick={() => {
                            this.onUpdateInward("");
                          }}
                        />
                        <CustomButton
                          label={"Approve"}
                          className="approved"
                          handleClick={() => {
                            this.onClickApprove(
                              this.state.InwardFillDetails.status
                            );
                          }}
                        />
                      </>
                    )}
                  {this.state.locationState.pathname ===
                    "/create_material_inspection" &&
                    this.state.ApproveButton === false &&
                    this.state.InwardFillDetails.statusName !== "APPROVED" && (
                      <CustomButton
                        label={"Cancel"}
                        className="greenBorderButton"
                        handleClick={() => {
                          this.props.history.push("/store_material_inspection");
                        }}
                        type="sumbit"
                      />
                    )}
                  {this.state.locationState.pathname ===
                    "/Create_store_receipt" &&
                    this.state.locationState.state !== undefined &&
                    this.state.InwardFillDetails.srStatusName !== "APPROVED" &&
                    this.state.InwardFillDetails.srStatusName !==
                      "REJECTED" && (
                      <CustomButton
                        label={"Update"}
                        className="greenButton"
                        handleClick={() => {
                          this.onUpdateInward("");
                        }}
                      />
                    )}

                  {this.state.locationState.pathname ===
                    "/Create_store_receipt" &&
                    this.state.locationState.state !== undefined &&
                    this.state.ApproveButton === true && (
                      <>
                        <CustomButton
                          label={"Reject"}
                          className="rejected"
                          handleClick={() => {
                            this.onClickReject("4");
                          }}
                          type="sumbit"
                        />
                        <CustomButton
                          label={"Approve"}
                          className="approved"
                          handleClick={() => {
                            this.onClickApprove(
                              this.state.InwardFillDetails.srStatusId
                            );
                          }}
                        />
                      </>
                    )}
                  {this.state.locationState.pathname ===
                    "/create_store_bill_pass" &&
                    this.state.locationState.state !== undefined &&
                    this.state.InwardFillDetails.bpStatusName !== "APPROVED" &&
                    this.state.InwardFillDetails.bpStatusName !==
                      "REJECTED" && (
                      <CustomButton
                        label={"Update"}
                        className="greenButton"
                        handleClick={() => {
                          this.onUpdateInward("");
                        }}
                      />
                    )}
                  {this.state.locationState.pathname ===
                    "/create_store_bill_pass" &&
                    this.state.ApproveButton === true && (
                      <>
                        <CustomButton
                          label={"Reject"}
                          className="rejected"
                          handleClick={() => {
                            this.onClickReject("4");
                          }}
                          type="sumbit"
                        />
                        <CustomButton
                          label={"Approve"}
                          className="approved"
                          handleClick={() => {
                            this.onClickApprove(
                              this.state.InwardFillDetails.billpassStatusId
                            );
                          }}
                        />
                      </>
                    )}
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Inward_LineItems } = state.InwardReducer;
  const { filesofInvoice } = state.IndentReducer;
  const { Uploaded_invoiceList } = state.UploadFileReducer;
  const { approval_hierarchy } = state.EmployeeReducer;

  return {
    Inward_LineItems,
    approval_hierarchy,
    filesofInvoice,
    Uploaded_invoiceList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    InwardLineItemsProps,
    CreateInward,
    UpdateStore,
    ApprovalHierarchy,
    UpdateInward,
  })(CreateInwardEntry)
);
