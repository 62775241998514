import {
  JUTE_BILL_PASS_LIST,
  JUTE_BILL_PASS_LIST_SUCCESSFULL,
  JUTE_BILL_PASS_VIEW_BY_ID,
  JUTE_BILL_PASS_VIEW_BY_ID_SUCCESSFULL,
  BILLPASS_LINE_ITEMS,
  GET_FILES_OF_SUPPDOCS,
  GET_FILES_OF_CHALLAN,
  GET_FILES_OF_INVOICE,
  GET_FILES_OF_BILLPASS_SUCCESSFULL,
  GET_FILES_OF_BILLPASS,
  UPDATE_JUTE_BILL_PASS,
  UPDATE_JUTE_BILL_PASS_SUCCESSFULL,
  JUTE_BILL_PASS_HEADER,
  CLEAR_JUTE_PASS_HEADER,
  CLEAR_BILLPASS_LINE_ITEMS,
  INVOICE_UPLOAD_FILES,
  INVOICE_UPLOAD_FILES_SUCCESSFULL,
  GET_INVOICE_FILES,
  GET_INVOICE_FILES_SUCCESSFULL,
  GET_SUPPORT_FILES,
  GET_SUPPORT_FILES_SUCCESSFULL,
  GET_CHALLAN_FILES,
  GET_CHALLAN_FILES_SUCCESSFULL,
  SUPPORT_UPLOAD_FILES_SUCCESSFULL,
  CHALLAN_UPLOAD_FILES_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  JuteBillPassListData: [],
  updateBillpassList: [],
  JuteBillPassViewByListData: [],
  JuteBillPassHeader: {
    hdrId: "",
    id: "",
    type: "",
    billPassNo: "",
    billDate: "",
    supplierCode: "",
    supplierName: "",
    mrNo: "",
    statusDesc: "",
    status: "",
    approveStatus: true,
    poNum: "",
    poDate: "",
    chalanNo: "",
    chalanDate: "",
    agentName: "",
    agentId: "",
    vehicleNo: "",
    grnNo: "",
    billpassDate: "",
    srNo: "",
    srMrDate: "",
    ledgerGroupDesc: "",
    srLineItems: "",
    billDocsUrl: [],
    billChallanUrl: [],
    invoiceNo: "",
    invoiceDate: "",
    paymentDueDate: "",
    invoiceAmount: "",
    invoiceRecievedDate: "",
    companyId: "",
    frieghtCharges: "",
    netAmount: "",
    claimAmount: "",
    ledgerName: "",
    srPrintNo: "",
    gateEntrySeq: "",
    branchId: "",
    tdsPayable: "",
    tdsReason: "",
    tdsAmount: "",
    projectId: "",
    internalNote: "",
    saleType: "",
    goodType: "",
    tcsPercentage: "",
    tcsAmount: "",
    mrPrintNo: "",
    roundOff: "",
    poHdrid: "",
    indentHdrid: "",
    acYear: "",
    suppliedPoValue: "",
    suppliedSrValue: "",
    supplierPaidAmount: "",
    supplierPendingPo: "",
    supplierPoval: "",
    supplierSoval: "",
    supplierPayment: "",
    pendingPoval: "",
    ledgerBalance: "",
    ledegerBal: "",
    ApproveButton: false,
    RejectButton: false,
    UpdateButton: true,
    EditButton: false,
    wareHouseList: [],
    uploadFile: false,
    files_List: [],
    supportDocsList: [],
    challandocsList: [],
    invoicedocsList: [],
    invoiceFilesList: [],
    totalAmount: "0.00",
    totalPrice: "0.00",
  },

  billpasslineItems: [
    {
      id: 1,
      itemCode: "",
      itemDesc: "",
      qualityId: "",
      qualityDesc: "",
      weight: 0,
      uom: null,
      quantity: 0,
      wareHouseNo: "",
      rate: 0,
      totalPrice: 0,
      warehouseName: "",
      claimRate: 0,
      hsnCode: "",
      premiumAmount: 0,
      waterDamageAmount: 0,
    },
  ],
  invoiceFilesList: [],
  challanFilesList: [],
  supportFilesList: [],
  invoiceFilesData: {},
  supportFilesData: {},
  challanFilesData: {},

};

const JuteBillPassReducer = (state = initialState, action) => {
  switch (action.type) {
    case JUTE_BILL_PASS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTE_BILL_PASS_LIST_SUCCESSFULL:
      state = {
        ...state,
        JuteBillPassListData: action.payload.response,
        loading: true,
      };
      break;
    case JUTE_BILL_PASS_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTE_BILL_PASS_VIEW_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        JuteBillPassViewByListData: action.payload.response,
        loading: false,
      };
      break;
    case BILLPASS_LINE_ITEMS:
      state = {
        ...state,
        billpasslineItems: action.payload,
        loading: true,
      };
      break;

    case CLEAR_BILLPASS_LINE_ITEMS:
      state = {
        ...state,
        billpasslineItems: [
          {
            id: 1,
            itemCode: "",
            itemDesc: "",
            qualityId: "",
            qualityDesc: "",
            weight: 0,
            uom: null,
            quantity: 0,
            wareHouseNo: "",
            rate: 0,
            totalPrice: 0,
            warehouseName: "",
            claimRate: 0,
            hsnCode: "",
            premiumAmount: 0,
            waterDamageAmount: 0,
          },
        ],
        loading: true,
      };
      break;

    case GET_FILES_OF_BILLPASS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_FILES_OF_BILLPASS_SUCCESSFULL:
      state = {
        ...state,
        files_List: action.payload.response,
        loading: true,
      };
      break;
    case UPDATE_JUTE_BILL_PASS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_JUTE_BILL_PASS_SUCCESSFULL:
      state = {
        ...state,
        updateBillpassList: action.payload.response,
        loading: true,
      };
      break;
    case GET_FILES_OF_INVOICE:
      state = {
        ...state,
        filesofInvoice: action.payload.response,
        loading: true,
      };
      break;
    case GET_FILES_OF_CHALLAN:
      state = {
        ...state,
        filesofChallan: action.payload.response,
        loading: true,
      };
      break;
    case GET_FILES_OF_SUPPDOCS:
      state = {
        ...state,
        filesofSuppdocs: action.payload.response,
        loading: true,
      };
      break;

    case JUTE_BILL_PASS_HEADER:
      state = {
        ...state,
        JuteBillPassHeader: action.payload,
        loading: true,
      };
      break;

    case CLEAR_JUTE_PASS_HEADER:
      state = {
        ...state,
        JuteBillPassHeader: initialState.JuteBillPassHeader,
        loading: true,
      };
      break;
    case INVOICE_UPLOAD_FILES:
      state = {
        ...state,
      };
      break;

    case INVOICE_UPLOAD_FILES_SUCCESSFULL:
      state = {
        ...state,
        invoiceFilesData: action.payload.response,
      };
      break;
    case SUPPORT_UPLOAD_FILES_SUCCESSFULL:
      state = {
        ...state,
        supportFilesData: action.payload.response,
      };
      break;
    case CHALLAN_UPLOAD_FILES_SUCCESSFULL:
      state = {
        ...state,
        challanFilesData: action.payload.response,
      };
      break;
    case GET_INVOICE_FILES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_INVOICE_FILES_SUCCESSFULL:
      console.log(action.payload.response)
      state = {
        ...state,
        invoiceFilesList: action.payload.response,
        loading: true,
      };
      break;
    case GET_SUPPORT_FILES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SUPPORT_FILES_SUCCESSFULL:
      state = {
        ...state,
        supportFilesList: action.payload.response.data,
        loading: true,
      };
      break;
    case GET_CHALLAN_FILES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CHALLAN_FILES_SUCCESSFULL:
      state = {
        ...state,
        challanFilesList: action.payload.response.data,
        loading: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default JuteBillPassReducer;
