import React, { Component } from "react";
import Select from "react-select";
import FormControl from "@mui/material/FormControl";



class DynamicSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedlable: "",
      selectedValue:"",
      selectedName:"",
    };
  }

  handleChange = (event) => {
    let selectedValue = event.value;
    let selectedName = event.name;
    let selectedlable = event.label;
    this.setState({
      selectedValue: selectedValue,
      selectedName: selectedName,
      selectedlable:selectedlable
    })
    // this.props.onSelectChange(selectedValue, selectedName,this.props.name, this.props.lineItemId);

    this.props.onSelectChange(selectedValue, selectedName,this.props.name, this.props.row, this.props.lineItemId);
  };

  componentDidMount(){
    if(this.props.arrayOfData){
      this.setState({arrayOfData:this.props.arrayOfData,}) 
      this.props.arrayOfData.filter((row) => {
        if(this.props.selected === row.value) {
          this.setState({
            selectedValue: row.value,
            selectedName: row.label,
            selectedlable:row.label
          })
        }
      })
    }
  }

  handleMenuOpen = () => {
      this.props.handleMenuOpen()
  }

  render() {
      if(this.props.arrayOfData){
        this.props.arrayOfData.map((row) => {
          if(this.props.selected === row.value) {
            this.state.selectedValue = this.props.selected
            this.state.selectedlable = row.label
            this.state.selectedName = row.label
          }
        })
      }

  
    return (
      <FormControl sx={{ width: "100%" }} className="customDropdown">
        <div>
          <label htmlFor="app-input-field">{this.props.label}</label>
          {this.props.required && <div className="mandatoryField">*</div>}
          
        </div>
                
      <Select
          classNamePrefix="react-select"
          options={this.props.arrayOfData}
          menuPosition="fixed"
          menuPlacement="auto"
          defaultValue={{ value: this.props.selected, label: this.state.selectedlable}}
          value={{ value: this.props.selected, label: this.state.selectedlable}}
          closeMenuOnScroll={() => this.props.isDropdownOpen}
          placeholder="Select"
          onChange={this.handleChange}
          onMenuOpen={this.handleMenuOpen}
          isDisabled={this.props.disabled ? true : false}

        />
      </FormControl>
    );
  }
}


export default DynamicSelect;