import { ADVANCE_REQUEST_LIST,ADVANCE_REQUEST_LIST_SUCCESSFULL,
  ADVANCE_REQUEST_VIEW_BY_ID,ADVANCE_REQUEST_VIEW_BY_ID_SUCCESSFULL,
  CREATE_ADVANCE_REQUEST,CREATE_ADVANCE_REQUEST_SUCCESSFULL, GET_WORKER_DATA, GET_WORKER_DATA_SUCCESSFULL,WORKER_DATA_CLEAR} from './actionType'

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
   advanceRequestList: [],
   create_advance_request:[],
   advance_request_view_byid:[],
   Get_worker_data:[]
 
  };
  const AdvanceRequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADVANCE_REQUEST_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case ADVANCE_REQUEST_LIST_SUCCESSFULL:
            state = {
              ...state,
              advanceRequestList: action.payload.response.data,
              loading: true,
            };
            break;
            case CREATE_ADVANCE_REQUEST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case CREATE_ADVANCE_REQUEST_SUCCESSFULL:
            state = {
              ...state,
              create_advance_request: action.payload.response.data,
              loading: true,
            };
            break;
            case ADVANCE_REQUEST_VIEW_BY_ID:
              state = {
                ...state,
                loading: true,
              };
              break;
            case ADVANCE_REQUEST_VIEW_BY_ID_SUCCESSFULL:
              state = {
                ...state,
                advance_request_view_byid: action.payload.response.data,
                loading: true,
              };
              break;
              case GET_WORKER_DATA:
                state = {
                  ...state,
                  loading: true,
                };
                break;
              case GET_WORKER_DATA_SUCCESSFULL:
                state = {
                  ...state,
                  Get_worker_data: action.payload.response.data,
                  loading: true,
                };
                break;
                case WORKER_DATA_CLEAR:
                  state = {
                    ...state,
                    Get_worker_data: action.payload,
                    loading: true,
                  };
                  break;
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default AdvanceRequestReducer;