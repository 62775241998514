import { SPINNING_DOFF_ENTRY_LIST } from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getList } from "../../../helpers/Server_Helper";
import { spinningDoffListSuccessfull } from "./actions";


function* spinningDoffEntrySaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(spinningDoffListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

export function* watchSpinningDoffEntry() {
  yield takeEvery(SPINNING_DOFF_ENTRY_LIST, spinningDoffEntrySaga);
}

function* spinningDoffSaga() {
  yield all([fork(watchSpinningDoffEntry)]);
}

export default spinningDoffSaga;
