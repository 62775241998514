import {YARN_MASTER_LIST,YARN_MASTER_LIST_SUCCESSFULL,CREATE_YARN_MASTER_SUCCESSFULL,CREATE_YARN_MASTER,
    UPDATE_YARN_MASTER,UPDATE_YARN_MASTER_SUCCESSFULL,
    API_ERRORS,} from './actionTypes';

export const yarnMasterList = (url,data,history) =>{
    return {
        type:YARN_MASTER_LIST,
        payload:{url,data,history}
    }
}
export const yarnMasterListSuccessfull = (data)=>{
    return {
        type:YARN_MASTER_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createYarnMaster = (url, data, history) => {
    return {
        type: CREATE_YARN_MASTER,
        payload: { url, data, history }
    }
}

export const createYarnMasterSuccessfull = (data) => {
    return {
        type: CREATE_YARN_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const updateYarnMaster = (url, data, history) => {
    return {
        type: UPDATE_YARN_MASTER,
        payload: { url, data, history }
    }
}

export const updateYarnMasterSuccessfull = (data) => {
    return {
        type: UPDATE_YARN_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const apiErrors = (error) => {
    return {
        type: API_ERRORS,
        payload: error,
    };
};