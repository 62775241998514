import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Grid, Box } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/JuteProduction/DynamicSelect";
import DynamicSelectNew from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";

import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { beamingCreate } from "../../../store/JuteProduction/BeamingProduction/actions";
import { getSpell } from "../../../store/HRMS/AttendanceCalender/actions";
import { beamingDdList } from "../../../store/JuteProduction/BeamingProduction/actions";
import { spinningQualityList } from "../../../store/Master/SpinningQualityMaster/actions";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import CustomButton from "../../../components/Buttons/Buttons";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import swal from "sweetalert";
import InputAdornment from "../../../components/InputAdorment/InputAdorment";
import { getTrollyDdList } from "../../../store/JuteProduction/WindingDoffEntry/actions";
import {
  wastageTypeList,
  stockwastageTypeList,
  createWastageType,
} from "../../../store/JuteProduction/WastageEntry/actions";
import { parse } from "date-fns";
import { validate } from "../../../Utilities/Validations";

class CreateWastageEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProcessMasterContent: [],
      userDit: JSON.parse(localStorage.getItem("authUser")),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      spellContent: [],
      tranDate: moment().format("YYYY-MM-DD").toString(),
      time: moment().format().split("T"),
      spinningQualityListData: [],
      BeamingDropdownData: [],
      spell: "",
      qualityCode: "",
      ends: "",
      beamMcNo: "",
      noOfCuts: "",
      noOfBeams: "",
      confirm: false,
      wastageTypeList: [],
      wastageType: "",
      trollyNo: "",
      trollyNoList: [],
      stock: 0,
      tareWeight: 0,
      netWeight: 0,
      grossWeight: 0,
    };
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getSpell(
      serverApi.SPELL +
        localStorage.getItem("companyId") +
        "/" +
        this.state.cipher,
      this.props.history
    );

    this.props.getTrollyDdList(
      serverApi.GET_TROLLY_DD_LIST_WASTAGE +
        "1" +
        "/" +
        localStorage.getItem("companyId") +
        "/" +
        this.state.cipher
    );
    this.props.wastageTypeList(
      serverApi.WASTAGE_TYPE_LIST +
        localStorage.getItem("companyId") +
        "/" +
        this.state.cipher
    );

    if (this.state.tranDate) {
      const moonLanding = new Date(this.state.tranDate + " 00:00:00").getTime();

      this.setState({
        tranDate: moonLanding,
      });
    }
  }

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    this.setState({ [name]: selectedValue });

    if (name === "wastageType") {
      const data = {
        companyId: localStorage.getItem("companyId"),
        wastageType: selectedValue,
      };
      this.props.stockwastageTypeList(
        serverApi.WASTAGE_STOCK_LIST,
        data,
        this.props.history
      );
    }

    if (name === "trollyNo") {
      let tareWeight = selectedName.split("^");

      var netWeight =
        this.state.grossWeight - Number(parseFloat(tareWeight[1]).toFixed(3));
      this.setState({
        tareWeight: Number(parseFloat(tareWeight[1]).toFixed(3)),
        netWeight: netWeight,
      });
    }
  };

  handleChange = (key) => (name, event) => {
    if (key === "wastageStock") {
      this.state.stock = event;
      this.setState({
        stock: event,
      });
    }

    if (key === "grossWeight") {
      this.state.grossWeight = event;
      let netWeight = Number(event) - this.state.tareWeight;

      this.setState({
        grossWeight: event,
        netWeight: netWeight,
      });
    }
  };

  componentWillReceiveProps(props) {
    if (props.spell) {
      if (props.spell) {
        let list = props.spell.filter((prop) => {
          if (prop.value !== "0") {
            return prop;
          }
        });
        this.setState({
          spellContent: list, // updating state name list dropdown
        });
      }
    }

    if (props.trollyDdListData) {
      if (props.trollyDdListData.data) {
        let list = props.trollyDdListData.data.filter((props) => {
          if (props.value !== 0) {
            return props;
          }
        });

        this.setState({
          trollyNoList: list,
        });
      }
    }

    if (props.wastageTypeListData) {
      if (props.wastageTypeListData.data) {
        let list = props.wastageTypeListData.data.filter((props) => {
          if (props.value !== 0) {
            return props;
          }
        });
        this.setState({
          wastageTypeList: list,
        });
      }
    }

    if (props.stockWastageList) {
      this.setState({
        stock: props.stockWastageList.data,
      });
    }

    if (props.getWastageTypeListData) {
      this.setState({
        stock: 0,
      });
    }
  }

  handleSelectDate = (e, name) => {
    if (name === "tranDate") {
      let date = e.getTime();

      this.setState({
        tranDate: date,
      });
    }
  };

  onClickCreate = () => {
    var data = {
      entryDate: this.state.tranDate,
      spell: this.state.spell,
      wastageType: this.state.wastageType,
      stock: this.state.stock,
      trollyNo: this.state.trollyNo,
      grossWeight: this.state.grossWeight,
      tareWeight: this.state.tareWeight,
      netWeight: this.state.netWeight,
      companyId: localStorage.getItem("companyId"),
      cipher: this.state.cipher,
    };

    const validateFields = [
      { field: this.state.tranDate, msg: "Date", type: 2 },
      { field: this.state.spell, msg: "Spell Name", type: 2 },
      { field: this.state.wastageType, msg: "Wastage Type", type: 2 },
      { field: this.state.stock, msg: "Stock", type: 1 },
      { field: this.state.trollyNo, msg: "Trolly No", type: 1 },
      { field: this.state.grossWeight, msg: "Gross Weight", type: 1 },
    ];

    let validObj = validate(validateFields);
    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message}`);
      }
      if (validObj.type === 2) {
        swal(`Please Select ${validObj.message}`);
      }
    } else {
      if (Number(this.state.grossWeight) > this.state.tareWeight) {
        this.props.createWastageType(
          serverApi.CREATE_WASTAGE_ENTRY,
          data,
          this.props.history
        );
      } else {
        swal("Gross Weight should be more than tare weight", { icon: "error" });
      }
    }
  };
  onClickCancel = () => {};
  // Function For Displaying Common Popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    if (this.state.wastageType === "") {
      this.state.stock = 0;
    }

    return (
      <div className="finishingEntriesContainer">
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div>
                      <div className="TextFieldBlock">
                        <label>
                          Date<div className="mandatoryField">*</div>
                        </label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          name="tranDate"
                          inputFormat="dd-MM-yyyy"
                          value={this.state.tranDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "tranDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="TextFieldBlock">
                      <label>
                        Time<label className="starcolor">*</label>
                      </label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="Time"
                        name="time"
                        value={this.state.time}
                        onChange={(e) => this.handleSelectDate(e, "time")}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input ref={inputRef} {...inputProps} />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={
                          this.state.spellContent
                            ? this.state.spellContent.map((item) => ({
                                label: item.label,
                                value: item.value,
                                name: item.name,
                              }))
                            : ""
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.spell}
                        name="spell"
                        label="Spell"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectNew
                        arrayOfData={this.state.wastageTypeList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.wastageType}
                        name="wastageType"
                        label="Wastage Type"
                        required
                        isDisabled={this.state.spell === "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>
                          Stock <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <InputAdornment
                        label={"KG"}
                        type="number"
                        value={this.state.stock}
                        handleChange={this.handleChange("wastageStock")}
                        disabled={
                          this.state.wastageType === "" ||
                          this.state.wastageType === null
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectNew
                        arrayOfData={this.state.trollyNoList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.trollyNo}
                        name="trollyNo"
                        label="Trolly No"
                        required
                        isDisabled={
                          this.state.stock === 0 ||
                          this.state.stock === "" ||
                          this.state.stock === null
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>Tare Weight</label>
                      </div>
                      <InputAdornment
                        label="KG"
                        type="number"
                        value={this.state.tareWeight}
                        handleChange={this.handleChange("tareWeight")}
                        disabled={true}
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>
                          Gross Weight <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <InputAdornment
                        label="KG"
                        type="number"
                        value={this.state.grossWeight}
                        handleChange={this.handleChange("grossWeight")}
                        disabled={
                          this.state.trollyNo === "" ||
                          this.state.trollyNo === null
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>Net Weight</label>
                      </div>
                      <InputAdornment
                        label="KG"
                        type="number"
                        value={this.state.netWeight}
                        handleChange={this.handleChange("netWeight")}
                        disabled={true}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {/* Common Popup for validation */}
            {this.state.confirm && (
              <CommonPopup>
                <div className="delAddItem">
                  <div className="mandatoryFieldText">
                    Please select all the mandatory fields
                  </div>
                  <div className="delAddItemBtns">
                    <input
                      type="button"
                      onClick={this.onclickPopUP.bind(this)}
                      value="Ok"
                      className="delYesBtn"
                    />
                  </div>
                </div>
              </CommonPopup>
            )}
            <div className="consoleFormButtonBlock">
              <Link to="/ad_wastage_entry">
                <CustomButton
                  label={"Cancel"}
                  className="greenBorderButton"
                  handleClick={this.onClickCancel}
                />
              </Link>
              <CustomButton
                label={"Create"}
                className="greenButton"
                handleClick={this.onClickCreate}
                type="sumbit"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { spell } = state.AttendanceReducer;
  const { trollyDdListData } = state.WindingDoffEntryReducer;
  const { wastageTypeListData, stockWastageList, getWastageTypeListData } =
    state.wastageEntryListReducer;

  return {
    spell,
    trollyDdListData,
    wastageTypeListData,
    stockWastageList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSpell,
    spinningQualityList,
    beamingDdList,
    beamingCreate,
    getTrollyDdList,
    wastageTypeList,
    stockwastageTypeList,
    createWastageType,
  })(CreateWastageEntry)
);
