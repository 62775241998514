import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { BANK_ACCOUNT_LIST, CREATE_BANK_ACCOUNT, UPDATE_BANK_ACCOUNT } from './actionTypes';
import { BankAccountListSuccessfull, createBankAccountSuccessfull, updateBankAccountSuccessfull } from './actions';
import { getList, postAdd } from '../../../helpers/Server_Helper';
import swal from "sweetalert";

function* getBankAccountList({ payload: { url, data, history } }) {
    try {
        const response = yield call(getList, url, data);
        yield put(BankAccountListSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
function* CreateBankAccount({ payload: { url, data, history } }) {
    try {
        const response = yield call(postAdd, url, data);
        yield put(createBankAccountSuccessfull({ response }))
        if (response.data.status === true) {
            swal(response.data.message, { icon: "success" })
            history.push("/Bank_Accounts");
        } else {
            swal(response.data.message, { icon: "error" })
        }
    } catch (error) {
        swal("error:" + error, { icon: "danger" });
        console.log(error)
    }
}
function* UpdateBankAccount({ payload: { url, data, history } }) {
    try {
        const response = yield call(postAdd, url, data);
        yield put(updateBankAccountSuccessfull({ response }))
        if (response.data.status === true) {
            swal(response.data.message, { icon: "success" })
            history.push("/Bank_Accounts");
        } else {
            swal(response.data.message, { icon: "error" })
        }
    } catch (error) {
        console.log(error)
        swal("error:" + error, { icon: "danger" });
    }
}

export function* watchBankAccountList() {
    yield takeEvery(BANK_ACCOUNT_LIST, getBankAccountList)
}
export function* watchCreateBankAccount() {
    yield takeEvery(CREATE_BANK_ACCOUNT, CreateBankAccount)
}
export function* watchUpdateBankAccount() {
    yield takeEvery(UPDATE_BANK_ACCOUNT, UpdateBankAccount)
}

function* BankAccountListSaga() {
    yield all([
        fork(watchBankAccountList),
        fork(watchCreateBankAccount),
        fork(watchUpdateBankAccount)
    ])
}


export default BankAccountListSaga;