import {
  DEPARTMENT_MASTER_LIST,
  CREATE_DEPARTMENT,
  UPDATE_DEPARTMENT,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getList, postAdd } from "../../../helpers/Server_Helper";
import {
  departmentMasterListSuccessfull,
  createDepartmentSuccessfull,
  updateDepartmentSuccessfull,
} from "./actions";
import swal from "sweetalert";

function* DepartmentMasterList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    // response.data.sort((a, b) => (a.suppId < b.suppId) ? 1 : -1)
    yield put(departmentMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateDepartment({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createDepartmentSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/department_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateDepartment({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateDepartmentSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/department_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

export function* watchDepartmentMasterList() {
  yield takeEvery(DEPARTMENT_MASTER_LIST, DepartmentMasterList);
}

export function* watchCreateDepartmentMaster() {
  yield takeEvery(CREATE_DEPARTMENT, CreateDepartment);
}
export function* watchUpdateDepartmentMaster() {
  yield takeEvery(UPDATE_DEPARTMENT, UpdateDepartment);
}

function* DepartmentSaga() {
  yield all([
    fork(watchDepartmentMasterList),
    fork(watchCreateDepartmentMaster),
    fork(watchUpdateDepartmentMaster),
  ]);
}
export default DepartmentSaga;
