import {
  COMPANY_MASTER_LIST,
  CREATE_COMPANY_MASTER,
  UPDATE_COMPANY_MASTER,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getList, postAdd } from "../../../helpers/Server_Helper";
import {
  companyMasterListSuccessfull,
  createCompanyMasterSuccessfull,
  updateCompanyMasterSuccessfull,
} from "./actions";
import swal from "sweetalert";

function* CompanyMasterList({ payload: { url, data } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(companyMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateCompanyMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createCompanyMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/company_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateCompanyMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateCompanyMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/company_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

export function* watchCompanyMasterList() {
  yield takeEvery(COMPANY_MASTER_LIST, CompanyMasterList);
}
export function* watchCreateCompanyMaster() {
  yield takeEvery(CREATE_COMPANY_MASTER, CreateCompanyMaster);
}
export function* watchUpdateCompanyMaster() {
  yield takeEvery(UPDATE_COMPANY_MASTER, UpdateCompanyMaster);
}

function* CompanyMasterSaga() {
  yield all([
    fork(watchCompanyMasterList),
    fork(watchCreateCompanyMaster),
    fork(watchUpdateCompanyMaster),
  ]);
}

export default CompanyMasterSaga;
