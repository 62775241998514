import {
  JUTE_CONVERSION_LIST,
  JUTE_CONVERSION_LIST_SUCCESSFULL,
  JUTE_GET_STOCK_DETAILS_BY_GODOWN_NUMBER,
  JUTE_GET_STOCK_DETAILS_BY_GODOWN_NUMBER_SCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  JuteConversionData: [],
  StockDetailsByGodown: [],
};

const JuteConversionReducer = (state = initialState, action) => {
  switch (action.type) {
    case JUTE_CONVERSION_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTE_CONVERSION_LIST_SUCCESSFULL:
      state = {
        ...state,
        JuteConversionData: action.payload.response,
        loading: true,
      };
      break;
    /****************************************Get stock by godown number***************************************/
    case JUTE_GET_STOCK_DETAILS_BY_GODOWN_NUMBER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTE_GET_STOCK_DETAILS_BY_GODOWN_NUMBER_SCCESSFULL:
      state = {
        ...state,
        StockDetailsByGodown: action.payload.response,
        loading: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default JuteConversionReducer;
