import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import DynamicSelect from "../Dropdown/DynamicSelect";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DesktopDatePicker from "@mui/lab/DatePicker";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';   
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';   
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'; 
import moment from "moment";
import { serverApi } from "../../helpers/Consts";
import { getSupplierListMultiselect } from "../../store/Global/DropDownApis/actions";
import { getVehicleList } from "../../store/MasterDropdownApis/actions";
import swal from "sweetalert";

class DayBookFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      supplierListMultiselectData: [],
      VehicleListData: [],
      fromDate:moment().format(),
      toDate:moment().format(),
      supplier:"",
      supplierValue:"",
      vehicle:"",
      vehicleValue:"",
      ledgerMasterListData: [],
      ledgerId: ""
    };
  }

  componentDidMount() {
    this.props.getSupplierListMultiselect(
      serverApi.GET_SUPPLIER_LIST_MULTISELECT +
        localStorage.getItem("companyId"),
      this.props.history
    );
    this.props.getVehicleList(
      serverApi.GET_VEHICLE_DATA +
        localStorage.getItem("companyId") +
        "/" +
        this.state.userDit.cipher
    );
  }
  componentWillReceiveProps(props) {
    var ledgerList = [];
    if(props.LedgerMasterList.data) {
      props.LedgerMasterList.data.map(prop => {
      
        ledgerList.push({
          value: prop.id,
          label: prop.ledgerName,
          name: prop.ledgerName
        })
      })
    }

    this.setState({
      ledgerMasterListData: ledgerList,
    })
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if(name === "ledger") {
      this.setState({
        ledgerId: selectedValue
      })
    }  
  };
  handleSelectDate = (e, name) => {
    if (name === "FromDate") {
      let date = moment(e).format();
      let formattedFromDate = date.split("T", 1).toString();

      this.setState({
        fromDate: formattedFromDate,
      });
    }

    if (name === "ToDate") {
      let date = moment(e).format();
      let formattedToDate = date.split("T", 1).toString();

      this.setState({
        toDate: formattedToDate,
      });
    }
  };

  handleSubmit = () => {
    const data = {
      acYear: "2022",
      companyId: this.state.companyId,
      fromDate: moment(this.state.fromDate).format("DD-MM-YYYY"),
      ledgerId: "",
      suppType: "",
      toDate:  moment(this.state.toDate).format("DD-MM-YYYY"),
      tranStatus: "",
      tranType: "PR"
    }

    this.props.handle_search(data);
    
      this.setState({
        open: false,
      });
  };

 

  render() {
    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid spacing={0}>
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
                {/* <div>Save View</div> */}
              </div>
            </div>
            <Grid item xs={12} className="filterBlockContainer">
              <Box gridColumn="span 3">
                <div className="filterDateBlock">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>From Date</label>
                      </div>
                      {/* <ConsoleDatePicker /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.fromDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "FromDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="fromDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>To Date</label>
                      </div>
                      {/* <ConsoleDatePicker /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.toDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "ToDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="toDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
              </Box>

              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Search"}
                  className="greenButton"
                  handleClick={this.handleSubmit}
                  type="sumbit"
                />
                {/* <CustomButton
                  label={"Clear"}
                  className="greenButton"
                  handleClick={this.handleClear}
                  type="sumbit"
                /> */}
              </div>
            </Grid>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  const { LedgerMasterList } = state.LedgerReducer;
  return { LedgerMasterList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSupplierListMultiselect,
    getVehicleList,
  })(DayBookFilter)
);
