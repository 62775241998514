import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import { getStockTransferList } from "../../../store/StoreModule/StockTransfer/actions";

class StockTransferMasterIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      stockTransferListData: [],
      stockTransferListColumns: [
        {
          Header: "SR No",
          accessor: "srNo",
          headerAlign: "left",
          align: "left",
          hidden: false,
        },
        {
          Header: "SR Print No",
          accessor: "srPrintNo",
          headerAlign: "left",
          align: "left",
          hidden: false,
        },
        {
          Header: "Transfer Date",
          accessor: "srDate",
          headerAlign: "left",
          align: "left",
          hidden: false,
        },
        {
          Header: "Source",
          accessor: "srcBranch",
          headerAlign: "left",
          align: "left",
          hidden: false,
        },
        {
          Header: "Destination",
          accessor: "desBranch",
          headerAlign: "left",
          align: "left",
          hidden: false,
        },
        {
          Header: "Status",
          accessor: "status",
          headerAlign: "left",
          align: "left",
          hidden: false,
        },
      ],
    };
  }

  componentDidMount() {
    const data = {
      acYear: "2022",
      companyId: this.state.companyId,
      erpType: "1",
      fromDate: "",
      toDate: "",
    };
    this.props.getStockTransferList(
      serverApi.GET_ALL_STOCK_TRANSFER_LIST,
      data,
      this.props.history
    );
  }
  componentWillReceiveProps(props) {
    if (props.StockTransfer_List) {
      this.setState({
        stockTransferListData: props.StockTransfer_List.data,
        stockTransferListColumns: props.StockTransfer_List.column,
      });
    }
  }

  render() {
    const mdata1 = this.props.StockTransfer_List;

    return (
      <>
        <Gridwithcustomview
          mdata={this.state.stockTransferListData}
          mcolumns={this.state.stockTransferListColumns}
          micon={GridArrowIcon}
          status={false}
          actions={true}
          url="/createStockTransfer"
        />
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { StockTransfer_List } = state.StockTransfer_List;
  return { StockTransfer_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getStockTransferList,
  })(StockTransferMasterIndex)
);
