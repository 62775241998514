import {
  INDENT_LIST,
  INDENT_LIST_SUCCESSFULL,
  CREATE_INDNET,
  CREATE_INDNET_SUCCESSFULL,
  ACTIONS,
  ACTIONS_SUCCESSFULL,
  SEE_THE_LOGS,
  SEE_THE_LOGS_SUCCESSFULL,
  UPDATE_INDNET,
  UPDATE_INDNET_SUCCESSFULL,
  PENDING_STOCK,
  PENDING_STOCK_SUCCESSFULL,
  PENDING_STOCKPO_AND_RECEIPT,
  PENDING_STOCKPO_AND_RECEIPT_SUCCESSFULL,
  GET_FILES_OF_INDENT,
  GET_FILES_OF_INDENT_SUCCESSFULL,
  GET_DOWNLOAD_PDF,
  GET_DOWNLOAD_PDF_SUCCESSFULL,
  GET_COPYOF_INDENTS,
  GET_COPYOF_INDENTS_SUCCESSFULL,
  CREATE_PROJECT,
  CREATE_PROJECT_SUCCESSFULL,
  INDENT_LINEITEMS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESSFULL,
  GET_FILES_OF_INVOICE,
  GET_FILES_OF_CHALLAN,
  GET_FILES_OF_SUPPDOCS,
  VALIDATE_ITEM_IN_OPENINDENT,
  VALIDATE_ITEM_IN_OPENINDENT_SUCCESSFULL,
  GETITEMSLISTFORINDENT_SUCCESSFULL,
  GETITEMGROUPSFORINDENT_SUCCESSFULL,
  CLEAR_INDENT_LINEITEMS,
  GET_ITEM_DETAILS_BY_POID_FOR_INDENT,
  GET_ITEM_DETAILS_BY_POID_FOR_INDENT_SUCCESSFULL,
  GET_ITEMSLIST_BY_ITEMGROUPID_COMPANYID,
  GET_ITEMSLIST_BY_ITEMGROUPID_COMPANYID_SUCCESSFULL,
  GET_ITEMDETAILS_BY_ITEMID,
  GET_ITEMDETAILS_BY_ITEMID_SUCCESSFULL,
  GET_ITEMDETAILS,
  GET_ITEMDETAILS_SUCCESSFULL,
  VALIDATE_ITEM,
  VALIDATE_ITEM_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  indentList: [],
  indentListColumns: [],
  indentListItemsList: [],
  createIndent: [],
  updateIndenttData: [],
  actions_data: [],
  logs_List: [],
  files_List: [],
  filesofChallan: [],
  filesofInvoice: [],
  filesofSuppdocs: [],
  pendingStock: [],
  pendingStockPoandReceipt: [],
  downloadPDf: [],
  CopyOfIndentList: [],
  projectData: [],
  updateProjectdData: [],
  validateItemMessage: [],
  itemsListForIndent: [],
  itemGroupsForIndent: [],
  ItemDetailsByPOIdForIndent: [],
  itemDetails:{},
  itemDetailsData:{},
  itemValidateData:[],
  indentLineItems: [
    {
      id: 1,
      qty: "0.000",
      uomCode: "",
      qohCompany: null,
      qohBranch: 0,
      qohProject: 0,
      rate: "0.000",
      rateSource: 0,
      isActive: 1,
      remarks: "",
      make: "",
      itemGroup: "",
      itemGroupValue: "",
      departmentName: "",
      departmentId: null,
      budgetHeadId: "",
      budgetHeadName: "",
      itemId: 0,
      qtyPo: null,
      status: 1,
      subExpenseTypeName: null,
      selectedExpenseType: null,
      selectedSubExpenseType: null,
      installationRate: "0.000",
      min: 0,
      max: 0,
      minaAllowedQty: 0,
      maxAllowedQty: 0,
      itemList: [],
      departmentList: [],
      itemGroupList: [],
      reOrderQty: "0.000",
      itemName: "",
      isUpdate: false,
    },
  ],
};

const IndentReducer = (state = initialState, action) => {
  switch (action.type) {
    case INDENT_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case INDENT_LIST_SUCCESSFULL:
      state = {
        ...state,
        indentList: action.payload.response,
        loading: true,
      };
      break;
    case CREATE_INDNET:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_INDNET_SUCCESSFULL:
      state = {
        ...state,
        createIndent: action.payload.response,
        loading: true,
      };
      break;
    case UPDATE_INDNET:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_INDNET_SUCCESSFULL:
      state = {
        ...state,
        updateIndenttData: action.payload.response,
        loading: true,
      };
      break;

    case ACTIONS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ACTIONS_SUCCESSFULL:
      state = {
        ...state,
        actions_data: action.payload.response,
        loading: true,
      };
      break;
    case SEE_THE_LOGS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SEE_THE_LOGS_SUCCESSFULL:
      state = {
        ...state,
        logs_List: action.payload.response,
        loading: true,
      };
      break;
    case GET_FILES_OF_INDENT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_FILES_OF_INDENT_SUCCESSFULL:
      state = {
        ...state,
        files_List: action.payload.response,
        loading: true,
      };
      break;
    case GET_FILES_OF_INVOICE:
      state = {
        ...state,
        filesofInvoice: action.payload.response,
        loading: true,
      };
      break;
    case GET_FILES_OF_CHALLAN:
      state = {
        ...state,
        filesofChallan: action.payload.response,
        loading: true,
      };
      break;
    case GET_FILES_OF_SUPPDOCS:
      state = {
        ...state,
        filesofSuppdocs: action.payload.response,
        loading: true,
      };
      break;
    case GET_DOWNLOAD_PDF:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_DOWNLOAD_PDF_SUCCESSFULL:
      state = {
        ...state,
        downloadPDf: action.payload.response,
        loading: true,
      };
      break;
    case PENDING_STOCK:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PENDING_STOCK_SUCCESSFULL:
      state = {
        ...state,
        pendingStock: action.payload.response,
        loading: true,
      };
      break;
    case PENDING_STOCKPO_AND_RECEIPT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PENDING_STOCKPO_AND_RECEIPT_SUCCESSFULL:
      state = {
        ...state,
        pendingStockPoandReceipt: action.payload.response,
        loading: true,
      };
      break;
    case GET_COPYOF_INDENTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_COPYOF_INDENTS_SUCCESSFULL:
      state = {
        ...state,
        CopyOfIndentList: action.payload.response,
        loading: true,
      };
      break;
    case CREATE_PROJECT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_PROJECT_SUCCESSFULL:
      state = {
        ...state,
        projectData: action.payload.response,
        loading: true,
      };
      break;

    case UPDATE_PROJECT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_PROJECT_SUCCESSFULL:
      state = {
        ...state,
        updateProjectdData: action.payload.response,
        loading: true,
      };
      break;
    case VALIDATE_ITEM_IN_OPENINDENT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case VALIDATE_ITEM_IN_OPENINDENT_SUCCESSFULL:
      state = {
        ...state,
        validateItemMessage: action.payload.response,
        loading: true,
      };
      break;
    case GETITEMSLISTFORINDENT_SUCCESSFULL:
      state = {
        ...state,
        itemsListForIndent: action.payload.response.data,
        loading: true,
      };
      break;
    case INDENT_LINEITEMS:
      state = {
        ...state,
        indentLineItems: action.payload,
        loading: true,
      };
      break;
    case CLEAR_INDENT_LINEITEMS:
      state = {
        ...state,
        indentLineItems: action.payload,
        loading: true,
      };
      break;
    case GETITEMGROUPSFORINDENT_SUCCESSFULL:
      state = {
        ...state,
        itemGroupsForIndent: action.payload.response.data,
        loading: true,
      };
      break;
    case GET_ITEM_DETAILS_BY_POID_FOR_INDENT:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_ITEM_DETAILS_BY_POID_FOR_INDENT_SUCCESSFULL:
      state = {
        ...state,
        ItemDetailsByPOIdForIndent: action.payload.response,
        loading: false,
      };
      break;
    case GET_ITEMSLIST_BY_ITEMGROUPID_COMPANYID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ITEMSLIST_BY_ITEMGROUPID_COMPANYID_SUCCESSFULL:
      state = {
        ...state,
        itemsListForIndent: action.payload.response,
        loading: true,
      };
      break;
      case GET_ITEMDETAILS_BY_ITEMID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ITEMDETAILS_BY_ITEMID_SUCCESSFULL:
      state = {
        ...state,
        itemDetails: action.payload.response,
        loading: true,
      };
      break;
      case GET_ITEMDETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ITEMDETAILS_SUCCESSFULL:
      state = {
        ...state,
        itemDetailsData: action.payload.response,
        loading: true,
      };
      break;
      case VALIDATE_ITEM:
      state = {
        ...state,
        loading: true,
      };
      break;
    case VALIDATE_ITEM_SUCCESSFULL:
      state = {
        ...state,
        itemValidateData: action.payload.response,
        loading: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default IndentReducer;
