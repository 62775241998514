import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
// import { BrowserRouter } from "react-router-dom";

import store from './store';
const app = (
  <Provider store={store}>
      <BrowserRouter>
          <App />
      </BrowserRouter>
  </Provider>
);

if(process.env.NODE_ENV === "production") {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

ReactDOM.render(app, document.getElementById('root'));
// serviceWorker.unregister();
