import {
  EXPENSE_BOOKING_LIST,
  WORKER_LIST,
  ADD_EXPENSE_BOOKING,
  COST_CENTER,
  EXPENSE_BOOKING_VIEW_BY_ID,
  DELETE_FILES,
  UPLOAD_FILES,
} from "./actionType";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { postAdd, getListurl } from "../../../helpers/Server_Helper";
import {
  ExpenseBookingListSuccessfull,
  WorkerListSuccessfull,
  AddExpenseBookingListSuccessfull,
  costCenterListSuccessfull,
  getExpenseBookingViewByIdSuccessfull,
  getExpenseBookingList,
  getWorkerList,
  DeleteUploadedfilesSuccessfull,
  UploadfilesSuccessfull
} from "./actions";
import swal from "sweetalert";
import { serverApi } from "../../../helpers/Consts";

function* getExpenseBookingLists({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(ExpenseBookingListSuccessfull({ response }));
  } catch (error) {
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
  }
}
function* getWorkerListData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (response) {
      yield put(WorkerListSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
  }
}

function* setExpenseBookingList({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(AddExpenseBookingListSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
    if (response.data.message) {
      // var userId = JSON.parse(localStorage.getItem('authUser'))
      var listUrl =
        serverApi.EXPENSE_BOOKING_LIST +
        localStorage.getItem("companyId") +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).userId;
      yield put(getExpenseBookingList(listUrl, history));
    }
    history.push("/expensebooking");
  } catch (error) {
    console.log(error);
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
  }
}
function* getExpenseBookingViewByIdData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getExpenseBookingViewByIdSuccessfull({ response }));
    if (response.data) {
      if (response.data.exboHdr) {
        yield put(
          getWorkerList(
            serverApi.WORKER_LIST +
              response.data.exboHdr.ebNo +
              "/" +
              localStorage.getItem("companyId") +
              "/" +
              JSON.parse(localStorage.getItem("authUser")).userId,
            history
          )
        );
      }
    }
  } catch (error) {
    console.log(error);
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
  }
}
function* costCenterDropDown({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(costCenterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* deleteFiles({ payload: { url, history, rowId } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(DeleteUploadedfilesSuccessfull({ response }));
    if (response) {
    swal('Deleted Successfully', { icon: "success" });

      // yield put(
      //   getExpenseBookingViewById(
      //     serverApi.EXPENSE_BOOKING_VIEW_BYID +
      //       rowId +
      //       "/" +
      //       JSON.parse(localStorage.getItem("authUser")).userId
      //   )
      // );
    }
  } catch (error) {
    console.log(error);
  }
}
function* UploadFilesdata({ payload: { url, data,history, recId } }) {
  try {
    const newresponse = yield call(postAdd, url,data);
    const response ={...newresponse,recId:recId}
    yield put(UploadfilesSuccessfull({ response }));
    swal('Uploaded Successfully', { icon: "success" });

  } catch (error) {
    console.log(error);
  }
}
export function* watchExpenseBookingList() {
  yield takeEvery(EXPENSE_BOOKING_LIST, getExpenseBookingLists);
  yield takeEvery(WORKER_LIST, getWorkerListData);
  yield takeEvery(ADD_EXPENSE_BOOKING, setExpenseBookingList);
  yield takeEvery(EXPENSE_BOOKING_VIEW_BY_ID, getExpenseBookingViewByIdData);
  yield takeEvery(COST_CENTER, costCenterDropDown);
  yield takeEvery(DELETE_FILES, deleteFiles);
  yield takeEvery(UPLOAD_FILES,UploadFilesdata)
}

function* ExpenseBookingSaga() {
  yield all([fork(watchExpenseBookingList)]);
}

export default ExpenseBookingSaga;
