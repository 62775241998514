import {
  JUTE_ISSUE_LIST,
  JUTE_ISSUE_LIST_SUCCESSFULL,
  JUTE_ISSUE_LINE_ITEMS,
  JUTE_ISSUE_VIEW_BY_ID,
  JUTE_ISSUE_CREATE,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  JuteIssueData: [],
  juteIssueLineItems: [
    {
      juteType: "0",
      finYear: localStorage.getItem("acadamicYear"),
      issueDate: "",
      juteQuality: 0,
      baleLoose: "",
      totalWeight: "0",
      quantity: "",
      uomCode: "",
      godownNo: 0,
      side: "",
      createdBy: "",
      deptId: "",
      wastageId: "",
      mrNo: "0",
      stockId: 0,
      openStock: "0",
      closeStock: "0",
      yarnId: 0,
      issueValue: "0",
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")),
      branchId: 0,
    },
  ],
};

const JuteIssueReducer = (state = initialState, action) => {
  switch (action.type) {
    case JUTE_ISSUE_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTE_ISSUE_LIST_SUCCESSFULL:
      state = {
        ...state,
        JuteIssueData: action.payload.response,
        loading: true,
      };
      break;
    case JUTE_ISSUE_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTE_ISSUE_LINE_ITEMS:
      state = {
        ...state,
        juteIssueLineItems: action.payload,
      };
      break;
    case JUTE_ISSUE_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default JuteIssueReducer;
