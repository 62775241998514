import React, { Component } from "react";
import { Grid, Box, Button, Alert } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { serverApi } from "../../../helpers/Consts";
import { travelCatageriesList } from "../../../store/Master/TravelCatageries/actions";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import DeleteIcon from "../../../assets/images/picEditIcon.png";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import { postUploadFileList } from "../../../store/MyProfile/UploadFile/actions";
import { getAllFiles } from "../../../store/HRMS/EmployeeDataBase/actions";
import { getParentBudgetHeads } from "../../../store/Global/DropDownApis/actions";
import {
  DeleteUploadedfiles,
  Uploadfiles,
} from "../../../store/HRMS/ExpenseBooking/actions";
import swal from "sweetalert";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
class ExpenseBookingLineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: localStorage.getItem("companyId"),
      rowCount: 1,
      CostCenter: [],
      lineItems: this.props.products,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      selectedValue: "",
      selectedRow: [],
      lastRow: [],
      lastVal: 0,
      uomList: [],
      ExpenseList: [],
      All_File: [],
      filename: "",
      delete: false,
      fileType: "",
      FileValue: "",
      dtlId: "",
      DeleteId: "",
      DeleteButton: true,
      deleteValue: true,
      isDropdownOpen: false,
    };
  }
  OnhandleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    this.setState({ [key]: event });
    let mname = key.split("_");
    if (mname[0] === "expenseNote") {
      row.expenseNote = value;
      this.setState({
        expenseNote: value,
      });
    }
    if (mname[0] === "amount") {
      row.amount = value;
      this.setState({
        amount: value,
      });

      var totalAmount = this.state.lineItems
        .map((p) => p.amount)
        .reduce((total, ind) => parseFloat(total) + parseFloat(ind));
      this.props.header.total = totalAmount;
      this.props.header.dueAmount = totalAmount;
    }
  };
  handleChange = (selectedValue, selectedName, name, selectedRow) => {
    let lastVal = selectedRow.id;
    this.setState({
      rowCount: this.state.rowCount + 1,
    });
    let lastRow = this.state.lineItems[this.state.lineItems.length - 1];
    if (name === "costCenter") {
      selectedRow.costId = selectedValue;
      if (selectedRow === lastRow && lastVal === 0 && selectedValue !== "0") {
      }
    }
    if (name === "expenseType") {
      selectedRow.expenseType = selectedValue;
      // this.handleAddEvent(selectedRow);
    }
  };
  handleSelectDate = (e, name, row) => {
    if (name === "DOB") {
      if (row.expenseDate == "") {
        this.handleAddEvent(row);
      }
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      this.setState({
        expenseDate: e.getTime(),
      });
      row.expenseDate = e.getTime();
    }
  };
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getParentBudgetHeads(
      serverApi.GET_PARENT_BUDGET_HEADS_LIST +
        "company" +
        "/" +
        localStorage.getItem("companyId"),
      this.props.history
    );

    const data1 = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      travelCategory: "",
    };
    this.props.travelCatageriesList(
      serverApi.GET_ALL_TRAVEL_CATAGERIES_LIST,
      data1,
      this.props.history
    );
    if (this.props.location.state) {
      this.props.header.total =
        this.props.location.state.rowData.total === null
          ? 0
          : this.props.location.state.rowData.total;
      this.props.header.dueAmount =
        this.props.location.state.rowData.total -
        this.props.location.state.rowData.paidAmount;
      this.props.header.paidAmount = parseFloat(
        this.props.location.state.rowData.paidAmount
      ).toFixed(2);
    }
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData) {
        if (
          this.props.location.state.rowData.status === "4" ||
          this.props.location.state.rowData.status === "3"
        ) {
          this.setState({
            DeleteButton: false,
          });
        }
      }
    }
  }
  componentWillReceiveProps(props) {
    if (props.Upload_files) {
      if (props.Upload_files.data) {
        this.state.lineItems.map((item) => {
          if (item.dtlId == props.Upload_files.recId) {
            item.fileUploadId = props.Upload_files.data.id;
          }
        });
      }
    }
    if (props.parentBudgetHeadsList) {
      this.setState({
        parentBudgetHeadsList: props.parentBudgetHeadsList.data,
      });
    }
    // expenseType
    var Expense = [];
    if (props.TravelCatageriesList.data) {
      props.TravelCatageriesList.data.map((prop) => {
        Expense.push({
          label: prop.travelCategory,
          name: prop.travelCategory,
          value: prop.id,
        });
      });
      this.setState({
        ExpenseList: Expense,
      });
    }
  }
  handleAddEvent = (data) => {
    let rowId = this.state.rowCount;
    let product = {
      id: 0,
      no: 0,
      amount: 0,
      costId: "",
      expenseDate: "",
      expenseNote: "",
      expenseType: "",
      quantity: 0,
      rate: 0,
      uom: 0,
      isActive: 1,
      displayName: null,
      fileUploadId: null,
    };
    this.setState({ rowCount: rowId + 1 });
    this.state.lineItems.push(product);
  };

  handleselectedFile = (id) => (e) => {
    this.setState({
      FileValue: e,
    });

    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 50);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", id);
    formData.append("createdBy", this.state.userDit.userId);
    formData.append("fileCompanyId", localStorage.getItem("companyId"));
    this.setState({
      fileName: file.name,
      fileExtension: file.name.split(".").pop(),
    });
    this.setState({
      selectedFile: e.target.files[0],
      selectedFileName: e.target.files[0].name,
      filename: e.target.files[0].name,
    });
    this.state.lineItems.map((item) => {
      if (item.dtlId == id) {
        item.displayName = e.target.files[0].name;
      }
    });
    this.props.Uploadfiles(
      serverApi.UPLOADFILE,
      formData,
      this.props.history,
      id
    );
  };

  handleDeleteFile = (fileId) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.state.lineItems.map((item) => {
          if (item.fileUploadId == fileId) {
            item.displayName = null;
            item.fileUploadId = null;
          }
        });
        if (this.props.location.state) {
          if (this.props.location.state.rowData) {
            if (this.props.location.state.rowData.recId) {
              this.props.DeleteUploadedfiles(
                serverApi.DELETEFILE + fileId,
                this.props.history,
                this.props.location.state.rowData.recId
              );
            }
          }
        }
      } else {
        swal("Your action is canceled!");
      }
    });
  };
  onDel = (data) => (event) => {
    const { key, row, del } = data;
    if (this.state.lineItems.length == 1) {
      Alert("cannot be removed");
    } else {
      let lineItem = this.state.lineItems.filter((item) => item != row);
      this.props.delete(lineItem);

      this.setState({
        lineItems: lineItem,
      });

      var totalAmount = lineItem
        .map((p) => p.amount)
        .reduce((total, ind) => parseFloat(total) + parseFloat(ind));
      this.props.header.total = totalAmount;
      this.props.header.dueAmount = totalAmount;
    }
  };
  handleValuechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "paidAmount") {
      this.props.header.paidAmount = event;
      this.props.header.dueAmount = this.props.header.total - event;
    }
  };
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <>
        <div className="editableTableContainer">
          <div className="gridContainer">
            <div className="gridComponent">
              <table cellSpacing="0px" cellPadding="0px">
                <thead>
                  <tr className="tableRowBlock">
                    {this.props.tableHeaders.map((column) => (
                      <>
                        {column.header === "Expense Date" ||
                        column.header === "Catageries" ||
                        column.header === "Description" ||
                        column.header == "Amount" ? (
                          <th>
                            {column.header}
                            <label style={{ color: "red" }}>*</label>
                          </th>
                        ) : (
                          <th>{column.header}</th>
                        )}
                      </>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {this.state.lineItems.map((row) => {
                    let rowIndex = this.state.lineItems.findIndex(
                      (idd) => idd == row
                    );

                    return (
                      <tr>
                        <>
                          {this.props.RowData &&
                            this.props.RowData.map((col) =>
                              col.component === "Inputadorment" ? (
                                <td>
                                  <div className="quantityDropdownBlock">
                                    <Grid className="selectAndTextfield">
                                      <Grid
                                        xs={12}
                                        md={12}
                                        className="dynamicSelectBlock"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          name={col.type + "_" + rowIndex}
                                          value={
                                            col.type === "amount"
                                              ? row.amount
                                              : col.type === "expenseNote"
                                              ? row.expenseNote
                                              : ""
                                          }
                                          onChange={this.OnhandleChange({
                                            key: col.type + "_" + rowIndex,
                                            row,
                                          })}
                                          className="inputBlockContainer"
                                          style={{
                                            textAlign:
                                              col.type === "amount"
                                                ? "right"
                                                : "",
                                            paddingRight: "8px",
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </div>
                                </td>
                              ) : col.component === "Date" ? (
                                <td>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DesktopDatePicker
                                      inputFormat="dd-MM-yyyy"
                                      name="DOB"
                                      value={row.expenseDate}
                                      fullWidth
                                      onChange={(e) =>
                                        this.handleSelectDate(e, "DOB", row)
                                      }
                                      renderInput={({
                                        inputRef,
                                        inputProps,
                                        InputProps,
                                      }) => (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          className="consoleDatePicker"
                                          style={{
                                            width:
                                              col.component === "Date"
                                                ? "120px"
                                                : "",
                                          }}
                                        >
                                          <input
                                            ref={inputRef}
                                            {...inputProps}
                                            readOnly={true}
                                          />
                                          {InputProps?.endAdornment}
                                        </Box>
                                      )}
                                    />
                                  </LocalizationProvider>
                                </td>
                              ) : col.component === "DynamicSelect" ? (
                                <td>
                                  <div className="quantityDropdownBlock">
                                    <Grid className="selectAndTextfield">
                                      <Grid
                                        xs={12}
                                        md={12}
                                        className="dynamicSelectBlock"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <DynamicSelect
                                          handleMenuOpen={this.handleMenuOpen}
                                          isDropdownOpen={
                                            this.state.isDropdownOpen
                                          }
                                          arrayOfData={
                                            col.type === "costCenter"
                                              ? this.state.parentBudgetHeadsList
                                              : col.type === "expenseType"
                                              ? this.state.ExpenseList
                                              : ""
                                          }
                                          className="dropdownBlockContainer"
                                          onSelectChange={this.handleChange}
                                          selected={
                                            col.type === "costCenter"
                                              ? row.costId
                                              : col.type === "expenseType"
                                              ? row.expenseType
                                              : ""
                                          }
                                          name={
                                            col.type === "costCenter"
                                              ? "costCenter"
                                              : col.type === "expenseType"
                                              ? "expenseType"
                                              : ""
                                          }
                                          row={row}
                                          value={
                                            col.type === "costCenter"
                                              ? row.costId
                                              : col.type === "expenseType"
                                              ? row.expenseType
                                              : ""
                                          }
                                          update={
                                            this.props.location.state ? 1 : 0
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  </div>
                                </td>
                              ) : col.component === "UploadDocument" ? (
                                <>
                                  <td>
                                    <div className="d-flex cloumnButtonBlock">
                                      <Box gridColumn="span 3">
                                        <div className="personalDetailsUpload">
                                          <div className="ducumentUploadBlock">
                                            <div className="documentUploadContent">
                                              {row.displayName === null ? (
                                                <span
                                                  className={
                                                    row.dtlId &&
                                                    (this.props.ApproveButton ||
                                                      this.props.RejectButton)
                                                      ? "btn_upload documentUpload "
                                                      : "btn_upload documentUpload disabled"
                                                  }
                                                >
                                                  <img
                                                    src={panUploadIcon}
                                                    alt=""
                                                  />
                                                  <input
                                                    type="file"
                                                    id="imag"
                                                    disabled={
                                                      row.dtlId &&
                                                      (this.props
                                                        .ApproveButton ||
                                                        this.props.RejectButton)
                                                        ? false
                                                        : true
                                                    }
                                                    title=""
                                                    className="input-img"
                                                    onChange={this.handleselectedFile(
                                                      row.dtlId
                                                    )}
                                                  />
                                                  Upload Document
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                              {row.displayName !== null ? (
                                                <div className="uploadedInfo">
                                                  <img
                                                    src={TickIcon}
                                                    alt=""
                                                    className="tickIcon"
                                                  />
                                                  <p>{row.displayName}</p>
                                                  {this.props.ApproveButton ||
                                                  this.props.RejectButton ? (
                                                    <Button
                                                      onClick={() =>
                                                        this.handleDeleteFile(
                                                          row.fileUploadId
                                                        )
                                                      }
                                                      className="deleteButton"
                                                    >
                                                      <img
                                                        src={DeleteIcon}
                                                        alt=""
                                                      />
                                                    </Button>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </Box>
                                    </div>
                                  </td>
                                </>
                              ) : (
                                ""
                              )
                            )}
                        </>
                        <td className="">
                          <input
                            type="button"
                            onClick={this.onDel({
                              key: row.recId,
                              row,
                            })}
                            value="X"
                            className="deleteDisplayButton"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div className="d-flex">
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Total"
                      value={parseFloat(this.props.header.total).toFixed(2)}
                      type="textarea"
                      placeholder="Enter Here"
                      required
                      disabled
                    />
                  </div>
                </Box>

                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Paid Amount"
                      value={this.props.header.paidAmount}
                      onChange={this.handleValuechange("paidAmount")}
                      caps="OFF"
                      minValue="0"
                      required
                      disabled={this.props.header.paidAmountshow}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Due Amount"
                      value={parseFloat(this.props.header.dueAmount).toFixed(2)}
                      caps="OFF"
                      minValue="0"
                      required
                      disabled
                    />
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStatetoProps = (state) => {
  const { TravelCatageriesList } = state.TravelCatageriesReducer;
  const { allFiles } = state.EmployeeReducer;
  const { Upload_File_List } = state.UploadFileReducer;
  const { parentBudgetHeadsList } = state.DropDownListReducer;
  const { ExpenseBookingViewById, deleteFiles, Upload_files } =
    state.ExpenseBookingReducer;
  const { fileUpload } = state.EmployeeReducer;
  return {
    fileUpload,
    ExpenseBookingViewById,
    TravelCatageriesList,
    allFiles,
    Upload_File_List,
    deleteFiles,
    parentBudgetHeadsList,
    Upload_files,
  };
};
export default withRouter(
  connect(mapStatetoProps, {
    travelCatageriesList,
    postUploadFileList,
    getAllFiles,
    DeleteUploadedfiles,
    Uploadfiles,
    getParentBudgetHeads,
  })(ExpenseBookingLineItem)
);
