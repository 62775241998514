import {VEHICLE_TYPES_MASTER_LIST,VEHICLE_TYPES_MASTER_LIST_SUCCESSFULL,CREATE_VEHICLE_TYPES_MASTER_SUCCESSFULL,CREATE_VEHICLE_TYPES_MASTER,
    UPDATE_VEHICLE_TYPES_MASTER,UPDATE_VEHICLE_TYPES_MASTER_SUCCESSFULL,
    API_ERRORS,} from './actionTypes';

export const vehicleTypesMasterList = (url,data,history) =>{
    return {
        type:VEHICLE_TYPES_MASTER_LIST,
        payload:{url,data,history}
    }
}
export const vehicleTypesMasterListSuccessfull = (data)=>{
    return {
        type:VEHICLE_TYPES_MASTER_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createVehicleTypesMaster = (url, data, history) => {
    return {
        type: CREATE_VEHICLE_TYPES_MASTER,
        payload: { url, data, history }
    }
}

export const createVehicleTypesMasterSuccessfull = (data) => {
    return {
        type: CREATE_VEHICLE_TYPES_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const updateVehicleTypesMaster = (url, data, history) => {
    return {
        type: UPDATE_VEHICLE_TYPES_MASTER,
        payload: { url, data, history }
    }
}

export const updateVehicleTypesMasterSuccessfull = (data) => {
    return {
        type: UPDATE_VEHICLE_TYPES_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const apiErrors = (error) => {
    return {
        type: API_ERRORS,
        payload: error,
    };
};