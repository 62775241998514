import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import { workerCategoryList } from "../../../store/Master/WorkerCategoryMaster/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      workerCategoryListData: [], // to display list data
    };
  }

  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
    };
    this.props.workerCategoryList(
      serverApi.GET_ALL_WORKER_CATEGORY_LIST,
      data,
      this.props.history
    ); // calling worker catagerirs list API
  }
  componentWillReceiveProps(props) {
    if (props.WorkerCategoryList) {
      this.setState({
        workerCategoryListData: props.WorkerCategoryList.data,
      });
    }
  }
  handleSearch = (data1) => {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      cataDesc: data1.cataDesc,
      cataCode: data1.cataCode,
    };
    this.props.workerCategoryList(
      serverApi.GET_ALL_WORKER_CATEGORY_LIST,
      data,
      this.props.history
    );
  };
  render() {
    if (this.state.workerCategoryListData) {
      if (this.state.workerCategoryListData.length !== 0) {
        var workerCategoryListData = this.state.workerCategoryListData.sort(
          (a, b) => {
            return b.cataId - a.cataId;
          }
        );
      }
    }

    return (
      <>
        <Gridwithcustomview
          mdata={workerCategoryListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          url="/create__Worker_Category_Master"
          filter_form={"WorkerCategoryMaster"}
          handleSearch={this.handleSearch}
        />
      </>
    );
  }
}
// Worker Catageries list Headers
const Columns = [
  {
    Header: "Category Code",
    accessor: "cataCode",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Category Name",
    accessor: "cataDesc",
    hidden: false,
    csvExport: true,
  },
];

const mapStatetoProps = (state) => {
  const { WorkerCategoryList } = state.WorkerCategoryReducer; // fetching list from worker Catageries reducer
  return { WorkerCategoryList };
};

export default withRouter(
  connect(mapStatetoProps, {
    workerCategoryList,
  })(Index)
);
