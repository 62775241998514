import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import DynamicSelect from "../../components/Dropdown/Master/DynamicSelectMasters"
import TextFieldNormal from "../TextField/TextFieldNormal";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';   
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';   
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'; 
import moment from "moment";
import { serverApi } from "../../helpers/Consts";
import {
  getSupplierListMultiselect,
  getStatusList,
  getMukamList,
} from "../../store/Global/DropDownApis/actions";
import { getVehicleList } from "../../store/MasterDropdownApis/actions";

class JutePOFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      supplierListMultiselectData: [],
      StatusListData: [],
      mukamList: [],
      fromDate:"",
      toDate:"",
      supplier: "",
      supplierValue: "",
      statusValue: "",
      status: "",
      mukam: "",
      mukamValue: "",
      PoNumber:"",
    };
  }

  componentDidMount() {
    this.props.getSupplierListMultiselect(
      serverApi.GET_SUPPLIER_LIST_JPO +
        "JO/" +
        localStorage.getItem("companyId"),
      this.props.history
    );
    this.props.getStatusList(serverApi.STATUS_LISTS, this.props.history);
    this.props.getMukamList(
      serverApi.MUKAM_GET_LIST + localStorage.getItem("companyId"),
      this.props.history
    );
    

    const financialYearStartMonth = 3; // Assuming financial year starts in April
    let financialYearStart = moment()
      .year(localStorage.getItem("acadamicYear"))
      .month(financialYearStartMonth)
      .startOf("month");

    const fromDate = financialYearStart.day(-7).format("YYYY-MM-DD");
    const toDate = moment().format("YYYY-MM-DD");

    this.setState({
      fromDate:fromDate,
      toDate:toDate
    })
   
  }
  componentWillReceiveProps(props) {
    if (props.supplierListMultiselect) {
      if(props.supplierListMultiselect.data){
      if(props.supplierListMultiselect.data.length !==0){
        var list =[]
        props.supplierListMultiselect.data.map(item=>{
          list.push({
            value:item.code,
            name:item.name,
            label:item.label
          })
        })
      }
      }
      this.setState({
        supplierListMultiselectData:list,
      });
    }
    if (props.statusList) {
      this.setState({
        StatusListData: props.statusList.data,
      });
    }
    if (props.mukamList) {
      this.setState({
        mukamList: props.mukamList.data,
      });
    }
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if(key==="MrNo"){
      this.setState({
        MrNO:event
      })
    }
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "Supplier") {
      this.setState({
        supplierValue: selectedValue == 0 ? "" : selectedValue,
        supplier: selectedName,
      });
    }
    if (name === "Status") {
      this.setState({
        statusValue: selectedValue == 0 ? "" : selectedValue,
        status: selectedName,
      });
    }
    if (name === "mukamName") {
      this.setState({
        mukamValue: selectedValue == 0 ? "" : selectedValue,
        mukam: selectedName,
      });
    }
  };
  handleSelectDate = (e, name) => {
    if (name === "FromDate") {
      let date = moment(e).format();
      let formattedFromDate = date.split("T", 1).toString();

      this.setState({
        fromDate: formattedFromDate,
      });
    }

    if (name === "ToDate") {
      let date = moment(e).format();
      let formattedToDate = date.split("T", 1).toString();

      this.setState({
        toDate: formattedToDate,
      });
    }
  };

  handleSubmit = () => {
    var data = {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      supplier: this.state.supplier,
      supplierValue: this.state.supplierValue,
      status: this.state.status,
      statusValue: this.state.statusValue,
      mukam:this.state.mukam,
      mukamValue:this.state.mukamValue,
      PoNumber:this.state.PoNumber,
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleClear = () => {
    this.setState({
      supplier: "",
      supplierValue: "",
      vehicle: "",
      vehicleValue: "",
      fromDate: "",
      toDate: "",
    });
  };

  render() {
    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid spacing={0}>
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
                {/* <div>Save View</div> */}
              </div>
            </div>
            <Grid item xs={12} className="filterBlockContainer">
              <Box gridColumn="span 3">
                <div className="filterDateBlock">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>From Date</label>
                      </div>
                      {/* <ConsoleDatePicker /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.fromDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "FromDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="fromDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>To Date</label>
                      </div>
                      {/* <ConsoleDatePicker /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.toDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "ToDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="toDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={this.state.supplierListMultiselectData}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handleSelectChange}
                    selected={this.state.supplierValue}
                    update={this.state.supplierValue ? 1 : 0}
                    name="Supplier"
                    label="Supplier"
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <TextFieldNormal
                    value={this.state.PoNumber}
                    placeholder={"Enter here"}
                    label="PO Number"
                    caps="OFF"
                    onChange={this.onhandlechange("PoNumber")}
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={this.state.mukamList}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handleSelectChange}
                    selected={this.state.mukamValue}
                    name="mukamName"
                    label="Mukam"
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={this.state.StatusListData}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handleSelectChange}
                    selected={this.state.statusValue}
                    // update={this.state.statusValue ? 1 : 0}
                    name="Status"
                    label="Status"
                  />
                </div>
              </Box>

              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Search"}
                  className="greenButton"
                  handleClick={this.handleSubmit}
                  type="sumbit"
                />
              </div>
            </Grid>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  const { supplierListMultiselect, statusList, mukamList } =
    state.DropDownListReducer;
  // const { VehicleData } = state.MasterDropDownListReducer;
  return { supplierListMultiselect, statusList, mukamList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSupplierListMultiselect,
    getVehicleList,
    getStatusList,
    getMukamList
  })(JutePOFilter)
);
