import {
  QUOTATION_LIST,
  QUOTATION_LIST_SUCCESSFULL,
  API_ERRORS,
  QUOTATION_UOM_LIST,
  QUOTATION_UOM_LIST_SUCCESSFULL,
  GET_UOM_RELATION_VALUES,
  GET_UOM_RELATION_VALUES_SUCCESSFULL,
  QUOTATION_ITEM_BY_ITEM_GROUP_ID,
  QUOTATION_ITEM_BY_ITEM_GROUP_ID_SUCCESSFULL,
  GET_ITEM_UOM_RELATION_VALUE,
  GET_ITEM_UOM_RELATION_VALUE_SUCCESSFULL,
  CREATE_QUOTATIONS,
  CREATE_QUOTATIONS_SUCCESSFULL,
  GET_QUOTATION_BROKER,
  GET_QUOTATION_BROKER_SUCCESSFULL,
  QUOTATION_HEADERS,
  QUOTATION_LINEITEMS,
  QUOTAION_VIEW_BY_ID_IN_QUOTATION,
  QUOTAION_VIEW_BY_ID_IN_SUCCESSFULL,
  CLEAR_QUOTATION_LINEITEMS,
  CLEAR_QUOTATION_HEADERS,
  QUOTATION_UPDATE,
  QUOTATION_UPDATE_SUCCESSFUL,
  GET_ITEM_DETAILS_BY_ITEM_GROUP_AND_ITEM_ID_AND_COMPANY_ID,
  GET_ITEM_DETAILS_BY_ITEM_GROUP_AND_ITEM_ID_AND_COMPANY_ID_SUCCESSFULL
} from "./actionTypes";

export const getQuotationList = (url, data, history) => {
  return {
    type: QUOTATION_LIST,
    payload: { url, data, history },
  };
};

export const QuotationListSuccessfull = (data) => {
  return {
    type: QUOTATION_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getQuotationUomList = (url, history) => {
  return {
    type: QUOTATION_UOM_LIST,
    payload: { url, history },
  };
};

export const QuotationListUomSuccessfull = (data) => {
  return {
    type: QUOTATION_UOM_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getUomRelationValues = (url, history) => {
  return {
    type:GET_UOM_RELATION_VALUES,
    payload: { url, history },
  };
};

export const getUomRelationValuesSuccessfull = (data) => {
  return {
    type: GET_UOM_RELATION_VALUES_SUCCESSFULL,
    payload: data,
  };
};
export const getQuotationItemsByItemGroupId = (url, data, history, rowId) => {
  return {
    type: QUOTATION_ITEM_BY_ITEM_GROUP_ID,
    payload: { url, data, history, rowId },
  };
};

export const QuotationItemsByItemGroupIdSuccessfull = (data) => {
  return {
    type: QUOTATION_ITEM_BY_ITEM_GROUP_ID_SUCCESSFULL,
    payload: data,
  };
};
export const getItemDetailsByItemGroupAndItemIdAndComapnyId = (url, data,history,rowId ) => {
  return {
    type: GET_ITEM_DETAILS_BY_ITEM_GROUP_AND_ITEM_ID_AND_COMPANY_ID,
    payload: { url, data,history,rowId },
  };
};

export const getItemDetailsByItemGroupAndItemIdAndComapnyIdSuccessfull = (data) => {
  return {
    type: GET_ITEM_DETAILS_BY_ITEM_GROUP_AND_ITEM_ID_AND_COMPANY_ID_SUCCESSFULL,
    payload: data,
  };
};
export const getItemUomRelation = (url, data, history) => {
  return {
    type: GET_ITEM_UOM_RELATION_VALUE,
    payload: { url, data, history },
  };
};

export const getItemUomRelationSuccessfull = (data) => {
  return {
    type: GET_ITEM_UOM_RELATION_VALUE_SUCCESSFULL,
    payload: data,
  };
};

export const setQuotation = (url, data, history) => {
  return {
    type: CREATE_QUOTATIONS,
    payload: { url, data, history },
  };
};

export const setQuotationSuccessfull = (data) => {
  return {
    type: CREATE_QUOTATIONS_SUCCESSFULL,
    payload: data,
  };
};
export const getQuotationBroker = (url, history) => {
  return {
    type: GET_QUOTATION_BROKER,
    payload: { url, history },
  };
};

export const getQuotationBrokerSuccessfull = (data) => {
  return {
    type: GET_QUOTATION_BROKER_SUCCESSFULL,
    payload: data,
  };
};

export const apiErrors = (error) => {
  return {
    type: API_ERRORS,
    payload: error,
  };
};

export const getQuotationHeaders = (data) => {
  return {
    type: QUOTATION_HEADERS,
    payload: data,
  };
};
export const clearQuotationHeaders = (data) => {
  return {
    type: CLEAR_QUOTATION_HEADERS,
    payload: data,
  };
};

export const clearQuotationLineItems = () => {
  return {
    type: CLEAR_QUOTATION_LINEITEMS,
  };
};

export const getQuotationLineItems = (data) => {
  return {
    type: QUOTATION_LINEITEMS,
    payload: data,
  };
};

export const getQuotationViewById = (url,history) =>{
  return {
      type: QUOTAION_VIEW_BY_ID_IN_QUOTATION,
      payload:{ url, history }
  }
}

export const getQuotationViewByIdSuccessfull = (data)=>{
  return {
      type: QUOTAION_VIEW_BY_ID_IN_SUCCESSFULL,
      payload:data
  }
}

export const updateQuotation = (url, data, history) =>{
  return {
      type: QUOTATION_UPDATE,
      payload:{ url, data, history }
  }
}

export const updateQuotationSucceefull = (data)=>{
  return {
      type: QUOTATION_UPDATE_SUCCESSFUL,
      payload: data
  }
}
