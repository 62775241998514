import {DEPARTMENT_MASTER_LIST,DEPARTMENT_MASTER_LIST_SUCCESSFULL,API_ERRORS,
  CREATE_DEPARTMENT, CREATE_DEPARTMENT_SUCCESSFULL, UPDATE_DEPARTMENT_SUCCESSFULL, UPDATE_DEPARTMENT,
} from './actionTypes';

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    DepartmentMasterList: [],
    Create_Department:[],
    Update_Department:[],
  };

  const DepartmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case DEPARTMENT_MASTER_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case DEPARTMENT_MASTER_LIST_SUCCESSFULL:
            state = {
              ...state,
              DepartmentMasterList: action.payload.response,
              loading: true,
            };
            break;
            case CREATE_DEPARTMENT:
              state = {
                ...state,
                loading: true,
              };
              break;
            case CREATE_DEPARTMENT_SUCCESSFULL:
              state = {
                ...state,
                Create_Department: action.payload.response,
                loading: true,
              };
              break;
              case UPDATE_DEPARTMENT:
              state = {
                ...state,
                loading: true,
              };
              break;
            case UPDATE_DEPARTMENT_SUCCESSFULL:
              state = {
                ...state,
                Update_Department: action.payload.response,
                loading: true,
              };
              break;
              case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default DepartmentReducer;