import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import DynamicSelect from "../../../components/Dropdown/Master/DynamicSelectMasters";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { serverApi } from "../../../helpers/Consts";
import TextFieldNormal from "../../TextField/TextFieldNormal";
import { getDepartmentList } from "../../../store/Global/DropDownApis/actions";

class SubDeptMasterFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      deptCode: null,
      deptDesc: null,
      departmentName: null,
      DepartmentList: [],
    };
  }

  componentDidMount() {
    this.props.getDepartmentList(
      serverApi.GET_DEPARTMENT_LIST +
        this.state.companyId +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    );
  }

  componentWillReceiveProps(props) {
    if (props.departmentList) {
      var list = [];

      if (props.departmentList.data) {
        props.departmentList.data.map((prop) => {
          list.push({
            label: prop.label,
            name: prop.name,
            value: prop.masterDeptId,
          });
        });
        this.setState({
          DepartmentList: list,
        });
      }
    }
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "departmentName") {
      this.setState({
        departmentName: selectedName,
      });
    }
  };

  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });

    if (key === "deptCode") {
      this.setState({
        deptCode: event,
      });
    }
    if (key === "deptDesc") {
      this.setState({
        deptDesc: event,
      });
    }
  };

  handleSubmit = () => {
    var data = {
      deptCode: this.state.deptCode !== "" ? this.state.deptCode : null,
      deptDesc: this.state.deptDesc !== "" ? this.state.deptDesc : null,
      departmentName:
        this.state.departmentName !== "Select...."
          ? this.state.departmentName
          : null,
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleClear = () => {
    this.setState({
      deptCode: null,
      deptDesc: null,
      departmentName: null,
    });
  };

  render() {
    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid spacing={0}>
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
              </div>
            </div>
            <div className="procurementFilter">
              <Grid
                item
                xs={12}
                className="filterBlockContainer juteFilterBlock"
              >
                <Box gridColumn="span 3">
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          value={this.state.deptCode}
                          placeholder={"Enter here"}
                          label="Department Code"
                          // caps="OFF"
                          onChange={this.onhandlechange("deptCode")}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          value={this.state.deptDesc}
                          placeholder={"Enter here"}
                          label="Department Name"
                          // caps="OFF"
                          onChange={this.onhandlechange("deptDesc")}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.DepartmentList}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handleSelectChange}
                          selected={this.state.departmentName}
                          update={this.props.location.state ? 1 : 0}
                          name="departmentName"
                          label="Master Department"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Box>

                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Search"}
                    className="greenButton"
                    handleClick={this.handleSubmit}
                    type="sumbit"
                  />
                </div>
              </Grid>
            </div>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}
const mapStatetoProps = (state) => {
  const { departmentList } = state.DropDownListReducer;
  return {
    departmentList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getDepartmentList,
  })(SubDeptMasterFilter)
);
