export const CHECK_LOGIN = 'check_login';
export const CHECK_TOKEN = 'check_token';
export const LOGIN_USER_SUCCESSFUL = 'login_user_successfull';
export const CHECK_TOKEN_SUCCESSFUL = 'check_token_successfull';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

export const RESET_PASSWORD = 'RESET_PASSWORD';

export const API_ERROR = 'api_failed';
