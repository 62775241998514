import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { serverApi } from "../../../helpers/Consts";
import { Grid, Box, Button, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { getQuotationHeaders } from "../../../store/Sales/Qutation/actions";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import { getTermsConditions } from "../../../store/Sales/SalesOrder/actions";

const Transportation = () => {
  const [HeaderData, setHeaderData] = useState({});
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [termsConditionsList, setTermsConditionsList] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { quoteHeader } = useSelector((state) => state.QuotationReducer);

  const { TermsConditions } = useSelector((state) => state.SalesOrderReducer);

  const userDit = JSON.parse(localStorage.getItem("authUser"));
  const companyId = localStorage.getItem("companyId");

  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setHeaderData(quoteHeader);
    window.addEventListener("scroll", handleScroll);
  }, [quoteHeader]);

  const onhandlechange = (key) => (event) => {
    if (key === "footerNotes") {
      setHeaderData((prev) => ({
        ...prev,
        footerNotes: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        footerNotes: event,
      };
      dispatch(getQuotationHeaders(UpdatedRec));
    }

    if (key === "deliveryDays") {
      setHeaderData((prev) => ({
        ...prev,
        deliveryDays: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        deliveryDays: event,
      };
      dispatch(getQuotationHeaders(UpdatedRec));
    }

    if (key === "paymentTerms") {
      setHeaderData((prev) => ({
        ...prev,
        paymentTerms: event.target.value,
      }));
      const UpdatedRec = {
        ...HeaderData,
        paymentTerms: event.target.value,
      };
      dispatch(getQuotationHeaders(UpdatedRec));
    }

    if (key === "internalNote") {
      setHeaderData((prev) => ({
        ...prev,
        internalNote: event,
      }));

      const UpdatedRec = {
        ...HeaderData,
        internalNote: event,
      };
      dispatch(getQuotationHeaders(UpdatedRec));
    }
  };

  useEffect(() => {
    const data = {
      orgId: userDit.orgId,
      autoId: "",
      termsAndConditions: "",
      startIndex: "",
      lastIndex: "",
    };
    dispatch(getTermsConditions(serverApi.GET_ALL_TERMS_LIST, data, history));
  }, []);

  useEffect(() => {
    if (TermsConditions) {
      if (TermsConditions.length !== 0) {
        var list = [];
        list.push({
          value: "",
          label: "Select",
          name: "Select",
        });

        TermsConditions.map((item) => {
          list.push({
            value: item.autoId,
            label: item.termsAndConditions,
            name: item.termsAndConditions,
          });
        });
      }
      setTermsConditionsList(list);
    }
  }, [TermsConditions]);

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };

  const handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name == "termsCondition") {
      if (selectedName === "Select") {
        setHeaderData((prev) => ({
          ...prev,
          termsCondition: "",
        }));
        const UpdatedRec = {
          ...HeaderData,
          termsCondition: "",
        };
        dispatch(getQuotationHeaders(UpdatedRec));
        return;
      }

      setHeaderData((prev) => ({
        ...prev,
        termsCondition: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        termsCondition: selectedName,
      };
      dispatch(getQuotationHeaders(UpdatedRec));
    }
  };

  return (
    <>
      <div>
        <div className="consoleFormContainer">
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Footer Notes"
                      value={HeaderData.footerNotes}
                      onChange={onhandlechange("footerNotes")}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      type="number"
                      label="Delivery Days"
                      value={HeaderData.deliveryDays}
                      onChange={onhandlechange("deliveryDays")}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>Payment Terms (In Days)</label>
                      </div>
                      <div className="consoleAdormentBlock">
                        <TextField
                          value={HeaderData.paymentTerms}
                          onChange={onhandlechange("paymentTerms")}
                          type="number"
                          placeholder="Enter here"
                          fullWidth
                          onWheel={(e) => e.target.blur()} 
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={onclick}>Days</Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <DynamicSelectNew
                      update={HeaderData.termsCondition ? 1 : 0}
                      arrayOfData={termsConditionsList}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.termsCondition}
                      name="termsCondition"
                      label="Terms & Conditions"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Internal Note"
                      value={HeaderData.internalNote}
                      onChange={onhandlechange("internalNote")}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transportation;
