/**
 *
 * @param {*} value could be an string | array | object | undefined | null
 * @returns return boolean
 * @author Pritam Shahi
 */

export const isEmpty = (value) => {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === "string" || Array.isArray(value)) {
    return value.length === 0;
  }

  if (typeof value === "object") {
    return Object.keys(value).length === 0;
  }

  return false;
};

/**
 * Limits the number of decimal places in a numeric or string value.
 * @param {string | number} value - The input value to limit decimal places.
 * @param {number} decimalPlaces - The number of decimal places to round to (default is 2).
 * @returns {string} - The value with limited decimal places as a string.
 */

export const limitDecimals = (value, decimalPlaces = 2) => {
  let floatValue;

  // Check if the value is a string and convert it to a number
  if (typeof value === "string") {
    floatValue = parseFloat(value);
  } else if (typeof value === "number") {
    // If the value is already a number, use it directly
    floatValue = value;
  }

  // Check if the conversion resulted in a valid number
  if (!isNaN(floatValue)) {
    // Round the number to the specified decimal places
    // const roundedValue = parseFloat(floatValue.toFixed(decimalPlaces));
    const roundedValue = Math.floor(floatValue * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
    // Convert the rounded value back to a string with the specified decimal places
    return Number(roundedValue).toFixed(decimalPlaces);
  }

  // If the input is not a valid number, return an empty string
  return "";
}
;

export const isPositiveNumberWithDecimals = (input, decimalPlaces = 2) => {
  const regexPattern = new RegExp(`^\\d{1,3}\\.\\d{${decimalPlaces + 1}}$`);
  return input.match(regexPattern);
};

export const isEmptyWithZero = (value) => {
  if (value <= 0 || value === null || value === undefined) {
    return true;
  }

  if (typeof value === "string" || Array.isArray(value)) {
    const trimmedValue = String(value).trim();
    const isEmptyPattern = /^0(\.0{0,4})?$/;
    return isEmptyPattern.test(trimmedValue);
  }

  if (typeof value === "object") {
    return Object.keys(value).length === 0;
  }

  return false;
};

// Dynamic UOM conversion function
export const convertPrimaryToSecondary = (pcsQuantity, conversionFactor) => {
  // Ensure that pcsQuantity is a number
  const quantityInPcs = Number(pcsQuantity);

  if (!isNaN(quantityInPcs)) {
    const convertPrimaryToSecondary = quantityInPcs * conversionFactor;

    return convertPrimaryToSecondary;
  } else {
    // Handle the case where pcsQuantity is not a valid number
    console.error("Invalid quantity value:", pcsQuantity);
    return null;
  }
};

export const QuantityOfVariableUOMconvertion= (PrimaryQuantity, QuantityOfVariable) => {
  // Ensure that PrimaryQuantity is a number
  const quantity = Number(PrimaryQuantity);

  if (!isNaN(quantity)) {
    const convertPrimaryToSecondary = QuantityOfVariable / quantity;
    return convertPrimaryToSecondary;
  } else {
    // Handle the case where PrimaryQuantity is not a valid number
    console.error("Invalid quantity value:", quantity);
    return null;
  }
};




// Dynamic rate UOM conversion function
export const convertRatePcsToMt = (Rate, conversionFactor) => {
  if (!isNaN(Rate)) {
    const conversionFactorValue = conversionFactor || 1; // Default to 1 if conversionFactor is not provided
    // const rateInMt = Number(Rate) / conversionFactorValue;  Changed because conversionFactor having 0 value make it infinity.
    const rateInMt = conversionFactorValue !== "0" ? Number(Rate) / conversionFactorValue:0;
    return rateInMt;
  } else {
    console.error('Invalid rate value:', Rate);
    return null;
  }
};


// Dynamic UOM conversion function
export const convertOtherUOMTOPrimaryUOM = (pcsQuantity, conversionFactor) => {
  // Ensure that pcsQuantity is a number
  const quantityInPcs = Number(pcsQuantity);

  if (!isNaN(quantityInPcs)) {
    const conversionFactorValue = conversionFactor || 1; // Default to 1 if conversionFactor is not provided
    const convertPrimaryToSecondary = quantityInPcs / conversionFactorValue;

    return convertPrimaryToSecondary;
  } else {
    // Handle the case where pcsQuantity is not a valid number
    console.error("Invalid quantity value:", pcsQuantity);
    return null;
  }
};

// Dynamic rate UOM conversion function
export const convertOtherRateUOmToPrimaryUOM = (Rate, conversionFactor) => {
  if (!isNaN(Rate)) {
    const conversionFactorValue = conversionFactor || 1; // Default to 1 if conversionFactor is not provided
    const rateInMt = Number(Rate) * conversionFactorValue;
    return rateInMt;
  } else {
    // Handle the case where pcsRate is not a valid number
    console.error('Invalid rate value:', Rate);
    return null;
  }
};



export const roundAndRemainingValues = (RegularInvoiceLineItems, roundoff) => {
  let LineItems = RegularInvoiceLineItems.filter(
    (item) => item.isActive != 0 && item.itemGroupId !== ""
  );

  let totalAmtt = LineItems.map((p) => p.netAmount).reduce(
    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
  );
  let totalIGST = LineItems.map((p) => p.igstAmount).reduce(
    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
  );
  let totalCGST = LineItems.map((p) => p.cgstAmount).reduce(
    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
  );
  let totalSGST = LineItems.map((p) => p.sgstAmount).reduce(
    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
  );
  let TaxTotal = limitDecimals(
    Number(totalAmtt) +
      Number(totalIGST) +
      Number(totalCGST) +
      Number(totalSGST),
    2
  );
  let positiveRF = "";
  let NegativeRF = "";
  let RoundoffValue = "";
  let splitTotal = TaxTotal.split(".");
  if (splitTotal[1] > 0) {

  let positiveVal = "0." + splitTotal[1];
  positiveRF =parseFloat(1 - positiveVal).toFixed(2);
  NegativeRF =limitDecimals("-0." + splitTotal[1]);

    if (roundoff == 1) {
      RoundoffValue = Number(TaxTotal) + Number(positiveRF);
    } else if(roundoff == 2){
      RoundoffValue = Number(TaxTotal) + Number(NegativeRF);
    }else{
      RoundoffValue = Number(TaxTotal)
    }
  }else{
    positiveRF = "0.00";
    NegativeRF = "0.00";
    RoundoffValue =TaxTotal;
  }
  return {
    positiveRF,
    NegativeRF,
    RoundoffValue,
  };
};

/**
 * Get the status based on the provided code.
 *
 * @param {number} statusCode - The status code to evaluate.
 * @returns {string} - The corresponding status.
 */

export const getStatus = (statusCode) => {
  switch (statusCode) {
    case 5:
      return "Closed";
    case 3:
      return "Approved";
    default:
      return "";
  }
}


export const attachNextYear = (year) => {
  // Convert the year to a number
  const currentYear = parseInt(year, 10);

  // Check if the year is a valid number
  if (isNaN(currentYear)) {
    return null; // Return null for invalid input
  }

  // Calculate the next year
  const nextYear = currentYear + 1;

  // Construct the result based on the rules
  let result;
  if (currentYear < nextYear) {
    result = `${currentYear}-${nextYear}`;
  } else {
    result = `${currentYear}_${nextYear}`;
  }

  return result;
}

// Example usage
console.log(attachNextYear("2023")); // Outputs: 2023-2024



