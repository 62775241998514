import React, { Component } from "react";
import { Box, Grid, Button, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import DynamicSelect from "../../StoreModule/MaterialRequest/DynamicSelect";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Fade from "@mui/material/Fade";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "../../../components/Buttons/Buttons";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import { serverApi, serverConfig } from "../../../helpers/Consts";
import { getFileDownload } from "../../../helpers/server.js";
import {
  getPendingStockPoandReceipt,
  getPendingStock,
  actions,
  logs,
  getFiles,
  getFilesSuccessfull,
  getItemsListforIndent,
  getItemGroupListforindent,
  validateItem,
} from "../../../store/Purchase/Indent/actions";
import {
  postUploadFileList,
  deleteFile,
} from "../../../store/MyProfile/UploadFile/actions";
import {
  BudgetHeads,
  getDepartmentList,
  allItemGroupMasterList,
  getTax_List,
  getItemsListForIndent,
  getTermsConditions,
} from "../../../store/Global/DropDownApis/actions";
import {getItemListByItemGroupForPurchase,getItemDetailsByItemItemGrpForPurchase} from "../../../store/Purchase/PurchaseOrder/actions.js"
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import DeleteIcons from "../../../assets/images/picEditIcon.png";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import swal from "sweetalert";
import { POWOLineItemsProps } from "../../../store/Purchase/PurchaseOrder/actions";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import ReactTooltip from "react-tooltip";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { QtyCalculation } from "../../../Utilities/QtyCalculation";
import { InvoiceSection } from "../../../components/InvoiceDetailsSection";

class POLineItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCount: 0,
      itemsList: [],
      userDit: JSON.parse(localStorage.getItem("authUser")),
      taxList: [],
      isChecked: true,
      DepartmentList: [],
      ItemGroupMasterList: [],
      advancePercentage: this.props.PoFillDetails.advancePercentage,
      advanceAmount: this.props.PoFillDetails.advanceAmount,
      termsNconditionsList: [],
      termsNconditions: this.props.PoFillDetails.termsNconditions,
      selectTermsNconditions: this.props.PoFillDetails.selectTermsNconditions,
      budgetHeadsList: [],
      poDetails: [],
      files_List: [],
      Logs: [],
      totalAmount: this.props.PoFillDetails.totalAmount,
      totalIgst: this.props.PoFillDetails.totalIgst,
      totalCgst: this.props.PoFillDetails.totalCgst,
      totalSgst: this.props.PoFillDetails.totalSgst,
      netTotal: this.props.PoFillDetails.netTotal,
      lineitemId: 1,
      uploadFile: false,
      seeLogs: false,
      othercharges: false,
      otherChargesItemList: [],
      isDropdownOpen: false,
      deptDisplayTxtField: {},
      deptDisplayDropField: {},
      locationState: "",
    };
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);
    this.props.getTermsConditions(
      serverApi.TERMS_AND_CONDITIONS_DATA +
        localStorage.getItem("companyId") +
        "/PO",
      this.props.history
    );
    if (this.props.location.state) {
      let id = this.props.location.state.rowData.id;
      this.props.getFiles(
        serverApi.GET_FILES +
          "14/" +
          id +
          "/" +
          localStorage.getItem("companyId"),
        this.props.history
      );
      if (this.props.po_wo_lineitems) {
        this.props.po_wo_lineitems.map((item) => {
          if (item.discountMode === 1) {
            let totalAmt = item.qty * item.rate;
            let discountAmt = totalAmt - Number(item.discountPercentage);
            item.amount = discountAmt;
            this.setState({
              totalAmount: item.amount,
            });
            this.props.PoFillDetails.totalAmount = item.amount;
          } else {
            let totalAmt = item.qty * item.rate;
            let discountAmt =
              (totalAmt * Number(item.discountPercentage)) / 100;
            item.amount = totalAmt - discountAmt;
            this.setState({
              totalAmount: item.amount,
            });
            this.props.PoFillDetails.totalAmount = item.amount;
          }

          ///for gst
          let taxAmount =
            (this.props.PoFillDetails.totalAmount * Number(item.taxId)) / 100;
          if (this.props.PoFillDetails.tax_type_value === 1) {
            this.setState({
              totalIgst: taxAmount,
              totalCgst: 0,
              totalSgst: 0,
            });
            this.props.PoFillDetails.totalIgst = taxAmount;
          } else {
            this.setState({
              totalCgst: taxAmount / 2,
              totalSgst: taxAmount / 2,
              totalIgst: 0,
            });
            this.props.PoFillDetails.totalCgst = taxAmount / 2;
            this.props.PoFillDetails.totalSgst = taxAmount / 2;
          }
        });
      }
    }

    if (
      this.props.po_wo_lineitems &&
      this.props.po_wo_lineitems !== undefined
    ) {
    } else {
      let poDetails = [
        {
          budgetHeadId: null,
          ctaxPercentage: "0.00",
          departmentId: "",
          description: null,
          discountMode: "",
          discountPercentage: "0.00",
          hsnCode: null,
          id: 1,
          indentDetailsId: null,
          indentId: null,
          installationAmount: "0.00",
          installationRate: "0.00",
          isActive: 1,
          iTaxPercentage: "0.00",
          itemId: "",
          make: "",
          oldQty: "0.000",
          qohBranch: 0.0,
          qohCompany: 0.0,
          qty: "0.000",
          rate: "0.000",
          remarks: "",
          staxPercentage: "0.00",
          tax: "0.00",
          taxId: 0,
          taxPercentage: null,
          uomCode: null,
          amount: "0.00",
        },
      ];
      this.props.POWOLineItemsProps(poDetails);
    }

    if (this.props.Upload_File_List) {
      if (this.props.location.state) {
        let id = this.props.location.state.rowData.id;
        this.props.getFiles(
          serverApi.GET_FILES +
            "14/" +
            id +
            "/" +
            localStorage.getItem("companyId"),
          this.props.history
        );
      }
    }
  }

  componentWillReceiveProps(props) {
    if (props.itemsListForIndent) {
      let list = [];
      if (this.state.othercharges === false) {
        let itemsList = props.itemsListForIndent;
        itemsList.map((prop) => {
          list.push({
            label: prop.label,
            name: prop.name,
            value: prop.value,
            variant: prop.itemCode,
          });
        });
        let newList = itemsList.filter((prop) => {
          if (prop.value !== "0") {
            return prop;
          }
        });

        this.setState({
          itemsList: newList,
        });
      } else {
        let itemsList = props.itemsListForIndent;
        itemsList.map((prop) => {
          list.push({
            label: prop.label,
            name: prop.name,
            value: prop.value,
            variant: prop.itemCode,
          });
        });
        let newList = itemsList.filter((prop) => {
          if (prop.value !== "0") {
            return prop;
          }
        });

        this.setState({
          otherChargesItemList: newList,
        });
      }
    }
    if (props.taxList) {
      if (props.taxList.data) {
        let newList = props.taxList.data.filter((prop) => {
          if (prop.value !== 0) {
            return prop;
          }
        });
        this.setState({
          taxList: newList,
        });
      }
    }
    if (props.location.state) {
      if (props.budgetHeadsList) {
        this.setState({
          budgetHeadsList: props.budgetHeadsList.data,
        });
      }
    }
    if (props.departmentList) {
      if (props.departmentList.data) {
        let newList = props.departmentList.data.filter((prop) => {
          if (prop.value !== 0) {
            return prop;
          }
        });

        this.setState({
          DepartmentList: newList,
        });
      }
    }
    if (props.allItemGroupMaster_List) {
      if (props.allItemGroupMaster_List.data) {
        let newList = props.allItemGroupMaster_List.data.filter((prop) => {
          if (prop.value !== "0") {
            return prop;
          }
        });

        this.setState({
          ItemGroupMasterList: newList,
        });
      }
    }

    if (props.po_wo_lineitems) {
      this.setState({
        poDetails: props.po_wo_lineitems,
      });
    }

    if (props.TermsConditions) {
      if (props.TermsConditions.length !== 0) {
        var TandSlist = [];
        props.TermsConditions.map((item) => {
          TandSlist.push({
            value: item.autoId,
            label: item.termsAndConditions,
            name: item.termsAndConditions,
          });
        });
      }
      this.setState({
        termsNconditionsList: TandSlist,
      });
    }
    if (this.state.seeLogs === true) {
      if (props.logs_List) {
        if (props.logs_List.data.length !== 0) {
          this.setState({
            Logs: props.logs_List.data,
          });
        } else {
          this.setState({
            Logs: [],
          });
        }
      }
    }
    if (this.state.seeLogs === false) {
      if (props.files_List) {
        if (props.files_List.data) {
          let filesList = props.files_List.data;
          this.setState({
            files_List: filesList.data,
          });
        }
      }
    }
    if (this.state.uploadFile === true) {
      if (this.state.fileName !== "") {
        if (props.Upload_File_List) {
          var Filedata = props.Upload_File_List.data;
          if (props.Upload_File_List.status) {
            this.setState({
              uploadFile: false,
            });
            if (this.state.files_List !== undefined) {
              var arr = this.state.files_List;
              Filedata = {
                fileUploadId: Filedata.id,
                fileName: this.state.fileName,
                fileExtension: this.state.fileType,
                retrievalPath: Filedata.fileUrl,
                sourceMenu: 14,
              };
              arr.splice(arr.length, 0, Filedata);
              this.setState({
                files_List: arr,
              });
            }
          }
        }
      }
    }
    if (props.logs_List) {
      this.setState({
        Logs: props.logs_List.data,
      });
    }

    if (props.pendingStock) {
      if (props.pendingStock.data !== null) {
        if (props.pendingStock.id !== undefined) {
          let poDetails = this.state.poDetails.filter((rowItem) => {
            if (rowItem.id !== 0 && props.pendingStock.id == rowItem.itemId) {
              rowItem.qohBranch = props.pendingStock.data;
            }
            return rowItem;
          });
        }
      }
    }
    if (props.pendingStockPoandReceipt) {
      if (props.pendingStockPoandReceipt.data !== null) {
        let poDetails = this.state.poDetails.filter((rowItem) => {
          if (props.pendingStockPoandReceipt.id == rowItem.itemId) {
            rowItem.qohCompany = props.pendingStockPoandReceipt.data;
          }
          return rowItem;
        });
      }
    }
  }

  onhandleChange = (key) => (value) => {
    this.setState({ [key]: value });
    if (key === "advance_per") {
      // let valuedata = value.replace(/[^0-9]/g, "");
      let valuedata = value;
      if (valuedata >= 0 && valuedata <= 99) {
        if (!valuedata.match(/^\d{1,10}\.\d{3}$/)) {
          this.setState({
            advancePercentage: valuedata,
          });
          this.props.PoFillDetails.advancePercentage = valuedata;
          let totalAmount = this.props.PoFillDetails.totalAmount;
          let advanceAmount = parseFloat(
            (totalAmount * valuedata) / 100
          ).toFixed(2);
          this.setState({
            advanceAmount: Number(advanceAmount),
          });
          this.props.PoFillDetails.advanceAmount = Number(advanceAmount);
        } else {
          swal("Can't Enter more than 2 Decimals!");
        }
      } else {
        swal("Advance Percentage should be below 100%");
      }
    }
    if (key === "advance_amt") {
      if (this.state.advanceAmount) {
        if (this.state.advanceAmount === "") {
        } else {
          this.props.PoFillDetails.advanceAmount = value;
          this.setState({
            advanceAmount: value,
          });
        }
      }
    }
    if (key === "term&Con") {
      this.props.PoFillDetails.selectTermsNconditions = value;
      this.setState({
        selectTermsNconditions: value,
      });
    }
  };
  handleBlur = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    this.setState({ [key]: event });
    let mname = key.split("_");
    if (mname[0] === "qty") {
      let poDetails = this.state.poDetails.filter((dataVal) => {
        if (dataVal.id === row.id) {
          let remainqty =
            row.remainingQuantity === undefined
              ? 0.0
              : Number(row.remainingQuantity).toFixed(3);
          let indentqty = Number(row.oldQty);
          let qty = Number(value);
          let minqty =
            row.minStock === undefined ? 0.0 : Number(row.minStock).toFixed(3);
          let maxqty =
            row.maxStock === undefined ? 0.0 : Number(row.maxStock).toFixed(3);
          let branchqty = Number(row.qohBranch).toFixed(3);
          let centralqty = Number(row.qohCompany).toFixed(3);
          let updatetype = this.props.location.state !== undefined ? 0 : 1;
          let minAllowedQty = Number(row.minAllowedQty);
          let maxAllowedQty = Number(row.maxAllowedQty);
          let tangible = row.tangible;
          console.log(this.props.source, "this.props.source")
          let type =
            this.props.source === "WITHOUTINDENT" ||
            this.props.source === "WITHOUTBOQ" ||
            this.props.source === "WITHOPENINDENT" || 
            this.props.source === "OPENINDENT"
              ? "CASE3"
              : "CASE2";
          console.log(type, "type")
          let poqty = Number(row.qty).toFixed(3);
          let reOrderQty =
            row.reOrderQty === undefined
              ? 0.0
              : Number(row.reOrderQty).toFixed(3);
          let resultqty = QtyCalculation({
            type,
            remainqty,
            indentqty,
            qty,
            minqty,
            maxqty,
            branchqty,
            centralqty,
            updatetype,
            poqty,
            reOrderQty,
            minAllowedQty,
            maxAllowedQty,
            tangible,
          });
          
          if (
            this.props.source === "WITHOUTINDENT" &&
            (this.props.PoFillDetails.category_type_value === "7" ||
            this.props.PoFillDetails.category_type_value === "4")
          ) {
            row.qty = value;
            row.amount = Number(row.rate) * value;
          } else {
            if (resultqty.error === true) {
              swal(resultqty.message);
              row.qty = resultqty.value;
            }
          }
        }
        return dataVal;
      });
      this.setState({
        poDetails: poDetails,
      });
      this.props.POWOLineItemsProps(poDetails);
    }
  };
  handleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    this.setState({ [key]: event });
    let mname = key.split("_");
    if (mname[0] === "price") {
      if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
        row.rate = value;
        let qunatity = row.itemGroupId === 997 ? 1 : Number(row.qty);
        row.amount = Number(row.rate) * qunatity;
        this.setState({
          totalAmount: Number(row.rate) * qunatity,
        });
        this.props.PoFillDetails.totalAmount = Number(row.rate) * qunatity;
      } else {
        swal("Can't Enter more than 3 Decimals!");
      }
    }
    if (mname[0] === "qty") {
      let poDetails = this.state.poDetails.filter((dataVal) => {
        if (dataVal.id === row.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            row.qty = value;
          } else {
            swal("Can't Enter more than 3 Decimals!");
          }

          let qunatity = row.itemGroupId === 997 ? 1 : Number(row.qty);
          row.amount = Number(row.rate) * qunatity;
          this.setState({
            totalAmount: Number(row.rate) * qunatity,
          });
          this.props.PoFillDetails.totalAmount = Number(row.rate) * qunatity;
          // }
        }
        return dataVal;
      });
      this.setState({
        poDetails: poDetails,
      });
      this.props.POWOLineItemsProps(poDetails);
    }
    if (mname[0] === "discountPercentage") {
      if (value < 0) {
        swal("Discount value Shouldn't be less than 0");
        row.discountPercentage = 0;
      } else {
        if (!value.match(/^\d{1,2}\.\d{3}$/)) {
          row.discountPercentage = value;
          if (row.discountMode === 1) {
            if (!value.match(/^\d{1,2}\.\d{3}$/)) {
              let totalAmt = Number(row.qty) * row.rate;
              let discountAmt = totalAmt - Number(value);
              if (value > totalAmt) {
                swal("Discount amount can't be greater than Total Amount");
                row.amount = totalAmt;
                row.discountPercentage = 0;
                this.setState({
                  totalAmount: totalAmt,
                });
              } else {
                row.amount = discountAmt;
                this.setState({
                  totalAmount: row.amount,
                });
                this.props.PoFillDetails.totalAmount = row.amount;
              }
            } else {
              swal("Can't Enter more than 2 Decimals!");
            }
          } else {
            if (value > 100) {
              if (!value.match(/^\d{1,2}\.\d{4}$/)) {
                swal("Discount value Shouldn't be greater than 100");
                row.discountPercentage = 0;
                let totalAmt = Number(row.qty) * row.rate;
                row.amount = totalAmt;
              } else {
                swal("Can't Enter more than 2 Decimals!");
              }
            } else {
              if (value) {
                let totalAmt = Number(row.qty) * row.rate;
                let discountAmt = (totalAmt * Number(value)) / 100;
                totalAmt = totalAmt - discountAmt;
                row.amount = totalAmt;
                this.setState({
                  totalAmount: row.amount,
                });
                this.props.PoFillDetails.totalAmount = row.amount;
              } else {
                let totalAmt = Number(row.qty) * row.rate;
                row.amount = totalAmt;
              }
            }
          }
        } else {
          swal("Can't Enter more than 2 Decimals!");
        }
      }
    }
    if (mname[0] === "HSN/SAC") {
      row.hsnCode = value;
    }
    if (mname[0] === "remarks") {
      row.remarks = value;
    }
    if (mname[0] === "make") {
      row.make = value;
    }
    this.props.POWOLineItemsProps(this.state.poDetails);
  };

  handleValidateItem = (res, dropdowndata) => {
    if (res) {
      if (res.data) {
        if (res.data.message) {
          if (res.data.message !== "Indent Not Found") {
            swal(res.data.message, { icon: "error" });
            dropdowndata.row.itemId = "";
            dropdowndata.row.itemCode = "";
            dropdowndata.row.qty = "0.000";
            dropdowndata.row.uomCode = "";
            dropdowndata.row.discountMode = "";
            dropdowndata.row.discountPercentage = "0.00";
            dropdowndata.row.iTaxPercentage = "";
            dropdowndata.row.tax = "";
            dropdowndata.row.taxId = "";
            dropdowndata.row.taxPercentage = "";
            dropdowndata.row.remarks = "";
            dropdowndata.row.itemName = "";
            dropdowndata.row.rate = "0.000";
            dropdowndata.row.make = "";
          } else {
            if (dropdowndata.row.id === dropdowndata.selectedRow.id) {
              if (this.props.itemDetails) {
                if (this.props.itemDetails.data) {
                  let itemDetails = this.props.itemDetails.data;
                   dropdowndata.row.rate =
                    itemDetails.srRate === "null"
                      ? 0
                      : parseInt(itemDetails.srRate);
                  dropdowndata.row.uomCode = itemDetails.uomCode;
                  dropdowndata.row.itemId = dropdowndata.selectedValue;
                  dropdowndata.row.itemName = dropdowndata.selectedName;
                  dropdowndata.row.itemCode = dropdowndata.selectedItemCode;
                  dropdowndata.row.isActive = 1;
                  dropdowndata.row.minStock = itemDetails.minQty;
                  dropdowndata.row.maxStock = itemDetails.maxQty;
                  dropdowndata.row.minAllowedQty = itemDetails.minAllowableQty;
                  dropdowndata.row.maxAllowedQty = itemDetails.maxAllowableQty;
                  dropdowndata.row.reOrderQty = itemDetails.reorderQty;
                  dropdowndata.row.qohCompany = itemDetails.centralStock;
                  dropdowndata.row.hsnCode= itemDetails.hsnCode;
                  dropdowndata.row.taxPercentage= itemDetails.taxPercentage
                  dropdowndata.row.taxId = itemDetails.taxId;
                  dropdowndata.row.qty = Number(dropdowndata.row.itemGroupId) === 997?1:dropdowndata.row.qty

                }
              }
            }
            this.setState({
              rowCount: this.state.rowCount + 1,
            });

            const data1 = {
              companyId: localStorage.getItem("companyId"),
              itemId: dropdowndata.selectedValue,
              branchId: this.props.poDetails.shippingAddress_Value,
            };
            this.props.getPendingStock(
              serverApi.GET_PENDING_STOCK_BRANCH_WISE,
              data1,
              this.props.history,
              dropdowndata.selectedRow.itemId
            );
            const data2 = {
              companyId: localStorage.getItem("companyId"),
              itemId: dropdowndata.selectedRow.itemId,
            };
            this.props.getPendingStockPoandReceipt(
              serverApi.GET_CENTRAL_STOCK,
              data2,
              this.props.history,
              dropdowndata.selectedRow.itemId
            );
          }
          return dropdowndata.row;
        }
      }
    }
  };

  getItemDetails = (response, itemId)=>{
    if (response) {
          let poDetails = this.state.poDetails.filter((data) => {
            if (data.itemId === itemId) {
              data.uomCode = response.data.uomCode;
              data.rate = response.data.srRate;
              data.hsnCode = response.data.hsnCode;
              data.taxId = response.data.taxId;
              data.taxPercentage = response.data.taxPercentage;
              data.qty = data.itemGroupId === 997 ? 1 : data.qty;
              return data;
            }
            return data;
          });
  
          this.setState({
            poDetails: poDetails,
          });
  
          this.props.POWOLineItemsProps(poDetails);
      }
  }

  handleChangedropdown = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    selectedItemCode
  ) => {
    if (name === "department") {
      //this code is to check the item duplication.......
      if (selectedRow.departmentId === selectedValue) {
        var checkDept = !!this.state.poDetails.find(
          ({ departmentId }) => departmentId === selectedValue
        );
      }
      if (!checkDept) {
        selectedRow.itemGroupId = "";
        selectedRow.itemGroupName = "";
        selectedRow.itemId = "";
        selectedRow.itemCode = "";
        selectedRow.qty = "0.000";
        selectedRow.uomCode = "";
        selectedRow.discountMode = "";
        selectedRow.discountPercentage = "0.00";
        selectedRow.iTaxPercentage = "";
        selectedRow.tax = "";
        selectedRow.taxId = "";
        selectedRow.taxPercentage = "0.00";
        selectedRow.remarks = "";
        selectedRow.itemName = "";
        selectedRow.rate = "0.000";
        selectedRow.make = "";
        selectedRow.amount = "";
        this.props.getItemGroupListforindent(
          serverApi.GET_ITEMGROUP_LIST +
            selectedValue +
            "/" +
            localStorage.getItem("companyId") +
            "/getAllItemGroupsByDepartmentId/" +
            this.state.userDit.cipher,
          this.props.history,
          this.getitemListByitemgrpId,
          selectedValue
        );
      }
      this.displayField(selectedRow, "department", 2);
      selectedRow.departmentId = selectedValue;
      selectedRow.departmentName = selectedName;
      let lastVal = selectedRow.id;
      let lastRow = this.state.poDetails[this.state.poDetails.length - 1];
      if (selectedRow === lastRow && selectedValue !== "0" && lastVal === "0") {
        selectedRow.amount = selectedRow.rate * selectedRow.qty;
      }
      var newline = 1;

      var polineitems = this.state.poDetails.filter((row) => {
        newline = Number(row.id);
        return row;
      });

      let obj = {
        indentId: null,
        indentDetailsId: null,
        id: Number(newline) + 1,
        budgetHeadId: "",
        budgetHeadName: "",
        departmentId: "",
        departmentName: "",
        itemId: "",
        itemName: "",
        itemGroup: "",
        itemGroupId: "",
        itemGroupName: "",
        itemCode: "",
        qty: "0.000",
        qtyPo: "0.000",
        qohBranch: 0.0,
        qohCompany: 0.0,
        rate: "0.000",
        tax: "0.00",
        taxId: 0,
        ctaxPercentage: "0.00",
        staxPercentage: "0.00",
        iTaxPercentage: "0.00",
        description: "",
        discountMode: "",
        discountPercentage: "0.00",
        hsnCode: "",
        uomCode: null,
        installationRate: "0.000",
        installationAmount: "0.000",
        make: "",
        remarks: "",
        taxPercentage: null,
        oldQty: "0.000",
        isActive: 1,
        amount: "0.00",
        currentStock: "0.000",
        minStock: "0.000",
        maxStock: "0.000",
        reOrderQty: "0.000",
      };
      if (selectedRow === lastRow) {
        polineitems.push(obj);
      }
      this.setState({
        poDetails: polineitems,
        lineItemId: selectedRow.lineItemId,
      });
    }
    if (name === "itemGroup") {
      this.displayField(selectedRow, "itemGroup", 2);
      //this code is to check the item duplication.......
      if (
        selectedRow.itemGroupId === selectedValue ||
        selectedRow.itemGroup === selectedValue
      ) {
        var checkItemGroupId = !!this.state.poDetails.find(
          ({ itemGroupId }) => itemGroupId === selectedValue
        );
      }

      selectedRow.itemGroup = selectedValue;
      selectedRow.itemGroupName = selectedName;
      this.props.PoFillDetails.itemGroup_Value = selectedValue;
      selectedRow.itemGroupId = selectedValue;
      if (!checkItemGroupId) {
        selectedRow.itemId = "";
        selectedRow.itemCode = "";
        selectedRow.qty = "0.000";
        selectedRow.uomCode = "";
        selectedRow.discountMode = "";
        selectedRow.discountPercentage = "0.00";
        selectedRow.iTaxPercentage = "";
        selectedRow.tax = "";
        selectedRow.taxId = "";
        selectedRow.taxPercentage = "";
        selectedRow.remarks = "";
        selectedRow.itemName = "";
        selectedRow.rate = "0.000";
        selectedRow.make = "";
      }
      const indentTypeId =
        selectedValue === "997"
          ? ""
          : this.props.PoFillDetails.category_type_value;
      if (
        selectedRow.itemGroupId !== "" &&
        selectedRow.itemGroupId !== undefined
      ) {
        // const data = {
        //   companyId: localStorage.getItem("companyId"),
        //   consumable: "",
        //   indentTypeId: indentTypeId,
        //   itemGroupId: selectedValue,
        //   saleable: "",
        //   branchId: this.props.PoFillDetails.shippingAddress_Value,
        //   // tangible:"Y"
        //   // this.props.location.pathname === "/create_purchase_order" ? "Y" : "N",
        // };

        const data = {
          companyId: localStorage.getItem("companyId"),
          
          itemGroupId: selectedValue,
          // saleable: "",
          // branchId: this.props.PoFillDetails.shippingAddress_Value,
          // tangible:"Y"
          // this.props.location.pathname === "/create_purchase_order" ? "Y" : "N",
        };

        // this.props.getItemsListforIndent(
        //   serverApi.GET_ITEMSBY_FORPROCUREMENT,
        //   data,
        //   this.getitemListByitemgrpId,
        //   selectedRow.itemGroupId
        // );

        // getItemListByItemGroupForPurchase
        this.props.getItemListByItemGroupForPurchase(
          serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
          data,
          this.getitemListByitemgrpId,
          selectedRow.itemGroupId
        );
      }
    }
    if (name === "itemId") {
      const data = {
        itemGroupId: selectedRow.itemGroupId,
        companyId: localStorage.getItem("companyId"),
        itemId:selectedValue,
        branchId: this.props.PoFillDetails.shippingAddress_Value,
    }
    this.props.getItemDetailsByItemItemGrpForPurchase(serverApi.GET_ITEMDETAILS_BYITEM_ITEMGRP_COMPANYID,data,this.getItemDetails,selectedValue)
      this.displayField(selectedRow, "itemId", 2);

      if (selectedRow.itemId !== selectedValue) {
        var filteredPODetails = this.state.poDetails.filter(item => item.isActive !== 0);
        var check = !!filteredPODetails.find(({ itemId }) => Number(itemId) === Number(selectedValue));
      } else {
        //this code is to check the item duplication.......
        if (selectedRow.itemId === selectedValue) {
          var checkItem = !!this.state.poDetails.find(
            ({ itemId }) => itemId === selectedValue
          );
        }
      }
      if (!checkItem) {
        selectedRow.qty = "0.000";
        selectedRow.discountMode = "";
        selectedRow.discountPercentage = "0.00";
        selectedRow.iTaxPercentage = "";
        selectedRow.tax = "";
        selectedRow.taxId = "";
        selectedRow.taxPercentage = "";
        selectedRow.remarks = "";
        selectedRow.make = "";
      }
      if (check) {
        swal("You can't create the record with the duplicate item");
      } else {
        let poDetails = this.state.poDetails.filter((data) => {
          if (data.id === selectedRow.id) {
          data.itemId = selectedValue;
          data.itemName = selectedName;
          data.itemCode = selectedItemCode;
          data.remarks = "";
          data.make ="";
            const Changedropdowndata = {
              selectedValue: selectedValue,
              selectedName: selectedName,
              name: name,
              selectedRow: selectedRow,
              selectedItemCode: selectedItemCode,
              row: data,
            };
            data = this.props.validateItem(
              serverApi.VALIDATE_ITEM_OI +
                selectedValue +
                "/" +
                localStorage.getItem("companyId") +
                "/" +
                localStorage.getItem("acadamicYear") +
                "/" +
                this.props.PoFillDetails.shippingAddress_Value,
              this.handleValidateItem,
              Changedropdowndata
            ); ///calling the Item validation API with callback function(handleValidateItem) and setting item values accordingly in callback function(handleValidateItem).......

          }
          return data;
        });

        this.setState({
          poDetails: poDetails,
        });

        this.props.POWOLineItemsProps(poDetails);
      }
    }
    if (name === "tax") {
      this.displayField(selectedRow, "tax", 2);

      let poDetails = this.state.poDetails.filter((data) => {
        if (data.id === selectedRow.id) {
          let selTaxName = selectedName.split("^");
          selectedRow.tax = selTaxName[0];
          selectedRow.taxId = selectedValue;
          // selectedRow.taxPercentage = selectedValue;
          selectedRow.taxPercentage = Number(selTaxName[1]);

          if (this.props.PoFillDetails.tax_type_value === 1) {
            selectedRow.iTaxPercentage = selTaxName[1];
            // selectedRow.taxPercentage = Number(selTaxName[1]);
          } else {
            selectedRow.ctaxPercentage = Number(selTaxName[1]) / 2;
            selectedRow.staxPercentage = Number(selTaxName[1]) / 2;
          }
        }
        return data;
      });

      this.setState({
        poDetails: poDetails,
      });
      this.props.POWOLineItemsProps(poDetails);
    }
    if (name === "discountMode") {
      this.displayField(selectedRow, "discountMode", 2);
      let poDetails = this.state.poDetails.filter((data) => {
        if (data.id === selectedRow.id) {
          selectedRow.discountMode = selectedValue;
          selectedRow.discountPercentage = "";
        }
        return data;
      });
      this.setState({
        poDetails: poDetails,
      });
      this.props.POWOLineItemsProps(poDetails);
    }
    this.props.POWOLineItemsProps(this.state.poDetails);
  };

  onDelEvent = (key) => (row) => {
    this.setState({ [key]: row });

    this.setState({
      delete: true,
    });
  };
  onDel(e) {
    this.setState({
      delete: false,
    });
  }

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "termsNconditions") {
      this.props.PoFillDetails.termsNconditions = selectedName;
      this.props.PoFillDetails.selectTermsNconditions = selectedName;
      this.setState({
        termsNconditions: selectedName,
        selectTermsNconditions: selectedName,
      });
    }
  };

  UploadDocuments = (key) => (e) => {
    let taskuqId = this.props.locationState.state.rowData.id;
    e.preventDefault();
    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 14);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", taskuqId);
    formData.append("createdBy", this.state.userDit.userId);
    formData.append("fileCompanyId", localStorage.getItem("companyId"));
    this.setState({
      fileName: file.name,
      fileType: fileExt,
      uploadFile: true,
    });
    this.props.postUploadFileList(
      serverApi.UPLOADFILE,
      formData,
      this.props.history
    );
  };
  onDeleteFile = (fileId) => {
    let data = this.state.files_List.filter(
      (item) => item.fileUploadId !== fileId
    );
    this.props.deleteFile(serverApi.DELETEFILE + fileId);
    this.setState({
      files_List: data,
    });
    this.props.getFilesSuccessfull({ data });
  };

  onClickToSeeLogs = () => {
    this.setState({
      uploadFile: false,
      seeLogs: true,
    });
    if (this.props.location.state) {
      let id = this.props.location.state.rowData.id;
      this.props.logs(serverApi.SEE_THE_LOGSOF_PO + id, this.props.history);
    }
  };

  onDelLineItem = (row) => {
    let lastRow = this.state.poDetails[this.state.poDetails.length - 1];
    let selectedRow = row.itemId;

    if (lastRow === row && selectedRow === "") {
      swal("You can't delete the data");
    } else {
      var indexitem = this.state.poDetails.findIndex((item) => item == row);
      var lineItems = this.state.poDetails;
      lineItems[indexitem].isActive = 0;
      this.setState({
        poDetails: lineItems,
      });
      this.props.POWOLineItemsProps(lineItems);
    }

    if (lineItems !== null && lineItems !== undefined) {
      var total = lineItems
        .filter((item) => item.isActive != 0 && item.itemId !== "")
        .map((p) => p.amount)
        .reduce((igst, ind) => parseFloat(igst) + parseFloat(ind));
    }

    let advanceAmount = parseFloat(
      (total * this.props.PoFillDetails.advancePercentage) / 100
    ).toFixed(2);
    this.setState({
      advanceAmount: Number(advanceAmount),
    });
    this.props.PoFillDetails.advanceAmount = Number(advanceAmount);
  };
  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  getAmount = (
    qty,
    qtyPo,
    rate,
    discountMode,
    discountPercentage,
    itemGroupId
  ) => {
    let amount = 0;
    if (discountMode) {
      if (discountMode === 1) {
        amount =
          // (Number(qty) - (qtyPo === undefined ? 0 : Number(qtyPo)))
          Number(qty) * Number(rate);
        if (discountPercentage > amount) {
          amount = amount;
        } else {
          if (
            discountPercentage !== 0 &&
            discountPercentage !== undefined &&
            discountPercentage !== ""
          ) {
            amount = amount - Number(discountPercentage);
          }
        }
        // return amount;
        return parseFloat(amount).toFixed(2);
      } else {
        // (Number(qty) - (qtyPo === undefined ? 0 : Number(qtyPo)))
        if (itemGroupId == 997) {
          amount = 1 * Number(rate);
        } else {
          amount = Number(qty) * Number(rate);
        }
        if (
          discountPercentage !== 0 &&
          discountPercentage !== undefined &&
          discountPercentage !== ""
        ) {
          let disAmount = (amount * Number(discountPercentage)) / 100;
          amount = amount - disAmount;
        }
        // return amount;
        return parseFloat(amount).toFixed(2);
      }
    } else {
      // amount =
      // (Number(qty) - (qtyPo === undefined ? 0 : Number(qtyPo)))
      // Number(qty) * Number(rate);
      if (itemGroupId == 997) {
        amount = 1 * Number(rate);
      } else {
        amount = Number(qty) * Number(rate);
      }
      return parseFloat(amount).toFixed(2);
      // return amount;
    }
  };

  ///to add other charges
  addOtherCharges = () => {
    this.props.getItemGroupListforindent(
      serverApi.GET_ITEMGROUP_LIST +
        29 +
        "/" +
        localStorage.getItem("companyId") +
        "/getAllItemGroupsByDepartmentId/" +
        this.state.userDit.cipher,
      this.props.history,
      this.getitemListByitemgrpId,
      29
    );
    const data = {
      itemGroupId: "997",
      // saleable: "",
      // consumable: "",
      companyId: localStorage.getItem("companyId"),
      // indentTypeId: "",
      // branchId: this.props.PoFillDetails.shippingAddress_Value,
    };
    // this.props.getItemsListforIndent(
    //   serverApi.GET_ITEMSBY_FORPROCUREMENT,
    //   data,
    //   this.getitemListByitemgrpId,
    //   "997"
    // );

    this.props.getItemListByItemGroupForPurchase(
      serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
      data,
      this.getitemListByitemgrpId,
      "997"
    );
    this.setState({
      othercharges: true,
    });
    let newline = 1;
    let filteredproducts = this.state.poDetails.map((item) => {
      newline = Number(item.id);
      return item;
    });
    let obj = {
      budgetHeadId: null,
      budgetHeadName: null,
      cancelledBy: null,
      cancelledByUser: null,
      cancelledDate: null,
      cancelledQty: null,
      cancelledReasons: null,
      categoryId: null,
      costcenterId: null,
      costcenterName: null,
      departmentId: 29,
      departmentName: "GENERAL(EJM)",
      description: null,
      discountMode: "",
      discountPercentage: 0,
      expectedDeliveryDate: null,
      expenseTypeName: null,
      id: filteredproducts.length + 1,
      indentId: null,
      indentName: null,
      installationRate: null,
      isActive: 1,
      itemCode: "",
      itemGroup: "Other Charges",
      itemGroupName: "Other Charges",
      itemGroupId: 997,
      itemId: null,
      itemName: "",
      itemList: [],
      lastPurchaseDate: null,
      lastPurchaseRate: null,
      make: "",
      hsnCode:"",
      qohBranch: 0,
      qohCompany: 0,
      qohProject: 0,
      qty: 1,
      qtyPo: null,
      qtyPoOpen: null,
      rate: 0,
      rateSource: 1,
      remainingQuantity: null,
      remarks: "",
      selectedExpenseType: null,
      selectedSubExpenseType: null,
      taxId: 0,
      taxPercentage: "",
      uomCode: "",
    };

    if (this.props.location.state !== undefined) {
      if (
        this.props.source === "WITHOUTINDENT" ||
        this.props.source === "WITHOUTBOQ"
      ) {
        filteredproducts.splice(filteredproducts.length - 1, 0, obj);
      } else {
        filteredproducts.splice(filteredproducts.length, 0, obj);
      }
    } else {
      if (
        this.props.source === "WITHINDENT" ||
        this.props.source === "WITHOPENINDENT" ||
        this.props.source === "WITHBOM" ||
        this.props.source === "WITHBOQ"
      ) {
        // new Code
        if (filteredproducts.length === 1) {
          filteredproducts.splice(1, 0, obj);
        } else {
          filteredproducts.splice(filteredproducts.length, 0, obj);
        }
      } else {
        // new code
        if (filteredproducts.length === 1) {
          filteredproducts.splice(1, 0, obj);
        } else {
          filteredproducts.splice(filteredproducts.length - 1, 0, obj);
        }
      }
    }
    this.setState({
      poDetails: filteredproducts,
    });
    this.props.POWOLineItemsProps(filteredproducts);
  };

  handleEventPositioned = (info, item) => {
    console.log(item, item.qohBranch, "item.qohBranch")
    if (info.target) {
      info.target.setAttribute(
        "data-tip",
        `
            <p>Central Stock = ${Number(
              item.qohCompany === "" ? 0 : item.qohCompany
            ).toFixed(2)}</p>
            <p>Branch Stock = ${Number(
              item.branchStock === "" ? 0 : item.branchStock
            ).toFixed(2)}</p>
          <p> Qty pending for Po = ${
            item.qtyPo === "" || item.qtyPo == null
              ? 0
              : Number(item.qtyPo).toFixed(3)
          }</p>
          <p>Max = ${
            item.maxStock === undefined ? 0 : Number(item.maxStock).toFixed(3)
          }</p>
          <p>Min = ${
            item.minStock === undefined ? 0 : Number(item.minStock).toFixed(3)
          }</p>
          <p>MaxAllowedQty= ${
            item.maxAllowedQty === undefined
              ? 0
              : Number(item.maxAllowedQty).toFixed(3)
          }</p>
          <p>MinAllowedQty = ${
            item.minAllowedQty === undefined
              ? 0
              : Number(item.minAllowedQty).toFixed(3)
          }</p>
          <p>Re order quantity = ${
            item.reOrderQty === undefined ? 0 : item.reOrderQty
          }</p>

          `
      );
      ReactTooltip.rebuild();
    }
    //   }
    // });
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.
    this.setState({
      isDropdownOpen: true,
    });
  };
  getitemListByitemgrpId = (response, itemGrpId) => {
    if (itemGrpId !== undefined) {
      if (response) {
        let poDetails = this.state.poDetails.filter((row) => {
          if (row.itemGroup == itemGrpId || row.itemGroupId == itemGrpId) {
            let list = [];
            response.data.map((prop) => {
              list.push({
                label: prop.itemDesc+ `(${prop.itemCode})`,
                name: prop.itemDesc+ `(${prop.itemCode})`,
                value: prop.itemId,
                variant: prop.itemCode,
              });
            });
            // // let itemsList = list.filter((item) => {
            // //   if (item.value == row.itemId) {
            // //     let value = item.name.split("^");
            // //     row.min = value[8];
            // //     row.max = value[6];
            // //     row.tangible = value[16];
            // //   }
            // //   if (item.value !== 0 && item.value !== "0") {
            // //     return item;
            // //   }
            // // });
            row.itemList = list;
          } else if (row.departmentId == itemGrpId) {
            let itemGRPList = [];
            response.data.map((prop) => {
              itemGRPList.push({
                label: prop.label,
                name: prop.name,
                value: prop.value,
              });
            });
            let itemsgroupList = itemGRPList.filter((item) => {
              if (item.value !== 0 && item.value !== "0") {
                return item;
              }
            });
            row.itemGroupList = itemsgroupList;
          }
          return row;
        });
        this.setState({
          poDetails: poDetails,
        });
        this.props.POWOLineItemsProps(poDetails);
      }
      // return this.state.itemsList
    }
  };

 


  displayField = (row, colname, type) => {
    if (colname === "department") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";
      this.setState((prevState) => ({
        deptDisplayTxtField: {
          ...prevState.deptDisplayTxtField,
          [fieldKey1]: !prevState.deptDisplayTxtField[fieldKey1],
        },
        deptDisplayDropField: {
          ...prevState.deptDisplayDropField,
          [fieldKey2]: !prevState.deptDisplayDropField[fieldKey2],
        },
      }));
      //call api
      if (type !== 2) {
        this.props.getDepartmentList(
          serverApi.GET_DEPARTMENT_LIST +
            localStorage.getItem("companyId") +
            "/" +
            this.state.userDit.cipher,
          this.props.history
        );
      }
    } else if (colname === "itemGroup") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";
      this.setState((prevState) => ({
        deptDisplayTxtField: {
          ...prevState.deptDisplayTxtField,
          [fieldKey1]: !prevState.deptDisplayTxtField[fieldKey1],
        },
        deptDisplayDropField: {
          ...prevState.deptDisplayDropField,
          [fieldKey2]: !prevState.deptDisplayDropField[fieldKey2],
        },
      }));
      if (type !== 2) {
        const indentTypeId =
          row.itemGroupId === "997"
            ? ""
            : this.props.PoFillDetails.category_type_value;
        if (row.itemGroupId !== "" && row.itemGroupId !== undefined) {
          const data = {
            companyId: localStorage.getItem("companyId"),
            // consumable: "",
            // indentTypeId: indentTypeId,
            itemGroupId: row.itemGroupId,
            // saleable: "",
            // branchId: this.props.PoFillDetails.shippingAddress_Value,
          };
          // this.props.getItemsListforIndent(
          //   serverApi.GET_ITEMSBY_FORPROCUREMENT,
          //   data,
          //   this.getitemListByitemgrpId,
          //   row.itemGroupId
          // );

          this.props.getItemListByItemGroupForPurchase(
            serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
            data,
            this.getitemListByitemgrpId,
            row.itemGroupId
          );
        }
      }
    } else if (colname === "tax") {
      if (type !== 2) {
        this.props.getTax_List(
          serverApi.GET_ALL_TAXLIST +
            localStorage.getItem("companyId") +
            "/" +
            this.state.userDit.cipher,
          this.props.history
        );
      }
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";
      this.setState((prevState) => ({
        deptDisplayTxtField: {
          ...prevState.deptDisplayTxtField,
          [fieldKey1]: !prevState.deptDisplayTxtField[fieldKey1],
        },
        deptDisplayDropField: {
          ...prevState.deptDisplayDropField,
          [fieldKey2]: !prevState.deptDisplayDropField[fieldKey2],
        },
      }));
    } else if (colname === "discountMode") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";
      this.setState((prevState) => ({
        deptDisplayTxtField: {
          ...prevState.deptDisplayTxtField,
          [fieldKey1]: !prevState.deptDisplayTxtField[fieldKey1],
        },
        deptDisplayDropField: {
          ...prevState.deptDisplayDropField,
          [fieldKey2]: !prevState.deptDisplayDropField[fieldKey2],
        },
      }));
    } else if (colname === "itemId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";
      this.setState((prevState) => ({
        deptDisplayTxtField: {
          ...prevState.deptDisplayTxtField,
          [fieldKey1]: !prevState.deptDisplayTxtField[fieldKey1],
        },
        deptDisplayDropField: {
          ...prevState.deptDisplayDropField,
          [fieldKey2]: !prevState.deptDisplayDropField[fieldKey2],
        },
      }));

      if (type !== 2) {
        if (row.itemId !== "" && row.itemId !== undefined) {
          const data1 = {
            companyId: localStorage.getItem("companyId"),
            itemId: row.itemId,
            branchId: this.props.PoFillDetails.branchValue,
          };


          this.props.getPendingStock(
            serverApi.GET_PENDING_STOCK_BRANCH_WISE,
            data1,
            this.props.history,
            row.itemId
          );
          const data2 = {
            companyId: localStorage.getItem("companyId"),
            itemId: row.itemId,
          };
          this.props.getPendingStockPoandReceipt(
            serverApi.GET_CENTRAL_STOCK,
            data2,
            this.props.history,
            row.itemId
          );
          
          //  calling api for othercharges
 
          this.props.getItemGroupListforindent(
            serverApi.GET_ITEMGROUP_LIST +
              29 +
              "/" +
              localStorage.getItem("companyId") +
              "/getAllItemGroupsByDepartmentId/" +
              this.state.userDit.cipher,
            this.props.history,
            this.getitemListByitemgrpId,
            29
          );
          const data = {
            itemGroupId: "997",
            companyId: localStorage.getItem("companyId"),
            
          };
          this.props.getItemListByItemGroupForPurchase(
            serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
            data,
            this.getitemListByitemgrpId,
            "997"
          );
          this.setState({
            othercharges: true,
          });
        }
      }
    }
  };

  disableField = (col, row) => {
    if (this.props.locationState.state !== undefined) {
      if (
        col.type === "itemQty" ||
        col.type === "qty" ||
        col.type === "discountMode" ||
        col.type === "tax"
      ) {
        return false;
      } else {
        if (
          row.departmentId === "" ||
          row.itemGroupId === "" ||
          row.itemId === ""
        ) {
          return false;
        }
      }
      if (
        this.props.source !== "WITHOUTBOQ" ||
        this.props.source !== "WITHOUTINDENT"
      ) {
        if (
          col.type === "tax" ||
          col.type === "discountMode" ||
          (row.itemGroupId == 997 &&
          col.type === "itemId")
        ) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    } else {
      if(this.props.source === "WITHINDENT" || this.props.source === "WITHOPENINDENT" || this.props.source !== "WITHOUTBOQ"){
        if (
          row.itemGroupId == 997 &&
          (col.type === "department" ||
          col.type === "itemGroup")
        ) {
          return true;
        }
      }
      if (
        this.props.source !== "WITHOUTBOQ" &&
        this.props.source !== "WITHOUTINDENT"
      ) {
        if (
          row.itemGroupId === 997 ||
          col.type === "tax" ||
          col.type === "discountMode"
        ) {
          return false;
        }
        return true;
      }
    }
    return false;
  };

  
  render() {
    var filteredproducts = this.state.poDetails.filter((item) => {
      if (item.isActive !== 0) {
        return item;
      }
    });

    var totalAmount = 0; //price item1 + price of item2
    var netAmount = 0; // price item1 + price of item2 + gst of item1 + gst of item2
    var totalGst = 0; // gst of item1 + gst of item2
    var totalCgstAmount = 0;
    var totalSgstAmount = 0;
    if (this.state.poDetails) {
      const poDetailsItems = this.state.poDetails.filter(
        (item) => item.departmentId !== "" && item.isActive !== 0
      );

      poDetailsItems.map((data) => {
        var lineItemAmt = 0;
        lineItemAmt += Number(data.qty) * Number(data.rate);
        var totalInstallationRate = 0;
        if (Number(data.installationRate) !== null) {
          totalInstallationRate =
            Number(data.installationRate) * Number(data.qty);
        }
        lineItemAmt += Number(totalInstallationRate);

        if (
          Number(data.discountMode) !== undefined &&
          Number(data.discountMode) !== "" &&
          Number(data.discountMode) !== null
        ) {
          if (Number(data.discountMode) === 1) {
            // fixed
            lineItemAmt -= Number(data.discountPercentage);
          } else if (data.discountMode === 2) {
            // percentage
            var discountAmt =
              (Number(lineItemAmt) * Number(data.discountPercentage)) / 100;
            lineItemAmt -= Number(discountAmt);
          }
        }
        // gst check
        var gstAmount = 0;

        if (this.props.PoFillDetails.tax_payable_value === 1) {
          if (this.props.PoFillDetails.tax_type_value === 1) {
            gstAmount =
              (Number(lineItemAmt) * Number(data.taxPercentage)) / 100;
            totalGst += Number(gstAmount);
          } else {
            gstAmount =
              (Number(lineItemAmt) * Number(data.taxPercentage)) / 100;
            totalGst += Number(gstAmount); // totalGst = totalGst + gstAmount
            totalCgstAmount += Number(gstAmount) / 2;
            totalSgstAmount += Number(gstAmount) / 2;
          }
        }
        totalAmount += Number(lineItemAmt);
      });

      netAmount += Math.round(Number(totalAmount) + Number(totalGst));
    }

    var netTotal = Number(netAmount);
    this.props.PoFillDetails.totalAmount = Number(totalAmount);
    this.props.PoFillDetails.totalIgst = Number(totalGst);
    this.props.PoFillDetails.totalCgst = Number(totalCgstAmount);
    this.props.PoFillDetails.totalSgst = Number(totalSgstAmount);
    this.props.PoFillDetails.netTotal = Number(netTotal);

    var advanceAmount = 0;
    advanceAmount = parseFloat(
      (totalAmount * this.state.advancePercentage) / 100
    ).toFixed(2);
    this.props.PoFillDetails.advanceAmount = Number(advanceAmount);
    return (
      // Purchase Order by Indent --- update time
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent" onScroll={this.handleScroll}>
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {tableHeaders.map((column) => (
                    <>
                      {column.header === "Quantity" ||
                      column.header === "Tax Percentage" ||
                      column.header === "Department" ||
                      column.header === "Item" ||
                      column.header === "Item Group" ||
                      column.header === "Item Rate" ? (
                        <th>
                          {column.header}
                          <span style={{ color: "red" }}>*</span>
                        </th>
                      ) : (
                        <th>{column.header}</th>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredproducts.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <>
                      <td className="">
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 100 }}
                          title="Delete"
                        >
                          <span>
                            <IconButton
                              onClick={() => {
                                this.onDelLineItem(row);
                              }}
                              disabled={
                                filteredproducts.length === 1 ? true : false
                              }
                            >
                              <DeleteIcon className="deleteDisplayButton" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </td>
                      {RowData &&
                        RowData.map((col) =>
                          col.component === "EditableCell" ? (
                            <>
                              <td key={col.no}>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        type={
                                          col.type === "remarks" ||
                                          col.type === "make" ||
                                          col.type === "Unit" ||
                                          col.type === "itemCode"
                                            ? "text"
                                            : "number"
                                        }
                                        name={col.type + "_" + rowIndex}
                                        value={
                                          col.type === "Unit"
                                            ? row.uomCode
                                            : col.type === "itemCode"
                                            ? row.itemCode
                                            : col.type === "price"
                                            ? row.rate
                                            : col.type === "installationRate"
                                            ? row.installationRate
                                            : col.type === "installationAmt"
                                            ? row.installationAmount
                                            : // : col.type === "discountPercentage"
                                            // ? row.discountPercentage
                                            col.type === "amount"
                                            ? this.getAmount(
                                                Number(row.qty),
                                                row.qtyPo === undefined
                                                  ? 0
                                                  : Number(row.qtyPo),
                                                row.rate,
                                                row.discountMode,
                                                row.discountPercentage,
                                                row.itemGroupId
                                              )
                                            : col.type === "remarks"
                                            ? row.remarks
                                            : col.type === "make"
                                            ? row.make
                                            : ""
                                        }
                                        onChange={this.handleChange({
                                          key: col.type + "_" + rowIndex,
                                          row,
                                        })}
                                        onWheel={(e) => e.target.blur()}
                                        // onKeyDown={(evt) =>
                                        //   col.type === "remarks"|| col.type === "make" ? ()=>{}:
                                        //   ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
                                        // }
                                        className="inputBlockContainer"
                                        style={{
                                          textAlign:
                                            col.type !== "remarks" &&
                                            col.type !== "make" &&
                                            col.type !== "Unit"
                                              ? "right"
                                              : "",
                                          paddingRight: "8px",
                                        }}
                                        disabled={
                                          col.type === "Unit"|| col.type === "amount"  ? true : false
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            </>
                          ) : col.component === "Inputadorment" ? (
                            <td>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      type={
                                        row.itemGroupId === 997 ||
                                        Number(row.itemGroupId) === 997
                                          ? "text"
                                          : "number"
                                      }
                                      name={col.type + "_" + rowIndex}
                                      disabled={
                                        row.itemGroupId === 997 ? true : false
                                      }
                                      onKeyDown={(evt) =>
                                        ["e", "E", "+", "-"].includes(
                                          evt.key
                                        ) && evt.preventDefault()
                                      }
                                      value={
                                        //c=get qty(Number(row.qty), row.qtyPo === undefined?0:Number(row.qtyPo))
                                        col.type === "qty"
                                          ? row.itemGroupId === 997 ||
                                            Number(row.itemGroupId) === 997
                                            ? "NA"
                                            : row.qty === ""
                                            ? ""
                                            : row.qty === 0 &&
                                              this.props.source ===
                                                "WITHOPENINDENT"
                                            ? row.qty
                                            : row.qty
                                          : ""
                                      }
                                      onChange={this.handleChange({
                                        key: col.type + "_" + rowIndex,
                                        row,
                                      })}
                                      onBlur={this.handleBlur({
                                        key: col.type + "_" + rowIndex,
                                        row,
                                      })}
                                      onWheel={(e) => e.target.blur()}
                                      className="inputBlockContainer"
                                      style={{
                                        textAlign:
                                          col.type === "qty" ? "right" : "",
                                        paddingRight: "8px",
                                      }}
                                      onClick={this.props.tooltip}
                                      onMouseOver={(e) =>
                                        col.type === "qty"
                                          ? this.handleEventPositioned(e, row)
                                          : ""
                                      }
                                    />
                                    <ReactTooltip
                                      effect={"solid"}
                                      html={true}
                                      place={"BottomCenter"}
                                      className="tooltipClassBorder"
                                      backgroundColor="rgba(0,127,173,0.9)"
                                      textColor="#FFF"
                                      border={true}
                                      borderColor="#04749c"
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          ) : col.component === "textfield" ? (
                            <>
                              <td>
                                <Grid
                                  xs={12}
                                  md={12}
                                  className="dynamicSelectBlock"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    type={
                                      col.type === "discountPercentage"
                                        ? "number"
                                        : ""
                                    }
                                    name={col.type + "_" + rowIndex}
                                    value={
                                      col.type === "discountPercentage"
                                        ? row.discountPercentage
                                        : col.type === "HSN/SAC_code"
                                        ? row.hsnCode
                                        : ""
                                    }
                                    onChange={this.handleChange({
                                      key: col.type + "_" + rowIndex,
                                      row,
                                    })}
                                    onWheel={(e) => e.target.blur()}
                                    className="inputBlockContainer"
                                    style={{
                                      textAlign:
                                        col.type === "discountPercentage"
                                          ? "right"
                                          : "",
                                      paddingRight: "8px",
                                    }}
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-"].includes(evt.key) &&
                                      evt.preventDefault()
                                    }
                                    disabled={
                                      col.type === "HSN/SAC_code"
                                        ? true
                                        : col.type === "discountPercentage"
                                        ? row.discountMode === 0 ||
                                          row.discountMode === "" ||
                                          row.discountMode === undefined
                                          ? true
                                          : false
                                        : false
                                    }
                                  />
                                </Grid>
                              </td>
                            </>
                          ) : col.component === "DynamicSelect" ? (
                            <td>
                              <Tooltip
                                title={
                                  col.type === "department"
                                    ? row.departmentName
                                    : col.type === "budgetHeads"
                                    ? row.budgetHeadName
                                    : col.type === "itemGroup"
                                    ? row.itemGroupId !== ""
                                      ? row.itemGroupName
                                        ? row.itemGroupName
                                        : row.itemGroup
                                      : ""
                                    : col.type === "itemId"
                                    ? row.itemId !== ""
                                      ? this.props.source === "WITHOUTINDENT"
                                        ? row.description
                                        : row.itemName
                                      : ""
                                    : ""
                                }
                                placement="top"
                              >
                                <div className="quantityDropdownBlock">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: this.state.deptDisplayTxtField[
                                        col.type + row.id + "1"
                                      ]
                                        ? "none"
                                        : "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      className="inputBlockContainer"
                                      style={{
                                        paddingRight: "8px",
                                        minWidth: "250px",
                                        ...(this.disableField(col, row)
                                          ? { backgroundColor: "#ccd6db" }
                                          : {}),
                                      }}
                                      // ##TO add the disabled the background ##
                                      value={
                                        col.type === "department"
                                          ? row.departmentName
                                          : col.type === "itemId"
                                          ? row.itemName
                                          : col.type === "itemGroup"
                                          ? row.itemGroupName
                                          : col.type === "discountMode"
                                          ? row.discountMode === "" ||
                                            row.discountMode === null
                                            ? ""
                                            : row.discountMode === 1
                                            ? "Amount"
                                            : row.discountMode === 2
                                            ? "Percentage"
                                            : ""
                                          : col.type === "tax"
                                          ? row.tax
                                            ? row.tax
                                            : row.taxPercentage
                                          : ""
                                      }
                                      onChange={this.handleChange({
                                        key: col.type + "_" + rowIndex,
                                        row,
                                      })}
                                      onClick={() => {
                                        if (
                                          this.props.locationState.state ===
                                          undefined
                                        ) {
                                          this.displayField(row, col.type, 1);
                                        } else {
                                          if (
                                            this.props.source ===
                                            "WITHOUTINDENT"
                                          ) {
                                            if (
                                              row.departmentId === "" ||
                                              row.itemGroupId === "" ||
                                              row.itemId === "" ||
                                              row.discountMode === "" ||
                                              row.discountMode === null ||
                                              row.taxId === 0
                                            ) {
                                              this.displayField(
                                                row,
                                                col.type,
                                                1
                                              );
                                            } else {
                                              if (
                                                col.type === "itemQty" ||
                                                col.type === "qty" ||
                                                col.type === "discountMode" ||
                                                col.type === "tax"
                                              ) {
                                                this.displayField(
                                                  row,
                                                  col.type,
                                                  1
                                                );
                                              }
                                            }
                                          } else {
                                            if (
                                              col.type === "itemQty" ||
                                              col.type === "qty"
                                            ) {
                                              return false;
                                            } else {
                                              this.displayField(
                                                row,
                                                col.type,
                                                1
                                              );
                                            }
                                          }
                                        }
                                      }}
                                      disabled={this.disableField(col, row)}
                                    />
                                  </Grid>
                                  <Grid
                                    className="selectAndTextfield"
                                    style={{
                                      display: this.state.deptDisplayDropField[
                                        col.type + row.id + "2"
                                      ]
                                        ? "flex"
                                        : "none",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {this.props.source !== "WITHINDENT" ||
                                      row.itemGroup !== "Other Charges" ? (
                                        <>
                                          <DynamicSelect
                                            arrayOfData={
                                              col.type === "department"
                                                ? this.state.DepartmentList
                                                : col.type === "itemGroup"
                                                ? row.itemGroupList
                                                : col.type === "itemId"
                                                ? Number(row.itemGroupId) ===
                                                  997
                                                  ? row.itemList
                                                  : // :
                                                  row.itemGroupId ===
                                                      undefined ||
                                                    row.itemGroupId === ""
                                                  ? []
                                                  : row.itemList
                                                : col.type === "tax"
                                                ? this.state.taxList
                                                : col.type === "discountMode"
                                                ? discountModeList
                                                : []
                                            }
                                            className="dropdownBlockContainer"
                                            onSelectChange={
                                              this.handleChangedropdown
                                            }
                                            isDropdownOpen={
                                              this.state.isDropdownOpen
                                            }
                                            handleMenuOpen={this.handleMenuOpen}
                                            selected={
                                              col.type === "department"
                                                ? row.departmentId
                                                : col.type === "budgetHeads"
                                                ? row.budgetHeadId
                                                : col.type === "itemGroup"
                                                ? row.itemGroupId !== null
                                                  ? row.itemGroupId
                                                  : row.itemGroup
                                                : col.type === "itemId"
                                                ? row.itemId
                                                : col.type === "discountMode"
                                                ? row.discountMode
                                                : col.type === "tax"
                                                ? row.taxId
                                                : ""
                                            }
                                            update={
                                              row.departmentId ||
                                              row.budgetHeads ||
                                              row.itemGroupId ||
                                              row.itemId ||
                                              row.taxId ||
                                              row.discountMode
                                                ? 1
                                                : 0
                                            }
                                            name={col.type}
                                            row={row}
                                          />
                                        </>
                                      ) : (
                                        <DynamicSelect
                                          arrayOfData={
                                            col.type === "department"
                                              ? this.state.DepartmentList
                                              : col.type === "itemGroup"
                                              ? this.state.ItemGroupMasterList
                                              : col.type === "itemId"
                                              ? Number(row.itemGroupId) === 997
                                                ? row.itemList
                                                : this.state.itemsList
                                              : col.type === "tax"
                                              ? this.state.taxList
                                              : col.type === "discountMode"
                                              ? discountModeList
                                              : []
                                          }
                                          className="dropdownBlockContainer"
                                          onSelectChange={
                                            this.handleChangedropdown
                                          }
                                          isDropdownOpen={
                                            this.state.isDropdownOpen
                                          }
                                          handleMenuOpen={this.handleMenuOpen}
                                          selected={
                                            col.type === "department"
                                              ? row.departmentId
                                              : col.type === "budgetHeads"
                                              ? row.budgetHeadId
                                              : col.type === "itemGroup"
                                              ? row.itemGroupId !== null
                                                ? row.itemGroupId
                                                : row.itemGroup
                                              : col.type === "itemId"
                                              ? row.itemId
                                              : col.type === "discountMode"
                                              ? row.discountMode
                                              : col.type === "tax"
                                              ? row.taxId
                                              : row.taxPercentage
                                          }
                                          update={
                                            row.departmentId ||
                                            row.budgetHeads ||
                                            row.itemGroupId ||
                                            row.itemId ||
                                            row.taxId ||
                                            row.discountMode
                                              ? 1
                                              : 0
                                          }
                                          name={col.type}
                                          row={row}
                                        />
                                      )}
                                    </Grid>
                                  </Grid>
                                </div>
                              </Tooltip>
                            </td>
                          ) : (
                            ""
                          )
                        )}
                    </>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div style={{ marginTop: "5px" }}>
          <span
            className="OtherCharges"
            onClick={() => {
              this.addOtherCharges();
            }}
          >
            Click Here To Add Printing Charges/Labour Charges/Transport
            Charges/Delivery Charges/Packing & Forwarding Charges/Other Charges
          </span>
        </div>
        <div className="consoleFormContainer">
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 12">
                    {/* <InvoiceSection poDetails={this.state.poDetails} poFillDetails={this.props.PoFillDetails} type={"BY_NOT_BOM"} /> */}

                    <div
                      className="calculationBlockTable"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <table>
                        <tr>
                          <td>Total IGST:</td>
                          {/* <td>{parseFloat(totalIgst).toFixed(2)}</td> */}
                          <b>
                            {" "}
                            {this.props.PoFillDetails.tax_type_value === 1
                              ? parseFloat(Number(totalGst)).toFixed(2)
                              : parseFloat(0).toFixed(2)}
                          </b>
                        </tr>
                        <tr>
                          <td>Total SGST:</td>
                          <td>
                            <b>
                              {this.props.PoFillDetails.tax_type_value === 2
                                ? parseFloat(totalSgstAmount).toFixed(2)
                                : parseFloat(0).toFixed(2)}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td>Total CGST:</td>
                          <td>
                            <b>
                              {this.props.PoFillDetails.tax_type_value === 2
                                ? parseFloat(totalCgstAmount).toFixed(2)
                                : parseFloat(0).toFixed(2)}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td>Net Amount:</td>
                          {/* <td>{parseFloat(totalAmount).toFixed(2)}</td> */}
                          <b>{parseFloat(Number(totalAmount)).toFixed(2)}</b>
                        </tr>
                        <tr>
                          <td>Total Amount:</td>
                          {/* <td>{parseFloat(Math.round(netTotal)).toFixed(2)}</td> */}
                          {/* <td>{parseFloat(netTotal).toFixed(2)}</td> */}
                          <b> {parseFloat(Number(netAmount)).toFixed(2)}</b>
                        </tr>
                      </table>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Advance Percentage"
                        value={this.state.advancePercentage}
                        onChange={this.onhandleChange("advance_per")}
                        minValue={0}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Advance Amount"
                        type={"number"}
                        value={advanceAmount}
                        onChange={this.onhandleChange("advance_amt")}
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectNew
                        arrayOfData={this.state.termsNconditionsList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.termsNconditions}
                        update={this.state.termsNconditions ? 1 : 0}
                        name="termsNconditions"
                        label="Select Terms and Conditions"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        label="Terms and Conditions"
                        type="textarea"
                        value={this.state.selectTermsNconditions}
                        onChange={this.onhandleChange("term&Con")}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        {this.props.locationState.state !== undefined && (
          <div className="consoleFormContainer">
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 4">
                      <div>Support Documents:</div>
                      <div className="personalDetailsUpload">
                        <div className="ducumentUploadBlock">
                          <div className="documentUploadContent">
                            <span className="btn_upload documentUpload">
                              <img src={panUploadIcon} alt="" />
                              <input
                                type="file"
                                id="imag"
                                title=""
                                className="input-img"
                                onChange={this.UploadDocuments()}
                              />
                              Upload Document
                            </span>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    className="ducumentUploadBlock"
                    gap={2}
                  >
                    <Box gridColumn="span 4">
                      {this.state.files_List &&
                        this.state.files_List.map((item) => (
                          <>
                            <div
                              className="uploadedInfo"
                              style={{ display: "flex", marginTop: "10px" }}
                            >
                              <img
                                src={TickIcon}
                                alt=""
                                className="tickIcon"
                                height={"20px"}
                                width={"20px"}
                              />
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title="Click to download File"
                              >
                                <div
                                  onClick={() => {
                                    getFileDownload(
                                      serverConfig.SERVER_URL +
                                        "security-api/api/files/downloadfile/" +
                                        item.fileUploadId,
                                      item.fileName,
                                      item.fileExtension
                                    );
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                  }}
                                >
                                  {item.fileName}
                                </div>
                              </Tooltip>
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 100 }}
                                title="Click to Delete File"
                              >
                                <Button
                                  onClick={() => {
                                    this.onDeleteFile(item.fileUploadId);
                                  }}
                                  className="deleteButton"
                                >
                                  <img
                                    src={DeleteIcons}
                                    alt=""
                                    height={"20px"}
                                    width={"20px"}
                                  />
                                </Button>
                              </Tooltip>
                            </div>
                          </>
                        ))}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div className="consoleFormButtonBlock">
                        <CustomButton
                          label={"CLICK HERE TO SEE THE LOGS"}
                          className="greenButton"
                          handleClick={this.onClickToSeeLogs}
                          type="sumbit"
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} className="logsContainer">
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 6">
                      {this.state.Logs &&
                        this.state.Logs.map((item) => (
                          <div>
                            {item.actionDate}
                            {item.remarks}
                          </div>
                        ))}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const discountModeList = [
  {
    label: "Amount",
    name: "Amount",
    value: 1,
  },
  {
    label: "Percentage",
    name: "Percentage",
    value: 2,
  },
];
const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "department",
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "itemGroup",
  },
  {
    no: 3,
    component: "DynamicSelect",
    type: "itemId",
  },
  {
    no: 4,
    component: "EditableCell",
    type: "itemCode",
  },
  {
    no: 5,
    component: "Inputadorment",
    type: "qty",
  },
  {
    no: 6,
    component: "EditableCell",
    type: "Unit",
  },
  {
    no: 7,
    component: "textfield",
    type: "HSN/SAC_code",
  },

  {
    no: 8,
    component: "EditableCell",
    type: "price",
  },
  {
    no: 9,
    component: "DynamicSelect",
    type: "discountMode",
  },
  {
    no: 10,
    component: "textfield",
    type: "discountPercentage",
  },
  {
    no: 11,
    component: "DynamicSelect",
    type: "tax",
  },
  {
    no: 12,
    component: "EditableCell",
    type: "amount",
  },
  {
    no: 13,
    component: "EditableCell",
    type: "remarks",
  },
  {
    no: 14,
    component: "EditableCell",
    type: "make",
  },
];
const tableHeaders = [
  {
    header: "Actions",
  },
  {
    header: "Department",
  },
  {
    header: "Item Group",
  },
  {
    header: "Item",
  },
  {
    header: "Item Code",
  },
  {
    header: "Quantity",
  },
  {
    header: "Unit",
  },
  {
    header: "HSN/SAC code",
  },
  {
    header: "Item Rate",
  },
  {
    header: "Discount Mode",
  },
  {
    header: "Discount/Amount",
  },
  {
    header: "Tax Percentage",
  },
  {
    header: "Amount",
  },
  {
    header: "Remarks",
  },
  {
    header: "Make",
  },
];

const mapStatetoProps = (state) => {
  const { itemsListForIndent } = state.itemsListForIndent;
  const { taxList } = state.taxList;
  const { po_wo_lineitems,itemDetails } = state.PurchaseOrderReducer;
  const { departmentList, allItemGroupMaster_List } = state.DropDownListReducer;
  const { TermsConditions } = state.DropDownListReducer;
  const { Upload_File_List } = state.UploadFileReducer;
  const { logs_List, files_List, pendingStockPoandReceipt, pendingStock } =
    state.IndentReducer;
  return {
    logs_List,
    files_List,
    Upload_File_List,
    itemsListForIndent,
    taxList,
    departmentList,
    allItemGroupMaster_List,
    po_wo_lineitems,
    TermsConditions,
    pendingStockPoandReceipt,
    pendingStock,
    itemDetails
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getPendingStockPoandReceipt,
    getItemGroupListforindent,
    getPendingStock,
    getItemListByItemGroupForPurchase,
    getItemDetailsByItemItemGrpForPurchase,
    getItemsListForIndent,
    getTax_List,
    BudgetHeads,
    postUploadFileList,
    deleteFile,
    actions,
    logs,
    getFiles,
    getFilesSuccessfull,
    validateItem,
    getDepartmentList,
    allItemGroupMasterList,
    POWOLineItemsProps,
    getTermsConditions,
    getItemsListforIndent,
  })(POLineItems)
);
