/* Created By Nagesh Medisetty on 19-04-2022 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "./DynamicSelect";
import TextFieldAlfaNum from "../../../components/TextField/TextFieldAlfaNum";
import { getIndentListMaster } from "../../../store/Master/ItemMaster/actions";
import { serverApi } from "../../../helpers/Consts";
import { getBranchList } from "../../../store/Global/DropDownApis/actions";
import {
  getCustomerList,
  getProjectList,
  storeIssueHeaderData,
} from "../../../store/StoreModule/MaterialRequest/actions";
import moment from "moment";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";

class MaterialRequestFillDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      expense_type: props.storeHeaderDataList.expense_type,
      expense_name: "",
      branchId: props.storeHeaderDataList.branchId,
      branch_name: 0,
      customer_type: props.storeHeaderDataList.customer_type,
      customer_name: "",
      project_type: props.storeHeaderDataList.project_type,
      date: props.storeHeaderDataList.date,
      project_name: 0,
      expenseType: [],
      branchList: [],
      customerList: [],
      projectList: [],
      internalNote: props.storeHeaderDataList.internalNote,
    };
  }

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    //alert(name);
    if (name === "expenseType") {
      //   alert(name);
      let name = selectedName.split("_");
      this.props.storeHeaderDataList.expense_type = selectedValue;
      this.props.storeHeaderDataList.expense_name = name[0];
      this.setState({
        expense_type: selectedValue,
        expense_name: name[0],
      });
    }
    if (name === "branch") {
      //   alert(name);
      if (this.props.storeIssueViewByIdList) {
        this.props.getCustomerList(
          serverApi.CUSTOMER_LIST +
            localStorage.getItem("companyId") +
            "/" +
            this.state.branchId
        );
      } else {
        this.props.getCustomerList(
          serverApi.CUSTOMER_LIST +
            localStorage.getItem("companyId") +
            "/" +
            selectedValue
        );
      }

      this.props.getProjectList(
        serverApi.PROJECT_LIST +
          localStorage.getItem("companyId") +
          "/customer/0/branch/" +
          selectedValue
      );
      this.props.storeHeaderDataList.branchId = selectedValue;
      this.setState({
        branchId: selectedValue,
      });
    }
    if (name === "customerName") {
      //   alert(name);
      this.props.storeHeaderDataList.customer_type = selectedValue;
      this.props.storeHeaderDataList.customer_name = selectedName;

      this.setState({
        customer_type: selectedValue,
        customer_name: selectedName,
      });
    }
    if (name === "project") {
      //   alert(name);
      this.props.storeHeaderDataList.project_type = selectedValue;
      this.props.storeHeaderDataList.project_name = selectedName;

      this.setState({
        project_type: selectedValue,
        project_name: selectedName,
      });
    }
    this.props.onSelectDep(selectedValue, selectedName, name);
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getIndentListMaster(
      serverApi.INDENT_TYPE_LIST_MASTERS,
      this.props.history
    );
    this.props.getBranchList(
      serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
      this.props.history
    );

    this.setState({
      branchId: this.state.branchId,
    });
  }

  componentWillReceiveProps(props) {
    // Expense Type list setting and filtering "select" to the state....
    if (props.Indent_List_Master) {
      if (props.Indent_List_Master.data) {
        if (props.Indent_List_Master.data.length > 0) {
          var list = [];

          list = props.Indent_List_Master.data.filter((item) => {
            if (item.value !== "0") {
              return item;
            }
          });

          this.setState({
            expenseType: list,
          });
        }
      }
    }

    // Branch list setting and filtering "select" to the state....

    if (props.branchList) {
      if (props.branchList.data) {
        if (props.branchList.data.length > 0) {
          var list = [];

          list = props.branchList.data.filter((item) => {
            if (item.value !== 0) {
              return item;
            }
          });
          this.setState({
            branchList: list,
          });
        }
      }
    }

    if (props.customerList) {
      if (props.customerList.data) {
        if (props.customerList.data.length > 0) {
          this.setState({
            customerList: props.customerList.data,
          });
        }
      }
    }

    if (props.projectList) {
      if (props.projectList.data) {
        if (props.projectList.data.length > 0) {
          var list = [];
          list = props.projectList.data.filter((item) => {
            if (item.value !== 0) {
              return item;
            }
          });
          this.setState({
            projectList: list,
          });
        }
      }
    }

    if (this.props.storeIssueViewByIdList) {
      if (props.storeIssueViewByIdList) {
        props.storeHeaderDataList.date = props.storeIssueViewByIdList[0]
          ? props.storeIssueViewByIdList[0].issueDate
          : "";

        props.storeHeaderDataList.expense_type = props.storeIssueViewByIdList[0]
          ? props.storeIssueViewByIdList[0].indentTypeId
          : "";
        props.storeHeaderDataList.branchId = props.storeIssueViewByIdList[0]
          ? props.storeIssueViewByIdList[0].branchId
          : "";
        props.storeHeaderDataList.project_type = props.storeIssueViewByIdList[0]
          ? props.storeIssueViewByIdList[0].projectId
          : "";
        props.storeHeaderDataList.customer_type = props
          .storeIssueViewByIdList[0]
          ? props.storeIssueViewByIdList[0].customerId
          : "";
        props.storeHeaderDataList.internalNote = props.storeIssueViewByIdList[0]
          ? props.storeIssueViewByIdList[0].internalNote
          : "";

        this.setState({
          date: props.storeIssueViewByIdList[0]
            ? props.storeIssueViewByIdList[0].issueDate
            : "",
          expense_type: props.storeIssueViewByIdList[0]
            ? props.storeIssueViewByIdList[0].indentTypeId
            : "",
          branchId: props.storeIssueViewByIdList[0]
            ? props.storeIssueViewByIdList[0].branchId
            : "",
          customer_type: props.storeIssueViewByIdList[0]
            ? props.storeIssueViewByIdList[0].customerId
            : "",
          project_type: props.storeIssueViewByIdList[0]
            ? props.storeIssueViewByIdList[0].projectId
            : "",
          internalNote: props.storeIssueViewByIdList[0]
            ? props.storeIssueViewByIdList[0].internalNote
            : "",
        });
      }
    }
  }

  handleSelect_Date = (e, name) => {
    if (name === "date") {
      var dateVal = e;
      var date = moment(dateVal).format();

      var fromdate = date.split("T", 1).toString();

      this.props.storeHeaderDataList.date = fromdate;
      this.setState({
        date: fromdate,
      });

      this.props.handleSelectDate(fromdate, name);
    }
  };

  handleChange = (key) => (event) => {
    this.setState({ [key]: event });

    if (key === "internalNote") {
      this.props.storeHeaderDataList.internalNote = event;
      this.setState({
        internalNote: event,
      });
    }
  };
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <div className="consoleFormContainer">
        <h5>Fill Details</h5>
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>Issue Date</label>
                        <span style={{ color: "red" }}>*</span>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="transactionDate"
                          value={this.state.date}
                          disabled={
                            this.props.storeIssueViewByIdList ? true : false
                          }
                          fullWidth
                          onChange={(e) => this.handleSelect_Date(e, "date")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      arrayOfData={this.state.expenseType}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      selected={this.state.expense_type}
                      update={this.props.location.state ? 1 : 0}
                      isDisabled={
                        this.props.storeIssueViewByIdList ? true : false
                      }
                      name="expenseType"
                      label="Expenses Type"
                      required
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      arrayOfData={this.state.branchList}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      selected={this.state.branchId}
                      name="branch"
                      label="Branch"
                      update={this.props.location.state ? 1 : 0}
                      isDisabled={
                        this.props.storeIssueViewByIdList === undefined &&
                        this.state.expense_type == undefined
                          ? true
                          : this.props.storeIssueViewByIdList
                          ? true
                          : false
                      }
                      required
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      arrayOfData={this.state.customerList}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      selected={this.state.customer_type}
                      update={this.props.location.state ? 1 : 0}
                      isDisabled={
                        this.props.storeIssueViewByIdList === undefined &&
                        this.state.branchId == undefined
                          ? true
                          : this.props.storeIssueViewByIdList
                          ? true
                          : false
                      }
                      name="customerName"
                      label="Customer Name"
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      arrayOfData={this.state.projectList}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      selected={this.state.project_type}
                      update={this.props.location.state ? 1 : 0}
                      isDisabled={
                        this.props.storeIssueViewByIdList === undefined &&
                        this.state.branchId == undefined
                          ? true
                          : this.props.storeIssueViewByIdList
                          ? true
                          : false
                      }
                      name="project"
                      label="Project"
                      required
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      type="textarea"
                      label="Internal Note"
                      value={this.state.internalNote}
                      onChange={this.handleChange("internalNote")}
                    />
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Indent_List_Master } = state.ItemListReducer;
  const { branchList } = state.DropDownListReducer;
  const {
    customerList,
    projectList,
    storeIssueViewByIdList,
    storeHeaderDataList,
  } = state.Material_Request_List;
  return {
    Indent_List_Master,
    branchList,
    customerList,
    projectList,
    storeIssueViewByIdList,
    storeHeaderDataList,
  };
};
export default connect(mapStatetoProps, {
  getIndentListMaster,
  getBranchList,
  getCustomerList,
  storeIssueHeaderData,
  getProjectList,
})(withRouter(MaterialRequestFillDetails));
