import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../../../components/Buttons/Buttons";
import { Link } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../../../components/Dropdown/DynamicSelect";
import {
  getLeavePolicyList,
  getLeaveList,
  leavePolicyMapping,
  setPersonalDetails,
} from "../../../../../store/HRMS/EmployeeDataBase/actions";
import Gridwithcustomview from "../../../../../components/Grid/Gridwithcustomview";
import GridArrowIcon from "../../../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../../../helpers/Consts";
import { viewbyidPersonalDetails } from "../../../../../store/HRMS/EmployeeDataBase/actions";
import {
  UpdateleavePolicy,
  getViewByIdLeavePolicy,
} from "../../../../../store/HRMS/EmployeeDataBase/actions";
class LeavePolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      ebId: this.props.getEbId,
      leave: "",
      leave_type: [],
      Leave_list: [],
      display: false,

      update_button: false,
      mappingId: null,
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getLeavePolicyList(
      serverApi.LEAVE_POLICY_LIST + this.state.companyId,
      this.props.history
    );
    this.props.getViewByIdLeavePolicy(
      serverApi.VIEW_BY_ID_EMPLOYEE_LEAVE + "/" + localStorage.getItem("ebId"),
      this.props.history,
      null
    );
  }
  componentWillReceiveProps(props) {
    if (props.leavePolicy_List) {
      this.setState({
        leave_type: props.leavePolicy_List.data,
      });
    }
    if (props.leave_List.data) {
      this.setState({
        Leave_list: props.leave_List.data.dtlList,
      });
    }

    if (props.viewById_LeavePlocy && this.state.leave == "") {
      if (props.viewById_LeavePlocy.data) {
        if (props.viewById_LeavePlocy.data.empLeavePolicyMapping) {
          this.setState({
            leave:
              props.viewById_LeavePlocy.data.empLeavePolicyMapping
                .leavePolicyId,
            mappingId:
              props.viewById_LeavePlocy.data.empLeavePolicyMapping.mappingId,
            display: true,
          });
        }
      }
    }
  }
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    this.setState({
      leave: selectedValue,
      display: true,
    });
    this.props.getLeaveList(
      serverApi.LEAVE_LIST + selectedValue,
      this.props.history
    );
  };

  onClickNext = () => {
    const data = {
      companyId: localStorage.getItem("companyId"),
      createdBy: this.state.userDit.userId,
      ebId: this.props.getEbId,
      leavePolicyId: this.state.leave,
    };
    this.props.leavePolicyMapping(
      serverApi.LEAVE_POLICY_MAPPING,
      data,
      this.props.history
    );
  };

  onClickUpdateList = () => {
    const data = {
      companyId: localStorage.getItem("companyId"),
      updatedBy: this.state.userDit.userId,
      ebId: this.props.getEbId,
      leavePolicyId: this.state.leave,
      mappingId: this.state.mappingId,
    };
    this.props.UpdateleavePolicy(
      serverApi.UPDATE_LEAVE_POLICY,
      data,
      this.props.history
    );
    this.updateProgress();
  };
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div className="leavePolicy">
        <h1>Leave Policies</h1>
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      update={this.state.leave ? 1 : 0}
                      arrayOfData={
                        this.state.leave_type
                          ? this.state.leave_type.map((item) => ({
                              label: item.leavePolicyDesc,
                              value: item.leavePolicyId,
                              name: item.leavePolicyDesc,
                            }))
                          : ""
                      }
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.leave}
                      name="leave"
                      label="Select Leave Policy"
                    />
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
        {this.state.display && (
          <Gridwithcustomview
            mdata={this.state.Leave_list ? this.state.Leave_list : []}
            mcolumns={Columns}
            micon={GridArrowIcon}
            actions={false}
          />
        )}
        <div className="consoleFormButtonBlock">
          <Link to="/add_employee">
            <CustomButton label={"Cancel"} className="greenBorderButton" />
          </Link>
          {this.state.mappingId ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickUpdateList}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Save"}
              className="greenButton"
              handleClick={this.onClickNext}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}
const Columns = [
  {
    accessor: "leaveTypeName",
    Header: "Leave Type",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    accessor: "carryFrwStatus",
    Header: "Carry Forward Status",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    accessor: "encashmentStatus",
    Header: "Encashment Status",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    accessor: "leavesPerYear",
    Header: "Leaves Per Year",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    accessor: "maxConsecutiveCount",
    Header: "Max Consecutive Count",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    accessor: "maxLeavesPerYear",
    Header: "Max Leaves Per Year",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    accessor: "yearStartDateDesc",
    Header: "Year Start Date",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    accessor: "yearEndStateDesc",
    Header: "Year End Date",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    accessor: "leavePolicyDtlId",
    Header: "Leave Policy Dtl Id",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    accessor: "leavePolicyId",
    Header: "Leave Policy Id",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    accessor: "leaveTypeId",
    Header: "Leave Type Id",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
  {
    accessor: "yearStartDate",
    Header: "Year Start Date Desc",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
  {
    accessor: "yearEndDate",
    Header: "Year End State Desc",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
  {
    accessor: "isActive",
    Header: "IsActive",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
];
const mapStatetoProps = (state) => {
  const {
    leavePolicy_List,
    leave_List,
    pay_policy_mapping,
    Update_leave_policy,
    personalDetails,
    getEbId,
    viewById_LeavePlocy,
  } = state.EmployeeReducer;
  return {
    leavePolicy_List,
    leave_List,
    pay_policy_mapping,
    Update_leave_policy,
    personalDetails,
    getEbId,
    viewById_LeavePlocy,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getLeavePolicyList,
    setPersonalDetails,
    getLeaveList,
    leavePolicyMapping,
    UpdateleavePolicy,
    viewbyidPersonalDetails,
    getViewByIdLeavePolicy,
  })(LeavePolicy)
);
