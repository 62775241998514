import {PAYROLL_DATA,PAYROLL_DATA_SUCCESSFULL, ADD_ATTENDENCE, ADD_ATTENDENCE_SUCCESSFULL, PAYROLL_CREATE, PAYROLE_CREATE_SUCCESS} from './actionType'

export const getPayRollData= (url,data,history) =>{
    return {
        type:PAYROLL_DATA,
        payload:{url,data,history}
    }
}
export const PayRollDataSuccessfull = (data)=>{
    return {
        type:PAYROLL_DATA_SUCCESSFULL,
        payload:data
    }
}

export const getAttendence = (url, history) => {
    return {
        type: ADD_ATTENDENCE,
        payload: {url, history}
    }
}

export const getAttendenceSuccessfull = (data) => {
    return {
        type: ADD_ATTENDENCE_SUCCESSFULL,
        payload: data
    }
}

// export const createPayRollData= (url,data,history) =>{
//     return {
//         type:CREATE_PAY_ROLL_DATA,
//         payload:{url,data,history}
//     }
// }
// export const createPayRollDataSuccessfull = (data)=>{
    
//     return {
//         type:CREATE_PAY_ROLL_DATA_SUCCESSFULL,
//         payload:data
//     }
// }

export const payrollcreate = (url,data,history) => {
    return {
        type: PAYROLL_CREATE,
        payload: {url, data, history}
    }
}

export const payrollcreatesuccess = (data) => {
    return {
        type : PAYROLE_CREATE_SUCCESS,
        payroll: data
    }
}