import { CLEAR_INDENT_LINEITEMS_JUTE, CREATE_JUTE_INDENT, CREATE_JUTE_INDENT_SUCCESSFULL, INDENT_LINEITEMS_JUTE, JUTETYPE_BY_MUKAM, JUTETYPE_BY_MUKAM_SUCCESSFULL, JUTE_INDENT_BY_ID, JUTE_INDENT_BY_ID_SUCCESSFULL, JUTE_INDENT_LIST, JUTE_INDENT_LIST_SUCCESSFULL, QUALITY_BY_JUTETYPE, QUALITY_BY_JUTETYPE_SUCCESSFULL, UPDATE_JUTE_INDENT, UPDATE_JUTE_INDENT_SUCCESSFULL,JUTE_INDENT_HEADER, CLEAR_JUTE_INDENT_HEADER } from "./actionTypes";

export const JuteIndentList = (url, data, history) => {
  return {
    type: JUTE_INDENT_LIST,
    payload: { url, data, history },
  };
};
export const JuteIndentListSuccessfull = (data) => {
  return {
    type: JUTE_INDENT_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const JuteTypeByMukam = (url, data, history) => {
  return {
    type: JUTETYPE_BY_MUKAM,
    payload: { url, data, history },
  };
};
export const JuteTypeByMukamSuccessfull = (data) => {
  return {
    type: JUTETYPE_BY_MUKAM_SUCCESSFULL,
    payload: data,
  };
};
export const QualityByJuteType = (url,rowId) => {
  return {
    type: QUALITY_BY_JUTETYPE,
    payload: { url, rowId},
  };
};
export const QualityByJuteTypeSuccessfull = (data) => {
  return {
    type: QUALITY_BY_JUTETYPE_SUCCESSFULL,
    payload: data,
  };
};

export const JuteIndentLineItemsProps = (data) => {
  return {
    type: INDENT_LINEITEMS_JUTE,
    payload: data,
  };
};
export const ClearJuteIndentLineItemsProps = (data) => {
  return {
    type: CLEAR_INDENT_LINEITEMS_JUTE,
    payload: data,
  };
};
export const create_jute_indent=(url, data, history)=>{
  return{
    type:CREATE_JUTE_INDENT,
    payload:{url, data, history}
  }
}
export const create_jute_indent_successfull=(data)=>{
  return{
    type:CREATE_JUTE_INDENT_SUCCESSFULL,
    payload:data,
  }
}
export const update_jute_indent=(url, data, history)=>{
  return{
    type:UPDATE_JUTE_INDENT,
    payload:{url, data, history}
  };
};
export const update_jute_indent_successfull=(data)=>{
  return{
    type:UPDATE_JUTE_INDENT_SUCCESSFULL,
    payload:data,
  };
};
export const jute_indent_by_id=(url,history)=>{
  return{
    type:JUTE_INDENT_BY_ID,
    payload:{url,history}
  };
};
export const jute_indent_by_id_successfull=(data)=>{
  return{
    type:JUTE_INDENT_BY_ID_SUCCESSFULL,
    payload:data,
  };
}; 

export const getJuteIndentHeader = (data) => {
  return {
    type: JUTE_INDENT_HEADER,
    payload: data,
  };
};
export const ClearJuteIndentHeader = (data) => {
  return {
    type:CLEAR_JUTE_INDENT_HEADER,
    payload: data,
  };
};
