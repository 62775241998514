import {
  JUTE_CONVERSION_LIST,
  JUTE_CONVERSION_LIST_SUCCESSFULL,
  JUTE_GET_STOCK_DETAILS_BY_GODOWN_NUMBER,
  JUTE_GET_STOCK_DETAILS_BY_GODOWN_NUMBER_SCCESSFULL,
  // JUTE_GATE_ENTRY_LINE_ITEMS,
  // JUTE_GATE_ENTRY_CREATE,
  // JUTE_GATE_ENTRY_VIEW_BY_ID,
  // JUTE_GATE_ENTRY_VIEW_BY_ID_SCCESSFULL,
} from "./actionTypes";

export const JuteConversion = (url, data, history) => {
  return {
    type: JUTE_CONVERSION_LIST,
    payload: { url, data, history },
  };
};
export const JuteConversionSuccessfull = (data) => {
  return {
    type: JUTE_CONVERSION_LIST_SUCCESSFULL,
    payload: data,
  };
};
/*****************************getStockDetailsByGodownNo***********************************/
export const juteGetStockDetailsByGodownNumber = (url,data,history) =>{
  return {
      type:JUTE_GET_STOCK_DETAILS_BY_GODOWN_NUMBER,
      payload:{url,data,history}
  }
}

export const juteGetStockDetailsByGodownNumberSuccessfull = (data)=>{
  return {
      type:JUTE_GET_STOCK_DETAILS_BY_GODOWN_NUMBER_SCCESSFULL,
      payload:data
  }
}
// export const LineItemsGateEntryPros = (data) => {
//   return {type: JUTE_GATE_ENTRY_LINE_ITEMS, payload:data}
// }

// export const juteGateEntryUpdate = (url, data, history) => {
//   return {
//       type: JUTE_GATE_ENTRY_CREATE,
//       payload: {url, data, history}
//   }
// }

// export const juteGateEntryViewById = (url,history) =>{
//   return {
//       type:JUTE_GATE_ENTRY_VIEW_BY_ID,
//       payload:{url,history}
//   }
// }

// export const juteGateEntryViewByIdSuccessfull = (data)=>{
//   return {
//       type:JUTE_GATE_ENTRY_VIEW_BY_ID_SCCESSFULL,
//       payload:data
//   }
// }
