//************************************************************************** */
//date of creation : 02-08-2023 :: 12:00 AM Author : Nagesh Medisetty //
//************************************************************************** */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi, nullChecker } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import { subDepartmentMasterList } from "../../../store/Master/SubDepartmentMaster/actions";

const Index = (props) => {
  const [userDit, setUserDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [companyId, setCompanyId] = useState(localStorage.getItem("companyId"));
  const [subdepartmentMasterListData, setSubdepartmentMasterListData] = useState([]);

  const handleCallApi=(searchData)=>{
    const data = {
      cipher: userDit.cipher,
      companyId: companyId,
      userId: userDit.userId.toString(),
      deptDesc: searchData ? searchData.deptDesc : "",
      departmentName: searchData ? searchData.departmentName : "",
      deptCode: searchData ? searchData.deptCode : ""||null,
    };
    props.subDepartmentMasterList(
      serverApi.GET_ALL_SUB_DEPARTMENT_LIST,
      data,
      props.history
    );
  }

  useEffect(() => {
    handleCallApi(null);    
  }, []);

  useEffect(() => {
    if (props.SubDepartmentMasterList) {
      setSubdepartmentMasterListData(props.SubDepartmentMasterList.data);
    }
  }, [props.SubDepartmentMasterList]);

  const handleSearch = (searchData) => {
    handleCallApi(searchData);
  };

  return (
    <>
      <Gridwithcustomview
        mdata={subdepartmentMasterListData}
        mcolumns={Columns}
        micon={GridArrowIcon}
        status={true}
        actions={true}
        url="create_sub_department_master"
        handleSearch={handleSearch}
        filter_form={"SubDeptMaster"}
      />
    </>
  );
};

const Columns = [
  {
    Header: "Department Id",
    accessor: "deptId",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Department Code",
    accessor: "deptCode",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Department Name",
    accessor: "departmentName",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Master Department",
    accessor: "masterDepartmentName",
    hidden: false,
    csvExport: true,
  },
];

const mapStatetoProps = (state) => {
  const { SubDepartmentMasterList } = state.SubDepartmentReducer;
  return { SubDepartmentMasterList };
};

export default withRouter(
  connect(mapStatetoProps, {
    subDepartmentMasterList,
  })(Index)
);
