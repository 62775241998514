import {
  AGENT_NAME_LIST,
  AGENT_NAME_LIST_SUCCESSFULL,
  AGENT_LIST_DROPDOWN,
  AGENT_LIST_DROPDOWN_SUCCESSFULL,
  GET_BROKER_BY_SUPP_LIST,
  GET_BROKER_BY_SUPP_LIST_SUCCESSFULL,
  GET_PO_APPROVED_LIST,
  GET_PO_APPROVED_LIST_SUCCESSFULL,
  GET_PO_DETAILS_LIST,
  GET_PO_DETAILS_LIST_SUCCESSFULL,
  GET_QUALITY_BY_ITEM_LIST,
  GET_QUALITY_BY_ITEM_LIST_SUCCESSFULL,
  GET_SUPP_BY_MUKAM_LIST,
  GET_SUPP_BY_MUKAM_LIST_SUCCESSFULL,
  GET_VEHICLE_LIST,
  GET_VEHICLE_LIST_SUCCESSFULL,
  MUKAM_BY_ITEM_LIST,
  MUKAM_BY_ITEM_LIST_SUCCESSFULL,
  VEHICLE_TYPE_LIST,
  VEHICLE_TYPE_LIST_SUCCESSFULL,
  GET_GODOWN_NUMBER_LIST,
  GET_GODOWN_NUMBER_LIST_SUCCESSFULL,
  GET_PLAN_NAME_LIST,
  GET_PLAN_NAME_LIST_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  agentNameList: [],
  MukamByItemList: [],
  vehicleTypeList: [],
  SupplierByMukamList: [],
  BrokerBySupplierId: [],
  QualityByItemId: [],
  PoApprovedData: [],
  PoDetailsData: [],
  VehicleData: [],
  agentDropdownList: [],
  getGodownListData: [],
  getPlanNameListData: [],
};
const MasterDropDownListReducer = (state = initialState, action) => {
  switch (action.type) {
    case AGENT_NAME_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AGENT_NAME_LIST_SUCCESSFULL:
      state = {
        ...state,
        agentNameList: action.payload.response,
        loading: true,
      };
      break;
    case MUKAM_BY_ITEM_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case MUKAM_BY_ITEM_LIST_SUCCESSFULL:
      state = {
        ...state,
        MukamByItemList: action.payload.response,
        loading: true,
      };
      break;
    case VEHICLE_TYPE_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case VEHICLE_TYPE_LIST_SUCCESSFULL:
      state = {
        ...state,
        vehicleTypeList: action.payload.response,
        loading: true,
      };
      break;
    case GET_SUPP_BY_MUKAM_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SUPP_BY_MUKAM_LIST_SUCCESSFULL:
      state = {
        ...state,
        SupplierByMukamList: action.payload.response,
        loading: true,
      };
      break;
    case GET_BROKER_BY_SUPP_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_BROKER_BY_SUPP_LIST_SUCCESSFULL:
      state = {
        ...state,
        BrokerBySupplierId: action.payload.response,
        loading: true,
      };
      break;
    case GET_QUALITY_BY_ITEM_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_QUALITY_BY_ITEM_LIST_SUCCESSFULL:
      state = {
        ...state,
        QualityByItemId: action.payload.response,
        loading: true,
      };
      break;
    case GET_PO_APPROVED_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PO_APPROVED_LIST_SUCCESSFULL:
      state = {
        ...state,
        PoApprovedData: action.payload.response,
        loading: true,
      };
      break;
    case GET_PO_DETAILS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PO_DETAILS_LIST_SUCCESSFULL:
      state = {
        ...state,
        PoDetailsData: action.payload.response,
        loading: true,
      };
      break;
    case GET_VEHICLE_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_VEHICLE_LIST_SUCCESSFULL:
      state = {
        ...state,
        VehicleData: action.payload.response,
        loading: true,
      };
      break;
    case AGENT_LIST_DROPDOWN:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AGENT_LIST_DROPDOWN_SUCCESSFULL:
      state = {
        ...state,
        agentDropdownList: action.payload.response,
        loading: true,
      };
      break;
    case GET_GODOWN_NUMBER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_GODOWN_NUMBER_LIST_SUCCESSFULL:
      state = {
        ...state,
        getGodownListData: action.payload.response,
        loading: true,
      };
      break;
    case GET_PLAN_NAME_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PLAN_NAME_LIST_SUCCESSFULL:
      state = {
        ...state,
        getPlanNameListData: action.payload.response,
        loading: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default MasterDropDownListReducer;
