import {ITEMGROUP_LIST,CREATE_ITEMGROUP_MASTER,UPDATE_ITEMGROUP_MASTER,
    ITEMGROUP_LIST_SUCCESSFULL,CREATE_ITEMGROUP_MASTER_SUCCESSFULL,UPDATE_ITEMGROUP_MASTER_SUCCESSFULL, ITEM_GROUP_VIEW_BY_ID, ITEM_GROUP_VIEW_BY_ID_SUCCESSFULLY, ITEM_GROUP_DROPDOWN_API, ITEM_GROUP_DROPDOWN_API_SUCCESSFULL} from './actionTypes';



export const getItemGroupMasterList = (url,data,history) =>{
    return {
        type:ITEMGROUP_LIST,
        payload:{url,data,history}
    }
}

export const ItemGroupMasterListSuccessfull = (data)=>{
    return {
        type:ITEMGROUP_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createItemGroupMaster = (url,data,history) =>{
    return {
        type: CREATE_ITEMGROUP_MASTER,
        payload:{url,data,history}
    }
}

export const createItemGroupMasterSuccessfull = (data)=>{
    return {
        type:CREATE_ITEMGROUP_MASTER_SUCCESSFULL,
        payload:data
    }
}
export const updateItemGroupMaster = (url,data,history) =>{
    return {
        type:UPDATE_ITEMGROUP_MASTER,
        payload:{url,data,history}
    }
}

export const updateItemGroupMasterSuccessfull = (data)=>{
    return {
        type:UPDATE_ITEMGROUP_MASTER_SUCCESSFULL,
        payload:data
    }
}

export const getViewById = (url,data,history) =>{
    
    return {
        type: ITEM_GROUP_VIEW_BY_ID,
        payload:{url,data,history}
    }
}

export const getViewByIdSuccessfully = (data)=>{
    return {
        type: ITEM_GROUP_VIEW_BY_ID_SUCCESSFULLY,
        payload:data
    }
}

export const getItemGroupDropdownList=(url,history)=>{
    return{
        type:ITEM_GROUP_DROPDOWN_API,
        payload:{url,history}
    }
}

export const getItemGroupDropdownListSuccessfull=(data)=>{
    return{
        type:ITEM_GROUP_DROPDOWN_API_SUCCESSFULL,
        payload:data
    }
}


