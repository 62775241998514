import {
  VEHICLE_TYPES_MASTER_LIST,
  CREATE_VEHICLE_TYPES_MASTER,
  UPDATE_VEHICLE_TYPES_MASTER,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { postAdd, getList } from "../../../helpers/Server_Helper";
import {
  vehicleTypesMasterListSuccessfull,
  createVehicleTypesMasterSuccessfull,
  updateVehicleTypesMasterSuccessfull,
} from "./actions";
import swal from "sweetalert";

function* VehicleTypesMasterList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(vehicleTypesMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateVehicleTypesMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createVehicleTypesMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/vehicle_types_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateVehicleTypesMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateVehicleTypesMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/vehicle_types_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

export function* watchVehicleTypesMasterList() {
  yield takeEvery(VEHICLE_TYPES_MASTER_LIST, VehicleTypesMasterList);
}
export function* watchCreateVehicleTypesMaster() {
  yield takeEvery(CREATE_VEHICLE_TYPES_MASTER, CreateVehicleTypesMaster);
}
export function* watchUpdateVehicleTypesMaster() {
  yield takeEvery(UPDATE_VEHICLE_TYPES_MASTER, UpdateVehicleTypesMaster);
}

function* VehicleTypesMasterSaga() {
  yield all([
    fork(watchVehicleTypesMasterList),
    fork(watchCreateVehicleTypesMaster),
    fork(watchUpdateVehicleTypesMaster),
  ]);
}

export default VehicleTypesMasterSaga;
