import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Box, IconButton,Button } from "@mui/material";
import { allItemGroupMasterList } from "../../../../store/Global/DropDownApis/actions";
import DynamicSelect from "../../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import { getQuotationItemsByItemGroupId } from "../../../../store/Sales/Qutation/actions";
import { serverApi,serverConfig } from "../../../../helpers/Consts";
import TickIcon from "../../../../assets/images/fileUploadedTick.png";
import { getFileDownload } from "../../../../helpers/server.js";
import DeleteIcons from "../../../../assets/images/picEditIcon.png";
import panUploadIcon from "../../../../assets/images/panUploadIcon.png";
import {getJuteYarnInvoiceLineItems,getJuteYarnInvoiceHeader,getInvoiceJuteYarnUomRelationValue,
  UploadInvoiceFiles,
  deleteInvoiceFile,} from '../../../../store/Sales/InVoice/actions'
import swal from "sweetalert";
import { SalesGstCalculations } from "../../../../Utilities/SalesGstCalculation";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { limitDecimals ,QuantityOfVariableUOMconvertion,convertRatePcsToMt} from "../../../../Utilities/helper";
import ReactTooltip from "react-tooltip";
import {getInvoiceYarnUomList} from '../../../../store/Sales/InVoice/actions.js'

const JuteYarnLineItems = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [LineItems, setLineItems] = useState([]);
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [LineItemsDisplayTxtField, setLineItemsDisplayTxtField] = useState({});
  const [LineItemsDisplayDropField, setLineItemsDisplayDropField] = useState(
    {}
  );
  const [ItemGroupData, setItemGroupData] = useState([]);
  const [ItemNameData, setItemNameData] = useState([]);
  const [userDit, setuserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [uom2,setUom2]= useState([]);

  const [HeaderData, setHeaderData] = useState({});

  const { allItemGroupMaster_List } = useSelector(
    (state) => state.DropDownListReducer
  );
  const { JuteYarnInvoiceLineItems, JuteYarnInvoiceHeader,InvoiceFiles } =
    useSelector((state) => state.InvoiceReducer
    );
  const { quotationItemBy_ItemGroupId } = useSelector(
    (state) => state.QuotationReducer
  );
  const { uomMastersList }=useSelector(
    (state) => state.UomListReducer
  )
  const [invoiceFiles, SetInvoiceFiles] = useState(
    InvoiceFiles?.data?.data || []
  );
  useEffect(() => {
    SetInvoiceFiles(InvoiceFiles?.data?.data);
  }, [InvoiceFiles]);
  
  const onDeleteFile = (fileId) => {
    let data = invoiceFiles.filter(
      (item) => item.fileUploadId !== fileId
    );
    SetInvoiceFiles(data);
    dispatch({ type: "UPDATE_INVOICE_FILES", payload: data });
    dispatch(deleteInvoiceFile(serverApi.DELETEFILE + fileId));
  };
  const handleUpload = (e) => {
    e.preventDefault();
    let sourceMenu = 47;
  
    let file = e.target.files[0];
    let fileExt = file.name.split('.').pop();
    const formData = new FormData();
    formData.append('fileName', file.name);
    formData.append('fileUpload', file);
    formData.append('fileExtension', fileExt);
    formData.append('sourceMenu', sourceMenu);
    formData.append('displayName', file.name);
    formData.append('taskUniqueId', HeaderData.regInvoiceId);
    formData.append('createdBy',JSON.parse(localStorage.getItem("authUser")).userId);
    formData.append('fileCompanyId', localStorage.getItem('companyId'));
    dispatch(
      UploadInvoiceFiles(
        serverApi.UPLOADFILE,
        formData,
        history,
        sourceMenu,
        HeaderData.regInvoiceId
      )
    );
    
  };
  useEffect(()=>{
    setLineItems(JuteYarnInvoiceLineItems);
    setHeaderData(JuteYarnInvoiceHeader);
    if (allItemGroupMaster_List) {
      if (allItemGroupMaster_List.data) {
        let ListFilter = allItemGroupMaster_List.data.filter((props) => {
          if (props.value !== "0") {
            return props;
          }
        });
        let list = [];
        ListFilter.map((prop) => {
          list.push({
            label: prop.label,
            name: prop.name,
            value: prop.value,
            id: prop.id,
          });
        });
        setItemGroupData(list);
      }
    }
    if (quotationItemBy_ItemGroupId) {
      let ListFilter = quotationItemBy_ItemGroupId.filter((data) => {
        if (data.value !== "0") {
          return props;
        }
      });
      let list = [];
      ListFilter.map((prop) => {
        list.push({
          label: prop.label,
          name: prop.name,
          value: prop.value,
          id: prop.itemId,
        });
      });
      setItemNameData(list);
    }
    // if (uomMastersList) {
    //   if (uomMastersList.data) {
    //   if (uomMastersList.data.length !==0) {
    //     let ListFilter = uomMastersList.data.filter((data) => {
    //       if (data.value !== "0") {
    //         return props;
    //       }
    //     });
    //     let list = [];
    //     ListFilter.map((prop) => {
    //       list.push({
    //         label: prop.uomDsc,
    //         name: prop.uomDsc,
    //         value: prop.uomCode,
    //         variant: prop.id,
    //       });
    //     });
    //     setUom2(list);
    //   }} }
  }, [
    JuteYarnInvoiceLineItems,
    allItemGroupMaster_List,
    quotationItemBy_ItemGroupId,
    JuteYarnInvoiceHeader,
    uomMastersList
  ]);
  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const onDel = (row) => {
    var FilterLineItems = LineItems.filter((item) => item.isActive !== 0);
    if (FilterLineItems.length == 1) {
      swal("you can't delete the last lineitem");
    } else {
      let indexItem = LineItems.findIndex((item) => item === row);
      let lineItems = [...LineItems];
      lineItems[indexItem].isActive = 0;
      lineItems[indexItem].yarnIsActive = 0;
      setLineItems(lineItems);
      dispatch(getJuteYarnInvoiceLineItems(lineItems));
      let Disc = SalesGstCalculations(
        {
          lineItems: lineItems,
          taxType: HeaderData.taxType,
          brokerCommisionPercent:HeaderData.brokerCommissionPercent
        },
        "INVOICE_YARN_UOM_NEW"
      );
      handleCalculation(Disc);
    }
  };

  const displayField = (row, colname, type) => {
    if (colname === "itemGroupId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type == 1) {
        dispatch(
          allItemGroupMasterList(
            serverApi.GET_ALL_ITEMGROUP_MASTER_LIST +
              localStorage.getItem("companyId") +
              "/" +
              userDit.cipher,
            history
          )
        );
      }
    } else if (colname === "itemId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
    }  else if (colname === "yarnRateUomId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type === 1) { 
      dispatch(
        getInvoiceYarnUomList(
          serverApi.UOM_LIST_MASTERS_BASEDON_ITEM + localStorage.getItem("companyId") + "/" + "ItemId" + "/" + row.itemId,
          row.id
        )
      );
        }
    } 
    else if (colname === "yarnQuantityUomId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type === 1) { 
      dispatch(
        getInvoiceYarnUomList(
          serverApi.UOM_LIST_MASTERS_BASEDON_ITEM + localStorage.getItem("companyId") + "/" + "ItemId" + "/" + row.itemId,
          row.id
        )
      );
        }
    } 
  };
  const OnhandleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    let mname = key.split("_");
    if (mname[0] === "remarks") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.remarks = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteYarnInvoiceLineItems(LineItemsRec));
    }
    if (mname[0] === "itemMake") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.itemMake = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteYarnInvoiceLineItems(LineItemsRec));
    }
    if (mname[0] === "hsncode") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.hsncode = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteYarnInvoiceLineItems(LineItemsRec));
    }
    if (mname[0] === "rate") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            rowItem.rate = value;        
          if (rowItem.discount !== "") {
            if (rowItem.discountType == 1 && value !== "") {
              rowItem.discountedRate = limitDecimals(value - rowItem.discount,3);
            } else {
              let data = (value * rowItem.discount) / 100;
              rowItem.discountedRate = limitDecimals(value - data, 3);
            }
          } else {
            rowItem.discountedRate = limitDecimals(value, 3);
          }
        } else {
          swal("Please enter a positive number with up to 3 decimal places.");
        }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteYarnInvoiceLineItems(LineItemsRec));
      let Rate = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
          brokerCommisionPercent:HeaderData.brokerCommissionPercent
        },
        "INVOICE_YARN_UOM_NEW"
      );
      handleCalculation(Rate);
    }
    if (mname[0] === "discountedRate") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.discountedRate = limitDecimals(value);
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteYarnInvoiceLineItems(LineItemsRec));
    }
    if (mname[0] === "quantity") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
              if(Number(value) <= Number(rowItem.invoiceQty) ){
                rowItem.quantity = value; 
                rowItem.yarnQuantityCalculatedValue=QuantityOfVariableUOMconvertion(value,rowItem.yarnQuantityUomRelationValue)
              }
              else {
                swal("Quantity can't be more than Balance Quantity.")
                rowItem.quantity=rowItem.invoiceQty
                rowItem.yarnQuantityCalculatedValue=QuantityOfVariableUOMconvertion(rowItem.invoiceQty,rowItem.yarnQuantityUomRelationValue)
              } 
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteYarnInvoiceLineItems(LineItemsRec));
      let Qty = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
          brokerCommisionPercent:HeaderData.brokerCommissionPercent
        },
        "INVOICE_YARN_UOM_NEW"
      );
      handleCalculation(Qty);
    }
    if (mname[0] === "uom") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.uom = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteYarnInvoiceLineItems(LineItemsRec));
    }
    if (mname[0] === "taxPercent") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.taxPercent = limitDecimals(value);
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteYarnInvoiceLineItems(LineItemsRec));
      let TaxPer = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
          brokerCommisionPercent:HeaderData.brokerCommissionPercent
        },
        "INVOICE_YARN_UOM_NEW"
      );
      handleCalculation(TaxPer);
    }
    if (mname[0] === "netAmount") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.netAmount = limitDecimals(value);
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteYarnInvoiceLineItems(LineItemsRec));
    }
    if (mname[0] === "yarnQuantityUomRelationValue") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.yarnQuantityUomRelationValue = value;
          rowItem.yarnQuantityCalculatedValue=QuantityOfVariableUOMconvertion(rowItem.quantity,value)
        }
        return rowItem;
      });
      // setLineItems(LineItemsRec);
      // dispatch(getJuteYarnInvoiceLineItems(LineItemsRec));
      let TaxPer = SalesGstCalculations(
        {
          lineItems: LineItemsRec,
          taxType: HeaderData.taxType,
          brokerCommisionPercent:HeaderData.brokerCommissionPercent
        },
        "INVOICE_YARN_UOM_NEW"
      );
      handleCalculation(TaxPer);
    }
    if (mname[0] === "yarnRateUomRelationValue") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.yarnRateUomRelationValue = value;
          let convertedValue = convertRatePcsToMt(rowItem.rate,value)
          rowItem.yarnRateCalculatedValue=convertedValue
          rowItem.yarnRateExcludingBrokerage=convertedValue
          rowItem.yarnRateIncludingBrokerage=convertedValue
          rowItem.discountedRate = convertedValue
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteYarnInvoiceLineItems(LineItemsRec));
      let TaxPer = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
          brokerCommisionPercent:HeaderData.brokerCommissionPercent
        },
        "INVOICE_YARN_UOM_NEW"
      );
      handleCalculation(TaxPer);
    }
  };
  const handleChange = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    selectedItemCode,
  ) => {
    var lastRow = LineItems[LineItems.length - 1];
    if (name === "itemGroupId") {
      if (selectedRow === lastRow) {
        let LineItem = LineItems;
        let Obj =  {
          i:0,
          salesRegularInvoiceLineitemId: "",
          regInvoiceId: "",
          createdBy: "",
          createdDate: "",
          companyId: "",
          companyName: "",
          regularSalesOrderId: "",
          regularSalesOrderLineItemId: "",
          regularDeliveryOrderID: "",
          regularDeliveryOrderLineItemID: "",
          itemGroupId: "",
          itemGroupName: "",
          itemGroupValue: "",
          itemId: null,
          itemName: "",
          itemValue: "",
          itemCode: "",
          hsncode: "",
          itemMake: "",
          quantity: "0.000",
          uom: "",
          rate: "0.000",
          invoiceQty: null,
          igstAmount: "",
          igstPercent: "",
          cgstAmount: "",
          cgstPercent: "",
          sgstAmount: "",
          sgstPercent: "",
          cessAmount: "",
          discountType: "",
          discountName: "",
          discount: "",
          discountedRate: "0.000",
          discountAmount:"0.00",
          netAmount: "0.00",
          totalAmount: "0.00",
          taxPercent: "0.00",
          remarks: "",
          balesPacketSlNo: "",
          isActive: 1,
          yarnIsActive:1,
          status: 1,
          updatedBy: "",
          updatedOn: "",
          govtInvoiceLineItemId: "",
          govtInvoiceHdrId: "",
          govtInvoiceRateUom: null,
          govtInvoiceRateUom2: null,
          govtInvoiceRateUom3: null,
          govtInvoiceQuantityUom: null,
          govtInvoiceQuantityUom2: null,
          govtInvoiceQuantityUom3: null,
          govtInvoiceRelationValue: null,
          govtInvoiceRelationValue2: null,
          govtInvoiceRelationValue3: null,
          govtInvoiceQtyCalculatedValue: null,
          govtInvoiceQtyCalculatedValue2: null,
          govtInvoiceQtyCalculatedValue3: null,
          govtInvoiceRateCalculatedValue:null,
          govtInvoiceRateCalculatedValue2: null,
          govtInvoiceRateCalculatedValue3: null,
          govtInvoiceIsActive: null,
          govtInvoiceBalesPacketSlNo: null,
          yarnInvoiceLineItemId: null,
          yarnInvoiceId: null,
          yarnProductId: null,
          yarnQuantity: null,
          yarnUnitPrice: null,
          yarnRateUom: null,
          yarnRateUomRelationValue: null,
          yarnRateUomId: null,
          yarnRateCalculatedValue: null,
          yarnQuantityUom: null,
          yarnQuantityUomRelationValue: null,
          yarnQuantityUomId: null,
          yarnQuantityCalculatedValue: null,
          yarnVariableRelationValue: null,
          yarnTotalPrice: null,
          yarnBalesPacketSlNo: null,
          yarnRateIncludingBrokerage:"",
          yarnRateExcludingBrokerage:"",
          hessianInvoicelineitemId: null,
          hessianInvoiceId: null,
          hessianQuantityUom: null,
          hessianQuantityUomId: null,
          hessianQuantityRelationValue: null,
          hessianQuantityCalculatedValue: null,
          hessianRateUom: null,
          hessianRateUomId: null,
          hessianRateRelationValue: null,
          hessianRateCalculatedValue: null,
          hessianRateExcludingBrokerage: null,
          hessianRateIncludingBrokerage: null,
          hessianBalesPacketSlNo: null,
          ItemNameData: [],
        }
        LineItem.push(Obj);
        setLineItems(LineItem);
        dispatch(getJuteYarnInvoiceLineItems(LineItem));
      }
    }
    if(name === "itemGroupId"){
      let LineItemdata = LineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          row.itemGroupId = selectedItemCode;
          row.itemGroupName = selectedName;
          row.itemGroupValue=selectedValue;
        }
        return row;
      });
      setLineItems(LineItemdata);
      dispatch(getJuteYarnInvoiceLineItems(LineItemdata));
      displayField(selectedRow, "itemGroupId", 2);
      const data = {
        companyId: localStorage.getItem("companyId"),
        consumable: "",
        itemGroupId: selectedValue,
        saleable: "Y",
      };
      dispatch(
        getQuotationItemsByItemGroupId(
          serverApi.QUOTATION_ITEM_BY_ITEM_GROUP,
          data,
          history
        )
      );
    }
    if (name === "itemId") {
      let checkDuplicate = false;
      checkDuplicate = LineItems.find(
        ({ itemId }) => Number(itemId) === Number(selectedValue)
      );
    let LineItemdata = LineItems.filter((row) => {
      if (row.id === selectedRow.id) {
        row.itemId = selectedItemCode;
        row.itemValue = selectedValue;
        row.itemCode = row.itemGroupValue + selectedItemCode;
        var SelectedData = selectedName.split("^", 11);

        row.itemName = SelectedData[0];
        row.hsncode = SelectedData[4];
        row.rate = SelectedData[3];
        row.discountedRate = SelectedData[3];
        row.uom = SelectedData[1];
      }
      return row;
      });
      setLineItems(LineItemdata);
      dispatch(getJuteYarnInvoiceLineItems(LineItemdata));
      displayField(selectedRow, "itemId", 2);
    }

    if (name === "yarnQuantityUomId") {
      let PrimaryUom = ""
      let ItemId=""
      let LineItemsRec = LineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          row.yarnQuantityUom=selectedValue
          row.yarnQuantityUomId=selectedItemCode
          PrimaryUom = row.uom
          ItemId= row.itemId
        }
        return row;
      });

      dispatch(
        getInvoiceJuteYarnUomRelationValue(
          serverApi.GET_UOM_RELATION_VALUE_BY_UOM_CODE +localStorage.getItem("companyId") + "/fromUom" + "/" + PrimaryUom + "/toUom" + "/" + selectedValue + "/ItemId" + "/" + ItemId,
          ItemId,
          "quantityUOM",
            history
        )
      );
      let DiscType = SalesGstCalculations(
        {
          lineItems: LineItemsRec,
          taxType: HeaderData.taxType,
          brokerCommisionPercent:HeaderData.brokerCommissionPercent
        },
        "INVOICE_YARN_UOM_NEW"
      );
      handleCalculation(DiscType);
      displayField(selectedRow, "yarnQuantityUomId", 2);

    }
    if (name === "yarnRateUomId") {
      let PrimaryUom = ""
      let ItemId=""
      let LineItemsRec = LineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          row.yarnRateUom=selectedValue
          row.yarnRateUomId=selectedItemCode
          PrimaryUom = row.uom
          ItemId=row.itemId
        }
        return row;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteYarnInvoiceLineItems(LineItemsRec));
      dispatch(
        getInvoiceJuteYarnUomRelationValue(
          serverApi.GET_UOM_RELATION_VALUE_BY_UOM_CODE +localStorage.getItem("companyId") + "/fromUom/" + PrimaryUom + "/toUom/" + selectedValue + "/ItemId/" + ItemId,
          ItemId,
          "yarnRateUom",
            history
        )
      );
      let DiscType = SalesGstCalculations(
        {
          lineItems: LineItemsRec,
          taxType: HeaderData.taxType,
          brokerCommisionPercent:HeaderData.brokerCommissionPercent
        },
        "INVOICE_YARN_UOM_NEW"
      );
      handleCalculation(DiscType);
      displayField(selectedRow, "yarnRateUomId", 2);

    }
    
  };
  const handleCalculation = (Calculations) => {
    setLineItems(Calculations.lineItems);
    dispatch(getJuteYarnInvoiceLineItems(Calculations.lineItems));

    var Headerdata = {
      ...HeaderData,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
    };
    setHeaderData((prev) => ({
      ...prev,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
    }));
    dispatch(getJuteYarnInvoiceHeader(Headerdata));
  };

  const handleEventPositioned = (info, id) => {
    var filteredLineitems = LineItems.filter((item) => item.isActive !== 0);
  
    filteredLineitems.map((item) => {
      if (item.id == id) {
        if (info.target) {
          info.target.setAttribute(
            "data-tip",
            `
          <p>Balance Quantity = ${
            item.invoiceQty === undefined || item.invoiceQty === null
              ? 0
              : item.invoiceQty
          }</p>
          `
          );
          ReactTooltip.rebuild();
        }
      }
    });
  };
  
    let filterProduct = LineItems.filter((item) => item.isActive != 0);
    
   return (
    <>
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent" onScroll={handleScroll}>
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {tableHeaders.map((column) => (
                    <>
                      {column.header === "Item Group" ||
                      column.header === "Item Name" ||
                      column.header === "Rate" ||
                      column.header === "Quantity" ||
                      column.header === "UOM" ||
                      column.header === "Variable UOM" ||
                      column.header === "Quantity of Variable UOM"
                      ? (
                        <>
                          <th>
                            {column.header}
                            <label style={{ color: "red" }}>*</label>
                          </th>
                        </>
                      ) : (
                        <>
                          <th>{column.header}</th>
                        </>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filterProduct.length !== 0 &&
                  filterProduct.map((row, i) => {
                    let rowIndex = filterProduct.findIndex((idd) => idd == row);

                    return (
                      <>
                        <tr>
                          <>
                            {RowData &&
                              RowData.map((col) =>
                                col.component === "Editablecell" ? (
                                  <>
                                    <td>
                                      <div className="quantityDropdownBlock">
                                        <Grid className="selectAndTextfield">
                                          <Grid
                                            xs={12}
                                            md={12}
                                            className="dynamicSelectBlock"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <input
                                               onMouseOver={(e) =>
                                                col.type === "quantity" &&
                                               handleEventPositioned(
                                                      e,
                                                      row.id
                                                    )
                                              }
                                              onWheel={(e) => e.target.blur()}
                                              type={
                                                col.type === "rate"
                                                  ? "number"
                                                  : col.type ===
                                                    "discountedRate"
                                                  ? "number"
                                                  : col.type === "quantity"
                                                  ? "number"
                                                  : col.type === "taxPercent"
                                                  ? "number"
                                                  : col.type === "netAmount"
                                                  ? "number"
                                                  : col.type === "yarnRateUomRelationValue"
                                                  ? "number"
                                                  : col.type === "yarnQuantityUomRelationValue"
                                                  ? "number"
                                                  : "text"
                                              }
                                              name={col.type + "_" + rowIndex}
                                              disabled={
                                                col.type === "discountedRate"
                                                  ? true
                                                  : 
                                                  col.type === "rate"
                                                  ? true
                                                  : col.type === "uom"
                                                  ? true
                                                  : col.type === "taxPercent"
                                                  ? true
                                                  : col.type === "netAmount"
                                                  ? true
                                                  : col.type==="hsncode"
                                                  ? true 
                                                  : col.type==="discountMode"
                                                  ? true 
                                                  : col.type==="discount"
                                                  ? true 
                                                  : col.type === "remarks"
                                                  ? true
                                                  : col.type === "yarnRateIncludingBrokerage"
                                                  ? true
                                                  : false
                                              }
                                              value={
                                                col.type === "remarks"
                                                  ? row.remarks
                                                  : col.type === "hsncode"
                                                  ? row.hsncode
                                                  : col.type === "rate"
                                                  ? row.rate
                                                  : col.type === "discountedRate"
                                                  ? row.discountedRate
                                                  : col.type === "quantity"
                                                  ? row.quantity
                                                  : col.type === "uom"
                                                  ? row.uom
                                                  : col.type === "taxPercent"
                                                  ? row.taxPercent
                                                  : col.type === "netAmount"
                                                  ? row.netAmount
                                                  : col.type === "discountMode"
                                                  ? row.discountName 
                                                  : col.type === "discount" 
                                                  ? row.discount 
                                                  : col.type === "yarnRateUomRelationValue"
                                                  ? row.yarnRateUomRelationValue 
                                                  : col.type === "yarnQuantityUomRelationValue"
                                                  ? row.yarnQuantityUomRelationValue 
                                                  : col.type === "yarnRateIncludingBrokerage"
                                                  ? row.yarnRateIncludingBrokerage                              
                                                  : ""
                                              }
                                              onChange={OnhandleChange({
                                                key: col.type + "_" + rowIndex,
                                                row,
                                              })}
                                              className="inputBlockContainer"
                                              style={{
                                                backgroundColor:
                                                  col.type === "discountedRate"
                                                    ? "#ccd6db"
                                                    : 
                                                    col.type === "rate"
                                                    ? row.itemGroupValue === "997" ? "":"#ccd6db"
                                                    : col.type === "uom"
                                                    ? "#ccd6db"
                                                    : col.type === "taxPercent"
                                                    ? "#ccd6db"
                                                    : col.type === "netAmount"
                                                    ? "#ccd6db"
                                                    : col.type === "discountMode"
                                                    ? "#ccd6db"
                                                    : col.type === "discount"
                                                    ? "#ccd6db"
                                                    : col.type === "remarks"
                                                    ? "#ccd6db"
                                                    : col.type === "hsncode"
                                                    ? "#ccd6db"
                                                    : col.type === "yarnRateIncludingBrokerage"
                                                    ? "#ccd6db"
                                                    : "",
                                                textAlign:
                                                  col.type !== "remarks" &&
                                                  col.type !== "hsncode" &&
                                                  col.type !== "uom"&&
                                                  col.type !=="discountMode"
                                                    ? "right"
                                                    : "",
                                                paddingRight: "8px",
                                              }}
                                            />
                                                 <ReactTooltip
                                              effect={"solid"}
                                              html={true}
                                              place={"BottomCenter"}
                                              className="tooltipClassBorder"
                                              backgroundColor="rgba(0,127,173,0.5)"
                                              textColor="#FFF"
                                              border={true}
                                              borderColor="#04749c"
                                            />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </td>
                                  </>
                                ) : col.component === "DynamicSelect" ? (
                                  <td>
                                    <div className="quantityDropdownBlock">
                                      <Grid className="selectAndTextfield">
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: LineItemsDisplayTxtField[
                                              col.type + row.id + "1"
                                            ]
                                              ? "none"
                                              : "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                        
                                          <input
                                            type="text"
                                            className="inputBlockContainer"
                                            style={{
                                              paddingRight: "8px",
                                              backgroundColor:
                                                col.type === "itemGroupId"? "#ccd6db"
                                               : col.type === "itemId"? "#ccd6db"
                                                  : "",
                                            }}
                                            value={
                                              col.type === "itemGroupId"
                                                ? row.itemGroupName
                                                : col.type === "itemId"
                                                ? row.itemName
                                                : col.type === "yarnRateUomId"
                                                ? row.yarnRateUom
                                                : col.type === "yarnQuantityUomId"
                                                ? row.yarnQuantityUom
                                                : "" 
                                            }
                                            onChange={OnhandleChange({
                                              key: col.type + "_" + rowIndex,
                                              row,
                                            })}
                                            onClick={() => {
                                              displayField(row, col.type, 1);
                                            }}
                                            disabled={
                                              col.type === "itemGroupId"
                                                ? true
                                                : col.type === "itemId"
                                                ?   true
                                                : col.type === "yarnRateUomId"
                                                ?   false
                                                : col.type === "yarnQuantityUomId"
                                                ?   false
                                                : false
                                            }
                                          />
                                        </Grid>
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: LineItemsDisplayDropField[
                                              col.type + row.id + "2"
                                            ]
                                              ? "flex"
                                              : "none",
                                            alignItems: "center",
                                          }}
                                        >
                                          <DynamicSelect
                                            arrayOfData={
                                              col.type === "itemGroupId"
                                                ? ItemGroupData
                                                : col.type === "itemId"
                                                ? row.ItemNameData
                                                : col.type === "yarnRateUomId"
                                                ? row.uom2Data
                                                : col.type === "yarnQuantityUomId"
                                                ? row.uom2Data
                                                : ""
                                            }
                                            className="dropdownBlockContainer"
                                            onSelectChange={handleChange}
                                            selected={
                                              col.type === "itemGroupId"
                                                ? row.itemGroupId
                                                : col.type === "itemId"
                                                ? row.itemId
                                                : col.type === "yarnRateUomId"
                                                ? row.yarnRateUom
                                                : col.type === "yarnQuantityUomId"
                                                ? row.yarnQuantityUom
                                                : ""
                                            }
                                            name={
                                              col.type === "itemGroupId"
                                                ? "itemGroupId"
                                                : col.type === "itemId"
                                                ? "itemId"
                                                : col.type === "yarnRateUomId"
                                                ? "yarnRateUomId"
                                                : col.type === "yarnQuantityUomId"
                                                ? "yarnQuantityUomId"
                                                : ""
                                            }
                                            row={row}
                                            update={
                                                 row.itemGroupId||                                             
                                                row.itemId||                                           
                                                row.yarnRateUom||
                                                row.yarnQuantityUom 
                                                ?
                                                1:0
                                                
                                            }
                                            isDropdownOpen={isDropdownOpen}
                                            handleMenuOpen={handleMenuOpen}
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </td>
                                ) : (
                                  ""
                                )
                              )}
                          </>
                          <td>
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Delete"
                            >
                              <IconButton
                                onClick={() => {
                                  onDel(row, i);
                                }}
                              >
                                <DeleteIcon className="deleteDisplayButton" />
                              </IconButton>
                            </Tooltip>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="consoleFormContainer">
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 12">
                      <div
                        className="calculationBlockTable"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <table>
                        <tr>
                            <td>Taxable Value:</td>
                            <b>{limitDecimals(HeaderData.netTotal,2)}</b>
                          </tr>
                          <tr>
                            <td>Total IGST:</td>
                            <b>
                              {" "}
                              {limitDecimals(HeaderData.totalIGST,2)}
                            </b>
                          </tr>
                          <tr>
                            <td>Total SGST:</td>
                            <td>
                              {limitDecimals(HeaderData.totalSGST,2)}
                            </td>
                          </tr>
                          <tr>
                            <td>Total CGST:</td>
                            <td>
                              {limitDecimals(HeaderData.totalCGST,2)}
                            </td>
                          </tr>
                         
                          <tr>
                            <td>Broker Percentage : @</td>
                            <b>{limitDecimals(HeaderData.brokerCommissionPercent ? HeaderData.brokerCommissionPercent:0 ,2)}%</b>
                          </tr>
                          <tr>
                            <td>Total Value With Tax:</td>
                            <b>
                              {limitDecimals(HeaderData.TotalAmount,2)}
                            </b>
                          </tr>
                        </table>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
          {props.locationState.state !== undefined ? (
        <div className="consoleFormContainer">
          <div style={{ height: '15px' }}></div>
          <div className="consoleFormBlock" style={{ display: 'flex' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2} className="ducumentUploadBlock uploadedContent">
                  <Box gridColumn="span 10">
                    {invoiceFiles?.length > 0 &&
                      invoiceFiles.map((item) => (
                        <div key={item.fileUploadId} className="uploadedInfo" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', padding: '5px', border: '1px solid #ccc', borderRadius: '5px' }}>
                          <img src={TickIcon} alt="" className="tickIcon" height={'20px'} width={'20px'} />
                          <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} title="Click to download File">
                            <div
                              className="uplodedFileName"
                              onClick={() => {
                                getFileDownload(
                                  serverConfig.SERVER_URL +
                                  'security-api/api/files/downloadfile/' +
                                  item.fileUploadId,
                                  item.fileName,
                                  item.fileExtension
                                );
                              }}
                              style={{ cursor: 'pointer', marginLeft: '10px', marginRight: '10px' }}
                            >
                              {item.fileName}
                            </div>
                          </Tooltip>
                          <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 100 }} title="Click to Delete File">
                            <Button
                              onClick={() => {
                                onDeleteFile(item.fileUploadId);
                              }}
                              className="deleteButton"
                              style={{ marginLeft: '10px' }}
                            >
                              <img src={DeleteIcons} alt="" height={'20px'} width={'20px'} />
                            </Button>
                          </Tooltip>
                        </div>
                      ))}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                  <Box gridColumn="span 4">
                    <div style={{ marginBottom: '10px' }}>
                      <b>Support Documents:</b><span style={{ color: 'red' }}></span>
                    </div>
                    <div className="personalDetailsUpload">
                      <div className="ducumentUploadBlock">
                        <div className="documentUploadContent">
                          <span className="btn_upload documentUpload" style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={panUploadIcon} alt="" style={{ marginRight: '10px' }} />
                            <input
                              type="file"
                              id="imag"
                              title=""
                              className="input-img"
                              onChange={handleUpload}
                            />
                            Upload Document
                          </span>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        ""
      )}
        </div>
      </div>
    </>
  );
};
const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "itemGroupId",
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "itemId",
  },
  {
    no: 3,
    component: "Editablecell",
    type: "remarks",
  },
  {
    no: 4,
    component: "Editablecell",
    type: "hsncode",
  },
  {
    no: 5,
    component: "Editablecell",
    type: "quantity",
  },
  {
    no: 6,
    component: "Editablecell",
    type: "uom",
  },
  {
    no: 7,
    component: "DynamicSelect",
    type: "yarnQuantityUomId",
  },
  {
    no: 8,
    component: "Editablecell",
    type: "yarnQuantityUomRelationValue",
  },
  {
    no: 9,
    component: "Editablecell",
    type: "rate",
  },
  // {
  //   no: 10,
  //   component: "DynamicSelect",
  //   type: "yarnRateUomId",
  // },
  // {
  //   no: 11,
  //   component: "Editablecell",
  //   type: "yarnRateUomRelationValue",
  // },

  {
    no: 16,
    component: "Editablecell",
    type: "taxPercent",
  },
  {
    no: 17,
    component: "Editablecell",
    type: "netAmount",
  },
];
const tableHeaders = [
  {
    header: "Item Group",
  },
  {
    header: "Item Name",
  },
  {
    header: "Remarks",
  },
  {
    header: "HSN",
  },
  {
    header: "Quantity",
  },
  {
    header: "UOM",
  },
  {
    header: "Variable UOM",
  },
  {
    header: "Quantity of Variable UOM",
  },
  {
    header: "Rate",
  },
  // {
  //   header: "RateUOM",
  // },
  // {
  //   header: "Rate Relation Value",
  // },
 
  {
    header: "Tax%",
  },
  {
    header: "Amount",
  },
  {
    header: "Actions",
  },
];


export default JuteYarnLineItems;
