import React, { useState, useEffect } from "react";
import { serverApi, serverConfig, serverVars } from "../../../helpers/Consts";
import DynamicSelect from "../../../Pages/StoreModule/MaterialRequest/DynamicSelect";
import { getMukamByItemList } from "../../../store/MasterDropdownApis/actions";
import { GetPoLineItems,JutePODeleteFiles,UploadJutePOFiles,getJutePOHeader,getJuteQuantityByJuteType,getLatestMarketRate, getSupportFiles} from "../../../store/Jute/JutePurchase/actions";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteIcons from "../../../assets/images/picEditIcon.png";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { Grid, Box, IconButton, Button } from "@mui/material";
import { isEmpty, limitDecimals } from "../../../Utilities/helper";
import swal from "sweetalert";
import ReactTooltip from "react-tooltip";
import { getFileDownload } from "../../../helpers/server";

const JutePurchaseOrderLineItems = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [locationState] = useState(useLocation());
  const [LineItems, setLineItems] = useState([]);
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [LineItemsDisplayTxtField, setLineItemsDisplayTxtField] = useState({});
  const [LineItemsDisplayDropField, setLineItemsDisplayDropField] = useState(
    {}
  );
  const [userDit, setuserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [mukamList, setmukamList] = useState([]);
  const [qualitybyItemData, setqualitybyItemData] = useState([]);
  const [HeaderData, setHeaderData] = useState({});
  const [supportDocsListData, setSupportDocsList] = useState([]);

  const { MukamByItemList, vehicleTypeList } = useSelector(
    (state) => state.MasterDropDownListReducer
  );
  const { qualitybyItem, lastMarkRate } = useSelector(
    (state) => state.DropDownListReducer
  );
  const { JutePoLineItems,JutePoHeader ,supportFilesList} = useSelector((state) => state.JutePurchaseReducer);
  useEffect(() => {
    setLineItems(JutePoLineItems);
    setHeaderData(JutePoHeader);
  }, [JutePoLineItems, JutePoHeader]);

  useEffect(()=>{
    if(supportFilesList){
      if(supportFilesList.data){
        setSupportDocsList(supportFilesList.data)
      }
    }
    },[supportFilesList])

  const OnhandleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    let mname = key.split("_");

    if (mname[0] === "stock") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.stock = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(GetPoLineItems(LineItemsRec));
    }
    if (mname[0] === "quantity") {
      let totalQuantiy =
        HeaderData.vehicleTypeName.split("_")[1] * HeaderData.vehicleQuantity;
      let LineItemsRec = LineItems.filter((rowItem) => {
        let baleWeight = 150; // conversion value for bale
        let actualQuantity = 0;
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            if (
              HeaderData.juteUnit === "LOOSE" &&
              localStorage.getItem("companyId") !== "116"
            ) {
              if (Number(value) <= 100) {
                rowItem.quantity = value;
                actualQuantity = (totalQuantiy * value) / 100;
                rowItem.actualQuantity = actualQuantity;
                rowItem.amount = actualQuantity * rowItem.rate;
              } else {
                swal("Quantity should not be greater than 100!");
              }
            } else {
              rowItem.quantity = value;
              actualQuantity = (baleWeight * value) / 100;
              rowItem.actualQuantity = actualQuantity;
              rowItem.amount = actualQuantity * rowItem.rate;
            }
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(GetPoLineItems(LineItemsRec));
      getTotalAmount(HeaderData.frieghtCharge);
    }
    if (mname[0] === "rate") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            rowItem.rate = value;
            rowItem.amount = limitDecimals(
              Number(rowItem.actualQuantity) * Number(value),
              2
            );
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(GetPoLineItems(LineItemsRec));
      getTotalAmount(HeaderData.frieghtCharge);
    }
    if (mname[0] === "amount") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            rowItem.amount = value;
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(GetPoLineItems(LineItemsRec));
    }
    if (mname[0] === "marka") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.marka = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(GetPoLineItems(LineItemsRec));
    }
    if (mname[0] == "actualQuantity") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            rowItem.actualQuantity = value;
            if (rowItem.rate !== "") {
              rowItem.amount = limitDecimals(
                Number(rowItem.rate) * Number(value)
              );
            }
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(GetPoLineItems(LineItemsRec));
      getTotalAmount(HeaderData.frieghtCharge);
      // getTotalWeight(HeaderData.frieghtCharge)
    }
    
    if (mname[0] === "allowableMoisturePercentage") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if(value <= 100){
            if (value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
            rowItem.allowableMoisturePercentage = value
            } else {
              swal("Please enter a positive number with up to 2 decimal places.");
            }
          } else {
            swal("Allowable Moisture should not be 100 or greater than 100!");
          }

        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(GetPoLineItems(LineItemsRec));
     
    }
    
  };

  const OnhandleChangeProp = (event) => {
    var TotalNet = 0;
    TotalNet = LineItems.filter((item) => item.isActive !== 0 && item.itemId !== "")
      .map((p) => (p.amount == null ? 0 : p.amount))
      .reduce((amount, ind) => parseFloat(amount) + parseFloat(ind), 0);
    if (event.target.value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
      setHeaderData((prev) => ({
        ...prev,
        frieghtCharge: event.target.value,
        netTotal: Number(TotalNet) + Number(event.target.value),
      }));
      const UpdatedRec = {
        ...HeaderData,
        frieghtCharge: event.target.value,
        netTotal: Number(TotalNet) + Number(event.target.value),
      };
      dispatch(getJutePOHeader(UpdatedRec));
    } else {
      swal("Please enter a positive number with up to 2 decimal places.");
    }
  };
  const onDel = (row) => {
    var FilterLineItems = LineItems.filter((item) => item.isActive !== 0);
    if (FilterLineItems.length == 1) {
      swal("you can't delete the last lineitem");
    } else {
      let indexItem = LineItems.findIndex((item) => item === row);
      let lineItems = [...LineItems];
      lineItems[indexItem].isActive = 0;
      setLineItems(lineItems);
      dispatch(GetPoLineItems(lineItems));
      getTotalAmount();
      // getTotalWeight()
    }
  };

  const displayField = (row, colname, type) => {
    if (colname === "itemId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type == 1) {
        dispatch(
          getMukamByItemList(
            serverApi.GET_JUTE_TYPE +
              JutePoHeader.mukam +
              "/getAllItemByMukam/" +
              localStorage.getItem("companyId") +
              "/" +
              userDit.cipher,
            history
          )
        );
      }
    } else if (colname === "qualityCode") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
    } else if (colname === "cropYear") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
    }
  };

  useEffect(() => {
    dispatch(
      getMukamByItemList(
        serverApi.GET_JUTE_TYPE +
          JutePoHeader.mukam +
          "/getAllItemByMukam/" +
          localStorage.getItem("companyId") +
          "/" +
          userDit.cipher,
        history
      ))

      dispatch(getSupportFiles(
        serverApi.GET_FILES + "12/" + HeaderData.hdrId + "/" + localStorage.getItem("companyId"),
      )) 

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (MukamByItemList.data) {
      if (MukamByItemList.data.length !== 0) {
        let ListFilter = MukamByItemList.data.filter((item) => {
          if (item.value != 0) {
            return item;
          }
        });
        let list = [];
        ListFilter.map((prop) => {
          list.push({
            label: prop.label,
            name: prop.name,
            value: prop.value,
            variant: prop.name.split("^")[1],
          });
        });
        setmukamList(list);
      }
    }
  }, [MukamByItemList]);

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };

  const handleSelectChange = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    selectedItemCode
  ) => {
    var lastRow = JutePoLineItems[JutePoLineItems.length - 1];
    if (name === "itemId") {
      if (selectedRow === lastRow) {
        let LineItem = LineItems;
        let Obj = {
          id: LineItems.length + 1,
          itemId: "",
          itemDesc: "",
          itemCode: "",
          qualityCode: "",
          qualityCodeName: "",
          unitId: "",
          quantity: 0,
          rate: "",
          allowableMoisturePercentage: 0.0,
          tax: 0,
          valueWithoutTax: "",
          cTax: 0,
          sTax: 0,
          iTax: 0,
          bale: 0,
          loose: 0,
          poId: null,
          deptId: "",
          valueWithTax: 0,
          indentId: "",
          actualQuantity: "",
          itemGroupId: "",
          discount: 0,
          taxId: 0,
          taxPercentage: 0,
          deptName: "",
          juteQuality: "",
          hsnCode: "",
          marka: "",
          cropYear: "",
          gatePoPlaced: 0,
          remaingQuantity: 0,
          lastPurchaseRate: null,
          lastPurchaseDate: null,
          lastPurchaseSupp: null,
          stRemQty: 0,
          pendingAtRcpt: 0,
          itemGroupName: "",
          stock: 0,
          amount: "",
          QuantityListData: [],
          uom: "",
        };
        LineItem.push(Obj);
        setLineItems(LineItem);
        dispatch(GetPoLineItems(LineItem));
      }
    }
    if (name === "itemId") {
      let LineItemdata = LineItems.filter((row) => {
        console.log("selectedname", selectedName);
        var selectedname = selectedName.split("^");
        if (row.id === selectedRow.id) {
          row.itemId = selectedValue;
          row.itemDesc = selectedname[0];
          row.itemGroupId = selectedItemCode;
          row.uom = selectedname[2];
          row.hsnCode = selectedname[4];
          row.qualityCode = "";
          row.qualityCodeName = "";
          row.itemCode = "";
          row.stock = "";
          row.rate = "";
        }
        return row;
      });
      setLineItems(LineItemdata);
      dispatch(GetPoLineItems(LineItemdata));
      displayField(selectedRow, "itemId", 2);
      dispatch(
        getJuteQuantityByJuteType(
          serverVars.PO_API +
            "api/v1/" +
            selectedValue +
            "/" +
            localStorage.getItem("companyId") +
            "/getQualityByItem",
          selectedRow.id
        )
      );
    }
    if (name === "qualityCode") {
      let filteredLineItems = LineItems.filter((item) => item.isActive !== 0);

      if (selectedRow.itemId !== selectedValue) {
        var checkItem = !!filteredLineItems.find(
          ({ qualityCode }) => Number(qualityCode) === Number(selectedValue)
        );
      }
      if (checkItem) {
        swal("You can't create record with duplicate Quality/Grade");
        let LineItemdata = LineItems.filter((row) => {
          if (row.id === selectedRow.id) {
            row.qualityCode = "";
            row.qualityCodeName = "";
            row.itemCode = "";
            row.stock = "";
            row.rate = "";
          }
          return row;
        });
        setLineItems(LineItemdata);
        dispatch(GetPoLineItems(LineItemdata));
        displayField(selectedRow, "qualityCode", 2);
      } else {
        let LineItemdata = LineItems.filter((row) => {
          if (row.id === selectedRow.id) {
            let selectedNameData = selectedName.split("^");
            row.qualityCode = selectedValue;
            row.qualityCodeName = selectedNameData[0];
            row.itemCode = row.itemGroupId + selectedValue;
            row.stock = selectedNameData[1];
            row.rate = selectedNameData[2];
          }
          return row;
        });
        setLineItems(LineItemdata);
        dispatch(GetPoLineItems(LineItemdata));
        displayField(selectedRow, "qualityCode", 2);
        dispatch(
          getLatestMarketRate(
            serverApi.GET_LATEST_MARKET_RATE +
              "/" +
              selectedValue +
              "/" +
              localStorage.getItem("companyId"),
            selectedRow.id
          )
        );
      }
    }
    if (name === "cropYear") {
      let LineItemdata = LineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          row.cropYear = selectedValue;
        }
        return row;
      });
      setLineItems(LineItemdata);
      dispatch(GetPoLineItems(LineItemdata));
      displayField(selectedRow, "cropYear", 2);
    }
  };

  const getTotalAmount = () => {
    let TotalNet = "0.00";
    let TotalWeight = "0.00";

    let LineItemsData = LineItems.filter(
      (item) => item.isActive !== 0 && item.itemId !== ""
    );
    if (LineItemsData.length !== 0) {
      TotalNet = LineItemsData.map((p) =>
        p.amount == null ? 0 : p.amount
      ).reduce((amount, ind) => parseFloat(amount) + parseFloat(ind));
      TotalWeight = LineItemsData.map((p) =>
        p.actualQuantity == null || "" ? 0 : p.actualQuantity
      ).reduce(
        (actualQuantity, ind) => parseFloat(actualQuantity) + parseFloat(ind)
      );
    } else {
      TotalNet = "0.00";
      TotalWeight = "0.00";
    }
    setHeaderData((prev) => ({
      ...prev,
      netTotal: limitDecimals(
        Number(TotalNet) + Number(HeaderData.frieghtCharge),
        2
      ),
      weightTotal: limitDecimals(Number(TotalWeight), 2),
    }));
    const UpdatedRec = {
      ...JutePoHeader,
      netTotal: limitDecimals(
        Number(TotalNet) + Number(HeaderData.frieghtCharge),
        2
      ),
      weightTotal: limitDecimals(Number(TotalWeight), 2),
    };
    dispatch(getJutePOHeader(UpdatedRec));
  };
  // const getTotalWeight =() =>{
  // let TotalWeight ="0.00"
  // let LineItemsData =LineItems.filter((item) => item.isActive !== 0 && item.itemId !=="")
  // if(LineItemsData.length !==0){
  //  TotalWeight = LineItemsData.map((p) =>(p.actualQuantity == null ||""? 0 : p.actualQuantity)).reduce((actualQuantity, ind) => parseFloat(actualQuantity) + parseFloat(ind));
  // }
  // setHeaderData((prev) => ({
  //   ...prev,
  //   weightTotal:limitDecimals( Number(TotalWeight)+Number(HeaderData.frieghtCharge),2)
  // }));
  // const UpdatedRec = {
  //   ...HeaderData,
  //   weightTotal:limitDecimals(Number(TotalWeight)+Number(HeaderData.frieghtCharge),2),
  // };
  // dispatch(getJutePOHeader(UpdatedRec));
  // }

  const handleEventPositioned = (info, id) => {
    var filteredLineitems = LineItems.filter((item) => item.isActive !== 0);

    filteredLineitems.map((item) => {
      if (item.id == id) {
        if (info.target) {
          info.target.setAttribute(
            "data-tip",
            `
        <p> Last MarketRate at ${item.lastPurchaseRate} on ${item.lastPurchaseDate}</p>
        `
          );
          ReactTooltip.rebuild();
        }
      }
    });
  };

  const handleDeleteFile = (fileId,) => {
      dispatch (JutePODeleteFiles(serverApi.DELETEFILE + fileId,HeaderData.hdrId));
  };

  const UploadDocuments = (key) => (e) => {
    e.preventDefault(); 
    const file = e.target.files[0];
    const fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 12);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", HeaderData.hdrId);
    formData.append("createdBy", userDit.userId);
    formData.append("fileCompanyId", localStorage.getItem("companyId"));

      dispatch(
        UploadJutePOFiles(
          serverApi.UPLOADFILE,
          formData,
          HeaderData.hdrId
        )
      );
  };

  let filterProduct = LineItems.filter((item) => item.isActive !== 0);
  let lastLineItem = LineItems[LineItems.length - 1];
  return (
    <div className="editableTableContainer">
      <div className="gridContainer">
        <div className="gridComponent" onScroll={handleScroll}>
          <table cellSpacing="0px" cellPadding="0px">
            <thead>
              <tr className="tableRowBlock">
                {tableHeaders.map((column) => (
                  <>
                    {column.header === "Jute Type/Region" ||
                    column.header === "Quality/Grade" ||
                    column.header === "Quantity" ||
                    column.header === "Price" ||
                    column.header === "Allowable Moisture" ||
                    column.header === "Weight" ||
                    column.header === "Amount" ? (
                      <>
                        <th>
                          {column.header}
                          <label style={{ color: "red" }}>*</label>
                        </th>
                      </>
                    ) : (
                      <>
                        <th>{column.header}</th>
                      </>
                    )}
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {filterProduct.length !== 0 &&
                filterProduct.map((row, i) => {
                  let rowIndex = filterProduct.findIndex((idd) => idd == row);

                  return (
                    <>
                      <tr>
                        <>
                          {RowData &&
                            RowData.map((col) =>
                              col.component === "Editablecell" ? (
                                <>
                                  <td>
                                    <div className="quantityDropdownBlock">
                                      <Grid className="selectAndTextfield">
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <input
                                            onWheel={(e) => e.target.blur()}
                                            name={col.type + "_" + rowIndex}
                                            type={
                                              col.type === "stock" ||
                                              col.type === "rate" ||
                                              col.type ===
                                                "allowableMoisturePercentage" ||
                                              col.type === "amount"
                                                ? "number"
                                                : ""
                                            }
                                            value={
                                              col.type === "stock"
                                                ? row.stock
                                                : col.type === "rate"
                                                ? row.rate
                                                : col.type ===
                                                  "allowableMoisturePercentage"
                                                ? row.allowableMoisturePercentage
                                                : col.type === "marka"
                                                ? row.marka
                                                : col.type === "amount"
                                                ? row.amount
                                                : ""
                                            }
                                            onMouseOver={(e) =>
                                              col.type === "rate" &&
                                              handleEventPositioned(e, row.id)
                                            }
                                            onChange={OnhandleChange({
                                              key: col.type + "_" + rowIndex,
                                              row,
                                            })}
                                            className="inputBlockContainer"
                                            disabled={
                                              col.type === "stock" ||
                                              col.type === "amount"
                                                ? true
                                                : false
                                            }
                                            style={{
                                              backgroundColor:
                                                col.type === "stock" ||
                                                col.type === "amount"
                                                  ? "#ccd6db"
                                                  : "",
                                              textAlign:
                                                col.type === "stock" ||
                                                col.type === "rate" ||
                                                col.type ===
                                                  "allowableMoisturePercentage" ||
                                                col.type === "amount"
                                                  ? "right"
                                                  : "",
                                              paddingRight: "8px",
                                            }}
                                          />
                                          <ReactTooltip
                                            effect={"solid"}
                                            html={true}
                                            place={"BottomCenter"}
                                            className="tooltipClassBorder"
                                            backgroundColor="rgba(0,127,173,0.5)"
                                            textColor="#FFF"
                                            border={true}
                                            borderColor="#04749c"
                                          />
                                                <ReactTooltip
                                              effect={"solid"}
                                              html={true}
                                              place={"BottomCenter"}
                                              className="tooltipClassBorder"
                                              backgroundColor="rgba(0,127,173,0.5)"
                                              textColor="#FFF"
                                              border={true}
                                              borderColor="#04749c"
                                            />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </td>
                                </>
                              ) : col.component === "DynamicSelect" ? (
                                <td>
                                  <div className="quantityDropdownBlock">
                                    <Grid className="selectAndTextfield">
                                      <Grid
                                        xs={12}
                                        md={12}
                                        className="dynamicSelectBlock"
                                        style={{
                                          display: LineItemsDisplayTxtField[
                                            col.type + row.id + "1"
                                          ]
                                            ? "none"
                                            : "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Tooltip
                                          TransitionComponent={Fade}
                                          TransitionProps={{ timeout: 600 }}
                                        >
                                          <input
                                            type="text"
                                            className="inputBlockContainer"
                                            value={
                                              col.type === "itemId"
                                                ? row.itemDesc
                                                : col.type === "qualityCode"
                                                ? row.qualityCodeName
                                                : col.type === "cropYear"
                                                ? row.cropYear
                                                : ""
                                            }
                                            onChange={OnhandleChange({
                                              key: col.type + "_" + rowIndex,
                                              row,
                                            })}
                                            onClick={() => {
                                              displayField(row, col.type, 1);
                                            }}
                                          />
                                        </Tooltip>
                                      </Grid>
                                      <Grid
                                        xs={12}
                                        md={12}
                                        className="dynamicSelectBlock"
                                        style={{
                                          display: LineItemsDisplayDropField[
                                            col.type + row.id + "2"
                                          ]
                                            ? "flex"
                                            : "none",
                                          alignItems: "center",
                                        }}
                                      >
                                        <DynamicSelect
                                          arrayOfData={
                                            col.type === "itemId"
                                              ? mukamList
                                              : col.type === "qualityCode"
                                              ? row.QuantityListData
                                              : col.type === "cropYear"
                                              ? yearOptions
                                              : ""
                                          }
                                          className="dropdownBlockContainer"
                                          onSelectChange={handleSelectChange}
                                          selected={
                                            col.type === "itemId"
                                              ? row.itemId
                                              : col.type === "qualityCode"
                                              ? row.qualityCode
                                              : col.type === "cropYear"
                                              ? row.cropYear
                                              : ""
                                          }
                                          name={
                                            col.type === "itemId"
                                              ? "itemId"
                                              : col.type === "qualityCode"
                                              ? "qualityCode"
                                              : col.type === "cropYear"
                                              ? "cropYear"
                                              : ""
                                          }
                                          row={row}
                                          update={
                                            row.itemId ||
                                            row.qualityCode ||
                                            row.cropYear
                                              ? 1
                                              : 0
                                          }
                                          isDropdownOpen={isDropdownOpen}
                                          handleMenuOpen={handleMenuOpen}
                                        />
                                      </Grid>
                                    </Grid>
                                  </div>
                                </td>
                              ) : (
                                <td>
                                  <div className="verificationContainer">
                                    <input
                                      className="inputBlockContainer"
                                      type="number"
                                      onWheel={(e) => e.target.blur()}
                                      onKeyDown={(e) =>
                                        ["e", "E", "+", "-"].includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      value={
                                        col.type === "quantity"
                                          ? row.quantity
                                          : col.type === "actualQuantity"
                                          ? row.actualQuantity
                                          : ""
                                      }
                                      onChange={OnhandleChange({
                                        key: col.type + "_" + rowIndex,
                                        row,
                                      })}
                                      name={
                                        col.type === "quantity"
                                          ? row.quantity
                                          : col.type === "actualQuantity"
                                          ? row.actualQuantity
                                          : ""
                                      }
                                      style={{
                                        textAlign: "right",
                                        paddingRight: "8px",
                                      }}
                                    />

                                    <button className="verifiedBlock">
                                      {col.type === "quantity"
                                        ? HeaderData.juteUnit == "BALE"
                                          ? "BALE"
                                          : "DRUM"
                                        : col.type === "actualQuantity"
                                        ? "QTL"
                                        : ""}
                                    </button>
                                  </div>
                                </td>
                              )
                            )}
                        </>
                        <td>
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title="Delete"
                          >
                            <IconButton
                              onClick={() => {
                                onDel(row, i);
                              }}
                              disabled={
                                filterProduct.length === 1
                                  ? true
                                  : lastLineItem === row
                                  ? true
                                  : false
                              }
                            >
                              <DeleteIcon className="deleteDisplayButton" />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="consoleFormContainer">
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 12">
                    <div
                      className="calculationBlockTable"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <table>
                        <tr>
                          <td>Freight Charges ( If Any ):</td>
                          <input
                            type="number"
                            className="inputBlockContainer"
                            value={HeaderData.frieghtCharge}
                            onChange={(e) => OnhandleChangeProp(e)}
                            onWheel={e => e.target.blur()}
                          />
                          {/* <b> {limitDecimals(HeaderData.frieghtCharge,2)}</b> */}
                        </tr>
                        <tr>
                          <td>Weight Total:</td>
                          <b> {limitDecimals(HeaderData.weightTotal, 2)}</b>
                        </tr>
                        <tr>
                          <td>Net Total:</td>
                          <b> {limitDecimals(HeaderData.netTotal, 2)}</b>
                        </tr>
                      </table>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
                {/* <---Support Documents-------> */}

          { locationState.state !== undefined && (

          <div className="consoleFormContainer">
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock" style={{ display: "flex" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    className="ducumentUploadBlock"
                    gap={2}
                  >
                    <Box gridColumn="span 8">
                      {supportDocsListData &&
                        supportDocsListData.map((item) => (
                          <>
                            <div>
                              <div
                                className="uploadedInfo"
                                style={{ display: "flex",marginBottom:"3px"}}
                              >
                                <img
                                  src={TickIcon}
                                  alt=""
                                  className="tickIcon"
                                  height={"20px"}
                                  width={"20px"}
                                />
                                <Tooltip
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 600 }}
                                  title="Click to download File"
                                >
                                  <div
                                    onClick={() => {
                                      getFileDownload(
                                        serverConfig.SERVER_URL +
                                          "security-api/api/files/downloadfile/" +
                                          item.fileUploadId,
                                        item.fileName,
                                        item.fileExtension
                                      );
                                    }
                                  }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {item.fileName}
                                  </div>
                                </Tooltip>
                                <Tooltip
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 100 }}
                                  title="Click to Delete File"
                                >
                                  <div
                                    onClick={() => {
                                      handleDeleteFile(
                                        item.fileUploadId,
                                      );
                                    }}
                                    className="deleteButton"
                                  >
                                    <img
                                      src={DeleteIcons}
                                      alt=""
                                      height={"20px"}
                                      width={"20px"}
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                          </>
                        ))}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            { locationState.state !== undefined && (
              <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 8">
                    <div>Support Documents:</div>
                    <div className="personalDetailsUpload">
                      <div className="ducumentUploadBlock">
                        <div className="documentUploadContent">
                          <span className="btn_upload documentUpload">
                            <img src={panUploadIcon} alt="" />
                            <input
                              type="file"
                              id="imag"
                              title=""
                              className="input-img"
                              onChange={UploadDocuments()}
                            />
                            Upload Document
                          </span>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            )
            }
              
            </div>
          </div>
          )}
      </div>
    </div>
  );
};
var year = parseInt(moment().year());

const yearOptions = [
  { value: year + "-" + (year + 1), label: year + "-" + (year + 1), name: year },
  { value: year - 1 + "-" + year, label: year - 1 + "-" + year, name: year - 1 },
  { value: year - 2 + "-" + (year - 1), label: year - 2 + "-" + (year - 1), name: year - 2 },
];

const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "itemId",
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "qualityCode",
  },
  {
    no: 3,
    component: "Editablecell",
    type: "stock",
  },
  {
    no: 4,
    component: "InputAdornment",
    type: "quantity",
  },
  {
    no: 5,
    component: "Editablecell",
    type: "rate",
  },
  {
    no: 6,
    component: "Editablecell",
    type: "allowableMoisturePercentage",
  },
  {
    no: 7,
    component: "InputAdornment",
    type: "actualQuantity",
  },
  {
    no: 8,
    component: "Editablecell",
    type: "amount",
  },
  {
    no: 9,
    component: "Editablecell",
    type: "marka",
  },
  {
    no: 10,
    component: "DynamicSelect",
    type: "cropYear",
  },
];
const tableHeaders = [
  {
    header: "Jute Type/Region",
  },
  {
    header: "Quality/Grade",
  },
  {
    header: "Stock",
  },
  {
    header: "Quantity",
  },
  {
    header: "Price",
  },
  {
    header: " Allowable Moisture",
  },
  {
    header: "Weight",
  },
  {
    header: "Amount",
  },
  {
    header: "Marka",
  },
  {
    header: "Crop Year",
  },
  {
    header: "Actions",
  },
];
export default JutePurchaseOrderLineItems;
