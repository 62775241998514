import React from 'react'

import ReactDOM from 'react-dom'

const Modal = (props) => {
  return (
    <div className="backdrop">
      <div className="mr_modal">
        <div className="content">{props.children}</div>
      </div>
    </div>
  )
}

const portalElement = document.getElementById('overlays')

const MRPopup = (props) => {
  return (
    <div>
      {ReactDOM.createPortal(<Modal>{props.children}</Modal>, portalElement)}
    </div>
  )
}

export default MRPopup
