import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import { travelCatageriesList } from "../../../store/Master/TravelCatageries/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      travelCatageriesListData: [], // to display list data
      travelCategory: "",
      dataexp: "",
    };
  }

  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
      travelCategory: "",
    };
    this.props.travelCatageriesList(
      serverApi.GET_ALL_TRAVEL_CATAGERIES_LIST,
      data,
      this.props.history
    ); // calling travel catagerirs list API
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.TravelCatageriesList) {
      this.setState({
        totalRecs: props.TravelCatageriesList.totalRecords,
        travelCatageriesListData: props.TravelCatageriesList.data, // updating travel categories list
      });
    }
  }

  handleSearch = (data) => {
    var Searchdata = {
      cipher: data.cipher,
      companyId: data.companyId,
      startIndex: data.startIndex,
      lastIndex: data.lastIndex,
      travelCategory: data.travelCategory,
    };

    this.setState({
      travelCategory: data.travelCategory,
    });
    this.props.travelCatageriesList(
      serverApi.GET_ALL_TRAVEL_CATAGERIES_LIST,
      Searchdata,
      this.props.history
    ); // calling pagination api for travel category master
  };

  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const data = {
        companyId: localStorage.getItem("companyId"),
        cipher: this.state.userDit.cipher,
        startIndex: sizePerPage,
        lastIndex: currentIndex,
        travelCategory: this.state.travelCategory,
      };
      if (currentIndex >= 0) {
        this.props.travelCatageriesList(
          serverApi.GET_ALL_TRAVEL_CATAGERIES_LIST,
          data,
          this.props.history
        ); // calling pagination api for travel category master
      }
    } else {
      const data = {
        companyId: localStorage.getItem("companyId"),
        cipher: this.state.userDit.cipher,
        startIndex: currentIndex === 0 ? currentIndex + 1 : currentIndex,
        lastIndex: sizePerPage,
        travelCategory: this.state.travelCategory,
      };
      if (currentIndex >= 0) {
        this.props.travelCatageriesList(
          serverApi.GET_ALL_TRAVEL_CATAGERIES_LIST,
          data,
          this.props.history
        ); // calling Pagination api for travel category master
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  render() {
    return (
      <>
        <PaginationGridView
          mdata={this.state.travelCatageriesListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          filter_form={"TravelCategories"}
          actions={true}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          handleSearch={this.handleSearch}
          url="/create_Travel_Catageries"
          apiexp={serverApi.GET_ALL_TRAVEL_CATAGERIES_LIST}
          payloadexp={this.state.dataexp}
          expName={"Travel-Catageries-Master.csv"}
        />
      </>
    );
  }
}
// Travel Catageries list Headers
const Columns = [
  {
    Header: "Travel Category Name",
    accessor: "travelCategory",
    hidden: false,
  },
];

const mapStatetoProps = (state) => {
  const { TravelCatageriesList } = state.TravelCatageriesReducer; // fetching list from travel Catageries reducer
  return { TravelCatageriesList };
};

export default withRouter(
  connect(mapStatetoProps, {
    travelCatageriesList,
  })(Index)
);
