import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom"; 
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";

class IndentFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      mukamName: null,
      mukamId: null,
    //   startIndex: this.state.startIndex,
    //  lastIndex: this.state.lastIndex,
    };
  }

  componentDidMount() {
    
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleChange = (key)=> (event)=>{
    if(key === "mukamName"){
      this.setState({
        mukamName:event
      })
    }
    if(key === "mukamId"){
      this.setState({
        mukamId:event
      })
    }
  }

  handleSubmit = () => {
    var data = {
      mukamName:this.state.mukamName !==''?this.state.mukamName:"",
      mukamId:this.state.mukamId !==""?this.state.mukamId:'',
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleClear = () => {
    this.setState({
      mukamName: '',
      mukamId: '',
    });
  };

  render() {

    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid
            spacing={0}
          >
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
              </div>
            </div>
            <Grid item xs={12} className="filterBlockContainer">
               <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      value={this.state.mukamName}
                      placeholder={"Enter here"}
                      label="Mukam Name"
                      onChange={this.handleChange("mukamName")}
                    />
                  </div>
                </Box>

              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Search"}
                  className="greenButton"
                  handleClick={this.handleSubmit}
                  type="sumbit"
                />
              </div>
            </Grid>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {};

export default withRouter(
  connect(mapStatetoProps, {
  })(IndentFilter)
);
