import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TabNavigation from "../../../../components/TabsNavigation/TabsNavigation";
import TotalEmployees from "./TotalEmployees";
import TotalEmployeesGrid from "./GridView/TotalEmployeesGrid";
import ActiveApplication from "./ActiveApplications";
import ActiveApplicationsGrid from "./GridView/ActiveApplicationsGrid";
import RejectedApplications from "./RejectedApplications";
import RejectedApplicationsGrid from "./GridView/RejectedApplicationsGrid";
import { ToggleButtonGroup } from "@mui/material";
import { ToggleButton } from "@mui/material";
import ListView from "../../../../assets/images/listView.png";
import CardView from "../../../../assets/images/cardView.png";
import {
  getEmployeeList,
  clearAdharDataProp,
  getListRowData,
  clearPayScheme,
  ClearEmployeeProgressBar,
} from "../../../../store/HRMS/EmployeeDataBase/actions";
class ListOfEmployees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alignment: "right",
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      employeeListColumn: [],
      total: "",
      Active: "",
      inActive: "",
      employeeListData: [],
    };
  }

  handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    this.setState({
      alignment: newAlignment,
    });
  };

  handleData = (id, rec) => {
    if (id === "1") {
      this.setState({
        total: rec,
      });
    }
    if (id === "2") {
      this.setState({
        Active: rec,
      });
    }
    if (id === "3") {
      this.setState({
        inActive: rec,
      });
    }
  };
  componentDidMount() {
    this.props.clearAdharDataProp(null);
    this.props.getListRowData(null);
    this.props.clearPayScheme(null);
    this.props.ClearEmployeeProgressBar(null);
  }
  render() {
    return (
      <>
        <div className="toggle-alignment">
          <ToggleButtonGroup
            value={this.state.alignment}
            exclusive
            onChange={this.handleAlignment}
            aria-label="text alignment"
            className="iconToggleComponent"
          >
            <ToggleButton value="left" aria-label="centered">
              <img src={CardView} alt="" width="20" height="20" />
            </ToggleButton>
            <ToggleButton value="right" aria-label="left aligned">
              <img src={ListView} alt="" width="25" height="20" />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <TabNavigation
          tabOneLabel={"Active Employees"}
          tabTwoLabel={"Inactive Employees"}
          tabThreeLabel={"Total Employees"}
          tabOneContent={
            this.state.alignment == "right" ? (
              <ActiveApplication
                handleData={this.handleData}
                mcolumns={employeeListColumn}
              />
            ) : (
              <ActiveApplicationsGrid handleData={this.handleData} />
            )
          }
          tabTwoContent={
            this.state.alignment == "right" ? (
              <RejectedApplications
                handleData={this.handleData}
                mcolumns={employeeListColumn}
              />
            ) : (
              <RejectedApplicationsGrid handleData={this.handleData} />
            )
          }
          tabThreeContent={
            this.state.alignment == "right" ? (
              <TotalEmployees
                handleData={this.handleData}
                mcolumns={employeeListColumn}
              />
            ) : (
              <TotalEmployeesGrid handleData={this.handleData} />
            )
          }
        />
      </>
    );
  }
}
const employeeListColumn = [
  {
    Header: "Is Active",
    accessor: "is_active",
    hidden: true,
  },
  {
    Header: "EB Number",
    accessor: "emp_code",
    hidden: false,
  },
  {
    Header: "First Name",
    accessor: "first_name",
    hidden: false,
  },
  {
    Header: "Middle Name",
    accessor: "middle_name",
    hidden: false,
  },
  {
    Header: "Last Name",
    accessor: "last_name",
    hidden: false,
  },
  {
    Header: "Date of Birth",
    accessor: "date_of_birth",
    hidden: false,
  },
  {
    Header: "Department",
    accessor: "dept_desc",
    hidden: false,
  },
  {
    Header: "Designation",
    accessor: "desig",
    hidden: false,
  },
  {
    Header: "Date of Join",
    accessor: "date_of_join",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "status_name",
    hidden: false,
  },

  {
    Header: "Updated Time",
    accessor: "updated_time",
    hidden: true,
  },
  {
    Header: "Office Email Id",
    accessor: "office_email_id",
    hidden: true,
  },
  {
    Header: "Resign Remarks",
    accessor: "resign_remarks",
    hidden: true,
  },
  {
    Header: "Date PF Join",
    accessor: "pf_date_of_join",
    hidden: true,
  },
  {
    Header: "Pan Number",
    accessor: "pan_no",
    hidden: true,
  },
  {
    Header: "Probation Period",
    accessor: "probation_period",
    hidden: true,
  },
  {
    Header: "Nominee Name",
    accessor: "nominee_name",
    hidden: true,
  },
  {
    Header: "Catagory Description",
    accessor: "cata_desc",
    hidden: true,
  },
  {
    Header: "Net Settlement Amount",
    accessor: "net_settlement_amount",
    hidden: true,
  },

  {
    Header: "Emergency Number",
    accessor: "emergency_no",
    hidden: true,
  },
  {
    Header: "Notice Days",
    accessor: "notice_days",
    hidden: true,
  },

  {
    Header: "Legacy Code",
    accessor: "legacy_code",
    hidden: true,
  },
  {
    Header: "created_time",
    accessor: "created_time",
    hidden: true,
  },
  {
    Header: "PF Transfer Number",
    accessor: "pf_transfer_no",
    hidden: true,
  },
  {
    Header: "Catagory ID",
    accessor: "catagory_id",
    hidden: true,
  },
  {
    Header: "Resigned Date",
    accessor: "resigned_date",
    hidden: true,
  },
  {
    Header: "Created By",
    accessor: "created_by",
    hidden: true,
  },
  {
    Header: "FNF Date",
    accessor: "fnf_date",
    hidden: true,
  },
  {
    Header: "Father Spouse Name",
    accessor: "father_spouse_name",
    hidden: true,
  },

  {
    Header: "Release Date",
    accessor: "release_date",
    hidden: true,
  },
  {
    Header: "Updated By",
    accessor: "updated_by",
    hidden: true,
  },
  {
    Header: "Blood Group",
    accessor: "blood_group",
    hidden: true,
  },

  {
    Header: "Status ID",
    accessor: "status",
    hidden: true,
  },

  {
    Header: "Email Id",
    accessor: "email_id",
    hidden: true,
  },
  {
    Header: "Relationship Name",
    accessor: "relationship_name",
    hidden: true,
  },
  {
    Header: "Gender",
    accessor: "gender",
    hidden: true,
  },

  {
    Header: "Date Of Inactive",
    accessor: "date_of_inactive",
    hidden: true,
  },
  {
    Header: "Mobile Number",
    accessor: "mobile_no",
    hidden: true,
  },
  {
    Header: "Contractor Name",
    accessor: "contractor_name",
    hidden: true,
  },
  {
    Header: "Reporting EB_Id",
    accessor: "reporting_eb_id",
    hidden: true,
  },
  {
    Header: "Type Of Resign",
    accessor: "type_of_resign",
    hidden: true,
  },

  {
    Header: "Branch Id",
    accessor: "branch_id",
    hidden: true,
  },
  {
    Header: "Branch Name",
    accessor: "branch_name",
    hidden: true,
  },
  {
    Header: "Aadhar Number",
    accessor: "aadhar_no",
    hidden: true,
  },

  {
    Header: "ESI Number",
    accessor: "esi_no",
    hidden: true,
  },

  {
    Header: "Resign Reasons",
    accessor: "resign_reasons",
    hidden: true,
  },
  {
    Header: "Relegion Name",
    accessor: "relegion_name",
    hidden: true,
  },

  {
    Header: "Driving Licence Number",
    accessor: "driving_licence_no",
    hidden: true,
  },

  {
    Header: "Company Id",
    accessor: "company_id",
    hidden: true,
  },

  {
    Header: "Department Id",
    accessor: "department_id",
    hidden: true,
  },
  {
    Header: "Minimum Working Commitment",
    accessor: "minimum_working_commitment",
    hidden: true,
  },
  {
    Header: "Designation Id",
    accessor: "designation_id",
    hidden: true,
  },
  {
    Header: "Passport Number",
    accessor: "passport_no",
    hidden: true,
  },
  {
    Header: "Catagory Code",
    accessor: "cata_code",
    hidden: true,
  },
  {
    Header: "PF UAN Number",
    accessor: "pf_uan_no",
    hidden: true,
  },
  {
    Header: "PF Number",
    accessor: "pf_no",
    hidden: true,
  },
  {
    Header: "Marital Status",
    accessor: "marital_status",
    hidden: true,
  },
  {
    Header: "Contractor Id",
    accessor: "contractor_id",
    hidden: true,
  },
  {
    Header: "PF Previous Number",
    accessor: "pf_previous_no",
    hidden: true,
  },
];
const mapStatetoProps = (state) => {
  const { employeeList } = state.EmployeeReducer;
  return { employeeList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getEmployeeList,
    clearAdharDataProp,
    getListRowData,
    clearPayScheme,
    ClearEmployeeProgressBar,
  })(ListOfEmployees)
);
