import {
  BRANCH_LIST,
  CLIENT_LIST,
  DEPARTMENT_LIST,
  ITEMGROUP_LIST,
  PROJECT_LIST,
  CATEGORY_TYPE_LIST,
  SUPPLIER_LIST,
  STATUS_LIST,
  PARENT_LEDGER_LIST,
  STATE_NAME_LIST,
  COUNTRY_NAME_LIST,
  CUSTOMER_LIST,
  BILLING_SHIPPING_LIST,
  ITEMGROUP_MASTER_LIST,
  ITEMS_BY_ITEMGROUP_BY_ID,
  TAX_LIST,
  UOM_LIST,
  BUDGET_HEADS_LIST,
  ITEMSLIST_FORINDENT,
  APPROVED_INDENTS,
  ALLITEMGROUP_MASTERLIST,
  SUPPLIERLISTBY_SUPP_ID,
  BILLING_ADDRESS_SHIPPING_ADDRESS,
  BUDGETHEADS,
  ITEMGRP_MASTER_LIST,
  BOQ_List,
  PARENT_BUDGET_HEADS_LIST,
  MUKAM_LIST,
  EMPLOYEE_CATEGORY_LIST,
  SUPPLIER_LIST_MULTISELECT,
  SUPPLIER_LIST_MULTISELECT_J,
  GST_VERIFICATION,
  TYPES_OF_MACHINE,YARN_TYPE_LIST, SUB_BUDGET_HEADS_LIST, ITEMGROUPBYBUDGET_HEADS_LIST, QUALITY_BY_ITEM, LAST_MARKET_RATE, PROJECT_MASTER_LIST, TERMS_CONDITIONS, DASHBOARD_GOOGLE_LINK_API, CONFIGURATION_API, ITEMGRPBYBDGT_HEAD_ID, EXPORT_DATA_LIST
} from "./actionTypes";
import { all, call, fork, takeEvery, put ,select} from "redux-saga/effects";
import {
  BranchListSuccessfull,
  DepartmentListSuccessfull,
  ClientListSuccessfull,
  ItemGroupListSuccessfull,
  ProjectListSuccessfull,
  CateogryTypeListSuccessfull,
  SupplierListSuccessfull,
  StatusListSuccessfull,
  ParentLedgerListSuccessfull,
  LedgerGroupsListSuccessfull,
  StateNameListSuccessfull,
  CustomerListSuccessfull,
  BillingShippingListSuccessfull,
  ItemGroupMasterListSuccessfull,
  ItemsListSuccessfull,
  TaxListSuccessfull,
  BudgetHeadsListSuccessfull,
  ItemsListForIndentSuccessfull,
  ItemGroupByBudegtHeadsListSuccessfull,
  UomListSuccessfull,
  approvedIndentsSuccessfull,
  allItemGroupMasterListSuccessfull,
  SupplierListBySuppIdSuccessfull,
  BillingAdsShippingAdsSuccessfull,
  BudgetHeadsSuccessfull,
  ItemGrpMasterListSuccessfull,
  BOQListSuccessfull,
  parentBudgetHeadsListSuccessfull,
  MukamListSuccessfull,
  EmployeeCategoryListSuccessfull,
  SupplierListMultiselectSuccessfull,
  TypesOfMachineSuccessfull,
  YarnTypeListSuccessfull,
  SubBudgetHeadsListSuccessfull,
  getJutePoQualitybyItemSuccessfull,
  getLatestMarketJuteRateSuccessfull,
  getProjectMasterListSuccessfull,
  getSupplierListJSuccessfull,
  getTermsConditionsSuccessfull,
  CountryListSuccessfull,
  getGoogleLinkDataSuccessfull,
  getConfigurationSuccessfull,
  ItemGpListByBdgtHeadIdSuccessfull,
  exportDatalistuccessfull,
  gstVerificationSuccessful
} from "./actions";
import {SalesOrderJuteGovtLineItems} from "../../Sales/SalesOrder/actions"
import { getListurl, getList, postAdd, getCallBackData } from "../../../helpers/Server_Helper";
import { hideSpinner, serverApi, showSpinner  } from "../../../helpers/Consts";
import swal from "sweetalert";

function* getBranch({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if(response){
    yield put(BranchListSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getDepartmentList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(DepartmentListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getClientList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(ClientListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getItemGroup({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if(response){
    yield put(ItemGroupListSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getProject_List({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    if(response){
      yield put(ProjectListSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getItemsbyItemGrpID_List({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(ItemsListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getStatus_List({ payload: { url, data, history } }) {
  try {
    const response = yield call(getListurl, url, data);
    yield put(StatusListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getCustomer_List({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(CustomerListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}


function* getBillingShippingList({ payload: { url, data, history } }) {
  try {
    // showSpinner()
    const response = yield call(getListurl, url, data);
    yield put(BillingShippingListSuccessfull({ response }));
  // if(response){
  //   hideSpinner()
  // }
  } catch (error) {
    hideSpinner()
    console.log(error);
  }
}

function* getCategoryTypeList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(CateogryTypeListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getSupplier({payload:{url,history}}) {
  try{
      const response = yield call(getListurl,url);
      yield put(SupplierListSuccessfull({response}))

  }catch(error){
      console.log(error)
  }
}

function* getParentLedgerList({payload:{url,history}}) {
  try{
      const response = yield call(getListurl,url);
      yield put(ParentLedgerListSuccessfull({response}))

  }catch(error){
      console.log(error)
  }
}
function* getLedgerGroupsList({payload:{url,history}}) {
  try{
      const response = yield call(getListurl,url);
      yield put(LedgerGroupsListSuccessfull({response}))

  }catch(error){
      console.log(error)
  }
}
function* getStateNameListSaga({payload:{url,history}}) {
  try{
      const response = yield call(getListurl,url);
      yield put(StateNameListSuccessfull({response}))

  }catch(error){
      console.log(error)
  }
}
function* getCountryNameList({payload:{url,history}}) {
  try{
      const response = yield call(getListurl,url);
      yield put(CountryListSuccessfull({response}))

  }catch(error){
      console.log(error)
  }
}

function* getItemGroupMasterList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(ItemGroupMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getTax_List({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(TaxListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getUomList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(UomListSuccessfull({ response }));
  }
  catch (error) {
    console.log(error);
  }
}
function* getBudgetHeadsList({ payload: { url,data, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(BudgetHeadsListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getItemsListForIndent({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(ItemsListForIndentSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* approvedIndents({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(approvedIndentsSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* allItemGroupMasterList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    const { SoJuteGovtLineItems } = yield select((state) => state.SalesOrderReducer);
    if (response) {
      if (response.data) {
        let itemGroupId = null
        response.data.map((itemGrp) => {
        if(itemGrp.value === "997"){
          itemGroupId = itemGrp.id
        }
        });
        let LineItemsRec = SoJuteGovtLineItems.filter((rowItem) => {
            if ("997" === rowItem.itemGroupValue) {
              rowItem.itemGroupId = itemGroupId;
            }
            return rowItem;
          });
          yield put(SalesOrderJuteGovtLineItems(LineItemsRec));
      }
    }
    yield put(allItemGroupMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getsupplierListBy_suppId({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(SupplierListBySuppIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* BillingAdsShippingAds({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(BillingAdsShippingAdsSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* budegtHeads({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(BudgetHeadsSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* subBudegtHeads({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(SubBudgetHeadsListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* ItemGrp_MasterList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(ItemGrpMasterListSuccessfull({ response }));
  } catch (error) {
    if (error.response) {
      hideSpinner()
      if (error.response.data.status) {
        let response = []
        yield put(ItemGrpMasterListSuccessfull({ response }));
        swal(error?.response?.data?.error, { icon: "error" });
      }

    }
  }
}
function* ItemGrp_ByBudegtHeadsList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url,);
    yield put(ItemGroupByBudegtHeadsListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* ItemGrpByBdgtHeadId({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url,);
    yield put(ItemGpListByBdgtHeadIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* boqList({ payload: { url, history } }) {

  try {
    const response = yield call(getListurl, url);
    hideSpinner()
    yield put(BOQListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
    hideSpinner()
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        yield put(BOQListSuccessfull({}));
      }
    }
  }
}

function* parentBudgetHeadsList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(parentBudgetHeadsListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* mukamList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(MukamListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* employeeCategoryList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(EmployeeCategoryListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* supplierListMultiselect({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(SupplierListMultiselectSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* supplierListMultiselectJ({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getSupplierListJSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* typesOfMachine({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(TypesOfMachineSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* yarnTypeList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(YarnTypeListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getProjectMasterData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getProjectMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getTermsConditionsData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getTermsConditionsSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* qualitybyItem({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if(response){
      if(response.status==200){
        yield put(getJutePoQualitybyItemSuccessfull({ response }));
      }else{
        const Response = null;
        yield put(getJutePoQualitybyItemSuccessfull({ Response }));
      }      
    }else{
      const Response = null;
      yield put(getJutePoQualitybyItemSuccessfull({ Response }));
    }
    
  } catch (error) {
    console.log(error);
    const response = null;
    yield put(getJutePoQualitybyItemSuccessfull({ response }));
  }
}
function* getLatestMarketRateJute({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if(response){
      if(response.status==200){
        yield put(getLatestMarketJuteRateSuccessfull({ response }));
      }else{
        const Response = null;
        yield put(getLatestMarketJuteRateSuccessfull({ Response }));
      }      
    }else{
      const Response = null;
      yield put(getLatestMarketJuteRateSuccessfull({ Response }));
    }
    
  } catch (error) {
    console.log(error);
    const response = null;
    yield put(getLatestMarketJuteRateSuccessfull({ response }));
  }
}

function* getGoogleLinkDashBoardData({ payload: { url,data ,history } }) {
  try {
    const response = yield call(postAdd,url,data);
    yield put(getGoogleLinkDataSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* ConfigurationData({payload:{url,history}}){
  try{
   const response =yield call(getListurl,url)
   yield put(getConfigurationSuccessfull({response}));
   if(response){
    if(response.data){
      localStorage.setItem("Configuration",JSON.stringify(response.data));
    }  
   }
  }catch(error){
    console.log(error)
  }
}

function* exportListData({ payload: { url, data, callback } }) {
  try {
    // const response = yield call(getList, url, data);
    const response = yield call(getCallBackData, url, data, callback);
    
    if(response){
      yield put(exportDatalistuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getGstVerification({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if(response){
      if(response.data.status) {
        yield put(gstVerificationSuccessful({ response }));
        swal("GST Verified Successfully!", { icon: "success" });
      } else {
        yield put(gstVerificationSuccessful({ response }));
        swal("GST Verification Failed", { icon: "error" });
      }
    }
  } catch (error) {
    swal("GST Verification Failed", { icon: "error" });
  }
}

export function* watchBranchList() {
  yield takeEvery(BRANCH_LIST, getBranch);
}

export function* watchDepartmentList() {
  yield takeEvery(DEPARTMENT_LIST, getDepartmentList);
}

export function* watchClientList() {
  yield takeEvery(CLIENT_LIST, getClientList);
}

export function* watchSupplierListBy_suppId() {
  yield takeEvery(SUPPLIERLISTBY_SUPP_ID, getsupplierListBy_suppId);
}
export function* watchBudgetHeads() {
  yield takeEvery(BUDGETHEADS, budegtHeads);
}
export function* watchSubBudgetHeads() {
  yield takeEvery(SUB_BUDGET_HEADS_LIST, subBudegtHeads);
}
export function* watchItemGroupList() {
  yield takeEvery(ITEMGROUP_LIST, getItemGroup);
  yield takeEvery(ITEMGROUPBYBUDGET_HEADS_LIST,ItemGrp_ByBudegtHeadsList)
}
export function* watchProjectList() {
  yield takeEvery(PROJECT_LIST, getProject_List);
}
export function* watchItemsByItmGrpIdList() {
  yield takeEvery(ITEMS_BY_ITEMGROUP_BY_ID, getItemsbyItemGrpID_List);
}
export function* watchItemGrpMasterList() {
  yield takeEvery(ITEMGRP_MASTER_LIST, ItemGrp_MasterList);
}
export function* watchBoqList() {
  yield takeEvery(BOQ_List, boqList);
}
export function* watchStatusList() {
  yield takeEvery(STATUS_LIST, getStatus_List);
}
export function* watchgetCategoryTypeList() {
  yield takeEvery(CATEGORY_TYPE_LIST, getCategoryTypeList);
}
export function* watchSupplierList () {
  yield takeEvery(SUPPLIER_LIST, getSupplier)
  
}
export function* watchParentLedgerList () {
  yield takeEvery(PARENT_LEDGER_LIST, getParentLedgerList) 
}
export function* watchLedgerGroupsList () {
  // yield takeEvery(LEDGER_GROUPS_LIST, getLedgerGroupsList) 
}
export function* watchStateNameList () {
  yield takeEvery(STATE_NAME_LIST, getStateNameListSaga) 
}
export function* watchCountryNameList () {
  yield takeEvery(COUNTRY_NAME_LIST, getCountryNameList) 
}
export function* watchCustomerList () {
  yield takeEvery(CUSTOMER_LIST, getCustomer_List) 
}
export function* watchItemGroupMasterList () {
  yield takeEvery(ITEMGROUP_MASTER_LIST, getItemGroupMasterList)
  yield takeEvery(ITEMGRPBYBDGT_HEAD_ID ,ItemGrpByBdgtHeadId)
}
export function* watchBillingShippingList () {
  yield takeEvery(BILLING_SHIPPING_LIST, getBillingShippingList)
}
export function* watchtax_List () {
  yield takeEvery(TAX_LIST, getTax_List)
}
export function* watchUomList () {
  yield takeEvery(UOM_LIST, getUomList)
}
// export function* watchAccountTypeList () {
//   yield takeEvery(ACCOUNT_TYPE_LIST, getAccountTypeList)
// }
export function* watchBudgetHeadsList () {
  yield takeEvery(BUDGET_HEADS_LIST, getBudgetHeadsList)
}
export function* watchItemsListForIndent () {
  yield takeEvery(ITEMSLIST_FORINDENT, getItemsListForIndent)
}

export function* watchApprovedIndents () {
  yield takeEvery(APPROVED_INDENTS, approvedIndents)
}
export function* watchBillingaAdsShippingAds () {
  yield takeEvery(BILLING_ADDRESS_SHIPPING_ADDRESS, BillingAdsShippingAds)
}

export function* watchAllItemGroupMasterList () {
  yield takeEvery(ALLITEMGROUP_MASTERLIST, allItemGroupMasterList)
}
export function* watchparentBudgetHeadsList () {
  yield takeEvery(PARENT_BUDGET_HEADS_LIST, parentBudgetHeadsList)
}
export function* watchMukamList () {
  yield takeEvery(MUKAM_LIST, mukamList)
}
export function* watchEmployeeCategoryList () {
  yield takeEvery(EMPLOYEE_CATEGORY_LIST, employeeCategoryList)
}
export function* watchSupplierListMultiselect () {
  yield takeEvery(SUPPLIER_LIST_MULTISELECT, supplierListMultiselect)
}
export function* watchSupplierListMultiselectJ () {
  yield takeEvery(SUPPLIER_LIST_MULTISELECT_J, supplierListMultiselectJ)
}
export function* watchTypesOfMachine () {
  yield takeEvery(TYPES_OF_MACHINE, typesOfMachine)
}
export function* watchYarnTypeList () {
  yield takeEvery(YARN_TYPE_LIST, yarnTypeList)
}
export function* watchQualityByItem(){
  yield takeEvery(QUALITY_BY_ITEM, qualitybyItem)
}

export function* watchLastMarketRate(){
  yield takeEvery(LAST_MARKET_RATE, getLatestMarketRateJute)
}
export function* watchProjectMasterList(){
  yield takeEvery(PROJECT_MASTER_LIST,getProjectMasterData)
}
export function* getTermsConditionList(){
  yield takeEvery(TERMS_CONDITIONS,getTermsConditionsData)
}
export function* getGoogleLinkDashBoardDataFun(){
  yield takeEvery(DASHBOARD_GOOGLE_LINK_API, getGoogleLinkDashBoardData)
}
export function* watchConfigurartion(){
  yield takeEvery(CONFIGURATION_API,ConfigurationData)
}
export function* watchExportListData () {
  yield takeEvery(EXPORT_DATA_LIST, exportListData)
}

export function* watchGstVerification() {
  yield takeEvery(GST_VERIFICATION, getGstVerification);
}

function* DropDownListSaga() {
  yield all([
    fork(watchBranchList),
    fork(watchGstVerification),
    fork(watchDepartmentList),
    fork(watchClientList),
    fork(watchItemGroupList),
    fork(watchProjectList),
    fork(watchStatusList),
    fork(watchgetCategoryTypeList),
    fork(watchSupplierList),
    fork(watchParentLedgerList),
    fork(watchLedgerGroupsList),
    fork(watchStateNameList),
    fork(watchCustomerList),
    fork(watchBillingShippingList),
    fork(watchItemGroupMasterList),
    fork(watchItemsByItmGrpIdList),
    // fork(watchAccountTypeList),
    fork(watchtax_List),
    fork(watchUomList),
    fork(watchItemsListForIndent),
    fork(watchApprovedIndents),
    fork(watchAllItemGroupMasterList),
    fork(watchparentBudgetHeadsList), 
    fork(watchMukamList),
    fork(watchSupplierListBy_suppId),
    fork(watchBillingaAdsShippingAds),
    fork(watchBudgetHeads),
    fork(watchItemGrpMasterList),
    fork(watchBoqList),
    fork(watchparentBudgetHeadsList),
    fork(watchEmployeeCategoryList),
    fork(watchSupplierListMultiselect),
    fork(watchTypesOfMachine),
    fork(watchYarnTypeList),
    fork(watchSubBudgetHeads),
    fork(watchQualityByItem),
    fork(watchLastMarketRate),
    fork(watchProjectMasterList),
    fork(watchSupplierListMultiselectJ),
    fork(getTermsConditionList),
    fork(watchCountryNameList),
    fork(getGoogleLinkDashBoardDataFun),
    fork(watchConfigurartion),
    fork(watchExportListData)
  ]);
}

export default DropDownListSaga;
