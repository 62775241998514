import React, { Component, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Grid, Box } from "@mui/material";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import FormPopup from "../../../components/FormlPopup/FormPopup";

import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import { serverApi } from "../../../helpers/Consts";
import { SalesGstCalculations } from "../../../Utilities/SalesGstCalculation";
import { getQuotationLineItems } from "../../../store/Sales/Qutation/actions";
import {
  getBranchList,
  getCustomerList,
  getBillingShippingList,
} from "../../../store/Global/DropDownApis/actions";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import {
  getQuotationBroker,
  getQuotationHeaders,
} from "../../../store/Sales/Qutation/actions";
import swal from "sweetalert";
import moment from "moment";
import { isEmpty } from "../../../Utilities/helper";

const QuotationFillDetails = (props) => {
  let locationState = useLocation();
  let dispatch = useDispatch();
  let history = useHistory();
  const { quoteLineItems, quoteHeader, BrokerData } = useSelector(
    (state) => state.QuotationReducer
  );
  const { branchList, customerList, billingShippingList } = useSelector(
    (state) => state.DropDownListReducer
  );
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [LineItems, setLineItems] = useState([]);
  const [companyId] = useState(localStorage.getItem("companyId"));
  const [quoteHdr, setquoteHdr] = useState({});
  const [branchListData, setbranchListData] = useState([]);
  const [brokerData, setbrokerData] = useState([]);
  const [CustomerData, setCustomerData] = useState([]);
  const [billingAddressData, setbillingAddressData] = useState([]);
  const [shippingAddressData, setshippingAddressData] = useState([]);
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [backDaysAllowable, setBackDaysAllowable] = useState("");
  const [futureDaysAllowable, setFutureDaysAllowable] = useState("");

  useEffect(() => {
    setLineItems(quoteLineItems);
    setquoteHdr(quoteHeader);
    dispatch(getBranchList(serverApi.BRANCH_LIST + companyId, history));

    const data = {
      companyId: companyId,
      cipher: userDit.cipher,
    };

    dispatch(getCustomerList(serverApi.GET_ALL_CUSTOMERS, data, history));
    dispatch(
      getQuotationBroker(
        serverApi.GET_ALL_BROKER_LIST +
          localStorage.getItem("companyId") +
          "/SALE",
        history
      )
    );
    const currentMonth = moment().format('MM');
    const currentDate = moment().format('DD');
    const fiscalYearStart = moment(`${localStorage.getItem("acadamicYear")}-${currentMonth}-${currentDate}`);
    const today = moment();
    let dateValue;
    if (today.isBefore(fiscalYearStart)) {
      dateValue = today.format("YYYY-MM-DD");
    } else {
      dateValue = fiscalYearStart.format("YYYY-MM-DD");
    }

    if (userDit) {
      userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              if (item.backDaysAllowable !== undefined) {
                let backDaysAllowable = JSON.parse(item.backDaysAllowable);
                let backDays = backDaysAllowable.bdaystype;
                if (Number(backDays) === 1) {
                  backDays = 7;
                } else if (Number(backDays) === 2) {
                  backDays = 14;
                } else if (Number(backDays) === 3) {
                  backDays = "";
                } else if (Number(backDays) === 4) {
                  backDays = backDaysAllowable.otherBackDateValue;
                } else {
                  backDays = backDaysAllowable.bdaystype;
                }
                let minDate = moment(dateValue).subtract(
                  Number(backDays),

                  "days"
                );
                let setMinDate = moment(minDate).format("YYYY-MM-DD");
                setBackDaysAllowable(setMinDate);
              }
              if (item.futureDaysAllowable !== undefined) {
                let futureDaysAllowable = JSON.parse(item.futureDaysAllowable);
                let futureDays = futureDaysAllowable.fdaystype;
                if (Number(futureDays) === 1) {
                  futureDays = 7;
                } else if (Number(futureDays) === 2) {
                  futureDays = 14;
                } else if (Number(futureDays) === 3) {
                  futureDays = "";
                } else if (Number(futureDays) === 4) {
                  futureDays = futureDaysAllowable.othervalue;
                } else {
                  futureDays = futureDaysAllowable.fdaystype;
                }
                let maxDate = moment(dateValue).add(
                  Number(futureDays),

                  "days"
                );
                let setMaxDate = moment(maxDate).format("YYYY-MM-DD");
                setFutureDaysAllowable(setMaxDate);
              }
            });
            // setquoteHdr((prev) => ({
            //   ...prev,
            //   quotationDate:dateValue,
            //   quotationExpiryDate:dateValue
            // }));
      
            // const UpdatedRec = {
            //   ...quoteHeader,
            //   quotationDate:dateValue,
            //   quotationExpiryDate:dateValue
            // };
            // dispatch(getQuotationHeaders(UpdatedRec));

          }
      });
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const isEmptyObj = (myObject) => {
    const keys = Object.keys(myObject);
    return keys.length === 0;
  };

  useEffect(() => {
    if (branchList) {
      if (branchList.data) {
        if (branchList.data.length !== 0) {
          let List = branchList.data.filter((item) => item.value !== 0);
          setbranchListData(List);
        }
      }
    }
    if (customerList) {
      if (customerList.data) {
        if (customerList.data.length !== 0) {
          let CusList = [];
          CusList.push(consigneeSelect);
          customerList.data.map((item) => {
            CusList.push({
              value: item.id,
              name: item.name,
              label: item.name,
            });
          });
          setCustomerData(CusList);
        }
      }
    }
    if (BrokerData) {
      if (BrokerData.data) {
        setbrokerData(BrokerData.data);
      }
    }
  }, [branchList, customerList, BrokerData]);

  useEffect(() => {
    if (
      !billingShippingList ||
      !billingShippingList.data ||
      !billingShippingList.data.customerLineitems
    )
      return;

    const { customerLineitems, customerHdr } = billingShippingList.data;
    const list = [];
    const billingAddressList = [];
    const shippingAddressList = [];

    customerLineitems.forEach((item) => {
      if (item.isActive !== 0) {
        const addressObject = {
          value: item.customerLineId,
          label: item.address,
          name: item.address,
          gstNo: item.gstNo,
          contactNo: item.contactNo,
          contactPerson: item.contactPerson,
        };

        if (item.addressType === 2 || item.addressType === null) {
          billingAddressList.push(addressObject);
        }
        if (
          item.addressType === 1 ||
          item.addressType === null ||
          item.addressType === 2
        ) {
          shippingAddressList.push(addressObject);
        }

        list.push(addressObject);
      }
    });

    const updateQuoteHeader = (addressType, addressList) => {
      if (addressList.length === 0) {
        setquoteHdr((prev) => ({
          ...prev,
          [`${addressType}Address`]: "",
          [`${addressType}ToAddress`]: "",
          [`${addressType}GstNo`]: "",
          [`${addressType}ContactNo`]: "",
          [`${addressType}ContactPerson`]: "",
          [`${addressType}ToEmail`]: "",
        }));
        const updatedRec = {
          ...quoteHeader,
          [`${addressType}Address`]: "",
          [`${addressType}ToAddress`]: "",
          [`${addressType}GstNo`]: "",
          [`${addressType}ContactNo`]: "",
          [`${addressType}ContactPerson`]: "",
          [`${addressType}ToEmail`]: "",
        };
        dispatch(getQuotationHeaders(updatedRec));
      } else {
        const addressData = addressList[0];
        setquoteHdr((prev) => ({
          ...prev,
          [`${addressType}Address`]: addressData.value,
          [`${addressType}ToAddress`]: addressData.label,
          [`${addressType}GstNo`]: addressData.gstNo,
          [`${addressType}ContactNo`]: addressData.contactNo,
          [`${addressType}ContactPerson`]: addressData.contactPerson,
          [`${addressType}ToEmail`]: customerHdr.emailId,
        }));
        const updatedRec = {
          ...quoteHeader,
          [`${addressType}Address`]: addressData.value,
          [`${addressType}ToAddress`]: addressData.label,
          [`${addressType}GstNo`]: addressData.gstNo,
          [`${addressType}ContactNo`]: addressData.contactNo,
          [`${addressType}ContactPerson`]: addressData.contactPerson,
          [`${addressType}ToEmail`]: customerHdr.emailId,
        };
        dispatch(getQuotationHeaders(updatedRec));
      }

      // const updatedRec = {
      //   ...quoteHdr,
      //   [`${addressType}Address`]: "",
      //   [`${addressType}ToAddress`]: "",
      //   [`${addressType}GstNo`]: "",
      //   [`${addressType}ContactNo`]: "",
      //   [`${addressType}ContactPerson`]: "",
      //   [`${addressType}ToEmail`]: "",
      // };
      // dispatch(getQuotationHeaders(updatedRec));
    };

    updateQuoteHeader("billing", billingAddressList);
    updateQuoteHeader("shipping", shippingAddressList);

    if (!isEmpty(quoteHeader.customerId) ) {
      setbillingAddressData(billingAddressList);
      setshippingAddressData(shippingAddressList);
    }
  }, [billingShippingList]);

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };

  const handleSelectChange = (
    selectedValue,
    selectedName,
    name,
    row,
    selectedlable
  ) => {
    if (name === "branchId") {
      setquoteHdr((prev) => ({
        ...prev,
        branchId: selectedValue,
        branchName: selectedlable,
      }));

      const UpdatedRec = {
        ...quoteHdr,
        branchId: selectedValue,
        branchName: selectedlable,
      };
      dispatch(getQuotationHeaders(UpdatedRec));
    }
    if (name === "customerId") {
      if (selectedValue === "") {
        let TaxPer = SalesGstCalculations(
          {
            lineItems: quoteLineItems,
            taxType: 0,
          },
          "SALES_REGULAR_FLOW"
        );
        let taxType = 0;
        let taxName = "";
        handleCalculation(TaxPer, taxType, taxName, "", "");
        setquoteHdr((prev) => ({
          ...prev,
          customerId: "",
          customerName: "",

          billingAddress: "",
          billingToAddress: "",
          billingGstNo: "",
          billingContactNo: "",
          billingContactPerson: "",
          billingToEmail: "",

          shippingAddress: "",
          shippingToAddress: "",
          shippingGstNo: "",
          shippingContactNo: "",
          shippingContactPerson: "",
          shippingToEmail: "",
          taxType: "",
        }));

        return;
      } else {
        setquoteHdr((prev) => ({
          ...prev,
          customerId: selectedValue,
          customerName: selectedName,
          taxType: 2,
        }));

        const UpdatedRec = {
          ...quoteHeader,
          customerId: selectedValue,
          customerName: selectedName,
        };
        dispatch(getQuotationHeaders(UpdatedRec));
        let TaxPer = SalesGstCalculations(
          {
            lineItems: quoteLineItems,
            taxType: 2,
          },
          "SALES_REGULAR_FLOW"
        );
        let taxType = 2;
        let taxName = "CGST & SGST";
        handleCalculation(
          TaxPer,
          taxType,
          taxName,
          selectedValue,
          selectedName
        );
        dispatch(
          getBillingShippingList(
            serverApi.GET_ALL_BILLING_SHIPPING +
              selectedValue +
              "/" +
              JSON.parse(localStorage.getItem("authUser")).cipher +
              "?isForSalesModule=1",
            history
          )
        );
      }
    }
    if (name == "brokerId") {
      if (selectedValue === 0) {
        setquoteHdr((prev) => ({
          ...prev,
          brokerId: "",
          brokeragePercentage: "",
          brokerName: "",
        }));
        const UpdatedRec = {
          ...quoteHdr,
          brokerId: "",
          brokeragePercentage: "",
          brokerName: "",
        };
        dispatch(getQuotationHeaders(UpdatedRec));

        return;
      }
      setquoteHdr((prev) => ({
        ...prev,
        brokerId: selectedValue,
        brokeragePercentage: "",
        brokerName: selectedName,
      }));
      const UpdatedRec = {
        ...quoteHdr,
        brokerId: selectedValue,
        brokeragePercentage: "",
        brokerName: selectedName,
      };
      dispatch(getQuotationHeaders(UpdatedRec));
    }
    if (name == "billingAddress") {
      const getModifiedBilllingAddress =
        billingShippingList.data.customerLineitems.filter(
          (prop) => prop.customerLineId === selectedValue
        ) || [];
      setquoteHdr((prev) => ({
        ...prev,
        billingAddress: selectedValue,
        billingToAddress: selectedName,
        billingGstNo: getModifiedBilllingAddress[0].gstNo,
        billingContactNo: getModifiedBilllingAddress[0].contactNo,
        billingContactPerson: getModifiedBilllingAddress[0].contactPerson,
      }));

      const UpdatedRec = {
        ...quoteHdr,
        billingAddress: selectedValue,
        billingToAddress: selectedName,

        billingGstNo: getModifiedBilllingAddress[0].gstNo,
        billingContactNo: getModifiedBilllingAddress[0].contactNo,
        billingContactPerson: getModifiedBilllingAddress[0].contactPerson,
      };
      dispatch(getQuotationHeaders(UpdatedRec));
    }
    if (name == "shippingAddress") {
      const getModifiedBilllingAddress =
        billingShippingList.data.customerLineitems.filter(
          (prop) => prop.customerLineId === selectedValue
        ) || [];
      setquoteHdr((prev) => ({
        ...prev,
        shippingAddress: selectedValue,
        shippingToAddress: selectedName,

        shippingGstNo: getModifiedBilllingAddress[0].gstNo,
        shippingContactNo: getModifiedBilllingAddress[0].contactNo,
        shippingContactPerson: getModifiedBilllingAddress[0].contactPerson,
      }));
      const UpdatedRec = {
        ...quoteHdr,
        shippingAddress: selectedValue,
        shippingToAddress: selectedName,

        shippingGstNo: getModifiedBilllingAddress[0].gstNo,
        shippingContactNo: getModifiedBilllingAddress[0].contactNo,
        shippingContactPerson: getModifiedBilllingAddress[0].contactPerson,
      };
      dispatch(getQuotationHeaders(UpdatedRec));
    }
    if (name == "TaxType") {
      let TaxPer = SalesGstCalculations(
        {
          lineItems: quoteLineItems,
          taxType: selectedValue,
        },
        "SALES_REGULAR_FLOW"
      );
      let taxType = selectedValue;
      let taxName = selectedName;
      handleCalculation(
        TaxPer,
        taxType,
        taxName,
        quoteHdr.customerId,
        quoteHdr.customerName
      );
      setquoteHdr((prev) => ({
        ...prev,
        taxType: selectedValue,
        taxName: selectedName,
      }));
    }
    if (name === "deliveryTerms") {
      if (selectedValue == "") {
        setquoteHdr((prev) => ({
          ...prev,
          deliveryTerms: "",
        }));
        const UpdatedRec = {
          ...quoteHdr,
          deliveryTerms: "",
        };
        dispatch(getQuotationHeaders(UpdatedRec));
        return;
      }
      setquoteHdr((prev) => ({
        ...prev,
        deliveryTerms: selectedName,
      }));
      const UpdatedRec = {
        ...quoteHdr,
        deliveryTerms: selectedName,
      };
      dispatch(getQuotationHeaders(UpdatedRec));
    }
  };

  const handleCalculation = (
    Calculations,
    taxType,
    taxName,
    customerId,
    customerName
  ) => {
    setLineItems(Calculations.lineItems);
    dispatch(getQuotationLineItems(Calculations.lineItems));

    let Headerdata = {
      ...quoteHdr,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      netTotal: Calculations.netTotal,
      totalAmount: Calculations.TotalAmount,
      subTotal: Calculations.subTotal,
      taxType: taxType,
      taxName: taxName,
      customerId: customerId,
      customerName: customerName,
    };
    if (!taxType) {
      Headerdata = {
        ...Headerdata,
        customerId: "",
        customerName: "",

        billingAddress: "",
        billingToAddress: "",
        billingGstNo: "",
        billingContactNo: "",
        billingContactPerson: "",
        billingToEmail: "",

        shippingAddress: "",
        shippingToAddress: "",
        shippingGstNo: "",
        shippingContactNo: "",
        shippingContactPerson: "",
        shippingToEmail: "",
        taxType: "",
      };
    }
    setquoteHdr((prev) => ({
      ...prev,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
      totalAmount: Calculations.TotalAmount,
      taxType: taxType,
      taxName: taxName,
    }));
    dispatch(getQuotationHeaders(Headerdata));
  };

  const handleSelect_Date = (e, name) => {
    if (name === "quotationDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      let quoteDate;
      if (Date > quoteHdr.quotationExpiryDate) {
        swal("Quotation date can't be greater than Quotation Expiry Date");
        quoteDate = quoteHdr.quotationExpiryDate;
      } else {
        quoteDate = Date;
      }

      setquoteHdr((prev) => ({
        ...prev,
        quotationDate: quoteDate,
      }));
      const UpdatedRec = {
        ...quoteHdr,
        quotationDate: quoteDate,
      };
      dispatch(getQuotationHeaders(UpdatedRec));
    }

    if (name === "quotationExpiryDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      let quoteExpiryDate;
      if (Date < quoteHdr.quotationDate) {
        swal("Expiry Date Should be Greater than Quotation Date");
        quoteExpiryDate = quoteHdr.quotationDate;
      } else {
        quoteExpiryDate = Date;
      }

      setquoteHdr((prev) => ({
        ...prev,
        quotationExpiryDate: quoteExpiryDate,
      }));
      const UpdatedRec = {
        ...quoteHdr,
        quotationExpiryDate: quoteExpiryDate,
      };
      dispatch(getQuotationHeaders(UpdatedRec));
    }
  };

  const onhandlechange = (key) => (event) => {
    if (key === "brokeragePercentage") {
      if (event.match(/^(100(\.0{1,2})?|(\d{0,2}(\.\d{0,2})?))$/)) {
        setquoteHdr((prev) => ({
          ...prev,
          brokeragePercentage: event,
        }));
        const UpdatedRec = {
          ...quoteHdr,
          brokeragePercentage: event,
        };
        dispatch(getQuotationHeaders(UpdatedRec));
      } else {
        swal(
          "Brokerage percentage can take upto 2 decimals and can't be more than 100."
        );
        return;
      }
    }
  };

  return (
    <div className="consoleFormContainer">
      <h5>Fill Details</h5>
      <div className="consoleFormBlock">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    update={quoteHdr.branchId ? 1 : 0}
                    arrayOfData={branchListData}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={handleSelectChange}
                    selected={quoteHdr.branchId}
                    name="branchId"
                    label="Branch"
                    required
                    isDropdownOpen={isDropdownOpen}
                    handleMenuOpen={handleMenuOpen}
                    isDisabled={props.locationState.state !== undefined}
                  />
                </div>
              </Box>

              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    update={quoteHdr.brokerId ? 1 : 0}
                    arrayOfData={brokerData}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    selected={quoteHdr.brokerId}
                    onSelectChange={handleSelectChange}
                    isDropdownOpen={isDropdownOpen}
                    handleMenuOpen={handleMenuOpen}
                    name="brokerId"
                    label="Broker"
                    ErrorOutlineIcon={true}
                    toolTipTitle={
                      "Broker or Consignee is mandetory Select At least one and Proceed"
                    }
                    isDisabled={isEmpty(quoteHdr.branchId) ? true : false}
                  />
                </div>
              </Box>

              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    update={quoteHdr.customerId ? 1 : 0}
                    arrayOfData={CustomerData}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={handleSelectChange}
                    selected={quoteHdr.customerId}
                    name="customerId"
                    label="Customer/Consignee"
                    isDropdownOpen={isDropdownOpen}
                    handleMenuOpen={handleMenuOpen}
                    ErrorOutlineIcon={true}
                    toolTipTitle={
                      "Broker or Consignee is mandetory Select At least one and Proceed"
                    }
                    isDisabled={isEmpty(quoteHdr.branchId) ? true : false}
                  />
                </div>
              </Box>

              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <div>
                    <div className="TextFieldBlock">
                      <label>
                        Quote Date<span className="starcolor">*</span>
                      </label>
                    </div>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        name="quotationDate"
                        inputFormat="dd-MM-yyyy"
                        id="quotationDate"
                        minDate={
                          backDaysAllowable !== ""
                            ? backDaysAllowable
                            : quoteHdr.quotationDate
                        }
                        maxDate={
                          futureDaysAllowable !== ""
                            ? futureDaysAllowable
                            : quoteHdr.quotationDate
                        }
                        value={quoteHdr.quotationDate}
                        fullWidth
                        onChange={(e) => handleSelect_Date(e, "quotationDate")}
                        isDisabled={true}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                            style={{
                              backgroundColor:
                                quoteHdr.branchId === "" ? "#ccd6db" : "",
                            }}
                          >
                            <input
                              ref={inputRef}
                              {...inputProps}
                              style={{
                                backgroundColor:
                                  quoteHdr.branchId === "" ? "#ccd6db" : "",
                              }}
                              readOnly={true}
                            />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <div>
                    <div className="TextFieldBlock">
                      <label>
                        Expiry Date<span className="starcolor">*</span>
                      </label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        name="quotationExpiryDate"
                        inputFormat="dd-MM-yyyy"
                        id="quotationExpiryDate"
                        value={quoteHdr.quotationExpiryDate}
                        minDate={
                          backDaysAllowable !== ""
                            ? backDaysAllowable
                            : quoteHdr.quotationExpiryDate
                        }
                        maxDate={
                          futureDaysAllowable !== ""
                            ? futureDaysAllowable
                            : quoteHdr.quotationExpiryDate
                        }
                        fullWidth
                        onChange={(e) =>
                          handleSelect_Date(e, "quotationExpiryDate")
                        }
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                            style={{
                              backgroundColor:
                                quoteHdr.customerId || quoteHdr.brokerId
                                  ? ""
                                  : "#c8dce4",
                            }}
                          >
                            <input
                              ref={inputRef}
                              {...inputProps}
                              style={{
                                backgroundColor:
                                  quoteHdr.customerId || quoteHdr.brokerId
                                    ? ""
                                    : "#c8dce4",
                              }}
                              readOnly={true}
                            />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelectNew
                    update={quoteHdr.billingAddress ? 1 : 0}
                    arrayOfData={billingAddressData}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={handleSelectChange}
                    selected={quoteHdr.billingToAddress}
                    name="billingAddress"
                    label="Billing To"
                    isDropdownOpen={isDropdownOpen}
                    handleMenuOpen={handleMenuOpen}
                    isDisabled={isEmpty(quoteHdr.customerId) ? true : false}
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div
                  className="consoleTextFieldBlock"
                  style={{ marginBottom: "3px" }}
                >
                  <DynamicSelectNew
                    update={quoteHdr.shippingAddress ? 1 : 0}
                    arrayOfData={shippingAddressData}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={handleSelectChange}
                    selected={quoteHdr.shippingToAddress}
                    name="shippingAddress"
                    label="Shipping TO"
                    isDropdownOpen={isDropdownOpen}
                    handleMenuOpen={handleMenuOpen}
                    isDisabled={isEmpty(quoteHdr.customerId)}
                  />
                </div>
                {!isEmpty(quoteHdr.customerId) && (
                  <span
                    onClick={openPopup}
                    style={{
                      color: "red",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: 100,
                      fontSize: "15px",
                      marginTop: "5px",
                      cursor: "pointer",
                      padding: "5px",
                    }}
                  >
                    Add a new Customer Branch Address
                  </span>
                )}
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    update={quoteHdr.taxType ? 1 : 0}
                    arrayOfData={taxTypeData}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={handleSelectChange}
                    selected={quoteHdr.taxType}
                    name="TaxType"
                    label="Tax Type"
                    isDropdownOpen={isDropdownOpen}
                    handleMenuOpen={handleMenuOpen}
                    isDisabled={isEmpty(quoteHdr.customerId) ? true : false}
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <TextFieldNormal
                    label="Brokerage%"
                    placeholder="Enter brokerage percentage%"
                    value={quoteHdr.brokeragePercentage}
                    onChange={onhandlechange("brokeragePercentage")}
                    disabled={quoteHdr.brokerId ? false : true}
                    required={quoteHdr.brokerId ? true : false}
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <DynamicSelectNew
                  update={quoteHdr.deliveryTerms ? 1 : 0}
                  arrayOfData={DeliveryTermsData}
                  className="dropdownBlockContainer"
                  menuPosition="fixed"
                  menuPlacement="auto"
                  onSelectChange={handleSelectChange}
                  isDropdownOpen={isDropdownOpen}
                  handleMenuOpen={handleMenuOpen}
                  selected={quoteHdr.deliveryTerms}
                  name="deliveryTerms"
                  label="Delivery Terms"
                  required={true}
                  isDisabled={isEmpty(quoteHdr.customerId || quoteHdr.brokerId) ? true : false}
                />
              </Box>
              {/* {this.props.location.state && (
                <>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>Created Date</label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="Expire_Date"
                            inputFormat="dd-MM-yyyy"
                            id="Expire_Date"
                            value={
                              this.props.location.state.rowData.createdDate
                            }
                            fullWidth
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Created By"
                        placeholder="Enter here"
                        value={this.props.location.state.rowData.userName}
                        disabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        label="Status"
                        placeholder="Enter here"
                        value={this.props.location.state.rowData.statusDesc}
                        disabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Quotation Number"
                        placeholder="Enter here"
                        value={this.props.location.state.rowData.quoteId}
                        disabled={true}
                      />
                    </div>
                  </Box>
                </>
              )} */}
            </Box>
          </Grid>
        </Grid>
      </div>
      <FormPopup
        isOpen={isPopupOpen}
        onClose={closePopup}
        customerData={billingShippingList}
        customerId={quoteHdr.customerId}
      />
    </div>
  );
};

const taxTypeData = [
  {
    value: 1,
    name: "IGST",
    label: "IGST",
  },
  {
    value: 2,
    name: "CGST & SGST",
    label: "CGST & SGST",
  },
];
const DeliveryTermsData = [
  {
    value: 1,
    label: "Ex-Works",
    name: "Ex-Works",
  },
  {
    value: 2,
    label: "Delivery to Destination",
    name: "Delivery to Destination",
  },
];

const consigneeSelect = {
  value: "",
  name: "Select",
  label: "Select",
};

export default QuotationFillDetails;
