import React from "react";



function Button(props) {
  const customClass = `button ${props.className}`;
  return (
    <button
      className={customClass}
      onClick={props.handleClick}
      type={props.type}
      value={props.value}
      disabled={props.disabled}
      style={{width: props.width}}
    >
      {(props.icon ? <img src={props.icon} alt=''/> : "")}
      {props.label}
      {props.muIcon}
    </button>
  );
}

export default Button;