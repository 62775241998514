import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import {
  createSpinningQuality,
  updateSpinningQuality,
} from "../../../store/Master/SpinningQualityMaster/actions";
import { getYarnTypeList } from "../../../store/Global/DropDownApis/actions";

import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import moment from "moment";
import { validate } from "../../../Utilities/Validations";
import swal from "sweetalert";

class CreateSpinningQuality extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      createdBy: "",
      qualityCode: "",
      qualityName: "",
      type: "",
      typeId: "",
      frameType: "",
      frameTypeValue: "",
      jboRbo: "",
      jboRboId: "",
      count: "",
      spindle: "",
      tarEff: "",
      tpi: "",
      speed: "",
      stdDoff: "",
      stdWtDoff: "",
      yarnTypeListData: [], //displaying yarn type list data
      qualityId: "",
      createdDate: "",
    };
  }
  /* Calling Create and Update Api's to Actions */
  onClickCreateUpdate = () => {
    const {
      qualityCode,
      qualityName,
      typeId,
      frameTypeValue,
      jboRboId,
      count,
      spindle,
      tarEff,
      tpi,
      speed,
      stdDoff,
      stdWtDoff,
    } = this.state;

    const validateFields = [
      { field: qualityCode, msg: "Quality Code", type: 1 },
      { field: qualityName, msg: "Quality Name", type: 1 },
      { field: typeId, msg: "Type", type: 2 },
      { field: jboRboId, msg: "JBO/RBO", type: 2 },
      { field: frameTypeValue, msg: "Frame Type", type: 2 },
      { field: count, msg: "Count", type: 1 },
      { field: spindle, msg: "Spindle", type: 1 },
      { field: tarEff, msg: "TarEff", type: 1 },
      { field: tpi, msg: "TPI", type: 1 },
      { field: speed, msg: "Speed", type: 1 },
      { field: stdDoff, msg: "StdDoff", type: 1 },
      { field: stdWtDoff, msg: "StdWtDoff", type: 1 },
    ];

    const validObj = validate(validateFields);

    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message} ..!!`);
      } else {
        swal(`Please Select ${validObj.message} ..!!`);
      }
    } else {
      if (this.props.location.state === undefined) {
        const data = {
          qualityCode: this.state.qualityCode,
          qualityName: this.state.qualityName,
          qualityType: 2,
          yarnCount: this.state.count,
          spindleCount: this.state.spindle,
          tarEff: this.state.tarEff,
          tpi: this.state.tpi,
          speed: this.state.speed,
          stdDoff: this.state.stdDoff,
          stdDoffWt: this.state.stdWtDoff,
          companyId: this.state.companyId,
          yarnType: this.state.typeId,
          machineType: this.state.frameTypeValue,
          jboRbo: this.state.jboRboId,
          createdBy: this.state.userDit.userId.toString(),
        };
        // if(this.state.jboRbo==""||this.state.jboRbo==null||this.state.jboRbo==undefined){
        //   swal("jvhg");
        //   return false;
        // }
        this.props.createSpinningQuality(
          serverApi.CREATE_SPINNING_QUALITY,
          data,
          this.props.history
        ); // calling create spinning quality api
      } else {
        const data = {
          qualityId: this.state.qualityId,
          // qualityCode: this.state.qualityCode,
          // qualityName: this.state.qualityName,
          yarnCount: this.state.count,
          spindleCount: this.state.spindle,
          tarEff: this.state.tarEff,
          shots: 0,
          tpi: this.state.tpi,
          speed: this.state.speed,
          stdDoff: this.state.stdDoff,
          stdDoffWt: this.state.stdWtDoff,
          createdBy: this.state.userDit.userId.toString(),
          companyId: this.state.companyId,
          qualityType: 2,
          yarnType: this.state.typeId,
          machineType: this.state.frameTypeValue,
          jboRbo: this.state.jboRboId,
          createDateTime: this.state.createdOn,
        };
        this.props.updateSpinningQuality(
          serverApi.UPDATE_SPINNING_QUALITY,
          data,
          this.props.history
        ); // calling update spinning quality api
      }
    }
  };
  // function for previous page
  onClickCancel = () => {
    this.props.history.push("/spinning_quality_master");
  };
  //function for TextFields
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "qualityCode") {
      const qtycode = event.replace(/[^a-zA-Z0-9\s]+/g, "");
      this.setState({
        qualityCode: qtycode,
      });
    }
    if (key === "qualityName") {
      this.setState({
        qualityName: event,
      });
    }
    if (key === "count") {
      this.setState({
        count: event,
      });
    }
    if (key === "spindle") {
      this.setState({
        spindle: event,
      });
    }
    if (key === "tarEff") {
      this.setState({
        tarEff: event,
      });
    }
    if (key === "tpi") {
      this.setState({
        tpi: event,
      });
    }
    if (key === "speed") {
      this.setState({
        speed: event,
      });
    }
    if (key === "stdDoff") {
      this.setState({
        stdDoff: event,
      });
    }
    if (key === "stdWtDoff") {
      this.setState({
        stdWtDoff: event,
      });
    }
  };
  // function for popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "type") {
      this.setState({
        type: selectedValue,
        typeId: selectedValue,
      });
    }
    if (name === "frameType") {
      this.setState({
        frameType: selectedValue,
        frameTypeValue: selectedValue,
      });
    }
    if (name === "jboRbo") {
      this.setState({
        jboRbo: selectedValue,
        jboRboId: selectedValue,
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getYarnTypeList(
      serverApi.GET_YARN_TYPE_LIST + this.state.companyId,
      this.props.history
    ); // calling for yarn type list api

    if (this.props.location.state) {
      this.setState({
        qualityId: this.props.location.state.rowData.qualityId,
        companyId: this.props.location.state.rowData.companyId,
        createdOn: this.props.location.state.rowData.createDateTime,
        createdBy: this.props.location.state.rowData.createdBy,
        jboRboId: this.props.location.state.rowData.jboRbo,
        frameTypeValue: this.props.location.state.rowData.machineType,
        qualityCode: this.props.location.state.rowData.qualityCode,
        qualityName: this.props.location.state.rowData.qualityName,
        qualityType: this.props.location.state.rowData.qualityType,
        shots: this.props.location.state.rowData.shots,
        speed: this.props.location.state.rowData.speed,
        spindle: this.props.location.state.rowData.spindleCount,
        stdDoff: this.props.location.state.rowData.stdDoff,
        stdWtDoff: this.props.location.state.rowData.stdDoffWt,
        tarEff: this.props.location.state.rowData.tarEff,
        tpi: this.props.location.state.rowData.tpi,
        count: this.props.location.state.rowData.yarnCount,
        typeId: this.props.location.state.rowData.yarnType,
      });
      const dateEntered = this.props.location.state.rowData.createDateTime;
      const dateIsValid = moment(dateEntered).format("LLLL");
      this.setState({
        createdDate: dateIsValid,
      });
    }
  }
  componentWillReceiveProps(props) {
    // if (props.yarnTypeList) {
    //   this.setState({
    //     yarnTypeListData: props.yarnTypeList.data, // updating department list
    //   });
    // }

    if (props.yarnTypeList.data) {
      var list = [];

      list = props.yarnTypeList.data.filter((item) => {
        if (item.value !== 0) {
          return item;
        }
      });

      this.setState({
        yarnTypeListData: list,
      });
    }
  }
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.qualityCode}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("qualityCode")}
                      label="Quality Code"
                      disabled={this.props.location.state ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.qualityName}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("qualityName")}
                      label="Quality Name"
                      disabled={
                        this.props.location.state === ""
                          ? true
                          : false || this.state.qualityCode === ""
                          ? true
                          : false
                      }
                      readOnly={this.state.qualityCode === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.yarnTypeListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.typeId}
                        update={this.props.location.state ? 1 : 0}
                        name="type"
                        label="Type"
                        required
                        isDisabled={
                          this.state.qualityName === "" ? true : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={JboRbo}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.jboRboId}
                        update={this.props.location.state ? 1 : 0}
                        name="jboRbo"
                        label="JBO/RBO"
                        required
                        isDisabled={
                          this.state.typeId === ""
                            ? true
                            : false || this.state.typeId === 0
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={FrameType}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.frameTypeValue}
                        update={this.props.location.state ? 1 : 0}
                        name="frameType"
                        label="Frame Type"
                        required
                        isDisabled={
                          this.state.jboRboId === ""
                            ? true
                            : false || this.state.jboRboId === null
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      type="number"
                      value={this.state.count}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("count")}
                      label="Count"
                      disabled={
                        this.state.frameTypeValue === ""
                          ? true
                          : false || this.state.frameTypeValue === null
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.spindle}
                      type="number"
                      caps="OFF"
                      onChange={this.onhandlechangeValue("spindle")}
                      label="Spindle"
                      disabled={this.state.count === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.tarEff}
                      type="number"
                      caps="OFF"
                      onChange={this.onhandlechangeValue("tarEff")}
                      label="TarEff"
                      disabled={this.state.spindle === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.tpi}
                      type="number"
                      caps="OFF"
                      onChange={this.onhandlechangeValue("tpi")}
                      label="TPI"
                      disabled={this.state.tarEff === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.speed}
                      type="number"
                      caps="OFF"
                      onChange={this.onhandlechangeValue("speed")}
                      label="Speed"
                      disabled={this.state.tpi === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.stdDoff}
                      type="number"
                      caps="OFF"
                      onChange={this.onhandlechangeValue("stdDoff")}
                      label="StdDoff"
                      disabled={this.state.speed === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.stdWtDoff}
                      type="number"
                      caps="OFF"
                      onChange={this.onhandlechangeValue("stdWtDoff")}
                      label="StdWtDoff"
                      disabled={this.state.stdDoff === "" ? true : false}
                    />
                  </Box>
                  {this.props.location.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdDate}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.props.location.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}
const JboRbo = [
  { label: "JBO", value: "JBO", name: "JBO" },
  { label: "RBO", value: "RBO", name: "RBO" },
];

const FrameType = [
  { label: "Boxter", value: "1", name: "boxter" },
  { label: "2 Leg", value: "2", name: "2leg" },
];
const mapStatetoProps = (state) => {
  const { UpdateSpinningQuality } = state.SpinningQualityReducer; // fetching create and update from Spinning quality list reducer
  const { yarnTypeList } = state.DropDownListReducer; // fetching globle dropdown from yarn type list
  return { UpdateSpinningQuality, yarnTypeList };
};

export default withRouter(
  connect(mapStatetoProps, {
    createSpinningQuality,
    updateSpinningQuality,
    getYarnTypeList,
  })(CreateSpinningQuality)
);
