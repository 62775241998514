import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import DynamicSelect from "../../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import { Grid, Box, IconButton, Button } from "@mui/material";
import { allItemGroupMasterList } from "../../../../store/Global/DropDownApis/actions";
import { serverApi, serverConfig } from "../../../../helpers/Consts";
import TickIcon from "../../../../assets/images/fileUploadedTick.png";
import DeleteIcons from "../../../../assets/images/picEditIcon.png";
import panUploadIcon from "../../../../assets/images/panUploadIcon.png";
import { getFileDownload } from "../../../../helpers/server";
import {
  SalesOrderJuteGovtHeaders,
  SalesOrderJuteGovtLineItems,
  getItemsByItemGroupIdForJuteGovt,
  UploadSOFiles,
  deleteSoFile,
  getJuteGovtSoItemNameData
} from "../../../../store/Sales/SalesOrder/actions";
import { getUomRelationValues } from "../../../../store/Sales/Qutation/actions";
import swal from "sweetalert";
import { SalesGstCalculations } from "../../../../Utilities/SalesGstCalculation";
import {
  isPositiveNumberWithDecimals,
  limitDecimals,
  convertPrimaryToSecondary,
  convertRatePcsToMt,
} from "../../../../Utilities/helper";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";

const JuteGovtLineItems = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [locationState] = useState(useLocation());
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [LineItemsDisplayTxtField, setLineItemsDisplayTxtField] = useState({});
  const [LineItemsDisplayDropField, setLineItemsDisplayDropField] = useState(
    {}
  );
  const [ItemGroupData, setItemGroupData] = useState([]);
  const [userDit, setuserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [SoJuteGovtHeadersData, setSoJuteGovtHeadersData] = useState({});
  const [SoJuteGovtLineItemsData, SetSoJuteGovtLineItemsData] = useState([]);
  const { allItemGroupMaster_List } = useSelector(
    (state) => state.DropDownListReducer
  );
  const { SoJuteGovtLineItems, SoJuteGovtHeaders, SOFiles } = useSelector(
    (state) => state.SalesOrderReducer
  );
  const [salesOrderFiles, SetSalesOrderFiles] = useState(
    SOFiles?.data?.data || []
  );
  useEffect(() => {
    SetSalesOrderFiles(SOFiles?.data?.data);
  }, [SOFiles]);
  const onDeleteFile = (fileId) => {
    let data = salesOrderFiles.filter((item) => item.fileUploadId !== fileId);
    SetSalesOrderFiles(data);
    dispatch({ type: "UPDATE_SO_FILES", payload: data });
    dispatch(deleteSoFile(serverApi.DELETEFILE + fileId));
  };

  const handleUpload = (e) => {
    e.preventDefault();
    let sourceMenu = 45;

    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", sourceMenu);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", SoJuteGovtHeadersData.regularSalesId);
    formData.append(
      "createdBy",
      JSON.parse(localStorage.getItem("authUser")).userId
    );
    formData.append("fileCompanyId", localStorage.getItem("companyId"));

    dispatch(
      UploadSOFiles(
        serverApi.UPLOADFILE,
        formData,
        history,
        sourceMenu,
        SoJuteGovtHeadersData.regularSalesId
      )
    );
  };
  useEffect(() => {
    SetSoJuteGovtLineItemsData(SoJuteGovtLineItems);
    setSoJuteGovtHeadersData(SoJuteGovtHeaders);
    if (allItemGroupMaster_List) {
      if (allItemGroupMaster_List.data) {
        let ListFilter = allItemGroupMaster_List.data.filter((itemgrps) => {
          if (itemgrps.value !== "0") {
            return itemgrps;
          }
        });
        let list = [];
        ListFilter.map((itemGrp) => {
          list.push({
            label: itemGrp.label,
            name: itemGrp.name,
            value: itemGrp.value,
            variant: itemGrp.id,
          });
        });
        setItemGroupData(list);
      }
    }
  }, [allItemGroupMaster_List, SoJuteGovtLineItems, SoJuteGovtHeaders]);

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const onDel = (row) => {
    var FilterLineItems = SoJuteGovtLineItemsData.filter(
      (item) => item.isActive !== 0
    );
    if (FilterLineItems.length == 1) {
      swal("you can't delete the last lineitem");
    } else {
      let indexItem = SoJuteGovtLineItemsData.findIndex((item) => item === row);
      let lineItems = [...SoJuteGovtLineItemsData];
      lineItems[indexItem].isActive = 0;
      lineItems[indexItem].govtIsActive=0;
      SetSoJuteGovtLineItemsData(lineItems);
      dispatch(SalesOrderJuteGovtLineItems(lineItems));
    }
  };

  const displayField = (row, colname, type) => {
    if (colname === "itemGroupId") {
      const fieldKey1 = colname + row.lineItemId + "1";
      const fieldKey2 = colname + row.lineItemId + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type == 1) {
        dispatch(
          allItemGroupMasterList(
            serverApi.GET_ALL_ITEMGROUP_MASTER_LIST +
              localStorage.getItem("companyId") +
              "/" +
              userDit.cipher,
            history
          )
        );
      }
    } else if (colname === "itemId") {
      const fieldKey1 = colname + row.lineItemId + "1";
      const fieldKey2 = colname + row.lineItemId + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type == 1) {
        if (row.itemGroupId !== null && row.itemGroupValue !== "") {
          const data = {
            companyId: localStorage.getItem("companyId"),
            itemGroupId: row.itemGroupValue,
          };
          dispatch(
            getItemsByItemGroupIdForJuteGovt(
              serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
              data,
              history,
              row.lineItemId
            )
          );
        } else {
          if (row.itemGroupId === 997) {
            const data = {
              companyId: localStorage.getItem("companyId"),
              itemGroupId: 997,
            };
            dispatch(
              getItemsByItemGroupIdForJuteGovt(
                serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
                data,
                history,
                row.lineItemId
              )
            );
          }
        }
      }
    }
  };
  const OnhandleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    let mname = key.split("_");
    if (mname[0] === "remarks") {
      let LineItemsRec = SoJuteGovtLineItemsData.filter((rowItem) => {
        if (row.lineItemId === rowItem.lineItemId) {
          rowItem.remarks = value;
        }
        return rowItem;
      });
      SetSoJuteGovtLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteGovtLineItems(LineItemsRec));
    }
    if (mname[0] === "itemMake") {
      let LineItemsRec = SoJuteGovtLineItemsData.filter((rowItem) => {
        if (row.lineItemId === rowItem.lineItemId) {
          rowItem.itemMake = value;
        }
        return rowItem;
      });
      SetSoJuteGovtLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteGovtLineItems(LineItemsRec));
    }
    if (mname[0] === "hsncode") {
      let LineItemsRec = SoJuteGovtLineItemsData.filter((rowItem) => {
        if (row.lineItemId === rowItem.lineItemId) {
          rowItem.hsnCode = value;
        }
        return rowItem;
      });
      SetSoJuteGovtLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteGovtLineItems(LineItemsRec));
    }
    if (mname[0] === "rate") {
      let LineItemsRec = SoJuteGovtLineItemsData.filter((rowItem) => {
        if (row.lineItemId === rowItem.lineItemId) {
          // if (!isPositiveNumberWithDecimals(value, 2)) {
          if (value.match(`^\\d*(\\.\\d{0,${3}})?$`)) {
            rowItem.rate = value;
            rowItem.discountedRate = value;
            if (rowItem.govtQuantityUom !== "") {
              const balesResult = convertPrimaryToSecondary(
                rowItem.quantity,
                rowItem.govtRelationValue
              );
              if (balesResult !== null) {
                rowItem.govtQtyCalculatedValue = balesResult;
              }
            }
            if (rowItem.govtQuantityUom2 !== "") {
              const mtResult = convertPrimaryToSecondary(
                rowItem.quantity,
                rowItem.govtRelationValue2
              );
              if (mtResult !== null && rowItem.govtQuantityUom2 !== "") {
                rowItem.govtQtyCalculatedValue2 = mtResult;
              }
            }
            if (rowItem.govtQuantityUom3 !== "") {
              const kgsResult = convertPrimaryToSecondary(
                rowItem.quantity,
                rowItem.govtRelationValue3
              );
              if (kgsResult !== null && rowItem.govtQuantityUom3 !== "") {
                rowItem.govtQtyCalculatedValue3 = kgsResult;
              }
            }
            if (rowItem.govtRateUom2 !== "") {
              // rate UOM conversion
              const mtRateResult = convertRatePcsToMt(
                value,
                rowItem.govtRelationValue2
              );
              rowItem.govtRateCalculatedValue2=mtRateResult

            }
          } else {
            swal("Can't Enter more than 3 Decimals!");
          }
        }
        return rowItem;
      });
      SetSoJuteGovtLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteGovtLineItems(LineItemsRec));
      let Rate = SalesGstCalculations(
        {
          lineItems: SoJuteGovtLineItemsData,
          taxType: SoJuteGovtHeadersData.taxType,
        },
        "SALES_REGULAR_FLOW"
      );
      handleCalculation(Rate);
    }
    if (mname[0] === "quantity") {
      let LineItemsRec = SoJuteGovtLineItemsData.filter((rowItem) => {
        // if (!isPositiveNumberWithDecimals(value, 3)) {
        if (value.match(`^\\d*(\\.\\d{0,${3}})?$`)) {
          rowItem.quantity = value;
          // qty UOM conversion
          if (rowItem.govtQuantityUom !== "") {
            const balesResult = convertPrimaryToSecondary(
              value,
              rowItem.govtRelationValue
            );
            if (balesResult !== null) {
              rowItem.govtQtyCalculatedValue = balesResult;
            }
          }
          if (rowItem.govtQuantityUom2 !== "") {
            const mtResult = convertPrimaryToSecondary(
              value,
              rowItem.govtRelationValue2
            );
            if (mtResult !== null && rowItem.govtQuantityUom2 !== "") {
              rowItem.govtQtyCalculatedValue2 = mtResult;
            }
          }
          if (rowItem.govtQuantityUom3 !== "") {
            const kgsResult = convertPrimaryToSecondary(
              value,
              rowItem.govtRelationValue3
            );
            if (kgsResult !== null && rowItem.govtQuantityUom3 !== "") {
              rowItem.govtQtyCalculatedValue3 = kgsResult;
            }
          }
        } else {
          swal("Can't Enter more than 3 Decimals!");
        }
        return rowItem;
      });
      SetSoJuteGovtLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteGovtLineItems(LineItemsRec));
      let Qty = SalesGstCalculations(
        {
          lineItems: SoJuteGovtLineItemsData,
          taxType: SoJuteGovtHeadersData.taxType,
        },
        "SALES_REGULAR_FLOW"
      );
      handleCalculation(Qty);
    }
    if (mname[0] === "taxPercent") {
      let LineItemsRec = SoJuteGovtLineItemsData.filter((rowItem) => {
        if (row.lineItemId === rowItem.lineItemId) {
          rowItem.taxPercent = limitDecimals(value);
        }
        return rowItem;
      });
      SetSoJuteGovtLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteGovtLineItems(LineItemsRec));
      let TaxPer = SalesGstCalculations(
        {
          lineItems: SoJuteGovtLineItemsData,
          taxType: SoJuteGovtHeadersData.taxType,
        },
        "SALES_REGULAR_FLOW"
      );
      handleCalculation(TaxPer);
    }
    if (mname[0] === "netAmount") {
      let LineItemsRec = SoJuteGovtLineItemsData.filter((rowItem) => {
        if (row.lineItemId === rowItem.lineItemId) {
          rowItem.netAmount = limitDecimals(value);
        }
        return rowItem;
      });
      SetSoJuteGovtLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteGovtLineItems(LineItemsRec));
    }
  };
  const handleSelectChange = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    selectedItemCode
  ) => {
    if (name === "itemGroupId") {
      let LineItemdata = SoJuteGovtLineItemsData.filter((row) => {
        if (row.lineItemId === selectedRow.lineItemId) {
          row.itemGroupId = selectedItemCode;
          row.itemGroupName = selectedName;
          row.itemGroupValue = selectedValue;
          row.itemId = null;
          row.itemName = "";
          row.itemValue = "";
          row.itemCode = "";
          row.rate = "";
          row.itemName = "";
          row.hsnCode = "";
          row.discountedRate = "";
          row.uom = "";
          row.discount = "";
          row.discountType = "";
          row.discountName = "";
          row.discountAmount = "";
          row.make = "";
          row.quantity = "0.000";
          row.totalAmount = "";
          row.netAmount = "";
          row.taxPercent = "";
          row.itemMake = "";
          row.remarks = "";
        }
        return row;
      });
      SetSoJuteGovtLineItemsData(LineItemdata);
      dispatch(SalesOrderJuteGovtLineItems(LineItemdata));
      displayField(selectedRow, "itemGroupId", 2);
      const data = {
        companyId: localStorage.getItem("companyId"),
        itemGroupId: selectedValue,
      };
      dispatch(
        getItemsByItemGroupIdForJuteGovt(
          serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
          data,
          history,
          selectedRow.lineItemId
        )
      );
    }
    if (name === "itemId") {
      var ItemGroup = "";
      //this code is to check the item duplication.......
      if (Number(selectedRow.itemId) === Number(selectedValue)) {
        var checkItem = !!SoJuteGovtLineItemsData.find(
          ({ itemId }) => Number(itemId) === Number(selectedValue)
        );
      }
      if(!checkItem){
      let LineItemdata = SoJuteGovtLineItemsData.filter((row) => {
        if (row.lineItemId === selectedRow.lineItemId) {
          ItemGroup = row.itemGroupValue;
          row.itemId = selectedValue;
          row.itemValue = selectedValue;
          row.itemName = selectedName;
          row.itemCode = row.itemGroupValue + selectedItemCode;
          row.itemMake = "";
          row.remarks = "";
          row.make = "";
        }
        return row;
      });


      const data = {
        itemGroupId: ItemGroup,
        companyId: localStorage.getItem("companyId"),
        itemId: selectedValue,
        branchId: SoJuteGovtHeadersData.branchId,
      };
      dispatch(
        getJuteGovtSoItemNameData(
          serverApi.GET_ITEMDETAILS_BYITEM_ITEMGRP_COMPANYID,
          data,
          selectedRow.lineItemId
        )
      );
      SetSoJuteGovtLineItemsData(LineItemdata);
      dispatch(SalesOrderJuteGovtLineItems(LineItemdata));
      displayField(selectedRow, "itemId", 2);
    }
    let DiscType = SalesGstCalculations(
      {
        lineItems: SoJuteGovtLineItemsData,
        taxType: SoJuteGovtHeadersData.taxType,
      },
      "SALES_REGULAR_FLOW"
    );
    handleCalculation(DiscType);
  }
  };
  const handleCalculation = (Calculations) => {
    SetSoJuteGovtLineItemsData(Calculations.lineItems);
    dispatch(SalesOrderJuteGovtLineItems(Calculations.lineItems));
    let JuteGovtHeadersData = {
      ...SoJuteGovtHeadersData,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
    };
    dispatch(SalesOrderJuteGovtHeaders(JuteGovtHeadersData));
    setSoJuteGovtHeadersData((prev) => ({
      ...prev,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
    }));
  };

  const disabledForOthercharges = (row, col) => {
    if (row.itemGroupValue === "997") {
      if (col.type === "quantity") {
        return true;
      }
    }
  };
  const disabledInputForOT = (row, col) => {
    if (row.itemGroupValue === "997") {
      if (col.type === "itemGroupId") {
        return true;
      }
    }
  };

  let filterProduct = SoJuteGovtLineItemsData.filter(
    (item) => item.isActive !== 0
  );

  return (
    <>
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent">
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {tableHeaders.map((column) => (
                    <>
                      {column.header === "Item Group" ||
                      column.header === "Item Name" ||
                      column.header === "Rate" ||
                      column.header === "Quantity" ||
                      column.header === "UOM" ? (
                        <>
                          <th>
                            {column.header}
                            <label style={{ color: "red" }}>*</label>
                          </th>
                        </>
                      ) : (
                        <>
                          <th>{column.header}</th>
                        </>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filterProduct.length !== 0 &&
                  filterProduct.map((row, i) => {
                    let rowIndex = filterProduct.findIndex(
                      (idd) => idd === row
                    );
                    return (
                      <>
                        <tr>
                          <>
                            {RowData &&
                              RowData.map((col) =>
                                col.component === "Editablecell" ? (
                                  <>
                                    <td>
                                      <div className="quantityDropdownBlock">
                                        <Grid className="selectAndTextfield">
                                          <Grid
                                            xs={12}
                                            md={12}
                                            className="dynamicSelectBlock"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <input
                                              type={
                                                col.type !== "remarks" &&
                                                col.type !== "itemMake" &&
                                                col.type !== "hsncode" &&
                                                col.type !== "uom"
                                                  ? "number"
                                                  : "text"
                                              }
                                              name={col.type + "_" + rowIndex}
                                              disabled={
                                                col.type === "discountedRate" ||
                                                col.type === "hsncode" ||
                                                col.type === "uom" ||
                                                col.type === "netAmount" ||
                                                col.type === "taxPercent"
                                                  ? true
                                                  : disabledForOthercharges(
                                                      row,
                                                      col
                                                    )
                                              }
                                              value={
                                                col.type === "remarks"
                                                  ? row.remarks
                                                  : col.type === "itemMake"
                                                  ? row.itemMake
                                                  : col.type === "hsncode"
                                                  ? row.hsnCode
                                                  : col.type === "rate"
                                                  ? row.rate
                                                  : col.type === "discount"
                                                  ? row.discount
                                                  : col.type ===
                                                    "discountedRate"
                                                  ? row.discountedRate
                                                  : col.type === "quantity"
                                                  ? row.quantity
                                                  : col.type === "uom"
                                                  ? row.uom
                                                  : col.type === "taxPercent"
                                                  ? row.taxPercent
                                                  : col.type === "netAmount"
                                                  ? row.rate === "0.000"
                                                    ? "0.000"
                                                    : row.netAmount
                                                  : ""
                                              }
                                              onChange={OnhandleChange({
                                                key: col.type + "_" + rowIndex,
                                                row,
                                              })}
                                              className="inputBlockContainer"
                                              style={{
                                                backgroundColor:
                                                  col.type === "hsncode" ||
                                                  col.type === "uom" ||
                                                  col.type === "netAmount" ||
                                                  col.type === "taxPercent"
                                                    ? "#ccd6db"
                                                    : disabledForOthercharges(
                                                        row,
                                                        col
                                                      ) === true
                                                    ? "#ccd6db"
                                                    : "",
                                                textAlign:
                                                  col.type !== "remarks" &&
                                                  col.type !== "itemMake" &&
                                                  col.type !== "hsncode" &&
                                                  col.type !== "uom"
                                                    ? "right"
                                                    : "",
                                                paddingRight: "8px",
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </td>
                                  </>
                                ) : col.component === "DynamicSelect" ? (
                                  <td>
                                    <div className="quantityDropdownBlock">
                                      <Grid className="selectAndTextfield">
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: LineItemsDisplayTxtField[
                                              col.type + row.lineItemId + "1"
                                            ]
                                              ? "none"
                                              : "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={
                                              row.itemGroupId === null
                                                ? col.type === "itemId"
                                                  ? "Please Select Item Group"
                                                  : false
                                                : false
                                            }
                                          >
                                            <input
                                              type="text"
                                              className="inputBlockContainer"
                                              style={{
                                                paddingRight: "8px",
                                                backgroundColor:
                                                  disabledInputForOT(row, col)
                                                    ? "#ccd6db"
                                                    : "",
                                              }}
                                              value={
                                                col.type === "itemGroupId"
                                                  ? row.itemGroupName
                                                  : col.type === "itemId"
                                                  ? row.itemName
                                                  : col.type === "discountType"
                                                  ? row.discountName
                                                  : col.type === "UOM 2"
                                                  ? row.govtQuantityUom
                                                  : ""
                                              }
                                              onChange={OnhandleChange({
                                                key: col.type + "_" + rowIndex,
                                                row,
                                              })}
                                              onClick={() => {
                                                displayField(row, col.type, 1);
                                              }}
                                              disabled={
                                                row.itemGroupValue === "997"
                                                  ? col.type === "itemGroupId"
                                                    ? true
                                                    : row.itemGroupId === null
                                                    ? col.type === "itemId"
                                                      ? true
                                                      : false
                                                    : false
                                                  : false
                                              }
                                            />
                                          </Tooltip>
                                        </Grid>
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: LineItemsDisplayDropField[
                                              col.type + row.lineItemId + "2"
                                            ]
                                              ? "flex"
                                              : "none",
                                            alignItems: "center",
                                          }}
                                        >
                                          <DynamicSelect
                                            arrayOfData={
                                              col.type === "itemGroupId"
                                                ? ItemGroupData
                                                : col.type === "itemId"
                                                ? row.itemsListData
                                                : col.type === "discountType"
                                                ? discount_Type
                                                : ""
                                            }
                                            className="dropdownBlockContainer"
                                            onSelectChange={handleSelectChange}
                                            selected={
                                              col.type === "itemGroupId"
                                                ? row.itemGroupValue
                                                : col.type === "itemId"
                                                ? row.itemId
                                                : col.type === "discountType"
                                                ? row.discountType
                                                : ""
                                            }
                                            name={
                                              col.type === "itemGroupId"
                                                ? "itemGroupId"
                                                : col.type === "itemId"
                                                ? "itemId"
                                                : col.type === "discountType"
                                                ? "discountType"
                                                : ""
                                            }
                                            row={row}
                                            update={
                                              row.itemGroupValue || row.itemId
                                                ? 1
                                                : 0
                                            }
                                            isDisabled={
                                              row.itemGroupValue === "997"
                                                ? col.type === "itemGroupId"
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            isDropdownOpen={isDropdownOpen}
                                            handleMenuOpen={handleMenuOpen}
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </td>
                                ) : (
                                  ""
                                )
                              )}
                          </>
                          <td>
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="You Con't delete the Item"
                            >
                              <span>
                                <IconButton
                                  onClick={() => {
                                    onDel(row, i);
                                  }}
                                  disabled={true}
                                >
                                  <DeleteIcon className="deleteDisplayButton" />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="consoleFormContainer">
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 12">
                      <div
                        className="calculationBlockTable"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <table>
                          <tr>
                            <td>Taxable Value:</td>
                            <b>
                              {" "}
                              {parseFloat(
                                SoJuteGovtHeadersData.netTotal
                              ).toFixed(2)}
                            </b>
                          </tr>
                          <tr>
                            <td>Total IGST:</td>
                            <b>
                              {" "}
                              {parseFloat(
                                SoJuteGovtHeadersData.totalIGST
                              ).toFixed(2)}
                            </b>
                          </tr>
                          <tr>
                            <td>Total SGST:</td>
                            <td>
                              <b>
                                {parseFloat(
                                  SoJuteGovtHeadersData.totalSGST
                                ).toFixed(2)}
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>Total CGST:</td>
                            <td>
                              <b>
                                {parseFloat(
                                  SoJuteGovtHeadersData.totalCGST
                                ).toFixed(2)}
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>Total Value with Tax:</td>
                            <b>
                              {parseFloat(
                                SoJuteGovtHeadersData.TotalAmount
                              ).toFixed(2)}
                            </b>
                          </tr>
                        </table>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
          {locationState.state !== undefined ? (
            <div className="consoleFormContainer">
              <div style={{ height: "15px" }}></div>
              <div className="consoleFormBlock" style={{ display: "flex" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                      className="ducumentUploadBlock uploadedContent"
                    >
                      <Box gridColumn="span 10">
                        {salesOrderFiles?.length > 0 &&
                          salesOrderFiles.map((item) => (
                            <div
                              key={item.fileUploadId}
                              className="uploadedInfo"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                                padding: "5px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                              }}
                            >
                              <img
                                src={TickIcon}
                                alt=""
                                className="tickIcon"
                                height={"20px"}
                                width={"20px"}
                              />
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title="Click to download File"
                              >
                                <div
                                  className="uplodedFileName"
                                  onClick={() => {
                                    getFileDownload(
                                      serverConfig.SERVER_URL +
                                        "security-api/api/files/downloadfile/" +
                                        item.fileUploadId,
                                      item.fileName,
                                      item.fileExtension
                                    );
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                  }}
                                >
                                  {item.fileName}
                                </div>
                              </Tooltip>
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 100 }}
                                title="Click to Delete File"
                              >
                                <Button
                                  onClick={() => {
                                    onDeleteFile(item.fileUploadId);
                                  }}
                                  className="deleteButton"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <img
                                    src={DeleteIcons}
                                    alt=""
                                    height={"20px"}
                                    width={"20px"}
                                  />
                                </Button>
                              </Tooltip>
                            </div>
                          ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 4">
                        <div style={{ marginBottom: "10px" }}>
                          <b>Support Documents:</b>
                          <span style={{ color: "red" }}></span>
                        </div>
                        <div className="personalDetailsUpload">
                          <div className="ducumentUploadBlock">
                            <div className="documentUploadContent">
                              <span
                                className="btn_upload documentUpload"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={panUploadIcon}
                                  alt=""
                                  style={{ marginRight: "10px" }}
                                />
                                <input
                                  type="file"
                                  id="imag"
                                  title=""
                                  className="input-img"
                                  onChange={handleUpload}
                                />
                                Upload Document
                              </span>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "itemGroupId",
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "itemId",
  },
  {
    no: 3,
    component: "Editablecell",
    type: "remarks",
  },
  {
    no: 4,
    component: "Editablecell",
    type: "itemMake",
  },
  {
    no: 5,
    component: "Editablecell",
    type: "hsncode",
  },
  {
    no: 6,
    component: "Editablecell",
    type: "quantity",
  },

  {
    no: 7,
    component: "Editablecell",
    type: "uom",
  },
  {
    no: 8,
    component: "Editablecell",
    type: "rate",
  },
  {
    no: 9,
    component: "Editablecell",
    type: "taxPercent",
  },
  {
    no: 10,
    component: "Editablecell",
    type: "netAmount",
  },
];

const tableHeaders = [
  {
    header: "Item Group",
  },
  {
    header: "Item Name",
  },
  {
    header: "Remarks",
  },
  {
    header: "Make",
  },
  {
    header: "HSN",
  },
  {
    header: "Quantity",
  },
  {
    header: "UOM",
  },
  {
    header: "Rate",
  },
  {
    header: "Tax%",
  },
  {
    header: "Amount",
  },
  {
    header: "Actions",
  },
];
const discount_Type = [
  {
    value: 1,
    label: "Amount",
    name: "Amount",
  },
  {
    value: 2,
    label: "Percentage",
    name: "Percentage",
  },
];

export default JuteGovtLineItems;
