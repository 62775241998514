import { Component } from "react"
import { connect } from "react-redux"
import { serverApi } from "../../../helpers/Consts"
import { withRouter } from "react-router-dom";
import { getCustomerPaginatedList } from "../../../store/Master/CustomerMaster/actions"
import PaginationGridView from "../../../components/Grid/PaginationGridView"
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      totalRecs: '',
      startIndex: 1,
      lastIndex: 10,
      customerListData: [], //displaying customr list data
      dataexp:"",
    }
  }
  componentDidMount() {
   const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: this.state.userDit.cipher,
      startIndex: this.sytate.startIndex,
      lastIndex: this.state.lastIndex,
    };
    const expdata = data;
    data.startIndex = this.state.startIndex;
    data.lastIndex = this.state.lastIndex;
    this.props.getCustomerPaginatedList(serverApi.GET_ALL_CUSTOMER_PAGINATED_LIST, data, this.props.history);
    expdata.startIndex = 0;
    expdata.lastIndex = 0;
    this.setState({ loadingdata: true, dataexp: expdata });
  }
  componentWillReceive(props) {
    if (props.Customer_Paginated_List) {
      this.setState({
        totalRec: this.props.Customer_Paginated_List.totalRecords,
        customerListData: props.Customer_Paginated_List.data
      })
    }
  }
  render() {
    return (
      <PaginationGridView
        mdata={this.state.customerListData}
        mColumn={Columns}
        micon={GridArrowIcon}
        status={false}
        actions={true}
        filter_form={"CustomerMaster"}
        totalRecs={this.state.totalRecs}
        page={this.state.startIndex}
        sizePerPage={this.state.lastIndex}
        handlePagination={this.handlePagination}
        url="/create_Customer_Master"
        apiexp={serverApi.GET_ALL_UOM_LIST}
          payloadexp={this.state.dataexp}
          expName={"UOM-Master.csv"}
      />
    );
  }
}
const Columns = [
  {
    Header: "Customer Name",
    accessor: "name",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Contact No",
    accessor: "contactNo",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Email",
    accessor: "emailId",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
];
const mapStateProps = (state) => {
  const { Customer_Paginated_List } = state.CustomerListReducer;
  return { Customer_Paginated_List }
}
export default withRouter(
  connect(mapStateProps, {
    getCustomerPaginatedList,
  }
  )(Index)
)
