import {
  AGENT_NAME_LIST,
  AGENT_NAME_LIST_SUCCESSFULL,
  AGENT_LIST_DROPDOWN,
  AGENT_LIST_DROPDOWN_SUCCESSFULL,
  GET_BROKER_BY_SUPP_LIST,
  GET_BROKER_BY_SUPP_LIST_SUCCESSFULL,
  GET_QUALITY_BY_ITEM_LIST,
  GET_QUALITY_BY_ITEM_LIST_SUCCESSFULL,
  GET_SUPP_BY_MUKAM_LIST,
  GET_SUPP_BY_MUKAM_LIST_SUCCESSFULL,
  MUKAM_BY_ITEM_LIST,
  MUKAM_BY_ITEM_LIST_SUCCESSFULL,
  VEHICLE_TYPE_LIST,
  VEHICLE_TYPE_LIST_SUCCESSFULL,
  GET_PO_APPROVED_LIST,
  GET_PO_APPROVED_LIST_SUCCESSFULL,
  GET_PO_DETAILS_LIST,
  GET_PO_DETAILS_LIST_SUCCESSFULL,
  GET_VEHICLE_LIST,
  GET_VEHICLE_LIST_SUCCESSFULL,
  GET_GODOWN_NUMBER_LIST,
  GET_GODOWN_NUMBER_LIST_SUCCESSFULL,
  GET_PLAN_NAME_LIST,
  GET_PLAN_NAME_LIST_SUCCESSFULL,
} from "./actionTypes";

// AGENT NAME LIST
export const getAgentNameList = (url, data, history) => {
  return {
    type: AGENT_NAME_LIST,
    payload: { url, data, history },
  };
};
export const AgentNameListSuccessfull = (data) => {
  return {
    type: AGENT_NAME_LIST_SUCCESSFULL,
    payload: data,
  };
};
// AGENT LIST DROPDOWN
export const getAgentDropdownList = (url,data, history) => {
  return{
    type: AGENT_LIST_DROPDOWN,
    payload : { url, data, history},
  };
};
export const AgentListDropdownSuccessfull = (data) => {
  return {
    type: AGENT_LIST_DROPDOWN_SUCCESSFULL,
    payload: data,
  };
};
//Mukam By Item
export const getMukamByItemList = (url, data, history) => {
  return {
    type: MUKAM_BY_ITEM_LIST,
    payload: { url, data, history },
  };
};
export const getMukamByItemSuccessfull = (data) => {
  return {
    type: MUKAM_BY_ITEM_LIST_SUCCESSFULL,
    payload: data,
  };
};
//Vehicle Type
export const getVehicleTypeList = (url, data, history) => {
  return {
    type: VEHICLE_TYPE_LIST,
    payload: { url, data, history },
  };
};
export const getVehicleTypeSuccessfull = (data) => {
  return {
    type: VEHICLE_TYPE_LIST_SUCCESSFULL,
    payload: data,
  };
};
// Supplier by mukam id
export const getSupplierByMukamIdList = (url, data, history) => {
  return {
    type: GET_SUPP_BY_MUKAM_LIST,
    payload: { url, data, history },
  };
};
export const getSupplierByMukamIdSuccessfull = (data) => {
  return {
    type: GET_SUPP_BY_MUKAM_LIST_SUCCESSFULL,
    payload: data,
  };
};
// Broker by Supplier id
export const getBrokerBySupplierIdList = (url, data, history) => {
  return {
    type: GET_BROKER_BY_SUPP_LIST,
    payload: { url, data, history },
  };
};
export const getBrokerBySupplierIdListSuccessfull = (data) => {
  return {
    type: GET_BROKER_BY_SUPP_LIST_SUCCESSFULL,
    payload: data,
  };
};


// Quality by Item id
export const getQualityByItemList = (url, data, history) => {
  return {
    type: GET_QUALITY_BY_ITEM_LIST,
    payload: { url, data, history },
  };
};
export const getQualityByItemListSuccessfull = (data) => {
  return {
    type: GET_QUALITY_BY_ITEM_LIST_SUCCESSFULL,
    payload: data,
  };
};
// Get Apporved Po List
export const getPoDropdownList = (url, data, history) => {
  return {
    type: GET_PO_APPROVED_LIST,
    payload: { url, data, history },
  };
};
export const getPoDropdownListSuccessfull = (data) => {
  return {
    type: GET_PO_APPROVED_LIST_SUCCESSFULL,
    payload: data,
  };
};

// Get Apporved Po List
export const getPoDetailsList = (url, data, history) => {
  return {
    type: GET_PO_DETAILS_LIST,
    payload: { url, data, history },
  };
};
export const getPoDetailsListSuccessfull = (data) => {
  return {
    type: GET_PO_DETAILS_LIST_SUCCESSFULL,
    payload: data,
  };
};
// Get Vehicle List
export const getVehicleList = (url, data, history) => {
  return {
    type: GET_VEHICLE_LIST,
    payload: { url, data, history },
  };
};
export const getVehicleListSuccessfull = (data) => {
  return {
    type: GET_VEHICLE_LIST_SUCCESSFULL,
    payload: data,
  };
};
// Get Godown Number
export const getGodownNumberList = (url, data, history) => {
  return {
    type: GET_GODOWN_NUMBER_LIST,
    payload: { url, data, history },
  };
};
export const getGodownNumberSuccessfull = (data) => {
  return {
    type: GET_GODOWN_NUMBER_LIST_SUCCESSFULL,
    payload: data,
  };
};
// Get Plan name
export const getPlanNameList = (url, data, history) => {
  return {
    type: GET_PLAN_NAME_LIST,
    payload: { url, data, history },
  };
};
export const getPlanNameSuccessfull = (data) => {
  return {
    type: GET_PLAN_NAME_LIST_SUCCESSFULL,
    payload: data,
  };
};
