import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import { getPaymentList } from "../../../store/Accounting/Payments/actions";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      paymentListData: [],
      financialYear: localStorage.getItem("state"),
    };
  }

  componentDidMount() {
    const data = {
      acYear: "2022",
      companyId: this.state.companyId,
      fromDate: "",
      ledgerId: "",
      suppType: "",
      toDate: "",
      tranStatus: "",
      tranType: "P",
    };
    this.props.getPaymentList(serverApi.PAYMENT, data, this.props.history);
  }
  componentWillReceiveProps(props) {
    if (props.PaymentList) {
      this.setState({
        paymentListData: props.PaymentList.data,
      });
    }
  }
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.paymentListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          url="/create_payments"
        />
      </>
    );
  }
}
const Columns = [
  {
    Header: "TNR No",
    accessor: "transaUniqNo",
    hidden: false,
  },
  {
    Header: "Ledger",
    accessor: "ledgerName",
    hidden: false,
  },
  {
    Header: "Ledger Id",
    accessor: "ledgerId",
    hidden: true,
  },
  {
    Header: "Account",
    accessor: "accountName",
    hidden: false,
  },
  {
    Header: "Bank Id",
    accessor: "bankId",
    hidden: false,
  },
  {
    Header: "Description",
    accessor: "description",
    hidden: false,
  },
  {
    Header: "Bill No",
    accessor: "billNo",
    hidden: false,
  },
  {
    Header: "Date",
    accessor: "tranDate",
    hidden: false,
  },
  {
    Header: "Amount",
    accessor: "fundsOut",
    hidden: false,
  },
  {
    Header: "Due Amount",
    accessor: "dueAmount",
    hidden: false,
  },
  {
    Header: "Funds In",
    accessor: "fundsIn",
    hidden: true,
  },
  {
    Header: "Status",
    accessor: "status",
    hidden: false,
  },
  {
    Header: "Invoice No",
    accessor: "invoiceNo",
    hidden: true,
  },
  {
    Header: "Paid Amount",
    accessor: "paidAmount",
    hidden: true,
  },
  {
    Header: "Received Amount",
    accessor: "receivedAmount",
    hidden: true,
  },
  {
    Header: "Message",
    accessor: "message",
    hidden: true,
  },
  {
    Header: "Tally Sync",
    accessor: "tallySync",
    hidden: true,
  },
  {
    Header: "Tally Message",
    accessor: "tallyMessage",
    hidden: true,
  },
  {
    Header: "Transaction Id",
    accessor: "transactionId",
    hidden: true,
  },
  {
    Header: "Recept No",
    accessor: "rcptNo",
    hidden: true,
  },
];
const mapStatetoProps = (state) => {
  const { PaymentList } = state.PaymentReducer;
  return { PaymentList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getPaymentList,
  })(Index)
);
