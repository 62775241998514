import React, { Component } from "react";
import { Grid, Step, Stepper, StepLabel } from "@mui/material";
import Link from "@mui/material/Link";
import CustomButton from "../../../components/Buttons/Buttons";
import CreateExpenseFillDetails from "./CreateExpenseFillDetails";
import CreateExpenseAddItem from "./CreateExpenseAddItem";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getWorkerList,
  setExpenseBookingList,
  getExpenseBookingViewById,
} from "../../../store/HRMS/ExpenseBooking/actions";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import moment from "moment";
import { serverApi } from "../../../helpers/Consts";
import swal from "sweetalert";
import { validate } from "../../../Utilities/Validations";
class CreateExpense extends Component {
  constructor() {
    super();
    this.state = {
      steps: [
        {
          title: "Fill Details",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Add Items",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
      ],
      currentStep: 1,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      expenseBooking_ViewById: [],
      header: {
        acYear: localStorage.getItem("financialYear"),
        createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
        companyId: localStorage.getItem("companyId"),
        ebNo: JSON.parse(localStorage.getItem("authUser")).ebNo,
        ebId: "",
        deptId: "",
        name: "",
        department: "",
        designation: "",
        project: "",
        description: "",
        createDate: moment().format(),
        fromDate: "",
        toDate: "",
        total: 0,
        dueAmount: 0,
        paidAmount: 0,
        paidAmountshow: true,
        payNote: "",
        payDate: "",
      },
      products: [
        {
          // dtlId:0,
          // hdrId:0,
          id: 0,
          no: 0,
          amount: 0,
          costId: "",
          expenseDate: "",
          expenseNote: "",
          expenseType: "",
          quantity: 0,
          rate: 0,
          uom: 0,
          displayName: null,
          fileUploadId: null,
        },
      ],
      UpdateButton: false,
      ApproveButton: false,
      RejectButton: false,
    };
  }
  onclickOk() {
    this.setState({
      confirm: false,
    });
  }
  onClickNext = () => {
    // if (
    //   this.state.header.ebNo !== undefined &&
    //   this.state.header.ebNo !== "" &&
    //   this.state.header.project !== undefined &&
    //   this.state.header.project !== "" &&
    //   this.state.header.description !== undefined &&
    //   this.state.header.description !== "" &&
    //   this.state.header.fromDate !== undefined &&
    //   this.state.header.fromDate !== "" &&
    //   this.state.header.toDate !== undefined &&
    //   this.state.header.toDate !== ""
    // ) {
    if (this.state.header.project === "") {
      swal("Please Enter The Project");
      return false;
    }
    if (this.state.header.description === "") {
      swal("Please Enter The Description");
      return false;
    }
    if (
      this.state.header.fromDate === "" ||
      this.state.header.fromDate == null
    ) {
      swal("Please Enter The Expenses From");
      return false;
    }
    if (this.state.header.toDate === "" || this.state.header.toDate == null) {
      swal("Please Enter The Expenses To");
      return false;
    } else {
      const { currentStep } = this.state;
      this.setState({
        currentStep: currentStep + 1,
      });
    }
  };
  componentWillReceiveProps(props) {
    if (props.ExpenseBookingViewById) {
      this.setState({
        expenseBooking_ViewById: props.ExpenseBookingViewById,
      });

      if (this.props.location.state) {
        if (props.ExpenseBookingViewById) {
          if (props.ExpenseBookingViewById.exboDtl) {
            // let obj = {};
            let productData = props.ExpenseBookingViewById.exboDtl.map(
              (data) => {
                return {
                  amount: data.amount,
                  costId: data.costId,
                  costName: data.costName,
                  dtlId: data.dtlId,
                  expenseDate: data.expenseDate,
                  expenseDateDesc: data.expenseDateDesc,
                  expenseNote: data.expenseNote,
                  expenseType: data.expenseType,
                  hdrId: data.hdrId,
                  isActive: data.isActive,
                  quantity: data.quantity,
                  rate: data.rate,
                  uom: data.rate,
                  displayName: data.displayName,
                  fileUploadId: data.fileUploadId,
                };
              }
            );
            productData.push({
              // dtlId:0,
              // hdrId:0,
              id: 0,
              no: 0,
              amount: 0,
              costId: "",
              expenseDate: "",
              expenseNote: "",
              expenseType: "",
              quantity: 0,
              rate: 0,
              uom: 0,
              displayName: null,
              fileUploadId: null,
            });
            this.setState({
              products: productData,
            });
          }
        }
      }
    }
  }

  componentDidMount() {
    if (this.props.location.state) {
      if (this.props.location.state.rowData !== undefined) {
        this.state.header.paidAmountshow = false;

        this.props.getExpenseBookingViewById(
          serverApi.EXPENSE_BOOKING_VIEW_BYID +
            this.props.location.state.rowData.recId +
            "/" +
            this.state.userDit.userId,
          this.props.history
        );

        if (this.props.location.state.rowData.status === "1") {
          this.setState({
            RejectButton: true,
            UpdateButton: true,
            ApproveButton: true,
          });
        }
        if (
          this.props.location.state.rowData.status === "3" ||
          this.props.location.state.rowData.status === "4"
        ) {
          this.setState({
            RejectButton: false,
            UpdateButton: false,
            ApproveButton: false,
          });
        }
        // if (this.props.location.state.rowData.status === "4") {
        //   this.setState({
        //     RejectButton: false,
        //     UpdateButton: false,
        //     ApproveButton: false,
        //   });
        // }
      }
    }
  }
  onClickCreate = () => {
    let CreateDate = new Date(this.state.header.createDate).getTime();
    let expenseFromDate = new Date(this.state.header.fromDate).getTime();
    let expenseToDate = new Date(this.state.header.toDate).getTime();
    let lastRow = this.state.products[this.state.products.length - 1];
    var ValidateFields = false;
    if (this.state.products.length == 1) {
      this.state.products.map((item) => {
        if (item.expenseDate === "") {
          swal("Please Enter Expense Date");
          return false;
        } else if (item.expenseType === "") {
          swal("Please Enter Categories");
          return false;
        } else if (item.expenseNote === "") {
          swal("Please Enter Description");
          return false;
        } else if (item.amount === "") {
          swal("Please Enter the Amount");
          return false;
        } else {
          ValidateFields = true;
        }
      });
    } else {
      if (this.state.products.length != 0) {
        this.state.products.map((item) => {
          if (item !== lastRow) {
            if (item.expenseDate === "") {
              swal("Please Enter Expense Date");
              ValidateFields = false;
              return false;
            } else if (item.expenseType === "") {
              swal("Please Enter Categories");
              ValidateFields = false;
              return false;
            } else if (item.expenseNote === "") {
              swal("Please Enter Description");
              ValidateFields = false;
              return false;
            } else if (item.amount === "") {
              swal("Please Enter the Amount");
              ValidateFields = false;
              return false;
            } else {
              ValidateFields = true;
            }
          }
        });
      }
    }
    if (ValidateFields) {
      var filterProduct = this.state.products.filter((prop) => {
        if (prop.expenseDate !== "") {
          return prop;
        }
      });
      const data = {
        exboHdr: {
          project: this.state.header.project,
          createDate: CreateDate,
          description: this.state.header.description,
          total: this.state.header.total,
          payNote: "",
          payDate: "",
          ebId: this.state.header.ebId,
          department: "",
          paidAmount: this.state.header.paidAmount,
          fromDate: expenseFromDate,
          toDate: expenseToDate,
          acYear: localStorage.getItem("financialYear"),
          createdBy: this.state.userDit.userId,
          companyId: localStorage.getItem("companyId"),
        },
        exboDtl: filterProduct,
      };
      this.props.setExpenseBookingList(
        serverApi.ADD_EXPENSE_BOOKING,
        data,
        this.props.history
      );

      this.props.history.push("/expensebooking");
    }
  };
  onClickUpdate = (status) => {
    if (this.props.location.state) {
      let CreateDate = new Date(this.state.header.createDate).getTime();
      let expenseFromDate = new Date(this.state.header.fromDate).getTime();
      let expenseToDate = new Date(this.state.header.toDate).getTime();
      let lastRow = this.state.products[this.state.products.length - 1];
      var Validate2 = false;
      if (this.state.products.length === 1) {
        this.state.products.map((item) => {
          if (item.expenseDate == "") {
            swal("Please Enter Expense Date");
            return false;
          } else if (item.expenseType === "") {
            swal("Please Enter Categories ");
            return false;
          } else if (item.expenseNote === "") {
            swal("Please Enter Description");
            return false;
          } else if (item.amount === "") {
            swal("Please Enter the Amount");
            return false;
          } else {
            Validate2 = true;
          }
        });
      } else {
        for (var i = 0; i <= this.state.products.length - 1; i++) {
          if (this.state.products[i] !== lastRow) {
            if (this.state.products[i].expenseDate === "") {
              swal("Please Enter Expense Date");
              return false;
            }
            if (this.state.products[i].expenseType === "") {
              swal("Please Enter Categories");
              return false;
            }
            if (this.state.products[i].expenseNote == "") {
              swal("Please Enter Description");
              return false;
            }
            if (this.state.products[i].amount === "") {
              swal("Please Enter the Amount");
              return false;
            } else {
              Validate2 = true;
            }
          }
        }
      }
      if (Validate2) {
        var filterProduct = this.state.products.filter((prop) => {
          if (prop.expenseDate !== "") {
            return prop;
          }
        });
        const data = {
          exboHdr: {
            recId: this.props.location.state.rowData.recId,
            project: this.state.header.project,
            createDate: CreateDate,
            description: this.state.header.description,
            total: this.state.header.total,
            dueAmount: this.state.header.dueAmount,
            payNote: "",
            payDate: "",
            ebId: this.props.location.state.rowData.ebId,
            department: "",
            fromDate: expenseFromDate,
            toDate: expenseToDate,
            status: status === 1 ? "" : status,
            isActive: 1,
            paidAmount: parseFloat(this.state.header.paidAmount).toFixed(2),
            userId: this.props.location.state.rowData.createdBy,
            createdBy: this.props.location.state.rowData.createdBy,
            updatedBy: this.state.userDit.userId,
            updatedDateTime: 1664025131000,
            companyId: this.props.location.state.rowData.companyId,
          },
          exboDtl: filterProduct,
        };
        this.props.setExpenseBookingList(
          serverApi.UPDATE_EXPENSE_BOOKING,
          data,
          this.props.history
        );
      } else {
        swal("Please Enter All Mandatory Fields 3");
      }
    }
  };
  onClickBefore = () => {
    const { currentStep } = this.state;
    if (currentStep > 1) {
      this.setState({
        currentStep: currentStep - 1,
      });
    } else {
      this.props.history.push("/expensebooking");
    }
  };
  QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;

    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };
  delete = (filterProducts) => {
    this.setState({
      products: filterProducts,
    });
  };
  render() {
    const { steps, currentStep } = this.state;
    return (
      <div className="createExpenseBlock">
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            <div className="iconsBlock">
              <Stepper alternativeLabel activeStep={currentStep}>
                {steps.map((prop) => (
                  <Step key={prop.title}>
                    <StepLabel
                      StepIconComponent={(Iconprops) => {
                        return this.QontoStepIcon(
                          Iconprops,
                          prop.icon,
                          prop.activeIcon,
                          prop.title
                        );
                      }}
                    ></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {currentStep === 1 && (
              <>
                <CreateExpenseFillDetails
                  header={this.state.header}
                  ebNo={this.state.ebNo}
                  ViewById={this.state.expenseBooking_ViewById}
                  className="expenseBlock"
                />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="greenButton"
                    handleClick={this.onClickNext}
                    type="sumbit"
                  />
                  {this.state.confirm && (
                    <CommonPopup>
                      <div className="delAddItem">
                        <div className="mandatoryFieldText">
                          Please select all the mandatory fields
                        </div>
                        <div className="delAddItemBtns">
                          <input
                            type="button"
                            onClick={this.onclickOk.bind(this)}
                            value="Ok"
                            className="delYesBtn"
                          />
                        </div>
                      </div>
                    </CommonPopup>
                  )}
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                <CreateExpenseAddItem
                  header={this.state.header}
                  products={this.state.products}
                  ViewById={this.state.expenseBooking_ViewById}
                  delete={this.delete}
                  ApproveButton={this.state.ApproveButton}
                  RejectButton={this.state.RejectButton}
                />
                <div className="consoleFormButtonBlock">
                  {this.props.location.state === undefined && (
                    <>
                      <Link>
                        <CustomButton
                          label={"Cancel"}
                          className="greenBorderButton"
                          handleClick={this.onClickBefore}
                        />
                      </Link>
                      <Link to="/expense_booking">
                        <CustomButton
                          label={"Create"}
                          className="greenButton"
                          handleClick={this.onClickCreate}
                          type="sumbit"
                        />
                      </Link>
                    </>
                  )}
                  {this.state.UpdateButton && (
                    <>
                      <CustomButton
                        label={"Cancel"}
                        className="greenBorderButton"
                        handleClick={this.onClickBefore}
                      />

                      <CustomButton
                        label={"Update"}
                        className="greenButton"
                        handleClick={() => this.onClickUpdate(1)}
                      />
                    </>
                  )}
                  {this.state.ApproveButton && (
                    <CustomButton
                      label={"Approve"}
                      className="blueBorderButton"
                      handleClick={() => this.onClickUpdate(3)}
                    />
                  )}
                  {this.state.RejectButton && (
                    <CustomButton
                      label={"Reject"}
                      className="rejected"
                      handleClick={() => this.onClickUpdate(4)}
                    />
                  )}
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}
const mapStatetoProps = (state) => {
  const { worker_List, AddExpenseBooking, ExpenseBookingViewById } =
    state.ExpenseBookingReducer;

  return { worker_List, AddExpenseBooking, ExpenseBookingViewById };
};

export default withRouter(
  connect(mapStatetoProps, {
    getWorkerList,
    setExpenseBookingList,
    getExpenseBookingViewById,
  })(CreateExpense)
);
