import {
  LEDGER_MASTER_LIST,
  CREATE_LEDGER_MASTER,
  UPDATE_LEDGER_MASTER,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getList, postAdd } from "../../../helpers/Server_Helper";
import {
  ledgerMasterListSuccessfull,
  createLedgerMasterSuccessfull,
  updateLedgerMasterSuccessfull,
} from "./actions";
import swal from "sweetalert";

function* LedgerMasterList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(ledgerMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateLedgerMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createLedgerMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/Ledger_Master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateLedgerMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateLedgerMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/Ledger_Master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

export function* watchLedgerMasterList() {
  yield takeEvery(LEDGER_MASTER_LIST, LedgerMasterList);
}
export function* watchCreateLedgerMaster() {
  yield takeEvery(CREATE_LEDGER_MASTER, CreateLedgerMaster);
}
export function* watchUpdateLedgerMaster() {
  yield takeEvery(UPDATE_LEDGER_MASTER, UpdateLedgerMaster);
}

function* LedgerSaga() {
  yield all([
    fork(watchLedgerMasterList),
    fork(watchCreateLedgerMaster),
    fork(watchUpdateLedgerMaster),
  ]);
}

export default LedgerSaga;
