import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TabNavigation from "../../../components/TabsNavigation/TabsNavigation";
import ActiveApplication from "./ActiveTransporterApp";
import RejectedApplications from "./InActiveTransporterApp";

class ListOfTransporterMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alignment: "right",
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      employeeListColumn: [],
      total: "",
      Active: "",
      inActive: "",
      employeeListData: [],
    };
  }

  handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    this.setState({
      alignment: newAlignment,
    });
  };

  handleData = (id, rec) => {
    if (id === "1") {
      this.setState({
        total: rec,
      });
    }
    if (id === "2") {
      this.setState({
        Active: rec,
      });
    }
  };
  componentDidMount() {}
  render() {
    return (
      <>
        <div className="toggle-alignment"></div>
        <TabNavigation
          tabOneLabel={"Active Transporter"}
          tabTwoLabel={"Inactive Transporter"}
          tabOneContent={
            <ActiveApplication
              handleData={this.handleData}
              mcolumns={TransporterListColumn}
            />
          }
          tabTwoContent={
            <RejectedApplications
              handleData={this.handleData}
              mcolumns={TransporterListColumn}
            />
          }
        />
      </>
    );
  }
}

const TransporterListColumn = [
  {
    Header: "Transporter Id",
    accessor: "transporterId",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Transporter Name",
    accessor: "transporterName",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Transporter Address",
    accessor: "transporterAddress",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "State Code",
    accessor: "stateCode",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "State Name",
    accessor: "stateName",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Contact",
    accessor: "contact",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Email",
    accessor: "email",
    hidden: false,
    csvExport: true,
  },
];
const mapStatetoProps = (state) => {};

export default withRouter(
  connect(mapStatetoProps, {})(ListOfTransporterMaster)
);
