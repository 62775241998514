import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import DynamicSelect from "../../components/Dropdown/Master/DynamicSelectMasters"
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { serverApi } from "../../helpers/Consts";
import { getSupplierListMultiselect } from "../../store/Global/DropDownApis/actions";
import { getVehicleList } from "../../store/MasterDropdownApis/actions";
import TextFieldNormal from "../../components/TextField/TextFieldNormal";

class JuteQualityCheckFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      supplierListMultiselectData: [],
      VehicleListData: [],
      fromDate: moment().format(),
      toDate: moment().format(),
      supplier: "",
      supplierValue: "",
      vehicle: "",
      vehicleValue: "",
      gateEntryNum: "",
    };
  }

  componentDidMount() {
    this.props.getSupplierListMultiselect(
      serverApi.GET_SUPPLIER_LIST_MULTISELECT +
        localStorage.getItem("companyId"),
      this.props.history
    );
    this.props.getVehicleList(
      serverApi.GET_VEHICLE_DATA +
        localStorage.getItem("companyId") +
        "/" +
        this.state.userDit.cipher
    );
  }
  componentWillReceiveProps(props) {
    if (props.supplierListMultiselect) {
      this.setState({
        supplierListMultiselectData: props.supplierListMultiselect.data,
      });
    }
    if (props.VehicleData) {
      this.setState({
        VehicleListData: props.VehicleData.data,
      });
    }
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "Supplier") {
      this.setState({
        supplierValue: selectedValue,
        supplier: selectedName,
      });
    }
    if (name === "Vehicle") {
      this.setState({
        vehicleValue: selectedValue,
        vehicle: selectedName,
      });
    }
  };

  handleSelectDate = (e, name) => {
    if (name === "FromDate") {
      let date = moment(e).format();
      let formattedFromDate = date.split("T", 1).toString();

      this.setState({
        fromDate: formattedFromDate,
      });
    }

    if (name === "ToDate") {
      let date = moment(e).format();
      let formattedToDate = date.split("T", 1).toString();

      this.setState({
        toDate: formattedToDate,
      });
    }
  };

  handleSubmit = () => {
    var data = {
      frmDt: moment(this.state.fromDate).format("DD-MM-YYYY"),
      toDt: moment(this.state.toDate).format("DD-MM-YYYY"),
      gateEntryNum: this.state.gateEntryNum,
      supplier: this.state.supplier,
      supplierValue: this.state.supplierValue,
      vehicle: this.state.vehicle,
      vehicleValue: this.state.vehicleValue,
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleClear = () => {
    this.setState({
      supplier: "",
      supplierValue: "",
      vehicle: "",
      vehicleValue: "",
      fromDate: "",
      toDate: "",
    });
  };
  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "gateEntryNo") {
      this.setState({
        gateEntryNum: event,
      });
    }
  };
  render() {
    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid spacing={0}>
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
              </div>
            </div>
            <Grid item xs={12} className="filterBlockContainer">
              <Box gridColumn="span 3">
                <div className="filterDateBlock">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>From Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.fromDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "FromDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="fromDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>To Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.toDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "ToDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="toDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={this.state.supplierListMultiselectData}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handleSelectChange}
                    selected={this.state.supplierValue}
                    update={this.state.supplierValue ? 1 : 0}
                    name="Supplier"
                    label="Supplier"
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <TextFieldNormal
                    value={this.state.gateEntryNum}
                    placeholder={"Enter here"}
                    label="Gate Entry Number"
                    caps="OFF"
                    onChange={this.onhandlechange("gateEntryNo")}
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={this.state.VehicleListData}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handleSelectChange}
                    selected={this.state.vehicleValue}
                    update={this.state.vehicleValue ? 1 : 0}
                    name="Vehicle"
                    label="Vehicle"
                  />
                </div>
              </Box>

              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Search"}
                  className="greenButton"
                  handleClick={this.handleSubmit}
                  type="sumbit"
                />
              </div>
            </Grid>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  const { supplierListMultiselect } = state.DropDownListReducer;
  const { VehicleData } = state.MasterDropDownListReducer;
  return { supplierListMultiselect, VehicleData };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSupplierListMultiselect,
    getVehicleList,
  })(JuteQualityCheckFilter)
);
