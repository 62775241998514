import React, { Component } from "react";
import TextFieldNormal from "../../../../../components/TextField/TextFieldNormal";
import CustomButton from "../../../../../components/Buttons/Buttons";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import swal from "sweetalert";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import cons, { serverApi, getTenantId } from "../../../../../helpers/Consts";

import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CommonPopup from "../../../../../components/CommonPopup/CommonPopup";
import TextFieldEmail from "../../../../../components/TextField/TextFieldEmailReq";
import TextFieldMobile from "../../../../../components/TextField/TextFieldMobileReq";
import {
  viewbyidPersonalDetails,
  viewbyidPersonalDetailsSuccessfull,
  setPersonalDetails,
} from "../../../../../store/HRMS/EmployeeDataBase/actions";
import { makePayloaddata } from "../Makepayload.js";

class Onboarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ebId: localStorage.getItem("ebId"),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),

      disable: false,
      show: false,
      showPopup: false,
      ReOpen: false,
      toDisplayForms: JSON.parse(localStorage.getItem("toDisplayForms")),

      tblHrmsEdOfficialDetailId: "",
      ebId: this.props.getEbId,
      updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
      updatedTime: "",
      isActive: 1,
      departmentId: "",
      catagoryId: "",
      designationId: "",
      branchId: "",
      dateOfJoin: null,
      probationPeriod: "",
      minimumWorkingCommitment: "",
      reportingEbId: "",
      empCode: "",
      legacyCode: "",
      contractorId: "",
      officeMobileNo: "",
      officeMailId: "",
    };
  }

  handleSelectDate = (e, name) => {
    if (name === "DOJ") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();

      this.setState({
        dateOfJoin: Date,
      });
      var dateOfJoining = moment(Date, "YYYY-MM-DD", true).format("DD-MM-YYYY");
    }
  };
  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
  };

  componentWillReceiveProps(props) {
    var ebId = this.props.getEbId;
    if (ebId > 0) {
      if (props.personalDetails) {
        var personal = props.personalDetails.data;
        if (personal) {
          Object.keys(personal).map((key, index) => {
            this.setState({ [key]: personal[key] });
          });
        }
      }
    }
  }
  componentDidMount() {
    var ebId = this.props.getEbId;
    if (ebId > 0) {
      this.props.viewbyidPersonalDetails(
        serverApi.VIEW_EMPLOYEE_WORKER_DIT + "/" + this.props.getEbId,
        this.props.history,
        6
      );
    } else {
      const response = { data: null };
      this.props.viewbyidPersonalDetailsSuccessfull({ response });
    }
  }

  // Save Button
  onClickSave = () => {
    const data = {
      tblHrmsEdOfficialDetailId: this.state.tblHrmsEdOfficialDetailId,
      ebId: this.props.getEbId,
      updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
      isActive: 1,
      departmentId: this.state.departmentId,
      catagoryId: this.state.catagoryId,
      designationId: this.state.designationId,
      branchId: this.state.branchId,
      dateOfJoin: this.state.dateOfJoin,
      probationPeriod: this.state.probationPeriod,
      minimumWorkingCommitment: this.state.minimumWorkingCommitment,
      reportingEbId: this.state.reportingEbId,
      empCode: this.state.empCode,
      legacyCode: this.state.legacyCode,
      contractorId: this.state.contractorId,
      officeMobileNo: this.state.officeMobileNo,
      officeMailId: this.state.officeMailId,
    };

    if (data.dateOfJoin && data.officeMobileNo && data.officeMailId) {
      this.props.setPersonalDetails(
        serverApi.UPDATE_WORKER_DETAILS,
        data,
        this.props.history
      );
      this.setState({ handleNext: true });
    } else {
      swal("Enter all mandatory fields", { icon: "error" });
    }
  };

  DownloadAssetform = () => {
    fetch(serverApi.DOWNLOAD_ASSET_FORM, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        "X-TenantId": getTenantId(window.location.hostname),
        Authorization:
          localStorage.getItem("token_type") +
          localStorage.getItem("access_token"),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "AssetForm" + ".pdf");
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };
  DownloadWelcomeLetter = () => {
    if (localStorage.getItem("EmpData")) {
      var ebid = JSON.parse(localStorage.getItem("EmpData")).employeeData.ebId;
    } else {
      var ebid = localStorage.getItem("ebId");
    }
    fetch(serverApi.DOWNLOAD_WELCOME_LETTER + ebid, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        "X-TenantId": getTenantId(window.location.hostname),
        Authorization:
          localStorage.getItem("token_type") +
          localStorage.getItem("access_token"),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "WelcomeLetter" + ".pdf");
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };
  // Status For Create
  handleStatus = (status) => {
    const data = makePayloaddata(this.state);
    if (this.props.getEbId) {
      data.ebId = this.props.getEbId;
      data.dateOfJoining = this.state.dateOfJoining;
      data.onboarding = this.state.onboarding;
      data.empStatus = status;
    }
  };
  // Status For Update
  handleStatusUpdate = (status) => {
    const data = makePayloaddata(this.state);
    if (this.props.getEbId) {
      data.ebId = this.props.getEbId;
      data.dateOfJoining = this.state.dateOfJoining;
      data.onboarding = this.state.onboarding;
      data.empStatus = status;
    }

    this.props.setPersonalDetails(
      serverApi.ADD_EMPLOYEE,
      data,
      this.props.history
    );
  };
  onclickOk = () => {
    this.setState({
      showPopup: false,
    });
  };

  render() {
    return (
      <>
        <div className="onboardingContainerBlock">
          <div className="gridContainer">
            <div className="gridComponent">
              <table>
                <tr>
                  <th>Details</th>
                  <th>Description</th>
                </tr>
                <tr>
                  <td>Official Email Id</td>
                  <td>
                    <TextFieldNormal
                      disabled={true}
                      placeholder="abc@abc.com"
                      value={this.state.officeMailId}
                      onChange={this.onhandlechange("officeMailId")}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Date of Joining</td>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        disabled={true}
                        inputFormat="dd-MM-yyyy"
                        name="DOB"
                        value={this.state.dateOfJoin}
                        fullWidth
                        onChange={(e) => this.handleSelectDate(e, "DOJ")}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input
                              ref={inputRef}
                              {...inputProps}
                              readOnly={true}
                            />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                </tr>
                <tr>
                  <td>Official Mobile Number</td>
                  <td>
                    <TextFieldNormal
                      disabled={true}
                      placeholder="+91"
                      value={this.state.officeMobileNo}
                      onChange={this.onhandlechange("officeMobileNo")}
                    />
                  </td>
                </tr>
                {this.state.officeMailId !== undefined && (
                  <>
                    <tr>
                      <td>Welcome Letter</td>
                      <td>
                        <CustomButton
                          label={"Generate Welcome Letter"}
                          className="blueButton"
                          handleClick={this.DownloadWelcomeLetter}
                          disabled={this.state.disable}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Assets Handover Form</td>
                      <td>
                        <CustomButton
                          label={"Generate Asset Issue Form"}
                          className="blueButton"
                          handleClick={this.DownloadAssetform}
                          disabled={this.state.disable}
                        />
                      </td>
                    </tr>
                  </>
                )}
              </table>
            </div>
          </div>
          {this.state.showPopup && (
            <CommonPopup>
              <div className="delAddItem">
                <div>
                  Please Enter All Mandatory Fields Of Personal details{" "}
                </div>
                <div className="delAddItemBtns">
                  <input
                    type="button"
                    onClick={this.onclickOk.bind(this)}
                    value="Ok!"
                    className="delYesBtn"
                  />
                </div>
              </div>
            </CommonPopup>
          )}
        </div>
        <div className="consoleFormButtonBlock">
          {this.state.toDisplayForms ? (
            this.state.toDisplayForms.onBoardingCaseUpdate ? (
              <>
                <Link to="/add_employee">
                  {" "}
                  <CustomButton
                    label={"Cancel"}
                    className="greenBorderButton"
                  />{" "}
                </Link>
                <CustomButton
                  label={"Update"}
                  className="greenButton"
                  handleClick={this.onClickSave}
                  type="sumbit"
                />
              </>
            ) : (
              <>
                <Link to="/add_employee">
                  {" "}
                  <CustomButton
                    label={"Cancel"}
                    className="greenBorderButton"
                  />{" "}
                </Link>
              </>
            )
          ) : (
            <>
              <Link to="/add_employee">
                {" "}
                <CustomButton
                  label={"Cancel"}
                  className="greenBorderButton"
                />{" "}
              </Link>
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { personalDetails, approval_hierarchy, getEbId } =
    state.EmployeeReducer;
  return { personalDetails, approval_hierarchy, getEbId };
};

export default withRouter(
  connect(mapStatetoProps, {
    viewbyidPersonalDetailsSuccessfull,
    viewbyidPersonalDetails,
    setPersonalDetails,
  })(Onboarding)
);
