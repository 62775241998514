import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
// import DynamicSelect from "../Dropdown/DynamicSelect";
import DynamicSelect from "../../components/Dropdown/Master/DynamicSelectMasters";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextFieldAlfaNum from "../../components/TextField/TextFieldAlfaNum";
import { getProjectList } from "../../store/Global/DropDownApis/actions";
import { getCateogryTypeList } from "../../store/Global/DropDownApis/actions";
import { getStatusList } from "../../store/Global/DropDownApis/actions";
import { getSupplierList } from "../../store/Global/DropDownApis/actions";
import TextFieldNormal from "../TextField/TextFieldNormal";
import { serverApi } from "../../helpers/Consts";

class PrResponseFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      itemId: "",
      suppCode: '',
      PriceRequestId: "",
      PriceRequestIdValue: "",
      supplierListData: [],
    };
  }

  componentDidMount() {
    this.props.getSupplierList(
      serverApi.GET_ALL_SUPPLIERS_LIST + this.state.companyId,
      this.state.history
    );
  }

  componentWillReceiveProps(props) {
    if (props.supplierList) {
      let list=[]
    if (props.supplierList.data) {
      if (props.supplierList.data.length !==0) {
      props.supplierList.data.map(item=>{
        list.push({
          value:item.value,
          label:item.label,
          name:item.label
        })
      })
      this.setState({
        supplierListData: list,
      });
     } }}
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "Supplier") {
      this.setState({
        suppCode: selectedValue,
      });
    }
  };

 
  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
    // if (key === "PriceRequestId") {
    //   this.setState({
    //     PriceRequestId: event,
    //   });
    // }
    if (key === "itemId") {
      this.setState({
        itemId: event,
      });
    }
  };

  handleSubmit = () => {
    var data = {
      suppCode: this.state.suppCode,
      itemId: this.state.itemId,
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          // TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid
            container
            spacing={0}
            maxWidth="1000px"
            style={{ padding: "20px" }}
          >
            <div className="prResponseFilter">
              <Grid item xs={12}>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "20px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <FilterListOutlinedIcon />
                        <span>Filter</span>
                      </div>
                      {/* <div>Save View</div> */}
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      value={this.state.itemId}
                      placeholder={"Enter here"}
                      label="Item Code"
                      caps="OFF"
                      onChange={this.handleChange("itemId")}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.supplierListData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.suppCode}
                      name="Supplier"
                      label="Supplier"
                    />
                  </div>
                </Box>

                {/* <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldAlfaNum
                      value={this.state.PriceRequestId}
                      label="Price Request Id"
                      placeholder={"Enter here"}
                      caps="OFF"
                      onChange={this.handleChange("PriceRequestId")}
                    />
                  </div>
                </Box> */}
                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Search"}
                    className="greenButton"
                    handleClick={this.handleSubmit}
                    type="sumbit"
                  />
                </div>
              </Grid>
            </div>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  const { indentList, indentListColumns } = state.Indent;

  const { projectList, statusList, supplierList, CategoryTypeList } =
    state.DropDownListReducer;
  return {
    indentList,
    indentListColumns,
    projectList,
    statusList,
    supplierList,
    CategoryTypeList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getProjectList,
    getStatusList,
    getSupplierList,
    getCateogryTypeList,
  })(PrResponseFilter)
);
