import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";

import { getInwardList } from "../../../store/Purchase/Inward/actions";
import { getProjectList } from "../../../store/Global/DropDownApis/actions";
import Gridwithcustomview from "../../../components/Grid/PaginationGridView";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      projectListData: [],
      totalRecords: 0,
      startIndex: 0,
      lastIndex: 10,
      dataexp: "",
      startDate: "",
      endDate: "",
      customerId: "",
      name: "",
    };
  }

  componentDidMount() {
    const data = {
      indentType: "store",
      startDate: "",
      endDate: "",
      customerId: "",
      status: "",
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      financialYear: localStorage.getItem("acadamicYear"),
      erpType: "1",
      startIndex: 0,
      lastIndex: 10,
      name: "",
    };
    const data2 = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: data2 });
    this.props.getProjectList(
      serverApi.GET_ALL_PROJECTS,
      data,
      this.props.history
    );
  }
  componentWillReceiveProps(props) {
    if (props.projectList) {
      this.setState({
        projectListData: props.projectList.data,
        totalRecords: props.projectList.totalRecords,
      });
    }
  }
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;

    const data = {
      indentType: "store",
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      customerId: this.state.customerId,
      status: "",
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      financialYear: localStorage.getItem("acadamicYear"),
      erpType: "1",
      name: this.state.name,
    };
    if (type === 1) {
      data.startIndex = sizePerPage;
      data.lastIndex = currentIndex;
      if (currentIndex >= 0) {
        this.props.getProjectList(
          serverApi.GET_ALL_PROJECTS,
          data,
          this.props.history
        );
      }
    } else {
      data.startIndex = currentIndex;
      data.lastIndex = sizePerPage;
      if (currentIndex >= 0) {
        this.props.getProjectList(
          serverApi.GET_ALL_PROJECTS,
          data,
          this.props.history
        );
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  handleSearch = (data1) => {
    data1.startIndex = 0;
    data1.lastIndex = 10;
    this.props.getProjectList(
      serverApi.GET_ALL_PROJECTS,
      data1,
      this.props.history
    );
    this.setState({
      startDate: data1.startDate,
      endDate: data1.endDate,
      customerId: data1.customerId,
      name: data1.name,
    });
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          filter_form={"Project-List"}
          handleSearch={this.handleSearch}
          mdata={this.state.projectListData}
          mcolumns={columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          url="create_project_list"
          totalRecs={this.state.totalRecords}
          pageno={this.state.pageno}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          apiexp={serverApi.GET_ALL_PROJECTS}
          payloadexp={this.state.dataexp}
          expName={"ProjectList.csv"}
        />
      </>
    );
  }
}

const columns = [
  {
    filterable: "true",
    Header: "Project Code",
    accessor: "code",
    minWidth: 200,
    style: {
      textAlign: "center",
    },
    sortable: "true",
  },
  {
    filterable: "true",
    Header: "Project Name",
    accessor: "name",
    minWidth: 200,
    style: {
      textAlign: "center",
    },
    sortable: "true",
  },
  {
    filterable: "true",
    Header: "Start Date",
    accessor: "startDate",
    minWidth: 150,
    style: {
      textAlign: "center",
    },
    sortable: "true",
  },
  {
    filterable: "true",
    Header: "End Date",
    accessor: "expectedCloserDate",
    minWidth: 150,
    style: {
      textAlign: "center",
    },
    sortable: "true",
  },
  {
    filterable: "true",
    Header: "Customer",
    accessor: "customerName",
    minWidth: 150,
    style: {
      textAlign: "center",
    },
    sortable: "true",
  },
  {
    filterable: "true",
    Header: "Total Sqft",
    accessor: "sft",
    minWidth: 150,
    style: {
      textAlign: "center",
    },
    sortable: "true",
  },
];

const mapStatetoProps = (state) => {
  const { projectList } = state.DropDownListReducer;
  return { projectList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getInwardList,
    getProjectList,
  })(Index)
);
