import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import moment from "moment";
import {
  createVehicleTypesMaster,
  updateVehicleTypesMaster,
} from "../../../store/Master/VehicleTypesMaster/actions";
import swal from "sweetalert";
import { validate } from "../../../Utilities/Validations";

class CreateVehicleTypesMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      createdBy: "",
      vehicleType: "",
      weight: "",
      createdOn: "",
      id: "",
      createdDate: "",
      locationstate: "",
    };
  }
  /* Calling Create and Update Api's to Actions */
  onClickCreateUpdate = () => {
    const { vehicleType, weight } = this.state;

    const validateFields = [
      { field: vehicleType, msg: "Vehicle Type", type: 1 },
      { field: weight, msg: "Max Capacity   ", type: 1 },
    ];

    const validObj = validate(validateFields);

    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message} ..!!`);
      } else {
        swal(`Please Select ${validObj.message} ..!!`);
      }
    } else {
      if (this.props.location.state === undefined) {
        const data = {
          cipher: this.state.userDit.cipher,
          companyId: this.state.companyId,
          createdBy: this.state.userDit.userId.toString(),
          vehicleType: this.state.vehicleType,
          weight: this.state.weight,
        };
        this.props.createVehicleTypesMaster(
          serverApi.CREATE_VEHICLE_TYPES_MASTER,
          data,
          this.props.history
        ); // calling create vehicle types master api
      } else {
        const data = {
          cipher: this.state.userDit.cipher,
          createdBy: this.state.userDit.userId.toString(),
          companyId: this.state.companyId,
          createdOn: this.props.location.state.rowData.autoDateTime,
          id: this.state.id,
          vehicleType: this.state.vehicleType,
          weight: this.state.weight,
        };
        this.props.updateVehicleTypesMaster(
          serverApi.UPDATE_VEHICLE_TYPES_MASTER,
          data,
          this.props.history
        ); // calling update vehicle types master api
      }
    }
  };
  // function for previous page
  onClickCancel = () => {
    this.setState({
      vehicleType: "",
      weight: "",
    });
    this.props.history.push("/vehicle_types_master");
  };
  //function for TextFields
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "vehicleType") {
      this.setState({
        vehicleType: event,
      });
    }
    if (key === "weight") {
      this.setState({
        weight: event,
      });
    }
  };
  // function for popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }

  componentDidMount() {
    this.setState({
      locationstate:this.props.location
    })
    if (this.props.location.state) {
      this.setState({
        cipher: this.props.location.state.rowData.cipher,
        createdBy: this.props.location.state.rowData.createdBy,
        createdOn: this.props.location.state.rowData.autoDateTime,
        companyId: this.props.location.state.rowData.companyId,
        weight: this.props.location.state.rowData.weight,
        vehicleType: this.props.location.state.rowData.vehicleType,
        id: this.props.location.state.rowData.id,
      });
      const dateEntered = this.props.location.state.rowData.autoDateTime;
      const dateIsValid = moment(dateEntered).format("LLLL");
      this.setState({
        createdDate: dateIsValid,
      });
    }
  }

  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.vehicleType}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("vehicleType")}
                      label="Vehicle Type"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.weight}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("weight")}
                      label="Max Capacity"
                      disabled={this.state.vehicleType === "" ? true : false}
                    />
                  </Box>
                  {this.state.locationstate.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdDate}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.state.locationstate.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { UpdateVehicleTypesMaster } = state.VehicleTypesMasterReducer; // fetching create and update from Vehicle Types list reducer
  return { UpdateVehicleTypesMaster };
};

export default withRouter(
  connect(mapStatetoProps, {
    createVehicleTypesMaster,
    updateVehicleTypesMaster,
  })(CreateVehicleTypesMaster)
);
