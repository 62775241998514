import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Fullpageloader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: null,
    };
  }
  render() {
    return null;
  }
}

const mapStatetoProps = (state) => {
  return {
    ...state.EmployeeReducer,
  };
};

export default withRouter(connect(mapStatetoProps, {})(Fullpageloader));
