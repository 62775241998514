export const QUALITY_MASTER_LIST = "quality_master_list"; 
export const QUALITY_MASTER_LIST_SUCCESSFULL = "quality_master_list_successfull";
export const CREATE_QUALITY_MASTER = "create_quality_master";
export const CREATE_QUALITY_MASTER_SUCCESSFULL = "create_quality_master_successfull";
export const UPDATE_QUALITY_MASTER = "update_quality_master";
export const UPDATE_QUALITY_MASTER_SUCCESSFULL = "update_quality_master_successfull";
export const QUALITY_MASTER_VIEW_BY_ID = "quality_master_view_by_id";
export const QUALITY_MASTER_VIEW_BY_ID_SUCCESSFULL = "quality_master_view_by_id_successfull";
export const JUTE_QUALITIES_LIST='jute_qualities_list';
export const JUTE_QUALITIES_LIST_SUCCESSFULL='jute_qualities_list_successfull';
export const API_ERRORS = 'api_failed';