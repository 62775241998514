import React, { Component } from "react";
import { Grid, Box, Button, Tooltip, IconButton } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/DropdownSelectNew";
import { withRouter } from "react-router-dom";
import Fade from "@mui/material/Fade";
import { connect } from "react-redux";
import { serverApi ,serverConfig} from "../../../helpers/Consts";
import { getStateNameList ,getBranchList} from "../../../store/Global/DropDownApis/actions";
import { getLedgerGroupsList } from "../../../store/Master/LedgerGroupsMaster/actions";
import { getFiles } from "../../../store/Purchase/Indent/actions";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteFileIcon from "../../../assets/images/picEditIcon.png";
import { getFileDownload } from "../../../helpers/server.js";

import {
  SupplierLineItemsProps,
  getSuppFilesData,
  deleteSupplierMasterFiles,
  UploadSupplierMasterFiles,
  getSupplierGstVerification,
} from "../../../store/Master/SupplierMaster/actions";
import swal from "sweetalert";
import CheckIcon from "@mui/icons-material/Check";

class SupplierLineItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCount: 1,
      showGST: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      rowNo: 0,
      files_List: [],
      selectedGstFile: "",
      Supplier_LineItems: this.props.Supplier_LineItems,
      id: 1,
      selectedPanFile: "",
      supplierId: "",
      stateNameList: [],
      branchData:[],
      ledgerGroupsListData:[]

    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getStateNameList(
      serverApi.GET_STATE_NAME_LIST,
      this.props.history
    );
    this.props.getBranchList(
      serverApi.BRANCH_LIST +localStorage.getItem("companyId"),
      this.props.history
    );
    if (this.props.location.state) {
      if (this.props.location.state.rowData) {
        this.setState({
          supplierId: this.props.location.state.rowData.id,
        });
      }
    }

    if (this.props.location.state) {
      if (this.props.location.state.rowData) {
        let id = this.props.location.state.rowData.suppId;
        this.setState({
          supplierId: id,
        });
        this.props.getSuppFilesData(
          serverApi.GET_FILES +
            32 +
            "/" +
            id +
            "/" +
            localStorage.getItem("companyId"),
          this.props.history
        );
      }
    }

    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
    };
    this.props.getLedgerGroupsList(
      serverApi.GET_ALL_LEDGER_GROUPS_LIST,
      data,
      this.props.history
    );
  }

  componentWillReceiveProps(props) {
    // stateNameList
    if (props.stateNameList.data) {
      var list = [];
      list = props.stateNameList.data.filter((item) => {
        if (item.value !== 0) {
          return item;
        }
      });
      this.setState({
        stateNameList: list,
      });
    }

    if (props.GetAllFiles_Data) {
      if (props.GetAllFiles_Data.data) {
        let Supplier_LineItemsData = this.state.Supplier_LineItems;
        if (props.GetAllFiles_Data.data.length !== 0) {
          props.GetAllFiles_Data.data.map((data) => {
            Supplier_LineItemsData.map((item) => {
              if (data.subTaskUniqueId == item.autoId) {
                item.gstFilename = data.displayName;
                item.gstFileId = data.fileUploadId;
                item.gstFileExtension=data.fileExtension;
                item.gstRetrivePath=data.retrievalPath;
              } else if (
                data.subTaskUniqueId ==
                Number(item.autoId) + 1
              ) {
                item.BankFilename = data.displayName;
                item.BankFileId = data.fileUploadId;
                item.BankFileExtension=data.fileExtension;
                item.BankRetrivePath=data.retrievalPath;
              }
            });
          });
        } else {
          Supplier_LineItemsData.map((item) => {
            item.BankFilename = "";
            item.BankFileId = "";
            item.BankFileExtension="";
            item.BankRetrivePath="";
            item.gstFilename = "";
            item.gstFileId = "";
            item.gstFileExtension="";
            item.gstRetrivePath="";
          });
        }
      }
    }
    if (props.getSupplierGstStatus) {
      if (props.getSupplierGstStatus.data) {
        this.state.Supplier_LineItems.map((item) => {
          if (item.id == props.getSupplierGstStatus.id) {
            if (props.getSupplierGstStatus.data.status == true) {
              item.isGstVerified = 1;
            } else {
              item.isGstVerified = 2;
            }
          }
        }
        
        );
      }
    }
    if (props.branchList) {
      if (props.branchList.data) {
        if (props.branchList.data.length !== 0) {
          var List=[]
          var ListData = props.branchList.data.filter(
            (item) => item.value !== 0
          );
          if(ListData.length !==0){
            ListData.map(item=>{
              List.push({
                value:item.value,
                name:item.label,
                label:item.label
              })
            })
          }
          this.setState({
            branchData: List,
          });
        }
      }
    }
    if (props.ledgerGroupsList) {
      var List = []
      if(props.ledgerGroupsList.data){
        if(props.ledgerGroupsList.data.length !==0){
          props.ledgerGroupsList.data.map(item=>{
            List.push({
              value:item.id,
              name:item.name,
              label:item.name
            })
          })
        }
      }
      this.setState({
        ledgerGroupsListData:List
      });
    }
  }


  handleChangeGst = (key) => (event) => {
    this.setState({ [key]: event });
  };
  handleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    this.setState({ [key]: event });
    let mname = key.split("_");
    if (mname[0] === "branchAddress") {
      row.branchAddress = value;
      this.setState({
        branchAddress: value,
      });
    }
    if(mname[0]==="branchName"){
      row.branchName = value;
    }
    if (mname[0] === "city") {
      row.city = value;
      this.setState({
        city: value,
      });
    }
    if (mname[0] === "stateCode") {
      row.stateCode = value;
      this.setState({
        stateCode: value,
      });
    }
    if (mname[0] === "gstNo") {
      row.gstNo = value;
      this.setState({
        gstNo: value,
      });
    }
    if (mname[0] === "contactNo") {
      row.contactNo = value;
      this.setState({
        contactNo: value,
      });
    }
    if (mname[0] === "contactPerson") {
      row.contactPerson = value;
      this.setState({
        contactPerson: value,
      });
    }
    if (mname[0] === "email") {
      const val = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      row.email = value;
      this.setState({
        email: value,
      });
      if (val.test(value)) {
        row.emailValidation = false;
      } else {
        row.emailValidation = true;
      }
    }
    if (mname[0] === "accountNumber") {
      row.accountNumber = value;
      this.setState({
        accountNumber: value,
      });
    }
    if (mname[0] === "ifscCode") {
      row.ifscCode = value;
      this.setState({
        ifscCode: value,
      });
    }
    if (mname[0] === "ledgerName") {
      row.ledgerName = value;
      this.setState({
        ledgerName: value,
      });
    }
    if (mname[0] === "openningBalance") {
      // row.openningBalance = value;
      // this.setState({
      //   openningBalance: value,
      // });

      if (/^\d+$/.test(value) || value === "") {
        row.openningBalance = value;
        this.setState({
          openningBalance: value,
        });

        var TotalOpeningBalance = this.state.Supplier_LineItems.filter(
          (item) => item.isActive != 0
        )
          .map((p) => Number(p.openningBalance))
          .reduce((total, ind) => parseFloat(total) + parseFloat(ind));
        this.props.SupplierfillDetails.totalOpeningBalance =
          TotalOpeningBalance;
      }
    }
    
  };
  //function for dropdown
  handleChangedropdown = (selectedValue, selectedName, name, selectedRow) => {
    this.setState({
      rowCount: this.state.rowCount + 1,
    });
    let lastRow =
      this.state.Supplier_LineItems[this.state.Supplier_LineItems.length - 1];

    if (name === "stateName") {
      if (selectedRow === lastRow) {
        this.handleAddEvent();
      }
      let stateCode = selectedName.split("^");
      selectedRow.stateCode = stateCode[1];
      selectedRow.stateName = stateCode[0];
      selectedRow.gstNo = stateCode[1];
      this.setState({
        stateName: stateCode[0],
        stateCode: stateCode[1],
        gstNo: stateCode[1],
      });
    }
if(name === "ledgerGroupId"){
  selectedRow.ledgerGroupId = selectedValue;
  selectedRow.ledgerGroupDesc = selectedName;
}

  };
  onDeleteFile = (fileId) => {
    this.props.deleteSupplierMasterFiles(serverApi.DELETEFILE + fileId);
  };
  onDelLineItem = (row) => {
    var lastRow =
      this.state.Supplier_LineItems[this.state.Supplier_LineItems.length - 1];
    if (lastRow === row) {
      swal("you can't delete the empty lineitem");
    } else {
      let indexItem = this.state.Supplier_LineItems.findIndex(
        (item) => item === row
      );
      let lineItems = this.state.Supplier_LineItems;
      lineItems[indexItem].isActive = 0;
      this.setState({
        Supplier_LineItems: lineItems,
      });

      var TotalOpeningBalance = this.state.Supplier_LineItems.filter(
        (item) => item.isActive != 0
      )
        .map((p) => Number(p.openningBalance))
        .reduce((total, ind) => parseFloat(total) + parseFloat(ind));
      this.props.SupplierfillDetails.totalOpeningBalance = TotalOpeningBalance;
    }
  };
  handleselectedFile = (value) => (e) => {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData !== undefined) {
        e.preventDefault();
        let file = e.target.files[0];
        let fileExt = file.name.split(".").pop();
        const formData = new FormData();
        formData.append("fileName", file.name);
        formData.append("fileUpload", file);
        formData.append("fileExtension", fileExt);
        formData.append("sourceMenu", 32);
        formData.append("displayName", file.name);
        formData.append("taskUniqueId", this.state.supplierId);
        formData.append("createdBy", this.state.userDit.userId);
        formData.append("fileCompanyId", this.state.companyId);
        formData.append("subTaskUniqueId", value);
        this.setState({
          fileName: file.name,
          fileExtension: file.name.split(".").pop(),
        });
        this.props.UploadSupplierMasterFiles(
          serverApi.UPLOADFILE,
          formData,
          this.state.supplierId,
          this.props.history
        );
        this.state.Supplier_LineItems.map((item) => {
          if (item.autoId == value) {
            item.gstFilename = e.target.files[0].name;
          }
        });
      }
    }
  };
  handleDeleteFile = (fileUploadId, value, autoId) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.state.Supplier_LineItems.map((item) => {
          if (item.autoId === autoId) {
            item.gstFilename = "";
            item.gstFileId = "";
            item.gstFileExtension="";
            item.gstRetrivePath="";
          }
          if (
            item.autoId.toString() + item.autoId.toString() ==
            autoId.toString() + autoId.toString()
          ) {
            item.BankFilename = "";
            item.BankFileId = "";
            item.BankFileExtension="";
            item.BankRetrivePath="";
          }
        });

        if (this.props.location.state !== undefined) {
          if (this.props.location.state.rowData !== undefined) {
            this.props.deleteSupplierMasterFiles(
              serverApi.DELETE_FILE_BI_ID + fileUploadId,
              this.state.supplierId,
              this.props.history
            );
          }
        }
      } else {
        swal("Your action is canceled!");
      }
    });
    //
  };
  handleAddEvent = (data) => {
    let rowId = this.state.rowCount;
    let product = {
      accountNumber: "",
      autoId: "",
      branchAddress: "",
      branchId: "",
      branchName:"",
      city: "",
      contactNo: "",
      contactPerson: "",
      createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
      email: "",
      gstNo: "",
      id: this.state.Supplier_LineItems.length+1,
      ifscCode: "",
      isActive: 1,
      openningBalance: "",
      rcCopy: "",
      stateCode: "",
      stateName: "",
      isGstVerified:2,
      ledgerGroupId: null,
      ledgerGroupDesc: null,
      ledgerName: null,
    };
    this.setState({ rowCount: rowId + 1 });
    this.state.Supplier_LineItems.push(product);
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  OnClickVerify = (row) => {
    this.props.getSupplierGstVerification(
      serverApi.GST_VERIFICATION_API + row.gstNo,
      row.id,
      this.props.history
    );
  };
  

  render() {
    var tableHeadersList = tableHeaders.map((column) => {
      if (this.props.location.state !== undefined) {
        return column;
      } else {
        if (column.header !== "Upload File" 
        // && column.header !== "Ledger Name"
         ) {
          return column;
        }
      }
    });
    tableHeadersList = tableHeadersList.filter((item) => item !== undefined);
    var Supplier_LineItem = this.state.Supplier_LineItems.filter(
      (item) => item.isActive !== 0
    );
  let lastLineItem = this.state.Supplier_LineItems[this.state.Supplier_LineItems.length - 1];
    return (
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent" onScroll={this.handleScroll}>
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {tableHeadersList.map((column) => (
                    <>
                      {column.header === "Branch Address" ||
                      column.header === "State" ||
                      column.header === "Contact Number" ||
                      column.header === "Email" ||
                      column.header === "Opening Balance" || 
                      column.header === "Branch Name"
                      ? (
                        <th>
                          {column.header}
                          <span style={{ color: "red" }}>*</span>
                        </th>
                      ) : (
                        <th>{column.header}</th>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Supplier_LineItem.map((row) => {
                  let rowIndex = Supplier_LineItem.findIndex(
                    (idd) => idd == row
                  );
                  return (
                    <tr>
                      <>
                        {RowData  &&
                          RowData.map((col) =>
                            col.component === "Inputadorment" ? (
                              <>
                                <td>
                                  <div className="quantityDropdownBlock">
                                    <Grid className="selectAndTextfield">
                                      <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        className="dynamicSelectBlock"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          name={col.type + "_" + rowIndex}
                                          id={col.no}
                                          disabled
                                          Value={
                                            col.type === "stateCode"
                                              ? row.stateCode
                                              : ""
                                          }
                                          onChange={this.handleChange({
                                            key: col.type,
                                            row,
                                          })}
                                          className="inputBlockContainer"
                                          style={{
                                            textAlign:
                                              col.type === "stateCode"
                                                ? "right"
                                                : "",
                                            paddingRight: "8px",
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </div>
                                </td>
                              </>
                            ) : col.component === "EditableCell" ? (
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        type={
                                          col.type === "contactNo"
                                            ? "Number"
                                            : "text"
                                            ? col.type === "accountNumber"
                                              ? "Number"
                                              : "text"
                                            : ""
                                        }
                                        onKeyDown={
                                          col.contactNo
                                            ? (e) =>
                                                ["e", "E", "-"].includes(
                                                  e.key
                                                ) && e.preventDefault()
                                            : ""
                                        }
                                        name={col.type}
                                        id={col.no}
                                        value={
                                          col.type === "branchAddress"
                                            ? row.branchAddress
                                            : col.type === "city"
                                            ? row.city
                                            : col.type === "contactNo"
                                            ? row.contactNo
                                            : col.type === "contactPerson"
                                            ? row.contactPerson
                                            : col.type === "email"
                                            ? row.email
                                            : col.type === "accountNumber"
                                            ? row.accountNumber
                                            : col.type === "ifscCode"
                                            ? row.ifscCode
                                            : col.type === "ledgerName"
                                            ? row.ledgerName 
                                            : col.type === "openningBalance"
                                            ? row.openningBalance
                                            :col.type==="branchName"
                                           ?row.branchName
                                            : ""
                                        }
                                        onChange={this.handleChange({
                                          key: col.type + "_" + rowIndex,
                                          row,
                                        })}
                                        className="inputBlockContainer"
                                        style={{
                                          textAlign:
                                            col.type === "stateCode" ||
                                            col.type === "openningBalance"
                                              ? "right"
                                              : "",
                                          paddingRight: "8px",
                                          backgroundColor:
                                          col.type === "branchAddress"
                                          ? ""
                                          : col.type === "city"
                                          ? ""
                                          : col.type === "contactNo"
                                          ? ""
                                          : col.type === "contactPerson"
                                          ? ""
                                          : col.type === "email"
                                          ? ""
                                          : col.type === "accountNumber"
                                          ? ""
                                          : col.type === "ifscCode"
                                          ? ""
                                          : col.type === "ledgerName"
                                          ?  "#ccd6db"
                                          : col.type === "openningBalance"
                                          ? ""
                                          :col.type==="branchName"
                                          ? ""
                                          : ""
                                        }}
                                        disabled={
                                          col.type === "branchAddress"
                                          ? false
                                          : col.type === "city"
                                          ? false
                                          : col.type === "contactNo"
                                          ? false
                                          : col.type === "contactPerson"
                                          ? false
                                          : col.type === "email"
                                          ? false
                                          : col.type === "accountNumber"
                                          ? false
                                          : col.type === "ifscCode"
                                          ? false
                                          : col.type === "ledgerName"
                                          ?  true
                                          :col.type==="branchName"
                                          ? false
                                          : col.type === "openningBalance"
                                          ? false
                                          : false
                                        }
                                      />
                                    </Grid>
                                    {col.type === "email" &&
                                      row.emailValidation == true && (
                                        <span style={{ color: "red" }}>
                                          Invalid Email
                                        </span>
                                      )}
                                  </Grid>
                                </div>
                              </td>
                            ) : col.component === "TextFieldGstVerify" ? (
                              <td>
                                <div className="verificationContainer">
                                  {/* <div className="incDecContent"> */}
                                    <input
                                      className="inputBlockContainer"
                                      value={row.gstNo}
                                      onChange={this.handleChange({
                                        key: col.type + "_" + rowIndex,
                                        row,
                                      })}
                                      name={col.type}
                                      id={col.no}
                                    />
                                    {row.isGstVerified ==1 ? (
                                      <button className="verifiedBlock">
                                        <CheckIcon style={{ color: "green" }} />
                                        <span
                                          style={{
                                            margin: "0px",
                                            marginRight: "5px",
                                          }}
                                        >
                                          Verified
                                        </span>
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() => this.OnClickVerify(row)}
                                        className="verifiedBlock"
                                      >
                                        verify
                                      </button>
                                    )}
                                  {/* </div> */}
                                </div>
                              </td>
                            ) : col.type === "files" ? (
                              <td>
                                <div className="d-flex cloumnButtonBlock">
                                  <Box gridColumn="span 3">
                                    <div className="personalDetailsUpload">
                                      <div className="ducumentUploadBlock">
                                        <div className="documentUploadContent">
                                          {row.gstFilename === "" ||
                                          row.gstFilename === undefined ? (
                                            <span
                                              className={
                                                row.autoId !== ""
                                                  ? "btn_upload documentUpload "
                                                  : "btn_upload documentUpload disabled"
                                              }
                                            >
                                              <img src={panUploadIcon} alt="" />
                                              <input
                                                type="file"
                                                id="imag"
                                                disabled={
                                                  row.autoId !== ""
                                                    ? false
                                                    : true
                                                }
                                                title=""
                                                className="input-img"
                                                onChange={this.handleselectedFile(
                                                  row.autoId
                                                )}
                                              />
                                              Upload Document
                                            </span>
                                          ) : (
                                            ""
                                          )}

                                          {row.gstFilename !== "" &&
                                            row.gstFilename !== undefined && (
                                              <div className="uploadedInfo">
                                                <img
                                                  src={TickIcon}
                                                  alt=""
                                                  className="tickIcon"
                                                />
                                                 <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Click to download File"
                            >
                              <div
                                onClick={() => {
                                  getFileDownload(
                                    serverConfig.SERVER_URL +
                                    "security-api/api/files/downloadfile/" +
                                    row.gstFileId,
                                    row.gstFilename,
                                    row.gstFileExtension
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {row.gstFilename}
                              </div>

                            </Tooltip>                                            
                                                <Button
                                                  onClick={() =>
                                                    this.handleDeleteFile(
                                                      row.gstFileId,
                                                      row.suppId,
                                                      row.autoId
                                                    )
                                                  }
                                                  className="deleteButton"
                                                >
                                                  <img
                                                    src={DeleteFileIcon}
                                                    alt=""
                                                  />
                                                </Button>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </Box>
                                </div>
                              </td>
                            ) : col.type === "Bankfiles" ? (
                              <td>
                                <div className="d-flex cloumnButtonBlock">
                                  <Box gridColumn="span 3">
                                    <div className="personalDetailsUpload">
                                      <div className="ducumentUploadBlock">
                                        <div className="documentUploadContent">
                                          {row.BankFilename === "" ||
                                          row.BankFilename === undefined ? (
                                            <span
                                              className={
                                                row.autoId !== ""
                                                  ? "btn_upload documentUpload "
                                                  : "btn_upload documentUpload disabled"
                                              }
                                            >
                                              <img src={panUploadIcon} alt="" />
                                              <input
                                                type="file"
                                                id="imag"
                                                disabled={
                                                  row.autoId !== ""
                                                    ? false
                                                    : true
                                                }
                                                title=""
                                                className="input-img"
                                                onChange={this.handleselectedFile(
                                                  Number(row.autoId) + 1
                                                )}
                                              />
                                              Upload Cancelled Cheque
                                            </span>
                                          ) : (
                                            ""
                                          )}

                                          {row.BankFilename !== "" &&
                                            row.BankFilename !== undefined && (
                                              <div className="uploadedInfo">
                                                <img
                                                  src={TickIcon}
                                                  alt=""
                                                  className="tickIcon"
                                                />
                                                  <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Click to download File"
                            >
                              <div
                                onClick={() => {
                                  getFileDownload(
                                    serverConfig.SERVER_URL +
                                    "security-api/api/files/downloadfile/" +
                                    row.BankFileId,
                                    row.BankFilename,
                                    row.BankFileExtension,
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {row.BankFilename}
                              </div>
                            </Tooltip>           
                                                <Button
                                                  onClick={() =>
                                                    this.handleDeleteFile(
                                                      row.BankFileId,
                                                      row.suppId,
                                                      row.autoId
                                                    )
                                                  }
                                                  className="deleteButton"
                                                >
                                                  <img
                                                    src={DeleteFileIcon}
                                                    alt=""
                                                  />
                                                </Button>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </Box>
                                </div>
                              </td>
                            ) : (
                              col.component === "DynamicSelect" && (
                                <td>
                                  <div className="quantityDropdownBlock">
                                    <Grid className="selectAndTextfield">
                                      <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        className="dynamicSelectBlock"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <DynamicSelect
                                          arrayOfData={ 
                                            col.type == "stateName" ? this.state.stateNameList
                                       
                                          : col.type =="ledgerGroupId" ? this.state.ledgerGroupsListData 
                                          :""
                                          }
                                          className="dropdownBlockContainer"
                                          onSelectChange={
                                            this.handleChangedropdown
                                          }
                                          selected={ 
                                            col.type == "stateName" ?row.stateName
                                        
                                          : col.type == "ledgerGroupId" ? row.ledgerGroupDesc
                                          :""
                                          }
                                          isDropdownOpen={
                                            this.state.isDropdownOpen
                                          }
                                          handleMenuOpen={this.handleMenuOpen}
                                          name={  
                                            col.type == "stateName" ?"stateName"

                                          : col.type == "ledgerGroupId" ? "ledgerGroupId"
                                          :""}
                                          row={row}
                                          update={
                                            col.type == "stateName" ?row.stateName?1:0:
                                           col.type == "ledgerGroupId" ? row.ledgerGroupDesc?1:0:
                                            ""
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  </div>
                                </td>
                              )
                            )
                          )}
                      </>
                      <td className="">
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title="Delete"
                        >
                          <IconButton
                            onClick={() => {
                              this.onDelLineItem(row);
                            }}
                            disabled={
                              Supplier_LineItem.length === 1
                                ? true
                                : lastLineItem === row
                                ? true
                                : false
                            }
                          >
                            <DeleteIcon className="deleteDisplayButton" />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
const RowData = [
  
 
  {
    no: 1,
    component: "DynamicSelect",
    type: "stateName",
    dropDown: "dropDown",
  },
  {
    no: 2,
    component: "EditableCell",
    type: "branchName",
  },
  {
    no: 3,
    component: "EditableCell",
    type: "branchAddress",
  },
  {
    no: 4,
    component: "EditableCell",
    type: "city",
  },

  {
    no: 5,
    component: "Inputadorment",
    type: "stateCode",
  },
  {
    no: 6,
    component: "TextFieldGstVerify",
    type: "gstNo",
  },
  {
    no: 7,
    component: "uploaddocs",
    type: "files",
  },
  {
    no: 8,
    component: "EditableCell",
    type: "contactNo",
  },
  {
    no: 9,
    component: "EditableCell",
    type: "contactPerson",
  },
  {
    no: 10,
    component: "EditableCell",
    type: "email",
  },
  {
    no: 11,
    component: "EditableCell",
    type: "accountNumber",
  },
  {
    no: 12,
    component: "uploaddocs",
    type: "Bankfiles",
  },
  {
    no: 13,
    component: "EditableCell",
    type: "ifscCode",
  },
  {
    no: 14,
    component: "DynamicSelect",
    type: "ledgerGroupId",
  },
  {
    no: 14,
    component: "EditableCell",
    type: "ledgerName",
  },
  {
    no: 14,
    component: "EditableCell",
    type: "openningBalance",
  },
];
const tableHeaders = [
  {
    header: "State",
  },
  {
    header: "Branch Name",
  },
  {
    header: "Branch Address",
  },
  {
    header: "City",
  },
  {
    header: "State Code",
  },
  {
    header: "GST Number",
  },
  {
    header: "Upload GST File",
  },
  {
    header: "Contact Number",
  },
  {
    header: "Contact Person",
  },
  {
    header: "Email",
  },
  {
    header: "Account Number",
  },
  {
    header: "Upload Bank Statement",
  },
  {
    header: "IFSC Code",
  },
  {
    header:"Ledger Group"
  },
  {
    header:"Ledger Name"
  },
  {
    header: "Opening Balance",
  },
  {
    header: "Actions",
  },
];

const mapStatetoProps = (state) => {
  const { stateNameList,branchList,ledgerGroupsList } = state.DropDownListReducer;
  const { Upload_File_List } = state.UploadFileReducer;
  const { GetAllFiles_Data, getSupplierGstStatus } = state.Supplier_List;
  const { files_List } = state.IndentReducer;
  // const {getSupplierGstStatus}=state.SupplierListReducer;
  return {
    stateNameList,
    Upload_File_List,
    files_List,
    GetAllFiles_Data,
    getSupplierGstStatus,
    branchList,
    ledgerGroupsList
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getStateNameList,
    getFiles,
    deleteSupplierMasterFiles,
    UploadSupplierMasterFiles,
    SupplierLineItemsProps,
    getSuppFilesData,
    getSupplierGstVerification,
    getBranchList,
    getLedgerGroupsList
  })(SupplierLineItems)
);
