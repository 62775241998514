import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/Buttons/Buttons";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi, getTenantId } from "../../../helpers/Consts";
import { getIndentPdf } from "../../../store/Purchase/Indent/actions";
import { getFileDownload } from "../../../helpers/server.js";
import { InwardLineItemsProps } from "../../../store/Purchase/Inward/actions";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import moment from "moment";

const JuteBillPassPreview = (props) => {
  const [HeaderData, setHeaderData] = useState({});
  const [LineItems, setLineItems] = useState([]);
  const [date, setDate] = useState(moment().format());
  var DateTime = date.split("T", 2);
  var Time = DateTime[1].split("+")[0];
  var TodayDate = moment(DateTime[0], "YYYY-MM-DD", true).format("DD-MM-YYYY");

  const [companyLogo, setCompanyLogo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [source, setSource] = useState("");
  const [Inward_LineItems, setInward_LineItems] = useState([]);
  const [RowData, setRowData] = useState("");
  const [supplierName, setsupplierName] = useState("");
  const [SequenceNo, setSequenceNo] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [gstNo, setGstNo] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [phoneNo, setphoneNo] = useState("");
  const [email, setemail] = useState("");

  const convertNumberToWords = (amount) => {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();

    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = "";
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  };

  const handleEdit = () => {
    props.handleCurrentStep(1);
  };

  useEffect(() => {
    let companyData = JSON.parse(localStorage.getItem("authUser"));
    let companyId = localStorage.getItem("companyId");
    companyData.companyDetails.map((p) => {
      let id = p.value;
      if (companyId === id.toString()) {
        setCompanyName(p.label);
        setCompanyLogo(p.companyLogo);
        setphoneNo(p.contactNo);
        setemail(p.emailId);
        setaddress1(p.address1);
        setaddress2(p.address2);
        setGstNo(p.gstNo);
      }
    });
  }, []);

  const { JuteBillPassHeader, billpasslineItems } = useSelector(
    (state) => state.JuteBillPassReducer
  );

  useEffect(() => {
    setLineItems(billpasslineItems);
    setHeaderData(JuteBillPassHeader);
  }, [JuteBillPassHeader, billpasslineItems]);

  return (
    <>
      <div className="previewBlock">
        <div
          style={{ display: "flex", justifyContent: "end", padding: "10px" }}
        >
          <CustomButton
            handleClick={handleEdit}
            label="Edit"
            type="button"
            className="lightBlueButton"
            muIcon={<EditIcon />}
          />
        </div>
        <table
          cellpadding="0"
          cellspacing="0"
          width="90%"
          className="previewTable"
        >
          <tbody>
            <tr>
              <td
                colspan="3"
                style={{ borderRight: "none", borderBottom: "none" }}
              >
                <img src={companyLogo} width="100%" alt="" />
              </td>
              <td
                colspan="10"
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  textAlign: "right",
                }}
              >
                <p style={{ margin: "0px" }}> {companyName}</p>
                <p style={{ margin: "0px" }}>{address1}</p>
                <span>GST Number:{gstNo}</span>
              </td>
            </tr>
            <tr>
              <td
                colspan="20"
                style={{ textAlign: "center", borderTop: "none" }}
              >
                <h2>JUTE BILL PASS</h2>
              </td>
            </tr>
            <tr>
              <td colspan="1">
                <b>Bill Pass Number</b>
              </td>
              <td colspan="4">
                <span>{HeaderData.billPassNo}</span>
              </td>
              <td colspan="1">
                <b>Bill Pass Date</b>
              </td>
              <td colspan="6">
                <span>{HeaderData.billpassDate}</span>
              </td>
            </tr>
            <tr>
              <td colspan="1">
                <b>MR No:</b>
              </td>
              <td colspan="4">
                <span>{HeaderData.mrPrintNo}</span>
              </td>
              <td colspan="1">
                <b>Gate Entry No:</b>
              </td>
              <td colspan="6">
                <span>{HeaderData.gateEntry}</span>
              </td>
            </tr>

            <tr>
              <td colspan="1">
                <b>PO Number:</b>
              </td>
              <td colspan="4">
                <span>{HeaderData.poNum}</span>
              </td>
              <td colspan="1">
                <b>PO Date: </b>
              </td>
              <td colspan="6">
                <span>{HeaderData.poDate}</span>
              </td>
            </tr>

            <tr>
              <td colspan="1">
                <b>Challan Number:</b>
              </td>
              <td colspan="4">
                <span>{HeaderData.chalanNo}</span>
              </td>
              <td colspan="1">
                <b>Challan Date: </b>
              </td>
              <td colspan="6">
                <span>{HeaderData.chalanDate}</span>
              </td>
            </tr>
            <tr>
              <td colspan="1">
                <b>Agent: </b>

              </td>
              <td colspan="4">
                <span>{HeaderData.agentName}</span>
              </td>
              <td colspan="1">
                <b>Vehicle No: </b>
              </td>
              <td colspan="6">
                <span>{HeaderData.vehicleNo}</span>
              </td>
            </tr>
            {/* {this.props.location.pathname === "/Create_store_receipt" ? (
                ""
              ) : ( */}
            <tr>
              <td colspan="1">
                <b>Supplier</b>
              </td>
              <td colspan="11">
                <span>{HeaderData.supplierName}</span>
              </td>
            </tr>

            <tr align="center" valign="top">
              <td>
                <b>Item </b>
              </td>
              <td width={"15%"}>
                <b>Quality</b>
              </td>
              <td>
                <b>Quantity</b>
              </td>
              <td>
                <b>Claim Rate</b>
              </td>
              <td>
                <b>Weight</b>
              </td>
              <td>
                <b>UOM</b>
              </td>
              <td>
                <b>Warehouse No</b>
              </td>
              <td>
                <b>Rate</b>
              </td>
              <td>
                <b>Amount</b>
              </td>
              <td>
                <b>Prem Amt</b>
              </td>
              <td>
                <b>Water Dmg Amount</b>
              </td>
            </tr>

            {billpasslineItems.map((data, index) => (
              <tr key={index}>
                <td>{data.itemDesc}</td>
                <td>{data.qualityDesc}</td>
                <td>{data.quantity}</td>
                <td style={{ textAlign: "right" }}> {data.claimRate}</td>
                <td align="right">{data.weight}</td>
                <td align="right">{data.uom}</td>
                <td>{data.warehouseName}</td>
                <td>{data.rate}</td>
                <td align="right">{parseFloat(data.amount).toFixed(2)}</td>
                <td align="right"> {data.premiumAmount}</td>
                <td align="right"> {data.waterDamageAmount}</td>
              </tr>
            ))}

            <tr>
              <td colSpan={2}>
                <b>Total</b>
              </td>
              <td colSpan={10} align="right">
                <b> {parseFloat(HeaderData.totalAmount).toFixed(2)}</b>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>Claim Amount</b>
              </td>
              <td colSpan={10}>
                <b> {parseFloat(HeaderData.claimAmount).toFixed(2)}</b>
              </td>
            </tr>

            <tr>
              <td colSpan={2}>
                <b>TCS Amount</b>
              </td>
              <td colSpan={10}>
                <b> {parseFloat(HeaderData.tcsAmount).toFixed(2)}</b>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>TDS Amount</b>
              </td>
              <td colSpan={10}>
                <b> {parseFloat(Number(HeaderData.tdsAmount)).toFixed(2)}</b>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>Net Total</b>
              </td>
              <td colSpan={10}>
                <b>{parseFloat(HeaderData.netAmount).toFixed(2)}</b>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>Freight Charges</b>
              </td>
              <td colSpan={10}>
                <b> {parseFloat(HeaderData.frieghtCharges).toFixed(2)}</b>
              </td>
            </tr>
            <tr>
              <td
                colspan="10"
                style={{
                  borderTop: "none",
                  borderBottom: "none",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                ></div>
              </td>
            </tr>
            <tr></tr>
            <tr>
              <td
                colspan="13"
                style={{ borderTop: "none", borderBottom: "none" }}
              >
                <b>
                  Note : This is a computer generated print, Signature is not
                  required.{" "}
                </b>
              </td>
            </tr>
            <tr>
              <td
                colspan="6"
                style={{ borderTop: "none", borderRight: "none" }}
              >
                Printed on: {TodayDate} {Time}
              </td>
              <td
                colspan="7"
                align="right"
                style={{ borderTop: "none", borderLeft: "none" }}
              >
                Printed By: {userDit.userName}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
export default JuteBillPassPreview;
