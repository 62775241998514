import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Grid, Box } from '@mui/material'
import TextFieldReq from '../../../components/TextField/TextFieldReq'

class CostPlanFillDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
        Project_Name:'',
    }
  }

  handlePropSelectChange = (selectedValue, selectedName, name) => {}

  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                   <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq label="Project Name"  required />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}
const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {},
)(withRouter(CostPlanFillDetails))
