import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import {
  ITEM_LISTS,
  CREATE_ITEM_MASTER,
  UPDATE_ITEM_MASTER,
  ITEM_CODE_BY_ITEM_GROUP,
  INDENT_TYPE_LIST_MASTERS,
  CREATE_LEAVE_TYPE_MASTER,
  GET_ITEM_CODE_BY_ITEM_GROUP,
  GET_UOM_IN_ITEM_MASTER,
  GET_ITEM_MASTER_VIEW_BY_ID,
} from "./actionTypes";
import {
  ItemListSuccessfull,
  createItemMasterSuccessfull,
  updateItemMasterSuccessfull,
  ItemCodeByItemGroupSuccessfull,
  getIndentListMasterSuccessfull,
  createLeaveTypeMasterSuccessfull,
  getItemByItemCodeSuccessfull,
  getUomInItemMasterSuccessfull,
  getItemMasterViewByIdSuccessfull,
} from "./actions";
import { getList, postAdd, getListurl } from "../../../helpers/Server_Helper";
import swal from "sweetalert";
import { hideSpinner, showSpinner } from "../../../helpers/Consts";

function* getItemListData({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(ItemListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateItemMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createItemMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/item_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* createLeaveTypeMasters({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    if (response.data.status === true) {
      yield put(createLeaveTypeMasterSuccessfull({ response }));
      swal(response.data.message, { icon: "success" });
      history.push("/leave_types");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    swal("error:" + error, { icon: "danger" });
  }
}
function* UpdateItemMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateItemMasterSuccessfull({ response }));
    if (response.data.message === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/item_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
      }
    }
  }
}
function* getItemListByItemGroup({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(ItemCodeByItemGroupSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getIndentListMasters({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getIndentListMasterSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getItemByCodeSaga({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getItemByItemCodeSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getUomInItemMasterSaga({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getUomInItemMasterSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getItemMasterViewByIdSaga({ payload: { url, history } }) {
  try {
    showSpinner();
    const response = yield call(getListurl, url);
    if (response) {
      hideSpinner();
      yield put(getItemMasterViewByIdSuccessfull({ response }));
    }
  } catch (error) {
    hideSpinner();
    console.log(error);
  }
}

export function* watchItemList() {
  yield takeEvery(ITEM_LISTS, getItemListData);
  yield takeEvery(ITEM_CODE_BY_ITEM_GROUP, getItemListByItemGroup);
  yield takeEvery(CREATE_ITEM_MASTER, CreateItemMaster);
  yield takeEvery(UPDATE_ITEM_MASTER, UpdateItemMaster);
  yield takeEvery(INDENT_TYPE_LIST_MASTERS, getIndentListMasters);
  yield takeEvery(CREATE_LEAVE_TYPE_MASTER, createLeaveTypeMasters);
  yield takeEvery(GET_ITEM_CODE_BY_ITEM_GROUP, getItemByCodeSaga);
  yield takeEvery(GET_UOM_IN_ITEM_MASTER, getUomInItemMasterSaga);
  yield takeEvery(GET_ITEM_MASTER_VIEW_BY_ID, getItemMasterViewByIdSaga);
}

function* ItemMasterListSaga() {
  yield all([fork(watchItemList)]);
}

export default ItemMasterListSaga;
