import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import { machineMasterList } from "../../../store/Master/MachineMaster/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      startIndex: 1,
      lastIndex: 10,
      totalRecs: 0,
      machineMasterListData: [], // to display list data
      machineName: "",
      departmentName: "",
      machinetype: "",
      dataexp:"",
    };
  }

  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
      machineName: null,
      departmentName: null,
      machinetype: null,
    };
    this.props.machineMasterList(
      serverApi.GET_ALL_MACHINE_MASTER_LIST,
      data,
      this.props.history
    ); // calling Machine master list API
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ loading:true, dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.MachineMasterList) {
      this.setState({
        totalRecs: props.MachineMasterList.totalRecords,
        machineMasterListData: props.MachineMasterList.data, // updating machine master list data
      });
    }
  }
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const data = {
        companyId: localStorage.getItem("companyId"),
        startIndex: sizePerPage,
        lastIndex: currentIndex,
        machineName: this.state.machineName==''?null:this.state.machineName,
        departmentName: this.state.departmentName==''?null:this.state.departmentName,
        machinetype: this.state.machinetype==''?null:this.state.machinetype,
      };
      if (currentIndex >= 0) {
        this.props.machineMasterList(
          serverApi.GET_ALL_MACHINE_MASTER_LIST,
          data,
          this.props.history
        );
      }
    } else {
      const data = {
        companyId: localStorage.getItem("companyId"),
        startIndex: currentIndex ==0? currentIndex+1:currentIndex,
        lastIndex: sizePerPage,
        machineName: this.state.machineName==''?null:this.state.machineName,
        departmentName: this.state.departmentName==''?null:this.state.departmentName,
        machinetype: this.state.machinetype==''?null:this.state.machinetype,
      };
      if (currentIndex >= 0) {
        this.props.machineMasterList(
          serverApi.GET_ALL_MACHINE_MASTER_LIST,
          data,
          this.props.history
        );
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  handleSearch = (data) => {
    
    const searchData = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      machineName: data.machineName==''?null:data.machineName,
      departmentName: data.department==''?null:data.department,
      machinetype: data.machinetype==''?null:data.machinetype,
      startIndex: 1,
      lastIndex: 10,
    };
    this.setState({
      machineName: data.machineName==''?null:data.machineName,
      departmentName: data.department==''?null:data.department,
      machinetype: data.machinetype==''?null:data.machinetype
    })
    this.props.machineMasterList(
      serverApi.GET_ALL_MACHINE_MASTER_LIST,
      searchData,
      this.props.history
    ); // calling Machine master list API
  };

  render() {
    return (
      <>
        <PaginationGridView
          mdata={this.state.machineMasterListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          filter_form={"MachineMaster"}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          url="/create_machine_master"
          handleSearch={this.handleSearch}
          apiexp={serverApi.GET_ALL_MACHINE_MASTER_LIST}
          payloadexp={this.state.dataexp}
          expName={"Machine-Master.csv"}
        />
      </>
    );
  }
}
// Machine master list Headers
const Columns = [
  // {
  //   Header: "Machine Id",
  //   accessor: "machineId",
  //   hidden: false,
  // },
  {
    Header: "Machine Name",
    accessor: "machineName",
    hidden: false,
  },
  {
    Header: "Department",
    accessor: "department",
    hidden: false,
  },
  {
    Header: "Machine Type",
    accessor: "machineType",
    hidden: false,
  },
  {
    Header: "Data Code",
    accessor: "machineShrCode",
    hidden: false,
  },
];

const mapStatetoProps = (state) => {
  const { MachineMasterList } = state.MachineMasterReducer; // fetching list from machine master reducer
  return { MachineMasterList };
};

export default withRouter(
  connect(mapStatetoProps, {
    machineMasterList,
  })(Index)
);
