import React, { Component } from "react";
export default class ViewPaySlip extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="payslipContainerBlock">
        <p>Nellimarla Jute Mills</p>
        <div>
          <h1>Pay Slip for December'21</h1>
        </div>
        <div className="payslipGridContainer">
          <div className="payslipProfileInfoContainer">
            <div className="profileInfoContainerBlock">
              <div className="gridContainer">
                <div className="gridComponent">
                  <table>
                    <tr>
                      <td>Transaction No.</td>
                      <td>276456</td>
                    </tr>
                    <tr>
                      <td>Account Name</td>
                      <td>Snehadeep Goli</td>
                    </tr>
                    <tr>
                      <td>Account Number</td>
                      <td>00000199299292</td>
                    </tr>
                    <tr>
                      <td>Bank</td>
                      <td>Bank of Baroda</td>
                    </tr>
                    <tr>
                      <td>Location</td>
                      <td>Bangalore</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="profileInfoWorkingDaysContainerBlock">
              <div className="gridContainer">
                <div className="gridComponent">
                  <table>
                    <tr>
                      <td>Working Days</td>
                      <td>30</td>
                    </tr>
                    <tr>
                      <td>LOP</td>
                      <td>0</td>
                    </tr>
                    <tr>
                      <td>PF no.</td>
                      <td>IHK3839000</td>
                    </tr>
                    <tr>
                      <td>Date of Joining</td>
                      <td>23rd Dec'21</td>
                    </tr>
                    <tr>
                      <td>Department</td>
                      <td>Software</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="CommonTableContainerBlock">
            <div className="gridContainer">
              <div className="gridComponent">
                <table>
                  <thead>
                    <tr>
                      <th>Earnings</th>
                      <th>Amount (₹)</th>
                      <th>Deductions</th>
                      <th>Amount (₹)</th>
                    </tr>
                  </thead>
                  <tr>
                    <td>Basic</td>
                    <td>₹ 75,400</td>
                    <td>Provident Fund</td>
                    <td>₹ 5,400</td>
                  </tr>
                  <tr>
                    <td>House Rent Allowance</td>
                    <td>₹ 5,400</td>
                    <td>Professional Tax</td>
                    <td>₹ 830</td>
                  </tr>
                  <tr>
                    <td>Travel Allowance</td>
                    <td>₹ 1,400</td>
                    <td>Income Tax</td>
                    <td>₹ 830</td>
                  </tr>
                  <tr>
                    <td>Bonus</td>
                    <td>₹ 10,800</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr className="grossEarningsBlock">
                    <td>GROSS EARNINGS</td>
                    <td>₹ 41,200</td>
                    <td>GROSS DEDUCTIONS</td>
                    <td>₹ 7,530</td>
                  </tr>
                  <tr className="grossEarningsBlock">
                    <td>NET PAY</td>
                    <td colSpan={3}>₹ 3,50,000</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
