import {
  JUTE_BILL_PASS_LIST,
  JUTE_BILL_PASS_VIEW_BY_ID,
  GET_FILES_OF_BILLPASS,
  UPDATE_JUTE_BILL_PASS,
  INVOICE_UPLOAD_FILES,
  GET_FILES_OF_INVOICE,
  GET_INVOICE_FILES,
  GET_SUPPORT_FILES,
  GET_CHALLAN_FILES,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getList, getListurl, postAdd } from "../../../helpers/Server_Helper";
import {
  JuteBillPassListSuccessfull,
  JuteBillPassViewByIdActionSUccessfull,
  getBillPassLineItems,
  // getFilesSuccessfull,
  getSuppdocsFilesSuccessfull,
  getChallanFilesSuccessfull,
  getInvoiceFilesSuccessfull,
  updateJuteBillpassSuccessfull,
  getJuteBillPassHeader,
  UploadInvoiceFilesSuccessfull,
  getInvoiceFiles,
  getSupportFilesSuccessfull,
  UploadSupportFilesSuccessfull,
  UploadChallanFilesSuccessfull,
  getSupportFiles,
  getChallanFiles,
} from "./actions";
import swal from "sweetalert";
import { serverApi } from "../../../helpers/Consts";

function* JuteBillPassSagaList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(JuteBillPassListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* updateJuteBillpassSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(updateJuteBillpassSuccessfull({ response }));
    if (response) {
      if (response.status === true) {
        swal(response.message, { icon: "success" });
      } else {
        swal(response.message, { icon: "error" });
      }
      history.push("/jute_bill_pass");
    }
  } catch (error) {
    console.log(error);
  }
}

function* JuteBillPassViewByIdSagaList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getListurl, url, data);
    yield put(JuteBillPassViewByIdActionSUccessfull({ response }));

    if (response) {
      if (response.data) {
        if (response.data.mrLineItems) {
          if (response.data.mrLineItems.length !== 0) {
            let billpassLineitemsData = [];
            let totalAmount = 0; // Initialize total amount
            response.data.mrLineItems.map((item, i) => {
              const itemAmount = item.weight * item.rate;
              totalAmount += itemAmount; // Accumulate total amount
              billpassLineitemsData.push({
                id: 1,
                itemCode: item.itemCode,
                itemDesc: item.itemDesc,
                qualityId: item.qualityId,
                qualityDesc: item.qualityDesc,
                weight: item.weight,
                uom: item.uom,
                quantity: item.quantity,
                wareHouseNo: item.wareHouseNo,
                rate: item.rate,
                totalPrice: item.totalPrice,
                warehouseName: item.warehouseName,
                claimRate: item.claimRate,
                hsnCode: item.hsnCode,
                premiumAmount: item.premiumAmount,
                waterDamageAmount: item.waterDamageAmount,
                amount: itemAmount,
              });
            });
            yield put(getBillPassLineItems(billpassLineitemsData));

            var ApproveButton = false;
            var RejectButton = false;
            var UpdateButton = true;
            var EditButton = false;

            if (response.data.approveStatus === true) {
              if (
                response.data.status === "1" ||
                response.data.status === "17" ||
                response.data.status === "18" ||
                response.data.status === "19" ||
                response.data.status === "20"
              ) {
                ApproveButton = true;
                RejectButton = true;
                UpdateButton = true;
                EditButton = true;
              }
            } else if (
              response.data.status === "1" ||
              response.data.status === "17" ||
              response.data.status === "18" ||
              response.data.status === "19" ||
              response.data.status === "20"
            ) {
              UpdateButton = true;
              EditButton = true;
            }

            if (
              response.data.status === "4" ||
              response.data.status === "3" ||
              response.data.status === "5"
            ) {
              ApproveButton = false;
              RejectButton = false;
              UpdateButton = false;
              EditButton = false;
            }
            const Header = {
              hdrId: response.data.hdrId,
              id: response.data.id,
              type: response.data.type,
              billPassNo: response.data.billPassNo,
              billDate: response.data.billDate,
              supplierCode: response.data.supplierCode,
              mrNo: response.data.mrNo,
              statusDesc: response.data.statusDesc,
              status: response.data.status,
              approveStatus: true,
              poNum: response.data.poNum,
              poDate: response.data.poDate,
              chalanNo: response.data.chalanNo,
              chalanDate: response.data.chalanDate,
              agentName: response.data.agentName,
              vehicleNo: response.data.vehicleNo,
              gateEntry: response.data.grnNo,
              billpassDate: response.data.billpassDate,
              agentId: response.data.agentId,
              srNo: response.data.srNo,
              srMrDate: response.data.srMrDate,
              ledgerGroupDesc: response.data.ledgerGroupDesc,
              srLineItems: response.data.srLineItems,
              billDocsUrl: response.data.billDocsUrl,
              billChallanUrl: response.data.billChallanUrl,
              invoiceNo: response.data.invoiceNo,
              invoiceDate: response.data.invoiceDate,
              paymentDueDate: response.data.paymentDueDate,
              invoiceAmount: response.data.invoiceAmount,
              invoiceRecievedDate: response.data.invoiceRecievedDate,
              companyId: response.data.companyId,
              frieghtCharges: response.data.frieghtCharges,
              netAmount: response.data.netAmount,
              claimAmount: response.data.claimAmount,
              supplierName: response.data.supplierName,
              ledgerName: response.data.ledgerName,
              srPrintNo: response.data.srPrintNo,
              gateEntrySeq: response.data.gateEntrySeq,
              branchId: response.data.branchId,
              tdsPayable: response.data.tdsPayable,
              tdsReason: response.data.tdsReason,
              tdsAmount: response.data.tdsAmount,
              projectId: response.data.projectId,
              internalNote: response.data.internalNote,
              saleType: response.data.saleType,
              goodType: response.data.goodType,
              tcsPercentage: response.data.tcsPercentage,
              tcsAmount: response.data.tcsAmount,
              mrPrintNo: response.data.mrPrintNo,
              roundOff: response.data.roundOff,
              poHdrid: response.data.poHdrid,
              indentHdrid: response.data.indentHdrid,
              acYear: response.data.acYear,
              suppliedPoValue: response.data.suppliedPoValue,
              suppliedSrValue: response.data.suppliedSrValue,
              supplierPaidAmount: response.data.supplierPaidAmount,
              supplierPendingPo: response.data.supplierPendingPo,
              supplierPoval: response.data.supplierPoval,
              supplierSoval: response.data.supplierSoval,
              supplierPayment: response.data.supplierPayment,
              pendingPoval: response.data.pendingPoval,
              ledgerBalance: response.data.ledgerBalance,
              ledegerBal: response.data.ledegerBal,

              totalAmount: totalAmount,

              ApproveButton: ApproveButton,
              RejectButton: RejectButton,
              UpdateButton: UpdateButton,
              EditButton: EditButton,
            };
            yield put(getJuteBillPassHeader(Header));
          }
        }
        // yield put(
        //   getInvoiceFiles(
        //     serverApi.GET_FILES +
        //       "2/" +
        //       response.data.billPassNo +
        //       "/" +
        //       localStorage.getItem("companyId"),
        //     history,
        //     "2"
        //   )
        // );
        // yield put(
        //   getInvoiceFiles(
        //     serverApi.GET_FILES +
        //       "18/" +
        //       response.data.billPassNo +
        //       "/" +
        //       localStorage.getItem("companyId"),
        //     history,
        //     "18"
        //   )
        // );
        // yield put(
        //   getInvoiceFiles(
        //     serverApi.GET_FILES +
        //       "3/" +
        //       response.data.billPassNo +
        //       "/" +
        //       localStorage.getItem("companyId"),
        //     history,
        //     "3"
        //   )
        // );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* UploadInvoiceFilesData({
  payload: { url, data, history, sourceMenu, reqObj },
}) {
  console.log(url, data, history, sourceMenu, reqObj);
  try {
    const response = yield call(postAdd, url, data);
    if (sourceMenu) {
      if (sourceMenu === 2) {
        yield put(UploadInvoiceFilesSuccessfull({ response }));
        yield put(
          getInvoiceFiles(
            serverApi.GET_FILES + "2/" + reqObj.id + "/" + reqObj.companyId,
            history,
            "2"
          )
        );
      } 
      else if (sourceMenu === 3) {
        yield put(UploadChallanFilesSuccessfull({ response }));
        yield put(
          getChallanFiles(
            serverApi.GET_FILES + "3/" + reqObj.id + "/" + reqObj.companyId,
            history,
            "3"
          )
        );
      } 
      else if (sourceMenu === 18) {
        yield put(UploadSupportFilesSuccessfull({ response }));
        yield put(
          getSupportFiles(
            serverApi.GET_FILES + "18/" + reqObj.id + "/" + reqObj.companyId,
            history,
            "18"
          )
        );
      }
    }
    yield put(UploadInvoiceFilesSuccessfull({ response }));
    swal("Uploaded Successfully", { icon: "success" });
  } catch (error) {
    console.log(error);
  }
}

// function* getFilesofBillPass({ payload: { url, history, sourceMenu } }) {
//   try {
//     const response = yield call(getListurl, url);
//     if (sourceMenu) {
//       if (sourceMenu === "2") {
//         yield put(getInvoiceFilesSuccessfull({ response }));
//       } else if (sourceMenu === "3") {
//         yield put(getChallanFilesSuccessfull({ response }));
//       } else if (sourceMenu === "18") {
//         yield put(getSuppdocsFilesSuccessfull({ response }));
//       }
//     } else {
//       yield put(getFilesSuccessfull({ response }));
//     }
//   } catch (error) {
//     console.log(error);
//   }
// }

function* getFilesOfInvoice({ payload: { url, history, sourceMenu } }) {
  try {
    const response = yield call(getListurl, url, history);
    if (response) {
      console.log(response);
      yield put(getInvoiceFilesSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getFilesOfSupport({ payload: { url, history, sourceMenu } }) {
  try {
    const response = yield call(getListurl, url, history);
    if (response) {
      yield put(getSupportFilesSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getFilesOfChallan({ payload: { url, history, sourceMenu } }) {
  try {
    const response = yield call(getListurl, url, history);
    if (response) {
      yield put(getChallanFilesSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchJuteBillPassList() {
  yield takeEvery(JUTE_BILL_PASS_LIST, JuteBillPassSagaList);
  yield takeEvery(JUTE_BILL_PASS_VIEW_BY_ID, JuteBillPassViewByIdSagaList);
  yield takeEvery(UPDATE_JUTE_BILL_PASS, updateJuteBillpassSaga);
  // yield takeEvery(GET_FILES_OF_BILLPASS, getFilesofBillPass);
  yield takeEvery(INVOICE_UPLOAD_FILES, UploadInvoiceFilesData);
  yield takeEvery(GET_INVOICE_FILES, getFilesOfInvoice);
  yield takeEvery(GET_SUPPORT_FILES, getFilesOfSupport);
  yield takeEvery(GET_CHALLAN_FILES, getFilesOfChallan);
}

function* JuteBillPassSaga() {
  yield all([fork(watchJuteBillPassList)]);
}

export default JuteBillPassSaga;
