import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import swal from "sweetalert";

import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import { serverApi } from "../../../helpers/Consts";
import {
  getSalesOrderHeader,
  SalesOrderLineItems,
  getQuotationRecords,
  getQuotationViewById,
} from "../../../store/Sales/SalesOrder/actions";
import {
  getBranchList,
  getCustomerList,
  getBillingShippingList,
} from "../../../store/Global/DropDownApis/actions";
import FormPopup from "../../../components/FormlPopup/FormPopup";
import { getQuotationBroker } from "../../../store/Sales/Qutation/actions";
import { SalesGstCalculations } from "../../../Utilities/SalesGstCalculation";
import {
  clearSalesOrderHeader,
  ClearSalesOrderLineItems,
} from "../../../store/Sales/SalesOrder/actions";
import { isEmpty } from "../../../Utilities/helper";

const FillDetails = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [HeaderData, setHeaderData] = useState({});
  const [branchListData, setbranchListData] = useState([]);
  const [brokerData, setbrokerData] = useState([]);
  const [CustomerData, setCustomerData] = useState([]);
  const [billingAddressData, setbillingAddressData] = useState([]);
  const [shippingAddressData, setshippingAddressData] = useState([]);
  const [QuotationData, setQuotationData] = useState([]);
  const [LineItems, setLineItems] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [customerListData, SetCustomerListData] = useState([]);
  const [emailCustomer, setEmailCustomer] = useState("");
  const [noConsigneeWithQt, setnoConsigneeWithQt] = useState(false);
  const [backDaysAllowable, setBackDaysAllowable] = useState("");
  const [futureDaysAllowable, setFutureDaysAllowable] = useState("");

  useEffect(() => {
    dispatch(
      getBranchList(
        serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
        history
      )
    );
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
    };
    dispatch(getCustomerList(serverApi.GET_ALL_CUSTOMERS, data, history));
    dispatch(
      getQuotationBroker(
        serverApi.GET_ALL_BROKER_LIST +
          localStorage.getItem("companyId") +
          "/SALE",
        history
      )
    );
    dispatch(
      getQuotationRecords(
        serverApi.GET_QUOTATION_DROPDOWN + localStorage.getItem("companyId"),
        history
      )
    );
    const currentMonth = moment().format('MM');
    const currentDate = moment().format('DD');
    const fiscalYearStart = moment(`${localStorage.getItem("acadamicYear")}-${currentMonth}-${currentDate}`);
    const today = moment();
    let dateValue;
    if (today.isBefore(fiscalYearStart)) {
      dateValue = today.format("YYYY-MM-DD");
    } else {
      dateValue = fiscalYearStart.format("YYYY-MM-DD");
    }

    if (userDit) {
      userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              if (item.backDaysAllowable !== undefined) {
                let backDaysAllowable = JSON.parse(item.backDaysAllowable);
                let backDays = backDaysAllowable.bdaystype;
                if (Number(backDays) === 1) {
                  backDays = 7;
                } else if (Number(backDays) === 2) {
                  backDays = 14;
                } else if (Number(backDays) === 3) {
                  backDays = "";
                } else if (Number(backDays) === 4) {
                  backDays = backDaysAllowable.otherBackDateValue;
                } else {
                  backDays = backDaysAllowable.bdaystype;
                }
                let minDate = moment(dateValue).subtract(
                  Number(backDays),
                  "days"
                );
                let setMinDate = moment(minDate).format("YYYY-MM-DD");
                if(backDays !==""){
                setBackDaysAllowable(setMinDate);
                }
              }
              if (item.futureDaysAllowable !== undefined) {
                let futureDaysAllowable = JSON.parse(item.futureDaysAllowable);
                let futureDays = futureDaysAllowable.fdaystype;
                if (Number(futureDays) === 1) {
                  futureDays = 7;
                } else if (Number(futureDays) === 2) {
                  futureDays = 14;
                } else if (Number(futureDays) === 3) {
                  futureDays = "";
                } else if (Number(futureDays) === 4) {
                  futureDays = futureDaysAllowable.othervalue;
                } else {
                  futureDays = futureDaysAllowable.fdaystype;
                }
                let maxDate = moment(dateValue).add(
                  Number(futureDays),
                  "days"
                );
                let setMaxDate = moment(maxDate).format("YYYY-MM-DD");
                if(futureDays !==""){
                setFutureDaysAllowable(setMaxDate);
                }
              }
            });
            setHeaderData((prev) => ({
              ...prev,
              salesDate:dateValue,
              // salesOrderExpiryDate:dateValue
            }));
      
            const UpdatedRec = {
              ...SalesOrderHeader,
              salesDate:dateValue,
              // salesOrderExpiryDate:dateValue
            };
            dispatch(getSalesOrderHeader(UpdatedRec));

          }
      });
    }
  }, []);

  const { SalesOrderHeader, QuotationApprovedData, LineItemsData } =
    useSelector((state) => state.SalesOrderReducer);

  useEffect(() => {
    setLineItems(LineItemsData);
    setHeaderData(SalesOrderHeader);
  }, [SalesOrderHeader]);

  const { branchList, customerList, billingShippingList } = useSelector(
    (state) => state.DropDownListReducer
  );

  const { BrokerData } = useSelector((state) => state.QuotationReducer);

  useEffect(() => {
    if (branchList) {
      if (branchList.data) {
        if (branchList.data.length !== 0) {
          let List = branchList.data.filter((item) => item.value !== 0);
          setbranchListData(List);
        }
      }
    }
    if (customerList) {
      if (customerList.data) {
        if (customerList.data.length !== 0) {
          var CusList = [];
          CusList.push(customerSelect);
          customerList.data.map((item) => {
            CusList.push({
              value: item.id,
              name: item.name,
              label: item.name,
            });
          });
          setCustomerData(CusList);
        }
      }
    }
    if (BrokerData) {
      if (BrokerData.data) {
        setbrokerData(BrokerData.data);
      }
    }

    if (QuotationApprovedData) {
      if (QuotationApprovedData.data) {
        if (QuotationApprovedData.data.length !== 0) {
          var QuoteData = [];
          if (props.source !== "WITHQUOTATION") {
            QuoteData.push({
              value: "",
              name: "Select",
              label: "Select",
            });
          }

          QuotationApprovedData.data.map((item) => {
            QuoteData.push({
              value: item.Id,
              name: item.Value,
              label: item.Value,
            });
          });

          setQuotationData(QuoteData);
        }
      }
    }
  }, [branchList, customerList, QuotationApprovedData]);

  const isEmptyObj = (myObject) => {
    const keys = Object.keys(myObject);
    return keys.length === 0;
  };

  useEffect(() => {
    if (billingShippingList) {
      if (billingShippingList.data) {
        setEmailCustomer(billingShippingList.data.customerHdr.emailId);
        if (billingShippingList.data.customerLineitems) {
          if (billingShippingList.data.customerLineitems.length !== 0) {
            var list = [];
            let billingAddressList = [];
            let shippingaddressList = [];
            let SalesOrderHeaderObj =  SalesOrderHeader
            let customerLineitems = billingShippingList.data.customerLineitems;
            SetCustomerListData(customerLineitems);
            billingShippingList.data.customerLineitems.map((prop, i) => {
              if (prop.addressType === 2 || prop.addressType === null) {
                if (
                  (prop.customerLineId === SalesOrderHeader.shippingTo &&
                    prop.isActive === 0) ||
                  prop.isActive !== 0
                ) {
                  billingAddressList.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
                  list.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
                }
              }
              if (
                prop.addressType === 2 ||
                prop.addressType === null ||
                prop.addressType === 1
              ) {
                if (
                  (prop.customerLineId === SalesOrderHeader.shippingTo &&
                    prop.isActive === 0) ||
                  prop.isActive !== 0
                ) {
                  shippingaddressList.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
                  list.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
                }
              }
            });
            if (
              props.source === "DIRECTORDER" ||
              SalesOrderHeader.customerResponse
            ) {
              billingShippingList.data.customerLineitems.map((prop, i) => {
                if (billingAddressList.length !== 0 &&  HeaderData.customerId !== "" &&
                HeaderData.customerId !== null) {
                  billingAddressList.map((billingdata, ind) => {
                    if (ind === 0) {
                      if (!isEmptyObj(HeaderData)) {
                        if (
                          HeaderData.customerId !== "" ||
                          HeaderData.customerId !== null
                        ) {
                          if (prop.customerLineId === billingdata.value) {
                            setHeaderData((prev) => ({
                              ...prev,
                              ContactNumber: billingShippingList.data.customerHdr.contactNo,
                              ContactPerson:
                                billingShippingList.data.customerHdr.contactPerson,
                              billingTo: prop.customerLineId,
                              billingToAddress: prop.address,
                              billingToGST: prop.gstNo,
                              billingContactNo: prop.contactNo,
                              billingContactPerson: prop.contactPerson,
                              billingToEmail: billingShippingList.data.customerHdr.emailId,
                              taxType: 2,
                            }));
                            const UpdatedRec = {
                              ...SalesOrderHeaderObj,
                              ContactNumber: billingShippingList.data.customerHdr.contactNo,
                              ContactPerson:
                                billingShippingList.data.customerHdr.contactPerson,
                              billingTo: prop.customerLineId,
                              billingToAddress: prop.address,
                              billingToGST: prop.gstNo,
                              billingContactNo: prop.contactNo,
                              billingContactPerson: prop.contactPerson,
                              billingToEmail: billingShippingList.data.customerHdr.emailId,
                              taxType: 2,
                            };
                            SalesOrderHeaderObj = UpdatedRec
                          }
                        }else{
                          setHeaderData((prev) => ({
                            ...prev,
                            ContactNumber: "",
                            ContactPerson: "",
                            billingTo: "",
                            billingToAddress: "",
                            billingToGST: "",
                            billingContactNo: "",
                            billingContactPerson: "",
                            billingToEmail: "",
                            taxType: "",
                          }));
                          const UpdatedRec = {
                            ...SalesOrderHeaderObj,
                            ContactNumber: "",
                            ContactPerson: "",
                            billingTo: "",
                            billingToAddress: "",
                            billingToGST: "",
                            billingContactNo: "",
                            billingContactPerson: "",
                            billingToEmail: "",
                            taxType: "",
                          };
                          SalesOrderHeaderObj = UpdatedRec
                        }
                      }
                    }
                  });
                } else {
                  setHeaderData((prev) => ({
                    ...prev,
                    ContactNumber: "",
                    ContactPerson: "",
                    billingTo: "",
                    billingToAddress: "",
                    billingToGST: "",
                    billingContactNo: "",
                    billingContactPerson: "",
                    billingToEmail: "",
                    taxType: "",
                  }));
                  const UpdatedRec = {
                    ...SalesOrderHeaderObj,
                    ContactNumber: "",
                    ContactPerson: "",
                    billingTo: "",
                    billingToAddress: "",
                    billingToGST: "",
                    billingContactNo: "",
                    billingContactPerson: "",
                    billingToEmail: "",
                    taxType: "",
                  };
                  SalesOrderHeaderObj = UpdatedRec

                }
              })
            }
            if (
              props.source === "DIRECTORDER" ||
              SalesOrderHeader.customerResponse
            ) {
              billingShippingList.data.customerLineitems.map((prop, i) => {
                if (shippingaddressList.length !== 0 &&  HeaderData.customerId !== "" &&
                HeaderData.customerId !== null) {
                  shippingaddressList.map((shippingdata, ind) => {
                    if (ind === 0) {
                      if (!isEmptyObj(HeaderData)) {
                        if (
                          HeaderData.customerId !== "" ||
                          HeaderData.customerId !== null
                        ) {
                          if (prop.customerLineId === shippingdata.value) {
                            setHeaderData((prev) => ({
                              ...prev,
                              ContactNumber: billingShippingList.data.customerHdr.contactNo,
                              ContactPerson:
                                billingShippingList.data.customerHdr.contactPerson,
                              shippingTo: prop.customerLineId,
                              shippingToAddress: prop.address,
                              shippingToGST: prop.gstNo,
                              shippingContactNo: prop.contactNo,
                              shippingContactPerson: prop.contactPerson,
                              shippingToEmail: billingShippingList.data.customerHdr.emailId,
                              taxType: 2,
                            }));
                            const UpdatedRec = {
                              ...SalesOrderHeaderObj,
                              ContactNumber: billingShippingList.data.customerHdr.contactNo,
                              ContactPerson:
                                billingShippingList.data.customerHdr.contactPerson,
                              shippingTo: prop.customerLineId,
                              shippingToAddress: prop.address,
                              shippingToGST: prop.gstNo,
                              shippingContactNo: prop.contactNo,
                              shippingContactPerson: prop.contactPerson,
                              shippingToEmail: billingShippingList.data.customerHdr.emailId,
                              taxType: 2,
                            };
                            SalesOrderHeaderObj = UpdatedRec
                          }
                        }else{
                          setHeaderData((prev) => ({
                            ...prev,
                            ContactNumber: "",
                            ContactPerson: "",
                            shippingTo: "",
                            shippingToAddress: "",
                            shippingToGST: "",
                            shippingContactNo: "",
                            shippingContactPerson: "",
                            shippingToEmail: "",
                            taxType: "",
                          }));
                          const UpdatedRec = {
                            ...SalesOrderHeaderObj,
                            ContactNumber: "",
                            ContactPerson: "",
                            shippingTo: "",
                            shippingToAddress: "",
                            shippingToGST: "",
                            shippingContactNo: "",
                            shippingContactPerson: "",
                            shippingToEmail: "",
                            taxType: "",
                          };
                          SalesOrderHeaderObj = UpdatedRec
                        }
                      }
                    }
                  });
                }  else {
                  setHeaderData((prev) => ({
                    ...prev,
                    ContactNumber: "",
                    ContactPerson: "",
                    shippingTo: "",
                    shippingToAddress: "",
                    shippingToGST: "",
                    shippingContactNo: "",
                    shippingContactPerson: "",
                    shippingToEmail: "",
                    taxType: "",
                  }));
                  const UpdatedRec = {
                    ...SalesOrderHeaderObj,
                    ContactNumber: "",
                    ContactPerson: "",
                    shippingTo: "",
                    shippingToAddress: "",
                    shippingToGST: "",
                    shippingContactNo: "",
                    shippingContactPerson: "",
                    shippingToEmail: "",
                    taxType: "",
                  };
                  SalesOrderHeaderObj = UpdatedRec
                }
              });
            }
            if (SalesOrderHeader.customerId !== "") {
                  dispatch(getSalesOrderHeader(SalesOrderHeaderObj));
              setbillingAddressData(billingAddressList);
              setshippingAddressData(shippingaddressList);
            }
          }
        }
      }
    }
  }, [billingShippingList]);
  
  const handlePropSelectChange = (selectedValue, selectedName, name,row, selectedlable,) => {
    if (name === "quotationId") {
      if (selectedValue === "") {
        dispatch(clearSalesOrderHeader());
        dispatch(ClearSalesOrderLineItems());

        return;
      }
      setHeaderData((prev) => ({
        ...prev,
        quotationId: selectedValue,
      }));
      const UpdatedRec = {
        ...HeaderData,
        quotationId: selectedValue,
      };
      setnoConsigneeWithQt(false);
      dispatch(getSalesOrderHeader(UpdatedRec));
      dispatch(
        getQuotationViewById(
          serverApi.QUOTATION_VIEW_BYID +
            selectedValue +
            "/" +
            localStorage.getItem("companyId")
        )
      );
    }
    if (name === "branchId") {
      setHeaderData((prev) => ({
        ...prev,
        branchId: selectedValue,
        branchName: selectedlable,
      }));
      const UpdatedRec = {
        ...HeaderData,
        branchId: selectedValue,
        branchName: selectedlable,
      };
      dispatch(getSalesOrderHeader(UpdatedRec));
    }
    if (name === "customerId") {
      if(selectedValue === "") {
        let TaxPer = SalesGstCalculations(
          {
            lineItems: LineItemsData,
            taxType: 0,
          },
          "SALES_REGULAR_FLOW"
        );
        handleCalculationCustomer(TaxPer,selectedValue,selectedName);
        return
      }else{
      let TaxPer = SalesGstCalculations(
        {
          lineItems: LineItemsData,
          taxType: 2,
        },
        "SALES_REGULAR_FLOW"
      );
      handleCalculationCustomer(TaxPer, selectedValue, selectedName);
      setHeaderData((prev) => ({
        ...prev,
        customerId: selectedValue,
        customerName: selectedName,
      }));
      if (props.source === "WITHQUOTATION") {
        setnoConsigneeWithQt(true);
      }
      dispatch(
        getBillingShippingList(
          serverApi.GET_ALL_BILLING_SHIPPING +
            selectedValue +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).cipher +
            "?isForSalesModule=1",
          history
        )
      );
        }
    }
    if (name == "brokerId") {
      if (selectedValue === 0) {
        setHeaderData((prev) => ({
          ...prev,
          brokerId: "",
          brokerCommisionPercent: "",
          brokerName: "",
        }));
        const UpdatedRec = {
          ...HeaderData,
          brokerId: "",
          brokerCommisionPercent: "",
          brokerName: "",
        };
        dispatch(getSalesOrderHeader(UpdatedRec));

        return;
      }
      setHeaderData((prev) => ({
        ...prev,
        brokerId: selectedValue,
        brokerCommisionPercent: "",
        brokerName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        brokerId: selectedValue,
        brokerCommisionPercent: "",
        brokerName: selectedName,
      };
      dispatch(getSalesOrderHeader(UpdatedRec));
    }

    if (name === "BillingTo") {
      customerListData.filter((prop) => {
        if (prop.customerLineId === selectedValue) {
          setHeaderData((prev) => ({
            ...prev,
            billingTo: selectedValue,
            billingToAddress: selectedName,
            billingToGST: prop.gstNo,
            billingContactNo: prop.contactNo,
            billingContactPerson: prop.contactPerson,
            billingToEmail: emailCustomer,
          }));
          const UpdatedRec = {
            ...HeaderData,
            billingTo: selectedValue,
            billingToAddress: selectedName,
            billingToGST: prop.gstNo,
            billingContactNo: prop.contactNo,
            billingContactPerson: prop.contactPerson,
            billingToEmail: emailCustomer,
          };
          dispatch(getSalesOrderHeader(UpdatedRec));
        }
      });
    }
    if (name === "shippingTo") {
      customerListData.filter((prop) => {
        if (prop.customerLineId === selectedValue) {
          setHeaderData((prev) => ({
            ...prev,
            shippingTo: selectedValue,
            shippingToAddress: selectedName,
            shippingToGST: prop.gstNo,
            shippingContactNo: prop.contactNo,
            shippingContactPerson: prop.contactPerson,
            shippingToEmail: emailCustomer,
          }));
          const UpdatedRec = {
            ...HeaderData,
            shippingTo: selectedValue,
            shippingToAddress: selectedName,
            shippingToGST: prop.gstNo,
            shippingContactNo: prop.contactNo,
            shippingContactPerson: prop.contactPerson,
            shippingToEmail: emailCustomer,
          };
          dispatch(getSalesOrderHeader(UpdatedRec));
        }
      });
    }
    if (name === "TaxType") {
      let TaxPer = SalesGstCalculations(
        {
          lineItems: LineItemsData,
          taxType: selectedValue,
        },
        "SALES_REGULAR_FLOW"
      );
      let taxType = selectedValue;
      let taxName = selectedName;
      handleCalculation(TaxPer, taxType, taxName);
      setHeaderData((prev) => ({
        ...prev,
        taxType: selectedValue,
        taxName: selectedName,
      }));
    }
    if (name === "deliveryTerms") {
      if (selectedValue == "") {
        setHeaderData((prev) => ({
          ...prev,
          deliveryTerms: "",
        }));
        const UpdatedRec = {
          ...HeaderData,
          deliveryTerms: "",
        };
        dispatch(getSalesOrderHeader(UpdatedRec));
        return;
      }

      setHeaderData((prev) => ({
        ...prev,
        deliveryTerms: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        deliveryTerms: selectedName,
      };
      dispatch(getSalesOrderHeader(UpdatedRec));
    }
  };
  const handleCalculation = (Calculations, taxType, taxName) => {
    setLineItems(Calculations.lineItems);
    dispatch(SalesOrderLineItems(Calculations.lineItems));
    let Headerdata = {
      ...SalesOrderHeader,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
      taxType: taxType,
      taxName: taxName,
    };
    setHeaderData((prev) => ({
      ...prev,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
      taxType: taxType,
      taxName: taxName,
    }));
    dispatch(getSalesOrderHeader(Headerdata));
  };

  const handleCalculationCustomer = (Calculations, selectedValue, selectedName) => {
    setLineItems(Calculations.lineItems);
     dispatch(SalesOrderLineItems(Calculations.lineItems));

    if(selectedValue === "") {
      setHeaderData((prev) => ({
        ...prev,
        customerId: selectedValue,
        customerName: selectedName,
        ContactNumber: "",
        ContactPerson: "",
        billingTo: "",
        billingToAddress: "",
        billingToGST: "",
        billingContactNo: "",
        billingContactPerson: "",
        billingToEmail: "",
        shippingTo: "",
        shippingToAddress: "",
        shippingToGST: "",
        shippingContactNo: "",
        shippingContactPerson: "",
        shippingToEmail:"",
        taxType: null,
        totalIGST: "0.00",
        totalCGST: "0.00",
        totalSGST: "0.00",
        taxTotal: "0.00",
        TotalAmount: Calculations.netTotal,
      }));
      setbillingAddressData([])
      setshippingAddressData([])
       let Headerdata = {
        ...SalesOrderHeader,
        customerId: selectedValue,
        customerName: selectedName,
        ContactNumber: "",
        ContactPerson: "",
        billingTo: "",
        billingToAddress: "",
        billingToGST: "",
        billingContactNo: "",
        billingContactPerson: "",
        billingToEmail: "",
        shippingTo: "",
        shippingToAddress: "",
        shippingToGST: "",
        shippingContactNo: "",
        shippingContactPerson: "",
        shippingToEmail: "",
        taxType: null,
        totalIGST: "0.00",
        totalCGST: "0.00",
        totalSGST: "0.00",
        taxTotal: "0.00",
        TotalAmount: Calculations.netTotal,
      };
      dispatch(getSalesOrderHeader(Headerdata));
    }else{
 
      let Headerdata = {
        ...SalesOrderHeader,
        totalIGST: Calculations.totalIGST,
        totalCGST: Calculations.totalCGST,
        totalSGST: Calculations.totalSGST,
        taxTotal: Calculations.taxTotal,
        TotalAmount: Calculations.TotalAmount,
        netTotal: Calculations.netTotal,
        subTotal: Calculations.subTotal,
        customerId: selectedValue,
        customerName: selectedName,
      };
      setHeaderData((prev) => ({
        ...prev,
        totalIGST: Calculations.totalIGST,
        totalCGST: Calculations.totalCGST,
        totalSGST: Calculations.totalSGST,
        taxTotal: Calculations.taxTotal,
        TotalAmount: Calculations.TotalAmount,
        netTotal: Calculations.netTotal,
        subTotal: Calculations.subTotal,
        customerId: selectedValue,
        customerName: selectedName,
  
      }))
      dispatch(getSalesOrderHeader(Headerdata));
    }

   };

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const onhandlechange = (key) => (event) => {
    if (key === "deliveryDays") {
      setHeaderData((prev) => ({
        ...prev,
        deliveryDays: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        deliveryDays: event,
      };
      dispatch(getSalesOrderHeader(UpdatedRec));
    }
    if (key === "brokerCommisionPercent") {
      let Val = event.match(/^(\d*\.{0,1}\d{0,2}$)/);
      var Event = "";
      if (Val) {
        if (event > 100) {
          swal("Cannot enter more than 100%");
          Event = "";
        } else {
          Event = event;
        }
      } else {
        swal("Cannot enter more than two decimals");
        Event = "";
      }
      setHeaderData((prev) => ({
        ...prev,
        brokerCommisionPercent: Event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        brokerCommisionPercent: Event,
      };
      dispatch(getSalesOrderHeader(UpdatedRec));
    }
    // props.getSalesOrderHeader(HeaderData);
  };

  const formatDateToYYYYMMDD = (dateString) => {
    // Try parsing the date string using moment.js
    const momentDate = moment(dateString, ["YYYY-MM-DD", "DD-MM-YYYY"], true);

    // Check if the parsing was successful
    if (momentDate.isValid()) {
      // Format the date to "YYYY-MM-DD"
      const formattedDate = momentDate.format("YYYY-MM-DD");
      return formattedDate;
    } else {
      // Handle invalid date strings as needed
      console.error("Invalid date string:", dateString);
      return null;
    }
  };

  const handleSelect_Date = (e, name) => {
    if (name === "salesDate") {
      let date = moment(e).format();
      let Date = date.split("T", 1).toString();
      let Sale_date = null;
      if (HeaderData.salesOrderExpiryDate !== null) {
        if (Date > HeaderData.salesOrderExpiryDate) {
          swal("Sale Date Should not be Greater than Expire Date");
          Sale_date = HeaderData.salesOrderExpiryDate
        } 
        else if (Date < HeaderData.quotationDate) {
          swal(
            `Sales Order Date Should be Greater than Or Equals to Quotation Date. ( ${moment(
              HeaderData.quotationDate
            ).format("DD-MM-YYYY")})`
          );
          Sale_date = HeaderData.quotationDate
        }
        else {
          Sale_date = Date;
        }
      } else {
        if (Date < HeaderData.quotationDate) {
          swal(
            `Sales Order Date Should be Greater than Or Equals to Quotation Date. ( ${moment(
              HeaderData.quotationDate
            ).format("DD-MM-YYYY")})`
          );
          Sale_date = HeaderData.quotationDate

        } else {
          Sale_date = Date;
        }
      }
      setHeaderData((prev) => ({
        ...prev,
        salesDate: Sale_date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        salesDate: Sale_date,
      };
      dispatch(getSalesOrderHeader(UpdatedRec));
    }
    if (name === "salesOrderExpiryDate") {
      let date = moment(e).format();
      let Date = date.split("T", 1).toString();
      let SaleExpDate = null;
      if (HeaderData.salesDate !== null) {
        if (Date < HeaderData.salesDate) {
          swal("Expire Date Should be Greater than Sale Date");
          SaleExpDate = HeaderData.salesDate;
        } else {
          SaleExpDate = Date;
        }
      } else {
        swal("Please Select the Sale Date First");
      }
      setHeaderData((prev) => ({
        ...prev,
        salesOrderExpiryDate: SaleExpDate,
      }));
      const UpdatedRec = {
        ...HeaderData,
        salesOrderExpiryDate: SaleExpDate,
      };
      dispatch(getSalesOrderHeader(UpdatedRec));
    }
  };

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details </h5>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.quotationId ? 1 : 0}
                        arrayOfData={QuotationData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.quotationId}
                        name="quotationId"
                        label="Quotation"
                        isDisabled={
                          props.locationState.state !== undefined
                            ? HeaderData.quotationNo !== null
                              ? true
                              : false
                            : false
                        }
                        required={
                          props.source === "WITHQUOTATION" ? true : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.branchId ? 1 : 0}
                        arrayOfData={branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.branchId}
                        name="branchId"
                        label="Branch"
                        required
                        // isDisabled ={(props.source === "DIRECTORDER"?false:HeaderData.branchId!==null?true:false)&&(props.source === "WITHQUOTATION" ? HeaderData.quotationId ===null?true:true:HeaderData.quotationId !==null?true:false)}
                        isDisabled={
                          props.source === "DIRECTORDER"
                            ? false
                            : HeaderData.branchId !== null ||
                              (props.source === "WITHQUOTATION"
                                ? HeaderData.quotationId === null
                                  ? true
                                  : true
                                : HeaderData.quotationId !== null
                                ? true
                                : false)
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.brokerId ? 1 : 0}
                        arrayOfData={brokerData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.brokerId}
                        name="brokerId"
                        label="Broker"
                        ErrorOutlineIcon={true}
                        toolTipTitle={
                          "Broker or Consignee is mandetory Select At least one and Proceed"
                        }
                        isDisabled={
                          (props.source === "WITHQUOTATION" &&
                          HeaderData.quotationId === ""
                            ? true
                            : HeaderData.disableBroker === true
                            ? true
                            : false) ||
                          (props.source === "DIRECTORDER" &&
                            (HeaderData.disableBroker === true ||
                              isEmpty(HeaderData.branchId)))
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelect
                      update={HeaderData.customerId ? 1 : 0}
                      arrayOfData={CustomerData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      selected={HeaderData.customerId}
                      name="customerId"
                      label="Customer/Consignee"
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      ErrorOutlineIcon={true}
                      toolTipTitle={
                        "Broker or Consignee is mandetory Select At least one and Proceed"
                      }
                      isDisabled={
                        (props.source === "WITHQUOTATION" &&
                        HeaderData.quotationId === ""
                          ? true
                          : HeaderData.disableCustomer === true
                          ? true
                          : false) ||
                        (props.source === "DIRECTORDER" &&
                          // (HeaderData.quotationNo !== null || HeaderData.branchId === "")
                          (HeaderData.disableCustomer === true ||
                            isEmpty(HeaderData.branchId)))
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.billingTo ? 1 : 0}
                        arrayOfData={billingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.billingTo}
                        name="BillingTo"
                        label="Billing To"
                        // required
                        ErrorOutlineIcon={true}
                        toolTipTitle={"If you select the Consignee Billing To is Mandatory"}
                        isDisabled ={HeaderData.quotationNo !== null ? true: HeaderData.customerId === "" ? true : false}

                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.shippingTo ? 1 : 0}
                        arrayOfData={shippingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.shippingTo}
                        name="shippingTo"
                        label="Shipping To"
                        ErrorOutlineIcon={true}
                        toolTipTitle={
                          "If you select the Consignee Shippping To is Mandatory"
                        }
                        // isDisabled ={HeaderData.quotationNo !== null ?true:HeaderData.customerId ===null?true:false}
                        isDisabled={isEmpty(HeaderData.customerId)}
                      />
                    </div>
                    {!isEmpty(HeaderData.customerId) && (
                      <span
                        onClick={openPopup}
                        style={{
                          color: "red",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 100,
                          fontSize: "15px",
                          marginTop: "5px",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                      >
                        Add a new Customer Branch Address
                      </span>
                    )}
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.taxType ? 1 : 0}
                        arrayOfData={taxTypeData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.taxType}
                        name="TaxType"
                        label="Tax Type"
                        isDisabled={isEmpty(HeaderData.customerId)}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Sale Date <span className="starcolor">*</span>
                          </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="saleDate"
                            inputFormat="dd-MM-yyyy"
                            id="saleDate"
                            minDate={
                              backDaysAllowable !== ""
                                ? backDaysAllowable
                                : HeaderData.salesDate
                            }
                            maxDate={
                              futureDaysAllowable !== ""
                                ? futureDaysAllowable
                                : HeaderData.salesDate
                            }
                            value={HeaderData.salesDate}
                            fullWidth
                            onChange={(e) => handleSelect_Date(e, "salesDate")}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Expire Date<span className="starcolor">*</span>
                          </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="salesOrderExpiryDate"
                            inputFormat="dd-MM-yyyy"
                            id="salesOrderExpiryDate"
                            minDate={
                              backDaysAllowable !== ""
                                ? backDaysAllowable
                                : HeaderData.salesOrderExpiryDate
                            }
                            maxDate={
                              futureDaysAllowable !== ""
                                ? futureDaysAllowable
                                : HeaderData.salesOrderExpiryDate
                            }
                            value={HeaderData.salesOrderExpiryDate}
                            fullWidth
                            onChange={(e) =>
                              handleSelect_Date(e, "salesOrderExpiryDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  {/* <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Delivery Days"
                      value={HeaderData.deliveryDays}
                      onChange={onhandlechange("deliveryDays")}
                      type="number"
                    />
                  </Box> */}
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Broker %"
                      value={HeaderData.brokerCommisionPercent}
                      onChange={onhandlechange("brokerCommisionPercent")}
                      type="number"
                      required={HeaderData.brokerId ? true : false}
                      disabled={isEmpty(HeaderData.brokerId)}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelectNew
                      update={HeaderData.deliveryTerms ? 1 : 0}
                      arrayOfData={DeliveryTermsData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.deliveryTerms}
                      name="deliveryTerms"
                      label="Delivery Terms"
                      required={true}
                      isDisabled={isEmpty(HeaderData.customerId || HeaderData.brokerId) ? true : false}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <FormPopup
          isOpen={isPopupOpen}
          onClose={closePopup}
          customerData={billingShippingList}
          customerId={SalesOrderHeader.customerId}
        />
      </div>
    </>
  );
};
const taxTypeData = [
  {
    value: 1,
    name: "IGST",
    label: "IGST",
  },
  {
    value: 2,
    name: "CGST & SGST",
    label: "CGST & SGST",
  },
];
const DeliveryTermsData = [
  {
    value: 1,
    label: "Ex-Works",
    name: "Ex-Works",
  },
  {
    value: 2,
    label: "Delivery to Destination",
    name: "Delivery to Destination",
  },
];

const customerSelect = {
  value: "",
  name: "Select",
  label: "Select",
};

export default FillDetails;
