import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import StockTransferLineItem from "./StockTransferLineItem";
import { getBillNoList } from "../../../store/Accounting/Payments/actions";

class StockTransferAddItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowcount: 1,
      show: false,
      checkAll: false,
      companyId: localStorage.getItem("companyId"),
    };
    this.state.filterText = "";
    this.state.tooltip = "item description....";
    this.state.products = [
      {
        id: "0",
        deptId: 0,
        itemGroupId: 0,
        itemId: 0,
        srNo: "",
        quantity: 0,
        costfactor: 0,
        machineNo: 0,
        centralStock: 0,
        branchStock: 0,
        srStock: 0,
        issueValue: 0,
        itemCode: 0,
      },
    ];
  }

  componentDidMount() {
    this.setState({
      products: this.props.products,
    });
  }

  render() {
    return (
      <div>
        <StockTransferLineItem
          tableHeaders={tableHeaders}
          RowData={RowData}
          products={this.props.products}
          header={this.props.header}
          delete={this.props.delete}
        />
      </div>
    );
  }
}
const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "department",
    dropDown: "dropDown",
    name: "department",
    Items: true,
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "itemGroup",
    dropDown: "dropDown",
    name: "itemGroup",
    Items: true,
  },
  {
    no: 3,
    component: "DynamicSelect",
    type: "item",
    dropDown: "dropDown",
    name: "item",
    Items: true,
  },
  {
    no: 4,
    component: "DynamicSelect",
    type: "srNo",
    dropDown: "dropDown",
    name: "srNo",
    Items: true,
  },
  {
    no: 5,
    component: "Inputadorment",
    type: "quantity",
  },

  {
    no: 6,
    component: "Inputadorment",
    type: "branchStock",
  },
  {
    no: 7,
    component: "Inputadorment",
    type: "srStock",
  },
  {
    no: 8,
    component: "Inputadorment",
    type: "issueValue",
  },

  {
    no: 9,
    component: "DynamicSelect",
    type: "wareHouseNo",
    dropDown: "dropDown",
    name: "wareHouseNo",
    Items: true,
  },
];

const tableHeaders = [
  {
    header: "Department",
  },
  {
    header: "Item Group",
  },
  {
    header: "Item List Data",
  },
  {
    header: "Store Recepit No",
  },
  {
    header: "quantity",
  },

  {
    header: "Branch Stock",
  },
  {
    header: "Stock",
  },
  {
    header: "Price",
  },
  {
    header: "Ware House",
  },
  {
    header: "Actions",
  },
];

const mapStatetoProps = (state) => {
  const { indentList } = state.Indent;
  return { indentList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getBillNoList,
  })(StockTransferAddItems)
);
