import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import {
  getDeliveryOrderList,
  clearRegularDeliveryOrderHeader,
  clearRegularDeliveryOrderLineItems,
  ClearDeliveryOrderLineItems,
  ClearDOJuteHessianHeaders,
  ClearJuteYarnDeliveryOrderHeader,
  clearJuteYarnDeliveryOrderLineItems,
  clearJuteGovtDeliveryOrderHeader,
  clearJuteGovtDeliveryOrderLineItems,
} from "../../../store/Sales/DeliveryOrder/actions";
import moment from "moment";
function Index(props) {
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [totalRecs, setTotalRecs] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(10);
  const [dataexp, setDataExp] = useState("");
  const [fromDate, setfromDate] = useState(
    moment().day(-7).format("DD-MM-YYYY")
  );
  const [toDate, settoDate] = useState(moment().format("DD-MM-YYYY"));
  const [branchId, setbranchId] = useState("");
  const [cusId, setcusId] = useState("");
  const [deliveryOrderNumber, setdeliveryOrderNumber] = useState("");
  const [status, setstatus] = useState("");
  const [DeliveryOrderData, setDeliveryOrderData] = useState([]);
  const [InvoiceType, setInvoiceType] = useState("");
  const [salesOrderNumber, setsalesOrderNumber] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    const financialYearStartMonth = 3; // Assuming financial year starts in April
    let financialYearStart = moment()
      .year(localStorage.getItem("acadamicYear"))
      .month(financialYearStartMonth)
      .startOf("month");

    const fromDate = financialYearStart.day(-7).format("YYYY-MM-DD");
    const toDate = moment().format("YYYY-MM-DD");

    var startDate = moment(fromDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var endDate = moment(toDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    dispatch(clearRegularDeliveryOrderHeader());
    dispatch(clearRegularDeliveryOrderLineItems());
    const data = {
      fromDate: startDate,
      toDate: endDate,
      cusId: "",
      branchId: null,
      status: "",
      companyId: localStorage.getItem("companyId"),
      acYear: localStorage.getItem("acadamicYear"),
      deliveryOrderNo: "",
      type: "",
      startIndex: startIndex,
      lastIndex: lastIndex,
    };
    const UpdatedVal = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    setDataExp(UpdatedVal);
    setfromDate(startDate)
    settoDate(endDate)
    dispatch(
      getDeliveryOrderList(
        serverApi.DELIVERY_ORDER_GET_API,
        data,
        props.history
      )
    );
  }, []);
  const { DeliveryOrder_List } = useSelector(
    (state) => state.DeliveryOrderReducer
  );

  useEffect(() => {
    dispatch(ClearDOJuteHessianHeaders());
    dispatch(ClearDeliveryOrderLineItems());
  }, []);

  useEffect(() => {
    dispatch(ClearJuteYarnDeliveryOrderHeader());
    dispatch(clearJuteYarnDeliveryOrderLineItems());
  }, []);

  useEffect(()=>{
    dispatch(clearJuteGovtDeliveryOrderHeader());
    dispatch(clearJuteGovtDeliveryOrderLineItems());
  },[])

  useEffect(() => {
    if (DeliveryOrder_List) {
      if (DeliveryOrder_List.data) {
        setDeliveryOrderData(DeliveryOrder_List.data);
        setTotalRecs(DeliveryOrder_List.totalRecords);
      }
    }
  }, [DeliveryOrder_List]);

  const handleSearch = (data) => {
    var from_Date = moment(data.fromDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var to_Date = moment(data.toDate, "YYYY-MM-DD", true).format("DD-MM-YYYY");
    setfromDate(from_Date);
    settoDate(to_Date);
    setInvoiceType(data.type);
    setbranchId(data.branchId);
    setcusId(data.cusId);
    setdeliveryOrderNumber(data.deliveryOrderNumber);
    setstatus(data.status);
    setsalesOrderNumber(data.salesOrderNumber)
    
    const payload = {
      fromDate: from_Date,
      toDate: to_Date,
      cusId: data.cusId,
      branchId: data.branchId,
      status: data.status,
      companyId: localStorage.getItem("companyId"),
      acYear: localStorage.getItem("acadamicYear"),
      deliveryOrderNo: data.deliveryOrderNumber,
      type: data.type,
      lastIndex: 10,
      startIndex: 0,
      salesOrderNo:data.salesOrderNumber
    };
    const UpdatedVal = {
      ...payload ,
      startIndex: 0,
      lastIndex: 0,
    };
    setDataExp(UpdatedVal)
    dispatch(
      getDeliveryOrderList(
        serverApi.DELIVERY_ORDER_GET_API,
        payload,
        props.history
      )
    );
  };
  const handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    const data = {
      fromDate: fromDate,
      toDate: toDate,
      cusId: cusId,
      branchId: branchId,
      status: status,
      companyId: localStorage.getItem("companyId"),
      acYear: localStorage.getItem("acadamicYear"),
      deliveryOrderNo: deliveryOrderNumber,
      type: InvoiceType,
      salesOrderNumber:salesOrderNumber
    };
    if (type === 1) {
      data.startIndex = sizePerPage;
      data.lastIndex = currentIndex;
      if (currentIndex >= 0) {
        dispatch(
          getDeliveryOrderList(
            serverApi.DELIVERY_ORDER_GET_API,
            data,
            props.history
          )
        );
      }
    } else {
      data.startIndex = currentIndex;
      data.lastIndex = sizePerPage;
      if (currentIndex >= 0) {
        dispatch(
          getDeliveryOrderList(
            serverApi.DELIVERY_ORDER_GET_API,
            data,
            props.history
          )
        );
      }
    }
    setStartIndex(currentIndex);
    setLastIndex(sizePerPage);
  };
  return (
    <>
      <PaginationGridView
        mdata={DeliveryOrderData}
        mcolumns={Columns}
        micon={GridArrowIcon}
        status={true}
        actions={true}
        filter_form={"DeliveryOrder"}
        totalRecs={totalRecs}
        // page={pageno}
        sizePerPage={lastIndex}
        handlePagination={handlePagination}
        handleSearch={handleSearch}
        url="/create_delivery_order"
        apiexp={serverApi.DELIVERY_ORDER_GET_API}
        payloadexp={dataexp}
        expName={"DeliveryOrder.csv"}
        fromDate={fromDate}
        toDate={toDate}
      />
    </>
  );
}
const Columns = [
  {
    Header: "Invoice Type",
    accessor: "invoiceTypeDesc",
    hidden: false,
  },
  {
    Header: "Delivery Number",
    accessor: "deliveryOrderNumber",
    hidden: false,
  },
  {
    Header: "Customer",
    accessor: "customer",
    hidden: false,
  },
  {
    Header: "Delivery Date",
    accessor: "deliveryDate",
    hidden: false,
  },
  {
    Header: "Sale Number",
    accessor: "saleNumber",
    hidden: false,
  },
  {
    Header: "Sale Date",
    accessor: "saleDate",
    hidden: false,
  },
  {
    Header: "Quantity",
    accessor: "quantity",
    hidden: false,
  },
  {
    Header: "status",
    accessor: "status",
    hidden: false,
  },
];

export default Index;
