import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import { yarnMasterList } from "../../../store/Master/YarnMaster/actions";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      yarnMasterListData: [], // To display Yarn master list
      yarnType: null,
      yarnGroup: null,
      dataexp: "",
    };
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
      yarnType: null,
      yarnGroup: null,
    };
    this.props.yarnMasterList(
      serverApi.GET_ALL_YARN_MASTER_LIST,
      data,
      this.props.history
    ); // API For yarn master Master list
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.YarnMasterList) {
      this.setState({
        totalRecs: props.YarnMasterList.totalRecords,
        yarnMasterListData: props.YarnMasterList.data, // State Updating to Yarn Master List Data
      });
    }
  }
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const data = {
        cipher: this.state.userDit.cipher,
        companyId: localStorage.getItem("companyId"),
        userId: this.state.userDit.userId.toString(),
        startIndex: sizePerPage,
        lastIndex: currentIndex,
        yarnType: this.state.yarnType,
        yarnGroup: this.state.yarnGroup,
      };
      if (currentIndex >= 0) {
        this.props.yarnMasterList(
          serverApi.GET_ALL_YARN_MASTER_LIST,
          data,
          this.props.history
        ); // API For yarn Master Pagination list
      }
    } else {
      const data = {
        cipher: this.state.userDit.cipher,
        companyId: localStorage.getItem("companyId"),
        userId: this.state.userDit.userId.toString(),
        startIndex: currentIndex === 0 ? currentIndex + 1 : currentIndex,
        lastIndex: sizePerPage,
        yarnType: this.state.yarnType,
        yarnGroup: this.state.yarnGroup,
      };
      if (currentIndex >= 0) {
        this.props.yarnMasterList(
          serverApi.GET_ALL_YARN_MASTER_LIST,
          data,
          this.props.history
        ); // API For yarn master pagination list
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  handleSearch = (data) => {
    const searchdata = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      yarnType: data.yarnType,
      yarnGroup: data.yarnGroup,
      startIndex: 1,
      lastIndex: 10,
    };
    this.setState({
      yarnType: data.yarnType,
      yarnGroup: data.yarnGroup,
    });
    this.props.yarnMasterList(
      serverApi.GET_ALL_YARN_MASTER_LIST,
      searchdata,
      this.props.history
    ); // API For yarn master pagination list
  };
  render() {
    return (
      <>
        <PaginationGridView
          mdata={this.state.yarnMasterListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          filter_form={"YarnMaster"}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          url="create_yarn_master"
          handleSearch={this.handleSearch}
          apiexp={serverApi.GET_ALL_YARN_MASTER_LIST}
          payloadexp={this.state.dataexp}
          expName={"Yarn-Master.csv"}
        />
      </>
    );
  }
}
// Yarn master List Headers
const Columns = [
  {
    Header: "Yarn Type",
    accessor: "yarnType",
    hidden: false,
  },
  {
    Header: "Yarn Group",
    accessor: "yarnGroup",
    hidden: false,
  },
];
const mapStatetoProps = (state) => {
  const { YarnMasterList } = state.YarnMasterReducer; // Fetching List From Yarn master Reducers
  return { YarnMasterList };
};

export default withRouter(
  connect(mapStatetoProps, {
    yarnMasterList,
  })(Index)
);
