import React, { useState, useEffect } from "react";
import moment from "moment";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import CustomButton from "../../../components/Buttons/Buttons";
import { serverApi, getTenantId } from "../../../helpers/Consts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty, limitDecimals } from "../../../Utilities/helper";
const JuteGateEntryPreview = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [locationState] = useState(useLocation());
  const [rowCount, setRowCount] = useState(1);
  const [show, setShow] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [companyId, setCompanyId] = useState(localStorage.getItem("companyId"));
  const [companyName, setCompanyName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [comName, setComName] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [filterText, setFilterText] = useState("");
  const [tooltip, setTooltip] = useState("item description....");
  const [LineItems, setLineItems] = useState([]);
  const [HeaderData, setHeaderData] = useState({});

  const { JuteGateEntrylineItems, JuteGateEntryHeader } = useSelector(
    (state) => state.JuteGateEntryReducer
  );
  useEffect(() => {
    setLineItems(JuteGateEntrylineItems);
    setHeaderData(JuteGateEntryHeader);
  }, [JuteGateEntrylineItems, JuteGateEntryHeader]);
  const convertNumberToWords = (amount) => {
    const words = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    amount = amount.toString();
    const atemp = amount.split(".");
    const number = atemp[0].split(",").join("");
    const n_length = number.length;
    let words_string = "";
    if (n_length <= 9) {
      const n_array = Array(9).fill(0);
      const received_n_array = [];
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i === 0 || i === 2 || i === 4 || i === 7) {
          if (n_array[i] === 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      for (let i = 0; i < 9; i++) {
        let value =
          i === 0 || i === 2 || i === 4 || i === 7
            ? n_array[i] * 10
            : n_array[i];
        if (value !== 0) {
          words_string += words[value] + " ";
        }
        if (
          (i === 1 && value !== 0) ||
          (i === 0 && value !== 0 && n_array[i + 1] === 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i === 3 && value !== 0) ||
          (i === 2 && value !== 0 && n_array[i + 1] === 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i === 5 && value !== 0) ||
          (i === 4 && value !== 0 && n_array[i + 1] === 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i === 6 &&
          value !== 0 &&
          n_array[i + 1] !== 0 &&
          n_array[i + 2] !== 0
        ) {
          words_string += "Hundred and ";
        } else if (i === 6 && value !== 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  };

  const withDecimal = (n) => {
    // if(n !==""){
    //   const nums = n.toString().split(".");
    //   const whole = convertNumberToWords(nums[0]);
    //   if (nums.length === 2) {
    //     if (parseInt(nums[1]) > 0) {
    //       const fraction = convertNumberToWords(parseInt(nums[1]));
    //       return whole + " Rupees and " + fraction + " Paisa";
    //     } else {
    //       return whole + " Rupees only";
    //     }
    //   } else {
    //     return whole + " Rupees only";
    //   }
    // }
  };

  const handlePdf = () => {
    const data = {
      poNum: locationState.rowData.poNo,
      userId: userDit.userId,
      cipher: userDit.cipher,
    };

    fetch(`${serverApi.DOWNLOAD_JUTE_PO}?poNum=${locationState.rowData.poNo}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        "X-TenantId": getTenantId(window.location.hostname),
        Authorization: `${localStorage.getItem(
          "token_type"
        )} ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const file_name = "JutePurchaseOrder.pdf";
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const handleEdit = () => {
    props.handleEdit(2);
  };

  useEffect(() => {
    const companyData = JSON.parse(localStorage.getItem("authUser"));
    const companyId = localStorage.getItem("companyId");
    const selectedCompany = companyData.companyDetails.find(
      (p) => companyId === p.value.toString()
    );

    if (selectedCompany) {
      setCompanyName(selectedCompany.label);
      setAddress1(selectedCompany.address1);
      setAddress2(selectedCompany.address2);
      setComName(selectedCompany.name);
      setCompanyLogo(selectedCompany.companyLogo);
    }
  }, []);

  const convertion = withDecimal(HeaderData.netTotal);
  const date = new Date();
  const newDate = moment(date).format("DD-MM-YYYY");
  const time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

  const filterData = JuteGateEntrylineItems.filter(
    (row) => row.isActive !== 0 && !isEmpty(row.itemId)
  );

  const newPoDate = moment(HeaderData.inDate, "YYYY-MM-DD").format(
    "DD-MM-YYYY"
  );

  let chalanDate = ""
  if(!isEmpty(HeaderData.chalanDate)){
    chalanDate =  moment(HeaderData.chalanDate, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    );
  }

  return (
    <>


      <div className="previewBlock">
        <div
          style={{ display: "flex", justifyContent: "end", padding: "10px" }}
        >
          {locationState.state !== undefined ? (
         <CustomButton
           label="Edit"
           className="lightBlueButton"
           muIcon={<EditIcon />}
           handleClick={handleEdit}
           type="button"
         />
        ):""}
   </div>
          <table
            cellpadding="0"
            cellspacing="0"
            width="100%"
            className="previewTable"
          >
            <tbody>
              <tr>
                <td
                  colspan="3"
                  style={{ borderRight: "none", borderBottom: "none" }}
                >
                  <img src={companyLogo} width="100%" alt="" />
                </td>
                <td
                  colspan="10"
                  style={{
                    borderBottom: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    textAlign: "right",
                  }}
                >
                  <h2>{companyName}</h2>
                  {address1}
                  <br />
                  {address2}
                  <br />
                </td>
              </tr>
              <tr>
                <td
                  colspan="20"
                  style={{ textAlign: "center", borderTop: "none" }}
                >
                  <h2>JUTE GATE ENTRY</h2>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <b>GATE ENTRY NO:</b>
                </td>
                <td colspan={HeaderData.JuteGateEntryType == "WITHPO"?"2":"10"}
                  className={isEmpty(HeaderData.id) ? "blur-text" : ""}
                >
                  <span>
                    {!isEmpty(HeaderData.id)
                      ? HeaderData.id
                      : "Will be generated after creation"}
                  </span>
                </td>
                {HeaderData.JuteGateEntryType == "WITHPO" &&<>
                <td colspan="1">
                  <b> PO Number: </b>
                </td>
                <td colspan="6">
                  <span> {HeaderData.poNo}</span>
                </td> 
                </>}
             
              </tr>

              <tr>
                <td colspan="1">
                  <b>GATE ENTRY DATE:</b>
                </td>
                <td colspan="2">
                  <span>
                    {newPoDate}
                  </span>
                </td>
                <td colspan="1">
                  <b>Challan Number: </b>
                </td>
                <td colspan="6">
                  <span> {HeaderData.chalanNo}</span>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <b>Challan Date:</b>
                </td>
                <td colspan="2">
                  <span>
                    {chalanDate}
                  </span>
                </td>
                <td colspan="1">
                  <b> Vehicle Number: </b>
                </td>
                <td colspan="6">
                  <span> {HeaderData.vehicleNo}</span>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <b>Driver Name:</b>
                </td>
                <td colspan="2">
                  <span>
                    {HeaderData.driverName}
                  </span>
                </td>
                <td colspan="1">
                  <b> Transporter: </b>
                </td>
                <td colspan="6">
                  <span>{HeaderData.transporter} </span>
                </td>
              </tr>

              <tr>
                <td colspan="1">
                  <b>Project:</b>
                </td>
                <td colspan="2">
                  <span>{HeaderData.branchName}</span>
                </td>
                <td colspan="1">
                  <b>MUKAM:</b>
                </td>
                <td colspan="6">
                  <span>{HeaderData.mukamName}</span>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <b>Supplier:</b>
                </td>
                <td colspan="10">
                  <span>{HeaderData.supplierName}</span>
                </td>
              
              </tr>

              <tr align="center" valign="top">
                <td align="center" width={"8%"}>
                  <b>Sl No </b>
                </td>
                <td align="center" width={"8%"}>
                  <b>Item Code </b>
                </td>
                <td align="center" width={"16%"}>
                  <b>Item Description </b>
                </td>
                <td align="center" width={"16%"}>
                  <b>Quality</b>
                </td>
                <td align="center" width={"16%"}>
                  <b>Drum</b>
                </td>
                <td align="center" width={"16%"}>
                  <b>Weight</b>
                </td>
                <td align="center" width={"16%"}>
                  <b>Unit</b>
                </td>
              </tr>

              {filterData.map((data, index) => (
                <tr key={index}>
                  <td align="center">{index + 1}</td>
                  <td align="center">{data.itemCode}</td>
                  <td align="center">{data.itemDesc}</td>
                  <td align="center">{data.qualityCodeName}</td>
                  <td align="center"> {data.actualQuantity}</td>
                  <td align="center">{data.actualWeight}</td>
                  <td align="center">{data.unitId}</td>
                  
                </tr>
              ))}

             
              <tr>
                <td
                  colspan="13"
                  style={{ borderTop: "none", borderBottom: "none" }}
                >
                  <b>
                    {locationState.state !== undefined ? (
                      <span>Status :{HeaderData.statusDesc}</span>
                    ) : null}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
     
      </div>
    </>
  );
};

export default JuteGateEntryPreview;
