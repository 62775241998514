export const CREATE_SHIFT_MASTER = "create_shift_master";
export const CREATE_SHIFT_MASTER_SUCCESSFULL = "create_shift_master_successfull";
export const BIO_METRIc_DEVICE ="bio_metric_device"
export const BIO_METRIc_DEVICE_SUCCESSFULL ="bio_metric_devicEsuccessfull"
export const CREATE_BIO_METRIC_DEVICE ='create_bio_metric_device';
export const CREATE_BIO_METRIC_DEVICE_SUCCESSFULL ='create_bio_metric_device_successfull';
export const GET_WORKERDETAILS_BYID='get_workerdetails_byid';
export const GET_WORKERDETAILS_BYID_SUCCESSFULL='get_workerdetails_byid_successfull';
export const DELETE_BIO_METRIC='delete_bio_metric';
export const DELETE_BIO_METRIC_SUCCESSFULL='delete_bio_metric_successfull';
export const VIEW_BY_ID_SHIFT_MASTER='view_by_id_shift_master';
export const VIEW_BY_ID_SHIFT_MASTER_SUCCESSFULL='view_by_id_shift_master_successfull';

