export const QtyCalculation = (props) => {
  switch (props.type) {
    case "CASE1":
      //quantity should not be less than 0 and if props.minqty and props.maxqty is 0 then quantity can be any number more than 0 and props.minqty and props.maxqty is not 0 then quantity should be between props.minqty and props.maxqty in case of props.qty is less than props.minqty then props.minqty will be return and if props.qty is more than props.maxqty then props.maxqty will be return and if props.qty is between props.minqty and props.maxqty then props.qty will be return heare props.maxqty = props.maxqty - (props.branchqty + props.centralqty)
      // let stage3maxqty = props.maxAllowedQty - (props.branchqty + props.centralqty);
      var finalQty;
      switch (true) {
        case props.qty < 0:
          finalQty = {
            value: 0,
            message: "Quantity can not be less than 0",
            error: true,
          };
          break;
        case props.minAllowedQty === 0 && props.maxAllowedQty === 0:
          finalQty = { value: props.qty, message: "", error: false };
          break;
        // case stage3maxqty < props.reOrderQty && props.qty > props.reOrderQty:
        //     finalQty = { 'value': props.reOrderQty, 'message': 'Quantity cannot be more than ' + props.reOrderQty ,error:true};
        //     break;
        case props.qty > props.maxAllowedQty:
          finalQty = {
            value: Number(props.maxAllowedQty.toFixed(3)),
            message:
              "Quantity can not be more than " +
              Number(props.maxAllowedQty.toFixed(3)),
            error: true,
          };
          break;
        case props.qty < props.minAllowedQty:
          finalQty = {
            value: Number(props.minAllowedQty.toFixed(3)),
            message:
              "Quantity can not be less than " +
              Number(props.minAllowedQty.toFixed(3)),
            error: true,
          };
          break;
        // case props.qty < props.minqty:
        //     finalQty = { 'value': props.minqty, 'message': 'Quantity cannot be less than ' + props.minqty, error: true };
        //     break;
        // case props.qty > stage3maxqty:
        //     finalQty = { 'value': Number(stage3maxqty.toFixed(3)), 'message': 'Quantity cannot be more than ' + Number(stage3maxqty.toFixed(3)), error: true };
        //     break;
        default:
          finalQty = { value: props.qty, message: "", error: false };
      }
      return finalQty;
    case "CASE2":
      //quantity should not be graterthan props.indentqty and if props.minqty and props.maxqty is 0 then quantity can be any number more than 0 and props.minqty and props.maxqty is not 0 then quantity should be between props.minqty and props.maxqty and quantity should not exceed props.indentqty and props.maxqty = props.maxqty - (props.branchqty + props.centralqty) in case of props.qty is less than props.minqty then props.indentqty will be return and if props.qty is more than props.maxqty then props.indentqty will be return
      var finalQty1;
      if (props.updatetype === 0) {
        if (props.remainqty === 0) {
          finalQty1 = QtyCalculationForIndent(props);
        } else {
          if (props.qty <= props.remainqty) {
            finalQty1 = QtyCalculationForIndent(props);
          } else {
            finalQty1 = {
              value: props.remainqty,
              message: "Quantity can not be more than " + props.remainqty,
              error: true,
            };
          }
        }
      } else if (props.updatetype === 1) {
        if (props.qty <= props.remainqty) {
          finalQty1 = QtyCalculationForIndent(props);
        } else {
          finalQty1 = {
            value: props.remainqty,
            message: "Quantity can not be more than " + props.remainqty,
            error: true,
          };
        }
      } else {
        finalQty1 = {
          value: props.poqty,
          message: "Quantity can not be more than " + props.poqty,
          error: true,
        };
      }
      return finalQty1;
    default:
      return { value: props.qty, message: "", error: false };

    case "CASE3":
      //quantity should not be less than 0 and if props.minqty and props.maxqty is 0 then quantity can be any number more than 0 and props.minqty and props.maxqty is not 0 then quantity should be between props.minqty and props.maxqty in case of props.qty is less than props.minqty then props.minqty will be return and if props.qty is more than props.maxqty then props.maxqty will be return and if props.qty is between props.minqty and props.maxqty then props.qty will be return heare props.maxqty = props.maxqty - (props.branchqty + props.centralqty)
      if (props.tangible === "N") {
        finalQty = { value: props.qty, message: "", error: false };
      } else {
        switch (true) {
          case props.qty < 0:
            finalQty = {
              value: 0,
              message: "Quantity can not be less than 0",
              error: true,
            };
            break;
          case props.minAllowedQty === 0 && props.maxAllowedQty === 0:
            finalQty = { value: props.qty, message: "", error: false };
            break;
          case props.qty > props.maxAllowedQty:
            finalQty = {
              value: Number(props.maxAllowedQty.toFixed(3)),
              message:
                "Quantity can not be more than " +
                Number(props.maxAllowedQty.toFixed(3)),
              error: true,
            };
            break;
          case props.qty < props.minAllowedQty:
            finalQty = {
              value: Number(props.minAllowedQty.toFixed(3)),
              message:
                "Quantity can not be less than " +
                Number(props.minAllowedQty.toFixed(3)),
              error: true,
            };
            break;

          default:
            finalQty = { value: props.qty, message: "", error: false };
        }
      }
      return finalQty;
  }
};

export const QtyCalculationForIndent = (props) => {
  let stage3maxqty1 = props.maxqty - (props.branchqty + props.centralqty);
  var finalQty1;
  switch (true) {
    case props.qty < 0:
      finalQty1 = {
        value: 0,
        message: "Quantity cannot be less than 0",
        error: true,
      };
      break;
    case props.qty > props.indentqty:
      finalQty1 = {
        value: props.indentqty,
        message: "Quantity cannot be more then Indent Quantity",
        error: true,
      };
      break;
    case props.minqty === 0 && props.maxqty === 0:
      finalQty1 = { value: props.qty, message: "", error: false };
      break;
    case props.qty < props.minqty:
      finalQty1 = {
        value: props.indentqty,
        message: "Quantity cannot be less than " + props.minqty,
        error: true,
      };
      break;
    case props.qty > stage3maxqty1:
      finalQty1 = {
        value: props.indentqty,
        message: "Quantity cannot be more than " + props.maxqty,
        error: true,
      };
      break;
    default:
      finalQty1 = { value: props.indentqty, message: "", error: false };
  }
  return finalQty1;
};

/******************************************************** */
/* SENDING PROPS TO QtyCalculation FUNCTION
-----------------------------------------------------------
Remain Qty ----> props.remainqty   ==  0
Indent Qty ----> props.indentqty   ==  6
Enter Qty  ----> props.qty         ==  2
MIN Qty    ----> props.minqty      ==  1
Max Qty    ----> props.maxqty      ==  8
Branch Qty ----> props.branchqty   ==  1
Central Qty ---> props.centralqty  ==  1
CreateOrUpdate-> props.updatetype  == 0/1
Previws POQty--> props.poqty       ==  2


reordeqty >= qty
/********************************************************* */
