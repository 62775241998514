import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { PR_RESPONSE_LIST } from "./actionTypes";
import { PrResponseListSuccessfull } from "./actions";
import { getList } from "../../../helpers/Server_Helper";

function* getPrResponseList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(PrResponseListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

export function* watchPrResponseList() {
  yield takeEvery(PR_RESPONSE_LIST, getPrResponseList);
}

function* PrResponseSaga() {
  yield all([fork(watchPrResponseList)]);
}

export default PrResponseSaga;
