import { TERMS_LIST, CREATE_TERMS, UPDATE_TERMS } from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getList, postAdd } from "../../../helpers/Server_Helper";
import {
  termsListSuccessfull,
  createTermsSuccessfull,
  updateTermsSuccessfull,
} from "./actions";
import swal from "sweetalert";
import { hideSpinner, showSpinner } from "../../../helpers/Consts";

function* termsList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(termsListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateTerms({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(createTermsSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/terms");
      hideSpinner(true);
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateTerms({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(updateTermsSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });

    history.push("/terms");
    hideSpinner(true);
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

export function* watchTermsList() {
  yield takeEvery(TERMS_LIST, termsList);
}

export function* watchCreateTerms() {
  yield takeEvery(CREATE_TERMS, CreateTerms);
}
export function* watchUpdateTerms() {
  yield takeEvery(UPDATE_TERMS, UpdateTerms);
}

function* TermsSaga() {
  yield all([
    fork(watchTermsList),
    fork(watchCreateTerms),
    fork(watchUpdateTerms),
  ]);
}

export default TermsSaga;
