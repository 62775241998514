import {WEAVING_QUALITY_MASTER_LIST,WEAVING_QUALITY_MASTER_LIST_SUCCESSFULL,CREATE_WEAVING_QUALITY_MASTER_SUCCESSFULL,CREATE_WEAVING_QUALITY_MASTER,
  UPDATE_WEAVING_QUALITY_MASTER,
  UPDATE_WEAVING_QUALITY_MASTER_SUCCESSFULL,
  API_ERRORS,
 } from './actionTypes';

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    WeavingQualityMasterList: [],
    CreateWeavingQualityMaster:[],
    UpdateWeavingQualityMaster:[],
  };

  const WeavingQualityMasterReducer = (state = initialState, action) => {
    switch (action.type) {
        case WEAVING_QUALITY_MASTER_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case WEAVING_QUALITY_MASTER_LIST_SUCCESSFULL:
            state = {
              ...state,
              WeavingQualityMasterList: action.payload.response,
              loading: true,
            };
            break;
            case CREATE_WEAVING_QUALITY_MASTER:
              state = {
                ...state,
                loading: true,
              };
              break;
            case CREATE_WEAVING_QUALITY_MASTER_SUCCESSFULL:
              state = {
                ...state,
                CreateWeavingQualityMaster: action.payload.response,
                loading: true,
              };
              break;
              case UPDATE_WEAVING_QUALITY_MASTER:
              state = {
                ...state,
                loading: true,
              };
              break;
            case UPDATE_WEAVING_QUALITY_MASTER_SUCCESSFULL:
              state = {
                ...state,
                UpdateWeavingQualityMaster: action.payload.response,
                loading: true,
              };
              break;
              case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default WeavingQualityMasterReducer;