import {WORK_ORDER_LIST,WORK_ORDER_LIST_SUCCESSFULL, CREATE_WORK_ORDER, CREATE_WORK_ORDER_SUCCESSFULL, UPDATE_PO_WO, UPDATE_PO_WO_SUCCESSFULL} from './actionTypes';



export const getWorkOrderList = (url,data,history) =>{
    return {
        type:WORK_ORDER_LIST,
        payload:{url,data,history}
    }
}

export const WorkOrderListSuccessfull = (data)=>{
    return {
        type:WORK_ORDER_LIST_SUCCESSFULL,
        payload:data
    }
}

export const CreateWorkOrder = (url,data,history,pathname) =>{
    return {
        type:CREATE_WORK_ORDER,
        payload:{url,data,history,pathname}
    }
}

export const CreateWorkOrderSuccessfull = (data)=>{
    return {
        type:CREATE_WORK_ORDER_SUCCESSFULL,
        payload:data
    }
}

export const UpdatePoWo = (url,data,history,pathname) =>{
    return {
        type:UPDATE_PO_WO,
        payload:{url,data,history,pathname}
    }
}

export const UpdatePoWoSuccessfull = (data)=>{
    return {
        type:UPDATE_PO_WO_SUCCESSFULL,
        payload:data
    }
}

