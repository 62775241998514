import {
    API_ERRORS,
    COMPANIES,
    COMPANIES_LIST_SUCCESSFUL,
  } from "./actionTypes";
  
  const initialState = {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    companiesList: [],
    companiesCoulmns: [],
    clientColumn: [],
    error_msg: null,
  };
  
  const basicdetails = (state = initialState, action) => {
    switch (action.type) {    
      case COMPANIES:
        state = {
          ...state,
          loading: true,
        };
        break;
      case COMPANIES_LIST_SUCCESSFUL:
        state = {
          ...state,
          companiesList: action.payload.response.data,
          companiesCoulmns: action.payload.response.column,
          loading: false,
        };
        break;
      case API_ERRORS:
        state = {
          ...state,
          loading: false,
          error_msg: action.payload,
        };
        break;
  
      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default basicdetails;
  