import * as React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button'


export default function InputWithIcon(props) {
  const handleChange = (event) => {
    const { value } = event.target
    if(props.isNumber){
      if(!isNaN(+value)){
        props.handleChange(props.name,value, props.lineItemId)
      }   
    }else{
      props.handleChange(props.name,value, props.lineItemId)
    }
     
 }

 const onclick=()=>{
  props.onClick()
 }
 
  return (
    <div className="consoleAdormentBlock">
      <TextField
        onChange={handleChange}
        type={props.type}
        value={props.value}
        disabled={props.disabled}
        fullWidth
        onWheel={e => e.target.blur()}
        onKeyDown={props.type ==='number'? (e) =>["e", "E", "+", "-", ].includes(e.key) && e.preventDefault():()=>{}}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {props.label&& <span>{props.label}</span>}
              {props.btnLable&&
              <Button onClick={onclick}>{props.btnLable}</Button>
              }
              {/* <img src={props.imgUrl} alt="" /> */}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
