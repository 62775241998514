import {
  BEAMING_CREATE,
  BEAMING_CREATE_SUCCESSFULL,
  BEAMING_DD_LIST,
  BEAMING_DD_LIST_SUCCESSFULL,
  BEAMING_DELETE_ROW,
  BEAMING_DELETE_ROW_SUCCESSFULL,
  BEAMING_LIST,
  BEAMING_LIST_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  beamingProductionLists: [],
  beamingProductionDdLists:[],
  beamingCreateData:[],
  beamingDelRowData:[],
};

const BeamingProductionListReducer = (state = initialState, action) => {
  switch (action.type) {
    case BEAMING_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case BEAMING_LIST_SUCCESSFULL:
      state = {
        ...state,
        beamingProductionLists: action.payload.response,
        loading: true,
      };
      break;
      case BEAMING_DD_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case BEAMING_DD_LIST_SUCCESSFULL:
      state = {
        ...state,
        beamingProductionDdLists: action.payload.response,
        loading: true,
      };
      break;
      case BEAMING_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case BEAMING_CREATE_SUCCESSFULL:
      state = {
        ...state,
        beamingCreateData: action.payload.response,
        loading: true,
      };
      break;
      case BEAMING_DELETE_ROW:
      state = {
        ...state,
        loading: true,
      };
      break;
    case BEAMING_DELETE_ROW_SUCCESSFULL:
      state = {
        ...state,
        beamingDelRowData: action.payload.response,
        loading: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default BeamingProductionListReducer;
