import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import { getLeaveTypesList } from "../../../store/Master/LeaveTypeMaster/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      leaveTypeListData: [], // To displaying leaveType list data
    };
  }
  componentDidMount() {
    const Data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      leaveTypeCode: null,
      leaveDesc: null,
      payable: null,
    };
    this.props.getLeaveTypesList(
      serverApi.GET_ALL_LEAVE_TYPES_LIST,
      Data,
      this.props.history
    ); // calling api for Leave type list
  }
  componentWillReceiveProps(props) {
    if (props.LeaveType_List) {
      this.setState({
        leaveTypeListData: props.LeaveType_List.data, // updating leave Type list data
      });
    }
  }
  handleSearch = (data) => {
    const searchData = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      leaveTypeCode: data.leaveTypeCode === "" ? null : data.leaveTypeCode,
      leaveTypeDescription: data.leaveDesc === "" ? null : data.leaveDesc,
      payable: data.payable === "" ? null : data.payable,
    };
    this.props.getLeaveTypesList(
      serverApi.GET_ALL_LEAVE_TYPES_LIST,
      searchData,
      this.props.history
    ); // calling api for Leave type list
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.leaveTypeListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={false}
          actions={true}
          url="/create_leave_types"
          filter_form={"leaveType"}
          handleSearch={this.handleSearch}
        />
      </>
    );
  }
}
// leave type list headers
const Columns = [
  {
    Header: "Leave Type Code",
    accessor: "leaveTypeCode",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Leave Description",
    accessor: "leaveTypeDescription",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Payable",
    accessor: "payable",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
];

const mapStatetoProps = (state) => {
  const { LeaveType_List } = state.LeaveTypeListReducer; // fetching list from LeaveType list Reducer
  return { LeaveType_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getLeaveTypesList,
  })(Index)
);
