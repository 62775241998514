import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import moment from "moment";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import { serverApi, getTenantId } from "../../../helpers/Consts";
import { isEmpty } from "../../../Utilities/helper";

const QuotationPreview = (props) => {
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [phoneNo, setphoneNo] = useState("");
  const [email, setemail] = useState("");
  const [companyLogo, setcompanyLogo] = useState("");
  const [editButton, seteditButton] = useState(true);
  const [gstNo1, setGstNo1] = useState("");
  const [gstNo2, setGstNo2] = useState("");

  const [LineItems, setLineItems] = useState([]);
  const [quoteHdr, setquoteHdr] = useState({});

  useEffect(() => {
    let companyData = JSON.parse(localStorage.getItem("authUser"));
    let companyId = localStorage.getItem("companyId");
    companyData.companyDetails.map((p) => {
      let id = p.value;
      if (companyId === id.toString()) {
        setcompanyName(p.label);
        setcompanyLogo(p.companyLogo);
        setphoneNo(p.contactNo);
        setemail(p.emailId);
        setaddress1(p.address1);
        setaddress2(p.address2);
        setGstNo1(p.gstNo);
        setGstNo2(p.gstNo2);
      }
    });
  });

  const { quoteLineItems, quoteHeader } = useSelector(
    (state) => state.QuotationReducer
  );

  useEffect(() => {
    setLineItems(quoteLineItems);
    setquoteHdr(quoteHeader);
  }, [quoteLineItems, quoteHeader]);

  const handleEdit = () => {
    props.handleCurrentStep(1);
  };

  const handlePdf = (printType) => {
    if (props.locationState.state !== undefined) {
      fetch(
        serverApi.QUOTATION_PDF_DOWNLOAD +
          quoteHdr.quotationId +
          "/" +
          printType +
          "/" +
          JSON.parse(localStorage.getItem("authUser")).userId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            "X-TenantId": getTenantId(window.location.hostname),
            Authorization:
              localStorage.getItem("token_type") +
              localStorage.getItem("access_token"),
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", quoteHdr.quotationNo + ".pdf");
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
    }
  };

  const convertNumberToWords = (amount) => {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();

    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = "";
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  };

  const withDecimal = (n) => {
    if (n !== undefined) {
      var nums = n.toString().split(".");
      var whole = convertNumberToWords(nums[0]);

      if (nums.length == 2) {
        if (parseInt(nums[1]) > 0) {
          return whole + " Rupees only";
        }
      }
      return whole + "Rupees only";
    }
  };

  let conversionAmountTotalInWords = withDecimal(quoteHdr.totalAmount);

  let filterProduct = LineItems.filter((item) => {
    if (item.itemGroupValue === "997") {
      return (
        item.isActive !== 0 &&
        item.itemGroupId !== null &&
        item.itemGroupId !== "" &&
        Number(item.taxPercent) !== 0
      );
    } else {
      return (
        item.isActive !== 0 &&
        item.itemGroupId !== null &&
        item.itemGroupId !== ""
      );
    }
  });
  if (
    quoteHdr.updatedOn !== null &&
    quoteHdr.updatedOn !== "" &&
    quoteHdr.updatedOn !== undefined
  ) {
    var updatedOn = moment(quoteHdr.updatedOn, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    );
  }

  const covertNumbersToDecimals = (number) => {
    return parseFloat(number).toFixed(2);
  };
  let otherChargesItems = LineItems.filter(
    (item) =>
      item.isActive !== 0 &&
      item.itemGroupValue === "997" &&
      Number(item.taxPercent) === 0
  );
  return (
    <>
      <div className="previewBlock">
        <div
          classname="helloworld"
          style={{
            display: "flex",
            justifyContent: "end",
            padding: "10px",
            marginTop: "20px",
          }}
        >
          {props.locationState.state &&
            quoteHdr.statusId !== "" &&
            !isEmpty(quoteHdr.brokerId) && (
              <CustomButton
                label="Broker Copy Print"
                className="lightBlueButton"
                muIcon={<PrintIcon />}
                handleClick={() => {
                  handlePdf(2);
                }}
                type="button"
              ></CustomButton>
            )}
          {props.locationState.state &&
            quoteHdr.statusId !== "" &&
            !isEmpty(quoteHdr.customerId) && (
              <CustomButton
                label="Consignee Copy Print"
                className="lightBlueButton"
                muIcon={<PrintIcon />}
                handleClick={() => {
                  handlePdf(1);
                }}
                type="button"
              ></CustomButton>
            )}
          {props.locationState.state && quoteHdr.UpdateButton === true && (
            <CustomButton
              label="Edit"
              muIcon={<EditIcon />}
              className="lightBlueButton"
              handleClick={handleEdit}
              type="button"
            ></CustomButton>
          )}
        </div>
        <div id="qutationPreview" style={{ marginTop: "10px" }}>
          <table
            cellpadding="0"
            cellspacing="0"
            width="100%"
            className="previewTable"
          >
            <tbody>
              <tr>
                <td
                  colspan="5"
                  style={{ borderRight: "none", borderBottom: "none" }}
                >
                  <img src={companyLogo} width="100%" alt="" />
                </td>
                <td
                  colspan="10"
                  style={{
                    borderBottom: "none",
                    borderLeft: "none",
                    textAlign: "right",
                  }}
                >
                  <h2>{companyName}</h2>
                  {address1}
                  <br />
                  {address2}
                  <br />
                  <span>
                    <b>Ph.No: </b>
                    {phoneNo},{" "}
                  </span>
                  <span>
                    <b>Email: </b> {email}
                  </span>
                  <br />
                  <b>GST No:</b> {gstNo1 || "n/a"}
                  <br />
                  <br></br>
                </td>
              </tr>
              <tr>
                <td
                  colspan="15"
                  style={{ textAlign: "center", borderTop: "none" }}
                >
                  <h2>QUOTATION</h2>
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <b>QUOTATION NO : </b>
                </td>
                <td
                  colspan="3"
                  className={quoteHdr.quotationNo == "" ? "blur-text" : ""}
                >
                  {quoteHdr.quotationNo == ""
                    ? "Will be generated after creation"
                    : quoteHdr.quotationNo}
                </td>
                <td colspan="4">
                  <b>DATE :</b>
                </td>
                <td colspan="4">
                  {moment(quoteHdr.quotationDate).format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Expiry Date :</b>
                </td>
                <td colspan="3">
                  {moment(quoteHdr.quotationExpiryDate).format("DD-MM-YYYY")}
                </td>
                <td colspan="4">
                  <b>Consignee :</b>
                </td>
                <td colspan="4">{quoteHdr.customerName}</td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Broker :</b>
                </td>
                <td colspan="3">{quoteHdr.brokerName}</td>
                <td colspan="4">
                  <b>Branch :</b>
                </td>
                <td colspan="4">{quoteHdr.branchName}</td>
              </tr>
              <tr>
                <td
                  colspan="5"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    padding: "none",
                  }}
                >
                  <br />
                  <b>Billed To :</b> {quoteHdr.customerName} -
                  <br /> {quoteHdr.billingToAddress || "N/A"}
                  <br />
                  <br />
                  <b>GSTIN :</b> {quoteHdr.billingGstNo || "N/A"}
                  <br />
                  <b>Contact Person : </b>{" "}
                  {quoteHdr.billingContactPerson || "N/A"}
                  <br />
                  <b>Contact Number :</b> {quoteHdr.billingContactNo || "N/A"}
                  <br />
                  <b>EMAIL ID :</b> {quoteHdr.billingToEmail}
                </td>
                <td
                  colspan="9"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    padding: "none",
                  }}
                >
                  {quoteHdr.deliveryTerms === "Ex-Works" ? (
                    <>{}</>
                  ) : (
                    <>
                      <b>Shipped To : </b>
                      {quoteHdr.customerName} -
                      <br /> {quoteHdr.shippingToAddress || "N/A"}
                      <br />
                      <br />
                      <b>GSTIN : </b> {quoteHdr.shippingGstNo || "N/A"}
                      <br />
                      <b>Contact Person : </b>{" "}
                      {quoteHdr.shippingContactPerson || "N/A"}
                      <br />
                      <b>Contact Number :</b>{" "}
                      {quoteHdr.shippingContactNo || "N/A"}
                      <br />
                      <b>EMAIL ID :</b> {quoteHdr.shippingToEmail}
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td rowspan="2" align={"center"} width={"5%"}>
                  <b>S.No</b>
                </td>
                <td rowspan="2" align={"center"} width={"8%"}>
                  <b>Item CODE</b>
                </td>
                <td rowspan="2" align={"center"} width={"10%"}>
                  <b>Item Group</b>
                </td>
                <td rowspan="2" align={"center"} width={"20%"}>
                  <b>Item Desc</b>
                </td>
                <td rowspan="2" align={"center"} width={"9%"}>
                  <b>Quantity/UOM</b>
                </td>

                <td rowspan="2" align={"center"} width={"8%"}>
                  <b>RATE</b>
                </td>
                <td rowspan="2" align={"center"} width={"8%"}>
                  <b>Rate after discount</b>
                </td>
                <td rowspan="2" align={"center"} width={"8%"}>
                  <b>Amount</b>
                </td>
                <td colspan="2" align="center" width={"8%"}>
                  <b>IGST</b>
                </td>
                <td colspan="2" align="center" width={"8%"}>
                  <b>CGST</b>
                </td>
                <td colspan="2" align="center" width={"8%"}>
                  <b>SGST</b>
                </td>
              </tr>
              <tr>
                <td>
                  <b>%</b>
                </td>
                <td>
                  <b>Amt</b>
                </td>
                <td>
                  <b>%</b>
                </td>
                <td>
                  <b>Amt</b>
                </td>
                <td>
                  <b>%</b>
                </td>
                <td>
                  <b>Amt</b>
                </td>
              </tr>
              {filterProduct &&
                filterProduct.map((item, i) => {
                  return (
                    <>
                      <tr>
                        <td>{i + 1}</td>
                        <td>{item.itemCode}</td>
                        <td>{item.itemGroupName}</td>
                        <td>
                          {item.itemName}
                          <br />
                          <span>
                            <b>Remarks</b> : {item.remarks}
                          </span>{" "}
                          <br />
                          <span>
                            <b>Make</b> : {item.make}
                          </span>
                          <br />
                          <span>
                            <b>HSN </b> : {item.hsnCode}
                          </span>
                        </td>
                        <td align="right">
                          {parseFloat(item.quantity).toFixed(3)} {item.uom}
                        </td>

                        <td align="right">
                          {" "}
                          {parseFloat(item.rate).toFixed(3)}
                        </td>

                        <td align="right">
                          {parseFloat(item.discountedRate).toFixed(3)}
                        </td>
                        <td align="right">
                          {parseFloat(Number(item.netAmount)).toFixed(2)}
                        </td>
                        <td align="right">
                          {parseFloat(Number(item.igstPercent)).toFixed(2)}
                        </td>
                        <td align="right">
                          {parseFloat(Number(item.igstAmount)).toFixed(2)}
                        </td>
                        <td align="right">
                          {parseFloat(Number(item.cgstPercent)).toFixed(2)}{" "}
                        </td>
                        <td align="right">
                          {parseFloat(item.cgstAmount).toFixed(2)}
                        </td>
                        <td align="right">
                          {parseFloat(item.sgstPercent).toFixed(2)}
                        </td>
                        <td align="right">
                          {parseFloat(item.sgstAmount).toFixed(2)}
                        </td>
                      </tr>
                    </>
                  );
                })}
              <tr>
                <td colspan="3">
                  <b>Taxable Value</b>
                </td>
                <td colspan="17">
                  {covertNumbersToDecimals(quoteHdr.netTotal)}
                </td>
              </tr>
              {otherChargesItems &&
                otherChargesItems.map((item) => {
                  return (
                    <tr>
                      <td colspan="3">
                        <b> {item.itemName}</b>
                      </td>
                      <td colspan="16">
                        {parseFloat(item.netAmount).toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <td colspan="3">
                  <b>Total IGST</b>
                </td>
                <td colspan="17">
                  {" "}
                  {covertNumbersToDecimals(quoteHdr.totalIGST)}
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Total CGST</b>
                </td>
                <td colspan="17">
                  {covertNumbersToDecimals(quoteHdr.totalCGST)}
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Total SGST</b>
                </td>
                <td colspan="17">
                  {covertNumbersToDecimals(quoteHdr.totalSGST)}
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Total Value with Tax</b>
                </td>
                <td colspan="17">
                  {covertNumbersToDecimals(quoteHdr.totalAmount)}
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Total Value with Tax in words</b>
                </td>
                <td colspan="17">{conversionAmountTotalInWords}</td>
              </tr>
              <tr>
                <td
                  colspan="20"
                  style={{
                    borderTop: "none",
                    borderRight: "none",
                    borderBottom: "none",
                    marginTop: "5px",
                  }}
                >
                  {isEmpty(quoteHdr.customerId) ? (
                    <span style={{ color: "red" }}>
                      {" "}
                      Note: The Tax for the Items is not applied since a
                      customer has not been selected.
                    </span>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
              <tr>
                <td
                  colspan="20"
                  style={{
                    borderTop: "none",
                    borderRight: "none",
                    borderBottom: "none",
                    marginTop: "5px",
                  }}
                >
                  <br></br>
                  <b>Delivery Days: </b>{" "}
                  {quoteHdr.deliveryDays
                    ? quoteHdr.deliveryDays + " " + "Days"
                    : "N/A"}
                  <br />
                  <b>Delivery Terms: </b> {quoteHdr.deliveryTerms || "N/A"}
                  <br />
                  <b>Payment Terms (In Days): </b>{" "}
                  {quoteHdr.paymentTerms || "N/A"}
                  <br />
                  <b>Footer Notes: </b> {quoteHdr.footerNotes || "N/A"}
                  <br />
                  <b>Internal Notes: </b> {quoteHdr.internalNote || "N/A"}
                  <br />
                  <br />
                  <b>Broker Percentage:</b>
                  {quoteHdr.brokeragePercentage
                    ? parseFloat(Number(quoteHdr.brokeragePercentage)).toFixed(
                        2
                      )
                    : "0.00"}
                  %
                </td>
              </tr>
              <tr>
                <td
                  colspan="20"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                  }}
                >
                  <b>Terms and Conditions: </b> <br />
                  {quoteHdr.termsCondition}
                  <br />
                  {""}
                </td>
              </tr>
              <tr>
                <td
                  colspan="9"
                  style={{
                    borderTop: "none",
                    borderRight: "none",
                    borderBottom: "none",
                    marginTop: "5px",
                  }}
                >
                  <br />
                  <br />
                  <b>Last Modified On: </b> {updatedOn}
                  <br />
                  <b>Last Modified By: </b> {quoteHdr.updatedBy}
                  <br />
                  <br />
                </td>
              </tr>
              <tr>
                <td
                  colspan="9"
                  style={{
                    borderTop: "none",
                    borderRight: "none",
                    borderBottom: "none",
                    marginTop: "5px",
                  }}
                >
                  <b>Status: {quoteHdr.statusName}</b>
                  <br />
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default QuotationPreview;
