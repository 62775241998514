import {GETALL_PAYSCHEMES_LIST,GETALL_PAYSCHEMES_LIST_SUCCESSFULL} from "./actionType";

export const getAllPaySchemeList = (url,data,history) =>{
    return {
        type:GETALL_PAYSCHEMES_LIST,
        payload:{url,data,history}
    }
}

export const allPaySchemeListSuccessfull = (data)=>{
    return {
        type:GETALL_PAYSCHEMES_LIST_SUCCESSFULL,
        payload:data
    }
}