import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import moment from "moment";
import {
  createWorkerCategory,
  updateWorkerCategory,
} from "../../../store/Master/WorkerCategoryMaster/actions";
import { validate } from "../../../Utilities/Validations";
import swal from "sweetalert";

class CreateWorkerCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      createdBy: "",
      categoryId: "",
      categoryCode: "",
      categoryName: "",
      createdOn: "",
      createdDate: "",
      locationState:"",
    };
  }
  /* Calling Create and Update Api's to Actions */
  onClickCreateUpdate = () => {
    const { categoryCode, categoryName } = this.state;

    const validateFields = [
      { field: categoryCode, msg: "Category Code", type: 1 },
      { field: categoryName, msg: "Category Name", type: 1 },
    ];
    let validObj = validate(validateFields);
    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message}`);
      }
      if (validObj.type === 2) {
        swal(`Please Select ${validObj.message}`);
      }
    } else {
      if (this.props.location.state === undefined) {
        const data = {
          companyId: this.state.companyId,
          createdBy: this.state.userDit.userId.toString(),
          cataCode: this.state.categoryCode,
          cataDesc: this.state.categoryName,
          userId: this.state.userDit.userId.toString(),
        };
        this.props.createWorkerCategory(
          serverApi.CREATE_WORKER_CATEGORY,
          data,
          this.props.history
        ); // calling create worker category api
      } else {
        const data = {
          createdBy: this.state.userDit.userId.toString(),
          companyId: this.state.companyId,
          createdOn: this.props.location.state.rowData.autoDateTime,
          cataCode: this.state.categoryCode,
          cataDesc: this.state.categoryName,
          cataId: this.state.categoryId,
          userId: this.state.userDit.userId.toString(),
          updated: this.props.location.state.rowData.updated,
        };
        this.props.updateWorkerCategory(
          serverApi.UPDATE_WORKER_CATEGORY,
          data,
          this.props.history
        ); // calling update Worker category api
      }
    }
  };
  // function for previous page
  onClickCancel = () => {
    this.props.history.push("/Worker_Category_Master");
  };
  //function for TextFields
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "categoryId") {
      this.setState({
        categoryId: event,
      });
    }
    if (key === "categoryCode") {
      this.setState({
        categoryCode: event,
      });
    }
    if (key === "categoryName") {
      this.setState({
        categoryName: event,
      });
    }
  };
  // function for popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }

  componentDidMount() {
    this.setState({
      locationState:this.props.location,
    })
    if (this.props.location.state) {
      this.setState({
        createdBy: this.props.location.state.rowData.createdBy,
        createdOn: this.props.location.state.rowData.autoDateTime,
        companyId: this.props.location.state.rowData.companyId,
        categoryCode: this.props.location.state.rowData.cataCode,
        categoryName: this.props.location.state.rowData.cataDesc,
        categoryId: this.props.location.state.rowData.cataId,
        updated: this.props.location.state.rowData.updated,
        userId: this.props.location.state.rowData.userId,
      });
      const dateEntered = this.props.location.state.rowData.autoDateTime;
      const dateIsValid = moment(dateEntered).format("LLLL");
      this.setState({
        createdDate: dateIsValid,
      });
    }
  }

  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <TextFieldReq
                      value={this.state.categoryId}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("categoryId")}
                      label="Category Id"
                      readOnly={true}
                    />
                  )}
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.categoryCode}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("categoryCode")}
                      label="Category Code"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.categoryName}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("categoryName")}
                      label="Category Name"
                      disabled={this.state.categoryCode === "" ? true : false}
                    />
                  </Box>
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdDate}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.state.locationState.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
          {/* common popup for validation */}
          {this.state.confirm && (
            <CommonPopup>
              <div className="delAddItem">
                <div className="mandatoryFieldText">
                  Please select all the mandatory fields
                </div>
                <div className="delAddItemBtns">
                  <input
                    type="button"
                    onClick={this.onclickPopUP.bind(this)}
                    value="Ok"
                    className="delYesBtn"
                  />
                </div>
              </div>
            </CommonPopup>
          )}
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {};

export default withRouter(
  connect(mapStatetoProps, {
    createWorkerCategory,
    updateWorkerCategory,
  })(CreateWorkerCategory)
);
