import React, { useState, useEffect } from "react";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import { ClearJutePOHeader, ClearPoLineItems, JutePurchaseList } from "../../../store/Jute/JutePurchase/actions";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

function JutePurchaeOrderList(props) {
  const [userDit, setUserDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [companyId] = useState(localStorage.getItem("companyId"));
  const [JutePoListDataState, setJutePoListDataState] = useState([]);
  const [supplierListMultiselectData, setSupplierListMultiselectData] = useState([]);
  const [locationState] = useState(useLocation());
  const [fromDate, setfromDate] = useState( moment().day(-7).format("DD-MM-YYYY"));
  const [toDate, settoDate] = useState(moment().format("DD-MM-YYYY"));
  const dispatch = useDispatch();
  let history = useHistory();

  const { supplierListMultiselect } = useSelector(
    (state) => state.DropDownListReducer
  );
  const { JutePoListData } = useSelector(
    (state) => state.JutePurchaseReducer
  );
  useEffect(() => {
    const financialYearStartMonth = 3; // Assuming financial year starts in April
    let financialYearStart = moment()
      .year(localStorage.getItem("acadamicYear"))
      .month(financialYearStartMonth)
      .startOf("month");

    const fromDate = financialYearStart.day(-7).format("YYYY-MM-DD");
    const toDate = moment().format("YYYY-MM-DD");
    var startDate = moment(fromDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var endDate = moment(toDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    setfromDate(startDate)
    settoDate(endDate)
    const data = {
      cipher: userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      purchaseType: "J",
      frmDt: fromDate,
      toDt: toDate,
      mukam: "",
      statusId: "",
      poNum: "",
      supplierId: "",
      financialYear: localStorage.getItem("acadamicYear"),
    };
    
    dispatch(JutePurchaseList(serverApi.JUTE_PO_LIST, data, history))
    dispatch(ClearJutePOHeader())
    dispatch(ClearPoLineItems())
  }, []);

  useEffect(() => {
    if (supplierListMultiselect) {
      setSupplierListMultiselectData(supplierListMultiselect.data);
    }
    if (JutePoListData) {
      setJutePoListDataState(JutePoListData.data);
    }
  }, [supplierListMultiselect, JutePoListData]);

  const handleSearch = (searchData) => {
    var startDate = moment(searchData.fromDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var endDate = moment(searchData.toDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    setfromDate(startDate)
    settoDate(endDate)
    const data = {
      cipher: userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      purchaseType: "J",
      frmDt: searchData.fromDate,
      toDt:  searchData.toDate,
      mukam:  searchData.mukamValue,
      statusId: searchData.statusValue,
      poNum:  searchData.PoNumber,
      supplierCode:searchData.supplierValue,
      financialYear: localStorage.getItem("acadamicYear"),
    };
    dispatch(JutePurchaseList(serverApi.JUTE_PO_LIST, data, history))
  };

  return (
    <>
      <Gridwithcustomview
        mdata={JutePoListDataState}
        mcolumns={Columns}
        status={true}
        micon={GridArrowIcon}
        filter_form={"JutePurchaseOrder"}
        handleSearch={handleSearch}
        actions={true}
        url="/create_jute_purchase_order"
        fromDate={fromDate}
        toDate={toDate}
      />
    </>
  );
};

const Columns = [
  {
    Header: "PO No",
    accessor: "poNo",
    hidden: false,
  },
  {
    Header: "PO Date",
    accessor: "poDate",
    hidden: false,
  },
  {
    Header: "Supplier Name",
    accessor: "supplierName",
    hidden: false,
  },
  {
    Header: "Mukam",
    accessor: "mukam",
    hidden: false,
  },
  {
    Header: "Vehicle Type",
    accessor: "vehicleType",
    hidden: false,
  },
  {
    Header: "Vehicle Qty",
    accessor: "vehicleQty",
    hidden: false,
  },
  {
    Header: "Rcd.Qty",
    accessor: "receivedQty",
    hidden: false,
  },
  {
    Header: "Pend.Qty",
    accessor: "pendingQty",
    hidden: false,
  },
  {
    Header: "Broker",
    accessor: "broker",
    hidden: false,
  },
  {
    Header: "Weight",
    accessor: "weight",
    hidden: false,
  },
  {
    Header: "PO Val",
    accessor: "poValue",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "status",
    hidden: false,
  },
  {
    Header: "Type Of Creatrion",
    accessor: "typeOfCreation",
    hidden: false,
  },
];

export default JutePurchaeOrderList;