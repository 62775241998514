import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import {
  ITEMGROUP_LIST,
  CREATE_ITEMGROUP_MASTER,
  UPDATE_ITEMGROUP_MASTER,
  ITEM_GROUP_VIEW_BY_ID,
  ITEM_GROUP_DROPDOWN_API,
} from "./actionTypes";
import {
  ItemGroupMasterListSuccessfull,
  createItemGroupMasterSuccessfull,
  updateItemGroupMasterSuccessfull,
  getViewByIdSuccessfully,
  getItemGroupMasterList,
  getItemGroupDropdownListSuccessfull,
} from "./actions";
import { getList, getListurl } from "../../../helpers/Server_Helper";
import swal from "sweetalert";
import { hideSpinner, serverApi, showSpinner } from "../../../helpers/Consts";

function* getItemGroupList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(ItemGroupMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* createItemGroupMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    showSpinner();
    yield put(createItemGroupMasterSuccessfull({ response }));
    if (response.status === true) {
      swal(response.message, { icon: "success" });
      const data = {
        cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
        companyId: localStorage.getItem("companyId"),
        userId: JSON.parse(localStorage.getItem("authUser")).userId,
        startIndex: 1,
        lastIndex: 10,
      };
      yield put(
        getItemGroupMasterList(serverApi.GET_ALL_ITEM_GROUP_LIST, data, history)
      );
      hideSpinner();
      history.push("/item_group_master");
    } else {
      hideSpinner();
      swal(response.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        hideSpinner();
      }
    }
  }
}
function* updateItemGroupMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    showSpinner();
    yield put(updateItemGroupMasterSuccessfull({ response }));
    if (response.status === true) {
      swal(response.message, { icon: "success" });
      hideSpinner();
      history.push("/item_group_master");
    } else {
      swal(response.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
      }
    }
  }
}

function* getItemGroupViewById({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    if (response.status) {
      yield put(getViewByIdSuccessfully({ response }));
      swal(response.message, { icon: "success" });
    } else {
      swal(response.message, { icon: "error" });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getItemGroupDropdownListData({ payload: { url } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getItemGroupDropdownListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

export function* watchItemGroupList() {
  yield takeEvery(ITEMGROUP_LIST, getItemGroupList);
}
export function* watchCreateItemGroupMaster() {
  yield takeEvery(CREATE_ITEMGROUP_MASTER, createItemGroupMaster);
}
export function* watchUpdateItemGroupMaster() {
  yield takeEvery(UPDATE_ITEMGROUP_MASTER, updateItemGroupMaster);
}
export function* watchGetItemViewById() {
  yield takeEvery(ITEM_GROUP_VIEW_BY_ID, getItemGroupViewById);
}
export function* watchItemGroupdropdownAPI() {
  yield takeEvery(ITEM_GROUP_DROPDOWN_API, getItemGroupDropdownListData);
}

function* ItemGroupMasterListSaga() {
  yield all([
    fork(watchItemGroupList),
    fork(watchCreateItemGroupMaster),
    fork(watchUpdateItemGroupMaster),
    fork(watchGetItemViewById),
    fork(watchItemGroupdropdownAPI),
  ]);
}

export default ItemGroupMasterListSaga;
