import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import { getStockReturnList } from "../../../store/StoreModule/StockReturn/actions";

class StockReturnMasterIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      consumptionListData: [],
    };
  }

  componentDidMount() {
    const data = {
      companyId: this.state.companyId,
      erpType: "1",
      finnacialYear: "2022",
    };
    this.props.getStockReturnList(
      serverApi.GET_ALL_STOCK_RETURN_LIST,
      data,
      this.props.history
    );
  }
  componentWillReceiveProps(props) {
    if (props.StockReturn_List) {
      this.setState({
        consumptionListData: props.StockReturn_List.data,
      });
    }
  }

  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.consumptionListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={false}
          actions={true}
          url="/create_StockReturn_master"
        />
      </>
    );
  }
}
const Columns = [
  {
    Header: "Return No",
    accessor: "hdrId",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Date",
    accessor: "returnDate",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Customer",
    accessor: "customerName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Branch",
    accessor: "branchName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Project",
    accessor: "projectName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "ExpeType",
    accessor: "indentType",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "status",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
];

const mapStatetoProps = (state) => {
  const { StockReturn_List } = state.StockReturn_List;
  return { StockReturn_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getStockReturnList,
  })(StockReturnMasterIndex)
);
