import React from 'react'
import PropTypes from 'prop-types'

const CheckboxIsCorr = ({
  label,
  selected,
  checked,
  styleClass,
  onChange,
  name,
  image,
  iconSizeClass,
  disabled,
}) => {
  const handleChange = (event) => {
    const { Name, Checked, Disabled } = event.target
    onChange(Name, Checked, Disabled)
  }

  return (
    <div className="checkboxBlock">
      <input
        type="checkbox"
        className="checkbox"
        value={selected}
        defaultChecked={selected}
        checked={checked}
        onChange={handleChange}
        name={name}
        disabled={disabled}
      />
      <img src={image} alt="" className={iconSizeClass} />
      {label}
    </div>
  )
}

CheckboxIsCorr.propTypes = {
  styleClass: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

CheckboxIsCorr.defaultProps = {
  styleClass: 'regular-checkbox',
}

export default CheckboxIsCorr
