import React, { useState, useEffect } from "react";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import { useDispatch, useSelector } from "react-redux";
import { JuteGateEntry,ClearJuteGateEntryHeader,ClearJuteGateEntryLineItems} from "../../../store/Jute/JuteGateEntry/actions";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
const JuteGateEntryIndex = (props) => {
  const [userDit, setUserDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [companyId, setCompanyId] = useState(localStorage.getItem("companyId"));
  const [startIndex, setStartIndex] = useState("");
  const [lastIndex, setLastIndex] = useState("");
  const [JuteGateEntryContent, setJuteGateEntryContent] = useState([]);
  const [ExpData,setExpData]=useState({})
  const [totalRecs,settotalRecs]=useState("")
  const [fromDate, setfromDate] = useState( moment().day(-7).format("DD-MM-YYYY"));
  const [toDate, settoDate] = useState(moment().format("DD-MM-YYYY"));
  const dispatch = useDispatch();
  let history = useHistory();
  const { JuteGateEntryData } = useSelector(
    (state) => state.JuteGateEntryReducer
  );
  useEffect(() => {
    const financialYearStartMonth = 3; // Assuming financial year starts in April
    let financialYearStart = moment()
      .year(localStorage.getItem("acadamicYear"))
      .month(financialYearStartMonth)
      .startOf("month");

    const fromDate = financialYearStart.day(-7).format("YYYY-MM-DD");
    const toDate = moment().format("YYYY-MM-DD");
    var startDate = moment(fromDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var endDate = moment(toDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    setfromDate(startDate)
    settoDate(endDate)
      const data = {
        frmDt: startDate,
        toDt: endDate,
        supCode: null,
        vehicleNum: null,
        gateEntryNum: null,
        companyId: localStorage.getItem("companyId"),
        cipher: userDit.cipher,
        financialYear: localStorage.getItem("acadamicYear"),
      };
      dispatch(JuteGateEntry(serverApi.JUTE_GATE_ENTRY_LIST, data, props.history));
      const expdata = { ...data, startIndex: 0, lastIndex: 0 };
      showSpinner();
      setExpData(expdata);
      dispatch(ClearJuteGateEntryHeader())
      dispatch(ClearJuteGateEntryLineItems())
  }, []);

  useEffect(() => {
    if (JuteGateEntryData) {
      setJuteGateEntryContent(JuteGateEntryData.data);
      hideSpinner();
    }
  }, [JuteGateEntryData]);

  const handleSearch = (searchData) => {
    const data = {
      frmDt: searchData.frmDt,
      toDt: searchData.toDt,
      supCode: searchData.supplierValue,
      vehicleNum: searchData.vehicleValue,
      gateEntryNum: searchData.gateEntryNum,
      companyId: localStorage.getItem("companyId"),
      cipher: userDit.cipher,
      financialYear: localStorage.getItem("acadamicYear"),
    };
    dispatch(JuteGateEntry(serverApi.JUTE_GATE_ENTRY_LIST, data, props.history))
    showSpinner();
  };

  const handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      // handle type 1 pagination
    } else {
      // handle type 2 pagination
    }
    setStartIndex(currentIndex);
    setLastIndex(sizePerPage);
  };

  return (
    <>
      <Gridwithcustomview
        mdata={JuteGateEntryContent}
        mcolumns={Columns}
        status={true}
        micon={GridArrowIcon}
        actions={true}
        totalRecs={totalRecs}
        page={startIndex}
        sizePerPage={lastIndex}
        handlePagination={handlePagination}
        url="/create_jute_gate_entry"
        filter_form={"JuteGateEntry"}
        handleSearch={handleSearch}
        apiexp={serverApi.JUTE_GATE_ENTRY_LIST}
        payloadexp={ExpData}
        expName={"JuteGateEntry.csv"}
        fromDate={fromDate}
        toDate={toDate}
      />
    </>
  );
};

// machine master header
const Columns = [
  {
    Header: "Entry No",
    accessor: "comSeqNo",
    hidden: false,
  },
  {
    Header: "Ref No",
    accessor: "gateEntryNum",
    hidden: false,
  },
  {
    Header: "Po No",
    accessor: "poNo",
    hidden: false,
  },
  {
    Header: "Entry Date",
    accessor: "chalanDate",
    hidden: false,
  },
  {
    Header: "Vehicle No",
    accessor: "vehicleNo",
    hidden: false,
  },
  {
    Header: "Supplier",
    accessor: "supplierName",
    hidden: false,
  },
  {
    Header: "Driver",
    accessor: "driver",
    hidden: false,
  },
  {
    Header: "Exit Time",
    accessor: "exitTime",
    hidden: false,
  },
];



export default JuteGateEntryIndex;
