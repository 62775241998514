import React, { Component } from "react";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
class ClientVisitPlan extends Component {
  render() {
    const mcolumns = [
      {
        dataField: "id",
        text: "Employee Code",
        headerAlign: "left",
        align: "left",
      },
      {
        dataField: "name",
        text: "Name",
        headerAlign: "left",
        align: "left",
      },
      {
        dataField: "type",
        text: "Visit Type",
        headerAlign: "left",
        align: "left",
      },
      {
        dataField: "companyName",
        text: "Company Visited",
        headerAlign: "left",
        align: "left",
      },
      {
        dataField: "location",
        text: "Location",
        headerAlign: "left",
        align: "left",
      },
      {
        dataField: "date",
        text: "From to date",
        headerAlign: "left",
        align: "left",
      },
    ];
    const mdata = [
      {
        id: "STPL057",
        name: "Snehadeep Goli",
        type: "Sales",
        companyName: "EY",
        location: "Australia",
        date: "2nd Dec to 5th Dec'21",
        clientVisitPlan: "Planning",
      },
      {
        id: "STPL057",
        name: "Shivya Chandrashekran",
        type: "Purchase",
        companyName: "Vistara",
        location: "Australia",
        date: "2nd Dec to 5th Dec'21",
        clientVisitPlan: "Planning",
      },
      {
        id: "STPL057",
        name: "Snehadeep Goli",
        type: "Account",
        companyName: "Air India",
        location: "Australia",
        date: "2nd Dec to 5th Dec'21",
        clientVisitPlan: "Visited",
      },
      {
        id: "STPL057",
        name: "Megha Goel",
        type: "Legal",
        companyName: "Smarts works",
        location: "Australia",
        date: "2nd Dec to 5th Dec'21",
        clientVisitPlan: "Visited",
      },
      {
        id: "STPL057",
        name: "Niranjan",
        type: "Sales",
        companyName: "EY",
        location: "Australia",
        date: "2nd Dec to 5th Dec'21",
        clientVisitPlan: "Planning",
      },
      {
        id: "STPL057",
        name: "Nikil krishna",
        type: "Sales",
        companyName: "EY",
        location: "Australia",
        date: "2nd Dec to 5th Dec'21",
        clientVisitPlan: "Planning",
      },
      {
        id: "STPL057",
        name: "Snehadeep Goli",
        type: "Sales",
        companyName: "EY",
        location: "Australia",
        date: "2nd Dec to 5th Dec'21",
        clientVisitPlan: "Planning",
      },
      {
        id: "STPL057",
        name: "Snehadeep Goli",
        type: "Sales",
        companyName: "EY",
        location: "Australia",
        date: "2nd Dec to 5th Dec'21",
        clientVisitPlan: "Planning",
      },
      {
        id: "STPL057",
        name: "Snehadeep Goli",
        type: "Sales",
        companyName: "EY",
        location: "Australia",
        date: "2nd Dec to 5th Dec'21",
        clientVisitPlan: "Planning",
      },
      {
        id: "STPL057",
        name: "Snehadeep Goli",
        type: "Sales",
        companyName: "EY",
        location: "Australia",
        date: "2nd Dec to 5th Dec'21",
        clientVisitPlan: "Planning",
      },
      {
        id: "STPL057",
        name: "Snehadeep Goli",
        type: "Sales",
        companyName: "EY",
        location: "Australia",
        date: "2nd Dec to 5th Dec'21",
        clientVisitPlan: "Planning",
      },
      {
        id: "STPL057",
        name: "Snehadeep Goli",
        type: "Sales",
        companyName: "EY",
        location: "Australia",
        date: "2nd Dec to 5th Dec'21",
        clientVisitPlan: "Planning",
      },
      {
        id: "STPL057",
        name: "Snehadeep Goli",
        type: "Sales",
        companyName: "EY",
        location: "Australia",
        date: "2nd Dec to 5th Dec'21",
        clientVisitPlan: "Planning",
      },
      {
        id: "STPL057",
        name: "Snehadeep Goli",
        type: "Sales",
        companyName: "EY",
        location: "Australia",
        date: "2nd Dec to 5th Dec'21",
        clientVisitPlan: "Planning",
      },
      {
        id: "STPL057",
        name: "Snehadeep Goli",
        type: "Sales",
        companyName: "EY",
        location: "Australia",
        date: "2nd Dec to 5th Dec'21",
        clientVisitPlan: "Planning",
      },
    ];
    return (
      <>
        <Gridwithcustomview
          mdata={mdata}
          mcolumns={mcolumns}
          micon={GridArrowIcon}
          clientVisitPlan={true}
          actions={true}
        />
      </>
    );
  }
}
const mapStatetoProps = (state) => {};

export default withRouter(connect(mapStatetoProps, {})(ClientVisitPlan));
