import React, { Component } from "react";
import { Grid, Step, Stepper, StepLabel } from "@mui/material";
import Link from "@mui/material/Link";
import swal from "sweetalert";
import CustomButton from "../../../components/Buttons/Buttons";
import StockReturnAddItems from "./StockReturnAddItems";
import StockReturnFillDetails from "./StockReturnFillDetails";
import StockReturnPreview from "./StockReturnPreview";

export default class StockReturnCreate extends Component {
  constructor() {
    super();
    this.state = {
      steps: [
        {
          title: "Fill Details",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Add Items",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Preview",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
          activeIcon:
            "http://vowerp.com/MailImages/assets/indentDisabled03.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
      ],
      currentStep: 1,
      date: null,
      expense_type: 0,
      expense_name: "",
      branch_type: 0,
      branch_name: "",
      project_type: 0,
      project_name: "",
    };
  }

  onClickNext = () => {
    const { currentStep } = this.state;
    const { expense_name, branch_name, project_name } = this.state;

    if (expense_name !== "" && branch_name !== "" && project_name !== "") {
      this.setState({
        currentStep: currentStep + 1,
      });
    } else {
      swal("please select mandatory field");
    }
  };

  onClickBefore = () => {
    const { currentStep } = this.state;
    this.setState({
      currentStep: currentStep - 1,
    });
  };
  QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;

    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "expenseType") {
      this.setState({
        expense_type: selectedValue,
        expense_name: selectedName,
      });
    }
    if (name === "branchName") {
      this.setState({
        branch_type: selectedValue,
        branch_name: selectedName,
      });
    }
    if (name === "projectName") {
      this.setState({
        project_type: selectedValue,
        project_name: selectedName,
      });
    }
  };
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    const { steps, currentStep } = this.state;

    return (
      <div>
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            <div className="iconsBlock">
              <Stepper alternativeLabel activeStep={currentStep}>
                {steps.map((prop) => (
                  <Step key={prop.title}>
                    <StepLabel
                      StepIconComponent={(Iconprops) => {
                        return this.QontoStepIcon(
                          Iconprops,
                          prop.icon,
                          prop.activeIcon,
                          prop.title
                        );
                      }}
                    ></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {currentStep === 1 && (
              <>
                <StockReturnFillDetails onSelectDep={this.handleSelectChange} />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="cancelButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="saveButton"
                    handleClick={this.onClickNext}
                    type="sumbit"
                  />
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                <StockReturnAddItems />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="cancelButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="saveButton"
                    handleClick={this.onClickNext}
                    type="sumbit"
                  />
                </div>
              </>
            )}
            {currentStep === 3 && (
              <>
                <StockReturnPreview />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="cancelButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="saveButton"
                    handleClick={this.onClickNext}
                    type="sumbit"
                  />
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}
