import {
  YARN_MASTER_LIST,
  CREATE_YARN_MASTER,
  UPDATE_YARN_MASTER,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { postAdd, getList } from "../../../helpers/Server_Helper";
import {
  yarnMasterListSuccessfull,
  createYarnMasterSuccessfull,
  updateYarnMasterSuccessfull,
} from "./actions";
import swal from "sweetalert";

function* YarnMasterList({ payload: { url, data } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(yarnMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateYarnMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createYarnMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/yarn_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateYarnMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateYarnMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/yarn_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

export function* watchYarnMasterList() {
  yield takeEvery(YARN_MASTER_LIST, YarnMasterList);
}
export function* watchCreateYarnMaster() {
  yield takeEvery(CREATE_YARN_MASTER, CreateYarnMaster);
}
export function* watchUpdateYarnMaster() {
  yield takeEvery(UPDATE_YARN_MASTER, UpdateYarnMaster);
}

function* YarnMasterSaga() {
  yield all([
    fork(watchYarnMasterList),
    fork(watchCreateYarnMaster),
    fork(watchUpdateYarnMaster),
  ]);
}

export default YarnMasterSaga;
