import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";

const DepartmentFilter = (props) => {
  const [open, setOpen] = useState(false);
  const [department, setDepartment] = useState("");
  const [mDeptCode, setMDeptCode] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    var data = {
      department: department !== "" ? department : null,
      mDeptCode: mDeptCode !== "" ? mDeptCode : null,
    };

    props.handle_search(data);
    setOpen(false);
  };

  const handleChange = (key) => (event) => {
    if (key === "department") {
      setDepartment(event);
    }
    if (key === "mDeptCode") {
      setMDeptCode(event);
    }
  };

  return (
    <Box>
      <Button onClick={handleClickOpen}>
        <Tooltip title="Filter">
          <FilterListOutlinedIcon />
        </Tooltip>
      </Button>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            position: " fixed",
            top: 0,
            right: 0,
            margin: 0,
            bottom: 0,
            maxHeight: "100vh",
          },
        }}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid spacing={0}>
          <div className="consoleTextFieldBlock">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <div className="filterBlockHeader">
                <FilterListOutlinedIcon />
                <span>Filter</span>
              </div>
            </div>
          </div>
          <Grid item xs={12} className="filterBlockContainer">
            <Box gridColumn="span 3">
              <div className="consoleTextFieldBlock">
                <TextFieldNormal
                  label="Department"
                  value={department}
                  placeholder={"Enter here"}
                  onChange={handleChange("department")}
                />
              </div>
            </Box>
            <Box gridColumn="span 3">
              <div className="consoleTextFieldBlock">
                <TextFieldNormal
                  label="Department Code"
                  value={mDeptCode}
                  placeholder={"Enter here"}
                  onChange={handleChange("mDeptCode")}
                />
              </div>
            </Box>
            <div className="consoleFormButtonBlock">
              <CustomButton
                label={"Search"}
                className="greenButton"
                handleClick={handleSubmit}
                type="submit"
              />
            </div>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
};

const mapStatetoProps = (state) => {
  // const {  } = state.DropDownListReducer;
  return {};
};

export default withRouter(
  connect(mapStatetoProps, {})(DepartmentFilter)
);
