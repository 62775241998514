import { all, call, fork, takeEvery, put } from "redux-saga/effects";


import {BILLPASS_LIST} from './actionTypes';
import {BillPassListSuccessfull} from './actions';
import {getList} from '../../../helpers/Server_Helper';



function* getBillPassList({payload:{url,data,history}}) {
    try{
        const response = yield call(getList,url,data);
        yield put(BillPassListSuccessfull({response}))
    }catch(error){
        console.log(error)
    }
}




export function* watchBillPassList () {
    yield takeEvery(BILLPASS_LIST, getBillPassList)
}




function* BillPassSaga() {
    yield all([
        fork(watchBillPassList),
    ])
}


export default BillPassSaga;