import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Box,  } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import moment from "moment";
import { serverApi } from "../../../helpers/Consts";
import swal from "sweetalert";
import {
  SalesOrderJuteYarnHeaders,
  getQuotationRecords,
  getQuoteViewByIdForJuteYarn,
  SalesOrderJuteYarnLineItems,
} from "../../../store/Sales/SalesOrder/actions";
import {
  getBranchList,
  getCustomerList,
  getBillingShippingList,
} from "../../../store/Global/DropDownApis/actions";
import { ClearSOJuteGovtLineItems, ClearSOJuteYarnHeaders } from "../../../store/Sales/SalesOrder/actions";
import { getQuotationBroker } from "../../../store/Sales/Qutation/actions";
import { SalesGstCalculations } from "../../../Utilities/SalesGstCalculation";
import { isEmpty } from "../../../Utilities/helper";
import FormPopup from "../../../components/FormlPopup/FormPopup";

const JuteYarnFillDetails = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [SoJuteYarnHeadersData, setSoJuteYarnHeadersData] = useState({});
  const [branchListData, setbranchListData] = useState([]);
  const [SoJuteYarnLineItemsData, SetSoJuteYarnLineItemsData] = useState([]);
  const [brokerData, setbrokerData] = useState([]);
  const [CustomerData, setCustomerData] = useState([]);
  const [billingAddressData, setbillingAddressData] = useState([]);
  const [shippingAddressData, setshippingAddressData] = useState([]);
  const [QuotationData, setQuotationData] = useState([]);
  const [transporterList, settransporterList] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [customerListData, SetCustomerListData] = useState([]);
  const [emailCustomer,setEmailCustomer]=useState("");
  const [backDaysAllowable, setBackDaysAllowable] = useState("");
  const [futureDaysAllowable, setFutureDaysAllowable] = useState("");

  const { branchList, customerList, billingShippingList } = useSelector(
    (state) => state.DropDownListReducer
  );
  const { BrokerData } = useSelector((state) => state.QuotationReducer);
  const { SoJuteYarnHeaders, SoJuteYarnLineItems,QuotationApprovedData } = useSelector(
    (state) => state.SalesOrderReducer
  );
  const state = useSelector((state) => state.SalesOrderReducer);
 
  useEffect(() => {
    setSoJuteYarnHeadersData(SoJuteYarnHeaders);
    SetSoJuteYarnLineItemsData(SoJuteYarnLineItems);
    dispatch(
      getBranchList(
        serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
        history
      )
    );
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
    };
    dispatch(getCustomerList(serverApi.GET_ALL_CUSTOMERS, data, history));
    dispatch(
      getQuotationBroker(
        serverApi.GET_ALL_BROKER_LIST +
          localStorage.getItem("companyId") +
          "/SALE",
        history
      )
    );
    dispatch(
      getQuotationRecords(
        serverApi.GET_QUOTATION_DROPDOWN + localStorage.getItem("companyId"),
        history
      )
    );
    const currentMonth = moment().format('MM');
    const currentDate = moment().format('DD');
    const fiscalYearStart = moment(`${localStorage.getItem("acadamicYear")}-${currentMonth}-${currentDate}`);
    const today = moment();
    let dateValue;
    if (today.isBefore(fiscalYearStart)) {
      dateValue = today.format("YYYY-MM-DD");
    } else {
      dateValue = fiscalYearStart.format("YYYY-MM-DD");
    }

    if (userDit) {
      userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              if (item.backDaysAllowable !== undefined) {
                let backDaysAllowable = JSON.parse(item.backDaysAllowable);
                let backDays = backDaysAllowable.bdaystype;
                if (Number(backDays) === 1) {
                  backDays = 7;
                } else if (Number(backDays) === 2) {
                  backDays = 14;
                } else if (Number(backDays) === 3) {
                  backDays = "";
                } else if (Number(backDays) === 4) {
                  backDays = backDaysAllowable.otherBackDateValue;
                } else {
                  backDays = backDaysAllowable.bdaystype;
                }
                console.log(dateValue)
                let minDate = moment(dateValue).subtract(
                  Number(backDays),

                  "days"
                );
                let setMinDate = moment(minDate).format("YYYY-MM-DD");
                setBackDaysAllowable(setMinDate);
              }
              if (item.futureDaysAllowable !== undefined) {
                let futureDaysAllowable = JSON.parse(item.futureDaysAllowable);
                let futureDays = futureDaysAllowable.fdaystype;
                if (Number(futureDays) === 1) {
                  futureDays = 7;
                } else if (Number(futureDays) === 2) {
                  futureDays = 14;
                } else if (Number(futureDays) === 3) {
                  futureDays = "";
                } else if (Number(futureDays) === 4) {
                  futureDays = futureDaysAllowable.othervalue;
                } else {
                  futureDays = futureDaysAllowable.fdaystype;
                }
                let maxDate = moment(dateValue).add(
                  Number(futureDays),

                  "days"
                );
                let setMaxDate = moment(maxDate).format("YYYY-MM-DD");
                setFutureDaysAllowable(setMaxDate);
                console.log(setMaxDate)
              }
            });
            // setSoJuteYarnHeadersData((prev) => ({
            //   ...prev,
            //   salesDate:dateValue,
            //   salesOrderExpiryDate:dateValue            
            // }));
            // const UpdatedRec = {
            //   ...SoJuteYarnHeaders,
            //   salesDate:dateValue,
            //   salesOrderExpiryDate:dateValue
            // };
            // dispatch(SalesOrderJuteYarnHeaders(UpdatedRec));

          }
      });
    }
  }, []);

  useEffect(() => {
    if (branchList) {
      if (branchList.data) {
        if (branchList.data.length !== 0) {
          let List = branchList.data.filter((item) => item.value !== 0);
          setbranchListData(List);
        }
      }
    }
    if (customerList) {
      if (customerList.data) {
        if (customerList.data.length !== 0) {
          var CusList = [];
          customerList.data.map((item) => {
            CusList.push({
              value: item.id,
              name: item.name,
              label: item.name,
            });
          });
          setCustomerData(CusList);
        }
      }
    }
    if (BrokerData) {
      if (BrokerData.data) {
        setbrokerData(BrokerData.data);
      }
    }

    if (QuotationApprovedData) {
      if (QuotationApprovedData.data) {
        if (QuotationApprovedData.data.length !== 0) {
          var QuoteData = [];
          QuotationApprovedData.data.map((item) => {
            QuoteData.push({
              value: item.Id,
              name: item.Value,
              label: item.Value,
            });
          });
          setQuotationData(QuoteData);
        }
      }
    }
  }, [branchList, customerList, QuotationApprovedData,]);
  const isEmptyObj = (myObject) => {
    // Use the 'Object.keys' method to get an array of the object's keys
    const keys = Object.keys(myObject);

    // Check if the number of keys in the array is 0
    return keys.length === 0;
  };

  useEffect(() => {
    if (billingShippingList) {
      if (billingShippingList.data) {
        setEmailCustomer(billingShippingList.data.customerHdr.emailId)
        if (billingShippingList.data.customerLineitems) {
          if (billingShippingList.data.customerLineitems.length !== 0) {
            var list = [];
            let billingAddressList = [];
            let shippingaddressList = [];
            let SoJuteYarnHeadersObj = SoJuteYarnHeaders
            let customerLineitems = billingShippingList.data.customerLineitems;
            SetCustomerListData(customerLineitems);
            billingShippingList.data.customerLineitems.map((prop, i) => {
              if (prop.addressType === 2 || prop.addressType === null) {
                if (
                  (prop.customerLineId === SoJuteYarnHeaders.shippingTo &&
                    prop.isActive === 0) ||
                  prop.isActive !== 0
                ) {
                  billingAddressList.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
                  list.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
                }
              }
              if (prop.addressType === 1 || prop.addressType === null || prop.addressType === 2) {
                if (
                  (prop.customerLineId === SoJuteYarnHeaders.shippingTo &&
                    prop.isActive === 0) ||
                  prop.isActive !== 0
                ) {
                  shippingaddressList.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
                  list.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
                }
              }
            });
            billingShippingList.data.customerLineitems.map((prop, i) => {
              if (billingAddressList.length !== 0 &&  SoJuteYarnHeadersData.customerId !== "" &&
              SoJuteYarnHeadersData.customerId !== null) {
                billingAddressList.map((billingdata, ind) => {
                  if (ind === 0) {
                    if (!isEmptyObj(SoJuteYarnHeadersData)) {
                      if (
                        SoJuteYarnHeadersData.customerId !== "" ||
                        SoJuteYarnHeadersData.customerId !== null
                      ) {
                        if (prop.customerLineId === billingdata.value) {
                          setSoJuteYarnHeadersData((prev) => ({
                            ...prev,
                            billingTo: prop.customerLineId,
                            billingToAddress: prop.address,
                            billingToGST: prop.gstNo,
                            billingContactNo: prop.contactNo,
                            billingContactPerson: prop.contactPerson,
                            billingToEmail:billingShippingList.data.customerHdr.emailId,
                            taxType: 2,
                          }));
                          const UpdatedRec = {
                            ...SoJuteYarnHeadersObj,
                            billingTo: prop.customerLineId,
                            billingToAddress: prop.address,
                            billingToGST: prop.gstNo,
                            billingContactNo: prop.contactNo,
                            billingContactPerson: prop.contactPerson,
                            billingToEmail:billingShippingList.data.customerHdr.emailId,
                            taxType: 2,
                          };
                          SoJuteYarnHeadersObj = UpdatedRec
                        }
                      }else{
                        setSoJuteYarnHeadersData((prev) => ({
                          ...prev,
                          billingTo: "",
                          billingToAddress: "",
                          billingToGST: "",
                          billingContactNo: "",
                          billingContactPerson: "",
                          billingToEmail:"",
                          taxType: "",
                        }));
                        const UpdatedRec = {
                          ...SoJuteYarnHeadersObj,
                          billingTo: "",
                          billingToAddress: "",
                          billingToGST: "",
                          billingContactNo: "",
                          billingContactPerson: "",
                          billingToEmail:"",
                          taxType: "",
                        };
                        SoJuteYarnHeadersObj = UpdatedRec
                      }
                    }
                  }
                });
              } else {
                setSoJuteYarnHeadersData((prev) => ({
                  ...prev,
                  billingTo: "",
                  billingToAddress: "",
                  billingToGST: "",
                  billingContactNo: "",
                  billingContactPerson: "",
                  billingToEmail:"",
                  taxType: "",
                }));
                const UpdatedRec = {
                  ...SoJuteYarnHeadersObj,
                  billingTo: "",
                  billingToAddress: "",
                  billingToGST: "",
                  billingContactNo: "",
                  billingContactPerson: "",
                  billingToEmail:"",
                  taxType: "",
                };
                SoJuteYarnHeadersObj = UpdatedRec

              }
            })
            billingShippingList.data.customerLineitems.map((prop, i) => {
              if (shippingaddressList.length !== 0 &&  SoJuteYarnHeadersData.customerId !== "" &&
              SoJuteYarnHeadersData.customerId !== null) {
                shippingaddressList.map((shippingdata, ind) => {
                  if (ind === 0) {
                    if (!isEmptyObj(SoJuteYarnHeadersData)) {
                      if (
                        SoJuteYarnHeadersData.customerId !== "" ||
                        SoJuteYarnHeadersData.customerId !== null
                      ) {
                        if (prop.customerLineId === shippingdata.value) {
                          setSoJuteYarnHeadersData((prev) => ({
                            ...prev,
                            shippingTo: prop.customerLineId,
                            shippingToAddress: prop.address,
                            shippingToGST: prop.gstNo,
                            shippingContactNo: prop.contactNo,
                            shippingContactPerson: prop.contactPerson,
                            shippingToEmail: billingShippingList.data.customerHdr.emailId,
                            taxType: 2,
                          }));
                          const UpdatedRec = {
                            ...SoJuteYarnHeadersObj,
                            shippingTo: prop.customerLineId,
                            shippingToAddress: prop.address,
                            shippingToGST: prop.gstNo,
                            shippingContactNo: prop.contactNo,
                            shippingContactPerson: prop.contactPerson,
                            shippingToEmail: billingShippingList.data.customerHdr.emailId,
                            taxType: 2,
                          };
                          SoJuteYarnHeadersObj = UpdatedRec
                        }
                      }else{
                        setSoJuteYarnHeadersData((prev) => ({
                          ...prev,
                          shippingTo: "",
                          shippingToAddress:"",
                          shippingToGST: "",
                          shippingContactNo: "",
                          shippingContactPerson: "",
                          shippingToEmail: "",
                          taxType: "",
                        }));
                        const UpdatedRec = {
                          ...SoJuteYarnHeadersObj,
                          shippingTo: "",
                          shippingToAddress:"",
                          shippingToGST: "",
                          shippingContactNo: "",
                          shippingContactPerson: "",
                          shippingToEmail: "",
                          taxType: "",
                        };
                        SoJuteYarnHeadersObj = UpdatedRec
                      }
                    }
                  }
                });
              }  else {
                setSoJuteYarnHeadersData((prev) => ({
                  ...prev,
                  shippingTo: "",
                  shippingToAddress:"",
                  shippingToGST: "",
                  shippingContactNo: "",
                  shippingContactPerson: "",
                  shippingToEmail: "",
                  taxType: "",
                }));
                const UpdatedRec = {
                  ...SoJuteYarnHeadersObj,
                  shippingTo: "",
                  shippingToAddress:"",
                  shippingToGST: "",
                  shippingContactNo: "",
                  shippingContactPerson: "",
                  shippingToEmail: "",
                  taxType: "",
                };
                SoJuteYarnHeadersObj = UpdatedRec
              }
            });
            if (SoJuteYarnHeadersData.customerId !== "") {
              dispatch(SalesOrderJuteYarnHeaders(SoJuteYarnHeadersObj));
              setbillingAddressData(billingAddressList);
              setshippingAddressData(shippingaddressList);
            }
          }
        }
      }
    }
  }, [billingShippingList]);

  const handlePropSelectChange = (selectedValue, selectedName, name,row,selectedlable) => {
    if (name === "branchId") {
      setSoJuteYarnHeadersData((prev) => ({
        ...prev,
        branchId: selectedValue,
        branchName: selectedlable,
      }));
      const UpdatedRec = {
        ...SoJuteYarnHeadersData,
        branchId: selectedValue,
        branchName: selectedlable,
      };
      dispatch(SalesOrderJuteYarnHeaders(UpdatedRec));
    }
    if (name === "deliveryTerms") {
      setSoJuteYarnHeadersData((prev) => ({
        ...prev,
        deliveryTerms: selectedName,
      }));
      const UpdatedRec = {
        ...SoJuteYarnHeadersData,
        deliveryTerms: selectedName,
      };
      dispatch(SalesOrderJuteYarnHeaders(UpdatedRec));
    }
    if (name === "customerId") {
      setSoJuteYarnHeadersData((prev) => ({
        ...prev,
        customerId: selectedValue,
        customerName: selectedName,
      }));
      const UpdatedRec = {
        ...SoJuteYarnHeadersData,
        customerId: selectedValue,
        customerName: selectedName,
      };
      dispatch(SalesOrderJuteYarnHeaders(UpdatedRec));
      dispatch(
        getBillingShippingList(
          serverApi.GET_ALL_BILLING_SHIPPING +
            selectedValue +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).cipher+
            "?isForSalesModule=1",
          history
        )
      );
    }
    if (name === "brokerId") {
  
        setSoJuteYarnHeadersData((prev) => ({
          ...prev,
          brokerId: selectedValue,
          brokerCommisionPercent: "",
          brokerName: selectedName
        }));
        const UpdatedRec = {
          ...SoJuteYarnHeadersData,
          brokerId: selectedValue,
          brokerCommisionPercent: "",
          brokerName: selectedName
        };
        dispatch(SalesOrderJuteYarnHeaders(UpdatedRec));
      let TaxPer = SalesGstCalculations(
        {
          lineItems: SoJuteYarnLineItems,
          taxType: SoJuteYarnHeadersData.taxType,
          brokerCommisionPercent:"" ,
        },
        "SO_JUTE_YARN_UOM"
      );

      let taxType = SoJuteYarnHeadersData.taxType;
      let taxName = SoJuteYarnHeadersData.taxName;
      let brokerCommisionPercent=""
      let brokerId=selectedValue
      let brokerName =selectedName
      handleCalculation(TaxPer, taxType, taxName,brokerCommisionPercent,brokerId,brokerName);
    }

    if (name === "BillingTo") {
      customerListData.filter((prop)=>{
        if (prop.customerLineId === selectedValue) {
      setSoJuteYarnHeadersData((prev) => ({
        ...prev,
        billingTo: selectedValue,
        billingToAddress: selectedName,
        billingToGST: prop.gstNo,
        billingContactNo: prop.contactNo,
        billingContactPerson: prop.contactPerson,
        billingToEmail: emailCustomer,
      }));
      const UpdatedRec = {
        ...SoJuteYarnHeadersData,
        billingTo: selectedValue,
        billingToAddress: selectedName,
        billingToGST: prop.gstNo,
        billingContactNo: prop.contactNo,
        billingContactPerson: prop.contactPerson,
        billingToEmail: emailCustomer,
      };
      dispatch(SalesOrderJuteYarnHeaders(UpdatedRec));
    }
  })
    }
    if (name === "shippingTo") {
      customerListData.filter((prop) => {
        if (prop.customerLineId === selectedValue) {
      setSoJuteYarnHeadersData((prev) => ({
        ...prev,
        shippingTo: selectedValue,
        shippingToAddress: selectedName,
        shippingToGST: prop.gstNo,
        shippingContactNo: prop.contactNo,
        shippingContactPerson: prop.contactPerson,
        shippingToEmail: emailCustomer,
      }));
      const UpdatedRec = {
        ...SoJuteYarnHeadersData,
        shippingTo: selectedValue,
        shippingToAddress: selectedName,
        shippingToGST: prop.gstNo,
        shippingContactNo: prop.contactNo,
        shippingContactPerson: prop.contactPerson,
        shippingToEmail: emailCustomer,
      };
      dispatch(SalesOrderJuteYarnHeaders(UpdatedRec));
    }
  })
    }
    if (name === "TaxType") {
      setSoJuteYarnHeadersData((prev) => ({
        ...prev,
        taxType: selectedValue,
        taxName: selectedName,
      }));
      const UpdatedRec = {
        ...SoJuteYarnHeadersData,
        taxType: selectedValue,
        taxName: selectedName,
      };
      dispatch(SalesOrderJuteYarnHeaders(UpdatedRec));
      let TaxPer = SalesGstCalculations(
        {
          lineItems: SoJuteYarnLineItems,
          taxType: selectedValue,
          brokerCommisionPercent:SoJuteYarnHeadersData.brokerCommisionPercent,
        },
        "SO_JUTE_YARN_UOM"
      );
      let taxType = selectedValue;
      let taxName = selectedName;
      let brokerCommisionPercent= SoJuteYarnHeadersData.brokerCommisionPercent
      let brokerId=SoJuteYarnHeadersData.brokerId
      let brokerName =SoJuteYarnHeadersData.brokerName
      handleCalculation(TaxPer, taxType, taxName,brokerCommisionPercent,brokerId,brokerName);
    }
  };
  const handleCalculation = (Calculations, taxType, taxName,brokerCommisionPercent,brokerId,brokerName) => {
    SetSoJuteYarnLineItemsData(Calculations.lineItems);
    dispatch(SalesOrderJuteYarnLineItems(Calculations.lineItems));
    let JuteYarnHeadersData = {
      ...SoJuteYarnHeadersData,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
      taxType: taxType,
      taxName: taxName,
      brokerCommisionPercent:brokerCommisionPercent,
      brokerId:brokerId,
      brokerName:brokerName
    };
    setSoJuteYarnHeadersData((prev) => ({
      ...prev,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
      taxType: taxType,
      taxName: taxName,
    }));
    dispatch(SalesOrderJuteYarnHeaders(JuteYarnHeadersData));
  };
  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const onhandlechange = (key) => (event) => {
    if (key === "brokerCommisionPercent") {
      if(event <= 100){
        if (event.match(/^(\d*\.{0,1}\d{0,2}$)/)){
          setSoJuteYarnHeadersData((prev) => ({
            ...prev,
            brokerCommisionPercent: event,
          }));
          const UpdatedRec = {
            ...SoJuteYarnHeadersData,
            brokerCommisionPercent: event,
          };
          dispatch(SalesOrderJuteYarnHeaders(UpdatedRec));
        }
        else {
          swal("Please enter a positive number with up to 2 decimal places.")
        }
     
      let TaxPer = SalesGstCalculations(
        {
          lineItems: SoJuteYarnLineItems,
          taxType: SoJuteYarnHeadersData.taxType,
          brokerCommisionPercent:event,
        },
        "SO_JUTE_YARN_UOM"
      );
      let taxType = SoJuteYarnHeadersData.taxType;
      let taxName = SoJuteYarnHeadersData.taxName;
      let brokerCommisionPercent= event
      let brokerId=SoJuteYarnHeadersData.brokerId
      let brokerName =SoJuteYarnHeadersData.brokerName
      handleCalculation(TaxPer, taxType, taxName,brokerCommisionPercent,brokerId,brokerName);
    }
    else {
      swal("Broker Percent Can't be more than 100%")
    }
    }
  };
  const handleSelect_Date = (e, name) => {
    if (name === "salesDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      var Sale_date = null;
      if (SoJuteYarnHeadersData.salesOrderExpiryDate !== null) {
        if (Date > SoJuteYarnHeadersData.salesOrderExpiryDate) {
          swal("Sale Date Shouldn't be Greater than Expire Date");
          Sale_date =  SoJuteYarnHeadersData.salesDate
        } else {
          Sale_date = Date;
        }
      } else {
        Sale_date = Date;
      }
      setSoJuteYarnHeadersData((prev) => ({
        ...prev,
        salesDate: Sale_date,
      }));
      const UpdatedRec = {
        ...SoJuteYarnHeadersData,
        salesDate: Sale_date,
      };
      dispatch(SalesOrderJuteYarnHeaders(UpdatedRec));
    } 
   if (name === "salesOrderExpiryDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      var SaleExpDate = null;
      if (SoJuteYarnHeadersData.salesDate !== null) {
        if (Date < SoJuteYarnHeadersData.salesDate) {
          swal("Expire Date Should be Greater than Sale Date");
          SaleExpDate = null;
        } else {
          SaleExpDate = Date;
        }
      } else {
        swal("Please Select the Sale Date First");
      }
      setSoJuteYarnHeadersData((prev) => ({
        ...prev,
        salesOrderExpiryDate: SaleExpDate,
      }));
      const UpdatedRec = {
        ...SoJuteYarnHeadersData,
        salesOrderExpiryDate: SaleExpDate,
      };
     dispatch(SalesOrderJuteYarnHeaders(UpdatedRec));
    }
  };

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <div>
        <div className="consoleFormContainer">
          <h5>Jute Yarn Fill Details </h5>

          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={SoJuteYarnHeadersData.branchId ? 1 : 0}
                        arrayOfData={branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={SoJuteYarnHeadersData.branchId}
                        name="branchId"
                        label="Branch"
                        required
                        isDisabled={props.locationState.state !==undefined ? true :false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={SoJuteYarnHeadersData.brokerId ? 1 : 0}
                        arrayOfData={brokerData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={SoJuteYarnHeadersData.brokerId}
                        name="brokerId"
                        label="Broker"
                        toolTipTitle={
                          "Broker or Consignee is mandetory Select At least one and Proceed"
                        }
                        isDisabled={SoJuteYarnHeadersData.branchId ==="" ? true:false}
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <DynamicSelect
                      update={SoJuteYarnHeadersData.customerId ? 1 : 0}
                      arrayOfData={CustomerData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      selected={SoJuteYarnHeadersData.customerId}
                      name="customerId"
                      label="Customer/Consignee"
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      required
                      toolTipTitle={
                        "Broker or Consignee is mandetory Select At least one and Proceed"
                      }
                      isDisabled={SoJuteYarnHeadersData.branchId ==="" ? true:false}
                    />
                  </Box>
                 
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={SoJuteYarnHeadersData.billingTo ? 1 : 0}
                        arrayOfData={billingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={SoJuteYarnHeadersData.billingTo}
                        name="BillingTo"
                        label="Billing To"
                        required
                        isDisabled={SoJuteYarnHeadersData.customerId ===""||SoJuteYarnHeadersData.customerId ===null ? true:false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={SoJuteYarnHeadersData.shippingTo ? 1 : 0}
                        arrayOfData={shippingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={SoJuteYarnHeadersData.shippingTo}
                        name="shippingTo"
                        label="Shipping To"
                        required   
                        isDisabled={SoJuteYarnHeadersData.customerId ===""||SoJuteYarnHeadersData.customerId ===null ? true:false}                     
                      />
                    </div>
                    {!(SoJuteYarnHeadersData.customerId === "" || SoJuteYarnHeadersData.customerId === null) && (
                    <span
                      onClick={openPopup}
                      style={{
                        color: "red",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 100,
                        fontSize: "15px",
                        marginTop: "5px",
                        cursor: "pointer",
                        padding: "5px",
                      }}>
                        Add a new Customer Branch Address
                    </span>
                    )}
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={SoJuteYarnHeadersData.taxType ? 1 : 0}
                        arrayOfData={taxTypeData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={SoJuteYarnHeadersData.taxType}
                        name="TaxType"
                        label="Tax Type"  
                        isDisabled={SoJuteYarnHeadersData.customerId ===""||SoJuteYarnHeadersData.customerId === null ? true:false}                    
                      />
                    </div>
                  </Box>     
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>Sale Order Date <span className="starcolor">*</span></label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="saleDate"
                            inputFormat="dd-MM-yyyy"
                            id="saleDate"
                            minDate={
                              backDaysAllowable !== ""
                                ? backDaysAllowable
                                :SoJuteYarnHeadersData.salesDate
                            }
                            maxDate={
                              futureDaysAllowable !== ""
                                ? futureDaysAllowable
                                : SoJuteYarnHeadersData.salesDate
                            }
                            value={SoJuteYarnHeadersData.salesDate}
                            disabled={isEmpty(SoJuteYarnHeadersData.customerId) ? true:false}
                            // disabled={
                            //   SoJuteYarnHeadersData.customerId !== "" &&
                            //   SoJuteYarnHeadersData.brokerId !== null
                            //     ? true
                            //     : false
                            // }
                            fullWidth
                            onChange={(e) => handleSelect_Date(e, "salesDate")}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                                style={{
                                  backgroundColor: isEmpty(SoJuteYarnHeadersData.customerId)  ? "#ccd6db":"",
                                }}
                              >
                                <input ref={inputRef} {...inputProps}
                                style={{
                                  backgroundColor: isEmpty(SoJuteYarnHeadersData.customerId)  ? "#ccd6db":"",
                                }} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>Expired Date<span className="starcolor">*</span></label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="salesOrderExpiryDate"
                            inputFormat="dd-MM-yyyy"
                            id="salesOrderExpiryDate"
                            value={SoJuteYarnHeadersData.salesOrderExpiryDate}
                            minDate={
                              backDaysAllowable !== ""
                                ? backDaysAllowable
                                :SoJuteYarnHeadersData.salesOrderExpiryDate
                            }
                            maxDate={
                              futureDaysAllowable !== ""
                                ? futureDaysAllowable
                                : SoJuteYarnHeadersData.salesOrderExpiryDate
                            }
                            disabled={ isEmpty(SoJuteYarnHeadersData.salesDate) ? true : false}
                            fullWidth
                            onChange={(e) =>
                              handleSelect_Date(e, "salesOrderExpiryDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                                style={{backgroundColor: isEmpty(SoJuteYarnHeadersData.salesDate) ? "#ccd6db":""}}
                              >
                                <input ref={inputRef} {...inputProps} 
                                style={{backgroundColor: isEmpty(SoJuteYarnHeadersData.salesDate) ? "#ccd6db":""}}
                                 />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Broker %"
                      value={SoJuteYarnHeadersData.brokerCommisionPercent}
                      onChange={onhandlechange("brokerCommisionPercent")}
                      type="number"
                      required={SoJuteYarnHeadersData.brokerId? true:false}
                      disabled={SoJuteYarnHeadersData.brokerId?false :true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                      <DynamicSelectNew
                        update={SoJuteYarnHeadersData.deliveryTerms ? 1 : 0}
                        arrayOfData={DeliveryTermsData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={SoJuteYarnHeadersData.deliveryTerms}
                        name="deliveryTerms"
                        label="Delivery Terms"
                        required
                      />
                 
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <FormPopup isOpen={isPopupOpen} onClose={closePopup} customerData={billingShippingList} customerId={SoJuteYarnHeadersData.customerId}/>
      </div>
    </>
  );
};
const taxTypeData = [
  {
    value: 1,
    name: "IGST",
    label: "IGST",
  },
  {
    value: 2,
    name: "CGST & SGST",
    label: "CGST & SGST",
  },
];
const DeliveryTermsData = [
  {
    value: 1,
    label: "Ex-Works",
    name: "Ex-Works",
  },
  {
    value: 2,
    label: "Delivery to Destination",
    name: "Delivery to Destination",
  },
];
export default JuteYarnFillDetails;
