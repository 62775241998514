
import React, { Component } from 'react'
import TextField from '@mui/material/TextField'
// import AdapterDateFns from '@mui/lab/AdapterDateFns'
// import LocalizationProvider from '@mui/lab/LocalizationProvider'
// import DatePicker from '@mui/lab/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';   
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';   
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment';

class MaterialUIPickers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      month:null,
      year:null
    }
  }
  handleSelect =(e,name)=>{
   if( this.props.field ==='1'){
    if (name === "month") {
      let date = moment(e).format("MM")
      let Date = date.split("-", 1).toString()
      // this.props.month =Date
      this.setState({
       month:Date
      })
      this.props.handleSelect(Date,name,"1", this.props.id)
    }
    if (name === "year") {
      var date = moment(e).format()
      var Date = date.split("-", 1).toString()
      // this.props.year =Date
      this.setState({
        year:Date
      })
      this.props.handleSelect(Date,name,"1", this.props.id)
    }
  }
  if( this.props.field ==='2'){
    if (name === "month") {
      let date = moment(e).format("MM")
      let Date = date.split("-", 1).toString()
      // this.props.month =Date
      this.setState({
       month:Date
      })
      this.props.handleSelect(Date,name,"2", this.props.id)
    }
    if (name === "year") {
      var date = moment(e).format()
      var Date = date.split("-", 1).toString()
      // this.props.year =Date
      this.setState({
        year:Date
      })
      this.props.handleSelect(Date,name,"2", this.props.id)
    }
  }
  }
render(){
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="monthAndYearDatePicker">
        <DatePicker
      
          inputFormat="MMMM"
          views={['month']}
          value={this.props.month}
          onChange={(e) => this.handleSelect(e, 'month')}
          InputAdornmentProps={{}}
          components={{
            OpenPickerIcon: ArrowDropDownIcon,
          }}
          renderInput={(params) => (
            <TextField {...params} className="monthPicker"   
            inputProps={{...params.inputProps, readOnly: true }}
            />
          )}
        />
        <DatePicker
          views={['year']}
          value={this.props.year}
          onChange={(e) => this.handleSelect(e, 'year')}
          InputAdornmentProps={{}}
          components={{
            OpenPickerIcon: ArrowDropDownIcon,
          }}
          renderInput={(params) => (
            <TextField {...params} className="yearPicker"   
            inputProps={{...params.inputProps, readOnly: true }}
            />
          )}
        />
      </div>
    </LocalizationProvider>
  )
}
}
export default MaterialUIPickers;