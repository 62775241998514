import {
  GET_WASTAGE_ENTRY,
  WASTAGE_LIST_TYPE,
  STOCK_WASTAGE_LIST_TYPE,
  CREATE_WASTAGE_ENTRY,
  DELETE_WASTAGE_ENTRY,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getListurl, getList } from "../../../helpers/Server_Helper";
import {
  wastageEntryListSuccessfull,
  wastageTypeListSuccessfull,
  stockwastageTypeListSuccessfull,
  createWastageTypeSuccessfull,
  DeleteWastageTypeEntrySuccessfull,
  wastageEntryList,
} from "./actions";
import swal from "sweetalert";
import { serverApi } from "../../../helpers/Consts";

function* wastageEntryListSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(wastageEntryListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* wastageTypeListSaga({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(wastageTypeListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* DeletewastageTypeListSaga({
  payload: { url, history, fromDate, toDate },
}) {
  try {
    const response = yield call(getListurl, url);
    yield put(DeleteWastageTypeEntrySuccessfull({ response }));
    swal(response.data.message, { icon: "success" });

    if (response.data.status === true) {
      const data = {
        companyId: localStorage.getItem("companyId"),
        cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
        fromDate: fromDate,
        toDate: toDate,
      };

      var apiList = serverApi.GET_WASTAGE_ENTRY;
      yield put(wastageEntryList(apiList, data, history));
    }
  } catch (error) {
    console.log(error);
  }
}

function* stockwastageTypeListSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(stockwastageTypeListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* createWastageTypeSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(createWastageTypeSuccessfull({ response }));
    if (response.status === true) {
      swal(response.message, { icon: "success" });
      history.push("/ad_wastage_entry");
    } else {
      swal(response.message ? response.message : "Something went wrong!!", {
        icon: "error",
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchWastagefEntry() {
  yield takeEvery(GET_WASTAGE_ENTRY, wastageEntryListSaga);
  yield takeEvery(WASTAGE_LIST_TYPE, wastageTypeListSaga);
  yield takeEvery(STOCK_WASTAGE_LIST_TYPE, stockwastageTypeListSaga);
  yield takeEvery(CREATE_WASTAGE_ENTRY, createWastageTypeSaga);
  yield takeEvery(DELETE_WASTAGE_ENTRY, DeletewastageTypeListSaga);
}

function* wastageEntrySaga() {
  yield all([fork(watchWastagefEntry)]);
}

export default wastageEntrySaga;
