import {
  SPINNING_DOFF_ENTRY_LIST,
  SPINNING_DOFF_ENTRY_LIST_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  SpinningDoffData: [],
};

const SpinningDoffEntryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SPINNING_DOFF_ENTRY_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SPINNING_DOFF_ENTRY_LIST_SUCCESSFULL:
      state = {
        ...state,
        SpinningDoffData: action.payload.response,
        loading: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default SpinningDoffEntryReducer;
