import {WEAVING_QUALITY_MASTER_LIST,WEAVING_QUALITY_MASTER_LIST_SUCCESSFULL,CREATE_WEAVING_QUALITY_MASTER_SUCCESSFULL,CREATE_WEAVING_QUALITY_MASTER,
    UPDATE_WEAVING_QUALITY_MASTER,UPDATE_WEAVING_QUALITY_MASTER_SUCCESSFULL,
    API_ERRORS,} from './actionTypes';

export const weavingQualityMasterList = (url,data,history) =>{
    return {
        type:WEAVING_QUALITY_MASTER_LIST,
        payload:{url,data,history}
    }
}
export const weavingQualityMasterListSuccessfull = (data)=>{
    return {
        type:WEAVING_QUALITY_MASTER_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createWeavingQualityMaster = (url, data, history) => {
    return {
        type: CREATE_WEAVING_QUALITY_MASTER,
        payload: { url, data, history }
    }
}

export const createWeavingQualityMasterSuccessfull = (data) => {
    return {
        type: CREATE_WEAVING_QUALITY_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const updateWeavingQualityMaster = (url, data, history) => {
    return {
        type: UPDATE_WEAVING_QUALITY_MASTER,
        payload: { url, data, history }
    }
}

export const updateWeavingQualityMasterSuccessfull = (data) => {
    return {
        type: UPDATE_WEAVING_QUALITY_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const apiErrors = (error) => {
    return {
        type: API_ERRORS,
        payload: error,
    };
};