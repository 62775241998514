import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/PaginationGridView";
import { getInwardList } from "../../../store/Purchase/Inward/actions";
import moment from "moment";
import {
  WorkReportCols,
  InwardCols,
  materialListColumns,
  BillPassColumns,
  SRColumns,
} from "./Grid_Col_headers";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      recordType: localStorage.getItem("recordType"),
      inwardListData: [],
      mcolumns: [],
      urlpath: "",
      startIndex: 0,
      lastIndex: 10,
      totalRecords: 0,
      fromDate: moment().day(-7).format("YYYY-MM-DD").toString(),
      toDate: moment().format("YYYY-MM-DD").toString(),
      dataexp:"",
      supplierId:""
    };
  }

  componentDidMount() {
    const financialYearStartMonth = 3; // Assuming financial year starts in April
    let financialYearStart = moment()
      .year(localStorage.getItem("acadamicYear"))
      .month(financialYearStartMonth)
      .startOf("month");

    const fromDate = financialYearStart.day(-7).format("YYYY-MM-DD");
    const toDate = moment().format("YYYY-MM-DD");

    var startDate = moment(fromDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var endDate = moment(toDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
this.setState({
  fromDate:startDate,
  toDate:endDate,
})
    if (this.props.location.pathname) {
      if (this.props.location.pathname === "/security_store_gate_entry") {
        const data = {
          branchId: "",
          cipher: this.state.userDit.cipher,
          companyId: localStorage.getItem("companyId"),
          financialYear: localStorage.getItem("acadamicYear"),
          erpType: "1",
          fromDate: startDate,
          projectId: "",
          recordType: "GRN",
          supplierId:this.state.supplierId,
          // this.state.userDit.roleId === 100
          //   ? this.state.userDit.suppCode: null,
          toDate: endDate,
          startIndex: this.state.startIndex,
          lastIndex: this.state.lastIndex,
        };
        this.props.getInwardList(
          serverApi.ALLINWARDS,
          data,
          this.props.history
        );
     
        const expdata = {
          ...data,
          startIndex : 0,
          lastIndex : 0,
        }
    this.setState({  dataexp: expdata,supplierId:this.state.supplierId, });
      } else if (this.props.location.pathname === "/workReport") {
        const data = {
          branchId: "",
          cipher: this.state.userDit.cipher,
          companyId: localStorage.getItem("companyId"),
          financialYear: localStorage.getItem("acadamicYear"),
          erpType: "1",
          fromDate: startDate,
          projectId: "",
          recordType: "SRN",
          supplierId: 
          this.state.userDit.roleId === 100
            ? this.state.userDit.suppCode:null,
          toDate: endDate,
          startIndex: this.state.startIndex,
          lastIndex: this.state.lastIndex,
        };
        this.props.getInwardList(
          serverApi.ALLINWARDS,
          data,
          this.props.history
        );
      
        const expdata = {
          ...data,
          startIndex : 0,
          lastIndex : 0,
        }
        this.setState({  dataexp: expdata });
      } else if (this.props.location.pathname === "/store_receipt") {
        const data = {
          fromDate: startDate,
          toDate: endDate,
          supplierId:
          this.state.userDit.roleId === 100
            ? this.state.userDit.suppCode: null,
          cipher: this.state.userDit.cipher,
          companyId: localStorage.getItem("companyId"),
          financialYear: localStorage.getItem("acadamicYear"),
          erpType: "1",
          branchId: "",
          projectId: "",
          recordType: "SR",
          srNum: "",
          poNum: "",
          status: "",
          startIndex: this.state.startIndex,
          lastIndex: this.state.lastIndex,
        };
        this.props.getInwardList(
          serverApi.ALLINWARDS,
          data,
          this.props.history
        );
 
        const expdata = {
          ...data,
          startIndex : 0,
          lastIndex : 0,
        }
        this.setState({  dataexp: expdata });
      } else if (
        this.props.location.pathname === "/store_material_inspection"
      ) {
        const data = {
          fromDate: startDate,
          toDate: endDate,
          supplierId:this.state.supplierId,
          cipher: this.state.userDit.cipher,
          companyId: localStorage.getItem("companyId"),
          financialYear: localStorage.getItem("acadamicYear"),
          erpType: "1",
          branchId: "",
          projectId: "",
          isInspection: true,
          recordType: "GRN",
          startIndex: this.state.startIndex,
          lastIndex: this.state.lastIndex,
        };
        this.props.getInwardList(
          serverApi.ALLINWARDS,
          data,
          this.props.history
        );
    
        const expdata = {
          ...data,
          startIndex : 0,
          lastIndex : 0,
        }
        this.setState({ dataexp: expdata,    supplierId:this.state.supplierId, });
      } else {
        const data = {
          branchId: "",
          cipher: this.state.userDit.cipher,
          companyId: localStorage.getItem("companyId"),
          erpType: "1",
          financialYear: localStorage.getItem("acadamicYear"),
          fromDate: startDate,
          projectId: "",
          recordType: "BP",
          supplierId:
          this.state.userDit.roleId === 100
            ? this.state.userDit.suppCode: null,
          toDate: endDate,
          startIndex: this.state.startIndex,
          lastIndex: this.state.lastIndex,
        };
        this.props.getInwardList(
          serverApi.ALLINWARDS,
          data,
          this.props.history
        );
   
        const expdata = {
          ...data,
          startIndex : 0,
          lastIndex : 0,
        }
        this.setState({  dataexp: expdata });
      }
    }
  }
  componentWillReceiveProps(props) {
    if (props.Inward_List) {
      this.setState({
        inwardListData: props.Inward_List.data,
        totalRecords: props.Inward_List.totalRecords,
      });
   
    }

    if (this.props.location.pathname) {
      if (this.props.location.pathname === "/security_store_gate_entry") {
        this.setState({
          mcolumns: InwardCols,
        });
      } else if (this.props.location.pathname === "/workReport") {
        this.setState({
          mcolumns: WorkReportCols,
        });
      } else if (
        this.props.location.pathname === "/store_material_inspection"
      ) {
        this.setState({
          mcolumns: materialListColumns,
        });
      } else if (this.props.location.pathname === "/store_receipt") {
        this.setState({
          mcolumns: SRColumns,
        });
      } else {
        this.setState({
          mcolumns: BillPassColumns,
        });
      }
    }
  }

  handlePagination = (page, sizePerPage, type) => {
    // const currentIndex = (page - 1) * sizePerPage;
 
    if (this.props.location.pathname) {
      if (this.props.location.pathname === "/security_store_gate_entry") {
        const data = {
          billpassNum: "",
          branchId: "",
          cipher: this.state.userDit.cipher,
          companyId: localStorage.getItem("companyId"),
          financialYear: localStorage.getItem("acadamicYear"),
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          erpType: "1",
          projectId: "",
          recordType: "GRN",
          supplierId:this.state.supplierId,
          startIndex: page-1,
          lastIndex: sizePerPage,
        };
        this.props.getInwardList(
          serverApi.ALLINWARDS,
          data,
          this.props.history
        );
     
      } else if (this.props.location.pathname === "/workReport") {
        const data = {
          branchId: this.state.branchId,
          cipher: this.state.userDit.cipher,
          companyId: localStorage.getItem("companyId"),
          financialYear: localStorage.getItem("acadamicYear"),
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          erpType: "1",
          projectId: "",
          recordType: "SRN",
          supplierId: this.state.supplierId,
          startIndex: page - 1,
          lastIndex: sizePerPage,
          projectId:this.state.projectId,
          status: this.state.status,
        };
        this.props.getInwardList(
          serverApi.ALLINWARDS,
          data,
          this.props.history
        );
     
      } else if (this.props.location.pathname === "/store_receipt") {
        const data = {
          supplierId: null,
          cipher: this.state.userDit.cipher,
          companyId: localStorage.getItem("companyId"),
          financialYear: localStorage.getItem("acadamicYear"),
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          erpType: "1",
          branchId: "",
          projectId: "",
          recordType: "SR",
          srNum: "",
          poNum: "",
          status: "",
          startIndex: page - 1,
          lastIndex: sizePerPage,
        };
        this.props.getInwardList(
          serverApi.ALLINWARDS,
          data,
          this.props.history
        );
    
      } else if (
        this.props.location.pathname === "/store_material_inspection"
      ) {
        const data = {
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          supplierId:this.state.supplierId,
          cipher: this.state.userDit.cipher,
          companyId: localStorage.getItem("companyId"),
          financialYear: localStorage.getItem("acadamicYear"),
          erpType: "1",
          branchId: "",
          projectId: "",
          isInspection: true,
          recordType: "GRN",
          startIndex: page - 1,
          lastIndex: sizePerPage,
        };
        this.props.getInwardList(
          serverApi.ALLINWARDS,
          data,
          this.props.history
        );
     
      } else {
        const data = {
          "billpassNum": "",
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          branchId: "",
          cipher: this.state.userDit.cipher,
          companyId: localStorage.getItem("companyId"),
          erpType: "1",
          financialYear: localStorage.getItem("acadamicYear"),
          projectId: "",
          recordType: "BP",
          supplierId: null,
          "status": "",
          startIndex: page - 1,
          lastIndex: sizePerPage,
        };
        this.props.getInwardList(
          serverApi.ALLINWARDS,
          data,
          this.props.history
        );
    
      }
    }
  };

  handleSearch = (data1) => {
    if (this.props.location.pathname) {
      
      if (this.props.location.pathname === "/security_store_gate_entry") {
        const data = {
          branchId: data1.branchValue,
          cipher: this.state.userDit.cipher,
          companyId: localStorage.getItem("companyId"),
          financialYear: localStorage.getItem("acadamicYear"),
          erpType: "1",
          fromDate: data1.fromDate,
          projectId: data1.projctValue,
          recordType: "GRN",
          supplierId: data1.supplierValue,
          status: data1.StatusValue,
          toDate: data1.toDate,
          startIndex: this.state.startIndex,
          lastIndex: this.state.lastIndex,
          inwardNum: data1.inwardNum,
          poNum: data1.poNum,
        };
        const expdata = {
          ...data,
          startIndex : 0,
          lastIndex : 0,
        }

        this.setState ({
          dataexp :{...data,
            startIndex : 0,
            lastIndex : 0,
            } 
        })
    this.setState({  
      dataexp: expdata ,
      supplierId: data1.supplierValue,
    });
        this.props.getInwardList(
          serverApi.ALLINWARDS,
          data,
          this.props.history
        );
      } else if (this.props.location.pathname === "/workReport") {
        const data = {
          branchId: data1.branchId,
          cipher: this.state.userDit.cipher,
          companyId: localStorage.getItem("companyId"),
          financialYear: localStorage.getItem("acadamicYear"),
          erpType: "1",
          fromDate: data1.fromDate,
          projectId: data1.projctValue,
          recordType: "SRN",
          supplierId: data1.supplierValue,
          toDate: data1.toDate,
          startIndex: this.state.startIndex,
          lastIndex: this.state.lastIndex,
          poNum: data1.poNum,
          inwardNum: data1.inwardNum,
          status: data1.StatusValue,
        };
        const expdata = {
          ...data,
          startIndex : 0,
          lastIndex : 0,
        }
      
    this.setState({  
      dataexp: expdata ,
      supplierId: data1.supplierValue,
       branchId: data1.branchId, 
       projectId: data1.projctValue, 
        status: data1.StatusValue,
      });
        this.props.getInwardList(
          serverApi.ALLINWARDS,
          data,
          this.props.history
        );
      } else if (this.props.location.pathname === "/store_receipt") {
        const data = {
          fromDate: data1.fromDate,
          toDate: data1.toDate,
          supplierId: data1.supplier,
          cipher: this.state.userDit.cipher,
          companyId: localStorage.getItem("companyId"),
          financialYear: localStorage.getItem("acadamicYear"),
          erpType: "1",
          branchId: data1.branchId,
          projectId: data1.projectId,
          recordType: "SR",
          srNum: data1.SrNO,
          poNum: data1.PoNO,
          status: data1.status,
          startIndex: this.state.startIndex,
          lastIndex: this.state.lastIndex,
        };
        const expdata = {
          ...data,
          startIndex : 0,
          lastIndex : 0,
        }
    this.setState({  
      dataexp: expdata,
     });
        this.props.getInwardList(
          serverApi.ALLINWARDS,
          data,
          this.props.history
        );
      } else if (
        this.props.location.pathname === "/store_material_inspection"
      ) {
        const data = {
          fromDate: data1.fromDate,
          toDate: data1.toDate,
          supplierId: data1.supplierValue,
          status:data1.statusValue,
          cipher: this.state.userDit.cipher,
          companyId: localStorage.getItem("companyId"),
          financialYear: localStorage.getItem("acadamicYear"),
          erpType: "1",
          branchId: data1.branchId,
          projectId: data1.projectId,
          isInspection: true,
          recordType: "GRN",
          startIndex: this.state.startIndex,
          lastIndex: this.state.lastIndex,
          inwardNum: data1.inwardNum,
          poNum: data1.poNum,
          // status:data1.StatusValue
        };
        const expdata = {
          ...data,
          startIndex : 0,
          lastIndex : 0,      
        }
    this.setState({  
      dataexp: expdata, 
      supplierId: data1.supplierValue,
     });
        this.props.getInwardList(
          serverApi.ALLINWARDS,
          data,
          this.props.history
        );
      } else {
        const data = {
          branchId: data1.branchId,
          cipher: this.state.userDit.cipher,
          companyId: localStorage.getItem("companyId"),
          erpType: "1",
          financialYear: localStorage.getItem("acadamicYear"),
          fromDate: data1.fromDate,
          projectId: data1.projectId,
          recordType: "BP",
          supplierId: data1.supplier,
          toDate: data1.toDate,
          startIndex: this.state.startIndex,
          lastIndex: this.state.lastIndex,
          billpassNum: data1.billpassNum,
          status: data1.status,
          srNum: data1.SrNO,
          poNum: data1.PoNO,
        };
        const expdata = {
          ...data,
          startIndex : 0,
          lastIndex : 0,
        }
    this.setState({  
      dataexp: expdata ,
    });
        this.props.getInwardList(
          serverApi.ALLINWARDS,
          data,
          this.props.history
        );
      }
   
      this.setState({
        fromDate:data1.fromDate,
        toDate:data1.toDate
      })
    }
  };

  render() {
    var mcolumns = [];
    var mListData = [];
    if (this.props.location.pathname) {
      if (this.props.location.pathname === "/security_store_gate_entry") {
        mcolumns = InwardCols;
        mListData = this.state.inwardListData;
      } else if (this.props.location.pathname === "/workReport") {
        mcolumns = WorkReportCols;
        mListData = this.state.inwardListData;
      } else if (
        this.props.location.pathname === "/store_material_inspection"
      ) {
        mcolumns = materialListColumns;
        mListData = this.state.inwardListData;
      } else if (this.props.location.pathname === "/store_receipt") {
        mcolumns = SRColumns;
        mListData = this.state.inwardListData;
      } else {
        mcolumns = BillPassColumns;
        mListData = this.state.inwardListData;
      }
    }

    return (
      <>
        <Gridwithcustomview
          mdata={mListData}
          mcolumns={mcolumns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          filter_form={
            this.props.location.pathname === "/security_store_gate_entry"
              ? "inward"
              : this.props.location.pathname === "/workReport"
              ? "workReport"
              : this.props.location.pathname === "/store_material_inspection"
              ? "MaterialInspection"
              : this.props.location.pathname === "/store_receipt"
              ? "StoreReceipt"
              : "billPass"
          }
          handleSearch={this.handleSearch}
          url={
            this.props.location.pathname === "/security_store_gate_entry"
              ? "/create_inward"
              : this.props.location.pathname === "/workReport"
              ? "/create_WorkReport"
              : this.props.location.pathname === "/store_material_inspection"
              ? "/create_material_inspection"
              : this.props.location.pathname === "/store_receipt"
              ? "/Create_store_receipt"
              : "/create_store_bill_pass"
          }
          totalRecs={this.state.totalRecords}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          pageno={this.state.pageno}
          apiexp={   serverApi.ALLINWARDS_WITHOUT_PAGINATION}
            // this.props.location.pathname === "/security_store_gate_entry" ?serverApi.ALLINWARDS_WITHOUT_PAGINATION :
            // this.props.location.pathname === "/workReport"?serverApi.ALLINWARDS_WITHOUT_PAGINATION :
            // this.props.location.pathname === "/store_receipt"?serverApi.ALLINWARDS_WITHOUT_PAGINATION :
            // this.props.location.pathname === "/store_bill_pass"?serverApi.ALLINWARDS_WITHOUT_PAGINATION :
            // serverApi.ALLINWARDS_WITHOUT_PAGINATION
        
          payloadexp={this.state.dataexp}
          expName={
          this.props.location.pathname === "/security_store_gate_entry"?'inward.csv':
          this.props.location.pathname === "/workReport"?"WorkReport.csv":
          this.props.location.pathname === "/store_material_inspection"?"MaterialInspection.csv":
          this.props.location.pathname === "/store_receipt"?"StoreReceipt.csv":"billPass.csv"
          
        }
        fromDate={this.state.fromDate}
      
        toDate={this.state.toDate}
        />
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Inward_List } = state.InwardReducer;
  return {
    ...state,
    Inward_List,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getInwardList,
  })(Index)
);
