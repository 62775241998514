import React, { Component } from "react";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getExpenseBookingList,
  getWorkerDataClearForExpenseBooking,
} from "../../../store/HRMS/ExpenseBooking/actions";
import { SetClearFiles } from "../../../store/HRMS/EmployeeDataBase/actions";
import { serverApi } from "../../../helpers/Consts";
class ExpenseBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      ExpenseBookingListData: [],
    };
  }
  componentDidMount() {
    this.props.getExpenseBookingList(
      serverApi.EXPENSE_BOOKING_LIST +
        this.state.companyId +
        "/" +
        this.state.userDit.userId,
      this.props.history
    );
    this.props.getWorkerDataClearForExpenseBooking(null);
    this.props.SetClearFiles(null);
  }
  componentWillReceiveProps(props) {
    if (props.expenseBooking_list) {
      this.setState({
        ExpenseBookingListData: props.expenseBooking_list.data,
      });
    }
  }
  render() {
    return (
      <>
        {" "}
        <Gridwithcustomview
          mdata={this.state.ExpenseBookingListData}
          mcolumns={ExpenseBookingListColumn}
          micon={GridArrowIcon}
          actions={true}
          url="/create_expense"
        />
      </>
    );
  }
}
const ExpenseBookingListColumn = [
  {
    Header: "Id",
    accessor: "recId",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Worker Name",
    accessor: "workerName",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Create Date",
    accessor: "createDateDesc",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Description",
    accessor: "description",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Project",
    accessor: "project",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Total",
    accessor: "total",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Due Amount",
    accessor: "dueAmount",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Status",
    accessor: "statusDesc",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Academic Year",
    accessor: "acYear",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Advance Taken",
    accessor: "advanceTaken",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Approve Button",
    accessor: "approveButton",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Company Id",
    accessor: "companyId",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Department",
    accessor: "department",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "EB Id",
    accessor: "ebId",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "EB Number",
    accessor: "ebNo",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "From Date",
    accessor: "fromDate",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "From Date Description",
    accessor: "fromDateDesc",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Is Active",
    accessor: "isActive",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Paid  Amount",
    accessor: "paidAmount",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Pay Date",
    accessor: "payDate",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Pay Date Description",
    accessor: "payDateDesc",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Pay Note",
    accessor: "payNote",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Status ID",
    accessor: "status",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "To Date",
    accessor: "toDate",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "To Date Description",
    accessor: "toDateDesc",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Up date By",
    accessor: "updateBy",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "update Date Description",
    accessor: "updateDateDesc",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Updated Date Time",
    accessor: "updatedDateTime",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "User ID",
    accessor: "userId",
    hidden: true,
    csvExport: false,
  },
];
const mapStatetoProps = (state) => {
  const { expenseBooking_list } = state.ExpenseBookingReducer;
  return { expenseBooking_list };
};

export default withRouter(
  connect(mapStatetoProps, {
    getExpenseBookingList,
    getWorkerDataClearForExpenseBooking,
    SetClearFiles,
  })(ExpenseBooking)
);
