import React, { Component } from 'react'
import { Grid, Step, Stepper, StepLabel } from '@mui/material'
import Link from '@mui/material/Link'
import CustomButton from '../../../components/Buttons/Buttons'
import ReceiptFillDetails from './ReceiptFillDetails'
import ReceiptAddItems from './ReceiptAddItems'
import ReceiptPreview from './ReceiptPreview'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import CommonPopup from '../../../components/CommonPopup/CommonPopup'
import {getPaymentCreate} from '../../../store/Accounting/Payments/actions'
import { serverApi } from '../../../helpers/Consts';
import moment from 'moment'
import swal from 'sweetalert'

class CreateReceipts extends Component {
  constructor() {
    super()
    this.state = {
      steps: [
        {
          title: 'Fill Details',
          icon: 'http://vowerp.com/MailImages/assets/indentDisabled01.png',
          activeIcon: 'http://vowerp.com/MailImages/assets/indentActive01.png',
          onClick: (e) => {
            e.preventDefault()
          
          },
        },
        {
          title: 'Add Items',
          icon: 'http://vowerp.com/MailImages/assets/indentDisabled02.png',
          activeIcon: 'http://vowerp.com/MailImages/assets/indentActive02.png',
          onClick: (e) => {
            e.preventDefault()
          
          },
        },
        {
          title: 'Preview',
          icon: 'http://vowerp.com/MailImages/assets/indentDisabled03.png',
          activeIcon: 'http://vowerp.com/MailImages/assets/indentActive03.png',
          onClick: (e) => {
            e.preventDefault()
          
          },
        },
      ],
      currentStep: 1,
      key: 0,
      date: null,
      status: 0,
      confirm: false,
      header: 
        {
          ledgerId: 0,
          transactionType: "P",
          fundsIn: "",
          fundsOut: "",
          bankId: 0,
          transactionDate: '',
          tdsAmount: 0,
          tdsReason: "",
          createdBy: "",
          companyId: localStorage.getItem('companyId'),
          paymentSource: "",
          bankRefnumber: "",
          description: "",
          acYear: '2022' ,
          tranStatus: 1,
          advanceId: "",
          branchId: 0,
          transactionId: 0,
          ledgerName: ""
        }
      ,
      products: [
        {
          id: "0",
          no: 0,
         
          amount:0,
          billId:0,
          createOn:null,
          createdBy:null,
          invoiceId:0,
          invoiceNo:0,
          isActive:1,
          modifiedBy:null,
          receiptNo:0,
          transactionId:0,
          trnDetailId:1
        },
      ]
    }
  } 

  onClickNext = () => {
    const { currentStep } = this.state
    this.setState({
      ledgerId: this.state.header.ledgerId,
      transactionType: "P",
      fundsIn: this.state.header.fundsIn,
      fundsOut: this.state.header.fundsOut,
      bankId: this.state.header.bankId,
      transactionDate: this.state.header.transactionDate,
      tdsAmount: this.state.header.tdsAmount,
      tdsReason: this.state.header.tdsReason,
      createdBy: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem('companyId'),
      paymentSource: this.state.header.paymentSource,
      bankRefnumber: this.state.header.bankRefnumber,
      description: this.state.header.description,
      acYear: "2022",
      tranStatus: 1,
      advanceId: this.state.header.advanceId,
      branchId: this.state.header.branchId,
      ledgerName: this.state.ledgerName
      
    })
    if(currentStep === 1){
    if(this.state.header.branchId === 0){
      swal("Please Select Branch!!!")
      return false;
    }
    else if(this.state.header.transactionDate === ''){
      swal("Please select the Date!!!");
      return false
    }
    else if(this.state.header.bankId === 0){
      swal("Please select the Account!!!");
      return false;
    }
    else if(this.state.header.paymentSource === ""){
      swal("Please Select the Receipt source!!!");
      return false;
    }

    this.setState({
      currentStep: currentStep + 1,
    })

  }

    else {
      this.setState({
        confirm: true,
      });
    }

   
  }

  onClickBefore = () => {
    const { currentStep } = this.state    
    if (currentStep > 1) {
      this.setState({
        currentStep: currentStep - 1,
      })
      this.setState({
        branchId: this.state.header.branchId
      })
    } else {
      this.props.history.push('/receipts')
    }
  }

  QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp
    return (
      <div style={{ textAlign: 'center' }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: '#00abdc', margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    )
  }

  handleSelectChange = (selectedValue, selectedName, name) => {
    
    

  }
  onclickOk() {
    this.setState({
      confirm: false,
    });

  }
  handleSelectDate = (value, name) => {
   
    if(name === 'transactionDate') {
      this.setState({
        transactionDate: value,
      });
    }
    
  };

  onhandlechangeValue = (value, key) => {
    
    
  };

 

  delete = (filterProducts) => {
    this.setState({
      products:filterProducts
    })
   

  };
  

  componentWillReceiveProps(props) {
    if(props.location.state) {
      
      if(props.location.state.rowData.transactions !== null) {
     
        let productsData =  props.location.state.rowData.transactions.tranDetails
        // header updation
        var date = props.location.state.rowData.tranDate
        var tranDate = moment(date, 'DD-MM-YYYY', true).format('YYYY-MM-DD')
        this.state.header.ledgerId = props.location.state.rowData.transactions.ledgerId
        this.state.header.transactionDate = tranDate
        this.state.header.ledgerName = props.location.state.rowData.ledgerName
        this.state.header.branchId =  props.location.state.rowData.transactions.branchId
        this.state.header.bankRefnumber = props.location.state.rowData.transactions.bankRefnumber
        this.state.header.transactionId = props.location.state.rowData.transactions.transactionId
        this.state.header.bankId = props.location.state.rowData.bankId
        this.state.header.paymentSource = props.location.state.rowData.transactions.paymentSource
        this.state.header.fundsIn = props.location.state.rowData.transactions.fundsIn;
        this.state.header.description =
        props.location.state.rowData.transactions.description;
        this.state.header.paymentSource = props.location.state.rowData.transactions.paymentSource

        productsData.push({
          id: "0",
          no: 0,
          billId: 0,
          invoiceNo: '',
          amount: 0,
          createdBy: '',
          receiptNo: 'null',
          transactionId: 0,
          ledgerName: '',
          isActive:1,
          invoiceId: 0
        })
       
        this.setState({
          status:this.props.location.state.rowData.transactions.tranStatus,
          products: productsData,
          ledgerId: props.location.state.rowData.transactions.ledgerId,
          transactionDate: tranDate,
          branchId: props.location.state.rowData.transactions.branchId,
          bankRefnumber: props.location.state.rowData.transactions.bankRefnumber,
          transactionId: props.location.state.rowData.transactions.transactionId,
          bankId: props.location.state.rowData.bankId,
          paymentSource: props.location.state.rowData.transactions.paymentSource,
          fundsIn: props.location.state.rowData.transactions.fundsIn,
          description: props.location.state.rowData.transactions.description,
          ledgerName: props.location.state.rowData.ledgerName,
        })
        
      }
      
    }
   
  }

  onCreatePayment = () => {
    if(!this.props.location.state) {
     let userId = JSON.parse(localStorage.getItem("authUser"))
     let products = this.state.products.filter((product) => {
       return (
         product.amount!= 0 && product.isActive !=0
       );
     });
     let data = {
       ledgerId: this.state.ledgerId === undefined ? '' : this.state.ledgerId,
       billPassid: 0,
       transactionType: "R",
       fundsOut: this.state.fundsOut === undefined ? '': this.state.fundsOut,
       fundsIn: this.state.header.fundsIn,
       bankId: this.state.bankId,
       transactionDate: this.state.transactionDate,
       tdsAmount: 0,
       tdsReason: "",
       createdBy: userId.userId,
       companyId: localStorage.getItem('companyId'),
       paymentSource: this.state.paymentSource,
       bankRefnumber: this.state.bankRefnumber,
       description: this.state.header.description,
       acYear: '2022' ,
       tranStatus: 1,
       // advanceId: "",
       branchId: this.state.branchId,
       // totalAmount: 0
       tranDetails: products.map((prop, ind) =>({
         // billId: prop.billId,
         amount: prop.amount,
         invoiceNo: prop.invoiceNo,
         receiptNo: prop.receiptNo,
         createdBy: userId.userId,
         isActive: prop.isActive
       }))
     }
     this.props.getPaymentCreate(serverApi.CREATE_PAYMENT, data, this.props.history)
   
    }else {
     let userId = JSON.parse(localStorage.getItem("authUser"))
     let products = this.state.products.filter((product) => {
       return (
         product.amount != 0 
       );
     });
     // products.pop()
     let data = {
       ledgerId: this.state.header.ledgerId,
       transactionId: this.state.header.transactionId,
       transactionType: "R",
       fundsOut: this.state.fundsOut === undefined ? '': this.state.fundsOut,
       fundsIn: this.state.header.fundsIn,
       bankId: this.state.header.bankId,
       transactionDate: this.state.header.transactionDate,
       tdsAmount: 0,
       recievedAmount: 0,
       paidAmount: 0,
       tdsReason: "",
       updatedBy: userId.userId,
       companyId: localStorage.getItem('companyId'),
       paymentSource: this.state.header.paymentSource,
       bankRefnumber: this.state.header.bankRefnumber,
       description: this.state.header.description,
       acYear: '2022' ,
       tranStatus: '',
    
       userId: userId.userId,
       branchId: this.state.header.branchId,
     
         tranDetails: products.map((prop, ind) =>({
 
         amount: prop.amount,
         invoiceNo: prop.invoiceNo,
         receiptNo: prop.receiptNo,
         modifiedBy: userId.userId,
         transactionId: this.state.header.transactionId,
    
         trnDetailId: prop.trnDetailId,
         isActive: prop.isActive
         
       }))
     }
     this.props.getPaymentCreate(serverApi.UPDATE_PAYMENT, data, this.props.history)
   
    }
   }
 
   onApproveClick = () => {
     let userId = JSON.parse(localStorage.getItem("authUser"))
     let products = this.state.products.filter((product) => {
       return (
         product.amount !== 0
       );
     });
     // products.pop()
     let data = {
       ledgerId: this.state.header.ledgerId,
       transactionId: this.state.header.transactionId,
       transactionType: "R",
       fundsOut: this.state.fundsOut === undefined ? '': this.state.fundsOut,
       fundsIn: this.state.header.fundsIn,
       bankId: this.state.header.bankId,
       transactionDate: this.state.header.transactionDate,
       tdsAmount: 0,
       recievedAmount: 0,
       paidAmount: 0,
       tdsReason: "",
       updatedBy: userId.userId,
       companyId: localStorage.getItem('companyId'),
       paymentSource: this.state.header.paymentSource,
       bankRefnumber: this.state.header.bankRefnumber,
       description: this.state.header.description,
       acYear: '2022' ,
       tranStatus: 1,
   
       userId: userId.userId,
       branchId: this.state.header.branchId,
       
         tranDetails: products.map((prop, ind) =>({
      
         amount: prop.amount,
         invoiceNo: prop.invoiceNo,
         receiptNo: prop.receiptNo,
         modifiedBy: userId.userId,
         transactionId: this.state.header.transactionId,
      
         trnDetailId: prop.trnDetailId,
         isActive: prop.isActive
    
       }))
     }
     this.props.getPaymentCreate(serverApi.UPDATE_PAYMENT, data, this.props.history)
  
   }


  render() {
    const { steps, currentStep } = this.state
 
    return (
      <div>
   
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            <div className="iconsBlock">
              <Stepper alternativeLabel activeStep={currentStep}>
                {steps.map((prop) => (
                  <Step key={prop.title}>
                    <StepLabel
                      StepIconComponent={(Iconprops) => {
                        return this.QontoStepIcon(
                          Iconprops,
                          prop.icon,
                          prop.activeIcon,
                          prop.title,
                        )
                      }}
                    >
                    
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {currentStep === 1 && (
              <>
                <ReceiptFillDetails onSelectDep={this.handleSelectChange} onhandlechangeValue={this.onhandlechangeValue} handleSelectDate={this.handleSelectDate} header={this.state.header}/>
                {this.props.location.state ? this.props.location.state.rowData.transactions !== null ? 
                <div className="consoleFormButtonBlock">
                <Link>
                  <CustomButton
                    label={'Cancel'}
                    className="greenBorderButton"
                    handleClick={this.onClickBefore}
                  />
                </Link>
                <CustomButton
                  label={'Next'}
                  className="greenButton"
                  handleClick={this.onClickNext}
                  type="sumbit"
                />
                {this.state.confirm && (
                  <CommonPopup>
                    <div className="delAddItem">
                      <div>Please Enter the mandatory Fields !</div>
                      <div className="delAddItemBtns">
                        <input
                          type="button"
                          onClick={this.onclickOk.bind(this)}
                          value="Ok!"
                          className="delYesBtn"
                        />
                      </div>
                    </div>
                  </CommonPopup>
                )}
              </div> : "" : <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={'Cancel'}
                      className="greenBorderButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={'Next'}
                    className="greenButton"
                    handleClick={this.onClickNext}
                    type="sumbit"
                  />
                  {this.state.confirm && (
                    <CommonPopup>
                      <div className="delAddItem">
                        <div>Please Enter the mandatory Fields !</div>
                        <div className="delAddItemBtns">
                          <input
                            type="button"
                            onClick={this.onclickOk.bind(this)}
                            value="Ok!"
                            className="delYesBtn"
                          />
                        </div>
                      </div>
                    </CommonPopup>
                  )}
                </div>  
              }
                
              </>
            )}
            {currentStep === 2 && (
              <>
                <ReceiptAddItems products={this.state.products} header={this.state.header} delete={this.delete}/>
                <div className="consoleFormButtonBlock"> 
                  <Link>
                    <CustomButton
                      label={'Cancel'}
                      className="greenBorderButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={'Next'}
                    className="greenButton"
                    handleClick={this.onClickNext}
                    type="sumbit"
                  />
                </div>
              </>
            )}
            {currentStep === 3 && (
              <>
                <ReceiptPreview products={this.state.products} header={this.state.header}/>
                <div className="consoleFormButtonBlock"> 
                  {this.state.status === 1 ? <>
                    <Link>
                    <CustomButton
                      label={'Cancel'}
                      className="greenBorderButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={'Update'}
                    className="greenButton"
                    handleClick={this.onCreatePayment}
                    type="sumbit"
                  />
                   <CustomButton
                    label={'Approve'}
                    className="greenButton"
                    handleClick={this.onApproveClick}
                    type="sumbit"
                  />
                  </> : this.state.status === 3 ? 
                  
                  <Link>
                  <CustomButton
                    label={'Cancel'}
                    className="greenBorderButton"
                    handleClick={this.onClickBefore}
                  />
                </Link> : <>
                <Link>
                    <CustomButton
                      label={'Cancel'}
                      className="greenBorderButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={'Next'}
                    className="greenButton"
                    handleClick={this.onCreatePayment}
                    type="sumbit"
                  />
                </>
                } 
                  
                  
             
  
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStatetoProps = (state) => {}

export default withRouter(connect(mapStatetoProps, {
  getPaymentCreate
})(CreateReceipts))
