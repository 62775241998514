import {
  WEAVING_QUALITY_MASTER_LIST,
  CREATE_WEAVING_QUALITY_MASTER,
  UPDATE_WEAVING_QUALITY_MASTER,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { postAdd, getList } from "../../../helpers/Server_Helper";
import {
  weavingQualityMasterListSuccessfull,
  createWeavingQualityMasterSuccessfull,
  updateWeavingQualityMasterSuccessfull,
} from "./actions";
import swal from "sweetalert";

function* WeavingQualityMasterList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(weavingQualityMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateWeavingQualityMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createWeavingQualityMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/weaving_quality_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateWeavingQualityMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateWeavingQualityMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/weaving_quality_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

export function* watchWeavingQualityMasterList() {
  yield takeEvery(WEAVING_QUALITY_MASTER_LIST, WeavingQualityMasterList);
}
export function* watchCreateWeavingQualityMaster() {
  yield takeEvery(CREATE_WEAVING_QUALITY_MASTER, CreateWeavingQualityMaster);
}
export function* watchUpdateWeavingQualityMaster() {
  yield takeEvery(UPDATE_WEAVING_QUALITY_MASTER, UpdateWeavingQualityMaster);
}

function* WeavingQualityMasterSaga() {
  yield all([
    fork(watchWeavingQualityMasterList),
    fork(watchCreateWeavingQualityMaster),
    fork(watchUpdateWeavingQualityMaster),
  ]);
}

export default WeavingQualityMasterSaga;
