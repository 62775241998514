import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/PaginationGridView";
import { mukamMasterList } from "../../../store/Master/MukamMaster/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      mukamMasterListData: [], // to display list data
      mukamName: "",
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      dataexp:"",
    };
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
    };
    this.props.mukamMasterList(
      serverApi.GET_ALL_MUKAM_MASTER_LIST,
      data,
      this.props.history
    ); // calling mukam Master list API
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({  dataexp: expdata });

  }
  componentWillReceiveProps(props) {
    if (props.MukamListData) {
      this.setState({
        totalRecs: props.MukamListData.totalRecords,
      })
      if (props.MukamListData.data) {
        var mukamList = props.MukamListData.data.map((row) => {
          row.mukam.itemList = row.itemList;
          return row;
        });
      
        
        this.setState({
          mukamMasterListData: mukamList, // updating mukam master list
        });
      }
    }
  }
  handleSearch = (data) => {
    const searchdata = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      mukamName: data.mukamName,
      startIndex: 1,
      lastIndex: 10,
      //  mukamId: data.mukamId === ""?null:data.mukamId,
    };
    this.setState({
      mukamName: data.mukamName,
    });
    this.props.mukamMasterList(
      serverApi.GET_ALL_MUKAM_MASTER_LIST,
      searchdata,
      this.props.history
    ); // calling mukam Master list API
  };
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      mukamName: this.state.mukamName,
    };
    if (type === 1) {
      data.startIndex = sizePerPage;
      data.lastIndex = currentIndex;
      if (currentIndex >= 0) {
        this.props.mukamMasterList(
          serverApi.GET_ALL_MUKAM_MASTER_LIST,
          data,
          this.props.history
        );
      }
    } else {
      data.startIndex =  currentIndex ===0?currentIndex+1:currentIndex;
      data.lastIndex = sizePerPage;
      if (currentIndex >= 0) {
        this.props.mukamMasterList(
          serverApi.GET_ALL_MUKAM_MASTER_LIST,
          data,
          this.props.history
        );
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
      // loadingdata: true
    });
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.mukamMasterListData.map((item) => item.mukam)}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          url="/create_mukam_master"
          filter_form={"Mukam_Master"}
          handleSearch={this.handleSearch}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          apiexp={serverApi.GET_ALL_MUKAM_MASTER_LIST}
          payloadexp={this.state.dataexp}
          expName={"Mukam.csv"}
          type={"Mukam"}
        />
      </>
    );
  }
}
// mukam master list Headers
const Columns = [
  // {
  //   Header: "Mukam Id",
  //   accessor: "id",
  //   hidden: false
  // },
  {
    Header: "Mukam Name",
    accessor: "mukamName",
    hidden: false,
  },
];
const mapStatetoProps = (state) => {
  const { MukamListData } = state.MukamMasterReducer; // fetching list from mukam master reducer
  return { MukamListData };
};
export default withRouter(
  connect(mapStatetoProps, {
    mukamMasterList,
  })(Index)
);
