import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import DynamicSelect from "../../../../components/Dropdown/DynamicSelect";
import { Grid, Box, IconButton, Button } from "@mui/material";
import { allItemGroupMasterList } from "../../../../store/Global/DropDownApis/actions";
import { getQuotationItemsByItemGroupId } from "../../../../store/Sales/Qutation/actions";
import { serverApi, serverConfig } from "../../../../helpers/Consts";
import TickIcon from "../../../../assets/images/fileUploadedTick.png";
import DeleteIcons from "../../../../assets/images/picEditIcon.png";
import panUploadIcon from "../../../../assets/images/panUploadIcon.png";
import { getFileDownload } from "../../../../helpers/server.js";
import {
  getJuteGovtDeliveryOrderLineItems,
  getJuteGovtDeliveryOrderHeader,
  getDeliveryOrderItemsByItemGroupId,
  deleteDoFile,
  UploadDOFiles,
  UploadDOFilesSuccessfull,
} from "../../../../store/Sales/DeliveryOrder/actions";
import swal from "sweetalert";
import { SalesGstCalculations } from "../../../../Utilities/SalesGstCalculation";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import {
  limitDecimals,
  convertPrimaryToSecondary,
  convertRatePcsToMt,
  isEmpty,
} from "../../../../Utilities/helper";
import ReactTooltip from "react-tooltip";

const JuteGovtLineItems = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [LineItems, setLineItems] = useState([]);
  const [locationState] = useState(useLocation());
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [LineItemsDisplayTxtField, setLineItemsDisplayTxtField] = useState({});
  const [LineItemsDisplayDropField, setLineItemsDisplayDropField] = useState(
    {}
  );
  const [ItemGroupData, setItemGroupData] = useState([]);
  const [ItemNameData, setItemNameData] = useState([]);
  const [userDit, setuserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [HeaderData, setHeaderData] = useState({});

  const { allItemGroupMaster_List } = useSelector(
    (state) => state.DropDownListReducer
  );
  const {
    JuteGovtDeliveryOrderLineItems,
    JuteGovtDeliveryOrderHeader,
    DeliveryOrderItemBy_ItemGroupId,
    DOFiles,
  } = useSelector((state) => state.DeliveryOrderReducer);
  const { quotationItemBy_ItemGroupId } = useSelector(
    (state) => state.QuotationReducer
  );
  const [deliveryOrderFiles, SetDeliveryOrderFiles] = useState(
    DOFiles?.data?.data || []
  );
  useEffect(() => {
    SetDeliveryOrderFiles(DOFiles?.data?.data);
  }, [DOFiles]);
  const onDeleteFile = (fileId) => {
    let data = deliveryOrderFiles.filter(
      (item) => item.fileUploadId !== fileId
    );
    SetDeliveryOrderFiles(data);
    dispatch(UploadDOFilesSuccessfull(data));
    dispatch({ type: "UPDATE_DO_FILES", payload: data });
    dispatch(deleteDoFile(serverApi.DELETEFILE + fileId));
  };
  const handleUpload = (e) => {
    e.preventDefault();
    let sourceMenu = 46;

    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", sourceMenu);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", HeaderData.regDeliveryOrderId);
    formData.append(
      "createdBy",
      JSON.parse(localStorage.getItem("authUser")).userId
    );
    formData.append("fileCompanyId", localStorage.getItem("companyId"));

    dispatch(
      UploadDOFiles(
        serverApi.UPLOADFILE,
        formData,
        history,
        sourceMenu,
        HeaderData.regDeliveryOrderId
      )
    );
  };

  useEffect(() => {
    setLineItems(JuteGovtDeliveryOrderLineItems);
    setHeaderData(JuteGovtDeliveryOrderHeader);
    if (allItemGroupMaster_List) {
      if (allItemGroupMaster_List.data) {
        let ListFilter = allItemGroupMaster_List.data.filter((props) => {
          if (props.value !== "0") {
            return props;
          }
        });
        let list = [];
        ListFilter.map((prop) => {
          list.push({
            label: prop.label,
            name: prop.name,
            value: prop.value,
            id: prop.id,
          });
        });
        setItemGroupData(list);
      }
    }
    if (quotationItemBy_ItemGroupId) {
      let ListFilter = quotationItemBy_ItemGroupId.filter((data) => {
        if (data.value !== "0") {
          return data;
        }
      });
      let list = [];
      ListFilter.map((prop) => {
        list.push({
          label: prop.label,
          name: prop.name,
          value: prop.value,
          id: prop.itemId,
        });
      });
      setItemNameData(list);
    }
    if (DeliveryOrderItemBy_ItemGroupId) {
      if (DeliveryOrderItemBy_ItemGroupId.length !== 0) {
        let ListFilter = DeliveryOrderItemBy_ItemGroupId.filter((data) => {
          if (data.value !== "0") {
            return data;
          }
        });
        var list = [];
        ListFilter.map((prop) => {
          list.push({
            label: prop.label,
            name: prop.name,
            value: prop.value,
            variant: prop.itemCode,
          });
        });
        setItemNameData(ListFilter);
      }
    }
  }, [
    JuteGovtDeliveryOrderLineItems,
    allItemGroupMaster_List,
    quotationItemBy_ItemGroupId,
    JuteGovtDeliveryOrderHeader,
    DeliveryOrderItemBy_ItemGroupId,
  ]);

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };

  const onDel = (row) => {
    var FilterLineItems = LineItems.filter((item) => item.isActive !== 0);
    if (FilterLineItems.length == 1) {
      swal("you can't delete the last lineitem");
    } else {
      let indexItem = LineItems.findIndex((item) => item === row);
      let lineItems = [...LineItems];
      lineItems[indexItem].isActive = 0;
      lineItems[indexItem].govtIsActive=0;
      setLineItems(lineItems);
      dispatch(getJuteGovtDeliveryOrderLineItems(lineItems));
      let DiscType = SalesGstCalculations(
        {
          lineItems: lineItems,
          taxType: HeaderData.taxType,
        },
        "SALES_REGULAR_FLOW"
      );
      handleCalculation(DiscType);
    }
  };

  const displayField = (row, colname, type) => {
    if (colname === "itemGroupId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type == 1) {
        dispatch(
          allItemGroupMasterList(
            serverApi.GET_ALL_ITEMGROUP_MASTER_LIST +
              localStorage.getItem("companyId") +
              "/" +
              userDit.cipher,
            history
          )
        );
      }
    } else if (colname === "itemId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      const data = {
        companyId: localStorage.getItem("companyId"),
        consumable: "",
        itemGroupId: row.itemGroupValue,
        saleable: "Y",
        branchId: HeaderData.branch,
      };
      dispatch(
        getDeliveryOrderItemsByItemGroupId(
          serverApi.GET_ITEMSBY_FORPROCUREMENT,
          data,
          history,
          row.id
        )
      );
    }
  };

  const OnhandleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    let mname = key.split("_");
    if (mname[0] === "remarks") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.remarks = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteGovtDeliveryOrderLineItems(LineItemsRec));
    }
    if (mname[0] === "itemMake") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.itemMake = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteGovtDeliveryOrderLineItems(LineItemsRec));
    }
    if (mname[0] === "hsncode") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.hsncode = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteGovtDeliveryOrderLineItems(LineItemsRec));
    }
    if (mname[0] === "rate") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          // rowItem.rate = value;
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            rowItem.rate = value;
            if (Number(value) < Number(rowItem.discount)) {
              swal("Rate must be greater than the discount.");
              return rowItem;
            }
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
          if (rowItem.govtQuantityUom !== "") {
            const balesResult = convertPrimaryToSecondary(
              rowItem.quantity,
              rowItem.govtRelationValue
            );
            if (balesResult !== null) {
              rowItem.govtQtyCalculatedValue = balesResult;
            } else {
              console.log("Conversion failed");
            }
          }
          if (rowItem.govtQuantityUom2 !== "") {
            const mtResult = convertPrimaryToSecondary(
              rowItem.quantity,
              rowItem.govtRelationValue2
            );
            if (mtResult !== null && rowItem.govtQuantityUom2 !== "") {
              rowItem.govtQtyCalculatedValue2 = mtResult;
            } else {
              console.log("Conversion failed");
            }
          }
          if (rowItem.govtQuantityUom3 !== "") {
            const kgsResult = convertPrimaryToSecondary(
              rowItem.quantity,
              rowItem.govtRelationValue3
            );
            if (kgsResult !== null && rowItem.govtQuantityUom3 !== "") {
              rowItem.govtQtyCalculatedValue3 = kgsResult;
            } else {
              console.log("Conversion failed");
            }
          }
          if (rowItem.govtQuantityUom !== "") {
            // rate UOM conversion
            const mtRateResult = convertRatePcsToMt(
              value,
              rowItem.govtRelationValue2
            );
            if (mtRateResult !== null && rowItem.govtQuantityUom !== "") {
              rowItem.govtRateCalculatedValue2 = mtRateResult;
            } else {
              console.log("Conversion failed");
            }
          }
          if (rowItem.discount !== "") {
            if (rowItem.discountType == 1 && value !== "") {
              rowItem.discountedRate = limitDecimals(value - rowItem.discount);
            } else {
              let data = (value * rowItem.discount) / 100;
              rowItem.discountedRate = limitDecimals(value - data, 3);
            }
          } else {
            rowItem.discountedRate = limitDecimals(value, 3);
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteGovtDeliveryOrderLineItems(LineItemsRec));
      let Rate = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
        },
        "SALES_REGULAR_FLOW"
      );
      handleCalculation(Rate);
    }

    if (mname[0] === "discountedRate") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.discountedRate = limitDecimals(value, 3);
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteGovtDeliveryOrderLineItems(LineItemsRec));
    }
    if (mname[0] === "quantity") {
      let updatedLineItems = LineItems.map((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            if (rowItem.itemGroupValue === "997") {
              rowItem.quantity = value;
              // Method for UOM Conversion
              if (rowItem.govtDeliveryQuantityUom !== "") {
                const balesResult = convertPrimaryToSecondary(
                  value,
                  rowItem.govtDeliveryRelationValue
                );
                rowItem.govtDeliveryQtyCalculatedValue = balesResult;
              }
              if (rowItem.govtDeliveryQuantityUom2 !== "") {
                const mtResult = convertPrimaryToSecondary(
                  value,
                  rowItem.govtDeliveryRelationValue2
                );
                rowItem.govtDeliveryQtyCalculatedValue2 = mtResult;
              }
              if (rowItem.govtDeliveryQuantityUom3 !== "") {
                const kgsResult = convertPrimaryToSecondary(
                  value,
                  rowItem.govtDeliveryRelationValue3
                );
                rowItem.govtDeliveryQtyCalculatedValue3 = kgsResult;
              }
              if (rowItem.govtDeliveryQuantityUom !== "") {
                const mtRateResult = convertRatePcsToMt(
                  rowItem.rate,
                  rowItem.govtDeliveryRelationValue2
                );
                rowItem.govtDeliveryRateCalculatedValue2 = mtRateResult;
              }
            } else {
              if (Number(value) <= Number(rowItem.doBalQty)) {
                rowItem.quantity = value;
                // Method for UOM Conversion
                if (rowItem.govtDeliveryQuantityUom !== "") {
                  const balesResult = convertPrimaryToSecondary(
                    value,
                    rowItem.govtDeliveryRelationValue
                  );
                  rowItem.govtDeliveryQtyCalculatedValue = balesResult;
                }
                if (rowItem.govtDeliveryQuantityUom2 !== "") {
                  const mtResult = convertPrimaryToSecondary(
                    value,
                    rowItem.govtDeliveryRelationValue2
                  );
                  rowItem.govtDeliveryQtyCalculatedValue2 = mtResult;
                }
                if (rowItem.govtDeliveryQuantityUom3 !== "") {
                  const kgsResult = convertPrimaryToSecondary(
                    value,
                    rowItem.govtDeliveryRelationValue3
                  );
                  rowItem.govtDeliveryQtyCalculatedValue3 = kgsResult;
                }
                if (rowItem.govtDeliveryQuantityUom !== "") {
                  const mtRateResult = convertRatePcsToMt(
                    rowItem.rate,
                    rowItem.govtDeliveryRelationValue2
                  );
                  rowItem.govtDeliveryRateCalculatedValue2 = mtRateResult;
                }
              } else {
                swal("Quantity can't be more than Balance Quantity.");
                rowItem.quantity = rowItem.doBalQty;
                // Method for UOM Conversion
                if (rowItem.govtDeliveryQuantityUom !== "") {
                  const balesResult = convertPrimaryToSecondary(
                    value,
                    rowItem.govtDeliveryRelationValue
                  );
                  rowItem.govtDeliveryQtyCalculatedValue = balesResult;
                }
                if (rowItem.govtDeliveryQuantityUom2 !== "") {
                  const mtResult = convertPrimaryToSecondary(
                    value,
                    rowItem.govtDeliveryRelationValue2
                  );
                  rowItem.govtDeliveryQtyCalculatedValue2 = mtResult;
                }
                if (rowItem.govtDeliveryQuantityUom3 !== "") {
                  const kgsResult = convertPrimaryToSecondary(
                    value,
                    rowItem.govtDeliveryRelationValue3
                  );
                  rowItem.govtDeliveryQtyCalculatedValue3 = kgsResult;
                }
                if (rowItem.govtDeliveryQuantityUom !== "") {
                  const mtRateResult = convertRatePcsToMt(
                    rowItem.rate,
                    rowItem.govtDeliveryRelationValue2
                  );
                  rowItem.govtDeliveryRateCalculatedValue2 = mtRateResult;
                }
              }
            }
            // Synchronize quantity across all rows
            LineItems.forEach((item) => {
              item.quantity = rowItem.quantity;
            });
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        return rowItem;
      });
      setLineItems(updatedLineItems);
      dispatch(getJuteGovtDeliveryOrderLineItems(updatedLineItems));

      let Qty = SalesGstCalculations(
        {
          lineItems: updatedLineItems,
          taxType: HeaderData.taxType,
        },
        "SALES_REGULAR_FLOW"
      );
      handleCalculation(Qty);
    }
    if (mname[0] === "uom") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.uom = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteGovtDeliveryOrderLineItems(LineItemsRec));
    }
    if (mname[0] === "taxPercent") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.taxPercent = limitDecimals(value, 2);
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteGovtDeliveryOrderLineItems(LineItemsRec));
      let TaxPer = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
        },
        "SALES_REGULAR_FLOW"
      );
      handleCalculation(TaxPer);
    }
    if (mname[0] === "netAmount") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.netAmount = limitDecimals(value, 2);
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteGovtDeliveryOrderLineItems(LineItemsRec));
    }
  };

  const handleSelectChange = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    selectedlable,
    index,
    selectedItemCode,
    selectedId
  ) => {
    if (name === "itemGroupId") {
      let LineItemdata = LineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          row.itemGroupId = selectedItemCode;
          row.itemGroupName = selectedName;
          row.itemGroupValue = selectedValue;
        }
        return row;
      });
      setLineItems(LineItemdata);
      dispatch(getJuteGovtDeliveryOrderLineItems(LineItemdata));
      displayField(selectedRow, "itemGroupId", 2);
      const data = {
        companyId: localStorage.getItem("companyId"),
        consumable: "",
        itemGroupId: selectedValue,
        saleable: "Y",
      };
      dispatch(
        getQuotationItemsByItemGroupId(
          serverApi.QUOTATION_ITEM_BY_ITEM_GROUP,
          data,
          history
        )
      );
    }

    if (name === "itemId") {
      let filteredLineItems = LineItems.filter((item) => item.isActive !== 0);
      if (selectedRow.itemId !== selectedValue) {
        var checkItem = !!filteredLineItems.find(
          ({ itemId }) => Number(itemId) === Number(selectedValue)
        );
      }
      if (checkItem) {
        swal("You can't create the record with the duplicate item");
        let LineItemdata = LineItems.filter((row) => {
          if (row.id === selectedRow.id) {
            row.itemId = "";
            row.itemValue = "";
            row.itemCode = "";
            row.itemName = "";
            row.hsncode = "";
            row.discountedRate = "";
            row.discountAmount = "";
            row.remarks = "";
            row.itemMake = "";
            row.discountType = "";
            row.discountName = "";
            row.discount = "";
            row.totalAmount = "";
            row.netAmount = "";
          }
          return row;
        });
        setLineItems(LineItemdata);
        dispatch(getJuteGovtDeliveryOrderLineItems(LineItemdata));
        displayField(selectedRow, "itemId", 2);
      } else {
        let LineItemdata = LineItems.filter((row) => {
          if (row.id === selectedRow.id) {
            row.itemId = selectedValue;
            row.itemMake = "";
            row.remarks = "";
            row.make = "";
            // row.itemName= selectedName
            // row.itemValue = selectedValue
            // row.itemCode= row.itemGroupValue+selectedItemCode
            let splitedSelectedName = selectedName.split("^");
            row.itemName = splitedSelectedName[0];
            row.itemValue = selectedValue;
            row.itemCode = row.itemGroupValue + selectedItemCode;
            row.hsncode = splitedSelectedName[4];
            row.rate = limitDecimals(splitedSelectedName[3], 3);
            row.discountedRate = limitDecimals(splitedSelectedName[3], 3);
            // row.uom = splitedSelectedName[1];
          }
          return row;
        });
        setLineItems(LineItemdata);
        dispatch(getJuteGovtDeliveryOrderLineItems(LineItemdata));
        displayField(selectedRow, "itemId", 2);
      }
    }
    let DiscType = SalesGstCalculations(
      {
        lineItems: LineItems,
        taxType: HeaderData.taxType,
      },
      "SALES_REGULAR_FLOW"
    );
    handleCalculation(DiscType);
  };
  const handleCalculation = (Calculations) => {
    setLineItems(Calculations.lineItems);
    dispatch(getJuteGovtDeliveryOrderLineItems(Calculations.lineItems));

    var Headerdata = {
      ...HeaderData,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
    };
    setHeaderData((prev) => ({
      ...prev,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
    }));
    dispatch(getJuteGovtDeliveryOrderHeader(Headerdata));
  };

  const addOtherCharges = () => {
    let filterProduct = LineItems.filter((item) => item.isActive !== 0);
    let filteredOtherCharges = filterProduct.filter(
      (item) => item.itemGroupName === "Other Charges"
    );

    if (filteredOtherCharges.length < 3) {
      let filteredproducts = LineItems.map((item) => ({ ...item }));
      let newline = filteredproducts.length;
      let firstLineItem = filterProduct.length > 0 ? filterProduct[0] : null;
      let uomValue =
        firstLineItem && firstLineItem.uom ? firstLineItem.uom : "";
      let obj = {
        id: newline,
        itemGroupId: 1252,
        itemGroupName: "Other Charges",
        itemGroupValue: "997",
        itemId: "",
        itemName: "",
        itemCode: "",
        createdBy: "",
        createdDate: "",
        companyId: "",
        salesId: HeaderData.salesOrderId,
        salesLineItemId: "",
        regularSalesOrderId: "",
        regularDeliveryOrderLineItemId:"",
        govtSalesOrderLineItemId: "",
        hsncode: "",
        itemMake: "",
        quantity: firstLineItem ? firstLineItem.quantity : "0.000",
        rate: "0.000",
        uom: uomValue ? uomValue : "" || null,
        invoiceQty: "",
        igstAmount: "",
        igstPercent: "",
        cgstAmount: "",
        cgstPercent: "",
        sgstAmount: "",
        sgstPercent: "",
        cessAmount: "",
        discountType: "",
        discount: "",
        discountedRate: "",
        discountAmount: "",
        netAmount: "",
        totalAmount: "",
        remarks: "",
        balesPacketSlNo: "",
        status: 1,
        govtDeliveryRateUom: "",
        govtDeliveryRateUom2: "",
        govtDeliveryQuantityUom: "",
        govtDeliveryQuantityUom2: "",
        isActive: 1,
        govtIsActive:1,
        doBalQty: null,
        govtDeliveryBalesPacketSlNo: "",
        taxPercent: firstLineItem ? firstLineItem.taxPercent : "0.00",
        currency: "INR",
      };

      filteredproducts.push(obj);
      setLineItems(filteredproducts);
      dispatch(getJuteGovtDeliveryOrderLineItems(filteredproducts));
    } else {
      swal("Maximum 3 Other Charges rows can be added.");
    }
  };
  const handleEventPositioned = (info, id) => {
    var filteredLineitems = LineItems.filter((item) => item.isActive !== 0);

    filteredLineitems.map((item) => {
      if (item.id == id) {
        if (info.target) {
          info.target.setAttribute(
            "data-tip",
            `
        <p>Balance Quantity = ${
          item.doBalQty === undefined || item.doBalQty === null
            ? 0
            : item.doBalQty
        }</p>
        `
          );
          ReactTooltip.rebuild();
        }
      }
    });
  };
  const disableOtherCharges = (row, col) => {
    if (row.itemGroupValue === "997") {
      if (col.type === "quantity") {
        return true;
      }
    }
  };

  let filterProduct = LineItems.filter((item) => item.isActive !== 0);
  let FirstLineItem = LineItems[(LineItems.length, 0)];
  return (
    <>
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent">
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {tableHeaders.map((column) => (
                    <>
                      {column.header == "Item Group" ||
                      column.header == "Item Name" ||
                      column.header == "Rate" ||
                      column.header == "Quantity" ||
                      column.header == "UOM" ? (
                        <>
                          <th>
                            {column.header}
                            <label style={{ color: "red" }}>*</label>
                          </th>
                        </>
                      ) : (
                        <>
                          <th>{column.header}</th>
                        </>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filterProduct.length !== 0 &&
                  filterProduct.map((row, i) => {
                    let rowIndex = filterProduct.findIndex((idd) => idd == row);

                    return (
                      <>
                        <tr>
                          <>
                            {RowData &&
                              RowData.map((col) =>
                                col.component === "Editablecell" ? (
                                  <>
                                    {col.type === "taxPercent"}

                                    <td>
                                      <div className="quantityDropdownBlock">
                                        <Grid className="selectAndTextfield">
                                          <Grid
                                            xs={12}
                                            md={12}
                                            className="dynamicSelectBlock"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <input
                                              onMouseOver={(e) =>
                                                col.type === "quantity" &&
                                                handleEventPositioned(e, row.id)
                                              }
                                              onWheel={(e) => e.target.blur()}
                                              type={
                                                col.type == "rate"
                                                  ? "number"
                                                  : col.type ===
                                                    "discountedRate"
                                                  ? "number"
                                                  : col.type === "quantity"
                                                  ? "number"
                                                  : col.type === "taxPercent"
                                                  ? "number"
                                                  : col.type == "netAmount"
                                                  ? "number"
                                                  : "text"
                                              }
                                              name={col.type + "_" + rowIndex}
                                              disabled={
                                                col.type === "discountedRate"
                                                  ? true
                                                  : col.type === "rate" &&
                                                    HeaderData.ammendmentNo
                                                  ? false
                                                  : col.type === "rate" &&
                                                    row.itemGroupValue === "997"
                                                  ? false
                                                  : col.type === "rate"
                                                  ? true
                                                  : col.type === "uom"
                                                  ? true
                                                  : col.type === "taxPercent"
                                                  ? true
                                                  : col.type === "netAmount"
                                                  ? true
                                                  : col.type === "hsncode"
                                                  ? true
                                                  : col.type === "discountMode"
                                                  ? true
                                                  : col.type === "discount"
                                                  ? true
                                                  : disableOtherCharges(
                                                      row,
                                                      col
                                                    )
                                              }
                                              value={
                                                col.type === "remarks"
                                                  ? row.remarks
                                                  : col.type === "itemMake"
                                                  ? row.itemMake
                                                  : col.type === "hsncode"
                                                  ? row.hsncode
                                                  : col.type == "rate"
                                                  ? row.rate
                                                  : col.type ===
                                                    "discountedRate"
                                                  ? row.discountedRate
                                                  : col.type === "quantity"
                                                  ? row.quantity
                                                  : col.type === "uom"
                                                  ? row.uom
                                                  : col.type === "taxPercent"
                                                  ? row.taxPercent
                                                  : col.type == "netAmount"
                                                  ? parseFloat(
                                                      row.netAmount
                                                    ).toFixed(2)
                                                  : col.type === "discountMode"
                                                  ? row.discountName
                                                  : col.type === "discount"
                                                  ? row.discount
                                                  : ""
                                              }
                                              onChange={OnhandleChange({
                                                key: col.type + "_" + rowIndex,
                                                row,
                                              })}
                                              className="inputBlockContainer"
                                              style={{
                                                backgroundColor:
                                                  col.type === "discountedRate"
                                                    ? "#ccd6db"
                                                    : isEmpty(
                                                        col.type === "rate" &&
                                                          HeaderData.ammendmentNo
                                                      )
                                                    ? row.itemGroupValue ===
                                                      "997"
                                                      ? ""
                                                      : "#ccd6db"
                                                    : col.type === "uom"
                                                    ? "#ccd6db"
                                                    : col.type === "taxPercent"
                                                    ? "#ccd6db"
                                                    : col.type == "netAmount"
                                                    ? "#ccd6db"
                                                    : col.type ===
                                                      "discountMode"
                                                    ? "#ccd6db"
                                                    : col.type === "hsncode"
                                                    ? "#ccd6db"
                                                    : col.type === "discount"
                                                    ? "#ccd6db"
                                                    : disableOtherCharges(
                                                        row,
                                                        col
                                                      ) === true
                                                    ? "#ccd6db"
                                                    : "",
                                                textAlign:
                                                  col.type !== "remarks" &&
                                                  col.type !== "itemMake" &&
                                                  col.type !== "hsncode" &&
                                                  col.type !== "uom" &&
                                                  col.type !== "discountMode"
                                                    ? "right"
                                                    : "",
                                                paddingRight: "8px",
                                              }}
                                            />
                                            <ReactTooltip
                                              effect={"solid"}
                                              html={true}
                                              place={"BottomCenter"}
                                              // backgroundColor="#ebfaff"
                                              className="tooltipClassBorder"
                                              backgroundColor="rgba(0,127,173,0.5)"
                                              textColor="#FFF"
                                              border={true}
                                              borderColor="#04749c"
                                            />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </td>
                                  </>
                                ) : col.component === "DynamicSelect" ? (
                                  <td>
                                    <div className="quantityDropdownBlock">
                                      <Grid className="selectAndTextfield">
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: LineItemsDisplayTxtField[
                                              col.type + row.id + "1"
                                            ]
                                              ? "none"
                                              : "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            className="inputBlockContainer"
                                            style={{
                                              paddingRight: "8px",
                                              backgroundColor:
                                                col.type === "itemGroupId"
                                                  ? "#ccd6db"
                                                  : col.type === "itemId"
                                                  ? row.itemGroupValue === "997"
                                                    ? ""
                                                    : "#ccd6db"
                                                  : // ? "#ccd6db"
                                                    "",
                                            }}
                                            value={
                                              col.type === "itemGroupId"
                                                ? row.itemGroupName
                                                : col.type === "itemId"
                                                ? row.itemName
                                                : ""
                                            }
                                            onChange={OnhandleChange({
                                              key: col.type + "_" + rowIndex,
                                              row,
                                            })}
                                            onClick={() => {
                                              displayField(row, col.type, 1);
                                            }}
                                            disabled={
                                              col.type === "itemGroupId"
                                                ? true
                                                : col.type === "itemId"
                                                ? row.itemGroupValue === "997"
                                                  ? false
                                                  : true
                                                : false
                                            }
                                          />
                                        </Grid>
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: LineItemsDisplayDropField[
                                              col.type + row.id + "2"
                                            ]
                                              ? "flex"
                                              : "none",
                                            alignItems: "center",
                                          }}
                                        >
                                          <DynamicSelect
                                            arrayOfData={
                                              col.type == "itemGroupId"
                                                ? ItemGroupData
                                                : col.type == "itemId"
                                                ? ItemNameData
                                                : ""
                                            }
                                            className="dropdownBlockContainer"
                                            onSelectChange={handleSelectChange}
                                            selected={
                                              col.type === "itemGroupId"
                                                ? row.itemGroupId
                                                : col.type === "itemId"
                                                ? row.itemId
                                                : ""
                                            }
                                            name={
                                              col.type === "itemGroupId"
                                                ? "itemGroupId"
                                                : col.type === "itemId"
                                                ? "itemId"
                                                : ""
                                            }
                                            row={row}
                                            update={
                                              col.type === "itemGroupId"
                                                ? row.itemGroupId
                                                : col.type === "itemId"
                                                ? row.itemId
                                                : ""
                                            }
                                            isDropdownOpen={isDropdownOpen}
                                            handleMenuOpen={handleMenuOpen}
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </td>
                                ) : (
                                  ""
                                )
                              )}
                          </>
                          <td>
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title={
                                filterProduct.length === 1
                                  ? "You can't delete the last item"
                                  : FirstLineItem === row
                                  ? "You can't delete the first line item"
                                  : ""
                              }
                            >
                              <span>
                                <IconButton
                                  onClick={() => {
                                    onDel(row, i);
                                  }}
                                  disabled={
                                    filterProduct.length === 1
                                      ? true
                                      : FirstLineItem === row
                                      ? true
                                      : false
                                  }
                                >
                                  <DeleteIcon className="deleteDisplayButton" />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div style={{ marginTop: "5px" }}>
            <span
              className="OtherCharges"
              onClick={() => {
                addOtherCharges();
              }}
            >
              Click Here To Add Printing Charges/Labour Charges/Transport
              Charges/Delivery Charges/Packing & Forwarding Charges/Other
              Charges
            </span>
          </div>
          <div className="consoleFormContainer">
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 12">
                      <div
                        className="calculationBlockTable"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <table>
                          <tr>
                            <td>Taxable Value:</td>
                            <b>{parseFloat(HeaderData.netTotal).toFixed(2)}</b>
                          </tr>
                          <tr>
                            <td>Total IGST:</td>
                            <b>
                              {" "}
                              {parseFloat(HeaderData.totalIGST).toFixed(2)}
                            </b>
                          </tr>
                          <tr>
                            <td>Total SGST:</td>
                            <td>
                              {parseFloat(HeaderData.totalSGST).toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td>Total CGST:</td>
                            <td>
                              {parseFloat(HeaderData.totalCGST).toFixed(2)}
                            </td>
                          </tr>

                          <tr>
                            <td>Total Value With Tax:</td>
                            <b>
                              {parseFloat(HeaderData.TotalAmount).toFixed(2)}
                            </b>
                          </tr>
                        </table>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
          {props.locationState.state !== undefined ? (
            <div className="consoleFormContainer">
              <div style={{ height: "15px" }}></div>
              <div className="consoleFormBlock" style={{ display: "flex" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                      className="ducumentUploadBlock uploadedContent"
                    >
                      <Box gridColumn="span 10">
                        {deliveryOrderFiles?.length > 0 &&
                          deliveryOrderFiles.map((item) => (
                            <div
                              key={item.fileUploadId}
                              className="uploadedInfo"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                                padding: "5px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                              }}
                            >
                              <img
                                src={TickIcon}
                                alt=""
                                className="tickIcon"
                                height={"20px"}
                                width={"20px"}
                              />
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title="Click to download File"
                              >
                                <div
                                  className="uplodedFileName"
                                  onClick={() => {
                                    getFileDownload(
                                      serverConfig.SERVER_URL +
                                        "security-api/api/files/downloadfile/" +
                                        item.fileUploadId,
                                      item.fileName,
                                      item.fileExtension
                                    );
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                  }}
                                >
                                  {item.fileName}
                                </div>
                              </Tooltip>
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 100 }}
                                title="Click to Delete File"
                              >
                                <Button
                                  onClick={() => {
                                    onDeleteFile(item.fileUploadId);
                                  }}
                                  className="deleteButton"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <img
                                    src={DeleteIcons}
                                    alt=""
                                    height={"20px"}
                                    width={"20px"}
                                  />
                                </Button>
                              </Tooltip>
                            </div>
                          ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 4">
                        <div style={{ marginBottom: "10px" }}>
                          <b>Support Documents:</b>
                          <span style={{ color: "red" }}></span>
                        </div>
                        <div className="personalDetailsUpload">
                          <div className="ducumentUploadBlock">
                            <div className="documentUploadContent">
                              <span
                                className="btn_upload documentUpload"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={panUploadIcon}
                                  alt=""
                                  style={{ marginRight: "10px" }}
                                />
                                <input
                                  type="file"
                                  id="imag"
                                  title=""
                                  className="input-img"
                                  onChange={handleUpload}
                                />
                                Upload Document
                              </span>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "itemGroupId",
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "itemId",
  },
  {
    no: 3,
    component: "Editablecell",
    type: "remarks",
  },
  {
    no: 3,
    component: "Editablecell",
    type: "itemMake",
  },
  {
    no: 4,
    component: "Editablecell",
    type: "hsncode",
  },
  {
    no: 9,
    component: "Editablecell",
    type: "quantity",
  },
  {
    no: 10,
    component: "Editablecell",
    type: "uom",
  },
  {
    no: 5,
    component: "Editablecell",
    type: "rate",
  },

  {
    no: 11,
    component: "Editablecell",
    type: "taxPercent",
  },
  {
    no: 12,
    component: "Editablecell",
    type: "netAmount",
  },
];
const tableHeaders = [
  {
    header: "Item Group",
  },
  {
    header: "Item Name",
  },
  {
    header: "Remarks",
  },
  {
    header: "Make",
  },
  {
    header: "HSN",
  },
  {
    header: "Quantity",
  },
  {
    header: "UOM",
  },
  {
    header: "Rate",
  },

  {
    header: "Tax%",
  },
  {
    header: "Amount",
  },
  {
    header: "Actions",
  },
];
const discount_Type = [
  {
    value: 1,
    label: "Amount",
    name: "Amount",
  },
  {
    value: 2,
    label: "Percentage",
    name: "Percentage",
  },
];

export default JuteGovtLineItems;
