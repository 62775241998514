import React, { Component } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import CommonPopup from "../CommonPopup/CommonPopup";
import { TextField } from "@mui/material";
import CustomButton from "../../components/Buttons/Buttons";
class InputWithIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account_number: "",
      show: false,
      hide: false,
    };
  }
  componentDidMount() {
    if (this.props.value) {
      this.setState({
        account_number: this.props.value,
      });
    }
  }
  componentWillReceiveProps() {
    this.setState({
      account_number: this.props.value,
    });
  }
  handleChange = (event) => {
    this.setState({
      account_number: event.target.value,
    });
    this.props.handleChange(event.target.value);
  };
  onClick = () => {
    this.setState({
      show: !this.state.show,
    });
  };
  render() {
    return (
      <div
        className={
          this.props.imgUrl ? "consoleAdormentImgBlock" : "consoleAdormentBlock"
        }
      >
        <TextField
          type="tel"
          onChange={this.handleChange}
          value={this.state.account_number}
          defaultValue={this.props.value}
          placeholder={this.props.placeholder}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button onClick={this.onClick}>{this.props.btnLable}</Button>
                <img src={this.props.imgUrl} alt="" />
              </InputAdornment>
            ),
          }}
        />
        {this.state.show && (
          <CommonPopup>
            Kindly please configure the Payment GateWay to proceed with
            verification
            <div className="consoleFormButtonBlock">
              <CustomButton
                label={"Cancel"}
                className="greenBorderButton"
                handleClick={this.onClick}
              />
              <CustomButton
                label={"OK"}
                className="greenButton"
                handleClick={this.onClick}
                type="sumbit"
              />
            </div>
          </CommonPopup>
        )}
      </div>
    );
  }
}
export default InputWithIcon;
