export const PURCHASE_ORDER_LIST = "purchase_order_list";
export const PURCHASE_ORDER_LIST_SUCCESSFULL = "Purchase_order_list_successfull";
export const PO_WO_LINEITEMS = "po_wo_lineitems";
export const INDENT_VIEW_BY_ID = "indent_view_by_id";
export const INDENT_VIEW_BY_ID_SUCCESSFULL = "indent_view_by_id_successfull";
export const PURCHASE_ORDER_HEADER = "purchase_order_header"
export const GET_ITEM_DETAILS_BY_POID = "get_Item_Details_By_PoId";
export const GET_ITEM_DETAILS_BY_POID_SUCCESSFULL = "get_Item_Details_By_PoId_successfull";

export const GET_ITEMLIST_BY_ITEM_GROUP_FOR_PURCHASE = "get_ItemList_by_item_group_for_purchase";
export const GET_ITEM_LIST_BY_ITEM_GROUP_FOR_PURCHASE_SUCCESSFULL = "get_Item_List_by_item_group_for_purchase_successfull";
export const GET_ITEMDETAILS_BYITEM_ITEMGRP_FOR_PURCHASE = "get_Itemdetails_byitem_itemgrp_for_purchase";
export const GET_ITEMDETAILS_BYITEM_ITEMGRP_FOR_PURCHASE_SUCCESSFULL = "get_Itemdetails_byitem_itemgrp_for_purchase_successfull";
export const API_ERRORS = 'api_failed';
