import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextField from "../../../components/TextField/TextField";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import CustomButton from "../../../components/Buttons/Buttons";
import Link from "@mui/material/Link";
import Checkbox from "../../../components/CheckBox/Checkbox";
import AutoCompleteSelect from "../../../components/Dropdown/AutoCompleteSelect";

class CreatePayScheme extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Code: "",
      Name: "",
      type: "",
      parent: "",
      round_to_decimals: "",
      round_of_type: "",
      Default_Value: "",
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  handlePropSelectChange = (selectedValue, selectedName, name) => {};

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq label="Code" required />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq label="Name" required />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={arrayOfIndent}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.type}
                        name="type"
                        label="Type"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>Effective From</label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="Effective_From"
                            inputFormat="dd.MM.yyyy"
                            id="Effective_From"
                            value={this.state.dtvalue}
                            fullWidth
                            onChange={(newValue) => {
                              this.setState({ dtvalue: newValue });
                            }}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>Effective Till</label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="Effective_Till"
                            inputFormat="dd.MM.yyyy"
                            id="Effective_Till"
                            value={this.state.dtvalue}
                            fullWidth
                            onChange={(newValue) => {
                              this.setState({ dtvalue: newValue });
                            }}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={arrayOfIndent}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.parent}
                        name="parent"
                        label="Parent"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 12">
                    <div className="consoleTextFieldBlock">
                      <div className="taxPayableContent">
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Box
                              display="grid"
                              gridTemplateColumns="repeat(12, 1fr)"
                              gap={2}
                            >
                              <Box gridColumn="span 3">
                                <Checkbox label="Is Custom" />
                              </Box>
                              <Box gridColumn="span 3">
                                <Checkbox label="Is Displayable" />
                              </Box>
                              <Box gridColumn="span 3">
                                <Checkbox label="Is Occationally" />
                              </Box>
                              <Box gridColumn="span 3">
                                <Checkbox label="Is Excel Downloadable" />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={arrayOfIndent}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.round_to_decimals}
                        name="round_to_decimals"
                        label="Round To Decimals"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={arrayOfIndent}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.round_of_type}
                        name="round_of_type"
                        label="Round Of Type"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextField label="Default Value" />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextField
                        label="Description"
                        type="textarea"
                        onChange={this.handlePropSelectChange}
                        rows={5}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickBefore}
            />
          </Link>
          <CustomButton
            label={"Create"}
            className="greenButton"
            handleClick={this.onClickNext}
            type="sumbit"
          />
        </div>
      </div>
    );
  }
}

const arrayOfIndent = [
  {
    value: "1",
    label: "Capital",
    name: "Capital",
  },
  {
    value: "2",
    label: "General",
    name: "General",
  },
  {
    value: "3",
    label: "Maintainence",
    name: "Maintainence",
  },
  {
    value: "4",
    label: "Open",
    name: "Open",
  },
  {
    value: "5",
    label: "Production",
    name: "Production",
  },
];
const Project = [
  {
    value: 1,
    label: "Project1",
    name: "Project 1",
  },
  {
    value: 2,
    label: "Project2",
    name: "Project 2",
  },
  {
    value: 3,
    label: "Project3",
    name: "Project 3",
  },
];

const phase = [
  {
    value: "1",
    label: "Ho",
    name: "Ho",
  },
  {
    value: "2",
    label: "Factory",
    name: "Factory",
  },
  {
    value: "3",
    label: "BNG",
    name: "BNG",
  },
  {
    value: "4",
    label: "Kolkatta",
    name: "Kolkatta",
  },
  {
    value: "5",
    label: "Test",
    name: "Test",
  },
];

const client = [
  {
    value: "1",
    title: "Center",
    name: "Center",
  },
  {
    value: "2",
    title: "TechStar",
    name: "TechStar",
  },
  {
    value: "3",
    title: "Mahindra Logistics",
    name: "Mahindra Logistics",
  },
  {
    value: "4",
    title: "Smartworks",
    name: "Smartworks",
  },
];

const CategoryList = [
  {
    value: "1",
    label: "OPEN",
    name: "OPEN",
  },
  {
    value: "2",
    label: "OPEX",
    name: "OPEX",
  },
  {
    value: "3",
    label: "CAPEX",
    name: "CAPEX",
  },
];

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};
export default connect(mapStatetoProps, {})(withRouter(CreatePayScheme));
