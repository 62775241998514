import {
  BRANCH_LIST,
  BRANCH_LIST_SUCCESSFULL,
  DEPARTMENT_LIST,
  DEPARTMENT_LIST_SUCCESSFULL,
  CLIENT_LIST,
  CLIENT_LIST_SUCCESSFULL,
  ITEMGROUP_LIST,
  ITEMGROUP_LIST_SUCCESSFULL,
  PROJECT_LIST,
  PROJECT_LIST_SUCCESSFULL,
  CATEGORY_TYPE_LIST,
  CATEGORY_TYPE_LIST_SUCCESSFULL,
  SUPPLIER_LIST,
  SUPPLIER_LIST_SUCCESSFULL,
  STATUS_LIST,
  STATUS_LIST_SUCCESSFUL,
  PARENT_LEDGER_LIST,
  PARENT_LEDGER_LIST_SUCCESSFULL,
  LEDGER_GROUPS_LIST,
  LEDGER_GROUPS_LIST_SUCCESSFULL,
  STATE_NAME_LIST,
  STATE_NAME_LIST_SUCCESSFULL,
  CUSTOMER_LIST,
  CUSTOMER_LIST_SUCCESSFUL,
  BILLING_SHIPPING_LIST,
  BILLING_SHIPPING_LIST_SUCCESSFUL,
  ITEMGROUP_MASTER_LIST,
  ITEMGROUP_MASTER_LIST_SUCCESSFUL,
  ITEMS_BY_ITEMGROUP_BY_ID,
  ITEMS_BY_ITEMGROUP_BY_ID_SUCCESSFUL,
  ITEMGRPBYBDGT_HEAD_ID,
  ITEMGRPBYBDGT_HEAD_ID_SUCCESSFULL,
  TAX_LIST,
  Tax_List_SUCCESSFULL,
  BUDGET_HEADS_LIST,
  BUDGET_HEADS_LIST_SUCCESSFULL,
  PARENT_BUDGET_HEADS_LIST,
  PARENT_BUDGET_HEADS_LIST_SUCCESSFULL,
  ITEMSLIST_FORINDENT,
  ITEMSLIST_FORINDENT_SUCCESSFULL,
  APPROVED_INDENTS,
  APPROVED_INDENTS_SUCCESSFUL,
  ALLITEMGROUP_MASTERLIST,
  ALLITEMGROUP_MASTERLIST_SUCCESSFULL,
  UOM_LIST,
  UOM_LIST_SUCCESSFULL,
  SUPPLIERLISTBY_SUPP_ID,
  SUPPLIERLISTBY_SUPP_ID_SUCCESSFULL,
  BILLING_ADDRESS_SHIPPING_ADDRESS,
  BILLING_ADS_SHIPPING_ADS_SUCCESSFULL,
  BUDGETHEADS,
  BUDGETHEADS_SUCCESSFULL,
  ITEMGRP_MASTER_LIST,
  ITEMGRP_MASTER_LIST_SUCCESSFULL,
  MUKAM_LIST,
  MUKAM_LIST_SUCCESSFULL,
  EMPLOYEE_CATEGORY_LIST,
  EMPLOYEE_CATEGORY_LIST_SUCCESSFULL,
  SUPPLIER_LIST_MULTISELECT,
  SUPPLIER_LIST_MULTISELECT_SUCCESSFULL,
  BOQ_List,
  BOQList_SUCCESSFULL,
  TYPES_OF_MACHINE,
  TYPES_OF_MACHINE_SUCCESSFULL,
  YARN_TYPE_LIST,
  YARN_TYPE_LIST_SUCCESSFULL,
  SUB_BUDGET_HEADS_LIST,
  SUB_BUDGET_HEADS_LIST_SUCCESSFULL,
  ITEMGROUPBYBUDGET_HEADS_LIST_SUCCESSFULL,
  ITEMGROUPBYBUDGET_HEADS_LIST,
  QUALITY_BY_ITEM,
  QUALITY_BY_ITEM_SUCCESSFULL,
  LAST_MARKET_RATE,
  LAST_MARKET_RATE_SUCCESSFULL,
  SUPPLIER_LIST_MULTISELECT_J,
  SUPPLIER_LIST_MULTISELECT_J_SECCESSFULL,
  PROJECT_MASTER_LIST,
  PROJECT_MASTER_LIST_SUCCESSFULL,
  TERMS_CONDITIONS,
  TERMS_CONDITIONS_SUCCESSFULL,
  COUNTRY_NAME_LIST,
  COUNTRY_NAME_LIST_SUCCESSFULL,
  DASHBOARD_GOOGLE_LINK_API,
  DASHBOARD_GOOGLE_LINK_API_SUCCESSFULL,
  CONFIGURATION_API,
  CONFIGURATION_API_SUCCESSFULL,
  EXPORT_DATA_LIST,
  EXPORT_DATA_LIST_SUCCESSFULL,
  GST_VERIFICATION,
  GST_VERIFICATION_SUCCESSFULL,
} from "./actionTypes";

// BRANCH LIST
export const getBranchList = (url, history) => {
  return {
    type: BRANCH_LIST,
    payload: { url, history },
  };
};

export const BranchListSuccessfull = (data) => {
  return {
    type: BRANCH_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getDepartmentList = (url, history) => {
  return {
    type: DEPARTMENT_LIST,
    payload: { url, history },
  };
};
export const DepartmentListSuccessfull = (data) => {
  return {
    type: DEPARTMENT_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getClientList = (url, history) => {
  return {
    type: CLIENT_LIST,
    payload: { url, history },
  };
};
export const ClientListSuccessfull = (data) => {
  return {
    type: CLIENT_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getItemGroupList = (url, history) => {
  return {
    type: ITEMGROUP_LIST,
    payload: { url, history },
  };
};
export const ItemGroupListSuccessfull = (data) => {
  return {
    type: ITEMGROUP_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getProjectList = (url, data, history) => {
  return {
    type: PROJECT_LIST,
    payload: { url, data, history },
  };
};
export const ProjectListSuccessfull = (data) => {
  return {
    type: PROJECT_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getCateogryTypeList = (url, history) => {
  return {
    type: CATEGORY_TYPE_LIST,
    payload: { url, history },
  };
};
export const CateogryTypeListSuccessfull = (data) => {
  return {
    type: CATEGORY_TYPE_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getSupplierList = (url, history) => {
  return {
    type: SUPPLIER_LIST,
    payload: { url, history },
  };
};
export const SupplierListSuccessfull = (data) => {
  return {
    type: SUPPLIER_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getStatusList = (url, history) => {
  return {
    type: STATUS_LIST,
    payload: { url, history },
  };
};
export const StatusListSuccessfull = (data) => {
  return {
    type: STATUS_LIST_SUCCESSFUL,
    payload: data,
  };
};

export const getCountryNameList = (url, history) => {
  return {
    type: COUNTRY_NAME_LIST,
    payload: { url, history },
  };
};
export const CountryListSuccessfull = (data) => {
  return {
    type: COUNTRY_NAME_LIST_SUCCESSFULL,
    payload: data,
  };
};

// Ledger
export const getParentLedgerList = (url, history) => {
  return {
    type: PARENT_LEDGER_LIST,
    payload: { url, history },
  };
};
export const ParentLedgerListSuccessfull = (data) => {
  return {
    type: PARENT_LEDGER_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getLedgerGroupsList = (url, history) => {
  return {
    type: LEDGER_GROUPS_LIST,
    payload: { url, history },
  };
};
export const LedgerGroupsListSuccessfull = (data) => {
  return {
    type: LEDGER_GROUPS_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getStateNameList = (url, history) => {
  return {
    type: STATE_NAME_LIST,
    payload: { url, history },
  };
};
export const StateNameListSuccessfull = (data) => {
  return {
    type: STATE_NAME_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getCustomerList = (url, data, history) => {
  return {
    type: CUSTOMER_LIST,
    payload: { url, data, history },
  };
};
export const CustomerListSuccessfull = (data) => {
  return {
    type: CUSTOMER_LIST_SUCCESSFUL,
    payload: data,
  };
};

export const getBillingShippingList = (url, history) => {
  return {
    type: BILLING_SHIPPING_LIST,
    payload: { url, history },
  };
};
export const BillingShippingListSuccessfull = (data) => {
  return {
    type: BILLING_SHIPPING_LIST_SUCCESSFUL,
    payload: data,
  };
};

export const getItemGroupMasterList = (url, history) => {
  return {
    type: ITEMGROUP_MASTER_LIST,
    payload: { url, history },
  };
};
export const ItemGroupMasterListSuccessfull = (data) => {
  return {
    type: ITEMGROUP_MASTER_LIST_SUCCESSFUL,
    payload: data,
  };
};
export const getItemGroupByBudegtHeadsList = (url, history) => {
  return {
    type: ITEMGROUPBYBUDGET_HEADS_LIST,
    payload: { url, history },
  };
};
export const ItemGroupByBudegtHeadsListSuccessfull = (data) => {
  return {
    type: ITEMGROUPBYBUDGET_HEADS_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getItemGpListByBdgtHeadId = (url, history) => {
  return {
    type: ITEMGRPBYBDGT_HEAD_ID,
    payload: { url, history },
  };
};
export const ItemGpListByBdgtHeadIdSuccessfull = (data) => {
  return {
    type: ITEMGRPBYBDGT_HEAD_ID_SUCCESSFULL,
    payload: data,
  };
};
export const getItemsList = (url, data, history) => {
  return {
    type: ITEMS_BY_ITEMGROUP_BY_ID,
    payload: { url, data, history },
  };
};
export const ItemsListSuccessfull = (data) => {
  return {
    type: ITEMS_BY_ITEMGROUP_BY_ID_SUCCESSFUL,
    payload: data,
  };
};
export const getTax_List = (url, history) => {
  return {
    type: TAX_LIST,
    payload: { url, history },
  };
};
export const TaxListSuccessfull = (data) => {
  return {
    type: Tax_List_SUCCESSFULL,
    payload: data,
  };
};
export const getBudgetHeadsList = (url, history) => {
  return {
    type: BUDGET_HEADS_LIST,
    payload: { url, history },
  };
};
export const BudgetHeadsListSuccessfull = (data) => {
  return {
    type: BUDGET_HEADS_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getSubBudgetHeadsList = (url, history) => {
  return {
    type: SUB_BUDGET_HEADS_LIST,
    payload: { url, history },
  };
};
export const SubBudgetHeadsListSuccessfull = (data) => {
  return {
    type: SUB_BUDGET_HEADS_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const approvedIndents = (url, data, history) => {
  return {
    type: APPROVED_INDENTS,
    payload: { url, data, history },
  };
};
export const approvedIndentsSuccessfull = (data) => {
  return {
    type: APPROVED_INDENTS_SUCCESSFUL,
    payload: data,
  };
};
export const allItemGroupMasterList = (url, history) => {
  return {
    type: ALLITEMGROUP_MASTERLIST,
    payload: { url, history },
  };
};
export const allItemGroupMasterListSuccessfull = (data) => {
  return {
    type: ALLITEMGROUP_MASTERLIST_SUCCESSFULL,
    payload: data,
  };
};

export const getSupplierListBySuppId = (url, history) => {
  return {
    type: SUPPLIERLISTBY_SUPP_ID,
    payload: { url, history },
  };
};
export const SupplierListBySuppIdSuccessfull = (data) => {
  return {
    type: SUPPLIERLISTBY_SUPP_ID_SUCCESSFULL,
    payload: data,
  };
};
export const getParentBudgetHeads = (url, history) => {
  return {
    type: PARENT_BUDGET_HEADS_LIST,
    payload: { url, history },
  };
};
export const parentBudgetHeadsListSuccessfull = (data) => {
  return {
    type: PARENT_BUDGET_HEADS_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const BillingAdsShippingAds = (url, data, history) => {
  return {
    type: BILLING_ADDRESS_SHIPPING_ADDRESS,
    payload: { url, data, history },
  };
};
export const BillingAdsShippingAdsSuccessfull = (data) => {
  return {
    type: BILLING_ADS_SHIPPING_ADS_SUCCESSFULL,
    payload: data,
  };
};

export const BudgetHeads = (url, history) => {
  return {
    type: BUDGETHEADS,
    payload: { url, history },
  };
};
export const BudgetHeadsSuccessfull = (data) => {
  return {
    type: BUDGETHEADS_SUCCESSFULL,
    payload: data,
  };
};

export const ItemGrpMasterList = (url, data, history) => {
  return {
    type: ITEMGRP_MASTER_LIST,
    payload: { url, data, history },
  };
};
export const ItemGrpMasterListSuccessfull = (data) => {
  return {
    type: ITEMGRP_MASTER_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const BOQList = (url, history) => {
  return {
    type: BOQ_List,
    payload: { url, history },
  };
};
export const BOQListSuccessfull = (data) => {
  return {
    type: BOQList_SUCCESSFULL,
    payload: data,
  };
};
export const getItemsListForIndent = (url, data, history) => {
  return {
    type: ITEMSLIST_FORINDENT,
    payload: { url, data, history },
  };
};
export const ItemsListForIndentSuccessfull = (data) => {
  return {
    type: ITEMSLIST_FORINDENT_SUCCESSFULL,
    payload: data,
  };
};

// UOM Dropdown
export const getUomMasterList = (url, history) => {
  return {
    type: UOM_LIST,
    payload: { url, history },
  };
};
export const UomListSuccessfull = (data) => {
  return {
    type: UOM_LIST_SUCCESSFULL,
    payload: data,
  };
};
//MUKAM lIST
export const getMukamList = (url, history) => {
  return {
    type: MUKAM_LIST,
    payload: { url, history },
  };
};
export const MukamListSuccessfull = (data) => {
  return {
    type: MUKAM_LIST_SUCCESSFULL,
    payload: data,
  };
};
//Employee Catagory lIST
export const getEmployeeCategoryList = (url, history) => {
  return {
    type: EMPLOYEE_CATEGORY_LIST,
    payload: { url, history },
  };
};
export const EmployeeCategoryListSuccessfull = (data) => {
  return {
    type: EMPLOYEE_CATEGORY_LIST_SUCCESSFULL,
    payload: data,
  };
};
// supplierList for multi select
export const getSupplierListMultiselect = (url, history) => {
  return {
    type: SUPPLIER_LIST_MULTISELECT,
    payload: { url, history },
  };
};

export const SupplierListMultiselectSuccessfull = (data) => {
  return {
    type: SUPPLIER_LIST_MULTISELECT_SUCCESSFULL,
    payload: data,
  };
};
export const getSupplierListJ = (url, history) => {
  return {
    type: SUPPLIER_LIST_MULTISELECT_J,
    payload: { url, history },
  };
};

export const getSupplierListJSuccessfull = (data) => {
  return {
    type: SUPPLIER_LIST_MULTISELECT_J_SECCESSFULL,
    payload: data,
  };
};
//Type of machine
export const getTypesOfMachine = (url, history) => {
  return {
    type: TYPES_OF_MACHINE,
    payload: { url, history },
  };
};
export const TypesOfMachineSuccessfull = (data) => {
  return {
    type: TYPES_OF_MACHINE_SUCCESSFULL,
    payload: data,
  };
};
// Yarn Type List IN Master
export const getYarnTypeList = (url, history) => {
  return {
    type: YARN_TYPE_LIST,
    payload: { url, history },
  };
};
export const YarnTypeListSuccessfull = (data) => {
  return {
    type: YARN_TYPE_LIST_SUCCESSFULL,
    payload: data,
  };
};

// Jute Purchase Order Quality By Item
export const getJutePoQualitybyItem = (url, history) => {
  return {
    type: QUALITY_BY_ITEM,
    payload: { url, history },
  };
};
export const getJutePoQualitybyItemSuccessfull = (data) => {
  return {
    type: QUALITY_BY_ITEM_SUCCESSFULL,
    payload: data,
  };
};
export const getLatestMarketJuteRate = (url, history) => {
  return {
    type: LAST_MARKET_RATE,
    payload: { url, history },
  };
};
export const getLatestMarketJuteRateSuccessfull = (data) => {
  return {
    type: LAST_MARKET_RATE_SUCCESSFULL,
    payload: data,
  };
};
export const getProjectMasterList = (url, history) => {
  return {
    type: PROJECT_MASTER_LIST,
    payload: { url, history },
  };
};
export const getProjectMasterListSuccessfull = (data) => {
  return {
    type: PROJECT_MASTER_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getTermsConditions = (url, history) => {
  return {
    type: TERMS_CONDITIONS,
    payload: { url, history },
  };
};
export const getTermsConditionsSuccessfull = (data) => {
  return {
    type: TERMS_CONDITIONS_SUCCESSFULL,
    payload: data,
  };
};
export const getGoogleLinkData = (url, data, history) => {
  return {
    type: DASHBOARD_GOOGLE_LINK_API,
    payload: { url, data, history },
  };
};
export const getGoogleLinkDataSuccessfull = (data) => {
  return {
    type: DASHBOARD_GOOGLE_LINK_API_SUCCESSFULL,
    payload: data,
  };
};

export const getConfiguration = (url, history) => {
  return {
    type: CONFIGURATION_API,
    payload: { url, history },
  };
};
export const getConfigurationSuccessfull = (data) => {
  return {
    type: CONFIGURATION_API_SUCCESSFULL,
    payload: data,
  };
};

export const getExportDataList = (url, data, callback) => {
  return {
    type: EXPORT_DATA_LIST,
    payload: { url, data, callback },
  };
};

export const exportDatalistuccessfull = (data) => {
  return {
    type: EXPORT_DATA_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getGstVerification = (url, history) => {
  return {
    type: GST_VERIFICATION,
    payload: { url, history },
  };
};

export const gstVerificationSuccessful = (data) => {
  return {
    type: GST_VERIFICATION_SUCCESSFULL,
    payload: data,
  };
};
