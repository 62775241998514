import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import DynamicSelectWorking from "../../../components/Dropdown/DynamicSelect";

import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import { getCateogryTypeList } from "../../../store/Global/DropDownApis/actions";
import InputAdornment from "../../../components/InputAdorment/InputAdorment";
import { getProjectList } from "../../../store/Global/DropDownApis/actions";
import { getBranchList } from "../../../store/Global/DropDownApis/actions";
import { getSupplierList } from "../../../store/Global/DropDownApis/actions";
import { getSupplierListBySuppId } from "../../../store/Global/DropDownApis/actions";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import TextFieldMobile from "../../../components/TextField/TextFieldMobile";
import { BillingAdsShippingAds } from "../../../store/Global/DropDownApis/actions";
import { POWOLineItemsProps } from "../../../store/Purchase/PurchaseOrder/actions";
import { BudgetHeads } from "../../../store/Global/DropDownApis/actions";
import { ItemGrpMasterList } from "../../../store/Global/DropDownApis/actions";
import { approvedIndents } from "../../../store/Global/DropDownApis/actions";
import { getClientList } from "../../../store/Global/DropDownApis/actions";
import { IndentViewById } from "../../../store/Purchase/PurchaseOrder/actions";
import moment from "moment";
import swal from "sweetalert";
import { isEmpty } from "../../../Utilities/helper";
import FormPopUpForPurchase from "../../../components/FormlPopup/FormPopUpForPurchase";

class POFillDeatils extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      showByBOM: true,
      showByIndent: true,
      poSourceType: "",
      categoryTypeList: [],
      datevalue: this.props.PoFillDetails.datevalue,
      futureDaysAllowable: "",
      backDaysAllowable: "",
      budgetHeads: this.props.PoFillDetails.budgetHeads,
      budgetHead_value: this.props.PoFillDetails.budgetHead_value,
      indentListItem: this.props.PoFillDetails.indentListItem,
      indentListItemValue: this.props.PoFillDetails.indentListItemValue,
      branch: this.props.PoFillDetails.branch,
      branchValue: this.props.PoFillDetails.branchValue,
      itemGroup: this.props.PoFillDetails.itemGroup,
      itemGroup_Value: this.props.PoFillDetails.itemGroup_Value,
      boqList: this.props.PoFillDetails.boqList,
      boqList_value: this.props.PoFillDetails.boqList_value,
      PO_Source: this.props.PoFillDetails.PO_Source,
      WO_Source_value: this.props.PoFillDetails.WO_Source_value,
      Supplier: this.props.PoFillDetails.Supplier,
      Supplier_value: this.props.PoFillDetails.Supplier_value,
      Supplier_Branch: this.props.PoFillDetails.Supplier_Branch,
      Supplier_Branch_stateCode: "",
      Supplier_Branch_value: this.props.PoFillDetails.Supplier_Branch_value,
      Billing_Address: this.props.PoFillDetails.Billing_Address,
      Billing_Address_value: this.props.PoFillDetails.Billing_Address_value,
      Billing_state_code: this.props.PoFillDetails.Billing_state_code,
      Billing_state_name: this.props.PoFillDetails.Billing_state_name,
      shippingAddress: this.props.PoFillDetails.shippingAddress,
      shippingAddress_Value: this.props.PoFillDetails.shippingAddress_Value,
      shipping_state_code: this.props.PoFillDetails.shipping_state_code,
      shipping_state_name: this.props.PoFillDetails.shipping_state_name,
      tax_payable: this.props.PoFillDetails.tax_payable,
      tax_payable_value: this.props.PoFillDetails.tax_payable_value,
      tax_type: this.props.PoFillDetails.tax_type,
      tax_type_value: this.props.PoFillDetails.tax_type_value,
      Credit_term: this.props.PoFillDetails.Credit_term,
      delivery_timeLine: this.props.PoFillDetails.delivery_timeLine,
      expected_date: moment()
        .add(this.props.PoFillDetails.delivery_timeLine, "days")
        .format("DD-MM-YYYY"),
      // this.props.PoFillDetails.expected_date,
      poTillDate: this.props.PoFillDetails.poTillDate,
      project: this.props.PoFillDetails.project,
      project_value: this.props.PoFillDetails.project_value,
      client: this.props.PoFillDetails.client,
      client_value: this.props.PoFillDetails.client_value,
      category_type: this.props.PoFillDetails.category_type,
      category_type_value: this.props.PoFillDetails.category_type_value,
      RQN_name: this.props.PoFillDetails.RQN_name,
      contact_person: this.props.PoFillDetails.contact_person,
      contact_no: this.props.PoFillDetails.contact_no,
      footerNotes: this.props.PoFillDetails.footerNotes,
      remarks: this.props.PoFillDetails.remarks,
      setIndentProps: this.props.PoFillDetails.setIndentProps,
      projectListData: [],
      supplierListData: [],
      supplierBranchData: [],
      BillingShippingAdsList: [],
      billingList: [],
      shippingList: [],
      budgetHeadsList: [],
      itemGrpMasterList: [],
      branchListData: [],
      BoqList: [],
      ClientList: [],
      currency: this.props.PoFillDetails.currency,
      currencyoptions: [],
      gstValidate: false,
      projectValidate: false,
      RqnValidation: false,
      contactPerValidation: false,
      footerValidation: false,
      internalnoteValidation: false,
      isDropdownOpen: false,
      locationState: "",
      isPopupOpen: false,
    };
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getCateogryTypeList(
      serverApi.GET_ALL_CATEGORY_TYPE,
      this.props.history
    );

    const data = {
      cipher: this.state.userDit.cipher,
      companyId: this.state.companyId,
    };
    this.props.getProjectList(
      serverApi.GET_ALL_PROJECTS,
      data,
      this.props.history
    );
    this.props.getSupplierList(
      serverApi.GET_ALL_SUPPLIERS_LIST + this.state.companyId,
      this.state.history
    );
    this.props.BillingAdsShippingAds(
      serverApi.GET_BILLING_ADDRESS_SHIPPING_ADDRESS,
      data,
      this.state.history
    );
    this.props.BudgetHeads(
      serverApi.GET_BUDGET_HEADES + this.state.companyId,
      this.state.history
    );
    this.props.getClientList(
      serverApi.GET_CLIENT_LIST + this.state.companyId,
      this.props.history
    );
    this.props.getBranchList(
      serverApi.BRANCH_LIST + this.state.companyId,
      this.props.history
    );
    const currentMonth = moment().format('MM');
    const currentDate = moment().format('DD');
    const fiscalYearStart = moment(`${localStorage.getItem("acadamicYear")}-${currentMonth}-${currentDate}`);
    const today = moment();
    let dateValue;
    if (today.isBefore(fiscalYearStart)) {
      dateValue = today.format("YYYY-MM-DD");
    } else {
      dateValue = fiscalYearStart.format("YYYY-MM-DD");
    }
    this.setState({
      datevalue:dateValue
    });
    if (this.state.userDit) {
      this.state.userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              if (item.backDaysAllowable !== undefined) {
                let backDaysAllowable = JSON.parse(item.backDaysAllowable);
                let backDays = backDaysAllowable.bdaystype;
                if (Number(backDays) === 1) {
                  backDays = 7;
                } else if (Number(backDays) === 2) {
                  backDays = 14;
                } else if (Number(backDays) === 3) {
                  backDays = "";
                } else if (Number(backDays) === 4) {
                  backDays = backDaysAllowable.otherBackDateValue;
                } else {
                  backDays = backDaysAllowable.bdaystype;
                }
                let minDate = moment(dateValue).subtract(
                  Number(backDays),
                  "days"
                );
                let setMinDate = moment(minDate).format("YYYY-MM-DD");
                this.setState({
                  backDaysAllowable: setMinDate,
                });
              }
              if (item.futureDaysAllowable !== undefined) {
                let futureDaysAllowable = JSON.parse(item.futureDaysAllowable);
                let futureDays = futureDaysAllowable.fdaystype;
                if (Number(futureDays) === 1) {
                  futureDays = 7;
                } else if (Number(futureDays) === 2) {
                  futureDays = 14;
                } else if (Number(futureDays) === 3) {
                  futureDays = "";
                } else if (Number(futureDays) === 4) {
                  futureDays = futureDaysAllowable.othervalue;
                } else {
                  futureDays = futureDaysAllowable.fdaystype;
                }
                let maxDate = moment(dateValue).add(
                  Number(futureDays),
                  "days"
                );
                let setMaxDate = moment(maxDate).format("YYYY-MM-DD");
                this.setState({
                  futureDaysAllowable: setMaxDate,
                });
              }
              let itemCurrencyTypes = item.currencyTypes;
              let currencyTypes = {};
              let currencyoptions = [];
              if (itemCurrencyTypes !== undefined) {
                currencyTypes = JSON.parse(itemCurrencyTypes);
                if (currencyTypes.inr === 1 && currencyTypes.usd === 0) {
                  this.setState({
                    currency: "INR",
                  });
                  this.props.PoFillDetails.currency = "INR";
                  currencyoptions.push({
                    value: "INR",
                    label: "INR",
                    name: "INR",
                  });
                } else if (currencyTypes.inr === 0 && currencyTypes.usd === 1) {
                  this.setState({
                    currency: "USD",
                  });
                  this.props.PoFillDetails.currency = "USD";
                  currencyoptions.push({
                    value: "USD",
                    label: "USD",
                    name: "USD",
                  });
                } else if (currencyTypes.inr === 1 && currencyTypes.usd === 1) {
                  this.setState({
                    currency: "INR",
                  });
                  this.props.PoFillDetails.currency = "INR";
                  currencyoptions.push({
                    value: "INR",
                    label: "INR",
                    name: "INR",
                  });
                  currencyoptions.push({
                    value: "USD",
                    label: "USD",
                    name: "USD",
                  });
                } else {
                  currencyoptions.push({
                    value: "INR",
                    label: "INR",
                    name: "INR",
                  });
                }
              }
              this.setState({
                currencyoptions: currencyoptions,
              });
              this.props.PoFillDetails.currencyoptions = currencyoptions;
            });
          }
      });
    }
  }
  componentWillReceiveProps(props) {
    if (props.CategoryTypeList) {
      if (props.CategoryTypeList.data) {
        if (props.CategoryTypeList.data.length !== 0) {
          var list = [];
          list = props.CategoryTypeList.data.filter((item) => {
            if (item.value != "2") {
              return item;
            }
          });
          this.setState({
            categoryTypeList: list,
          });
        }
      }
    }
    if (props.projectList) {
      this.setState({
        projectListData: props.projectList.data,
      });
    }
    if (props.branchList) {
      if (props.branchList.data) {
        let list = props.branchList.data.filter((prop) => {
          if (prop.value !== 0) {
            return prop;
          }
        });
        this.setState({
          branchListData: list,
        });
        if (props.branchList !== undefined) {
          this.props.PoFillDetails.branchListData = list;
        }
      }
    }
    if (props.supplierList) {
      if (props.supplierList.data) {
        let newData = props.supplierList.data.filter((prop) => {
          if (prop.value !== "0") {
            return prop;
          }
        });

        this.setState({
          supplierListData: newData,
        });
      }
    }
    if (props.clientList) {
      if (props.clientList.data) {
        if (props.clientList.data.length !== 0) {
          let list = [ {
            value: "",
            name: "Select",
            label: "Select",
          },];
         props.clientList.data.filter((item) => {
            if (item.value != "0") {
              list.push(item)
              // return item;
            }
          });
          this.setState({
            ClientList: list,
          });
        }
      }
    }
    if (this.props.poSourceType) {
      if (this.props.poSourceType === 1) {
        this.setState({
          showByIndent: false,
          poSourceType: "By Indent",
        });
      } else if (this.props.poSourceType === 2) {
        this.setState({
          poSourceType: "Direct Order",
        });
      } else {
        this.setState({
          showByBOM: false,
          poSourceType: "By BOM",
        });
      }
    }
    if (props.supplierListBy_suppId.data) {
      let suppDataList = [];
      let suppBranchList = [];
      let autoId;
      if (props.supplierListBy_suppId.data) {
        suppDataList.push({
          label: props.supplierListBy_suppId.data.suppName,
          name: props.supplierListBy_suppId.data.suppName,
          value: props.supplierListBy_suppId.data.suppId,
        });
        this.props.supplierBranchDetails.gstin =
          props.supplierListBy_suppId.data.gstNo;
        this.props.supplierBranchDetails.Contact_person =
          props.supplierListBy_suppId.data.contactPerson;
        this.props.supplierBranchDetails.Contact_Number =
          props.supplierListBy_suppId.data.cellPhone;
        this.props.supplierBranchDetails.email =
          props.supplierListBy_suppId.data.email;
      }
      if (props.supplierListBy_suppId.data.supLineItems) {
        props.supplierListBy_suppId.data.supLineItems.map((item) => {
          autoId = item.autoId;
          suppBranchList.push({
            label: item.branchAddress,
            name: item.branchAddress,
            value: item.autoId,
            variant: item.stateCode,
          });
          this.setState({
            Supplier_Branch_stateCode: item.stateCode,
          });
        });
      }
      this.setState({
        supplierBranchData: suppBranchList,
      });
      if (this.state.Supplier_value) {
        this.setState({
          Supplier_Branch_value: autoId,
          Supplier_Branch: autoId,
        });
        this.props.PoFillDetails.Supplier_Branch_value = autoId;
      }
    }
    if (props.billing_shipping_adsList) {
      let BillShipAdsList = [];
      if (props.billing_shipping_adsList.data) {
        this.getBillingList(props.billing_shipping_adsList.data);
        this.getShippingList(props.billing_shipping_adsList.data);
        props.billing_shipping_adsList.data.map((item) => {
          // this.props.PoFillDetails.Billing_Address = item.id;
          if (item.isActive !== 0) {
            BillShipAdsList.push({
              label: item.brname + " - " + item.address,
              name: item.brname + " - " + item.address,
              value: item.id,
            });
          }
          if (this.props.PoFillDetails.Billing_Address_value) {
            if (this.props.PoFillDetails.Billing_Address_value === item.id) {
              //for preview
              this.props.poBilledToDetails.gstin = item.gstNo;
              this.props.poBilledToDetails.Contact_person = item.personName;
              this.props.poBilledToDetails.Contact_Number = item.contactNo;
              this.props.poBilledToDetails.email = item.email;
            }
          }
          if (this.props.PoFillDetails.shippingAddress_Value) {
            if (this.props.PoFillDetails.shippingAddress_Value === item.id) {
              //for preview
              this.props.poShippedToDetails.gstin = item.gstNo;
              this.props.poShippedToDetails.Contact_person = item.personName;
              this.props.poShippedToDetails.Contact_Number = item.contactNo;
              this.props.poShippedToDetails.email = item.email;
            }
          }
        });
      }
      this.setState({
        BillingShippingAdsList: BillShipAdsList,
      });
    }

    if (props.budgetHeadsList.data) {
      let budgetHeadsList = props.budgetHeadsList.data.filter((prop) => {
        if (prop.value !== "0" && prop.value !== 0) {
          return prop;
        }
      });
      this.setState({
        budgetHeadsList: budgetHeadsList,
      });
      this.props.PoFillDetails.budgetHeadsList = budgetHeadsList;
    }
    if (this.state.budgetHeads) {
      if (props.itemGrpMasterList) {
        let list = props.itemGrpMasterList.filter((prop) => {
          if (prop.value !== "0" && prop.value !== 0) {
            return prop;
          }
        });
        this.setState({
          itemGrpMasterList: list,
        });
      }
    }
    if (this.state.branch) {
      if (props.itemGrpMasterList) {
        let list = props.itemGrpMasterList.filter((prop) => {
          if (prop.value !== "0" && prop.value !== 0) {
            return prop;
          }
        });
        this.setState({
          itemGrpMasterList: list,
        });
        this.props.PoFillDetails.itemGrpMasterList = list;
      }
    }
    if (props.approvedIndentsList) {
      let approvedIndentsList = props.approvedIndentsList.filter(
        (item) => item.value !== "0"
      );
      this.setState({
        BoqList: approvedIndentsList,
      });
      this.props.PoFillDetails.approvedIndentsList = approvedIndentsList;
    }
    if (
      this.props.source === "WITHBOM" ||
      this.props.source === "WITHINDENT" ||
      this.props.source === "WITHBOQ" ||
      this.props.source === "WITHOPENINDENT"
    ) {
      if (this.state.boqList_value !== "" || this.state.indentListItem !== "") {
        if (props.IndentListById !== undefined) {
          if (props.IndentListById.data !== undefined) {
            if (props.IndentListById.data.indentDetails !== null) {
              let IndentListData = props.IndentListById.data;

              props.PoFillDetails.category_type = IndentListData.categoryName;

              props.PoFillDetails.category_type_value =
                IndentListData.categoryId;

              props.PoFillDetails.client = IndentListData.customerName;

              props.PoFillDetails.client_value = IndentListData.customerId;

              props.PoFillDetails.project = IndentListData.projectName;

              props.PoFillDetails.project_value = IndentListData.projectId;

              props.PoFillDetails.setIndentProps = true;

              this.setState({
                category_type_value: IndentListData.categoryId,

                client_value: IndentListData.customerId,

                project_value: IndentListData.projectId,

                projectValidate: true,

                setIndentProps: true,
              });

              hideSpinner();
            }
          }
        } else {
          this.props.POWOLineItemsProps([]);
        }

        // }
      }
    }

    ///Updating the state
    if (this.props.locationState.state) {
      // this.props.PoFillDetails.datevalue= props.location.state.rowData.poDate
      this.setState({
        datevalue: moment(this.props.locationState.state.rowData.poDate).format(
          "DD-MM-YYYY"
        ),
      });
    }
  }
  handlePropSelectChange = (
    selectedValue,
    selectedName,
    name,
    row,
    variant
  ) => {
    this.setState({
      isDropdownOpen: false,
    });
    if (name === "Supplier_branch") {
      this.props.PoFillDetails.Supplier_Branch = selectedName;
      this.props.PoFillDetails.Supplier_Branch_value = selectedValue;
      this.setState({
        Supplier_Branch: selectedName,
        Supplier_Branch_value: selectedValue,
        Supplier_Branch_stateCode: variant,
      });
    }
    if (name === "Indent_List") {
      this.props.PoFillDetails.indentListItem = selectedName;
      this.props.PoFillDetails.indentListItemValue = selectedValue;
      this.setState({
        indentListItem: selectedName,
        indentListItemValue: selectedValue,
        setIndentProps: false,
      });
      this.props.IndentViewById(
        serverApi.CREATE_INDENT +
          "/" +
          selectedValue +
          "/userid/" +
          this.state.userDit.userId,
        this.props.history,
        this.props.source
      );
      showSpinner();
    }
    if (name === "BOM_List") {
      this.props.PoFillDetails.boqList = selectedName;
      this.props.PoFillDetails.boqList_value = selectedValue;
      this.props.PoFillDetails.indentListItemValue = selectedValue;
      this.setState({
        boqList: selectedName,
        boqList_value: selectedValue,
        setIndentProps: false,
      });
      this.props.IndentViewById(
        serverApi.CREATE_INDENT +
          "/" +
          selectedValue +
          "/userid/" +
          this.state.userDit.userId,
        this.props.history,
        this.props.source
      );
      showSpinner();
    }
    if (name === "BOQ_List") {
      this.props.PoFillDetails.boqList = selectedName;
      this.props.PoFillDetails.boqList_value = selectedValue;
      this.props.PoFillDetails.indentListItemValue = selectedValue;
      this.setState({
        boqList: selectedName,
        boqList_value: selectedValue,
        client_value: "",
        category_type_value: "",
        project_value: "",
      });

      this.props.IndentViewById(
        serverApi.CREATE_INDENT +
          "/" +
          selectedValue +
          "/userid/" +
          this.state.userDit.userId,
        this.props.history,
        this.props.source
      );
      // showSpinner();
    }
    if (name === "Tax_payable") {
      this.setState({
        tax_payable: selectedName,
        tax_payable_value: selectedValue,
      });
      this.props.PoFillDetails.tax_payable = selectedName;
      this.props.PoFillDetails.tax_payable_value = selectedValue;
    }
    if (name === "Tax_type") {
      this.props.PoFillDetails.tax_type = selectedName;
      this.props.PoFillDetails.tax_type_value = selectedValue;
      this.setState({
        tax_type: selectedName,
        tax_type_value: selectedValue,
      });
    }
    if (name === "project") {
      this.props.PoFillDetails.project = selectedName;
      this.props.PoFillDetails.project_value = selectedValue;
      this.setState({
        project: selectedName,
        project_value: selectedValue,
      });
    }
    if (name === "client") {
      this.props.PoFillDetails.client = selectedName;
      this.props.PoFillDetails.client_value = selectedValue;
      this.setState({
        client: selectedName,
        client_value: selectedValue,
      });
    }
    if (name === "category_Type") {
      this.props.PoFillDetails.category_type = selectedName;
      this.props.PoFillDetails.category_type_value = selectedValue;
      this.setState({
        category_type: selectedName,
        category_type_value: selectedValue,
      });
    }
    if (name === "Budget_Head") {
      //this code is to check the item duplication.......
      let checkDept = true;
      if (this.props.PoFillDetails.budgetHead_value === selectedValue) {
        checkDept = false;
      }
      if (checkDept) {
        this.props.PoFillDetails.budgetHeads = selectedName;
        this.props.PoFillDetails.budgetHead_value = selectedValue;
        this.props.PoFillDetails.itemGroup_Value = "";
        this.props.PoFillDetails.boqList_value = "";
        this.setState({
          budgetHeads: selectedName,
          budgetHead_value: selectedValue,
          itemGroup_Value: "",
          boqList_value: "",
          client_value: "",
          category_type_value: "",
          project_value: "",
        });
      }
      const data = {
        branchId: "",
        budgetHeadId: selectedValue,
        cipher: this.state.userDit.cipher,
        companyId: localStorage.getItem("companyId"),
        finshedSaleable: "",
      };
      this.props.ItemGrpMasterList(
        serverApi.GET_PROCUREMENT_ITEMGRP_MASTERLIST,
        data,
        this.state.history
      );
    }
    if (name === "Branch") {
      //this code is to check the item duplication.......
      let checkDept = true;
      if (this.props.PoFillDetails.branchValue === selectedValue) {
        checkDept = false;
      }
      if (checkDept) {
        this.props.PoFillDetails.branch = selectedName;
        this.props.PoFillDetails.branchValue = selectedValue;
        this.setState({
          branch: selectedName,
          branchValue: selectedValue,
          indentListItemValue: "",
          itemGroup_Value: "",
          category_type_value: "",
          project_value: "",
        });
      }
      const data = {
        branchId: selectedValue,
        budgetHeadId: "",
        cipher: this.state.userDit.cipher,
        companyId: localStorage.getItem("companyId"),
        finshedSaleable: "",
      };
      this.props.ItemGrpMasterList(
        serverApi.GET_PROCUREMENT_ITEMGRP_MASTERLIST,
        data,
        this.state.history
      );
    }
    if (name === "Item_group") {
      this.props.PoFillDetails.itemGroup = selectedName;
      this.props.PoFillDetails.itemGroup_Value = selectedValue;
      this.setState({
        itemGroup: selectedName,
        itemGroup_Value: selectedValue,
        indentListItemValue: "",
        boqList_value: "",
        category_type_value: "",
        project_value: "",
      });
      const data = {
        branchId: this.state.branchValue,
        companyId: localStorage.getItem("companyId"),
        financialYear: "2022",
        itemGroup: selectedValue,
        recordType:
          this.props.source === "WITHBOM"
            ? "BOM"
            : this.props.source === "WITHBOQ"
            ? "BOQ"
            : this.props.source === "WITHOPENINDENT"
            ? "OPENINDENT"
            : "INDENT",
      };
      this.props.approvedIndents(
        serverApi.GET_APPROVEDINDENTS,
        data,
        this.props.history
      );
    }

    if (name === "Supplier") {
      this.props.PoFillDetails.Supplier = selectedName;
      this.props.PoFillDetails.Supplier_value = selectedValue;
      this.setState({
        Supplier: selectedName,
        Supplier_value: selectedValue,
      });
      this.props.getSupplierListBySuppId(
        serverApi.GET_SUPPLIER_BY_SUPPLIER_ID +
          selectedValue +
          "/" +
          this.state.userDit.userId,
        this.state.history
      );
    }
    if (name === "Billing_Address") {
      this.props.PoFillDetails.Billing_Address = selectedName;
      this.props.PoFillDetails.Billing_Address_value = selectedValue;
      this.props.billing_shipping_adsList.data.map((item) => {
        if (item.id === selectedValue) {
          this.setState({
            Billing_Address_value: selectedValue,
            Billing_state_code: item.stateCode,
            Billing_state_name: item.stateName,
            shipping_state_code: item.stateCode,
          });
          if (this.state.Supplier_Branch_stateCode === item.stateCode) {
            this.setState({
              tax_type_value: 2,
              gstValidate: true,
            });
            this.props.PoFillDetails.tax_type_value = 2;
          } else {
            this.setState({
              tax_type_value: 1,
              gstValidate: true,
            });
            this.props.PoFillDetails.tax_type_value = 1;
          }

          this.props.PoFillDetails.Billing_state_code = item.stateCode;
          this.props.PoFillDetails.shipping_state_code = item.stateCode;
          this.props.PoFillDetails.Billing_state_name = item.stateName;
          //for preview
          this.props.poBilledToDetails.gstin = item.gstNo;
          this.props.poBilledToDetails.Contact_person = item.personName;
          this.props.poBilledToDetails.Contact_Number = item.contactNo;
          this.props.poBilledToDetails.email = item.email;
        }
      });
    }
    if (name === "Shipping_Address") {
      this.props.PoFillDetails.shippingAddress = selectedName;
      this.props.PoFillDetails.shippingAddress_Value = selectedValue;
      this.props.billing_shipping_adsList.data.map((item) => {
        if (item.id === selectedValue) {
          this.setState({
            shippingAddress_Value: selectedValue,
            shipping_state_code: item.stateCode,
            shipping_state_name: item.stateName,
          });
          this.props.PoFillDetails.shipping_state_code = item.stateCode;
          this.props.PoFillDetails.shipping_state_name = item.stateName;
          //for preview
          this.props.poShippedToDetails.gstin = item.gstNo;
          this.props.poShippedToDetails.Contact_person = item.personName;
          this.props.poShippedToDetails.Contact_Number = item.contactNo;
          this.props.poShippedToDetails.email = item.email;
        }
      });
    }
    if (name === "Currency") {
      this.props.PoFillDetails.currency = selectedValue;
      this.setState({
        currency: selectedValue,
      });
    }
  };
  onhandleChange = (key) => (name, value) => {
    if (value >= 0 && value <= 100) {
      if (key === "deliveryTimeLine") {
        if (/^[0-9]+$/.test(value) || value === "") {
          this.props.PoFillDetails.delivery_timeLine = value;
          var date = moment(this.state.datevalue, "YYYY-MM-DD", true).format(
            "DD-MM-YYYY"
          );
          this.setState({
             expected_date : moment(this.state.datevalue, "YYYY-MM-DD").add(parseInt(value), 'days').format('DD-MM-YYYY')
          });
          this.setState({
            delivery_timeLine: value,
          });
          this.props.PoFillDetails.expected_date = moment()
            .add(value, "days")
            .format("DD-MM-YYYY");
        }
      }
      if (key === "CreditTerm") {
        if (value <= 60) {
          if (value.indexOf(".") === -1) {
            this.props.PoFillDetails.Credit_term = value;
            this.setState({
              Credit_term: value,
            });
          }
        } else {
          swal("Credit Term should not be more than 60");
          this.props.PoFillDetails.Credit_term = 60;
          this.setState({
            Credit_term: 60,
          });
        }
      }
    }
  };

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });

    if (key === "Blg_state_code") {
      this.props.PoFillDetails.Billing_state_code = value;
      this.setState({
        Billing_state_code: value,
      });
    }
    if (key === "Blg_state_name") {
      this.props.PoFillDetails.Billing_state_name = value;
      this.setState({
        Billing_state_name: value,
      });
    }
    if (key === "Shg_state_code") {
      this.props.PoFillDetails.shipping_state_code = value;
      this.setState({
        shipping_state_code: value,
      });
    }
    if (key === "Shg_state_name") {
      this.props.PoFillDetails.shipping_state_name = value;
      this.setState({
        shipping_state_name: value,
      });
    }
    if (key === "footerNotes") {
      /** commenting the regax validation*/

      // let regEx = /^[A-Za-z]+$/;
      // if (regEx.test(value) || value === "") {
      //   this.setState({
      //     footerValidation: false,
      //   });
      // } else {
      //   this.setState({
      //     footerValidation: true,
      //   });
      // }
      // const result = value.replace(/[^a-z]/gi, "");
      this.props.PoFillDetails.footerNotes = value;
      this.setState({
        footerNotes: value,
      });
    }
    if (key === "remarks") {
      /** commenting the regax validation*/

      // let regEx = /^[A-Za-z]+$/;
      // if (regEx.test(value) || value === "") {
      //   this.setState({
      //     internalnoteValidation: false,
      //   });
      // } else {
      //   this.setState({
      //     internalnoteValidation: true,
      //   });
      // }
      // const result = value.replace(/[^a-z]/gi, "");
      this.props.PoFillDetails.remarks = value;
      this.setState({
        remarks: value,
      });
    }
    if (key === "RQN_name") {
      /** commenting the regax validation*/

      // let regEx = /^[A-Za-z]+$/;
      // if (regEx.test(value) || value === "") {
      //   this.setState({
      //     RqnValidation: false,
      //   });
      // } else {
      //   this.setState({
      //     RqnValidation: true,
      //   });
      // }
      // const result = value.replace(/[^a-z]/gi, "");
      this.props.PoFillDetails.RQN_name = value;
      this.setState({
        RQN_name: value,
      });
    }
    if (key === "contact_person") {
      // let regEx = /^[A-Za-z]+$/;
      // if (regEx.test(value) || value === "") {
      //   this.setState({
      //     contactPerValidation: false,
      //   });
      // } else {
      //   this.setState({
      //     contactPerValidation: true,
      //   });
      // }
      // const result = value.replace(/[^a-z]/gi, "");
      this.props.PoFillDetails.contact_person = value;
      this.setState({
        contact_person: value,
      });
    }
    if (key === "contact_no") {
      this.props.PoFillDetails.contact_no = value;
      this.setState({
        contact_no: value,
      });
    }
  };
  handleSelect_Date = (e, name) => {
    if (name === "date") {
      var date = e;
      var date1 = moment(date, "DD-MM-YYYY").format();
      var fromdate = date1.split("T", 1).toString();

      this.props.PoFillDetails.datevalue = fromdate;
      this.setState({
        datevalue: fromdate,
        delivery_timeLine :"",
        expected_date: moment(fromdate, "YYYY-MM-DD").format('DD-MM-YYYY')
        
      });
    }
    if (name === "poTillDate") {
      let date = e;
      let date1 = moment(date, "DD-MM-YYYY").format();
      let fromdate = date1.split("T", 1).toString();

      this.props.PoFillDetails.poTillDate = fromdate;
      this.setState({
        poTillDate: fromdate,
      });
    }
  };

  handleDisableFields = (pathname, field) => {
    if (pathname == "WITHINDENT") {
      if (field === "") {
        return true;
      }
    } else if (pathname == "WITHBOM" || pathname == "WITHBOQ") {
      if (this.state.boqList_value == "") {
        return true;
      }
    } else if (pathname == "WITHOPENINDENT") {
      if (this.state.indentListItemValue == "") {
        return true;
      }
    }
  };
  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  openPopup = () => {
    this.setState({
      isPopupOpen: true,
    });
  };

  closePopup = () => {
    this.setState({
      isPopupOpen: false,
    });
  };

  getBillingList = (List) => {
    let billingList = []
    List.map((item) => {
      // this.props.PoFillDetails.Billing_Address = item.id;
      if (item.isActive !== 0) {
        if (item.addressType === 2) {
          billingList.push({
            label: item.brname + " - " + item.address,
            name: item.brname + " - " + item.address,
            value: item.id,
          });
        }
      }
    });
    this.setState(({
      billingList:billingList
    }))
  };
  getShippingList = (List) => {
    let shippingList = []
    List.map((item) => {
      // this.props.PoFillDetails.Billing_Address = item.id;
      if (item.isActive !== 0) {
          shippingList.push({
            label: item.brname + " - " + item.address,
            name: item.brname + " - " + item.address,
            value: item.id,
          });
      }
    });
    this.setState(({
      shippingList:shippingList
    }))
  };

  render() {
    const {
      datevalue,
      budgetHeads,
      itemGroup,
      itemGroup_Value,
      boqList,
      boqList_value,
      PO_Source,
      poTillDate,
      Supplier_value,
      Supplier_Branch_value,
      Supplier_Branch,
      Billing_Address_value,
      Billing_state_code,
      Billing_state_name,
      shippingAddress_Value,
      shipping_state_code,
      shipping_state_name,
      tax_payable,
      tax_payable_value,
      tax_type_value,
      Credit_term,
      delivery_timeLine,
      expected_date,
      project_value,
      client_value,
      category_type_value,
      RQN_name,
      contact_person,
      contact_no,
      footerNotes,
      remarks,
      currency,
    } = this.state;

    return (
      <div className="consoleFormContainer" onScroll={this.handleScroll}>
        <div style={{ height: "15px" }}></div>
        <h4>Source Type: {this.props.source}</h4>
        {this.props.poSourceType === 1 || this.props.poSourceType === 5 ? (
          <div className="consoleFormBlock">
            {this.props.source === "WITHINDENT" ? (
              <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
                INDENT DETAILS
              </div>
            ) : (
              ""
            )}
            {this.props.source === "WITHOPENINDENT" ? (
              <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
                OPEN INDENT DETAILS
              </div>
            ) : (
              ""
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        handleMenuOpen={this.handleMenuOpen}
                        isDropdownOpen={this.state.isDropdownOpen}
                        selected={this.state.branchValue}
                        update={this.state.branchValue ? 1 : 0}
                        name="Branch"
                        label="Branch"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectWorking
                        arrayOfData={this.state.itemGrpMasterList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        handleMenuOpen={this.handleMenuOpen}
                        isDropdownOpen={this.state.isDropdownOpen}
                        selected={itemGroup_Value}
                        update={itemGroup_Value ? 1 : 0}
                        name="Item_group"
                        label="Item Group"
                        required
                        isDisabled={this.state.branchValue == "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectWorking
                        arrayOfData={this.state.BoqList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        handleMenuOpen={this.handleMenuOpen}
                        isDropdownOpen={this.state.isDropdownOpen}
                        selected={this.state.indentListItemValue}
                        update={this.state.indentListItemValue ? 1 : 0}
                        name="Indent_List"
                        label={
                          this.props.source === "WITHOPENINDENT"
                            ? "Open Indent List"
                            : "Indent List"
                        }
                        required
                        isDisabled={
                          itemGroup_Value === "" || itemGroup_Value === 0
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        ) : (
          ""
        )}
        {this.props.poSourceType === 3 && (
          <div>
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock">
              {this.props.source === "WITHBOM" ? (
                <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
                  BOM DETAILS
                </div>
              ) : (
                ""
              )}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelectWorking
                          arrayOfData={this.state.budgetHeadsList}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          handleMenuOpen={this.handleMenuOpen}
                          isDropdownOpen={this.state.isDropdownOpen}
                          selected={this.state.budgetHead_value}
                          update={this.state.budgetHead_value ? 1 : 0}
                          name="Budget_Head"
                          label="Budget Head"
                          required
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelectWorking
                          arrayOfData={this.state.itemGrpMasterList}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          handleMenuOpen={this.handleMenuOpen}
                          isDropdownOpen={this.state.isDropdownOpen}
                          selected={itemGroup_Value}
                          update={itemGroup_Value ? 1 : 0}
                          name="Item_group"
                          label="Item Group"
                          required
                          isDisabled={
                            this.state.budgetHead_value === "" ||
                            this.state.budgetHead_value === "0"
                              ? true
                              : false
                          }
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelectWorking
                          arrayOfData={this.state.BoqList}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          handleMenuOpen={this.handleMenuOpen}
                          isDropdownOpen={this.state.isDropdownOpen}
                          selected={this.state.boqList_value}
                          update={this.state.boqList_value ? 1 : 0}
                          name="BOM_List"
                          label="BOM List"
                          required
                          isDisabled={itemGroup_Value === "" ? true : false}
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
        {this.props.poSourceType === 4 && (
          <div>
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock">
              {this.props.source === "WITHBOQ" ? (
                <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
                  BOQ DETAILS
                </div>
              ) : (
                ""
              )}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelectWorking
                          arrayOfData={this.state.budgetHeadsList}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          handleMenuOpen={this.handleMenuOpen}
                          isDropdownOpen={this.state.isDropdownOpen}
                          selected={this.state.budgetHead_value}
                          update={this.state.budgetHead_value ? 1 : 0}
                          name="Budget_Head"
                          label="Budget Head"
                          required
                        />
                      </div>
                    </Box>

                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelectWorking
                          arrayOfData={this.state.itemGrpMasterList}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          handleMenuOpen={this.handleMenuOpen}
                          isDropdownOpen={this.state.isDropdownOpen}
                          selected={itemGroup_Value}
                          update={itemGroup_Value ? 1 : 0}
                          name="Item_group"
                          label="Item Group"
                          required
                          isDisabled={
                            this.state.budgetHead_value == "" ||
                            this.state.budgetHead_value == "0"
                              ? true
                              : false
                          }
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelectWorking
                          arrayOfData={this.state.BoqList}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          handleMenuOpen={this.handleMenuOpen}
                          isDropdownOpen={this.state.isDropdownOpen}
                          selected={this.state.boqList_value}
                          update={this.state.boqList_value ? 1 : 0}
                          name="BOQ_List"
                          label="BOQ List"
                          required
                          isDisabled={itemGroup_Value == "" ? true : false}
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
        {/* create by source  ends */}
        <div style={{ height: "15px" }}></div>
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          minDate={
                            this.state.backDaysAllowable !== "" &&
                            this.state.backDaysAllowable !== undefined
                              ? this.state.backDaysAllowable
                              : datevalue
                          }
                          maxDate={
                            this.state.futureDaysAllowable !== "" &&
                            this.state.futureDaysAllowable !== undefined
                              ? this.state.futureDaysAllowable
                              : datevalue
                          }
                          value={datevalue}
                          fullWidth
                          onChange={(e) => this.handleSelect_Date(e, "date")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.supplierListData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      handleMenuOpen={this.handleMenuOpen}
                      isDropdownOpen={this.state.isDropdownOpen}
                      selected={Supplier_value}
                      update={Supplier_value ? 1 : 0}
                      name="Supplier"
                      label="Supplier"
                      required
                      isDisabled={this.handleDisableFields(
                        this.props.source,
                        this.state.indentListItemValue
                      )}
                      // isDisabled={this.props.source=='WITHBOQ'?this.state.boqList_value==''?true:false:""}
                    />
                  </div>
                </Box>

                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.supplierBranchData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      handleMenuOpen={this.handleMenuOpen}
                      isDropdownOpen={this.state.isDropdownOpen}
                      selected={Supplier_Branch}
                      update={Supplier_Branch ? 1 : 0}
                      name="Supplier_branch"
                      label="Supplier Branch"
                      required
                      isDisabled={
                        this.state.Supplier_value === "" ||
                        this.state.Supplier_value == "0"
                          ? true
                          : false
                      }
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.billingList}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      handleMenuOpen={this.handleMenuOpen}
                      isDropdownOpen={this.state.isDropdownOpen}
                      selected={Billing_Address_value}
                      update={Billing_Address_value ? 1 : 0}
                      name="Billing_Address"
                      label="Billing Address"
                      required
                      // isDisabled={
                      //   this.props.location.state === undefined || this.state.Supplier_value === "" ||
                      //   this.state.Supplier_value === "0"? true : this.state.locationState !== undefined || (this.state.Supplier_value !== "" ||
                      //   this.state.Supplier_value !== "0") ? true:false
                      isDisabled={
                        (this.props.locationState.state === undefined &&
                          (this.state.Supplier_value === "" ||
                            this.state.Supplier_value === "0")) ||
                        (this.props.locationState.state !== undefined &&
                          this.state.Supplier_value !== "" &&
                          this.state.Supplier_value !== "0")
                          ? true
                          : false
                      }
                    />
                  </div>
                </Box>
                {/* As of now we are hiding the fields */}
                {/* <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      placeholder="Enter here"
                      label="Billing State Code"
                      value={Billing_state_code}
                      onChange={this.handleChange("Blg_state_code")}
                      disabled={Billing_Address_value == "" ? true : false}
                    />
                  </div>
                </Box> */}
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      placeholder="Enter here"
                      label="Billing State Name"
                      value={Billing_state_name}
                      onChange={this.handleChange("Blg_state_name")}
                      disabled={Billing_Address_value === "" ? true : false}
                      readOnly={true}
                    />
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>

        <div style={{ height: "15px" }}></div>
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.shippingList}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      handleMenuOpen={this.handleMenuOpen}
                      isDropdownOpen={this.state.isDropdownOpen}
                      selected={shippingAddress_Value}
                      update={shippingAddress_Value ? 1 : 0}
                      name="Shipping_Address"
                      label="Shipping Address"
                      required
                      // isDisabled={this.state.locationState === undefined ? true : Billing_Address_value == "" ? true : false}
                      isDisabled={
                        this.props.locationState.state === undefined &&
                        Billing_Address_value === ""
                          ? true
                          : this.props.locationState.state !== undefined &&
                            Billing_Address_value !== ""
                          ? true
                          : false
                      }
                    />
                  </div>

                  {/* commenting this lines of code because of discussion is pending regarding this */}
                  {/* {!isEmpty(Billing_Address_value) && (
                    <span
                      onClick={() => {
                        this.openPopup();
                      }}
                      style={{
                        color: "red",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 100,
                        fontSize: "15px",
                        marginTop: "5px",
                        cursor: "pointer",
                        padding: "5px",
                      }}
                    >
                      Add New Address
                    </span>
                  )} */}
                </Box>
                {/* As of now we are hiding the fields */}

                {/* <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    
                    <TextFieldNormal
                      label="Shipping State Code"
                      placeholder="Enter here"
                      value={shipping_state_code}
                      onChange={this.handleChange("Shg_state_code")}
                      disabled={Billing_Address_value == "" ? true : false}
                    />
                  </div>
                </Box> */}
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Shipping State Name"
                      placeholder="Enter here"
                      value={shipping_state_name}
                      onChange={this.handleChange("Shg_state_name")}
                      disabled={shippingAddress_Value == "" ? true : false}
                      readOnly={true}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={TaxPayable}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      handleMenuOpen={this.handleMenuOpen}
                      isDropdownOpen={this.state.isDropdownOpen}
                      selected={tax_payable_value}
                      update={tax_payable_value ? 1 : 0}
                      name="Tax_payable"
                      label="Tax Payable"
                      required
                      isDisabled={shippingAddress_Value == "" ? true : false}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    {/* {this.state.Supplier_Branch_stateCode} */}
                    <DynamicSelect
                      arrayOfData={TaxType}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      handleMenuOpen={this.handleMenuOpen}
                      isDropdownOpen={this.state.isDropdownOpen}
                      selected={tax_type_value}
                      update={tax_type_value ? 1 : 0}
                      name="Tax_type"
                      label="Tax type"
                      isDisabled={
                        this.props.locationState.state !== undefined ||
                        this.props.source === "WITHOUTINDENT"
                          ? true
                          : this.props.source === "WITHOUTINDENT"
                          ? Billing_Address_value == ""
                            ? true
                            : this.state.gstValidate === true
                            ? true
                            : false
                          : true
                      }
                    />
                  </div>
                </Box>
                {this.props.pathname === "PO" && (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>Credit Term</label>
                      </div>
                      <InputAdornment
                        label={"Days"}
                        // type={"number"}
                        value={Credit_term}
                        handleChange={this.onhandleChange("CreditTerm")}
                        // noDecimal={true}
                      />
                    </div>
                  </Box>
                )}
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div className="TextFieldBlock">
                      <label>
                        Delivery TimeLine <span className="starcolor">*</span>
                      </label>
                    </div>
                    {/* <div style={{ background: "red" }}> */}
                    <InputAdornment
                      label={"Days"}
                      value={delivery_timeLine}
                      handleChange={this.onhandleChange("deliveryTimeLine")}
                      disabled={shippingAddress_Value == "" ? true : false}
                    />
                    {/* </div> */}
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      readOnly={true}
                      label="Expected Date"
                      value={expected_date}
                      disabled={
                        this.props.source === "WITHOUTINDENT"
                          ? delivery_timeLine == ""
                            ? true
                            : false
                          : true
                      }
                      // disabled={delivery_timeLine == "" ? true : false}
                      // onChange={this.handleChange("expected_date")}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>PO Till Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          value={poTillDate}
                          fullWidth
                          onChange={(e) =>
                            this.handleSelect_Date(e, "poTillDate")
                          }
                          disabled={
                            this.props.source !== "WITHOPENINDENT" ||
                            this.props.source !== "WITHINDENT" ||
                            this.props.source !== "WITHBOM"
                              ? true
                              : delivery_timeLine === ""
                              ? true
                              : false
                          }
                          minDate={poTillDate}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                              style={{
                                backgroundColor:
                                  delivery_timeLine == ""
                                    ? "#ccd6db"
                                    : "#ffffff",
                              }}
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                style={{
                                  backgroundColor:
                                    delivery_timeLine === ""
                                      ? "#ccd6db"
                                      : "#ffffff",
                                }}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.categoryTypeList}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      handleMenuOpen={this.handleMenuOpen}
                      isDropdownOpen={this.state.isDropdownOpen}
                      selected={category_type_value}
                      update={category_type_value ? 1 : 0}
                      name="category_Type"
                      label="Category Type"
                      required
                      // isDisabled={delivery_timeLine == "" ? true : false}
                      isDisabled={
                        this.props.source === "WITHOUTINDENT" ||
                        this.props.source === "WITHOUTBOQ" ||
                        this.props.source === "WITHOPENINDENT"
                          ? delivery_timeLine == ""
                            ? true
                            : false
                          : true
                      }
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={
                        this.state.projectListData &&
                        this.state.projectListData.map((item) => ({
                          label: item.name,
                          value: item.projectId,
                          name: item.name,
                        }))
                      }
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      handleMenuOpen={this.handleMenuOpen}
                      isDropdownOpen={this.state.isDropdownOpen}
                      selected={project_value}
                      update={project_value ? 1 : 0}
                      name="project"
                      label="Project"
                      required
                      isDisabled={
                        this.props.source === "WITHOUTINDENT" ||
                        this.props.source === "WITHOUTBOQ"
                          ? category_type_value == ""
                            ? true
                            : this.state.projectValidate
                            ? true
                            : false
                          : true
                      }
                    />
                  </div>
                </Box>
                {this.state.currencyoptions.length !== 0 && (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={
                          this.state.currencyoptions.length !== 0 &&
                          this.state.currencyoptions
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        handleMenuOpen={this.handleMenuOpen}
                        isDropdownOpen={this.state.isDropdownOpen}
                        selected={currency}
                        update={currency ? 1 : 0}
                        name="Currency"
                        label="Currency"
                        required
                      />
                    </div>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </div>

        <div style={{ height: "15px" }}></div>
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.ClientList}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      handleMenuOpen={this.handleMenuOpen}
                      isDropdownOpen={this.state.isDropdownOpen}
                      selected={client_value}
                      update={client_value ? 1 : 0}
                      name="client"
                      label="Client"
                      isDisabled={project_value == "" ? true : false}
                    />
                  </div>
                </Box>

                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="RQN Name"
                      value={RQN_name}
                      onChange={this.handleChange("RQN_name")}
                      disabled={project_value == "" ? true : false}
                    />
                    {this.state.RqnValidation && (
                      <div className="TextFieldBlock">
                        <span className="text-danger">
                          Please Enter Valid RQN Name
                        </span>
                      </div>
                    )}
                  </div>
                </Box>

                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Contact Person"
                      value={contact_person}
                      onChange={this.handleChange("contact_person")}
                      disabled={project_value == "" ? true : false}
                    />
                    {this.state.contactPerValidation && (
                      <div className="TextFieldBlock">
                        <span className="text-danger">
                          Please Enter Valid Contact Person
                        </span>
                      </div>
                    )}
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldMobile
                      label="Contact No"
                      type={"number"}
                      value={contact_no}
                      onChange={this.handleChange("contact_no")}
                      disabled={project_value === "" ? true : false}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      type="textarea"
                      label="Footer Note"
                      value={footerNotes}
                      onChange={this.handleChange("footerNotes")}
                      disabled={project_value == "" ? true : false}
                    />
                    {this.state.footerValidation && (
                      <div className="TextFieldBlock">
                        <span className="text-danger">
                          Please Enter Valid footer notes
                        </span>
                      </div>
                    )}
                  </div>
                </Box>

                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      type="textarea"
                      label="Internal Note"
                      value={remarks}
                      onChange={this.handleChange("remarks")}
                      disabled={project_value == "" ? true : false}
                    />
                    {this.state.internalnoteValidation && (
                      <div className="TextFieldBlock">
                        <span className="text-danger">
                          Please Enter Valid internal note
                        </span>
                      </div>
                    )}
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
        <FormPopUpForPurchase
          isOpen={this.state.isPopupOpen}
          onClose={this.closePopup}
        />
      </div>
    );
  }
}

const TaxPayable = [
  {
    value: 1,
    label: "Yes",
    name: "Yes",
  },
  {
    value: 2,
    label: "No",
    name: "No",
  },
];
const TaxType = [
  {
    value: 1,
    label: "IGST",
    name: "IGST",
  },
  {
    value: 2,
    label: "CGST & SGST",
    name: "CGST & SGST",
  },
];

const mapStatetoProps = (state) => {
  const {
    CategoryTypeList,
    projectList,
    supplierList,
    supplierListBy_suppId,
    billing_shipping_adsList,
    budgetHeadsList,
    clientList,
    itemGrpMasterList,
    approvedIndentsList,
    boqListResp,
    branchList,
  } = state.DropDownListReducer;
  const { IndentListById } = state.PurchaseOrderReducer;
  return {
    CategoryTypeList,
    projectList,
    supplierList,
    supplierListBy_suppId,
    billing_shipping_adsList,
    budgetHeadsList,
    itemGrpMasterList,
    approvedIndentsList,
    clientList,
    boqListResp,
    branchList,
    IndentListById,
  };
};
export default connect(mapStatetoProps, {
  getBranchList,
  getCateogryTypeList,
  getProjectList,
  getSupplierList,
  getSupplierListBySuppId,
  BillingAdsShippingAds,
  BudgetHeads,
  ItemGrpMasterList,
  approvedIndents,
  getClientList,
  POWOLineItemsProps,
  IndentViewById,
})(withRouter(POFillDeatils));
