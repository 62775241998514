
import {
    DEPARTMENT_MASTER_LIST, DEPARTMENT_MASTER_LIST_SUCCESSFULL,
    CREATE_DEPARTMENT, CREATE_DEPARTMENT_SUCCESSFULL,
    API_ERRORS,
    UPDATE_DEPARTMENT,
    UPDATE_DEPARTMENT_SUCCESSFULL
} from './actionTypes';

export const departmentMasterList = (url, data, history) => {
    return {
        type: DEPARTMENT_MASTER_LIST,
        payload: { url, data, history }
    }
}
export const departmentMasterListSuccessfull = (data) => {
    return {
        type: DEPARTMENT_MASTER_LIST_SUCCESSFULL,
        payload: data
    }
}
export const createDepartment = (url, data, history) => {
    return {
        type: CREATE_DEPARTMENT,
        payload: { url, data, history }
    }
}

export const createDepartmentSuccessfull = (data) => {
    return {
        type: CREATE_DEPARTMENT_SUCCESSFULL,
        payload: data
    }
}
export const updateDepartment = (url, data, history) => {
    return {
        type: UPDATE_DEPARTMENT,
        payload: { url, data, history }
    }
}

export const updateDepartmentSuccessfull = (data) => {
    return {
        type: UPDATE_DEPARTMENT_SUCCESSFULL,
        payload: data
    }
}
export const apiErrors = (error) => {
    return {
        type: API_ERRORS,
        payload: error,
    };
};