import {BANK_ACCOUNT_LIST,BANK_ACCOUNT_LIST_SUCCESSFULL,
    CREATE_BANK_ACCOUNT,CREATE_BANK_ACCOUNT_SUCCESSFULL,
    UPDATE_BANK_ACCOUNT,UPDATE_BANK_ACCOUNT_SUCCESSFULL
    ,API_ERRORS} from './actionTypes';



export const getBankAccountList = (url,data,history) =>{
    return {
        type:BANK_ACCOUNT_LIST,
        payload:{url,data,history}
    }
}
export const getbankAccountList=(url,data,history) =>{
    return{
        type:BANK_ACCOUNT_LIST,
        payload:{url,data,history}
    }
}
export const bankAccountListSuccessfull=(data) =>{
    return{
        type:BANK_ACCOUNT_LIST_SUCCESSFULL,
        payload:data,
    }
    
}
export const BankAccountListSuccessfull = (data)=>{
    return {
        type:BANK_ACCOUNT_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createBankAccount = (url, data, history) => {
    return {
        type: CREATE_BANK_ACCOUNT,
        payload: { url, data, history }
    }
}
export const createBankAccountSuccessfull = (data) => {
    return {
        type: CREATE_BANK_ACCOUNT_SUCCESSFULL,
        payload: data
    }
}
export const updateBankAccount = (url, data, history) => {
    return {
        type: UPDATE_BANK_ACCOUNT,
        payload: { url, data, history }
    }
}
export const updateBankAccountSuccessfull = (data) => {
    return {
        type: UPDATE_BANK_ACCOUNT_SUCCESSFULL,
        payload: data
    }
}
export const apiErrors = (error) => {
    return {
      type: API_ERRORS,
      payload: error,
    };
  };

