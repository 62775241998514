import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { REQUESTOTP, SUBMIT_GST_CREDENTIALS_OTP, UPDATECLIENTCREDENTIALS } from "./actionTypes";
import {
  RequestOtp_successfull,
  SubmitGSTOtp_successfull,
  UpdateClientCredentials_successfull,
} from "./actions";
import { postAdd } from "../../../helpers/Server_Helper";
import swal from "sweetalert";

function* UpdateClient_Credentials({ payload: { url, data, history, callback } }) {
  
  try {
    const response = yield call(postAdd, url, data);
    yield put(UpdateClientCredentials_successfull({ response }));
    if (response) {
      if (response.data) {
        if (response.data.status ) {
          swal(response.data.message, { icon: "success" });
        } else {
          callback(response.data); 
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function* RequestOtpMethod({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(RequestOtp_successfull({ response }));
    if (response) {
      console.log(response.data);
      swal(response.data.message);
    } else {
      swal(response.data.message);
    }
  } catch (error) {
    console.log(error);
  }
}
function* SubmitGstOtpMethod({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(SubmitGSTOtp_successfull({ response }));
    if (response) {
      swal(response.data.message);
    } else {
      swal(response.data.message);
    }
  } catch (error) {
    console.log(error);
  }
}
export function* watchGst() {
  yield takeEvery(UPDATECLIENTCREDENTIALS, UpdateClient_Credentials);
  yield takeEvery(REQUESTOTP, RequestOtpMethod);
  yield takeEvery(SUBMIT_GST_CREDENTIALS_OTP,SubmitGstOtpMethod);
}

function* GstSaga() {
  yield all([fork(watchGst)]);
}

export default GstSaga;
