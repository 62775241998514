import { JUTE_CRAETE_MARKET_RATE_SUCCESSFULL,
      JUTE_CREATE_MARKET_RATE, 
      JUTE_MARKET_RATE, 
      JUTE_MARKET_RATE_SUCCESSFULL} from "./actionTypes";

export const JuteMarketRateData = (url, data, history) => {
  return {
    type: JUTE_MARKET_RATE,
    payload: { url, data, history },
  };
};
export const JuteMarketRateSuccessfull = (data) => {
  return {
    type: JUTE_MARKET_RATE_SUCCESSFULL,
    payload: data,
  };
};
export const JuteCreateMarketRate=(url,data,history)=>{
  return {
    type: JUTE_CREATE_MARKET_RATE,
    payload:{url,data,history}
  }
}

export const JuteCreateMarketRateSuccessfull=(data)=>{
  return {
    type: JUTE_CRAETE_MARKET_RATE_SUCCESSFULL,
    payload: data,
  }
}
