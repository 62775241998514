import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import moment from "moment";

import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/PaginationGridView";
import { getBillsList } from "../../../store/Accounting/Bills/actions";

const AccountingBills = () => {
  let dispatch = useDispatch();
  let history = useHistory();

  const [BillsListData, setBillsListData] = useState([]);
  const [totalRecs, setTotalRecs] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(10);
  const [status, setStatus] = useState("");
  const [dataexp, setDataExp] = useState([]);
  const [fromDate, setfromDate] = useState( moment().day(-7).format("DD-MM-YYYY"));
  const [toDate, settoDate] = useState(moment().format("DD-MM-YYYY"));

  const billsListPayload = {
    acYear: localStorage.getItem("acadamicYear"),
    companyId: localStorage.getItem("companyId"),
    fromDate: fromDate,
    status: "",
    toDate: toDate,
    startIndex: startIndex,
    lastIndex: lastIndex,
  };

  useEffect(() => {
    const financialYearStartMonth = 3; // Assuming financial year starts in April
    let financialYearStart = moment()
      .year(localStorage.getItem("acadamicYear"))
      .month(financialYearStartMonth)
      .startOf("month");

    const fromDate = financialYearStart.day(-7).format("YYYY-MM-DD");
    const toDate = moment().format("YYYY-MM-DD");

    var startDate = moment(fromDate, "YYYY-MM-DD", true).format("YYYY-MM-DD");
    var endDate = moment(toDate, "YYYY-MM-DD", true).format("YYYY-MM-DD");
  
    const expdata = {
      ...billsListPayload,
      startIndex: 0,
      lastIndex: 10,
      fromDate: startDate,
      toDate: endDate,
    };
    setDataExp(expdata,);
    setfromDate(startDate)
    settoDate(endDate)
    dispatch(getBillsList(serverApi.BILLS, expdata, history));
  }, [getBillsList]);


  const { BillsList } = useSelector((state) => state.BillsReducer);

  useEffect(() => {
    if (BillsList) {
      setBillsListData(BillsList.data);
      setTotalRecs(BillsList.totalRecords);
    }
  }, [BillsList]);

  const Columns = [
    { Header: "Vch No", accessor: "vchNo.", hidden: false },
    { Header: "Vch Type", accessor: "vchType", hidden: false },
    { Header: "Mr Date", accessor: "mrDate", hidden: false },
    { Header: "Supplier Inv No", accessor: "supplierInvNo", hidden: false },
    { Header: "Supplier Inv Date", accessor: "supplierInvDate", hidden: false },
    { Header: "Receipt Note No", accessor: "receiptNoteNo", hidden: false },
    { Header: "Receipt Note Date", accessor: "receiptNoteDate", hidden: false },
    { Header: "Order Number", accessor: "orderNumber", hidden: false },
    { Header: "Order Date", accessor: "orderDate", hidden: false },
    { Header: "Party Name", accessor: "partyName", hidden: false },
    { Header: "Registration Type", accessor: "registrationType", hidden: false },
    { Header: "Gstin No", accessor: "gstinNo", hidden: false },
    { Header: "Country", accessor: "country", hidden: false },
    { Header: "State", accessor: "state", hidden: false },
    { Header: "Pin Code", accessor: "pinCode", hidden: false },
    { Header: "Address 1", accessor: "address1", hidden: false },
    { Header: "Address 2", accessor: "address2", hidden: false },
    { Header: "Address 3", accessor: "address3", hidden: false },
    { Header: "Purchase Ledger", accessor: "purchaseLedger", hidden: false },
    { Header: "Purchase Ledger Description", accessor: "purchaseLedgerDescription", hidden: false },
    { Header: "Item Name", accessor: "itemName", hidden: false },
    { Header: "Item Description", accessor: "itemDescription", hidden: false },
    { Header: "Units", accessor: "units", hidden: false },
    { Header: "Tracking No", accessor: "trackingNo", hidden: false },
    { Header: "Order No", accessor: "orderNo", hidden: false },
    { Header: "Order Due Date", accessor: "orderDueDate", hidden: false },
    { Header: "Godown", accessor: "godown", hidden: false },
    { Header: "Batch", accessor: "batch", hidden: false },
    { Header: "Qty", accessor: "qty", hidden: false },
    { Header: "Rate", accessor: "rate", hidden: false },
    { Header: "Amt", accessor: "amt", hidden: false },
    { Header: "Amount1", accessor: "amount1", hidden: false },
    { Header: "Discount Ledger", accessor: "discountLedger", hidden: false },
    { Header: "Discount Ledger Description", accessor: "discountLedgerDescription", hidden: false },
    { Header: "Amount2", accessor: "amount2", hidden: false },
    { Header: "Additional Ledger", accessor: "additionalLedger", hidden: false },
    { Header: "Description", accessor: "description", hidden: false },
    { Header: "Amount", accessor: "amount", hidden: false },
    { Header: "Input Igst", accessor: "inputIgst", hidden: false },
    { Header: "Input Cgst", accessor: "inputCgst", hidden: false },
    { Header: "Input Sgst", accessor: "inputSgst", hidden: false },
    { Header: "Roundoff Amt", accessor: "roundoffAmt", hidden: false },
    { Header: "Total", accessor: "total", hidden: false },
    { Header: "Ref No", accessor: "refNo", hidden: false },
    { Header: "Due On", accessor: "dueOn", hidden: false },
    { Header: "E Way Bill No", accessor: "eWayBillNo", hidden: false },
    { Header: "E Way Bill Date", accessor: "eWayBillDate", hidden: false },
    { Header: "Sub Type", accessor: "subType", hidden: false },
    { Header: "Doc Type", accessor: "docType", hidden: false },
    { Header: "Status Of Eway Bill", accessor: "statusOfEwayBill", hidden: false },
    { Header: "Mode", accessor: "mode", hidden: false },
    { Header: "Distance (In KM)", accessor: "distanceInKm", hidden: false },
    { Header: "Transporter Name", accessor: "transporterName", hidden: false },
    { Header: "Vehicle No", accessor: "vehicleNo", hidden: false },
    { Header: "Doc/Loading/RR/AirWay No", accessor: "docLoadingRrAirWayNo", hidden: false },
    { Header: "Date", accessor: "date", hidden: false },
    { Header: "Transporter ID", accessor: "transporterId", hidden: false },
    { Header: "Consignor", accessor: "consignor", hidden: false },
    { Header: "Address 1A", accessor: "address1A", hidden: false },
    { Header: "Address 2A", accessor: "address2A", hidden: false },
    { Header: "Pin Code1", accessor: "pinCode1", hidden: false },
    { Header: "Place", accessor: "place", hidden: false },
    { Header: "State1", accessor: "state1", hidden: false },
    { Header: "Gstin/Uin", accessor: "gstinUin", hidden: false },
    { Header: "Consignee", accessor: "consignee", hidden: false },
    { Header: "Address 1B", accessor: "address1B", hidden: false },
    { Header: "Address 2B", accessor: "address2B", hidden: false },
    { Header: "To Place (Destination )", accessor: "toPlaceDestination", hidden: false },
    { Header: "Pin1", accessor: "pin1", hidden: false },
    { Header: "State2", accessor: "state2", hidden: false },
    { Header: "Gstin/Uin1", accessor: "gstinUin1", hidden: false },
    { Header: "Tallyimportstatus", accessor: "tallyImportStatus", hidden: false }
];


  const handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const expdata = {
        ...billsListPayload,
        fromDate: fromDate,
        status: status,
        toDate: toDate,
        startIndex: sizePerPage,
        lastIndex: currentIndex,
      };
      
      if (currentIndex >= 0) {
        dispatch(getBillsList(serverApi.BILLS, expdata, history));
      }
    } else {
      const expdata = {
        ...billsListPayload,
        fromDate: fromDate,
        status: status,
        toDate: toDate,
        startIndex: currentIndex,
        lastIndex: sizePerPage
      };

      if (currentIndex >= 0) {
        dispatch(getBillsList(serverApi.BILLS, expdata, history));
      }
    }

    setStartIndex(currentIndex);
    setLastIndex(sizePerPage);
  };

  const handleSearch = (data1) => {
    setfromDate(data1.fromDate);
    setStatus(data1.status);
    settoDate(data1.toDate);

    const expdata = {
        ...billsListPayload,
        acYear: data1.acYear,
        companyId: data1.companyId,
        fromDate: data1.fromDate,
        status: data1.status,
        toDate: data1.toDate,
      };

      const val = {
        ...expdata,
        lastIndex:0,
        startIndex:0,
      }
      setDataExp(val)
      dispatch(getBillsList(serverApi.BILLS, expdata, history));
    };

  return (
    <>
      <Gridwithcustomview
        mdata={BillsListData}
        mcolumns={Columns}
        micon={GridArrowIcon}
        actions={true}
        url="/create_bills"
        filter_form={"accounting_bill"}
        handleSearch={handleSearch}
        totalRecs={totalRecs}
        page={startIndex}
        sizePerPage={lastIndex}
        handlePagination={handlePagination}
        apiexp={serverApi.BILLS_EXPORT}
        payloadexp={dataexp}
        expName={"Bills.csv"}
        fromDate={fromDate}
        toDate={toDate}

        // excel export props
        isExcelExport={true}
        exportButtonLabel={"Export Bills"}
      />
    </>
  );
};

export default AccountingBills;
