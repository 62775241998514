import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/PaginationGridView";
import { getLedgerGroupsList } from "../../../store/Master/LedgerGroupsMaster/actions";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      ledgerGroupsListData: [],
      ledgerCode: null,
      ledgerGroupName: null,
      parentLedgerGroup: null,
      startIndex: 1,
      lastIndex: 10,
      totalRecs: 0,
      dataexp: "",
    };
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      ledgerCode: this.state.ledgerCode,
      ledgerGroupName: this.state.ledgerGroupName,
      parentLedgerGroup: this.state.parentLedgerGroup,
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
    };
    this.props.getLedgerGroupsList(
      serverApi.GET_ALL_LEDGER_GROUPS_LIST,
      data,
      this.props.history
    );
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.LedgerGroupsList) {
      this.setState({
        ledgerGroupsListData: props.LedgerGroupsList.data,
        totalRecs: props.LedgerGroupsList.totalRecords,
      });
    }
  }
  handleSearch = (data1) => {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      ledgerCode: data1.ledgerCode,
      ledgerGroupName: data1.ledgerGroupName,
      parentLedgerGroup: data1.parentLedgerGroup,
      startIndex: 1,
      lastIndex: 10,
    };
    this.setState({
      ledgerCode: data1.ledgerCode,
      ledgerGroupName: data1.ledgerGroupName,
      parentLedgerGroup: data1.parentLedgerGroup,
    });
    this.props.getLedgerGroupsList(
      serverApi.GET_ALL_LEDGER_GROUPS_LIST,
      data,
      this.props.history
    );
  };
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;

    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      ledgerCode: this.state.ledgerCode,
      ledgerGroupName: this.state.ledgerGroupName,
      parentLedgerGroup: this.state.parentLedgerGroup,
    };
    if (type === 1) {
      data.startIndex = sizePerPage;
      data.lastIndex = currentIndex;
      if (currentIndex >= 0) {
        this.props.getLedgerGroupsList(
          serverApi.GET_ALL_LEDGER_GROUPS_LIST,
          data,
          this.props.history
        );
      }
    } else {
      data.startIndex = currentIndex === 0 ? currentIndex + 1 : currentIndex;
      data.lastIndex = sizePerPage;
      if (currentIndex >= 0) {
        this.props.getLedgerGroupsList(
          serverApi.GET_ALL_LEDGER_GROUPS_LIST,
          data,
          this.props.history
        );
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.ledgerGroupsListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          url="/create_Ledger_Groups"
          handleSearch={this.handleSearch}
          filter_form={"LedgerGroupMaster"}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          apiexp={serverApi.GET_ALL_LEDGER_GROUPS_LIST}
          payloadexp={this.state.dataexp}
          expName={"Ledger-Group-Master.csv"}
        />
      </>
    );
  }
}
// ledger groups list headers
const Columns = [
  {
    Header: "Ledger Group Name",
    accessor: "name",
    hidden: false,
  },
  {
    Header: "Ledger Group Code",
    accessor: "id",
    hidden: false,
  },
  {
    Header: "Parent Ledger Group ",
    accessor: "parentLedgerGroup",
    hidden: false,
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    hidden: true,
  },
  {
    Header: "Company Id",
    accessor: "companyId",
    hidden: true,
  },
  {
    Header: "Parent Group",
    accessor: "parentGroup",
    hidden: true,
  },
];
const mapStatetoProps = (state) => {
  const { LedgerGroupsList } = state.LedgerGroupsReducer; // fetching list from ledger groups reducer
  return { LedgerGroupsList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getLedgerGroupsList,
  })(Index)
);
