import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { serverApi } from "../../../helpers/Consts";
import TextFieldNormal from "../../TextField/TextFieldNormal";
// import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import DynamicSelect from "../../../components/Dropdown/Master/DynamicSelectMasters";
import {
    getTypesOfMachine,
    getDepartmentList,
  } from "../../../store/Global/DropDownApis/actions";



class MachineMasterFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      machineName:null,
      machinetype:null,
      department:null,
      departmentList: [],
      typesOfMachineList:[]
    };
  }

  componentDidMount() {
    this.props.getDepartmentList(
        serverApi.GET_DEPARTMENT_LIST +
          localStorage.getItem("companyId") +
          "/" +
          this.state.userDit.cipher,
        this.props.history
      ); // calling for department list api
      this.props.getTypesOfMachine(
        serverApi.GET_TYPES_OF_MACHINE,
        this.props.history
      );
    
  }
  componentWillReceiveProps(props){
    if (props.departmentList) {
        var departmentList = [];
        if (props.departmentList.data) {
          if (props.departmentList.data.length > 0) {
            props.departmentList.data.map((row) => {
              var dept = {
                label: row.label,
                name: row.name,
                value: row.masterDeptId,
                variant: null,
              };
              departmentList.push(dept);
            });
            this.setState({
              departmentList: departmentList, //props.departmentList.data,  // updating department list
            });
          }
        }
      }
      if (props.typesOfMachine) {
        this.setState({
          typesOfMachineList: props.typesOfMachine.data, // updating types Of Machine list
        });
      }
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  
  

  handleChange = (key)=> (event)=>{
    if(key === "machineName"){
      this.setState({
        machineName:event
      })
    }
  }

  handleSubmit = () => {
    // var data = {
    //   machineName:this.state.machineName ,
    //   machinetype:this.state.machinetype,
    //   department:this.state.department,
    // };
     var data = {
      machineName:this.state.machineName !==''?this.state.machineName:"",
      machinetype:this.state.machinetype !==""?this.state.machinetype:'',
      department:this.state.department !==""?this.state.department:'',
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleClear = () => {
    this.setState({
      machineName:'',
      machinetype:'',
      department:'',
    });
  };
  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    
    if (name === "machinetype") {
      if(selectedValue==0){
        this.setState({
          machinetype: null,
        });
      }else{
        this.setState({
          machinetype: selectedValue,
        });
      }
       
      }
      if (name === "department") {
        if(selectedName=='Select....'){
          this.setState({
            department: null,
          });
        }else{
          this.setState({
            department: selectedName,
          });
        }
      
      }
  };

  render() {

    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          // TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid
            spacing={0}
            // maxWidth="800px"
            //style={{ padding: "20px" }}
          >
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
                {/* <div>Save View</div> */}
              </div>
            </div>
            <Grid item xs={12} className="filterBlockContainer">
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Machine Name"
                        value={this.state.machineName}
                        onChange={this.handleChange("machineName")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.departmentList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.state.department}
                        update={this.props.location.state ? 1 : 0}
                        name="department"
                        label="Department"
                      />
                    </div>
                  </Box>
                  
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.typesOfMachineList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.state.machinetype}
                        update={this.props.location.state ? 1 : 0}
                        name="machinetype"
                        label="Machine Type"
                      />
                    </div>
                  </Box>

              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Search"}
                  className="greenButton"
                  handleClick={this.handleSubmit}
                  type="sumbit"
                />
              </div>
            </Grid>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  const { typesOfMachine, departmentList } = state.DropDownListReducer;
     // fetching global dropdown from dropdown list reducer
  return {typesOfMachine, departmentList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getTypesOfMachine,
    getDepartmentList,
  })(MachineMasterFilter)
);
