import React, { Component } from "react";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const Tab = styled(TabUnstyled)`
  color: #6491a9;
  font-family: "poppins-regular";
  cursor: pointer;
  background-color: transparent;
  padding: 0 0 5px;
  margin-right: 40px;
  border: none;
  border-radius: 5px;
  display: flex;
  //   justify-content: center;
  &:focus {
    color: #fff;
    border-radius: 3px;
  }

  &.${tabUnstyledClasses.selected} {
    color: #004b73;
    font-family: "poppins_bold";
    border-bottom: 4px solid #004b73;
    border-radius: 0;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: "poppins-regular";
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  //   background-color: ${blue[500]};
  // border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  border-bottom: 1px solid #d9d9d9;
`;
class TabsNavigationEmpDb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      value1: 1,
      value2: 2,
      value3: 3,
      value4: 4,
      defalutTab: 0,
    };
  }

  onvalue = (event, newValue) => {
    this.setState({
      defalutTab: newValue,
    });
  };

  render() {
    return (
      <>
        <TabsUnstyled defaultValue={this.props.defalutTab}>
          {/* <TabsUnstyled defaultValue={0}> */}
          <TabsList onChange={() => this.onvalue}>
            <Tab value={this.state.value}>{this.props.tabOneLabel}</Tab>
            <Tab value={this.state.value1} disabled={this.props.contact}>
              {this.props.tabTwoLabel}
            </Tab>
            <Tab value={this.state.value4} disabled={this.props.address}>
              {this.props.tabFiveLabel}
            </Tab>
            <Tab value={this.state.value2} disabled={this.props.education}>
              {this.props.tabThreeLabel}
            </Tab>
            <Tab value={this.state.value3} disabled={this.props.previous}>
              {this.props.tabFourLabel}
            </Tab>
          </TabsList>
          <TabPanel value={this.state.value}>
            {/* <RadioButton /> */}
            {this.props.tabOneContent}
          </TabPanel>
          <TabPanel value={this.state.value1}>
            {this.props.tabTwoContent}
          </TabPanel>
          <TabPanel value={this.state.value4}>
            {" "}
            {this.props.tabFiveContent}
          </TabPanel>
          <TabPanel value={this.state.value2}>
            {this.props.tabThreeContent}
          </TabPanel>
          <TabPanel value={this.state.value3}>
            {" "}
            {this.props.tabFourContent}
          </TabPanel>
        </TabsUnstyled>
      </>
    );
  }
}

export default TabsNavigationEmpDb;
