import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../../components/Dropdown/DynamicSelect";
import TextFieldNormal from "../../../../components/TextField/TextFieldNormal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { serverApi } from "../../../../helpers/Consts";
import {
  SalesOrderJuteGovtHeaders,
  getQuotationRecords,
  SalesOrderJuteGovtLineItems,
} from "../../../../store/Sales/SalesOrder/actions";
import {
  getBranchList,
  getCustomerList,
  getBillingShippingList,
} from "../../../../store/Global/DropDownApis/actions";
import { transporterMasterList } from "../../../../store/Master/TransporterMaster/actions";
import { getQuotationBroker } from "../../../../store/Sales/Qutation/actions";
import swal from "sweetalert";
import { SalesGstCalculations } from "../../../../Utilities/SalesGstCalculation";
import DynamicSelectNew from "../../../../components/Dropdown/DropdownSelectNew";
import FormPopup from "../../../../components/FormlPopup/FormPopup";
import { isEmpty } from "../../../../Utilities/helper";

const JuteGovtFillDetails = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [SoJuteGovtHeadersData, setHeaderData] = useState({});
  const [branchListData, setbranchListData] = useState([]);
  const [CustomerData, setCustomerData] = useState([]);
  const [billingAddressData, setbillingAddressData] = useState([]);
  const [shippingAddressData, setshippingAddressData] = useState([]);
  const [transporterList, settransporterList] = useState([]);
  const [customerListData, SetCustomerListData] = useState([]);
  const [emailCustomer,setEmailCustomer]=useState("")
  const [backDaysAllowable, setBackDaysAllowable] = useState("");
  const [futureDaysAllowable, setFutureDaysAllowable] = useState("");

  const { branchList, customerList, billingShippingList } = useSelector(
    (state) => state.DropDownListReducer
  );
  const { SoJuteGovtHeaders, SoJuteGovtLineItems, QuotationApprovedData } =
    useSelector((state) => state.SalesOrderReducer);
  const { TransporterMasterList } = useSelector(
    (state) => state.TransporterReducer
  );

  useEffect(() => {
    setHeaderData(SoJuteGovtHeaders);
    dispatch(
      getBranchList(
        serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
        history
      )
    );
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
    };
    dispatch(getCustomerList(serverApi.GET_ALL_CUSTOMERS, data, history));
    dispatch(
      getQuotationBroker(
        serverApi.GET_ALL_BROKER_LIST +
          localStorage.getItem("companyId") +
          "/SALE",
        history
      )
    );
    dispatch(
      getQuotationRecords(
        serverApi.GET_QUOTATION_DROPDOWN + localStorage.getItem("companyId"),
        history
      )
    );
    dispatch(
      transporterMasterList(
        serverApi.GET_ALL_TRANSPORTER_MASTER_LIST +
          localStorage.getItem("companyId"),
        history
      )
    );
    const currentMonth = moment().format('MM');
    const currentDate = moment().format('DD');
    const fiscalYearStart = moment(`${localStorage.getItem("acadamicYear")}-${currentMonth}-${currentDate}`);
    const today = moment();
    let dateValue;
    if (today.isBefore(fiscalYearStart)) {
      dateValue = today.format("YYYY-MM-DD");
    } else {
      dateValue = fiscalYearStart.format("YYYY-MM-DD");
    }

    if (userDit) {
      userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              if (item.backDaysAllowable !== undefined) {
                let backDaysAllowable = JSON.parse(item.backDaysAllowable);
                let backDays = backDaysAllowable.bdaystype;
                if (Number(backDays) === 1) {
                  backDays = 7;
                } else if (Number(backDays) === 2) {
                  backDays = 14;
                } else if (Number(backDays) === 3) {
                  backDays = "";
                } else if (Number(backDays) === 4) {
                  backDays = backDaysAllowable.otherBackDateValue;
                } else {
                  backDays = backDaysAllowable.bdaystype;
                }
                console.log(dateValue)
                let minDate = moment(dateValue).subtract(
                  Number(backDays),

                  "days"
                );
                let setMinDate = moment(minDate).format("YYYY-MM-DD");
                setBackDaysAllowable(setMinDate);
              }
              if (item.futureDaysAllowable !== undefined) {
                let futureDaysAllowable = JSON.parse(item.futureDaysAllowable);
                let futureDays = futureDaysAllowable.fdaystype;
                if (Number(futureDays) === 1) {
                  futureDays = 7;
                } else if (Number(futureDays) === 2) {
                  futureDays = 14;
                } else if (Number(futureDays) === 3) {
                  futureDays = "";
                } else if (Number(futureDays) === 4) {
                  futureDays = futureDaysAllowable.othervalue;
                } else {
                  futureDays = futureDaysAllowable.fdaystype;
                }
                let maxDate = moment(dateValue).add(
                  Number(futureDays),

                  "days"
                );
                let setMaxDate = moment(maxDate).format("YYYY-MM-DD");
                setFutureDaysAllowable(setMaxDate);
              }
            });
            // setHeaderData((prev) => ({
            //   ...prev,
            //   // salesDate: dateValue,
            //   // salesOrderExpiryDate:dateValue,
            //   // pcsoDate:dateValue
            // }));
            // const UpdatedRec = {
            //   ...SoJuteGovtHeaders,
            //   // salesDate: dateValue,
            //   // salesOrderExpiryDate:dateValue,
            //   // pcsoDate:dateValue
            // };
            // dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));

          }
      });
    }
  }, []);

  useEffect(() => {
    if (branchList) {
      if (branchList.data) {
        if (branchList.data.length !== 0) {
          let List = branchList.data.filter((item) => item.value !== 0);
          setbranchListData(List);
        }
      }
    }
    if (customerList) {
      if (customerList.data) {
        if (customerList.data.length !== 0) {
          var CusList = [];
          customerList.data.map((item) => {
            CusList.push({
              value: item.id,
              name: item.name,
              label: item.name,
            });
          });
          setCustomerData(CusList);
        }
      }
    }
    if (TransporterMasterList) {
      if (TransporterMasterList.data) {
        if (TransporterMasterList.data.data) {
          let TransporterList = TransporterMasterList.data.data.map((item) => ({
            name:
              item.transporterName +
              "^" +
              item.transporterAddress +
              "^" +
              item.stateName +
              "^" +
              item.stateCode,
            label: item.transporterName,
            value: item.transporterId,
          }));
          let obj = {
            label:"Select",
            name:"Select",
            value:0
          }
          TransporterList.splice(0,0,obj)
          settransporterList(TransporterList);
        }
      }
    }
  }, [branchList, customerList, QuotationApprovedData, TransporterMasterList]);
  const isEmptyObj = (myObject) => {
    // Use the 'Object.keys' method to get an array of the object's keys
    const keys = Object.keys(myObject);

    // Check if the number of keys in the array is 0
    return keys.length === 0;
  };

  useEffect(() => {
    if (billingShippingList) {
      if (billingShippingList.data) {
        setEmailCustomer(billingShippingList.data.customerHdr.emailId)
        if (billingShippingList.data.customerLineitems) {
          if (billingShippingList.data.customerLineitems.length !== 0) {
            var list = [];
            let billingAddressList = [];
            let shippingaddressList = [];
            let SoJuteGovtHeadersObj =  SoJuteGovtHeaders
            let customerLineitems = billingShippingList.data.customerLineitems;
            SetCustomerListData(customerLineitems);
            billingShippingList.data.customerLineitems.map((prop, i) => {
              if (prop.addressType === 2 || prop.addressType === null) {
                if (
                  (prop.customerLineId === SoJuteGovtHeaders.shippingTo &&
                    prop.isActive === 0) ||
                  prop.isActive !== 0
                ) {
                  billingAddressList.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
                  list.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
                }
              }
              if (prop.addressType === 2 || prop.addressType === 1 || prop.addressType === null) {
                if (
                  (prop.customerLineId === SoJuteGovtHeaders.shippingTo &&
                    prop.isActive === 0) ||
                  prop.isActive !== 0
                ) {
                  shippingaddressList.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
                  list.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
                }
              }
            });
            billingShippingList.data.customerLineitems.map((prop, i) => {
              if (billingAddressList.length !== 0 &&  SoJuteGovtHeadersData.customerId !== "" &&
              SoJuteGovtHeadersData.customerId !== null) {
                billingAddressList.map((billingdata, ind) => {
                  if (ind === 0) {
                    if (!isEmptyObj(SoJuteGovtHeadersData)) {
                      if (
                        SoJuteGovtHeadersData.customerId !== "" ||
                        SoJuteGovtHeadersData.customerId !== null
                      ) {
                        if (prop.customerLineId === billingdata.value) {

                          setHeaderData((prev) => ({
                            ...prev,
                            billingTo: prop.customerLineId,
                            billingToAddress: prop.address,
                            billingToGST: prop.gstNo,
                            billingContactNo: prop.contactNo,
                            billingContactPerson: prop.contactPerson,
                            billingToEmail: emailCustomer,
                            taxType: 2,
                          }));
                          const UpdatedRec = {
                            ...SoJuteGovtHeadersData,
                            billingTo: prop.customerLineId,
                            billingToAddress: prop.address,
                            billingToGST: prop.gstNo,
                            billingContactNo: prop.contactNo,
                            billingContactPerson: prop.contactPerson,
                            billingToEmail: emailCustomer,
                            taxType: 2,
                          };
                          SoJuteGovtHeadersObj = UpdatedRec
                        }
                      }else{
                        setHeaderData((prev) => ({
                          ...prev,
                          billingTo: null,
                          billingToAddress: "",
                          billingToGST: "",
                          billingContactNo: "",
                          billingContactPerson: "",
                          billingToEmail: "",
                          taxType: "",
                        }));
                        const UpdatedRec = {
                          ...SoJuteGovtHeadersData,
                          billingTo: null,
                          billingToAddress: "",
                          billingToGST: "",
                          billingContactNo: "",
                          billingContactPerson: "",
                          billingToEmail: "",
                          taxType: "",
                        };
                        SoJuteGovtHeadersObj = UpdatedRec
                      }
                    }
                  }
                });
              } else {
                setHeaderData((prev) => ({
                  ...prev,
                  ContactNumber: "",
                  ContactPerson: "",
                  billingTo: null,
                  billingToAddress: "",
                  billingToGST: "",
                  billingContactNo: "",
                  billingContactPerson: "",
                  billingToEmail: "",
                  taxType: "",
                }));
                const UpdatedRec = {
                  ...SoJuteGovtHeadersData,
                  ContactNumber: "",
                  ContactPerson: "",
                  billingTo: null,
                  billingToAddress: "",
                  billingToGST: "",
                  billingContactNo: "",
                  billingContactPerson: "",
                  billingToEmail: "",
                  taxType: "",
                };
                SoJuteGovtHeadersObj = UpdatedRec
                
              }
            })

            billingShippingList.data.customerLineitems.map((prop, i) => {
              if (shippingaddressList.length !== 0 &&  SoJuteGovtHeadersData.customerId !== "" &&
              SoJuteGovtHeadersData.customerId !== null) {
                shippingaddressList.map((billingdata, ind) => {
                  if (ind === 0) {
                    if (!isEmptyObj(SoJuteGovtHeadersData)) {
                      if (
                        SoJuteGovtHeadersData.customerId !== "" ||
                        SoJuteGovtHeadersData.customerId !== null
                      ) {
                        if (prop.customerLineId === billingdata.value) {
                          setHeaderData((prev) => ({
                            ...prev,
                            shippingTo: prop.customerLineId,
                            shippingToAddress: prop.address,
                            shippingToGST: prop.gstNo,
                            shippingContactNo: prop.contactNo,
                            shippingContactPerson: prop.contactPerson,
                            shippingToEmail: emailCustomer,
                            taxType: 2,
                          }));
                          const UpdatedRec = {
                            ...SoJuteGovtHeadersData,
                            shippingTo: prop.customerLineId,
                            shippingToAddress: prop.address,
                            shippingToGST: prop.gstNo,
                            shippingContactNo: prop.contactNo,
                            shippingContactPerson: prop.contactPerson,
                            shippingToEmail: emailCustomer,
                            taxType: 2,
                          };
                          SoJuteGovtHeadersObj = UpdatedRec
                        }
                      }else{
                        setHeaderData((prev) => ({
                          ...prev,
                          shippingTo: null,
                          shippingToAddress: "",
                          shippingToGST: "",
                          shippingContactNo: "",
                          shippingContactPerson: "",
                          shippingToEmail: "",
                          taxType: "",
                        }));
                        const UpdatedRec = {
                          ...SoJuteGovtHeadersData,
                          shippingTo: null,
                          shippingToAddress: "",
                          shippingToGST: "",
                          shippingContactNo: "",
                          shippingContactPerson: "",
                          shippingToEmail: "",
                          taxType: "",
                        };
                        SoJuteGovtHeadersObj = UpdatedRec
                      }
                    }
                  }
                });
              } else {
                setHeaderData((prev) => ({
                  ...prev,
                  shippingTo: null,
                  shippingToAddress: "",
                  shippingToGST: "",
                  shippingContactNo: "",
                  shippingContactPerson: "",
                  shippingToEmail: "",
                  taxType: "",
                }));
                const UpdatedRec = {
                  ...SoJuteGovtHeadersData,
                  shippingTo: null,
                  shippingToAddress: "",
                  shippingToGST: "",
                  shippingContactNo: "",
                  shippingContactPerson: "",
                  shippingToEmail: "",
                  taxType: "",
                };
                SoJuteGovtHeadersObj = UpdatedRec
                dispatch(SalesOrderJuteGovtHeaders(SoJuteGovtHeadersObj));
              }
            })

            if (SoJuteGovtHeadersData.customerId !== "") {
              dispatch(SalesOrderJuteGovtHeaders(SoJuteGovtHeadersObj));
              setbillingAddressData(billingAddressList);
              setshippingAddressData(shippingaddressList);
            }
          }
        }
      }
    }
  }, [billingShippingList]);

  const handlePropSelectChange = (
    selectedValue,
    selectedName,
    name,
    row,
    selectedlable
  ) => {
    if (name === "branchId") {
      setHeaderData((prev) => ({
        ...prev,
        branchId: selectedValue,
        branchName: selectedlable,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        branchId: selectedValue,
        branchName: selectedlable,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
    if (name === "deliveryTerms") {
      setHeaderData((prev) => ({
        ...prev,
        deliveryTerms: selectedName,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        deliveryTerms: selectedName,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
    if (name === "customerId") {
      setHeaderData((prev) => ({
        ...prev,
        customerId: selectedValue,
        customerName: selectedlable,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        customerId: selectedValue,
        customerName: selectedlable,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
      dispatch(
        getBillingShippingList(
          serverApi.GET_ALL_BILLING_SHIPPING +
            selectedValue +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).cipher +
            "?isForSalesModule=1",
          history
        )
      );
    }
    if (name === "brokerId") {
      setHeaderData((prev) => ({
        ...prev,
        brokerId: selectedValue,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        brokerId: selectedValue,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
    if (name === "billingTo") {
      customerListData.filter((prop)=>{
        if (prop.customerLineId === selectedValue) {
     
      setHeaderData((prev) => ({
        ...prev,
        billingTo: selectedValue,
        billingToAddress: selectedName,
        billingToGST: prop.gstNo,
        billingContactNo: prop.contactNo,
        billingContactPerson: prop.contactPerson,
        billingToEmail: emailCustomer,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        billingTo: selectedValue,
        billingToAddress: selectedName,
        billingToGST: prop.gstNo,
        billingContactNo: prop.contactNo,
        billingContactPerson: prop.contactPerson,
        billingToEmail: emailCustomer,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
  }) 
    }
    if (name === "shippingTo") {
      customerListData.filter((prop)=>{
        if (prop.customerLineId === selectedValue) {
      setHeaderData((prev) => ({
        ...prev,
        shippingTo: selectedValue,
        shippingToAddress: selectedName,
        shippingToGST: prop.gstNo,
        shippingContactNo: prop.contactNo,
        shippingContactPerson: prop.contactPerson,
        shippingToEmail: emailCustomer,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        shippingTo: selectedValue,
        shippingToAddress: selectedName,
        shippingToGST: prop.gstNo,
        shippingContactNo: prop.contactNo,
        shippingContactPerson: prop.contactPerson,
        shippingToEmail: emailCustomer,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
  })
    }
    if (name === "TaxType") {
      let TaxPer = SalesGstCalculations(
        {
          lineItems: SoJuteGovtLineItems,
          taxType: selectedValue,
        },
        "SALES_REGULAR_FLOW"
      );
      let taxType = selectedValue;
      let taxName = selectedName;
      handleCalculation(TaxPer, taxType, taxName);
      setHeaderData((prev) => ({
        ...prev,
        taxType: selectedValue,
        taxName: selectedlable,
      }));
      
    }
    if (name === "Transporter") {
      var Data = selectedName.split("^", 4);
      setHeaderData((prev) => ({
        ...prev,
        transporterId: selectedValue,
        transporterName: selectedlable,
        transporterAddress:selectedValue ===0?"": Data[1],
        transporterStateName:selectedValue ===0?"": Data[2],
        transporterStateCode:selectedValue ===0?"": Data[3],
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        transporterId: selectedValue,
        transporterName: selectedlable,
        transporterAddress: Data[1],
        transporterStateName: Data[2],
        transporterStateCode: Data[3],
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
  };

  const handleCalculation = (Calculations, taxType, taxName) => {
 
    dispatch(SalesOrderJuteGovtLineItems(Calculations.lineItems));

    let Headerdata = {
      ...SoJuteGovtHeadersData,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
      taxType: taxType,
      taxName: taxName,
    };
    setHeaderData((prev) => ({
      ...prev,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
      taxType: taxType,
      taxName: taxName,
    }));
    dispatch(SalesOrderJuteGovtHeaders(Headerdata));
  };
  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const onhandlechange = (key) => (event) => {
    if (key === "deliveryDays") {
      setHeaderData((prev) => ({
        ...prev,
        deliveryDays: event,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        deliveryDays: event,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
    if (key === "transporterAddress") {
      setHeaderData((prev) => ({
        ...prev,
        transporterAddress: event,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        transporterAddress: event,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
    if (key === "transporterStateName") {
      setHeaderData((prev) => ({
        ...prev,
        transporterName: event,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        transporterStateName: event,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
    if (key === "transporterStateCode") {
      setHeaderData((prev) => ({
        ...prev,
        transporterStateCode: event,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        transporterStateCode: event,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
    if (key === "PCSONumber") {
      setHeaderData((prev) => ({
        ...prev,
        pcsoNo: event,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        pcsoNo: event,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
    if (key === "brokerCommisionPercent") {
      setHeaderData((prev) => ({
        ...prev,
        brokerCommisionPercent: event,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        brokerCommisionPercent: event,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }

    if (key === "AmmendmendNo.1") {
      setHeaderData((prev) => ({
        ...prev,
        ammendmendNo: event,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        ammendmendNo: event,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
  };
  const handleSelect_Date = (e, name) => {
    if (name === "salesDate") {
      let date = moment(e).format();
      let Date = date.split("T", 1).toString();
      let Sale_date = null;
      if (SoJuteGovtHeadersData.salesOrderExpiryDate !== null) {
        if (Date > SoJuteGovtHeadersData.salesOrderExpiryDate) {
          swal("Sale Date Shouldn't be Greater than Expire Date");
          Sale_date = SoJuteGovtHeadersData.salesOrderExpiryDate
        } else {
          Sale_date = Date;
        }
      } else {
        Sale_date = Date;
      }
      setHeaderData((prev) => ({
        ...prev,
        salesDate: Sale_date,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        salesDate: Sale_date,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
    if (name === "ExpiredDate") {
      let date = moment(e).format();
      let Date = date.split("T", 1).toString();
      let SaleExpDate = null;
      if (SoJuteGovtHeadersData.salesDate !== null) {
        if (Date < SoJuteGovtHeadersData.salesDate) {
          swal("Expire Date Should be Greater than Sale Date");
          SaleExpDate = SoJuteGovtHeadersData.salesDate;
        } else {
          SaleExpDate = Date;
        }
      } else {
        swal("Please Select the Sale Date First");
      }
      setHeaderData((prev) => ({
        ...prev,
        salesOrderExpiryDate: SaleExpDate,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        salesOrderExpiryDate: SaleExpDate,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
    if (name === "PCSODate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        pcsoDate: Date,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        pcsoDate: Date,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
    if (name === "AmmendmendDate") {
      let date = moment(e).format();
      let Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        ammendmendDate: Date,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        ammendmendDate: Date,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
  };

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <div>
        <div className="consoleFormContainer">
          <h5> Jute Govt Fill Details</h5>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={SoJuteGovtHeadersData.branchId ? 1 : 0}
                        arrayOfData={branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={SoJuteGovtHeadersData.branchId}
                        name="branchId"
                        label="Branch"
                        required
                        isDisabled={
                          props.locationState.state === undefined ? false : true
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelect
                      update={SoJuteGovtHeadersData.customerId ? 1 : 0}
                      arrayOfData={CustomerData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      selected={SoJuteGovtHeadersData.customerId}
                      name="customerId"
                      label="Customer/Consignee"
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      required
                      isDisabled={
                        SoJuteGovtHeadersData.branchId === "" ? true : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={SoJuteGovtHeadersData.billingTo ? 1 : 0}
                        arrayOfData={billingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={SoJuteGovtHeadersData.billingTo}
                        name="billingTo"
                        label="Billing To"
                        required
                        isDisabled={
                          SoJuteGovtHeadersData.customerId === null
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={SoJuteGovtHeadersData.shippingTo ? 1 : 0}
                        arrayOfData={shippingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={SoJuteGovtHeadersData.shippingTo}
                        name="shippingTo"
                        label="Shipping To"
                        required
                        isDisabled={
                          SoJuteGovtHeadersData.customerId === null
                            ? true
                            : false
                        }
                      />
                    </div>
                    {!isEmpty( SoJuteGovtHeadersData.customerId) && (
                    <span
                      onClick={openPopup}
                      style={{
                        color: "red",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 100,
                        fontSize: "15px",
                        marginTop: "5px",
                        cursor: "pointer",
                        padding: "5px",
                      }}>
                        Add a new Customer Branch Address
                    </span>
                )}
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={SoJuteGovtHeadersData.taxType ? 1 : 0}
                        arrayOfData={taxTypeData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={SoJuteGovtHeadersData.taxType}
                        name="TaxType"
                        label="Tax Type"
                        isDisabled={
                          SoJuteGovtHeadersData.customerId === null
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Sale Date <span className="starcolor">*</span>
                          </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="saleDate"
                            inputFormat="dd-MM-yyyy"
                            id="saleDate"
                            minDate={
                              backDaysAllowable !== ""
                                ? backDaysAllowable
                                :SoJuteGovtHeadersData.salesDate
                            }
                            maxDate={
                              futureDaysAllowable !== ""
                                ? futureDaysAllowable
                                : SoJuteGovtHeadersData.salesDate
                            }
                            value={SoJuteGovtHeadersData.salesDate}
                            fullWidth
                            onChange={(e) => handleSelect_Date(e, "salesDate")}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Expire Date<span className="starcolor">*</span>
                          </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="Expired Date"
                            inputFormat="dd-MM-yyyy"
                            id="ExpiredDate"
                            value={SoJuteGovtHeadersData.salesOrderExpiryDate}
                            minDate={
                              backDaysAllowable !== ""
                                ? backDaysAllowable
                                :SoJuteGovtHeadersData.salesOrderExpiryDate
                            }
                            maxDate={
                              futureDaysAllowable !== ""
                                ? futureDaysAllowable
                                : SoJuteGovtHeadersData.salesOrderExpiryDate
                            }
                            fullWidth
                            onChange={(e) =>
                              handleSelect_Date(e, "ExpiredDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div style={{ height: "15px" }}></div>

          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={SoJuteGovtHeadersData.branchId ? 1 : 0}
                        arrayOfData={transporterList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={SoJuteGovtHeadersData.transporterId}
                        name="Transporter"
                        label="Transporter"
                      />
                    </div>
                  </Box>{" "}
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Transporter Address"
                      value={SoJuteGovtHeadersData.transporterAddress}
                      onChange={onhandlechange("transporterAddress")}
                      type="text"
                      disabled={SoJuteGovtHeadersData.transporterId && true}
                    />
                  </Box>{" "}
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Transporter State Name"
                      value={SoJuteGovtHeadersData.transporterStateName}
                      onChange={onhandlechange("transporterStateName")}
                      type="text"
                      disabled={SoJuteGovtHeadersData.transporterId && true}
                    />
                  </Box>{" "}
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Transporter State Code"
                      value={SoJuteGovtHeadersData.transporterStateCode}
                      onChange={onhandlechange("transporterStateCode")}
                      type="text"
                      disabled={SoJuteGovtHeadersData.transporterId && true}
                    />
                  </Box>{" "}
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="PCSO Number"
                      value={SoJuteGovtHeadersData.pcsoNo}
                      onChange={onhandlechange("PCSONumber")}
                      required
                      disabled={
                        SoJuteGovtHeadersData.customerId === null ? true : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            PCSO Date<span className="starcolor">*</span>
                          </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="PCSO Date"
                            inputFormat="dd-MM-yyyy"
                            id="PCSODate"
                            value={SoJuteGovtHeadersData.pcsoDate}
                            minDate={
                              backDaysAllowable !== ""
                                ? backDaysAllowable
                                :SoJuteGovtHeadersData.pcsoDate
                            }
                            maxDate={
                              futureDaysAllowable !== ""
                                ? futureDaysAllowable
                                : SoJuteGovtHeadersData.pcsoDate
                            }
                            fullWidth
                            onChange={(e) => handleSelect_Date(e, "PCSODate")}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelectNew
                      update={SoJuteGovtHeadersData.deliveryTerms ? 1 : 0}
                      arrayOfData={DeliveryTermsData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={SoJuteGovtHeadersData.deliveryTerms}
                      name="deliveryTerms"
                      label="Delivery Terms"
                      required
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <FormPopup isOpen={isPopupOpen} onClose={closePopup} customerData={billingShippingList} customerId={SoJuteGovtHeadersData.customerId}/>
      </div>
    </>
  );
};
const taxTypeData = [
  {
    value: 1,
    name: "IGST",
    label: "IGST",
  },
  {
    value: 2,
    name: "CGST & SGST",
    label: "CGST & SGST",
  },
];
const DeliveryTermsData = [
  {
    value: 1,
    label: "Ex-Works",
    name: "Ex-Works",
  },
  {
    value: 2,
    label: "Delivery to Destination",
    name: "Delivery to Destination",
  },
];

export default JuteGovtFillDetails;
