import {COMPANY_MASTER_LIST,COMPANY_MASTER_LIST_SUCCESSFULL,
  API_ERRORS,
  CREATE_COMPANY_MASTER, CREATE_COMPANY_MASTER_SUCCESSFULL, UPDATE_COMPANY_MASTER_SUCCESSFULL, UPDATE_COMPANY_MASTER,
  } from './actionTypes';

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    CompanyMasterList: [],
    CreateCompanyMaster:[],
    UpdateCompanyMaster:[],
  };

  const CompanyMasterReducer = (state = initialState, action) => {
    switch (action.type) {
        case COMPANY_MASTER_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case COMPANY_MASTER_LIST_SUCCESSFULL:
            state = {
              ...state,
              CompanyMasterList: action.payload.response,
              loading: true,
            };
            break;
            case CREATE_COMPANY_MASTER:
              state = {
                ...state,
                loading: true,
              };
              break;
            case CREATE_COMPANY_MASTER_SUCCESSFULL:
              state = {
                ...state,
                CreateCompanyMaster: action.payload.response,
                loading: true,
              };
              break;
              case UPDATE_COMPANY_MASTER:
              state = {
                ...state,
                loading: true,
              };
              break;
            case UPDATE_COMPANY_MASTER_SUCCESSFULL:
              state = {
                ...state,
                UpdateCompanyMaster: action.payload.response,
                loading: true,
              };
              break;
              case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default CompanyMasterReducer;