import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import DynamicSelect from "../../components/Dropdown/Master/DynamicSelectMasters";
import { serverApi } from "../../helpers/Consts";
import TextFieldNormal from "../../components/TextField/TextFieldNormal";
import { getStatusList } from "../../store/Global/DropDownApis/actions";
import { getCustomerList } from "../../store/Global/DropDownApis/actions";

import moment from "moment";

class QuotationFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      customerListData: [],
      Status: [],
      from_date: "",
      to_date: "",
      status_id: "",
      quotation_no: "",
      customer_name: "",
    };
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "Status") {
      this.setState({
        status_id: selectedValue,
      });
    }
    if (name === "CustomerName") {
      this.setState({
        customer_name: selectedValue,
      });
    }
  };
  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
  };
  handleSelectDate = (e, name) => {
    if (name === "FromDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      this.setState({
        from_date: Date,
      });
    }
    if (name === "ToDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      this.setState({
        to_date: Date,
      });
    }
  };
  handleSubmit = () => {
    if (this.state.from_date !== "") {
      var from_Date = moment(this.state.from_date, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      );
    } else {
      var from_Date = this.state.from_date;
    }
    if (this.state.to_date !== "") {
      var to_Date = moment(this.state.to_date, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      );
    } else {
      var to_Date = this.state.to_date;
    }

    const data = {
      acYear: localStorage.getItem("acadamicYear"),
      companyId: localStorage.getItem("companyId"),
      cusId: this.state.customer_name,
      fromDate: from_Date,
      quoteNo: this.state.quotation_no,
      status: this.state.status_id,
      toDate: to_Date,
    };
    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  componentWillReceiveProps(props) {
    if (props.statusList) {
      this.setState({
        Status: props.statusList.data,
      });
    }
    if (props.customerList) {
      this.setState({
        customerListData: props.customerList.data,
      });
    }
  }

  componentDidMount() {
    this.props.getStatusList(serverApi.STATUS_LISTS, this.props.history);
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: this.state.userDit.cipher,
    };
    this.props.getCustomerList(
      serverApi.GET_ALL_CUSTOMERS,
      data,
      this.props.history
    );
  }

  render() {
    return (
      <>
        <Box>
          <Button onClick={this.handleClickOpen}>
            <Tooltip title="Filter">
              <FilterListOutlinedIcon />
            </Tooltip>
          </Button>
          <Dialog
            open={this.state.open}
            PaperProps={{
              sx: {
                position: " fixed",
                top: 0,
                right: 0,
                margin: 0,
                bottom: 0,
                maxHeight: "100vh",
              },
            }}
            keepMounted
            onClose={this.handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <Grid spacing={0}>
              <div className="consoleTextFieldBlock">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div className="filterBlockHeader">
                    <FilterListOutlinedIcon />
                    <span>Filter</span>
                  </div>
                </div>
              </div>
              <div className="prResponseFilter">
                <Grid item xs={12} className="filterBlockContainer">
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>From Date</label>
                      </div>
                      {/* <ConsoleDatePicker /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="FromDate"
                          value={this.state.from_date}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "FromDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>To Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="ToDate"
                          value={this.state.to_date}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "ToDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={
                          this.state.Status
                            ? this.state.Status.map((item) => ({
                                label: item.label,
                                value: item.value,
                                name: item.name,
                              }))
                            : ""
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectChange}
                        selected={this.state.status_id}
                        name="Status"
                        label="Status"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="filterDateBlock">
                      <div>
                        <TextFieldNormal
                          label="Quotation Number"
                          value={this.state.quotation_no}
                          onChange={this.onhandlechange("quotation_no")}
                        />
                      </div>
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={
                          this.state.customerListData &&
                          this.state.customerListData.map((item) => ({
                            label: item.name,
                            value: item.id,
                            name: item.name,
                          }))
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectChange}
                        selected={this.state.customer_name}
                        name="CustomerName"
                        label="Customer Name"
                      />
                    </div>
                  </Box>
                  <div className="consoleFormButtonBlock">
                    <CustomButton
                      label={"Search"}
                      className="greenButton"
                      handleClick={this.handleSubmit}
                      type="sumbit"
                    />
                  </div>
                </Grid>
              </div>
            </Grid>
          </Dialog>
        </Box>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { statusList, customerList } = state.DropDownListReducer;
  return { statusList, customerList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getStatusList,
    getCustomerList,
  })(QuotationFilter)
);
