import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Grid, Box } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/JuteProduction/DynamicSelect";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { getSpell } from "../../../store/HRMS/AttendanceCalender/actions";
import { getYarnTypeList } from "../../../store/Global/DropDownApis/actions";
import GridDeleteIcon from "../../../assets/images/deleteIconButton.png";

import {
  serverApi,
  showSpinner,
  hideSpinner,
  serverVars,
} from "../../../helpers/Consts";
import CustomButton from "../../../components/Buttons/Buttons";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { getTrollyDdList } from "../../../store/JuteProduction/WindingDoffEntry/actions";
import InputAdormentSearch from "../../../components/InputAdorment/InputAdormentSearch";
import searchIcon from "../../../assets/images/search.png";
import { getWorkerList } from "../../../store/HRMS/ExpenseBooking/actions";
import { beamingDdList } from "../../../store/JuteProduction/BeamingProduction/actions";
import MrCommonPopup from "../../../components/CommonPopup/MRPopup";
import {
  getLoomNo,
  getcutsandjugarByLoomNo,
  dailyQualityMapping,
  CutsAndJugarDataList,
  createActualShot,
  CutsJugarList,
  updateEbNoWorkingHrs,
  createCutsandJugar,
  updateWeavingMachineMapping,
  DeleteActualShot,
} from "../../../store/JuteProduction/CutsAndJugarEntry/actions";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import { spinningQualityList } from "../../../store/Master/SpinningQualityMaster/actions";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import swal from "sweetalert";

class CreateSpinningDoffEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProcessMasterContent: [],
      userDit: JSON.parse(localStorage.getItem("authUser")),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      spellContent: [],
      yarnTypeListData: [],
      trollyDdList: [],
      tranDate: moment().format("YYYY-MM-DD"),
      time: moment().format().split("T"),
      spell: "",
      confirm: false,
      show: false,
      loomNo: "",
      message: "",
      spellListType: [],
      dailyDisplay: "none",
      dailyMapping: "none",
      displayCutsandJugar: "none",
      showActualShot: false,
      cutsJugarList: [],
      qualityCodeList: [],
      qualityCode: "",
      actualShots: 0,
      spellList: [],
      loomId: "",
      frameNoList: [],
      weavingList: [],
      machineQualityList: [],
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.beamingDdList(
      serverApi.GET_BEAMING_DD_LIST +
        localStorage.getItem("companyId") +
        "/Loom",
      this.props.history
    );
    this.props.getYarnTypeList(
      serverApi.GET_YARN_TYPE_LIST + localStorage.getItem("companyId"),
      this.props.history
    );

    this.props.getTrollyDdList(
      serverApi.GET_TROLLY_DD_LIST +
        "2/" +
        localStorage.getItem("companyId") +
        "/" +
        this.state.cipher,
      this.props.history
    );
    this.props.getSpell(
      serverApi.SPELL +
        localStorage.getItem("companyId") +
        "/" +
        this.state.cipher,
      this.props.history
    );

    const data2 = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      qualityType: 1,
    };
    this.props.spinningQualityList(
      serverApi.GET_ALL_WEAVING_QUALITY_MASTER_LIST,
      data2,
      this.props.history
    );
  }

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    this.setState({ [name]: selectedValue });
    // selected value for spell
    if (name === "Spell") {
      this.setState({
        spell_name: selectedValue,
      });
    }
    // selected value for quality
    if (name === "quality") {
      this.setState({
        qualityCode: selectedValue,
      });
    }
  };

  // function to edit mapped cuts and jugar according to spell
  onhandleChangeValue = (key, name) => (event) => {
    var result = [...this.state.spellList];
    result = result.map((prop) => {
      if (name === "cuts") {
        if (prop.index === key) prop.cuts = event;
        return prop;
      }
      if (name === "jugar") {
        if (prop.index === key) prop.jugar = event;
        return prop;
      }
    });

    this.setState({
      spellList: result,
    });
  };

  //function to edit mapped quality according to machine name
  handleSelectChange = (key) => (selectedValue) => {
    var result = [...this.state.weavingList];
    result = result.map((prop) => {
      if (prop.mcId === key) prop.qCode = selectedValue;
      return prop;
    });
    this.setState({
      weavingList: result,
    });
  };

  // function to input value
  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "actualShots") {
      this.setState({
        actualShots: event,
      });
    }
  };

  onHandleMrData = () => {
    this.setState({
      show: true,
      dailyDisplay: "block",
    });
  };

  //function trigger's onclick actual shot
  onClickActualShot = () => {
    this.props.CutsAndJugarDataList(
      serverApi.CUTS_JUGAR_DATA_LIST + localStorage.getItem("companyId") + "/1",
      this.props.history
    );
    this.setState({
      showActualShot: true,
    });
  };

  handleCancel = () => {
    this.setState({
      show: false,
      machineQualityList: [],
      dailyMapping: "none",
    });
  };
  handleActualshotCancel = () => {
    this.setState({
      showActualShot: false,
    });
  };
  handleCutsandJugarCancel = () => {
    this.setState({
      showActualShot: false,

      displayCutsandJugar: "none",
    });
    this.props.history.push("/ad_cuts_jugar_entry");
  };
  componentWillReceiveProps(props) {
    if (props.getLoomNoList) {
      this.setState({
        message: props.getLoomNoList.message,
        loomId: props.getLoomNoList.data
          ? props.getLoomNoList.data.machineId
          : "",
      });
    }
    if (props.spell) {
      var list = [];

      let FilterList = props.spell.filter((data) => {
        if (data.value !== "0") {
          return data;
        }
      });

      FilterList.map((prop) => {
        var name = prop.name.split("_");

        if (name[1] === "1") {
          list.push(prop);
        }
      });
      this.setState({
        spellContent: FilterList,
        spellListType: list,
      });
    }

    if (props.cutsJugarDataList) {
      this.setState({
        cutsJugarList: props.cutsJugarDataList.data,
      });
    }

    // actualshot - quality code dropdown response
    if (props.SpinningQualityList) {
      let list = [];
      if (props.SpinningQualityList.data) {
        let filterList = props.SpinningQualityList.data.filter((data) => {
          if (data.qualityCode !== "0") {
            return data;
          }
        });
        filterList.map((prop) => {
          list.push({
            label: prop.qualityName + "(" + prop.qualityCode + ")",
            value: prop.qualityId,
            name: prop.qualityName,
          });
        });
        this.setState({
          qualityCodeList: list,
        });
      }
    }

    // machine & quality - mapped quality dropdown response

    if (props.SpinningQualityList) {
      let list = [];
      if (props.SpinningQualityList.data) {
        let FilterList = props.SpinningQualityList.data.filter((data) => {
          if (data.qualityCode !== "0") {
            return data;
          }
        });
        FilterList.map((prop) => {
          list.push({
            label: prop.qualityName + "_" + prop.qualityCode,
            value: prop.qualityCode,
            name: prop.qualityName,
          });
        });
        this.setState({
          machineQualityList: list,
        });
      }
    }

    if (props.cutsJugarListRed) {
      var spellList = this.state.spellListType.map((prop, index) => {
        var obj = {
          spell: prop.value,
          empNo: "",
          cuts: "",
          jugar: "",
          index: index,
        };

        if (props.cutsJugarListRed.length > 0) {
          props.cutsJugarListRed.forEach((o) => {
            if (o.spell === prop.value) {
              obj.cuts = o.cuts;
              obj.jugar = o.jugar;
            }
          });
        }
        return obj;
      });

      this.setState({
        spellList: spellList,
      });
    }

    if (props.beamingProductionDdLists) {
      if (props.beamingProductionDdLists.data) {
        this.setState({
          frameNoList: props.beamingProductionDdLists.data,
        });
      }
    }

    if (props.dailyQualityList) {
      var weavingList = [];
      this.state.frameNoList.map((prop, i) => {
        if (i > 0) {
          var obj = { qCode: "", mcId: prop.value, mcName: prop.label };
          if (props.dailyQualityList.data) {
            props.dailyQualityList.data.map((o) => {
              if (prop.value === o.mcId) {
                obj.qCode = o.qCode;
              }
            });
            weavingList.push(obj);
          }
        }
      });
      this.setState({
        weavingList: weavingList,
      });
    }
  }
  onClick = () => {
    var date = moment(this.state.tranDate, true, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    );

    var data = {
      cipher: this.state.cipher,
      companyId: localStorage.getItem("companyId"),
      date: date,
      loomId: this.state.loomNo,
    };

    if (this.state.loomNo !== "") {
      this.props.getLoomNo(
        serverApi.GET_LOOM_NO,
        data,
        this.props.history,
        date
      );
      this.props.getSpell(
        serverApi.SPELL +
          localStorage.getItem("companyId") +
          "/" +
          this.state.cipher,
        this.props.history
      );
      this.setState({
        displayCutsandJugar: "block",
      });
    } else {
      swal("Please Enter the loom no.", { icon: "error" });
    }
  };
  handleInputChange = (e, name) => {
    if (name === "grossWeight") {
    }
  };
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      var date = moment(this.state.tranDate, true, "YYYY-MM-DD").format(
        "DD-MM-YYYY"
      );

      var data = {
        cipher: this.state.cipher,
        companyId: localStorage.getItem("companyId"),
        date: date,
        loomId: this.state.loomNo,
      };
      this.props.getLoomNo(serverApi.GET_LOOM_NO, data, this.props.history);
    }
    if (this.state.employeeDetailsBlock === "none") {
      this.setState({ employeeDetailsBlock: "block" });
    }
  };
  onClickCreate = () => {
    var date = moment(this.state.tranDate, true, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    );
    var data = this.state.spellList.map((prop) => {
      return {
        userId: localStorage.getItem("companyId"),
        loomNo: this.state.loomId,
        cuts: prop.cuts,
        jugar: prop.jugar,
        prodDate: date,
        spell: prop.spell,
        dataSource: 0,
        activeStatus: 1,
        companyId: localStorage.getItem("companyId"),
      };
    });
    this.props.createCutsandJugar(
      serverApi.CREATE_CUTS_AND_JUGAR,
      data,
      this.props.history
    );
  };

  // Function For Displaying Common Popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  // function to get the date
  handleSelectDate = (e, name) => {
    if (name === "tranDate") {
      let date = moment(e).format();
      let formattedFromDate = date.split("T", 1).toString();
      this.setState({
        tranDate: formattedFromDate,
        displayCutsandJugar: "none",
        loomNo: "",
      });
    }
  };

  // function trigger's when spell is clicked
  onClickSpell = () => {
    var date = moment(this.state.tranDate, true, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    );
    var data = {
      companyId: localStorage.getItem("companyId"),
      date: date,
      spell: this.state.spell_name,
    };
    this.props.dailyQualityMapping(
      serverApi.GET_DAILY_QUALITY_MAPPING,
      data,
      this.props.history
    );
    this.setState({
      dailyMapping: "block",
      dailyDisplay: "none",
    });
  };

  //function to create actual shot
  onCreateActualShots = () => {
    var date = moment(this.state.tranDate, true, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    );

    const data = {
      qualityId: this.state.qualityCode,
      actualShot: this.state.actualShots,
      spiningActualCount: "",
      qualityType: 1,
      isActive: 1,
      companyId: localStorage.getItem("companyId"),
      mappingDate: this.state.tranDate,
    };
    if (
      this.state.qualityCode !== "" &&
      this.state.actualShots !== 0 &&
      this.state.actualShots !== ""
    ) {
      this.props.createActualShot(
        serverApi.ADD_ACTUAL_SHOTS,
        data,
        this.props.history
      );
    } else {
      swal("Please Enter Quality Code and Actual Shots", { icon: "error" });
    }
  };

  // update function of ebno
  onUpdateEbNo = () => {
    var date = moment(this.state.tranDate, true, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    );
    var data = {
      companyId: localStorage.getItem("companyId"),
      date: date,
    };
    this.props.updateEbNoWorkingHrs(
      serverApi.UPDATE_EB_NO_WORKING_HOURS,
      data,
      this.props.history
    );
    // showSpinner();
  };

  handleEffiency = () => {
    var date = moment(this.state.tranDate, true, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    );
    var data = {
      companyId: localStorage.getItem("companyId"),
      date: date,
    };
    this.props.updateEbNoWorkingHrs(
      serverApi.UPDATE_PRODUCTION_EFFECIENCY,
      data,
      this.props.history
    );
    // showSpinner();
  };

  onClickUpdateMachineMapping = () => {
    var date = moment(this.state.tranDate, true, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    );
    var data = {
      date: date,
      spell: this.state.spell_name,
      companyId: localStorage.getItem("companyId"),
      userId: this.state.userDit.userId,
      wevingList: this.state.weavingList,
    };

    this.props.updateWeavingMachineMapping(
      serverApi.UPDATE_WEAVING_MACHINE_MAPPING,
      data,
      this.props.history
    );
  };

  handledeleteRecord = (row) => {
    this.props.DeleteActualShot(
      serverApi.DELETE_ACTUAL_SHOTS + row.id,
      this.props.history
    );
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div className="finishingEntriesContainer">
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormContainer">
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Date<div className="mandatoryField">*</div>
                          </label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="tranDate"
                            inputFormat="dd-MM-yyyy"
                            value={this.state.tranDate}
                            fullWidth
                            onChange={(e) =>
                              this.handleSelectDate(e, "tranDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  readOnly={true}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <InputAdormentSearch
                          imgUrl={searchIcon}
                          label="Loom No."
                          handleChange={this.handleChange("loomNo")}
                          onClick={this.onClick}
                          value={this.state.loomNo}
                          onKeyPress={this.handleKeyPress}
                          maxLength={15}
                          placeholder="Loom No."
                        />
                      </div>

                      <i>
                        {this.state.displayCutsandJugar === "block"
                          ? this.state.message
                          : ""}
                      </i>
                    </Box>
                  </Box>

                  {this.state.displayCutsandJugar === "block" ? (
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 12" className="cutsAndJugarTable">
                        <table
                          cellSpacing="0px"
                          cellPadding="0px"
                          className="formulaTable"
                        >
                          <thead>
                            <tr>
                              <th>Spell</th>
                              <th>Cuts</th>
                              <th>Jugar</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.spellList.map((prop, key) => {
                              return (
                                <tr>
                                  <td>{prop.spell}</td>

                                  <td>
                                    <>
                                      <TextFieldNormal
                                        value={prop.cuts}
                                        caps="OFF"
                                        type="number"
                                        onChange={this.onhandleChangeValue(
                                          key,
                                          "cuts"
                                        )}
                                      />
                                    </>
                                  </td>
                                  <td>
                                    <>
                                      <TextFieldNormal
                                        value={prop.jugar}
                                        caps="OFF"
                                        type="number"
                                        onChange={this.onhandleChangeValue(
                                          key,
                                          "jugar"
                                        )}
                                      />
                                    </>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                          <div className="consoleFormButtonBlock">
                            <CustomButton
                              label={"Cancel"}
                              className="greenBorderButton"
                              handleClick={this.handleCutsandJugarCancel}
                            />
                            <CustomButton
                              label={"Create"}
                              className="greenButton"
                              handleClick={this.onClickCreate}
                              type="sumbit"
                            />
                          </div>
                        </table>
                      </Box>
                      <Box gridColumn="span 3"></Box>
                    </Box>
                  ) : (
                    ""
                  )}

                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                    className="linkButtons"
                  >
                    <Box gridColumn="span 3">
                      <span onClick={this.onHandleMrData}>
                        Map Machines & Quality
                      </span>
                    </Box>
                    <Box gridColumn="span 3" onClick={this.onClickActualShot}>
                      <span>Map Actual Shots</span>
                    </Box>
                    <Box gridColumn="span 3" onClick={this.onUpdateEbNo}>
                      <span>Update EB No & Working Hours</span>
                    </Box>
                    <Box gridColumn="span 3" onClick={this.handleEffiency}>
                      <span>Calculate Production & Efficiency</span>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              {this.state.show && (
                <MrCommonPopup>
                  <div
                    className="mrPopupContainer"
                    style={{ display: this.state.dailyDisplay }}
                  >
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            arrayOfData={
                              this.state.spellContent
                                ? this.state.spellContent.map((item) => ({
                                    label: item.label,
                                    value: item.value,
                                    name: item.name,
                                  }))
                                : ""
                            }
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            isDropdownOpen={this.state.isDropdownOpen}
                            handleMenuOpen={this.handleMenuOpen}
                            selected={this.state.spell_name}
                            name="Spell"
                            label="Spell"
                            required
                          />
                        </div>
                      </Box>
                    </Box>
                  </div>
                  <div style={{ display: this.state.dailyMapping }}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box
                        gridColumn="span 12"
                        className="machineandQuantityTable"
                      >
                        <table
                          cellSpacing="0px"
                          cellPadding="0px"
                          className="formulaTable"
                        >
                          <thead>
                            <tr>
                              <th>Machine Name</th>
                              <th>Quality</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.weavingList.map((prop, key) => {
                              return (
                                <tr>
                                  <td>{prop.mcName}</td>

                                  <td>
                                    <>
                                      <DynamicSelect
                                        arrayOfData={
                                          this.state.machineQualityList
                                        }
                                        className="dropdownBlockContainer"
                                        menuPosition="fixed"
                                        menuPlacement="auto"
                                        onSelectChange={this.handleSelectChange(
                                          prop.mcId
                                        )}
                                        isDropdownOpen={
                                          this.state.isDropdownOpen
                                        }
                                        handleMenuOpen={this.handleMenuOpen}
                                        selected={prop.qCode}
                                        name="machineQualityCode"
                                      />
                                    </>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                          <div className="consoleFormButtonBlock">
                            <CustomButton
                              label={"Cancel"}
                              className="greenBorderButton"
                              handleClick={this.handleCancel}
                            />
                            <CustomButton
                              label={"Update"}
                              className="greenButton"
                              handleClick={this.onClickUpdateMachineMapping}
                              type="sumbit"
                            />
                          </div>
                        </table>
                      </Box>
                      <Box gridColumn="span 3"></Box>
                    </Box>
                  </div>
                  {this.state.dailyMapping !== "block" ? (
                    <div className="consoleFormButtonBlock">
                      <CustomButton
                        label={"Cancel"}
                        className="greenBorderButton"
                        handleClick={this.handleCancel}
                      />
                      <CustomButton
                        label={"OK"}
                        className="greenButton"
                        handleClick={this.onClickSpell}
                        type="sumbit"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </MrCommonPopup>
              )}

              {this.state.showActualShot && (
                <MrCommonPopup>
                  <div className="mrPopupContainer actualShotGrid">
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            arrayOfData={this.state.qualityCodeList}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            isDropdownOpen={this.state.isDropdownOpen}
                            handleMenuOpen={this.handleMenuOpen}
                            selected={this.state.qualityCode}
                            name="quality"
                            label="Quality Code"
                            required
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 3">
                        <TextFieldNormal
                          value={this.state.actualShots}
                          caps="OFF"
                          type="number"
                          onChange={this.handleChange("actualShots")}
                          label="Actual Shots"
                          required
                        />
                      </Box>
                    </Box>
                    <div className="consoleFormButtonBlock">
                      <CustomButton
                        label={"Cancel"}
                        className="greenBorderButton"
                        handleClick={this.handleActualshotCancel}
                      />
                      <CustomButton
                        label={"OK"}
                        className="greenButton"
                        handleClick={this.onCreateActualShots}
                        type="sumbit"
                      />
                    </div>
                    <Gridwithcustomview
                      mdata={this.state.cutsJugarList}
                      mcolumns={Columns}
                      status={true}
                      micon={GridDeleteIcon}
                      actions={false}
                      deleteIcon={true}
                      sizePerpage={5}
                      handledeleteRecord={this.handledeleteRecord}
                    />
                  </div>
                </MrCommonPopup>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Columns = [
  {
    hidden: false,
    Header: "Quality Code",
    accessor: "qualityCode",
  },
  {
    hidden: false,
    Header: "Actual Shote",
    accessor: "actualShot",
  },
  {
    hidden: false,
    Header: "Date",
    accessor: "mappingDate",
  },
  {
    hidden: false,
    Header: "Speed",
    accessor: "speed",
  },
  {
    hidden: false,
    Header: "Finished Length",
    accessor: "finishedLength",
  },
];

const mapStatetoProps = (state) => {
  const { spell } = state.AttendanceReducer;
  const { yarnTypeList } = state.DropDownListReducer;
  const { trollyDdListData } = state.WindingDoffEntryReducer;
  const { worker_List } = state.ExpenseBookingReducer;
  const { beamingProductionDdLists } = state.BeamingProductionListReducer;
  const {
    getLoomNoList,
    cutsJugarDataList,
    getCutsandJugarByLoom,
    cutsJugarListRed,
    dailyQualityList,
    loading,
    updateEbNoWrkHrsList,
  } = state.CutsAndJugarEntryReducer;
  const { SpinningQualityList } = state.SpinningQualityReducer; // fetching list from spinning quality reducer

  return {
    spell,
    yarnTypeList,
    trollyDdListData,
    worker_List,
    beamingProductionDdLists,
    getLoomNoList,
    cutsJugarDataList,
    SpinningQualityList,
    getCutsandJugarByLoom,
    cutsJugarListRed,
    dailyQualityList,
    loading,
    updateEbNoWrkHrsList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSpell,
    getYarnTypeList,
    getTrollyDdList,
    getWorkerList,
    beamingDdList,
    getLoomNo,
    getcutsandjugarByLoomNo,
    dailyQualityMapping,
    CutsAndJugarDataList,
    createActualShot,
    spinningQualityList,
    CutsJugarList,
    updateEbNoWorkingHrs,
    createCutsandJugar,
    updateWeavingMachineMapping,
    DeleteActualShot,
  })(CreateSpinningDoffEntry)
);
