import {
  JUTE_QUALITY_CHECK,
  JUTE_QUALITY_CHECK_UPDATE,
  JUTE_COMMIT_MATERIAL_READING,
  JUTE_QUALITYCHECK_VIEWBYID,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { postAdd, getListurl, getList } from "../../../helpers/Server_Helper";
import {
  JuteQualityCheckHeader,
  JuteQualityCheckSuccessfull,
  getJuteQualityCheckLineItems,
  juteCommitMaterialReading,
  juteQualityCheckViewByIdSuccessfull,
} from "./actions";
import { getJutePoQualitybyItem } from "../../../store/Global/DropDownApis/actions";

import swal from "sweetalert";
import { serverApi, serverVars } from "../../../helpers/Consts";
import { getSupplierByMukamIdList } from "../../MasterDropdownApis/actions";
import { isEmpty } from "../../../Utilities/helper";

function* JuteQualityCheckListSagaList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(JuteQualityCheckSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getJuteQualityCheckUpdate({
  payload: { url, data, history, getUrl },
}) {
  try {
    const response = yield call(postAdd, url, data);
    if (response.data.status === true) {
      if (getUrl) {
        yield put(juteCommitMaterialReading(getUrl, history));
      } else {
        swal(response.data.message, { icon: "success" });
        history.push("/jute_quality_check");
      }
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    swal("error:" + error, { icon: "danger" });
    console.log(error);
  }
}

function* getCommitMeterialReading({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/jute_quality_check");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    swal("error:" + error, { icon: "danger" });
    console.log(error);
  }
}

function* qualityCheckViewById({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (response) {
      if (response.data) {
        if (response.data.entryHeader) {
          let ApproveButton = false;
          let RejectButton = false;
          let UpdateButton = true;
          let EditButton = false;
          let entryHeader = response.data.entryHeader;
          const qualityCheckHeader = {
            id: entryHeader.id,
            chalanNo: entryHeader.chalanNo,
            chalanDate: entryHeader.chalanDate,
            vehicleNo: entryHeader.vehicleNo,
            driverName: entryHeader.driverName || "", // Assigning if exists, otherwise default empty string
            brokerName: entryHeader.brokerName || null, // Assigning if exists, otherwise default null
            transporter: entryHeader.transporter || "", // Assigning if exists, otherwise default empty string
            brokerAddress: entryHeader.brokerAddress || null, // Assigning if exists, otherwise default null
            inDate: entryHeader.inDate || "", // Assigning if exists, otherwise default empty string
            updateBy: entryHeader.updateBy || null, // Assigning if exists, otherwise default null
            inTime: entryHeader.inTime || "", // Assigning if exists, otherwise default empty string
            outTime: entryHeader.outTime || null, // Assigning if exists, otherwise default null
            outDate: entryHeader.outDate || null, // Assigning if exists, otherwise default null
            updateDateTime: entryHeader.updateDateTime || "", // Assigning if exists, otherwise default empty string
            finYear: entryHeader.finYear || "", // Assigning if exists, otherwise default empty string
            mukam: entryHeader.mukam || "",
            mukamName: entryHeader.mukamName || "", // Assigning if exists, otherwise default empty string
            poNo: entryHeader.poNo || "", // Assigning if exists, otherwise default empty string
            netWeight: entryHeader.netWeight || null, // Assigning if exists, otherwise default null
            grossWeight: entryHeader.grossWeight || 0, // Assigning if exists, otherwise default 0
            actualWeight: entryHeader.actualWeight || 0, // Assigning if exists, otherwise default 0
            mrNo: entryHeader.mrNo || 0, // Assigning if exists, otherwise default 0
            suppCode: entryHeader.suppCode || "", // Assigning if exists, otherwise default empty string
            supplierName: entryHeader.supplierName || "", // Assigning if exists, otherwise default empty string
            challanWeight: entryHeader.challanWeight || 0, // Assigning if exists, otherwise default 0
            vehicleType: entryHeader.vehicleType || 0, // Assigning if exists, otherwise default 0
            qcCheck: entryHeader.qcCheck || "", // Assigning if exists, otherwise default empty string
            createdBy: entryHeader.createdBy || "", // Assigning if exists, otherwise default empty string
            tareWeight: entryHeader.tareWeight || 0, // Assigning if exists, otherwise default 0
            remarks: entryHeader.remarks || "", // Assigning if exists, otherwise default empty string
            withorWithOutPo: entryHeader.withorWithOutPo || 0, // Assigning if exists, otherwise default 0
            companyId: entryHeader.companyId || 2, // Assigning if exists, otherwise default 2
            unitConversion: entryHeader.unitConversion || "", // Assigning if exists, otherwise default empty string
            status: entryHeader.status || 1, // Assigning if exists, otherwise default 1
            entryCompSeq: entryHeader.entryCompSeq || 0, // Assigning if exists, otherwise default 0
            consignmentDate: entryHeader.consignmentDate || null, // Assigning if exists, otherwise default null
            consignmentNo: entryHeader.consignmentNo || null, // Assigning if exists, otherwise default null
            branchId: entryHeader.branchId || 0,
            branchName: entryHeader.branchName || 0, // Assigning if exists, otherwise default 0
            poHdrId: entryHeader.poHdrId || null, // Assigning if exists, otherwise default null
            challanDateDesc: entryHeader.challanDateDesc || "", // Assigning if exists, otherwise default empty string
            createdDate: entryHeader.createdDate || "", // Assigning if exists, otherwise default empty string
            createdTime: entryHeader.createdTime || "", // Assigning if exists, otherwise default empty string
            outTimeDesc: entryHeader.outTimeDesc || "", // Assigning if exists, otherwise default empty string
            outDateDesc: entryHeader.outDateDesc || "", // Assigning if exists, otherwise default empty string
            statusDesc: entryHeader.statusDesc, // Assigning if exists, otherwise default empty string
            consignmentDateDesc: entryHeader.consignmentDateDesc, // Assigning if exists, otherwise default null
            indentHdrId: entryHeader.indentHdrId, // Assigning if exists, otherwise default null
            ApproveButton: ApproveButton,
            RejectButton: RejectButton,
            UpdateButton: UpdateButton,
            EditButton: EditButton,
          };
          if(!isEmpty(entryHeader.mukam)){
          yield put(
            getSupplierByMukamIdList(
              serverApi.GET_SUPPLIER_BY_MUKAM_ID +
              entryHeader.mukam +
                "/company/" +
                localStorage.getItem("companyId")
            )
          );
        }
          yield put(JuteQualityCheckHeader(qualityCheckHeader));
          
        }
        if (response.data.juteGateLineItems) {
          let actualJuteTyp = ""
          let lineItems = response.data.juteGateLineItems;
          let QualityCheckLineItems = [];
          lineItems.map((item, i) => {
            actualJuteTyp = item.actualJuteTyp
            QualityCheckLineItems.push({
              lineItemId: item.recId,
              recId: item.recId,
              hdrId: item.hdrId,
              advisedJuteTyp: item.advisedJuteTyp || "", // Assigning if exists, otherwise default empty string
              actualJuteTyp: item.actualJuteTyp || "", // Assigning if exists, otherwise default empty string
              advisedQuality: item.advisedQuality || "", // Assigning if exists, otherwise default empty string
              actualQuality: item.actualQuality || "",
              actualQualityDesc:item.actualQualityDesc, // Assigning if exists, otherwise default empty string
              advisedQuantity: item.advisedQuantity || "", // Assigning if exists, otherwise default empty string
              actualQuantity: item.actualQuantity || 0, // Assigning if exists, otherwise default 0
              receivedIn: item.receivedIn || "", // Assigning if exists, otherwise default empty string
              autoDateTime: item.autoDateTime || "", // Assigning if exists, otherwise default empty string
              unitId: item.unitId || "", // Assigning if exists, otherwise default empty string
              remarks: item.remarks || null, // Assigning if exists, otherwise default null
              kgs: item.kgs || null, // Assigning if exists, otherwise default null
              poLineItemNum: item.poLineItemNum || 0, // Assigning if exists, otherwise default 0
              createdBy: item.createdBy || "", // Assigning if exists, otherwise default empty string
              isActive: item.isActive || "1", // Assigning if exists, otherwise default "1"
              qcJuteType: item.qcJuteType || "", // Assigning if exists, otherwise default empty string
              qcJuteQuality: item.qcJuteQuality || "", // Assigning if exists, otherwise default empty string
              qcJuteQuantity: item.qcJuteQuantity || "", // Assigning if exists, otherwise default empty string
              advisedWeight: item.advisedWeight || null, // Assigning if exists, otherwise default null
              actualWeight: item.actualWeight || 0, // Assigning if exists, otherwise default 0
              qcJuteWeight: item.qcJuteWeight || 0, // Assigning if exists, otherwise default 0
              allowableMoisture: item.allowableMoisture || null, // Assigning if exists, otherwise default null
              itemCode:item.itemGroupId + item.actualQuality,
              itemGroupId:item.itemGroupId,
              readings:item.readings.length>0 ? item.readings.map((readingItem)=>{
                return {
                    id: readingItem.id,
                    reading: readingItem.reading,
                    readingId:readingItem.id,
                }
              }) : [
                {
                  id: 0,
                  reading: 0,
                  readingId:1,
                },
              ], // Assigning if exists, otherwise default array
              advisedQualityDesc: item.advisedQualityDesc || "", // Assigning if exists, otherwise default empty string
              itemDesc: item.itemDesc || "", // Assigning if exists, otherwise default empty string
              advisedItemDesc: item.advisedItemDesc || "", // Assigning if exists, otherwise default empty string
              avgReadings: item.avgReadings || 0, // Assigning if exists, otherwise default 0
              qcJuteTypeDesc: item.qcJuteTypeDesc || "", // Assigning if exists, otherwise default empty string
              qcJuteQualityDesc: item.qcJuteQualityDesc || "", // Assigning if exists, otherwise default empty string
            });
          });

          QualityCheckLineItems.push({
            lineItemId: 0,
            recId: 0,
            hdrId: 0,
            advisedJuteTyp: "",
            actualJuteTyp: "",
            advisedQuality: "",
            actualQuality: "",
            advisedQuantity: "",
            actualQuantity: 0,
            receivedIn: "",
            autoDateTime: "",
            unitId: "",
            remarks: null,
            kgs: null,
            poLineItemNum: 0,
            createdBy: "",
            isActive: "1",
            qcJuteType: "",
            qcJuteQuality: "",
            qcJuteQuantity: "",
            advisedWeight: null,
            actualWeight: 0,
            qcJuteWeight: 0,
            allowableMoisture: null,
            readings: [
              {
                id: 0,
                reading: 0,
                readingId:1
              },
            ],
            actualQualityDesc: "",
            advisedQualityDesc: "",
            itemDesc: "",
            advisedItemDesc: "",
            avgReadings: 0,
            qcJuteTypeDesc: "",
            qcJuteQualityDesc: "",
          });
          if(!isEmpty(actualJuteTyp)){
          let url =
            serverVars.PO_API +
            "api/v1/" +
            actualJuteTyp +
            "/" +
            localStorage.getItem("companyId") +
            "/getQualityByItem";
          yield put((getJutePoQualitybyItem(url, history)))
          yield put(getJuteQualityCheckLineItems(QualityCheckLineItems));

          }
        }
      }
      yield put(juteQualityCheckViewByIdSuccessfull({ response }));

    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchJuteQualityCheckList() {
  yield takeEvery(JUTE_QUALITY_CHECK, JuteQualityCheckListSagaList);
}

export function* watchJuteQualityCheckCreate() {
  yield takeEvery(JUTE_QUALITY_CHECK_UPDATE, getJuteQualityCheckUpdate);
}

export function* watchCommitMeterialReading() {
  yield takeEvery(JUTE_COMMIT_MATERIAL_READING, getCommitMeterialReading);
}

export function* watchJuteQualityCheck() {
  yield takeEvery(JUTE_QUALITYCHECK_VIEWBYID, qualityCheckViewById);
}

function* JuteQualityCheckSaga() {
  yield all([
    fork(watchJuteQualityCheckList),
    fork(watchJuteQualityCheckCreate),
    fork(watchCommitMeterialReading),
    fork(watchJuteQualityCheck),
  ]);
}

export default JuteQualityCheckSaga;
