import React, { Component, useEffect, useState } from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Button, Step, Stepper, StepLabel } from "@mui/material";
import CustomButton from "../../../components/Buttons/Buttons";
import Link from "@mui/material/Link";
import { serverApi } from "../../../helpers/Consts";

import JuteBillPassPreview from "./JuteBillPassPreview";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import JuteMaterialReceiptLineItems from "./JuteBillPassLineItem";
import JuteBillPassFillDetails from "./JuteBillPassFillDetals";
import {
  getMaterialGoodReceiveDetailsById,
  getMrLineItems,
  updateJuteMaterialReceipt,
} from "../../../store/Jute/JuteMaterialReceipt/actions";
import {
  JuteBillPassViewByIdAction,
  getBillPassLineItems,
  updateJuteBillpass,
} from "../../../store/Jute/JuteBillPass/actions";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../../Utilities/helper";
import { useLocation, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LineAxis } from "@mui/icons-material";
import swal from "sweetalert";
const UpdateBillPass = (props) => {

  const [currentStep, setCurrentStep] = useState(1);
  const [steps] = useState([
    {
      title: "Fill Details",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Add Items",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Preview",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
  ]);

  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [HeaderData, setHeaderData] = useState({});
  const [lineItems, setLineItems] = useState({});
  const dispatch = useDispatch();
  const [locationState] = useState(useLocation());
  let history = useHistory();

  const { JuteBillPassHeader, billpasslineItems } = useSelector(
    (state) => state.JuteBillPassReducer
  );

  const onClickNext =()=>{

    if(currentStep===1){
        setCurrentStep(currentStep + 1);
    }else{
      let validateBillPass=false;
      let FilteredData=billpasslineItems.filter(
        (item)=>item.isActive !==0
      );
      if(FilteredData.length === 1){
        FilteredData.map((item,index)=>{
          validateBillPass = true;
        });
      }else{
        for(let i=0;i<FilteredData.length-1;i++){
          validateBillPass = true;
      }
    }
if (validateBillPass === true) {
        setCurrentStep(currentStep + 1);
      }
    }
  }

  const onClickBefore = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1)
    } else {
      history.push("/jute_bill_pass");
    }
  }

  const QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { active, completed, className } = iconProp;

    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };

  const dateChanged = (date) => {
    var myDate = date;

    myDate = myDate.split("-");

    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];

    return new Date(newDate).getTime();
  };
  const onClickUpdate = (status) => {
    let invoiceRecievedDate=new Date(HeaderData.invoiceRecievedDate).getTime()
    let paymentDueDate=new Date(HeaderData.paymentDueDate).getTime()
    let invoiceDate=new Date(HeaderData.invoiceDate).getTime()
    let Lineitems = [];
    if (billpasslineItems) {
      if (billpasslineItems.length !== 0) {
        billpasslineItems.map((item) => {
          Lineitems.push({
            id: item.id,
            premiumAmount: item.premiumAmount,
            waterDamageAmount: item.waterDamageAmount,
            totalPrice: item.totalPrice,
          });
        });
      }
    }

    const data = {
      billPass: {
        billPassNo: HeaderData.billPassNo,
        invoiceNo: HeaderData.invoiceNo,
        invoiceDate:invoiceDate,
        paymentDueDate: paymentDueDate,
        invoiceAmount: HeaderData.invoiceAmount,
        invoiceRecievedDate: invoiceRecievedDate,
        claimAmount: HeaderData.claimAmount,
        netAmount: HeaderData.netAmount,
        tdsPayable: HeaderData.tdsPayable,
        tdsReason: HeaderData.tdsReason,
        branchId: HeaderData.branchId,
        tcsPercentage: HeaderData.tcsPercentage,
        tdsAmount: HeaderData.tdsAmount,
        tcsAmount: HeaderData.tcsAmount,
        roundOff: HeaderData.roundOff,
        mrNo: HeaderData.mrNo,
        id: HeaderData.billPassNo,
        status:status,
        userName: userDit.userName,
        finnacialYear: localStorage.getItem("acadamicYear"),
        userId: userDit.userId,
        companyId: localStorage.getItem("companyId"),
        cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
        approveStatus:HeaderData.approveStatus,
        agentId:HeaderData.agentId,
        agentName: HeaderData.agentName, 
      },

      billPassLineItem: Lineitems,
    };
    dispatch(updateJuteBillpass(serverApi.JUTE_BILL_PASS_UPDATE,data,props.history))
  };

  useEffect(()=>{
    setHeaderData(JuteBillPassHeader)
    setLineItems(billpasslineItems)
  },[JuteBillPassHeader,billpasslineItems]);
  
  useEffect(()=>{
    if(locationState.state !== undefined){
      if(locationState.state.rowData !== undefined){
        setCurrentStep(3);
        dispatch(JuteBillPassViewByIdAction(serverApi.JUTE_BILL_PASS_VIEWBY_ID+
           locationState.state.rowData.billPassNo+"/"+
           userDit.userId+"/"+
           userDit.cipher
         ))
      }
    }
  },[])

  const handleCurrentStep = (data) => {
    setCurrentStep(data);
  };
  return (
    <div>
      <Grid className="indentContainerBlock">
        <Grid sx={12} className="indentContainerMainBlock">
          <div className="iconsBlock">
            <Stepper alternativeLabel activeStep={currentStep}>
              {steps.map((prop) => (
                <Step key={prop.title}>
                  <StepLabel
                    StepIconComponent={(Iconprops) => {
                      return QontoStepIcon(
                        Iconprops,
                        prop.icon,
                        prop.activeIcon,
                        prop.title
                      );
                    }}
                  ></StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          {currentStep === 1 && (
            <>
              <JuteBillPassFillDetails
              />
              <div className="consoleFormButtonBlock">
                <Link>
                  <CustomButton
                    label={"Cancel"}
                    className="greenBorderButton"
                    handleClick={onClickBefore}
                  />
                </Link>
                <CustomButton
                  label={"Next"}
                  className="greenButton"
                  handleClick={onClickNext}
                  type="sumbit"
                />
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <JuteMaterialReceiptLineItems locationState={locationState}/>
              <div className="consoleFormButtonBlock">
                <Link>
                  <CustomButton
                    label={"Cancel"}
                    className="greenBorderButton"
                    handleClick={onClickBefore}
                  />
                </Link>
                <CustomButton
                  label={"Next"}
                  className="greenButton"
                  handleClick={onClickNext}
                  type="sumbit"
                />
              </div>
            </>
          )}
          {currentStep === 3 && (
            <>
              <JuteBillPassPreview
                handleCurrentStep={handleCurrentStep} 
              />
              <div className="consoleFormButtonBlock">
                <>
                 {locationState.state === undefined && ( <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={onClickBefore}
                    />
                  </Link>)}
                 
                  {locationState.state !== undefined &&
                    HeaderData.UpdateButton && (
                      <CustomButton
                        label={"Update"}
                        className="greenButton"
                        handleClick={() => onClickUpdate("")}
                        type="sumbit"
                      />
                    )}
                  {locationState.state !== undefined &&
                    HeaderData.ApproveButton && (
                      <CustomButton
                        label={"Approve"}
                        className="approved"
                        handleClick={() => onClickUpdate(HeaderData.status)}
                        type="sumbit"
                      />
                    )}
                  {locationState.state !== undefined &&
                    HeaderData.RejectButton && (
                      <CustomButton
                        label={"Reject"}
                        className="rejected"
                        handleClick={() => onClickUpdate(4)}
                        type="sumbit"
                      />
                    )}
                </>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
export default UpdateBillPass;
