import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Box, Tooltip, Fade } from "@mui/material";
import TextFieldNormal from "../TextField/TextFieldNormal";
import TextFieldGstVerify from "../TextField/TextFieldGstVerify";
import CustomButton from "../Buttons/Buttons";
import {
  getStateNameList,
  getGstVerification,
} from "../../store/Global/DropDownApis/actions";
import { createBranchMaster } from "../../store/Master/BranchMaster/actions";
import { serverApi } from "../../helpers/Consts";
import DynamicSelect from "../Dropdown/DynamicSelect";
import swal from "sweetalert";

const FormPopUpForPurchase = ({ isOpen, onClose }) => {
  const [stateList, setStateNameList] = useState([]);
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [state, setStateState] = useState("");
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));

  const [branchName, setBranchName] = useState("");
  const [branchAddress, setBranchAddress] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [branchHeadName, setBranchHeadName] = useState("");
  const [email, setEmail] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [isChecked, setChecked] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { stateNameList } = useSelector((state) => state.DropDownListReducer);
  const { gstVerificationStatus } = useSelector(
    (state) => state.DropDownListReducer
  );

  useEffect(() => {
    dispatch(getStateNameList(serverApi.GET_STATE_NAME_LIST, history));
  }, [isOpen]);

  useEffect(() => {
    if (stateNameList.data) {
      setStateNameList(stateNameList.data);
    }
  }, [stateNameList.data]);

  const initializeState = () => {
    setStateNameList([]);
    setStateValue("");
    setStateCode("");
    setBranchAddress("");
    setBranchName("");
    setBranchHeadName("");
    setCity("");
    setEmail("");
    setCity("");
    setGstNo("");
    setPinCode("");
    setContactNumber("");
    setChecked(false);
    // ... (reset other state variables)
  };

  const onClickVerify = (name) => {
    dispatch(
      getGstVerification(serverApi.GST_VERIFICATION_API + gstNo, history)
    );
  };

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const onhandlechange = (key) => (event) => {
    if (key === "branchName") {
      setBranchName(event);
    }
    if (key === "branchAddress") {
      setBranchAddress(event);
    }
    if (key === "city") {
      setCity(event);
    }
    if (key === "gstNumber") {
      setGstNo(event);
    }
    if (key === "pinCode") {
      setPinCode(event);
    }
    if (key === "Email") {
      setEmail(event);
    }
    if (key === "branchHeadName") {
      setBranchHeadName(event);
    }
    if (key === "contactNumber") {
      setContactNumber(event);
    }
  };

  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };

  const handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "state") {
      let stateDetails = selectedName.split("^");
      setStateState(stateDetails[0]);
      setStateValue(selectedValue);
      setStateCode(stateDetails[1]);
    }
  };

  const checkboxStyle = {
    width: "20px", // Increase the width of the checkbox
    height: "20px", // Increase the height of the checkbox
    color: "#000000",
  };

  const labelStyle = {
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
    fontWeight: 400,
  };

  const headerStyle = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 550,
    fontSize: "15px",
    color: "#004B73",
  };
  const buttonsHeaderStyle = {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const handleSubmit = () => {
    if (branchName === "") {
      swal("Fill in the branch name");
      return;
    }
    if (branchAddress === "") {
      swal("Fill in the branch address");
      return;
    }

    if (stateValue === "") {
      swal("Please select an state to proceed.");
      return;
    }
    if (city === "") {
      swal("Fill in the city");
      return;
    }
    if (pinCode === "") {
      swal("Please enter a valid PIN code.");
      return;
    }
    if (branchHeadName === "") {
      swal("Fill in the branch head name");
      return;
    }
    if (contactNumber === "") {
      swal("Fill in the contact number");
      // return;/
    }
    const isValidContactNumber = /^\d{10}$/.test(contactNumber);
    if (!isValidContactNumber) {
      swal("Invalid contact number");
      return;
    }

    if (email === "") {
      swal("Fill in the Email");
      return;
    }

    const data = {
      branch: {
        brname: branchName,
        address: branchAddress,
        gstNo: gstNo,
        contactNo: contactNumber,
        personName: branchHeadName,
        addressType: 1,
        personAddress: "",
        email: email,
        cityTown:city,
        stateName: stateValue,
        stateCode: stateCode,
        createdBy: userDit.userId.toString(),
        isGstVerified: gstVerificationStatus ? 1 : 0,
        futureReference: isChecked ? 1 : 0,
        isActive: 1,
      },
      postAuthenticationDto: {
        cipher: userDit.cipher,
        companyId: localStorage.getItem("companyId"),
      },
    };
    const isPOandWO = true;
    dispatch(
      createBranchMaster(
        serverApi.CREATE_BRANCH_MASTER,
        data,
        isPOandWO,
        history
      )
    );
    onClose();
    initializeState();
  };

  const handleOnCancel = () => {
    if (onClose) {
      onClose();
      initializeState();
    }
  };

  return (
    <>
      {isOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <span style={headerStyle}>Branch Details</span>
            <div style={buttonsHeaderStyle}>
              <div>
                <label style={labelStyle}>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    style={checkboxStyle}
                    onChange={handleCheckboxChange}
                  />
                  <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 100 }}
                    title="Please Fill All the Mandotary Fields"
                  >
                    <span style={{ marginLeft: "5px" }}>
                      Save for Future Reference
                    </span>
                  </Tooltip>
                </label>
              </div>
            </div>
            <div className="consoleFormBlock">
              <Grid container>
                <Grid item>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 4">
                      <TextFieldNormal
                        type="text"
                        label="Branch Name"
                        onChange={onhandlechange("branchName")}
                        value={branchName}
                        autoComplete="off"
                        required
                      />
                    </Box>
                    <Box gridColumn="span 4">
                      <TextFieldNormal
                        type="text"
                        label="Address"
                        onChange={onhandlechange("branchAddress")}
                        value={branchAddress}
                        autoComplete="off"
                        required
                      />
                    </Box>
                    <Box gridColumn="span 4">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={stateList.filter(
                            (state) => state.value !== 0
                          )}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={handlePropSelectChange}
                          isDropdownOpen={isDropdownOpen}
                          handleMenuOpen={handleMenuOpen}
                          selected={stateValue}
                          update={location.state ? 1 : 0}
                          name="state"
                          label="State"
                          required
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 4">
                      <TextFieldNormal
                        label="State Code"
                        onChange={onhandlechange("stateCode")}
                        value={stateCode}
                        disabled={true}
                        required
                        autoComplete="off"
                      />
                    </Box>
                    <Box gridColumn="span 4">
                      <TextFieldNormal
                        type="text"
                        label="City/Town/District"
                        onChange={onhandlechange("city")}
                        value={city}
                        autoComplete="off"
                        required
                      />
                    </Box>
                    <Box gridColumn="span 4">
                      <TextFieldNormal
                        type="number"
                        label="Pincode"
                        onChange={onhandlechange("pinCode")}
                        value={pinCode}
                        required
                        autoComplete="off"
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
            <div
              className="consoleFormBlock"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <Grid container>
                <Grid item>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 4">
                      <TextFieldNormal
                        label="Contact Person"
                        value={branchHeadName}
                        onChange={onhandlechange("branchHeadName")}
                        autoComplete="off"
                        required
                      />
                    </Box>
                    <Box gridColumn="span 4">
                      <TextFieldNormal
                        type="number"
                        label="Contact Number"
                        value={contactNumber}
                        onChange={onhandlechange("contactNumber")}
                        autoComplete="off"
                        required
                      />
                    </Box>
                    <Box gridColumn="span 4">
                      <TextFieldNormal
                        label="Email"
                        value={email}
                        type="email"
                        onChange={onhandlechange("Email")}
                        autoComplete="off"
                        required
                      />
                    </Box>
                    <Box gridColumn="span 4">
                      <TextFieldNormal
                        label="Address Type"
                        value="Shipping"
                        type="text"
                        onChange={onhandlechange("Email")}
                        disabled
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
            <span style={headerStyle}>GST Details</span>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 4">
                      <TextFieldGstVerify
                        label="GST Number"
                        onChange={onhandlechange("gstNumber")}
                        value={gstNo}
                        onVerify={onClickVerify}
                        isGstVerified={gstVerificationStatus}
                        // required
                        autoComplete="off"
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
            <div className="consoleFormButtonBlock">
              <CustomButton
                label={"Cancel"}
                className="greenBorderButton"
                handleClick={handleOnCancel}
              />
              <CustomButton
                label={"Save"}
                className={"greenButton"}
                type="sumbit"
                handleClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FormPopUpForPurchase;
