import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import CustomButton from "../../../components/Buttons/Buttons";
import Link from "@mui/material/Link";
import { serverApi } from "../../../helpers/Consts";
import { getBranchList } from "../../../store/Global/DropDownApis/actions";
import {
  createWarehouse,
  updateWarehouse,
} from "../../../store/Master/WareHouseMaster/actions";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import moment from "moment";
import { validate } from "../../../Utilities/Validations";
import swal from "sweetalert";

class CreateWarehouseMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      Warehouse_Name: "",
      warehouseNameTextField: "",
      type: "",
      typeValue: "",
      branchListData: [], // to displaying branch list data
      branch: "",
      branchValue: "",
      companyId: localStorage.getItem("companyId"),
      createdBy: "",
      createdOn: "",
      id: "",
      branchId: "",
      address: "",
      locationState: "",
    };
  }
  // calling create and update api
  onClickCreateUpdate = () => {
    const validateFields = [
      {
        field: this.state.warehouseNameTextField,
        msg: "Ware House Name",
        type: 1,
      },
      { field: this.state.address, msg: "Address", type: 1 },
    ];
    let validObj = validate(validateFields);
    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message}`);
      }
      if (validObj.type === 2) {
        swal(`Please Select ${validObj.message}`);
      }
    } else {
      if (this.props.location.state === undefined) {
        const data = {
          companyId: localStorage.getItem("companyId"),
          createdBy: this.state.userDit.userId.toString(),
          id: this.state.id,
          name: this.state.warehouseNameTextField,
          type:
            this.state.typeValue === "J" ? "JutewareHouse" : "StorewareHouse",
          address: this.state.address,
          branchId: this.state.branch,
        };
        this.props.createWarehouse(
          serverApi.CREATE_WAREHOUSE_MASTER,
          data,
          this.props.history
        ); // calling create warehouse api
      } else {
        const data = {
          id: this.state.id,
          createdBy: this.state.userDit.userId.toString(),
          branchId: this.props.location.state.rowData.branchId,
          address: this.state.address,
          name: this.state.warehouseNameTextField,
          type: this.state.typeValue,
          companyId: localStorage.getItem("companyId"),
          autoDateTime: moment().format(),
        };
        this.props.updateWarehouse(
          serverApi.UPDATE_WAREHOUSE_MASTER,
          data,
          this.props.history
        ); // calling update ware house api
      }
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    this.setState({
      locationState:this.props.location,
    })
    window.addEventListener("scroll", this.handleScroll);
    this.props.getBranchList(
      serverApi.BRANCH_LIST + this.state.companyId,
      this.props.history
    ); // calling branch list api
    if (this.props.location.state) {
      this.setState({
        id: this.props.location.state.rowData.id,
        createdBy: this.props.location.state.rowData.createdBy,
        branchId: this.props.location.state.rowData.branchId,
        warehouseNameTextField: this.props.location.state.rowData.name,
        typeValue:
          this.props.location.state.rowData.type === "JutewareHouse"
            ? "J"
            : "S",
        address: this.props.location.state.rowData.address,
        companyId: localStorage.getItem("companyId"),
      });
      const dateEntered = this.props.location.state.rowData.autoDateTime;
      const autoDateTime = moment(dateEntered).format("LLLL");
      this.setState({
        createdOn: autoDateTime,
      });
    }
  }
  componentWillReceiveProps(props) {
    if (props.branchList) {
      this.setState({
        branchListData: props.branchList.data, // updating branch list data
      });
    }
  }
  // function for textfields
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "warehouseName") {
      this.setState({
        warehouseNameTextField: event,
      });
    }
    if (key === "address") {
      this.setState({
        address: event,
      });
    }
  };
  // Function for dropdowns
  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "branch") {
      if (this.props.location.state) {
        this.props.location.state.rowData.branchId = selectedValue;
      } else {
        this.state.branch = selectedValue;
        this.setState({
          branch: selectedValue,
          branchId: selectedValue,
        });
      }
    }
  };
  // function for popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  // function for previous page
  onClickCancel = () => {
    this.props.history.push("/warehouse_master");
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        value={this.state.warehouseNameTextField}
                        onChange={this.onhandlechangeValue("warehouseName")}
                        label="Warehouse Name"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        value={this.state.address}
                        onChange={this.onhandlechangeValue("address")}
                        label="Address"
                        required
                        disabled={
                          this.state.warehouseNameTextField === ""
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.branchId}
                        update={this.props.location.state ? 1 : 0}
                        name="branch"
                        label="Branch"
                        isDisabled={
                          this.state.locationState.state
                            ? true
                            : false || this.state.address === ""
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <>
                      <Box gridColumn="span 6">
                        <div className="consoleUpdateTextBlock">
                          <div>
                            <span className="taxLabel">
                              Created By : <b>{this.state.createdBy}</b>
                            </span>
                          </div>
                          <div>
                            <span className="taxLabel">
                              Created On :<b> {this.state.createdOn}</b>
                            </span>
                          </div>
                        </div>
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.state.locationState.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
          {/* common popup for validation */}
          {this.state.confirm && (
            <CommonPopup>
              <div className="delAddItem">
                <div className="mandatoryFieldText">
                  Please select all the mandatory fields
                </div>
                <div className="delAddItemBtns">
                  <input
                    type="button"
                    onClick={this.onclickPopUP.bind(this)}
                    value="Ok"
                    className="delYesBtn"
                  />
                </div>
              </div>
            </CommonPopup>
          )}
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { branchList } = state.DropDownListReducer; // fetching branch list from global api
  const { Create_Warehouse, Update_Warehouse } = state.WareHouseListReducer; // fetching create and update  from warehouse list reducer
  return { branchList, Create_Warehouse, Update_Warehouse };
};
export default connect(mapStatetoProps, {
  getBranchList,
  createWarehouse,
  updateWarehouse,
})(withRouter(CreateWarehouseMaster));
