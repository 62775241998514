import { Component } from "react";
import { connect } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import { withRouter } from "react-router-dom";
import { machineMasterList } from "../../../store/Master/MachineMaster/actions";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      startIndex: "",
      lastIndex: "",
      machineMasterListData: [],
    };
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
    };
    this.props.machineMasterList(
      serverApi.GET_ALL_MACHINE_MASTER_LIST,
      data,
      this.props.history
    );
  }
  componentWillReceiveProps(props) {
    if (props.MachineMasterList) {
      this.setState({
        machineMasterListData: props.MachineMasterList.data,
      });
    }
  }
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.machineMasterListData}
          mcolumns={Columns}
          status={true}
          micon={GridArrowIcon}
          actions={true}
          url="/create_machine_master"
          expname={"Problem-MAster.csv"}
        />
      </>
    );
  }
}
// machine master header
const Columns = [
  {
    Header: "machine id",
    accessor: "machineId",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "machine Name",
    accessor: "machineName",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Department",
    accessor: "department",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "machine Type",
    accessor: "machineType",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "machine code",
    accessor: "machineShrCode",
    hidden: false,
    csvExport: true,
  },
];
const mapStatetoProps = (state) => {
  const { MachineMasterList } = state.MachineMasterReducer;
  return { MachineMasterList };
};

export default withRouter(
  connect(mapStatetoProps, {
    machineMasterList,
  })(Index)
);
