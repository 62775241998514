import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import ExportIcon from "../../../assets/images/exportIcon.png"; // Import the export icon

import { serverApi } from "../../../helpers/Consts";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import { getInvoiceList } from "../../../store/Sales/InVoice/actions";
import { ClearDeliveryOrderList } from "../../../store/Sales/DeliveryOrder/actions";
import moment from "moment";

const SalesBillsGridView = (props) => {
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [totalRecs, setTotalRecs] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(10);
  const [dataexp, setDataExp] = useState("");
  const [fromDate, setfromDate] = useState(
    moment().day(-7).format("DD-MM-YYYY")
  );
  const [toDate, settoDate] = useState(moment().format("DD-MM-YYYY"));
  const [branchId, setbranchId] = useState("");
  const [cusId, setcusId] = useState("");
  const [InvoiceType, setInvoiceType] = useState("");
  const [invoice, setinvoice] = useState("");
  const [salesOrderNo, setsalesOrderNo] = useState("");
  const [deliveryOrderNo, setDeliveryOrderNo] = useState("");
  const [status, setstatus] = useState("");
  const [incoiceListData, setInvoiceListData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const financialYearStartMonth = 3; // Assuming financial year starts in April
    let financialYearStart = moment()
      .year(localStorage.getItem("acadamicYear"))
      .month(financialYearStartMonth)
      .startOf("month");

    const fromDate = financialYearStart.day(-7).format("YYYY-MM-DD");
    const toDate = moment().format("YYYY-MM-DD");

    var startDate = moment(fromDate, "YYYY-MM-DD", true).format("DD-MM-YYYY");
    var endDate = moment(toDate, "YYYY-MM-DD", true).format("DD-MM-YYYY");
    const data = {
      fromDate: startDate,
      toDate: endDate,
      cusId: "",
      branchId: "",
      invoice: "",
      salesOrderNo: "",
      status: "3",
      companyId: localStorage.getItem("companyId"),
      acYear: localStorage.getItem("acadamicYear"),
      type: "",
      startIndex: startIndex,
      lastIndex: lastIndex,
      deliveryOrderNo: "",
    };
    const UpdatedVal = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    setDataExp(UpdatedVal);
    setfromDate(startDate);
    settoDate(endDate);
    dispatch(
      getInvoiceList(serverApi.GET_INVOICE_LIST_V2, data, props.history)
    );
  }, []);

  const { Invoice_List } = useSelector((state) => state.InvoiceReducer);

  useEffect(() => {
    if (Invoice_List) {
      if (Invoice_List.data) {
        setInvoiceListData(Invoice_List.data);
        setTotalRecs(Invoice_List.totalRecords);
      }
    }
  }, [Invoice_List]);

  const handleSearch = (data) => {
    var from_Date = moment(data.fromDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var to_Date = moment(data.toDate, "YYYY-MM-DD", true).format("DD-MM-YYYY");
    setfromDate(from_Date);
    settoDate(to_Date);
    setInvoiceType(data.type);
    setbranchId(data.branchId);
    setcusId(data.cusId);
    setinvoice(data.invoice);
    setsalesOrderNo(data.salesOrderNo);
    setstatus(data.status);
    setDeliveryOrderNo(data.deliveryOrderNo);

    const payload = {
      fromDate: from_Date,
      toDate: to_Date,
      cusId: data.cusId,
      branchId: data.branchId,
      status: data.status,
      companyId: localStorage.getItem("companyId"),
      acYear: localStorage.getItem("acadamicYear"),
      type: data.type,
      lastIndex: 10,
      startIndex: 0,
      invoice: data.invoice,
      salesOrderNo: data.salesOrderNo,
      deliveryOrderNo: data.deliveryOrderNo,
    };
    const UpdatedVal = {
      ...payload,
      startIndex: 0,
      lastIndex: 0,
    };
    setDataExp(UpdatedVal);
    dispatch(
      getInvoiceList(serverApi.GET_INVOICE_LIST_V2, payload, props.history)
    );
  };

  const handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    const data = {
      fromDate: fromDate,
      toDate: toDate,
      cusId: cusId,
      branchId: branchId,
      status: "3",
      companyId: localStorage.getItem("companyId"),
      acYear: localStorage.getItem("acadamicYear"),
      type: InvoiceType,
      invoice: invoice,
    };
    if (type === 1) {
      data.startIndex = sizePerPage;
      data.lastIndex = currentIndex;
      if (currentIndex >= 0) {
        dispatch(
          getInvoiceList(serverApi.GET_INVOICE_LIST_V2, data, props.history)
        );
      }
    } else {
      data.startIndex = currentIndex;
      data.lastIndex = sizePerPage;
      if (currentIndex >= 0) {
        dispatch(
          getInvoiceList(serverApi.GET_INVOICE_LIST_V2, data, props.history)
        );
      }
    }
    setStartIndex(currentIndex);
    setLastIndex(sizePerPage);
  };

  return (
    <>
      <PaginationGridView
        mdata={incoiceListData}
        mcolumns={Columns}
        micon={GridArrowIcon}
        status={true}
        actions={false}
        rowAction={true}
        rowIcon={ExportIcon}
        filter_form={"sales_bills"}
        totalRecs={totalRecs}
        // page={pageno}
        sizePerPage={lastIndex}
        handlePagination={handlePagination}
        handleSearch={handleSearch}
        url="/create_invoice"
        apiexp={serverApi.GET_INVOICE_LIST_V2}
        payloadexp={dataexp}
        expName={"Invoice.csv"}
        fromDate={fromDate}
        toDate={toDate}
      />
    </>
  );
};
const Columns = [
  {
    Header: "Invoice Type",
    accessor: "invoiceType",
    hidden: false,
  },
  {
    Header: "Invoice Number",
    accessor: "invoiceNumber",
    hidden: false,
  },
  {
    Header: "Customer",
    accessor: "customer",
    hidden: false,
  },
  {
    Header: "Invoice Date",
    accessor: "invoiceDate",
    hidden: false,
  },
  {
    Header: "Sales Order Number",
    accessor: "salesOrderNumber",
    hidden: true,
  },
  {
    Header: "Delivery Number",
    accessor: "deliveryNumber",
    hidden: true,
  },
  {
    Header: "Delivery Date",
    accessor: "deliveryDate",
    hidden: true,
  },
  // {
  //   Header: "Due Date",
  //   accessor: "invoiceDate",
  //   hidden: false,
  // },
  {
    Header: "Due Amount",
    accessor: "amountWithTax",
    hidden: false,
  },
  {
    Header: "Quantity",
    accessor: "quantity",
    hidden: true,
  },
  {
    Header: "Status",
    accessor: "status",
    hidden: true,
  },
];

export default SalesBillsGridView;
