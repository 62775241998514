import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import {
  MATERIAL_REQUEST_LIST,
  GET_CUSTOMER_LIST,
  GET_PROJECT_LIST,
  GET_ITEM_GROUP_BY_ID,
  GET_SR_STOCK_LIST,
  GET_SR_LIST,
  GET_MACHINE_NO_LIST,
  ADD_MATERIAL_REQUEST,
  ITEM_LIST,
  CREATE_STORE_ISSUE_LINE_ITEM,
  STORE_ISSUE_VIEW_BY_ID,
  BRANCH_WISE_STOCK_LIST,
  GETITEMSLISTFORCONSUMPTION,
  GETITEMGROUPSFORCONSUMPTION,
  GETSRNOFORCONSUMPTION,
  GETCOSTFACTORCONSUMPTION,
} from "./actionTypes";
import {
  MaterialRequestListSuccessfull,
  getCustomerListtSuccessfull,
  getProjectListtSuccessfull,
  getItemGroupByIdSuccessfull,
  getSrStockListSuccessfull,
  getSrListSuccessfull,
  getMachineNoListSuccessfull,
  addMaterialRequestSuccessfull,
  getAllItemListSuccessfull,
  storeIssueLineItemWise,
  getStoreIssueViewByIdSuccessfull,
  getBranchStockListSuccessfull,
  ItemGroupListForConsumptionSuccessfull,
  getItemsListforConsumptionSuccessfull,
  getSrNoForConsumptionSuccessfull,
  getCostFactorConsumptionSuccessfull,
} from "./actions";

import {
  getCallBacklistData,
  getList,
  getListurl,
  postCallBacklistData,
} from "../../../helpers/Server_Helper";
import swal from "sweetalert";
import { hideSpinner, showSpinner } from "../../../helpers/Consts";

function* getMaterialRequestList({ payload: { url, data, history } }) {
  try {
    showSpinner();

    const response = yield call(getList, url, data);
    if (response) {
      yield put(MaterialRequestListSuccessfull({ response }));
      hideSpinner();
    }
  } catch (error) {
    console.log(error);
  }
}

function* storeIssueViewByIdSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    if (response) {
      var LineItemData = response;
      var object = [];
      var addItem = 1;
      if (LineItemData) {
        LineItemData.map((row) => {
          var obj = {
            id: row.id,
            no: 0,
            lineitemId: row.id,
            status: row.status,
            hdrId: row.hdrId,
            deptId: row.deptId,
            itemGroupId: row.groupCode,
            itemId: row.itemId,
            srNo: row.materialInventoryId,
            quantity: row.quantity,
            costfactor: row.deptCost,
            newIssueValue: 0,
            itemGroupList: [],
            itemsList: [],
            srList: [],
            departmentList: [],
            machineNo: row.machineId,
            machineName: row.machineName,
            centralStock: 0,
            originalQty: row.quantity,
            branchStock: 0,
            srStock: 0,
            srStockName: "",
            issueValue: row.issueValue,
            rate: row.itemRate,
            itemName: row.itemDesc,
            departmentName: row.deptDesc,
            itemGroupName: row.groupDesc,
            uom: row.uomCode,
            costFactorName: row.costDesc,
            srPrintNo: row.srPrintNo,
            inwardId: row.inwardId,
            itemCode: row.itemCode,
            expense_name: row.indentTypeDesc,
            approveButton: row.approveButton,
            deleteStatus: row.deleteStatus === null ? false : row.deleteStatus,
            issueDate: row.issueDate,
            branchId: row.branchId,
            internalNote: row.internalNote,
            projectId: row.projectId,
            customerId: row.customerId,

            // Reverting Discount Type in Consumption

            // discountType: row.discountMode,
            // discount: row.discount
          };
          object.push(obj);
          addItem = Number(row.id);
        });
        let viewByIdLineItem = object;
        viewByIdLineItem.push({
          id: "0",
          no: 0,
          lineitemId: Number(addItem) + 1,
          hdrId: "",
          itemGroupList: [],
          newIssueValue: 0,
          itemsList: [],
          srList: [],
          departmentList: [],
          deptId: 0,
          itemGroupId: 0,
          itemId: 0,
          srNo: "",
          quantity: 0,
          costfactor: 0,
          machineNo: 0,
          centralStock: 0,
          branchStock: 0,
          srStock: 0,
          issueValue: 0,
          itemName: "",
          departmentName: "",
          itemGroupName: "",
          uom: "",
          costFactorName: "",
          srPrintNo: "",
          inwardId: 0,
          itemCode: "",
          expense_name: "",
          issueDate: "",
          deleteStatus: false
          // branchId:""
        });

        yield put(storeIssueLineItemWise(viewByIdLineItem));
      }
    }
    yield put(getStoreIssueViewByIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getAllCustomerListById({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getCustomerListtSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getAllProjectListById({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getProjectListtSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getAllItemGroupList({ payload: { url, rowId, history } }) {
  try {
    const response = yield call(getListurl, url, rowId);
    yield put(getItemGroupByIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

//Call Back

function* ItemsListforConsumption({
  payload: { url, data, callback, itemgrpId },
}) {
  try {
    const response = yield call(
      postCallBacklistData,
      url,
      data,
      callback,
      itemgrpId
    );
    if (response) {
      yield put(getItemsListforConsumptionSuccessfull({ response }));
    }
  } catch (error) {
    if (error.response) {
      hideSpinner();
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
      }
    }
  }
}

function* ItemsGroupListforConsumption({
  payload: { url, history, callback, Id },
}) {
  try {
    const response = yield call(
      getCallBacklistData,
      url,
      history,
      callback,
      Id
    );
    if (response) {
      yield put(ItemGroupListForConsumptionSuccessfull({ response }));
    }
  } catch (error) {
    if (error.response) {
      hideSpinner();
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
      }
    }
  }
}

function* SrNoforConsumption({ payload: { url, data, callback, itemgrpId } }) {
  try {
    const response = yield call(
      postCallBacklistData,
      url,
      data,
      callback,
      itemgrpId
    );
    if (response) {
      yield put(getSrNoForConsumptionSuccessfull({ response }));
    }
  } catch (error) {
    if (error.response) {
      hideSpinner();
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
      }
    }
  }
}

function* costFactorConsumption({
  payload: { url, history, callback, deptId },
}) {
  try {
    const response = yield call(
      getCallBacklistData,
      url,
      history,
      callback,
      deptId
    );
    if (response) {
      yield put(getCostFactorConsumptionSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      hideSpinner();
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

// -----

function* getStockList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(getSrStockListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getBranchStockListSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(getBranchStockListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getAllSrList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(getSrListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getAllMachineNoList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getMachineNoListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* addMaterial({ payload: { url, data, history } }) {
  try {
    showSpinner();

    const response = yield call(getList, url, data);
    if (response) {
      yield put(addMaterialRequestSuccessfull({ response }));
      hideSpinner();
    }
    swal(response.message, { icon: "success" });
    if (history.location.pathname === "/create_consumption") {
      history.push("/store_issue");
    } else {
      history.push("/material_request");
    }
  } catch (error) {
    console.log(error);
  }
}

function* createStoreIssueLine({ payload: { url, data, history, products } }) {
  try {
    const response = yield call(getList, url, data);
    if (response) {
      if (products) {
        var sagaproducts = products.filter((item) => {
          if (item.lineitemId === data.lineItemId) {
            item.id = response.data.id;
            item.hdrId = response.data.hdrId;
          }
          return item;
        });
        yield put(storeIssueLineItemWise(sagaproducts));
      }
    }

    swal(response.message, { icon: "success" });
  } catch (error) {
    console.log(error);
  }
}

function* getTheItemList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(getAllItemListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

export function* watchMaterialRequestList() {
  yield takeEvery(MATERIAL_REQUEST_LIST, getMaterialRequestList);
}

export function* watchAllCustomerList() {
  yield takeEvery(GET_CUSTOMER_LIST, getAllCustomerListById);
}

export function* watchAllProjectList() {
  yield takeEvery(GET_PROJECT_LIST, getAllProjectListById);
}

export function* watchAllItemGroupList() {
  yield takeEvery(GET_ITEM_GROUP_BY_ID, getAllItemGroupList);
}

export function* watchAllSrStockList() {
  yield takeEvery(GET_SR_STOCK_LIST, getStockList);
}

export function* watchAllBranchStockList() {
  yield takeEvery(BRANCH_WISE_STOCK_LIST, getBranchStockListSaga);
}

export function* watchAllSrList() {
  yield takeEvery(GET_SR_LIST, getAllSrList);
}

export function* watchAllMachineNoList() {
  yield takeEvery(GET_MACHINE_NO_LIST, getAllMachineNoList);
}

export function* watchAddMaterial() {
  yield takeEvery(ADD_MATERIAL_REQUEST, addMaterial);
}

export function* watchItemList() {
  yield takeEvery(ITEM_LIST, getTheItemList);
}

export function* watchCreateStoreIssue() {
  yield takeEvery(CREATE_STORE_ISSUE_LINE_ITEM, createStoreIssueLine);
}

export function* watchStoreViewById() {
  yield takeEvery(STORE_ISSUE_VIEW_BY_ID, storeIssueViewByIdSaga);
}

export function* watchItemsListForConsumption() {
  yield takeEvery(GETITEMSLISTFORCONSUMPTION, ItemsListforConsumption);
}
export function* watchItemGroupListForConsumption() {
  yield takeEvery(GETITEMGROUPSFORCONSUMPTION, ItemsGroupListforConsumption);
}

export function* watchSrNoForConsumption() {
  yield takeEvery(GETSRNOFORCONSUMPTION, SrNoforConsumption);
}

export function* watchcostFactorConsumption() {
  yield takeEvery(GETCOSTFACTORCONSUMPTION, costFactorConsumption);
}

function* MaterialRequestSaga() {
  yield all([
    fork(watchMaterialRequestList),
    fork(watchAllCustomerList),
    fork(watchAllProjectList),
    fork(watchAllItemGroupList),
    fork(watchAllSrStockList),
    fork(watchAllSrList),
    fork(watchAllMachineNoList),
    fork(watchAddMaterial),
    fork(watchItemList),
    fork(watchCreateStoreIssue),
    fork(watchStoreViewById),
    fork(watchAllBranchStockList),
    fork(watchItemsListForConsumption),
    fork(watchItemGroupListForConsumption),
    fork(watchSrNoForConsumption),
    fork(watchcostFactorConsumption),
  ]);
}

export default MaterialRequestSaga;
