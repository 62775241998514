import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// eslint-disable-next-line react/no-multi-comp
class Radio extends React.Component {
  handlechange = (event) => {
    const { value } = event.target;
    this.props.onChange(value);
  };
  render() {
    const { value, labelText, checkednew, name, disabled } = this.props;

    return (
      <div className="radioContainer">
        <div>
          <Root>
            <Input
              type="radio"
              onChange={this.handlechange}
              name={name}
              value={value}
              checked={checkednew}
              aria-checked={checkednew}
              disabled={disabled}
              labelText={this.props.labelText}
            />
            <Fill />
          </Root>
        </div>
        <label style={{ display: "flex" }}>{labelText}</label>
      </div>
    );
  }
}

Radio.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  labelText: PropTypes.string,
};

Radio.defaultProps = {
  onChange: () => {},
  value: "",
  labelText: "",
};

const Root = styled.div`
  margin: 5px;
  cursor: pointer;
  width: ${(props) => (props.size ? props.size : 20)}px;
  height: ${(props) => (props.size ? props.size : 20)}px;
  position: relative;
  label {
    margin-left: 25px;
  }
  &::before {
    content: "";
    border-radius: 100%;
    border: 1px solid
      ${(props) => (props.borderColor ? props.borderColor : "#DDD")};
    background: ${(props) =>
      props.backgroundColor ? props.backgroundColor : "#FAFAFA"};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
  }
`;

const Fill = styled.div`
  width: 0;
  height: 0;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease-in, height 0.2s ease-in;
  pointer-events: none;
  z-index: 1;

  &::before {
    content: "";
    opacity: 0;
    width: calc(10px - 4px);
    position: absolute;
    height: calc(10px - 4px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${(props) => (props.fillColor ? props.fillColor : "#ffffff")};
    border: 7px solid
      ${(props) => (props.borderActive ? props.borderActive : "#003f66")};
    border-radius: 100%;
  }
`;

const Input = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${Fill} {
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      transition: width 0.2s ease-out, height 0.2s ease-out;

      &::before {
        opacity: 1;
        transition: opacity 1s ease;
      }
    }
  }
`;

export default Radio;
