//import { Switch } from "react-router-dom";

import {
    API_ERRORS,
    STOCK_RETURN_LIST,
    STOCK_RETURN_LIST_SUCCESSFULL,
  } from "./actionTypes";
  
  const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    StockReturn_List: [],
    error_msg: null,
  };
  
  const StockReturnReducer = (state = initialState, action) => {
    switch (action.type) {
      case STOCK_RETURN_LIST:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case STOCK_RETURN_LIST_SUCCESSFULL:
        state = {
          ...state,
          StockReturn_List: action.payload.response,
          loading: false,
        };
        break;
        case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
      default:
        state = { ...state };
        break;
    }
  
    return state;
  };
  
  export default StockReturnReducer;
  