import React, { Component } from "react";
import { Grid, Box, Button, Tooltip, IconButton } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi, serverConfig, getTenantId } from "../../../helpers/Consts";
import TextArea from "../../../components/TextField/TextArea";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { getItemsListForIndent } from "../../../store/Global/DropDownApis/actions";
import { InwardLineItemsProps } from "../../../store/Purchase/Inward/actions";
import {
  getDepartmentList,
  allItemGroupMasterList,
  getParentBudgetHeads,
} from "../../../store/Global/DropDownApis/actions";
import PrintIcon from "@mui/icons-material/Print";
import Fade from "@mui/material/Fade";
import CustomButton from "../../../components/Buttons/Buttons";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { getFileDownload } from "../../../helpers/server.js";
import {
  getPendingStockPoandReceipt,
  getPendingStock,
  actions,
  logs,
  getFiles,
  getInvoiceFilesSuccessfull,
  getChallanFilesSuccessfull,
  getSuppdocsFilesSuccessfull,
} from "../../../store/Purchase/Indent/actions";
import {
  postUploadFileList,
  deleteFile,
  UploadInvoiceFileList,
  UploadsuppdocsFileList,
  UploadchallanFileList,
} from "../../../store/MyProfile/UploadFile/actions";
import { getwareHouseDataList } from "../../../store/StoreModule/StockTransfer/actions";
import { getTax_List } from "../../../store/Global/DropDownApis/actions";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import DeleteIcons from "../../../assets/images/picEditIcon.png";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import swal from "sweetalert";
import { ledgerMasterList } from "../../../store/Master/LedgerMaster/actions.js";
import { limitDecimals } from "../../../Utilities/helper.js";
import { isEmpty } from "../../../Utilities/helper";

class BillPassLineItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      rowCount: 0,
      amount: 0,
      itemsList: [],
      taxList: [],
      files_List: [],
      supportDocsList: [],
      challandocsList: [],
      invoicedocsList: [],
      invoiceFilesList: [],
      ledgerMasterListData: [],
      fileName: "",
      fileType: "",
      lineItemId: 1,
      delete: false,
      deletedId: "",
      Inward_LineItems: this.props.Inward_LineItems,
      Logs: [],
      DepartmentList: [],
      termsNconditions: [],
      ItemGroupMasterList: [],
      parentBudgetHeadsList: [],
      wareHouseList: [],
      internalNote: this.props.InwardFillDetails.internalNote,
      igstPercentage: 0,
      cgstPercentage: 0,
      sgstPercentage: 0,
      paymentDueDate: this.props.InwardFillDetails.paymentDueDate,
      invoiceDate: this.props.InwardFillDetails.invoiceDate,
      invoiceNumber: this.props.InwardFillDetails.invoiceNumber,
      invoiceAmount: this.props.InwardFillDetails.invoiceAmount,
      invoiceRecvdDate: this.props.InwardFillDetails.invoiceRecvdDate,
      tcsPercentage: this.props.InwardFillDetails.tcsPercentage,
      tcsPercentageValue: this.props.InwardFillDetails.tcsPercentageValue,
      tcsAmount: this.props.InwardFillDetails.tcsAmount,
      warehouseno: this.props.InwardFillDetails.warehouseno,
      ledger: this.props.InwardFillDetails.ledger,
      ledgerValue: this.props.InwardFillDetails.ledgerValue,
      tdsPayable: this.props.InwardFillDetails.tdsPayable,
      tdsReason: this.props.InwardFillDetails.tdsReason,
      tdsAmount: this.props.InwardFillDetails.tdsAmount,
      checkRoundOffValue: this.props.InwardFillDetails.checkRoundOffValue,
      tdsfields: false,
      fileTypeName: "",
      locationState: "",
      gstInvoiceType: this.props.InwardFillDetails.gstInvoiceType,
      itcApplicable: this.props.InwardFillDetails.itcApplicable,
      tcsTds: this.props.InwardFillDetails.tcsTds,
      tdsPercentage: this.props.InwardFillDetails.tdsPercentage,
      tdsPercentageValue: this.props.InwardFillDetails.tdsPercentage,
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.setState({
      locationState: this.props.locationState,
    });

    const data = {
      branchId: "", //this.props.headerData.branchId,
      companyId: localStorage.getItem("companyId"),
      type: "S",
    };
    this.props.getwareHouseDataList(
      serverApi.WARE_HOUSE_LIST,
      data,
      this.props.history
    );

    this.props.getTax_List(
      serverApi.GET_ALL_TAXLIST +
        localStorage.getItem("companyId") +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    );
    // {/* Commentted this for the future reference */}
    //  api calling for ledger
    // const data1 = {
    //   cipher: this.state.userDit.cipher,
    //   companyId: localStorage.getItem("companyId"),
    // };
    // this.props.ledgerMasterList(
    //   serverApi.GET_ALL_LEDGER_MASTER_LIST,
    //   data1,
    //   this.props.history
    // );

    if (
      this.props.Inward_LineItems &&
      this.props.Inward_LineItems === undefined
    ) {
      let Inward_LineItems = [
        {
          lineitemId: 1,
          departmentId: "",
          itemId: "",
          itemGroupId: "",
          rate: 0,
          budgetHeadId: "",
          inwardQty: 0,
          uomCode: "",
          description: "",
          poId: "",
          poDetailsId: "",
          oldQty: 0,
          hdrStatus: 1,
          isActive: 1,
          tax: "",
          igstPercentage: "",
          cgstPercentage: "",
          sgstPercentage: "",
          hsnCode: "",
          taxPercentage: "",
          amount: 0,
        },
      ];
      this.props.InwardLineItemsProps(Inward_LineItems);
    }

    if (this.props.Upload_File_List) {
      if (this.props.location.state) {
        let id = this.props.location.state.rowData.id;
        this.props.getFiles(
          serverApi.GET_FILES +
            "18/" +
            id +
            "/" +
            localStorage.getItem("companyId"),
          this.props.history,
          "18"
        );
        this.props.getFiles(
          serverApi.GET_FILES +
            "2/" +
            id +
            "/" +
            localStorage.getItem("companyId"),
          this.props.history,
          "2"
        );
        this.props.getFiles(
          serverApi.GET_FILES +
            "3/" +
            id +
            "/" +
            localStorage.getItem("companyId"),
          this.props.history,
          "3"
        );
      }
    }
  }

  componentWillReceiveProps(props) {
    // {/* Commentted this for the future reference */}
    // var ledgerList = [];

    // if (props.LedgerMasterList.data !== undefined) {
    //   props.LedgerMasterList.data.map((prop) => {
    //     ledgerList.push({
    //       value: prop.id,
    //       label: prop.ledgerName,
    //       name: prop.ledgerName,
    //     });
    //   });
    // }

    // this.setState({
    //   ledgerMasterListData: ledgerList,
    // });

    if (props.taxList) {
      this.setState({
        taxList: props.taxList.data,
      });
    }
    if (props.wareHouseList) {
      var listdept = [];
      listdept = props.wareHouseList.filter((item) => {
        if (item.value !== 0) {
          return item;
        }
      });

      this.setState({
        wareHouseList: listdept,
      });
    }
    if (props.Inward_LineItems) {
      this.setState({
        Inward_LineItems: props.Inward_LineItems,
      });
    }

    if (this.props.InwardFillDetails) {
      let taxType = this.props.InwardFillDetails.taxType;
      if (taxType === 1 || taxType === 3) {
        this.setState({
          igstPercentage: 1,
          cgstPercentage: 0,
          sgstPercentage: 0,
        });
      } else {
        this.setState({
          igstPercentage: 0,
          cgstPercentage: 1,
          sgstPercentage: 1,
        });
      }
    }

    if (props.filesofSuppdocs) {
      if (props.filesofSuppdocs.data) {
        let filesList = props.filesofSuppdocs.data;
        this.setState({
          supportDocsList: filesList.data,
        });
      }
    }
    if (props.filesofInvoice) {
      if (props.filesofInvoice.data) {
        let filesList = props.filesofInvoice.data;
        this.setState({
          invoicedocsList: filesList.data,
        });
      }
    }
    if (props.filesofChallan) {
      if (props.filesofChallan.data) {
        let filesList = props.filesofChallan.data;
        this.setState({
          challandocsList: filesList.data,
        });
      }
    }

    if (this.state.fileName !== "") {
      if (this.state.fileTypeName === "SuppDocs") {
        if (props.Uploaded_suppdocsFiles) {
          if (props.Uploaded_suppdocsFiles.status) {
            var Filedata = props.Uploaded_suppdocsFiles.data;
            if (this.state.supportDocsList !== undefined) {
              var arr = this.state.supportDocsList;
              Filedata = {
                fileUploadId: Filedata.id,
                fileName: this.state.fileName,
                fileExtension: this.state.fileType,
                retrievalPath: Filedata.fileUrl,
                sourceMenu: 18,
              };
              arr.splice(arr.length, 0, Filedata);
              this.setState({
                supportDocsList: arr,
              });
            }
          }
        }
      }
      if (this.state.fileTypeName === "invoices") {
        if (props.Uploaded_invoiceList) {
          if (props.Uploaded_invoiceList.status) {
            let Filedata = props.Uploaded_invoiceList.data;
            if (this.state.invoicedocsList !== undefined) {
              let arr = this.state.invoicedocsList;
              Filedata = {
                fileUploadId: Filedata.id,
                fileName: this.state.fileName,
                fileExtension: this.state.fileType,
                retrievalPath: Filedata.fileUrl,
                sourceMenu: 2,
              };
              arr.splice(arr.length, 0, Filedata);
              // this.setState({
              //   invoicedocsList: arr,
              // });
            }
          }
        }
      }
      if (this.state.fileTypeName === "Challan") {
        if (props.Uploaded_challanFiles) {
          if (props.Uploaded_challanFiles.status) {
            let Filedata = props.Uploaded_challanFiles.data;

            if (this.state.challandocsList !== undefined) {
              let arr = this.state.challandocsList;
              Filedata = {
                fileUploadId: Filedata.id,
                fileName: this.state.fileName,
                fileExtension: this.state.fileType,
                retrievalPath: Filedata.fileUrl,
                sourceMenu: 3,
              };
              arr.splice(arr.length, 0, Filedata);
              this.setState({
                challandocsList: arr,
              });
            }
          }
        }
      }
    }
    if (props.logs_List) {
      this.setState({
        Logs: props.logs_List.data,
      });
    }

    if (props.InwardFillDetails) {
      this.setState({
        tdsPayable: props.InwardFillDetails.tdsPayable,
        tdsfields: props.InwardFillDetails.tdsPayable === "Y" ? true : false,
        tdsReason: props.InwardFillDetails.tdsReason,
      });
    }
  }

  handleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    this.setState({ [key]: event });
    if (key === "qty") {
      row.inwardQty = Number(value);
      row.amount = Number(row.rate) * Number(row.inwardQty);
    }
    if (key === "Rate") {
      row.rate = Number(value);
      row.amount = Number(row.rate) * Number(row.inwardQty);
    }

    // }
    // });
  };

  onClickToSeeLogs = () => {
    if (this.props.location.state) {
      let id = this.props.location.state.rowData.id;
      this.props.logs(serverApi.SEE_THE_LOGSOF_INWARD + id, this.props.history);
    }
  };

  handleChangetextAreas = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "internalNote") {
      this.props.InwardFillDetails.internalNote = event;
      this.setState({
        internalNote: event,
      });
    }
  };
  handleChangedropdown = (selectedValue, selectedName, name, selectedRow) => {
    if (name === "Tax") {
      selectedRow.tax = selectedValue;
      let taxPercentage = selectedName.split("^");
      selectedRow.taxPercentage = taxPercentage[1];
      selectedRow.cgstPercentage = Number(taxPercentage[1] / 2);
      selectedRow.sgstPercentage = Number(taxPercentage[1] / 2);
      if (this.props.InwardFillDetails.taxType === 1) {
        selectedRow.igstPercentage = Number(taxPercentage[1]);
      }
    }

    if (name === "StoreNo") {
      let Inward_LineItems = this.state.Inward_LineItems.filter((data) => {
        if (data.id === selectedRow.id) {
          selectedRow.warehouseno = selectedValue;
          this.props.InwardFillDetails.warehouseno = selectedValue;
        }
        return data;
      });
      this.setState({
        Inward_LineItems: Inward_LineItems,
      });
      this.props.InwardLineItemsProps(Inward_LineItems);
    }
  };
  onhandleChangeDropdown = (selectedValue, selectedName, name) => {
    if (name === "TcsPercentage") {
      this.setState({
        tcsPercentage: selectedName,
        tcsPercentageValue: selectedValue,
      });

      this.props.InwardFillDetails.tcsPercentage = selectedName;
      this.props.InwardFillDetails.tcsPercentageValue = selectedValue;
    }
    // {/* Commentted this for the future reference */}
    // if (name === "ledger") {
    //   this.setState({
    //     ledger: selectedName,
    //     ledgerValue: selectedValue,
    //   });
    //   this.props.InwardFillDetails.ledger = selectedName;
    //   this.props.InwardFillDetails.ledgerValue = selectedValue;
    // }

    if (name === "tdsPayable") {
      if (selectedName === "Yes") {
        this.setState({
          tdsPayable: selectedValue,
          tdsfields: true,
        });
        this.props.InwardFillDetails.tdsPayable = selectedValue;
      } else {
        this.setState({
          tdsPayable: selectedValue,
          tdsfields: false,
          tdsReason: "",
          tdsAmount: 0,
        });
        this.props.InwardFillDetails.tdsPayable = selectedValue;
        this.props.InwardFillDetails.tdsReason = "";
        // this.props.InwardFillDetails.tdsAmount = 0;
      }
    }

    if (name === "gstInvoiceType") {
      this.setState({
        gstInvoiceType: selectedValue,
      });
      this.props.InwardFillDetails.gstInvoiceType = selectedValue;
    }

    if (name === "itcApplicable") {
      this.setState({
        itcApplicable: selectedValue,
      });
      this.props.InwardFillDetails.itcApplicable = selectedValue;
    }
  };

  onDelEvent = (event) => {
    this.setState({ delete: true, deletedId: event.target.name });
  };

  onDel = (event) => {
    this.setState({ delete: false });
    if (event.target.value === "Yes") {
      if (this.state.Inward_LineItems.length > 1) {
        let lineItemId = this.state.deletedId;
        var object = this.state.Inward_LineItems.filter((row) => {
          if (row.lineitemId != lineItemId) {
            return row;
          }
        });

        this.setState({
          Inward_LineItems: object,
          lineItemId: lineItemId,
        });
        this.props.InwardLineItemsProps(object);
      }
    }
  };

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "termsNconditions") {
      this.props.InwardFillDetails.termsNconditions = selectedName;
      this.props.InwardFillDetails.selectTermsNconditions = selectedName;
      this.setState({
        termsNconditions: selectedName,
        selectTermsNconditions: selectedName,
      });
    }
  };

  onhandleChange = (key) => (value) => {
    this.setState({ [key]: value });

    if (key === "tdsReason") {
      this.props.InwardFillDetails.tdsReason = value;
      this.setState({
        AdvancePercentage: value,
      });
    }
    if (key === "tdsAmount") {
      if (/^\d*\.?\d{0,2}$/.test(value)) {
        this.props.InwardFillDetails.tdsAmount = value;
        this.setState({
          tdsAmount: value,
        });
      } else {
        swal("please enter upto 2 decimal");
        this.props.InwardFillDetails.tdsAmount = this.state.tdsAmount;
        this.setState({
          tdsAmount: this.state.tdsAmount,
        });
      }
    }
    if (key === "invoiceAmount") {
      this.props.InwardFillDetails.invoiceAmount = value;
      this.setState({
        invoiceAmount: value,
      });
    }
    if (key === "invoiceNumber") {
      this.props.InwardFillDetails.invoiceNumber = value;
      this.setState({
        invoiceNumber: value,
      });
    }
  };
  UploadDocuments = (key) => (e) => {
    if (this.props.locationState.state) {
      var inwardId = this.props.locationState.state.rowData.id;
    }
    e.preventDefault();
    let sourceMenu = 0;
    if (key === "SuppDocs") {
      sourceMenu = 18;
    } else if (key === "invoices") {
      sourceMenu = 2;
    } else {
      sourceMenu = 3;
    }
    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", sourceMenu);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", inwardId);
    formData.append("createdBy", this.state.userDit.userId);
    formData.append("fileCompanyId", localStorage.getItem("companyId"));
    this.setState({
      fileName: file.name,
      fileType: fileExt,
      fileTypeName: key,
    });
    let reqObj = {id:this.props.location.state.rowData.id,companyId:localStorage.getItem("companyId"),}
    if (sourceMenu === 2) {
      this.props.UploadInvoiceFileList(
        serverApi.UPLOADFILE,
        formData,
        this.props.history,
        sourceMenu,
        reqObj
      );
    }
    if (sourceMenu === 18) {
      this.props.UploadsuppdocsFileList(
        serverApi.UPLOADFILE,
        formData,
        this.props.history,
        sourceMenu
      );
    }
    if (sourceMenu === 3) {
      this.props.UploadchallanFileList(
        serverApi.UPLOADFILE,
        formData,
        this.props.history,
        sourceMenu
      );
    }
  };

  onDeleteFile = (fileId, type) => {
    if (type === "suppdocs") {
      let data = this.state.supportDocsList.filter(
        (item) => item.fileUploadId !== fileId
      );
      this.props.deleteFile(serverApi.DELETEFILE + fileId);
      this.setState({
        supportDocsList: data,
      });
    }
    if (type === "Challan") {
      let data = this.state.challandocsList.filter(
        (item) => item.fileUploadId !== fileId
      );
      this.props.deleteFile(serverApi.DELETEFILE + fileId);
      this.setState({
        challandocsList: data,
      });
      this.props.getChallanFilesSuccessfull({ data });
    }
    if (type === "invoices") {
      let data = this.state.invoicedocsList.filter(
        (item) => item.fileUploadId !== fileId
      );
      this.props.deleteFile(serverApi.DELETEFILE + fileId);
      this.setState({
        invoicedocsList: data,
      });
      this.props.getInvoiceFilesSuccessfull({ data });
    }
  };

  handleSelect_Date = (e, name) => {
    if (name === "paymentDueDate") {
      let date = moment(e).format();
      let fromdate = date.split("T", 1).toString();
      this.setState({
        paymentDueDate: fromdate,
      });
      this.props.InwardFillDetails.paymentDueDate = fromdate;
    }

    if (name === "invoiceRecvdDate") {
      let date = moment(e).format();
      let fromdate = date.split("T", 1).toString();
      this.setState({
        invoiceRecvdDate: fromdate,
      });
      this.props.InwardFillDetails.invoiceRecvdDate = fromdate;
    }
    if (name === "invoiceDate") {
      let date = moment(e).format();
      let fromdate = date.split("T", 1).toString();
      this.setState({
        invoiceDate: fromdate,
      });
        this.props.InwardFillDetails.invoiceDate = fromdate
    }
  };


  handlePdf = () => {
    let id = 0;
    if (this.props.location.state) {
      if (this.props.location.state.rowData) {
        id = this.props.location.state.rowData.id;
      }
    }
    // this.props.getFileDownload(serverApi.DOWNLOAD_PDF+indentId+'/'+this.state.userDit.userId)

    fetch(serverApi.DOWNLOAD_BPLI_PDF + id + "/" + this.state.userDit.userId, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        "X-TenantId": getTenantId(window.location.hostname),
        Authorization:
          localStorage.getItem("token_type") +
          localStorage.getItem("access_token"),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          this.props.InwardFillDetails.billPassNo + ".pdf"
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    let DepartmentList = this.state.departmentList;
    let AllItemGroupMasterList = this.state.AllItemGroupMasterList;
    var totalAmount = 0;
    var totalIgst = 0;
    var totalCgst = 0;
    var totalSgst = 0;
    var tcsAmount = 0;
    var IndividualAmount = 0;
    var totalCgstValue = 0;

    var debitnoteBtnShow = false;
    if (this.state.Inward_LineItems) {
      var filteredproducts = this.state.Inward_LineItems.filter(
        (item) => item.departmentId !== "" && item.isActive !== 0
      );

      filteredproducts.forEach((data) => {
        if (data.approvedQty && data.rate) {
          totalAmount =
            totalAmount +
            (data.approvedQty * data.rate);
            if (Number(data.discountMode !== null) && data.discountMode !== "" ) {
            let amount =
              Number(data.approvedQty) * Number(data.rate);
              if (Number(data.discountMode) ===1 ){
              if (
                Number(data.discountPercentage) !== 0 &&
                Number(data.discountPercentage) !== undefined &&
                Number(data.discountPercentage) !== "" &&
                Number(data.discountPercentage) !== ""
              ) {

                IndividualAmount = amount - Number( data.discountPercentage)
                totalAmount = totalAmount - Number( data.discountPercentage);
               
              }
            } else if (data.discountMode === 2){
              if (
                Number(data.discountPercentage) !== 0 &&
                Number(data.discountPercentage)  !== undefined &&
                Number(data.discountPercentage)  !== "" &&
                Number(data.discountPercentage)  !== null

              ) {
                let disAmount = (amount * Number(data.discountPercentage)) / 100;
                IndividualAmount = amount - disAmount
                totalAmount = totalAmount - disAmount;
                
              }
            }
          }
         
        }

        if (Number(this.props.InwardFillDetails.taxType) === 3) {
          totalIgst = 0;
          totalCgst = 0;
          totalSgst = 0;
        } else {
          if (this.props.InwardFillDetails.taxType === 1) {
            let amountWithDiscount = 0
            if (data.discountMode !== undefined && data.discountMode !== "" && data.discountMode !== null) {
              if (data.discountMode === 1) {
                amountWithDiscount =
                  (data.approvedQty * data.rate) -
                  Number(data.discountPercentage);
                totalIgst = totalIgst + ((amountWithDiscount) * data.taxPercentage) / 100;
              } else if (data.discountMode === 2) {
                amountWithDiscount = ((data.approvedQty * data.rate) *
                  Number(data.discountPercentage)) /
                  100;
                amountWithDiscount = (data.approvedQty * data.rate) - amountWithDiscount
                totalIgst = totalIgst + ((amountWithDiscount) * data.taxPercentage) / 100;
              } else {
                amountWithDiscount = (data.approvedQty * data.rate) - amountWithDiscount
                totalIgst = totalIgst + ((amountWithDiscount) * data.taxPercentage) / 100;
              }
            } else {
            
              totalIgst = totalIgst + ((
                data.approvedQty * data.rate) * Number(data.taxPercentage)) / 100;
            }

          } else {
            let amountWithDiscount = 0
            if (data.discountMode !== undefined && data.discountMode !== "" && data.discountMode !== null) {
              if (data.discountMode === 1) {
                amountWithDiscount =
                  (data.approvedQty * data.rate) -
                  Number(data.discountPercentage);
                totalCgst = totalCgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
                totalSgst = totalSgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;

              } else if (data.discountMode === 2) {
                amountWithDiscount = ((data.approvedQty * data.rate) *
                  Number(data.discountPercentage)) /
                  100;
                amountWithDiscount = (data.approvedQty * data.rate) - amountWithDiscount
                totalCgst = totalCgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
                totalSgst = totalSgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
              } else {
                amountWithDiscount = (data.approvedQty * data.rate) - amountWithDiscount
                totalCgst = totalCgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
                totalSgst = totalSgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
              }
            }
            else{
              amountWithDiscount =(data.approvedQty * data.rate) 
                totalCgst = totalCgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
                totalSgst = totalSgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
            }
          }
        }

      });
    }

    var netTotal = totalAmount + totalCgst + totalIgst + totalSgst;

    if (this.state.tcsPercentageValue) {
      tcsAmount = (totalAmount * this.state.tcsPercentageValue) / 100;
      tcsAmount = tcsAmount.toFixed(2);
      netTotal = netTotal + Number(tcsAmount);
    }
    if (tcsAmount > 0) {
      netTotal = netTotal.toFixed(2);
      let SplittcsAmount = netTotal.split(".");
      netTotal = SplittcsAmount[0];
      if (SplittcsAmount[1] <= 49) {
        netTotal = Number(SplittcsAmount[0]) + 0;
      } else {
        netTotal = Number(SplittcsAmount[0]) + 1;
      }
    }
    this.props.InwardFillDetails.tcsAmount = tcsAmount;
    this.props.InwardFillDetails.netTotal = netTotal;
    this.props.InwardFillDetails.invoiceAmount = netTotal;

    return (
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent" onScroll={this.handleScroll}>
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {tableHeaders.map((column) => (
                    <th>{column.header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredproducts.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <>
                      {RowData &&
                        RowData.map((col) =>
                          col.component === "EditableCell" ? (
                            <>
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        type="text"
                                        name={col.type}
                                        id={col.no}
                                        // disabled
                                        Value={
                                          col.type === "qty"
                                            ? parseFloat(row.inwardQty).toFixed(
                                                3
                                              )
                                              ? parseFloat(
                                                  row.inwardQty
                                                ).toFixed(3)
                                              : parseFloat(row.qty).toFixed(3)
                                            : col.type === "Unit"
                                            ? row.uom
                                              ? row.uom
                                              : row.uomCode
                                            : col.type === "Rate"
                                            ? parseFloat(row.rate).toFixed(3)
                                            : col.type === "amount"
                                            ? parseFloat(row.inwardQty).toFixed(
                                                2
                                              )
                                              ? parseFloat(
                                                 (Number(row.approvedQty) *
                                                    Number(row.rate))-Number(row.discount)
                                                ).toFixed(2)
                                              : parseFloat(row.amount).toFixed(
                                                  2
                                                )
                                            : col.type === "NetAmount"
                                            ? this.state.amount
                                            : col.type === "department"
                                            ? row.departmentName
                                            : col.type === "itemGroup"
                                            ? row.itemGroupName
                                            : col.type === "item"
                                            ? row.itemName
                                            : col.type === "DiscountMode"
                                            ? row.discountMode === 0
                                              ? ""
                                              : row.discountMode !== null
                                              ? row.discountMode === 1
                                                ? "Amount"
                                                : "Percentage"
                                              : ""
                                            : col.type === "Discount/Amount"
                                            ? row.discountMode === 1
                                              ? parseFloat(
                                                  row.discount
                                                ).toFixed(2)
                                              : row.discount
                                            : col.type === "IGST"
                                            ? row.igstPercentage
                                            : col.type === "CGST"
                                            ? row.cgstPercentage
                                            : col.type === "SGST"
                                            ? row.sgstPercentage
                                            : col.type === "BudgetHead"
                                            ? row.budgetHeadName
                                            : col.type === "Approvedqty"
                                            ? parseFloat(
                                                row.approvedQty
                                              ).toFixed(3)
                                            : col.type === "Rejectedqty"
                                            ? row.rejectedQty === null
                                              ? "0.00"
                                              : parseFloat(
                                                  row.rejectedQty
                                                ).toFixed(3)
                                            : col.type === "HSN/SAC_code"
                                            ? row.hsnCode
                                            : ""
                                        }
                                        onChange={this.handleChange({
                                          key: col.type,
                                          row,
                                        })}
                                        min={0}
                                        onWheel={(e) => e.target.blur()}
                                        className="inputBlockContainer"
                                        // onClick={this.props.tooltip}
                                        style={{
                                          textAlign:
                                            col.type === "qty" ||
                                            col.type === "Approvedqty" ||
                                            col.type === "Rejectedqty" ||
                                            col.type === "IGST" ||
                                            col.type === "CGST" ||
                                            col.type === "SGST" ||
                                            col.type === "Discount/Amount" ||
                                            col.type === "amount" ||
                                            col.type === "Rate"
                                              ? "right"
                                              : "",
                                          paddingRight: "8px",
                                          width:
                                            col.type === "item" ||
                                            col.type === "itemGroup"
                                              ? "340px"
                                              : "",
                                        }}
                                        readOnly={true}
                                      />
                                      <span>
                                        {col.type === "Discount/Amount"
                                          ? Number(row.discountMode) === 2
                                            ? "%"
                                            : ""
                                          : ""}
                                      </span>
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            </>
                          ) : (
                            <td>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <DynamicSelect
                                      arrayOfData={
                                        col.type === "department"
                                          ? DepartmentList
                                          : col.type === "BudgetHead"
                                          ? this.state.parentBudgetHeadsList
                                          : col.type === "itemGroup"
                                          ? AllItemGroupMasterList
                                          : col.type === "item"
                                          ? this.state.itemsList
                                          : col.type === "Tax"
                                          ? this.state.taxList
                                          : col.type === "StoreNo"
                                          ? this.state.wareHouseList
                                          : []
                                      }
                                      className="dropdownBlockContainer"
                                      onSelectChange={this.handleChangedropdown}
                                      isDropdownOpen={this.state.isDropdownOpen}
                                      handleMenuOpen={this.handleMenuOpen}
                                      selected={
                                        // col.type === "department"
                                        //   ? row.departmentId
                                        //   : col.type === "itemGroup"
                                        //   ? row.itemGroup
                                        //   : col.type === "item"
                                        //   ? row.itemId
                                        //   : col.type === "Tax"
                                        //   ? row.tax
                                        //   : col.type === "BudgetHead"
                                        //   ? row.budgetHeadId:
                                        col.type === "StoreNo"
                                          ? row.warehouseno
                                          : ""
                                      }
                                      name={col.type}
                                      row={row}
                                      // value={
                                      //   col.type === "department"
                                      //     ? row.departmentId
                                      //     : col.type === "itemGroup"
                                      //     ? row.itemGroupId
                                      //     : col.type === "item"
                                      //     ? row.itemId
                                      //     : col.type === "Tax"
                                      //     ? row.tax
                                      //     : col.type === "BudgetHead"
                                      //     ? row.budgetHeadId
                                      //     : ""
                                      // }
                                      update={
                                        row.itemGroup ||
                                        row.departmentId ||
                                        row.itemId ||
                                        row.budgetHeadId ||
                                        row.tax ||
                                        row.warehouseno
                                          ? 1
                                          : 0
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          )
                        )}
                    </>
                    {/* <td className="">
                      {row.debitnoteQty > 0 ? (
                        <IconButton
                          onClick={() => {
                            this.handlePdf();
                          }}
                        >
                          <PrintIcon />
                        </IconButton>
                      ) : (
                        ""
                      )}
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="consoleFormContainer">
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 4">
                      <div className="consoleTextFieldBlock">
                        <Box gridColumn="span 3">
                          <TextFieldNormal
                            label="TCS/TDS"
                            placeholder="Enter here"
                            value={this.state.tcsTds}
                            onChange={this.onhandleChange("TdsPercentage")}
                            disabled={true}
                          />
                        </Box>
                      </div>
                    </Box>

                    <Box gridColumn="span 4">
                      <div className="consoleTextFieldBlock">
                        {this.state.tcsTds === "TCS" ? (
                          <Box gridColumn="span 3">
                            <TextFieldNormal
                              type="number"
                              label="TCS Percentage"
                              placeholder="Enter here"
                              value={this.state.tcsPercentageValue}
                              onChange={this.onhandleChange("TdsPercentage")}
                              disabled={true}
                            />
                          </Box>
                        ) : (
                          ""
                        )}
                        {this.state.tcsTds === "TDS" ? (
                          <Box gridColumn="span 3">
                            <TextFieldNormal
                              type="number"
                              label="TDS Percentage"
                              placeholder="Enter here"
                              value={this.state.tdsPercentageValue}
                              onChange={this.onhandleChange("TcsPercentage")}
                              disabled={true}
                            />
                          </Box>
                        ) : (
                          ""
                        )}
                      </div>
                    </Box>
                    <Box gridColumn="span 9">
                      {debitnoteBtnShow && (
                        <div className="consoleTextFieldBlock">
                          {/* <DynamicSelect
                          arrayOfData={TCSPercentages}
                          className="dropdownBlockContainer"
                          onSelectChange={this.onhandleChangeDropdown}
                          selected={this.state.tcsPercentageValue}
                          name={"TcsPercentage"}
                          label="TCS Percentage"
                          update={this.state.tcsPercentageValue ? 1 : 0}
                        />*/}
                          <div>
                            <b>Debit Note Print out:</b>
                          </div>
                          {/* <IconButton
                          onClick={() => {
                            this.handlePdf();
                          }}
                          className="lightBlueButton"
                        >
                          <PrintIcon />
                        </IconButton> */}
                          <CustomButton
                            label="Print"
                            className="lightBlueButton"
                            muIcon={<PrintIcon />}
                            handleClick={this.handlePdf}
                            type="button"
                          />
                        </div>
                      )}
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TableContainer className="amountTable">
                          <Table aria-label="simple table">
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="noBorder"
                                >
                                  Total IGST:
                                </TableCell>
                                <TableCell align="right" className="noBorder">
                                  <strong>
                                    {parseFloat(totalIgst).toFixed(2)}
                                  </strong>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="noBorder"
                                >
                                  Total SGST:
                                </TableCell>
                                <TableCell align="right" className="noBorder">
                                  <strong>
                                    {parseFloat(totalCgst).toFixed(2)}
                                  </strong>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="noBorder"
                                >
                                  Total CGST:
                                </TableCell>
                                <TableCell align="right" className="noBorder">
                                  <strong>
                                    {parseFloat(totalSgst).toFixed(2)}
                                  </strong>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="noBorder"
                                >
                                  Net Total:
                                </TableCell>
                                <TableCell align="right" className="noBorder">
                                  <strong>
                                    {parseFloat(totalAmount).toFixed(2)}
                                  </strong>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="noBorder"
                                >
                                  {isEmpty(this.state.tcsTds) ||
                                   this.state.tcsTds === "N/A"
                                    ? "TCS/TDS Amount:"
                                    : this.state.tcsTds === "TCS"
                                    ? "TCS Amount:"
                                    : "TDS Amount:"}
                                </TableCell>
                                <TableCell align="right" className="noBorder">
                                  <strong>
                                    {this.state.tcsTds === "N/A" ||
                                    this.state.tcsTds === null
                                      ? "0.00"
                                      : this.state.tcsTds === "TCS"
                                      ? limitDecimals(
                                          Math.round(
                                            this.props.InwardFillDetails
                                              .tcsAmount
                                          ),
                                          2
                                        )
                                      : this.state.tcsTds === "TDS"
                                      ? limitDecimals(
                                          Math.round(
                                            this.props.InwardFillDetails
                                              .tdsAmount
                                          ),
                                          2
                                        )
                                      : "0.00"}
                                  </strong>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="noBorder"
                                >
                                  Total Amount:
                                </TableCell>
                                <TableCell align="right" className="noBorder">
                                  <strong>
                                    {/* {parseFloat(Math.round(netTotal)).toFixed(
                                      2
                                    )} */}
                                    {limitDecimals(
                                      Math.round(
                                        this.props.InwardFillDetails
                                          .totalAmount,
                                        2
                                      )
                                    )}
                                  </strong>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>

          <>
            <div className="consoleFormContainer">
              <div style={{ height: "15px" }}></div>
              <div className="consoleFormBlock" style={{ display: "flex" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                      className="ducumentUploadBlock uploadedContent"
                    >
                      <Box gridColumn="span 8">
                        {this.state.invoicedocsList &&
                          this.state.invoicedocsList.map((item) => (
                            <>
                              <div>
                                {/* {item.fileName} */}
                                <div
                                  className="uploadedInfo"
                                  style={{ display: "flex" }}
                                >
                                  <img
                                    src={TickIcon}
                                    alt=""
                                    className="tickIcon"
                                    height={"20px"}
                                    width={"20px"}
                                  />
                                  <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    title="Click to download File"
                                  >
                                    <div
                                      className="uplodedFileName"
                                      onClick={() => {
                                        getFileDownload(
                                          serverConfig.SERVER_URL +
                                            "security-api/api/files/downloadfile/" +
                                            item.fileUploadId,
                                          item.fileName,
                                          item.fileExtension
                                        );
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      {item.fileName}
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 100 }}
                                    title="Click to Delete File"
                                  >
                                    <Button
                                      onClick={() => {
                                        this.onDeleteFile(
                                          item.fileUploadId,
                                          "invoices"
                                        );
                                      }}
                                      className="deleteButton"
                                    >
                                      <img
                                        src={DeleteIcons}
                                        alt=""
                                        height={"20px"}
                                        width={"20px"}
                                      />
                                    </Button>
                                  </Tooltip>
                                </div>
                              </div>
                            </>
                          ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 5">
                        <div>
                          Invoices<span style={{ color: "red" }}>*</span>:
                        </div>
                        <div className="personalDetailsUpload">
                          <div className="ducumentUploadBlock">
                            <div className="documentUploadContent">
                              <span className="btn_upload documentUpload">
                                <img src={panUploadIcon} alt="" />
                                <input
                                  type="file"
                                  id="imag"
                                  title=""
                                  className="input-img"
                                  onChange={this.UploadDocuments("invoices")}
                                />
                                Upload Document
                              </span>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="consoleFormContainer">
              <div style={{ height: "15px" }}></div>
              <div className="consoleFormBlock" style={{ display: "flex" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                      className="ducumentUploadBlock uploadedContent"
                    >
                      <Box gridColumn="span 7">
                        {this.state.challandocsList &&
                          this.state.challandocsList.map((item) => (
                            <>
                              <div>
                                {/* {item.fileName} */}
                                <div
                                  className="uploadedInfo"
                                  style={{ display: "flex" }}
                                >
                                  <img
                                    src={TickIcon}
                                    alt=""
                                    className="tickIcon"
                                    height={"20px"}
                                    width={"20px"}
                                  />
                                  <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    title="Click to download File"
                                  >
                                    <div
                                      className="uplodedFileName"
                                      onClick={() => {
                                        getFileDownload(
                                          serverConfig.SERVER_URL +
                                            "security-api/api/files/downloadfile/" +
                                            item.fileUploadId,
                                          item.fileName,
                                          item.fileExtension
                                        );
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      {item.fileName}
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 100 }}
                                    title="Click to Delete File"
                                  >
                                    <Button
                                      onClick={() => {
                                        this.onDeleteFile(
                                          item.fileUploadId,
                                          "Challan"
                                        );
                                      }}
                                      className="deleteButton"
                                    >
                                      <img
                                        src={DeleteIcons}
                                        alt=""
                                        height={"20px"}
                                        width={"20px"}
                                      />
                                    </Button>
                                  </Tooltip>
                                </div>
                              </div>
                            </>
                          ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 5">
                        <div>Challan:</div>
                        <div className="personalDetailsUpload">
                          <div className="ducumentUploadBlock">
                            <div className="documentUploadContent">
                              <span className="btn_upload documentUpload">
                                <img src={panUploadIcon} alt="" />
                                <input
                                  type="file"
                                  id="imag"
                                  title=""
                                  className="input-img"
                                  onChange={this.UploadDocuments("Challan")}
                                />
                                Upload Document
                              </span>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="consoleFormContainer">
              <div style={{ height: "15px" }}></div>
              <div className="consoleFormBlock" style={{ display: "flex" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                      className="ducumentUploadBlock uploadedContent"
                    >
                      <Box gridColumn="span 7">
                        {this.state.supportDocsList &&
                          this.state.supportDocsList.map((item) => (
                            <>
                              <div>
                                {/* {item.fileName} */}
                                <div
                                  className="uploadedInfo"
                                  style={{ display: "flex" }}
                                >
                                  <img
                                    src={TickIcon}
                                    alt=""
                                    className="tickIcon"
                                    height={"20px"}
                                    width={"20px"}
                                  />
                                  <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    title="Click to download File"
                                  >
                                    <div
                                      className="uplodedFileName"
                                      onClick={() => {
                                        getFileDownload(
                                          serverConfig.SERVER_URL +
                                            "security-api/api/files/downloadfile/" +
                                            item.fileUploadId,
                                          item.fileName,
                                          item.fileExtension
                                        );
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      {item.fileName}
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 100 }}
                                    title="Click to Delete File"
                                  >
                                    <Button
                                      onClick={() => {
                                        this.onDeleteFile(
                                          item.fileUploadId,
                                          "suppdocs"
                                        );
                                      }}
                                      className="deleteButton"
                                    >
                                      <img
                                        src={DeleteIcons}
                                        alt=""
                                        height={"20px"}
                                        width={"20px"}
                                      />
                                    </Button>
                                  </Tooltip>
                                </div>
                              </div>
                            </>
                          ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 5">
                        <div>Support Documents:</div>
                        <div className="personalDetailsUpload">
                          <div className="ducumentUploadBlock">
                            <div className="documentUploadContent">
                              <span className="btn_upload documentUpload">
                                <img src={panUploadIcon} alt="" />
                                <input
                                  type="file"
                                  id="imag"
                                  title=""
                                  className="input-img"
                                  onChange={this.UploadDocuments("SuppDocs")}
                                />
                                Upload Document
                              </span>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </div>
          </>

          <div className="consoleFormContainer">
            <div style={{ height: "15px" }}></div>
            <div>Vendor Invoice Details :</div>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <TextFieldReq
                        label="Invoice No"
                        placeholder="Enter here"
                        value={this.state.invoiceNumber}
                        onChange={this.onhandleChange("invoiceNumber")}
                      />
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <div>
                          <div className="TextFieldBlock">
                            <label>
                              Invoice Date
                              <label className="starcolor">*</label>
                            </label>
                          </div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              name="financial_year"
                              id="financial_year"
                              inputFormat="dd-MM-yyyy"
                              value={this.state.invoiceDate}
                              fullWidth
                              onChange={(e) =>
                                this.handleSelect_Date(e, "invoiceDate")
                              }
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="consoleDatePicker"
                                >
                                  <input ref={inputRef} {...inputProps} />
                                  {InputProps?.endAdornment}
                                </Box>
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <div>
                          <div className="TextFieldBlock">
                            <label>Payment Due Date</label>
                          </div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              inputFormat="dd-MM-yyyy"
                              value={this.state.paymentDueDate}
                              fullWidth
                              onChange={(e) =>
                                this.handleSelect_Date(e, "paymentDueDate")
                              }
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="consoleDatePicker"
                                >
                                  <input ref={inputRef} {...inputProps} />
                                  {InputProps?.endAdornment}
                                </Box>
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <TextFieldReq
                        label="Invoice Amount"
                        placeholder="Enter here"
                        value = {limitDecimals(
                          Math.round(
                            this.props.InwardFillDetails
                              .totalAmount,
                            2
                          )
                        )}
                        onChange={this.onhandleChange("invoiceAmount")}
                      />
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <div>
                          <div className="TextFieldBlock">
                            <label>
                              Invoice Received Date
                              <label className="starcolor">*</label>
                            </label>
                          </div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              inputFormat="dd-MM-yyyy"
                              value={this.state.invoiceRecvdDate}
                              fullWidth
                              onChange={(e) =>
                                this.handleSelect_Date(e, "invoiceRecvdDate")
                              }
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="consoleDatePicker"
                                >
                                  <input ref={inputRef} {...inputProps} />
                                  {InputProps?.endAdornment}
                                </Box>
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </Box>
                    {/* Commentted this for the future reference */}
                    {/* <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            arrayOfData={this.state.ledgerMasterListData}
                            className="dropdownBlockContainer"
                            onSelectChange={this.onhandleChangeDropdown}
                            isDropdownOpen={this.state.isDropdownOpen}
                            handleMenuOpen={this.handleMenuOpen}
                            selected={this.state.ledgerValue}
                            name={"ledger"}
                            label="Ledger"
                            update={this.state.ledgerValue ? 1 : 0}
                          />
                        </div>
                      </Box> */}
                    <Box gridColumn="span 3">
                      <TextFieldNormal
                        label="Ledger"
                        value={this.props.InwardFillDetails.ledger}
                        onChange={this.onhandleChange("ledger")}
                        readOnly
                      />
                    </Box>
                    {/* <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={TCSPercentages}
                          className="dropdownBlockContainer"
                          onSelectChange={this.onhandleChangeDropdown}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.tcsPercentageValue}
                          name={"TcsPercentage"}
                          label="TCS Percentage"
                          update={this.state.tcsPercentageValue ? 1 : 0}
                        />
                      </div>
                    </Box> */}
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={GSTInvoiceType}
                          className="dropdownBlockContainer"
                          onSelectChange={this.onhandleChangeDropdown}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.gstInvoiceType}
                          name={"gstInvoiceType"}
                          label="GST Invoice Type"
                          update={this.state.gstInvoiceType ? 1 : 0}
                        />
                      </div>
                    </Box>

                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={ITCApplicable}
                          className="dropdownBlockContainer"
                          onSelectChange={this.onhandleChangeDropdown}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.itcApplicable}
                          name={"itcApplicable"}
                          label="ITC Applicable"
                          update={this.state.itcApplicable ? 1 : 0}
                        />
                      </div>
                    </Box>

                    {/* <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={TCSPayable}
                          className="dropdownBlockContainer"
                          onSelectChange={this.onhandleChangeDropdown}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.tdsPayable}
                          name={"tdsPayable"}
                          label="TDS Payable"
                          update={this.state.tdsPayable ? 1 : 0}
                          required
                        />
                      </div>
                    </Box> */}
                    {/* {this.state.tdsfields === true && (
                      <>
                        <Box gridColumn="span 3">
                          <TextFieldNormal
                            label="TDS Amount"
                            placeholder="Enter Amount here"
                            type="number"
                            value={this.state.tdsAmount}
                            onChange={this.onhandleChange("tdsAmount")}
                            required
                          />
                        </Box>
                        <Box gridColumn="span 3">
                          <TextFieldNormal
                            label="TDS Reason"
                            placeholder="Enter here"
                            value={this.state.tdsReason}
                            onChange={this.onhandleChange("tdsReason")}
                          />
                        </Box>
                      </>
                    )} */}
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
    );
  }
}

const RowData = [
  {
    no: 1,
    component: "EditableCell",
    type: "department",
  },
  {
    no: 2,
    component: "EditableCell",
    type: "BudgetHead",
  },

  {
    no: 3,
    component: "EditableCell",
    type: "itemGroup",
  },
  {
    no: 4,
    component: "EditableCell",
    type: "item",
  },
  {
    no: 5,
    component: "EditableCell",
    type: "qty",
  },
  {
    no: 6,
    component: "EditableCell",
    type: "Approvedqty",
  },
  {
    no: 7,
    component: "EditableCell",
    type: "Rejectedqty",
  },
  {
    no: 8,
    component: "EditableCell",
    type: "Unit",
  },
  {
    no: 9,
    component: "EditableCell",
    type: "HSN/SAC_code",
  },
  {
    no: 10,
    component: "DynamicSelect",
    type: "StoreNo",
  },
  {
    no: 11,
    component: "EditableCell",
    type: "Rate",
  },
  {
    no: 12,
    component: "EditableCell",
    type: "DiscountMode",
  },
  {
    no: 13,
    component: "EditableCell",
    type: "Discount/Amount",
  },
  {
    no: 14,
    component: "EditableCell",
    type: "IGST",
  },
  {
    no: 15,
    component: "EditableCell",
    type: "CGST",
  },
  {
    no: 16,
    component: "EditableCell",
    type: "SGST",
  },
  {
    no: 17,
    component: "EditableCell",
    type: "amount",
  },
  // {
  //   no: 10,
  //   component: "EditableCell",
  //   type: "NetAmount",
  // },
];
const tableHeaders = [
  {
    header: "Department",
  },
  {
    header: "Budget Head",
  },
  {
    header: "Item Group",
  },
  {
    header: "Item",
  },
  {
    header: "Quantity",
  },
  {
    header: "Approved Quantity",
  },
  {
    header: "Rejected Quantity",
  },
  {
    header: "Unit",
  },
  {
    header: "HSN/SAC code",
  },
  {
    header: "Store No",
  },
  {
    header: "Item Rate",
  },
  {
    header: "Discount Mode",
  },
  {
    header: "Discount/Amount",
  },
  {
    header: "IGST",
  },
  {
    header: "CGST",
  },
  {
    header: "SGST",
  },
  {
    header: "Amount",
  },
  // {
  //   header: "Net Amount",
  // },
  // {
  //   header: "Actions",
  // },
];

const TCSPercentages = [
  { label: "0%", value: 0, name: "0%" },
  { label: "0.075%", value: 0.075, name: "0.075%" },
  { label: "0.1%", value: 0.1, name: "0.1%" },
  { label: "1%", value: 1, name: "1%" },
];
const TCSPayable = [
  { label: "Yes", value: "Y", name: "Yes" },
  { label: "No", value: "N", name: "No" },
];

const GSTInvoiceType = [
  { label: "B2B", value: 0, name: "B2B" },
  { label: "B2C", value: 1, name: "B2C" },
  { label: "Export", value: 2, name: "Export" },
  { label: "Import", value: 3, name: "Import" },
];

const ITCApplicable = [
  { label: "Yes", value: 1, name: "Yes" },
  { label: "No", value: 2, name: "No" },
];
const mapStatetoProps = (state) => {
  const { itemsListForIndent } = state.itemGroupMasterList;
  const { Inward_LineItems } = state.InwardReducer;
  const { taxList } = state.taxList;
  const { LedgerMasterList } = state.LedgerReducer;
  const {
    files_List,
    logs_List,
    filesofInvoice,
    filesofChallan,
    filesofSuppdocs,
  } = state.IndentReducer;
  const {
    Upload_File_List,
    Uploaded_suppdocsFiles,
    Uploaded_invoiceList,
    Uploaded_challanFiles,
  } = state.UploadFileReducer;
  const { wareHouseList } = state.StockTransfer_List;

  const { departmentList, allItemGroupMaster_List, parentBudgetHeadsList } =
    state.DropDownListReducer;

  return {
    itemsListForIndent,
    Inward_LineItems,
    departmentList,
    allItemGroupMaster_List,
    parentBudgetHeadsList,
    taxList,
    files_List,
    Upload_File_List,
    logs_List,
    wareHouseList,
    filesofInvoice,
    filesofChallan,
    filesofSuppdocs,
    Uploaded_suppdocsFiles,
    Uploaded_invoiceList,
    Uploaded_challanFiles,
    LedgerMasterList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getItemsListForIndent,
    InwardLineItemsProps,
    getDepartmentList,
    allItemGroupMasterList,
    getParentBudgetHeads,
    postUploadFileList,
    deleteFile,
    UploadInvoiceFileList,
    UploadsuppdocsFileList,
    UploadchallanFileList,
    actions,
    logs,
    getFiles,
    getInvoiceFilesSuccessfull,
    getChallanFilesSuccessfull,
    getSuppdocsFilesSuccessfull,
    getPendingStockPoandReceipt,
    getPendingStock,
    getTax_List,
    getwareHouseDataList,
    ledgerMasterList,
  })(BillPassLineItems)
);
