import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Grid, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Fade from "@mui/material/Fade";
import { limitDecimals } from "../../../Utilities/helper";
import { isEmpty } from "../../../Utilities/helper";
import swal from "sweetalert";
import DynamicSelect from "../../../Pages/StoreModule/MaterialRequest/DynamicSelect";
import { allItemGroupMasterList } from "../../../store/Global/DropDownApis/actions";
import {
  getQuotationItemsByItemGroupId,
  getQuotationLineItems,
  getQuotationHeaders,
  getItemDetailsByItemGroupAndItemIdAndComapnyId,
} from "../../../store/Sales/Qutation/actions";
import { serverApi } from "../../../helpers/Consts";
import { SalesGstCalculations } from "../../../Utilities/SalesGstCalculation";

const QuotationLineItems = () => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [LineItems, setLineItems] = useState([]);
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [LineItemsDisplayTxtField, setLineItemsDisplayTxtField] = useState({});
  const [LineItemsDisplayDropField, setLineItemsDisplayDropField] = useState(
    {}
  );
  const [HeaderData, setHeaderData] = useState({});
  const [ItemGroupData, setItemGroupData] = useState([]);
  const [ItemNameData, setItemNameData] = useState([]);
  const [userDit, setuserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );

  const { allItemGroupMaster_List } = useSelector(
    (state) => state.DropDownListReducer
  );
  const { quoteLineItems, quoteHeader } = useSelector(
    (state) => state.QuotationReducer
  );
  const {ItemDetailsByItemGroupAndCompanyId_List} = useSelector(
    (state) => state.QuotationReducer
  )

  const { quotationItemBy_ItemGroupId } = useSelector(
    (state) => state.QuotationReducer
  );

  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setLineItems(quoteLineItems);
    setHeaderData(quoteHeader);
    window.addEventListener("scroll", handleScroll);
  }, [quoteLineItems, quoteHeader]);

  useEffect(() => {
    if (allItemGroupMaster_List) {
      if (allItemGroupMaster_List.data) {
        let list = allItemGroupMaster_List.data.filter((props) => {
          if (props.value !== "0") {
            return props;
          }
        });
        let ItemList = [];
        list.map((data) => {
          ItemList.push({
            label: data.label,
            name: data.name,
            value: data.value,
            variant: data.id,
          });
        });
        setItemGroupData(ItemList);
      }
    }
  }, [, allItemGroupMaster_List, quotationItemBy_ItemGroupId]);

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };

  const onDel = (row) => {
    var FilterLineItems = LineItems.filter((item) => item.isActive !== 0);
    if (FilterLineItems.length == 1) {
      swal("you can't delete the last lineitem");
    } else {
      let indexItem = LineItems.findIndex((item) => item === row);
      let lineItems = [...LineItems];
      lineItems[indexItem].isActive = 0;
      setLineItems(lineItems);
      dispatch(getQuotationLineItems(lineItems));

      let updatingAfterDeletion = SalesGstCalculations(
        {
          lineItems: lineItems,
          taxType: HeaderData.taxType,
        },
        "SALES_REGULAR_FLOW"
      );
      handleCalculation(updatingAfterDeletion);
    }
  };

  const displayField = (row, colname, type) => {
    if (colname === "itemGroupValue") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type == 1) {
        dispatch(
          allItemGroupMasterList(
            serverApi.GET_ALL_ITEMGROUP_MASTER_LIST +
              localStorage.getItem("companyId") +
              "/" +
              userDit.cipher,
            history
          )
        );
      }
    } else if (colname === "itemId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type == 1) {

      const data = {
        companyId: localStorage.getItem("companyId"),
        itemGroupId: row.itemGroupValue,
      };
      dispatch(
        getQuotationItemsByItemGroupId(
          serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
          data,
          history,
          row.id
        )
      );
        }
    } else if (colname === "discountType") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
    }
  };
  const OnhandleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    let mname = key.split("_");
    if (mname[0] === "remarks") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.remarks = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getQuotationLineItems(LineItemsRec));
    }
    if (mname[0] === "make") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.make = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getQuotationLineItems(LineItemsRec));
    }
    if (mname[0] === "hsnCode") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.hsnCode = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getQuotationLineItems(LineItemsRec));
    }  
if (mname[0] === "rate") {
  let LineItemsRec = LineItems.map((rowItem) => {
      if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
              if (rowItem.discount !== "" && value < rowItem.discount) {
                  swal("Rate should not be less than the discount.");
                  rowItem.rate = "0.000";
                  rowItem.discount = 0;
                  rowItem.discountedRate = 0;
              } else {
                  rowItem.rate = value;
                  if (rowItem.discount !== "") {
                      if (rowItem.discountType === 1 && value !== "") {
                          rowItem.discountedRate = limitDecimals(
                            value - rowItem.discount,
                             3
                             );
                      } else {
                          let data = (value * rowItem.discount) / 100;
                          rowItem.discountedRate = limitDecimals(value - data, 3);
                      }
                  } else {
                      rowItem.discountedRate = limitDecimals(value, 3);
                  }
              }
          } else {
              swal("Please enter a positive number with up to 3 decimal places.");
          }
      }
      return rowItem;
  });
  setLineItems(LineItemsRec);
  dispatch(getQuotationLineItems(LineItemsRec));
  let Rate = SalesGstCalculations(
      {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
      },
      "SALES_REGULAR_FLOW"
  );
  handleCalculation(Rate);
}
if (mname[0] === "discount") {
  let LineItemsRec = LineItems.map((rowItem) => {
      if (row.id === rowItem.id) {
        if (rowItem.discountType === 1) {
          if (Number(value) > Number(rowItem.rate)) {
              swal("Discount value shouldn't be greater than Rate");
              // rowItem.rate = "0.000"; // Set rate to 0 if discount is greater than rate
              rowItem.discount = 0; // Set discount to 0
              rowItem.discountedRate = rowItem.rate; // Set discounted rate to 0
          } else {
                  if (value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
                      rowItem.discount = value;
                      rowItem.discountedRate = limitDecimals(Number(rowItem.rate) - Number(value), 3);
                  } else {
                      swal("Please enter a positive number with up to 2 decimal places.");
              }}}
               else {
                  if (Number(value) > 100) {
                      swal("Discount percentage shouldn't be greater than 100");
                      // rowItem.rate = "0.000"; // Set rate to 0 if discount percentage is greater than 100
                      rowItem.discount = 0; // Set discount to 0
                      rowItem.discountedRate = rowItem.rate; // Set discounted rate to 0
                      rowItem.discountAmount="0.00"; // Reset discount amount
                  } else {
                      if (value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
                          rowItem.discount = value;
                      } else {
                          swal(
                            "Please enter a positive number with up to 2 decimal places."
                            );
                      }
                      let data = (rowItem.rate * value) / 100;
                      rowItem.discountedRate = limitDecimals(rowItem.rate - data, 3); // Keep the original precision without rounding
                  }
              }
          }
      return rowItem;
  });
  setLineItems(LineItemsRec);
  dispatch(getQuotationLineItems(LineItemsRec));
  let Disc = SalesGstCalculations(
      {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
      },
      "SALES_REGULAR_FLOW"
  );
  handleCalculation(Disc);
}
    if (mname[0] === "discountedRate") {
      let LineItemsRec = LineItems.map((rowItem) => {
          if (row.id === rowItem.id) {
              rowItem.discountedRate = value;
          }
          return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getQuotationLineItems(LineItemsRec));
  }
      if (mname[0] === "quantity") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            rowItem.quantity = value;
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getQuotationLineItems(LineItemsRec));
      let Qty = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
        },
        "SALES_REGULAR_FLOW"
      );
      handleCalculation(Qty);
    }
    if (mname[0] === "uom") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.uom = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getQuotationLineItems(LineItemsRec));
    }
    if (mname[0] === "taxPercent") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(?:\d+(\.\d{1,2})?)?$/)) {
            rowItem.taxPercent = value;
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getQuotationLineItems(LineItemsRec));
      let TaxPer = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
        },
        "SALES_REGULAR_FLOW"
      );
      handleCalculation(TaxPer);
    }
    if (mname[0] === "netAmount") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.netAmount = limitDecimals(value);
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getQuotationLineItems(LineItemsRec));
    }
  };

  const handleSelectChange = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    selectedItemCode
  ) => {
    const countOfOtherCharges = LineItems.filter(
      (el) => el.itemGroupValue == 997 
    ).length;
    const isNonOtherChargeItemOne=LineItems.length-countOfOtherCharges
    const lastRow = isNonOtherChargeItemOne == 1 ? LineItems[LineItems.length - 1 - countOfOtherCharges]: LineItems[LineItems.length-1];
    if (name === "itemGroupValue") { 
      if (selectedRow === lastRow) {
        let LineItem = LineItems;
        let Obj = {
          id: LineItems.length + 1,
          cgstAmount: "",
          cgstPercent: "",
          companyId: "",
          companyName: "",
          createdBy: "",
          createdDate: "",
          discount: "",
          discountedRate: "",
          discountType: "",
          discountName: "",
          discountAmount: "0.00",
          hsnCode: "",
          igstAmount: "",
          igstPercent: "",
          invoiceBalQty: "",

          itemGroupId: "",
          itemGroupName: "",
          itemGroupValue: "",

          itemId: "",
          itemName: "",
          itemValueId: "",
          itemValue: "",
          itemCode: "",
          make: "",
          netAmount: "0.00",
          quantity: "0.000",
          quotationId: "",
          quotationLineitemId: "",
          rate: "0.000",
          remarks: "",
          salesBalQty: "",
          sgstAmount: "",
          sgstPercent: "",
          totalAmount: "0.00",
          uom: "",
          updatedBy: "",
          isActive: 1,
          taxPercent: "0.00",
          currency: "INR",
          updatedOn: null,
          quoteLineItems: [],
        };
        LineItem.push(Obj);
        setLineItems(LineItem);
        dispatch(getQuotationLineItems(LineItem));
        }
      }
      if (name === "itemGroupValue") { 
      let LineItemdata = LineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          row.itemGroupId = selectedItemCode;
          row.itemGroupValue = selectedValue;
          row.itemGroupName = selectedName;
          row.discount = "";
          row.discountedRate = "";
          row.discountType = "";
          row.discountName = "";
          row.hsnCode = "";
          row.itemId = null;
          row.itemName = "";
          row.itemValueId = "";
          row.itemValue = "";
          row.itemCode = "";
          row.make = "";
          row.quantity = "0.000";
          row.rate = "0.000";
          row.remarks = "";
          row.totalAmount = "0.00";
          row.netAmount = "0.00";
          row.uom = "";
          row.taxPercent = "0.00";
        }
        return row;
      });

      setLineItems(LineItemdata);
      dispatch(getQuotationLineItems(LineItemdata));
      displayField(selectedRow, "itemGroupValue", 2);
      let ItemWhenAdd = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
        },
        "SALES_REGULAR_FLOW"
      );
      handleCalculation(ItemWhenAdd);
      const data = {
        companyId: localStorage.getItem("companyId"),
        itemGroupId: selectedValue,
      };
      dispatch(
        getQuotationItemsByItemGroupId(
          serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
          data,
          history,
          selectedRow.id
        )
      );
    }


    if (name === "itemId") {
      let filteredLineItems = LineItems.filter((item) => item.isActive !== 0);
      if (selectedRow.itemId !== selectedValue) {
        var check = !!filteredLineItems.find(
          ({ itemId }) => Number(itemId) === Number(selectedValue)
        );
      }
      if (check) {
        swal("You can't create the record with the duplicate item");
        let LineItemdata = LineItems.filter((row) => {
          if (row.id === selectedRow.id) {
            row.discount = "";
            row.discountedRate = "";
            row.discountType = "";
            row.discountName = "";
            row.discountAmount = "0.00";
            row.hsnCode = "";
            row.itemId = null;
            row.itemName = "";
            row.itemValueId = "";
            row.itemValue = "";
            row.itemCode = "";
            row.make = "";
            row.quantity = "0.000";
            row.rate = "0.000";
            row.remarks = "";
            row.totalAmount = "0.00";
            row.netAmount = "0.00";
            row.uom = "";
            row.taxPercent = "0.00";
          }
          return row;
        });
        setLineItems(LineItemdata);
        dispatch(getQuotationLineItems(LineItemdata));
        displayField(selectedRow, "itemId", 2);
      } else {
        let LineItemdata = LineItems.filter((row) => {
          if (row.id === selectedRow.id) {
            row.itemId = selectedValue;
            row.itemValueId = selectedValue;
            row.itemCode = row.itemGroupValue + selectedItemCode;
            row.itemName = selectedName;
            row.make = "";
          }

          return row;
        });
        setLineItems(LineItemdata);
        dispatch(getQuotationLineItems(LineItemdata));
        displayField(selectedRow, "itemId", 2);
      }
      const data = {
        companyId: localStorage.getItem("companyId"),
        branchId: HeaderData.branchId,
        itemGroupId: selectedRow.itemGroupValue,
        itemId: selectedValue,
      };
      dispatch(
        getItemDetailsByItemGroupAndItemIdAndComapnyId(
          serverApi.GET_ITEMDETAILS_BYITEM_ITEMGRP_COMPANYID,
          data,
          history,
          selectedRow.id
        )
      );
    }
    if (name === "discountType") {
      let LineItemdata = LineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          if (selectedValue === "") {
            row.discount = "";
            row.discountType = "";
            row.discountName = "";
            row.discountedRate = limitDecimals(row.rate, 3);
          } else {
            row.discountedRate = "";
            row.discount = "";
            row.discountType = selectedValue;
            row.discountName = selectedName;
            if (selectedValue == 1) {
              row.discountedRate = limitDecimals(row.rate - row.discount, 3);
            } else {
              let data = (row.rate * row.discount) / 100;
              row.discountedRate = limitDecimals(row.rate - data, 3);
            }
          }
        }
        return row;
      });
      setLineItems(LineItemdata);
      dispatch(getQuotationLineItems(LineItemdata));
      displayField(selectedRow, "discountType", 2);
    }
  };
  const handleCalculation = (Calculations) => {
    setLineItems(Calculations.lineItems);
    dispatch(getQuotationLineItems(Calculations.lineItems));
    var Headerdata = {
      ...HeaderData,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      netTotal: Calculations.netTotal,
      totalAmount: Calculations.TotalAmount,
    };
    setHeaderData((prev) => ({
      ...prev,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      netTotal: Calculations.netTotal,
      totalAmount: Calculations.TotalAmount,
    }));
    dispatch(getQuotationHeaders(Headerdata));
  };

  const addOtherCharges = () => {
    let newline = 1;
    let filterProduct = LineItems.map((item) => {
      newline = Number(item.id);
      return item;
    });

    let obj = {
      id: LineItems.length + 1,
      itemGroupId: 1252,
      itemGroupName: "Other Charges",
      itemGroupValue: "997",
      itemId: "",
      itemName: "",
      itemCode: "",
      itemValue: "",
      remarks: "",
      itemMake: "",
      hsncode: "",
      rate: "0.000",
      discountedRate: "",
      quantity: "0.000",
      uom: "",
      taxPercent: "",
      createdBy: "",
      createdDate: "",
      companyId: "",
      invoiceQty: "",
      igstAmount: "",
      igstPercent: "",
      cgstAmount: "",
      cgstPercent: "",
      sgstAmount: "",
      sgstPercent: "",
      cessAmount: "",
      discountType: "",
      discountName: "",
      discount: "",
      discountedRate: "",
      discountAmount: "0.00",
      netAmount: "0.00",
      totalAmount: "0.00",
      balesPacketSlNo: "",
      status: 1,
      isActive: 1,
      currency: "INR",
      invoiceBalQty: "",
      quotationId: "",
      quotationLineitemId: "",
      updatedBy: null,
      updatedOn: null,
      quoteItemData: [],
    };
    const spliceStartValue = filterProduct[filterProduct.length - 1].itemGroupId
      ? LineItems.length
      : LineItems.length - 1;
 
    if (filterProduct.length == 1) {
      filterProduct.push(obj);
      setLineItems(filterProduct);
      dispatch(getQuotationLineItems(filterProduct));
    } else {
      filterProduct.splice(spliceStartValue, 0, obj);
      setLineItems(filterProduct);
      dispatch(getQuotationLineItems(filterProduct));
    }
  };
  const filterListItem = LineItems.filter((item) => item.isActive != 0);
  let lastLineItem = LineItems[LineItems.length - 1];

  return (
    <>
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent" onScroll={handleScroll}>
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {tableHeaders.map((column) => (
                    <>
                      {column.header == "Item Group" ||
                      column.header == "Item Name" ||
                      column.header == "Rate" ||
                      column.header == "Quantity" ||
                      column.header == "UOM" ? (
                        <>
                          <th>
                            {column.header}
                            <label style={{ color: "red" }}>*</label>
                          </th>
                        </>
                      ) : (
                        <>
                          <th>{column.header}</th>
                        </>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filterListItem.length !== 0 &&
                  filterListItem.map((row) => {
                    let rowIndex = filterListItem.findIndex(
                      (idd) => idd == row
                    );

                    return (
                      <>
                        <tr>
                          <>
                            {RowData &&
                              RowData.map((col) =>
                                col.component === "Editablecell" ? (
                                  <>
                                    <td>
                                      <div className="quantityDropdownBlock">
                                        <Grid className="selectAndTextfield">
                                          <Grid
                                            xs={12}
                                            md={12}
                                            className="dynamicSelectBlock"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <input
                                              type={
                                                col.type == "rate"
                                                  ? "number"
                                                  : col.type === "discount"
                                                  ? "number"
                                                  : col.type ===
                                                    "discountedRate"
                                                  ? "number"
                                                  : col.type === "quantity"
                                                  ? "number"
                                                  : col.type === "taxPercent"
                                                  ? "number"
                                                  : col.type == "netAmount"
                                                  ? "number"
                                                  : "text"
                                              }
                                              name={col.type + "_" + rowIndex}
                                              value={
                                                col.type === "remarks"
                                                  ? row.remarks
                                                  : col.type === "make"
                                                  ? row.make
                                                  : col.type === "hsnCode"
                                                  ? row.hsnCode
                                                  : col.type == "rate"
                                                  ? row.rate
                                                  : col.type === "discount"
                                                  ? row.discount
                                                  : col.type ===
                                                    "discountedRate"
                                                  ? row.discountedRate
                                                  : col.type === "quantity"
                                                  ? row.quantity
                                                  : col.type === "uom"
                                                  ? row.uom
                                                  : col.type === "taxPercent"
                                                  ? row.taxPercent
                                                  : col.type == "netAmount"
                                                  ? row.netAmount
                                                  : ""
                                              }
                                              onChange={OnhandleChange({
                                                key: col.type + "_" + rowIndex,
                                                row,
                                              })}
                                              disabled={
                                                col.type === "discountedRate"
                                                  ? true
                                                  : col.type === "discount" &&
                                                    isEmpty(row.discountName)
                                                  ? true
                                                  : col.type === "netAmount"
                                                  ? true
                                                  : col.type === "taxPercent"
                                                  ? true
                                                  : col.type === "hsnCode"
                                                  ? true
                                                  : col.type === "uom"
                                                  ? true
                                                  : false
                                              }
                                              style={{
                                                paddingRight:
                                                  col.type === "discountedRate"
                                                    ? "2px"
                                                    : col.type === "rate"
                                                    ? "2px"
                                                    : col.type === "netAmount"
                                                    ? "2px"
                                                    : col.type === "discount"
                                                    ? "2px"
                                                    : col.type ===
                                                      "discountedRate"
                                                    ? "2px"
                                                    : col.type === "quantity"
                                                    ? "2px"
                                                    : col.type === "taxPercent"
                                                    ? "2px"
                                                    : col.type === "netAmount"
                                                    ? "2px"
                                                    : "",
                                                textAlign:
                                                  col.type === "discountedRate"
                                                    ? "right"
                                                    : col.type === "rate"
                                                    ? "right"
                                                    : col.type === "netAmount"
                                                    ? "right"
                                                    : col.type === "discount"
                                                    ? "right"
                                                    : col.type ===
                                                      "discountedRate"
                                                    ? "right"
                                                    : col.type === "quantity"
                                                    ? "right"
                                                    : col.type === "taxPercent"
                                                    ? "right"
                                                    : col.type === "netAmount"
                                                    ? "right"
                                                    : "",
                                                backgroundColor:
                                                  col.type === "discountedRate"
                                                    ? "#ccd6db"
                                                    : col.type === "discount" &&
                                                      isEmpty(row.discountName)
                                                    ? "#ccd6db"
                                                    : col.type === "netAmount"
                                                    ? "#ccd6db"
                                                    : col.type === "taxPercent"
                                                    ? "#ccd6db"
                                                    : col.type === "hsnCode"
                                                    ? "#ccd6db"
                                                    : col.type === "uom"
                                                    ? "#ccd6db"
                                                    : "",
                                              }}
                                              onWheel={(e) => e.target.blur()}
                                              className="inputBlockContainer"
                                            />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </td>
                                  </>
                                ) : col.component === "DynamicSelect" ? (
                                  <td>
                                    <div className="quantityDropdownBlock">
                                      <Grid className="selectAndTextfield">
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: LineItemsDisplayTxtField[
                                              col.type + row.id + "1"
                                            ]
                                              ? "none"
                                              : "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            className="inputBlockContainer"
                                            value={
                                              col.type === "itemGroupValue"
                                                ? row.itemGroupName
                                                : col.type === "itemId"
                                                ? row.itemName
                                                : col.type === "discountType"
                                                ? row.discountName
                                                : ""
                                            }
                                            onChange={OnhandleChange({
                                              key: col.type + "_" + rowIndex,
                                              row,
                                            })}
                                            disabled={
                                              col.type === "itemId" &&
                                              isEmpty(row.itemGroupValue)
                                                ? true
                                                : col.type ===
                                                    "itemGroupValue" &&
                                                  row.itemGroupValue === "997"
                                                ? true
                                                : false
                                            }
                                            style={{
                                              paddingRight: "8px",
                                              backgroundColor:
                                                col.type === "itemId" &&
                                                isEmpty(row.itemGroupValue)
                                                  ? "#ccd6db"
                                                  : col.type ===
                                                      "itemGroupValue" &&
                                                    row.itemGroupValue === "997"
                                                  ? "#ccd6db"
                                                  : "",
                                            }}
                                            onClick={() => {
                                              displayField(row, col.type, 1);
                                            }}
                                          />
                                        </Grid>
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: LineItemsDisplayDropField[
                                              col.type + row.id + "2"
                                            ]
                                              ? "flex"
                                              : "none",
                                            alignItems: "center",
                                          }}
                                        >
                                          <DynamicSelect
                                            arrayOfData={
                                              col.type == "itemGroupValue"
                                                ? ItemGroupData
                                                : col.type == "itemId"
                                                ? row.quoteLineItems
                                                : col.type == "discountType"
                                                ? discount_Type
                                                : ""
                                            }
                                            className="dropdownBlockContainer"
                                            onSelectChange={handleSelectChange}
                                            selected={
                                              col.type === "itemGroupValue"
                                                ? row.itemGroupValue
                                                : col.type === "itemId"
                                                ? row.itemId
                                                : col.type == "discountType"
                                                ? row.discountType
                                                : ""
                                            }
                                            name={
                                              col.type === "itemGroupValue"
                                                ? "itemGroupValue"
                                                : col.type === "itemId"
                                                ? "itemId"
                                                : col.type == "discountType"
                                                ? "discountType"
                                                : ""
                                            }
                                            row={row}
                                            update={
                                              row.itemGroupValue ||
                                              row.itemId ||
                                              row.discountType
                                                ? 1
                                                : 0
                                            }
                                            isDropdownOpen={isDropdownOpen}
                                            handleMenuOpen={handleMenuOpen}
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </td>
                                ) : (
                                  ""
                                )
                              )}
                          </>
                          <td>
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Delete"
                            >
                              <IconButton
                                onClick={() => {
                                  onDel(row);
                                }}
                                disabled={
                                  filterListItem.length === 1
                                    ? true
                                    : lastLineItem === row
                                    ? true
                                    : false
                                }
                              >
                                <DeleteIcon className="deleteDisplayButton" />
                              </IconButton>
                            </Tooltip>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div style={{ marginTop: "5px" }}>
            <span
              className="OtherCharges"
              onClick={() => {
                addOtherCharges();
              }}
            >
              Click Here To Add Printing Charges/Labour Charges/Transport
              Charges/Delivery Charges/Packing & Forwarding Charges/Other
              Charges
            </span>
          </div>
          <div className="consoleFormContainer">
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 12">
                      {/* <InvoiceSection poDetails={this.state.poDetails} poFillDetails={this.props.PoFillDetails} type={"BY_NOT_BOM"}/> */}

                      {/* commment this line and added InvoiceSection for business calculation */}
                      <div
                        className="calculationBlockTable"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <table>
                          <tr>
                            <td>Taxable Value:</td>
                            <b> {parseFloat(HeaderData.netTotal).toFixed(2)}</b>
                          </tr>
                          <tr>
                            <td>Total IGST:</td>
                            <b>
                              {" "}
                              {parseFloat(HeaderData.totalIGST).toFixed(2)}
                            </b>
                          </tr>
                          <tr>
                            <td>Total SGST:</td>
                            <td>
                              <b>
                                {parseFloat(HeaderData.totalSGST).toFixed(2)}
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>Total CGST:</td>
                            <td>
                              <b>
                                {parseFloat(HeaderData.totalCGST).toFixed(2)}
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Brokerage @
                              {HeaderData.brokeragePercentage
                                ? parseFloat(
                                    HeaderData.brokeragePercentage
                                  ).toFixed(2)
                                : 0}
                              %
                            </td>
                          </tr>
                          <tr>
                            <td>Total Value with Tax:</td>
                            <b>
                              {parseFloat(HeaderData.totalAmount).toFixed(2)}
                            </b>
                          </tr>
                        </table>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "itemGroupValue",
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "itemId",
  },
  {
    no: 3,
    component: "Editablecell",
    type: "remarks",
  },
  {
    no: 3,
    component: "Editablecell",
    type: "make",
  },
  {
    no: 4,
    component: "Editablecell",
    type: "hsnCode",
  },
  {
    no: 6,
    component: "Editablecell",
    type: "quantity",
  },
  {
    no: 7,
    component: "Editablecell",
    type: "uom",
  },
  {
    no: 5,
    component: "Editablecell",
    type: "rate",
  },
  {
    no: 8,
    component: "DynamicSelect",
    type: "discountType",
  },
  {
    no: 9,
    component: "Editablecell",
    type: "discount",
  },
  {
    no: 10,
    component: "Editablecell",
    type: "discountedRate",
  },
  {
    no: 11,
    component: "Editablecell",
    type: "taxPercent",
  },
  {
    no: 12,
    component: "Editablecell",
    type: "netAmount",
  },
];

const tableHeaders = [
  {
    header: "Item Group",
  },
  {
    header: "Item Name",
  },
  {
    header: "Remarks",
  },
  {
    header: "Make",
  },
  {
    header: "HSN",
  },
  {
    header: "Quantity",
  },
  {
    header: "UOM",
  },
  {
    header: "Rate",
  },
  {
    header: "Discount Mode",
  },
  {
    header: "Discount",
  },
  {
    header: "Rate After Discount",
  },
  {
    header: "Tax%",
  },
  {
    header: "Amount",
  },
  {
    header: "Actions",
  },
];

const discount_Type = [
  {
    value: "",
    label: "Select",
    name: "Select",
  },
  {
    value: 1,
    label: "Amount",
    name: "Amount",
  },
  {
    value: 2,
    label: "Percentage",
    name: "Percentage",
  },
];

export default QuotationLineItems;
