import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {
  CUSTOMER_PAGINATED_LIST,
  CREATE_CUSTOMER_MASTER,
  CUSTOMER_MASTER_VIEW_BY_ID,
  CUSTOMER_MASTER_UPDATE,
  GET_ALL_FILES,
  DELETE_CUSTOMER_FILES,
  UPLOAD_CUSTOMER_FILES,
  GET_ALL_LEDGER_DATA,
  ENTITY_TYPE_DATA,
  CUSTOMER_GST_VERIFICATION,
} from "./actionTypes";
import {
  CustomerPaginatedListSuccessfull,
  CreateCustomerMasterSuccessfull,
  CustomerMasterViewByIdSuccessfull,
  setCustomerMasterUpdateSuccessfull,
  getFilesDataSuccessfull,
  deleteCustomerMasterFilesSuccessfull,
  getFilesData,
  UploadCustomerMasterFilesSuccessfull,
  getAllLedgerDataSuccessfull,
  getEntityTypeDataSuccessfull,
  getCustomerGstVerificationSuccessfull,
} from "./actions";
import { getList, getListurl, postAdd } from "../../../helpers/Server_Helper";
import swal from "sweetalert";
import { serverApi, showSpinner } from "../../../helpers/Consts";
import { hideSpinner } from "../../../Utilities/Validations";
import { getBillingShippingList } from "../../../store/Global/DropDownApis/actions";

function* getCustomerPaginatedList({ payload: { url, data } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(CustomerPaginatedListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* createCustomerMaster({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    yield put(CreateCustomerMasterSuccessfull({ response }));
    swal(response.message, { icon: "success" });
    history.push("/Customer_Master");
    hideSpinner(true);
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

function* customerMasterViewById({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    yield put(CustomerMasterViewByIdSuccessfull({ response }));

    swal(response.data.message, { icon: "success" });
    history.push("/Customer_Master");
    hideSpinner(true);
  } catch (error) {
    swal("error:" + error, { icon: "danger" });
    console.log(error);
  }
}

function* setCustomerMasterUpdateData({
  payload: { url, data, history, isSales = false, customerId },
}) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(setCustomerMasterUpdateSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      if (isSales) {
        hideSpinner(true);
        var CustomerUrl =
          serverApi.GET_ALL_BILLING_SHIPPING +
          customerId +
          "/" +
          JSON.parse(localStorage.getItem("authUser")).cipher;
        yield put(getBillingShippingList(CustomerUrl, history));
        return;
      } else {
        history.push("/Customer_Master");
        hideSpinner(true);
      }
    } else {
      swal(response.data.message, { icon: "error" });
      hideSpinner(true);
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
        hideSpinner(true);

      }
    }
  }
}

function* getAllCustomerFiles({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getFilesDataSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* GetLedgersData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getAllLedgerDataSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* deleteCustomerFiles({ payload: { url, history, id } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(deleteCustomerMasterFilesSuccessfull({ response }));
    if (response) {
      swal("Deleted Successfully", { icon: "success" });
      let Url =
        serverApi.GET_ALL_FILES +
        "40/" +
        id +
        "/" +
        localStorage.getItem("companyId");
      yield put(getFilesData(Url, history));
    }
  } catch (error) {
    console.log(error);
  }
}
function* UploadCustomerMasterFilesData({
  payload: { url, data, history, id },
}) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(UploadCustomerMasterFilesSuccessfull({ response }));
    if (response) {
      swal("Uploaded Successfully", { icon: "success" });
      let Url =
        serverApi.GET_ALL_FILES +
        "40/" +
        id +
        "/" +
        localStorage.getItem("companyId");
      yield put(getFilesData(Url, history));
    }
  } catch (error) {
    console.log(error);
  }
}
function* EntityTypeData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getEntityTypeDataSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* gstCustomerVerificationaData({ payload: { url, id, history } }) {
  try {
    const response = yield call(getListurl, url, id);
    var data = response;
    data.id = id;
    yield put(getCustomerGstVerificationSuccessfull({ response }));
    if (response.data.status) {
      swal("Customer GST Verified Successfully..!", { icon: "success" });
    } else {
      swal("Customer GST Verification Failed..!", { icon: "error" });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchCustomerData() {
  yield takeEvery(CUSTOMER_PAGINATED_LIST, getCustomerPaginatedList);
  yield takeEvery(CREATE_CUSTOMER_MASTER, createCustomerMaster);
  yield takeEvery(CUSTOMER_MASTER_VIEW_BY_ID, customerMasterViewById);
  yield takeEvery(CUSTOMER_MASTER_UPDATE, setCustomerMasterUpdateData);
  yield takeEvery(GET_ALL_FILES, getAllCustomerFiles);
  yield takeEvery(DELETE_CUSTOMER_FILES, deleteCustomerFiles);
  yield takeEvery(UPLOAD_CUSTOMER_FILES, UploadCustomerMasterFilesData);
  yield takeEvery(GET_ALL_LEDGER_DATA, GetLedgersData);
  yield takeEvery(ENTITY_TYPE_DATA, EntityTypeData);
  yield takeEvery(CUSTOMER_GST_VERIFICATION, gstCustomerVerificationaData);
}

function* CustomerMasterListSaga() {
  yield all([fork(watchCustomerData)]);
}

export default CustomerMasterListSaga;
