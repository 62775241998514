import {
  INDENT_LIST,
  INDENT_LIST_SUCCESSFULL,
  CREATE_INDNET,
  CREATE_INDNET_SUCCESSFULL,
  ACTIONS,
  ACTIONS_SUCCESSFULL,
  SEE_THE_LOGS,
  SEE_THE_LOGS_SUCCESSFULL,
  UPDATE_INDNET,
  UPDATE_INDNET_SUCCESSFULL,
  PENDING_STOCK,
  PENDING_STOCK_SUCCESSFULL,
  PENDING_STOCKPO_AND_RECEIPT,
  PENDING_STOCKPO_AND_RECEIPT_SUCCESSFULL,
  GET_FILES_OF_INDENT,
  GET_FILES_OF_INDENT_SUCCESSFULL,
  GET_DOWNLOAD_PDF,
  GET_DOWNLOAD_PDF_SUCCESSFULL,
  GET_COPYOF_INDENTS,
  GET_COPYOF_INDENTS_SUCCESSFULL,
  CREATE_PROJECT,
  CREATE_PROJECT_SUCCESSFULL,
  INDENT_LINEITEMS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESSFULL,
  GET_FILES_OF_INVOICE,
  GET_FILES_OF_CHALLAN,
  GET_FILES_OF_SUPPDOCS,
  VALIDATE_ITEM_IN_OPENINDENT,
  VALIDATE_ITEM_IN_OPENINDENT_SUCCESSFULL,
  GETITEMSLISTFORINDENT,
  GETITEMSLISTFORINDENT_SUCCESSFULL,
  GETITEMGROUPSFORINDENT,
  GETITEMGROUPSFORINDENT_SUCCESSFULL,
  CLEAR_INDENT_LINEITEMS,
  GET_ITEM_DETAILS_BY_POID_FOR_INDENT,
  GET_ITEM_DETAILS_BY_POID_FOR_INDENT_SUCCESSFULL,
  GET_ITEMSLIST_BY_ITEMGROUPID_COMPANYID,
  GET_ITEMSLIST_BY_ITEMGROUPID_COMPANYID_SUCCESSFULL,
  GET_ITEMDETAILS_BY_ITEMID,
  GET_ITEMDETAILS_BY_ITEMID_SUCCESSFULL,
  GET_ITEMDETAILS,
  GET_ITEMDETAILS_SUCCESSFULL,
  VALIDATE_ITEM,
  VALIDATE_ITEM_SUCCESSFULL,
} from "./actionTypes";

export const getIndentList = (url, data, history) => {
  return {
    type: INDENT_LIST,
    payload: { url, data, history },
  };
};

export const indentListSuccessfull = (data) => {
  return {
    type: INDENT_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getCreateIndent = (url, data, history) => {
  return {
    type: CREATE_INDNET,
    payload: { url, data, history },
  };
};
export const CreateIndentSuccessfull = (data) => {
  return {
    type: CREATE_INDNET_SUCCESSFULL,
    payload: data,
  };
};
export const UpdateIndent = (url, data, history) => {
  return {
    type: UPDATE_INDNET,
    payload: { url, data, history },
  };
};
export const UpdateIndentSuccessfull = (data) => {
  return {
    type: UPDATE_INDNET_SUCCESSFULL,
    payload: data,
  };
};

export const actions = (url, data, history) => {
  return {
    type: ACTIONS,
    payload: { url, data, history },
  };
};
export const actionsSuccessfull = (data) => {
  return {
    type: ACTIONS_SUCCESSFULL,
    payload: data,
  };
};

export const logs = (url, history) => {
  return {
    type: SEE_THE_LOGS,
    payload: { url, history },
  };
};
export const logsSuccessfull = (data) => {
  return {
    type: SEE_THE_LOGS_SUCCESSFULL,
    payload: data,
  };
};
export const getFiles = (url, history, sourceMenu) => {
  return {
    type: GET_FILES_OF_INDENT,
    payload: { url, history, sourceMenu },
  };
};
export const getFilesSuccessfull = (data) => {
  return {
    type: GET_FILES_OF_INDENT_SUCCESSFULL,
    payload: data,
  };
};
export const getInvoiceFilesSuccessfull = (data) => {
  return {
    type: GET_FILES_OF_INVOICE,
    payload: data,
  };
};
export const getChallanFilesSuccessfull = (data) => {
  return {
    type: GET_FILES_OF_CHALLAN,
    payload: data,
  };
};
export const getSuppdocsFilesSuccessfull = (data) => {
  return {
    type: GET_FILES_OF_SUPPDOCS,
    payload: data,
  };
};

export const getIndentPdf = (url, history) => {
  return {
    type: GET_DOWNLOAD_PDF,
    payload: { url, history },
  };
};
export const getIndentPdfSuccessfull = (data) => {
  return {
    type: GET_DOWNLOAD_PDF_SUCCESSFULL,
    payload: data,
  };
};

export const getPendingStock = (url, data, history, id) => {
  return {
    type: PENDING_STOCK,
    payload: { url, data, history, id },
  };
};
export const PendingStockSuccessfull = (data, id) => {
  return {
    type: PENDING_STOCK_SUCCESSFULL,
    payload: data,
    id,
  };
};
export const getPendingStockPoandReceipt = (url, data, history,id) => {
  return {
    type: PENDING_STOCKPO_AND_RECEIPT,
    payload: { url, data, history ,id},
  };
};
export const PendingStockPoandReceiptSuccessfull = (data) => {
  return {
    type: PENDING_STOCKPO_AND_RECEIPT_SUCCESSFULL,
    payload: data,
  };
};
export const getCopyOfIndents = (url, data, history) => {
  return {
    type: GET_COPYOF_INDENTS,
    payload: { url, data, history },
  };
};
export const getCopyOfIndentsSuccessfull = (data) => {
  return {
    type: GET_COPYOF_INDENTS_SUCCESSFULL,
    payload: data,
  };
};

export const createProject = (url, data, history) => {
  return {
    type: CREATE_PROJECT,
    payload: { url, data, history },
  };
};

export const projectSuccessfull = (data) => {
  return {
    type: CREATE_PROJECT_SUCCESSFULL,
    payload: data,
  };
};
export const updateProject = (url, data, history) => {
  return {
    type: UPDATE_PROJECT,
    payload: { url, data, history },
  };
};

export const updateProjectSuccessfull = (data) => {
  return {
    type: UPDATE_PROJECT_SUCCESSFULL,
    payload: data,
  };
};

export const IndentLineItemsProps = (data) => {
  return {
    type: INDENT_LINEITEMS,
    payload: data,
  };
};

export const ClearIndentLineItemsProps = (data) => {
  return {
    type: CLEAR_INDENT_LINEITEMS,
    payload: data,
  };
};
export const validateItem = (url, callback, dropdowndata) => {
  return {
    type: VALIDATE_ITEM_IN_OPENINDENT,
    payload: { url, callback, dropdowndata },
  };
};

export const validateItemSuccessfull = (data) => {
  return {
    type: VALIDATE_ITEM_IN_OPENINDENT_SUCCESSFULL,
    payload: data,
  };
};
export const getItemDetailsByPoIdForIndent = (url, history, source) => {
  return {
   type: GET_ITEM_DETAILS_BY_POID_FOR_INDENT,
   payload: {url, history, source},
  }
}

export const getItemDetailsByPoIdForIndentSuccessfull = (data) => {
 return {
   type: GET_ITEM_DETAILS_BY_POID_FOR_INDENT_SUCCESSFULL,
   payload: data,

 }
}
//call back methods
export const getItemsListforIndent = (url, data, callback, itemgrpId) => {
  return {
    type: GETITEMSLISTFORINDENT,
    payload: { url, data, callback, itemgrpId },
  };
};

export const getItemsListForIndentSuccessfull = (data) => {
  return {
    type: GETITEMSLISTFORINDENT_SUCCESSFULL,
    payload: data,
  };
};

export const getItemGroupListforindent = (url, history,callback,Id) => {
  return {
    type: GETITEMGROUPSFORINDENT,
    payload: { url, history,callback,Id},
  };
};
export const ItemGroupListForIndentSuccessfull = (data) => {
  return {
    type: GETITEMGROUPSFORINDENT_SUCCESSFULL,
    payload: data,
  };
};

export const getItemsListByItemGroupId = (url, data, callback, itemgrpId) => {
  return {
    type: GET_ITEMSLIST_BY_ITEMGROUPID_COMPANYID,
    payload: { url, data, callback, itemgrpId },
  };
};

export const getItemsListByItemGroupIdSuccessfull = (data) => {
  return {
    type: GET_ITEMSLIST_BY_ITEMGROUPID_COMPANYID_SUCCESSFULL,
    payload: data,
  };
};

export const getItemdetailsByItemId = (url, data, callback, itemId) => {
  return {
    type: GET_ITEMDETAILS_BY_ITEMID,
    payload: { url, data, callback, itemId },
  };
};

export const getItemdetailsByItemIdSuccessfull = (data) => {
  return {
    type: GET_ITEMDETAILS_BY_ITEMID_SUCCESSFULL,
    payload: data,
  };
};

export const getItemDetails = (url, data,selectedValue,branchId,selectedItemCode,history) => {
  return {
    type: GET_ITEMDETAILS,
    payload: { url, data,selectedValue,branchId,selectedItemCode, history },
  };
};
export const ItemDetailsSuccessfull = (data) => {
  return {
    type: GET_ITEMDETAILS_SUCCESSFULL,
    payload: data,
  };
};

export const itemValidate = (url, history) => {
  return {
    type: VALIDATE_ITEM,
    payload: { url, history },
  };
};
export const itemValidateSuccessfull = (data) => {
  return {
    type: VALIDATE_ITEM_SUCCESSFULL,
    payload: data,
  };
};

