import { Component } from "react";
import { connect } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import { withRouter } from "react-router-dom";
import {
  wastageEntryList,
  DeleteWastageTypeEntry,
} from "../../../store/JuteProduction/WastageEntry/actions";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridDeleteIcon from "../../../assets/images/deleteIconButton.png";

class WastageEntryIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      startIndex: "",
      lastIndex: "",
      wastageEntryList: [],
      fromDate: "",
      toDate: "",
    };
  }
  componentDidMount() {
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      fromDate: "01-01-1989",
      toDate: "10-01-1989",
    };
    this.props.wastageEntryList(
      serverApi.GET_WASTAGE_ENTRY,
      data,
      this.props.history
    );
  }

  handleSearch = (searchData) => {
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      fromDate: searchData.fromDate,
      toDate: searchData.toDate,
    };
    this.props.wastageEntryList(
      serverApi.GET_WASTAGE_ENTRY,
      data,
      this.props.history
    );
    this.setState({
      fromDate: searchData.fromDate,
      toDate: searchData.toDate,
    });
  };
  componentWillReceiveProps(props) {
    if (props.wastageEntryList) {
      this.setState({
        wastageEntryList: props.wastageEntryListData.data,
      });
    }
  }

  handledeleteRecord = (row) => {
    this.props.DeleteWastageTypeEntry(
      serverApi.DELETE_WASTAGE_ENTRY + "/" + row.id,
      this.props.history,
      this.state.fromDate,
      this.state.toDate
    );
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.wastageEntryList}
          mcolumns={Columns}
          status={true}
          micon={GridDeleteIcon}
          actions={false}
          deleteIcon={true}
          filter_form={"wastageEntryFilter"}
          handleSearch={this.handleSearch}
          handledeleteRecord={this.handledeleteRecord}
          url="/create_beaming_production"
        />
      </>
    );
  }
}
// machine master header
const Columns = [
  {
    hidden: false,
    Header: "Entry Date Time",
    accessor: "entryDate",
  },
  {
    hidden: false,
    Header: "Wastage Type",
    accessor: "wastageType",
  },
  {
    hidden: false,
    Header: "Trolly No",
    accessor: "trollyNo",
  },
  {
    hidden: false,
    Header: "Net Weight",
    accessor: "netWeight",
  },
  {
    hidden: false,
    Header: "Spell",
    accessor: "spell",
  },
];
const mapStatetoProps = (state) => {
  const { wastageEntryListData } = state.wastageEntryListReducer;
  return { wastageEntryListData };
};

export default withRouter(
  connect(mapStatetoProps, {
    wastageEntryList,
    DeleteWastageTypeEntry,
  })(WastageEntryIndex)
);
