import React, { Component } from "react";
import { Grid } from "@mui/material";
import PaySchemeFillDetails from "../../HR_Management/PaySchemeCreation/PaySchemeFillDetails";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class CreatePayCreation extends Component {
  constructor() {
    super();
    this.state = {
      currentStep: 1,
    };
  }

  handleSelectChange = (selectedValue, selectedName, name) => {};
  render() {
    const { currentStep } = this.state;

    return (
      <div>
        <Grid className="packageMasterContainer">
          <Grid>
            {currentStep === 1 && (
              <>
                <PaySchemeFillDetails
                  onSelectDep={this.handleSelectChange}
                  currentStep={currentStep}
                />
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(connect()(CreatePayCreation));
