import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { Grid, Box } from "@mui/material";
import { getPurchaseOrderList } from "../../../store/Purchase/PurchaseOrder/actions";
import { getWorkOrderList } from "../../../store/Purchase/WorkOrder/actions";
import {
  getBranchList,
  getSupplierList,
  getProjectList,
  getClientList,
} from "../../../store/Global/DropDownApis/actions";
import {
  getPoLineItemsListByPoIds,
  InwardLineItemsProps,
  checkValidSuppIdChallanInvoiceNo,
} from "../../../store/Purchase/Inward/actions";
import { hideSpinner, serverApi, showSpinner } from "../../../helpers/Consts";
import moment from "moment";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import swal from "sweetalert";

class FillDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      inwardNo: this.props.InwardFillDetails.inwardNo,
      inwardDate: moment(this.props.InwardFillDetails.inwardDate).format(
        "YYYY-MM-DD"
      ),
      inwardtime: this.props.InwardFillDetails.inwardtime,
      challanno: this.props.InwardFillDetails.challanno,
      challannoDate: this.props.InwardFillDetails.challannoDate,
      branchId: this.props.InwardFillDetails.branchId,
      projectId: this.props.InwardFillDetails.projectId,
      supplierId: this.props.InwardFillDetails.supplierId,
      supplierName: this.props.InwardFillDetails.supplierName,
      source: this.props.InwardFillDetails.source,
      recordType: this.props.InwardFillDetails.recordType,
      companyId: localStorage.getItem("companyId"),
      internalNote: this.props.InwardFillDetails.internalNote,
      createdBy: this.props.InwardFillDetails.createdBy,
      isCommitted: this.props.InwardFillDetails.isCommitted,
      inwardDetails: this.props.InwardFillDetails.inwardDetails,
      consignmentNumber: this.props.InwardFillDetails.consignmentNumber,
      consignmentDate: this.props.InwardFillDetails.consignmentDate,
      vehicleNumber: this.props.InwardFillDetails.vehicleNumber,
      driverContactNumber: this.props.InwardFillDetails.driverContactNumber,
      driverName: this.props.InwardFillDetails.driverName,
      remarks: this.props.InwardFillDetails.remarks,
      taxType: this.props.InwardFillDetails.taxType,
      taxTypeDesc: this.props.InwardFillDetails.taxTypeDesc,
      goodsType:
        this.props.location.pathname !== "/create_WorkReport"
          ? this.props.InwardFillDetails.goodsType
          : "SRN",
      invoiceNumber: this.props.InwardFillDetails.invoiceNumber,
      invoiceDate: this.props.InwardFillDetails.invoiceDate,
      ewaybillno: this.props.InwardFillDetails.ewaybillno,
      ewaybillnoDate: this.props.InwardFillDetails.ewaybillnoDate,
      customerId: this.props.InwardFillDetails.customerId,
      status: this.props.InwardFillDetails.status,
      PoItem: this.props.InwardFillDetails.PoItem,
      poDate: this.props.InwardFillDetails.poDate,
      challanTypeNo: this.props.InwardFillDetails.challanTypeNo,
      srDate: this.props.InwardFillDetails.srDate,
      SRPrintNO: this.props.InwardFillDetails.SRPrintNO,
      GateEntryNo: this.props.InwardFillDetails.GateEntryNo,
      TCSPercentage: this.props.InwardFillDetails.TCSPercentage,
      AdvancePercentage: this.props.InwardFillDetails.AdvancePercentage,
      AdvanceAmount: this.props.InwardFillDetails.AdvanceAmount,
      termsNcondition: this.props.InwardFillDetails.termsNcondition,
      selectTermsNconditions:
        this.props.InwardFillDetails.selectTermsNconditions,
      billpassDate: this.props.InwardFillDetails.billpassDate,
      billPassNo: this.props.InwardFillDetails.billPassNo,
      SrNo: this.props.InwardFillDetails.SrNo,
      paymentDueDate: this.props.InwardFillDetails.paymentDueDate,
      invoiceAmount: this.props.InwardFillDetails.invoiceAmount,
      invoiceRecvdDate: this.props.InwardFillDetails.invoiceRecvdDate,
      ledgers: this.props.InwardFillDetails.ledgers,
      tdsPayable: this.props.InwardFillDetails.tdsPayable,
      poIds: [],
      poList: [],
      branchListData: [],
      supplierListData: [],
      projectListData: [],
      ClientList: [],
      isInward: false,
      isWorkReport: false,
      isMaterialInspection: false,
      isStoreReceipt: false,
      isBillPass: false,
      showInwardFields: this.props.ShowFields.showInwardFields,
      showWRFields: this.props.ShowFields.showWRFields,
      showMIFields: this.props.ShowFields.showMIFields,
      showSRFields: this.props.ShowFields.showSRFields,
      showBPFields: this.props.ShowFields.showBPFields,
      gstValidation: false,
      isDropdownOpen: false,
      locationState: "",
    };
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.setState({
      locationState: this.props.location,
    });
    // this lines of code is commented here for the future use ----start
    // if (this.props.location.pathname === "/create_inward") {
    //   const data = {
    //     branchId: "",
    //     companyId: localStorage.getItem("companyId"),
    //     endDate: this.state.endDate,
    //     financialYear: localStorage.getItem("acadamicYear"),
    //     customerId: "",
    //     phase: "",
    //     project: "",
    //     purchaseType: "store",
    //     recordType: "PO",
    //     startDate: this.state.startDate,
    //     status: "3",
    //     supplierId: this.state.userDit.roleId === 100 ? this.state.userDit.suppCode : this.props.InwardFillDetails.supplierId
    //       ? this.props.InwardFillDetails.supplierId
    //       : "",
    //     startIndex: 0,
    //     lastIndex: 20,
    //   };
    //   this.props.getPurchaseOrderList(
    //     serverApi.WORKORDER_LIST_BY_CMPY_ID,
    //     data,
    //     this.props.history
    //   );
    // } else {
    //   const data = {
    //     branchId: "",
    //     companyId: localStorage.getItem("companyId"),
    //     endDate: "",
    //     financialYear: localStorage.getItem("acadamicYear"),
    //     phase: "",
    //     project: "",
    //     purchaseType: "store",
    //     recordType: "WO",
    //     startDate: "",
    //     status: "3",
    //     supplierId: "",
    //     startIndex: 0,
    //     lastIndex: 20,
    //   };
    //   this.props.getWorkOrderList(
    //     serverApi.WORKORDER_LIST_BY_CMPY_ID,
    //     data,
    //     this.props.history
    //   );
    // }

    // this lines of code is commented here for the future use ---end

    // caliing branch dropdown list api
    this.props.getBranchList(
      serverApi.BRANCH_LIST + this.state.companyId,
      this.props.history
    );
    // calling for supplier list dropdown
    this.props.getSupplierList(
      serverApi.GET_ALL_SUPPLIERS_LIST + localStorage.getItem("companyId"),
      this.state.history
    );
    // calling for project list dropdown
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
    };
    this.props.getProjectList(
      serverApi.GET_ALL_PROJECTS,
      data,
      this.props.history
    );
    // calling for client list dropdown
    this.props.getClientList(
      serverApi.GET_CLIENT_LIST + this.state.companyId,
      this.props.history
    );

    if (this.props.location) {
      if (this.props.location.pathname === "/create_inward") {
        this.setState({
          showInwardFields: true,
        });
      }
      if (this.props.location.pathname === "/create_WorkReport") {
        this.setState({
          showWRFields: true,
        });
      }
      if (this.props.location.pathname === "/create_store_bill_pass") {
        let data = {
          billPassNo: this.state.billPassNo,
        };
        this.setState({
          showBPFields: true,
        });
      }
      if (this.props.location.pathname === "/Create_store_receipt") {
        let data = {
          billPassNo: this.state.billPassNo,
        };
        this.setState({
          showSRFields: true,
        });
      }
      if (this.props.location.pathname === "/create_material_inspection") {
        let data = {
          billPassNo: this.state.billPassNo,
        };
        this.setState({
          showMIFields: true,
        });
      }
    }

    
    const currentMonth = moment().format('MM');
    const currentDate = moment().format('DD');
    const fiscalYearStart = moment(`${localStorage.getItem("acadamicYear")}-${currentMonth}-${currentDate}`);
    const today = moment();
    let inwardDate;
    if (today.isBefore(fiscalYearStart)) {
      inwardDate = today.format("YYYY-MM-DD");
    } else {
      inwardDate = fiscalYearStart.format("YYYY-MM-DD");
    }
    this.setState({
      inwardDate:inwardDate
    });
    if (this.state.userDit) {
      this.state.userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              if (item.backDaysAllowable !== undefined) {
                let backDaysAllowable = JSON.parse(item.backDaysAllowable);
                let backDays = backDaysAllowable.bdaystype;
                if (Number(backDays) === 1) {
                  backDays = 7;
                } else if (Number(backDays) === 2) {
                  backDays = 14;
                } else if (Number(backDays) === 3) {
                  backDays = "";
                } else if (Number(backDays) === 4) {
                  backDays = backDaysAllowable.otherBackDateValue;
                } else {
                  backDays = backDaysAllowable.bdaystype;
                }
                let minDate = moment(inwardDate).subtract(
                  Number(backDays),
                  "days"
                );
                let setMinDate = moment(minDate).format("YYYY-MM-DD");
                this.setState({
                  backDaysAllowable: setMinDate,
                });
              }
              if (item.futureDaysAllowable !== undefined) {
                let futureDaysAllowable = JSON.parse(item.futureDaysAllowable);
                let futureDays = futureDaysAllowable.fdaystype;
                if (Number(futureDays) === 1) {
                  futureDays = 7;
                } else if (Number(futureDays) === 2) {
                  futureDays = 14;
                } else if (Number(futureDays) === 3) {
                  futureDays = "";
                } else if (Number(futureDays) === 4) {
                  futureDays = futureDaysAllowable.othervalue;
                } else {
                  futureDays = futureDaysAllowable.fdaystype;
                }
                let maxDate = moment(inwardDate).add(
                  Number(futureDays),
                  "days"
                );
                let setMaxDate = moment(maxDate).format("YYYY-MM-DD");
                this.setState({
                  futureDaysAllowable: setMaxDate,
                });
              }
            });
          }
      });
    }
  }

  componentWillReceiveProps(props) {
    if (this.props.location.pathname === "/create_WorkReport") {
      if (props.workOrder_List) {
        hideSpinner();
        let list = [];
        if (props.workOrder_List.data) {
          props.workOrder_List.data.map((prop) => {
            list.push({
              value: prop.id,
              label: prop.poSequenceNo,
              name: prop.poSequenceNo,
            });
          });
        }
        this.setState({
          poList: list,
        });
      }
    } else {
      if (props.PurchaseOrder_List) {
        hideSpinner();
        let list = [];
        if (props.PurchaseOrder_List.data) {
          props.PurchaseOrder_List.data.map((prop) => {
            list.push({
              value: prop.id,
              label: prop.poSequenceNo,
              name: prop.poSequenceNo,
            });
          });
        }
        this.setState({
          poList: list,
        });
      }
    }
    if (props.branchList) {
      if (props.branchList.data) {
        let list = props.branchList.data.filter((prop) => {
          if (prop.value !== 0) {
            return prop;
          }
        });
        this.setState({
          branchListData: list, // updating branch dropdown api
        });
      }
    }
    if (props.projectList) {
      this.setState({
        projectListData: props.projectList.data, // updating project dropdown api
      });
    }
    if (props.supplierList) {
      if (props.supplierList.data) {
        let list = props.supplierList.data.filter((prop) => {
          if (prop.value !== "0") {
            return prop;
          }
        });
        this.setState({
          supplierListData: list, // updating supplier dropdown api
        });
      }
    }
    // if (props.clientList) {
    //   this.setState({
    //     ClientList: props.clientList.data, // updating client dropdown api
    //   });
    // }

    if (props.clientList) {
      if (props.clientList.data) {
        if (props.clientList.data.length !== 0) {
          let list = [];
          list = props.clientList.data.filter((item) => {
            if (item.value !== 0) {
              return item;
            }
          });

          this.setState({
            ClientList:  props.clientList.data,
          });
        }
      }
    }
    if (this.props.source === "WITHPO") {
      if (this.props.POLineItemsList) {
        // this.props.InwardLineItemsProps(this.props.POLineItemsList);
      }
    }
    if (props.CheckValidData) {
      if (
        props.CheckValidData.status === false &&
        props.CheckValidData.data.Type === "Challan Number"
      ) {
        if (this.state.challanno !== "" && this.state.challanno !== undefined) {
          this.setState({
            challanno: "",
          });
          this.props.InwardFillDetails.challanno = "";
        }
      }
      if (
        props.CheckValidData.status === false &&
        props.CheckValidData.data.Type === "Invoice Number"
      ) {
        if (
          this.state.invoiceNumber !== "" &&
          this.state.invoiceNumber !== undefined
        ) {
          this.setState({
            invoiceNumber: "",
          });
          this.props.InwardFillDetails.invoiceNumber = "";
        }
      }
    }
  }

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    this.setState({
      isDropdownOpen: false,
    });
    if (name === "poList") {
      this.setState({
        poIds: [selectedValue],
        PoItem: selectedValue,
      });
      this.props.InwardFillDetails.PoItem = selectedValue;
      if (selectedValue) {
        let data = {
          poIds: [selectedValue],
          companyId: localStorage.getItem("companyId"),
        };
        this.props.getPoLineItemsListByPoIds(
          serverApi.GET_POLINEITEMS_BYPOIDS,
          data,
          this.props.history
        );
      }
      if (this.props.location.pathname === "/create_WorkReport") {
        this.props.workOrder_List.data.map((item) => {
          if (item.poSequenceNo === selectedName) {
            this.setState({
              projectId: item.projectId,
              branchId: item.branchId,
              customerId: item.customerId,
              taxType: item.taxTypeId,
              gstValidation: true,
              poDate: item.poDate,
            });
            this.props.InwardFillDetails.projectId = item.projectId;
            this.props.InwardFillDetails.branchId = item.branchId;
            this.props.InwardFillDetails.customerId = item.customerId;
            this.props.InwardFillDetails.taxType = item.taxTypeId;
            this.props.InwardFillDetails.poDate = item.poDate;
          }
        });
      } else {
        this.props.PurchaseOrder_List.data.map((item) => {
          if (item.poSequenceNo === selectedName) {
            this.setState({
              projectId: item.projectId,
              branchId: item.branchId,
              customerId: item.customerId,
              taxType: item.taxTypeId,
              gstValidation: true,
              poDate: item.poDate,
            });

            this.props.InwardFillDetails.projectId = item.projectId;
            this.props.InwardFillDetails.branchId = item.branchId;
            this.props.InwardFillDetails.customerId = item.customerId;
            this.props.InwardFillDetails.poSequence = selectedName;
            this.props.InwardFillDetails.taxType = item.taxTypeId;
            this.props.InwardFillDetails.poDate = item.poDate;
          }
        });
      }
    }

    if (name === "branchId") {
      this.setState({
        branchId: selectedValue,
      });
      this.props.InwardFillDetails.branchId = selectedValue;
    }
    if (name === "projectId") {
      this.setState({
        projectId: selectedValue,
      });
      this.props.InwardFillDetails.projectId = selectedValue;
    }
    if (name === "supplierId") {
      let supplierName = selectedName.split("_");
      this.setState({
        supplierId: selectedValue,
        supplierName: supplierName[0],
        PoItem: "",
        branchId: "",
        projectId: "",
        taxType: "",
      });
      this.props.InwardFillDetails.PoItem = "";
      this.props.InwardFillDetails.branchId = "";
      this.props.InwardFillDetails.projectId = "";
      this.props.InwardFillDetails.taxType = "";

      this.props.InwardFillDetails.supplierId = selectedValue;
      this.props.InwardFillDetails.supplierName = supplierName[0];
      if (
        selectedValue &&
        (this.props.source === "WITHPO" || this.props.source === "WITHWO")
      ) {
        if (this.props.location.pathname === "/create_inward") {
          const data = {
            branchId: "",
            companyId: localStorage.getItem("companyId"),
            endDate: "",
            financialYear: localStorage.getItem("acadamicYear"),
            project: "",
            purchaseType: "store",
            recordType: "PO",
            startDate: "",
            status: "3",
            supplierId: selectedValue,
          };
          this.props.getPurchaseOrderList(
            serverApi.OLDWORKORDER_LIST_BY_CMPY_ID,
            data,
            this.props.history
          );
          showSpinner();
        } else {
          const data = {
            branchId: "",
            companyId: localStorage.getItem("companyId"),
            endDate: "",
            financialYear: localStorage.getItem("acadamicYear"),
            phase: "",
            project: "",
            purchaseType: "store",
            recordType: "WO",
            startDate: "",
            status: "3",
            supplierId: selectedValue,
          };
          this.props.getWorkOrderList(
            serverApi.OLDWORKORDER_LIST_BY_CMPY_ID,
            data,
            this.props.history
          );
          showSpinner();
        }
      }
    }
    if (name === "customerId") {
      this.setState({
        customerId: selectedValue,
      });
      this.props.InwardFillDetails.customerId = selectedValue;
    }
    if (name === "taxType") {
      this.setState({
        taxType: selectedValue,
      });
      this.props.InwardFillDetails.taxType = selectedValue;
      this.props.InwardFillDetails.taxTypeDesc = selectedName;
    }
  };

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
    if (key === "challanno") {
      this.setState({
        challanno: value,
      });
      this.props.InwardFillDetails.challanno = value;
    }
    if (key === "consignmentNumber") {
      this.setState({
        consignmentNumber: value,
      });
      this.props.InwardFillDetails.consignmentNumber = value;
    }
    if (key === "vehicleNumber") {
      this.setState({
        vehicleNumber: value,
      });
      this.props.InwardFillDetails.vehicleNumber = value;
    }
    if (key === "driverName") {
      this.setState({
        driverName: value,
      });
      this.props.InwardFillDetails.driverName = value;
    }
    if (key === "remarks") {
      this.setState({
        remarks: value,
      });
      this.props.InwardFillDetails.remarks = value;
    }
    if (key === "invoiceNumber") {
      this.setState({
        invoiceNumber: value,
      });
      this.props.InwardFillDetails.invoiceNumber = value;
    }
    if (key === "ewaybillno") {
      this.setState({
        ewaybillno: value,
      });
      this.props.InwardFillDetails.ewaybillno = value;
    }
    if (key === "inwardNo") {
      this.setState({
        ewaybillno: value,
      });
      this.props.InwardFillDetails.inwardNo = value;
    }
  };

  handleBlur = (key) => (value) => {
    this.setState({ [key]: value });
    if (key === "challanno") {
      let checkData = {
        companyId: localStorage.getItem("companyId"),
        challanno: value,
        invoiceNumber: null,
        supplierId: null,
        fy: localStorage.getItem("acadamicYear"),
      };
      if (value !== "" && value !== undefined) {
        this.props.checkValidSuppIdChallanInvoiceNo(
          serverApi.VALIDATE_SUPPID_CHALLAN_INVOICE_NO,
          checkData,
          this.props.history
        );
      }
      this.setState({
        challanno: value,
      });
      this.props.InwardFillDetails.challanno = value;
    }
    if (key === "invoiceNumber") {
      let checkData = {
        companyId: localStorage.getItem("companyId"),
        challanno: null,
        invoiceNumber: value,
        supplierId: null,
        fy: localStorage.getItem("acadamicYear"),
      };
      if (value !== "" && value !== undefined) {
        this.props.checkValidSuppIdChallanInvoiceNo(
          serverApi.VALIDATE_SUPPID_CHALLAN_INVOICE_NO,
          checkData,
          this.props.history
        );
      }
      this.setState({
        invoiceNumber: value,
      });
      this.props.InwardFillDetails.invoiceNumber = value;
    }
  };
  handleSelect_Date = (e, name) => {
    if (name === "date") {
      let date = moment(e).format();
      let fromdate = date.split("T", 1).toString();

      let poDate = this.state.poDate;
      let formattedPoDate = moment(poDate, "DD-MM-YYYY").format("YYYY-MM-DD");

      if (this.props.source === "WITHPO") {
        if (fromdate >= formattedPoDate) {
          this.setState({
            inwardDate: fromdate,
          });
          this.props.InwardFillDetails.inwardDate = fromdate;
        } else if (this.props.location.pathname === "/create_WorkReport") {
          swal("Date should not be less than Work Order date");
        } else {
          swal("Date should not be less than PO date");
        }
      }else{
        this.setState({
          inwardDate: fromdate,
        });
          this.props.InwardFillDetails.inwardDate = fromdate;
      }
    }
    if (name === "time") {
      let time = moment(e).format();
      let formattedTime = time.split("T", 2);
      this.setState({
        time: time,
      });
      this.props.InwardFillDetails.inwardtime = formattedTime[1];
    }
    if (name === "consignmentDate") {
      let date = moment(e).format();
      let fromdate = date.split("T", 1).toString();
      this.setState({
        consignmentDate: fromdate,
      });
      this.props.InwardFillDetails.consignmentDate = fromdate;
    }
    if (name === "invoiceDate") {
      let date = moment(e).format();
      let fromdate = date.split("T", 1).toString();
      this.setState({
        invoiceDate: fromdate,
      });
      this.props.InwardFillDetails.invoiceDate = fromdate;
    }
    if (name === "challannoDate") {
      let date = moment(e).format();
      let fromdate = date.split("T", 1).toString();
      this.setState({
        challannoDate: fromdate,
      });
      this.props.InwardFillDetails.challannoDate = fromdate;
    }
    if (name === "ewaybillnoDate") {
      let date = moment(e).format();
      let fromdate = date.split("T", 1).toString();
      this.setState({
        ewaybillnoDate: fromdate,
      });
      this.props.InwardFillDetails.ewaybillnoDate = fromdate;
    }
  };

  handleChangetextAreas = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "internalNote") {
      this.props.InwardFillDetails.internalNote = event;
      this.setState({
        internalNote: event,
      });
    }
  };
  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  render() {
    return (
      <>
        <div className="consoleFormContainer" onScroll={this.handleScroll}>
          <h5>Fill Details</h5>
          {this.props.source === "WITHPO" &&
            this.props.locationState.pathname !==
              "/create_material_inspection" &&
            this.props.locationState.pathname !== "/Create_store_receipt" &&
            this.props.locationState.pathname !== "/create_store_bill_pass" &&
            this.props.locationState.state === undefined && (
              <div className="consoleFormBlock">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      {this.state.userDit.roleId !== 100 ? (
                        <Box gridColumn="span 3">
                          <div className="consoleTextFieldBlock">
                            <DynamicSelect
                              arrayOfData={this.state.supplierListData}
                              className="dropdownBlockContainer"
                              menuPosition="fixed"
                              menuPlacement="auto"
                              onSelectChange={this.handlePropSelectChange}
                              handleMenuOpen={this.handleMenuOpen}
                              isDropdownOpen={this.state.isDropdownOpen}
                              selected={this.state.supplierId}
                              update={this.state.supplierId ? 1 : 0}
                              name="supplierId"
                              label="Supplier"
                              required
                            />
                          </div>
                        </Box>
                      ) : (
                        ""
                      )}
                      <Box gridColumn="span 4">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            arrayOfData={this.state.poList}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            handleMenuOpen={this.handleMenuOpen}
                            isDropdownOpen={this.state.isDropdownOpen}
                            selected={this.state.PoItem}
                            update={this.state.PoItem ? 1 : 0}
                            name="poList"
                            label="PO List"
                            required
                            isDisabled={
                              this.state.supplierId === "" ? true : false
                            }
                          />
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            )}
          {this.props.source === "WITHWO" &&
            ((this.props.locationState.pathname !==
              "/create_material_inspection" &&
              this.props.locationState.pathname !==
                "/create_store_bill_pass") ||
              this.props.locationState.state === undefined) && (
              <div className="consoleFormBlock">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            arrayOfData={this.state.supplierListData}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            handleMenuOpen={this.handleMenuOpen}
                            isDropdownOpen={this.state.isDropdownOpen}
                            selected={this.state.supplierId}
                            update={this.state.supplierId ? 1 : 0}
                            name="supplierId"
                            label="Supplier"
                            required
                            isDisabled={
                              this.props.locationState.pathname ===
                              "/create_store_bill_pass"
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 4">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            arrayOfData={this.state.poList}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            handleMenuOpen={this.handleMenuOpen}
                            isDropdownOpen={this.state.isDropdownOpen}
                            selected={this.state.PoItem}
                            update={this.state.PoItem ? 1 : 0}
                            name="poList"
                            label="Wo List"
                            required
                            isDisabled={
                              this.props.locationState.pathname ===
                              "/create_store_bill_pass"
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            )}
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                {this.state.showSRFields === true ||
                this.state.showBPFields === true ? (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>SR Date</label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="dd-MM-yyyy"
                            minDate={
                              this.state.backDaysAllowable !== "" &&
                              this.state.backDaysAllowable !== undefined
                                ? this.state.backDaysAllowable
                                : this.state.inwardDate
                            }
                            maxDate={
                              this.state.futureDaysAllowable !== "" &&
                              this.state.futureDaysAllowable !== undefined
                                ? this.state.futureDaysAllowable
                                : this.state.inwardDate
                            }
                            value={this.state.srDate}
                            fullWidth
                            onChange={(e) => this.handleSelect_Date(e, "date")}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  readOnly={true}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                ) : (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Date<label className="starcolor">*</label>
                          </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="dd-MM-yyyy"
                            minDate={
                              this.state.backDaysAllowable !== "" &&
                              this.state.backDaysAllowable !== undefined
                                ? this.state.backDaysAllowable
                                : this.state.inwardDate
                            }
                            maxDate={
                              this.state.futureDaysAllowable !== "" &&
                              this.state.futureDaysAllowable !== undefined
                                ? this.state.futureDaysAllowable
                                : this.state.inwardDate
                            }
                            value={this.state.inwardDate}
                            fullWidth
                            onChange={(e) => this.handleSelect_Date(e, "date")}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  readOnly={true}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                           
                            disabled={this.props.locationState.state === undefined ? false
                            : true}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                )}
                {this.state.showBPFields === true && (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Bill Pass Date<label className="starcolor">*</label>
                          </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="financial_year"
                            id="financial_year"
                            inputFormat="dd-MM-yyyy"
                            minDate={
                              this.state.backDaysAllowable !== "" &&
                              this.state.backDaysAllowable !== undefined
                                ? this.state.backDaysAllowable
                                : this.state.inwardDate
                            }
                            maxDate={
                              this.state.futureDaysAllowable !== "" &&
                              this.state.futureDaysAllowable !== undefined
                                ? this.state.futureDaysAllowable
                                : this.state.inwardDate
                            }
                            value={this.state.billpassDate}
                            fullWidth
                            onChange={(e) => this.handleSelect_Date(e, "date")}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  readOnly={true}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                )}
                {this.state.showSRFields === true && (
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="SR Print Number"
                      placeholder="Enter here"
                      value={this.state.SRPrintNO}
                      readOnly={true}
                      onChange={this.handleChange("SRPrintNO")}
                    />
                  </Box>
                )}
                {this.state.showBPFields === true && (
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      label="Bill Pass Number"
                      placeholder="Enter here"
                      value={this.state.billPassNo}
                      onChange={this.handleChange("SRNO")}
                      disabled={true}
                    />
                  </Box>
                )}
                {this.state.showBPFields === true && (
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="SR Number"
                      placeholder="Enter here"
                      value={this.state.SRPrintNO}
                      readOnly={true}
                      onChange={this.handleChange("SR NO")}
                      disabled={true}
                    />
                  </Box>
                )}
                {this.state.showBPFields === true && (
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      label="Supplier"
                      placeholder="Enter here"
                      value={this.state.supplierName}
                      onChange={this.handleChange("SR NO")}
                      disabled={true}
                    />
                  </Box>
                )}
                {this.state.showSRFields === true ||
                this.state.showBPFields === true ? (
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      label="Gate Entry Number"
                      placeholder="Enter here"
                      value={this.state.GateEntryNo}
                      readOnly={true}
                      onChange={this.handleChange("GateEntryNo")}
                      disabled={true}
                    />
                  </Box>
                ) : (
                  ""
                )}
                {this.state.showSRFields === true && (
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      label="Supplier"
                      placeholder="Enter here"
                      value={this.state.supplierName}
                      readOnly={true}
                      onChange={this.handleChange("SR NO")}
                    />
                  </Box>
                )}
                {this.state.showMIFields === true && (
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      label="Inward Number"
                      placeholder="Enter here"
                      value={this.state.inwardNo}
                      readOnly={true}
                      onChange={this.handleChange("inwardNo")}
                    />
                  </Box>
                )}
                {this.state.showMIFields === true && (
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      label="Supplier"
                      placeholder="Enter here"
                      value={this.state.supplierName}
                      readOnly={true}
                      onChange={this.handleChange("SR NO")}
                    />
                  </Box>
                )}

                {this.state.showMIFields === true ||
                this.state.showSRFields === true ||
                this.state.showBPFields === true ? (
                  ""
                ) : (
                  <Box gridColumn="span 3">
                    <div className="TextFieldBlock">
                      <label>
                        Time<label className="starcolor">*</label>
                      </label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="Time"
                        value={this.state.time}
                        onChange={(e) => this.handleSelect_Date(e, "time")}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input
                              ref={inputRef}
                              {...inputProps}
                              readOnly={true}
                            />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                )}
                <Box gridColumn="span 3">
                  <TextFieldNormal
                    label="Challan Number"
                    placeholder="Enter here"
                    value={this.state.challanno}
                    onChange={this.handleChange("challanno")}
                    handleBlur={this.handleBlur("challanno")}
                  />
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>Challan Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          name="financial_year"
                          placeholder="Choose Date"
                          inputFormat="dd-MM-yyyy"
                          id="financial_year"
                          // minDate={this.state.challannoDate}
                          // maxDate={this.state.challannoDate}
                          value={this.state.challannoDate}
                          fullWidth
                          onChange={(e) =>
                            this.handleSelect_Date(e, "challannoDate")
                          }
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Box>
                {this.state.showMIFields === true ? (
                  ""
                ) : (
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Vehical Number"
                      caps={"ON"}
                      placeholder="Enter here"
                      value={this.state.vehicleNumber}
                      onChange={this.handleChange("vehicleNumber")}
                    />
                  </Box>
                )}
                {this.state.showMIFields === true ||
                this.state.showSRFields === true ||
                this.state.showBPFields === true ? (
                  ""
                ) : (
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Driver Name"
                      placeholder="Enter here"
                      value={this.state.driverName}
                      onChange={this.handleChange("driverName")}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </div>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                {this.props.source !== "WITHPO" &&
                this.props.source !== "WITHWO" ? (
                  this.state.userDit.roleId === 100 || this.state.showBPFields ? (
                    ""
                  ) : (
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.supplierListData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          handleMenuOpen={this.handleMenuOpen}
                          isDropdownOpen={this.state.isDropdownOpen}
                          selected={this.state.supplierId}
                          update={this.state.supplierId ? 1 : 0}
                          name="supplierId"
                          label="Supplier"
                          required
                          isDisabled={(this.props.locationState.pathname ===
                            "/create_material_inspection" ) ||  (this.props.locationState.state !== undefined && 
                              this.props.locationState.pathname === "/create_inward")}

                          
                        />
                      </div>
                    </Box>
                  )
                ) : (
                  ""
                )}
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.branchListData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      handleMenuOpen={this.handleMenuOpen}
                      isDropdownOpen={this.state.isDropdownOpen}
                      selected={this.state.branchId}
                      isDisabled={
                        (this.props.locationState.state !== undefined && 
                          this.props.locationState.pathname === "/create_inward") ? true :

                        this.props.locationState.pathname ===
                            "/create_material_inspection"?true:
                        this.props.locationState.pathname ===
                        "/create_store_bill_pass"
                          ? true
                          : this.props.locationState.pathname ===
                            "/Create_store_receipt"
                          ? true
                          : this.props.source === "WITHOUTPO"
                          ? this.state.supplierId === ""
                            ? true
                            : false
                          : this.state.showSRFields === true
                          ? true
                          : this.state.showBPFields === true
                          ? true
                          : this.state.PoItem === "" &&
                            this.props.source === "WITHPO"
                          ? true
                          : this.state.supplierId === ""
                          ? true
                          : false
                          ? true
                          : this.state.showMIFields === true
                          ? true
                          : this.state.showInwardFields === true
                          ? this.state.poList !== ""
                            ? true
                            : false
                          : false
                      }
                      update={this.state.branchId ? 1 : 0}
                      name="branchId"
                      label="Branch"
                      required
                    />
                  </div>
                </Box>

                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={
                        this.state.projectListData &&
                        this.state.projectListData.map((item) => ({
                          label: item.name,
                          value: item.projectId,
                          name: item.name,
                        }))
                      }
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      handleMenuOpen={this.handleMenuOpen}
                      isDropdownOpen={this.state.isDropdownOpen}
                      selected={this.state.projectId}
                      // isDisabled = {this.state.showSRFields === true?true:this.state.showBPFields === true?true:false}
                      isDisabled={this.props.locationState.pathname ===
                        "/create_material_inspection" || this.state.showBPFields?true:
                        this.props.source === "WITHOUTPO"
                          ? this.state.branchId === ""
                            ? true
                            : false
                          : this.state.showSRFields === true
                          ? true
                          : this.state.gstValidation === true
                          ? true
                          : this.state.showBPFields === true
                          ? true
                          : this.state.showMIFields === true
                          ? true
                          : this.props.source === "WITHWO"
                          ? true
                          : this.state.branchId === "" ||
                            this.state.branchId === undefined
                          ? true
                          : this.state.showInwardFields === true
                          ? this.state.poList !== ""
                            ? true
                            : false
                          : false
                      }
                      update={this.state.projectId ? 1 : 0}
                      name="projectId"
                      label="Project"
                      required
                    />
                  </div>
                </Box>
                {this.state.showMIFields === true && (
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      label="Challan type Number"
                      placeholder="Enter here"
                      value={this.state.challanTypeNo}
                      readOnly={true}
                      onChange={this.handleChange("consignmentNumber")}
                    />
                  </Box>
                )}
                {this.state.showMIFields === true ||
                this.state.showSRFields === true ||
                this.state.showBPFields === true ? (
                  ""
                ) : (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.ClientList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        handleMenuOpen={this.handleMenuOpen}
                        isDropdownOpen={this.state.isDropdownOpen}
                        selected={this.state.customerId}
                        update={this.state.customerId ? 1 : 0}
                        name="customerId"
                        label="Client"
                        isDisabled={this.state.projectId === "" ? true : false}
                      />
                    </div>
                  </Box>
                )}
                {this.state.showMIFields === true ||
                this.state.showBPFields === true ? (
                  ""
                ) : (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>Consignment Date</label>
                        </div>
                        {/* <ConsoleDatePicker /> */}
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="financial_year"
                            id="financial_year"
                            inputFormat="dd-MM-yyyy"
                            value={this.state.consignmentDate}
                            fullWidth
                            onChange={(e) =>
                              this.handleSelect_Date(e, "consignmentDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                )}
                {this.state.showMIFields === true ||
                this.state.showBPFields === true ? (
                  ""
                ) : (
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Consignment Number"
                      placeholder="Enter here"
                      value={this.state.consignmentNumber}
                      onChange={this.handleChange("consignmentNumber")}
                      disabled={this.state.projectId === "" ? true : false}
                    />
                  </Box>
                )}
                {this.state.showMIFields === true ? (
                  ""
                ) : (
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      label="Goods Type"
                      placeholder="Enter here"
                      value={this.state.goodsType}
                      readOnly={true}
                      onChange={this.handleChange("goodsType")}
                      disabled={this.state.projectId === "" ? true : false}
                    />
                  </Box>
                )}
                {this.state.showMIFields === true ||
                this.state.showBPFields === true ? (
                  ""
                ) : (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={arrayOfIndent}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        handleMenuOpen={this.handleMenuOpen}
                        isDropdownOpen={this.state.isDropdownOpen}
                        selected={this.state.taxType}
                        isDisabled={
                          this.props.source === "WITHOUTPO"
                            ? this.state.projectId === ""
                              ? true
                              : false
                            : this.state.showInwardFields === true
                            ? true
                            : this.state.showSRFields === true
                            ? true
                            : this.state.gstValidation === true
                            ? true
                            : this.props.source === "WITHWO"
                            ? true
                            : this.state.showInwardFields === true
                            ? this.state.poList !== ""
                              ? true
                              : false
                            : false
                        }
                        update={this.state.taxType ? 1 : 0}
                        name="taxType"
                        label="Tax Type"
                        required
                      />
                    </div>
                  </Box>
                )}
              </Box>
            </Grid>
          </div>
          <div style={{ height: "15px" }}></div>
          {this.state.showMIFields === false && (
            <div className="consoleFormBlock">
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {this.state.showMIFields === true ||
                  this.state.showSRFields === true ||
                  this.state.showBPFields === true ? (
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          type="textarea"
                          label="Internal Note"
                          value={this.state.internalNote}
                          onChange={this.handleChangetextAreas("internalNote")}
                        />
                      </div>
                    </Box>
                  ) : (
                    <>
                      <Box gridColumn="span 3">
                        <TextFieldNormal
                          label="Invoice Number"
                          placeholder="Enter here"
                          value={this.state.invoiceNumber}
                          onChange={this.handleChange("invoiceNumber")}
                          handleBlur={this.handleBlur("invoiceNumber")}
                          required={
                            this.state.userDit.roleId === 100 ? true : false
                          }
                        />
                      </Box>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <div>
                            <div className="TextFieldBlock">
                              <label>
                                Invoice Date
                                {this.state.userDit.roleId === 100 ? (
                                  <label className="starcolor">*</label>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                name="financial_year"
                                id="financial_year"
                                inputFormat="dd-MM-yyyy"
                                maxDate={this.state.inwardDate}
                                value={this.state.invoiceDate}
                                fullWidth
                                onChange={(e) =>
                                  this.handleSelect_Date(e, "invoiceDate")
                                }
                                renderInput={({
                                  inputRef,
                                  inputProps,
                                  InputProps,
                                }) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    className="consoleDatePicker"
                                  >
                                    <input ref={inputRef} {...inputProps} />
                                    {InputProps?.endAdornment}
                                  </Box>
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </Box>

                      <Box gridColumn="span 3">
                        <TextFieldNormal
                          label="Eway Bill Number"
                          placeholder="Enter here"
                          value={this.state.ewaybillno}
                          onChange={this.handleChange("ewaybillno")}
                          required={
                            this.state.userDit.roleId === 100 ? true : false
                          }
                        />
                      </Box>

                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <div>
                            <div className="TextFieldBlock">
                              <label>
                                Eway Bill Date{" "}
                                {this.state.userDit.roleId === 100 ? (
                                  <label className="starcolor">*</label>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                name="financial_year"
                                id="financial_year"
                                inputFormat="dd-MM-yyyy"
                                value={this.state.ewaybillnoDate}
                                fullWidth
                                onChange={(e) =>
                                  this.handleSelect_Date(e, "ewaybillnoDate")
                                }
                                renderInput={({
                                  inputRef,
                                  inputProps,
                                  InputProps,
                                }) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    className="consoleDatePicker"
                                  >
                                    <input ref={inputRef} {...inputProps} />
                                    {InputProps?.endAdornment}
                                  </Box>
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </Box>
                    </>
                  )}
                  {this.state.showMIFields === true ||
                  this.state.showBPFields === true ? (
                    ""
                  ) : (
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          type="textarea"
                          label="Remarks"
                          value={this.state.remarks}
                          onChange={this.handleChange("remarks")}
                        />
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </div>
          )}
        </div>
      </>
    );
  }
}

const arrayOfIndent = [
  // {
  //   value: null,
  //   label: "Select....",
  //   name: "Select",
  // },
  {
    value: 1,
    label: "IGST",
    name: "IGST",
  },
  {
    value: 2,
    label: "CGST & SGST",
    name: "CGST & SGST",
  },
  {
    value: "3",
    label: "NO TAX",
    name: "NOTAX",
  },
];

const mapStatetoProps = (state) => {
  const { PurchaseOrder_List } = state.PurchaseOrderReducer;
  const { branchList, supplierList, projectList, clientList } =
    state.DropDownListReducer;
  const { POLineItemsList, CheckValidData } = state.InwardReducer;
  const { workOrder_List } = state.workOrder_List;
  return {
    PurchaseOrder_List,
    workOrder_List,
    branchList,
    supplierList,
    projectList,
    clientList,
    POLineItemsList,
    CheckValidData,
  };
};
export default connect(mapStatetoProps, {
  getPurchaseOrderList,
  getWorkOrderList,
  getBranchList,
  getSupplierList,
  getProjectList,
  getClientList,
  getPoLineItemsListByPoIds,
  InwardLineItemsProps,
  checkValidSuppIdChallanInvoiceNo,
})(withRouter(FillDetails));
