import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import { serverApi } from "../../../helpers/Consts";
import { createTerms } from "../../../store/Master/Terms/actions";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import DynamicSelect from "../../../components/Dropdown/Master/DynamicSelect";
import swal from "sweetalert";
import { validate } from "../../../Utilities/Validations";

class CreateTermsMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      termsAndConditions: "",
      termsConditionType: "",
      autoId: "",
      isActive: 1,
      displayUpdateButton: true,
      show: false,
      locationState:"",
    };
  }
  /* Calling Create and Update Api's to Actions */
  onClickCreate = () => {
    if (this.state.termsAndConditions === "") {
      swal("Please Enter the Description");
      return false;
    }
    if (this.state.termsConditionType === "") {
      swal("Please Enter the Type");
      return false;
    }
    const data = {
      termsAndConditions: this.state.termsAndConditions,
      termsConditionType: this.state.termsConditionType,
      orgId: this.state.userDit.orgId,
      isActive: 1,
      createdBy: this.state.userDit.userId,
    };
    this.props.createTerms(serverApi.CREATE_TERMS, data, this.props.history); // create Terms api
  };
  onClickUpdate = () => {
    if (this.state.termsAndConditions === "") {
      swal("Please Enter Description");
      return false;
    } else if (this.state.termsConditionType === "") {
      swal("Please Enter the Type");
      return false;
    }

    const data = {
      autoId: this.state.autoId,
      termsAndConditions: this.state.termsAndConditions,
      termsConditionType: this.state.termsConditionType,
      orgId: this.state.userDit.orgId,
      isActive: this.state.isActive == 2 ? 0 : 1,
      createdBy: this.state.userDit.userId,
    };
    this.props.createTerms(serverApi.CREATE_TERMS, data, this.props.history);
  };
  // function for TextFields
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "description") {
      this.setState({
        termsAndConditions: event,
      });
    }
  };

  handleSelect = (selectedValue, selectedName, name) => {
    if (name === "type") {
      this.setState({
        termsConditionType: selectedValue,
      });
    }
    if (name === "status") {
      this.setState({
        isActive: selectedValue,
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    this.setState({
      locationState:this.props.location
    });

    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData !== undefined) {
        this.setState({
          autoId: this.props.location.state.rowData.autoId,
          termsAndConditions:
            this.props.location.state.rowData.termsAndConditions,
          termsConditionType:
            this.props.location.state.rowData.termsConditionType,
          isActive: this.props.location.state.rowData.isActive === 0 ? 2 : 1,
        });
      }
    }
  }

  componentWillReceiveProps =(props) => {
    if (this.state.locationState.state !== undefined) {
      if (this.props.location.state.rowData !== undefined) {
        this.state.termsAndConditions =
        this.props.location.state.rowData.termsAndConditions;
        this.state.termsConditionType =
        this.props.location.state.rowData.termsConditionType;
        this.state.isActive = this.props.location.state.rowData.isActive;
        this.setState({
          termsAndConditions: this.props.location.state.rowData.termsAndConditions,
          termsConditionType: this.props.location.state.rowData.termsConditionType,
          isActive: this.props.location.state.rowData.isActive=== 0 ? 2 : 1,
        });
        if (this.props.location.state.rowData.isActive === 0) {
          this.setState({
            displayUpdateButton: true,
            show: true,
          });
        }
      }
    }
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.termsAndConditions}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("description")}
                      label="Description"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelect
                      arrayOfData={taskList}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelect}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.termsConditionType}
                      update={this.props.location.state ? 1 : 0}
                      name="type"
                      label="Type"
                      required
                      isDisabled={
                        this.state.termsAndConditions === "" ? true : false
                      }
                    />
                  </Box>
                  {this.state.locationState.state ? (
                    <Box gridColumn="span 3">
                      <DynamicSelect
                        update={this.state.isActive ? 1 : 0}
                        arrayOfData={Data}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelect}
                        selected={this.state.isActive}
                        name="status"
                        label="Status"
                        isDisabled={
                          this.state.termsAndConditions === "" ? true : false
                        }
                      />
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
          {/* {this.state.show && (
            <p style={{ color: "red" }}>
              This Terms Cannot be Updated as Status is InActive
            </p>
          )} */}
        </div>

        <div className="consoleFormButtonBlock">
          <Link to="/terms">
            <CustomButton label={"Cancel"} className="greenBorderButton" />
          </Link>
          {this.state.locationState.state !== undefined ? (
            <>
              {this.state.displayUpdateButton ? (
                <CustomButton
                  label={"Update"}
                  className="greenButton"
                  handleClick={this.onClickUpdate}
                  type="sumbit"
                />
              ) : (
                ""
              )}
            </>
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreate}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}
const taskList = [
  {
    value: "WO",
    label: "Work order",
    name: "Work order",
  },
  {
    value: "PO",
    label: "Purchase Order",
    name: "Purchase Order",
  },
  {
    value: "INW",
    label: "Inward",
    name: "Inward",
  },
  {
    value: "BP",
    label: "Bill Pass",
    name: "Bill Pass",
  },
  {
    value: "SR",
    label: "Store Receipt",
    name: "Store Receipt",
  },
  {
    value: "SALES",
    label: "Sales Order",
    name: "Sales Order",
  },
  {
    value: "DO",
    label: "Delivery Order",
    name: "Delivery Order",
  },
  {
    value: "INVOICE",
    label: "Invoice",
    name: "Invoice",
  },
];
const Data = [
  {
    value: "1",
    label: "Active",
    name: "Active",
  },
  {
    value: "2",
    label: "InActive",
    name: "InActive",
  },
];

const mapStatetoProps = (state) => {
  const { Create_Terms } = state.TermsReducer; // create terms from terms reducer
  return { Create_Terms };
};

export default withRouter(
  connect(mapStatetoProps, {
    createTerms,
  })(CreateTermsMaster)
);
