import {
  LEDGER_GROUPS_LIST,
  CREATE_LEDGER_GROUPS_MASTER,
  UPDATE_LEDGER_GROUPS_MASTER,
} from "./actionType";
import { all, call, takeEvery, put } from "redux-saga/effects";
import { getList, postAdd } from "../../../helpers/Server_Helper";
import {
  ledgerGroupsListSuccessfull,
  createLedgerGroupsMasterSuccessfull,
  updateLedgerGroupsMasterSuccessfull,
} from "./actions";
import swal from "sweetalert";

function* getAllLedgerGroupsList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(ledgerGroupsListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateLedgerGroupsMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createLedgerGroupsMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/Ledger_Groups");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateLedgerGroupsMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateLedgerGroupsMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/Ledger_Groups");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

function* LedgerGroupsSaga() {
  yield all([
    yield takeEvery(LEDGER_GROUPS_LIST, getAllLedgerGroupsList),
    yield takeEvery(CREATE_LEDGER_GROUPS_MASTER, CreateLedgerGroupsMaster),
    yield takeEvery(UPDATE_LEDGER_GROUPS_MASTER, UpdateLedgerGroupsMaster),
  ]);
}
export default LedgerGroupsSaga;
