import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import moment from "moment";
import { Link } from "react-router-dom";
import CustomButton from "../../../components/Buttons/Buttons";
import { setReOccuringInvoice } from "../../../store/Sales/ReOccuringInVoice/actions";
import { serverApi } from "../../../helpers/Consts";
class CreateReOccuringInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoice: "",
      invoice_id: "",
      Customer: "",
      startDate: "",
      endDate: "",
      frequency: "",
      amount: "",
    };
  }

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name == "invoice") {
      this.setState({
        invoice: selectedName,
        invoice_id: selectedValue,
      });
    }
    if (name == "frequency") {
      this.setState({
        frequency: selectedName,
      });
    }
  };
  onhandleChange = (key) => (event) => {
    this.setState({ [key]: event });
  };
  handleSelectDate = (e, name) => {
    if (name === "startDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();

      this.setState({
        startDate: Date,
      });
    }
    if (name === "endDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();

      this.setState({
        endDate: Date,
      });
    }
  };
  onClickSave = () => {
    const data = {
      invoice: this.state.invoice,
      invoice_id: this.state.invoice_id,
      Customer: this.state.Customer,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      frequency: this.state.frequency,
      amount: this.state.amount,
    };
  };
  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={client}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.state.invoice_id}
                        name="invoice"
                        label="Invoice"
                        required
                        update={this.state.invoice_id}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Customer"
                        value={this.state.Customer}
                        onChange={this.onhandleChange("Customer")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>Start Date</label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="startDate"
                            inputFormat="dd-MM-yyyy"
                            id="startDate"
                            value={this.state.startDate}
                            fullWidth
                            onChange={(e) =>
                              this.handleSelectDate(e, "startDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>End Date</label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="endDate"
                            inputFormat="dd-MM-yyyy"
                            id="endDate"
                            value={this.state.endDate}
                            fullWidth
                            onChange={(e) =>
                              this.handleSelectDate(e, "endDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectNew
                        arrayOfData={Frequency}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.state.frequency}
                        name="frequency"
                        label="Frequency"
                        update={this.state.frequency}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Amount"
                        value={this.state.amount}
                        onChange={this.onhandleChange("amount")}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div className="consoleFormButtonBlock">
            <Link to="/reoccurinvoice">
              <CustomButton label={"Cancel"} className="greenBorderButton" />
            </Link>
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickSave}
              type="sumbit"
            />
          </div>
        </div>
      </div>
    );
  }
}

const client = [
  {
    value: "1",
    label: "Center",
    name: "Center",
  },
  {
    value: "2",
    label: "TechStar",
    name: "TechStar",
  },
  {
    value: "3",
    label: "Mahindra Logistics",
    name: "Mahindra Logistics",
  },
  {
    value: "4",
    label: "Smartworks",
    name: "Smartworks",
  },
];

const Frequency = [
  {
    value: "",
    label: "Select...",
    name: "Select...",
  },
  {
    value: "1",
    label: "Daily",
    name: "Daily",
  },
  {
    value: "2",
    label: "Weekly",
    name: "Weekly",
  },
  {
    value: "3",
    label: "Monthly",
    name: "Monthly",
  },
  {
    value: "4",
    label: "Quarterly",
    name: "Quarterly",
  },
  {
    value: "5",
    label: "HalfYearly",
    name: "HalfYearly",
  },
  {
    value: "6",
    label: "Yearly",
    name: "Yearly",
  },
];

const mapStatetoProps = (state) => {
  const { ReOccuringInvoices } = state.ReOccuringInvoiceReducer;
  return { ReOccuringInvoices };
};
export default connect(mapStatetoProps, {
  setReOccuringInvoice,
})(withRouter(CreateReOccuringInvoice));
