import { CLEAR_JUTE_INDENT_HEADER } from "../JuteIndent/actionTypes";
import {
  JUTE_BILL_PASS_LIST,
  JUTE_BILL_PASS_LIST_SUCCESSFULL,
  JUTE_BILL_PASS_VIEW_BY_ID,
  JUTE_BILL_PASS_VIEW_BY_ID_SUCCESSFULL,
  BILLPASS_LINE_ITEMS,
  GET_FILES_OF_CHALLAN,
  GET_FILES_OF_INVOICE,
  GET_FILES_OF_BILLPASS_SUCCESSFULL,
  GET_FILES_OF_BILLPASS,
  GET_FILES_OF_SUPPDOCS,
  UPDATE_JUTE_BILL_PASS,
  UPDATE_JUTE_BILL_PASS_SUCCESSFULL,
  JUTE_BILL_PASS_HEADER,
  CLEAR_JUTE_PASS_HEADER,
  CLEAR_BILLPASS_LINE_ITEMS,
  INVOICE_UPLOAD_FILES,
  INVOICE_UPLOAD_FILES_SUCCESSFULL,
  GET_INVOICE_FILES_SUCCESSFULL,
  GET_SUPPORT_FILES,
  GET_SUPPORT_FILES_SUCCESSFULL,
  GET_INVOICE_FILES,
  GET_CHALLAN_FILES,
  GET_CHALLAN_FILES_SUCCESSFULL,
  SUPPORT_UPLOAD_FILES_SUCCESSFULL,
  CHALLAN_UPLOAD_FILES_SUCCESSFULL,
} from "./actionTypes";

export const JuteBillPassList = (url, data, history) => {
  return {
    type: JUTE_BILL_PASS_LIST,
    payload: { url, data, history },
  };
};
export const JuteBillPassListSuccessfull = (data) => {
  return {
    type: JUTE_BILL_PASS_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const JuteBillPassViewByIdAction = (url, history) => {
  return {
    type: JUTE_BILL_PASS_VIEW_BY_ID,
    payload: { url, history },
  };
};

export const JuteBillPassViewByIdActionSUccessfull = (data) => {
  return {
    type: JUTE_BILL_PASS_VIEW_BY_ID_SUCCESSFULL,
    payload: data,
  };
};



// export const getFiles = (url, history, sourceMenu) => {
//   return {
//     type: GET_FILES_OF_BILLPASS,
//     payload: { url, history, sourceMenu },
//   };
// };
// export const getFilesSuccessfull = (data) => {
//   return {
//     type: GET_FILES_OF_BILLPASS_SUCCESSFULL,
//     payload: data,
//   };
// };
// export const getInvoiceFilesSuccessfull = (data) => {
//   return {
//     type: GET_FILES_OF_INVOICE,
//     payload: data,
//   };
// };
// export const getChallanFilesSuccessfull = (data) => {
//   return {
//     type: GET_FILES_OF_CHALLAN,
//     payload: data,
//   };
// // };
// export const getSuppdocsFilesSuccessfull = (data) => {
//   return {
//     type: GET_FILES_OF_SUPPDOCS,
//     payload: data,
//   };
// };

// action for updating jute billpass
export const updateJuteBillpass = (url, data, history) => {
  return {
    type: UPDATE_JUTE_BILL_PASS,
    payload: { url, data, history },
  };
};
export const updateJuteBillpassSuccessfull = (data) => {
  return {
    type: UPDATE_JUTE_BILL_PASS_SUCCESSFULL,
    payload: data,
  };
};

export const getJuteBillPassHeader=(data)=>{
  return{
    type:JUTE_BILL_PASS_HEADER,
    payload:data,
  };
};

export const ClearJuteBillPassHeader = (data) => {
  return{
    type: CLEAR_JUTE_PASS_HEADER,
    payload:data,
  };
};

export const getBillPassLineItems = (data) => {
  return {
    type: BILLPASS_LINE_ITEMS,
    payload: data,
  };
};

export const ClearJuteBillPassLineItemdata=(data)=>{
  return{
type:CLEAR_BILLPASS_LINE_ITEMS,
payload:data,
  };
};

export const UploadInvoiceFiles = (url,data,history,sourceMenu,reqObj) =>{
  return {
      type:INVOICE_UPLOAD_FILES,
      payload:{url,data,history,sourceMenu,reqObj}
  }
}

export const UploadInvoiceFilesSuccessfull = (data)=>{
  return {
      type:INVOICE_UPLOAD_FILES_SUCCESSFULL,
      payload:data
  }
}

export const UploadSupportFilesSuccessfull = (data)=>{
  return {
      type:SUPPORT_UPLOAD_FILES_SUCCESSFULL,
      payload:data
  }
}
export const UploadChallanFilesSuccessfull = (data)=>{
  return {
      type:CHALLAN_UPLOAD_FILES_SUCCESSFULL,
      payload:data
  }
}
export const getInvoiceFiles = (url, history, sourceMenu) => {
  return {
    type: GET_INVOICE_FILES,
    payload: { url, history, sourceMenu },
  };
};
export const getInvoiceFilesSuccessfull = (data) => {
  console.log(data)
  return {
    type: GET_INVOICE_FILES_SUCCESSFULL,
    payload: data,
  };
};

export const getSupportFiles = (url, history, sourceMenu) => {
  return {
    type: GET_SUPPORT_FILES,
    payload: { url, history, sourceMenu },
  };
};
export const getSupportFilesSuccessfull = (data) => {
  return {
    type: GET_SUPPORT_FILES_SUCCESSFULL,
    payload: data,
  };
};

export const getChallanFiles = (url, history, sourceMenu) => {
  return {
    type: GET_CHALLAN_FILES,
    payload: { url, history, sourceMenu },
  };
};
export const getChallanFilesSuccessfull = (data) => {
  return {
    type: GET_CHALLAN_FILES_SUCCESSFULL,
    payload: data,
  };
};