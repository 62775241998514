import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import { serverApi } from "../../../helpers/Consts";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import {
  createQualityMaster,
  updateQualityMaster,
  qualityMasterList,
  getJuteQualityMasterListValues,
} from "../../../store/Master/QualityMaster/actions";
import { getItemsList } from "../../../store/Global/DropDownApis/actions";
// import DynamicSelect from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import moment from "moment";
import swal from "sweetalert";
import { isEmptyWithZero, isEmpty } from "../../../Utilities/helper";

class CreateQualityMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      CompanyList: [],
      createdBy: "",
      item: "",
      itemValue: "",
      itemCode: "",
      qualityName: "",
      rate: "",
      allowableMoisture: "",
      batchCategory: "",
      inputType: "",
      inputTypeValue: "",
      id: "",
      inputRate: false,
      createdOn: "",
      itemsList: [],
      depandentQuality: "",
      dependantQualityId: "",
      difference: "",
      startIndex: "",
      createdDate: "",
      lastIndex: "",
      qualityMasterListData: [], // dropdown array list for quality master list
      PreJuteQualityList: [],
      PreJuteQualityList_Data: [],
      qualityId: "",
      companyid: "",
      locationState:"",
    };
  }

  onClickCreate = () => {
    const data = {
      juteQualityPriceMaster: {
        itemCode: this.state.itemCode,
        juteQuality: this.state.qualityName,
        rate: Number(this.state.rate),
        currency: "",
        lastPurchaseRate: "",
        stock: "",
        createdBy: this.state.userDit.userId.toString(),
        allowableMoisture: this.state.allowableMoisture,
        op2019: "",
        batchCategory: this.state.batchCategory,
        inputRate: this.state.inputTypeValue,
        dependantQualityId: this.state.dependantQualityId,
        difference: Number(this.state.difference),
        autoDateTime: moment().format(),
      },
      postAuthenticationDto: {
        companyId: localStorage.getItem("companyId"),
        cipher: this.state.userDit.cipher,
      },
    };
    console.log(this.state.rate, "this.state.rate122222")
    if (
      this.state.itemCode === "" ||
      this.state.itemCode === undefined ||
      this.state.itemCode == null
    ) {
      swal("Please select the item");
    } else if (this.state.qualityName === "") {
      swal("Please enter the Quality name");
    } else if (isEmptyWithZero(this.state.rate)) {
      swal("Please enter the Rate");
    } else if (this.state.inputTypeValue === "") {
      swal("Please Select Price Input Type");
    } else if (this.state.inputTypeValue === "Yes") {
      this.props.createQualityMaster(
        serverApi.CREATE_QUALITY_MASTER,
        data,
        this.props.history
      );
    } else if (this.state.inputTypeValue === "No") {
      if (this.state.dependantQualityId === "") {
        swal("Please enter the Rate Difference");
      } else if (isEmptyWithZero(this.state.difference)) {
        swal("Please enter the Diffrence");
      } else {
        this.props.createQualityMaster(
          serverApi.CREATE_QUALITY_MASTER,
          data,
          this.props.history
        );
      }
    }
  };
  /* Calling Create and Update Api's to Actions */
  onClickUpdate = () => {
    const data = {
      juteQualityPriceMaster: {
        id: this.state.id,
        itemCode: this.state.itemCode,
        juteQuality: this.state.qualityName,
        rate: Number(this.state.rate),
        currency: "",
        lastPurchaseRate: "",
        stock: "",
        createdBy: this.state.userDit.userId.toString(),
        allowableMoisture: this.state.allowableMoisture,
        op2019: "",
        batchCategory: this.state.batchCategory,
        inputRate: this.state.inputTypeValue,
        dependantQualityId: this.state.dependantQualityId,
        difference: Number(this.state.difference),
        autoDateTime: moment().local().format(),
      },
      postAuthenticationDto: {
        companyId: localStorage.getItem("companyId"),
        cipher: this.state.userDit.cipher,
      },
    };
    if (
      this.state.itemCode === "" ||
      this.state.itemCode === undefined ||
      this.state.itemCode == null
    ) {
      swal("Please select the item");
    } else if (this.state.qualityName === undefined) {
      swal("Please enter the Quality name");
    } else if (isEmptyWithZero(this.state.rate)) {
      swal("Please enter the Rate");
    } else if (this.state.inputTypeValue === undefined) {
      swal("Please Select Price Input Type");
    } else if (this.state.inputTypeValue === "Yes") {
      this.props.updateQualityMaster(
        serverApi.UPDATE_QUALITY_MASTER,
        data,
        this.props.history
      );
    } else if (this.state.inputTypeValue === "No") {
      if (isEmpty(this.state.dependantQualityId)) {
        swal("Please enter the Rate Difference");
      } else if (isEmptyWithZero(this.state.difference)) {
        swal("Please enter the Difference");
      } else {
        this.props.updateQualityMaster(
          serverApi.UPDATE_QUALITY_MASTER,
          data,
          this.props.history
        );
      }
    }
  };
  // function for previous page
  onClickCancel = () => {
    this.props.history.push("/quality_master");
  };
  //function for TextFields
  onhandlechangeValue = (key) => (event) => {
    if (key === "qualityName") {
      this.setState({
        qualityName: event,
      });
    }
    if (key === "rate") {
      this.setState({
        rate: event,
      });
    }
    if (key === "allowableMoisture") {
      if (event < 0) {
        swal("Allowable Moisture Percentage Shouldn't be Less than Zero");
        this.setState({
          allowableMoisture: "",
        });
      } else if (event > 100) {
        swal("Allowable Moisture Percentage Shouldn't be Greater than 100");
        this.setState({
          allowableMoisture: "",
        });
      } else {
        this.setState({
          allowableMoisture: event,
        });
      }
    }
    if (key === "batchCategory") {
      this.setState({
        batchCategory: event,
      });
    }
    if (key === "difference") {
      this.setState({
        difference: event,
      });
    }
  };
  // function for popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "item") {
      this.setState({
        itemCode: selectedValue,
        itemValue: selectedValue,
      });
    }
    if (name === "depandentQuality") {
      this.setState({
        depandentQuality: selectedValue,
        dependantQualityId: selectedValue,
      });
    }

    if (name === "inputType") {
      if (selectedValue === "No") {
        this.setState({ inputType: !this.inputType });
      } else {
        this.setState({ inputType: false });
      }
      this.setState({
        inputRate: selectedValue,
        inputTypeValue: selectedValue,
      });
    }
    if (name === "companyid") {
      this.setState({
        companyid: selectedValue,
         dependantQualityId:"",
         depandentQuality:"",
         itemCode:"",
         itemValue:"",
         inputRate:"",
         inputTypeValue:"",
         qualityName:"",
         rate:"",
         allowableMoisture:"",
         batchCategory:"",
         difference:"",
         qualityId:"",
      });
      const data = {
        cipher: this.state.userDit.cipher,
        companyId: selectedValue,
        juteQuality: null,
        qualityCode: null,
      };
      this.props.getJuteQualityMasterListValues(
        serverApi.GET_ALL_QUALITY_MASTER_LIST,
        data,
        this.props.history
      );
    }
    if (name === "qualityId") {
      this.setState({
        qualityId: Number(selectedValue),
      });

      this.state.PreJuteQualityList_Data.map((item) => {
        if (selectedValue === item.id) {
          this.setState({
            itemCode: item.itemCode,
            qualityName: item.juteQuality,
            rate: item.rate,
            allowableMoisture: item.allowableMoisture,
            batchCategory: item.batchCategory,
            inputTypeValue: item.inputRate,
            dependantQualityId: item.dependantQualityId,
            difference: item.difference,
          });
        }
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    this.setState({
      locationState: this.props.location
    })
    window.addEventListener("scroll", this.handleScroll);
    const data = {
      companyId: localStorage.getItem("companyId"),
      consumable: "",
      itemGroupId: "999",
      saleable: "",
    };
    this.props.getItemsList(
      serverApi.GET_ITEMS_BY_ITEMGROUP_ID,
      data,
      this.props.history
    );
    const datas = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
    };
    this.props.qualityMasterList(
      serverApi.GET_ALL_QUALITY_MASTER_LIST,
      datas,
      this.props.history
    );
    if (this.props.location.state) {
      if (this.props.location.state.rowData) {
        this.setState({
          id: this.props.location.state.rowData.id,
          qualityName: this.props.location.state.rowData.juteQuality,
          rate: this.props.location.state.rowData.rate,
          currency: this.props.location.state.rowData.currency,
          lastPurchaseRate: this.props.location.state.rowData.lastPurchaseRate,
          stock: this.props.location.state.rowData.stock,
          allowableMoisture:
            this.props.location.state.rowData.allowableMoisture,
          createdOn: this.props.location.state.rowData.autoDateTime,
          batchCategory: this.props.location.state.rowData.batchCategory,
          bales: this.props.location.state.rowData.bales,
          companyId: this.props.location.state.rowData.companyId,
          createdBy: this.props.location.state.rowData.createdBy,
          dependantQualityId:
            this.props.location.state.rowData.dependantQualityId,
          difference: this.props.location.state.rowData.difference,
          drums: this.props.location.state.rowData.drums,
          inputTypeValue: this.props.location.state.rowData.inputRate,
          inputRate: this.props.location.state.rowData.inputRate,
          itemCode: this.props.location.state.rowData.itemCode,
          itemDesc: this.props.location.state.rowData.itemDesc,
          itemId: this.props.location.state.rowData.itemId,
          op2019: this.props.location.state.rowData.op2019,
        });
        const dateEntered = this.props.location.state.rowData.autoDateTime;
        const dateIsValid = moment(dateEntered).format("LLLL");
        this.setState({
          createdDate: dateIsValid,
        });
      }
    }

    this.setState({
      CompanyList: this.state.userDit.companyDetails.map((item) => ({
        label: item.label,
        value: item.value,
      })),
    });
  }
  componentWillReceiveProps(props) {
    if (props.itemsByItmGrpIdList) {
      let itemsList = props.itemsByItmGrpIdList;
      const uniques = Object.values(
        itemsList.reduce((a, c) => {
          a[c.value] = c;
          return a;
        }, {})
      );
      itemsList = uniques;
      if (itemsList.length !== 0) {
        var itemsListData = itemsList.filter((item) => item.value !== "0");
        this.setState({
          itemsList: itemsListData, // updating branch dropdown api
        });
      }
    }

    if (props.QualityMasterList) {
      this.setState({
        qualityMasterListData: props.QualityMasterList.data,
      });
    }
    if (props.jute_quality_master_listData) {
      if (props.jute_quality_master_listData.data) {
        this.setState({
          PreJuteQualityList: props.jute_quality_master_listData.data,
          PreJuteQualityList_Data: props.jute_quality_master_listData.data,
        });
      }
    }
  }
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div>
        {this.state.locationState.state === undefined && (
          <div className="consoleFormContainer">
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.CompanyList}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.companyid}
                          update={this.state.companyid ? 1 : 0}
                          name="companyid"
                          label="Company List"
                        />
                      </div>
                    </Box>

                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.PreJuteQualityList.map(
                            (item) => ({
                              label: item.juteQuality,
                              name: item.juteQuality,
                              value: item.id,
                            })
                          )}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.qualityId}
                          update={1}
                          name="qualityId"
                          label="Previous Quality"
                          isDisabled={
                            this.state.companyid === ""
                              ? true
                              : false
                          }
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
        <div className="consoleFormContainer">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.itemsList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.itemCode}
                        update={1}
                        name="item"
                        label="Item"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.qualityName}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("qualityName")}
                      label="Quality Name"
                      disabled={
                        this.state.itemCode === ""
                          ? true
                          : false && this.state.qualityId === ""
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.rate}
                      caps="OFF"
                      type="number"
                      onChange={this.onhandlechangeValue("rate")}
                      label="Rate"
                      disabled={
                        this.state.qualityName === ""
                          ? true
                          : false && this.state.qualityId === ""
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.allowableMoisture}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("allowableMoisture")}
                      label="Allowable Moisture %"
                      disabled={
                        this.state.qualityName === ""
                          ? true
                          : false && this.state.qualityId === ""
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.batchCategory}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("batchCategory")}
                      label="Batch Category"
                      disabled={
                        this.state.qualityName === ""
                          ? true
                          : false && this.state.qualityId === ""
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={InputType}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.inputTypeValue}
                        update={this.state.inputTypeValue? 1 : 0}
                        name="inputType"
                        label="Price Input Type"
                        required
                        isDisabled={
                          this.state.rate === ""
                            ? true
                            : false && this.state.qualityId === ""
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  {this.state.inputTypeValue === "No" ? (
                    <>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            arrayOfData={
                              this.state.qualityMasterListData &&
                              this.state.qualityMasterListData.length !== 0 &&
                              this.state.qualityMasterListData.map((item) => ({
                                label: item.juteQuality,
                                value: item.id,
                                name: item.juteQuality,
                              }))
                            }
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            isDropdownOpen={this.state.isDropdownOpen}
                            handleMenuOpen={this.handleMenuOpen}
                            selected={this.state.dependantQualityId}
                            update={1}
                            name="depandentQuality"
                            label="Rate Difference"
                            required
                            disableBranch={
                              this.state.inputTypeValue === ""
                                ? true
                                : false && this.state.qualityId === ""
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 3">
                        <TextFieldReq
                          type="number"
                          value={this.state.difference}
                          caps="OFF"
                          onChange={this.onhandlechangeValue("difference")}
                          label="Difference"
                          disabled={
                            this.state.dependantQualityId === ""
                              ? true
                              : false && this.state.qualityId === ""
                              ? true
                              : false
                          }
                        />
                      </Box>
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdDate}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.state.locationState.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreate}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}
const InputType = [
  { label: "Yes", value: "Yes", name: "Yes" },
  { label: "No", value: "No", name: "No" },
];

const mapStatetoProps = (state) => {
  const {
    UpdateQualityMaster,
    QualityMasterList,
    jute_quality_master_listData,
  } = state.QualityMasterReducer; // fetching create and update from mukam master list reducer
  const { itemsByItmGrpIdList } = state.DropDownListReducer;
  return {
    QualityMasterList,
    UpdateQualityMaster,
    itemsByItmGrpIdList,
    jute_quality_master_listData,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    createQualityMaster,
    updateQualityMaster,
    getItemsList,
    qualityMasterList,
    getJuteQualityMasterListValues,
  })(CreateQualityMaster)
);
