import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import moment from "moment";

import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/PaginationGridView";
import { getQuotationList, setQuotation ,clearQuotationHeaders, clearQuotationLineItems} from "../../../store/Sales/Qutation/actions";

const QuotationGrid = () => {
  let dispatch = useDispatch();
  let history = useHistory();

  const [QuotationListData, setQuotationListData] = useState([]);
  const [totalRecs, setTotalRecs] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(10);
  const [cusId, setCusId] = useState("");
  const [status, setStatus] = useState("");
  const [dataexp, setDataExp] = useState([]);
  const [branchId, setBranchId] = useState("");
  const [quotationNumber, setQuotationNumber] = useState("")
  const [fromDate, setfromDate] = useState( moment().day(-7).format("DD-MM-YYYY"));
  const [toDate, settoDate] = useState(moment().format("DD-MM-YYYY"));

  const QuotationListColumns = [
    {
      Header: "Quote Number",
      accessor: "quotationNo",
      hidden: false,
    },
    {
      Header: "Customer",
      accessor: "customer",
      hidden: false,
    },
    {
      Header: "Quote Date",
      accessor: "quoteDate",
      hidden: false,
    },
    {
      Header: "Created By",
      accessor: "createdBy",
      hidden: false,
    },
    {
      Header: "Expire Date",
      accessor: "expireDate",
      hidden: false,
    },
    {
      Header: "Amount",
      accessor: "amount",
      hidden: false,
    },
    {
      Header: "Status",
      accessor: "status",
      hidden: false,
    }
  ];

  const quotationListPayload = {
    acYear: localStorage.getItem("acadamicYear"),
    companyId: localStorage.getItem("companyId"),
    cusId: "",
    fromDate: fromDate,
    status: "",
    toDate: toDate,
    startIndex: startIndex,
    lastIndex: lastIndex,
    quotationNo: "",
    branchId: branchId,
  };

  useEffect(() => {
    const financialYearStartMonth = 3; // Assuming financial year starts in April
    let financialYearStart = moment()
      .year(localStorage.getItem("acadamicYear"))
      .month(financialYearStartMonth)
      .startOf("month");

    const fromDate = financialYearStart.day(-7).format("YYYY-MM-DD");
    const toDate = moment().format("YYYY-MM-DD");

    var startDate = moment(fromDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var endDate = moment(toDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    const expdata = {
      ...quotationListPayload,
      startIndex: 0,
      lastIndex: 10,
      fromDate: startDate,
      toDate: endDate,
    };
    setDataExp(expdata,);
    setfromDate(startDate)
    settoDate(endDate)
    dispatch(getQuotationList(serverApi.GET_QUOTATION_LIST_V2, expdata, history));
  }, [getQuotationList]);

  const { dataLoaded, Quotation_List } = useSelector(
    (state) => state.QuotationReducer
  );

  useEffect(() => {
    if (dataLoaded && Quotation_List) {
      setQuotationListData(Quotation_List.data);
      setTotalRecs(Quotation_List.totalRecords);
    }
  }, [dataLoaded, Quotation_List]);

  const handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const expdata = {
        ...quotationListPayload,
        cusId: cusId,
        fromDate: fromDate,
        status: status,
        toDate: toDate,
        startIndex: sizePerPage,
        lastIndex: currentIndex,
        branchId: branchId,
        quotationNo: quotationNumber
      };
      
      if (currentIndex >= 0) {
        dispatch(getQuotationList(serverApi.GET_QUOTATION_LIST_V2, expdata, history));
      }
    } else {
      const expdata = {
        ...quotationListPayload,
        cusId: cusId,
        fromDate: fromDate,
        status: status,
        toDate: toDate,
        startIndex: currentIndex,
        lastIndex: sizePerPage
      };

      if (currentIndex >= 0) {
        dispatch(getQuotationList(serverApi.GET_QUOTATION_LIST_V2, expdata, history));
      }
    }

    setStartIndex(currentIndex);
    setLastIndex(sizePerPage);
  };
  const handleSearch = (data1) => {
    setCusId(data1.cusId);
    setfromDate(data1.fromDate);
    setStatus(data1.status);
    settoDate(data1.toDate);
    setBranchId(data1.branchId);
    setQuotationNumber(data1.quoteNo);

    const expdata = {
        ...quotationListPayload,
        acYear: data1.acYear,
        companyId: data1.companyId,
        cusId: data1.cusId,
        fromDate: data1.fromDate,
        quotationNo: data1.quoteNo,
        status: data1.status,
        toDate: data1.toDate,
        branchId: data1.branchId
      };
      const val = {
        ...expdata,
        lastIndex:0,
        startIndex:0,
      }
      setDataExp(val)
    dispatch(getQuotationList(serverApi.GET_QUOTATION_LIST_V2, expdata, history));
};
useEffect(()=>{
  dispatch(clearQuotationHeaders())
  dispatch(clearQuotationLineItems())
},[])

  return (
    <>
      <Gridwithcustomview
        mdata={QuotationListData}
        mcolumns={QuotationListColumns}
        micon={GridArrowIcon}
        actions={true}
        url="/create_quotation"
        filter_form={"Quotation"}
        handleSearch={handleSearch}
        totalRecs={totalRecs}
        page={startIndex}
        sizePerPage={lastIndex}
        handlePagination={handlePagination}
        apiexp={serverApi.GET_QUOTATION_LIST_V2}
        payloadexp={dataexp}
        expName={"Quotations.csv"}
        fromDate={fromDate}
        toDate={toDate}
      />
    </>
  );
};

export default QuotationGrid;
