import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextField from "../../../components/TextField/TextField";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import CustomButton from "../../../components/Buttons/Buttons";
import Link from "@mui/material/Link";

class CreateCarriers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Carriers_Name: "",
      item_group: "",
      declaration: "",
      Created_By: "",
    };
  }

  handlePropSelectChange = (selectedValue, selectedName, name) => {};

  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        label="Carrier Name"
                        value={this.state.Carriers_Name}
                        caps="OFF"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={phase}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.state.item_group}
                        name="item_group"
                        label="Item Group"
                        required
                        isDisabled={
                          this.state.Carriers_Name === "" ? true : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={phase}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.state.declaration}
                        name="declaration"
                        label="Declaration"
                        required
                        isDisabled={this.state.item_group === "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextField
                        label="Created By"
                        disabled={this.state.item_group === "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>Created On</label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="Created_On"
                            inputFormat="dd.MM.yyyy"
                            id="Created_On"
                            value={this.state.dtvalue}
                            fullWidth
                            onChange={(newValue) => {
                              this.setState({ dtvalue: newValue });
                            }}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickBefore}
            />
          </Link>
          <CustomButton
            label={"Create"}
            className="greenButton"
            handleClick={this.onClickNext}
            type="sumbit"
          />
        </div>
      </div>
    );
  }
}

const arrayOfIndent = [
  {
    value: "1",
    label: "Capital",
    name: "Capital",
  },
  {
    value: "2",
    label: "General",
    name: "General",
  },
  {
    value: "3",
    label: "Maintainence",
    name: "Maintainence",
  },
  {
    value: "4",
    label: "Open",
    name: "Open",
  },
  {
    value: "5",
    label: "Production",
    name: "Production",
  },
];
const Project = [
  {
    value: 1,
    label: "Project1",
    name: "Project 1",
  },
  {
    value: 2,
    label: "Project2",
    name: "Project 2",
  },
  {
    value: 3,
    label: "Project3",
    name: "Project 3",
  },
];

const phase = [
  {
    value: "1",
    label: "Ho",
    name: "Ho",
  },
  {
    value: "2",
    label: "Factory",
    name: "Factory",
  },
  {
    value: "3",
    label: "BNG",
    name: "BNG",
  },
  {
    value: "4",
    label: "Kolkatta",
    name: "Kolkatta",
  },
  {
    value: "5",
    label: "Test",
    name: "Test",
  },
];

const client = [
  {
    value: "1",
    title: "Center",
    name: "Center",
  },
  {
    value: "2",
    title: "TechStar",
    name: "TechStar",
  },
  {
    value: "3",
    title: "Mahindra Logistics",
    name: "Mahindra Logistics",
  },
  {
    value: "4",
    title: "Smartworks",
    name: "Smartworks",
  },
];

const CategoryList = [
  {
    value: "1",
    label: "OPEN",
    name: "OPEN",
  },
  {
    value: "2",
    label: "OPEX",
    name: "OPEX",
  },
  {
    value: "3",
    label: "CAPEX",
    name: "CAPEX",
  },
];

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};
export default connect(mapStatetoProps, {})(withRouter(CreateCarriers));
