import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CustomButton from "../../../../../components/Buttons/Buttons";
import {
  getAllFiles,
  downLoadFiles,
  updateFiles,
  viewbyidPersonalDetails,
  ViewByIdExperienceDetails,
} from "../../../../../store/HRMS/EmployeeDataBase/actions";
import { serverApi, serverConfig } from "../../../../../helpers/Consts";
import { getFileDownload } from "../../../../../helpers/server.js";
import {
  getFilesList,
  getFilesData,
} from "../../../../../helpers/Server_Helper";
import swal from "sweetalert";
class UploadDocument extends Component {
  constructor() {
    super();
    this.state = {
      companyId: localStorage.getItem("companyId"),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      ebId: "",
      approve: false,
      reject: false,
      display: true,
      contactsDocs: [],
      educationDocs: [],
      previosExpDocs: [],
      profilePic: [],
      AllFiles: [],
    };
  }
  handleEducationDocsList = (response) => {
    if (response) {
      response.map((row) => {
        this.state.AllFiles.push(row);
      });
      this.setState({ AllFiles: this.state.AllFiles });
    }
  };
  handlePersonalDocsList = (response) => {
    response.map((row) => {
      this.state.AllFiles.push(row);
    });
    this.setState({ AllFiles: this.state.AllFiles });
  };
  handleProfileDocsList = (response) => {
    response.map((row) => {
      this.state.AllFiles.push(row);
    });
    this.setState({ AllFiles: this.state.AllFiles });
  };
  handlePreviousExpDocsList = (response) => {
    response.map((row) => {
      this.state.AllFiles.push(row);
    });
    this.setState({ AllFiles: this.state.AllFiles });
  };
  handleMedicalDocList = (response) => {
    response.map((row) => {
      this.state.AllFiles.push(row);
    });
    this.setState({ AllFiles: this.state.AllFiles });
  };
  handleEducationalDetailsViewById = (res) => {
    if (res) {
      res.map((data) => {
        getFilesList(
          serverApi.GET_ALL_FILES +
            "42/" +
            data.education_id +
            "/" +
            this.state.companyId,
          this.handleEducationDocsList
        );
      });
    }
  };
  handlePreviousDetails = (response) => {
    if (response) {
      response.map((data) => {
        getFilesList(
          serverApi.GET_ALL_FILES +
            "43/" +
            data.autoId +
            "/" +
            this.state.companyId,
          this.handlePreviousExpDocsList
        );
      });
    }
  };
  componentDidMount() {
    var ebId = this.props.getEbId;
    if (ebId > 0) {
      getFilesList(
        serverApi.GET_ALL_FILES +
          "1/" +
          this.props.getEbId +
          "/" +
          this.state.companyId,
        this.handleProfileDocsList
      );
      getFilesList(
        serverApi.GET_ALL_FILES +
          "6/" +
          this.props.getEbId +
          "/" +
          this.state.companyId,
        this.handlePersonalDocsList
      );
      getFilesList(
        serverApi.GET_ALL_FILES +
          "49/" +
          this.props.getEbId +
          "/" +
          localStorage.getItem("companyId"),
        this.handleMedicalDocList
      );
      getFilesList(
        serverApi.VIEW_EMPLOYEE_EDUCATION_DIT + "/" + this.props.getEbId,
        this.handleEducationalDetailsViewById
      );
      getFilesData(
        serverApi.EXPERIENCE_DETAILS_VIEW_BY_ID + this.props.getEbId,
        this.handlePreviousDetails
      );
    }
  }

  componentWillReceiveProps(props) {}

  handleAddRow = () => {
    this.setState({
      AllFiles: this.state.AllFiles.concat({
        id: this.state.AllFiles.length + 1,
        input: true,
      }),
    });
  };
  handleStatus = (fileid, data1, id, i, sourceMenu) => {
    const data = {
      fileStatus: id,
      fileUploadId: fileid,
      fileRemarks: "",
    };

    if (id == 4) {
      swal({
        title: "Are you sure?",
        text: "Do you want to Reject this file",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          swal("Write Remarks here:", {
            content: "input",
          }).then((value) => {
            data.fileRemarks = `${value}`;
            let fileurl =
              serverApi.GET_ALL_FILES +
              sourceMenu +
              "/" +
              this.props.getEbId +
              "/" +
              this.state.companyId;
            this.props.updateFiles(
              serverApi.UPDATE_FILE,
              data,
              this.props.history,
              fileurl
            );
            let AllFiles = this.state.AllFiles.filter((row) => {
              if (row.fileUploadId == fileid) {
                row.fileStatus = id;
                row.fileRemarks = `${value}`;
              }
              return row;
            });
            this.setState({ AllFiles: AllFiles });
          });
        } else {
          swal("Your action is canceled!");
        }
      });
    } else {
      let fileurl =
        serverApi.GET_ALL_FILES +
        sourceMenu +
        "/" +
        this.props.getEbId +
        "/" +
        this.state.companyId;
      this.props.updateFiles(
        serverApi.UPDATE_FILE,
        data,
        this.props.history,
        fileurl
      );
      let AllFiles = this.state.AllFiles.filter((row) => {
        if (row.fileUploadId == fileid) {
          row.fileStatus = id;
        }
        return row;
      });
      this.setState({ AllFiles: AllFiles });
    }
  };

  trimFileName = (fileName) => {
    const dotIndex = fileName.lastIndexOf(".");
    const extension = fileName.slice(dotIndex);
    const baseName = fileName.slice(0, dotIndex);
    const trimmedBaseName = baseName.slice(0, 26);
    console.log(trimmedBaseName + extension + ".....", "trimmedBaseName + extension")
    return trimmedBaseName + extension + "....."
  };

  render() {
    return (
      <div className="uploadDocuments">
        <div className="gridContainer">
          <div className="gridComponent">
            <table>
              <tr>
                <th style={{ width: "10%" }}>Documents</th>
                <th style={{ width: "10%" }}>Upload</th>
                <th style={{ width: "10%" }}></th>
                <th style={{ width: "10%" }}>Reason for Reject</th>
              </tr>
              {this.state.AllFiles &&
                this.state.AllFiles.map((data, i) => (
                  <tr key={i}>
                    <td title={data.fileName}>
                        {this.trimFileName(data.fileName)}
                      </td>
                    <td>
                      <a
                        className="addMoreAction"
                        onClick={() => {
                          getFileDownload(
                            serverConfig.SERVER_URL + data.retrievalPath,
                            data.fileName,
                            data.fileExtension
                          );
                        }}
                        title={data.fileName}
                      >
                        {this.trimFileName(data.fileName)}
                      </a>
                    </td>
                    <td>
                      <div className="d-flex cloumnButtonBlock">
                        {data.fileStatus === null || data.fileStatus == 1 ? (
                          <>
                            <CustomButton
                              className="greenBorderButton"
                              label="Reject"
                              handleClick={() => {
                                this.handleStatus(
                                  data.fileUploadId,
                                  data,
                                  4,
                                  i,
                                  data.sourceMenu
                                );
                              }}
                            />
                            <CustomButton
                              className="greenButton"
                              label="Approve"
                              handleClick={() => {
                                this.handleStatus(
                                  data.fileUploadId,
                                  data,
                                  3,
                                  i,
                                  data.sourceMenu
                                );
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                        {data.fileStatus === 3 && (
                          <div className="addMoreAction">Approved</div>
                        )}
                        {data.fileStatus === 4 && (
                          <div className="addMoreAction">Rejected</div>
                        )}
                      </div>
                    </td>
                    <td>{data.fileRemarks}</td>
                  </tr>
                ))}
            </table>
          </div>
        </div>

        <div className="consoleFormButtonBlock">
          <Link to="/add_employee">
            <CustomButton label={"Cancel"} className="greenBorderButton" />
          </Link>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    allFiles,
    Download_File,
    Update_file,
    personalDetails,
    experienceDetailsViewById,
    getEbId,
  } = state.EmployeeReducer;
  return {
    allFiles,
    Download_File,
    Update_file,
    personalDetails,
    experienceDetailsViewById,
    getEbId,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getAllFiles,
    downLoadFiles,
    updateFiles,
    viewbyidPersonalDetails,
    ViewByIdExperienceDetails,
  })(UploadDocument)
);
