import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextField from "../../../components/TextField/TextField";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import CustomButton from "../../../components/Buttons/Buttons";
import Link from "@mui/material/Link";
import InputAdornment from "../../../components/InputAdorment/InputAdorment";
import TextFieldAlfaNum from "../../../components/TextField/TextFieldAlfaNum";
import TextFieldAlfaNumReq from "../../../components/TextField/TextFieldAlfaNumReq";

import {
  getSupplierList,
  getBranchList,
} from "../../../store/Global/DropDownApis/actions";
import { serverApi } from "../../../helpers/Consts";
import moment from "moment";

class BillsFillDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billNo: "",
      supplierCode: "",
      taxType: "",
      branchId: "",
      invoiceNo: "",
      invoiceAmount: "",
      tdsPayable: "",
      tcsPercentage: "",
      tcsAmount: "",
      invoiceDate: "",
      invoiceRecievedDate: "",
      paymentDueDate: "",
      billDate: "",
      supplierList: [],
      branchListData: [],
      companyId: localStorage.getItem("companyId"),
    };
  }

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    this.props.onSelectDep(selectedValue, selectedName, name);
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getSupplierList(serverApi.VENDOR_LIST + this.state.companyId);
    this.props.getBranchList(
      serverApi.BRANCH_LIST + this.state.companyId,
      this.props.history
    );
  };

  componentWillReceiveProps(props) {
    if (props.supplierList) {
      this.setState({
        supplierList: props.supplierList.data,
        branchListData: props.branchList.data,
      });
    }
  }

  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
    this.props.onhandlechangeValue(event, key);
  };

  handleSelect_Date = (e, name) => {
    if (name === "billDate") {
      var date = moment(e).format();
      var fromdate = date.split("T", 1).toString();

      this.setState({
        billDate: fromdate,
      });
    }

    if (name === "invoiceDate") {
      var date = moment(e).format();
      var fromdate = date.split("T", 1).toString();

      this.setState({
        invoiceDate: fromdate,
      });
    }

    if (name === "invoiceRecievedDate") {
      var date = moment(e).format();
      var fromdate = date.split("T", 1).toString();

      this.setState({
        invoiceRecievedDate: fromdate,
      });
    }

    if (name === "paymentDueDate") {
      var date = moment(e).format();
      var fromdate = date.split("T", 1).toString();

      this.setState({
        paymentDueDate: fromdate,
      });
    }

    this.props.handleSelectDate(fromdate, name);
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>Bill Date</label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="dd-MM-yyyy"
                            name="billDate"
                            value={this.state.billDate}
                            fullWidth
                            onChange={(e) =>
                              this.handleSelect_Date(e, "billDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        label="Bill No"
                        value={this.state.billNo}
                        caps="OFF"
                        minValue="0"
                        onChange={this.handleChange("billNo")}
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.supplierList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.supplierCode}
                        name="supplierCode"
                        label="Vendor"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={taxType}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.taxType}
                        name="taxType"
                        label="Tax Type"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.branchId}
                        name="branchId"
                        label="Branch"
                        required
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormContainer">
          <h5>Vendor Invoice Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        label="Invoice No"
                        value={this.state.invoiceNo}
                        caps="OFF"
                        minValue="0"
                        onChange={this.handleChange("invoiceNo")}
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>Invoice Date</label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="dd-MM-yyyy"
                            name="invoiceDate"
                            value={this.state.invoiceDate}
                            fullWidth
                            onChange={(e) =>
                              this.handleSelect_Date(e, "invoiceDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>Payment Due Date</label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="dd-MM-yyyy"
                            name="paymentDueDate"
                            value={this.state.paymentDueDate}
                            fullWidth
                            onChange={(e) =>
                              this.handleSelect_Date(e, "paymentDueDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        label="Invoice Amount"
                        value={this.state.invoiceAmount}
                        caps="OFF"
                        minValue="0"
                        onChange={this.handleChange("invoiceAmount")}
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>Invoice Received Date</label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="dd-MM-yyyy"
                            name="invoiceRecievedDate"
                            value={this.state.invoiceRecievedDate}
                            fullWidth
                            onChange={(e) =>
                              this.handleSelect_Date(e, "invoiceRecievedDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={tdsPayable}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.tdsPayable}
                        name="tdsPayable"
                        label="TDS Payable"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={TcsPercentage}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.tcsPercentage}
                        name="tcsPercentage"
                        label="TCS Percentage"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        label="TCS Amount"
                        value={this.state.tcsAmount}
                        caps="OFF"
                        minValue="0"
                        onChange={this.handleChange("tcsAmount")}
                        required
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

const arrayOfIndent = [
  {
    value: "1",
    label: "Capital",
    name: "Capital",
  },
  {
    value: "2",
    label: "General",
    name: "General",
  },
  {
    value: "3",
    label: "Maintainence",
    name: "Maintainence",
  },
  {
    value: "4",
    label: "Open",
    name: "Open",
  },
  {
    value: "5",
    label: "Production",
    name: "Production",
  },
];
const Project = [
  {
    value: 1,
    label: "Project1",
    name: "Project 1",
  },
  {
    value: 2,
    label: "Project2",
    name: "Project 2",
  },
  {
    value: 3,
    label: "Project3",
    name: "Project 3",
  },
];

const taxType = [
  { value: 0, label: "Select...", name: "Select..." },
  { value: 1, label: "IGST", name: "IGST" },
  { value: 2, label: "CGST & SGST", name: "CGST & SGST" },
  { value: 3, label: "VAT", name: "VAT" },
  { value: 4, label: "No Tax", name: "No Tax" },
];
const phase = [];

const tdsPayable = [
  { value: "Select", label: "Select..." },
  { value: "Y", label: "Yes" },
  { value: "N", label: "No" },
];

const TcsPercentage = [
  { label: "0%", value: 0 },
  { label: "0.075%", value: 0.075 },
  { label: "0.1%", value: 0.1 },
  { label: "1%", value: 1 },
];

const client = [
  {
    value: "1",
    lable: "Center",
    name: "Center",
  },
  {
    value: "2",
    label: "TechStar",
    name: "TechStar",
  },
  {
    value: "3",
    label: "Mahindra Logistics",
    name: "Mahindra Logistics",
  },
  {
    value: "4",
    label: "Smartworks",
    name: "Smartworks",
  },
];

const CategoryList = [
  {
    value: "1",
    label: "OPEN",
    name: "OPEN",
  },
  {
    value: "2",
    label: "OPEX",
    name: "OPEX",
  },
  {
    value: "3",
    label: "CAPEX",
    name: "CAPEX",
  },
];

const mapStatetoProps = (state) => {
  const { VendorList } = state.BillsReducer;
  const { supplierList, branchList } = state.DropDownListReducer;
  return { VendorList, supplierList, branchList };
};
export default connect(mapStatetoProps, {
  getSupplierList,
  getBranchList,
})(withRouter(BillsFillDetails));
