import {PAYSLIP_LIST, PAYSLIP_LIST_SUCCESSFULL, PAYSLIP_DATA, PAYSLIP_DATA_SUCCESSFULL,
     PAYSLIP_AVAILABLE_LIST, PAYSLIP_AVAILABLE_LIST_SUCCESSFULL,
      PAY_SUMMARY, PAY_SUMMARY_SUCCESSFULL, PAYSLIP_DUP_DATA, 
      PAYSLIP_DUP_DATA_SUCCESSFULL,PAYSLIP_DATA_CLEAR} from "./actionType";

export const getPayslipList = (url,data,history) =>{
    return {
        type:PAYSLIP_LIST,
        payload:{url,data,history}
    }
}

export const payslipListSuccessfull = (data)=>{
    return {
        type:PAYSLIP_LIST_SUCCESSFULL,
        payload:data
    }
}

export const paySlipsByEbNo = (url, history) =>{
    return {
        type:PAYSLIP_DATA,
        payload:{url,history}
    }
}

export const paySlipsByEbNoSuccessfull = (data)=>{
    return {
        type:PAYSLIP_DATA_SUCCESSFULL,
        payload:data
    }
}
export const paySlipDataClear = (data)=>{
    return {
        type:PAYSLIP_DATA_CLEAR,
        payload:data
    }
}

export const paySlipsByEbNo2 = (url,fromDate, toDate, history) =>{
    return {
        type:PAYSLIP_DUP_DATA,
        payload:{url,history}
    }
}

export const paySlipsByEbNoSuccessfull2 = (data)=>{
    return {
        type:PAYSLIP_DUP_DATA_SUCCESSFULL,
        payload:data
    }
}
// THIS IS FOR GET ALL AVAILABLE PAYSLIP DATA
export const getAllpaySlips = (url,data,history) =>{
    return {
        type:PAYSLIP_AVAILABLE_LIST,
        payload:{url,data,history}
    }
}

export const getAllPaySlipsSuccessfull = (data)=>{
    return {
        type:PAYSLIP_AVAILABLE_LIST_SUCCESSFULL,
        payload:data
    }
}

export const getPaySummary = (url,data,history) =>{
    return {
        type:PAY_SUMMARY,
        payload:{url,data,history}
    }
}

export const getPaySummarySuccessfull = (data)=>{
    return {
        type:PAY_SUMMARY_SUCCESSFULL,
        payload:data
    }
}

