// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  HIDE_RIGHT_SIDEBAR,
  HEADER_DATA,
} from "./actionTypes";

import {
  changeSidebarType as changeSidebarTypeAction,
  changeTopbarTheme as changeTopbarThemeAction,
  headerDataSuccess,
} from "./actions";

/**
 * Changes the body attribute
 */
function changeBodyAttribute(attribute, value) {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
}

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
function manageBodyClass(cssClass, action = "toggle") {
  switch (action) {
    case "add":
      if (document.body) document.body.classList.add(cssClass);
      break;
    case "remove":
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* changeLayout({ payload: layout }) {
  try {
    if (layout === "horizontal") {
      yield put(changeTopbarThemeAction("dark"));
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-sidebar-size");
    } else {
      yield put(changeTopbarThemeAction("light"));
    }
    yield call(changeBodyAttribute, "data-layout", layout);
  } catch (error) {}
}

/**
 * Changes the layout width
 * @param {*} param0
 */
function* changeLayoutWidth({ payload: { width, layoutType } }) {
  try {
    if (layoutType === "vertical") {
      if (width === "boxed") {
        yield put(changeSidebarTypeAction("icon"));
      } else {
        yield put(changeSidebarTypeAction("default"));
      }
    }
    yield call(changeBodyAttribute, "data-layout-size", width);
  } catch (error) {}
}

/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
function* changeLeftSidebarTheme({ payload: { theme, layoutType } }) {
  try {
    yield call(changeBodyAttribute, "data-sidebar", theme);
    if (layoutType === "vertical") {
      if (theme === "light")
        //Fire action for changing dark theme of topbar
        yield put(changeTopbarThemeAction("dark"));
      if (theme === "dark")
        //Fire action for changing light theme of topbar
        yield put(changeTopbarThemeAction("light"));
    }
  } catch (error) {}
}

/**
 * Changes the topbar theme
 * @param {*} param0
 */
function* changeTopbarTheme({ payload: theme }) {
  try {
    yield call(changeBodyAttribute, "data-topbar", theme);
  } catch (error) {}
}

/**
 * Changes the left sidebar type
 * @param {*} param0
 */
function* changeLeftSidebarType({ payload: { sidebarType, isMobile } }) {
  try {
    switch (sidebarType) {
      case "compact":
        yield call(changeBodyAttribute, "data-sidebar-size", "small");
        yield call(manageBodyClass, "sidebar-enable", "remove");
        yield call(manageBodyClass, "vertical-collpsed", "remove");
        break;
      case "icon":
        yield call(changeBodyAttribute, "data-keep-enlarged", "true");
        yield call(manageBodyClass, "vertical-collpsed", "add");
        break;
      case "condensed":
        yield call(manageBodyClass, "sidebar-enable", "add");
        if (!isMobile) yield call(manageBodyClass, "vertical-collpsed", "add");
        break;
      default:
        yield call(changeBodyAttribute, "data-sidebar-size", "");
        yield call(manageBodyClass, "sidebar-enable", "remove");
        if (!isMobile)
          yield call(manageBodyClass, "vertical-collpsed", "remove");
        break;
    }
  } catch (error) {}
}

function* getHeaderData({ payload: layout }) {
  try {
    if (layout.pathname === "/dashboard") {
      let response = {
        parentName: "Dashboard",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 0,
        backlink: "0",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/Purchase") {
      let response = {
        parentName: "Purchase Order DashBorad",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 0,
        backlink: "0",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/myProfile") {
      let response = {
        parentName: "Profile",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 1,
        backlink: "/dashboard",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/store_indent") {
      let response = {
        parentName: "Indent",
        createbranchbtn: "purchase_1",
        subtitle: "",
        backicon: 0,
        backlink: "",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/openIndent") {
      let response = {
        parentName: "Open Indent",
        createbranchbtn: "purchase_7",
        subtitle: "",
        backicon: 0,
        backlink: "",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/create_indent") {
      let response = {
        parentName:
          layout.state === undefined ? "Create Indent" : "Update Indent",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 1,
        backlink: "/store_indent",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/create_openIndent") {
      let response = {
        parentName:
          layout.state === undefined
            ? "Create Open Indent"
            : "Update Open Indent",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 1,
        backlink: "/openIndent",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/purchase_price_request") {
      let response = {
        parentName: "Price Enquiry",
        createbranchbtn: "purchase_2",
        subtitle: "",
        backicon: 0,
        backlink: "",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/create_priceenquiry") {
      let response = {
        parentName: " Create Price Enquiry",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 1,
        backlink: "/purchase_price_request",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/work_order") {
      let response = {
        parentName: "Work Order",
        createbranchbtn: "purchase_3",
        subtitle: "",
        backicon: 0,
        backlink: "",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/create_workorder") {
      let response = {
        parentName:
          layout.state === undefined
            ? "Create Work Order"
            : "Update Work Order",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 1,
        backlink: "/work_order",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/pr_response") {
      let response = {
        parentName: "PR Response",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 0,
        backlink: "",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/create_pr_response") {
      let response = {
        parentName: "PR Response",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 1,
        backlink: "/pr_response",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/security_store_gate_entry") {
      let response = {
        parentName:
          JSON.parse(localStorage.getItem("authUser")).roleId === 100
            ? "Dispatch"
            : "Inward Entry",
        createbranchbtn: "purchase_4",
        subtitle: "",
        backicon: 0,
        backlink: "",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/create_inward") {
      let response = {
        parentName:
          layout.state === undefined
            ? JSON.parse(localStorage.getItem("authUser")).roleId === 100
              ? "Create Dispatch"
              : "Create Inward Entry"
            : JSON.parse(localStorage.getItem("authUser")).roleId === 100
            ? "Create Dispatch"
            : "Update Inward Entry",
        createbranchbtn: "",
        subtitle: "",
        backicon: 1,
        backlink: "/security_store_gate_entry",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/workReport") {
      let response = {
        parentName: "Work Report",
        createbranchbtn: "purchase_8",
        subtitle: "",
        backicon: 0,
        backlink: "",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/create_WorkReport") {
      let response = {
        parentName:
          layout.state === undefined
            ? "Create Work Report"
            : "Update Work Report",
        createbranchbtn: "",
        subtitle: "",
        backicon: 1,
        backlink: "/workReport",
      };
      yield put(headerDataSuccess(response));
    }

    if (layout.pathname === "/purchase_purchase_order") {
      let response = {
        parentName: "Purchase Order",
        createbranchbtn: "purchase_5",
        subtitle: "",
        backicon: 0,
        backlink: "",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/create_purchase_order") {
      let response = {
        parentName:
          layout.state === undefined
            ? "Create Purchase Order"
            : "Update Purchase Order",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 1,
        backlink: "/purchase_purchase_order",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/store_material_inspection") {
      let response = {
        parentName: "Material Inspection",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 0,
        backlink: "",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/create_material_inspection") {
      let response = {
        parentName: "Material Inspection",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 1,
        backlink: "/store_material_inspection",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/store_bill_pass") {
      let response = {
        parentName: "Bill Pass",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 0,
        backlink: "",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/store_receipt") {
      let response = {
        parentName: "Store Receipt",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 0,
        backlink: "",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/Create_store_receipt") {
      let response = {
        parentName: "Create store receipt",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 1,
        backlink: "/store_receipt",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/store_debit_credit_note") {
      let response = {
        parentName: "Debit Note",
        createbranchbtn: "purchase_6",
        subtitle: "",
        backicon: 0,
        backlink: "",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/create_DC_note") {
      let response = {
        parentName:
          layout.state === undefined
            ? "Create Debit Note"
            : "Update Debit Note",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 1,
        backlink: "/store_debit_credit_note",
      };
      yield put(headerDataSuccess(response));
    }
    //Bill PASS
    if (layout.pathname === "/store_bill_pass") {
      let response = {
        parentName: "Bill Pass",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 0,
        backlink: "",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/create_store_bill_pass") {
      let response = {
        parentName: "Create Bill Pass",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 1,
        backlink: "/store_bill_pass",
      };
      yield put(headerDataSuccess(response));
    }

    //   ******Store Layout Paths*******
    if (layout.pathname === "/Store") {
      let response = {
        parentName: "Store Order DashBorad",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 0,
        backlink: "0",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/material_request") {
      let response = {
        parentName: "Material Request",
        createbranchbtn: "store_1",
        subtitle: "",
        backicon: 0,
        backlink: "",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/create_material_request") {
      let response = {
        parentName: "Create Material Request",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 1,
        backlink: "/material_request",
      };
      yield put(headerDataSuccess(response));
    }

    if (layout.pathname === "/store_issue") {
      let response = {
        parentName: "Consumption",
        createbranchbtn: "store_2",
        subtitle: "",
        backicon: 0,
        backlink: "",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/create_consumption") {
      let response = {
        // parentName: 'Create Consumption',
        parentName:
          layout.state === undefined
            ? "Create Consumption"
            : "Update Consumption",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 1,
        backlink: "/store_issue",
      };
      yield put(headerDataSuccess(response));
    }

    if (layout.pathname === "/stock_transfer") {
      let response = {
        parentName: "Stock Transfer",
        createbranchbtn: "store_3",
        subtitle: "",
        backicon: 0,
        backlink: "",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/createStockTransfer") {
      let response = {
        parentName: "Create Stock Transfer",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 1,
        backlink: "/stock_transfer",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/stock_return") {
      let response = {
        parentName: "Stock Return",
        createbranchbtn: "store_4",
        subtitle: "",
        backicon: 0,
        backlink: "",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/createStockReturn") {
      let response = {
        parentName: "Create Stock Return",
        createbranchbtn: 0,
        subtitle: "",
        backicon: 1,
        backlink: "/stock_return",
      };
      yield put(headerDataSuccess(response));
    }
  } catch (error) {}

  // HRMS Module Start
  // Employee Database sub-module
  if (layout.pathname === "/employeedb") {
    let response = {
      parentName: "Employee Database",
      createbranchbtn: "HRMS_13",
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/hrms") {
    let response = {
      parentName: "Employee Database",
      createbranchbtn: "HRMS_01",
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/listOf_employees") {
    let response = {
      parentName: "Employee Database",
      createbranchbtn: "HRMS_12",
      subtitle: "",
      backicon: 1,
      backlink: "/employeedb",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/add_employee") {
    let response = {
      parentName: "Add Employee",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/listOf_employees",
    };

    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/bulk_employee") {
    let response = {
      parentName: " Bulk Employee Upload",
      createbranchbtn: "",
      subtitle: "",
      backicon: 1,
      backlink: "/employeedb",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/AadharVerification") {
    let response = {
      parentName: "Aadhar Verification",
      createbranchbtn: "",
      subtitle: "",
      backicon: 1,
      backlink: "/add_employee",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/personal_information") {
    let response = {
      parentName: "Personal Information",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/add_employee",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/offical_information") {
    let response = {
      parentName: "Official Details",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/add_employee",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/upload_document") {
    let response = {
      parentName: "Upload Document",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/add_employee",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/shift_leavePolicy") {
    let response = {
      parentName: "Shift and Leave Policy",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/add_employee",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/medical_enrollment") {
    let response = {
      parentName: "Medical Enrollments/ESI/PF",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/add_employee",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/onboarding") {
    let response = {
      parentName: "Onboarding",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/add_employee",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/welcome_letter") {
    let response = {
      parentName: "Welcome letter",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/add_employee",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/offerLetter") {
    let response = {
      parentName: "Offer letter",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/add_employee",
    };
    yield put(headerDataSuccess(response));
  }
  // Attendance Calender sub-module
  if (layout.pathname === "/attencalendar") {
    let response = {
      parentName: "Attendance Calendar",
      createbranchbtn: "HRMS_02",
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/Attendance_list") {
    let response = {
      parentName: "Attendance List",
      createbranchbtn: "HRMS_03",
      subtitle: "",
      backicon: 1,
      backlink: "/attencalendar",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/mark_attendance") {
    let response = {
      parentName: "Mark Attendance",
      createbranchbtn: "HRMS_04",
      subtitle: "",
      backicon: 1,
      backlink: "/Attendance_list",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/bulk_attendance") {
    let response = {
      parentName: "Bulk Attendance",
      createbranchbtn: "HRMS_05",
      subtitle: "",
      backicon: 1,
      backlink: "/mark_attendance",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/logs") {
    let response = {
      parentName: "Attendance Logs",
      createbranchbtn: "HRMS_14",
      subtitle: "",
      backicon: 1,
      backlink: "/attencalendar",
    };
    yield put(headerDataSuccess(response));
  }

  // Leave Request sub-module
  if (layout.pathname === "/leaverequest") {
    let response = {
      parentName: "Leave Request",
      createbranchbtn: "HRMS_06",
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/apply_leave") {
    let response = {
      parentName: "Apply Leave",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/leaverequest",
    };
    yield put(headerDataSuccess(response));
  }
  // Client Visit sub-module
  if (layout.pathname === "/clientvisit") {
    let response = {
      parentName: "Client Visit Plan",
      createbranchbtn: "HRMS_07",
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }

  // Expense Booking sub-module
  if (layout.pathname === "/expensebooking") {
    let response = {
      parentName: "Expense Booking",
      createbranchbtn: "HRMS_08",
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_expense") {
    let response = {
      parentName: "Create Expense Booking",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/expensebooking",
    };
    yield put(headerDataSuccess(response));
  }
  // Advance Request sub-module
  if (layout.pathname === "/advancerequest") {
    let response = {
      parentName: "Advance Request",
      createbranchbtn: "HRMS_09",
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_advanceRequest") {
    let response = {
      parentName: "Create Advance Request",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/advancerequest",
    };
    yield put(headerDataSuccess(response));
  }
  // Payroll Data sub-module
  if (layout.pathname === "/data_collection") {
    let response = {
      parentName: "Payroll Data",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }
  // Pay Register sub-module

  if (layout.pathname === "/payregister") {
    let response = {
      parentName: "Pay Register",
      createbranchbtn: "HRMS_10",
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_new") {
    let response = {
      parentName: "Create Pay Register",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/payregister",
    };
    yield put(headerDataSuccess(response));
  }

  if (layout.pathname === "/ViewPayRegister") {
    let response = {
      parentName: "View Pay Register",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/payregister",
    };
    yield put(headerDataSuccess(response));
  }
  // Pay Slip sub-module
  if (layout.pathname === "/payslip") {
    let response = {
      parentName: "Pay Slip",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }

  // Pay Slip sub-module
  if (layout.pathname === "/pay_slip") {
    let response = {
      parentName: "Pay Slip",
      createbranchbtn: "HRMS_11",
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/view_paySlip") {
    let response = {
      parentName: "Pay Slip",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/payslip",
    };
    yield put(headerDataSuccess(response));
  }
  //payscheme Parameters
  if (layout.pathname === "/psparameter") {
    let response = {
      parentName: "PayScheme Parameters",
      createbranchbtn: "PayScheme_1",
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_payScheme_parameters") {
    let response = {
      parentName: "Create PayScheme Parameters",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/psparameter",
    };
    yield put(headerDataSuccess(response));
  }
  //PayScheme Creation
  if (layout.pathname === "/payscheme") {
    let response = {
      parentName: "Pay Scheme Creation",
      createbranchbtn: "PayScheme_2",
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_payScheme_creation") {
    let response = {
      parentName: "Create Pay Scheme",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/payscheme",
    };
    yield put(headerDataSuccess(response));
  }

  // HRMS Module End

  // Sales Module Start
  // Quotation sub-module
  if (layout.pathname === "/sales") {
    let response = {
      parentName: "Sales Order DashBorad",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 0,
      backlink: "0",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/quotation") {
    let response = {
      parentName: "Quotation",
      createbranchbtn: "sales_1",
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_quotation") {
    let response = {
      parentName:
        layout.state === undefined ? "Create Quotation" : "Update Quotation",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/quotation",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }

  // if (layout.pathname === '/create_indent') {
  //   let response = {
  //     parentName: 'Create Indent',
  //     createbranchbtn: 0,
  //     subtitle: '',
  //     backicon: 1,
  //     backlink: '/store_indent',
  //   }
  //   yield put(headerDataSuccess(response))
  // }
  // Sales Order sub-module
  if (layout.pathname === "/s_order") {
    let response = {
      parentName: "Sales Order",
      createbranchbtn: "sales_2",
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_sales_order") {
    let response = {
      parentName:
        layout.state === undefined
          ? "Create Sales Order"
          : "Update Sales Order",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/s_order",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  // Delivery Order submodule
  if (layout.pathname === "/delivery_order") {
    let response = {
      parentName: "Delivery Order",
      createbranchbtn: "sales_3",
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_delivery_order") {
    let response = {
      parentName:
        layout.state === undefined
          ? "Create Delivery Order"
          : "Update Delivery Order",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/delivery_order",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  // Invoice Module
  if (layout.pathname === "/invoice") {
    let response = {
      parentName: "Invoice",
      createbranchbtn: "sales_4",
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_invoice") {
    let response = {
      parentName:
        layout.state === undefined ? "Create Invoice" : "Update Invoice",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/invoice",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  // Re-Occuring Invoice Module
  if (layout.pathname === "/reoccurinvoice") {
    let response = {
      parentName: "Re-Occuring Invoice",
      createbranchbtn: "sales_5",
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_ReOccuring_invoice") {
    let response = {
      parentName: "Create Re-Occuring Invoice",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/reoccurinvoice",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/spell_master") {
    let response = {
      parentName: "Spell Master",
      createbranchbtn: "spell_master_1",
      subtitle: "",
      backicon: 0,
      backlink: "/Master",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_spell_master") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Spell Master"
          : "Update Spell Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/spell_master",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/view_spellMaster") {
    let response = {
      parentName: "Update Spell Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/spell_master",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/Holidays_Calendar") {
    let response = {
      parentName: "Holidays Calendar",
      createbranchbtn: "master_37",
      subtitle: "",
      backicon: 1,
      backlink: "/Holidays_Calendar",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/Holidays_List") {
    let response = {
      parentName: "Holidays List",
      createbranchbtn: "master_38",
      subtitle: "",
      backicon: 1,
      backlink: "/Holidays_Calendar",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_holiday") {
    let response = {
      parentName:
        layout.state == undefined ? "Create Holiday" : "Update Holiday",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/Holidays_List",
    };
    yield put(headerDataSuccess(response));
  }
  // Store
  // if (layout.pathname === '/stock_transfer') {
  //   let response = {
  //     parentName: 'Stock Transfer',
  //     createbranchbtn: 'store_1',
  //     subtitle: '',
  //     backicon: 0,
  //     backlink: '',
  //     moreAbtn: true,
  //   }
  //   yield put(headerDataSuccess(response))
  // }
  // if (layout.pathname === '/Store') {
  //   let response = {
  //     parentName: 'Material Request',
  //     createbranchbtn: 'store_3',
  //     subtitle: '',
  //     backicon: 0,
  //     backlink: '',
  //     moreAbtn: true,
  //   }
  //   yield put(headerDataSuccess(response))
  // }
  // Sales Module End
  //master module start
  if (layout.pathname === "/tax_master") {
    let response = {
      parentName: "Tax Master",
      createbranchbtn: "master_1",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_tax_master") {
    let response = {
      parentName:
        layout.state == undefined ? "Create Tax master" : "Update Tax master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/tax_master",
    };
    yield put(headerDataSuccess(response));
  }
  //Uom Master subModule
  if (layout.pathname === "/uom_master") {
    let response = {
      parentName: "UOM Master",
      createbranchbtn: "master_2",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_uom_master") {
    let response = {
      parentName:
        layout.state == undefined ? "Create UOM Master" : "Update UOM Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/uom_master",
    };
    yield put(headerDataSuccess(response));
  }
  //Department master module
  if (layout.pathname === "/department_master") {
    let response = {
      parentName: "Department Master",
      createbranchbtn: "master_3",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_department_master") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Department Master"
          : "Update Department Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/department_master",
    };
    yield put(headerDataSuccess(response));
  }
  //Supplier item module
  if (layout.pathname === "/supplier_items") {
    let response = {
      parentName: "Supplier Items",
      createbranchbtn: "master_4",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_supplier_items") {
    let response = {
      parentName: "Create Supplier Items",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/supplier_items",
    };
    yield put(headerDataSuccess(response));
  }

  //Item Master
  if (layout.pathname === "/item_master") {
    let response = {
      parentName: "Item Master",
      createbranchbtn: "master_5",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_item_master") {
    let response = {
      parentName:
        layout.state == undefined ? "Create Item Master" : "Update Item Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/item_master",
    };
    yield put(headerDataSuccess(response));
  }
  //Item Group Master
  if (layout.pathname === "/item_group_master") {
    let response = {
      parentName: "Item Group Master",
      createbranchbtn: "master_6",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_item_group_master") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Item Group Master"
          : "Update Item Group Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/item_group_master",
    };
    yield put(headerDataSuccess(response));
  }
  //Quality Master
  if (layout.pathname === "/quality_master") {
    let response = {
      parentName: "Jute Quality Master",
      createbranchbtn: "master_7",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_quality_master") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Jute Quality Master"
          : "Update Jute Quality Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/quality_master",
    };
    yield put(headerDataSuccess(response));
  }
  //Weaving quality master
  if (layout.pathname === "/weaving_quality_master") {
    let response = {
      parentName: "Weaving Quality Master",
      createbranchbtn: "master_8",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_weaving_quality_master") {
    let response = {
      parentName:   layout.state == undefined ? "Create Weaving Quality Master" :"Update Weaving Quality Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/weaving_quality_master",
    };
    yield put(headerDataSuccess(response));
  }
  //Spinning quality master
  if (layout.pathname === "/spinning_quality_master") {
    let response = {
      parentName: "Spinning Quality Master",
      createbranchbtn: "master_9",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_spinning_quality_master") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Spinning Quality Master"
          : "Update Spinning Quality Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/spinning_quality_master",
    };
    yield put(headerDataSuccess(response));
  }
  //Machine master
  if (layout.pathname === "/machine_master") {
    let response = {
      parentName: "Machine Master",
      createbranchbtn: "master_10",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_machine_master") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Machine Master"
          : "Update Machine Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/machine_master",
    };
    yield put(headerDataSuccess(response));
  }
  //Company master
  if (layout.pathname === "/company_master") {
    let response = {
      parentName: "Company Master",
      createbranchbtn: "master_11",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_company_master") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Company Master"
          : "Update Company Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/company_master",
    };
    yield put(headerDataSuccess(response));
  }
  //Branch master
  if (layout.pathname === "/branch_master") {
    let response = {
      parentName: "Branch Master",
      createbranchbtn: "master_12",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_branch_master") {
    let response = {
      parentName: "Create Branch Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/branch_master",
    };
    yield put(headerDataSuccess(response));
  }
  //sub Department Master
  if (layout.pathname === "/sub_department_master") {
    let response = {
      parentName: "Sub Department Master",
      createbranchbtn: "master_13",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_sub_department_master") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Sub Department Master"
          : "Update Sub Department Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/sub_department_master",
    };
    yield put(headerDataSuccess(response));
  }
  //Vehicle Types Master
  if (layout.pathname === "/vehicle_types_master") {
    let response = {
      parentName: "Vehicle Types Master",
      createbranchbtn: "master_14",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_vehicle_types_master") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Vehicle Types Master"
          : "Update Vehicle Types Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/vehicle_types_master",
    };
    yield put(headerDataSuccess(response));
  }
  //Mukam Master
  if (layout.pathname === "/mukam_master") {
    let response = {
      parentName: "Mukam Master",
      createbranchbtn: "master_15",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_mukam_master") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Mukam Master"
          : "Update Mukam Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/mukam_master",
    };
    yield put(headerDataSuccess(response));
  }
  //Yarn Master
  if (layout.pathname === "/yarn_master") {
    let response = {
      parentName: "Yarn Master",
      createbranchbtn: "master_16",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_yarn_master") {
    let response = {
      parentName:
        layout.state == undefined ? "Create Yarn Master" : "Update Yarn Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/yarn_master",
    };
    yield put(headerDataSuccess(response));
  }
  //Ledger Master
  if (layout.pathname === "/Ledger_Master") {
    let response = {
      parentName: "Ledger Master",
      createbranchbtn: "master_17",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_Ledger_Master") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Ledger Master"
          : "Update Ledger Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/Ledger_Master",
    };
    yield put(headerDataSuccess(response));
  }
  //Ledger group Master
  if (layout.pathname === "/Ledger_Groups") {
    let response = {
      parentName: "Ledger Groups Master",
      createbranchbtn: "master_34",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_Ledger_Groups") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Ledger Groups Master"
          : "Update Ledger Groups Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/Ledger_Groups",
    };
    yield put(headerDataSuccess(response));
  }
  //Transporter Master
  if (layout.pathname === "/transporter_master") {
    let response = {
      parentName: "Transporter Master",
      createbranchbtn: "master_18",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_Transporter_master") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Transporter Master"
          : "Update Transporter Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/transporter_master",
    };
    yield put(headerDataSuccess(response));
  }
  //Designation Master
  if (layout.pathname === "/designation_master") {
    let response = {
      parentName: "Designation Master",
      createbranchbtn: "master_19",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_designation_master") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Designation Master"
          : "Update Designation Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/designation_master",
    };
    yield put(headerDataSuccess(response));
  }
  //Terms
  if (layout.pathname === "/terms") {
    let response = {
      parentName: "Terms",
      createbranchbtn: "master_20",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_terms_master") {
    let response = {
      parentName: layout.state == undefined ? "Create Terms" : "Update Terms",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/terms",
    };
    yield put(headerDataSuccess(response));
  }
  //Broker Master
  if (layout.pathname === "/broker_master") {
    let response = {
      parentName: "Broker Master",
      createbranchbtn: "master_21",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_broker_master") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Broker Master"
          : "Update Broker Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/broker_master",
    };
    yield put(headerDataSuccess(response));
  }
  //Agent Master
  if (layout.pathname === "/agent_master") {
    let response = {
      parentName: "Agent Master",
      createbranchbtn: "master_22",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_agent_master") {
    let response = {
      parentName: "Create Agent Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/agent_master",
    };
    yield put(headerDataSuccess(response));
  }
  //Worker Category Master
  if (layout.pathname === "/Worker_Category_Master") {
    let response = {
      parentName: "Worker Category Master",
      createbranchbtn: "master_23",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create__Worker_Category_Master") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Worker Category Master"
          : "Update Worker Category Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/Worker_Category_Master",
    };
    yield put(headerDataSuccess(response));
  }
  //Cost Center Master
  if (layout.pathname === "/Cost_Center_Master") {
    let response = {
      parentName: "Cost Center Master",
      createbranchbtn: "master_24",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_Cost_Center_Master") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Cost Center Master"
          : "Update Cost Center Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/Cost_Center_Master",
    };
    yield put(headerDataSuccess(response));
  }
  //Travel Catageries Master
  if (layout.pathname === "/Travel_Catageries") {
    let response = {
      parentName: "Travel Categories",
      createbranchbtn: "master_25",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_Travel_Catageries") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Travel Category"
          : "Update Travel Category",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/Travel_Catageries",
    };
    yield put(headerDataSuccess(response));
  }
  //Bank Accounts Master
  if (layout.pathname === "/Bank_Accounts") {
    let response = {
      parentName: "Bank Accounts",
      createbranchbtn: "master_26",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_Bank_Accounts") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Bank Account"
          : "Update Bank Account",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/Bank_Accounts",
    };
    yield put(headerDataSuccess(response));
  }
  //Leave Types
  if (layout.pathname === "/leave_types") {
    let response = {
      parentName: "Leave Types",
      createbranchbtn: "master_27",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_leave_types") {
    let response = {
      parentName:
        layout.state == undefined ? "Create Leave Types" : "Update Leave Types",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/leave_types",
    };
    yield put(headerDataSuccess(response));
  }
  //Help Topic
  if (layout.pathname === "/helptopic") {
    let response = {
      parentName: "Help Topic",
      createbranchbtn: "master_28",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_helptopic") {
    let response = {
      parentName: "Create Help Topic",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/helptopic",
    };
    yield put(headerDataSuccess(response));
  }
  //Problem Master
  if (layout.pathname === "/problemmaster") {
    let response = {
      parentName: "Problem Master",
      createbranchbtn: "master_29",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create__problem_master") {
    let response = {
      parentName: "Create Problem Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/problemmaster",
    };
    yield put(headerDataSuccess(response));
  }
  //Carriers
  if (layout.pathname === "/carriers") {
    let response = {
      parentName: "Carriers",
      createbranchbtn: "master_30",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create__carriers") {
    let response = {
      parentName: "Create Carriers",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/carriers",
    };
    yield put(headerDataSuccess(response));
  }
  //Package Master
  if (layout.pathname === "/packagemaster") {
    let response = {
      parentName: "Package Master",
      createbranchbtn: "master_31",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create__packagemaster") {
    let response = {
      parentName: "Create Package Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/packagemaster",
    };
    yield put(headerDataSuccess(response));
  }
  //Customer Master
  if (layout.pathname === "/Customer_Master") {
    let response = {
      parentName: "Customer Master",
      createbranchbtn: "master_32",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_Customer_Master") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Customer Master"
          : "Update Customer Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/Customer_Master",
    };
    yield put(headerDataSuccess(response));
  }
  //Warehouse Master
  if (layout.pathname === "/warehouse_master") {
    let response = {
      parentName: "Warehouse Master",
      createbranchbtn: "master_33",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_warehouse_master") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Warehouse Master"
          : "Update Warehouse Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/warehouse_master",
    };
    yield put(headerDataSuccess(response));
  }
  //supplier Master
  if (layout.pathname === "/supplier_master") {
    let response = {
      parentName: "Supplier Master",
      createbranchbtn: "master_35",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_supplier_master") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Supplier Master"
          : "Update Supplier Master",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/supplier_master",
    };
    yield put(headerDataSuccess(response));
  }
  //Budget Heads
  if (layout.pathname === "/budget_heads") {
    let response = {
      parentName: "Budget Heads",
      createbranchbtn: "master_36",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_budget_heads") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Budget Heads"
          : "Update Budget Heads",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/budget_heads",
    };
    yield put(headerDataSuccess(response));
  }
  // shift master
  if (layout.pathname === "/shift_master") {
    let response = {
      parentName: "Shift Master",
      createbranchbtn: "master_37",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }

  // MasterModule end
  // Accounting
  if (layout.pathname === "/accounting") {
    let response = {
      parentName: "Accounting DashBoard",
      createbranchbtn: "accounting_0",
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }
  //Bills module
  if (layout.pathname === "/bills") {
    let response = {
      parentName: "Bills",
      createbranchbtn: "accounting_1",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_bills") {
    let response = {
      parentName: "Create Bills",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/bills",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  //Payments
  if (layout.pathname === "/payments") {
    let response = {
      parentName: "Payments",
      createbranchbtn: "accounting_2",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_payments") {
    let response = {
      parentName: "Create Payments",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/payments",
    };
    yield put(headerDataSuccess(response));
  }
  //Receipts
  if (layout.pathname === "/receipts") {
    let response = {
      parentName: "Receipts",
      createbranchbtn: "accounting_3",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_receipts") {
    let response = {
      parentName: "Create Receipts",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/receipts",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/ledgerwisetrans") {
    let response = {
      parentName: "Ledger Wise Transactions",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/daybook") {
    let response = {
      parentName: "Daybook",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/accounting",
    };
    yield put(headerDataSuccess(response));
  }
  //Project Module
  if (layout.pathname === "/projects") {
    let response = {
      parentName: "Project List",
      createbranchbtn: "project_1",
      subtitle: "",
      backicon: 0,
      backlink: "",
    };
    yield put(headerDataSuccess(response));
  }
  //ProjectList module
  if (layout.pathname === "/project_list") {
    let response = {
      parentName: "Project List",
      createbranchbtn: "project_1",
      subtitle: "",
      backicon: 1,
      backlink: "/projects",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_project_list") {
    let response = {
      parentName: "Create Project List",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/project_list",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  //BOM Module
  if (layout.pathname === "/bom") {
    let response = {
      parentName: "BOM",
      createbranchbtn: "project_2",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_bom") {
    let response = {
      parentName: layout.state === undefined ? "Create BOM" : "Update Bom",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/bom",
    };
    yield put(headerDataSuccess(response));
  }
  //BOQ Module
  if (layout.pathname === "/boq") {
    let response = {
      parentName: "BOQ",
      createbranchbtn: "project_3",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_boq") {
    let response = {
      parentName: layout.state === undefined ? "Create BOQ" : "Update BOQ",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/boq",
    };
    yield put(headerDataSuccess(response));
  }
  //Cost Plan Module
  if (layout.pathname === "/cost_plan") {
    let response = {
      parentName: "Cost Plan",
      createbranchbtn: "project_4",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_cost_plan") {
    let response = {
      parentName: "Create Cost Plan",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/cost_plan",
    };
    yield put(headerDataSuccess(response));
  }
  //Jute Module Starts
  // Jute indent
  if (layout.pathname === "/jute_indent") {
    let response = {
      parentName: "Jute Indent",
      createbranchbtn: "juteIndent_1",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_jute_indent") {
    let response = {
      parentName: "Jute Indent",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/jute_indent",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_jute_purchase_order") {
    let response = {
      parentName:
      layout.state === undefined
            ? "Create Jute Purchase Order" 
            : "Update Jute Purchase Order" ,
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/jute_purchase_order",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/jute_purchase_order") {
    let response = {
      parentName: "Jute Purchase Order",
      createbranchbtn: "jutePO_2",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  // Jute Gate Entry
  if (layout.pathname === "/security_jute_gate_entry") {
    let response = {
      parentName: "Jute Gate Entry",
      createbranchbtn: "juteIndent_2",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_jute_gate_entry") {
    let response = {
      parentName: 
       layout.state === undefined
            ?"Create Jute Gate Entry"
            : "Update Jute Gate Entry" ,
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/security_jute_gate_entry",
    };
    yield put(headerDataSuccess(response));
  }
  // Jute Quality Check
  if (layout.pathname === "/jute_quality_check") {
    let response = {
      parentName: "Jute Quality Check",
      createbranchbtn: "jute_3",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/update_jute_quality_check") {
    let response = {
      parentName: "Update Jute Quality Check",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/jute_quality_check",
    };
    yield put(headerDataSuccess(response));
  }
  // Jute Material Receipt
  if (layout.pathname === "/jute_material_receipt") {
    let response = {
      parentName: "Jute Material Receipt",
      createbranchbtn: "jute_4",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/update_jute_material_receipt") {
    let response = {
      parentName: "Jute Material Receipt",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/jute_material_receipt",
    };
    yield put(headerDataSuccess(response));
  }
  // Jute Bill Pass
  if (layout.pathname === "/jute_bill_pass") {
    let response = {
      parentName: "Jute Bill Pass",
      createbranchbtn: "jute_5",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/update_jute_material_receipt") {
    let response = {
      parentName: "Update Jute Material Receipt",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/jute_material_receipt",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/update_jute_bill_pass") {
    let response = {
      parentName: "Update Jute Bill Pass",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/jute_bill_pass",
    };
    yield put(headerDataSuccess(response));
  }
  // Jute Market rate
  if (layout.pathname === "/jute_market_rate") {
    let response = {
      parentName: "Jute Market Rate",
      createbranchbtn: "jute_6",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  // Jute Issue
  if (layout.pathname === "/jute_issue") {
    let response = {
      parentName: "Jute Issue",
      createbranchbtn: "jute_7",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_jute_issue") {
    let response = {
      parentName: "Create Jute Issue",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/jute_issue",
    };
    yield put(headerDataSuccess(response));
  }
  // Jute Conversion
  if (layout.pathname === "/jute_conversion") {
    let response = {
      parentName: "Jute Conversion",
      createbranchbtn: "jute_8",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_jute_conversion") {
    let response = {
      parentName: "Create Jute Conversion",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/jute_conversion",
    };
    yield put(headerDataSuccess(response));
  }
  // Jute batch planning form
  if (layout.pathname === "/batch_planning_form") {
    let response = {
      parentName: "Jute Batch Planning Form",
      createbranchbtn: "jute_9",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_batch_planning_form") {
    let response = {
      parentName: "Jute Batch Planning Form",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/batch_planning_form",
    };
    yield put(headerDataSuccess(response));
  }

  /*******************Jute Production**********************/
  /**Finishing Entry**/
  if (layout.pathname === "/ad_finishing_entry") {
    let response = {
      parentName: "Finishing Entry",
      createbranchbtn: "jp_finishing_entry",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_finishing_entry") {
    let response = {
      parentName: "Create Finishing Entry",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/ad_finishing_entry",
    };
    yield put(headerDataSuccess(response));
  }
  /**Weaving Production**/
  if (layout.pathname === "/pr_weaving_production") {
    let response = {
      parentName: "Weaving Production",
      createbranchbtn: "jp_weaving_production",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_weaving_production") {
    let response = {
      parentName:
        layout.state == undefined
          ? "Create Weaving Production"
          : "Update Weaving Production",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/pr_weaving_production",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/ad_wastage_entry") {
    let response = {
      parentName: "Wastage Entry",
      createbranchbtn: "jp_wastage_entry",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_wasteage_entry") {
    let response = {
      parentName: "Create Wastage Entry",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/ad_wastage_entry",
    };
    yield put(headerDataSuccess(response));
  }
  /**Beaming Production**/
  if (layout.pathname === "/pr_beaming_production") {
    let response = {
      parentName: "Beaming Production",
      createbranchbtn: "jp_beaming_production",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_beaming_production") {
    let response = {
      parentName: "Create Beaming Production",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/pr_beaming_production",
    };
    yield put(headerDataSuccess(response));
  }
  /**Winding Doff Entry**/
  if (layout.pathname === "/ad_winding_doff_entry") {
    let response = {
      parentName: "Winding Doff Entry",
      createbranchbtn: "jp_winding_doff_entry",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_winding_doff_entry") {
    let response = {
      parentName: "Create Winding Doff Entry",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/ad_winding_doff_entry",
    };
    yield put(headerDataSuccess(response));
  }
  /**Cuts And Jugar Entry**/
  if (layout.pathname === "/ad_cuts_jugar_entry") {
    let response = {
      parentName: "Cuts And Jugar Entry",
      createbranchbtn: "jp_cuts_and_jugar_entry",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_cuts_and_jugar_entry") {
    let response = {
      parentName: "Create Cuts And Jugar Entry",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/ad_cuts_jugar_entry",
    };
    yield put(headerDataSuccess(response));
  }
  /**Spinning Doff Entry**/
  if (layout.pathname === "/ad_spinning_doff_entry") {
    let response = {
      parentName: "Spinning Doff Entry",
      createbranchbtn: "jp_spinning_doff_entry",
      subtitle: "",
      backicon: 0,
      backlink: "",
      moreAbtn: true,
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/create_spinning_doff_entry") {
    let response = {
      parentName: "Create Spinning Doff Entry",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 1,
      backlink: "/ad_spinning_doff_entry",
    };
    yield put(headerDataSuccess(response));
  }
  if (layout.pathname === "/gst_credentials") {
    let response = {
      parentName: "GST Credentrials",
      createbranchbtn: 0,
      subtitle: "",
      backicon: 0,
      backlink: "/dashboard",
    };
    yield put(headerDataSuccess(response));
  }
}

/**
 * Toggles the rightsidebar
 */
function* toggleRightSidebar() {
  try {
    yield call(manageBodyClass, "right-bar-enabled");
  } catch (error) {}
}

/**
 * Show the rightsidebar
 */
function* showRightSidebar() {
  try {
    yield call(manageBodyClass, "right-bar-enabled", "add");
  } catch (error) {}
}

/**
 * Hides the rightsidebar
 */
function* hideRightSidebar() {
  try {
    yield call(manageBodyClass, "right-bar-enabled", "remove");
  } catch (error) {}
}

/**
 * Watchers
 */
export function* watchChangeLayoutType() {
  yield takeEvery(CHANGE_LAYOUT, changeLayout);
}

export function* watchHeaderData() {
  yield takeEvery(HEADER_DATA, getHeaderData);
}

export function* watchChangeLayoutWidth() {
  yield takeEvery(CHANGE_LAYOUT_WIDTH, changeLayoutWidth);
}

export function* watchChangeLeftSidebarTheme() {
  yield takeEvery(CHANGE_SIDEBAR_THEME, changeLeftSidebarTheme);
}

export function* watchChangeLeftSidebarType() {
  yield takeEvery(CHANGE_SIDEBAR_TYPE, changeLeftSidebarType);
}

export function* watchChangeTopbarTheme() {
  yield takeEvery(CHANGE_TOPBAR_THEME, changeTopbarTheme);
}

export function* watchToggleRightSidebar() {
  yield takeEvery(TOGGLE_RIGHT_SIDEBAR, toggleRightSidebar);
}

export function* watchShowRightSidebar() {
  yield takeEvery(SHOW_RIGHT_SIDEBAR, showRightSidebar);
}

export function* watchHideRightSidebar() {
  yield takeEvery(HIDE_RIGHT_SIDEBAR, hideRightSidebar);
}

function* LayoutSaga() {
  yield all([
    fork(watchChangeLayoutType),
    fork(watchChangeLayoutWidth),
    fork(watchChangeLeftSidebarTheme),
    fork(watchChangeLeftSidebarType),
    fork(watchToggleRightSidebar),
    fork(watchShowRightSidebar),
    fork(watchHideRightSidebar),
    fork(watchChangeTopbarTheme),
    fork(watchHeaderData),
  ]);
}

export default LayoutSaga;
