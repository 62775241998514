import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/Jute/DynamicSelect";

import InputAdornment from "../../../components/InputAdorment/InputAdorment";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import TextArea from "../../../components/TextField/TextArea";

import moment from "moment";
import { getBranchList } from "../../../store/Global/DropDownApis/actions";

import { serverApi } from "../../../helpers/Consts";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import {
  getSupplierListMultiselect,
  getSupplierListJ,
} from "../../../store/Global/DropDownApis/actions";
import {
  getMaterialGoodReceiveDetailsById,
  juteMrHeaderAction,
} from "../../../store/Jute/JuteMaterialReceipt/actions";

const JuteMaterialReceiptFillDetails = (props) => {
  let dispatch = useDispatch();
  const [state, setState] = useState({
    userDit: JSON.parse(localStorage.getItem("authUser")),
    ...props.headerData,
    supplierListActual: [],
    supplierListAdvised: [],
    brokersList: [],
    agentsList: [],
    branchListData: [],
    isDropdownOpen: false,
  });
  const [juteMrHeaders, setJuteMrHeaderData] = useState({});

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const { juteMrHeaderData } = useSelector(
    (state) => state.JuteMaterialReceiptReducer
  );

  useEffect(() => {
    setJuteMrHeaderData(juteMrHeaderData);
  }, [juteMrHeaderData]);

  useEffect(() => {
    props.getSupplierListMultiselect(
      serverApi.GET_SUPPLIER_LIST_JPO +
        "JO/" +
        localStorage.getItem("companyId"),
      props.history
    );
    props.getSupplierListJ(
      serverApi.GET_SUPPLIER_LIST_JPO +
        "J/" +
        localStorage.getItem("companyId"),
      props.history
    );
    props.getBranchList(
      serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
      props.history
    );
  }, []);

  useEffect(() => {
    if (props.supplierListMultiselect) {
      if (props.supplierListMultiselect.data) {
        let list = props.supplierListMultiselect.data.filter(
          (item) => item.value !== "0"
        );
        setState((prevState) => ({
          ...prevState,
          supplierListActual: list,
        }));
      }
    }

    if (props.supplierListJ) {
      setState((prevState) => ({
        ...prevState,
        supplierListAdvised: props.supplierListJ.data,
      }));
    }

    if (props.BrokerBySupplierId) {
      if (props.BrokerBySupplierId.data) {
        let list = props.BrokerBySupplierId.data;
        setState((prevState) => ({
          ...prevState,
          brokersList: list,
        }));
      }
    }

    if (props.agentDropdownList) {
      if (props.agentDropdownList.data) {
        let list = props.agentDropdownList.data.filter(
          (item) => item.value !== "0"
        );
        setState((prevState) => ({
          ...prevState,
          agentsList: list,
        }));
      }
    }

    if (props.branchList) {
      if (props.branchList.data) {
        let list = props.branchList.data.filter((item) => item.value !== 0);
        setState((prevState) => ({
          ...prevState,
          branchListData: list,
        }));
      }
    }
  }, [props]);

  const handleScroll = () => {
    setState((prevState) => ({
      ...prevState,
      isDropdownOpen: true,
    }));
  };

  const onhandlechangeValue = (key) => (event) => {
    setState((prevState) => ({
      ...prevState,
      [key]: event,
    }));
    props.juteMrHeaders[key] = event;
  };

  const handlePropSelectChange = (selectedValue, selectedName, name) => {
    let updatedState = juteMrHeaders;

    switch (name) {
      case "branch":
        updatedState.branchId = selectedValue;
        props.headerData.branchId = selectedValue;
        break;
      case "advisedSupplier":
      case "actualSupplier":
        updatedState.actualSupplier = selectedValue;
        break;
      case "advisedBroker":
        updatedState.advisedBroker = selectedValue;
        break;
      case "actualBroker":
        updatedState.actualBroker = selectedValue;
        break;
      case "agentName":
        updatedState.agentName = selectedValue;
        break;
      case "unitConversion":
        updatedState.unitConversion = selectedValue;
        break;
      default:
        break;
    }
    setJuteMrHeaderData(updatedState);
    dispatch(juteMrHeaderAction(updatedState));
  };

  const handleMenuOpen = () => {
    setState((prevState) => ({
      ...prevState,
      isDropdownOpen: false,
    }));
  };
  return (
    <div className="juteIndent">
      <div className="consoleFormContainer">
        <h5>Fill Details</h5>
        <div className="consoleFormContainer">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div>
                      <div className="TextFieldBlock">
                        <label>
                          MR Date<div className="mandatoryField">*</div>
                        </label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          value={
                            juteMrHeaderData.goodReceiptDate
                              ? moment(
                                  juteMrHeaderData.goodReceiptDate
                                ).format()
                              : null
                          }
                          fullWidth
                          onChange={onhandlechangeValue("goodReceiptDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{ display: "flex", alignItems: "center" }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={state.supplierListActual}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={state.isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={juteMrHeaderData.actualSupplier}
                        name="advisedSupplier"
                        label="Advised Supplier"
                        required
                        disabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={state.supplierListActual}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={state.isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={juteMrHeaderData.actualSupplier}
                        name="actualSupplier"
                        label="Actual Supplier"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={state.brokersList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={state.isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={juteMrHeaderData.advisedBroker}
                        name="advisedBroker"
                        label="Advised Broker"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={state.brokersList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={state.isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={juteMrHeaderData.actualBroker}
                        name="actualBroker"
                        label="Actual Broker"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      label="Gate Entry Number"
                      placeholder="Enter here"
                      value={juteMrHeaderData.gateEntryNo}
                      name="gateEntryNo"
                      onChange={onhandlechangeValue("gateEntryNo")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      label="Gate Entry Date"
                      placeholder="Enter here"
                      value={juteMrHeaderData.gateEntryDate}
                      disabled={true}
                      name="gateEntryDate"
                      onChange={onhandlechangeValue("gateEntryDate")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      label="Mukam"
                      placeholder="Enter here"
                      value={juteMrHeaderData.mukamName}
                      onChange={onhandlechangeValue("mukamName")}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="PO Number"
                      placeholder="Enter here"
                      value={juteMrHeaderData.poId}
                      onChange={onhandlechangeValue("poId")}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div>
                      <div className="TextFieldBlock">
                        <label>PO Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          name="poDate"
                          inputFormat="dd-MM-yyyy"
                          readOnly={true}
                          id="poDate"
                          value={juteMrHeaderData.poDate}
                          fullWidth
                          onChange={(e) => this.handleSelect_Date(e, "poDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Challan Number"
                      placeholder="Enter here"
                      value={juteMrHeaderData.challanNo}
                      onChange={onhandlechangeValue("challanNo")}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div>
                      <div className="TextFieldBlock">
                        <label>Challan Date</label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          name="chalanDate"
                          inputFormat="dd-MM-yyyy"
                          readOnly={true}
                          id="chalanDate"
                          value={juteMrHeaderData.chalanDate}
                          fullWidth
                          onChange={(e) =>
                            this.handleSelect_Date(e, "chalanDate")
                          }
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={state.agentsList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={state.isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={juteMrHeaderData.agentName}
                        name="agentName"
                        label="Agent"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      label="Vehicle Number"
                      placeholder="Enter here"
                      value={juteMrHeaderData.vehicleNo}
                      onChange={onhandlechangeValue("vehicleNo")}
                      disabled={true}
                      required
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={UnitConversion}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={state.isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={juteMrHeaderData.unitConversion}
                        name="unitConversion"
                        label="Unit Convertion"
                        disabled={true}
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      label="Total Mill Weight"
                      placeholder="Enter here"
                      value={juteMrHeaderData.weight}
                      disabled={true}
                      onChange={onhandlechangeValue("weight")}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={state.branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={state.isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={juteMrHeaderData.branchId}
                        update={props.location.state ? 1 : 0}
                        name="branch"
                        label="Branch"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Remarks"
                      placeholder="Enter here"
                      value={juteMrHeaderData.remarks}
                      fullWidth
                      name="remarks"
                      onChange={onhandlechangeValue("remarks")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

const UnitConversion = [
  {
    value: "",
    label: "Select...",
    name: "Select...",
  },
  {
    value: "LOOSE",
    label: "LOOSE",
    name: "LOOSE",
  },
  {
    value: "BALE",
    label: "BALE",
    name: "BALE",
  },
];

const mapStateToProps = (state) => {
  const { branchList, mukamList, supplierListMultiselect, supplierListJ } =
    state.DropDownListReducer;
  const {
    vehicleTypeList,
    SupplierByMukamList,
    BrokerBySupplierId,
    PoApprovedData,
    PoDetailsData,
    agentDropdownList,
  } = state.MasterDropDownListReducer;
  const { juteMaterialViewById, mrlineItems } =
    state.JuteMaterialReceiptReducer;
  return {
    branchList,
    mukamList,
    vehicleTypeList,
    SupplierByMukamList,
    BrokerBySupplierId,
    PoApprovedData,
    PoDetailsData,
    supplierListMultiselect,
    supplierListJ,
    juteMaterialViewById,
    agentDropdownList,
    mrlineItems,
  };
};

const mapDispatchToProps = {
  getSupplierListMultiselect,
  getSupplierListJ,
  getBranchList,
  getMaterialGoodReceiveDetailsById,
  juteMrHeaderAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(JuteMaterialReceiptFillDetails));
