import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import swal from "sweetalert";

import {
  DELETEFILE,
  UPLOADED_FILES_OF_CHALLAN,
  UPLOADED_FILES_OF_INVOICE,
  UPLOADED_FILES_OF_SUPPDOCS,
  UPLOADFILE,
} from "./actionTypes";
import {
  deleteFileSuccessfull,
  UploadFileListSuccessfull,
  UploadSuppdocsFilesSuccessfull,
  UploadChallanFilesSuccessfull,
  UploadInvoiceFilesSuccessfull,
} from "./actions";
import { getFiles } from "../../Purchase/Indent/actions";
import { getListurl, postAdd } from "../../../helpers/Server_Helper";
import { serverApi } from "../../../helpers/Consts";
import { getAllFiles } from "../../HRMS/EmployeeDataBase/actions";

function* getUploadFileList({
  payload: { url, data, history, sourceMenu, ebId },
}) {
  try {
    const response = yield call(postAdd, url, data);
    if (sourceMenu) {
      if (sourceMenu === 2) {
        yield put(UploadInvoiceFilesSuccessfull({ response }));
      } else if (sourceMenu === 3) {
        yield put(UploadChallanFilesSuccessfull({ response }));
      } else if (sourceMenu === 18) {
        yield put(UploadSuppdocsFilesSuccessfull({ response }));
      } else if (sourceMenu === 49) {
        if (response) {
          let CustomerUrl =
            serverApi.GET_ALL_FILES +
            "49/" +
            ebId +
            "/" +
            localStorage.getItem("companyId");
          yield put(getAllFiles(CustomerUrl, history));
        }
      } else if (response) {
        let CustomerUrl =
          serverApi.GET_ALL_FILES +
          "6/" +
          ebId +
          "/" +
          localStorage.getItem("companyId");
        yield put(getAllFiles(CustomerUrl, history));
      }
    } else {
      yield put(UploadFileListSuccessfull({ response }));
    }
    swal("Uploaded Successfully", { icon: "success" });
  } catch (error) {
    console.log(error);
  }
}
function* getUploadInvoiceFileList({
  payload: { url, data, history, sourceMenu, reqObj },
}) {
  console.log(url, data, history, sourceMenu, reqObj)
  try {
    const response = yield call(postAdd, url, data);
    yield put(UploadInvoiceFilesSuccessfull({ response }));
    swal("Uploaded Successfully", { icon: "success" });
    yield put(
      getFiles(
        serverApi.GET_FILES + "2/" + reqObj.id + "/" + reqObj.companyId,
        history,
        "2"
      )
    );
  } catch (error) {
    console.log(error);
  }
}
function* getUploadchallanFileList({
  payload: { url, data, history, sourceMenu },
}) {
  try {
    const response = yield call(postAdd, url, data);

    yield put(UploadChallanFilesSuccessfull({ response }));
    swal("Uploaded Successfully", { icon: "success" });
  } catch (error) {
    console.log(error);
  }
}
function* getUploadsoppdocsFileList({
  payload: { url, data, history, sourceMenu },
}) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(UploadSuppdocsFilesSuccessfull({ response }));
    swal("Uploaded Successfully", { icon: "success" });
  } catch (error) {
    console.log(error);
  }
}

function* deleteFile({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(deleteFileSuccessfull({ response }));
    if (response.data.message == "Success.") {
      swal("Successfully Deleted", { icon: "success" });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchUploadFileList() {
  yield takeEvery(UPLOADFILE, getUploadFileList);
  yield takeEvery(DELETEFILE, deleteFile);
  yield takeEvery(UPLOADED_FILES_OF_INVOICE, getUploadInvoiceFileList);
  yield takeEvery(UPLOADED_FILES_OF_CHALLAN, getUploadchallanFileList);
  yield takeEvery(UPLOADED_FILES_OF_SUPPDOCS, getUploadsoppdocsFileList);
}

function* UploadFileListSaga() {
  yield all([fork(watchUploadFileList)]);
}

export default UploadFileListSaga;
