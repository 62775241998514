import {
  SUB_DEPARTMENT_LIST,
  CREATE_SUB_DEPARTMENT,
  UPDATE_SUB_DEPARTMENT,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getList, postAdd } from "../../../helpers/Server_Helper";
import {
  subdepartmentMasterListSuccessfull,
  createSubDepartmentSuccessfull,
  updateSubDepartmentSuccessfull,
} from "./actions";
import swal from "sweetalert";

function* SubDepartmentMasterList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    response.data.sort((a, b) => (a.deptId < b.deptId ? 1 : -1));
    yield put(subdepartmentMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateSubDepartment({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createSubDepartmentSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/sub_department_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
      }
    }
  }
}
function* UpdateSubDepartment({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateSubDepartmentSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/sub_department_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

export function* watchSubDepartmentMasterList() {
  yield takeEvery(SUB_DEPARTMENT_LIST, SubDepartmentMasterList);
}

export function* watchCreateSubDepartmentMaster() {
  yield takeEvery(CREATE_SUB_DEPARTMENT, CreateSubDepartment);
}
export function* watchUpdateSubDepartmentMaster() {
  yield takeEvery(UPDATE_SUB_DEPARTMENT, UpdateSubDepartment);
}

function* SubDepartmentSaga() {
  yield all([
    fork(watchSubDepartmentMasterList),
    fork(watchCreateSubDepartmentMaster),
    fork(watchUpdateSubDepartmentMaster),
  ]);
}
export default SubDepartmentSaga;
