import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import DynamicSelect from "../../Dropdown/DynamicSelect";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { serverApi } from "../../../helpers/Consts";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { getStatusList } from "../../../store/Global/DropDownApis/actions";
import { getCustomerList } from "../../../store/Global/DropDownApis/actions";
import {getSalesOrderBranch} from '../../../store/Sales/SalesOrder/actions'
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";

function SalesOrderFilter(props) {
  const [open, setOpen] = useState(false);
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [companyId] = useState(localStorage.getItem("companyId"));
  const [customerListData, setCustomerListData] = useState([]);
  const [Status, setStatus] = useState([]);
  const [from_date, setFromDate] = useState( moment().day(-7).format("YYYY-MM-DD"));
  const [to_date, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [status_id, setStatusId] = useState("");
  const [quotation_no, setQuotationNo] = useState("");
  const [customer_name, setCustomerName] = useState("");
  const [BranchData,setBranchdata] =useState([])
  const [branchId,setbranchId]=useState("")
  const [isDropdownOpen,setisDropdownOpen]=useState(false)
  const [selectedInvoice, setSelectedInvoiceType] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "Status") {
      setStatusId(selectedValue);
    }
    if (name === "CustomerName") {
      setCustomerName(selectedValue);
    }
    if(name==="branchId"){
        setbranchId(selectedValue)
    }
    if (name === "invoiceType") {
      setSelectedInvoiceType(selectedValue);
    }
  };

  const onhandlechange = (key) => (event) => {
    if (key === "quotation_no") {
      setQuotationNo(event);
    }
  };

  const handleSelectDate = (e, name) => {
    if (name === "FromDate") {
      const date = moment(e).format();
      const Date = date.split("T", 1).toString();
      setFromDate(Date);
    }
    if (name === "ToDate") {
      const date = moment(e).format();
      const Date = date.split("T", 1).toString();
      setToDate(Date);
    }
  };

  const handleSubmit = () => {
    const data = {
      saleNo: quotation_no,
      status: status_id,
      cusId: customer_name,
      branchId: branchId,
      cipher: userDit.cipher,
      companyId: companyId,
      erpType: "1",
      financialYear: localStorage.getItem("acadamicYear"),
      fromDate: from_date,
      projectId: "",
      recordType: "SR",
      supplierId: null,
      toDate: to_date,
      type: selectedInvoice,
    };
    props.handle_search(data);
    setOpen(false);
  };

  useEffect(() => {
    const financialYearStartMonth = 3; // Assuming financial year starts in April
    let financialYearStart = moment()
      .year(localStorage.getItem("acadamicYear"))
      .month(financialYearStartMonth)
      .startOf("month");

    const fromDate = financialYearStart.day(-7).format("YYYY-MM-DD");
    const toDate = moment().format("YYYY-MM-DD");

    setFromDate(fromDate)
    setToDate(toDate)
    props.getStatusList(serverApi.STATUS_LISTS, props.history);
    const data = {
      companyId: companyId,
      cipher: userDit.cipher,
    };
    props.getCustomerList(serverApi.GET_ALL_CUSTOMERS, data, props.history);
    props.getSalesOrderBranch(serverApi.BRANCH_LIST + localStorage.getItem("companyId"),props.history);
  }, []);
  const {statusList,customerList} =  useSelector((state) => state.DropDownListReducer)
  const {BranchPropdata} =  useSelector((state) => state.SalesOrderReducer)


  useEffect(() => {
    if (statusList) {
      setStatus(statusList.data);
    }
  }, [statusList]);

  useEffect(() => {
    if(BranchPropdata){
      console.log(BranchPropdata)
    if(BranchPropdata.data){
        setBranchdata(BranchPropdata.data)
    }}
  }, [BranchPropdata]);

  useEffect(() => {
    if (customerList) {
      let customerData = [];
      if(customerList?.data?.length > 0) {
        customerData.push({
          value: "",
          name: "Select",
          label: "Select"
        })
  
        customerList?.data?.map((item) => {
          customerData.push({
            value: item.id,
            name: item.name,
            label: item.name,
          });
        });
  
        setCustomerListData(customerData);
      }
     
    }
  }, [customerList])

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Box onScroll={handleScroll}>
        <Button onClick={handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={open}
          PaperProps={{
            sx: {
              position: "fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid spacing={0}>
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
              </div>
            </div>
      
              <Grid item xs={12} className="filterBlockContainer">
                <Box gridColumn="span 4">
                  <div className="consoleTextFieldBlock">
                  <div>
                    <div className="TextFieldBlock">
                      <label>From Date</label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="dd-MM-yyyy"
                        name="FromDate"
                        value={from_date}
                        fullWidth
                        onChange={(e) => handleSelectDate(e, "FromDate")}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input ref={inputRef} {...inputProps} readOnly={true} />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                 
              </div>
                  <div className="consoleTextFieldBlock">
                  <div>
                    <div className="TextFieldBlock">
                      <label>To Date</label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="dd-MM-yyyy"
                        name="ToDate"
                        value={to_date}
                        fullWidth
                        onChange={(e) => handleSelectDate(e, "ToDate")}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input ref={inputRef} {...inputProps} readOnly={true} />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                    </div>
                  </div>

                  </div>

                </Box>
                <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={inVoiceType}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={handleSelectChange}
                    selected={selectedInvoice}
                    name="invoiceType"
                    label="Invoice Type"
                    isDropdownOpen={isDropdownOpen}
                    handleMenuOpen={handleMenuOpen}
                  />
                </div>
              </Box>
              
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={
                        Status
                          ? Status.map((item) => ({
                              label: item.label,
                              value: item.value,
                              name: item.name,
                            }))
                          : ""
                      }
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handleSelectChange}
                      selected={status_id}
                      name="Status"
                      label="Status"
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={
                        customerListData
                      }
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handleSelectChange}
                      selected={customer_name}
                      name="CustomerName"
                      label="Customer Name"
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="filterDateBlock">
                    <div>
                      <TextFieldNormal
                        label="Sale Number"
                        value={quotation_no}
                        onChange={onhandlechange("quotation_no")}
                      />
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={BranchData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handleSelectChange}
                      selected={branchId}
                      name="branchId"
                      label="Branch"
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                    />
                  </div>
                </Box>
           
                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Search"}
                    className="greenButton"
                    handleClick={handleSubmit}
                    type="sumbit"
                  />
                </div>
              </Grid>
            
          </Grid>
        </Dialog>
      </Box>
    </>
  );
}

const mapStatetoProps = (state) => {
  const { statusList, customerList } = state.DropDownListReducer;
  const { BranchPropdata } = state.SalesOrderReducer;
  return { statusList, customerList ,BranchPropdata};
};
const inVoiceType = [
  {
    value: "",
    name: "Select",
    label: "Select",
  },
  {
    value: 1,
    name: "Regular Invoice",
    label: "Regular Invoice",
  },
  {
    value: 2,
    name: "Government Invoice",
    label: "Government Invoice",
  },
  {
    value: 3,
    name: "Yarn Invoice",
    label: "Yarn Invoice",
  },
  {
    value: 4,
    name: "Hessian Invoice",
    label: "Hessian Invoice",
  },
];

export default withRouter(
  connect(mapStatetoProps, {
    getStatusList,
    getCustomerList,
    getSalesOrderBranch
  })(SalesOrderFilter)
);
