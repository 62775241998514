import {
  BRANCH_MASTER_LIST, BRANCH_MASTER_LIST_SUCCESSFULL, CREATE_BRANCH_MASTER_SUCCESSFULL, CREATE_BRANCH_MASTER,
  UPDATE_BRANCH_MASTER,
  UPDATE_BRANCH_MASTER_SUCCESSFULL,
  API_ERRORS,
  DELETE_BRANCH_FILES,
  DELETE_BRANCH_FILES_SUCCESSFULL,
  GET_ALL_FILES,
  GET_ALL_FILES_SUCCESSFULL,
  UPLOAD_BRANCH_FILES,
  UPLOAD_BRANCH_FILES_SUCCESSFULL,
} from './actionTypes';

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  BranchMasterList: [],
  CreateBranchMaster: [],
  UpdateBranchMaster: [],
  GetAllFiles_Data:[],
  delete_branchMaster_Files:[],
  Upload_file:[],
  id:null,
};

const BranchMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case BRANCH_MASTER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case BRANCH_MASTER_LIST_SUCCESSFULL:
      state = {
        ...state,
        BranchMasterList: action.payload.response,
        loading: true,
      };
      break;
    case CREATE_BRANCH_MASTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_BRANCH_MASTER_SUCCESSFULL:
      state = {
        ...state,
        CreateBranchMaster: action.payload.response,
        loading: true,
      };
      break;
    case UPDATE_BRANCH_MASTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_BRANCH_MASTER_SUCCESSFULL:
      state = {
        ...state,
        UpdateBranchMaster: action.payload.response,
        loading: true,
      };
      break;
    case API_ERRORS:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;
    case DELETE_BRANCH_FILES:
      state={
        ...state,
        loading:true,
      };
      break;
    case DELETE_BRANCH_FILES_SUCCESSFULL:
      state={
        ...state,
        delete_branchMaster_Files: action.payload.response,
        loading:false,
      };
      break;
    case GET_ALL_FILES:
      state={
        ...state,
        loading:true,
      };
      break;
    case GET_ALL_FILES_SUCCESSFULL:
      state={
        ...state,
        GetAllFiles_Data: action.payload.response.data,
        loading:false
      };
      break; 
    case UPLOAD_BRANCH_FILES:
      state={
        ...state,
        loading:true,
      };
      break;
    case UPLOAD_BRANCH_FILES_SUCCESSFULL:
      state={
        ...state,
        Upload_file: action.payload.response.response,
        loading:false,
      };
      break;  
    default:
      state = { ...state };
      break;
  }
  return state;
}

export default BranchMasterReducer;