import {CREATE_SHIFT_MASTER,CREATE_SHIFT_MASTER_SUCCESSFULL,BIO_METRIc_DEVICE,BIO_METRIc_DEVICE_SUCCESSFULL, 
    CREATE_BIO_METRIC_DEVICE, CREATE_BIO_METRIC_DEVICE_SUCCESSFULL, GET_WORKERDETAILS_BYID, GET_WORKERDETAILS_BYID_SUCCESSFULL, DELETE_BIO_METRIC, DELETE_BIO_METRIC_SUCCESSFULL, VIEW_BY_ID_SHIFT_MASTER, VIEW_BY_ID_SHIFT_MASTER_SUCCESSFULL} from './actionType'

export const setShiftMaster = (url,data,history,ebId) =>{
    return {
        type:CREATE_SHIFT_MASTER,
        payload:{url,data,history,ebId}
    }
}

export const ShiftMasterSuccessfull = (data)=>{
    return {
        type:CREATE_SHIFT_MASTER_SUCCESSFULL,
        payload:data
    }
}
// bio metric device
export const getBioMetriDevice = (url,history) =>{
    return {
        type:BIO_METRIc_DEVICE,
        payload:{url,history}
    }
}

export const BioMetricSuccessfull = (data)=>{
    return {
        type:BIO_METRIc_DEVICE_SUCCESSFULL,
        payload:data
    }
}

export const createBioMetriDevice = (url,data,history,ebId) =>{
    return {
        type:CREATE_BIO_METRIC_DEVICE,
        payload:{url,data,history,ebId}
    }
}

export const createBioMetriDeviceSuccessfull = (data)=>{
    return {
        type:CREATE_BIO_METRIC_DEVICE_SUCCESSFULL,
        payload:data
    }
}
export const getWorkerDetailsById = (url,history) =>{
    return {
        type:GET_WORKERDETAILS_BYID,
        payload:{url,history}
    }
}

export const getWorkerDetailsByIdSuccessfull = (data)=>{
    return {
        type:GET_WORKERDETAILS_BYID_SUCCESSFULL,
        payload:data
    }
}
export const  deleteBioMetriDevice= (url,history,ebId) =>{
    return {
        type:DELETE_BIO_METRIC,
        payload:{url,history,ebId}
    }
}
export const deleteBioMetriDeviceSuccessfull = (data)=>{
    return {
        type:DELETE_BIO_METRIC_SUCCESSFULL,
        payload:data
    }
}
export const  ViewByIdShiftMaster= (url,history) =>{
    return {
        type:VIEW_BY_ID_SHIFT_MASTER,
        payload:{url,history}
    }
}
export const ViewByIdShiftMasterSuccessfull = (data)=>{
    return {
        type:VIEW_BY_ID_SHIFT_MASTER_SUCCESSFULL,
        payload:data
    }
}