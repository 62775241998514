import {PR_RESPONSE_LIST,PR_RESPONSE_LIST_SUCCESSFULL} from './actionTypes';



export const getPrResponseList = (url,data,history) =>{
    return {
        type:PR_RESPONSE_LIST,
        payload:{url,data,history}
    }
}

export const PrResponseListSuccessfull = (data)=>{
    return {
        type:PR_RESPONSE_LIST_SUCCESSFULL,
        payload:data
    }
}

