//import { Switch } from "react-router-dom";

import {
    API_ERRORS,
    CONSUMPTION_LIST,
    CONSUMPTION_LIST_SUCCESSFULL,
    ADD_CONSUMPTION,
    ADD_CONSUMPTION_SUCCESSFULL
  } from "./actionTypes";
  
  const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    Consumption_List: [],
    error_msg: null,
    addConsumption: []
  };
  
  const ConsumptionReducer = (state = initialState, action) => {
    switch (action.type) {
      case CONSUMPTION_LIST:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case CONSUMPTION_LIST_SUCCESSFULL:
        state = {
          ...state,
          Consumption_List: action.payload.response,
          loading: false,
        };
        break;
        case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
          case ADD_CONSUMPTION:
            state = {
              ...state,
              loading: true
            }
            break;
          case ADD_CONSUMPTION_SUCCESSFULL:
            state = {
              ...state,
              addConsumption: action.payload.response,
              loading: true
      
            }
            break;   
      default:
        state = { ...state };
        break;
    }
  
    return state;
  };
  
  export default ConsumptionReducer;
  