import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Box } from "@mui/material";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import CustomButton from "../../../components/Buttons/Buttons";
import { serverApi } from "../../../helpers/Consts";
import {
  postAddItemToSpellMasterList,
  postUpdateItemToSpellMasterList,
  getParentSpell,
} from "../../../store/Master/SpellMaster/actions";
import Datetime from "react-datetime";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import DynamicSelect from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import swal from "sweetalert";
import { validate } from "../../../Utilities/Validations";
class CreateSpellMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      ParentSpellList: [],
      rowCount: 1,
      spellId: null,
      spellName: "",
      spellCode: "",
      status: null,
      workingHours: null,
      newWorkingHours: null,
      minimumWorkHours: null,
      halfdayWorkHours: null,
      startingTime: "",
      endTime: "",
      userCode: null,
      endDate: null,
      spellType: 1,
      parentSpell: "",
      autoDateTime: null,
      createdBy: null,
      companyId: null,
      cutsEligibility: 1,
      breakHours: null,
      defaultBreakHours: new Date(),
      key: Date.now(),
      lateMinutes: null,
      lateMinutes2: null,
      weekOffDay: null,
      weekOffDay2: null,
      weekOffHalfday: null,
      isOvernight: null,
      updatedBy: null,
      updateOn: null,
      CheckBoxValue: false,
      locationState:"",
      spellLineItems: [
        {
          id: 0,
          spellLineId: "",
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          createdOn: moment().format(),
          roasterName: "",
          roasterDays: null,
          roasterGapDays: null,
          roasterStartDate: null,
          roasterEndDate: null,
          roasterLastInsertDate: null,
          isActive: 1,
        },
      ],
    };
  }
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "parentSpell") {
      this.setState({
        parentSpell: selectedValue,
      });
    }
    if (name === "weekOffDay") {
      this.setState({
        weekOffDay: selectedValue,
      });
    }
    if (name === "weekOffDay2") {
      this.setState({
        weekOffDay2: selectedValue,
      });
    }
    if (name === "weekOffHalfday") {
      this.setState({
        weekOffHalfday: selectedValue,
      });
    }
    if (name === "isOvernight") {
      this.setState({
        isOvernight: selectedValue,
      });
    }
  };
  // function for popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  // function for previous page
  onClickCancel = () => {
    this.props.history.push("/spell_master");
  };
  // function for textfiled
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });

    if (key === "spellCode") {
      const spellcod = event.replace(/[^a-zA-Z0-9\s]+/g, "");
      this.setState({
        spellCode: spellcod,
      });
    }

    if (key === "spellName") {
      this.setState({
        spellName: event,
      });
    }
    if (key === "workingHours") {
      if (event <= 0 && event !== "") {
        swal("Working hours should be greater than 0");
        this.setState({
          workingHours: "",
        });
      } else {
        this.setState({
          workingHours: event,
          newWorkingHours: event,
        });
        if (
          this.state.TimeObj != "" &&
          this.state.TimeObj !== undefined &&
          this.props.location.state === undefined
        ) {
          var StartTime =
            this.state.TimeObj.moment().format("YYYY-MM-DD HH:mm");

          var time = moment(StartTime)
            .add(Number(event), "hours")
            .format("HH:mm");
          this.setState({
            endTime: time,
          });
        }
      }
      if (this.props.location.state !== undefined) {
        if (this.props.location.state.rowData !== undefined) {
          this.setState({
            autoDateTime: this.props.location.state.rowData.autoDateTime,
          });
          var date = this.props.location.state.rowData.autoDateTime.split("T");
          var NewValue = date[0] + " " + this.state.startingTime;
          var times = moment(NewValue)
            .add(Number(event), "hours")
            .format("HH:mm");

          this.setState({
            endTime: times,
          });
        }
      }
    }
    if (key === "minimumWorkHours") {
      if (Number(event) > Number(this.state.workingHours)) {
        swal("Minimum Work Hours Shouldn't be greater than WorkingHours");
      } else {
        if (Number(event) <= 0 && event !== "") {
          swal("Minimum Work Hours Shoul be greater than 0");
          this.setState({
            minimumWorkHours: "",
          });
        } else {
          this.setState({
            minimumWorkHours: event,
          });
        }
      }
    }
    if (key === "lateMinutes") {
      if (Number(event) <= 0 && event !== "") {
        swal("lateMinutes Should be greater than 0");
        this.setState({
          lateMinutes: "",
        });
      } else {
        this.setState({
          lateMinutes: event,
        });
      }
    }
    if (key === "lateMinutes2") {
      if (Number(event) <= 0 && event !== "") {
        swal("lateMinutes2 Should be greater than 0");
        this.setState({
          lateMinutes2: "",
        });
      } else {
        this.setState({
          lateMinutes2: event,
        });
      }
    }
    if (key === "halfdayWorkHours") {
      if (Number(event) > Number(this.state.workingHours)) {
        swal("Halfday WorkHours shouldn't be greater than Working hours ");
        this.setState({
          halfdayWorkHours: "",
        });
      } else {
        if (Number(event) <= 0 && event !== "") {
          swal("Halfday WorkHours should be Less than 0 ");
          this.setState({
            halfdayWorkHours: "",
          });
        } else {
          this.setState({
            halfdayWorkHours: event,
          });
        }
      }
    }
  };
  handleCreate = () => {
    var filterProduct = this.state.spellLineItems.filter((prop) => {
      if (prop.roasterName !== "") {
        return prop;
      }
    });

    const validateFields = [
      { field: this.state.spellCode, msg: "Spell Code", type: 1 },
      { field: this.state.spellName, msg: "Spell Name", type: 1 },
      { field: this.state.workingHours, msg: "Working Hours", type: 1 },
      { field: this.state.isOvernight, msg: "Night Shift", type: 1 },
    ];

    const validObj = validate(validateFields);

    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message} ..!!`);
      } else {
        swal(`Please Select ${validObj.message} ..!!`);
      }
    } else {
      var isOvernight = 0;
      if (this.state.isOvernight === 1) {
        isOvernight = 1;
      }
      const data = {
        spellName: this.state.spellName,
        spellCode: this.state.spellCode,
        status: 1,
        workingHours: this.state.workingHours,
        minimumWorkHours: this.state.minimumWorkHours,
        halfdayWorkHours: this.state.halfdayWorkHours,
        startingTime: this.state.startingTime,
        endTime: this.state.endTime,
        userCode: this.state.userDit.userId,
        parentSpell: this.state.parentSpell,
        autoDateTime: moment().format(),
        createdBy: this.state.userDit.userId,
        companyId: localStorage.getItem("companyId"),
        cutsEligibility: 1,
        breakHours: this.state.breakHours,
        lateMinutes: this.state.lateMinutes,
        lateMinutes2: this.state.lateMinutes2,
        weekOffDay: this.state.weekOffDay,
        weekOffDay2: this.state.weekOffDay2,
        weekOffHalfday: this.state.weekOffHalfday,
        isOvernight: isOvernight,
        spellLineItems: filterProduct,
      };

      this.props.postAddItemToSpellMasterList(
        serverApi.CREATE_SPELL_MASTER,
        data,
        this.props.history
      );
    }
  };
  handleUpdate = () => {
    var filterProduct = this.state.spellLineItems.filter((prop) => {
      if (prop.roasterName !== "") {
        return prop;
      }
    });
    const validateFields = [
      { field: this.state.spellCode, msg: "Spell Code", type: 1 },
      { field: this.state.spellName, msg: "Spell Name", type: 1 },
      { field: this.state.workingHours, msg: "Working Hours", type: 1 },
      { field: this.state.isOvernight, msg: "Night Shift", type: 1 },
    ];
    const validObj = validate(validateFields);

    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message} ..!!`);
      } else {
        swal(`Please Select ${validObj.message} ..!!`);
      }
    } else {
      var isOvernight = 0;
      if (this.state.isOvernight == 1) {
        isOvernight = 1;
      }

      const data = {
        spellId: this.state.spellId,
        spellName: this.state.spellName,
        spellCode: this.state.spellCode,
        status: 1,
        workingHours: this.state.workingHours,
        minimumWorkHours: this.state.minimumWorkHours,
        halfdayWorkHours: this.state.halfdayWorkHours,
        startingTime: this.state.startingTime,
        endTime: this.state.endTime,
        userCode: this.state.userDit.userId,
        spellType: 1,
        parentSpell: this.state.parentSpell,
        autoDateTime: this.state.autoDateTime,
        createdBy: this.state.createdBy,
        companyId: this.state.companyId,
        cutsEligibility: 1,
        breakHours: this.state.breakHours,
        lateMinutes: this.state.lateMinutes,
        lateMinutes2: this.state.lateMinutes2,
        weekOffDay: this.state.weekOffDay,
        weekOffDay2: this.state.weekOffDay2,
        weekOffHalfday: this.state.weekOffHalfday,
        isOvernight: isOvernight,
        spellLineItems: filterProduct,
      };

      this.props.postUpdateItemToSpellMasterList(
        serverApi.UPDATE_SPELL_MASTER,
        data,
        this.props.history
      );
    }
  };
  time = (dateObj, id) => {
    if (typeof dateObj === "object") this.setState({});

    if (id == "startingTime") {
      this.setState({
        startingTime: dateObj.format("HH:mm"),
      });

      var StartTime = moment(dateObj)
        .add(0, "hours")
        .format("YYYY-MM-DD HH:mm");
      var time = moment(StartTime)
        .add(Number(this.state.workingHours), "hours")
        .format("HH:mm");
      this.setState({
        endTime: time,
        TimeObj: moment(StartTime),
      });
    }

    if (id === "breakHours") {
      var data = dateObj.format("HH:mm").split(":");
      if (Number(data[0]) > this.state.workingHours) {
        var hours = new Date(new Date().setHours(0, 0, 0, 0));

        swal("Break Hours shouldn't be greater than Working Hours").then(() => {
          this.setState({
            breakHours: "00:00",
          });
          this.setState({ rtdcounter: 0 }, () => {
            this.time(moment().startOf("day"), "time");
          });
          const elements = document.getElementsByClassName("rdtCount");
          for (let i = 0; i < elements.length; i++) {
            elements[i].textContent = 0;
          }
          this.setState((prevState) => ({
            breakHours: "00:00",
            key: prevState.key + 1, // Update key to re-render component
          }));
        });
      } else {
        this.setState({
          breakHours: data[0] + ":" + data[1],
          workingHours: this.state.newWorkingHours - Number(data[0]),
        });
        this.setState({ rtdcounter: 0 }, () => {
          this.time(moment().startOf("day"), "time");
        });
      }
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    this.setState({
      locationState:this.props.location
    })
    window.addEventListener("scroll", this.handleScroll);
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData !== undefined) {
        this.setState({
          spellId: this.props.location.state.rowData.spellId,
          parentSpell: this.props.location.state.rowData.parentSpell,
          spellCode: this.props.location.state.rowData.spellCode,
          spellName: this.props.location.state.rowData.spellName,
          workingHours: this.props.location.state.rowData.workingHours,
          newWorkingHours: this.props.location.state.rowData.workingHours,
          startingTime: this.props.location.state.rowData.startingTime,
          endTime: this.props.location.state.rowData.endTime,
          breakHours: this.props.location.state.rowData.breakHours,
          lateMinutes: this.props.location.state.rowData.lateMinutes,
          lateMinutes2: this.props.location.state.rowData.lateMinutes2,
          minimumWorkHours: this.props.location.state.rowData.minimumWorkHours,
          halfdayWorkHours: this.props.location.state.rowData.halfdayWorkHours,
          weekOffDay: this.props.location.state.rowData.weekOffDay,
          weekOffDay2: this.props.location.state.rowData.weekOffDay2,
          weekOffHalfday: this.props.location.state.rowData.weekOffHalfday,

          createdBy: this.props.location.state.rowData.createdBy,
          companyId: this.props.location.state.rowData.companyId,
        });
        if (this.props.location.state.rowData.isOvernight == 1) {
          this.setState({
            isOvernight: 1,
          });
        } else {
          this.setState({
            isOvernight: 2,
          });
        }
        const dateEntered = this.props.location.state.rowData.autoDateTime;
        const dateIsValid = moment(dateEntered).format("LLLL");
        this.setState({
          createdOn: dateIsValid,
        });
        if (this.props.location.state.rowData.spellLineItems) {
          if (this.props.location.state.rowData.spellLineItems.length !== 0) {
            this.setState({
              CheckBoxValue: true,
            });
            let obj = {};
            let productData =
              this.props.location.state.rowData.spellLineItems.map((data) => {
                var createdOn = [];
                var roasterStartDate = [];
                var roasterEndDate = [];
                var roasterLastInsertDate = [];
                if (data.createdOn !== null) {
                  var createdOn = data.createdOn.split("T", 1);
                }
                if (data.roasterStartDate !== null) {
                  var roasterStartDate = data.roasterStartDate.split("T", 1);
                }
                if (data.roasterEndDate !== null) {
                  var roasterEndDate = data.roasterEndDate.split("T", 1);
                }
                if (data.roasterLastInsertDate !== null) {
                  var roasterLastInsertDate = data.roasterLastInsertDate.split(
                    "T",
                    1
                  );
                }

                return (obj = {
                  id: 0,
                  spellLineId: data.spellLineId,
                  createdBy: data.createdBy,
                  createdOn: createdOn.length !== null ? createdOn[0] : "",
                  roasterName: data.roasterName,
                  roasterDays: data.roasterDays,
                  roasterGapDays: data.roasterGapDays,
                  roasterStartDate:
                    roasterStartDate.length !== 0 ? roasterStartDate[0] : "",
                  roasterEndDate:
                    roasterEndDate.length !== 0 ? roasterEndDate[0] : "",
                  roasterLastInsertDate:
                    roasterLastInsertDate.length !== 0
                      ? roasterLastInsertDate[0]
                      : "",
                  isActive: 1,
                  updatedBy: this.state.userDit.userId,
                  updatedOn: moment().format(),
                });
              });
            productData.push({
              id: 0,
              spellLineId: "",
              createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
              createdOn: "",
              roasterName: "",
              roasterDays: null,
              roasterGapDays: null,
              roasterStartDate: null,
              roasterEndDate: null,
              roasterLastInsertDate: null,
              isActive: 1,
            });
            this.setState({
              spellLineItems: productData,
            });
          }
        }
      }
    }
    this.props.getParentSpell(
      serverApi.GET_PARENT_SPELL + localStorage.getItem("companyId"),
      this.props.history
    );
  }
  componentWillReceiveProps(props) {
    if (props.parent_spell_data) {
      this.setState({
        ParentSpellList: props.parent_spell_data.data,
      });
    }
  }
  handleCheck = (name, checked, disabled) => {
    this.setState({
      CheckBoxValue: !this.state.CheckBoxValue,
    });
  };
  OnhandleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    this.setState({ [key]: event });
    let mname = key.split("_");

    if (mname[0] === "roasterName") {
      row.roasterName = value;
    }
    if (mname[0] === "roasterDays") {
      row.roasterDays = value;
    }
    if (mname[0] === "roasterGapDays") {
      row.roasterGapDays = value;
    }
  };

  handleSelectDate = (e, name, row) => {
    let lastVal = row.id;
    this.setState({
      rowCount: this.state.rowCount + 1,
    });
    let lastRow =
      this.state.spellLineItems[this.state.spellLineItems.length - 1];

    if (name === "roasterStartDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      row.roasterStartDate = Date;
      this.setState({
        roasterStartDate: Date,
      });
    }
    if (name === "roasterEndDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      row.roasterEndDate = Date;
      this.setState({
        roasterEndDate: Date,
      });
    }

    if (name === "roasterLastInsertDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();

      row.roasterLastInsertDate = Date;
      this.setState({
        roasterLastInsertDate: Date,
      });
      if (row === lastRow && lastVal === 0 && e !== null) {
        this.handleAddEvent(row);
      }
    }
  };
  handleAddEvent = (data) => {
    let rowId = this.state.rowCount;
    let product = {
      id: 0,
      spellLineId: null,
      createdBy: null,
      createdOn: "",
      roasterName: "",
      roasterDays: null,
      roasterGapDays: null,
      roasterStartDate: null,
      roasterEndDate: null,
      roasterLastInsertDate: null,
      isActive: 1,
      updatedBy: null,
      updatedOn: null,
    };
    this.setState({ rowCount: rowId + 1 });
    this.state.spellLineItems.push(product);
  };
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    if (this.state.workingHours === "") {
      this.state.breakHours = "";
    }
    return (
      <>
        <div className="consoleFormContainer">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={this.state.parentSpell ? 1 : 0}
                        arrayOfData={this.state.ParentSpellList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.parentSpell}
                        name="parentSpell"
                        label="Parent Spell"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        value={this.state.spellCode}
                        label="Spell Code"
                        caps="OFF"
                        onChange={this.onhandlechangeValue("spellCode")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        value={this.state.spellName}
                        label="Spell Name"
                        caps="OFF"
                        onChange={this.onhandlechangeValue("spellName")}
                        disabled={this.state.spellCode === "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        value={this.state.workingHours}
                        type={"number"}
                        label="Working Hours"
                        caps="OFF"
                        onChange={this.onhandlechangeValue("workingHours")}
                        disabled={this.state.spellName === "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="TextFieldBlock">
                      <label>Start Time </label>
                    </div>
                    <div className="consoleTextFieldBlock">
                      <Datetime
                        defaultValue={new Date()}
                        closeOnSelect={true}
                        closeOnClickOutside={true}
                        timeFormat={"HH:mm"}
                        dateFormat={false}
                        onChange={(timeObj) => {
                          this.time(timeObj, "startingTime");
                        }}
                        height={"15px"}
                        formControlProps={{
                          height: "15px",
                          inlineBlock: "none",
                        }}
                        inputProps={{
                          id: "startingTime",
                          value: this.state.startingTime,
                          autoComplete: "off",
                          readOnly: true,
                          disabled: this.state.spellName === "" ? true : false,
                        }}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="TextFieldBlock">
                      <label>End Time</label>
                    </div>
                    <div className="consoleTextFieldBlock">
                      <Datetime
                        defaultValue={new Date()}
                        closeOnSelect={true}
                        timeFormat={"HH:mm"}
                        dateFormat={false}
                        onChange={(timeObj) => {
                          this.setState({ endTime: timeObj.format("HH:mm") });
                        }}
                        inputProps={{
                          id: "endTime",
                          value: this.state.endTime,
                          autoComplete: "off",
                          readOnly: true,
                          disabled: this.state.spellName === "" ? true : false,
                        }}
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="TextFieldBlock">
                      <label>Break Hours </label>
                    </div>
                    <div className="consoleTextFieldBlock">
                      <Datetime
                        key={this.state.key}
                        defaultValue={this.state.breakHours}
                        closeOnSelect={true}
                        timeFormat={"HH:mm"}
                        dateFormat={false}
                        onChange={(timeObj) => {
                          this.time(timeObj, "breakHours");
                        }}
                        inputProps={{
                          id: "breakHours",
                          value: this.state.breakHours,
                          autoComplete: "off",
                          readOnly: true,
                          disabled: this.state.spellName === "" ? true : false,
                        }}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        value={this.state.lateMinutes}
                        label="Late Minutes"
                        type="number"
                        onChange={this.onhandlechangeValue("lateMinutes")}
                        disabled={this.state.spellName === "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        value={this.state.lateMinutes2}
                        label="Late Minutes2 "
                        type="number"
                        onChange={this.onhandlechangeValue("lateMinutes2")}
                        disabled={this.state.spellName === "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        value={this.state.minimumWorkHours}
                        label="Minimum Working Hours"
                        type="number"
                        onChange={this.onhandlechangeValue("minimumWorkHours")}
                        disabled={this.state.spellName === "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        value={this.state.halfdayWorkHours}
                        label="Half Day Work Hours"
                        type="number"
                        onChange={this.onhandlechangeValue("halfdayWorkHours")}
                        disabled={this.state.spellName === "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={this.state.weekOffDay ? 1 : 0}
                        arrayOfData={Days}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.weekOffDay}
                        name="weekOffDay"
                        label="Week Off Day"
                        isDisabled={this.state.spellName === "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={this.state.weekOffDay2 ? 1 : 0}
                        arrayOfData={Days}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.weekOffDay2}
                        name="weekOffDay2"
                        label="Week Off Day 2"
                        isDisabled={this.state.spellName === "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={this.state.weekOffHalfday ? 1 : 0}
                        arrayOfData={Days}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.weekOffHalfday}
                        name="weekOffHalfday"
                        label="Week Off Half Day"
                        isDisabled={this.state.spellName === "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={this.state.isOvernight ? 1 : 0}
                        arrayOfData={Shift}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.isOvernight}
                        name="isOvernight"
                        label="Night Shift"
                        required
                        isDisabled={
                          this.state.workingHours === null
                            ? true
                            : false || this.state.workingHours === ""
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>

              {this.state.locationState.state !== undefined ? (
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 5">
                    <div className="consoleUpdateTextBlock">
                      <div>
                        <span className="taxLabel">
                          Created By : <b>{this.state.createdBy}</b>
                        </span>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 6">
                    <div className="consoleUpdateTextBlock">
                      <div>
                        <span className="taxLabel">
                          Created On :<b> {this.state.createdOn}</b>
                        </span>
                      </div>
                    </div>
                  </Box>
                </Box>
              ) : (
                ""
              )}
            </Grid>
          </div>
          <Box
            gridColumn="span 3"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Checkbox
              onChange={this.handleCheck}
              checked={this.state.CheckBoxValue}
            />
            <div className="TextFieldBlock">
              <label>Is Roster Days</label>
            </div>
          </Box>
          {this.state.CheckBoxValue && (
            <div className="editableTableContainer">
              <div className="gridContainer">
                <div className="gridComponent">
                  <table cellSpacing="0px" cellPadding="0px">
                    <thead>
                      <tr className="tableRowBlock">
                        {tableHeaders.map((column) => (
                          <>
                            <th>{column.header}</th>
                          </>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.spellLineItems.map((row) => {
                        let rowIndex = this.state.spellLineItems.findIndex(
                          (idd) => idd == row
                        );
                        return (
                          <tr>
                            <>
                              {RowData.map((col) =>
                                col.component === "Inputadorment" ? (
                                  <>
                                    <td>
                                      <div className="quantityDropdownBlock">
                                        <Grid className="selectAndTextfield">
                                          <Grid
                                            xs={12}
                                            md={12}
                                            className="dynamicSelectBlock"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              name={col.type + "_" + rowIndex}
                                              value={
                                                col.type === "roasterName"
                                                  ? row.roasterName
                                                  : col.type === "roasterDays"
                                                  ? row.roasterDays
                                                  : col.type == "roasterGapDays"
                                                  ? row.roasterGapDays
                                                  : ""
                                              }
                                              onChange={this.OnhandleChange({
                                                key: col.type + "_" + rowIndex,
                                                row,
                                              })}
                                              className="inputBlockContainer"
                                            />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </td>
                                  </>
                                ) : col.component === "Date" ? (
                                  <>
                                    <td>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <DesktopDatePicker
                                          inputFormat="dd-MM-yyyy"
                                          name="DOB"
                                          value={
                                            col.type === "roasterStartDate"
                                              ? row.roasterStartDate
                                              : col.type === "roasterEndDate"
                                              ? row.roasterEndDate
                                              : col.type ===
                                                "roasterLastInsertDate"
                                              ? row.roasterLastInsertDate
                                              : ""
                                          }
                                          fullWidth
                                          onChange={(e) =>
                                            this.handleSelectDate(
                                              e,
                                              col.type,
                                              row
                                            )
                                          }
                                          renderInput={({
                                            inputRef,
                                            inputProps,
                                            InputProps,
                                          }) => (
                                            <Box
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                              className="consoleDatePicker"
                                            >
                                              <input
                                                ref={inputRef}
                                                {...inputProps}
                                                readOnly={true}
                                              />
                                              {InputProps?.endAdornment}
                                            </Box>
                                          )}
                                        />
                                      </LocalizationProvider>
                                    </td>
                                  </>
                                ) : (
                                  <></>
                                )
                              )}
                            </>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          <div className="consoleFormButtonBlock">
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
            {this.state.locationState.state === undefined ? (
              <CustomButton
                label={"Create"}
                className="greenButton"
                type="sumbit"
                handleClick={this.handleCreate}
              />
            ) : (
              ""
            )}
            {this.state.locationState.state !== undefined ? (
              <>
                <CustomButton
                  label={"Update"}
                  className="greenButton"
                  type="sumbit"
                  handleClick={this.handleUpdate}
                />
              </>
            ) : (
              ""
            )}

            {this.state.confirm && (
              <CommonPopup>
                <div className="delAddItem">
                  <div className="mandatoryFieldText">
                    Please select all the mandatory fields
                  </div>
                  <div className="delAddItemBtns">
                    <input
                      type="button"
                      onClick={this.onclickPopUP.bind(this)}
                      value="Ok"
                      className="delYesBtn"
                    />
                  </div>
                </div>
              </CommonPopup>
            )}
          </div>
        </div>
      </>
    );
  }
}

const Days = [
  {
    value: 1,
    label: "Monday",
    name: "Monday",
  },
  {
    value: 2,
    label: "Tuesday",
    name: "Tuesday",
  },
  {
    value: 3,
    label: "Wednesday",
    name: "Wednesday",
  },
  {
    value: 4,
    label: "Thursday",
    name: "Thursday",
  },
  {
    value: 5,
    label: "Friday",
    name: "Friday",
  },
  {
    value: 6,
    label: "Saturday",
    name: "Saturday",
  },
  {
    value: 7,
    label: "Sunday",
    name: "Sunday",
  },
];
const Shift = [
  {
    value: 1,
    label: "Yes",
    name: "Yes",
  },
  {
    value: 2,
    label: "No",
    name: "No",
  },
];
const tableHeaders = [
  {
    header: "Roster Name",
  },
  {
    header: "Roster Days",
  },
  {
    header: "Roster Gap Days",
  },
  {
    header: "Roster Start Days",
  },
  {
    header: "Roster End Days",
  },
  {
    header: "Roster Last Insert Days",
  },
];

const RowData = [
  {
    no: 1,
    component: "Inputadorment",
    type: "roasterName",
  },
  {
    no: 2,
    component: "Inputadorment",
    type: "roasterDays",
  },
  {
    no: 3,
    component: "Inputadorment",
    type: "roasterGapDays",
  },
  {
    no: 4,
    component: "Date",
    type: "roasterStartDate",
  },
  {
    no: 5,
    component: "Date",
    type: "roasterEndDate",
  },
  {
    no: 6,
    component: "Date",
    type: "roasterLastInsertDate",
  },
];
const mapStatetoProps = (state) => {
  const {
    AddItem_To_Spell_master_List,
    Update_Spell_master,
    parent_spell_data,
  } = state.SpellMasterListReducer;
  return {
    AddItem_To_Spell_master_List,
    Update_Spell_master,
    parent_spell_data,
  };
};
export default withRouter(
  connect(mapStatetoProps, {
    postAddItemToSpellMasterList,
    postUpdateItemToSpellMasterList,
    getParentSpell,
  })(CreateSpellMaster)
);
