import {
  JUTE_CONVERSION_LIST,
  JUTE_GET_STOCK_DETAILS_BY_GODOWN_NUMBER,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getList } from "../../../helpers/Server_Helper";
import {
    JuteConversionSuccessfull,
    juteGetStockDetailsByGodownNumberSuccessfull,
} from "./actions";


function* JuteConversionSagaList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(JuteConversionSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* GetStockDetailsSagaList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(juteGetStockDetailsByGodownNumberSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

export function* watchJuteConversionList() {
  yield takeEvery(JUTE_CONVERSION_LIST, JuteConversionSagaList);
}

export function* watchStockDetailsByGodown() {
  yield takeEvery(
    JUTE_GET_STOCK_DETAILS_BY_GODOWN_NUMBER,
    GetStockDetailsSagaList
  );
}
function* JuteConversionSaga() {
  yield all([
    fork(watchJuteConversionList),
    fork(watchStockDetailsByGodown),
  ]);
}

export default JuteConversionSaga;
