import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ExpenseBookingLineItem from "./ExpenseBookingLineItem";
class CreateExpenseAddItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowcount: 1,
      show: false,
      checkAll: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      ItemGroupMasterList: [],
      itemsList: [],
    };
  }
  render() {
    return (
      <>
        <ExpenseBookingLineItem
          tableHeaders={tableHeaders}
          RowData={RowData}
          products={this.props.products}
          header={this.props.header}
          delete={this.props.delete}
          ApproveButton={this.props.ApproveButton}
          RejectButton={this.props.RejectButton}
        />
      </>
    );
  }
}

const RowData = [
  {
    no: 1,
    component: "Date",
    type: "date",
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "expenseType",
    dropDown: "dropDown",
    name: "expenseType",
    Items: true,
  },
  {
    no: 3,
    component: "Inputadorment",
    type: "expenseNote",
  },
  {
    no: 4,
    component: "Inputadorment",
    type: "amount",
  },
  {
    no: 5,
    component: "DynamicSelect",
    type: "costCenter",
    dropDown: "dropDown",
    name: "costCenter",
    Items: true,
  },
  {
    no: 6,
    component: "UploadDocument",
    type: "UploadDocument",
    Items: true,
  },
];
const tableHeaders = [
  {
    header: "Expense Date",
  },
  {
    header: "Categories",
  },
  {
    header: "Description",
  },
  {
    header: "Amount",
  },
  {
    header: "Cost Center",
  },
  {
    header: "Support Documents",
  },
  {
    header: "Actions",
  },
];

const mapStatetoProps = (state) => {};

export default withRouter(connect(mapStatetoProps, {})(CreateExpenseAddItem));
