import {
  MACHINE_MASTER_LIST,
  MACHINE_MASTER_LIST_SUCCESSFULL,
  CREATE_MACHINE_MASTER_SUCCESSFULL,
  CREATE_MACHINE_MASTER,
  UPDATE_MACHINE_MASTER,
  UPDATE_MACHINE_MASTER_SUCCESSFULL,
  GET_MACHINE_VIEW_BY_ID,
  GET_MACHINE_VIEW_BY_ID_SUCCESSFULL,
  API_ERRORS,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  MachineMasterList: [],
  CreateMachineMaster: [],
  UpdateMachineMaster: [],
  machineViewByIdList: [],
};

const MachineMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case MACHINE_MASTER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case MACHINE_MASTER_LIST_SUCCESSFULL:
      state = {
        ...state,
        MachineMasterList: action.payload.response,
        loading: true,
      };
      break;
    case CREATE_MACHINE_MASTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_MACHINE_MASTER_SUCCESSFULL:
      state = {
        ...state,
        CreateMachineMaster: action.payload.response,
        loading: true,
      };
      break;
    case UPDATE_MACHINE_MASTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_MACHINE_MASTER_SUCCESSFULL:
      state = {
        ...state,
        UpdateMachineMaster: action.payload.response,
        loading: true,
      };
      break;
    case GET_MACHINE_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_MACHINE_VIEW_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        machineViewByIdList: action.payload.response,
        loading: true,
      };
      break;
    case API_ERRORS:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default MachineMasterReducer;
