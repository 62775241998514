import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import { getDesignationMasterList } from "../../../store/Master/DesignationMaster/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      designationListData: [], // to display designation list data
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      desig: "",
      departmentName: "",
      dataexp: "",
    };
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      desig: "",
      departmentName: "",
      startIndex: 1,
      lastIndex: 10,
    };
    this.props.getDesignationMasterList(
      serverApi.GET_DESIGNATION_API_LIST,
      data,
      this.props.history
    ); // calling designation list api
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.Designation_List) {
      this.setState({
        totalRecs: props.Designation_List.totalRecords,
        designationListData: props.Designation_List.data, // updating designation list data
      });
    }
  }

  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const data = {
        cipher: this.state.userDit.cipher,
        companyId: localStorage.getItem("companyId"),
        desig: this.state.desig,
        departmentName: this.state.departmentName,
        startIndex: sizePerPage,
        lastIndex: currentIndex,
      };
      if (currentIndex >= 0) {
        this.props.getDesignationMasterList(
          serverApi.GET_DESIGNATION_API_LIST,
          data,
          this.props.history
        );
      }
    } else {
      const data = {
        cipher: this.state.userDit.cipher,
        companyId: localStorage.getItem("companyId"),
        desig: this.state.desig,
        departmentName: this.state.departmentName,
        startIndex: currentIndex === 0 ? currentIndex + 1 : currentIndex,
        lastIndex: sizePerPage,
      };
      if (currentIndex >= 0) {
        this.props.getDesignationMasterList(
          serverApi.GET_DESIGNATION_API_LIST,
          data,
          this.props.history
        );
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };

  handleSearch = (searchData) => {
    const data = {
      companyId: searchData.companyId,
      cipher: searchData.cipher,
      startIndex: 1,
      lastIndex: 10,
      desig: searchData.desig,
      departmentName: searchData.departmentName,
    };
    this.setState({
      desig: searchData.desig,
      departmentName: searchData.departmentName,
    });
    this.props.getDesignationMasterList(
      serverApi.GET_DESIGNATION_API_LIST,
      data,
      this.props.history
    ); // calling designation list api

    // showSpinner()
  };

  render() {
    return (
      <>
        <PaginationGridView
          mdata={this.state.designationListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={false}
          actions={true}
          handleSearch={this.handleSearch}
          filter_form={"DesignationMaster"}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          url="/create_designation_master"
          apiexp={serverApi.GET_DESIGNATION_API_LIST}
          payloadexp={this.state.dataexp}
          expName={"Designation-Master.csv"}
        />
      </>
    );
  }
}
// designation list headers
const Columns = [
  {
    Header: "Designation Name",
    accessor: "desig",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Norms",
    accessor: "norms",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Department",
    accessor: "departmentName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
];

const mapStatetoProps = (state) => {
  const { Designation_List } = state.DesignationListReducer; // fetching list from designation reducer
  return { Designation_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getDesignationMasterList,
  })(Index)
);
