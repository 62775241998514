import React, { Component } from 'react';
import TextField from './TextField';


class TextFieldReq extends Component { 
    render() {
      const Humbug = {
        value: this.props.value,
        label: this.props.lable,
        placeholder: this.props.placeholder,
        type:this.props.type,
        onChange:this.props.onChange,
        handleKeyDown:this.props.handleKeyDown,
        caps:this.props.caps,
        disabled:this.props.disabled,
        minValue:this.props.minValue,
        readOnly:this.props.readOnly
      }
        return(
          <div className="TextFieldBlock">
            {this.props.label && <label htmlFor="app-input-field">{this.props.label} <label className="starcolor">*</label></label>}
              <TextField required={true} {...Humbug} rightAlign={this.props.rightAlign}></TextField>
            </div>
        )
    }
}

export default TextFieldReq;
