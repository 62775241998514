import {SPINNING_QUALITY_LIST,SPINNING_QUALITY_LIST_SUCCESSFULL,
  API_ERRORS,
  CREATE_SPINNING_QUALITY, CREATE_SPINNING_QUALITY_SUCCESSFULL, UPDATE_SPINNING_QUALITY_SUCCESSFULL, UPDATE_SPINNING_QUALITY,
  } from './actionTypes';

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    SpinningQualityList: [],
    CreateSpinningQuality:[],
    UpdateSpinningQuality:[],
  };

  const SpinningQualityReducer = (state = initialState, action) => {
    switch (action.type) {
        case SPINNING_QUALITY_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case SPINNING_QUALITY_LIST_SUCCESSFULL:
            state = {
              ...state,
              SpinningQualityList: action.payload.response,
              loading: true,
            };
            break;
            case CREATE_SPINNING_QUALITY:
              state = {
                ...state,
                loading: true,
              };
              break;
            case CREATE_SPINNING_QUALITY_SUCCESSFULL:
              state = {
                ...state,
                CreateSpinningQuality: action.payload.response,
                loading: true,
              };
              break;
              case UPDATE_SPINNING_QUALITY:
              state = {
                ...state,
                loading: true,
              };
              break;
            case UPDATE_SPINNING_QUALITY_SUCCESSFULL:
              state = {
                ...state,
                UpdateSpinningQuality: action.payload.response,
                loading: true,
              };
              break;
              case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default SpinningQualityReducer;