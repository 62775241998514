import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

class BillsPreview extends Component {
  render() {
    return (
      <>
        <div className="previewBlock">
          <table
            cellpadding="0"
            cellspacing="0"
            width="90%"
            className="previewTable"
          >
            <tbody>
              <tr>
                <td
                  colspan="5"
                  style={{ borderRight: 'none', borderBottom: 'none' }}
                >
                  this will be company logo
                </td>
                <td
                  colspan="5"
                  style={{
                    borderBottom: 'none',
                    borderLeft: 'none',
                    textAlign: 'right',
                  }}
                >
                  this will company name
                  <br /> this will be gst
                </td>
              </tr>
              <tr>
                <td
                  colspan="10"
                  style={{ textAlign: 'center', borderTop: 'none' }}
                >
                  <h2>PURCHASE ORDER</h2>
                </td>
              </tr>
              <tr>
                <td colspan="10" style={{ padding: 0 }}>
                  <table width="100%">
                    <tr>
                      <td
                        style={{
                          width: '33%',
                          borderTop: 'none',
                          borderBottom: 'none',
                          padding: 'none',
                          borderLeft: 'none',
                        }}
                      >
                        <b>Po Issued To : </b>
                        <br /> name <br />
                        address <br /> gst <br /> Contact person : <br />{' '}
                        Contact number <br /> email :
                      </td>
                      <td
                        style={{
                          width: '33%',
                          borderTop: 'none',
                          borderBottom: 'none',
                          borderLeft: 'none',
                        }}
                      >
                        <b>Po Billed To : </b>
                        <br />
                        name <br />
                        address <br /> gst <br /> Contact person : <br />{' '}
                        Contact number <br /> email :
                      </td>
                      <td
                        style={{
                          width: '33%',
                          borderTop: 'none',
                          borderBottom: 'none',
                          padding: 'none',
                          borderRight: 'none',
                        }}
                      >
                        <b>Po Shipped To : </b>
                        <br />
                        name <br />
                        address <br /> gst <br /> Contact person: <br /> Contact
                        number <br /> email :
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>PO NO : </b>
                </td>
                <td colspan="3">SCS/KOL/22-23/000001</td>
                <td colspan="2">
                  <b>PO DATE :</b>
                </td>
                <td colspan="3">date</td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Delivery By :</b>
                </td>
                <td colspan="3">date</td>
                <td colspan="2">
                  <b>Po Valid Till :</b>
                </td>
                <td colspan="3">date</td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Advance (%) :</b>
                </td>
                <td colspan="3">50%</td>
                <td colspan="2">
                  <b>Advance Amount :</b>
                </td>
                <td colspan="3">50000</td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Contact Person :</b>
                </td>
                <td colspan="3">Contact Person name</td>
                <td colspan="2">
                  <b>Contact no :</b>
                </td>
                <td colspan="3">7970956001</td>
              </tr>

              <tr>
                <td width="8%">
                  <b>Item Code/Hsn/Sac</b>
                </td>
                <td width="20%">
                  <b>Item Description</b>
                </td>
                <td width="8%">
                  <b>Quantity</b>
                </td>
                <td width="12%">
                  <b>Uom</b>
                </td>
                <td width="10%">
                  <b>Supp Rate</b>
                </td>
                <td width="10%">
                  <b>Inst Rate</b>
                </td>
                <td width="10%">
                  <b>Supp Amount</b>
                </td>
                <td width="10%">
                  <b>Inst Amount</b>
                </td>
                <td width="12%">
                  <b>Sub Total</b>
                </td>
                <td width="10%">
                  <b>Gst%</b>
                </td>
              </tr>
              <tr>
                <td>CCTV1101804</td>
                <td>
                  Supply of 2 MP IP CCTV Dome Camera <br /> Make: Hikevission{' '}
                  <br /> Remarks: DS-7732NI-Q2
                </td>
                <td>73.00</td>
                <td>Qty</td>
                <td>34500.00</td>
                <td>0</td>
                <td>251850</td>
                <td>0.00</td>
                <td>251850</td>
                <td>18</td>
              </tr>
              <tr>
                <td>CCTV1101804</td>
                <td>
                  Supply of 2 MP IP CCTV Dome Camera <br /> Make: Hikevission{' '}
                  <br /> Remarks: DS-7732NI-Q2
                </td>
                <td>73.00</td>
                <td>Qty</td>
                <td>34500.00</td>
                <td>0</td>
                <td>251850</td>
                <td>0.00</td>
                <td>251850</td>
                <td>18</td>
              </tr>
              <tr>
                <td>CCTV1101804</td>
                <td>
                  Supply of 2 MP IP CCTV Dome Camera <br /> Make: Hikevission{' '}
                  <br /> Remarks: DS-7732NI-Q2
                </td>
                <td>73.00</td>
                <td>Qty</td>
                <td>34500.00</td>
                <td>0</td>
                <td>251850</td>
                <td>0.00</td>
                <td>251850</td>
                <td>18</td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Sub Total</b>
                </td>
                <td colspan="8">
                  <b>700700.00</b>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b> Total IGST</b>
                </td>
                <td colspan="8">
                  <b>126126.00</b>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Total CGST </b>
                </td>
                <td colspan="8">
                  <b>0.00</b>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Total SGST</b>
                </td>
                <td colspan="8">
                  <b>0.00</b>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Total Value</b>
                </td>
                <td colspan="8">
                  <b>826826.00</b>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Total Value (In Words)</b>
                </td>
                <td colspan="8">
                  <b>Value in words ..............................</b>
                </td>
              </tr>
              <tr>
                <td
                  colspan="10"
                  align="right"
                  style={{ borderTop: 'none', borderBottom: 'none' }}
                >
                  Authorised Signature
                </td>
              </tr>
              <tr>
                <td
                  colspan="10"
                  style={{ borderTop: 'none', borderBottom: 'none' }}
                >
                  <b>Note :</b>
                  <br />
                  CCTV Camera
                  <br />
                  SCS/BNG09/22-23/000090
                </td>
              </tr>
              <tr>
                <td
                  colspan="5"
                  style={{ borderTop: 'none', borderRight: 'none' }}
                >
                  Printed on: 15/04/2022 13:07:03
                </td>
                <td
                  colspan="5"
                  align="right"
                  style={{ borderTop: 'none', borderLeft: 'none' }}
                >
                  Page 1 of 2<br />
                  Printed By: 2
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    )
  }
}

const mapStatetoProps = (state) => {}

export default withRouter(connect(mapStatetoProps, {})(BillsPreview))
