import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// import DynamicSelect from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import DynamicSelect from "../../../Pages/StoreModule/MaterialRequest/DynamicSelect";
import { Grid, Box, IconButton, Button } from "@mui/material";
import { allItemGroupMasterList } from "../../../store/Global/DropDownApis/actions";
import { getQuotationItemsByItemGroupId } from "../../../store/Sales/Qutation/actions";
import { serverApi, serverConfig } from "../../../helpers/Consts";
import {
  SalesOrderLineItems,
  getSalesOrderHeader,
  SalesOrderJuteHessianHeaders,
  SalesOrderJuteHessianLineItems,
  getSalesOrderHessianItemsByItemGroupId,
  getUomRelationHessian,
  getUomListBasedOnItemIdHessian,
  getJuteHessianSoItemNameData,
} from "../../../store/Sales/SalesOrder/actions";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import DeleteIcons from "../../../assets/images/picEditIcon.png";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import { getFileDownload } from "../../../helpers/server.js";
import {
  deleteSoFile,
  UploadSOFiles,
} from "../../../store/Sales/SalesOrder/actions";
import swal from "sweetalert";
import { SalesGstCalculations } from "../../../Utilities/SalesGstCalculation";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { getUomListBasedOnItemId } from "../../../store/Master/UomMaster/actions";

import {
  convertOtherUOMTOPrimaryUOM,
  limitDecimals,
  convertOtherRateUOmToPrimaryUOM,
  isEmpty,
  isEmptyWithZero,
} from "../../../Utilities/helper";

// import { getUomMasterData } from "../../";
const JuteHessianLineItems = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();

  const [LineItems, setLineItems] = useState([]);
  const [HeaderData, setHeaderData] = useState({});
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [locationState] = useState(useLocation());
  const [LineItemsDisplayTxtField, setLineItemsDisplayTxtField] = useState({});
  const [LineItemsDisplayDropField, setLineItemsDisplayDropField] = useState(
    {}
  );
  const [ItemGroupData, setItemGroupData] = useState([]);
  const [ItemNameData, setItemNameData] = useState([]);
  const [uom2, setUom2] = useState([]);
  const [userDit, setuserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );

  const { allItemGroupMaster_List } = useSelector(
    (state) => state.DropDownListReducer
  );
  const { uomMastersList } = useSelector((state) => state.UomListReducer);

  const { SoJuteHessianLineItems, SoJuteHessianHeaders, SOFiles } = useSelector(
    (state) => state.SalesOrderReducer
  );

  const { quotationItemBy_ItemGroupId } = useSelector(
    (state) => state.QuotationReducer
  );
  const [salesOrderFiles, SetSalesOrderFiles] = useState(
    SOFiles?.data?.data || []
  );
  useEffect(() => {
    SetSalesOrderFiles(SOFiles?.data?.data);
  }, [SOFiles]);
  const onDeleteFile = (fileId) => {
    let data = salesOrderFiles.filter((item) => item.fileUploadId !== fileId);
    SetSalesOrderFiles(data);
    dispatch({ type: "UPDATE_SO_FILES", payload: data });
    dispatch(deleteSoFile(serverApi.DELETEFILE + fileId));
  };

  const handleUpload = (e) => {
    e.preventDefault();
    let sourceMenu = 45;

    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", sourceMenu);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", HeaderData.regularSalesId);
    formData.append(
      "createdBy",
      JSON.parse(localStorage.getItem("authUser")).userId
    );
    formData.append("fileCompanyId", localStorage.getItem("companyId"));

    dispatch(
      UploadSOFiles(
        serverApi.UPLOADFILE,
        formData,
        history,
        sourceMenu,
        HeaderData.regularSalesId
      )
    );
  };

  useEffect(() => {
    if (allItemGroupMaster_List) {
      if (allItemGroupMaster_List.data) {
        let ListFilter = allItemGroupMaster_List.data.filter((props) => {
          if (props.value !== "0") {
            return props;
          }
        });
        let list = [];
        ListFilter.map((prop) => {
          list.push({
            label: prop.label,
            name: prop.name,
            value: prop.value,
            variant: prop.id,
          });
        });
        setItemGroupData(list);
      }
    }
    if (quotationItemBy_ItemGroupId) {
      let ListFilter = quotationItemBy_ItemGroupId.filter((data) => {
        if (data.value !== "0") {
          return props;
        }
      });
      let list = [];
      ListFilter.map((prop) => {
        list.push({
          label: prop.label,
          name: prop.name,
          value: prop.value,
          id: prop.itemId,
        });
      });
      setItemNameData(list);
    }
  }, [
    allItemGroupMaster_List,
    quotationItemBy_ItemGroupId,
    SoJuteHessianLineItems,
  ]);

  useEffect(() => {
    setLineItems(SoJuteHessianLineItems);
  }, [SoJuteHessianLineItems]);

  useEffect(() => {
    setHeaderData(SoJuteHessianHeaders);
  }, [SoJuteHessianHeaders]);

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };

  const onDel = (row) => {
    var FilterLineItems = LineItems.filter((item) => item.isActive !== 0);
    if (FilterLineItems.length == 1) {
      swal("you can't delete the last lineitem");
    } else {
      let indexItem = LineItems.findIndex((item) => item === row);
      let lineItems = [...LineItems];
      lineItems[indexItem].isActive = 0;
      lineItems[indexItem].hessianIsActive=0;
      setLineItems(lineItems);
      dispatch(SalesOrderJuteHessianLineItems(lineItems));
      let Rate = SalesGstCalculations(
        {
          lineItems: lineItems,
          taxType: HeaderData.taxType,
          brokerCommisionPercent: HeaderData.brokerCommisionPercent,
        },
        "SO_HESSIAN"
      );
      handleCalculation(Rate);
    }
  };

  const displayField = (row, colname, type) => {
    if (colname === "itemGroupId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type == 1) {
        dispatch(
          allItemGroupMasterList(
            serverApi.GET_ALL_ITEMGROUP_MASTER_LIST +
              localStorage.getItem("companyId") +
              "/" +
              userDit.cipher,
            history
          )
        );
      }
    } else if (colname === "itemId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type == 1) {
        const data = {
          companyId: localStorage.getItem("companyId"),
          itemGroupId: row.itemGroupValue,
        };
        dispatch(
          getSalesOrderHessianItemsByItemGroupId(
            serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
            data,
            history,
            row.id
          )
        );
      }
    } else if (colname === "discountType") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
    } else if (colname === "uom2") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));

      if (type === 1) {
        dispatch(
          getUomListBasedOnItemIdHessian(
            serverApi.UOM_LIST_MASTERS_BASEDON_ITEM +
              localStorage.getItem("companyId") +
              "/" +
              "ItemId" +
              "/" +
              row.itemId,
            history,
            row.id
          )
        );
      }
    } else if (colname === "rateUOM") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));

      if (type === 1) {
        dispatch(
          getUomListBasedOnItemIdHessian(
            serverApi.UOM_LIST_MASTERS_BASEDON_ITEM +
              localStorage.getItem("companyId") +
              "/" +
              "ItemId" +
              "/" +
              row.itemId,
            history,
            row.id
          )
        );
      }
    }
  };

  const OnhandleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    let mname = key.split("_");
    if (mname[0] === "remarks") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.remarks = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderJuteHessianLineItems(LineItemsRec));
    }
    if (mname[0] === "itemMake") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.itemMake = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderJuteHessianLineItems(LineItemsRec));
    }
    if (mname[0] === "hsncode") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.hsncode = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderJuteHessianLineItems(LineItemsRec));
    }
    if (mname[0] === "rate") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            rowItem.rate = value;
            let hessianRateCalculatedValue = limitDecimals(
              convertOtherRateUOmToPrimaryUOM(
                value,
                rowItem.hessianRateRelationValue
              ),
              3
            );
            rowItem.hessianRateCalculatedValue = limitDecimals(
              hessianRateCalculatedValue,
              3
            );
            // if (rowItem.discount !== "") {
            //   if (rowItem.discountType == 1 && value !== "") {
            //     rowItem.discountedRate = limitDecimals(
            //       value - rowItem.discount,
            //       3
            //     );

            //   } else {
            //     let data = (value * rowItem.discount) / 100;
            //     rowItem.discountedRate = limitDecimals(value - data, 3);
            //   }
            // } else {
            //   rowItem.discountedRate = limitDecimals(value, 3);
            // }
            if (rowItem.discount !== "") {
              if (
                rowItem.discountType === 1 &&
                hessianRateCalculatedValue !== ""
              ) {
                rowItem.discountedRate = limitDecimals(
                  hessianRateCalculatedValue - rowItem.discount,
                  3
                );
              } else {
                let data =
                  (hessianRateCalculatedValue * rowItem.discount) / 100;
                rowItem.discountedRate = limitDecimals(
                  hessianRateCalculatedValue - data,
                  3
                );
              }
            } else {
              rowItem.discountedRate = limitDecimals(
                hessianRateCalculatedValue,
                3
              );
            }
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderJuteHessianLineItems(LineItemsRec));
      let Rate = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
          brokerCommisionPercent: HeaderData.brokerCommisionPercent,
        },
        "SO_HESSIAN"
      );
      handleCalculation(Rate);
    }
    if (mname[0] === "discount") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (rowItem.discountType === 1) {
            if (Number(value) > Number(rowItem.hessianRateCalculatedValue)) {
              swal(
                `Discount value Shouldn't be greater than Rate ${rowItem.hessianRateCalculatedValue}`
              );
              rowItem.discount = 0;
              rowItem.discountedRate = limitDecimals(
                rowItem.hessianRateCalculatedValue,
                3
              );
              rowItem.discountAmount = "";
            } else {
              if (value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
                rowItem.discount = value;
                rowItem.discountedRate = limitDecimals(
                  rowItem.hessianRateCalculatedValue - value,
                  3
                );
                rowItem.hessianRateCalculatedValue =
                  convertOtherRateUOmToPrimaryUOM(
                    rowItem.rate,
                    rowItem.hessianRateRelationValue
                  );
              } else {
                swal(
                  "Please enter a positive number with up to 2 decimal places."
                );
              }
            }
          } else {
            if (Number(value) > 100) {
              swal("Discount percentage Shouldn't be greater than 100");
              rowItem.discount = 0;
              rowItem.discountedRate = limitDecimals(
                rowItem.hessianRateCalculatedValue,
                3
              );
              rowItem.discountAmount = "";
            } else {
              if (value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
                rowItem.discount = value;
                let data = (rowItem.hessianRateCalculatedValue * value) / 100;
                rowItem.discountedRate = limitDecimals(
                  rowItem.hessianRateCalculatedValue - data,
                  3
                );
                rowItem.hessianRateCalculatedValue =
                  convertOtherRateUOmToPrimaryUOM(
                    rowItem.rate,
                    rowItem.hessianRateRelationValue
                  );
              } else {
                swal(
                  "Please enter a positive number with up to 2 decimal places."
                );
              }
              // let data = (rowItem.rate * value) / 100;
              // rowItem.discountedRate = limitDecimals(rowItem.rate - data, 3);
            }
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderJuteHessianLineItems(LineItemsRec));
      let Disc = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
          brokerCommisionPercent: HeaderData.brokerCommisionPercent,
        },
        "SO_HESSIAN"
      );
      handleCalculation(Disc);
    }

    if (mname[0] === "discountedRate") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.discountedRate = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderJuteHessianLineItems(LineItemsRec));
    }
    if (mname[0] === "quantity") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            rowItem.quantity = value;
            rowItem.hessianQuantityCalculatedValue = limitDecimals(
              convertOtherUOMTOPrimaryUOM(
                value,
                rowItem.hessianQuantityRelationValue
              ),
              3
            );
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderJuteHessianLineItems(LineItemsRec));
      let Qty = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
          brokerCommisionPercent: HeaderData.brokerCommisionPercent,
        },
        "SO_HESSIAN"
      );
      handleCalculation(Qty);
    }
    if (mname[0] === "uom") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.uom = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderJuteHessianLineItems(LineItemsRec));
    }
    if (mname[0] === "taxPercent") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.taxPercent = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderJuteHessianLineItems(LineItemsRec));
      let TaxPer = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
          brokerCommisionPercent: HeaderData.brokerCommisionPercent,
        },
        "SO_HESSIAN"
      );
      handleCalculation(TaxPer);
    }
    if (mname[0] === "netAmount") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.netAmount = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderJuteHessianLineItems(LineItemsRec));
    }
  };
  const handleChange = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    selectedItemCode
  ) => {
    var lastRow = LineItems[LineItems.length - 1];
    if (name === "itemGroupId") {
      if (selectedRow === lastRow) {
        let LineItem = LineItems;
        let Obj = {
          id: SoJuteHessianLineItems.length + 1,
          regularSalesOrderLineItemId: null,
          regularSalesOrderId: null,
          hessianSalesOrderLineItemId: null,
          companyId: "",
          hsncode: "",
          itemGroupId: "",
          itemId: "",
          itemMake: "",
          quantity: "",
          uom: "",
          rate: "",
          doBalQty: "",
          invoiceBalQty: "",
          igstAmount: "",
          igstPercent: "",
          cgstAmount: "",
          cgstPercent: "",
          sgstAmount: "",
          sgstPercent: "",
          discountType: "",
          discount: "",
          discountedRate: "",
          discountAmount: "",
          netAmount: "0.00",
          totalAmount: "0.00",
          taxPercent: "",
          remarks: "",
          hessianRateIncludingBrokerage: "",
          hessianRemarks: "",
          isActive: 1,
          hessianIsActive:1,
          hessianRateExcludingBrokerage: "",
          hessianQuantityUom: "",
          hessianQuantityUomId: "",
          hessianQuantityRelationValue: "",
          hessianQuantityCalculatedValue: "",
          hessianRateUom: "",
          hessianRateUomId: "",
          hessianRateRelationValue: "",
          hessianRateCalculatedValue: "",
          hessianQuantityUom2: "",
        };
        LineItem.push(Obj);
        setLineItems(LineItem);
        dispatch(SalesOrderJuteHessianLineItems(LineItem));
      }
    }
    if (name === "itemGroupId") {
      let LineItemdata = LineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          row.itemGroupId = selectedItemCode;
          row.itemGroupName = selectedName;
          row.itemGroupValue = selectedValue;
          row.itemId = null;
          row.itemName = "";
          row.itemValue = "";
          row.itemCode = "";
          row.remarks = "";
          row.itemMake = "";
          row.hsncode = "";
          row.rate = "";
          row.discountType = "";
          row.discountName = "";
          row.discount = "";
          row.discountedRate = "";
          row.discountAmount = "";
          row.quantity = "0.000";
          row.uom = "";
          row.taxPercent = "";
          row.totalAmount = "0.00";
          row.netAmount = "0.00";
          row.hessianRateUom = "";
          row.hessianQuantityUom = "";
          row.isActive= 1;
          row.hessianIsActive=1;
          row.hessianQuantityUomId = null;
          row.hessianRateUomId = null;
        }
        return row;
      });
      setLineItems(LineItemdata);
      dispatch(SalesOrderJuteHessianLineItems(LineItemdata));
      displayField(selectedRow, "itemGroupId", 2);
      const data = {
        companyId: localStorage.getItem("companyId"),
        itemGroupId: selectedValue,
      };
      dispatch(
        getSalesOrderHessianItemsByItemGroupId(
          serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
          data,
          history,
          selectedRow.id
        )
      );
    }
    if (name === "itemId") {
      var ItemGroup = "";
      //this code is to check the item duplication.......
      let filteredLineItems = LineItems.filter((item) => item.isActive !== 0);
      if (Number(selectedRow.itemId) === Number(selectedValue)) {
        var checkItem = !!filteredLineItems.find(
          ({ itemId }) => Number(itemId) === Number(selectedValue)
        );
      }

      if(!checkItem){
        let LineItemdata = LineItems.filter((row) => {
          if (row.id === selectedRow.id) {
            ItemGroup = row.itemGroupValue;
            row.itemId = selectedValue;
            row.itemName = selectedName;
            row.itemValue = selectedValue;
            row.itemCode = row.itemGroupValue + selectedItemCode;
            row.remarks = "";
            row.itemMake = "";
          }
          return row;
        });
        const data = {
          itemGroupId: ItemGroup,
          companyId: localStorage.getItem("companyId"),
          itemId: selectedValue,
          branchId: HeaderData.branchId,
        };
        dispatch(
          getJuteHessianSoItemNameData(
            serverApi.GET_ITEMDETAILS_BYITEM_ITEMGRP_COMPANYID,
            data,
            selectedRow.id
          )
        );
        dispatch(
          getUomListBasedOnItemIdHessian(
            serverApi.UOM_LIST_MASTERS_BASEDON_ITEM +
              localStorage.getItem("companyId") +
              "/" +
              "ItemId" +
              "/" +
              selectedValue,
            history,
            selectedRow.id
          )
        );
        setLineItems(LineItemdata);
        dispatch(SalesOrderJuteHessianLineItems(LineItemdata));
        displayField(selectedRow, "itemId", 2);
      }
    }
    if (name === "discountType") {
      let LineItemdata = LineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          // row.discountType = selectedValue;
          // row.discountName = selectedName;
          // row.discount=""
          // if (selectedValue == 1) {
          //   row.discountedRate = row.rate - row.discount;
          // } else {
          //   let data = (row.rate * row.discount) / 100;
          //   row.discountedRate = row.rate - data;
          // }
          if (selectedValue === "") {
            row.discountType = "";
            row.discountName = "";
            row.discount = "";
            row.discountedRate = limitDecimals(
              row.hessianRateCalculatedValue,
              3
            );
            row.discountAmount = "0.00";
          } else {
            row.discountType = selectedValue;
            row.discountName = selectedName;
            row.discount = "";
            row.discountedRate = limitDecimals(row.hessianRateCalculatedValue, 3);
            row.discountAmount = "0.00";
            if (Number(selectedValue) === 1) {
              if (!isEmpty(row.discount)) {
                row.discountedRate = limitDecimals(
                  row.hessianRateCalculatedValue - row.discount,
                  3
                );
              }
            } else {
              if (!isEmpty(row.discount)) {
                let data =
                  (row.hessianRateCalculatedValue * row.discount) / 100;
                row.discountedRate = limitDecimals(
                  row.hessianRateCalculatedValue - data,
                  3
                );
              }
            }
          }
        }
        return row;
      });
      setLineItems(LineItemdata);
      dispatch(SalesOrderJuteHessianLineItems(LineItemdata));
      displayField(selectedRow, "discountType", 2);
    }
    let DiscType = SalesGstCalculations(
      {
        lineItems: LineItems,
        taxType: HeaderData.taxType,
        brokerCommisionPercent: HeaderData.brokerCommisionPercent,
      },
      "SO_HESSIAN"
    );
    handleCalculation(DiscType);
    if (name == "uom2") {
      let PrimaryUom = "";
      let ItemId = "";
      let LineItemdata = LineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          row.hessianQuantityUom = selectedValue;
          row.hessianQuantityUomId = selectedItemCode;
          PrimaryUom = row.uom;
          ItemId = row.itemId;
        }
        return row;
      });
      setLineItems(LineItemdata);
      dispatch(SalesOrderJuteHessianLineItems(LineItemdata));

      dispatch(
        getUomRelationHessian(
          serverApi.GET_UOM_RELATION_VALUE_BY_UOM_CODE +
            localStorage.getItem("companyId") +
            "/fromUom" +
            "/" +
            PrimaryUom +
            "/toUom" +
            "/" +
            selectedValue +
            "/ItemId/" +
            ItemId,
          ItemId,
          "quantityUOM",
          history
        )
      );
      displayField(selectedRow, "uom2", 2);
    }
    if (name == "rateUOM") {
      let PrimaryUom = "";
      let ItemId = "";
      let LineItemdata = LineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          row.hessianRateUom = selectedValue;
          row.hessianRateUomId = selectedItemCode;
          PrimaryUom = row.uom;
          ItemId = row.itemId;
        }
        return row;
      });
      setLineItems(LineItemdata);
      dispatch(SalesOrderJuteHessianLineItems(LineItemdata));

      dispatch(
        getUomRelationHessian(
          serverApi.GET_UOM_RELATION_VALUE_BY_UOM_CODE +
            localStorage.getItem("companyId") +
            "/fromUom" +
            "/" +
            PrimaryUom +
            "/toUom" +
            "/" +
            selectedValue +
            "/ItemId/" +
            ItemId,
          ItemId,
          "rateUOM",
          history
        )
      );
      displayField(selectedRow, "rateUOM", 2);
    }
  };
  const HandleBlur = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    let mname = key.split("_");
    if (mname[0] === "rate") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            rowItem.rate = value;
            if (Number(value) < Number(rowItem.discount)) {
              rowItem.rate = 0;
              rowItem.discountedRate = 0;
              swal("Rate must be greater than the discount.");
              return rowItem;
            }
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderJuteHessianLineItems(LineItemsRec));
      let Rate = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
          brokerCommisionPercent: HeaderData.brokerCommisionPercent,
        },
        "SO_HESSIAN"
      );
      handleCalculation(Rate);
    }
  };

  const handleCalculation = (Calculations) => {
    setLineItems(Calculations.lineItems);
    dispatch(SalesOrderJuteHessianLineItems(Calculations.lineItems));

    var Headerdata = {
      ...HeaderData,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
    };
    setHeaderData((prev) => ({
      ...prev,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
    }));
    dispatch(SalesOrderJuteHessianHeaders(Headerdata));
  };
  let filterProduct = LineItems.filter((item) => item.isActive != 0);
  let lastLineItem = LineItems[LineItems.length - 1];
  console.log('asdfghjklcvb',filterProduct)
  return (
    <>
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent" onScroll={handleScroll}>
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {tableHeaders.map((column) => (
                    <>
                      {column.header == "Item Group" ||
                      column.header == "Item Name" ||
                      column.header == "Rate" ||
                      column.header == "Quantity" ||
                      column.header == "UOM" ||
                      column.header == "UOM2" ||
                      column.header == "Rate UOM" ? (
                        <>
                          <th>
                            {column.header}
                            <label style={{ color: "red" }}>*</label>
                          </th>
                        </>
                      ) : (
                        <>
                          <th>{column.header}</th>
                        </>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filterProduct.length !== 0 &&
                  filterProduct.map((row, i) => {
                    let rowIndex = filterProduct.findIndex((idd) => idd == row);

                    return (
                      <>
                        <tr>
                          <>
                            {RowData &&
                              RowData.map((col) =>
                                col.component === "Editablecell" ? (
                                  <>
                                    <td>
                                      <div className="quantityDropdownBlock">
                                        <Grid className="selectAndTextfield">
                                          <Grid
                                            xs={12}
                                            md={12}
                                            className="dynamicSelectBlock"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <input
                                              type="text"
                                              name={col.type + "_" + rowIndex}
                                              disabled={
                                                col.type === "discountedRate" ||
                                                col.type === "hsncode" ||
                                                col.type ==
                                                  "hessianRateIncludingBrokerage"
                                                  ? true
                                                  : col.type === "discount"
                                                  ? row.discountType === ""
                                                    ? true
                                                    : false
                                                  : col.type === "uom"
                                                  ? true
                                                  : col.type === "taxPercent"
                                                  ? true
                                                  : col.type == "netAmount"
                                                  ? true
                                                  : col.type === "remarks"
                                                  ? row.itemId === ""
                                                    ? true
                                                    : false
                                                  : col.type === "itemMake"
                                                  ? row.itemId === ""
                                                    ? true
                                                    : false
                                                  : col.type === "rate"
                                                  ? row.itemId === ""
                                                    ? true
                                                    : false
                                                  : false
                                              }
                                              value={
                                                col.type === "remarks"
                                                  ? row.remarks
                                                  : col.type === "itemMake"
                                                  ? row.itemMake
                                                  : col.type === "hsncode"
                                                  ? row.hsncode
                                                  : col.type == "rate"
                                                  ? row.rate
                                                  : col.type === "discount"
                                                  ? row.discount
                                                  : col.type ===
                                                    "discountedRate"
                                                  ? row.discountedRate
                                                  : col.type === "quantity"
                                                  ? row.quantity
                                                  : col.type === "uom"
                                                  ? row.uom
                                                  : col.type === "taxPercent"
                                                  ? row.taxPercent
                                                  : col.type == "netAmount"
                                                  ? row.netAmount
                                                  : col.type ==
                                                    "hessianRateIncludingBrokerage"
                                                  ? row.hessianRateIncludingBrokerage
                                                  : ""
                                              }
                                              onChange={OnhandleChange({
                                                key: col.type + "_" + rowIndex,
                                                row,
                                              })}
                                              onBlur={HandleBlur({
                                                key: col.type + "_" + rowIndex,
                                                row,
                                              })}
                                              className="inputBlockContainer"
                                              style={{
                                                backgroundColor:
                                                  col.type ===
                                                    "discountedRate" ||
                                                  col.type ===
                                                    "hessianRateIncludingBrokerage" ||
                                                  col.type === "hsncode"
                                                    ? "rgb(179 214 231)"
                                                    : "",
                                                textAlign:
                                                  col.type !== "remarks" &&
                                                  col.type !== "hsncode" &&
                                                  col.type !== "uom"
                                                    ? "right"
                                                    : "",
                                                paddingRight: "8px",
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </td>
                                  </>
                                ) : col.component === "DynamicSelect" ? (
                                  <td>
                                    <div className="quantityDropdownBlock">
                                      <Grid className="selectAndTextfield">
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: LineItemsDisplayTxtField[
                                              col.type + row.id + "1"
                                            ]
                                              ? "none"
                                              : "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            className="inputBlockContainer"
                                            style={{ paddingRight: "8px" }}
                                            Value={
                                              col.type === "itemGroupId"
                                                ? row.itemGroupName
                                                : col.type === "itemId"
                                                ? row.itemName
                                                : col.type === "discountType"
                                                ? row.discountName
                                                : col.type === "uom2"
                                                ? row.hessianQuantityUom
                                                : col.type === "rateUOM"
                                                ? row.hessianRateUom
                                                : ""
                                            }
                                            disabled={
                                              col.type === "itemId"
                                                ? row.itemGroupId === ""
                                                  ? true
                                                  : false
                                                : col.type === "discountType"
                                                ? isEmpty(
                                                    row.hessianRateUomId
                                                  ) || isEmptyWithZero(row.rate)
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            onChange={OnhandleChange({
                                              key: col.type + "_" + rowIndex,
                                              row,
                                            })}
                                            onClick={() => {
                                              displayField(row, col.type, 1);
                                            }}
                                          />
                                        </Grid>
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: LineItemsDisplayDropField[
                                              col.type + row.id + "2"
                                            ]
                                              ? "flex"
                                              : "none",
                                            alignItems: "center",
                                          }}
                                        >
                                          <DynamicSelect
                                            arrayOfData={
                                              col.type == "itemGroupId"
                                                ? ItemGroupData
                                                : col.type == "itemId"
                                                ? row.ItemNameData
                                                : col.type == "discountType"
                                                ? discount_Type
                                                : col.type === "uom2"
                                                ? row.uomMastersListHessian
                                                : col.type === "rateUOM"
                                                ? row.uomMastersListHessian
                                                : ""
                                            }
                                            className="dropdownBlockContainer"
                                            onSelectChange={handleChange}
                                            selected={
                                              col.type === "itemGroupId"
                                                ? row.itemGroupValue
                                                : col.type === "itemId"
                                                ? row.itemId
                                                : col.type === "discountType"
                                                ? row.discountType
                                                : col.type === "uom2"
                                                ? row.hessianQuantityUom
                                                : col.type === "rateUOM"
                                                ? row.hessianRateUom
                                                : ""
                                            }
                                            name={
                                              col.type === "itemGroupId"
                                                ? "itemGroupId"
                                                : col.type === "itemId"
                                                ? "itemId"
                                                : col.type == "discountType"
                                                ? "discountType"
                                                : col.type == "uom2"
                                                ? "uom2"
                                                : col.type === "rateUOM"
                                                ? "rateUOM"
                                                : ""
                                            }
                                            row={row}
                                            update={
                                              row.itemGroupId ||
                                              row.itemId ||
                                              row.discountType ||
                                              row.hessianQuantityUom ||
                                              row.hessianRateUom
                                                ? 1
                                                : 0
                                            }
                                            isDropdownOpen={isDropdownOpen}
                                            handleMenuOpen={handleMenuOpen}
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </td>
                                ) : (
                                  ""
                                )
                              )}
                          </>
                          <td>
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Delete"
                            >
                              <IconButton
                                onClick={() => {
                                  onDel(row, i);
                                }}
                                disabled={
                                  filterProduct.length === 1
                                    ? true
                                    : lastLineItem === row
                                    ? true
                                    : false
                                }
                              >
                                <DeleteIcon className="deleteDisplayButton" />
                              </IconButton>
                            </Tooltip>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>

          <div className="consoleFormContainer">
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 12">
                      <div
                        className="calculationBlockTable"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <table>
                          <tr>
                            <td>Taxable Value:</td>
                            <b>{limitDecimals(HeaderData.netTotal, 2)}</b>
                          </tr>
                          <tr>
                            <td>Total IGST:</td>
                            <b> {limitDecimals(HeaderData.totalIGST, 2)}</b>
                          </tr>
                          <tr>
                            <td>Total SGST:</td>
                            <td>
                              <b>{limitDecimals(HeaderData.totalSGST, 2)}</b>
                            </td>
                          </tr>
                          <tr>
                            <td>Total CGST:</td>
                            <td>
                              <b>{limitDecimals(HeaderData.totalCGST, 2)}</b>
                            </td>
                          </tr>
                          <tr>
                            <td>Brokerage @ :</td>
                            <b>
                              {HeaderData.brokerCommisionPercent
                                ? HeaderData.brokerCommisionPercent
                                : 0}
                              %
                            </b>
                          </tr>
                          <tr>
                            <td>Total Amount With Tax:</td>
                            <b>{limitDecimals(HeaderData.TotalAmount, 2)}</b>
                          </tr>
                        </table>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
          {locationState.state !== undefined ? (
            <div className="consoleFormContainer">
              <div style={{ height: "15px" }}></div>
              <div className="consoleFormBlock" style={{ display: "flex" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                      className="ducumentUploadBlock uploadedContent"
                    >
                      <Box gridColumn="span 10">
                        {salesOrderFiles?.length > 0 &&
                          salesOrderFiles.map((item) => (
                            <div
                              key={item.fileUploadId}
                              className="uploadedInfo"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                                padding: "5px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                              }}
                            >
                              <img
                                src={TickIcon}
                                alt=""
                                className="tickIcon"
                                height={"20px"}
                                width={"20px"}
                              />
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title="Click to download File"
                              >
                                <div
                                  className="uplodedFileName"
                                  onClick={() => {
                                    getFileDownload(
                                      serverConfig.SERVER_URL +
                                        "security-api/api/files/downloadfile/" +
                                        item.fileUploadId,
                                      item.fileName,
                                      item.fileExtension
                                    );
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                  }}
                                >
                                  {item.fileName}
                                </div>
                              </Tooltip>
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 100 }}
                                title="Click to Delete File"
                              >
                                <Button
                                  onClick={() => {
                                    onDeleteFile(item.fileUploadId);
                                  }}
                                  className="deleteButton"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <img
                                    src={DeleteIcons}
                                    alt=""
                                    height={"20px"}
                                    width={"20px"}
                                  />
                                </Button>
                              </Tooltip>
                            </div>
                          ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 4">
                        <div style={{ marginBottom: "10px" }}>
                          <b>Support Documents:</b>
                          <span style={{ color: "red" }}></span>
                        </div>
                        <div className="personalDetailsUpload">
                          <div className="ducumentUploadBlock">
                            <div className="documentUploadContent">
                              <span
                                className="btn_upload documentUpload"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={panUploadIcon}
                                  alt=""
                                  style={{ marginRight: "10px" }}
                                />
                                <input
                                  type="file"
                                  id="imag"
                                  title=""
                                  className="input-img"
                                  onChange={handleUpload}
                                />
                                Upload Document
                              </span>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "itemGroupId",
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "itemId",
  },
  {
    no: 3,
    component: "Editablecell",
    type: "remarks",
  },
  {
    no: 4,
    component: "Editablecell",
    type: "hsncode",
  },
  {
    no: 5,
    component: "Editablecell",
    type: "quantity",
  },
  {
    no: 6,
    component: "Editablecell",
    type: "uom",
  },
  {
    no: 9,
    component: "DynamicSelect",
    type: "uom2",
  },
  {
    no: 7,
    component: "Editablecell",
    type: "rate",
  },
  {
    no: 9,
    component: "DynamicSelect",
    type: "rateUOM",
  },
  {
    no: 9,
    component: "DynamicSelect",
    type: "discountType",
  },
  {
    no: 10,
    component: "Editablecell",
    type: "discount",
  },
  {
    no: 11,
    component: "Editablecell",
    type: "discountedRate",
  },
  {
    no: 12,
    component: "Editablecell",
    type: "hessianRateIncludingBrokerage",
  },
  {
    no: 13,
    component: "Editablecell",
    type: "taxPercent",
  },

  {
    no: 14,
    component: "Editablecell",
    type: "netAmount",
  },
];
const tableHeaders = [
  {
    header: "Item Group",
  },
  {
    header: "Item Name",
  },
  {
    header: "Remarks",
  },

  {
    header: "HSN",
  },
  {
    header: "Quantity",
  },
  {
    header: "UOM",
  },
  {
    header: "UOM2",
  },
  {
    header: "Rate",
  },
  {
    header: "Rate UOM",
  },
  {
    header: "Discount Mode",
  },
  {
    header: "Discount",
  },
  {
    header: "Rate After Discount",
  },
  {
    header: "Rate After Brokerage",
  },
  {
    header: "Tax%",
  },
  {
    header: "Amount",
  },
  {
    header: "Actions",
  },
];
const discount_Type = [
  {
    value: 1,
    label: "Amount",
    name: "Amount",
  },
  {
    value: 2,
    label: "Percentage",
    name: "Percentage",
  },
];

export default JuteHessianLineItems;
