import React, { Component } from "react";
import { Grid, Box, Button, Tooltip } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { serverApi, serverConfig } from "../../../helpers/Consts";
import DropdownSelect from "../../../components/Dropdown/DynamicSelect";
import DynamicSelect from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import {
  getBranchList,
  getMukamList,
} from "../../../store/Global/DropDownApis/actions";
import RadioButton from "../../../components/RadioButton/RadioButton";
import { deleteFile } from "../../../store/MyProfile/UploadFile/actions";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import DeleteIcon from "../../../assets/images/picEditIcon.png";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import TextFieldMobileReq from "../../../components/TextField/TextFieldMobileReq";
import { getFiles } from "../../../store/Purchase/Indent/actions";
import AutoMultiselect from "../../../components/Dropdown/AutoMultiselect";
import {
  supplierMasterViewById,
  getSuppFilesData,
  deleteSupplierMasterFiles,
  UploadSupplierMasterFiles,
  getAllEntityTypes,
} from "../../../store/Master/SupplierMaster/actions";
import swal from "sweetalert";
import { getCostCenterList } from "../../../store/Master/CostCenterMaster/actions";
import { supplierTypesList } from "../../../store/Master/SupplierMaster/actions";
import moment from "moment";
import { ApprovalHierarchy } from "../../../store/HRMS/EmployeeDataBase/actions";
import { getFileDownload } from "../../../helpers/server.js";
import Fade from "@mui/material/Fade";
// import MukamList from "./mukamList.js";

class FillDetailsSupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      showDocument: true,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      suppId: this.props.SupplierfillDetails.suppId,
      activeFlag: this.props.SupplierfillDetails.activeFlag,
      address2: this.props.SupplierfillDetails.address2,
      autoCreate: this.props.SupplierfillDetails.autoCreate,
      cellPhone: this.props.SupplierfillDetails.cellPhone,
      cin: this.props.SupplierfillDetails.cin,
      cipher: this.props.SupplierfillDetails.cipher,
      comments: this.props.SupplierfillDetails.comments,
      contactNo: this.props.SupplierfillDetails.contactNo,
      contactPerson: this.props.SupplierfillDetails.contactPerson,
      createdBy: this.props.SupplierfillDetails.createdBy,
      designation: this.props.SupplierfillDetails.designation,
      email: this.props.SupplierfillDetails.email,
      gstNo: this.props.SupplierfillDetails.gstNo,
      ledgerGroup: this.props.SupplierfillDetails.ledgerGroup,
      msmeCertified: this.props.SupplierfillDetails.msmeCertified,
      mukam: this.props.SupplierfillDetails.mukam,
      panNo: this.props.SupplierfillDetails.panNo,
      state: this.props.SupplierfillDetails.state,
      status: this.props.SupplierfillDetails.status,
      suppName: this.props.SupplierfillDetails.suppName,
      suppTyp: this.props.SupplierfillDetails.suppTyp,
      tanNo: this.props.SupplierfillDetails.tanNo,
      totalOpeningBalance: this.props.SupplierfillDetails.totalOpeningBalance,
      userId: this.props.SupplierfillDetails.userId,
      vatno: this.props.SupplierfillDetails.vatno,
      suppCode: this.props.SupplierfillDetails.suppCode,
      supplierTypeList: [],
      branchListData: [],
      ledgerGroupsListData: [],
      mukamList: [],
      selectedMukamList: [],
      payment: "",
      active: "",
      showPan: false,
      showTan: false,
      showDoc: false,
      activeYes: this.props.SupplierfillDetails.activeYes,
      activeNo: this.props.SupplierfillDetails.activeNo,
      activeValue: "",
      msmeCertifiedYes: this.props.SupplierfillDetails.msmeCertifiedYes,
      msmeCertifiedNo: this.props.SupplierfillDetails.msmeCertifiedNo,
      tdsYes: this.props.SupplierfillDetails.tdsYes,
      tdsNo: this.props.SupplierfillDetails.tdsNo,
      uploadDocumentDisplay: "none",
      msmeCertifiedeValue: "",
      selectedTanFile: "",
      selectedPanFile: "",
      selectedCinFile: "",
      selectedBankFile: "",

      panfileId: "",
      tanfileId: "",
      chequqfileId: "",
      msmefileId: "",
      cinfileId: "",
      bankfileId: "",

      selectedUploadCld_dCheque: "",
      selecteduploadCertifications: "",
      PanValidation: this.props.SupplierfillDetails.PanValidation,
      PanMandatory: this.props.SupplierfillDetails.PanMandatory,
      TanValidation: this.props.SupplierfillDetails.TanValidation,
      AccountNoValidation: this.props.SupplierfillDetails.AccountNoValidation,
      IFScValidation: this.props.SupplierfillDetails.IFScValidation,
      Cost_Center_Data: [],

      createdOn: this.props.SupplierfillDetails.createdOn,
      EmailFieldValidation: this.props.SupplierfillDetails.EmailFieldValidation,
      EmailMandatory: this.props.SupplierfillDetails.EmailMandatory,
      vendorLoginYes: this.props.SupplierfillDetails.vendorLoginYes,
      vendorLoginNo: this.props.SupplierfillDetails.vendorLoginNo,
      ApproveButton: this.props.SupplierfillDetails.ApproveButton,
      entityTypeId: this.props.SupplierfillDetails.entityTypeId,
      accountNumber: this.props.SupplierfillDetails.accountNumber,
      ifscCode: this.props.SupplierfillDetails.ifscCode,
      entityTypeList: [],

      tanFileExtension: this.props.SupplierfillDetails.tanFileExtension,
      tanRetrivePath: this.props.SupplierfillDetails.tanRetrivePath,
      panFileExtension: this.props.SupplierfillDetails.panFileExtension,
      panRetrivePath: this.props.SupplierfillDetails.panRetrivePath,
      cinFileExtension: this.props.SupplierfillDetails.cinFileExtension,
      cinRetrivePath: this.props.SupplierfillDetails.cinRetrivePath,
      msmeFileExtension: this.props.SupplierfillDetails.msmeFileExtension,
      msmeRetrivePath: this.props.SupplierfillDetails.msmeRetrivePath,
      chequqFileExtension: this.props.SupplierfillDetails.chequqFileExtension,
      chequqRetrivePath: this.props.SupplierfillDetails.chequqRetrivePath,
      bankFileExtension: this.props.SupplierfillDetails.bankFileExtension,
      bankRetrivePath: this.props.SupplierfillDetails.bankRetrivePath,
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    const data1 = {
      companyId: localStorage.getItem("companyId"),
      cipher: this.state.userDit.cipher,
    };
    this.props.supplierTypesList(
      serverApi.SUPPLIER_TYPES_LIST,
      data1,
      this.props.history
    );

    this.props.getBranchList(
      serverApi.BRANCH_LIST + this.state.companyId,
      this.props.history
    );

    this.props.getMukamList(
      serverApi.MUKAM_GET_LIST + localStorage.getItem("companyId"),
      this.props.history
    );
    if (this.props.location.state) {
      if (this.props.location.state.rowData) {
        // this.props.supplierMasterViewById(
        //   serverApi.SUPPLIER_VIEWBY_ID +
        //     "/" +
        //     this.props.location.state.rowData.suppId +
        //     "/" +
        //     localStorage.getItem("companyId")
        // );
        this.props.getSuppFilesData(
          serverApi.GET_FILES +
            32 +
            "/" +
            this.props.location.state.rowData.suppId +
            "/" +
            localStorage.getItem("companyId"),
          this.props.history
        );
      }
    }
    const datas = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
    };
    this.props.getCostCenterList(
      serverApi.GET_ALL_COST_CENTER_LIST,
      datas,
      this.props.history
    );
    this.props.getAllEntityTypes(
      serverApi.GET_ALL_ENTITIES_LIST,
      this.props.history
    );
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData !== undefined) {
        if (
          this.state.suppId == "" &&
          this.state.activeYes == true &&
          this.state.activeNo == false &&
          this.state.msmeCertifiedNo == false &&
          this.state.msmeCertifiedYes == true &&
          this.state.address2 == "" &&
          this.state.cellPhone == "" &&
          this.state.cin == "" &&
          this.state.comments == "" &&
          this.state.contactNo == "" &&
          this.state.contactPerson == "" &&
          this.state.email == "" &&
          this.state.ledgerGroup == "" &&
          this.state.panNo == "" &&
          this.state.suppName == "" &&
          this.state.suppTyp == "" &&
          this.state.tanNo == "" &&
          this.state.accountNumber == "" &&
          this.state.ifscCode == ""
        ) {
          var Datasss = [];
          this.props.location.state.rowData.mukam.map((item) => {
            Datasss.push({
              label: item.mukamName,
              name: item.mukamName,
              value: item.id,
            });
          });
          let date = this.props.location.state.rowData.createdOn.split(" ", 1);
          let createdOn = moment(date[0], "YYYY-MM-DD", true).format(
            "DD-MM-YYYY"
          );
          this.setState({
            mukam: Datasss,
            suppId: this.props.location.state.rowData.suppId,
            activeYes:
              this.props.location.state.rowData.activeFlag == "Y"
                ? true
                : false,
            activeNo:
              this.props.location.state.rowData.activeFlag == "N"
                ? true
                : false,
            activeFlag:
              this.props.location.state.rowData.activeFlag == "Y" ? 1 : 2,
            msmeCertifiedNo:
              this.props.location.state.rowData.msmeCertified == "NO"
                ? true
                : false,
            msmeCertifiedYes:
              this.props.location.state.rowData.msmeCertified == "YES"
                ? true
                : false,
            msmeCertified: this.props.location.state.rowData.msmeCertified,
            address2: this.props.location.state.rowData.address2,
            autoCreate: false,
            cellPhone: this.props.location.state.rowData.cellPhone,
            cin: this.props.location.state.rowData.cin,
            cipher: this.props.location.state.rowData.cipher,
            comments: this.props.location.state.rowData.comments,
            companyId: this.props.location.state.rowData.companyId,
            contactNo: this.props.location.state.rowData.phone1,
            contactPerson: this.props.location.state.rowData.contactPerson,
            createdBy: this.props.location.state.rowData.createdBy,
            designation: this.props.location.state.rowData.designation,
            email: this.props.location.state.rowData.email,
            gstNo: this.props.location.state.rowData.gstNo,
            ledgerGroup: this.props.location.state.rowData.ledgerGroup,
            panNo: this.props.location.state.rowData.panNo,
            state: this.props.location.state.rowData.state,
            status: this.props.location.state.rowData.status,
            suppName: this.props.location.state.rowData.suppName,
            suppTyp: this.props.location.state.rowData.suppTyp,
            tanNo: this.props.location.state.rowData.tanNo,
            totalOpeningBalance:
              this.props.location.state.rowData.openningBalance,
            userId: this.props.location.state.rowData.userId,
            vatno: this.props.location.state.rowData.vatno,
            suppCode: this.props.location.state.rowData.suppCode,
            entityTypeId: this.props.location.state.rowData.entityTypeId,
            accountNumber: this.props.location.state.rowData.accountNumber,
            ifscCode: this.props.location.state.rowData.ifscCode,
            createdOn: createdOn,
          });
          this.props.SupplierfillDetails.createdBy =
            this.props.location.state.rowData.createdBy;
          this.props.SupplierfillDetails.createdOn = createdOn;
          this.props.SupplierfillDetails.suppCode =
            this.props.location.state.rowData.suppCode;
          this.props.SupplierfillDetails.totalOpeningBalance =
            this.props.location.state.rowData.openningBalance;
          this.props.SupplierfillDetails.mukam = Datasss;
          this.props.SupplierfillDetails.suppId =
            this.props.location.state.rowData.suppId;
          this.props.SupplierfillDetails.address2 =
            this.props.location.state.rowData.address2;
          this.props.SupplierfillDetails.autoCreate = false;
          this.props.SupplierfillDetails.cellPhone =
            this.props.location.state.rowData.cellPhone;
          this.props.SupplierfillDetails.cin =
            this.props.location.state.rowData.cin;
          this.props.SupplierfillDetails.cipher =
            this.props.location.state.rowData.cipher;
          this.props.SupplierfillDetails.comments =
            this.props.location.state.rowData.comments;
          this.props.SupplierfillDetails.companyId =
            this.props.location.state.rowData.companyId;
          this.props.SupplierfillDetails.contactNo =
            this.props.location.state.rowData.phone1;
          this.props.SupplierfillDetails.contactPerson =
            this.props.location.state.rowData.contactPerson;
          this.props.SupplierfillDetails.designation =
            this.props.location.state.rowData.designation;
          this.props.SupplierfillDetails.email =
            this.props.location.state.rowData.email;
          this.props.SupplierfillDetails.gstNo =
            this.props.location.state.rowData.gstNo;
          this.props.SupplierfillDetails.ledgerGroup =
            this.props.location.state.rowData.ledgerGroup;
          this.props.SupplierfillDetails.msmeCertifiedYes =
            this.props.location.state.rowData.msmeCertified == "YES"
              ? true
              : false;
          this.props.SupplierfillDetails.msmeCertifiedNo =
            this.props.location.state.rowData.msmeCertified == "NO"
              ? true
              : false;
          this.props.SupplierfillDetails.msmeCertified =
            this.props.location.state.rowData.msmeCertified;
          this.props.SupplierfillDetails.activeYes =
            this.props.location.state.rowData.activeFlag == "Y" ? true : false;
          this.props.SupplierfillDetails.activeNo =
            this.props.location.state.rowData.activeFlag == "N" ? true : false;
          this.props.SupplierfillDetails.activeFlag =
            this.props.location.state.rowData.activeFlag == "Y" ? 1 : 2;
          this.props.SupplierfillDetails.panNo =
            this.props.location.state.rowData.panNo;
          this.props.SupplierfillDetails.state =
            this.props.location.state.rowData.state;
          this.props.SupplierfillDetails.status =
            this.props.location.state.rowData.status;
          this.props.SupplierfillDetails.suppName =
            this.props.location.state.rowData.suppName;
          this.props.SupplierfillDetails.suppTyp =
            this.props.location.state.rowData.suppTyp;
          this.props.SupplierfillDetails.tanNo =
            this.props.location.state.rowData.tanNo;
          this.props.SupplierfillDetails.userId =
            this.props.location.state.rowData.userId;
          this.props.SupplierfillDetails.vatno =
            this.props.location.state.rowData.vatno;
          this.props.SupplierfillDetails.entityTypeId =
            this.props.location.state.rowData.entityTypeId;
          this.props.SupplierfillDetails.accountNumber =
            this.props.location.state.rowData.accountNumber;
          this.props.SupplierfillDetails.ifscCode =
            this.props.location.state.rowData.ifscCode;
        }
        // this.props.ApprovalHierarchy(
        //   serverApi.APPROVAL_BUTTON +
        //     JSON.parse(localStorage.getItem("authUser")).userId +
        //     "/32/company/" +
        //     localStorage.getItem("companyId") +
        //     "/branch/" +
        //     this.props.location.state.rowData.branchId +
        //     "/status/" +
        //     this.props.location.state.rowData.status,
        //   this.props.history
        // );
      }
    }
  }
  componentWillReceiveProps(props) {
    if (props.Supplier_TypesList) {
      if (props.Supplier_TypesList.data) {
        if (props.Supplier_TypesList.data.length !== 0) {
          var listData = [];
          props.Supplier_TypesList.data.map((item) => {
            listData.push({
              label: item.suppTypeDesc,
              name: item.suppTypeDesc,
              value: item.suppTypeCode,
            });
          });
        }
      }
      this.setState({
        supplierTypeList: listData,
      });
    }

    //branchList dropdown
    if (props.branchList) {
      if (props.branchList.data) {
        if (props.branchList.data.length !== 0) {
          var list = [];
          list = props.branchList.data.filter((item) => {
            if (item.value !== 0) {
              return item;
            }
          });

          this.setState({
            branchListData: list,
          });
        }
      }
    }

    if (props.GetAllFiles_Data) {
      if (props.GetAllFiles_Data.data) {
        if (props.GetAllFiles_Data.data.length !== 0) {
          props.GetAllFiles_Data.data.map((item) => {
            if (item.subTaskUniqueId === 321) {
              if (item.displayName !== "") {
                this.setState({
                  showPan: true,
                });
              }
              this.setState({
                selectedPanFile: item.displayName,
                panfileId: item.fileUploadId,
                panFileExtension: item.fileExtension,
                panRetrivePath: item.retrievalPath,
              });
            }
            if (item.subTaskUniqueId === 322) {
              if (item.displayName !== "") {
                this.setState({
                  showTan: true,
                });
              }
              this.setState({
                selectedTanFile: item.displayName,
                tanfileId: item.fileUploadId,
                tanFileExtension: item.fileExtension,
                tanRetrivePath: item.retrievalPath,
              });
            }
            if (item.subTaskUniqueId === 323) {
              this.setState({
                selectedUploadCld_dCheque: item.displayName,
                chequqfileId: item.fileUploadId,
                chequqFileExtension: item.fileExtension,
                chequqRetrivePath: item.retrievalPath,
              });
            }
            if (item.subTaskUniqueId === 324) {
              this.setState({
                selecteduploadCertifications: item.displayName,
                msmefileId: item.fileUploadId,
                msmeFileExtension: item.msmeFileExtension,
                msmeRetrivePath: item.retrievalPath,
              });
            }
            if (item.subTaskUniqueId === 325) {
              this.setState({
                selectedCinFile: item.displayName,
                cinfileId: item.fileUploadId,
                cinFileExtension: item.fileExtension,
                cinRetrivePath: item.retrievalPath,
              });
            }
            if (item.subTaskUniqueId === 326) {
              this.setState({
                selectedBankFile: item.displayName,
                bankfileId: item.fileUploadId,
                bankFileExtension: item.fileExtension,
                bankRetrivePath: item.retrievalPath,
              });
            }
          });
        } else {
          this.setState({
            selectedTanFile: "",
            tanfileId: null,
            selectedUploadCld_dCheque: "",
            chequqfileId: null,
            selectedPanFile: "",
            panfileId: null,
            selecteduploadCertifications: "",
            msmefileId: null,
            selectedCinFile: "",
            cinfileId: "",
            selectedBankFile: "",
            bankfileId: "",
            tanFileExtension: "",
            tanRetrivePath: "",
            panFileExtension: "",
            panRetrivePath: "",
            cinFileExtension: "",
            cinRetrivePath: "",
            msmeFileExtension: "",
            msmeRetrivePath: "",
            chequqFileExtension: "",
            chequqRetrivePath: "",
            bankFileExtension: "",
            bankRetrivePath: "",
          });
        }
      }
    }
    if (props.mukamList) {
      if (props.mukamList.data) {
        var Data = props.mukamList.data.filter((item) => item.value != 0);
        this.setState({
          mukamList: Data,
        });
      }
    }

    if (props.CostCenter_List) {
      if (props.CostCenter_List.data) {
        if (props.CostCenter_List.data.length !== 0) {
          var list = [];
          props.CostCenter_List.data.map((item) => {
            list.push({
              label: item.costDesc,
              name: item.costDesc,
              value: item.autoId,
            });
          });
          this.setState({
            Cost_Center_Data: list,
          });
        }
      }
    }

    if (props.getAllEntityType) {
      if (props.getAllEntityType.data) {
        if (props.getAllEntityType.data.length !== 0) {
          let List = [];
          props.getAllEntityType.data.map((item) => {
            List.push({
              value: item.id,
              name: item.Value,
              label: item.Value,
            });
          });
          this.setState({
            entityTypeList: List,
          });
        }
      }
    }
  }

  // Function for textfield
  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "panNo") {
      if (event !== undefined && event !== "") {
        this.setState({ showPan: !this.showPan });
      } else {
        this.setState({ showPan: false });
      }
      this.props.SupplierfillDetails.panNo = event;
      this.setState({
        panNo: event,
      });
      // Validation
      if (event == "") {
        this.setState({
          PanMandatory: true,
          PanValidation: false,
        });
        this.props.SupplierfillDetails.PanMandatory = true;
        this.props.SupplierfillDetails.PanValidation = false;
      } else {
        this.setState({
          PanMandatory: false,
        });
        this.props.SupplierfillDetails.PanMandatory = false;
        let exp = /^[A-Za-z0-9 ]+$/;
        if (exp.test(event)) {
          let exp2 = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
          if (exp2.test(event)) {
            this.setState({
              PanValidation: false,
            });
            this.props.SupplierfillDetails.PanValidation = false;
          } else {
            this.setState({
              PanValidation: true,
            });
            this.props.SupplierfillDetails.PanValidation = true;
          }
        } else {
          this.setState({
            PanValidation: true,
          });
          this.props.SupplierfillDetails.PanValidation = true;
        }
      }
    }
    if (key === "tanNo") {
      if (event !== undefined && event !== "") {
        this.setState({ showTan: !this.showTan });
      } else {
        this.setState({ showTan: false });
      }
      this.props.SupplierfillDetails.tanNo = event;
      this.setState({
        tanNo: event,
      });
      // Validation
      if (event == "") {
        this.setState({
          TanMandatory: true,
          TanValidation: false,
        });
        this.props.SupplierfillDetails.TanMandatory = true;
        this.props.SupplierfillDetails.TanValidation = false;
      } else {
        this.setState({
          TanMandatory: false,
        });
        this.props.SupplierfillDetails.TanMandatory = false;
        let exp = /^[A-Za-z0-9 ]+$/;
        if (exp.test(event)) {
          let exp2 = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
          if (exp2.test(event)) {
            this.setState({
              TanValidation: false,
            });
            this.props.SupplierfillDetails.TanValidation = false;
          } else {
            this.setState({
              TanValidation: true,
            });
            this.props.SupplierfillDetails.TanValidation = true;
          }
        } else {
          this.setState({
            TanValidation: true,
          });
          this.props.SupplierfillDetails.TanValidation = true;
        }
      }
    }
    if (key === "suppName") {
      this.props.SupplierfillDetails.suppName = event;
      this.setState({
        suppName: event,
      });
    }
    if (key === "email") {
      this.props.SupplierfillDetails.email = event;
      this.setState({
        email: event,
      });

      if (event === "") {
        this.setState({
          EmailMandatory: true,
          EmailFieldValidation: false,
        });
        this.props.SupplierfillDetails.EmailMandatory = true;
        this.props.SupplierfillDetails.EmailFieldValidation = false;
      } else {
        this.setState({
          EmailMandatory: false,
        });
        this.props.SupplierfillDetails.EmailMandatory = false;
        const val = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (val.test(event)) {
          this.setState({
            EmailFieldValidation: false,
          });
          this.props.SupplierfillDetails.EmailFieldValidation = false;
        } else {
          this.setState({
            EmailFieldValidation: true,
          });
          this.props.SupplierfillDetails.EmailFieldValidation = true;
        }
      }
    }
    if (key === "cin") {
      this.props.SupplierfillDetails.cin = event;
      this.setState({
        cin: event,
      });
    }
    if (key === "contactPerson") {
      const Person = event.replace(/[^a-zA-Z\s]+/g, "");
      this.props.SupplierfillDetails.contactPerson = event;
      this.setState({
        contactPerson: Person,
      });
    }
    if (key === "contactNo") {
      this.props.SupplierfillDetails.contactNo = event;
      this.setState({
        contactNo: event,
      });
    }

    if (key === "totalOpeningBalance") {
      this.props.SupplierfillDetails.totalOpeningBalance = event;
      this.setState({
        totalOpeningBalance: event,
      });
    }
    if (key === "comments") {
      this.props.SupplierfillDetails.comments = event;
      this.setState({
        comments: event,
      });
    }
    if (key === "accountNumber") {
      this.props.SupplierfillDetails.accountNumber = event;
      this.setState({
        accountNumber: event,
      });
    }
    if (key === "ifscCode") {
      this.props.SupplierfillDetails.ifscCode = event;
      this.setState({
        ifscCode: event,
      });
    }
  };
  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "ledgerGroup") {
      this.props.SupplierfillDetails.ledgerGroup = selectedValue;
      this.setState({
        ledgerGroup: selectedValue,
      });
    }
    if (name === "activeFlag") {
      this.props.SupplierfillDetails.activeFlag = selectedValue;
      this.setState({
        activeFlag: selectedValue,
      });
    }
    if (name === "entityTypeId") {
      this.props.SupplierfillDetails.entityTypeId = selectedValue;
      this.setState({
        entityTypeId: selectedValue,
      });
    }
    if (name === "status") {
      this.props.SupplierfillDetails.status = selectedValue;
      this.setState({
        status: selectedValue,
      });
    }
  };
  handlePropSelectChangeNew = (selectedValue, selectedName, name) => {
    if (name === "suppTyp") {
      this.props.SupplierfillDetails.suppTyp = selectedValue;
      this.setState({
        suppTyp: selectedValue,
      });
    }
  };

  // function for radio button
  //MSME Certified
  OnhandleChange = (msmeCertifiedValue) => {
    let msmeCertifiedYes = false,
      msmeCertifiedNo = false;
    let uploadDocumentDisplay = "none";
    if (msmeCertifiedValue === "Yes") {
      msmeCertifiedYes = true;
      this.props.SupplierfillDetails.msmeCertifiedYes = true;
      this.props.SupplierfillDetails.msmeCertifiedNo = false;

      uploadDocumentDisplay = "Block";
      this.setState({
        showDoc: true,
      });
    } else if (msmeCertifiedValue === "No") {
      msmeCertifiedNo = true;
      this.props.SupplierfillDetails.msmeCertifiedNo = true;
      this.props.SupplierfillDetails.msmeCertifiedYes = false;

      this.setState({
        showDoc: false,
      });
    }
    this.setState({
      msmeCertifiedValue: msmeCertifiedValue,
      msmeCertifiedYes: msmeCertifiedYes,
      uploadDocumentDisplay: uploadDocumentDisplay,
      msmeCertifiedNo: msmeCertifiedNo,
    });
  };
  //Active status
  ActivehandleChange = (activeValue) => {
    let activeYes = false,
      activeNo = false;
    if (activeValue === "Yes") {
      this.props.SupplierfillDetails.activeYes = true;
      this.props.SupplierfillDetails.activeNo = false;

      activeYes = true;
    } else if (activeValue === "No") {
      this.props.SupplierfillDetails.activeNo = true;
      this.props.SupplierfillDetails.activeYes = false;
      activeNo = true;
    }
    this.setState({
      activeValue: activeValue,
      activeYes: activeYes,
      activeNo: activeNo,
    });
  };
  //Vendor Login
  vendorLoginhandleChange = (activeValue) => {
    let vendorLoginYes = false,
      vendorLoginNo = false;
    if (activeValue === "Yes") {
      this.props.SupplierfillDetails.vendorLoginYes = true;
      this.props.SupplierfillDetails.vendorLoginNo = false;
      vendorLoginYes = true;
    } else if (activeValue === "No") {
      this.props.SupplierfillDetails.vendorLoginYes = false;
      this.props.SupplierfillDetails.vendorLoginNo = true;
      vendorLoginNo = true;
    }
    this.setState({
      activeValue: activeValue,
      vendorLoginYes: vendorLoginYes,
      vendorLoginNo: vendorLoginNo,
    });
  };
  TdshandleChange = (activeValue) => {
    let activeYes = false,
      activeNo = false;
    if (activeValue === "Yes") {
      this.props.SupplierfillDetails.tdsYes = true;
      this.props.SupplierfillDetails.tdsNo = false;
      activeYes = true;
    } else if (activeValue === "No") {
      this.props.SupplierfillDetails.tdsNo = true;
      this.props.SupplierfillDetails.tdsYes = false;
      activeNo = true;
    }
    this.setState({
      tdsYes: activeYes,
      tdsNo: activeNo,
    });
  };

  // upload Document
  handleselectedFile = (key) => (e) => {
    e.preventDefault();
    let idforfile = "";
    if (key === "panUpload") {
      idforfile = "1";
    }
    if (key === "tanUpload") {
      idforfile = "2";
    }
    if (key === "uploadCancelledCheq") {
      idforfile = "3";
    }
    if (key === "UploadCertificates") {
      idforfile = "4";
    }
    if (key === "cinUpload") {
      idforfile = "5";
    }
    if (key === "bankUpload") {
      idforfile = "6";
    }
    if (this.props.location.state.rowData) {
      let suppId = this.props.location.state.rowData.suppId;
      let file = e.target.files[0];
      let fileExt = file.name.split(".").pop();
      const formData = new FormData();
      formData.append("fileName", file.name);
      formData.append("fileUpload", file);
      formData.append("fileExtension", fileExt);
      formData.append("sourceMenu", 32);
      formData.append("displayName", file.name);
      formData.append("taskUniqueId", suppId);
      formData.append("createdBy", this.state.userDit.userId);
      formData.append("fileCompanyId", localStorage.getItem("companyId"));
      formData.append("subTaskUniqueId", "32" + idforfile);
      this.setState({
        fileName: file.name,
        fileExtension: file.name.split(".").pop(),
      });
      if (key === "panUpload") {
        this.setState({
          fileName: file.name,
          fileExtension: file.name.split(".").pop(),
        });
      }
      if (key === "panUpload") {
        this.setState({
          selectedFile: e.target.files[0],
          selectedPanFile: e.target.files[0].name,
        });
      }
      if (key === "tanUpload") {
        this.setState({
          selectedFile: e.target.files[0],
          selectedTanFile: e.target.files[0].name,
        });
      }
      if (key === "cinUpload") {
        this.setState({
          selectedFile: e.target.files[0],
          selectedCinFile: e.target.files[0].name,
        });
      }
      if (key === "bankUpload") {
        this.setState({
          selectedFile: e.target.files[0],
          selectedBankFile: e.target.files[0].name,
        });
      }
      if (key === "uploadCancelledCheq") {
        this.setState({
          selectedFile: e.target.files[0],
          selectedUploadCld_dCheque: e.target.files[0].name,
        });
      }
      if (key === "UploadCertificates") {
        this.setState({
          selectedFile: e.target.files[0],
          selecteduploadCertifications: e.target.files[0].name,
        });
      }
      this.props.UploadSupplierMasterFiles(
        serverApi.UPLOADFILE,
        formData,
        suppId,
        this.props.history
      );
    }
  };

  handleDeleteFile = (key, fileUploadId) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (key === "321") {
          this.setState({
            selectedPanFile: "",
            panfileId: "",
            panFileExtension: "",
            panRetrivePath: "",
          });
        }
        if (key === "322") {
          this.setState({
            selectedTanFile: "",
            tanfileId: "",
            tanFileExtension: "",
            tanRetrivePath: "",
          });
        }
        if (key === "323") {
          this.setState({
            selectedUploadCld_dCheque: "",
            chequqfileId: "",
            chequqFileExtension: "",
            chequqRetrivePath: "",
          });
        }
        if (key === "324") {
          this.setState({
            selecteduploadCertifications: "",
            msmefileId: "",
            msmeFileExtension: "",
            msmeRetrivePath: "",
          });
        }
        if (key === "325") {
          this.setState({
            selectedCinFile: "",
            cinfileId: "",
            cinFileExtension: "",
            cinRetrivePath: "",
          });
        }
        if (key === "326") {
          this.setState({
            selectedBankFile: "",
            bankfileId: "",
            bankFileExtension: "",
            bankRetrivePath: "",
          });
        }
        if (this.props.location.state !== undefined) {
          if (this.props.location.state.rowData !== undefined) {
            this.props.deleteSupplierMasterFiles(
              serverApi.DELETE_FILE_BI_ID + fileUploadId,
              this.props.location.state.rowData.suppId,
              this.props.history
            );
          }
        }
      } else {
        swal("Your action is canceled!");
      }
    });
    //
  };

  // function for multi slelect dropdown
  getMopt = (selectedValue, name) => {
    if (name === "itemslist") {
      var selectitem = selectedValue.filter((item) => {
        return item;
      });
      this.props.SupplierfillDetails.mukam = selectitem;
      this.setState({
        mukam: selectitem,
      });
    }

    if (name === "suppliertype") {
      let suppTypes = [];
      if (selectedValue) {
        selectedValue.map((data) => {
          this.state.supplierTypeList.map((p, i) => {
            if (data.value === p.value)
              suppTypes.push({
                label: p.label,
                value: p.value,
                name: p.name,
              });
          });
        });
      }

      this.setState({
        suppTyp: suppTypes,
      });
    }
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.
    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    const isBlacklist = this.state.status === 46;

    return (
      <div className="SupplierMasterContainer">
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.entityTypeList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.entityTypeId}
                        update={this.state.entityTypeId ? 1 : 0}
                        name="entityTypeId"
                        label="Entity Type"
                        required
                        isDisabled={isBlacklist}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.suppName}
                      onChange={this.handleChange("suppName")}
                      label="Supplier Name"
                      disabled={
                        isBlacklist || this.state.entityTypeId == ""
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DropdownSelect
                        arrayOfData={this.state.supplierTypeList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChangeNew}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.suppTyp}
                        update={this.state.suppTyp ? 1 : 0}
                        name="suppTyp"
                        label="Supplier Type"
                        getOpt={this.getMopt}
                        required
                        isDisabled={(this.props.location.state !== undefined) || (isBlacklist || this.state.suppName === "")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.email}
                      onChange={this.handleChange("email")}
                      label="Email"
                      disabled={
                        isBlacklist || this.state.suppName === "" ? true : false
                      }
                      required
                    />
                    {this.state.EmailMandatory && (
                      <div className="TextFieldBlock">
                        <span className="text-danger">
                          This Field is Required
                        </span>
                      </div>
                    )}
                    {this.state.EmailFieldValidation && (
                      <div className="TextFieldBlock">
                        <span className="text-danger">
                          Invalid Email Address
                        </span>
                      </div>
                    )}
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      required
                      value={this.state.contactPerson}
                      onChange={this.handleChange("contactPerson")}
                      label="Contact Person"
                      disabled={
                        isBlacklist || this.state.email === "" ? true : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldMobileReq
                      value={this.state.contactNo}
                      onChange={this.handleChange("contactNo")}
                      label="Contact Number"
                      disabled={
                        isBlacklist || this.state.contactPerson === ""
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 6"></Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.panNo}
                      caps="ON"
                      minValue="0"
                      maxlength="10"
                      onChange={this.handleChange("panNo")}
                      label="PAN Number"
                      disabled={
                        isBlacklist || this.state.contactNo === ""
                          ? true
                          : false
                      }
                    />
                    {this.state.PanValidation && (
                      <div className="TextFieldBlock">
                        <span className="text-danger">Invalid PAN Number</span>
                      </div>
                    )}
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="supplierDocumentUpload">
                      <div className="ducumentUploadBlock">
                        <div className="documentUploadContent">
                          {this.state.selectedPanFile === "" ? (
                            <span
                              className={
                                this.props.locationState.state !== undefined &&
                                !isBlacklist
                                  ? "btn_upload documentUpload"
                                  : "btn_upload documentUpload disabled"
                              }
                            >
                              <img src={panUploadIcon} alt="" />
                              <input
                                type="file"
                                id="imag"
                                title=""
                                className="input-img"
                                disabled={
                                  !isBlacklist &&
                                  this.props.locationState.state !== undefined
                                    ? false
                                    : true
                                }
                                onChange={this.handleselectedFile("panUpload")}
                              />
                              Upload Document
                            </span>
                          ) : (
                            ""
                          )}
                          {this.state.selectedPanFile !== "" && (
                            <div className="uploadedInfo">
                              <img src={TickIcon} alt="" className="tickIcon" />
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title="Click to download File"
                              >
                                <div
                                  onClick={() => {
                                    getFileDownload(
                                      serverConfig.SERVER_URL +
                                        "security-api/api/files/downloadfile/" +
                                        this.state.panfileId,
                                      this.state.selectedPanFile,
                                      this.state.panFileExtension
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {this.state.selectedPanFile}
                                </div>
                              </Tooltip>
                              {!isBlacklist ? (
                                <Button
                                  onClick={() => {
                                    this.handleDeleteFile(
                                      "321",
                                      this.state.panfileId
                                    );
                                  }}
                                  className="deleteButton"
                                >
                                  <img src={DeleteIcon} alt="" />
                                </Button>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Box>

                  <Box gridColumn="span 6"></Box>

                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.tanNo}
                      caps="ON"
                      minValue="0"
                      onChange={this.handleChange("tanNo")}
                      label="TAN Number"
                      disabled={
                        isBlacklist || this.state.contactNo === ""
                          ? true
                          : false
                      }
                    />
                    {this.state.TanValidation && (
                      <div className="TextFieldBlock">
                        <span className="text-danger">Invalid TAN Number</span>
                      </div>
                    )}
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="supplierDocumentUpload">
                      <div className="ducumentUploadBlock">
                        <div className="documentUploadContent">
                          {this.state.selectedTanFile === "" ? (
                            <span
                              className={
                                this.props.locationState.state !== undefined &&
                                !isBlacklist
                                  ? "btn_upload documentUpload "
                                  : "btn_upload documentUpload disabled"
                              }
                            >
                              <img src={panUploadIcon} alt="" />
                              <input
                                type="file"
                                id="imag"
                                title=""
                                className="input-img"
                                onChange={this.handleselectedFile("tanUpload")}
                                disabled={
                                  !isBlacklist &&
                                  this.props.locationState.state !== undefined
                                    ? false
                                    : true
                                }
                              />
                              Upload Document
                            </span>
                          ) : (
                            ""
                          )}
                          {this.state.selectedTanFile !== "" && (
                            <div className="uploadedInfo">
                              <img src={TickIcon} alt="" className="tickIcon" />
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title="Click to download File"
                              >
                                <div
                                  onClick={() => {
                                    getFileDownload(
                                      serverConfig.SERVER_URL +
                                        "security-api/api/files/downloadfile/" +
                                        this.state.tanfileId,
                                      this.state.selectedTanFile,
                                      this.state.tanFileExtension
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {/* {this.state.fileName} */}
                                  {this.state.selectedTanFile}
                                </div>
                              </Tooltip>

                              {!isBlacklist ? (
                                <Button
                                  onClick={() => {
                                    this.handleDeleteFile(
                                      "322",
                                      this.state.tanfileId
                                    );
                                  }}
                                  className="deleteButton"
                                >
                                  <img src={DeleteIcon} alt="" />
                                </Button>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Box>

                  <Box gridColumn="span 6"></Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.cin}
                      minValue="0"
                      onChange={this.handleChange("cin")}
                      label="CIN Number"
                      disabled={
                        isBlacklist || this.state.contactNo === ""
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="supplierDocumentUpload">
                      <div className="ducumentUploadBlock">
                        <div className="documentUploadContent">
                          {this.state.selectedCinFile === "" ? (
                            <span
                              className={
                                this.props.locationState.state !== undefined &&
                                !isBlacklist
                                  ? "btn_upload documentUpload "
                                  : "btn_upload documentUpload disabled"
                              }
                            >
                              <img src={panUploadIcon} alt="" />
                              <input
                                type="file"
                                id="imag"
                                title=""
                                className="input-img"
                                onChange={this.handleselectedFile("cinUpload")}
                                disabled={
                                  !isBlacklist &&
                                  this.props.locationState.state !== undefined
                                    ? false
                                    : true
                                }
                              />
                              Upload Document
                            </span>
                          ) : (
                            ""
                          )}
                          {this.state.selectedCinFile !== "" && (
                            <div className="uploadedInfo">
                              <img src={TickIcon} alt="" className="tickIcon" />
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title="Click to download File"
                              >
                                <div
                                  onClick={() => {
                                    getFileDownload(
                                      serverConfig.SERVER_URL +
                                        "security-api/api/files/downloadfile/" +
                                        this.state.cinfileId,
                                      this.state.selectedCinFile,
                                      this.state.cinFileExtension
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {this.state.selectedCinFile}
                                </div>
                              </Tooltip>

                              {!isBlacklist ? (
                                <Button
                                  onClick={() => {
                                    this.handleDeleteFile(
                                      "325",
                                      this.state.cinfileId
                                    );
                                  }}
                                  className="deleteButton"
                                >
                                  <img src={DeleteIcon} alt="" />
                                </Button>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <br />
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      value={this.state.accountNumber}
                      onChange={this.handleChange("accountNumber")}
                      label="Account Number"
                      disabled={isBlacklist}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="supplierDocumentUpload">
                    <div className="ducumentUploadBlock">
                      <div className="documentUploadContent">
                        {this.state.selectedBankFile === "" ? (
                          <span
                            className={
                              this.props.locationState.state !== undefined &&
                              !isBlacklist
                                ? "btn_upload documentUpload"
                                : "btn_upload documentUpload disabled"
                            }
                          >
                            <img src={panUploadIcon} alt="" />
                            <input
                              type="file"
                              id="imag"
                              title=""
                              className="input-img"
                              disabled={
                                !isBlacklist &&
                                this.props.locationState.state !== undefined
                                  ? false
                                  : true
                              }
                              onChange={this.handleselectedFile("bankUpload")}
                            />
                            Upload Bank Statement
                          </span>
                        ) : (
                          ""
                        )}
                        {this.state.selectedBankFile !== "" && (
                          <div className="uploadedInfo">
                            <img src={TickIcon} alt="" className="tickIcon" />
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Click to download File"
                            >
                              <div
                                onClick={() => {
                                  getFileDownload(
                                    serverConfig.SERVER_URL +
                                      "security-api/api/files/downloadfile/" +
                                      this.state.bankfileId,
                                    this.state.selectedBankFile,
                                    this.state.chequqFileExtension
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {/* {this.state.fileName} */}
                                {this.state.selectedBankFile}
                              </div>
                            </Tooltip>
                            {!isBlacklist ? (
                              <Button
                                onClick={() => {
                                  this.handleDeleteFile(
                                    "326",
                                    this.state.bankfileId
                                  );
                                }}
                                className="deleteButton"
                              >
                                <img src={DeleteIcon} alt="" />
                              </Button>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      value={this.state.ifscCode}
                      onChange={this.handleChange("ifscCode")}
                      label="IFSC Code"
                      disabled={isBlacklist}
                    />
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
        <br />

        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <TextFieldNormal
                    value={this.state.totalOpeningBalance}
                    caps="OFF"
                    onChange={this.handleChange("totalOpeningBalance")}
                    label="Opening Balance"
                    disabled={true}
                    rightAlign="right"
                  />
                </Box>
                {this.state.suppTyp === "J" ? (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <AutoMultiselect
                        options={
                          this.state.mukamList &&
                          this.state.mukamList.map((item) => ({
                            label: item.label,
                            value: item.value,
                            name: item.name,
                          }))
                        }
                        showCheckbox={true}
                        selectedValues={this.state.mukam}
                        label="Mukam List"
                        name={"itemslist"}
                        getOpt={this.getMopt}
                        mandatory="*"
                      />
                    </div>
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          </Grid>
        </div>
        <br />
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 2">
                  <div className="consoleTextFieldBlock">
                    <label>MSME Certified{this.state.msmeCertifiedYes}</label>
                    <div className="taxPayableContent">
                      <RadioButton
                        value={this.state.msmeCertifiedYes}
                        labelText="Yes"
                        checkednew={this.state.msmeCertifiedYes}
                        onChange={this.OnhandleChange}
                        disabled={isBlacklist}
                      />

                      <RadioButton
                        value={this.state.msmeCertifiedNo}
                        checkednew={this.state.msmeCertifiedNo}
                        onChange={this.OnhandleChange}
                        labelText="No"
                        disabled={isBlacklist}
                      />
                    </div>
                  </div>
                </Box>

                <Box gridColumn="span 3">
                  <div className="supplierDocumentUpload">
                    <div className="ducumentUploadBlock">
                      <div className="documentUploadContent">
                        {this.state.selecteduploadCertifications === "" &&
                        this.state.msmeCertifiedYes === true ? (
                          <span
                            className={
                              this.props.locationState.state !== undefined &&
                              !isBlacklist
                                ? "btn_upload documentUpload"
                                : "btn_upload documentUpload disabled"
                            }
                          >
                            <img src={panUploadIcon} alt="" />
                            <input
                              type="file"
                              id="imag"
                              title=""
                              disabled={
                                !isBlacklist &&
                                this.props.locationState.state !== undefined
                                  ? false
                                  : true
                              }
                              className="input-img"
                              onChange={this.handleselectedFile(
                                "UploadCertificates"
                              )}
                            />
                            Upload Certificates
                          </span>
                        ) : (
                          ""
                        )}
                        {this.state.selecteduploadCertifications !== "" && (
                          <div className="uploadedInfo">
                            <img src={TickIcon} alt="" className="tickIcon" />
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Click to download File"
                            >
                              <div
                                onClick={() => {
                                  getFileDownload(
                                    serverConfig.SERVER_URL +
                                      "security-api/api/files/downloadfile/" +
                                      this.state.msmefileId,
                                    this.state.selecteduploadCertifications,
                                    this.state.msmeFileExtension
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {/* {this.state.fileName} */}
                                {this.state.selecteduploadCertifications}
                              </div>
                            </Tooltip>

                            {!isBlacklist ? (
                              <Button
                                onClick={() => {
                                  this.handleDeleteFile(
                                    "324",
                                    this.state.msmefileId
                                  );
                                }}
                                className="deleteButton"
                              >
                                <img src={DeleteIcon} alt="" />
                              </Button>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Box>

                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      value={this.state.comments}
                      type="textarea"
                      label="Comments"
                      onChange={this.handleChange("comments")}
                      disabled={
                        isBlacklist || this.state.panNo === "" ? true : false
                      }
                    />
                  </div>
                </Box>

                {this.props.locationState.state !== undefined && (
                  <Box gridColumn="span 3">
                    <DynamicSelect
                      arrayOfData={activeFlagData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.activeFlag}
                      update={this.state.activeFlag ? 1 : 0}
                      name="activeFlag"
                      label="Active/InActive"
                    />
                  </Box>
                )}
                {this.props.locationState.state !== undefined && (
                  <Box gridColumn="span 3">
                    <DynamicSelect
                      arrayOfData={StatusData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.status}
                      update={this.state.status ? 1 : 0}
                      name="status"
                      label="Status"
                    />
                  </Box>
                )}
                {this.props.locationState.state === undefined ? (
                  ""
                ) : (
                  <>
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdOn}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </div>
        <br />
        
        {/* If mumak list to be added as list */}
      
        {/* <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MukamList mukamList={this.state.mukamList} selectedMukamIds={this.selectedMukamIds} onGeneratePayload={this.handleGeneratePayload} />
            </Grid>
          </Grid>
        </div> */}
      </div>
    );
  }
}
const activeFlagData = [
  {
    value: "1",
    label: "Active",
    name: "Active",
  },
  {
    value: "2",
    label: "InActive",
    name: "InActive",
  },
];
const StatusData = [
  {
    value: "3",
    label: "Approved",
    name: "Approved",
  },
  {
    value: "46",
    label: "Blacklist",
    name: "Blacklist",
  },
];
const mapStatetoProps = (state) => {
  const { branchList, mukamList } = state.DropDownListReducer;
  const { files_List } = state.IndentReducer;
  const {
    SupplierFillDetails,
    SupplierViewById,
    GetAllFiles_Data,
    Supplier_TypesList,
    getAllEntityType,
  } = state.Supplier_List;
  const { CostCenter_List } = state.CostCenterListReducer;
  const { approval_hierarchy } = state.EmployeeReducer;

  return {
    branchList,
    files_List,
    mukamList,
    SupplierViewById,
    GetAllFiles_Data,
    SupplierFillDetails,
    CostCenter_List,
    Supplier_TypesList,
    approval_hierarchy,
    getAllEntityType,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getBranchList,
    UploadSupplierMasterFiles,
    getFiles,
    deleteFile,
    getMukamList,
    supplierMasterViewById,
    getSuppFilesData,
    deleteSupplierMasterFiles,
    getCostCenterList,
    supplierTypesList,
    ApprovalHierarchy,
    getAllEntityTypes,
  })(FillDetailsSupplier)
);
