import { LEFT_SIDE_MENU, VENDOR_LEFT_SIDE_MENU } from "./actionType";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {
  LeftSideMenuSuccessfull,
  VendorLeftSideMenuSuccessfull,
} from "./actions";
import { getListurl } from "../../../../helpers/Server_Helper";

function* getLeftSideMenu({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (response.data) {
      yield put(LeftSideMenuSuccessfull({ response }));
    } else {
      const Response = null;
      yield put(LeftSideMenuSuccessfull({ Response }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getVendorLeftSideMenu({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (response.data) {
      yield put(VendorLeftSideMenuSuccessfull({ response }));
    } else {
      const Response = null;
      yield put(LeftSideMenuSuccessfull({ Response }));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchLeftSideMenu() {
  yield takeEvery(LEFT_SIDE_MENU, getLeftSideMenu);
  yield takeEvery(VENDOR_LEFT_SIDE_MENU, getVendorLeftSideMenu);
}

function* LeftSideMenuSaga() {
  yield all([fork(watchLeftSideMenu)]);
}

export default LeftSideMenuSaga;
