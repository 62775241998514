import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TCSPercentages } from "../../../helpers/TableData";
// import DynamicSelect from "../../../components/Dropdown/Jute/JuteDynamicSelect";
import { deleteFile } from "../../../store/MyProfile/UploadFile/actions"; // Assuming your actions are imported from the correct path
import {
  getMukamByItemList,
  getQualityByItemList,
} from "../../../store/MasterDropdownApis/actions";
import {
  getBillPassLineItems,
  UploadInvoiceFiles,
  getJuteBillPassHeader,
  getInvoiceFiles,
  getSupportFiles,
  getChallanFiles,
} from "../../../store/Jute/JuteBillPass/actions";
import { getwareHouseDataList } from "../../../store/StoreModule/StockTransfer/actions";
import { Grid, Box, Tooltip, Button, IconButton } from "@mui/material";
import Fade from "@mui/material/Fade";

import DeleteIcons from "../../../assets/images/picEditIcon.png";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SpeedIcon from "@mui/icons-material/Speed";

import moment from "moment";
import swal from "sweetalert";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { getFileDownload } from "../../../helpers/server";
import { serverApi, serverConfig } from "../../../helpers/Consts";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import ReactTooltip from "react-tooltip";
import { QualityByJuteType } from "../../../store/Jute/JuteIndent/actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// Import any other necessary components and assets

const JuteBillPassLineItem = (props) => {
  // Define your component's state using useState
  const [readingPopUp, setReadingPopUp] = useState(false);
  const [readingArrayData, setReadingArrayData] = useState([
    { SlNo: 0, readingData: 0 },
  ]);
  const [isDropdownOpen, setIsDropdownOpen] = useState();
  const [LineItems, setLineItems] = useState([]);
  const [HeaderData, setHeaderData] = useState({});
  const [invoicedocsListData, setInvoicedocsList] = useState([]);
  const [challanFilesListData, setChallandocsList] = useState([]);
  const [supportDocsListData, setSupportDocsList] = useState([]);
  const [tdsAmount, setTdsAmount] = useState(0);
  const [netTotal, setNetTotal] = useState(0);
  const [tcsPercentage, setTcsPercentage] = useState("");
  const [tcsPercentageValue, setTcsPercentageValue] = useState("");
  const [tdsPayable, setTdsPayable] = useState("");
  const [tdsfields, setTdsfields] = useState(false);
  const [tdsReason, setTdsReason] = useState("");
  const [LineItemsDisplayTxtField, setLineItemsDisplayTxtField] = useState({});
  const [LineItemsDisplayDropField, setLineItemsDisplayDropField] = useState(
    {}
  );
  const [juteTypeList, setJuteTypeList] = useState([]);
  const [qualityByJuteList, setqualityByJuteList] = useState([]);
  const [newPaymentDueDate, setNewPaymentDueDate] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const[invoiceRecievedDate,setInvoiceRecievedDate]=useState("");
  const location = useLocation();
  const history = useHistory();
  const [locationState] = useState(useLocation());
  const userDit = JSON.parse(localStorage.getItem("authUser"));

  const { JuteTypeByMukamData, QualityByJuteTypeData } = useSelector(
    (state) => state.JuteIndentReducer
  );

  const {
    JuteBillPassHeader,
    billpasslineItems,
    invoiceFilesList,
    challanFilesList,
    supportFilesList,
  } = useSelector((state) => state.JuteBillPassReducer);
  useEffect(() => {
    dispatch(
      getInvoiceFiles(
        serverApi.GET_FILES +
          "2/" +
          props.locationState.state.rowData.billPassNo +
          "/" +
          localStorage.getItem("companyId"),
        history,
        "2"
      )
    );
  }, []);

  useEffect(() => {
    dispatch(
      getSupportFiles(
        serverApi.GET_FILES +
          "18/" +
          props.locationState.state.rowData.billPassNo +
          "/" +
          localStorage.getItem("companyId"),
        history,
        "18"
      )
    );
  }, []);

  useEffect(() => {
    dispatch(
      getChallanFiles(
        serverApi.GET_FILES +
          "3/" +
          props.locationState.state.rowData.billPassNo +
          "/" +
          localStorage.getItem("companyId"),
        history,
        "3"
      )
    );
  }, []);

  useEffect(() => {
    setHeaderData(JuteBillPassHeader);
    setLineItems(billpasslineItems);
  }, [JuteBillPassHeader, billpasslineItems]);
  // Define any other state variables using useState as needed
  useEffect(() => {
    if (invoiceFilesList !== undefined) {
      if (invoiceFilesList.data) {
      if (invoiceFilesList.data.data) {

        setInvoicedocsList(invoiceFilesList.data.data);
      }
    }
    }
  }, [invoiceFilesList]);

  useEffect(() => {
    if (supportFilesList !== undefined) {
      if (supportFilesList.data) {
      if (supportFilesList.data) {
        setSupportDocsList(supportFilesList.data);
      }
    }
    }
  }, [supportFilesList]);

  useEffect(() => {
    if (challanFilesList !== undefined) {
      if (challanFilesList.data) {
      if (challanFilesList.data) {
        setChallandocsList(challanFilesList.data);
      }
      }
    }
  }, [challanFilesList]);

  useEffect(() => {
    if (JuteTypeByMukamData) {
      setJuteTypeList(JuteTypeByMukamData.data);
    }
  }, [JuteTypeByMukamData, QualityByJuteTypeData]);

  const headerData = useSelector((state) => state.headerData); // Assuming you have headerData in your Redux state

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMukamByItemList());
    dispatch(getQualityByItemList());
    dispatch(getwareHouseDataList());
  }, []);

  const displayField = (row, colname, type) => {
    if (colname == "itemId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
    } else if (colname == "Quality") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type == 1) {
        dispatch(
          QualityByJuteType(
            serverApi.QUALITY_BY_JUTE_TYPE +
              row.itemId +
              "/" +
              localStorage.getItem("companyId") +
              "/getQualityByItem",
            row.id
          )
        );
      }
    }
  };

  const OnhandleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    let mname = key.split("_");
    if (mname[0] === "premiumAmount") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.premiumAmount = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getBillPassLineItems(LineItemsRec));
    }

    if (mname[0] === "waterDamageAmount") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.waterDamageAmount = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getBillPassLineItems(LineItemsRec));
    }
  };

  const handleMenuOpen = () => {
    setIsDropdownOpen(false);
  };
  const handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    setIsDropdownOpen(true);
  };

  const onhandleChange = (key) => (value) => {
    if (key === "tdsReason") {
      HeaderData.tdsReason = value;
      setTdsReason(value);
    }
    if (key === "tdsAmount") {
      const filterData = billpasslineItem;
      const totalAmount = filterData.reduce(
        (acc, item) => acc + item.amount,
        0
      );
      const tcsAmount =
        HeaderData.tcsAmount === null ? 0 : HeaderData.tcsAmount;
      HeaderData.tdsAmount = value;
      setTdsAmount(value);
      setNetTotal(totalAmount + tcsAmount - value);
    }
    if (key === "invoiceAmount") {
      HeaderData.invoiceAmount = value;
      setInvoiceAmount(value);
    }
    if (key === "invoiceNo") {
      HeaderData.invoiceNo = value;
      setInvoiceNumber(value);
    }
    if (key === "ledgerName") {
      setHeaderData((prev) => ({
        ...prev,
        ledgerName: value,
      }));

      const UpdatedRec = {
        ...JuteBillPassHeader,
        ledgerName: value,
      };
      dispatch(getJuteBillPassHeader(UpdatedRec));
    }
  };

  const handleDeleteFile = (fileId, type) => {
    if (type === "suppdocs") {
      const data = supportDocsListData.filter(
        (item) => item.fileUploadId !== fileId
      );
      dispatch(deleteFile(`${serverApi.DELETEFILE}${fileId}`));
      setSupportDocsList(data)
    }
    if (type === "Challan") {
      const data = challanFilesListData.filter(
        (item) => item.fileUploadId !== fileId
      );
      dispatch(deleteFile(`${serverApi.DELETEFILE}${fileId}`));
      setChallandocsList(data)
    }
    if (type === "invoices") {
      const data = invoicedocsListData.filter(
        (item) => item.fileUploadId !== fileId
      );
      dispatch(deleteFile(`${serverApi.DELETEFILE}${fileId}`));
      setInvoicedocsList(data);
    }
  };

  const onDelLineItem = (row) => {
    var FilterLineItems = LineItems.filter((item) => item.isActive !== 0);
    if (FilterLineItems.length === 1) {
      swal("you can't delete the last lineitem");
    } else {
      let indexItem = LineItems.findIndex((item) => item === row);
      let lineItems = [...LineItems];
      lineItems[indexItem].isActive = 0;
      setLineItems(lineItems);
      dispatch(getBillPassLineItems(lineItems));
    }
  };

  const UploadDocuments = (key) => (e) => {
    if (location.state) {
      var BillPassId = location.state.rowData.billPassNo;
    }
    e.preventDefault();
    let sourceMenu = 0;
    if (key === "SuppDocs") {
      sourceMenu = 18;
    } else if (key === "invoices") {
      sourceMenu = 2;
    } else {
      sourceMenu = 3;
    }
    const file = e.target.files[0];
    const fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", sourceMenu);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", BillPassId);
    formData.append("createdBy", userDit.userId);
    formData.append("fileCompanyId", localStorage.getItem("companyId"));
    // setState((prevState) => ({
    //   ...prevState,
    //   fileName: file.name,
    //   fileType: fileExt,
    //   fileTypeName: key,
    // }));
    let reqObj = {
      id: props.locationState.state.rowData.billPassNo,
      companyId: localStorage.getItem("companyId"),
    };
    if (sourceMenu === 2) {
      dispatch(
        UploadInvoiceFiles(
          serverApi.UPLOADFILE,
          formData,
          props.history,
          sourceMenu,
          reqObj
        )
      );
    }
    if (sourceMenu === 18) {
      dispatch(
        UploadInvoiceFiles(
          serverApi.UPLOADFILE,
          formData,
          props.history,
          sourceMenu,
          reqObj
        )
      );
    }
    if (sourceMenu === 3) {
      dispatch(
        UploadInvoiceFiles(
          serverApi.UPLOADFILE,
          formData,
          props.history,
          sourceMenu,
          reqObj
        )
      );
    }
  };

  const onhandleChangeDropdown = (selectedValue, selectedName, name) => {
    if (name === "TcsPercentage") {
      setHeaderData((prev) => ({
        ...prev,
        tcsPercentage: selectedValue,
      }));
      const UpdatedRec = {
        ...HeaderData,
        tcsPercentage: selectedValue,
      };
      dispatch(getJuteBillPassHeader(UpdatedRec));
    }
    if (name === "tdsPayable") {
      if (selectedName === "Yes") {
        setTdsPayable(selectedName);
        setTdsfields(true);
        HeaderData.tdsPayable = selectedValue;
      } else {
        setTdsPayable(selectedName);
        setTdsfields(false);
        setTdsReason("");
        setTdsAmount(0);
        HeaderData.tdsPayable = selectedValue;
        HeaderData.tdsReason = "";
        HeaderData.tdsAmount = 0;
      }
    }
  };

  const handleSelect_Date = (e, name) => {
    if (name === "paymentDueDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev)=>({
        ...prev,
        paymentDueDate : Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        paymentDueDate: Date,
      };
      dispatch(getJuteBillPassHeader(UpdatedRec));
    }
    if (name === "invoiceDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev)=>({
        ...prev,
        invoiceDate : Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        invoiceDate: Date,
      };
      dispatch(getJuteBillPassHeader(UpdatedRec));
    }
    if (name === "invoiceRecievedDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev)=>({
        ...prev,
        invoiceRecievedDate : Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        invoiceRecievedDate: Date,
      };
      dispatch(getJuteBillPassHeader(UpdatedRec));
    }
  };

  const handlePropSelectChange = (
    selectedValue,
    selectedName,
    name,
    row,
    selectedlable,
    selectedRow
  ) => {
    var lastRow = LineItems[LineItems.length - 1];
    if (name === "itemId") {
      if (selectedRow === lastRow) {
        let LineItem = LineItems;
        let obj = {
          id: LineItems.length + 1,
          materialGrnHdrId: 0,
          itemId: "",
          quantity: 0,
          quantityUnit: "",
          advisedQuality: 0,
          actualQuality: 0,
          advisedWeight: 0,
          actualWeight: 0,
          deviation: 0,
          rate: 0,
          claimsCondition: "0.0",
          status: "",
          warehouseNo: 0,
          remarks: null,
          totalPrice: 0,
          totalPriceWithTax: null,
          debitNotesFlag: null,
          bale: null,
          loose: 0,
          actualBale: null,
          actualLoose: 0,
          unitConversion: null,
          advisedItemId: "",
          wareHouseName: null,
          autoDateTimeInsert: "",
          createdBy: "",
          isActive: 1,
          advisedQuantity: 1000,
          shortageKgs: 0,
          claimDust: 0,
          claimQuality: 0,
          allowableMoisture: 0,
          premiumAmount: "",
          waterDamageAmount: "",
          juteGateEntryLineItemNo: 0,
          variableShortage: 0,
          acceptedWeight: 0,
          agentMRRate: null,
          stockQuantityConsumed: 0,
          drumConsumed: 0,
          balesConsumed: 0,
          companyId: 1,
          srcLineId: null,
          srcCompId: null,
          cropYear: null,
          marka: null,
          pota: null,
          salesBale: 0,
          salesDrum: 0,
          salesWeight: 0,
          juteIssueRate: 0,
          claimRate: "",
          srcMrNo: null,
          srcMrLineId: null,
          convertedQuality: null,
          convertedJuteType: null,
          percentage: null,
          srcMrPrintNo: null,
          convertedBale: 0,
          convertedDrum: 0,
          convertedWeight: 0,
          jcStock: 0,
          itemDesc: "",
          qualityDesc: "",
          conItemDesc: null,
          conQltyDesc: null,
          VariableShortage: 0,
          qualityByJuteList: [],
          amount: "",
        };
        LineItem.push(obj);
        setLineItems(LineItem);
        dispatch(getBillPassLineItems(LineItem));
      }
    }

    if (name === "itemId") {
      let LineItemdata = LineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          var Value = selectedName.split("^");
          row.itemId = selectedValue;
          row.dtlItemDesc = Value[0];
          row.itemGroupId = Value[1];
          row.unitId = Value[2];
        }
        return row;
      });
      setLineItems(LineItemdata);
      dispatch(getBillPassLineItems(LineItemdata));
      dispatch(
        QualityByJuteType(
          serverApi.QUALITY_BY_JUTE_TYPE +
            selectedValue +
            "/" +
            localStorage.getItem("companyId") +
            "/getQualityByItem",
          selectedRow.id
        )
      );
      displayField(selectedRow, "jute", 2);
    }

    if (name === "quality") {
      let LineItemdata = LineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          var qualityName = selectedName.split("^^");
          row.qualityCode = selectedValue;
          row.qualityCodeDesc = qualityName[0];
          row.stock = qualityName[1];
        }
        return row;
      });
      setLineItems(LineItemdata);
      dispatch(getBillPassLineItems(LineItemdata));
      displayField(selectedRow, "quality", 2);
    }
  };
  let billpasslineItem = LineItems.filter((item) => item.isActive !== 0);
  return (
    <div className="editableTableContainer">
      <div className="gridContainer">
        <div className="gridComponent">
          <table cellSpacing="0px" cellPadding="0px">
            <thead>
              <tr className="tableRowBlock">
                {tableHeaders.map((column) => (
                  <>
                    {column.header === "Item Group" ||
                    column.header === "Item Name" ||
                    column.header === "Rate" ||
                    column.header === "Quantity" ||
                    column.header === "UOM" ||
                    column.header === "UOM2" ||
                    column.header === "RateUOM" ? (
                      <>
                        <th>
                          {column.header}
                          <label style={{ color: "red" }}>*</label>
                        </th>
                      </>
                    ) : (
                      <>
                        <th>{column.header}</th>
                      </>
                    )}
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {billpasslineItem.length !== 0 &&
                billpasslineItem.map((row, i) => {
                  let rowIndex = billpasslineItem.findIndex(
                    (idd) => idd == row
                  );

                  return (
                    <>
                      <tr>
                        <>
                          {RowData &&
                            RowData.map((col) =>
                              col.component === "Editablecell" ? (
                                <>
                                  <td>
                                    <div className="quantityDropdownBlock">
                                      <Grid className="selectAndTextfield">
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <input
                                            onWheel={(e) => e.target.blur()}
                                            type={
                                              col.type === "rate"
                                                ? "number"
                                                : col.type === "quantity"
                                                ? "number"
                                                : col.type === "claimRate"
                                                ? "number"
                                                : "text"
                                            }
                                            name={col.type + "_" + rowIndex}
                                            disabled={
                                              col.type === "rate"
                                                ? true
                                                : col.type === "uom"
                                                ? true
                                                : col.type === "claimRate"
                                                ? true
                                                : col.type === "warehouseName"
                                                ? true
                                                : col.type === "amount"
                                                ? true
                                                : col.type ===
                                                  "waterDamageAmount"
                                                ? true
                                                : col.type === "premiumAmount"
                                                ? true
                                                : false
                                            }
                                            value={
                                              col.type === "rate"
                                                ? parseFloat(row.rate).toFixed(
                                                    2
                                                  )
                                                : col.type === "quantity"
                                                ? parseFloat(
                                                    row.quantity
                                                  ).toFixed(2)
                                                : col.type === "warehouseName"
                                                ? row.warehouseName
                                                : col.type === "claimRate"
                                                ? parseFloat(
                                                    row.claimRate
                                                  ).toFixed(2)
                                                : col.type === "weight"
                                                ? row.weight
                                                : col.type === "uom"
                                                ? row.uom
                                                : col.type ===
                                                  "waterDamageAmount"
                                                ? parseFloat(
                                                    row.waterDamageAmount
                                                  ).toFixed(2)
                                                : col.type === "premiumAmount" //netAmount
                                                ? parseFloat(
                                                    row.premiumAmount
                                                  ).toFixed(2)
                                                : col.type === "amount"
                                                ? parseFloat(
                                                    row.amount
                                                  ).toFixed(2)
                                                : ""
                                            }
                                            onChange={OnhandleChange({
                                              key: col.type + "_" + rowIndex,
                                              row,
                                            })}
                                            className="inputBlockContainer"
                                            style={{
                                              backgroundColor:
                                                col.type === "rate"
                                                  ? "#ccd6db"
                                                  : col.type === "uom"
                                                  ? "#ccd6db"
                                                  : col.type === "quantity"
                                                  ? "#ccd6db"
                                                  : col.type === "warehouseName"
                                                  ? "#ccd6db"
                                                  : col.type === "claimRate"
                                                  ? "#ccd6db"
                                                  : col.type === "weight"
                                                  ? "#ccd6db"
                                                  : col.type === "amount"
                                                  ? "#ccd6db"
                                                  : col.type ===
                                                    "waterDamageAmount"
                                                  ? "#ccd6db"
                                                  : col.type === "premiumAmount"
                                                  ? "#ccd6db"
                                                  : "",
                                              textAlign:
                                                col.type !== "remarks" &&
                                                col.type !== "hsncode" &&
                                                col.type !== "uom" &&
                                                col.type !== "discountMode"
                                                  ? "right"
                                                  : "",
                                              paddingRight: "8px",
                                            }}
                                          />
                                          <ReactTooltip
                                            effect={"solid"}
                                            html={true}
                                            place={"BottomCenter"}
                                            className="tooltipClassBorder"
                                            backgroundColor="rgba(0,127,173,0.5)"
                                            textColor="#FFF"
                                            border={true}
                                            borderColor="#04749c"
                                            scrollHide={true}
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </td>
                                </>
                              ) : col.component === "DynamicSelect" ? (
                                <td>
                                  <div className="quantityDropdownBlock">
                                    <Grid className="selectAndTextfield">
                                      <Grid
                                        xs={12}
                                        md={12}
                                        className="dynamicSelectBlock"
                                        style={{
                                          display: LineItemsDisplayTxtField[
                                            col.type + row.id + "1"
                                          ]
                                            ? "none"
                                            : "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          className="inputBlockContainer"
                                          style={{
                                            paddingRight: "8px",
                                            backgroundColor:
                                              col.type === "itemDesc"
                                                ? "#ccd6db"
                                                : col.type === "qualityDesc"
                                                ? "#ccd6db"
                                                : "",
                                          }}
                                          value={
                                            col.type === "itemDesc"
                                              ? row.itemDesc
                                              : col.type === "qualityDesc"
                                              ? row.qualityDesc
                                              : ""
                                          }
                                          onChange={OnhandleChange({
                                            key: col.type + "_" + rowIndex,
                                            row,
                                          })}
                                          onClick={() => {
                                            displayField(row, col.type, 1);
                                          }}
                                          disabled={
                                            col.type === "itemDesc"
                                              ? true
                                              : col.type === "qualityDesc"
                                              ? true
                                              : false
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        xs={12}
                                        md={12}
                                        className="dynamicSelectBlock"
                                        style={{
                                          display: LineItemsDisplayDropField[
                                            col.type + row.id + "2"
                                          ]
                                            ? "flex"
                                            : "none",
                                          alignItems: "center",
                                        }}
                                      >
                                        <DynamicSelect
                                          arrayOfData={
                                            col.type === "itemDesc"
                                              ? juteTypeList
                                              : col.type === "Quality"
                                              ? row.qualityByJuteList
                                              : ""
                                          }
                                          className="dropdownBlockContainer"
                                          onSelectChange={
                                            handlePropSelectChange
                                          }
                                          selected={
                                            col.type === "itemId"
                                              ? row.itemGroupId
                                              : col.type === "qualityDesc"
                                              ? row.itemId
                                              : ""
                                          }
                                          name={
                                            col.type === "itemId"
                                              ? "itemId"
                                              : col.type === "Quality"
                                              ? "Quality"
                                              : ""
                                          }
                                          row={row}
                                          update={
                                            col.type === "itemId"
                                              ? row.itemId
                                              : col.type === "Quality"
                                              ? row.Quality
                                              : ""
                                          }
                                          isDropdownOpen={isDropdownOpen}
                                          handleMenuOpen={handleMenuOpen}
                                        />
                                      </Grid>
                                    </Grid>
                                  </div>
                                </td>
                              ) : (
                                ""
                              )
                            )}
                        </>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="consoleFormContainer">
        <div style={{ height: "15px" }}></div>
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 12">
                  <div
                    className="calculationBlockTable"
                    style={{ display: "flex", justifyContent: "end" }} //amount
                  >
                    <table>
                      <tr>
                        <td> Total Amount :</td>
                        <b>{parseFloat(HeaderData.totalAmount).toFixed(2)}</b>
                      </tr>
                      <tr>
                        <td>Claim Amount :</td>
                        <b>{parseFloat(HeaderData.claimAmount).toFixed(2)}</b>
                      </tr>
                      <tr>
                        <td>TCS Amount :</td>
                        <b>{parseFloat(HeaderData.tcsAmount).toFixed(2)}</b>
                      </tr>
                      <tr>
                        <td>TDS Amount :</td>
                        <b>
                          {parseFloat(Number(HeaderData.tdsAmount)).toFixed(2)}
                        </b>
                      </tr>
                      <tr>
                        <td>Net Total :</td>
                        <b>{parseFloat(HeaderData.netAmount).toFixed(2)}</b>
                      </tr>
                      <tr>
                        <td>Freight Charges :</td>
                        <b>
                          {parseFloat(HeaderData.frieghtCharges).toFixed(2)}
                        </b>
                      </tr>
                    </table>
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>

      {locationState.state && (
        <>
          <div className="consoleFormContainer">
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock" style={{ display: "flex" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    className="ducumentUploadBlock"
                    gap={2}
                  >
                    <Box gridColumn="span 8">
                      {invoicedocsListData &&
                        invoicedocsListData.map((item) => (
                          <>
                            <div>
                              <div
                                className="uploadedInfo"
                                style={{ display: "flex", marginTop: "10px" }}
                              >
                                <img
                                  src={TickIcon}
                                  alt=""
                                  className="tickIcon"
                                  height={"20px"}
                                  width={"20px"}
                                />
                                <Tooltip
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 600 }}
                                  title="Click to download File"
                                >
                                  <div
                                    onClick={() => {
                                      getFileDownload(
                                        serverConfig.SERVER_URL +
                                          "security-api/api/files/downloadfile/" +
                                          item.fileUploadId,
                                        item.fileName,
                                        item.fileExtension
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {item.fileName}
                                  </div>
                                </Tooltip>
                                <Tooltip
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 100 }}
                                  title="Click to Delete File"
                                >
                                  <Button
                                    onClick={() => {
                                      handleDeleteFile(
                                        item.fileUploadId,
                                        "invoices"
                                      );
                                    }}
                                    className="deleteButton"
                                  >
                                    <img
                                      src={DeleteIcons}
                                      alt=""
                                      height={"20px"}
                                      width={"20px"}
                                    />
                                  </Button>
                                </Tooltip>
                              </div>
                            </div>
                          </>
                        ))}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 8">
                      <div>
                        Invoices<span style={{ color: "red" }}>*</span>:
                      </div>
                      <div className="personalDetailsUpload">
                        <div className="ducumentUploadBlock">
                          <div className="documentUploadContent">
                            <span className="btn_upload documentUpload">
                              <img src={panUploadIcon} alt="" />
                              <input
                                type="file"
                                id="imag"
                                title=""
                                className="input-img"
                                onChange={UploadDocuments("invoices")}
                              />
                              Upload Document
                            </span>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="consoleFormContainer">
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock" style={{ display: "flex" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    className="ducumentUploadBlock"
                    gap={2}
                  >
                    <Box gridColumn="span 8">
                      {challanFilesListData &&
                        challanFilesListData.map((item) => (
                          <>
                            <div>
                              <div
                                className="uploadedInfo"
                                style={{ display: "flex" }}
                              >
                                <img
                                  src={TickIcon}
                                  alt=""
                                  className="tickIcon"
                                  height={"20px"}
                                  width={"20px"}
                                />
                                <Tooltip
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 600 }}
                                  title="Click to download File"
                                >
                                  <div
                                    onClick={() => {
                                      getFileDownload(
                                        serverConfig.SERVER_URL +
                                          "security-api/api/files/downloadfile/" +
                                          item.fileUploadId,
                                        item.fileName,
                                        item.fileExtension
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {item.fileName}
                                  </div>
                                </Tooltip>
                                <Tooltip
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 100 }}
                                  title="Click to Delete File"
                                >
                                  <Button
                                    onClick={() => {
                                      handleDeleteFile(
                                        item.fileUploadId,
                                        "Challan"
                                      );
                                    }}
                                    className="deleteButton"
                                  >
                                    <img
                                      src={DeleteIcons}
                                      alt=""
                                      height={"20px"}
                                      width={"20px"}
                                    />
                                  </Button>
                                </Tooltip>
                              </div>
                            </div>
                          </>
                        ))}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 8">
                      <div>Challan:</div>
                      <div className="personalDetailsUpload">
                        <div className="ducumentUploadBlock">
                          <div className="documentUploadContent">
                            <span className="btn_upload documentUpload">
                              <img src={panUploadIcon} alt="" />
                              <input
                                type="file"
                                id="imag"
                                title=""
                                className="input-img"
                                onChange={UploadDocuments("Challan")}
                              />
                              Upload Document
                            </span>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="consoleFormContainer">
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock" style={{ display: "flex" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    className="ducumentUploadBlock"
                    gap={2}
                  >
                    <Box gridColumn="span 8">
                      {supportDocsListData &&
                        supportDocsListData.map((item) => (
                          <>
                            <div>
                              <div
                                className="uploadedInfo"
                                style={{ display: "flex" }}
                              >
                                <img
                                  src={TickIcon}
                                  alt=""
                                  className="tickIcon"
                                  height={"20px"}
                                  width={"20px"}
                                />
                                <Tooltip
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 600 }}
                                  title="Click to download File"
                                >
                                  <div
                                    onClick={() => {
                                      getFileDownload(
                                        serverConfig.SERVER_URL +
                                          "security-api/api/files/downloadfile/" +
                                          item.fileUploadId,
                                        item.fileName,
                                        item.fileExtension
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {item.fileName}
                                  </div>
                                </Tooltip>
                                <Tooltip
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 100 }}
                                  title="Click to Delete File"
                                >
                                  <Button
                                    onClick={() => {
                                      handleDeleteFile(
                                        item.fileUploadId,
                                        "suppdocs"
                                      );
                                    }}
                                    className="deleteButton"
                                  >
                                    <img
                                      src={DeleteIcons}
                                      alt=""
                                      height={"20px"}
                                      width={"20px"}
                                    />
                                  </Button>
                                </Tooltip>
                              </div>
                            </div>
                          </>
                        ))}
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 8">
                      <div>Support Documents:</div>
                      <div className="personalDetailsUpload">
                        <div className="ducumentUploadBlock">
                          <div className="documentUploadContent">
                            <span className="btn_upload documentUpload">
                              <img src={panUploadIcon} alt="" />
                              <input
                                type="file"
                                id="imag"
                                title=""
                                className="input-img"
                                onChange={UploadDocuments("SuppDocs")}
                              />
                              Upload Document
                            </span>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </>
      )}

      <div className="consoleFormContainer">
        <div style={{ height: "15px" }}></div>
        <div>Vendor Invoice Details :</div>
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <TextFieldReq
                    label="Invoice No"
                    value={HeaderData.invoiceNo}
                    onChange={onhandleChange("invoiceNo")}
                  />
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>
                          Invoice Date
                          <label className="starcolor">*</label>
                        </label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          name="financial_year"
                          id="financial_year"
                          inputFormat="dd-MM-yyyy"
                          value={HeaderData.invoiceDate}
                          fullWidth
                          onChange={(e) => handleSelect_Date(e, "invoiceDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>Payment Due Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          value={HeaderData.paymentDueDate}
                          fullWidth
                          onChange={(e) =>
                            handleSelect_Date(e, "paymentDueDate")
                          }
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <TextFieldReq
                    label="Invoice Amount"
                    value={HeaderData.invoiceAmount}
                    onChange={onhandleChange("invoiceAmount")}
                  />
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>
                          Invoice Received Date
                          <label className="starcolor">*</label>
                        </label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          value={HeaderData.invoiceRecievedDate}
                          fullWidth
                          onChange={(e) =>
                            handleSelect_Date(e, "invoiceRecievedDate")
                          }
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <TextFieldNormal
                    label="Ledger"
                    placeholder="Enter here"
                    value={HeaderData.ledgerName}
                    disabled={true}
                  />
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={TCSPercentages}
                      className="dropdownBlockContainer"
                      onSelectChange={onhandleChangeDropdown}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.tcsPercentage}
                      name={"TcsPercentage"}
                      label="TCS Percentage"
                      update={HeaderData.tcsPercentage ? 1 : 0}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={TCSPayable}
                      className="dropdownBlockContainer"
                      onSelectChange={onhandleChangeDropdown}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.tdsPayable}
                      name={"tdsPayable"}
                      label="TDS Payable"
                      update={HeaderData.tdsPayable ? 1 : 0}
                      required
                    />
                  </div>
                </Box>
                {HeaderData.tdsPayable === "Y" && (
                  <>
                    <Box gridColumn="span 3">
                      <TextFieldReq
                        label="TDS Amount"
                        placeholder="Enter here"
                        value={HeaderData.tdsAmount}
                        onChange={onhandleChange("tdsAmount")}
                      />
                    </Box>
                    <Box gridColumn="span 3">
                      <TextFieldNormal
                        label="TDS Reason"
                        placeholder="Enter here"
                        value={HeaderData.tdsReason}
                        onChange={onhandleChange("tdsReason")}
                      />
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
const TCSPayable = [
  { label: "Yes", value: "Y", name: "Yes" },
  { label: "No", value: "N", name: "No" },
];

const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "itemDesc",
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "qualityDesc",
  },
  {
    no: 3,
    component: "Editablecell",
    type: "quantity",
  },
  {
    no: 4,
    component: "Editablecell",
    type: "claimRate",
  },
  {
    no: 5,
    component: "Editablecell",
    type: "weight",
  },
  {
    no: 6,
    component: "Editablecell",
    type: "uom",
  },
  {
    no: 7,
    component: "Editablecell",
    type: "warehouseName",
  },
  {
    no: 8,
    component: "Editablecell",
    type: "rate",
  },
  {
    no: 9,
    component: "Editablecell",
    type: "amount",
  },
  {
    no: 10,
    component: "Editablecell",
    type: "premiumAmount",
  },
  {
    no: 11,
    component: "Editablecell",
    type: "waterDamageAmount",
  },
];

const tableHeaders = [
  {
    header: "Item Desc",
  },
  {
    header: "Quality",
  },
  {
    header: "Quantity",
  },
  {
    header: "Claim Rate",
  },
  {
    header: "Weight",
  },
  {
    header: "UOM",
  },
  {
    header: "Wharehouse No",
  },
  {
    header: "Rate",
  },
  {
    header: "Amount",
  },
  {
    header: "Prem Amt",
  },
  {
    header: "Water Dmg Amt",
  },
];

export default JuteBillPassLineItem;
