import {
  CUTS_FROM_CUTS,
  CUTS_FROM_CUTS_SUCCESSFULL,
  WEAVING_LIST,
  WEAVING_LIST_SUCCESSFULL,
  WEAVING_PRODUCTION_ADD,
  WEAVING_PRODUCTION_ADD_SUCCESSFULL,
  WEAVING_PRODUCTION_DELETE,
  WEAVING_PRODUCTION_DELETE_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  weavinProductionLists: [],
  CutsListArray: [],
  WeavingProductionArray:[],
  deleteWeaving:[]
};

const WeavingProductionListReducer = (state = initialState, action) => {
  switch (action.type) {
    case WEAVING_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case WEAVING_LIST_SUCCESSFULL:
      state = {
        ...state,
        weavinProductionLists: action.payload.response,
        loading: true,
      };
      break;
    case CUTS_FROM_CUTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CUTS_FROM_CUTS_SUCCESSFULL:
      state = {
        ...state,
        CutsListArray: action.payload.response,
        loading: true,
      };
      break;
      case WEAVING_PRODUCTION_ADD:
      state = {
        ...state,
        loading: true,
      };
      break;
      case WEAVING_PRODUCTION_ADD_SUCCESSFULL:
      state = {
        ...state,
        WeavingProductionArray: action.payload.response,
        loading: true,
      };
      break;
      case WEAVING_PRODUCTION_DELETE:
      state = {
        ...state,
        loading: true,
      };
      break;
      case WEAVING_PRODUCTION_DELETE_SUCCESSFULL:
      state = {
        ...state,
        deleteWeaving: action.payload.response,
        loading: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default WeavingProductionListReducer;
