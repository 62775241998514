import {TAX_MASTER_LIST,TAX_MASTER_LIST_SUCCESSFULL,API_ERRORS} from './actionTypes';



export const getTaxMasterList = (url,data,history) =>{
    return {
        type:TAX_MASTER_LIST,
        payload:{url,data,history}
    }
}

export const TaxMasterListSuccessfull = (data)=>{
    return {
        type:TAX_MASTER_LIST_SUCCESSFULL,
        payload:data
    }
}

export const apiErrors = (error) => {
    return {
      type: API_ERRORS,
      payload: error,
    };
  };

