import React from 'react'
import { Multiselect } from "multiselect-react-dropdown";



export default class AutoMultiselect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    
  }
  onSelect = (selectedList, selectedItem) => {
    this.props.getOpt(selectedList, this.props.name);
  };



  onRemove = (selectedList, removedItem) => {
    this.props.getOpt(selectedList, this.props.name);
  };

  render() {
    return (
      <div className="autoCompleteDropdownContainer" style={{ width: '100%' }}>
        <label>{this.props.label}<span>{this.props.mandatory}</span></label>
          <Multiselect
              options={this.props.options} // Options to display in the dropdown
              displayValue="label" // Property name to display in the dropdown options
              showCheckbox={true}
              disable={this.props.disable}
              selectedValues={this.props.selectedValues} // Preselected value to persist in dropdown
              onSelect={this.onSelect} // Function will trigger on select event
              onRemove={this.onRemove} 
              // Function will trigger on remove event
          />
        
        
      </div>
    )
  }
}

