import { React, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import {
  getBranchList,
  getMukamList,
} from "../../../store/Global/DropDownApis/actions";
import { serverApi } from "../../../helpers/Consts";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getJuteIndentHeader } from "../../../store/Jute/JuteIndent/actions";
import InputAdornment from "../../../components/InputAdorment/InputAdorment";
import { useLocation } from "react-router-dom";

const FillDetails = (props) => {
  const [branchListData, setBranchListData] = useState([]);
  const [mukamListData, setMukamList] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState();
  const [HeaderData, setHeaderData] = useState({});
  const [LineItems, setLineItems] = useState([]);
  const [locationState] = useState(useLocation());

  const dispatch = useDispatch();

  const { branchList, mukamList } = useSelector(
    (state) => state.DropDownListReducer
  );
  const { JuteIndentHeader, JuteindentLineItems } = useSelector(
    (state) => state.JuteIndentReducer
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    dispatch(
      getBranchList(
        serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
        props.history
      )
    );
    dispatch(
      getMukamList(
        serverApi.MUKAM_GET_LIST + localStorage.getItem("companyId"),
        props.history
      )
    );

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (branchList) {
      if (branchList.data) {
        if (branchList.data.length !== 0) {
          let List = branchList.data.filter((item) => item.value !== 0);
          setBranchListData(List);
        }
      }
    }
    if (mukamList) {
      if (mukamList.data) {
        if (mukamList.data.length !== 0) {
          let List = mukamList.data.filter((item) => item.value !== 0);
          setMukamList(List);
        }
      }
    }
  }, [branchList, mukamList]);

  useEffect(() => {
    setLineItems(JuteindentLineItems);
    setHeaderData(JuteIndentHeader);
  }, [JuteIndentHeader, JuteindentLineItems]);

  const handlePropSelectChange = (
    selectedValue,
    selectedName,
    name,
    row,
    selectedlable
  ) => {
    if (name === "unitConversion") {
      var  convertedQuantity=""
      if(selectedName=="LOOSE"){
        convertedQuantity= Math.round(Number(HeaderData.indentWeight)* 48)
      }else{
        convertedQuantity= Math.round((Number(HeaderData.indentWeight )* 100) / 150);
      }
      setHeaderData((prev) => ({
        ...prev,
        unitConversion: selectedName,
        convertedQuantity:convertedQuantity
      }));
      const UpdatedRec = {
        ...HeaderData,
        unitConversion: selectedName,
        convertedQuantity:convertedQuantity
      };
      dispatch(getJuteIndentHeader(UpdatedRec));
    }
    if (name === "mukam") {
      setHeaderData((prev) => ({
        ...prev,
        mukam: selectedValue,
        mukamName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        mukam: selectedValue,
        mukamName: selectedName,
      };
      dispatch(getJuteIndentHeader(UpdatedRec));
    }

    if (name === "branchId") {
      setHeaderData((prev) => ({
        ...prev,
        branchId: selectedValue,
        branchName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        branchId: selectedValue,
        branchName: selectedName,
      };
      dispatch(getJuteIndentHeader(UpdatedRec));
    }
  };

  const handleSelectDate = (e, name) => {
    if (name === "indentDate") {
      let date = moment(e).format();
      let Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        indentDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        indentDate: Date,
      };
      dispatch(getJuteIndentHeader(UpdatedRec));
    }
  };

  const handleMenuOpen = () => {
    setIsDropdownOpen(false);
  };

  const handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    setIsDropdownOpen(true);
  };

  const onhandlechange = (key) => (name,event) => {
  
    var convertedQuantity =""
    var vehicleTypeId=""
    var vehicleQuantity=""
    if (key === "indentWeight") {
    if(HeaderData.unitConversion=="BALE"){
      convertedQuantity= Math.round((Number(event )* 100) / 150);
    }else{
      convertedQuantity= Math.round(Number(event )* 48)
    }
    if (event > 0 && event <= 100) {
        vehicleTypeId= 3
        vehicleQuantity = 1
    } else if (event > 100 && event <= 120) {
     
        vehicleTypeId= 2
        vehicleQuantity=1
   
    } else if (event > 120 && event <= 160) {
  
        vehicleTypeId= 1
        vehicleQuantity= 1
   
    } else if (event > 160 && event <= 200) {
    
        vehicleTypeId= 4
        vehicleQuantity= 1
  
    } else if (event > 200) {
      
        vehicleTypeId= 4
        vehicleQuantity=Math.round(event / 200)
  
    } else if (event === "") {
        vehicleTypeId= ""
        vehicleQuantity= ""
    }

      setHeaderData((prev) => ({
        ...prev,
        indentWeight:event,
        vehicleQuantity: vehicleQuantity,
        quantityInTon:Number(event)/10,
        convertedQuantity:convertedQuantity,
        vehicleTypeId:vehicleTypeId
      }));
      const UpdatedRec = {
        ...HeaderData,
        indentWeight:event,
        vehicleQuantity: vehicleQuantity,
        quantityInTon:Number(event)/10,
        convertedQuantity:convertedQuantity,
        vehicleTypeId:vehicleTypeId
      };
      dispatch(getJuteIndentHeader(UpdatedRec));
    }
  }

  const inputStyle = {
    backgroundColor: '#e0e0e0', // Light grey background
    border: '2px solid #ff0000', // Red border
    color: '#000000',            // Black text color
    padding: '10px',             // Padding inside the input
    fontStyle: 'italic',         // Italic text
  };

  return (
    <>
      <div className="juteIndent">
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div>
                      <div className="TextFieldBlock">
                        <label>Date <span className="starcolor">*</span></label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          value={HeaderData.indentDate}
                          fullWidth
                          onChange={(e) => handleSelectDate(e, "indentDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectNew
                        arrayOfData={UnitConversion}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.unitConversion}
                        name="unitConversion"
                        label="Unit Convertion"
                        isDisabled={locationState.state !== undefined? true:false}
                        required
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={mukamListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.mukam}
                        name="mukam"
                        label="Mukam"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.branchId}
                        update={HeaderData.branchId ? 1 : 0}
                        name="branchId"
                        label="Branch"
                        required
                      />
                    </div>
                  </Box>
                      <Box gridColumn="span 3">
                    <div className="TextFieldBlock">
                      <label>
                      Indent Quantity <span className="starcolor">*</span>
                      </label>
                    </div>
                    <InputAdornment
                      label="QTL"
                      type="number"
                      name="indentWeight"
                      handleChange={onhandlechange("indentWeight")}
                      value={HeaderData.indentWeight}
                    />
                  </Box>
                       <Box gridColumn="span 3">
                    <div className="TextFieldBlock">
                      <label>
                      .
                      </label>
                    </div>
                    <InputAdornment
                      label="Ton"
                      type="number"
                      name="quantityInTon"
                      handleChange={onhandlechange("quantityInTon")}
                      value={HeaderData.quantityInTon}
                      disabled={true}
                    />
                  </Box>
                         <Box gridColumn="span 3">
                    <div className="TextFieldBlock">
                      <label>
                      Converted Quantity
                      </label>
                    </div>
                    <InputAdornment
                      label={HeaderData.unitConversion === "LOOSE" ? "KGS" : HeaderData.unitConversion}
                      type="number"
                      name="convertedQuantity"
                      handleChange={onhandlechange("convertedQuantity")}
                      value={HeaderData.convertedQuantity}
                      disabled={true}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

const UnitConversion = [
  {
    value: 1,
    label: "LOOSE",
    name: "LOOSE",
  },
  {
    value: 2,
    label: "BALE",
    name: "BALE",
  },
];

export default FillDetails;
