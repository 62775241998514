import {
JUTE_GATE_ENTRY_LIST,
JUTE_GATE_ENTRY_LIST_SUCCESSFULL,
JUTE_GATE_ENTRY_LINE_ITEMS,
JUTE_GATE_ENTRY_VIEW_BY_ID,
JUTE_GATE_ENTRY_VIEW_BY_ID_SCCESSFULL,
JUTE_GATE_ENTRY_CREATE,
JUTE_GATE_ENTRY_HEADER,
SET_ENTRY_HEADER_LINEITEMS,
CLEAR_JUTE_GATE_ENTRY_HEADER,
CLEAR_JUTE_GATE_ENTRY_LINE_ITEMS,
GET_QUALITY_BY_ITEM,
GET_QUALITY_BY_ITEM_SUCCESSFULL,
GET_ALL_APPROVED_PO,
GET_ALL_APPROVED_PO_SUCCESSFULL,
JUTE_PO_VIEW_BY_ID_FOR_GATE_ENTRY,
JUTE_PO_VIEW_BY_ID_FOR_GATE_ENTRY_SCCESSFULL,
JUTE_GATE_ENTRY_UPLOAD_FILE,
JUTE_GATE_ENTRY_UPLOAD_FILE_SUCCESSFULL,
JUTE_GATE_ENTRY_GET_ALL_FILES,
JUTE_GATE_ENTRY_GET_ALL_FILES_SUCCESSFULL,
JUTE_GATE_ENTRY_DELETE_FILE,
JUTE_GATE_ENTRY_DELETE_FILE_SUCCESSFULL
} from "./actionTypes";
import moment from "moment";
const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  GateEntryViewByIdData:[],
  JuteGateEntryData: [],
  JuteQualityByItem:[],
  lineItems:[],
  headerData:{},
  GetAllApprovedPo:[],
  JutePoViewByIdForGateEntry:[],
  GateEntryUploadDocuments:[],
  JuteGateEntryFiles:[],
  GateEntryDeleteDocuments:[],
  JuteGateEntrylineItems:[
    {
      "id":0,
      "recId": "",
      "hdrId": "",
      "quantity":"",
      "advisedJuteTyp": "",
      "actualJuteTyp": "",
      "advisedQuality": "",
      "actualQuality": "",
      "advisedQuantity": "",
      "actualQuantity": "",
      "receivedIn": "",
      "autoDateTime": "",
      "unitId": "",
      "remarks": null,
      "kgs": null,
      "poLineItemNum": "",
      "createdBy": "",
      "isActive": "",
      "qcJuteType": "",
      "qcJuteQuality": "",
      "qcJuteQuantity": "",
      "advisedWeight": null,
      "actualWeight": "",
      "qcJuteWeight": "",
      "allowableMoisture": null,
      "readings": [],
      "actualQualityDesc": "",
      "advisedQualityDesc": "",
      "itemDesc": "",
      "itemId":"",
      "qualityCode":"",
      "qualityCodeName":"",
      "advisedItemDesc": "",
      "avgReadings": "",
      "qcJuteTypeDesc": "",
      "qcJuteQualityDesc": "",
      "unitId":"",
      "itemCode":"",
      QuantityListData:[]
    },        
  ],
  JuteGateEntryHeader:{
    "id": "",
    "chalanNo": "",
    "chalanDate": moment().format("YYYY-MM-DD"),
    "vehicleNo": "",
    "driverName": "",
    "brokerName": null,
    "transporter": "",
    "brokerAddress": null,
    "inDate": moment().format("YYYY-MM-DD"),
    "updateBy": null,
    "inTime":null,
    "poDate":null,
    // "inTime": moment().format("hh:mm A"),
    "outTime": null,
    "outDate": "",
    "updateDateTime": "",
    "finYear": "",
    "mukam": "",
    "mukamName":"",
    "poNo": "",
    "netWeight": null,
    "grossWeight": "",
    "grossWeightQtl":"",
    "actualWeight": "",
    "actualWeightQtl":"",
    "mrNo": "",
    "suppCode": "",
    "suppName":"",
    "supplierName": "",
    "challanWeight": "",
    "challanWeightQtl":"",
    "vehicleType": "",
    "qcCheck": "",
    "createdBy": "",
    "tareWeight": "",
    "tareWeightQtl": "",
    "remarks": "",
    "withorWithOutPo": "",
    "companyId": "",
    "unitConversion": "",
    "status": "",
    "entryCompSeq": "",
    "consignmentDate": null,
    "consignmentNo": null,
    "branchId": "",
    "branchName":"",
    "poHdrId": null,
    "challanDateDesc": "",
    "createdDate": "",
    "createdTime": "",
    "outTimeDesc": "",
    "outDateDesc": "",
    "statusDesc": "",
    "consignmentDateDesc": null,
    "indentHdrId": null,
    "source":"",
    "JuteGateEntryType":"",
    "supplierId":"",
     OpenButton : false,
     OutButton:false,
     UpdateButton : true,
     EditButton : true
  }
};

const JuteGateEntryReducer = (state = initialState, action) => {
  switch (action.type) {
    case JUTE_GATE_ENTRY_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTE_GATE_ENTRY_LIST_SUCCESSFULL:
      state = {
        ...state,
        JuteGateEntryData: action.payload.response,
        loading: true,
      };
      break;
      case SET_ENTRY_HEADER_LINEITEMS:
      state = {
        ...state, lineItems: action.payload.lineItems, headerData: action.payload.entryHeader
      }
      break;
      case JUTE_GATE_ENTRY_VIEW_BY_ID:
        state = {
          ...state,
          loading: true,
        };
        break;
        case JUTE_GATE_ENTRY_CREATE: 
        state = {
          ...state,
          loading: true              
        }
        break;
      case JUTE_GATE_ENTRY_VIEW_BY_ID_SCCESSFULL:
        state = {
          ...state,
          GateEntryViewByIdData: action.payload.response.data,
          loading: false,
        };
        break;
        case GET_QUALITY_BY_ITEM:
          state = {
            ...state,
            loading: true,
          };
          break;
        case GET_QUALITY_BY_ITEM_SUCCESSFULL:
          state = {
            ...state,
           JuteQualityByItem: action.payload.response,
            loading: false,
          };
          break;
        case JUTE_GATE_ENTRY_HEADER:
          state = {
            ...state,
            JuteGateEntryHeader: action.payload,
            loading: true,
          };
          break;
          case JUTE_GATE_ENTRY_LINE_ITEMS:
            state = {
              ...state,
              JuteGateEntrylineItems: action.payload
            }
            break;
          case CLEAR_JUTE_GATE_ENTRY_HEADER:
            state = {
              ...state,
              JuteGateEntryHeader: initialState.JuteGateEntryHeader,
              loading: true,
            };
            break;

            case CLEAR_JUTE_GATE_ENTRY_LINE_ITEMS:
              state = {
                ...state,
                JuteGateEntrylineItems: [
                  {
                    "id":0,
                    "recId": "",
                    "hdrId": "",
                    "advisedJuteTyp": "",
                    "actualJuteTyp": "",
                    "advisedQuality": "",
                    "actualQuality": "",
                    "advisedQuantity": "",
                    "actualQuantity": "",
                    "receivedIn": "",
                    "autoDateTime": "",
                    "unitId": "",
                    "remarks": null,
                    "kgs": null,
                    "poLineItemNum": "",
                    "createdBy": "",
                    "isActive": "",
                    "qcJuteType": "",
                    "qcJuteQuality": "",
                    "qcJuteQuantity": "",
                    "advisedWeight": null,
                    "actualWeight": "",
                    "qcJuteWeight": "",
                    "allowableMoisture": null,
                    "readings": [],
                    "actualQualityDesc": "",
                    "advisedQualityDesc": "",
                    "itemDesc": "",
                    "itemId":"",
                    "qualityCode":"",
                    "qualityCodeName":"",
                    "advisedItemDesc": "",
                    "avgReadings": "",
                    "qcJuteTypeDesc": "",
                    "qcJuteQualityDesc": "",
                    "unitId":"",
                    "itemCode":"",
                    QuantityListData:[]
                 }
                ],
              loading: true,
            };
            break;
            case GET_ALL_APPROVED_PO:
              state = {
                ...state,
                loading: true,
              };
              break;
            case GET_ALL_APPROVED_PO_SUCCESSFULL:
              state = {
                ...state,
               GetAllApprovedPo: action.payload.response,
                loading: false,
              };
              break;
              case JUTE_PO_VIEW_BY_ID_FOR_GATE_ENTRY: 
              state = {
                ...state,
                loading: true              
              }
              break;
            case JUTE_PO_VIEW_BY_ID_FOR_GATE_ENTRY_SCCESSFULL:
              state = {
                ...state,
                JutePoViewByIdForGateEntry: action.payload.response.data,
                loading: false,
              };
              break;
              case JUTE_GATE_ENTRY_UPLOAD_FILE:
                state = {
                  ...state,
                  loading: true,
                };
                break;
          
              case JUTE_GATE_ENTRY_UPLOAD_FILE_SUCCESSFULL:
                state = {
                  ...state,
                  GateEntryUploadDocuments: action.payload.response,
                  loading: false,
                };
                break;
                case JUTE_GATE_ENTRY_GET_ALL_FILES:
                  state = {
                    ...state,
                    loading: true,
                  };
                  break;
                case JUTE_GATE_ENTRY_GET_ALL_FILES_SUCCESSFULL:
                  state = {
                    ...state,
                    JuteGateEntryFiles: action.payload.response.data,
                    loading: false,
                  };
                  break;
                  case JUTE_GATE_ENTRY_DELETE_FILE:
                    state = {
                      ...state,
                      loading: true,
                    };
                    break;
                  case JUTE_GATE_ENTRY_DELETE_FILE_SUCCESSFULL:
                    state = {
                      ...state,
                      GateEntryDeleteDocuments: action.payload.response,
                      loading: false,
                    };
                    break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default JuteGateEntryReducer;
