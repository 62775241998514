import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextFieldNormal from "../../TextField/TextFieldNormal";
import DynamicSelect from "../../../components/Dropdown/Master/DynamicSelectMasters";
import { serverApi } from "../../../helpers/Consts";
import { getParentLedgerList } from '../../../store/Global/DropDownApis/actions';
import { getYarnTypeList } from "../../../store/Global/DropDownApis/actions";

class SpinningQualityMasterFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      qualityType:2,
      qualityCode:"",
      qualityName:"",
      speed:"",
      yarnType:"",
      machineType:"",
      jboRbo:"",
      yarnCount:"",
      tarEff:"",
      tpi:"",
      spindleCount:"",
      stdDoff:"",
      stdDoffWt:"",
      yarnTypeListData:[]
    };
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "qualityCode") {
      this.setState({
        qualityCode: event,
      });
    }
    if (key === "qualityName") {
      this.setState({
        qualityName: event,
      });
    }
    if (key === "speed") {
      this.setState({
        speed: event,
      });
    }
    if (key === "yarnCount") {
      this.setState({
        yarnCount: event,
      });
    }
    if (key === "tarEff") {
      this.setState({
        tarEff: event,
      });
    }
    if (key === "tpi") {
      this.setState({
        tpi: event,
      });
    }
    if (key === "spindleCount") {
      this.setState({
        spindleCount: event,
      });
    }
    if (key === "stdDoff") {
      this.setState({
        stdDoff: event,
      });
    }
    if (key === "stdDoffWt") {
      this.setState({
        stdDoffWt: event,
      });
    }
  };
  handleSubmit = () => {
    const data = {
      qualityType:this.state.qualityType,
      qualityCode:this.state.qualityCode,
      qualityName:this.state.qualityName,
      speed:this.state.speed,
      
      yarnType:this.state.yarnType,
      machineType:this.state.machineType,

      jboRbo:this.state.jboRbo,
      yarnCount:this.state.yarnCount,
      tarEff:this.state.tarEff,
      tpi:this.state.tpi,
      spindleCount:this.state.spindleCount,
      stdDoff:this.state.stdDoff,
      stdDoffWt:this.state.stdDoffWt
    };
    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };
  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "yarnType") {
      if(selectedValue==0){
        this.setState({
          yarnType: ''
        });
      }else{
        this.setState({
          yarnType: selectedValue
        });
      }
   
    }
    if (name === "machineType") {
      if(selectedValue==0){
        this.setState({
          machineType: ''
        });
      }else{
        this.setState({
          machineType: selectedValue
        });
      }
   
    }
    if (name === "jboRbo") {
      this.setState({
        jboRbo: selectedValue
      });
    }
  };
  componentDidMount(){
    this.props.getYarnTypeList(
      serverApi.GET_YARN_TYPE_LIST + this.state.companyId,
      this.props.history
    ); 
  }
  componentWillReceiveProps(props) {
    if (props.yarnTypeList) {
      this.setState({
        yarnTypeListData: props.yarnTypeList.data, // updating department list
      });
    }
  }
  render() {
    return (
      <>
          <Box>
          <Button onClick={this.handleClickOpen}>
            <Tooltip title="Filter">
              <FilterListOutlinedIcon />
            </Tooltip>
          </Button>
          <Dialog
            open={this.state.open}
            PaperProps={{
              sx: {
                position: " fixed",
                top: 0,
                right: 0,
                margin: 0,
                bottom: 0,
                maxHeight: "100vh",
              },
            }}
            keepMounted
            onClose={this.handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <Grid spacing={0}>
              <div className="consoleTextFieldBlock">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div className="filterBlockHeader">
                    <FilterListOutlinedIcon />
                    <span>Filter</span>
                  </div>
                </div>
              </div>
              <div className="hrmsFilter">
                <Grid container spacing={1} className="filterBlockContainer">
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                        label="Quality Code"
                        value={this.state.qualityCode}
                        onChange={this.onhandlechange("qualityCode")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Quality Name"
                        value={this.state.qualityName}
                        onChange={this.onhandlechange("qualityName")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Speed"
                        value={this.state.speed}
                        onChange={this.onhandlechange("speed")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                    <DynamicSelect
                        label="Yarn Type"
                        arrayOfData={this.state.yarnTypeListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectChange}
                        selected={this.state.yarnType}
                        name="yarnType"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                    <DynamicSelect
                        label="Frame Type"
                        arrayOfData={FrameType}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectChange}
                        selected={this.state.machineType}
                        name="machineType"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Yarn Count"
                        value={this.state.yarnCount}
                        onChange={this.onhandlechange("yarnCount")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                        label="TPI"
                        value={this.state.tpi}
                        onChange={this.onhandlechange("tpi")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                        label="Spindle"
                        value={this.state.spindleCount}
                        onChange={this.onhandlechange("spindleCount")}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Search"}
                  className="greenButton"
                  handleClick={this.handleSubmit}
                  type="sumbit"
                />
              </div>
            </Grid>
          </Dialog>
        </Box>
      </>
    );
  }
}

const FrameType = [
  { label: "Select", value: null, name: "Select" },
  { label: "Boxter", value: "1", name: "boxter" },
  { label: "2 Leg", value: "2", name: "2leg" },
];
const mapStatetoProps = (state) => {
  const { parentLedgerList ,yarnTypeList} = state.DropDownListReducer;
    return { parentLedgerList ,yarnTypeList}
};

export default withRouter(connect(mapStatetoProps, {
  getParentLedgerList,
  getYarnTypeList
})(SpinningQualityMasterFilter));
