import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import {
  createTransporterMaster,
  updateTransporterMaster,
} from "../../../store/Master/TransporterMaster/actions";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import { getStateNameList } from "../../../store/Global/DropDownApis/actions";
import TextFieldEmail from "../../../components/TextField/TextFieldEmail";
import TextFieldMobileReq from "../../../components/TextField/TextFieldMobileReq";
import moment from "moment";
import { email, validate } from "../../../Utilities/Validations";
import swal from "sweetalert";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";

class CreateTransporterMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      transporterId: "",
      stateId: "",
      transporterName: "",
      contactNo: "",
      emailAddress: "",
      address: "",
      stateName: "",
      stateCode: null,
      createdBy: null,
      createdOn: "",
      createdDate: "",
      stateNameList: [],// State Name list
      transporterGst:null,
      locationState: ""
    };
  }
  /* Calling Create and Update Api's to Actions */
  onClickCreateUpdate = () => {
    const userId = this.state.userDit.userId;
    const { transporterName, contactNo, address, stateId, stateCode } =
      this.state;
    const validateFields = [
      { field: transporterName, msg: "Transporter Name", type: 1 },
      { field: contactNo, msg: "Contact Number", type: 1 },
      { field: address, msg: "Address", type: 1 },
      { field: stateId, msg: "state", type: 2 },
      { field: stateCode, msg: "state Code", type: 1 },
    ];
    let validObj = validate(validateFields);
    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message}`);
      }
      if (validObj.type === 2) {
        swal(`Please Select ${validObj.message}`);
      }
    } else {
      if (this.props.location.state === undefined) {
        // validation for email
        if (this.state.emailAddress) {
          let err = email(this.state.emailAddress, "Invalid Email");
          if (err) {
            swal(err.message, { icon: "error" });
            return;
          }
        }
        const data = {
          companyId: this.state.companyId,
          createdBy: userId.toString(),
          transporterName: this.state.transporterName,
          contact: this.state.contactNo,
          email: this.state.emailAddress,
          transporterAddress: this.state.address,
          stateName: this.state.stateName,
          stateCode: this.state.stateCode,
          transporterGst:this.state.transporterGst,
        };
        this.props.createTransporterMaster(
          serverApi.CREATE_TRANSPORTER_MASTER,
          data,
          this.props.history
        ); // Calling for Create API
      } else {
        if (this.state.emailAddress) {
          let err = email(this.state.emailAddress, "Invalid Email");
          if (err) {
            swal(err.message, { icon: "error" });
            return;
          }
        }
        const data = {
          cipher: this.state.userDit.cipher,
          companyId: this.state.companyId,
          createdBy: userId.toString(),
          transporterName: this.state.transporterName,
          contact: this.state.contactNo,
          email: this.state.emailAddress,
          transporterAddress: this.state.address,
          stateName: this.state.stateName,
          stateCode: this.state.stateCode,
          transporterId: this.state.transporterId,
          transporterGst:this.state.transporterGst,
          createdOn: moment().format(),
        };
        this.props.updateTransporterMaster(
          serverApi.UPDATE_TRANSPORTER_MASTER_API,
          data,
          this.props.history
        ); // calling for update API
      }
    }
  };

  onClickDelete = () => {
    if (this.props.location.state) {
      if (this.props.location.state.rowData) {
        const data = {
          transporterId: this.props.location.state.rowData.transporterId,
        };

        this.props.updateTransporterMaster(
          serverApi.DELETE_TRANSPORTER_MASTER_API,
          data,
          this.props.history
        );
      }
    }
  };
  // function for previous page
  onClickCancel = () => {
    this.props.history.push("/transporter_master");
  };
  //Function for textfield
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "transporterName") {
      this.setState({
        transporterName: event,
      });
    }
    if (key === "contactNo") {
      this.setState({
        contactNo: event,
      });
    }
    if (key === "emailAddress") {
      this.setState({
        emailAddress: event,
      });
    }
    if (key === "address") {
      this.setState({
        address: event,
      });
    }
    if(key==="transporterGst"){
      this.setState({
        transporterGst:event,
      })
    }
  };
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "stateName") {
      let stateCode = selectedName.split("^");

      if (selectedValue === 0) {
        this.setState({
          stateName: stateCode[0],
          stateId: selectedValue,
          stateCode: "",
        });
      } else {
        this.setState({
          stateName: stateCode[0],
          stateId: selectedValue,
          stateCode: stateCode[1],
          transporterGst: stateCode[1]
        });
      }
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    this.setState({
      locationState: this.props.location,
    });
    window.addEventListener("scroll", this.handleScroll);
    this.props.getStateNameList(
      serverApi.GET_STATE_NAME_LIST,
      this.props.history
    ); // Calling state Name dropdown API
    if (this.props.location.state) {
      this.setState({
        transporterName: this.props.location.state.rowData.transporterName,
        createdBy: this.props.location.state.rowData.createdBy,
        createdOn: this.props.location.state.rowData.createdOn,
        contactNo: this.props.location.state.rowData.contact,
        emailAddress: this.props.location.state.rowData.email,
        address: this.props.location.state.rowData.transporterAddress,
        stateId: this.props.location.state.rowData.stateName,
        stateCode: this.props.location.state.rowData.stateCode,
        transporterId: this.props.location.state.rowData.transporterId,
        transporterGst:this.props.location.state.rowData.transporterGst,
      });
      const dateEntered = this.props.location.state.rowData.createdOn;
      const dateIsValid = moment(dateEntered).format("LLLL");
      this.setState({
        createdDate: dateIsValid,
      });
    }
  }
  componentWillReceiveProps(props) {
    if (props.stateNameList) {
      this.setState({
        stateNameList: props.stateNameList.data, // updating state name list dropdown
      });

      if (props.stateNameList) {
        var stateData = props.stateNameList.data;
        if (stateData) {
          stateData.map((state) => {
            var pieces = state.name.split("^");
            if (this.state.stateCode == pieces[1]) {
              this.setState({
                stateId: state.value,
              });
            }
          });
        }
      }
    }
    if (props.stateNameList.data) {
      var list = [];
      list = props.stateNameList.data.filter((item) => {
        if (item.value !== 0) {
          return item;
        }
      });

      this.setState({
        stateNameList: list,
      });
    }
  }
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.transporterName}
                      caps="OFF"
                      minValue="0"
                      onChange={this.onhandlechangeValue("transporterName")}
                      label="Transporter Name"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldMobileReq
                      value={this.state.contactNo}
                      caps="OFF"
                      minValue="0"
                      onChange={this.onhandlechangeValue("contactNo")}
                      label="Contact No"
                      disabled={
                        this.state.transporterName === "" ? true : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldEmail
                      value={this.state.emailAddress}
                      caps="OFF"
                      minValue="0"
                      onChange={this.onhandlechangeValue("emailAddress")}
                      label="Email Address"
                      disabled={
                        this.state.transporterName === "" ? true : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.address}
                      caps="OFF"
                      minValue="0"
                      onChange={this.onhandlechangeValue("address")}
                      label="Address"
                      disabled={this.state.contactNo === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.stateNameList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.stateId}
                        update={this.props.location.state ? 1 : 0}
                        name="stateName"
                        label="State Name"
                        required
                        isDisabled={this.state.address === "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.stateCode}
                      caps="OFF"
                      readOnly={
                        this.props.location.state !== undefined ? true : false
                      }
                      minValue="0"
                      label="State Code"
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.transporterGst}
                      caps="ON"
                      onChange={this.onhandlechangeValue("transporterGst")}
                      label="Transporter GST Number."
                    />
                  </Box>
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdDate}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.state.locationState.state !== undefined ? (
            this.state.locationState.state.rowData ? (
              this.state.locationState.state.rowData.isActive !== 0 ? (
                <>
                  <CustomButton
                    label={"Update"}
                    className="greenButton"
                    handleClick={this.onClickCreateUpdate}
                    type="sumbit"
                  />
                  <CustomButton
                    label={"Delete"}
                    className="rejected"
                    handleClick={this.onClickDelete}
                    type="sumbit"
                  />
                </>
              ) : (
                ""
              )
            ) : (
              ""
            )
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Create_Transporter_Master, Update_Transporter_Master } =
    state.TransporterReducer; // Fetching Create and update transporter Reducer
  const { stateNameList } = state.DropDownListReducer;
  return {
    stateNameList,
    Create_Transporter_Master,
    Update_Transporter_Master,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    createTransporterMaster,
    updateTransporterMaster,
    getStateNameList,
  })(CreateTransporterMaster)
);
