import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Box } from "@mui/material";
import ImageUpload from "../../components/PictureUpload/PictureUpload";
import TextField from "../../components/TextField/TextField";
import TextFieldEmail from "../../components/TextField/TextFieldEmail";
import DynamicSelect from "../../components/Dropdown/DynamicSelect";
import TextFieldMobile from "../TextField/TextFieldMobile";
class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: 0,
      role_name: "",
      blood_group: 0,
      userDit: JSON.parse(localStorage.getItem("authUser")),

    };
  }
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "role") {
      this.setState({
        role: selectedValue,
        role_name: selectedName,
      });
    }
  };
  render() {
    return (
      <>
        <div className="personalDetailsInfo">
          <div className="consoleFormContainer">
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <ImageUpload  taskUniqueId={this.state.userDit.ebId}/>
                </Grid>
                <Grid item xs={9}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                    className="personalInfo"
                  >
                    <Box gridColumn="span 6">
                      <div className="consoleTextFieldBlock">
                        <TextField label="Full Name" placeholder="Enter here" value={this.state.userDit.userName}/>
                      </div>
                    </Box>
                    <Box gridColumn="span 6">
                      <Box gridColumn="span 4">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            arrayOfData={role}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            selected={this.state.blood_group}
                            name="Branch"
                            label="Role"
                          />
                        </div>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                    className="personalInfo"
                  ></Box>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 6">
                      <div className="consoleTextFieldBlock">
                        <TextFieldMobile
                          label="Mobile No"
                          placeholder="Enter here"
                          value={this.state.userDit.mobileNumber}
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 6">
                      <div className="consoleTextFieldBlock">
                        <TextFieldEmail
                          label="Email ID"
                          placeholder="abc@xyz.com"
                          value={this.state.userDit.email}
                          
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const role = [
  {
    value: "1",
    label: "None",
    name: "Female",
  },
  {
    value: "2",
    label: "Team Representative",
    name: "Team Representative",
  },
  {
    value: "3",
    label: "Team Lead",
    name: "Team Lead",
  },
];
const mapStatetoProps = (state) => {};

export default withRouter(connect(mapStatetoProps, {})(MyProfile));
