import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Grid, Box } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/JuteProduction/DynamicSelect";
import DynamicSelectNew from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { beamingCreate } from "../../../store/JuteProduction/BeamingProduction/actions";
import { getSpell } from "../../../store/HRMS/AttendanceCalender/actions";
import { beamingDdList } from "../../../store/JuteProduction/BeamingProduction/actions";
import { spinningQualityList } from "../../../store/Master/SpinningQualityMaster/actions";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import CustomButton from "../../../components/Buttons/Buttons";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import swal from "sweetalert";
import { validate } from "../../../Utilities/Validations";

class CreateBeamingProduction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProcessMasterContent: [],
      userDit: JSON.parse(localStorage.getItem("authUser")),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      spellContent: [],
      tranDate: moment().format("YYYY-MM-DD"),
      time: moment().format().split("T"),
      spinningQualityListData: [],
      BeamingDropdownData: [],
      spell: "",
      qualityCode: "",
      ends: "",
      beamMcNo: "",
      noOfCuts: "",
      noOfBeams: "",
      confirm: false,
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      qualityType: 1,
    };
    this.props.spinningQualityList(
      serverApi.GET_ALL_WEAVING_QUALITY_MASTER_LIST,
      data,
      this.props.history
    );
    this.props.getSpell(
      serverApi.SPELL +
        localStorage.getItem("companyId") +
        "/" +
        this.state.cipher,
      this.props.history
    );
    this.props.beamingDdList(
      serverApi.GET_BEAMING_DD_LIST +
        localStorage.getItem("companyId") +
        "/BEAMING",
      this.props.history
    );
  }

  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    this.props.headerData[key] = event;
  };
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    this.setState({ [name]: selectedValue });
    if (name === "qualityCode") {
      var rawQualityCode = selectedName;
      var qCode = rawQualityCode.split("_")[1];
      this.setState({ qualityCode: qCode });
    }
  };
  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
  };

  componentWillReceiveProps(props) {
    if (props.SpinningQualityList) {
      let qualityListData = [];
      if (props.SpinningQualityList.data) {
        let list = props.SpinningQualityList.data.filter((props) => {
          if (props.qualityId !== 0) {
            return props;
          }
        });
        list.map((item) => {
          qualityListData.push({
            label: item.qualityName + "_" + item.qualityCode,
            name: item.qualityName + "_" + item.qualityCode,
            value: item.qualityId,
          });
        });
        this.setState({
          spinningQualityListData: qualityListData, // updating spinning quality master
        });
      }
    }
    if (props.spell) {
      let list = props.spell.filter((props) => {
        if (props.value !== "0") {
          return props;
        }
      });
      this.setState({
        spellContent: list,
      });
    }
    if (props.beamingProductionDdLists) {
      if (props.beamingProductionDdLists.data) {
        let list = props.beamingProductionDdLists.data.filter((props) => {
          if (props.value !== 0) {
            return props;
          }
        });
        this.setState({
          BeamingDropdownData: list,
        });
      }
    }
  }
  onClickCreate = () => {
    const validateFields = [
      { field: this.state.tranDate, msg: "Date", type: 2 },
      { field: this.state.spell, msg: "Spell Name", type: 2 },
      { field: this.state.qualityCode, msg: "Quality", type: 1 },
      { field: this.state.beamMcNo, msg: "Machine Number", type: 2 },
      { field: this.state.noOfCuts, msg: "Cuts", type: 1 },
      { field: this.state.ends, msg: "Ends", type: 1 },
      { field: this.state.noOfBeams, msg: "Number of Beams", type: 1 },
    ];

    let validObj = validate(validateFields);
    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message}`);
      }
      if (validObj.type === 2) {
        swal(`Please Select ${validObj.message}`);
      }
    } else {
      let time = this.state.time[1].split("+");

      var beamingCreatePayload = {
        tranDate: moment(this.state.tranDate).format("DD-MM-YYYY"),
        time: time[0],
        noOfBeams: this.state.noOfBeams,
        noOfCuts: this.state.noOfCuts,
        beamMcNo: this.state.beamMcNo,
        ends: this.state.ends,
        qualityCode: this.state.qualityCode.toString(),
        spell: this.state.spell,
        companyId: localStorage.getItem("companyId"),
        createdBy: this.state.userDit.userId.toString(),
      };
      this.props.beamingCreate(
        serverApi.ADD_BEAMING_PRODUCTION,
        beamingCreatePayload,
        this.props.history
      );
    }
  };
  onClickCancel = () => {};
  // Function For Displaying Common Popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  handleSelectDate = (e, name) => {
    if (name === "tranDate") {
      let date = moment(e).format();
      let formattedFromDate = date.split("T", 1).toString();
      this.setState({
        tranDate: formattedFromDate,
      });
    }
    if (name === "time") {
      let actualtime = moment(e).format();
      let formattedTime = actualtime.split("T", 2);

      this.setState({
        time: formattedTime,
      });
    }
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <div className="finishingEntriesContainer">
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div>
                      <div className="TextFieldBlock">
                        <label>
                          Date<div className="mandatoryField">*</div>
                        </label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          name="tranDate"
                          inputFormat="dd-MM-yyyy"
                          value={this.state.tranDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "tranDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="TextFieldBlock">
                      <label>
                        Time<label className="starcolor">*</label>
                      </label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="Time"
                        name="time"
                        value={this.state.time}
                        onChange={(e) => this.handleSelectDate(e, "time")}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input ref={inputRef} {...inputProps} />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={
                          this.state.spellContent
                            ? this.state.spellContent.map((item) => ({
                                label: item.label,
                                value: item.value,
                                name: item.name,
                              }))
                            : ""
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.spell}
                        name="spell"
                        label="Spell"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectNew
                        arrayOfData={this.state.spinningQualityListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.qualityCode}
                        name="qualityCode"
                        label="Quality"
                        required
                        isDisabled={this.state.spell === "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectNew
                        arrayOfData={this.state.BeamingDropdownData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.beamMcNo}
                        name="beamMcNo"
                        label="Machine Number"
                        required
                        isDisabled={
                          this.state.qualityCode === "" ? true : false
                        }
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.noOfCuts}
                      caps="OFF"
                      type="number"
                      onChange={this.handleChange("noOfCuts")}
                      label="Cuts"
                      required
                      disabled={this.state.beamMcNo === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.ends}
                      caps="OFF"
                      type="number"
                      onChange={this.handleChange("ends")}
                      label="Ends"
                      required
                      disabled={
                        this.state.noOfCuts === "" || this.state.noOfCuts === 0
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.noOfBeams}
                      caps="OFF"
                      type="number"
                      onChange={this.handleChange("noOfBeams")}
                      label="Number of Beams"
                      required
                      disabled={
                        this.state.ends === "" || this.state.ends === 0
                          ? true
                          : false
                      }
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {/* Common Popup for validation */}
            {this.state.confirm && (
              <CommonPopup>
                <div className="delAddItem">
                  <div className="mandatoryFieldText">
                    Please select all the mandatory fields
                  </div>
                  <div className="delAddItemBtns">
                    <input
                      type="button"
                      onClick={this.onclickPopUP.bind(this)}
                      value="Ok"
                      className="delYesBtn"
                    />
                  </div>
                </div>
              </CommonPopup>
            )}
            <div className="consoleFormButtonBlock">
              <Link to="/pr_beaming_production">
                <CustomButton
                  label={"Cancel"}
                  className="greenBorderButton"
                  handleClick={this.onClickCancel}
                />
              </Link>
              <CustomButton
                label={"Create"}
                className="greenButton"
                handleClick={this.onClickCreate}
                type="sumbit"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { spell } = state.AttendanceReducer;
  const { SpinningQualityList } = state.SpinningQualityReducer; // fetching list from spinning quality reducer
  const { CutsListArray } = state.WeavingProductionListReducer;
  const { beamingProductionDdLists, beamingCreateData } =
    state.BeamingProductionListReducer;

  return {
    spell,
    SpinningQualityList,
    CutsListArray,
    beamingProductionDdLists,
    beamingCreateData,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSpell,
    spinningQualityList,
    beamingDdList,
    beamingCreate,
  })(CreateBeamingProduction)
);
