import { Component } from "react";
import { connect } from "react-redux";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import { withRouter } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CustomButton from "../../../components/Buttons/Buttons";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import swal from "sweetalert";
import {
  JuteBatchPlanning,
  JuteBatchPlanAssign,
} from "../../../store/Jute/JuteBatchPlanning/actions";
import MrCommonPopup from "../../../components/CommonPopup/MRPopup";
import DynamicSelect from "../../../components/Dropdown/Jute/DynamicSelect";
import { getYarnTypeList } from "../../../store/Global/DropDownApis/actions";
import { getPlanNameList } from "../../../store/MasterDropdownApis/actions";
import Link from "@mui/material/Link";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";

class JuteBatchPlanningIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDateValue: moment().subtract(7, "d").format(),
      toDateValue: moment().format(),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      yarnTypeId: "",
      planDate: moment().format(),
      batchPlanCode: "",
      JuteBatchPlanningContent: [],
      yarnTypeListData: [],
      getPlanNameListContent: [],
      assignPlanPopup: false,
    };
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount(planDate) {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getYarnTypeList(
      serverApi.GET_YARN_TYPE_LIST + this.state.companyId,
      this.props.history
    );
    this.props.getPlanNameList(
      serverApi.GET_PLAN_NAME + this.state.companyId,
      this.props.history
    );
    const data = {
      startDate: planDate
        ? moment(planDate).format("DD-MM-YYYY")
        : moment(this.state.fromDateValue).format("DD-MM-YYYY"),
      endDate: moment(this.state.toDateValue).format("DD-MM-YYYY"),
      companyId: localStorage.getItem("companyId"),
      cipher: this.state.userDit.cipher,
      userId: this.state.userDit.userId.toString(),
    };
    this.props.JuteBatchPlanning(
      serverApi.GET_BATCH_PLAN_LIST,
      data,
      this.props.history
    );
  }

  handleSearch = (planDate) => {
    this.setState({
      batchPlanningBlock: true,
    });
    const data = {
      startDate: planDate
        ? moment(planDate).format("DD-MM-YYYY")
        : moment(this.state.fromDateValue).format("DD-MM-YYYY"),
      endDate: moment(this.state.toDateValue).format("DD-MM-YYYY"),
      companyId: localStorage.getItem("companyId"),
      cipher: this.state.userDit.cipher,
      userId: this.state.userDit.userId.toString(),
    };
    this.props.JuteBatchPlanning(
      serverApi.GET_BATCH_PLAN_LIST,
      data,
      this.props.history
    );
    showSpinner();
  };
  componentWillReceiveProps(props) {
    if (props.JuteBatchPlanning) {
      this.setState({
        JuteBatchPlanningContent: props.JuteBatchPlanningData,
      });
      hideSpinner();
    }
    if (props.yarnTypeList) {
      this.setState({
        yarnTypeListData: props.yarnTypeList.data,
      });
    }
    if (props.getPlanNameListData) {
      this.setState({
        getPlanNameListContent: props.getPlanNameListData.data,
      });
    }
  }
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    this.setState({ [name]: selectedValue });
  };
  handleAssignPlan = () => {
    this.setState({
      assignPlanPopup: true,
    });
  };
  handleCancel = () => {
    this.setState({
      assignPlanPopup: false,
    });
  };
  handleSelectDate = (e, name) => {
    if (name === "planDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      this.setState({
        planDate: Date,
      });
    }
  };
  handleAssignSubmit = (planDate) => {
    if (this.state.batchPlanCode === "" || this.state.yarnTypeId === "") {
      swal("Please fill the details", { icon: "error" });
    } else {
      const data = {
        batchPlanCode: this.state.batchPlanCode,
        yarnTypeId: this.state.yarnTypeId,
        planDate: moment(this.state.planDate).format("DD-MM-YYYY"),
        createdBy: this.state.userDit.userId.toString(),
        companyId: localStorage.getItem("companyId"),
      };
      this.props.JuteBatchPlanAssign(
        serverApi.ASSIGN_JUTE_PLAN,
        data,
        this.props.history
      );
      this.setState({
        assignPlanPopup: false,
      });
      const dataPlanList = {
        startDate: planDate
          ? moment(planDate).format("DD-MM-YYYY")
          : moment(this.state.fromDateValue).format("DD-MM-YYYY"),
        endDate: planDate
          ? moment(planDate).format("DD-MM-YYYY")
          : moment(this.state.toDateValue).format("DD-MM-YYYY"),
        companyId: localStorage.getItem("companyId"),
        cipher: this.state.userDit.cipher,
        userId: this.state.userDit.userId.toString(),
      };
      this.props.JuteBatchPlanning(
        serverApi.GET_BATCH_PLAN_LIST,
        dataPlanList,
        this.props.history
      );
    }
  };
  handleViewAssigned = () => {
    // alert("dfsfsd")
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <>
        <div className="batchPlanningContainer consoleFormContainer">
          <div className="consoleFormBlock">
            <Grid item xs={12} className="filterBlockContainer">
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div className="TextFieldBlock">
                      <label>From Date</label>
                    </div>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="dd-MM-yyyy"
                        value={this.state.fromDateValue}
                        fullWidth
                        onChange={(newValue) => {
                          this.setState({ fromDateValue: newValue });
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input ref={inputRef} {...inputProps} />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div className="TextFieldBlock">
                      <label>To Date</label>
                    </div>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="dd-MM-yyyy"
                        value={this.state.toDateValue}
                        fullWidth
                        onChange={(newValue) => {
                          this.setState({ toDateValue: newValue });
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input ref={inputRef} {...inputProps} />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </Box>
                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Search"}
                    className="greenButton"
                    handleClick={() => {
                      this.handleSearch();
                    }}
                    type="sumbit"
                  />
                </div>
              </Box>
            </Grid>
          </div>
        </div>

        <div className="batchPlanningGrid gridContainer">
          <div className="gridComponent">
            <div className="react-bootstrap-table">
              <table cellSpacing="0px" cellPadding="0px">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Plan code</th>
                    <th>Plan name</th>
                    <th>Yarn type</th>
                    <th width="5%">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.JuteBatchPlanningContent.length > 0 ? (
                    this.state.JuteBatchPlanningContent.map((row) => {
                      return (
                        <tr>
                          <td>{row.planDate}</td>
                          <td>{row.planCode}</td>
                          <td>{row.planName}</td>
                          <td>{row.yarnType}</td>
                          <td width="5%">
                            <div>
                              <span>
                                <RemoveRedEyeIcon
                                  onClick={this.handleViewAssigned}
                                />
                              </span>
                              <span>
                                <DeleteIcon />
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={5}
                        align="center"
                        className="react-bs-table-no-data"
                      >
                        No Data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* ) : (
          ""
        )} */}
        <div className="consoleFormButtonBlock">
          <CustomButton
            label={"Assign Plan"}
            className="greenButton"
            handleClick={this.handleAssignPlan}
            type="button"
          />
        </div>
        {this.state.assignPlanPopup && (
          <MrCommonPopup>
            <div className="mrPopupContainer">
              <div className="consoleFormContainer">
                <h5>Assign Plan</h5>
                <div className="consoleFormBlock">
                  <Grid item xs={12} className="">
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 4">
                        <div>
                          <div className="TextFieldBlock">
                            <label>Plan Date</label>
                          </div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              inputFormat="dd-MM-yyyy"
                              value={this.state.planDate}
                              fullWidth
                              onChange={(e) =>
                                this.handleSelectDate(e, "planDate")
                              }
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="consoleDatePicker"
                                >
                                  <input
                                    ref={inputRef}
                                    {...inputProps}
                                    readOnly={true}
                                  />
                                  {InputProps?.endAdornment}
                                </Box>
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </Box>
                      <Box gridColumn="span 4">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            arrayOfData={this.state.getPlanNameListContent}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            isDropdownOpen={this.state.isDropdownOpen}
                            handleMenuOpen={this.handleMenuOpen}
                            selected={this.state.batchPlanCode}
                            update={this.props.location.state ? 1 : 0}
                            name="batchPlanCode"
                            label="Plan"
                            required
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 4">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            arrayOfData={this.state.yarnTypeListData}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            isDropdownOpen={this.state.isDropdownOpen}
                            handleMenuOpen={this.handleMenuOpen}
                            selected={this.state.yarnTypeId}
                            update={this.props.location.state ? 1 : 0}
                            name="yarnTypeId"
                            label="Yarn Type"
                            required
                          />
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                </div>
              </div>
            </div>
            <div className="consoleFormButtonBlock">
              <CustomButton
                label={"Cancel"}
                className="greenBorderButton"
                handleClick={this.handleCancel}
              />
              <CustomButton
                label={"Assign"}
                className="greenButton"
                handleClick={this.handleAssignSubmit}
                type="sumbit"
              />
            </div>
          </MrCommonPopup>
        )}
      </>
    );
  }
}
const mapStatetoProps = (state) => {
  const { JuteBatchPlanningData, AssignPlanData } =
    state.JuteBatchPlanningReducer;
  const { yarnTypeList } = state.DropDownListReducer;
  const { getPlanNameListData } = state.MasterDropDownListReducer;
  return {
    JuteBatchPlanningData,
    getPlanNameListData,
    yarnTypeList,
    AssignPlanData,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    JuteBatchPlanning,
    JuteBatchPlanAssign,
    getYarnTypeList,
    getPlanNameList,
  })(JuteBatchPlanningIndex)
);
