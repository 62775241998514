import React, { Component } from "react";
import { Grid, Step, Stepper, StepLabel } from "@mui/material";
import Link from "@mui/material/Link";
import CustomButton from "../../../components/Buttons/Buttons";
import StockTransferAddItems from "./StockTransferAddItems";
import StockTransferFillDetails from "./StockTransferFillDetails";
import StockTransferPreview from "../StockTransfer/StockTransferPreview";

import { addStockTransfer } from "../../../store/StoreModule/StockTransfer/actions";
import { serverApi } from "../../../helpers/Consts";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";

class StockTransferCreate extends Component {
  constructor() {
    super();
    this.state = {
      steps: [
        {
          title: "Fill Details",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Add Items",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Preview",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
          activeIcon:
            "http://vowerp.com/MailImages/assets/indentDisabled03.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
      ],
      currentStep: 1,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      confirm: false,
      header: {
        date: null,
        expense_type: 0,
        branchId: 0,
        customer_type: 0,
        project_type: 0,
        internalNote: "",
        expense_name: "",
        desgCompanyId: 0,
        destinationBranchId: 0,
        srcComp: 0,
        indentTypeId: 0,
      },
      products: [
        {
          id: "0",
          no: 0,
          itemGroupList: [],
          itemsList: [],
          srList: [],
          departmentList: [],
          wareHouseList: [],
          deptId: 0,
          itemGroupId: 0,
          itemId: 0,
          srNo: "",
          quantity: 0,
          costfactor: 0,
          machineNo: 0,
          centralStock: 0,
          branchStock: 0,
          srStock: 0,
          issueValue: 0,
          itemName: "",
          departmentName: "",
          itemGroupName: "",
          uom: "",
          costFactorName: "",
          srPrintNo: "",
          inwardId: 0,
          wareHouseNo: 0,
        },
      ],
    };
  }

  onClickNext = () => {
    const { currentStep } = this.state;

    if (
      this.state.header.expense_type !== 0 &&
      this.state.header.branchId !== 0
    ) {
      this.setState({
        currentStep: currentStep + 1,
      });
    } else {
      this.setState({
        confirm: true,
      });
    }
  };

  onclickOk() {
    this.setState({
      confirm: false,
    });
  }

  onClickBefore = () => {
    const { currentStep } = this.state;
    if (currentStep > 1) {
      this.setState({
        currentStep: currentStep - 1,
      });
    } else {
      this.props.history.push("/stock_transfer");
    }
  };
  QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;

    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "expenseType") {
      this.setState({
        expense_type: selectedValue,
        expense_name: selectedName,
      });
    }
    if (name === "branch") {
      this.setState({
        branch_type: selectedValue,
        branch_name: selectedName,
      });
    }
    if (name === "customerName") {
      this.setState({
        customer_type: selectedValue,
        customer_name: selectedName,
      });
    }
    if (name === "project") {
      this.setState({
        project_type: selectedValue,
        project_name: selectedName,
      });
    }
  };

  handleSelectDate = (value, name) => {
    if (name === "date") {
      this.setState({
        date: value,
      });
    }
  };

  componentDidMount() {
    this.setState({
      branchId: this.state.header.branchId,
    });
  }

  onCreateMaterialRequest = () => {
    var currentDate = this.state.header.date;
    var properDte = moment(currentDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var filterData = this.state.products.filter((item) => {
      if (item.deptId !== 0) {
        return item;
      }
    });

    const lineiTem = filterData.map((prop, key) => ({
      itemId: Number(prop.itemId),
      additionalRequirements: "",
      advisedQuantity: prop.quantity,
      actualQuantity: prop.quantity,
      rate: prop.issueValue,
      uomCode: prop.uom,
      finalQty: prop.quantity,
      wareHouseNo: prop.wareHouseNo,
      deptId: prop.deptId,
      itemGrpId: prop.itemGroupId,
      srcLineId: prop.srNo,
      srcSrNo: prop.srNo,
      srcSuppCode: prop.inwardId,
      status: 1,
      isActive: 1,
      createdBy: this.state.userDit.userId,
    }));

    const data = {
      storeGoodReceiveHeader: {
        goodReceiptDate: properDte,
        status: 1,
        submitter: this.state.userDit.userName,
        createdBy: this.state.userDit.userId,
        srcComp: this.state.header.srcComp,
        acYear: localStorage.getItem("financialYear"),
        srcBranch: this.state.header.branchId,
        desBranch: this.state.header.destinationBranchId,
        strDate: properDte,
        srType: "STR",
        remarks: this.state.header.internalNote,
        userId: this.state.userDit.userId,
        expenseType: this.state.header.expense_type,
        companyId: this.state.header.desgCompanyId,
      },
      storeGrnItemList: lineiTem,
      cipher: this.state.userDit.cipher,
    };

    this.props.addStockTransfer(
      serverApi.CREATE_STOCK_TRANSFER,
      data,
      this.props.history
    );
  };

  render() {
    const { steps, currentStep } = this.state;

    return (
      <div>
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            <div className="iconsBlock">
              <Stepper alternativeLabel activeStep={currentStep}>
                {steps.map((prop) => (
                  <Step key={prop.title}>
                    <StepLabel
                      StepIconComponent={(Iconprops) => {
                        return this.QontoStepIcon(
                          Iconprops,
                          prop.icon,
                          prop.activeIcon,
                          prop.title
                        );
                      }}
                    ></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {currentStep === 1 && (
              <>
                <StockTransferFillDetails
                  onSelectDep={this.handleSelectChange}
                  header={this.state.header}
                  products={this.state.products}
                  handleSelectDate={this.handleSelectDate}
                />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="cancelButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="saveButton"
                    handleClick={this.onClickNext}
                    type="sumbit"
                  />
                  {this.state.confirm && (
                    <CommonPopup>
                      <div className="delAddItem">
                        <div>Please Enter the mandatory Fields !</div>
                        <div className="delAddItemBtns">
                          <input
                            type="button"
                            onClick={this.onclickOk.bind(this)}
                            value="Ok!"
                            className="delYesBtn"
                          />
                        </div>
                      </div>
                    </CommonPopup>
                  )}
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                <StockTransferAddItems
                  header={this.state.header}
                  products={this.state.products}
                />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="cancelButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="saveButton"
                    handleClick={this.onClickNext}
                    type="sumbit"
                  />
                </div>
              </>
            )}
            {currentStep === 3 && (
              <>
                <StockTransferPreview
                  header={this.state.header}
                  products={this.state.products}
                />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="cancelButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={"Create"}
                    className="saveButton"
                    handleClick={this.onCreateMaterialRequest}
                    type="sumbit"
                  />
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { addMaterialRequest } = state.Material_Request_List;

  return { addMaterialRequest };
};

export default withRouter(
  connect(mapStatetoProps, {
    addStockTransfer,
  })(StockTransferCreate)
);
