import React, { Component } from "react";
import { Grid, Step, Stepper, StepLabel } from "@mui/material";
import Link from "@mui/material/Link";
import { serverApi } from "../../../helpers/Consts";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import CustomButton from "../../../components/Buttons/Buttons";
import FillDetails from "./FillDetails";
import CustomerLineItems from "./CustomerLineItems";
import {
  setCustomerMasterUpdate,
  getFilesData,
} from "../../../store/Master/CustomerMaster/actions";
import { createCustomerMaster } from "../../../store/Master/TravelCatageries/actions";
import swal from "sweetalert";
import { getBranchList } from "../../../store/Global/DropDownApis/actions";
import { validate } from "../../../Utilities/Validations";

class CreateCustomerMaster extends Component {
  constructor() {
    super();
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      steps: [
        {
          title: "Fill Details",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Add Branch",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
      ],
      currentStep: 1,
      confirmPopUp: false,
      CustomerTypeList: [],
      addListItem: [],
      confirm: false,
      CallAPI: false,
      locationState:"",
      customerHdr: {
        alternateContactNo: "",
        cinNo: "",
        companyId: "",
        contactNo: "",
        contactPerson: "",
        createdBy: "",
        customerCode: "",
        emailId: "",
        finalStatus: "",
        id: null,
        internalCompanyId: null,
        ledgerGroup: null,
        name: "",
        openningBalance: null,
        panNo: "",
        mappedBranchIds: [],
        createdOn: moment().format(),
        PanFile: "",
        PanFileId: "",
        panFileExtension:"",
        panRetrivePath:"",
        CinFile: "",
        CinFileId: "",
        cinFileExtension:"",
        cinRetrivePath:"",
        TanFile:"",
        TanFileId:"",
        tanFileExtension:"",
        tanRetrivePath:"",
        MsmeFile:"",
        msmeFileId:"",
        msmeFileExtension:"",
        msmeRetrivePath:"",
        Time: "",
        ShowInValidText: false,
        showMandatoryField: false,
        PanNumberValidation: false,
        CinNumberValidation: false,
        suppId: "",
        costCenter: "",
        tdsNo: false,
        tdsYes: true,
        entityTypeId:"",
        tanNumber:"",
        msmeRegistration:"",
        sezOrExport:"",
        msmeValue:"",
        chargesYes: true,
        chargesNo:false,
      },
      customerLineitems: [
        {
          address: "",
          city: "",
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          customerId: null,
          customerLineId: "",
          gstNo: "",
          isActive: 1,
          pincode: "",
          state: "",
          stateCode: "",
          displayname: null,
          fileUploadId: null,
          Bankdisplayname: null,
          BankfileUploadId: null,
          contactNo: "",
          contactPerson: "",
          openningBalance: "",
          accountNumber: "",
          ifscCode: "",
          isGstVerified: 2,
          branchName: "",
          ledgerGroupId: null,
          ledgerGroupDesc: null,
          ledgerName: "",
          ledgerId:null,
          addressType:null,
          futureReference:1,
        },
      ],
      projectbudgetList: [],
    };
  }
  componentDidMount() {
    this.setState({
      locationState:this.props.location
    })
    if (this.props.location.state !== undefined) {

      if (this.props.location.state.rowData !== undefined) {

        this.props.getBranchList(
          serverApi.BRANCH_LIST + this.state.companyId,
          this.props.history
        );
        if (this.props.location.state.rowData.customerMasterLineItems) {
          if (
            this.props.location.state.rowData.customerMasterLineItems.length !== 0
          ) {
            let LineItemData =
              this.props.location.state.rowData.customerMasterLineItems.map(
                (data) => {
                  return {
                    address: data.address,
                    city: data.city,
                    createdBy: this.props.location.state.rowData.createdBy,
                    customerId: data.customerId,
                    customerLineId: data.customerLineId,
                    gstNo: data.gstNo,
                    isActive: data.isActive,
                    pincode: data.pincode,
                    state: data.state,
                    stateCode: data.stateCode,
                    displayname: null,
                    fileUploadId: null,
                    Bankdisplayname: null,
                    BankfileUploadId: null,
                    contactNo: data.contactNo,
                    contactPerson: data.contactPerson,
                    openningBalance: data.openningBalance,
                    accountNumber: data.accountNumber,
                    ifscCode: data.ifscCode,
                    isGstVerified:data.isGstVerified,
                    branchName: data.branchName,
                    ledgerGroupId: data.ledgerGroupId,
                    ledgerGroupDesc: data.ledgerGroupDesc,
                    ledgerName: data.ledgerName,
                    ledgerId:data.ledgerId,
                    addressType:data.addressType,
                    futureReference:data.futureReference,
                  };
                }
              );
            LineItemData.push({
              address: "",
              city: "",
              createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
              customerId: null,
              customerLineId: "",
              gstNo: "",
              isActive: 1,
              pincode: "",
              state: "",
              stateCode: "",
              displayname: null,
              fileUploadId: null,
              Bankdisplayname: null,
              BankfileUploadId: null,
              contactNo: "",
              contactPerson: "",
              openningBalance: "",
              accountNumber: "",
              ifscCode: "",
              isGstVerified:2,
              branchName: "",
              ledgerGroupId: null,
              ledgerGroupDesc: null,
              ledgerName: "",
              ledgerId:null,
              addressType:null,
              futureReference:1,
            });

            this.setState({
              customerLineitems: LineItemData,
            });


            //  }
          }
        }
      }
    }
  }
  componentWillReceiveProps(props) {}
  ///function for stepper
  QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;

    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };

  ///function for create and update
  onClickNext = () => {
    const { currentStep } = this.state;
    if (this.state.customerHdr.ShowInValidText == true) {
      swal("Please enter the valid email Id", { icon: "error" });
    } else if (this.state.customerHdr.PanNumberValidation == true) {
      swal("Please enter the valid PAN Number", { icon: "error" });
    } else if (this.state.customerHdr.CinNumberValidation == true) {
      swal("Please enter the valid CIN Number", { icon: "error" });
    } else {
      if (this.props.location.state) {
        if (this.props.location.state.rowData !== undefined) {
          if (this.state.customerHdr.customerCode == "") {
            swal("Please enter the Customer Code");
          } else if (this.state.customerHdr.name == "") {
            swal("Please enter the Name");
          } else if (this.state.customerHdr.panNo == "") {
            swal("Please enter PAN Number");
          } else if (this.state.customerHdr.finalStatus == "") {
            swal("Please Select Final Status");
          }

          else {
            this.setState({
              currentStep: currentStep + 1,
            });
          }
        }
      } else {
        if (this.state.customerHdr.entityTypeId == "") {
          swal("Please select the Entity Type");
        }
        else if (this.state.customerHdr.name == "") {
          swal("Please enter the Name");
        } else if (this.state.customerHdr.panNo == "") {
          swal("Please enter PAN Number");
        } else if (this.state.customerHdr.finalStatus == "") {
          swal("Please Select Final Status");
        }

        else {
          this.setState({
            currentStep: currentStep + 1,
          });
        }
      }
    }
  };

  // function for onClick back
  onClickCancel = () => {
    const { currentStep } = this.state;
    if (currentStep > 1) {
      this.setState({
        currentStep: currentStep - 1,
      });
    } else {
      this.props.history.push("/Customer_Master");
    }
  };

  // function for Create & Update API
  onCreate = () => {
    var filterProducts = this.state.customerLineitems.filter((prop) => {
      if (prop.branchName !== "" && prop.isActive !== 0) {
        return prop;
      }
    });
    var filterProductsData = this.state.customerLineitems.filter((prop) => {
      if (prop.isActive !== 0) {
        return prop;
      }
    });
    let lastRow =
      this.state.customerLineitems[this.state.customerLineitems.length - 1];
  
    var ValidateFields = false;
    if (filterProductsData.length === 1) {
      filterProductsData.map((prop) => {
                if (prop.branchName === "") {
                  swal("Please Enter the Branch Name");
                  return false;
                }
                if (prop.address === "") {
                  swal("Please Enter Branch Address");
                  return false;
                }
                if (prop.city === "") {
                  swal("Please Enter city");
                  return false;
                }
                if (prop.state === "" || prop.state == "Select........") {
                  swal("Please Select state");
                  return false;
                }
                if (prop.stateCode === "") {
                  swal("Please Enter state code");
                  return false;
                }
                if (prop.pincode === "") {
                  swal("Please Enter pin code");
                  return false;
                } else {
                  ValidateFields = true;
                }
              });
            }else {
      for (var i = 0; i <= filterProducts.length - 1; i++) {
        // if (this.state.Supplier_LineItems[i] !== lastRow) {
        if (
          filterProducts[i].branchName == "" ||
          filterProducts[i].branchName == null 
        ) {
          swal("Please Enter the Branch Name");
          return false;
        }  else if (filterProducts[i].address == "") {
          swal("Please Select the  Branch Address ");
          return false;
        } else if (filterProducts[i].city == "") {
          swal("Please Enter the city ");
          return false;
        } else if (filterProducts[i].email == "") {
          swal("Please enter the Email ");
          return false;
        } else if (filterProducts[i].state === "") {
          swal("Please Select the state ");
          return false;
        } else if (filterProducts[i].stateCode === "") {
          swal("Please Enter the stateCode ");
          return false;
        } else if (filterProducts[i].pincode === "") {
          swal("Please Enter the pincode ");
          return false;
        } 
        else {
          ValidateFields = true;
        }
        // }
      }
    }
    if (ValidateFields === true) {
      var filterProductDetails = this.state.customerLineitems.filter((prop) => {
        if (prop.isActive === 1 && prop.address !== "") {
          return prop;
        }
      });
      let Branches = [];
      this.state.customerHdr.mappedBranchIds.map((item) => {
        Branches.push(item.value);
      });
      const data = {
        customerMasterHeader: {
          alternateContactNo: "",
          cinNo: this.state.customerHdr.cinNo,
          companyId: localStorage.getItem("companyId"),
          contactNo: this.state.customerHdr.contactNo,
          contactPerson: this.state.customerHdr.contactPerson,
          createdBy: this.state.userDit.userId,
          createdOn: moment().format(),
          emailId: this.state.customerHdr.emailId,
          finalStatus: this.state.customerHdr.finalStatus,
          internalCompanyId: this.state.customerHdr.internalCompanyId,
          ledgerGroup: this.state.customerHdr.ledgerGroup,
          mappedBranchIds: Branches,
          name: this.state.customerHdr.name,
          openningBalance: Number(this.state.customerHdr.openningBalance),
          panNo: this.state.customerHdr.panNo,
          costCenter: this.state.customerHdr.costCenter,
          tds: this.state.customerHdr.tdsYes === true ? "Y" : "N",
          entityTypeId: this.state.customerHdr.entityTypeId,
          tanNumber:this.state.customerHdr.tanNumber,
          msmeRegistration: this.state.customerHdr.msmeRegistration,
          sezOrExport: this.state.customerHdr.sezOrExport,
          msmeValue: this.state.customerHdr.msmeValue,
          reverseChargesApplicable:this.state.customerHdr.chargesYes === true ? "Y" : "N",
        },
        customerMasterLineItems: filterProductDetails,
      };

      this.props.createCustomerMaster(
        serverApi.CREATE_NEW_CUSTOMER_MASTER,
        data,
        this.props.history
      );
    }
  };
  OnUpdate = () => {
  var filterProducts = this.state.customerLineitems.filter((prop) => {
    if (prop.branchName !== "" && prop.isActive !== 0) {
      return prop;
    }
  });
  var filterProductsData = this.state.customerLineitems.filter((prop) => {
    if (prop.isActive !== 0) {
      return prop;
    }
  });
  let lastRow =
    this.state.customerLineitems[this.state.customerLineitems.length - 1];

  var Validate2 = false;
  if (filterProductsData.length === 1) {
    filterProductsData.map((prop) => {
              if (prop.branchName === "") {
                swal("Please Enter Branch Name");
                return false;
              }
              if (prop.address === "") {
                swal("Please Enter Branch Address");
                return false;
              }
              if (prop.city === "") {
                swal("Please Enter city");
                return false;
              }
              if (prop.state == "" || prop.state == "Select........") {
                swal("Please Select state");
                return false;
              }
              if (prop.stateCode === "") {
                swal("Please Enter state code");
                return false;
              }
              if (prop.pincode === "") {
                swal("Please Enter pin code");
                return false;
              } else {
                Validate2 = true;
              }
            });
          }else {
    for (var i = 0; i <= filterProducts.length - 1; i++) {
      // if (this.state.Supplier_LineItems[i] !== lastRow) {
      if (
        filterProducts[i].branchName == "" ||
        filterProducts[i].branchName == null 
      ) {
        swal("Please Enter the Branch Name");
        return false;
      }  else if (filterProducts[i].address == "") {
        swal("Please Select the  Branch Address ");
        return false;
      } else if (filterProducts[i].city == "") {
        swal("Please Enter the city ");
        return false;
      } else if (filterProducts[i].email == "") {
        swal("Please enter the Email ");
        return false;
      } else if (filterProducts[i].state === "") {
        swal("Please Select the state ");
        return false;
      } else if (filterProducts[i].stateCode === "") {
        swal("Please Enter the stateCode ");
        return false;
      } else if (filterProducts[i].pincode === "") {
        swal("Please Enter the pincode ");
        return false;
      } 
      else {
        Validate2 = true;
      }
      // }
    }
  }

    if (Validate2 === true) {

      let filterProductData = this.state.customerLineitems.filter(item => 
        (item.isActive === 1 && item.branchName) || 
        (item.isActive === 0 && item.customerLineId)
      );
      
      let Branches = [];
      this.state.customerHdr.mappedBranchIds.map((item) => {
        Branches.push(item.value);
      });
      var CreatedOnDate = moment(
        this.state.customerHdr.createdOn.split("T")[0],
        "DD-MM-YYYY",
        true
      ).format("YYYY-MM-DD");
      const data = {
        customerMasterHeader: {
          alternateContactNo: this.state.customerHdr.alternateContactNo,
          cinNo: this.state.customerHdr.cinNo,
          companyId: localStorage.getItem("companyId"),
          contactNo: this.state.customerHdr.contactNo,
          contactPerson: this.state.customerHdr.contactPerson,
          createdBy: this.state.userDit.userId,
          createdOn:  moment().format(),
          emailId: this.state.customerHdr.emailId,
          finalStatus: this.state.customerHdr.finalStatus,
          internalCompanyId: this.state.customerHdr.internalCompanyId,
          ledgerGroup: this.state.customerHdr.ledgerGroup,
          mappedBranchIds: Branches,
          name: this.state.customerHdr.name,
          openningBalance: Number(this.state.customerHdr.openningBalance),
          panNo: this.state.customerHdr.panNo,
          id: this.state.customerHdr.id,
          customerCode: this.state.customerHdr.customerCode,
          tds: this.state.customerHdr.tdsYes == true ? "Y" : "N",
          costCenter: this.state.customerHdr.costCenter,
          entityTypeId: this.state.customerHdr.entityTypeId,
          tanNumber:this.state.customerHdr.tanNumber,
          msmeRegistration: this.state.customerHdr.msmeRegistration,
          sezOrExport:this.state.customerHdr.sezOrExport,
          msmeValue:this.state.customerHdr.msmeValue,
          reverseChargesApplicable:this.state.customerHdr.chargesYes === true ? "Y" : "N",
        },
        customerMasterLineItems: filterProductData,
      };
      this.props.setCustomerMasterUpdate(
        serverApi.UPDATE_CUSTOMER_MASTER,
        data,
        this.props.history
      );
    } 
  };
  UpdateLineItems = (Data) => {
    this.setState({
      customerLineitems: Data,
    });
  };
  render() {
    const { steps, currentStep } = this.state;
    return (
      <div>
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            <div className="iconsBlock">
              <Stepper alternativeLabel activeStep={currentStep}>
                {steps.map((prop) => (
                  <Step key={prop.title}>
                    <StepLabel
                      StepIconComponent={(Iconprops) => {
                        return this.QontoStepIcon(
                          Iconprops,
                          prop.icon,
                          prop.activeIcon,
                          prop.title
                        );
                      }}
                    ></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {currentStep === 1 && (
              <>
                <FillDetails
                  customerHdr={this.state.customerHdr}
                  customerLineitems={this.state.customerLineitems}
                />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={this.onClickCancel}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="greenButton"
                    handleClick={this.onClickNext}
                    type="sumbit"
                  />
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                <CustomerLineItems
                  customerHdr={this.state.customerHdr}
                  customerLineitems={this.state.customerLineitems}
                  UpdateLineItems={this.UpdateLineItems}
                />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={this.onClickCancel}
                    />
                  </Link>
                  {this.state.locationState.state === undefined ? (
                    <CustomButton
                      label={"Create"}
                      className="greenButton"
                      handleClick={this.onCreate}
                      type="sumbit"
                    />
                  ) : (
                    <CustomButton
                      label={"Update"}
                      className="greenButton"
                      handleClick={this.OnUpdate}
                      type="sumbit"
                    />
                  )}
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { GetAllFiles_Data } = state.CustomerListReducer;
  return {
    GetAllFiles_Data,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    createCustomerMaster,
    setCustomerMasterUpdate,
    getBranchList,
    getFilesData,
  })(CreateCustomerMaster)
);
