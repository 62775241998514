//Personal Details Form Created Date : 16-12-2022 By Nagesh Medisetty
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Box } from "@mui/material";
import TextFieldMobile from "../../../../../components/TextField/TextFieldMobile";
import { serverApi } from "../../../../../helpers/Consts";
import CustomButton from "../../../../../components/Buttons/Buttons";
import { Link } from "react-router-dom";
import {
  viewbyidPersonalDetails,
  updateWorkerDetails,
  viewbyidPersonalDetailsSuccessfull,
  clearProfileData,
} from "../../../../../store/HRMS/EmployeeDataBase/actions";
import swal from "sweetalert";
import { validate } from "../../../../../Utilities/Validations";
import TextFieldNormal from "../../../../../components/TextField/TextFieldNormal";

class ContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ebId: this.props.getEbId,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      contactDetailId: "",
      mobileNo: "",
      emergencyNo: "",
      updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
    };
  }
  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
  };
  componentDidMount() {
    this.props.clearProfileData(null);
    var ebId = this.props.getEbId;
    if (ebId > 0) {
      this.props.viewbyidPersonalDetails(
        serverApi.VIEW_EMPLOYEE_CONTACT_DIT + "/" + this.props.getEbId,
        this.props.history,
        2
      );
    } else {
      const response = { data: null };
      this.props.viewbyidPersonalDetailsSuccessfull({ response });
    }
  }
  componentWillReceiveProps(props) {
    var ebId = this.props.getEbId;
    if (ebId > 0) {
      if (props.personalDetails) {
        if (props.personalDetails.data) {
          var personal = props.personalDetails.data;
          if (personal) {
            Object.keys(personal).map((key, index) => {
              this.setState({ [key]: personal[key] });
              return true;
            });
          }
        }
      }
    }
  }

  onClickSave = () => {
    if (this.state.mobileNo == "") {
      swal("Enter the Mobile Number", { icon: "error" });
    } else if (this.state.emergencyNo == "") {
      swal("Enter the Emergency contact Number", { icon: "error" });
    } else {
      const data = {
        contactDetailId: this.state.contactDetailId,
        ebId: this.state.ebId,
        mobileNo: this.state.mobileNo,
        emergencyNo: this.state.emergencyNo,
        updatedBy: this.state.updatedBy,
      };

      this.props.updateWorkerDetails(
        serverApi.UPDATE_EMPLOYEE_CONTACT_DIT,
        data,
        this.props.history,
        "Contact Details",
        this.state.ebId
      );
      this.props.onvalue(4, this.props.getEbId);
    }
  };

  render() {
    return (
      <div className="contactDetailsBlock">
        <div className="">
          <h1>Contact Details</h1>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                  className="contactDetails"
                >
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldMobile
                        required
                        label="Mobile Number"
                        placeholder="+91"
                        value={this.state.mobileNo}
                        onChange={this.onhandlechange("mobileNo")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        type="number"
                        required
                        label="Emergency Contact Number"
                        placeholder="+91"
                        value={this.state.emergencyNo}
                        onChange={this.onhandlechange("emergencyNo")}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="consoleFormButtonBlock">
          <Link to="/add_employee">
            <CustomButton label={"Cancel"} className="greenBorderButton" />
          </Link>

          <CustomButton
            label={"Update"}
            className="greenButton"
            handleClick={this.onClickSave}
            type="sumbit"
          />
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { personalDetails, getEbId } = state.EmployeeReducer;
  return { personalDetails, getEbId };
};

export default withRouter(
  connect(mapStatetoProps, {
    viewbyidPersonalDetails,
    updateWorkerDetails,
    clearProfileData,
    viewbyidPersonalDetailsSuccessfull,
  })(ContactDetails)
);
