import {
  all,
  call,
  fork,
  takeEvery,
  put,
  select,
  take,
} from "redux-saga/effects";
import {
  CREATE_REGULAR_INVOICE,
  INVOICE_LIST,
  REGULAR_SALES_ORDER_VIEW_BYID_FOR_INVOICE,
  REGULAR_DELIVERY_ORDER_VIEW_BYID_FOR_INVOICE,
  REGULAR_INVOICE_VIEW_BY_ID,
  UPDATE_REGULAR_INVOICE,
  REGULAR_INVOICE_ITEMS_BY_ITEM_GROUP_ID,
  SALES_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE,
  DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE,
  JUTE_GOVT_INVOICE_ITEMS_BY_ITEM_GROUP_ID,
  CREATE_JUTE_GOVT_INVOICE,
  JUTE_GOVT_INVOICE_VIEW_BY_ID,
  UPDATE_JUTE_GOVT_INVOICE,
  GET_VEHICLE_TYPE,
  SALES_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE,
  DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE,
  CREATE_JUTE_HESSIAN_INVOICE,
  SALES_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE,
  DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE,
  DELETE_INVOICE_FILE,
  DELETE_INVOICE_FILE_SUCCESSFULL,
  UPLOAD_INVOICE_FILES,
  UPLOAD_INVOICE_FILES_SUCCESSFULL,
  GET_INVOICE_FILES,
  INVOICE_FILES_SUCCESSFULL,
  JUTE_HESSIAN_INVOICE_VIEW_BY_ID,
  CREATE_JUTE_YARN_INVOICE,
  UPDATE_JUTE_HESSIAN_INVOICE,
  INVOICE_JUTE_YARN_GET_UOM_RELATION_VALUE,
  INVOICE_JUTE_YARN_VIEW_BY_ID,
  UPDATE_JUTE_YARN_INVOICE,
  GENERATE_E_INVOICE,
  GENERATE_E_WAY_BILL,
  INVOICE_JUTE_YARN_UOM_LIST,
  REGULAR_INVOICE_ITEMS_DETAILS_BY_ITEM_ID,
} from "./actionTypes";
import {
  InvoiceListSuccessfull,
  CreateRegularInvoiceSuccessfull,
  getSalesOrderViewByIdForInvoiceSuccessfull,
  getDeliveryOrderViewByIdForInvoiceSuccessfull,
  getRegularInvoiceLineItems,
  getJuteGovtInvoiceLineItems,
  getRegularInvoiceHeader,
  getJuteGovtInvoiceHeader,
  getRegularInvoiceViewByIdSuccessfull,
  UpdateRegularInvoiceSuccessfull,
  getRegularInvoiceItemsByItemGroupIdSuccessfull,
  getSalesOrderViewByIdForJuteGovtInvoiceSuccessfull,
  getDeliveryOrderViewByIdForJuteGovtInvoiceSuccessfull,
  getJuteGovtInvoiceItemsByItemGroupIdSuccessfull,
  CreateJuteGovtInvoiceSuccessfull,
  CreateJuteYarnInvoiceSuccessfull,
  getJuteGovtInvoiceViewByIdSuccessfull,
  UpdateJuteGovtInvoiceSuccessfull,
  getVehicleTypeSuccessfull,
  getSalesOrderViewByIdForJuteHessianSuccessfull,
  getJuteHessionInvoiceHeader,
  getJuteHessianInvoiceLineItems,
  getDeliveryOrderViewByIdForJuteHessianSuccessfull,
  CreateJuteHessionInvoiveSuccessfull,
  UpdateJuteHessianInvoiceSuccessfull,
  getJuteYarnInvoiceLineItems,
  getSalesOrderViewByIdForJuteYarnInvoiceSuccessfull,
  getJuteYarnInvoiceHeader,
  getDeliveryOrderViewByIdForJuteYarnInvoiceSuccessfull,
  UploadInvoiceFilesSuccessfull,
  InvoiceFilesSuccessfull,
  getInvoiceFiles,
  deleteInvoiceFileSuccessfull,
  getJuteHessianInvoiceViewByIdSuccessfull,
  getInvoiceJuteYarnUomRelationValueSuccessfull,
  getJuteYarnInvoiceViewByIdSuccessfull,
  UpdateJuteYarnInvoiceSuccessfull,
  generateEInvoiceSuccessfull,
  generateEwayBillSuccessfull,
  getJuteHessianInvoiceViewById,
  getRegularInvoiceViewById,
  getJuteGovtInvoiceViewById,
  getJuteYarnInvoiceViewById,
  getInvoiceYarnUomListSuccessfull,
  getRegularInvoiceItemDetailsByItemIdSuccessfull,
} from "./actions";
import { getList, getListurl, postAdd } from "../../../helpers/Server_Helper";
import { getBillingShippingList } from "../../../store/Global/DropDownApis/actions";
import cons, {
  hideSpinner,
  serverApi,
  showSpinner,
} from "../../../helpers/Consts";

import swal from "sweetalert";
import {
  isEmpty,
  limitDecimals,
  roundAndRemainingValues,
  convertPrimaryToSecondary,
  convertRatePcsToMt,
  QuantityOfVariableUOMconvertion
} from "../../../Utilities/helper";
import { SalesGstCalculations } from "../../../Utilities/SalesGstCalculation";

function* UploadedInvoiceFileList({
  payload: { url, data, history, sourceMenu, regInvoiceId },
}) {
  try {
    const response = yield call(postAdd, url, data);
    if (response) {
      swal("Uploaded Successfully", { icon: "success" });
      let Url =
        serverApi.GET_FILES +
        "47/" +
        regInvoiceId +
        "/" +
        localStorage.getItem("companyId");
      yield put(getInvoiceFiles(Url, history));
      yield put(InvoiceFilesSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getAllInvoiceFileList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url, history);
    yield put(InvoiceFilesSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* deleteInvoiceFile({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(deleteInvoiceFileSuccessfull({ response }));
    if (response.data.message == "Success.") {
      swal("Successfully Deleted", { icon: "success" });
    }
  } catch (error) {
    console.log(error);
  }
}
function* getInvoiceList({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    if (response) {
      hideSpinner();
      yield put(InvoiceListSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}

function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}
function* setRegualarInvoiceData({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(CreateRegularInvoiceSuccessfull({ response }));
    if (response) {
      hideSpinner();
      if (response.data) {
        if (response.data.status) {
          swal(response.data.message, { icon: "success" });
        } else {
          swal(response.data.message, { icon: "error" });
        }
        history.push("/invoice");
      }
    }
  } catch (error) {
    hideSpinner();
    console.log(error);
  }
}
function* setJuteGovtInvoiceData({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(CreateJuteGovtInvoiceSuccessfull({ response }));
    if (response) {
      hideSpinner();
      if (response.data) {
        if (response.data.status) {
          swal(response.data.message, { icon: "success" });
        } else {
          swal(response.data.message, { icon: "error" });
        }
        history.push("/invoice");
      }
    }
  } catch (error) {
    hideSpinner();
    console.log(error);
  }
}
function* setJuteYarnInvoiceData({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(CreateJuteYarnInvoiceSuccessfull({ response }));
    if (response) {
      hideSpinner();
      if (response.data) {
        if (response.data.status) {
          swal(response.data.message, { icon: "success" });
        } else {
          swal(response.data.message, { icon: "error" });
        }
        history.push("/invoice");
      }
    }
  } catch (error) {
    hideSpinner();
    console.log(error);
  }
}
function* UpdateJuteYarnInvoiceData({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(UpdateJuteYarnInvoiceSuccessfull({ response }));
    if (response) {
      hideSpinner();
      if (response.data) {
        if (response.data.status) {
          swal(response.data.message, { icon: "success" });
        } else {
          swal(response.data.message, { icon: "error" });
        }
        history.push("/invoice");
      }
    }
  } catch (error) {
    hideSpinner();
    console.log(error);
  }
}
function* getSalesOrderViewByIdForInvoiceData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getSalesOrderViewByIdForInvoiceSuccessfull({ response }));
    if (isEmptyObject(response.data)) {
      swal("Something Went Wrong", { icon: "error" });
    } else {
      if (response) {
        if (response.data) {
          if(response.data.customerId !==null){
          var CustomerUrl =
            serverApi.GET_ALL_BILLING_SHIPPING +
            response.data.customerId +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).cipher +
            "?isForSalesModule=1";
          yield put(getBillingShippingList(CustomerUrl, history));
          }
          if (response.data.salesOrderLineItemDTO) {
            if (response.data.salesOrderLineItemDTO.length !== 0) {
              var SalesUpdate = [];
              response.data.salesOrderLineItemDTO.map((item, i) => {
                var createddate =
                  item.createdDate !== null
                    ? item.createdDate.split("T", 2)[0]
                    : null;
                if (
                  item.invoiceBalQty !== 0 &&
                  item.invoiceBalQty !== null &&
                  item.invoiceBalQty !== undefined &&
                  item.invoiceBalQty !== ""
                ) {
                  SalesUpdate.push({
                    id: i,
                    salesRegularInvoiceLineitemId: "",
                    createdBy: item.createdBy,
                    createdDate: createddate,
                    companyId: item.companyId,
                    companyName: item.companyName,
                    salesId: item.regularSalesOrderId,
                    salesLineItemId: item.regularSalesOrderLineItemId,
                    itemGroupId: item.itemGroupId,
                    itemGroupName: item.itemGroupName,
                    itemGroupValue: item.itemGroupValue,
                    itemId: item.itemId,
                    itemName: item.itemName,
                    itemCode: item.itemCode,
                    itemValue: item.itemValue,
                    hsncode: item.hsncode,
                    itemMake: item.itemMake,
                    quantity: limitDecimals(item.invoiceBalQty, 3),
                    uom: item.uom,
                    rate: limitDecimals(item.rate, 3),
                    invoiceQty: limitDecimals(item.invoiceBalQty, 3),
                    igstAmount: limitDecimals(
                      (item.discountedRate *
                        item.invoiceBalQty *
                        (item.igstPercent == null ? 0 : item.igstPercent)) /
                        100,
                      2
                    ),
                    igstPercent:
                      item.igstPercent == null
                        ? "0.00"
                        : limitDecimals(item.igstPercent, 2),
                    cgstAmount: limitDecimals(
                      (item.discountedRate *
                        item.invoiceBalQty *
                        (item.cgstPercent == null ? 0 : item.cgstPercent)) /
                        100,
                      2
                    ),
                    cgstPercent:
                      item.cgstPercent == null
                        ? "0.00"
                        : limitDecimals(item.cgstPercent, 2),
                    sgstAmount: limitDecimals(
                      (item.discountedRate *
                        item.invoiceBalQty *
                        (item.sgstPercent == null ? 0 : item.sgstPercent)) /
                        100,
                      2
                    ),
                    sgstPercent:
                      item.sgstPercent == null
                        ? "0.00"
                        : limitDecimals(item.sgstPercent, 2),
                    cessAmount: null,
                    discountType: item.discountType,
                    discountName: item.discountName,
                    discount: limitDecimals(item.discount, 2),
                    discountAmount: limitDecimals(item.discountAmount),
                    discountedRate: limitDecimals(item.discountedRate, 3),
                    totalAmount: limitDecimals(item.totalAmount, 2),
                    netAmount: limitDecimals(
                      item.discountedRate * item.invoiceBalQty,
                      2
                    ),
                    remarks: item.remarks,
                    taxPercent: limitDecimals(item.itemTaxPercentage, 2),
                    balesPacketSlNo: "",
                    isActive: item.isActive,
                    status: item.status,
                    updatedBy: null,
                    updatedOn: null,
                    regInvoiceId: null,
                    doBalQty: "",
                    currency: "INR",
                    ItemNameData: [],
                    regularDeliveryOrderID: "",
                    regularDeliveryOrderLineItemID: "",
                  });
                }
              });
              yield put(getRegularInvoiceLineItems(SalesUpdate));
            }
          }
          var TotalCGST = "0.00";
          if (SalesUpdate.length !== 0) {
            var TotalCGST = SalesUpdate.map((p) =>
              p.cgstAmount == null ? 0 : p.cgstAmount
            ).reduce(
              (cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind)
            );
          }

          var TotalSGST = "0.00";
          if (SalesUpdate.length !== 0) {
            TotalSGST = SalesUpdate.map((p) =>
              p.sgstAmount == null ? 0 : p.sgstAmount
            ).reduce(
              (sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind)
            );
          }
          var TotalIGST = "0.00";
          if (SalesUpdate.length !== 0) {
            TotalIGST = SalesUpdate.map((p) =>
              p.igstAmount == null ? 0 : p.igstAmount
            ).reduce(
              (igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind)
            );
          }
          var netAmount = "0.00";
          if (SalesUpdate.length !== 0) {
            netAmount = SalesUpdate.map((p) => p.netAmount).reduce(
              (netAmount, ind) => parseFloat(netAmount) + parseFloat(ind)
            );
          }
          var taxTotals =
            Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);
          var createddate =
            response.data.createdDate !== null
              ? response.data.createdDate.split("T", 2)[0]
              : null;
          var salesdate =
            response.data.salesDate !== null
              ? response.data.salesDate.split("T", 2)[0]
              : null;
          var quotationDate =
            response.data.quotationDate !== null
              ? response.data.quotationDate.split("T", 2)[0]
              : null;

          var roundingFlag = null;
          if (response.data.roundingFlag == true) {
            roundingFlag = 1;
          } else if (response.data.roundingFlag == false) {
            roundingFlag = 2;
          } else {
            roundingFlag = null;
          }
          var roundoff = roundAndRemainingValues(SalesUpdate, roundingFlag);
          const Header = {
            invoiceNo: "",
            regInvoiceId: "",
            salesOrderId: response.data.regularSalesId,
            salesOrderNo: response.data.salesNo,
            deliveryOrderId: "",
            deliveryOrderNo: "",
            invoiceType: response.data.invoiceTypeId,
            invoiceTypeDesc: response.data.invoiceTypeName,
            branchId: response.data.branchId,
            branchName: response.data.branchName,
            brokerId:
              response.data.brokerId == null ? "" : response.data.brokerId,
            brokerName: response.data.brokerName,
            bankId: "",
            bankName: "",
            bankBranch: "",
            ifscCode: "",
            accountNo: "",
            micrCode: "",
            customerId: response.data.customerId,
            customerName: response.data.customerName,
            customerResponse:response.data.customerId !==null ? true:false,
            billingTo: response.data.billingTo ? response.data.billingTo:"",
            billingAddress: response.data.billingToAddress,
            billingState: response.data.billedToState ? response.data.billedToState:"",
            billingFrom: response.data.billingFrom,
            shippingFrom: response.data.shippingFrom,
            billingFromAddress: response.data.billingFromAddress,
            shippingFromAddress: response.data.shippingFromAddress,
            transporterNumber: response.data.transporterNumber,
            transporterDocNo: response.data.transporterDocNo,
            billedToContactNumber: response.data.billedToContactNumber,
            billedToGstin: response.data.billedToGstin,
            billedToContactPerson: response.data.billedToContactPerson,
            billedToEmail: response.data.billedToEmail,
            shippingTo: response.data.shippingTo ? response.data.shippingTo:"",
            shippingAddress: response.data.shippingToAddress ? response.data.shippingToAddress:"" ,
            shippingState: response.data.shippedToState ? response.data.shippedToState:"",
            shippedToContactNumber: response.data.shippedToContactNumber,
            shippedToGstin: response.data.shippedToGstin,
            shippedToContactPerson: response.data.shippedToContactPerson,
            shippedToEmail: response.data.shippedToEmail,
            taxType: response.data.taxType,
            taxTypeDesc: response.data.taxName,
            invoiceDate: null,
            salesOrderDate: salesdate,
            deliveryOrderDate: null,
            quotationDate: quotationDate,
            quotationNo:
              response.data.quotationSalesOrderNo == null
                ? "N/A"
                : response.data.quotationSalesOrderNo,
            vehicleNo: "",
            poNumber: "",
            customerReferenceNumber: "",
            finYear: response.data.finYear,
            createdBy: response.data.createdBy,
            createdDate: createddate,
            companyId: response.data.companyId,
            customerBranchId: "",
            transporterId: response.data.transporterId,
            transporterName: response.data.transporterName,
            transporterGst: response.data.transporterGst,
            transporterStateCode: response.data.transporterStateCode,
            transporterStateName: response.data.transporterStateName,
            transporterAddress: response.data.transporterAddress,
            challanNo: response.data.challanNo,
            challanDate: response.data.challanDate,
            brokerCommision: "",
            brokerCommisionPercent:
              response.data.brokerCommisionPercent == null
                ? ""
                : response.data.brokerCommisionPercent,
            footerNote: response.data.footerNote,
            internalNote: response.data.internalNote,
            termsConditionType: response.data.termsConditionType,
            termsCondition: response.data.termsCondition,
            deliveryTerms: response.data.deliveryTerms,
            paymentTerms: response.data.paymentTerms,
            deliveryDays: response.data.deliveryDays,
            freightCharges: response.data.freightCharges,
            amountWithoutTax: response.data.amountWithoutTax,
            amountWithTax: response.data.amountWithTax,
            updatedBy: response.data.updatedBy,
            updatedOn: "",
            approvedBy: response.data.approvedBy,
            approvedOn: response.data.approvedOn,
            statusId: response.data.statusId,
            gstSuppType: "",
            gstInvoiceStatus: "",
            gstInvoiceCategory: "",
            gstInvoiceType: "",
            gstAmtTaxable: "",
            gst_txpd_taxable_value: "",
            gstShippingBillNo: "",
            gstShippingBillDate: "",
            gstReason: "",
            gstr1ReturnPeriod: "",
            gstr3bReturnPeriod: "",
            reverseCharge: "",
            isAmended: "",
            amendedPos: "",
            amendedPeriod: "",
            totalIGST:
              TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
            totalCGST:
              TotalCGST == null ? "0.00" : parseFloat(TotalCGST).toFixed(2),
            totalSGST:
              TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),
            subTotal: parseFloat(netAmount).toFixed(2),
            taxTotal: parseFloat(taxTotals).toFixed(2),
            netTotal: parseFloat(netAmount).toFixed(2),
            // TotalAmount: parseFloat(Number(roundoff.RoundoffValue)).toFixed(2),
            TotalAmount: limitDecimals(Number(netAmount)+Number(taxTotals),2),
            brokerContactNumber: response.data.brokerContactNumber,
            brokerContactPerson: response.data.brokerContactPerson,
            consigneeContactNumber: response.data.consigneeContactNumber,
            consigneeContactPerson: response.data.consigneeContactPerson,
            createWithDirectInvoice: true,

            roundOffValue: null,
            roundingFlag: null,

            roundingPositiveFlag:null,
              // response.data.roundingFlag == true ? true : false,
            roundingNegetiveFlag:null,
              // response.data.roundingFlag == false ? true : false,
            roundingPositiveValue: roundoff.positiveRF,
            roundingNegetiveValue: roundoff.NegativeRF,
            disableBrokerInvoice:
              response.data.brokerId !== null ? true : false,
            disableconsigneeInvoice:
              response.data.customerId !== null ? true : false,
          };
          yield put(getRegularInvoiceHeader(Header));
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function* getDeliveryOrderViewByIdForInvoiceData({
  payload: { url, history },
}) {
  const { RegularInvoiceHeader } = yield select(
    (state) => state.InvoiceReducer
  );
  try {
    const response = yield call(getListurl, url);
    yield put(getDeliveryOrderViewByIdForInvoiceSuccessfull({ response }));
    if (isEmptyObject(response.data)) {
      swal("Something Went Wrong", { icon: "error" });
    } else {
      if (response) {
        if (response.data) {
          var CustomerUrl =
            serverApi.GET_ALL_BILLING_SHIPPING +
            response.data.customerId +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).cipher +
            "?isForSalesModule=1";
          yield put(getBillingShippingList(CustomerUrl, history));
          if (response.data.regularDeliveryOrderLineItemsDTO) {
            if (response.data.regularDeliveryOrderLineItemsDTO.length !== 0) {
              var Salesupdate = [];
              response.data.regularDeliveryOrderLineItemsDTO.map((item, i) => {
                var createddate =
                  item.createdDate !== null
                    ? item.createdDate.split("T", 2)[0]
                    : null;

                if (
                  item.invoiceQty !== 0 &&
                  item.invoiceQty !== null &&
                  item.invoiceQty !== undefined &&
                  item.invoiceQty !== ""
                ) {
                  Salesupdate.push({
                    id: i,
                    salesRegularInvoiceLineitemId: "",
                    createdBy: item.createdBy,
                    createdDate: createddate,
                    companyId: item.companyId,
                    companyName: item.companyName,
                    salesId: item.salesId,
                    salesLineItemId: item.salesLineItemId,
                    itemGroupId: item.itemGroupId,
                    itemGroupName: item.itemGroupName,
                    itemGroupValue: item.itemGroupValue,
                    itemId: item.itemId,
                    itemName: item.itemName,
                    itemCode: item.itemCode,
                    itemValue: item.itemValue,
                    hsncode: item.hsncode,
                    itemMake: item.itemMake,
                    quantity: limitDecimals(item.invoiceQty, 3),
                    uom: item.uom,
                    rate: limitDecimals(item.rate, 3),
                    invoiceQty: limitDecimals(item.invoiceQty, 3),
                    igstAmount: limitDecimals(
                      (item.discountedRate *
                        item.invoiceQty *
                        (item.igstPercent == null ? 0 : item.igstPercent)) /
                        100,
                      2
                    ),

                    igstPercent:
                      item.igstPercent == null
                        ? "0.00"
                        : limitDecimals(item.igstPercent, 2),
                    cgstAmount: limitDecimals(
                      (item.discountedRate *
                        item.invoiceQty *
                        (item.cgstPercent == null ? 0 : item.cgstPercent)) /
                        100,
                      2
                    ),

                    cgstPercent:
                      item.cgstPercent == null
                        ? "0.00"
                        : limitDecimals(item.cgstPercent, 2),
                    sgstAmount: limitDecimals(
                      (item.discountedRate *
                        item.invoiceQty *
                        (item.sgstPercent == null ? 0 : item.sgstPercent)) /
                        100,
                      2
                    ),
                    sgstPercent:
                      item.sgstPercent == null
                        ? "0.00"
                        : limitDecimals(item.sgstPercent, 2),
                    cessAmount: null,
                    discountType: item.discountType,
                    discountName: item.discountName,
                    discount: limitDecimals(item.discount, 2),
                    discountedRate: limitDecimals(item.discountedRate, 3),
                    discountAmount: limitDecimals(item.discountAmount, 2),
                    totalAmount: limitDecimals(item.totalAmount, 2),
                    netAmount: limitDecimals(
                      item.discountedRate * item.invoiceQty,
                      2
                    ),
                    remarks: item.remarks,
                    taxPercent: limitDecimals(item.itemTaxPercentage, 2),
                    balesPacketSlNo: "",
                    isActive: item.isActive,
                    status: item.status,
                    updatedBy: null,
                    updatedOn: null,
                    regInvoiceId: null,
                    doBalQty: "",
                    currency: "INR",
                    ItemNameData: [],
                    regularDeliveryOrderID: item.regDeliveryOrderId,
                    regularDeliveryOrderLineItemID:
                      item.regularDeliveryOrderLineItemId,
                  });
                }
              });
              yield put(getRegularInvoiceLineItems(Salesupdate));
            }
          }

          var totalCGST = "0.00";
          if (Salesupdate.length !== 0) {
            totalCGST = Salesupdate.map((p) =>
              p.cgstAmount == null ? 0 : p.cgstAmount
            ).reduce(
              (cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind)
            );
          }
          var totalSGST = "0.00";
          if (Salesupdate.length !== 0) {
            totalSGST = Salesupdate.map((p) =>
              p.sgstAmount == null ? 0 : p.sgstAmount
            ).reduce(
              (sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind)
            );
          }
          var totalIGST = "0.00";
          if (Salesupdate.length !== 0) {
            totalIGST = Salesupdate.map((p) =>
              p.igstAmount == null ? 0 : p.igstAmount
            ).reduce(
              (igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind)
            );
          }
          var netAmount = "0.00";
          if (Salesupdate.length !== 0) {
            netAmount = Salesupdate.map((p) =>
              p.netAmount == null ? 0 : p.netAmount
            ).reduce(
              (netAmount, ind) => parseFloat(netAmount) + parseFloat(ind)
            );
          }
          var TaxTotals =
            Number(totalCGST) + Number(totalSGST) + Number(totalIGST);

          const deliveryDate =
            response.data.deliveryDate !== null &&
            response.data.deliveryDate.split("T", 2);

          var roundingFlag = null;
          if (response.data.roundingFlag == true) {
            roundingFlag = 1;
          } else if (response.data.roundingFlag == false) {
            roundingFlag = 2;
          } else {
            roundingFlag = null;
          }
          var roundoff = roundAndRemainingValues(Salesupdate, roundingFlag);
          const Header = {
            ...RegularInvoiceHeader,
            transporterId: response.data.transporter,
            bankId: response.data.bankId,
            bankBranch: response.data.bankBranch,
            bankName: response.data.bankName,
            ifscCode: response.data.ifscCode,
            accountNo: response.data.accountNo,
            micrCode: response.data.micrCode,
            customerName: response.data.customerName,
            customerId: response.data.customerId,
            customerResponse:response.data.customerId !==null ? true:false,
            transporterName: response.data.transporterName,
            transporterGst: response.data.transporterGst,
            transporterStateCode: response.data.transporterStateCode,
            transporterStateName: response.data.transporterStateName,
            transporterAddress: response.data.transporterAddress,
            deliveryOrderId: response.data.regDeliveryOrderId,
            deliveryOrderNo: response.data.deliveryOrderNo,
            deliveryOrderDate: deliveryDate[0],
            vehicleNo: response.data.vehicleNumber,
            totalIGST:
              totalIGST == null ? "0.00" : parseFloat(totalIGST).toFixed(2),
            totalCGST:
              totalCGST == null ? "0.00" : parseFloat(totalCGST).toFixed(2),
            totalSGST:
              totalSGST == null ? "0.00" : parseFloat(totalSGST).toFixed(2),
            subTotal: parseFloat(netAmount).toFixed(2),
            taxTotal: parseFloat(TaxTotals).toFixed(2),
            netTotal: parseFloat(netAmount).toFixed(2),
            // TotalAmount: parseFloat(Number(roundoff.RoundoffValue)).toFixed(2),
            TotalAmount: limitDecimals(Number(netAmount)+Number(TaxTotals),2),
            createWithDirectInvoice: true,
            roundOffValue:null,
            roundingFlag: null,
            roundingPositiveFlag:null,
              // response.data.roundingFlag == true ? true : false,
            roundingNegetiveFlag:null,
              // response.data.roundingFlag == false ? true : false,
            roundingPositiveValue: roundoff.positiveRF,
            roundingNegetiveValue: roundoff.NegativeRF,
            disableBrokerInvoice:
              response.data.brokerId !== null ? true : false,
            disableconsigneeInvoice:
              response.data.customerId !== null ? true : false,
            brokerCommisionPercent: response.data.brokerCommisionPercent,
            brokerId: response.data.brokerId ? response.data.brokerId : "",
            billingTo: response.data.billingTo,
            billingAddress: response.data.billingToAddress,
            billingState: response.data.billedToState,
            billingFrom: response.data.billingFrom,
            shippingFrom: response.data.shippingFrom,
            billingFromAddress: response.data.billingFromAddress,
            billedToContactNumber: response.data.billedToContactNumber,
            billedToGstin: response.data.billedToGstin,
            billedToContactPerson: response.data.billedToContactPerson,
            billedToEmail: response.data.billedToEmail,
            shippingFromAddress: response.data.shippingFromAddress,
            shippingTo: response.data.shippingTo,
            shippingAddress: response.data.shippingToAddress,
            shippingState: response.data.shippedToState,
            shippedToContactNumber: response.data.shippedToContactNumber,
            shippedToGstin: response.data.shippedToGstin,
            shippedToContactPerson: response.data.shippedToContactPerson,
            shippedToEmail: response.data.shippedToEmail,
            taxType: response.data.taxType,
          };

          yield put(getRegularInvoiceHeader(Header));
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function* getRegualarInvoiceViewByIdData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getRegularInvoiceViewByIdSuccessfull({ response }));
    if (isEmptyObject(response.data)) {
      swal("Something Went Wrong", { icon: "error" });
    } else {
      if (response) {
        if (response.data) {
          var CustomerUrl =
            serverApi.GET_ALL_BILLING_SHIPPING +
            response.data.customerId +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).cipher +
            "?isForSalesModule=1";
          yield put(getBillingShippingList(CustomerUrl, history));
          if (response.data.regularInvoiceLineItemsDTO) {
            if (response.data.regularInvoiceLineItemsDTO.length !== 0) {
              var Invoiceupdate = [];
              response.data.regularInvoiceLineItemsDTO.map((item, i) => {
                var createddate =
                  item.createdDate !== null
                    ? item.createdDate.split("T", 2)[0]
                    : null;
                Invoiceupdate.push({
                  id: i,
                  salesRegularInvoiceLineitemId:
                    item.salesRegularInvoiceLineitemId,
                  createdBy: item.createdBy,
                  createdDate: createddate,
                  companyId: item.companyId,
                  companyName: item.companyName,
                  salesId: item.salesId,
                  salesLineItemId: item.salesLineItemId,
                  itemGroupId: item.itemGroupId,
                  itemGroupName: item.itemGroupName,
                  itemGroupValue: item.itemGroupValue,
                  itemId: item.itemId,
                  itemName: item.itemName,
                  itemCode: item.itemCode,
                  itemValue: item.itemValue,
                  hsncode: item.hsncode,
                  itemMake: item.itemMake,
                  quantity: limitDecimals(item.quantity, 3),
                  uom: item.uom,
                  rate: limitDecimals(item.rate, 3),
                  invoiceQty: limitDecimals(item.invoiceQty, 3),
                  igstAmount:
                    item.igstAmount == null
                      ? "0.00"
                      : limitDecimals(item.igstAmount, 2),
                  igstPercent:
                    item.igstPercent == null
                      ? "0"
                      : limitDecimals(item.igstPercent, 2),
                  cgstAmount:
                    item.cgstAmount == null
                      ? "0.00"
                      : limitDecimals(item.cgstAmount, 2),
                  cgstPercent:
                    item.cgstPercent == null
                      ? "0.00"
                      : limitDecimals(item.cgstPercent, 2),
                  sgstAmount:
                    item.sgstAmount == null
                      ? "0.00"
                      : limitDecimals(item.sgstAmount, 2),
                  sgstPercent:
                    item.sgstPercent == null
                      ? "0.00"
                      : limitDecimals(item.sgstPercent, 2),
                  cessAmount: null,
                  discountType: item.discountType,
                  discountName: item.discountName,
                  discount: limitDecimals(item.discount, 2),
                  discountedRate: limitDecimals(item.discountedRate, 3),
                  discountAmount: limitDecimals(item.discountAmount, 2),
                  netAmount: limitDecimals(item.netAmount, 2),
                  totalAmount: limitDecimals(item.totalAmount, 2),
                  remarks: item.remarks,
                  taxPercent: limitDecimals(item.itemTaxPercentage, 2),
                  balesPacketSlNo: "",
                  isActive: item.isActive,
                  status: item.status,
                  updatedBy: null,
                  updatedOn: null,
                  regInvoiceId: response.data.regInvoiceId,
                  doBalQty: "",
                  currency: "INR",
                  ItemNameData: [],
                  regularDeliveryOrderID: item.regularDeliveryOrderID,
                  regularDeliveryOrderLineItemID:
                    item.regularDeliveryOrderLineItemID,
                });
              });

              var total_CGST = response.data.regularInvoiceLineItemsDTO
                .filter((item) => item.isActive != 0)
                .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
                .reduce(
                  (cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind)
                );
              var total_SGST = response.data.regularInvoiceLineItemsDTO
                .filter((item) => item.isActive != 0)
                .map((p) => (p.sgstAmount == null ? 0 : p.sgstAmount))
                .reduce(
                  (sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind)
                );
              var total_IGST = response.data.regularInvoiceLineItemsDTO
                .filter((item) => item.isActive != 0)
                .map((p) => (p.igstAmount == null ? 0 : p.igstAmount))
                .reduce(
                  (igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind)
                );

              var netAmount = response.data.regularInvoiceLineItemsDTO
                .filter((item) => item.isActive != 0)
                .map((p) => p.netAmount)
                .reduce(
                  (netAmount, ind) => parseFloat(netAmount) + parseFloat(ind)
                );
              var Tax_Totals =
                Number(total_CGST) + Number(total_SGST) + Number(total_IGST);
              var TotalAmount = Number(netAmount) + Number(Tax_Totals);
            }
          }
          var createddate =
            response.data.createdDate !== null
              ? response.data.createdDate.split("T", 2)[0]
              : null;
          var salesdate =
            response.data.salesOrderDate !== null
              ? response.data.salesOrderDate.split(" ", 2)[0]
              : null;
          var quotationDate =
            response.data.quotationDate !== null
              ? response.data.quotationDate.split(" ", 2)[0]
              : null;
          var invoiceDate =
            response.data.invoiceDate !== null
              ? response.data.invoiceDate.split("T", 2)[0]
              : null;
          var deliveryOrderDate =
            response.data.deliveryOrderDate !== null
              ? response.data.deliveryOrderDate.split(" ", 2)[0]
              : null;
          var updatedOn =
            response.data.updatedOn !== null
              ? response.data.updatedOn.split("T", 2)[0]
              : null;

          var ApproveButton = false;
          var RejectButton = false;
          var UpdateButton = true;
          var EditButton = false;

          if (response.data.approveStatus == true) {
            if (
              response.data.status == 1 ||
              response.data.status == 17 ||
              response.data.status == 18 ||
              response.data.status == 19 ||
              response.data.status == 20
            ) {
              ApproveButton = true;
              RejectButton = true;
              UpdateButton = true;
              EditButton = true;
            }
          } else if (
            response.data.status == 1 ||
            response.data.status == 17 ||
            response.data.status == 18 ||
            response.data.status == 19 ||
            response.data.status == 20
          ) {
            UpdateButton = true;
            EditButton = true;
          }

          if (
            response.data.status == 4 ||
            response.data.status == 3 ||
            response.data.status == 5
          ) {
            ApproveButton = false;
            RejectButton = false;
            UpdateButton = false;
            EditButton = false;
          }

          let type = "";
          if (isEmpty(response.data.salesOrderId)) {
            type = false;
          } else {
            type = true;
          }
          var roundingFlag = null;
          if (response.data.roundingFlag == true) {
            roundingFlag = 1;
          } else if (response.data.roundingFlag == false) {
            roundingFlag = 2;
          } else {
            roundingFlag = null;
          }
          var roundoff = roundAndRemainingValues(Invoiceupdate, roundingFlag);

          const Header = {
            invoiceNo: response.data.invoiceNo,
            regInvoiceId: response.data.regInvoiceId,
            salesOrderId: response.data.salesOrderId,
            salesOrderNo: response.data.salesOrderNo,
            deliveryOrderId: response.data.deliveryOrderId,
            deliveryOrderNo: response.data.deliveryOrderNo,
            invoiceType: response.data.invoiceType,
            invoiceTypeDesc: response.data.invoiceTypeDesc,
            branchId: response.data.branchId,
            branchName: response.data.branchName,
            billingFrom: response.data.billingFrom,
            shippingFrom: response.data.shippingFrom,
            billingFromAddress: response.data.billingFromAddress,
            shippingFromAddress: response.data.shippingFromAddress,
            transporterNumber: response.data.transporterNumber,
            transporterDocNo: response.data.transporterDocNo,
            brokerId:
              response.data.brokerId == null ? "" : response.data.brokerId,
            brokerName: response.data.brokerName,
            customerId: response.data.customerId,
            customerName: response.data.customerName,
            customerResponse:response.data.customerId !==null ? true:false,
            bankId: response.data.bankId,
            bankBranch: response.data.bankBranch,
            bankName: response.data.bankName,
            ifscCode: response.data.ifscCode,
            accountNo: response.data.accountNo,
            micrCode: response.data.micrCode,
            billingTo: response.data.billingTo,
            billingAddress: response.data.billingAddress,
            billingState: response.data.billingState,
            billedToContactNumber: response.data.billedToContactNumber,
            billedToGstin: response.data.billedToGstin,
            billedToContactPerson: response.data.billedToContactPerson,
            billedToEmail: response.data.billedToEmail,
            shippingTo: response.data.shippingTo,
            shippingAddress: response.data.shippingAddress,
            shippingState: response.data.shippingState,
            shippedToContactNumber: response.data.shippedToContactNumber,
            shippedToGstin: response.data.shippedToGstin,
            shippedToContactPerson: response.data.shippedToContactPerson,
            shippedToEmail: response.data.shippedToEmail,
            taxType: response.data.taxType,
            taxTypeDesc: response.data.taxName,
            invoiceDate: invoiceDate,
            salesOrderDate: salesdate,
            deliveryOrderDate: deliveryOrderDate,
            quotationDate: quotationDate,
            quotationNo: response.data.quotationNumber,
            vehicleNo: response.data.vehicleNumber,
            poNumber: response.data.poNumber,
            customerReferenceNumber: response.data.customerReferenceNumber,
            finYear: response.data.finYear,
            createdBy: response.data.createdBy,
            createdDate: createddate,
            companyId: response.data.companyId,
            customerBranchId: response.data.customerBranchId,
            transporterId: response.data.transporterId,
            transporterName: response.data.transporterName,
            transportationMode: response.data.transportationMode,
            transporterAddress: response.data.transporterAddress,
            transporterStateCode: response.data.transporterStateCode,
            transporterStateName: response.data.transporterStateName,
            transporterGst: response.data.transporterGst,
            vehicleType: response.data.vehicleType,
            transportationDistance: response.data.transportationDistance,
            challanNo: response.data.challanNo,
            challanDate: response.data.challanDate,
            brokerCommision: "",
            brokerCommisionPercent: response.data.brokerCommissionPercent,
            footerNote: response.data.footerNote,
            internalNote: response.data.internalNote,
            termsConditionType: response.data.termsConditionType,
            termsCondition: response.data.termsCondition,
            deliveryTerms: response.data.deliveryTerms,
            paymentTerms: response.data.paymentTerms,
            deliveryDays: response.data.deliveryDays,
            freightCharges: response.data.freightCharges,
            amountWithoutTax: response.data.amountWithoutTax,
            amountWithTax: response.data.amountWithTax,
            updatedBy: response.data.updatedBy,
            updatedOn: updatedOn,
            updatedByDesc: response.data.updatedByDesc,
            approvedBy: response.data.approvedBy,
            approvedOn: response.data.approvedOn,
            statusId: response.data.status,
            statusName: response.data.statusDesc,
            gstSuppType: "",
            gstInvoiceStatus: "",
            gstInvoiceCategory: "",
            gstInvoiceType: response.data.gstInvoiceType,
            gstAmtTaxable: "",
            gst_txpd_taxable_value: "",
            gstShippingBillNo: "",
            gstShippingBillDate: "",
            gstReason: "",
            gstr1ReturnPeriod: "",
            gstr3bReturnPeriod: "",
            reverseCharge: "",
            isAmended: "",
            amendedPos: "",
            amendedPeriod: "",
            totalIGST:
              total_IGST == null ? "0.00" : parseFloat(total_IGST).toFixed(2),
            totalCGST:
              total_CGST == null ? "0.00" : parseFloat(total_CGST).toFixed(2),
            totalSGST:
              total_SGST == null ? "0.00" : parseFloat(total_SGST).toFixed(2),
            subTotal: parseFloat(netAmount).toFixed(2),
            taxTotal: parseFloat(Tax_Totals).toFixed(2),
            netTotal: parseFloat(netAmount).toFixed(2),
            TotalAmount: parseFloat(Number(roundoff.RoundoffValue)).toFixed(2),
            brokerContactNumber: response.data.brokerContactNumber,
            brokerContactPerson: response.data.brokerContactPerson,
            consigneeContactNumber: response.data.consigneeContactNumber,
            consigneeContactPerson: response.data.consigneeContactPerson,
            ApproveButton: ApproveButton,
            RejectButton: RejectButton,
            UpdateButton: UpdateButton,
            EditButton: EditButton,
            createWithDirectInvoice: type,
            roundOffValue: response.data.roundOffValue,
            roundingFlag: response.data.roundingFlag,
            roundingPositiveFlag:
              response.data.roundingFlag == true ? true : false,
            roundingNegetiveFlag:
              response.data.roundingFlag == false ? true : false,
            roundingPositiveValue: roundoff.positiveRF,
            roundingNegetiveValue: roundoff.NegativeRF,
            disableBrokerInvoice:
              response.data.brokerId !== null ? true : false,
            disableconsigneeInvoice:
              response.data.customerId !== null ? true : false,
            ewayBillNo: response.data.ewayBillNo,
            eInvoiceAuthenticationApproval: response.data.eInvoiceAuthenticationApproval
          };
          yield put(getRegularInvoiceHeader(Header));
          if (type == false) {
            Invoiceupdate.push({
              id: Invoiceupdate.length + 1,
              salesRegularInvoiceLineitemId: null,
              createdBy: null,
              createdDate: "",
              companyId: null,
              companyName: "",
              salesId: null,
              salesLineItemId: null,
              itemGroupId: "",
              itemGroupName: "",
              itemGroupValue: "",
              itemId: null,
              itemName: "",
              itemCode: "",
              itemValue: "",
              hsncode: "",
              itemMake: "",
              quantity: 0,
              uom: "",
              rate: "",
              invoiceQty: null,
              igstAmount: "",
              igstPercent: "",
              cgstAmount: "",
              cgstPercent: "",
              sgstAmount: "",
              sgstPercent: "",
              cessAmount: null,
              discountType: "",
              discountName: "",
              discount: "",
              discountedRate: "",
              netAmount: "",
              totalAmount: "",
              remarks: "",
              taxPercent: "0.00",
              balesPacketSlNo: "",
              isActive: 1,
              status: 1,
              updatedBy: null,
              updatedOn: null,
              regInvoiceId: null,
              ItemNameData: [],
            });
          }
          yield put(getRegularInvoiceLineItems(Invoiceupdate));
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function* UpdateRegualarInvoiceData({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(UpdateRegularInvoiceSuccessfull({ response }));
    if (response) {
      hideSpinner();
      if (response.data) {
        if (response.data.status) {
          swal(response.data.message, { icon: "success" });
        } else {
          swal(response.data.message, { icon: "error" });
        }
      }
    }
    history.push("/invoice");
  } catch (error) {
    console.log(error);
    hideSpinner();
  }
}

function* getRegularInvoiceItemByItemGroupData({
  payload: { url, data, history, rowId },
}) {
  const { RegularInvoiceLineItems } = yield select(
    (state) => state.InvoiceReducer
  );
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    if (response) {
      hideSpinner();

      yield put(getRegularInvoiceItemsByItemGroupIdSuccessfull({ response }));
      if (response) {
        let ListFilter = response.filter((data) => {
          if (data.value !== "0") {
            return data;
          }
        });
        var list = [];
        ListFilter.map((prop) => {
          list.push({
            label: prop.itemDesc,
            name: prop.itemDesc,
            value: prop.itemId,
            variant: prop.itemCode,
          });
        });
      }
    }
    if (RegularInvoiceLineItems) {
      var LineItemsRecords = [];
      if (RegularInvoiceLineItems.length !== 0) {
        LineItemsRecords = RegularInvoiceLineItems.map((item) => {
          if (rowId == item.id) {
            item.ItemNameData = list;
          }
          return item;
        });
        yield put(getRegularInvoiceLineItems(LineItemsRecords));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* getRegularInvoiceItemDetailsByItemId({
  payload: { url, data, history, rowId },
}) {
  console.log(data);
  const { RegularInvoiceLineItems} = yield select(
    (state) => state.InvoiceReducer
  );
  try {
    // showSpinner();
    const response = yield call(getList, url, data);
    if (response) {
      // hideSpinner();
      if(RegularInvoiceLineItems){
      if (RegularInvoiceLineItems.length !== 0) {
        var LineItemsRecords = [];
        LineItemsRecords= RegularInvoiceLineItems.map((item) => {
          if (rowId == item.id) {
            item.hsncode = response.hsnCode;
            item.rate = limitDecimals(response.srRate,3);
            item.discountedRate = limitDecimals(response.srRate, 3);
            item.uom = response.uomCode;
            item.taxPercent = limitDecimals(response.taxPercentage, 2);
          }
          return item;
        });
        yield put(getRegularInvoiceLineItems(LineItemsRecords));
      }
    }
      

      yield put(getRegularInvoiceItemDetailsByItemIdSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getJuteGovtInvoiceItemByItemGroupData({
  payload: { url, data, history, rowId },
}) {
  const { JuteGovtInvoiceLineItems } = yield select(
    (state) => state.InvoiceReducer
  );
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    if (response) {
      hideSpinner();
      yield put(getJuteGovtInvoiceItemsByItemGroupIdSuccessfull({ response }));
      if (response) {
        let ListFilter = response.filter((data) => {
          if (data.value !== "0") {
            return data;
          }
        });
        var list = [];
        ListFilter.map((prop) => {
          list.push({
            label: prop.label,
            name: prop.name,
            value: prop.itemCode,
            id: prop.itemId,
          });
        });
      }
    }
    if (JuteGovtInvoiceLineItems) {
      var LineItemsRecords = [];
      if (JuteGovtInvoiceLineItems.length !== 0) {
        LineItemsRecords = JuteGovtInvoiceLineItems.map((item) => {
          if (rowId == item.id) {
            item.ItemNameData = list;
          }
          return item;
        });
        yield put(getJuteGovtInvoiceLineItems(LineItemsRecords));
      }
    }
  } catch (error) {
    console.log(error);
  }
}
// Jute Govt Sales Order ViewbyID
function* getSalesOrderViewByIdForJuteGovtInvoiceData({
  payload: { url, history },
}) {
  try {
    const response = yield call(getListurl, url);
    yield put(getSalesOrderViewByIdForJuteGovtInvoiceSuccessfull({ response }));
    if (isEmptyObject(response.data)) {
      swal("Something Went Wrong", { icon: "error" });
    } else {
      if (response) {
        if (response.data) {
          var CustomerUrl =
            serverApi.GET_ALL_BILLING_SHIPPING +
            response.data.salesOrderDTO.customerId +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).cipher +
            "?isForSalesModule=1";
          yield put(getBillingShippingList(CustomerUrl, history));
          if (response.data.salesOrderDTO.salesOrderLineItemDTO) {
            if (
              response.data.salesOrderDTO.salesOrderLineItemDTO.length !== 0
            ) {
              const firstLineItemData =  response.data.salesOrderDTO.salesOrderLineItemDTO[0];
              const firstLineItemTaxPercentageData = firstLineItemData.itemTaxPercentage;
              var SalesUpdate = [];
              response.data.salesOrderDTO.salesOrderLineItemDTO.map(
                (item, i) => {
                  var createddate =
                    item.createdDate !== null
                      ? item.createdDate.split("T", 2)[0]
                      : null;
                  if (
                    item.invoiceBalQty !== 0 &&
                    item.invoiceBalQty !== null &&
                    item.invoiceBalQty !== undefined &&
                    item.invoiceBalQty !== ""
                  ) {
                    SalesUpdate.push({
                      id: i,
                      regularInvoiceLineitemId: "",
                      govtInvoiceId: "",
                      createdBy: item.createdBy,
                      createdDate: createddate,
                      companyId: item.companyId,
                      companyName: item.companyName,

                      regularSalesOrderId: item.regularSalesOrderId,
                      regularSalesOrderLineItemId:
                        item.regularSalesOrderLineItemId,
                      regularDeliveryOrderID: "",
                      regularDeliveryOrderLineItemID: "",
                      itemGroupId: item.itemGroupId,
                      itemGroupName: item.itemGroupName,
                      itemGroupValue: item.itemGroupValue,
                      itemId: item.itemId,
                      itemName: item.itemName,
                      itemCode: item.itemCode,
                      itemValue: item.itemValue,

                      hsncode: item.hsncode,
                      itemMake: item.itemMake,
                      quantity: limitDecimals(item.invoiceBalQty, 3),
                      uom: item.uom,
                      rate: limitDecimals(item.rate, 3),
                      doBalQty: null,
                      invoiceQty: limitDecimals(item.invoiceBalQty, 3),
                      igstAmount: limitDecimals(
                        (item.discountedRate *
                          item.invoiceBalQty *
                          (item.igstPercent == null ? 0 : item.igstPercent)) /
                          100,
                        2
                      ),
                      igstPercent:
                        item.igstPercent == null
                          ? "0.00"
                          : limitDecimals(item.igstPercent, 2),
                      cgstAmount: limitDecimals(
                        (item.discountedRate *
                          item.invoiceBalQty *
                          (item.cgstPercent == null ? 0 : item.cgstPercent)) /
                          100,
                        2
                      ),
                      cgstPercent:
                        item.cgstPercent == null
                          ? "0.00"
                          : limitDecimals(item.cgstPercent, 2),
                      sgstAmount: limitDecimals(
                        (item.discountedRate *
                          item.invoiceBalQty *
                          (item.sgstPercent == null ? 0 : item.sgstPercent)) /
                          100,
                        2
                      ),

                      sgstPercent:
                        item.sgstPercent == null
                          ? "0.00"
                          : limitDecimals(item.sgstPercent, 2),
                      discountType: item.discountType,
                      discountName: item.discountTypeName,
                      discount: limitDecimals(item.discount, 2),
                      discountedRate: limitDecimals(item.discountedRate, 3),
                      discountAmount: limitDecimals(item.discountAmount, 2),
                      totalAmount: limitDecimals(item.totalAmount, 2),
                      netAmount: limitDecimals(
                        item.discountedRate * item.invoiceBalQty,
                        2
                      ),
                      remarks: item.remarks,
                      taxPercent: limitDecimals(firstLineItemTaxPercentageData, 2),
                      balesPacketSlNo: "",
                      isActive: item.isActive,
                      govtInvoiceIsActive: item.govtIsActive,
                      status: item.status,
                      updatedBy: null,
                      updatedOn: null,
                      regInvoiceId: null,
                      govtInvoiceLineItemId: "",
                      govtInvoiceHdrId: "",

                      govtInvoiceRateUom: item.govtRateUom,
                      govtInvoiceRateUom2: item.govtRateUom2,
                      govtInvoiceRateUom3: item.govtRateUom3,

                      govtInvoiceQuantityUom: item.govtQuantityUom,
                      govtInvoiceQuantityUom2: item.govtQuantityUom2,
                      govtInvoiceQuantityUom3: item.govtQuantityUom3,

                      govtInvoiceRelationValue: item.govtRelationValue,
                      govtInvoiceRelationValue2: item.govtRelationValue2,
                      govtInvoiceRelationValue3: item.govtRelationValue3,

                      govtInvoiceQtyCalculatedValue:
                        item.govtQtyCalculatedValue,
                      govtInvoiceQtyCalculatedValue2:
                        item.govtQtyCalculatedValue2,
                      govtInvoiceQtyCalculatedValue3:
                        item.govtQtyCalculatedValue3,

                      govtInvoiceRateCalculatedValue:
                        item.govtRateCalculatedValue,
                      govtInvoiceRateCalculatedValue2:
                        item.govtRateCalculatedValue2,
                      govtInvoiceRateCalculatedValue3:
                        item.govtRateCalculatedValue3,
                      govtInvoiceBalesPacketSlNo: null,
                      ItemNameData: [],
                    });
                  }
                }
              );
              yield put(getJuteGovtInvoiceLineItems(SalesUpdate));
            }
          }
          var TotalCGST = "0.00";
          if (SalesUpdate.length !== 0) {
            TotalCGST = SalesUpdate.map((p) =>
              p.cgstAmount == null ? 0 : p.cgstAmount
            ).reduce(
              (cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind)
            );
          }

          var TotalSGST = "0.00";
          if (SalesUpdate.length !== 0) {
            TotalSGST = SalesUpdate.map((p) =>
              p.sgstAmount == null ? 0 : p.sgstAmount
            ).reduce(
              (sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind)
            );
          }
          var TotalIGST = "0.00";
          if (SalesUpdate.length !== 0) {
            TotalIGST = SalesUpdate.map((p) =>
              p.igstAmount == null ? 0 : p.igstAmount
            ).reduce(
              (igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind)
            );
          }
          var netAmount = "0.00";
          if (SalesUpdate.length !== 0) {
            netAmount = SalesUpdate.map((p) => p.netAmount).reduce(
              (netAmount, ind) => parseFloat(netAmount) + parseFloat(ind)
            );
          }
          var taxTotals =
            Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);
          var createddate =
            response.data.salesOrderDTO.createdDate !== null
              ? response.data.salesOrderDTO.createdDate.split("T", 2)[0]
              : null;

          var salesdate =
            response.data.salesOrderDTO.salesDate !== null
              ? response.data.salesOrderDTO.salesDate.split("T", 2)[0]
              : null;
          var pcsoDate =
            response.data.pcsoDate !== null
              ? response.data.pcsoDate.split("T", 2)[0]
              : null;
          var ammendmentDate =
            response.data.ammendmendDate !== null
              ? response.data.ammendmendDate.split("T", 2)[0]
              : null;

          if (response.data.salesOrderDTO) {
            var roundingFlag = null;
            if (response.data.roundingFlag == true) {
              roundingFlag = 1;
            } else if (response.data.roundingFlag == false) {
              roundingFlag = 2;
            } else {
              roundingFlag = null;
            }
            var roundoff = roundAndRemainingValues(SalesUpdate, roundingFlag);
            const Header = {
              govtInvoiceId: "",
              invoiceNo: "",
              regularInvoiceId: "",
              regInvoiceId: "",
              regularSalesOrderId: response.data.salesOrderDTO.regularSalesId,
              salesOrderNumber: response.data.salesOrderDTO.salesNo,
              deliveryOrderId: "",
              deliveryOrderNo: "",
              invoiceType: response.data.salesOrderDTO.invoiceTypeId,
              invoiceTypeDesc: response.data.salesOrderDTO.invoiceTypeName,
              branchId: response.data.salesOrderDTO.branchId,
              branchName: response.data.salesOrderDTO.branchName,
              billingFrom: response.data.salesOrderDTO.billingFrom,
              shippingFrom: response.data.salesOrderDTO.shippingFrom,
              billingFromAddress:
                response.data.salesOrderDTO.billingFromAddress,
              shippingFromAddress:
                response.data.salesOrderDTO.shippingFromAddress,
              transporterNumber: response.data.salesOrderDTO.transporterNumber,
              transporterDocNo: response.data.salesOrderDTO.transporterDocNo,
              customerId: response.data.salesOrderDTO.customerId,
              customerName: response.data.salesOrderDTO.customerName,
              bankId: response.data.salesOrderDTO.bankId,
              bankBranch: response.data.salesOrderDTO.bankBranch,
              bankName: response.data.salesOrderDTO.bankName,
              ifscCode: response.data.salesOrderDTO.ifscCode,
              accountNo: response.data.salesOrderDTO.accountNo,
              micrCode: response.data.salesOrderDTO.micrCode,
              brokerId: null,
              brokerName: null,
              brokerContactPerson: null,
              brokerContactNumber: null,
              consigneeContactPerson: "",
              consigneeContactNumber: "",
              billingTo: response.data.salesOrderDTO.billingTo,
              billingAddress: response.data.salesOrderDTO.billingToAddress,
              billingState: response.data.salesOrderDTO.billingToState,
              billedToContactNumber:
                response.data.salesOrderDTO.billedToContactNumber,
              billedToGstin: response.data.salesOrderDTO.billedToGstin,
              billedToContactPerson:
                response.data.salesOrderDTO.billedToContactPerson,
              billedToEmail: response.data.salesOrderDTO.billedToEmail,
              shippingTo: response.data.salesOrderDTO.shippingTo,
              shippingAddress: response.data.salesOrderDTO.shippingToAddress,
              shippingState: response.data.salesOrderDTO.shippingToState,
              shippedToContactNumber:
                response.data.salesOrderDTO.shippedToContactNumber,
              shippedToGstin: response.data.salesOrderDTO.shippedToGstin,
              shippedToContactPerson:
                response.data.salesOrderDTO.shippedToContactPerson,
              shippedToEmail: response.data.salesOrderDTO.shippedToEmail,
              taxType: response.data.salesOrderDTO.taxType,
              taxName: response.data.salesOrderDTO.taxName,
              invoiceDate: null,
              salesOrderDate: salesdate,
              deliveryDate: null,
              pcsoNo: response.data.pcsoNo,
              pcsoDate: pcsoDate,
              vehicleNo: "",
              finYear: response.data.salesOrderDTO.finYear,
              createdBy: response.data.salesOrderDTO.createdBy,
              createdDate: createddate,
              createdByDesc: response.data.salesOrderDTO.createdByDesc,
              companyId: response.data.salesOrderDTO.companyId,
              customerBranchId: "",
              transporterId: response.data.salesOrderDTO.transporterId,
              transporterName: response.data.salesOrderDTO.transporterName,
              transporterAddress:
                response.data.salesOrderDTO.transporterAddress,
              transporterStateCode:
                response.data.salesOrderDTO.transporterStateCode,
              transporterStateName:
                response.data.salesOrderDTO.transporterStateName,
              transporterGst: response.data.salesOrderDTO.transporterGst,
              challanNo: response.data.salesOrderDTO.challanNo,
              challanDate: response.data.salesOrderDTO.challanDate,
              ammendmentNo: response.data.ammendmendNo,
              ammendmentDate: ammendmentDate,
              loadingPoint: "",
              destinationRailHead: "",
              containerNo: "",
              footerNote: response.data.salesOrderDTO.footerNote,
              internalNote: response.data.salesOrderDTO.internalNote,
              termsConditionType:
                response.data.salesOrderDTO.termsConditionType,
              termsCondition: response.data.salesOrderDTO.termsCondition,
              deliveryTerms: response.data.salesOrderDTO.deliveryTerms,
              paymentTerms: response.data.salesOrderDTO.paymentTerms,
              amountWithoutTax: response.data.salesOrderDTO.amountWithoutTax,
              amountWithTax: response.data.salesOrderDTO.amountWithTax,
              updatedBy: response.data.salesOrderDTO.updatedBy,
              updatedByDesc: response.data.salesOrderDTO.updatedByDesc,
              updatedOn: response.data.salesOrderDTO.updatedOn,
              approvedBy: response.data.salesOrderDTO.approvedBy,
              approvedOn: response.data.salesOrderDTO.approvedOn,
              approvedByDesc: response.data.salesOrderDTO.approvedByDesc,
              status: response.data.salesOrderDTO.statusId,
              statusName: response.data.salesOrderDTO.statusName,
              quotationDate: null,
              quotationNumber: null,
              totalIGST:
                TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
              totalCGST:
                TotalCGST == null ? "0.00" : parseFloat(TotalCGST).toFixed(2),
              totalSGST:
                TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),
              subTotal: parseFloat(netAmount).toFixed(2),
              taxTotal: parseFloat(taxTotals).toFixed(2),
              netTotal: parseFloat(netAmount).toFixed(2),
              TotalAmount: limitDecimals(Number(netAmount) + Number(taxTotals),2),
              // TotalAmount: parseFloat(Number(roundoff.RoundoffValue)).toFixed(2),
              printedOn: "",
              vehicleNumber: "",
              gstSuppType: null,
              gstInvoiceStatus: null,
              gstInvoiceCategory: null,
              gstInvoiceType: null,
              gstAmtTaxable: null,
              gst_txpd_taxable_value: null,
              gstShippingBillNo: null,
              gstShippingBillDate: null,
              gstReason: null,
              gstr1ReturnPeriod: null,
              gstr3bReturnPeriod: null,
              reverseCharge: null,
              isAmended: null,
              amendedPos: null,
              amendedPeriod: null,
              poNumber: null,
              approveStatus: true,
              brokerCommissionPercent: null,
              ApproveButton: false,
              RejectButton: false,
              UpdateButton: true,

              roundOffValue: null,
              roundingFlag: null,
              roundingPositiveFlag:null,
                // response.data.salesOrderDTO.roundingFlag == true ? true : false,
              roundingNegetiveFlag:null,
                // response.data.salesOrderDTO.roundingFlag == false? true: false,
              roundingPositiveValue: roundoff.positiveRF,
              roundingNegetiveValue: roundoff.NegativeRF,
            };
            yield put(getJuteGovtInvoiceHeader(Header));
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
// Jute Govt Delivery Order ViewbyID
function* getDeliveryOrderViewByIdForJuteGovtInvoiceData({
  payload: { url, history },
}) {
  const { juteGovtInvoiceHeader } = yield select(
    (state) => state.InvoiceReducer
  );
  try {
    const response = yield call(getListurl, url);
    yield put(
      getDeliveryOrderViewByIdForJuteGovtInvoiceSuccessfull({ response })
    );
    if (isEmptyObject(response.data)) {
      swal("Something Went Wrong", { icon: "error" });
    } else {
      if (response) {
        if (response.data) {
          if (response.data.deliveryOrderDTO) {
            if (response.data.deliveryOrderDTO.deliveryOrderLineItemDTO) {
              if (
                response.data.deliveryOrderDTO.deliveryOrderLineItemDTO
                  .length !== 0
              ) {
                var SalesUpdate = [];
          const firstTaxPercentage = response.data.deliveryOrderDTO.deliveryOrderLineItemDTO[0].itemTaxPercentage;

                response.data.deliveryOrderDTO.deliveryOrderLineItemDTO.map(
                  (item, i) => {
                    var createddate =
                      item.createdDate !== null
                        ? item.createdDate.split("T", 2)[0]
                        : null;
                    if (
                      item.invoiceQty !== 0 &&
                      item.invoiceQty !== null &&
                      item.invoiceQty !== undefined &&
                      item.invoiceQty !== ""
                    ) {
                      SalesUpdate.push({
                        id: i,
                        regularInvoiceLineitemId: "",
                        govtInvoiceId: "",
                        createdBy: item.createdBy,
                        createdDate: createddate,
                        companyId: item.companyId,
                        companyName: item.companyName,

                        regularSalesOrderId: item.salesId,
                        regularSalesOrderLineItemId: item.salesLineItemId,
                        regularDeliveryOrderID: item.regDeliveryOrderId,
                        regularDeliveryOrderLineItemID:
                          item.regularDeliveryOrderLineItemId,
                        itemGroupId: item.itemGroupId,
                        itemGroupName: item.itemGroupName,
                        itemGroupValue: item.itemGroupValue,
                        itemId: item.itemId,
                        itemName: item.itemName,
                        itemCode: item.itemCode,
                        itemValue: item.itemValue,
                        hsncode: item.hsncode,
                        itemMake: item.itemMake,
                        quantity: limitDecimals(item.invoiceQty, 3),
                        uom: item.uom,
                        rate: limitDecimals(item.rate, 3),
                        doBalQty: null,
                        invoiceQty: limitDecimals(item.invoiceQty, 3),
                        igstAmount: limitDecimals(
                          (item.discountedRate *
                            item.invoiceQty *
                            (item.igstPercent == null ? 0 : item.igstPercent)) /
                            100,
                          2
                        ),
                        igstPercent:
                          item.igstPercent == null
                            ? "0.00"
                            : limitDecimals(item.igstPercent, 2),
                        cgstAmount: limitDecimals(
                          (item.discountedRate *
                            item.invoiceQty *
                            (item.cgstPercent == null ? 0 : item.cgstPercent)) /
                            100,
                          2
                        ),
                        cgstPercent:
                          item.cgstPercent == null
                            ? "0.00"
                            : limitDecimals(item.cgstPercent, 2),
                        sgstAmount: limitDecimals(
                          (item.discountedRate *
                            item.invoiceQty *
                            (item.sgstPercent == null ? 0 : item.sgstPercent)) /
                            100,
                          2
                        ),

                        sgstPercent:
                          item.sgstPercent == null
                            ? "0.00"
                            : limitDecimals(item.sgstPercent, 2),
                        discountType: item.discountType,
                        discountName: item.discountTypeName,
                        discount: limitDecimals(item.discount, 2),
                        discountedRate: limitDecimals(item.discountedRate, 3),
                        discountAmount: limitDecimals(item.discountAmount, 2),
                        totalAmount: limitDecimals(item.totalAmount, 2),
                        netAmount: limitDecimals(
                          item.discountedRate * item.invoiceQty,
                          2
                        ),
                        remarks: item.remarks,
                        taxPercent: limitDecimals(firstTaxPercentage, 2),
                        balesPacketSlNo: "",
                        isActive: item.isActive,
                        govtInvoiceIsActive: item.govtIsActive,
                        status: item.status,
                        updatedBy: null,
                        updatedOn: null,
                        regInvoiceId: null,
                        govtInvoiceLineItemId: "",
                        govtInvoiceHdrId: "",

                        govtInvoiceRateUom: item.govtDeliveryRateUom,
                        govtInvoiceRateUom2: item.govtDeliveryRateUom2,
                        govtInvoiceRateUom3: item.govtDeliveryRateUom3,

                        govtInvoiceQuantityUom: item.govtDeliveryQuantityUom,
                        govtInvoiceQuantityUom2: item.govtDeliveryQuantityUom2,
                        govtInvoiceQuantityUom3: item.govtDeliveryQuantityUom3,

                        govtInvoiceRelationValue:
                          item.govtDeliveryRelationValue,
                        govtInvoiceRelationValue2:
                          item.govtDeliveryRelationValue2,
                        govtInvoiceRelationValue3:
                          item.govtDeliveryRelationValue3,

                        govtInvoiceQtyCalculatedValue:
                          item.govtDeliveryQtyCalculatedValue,
                        govtInvoiceQtyCalculatedValue2:
                          item.govtDeliveryQtyCalculatedValue2,
                        govtInvoiceQtyCalculatedValue3:
                          item.govtDeliveryQtyCalculatedValue3,

                        govtInvoiceRateCalculatedValue:
                          item.govtDeliveryRateCalculatedValue,
                        govtInvoiceRateCalculatedValue2:
                          item.govtDeliveryRateCalculatedValue2,
                        govtInvoiceRateCalculatedValue3:
                          item.govtDeliveryRateCalculatedValue3,
                        govtInvoiceBalesPacketSlNo: null,
                        ItemNameData: [],
                      });
                    }
                  }
                );
                yield put(getJuteGovtInvoiceLineItems(SalesUpdate));
              }
            }
          }
          var TotalCGST = "0.00";
          if (SalesUpdate.length !== 0) {
            var TotalCGST = SalesUpdate.map((p) =>
              p.cgstAmount == null ? 0 : p.cgstAmount
            ).reduce(
              (cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind)
            );
          }

          var TotalSGST = "0.00";
          if (SalesUpdate.length !== 0) {
            TotalSGST = SalesUpdate.map((p) =>
              p.sgstAmount == null ? 0 : p.sgstAmount
            ).reduce(
              (sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind)
            );
          }
          var TotalIGST = "0.00";
          if (SalesUpdate.length !== 0) {
            TotalIGST = SalesUpdate.map((p) =>
              p.igstAmount == null ? 0 : p.igstAmount
            ).reduce(
              (igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind)
            );
          }
          var netAmount = "0.00";
          if (SalesUpdate.length !== 0) {
            netAmount = SalesUpdate.map((p) => p.netAmount).reduce(
              (netAmount, ind) => parseFloat(netAmount) + parseFloat(ind)
            );
          }
          var taxTotals =
            Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);
          var ammendmentDate =
            response.data.ammendmentDate !== null
              ? response.data.ammendmentDate.split("T", 2)[0]
              : null;
          var roundingFlag = null;
          if (response.data.roundingFlag == true) {
            roundingFlag = 1;
          } else if (response.data.roundingFlag == false) {
            roundingFlag = 2;
          } else {
            roundingFlag = null;
          }
          var roundoff = roundAndRemainingValues(SalesUpdate, roundingFlag);

          const Header = {
            ...juteGovtInvoiceHeader,
            transporterId: response.data.deliveryOrderDTO.transporter,
            transporterName: response.data.deliveryOrderDTO.transporterName,
            transporterAddress:
              response.data.deliveryOrderDTO.transporterAddress,
            transporterStateCode:

              response.data.deliveryOrderDTO.transporterStateCode,
            transporterStateName:
              response.data.deliveryOrderDTO.transporterStateName,
            transporterGst: response.data.deliveryOrderDTO.transporterGst,
            deliveryOrderId: response.data.regDeliveryOrderId,
            deliveryOrderNo: response.data.deliveryOrderDTO.deliveryOrderNo,
            deliveryDate: response.data.deliveryOrderDTO.deliveryDate,
            bankId: response.data.deliveryOrderDTO.bankId,
            bankBranch: response.data.deliveryOrderDTO.bankBranch,
            bankName: response.data.deliveryOrderDTO.bankName,
            ifscCode: response.data.deliveryOrderDTO.ifscCode,
            accountNo: response.data.deliveryOrderDTO.accountNo,
            micrCode: response.data.deliveryOrderDTO.micrCode,
            vehicleNumber: response.data.deliveryOrderDTO.vehicleNumber,
            invoiceDate:null,
            totalIGST:
              TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
            totalCGST:
              TotalCGST == null ? "0.00" : parseFloat(TotalCGST).toFixed(2),
            totalSGST:
              TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),
            subTotal: parseFloat(netAmount).toFixed(2),
            taxTotal: parseFloat(taxTotals).toFixed(2),
            netTotal: parseFloat(netAmount).toFixed(2),
            TotalAmount: limitDecimals(Number(netAmount )+Number( taxTotals)),
            // TotalAmount: parseFloat(Number(roundoff.RoundoffValue)).toFixed(2),
            loadingPoint: response.data.loadingPoint,
            destinationRailHead: response.data.destinationRailHead,

            ammendmentNo: response.data.ammendmentNo,
            ammendmentDate: ammendmentDate,

            roundOffValue: null,
            roundingFlag:null,
            roundingPositiveFlag:null,
              // response.data.roundingFlag == true ? true : false,
            roundingNegetiveFlag:null,
              // response.data.roundingFlag == false ? true : false,
            roundingPositiveValue: roundoff.positiveRF,
            roundingNegetiveValue: roundoff.NegativeRF,
          };

          yield put(getJuteGovtInvoiceHeader(Header));
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
// Jute Govt Invoice ViewbyID
function* getJuteGovtInvoiceViewByIdData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getJuteGovtInvoiceViewByIdSuccessfull({ response }));
    if (isEmptyObject(response.data)) {
      swal("Something Went Wrong", { icon: "error" });
    } else {
      if (response) {
        if (response.data) {
          var CustomerUrl =
            serverApi.GET_ALL_BILLING_SHIPPING +
            response.data.invoiceHdrDto.customerId +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).cipher +
            "?isForSalesModule=1";
          yield put(getBillingShippingList(CustomerUrl, history));
          if (response.data.invoiceHdrDto.invoiceLineItemDto) {
            if (response.data.invoiceHdrDto.invoiceLineItemDto.length !== 0) {
              var Invoiceupdate = [];
              const firstTaxPercentage = response.data.invoiceHdrDto.invoiceLineItemDto[0].itemTaxPercentage;
              response.data.invoiceHdrDto.invoiceLineItemDto.map((item, i) => {
                var createddate =
                  item.createdDate !== null
                    ? item.createdDate.split("T", 2)[0]
                    : null;
                Invoiceupdate.push({
                  id: i,
                  regularInvoiceLineitemId: item.salesRegularInvoiceLineitemId,
                  govtInvoiceId: item.govtInvoiceHdrId,
                  createdBy: item.createdBy,
                  createdDate: createddate,
                  companyId: item.companyId,
                  companyName: item.companyName,
                  regularSalesOrderId: item.regularSalesOrderId,
                  regularSalesOrderLineItemId: item.regularSalesOrderLineItemId,
                  regularDeliveryOrderID: item.regularDeliveryOrderID,
                  regularDeliveryOrderLineItemID:
                    item.regularDeliveryOrderLineItemID,
                  itemGroupId: item.itemGroupId,
                  itemGroupName: item.itemGroupName,
                  itemGroupValue: item.itemGroupValue,
                  itemId: item.itemId,
                  itemName: item.itemName,
                  itemCode: item.itemCode,
                  itemValue: item.itemValue,
                  hsncode: item.hsncode,
                  itemMake: item.itemMake,
                  quantity: limitDecimals(item.quantity, 3),
                  uom: item.uom,
                  rate: limitDecimals(item.rate, 3),
                  doBalQty: null,
                  invoiceQty: limitDecimals(item.invoiceQty, 3),
                  igstAmount: limitDecimals(
                    (item.discountedRate *
                      item.quantity *
                      (item.igstPercent == null ? 0 : item.igstPercent)) /
                      100,
                    2
                  ),
                  igstPercent:
                    item.igstPercent == null
                      ? "0.00"
                      : limitDecimals(item.igstPercent, 2),
                  cgstAmount: limitDecimals(
                    (item.discountedRate *
                      item.quantity *
                      (item.cgstPercent == null ? 0 : item.cgstPercent)) /
                      100,
                    2
                  ),
                  cgstPercent:
                    item.cgstPercent == null
                      ? "0.00"
                      : limitDecimals(item.cgstPercent, 2),
                  sgstAmount: limitDecimals(
                    (item.discountedRate *
                      item.quantity *
                      (item.sgstPercent == null ? 0 : item.sgstPercent)) /
                      100,
                    2
                  ),

                  sgstPercent:
                    item.sgstPercent == null
                      ? "0.00"
                      : limitDecimals(item.sgstPercent, 2),
                  discountType: item.discountType,
                  discountName: item.discountTypeName,
                  discount: limitDecimals(item.discount, 2),
                  discountedRate: limitDecimals(item.discountedRate, 3),
                  discountAmount: limitDecimals(item.discountAmount, 2),
                  totalAmount: limitDecimals(item.totalAmount, 2),
                  netAmount: limitDecimals(
                    item.discountedRate * item.quantity,
                    2
                  ),
                  remarks: item.remarks,
                  taxPercent: limitDecimals(firstTaxPercentage , 2),
                  balesPacketSlNo: "",
                  isActive: item.isActive,
                  govtInvoiceIsActive: item.govtInvoiceIsActive,
                  status: item.status,
                  updatedBy: null,
                  updatedOn: null,
                  regInvoiceId: item.regInvoiceId,

                  govtInvoiceLineItemId: item.govtInvoiceLineItemId,

                  govtInvoiceHdrId: item.govtInvoiceHdrId,
                  govtInvoiceRateUom: item.govtInvoiceRateUom,
                  govtInvoiceRateUom2: item.govtInvoiceRateUom2,
                  govtInvoiceRateUom3: item.govtInvoiceRateUom3,

                  govtInvoiceQuantityUom: item.govtInvoiceQuantityUom,
                  govtInvoiceQuantityUom2: item.govtInvoiceQuantityUom2,
                  govtInvoiceQuantityUom3: item.govtInvoiceQuantityUom3,

                  govtInvoiceRelationValue: item.govtInvoiceRelationValue,
                  govtInvoiceRelationValue2: item.govtInvoiceRelationValue2,
                  govtInvoiceRelationValue3: item.govtInvoiceRelationValue3,

                  govtInvoiceQtyCalculatedValue:
                    item.govtInvoiceQtyCalculatedValue,
                  govtInvoiceQtyCalculatedValue2:
                    item.govtInvoiceQtyCalculatedValue2,
                  govtInvoiceQtyCalculatedValue3:
                    item.govtInvoiceQtyCalculatedValue3,

                  govtInvoiceRateCalculatedValue:
                    item.govtInvoiceRateCalculatedValue,
                  govtInvoiceRateCalculatedValue2:
                    item.govtInvoiceRateCalculatedValue2,
                  govtInvoiceRateCalculatedValue3:
                    item.govtInvoiceRateCalculatedValue3,
                  govtInvoiceBalesPacketSlNo: item.govtInvoiceBalesPacketSlNo,
                  ItemNameData: [],
                });
              });

              yield put(getJuteGovtInvoiceLineItems(Invoiceupdate));

              var total_CGST = response.data.invoiceHdrDto.invoiceLineItemDto
                .filter((item) => item.isActive != 0)
                .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
                .reduce(
                  (cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind)
                );
              var total_SGST = response.data.invoiceHdrDto.invoiceLineItemDto
                .filter((item) => item.isActive != 0)
                .map((p) => (p.sgstAmount == null ? 0 : p.sgstAmount))
                .reduce(
                  (sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind)
                );
              var total_IGST = response.data.invoiceHdrDto.invoiceLineItemDto
                .filter((item) => item.isActive != 0)
                .map((p) => (p.igstAmount == null ? 0 : p.igstAmount))
                .reduce(
                  (igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind)
                );

              var netAmount = response.data.invoiceHdrDto.invoiceLineItemDto
                .filter((item) => item.isActive != 0)
                .map((p) => p.netAmount)
                .reduce(
                  (netAmount, ind) => parseFloat(netAmount) + parseFloat(ind)
                );
              var Tax_Totals =
                Number(total_CGST) + Number(total_SGST) + Number(total_IGST);
            }
          }
          var createddate =
            response.data.invoiceHdrDto.createdDate !== null
              ? response.data.invoiceHdrDto.createdDate.split("T", 2)[0]
              : null;
          var salesdate =
            response.data.invoiceHdrDto.salesOrderDate !== null
              ? response.data.invoiceHdrDto.salesOrderDate.split(" ", 2)[0]
              : null;
          var pcsoDate =
            response.data.pcsoDate !== null
              ? response.data.pcsoDate.split("T", 2)[0]
              : null;
          var ammendmentDate =
            response.data.ammendmentDate !== null
              ? response.data.ammendmentDate.split("T", 2)[0]
              : null;
          var deliveryOrderDate =
            response.data.invoiceHdrDto.deliveryDate !== null
              ? response.data.invoiceHdrDto.deliveryDate.split(" ", 2)[0]
              : null;
          var challanDate =
            response.data.invoiceHdrDto.challanDate !== null
              ? response.data.invoiceHdrDto.challanDate.split("T", 2)[0]
              : null;
          var ApproveButton = false;
          var RejectButton = false;
          var UpdateButton = true;
          var EditButton = false;
          if (response.data.invoiceHdrDto.approveStatus === true) {
            if (
              response.data.invoiceHdrDto.status === 1 ||
              response.data.invoiceHdrDto.status === 17 ||
              response.data.invoiceHdrDto.status === 18 ||
              response.data.invoiceHdrDto.status === 19 ||
              response.data.invoiceHdrDto.status === 20
            ) {
              ApproveButton = true;
              RejectButton = true;
              UpdateButton = true;
              EditButton = true;
            }
          } else if (
            response.data.invoiceHdrDto.status === 1 ||
            response.data.invoiceHdrDto.status === 17 ||
            response.data.invoiceHdrDto.status === 18 ||
            response.data.invoiceHdrDto.status === 19 ||
            response.data.invoiceHdrDto.status === 20
          ) {
            UpdateButton = true;
            EditButton = true;
          }

          if (
            response.data.invoiceHdrDto.status === 4 ||
            response.data.invoiceHdrDto.status === 3 ||
            response.data.invoiceHdrDto.status === 5
          ) {
            ApproveButton = false;
            RejectButton = false;
            UpdateButton = false;
            EditButton = false;
          }
          var roundingFlag = null;
          if (response.data.roundingFlag == true) {
            roundingFlag = 1;
          } else if (response.data.roundingFlag == false) {
            roundingFlag = 2;
          } else {
            roundingFlag = null;
          }
          var roundoff = roundAndRemainingValues(Invoiceupdate, roundingFlag);
          const Header = {
            govtInvoiceId: response.data.govtInvoiceId,
            invoiceNo: response.data.invoiceHdrDto.invoiceNo,
            regularInvoiceId: response.data.regularInvoiceId,
            regInvoiceId: response.data.regularInvoiceId,
            regularSalesOrderId:
              response.data.invoiceHdrDto.regularSalesOrderId,
            salesOrderNumber: response.data.invoiceHdrDto.salesOrderNumber,
            deliveryOrderId: response.data.invoiceHdrDto.deliveryOrderId,
            deliveryOrderNo: response.data.invoiceHdrDto.deliveryOrderNo,
            invoiceType: response.data.invoiceHdrDto.invoiceType,
            invoiceTypeDesc: response.data.invoiceHdrDto.invoiceTypeDesc,
            bankId: response.data.invoiceHdrDto.bankId,
            bankBranch: response.data.invoiceHdrDto.bankBranch,
            bankName: response.data.invoiceHdrDto.bankName,
            ifscCode: response.data.invoiceHdrDto.ifscCode,
            accountNo: response.data.invoiceHdrDto.accountNo,
            micrCode: response.data.invoiceHdrDto.micrCode,
            branchId: response.data.invoiceHdrDto.branchId,
            branchName: response.data.invoiceHdrDto.branchName,
            billingFrom: response.data.invoiceHdrDto.billingFrom,
            billingState: response.data.invoiceHdrDto.billingState,
            shippingFrom: response.data.invoiceHdrDto.shippingFrom,
            billingFromAddress: response.data.invoiceHdrDto.billingFromAddress,
            shippingFromAddress:
              response.data.invoiceHdrDto.shippingFromAddress,
            shippingState: response.data.invoiceHdrDto.shippingState,
            transporterNumber: response.data.invoiceHdrDto.transporterNumber,
            transporterDocNo: response.data.invoiceHdrDto.transporterDocNo,
            customerId: response.data.invoiceHdrDto.customerId,
            customerName: response.data.invoiceHdrDto.customerName,
            brokerId: response.data.invoiceHdrDto.brokerId,
            brokerName: response.data.invoiceHdrDto.brokerName,
            brokerContactPerson:
              response.data.invoiceHdrDto.brokerContactPerson,
            brokerContactNumber:
              response.data.invoiceHdrDto.brokerContactNumber,
            consigneeContactPerson:
              response.data.invoiceHdrDto.consigneeContactPerson,
            consigneeContactNumber:
              response.data.invoiceHdrDto.consigneeContactNumber,
            billingTo: response.data.invoiceHdrDto.billingTo,
            billingAddress: response.data.invoiceHdrDto.billingAddress,
            // billingState: response.data.invoiceHdrDto.billedToState,
            billedToContactNumber:
              response.data.invoiceHdrDto.billedToContactNumber,
            billedToGstin: response.data.invoiceHdrDto.billedToGstin,
            billedToContactPerson:
              response.data.invoiceHdrDto.billedToContactPerson,
            billedToEmail: response.data.invoiceHdrDto.billedToEmail,
            shippingTo: response.data.invoiceHdrDto.shippingTo,
            shippingAddress: response.data.invoiceHdrDto.shippingAddress,
            // shippingState: response.data.invoiceHdrDto.shippedToState,
            shippedToContactNumber:
              response.data.invoiceHdrDto.shippedToContactNumber,
            shippedToGstin: response.data.invoiceHdrDto.shippedToGstin,
            shippedToContactPerson:
              response.data.invoiceHdrDto.shippedToContactPerson,
            shippedToEmail: response.data.invoiceHdrDto.shippedToEmail,
            taxType: response.data.invoiceHdrDto.taxType,
            taxName: response.data.invoiceHdrDto.taxName,
            invoiceDate: response.data.invoiceHdrDto.invoiceDate,
            salesOrderDate: salesdate,
            deliveryDate: deliveryOrderDate,
            pcsoNo: response.data.pcsoNo,
            pcsoDate: pcsoDate,
            vehicleNumber: response.data.invoiceHdrDto.vehicleNumber,
            finYear: response.data.invoiceHdrDto.finYear,
            createdBy: response.data.invoiceHdrDto.createdBy,
            createdDate: createddate,
            createdByDesc: response.data.invoiceHdrDto.createdByDesc,
            companyId: response.data.invoiceHdrDto.companyId,
            customerBranchId: "",
            transporterId: response.data.invoiceHdrDto.transporterId,
            transporterName: response.data.invoiceHdrDto.transporterName,
            transporterAddress: response.data.invoiceHdrDto.transporterAddress,
            transporterStateCode:
              response.data.invoiceHdrDto.transporterStateCode,
            transporterStateName:
              response.data.invoiceHdrDto.transporterStateName,
            challanNo: response.data.invoiceHdrDto.challanNo,
            challanDate: challanDate,
            ammendmentNo: response.data.ammendmentNo,
            ammendmentDate: ammendmentDate,
            loadingPoint: response.data.loadingPoint,
            destinationRailHead: response.data.destinationRailHead,
            containerNo: response.data.containerNo,
            footerNote: response.data.invoiceHdrDto.footerNote,
            internalNote: response.data.invoiceHdrDto.internalNote,
            termsConditionType: response.data.invoiceHdrDto.termsConditionType,
            termsCondition: response.data.invoiceHdrDto.termsCondition,
            deliveryTerms: response.data.invoiceHdrDto.deliveryTerms,
            paymentTerms: response.data.invoiceHdrDto.paymentTerms,
            amountWithoutTax: response.data.invoiceHdrDto.amountWithoutTax,
            amountWithTax: response.data.invoiceHdrDto.amountWithTax,
            updatedBy: response.data.invoiceHdrDto.updatedBy,
            updatedByDesc: response.data.invoiceHdrDto.updatedByDesc,
            updatedOn: response.data.invoiceHdrDto.updatedOn,
            approvedBy: response.data.invoiceHdrDto.approvedBy,
            approvedOn: response.data.invoiceHdrDto.approvedOn,
            approvedByDesc: response.data.invoiceHdrDto.approvedByDesc,
            status: response.data.invoiceHdrDto.statusId,
            statusName: response.data.invoiceHdrDto.statusName,
            quotationDate: null,
            quotationNumber: null,
            totalIGST:
              total_IGST == null ? "0.00" : parseFloat(total_IGST).toFixed(2),
            totalCGST:
              total_CGST == null ? "0.00" : parseFloat(total_CGST).toFixed(2),
            totalSGST:
              total_SGST == null ? "0.00" : parseFloat(total_SGST).toFixed(2),

            subTotal: parseFloat(netAmount).toFixed(2),
            taxTotal: parseFloat(Tax_Totals).toFixed(2),
            netTotal: parseFloat(netAmount).toFixed(2),
            // TotalAmount: parseFloat(netAmount + Tax_Totals).toFixed(2),
            TotalAmount: parseFloat(Number(roundoff.RoundoffValue)).toFixed(2),
            printedOn: "",
            gstSuppType: response.data.invoiceHdrDto.gstSuppType,
            gstInvoiceStatus: response.data.invoiceHdrDto.gstInvoiceStatus,
            gstInvoiceCategory: response.data.invoiceHdrDto.gstInvoiceCategory,
            gstInvoiceType: response.data.invoiceHdrDto.gstInvoiceType,
            gstAmtTaxable: response.data.invoiceHdrDto.gstAmtTaxable,
            gst_txpd_taxable_value:
              response.data.invoiceHdrDto.gst_txpd_taxable_value,
            gstShippingBillNo: response.data.invoiceHdrDto.gstShippingBillNo,
            gstShippingBillDate:
              response.data.invoiceHdrDto.gstShippingBillDate,
            gstReason: response.data.invoiceHdrDto.gstReason,
            gstr1ReturnPeriod: response.data.invoiceHdrDto.gstr1ReturnPeriod,
            gstr3bReturnPeriod: response.data.invoiceHdrDto.gstr3bReturnPeriod,
            reverseCharge: response.data.invoiceHdrDto.reverseCharge,
            isAmended: response.data.invoiceHdrDto.isAmended,
            amendedPos: response.data.invoiceHdrDto.amendedPos,
            amendedPeriod: response.data.invoiceHdrDto.amendedPeriod,
            poNumber: response.data.invoiceHdrDto.poNumber,
            approveStatus: response.data.invoiceHdrDto.approveStatus,
            status: response.data.invoiceHdrDto.status,
            brokerCommissionPercent: null,
            ApproveButton: ApproveButton,
            RejectButton: RejectButton,
            UpdateButton: UpdateButton,
            EditButton: EditButton,

            roundOffValue: response.data.invoiceHdrDto.roundOffValue,
            roundingFlag: response.data.invoiceHdrDto.roundingFlag,
            roundingPositiveFlag:
              response.data.invoiceHdrDto.roundingFlag == true ? true : false,
            roundingNegetiveFlag:
              response.data.invoiceHdrDto.roundingFlag == false ? true : false,
            roundingPositiveValue: roundoff.positiveRF,
            roundingNegetiveValue: roundoff.NegativeRF,

            transportationMode: response.data.invoiceHdrDto.transportationMode,
            vehicleType: response.data.invoiceHdrDto.vehicleType,
            transportationDistance:
              response.data.invoiceHdrDto.transportationDistance,
            ewayBillNo: response.data.ewayBillNo,
            eInvoiceAuthenticationApproval: response.data.invoiceHdrDto.eInvoiceAuthenticationApproval
          };
          yield put(getJuteGovtInvoiceHeader(Header));
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function* UpdateJuteGovtInvoiceData({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(UpdateJuteGovtInvoiceSuccessfull({ response }));
    if (response) {
      hideSpinner();
      if (response.data) {
        if (response.data.status) {
          swal(response.data.message, { icon: "success" });
        } else {
          swal(response.data.message, { icon: "error" });
        }
        history.push("/invoice");
      }
    }
  } catch (error) {
    hideSpinner();
    console.log(error);
  }
}

function* getVehicleTypeData({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data, history);
    yield put(getVehicleTypeSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
//Jute Yarn

/**Jute Hessian */
function* getSaleOrderViewByIdForJuteHessianInvoice({
  payload: { url, history },
}) {
  try {
    const response = yield call(getListurl, url);

    if (isEmptyObject(response.data)) {
      swal("Something went wrong");
    } else {
      if(!isEmpty(response.data.salesOrderDTO.customerId)){
      var CustomerUrl =
        serverApi.GET_ALL_BILLING_SHIPPING +
        response.data.salesOrderDTO.customerId +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher +
        "?isForSalesModule=1";
      yield put(getBillingShippingList(CustomerUrl, history));
      }

      if (response.data.salesOrderDTO.salesOrderLineItemDTO) {
        if (response.data.salesOrderDTO.salesOrderLineItemDTO.length !== 0) {
          var SalesUpdate = [];
          response.data.salesOrderDTO.salesOrderLineItemDTO.map((item, i) => {
            SalesUpdate.push({
              id: i,
              regularDeliveryOrderLineItemId:
                item.regularDeliveryOrderLineItemId,
              createdBy: item.createdBy,
              createdDate: item.createdDate,
              regDeliveryOrderId:
                response.data.salesOrderDTO.regDeliveryOrderId,
              companyId: item.companyId,
              salesId: response.data.salesOrderDTO.regularSalesId,
              salesLineItemId: item.regularSalesOrderLineItemId,
              itemGroupId: item.itemGroupId,
              itemGroupName: item.itemGroupName,
              itemGroupValue: item.itemGroupValue,
              itemId: item.itemId,
              itemName: item.itemName,
              itemCode: item.itemCode,
              itemValue: item.itemValue,
              hsncode: item.hsnCode,
              itemMake: item.itemMake,
              quantity: limitDecimals(item.doBalQty, 3),
              taxPercent: limitDecimals(item.itemTaxPercentage, 2),
              uom: item.uom,
              rate: limitDecimals(item.rate, 3),
              invoiceQty: "",
              igstAmount:
                item.igstAmount == null
                  ? "0.00"
                  : limitDecimals(item.igstAmount, 2),
              igstPercent:
                item.igstPercent == null
                  ? "0"
                  : limitDecimals(item.igstPercent, 2),
              cgstAmount:
                item.cgstAmount == null
                  ? "0.00"
                  : limitDecimals(item.cgstAmount, 2),
              cgstPercent:
                item.cgstPercent == null
                  ? "0"
                  : limitDecimals(item.cgstPercent, 2),
              sgstAmount:
                item.sgstAmount == null
                  ? "0.00"
                  : limitDecimals(item.sgstAmount, 2),
              sgstPercent:
                item.sgstPercent == null
                  ? "0"
                  : limitDecimals(item.sgstPercent, 2),
              cessAmount: "",
              discountType: item.discountType,
              discount: limitDecimals(item.discount, 2),
              discountName: item.discountName,
              discountedRate: limitDecimals(item.discountedRate, 3),
              netAmount: limitDecimals(item.netAmount, 2),
              totalAmount: limitDecimals(item.totalAmount, 2),
              remarks: item.remarks,
              balesPacketSlNo: "",
              isActive: item.isActive,
              hessianIsActive:item.hessianIsActive,
              status: item.status,
              updatedBy: null,
              updatedOn: null,
              govtDeliveryOrderLineItemId: "",
              govtDeliveryOrderId: "",
              govtDeliveryBalesPacketSlNo: item.govtDeliveryBalesPacketSlNo,
              govtDeliveryRateUom: item.govtRateUom,
              govtDeliveryRateUom2: item.govtRateUom2,
              govtDeliveryRateUom3: item.govtRateUom3,
              govtDeliveryQuantityUom: item.govtQuantityUom,
              govtDeliveryQuantityUom2: item.govtQuantityUom2,
              govtDeliveryQuantityUom3: item.govtQuantityUom3,
              govtDeliveryRelationValue: item.govtRelationValue,
              govtDeliveryRelationValue2: item.govtRelationValue2,
              govtDeliveryRelationValue3: item.govtRelationValue3,
              govtDeliveryQtyCalculatedValue: limitDecimals(
                item.govtQtyCalculatedValue,
                3
              ),
              govtDeliveryQtyCalculatedValue2: item.govtQtyCalculatedValue2,
              govtDeliveryQtyCalculatedValue3: item.govtQtyCalculatedValue3,
              govtDeliveryRateCalculatedValue: limitDecimals(
                item.govtRateCalculatedValue,
                3
              ),
              govtDeliveryRateCalculatedValue2: item.govtRateCalculatedValue2,
              govtDeliveryRateCalculatedValue3: item.govtRateCalculatedValue3,
              govtUpdatedBy: item.govtUpdatedBy,
              govtUpdatedOn: item.govtUpdatedOn,
              currency: "INR",
              doBalQty: limitDecimals(item.doBalQty, 3),
            });
          });
          yield put(getJuteHessianInvoiceLineItems(SalesUpdate));
        }
      }

      var TotalCGST = response.data.salesOrderDTO.salesOrderLineItemDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
        .reduce((cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind));
      var TotalSGST = response.data.salesOrderDTO.salesOrderLineItemDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.sgstAmount == null ? 0 : p.sgstAmount))
        .reduce((sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind));
      var TotalIGST = response.data.salesOrderDTO.salesOrderLineItemDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.igstAmount == null ? 0 : p.igstAmount))
        .reduce((igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind));
      var totalAmount = response.data.salesOrderDTO.salesOrderLineItemDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => p.totalAmount)
        .reduce(
          (totalAmount, ind) => parseFloat(totalAmount) + parseFloat(ind)
        );
      var taxTotals = Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);

      var createddate =
        response.data.salesOrderDTO.createdDate !== null &&
        response.data.salesOrderDTO.createdDate.split("T");
      var salesdate =
        response.data.salesOrderDTO.salesDate !== null &&
        response.data.salesOrderDTO.salesDate.split("T");

      const Header = {
        salesOrderId: response.data.salesOrderDTO.regularSalesId,
        salesOrderDate: salesdate[0],
        invoiceType: response.data.salesOrderDTO.invoiceTypeId,
        invoiceDate: null,
        branch: response.data.salesOrderDTO.branchId,
        branchName: response.data.salesOrderDTO.branchName,
        billingFrom: response.data.salesOrderDTO.billingFrom,
        shippingFrom: response.data.salesOrderDTO.shippingFrom,
        billingFromAddress: response.data.salesOrderDTO.billingFromAddress,
        shippingFromAddress: response.data.salesOrderDTO.shippingFromAddress,
        transporterNumber: response.data.transporterNumber,
        transporterDocNo: response.data.transporterDocNo,
        deliveryDate: null,
        salesOrderNumber: response.data.salesOrderDTO.salesNo,
        quotationNumber: response.data.salesOrderDTOquotationSalesOrderNo,
        quotationDate: response.data.salesOrderDTO.quotationDate,
        vehicleNo: "",
        finYear: response.data.salesOrderDTO.finYear,
        createdBy: response.data.salesOrderDTO.createdBy,
        createdDate: createddate[0],
        company: response.data.salesOrderDTO.companyId,
        brokerId: response.data.salesOrderDTO.brokerId,
        brokerName: response.data.salesOrderDTO.brokerName,
        customerId: response.data.salesOrderDTO.customerId,
        customerName: response.data.salesOrderDTO.customerName,
        customerLineItemId: response.data.salesOrderDTO.customerLineItemId,
        brokerContactNumber: response.data.salesOrderDTO.brokerContactNumber,
        brokerContactPerson: response.data.salesOrderDTO.brokerContactPerson,
        consigneeContactNumber:
          response.data.salesOrderDTO.consigneeContactNumber,
        consigneeContactPerson:
          response.data.salesOrderDTO.consigneeContactPerson,
        billingTo: response.data.salesOrderDTO.billingTo,
        billingToAddress: response.data.salesOrderDTO.billingToAddress,
        billingToGST: response.data.salesOrderDTO.billedToGstin,
        billingContactNo: response.data.salesOrderDTO.billedToContactNumber,
        billingContactPerson: response.data.salesOrderDTO.billedToContactPerson,
        billingToEmail: response.data.salesOrderDTO.billedToContactPerson,
        billingState: response.data.salesOrderDTO.billingToState,
        shippingTo: response.data.salesOrderDTO.shippingTo || "",
        shippingToAddress: response.data.salesOrderDTO.shippingToAddress,
        shippingToGST: response.data.salesOrderDTO.shippedToGstin,
        shippingContactNo: response.data.salesOrderDTO.shippedToContactNumber,
        shippingContactPerson:
          response.data.salesOrderDTO.shippedToContactPerson,
        shippingToEmail: response.data.salesOrderDTO.shippedToEmail,
        shippingState: response.data.salesOrderDTO.shippingToState,
        termsConditionType: response.data.salesOrderDTO.termsConditionType,
        transporter: response.data.salesOrderDTO.transporterId || "",
        transporterName: response.data.salesOrderDTO.transporterName || "",
        transporterAddress: response.data.salesOrderDTO.transporterAddress || "",
        transporterStateCode: response.data.salesOrderDTO.transporterStateCode || "",
        transporterStateName: response.data.salesOrderDTO.transporterStateName || "",
        transporterGst: response.data.salesOrderDTO.transporterGst || "",
        status: response.data.salesOrderDTO.statusId,
        statusName: response.data.salesOrderDTO.statusName,
        challanNo: response.data.salesOrderDTO.challanNo,
        challanDate: response.data.salesOrderDTO.challanDate,
        taxType: response.data.salesOrderDTO.taxType,
        taxTypeName: response.data.salesOrderDTO.taxTypeName,
        footerNote: response.data.salesOrderDTO.footerNote,
        internalNote: response.data.salesOrderDTO.internalNote,
        termsCondition: response.data.salesOrderDTO.termsCondition,
        deliveryTerms: response.data.salesOrderDTO.deliveryTerms,
        paymentTerms: response.data.salesOrderDTO.paymentTerms,
        deliveryDays: response.data.salesOrderDTO.deliveryDays,
        freightCharges: response.data.salesOrderDTO.freightCharges,
        amountWithoutTax: response.data.salesOrderDTO.amountWithoutTax,
        amountWithTax: response.data.salesOrderDTO.amountWithTax,
        updatedBy: response.data.salesOrderDTO.updatedBy,
        updatedByDesc: response.data.salesOrderDTO.updatedByDesc,
        updatedOn: response.data.salesOrderDTO.updatedOn,
        approvedBy: response.data.salesOrderDTO.approvedBy,
        approvedBydesc: response.data.salesOrderDTO.approvedBydesc,
        approvedOn: response.data.salesOrderDTO.approvedOn,
        totalIGST:
          TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
        totalCGST:
          TotalCGST == null ? "0.00" : parseFloat(TotalCGST).toFixed(2),
        totalSGST:
          TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),
        subTotal: parseFloat(totalAmount).toFixed(2),
        taxTotal: parseFloat(taxTotals).toFixed(2),
        netTotal: parseFloat(totalAmount - taxTotals).toFixed(2),
        TotalAmount: parseFloat(totalAmount + taxTotals).toFixed(2),
        pcsoNo: response.data.pcsoNo,
        pcsoDate: response.data.pcsoDate,
        ammendmentNo: response.data.ammendmentNo || "",
        ammendmentDate: response.data.ammendmentDate || null,
        ammendmentNo2: response.data.ammendmentNo2 || "",
        ammendmentDate2: response.data.ammendmentDate2 || null,
        ammendmentNo3: response.data.ammendmentNo3 || "",
        ammendmentDate3: response.data.ammendmentDate3 || null,
        ammendmentNo4: response.data.ammendmentNo4 || "",
        ammendmentDate4: response.data.ammendmentDate4 || null,
        ammendmentNo5: response.data.ammendmentNo5 || "",
        ammendmentDate5: response.data.ammendmentDate5 || null,
        ApproveButton: response.data.salesOrderDTO.ApproveButton || false,
        RejectButton: response.data.salesOrderDTO.RejectButton || false,
        UpdateButton: response.data.salesOrderDTO.UpdateButton || true,
        brokerCommisionPercent:
          response.data.salesOrderDTO.brokerCommisionPercent,
      };
      console.log(response.data,"response from sales order")
      yield put(getJuteHessionInvoiceHeader(Header));
    }
    yield put(getSalesOrderViewByIdForJuteHessianSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getDeliveryOrderViewByIdForJuteHessianInvoice({
  payload: { url, history },
}) {
  const { JuteHassianInvoiceHeader } = yield select(
    (state) => state.InvoiceReducer
  );
  try {
    const response = yield call(getListurl, url);

    yield put(getDeliveryOrderViewByIdForJuteHessianSuccessfull({ response }));
    if (isEmptyObject(response.data)) {
      swal("Something Went Wrong", { icon: "error" });
    } else {
      if(!isEmpty(response.data.deliveryOrderDTO.customerId)){
        var CustomerUrl =
          serverApi.GET_ALL_BILLING_SHIPPING +
          response.data.deliveryOrderDTO.customerId +
          "/" +
          JSON.parse(localStorage.getItem("authUser")).cipher +
          "?isForSalesModule=1";
        yield put(getBillingShippingList(CustomerUrl, history));
        }
      if (response) {
        if (response.data) {
          if (response.data.deliveryOrderDTO) {
            if (response.data.deliveryOrderDTO.deliveryOrderLineItemDTO) {
              if (
                response.data.deliveryOrderDTO.deliveryOrderLineItemDTO
                  .length !== 0
              ) {
                var SalesUpdate = [];
                const FilteredItem =
                  response.data.deliveryOrderDTO.deliveryOrderLineItemDTO.filter(
                    (item) => {
                      return item.invoiceQty !== 0 && item.isActive !== 0;
                    }
                  );
                FilteredItem.map((item, i) => {
                  var createddate =
                    item.createdDate !== null
                      ? item.createdDate.split("T", 2)[0]
                      : null;
                  let calculatedPryToSecValue = convertPrimaryToSecondary(
                    item.invoiceQty,
                    item.hessianQuantityRelationValue
                  );
                  let calculatedRatePcsToMt = convertRatePcsToMt(
                    item.rate,
                    item.hessianRateRelationValue
                  );
                  let netAmountTotal = limitDecimals(
                    item.invoiceQty * item.rate
                  );
                  SalesUpdate.push({
                    id: i,
                    regularInvoiceLineitemId: "",
                    govtInvoiceId: "",
                    createdBy: item.createdBy,
                    createdDate: createddate,
                    companyId: item.companyId,
                    companyName: item.companyName,
                    regularSalesOrderId: item.salesId,
                    regularSalesOrderLineItemId: item.salesLineItemId,
                    regularDeliveryOrderID: item.regDeliveryOrderId,
                    regularDeliveryOrderLineItemID:
                      item.regularDeliveryOrderLineItemId,
                    itemGroupId: item.itemGroupId,
                    itemGroupName: item.itemGroupName,
                    itemGroupValue: item.itemGroupValue,
                    itemId: item.itemId,
                    itemName: item.itemName,
                    itemCode: item.itemCode,
                    itemValue: item.itemValue,
                    hsncode: item.hsncode,
                    itemMake: item.itemMake,
                    quantity: limitDecimals(item.invoiceQty, 3),
                    uom: item.uom,
                    rate: limitDecimals(item.rate, 3),
                    doBalQty: limitDecimals(item.doBalQty, 3),
                    invoiceQty: limitDecimals(item.invoiceQty, 3),
                    igstAmount: limitDecimals(
                      (netAmountTotal *
                        (item.igstPercent == null ? 0 : item.igstPercent)) /
                        100,
                      2
                    ),
                    igstPercent:
                      item.igstPercent == null ? "0" : item.igstPercent,
                    cgstAmount: limitDecimals(
                      (netAmountTotal *
                        (item.cgstPercent == null ? 0 : item.cgstPercent)) /
                        100,
                      2
                    ),
                    cgstPercent:
                      item.cgstPercent == null ? "0" : item.cgstPercent,
                    sgstAmount: limitDecimals(
                      (netAmountTotal *
                        (item.sgstPercent == null ? 0 : item.sgstPercent)) /
                        100,
                      2
                    ),
                    sgstPercent:
                      item.sgstPercent == null ? "0" : item.sgstPercent,
                    discountType: item.discountType,
                    discountName: item.discountTypeName,
                    discount: limitDecimals(item.discount, 2),
                    discountedRate: limitDecimals(item.discountedRate, 3),
                    discountAmount: limitDecimals(item.discountAmount),
                    // totalAmount: limitDecimals((item.hessianRateIncludingBrokerage ? item.hessianRateIncludingBrokerage :item.discountedRate) * item.invoiceQty,2),
                    // netAmount: limitDecimals(
                    //   item.discountedRate ?item.discountedRate * item.invoiceQty : item.hessianRateIncludingBrokerage * item.invoiceQty,
                    //   2
                    // ),
                    totalAmount: limitDecimals(item.totalAmount, 2),
                    netAmount: limitDecimals(netAmountTotal, 2),
                    netTotal: item.netTotal,
                    remarks: item.remarks,
                    taxPercent: limitDecimals(item.itemTaxPercentage, 2),
                    balesPacketSlNo: "",
                    isActive: item.isActive,
                    hessianIsActive:item.hessianIsActive,
                    status: item.status,
                    updatedBy: item.updatedBy,
                    updatedOn: item.updatedOn,
                    updatedByDesc: item.updatedByDesc,
                    regInvoiceId: null,
                    govtInvoiceLineItemId: "",
                    govtInvoiceHdrId: "",
                    govtInvoiceIsActive: 1,
                    govtInvoiceBalesPacketSlNo: null,
                    ItemNameData: [],
                    hessianQuantityCalculatedValue: limitDecimals(
                      calculatedPryToSecValue,
                      3
                    ),
                    hessianBalesPacketSlNo: item.hessianBalesPacketSlNo,
                    hessianDeliveryOrderId: item.hessianDeliveryOrderId,
                    hessianDeliveryOrderLineItemId:
                      item.hessianDeliveryOrderLineItemId,
                    hessianQuantityUom: item.hessianQuantityUom,
                    hessianQuantityUomId: item.hessianQuantityUomId,
                    hessianQuantityRelationValue:
                      item.hessianQuantityRelationValue,
                    hessianRateUom: item.hessianRateUom,
                    hessianRateUomId: item.hessianRateUomId,
                    hessianRateRelationValue: item.hessianRateRelationValue,
                    hessianRateCalculatedValue: limitDecimals(
                      calculatedRatePcsToMt,
                      3
                    ),
                    hessianRateIncludingBrokerage: limitDecimals(
                      item.hessianRateIncludingBrokerage,
                      2
                    ),
                    hessianRateExcludingBrokerage:
                      item.hessianRateExcludingBrokerage,
                    hessianUpdatedBy: item.hessianUpdatedBy,
                    hessianUpdatedOn: item.hessianUpdatedOn,
                    regularDeliveryOrderLineItemId:
                      item.regularDeliveryOrderLineItemId,
                    regDeliveryOrderId: item.regDeliveryOrderId,
                  });
                });
                yield put(getJuteHessianInvoiceLineItems(SalesUpdate));
              }
            }
          }
          var TotalCGST = "0.00";
          if (SalesUpdate.length !== 0) {
            var totalcgst = SalesUpdate.map((p) =>
              p.cgstAmount == null ? 0 : p.cgstAmount
            ).reduce(
              (cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind)
            );
          }

          var TotalSGST = "0.00";
          if (SalesUpdate.length !== 0) {
            TotalSGST = SalesUpdate.map((p) =>
              p.sgstAmount == null ? 0 : p.sgstAmount
            ).reduce(
              (sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind)
            );
          }

          var TotalIGST = "0.00";
          if (SalesUpdate.length !== 0) {
            TotalIGST = SalesUpdate.map((p) =>
              p.igstAmount == null ? 0 : p.igstAmount
            ).reduce(
              (igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind)
            );
          }

          var netAmount = "0.00";
          if (SalesUpdate.length !== 0) {
            netAmount = SalesUpdate.map((p) =>
              p.netAmount == null ? 0 : p.netAmount
            ).reduce(
              (netAmount, ind) => parseFloat(netAmount) + parseFloat(ind)
            );
          }

          var totalAmount = SalesUpdate.map((p) =>
            p.totalAmount == null ? 0 : p.totalAmount
          ).reduce(
            (totalAmount, ind) => parseFloat(totalAmount) + parseFloat(ind)
          );

          var taxTotals =
            Number(totalcgst) + Number(TotalSGST) + Number(TotalIGST);

          const Header = {
            ...JuteHassianInvoiceHeader,
            transporterId: response.data.deliveryOrderDTO.transporter,
            transporterName: response.data.deliveryOrderDTO.transporterName,
            transporterAddress:
              response.data.deliveryOrderDTO.transporterAddress,
            transporterStateCode:
              response.data.deliveryOrderDTO.transporterStateCode,
            transporterStateName:
              response.data.deliveryOrderDTO.transporterStateName,
            transporterGst: response.data.deliveryOrderDTO.transporterGst,
            deliveryOrderId: response.data.regDeliveryOrderId,
            deliveryOrderNo: response.data.deliveryOrderDTO.deliveryOrderNo,
            deliveryDate: response.data.deliveryOrderDTO.deliveryDate,
            totalIGST:
              TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
            totalCGST:
              TotalCGST == null ? "0.00" : parseFloat(totalcgst).toFixed(2),
            totalSGST:
              TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),
            subTotal: parseFloat(netAmount).toFixed(2),
            taxTotal: parseFloat(taxTotals).toFixed(2),
            netTotal: parseFloat(netAmount).toFixed(2),
            TotalAmount: parseFloat(
              Number(netAmount) + Number(taxTotals)
            ).toFixed(2),
            loadingPoint: response.data.loadingPoint,
            destinationRailHead: response.data.destinationRailHead,
            ammendmentNo: response.data.ammendmentNo,
            bankId: response.data.deliveryOrderDTO.bankId,
            bankBranch: response.data.deliveryOrderDTO.bankBranch,
            bankName: response.data.deliveryOrderDTO.bankName,
            ifscCode: response.data.deliveryOrderDTO.ifscCode,
            accountNo: response.data.deliveryOrderDTO.accountNo,
            micrCode: response.data.deliveryOrderDTO.micrCode,
            deliveryTerms: response.data.deliveryOrderDTO.deliveryTerms,
            vehicleNo: response.data.deliveryOrderDTO.vehicleNumber,
            brokerCommisionPercent:
              response.data.deliveryOrderDTO.brokerCommisionPercent,
              billingTo: response.data.deliveryOrderDTO.billingTo,
              billingToAddress: response.data.deliveryOrderDTO.billingToAddress,
              billingToGST: response.data.deliveryOrderDTO.billedToGstin,
              billingContactNo: response.data.deliveryOrderDTO.billedToContactNumber,
              billingContactPerson: response.data.deliveryOrderDTO.billedToContactPerson,
              billingToEmail: response.data.deliveryOrderDTO.billedToContactPerson,
              billingState: response.data.deliveryOrderDTO.billingToState,
              shippingTo: response.data.deliveryOrderDTO.shippingTo,
              shippingToAddress: response.data.deliveryOrderDTO.shippingToAddress,
              shippingToGST: response.data.deliveryOrderDTO.shippedToGstin,
              shippingContactNo: response.data.deliveryOrderDTO.shippedToContactNumber,
              shippingContactPerson:
                response.data.deliveryOrderDTO.shippedToContactPerson,
              shippingToEmail: response.data.deliveryOrderDTO.shippedToEmail,
              shippingState: response.data.deliveryOrderDTO.shippingToState,
              customerId: response.data.deliveryOrderDTO.customerId,
              customerName: response.data.deliveryOrderDTO.customerName,
              taxType: response.data.deliveryOrderDTO.taxType,
              taxTypeName: response.data.deliveryOrderDTO.taxTypeName,
          };
          yield put(getJuteHessionInvoiceHeader(Header));
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* CreateJuteHessianInvoiceData({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(CreateJuteHessionInvoiveSuccessfull({ response }));
    if (response.data.status) {
      hideSpinner();
      swal(response.data.message, { icon: "success" });
      history.push("/invoice");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getJuteHessianInvoiceViewByIdData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (isEmptyObject(response.data)) {
      swal("Something Went Wrong");
    } else {
      if (response.data.invoiceHdrDto) {
        if (response.data.invoiceHdrDto.customerId !== undefined) {
          var CustomerUrl =
            serverApi.GET_ALL_BILLING_SHIPPING +
            response.data.invoiceHdrDto.customerId +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).cipher +
            "?isForSalesModule=1";
          yield put(getBillingShippingList(CustomerUrl, history));
        }
        if (response.data.invoiceHdrDto.invoiceLineItemDto.length !== 0) {
          var Invoiceupdate = [];
          response.data.invoiceHdrDto.invoiceLineItemDto.map((item, i) => {
            var createddate =
              item.createdDate !== null && item.createdDate.split("T");
            Invoiceupdate.push({
              id: i,
              createdBy: item.createdBy,
              companyId: item.companyId,
              regularSalesOrderId: item.regularSalesOrderId,
              regularSalesOrderLineItemId: item.regularSalesOrderLineItemId,
              hsnCode: item.hsnCode,
              itemGroupId: item.itemGroupId,
              itemGroupValue: item.itemGroupValue,
              itemId: item.itemId,
              hsncode: item.hsncode,
              itemMake: item.itemMake,
              uom: item.uom,
              discountType: item.discountType,
              discount: item.discount,
              balesPacketSlNo: item.balesPacketSlNo,
              isActive: item.isActive,
              hessianIsActive:item.hessianIsActive,
              itemGroupName: item.itemGroupName,
              itemName: item.itemName,
              itemCode: item.itemCode,
              itemValue: item.itemValue,
              createdDate: createddate[0],
              cessAmount: null,
              currency: item.currency,
              deliveryOrderLineItemId: item.deliveryOrderLineItemId,
              salesRegularInvoiceLineitemId: item.salesRegularInvoiceLineitemId,
              companyName: item.companyName,
              salesId: item.salesId,
              salesLineItemId: item.salesLineItemId,
              regularDeliveryOrderID: item.regularDeliveryOrderID,
              regularDeliveryOrderLineItemID:
                item.regularDeliveryOrderLineItemID,
              quantity: limitDecimals(item.quantity, 3),
              rate: limitDecimals(item.rate, 3),
              invoiceQty: limitDecimals(item.invoiceQty, 3),
              discountName: limitDecimals(item.discountName, 2),
              discountedRate: limitDecimals(item.discountedRate, 3),
              discountAmount: limitDecimals(item.discountAmount, 2),
              doBalQty: "",
              remarks: item.remarks,
              netAmount: limitDecimals(item.netAmount, 2),
              totalAmount: limitDecimals(item.totalAmount, 2),
              status: item.status,
              updatedOn: item.updatedOn,
              regInvoiceId: item.regInvoiceId,
              ItemNameData: [],
              taxPercent: limitDecimals(item.itemTaxPercentage, 2),
              igstAmount:
                item.igstAmount == null
                  ? "0.00"
                  : limitDecimals(item.igstAmount, 2),
              igstPercent:
                item.igstPercent == null
                  ? "0"
                  : limitDecimals(item.igstPercent, 2),
              cgstAmount:
                item.cgstAmount == null
                  ? "0.00"
                  : limitDecimals(item.cgstAmount, 2),
              cgstPercent:
                item.cgstPercent == null
                  ? "0"
                  : limitDecimals(item.cgstPercent, 2),
              sgstAmount:
                item.sgstAmount == null
                  ? "0.00"
                  : limitDecimals(item.sgstAmount, 2),
              sgstPercent:
                item.sgstPercent == null
                  ? "0"
                  : limitDecimals(item.sgstPercent, 2),
              hessianQuantityCalculatedValue: limitDecimals(
                item.hessianQuantityCalculatedValue,
                3
              ),
              hessianBalesPacketSlNo: item.hessianBalesPacketSlNo,
              hessianDeliveryOrderId: item.hessianDeliveryOrderId,
              hessianDeliveryOrderLineItemId:
                item.hessianDeliveryOrderLineItemId,
              hessianQuantityUom: item.hessianQuantityUom,
              hessianQuantityUomId: item.hessianQuantityUomId,
              hessianQuantityRelationValue: item.hessianQuantityRelationValue,
              hessianRateUom: item.hessianRateUom,
              hessianRateUomId: item.hessianRateUomId,
              hessianRateRelationValue: item.hessianRateRelationValue,
              hessianRateCalculatedValue: limitDecimals(
                item.hessianRateCalculatedValue,
                2
              ),
              hessianRateIncludingBrokerage: limitDecimals(
                item.hessianRateIncludingBrokerage,
                2
              ),
              hessianRateExcludingBrokerage: limitDecimals(
                item.hessianRateExcludingBrokerage,
                2
              ),
              hessianUpdatedBy: item.hessianUpdatedBy,
              hessianUpdatedOn: item.hessianUpdatedOn,
              updatedBy: response.data.invoiceHdrDto.updatedBy,
            });
          });

          yield put(getJuteHessianInvoiceLineItems(Invoiceupdate));
        }
      }

      var total_CGST = response.data.invoiceHdrDto.invoiceLineItemDto
        .filter((item) => item.isActive != 0)
        .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
        .reduce((cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind));

      var total_SGST = response.data.invoiceHdrDto.invoiceLineItemDto
        .filter((item) => item.isActive != 0)
        .map((p) => (p.sgstAmount == null ? 0 : p.sgstAmount))
        .reduce((sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind));

      var total_IGST = response.data.invoiceHdrDto.invoiceLineItemDto
        .filter((item) => item.isActive != 0)
        .map((p) => (p.igstAmount == null ? 0 : p.igstAmount))
        .reduce((igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind));

      var netAmount = response.data.invoiceHdrDto.invoiceLineItemDto
        .filter((item) => item.isActive != 0)
        .map((p) => p.netAmount)
        .reduce((netAmount, ind) => parseFloat(netAmount) + parseFloat(ind));

      var Tax_Totals =
        Number(total_CGST) + Number(total_SGST) + Number(total_IGST);

      var totalAmount = response.data.invoiceHdrDto.invoiceLineItemDto
        .filter((item) => item.isActive !== 0)
        .map((p) => p.totalAmount)
        .reduce(
          (totalAmount, ind) => parseFloat(totalAmount) + parseFloat(ind)
        );

      var createddate =
        response.data.invoiceHdrDto.createdDate !== null &&
        response.data.invoiceHdrDto.createdDate.split("T");
      var salesdate =
        response.data.invoiceHdrDto.salesOrderDate !== null &&
        response.data.invoiceHdrDto.salesOrderDate.split(" ");
      var deliveryDate =
        response.data.invoiceHdrDto.deliveryDate !== null &&
        response.data.invoiceHdrDto.deliveryDate.split("T");
      var quotationDate =
        response.data.invoiceHdrDto.quotationDate !== null &&
        response.data.invoiceHdrDto.quotationDate.split("T");
      var printedOn =
        response.data.invoiceHdrDto.printedOn !== null &&
        response.data.invoiceHdrDto.printedOn.split("T");

      var ApproveButton = false;
      var RejectButton = false;
      var UpdateButton = true;
      var EditButton = false;

      if (response.data.invoiceHdrDto.approveStatus === true) {
        if (
          response.data.invoiceHdrDto.status === 1 ||
          response.data.invoiceHdrDto.status === 17 ||
          response.data.invoiceHdrDto.status === 18 ||
          response.data.invoiceHdrDto.status === 19 ||
          response.data.invoiceHdrDto.status === 20
        ) {
          ApproveButton = true;
          RejectButton = true;
          UpdateButton = true;
          EditButton = true;
        }
      } else if (
        response.data.invoiceHdrDto.status === 1 ||
        response.data.invoiceHdrDto.status === 17 ||
        response.data.invoiceHdrDto.status === 18 ||
        response.data.invoiceHdrDto.status === 19 ||
        response.data.invoiceHdrDto.status === 20
      ) {
        UpdateButton = true;
        EditButton = true;
      }

      if (
        response.data.invoiceHdrDto.status === 4 ||
        response.data.invoiceHdrDto.status === 3 ||
        response.data.invoiceHdrDto.status == 5
      ) {
        ApproveButton = false;
        RejectButton = false;
        UpdateButton = false;
        EditButton = false;
      }
      const Header = {
        invoiceId: response.data.invoiceId,
        regInvoiceId: response.data.regInvoiceId,
        invoiceType: response.data.invoiceType,
        loadingPoint: response.data.loadingPoint,
        destinationRailHead: response.data.destinationRailHead,
        deliveryOrderId: response.data.invoiceHdrDto.deliveryOrderId,
        deliveryOrderNo: response.data.invoiceHdrDto.deliveryOrderNo,
        finYear: response.data.invoiceHdrDto.finYear,
        createdBy: response.data.invoiceHdrDto.createdBy,
        createdDate: createddate[0],
        billingAddress: response.data.invoiceHdrDto.billingToAddress,
        invoiceDate: response.data.invoiceHdrDto.invoiceDate,
        invoiceNo: response.data.invoiceHdrDto.invoiceNo,
        companyId: response.data.invoiceHdrDto.companyId,
        branchId: response.data.invoiceHdrDto.branchId,
        billingFrom: response.data.invoiceHdrDto.billingFrom,
        shippingFrom: response.data.invoiceHdrDto.shippingFrom,
        billingFromAddress: response.data.invoiceHdrDto.billingFromAddress,
        shippingFromAddress: response.data.invoiceHdrDto.shippingFromAddress,
        transporterNumber: response.data.invoiceHdrDto.transporterNumber,
        transporterDocNo: response.data.invoiceHdrDto.transporterDocNo,
        transporter: response.data.invoiceHdrDto.transporter,
        transporterName: response.data.invoiceHdrDto.transporterName,
        transporterAddress: response.data.invoiceHdrDto.transporterAddress,
        transporterStateName: response.data.invoiceHdrDto.transporterStateName,
        transporterGst: response.data.invoiceHdrDto.transporterGst,
        regularSalesOrderId: response.data.invoiceHdrDto.regularSalesOrderId,
        brokerId: response.data.invoiceHdrDto.brokerId,
        customerId: response.data.invoiceHdrDto.customerId,
        customerBranchId: response.data.invoiceHdrDto.customerBranchId,
        billingTo: response.data.invoiceHdrDto.billingTo,
        billingToAddress: response.data.invoiceHdrDto.billingAddress,
        billingToGST: response.data.invoiceHdrDto.billedToGstin,
        billingContactNo: response.data.invoiceHdrDto.billedToContactNumber,
        billingContactPerson: response.data.invoiceHdrDto.billedToContactPerson,
        billingToEmail: response.data.invoiceHdrDto.billedToEmail,
        billingState: response.data.invoiceHdrDto.billingState,
        shippingTo: response.data.invoiceHdrDto.shippingTo,
        transporterId: response.data.invoiceHdrDto.transporterId,
        challanNo: response.data.invoiceHdrDto.challanNo,
        challanDate: response.data.invoiceHdrDto.challanDate,
        taxType: response.data.invoiceHdrDto.taxType,
        brokerCommision: response.data.invoiceHdrDto.brokerCommision,
        brokerCommisionPercent:
          response.data.invoiceHdrDto.brokerCommissionPercent,
        footerNote: response.data.invoiceHdrDto.footerNote,
        internalNote: response.data.invoiceHdrDto.internalNote,
        termsConditionType: response.data.invoiceHdrDto.termsConditionType,
        termsCondition: response.data.invoiceHdrDto.termsCondition,
        deliveryTerms: response.data.invoiceHdrDto.deliveryTerms,
        paymentTerms: response.data.invoiceHdrDto.paymentTerms,
        deliveryDays: response.data.invoiceHdrDto.deliveryDays,
        deliveryDate: deliveryDate[0],
        salesOrderDate: salesdate[0],
        freightCharges: response.data.invoiceHdrDto.freightCharges,
        amountWithoutTax: response.data.invoiceHdrDto.amountWithoutTax,
        amountWithTax: response.data.invoiceHdrDto.amountWithTax,
        statusId: response.data.invoiceHdrDto.statusId,
        gstSuppType: response.data.invoiceHdrDto.gstSuppType,
        gstInvoiceStatus: response.data.invoiceHdrDto.gstInvoiceStatus,
        gstInvoiceCategory: response.data.invoiceHdrDto.gstInvoiceCategory,
        gstInvoiceType: response.data.invoiceHdrDto.gstInvoiceType,
        gstAmtTaxable: response.data.invoiceHdrDto.gstAmtTax,
        gst_txpd_taxable_value:
          response.data.invoiceHdrDto.gst_txpd_taxable_valu,
        gstShippingBillNo: response.data.invoiceHdrDto.gstShippingBillNo,
        gstShippingBillDate: response.data.invoiceHdrDto,
        gstReason: response.data.invoiceHdrDto,
        gstr1ReturnPeriod: response.data.invoiceHdrDto.gstr3bReturnPeriod,
        gstr3bReturnPeriod: response.data.invoiceHdrDto.gstr3bReturnPeriod,
        reverseCharge: response.data.invoiceHdrDto.reverseCharge,
        isAmended: response.data.invoiceHdrDto.isAmended,
        amendedPos: response.data.invoiceHdrDto.amendedPos,
        amendedPeriod: response.data.invoiceHdrDto.amendedPeriod,
        isActive: response.data.invoiceHdrDto.isActive,
        salesOrderId: response.data.invoiceHdrDto.regularSalesOrderId,
        branch: response.data.invoiceHdrDto.branch,
        branchName: response.data.invoiceHdrDto.branchName,
        salesOrderNumber: response.data.invoiceHdrDto.salesOrderNumber,
        quotationNumber: response.data.invoiceHdrDto.quotationNumber,
        quotationDate: response.data.invoiceHdrDto.quotationDate,
        vehicleNo: response.data.invoiceHdrDto.vehicleNumber,
        bankId: response.data.invoiceHdrDto.bankId,
        bankBranch: response.data.invoiceHdrDto.bankBranch,
        bankName: response.data.invoiceHdrDto.bankName,
        accountNo: response.data.invoiceHdrDto.accountNo,
        ifscCode: response.data.invoiceHdrDto.ifscCode,
        micrCode: response.data.invoiceHdrDto.micrCode,
        company: response.data.invoiceHdrDto.company,
        brokerName: response.data.invoiceHdrDto.brokerName,
        customerDisable: response.data.invoiceHdrDto.customerDisable,
        billingDisbale: response.data.invoiceHdrDto.billingDisbale,
        shippingDisable: response.data.invoiceHdrDto.shippingDisable,
        customerName: response.data.invoiceHdrDto.customerName,
        brokerContactNumber: response.data.invoiceHdrDto.brokerContactNumber,
        brokerContactPerson: response.data.invoiceHdrDto.brokerContactPerson,
        consigneeContactNumber:
          response.data.invoiceHdrDto.consigneeContactNumber,
        consigneeContactPerson:
          response.data.invoiceHdrDto.consigneeContactPerson,
        shippingToAddress: response.data.invoiceHdrDto.shippingAddress,
        shippingToGST: response.data.invoiceHdrDto.shippedToGstin,
        shippingContactNo: response.data.invoiceHdrDto.shippedToContactNumber,
        shippingContactPerson:
          response.data.invoiceHdrDto.shippedToContactPerson,
        shippingToEmail: response.data.invoiceHdrDto.shippedToEmail,
        shippingState: response.data.invoiceHdrDto.shippingState,
        transporterStateCode: response.data.invoiceHdrDto.transporterStateCode,
        status: response.data.invoiceHdrDto.status,
        statusName: response.data.invoiceHdrDto.statusName,
        updatedBy: response.data.invoiceHdrDto.updatedBy,
        updatedOn: response.data.invoiceHdrDto.updatedOn,
        updatedByDesc: response.data.invoiceHdrDto.updatedByDesc,
        approvedBy: response.data.invoiceHdrDto.approvedBy,
        approvedOn: response.data.invoiceHdrDto.approvedOn,
        approvedBydesc: response.data.invoiceHdrDto.approvedBydesc,
        regDeliveryOrderId: response.data.invoiceHdrDto.regDeliveryOrderId,
        createdByDesc: response.data.invoiceHdrDto.createdByDesc,
        invoiceTypeName: response.data.invoiceHdrDto.invoiceTypeName,
        billingStateName: response.data.invoiceHdrDto.billingStateName,
        shippingStateName: response.data.invoiceHdrDto.shippingStateName,
        printedOn: printedOn[0],
        taxName: response.data.invoiceHdrDto.taxName,
        approveStatus: response.data.invoiceHdrDto.approveStatus,
        vehicleNumber: response.data.invoiceHdrDto.vehicleNumber,
        ApproveButton: ApproveButton,
        RejectButton: RejectButton,
        UpdateButton: UpdateButton,
        EditButton: EditButton,
        disableBroker: response.data.invoiceHdrDto.disableBroker,
        regInvoiceId: response.data.invoiceHdrDto.regInvoiceId,
        salesOrderNo: response.data.invoiceHdrDto.salesOrderNo,
        invoiceTypeDesc: response.data.invoiceHdrDto.invoiceTypeDesc,
        shippingAddress: response.data.invoiceHdrDto.shippingAddress,
        taxTypeDesc: response.data.invoiceHdrDto.taxTypeDesc,
        deliveryOrderDate: response.data.invoiceHdrDto.deliveryOrderDate,
        quotationNo: response.data.invoiceHdrDto.quotationNo,
        poNumber: response.data.invoiceHdrDto.poNumber,
        customerReferenceNumber:
          response.data.invoiceHdrDto.customerReferenceNumber,
        transportationMode: response.data.invoiceHdrDto.transportationMode,
        vehicleType: response.data.invoiceHdrDto.vehicleType,
        transportationDistance:
          response.data.invoiceHdrDto.transportationDistance,
        totalIGST:
          total_IGST == null ? "0.00" : parseFloat(total_IGST).toFixed(2),
        totalCGST:
          total_CGST == null ? "0.00" : parseFloat(total_CGST).toFixed(2),
        totalSGST:
          total_SGST == null ? "0.00" : parseFloat(total_SGST).toFixed(2),
        netTotal: parseFloat(netAmount).toFixed(2),
        taxTotal: parseFloat(Tax_Totals).toFixed(2),
        TotalAmount: parseFloat(netAmount + Tax_Totals).toFixed(2),
        subTotal: parseFloat(netAmount).toFixed(2),
        createWithDirectInvoice: true,
        roundOffValue: null,
        roundingFlag: null,
        roundingPositiveFlag: null,
        roundingNegetiveFlag: null,
        roundingPositiveValue: "0.00",
        roundingNegetiveValue: "0.00",
        disableBrokerInvoice: false,
        disableconsigneeInvoice: false,
        ewayBillNo: response.data.invoiceHdrDto.ewayBillNo,
        eInvoiceAuthenticationApproval: response.data.invoiceHdrDto.eInvoiceAuthenticationApproval,
      };

      yield put(getJuteHessionInvoiceHeader(Header));
    }
    yield put(getJuteHessianInvoiceViewByIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* UpdateJuteHessianInvoiceData({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(UpdateJuteHessianInvoiceSuccessfull({ response }));
    if (response) {
      hideSpinner();
      if (response.data) {
        if (response.data.status) {
          swal(response.data.message, { icon: "success" });
        } else {
          swal(response.data.message, { icon: "error" });
        }
      }
    }
    history.push("/invoice");
  } catch (error) {
    console.log(error);
    hideSpinner();
  }
}
//jute yarn sales order viewbyid
function* getSalesOrderViewByIdForJuteYarnInvoiceData({
  payload: { url, history },
}) {
  try {
    const response = yield call(getListurl, url);
    yield put(getSalesOrderViewByIdForJuteYarnInvoiceSuccessfull({ response }));
    if (isEmptyObject(response.data)) {
      swal("Something Went Wrong", { icon: "error" });
    } else {
      if (response) {
        if (response.data) {
          var CustomerUrl =
            serverApi.GET_ALL_BILLING_SHIPPING +
            response.data.salesOrderDTO.customerId +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).cipher +
            "?isForSalesModule=1";
          yield put(getBillingShippingList(CustomerUrl, history));
          if (response.data.salesOrderDTO.salesOrderLineItemDTO) {
            if (
              response.data.salesOrderDTO.salesOrderLineItemDTO.length !== 0
            ) {
              var SalesUpdate = [];
              response.data.salesOrderDTO.salesOrderLineItemDTO.map(
                (item, i) => {
                  var createddate =
                    item.createdDate !== null
                      ? item.createdDate.split("T", 2)[0]
                      : null;
                  if (
                    item.invoiceBalQty !== 0 &&
                    item.invoiceBalQty !== null &&
                    item.invoiceBalQty !== undefined &&
                    item.invoiceBalQty !== ""
                  ) {
                    SalesUpdate.push({
                      id: i,
                      salesRegularInvoiceLineitemId: "",
                      regInvoiceId: "",
                      createdBy: item.createdBy,
                      createdDate: createddate,
                      companyId: item.companyId,
                      companyName: item.companyName,
                      regularSalesOrderId: item.regularSalesOrderId,
                      regularSalesOrderLineItemId:
                        item.regularSalesOrderLineItemId,
                      regularDeliveryOrderID: "",
                      regularDeliveryOrderLineItemID: "",
                      itemGroupId: item.itemGroupId,
                      itemGroupName: item.itemGroupName,
                      itemGroupValue: item.itemGroupValue,
                      itemId: item.itemId,
                      itemName: item.itemName,
                      itemValue: item.itemValue,
                      itemCode: item.itemCode,
                      hsncode: item.hsncode,
                      itemMake: item.itemMake,
                      quantity: limitDecimals(item.invoiceBalQty, 3),
                      uom: item.uom,
                      rate: limitDecimals(item.rate, 3),
                      invoiceQty: limitDecimals(item.invoiceBalQty, 3),
                      igstAmount: limitDecimals(
                        (item.rate *
                          item.invoiceBalQty *
                          (item.igstPercent == null ? 0 : item.igstPercent)) /
                          100,
                        2
                      ),
                      igstPercent:
                        item.igstPercent == null
                          ? "0.00"
                          : limitDecimals(item.igstPercent, 2),
                      cgstAmount: limitDecimals(
                        (item.rate *
                          item.invoiceBalQty *
                          (item.cgstPercent == null ? 0 : item.cgstPercent)) /
                          100,
                        2
                      ),
                      cgstPercent:
                        item.cgstPercent == null
                          ? "0.00"
                          : limitDecimals(item.cgstPercent, 2),
                      sgstAmount: limitDecimals(
                        (item.rate *
                          item.invoiceBalQty *
                          (item.sgstPercent == null ? 0 : item.sgstPercent)) /
                          100,
                        2
                      ),
                      sgstPercent:
                        item.sgstPercent == null
                          ? "0.00"
                          : limitDecimals(item.sgstPercent, 2),
                      cessAmount: "",
                      discountType: item.discountType,
                      discountName: item.discountName,
                      discount: item.discount,
                      discountedRate: limitDecimals(item.discountedRate, 3),
                      discountAmount: limitDecimals(item.discountAmount, 2),
                      netAmount: limitDecimals(
                        item.rate * item.invoiceBalQty,
                        2
                      ),
                      totalAmount: limitDecimals(item.totalAmount, 2),
                      taxPercent: limitDecimals(item.itemTaxPercentage, 2),
                      remarks: item.remarks,
                      balesPacketSlNo: "",
                      isActive: item.isActive,
                      yarnIsActive:item.yarnIsActive,
                      status: item.status,
                      updatedBy: "",
                      updatedOn: "",
                      govtInvoiceLineItemId: "",
                      govtInvoiceHdrId: "",
                      govtInvoiceRateUom: null,
                      govtInvoiceRateUom2: null,
                      govtInvoiceRateUom3: null,
                      govtInvoiceQuantityUom: null,
                      govtInvoiceQuantityUom2: null,
                      govtInvoiceQuantityUom3: null,
                      govtInvoiceRelationValue: null,
                      govtInvoiceRelationValue2: null,
                      govtInvoiceRelationValue3: null,
                      govtInvoiceQtyCalculatedValue: null,
                      govtInvoiceQtyCalculatedValue2: null,
                      govtInvoiceQtyCalculatedValue3: null,
                      govtInvoiceRateCalculatedValue: null,
                      govtInvoiceRateCalculatedValue2: null,
                      govtInvoiceRateCalculatedValue3: null,
                      govtInvoiceIsActive: null,
                      govtInvoiceBalesPacketSlNo: null,

                      yarnInvoiceLineItemId: null,
                      yarnInvoiceId: null,
                      yarnProductId: null,
                      yarnQuantity: null,
                      yarnUnitPrice: null,
                      yarnRateUom: item.yarnRateUom,
                      yarnRateUomRelationValue:item.yarnRateUomRelationValue,
                      yarnRateUomId:item.yarnRateUomId,
                      yarnRateCalculatedValue:  limitDecimals(
                        item.yarnRateCalculatedValue,
                        3
                      ),
                      yarnQuantityUom: "",
                      yarnQuantityUomRelationValue:"",
                      yarnQuantityUomId:"",
                      yarnQuantityCalculatedValue: "",
                      yarnVariableRelationValue: item.yarnVariableRelationValue,
                      yarnTotalPrice: null,
                      yarnBalesPacketSlNo: null,
                      yarnRateIncludingBrokerage: limitDecimals(
                        item.yarnRateIncludingBrokerage
                      ),
                      yarnRateExcludingBrokerage: limitDecimals(
                        item.yarnRateExcludingBrokerage
                      ),
                      hessianInvoicelineitemId: null,
                      hessianInvoiceId: null,
                      hessianQuantityUom: null,
                      hessianQuantityUomId: null,
                      hessianQuantityRelationValue: null,
                      hessianQuantityCalculatedValue: null,
                      hessianRateUom: null,
                      hessianRateUomId: null,
                      hessianRateRelationValue: null,
                      hessianRateCalculatedValue: null,
                      hessianRateExcludingBrokerage: null,
                      hessianRateIncludingBrokerage: null,
                      hessianBalesPacketSlNo: null,
                      ItemNameData: [],
                    });
                  }
                }
              );
              yield put(getJuteYarnInvoiceLineItems(SalesUpdate));
            }
          }
          var TotalCGST = "0.00";
          if (SalesUpdate.length !== 0) {
            TotalCGST = SalesUpdate.map((p) =>
              p.cgstAmount == null ? 0 : p.cgstAmount
            ).reduce(
              (cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind)
            );
          }

          var TotalSGST = "0.00";
          if (SalesUpdate.length !== 0) {
            TotalSGST = SalesUpdate.map((p) =>
              p.sgstAmount == null ? 0 : p.sgstAmount
            ).reduce(
              (sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind)
            );
          }
          var TotalIGST = "0.00";
          if (SalesUpdate.length !== 0) {
            TotalIGST = SalesUpdate.map((p) =>
              p.igstAmount == null ? 0 : p.igstAmount
            ).reduce(
              (igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind)
            );
          }

          var netAmount = "0.00";
          if (SalesUpdate.length !== 0) {
            netAmount = SalesUpdate.map((p) => p.netAmount).reduce(
              (netAmount, ind) => parseFloat(netAmount) + parseFloat(ind)
            );
          }
          var taxTotals =
            Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);
          var createddate =
            response.data.salesOrderDTO.createdDate !== null
              ? response.data.salesOrderDTO.createdDate.split("T", 2)[0]
              : null;

          var salesdate =
            response.data.salesOrderDTO.salesDate !== null
              ? response.data.salesOrderDTO.salesDate.split("T", 2)[0]
              : null;

          if (response.data.salesOrderDTO) {
            const Header = {
              yarnInvoiceId: "",
              regInvoiceId: "",
              invoiceType: response.data.salesOrderDTO.invoiceTypeId,
              deliveryOrderId: "",
              deliveryDate: null,
              deliveryOrderNo: "",
              finYear: response.data.salesOrderDTO.finYear,
              createdBy: response.data.salesOrderDTO.createdBy,
              createdByDesc: response.data.salesOrderDTO.createdByDesc,
              createdDate: createddate,
              invoiceDate: null,
              invoiceNo: "",
              invoiceTypeDesc: response.data.salesOrderDTO.invoiceTypeName,
              companyId: response.data.salesOrderDTO.companyId,
              companyName: response.data.salesOrderDTO.companyName,
              branchId: response.data.salesOrderDTO.branchId,
              branchName: response.data.salesOrderDTO.branchName,
              billingFrom: response.data.salesOrderDTO.billingFrom,
              shippingFrom: response.data.salesOrderDTO.shippingFrom,
              billingFromAddress:
                response.data.salesOrderDTO.billingFromAddress,
              shippingFromAddress:
                response.data.salesOrderDTO.shippingFromAddress,

              customerReferenceNumber: "",
              regularSalesOrderId: response.data.regularSalesId,
              salesOrderNumber: response.data.salesOrderDTO.salesNo,
              salesOrderDate: salesdate,
              brokerId: response.data.salesOrderDTO.brokerId,
              brokerName: response.data.salesOrderDTO.brokerName,
              brokerContactPerson:
                response.data.salesOrderDTO.brokerContactPerson,
              brokerContactNumber:
                response.data.salesOrderDTO.brokerContactNumber,
              consigneeContactPerson:
                response.data.salesOrderDTO.consigneeContactPerson,
              consigneeContactNumber:
                response.data.salesOrderDTO.consigneeContactNumber,
              customerId: response.data.salesOrderDTO.customerId,
              customerName: response.data.salesOrderDTO.customerName,
              customerBranchId: "",
              billingTo: response.data.salesOrderDTO.billingTo,
              billingAddress: response.data.salesOrderDTO.billingToAddress,
              billingState: response.data.salesOrderDTO.billingToState,
              billedToContactNumber:
                response.data.salesOrderDTO.billedToContactNumber,
              billedToGstin: response.data.salesOrderDTO.billedToGstin,
              billedToContactPerson:
                response.data.salesOrderDTO.billedToContactPerson,
              billedToEmail: response.data.salesOrderDTO.billedToEmail,
              shippingTo: response.data.salesOrderDTO.shippingTo,
              shippingAddress: response.data.salesOrderDTO.shippingToAddress,
              shippingState: response.data.salesOrderDTO.shippingToState,
              shippedToContactNumber:
                response.data.salesOrderDTO.shippedToContactPerson,
              shippedToGstin: response.data.salesOrderDTO.shippedToGstin,
              shippedToContactPerson:
                response.data.salesOrderDTO.shippedToContactPerson,
              shippedToEmail: response.data.salesOrderDTO.shippedToEmail,
              quotationDate: null,
              quotationNumber: "",
              printedOn: response.data.salesOrderDTO.printedOn,
              transporterId: response.data.salesOrderDTO.transporterId,
              transporterName: response.data.salesOrderDTO.transporterName,
              transporterAddress:
                response.data.salesOrderDTO.transporterAddress,
              transporterStateCode:
                response.data.salesOrderDTO.transporterStateCode,
              transporterStateName:
                response.data.salesOrderDTO.transporterStateName,
              transporterNumber: response.data.salesOrderDTO.transporterNumber,
              transporterDocNo: response.data.salesOrderDTO.transporterDocNo,
              transporterGst: response.data.salesOrderDTO.transporterStateName,
              vehicleNumber: "",
              termsConditionType:
                response.data.salesOrderDTO.termsConditionType,
              termsConditionName: response.data.salesOrderDTO.termsCondition,
              status: response.data.salesOrderDTO.statusId,
              statusName: response.data.salesOrderDTO.statusName,
              challanNo: "",
              challanDate: null,
              taxType: response.data.salesOrderDTO.taxType,
              taxName: response.data.salesOrderDTO.taxTypeName,
              footerNote: response.data.salesOrderDTO.footerNote,
              internalNote: response.data.salesOrderDTO.internalNote,
              termsCondition: response.data.salesOrderDTO.termsCondition,
              deliveryTerms: response.data.salesOrderDTO.deliveryTerms,
              paymentTerms: response.data.salesOrderDTO.paymentTerms,
              deliveryDays: response.data.salesOrderDTO.deliveryDays,
              freightCharges: response.data.salesOrderDTO.freightCharges,
              amountWithoutTax: "",
              amountWithTax: "",
              roundOffValue: "",
              roundingFlag: "",
              updatedByDesc: "",
              approvedBy: "",
              approvedByDesc: "",
              approvedOn: "",
              transportationMode: "",
              vehicleType: "",
              vehicleTypeName: "",
              transportationDistance: "",
              gstSuppType: "",
              gstInvoiceStatus: "",
              gstInvoiceCategory: "",
              gstInvoiceType: "",
              gstAmtTaxable: "",
              gst_txpd_taxable_value: "",
              gstShippingBillNo: "",
              gstShippingBillDate: null,
              gstReason: "",
              gstr1ReturnPeriod: "",
              gstr3bReturnPeriod: "",
              reverseCharge: "",
              isAmended: "",
              amendedPos: "",
              amendedPeriod: "",
              poNumber: "",
              approveStatus: null,
              brokerCommissionPercent:
                response.data.salesOrderDTO.brokerCommisionPercent,
              loadingPoint: "",
              destinationRailHead: "",
              updatedBy: null,
              updatedOn: null,
              isActive: 1,
              totalIGST:
                TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
              totalCGST:
                TotalCGST == null ? "0.00" : parseFloat(TotalCGST).toFixed(2),
              totalSGST:
                TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),
              subTotal: parseFloat(netAmount).toFixed(2),
              taxTotal: parseFloat(taxTotals).toFixed(2),
              netTotal: parseFloat(netAmount).toFixed(2),
              TotalAmount: parseFloat(netAmount + taxTotals).toFixed(2),

              ApproveButton: false,
              RejectButton: false,
              UpdateButton: false,
              EditButton: false,
            };
            yield put(getJuteYarnInvoiceHeader(Header));
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
//Jute yarn Delivery order viewbyid
function* getDeliveryOrderViewByIdForJuteYarnInvoiceData({
  payload: { url, history },
}) {
  const { JuteYarnInvoiceHeader } = yield select(
    (state) => state.InvoiceReducer
  );
  try {
    const response = yield call(getListurl, url);
    yield put(
      getDeliveryOrderViewByIdForJuteYarnInvoiceSuccessfull({ response })
    );
    if (isEmptyObject(response.data)) {
      swal("Something Went Wrong", { icon: "error" });
    } else {
      if (response) {
        if (response.data) {
          if (response.data.deliveryOrderDTO) {
            if (response.data.deliveryOrderDTO.deliveryOrderLineItemDTO) {
              if (
                response.data.deliveryOrderDTO.deliveryOrderLineItemDTO
                  .length !== 0
              ) {
                var SalesUpdate = [];
                response.data.deliveryOrderDTO.deliveryOrderLineItemDTO.map(
                  (item, i) => {
                    var createddate =
                      item.createdDate !== null
                        ? item.createdDate.split("T", 2)[0]
                        : null;
                    if (
                      item.invoiceQty !== 0 &&
                      item.invoiceQty !== null &&
                      item.invoiceQty !== undefined &&
                      item.invoiceQty !== ""
                    ) {
                      let calculatedPryToSecValue = limitDecimals(convertPrimaryToSecondary(
                        item.invoiceQty,
                        item.yarnQuantityUomRelationValue
                      ),3);
                      let calculatedRatePcsToMt =limitDecimals(
                        convertRatePcsToMt(
                          item.rate,
                          item.yarnRateUomRelationValue
                        ),3);
                        let netAmount= limitDecimals(Number(item.invoiceQty)*Number(item.rate))

                      SalesUpdate.push({
                        id: i,
                        salesRegularInvoiceLineitemId: "",
                        regInvoiceId: "",
                        createdBy: item.createdBy,
                        createdDate: createddate,

                        companyId: item.companyId,
                        companyName: item.companyName,

                        regularSalesOrderId: item.salesId,

                        regularSalesOrderLineItemId: item.salesLineItemId,

                        regularDeliveryOrderID: item.regDeliveryOrderId,
                        regularDeliveryOrderLineItemID:
                          item.regularDeliveryOrderLineItemId,

                        itemGroupId: item.itemGroupId,
                        itemGroupName: item.itemGroupName,
                        itemGroupValue: item.itemGroupValue,
                        itemId: item.itemId,
                        itemName: item.itemName,
                        itemValue: item.itemValue,
                        itemCode: item.itemCode,
                        hsncode: item.hsncode,
                        itemMake: item.itemMake,
                        quantity: limitDecimals(item.invoiceQty, 3),
                        uom: item.uom,
                        rate: limitDecimals(item.rate, 3),
                        invoiceQty: limitDecimals(item.invoiceQty, 3),
                        igstAmount: limitDecimals(
                          (netAmount *
                            (item.igstPercent == null ? 0 : item.igstPercent)) /
                            100,
                          2
                        ),
                        igstPercent:
                          item.igstPercent == null
                            ? "0.00"
                            : limitDecimals(item.igstPercent, 2),
                        cgstAmount: limitDecimals(
                          (netAmount*
                            (item.cgstPercent == null ? 0 : item.cgstPercent)) /
                            100,
                          2
                        ),
                        cgstPercent:
                          item.cgstPercent == null
                            ? "0.00"
                            : limitDecimals(item.cgstPercent, 2),
                        sgstAmount: limitDecimals(
                          (netAmount*
                            (item.sgstPercent == null ? 0 : item.sgstPercent)) /
                            100,
                          2
                        ),
                        sgstPercent:
                          item.sgstPercent == null
                            ? "0.00"
                            : limitDecimals(item.sgstPercent, 2),
                        cessAmount: "",
                        discountType: item.discountType,
                        discountName: item.discountName,
                        discount: limitDecimals(item.discount, 2),
                        discountedRate: limitDecimals(item.discountedRate, 3),
                        discountAmount: limitDecimals(item.discountAmount, 2),
                        netAmount: limitDecimals(
                          netAmount,
                          2
                        ),
                        totalAmount: limitDecimals(item.totalAmount, 2),
                        taxPercent: limitDecimals(item.itemTaxPercentage, 2),
                        remarks: item.remarks,
                        balesPacketSlNo: "",
                        isActive: item.isActive,
                        yarnIsActive:item.yarnIsActive,
                        status: item.status,
                        updatedBy: "",
                        updatedOn: "",
                        govtInvoiceLineItemId: "",
                        govtInvoiceHdrId: "",
                        govtInvoiceRateUom: null,
                        govtInvoiceRateUom2: null,
                        govtInvoiceRateUom3: null,
                        govtInvoiceQuantityUom: null,
                        govtInvoiceQuantityUom2: null,
                        govtInvoiceQuantityUom3: null,
                        govtInvoiceRelationValue: null,
                        govtInvoiceRelationValue2: null,
                        govtInvoiceRelationValue3: null,
                        govtInvoiceQtyCalculatedValue: null,
                        govtInvoiceQtyCalculatedValue2: null,
                        govtInvoiceQtyCalculatedValue3: null,
                        govtInvoiceRateCalculatedValue: null,
                        govtInvoiceRateCalculatedValue2: null,
                        govtInvoiceRateCalculatedValue3: null,
                        govtInvoiceIsActive: null,
                        govtInvoiceBalesPacketSlNo: null,
                        yarnInvoiceLineItemId: null,
                        yarnInvoiceId: null,
                        yarnProductId: null,
                        yarnUnitPrice: null,
                        yarnRateUom: item.yarnRateUom,
                        yarnRateUomRelationValue: item.yarnRateUomRelationValue,
                        yarnRateUomId: item.yarnRateUomId,
                        yarnRateCalculatedValue:  limitDecimals(
                          calculatedRatePcsToMt,
                          3
                        ),
                        yarnQuantity: null,
                        yarnQuantityUom: "",
                        yarnQuantityUomRelationValue:"",
                        yarnQuantityUomId: "",
                        yarnQuantityCalculatedValue:"",
                        yarnVariableRelationValue:
                          item.yarnVariableRelationValue,
                        yarnTotalPrice: null,
                        yarnBalesPacketSlNo: null,
                        yarnRateIncludingBrokerage: limitDecimals(
                          item.yarnRateIncludingBrokerage,
                          3
                        ),
                        yarnRateExcludingBrokerage: limitDecimals(
                          item.yarnRateExcludingBrokerage,
                          3
                        ),
                        hessianInvoicelineitemId: null,
                        hessianInvoiceId: null,
                        hessianQuantityUom: null,
                        hessianQuantityUomId: null,
                        hessianQuantityRelationValue: null,
                        hessianQuantityCalculatedValue: null,
                        hessianRateUom: null,
                        hessianRateUomId: null,
                        hessianRateRelationValue: null,
                        hessianRateCalculatedValue: null,
                        hessianRateExcludingBrokerage: null,
                        hessianRateIncludingBrokerage: null,
                        hessianBalesPacketSlNo: null,
                        ItemNameData: [],
                      });
                    }
                  }
                );
                yield put(getJuteYarnInvoiceLineItems(SalesUpdate));
              }
            }
          }
          var TotalCGST = "0.00";
          if (SalesUpdate.length !== 0) {
            var TotalCGST = SalesUpdate.map((p) =>
              p.cgstAmount == null ? 0 : p.cgstAmount
            ).reduce(
              (cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind)
            );
          }

          var TotalSGST = "0.00";
          if (SalesUpdate.length !== 0) {
            TotalSGST = SalesUpdate.map((p) =>
              p.sgstAmount == null ? 0 : p.sgstAmount
            ).reduce(
              (sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind)
            );
          }
          var TotalIGST = "0.00";
          if (SalesUpdate.length !== 0) {
            TotalIGST = SalesUpdate.map((p) =>
              p.igstAmount == null ? 0 : p.igstAmount
            ).reduce(
              (igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind)
            );
          }

          var netAmount = "0.00";
          if (SalesUpdate.length !== 0) {
            netAmount = SalesUpdate.map((p) => p.netAmount).reduce(
              (netAmount, ind) => parseFloat(netAmount) + parseFloat(ind)
            );
          }
          var taxTotals =
            Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);

          var deliveryDate =
            response.data.deliveryOrderDTO.deliveryDate !== null
              ? response.data.deliveryOrderDTO.deliveryDate.split("T", 2)[0]
              : null;

          const Header = {
            ...JuteYarnInvoiceHeader,
            transporterId: response.data.deliveryOrderDTO.transporter,
            transporterName: response.data.deliveryOrderDTO.transporterName,
            transporterAddress:
              response.data.deliveryOrderDTO.transporterAddress,
            transporterStateCode:
              response.data.deliveryOrderDTO.transporterStateCode,
            transporterStateName:
              response.data.deliveryOrderDTO.transporterStateName,
            transporterNumber: response.data.deliveryOrderDTO.transporterNumber,
            transporterDocNo: response.data.deliveryOrderDTO.transporterDocNo,
            transporterGst: response.data.deliveryOrderDTO.transporterGst,
            deliveryOrderId: response.data.deliveryOrderDTO.regDeliveryOrderId,
            deliveryOrderNo: response.data.deliveryOrderDTO.deliveryOrderNo,
            deliveryDate: response.data.deliveryOrderDTO.deliveryDate,
            invoiceDate:null,
            bankId: response.data.deliveryOrderDTO.bankId,
            bankBranch: response.data.deliveryOrderDTO.bankBranch,
            bankName: response.data.deliveryOrderDTO.bankName,
            ifscCode: response.data.deliveryOrderDTO.ifscCode,
            accountNo: response.data.deliveryOrderDTO.accountNo,
            micrCode: response.data.deliveryOrderDTO.micrCode,
            billingTo: response.data.deliveryOrderDTO.billingTo,
            billingAddress: response.data.deliveryOrderDTO.billingToAddress,
            billingState: response.data.deliveryOrderDTO.billingToState,
            billedToContactNumber:
              response.data.deliveryOrderDTO.billedToContactNumber,
            billedToGstin: response.data.deliveryOrderDTO.billedToGstin,
            billedToContactPerson:
              response.data.deliveryOrderDTO.billedToContactPerson,
            billedToEmail: response.data.deliveryOrderDTO.billedToEmail,
            shippingTo: response.data.deliveryOrderDTO.shippingTo,
            shippingAddress: response.data.deliveryOrderDTO.shippingToAddress,
            shippingState: response.data.deliveryOrderDTO.shippingToState,
            shippedToContactNumber:
              response.data.deliveryOrderDTO.shippedToContactNumber,
            shippedToGstin: response.data.deliveryOrderDTO.shippedToGstin,
            shippedToContactPerson:
              response.data.deliveryOrderDTO.shippedToContactPerson,
            shippedToEmail: response.data.deliveryOrderDTO.shippedToEmail,
            vehicleNumber: response.data.deliveryOrderDTO.vehicleNumber,
            totalIGST:
              TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
            totalCGST:
              TotalCGST == null ? "0.00" : parseFloat(TotalCGST).toFixed(2),
            totalSGST:
              TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),
            subTotal: parseFloat(netAmount).toFixed(2),
            taxTotal: parseFloat(taxTotals).toFixed(2),
            netTotal: parseFloat(netAmount).toFixed(2),
            TotalAmount: parseFloat(
              Number(netAmount) + Number(taxTotals)
            ).toFixed(2),
          };

          yield put(getJuteYarnInvoiceHeader(Header));
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* getInvoiceJuteYarnUomRelationValueData({
  payload: { url, ItemId, type, history },
}) {
  try {
    const response = yield call(getListurl, url);
    const { JuteYarnInvoiceLineItems, JuteYarnInvoiceHeader } = yield select(
      (state) => state.InvoiceReducer
    );
    if (response) {
      let SecondaryUOMObj = response.data;
      let LineItemsRecords = [...JuteYarnInvoiceLineItems];
      if (SecondaryUOMObj) {
        if (type === "quantityUOM") {
          LineItemsRecords = LineItemsRecords.map((item, index) => {
            if (ItemId === item.itemId) {
              return {
                ...item,
                yarnQuantityUomRelationValue: limitDecimals(SecondaryUOMObj.relationValue,2),
                yarnQuantityCalculatedValue:limitDecimals(QuantityOfVariableUOMconvertion(
                  item.quantity,
                  SecondaryUOMObj.relationValue
                ),3),
              };
            }
            return item;
          });

          //Added this Line so that whenever we will change quantity Uom then total amount calculation has to be happen.

          let Calculations = SalesGstCalculations(
            {
              lineItems: LineItemsRecords,
              taxType: JuteYarnInvoiceHeader.taxType,
              brokerCommisionPercent:
                JuteYarnInvoiceHeader.brokerCommissionPercent,
            },
            "INVOICE_YARN_UOM_NEW"
          );
          var Headerdata = {
            ...JuteYarnInvoiceHeader,
            totalIGST: Calculations.totalIGST,
            totalCGST: Calculations.totalCGST,
            totalSGST: Calculations.totalSGST,
            taxTotal: Calculations.taxTotal,
            TotalAmount: Calculations.TotalAmount,
            netTotal: Calculations.netTotal,
            subTotal: Calculations.subTotal,
          };
          yield put(getJuteYarnInvoiceHeader(Headerdata));
          yield put(getJuteYarnInvoiceLineItems(Calculations.lineItems));
        } else {
          LineItemsRecords = LineItemsRecords.map((item, index) => {
            if (ItemId === item.itemId) {
              return {
                ...item,
                yarnRateUomRelationValue:limitDecimals(SecondaryUOMObj.relationValue,2),
                yarnRateCalculatedValue:limitDecimals(convertRatePcsToMt(
                  item.rate,
                  SecondaryUOMObj.relationValue
                ),3),
              };
            }
            return item;
          });
          //Added this Line so that whenever we will change rate Uom then total amount calculation has to be happen.

          let Calculations = SalesGstCalculations(
            {
              lineItems: LineItemsRecords,
              taxType: JuteYarnInvoiceHeader.taxType,
              brokerCommisionPercent:
                JuteYarnInvoiceHeader.brokerCommissionPercent,
            },
            "INVOICE_YARN_UOM_NEW"
          );
          var Headerdata = {
            ...JuteYarnInvoiceHeader,
            totalIGST: Calculations.totalIGST,
            totalCGST: Calculations.totalCGST,
            totalSGST: Calculations.totalSGST,
            taxTotal: Calculations.taxTotal,
            TotalAmount: Calculations.TotalAmount,
            netTotal: Calculations.netTotal,
            subTotal: Calculations.subTotal,
          };
          yield put(getJuteYarnInvoiceHeader(Headerdata));
          yield put(getJuteYarnInvoiceLineItems(Calculations.lineItems));
        }
      }
      yield put(getInvoiceJuteYarnUomRelationValueSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getJuteYarnInvoiceViewByIdData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getJuteYarnInvoiceViewByIdSuccessfull({ response }));
    if (isEmptyObject(response.data)) {
      swal("Something Went Wrong", { icon: "error" });
    } else {
      if (response) {
        if (response.data) {
          var CustomerUrl =
            serverApi.GET_ALL_BILLING_SHIPPING +
            response.data.invoiceHdrDto.customerId +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).cipher +
            "?isForSalesModule=1";
          yield put(getBillingShippingList(CustomerUrl, history));
          if (response.data.invoiceHdrDto.invoiceLineItemDto) {
            if (response.data.invoiceHdrDto.invoiceLineItemDto.length !== 0) {
              var Invoiceupdate = [];
              response.data.invoiceHdrDto.invoiceLineItemDto.map((item, i) => {
                var createddate =
                  item.createdDate !== null
                    ? item.createdDate.split("T", 2)[0]
                    : null;
                Invoiceupdate.push({
                  id: i,
                  salesRegularInvoiceLineitemId:
                    item.salesRegularInvoiceLineitemId,
                  regInvoiceId: item.regInvoiceId,
                  createdBy: item.createdBy,
                  createdDate: createddate,
                  companyId: item.companyId,
                  companyName: item.companyName,
                  regularSalesOrderId: item.regularSalesOrderId,
                  regularSalesOrderLineItemId: item.regularSalesOrderLineItemId,
                  regularDeliveryOrderID: item.regularDeliveryOrderID,
                  regularDeliveryOrderLineItemID:
                    item.regularDeliveryOrderLineItemID,
                  itemGroupId: item.itemGroupId,
                  itemGroupName: item.itemGroupName,
                  itemGroupValue: item.itemGroupValue,
                  itemId: item.itemId,
                  itemName: item.itemName,
                  itemValue: item.itemValue,
                  itemCode: item.itemCode,
                  hsncode: item.hsncode,
                  itemMake: item.itemMake,
                  quantity: limitDecimals(item.quantity, 3),
                  uom: item.uom,
                  rate: limitDecimals(item.rate, 3),
                  invoiceQty: limitDecimals(item.invoiceQty, 3),
                  igstAmount: limitDecimals(
                    (item.yarnRateIncludingBrokerage *
                      item.quantity *
                      (item.igstPercent == null ? 0 : item.igstPercent)) /
                      100,
                    2
                  ),
                  igstPercent:
                    item.igstPercent == null
                      ? "0.00"
                      : limitDecimals(item.igstPercent, 2),
                  cgstAmount: limitDecimals(
                    (item.yarnRateIncludingBrokerage *
                      item.quantity *
                      (item.cgstPercent == null ? 0 : item.cgstPercent)) /
                      100,
                    2
                  ),
                  cgstPercent:
                    item.cgstPercent == null
                      ? "0.00"
                      : limitDecimals(item.cgstPercent, 2),
                  sgstAmount: limitDecimals(
                    (item.yarnRateIncludingBrokerage *
                      item.quantity *
                      (item.sgstPercent == null ? 0 : item.sgstPercent)) /
                      100,
                    2
                  ),
                  sgstPercent:
                    item.sgstPercent == null
                      ? "0.00"
                      : limitDecimals(item.sgstPercent, 2),
                  cessAmount: item.cessAmount,
                  discountType: item.discountType,
                  discountName: item.discountTypeName,
                  discount: limitDecimals(item.discount, 2),
                  discountedRate: limitDecimals(item.discountedRate, 3),
                  discountAmount: limitDecimals(item.discountAmount, 2),
                  totalAmount: limitDecimals(item.totalAmount, 2),
                  netAmount: limitDecimals(
                    item.yarnRateIncludingBrokerage * item.quantity,
                    2
                  ),
                  remarks: item.remarks,
                  taxPercent: limitDecimals(item.itemTaxPercentage, 2),
                  balesPacketSlNo: item.balesPacketSlNo,
                  isActive: item.isActive,
                  yarnIsActive:item.yarnIsActive,
                  status: item.status,
                  updatedBy: item.updatedBy,
                  updatedOn: item.updatedOn,

                  govtInvoiceLineItemId: "",
                  govtInvoiceHdrId: "",
                  govtInvoiceRateUom: null,
                  govtInvoiceRateUom2: null,
                  govtInvoiceRateUom3: null,
                  govtInvoiceQuantityUom: null,
                  govtInvoiceQuantityUom2: null,
                  govtInvoiceQuantityUom3: null,
                  govtInvoiceRelationValue: null,
                  govtInvoiceRelationValue2: null,
                  govtInvoiceRelationValue3: null,
                  govtInvoiceQtyCalculatedValue: null,
                  govtInvoiceQtyCalculatedValue2: null,
                  govtInvoiceQtyCalculatedValue3: null,
                  govtInvoiceRateCalculatedValue: null,
                  govtInvoiceRateCalculatedValue2: null,
                  govtInvoiceRateCalculatedValue3: null,
                  govtInvoiceIsActive: null,
                  govtInvoiceBalesPacketSlNo: null,

                  yarnInvoiceLineItemId: item.yarnInvoiceLineItemId,
                  yarnInvoiceId: item.yarnInvoiceId,
                  yarnProductId: "1",
                  yarnQuantity: limitDecimals(item.quantity, 3),
                  yarnUnitPrice: "1",
                  yarnRateUom: item.yarnRateUom,
                  yarnRateUomRelationValue:limitDecimals(item.yarnRateUomRelationValue,2),
                  yarnRateUomId: item.yarnRateUomId,
                  yarnRateCalculatedValue: limitDecimals(
                    item.yarnRateCalculatedValue,
                    3
                  ),
                  yarnQuantityUom: item.yarnQuantityUom,
                  yarnQuantityUomRelationValue:
                  limitDecimals(item.yarnQuantityUomRelationValue,2),
                  yarnQuantityUomId: item.yarnQuantityUomId,
                  yarnQuantityCalculatedValue: limitDecimals(
                    item.yarnQuantityCalculatedValue,
                    3
                  ),
                  yarnVariableRelationValue: null,
                  yarnTotalPrice: limitDecimals(
                    item.discountedRate * item.quantity,
                    2
                  ),
                  yarnBalesPacketSlNo: null,
                  yarnRateIncludingBrokerage: limitDecimals(
                    item.yarnRateIncludingBrokerage,
                    2
                  ),
                  yarnRateExcludingBrokerage: limitDecimals(
                    item.yarnRateExcludingBrokerage,
                    2
                  ),
                  hessianInvoicelineitemId: null,
                  hessianInvoiceId: null,
                  hessianQuantityUom: null,
                  hessianQuantityUomId: null,
                  hessianQuantityRelationValue: null,
                  hessianQuantityCalculatedValue: null,
                  hessianRateUom: null,
                  hessianRateUomId: null,
                  hessianRateRelationValue: null,
                  hessianRateCalculatedValue: null,
                  hessianRateExcludingBrokerage: null,
                  hessianRateIncludingBrokerage: null,
                  hessianBalesPacketSlNo: null,
                  ItemNameData: [],
                });
              });

              yield put(getJuteYarnInvoiceLineItems(Invoiceupdate));

              var total_CGST = response.data.invoiceHdrDto.invoiceLineItemDto
                .filter((item) => item.isActive != 0)
                .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
                .reduce(
                  (cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind)
                );
              var total_SGST = response.data.invoiceHdrDto.invoiceLineItemDto
                .filter((item) => item.isActive != 0)
                .map((p) => (p.sgstAmount == null ? 0 : p.sgstAmount))
                .reduce(
                  (sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind)
                );
              var total_IGST = response.data.invoiceHdrDto.invoiceLineItemDto
                .filter((item) => item.isActive != 0)
                .map((p) => (p.igstAmount == null ? 0 : p.igstAmount))
                .reduce(
                  (igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind)
                );

              var netAmount = response.data.invoiceHdrDto.invoiceLineItemDto
                .filter((item) => item.isActive != 0)
                .map((p) => p.netAmount)
                .reduce(
                  (netAmount, ind) => parseFloat(netAmount) + parseFloat(ind)
                );
              var Tax_Totals =
                Number(total_CGST) + Number(total_SGST) + Number(total_IGST);
            }
          }
          var createddate =
            response.data.invoiceHdrDto.createdDate !== null
              ? response.data.invoiceHdrDto.createdDate.split("T", 2)[0]
              : null;

          var salesOrderDate =
            response.data.invoiceHdrDto.salesOrderDate !== null
              ? response.data.invoiceHdrDto.salesOrderDate.split(" ", 2)[0]
              : null;

          var ApproveButton = false;
          var RejectButton = false;
          var UpdateButton = true;
          var EditButton = false;
          if (response.data.invoiceHdrDto.approveStatus === true) {
            if (
              response.data.invoiceHdrDto.status === 1 ||
              response.data.invoiceHdrDto.status === 17 ||
              response.data.invoiceHdrDto.status === 18 ||
              response.data.invoiceHdrDto.status === 19 ||
              response.data.invoiceHdrDto.status === 20
            ) {
              ApproveButton = true;
              RejectButton = true;
              UpdateButton = true;
              EditButton = true;
            }
          } else if (
            response.data.invoiceHdrDto.status === 1 ||
            response.data.invoiceHdrDto.status === 17 ||
            response.data.invoiceHdrDto.status === 18 ||
            response.data.invoiceHdrDto.status === 19 ||
            response.data.invoiceHdrDto.status === 20
          ) {
            UpdateButton = true;
            EditButton = true;
          }

          if (
            response.data.invoiceHdrDto.status === 4 ||
            response.data.invoiceHdrDto.status === 3 ||
            response.data.invoiceHdrDto.status === 5
          ) {
            ApproveButton = false;
            RejectButton = false;
            UpdateButton = false;
            EditButton = false;
          }
          var roundingFlag = null;
          if (response.data.roundingFlag == true) {
            roundingFlag = 1;
          } else if (response.data.roundingFlag == false) {
            roundingFlag = 2;
          } else {
            roundingFlag = null;
          }
          var roundoff = roundAndRemainingValues(Invoiceupdate, roundingFlag);
          const Header = {
            yarnInvoiceId: response.data.yarnInvoiceId,
            regInvoiceId: response.data.regInvoiceId,
            invoiceType: response.data.invoiceType,
            deliveryOrderId: response.data.invoiceHdrDto.deliveryOrderId,
            deliveryDate: response.data.invoiceHdrDto.deliveryDate,
            deliveryOrderNo: response.data.invoiceHdrDto.deliveryOrderNo,
            finYear: response.data.invoiceHdrDto.finYear,
            createdBy: response.data.invoiceHdrDto.createdBy,
            createdByDesc: response.data.invoiceHdrDto.createdByDesc,
            createdDate: createddate,
            invoiceDate: response.data.invoiceHdrDto.invoiceDate,
            invoiceNo: response.data.invoiceHdrDto.invoiceNo,
            invoiceTypeDesc: response.data.invoiceHdrDto.invoiceTypeDesc,
            companyId: response.data.invoiceHdrDto.companyId,
            companyName: response.data.invoiceHdrDto.companyName,
            branchId: response.data.invoiceHdrDto.branchId,
            branchName: response.data.invoiceHdrDto.branchName,
            billingFrom: response.data.invoiceHdrDto.billingFrom,
            shippingFrom: response.data.invoiceHdrDto.shippingFrom,
            billingFromAddress: response.data.invoiceHdrDto.billingFromAddress,
            shippingFromAddress:
              response.data.invoiceHdrDto.shippingFromAddress,
            transporterNumber: response.data.invoiceHdrDto.transporterNumber,
            transporterDocNo: response.data.invoiceHdrDto.transporterDocNo,
            customerReferenceNumber:
              response.data.invoiceHdrDto.customerReferenceNumber,
            regularSalesOrderId:
              response.data.invoiceHdrDto.regularSalesOrderId,
            salesOrderNumber: response.data.invoiceHdrDto.salesOrderNumber,
            salesOrderDate: salesOrderDate,
            brokerId: response.data.invoiceHdrDto.brokerId,
            brokerName: response.data.invoiceHdrDto.brokerName,
            brokerContactPerson:
              response.data.invoiceHdrDto.brokerContactPerson,
            brokerContactNumber:
              response.data.invoiceHdrDto.brokerContactNumber,
            consigneeContactPerson:
              response.data.invoiceHdrDto.consigneeContactPerson,
            consigneeContactNumber:
              response.data.invoiceHdrDto.consigneeContactNumber,
            customerId: response.data.invoiceHdrDto.customerId,
            customerName: response.data.invoiceHdrDto.customerName,
            customerBranchId: "",
            billingTo: response.data.invoiceHdrDto.billingTo,
            billingAddress: response.data.invoiceHdrDto.billingAddress,
            billingState: response.data.invoiceHdrDto.billingState,
            billedToContactNumber:
              response.data.invoiceHdrDto.billedToContactNumber,
            billedToGstin: response.data.invoiceHdrDto.billedToGstin,
            billedToContactPerson:
              response.data.invoiceHdrDto.billedToContactPerson,
            billedToEmail: response.data.invoiceHdrDto.billedToEmail,
            shippingTo: response.data.invoiceHdrDto.shippingTo,
            shippingAddress: response.data.invoiceHdrDto.shippingAddress,
            shippingState: response.data.invoiceHdrDto.shippingState,
            shippedToContactNumber:
              response.data.invoiceHdrDto.shippedToContactNumber,
            shippedToGstin: response.data.invoiceHdrDto.shippedToGstin,
            shippedToContactPerson:
              response.data.invoiceHdrDto.shippedToContactPerson,
            shippedToEmail: response.data.invoiceHdrDto.shippedToEmail,
            quotationDate: null,
            quotationNumber: "",
            printedOn: response.data.invoiceHdrDto.printedOn,
            transporterId: response.data.invoiceHdrDto.transporterId,
            transporterName: response.data.invoiceHdrDto.transporterName,
            transporterAddress: response.data.invoiceHdrDto.transporterAddress,
            transporterStateCode:
              response.data.invoiceHdrDto.transporterStateCode,
            transporterStateName:
              response.data.invoiceHdrDto.transporterStateName,
            transporterGst: response.data.invoiceHdrDto.transporterGst,
            vehicleNumber: response.data.invoiceHdrDto.vehicleNumber,

            bankId: response.data.invoiceHdrDto.bankId,
            bankBranch: response.data.invoiceHdrDto.bankBranch,
            bankName: response.data.invoiceHdrDto.bankName,
            ifscCode: response.data.invoiceHdrDto.ifscCode,
            accountNo: response.data.invoiceHdrDto.accountNo,
            micrCode: response.data.invoiceHdrDto.micrCode,

            termsConditionType: response.data.invoiceHdrDto.termsConditionType,
            termsConditionName: response.data.invoiceHdrDto.termsConditionName,
            status: response.data.invoiceHdrDto.status,
            statusName: response.data.invoiceHdrDto.statusName,
            challanNo: response.data.invoiceHdrDto.challanNo,
            challanDate: response.data.invoiceHdrDto.challanDate,
            taxType: response.data.invoiceHdrDto.taxType,
            taxName: response.data.invoiceHdrDto.taxName,
            footerNote: response.data.invoiceHdrDto.footerNote,
            internalNote: response.data.invoiceHdrDto.internalNote,
            termsCondition: response.data.invoiceHdrDto.termsCondition,
            deliveryTerms: response.data.invoiceHdrDto.deliveryTerms,
            paymentTerms: response.data.invoiceHdrDto.paymentTerms,
            deliveryDays: response.data.invoiceHdrDto.deliveryDays,
            freightCharges: response.data.invoiceHdrDto.freightCharges,
            amountWithoutTax: parseFloat(netAmount).toFixed(2),
            amountWithTax: parseFloat(netAmount + Tax_Totals).toFixed(2),
            roundOffValue: 0,
            roundingFlag: false,
            updatedBy: response.data.invoiceHdrDto.updatedBy,
            updatedByDesc: response.data.invoiceHdrDto.updatedByDesc,
            updatedOn: response.data.invoiceHdrDto.updatedOn,
            approvedBy: response.data.invoiceHdrDto.approvedBy,
            approvedByDesc: response.data.invoiceHdrDto.approvedByDesc,
            approvedOn: response.data.invoiceHdrDto.approvedOn,
            isActive: response.data.invoiceHdrDto.isActive,
            transportationMode: response.data.invoiceHdrDto.transportationMode,
            vehicleType: response.data.invoiceHdrDto.vehicleType,
            vehicleTypeName: response.data.invoiceHdrDto.vehicleTypeName,
            bankId: response.data.invoiceHdrDto.bankId,
            bankName: response.data.invoiceHdrDto.bankName,
            accountantName: response.data.invoiceHdrDto.accountantName,
            accountNo: response.data.invoiceHdrDto.accountNo,
            ifscCode: response.data.invoiceHdrDto.ifscCode,
            micrCode: response.data.invoiceHdrDto.micrCode,
            transportationDistance:
              response.data.invoiceHdrDto.transportationDistance,
            gstSuppType: "",
            gstInvoiceStatus: "",
            gstInvoiceCategory: "",
            gstInvoiceType: "",
            gstAmtTaxable: "",
            gst_txpd_taxable_value: "",
            gstShippingBillNo: "",
            gstShippingBillDate: null,
            gstReason: "",
            gstr1ReturnPeriod: "",
            gstr3bReturnPeriod: "",
            reverseCharge: "",
            isAmended: "",
            amendedPos: "",
            amendedPeriod: "",
            poNumber: "",
            approveStatus: null,
            brokerCommissionPercent:
              response.data.invoiceHdrDto.brokerCommissionPercent,
            loadingPoint: response.data.invoiceHdrDto.loadingPoint,
            destinationRailHead:
              response.data.invoiceHdrDto.destinationRailHead,
            updatedBy: response.data.invoiceHdrDto.updatedBy,
            updatedOn: response.data.invoiceHdrDto.updatedOn,
            totalIGST:
              total_IGST == null ? "0.00" : parseFloat(total_IGST).toFixed(2),
            totalCGST:
              total_CGST == null ? "0.00" : parseFloat(total_CGST).toFixed(2),
            totalSGST:
              total_SGST == null ? "0.00" : parseFloat(total_SGST).toFixed(2),
            subTotal: parseFloat(netAmount).toFixed(2),
            taxTotal: parseFloat(Tax_Totals).toFixed(2),
            netTotal: parseFloat(netAmount).toFixed(2),
            TotalAmount: parseFloat(netAmount + Tax_Totals).toFixed(2),
            subTotal: parseFloat(netAmount).toFixed(2),
            taxTotal: parseFloat(Tax_Totals).toFixed(2),
            netTotal: parseFloat(netAmount).toFixed(2),
            ApproveButton: ApproveButton,
            RejectButton: RejectButton,
            UpdateButton: UpdateButton,
            EditButton: EditButton,
            ewayBillNo: response.data.ewayBillNo,
            eInvoiceAuthenticationApproval: response.data.invoiceHdrDto.eInvoiceAuthenticationApproval,
          };
          yield put(getJuteYarnInvoiceHeader(Header));
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function* generateEInvoice({ payload: { url, data } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(generateEInvoiceSuccessfull({ response }));
    if (response) {
      hideSpinner();
      if (response.data) {
        if (response.data.status) {
          swal(response.data.message, { icon: "success" });
        } else {
          swal(response.data.message, { icon: "error" });
        }
      }
    }
  } catch (error) {
    hideSpinner()
    swal(error.Error, { icon: "error" });
    console.log(error);
  }
}

function* generateEwayBill({ payload: { url, data } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(generateEwayBillSuccessfull({ response }));
    if (response) {
      hideSpinner();
      if (response.data) {
        if (response.data.status) {
          swal(response.data.message, { icon: "success" });
        } else {
          swal(response.data.message, { icon: "error" });
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function* getInvoiceYarnUomListData({ payload: { url, rowId } }) {
  const { JuteYarnInvoiceLineItems } = yield select(
    (state) => state.InvoiceReducer
  );
  try {
    const response = yield call(getListurl, url);
    if (response) {
      yield put(getInvoiceYarnUomListSuccessfull({ response }));
      if (response) {
        if (response.data) {
          if (response.data.length !== 0) {
            let ListFilter = response.data.filter((data) => {
              if (data.variable !== "0") {
                return data;
              }
            });
            var list = [];
            ListFilter.map((prop) => {
              list.push({
                label: prop.uomCode,
                name: prop.uomCode,
                value: prop.uomCode,
                variant: prop.id,
              });
            });
          }
        }
      }
    }
    if (JuteYarnInvoiceLineItems) {
      var LineUomRecords = [];
      if (JuteYarnInvoiceLineItems.length !== 0) {
        LineUomRecords = JuteYarnInvoiceLineItems.map((item) => {
          if (rowId == item.id) {
            if(list.length !==0){
              item.uom2Data = list;
            }else{
              swal('Please add variable UOM in the UOM Master')
              item.uom2Data = [];
            }
          }
          return item;
        });
        yield put(getJuteYarnInvoiceLineItems(LineUomRecords));
      }else{
   
      }
    }
  } catch (error) {
    console.log(error);
  }
}
export function* watchInvoiceList() {
  yield takeEvery(UPLOAD_INVOICE_FILES, UploadedInvoiceFileList);
  yield takeEvery(GET_INVOICE_FILES, getAllInvoiceFileList);
  yield takeEvery(DELETE_INVOICE_FILE, deleteInvoiceFile);
  yield takeEvery(INVOICE_LIST, getInvoiceList);
  yield takeEvery(CREATE_REGULAR_INVOICE, setRegualarInvoiceData);
  yield takeEvery(
    REGULAR_SALES_ORDER_VIEW_BYID_FOR_INVOICE,
    getSalesOrderViewByIdForInvoiceData
  );
  yield takeEvery(
    SALES_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE,
    getSalesOrderViewByIdForJuteGovtInvoiceData
  );
  yield takeEvery(
    SALES_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE,
    getSalesOrderViewByIdForJuteYarnInvoiceData
  );

  yield takeEvery(
    DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE,
    getDeliveryOrderViewByIdForJuteGovtInvoiceData
  );
  yield takeEvery(
    DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE,
    getDeliveryOrderViewByIdForJuteYarnInvoiceData
  );

  yield takeEvery(
    REGULAR_DELIVERY_ORDER_VIEW_BYID_FOR_INVOICE,
    getDeliveryOrderViewByIdForInvoiceData
  );
  yield takeEvery(REGULAR_INVOICE_VIEW_BY_ID, getRegualarInvoiceViewByIdData);
  yield takeEvery(JUTE_GOVT_INVOICE_VIEW_BY_ID, getJuteGovtInvoiceViewByIdData);
  yield takeEvery(UPDATE_REGULAR_INVOICE, UpdateRegualarInvoiceData);
  yield takeEvery(
    REGULAR_INVOICE_ITEMS_BY_ITEM_GROUP_ID,
    getRegularInvoiceItemByItemGroupData
  );
  yield takeEvery(
    REGULAR_INVOICE_ITEMS_DETAILS_BY_ITEM_ID,
    getRegularInvoiceItemDetailsByItemId
  );
  yield takeEvery(
    JUTE_GOVT_INVOICE_ITEMS_BY_ITEM_GROUP_ID,
    getJuteGovtInvoiceItemByItemGroupData
  );
  yield takeEvery(CREATE_JUTE_GOVT_INVOICE, setJuteGovtInvoiceData);
  yield takeEvery(CREATE_JUTE_YARN_INVOICE, setJuteYarnInvoiceData);
  yield takeEvery(UPDATE_JUTE_GOVT_INVOICE, UpdateJuteGovtInvoiceData);
  yield takeEvery(GET_VEHICLE_TYPE, getVehicleTypeData);
  yield takeEvery(
    SALES_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE,
    getSaleOrderViewByIdForJuteHessianInvoice
  );
  yield takeEvery(
    DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE,
    getDeliveryOrderViewByIdForJuteHessianInvoice
  );
  yield takeEvery(CREATE_JUTE_HESSIAN_INVOICE, CreateJuteHessianInvoiceData);
  yield takeEvery(
    JUTE_HESSIAN_INVOICE_VIEW_BY_ID,
    getJuteHessianInvoiceViewByIdData
  );
  yield takeEvery(UPDATE_JUTE_HESSIAN_INVOICE, UpdateJuteHessianInvoiceData);
  yield takeEvery(
    INVOICE_JUTE_YARN_GET_UOM_RELATION_VALUE,
    getInvoiceJuteYarnUomRelationValueData
  );
  yield takeEvery(INVOICE_JUTE_YARN_VIEW_BY_ID, getJuteYarnInvoiceViewByIdData);
  yield takeEvery(UPDATE_JUTE_YARN_INVOICE, UpdateJuteYarnInvoiceData);
  yield takeEvery(GENERATE_E_INVOICE, generateEInvoice);
  yield takeEvery(GENERATE_E_WAY_BILL, generateEwayBill);
  yield takeEvery(INVOICE_JUTE_YARN_UOM_LIST, getInvoiceYarnUomListData);
}

function* InvoiceSaga() {
  yield all([fork(watchInvoiceList)]);
}

export default InvoiceSaga;
