import {
  JUTE_QUALITY_CHECK,
  JUTE_QUALITY_CHECK_SUCCESSFULL,
  JUTE_QUALITY_CHECK_UPDATE,
  JUTE_COMMIT_MATERIAL_READING,
  JUTE_QUALITYCHECK_VIEWBYID,
  JUTE_QUALITYCHECK_VIEWBYID_SUCCESSFULL,
  JUTE_QUALITY_CHECK_HEADERS,
  JUTE_QUALITY_CHECK_LINEITEMS,
  CLEAR_JUTE_QUALITY_CHECK_LINEITEMS,
  JUTE_QUALITY_CHECK_UPDATE_SUCCESSFULL
} from "./actionTypes";

export const JuteQualityCheck = (url, data, history) => {
  return {
    type: JUTE_QUALITY_CHECK,
    payload: { url, data, history },
  };
};
export const JuteQualityCheckSuccessfull = (data) => {
  return {
    type: JUTE_QUALITY_CHECK_SUCCESSFULL,
    payload: data,
  };
};

export const juteQualityCheckUpdate = (url, data, history, getUrl) => {
  return {
    type: JUTE_QUALITY_CHECK_UPDATE,
    payload: { url, data, history, getUrl },
  };
};

export const juteQualityCheckUpdateSuccessfull = (data) => {
  return {
    type: JUTE_QUALITY_CHECK_UPDATE_SUCCESSFULL,
    payload: data,
  };
};
export const juteCommitMaterialReading = (url, history) => {
  return {
    type: JUTE_COMMIT_MATERIAL_READING,
    payload: { url, history },
  };
};

export const juteQualityCheckViewById = (url, history) => {
  return {
    type: JUTE_QUALITYCHECK_VIEWBYID,
    payload: { url, history },
  };
};
export const juteQualityCheckViewByIdSuccessfull = (data) => {
  return {
    type: JUTE_QUALITYCHECK_VIEWBYID_SUCCESSFULL,
    payload: data,
  };
};



export const JuteQualityCheckHeader = (data) => {
  return {
    type: JUTE_QUALITY_CHECK_HEADERS,
    payload: data,
  };
};
export const ClearJuteQualityCheckHeader = (data) => {
  return {
    type:JUTE_QUALITY_CHECK_HEADERS,
    payload: data,
  };
};

export const getJuteQualityCheckLineItems = (data) => {
  return {
    type: JUTE_QUALITY_CHECK_LINEITEMS,
    payload:data
  }
}

export const ClearJuteQualityCheckLineItems = (data) => {
  return {
    type:CLEAR_JUTE_QUALITY_CHECK_LINEITEMS,
    payload:data
  }
}