import {
  JUTE_BATCH_PLANNING_LIST,
  JUTE_BATCH_PLANNING_LIST_SUCCESSFULL,
  JUTE_PLAN_ASSIGN,
  JUTE_PLAN_ASSIGN_SUCCESSFULL,
// JUTE_GATE_ENTRY_LINE_ITEMS,
// JUTE_GATE_ENTRY_VIEW_BY_ID,
// JUTE_GATE_ENTRY_VIEW_BY_ID_SCCESSFULL,
// JUTE_GATE_ENTRY_CREATE,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  JuteBatchPlanningData: [],
  AssignPlanData:[],
  // gatelineItems:[
  //   {
  //     lineItemId:1,
  //     actualJuteTyp:0,      
  //     actualQuality:0,
  //     actualQuantity:"0",    
  //     actualWeight:"0",
  //     advisedJuteTyp:0,
  //     advisedQuality: 0,      
  //     advisedQuantity:"0",        
  //     advisedWeight:"0",
  //     createdBy: "",
  //     isPoAmment: "",
  //     itemDesc:"",
  //     juteGateSrlNo: "",
  //     kgs: "",      
  //     poLineItemNum: "",
  //     qcJuteQuality:0,
  //     qcJuteQuantity:"0",
  //     qcJuteType:0,
  //     qcJuteWeight:"0",
  //     quantity:"0",
  //     receivedIn:"",
  //     unitId:"",
  //     recId:0,
  //     hdrId:0, 
  //     readings:[],
  //     avgReading:0,
  //   },        
  // ]
};

const JuteBatchPlanningReducer = (state = initialState, action) => {
  switch (action.type) {
    case JUTE_BATCH_PLANNING_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTE_BATCH_PLANNING_LIST_SUCCESSFULL:
      state = {
        ...state,
        JuteBatchPlanningData: action.payload.response,
        loading: true,
      };
      break;
      case JUTE_PLAN_ASSIGN:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTE_PLAN_ASSIGN_SUCCESSFULL:
      state = {
        ...state,
        AssignPlanData: action.payload.response,
        loading: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default JuteBatchPlanningReducer;
