import React, { Component } from "react";
import CustomButton from "../../../components/Buttons/Buttons";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi, getTenantId, serverVars } from "../../../helpers/Consts";
import { getIndentPdf } from "../../../store/Purchase/Indent/actions";
import { getFileDownload } from "../../../helpers/server.js";
import { InwardLineItemsProps } from "../../../store/Purchase/Inward/actions";
import PrintIcon from "@mui/icons-material/Print";
import { Box, Grid } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { limitDecimals } from "../../../Utilities/helper.js";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import { getFilesofInward } from "../../../store/Purchase/Inward/actions";
import { isEmpty } from "../../../Utilities/helper";

class StoreReciptPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      date: moment().format("YYYY-MM-DD"),
      currentStep: 1,
      companyLogo: "",
      source: "",
      Inward_LineItems: [],
      sr_files_list: [],
      RowData: "",
      supplierName: "",
      SequenceNo: "",
      TodayDate: moment().format(),
      locationState: "",
    };
  }
  componentDidMount() {
    this.setState({
      locationState: this.props.location,
    });
    let companyData = JSON.parse(localStorage.getItem("authUser"));
    let companyId = localStorage.getItem("companyId");
    companyData.companyDetails.map((p) => {
      let id = p.value;
      if (companyId === id.toString()) {
        let companyName = p.label.split("-");
        this.setState({
          companyName: companyName[1],
          address1: p.address1,
          companyLogo: p.companyLogo,
          gstNo: p.gstNo,
          address2: p.address2,
          comName: p.name,
          phoneNo: p.contactNo,
          email: p.emailId,
        });
      }
    });
    if (this.props.locationState.state !== undefined) {
      let id = this.props.locationState.state.rowData.id;
      this.props.getFilesofInward(
        serverApi.GET_FILES +
          18 +
          "/" +
          id +
          "/" +
          localStorage.getItem("companyId"),
        this.props.history,
        18,
        "SR"
      );
    }
    if (this.props.Inward_LineItems) {
      let inwardLineitems = this.props.Inward_LineItems.filter(
        (item) => item.departmentId !== ""
      );
      this.setState({
        Inward_LineItems: inwardLineitems,
      });
    }
    if (this.props.location) {
      if (this.props.location.state !== undefined) {
        if (this.props.location.state.rowData) {
          this.setState({
            RowData: this.props.location.state.rowData,
          });
          if (this.props.location.pathname === "/create_material_inspection") {
            this.setState({
              RowData: this.props.location.state.rowData,
              supplierName: this.props.location.state.rowData.supplierName,
              SequenceNo: this.props.location.state.rowData.inwardSequenceNo,
            });
          }
          if (this.props.location.pathname === "/create_WorkReport") {
            this.setState({
              RowData: this.props.location.state.rowData,
              supplierName: this.props.location.state.rowData.supplierName,
              SequenceNo: this.props.location.state.rowData.inwardSequenceNo,
            });
          }
          if (this.props.location.pathname === "/create_inward") {
            this.setState({
              RowData: this.props.location.state.rowData,
              supplierName: this.props.location.state.rowData.supplierName,
              SequenceNo: this.props.location.state.rowData.inwardSequenceNo,
            });
          }
          if (this.props.location.pathname === "/create_store_bill_pass") {
            this.setState({
              RowData: this.props.location.state.rowData,
              supplierName: this.props.location.state.rowData.supplierName,
              SequenceNo: this.props.location.state.rowData.billpassNumber,
            });
          }
          if (this.props.location.pathname === "/Create_store_receipt") {
            this.setState({
              RowData: this.props.location.state.rowData,
              supplierName: this.props.location.state.rowData.supplierName,
              SequenceNo: this.props.location.state.rowData.storeReceiptNo,
            });
          }
        }
      }
    }
  }

  componentWillReceiveProps(props) {
    if (props.srfilesList) {
      if (props.srfilesList.data) {
        let filesList = props.srfilesList.data;
        this.setState({
          sr_files_list: filesList.data,
        });
      }
    }
  }
  getFilesDownload = (fileUploadId, fileName, fileExtension) => {
    let url = serverVars.SECURITY_API + "files/downloadfile/" + fileUploadId;
    getFileDownload(url, fileName, fileExtension);
  };
  handleEdit = () => {
    this.props.handleCurrentStep(this.state.currentStep);
  };
  handlePdf = () => {
    let Id = this.state.RowData.id;
    // this.props.getFileDownload(serverApi.DOWNLOAD_PDF+indentId+'/'+this.state.userDit.userId)
    let taskId = 0;
    if (this.props.location.pathname === "/create_material_inspection") {
      taskId = 4;
    }
    if (this.props.location.pathname === "/create_WorkReport") {
      taskId = 38;
    }
    if (this.props.location.pathname === "/create_inward") {
      taskId = 34;
    }
    if (this.props.location.pathname === "/create_store_bill_pass") {
      taskId = 7;
    }
    if (this.props.location.pathname === "/Create_store_receipt") {
      taskId = 3;
    }

    fetch(
      serverApi.DOWNLOAD_PDF +
        Id +
        "/" +
        this.state.userDit.userId +
        "/" +
        taskId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          "X-TenantId": getTenantId(window.location.hostname),
          Authorization:
            localStorage.getItem("token_type") +
            localStorage.getItem("access_token"),
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          this.state.SequenceNo + "_" + this.state.supplierName + ".pdf"
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  convertNumberToWords(amount) {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = "";
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakh ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  }

  withDecimal(n) {
    if (n !== undefined) {
      var nums = n.toString().split(".");
      var whole = this.convertNumberToWords(nums[0]);

      if (nums.length == 2) {
        if (parseInt(nums[1]) > 0) {
          return whole + "Rupees only";
        }
      }
      return whole + "Rupees only";
    }
  }

  getAmount = (qty, rate, discountMode, discountPercentage) => {
    let amount = 0;
    if (discountMode) {
      if (discountMode === 1) {
        amount = Number(qty) * Number(rate);
        if (
          discountPercentage !== 0 &&
          discountPercentage !== undefined &&
          discountPercentage !== ""
        ) {
          amount = amount - Number(discountPercentage);
        }
        return parseFloat(amount).toFixed(2);
      } else {
        amount = Number(qty) * Number(rate);
        if (
          discountPercentage !== 0 &&
          discountPercentage !== undefined &&
          discountPercentage !== ""
        ) {
          let disAmount = (amount * Number(discountPercentage)) / 100;
          amount = amount - disAmount;
        }
        return parseFloat(amount).toFixed(2);
      }
    } else {
      amount = Number(qty) * Number(rate);
      return parseFloat(amount).toFixed(2);
    }
  };
  render() {
    var TcsAmount = 0;
    var checkRoundOffValue = 0;
    var totalAmount = 0;
    var totalIgst = 0;
    var totalCgst = 0;
    var totalSgst = 0;
    var IndividualAmount = 0;
    var totalCgstValue = 0;

    if (this.state.Inward_LineItems) {

      var filteredproductsData = this.state.Inward_LineItems.filter((item) => {
        if (item.departmentId !== "" && item.isActive !== 0) {
          if (item.itemGroupId === "997" && item.taxPercentage === 0) {
            return false;
          } 
          else if (item.itemGroupId !== "997" && item.approvedQty === 0) {
            return false;
          } 
          else if (item.itemGroupId === "997" && item.taxPercentage !== 0) {
            return true;
          } 
          else {
            return true;
          }
        } else {
          return false;
        }
      });

      var filteredproducts = this.state.Inward_LineItems.filter(
        (item) => {
          if (item.departmentId !== "" && item.isActive !== 0) {
            if (item.itemGroupId === "997" && item.taxPercentage === 0) {
              return false;
            }
            else if (item.itemGroupId === "997" && item.taxPercentage !== 0) {
              return true;
            }
            
            else if (item.itemGroupId !== "997" && item.approvedQty === 0) {
              return false;
            }
            else {
              return true;
            }
          } else {
            return false;
          }
        })

      filteredproducts.forEach((data) => {
        if (data.approvedQty && data.rate) {
          totalAmount = totalAmount + data.approvedQty * data.rate;

          if (Number(data.discountMode !== null) && data.discountMode !== "") {
            let amount = Number(data.approvedQty) * Number(data.rate);
            if (Number(data.discountMode) === 1) {
              if (Number(data.discountPercentage) !== 0 &&
              Number(data.discountPercentage) !== undefined &&
              Number(data.discountPercentage) !== "" &&
              Number(data.discountPercentage) !== null
              ){
                IndividualAmount =
                  amount -
                    Number(data.discountPercentage)
                  ;
                totalAmount =
                  totalAmount -
                  Number( data.discountPercentage);
              }
            } else if (data.discountMode === 2) {
              if (Number(data.discountPercentage) !== 0 &&
               Number(data.discountPercentage)  !== undefined &&
                Number(data.discountPercentage) !== "" &&
                Number(data.discountPercentage) !== null
              ) {
                let disAmount =
                  (amount *
                    Number(
                       data.discountPercentage
                    )) /
                  100;
                IndividualAmount = amount - disAmount;
                totalAmount = totalAmount - disAmount;
              }
            }
          }
        }

        if (Number(this.props.InwardFillDetails.taxType) === 3) {
          totalIgst = 0;
          totalCgst = 0;
          totalSgst = 0;
        } else {
          if (this.props.InwardFillDetails.taxType === 1) {
            let amountWithDiscount = 0;
            if (
              data.discountMode !== undefined &&
              data.discountMode !== "" &&
              data.discountMode !== null
            ) {
              if (data.discountMode === 1) {
                amountWithDiscount =
                  data.approvedQty * data.rate - Number(data.discountPercentage);
                totalIgst =
                  totalIgst + (amountWithDiscount * data.taxPercentage) / 100;
              } else if (data.discountMode === 2) {
                amountWithDiscount =
                  (data.approvedQty *
                    data.rate *
                    Number(data.discountPercentage)) /
                  100;
                amountWithDiscount =
                  data.approvedQty * data.rate - amountWithDiscount;
                totalIgst =
                  totalIgst + (amountWithDiscount * data.taxPercentage) / 100;
              } else {
                amountWithDiscount =
                  data.approvedQty * data.rate - amountWithDiscount;
                totalIgst =
                  totalIgst + (amountWithDiscount * data.taxPercentage) / 100;
              }
            } else {
              totalIgst =
                totalIgst +
                (data.approvedQty * data.rate * Number(data.taxPercentage)) / 100;
            }
          } else {
            let amountWithDiscount = 0;
            if (
              data.discountMode !== undefined &&
              data.discountMode !== "" &&
              data.discountMode !== null
            ) {
              if (data.discountMode === 1) {
                amountWithDiscount =
                  data.approvedQty * data.rate - Number(data.discountPercentage);
                totalCgst =
                  totalCgst +
                  (amountWithDiscount * data.taxPercentage) / 100 / 2;
                totalSgst =
                  totalSgst +
                  (amountWithDiscount * data.taxPercentage) / 100 / 2;
              } else if (data.discountMode === 2) {
                amountWithDiscount =
                  (data.approvedQty *
                    data.rate *
                    Number(data.discountPercentage)) /
                  100;
                amountWithDiscount =
                  data.approvedQty * data.rate - amountWithDiscount;
                totalCgst =
                  totalCgst +
                  (amountWithDiscount * data.taxPercentage) / 100 / 2;
                totalSgst =
                  totalSgst +
                  (amountWithDiscount * data.taxPercentage) / 100 / 2;
              } else {
                amountWithDiscount =
                  data.approvedQty * data.rate - amountWithDiscount;
                totalCgst =
                  totalCgst +
                  (amountWithDiscount * data.taxPercentage) / 100 / 2;
                totalSgst =
                  totalSgst +
                  (amountWithDiscount * data.taxPercentage) / 100 / 2;
              }
            } else {
              amountWithDiscount = data.approvedQty * data.rate;
              totalCgst =
                totalCgst + (amountWithDiscount * data.taxPercentage) / 100 / 2;
              totalSgst =
                totalSgst + (amountWithDiscount * data.taxPercentage) / 100 / 2;
            }
          }
        }
      });
    }

    let otherChargesListWithNoTax = [];
    otherChargesListWithNoTax = this.state.Inward_LineItems.filter((item) => {
      if (Number(item.itemGroupId) === 997) {
        return item.isActive !== 0 && Number(item.taxPercentage) === 0;
      }
    });

    const totalOtherChargesWithNoTax = otherChargesListWithNoTax?.reduce((acc, data) => {
      return data.discountMode === "" || data.discountMode === 0.0
          ? acc + Number(parseFloat((Number(data.inwardQty) * Number(data.rate)) - Number(data.discountPercentage)).toFixed(2))
          : data.discountMode === 1
              ? acc + Number(parseFloat((Number(data.inwardQty) * Number(data.rate)) - Number(data.discountPercentage)).toFixed(2))
              : data.discountMode === 2
                  ? acc + Number(parseFloat((Number(data.inwardQty) * Number(data.rate)) - (Number(data.discountPercentage) / 100) * Number(data.inwardQty) * Number(data.rate)).toFixed(2))
                  : acc;
  }, 0);


    let netTotal =
      Number(totalAmount) +
      Number(totalCgst) +
      Number(totalIgst) +
      Number(totalSgst) + Number(totalOtherChargesWithNoTax);

      // let NetTotalValue = this.props.InwardFillDetails.tcsTds !== null ? this.props.InwardFillDetails.totalAmount + Number(totalOtherChargesWithNoTax) : this.props.InwardFillDetails.totalAmount;
      let NetTotalValue = this.props.InwardFillDetails.tcsTds !== null ? this.props.InwardFillDetails.totalAmount : this.props.InwardFillDetails.totalAmount;


    if (this.props.InwardFillDetails.roundOffValue) {
      checkRoundOffValue = Number(this.props.InwardFillDetails.roundOffValue);
    }
    if (this.props.InwardFillDetails.tcsPercentageValue) {
      TcsAmount =
        (Number(totalAmount) *
          Number(this.props.InwardFillDetails.tcsPercentageValue)) /
        100;
    }
    // this.props.InwardFillDetails.tcsAmount = Number(TcsAmount).toFixed(2);

    TcsAmount = TcsAmount.toFixed(2);


    if (netTotal != null || netTotal !== undefined || netTotal !== "") {
      let netTotal =
        Number(totalAmount) +
        Number(totalCgst) +
        Number(totalIgst) +
        Number(totalSgst) +
        Number(TcsAmount) +
        Number(checkRoundOffValue);

   

    var DateTime = this.state.TodayDate.split("T", 2);
    var TodayDate = moment(DateTime[0], "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var Time = DateTime[1].split("+")[0];


  var convertion = this.withDecimal(
    Math.round(NetTotalValue)
  );
}

    return (
      <>
        <div className="previewBlock">
          <div
            style={{ display: "flex", justifyContent: "end", padding: "10px" }}
          >
            {this.state.locationState.state && (
              <CustomButton
                label="Print"
                className="lightBlueButton"
                muIcon={<PrintIcon />}
                handleClick={this.handlePdf}
                type="button"
              />
            )}

            {this.props.InwardFillDetails.srStatusName === "REJECTED" ||
            this.props.InwardFillDetails.srStatusName === "APPROVED" ? (
              ""
            ) : (
              <CustomButton
                label="Edit"
                className="lightBlueButton"
                muIcon={<EditIcon />}
                handleClick={this.handleEdit}
                type="button"
              />
            )}
          </div>
          <table
            cellpadding="0"
            cellspacing="0"
            width="90%"
            className="previewTable"
          >
            <tbody>
              <tr>
                <td
                  colspan="3"
                  style={{ borderRight: "none", borderBottom: "none" }}
                >
                  {/* <h3>{this.state.companyName}</h3> */}
                  <img src={this.state.companyLogo} width="100%" alt="" />
                </td>
                <td
                  colspan="11"
                  style={{
                    borderBottom: "none",
                    borderLeft: "none",
                    textAlign: "right",
                  }}
                >
                  <h2 style={{ margin: "0px" }}> {this.state.companyName}</h2>
                  <p style={{ margin: "0px" }}>{this.state.address1}</p>
                  <span>GST Number:{this.state.gstNo}</span>
                </td>
              </tr>
              <tr>
                <td
                  colspan="14"
                  style={{ textAlign: "center", borderTop: "none" }}
                >
                  <h2>STORE RECEIPT</h2>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  {this.props.location.pathname === "/Create_store_receipt" ? (
                    <b>GRN Number: </b>
                  ) : (
                    <b>Entry Number: </b>
                  )}
                </td>
                <td
                  colspan="3"
                  className={
                    this.props.InwardFillDetails.GateEntryNo === "" ||
                    this.props.InwardFillDetails.GateEntryNo === undefined
                      ? "blur-text"
                      : ""
                  }
                >
                  {this.props.location.pathname === "/Create_store_receipt" ? (
                    <span>{this.props.InwardFillDetails.SRPrintNO}</span>
                  ) : (
                    <span>
                      {this.props.InwardFillDetails.GateEntryNo === "" ||
                      this.props.InwardFillDetails.GateEntryNo === undefined
                        ? "Will be generated after creation"
                        : this.props.InwardFillDetails.GateEntryNo}
                    </span>
                  )}
                </td>
                <td colspan="2">
                  {this.props.location.pathname === "/Create_store_receipt" ? (
                    <b>GRN Date: </b>
                  ) : (
                    <b>Date Time: </b>
                  )}
                </td>
                <td colspan="7">
                  {this.props.location.pathname === "/Create_store_receipt" ? (
                    <span>
                      {moment(
                        this.props.InwardFillDetails.storeReceiptDate
                      ).format("DD-MM-YYYY")}
                    </span>
                  ) : (
                    <span>
                      {moment(this.props.InwardFillDetails.inwardDate).format(
                        "DD-MM-YYYY"
                      )}
                    </span>
                  )}
                </td>
              </tr>
              {this.props.location.pathname === "/Create_store_receipt" && (
                <tr>
                  <td colspan="2">
                    <b>Supplier</b>
                  </td>
                  <td colspan="3">
                    <span>{this.props.InwardFillDetails.supplierName}</span>
                  </td>
                  <td colspan="2">
                    <b>Entry Number:</b>
                  </td>
                  <td colspan="7">
                    <span>{this.props.InwardFillDetails.GateEntryNo}</span>
                  </td>
                </tr>
              )}
              <tr>
                <td colspan="2">
                  <b>Challan Number: </b>
                </td>
                <td colspan="3">
                  <span>{this.props.InwardFillDetails.challanno}</span>
                </td>
                <td colspan="2">
                  <b>Challan Date: </b>
                </td>
                <td colspan="7">
                  <span>
                    {this.props.InwardFillDetails.challannoDate !== ""
                      ? moment(
                          this.props.InwardFillDetails.challannoDate
                        ).format("DD-MM-YYYY")
                      : ""}
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Vehicle Number: </b>
                </td>
                <td colspan="3">
                  <span>{this.props.InwardFillDetails.vehicleNumber}</span>
                </td>
                <td colspan="2">
                  <b>Driver Name: </b>
                </td>
                <td colspan="7">
                  <span>{this.props.InwardFillDetails.driverName}</span>
                </td>
              </tr>
              {this.props.location.pathname === "/Create_store_receipt" ? (
                ""
              ) : (
                <tr>
                  <td colspan="2">
                    <b>Supplier</b>
                  </td>
                  <td colspan="11">
                    <span>{this.props.InwardFillDetails.supplierName}</span>
                  </td>
                </tr>
              )}
              <tr>
                <td colspan="2">
                  <b>Consignment Number: </b>
                </td>
                <td colspan="3">
                  <span>{this.props.InwardFillDetails.consignmentNumber}</span>
                </td>
                <td colspan="2">
                  <b>Consignment Date:</b>
                </td>
                <td colspan="7">
                  <span>
                    {this.props.InwardFillDetails.consignmentDate !== ""
                      ? moment(
                          this.props.InwardFillDetails.consignmentDate
                        ).format("DD-MM-YYYY")
                      : ""}
                  </span>
                </td>
              </tr>

              <tr>
                <td colspan="2">
                  <b>Invoice No: </b>
                </td>
                <td colspan="3">
                  <span>{this.props.InwardFillDetails.invoiceNumber}</span>
                </td>
                <td colspan="2">
                  <b>Invoice Date:</b>
                </td>
                <td colspan="7">
                  <span>{moment(this.props.InwardFillDetails.invoiceDate).format("DD-MM-YYYY")}</span>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Po Number</b>
                </td>
                <td colSpan="12">
                  <span>{this.props.InwardFillDetails.poSequence}</span>
                </td>
              </tr>
              <tr align="center" valign="top">
                {/* <td rowSpan={2} width="5%">
                  <b>Po Number</b>
                </td> */}
                <td rowSpan={2} width="10%">
                  <b>Item Code (HSN/SAC)</b>
                </td>
                <td rowSpan={2} width="20%">
                  <b>Item Description</b>
                </td>
                <td rowSpan={2} width="10%">
                  <b>Quantity</b>
                </td>
                <td rowSpan={2} width="5%">
                  <b>Unit</b>
                </td>
                <td rowSpan={2} width="10%">
                  <b>Rate</b>
                </td>
                <td rowSpan={2} width="10%">
                  <b>Discount</b>
                </td>
                <td rowSpan={2} width="10%">
                  <b>Amount</b>
                </td>
                <td width="10%" colSpan={2}>
                  <b>IGST </b>
                </td>
                <td width="10%" colSpan={2}>
                  <b>CGST</b>
                </td>
                <td width="10%" colSpan={2}>
                  <b>SGST</b>
                </td>
              </tr>
              <tr>
                <td align="center">%</td>
                <td align="center">Amt</td>
                <td align="center">%</td>
                <td align="center">Amt</td>
                <td align="center">%</td>
                <td align="center">Amt</td>
              </tr>
              {filteredproductsData.map((data, index) => {
                if (data.isActive !== 0) {
                  return (
                    <tr key={index}>
                      <td>
                        {data.itemGroupId
                          ? data.itemGroupId + data.itemCode
                          : data.itemGroup + data.itemCode}
                        <br />
                        HSN Code : {data.hsnCode}
                      </td>
                      <td>
                        {data.itemName}
                        <tr>Make:{data.make}</tr>
                        <tr>Remarks:{data.remarks}</tr>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {parseFloat(data.approvedQty).toFixed(3)}
                      </td>
                      <td>{data.uom ? data.uom : data.uomCode}</td>
                      <td align="right">{parseFloat(data.rate).toFixed(3)}</td>
                      <td align="right">
                        {parseFloat(
                          data.discountPercentage === null
                            ? 0
                            : data.discountPercentage
                        )}
                        {data.discountMode && data.discountMode !== 1
                          ? "%"
                          : ""}
                      </td>
                      <td align="right">
                        {Number(
                          this.getAmount(
                            Number(data.approvedQty),
                            data.rate,
                            Number(data.discountMode),
                            this.props.location.state !== undefined
                              ? data.discount
                              : data.discountPercentage
                          )
                        ).toFixed(2)}
                      </td>
                      <td align="right">
                        {this.props.InwardFillDetails.taxType === 1
                          ? parseFloat(Number(data.taxPercentage)).toFixed(2)
                          : parseFloat(0).toFixed(2)}
                      </td>
                      <td align="right">
                        {this.props.InwardFillDetails.taxType === 1
                          ? data.discountMode === 1
                            ? (
                                ((data.approvedQty * data.rate -
                                  data.discountPercentage) *
                                  data.taxPercentage) /
                                100
                              ).toFixed(2)
                            : (
                                ((data.approvedQty * data.rate -
                                  (data.approvedQty *
                                    data.rate *
                                    data.discountPercentage) /
                                    100) *
                                  data.igstPercentage) /
                                100
                              ).toFixed(2)
                          : ""}
                      </td>

                      <td align="right">
                        {this.props.InwardFillDetails.taxType === 2
                          ? parseFloat(Number(data.taxPercentage) / 2).toFixed(
                              2
                            )
                          : parseFloat(0).toFixed(2)}
                      </td>
                      <td align="right">
                        {this.props.InwardFillDetails.taxType === 2
                          ? data.discountMode === 1
                            ? (
                                ((data.approvedQty * data.rate -
                                  data.discountPercentage) *
                                  data.taxPercentage) /
                                100 /
                                2
                              ).toFixed(2)
                            : (
                                ((data.approvedQty * data.rate -
                                  (data.approvedQty *
                                    data.rate *
                                    data.discountPercentage) /
                                    100) *
                                  data.taxPercentage) /
                                100 /
                                2
                              ).toFixed(2)
                          : ""}
                      </td>
                      <td align="right">
                        {this.props.InwardFillDetails.taxType === 2
                          ? parseFloat(Number(data.taxPercentage) / 2).toFixed(
                              2
                            )
                          : parseFloat(0).toFixed(2)}
                      </td>
                      <td align="right">
                        {this.props.InwardFillDetails.taxType === 2
                          ? data.discountMode === 1
                            ? (
                                ((data.approvedQty * data.rate -
                                  data.discountPercentage) *
                                  data.taxPercentage) /
                                100 /
                                2
                              ).toFixed(2)
                            : (
                                ((data.approvedQty * data.rate -
                                  (data.approvedQty *
                                    data.rate *
                                    data.discountPercentage) /
                                    100) *
                                  data.taxPercentage) /
                                100 /
                                2
                              ).toFixed(2)
                          : ""}
                      </td>
                    </tr>
                  );
                }
              })}

              <tr>
                <td colSpan={6}>
                  <b>Total</b>
                </td>
                <td colSpan={1} align="right">
                  <b> {parseFloat(totalAmount).toFixed(2)}</b>
                </td>
                <td colSpan={2} align="right">
                  <b> {parseFloat(totalIgst).toFixed(2)}</b>
                </td>
                <td colSpan={2} align="right">
                  <b> {parseFloat(totalCgst).toFixed(2)}</b>
                </td>
                <td colSpan={2} align="right">
                  <b>{parseFloat(totalSgst).toFixed(2)}</b>
                </td>
              </tr>

              {otherChargesListWithNoTax.map((data, index) => {
                return (
                  <tr key={index}>
                    <td colspan="3">
                      <b>{data.itemName}</b>
                    </td>
                    <td colSpan={10}>
                      <b>
                        {data.discountMode === 0
                          ? parseFloat(
                              Number(data.approvedQty) * Number(data.rate) -
                                Number(data.discountPercentage)
                            ).toFixed(2)
                          : data.discountMode === 1
                          ? parseFloat(
                              Number(data.approvedQty) * Number(data.rate) -
                                Number(data.discountPercentage)
                            ).toFixed(2)
                          : data.discountMode === 2
                          ? parseFloat(
                              Number(data.approvedQty) * Number(data.rate) -
                                (Number(data.discountPercentage) / 100) *
                                  Number(data.approvedQty) *
                                  Number(data.rate)
                            ).toFixed(2)
                          : null}
                      </b>
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td colSpan={3}>
                  <b>IGST</b>
                </td>
                <td colSpan={11}>
                  <b>
                    {this.props.InwardFillDetails.taxType === 1
                      ? parseFloat(totalIgst).toFixed(2)
                      : "0.00"}
                  </b>
                </td>
              </tr>

              <tr>
                <td colSpan={3}>
                  <b>CGST</b>
                </td>
                <td colSpan={11}>
                  <b>
                    {this.props.InwardFillDetails.taxType === 1
                      ? "0.00"
                      : parseFloat(totalCgst).toFixed(2)}
                  </b>
                </td>
              </tr>

              <tr>
                <td colSpan={3}>
                  <b>SGST</b>
                </td>
                <td colSpan={11}>
                  <b>
                    {this.props.InwardFillDetails.taxType === 1
                      ? "0.00"
                      : parseFloat(totalSgst).toFixed(2)}
                  </b>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <b>
                    {isEmpty(this.props.InwardFillDetails.tcsTds) ||
                    this.props.InwardFillDetails.tcsTds === "N/A"
                      ? "TCS/TDS Amount:"
                      : this.props.InwardFillDetails.tcsTds === "TCS"
                      ? "TCS Amount:"
                      : "TDS Amount:"}
                  </b>
                </td>
                <td colSpan={11}>
                  <b>
                    {this.props.InwardFillDetails.tcsTds === "N/A" ||
                    this.props.InwardFillDetails.tcsTds === null
                      ? "0.00"
                      : this.props.InwardFillDetails.tcsTds === "TCS"
                      ? limitDecimals(
                          Math.round(this.props.InwardFillDetails.tcsAmount),
                          2
                        )
                      : this.props.InwardFillDetails.tcsTds === "TDS"
                      ? limitDecimals(
                          Math.round(this.props.InwardFillDetails.tdsAmount),
                          2
                        )
                      : "0.00"}
                  </b>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <b>Total Value</b>
                </td>
                <td colSpan={11}>
                  <b>
                    {limitDecimals(Math.round(NetTotalValue),2)}
                  </b>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <b>Total Value(in Words)</b>
                </td>
                <td colSpan={11}>
                  <b>{convertion}</b>
                </td>
              </tr>
              <tr>
                <td
                  colspan="14"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {this.state.locationState.state !== undefined ? (
                      <b>Status: {this.props.InwardFillDetails.srStatusName}</b>
                    ) : (
                      ""
                    )}
                  </div>
                </td>
              </tr>
              {/* <tr>
                {this.props.location.pathname === "/Create_store_receipt" ? (
                  <>
                    <td
                      colspan="3"
                      style={{
                        borderTop: "none",
                        borderBottom: "none",
                        marginTop: "40px",
                        borderRight: "1px solid transparent",
                      }}
                    >
                      <b>Receipt Signature </b>
                    </td>
                    <td
                      colspan="5"
                      style={{
                        borderTop: "none",
                        borderBottom: "none",
                        marginTop: "40px",
                      }}
                    >
                      <b>Store Authority</b>
                    </td>
                    <td
                      colspan="7"
                      style={{
                        borderTop: "none",
                        borderBottom: "none",
                        marginTop: "40px",
                        textAlign: "right",
                        borderLeft: "2px solid transparent",
                      }}
                    >
                      <b>Authorised Signature </b>
                    </td>
                  </>
                ) : (
                  <td
                    colspan="16"
                    style={{
                      borderTop: "none",
                      borderBottom: "none",
                      marginTop: "40px",
                      textAlign: "right",
                    }}
                  >
                    <b>Authorised Signature </b>
                  </td>
                )}
              </tr> */}
              <tr>
                <td
                  colspan="14"
                  style={{ borderTop: "none", borderBottom: "none" }}
                >
                  <b>Remarks :</b>
                  <tr>{this.props.InwardFillDetails.remarks}</tr>
                </td>
              </tr>

              <tr>
                <td
                  colspan="14"
                  style={{ borderTop: "none", borderBottom: "none" }}
                >
                  <b>Internal Note :</b>
                  <tr>{this.props.InwardFillDetails.internalNote}</tr>
                </td>
              </tr>
              <tr>
                <td
                  colspan="6"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {this.props.locationState.state && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box gridColumn="span 12">
                          {this.state.sr_files_list.length !== 0 && (
                            <span>
                              <b>Uploaded Documents</b>
                            </span>
                          )}
                        </Box>
                        <Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={2}
                          className="ducumentUploadBlock uploadedContent"
                          style={{ padding: "10px" }}
                        >
                          {this.state.sr_files_list &&
                            this.state.sr_files_list.map((item) => (
                              <Box gridColumn="span 6">
                                <div className="documentUploadContent">
                                  <div
                                    className="uploadedInfo"
                                    style={{ display: "flex" }}
                                  >
                                    <img
                                      src={TickIcon}
                                      alt=""
                                      className="tickIcon"
                                      height={"20px"}
                                      width={"20px"}
                                    />
                                    <Tooltip
                                      TransitionComponent={Fade}
                                      TransitionProps={{ timeout: 600 }}
                                      title="Click to download File"
                                    >
                                      <div
                                        className="uplodedFileName"
                                        onClick={() => {
                                          this.getFilesDownload(
                                            item.fileUploadId,
                                            item.fileName,
                                            item.fileExtension
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.fileName}
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </Box>
                            ))}
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Inward_LineItems, srfilesList } = state.InwardReducer;
  return {
    Inward_LineItems,
    srfilesList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getFilesofInward,
    getIndentPdf,
    getFileDownload,
    InwardLineItemsProps,
  })(StoreReciptPreview)
);
