import {
  FINISHING_ENTRY_LIST,
  FINISHING_ENTRY_LIST_DELETE,
  FINISHING_ENTRY_LIST_DELETE_SUCCESSFULL,
  FINISHING_ENTRY_LIST_SUCCESSFULL,
  GET_ALL_MACHINE_DETAILS_DD,
  GET_ALL_MACHINE_DETAILS_DD_SUCCESSFULL,
  GET_PROCESS_MASTER_LIST,
  GET_PROCESS_MASTER_LIST_SUCCESSFULL,
  ADD_FINISHING_ENTRY,
  ADD_FINISHING_ENTRY_SUCCESSFULL,
  DELETE_FINISHING_ENTRY_DATA,
  DELETE_FINISHING_ENTRY_DATA_SUCCESSFULL
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  FinishingEntryListData: [],
  FinishingEntryListDelete:[],
  GetProcessMasterListData:[],
  GetAllMachineDetailsArray:[],
  add_finishing_entry_list: [],
  delete_finishing_entry: []
};

const FinishingEntryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FINISHING_ENTRY_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FINISHING_ENTRY_LIST_SUCCESSFULL:
      state = {
        ...state,
        FinishingEntryListData: action.payload.response,
        loading: true,
      };
      break;
      case FINISHING_ENTRY_LIST_DELETE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FINISHING_ENTRY_LIST_DELETE_SUCCESSFULL:
      state = {
        ...state,
        FinishingEntryListDelete: action.payload.response,
        loading: true,
      };
      break;
      case GET_PROCESS_MASTER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PROCESS_MASTER_LIST_SUCCESSFULL:
      state = {
        ...state,
        GetProcessMasterListData: action.payload.response,
        loading: true,
      };
      break;
      case GET_ALL_MACHINE_DETAILS_DD:
      state = {
        ...state,
        loading: true,
      };
      break;
      case GET_ALL_MACHINE_DETAILS_DD_SUCCESSFULL:
      state = {
        ...state,
        GetAllMachineDetailsArray: action.payload.response,
        loading: true,
      };
      break;
      case ADD_FINISHING_ENTRY:
      state = {
        ...state,
        loading: true,
      };
      break;
      case ADD_FINISHING_ENTRY_SUCCESSFULL:
      state = {
        ...state,
        add_finishing_entry_list: action.payload.response,
        loading: true,
      };
      break;
      case DELETE_FINISHING_ENTRY_DATA:
      state = {
        ...state,
        loading: true,
      };
      break;
      case DELETE_FINISHING_ENTRY_DATA_SUCCESSFULL:
      state = {
        ...state,
        delete_finishing_entry: action.payload.response,
        loading: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default FinishingEntryListReducer;
