//import { Switch } from "react-router-dom";

import {
    API_ERRORS,
    STOCK_TRANSFER_LIST,
    STOCK_TRANSFER_LIST_SUCCESSFULL,
    COMPANY_ALL_LIST,
    COMPANY_ALL_LIST_SUCCESSFULL,
    ALL_BRANCH_LIST,
    ALL_BRANCH_LIST_SUCCESSFULL,
    GET_WARE_HOUSE_LIST,
    GET_WARE_HOUSE_LIST_SUCCESSFULL,
    ADD_STOCK_TRANSFER,
    ADD_STOCK_TRANSFER_SUCCESSFULL
  } from "./actionTypes";
  
  const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    StockTransfer_List: [],
    error_msg: null,
    companyList: [],
    desgbranchList: [],
    wareHouseList: [],
    addStockTransfer: []
  };
  
  const StockTransferReducer = (state = initialState, action) => {
    switch (action.type) {
      case STOCK_TRANSFER_LIST:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case STOCK_TRANSFER_LIST_SUCCESSFULL:
        state = {
          ...state,
          StockTransfer_List: action.payload.response,
          loading: false,
        };
        break;
        case COMPANY_ALL_LIST:
          state = {
            ...state,
            loading: true,
          };
          break;
    
        case COMPANY_ALL_LIST_SUCCESSFULL:
          state = {
            ...state,
            companyList: action.payload.response,
            loading: false,
          };
          break;  
          case ALL_BRANCH_LIST:
          state = {
            ...state,
            loading: true,
          };
          break;
    
        case ALL_BRANCH_LIST_SUCCESSFULL:
          state = {
            ...state,
            desgbranchList: action.payload.response,
            loading: false,
          };
          break;  
          case GET_WARE_HOUSE_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
      
          case GET_WARE_HOUSE_LIST_SUCCESSFULL:
            state = {
              ...state,
              wareHouseList: action.payload.response,
              loading: false,
            };
            break;   
            case ADD_STOCK_TRANSFER:
              state = {
                ...state,
                loading: true,
              };
              break;
        
            case ADD_STOCK_TRANSFER_SUCCESSFULL:
              state = {
                ...state,
                addStockTransfer: action.payload.response,
                loading: false,
              };
              break;      
        case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
      default:
        state = { ...state };
        break;
    }
  
    return state;
  };
  
  export default StockTransferReducer;
  