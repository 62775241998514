import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import {
  CREATE_UOM_MASTER,
  UOM_MASTER_LIST,
  GET_UOM_RELATION_DATA,
  DELETE_UOM_RELATION,
  UOM_LIST_BASEDON_ITEM_ID,
} from "./actionTypes";
import {
  UomMasterSuccessfull,
  createUomMasterSuccessfull,
  getUomRelationSuccessfull,
  getUomRelation,
  deleteUomRelationSuccessfull,
  getUomListBasedOnItemIdSuccess,
} from "./actions";
import {
  getList,
  postAdd,
  getListurl,
  postDelete,
} from "../../../helpers/Server_Helper";
import swal from "sweetalert";
import { serverApi } from "../../../helpers/Consts";
import { showSpinner, hideSpinner } from "../../../helpers/Consts";

//delete uom relation data
function* deleteUom({ payload: { url, uomCode, id, history } }) {
  try {
    const response = yield call(postDelete, url);
    yield put(deleteUomRelationSuccessfull({ response }));
    swal(response.data.msg, { icon: "success" });
    if (response.status) {
      if (id) {
        let listUrl =
          serverApi.GET_UOM_RELATION +
          "/" +
          uomCode +
          "/" +
          "companyId" +
          "/" +
          localStorage.getItem("companyId") +
          "/" +
          "itemId" +
          "/" +
          id;
        yield put(getUomRelation(listUrl, history));
      } else {
        let listUrl =
          serverApi.GET_UOM_RELATION +
          "/" +
          uomCode +
          "/" +
          "companyId" +
          "/" +
          localStorage.getItem("companyId") +
          "/" +
          "itemId" +
          "/" +
          0;
        yield put(getUomRelation(listUrl, history));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* getUomMasterListSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(UomMasterSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* createUomMasterList({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    if (response.data.status === true) {
      hideSpinner();
      yield put(createUomMasterSuccessfull({ response }));
      swal(response.data.message, { icon: "success" });
      history.push("/uom_master");
    } else {
      yield put(createUomMasterSuccessfull({ response }));
      if (response.status === 201) {
        hideSpinner();
        if (data.recordType === 1) {
          swal(response.data.message, { icon: "success" });
          history.push("/uom_master");
        } else {
          swal("Relation Created successfully", { icon: "success" });
        }
        if (data.itemId) {
          let listUrl =
            serverApi.GET_UOM_RELATION +
            "/" +
            data.uomCode +
            "/" +
            "companyId" +
            "/" +
            localStorage.getItem("companyId") +
            "/" +
            "itemId" +
            "/" +
            data.itemId;
          yield put(getUomRelation(listUrl, history));
        } else {
          let listUrl =
            serverApi.GET_UOM_RELATION +
            "/" +
            data.uomCode +
            "/" +
            "companyId" +
            "/" +
            localStorage.getItem("companyId") +
            "/" +
            "itemId" +
            "/" +
            0;
          yield put(getUomRelation(listUrl, history));
        }
      } else if (response.status === 208) {
        hideSpinner();
        swal("Relation value already exist", { icon: "warning" });
      }
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        hideSpinner();
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

function* getUomRelationData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getUomRelationSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
  } catch (error) {
    console.log(error);
  }
}

function* getUomMasterBasedOnItemIdListSaga({ payload: { url } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getUomListBasedOnItemIdSuccess({ response }));
  } catch (error) {
    console.log(error);
  }
}

export function* watchUomList() {
  yield takeEvery(CREATE_UOM_MASTER, createUomMasterList);
  yield takeEvery(UOM_MASTER_LIST, getUomMasterListSaga);
  yield takeEvery(GET_UOM_RELATION_DATA, getUomRelationData);
  yield takeEvery(DELETE_UOM_RELATION, deleteUom);
  yield takeEvery(UOM_LIST_BASEDON_ITEM_ID, getUomMasterBasedOnItemIdListSaga);
}

function* UomMasterListSaga() {
  yield all([fork(watchUomList)]);
}

export default UomMasterListSaga;
