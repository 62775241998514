import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../components/Buttons/Buttons";
import { serverApi, getTenantId } from "../../../../helpers/Consts";
import TickIcon from "../../../../assets/images/fileUploadedTick.png";
import { getFileDownload } from "../../../../helpers/server.js";
import { Box, Tooltip,Grid } from "@mui/material";
import Fade from "@mui/material/Fade";
import { serverConfig } from "../../../../helpers/Consts";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { isEmpty, isEmptyWithZero ,limitDecimals} from "../../../../Utilities/helper";

const JuteYarnPreview = (props) => {
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  let dispatch = useDispatch();
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [gstNo1, setGstNo1] = useState("");
  const [gstNo2, setGstNo2] = useState("");
  const [email, setemail] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [phoneNo, setphoneNo] = useState("");
  const [companyLogo, setcompanyLogo] = useState("");
  const [currentDate, setcurrentDate] = useState(new Date().toLocaleString());
  const [editButton, seteditButton] = useState(true);
  const [SoJuteYarnHeadersData, setSoJuteYarnHeadersData] = useState({});
  const [SoJuteYarnLineItemsData, SetSoJuteYarnLineItemsData] = useState([]);
  const { InvoiceFiles } = useSelector((state) => state.InvoiceReducer);
  const [invoiceFiles, SetInvoiceFiles] = useState(
    InvoiceFiles?.data?.data || []
  );
  useEffect(() => {
    SetInvoiceFiles(InvoiceFiles?.data?.data);
  }, [InvoiceFiles]);


  useEffect(() => {
    let companyData = JSON.parse(localStorage.getItem("authUser"));
    let companyId = localStorage.getItem("companyId");
    companyData.companyDetails.map((p) => {
      let id = p.value;
      if (companyId === id.toString()) {
        setcompanyName(p.label);
        setcompanyLogo(p.companyLogo);
        setphoneNo(p.contactNo);
        setemail(p.emailId);
        setaddress1(p.address1);
        setaddress2(p.address2);
        setGstNo1(p.gstNo);
        setGstNo2(p.gstNo2);
      }
    });
  });

  const { JuteYarnInvoiceLineItems, JuteYarnInvoiceHeader } = useSelector(
    (state) => state.InvoiceReducer
  );
  const { billingShippingList } = useSelector(
    (state) => state.DropDownListReducer
  );

  useEffect(() => {
    SetSoJuteYarnLineItemsData(JuteYarnInvoiceLineItems);
    setSoJuteYarnHeadersData(JuteYarnInvoiceHeader);
  }, [JuteYarnInvoiceLineItems, JuteYarnInvoiceHeader]);

  const handlePdf = (printType) => {
    if (props.locationState.state !== undefined) {
      fetch(
        serverApi.REGULAR_INVOICE_PDF_DOWNLOAD +
          "3/" +
          JuteYarnInvoiceHeader.regInvoiceId +
          "/" +
          JSON.parse(localStorage.getItem("authUser")).userId +
          "/" +
          printType,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            "X-TenantId": getTenantId(window.location.hostname),
            Authorization:
              localStorage.getItem("token_type") +
              localStorage.getItem("access_token"),
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", JuteYarnInvoiceHeader.invoiceNo + ".pdf");
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
    }
  };

  const handleEWayBill = (printType) => {
 
  if(props.locationState.state !== "undefined") {
    fetch(
      serverApi.REGULAR_INVOICE_EWAY_BILL_PDF_DOWNLOAD +
        "3/" +
        JuteYarnInvoiceHeader.regInvoiceId  +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).userId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          "X-TenantId": getTenantId(window.location.hostname),
          Authorization:
            localStorage.getItem("token_type") +
            localStorage.getItem("access_token"),
        },
      }
    ).then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      // link.setAttribute("download", HeaderData.invoiceNo + ".pdf");
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  }
  }
  const convertNumberToWords = (amount) => {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();

    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = "";
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  };
  const handleEdit = () => {
    props.handleCurrentStep(2);
  };
  const withDecimal = (n) => {
    if (n !== undefined) {
      var nums = n.toString().split(".");
      var whole = convertNumberToWords(nums[0]);

      if (nums.length == 2) {
        if (parseInt(nums[1]) > 0) {
          return whole + "Rupees only";
        }
      }
      return whole + "Rupees only";
    }
  };
  let filterProduct = SoJuteYarnLineItemsData.filter(
    (item) =>
      item.isActive !== 0 &&
      item.itemGroupId !== null &&
      item.itemGroupId !== ""
  );
  var updatedOn = SoJuteYarnHeadersData.updatedOn
    ? moment(SoJuteYarnHeadersData.updatedOn, "YYYY-MM-DD")?.format(
        "DD-MM-YYYY"
      )
    : null;

  if (
    SoJuteYarnHeadersData.invoiceDate !== null &&
    SoJuteYarnHeadersData.invoiceDate !== "" &&
    SoJuteYarnHeadersData.invoiceDate !== undefined
  ) {
    var invoiceDate = moment(
      SoJuteYarnHeadersData.invoiceDate,
      "YYYY-MM-DD",
      true
    ).format("DD-MM-YYYY");
  }
  if (
    SoJuteYarnHeadersData.deliveryDate !== null &&
    SoJuteYarnHeadersData.deliveryDate !== "" &&
    SoJuteYarnHeadersData.deliveryDate !== undefined
  ) {
    var deliveryDate = moment(
      SoJuteYarnHeadersData.deliveryDate,
      "YYYY-MM-DD",
      true
    ).format("DD-MM-YYYY");
  }
  if (
    SoJuteYarnHeadersData.salesOrderDate !== null &&
    SoJuteYarnHeadersData.salesOrderDate !== "" &&
    SoJuteYarnHeadersData.salesOrderDate !== undefined
  ) {
    var salesOrderDate = moment(
      SoJuteYarnHeadersData.salesOrderDate,
      "YYYY-MM-DD",
      true
    ).format("DD-MM-YYYY");
  }
  return (
    <>
      <div className="previewBlock">
        <div
          style={{ display: "flex", justifyContent: "end", padding: "10px" }}
        >
           {props.locationState.state !==undefined &&
            SoJuteYarnHeadersData.statusId !== "" &&
            !isEmpty(SoJuteYarnHeadersData.ewayBillNo) && (
              <CustomButton
                label="E-way Bill Copy Print"
                className="lightBlueButton"
                muIcon={<PrintIcon />}
                handleClick={() => {
                  handleEWayBill(1);
                }}
                type="button"
              ></CustomButton>
            )}
          {props.locationState.state !==undefined && (
            <CustomButton
              label="Consignee Copy Print"
              handleClick={() => {
                handlePdf(1);
              }}
              type="button"
              className="lightBlueButton"
              muIcon={<PrintIcon />}
            />
          )}
          {props.locationState.state !==undefined && SoJuteYarnHeadersData.EditButton && (
            <CustomButton
              label="Edit"
              handleClick={() => {
                handleEdit();
              }}
              type="button"
              className="lightBlueButton"
              muIcon={<EditIcon />}
            />
          )}
        </div>
        <div id="SOJuteYarnPreview">
          <table
            cellpadding="0"
            cellspacing="0"
            width="100%"
            className="previewTable"
          >
            <tbody>
              <tr>
                <td
                  colspan="5"
                  style={{ borderRight: "none", borderBottom: "none" }}
                >
                  <img src={companyLogo} width="100%" alt="" />
                </td>
                <td
                  colspan="8"
                  style={{
                    borderBottom: "none",
                    borderLeft: "none",
                    textAlign: "right",
                  }}
                >
                  <h2>{companyName}</h2>
                  {address1}
                  <br />
                  {address2}
                  <br />
                  <span><b>Ph.No: </b>{phoneNo}, </span>
                  <span><b>Email: </b> {email}</span>
                  <br/>
                  <b>GST No:</b> {gstNo1 || "n/a"}
                  <br />
                  <br></br>
                </td>
              </tr>
              <tr>
                <td
                  colspan="13"
                  style={{ textAlign: "center", borderTop: "none" }}
                >
                  <h2>INVOICE</h2>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Invoice NO : </b>
                </td>
                <td
                  colspan="3"
                  className={
                    SoJuteYarnHeadersData.invoiceNo === "" ? "blur-text" : ""
                  }
                >
                  {SoJuteYarnHeadersData.invoiceNo === ""
                    ? "Will be generated after creation"
                    : SoJuteYarnHeadersData.invoiceNo}
                </td>
                <td colspan="4">
                  <b>Invoice Date:</b>
                </td>
                <td colspan="3">{invoiceDate}</td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Delivery Order NO : </b>
                </td>
                <td colspan="3">{SoJuteYarnHeadersData.deliveryOrderNo}</td>
                <td colspan="4">
                  <b>Delivery Order DATE :</b>
                </td>
                <td colspan="3">{deliveryDate}</td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Sale Order NO : </b>
                </td>
                <td colspan="3">{SoJuteYarnHeadersData.salesOrderNumber}</td>
                <td colspan="4">
                  <b>Sale Order DATE :</b>
                </td>
                <td colspan="3">{salesOrderDate}</td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Consignee :</b>
                </td>
                <td colspan="3">{SoJuteYarnHeadersData.customerName}</td>
                <td colspan="4">
                  <b>Branch : </b>
                </td>
                <td colspan="3">{SoJuteYarnHeadersData.branchName}</td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Broker :</b>
                </td>
                <td colspan="10">{SoJuteYarnHeadersData.brokerName}</td>
              </tr>
              <tr>
                <td
                  colspan="5"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    padding: "none",
                  }}
                >
                  <br /> <b>Billed To : </b>
                  {SoJuteYarnHeadersData.customerName} -
                  <br />
                  {" "}
                  {SoJuteYarnHeadersData.billingAddress} <br />
                  <br />
                  <b>GSTIN : {"  "}</b>
                  {SoJuteYarnHeadersData.billedToGstin}
                  <br />
                  <b>Contact Person : {"  "}</b>
                  {SoJuteYarnHeadersData.billedToContactPerson}
                  <br />
                  <b>Contact Number : {"  "}</b>
                  {SoJuteYarnHeadersData.billedToContactNumber}
                  <br />
                  <b>EMAIL ID : {"  "}</b>
                  {SoJuteYarnHeadersData.billedToEmail}
                </td>
                <td
                  colspan="8"
                  style={{
                    border: "none"
                  }}
                >
                  <br />
                  <b> Shipped To :{"  "}</b>
                  {SoJuteYarnHeadersData.customerName} -
                  <br />
                  {" "}
                  {SoJuteYarnHeadersData.shippingAddress}
                  <br />
                  <br />
                  <b>GSTIN :</b> {SoJuteYarnHeadersData.shippedToGstin}
                  <br />
                  <b>Contact Person : {"  "}</b>{" "}
                  {SoJuteYarnHeadersData.shippedToContactPerson} <br />
                  <b>Contact Number : {"  "}</b>
                  {SoJuteYarnHeadersData.shippedToContactNumber}
                  <br />
                  <b>EMAIL ID : </b>
                  {SoJuteYarnHeadersData.shippedToEmail}
                </td>
              </tr>
              <tr>
                <td rowspan="2" align={"center"} width={"4%"}>
                  <b>S.No</b>
                </td>
                <td rowspan="2" align={"center"} width={"8%"}>
                  <b>Item CODE</b>
                </td>
                <td rowspan="2" align={"center"} width={"8%"}>
                  <b>Item Name</b>
                </td>
                <td rowspan="2" align={"center"} width={"32%"}>
                  <b>Item Desc</b>
                </td>
            
                <td rowspan="2" align={"center"} width={"8%"}>
                  <b>Quantity/UOM</b>
                </td>
         
                <td rowspan="2" align={"center"} width={"8%"}>
                  <b>RATE/RateUom</b>
                </td>
                {/* <td rowspan="2">
                  <b>Rate after Discount</b>
                </td> */}
                <td rowspan="2" align={"center"} width={"8%"}>
                  <b>Amount</b>
                </td>
                <td colspan="2" align={"center"} width={"8%"}>
                  <b>IGST</b>
                </td>
                <td colspan="2" align={"center"} width={"8%"}>
                  <b>CGST</b>
                </td>
                <td colspan="2" align={"center"} width={"8%"}>
                  <b>SGST</b>
                </td>
              </tr>
              <tr>
                <td>
                  <b>%</b>
                </td>
                <td>
                  <b>Amt</b>
                </td>
                <td>
                  <b>%</b>
                </td>
                <td>
                  <b>Amt</b>
                </td>
                <td>
                  <b>%</b>
                </td>
                <td>
                  <b>Amt</b>
                </td>
              </tr>
              {filterProduct &&
                filterProduct.map((item, i) => {
                  return (
                    <>
                      <tr>
                        <td>{i + 1}</td>
                        <td>{item.itemCode}</td>
                        <td>{item.itemGroupName}</td>
                        <td>
                          {item.itemName}
                          <br />
                          <span>
                            <b>Remarks :</b> {item.remarks}
                          </span>{" "}
                          <br />
                          <span>
                            <b>HSN Code :</b> {item.hsncode}
                            <br />
                          </span>
                          <span>
                            {item.quantity}-{item.yarnQuantityUom} =
                          {limitDecimals(
                                item.yarnQuantityCalculatedValue
                              ,3)}{" "}
                              - {item.uom}
                          </span>
                        </td>
                        <td align="right">
                          {isEmptyWithZero( item.yarnQuantityUomRelationValue)  ? (
                            ""
                          ) : (
                            <>
                          {limitDecimals(item.quantity,3)} {item.uom}
                          <br />
                           
                            </>
                          )}
                        </td>
                        <td align="right">
                          {isEmptyWithZero(item.yarnRateUomRelationValue) ? (
                            ""
                          ) : (
                            <>
                              {limitDecimals(item.rate,3)}{" "}
                              - RS/{item.yarnRateUom}
                              <br />
                          {/* {limitDecimals(item.yarnRateCalculatedValue,3)} - RS/{item.uom}     */}
                            </>
                          )}
                        </td>
                        {/* <td align="right">
                          {limitDecimals(item.discountedRate,3)}
                        </td> */}
                        <td align="right">{limitDecimals(item.netAmount,2)}</td>
                        <td align="right">{limitDecimals(item.igstPercent,2)}</td>
                        <td align="right">{limitDecimals(item.igstAmount,2)}</td>
                        <td align="right">
                          {limitDecimals(item.cgstPercent,2)}
                        </td>
                        <td align="right">{limitDecimals(item.cgstAmount,2)}</td>
                        <td align="right">
                          {limitDecimals(item.sgstPercent,2)}
                        </td>
                        <td align="right">
                          {limitDecimals(item.sgstAmount,2)}
                        </td>
                      </tr>
                    </>
                  );
                })}
              <tr>
                <td colspan="3">
                  <b>Taxable Value</b>
                </td>
                <td colspan="13">
                  {limitDecimals(SoJuteYarnHeadersData.netTotal,2)}
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Total IGST</b>
                </td>
                <td colspan="13">
                  {limitDecimals(SoJuteYarnHeadersData.totalIGST,2)}
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Total CGST</b>
                </td>
                <td colspan="13">
                  {limitDecimals(SoJuteYarnHeadersData.totalCGST,2)}
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Total SGST</b>
                </td>
                <td colspan="13">
                  {limitDecimals(SoJuteYarnHeadersData.totalSGST,2)}
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Total Value with Tax</b>
                </td>
                <td colspan="13">
                  {limitDecimals(SoJuteYarnHeadersData.TotalAmount,2)}
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Total Amount in words</b>
                </td>
                <td colspan="13">
                  {withDecimal(limitDecimals(SoJuteYarnHeadersData.TotalAmount,2))}
                </td>
              </tr>
         
              <tr>
                <td
                  colspan="9"
                  style={{
                    borderTop: "none",
                    borderRight: "none",
                    borderBottom: "none",
                    marginTop: "5px",
                  }}
                ></td>
              </tr>
              <tr>
                <td
                  colspan="9"
                  style={{
                    borderTop: "none",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  <b>Bank Details:</b>
                  <br />
                  <b>{companyName}</b>
                  <br />
                  <b> Bank:{"  "} </b>
                  {SoJuteYarnHeadersData.bankName}
                  <br />
                  <b> Branch: {"  "}</b>
                  {SoJuteYarnHeadersData.bankBranch}
                  <br />
                  <b> A/c No:{"  "}</b>
                  {SoJuteYarnHeadersData.accountNo}
                  <br />
                  <b> IFSC Code:{"  "}</b>
                  {SoJuteYarnHeadersData.ifscCode}
                  <br />
                  <b> MICR No:{"  "}</b>
                  {SoJuteYarnHeadersData.micrCode}
                  <br />
                  <br />
                  <b>Delivery Days: </b>{" "}
                  {SoJuteYarnHeadersData.deliveryDays
                    ? SoJuteYarnHeadersData.deliveryDays + " Days"
                    : "N/A"}
                  <br />
                  <b>Delivery Terms: </b>{" "}
                  <span>{SoJuteYarnHeadersData.deliveryTerms}</span>
                  <br />
                  <b>Payment Terms (In Days): </b>{" "}
                  {SoJuteYarnHeadersData.paymentTerms || "N/A"}
                  <br />
                  <b>Footer Notes: </b>{" "}
                  {SoJuteYarnHeadersData.footerNote || "N/A"}
                  <br />
                  <b>Internal Notes: </b>{" "}
                  {SoJuteYarnHeadersData.internalNote || "N/A"}
                  <br />
                  <b>Broker Percentage: </b>{" "}
                  {SoJuteYarnHeadersData.brokerCommissionPercent 
                    ? limitDecimals(
                        Number(SoJuteYarnHeadersData.brokerCommissionPercent)
                      ,2)+"%"
                    : "0.00%"}
                  <br />
                </td>
              </tr>
              <tr>
                    <td
                      colSpan="10"
                      style={{
                        borderTop: "none",
                        borderBottom: "none",
                        borderRight: "none",
                        borderLeft: "none",
                      }}
                    >
                {props.locationState.state !== undefined ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box gridColumn="span 12">
                            <span>
                              <b>Support Documents:</b>
                            </span>
                          </Box>
                          <Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={2}
                            className="ducumentUploadBlock uploadedContent"
                            style={{ padding: "10px" }}
                          >
                            {invoiceFiles?.length > 0 &&
                              invoiceFiles.map((item) => (
                                <Box gridColumn="span 6">
                                <div
                                  className="documentUploadContent"
                                  key={item.fileUploadId}
                                >
                                  <div
                                    className="uploadedInfo"
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <img
                                      src={TickIcon}
                                      alt=""
                                      className="tickIcon"
                                      style={{
                                        height: "16px",
                                        width: "16px",
                                        marginRight: "8px",
                                      }}
                                    />
                                    <Tooltip
                                      TransitionComponent={Fade}
                                      TransitionProps={{ timeout: 600 }}
                                      title="Click to download File"
                                    >
                                      <div
                                        className="uplodedFileName"
                                        onClick={() => {
                                          getFileDownload(
                                            serverConfig.SERVER_URL +
                                              "security-api/api/files/downloadfile/" +
                                              item.fileUploadId,
                                            item.fileName,
                                            item.fileExtension
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.fileName}
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                                </Box>
                              ))}
                          </Box>
                        </Grid>
                      </Grid>
                   
                ) : (
                  ""
                )}
                 </td>
                  </tr>
              <tr>
                <td
                  colspan="19"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                  }}
                >
                  <b>Terms and Conditions: </b> <br />
                  {SoJuteYarnHeadersData.termsCondition}
                  <br />
                  {""}
                  <br />
                  {props.locationState.state !== undefined ? (
                    <>
                      <b>Last Modified On: </b> {updatedOn}
                    </>
                  ) : (
                    ""
                  )}
                  <br />
                  {props.locationState.state !== undefined ? (
                    <>
                      <b>Last Modified By: </b>{" "}
                      {SoJuteYarnHeadersData.updatedBy}
                    </>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
              <tr>
                <td
                  colspan="9"
                  style={{
                    borderTop: "none",
                    borderRight: "none",
                    borderBottom: "none",
                    marginTop: "5px",
                  }}
                >
                 {props.locationState.state !== undefined ? <b>Status: {SoJuteYarnHeadersData.statusName}</b> :""}
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default JuteYarnPreview;
