import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/PaginationGridView";
import { getUomMasterData } from "../../../store/Master/UomMaster/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      uomListData: [],
      recordType: 1,
      uomCode: null,
      uomDsc: null,
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      dataexp: "",
    };
  }

  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyID: this.state.companyId,
      recordType: 1,
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
      uomCode: null,
      uomDsc: null,
    };

    this.props.getUomMasterData(
      serverApi.GET_ALL_UOM_LIST,
      data,
      this.props.history
    );
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({  dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.Uom_List) {
      this.setState({
        uomListData: props.Uom_List.data,
        totalRecs: props.Uom_List.totalRecords
      });
    }
  }

  handleSearch = (searchData) => {
    var data = {
      cipher: this.state.userDit.cipher,
      companyID: searchData.companyID,
      recordType: searchData.recordType,
      uomCode: searchData.uomCode,
      uomDsc: searchData.uomDsc,
      startIndex: 1,
      lastIndex: 10,
    };
    this.setState({
      recordType: searchData.recordType,
      uomCode: searchData.uomCode,
      uomDsc: searchData.uomDsc,
    });
    this.props.getUomMasterData(
      serverApi.GET_ALL_UOM_LIST,
      data,
      this.props.history
    );
  };
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const data = {
        cipher: this.state.userDit.cipher,
        companyID: localStorage.getItem("companyId"),
        startIndex: sizePerPage,
        lastIndex: currentIndex,
        recordType: this.state.recordType,
        uomCode: this.state.uomCode,
        uomDsc: this.state.uomDsc,
      };
      if (currentIndex >= 0) {
        this.props.getUomMasterData(
          serverApi.GET_ALL_UOM_LIST,
          data,
          this.props.history
        );
      }
    } else {
      const data = {
        cipher: this.state.userDit.cipher,
        companyID: localStorage.getItem("companyId"),
        startIndex: currentIndex === 0 ? currentIndex + 1 : currentIndex,
        lastIndex: sizePerPage,
        recordType: this.state.recordType,
        uomCode: this.state.uomCode,
        uomDsc: this.state.uomDsc,
      };
      if (currentIndex >= 0) {
        this.props.getUomMasterData(
          serverApi.GET_ALL_UOM_LIST,
          data,
          this.props.history
        );
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.uomListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={false}
          actions={true}
          url="/create_uom_master"
          handleSearch={this.handleSearch}
          filter_form={"uomMaster"}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          apiexp={serverApi.GET_ALL_UOM_LIST}
          payloadexp={this.state.dataexp}
          expName={"UOM-Master.csv"}
        />
      </>
    );
  }
}
const Columns = [
  {
    Header: "UOM Code",
    accessor: "uomCode",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "UOM Desc",
    accessor: "uomDsc",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
];

const mapStatetoProps = (state) => {
  const { Uom_List } = state.UomListReducer;
  return { Uom_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getUomMasterData,
  })(Index)
);
