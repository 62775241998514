import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
// import DynamicSelect from "../Dropdown/Jute/DynamicSelect";
import DynamicSelect from "../../components/Dropdown/Master/DynamicSelectMasters"
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DesktopDatePicker from "@mui/lab/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { serverApi } from "../../helpers/Consts";
import {
  getSupplierListMultiselect,
  getStatusList,
} from "../../store/Global/DropDownApis/actions";
import {
  getVehicleList,
  getAgentDropdownList,
} from "../../store/MasterDropdownApis/actions";
import TextFieldNormal from "../TextField/TextFieldNormal";

class JuteMaterialRequestFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      supplierListMultiselect: [],
      VehicleListData: [],
      StatusListData: [],
      agentDropdownList: [],
      fromDate: moment().day(-7).format("YYYY-MM-DD").toString(),
      toDate: moment().format("YYYY-MM-DD").toString(),
      gateEntryNO: "",
      supplier: "",
      supplierValue: "",
      vehicle: "",
      vehicleValue: "",
      statusValue: "",
      status: "",
      agentValue: "",
      agent: "",
      mrPrintNo:"",
      poNum:""
    };
  }

  componentDidMount() {
    this.props.getSupplierListMultiselect(
      serverApi.GET_SUPPLIER_LIST_JPO +
        "JO/" +
        localStorage.getItem("companyId"),
      this.props.history
    );
    this.props.getAgentDropdownList(
      serverApi.GET_SUPPLIER_LIST_JPO +
        "O/" +
        localStorage.getItem("companyId"),
      this.props.history
    );
    this.props.getVehicleList(
      serverApi.GET_VEHICLE_DATA +
        localStorage.getItem("companyId") +
        "/" +
        this.state.userDit.cipher
    );

    this.props.getStatusList(serverApi.STATUS_LISTS, this.props.history);
  }
  componentWillReceiveProps(props) {

    if (props.supplierListMultiselect) {
      if (props.supplierListMultiselect.data) {
          let List = [];
          props.supplierListMultiselect.data.map((item) => {
            List.push({
              value: item.code,
              name: item.name,
              label: item.name,
            });
          });
          this.setState({
            supplierListMultiselect: List,
          });
    }
  }
    if (props.agentDropdownList) {
      if (props.agentDropdownList.data) {
          let List = [];
          props.agentDropdownList.data.map((item) => {
            List.push({
              value: item.code,
              name: item.label,
              label: item.label,
            });
          });
          this.setState({
            agentDropdownList: List,
          });
    }
  }
    if (props.VehicleData) {
      this.setState({
        VehicleListData: props.VehicleData.data,
      });
    }
    if (props.statusList) {
      this.setState({
        StatusListData: props.statusList.data,
      });
    }
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "Supplier") {
      this.setState({
        supplierValue: selectedValue === 0 ? "" : selectedValue,
        supplier: selectedName,
      });
    }
    if (name === "Vehicle") {
      this.setState({
        vehicleValue: selectedValue === 0 ? "" : selectedValue,
        vehicle: selectedName,
      });
    }
    if (name === "Status") {
      this.setState({
        statusValue: selectedValue === 0 ? "" : selectedValue,
        status: selectedName,
      });
    }
    if (name === "Agent") {
      this.setState({
        agentValue: selectedValue === 0 ? "" : selectedValue,
        agent: selectedName,
      });
    }
  };
  handleSelectDate = (e, name) => {
    if (name === "FromDate") {
      let date = moment(e).format();
      let formattedFromDate = date.split("T", 1).toString();
      this.setState({
        fromDate: formattedFromDate,
      });
    }

    if (name === "ToDate") {
      let date = moment(e).format();
      let formattedToDate = date.split("T", 1).toString();

      this.setState({
        toDate: formattedToDate,
      });
    }
  };
  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "GateEntryNo") {
      this.setState({
        gateEntryNO: event,
      });
    }
    if (key === "mrPrintNo") {
      this.setState({
        mrPrintNo: event,
      });
    }
    if (key === "poNum") {
      this.setState({
        poNum: event,
      });
    }
  };
  handleSubmit = () => {
    var data = {
      fromDate: moment(this.state.fromDate).format("DD-MM-YYYY"),
      toDate: moment(this.state.toDate).format("DD-MM-YYYY"),
      gateEntryNum: this.state.gateEntryNO,
      supplier: this.state.supplier,
      supplierValue: this.state.supplierValue,
      vehicle: this.state.vehicle,
      vehicleValue: this.state.vehicleValue,
      status: this.state.status,
      statusValue: this.state.statusValue,
      agentValue: this.state.agentValue,
      agent: this.state.agent,
      mrPrintNo:this.state.mrPrintNo,
      poNum:this.state.poNum
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleClear = () => {
    this.setState({
      supplier: "",
      supplierValue: "",
      vehicle: "",
      vehicleValue: "",
      fromDate: "",
      toDate: "",
      mrPrintNo:"",
    });
  };

  render() {
    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid spacing={0}>
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
                {/* <div>Save View</div> */}
              </div>
            </div>
            <div className="procurementFilter">
              <Grid
                item
                xs={12}
                className="filterBlockContainer juteFilterBlock"
              >
                <Box gridColumn="span 3">
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <div className="juteFilterDateBlock">
                        <div className="consoleTextFieldBlock">
                          <div>
                            <div className="TextFieldBlock">
                              <label>From Date</label>
                            </div>
                            {/* <ConsoleDatePicker /> */}
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                inputFormat="dd-MM-yyyy"
                                name="dateOne"
                                value={this.state.fromDate}
                                fullWidth
                                onChange={(e) =>
                                  this.handleSelectDate(e, "FromDate")
                                }
                                renderInput={({
                                  inputRef,
                                  inputProps,
                                  InputProps,
                                }) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    className="fromDatePicker"
                                  >
                                    <input ref={inputRef} {...inputProps} />
                                    {InputProps?.endAdornment}
                                  </Box>
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="juteFilterDateBlock">
                        <div className="consoleTextFieldBlock">
                          <div>
                            <div className="TextFieldBlock">
                              <label>To Date</label>
                            </div>
                            {/* <ConsoleDatePicker /> */}
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                inputFormat="dd-MM-yyyy"
                                name="dateOne"
                                value={this.state.toDate}
                                fullWidth
                                onChange={(e) =>
                                  this.handleSelectDate(e, "ToDate")
                                }
                                renderInput={({
                                  inputRef,
                                  inputProps,
                                  InputProps,
                                }) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    className="fromDatePicker"
                                  >
                                    <input ref={inputRef} {...inputProps} />
                                    {InputProps?.endAdornment}
                                  </Box>
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.supplierListMultiselect}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handleSelectChange}
                          selected={this.state.supplierValue}
                          update={this.state.supplierValue ? 1 : 0}
                          name="Supplier"
                          label="Supplier"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          value={this.state.mrPrintNo}
                          placeholder={"Enter here"}
                          label="MR Number"
                          caps="OFF"
                          onChange={this.onhandlechange("mrPrintNo")}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          value={this.state.gateEntryNO}
                          placeholder={"Enter here"}
                          label="Gate Entry Number"
                          caps="OFF"
                          onChange={this.onhandlechange("GateEntryNo")}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.VehicleListData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handleSelectChange}
                          selected={this.state.vehicleValue}
                          update={this.state.vehicleValue ? 1 : 0}
                          name="Vehicle"
                          label="Vehicle"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          value={this.state.poNum}
                          placeholder={"Enter here"}
                          label="PO Number"
                          caps="OFF"
                          onChange={this.onhandlechange("poNum")}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.agentDropdownList}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handleSelectChange}
                          selected={this.state.agentValue}
                          // update={this.state.agentValue ? 1 : 0}
                          name="Agent"
                          label="Agent"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.StatusListData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handleSelectChange}
                          selected={this.state.statusValue}
                          // update={this.state.statusValue ? 1 : 0}
                          name="Status"
                          label="Status"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Box>

                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Search"}
                    className="greenButton"
                    handleClick={this.handleSubmit}
                    type="sumbit"
                  />
                  {/* <CustomButton
                  label={"Clear"}
                  className="greenButton"
                  handleClick={this.handleClear}
                  type="sumbit"
                /> */}
                </div>
              </Grid>
            </div>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  const { supplierListMultiselect, statusList } = state.DropDownListReducer;
  const { VehicleData, agentDropdownList } = state.MasterDropDownListReducer;
  return {
    supplierListMultiselect,
    VehicleData,
    statusList,
    agentDropdownList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSupplierListMultiselect,
    getVehicleList,
    getStatusList,
    getAgentDropdownList,
  })(JuteMaterialRequestFilter)
);
