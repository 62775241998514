import {STOCK_TRANSFER_LIST,STOCK_TRANSFER_LIST_SUCCESSFULL,API_ERRORS, COMPANY_ALL_LIST, COMPANY_ALL_LIST_SUCCESSFULL, ALL_BRANCH_LIST, ALL_BRANCH_LIST_SUCCESSFULL, GET_WARE_HOUSE_LIST, GET_WARE_HOUSE_LIST_SUCCESSFULL, ADD_STOCK_TRANSFER, ADD_STOCK_TRANSFER_SUCCESSFULL} from './actionTypes';



export const getStockTransferList = (url,data,history) =>{
    return {
        type:STOCK_TRANSFER_LIST,
        payload:{url,data,history}
    }
}

export const StockTransferListSuccessfull = (data)=>{
    return {
        type:STOCK_TRANSFER_LIST_SUCCESSFULL,
        payload:data
    }
}

export const getAllCompanyList = (url,data,history) =>{
    return {
        type:COMPANY_ALL_LIST,
        payload:{url,data,history}
    }
}

export const getAllCompanyListSuccessfull = (data)=>{
    return {
        type:COMPANY_ALL_LIST_SUCCESSFULL,
        payload:data
    }
}

export const allGetBranchList = (url,history) =>{
    return {
        type:ALL_BRANCH_LIST,
        payload:{url,history}
    }
}

export const allGetBranchListSuccessfull = (data)=>{
    return {
        type:ALL_BRANCH_LIST_SUCCESSFULL,
        payload:data
    }
}

export const apiErrors = (error) => {
    return {
      type: API_ERRORS,
      payload: error,
    };
  };

  export const getwareHouseDataList = (url,data,history) =>{
    return {
        type:GET_WARE_HOUSE_LIST,
        payload:{url,data,history}
    }
}

export const getwareHouseDataListSuccessfull = (data)=>{
    return {
        type:GET_WARE_HOUSE_LIST_SUCCESSFULL,
        payload:data
    }
}

export const addStockTransfer = (url,data,history) =>{
    return {
        type:ADD_STOCK_TRANSFER,
        payload:{url,data,history}
    }
}

export const addStockTransferSuccessfull = (data)=>{
    return {
        type:ADD_STOCK_TRANSFER_SUCCESSFULL,
        payload:data
    }
}

