import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { FORGET_USER, RESET_USER_PASSWORD } from './actionTypes';

import { forgetUserSuccessful, resetUserPasswordSuccessful } from './actions';

// AUTH related methods
import { requestList } from '../../../helpers/server';

import swal from "sweetalert";
import { showSpinner, hideSpinner } from "../../../helpers/Consts";

function* forgetUser({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(requestList, url, data);
    if (response) {
      hideSpinner();
      yield put(forgetUserSuccessful({ response }));
      if (response.status) {
        if (response.data.status === true) {
          swal(response.data.message, { icon: "success" });
          history.push("/login");
        } else {
          swal(response.data.message, { icon: "danger" });
        }
      } else {
        swal(response.data.message, { icon: "danger" });
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* resetUserPassword({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(requestList, url, data);
    if (response) {
      hideSpinner();
      yield put(resetUserPasswordSuccessful({ response }));
      if (response.status) {
        if (response.data.status === true) {
          swal(response.data.message, { icon: "success" });
          history.push("/login");
        } else {
          swal(response.data.message, { icon: "danger" });
        }
      } else {
        swal(response.data.message, { icon: "danger" });
      }
    }
  } catch (error) {
    console.log(error);
  }
}


export function* watchUserForget() {
    yield takeEvery(FORGET_USER, forgetUser)
    yield takeEvery(RESET_USER_PASSWORD, resetUserPassword)
}

function* forgetSaga() {
    yield all([fork(watchUserForget)]);
}

export default forgetSaga;