import {CARRIERS_LIST,CARRIERS_LIST_SUCCESSFULL,
  API_ERRORS,
  CREATE_CARRIERS, CREATE_CARRIERS_SUCCESSFULL, UPDATE_CARRIERS_SUCCESSFULL, UPDATE_CARRIERS,
  } from './actionTypes';

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    CarriersList: [],
    CreateCarriers:[],
    UpdateCarriers:[],
  };

  const CarriersReducer = (state = initialState, action) => {
    switch (action.type) {
        case CARRIERS_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case CARRIERS_LIST_SUCCESSFULL:
            state = {
              ...state,
              CarriersList: action.payload.response,
              loading: true,
            };
            break;
            case CREATE_CARRIERS:
              state = {
                ...state,
                loading: true,
              };
              break;
            case CREATE_CARRIERS_SUCCESSFULL:
              state = {
                ...state,
                CreateCarriers: action.payload.response,
                loading: true,
              };
              break;
              case UPDATE_CARRIERS:
              state = {
                ...state,
                loading: true,
              };
              break;
            case UPDATE_CARRIERS_SUCCESSFULL:
              state = {
                ...state,
                UpdateCarriers: action.payload.response,
                loading: true,
              };
              break;
              case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default CarriersReducer;