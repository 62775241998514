import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FINANCIAL_YEAR } from "./actionTypes";
import { apiErrors, AcadamicYearsListSuccessful } from "./actions";

// AUTH related methods
import { getListurl } from "../../../helpers/Server_Helper";

function* getAcadamicYearsList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url, null);
    localStorage.setItem("acadamicYearsList", JSON.stringify(response.data.data))
    yield put(AcadamicYearsListSuccessful({ response }));
  } catch (error) {
    yield put(apiErrors(error));
  }
}

export function* watchgetCompanies() {
  yield takeEvery(FINANCIAL_YEAR, getAcadamicYearsList);
}

function* AcadamicYearsSaga() {
  yield all([fork(watchgetCompanies)]);
}

export default AcadamicYearsSaga;
