import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";

import { serverApi } from "../../../helpers/Consts";
import {
  getBranchList,
  getCustomerList,
  getBillingShippingList,
} from "../../../store/Global/DropDownApis/actions";
import { getQuotationBroker } from "../../../store/Sales/Qutation/actions";
import swal from "sweetalert";
import { getAllTransporter, getDeliveryOrderList, getSoDropDown } from "../../../store/Sales/DeliveryOrder/actions";
import { getDeliveryOrderViewByIdForJuteHessian, getJuteHessionInvoiceHeader, getSalesOrderViewByIdForJuteHessian } from "../../../store/Sales/InVoice/actions";
import { getBankAccountList } from "../../../store/Sales/DeliveryOrder/actions"
import { limitDecimals } from "../../../Utilities/helper";


const JuteHessianInvoiceFillDetails = (props) => {
  let dispatch = useDispatch();
  let history = useHistory(); 
  
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [branchListData, setbranchListData] = useState([]);
  const [brokerData, setbrokerData] = useState([]);
  const [CustomerData, setCustomerData] = useState([]);
  const [billingAddressData, setbillingAddressData] = useState([]);
  const [shippingAddressData, setshippingAddressData] = useState([]);
  const [SoDropdownVal, setSoDropdownVal] = useState([]);
  const [HeaderData, setHeaderData] = useState({});
  const [transporterData, settransporterData] = useState([]);
  const [DoDropdownVal, setDoDropdownVal] = useState([]);
  const [bankListData,setbankListData]=useState([]);
  const [backDaysAllowable, setBackDaysAllowable] = useState("");
  const [futureDaysAllowable, setFutureDaysAllowable] = useState("");
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));
                        
  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    dispatch(
      getSoDropDown(
        serverApi.SALES_ORDER_DROPDOWN_FOR_INVOICE +
          "4/" +
          localStorage.getItem("companyId")
      )
    );
    dispatch(
      getBranchList(
        serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
        history
      )
    );
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
    };
    dispatch(getCustomerList(serverApi.GET_ALL_CUSTOMERS, data, history));
    dispatch(
      getQuotationBroker(
        serverApi.GET_ALL_BROKER_LIST +
          localStorage.getItem("companyId") +
          "/SALE",
        history
      )
    );
    dispatch(
      getAllTransporter(
        serverApi.GET_ALL_TRANSPORTER_MASTER_LIST +
          localStorage.getItem("companyId")
      )
    );
    dispatch(getBankAccountList(serverApi.GET_ALL_BANK_ACCOUNT_LIST, data,history));
    const currentMonth = moment().format('MM');
    const currentDate = moment().format('DD');
    const fiscalYearStart = moment(`${localStorage.getItem("acadamicYear")}-${currentMonth}-${currentDate}`);
    const today = moment();
    let dateValue;
    if (today.isBefore(fiscalYearStart)) {
      dateValue = today.format("YYYY-MM-DD");
    } else {
      dateValue = fiscalYearStart.format("YYYY-MM-DD");
    }

    if (userDit) {
      userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              if (item.backDaysAllowable !== undefined) {
                let backDaysAllowable = JSON.parse(item.backDaysAllowable);
                let backDays = backDaysAllowable.bdaystype;
                if (Number(backDays) === 1) {
                  backDays = 7;
                } else if (Number(backDays) === 2) {
                  backDays = 14;
                } else if (Number(backDays) === 3) {
                  backDays = "";
                } else if (Number(backDays) === 4) {
                  backDays = backDaysAllowable.otherBackDateValue;
                } else {
                  backDays = backDaysAllowable.bdaystype;
                }
                let minDate = moment(dateValue).subtract(
                  Number(backDays),
                  "days"
                );
                let setMinDate = moment(minDate).format("YYYY-MM-DD");
                if(backDays!==""){
                setBackDaysAllowable(setMinDate);
                }
              }
              if (item.futureDaysAllowable !== undefined) {
                let futureDaysAllowable = JSON.parse(item.futureDaysAllowable);
                let futureDays = futureDaysAllowable.fdaystype;
                if (Number(futureDays) === 1) {
                  futureDays = 7;
                } else if (Number(futureDays) === 2) {
                  futureDays = 14;
                } else if (Number(futureDays) === 3) {
                  futureDays = "";
                } else if (Number(futureDays) === 4) {
                  futureDays = futureDaysAllowable.othervalue;
                } else {
                  futureDays = futureDaysAllowable.fdaystype;
                }
                let maxDate = moment(dateValue).add(
                  Number(futureDays),

                  "days"
                );
                let setMaxDate = moment(maxDate).format("YYYY-MM-DD");
                if(futureDays !==""){
                setFutureDaysAllowable(setMaxDate);
                }
              }
            });
            setHeaderData((prev) => ({
              ...prev,
              // invoiceDate: dateValue,
            }));
            const UpdatedRec = {
              ...JuteHassianInvoiceHeader,
              // invoiceDate: dateValue,
            };
            dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
          }
        });
      }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { branchList, customerList, billingShippingList } = useSelector(
    (state) => state.DropDownListReducer
  );

  const { BrokerData } = useSelector((state) => state.QuotationReducer);
  const { JuteHassianInvoiceHeader } = useSelector((state) => state.InvoiceReducer);


  const { TransporterList, SrDropDownData, DeliveryOrder_List,bankAccountList } = useSelector(
    (state) => state.DeliveryOrderReducer
  );

  const isEmptyObj = (myObject) => {
    const keys = Object.keys(myObject);
    return keys.length === 0;
  };


  useEffect(() => {
    if (SrDropDownData) {
      if (SrDropDownData.data) {
        let List = [];
        SrDropDownData.data.map((item) => {
          List.push({
            value: item.id,
            name: item.Value,
            label: item.Value,
          });
        });
        setSoDropdownVal(List);
      }
    }
    if (branchList) {
      if (branchList.data) {
        if (branchList.data.length !== 0) {
          let List = branchList.data.filter((item) => item.value !== 0);
          setbranchListData(List);
        }
      }
    }
    if (customerList) {
      if (customerList.data) {
        if (customerList.data.length !== 0) {
          var CusList = [];
          customerList.data.map((item) => {
            CusList.push({
              value: item.id,
              name: item.name,
              label: item.name,
            });
          });
          setCustomerData(CusList);
        }
      }
    }
    if (BrokerData) {
      if (BrokerData.data) {
        setbrokerData(BrokerData.data);
      }
    }
    if (TransporterList) {
      if (TransporterList.data) {
        if (TransporterList.data.data) {
          let TransporterLists = TransporterList.data.data.map((item) => ({
            name:
              item.transporterName +
              "^" +
              item.transporterAddress +
              "^" +
              item.stateName +
              "^" +
              item.stateCode +
              "^" +
              item.transporterGst,  
            label: item.transporterName,
            value: item.transporterId,
          }));
          settransporterData(TransporterLists);
        }
      }
    }

    if (DeliveryOrder_List) {
          if (DeliveryOrder_List.data) {
            var List = [];
            if(props.DeliveryOrderMandatory === false) {
              List.push({
                value: "",
                label: "Select",
                name: "Select",
              });
            }
    
            if (DeliveryOrder_List.data.length !== 0) {
              DeliveryOrder_List.data.map((item) => {  
                List.push({
                  value: item.deliveryOrderId,
                  label: item.deliveryOrderNumber,
                  name: item.deliveryOrderNumber,
                });
              });
              setDoDropdownVal(List);
            }else{
              setDoDropdownVal([]);
            }

            if (bankAccountList) {
              if (bankAccountList.data) {
                if (bankAccountList.data.length !== 0) {
                  var BankList = [];
                  BankList.push(bankSelect)
                  bankAccountList.data.data.map((item) => {
                    BankList.push({
                      value: item.id,
                      name:
                      item.bankName +
                      "^" +
                      item.accountNo +
                      "^" +
                      item.ifscCode +
                      "^" +
                      item.micrCode +
                      "^" +
                      item.branch,
                      label: item.bankName,
                    });
                  });
                  
                  setbankListData(BankList);
               
                }
              }
            }

          }
        }
  }, [SrDropDownData, branchList, customerList, BrokerData, TransporterList,DeliveryOrder_List,bankAccountList]);




  useEffect(() => {
    if (billingShippingList) {
      if (billingShippingList.data) {
        if (billingShippingList.data.customerLineitems) {
          if (billingShippingList.data.customerLineitems.length !== 0) {
            var list = [];
            let billingAddressList = [];
            let shippingaddressList = [];
            billingShippingList.data.customerLineitems.map((prop, i) => {
              if (prop.addressType === 2 || prop.addressType === null) {
                if (
                  (prop.customerLineId === JuteHassianInvoiceHeader.shippingTo &&
                    prop.isActive === 0) ||
                  prop.isActive !== 0
                ) {
                  billingAddressList.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
              list.push({
                value: prop.customerLineId,
                label: prop.address,
                name: prop.address,
              });
            }
          }
            if (prop.addressType === 1 || prop.addressType === null || prop.addressType === 2 ) {
              if (
                (prop.customerLineId === JuteHassianInvoiceHeader.shippingTo &&
                  prop.isActive === 0) ||
                prop.isActive !== 0
              ) {
                shippingaddressList.push({
                  value: prop.customerLineId,
                  label: prop.address,
                  name: prop.address,
                });
                list.push({
                  value: prop.customerLineId,
                  label: prop.address,
                  name: prop.address,
                });
              }
            }

              if (i == 0) {
                if (!isEmptyObj(HeaderData)) {
                  if (HeaderData.customerId !== "") {
                    setHeaderData((prev) => ({
                      ...prev,
                      ContactNumber: billingShippingList.data.contactNo,
                      ContactPerson: billingShippingList.data.contactPerson,
                      billingTo: prop.customerLineId,
                      billingToAddress: prop.address,
                      billingToGST: prop.gstNo,
                      billingContactNo: prop.contactNo,
                      billingContactPerson: prop.contactPerson,
                      billingToEmail:
                        billingShippingList.data.customerHdr.emailId,
                      billingState: prop.state,

                      shippingTo: prop.customerLineId,
                      shippingToAddress: prop.address,
                      shippingToGST: prop.gstNo,
                      shippingContactNo: prop.contactNo,
                      shippingContactPerson: prop.contactPerson,
                      shippingToEmail:
                        billingShippingList.data.customerHdr.emailId,
                      shippingState: prop.state,
                      // taxType: 2,
                    }));

                    const UpdatedRec = {
                      ...JuteHassianInvoiceHeader,
                      ContactNumber: billingShippingList.data.contactNo,
                      ContactPerson: billingShippingList.data.contactPerson,

                      billingTo: prop.customerLineId,
                      billingToAddress: prop.address,
                      billingToGST: prop.gstNo,
                      billingContactNo: prop.contactNo,
                      billingContactPerson: prop.contactPerson,
                      billingToEmail:
                        billingShippingList.data.customerHdr.emailId,
                      billingState: prop.state,

                      shippingTo: prop.customerLineId,
                      shippingToAddress: prop.address,
                      shippingToGST: prop.gstNo,
                      shippingContactNo: prop.contactNo,
                      shippingContactPerson: prop.contactPerson,
                      shippingToEmail:
                        billingShippingList.data.customerHdr.emailId,
                      shippingState: prop.state,

                      // taxType: 2,
                    };
                    dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
                  }
                }
              }
            });
            if (JuteHassianInvoiceHeader.customerId !== "") {
              setbillingAddressData(billingAddressList);
              setshippingAddressData(shippingaddressList);
            }
          }
        }
      }
    }
  }, [billingShippingList]);

  useEffect(() => {
    setHeaderData(JuteHassianInvoiceHeader);
  }, [JuteHassianInvoiceHeader]);


  const handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name == "deliveryTerms") {
      setHeaderData((prev) => ({
        ...prev,
        deliveryTerms: selectedValue,
      }));
      const UpdatedRec = {
        ...HeaderData,
        deliveryTerms: selectedValue,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }
    if (name == "salesOrderId") {
      setHeaderData((prev) => ({
        ...prev,
        salesOrderId: selectedValue,
        salesOrderNumber: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        salesOrderId: selectedValue,
        salesOrderNumber: selectedName,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
      const data = {
        fromDate: "",
        toDate: "",
        cusId: "",
        branchId: null,
        status: "3",
        companyId: localStorage.getItem("companyId"),
        acYear: localStorage.getItem("acadamicYear"),
        deliveryOrderNo: "",
        type: 4,
        startIndex: 0,
        lastIndex: 0,
        salesOrderNo: selectedName,
      };
      dispatch(
        getDeliveryOrderList(
          serverApi.DELIVERY_ORDER_GET_API,
          data,
          props.history
        )
      );

      dispatch(
        getSalesOrderViewByIdForJuteHessian(
          serverApi.SALES_ORDER_VIEW_BYID +
            "4/" +
            selectedValue +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).userId,
          history
        )
      );
    }
    if (name == "deliveryOrderId") {
      if(selectedValue === "") {
        setHeaderData((prev) => ({
          ...prev,
          deliveryOrderId: "",
          deliveryOrderNo: "N/A",
        }));

        const UpdatedRec = {
          ...HeaderData,
          deliveryOrderId: "",
          deliveryOrderNo: "N/A",
        };
        dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
        // dispatch(
        //   getSalesOrderViewByIdForJuteHessian(
        //     serverApi.SALES_ORDER_VIEW_BYID +
        //       "4/" +
        //       HeaderData.salesOrderId +
        //       "/" +
        //       JSON.parse(localStorage.getItem("authUser")).userId,
        //     history
        //   )
        // );
      }

      setHeaderData((prev) => ({
        ...prev,
        deliveryOrderId: selectedValue,
        deliveryOrderNo: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        deliveryOrderId: selectedValue,
        deliveryOrderNo: selectedName,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));

      dispatch(
        getDeliveryOrderViewByIdForJuteHessian(
          serverApi.DELIVERY_ORDER_VIEW_BY_ID +
            "4/" +
            selectedValue +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).userId,
          history
        )
      );
    }
    if (name == "invoiceType") {
      setHeaderData((prev) => ({
        ...prev,
        invoiceType: selectedValue,
        invoiceTypeDesc: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        invoiceType: selectedValue,
        invoiceTypeDesc: selectedName,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }
    if (name == "branch") {
      setHeaderData((prev) => ({
        ...prev,
        branch: selectedValue,
        branchName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        branch: selectedValue,
        branchName: selectedName,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }

    if (name == "brokerId") {
      if(selectedValue === 0) {
        setHeaderData((prev) => ({
          ...prev,
          brokerId: "",
          brokerCommisionPercent: "",
          brokerName: ""
        }));
        const UpdatedRec = {
          ...HeaderData,
          brokerId: "",
          brokerCommisionPercent: "",
          brokerName: ""
        };
        dispatch(getJuteHessionInvoiceHeader(UpdatedRec));

        return
      }
      setHeaderData((prev) => ({
        ...prev,
        brokerId: selectedValue,
        brokerCommisionPercent: "",
        brokerName: selectedName
      }));
      const UpdatedRec = {
        ...HeaderData,
        brokerId: selectedValue,
        brokerCommisionPercent: "",
        brokerName: selectedName
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }


    if (name == "customerId") {
      setHeaderData((prev) => ({
        ...prev,
        customerId: selectedValue,
        customerName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        customerId: selectedValue,
        customerName: selectedName,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));

      dispatch(
        getBillingShippingList(
          serverApi.GET_ALL_BILLING_SHIPPING +
            selectedValue +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).cipher+
            "?isForSalesModule=1",
          history
        )
      );
    }
    if (name == "billingFrom") {
      setHeaderData((prev) => ({
        ...prev,
        billingFrom: selectedValue,
        billingFromAddress: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        billingFrom: selectedValue,
        billingFromAddress: selectedName,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }
    if (name == "shippingFrom") {
      setHeaderData((prev) => ({
        ...prev,
        shippingFrom: selectedValue,
        shippingFromAddress: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        shippingFrom: selectedValue,
        shippingFromAddress: selectedName,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }
    if (name == "billingTo") {
      setHeaderData((prev) => ({
        ...prev,
        billingTo: selectedValue,
        billingAddress: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        billingTo: selectedValue,
        billingAddress: selectedName,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }
    if (name == "shippingTo") {
      setHeaderData((prev) => ({
        ...prev,
        shippingTo: selectedValue,
        shippingAddress: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        shippingTo: selectedValue,
        shippingAddress: selectedName,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }
    if (name == "taxType") {
      setHeaderData((prev) => ({
        ...prev,
        taxType: selectedValue,
        taxTypeDesc: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        taxType: selectedValue,
        taxTypeDesc: selectedName,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }
    if (name === "bankId") {
      var Data = selectedName.split("^", 45);
      setHeaderData((prev) => ({
        ...prev,
        bankId: selectedValue,
        bankName: selectedName,
        accountNo:selectedValue ===0?"": Data[1],
        ifscCode:selectedValue ===0?"": Data[2],
        micrCode:selectedValue ===0?"": Data[3],
        bankBranch:selectedValue ===0?"": Data[4],
      }));
      const UpdatedRec = {
        ...HeaderData,
        bankId: selectedValue,
        bankName: selectedName,
        accountNo: Data[1],
        ifscCode:Data[2],
        micrCode: Data[3],
        bankBranch: Data[4],
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    } 
    if (name == "gstInvoiceType") {
      if(selectedValue === "") {
        setHeaderData((prev) => ({
          ...prev,
          gstInvoiceType: "",
        }));
        const UpdatedRec = {
          ...HeaderData,
          gstInvoiceType: "",
        };
        dispatch(getJuteHessionInvoiceHeader(UpdatedRec));

        return;
      }
      setHeaderData((prev) => ({
        ...prev,
        gstInvoiceType: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        gstInvoiceType: selectedName,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }
    if (name == "transporterId") {
      setHeaderData((prev) => ({
        ...prev,
        transporterName: selectedName,
        transporterId: selectedValue,
      }));
      const UpdatedRec = {
        ...HeaderData,
        transporterName: selectedName,
        transporterId: selectedValue,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }
  };

  const onhandlechange = (key) => (event) => {
    if (key === "quotationNo") {
      setHeaderData((prev) => ({
        ...prev,
        quotationNo: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        quotationNo: event,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }
    // if (key === "vehicleNo") {
    //   setHeaderData((prev) => ({
    //     ...prev,
    //     vehicleNo: event,
    //   }));
    //   const UpdatedRec = {
    //     ...HeaderData,
    //     vehicleNo: event,
    //   };
    //   dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    // }
    if (key === "poNumber") {
      setHeaderData((prev) => ({
        ...prev,
        poNumber: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        poNumber: event,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }
    if (key === "customerReferenceNumber") {
      setHeaderData((prev) => ({
        ...prev,
        customerReferenceNumber: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        customerReferenceNumber: event,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }
    if (key === "billingState") {
      setHeaderData((prev) => ({
        ...prev,
        billingState: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        billingState: event,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }
    if (key === "shippingState") {
      setHeaderData((prev) => ({
        ...prev,
        shippingState: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        shippingState: event,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }
    if (key === "brokerCommisionPercent") {
      let Val = event.match(/^(\d*\.{0,1}\d{0,2}$)/);
      var Event = "";
      if (Val) {
        if (event > 100) {
          swal("Cannot enter more than 100%");
          Event = "";
        } else {
          Event = event;
        }
      } else {
        swal("Cannot enter more than two decimals");
        Event = "";
      }
      setHeaderData((prev) => ({
        ...prev,
        brokerCommisionPercent: Event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        brokerCommisionPercent: Event,
      };
        dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }

  };

  const handleSelect_Date = (e, name) => {
    if (name === "invoiceDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      if (Date < HeaderData.deliveryDate) {
        swal("Invoice Date Shouldn't be less than the Delivery Order Date");
        setHeaderData((prev) => ({
          ...prev,
          invoiceDate: null,
        }));
        const UpdatedRec = {
          ...HeaderData,
          invoiceDate: null,
        };
        dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
      } else {
        setHeaderData((prev) => ({
          ...prev,
          invoiceDate: Date,
        }));
        const UpdatedRec = {
          ...HeaderData,
          invoiceDate: Date,
        };
        dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
      }
    }
    if (name === "salesOrderDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        salesOrderDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        salesOrderDate: Date,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }
    if (name === "pcsoDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        pcsoDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        pcsoDate: Date,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }

    if (name === "deliveryDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        deliveryDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        deliveryDate: Date,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }
    if (name === "quotationDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        quotationDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        quotationDate: Date,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }
    if (name === "challanDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        challanDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        challanDate: Date,
      };
      dispatch(getJuteHessionInvoiceHeader(UpdatedRec));
    }
    
  };

  return (
    <>
      <div>
        <div className="consoleFormContainer">
          <h5> Jute Hessian Fill Details</h5>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                    {props.locationState.state == undefined ? (
                      <>
                      <DynamicSelect
                        update={HeaderData.salesOrderId ? 1 : 0}
                        arrayOfData={SoDropdownVal}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.salesOrderId}
                        name="salesOrderId"
                        label="Sales Order Number"
                        required
                      />
                        </>
                      ) : (
                        <TextFieldNormal
                          label="Sales Order Number"
                          value={HeaderData.salesOrderNumber}
                          onChange={onhandlechange("salesOrderNumber")}
                          disabled={true}
                        />
                      )}
                    
                    </div>
                  </Box>
                  
               
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                      {props.locationState.state == undefined ? (
                         <DynamicSelect
                          update={HeaderData.deliveryOrderId ? 1 : 0}
                          arrayOfData={DoDropdownVal}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={handlePropSelectChange}
                          isDropdownOpen={isDropdownOpen}
                          handleMenuOpen={handleMenuOpen}
                          selected={HeaderData.deliveryOrderId}
                          label="Delivery Order Number"
                          name="deliveryOrderId"
                          required
                        />  
                        ) : (
                          <TextFieldNormal
                            label="Delivery Order Number"
                            value={HeaderData.deliveryOrderNo}
                            onChange={onhandlechange("deliveryOrderNo")}
                            disabled={true}
                          />
                        )}
                      </div>
                    </Box>

                  <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        
                        <div>
                          <div className="TextFieldBlock">
                            <label>Sales Order Date</label>
                          </div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              disabled={ true }
                              name="salesOrderDate"
                              inputFormat="dd-MM-yyyy"
                              id="salesOrderDate"
                              value={HeaderData.salesOrderDate}
                              fullWidth
                              onChange={(e) =>
                                handleSelect_Date(e, "salesOrderDate")
                              }
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="consoleDatePicker"
                                  style={{
                                    backgroundColor:"#ccd6db",  
                                  }}
                                >
                                  <input
                                    ref={inputRef}
                                    {...inputProps}
                                    style={{
                                      backgroundColor:"#ccd6db",
                                       
                                    }}
                                  />
                                  {InputProps?.endAdornment}
                                </Box>
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </Box>
                  

                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <div>
                          <div className="TextFieldBlock">
                            <label>
                              Delivery Order Date 
                            
                            </label>
                          </div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            
                            <DesktopDatePicker
                              disabled={ true }
                              name="deliveryDate"
                              inputFormat="dd-MM-yyyy"
                              id="deliveryDate"
                              value={HeaderData.deliveryDate}
                              fullWidth
                              onChange={(e) =>
                                handleSelect_Date(e, "deliveryDate")
                              }
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="consoleDatePicker"
                                  style={{
                                    backgroundColor: "#ccd6db"
                                  }}
                                >
                                  <input
                                    ref={inputRef}
                                    {...inputProps}
                                    style={{
                                      backgroundColor: "#ccd6db"                                       
                                    }}
                                  />
                                  {InputProps?.endAdornment}
                                </Box>
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </Box>

                    <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                    {props.locationState.state == undefined ? (
                      <DynamicSelect
                        update={HeaderData.branch ? 1 : 0}
                        arrayOfData={branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.branch}
                        label="Branch"
                        name="branch"
                        required
                        isDisabled = {true}
                      />) : (
                        <Box gridColumn="span 3">
                        <TextFieldNormal
                          label="Branch"
                          value={HeaderData.branchName}
                          onChange={onhandlechange("branchName")}
                          disabled={true}
                        />
                      </Box>
                      )
                       }
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.brokerId ? 1 : 0}
                        arrayOfData={brokerData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.brokerId}
                        label="Broker"
                        name="brokerId"
                        ErrorOutlineIcon={true}
                        toolTipTitle={"Broker or Consignee is mandetory Select At least one and Proceed"}
                        isDisabled={true}
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <DynamicSelect
                      update={HeaderData.customerId ? 1 : 0}
                      arrayOfData={CustomerData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      selected={HeaderData.customerId}
                      name="customerId"
                      label="Customer/Consignee"
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      ErrorOutlineIcon={true}
                      toolTipTitle={
                        "Broker or Consignee is mandetory Select At least one and Proceed"
                      }
                      isDisabled={true}
                    />
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Invoice Date<span className="starcolor">*</span>
                          </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="invoiceDate"
                            inputFormat="dd-MM-yyyy"
                            id="invoiceDate"
                            minDate={
                              backDaysAllowable !== ""
                                ? backDaysAllowable
                                :HeaderData.invoiceDate
                            }
                            maxDate={
                              futureDaysAllowable !== ""
                                ? futureDaysAllowable
                                : HeaderData.invoiceDate
                            }
                            value={HeaderData.invoiceDate}
                            fullWidth
                            onChange={(e) =>
                              handleSelect_Date(e, "invoiceDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>

          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          update={HeaderData.billingFrom ? 1 : 0}
                          arrayOfData={branchListData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={handlePropSelectChange}
                          isDropdownOpen={isDropdownOpen}
                          handleMenuOpen={handleMenuOpen}
                          selected={HeaderData.billingFrom}
                          label="Bill From"
                          name="billingFrom"
                          required
                        />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          update={HeaderData.shippingFrom ? 1 : 0}
                          arrayOfData={branchListData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={handlePropSelectChange}
                          isDropdownOpen={isDropdownOpen}
                          handleMenuOpen={handleMenuOpen}
                          selected={HeaderData.shippingFrom}
                          label="Ship From"
                          name="shippingFrom"
                          required
                        />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.billingTo ? 1 : 0}
                        arrayOfData={billingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.billingTo}
                        label="Billing To"
                        name="billingTo"
                        required
                        isDisabled={true}
                       
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.shippingTo ? 1 : 0}
                        arrayOfData={shippingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.shippingTo}
                        label="Shipping To"
                        name="shippingTo"
                        required
                        isDisabled={true}

                       
                      />
                    </div>
                  </Box>

                   <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Billing State"
                      value={HeaderData.billingState}
                      onChange={onhandlechange("billingState")}
                      disabled={true}
                    />
                  </Box>

                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Shipping State"
                      value={HeaderData.shippingState}
                      onChange={onhandlechange("shippingState")}
                      disabled={true}
                    />
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.taxType ? 1 : 0}
                        arrayOfData={taxTypeData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.taxType}
                        label="Tax type"
                        name="taxType"
                        required
                        isDisabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelectNew
                      update={HeaderData.deliveryTerms ? 1 : 0}
                      arrayOfData={DeliveryTermsData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.deliveryTerms}
                      name="deliveryTerms"
                      label="Delivery Terms"
                      required={true}
                      isDisabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Broker %"
                      value={limitDecimals(HeaderData.brokerCommisionPercent)}
                      onChange={onhandlechange("brokerCommisionPercent")}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                    {props.locationState.state == undefined ? (
                      <DynamicSelect
                        update={HeaderData.bankId ? 1 : 0}
                        arrayOfData={bankListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.bankId}
                        name="bankId"
                        label="Bank Name"
                        required
                        isDisabled={true}

                     />
                     ) : (
                      <TextFieldNormal
                        label="Bank Name"
                        value={HeaderData.bankName}
                        onChange={onhandlechange("bankName")}
                        disabled={true}
                      />
                    )}
                    </div>
                  </Box>
                  {/* <Box gridColumn="span 3">
                  {props.locationState.state == undefined ? (
                    <>
                    <TextFieldNormal
                      label="Vehicle No"
                      value={HeaderData.vehicleNo}
                      onChange={onhandlechange("vehicleNo")}
                      caps="ON"
                      // disabled={true}
                     
                    />
                     </> ): (
                      <>
                      <TextFieldNormal
                      label="Vehicle No"
                      value={HeaderData.vehicleNo}
                      onChange={onhandlechange("vehicleNo")}
                      caps="ON"
                      // disabled={true}
                     
                   />
                    </>
                    )}
                    </Box> */}
                 
                  <Box gridColumn="span 3">
                    <DynamicSelect
                      update={HeaderData.transporterId ? 1 : 0}
                      arrayOfData={transporterData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.transporterId}
                      label="Transporter Name"
                      name="transporterId"
                      isDisabled={true}
                    />
                  </Box>

                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Transporter Address"
                      value={HeaderData.transporterAddress}
                      onChange={onhandlechange("transporterAddress")}
                      type="text"
                      disabled={true}
                    />
                  </Box>

                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Transporter State Name"
                      value={HeaderData.transporterStateName}
                      onChange={onhandlechange("transporterStateName")}
                      type="text"
                      disabled={true}
                    />
                  </Box>
                  
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Transporter State Code"
                      value={HeaderData.transporterStateCode}
                      onChange={onhandlechange("transporterStateCode")}
                      type="text"
                      disabled={true}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div style={{ height: "15px" }}></div>

        

        </div>
      </div>
    </>
  );
};
const taxTypeData = [
  {
    value: 1,
    name: "IGST",
    label: "IGST",
  },
  {
    value: 2,
    name: "CGST & SGST",
    label: "CGST & SGST",
  },
];
const DeliveryTermsData = [
  {
    value: 1,
    label: "Ex-Works",
    name: "Ex-Works",
  },
  {
    value: 2,
    label: "Delivery to Destination",
    name: "Delivery to Destination",
  },
];
const bankSelect = {
  value: "",
  name: "Select",
  label: "Select",
}
export default JuteHessianInvoiceFillDetails;
