import React, { Component } from "react";
import Select from "react-select";
import FormControl from "@mui/material/FormControl";
import { IconButton } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";



class DynamicSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedlable: "",
      selectedValue: "",
      selectedName: "",
      selectedItemCode: "",
      selectedId: "",
      isDropdownOpen: false,
    };
  }

  handleChange = (event) => {
    let selectedValue = event.value;
    let selectedName = event.name;
    let selectedlable = event.label;
    let selectedItemCode = event.variant;
    let selectedId = event.id;

    this.setState({
      selectedValue: selectedValue,
      selectedName: selectedName,
      selectedlable: selectedlable,
      selectedItemCode: selectedItemCode,
      selectedId: selectedId
    })
    this.props.onSelectChange(selectedValue, selectedName, this.props.name, this.props.row, selectedlable, this.props.index, selectedItemCode, selectedId);
  };
  handleMenuOpen = () => {
    // if(this.props.handleMenuOpen){
    this.props.handleMenuOpen()
    // }
  }
  componentWillReceiveProps(props) {
    if (props.arrayOfData) {
      props.arrayOfData.forEach((row) => {
        if (props.selected == row.value) {
          // selectedlable = row.label;      
          this.setState({
            selectedlable: row.label,
            selectedValue: props.selected,
            selectedName: row.label,
            selectedItemCode: row.variant,
            selectedId: row.id,
          })
        }
      });
    }
  }

  render() {
    const { isDropdownOpen } = this.state;

    // if(this.props.update === 1) {
    //   if(this.props.arrayOfData){
    //     this.props.arrayOfData.map((row) => {
    //       if(this.props.selected === row.value) {
    //         this.state.selectedValue = this.props.selected
    //         this.state.selectedlable = row.label
    //         this.state.selectedName = row.label
    //         this.state.selectedItemCode =row.variant
    //       }
    //     })
    //   }
    // }else {

    if (this.props.arrayOfData) {
      this.props.arrayOfData.map((row) => {
        if (this.props.selected === row.value) {
          this.state.selectedValue = this.props.selected
          this.state.selectedlable = row.label
          this.state.selectedName = row.label
          this.state.selectedItemCode = row.variant
          this.state.selectedId = row.id
        }
      })
    }

    // }

    return (
      <FormControl sx={{ width: "100%" }} className="customDropdown">
        <div style={{display:"flex",justifyContent:"space-between"}}>
          <span>
            <label htmlFor="app-input-field">{this.props.label}  {isDropdownOpen}</label>
            {this.props.required && <div className="mandatoryField">*</div>}
            </span>
          <div>
            {this.props.ErrorOutlineIcon &&
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title={this.props.toolTipTitle}
                placement={"top-end"}
                arrow
              >
                <IconButton
                style={{padding:"0px"}}
                >
                  <ErrorOutlineIcon style={{ color: "rgb(144 130 232)", marginRight: "5px" ,width:"15px",height:"20px"}} />
                </IconButton>
              </Tooltip>
            }
          </div>
        </div>
        <Select
          classNamePrefix="react-select"
          options={this.props.arrayOfData}
          menuPosition="fixed"
          menuPlacement="auto"
          closeMenuOnScroll={() => this.props.isDropdownOpen}
          defaultValue={this.props.selected ? { value: this.props.selected, label: this.state.selectedlable, variant: this.state.selectedItemCode, id: this.state.selectedId } : ""}
          value={this.props.selected ? { value: this.state.selectedValue, label: this.state.selectedName, variant: this.state.selectedItemCode, id: this.state.selectedId } : ""}
          placeholder="Select"
          onChange={this.handleChange}
          onMenuOpen={this.handleMenuOpen}
          isDisabled={this.props.isDisabled ? true : this.props.update === 1 ? this.props.isDisabled : false}
        />
      </FormControl>
    );
  }
}
export default DynamicSelect;
