import React, { useState, useEffect } from "react";
import moment from "moment";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import CustomButton from "../../../components/Buttons/Buttons";
import { serverApi, getTenantId } from "../../../helpers/Consts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty, limitDecimals } from "../../../Utilities/helper";
const JutePOPreview = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [locationState] = useState(useLocation());
  const [rowCount, setRowCount] = useState(1);
  const [show, setShow] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [companyId, setCompanyId] = useState(localStorage.getItem("companyId"));
  const [companyName, setCompanyName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [comName, setComName] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [filterText, setFilterText] = useState("");
  const [tooltip, setTooltip] = useState("item description....");
  const [LineItems, setLineItems] = useState([]);
  const [HeaderData, setHeaderData] = useState({});

  const { JutePoLineItems, JutePoHeader } = useSelector(
    (state) => state.JutePurchaseReducer
  );
  useEffect(() => {
    setLineItems(JutePoLineItems);
    setHeaderData(JutePoHeader);
  }, [JutePoLineItems, JutePoHeader]);
  const convertNumberToWords = (amount) => {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();

    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = "";
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  };

  const withDecimal = (n) => {
    if (n !== undefined) {
      var nums = n.split(".");
      var whole = convertNumberToWords(nums[0]);

      if (nums.length === 2) {
        let numberAfterDecimal = Number(nums[1]).toFixed(2);
        var decimal = convertNumberToWords(numberAfterDecimal);
        if (parseInt(nums[1]) > 0) {
          return whole + " Rupees and " + decimal + " Paise only";
        }
      }

      return whole + " Rupees only";
    }
  };

  const handlePdf = (printType) => {
    if (locationState.state !== undefined) {
      if (locationState.state.rowData !== undefined) {
        fetch(serverApi.DOWNLOAD_JUTE_PO + "?poNum=" + HeaderData.id, {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            "X-TenantId": getTenantId(window.location.hostname),
            Authorization:
              localStorage.getItem("token_type") +
              localStorage.getItem("access_token"),
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "JutePurchaseOrder" + ".pdf");
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          });
      }
    }
  };

  const handleEdit = () => {
    props.handleCurrentStep(2);
  };

  useEffect(() => {
    const companyData = JSON.parse(localStorage.getItem("authUser"));
    const companyId = localStorage.getItem("companyId");
    const selectedCompany = companyData.companyDetails.find(
      (p) => companyId === p.value.toString()
    );

    if (selectedCompany) {
      setCompanyName(selectedCompany.label);
      setAddress1(selectedCompany.address1);
      setAddress2(selectedCompany.address2);
      setComName(selectedCompany.name);
      setCompanyLogo(selectedCompany.companyLogo);
    }
  }, []);

  const date = new Date();
  const newDate = moment(date).format("DD-MM-YYYY");
  const time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  const filterData = JutePoLineItems.filter(
    (item) => item.itemId !== "" && item.isActive !== 0
  );

  const newPoDate = moment(HeaderData.poDate, "YYYY-MM-DD").format(
    "DD-MM-YYYY"
  );

  return (
    <>
      <div className="previewBlock">
        <div
          style={{ display: "flex", justifyContent: "end", padding: "10px" }}
        >
          {locationState.state !== undefined ?
          (<CustomButton
            label="Print"
            handleClick={handlePdf}
            type="button"
            className="lightBlueButton"
            muIcon={<PrintIcon />}
          />) :("") 
          }
          {HeaderData.EditButton ? (
            <CustomButton
              label="Edit"
              className="lightBlueButton"
              muIcon={<EditIcon />}
              handleClick={handleEdit}
              type="button"
            />
          ) : (
            ""
          )}
        </div>

        <table
          cellpadding="0"
          cellspacing="0"
          width="100%"
          className="previewTable"
        >
          <tbody>
            <tr>
              <td
                colspan="3"
                style={{ borderRight: "none", borderBottom: "none" }}
              >
                <img src={companyLogo} width="100%" alt="" />
              </td>
              <td
                colspan="10"
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  textAlign: "right",
                }}
              >
                <h2>{companyName}</h2>
                {address1}
                <br />
                {address2}
                <br />
              </td>
            </tr>
            <tr>
              <td
                colspan="20"
                style={{ textAlign: "center", borderTop: "none" }}
              >
                <h2>JUTE PURCHASE ORDER</h2>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <b>PURCHASE ORDER NO:</b>
              </td>
              <td colspan="3" 
              className={isEmpty(HeaderData.id) ? "blur-text" : ""}
              >
                <span>
                  {!isEmpty(HeaderData.id)
                    ? HeaderData.id
                    : "Will be generated after creation"}
                </span>
              </td>
              <td colspan="2">
                <b> PO DATE: </b>
              </td>
              <td colspan="6">
                <span> {newPoDate}</span>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <b>SUPPLIER:</b>
              </td>
              <td colspan="3">
                <span>{HeaderData.suppName}</span>
              </td>
              <td colspan="2">
                <b>EXP DATE:</b>
              </td>
              <td colspan="6">
                <span>{HeaderData.exceptedDate}</span>
              </td>
            </tr>

            <tr>
              <td colspan="2">
                <b>SELLER/BROKER:</b>
              </td>
              <td colspan="3">
                <span>{HeaderData.brokerName}</span>
              </td>
              <td colspan="2">
                <b>CREDIT TERM: </b>
              </td>
              <td colspan="6">
                <span>{HeaderData.creditTerm}</span>
              </td>
            </tr>

            <tr>
              <td colspan="2">
                <b>NO. OF LORRIES:</b>
              </td>
              <td colspan="3">
                <span>{HeaderData.vehicleQuantity}</span>
              </td>
              <td colspan="2">
                <b>VEHICLE TYPE: </b>
              </td>
              <td colspan="6">
                <span>{HeaderData.vehicleTypeName}</span>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <b>MUKAM:</b>
              </td>
              <td colspan="10">
                <span>{HeaderData.mukamName}</span>
              </td>
              
            </tr>

            <tr align="center" valign="top">
              <td align="center" width={"8%"}>
                <b>Crop year </b>
              </td>
              <td align="center" width={"10%"}>
                <b>Item Code</b>
              </td>
              <td align="center" width={"15%"}>
                <b>Item Description</b>
              </td>
              <td align="center" width={"10%"}>
                <b>Quality</b>
              </td>
              <td align="center" width={"10%"}>
                <b>HSN/SAC Code</b>
              </td>
              <td align="center" width={"5%"}>
                <b>Bales</b>
              </td>
              <td align="center" width={"7%"}>
                <b>Weight</b>
              </td>
              <td align="center" width={"5%"}>
                <b>UOM</b>
              </td>
              <td align="center" width={"10%"}>
                <b>Moisture Allowed(%)</b>
              </td>
              <td align="center" width={"10%"}>
                <b>Rate Per QNT(INR)</b>
              </td>
              <td align="center" width={"10%"}>
                <b>Amount</b>
              </td>
            </tr>

            {filterData.map((data, index) => (
              <tr key={index}>
                <td align="center">{data.cropYear ? `${data.cropYear} - ${data.cropYear + 1}` : ''}</td>
                <td align="center">{data.itemCode}</td>
                <td align="center">{data.itemDesc}</td>
                <td align="center"> {data.qualityCodeName}</td>
                <td align="center">{data.hsnCode}</td>
                <td align="center">{data.quantity}</td>
                <td align="center">{data.actualQuantity}</td>
                <td align="center">{data.uom ? data.uom  : data.unitId}</td>

                <td align="center">
                  {" "}
                  {!isEmpty(data.allowableMoisturePercentage)
                    ? data.allowableMoisturePercentage
                    : "0.00"}{" "}
                </td>
                <td align="center"> {data.rate}</td>
                <td align="center"> {data.amount}</td>
              </tr>
            ))}

            <tr>
              <td colSpan={2}>
                <b>Weight Total</b>
              </td>
              <td colSpan={10}>
                <b>{limitDecimals(HeaderData.weightTotal, 2)}</b>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>Freight Charges</b>
              </td>
              <td colSpan={10} align="left">
                <b>
                  {HeaderData.frieghtCharge
                    ? limitDecimals(HeaderData.frieghtCharge, 2)
                    : "0.00"}
                </b>
              </td>
            </tr>           

            <tr>
              <td colSpan={2}>
                <b>Net Total</b>
              </td>
              <td colSpan={10}>
                <b>{limitDecimals(HeaderData.netTotal, 2)}</b>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>Total Value (In Words)</b>
              </td>
              <td colSpan={10}>
                <b>{withDecimal(limitDecimals(HeaderData.netTotal, 2))}</b>
              </td>
            </tr>
            <tr></tr>
            <tr>
              <td
                colspan="13"
                style={{ borderTop: "none", borderBottom: "none" }}
              >
                <b>
                  {locationState.state !== undefined ? (
                    <span>Status :{HeaderData.statusName}</span>
                  ) : null}
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default JutePOPreview;
