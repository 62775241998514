import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Grid, Box } from '@mui/material'
import DynamicSelect from '../../../components/Dropdown/DynamicSelect'
import CustomButton from '../../../components/Buttons/Buttons'
import Link from '@mui/material/Link'
import AutoCompleteSelect from '../../../components/Dropdown/AutoCompleteSelect'
class CreateSupplierItems extends Component {
  constructor(props) {
    super(props)
    this.state = {
      supplier_name: '',
      item_Group: '',
    }
  }

  handlePropSelectChange = (selectedValue, selectedName, name) => {}

  render() {
    return (
      <div className="supplierMasterContainer">
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={client}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.state.supplier_name}
                        name="supplier_name"
                        label="Supplier Name"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <AutoCompleteSelect
                        label="Item Group"
                        placeholder="select"
                        options={client}
                        checkbox={true}
                        
                        
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={'Cancel'}
              className="greenBorderButton"
              handleClick={this.onClickBefore}
            />
          </Link>
          <CustomButton
            label={'Save'}
            className="greenButton"
            handleClick={this.onClickNext}
            type="sumbit"
          />
        </div>
      </div>
    )
  }
}

const arrayOfIndent = [
  {
    value: '1',
    label: 'Capital',
    name: 'Capital',
  },
  {
    value: '2',
    label: 'General',
    name: 'General',
  },
  {
    value: '3',
    label: 'Maintainence',
    name: 'Maintainence',
  },
  {
    value: '4',
    label: 'Open',
    name: 'Open',
  },
  {
    value: '5',
    label: 'Production',
    name: 'Production',
  },
]
const Project = [
  {
    value: 1,
    label: 'Project1',
    name: 'Project 1',
  },
  {
    value: 2,
    label: 'Project2',
    name: 'Project 2',
  },
  {
    value: 3,
    label: 'Project3',
    name: 'Project 3',
  },
]

const phase = [
  {
    value: '1',
    label: 'Ho',
    name: 'Ho',
  },
  {
    value: '2',
    label: 'Factory',
    name: 'Factory',
  },
  {
    value: '3',
    label: 'BNG',
    name: 'BNG',
  },
  {
    value: '4',
    label: 'Kolkatta',
    name: 'Kolkatta',
  },
  {
    value: '5',
    label: 'Test',
    name: 'Test',
  },
]

const client = [
  {
    value: '1',
    title: 'Center',
    name: 'Center',
  },
  {
    value: '2',
    title: 'TechStar',
    name: 'TechStar',
  },
  {
    value: '3',
    title: 'Mahindra Logistics',
    name: 'Mahindra Logistics',
  },
  {
    value: '4',
    title: 'Smartworks',
    name: 'Smartworks',
  },
]

const CategoryList = [
  {
    value: '1',
    label: 'OPEN',
    name: 'OPEN',
  },
  {
    value: '2',
    label: 'OPEX',
    name: 'OPEX',
  },
  {
    value: '3',
    label: 'CAPEX',
    name: 'CAPEX',
  },
]

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  }
}
export default connect(mapStatetoProps, {})(withRouter(CreateSupplierItems))
