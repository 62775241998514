import React, { useState, useEffect } from "react";
import { Box, Grid, Tooltip } from "@mui/material";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import InputAdornment from "../../../components/InputAdorment/InputAdorment";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextArea from "../../../components/TextField/TextArea";
import cons, { serverApi, serverConfig } from "../../../helpers/Consts";
import moment from "moment";
import {
  getBranchList,
  getMukamList,
} from "../../../store/Global/DropDownApis/actions";
import { getSupplierByMukamIdList } from "../../../store/MasterDropdownApis/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  getJuteGateEntryHeader,
  getAllApprovedPO,
  jutePoViewByIdForGateEntry,
} from "../../../store/Jute/JuteGateEntry/actions.js";
import {
  isEmpty,
  isEmptyWithZero,
  limitDecimals,
} from "../../../Utilities/helper.js";
import swal from "sweetalert";
const JuteGateEntryFillDetails = (props) => {
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [ProjectData, setProjectData] = useState([]);
  const [ApprovedPoData, setApprovedPoData] = useState([]);
  const [MukamData, setMukamData] = useState([]);
  const [SupplierByMukamListData, setSupplierByMukamList] = useState([]);
  const [VehicleTypeList, setVehicleTypeList] = useState([]);
  const [BrokerList, setBrokerList] = useState([]);
  const [HeaderData, setHeaderData] = useState({});
  const [LineItems, setLineItems] = useState([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const dispatch = useDispatch();
  let history = useHistory();
  const [locationState] = useState(useLocation());

  const { JuteGateEntryHeader, JuteGateEntrylineItems, GetAllApprovedPo } =
    useSelector((state) => state.JuteGateEntryReducer);
  const { branchList, mukamList, approvedIndentsList } = useSelector(
    (state) => state.DropDownListReducer
  );
  const { vehicleTypeList, SupplierByMukamList, BrokerBySupplierId } =
    useSelector((state) => state.MasterDropDownListReducer);

  useEffect(() => {
    setHeaderData(JuteGateEntryHeader);
    setLineItems(JuteGateEntrylineItems);
  }, [JuteGateEntryHeader, JuteGateEntrylineItems]);

  useEffect(() => {
    dispatch(
      getBranchList(
        serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
        history
      )
    );

    dispatch(
      getMukamList(
        serverApi.MUKAM_GET_LIST + localStorage.getItem("companyId"),
        history
      )
    );
    dispatch(
      getAllApprovedPO(
        serverApi.GET_APPROVED_JUTE_PO +
          localStorage.getItem("companyId") +
          "/" +
          localStorage.getItem("acadamicYear") +
          "/" +
          JSON.parse(localStorage.getItem("authUser")).cipher
      )
    );

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (branchList) {
      if (branchList.data) {
        let list = branchList.data.filter((props) => {
          if (props.value !== 0) {
            return props;
          }
        });
        var ListData = [];
        list.map((item) => {
          ListData.push({
            value: item.value,
            label: item.label,
            name: item.label,
          });
        });
        setProjectData(ListData);
      }
    }
    if (mukamList) {
      if (mukamList.data) {
        let list = mukamList.data.filter((props) => {
          if (props.value !== 0) {
            return props;
          }
        });
        setMukamData(list);
      }
    }
    if (SupplierByMukamList) {
      if (SupplierByMukamList.data) {
        let FilteredList = SupplierByMukamList.data.filter((props) => {
          if (props.value !== "0") {
            return props;
          }
        });
        setSupplierByMukamList(FilteredList);
      }
    }
    if (GetAllApprovedPo) {
      if (GetAllApprovedPo.data) {
        var List = [];
        GetAllApprovedPo.data.map((item) => {
          var name = item.name.split("^");
          List.push({
            value: item.value,
            name: name[3],
            label: name[3],
          });
        });
        setApprovedPoData(List);
      }
    }
  }, [branchList, SupplierByMukamList, mukamList, GetAllApprovedPo]);
  const handleScroll = () => {
    setDropdownOpen(true);
  };
  const handleMenuOpen = () => {
    setDropdownOpen(false);
  };
  const handleSelectDate = (e, name) => {
    if (name === "inDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      if(HeaderData.JuteGateEntryType == "WITHPO"){
       if(Date < HeaderData.poDate){
        swal("Date Shouldn't be Less than Po Date")
        setHeaderData((prev) => ({
          ...prev,
          inDate: null,
        }));
        const UpdatedRec = {
          ...HeaderData,
          inDate:  null,
        };
        dispatch(getJuteGateEntryHeader(UpdatedRec));
       }else{
        setHeaderData((prev) => ({
          ...prev,
          inDate: Date,
        }));
        const UpdatedRec = {
          ...HeaderData,
          inDate: Date,
        };
        dispatch(getJuteGateEntryHeader(UpdatedRec));
       }
      }else{
        setHeaderData((prev) => ({
          ...prev,
          inDate: Date,
        }));
        const UpdatedRec = {
          ...HeaderData,
          inDate: Date,
        };
        dispatch(getJuteGateEntryHeader(UpdatedRec));
      }
    }

    if (name === "outDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      if(Date<HeaderData.inDate){
        swal('Out Date Should not be less than In Date')
        setHeaderData((prev) => ({
          ...prev,
          outDate: null,
        }));
        const UpdatedRec = {
          ...HeaderData,
          outDate: null,
        };
        dispatch(getJuteGateEntryHeader(UpdatedRec));
      }
      else{
      setHeaderData((prev) => ({
        ...prev,
        outDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        outDate: Date,
      };
      dispatch(getJuteGateEntryHeader(UpdatedRec));
    }
    }
    
    if (name === "chalanDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      if(Date < HeaderData.inDate){
        swal("Chalan Date Shouldn't be Less than Gate Entry Date")
        setHeaderData((prev) => ({
          ...prev,
          chalanDate: null,
        }));
        const UpdatedRec = {
          ...HeaderData,
          chalanDate:  null,
        };
        dispatch(getJuteGateEntryHeader(UpdatedRec));
       }else
      {setHeaderData((prev) => ({
        ...prev,
        chalanDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        chalanDate: Date,
      };
      dispatch(getJuteGateEntryHeader(UpdatedRec));
    }
    }
  };
  const handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "poNo") {
      setHeaderData((prev) => ({
        ...prev,
        poNo: selectedName,
        branchname: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        poNo: selectedName,
      };
      dispatch(getJuteGateEntryHeader(UpdatedRec));
      var mdata = {
        poNum: selectedName,
        userId: userDit.userId,
        cipher: userDit.cipher,
      };
      dispatch(
        jutePoViewByIdForGateEntry(serverApi.GET_PO_DETAILS, mdata, history)
      );
    }
    if (name === "branchId") {
      setHeaderData((prev) => ({
        ...prev,
        branchId: selectedValue,
        branchname: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        branchId: selectedValue,
        branchName: selectedName,
      };
      dispatch(getJuteGateEntryHeader(UpdatedRec));
    }

    if (name === "unitConversion") {
      setHeaderData((prev) => ({
        ...prev,
        unitConversion: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        unitConversion: selectedName,
      };
      dispatch(getJuteGateEntryHeader(UpdatedRec));
    }
    if (name === "suppCode") {
      setHeaderData((prev) => ({
        ...prev,
        suppCode: selectedValue,
        supplierName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        suppCode: selectedValue,
        supplierName: selectedName,
      };
      dispatch(getJuteGateEntryHeader(UpdatedRec));
    }
    if (name === "mukam") {
      setHeaderData((prev) => ({
        ...prev,
        mukam: selectedValue,
        mukamName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        mukam: selectedValue,
        mukamName: selectedName,
      };
      dispatch(getJuteGateEntryHeader(UpdatedRec));
      dispatch(
        getSupplierByMukamIdList(
          serverApi.GET_SUPPLIER_BY_MUKAM_ID +
            selectedValue +
            "/company/" +
            localStorage.getItem("companyId"),
          props.history
        )
      );
    }

    if (name === "supplierId") {
      setHeaderData((prev) => ({
        ...prev,
        supplierId: selectedValue,
        suppName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        supplierId: selectedValue,
        suppName: selectedName,
      };
      dispatch(getJuteGateEntryHeader(UpdatedRec));
    }
  };

  const OnhandleChange = (key) => (name, event) => {
    if (key === "grossWeight") {
      let actualWeight = "";
      let actualWeightQtl = "";
      if (event.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
      if (!isEmpty(HeaderData.tareWeight) && !isEmpty(event)) {
        actualWeight = limitDecimals(
          Number(event) - Number(HeaderData.tareWeight),
          2
        );
      } else {
        actualWeight = event;
      }
      if (!isEmpty(HeaderData.tareWeight) && !isEmpty(event)) {
        actualWeightQtl = limitDecimals(
          (Number(event) - Number(HeaderData.tareWeight)) / 100,
          2
        );
      } else if (isEmpty(event) && isEmpty(HeaderData.tareWeight)) {
        actualWeightQtl = limitDecimals(Number(event) / 100);
      } else {
        actualWeightQtl = limitDecimals(Number(event) / 100, 2);
      }
      setHeaderData((prev) => ({
        ...prev,
        grossWeight: event,
        grossWeightQtl: limitDecimals(Number(event) / 100, 2),
        actualWeight: actualWeight,
        actualWeightQtl: actualWeightQtl,
      }));
      const UpdatedRec = {
        ...HeaderData,
        grossWeight: event,
        grossWeightQtl: limitDecimals(Number(event) / 100, 2),
        actualWeight: actualWeight,
        actualWeightQtl: actualWeightQtl,
      };
      dispatch(getJuteGateEntryHeader(UpdatedRec));
      }else{
        swal("Please enter a positive number with up to 3 decimal places.");
      }}
    if (key === "challanWeight") {
      if (event.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
      setHeaderData((prev) => ({
        ...prev,
        challanWeight: event,
        challanWeightQtl: limitDecimals(Number(event) / 100, 2),
      }));
      const UpdatedRec = {
        ...HeaderData,
        challanWeight: event,
        challanWeightQtl: limitDecimals(Number(event) / 100, 2),
      };
      dispatch(getJuteGateEntryHeader(UpdatedRec));
    }else{
      swal("Please enter a positive number with up to 3 decimal places.");
    }
    }
    if (key === "tareWeight") {
      let actualWeight = "";
      let actualWeightQtl = "";
      if (event.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
      if (!isEmpty(event) && !isEmptyWithZero(HeaderData.grossWeight)) {
        actualWeight = limitDecimals(
          Number(HeaderData.grossWeight) - Number(event),
          2
        );
      } else {
        actualWeight = HeaderData.grossWeight;
      }
      if (!isEmptyWithZero(event) && !isEmptyWithZero(HeaderData.grossWeight)) {
        actualWeightQtl = limitDecimals(
          (Number(HeaderData.grossWeight) - Number(event)) / 100,
          2
        );
      } else if (isEmpty(HeaderData.grossWeight) && isEmptyWithZero(event)) {
        actualWeightQtl = limitDecimals(Number(event) / 100);
      } else {
        actualWeightQtl = "";
      }
      setHeaderData((prev) => ({
        ...prev,
        tareWeight: event,
        tareWeightQtl: limitDecimals(Number(event) / 100, 2),
        actualWeight: actualWeight,
        actualWeightQtl: actualWeightQtl,
      }));
      const UpdatedRec = {
        ...HeaderData,
        tareWeight: event,
        tareWeightQtl: limitDecimals(Number(event) / 100, 2),
        actualWeight: actualWeight,
        actualWeightQtl: actualWeightQtl,
      };
      dispatch(getJuteGateEntryHeader(UpdatedRec));
     }else{
      swal("Please enter a positive number with up to 3 decimal places.");

     } }
    if (key === "actualWeight") {
      setHeaderData((prev) => ({
        ...prev,
        actualWeight: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        actualWeight: event,
      };
      dispatch(getJuteGateEntryHeader(UpdatedRec));
    }
  };
  const onhandlechangeValue = (key) => (event) => {
    if (key === "chalanNo") {
      setHeaderData((prev) => ({
        ...prev,
        chalanNo: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        chalanNo: event,
      };
      dispatch(getJuteGateEntryHeader(UpdatedRec));
    }
    if (key === "vehicleNo") {
      setHeaderData((prev) => ({
        ...prev,
        vehicleNo: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        vehicleNo: event,
      };
      dispatch(getJuteGateEntryHeader(UpdatedRec));
    }
    if (key === "driverName") {
      setHeaderData((prev) => ({
        ...prev,
        driverName: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        driverName: event,
      };
      dispatch(getJuteGateEntryHeader(UpdatedRec));
    }
    if (key === "transporter") {
      setHeaderData((prev) => ({
        ...prev,
        transporter: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        transporter: event,
      };
      dispatch(getJuteGateEntryHeader(UpdatedRec));
    }
    if (key === "remarks") {
      setHeaderData((prev) => ({
        ...prev,
        remarks: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        remarks: event,
      };
      dispatch(getJuteGateEntryHeader(UpdatedRec));
    }
  };
  return (
    <div className="juteIndent">
      <div className="consoleFormContainer">
      <h5>Source Type:{HeaderData.JuteGateEntryType == "WITHPO" ? "With Po" : "Without Po"}</h5>
        <h5>Fill Details</h5>
        <div className="consoleFormContainer">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {HeaderData.JuteGateEntryType == "WITHPO" && (
                    <>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        {locationState.state ==undefined ?(<DynamicSelectNew
                          arrayOfData={ApprovedPoData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={handlePropSelectChange}
                          isDropdownOpen={isDropdownOpen}
                          handleMenuOpen={handleMenuOpen}
                          selected={HeaderData.poNo}
                          update={HeaderData.poNo ? 1 : 0}
                          name="poNo"
                          label="Po List"
                          required
                        />):(   <TextFieldNormal
                          label="Po Number"
                          value={HeaderData.poNo}
                          disabled={true}
                        />)}
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                    <div>
                      <div className="TextFieldBlock">
                        <label>
                         PO Date
                        </label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          readOnly={
                            locationState.pathname ===
                            "/update_jute_quality_check"
                              ? true
                              : false
                          }
                          value={HeaderData.poDate}
                          fullWidth
                        isDisabled={true}
                          onChange={(e) => handleSelectDate(e, "poDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                              style={{
                                backgroundColor:"#ccd6db" ,
                              }}
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                                style={{
                                  backgroundColor:"#ccd6db" ,
                                }}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  </>)}
                  <Box gridColumn="span 3">
                    <div>
                      <div className="TextFieldBlock">
                        <label>
                          Date <div className="mandatoryField">*</div>
                        </label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          readOnly={
                            locationState.pathname ===
                            "/update_jute_quality_check"
                              ? true
                              : false
                          }
                          value={HeaderData.inDate}
                          fullWidth
                          disabled={HeaderData.JuteGateEntryType == "WITHPO" && isEmpty(HeaderData.poNo)}
                          onChange={(e) => handleSelectDate(e, "inDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                              style={{
                                backgroundColor:
                                HeaderData.JuteGateEntryType == "WITHPO" && isEmpty(HeaderData.poNo)
                                    ? "#ccd6db"
                                    : "",
                              }}
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                                style={{
                                  backgroundColor:
                                  HeaderData.JuteGateEntryType == "WITHPO" && isEmpty(HeaderData.poNo)
                                      ? "#ccd6db"
                                      : "",
                                }}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="TextFieldBlock">
                      <label>
                        Time <div className="mandatoryField">*</div>
                      </label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        disabled={HeaderData.JuteGateEntryType == "WITHPO" && isEmpty(HeaderData.poNo)}
                        label="Time"
                        value={HeaderData.inTime}
                        readOnly={
                          locationState.pathname ===
                          "/update_jute_quality_check"
                            ? true
                            : false
                        }
                        onChange={(newValue) => {
                          // this.setState({ inTime: newValue });
                          // this.props.headerData.inTime = newValue.getTime();
                          setHeaderData((prev) => ({
                            ...prev,
                            inTime: newValue.getTime(),
                          }));
                          const UpdatedRec = {
                            ...HeaderData,
                            inTime: newValue.getTime(),
                          };
                          dispatch(getJuteGateEntryHeader(UpdatedRec));
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                            style={{
                              backgroundColor:
                              HeaderData.JuteGateEntryType == "WITHPO" && isEmpty(HeaderData.poNo)
                                  ? "#ccd6db"
                                  : "",
                            }}
                          >
                            <input ref={inputRef} {...inputProps}
                            style={{
                              backgroundColor:
                              HeaderData.JuteGateEntryType == "WITHPO" && isEmpty(HeaderData.poNo)
                                  ? "#ccd6db"
                                  : "",
                            }} />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Challan Number"
                      placeholder="Enter here"
                      value={HeaderData.chalanNo}
                      disabled={
                        locationState.pathname === "/update_jute_quality_check"
                          ? true
                          : false
                      }
                      onChange={onhandlechangeValue("chalanNo")}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div>
                      <div className="TextFieldBlock">
                        <label>Challan Date</label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          readOnly={
                            locationState.pathname ===
                            "/update_jute_quality_check"
                              ? true
                              : false
                          }
                          value={HeaderData.chalanDate}
                          fullWidth
                          disabled={HeaderData.JuteGateEntryType == "WITHPO" && isEmpty(HeaderData.poNo)}
                          onChange={(e) => handleSelectDate(e, "chalanDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                              style={{
                                backgroundColor:
                                HeaderData.JuteGateEntryType == "WITHPO" && isEmpty(HeaderData.poNo)
                                    ? "#ccd6db"
                                    : "",
                              }}
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                                style={{
                                  backgroundColor:
                                  HeaderData.JuteGateEntryType == "WITHPO" && isEmpty(HeaderData.poNo)
                                      ? "#ccd6db"
                                      : "",
                                }}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Vehicle Number"
                      placeholder="Enter here"
                      value={HeaderData.vehicleNo}
                      onChange={onhandlechangeValue("vehicleNo")}
                      disabled={
                        locationState.pathname === "/update_jute_quality_check"
                          ? true
                          : false
                      }
                      required
                      caps="ON"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Driver Name"
                      placeholder="Enter here"
                      value={HeaderData.driverName}
                      disabled={
                        locationState.pathname === "/update_jute_quality_check"
                          ? true
                          : false
                      }
                      onChange={onhandlechangeValue("driverName")}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Transporter"
                      placeholder="Enter here"
                      value={HeaderData.transporter}
                      disabled={
                        locationState.pathname === "/update_jute_quality_check"
                          ? true
                          : false
                      }
                    

                      onChange={onhandlechangeValue("transporter")}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={ProjectData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.branchId}
                        isDisabled={HeaderData.JuteGateEntryType == "WITHPO" ? true:locationState.state ? true : false}

                        update={HeaderData.branchId ? 1 : 0}
                        name="branchId"
                        label="Branch"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectNew
                        arrayOfData={UnitConversionData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        isDisabled={locationState.state ? true : false}
                        selected={HeaderData.unitConversion}
                        name="unitConversion"
                        label="Unit Convertion"
                        update={HeaderData.unitConversion ? 1 : 0}
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={MukamData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.mukam}
                        isDisabled={locationState.state ? true : false}
                        name="mukam"
                        label="Mukam"
                        required
                        update={HeaderData.mukam ? 1 : 0}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div
                      className="consoleTextFieldBlock"
                    >
                      <DynamicSelect
                        arrayOfData={SupplierByMukamListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.suppCode}
                        isDisabled={locationState.state ? true : false}
                        name="suppCode"
                        label="Supplier"
                        required
                      />
                    </div>
                  </Box>          
                  
                </Box>
              </Grid>
            </Grid>
        
          </div>
        </div>
        <div className="consoleFormContainer">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="TextFieldBlock">
                      <label>
                        Gross Weight <span className="starcolor">*</span>
                      </label>
                    </div>
                    <InputAdornment
                      label="KG"
                      type="number"
                      name="grossWeight"
                      handleChange={OnhandleChange("grossWeight")}
                      value={HeaderData.grossWeight}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="TextFieldBlock">
                      <label>.</label>
                    </div>
                    <InputAdornment
                      label="QTL"
                      type="number"
                      name="grossWeightQtl"
                      handleChange={OnhandleChange("grossWeightQtl")}
                      value={HeaderData.grossWeightQtl}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>
                          Challan Weight<span className="starcolor">*</span>
                        </label>
                      </div>
                      <InputAdornment
                        label="KG"
                        type="number"
                        name="challanWeight"
                        handleChange={OnhandleChange("challanWeight")}
                        value={HeaderData.challanWeight}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>.</label>
                      </div>
                      <InputAdornment
                        label="QTL"
                        type="number"
                        name="challanWeightQtl"
                        handleChange={OnhandleChange("challanWeightQtl")}
                        value={HeaderData.challanWeightQtl}
                      disabled={true}

                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>Tare Weight</label>
                      </div>
                      <InputAdornment
                        label="KG"
                        type="number"
                        name="tareWeight"
                        handleChange={OnhandleChange("tareWeight")}
                        value={HeaderData.tareWeight}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>.</label>
                      </div>
                      <InputAdornment
                        label="QTL"
                        type="number"
                        name="tareWeightQtl"
                        handleChange={OnhandleChange("tareWeightQtl")}
                        value={HeaderData.tareWeightQtl}
                      disabled={true}

                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>Actual Weight</label>
                      </div>
                      <InputAdornment
                        label="KG"
                        type="number"
                        name="actualWeight"
                        handleChange={OnhandleChange("actualWeight")}
                        value={HeaderData.actualWeight}
                        disabled={true}

                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>.</label>
                      </div>
                      <InputAdornment
                        label="QTL"
                        type="number"
                        name="actualWeightQtl"
                        handleChange={OnhandleChange("actualWeightQtl")}
                        value={HeaderData.actualWeightQtl}
                      disabled={true}

                      />
                    </div>
                  </Box>
                {locationState.state !==undefined &&<>  
                <Box gridColumn="span 3">
                    <div>
                      <div className="TextFieldBlock">
                        <label>
                         Out Date <div className="mandatoryField">*</div>
                        </label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          readOnly={
                            locationState.pathname ===
                            "/update_jute_quality_check"
                              ? true
                              : false
                          }
                          value={HeaderData.outDate}
                          fullWidth
                          onChange={(e) => handleSelectDate(e, "outDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="TextFieldBlock">
                      <label>
                       Out Time 
                      </label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="Time"
                        value={HeaderData.outTime}
                        readOnly={
                          locationState.pathname ===
                          "/update_jute_quality_check"
                            ? true
                            : false
                        }
                        onChange={(newValue) => {
                          setHeaderData((prev) => ({
                            ...prev,
                            outTime: newValue.getTime(),
                          }));
                          const UpdatedRec = {
                            ...HeaderData,
                            outTime: newValue.getTime(),
                          };
                          dispatch(getJuteGateEntryHeader(UpdatedRec));
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input ref={inputRef} {...inputProps} />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  </>}
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextArea
                        label="Remarks"
                        onChange={onhandlechangeValue("remarks")}
                        value={HeaderData.remarks}
                        disabled={
                          locationState.pathname ===
                          "/update_jute_quality_check"
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
               
                  </Box></Grid></Grid></div></div>
      </div>
    </div>
  );
};
const UnitConversionData = [
  {
    value: 1,
    label: "LOOSE",
    name: "LOOSE",
  },
  {
    value: 2,
    label: "BALE",
    name: "BALE",
  },
];
export default JuteGateEntryFillDetails;
