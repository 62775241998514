import {
  CREATE_SHIFT_MASTER,
  CREATE_SHIFT_MASTER_SUCCESSFULL,
  BIO_METRIc_DEVICE,
  BIO_METRIc_DEVICE_SUCCESSFULL,
  CREATE_BIO_METRIC_DEVICE,
  CREATE_BIO_METRIC_DEVICE_SUCCESSFULL,
  GET_WORKERDETAILS_BYID,
  GET_WORKERDETAILS_BYID_SUCCESSFULL,
  DELETE_BIO_METRIC,
  DELETE_BIO_METRIC_SUCCESSFULL,
  VIEW_BY_ID_SHIFT_MASTER,
  VIEW_BY_ID_SHIFT_MASTER_SUCCESSFULL,
} from "./actionType";
const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  shift_master: [],
  bio_metric_device: [],
  create_bio_metric_device: [],
  GetWorker_DetailsById: [],
  DeleteBioMetric_Data: [],
  ShiftMasterViewById_data:[]
};

const ShiftMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SHIFT_MASTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_SHIFT_MASTER_SUCCESSFULL:
      state = {
        ...state,
        shift_master: action.payload.response,
        loading: false,
      };
    // Bio metric Device
    case BIO_METRIc_DEVICE:
      state = {
        ...state,
        loading: true,
      };
      break;

    case BIO_METRIc_DEVICE_SUCCESSFULL:
      state = {
        ...state,
        bio_metric_device: action.payload.response,
        loading: false,
      };
    // Create Bio metric Device
    case CREATE_BIO_METRIC_DEVICE:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CREATE_BIO_METRIC_DEVICE_SUCCESSFULL:
      state = {
        ...state,
        create_bio_metric_device: action.payload.response,
        loading: false,
      };
    case GET_WORKERDETAILS_BYID:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_WORKERDETAILS_BYID_SUCCESSFULL:
      state = {
        ...state,
        GetWorker_DetailsById: action.payload.response,
        loading: false,
      };
    case DELETE_BIO_METRIC:
      state = {
        ...state,
        loading: true,
      };
      break;

    case DELETE_BIO_METRIC_SUCCESSFULL:
      state = {
        ...state,
        DeleteBioMetric_Data: action.payload.response,
        loading: false,
      };
      case VIEW_BY_ID_SHIFT_MASTER:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case VIEW_BY_ID_SHIFT_MASTER_SUCCESSFULL:
        state = {
          ...state,
          ShiftMasterViewById_data: action.payload.response,
          loading: false,
        };
  }
  return state;
};
export default ShiftMasterReducer;
