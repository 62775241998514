import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextFieldNormal from "../../TextField/TextFieldNormal";
import DynamicSelect from "../../../components/Dropdown/Master/DynamicSelectMasters";
import { serverApi } from "../../../helpers/Consts";
import { getParentLedgerList } from "../../../store/Global/DropDownApis/actions";

class LedgerGroupMasterFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      ledgerCode: "",
      ledgerGroupName: "",
      parentLedgerGroup: "",
      parentLedgerList_Data: [],
    };
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "ledgerCode") {
      this.setState({
        ledgerCode: event,
      });
    }
    if (key === "ledgerGroupName") {
      this.setState({
        ledgerGroupName: event,
      });
    }
  };

  handleSubmit = () => {
    const data = {
      ledgerCode: this.state.ledgerCode !==""?this.state.ledgerCode:null,
      ledgerGroupName: this.state.ledgerGroupName !==""?this.state.ledgerGroupName:null,
      parentLedgerGroup: this.state.parentLedgerGroup,
    };
    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "parentLedgerGroup") {
      if(selectedValue ==0){
        this.setState({
          parentLedgerGroup: null,
        });
      }else{
        this.setState({
          parentLedgerGroup: selectedValue,
        });
      }
     
    }
  };
  componentDidMount() {
    this.props.getParentLedgerList(
      serverApi.GET_PARENT_LEDGER_LIST +
        this.state.companyId +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    ); // parent ledger list api
  }
  componentWillReceiveProps(props) {
    if (props.parentLedgerList) {
      this.setState({
        parentLedgerList_Data: props.parentLedgerList.data, // updating parent ledger list
      });
    }
  }
  render() {
    return (
      <>
        <Box>
          <Button onClick={this.handleClickOpen}>
            <Tooltip title="Filter">
              <FilterListOutlinedIcon />
            </Tooltip>
          </Button>
          <Dialog
            open={this.state.open}
            PaperProps={{
              sx: {
                position: " fixed",
                top: 0,
                right: 0,
                margin: 0,
                bottom: 0,
                maxHeight: "100vh",
              },
            }}
            keepMounted
            onClose={this.handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <Grid spacing={0}>
              <div className="consoleTextFieldBlock">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div className="filterBlockHeader">
                    <FilterListOutlinedIcon />
                    <span>Filter</span>
                  </div>
                </div>
              </div>
              <Grid item xs={12} className="filterBlockContainer">
                <Box gridColumn="span 4">
                  <div className="filterDateBlock">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Ledger Group Code"
                        value={this.state.ledgerCode}
                        onChange={this.onhandlechange("ledgerCode")}
                      />
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <TextFieldNormal
                        label="Ledger Group Name"
                        value={this.state.ledgerGroupName}
                        onChange={this.onhandlechange("ledgerGroupName")}
                      />
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.parentLedgerList_Data}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.parentLedgerGroup}
                      name="parentLedgerGroup"
                      label="Parent ledger Group"
                    />
                  </div>
                </Box>
                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Search"}
                    className="greenButton"
                    handleClick={this.handleSubmit}
                    type="sumbit"
                  />
                </div>
              </Grid>
            </Grid>
          </Dialog>
        </Box>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { parentLedgerList } = state.DropDownListReducer;
  return { parentLedgerList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getParentLedgerList,
  })(LedgerGroupMasterFilter)
);
