import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/Jute/DynamicSelect";
import InputAdornment from "../../../components/InputAdorment/InputAdorment";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import moment from "moment";
import { getBranchList } from "../../../store/Global/DropDownApis/actions";

import { serverApi } from "../../../helpers/Consts";
import {
  LineItemsIssuePros,
  juteIssueViewById,
} from "../../../store/Jute/JuteIssue/actions";

class FillDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchId: 0,
      issueDate: moment().format(),
      userDit: JSON.parse(localStorage.getItem("authUser")),
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getBranchList(
      serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
      this.props.history
    );

    if (this.props.location.state && this.props.location.state !== undefined) {
      if (this.props.location.state.rowData) {
      }
    } else {
      this.setState({
        branchId: this.props.headerData.branchId,
        issueDate: this.props.headerData.issueDate
          ? moment(this.props.headerData.issueDate).format()
          : "",
      });
    }
  }
  componentWillReceiveProps(props) {
    if (props.branchList) {
      if (props.branchList.data) {
        let list = props.branchList.data.filter((props) => {
          if (props.value !== 0) {
            return props;
          }
        });
        this.setState({
          branchListData: list,
        });
      }
    }
    // if (props.branchList) {
    //   this.setState({
    //     branchListData: props.branchList.data,
    //   });
    // }

    if (this.props.JutePoViewByIdData) {
      var poHeader = this.props.JutePoViewByIdData.poHeader;
      if (poHeader) {
        this.setState({
          branchId: poHeader.branchId,
          issueDate: moment(poHeader.issueDate).format(),
        });
        this.props.headerData.withorWithOutPo = poHeader.branchId;
        this.props.headerData.issueDate = moment(poHeader.issueDate).format();
      }
    }

    if (this.props.location.state && this.props.location.state !== undefined) {
      if (this.props.location.state.rowData) {
      }
    }
  }
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    this.props.headerData[key] = event;
  };
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    this.setState({ [name]: selectedValue });
    this.props.headerData[name] = selectedValue;
  };
  handleSelectDate = (e, name) => {
    if (name === "issueDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      this.setState({
        issueDate: Date,
      });
      this.props.headerData.issueDate = e.getTime();
    }
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div className="juteIndent">
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormContainer">
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Date <div className="mandatoryField">*</div>
                          </label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="dd-MM-yyyy"
                            value={this.state.issueDate}
                            fullWidth
                            onChange={(e) =>
                              this.handleSelectDate(e, "issueDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  readOnly={true}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </Box>

                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.branchListData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.branchId}
                          update={this.props.location.state ? 1 : 0}
                          name="branchId"
                          label="Branch"
                          required
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { branchList } = state.DropDownListReducer;

  return {
    branchList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getBranchList,
  })(FillDetails)
);
