import React, { Component } from "react";
import { Grid, Box, Alert } from "@mui/material";
import DynamicSelect from "../DynamicSelect";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import TextFieldAlfaNum from "../../../components/TextField/TextFieldAlfaNum";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getCostList } from "../../../store/Accounting/Bills/actions";

class ReceiptLineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCount: 1,
      items_List: [],
      indentLineItems: [],
      BillListData: [],
      description: this.props.header.description,
      fundsIn: this.props.header.fundsIn,
      delete: false,
      products: this.props.products,
    };
  }

  handleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
  
    this.setState({ [key]: event });
    let mname = key.split("_");


    if (mname[0] === "amount") {
    
      

      let filterData = this.props.products;
      let objIndex = filterData.findIndex((item) => item == row);
      filterData[objIndex].amount = value;
      this.props.delete(filterData);
      this.setState({ products: filterData });
     
      var totalAmount = filterData
        .map((p) => p.amount)
        .reduce((total, ind) => parseFloat(total) + parseFloat(ind));
      this.props.header.fundsIn = totalAmount;
    }
  };
  headerValue = (key) => (event) => {
    this.setState({ [key]: event });

    if (key === "amount") {
      this.props.header.description = event;
      this.setState({
        description: event,
      });
    }
  };
  componentDidUpdate(prevProps) {

    if (prevProps.products != this.props.products) {
 
      this.setState({ products: this.props.products });
    }
  }

  handleChangedropdown = (selectedValue, selectedName, name, selectedRow) => {

    let lastRow =  this.state.products[this.state.products.length - 1];
    let lastVal = selectedRow.id;

    if (name === "billNo") {
      //amount calculation
      let amount = selectedName.split("_");
      let amt1 = amount[2] === "null" ? 0 : amount[2];
      let amt2 = amount[4] === "null" ? 0 : amount[4];
      let tolAmt = parseFloat(amt1) - parseFloat(amt2);

      let Name = selectedName.split("_");
      let nam = Name[3];

      selectedRow.billId = selectedValue;
      selectedRow.amount = tolAmt;

      selectedRow.description = nam;
      selectedRow.invoiceNo = nam;

      var filterData = this.props.products
      var totalAmount =  filterData.map(p => p.amount).reduce((total, ind) => parseFloat(total)+ parseFloat(ind));
      var filName = this.props.products
        .map((p) => p.description)
        .reduce((total, ind) => total + "," + ind);
    

      this.props.header.fundsIn = totalAmount;
      this.props.header.description = "Against Bill No:- " + filName;
      if(selectedRow === lastRow && lastVal === "0" && selectedValue !== "0") {
        this.handleAddEvent();
      }

    }
    
    // }
  };

  handleAddEvent() {
    let product = {
      id: "0",
      amount: 0,
      billId: 0,
      createOn: null,
      createdBy: "",
      invoiceId: 0,
      invoiceNo: 0,
      isActive: 1,
      modifiedBy: null,
      receiptNo: 0,
      transactionId: 0,
    };

    var products = this.props.products;
    products.push(product);

    this.props.delete(products);
    this.setState({ products });

  }

 

  onDel = (data) => (event) => {
    const {row } = data;

    if (this.props.products.length == 1) {
      Alert("cannot be removed");
    } else {
    

      var indexitem=this.props.products.findIndex((item)=>item==row);
      var products=this.props.products;
      products[indexitem].isActive=0;

      this.props.delete(products);
      this.setState({
        products,
      });

      var totalAmount = products
        .filter(item=>item.isActive!=0)
        .map((p) => p.amount)
        .reduce((total, ind) => parseFloat(total) + parseFloat(ind));
      this.props.header.fundsIn = totalAmount;

      var filName = products
      .filter(item=>item.isActive!=0)
      .map((p) => p.description)
      .reduce((total, ind) => total + "," + ind);
     

      this.props.header.description = "Against Bill No:- " + filName;

    }
  };

  onDelEvent = (data) => (event) => {
    const { key } = data;
    this.setState({ [key]: event });

    //

    this.setState({
      delete: true,
     
    });
   
  };

  componentWillReceiveProps(props) {
    let billList = [];

    if (props.BillNoList) {
      props.BillNoList.data.map((prop) => {
       
        billList.push({
          label: prop.invoiceNo + '_' + prop.ledgerName + '_' + prop.fundsOut,
          name: prop.invoiceNo + '_' + prop.ledgerId + "_" + prop.fundsOut + '_' +
            prop.invoiceNo + "_" + prop.receivedAmount + "_" + prop.rcptNo,
          value: prop.invoiceNo,
          ledgerId: prop.ledgerId
        });
      });
      this.setState({
        BillListData: billList,
      });
    }
  }

  render() {
 
    var filteredproducts=this.props.products.filter(item=>item.isActive!=0)
    return (
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent">
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {this.props.tableHeaders.map((column) => (
                    <th>{column.header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredproducts.map((row) => {
                  let index = this.props.products.findIndex(
                    (idd) => idd == row
                  );
                
                  return (
                    <tr>
                     
                      <>
                        {this.props.RowData &&
                          this.props.RowData.map((col) =>
                            col.component === "Inputadorment" ? (
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        type="text"
                                        name={col.type + "_" + index}
                                        id={col.no}
                                        value={
                                          col.type == "amount" ? row.amount : ""
                                        }
                                        // value="34343"
                                        onChange={this.handleChange({
                                          key: col.type + "_" + index,
                                          row,
                                        })}
                                        className="inputBlockContainer"
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            ) : (
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                     
                                      <DynamicSelect
                                        arrayOfData={
                                          col.type === "billNo"
                                            ? this.state.BillListData
                                            : []
                                        }
                                        className="dropdownBlockContainer"
                                        onSelectChange={
                                          this.handleChangedropdown
                                        }
                                        selected={
                                          col.type === "billNo"
                                            ? row.invoiceNo
                                            : ""
                                        }
                                        name={
                                          col.name === "billNo" ? "billNo" : ""
                                        }
                                        row={row}
                                        value={
                                          col.type === "billNo"
                                            ? row.invoiceNo
                                            : ""
                                        }
                                        update={
                                          this.props.location.state ? 1 : 0
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            )
                          )}
                      </>
                      <td className="">
                        <input
                          type="button"
                          onClick={this.onDel({
                            key: row.trnDetailId,
                            row,
                          })}
                          value="X"
                          className="deleteDisplayButton"
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex">
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <TextFieldAlfaNum
                    label="Description"
                    value={this.props.header.description}
                    type="textarea"
                    
                    placeholder="Enter Here"
                    required
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <TextFieldReq
                    label="Total Amount"
                    value={this.props.header.fundsIn}
                    caps="OFF"
                    minValue="0"
                    required
                  />
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
 
  const { BillNoList } = state.PaymentReducer;

  return { BillNoList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getCostList,
  })(ReceiptLineItem)
);
