import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {CREATE_RE_OCCURING_INVOICE, RE_OCCURING_INVOICE_LIST} from './actionType'
import {getReOccuringInvoiceListSuccessfull,setReOccuringInvoiceSuccessfull} from './actions'
import {getList, postAdd} from '../../../helpers/Server_Helper';
function* getReOccuringInvoice({payload:{url,data,history}}) {
    try{
        const response = yield call(getList,url,data);
        yield put(getReOccuringInvoiceListSuccessfull({response}))
    }catch(error){
        console.log(error)
    }
}
function* setReOccuringInvoiceData({payload:{url,data,history}}) {
    try{
        const response = yield call(postAdd,url,data);
        yield put(setReOccuringInvoiceSuccessfull({response}))
    }catch(error){
        console.log(error)
    }
}
export function* watchSalesOrderList () {
    yield takeEvery(RE_OCCURING_INVOICE_LIST, getReOccuringInvoice)
    yield takeEvery(CREATE_RE_OCCURING_INVOICE, setReOccuringInvoiceData)

}

function* ReOccuringInvoiceSaga() {
    yield all([
        fork(watchSalesOrderList),
    ])
}


export default ReOccuringInvoiceSaga;