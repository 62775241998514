import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
// import DynamicSelect from "../Dropdown/Jute/DynamicSelect";
import DynamicSelect from "../../components/Dropdown/Master/DynamicSelectMasters"
import TextFieldNormal from "../TextField/TextFieldNormal";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DesktopDatePicker from "@mui/lab/DatePicker";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';   
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';   
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'; 

import moment from "moment";
import { serverApi } from "../../helpers/Consts";
import {
  getSupplierListMultiselect,
  getStatusList,
  getMukamList,
} from "../../store/Global/DropDownApis/actions";
import { getVehicleList } from "../../store/MasterDropdownApis/actions";

class JutePOFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      supplierListMultiselectData: [],
      StatusListData: [],
      mukamList: [],
      fromDate: "",
      toDate: "",
      supplier: "",
      supplierValue: "",
      statusValue: "",
      status: "",
      gateEntryNo:"",
      mrNo:"",
    };
  }

  componentDidMount() {
    this.props.getSupplierListMultiselect(
      serverApi.GET_SUPPLIER_LIST_MULTISELECT +
        localStorage.getItem("companyId"),
      this.props.history
    );
    this.props.getStatusList(serverApi.STATUS_LISTS, this.props.history);
    this.props.getMukamList(
      serverApi.MUKAM_GET_LIST + localStorage.getItem("companyId"),
      this.props.history
    );
  }
  componentWillReceiveProps(props) {
    if (props.supplierListMultiselect) {
      this.setState({
        supplierListMultiselectData: props.supplierListMultiselect.data,
      });
    }
    if (props.statusList) {
      this.setState({
        StatusListData: props.statusList.data,
      });
    }
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if(key==="gateEntryNo"){
      this.setState({
        gateEntryNo:event
      })
    }
    if(key==="mrNo"){
      this.setState({
        mrNo:event
      })
    }
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "Supplier") {
      this.setState({
        supplierValue: selectedValue === 0 ? "" : selectedValue,
        supplier: selectedName,
      });
    }
    if (name === "Status") {
      this.setState({
        statusValue: selectedValue === 0 ? "" : selectedValue,
        status: selectedName,
      });
    }
  };
  handleSelectDate = (e, name) => {
    if (name === "FromDate") {
      let date = moment(e).format();
      let formattedFromDate = date.split("T", 1).toString();

      this.setState({
        fromDate: formattedFromDate,
      });
    }

    if (name === "ToDate") {
      let date = moment(e).format();
      let formattedToDate = date.split("T", 1).toString();

      this.setState({
        toDate: formattedToDate,
      });
    }
  };

  handleSubmit = () => {
    var data = {
      fromDate: moment(this.state.fromDate).format("DD-MM-YYYY"),
      toDate: moment(this.state.toDate).format("DD-MM-YYYY"),
      supplier: this.state.supplier,
      supplierValue: this.state.supplierValue,
      status: this.state.status,
      statusValue: this.state.statusValue,
      mukam:this.state.mukam,
      mukamValue:this.state.mukamValue,
      gateEntryNo:this.state.gateEntryNo,
      mrNo:this.state.mrNo,
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleClear = () => {
    this.setState({
      supplier: "",
      supplierValue: "",
      vehicle: "",
      vehicleValue: "",
      fromDate: "",
      toDate: "",
    });
  };

  render() {
    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid spacing={0}>
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
                {/* <div>Save View</div> */}
              </div>
            </div>
            <Grid item xs={12} className="filterBlockContainer">
              <Box gridColumn="span 3">
                <div className="filterDateBlock">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>From Date</label>
                      </div>
                      {/* <ConsoleDatePicker /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.fromDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "FromDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="fromDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>To Date</label>
                      </div>
                      {/* <ConsoleDatePicker /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.toDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "ToDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="toDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={this.state.supplierListMultiselectData}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handleSelectChange}
                    selected={this.state.supplierValue}
                    update={this.state.supplierValue ? 1 : 0}
                    name="Supplier"
                    label="Supplier"
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <TextFieldNormal
                    value={this.state.mrNo}
                    placeholder={"Enter here"}
                    label="MR Number"
                    caps="OFF"
                    onChange={this.onhandlechange("mrNo")}
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <TextFieldNormal
                    value={this.state.gateEntryNo}
                    placeholder={"Enter here"}
                    label="Gate Entry No"
                    caps="OFF"
                    onChange={this.onhandlechange("gateEntryNo")}
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={this.state.StatusListData}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handleSelectChange}
                    selected={this.state.statusValue}
                    // update={this.state.statusValue ? 1 : 0}
                    name="Status"
                    label="Status"
                  />
                </div>
              </Box>

              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Search"}
                  className="greenButton"
                  handleClick={this.handleSubmit}
                  type="sumbit"
                />
                {/* <CustomButton
                  label={"Clear"}
                  className="greenButton"
                  handleClick={this.handleClear}
                  type="sumbit"
                /> */}
              </div>
            </Grid>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  const { supplierListMultiselect, statusList, mukamList } =
    state.DropDownListReducer;
  // const { VehicleData } = state.MasterDropDownListReducer;
  return { supplierListMultiselect, statusList, mukamList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSupplierListMultiselect,
    getVehicleList,
    getStatusList,
    getMukamList
  })(JutePOFilter)
);
