
import React, { useState } from "react";
import PropTypes from "prop-types";
import { required, special } from "../../Utilities/Validations";

const TextFieldAlfaNumReq = ({
  value,
  label,
  caps,
  placeholder,
  type,
  onChange,
  onKeyDown
}) => {
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    const { values } = event.target;
    if(values){
      setError(special(values, "Invalid Text"));
    }else{
      setError(required(values, "This field is required"));
    }
    if(caps==='ON'){
      onChange(values.toUpperCase()); 
    }else{
      onChange(values); 
    }   
  };
 
  return (
    <div className="TextFieldBlock">
      {label && <label htmlFor="app-input-field">{label}<label className="starcolor">*</label></label>}
      {type === "textarea" ? (
        <textarea
          className= {error ? "TextField errorTextField" : "TextField" } 
          placeholder={placeholder}
          value={value}
          defaultValue={value}
          onChange={handleChange}
          onKeyDown={onKeyDown}
        />
      ) : (
        <input
          type={type}
          value={value}
          className= {error ? "TextField errorTextField" : "TextField" } 
          placeholder={placeholder}
          onChange={handleChange}
          onKeyDown={onKeyDown}
        />
      )}
      {error && <span className="text-danger">{error.message}</span>}
    </div>
  );
};

TextFieldAlfaNumReq.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.array,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

TextFieldAlfaNumReq.defaultProps = {
  value: "",
  label: "",
  placeholder: "",
  type: "text",
  validators: [],
};

export default TextFieldAlfaNumReq;
