import { all, call, fork, takeEvery, put, select } from "redux-saga/effects";
import {
  CREATE_DELIVERY_ORDER,
  CREATE_JUTE_GOVT_DELIVERY_ORDER,
  CREATE_JUTEHESSIAN_DELIVERY_ORDER,
  DELIVERY_ORDER_JUTEHESSIAN_BY_ID,
  DELIVERY_ORDER_LIST,
  GET_ALL_TRANSPORTER,
  ACCOUNT_LIST,
  GET_SO_DROPDOWN,
  JUTE_GOVT_DELIVERY_ORDER_VIEWBY_ID,
  REGULAR_DELIVERY_ORDER_VIEWBY_ID,
  SALES_ORDER_VIEW_BY_ID,
  UPDATE_JUTE_GOVT_DELIVERY_ORDER,
  CREATE_JUTE_YARN_DELIVERY_ORDER,
  JUTE_YARN_DELIVERY_ORDER_VIEW_BY_ID,
  SALES_ORDER_VIEW_BY_ID_FOR_JUTE_YARN,
  UPDATE_JUTE_YARN_DELIVERY_ORDER,
  SALES_ORDER_VIEW_BY_ID_FOR_JUTE_HESSIAN,
  UPDATE_JUTEHESSIAN_DELIVERY_ORDER,
  UPDATE_REGULAR_DELIVERY_ORDER,
  SALES_ORDER_VIEW_BY_ID_FOR_JUTE_GOVT,
  JUTE_GOVT_DELIVERY_ORDER_OTHER_CHARGES,
  DELIVERY_ORDER_ITEMS_BY_ITEM_GROUP_ID,
  JUTE_GOVT_DELORDER_ITEMS_BY_ITEM_GROUP_ID,
  GET_DO_FILES,
  DELETE_DO_FILE,
  UPLOAD_DO_FILES,
} from "./actionTypes";
import {
  DeliveryOrderListSuccessfull,
  getSoDropDownSuccessfull,
  SalesOrderViewByIdSuccessfull,
  getRegularDeliveryOrderHeader,
  getRegularDeliveryOrderLineItems,
  getBankAccountListSuccessfull,
  createDeliveryOrderSuccessfull,
  getAllTransporterSuccessfull,
  RegularDeliveryOrderViewBYIdSuccessfull,
  updateRegularDeliveryOrderSuccessfull,
  getJuteGovtDeliveryOrderHeader,
  JuteGovtDeliveryOrderViewByIdSuccessfull,
  CreateJuteGovtDeliveryOrderSuccessfull,
  UpdateJuteGovtDeliveryOrderSuccessfull,
  getJuteGovtDeliveryOrderLineItems,
  getJuteYarnDeliveryOrderLineItems,
  JuteYarnDeliveryOrderViewByIdSuccessfull,
  UpdateJuteYarnDeliveryOrderSuccessfull,
  CreateJuteYarnDeliveryOrderSuccessfull,
  SalesOrderViewByIdForJuteYarnSuccessfull,
  getJuteYarnDeliveryOrderHeader,
  DeliveryOrderJuteHessianLineItems,
  DeliveryOrderJuteHessianHeaders,
  SalesOrderViewByIdForJuteHessianSuccessfull,
  CreateJuteHessianDeliveryOrderSuccessfull,
  DeliveryOrderJuteHessianByIdSuccessfull,
  UpdateJuteHessianDeliveryOrderSuccessfull,
  SalesOrderViewByIdForJuteGovtSuccessfull,
  JuteGovtDeliveryOrderOtherChargesSuccessfull,
  getDeliveryOrderItemsByItemGroupIdSuccessfull,
  getJuteGovtDelOrderItemsByItemGroupIdSuccessfull, 
  deleteDoFileSuccessfull,
  DOFilesSuccessfull,
  getDOFiles,
} from "./actions";
import { getList, getListurl, postAdd } from "../../../helpers/Server_Helper";
import { hideSpinner, serverApi, showSpinner } from "../../../helpers/Consts";
import { getBillingShippingList } from "../../../store/Global/DropDownApis/actions";
import swal from "sweetalert";
import moment from "moment";
import { convertPrimaryToSecondary, limitDecimals ,convertRatePcsToMt, isEmpty} from "../../../Utilities/helper";
function* UploadedDoFileList({
  payload: { url, data, history, sourceMenu, regDeliveryOrderId },
}) {

  try {
    const response = yield call(postAdd, url, data);
    if (response) {
      swal("Uploaded Successfully", { icon: "success" });
      let Url =
        serverApi.GET_FILES +
        "46/" +
        regDeliveryOrderId +
        "/" +
        localStorage.getItem("companyId");
      yield put(getDOFiles(Url, history));
      yield put(DOFilesSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getAllDoFileList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url, history);
    yield put(DOFilesSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* deleteDoFile({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(deleteDoFileSuccessfull({ response }));
    if (response.data.message == "Success.") {
      swal("Successfully Deleted", { icon: "success" });
    }
  } catch (error) {
    console.log(error);
  }
}
function* getDeliveryOrderListData({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    if (response) {
      hideSpinner();
      yield put(DeliveryOrderListSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getBankDetailsData({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(getBankAccountListSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
    history.push("/s_order");
  } catch (error) {
    console.log(error);
  }
}
function* getSoDropDown({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getSoDropDownSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}
function* SalesOrderDataViewById({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (isEmptyObject(response.data)) {
      swal("Something Went Wrong", { icon: "error" });
    } else {
      if(response.data.customerId !==null){
      var CustomerUrl =
        serverApi.GET_ALL_BILLING_SHIPPING +
        response.data.customerId +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher +
        "?isForSalesModule=1";
      yield put(getBillingShippingList(CustomerUrl, history));
      }
      if (response.data.salesOrderLineItemDTO) {
        if (response.data.salesOrderLineItemDTO.length !== 0) {
          var SalesUpdate = [];
          const FilteredItem = response.data.salesOrderLineItemDTO.filter(
            (item) => {
              return item.doBalQty !== 0 && item.isActive !== 0;
            }
          );
          FilteredItem.map((item, i) => {
            SalesUpdate.push({
              id: i,
              itemGroupId: item.itemGroupId,
              itemGroupValue: item.itemGroupValue,
              itemGroupName: item.itemGroupName,
              itemId: item.itemId,
              itemName: item.itemName,
              itemCode: item.itemCode,
              itemValue: item.itemValue,
              itemMake: item.itemMake,
              remarks: item.remarks,
              hsncode: item.hsncode,
              rate: limitDecimals(item.rate, 3),
              discountedRate: limitDecimals(item.discountedRate, 3),
              quantity: limitDecimals(item.doBalQty, 3),
              uom: item.uom,
              taxPercent: limitDecimals(item.itemTaxPercentage, 2),

              createdBy: item.createdBy,
              createdDate: item.createdDate,
              companyId: item.companyId,
              salesId: response.data.regularSalesId,
              salesLineItemId: item.regularSalesOrderLineItemId,
              invoiceQty: "",
              igstAmount: limitDecimals(
                (item.discountedRate *
                  item.doBalQty *
                  (item.igstPercent == null ? 0 : item.igstPercent)) /
                  100,
                2
              ),
              igstPercent:
                item.igstPercent == null
                  ? "0"
                  : limitDecimals(item.igstPercent, 2),
              cgstAmount: limitDecimals(
                (item.discountedRate *
                  item.doBalQty *
                  (item.cgstPercent == null ? 0 : item.cgstPercent)) /
                  100,
                2
              ),
              cgstPercent:
                item.cgstPercent == null
                  ? "0"
                  : limitDecimals(item.cgstPercent, 2),
              sgstAmount: limitDecimals(
                (item.discountedRate *
                  item.doBalQty *
                  (item.sgstPercent == null ? 0 : item.sgstPercent)) /
                  100,
                2
              ),
              sgstPercent:
                item.sgstPercent == null
                  ? "0"
                  : limitDecimals(item.sgstPercent, 2),
              cessAmount: "",
              discountType: item.discountType,
              discountName: item.discountName,
              discountAmount:limitDecimals(item.discountAmount,2),
              discount: limitDecimals(item.discount, 2),
              netAmount: limitDecimals(item.discountedRate * item.doBalQty, 2),
              totalAmount: limitDecimals(item.totalAmount,2),
              balesPacketSlNo: "",
              status: item.status,
              isActive: item.isActive,
              currency: "INR",
              doBalQty: limitDecimals(item.doBalQty, 3),
              ItemNameData: [],
            });
          });
          yield put(getRegularDeliveryOrderLineItems(SalesUpdate));
        }
      }
      var TotalCGST = SalesUpdate.map((p) =>
        p.cgstAmount == null ? 0 : p.cgstAmount
      ).reduce((cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind));

      var TotalSGST = SalesUpdate.map((p) =>
        p.sgstAmount == null ? 0 : p.sgstAmount
      ).reduce((sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind));

      var TotalIGST = SalesUpdate.map((p) =>
        p.igstAmount == null ? 0 : p.igstAmount
      ).reduce((igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind));

      var netAmount = SalesUpdate.map((p) =>
        p.netAmount == null ? 0 : p.netAmount
      ).reduce((netAmount, ind) => parseFloat(netAmount) + parseFloat(ind));

      var taxTotals = Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);

      var createddate =
        response.data.createdDate !== null &&
        response.data.createdDate.split("T");
      var salesdate =
        response.data.salesDate !== null && response.data.salesDate.split("T");

      const Header = {
        salesOrderId: response.data.regularSalesId,
        salesOrderDate: salesdate[0],
        invoiceType: response.data.invoiceTypeId,
        branch: response.data.branchId,
        branchName: response.data.branchName,
        deliveryDate:null,
        salesOrderNumber: response.data.salesNo,
        quotationNumber: response.data.quotationSalesOrderNo,
        quotationDate: response.data.quotationDate,
        vehicleNo: "",
        finYear: response.data.finYear,
        createdBy: response.data.createdBy,
        createdDate: createddate[0],
        company: response.data.companyId,
        brokerId: response.data.brokerId,
        brokerName: response.data.brokerName,
        bankId:response.data.bankId,
        bankBranch:response.data.bankBranch,
        bankName:response.data.bankName,
        ifscCode:response.data.ifscCode,
        accountNo:response.data.accountNo,
        micrCode: response.data.micrCode,
        brokerCommisionPercent: response.data.brokerCommisionPercent,
        customerId: response.data.customerId,
        customerName: response.data.customerName,
        customerResponse:response.data.customerId===null ? true:false,

        customerDisable:
          response.data.customerId !== "" && response.data.customerName !== null
            ? true
            : false,
        billingDisbale:
          response.data.billingTo !== "" && response.data.billingTo !== null
            ? true
            : false,
        shippingDisable:
          response.data.shippingTo !== "" && response.data.shippingTo !== null
            ? true
            : false,

        brokerContactNumber: response.data.brokerContactNumber,
        brokerContactPerson: response.data.brokerContactPerson,
        consigneeContactNumber: response.data.consigneeContactNumber,
        consigneeContactPerson: response.data.consigneeContactPerson,

        billingTo: response.data.billingTo,
        billingToAddress: response.data.billingToAddress,
        billingToGST: response.data.billedToGstin,
        billingContactNo: response.data.billedToContactNumber,
        billingContactPerson: response.data.billedToContactPerson,
        billingToEmail: response.data.billedToEmail,
        billingState: response.data.billedToState ? response.data.billedToState:"" ,

        shippingTo: response.data.shippingTo ? response.data.shippingTo:"" ,
        shippingToAddress: response.data.shippingToAddress,
        shippingToGST: response.data.shippedToGstin,
        shippingContactNo: response.data.shippedToContactNumber,
        shippingContactPerson: response.data.shippedToContactPerson,
        shippingToEmail: response.data.shippedToEmail,
        shippingState: response.data.shippedToState ? response.data.shippedToState:"",
        termsConditionType: response.data.termsConditionType,
        disabledCustomer: response.data.customerId !== null ? true : false,
        disableBroker:response.data.brokerId !== null ? true : false,
        transporter: response.data.transporterId,
        transporterName: response.data.transporterName,
        transporterAddress: response.data.transporterAddress,
        transporterStateCode: response.data.transporterStateCode,
        transporterStateName: response.data.transporterStateName,
        transporterGst: response.data.transporterGst,
        status: response.data.statusId,
        statusName: response.data.statusName,
        challanNo: response.data.challanNo,
        challanDate: response.data.challanDate,
        taxType: response.data.taxType,
        footerNote: response.data.footerNote,
        internalNote: response.data.internalNote,
        termsCondition: response.data.termsCondition,
        deliveryTerms: response.data.deliveryTerms,
        paymentTerms: response.data.paymentTerms,
        deliveryDays: response.data.deliveryDays,
        freightCharges: response.data.freightCharges,
        amountWithoutTax: response.data.amountWithoutTax,
        amountWithTax: response.data.amountWithTax,
        updatedBy: response.data.updatedBy,
        updatedByDesc: response.data.updatedByDesc,
        updatedOn: response.data.updatedOn,
        approvedBy: response.data.approvedBy,
        approvedBydesc: response.data.approvedBydesc,
        approvedOn: response.data.approvedOn,
        totalIGST:
          TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
        totalCGST:
          TotalCGST == null ? "0.00" : parseFloat(TotalCGST).toFixed(2),
        totalSGST:
          TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),
        subTotal: parseFloat(netAmount).toFixed(2),
        taxTotal: parseFloat(taxTotals).toFixed(2),
        netTotal: parseFloat(netAmount).toFixed(2),
        TotalAmount: parseFloat(Number(netAmount) + Number(taxTotals)).toFixed(2),
              };
      yield put(getRegularDeliveryOrderHeader(Header));
    }
    yield put(SalesOrderViewByIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* setDeliveryOrder({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    if (response) {
      hideSpinner();
      yield put(createDeliveryOrderSuccessfull({ response }));
      swal(response.data.message, { icon: "success" });
      history.push("/delivery_order");
    }
  } catch (error) {
    console.log(error);
  }
}
function* getTransporterData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getAllTransporterSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* RegularDeliveryOrderViewBYIdData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (isEmptyObject(response.data)) {
      swal("Something Went Wrong");
    } else {
      var CustomerUrl =
        serverApi.GET_ALL_BILLING_SHIPPING +
        response.data.customerId +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher +
        "?isForSalesModule=1";
      yield put(getBillingShippingList(CustomerUrl, history));
      if (response.data.regularDeliveryOrderLineItemsDTO) {
        if (response.data.regularDeliveryOrderLineItemsDTO.length !== 0) {
          var SalesUpdate = [];
          response.data.regularDeliveryOrderLineItemsDTO.map((item, i) => {
            var createddate =
              item.createdDate !== null && item.createdDate.split("T");
            SalesUpdate.push({
              id: i,
              itemGroupId: item.itemGroupId,
              itemGroupValue: item.itemGroupValue,
              itemGroupName: item.itemGroupName,
              itemId: item.itemId,
              itemName: item.itemName,
              itemCode: item.itemCode,
              itemValue: item.itemValue,
              itemMake: item.itemMake,
              remarks: item.remarks,
              hsncode: item.hsncode,
              rate: limitDecimals(item.rate, 3),
              discountedRate: item.discountedRate,
              quantity: limitDecimals(item.quantity, 3),
              uom: item.uom,
              taxPercent: limitDecimals(item.itemTaxPercentage, 2),
              createdBy: item.createdBy,
              createdDate: createddate[0],
              companyId: item.companyId,
              salesId: response.data.salesOrderId,
              salesLineItemId: item.salesLineItemId,
              invoiceQty: "",
              igstAmount:
                item.igstAmount == null
                  ? "0.00"
                  : limitDecimals(item.igstAmount, 2),
              igstPercent:
                item.igstPercent == null
                  ? "0"
                  : limitDecimals(item.igstPercent, 2),
              cgstAmount:
                item.cgstAmount == null
                  ? "0.00"
                  : limitDecimals(item.cgstAmount, 2),
              cgstPercent:
                item.cgstPercent == null
                  ? "0"
                  : limitDecimals(item.cgstPercent, 2),
              sgstAmount:
                item.sgstAmount == null
                  ? "0.00"
                  : limitDecimals(item.sgstAmount, 2),
              sgstPercent:
                item.sgstPercent == null
                  ? "0"
                  : limitDecimals(item.sgstPercent, 2),
              cessAmount: "",
              discountType: item.discountType,
              discountName: item.discountName,
              discountAmount:limitDecimals(item.discountAmount,2),
              discount: limitDecimals(item.discount, 2),
              netAmount: limitDecimals(item.netAmount, 2),
              totalAmount: limitDecimals(item.totalAmount, 2),
              balesPacketSlNo: "",
              status: item.status,
              isActive: item.isActive,
              currency: "INR",
              regularDeliveryOrderLineItemId:
                item.regularDeliveryOrderLineItemId,
              regDeliveryOrderId: response.data.regDeliveryOrderId,
              updatedBy: null,
              updatedOn: null,
              doBalQty: parseFloat(item.quantity + item.doBalQty).toFixed(3),
              ItemNameData: [],
            });
          });
          yield put(getRegularDeliveryOrderLineItems(SalesUpdate));
        }
      }
      var TotalCGST = response.data.regularDeliveryOrderLineItemsDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
        .reduce((cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind));
      var TotalSGST = response.data.regularDeliveryOrderLineItemsDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.sgstAmount == null ? 0 : p.sgstAmount))
        .reduce((sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind));
      var TotalIGST = response.data.regularDeliveryOrderLineItemsDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.igstAmount == null ? 0 : p.igstAmount))
        .reduce((igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind));
      var netAmount = response.data.regularDeliveryOrderLineItemsDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => p.netAmount)
        .reduce(
          (netAmount, ind) => parseFloat(netAmount) + parseFloat(ind)
        );
      var taxTotals = Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);

      var createddate =
        response.data.createdDate !== null &&
        response.data.createdDate.split("T");
      var salesdate =
        response.data.salesOrderDate !== null &&
        response.data.salesOrderDate.split(" ");
      var deliveryDate =
        response.data.deliveryDate !== null &&
        response.data.deliveryDate.split("T");
      var quotationDate =
        response.data.quotationDate !== null &&
        response.data.quotationDate.split("T");
      var printedOn =
        response.data.printedOn !== null && response.data.printedOn.split("T");

      var ApproveButton = false;
      var RejectButton = false;
      var UpdateButton = true;
      var EditButton = false;

      if (response.data.approveStatus === true) {
        if (
          response.data.status === 1 ||
          response.data.status === 17 ||
          response.data.status === 18 ||
          response.data.status === 19 ||
          response.data.status === 20
        ) {
          ApproveButton = true;
          RejectButton = true;
          EditButton = true;
          UpdateButton = true;
        }
      } else if (
        response.data.status === 1 ||
        response.data.status === 17 ||
        response.data.status === 18 ||
        response.data.status === 19 ||
        response.data.status === 20
      ) {
        EditButton = true;
        UpdateButton = true;
      }

      if (
        response.data.status === 4 ||
        response.data.status === 3 ||
        response.data.status === 5
      ) {
        ApproveButton = false;
        RejectButton = false;
        UpdateButton = false;
      }

      const updatedOn =
        response.data.updatedOn !== null
          ? response.data.updatedOn.split("T")
          : "";

      const Header = {
        salesOrderId: response.data.salesOrderId,
        salesOrderDate: salesdate[0],
        invoiceType: response.data.invoiceType,
        branch: response.data.branch,
        branchName: response.data.branchName,
        deliveryDate: deliveryDate[0],
        salesOrderNumber: response.data.salesOrderNumber,
        quotationNumber: response.data.quotationNumber,
        quotationDate: response.data.quotationDate,
        vehicleNo: response.data.vehicleNumber,
        finYear: response.data.finYear,
        createdBy: response.data.createdBy,
        createdDate: createddate[0],
        company: response.data.company,
        bankId:response.data.bankId,
        bankBranch:response.data.bankBranch,
        bankName:response.data.bankName,
        accountNo:response.data.accountNo,
        ifscCode:response.data.ifscCode,      
        micrCode: response.data.micrCode,
        brokerId: response.data.brokerId,
        brokerName: response.data.brokerName,
        brokerCommisionPercent: response.data.brokerCommisionPercent,
        customerId: response.data.customerId,
        customerName: response.data.customerName,
        disabledCustomer: response.data.customerId !== null ? true : false,
        disableBroker:response.data.brokerId !== null ? true : false,
        brokerContactNumber: response.data.brokerContactNumber,
        brokerContactPerson: response.data.brokerContactPerson,
        consigneeContactNumber: response.data.consigneeContactNumber,
        consigneeContactPerson: response.data.consigneeContactPerson,
        billingTo: response.data.billingTo,
        billingToAddress: response.data.billingToAddress,
        billingToGST: response.data.billedToGstin,
        billingContactNo: response.data.billedToContactNumber,
        billingContactPerson: response.data.billedToContactPerson,
        billingToEmail: response.data.billedToEmail,
        billingState: response.data.billingStateName,
        shippingTo: response.data.shippingTo,
        shippingToAddress: response.data.shippingToAddress,
        shippingToGST: response.data.shippedToGstin,
        shippingContactNo: response.data.shippedToContactNumber,
        shippingContactPerson: response.data.shippedToContactPerson,
        shippingToEmail: response.data.shippedToEmail,
        shippingState: response.data.shippingStateName,
        termsCondition: response.data.termsCondition,
        termsConditionType: response.data.termsConditionType,
        transporter: response.data.transporter,
        transporterName: response.data.transporterName,
        transporterAddress: response.data.transporterAddress,
        transporterStateCode: response.data.transporterStateCode,
        transporterStateName: response.data.transporterStateName,
        transporterGst: response.data.transporterGst,
        status: response.data.status,
        challanNo: response.data.challanNo,
        challanDate: response.data.challanDate,
        taxType: response.data.taxType,
        footerNote: response.data.footerNote,
        internalNote: response.data.internalNote,
        deliveryTerms: response.data.deliveryTerms,
        paymentTerms: response.data.paymentTerms,
        deliveryDays: response.data.deliveryDays,
        freightCharges: response.data.freightCharges,
        amountWithoutTax: response.data.amountWithoutTax,
        amountWithTax: response.data.amountWithTax,
        updatedBy: response.data.updatedBy,
        updatedOn:
          updatedOn !== "" ? moment(updatedOn[0]).format("DD-MM-YYYY") : "",
        updatedByDesc: response.data.updatedByDesc,
        approvedBy: response.data.approvedBy,
        approvedOn: response.data.approvedOn,
        approvedBydesc: response.data.approvedBydesc,
        isActive: response.data.isActive,
        regDeliveryOrderId: response.data.regDeliveryOrderId,
        deliveryOrderNo: response.data.deliveryOrderNo,
        createdByDesc: response.data.createdByDesc,
        invoiceTypeName: response.data.invoiceTypeName,
        printedOn: printedOn[0],
        taxName: response.data.taxName,
        approveStatus: response.data.approveStatus,
        totalIGST:
          TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
        totalCGST:
          TotalCGST == null ? "0.00" : parseFloat(TotalCGST).toFixed(2),
        totalSGST:
          TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),
        subTotal: parseFloat(netAmount).toFixed(2),
        taxTotal: parseFloat(taxTotals).toFixed(2),
        netTotal: parseFloat(netAmount).toFixed(2),
        TotalAmount: parseFloat(Number(netAmount) + Number(taxTotals)).toFixed(2),
        ApproveButton: ApproveButton,
        RejectButton: RejectButton,
        UpdateButton: UpdateButton,
        EditButton: EditButton,
        statusName: response.data.statusName,
              };
      yield put(getRegularDeliveryOrderHeader(Header));
          }
    yield put(RegularDeliveryOrderViewBYIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* UpdateDeliveryOrderData({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    if (response) {
      hideSpinner();
      yield put(updateRegularDeliveryOrderSuccessfull({ response }));
      swal(response.data.message, { icon: "success" });
      history.push("/delivery_order");
    }
  } catch (error) {
    console.log(error);
  }
}

function* getDeliveryOrderItemByItemGroupData({
  payload: { url, data, history, rowId },
}) {
  const { RegularDeliveryOrderLineItems } = yield select(
    (state) => state.DeliveryOrderReducer
  );
  try {
    const response = yield call(getList, url, data);
    if (response) {
      yield put(getDeliveryOrderItemsByItemGroupIdSuccessfull({ response }));
      if (response) {
        let ListFilter = response.filter((data) => {
          if (data.value !== "0") {
            return data;
          }
        });
        var list = [];
        ListFilter.map((prop) => {
          list.push({
            label: prop.label,
            name: prop.name,
            value: prop.value,
            variant: prop.itemCode,
          });
        });
      }
    }
    if (RegularDeliveryOrderLineItems) {
      var LineItemsRecords = [];
      if (RegularDeliveryOrderLineItems.length !== 0) {
        LineItemsRecords = RegularDeliveryOrderLineItems.map((item) => {
          if (rowId == item.id) {
            item.ItemNameData = list;
          }
          return item;
        });
        yield put(getRegularDeliveryOrderLineItems(LineItemsRecords));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

//Jute Hessian---------------------

function* SalesOrderDataViewByIdForJuteHessian({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (isEmptyObject(response.data)) {
      swal("Something Went Wrong");
    } else {
      if(response.data.salesOrderDTO.customerId !==null){
        var CustomerUrl =
        serverApi.GET_ALL_BILLING_SHIPPING +
        response.data.salesOrderDTO.customerId +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher +
        "?isForSalesModule=1";
        yield put(getBillingShippingList(CustomerUrl, history));
      }
   
      if (response.data.salesOrderDTO.salesOrderLineItemDTO) {
        if (response.data.salesOrderDTO.salesOrderLineItemDTO.length !== 0) {
          var SalesUpdate = [];

          const FilteredItem = response.data.salesOrderDTO.salesOrderLineItemDTO.filter(
            (item) => {
              return item.doBalQty !== 0 && item.isActive !== 0;
            }
          );
          
          FilteredItem.map((item, i) => {
            let calculatedPryToSecValue = convertPrimaryToSecondary(item.doBalQty,item.hessianQuantityRelationValue) 
          let calculatedRatePcsToMt = convertRatePcsToMt(item.hessianRateIncludingBrokerage,item.hessianRateRelationValue)
            let netAmount= limitDecimals(Number(item.doBalQty)*Number(item.hessianRateIncludingBrokerage))
            SalesUpdate.push({
              id: i,
              itemGroupId: item.itemGroupId,
              itemGroupValue: item.itemGroupValue,
              itemGroupName: item.itemGroupName,
              itemId: item.itemId,
              itemName: item.itemName,
              itemCode: item.itemCode,
              itemValue: item.itemValue,
              itemMake: item.itemMake,
              remarks: item.remarks,
              hsncode: item.hsncode,
              rate:limitDecimals(item.hessianRateIncludingBrokerage,3),
              discountedRate: limitDecimals(item.discountedRate,3),
              quantity: limitDecimals(item.doBalQty,3),
              uom: item.uom,
              taxPercent: limitDecimals(item.itemTaxPercentage, 2),
              createdBy: item.createdBy,
              createdDate: item.createdDate,
              companyId: item.companyId,
              salesId: response.data.salesOrderDTO.regularSalesId,
              salesLineItemId: item.regularSalesOrderLineItemId,
              invoiceQty: "",
              igstAmount :limitDecimals((netAmount)*(item.igstPercent ==null?0:item.igstPercent)/100,2),
              igstPercent: item.igstPercent == null ? "0" : item.igstPercent,
              cgstAmount :limitDecimals((netAmount)*  (item.cgstPercent ==null?0:item.cgstPercent)/100,2),
              cgstPercent: item.cgstPercent == null ? "0" : item.cgstPercent,
              sgstAmount :limitDecimals((netAmount)*(item.sgstPercent ==null?0:item.sgstPercent)/100,2),
              sgstPercent: item.sgstPercent == null ? "0" : item.sgstPercent,
              cessAmount: "",
              discountType: item.discountType,
              discountName: item.discountName,
              discountAmount:limitDecimals(item.discountAmount,2),
              discount: item.discount,
              netAmount:limitDecimals(netAmount),
              totalAmount:limitDecimals(item.totalAmount,2),
              balesPacketSlNo: "",
              status: item.status,
              isActive: item.isActive,
              hessianIsActive:item.hessianIsActive,
              currency: "INR",
              doBalQty: limitDecimals(item.doBalQty,3),
              hessianRateIncludingBrokerage:limitDecimals(item.hessianRateIncludingBrokerage,3),
              hessianAmountExcludingBrokerage:limitDecimals(item.hessianAmountExcludingBrokerage,3),
              hessianQuantityUom: item.hessianQuantityUom,
              hessianQuantityUom2: item.hessianQuantityUom2,
              hessianQuantityUomId:item.hessianQuantityUomId,
              hessianQuantityRelationValue:item.hessianQuantityRelationValue,
              hessianQuantityCalculatedValue:limitDecimals(calculatedPryToSecValue,3),
              hessianRateUom:item.hessianRateUom,
              hessianRateUomId:item.hessianRateUomId,
              hessianRateRelationValue: item.hessianRateRelationValue,
              hessianRateCalculatedValue:limitDecimals(calculatedRatePcsToMt,3),
            });
          });
          yield put(DeliveryOrderJuteHessianLineItems(SalesUpdate));
        }
      }
      var TotalCGST = SalesUpdate.map((p) =>
      p.cgstAmount == null ? 0 : p.cgstAmount
    ).reduce((cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind));

      var TotalSGST = SalesUpdate.map((p) =>
      p.sgstAmount == null ? 0 : p.sgstAmount
    ).reduce((sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind));

  
      var TotalIGST = SalesUpdate.map((p) =>
      p.igstAmount == null ? 0 : p.igstAmount
    ).reduce((igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind));

 
      var netAmount = SalesUpdate.map((p) =>
      p.netAmount == null ? 0 : p.netAmount
    ).reduce((netAmount, ind) => parseFloat(netAmount) + parseFloat(ind));
      var taxTotals = Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);

      var createddate =
        response.data.salesOrderDTO.createdDate !== null &&
        response.data.salesOrderDTO.createdDate.split("T");
      var salesdate =
        response.data.salesOrderDTO.salesDate !== null && response.data.salesOrderDTO.salesDate.split("T");

      const Header = {
        deliveryOrderNo:null,
        salesOrderId: response.data.salesOrderDTO.regularSalesId,
        salesOrderDate: salesdate[0],
        invoiceType: response.data.salesOrderDTO.invoiceTypeId,
        branch: response.data.salesOrderDTO.branchId,
        branchName: response.data.salesOrderDTO.branchName,
        deliveryDate: null,
        salesOrderNumber: response.data.salesOrderDTO.salesNo,
        quotationNumber: response.data.salesOrderDTO.quotationSalesOrderNo,
        quotationDate: response.data.salesOrderDTO.quotationDate,
        vehicleNo: "",
        finYear: response.data.salesOrderDTO.finYear,
        createdBy: response.data.salesOrderDTO.createdBy,
        createdDate: createddate[0],
        company: response.data.salesOrderDTO.companyId,
        brokerId: response.data.salesOrderDTO.brokerId,
        brokerName: response.data.salesOrderDTO.brokerName,
        bankId:response.data.salesOrderDTO.bankId,
        bankBranch:response.data.salesOrderDTO.bankBranch,
        bankName:response.data.salesOrderDTO.bankName,
        accountNo:response.data.salesOrderDTO.accountNo,
        ifscCode:response.data.salesOrderDTO.ifscCode,
        micrCode: response.data.salesOrderDTO.micrCode,
        customerId: response.data.salesOrderDTO.customerId,
        customerName: response.data.salesOrderDTO.customerName,
        customerResponse:isEmpty(response.data.salesOrderDTO.customerId) ? true:false,
        disableBroker: response.data.salesOrderDTO.brokerId !==null?true:false,
        disableCustomer: response.data.salesOrderDTO.customerId !==null?true:false,
        brokerCommisionPercent:response.data.salesOrderDTO.brokerCommisionPercent,
        brokerContactNumber: response.data.salesOrderDTO.brokerContactNumber,
        brokerContactPerson: response.data.salesOrderDTO.brokerContactPerson,
        consigneeContactNumber: response.data.salesOrderDTO.consigneeContactNumber,
        consigneeContactPerson: response.data.salesOrderDTO.consigneeContactPerson,

        billingTo: response.data.salesOrderDTO.billingTo,
        billingToAddress: response.data.salesOrderDTO.billingToAddress,
        billingToGST: response.data.salesOrderDTO.billedToGstin,
        billingContactNo: response.data.salesOrderDTO.billedToContactNumber,
        billingContactPerson: response.data.salesOrderDTO.billedToContactPerson,
        billingToEmail: response.data.salesOrderDTO.billedToContactPerson,
        billingState: response.data.salesOrderDTO.billingToState,

        shippingTo: response.data.salesOrderDTO.shippingTo,
        shippingToAddress: response.data.salesOrderDTO.shippingToAddress,
        shippingToGST: response.data.salesOrderDTO.shippedToGstin,
        shippingContactNo: response.data.salesOrderDTO.shippedToContactNumber,
        shippingContactPerson: response.data.salesOrderDTO.shippedToContactPerson,
        shippingToEmail: response.data.salesOrderDTO.shippedToEmail,
        shippingState: response.data.salesOrderDTO.shippingToState,
        termsConditionType: response.data.salesOrderDTO.termsConditionType,

        transporter: response.data.salesOrderDTO.transporterId,
        transporterName: response.data.salesOrderDTO.transporterName,
        transporterAddress: response.data.salesOrderDTO.transporterAddress,
        transporterStateCode: response.data.salesOrderDTO.transporterStateCode,
        transporterStateName: response.data.salesOrderDTO.transporterStateName,
        status: response.data.salesOrderDTO.statusId,
        statusName: response.data.salesOrderDTO.statusName,
        challanNo: response.data.salesOrderDTO.challanNo,
        challanDate: response.data.salesOrderDTO.challanDate,
        taxType: response.data.salesOrderDTO.taxType,
        footerNote: response.data.salesOrderDTO.footerNote,
        internalNote: response.data.salesOrderDTO.internalNote,
        termsCondition: response.data.salesOrderDTO.termsCondition,
        deliveryTerms: response.data.salesOrderDTO.deliveryTerms,
        paymentTerms: response.data.salesOrderDTO.paymentTerms,
        deliveryDays: response.data.salesOrderDTO.deliveryDays,
        freightCharges: response.data.salesOrderDTO.freightCharges,
        amountWithoutTax: response.data.salesOrderDTO.amountWithoutTax,
        amountWithTax: response.data.salesOrderDTO.amountWithTax,
        updatedBy: response.data.salesOrderDTO.updatedBy,
        updatedByDesc: response.data.salesOrderDTO.updatedByDesc,
        updatedOn: response.data.salesOrderDTO.updatedOn,
        approvedBy: response.data.salesOrderDTO.approvedBy,
        approvedBydesc: response.data.salesOrderDTO.approvedBydesc,
        approvedOn: response.data.salesOrderDTO.approvedOn,
        totalIGST:
          TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
        totalCGST:
          TotalCGST == null ? "0.00" : parseFloat(TotalCGST).toFixed(2),
        totalSGST:
          TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),
        subTotal: parseFloat(netAmount).toFixed(2),
        taxTotal: parseFloat(taxTotals).toFixed(2),
        netTotal: parseFloat(netAmount).toFixed(2),
        TotalAmount: parseFloat(Number(netAmount) + Number(taxTotals)).toFixed(2),
      };
      yield put(DeliveryOrderJuteHessianHeaders(Header));
    }
    yield put(SalesOrderViewByIdForJuteHessianSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* CreateDeliveryOrderForJuteHessian({
  payload: { url, data, history },
}) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(CreateJuteHessianDeliveryOrderSuccessfull({ response }));
    if(response.data.status){
    hideSpinner()
    swal(response.data.message, { icon: "success" });
    history.push("/delivery_order");
    }
  } catch (error) {
    console.log(error);
  }
}

function* UpdateDeliveryOrderForJuteHessian({
  payload: { url, data, history },
}) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(UpdateJuteHessianDeliveryOrderSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
    history.push("/delivery_order");
  } catch (error) {
    console.log(error);
  }
}

// Jute Hessian
function* JuteHessianDeliveryOrderViewBYIdData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (isEmptyObject(response.data)) {
      swal("Something Went Wrong");
    } else {
      var CustomerUrl =
        serverApi.GET_ALL_BILLING_SHIPPING +
        response.data.deliveryOrderDTO.customerId +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher +
        "?isForSalesModule=1";
      yield put(getBillingShippingList(CustomerUrl, history));
      if (response.data.deliveryOrderDTO.deliveryOrderLineItemDTO) {
        if (
          response.data.deliveryOrderDTO.deliveryOrderLineItemDTO.length !== 0
        ) {
          var DeliveryUpdate = [];
          response.data.deliveryOrderDTO.deliveryOrderLineItemDTO.map(
            (item, i) => {
              var createddate =
                item.createdDate !== null && item.createdDate.split("T");
              DeliveryUpdate.push({
                id: i,
                deliveryOrderNo: item.deliveryOrderNo,
                regularDeliveryOrderLineItemId:
                  item.regularDeliveryOrderLineItemId,
                itemGroupId: item.itemGroupId,
                itemGroupValue: item.itemGroupValue,
                itemGroupName: item.itemGroupName,
                itemId: item.itemId,
                itemName: item.itemName,
                itemCode: item.itemCode,
                itemValue: item.itemValue,
                itemMake: item.itemMake,
                remarks: item.remarks,
                hsncode: item.hsncode,
                rate: limitDecimals(item.rate,3),
                discountedRate: limitDecimals(item.discountedRate,3),
                quantity:limitDecimals(item.quantity,3),
                uom: item.uom,
                taxPercent: limitDecimals(item.itemTaxPercentage, 2),
                createdBy: item.createdBy,
                createdDate: createddate[0],
                companyId: item.companyId,
                salesId: response.data.deliveryOrderDTO.salesOrderId,
                salesLineItemId: item.salesLineItemId,
                invoiceQty: "",
                igstAmount: item.igstAmount == null ? "0.00" :limitDecimals(item.igstAmount,2),
                igstPercent: item.igstPercent == null ? "0" :limitDecimals(item.igstPercent,2),
                cgstAmount: item.cgstAmount == null ? "0.00" :limitDecimals(item.cgstAmount,2),
                cgstPercent: item.cgstPercent == null ? "0" :limitDecimals(item.cgstPercent,2),
                sgstAmount: item.sgstAmount == null ? "0.00" : limitDecimals(item.sgstAmount,2),
                sgstPercent: item.sgstPercent == null ? "0" : limitDecimals(item.sgstPercent,2),
                cessAmount: "",
                discountType: item.discountType,
                discountName: item.discountName,
                discountAmount:limitDecimals(item.discountAmount,2),
                discount:limitDecimals(item.discount,2),
                netAmount: limitDecimals(item.netAmount,2),
                totalAmount:limitDecimals(item.totalAmount,2),
                balesPacketSlNo: "",
                status: item.status,
                isActive: item.isActive,
                hessianIsActive:item.hessianIsActive,
                currency: "INR",
                doBalQty:parseFloat(item.quantity + item.doBalQty).toFixed(3),
                deliveryOrderLineItemId: item.deliveryOrderLineItemId,
                regDeliveryOrderId:
                  response.data.deliveryOrderDTO.regDeliveryOrderId,
                hessianDeliveryOrderLineItemId:
                  item.hessianDeliveryOrderLineItemId,
                hessianDeliveryOrderId: item.hessianDeliveryOrderId,
                hessianRateIncludingBrokerage:limitDecimals(item.hessianRateIncludingBrokerage,3),
                hessianRateExcludingBrokerage:limitDecimals(item.hessianRateExcludingBrokerage,3),
                hessianBalesPacketSlNo: item.hessianBalesPacketSlNo,
                hessianQuantityUom: item.hessianQuantityUom,
                hessianQuantityUom2: item.hessianQuantityUom2,
                hessianQuantityUomId:item.hessianQuantityUomId,
                hessianQuantityRelationValue:item.hessianQuantityRelationValue,
                hessianQuantityCalculatedValue: limitDecimals(item.hessianQuantityCalculatedValue,3),
                hessianRateUom:item.hessianRateUom,
                hessianRateUomId:item.hessianRateUomId,
                hessianRateRelationValue:item.hessianRateRelationValue,
                hessianRateCalculatedValue:limitDecimals(item.hessianRateCalculatedValue,3),
                updatedBy: null,
                updatedOn: null,
              });
            }
          );
          yield put(DeliveryOrderJuteHessianLineItems(DeliveryUpdate));
        }
      }
      var TotalCGST = response.data.deliveryOrderDTO.deliveryOrderLineItemDTO
        .filter((item) => item.isActive != 0)
        .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
        .reduce((cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind));

      var TotalSGST = response.data.deliveryOrderDTO.deliveryOrderLineItemDTO
        .filter((item) => item.isActive != 0)
        .map((p) => (p.sgstAmount == null ? 0 : p.sgstAmount))
        .reduce((sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind));

      var TotalIGST = response.data.deliveryOrderDTO.deliveryOrderLineItemDTO
        .filter((item) => item.isActive != 0)
        .map((p) => (p.igstAmount == null ? 0 : p.igstAmount))
        .reduce((igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind));

      var netAmount = response.data.deliveryOrderDTO.deliveryOrderLineItemDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => p.netAmount)
        .reduce(
          (netAmount, ind) => parseFloat(netAmount) + parseFloat(ind)
        );

        var taxTotals = Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);

      var createddate =
        response.data.deliveryOrderDTO.createdDate !== null &&
        response.data.deliveryOrderDTO.createdDate.split("T");
      var salesdate =
        response.data.deliveryOrderDTO.salesOrderDate !== null &&
        response.data.deliveryOrderDTO.salesOrderDate.split(" ");
      var deliveryDate =
        response.data.deliveryOrderDTO.deliveryDate !== null &&
        response.data.deliveryOrderDTO.deliveryDate.split("T");
      var quotationDate =
        response.data.deliveryOrderDTO.quotationDate !== null &&
        response.data.deliveryOrderDTO.quotationDate.split("T");
      var printedOn =
        response.data.deliveryOrderDTO.printedOn !== null &&
        response.data.deliveryOrderDTO.printedOn.split("T");

      var ApproveButton = false;
      var RejectButton = false;
      var UpdateButton = true;
      var EditButton = false;

      if (response.data.deliveryOrderDTO.approveStatus === true) {
        if (
          response.data.deliveryOrderDTO.status === 1 ||
          response.data.deliveryOrderDTO.status === 17 ||
          response.data.deliveryOrderDTO.status === 18 ||
          response.data.deliveryOrderDTO.status === 19 ||
          response.data.deliveryOrderDTO.status === 20
        ) {
          ApproveButton = true;
          RejectButton = true;
          UpdateButton = true;
          EditButton = true;
        }
      } else if (
        response.data.deliveryOrderDTO.status === 1 ||
        response.data.deliveryOrderDTO.status === 17 ||
        response.data.deliveryOrderDTO.status === 18 ||
        response.data.deliveryOrderDTO.status === 19 ||
        response.data.deliveryOrderDTO.status === 20
      ) {
        UpdateButton = true;
        EditButton = true;
      }

      if (
        response.data.deliveryOrderDTO.status === 4 ||
        response.data.deliveryOrderDTO.status === 3 ||
        response.data.deliveryOrderDTO.status==5
      ) {
        ApproveButton = false;
        RejectButton = false;
        UpdateButton = false;
        EditButton = false;
      }

      const Header = {
        salesOrderId: response.data.deliveryOrderDTO.salesOrderId,
        salesOrderDate: salesdate[0],
        invoiceType: response.data.deliveryOrderDTO.invoiceType,
        branch: response.data.deliveryOrderDTO.branch,
        branchName: response.data.deliveryOrderDTO.branchName,
        deliveryDate: deliveryDate[0],
        salesOrderNumber: response.data.deliveryOrderDTO.salesOrderNumber,
        quotationNumber: response.data.deliveryOrderDTO.quotationNumber,
        quotationDate: quotationDate[0],
        vehicleNo: response.data.deliveryOrderDTO.vehicleNumber,
        finYear: response.data.deliveryOrderDTO.finYear,
        createdBy: response.data.deliveryOrderDTO.createdBy,
        createdDate: createddate[0],
        company: response.data.deliveryOrderDTO.company,
        brokerId: response.data.deliveryOrderDTO.brokerId,
        disableBroker: response.data.deliveryOrderDTO.brokerId !==null?true:false,
        disableCustomer: response.data.deliveryOrderDTO.customerId !==null?true:false,
        brokerName: response.data.deliveryOrderDTO.brokerName,
        bankId:response.data.deliveryOrderDTO.bankId,
        bankBranch:response.data.deliveryOrderDTO.bankBranch,
        bankName:response.data.deliveryOrderDTO.bankName,
        ifscCode:response.data.deliveryOrderDTO.ifscCode,
        accountNo:response.data.deliveryOrderDTO.accountNo,
        micrCode: response.data.deliveryOrderDTO.micrCode,
        customerId: response.data.deliveryOrderDTO.customerId,
        customerName: response.data.deliveryOrderDTO.customerName,
        brokerContactNumber: response.data.deliveryOrderDTO.brokerContactNumber,
        brokerContactPerson: response.data.deliveryOrderDTO.brokerContactPerson,
        brokerCommisionPercent:response.data.deliveryOrderDTO.brokerCommisionPercent,
        consigneeContactNumber:
          response.data.deliveryOrderDTO.consigneeContactNumber,
        consigneeContactPerson:
          response.data.deliveryOrderDTO.consigneeContactPerson,
        billingTo: response.data.deliveryOrderDTO.billingTo,
        billingToAddress: response.data.deliveryOrderDTO.billingToAddress,
        billingToGST: response.data.deliveryOrderDTO.billedToGstin,
        billingContactNo: response.data.deliveryOrderDTO.billedToContactNumber,
        billingContactPerson:
          response.data.deliveryOrderDTO.billedToContactPerson,
        billingToEmail: response.data.deliveryOrderDTO.billedToEmail,
        billingState: response.data.deliveryOrderDTO.billingStateName,
        shippingTo: response.data.deliveryOrderDTO.shippingTo,
        shippingToAddress: response.data.deliveryOrderDTO.shippingToAddress,
        shippingToGST: response.data.deliveryOrderDTO.shippedToGstin,
        shippingContactNo:
          response.data.deliveryOrderDTO.shippedToContactNumber,
        shippingContactPerson:
          response.data.deliveryOrderDTO.shippedToContactPerson,
        shippingToEmail: response.data.deliveryOrderDTO.shippedToEmail,
        shippingState: response.data.deliveryOrderDTO.shippingStateName,
        termsCondition: response.data.deliveryOrderDTO.termsCondition,
        termsConditionType: response.data.deliveryOrderDTO.termsConditionType,
        transporter: response.data.deliveryOrderDTO.transporter,
        transporterAddress: response.data.deliveryOrderDTO.transporterAddress,
        transporterStateName:
          response.data.deliveryOrderDTO.transporterStateName,
        transporterGst: response.data.deliveryOrderDTO.transporterGst,
        transporterStateCode:
          response.data.deliveryOrderDTO.transporterStateCode,
        status: response.data.deliveryOrderDTO.status,
        challanNo: response.data.deliveryOrderDTO.challanNo,
        challanDate: response.data.deliveryOrderDTO.challanDate,
        taxType: response.data.deliveryOrderDTO.taxType,
        footerNote: response.data.deliveryOrderDTO.footerNote,
        internalNote: response.data.deliveryOrderDTO.internalNote,
        deliveryTerms: response.data.deliveryOrderDTO.deliveryTerms,
        paymentTerms: response.data.deliveryOrderDTO.paymentTerms,
        deliveryDays: response.data.deliveryOrderDTO.deliveryDays,
        freightCharges: response.data.deliveryOrderDTO.freightCharges,
        amountWithoutTax: response.data.deliveryOrderDTO.amountWithoutTax,
        amountWithTax: response.data.deliveryOrderDTO.amountWithTax,
        updatedBy: response.data.deliveryOrderDTO.updatedBy,
        updatedOn: response.data.deliveryOrderDTO.updatedOn,
        updatedByDesc: response.data.deliveryOrderDTO.updatedByDesc,
        approvedBy: response.data.deliveryOrderDTO.approvedBy,
        approvedOn: response.data.deliveryOrderDTO.approvedOn,
        approvedBydesc: response.data.deliveryOrderDTO.approvedBydesc,
        isActive: response.data.deliveryOrderDTO.isActive,
        regDeliveryOrderId: response.data.deliveryOrderDTO.regDeliveryOrderId,
        deliveryOrderNo: response.data.deliveryOrderDTO.deliveryOrderNo,
        createdByDesc: response.data.deliveryOrderDTO.createdByDesc,
        invoiceTypeName: response.data.deliveryOrderDTO.invoiceTypeName,
        printedOn: printedOn[0],
        transporterName: response.data.deliveryOrderDTO.transporterName,
        taxName: response.data.deliveryOrderDTO.taxName,
        hessianDeliveryOrderId: response.data.hessianDeliveryOrderId,
        approveStatus: response.data.deliveryOrderDTO.approveStatus,
        totalIGST:
        TotalIGST == null ? "0.00" : limitDecimals(TotalIGST),
      totalCGST:
        TotalCGST == null ? "0.00" : limitDecimals(TotalCGST),
      totalSGST:
        TotalSGST == null ? "0.00" : limitDecimals(TotalSGST),
      subTotal: limitDecimals(netAmount),
      taxTotal: limitDecimals(taxTotals),
      netTotal: limitDecimals(netAmount),
      TotalAmount: limitDecimals(Number(netAmount) + Number(taxTotals)),
        ApproveButton: ApproveButton,
        RejectButton: RejectButton,
        UpdateButton: UpdateButton,
        EditButton: EditButton,
        statusName: response.data.deliveryOrderDTO.statusName,

      };
      yield put(DeliveryOrderJuteHessianHeaders(Header));
    }
    yield put(DeliveryOrderJuteHessianByIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

// Jute Yarn---------------------
function* CreateJuteYarnDeliveryOrder({ payload: { url, data, history } }) {
  try {
    showSpinner()
    const response = yield call(postAdd, url, data);
    yield put(CreateJuteYarnDeliveryOrderSuccessfull({ response }));
    if (response.data.status) {
      hideSpinner();
      swal(response.data.message, { icon: "success" });
      history.push("/delivery_order");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    console.log(error);
  }
}

function* UpdateJuteYarnDeliveryOrder({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(UpdateJuteYarnDeliveryOrderSuccessfull({ response }));
    if (response.data.status) {
      swal(response.data.message, { icon: "success" });
      history.push("/delivery_order");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    console.log(error);
  }
}

function* JuteYarnDeliveryOrderViewBYIdData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (isEmptyObject(response)) {
      swal("Something Went Wrong",{icon :"error"});
    } else {
      var CustomerUrl =
        serverApi.GET_ALL_BILLING_SHIPPING +
        response.data.deliveryOrderDTO.customerId +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher +
        "?isForSalesModule=1";
      yield put(getBillingShippingList(CustomerUrl, history));
      if (response.data.deliveryOrderDTO.deliveryOrderLineItemDTO) {
        if (
          response.data.deliveryOrderDTO.deliveryOrderLineItemDTO.length !== 0
        ) {
          var JuteYarnUpdate = [];
          response.data.deliveryOrderDTO.deliveryOrderLineItemDTO.map(
            (item, i) => {
              var createddate =
                item.createdDate !== null && item.createdDate.split("T",2);
              JuteYarnUpdate.push({
                id: i,
              regularDeliveryOrderLineItemId: item.regularDeliveryOrderLineItemId,
              createdBy: item.createdBy,
              createdDate: createddate[0],
              regDeliveryOrderId: item.regDeliveryOrderId,
              companyId: item.companyId,
              salesId: item.salesId,
              salesLineItemId: item.salesLineItemId,
              itemGroupId: item.itemGroupId,
              itemGroupName: item.itemGroupName,
              itemGroupValue: item.itemGroupValue,
              itemId: item.itemId,
              itemName: item.itemName,
              itemCode: item.itemCode,
              itemValue: item.itemValue,
              hsncode: item.hsncode,
              itemMake: item.itemMake,
              doBalQty: limitDecimals(item.quantity+item.doBalQty, 3),
              quantity: limitDecimals(item.quantity, 3),
              uom: item.uom,
              rate: limitDecimals(item.rate, 3),
              invoiceQty: "",
              taxPercent: limitDecimals(item.itemTaxPercentage, 2),
              igstAmount:
                item.igstAmount == null
                  ? "0.00"
                  : limitDecimals(item.igstAmount, 2),
              igstPercent:
                item.igstPercent == null
                  ? "0"
                  : limitDecimals(item.igstPercent, 2),
              cgstAmount:
                item.cgstAmount == null
                  ? "0.00"
                  : limitDecimals(item.cgstAmount, 2),
              cgstPercent:
                item.cgstPercent == null
                  ? "0"
                  : limitDecimals(item.cgstPercent, 2),
              sgstAmount:
                item.sgstAmount == null
                  ? "0.00"
                  : limitDecimals(item.sgstAmount, 2),
              sgstPercent:
                item.sgstPercent == null
                  ? "0"
                  : limitDecimals(item.sgstPercent, 2),
              cessAmount: "",
              discountType: item.discountType,
              discount: limitDecimals(item.discount, 2),
              discountName: item.discountName,
              discountAmount:limitDecimals(item.discountAmount,2),
              discountedRate: limitDecimals(item.discountedRate, 3),
              netAmount: limitDecimals(item.netAmount, 2),
              totalAmount: limitDecimals(item.totalAmount,2),
              remarks: item.remarks,
              balesPacketSlNo: item.balesPacketSlNo,
              isActive: item.isActive,
              yarnIsActive:item.yarnIsActive,
              status: item.status,
              updatedBy: item.updatedBy,
              updatedOn: item.updatedOn,
              yarnSalesOrderLineItemId:"",
              yarnDeliveryOrderLineItemId: item.yarnDeliveryOrderLineItemId,
              yarnDeliveryOrderId: item.yarnDeliveryOrderId,
              yarnRateUom: item.yarnRateUom,
              yarnRateUomId: item.yarnRateUomId,
              yarnRateUomRelationValue: Number(item.yarnRateUomRelationValue),
              yarnRateCalculatedValue: item.yarnRateCalculatedValue,
              yarnQuantityUom: item.yarnQuantityUom,
              yarnQuantityUomId: item.yarnQuantityUomId,
              yarnQuantityUomRelationValue: Number(item.yarnQuantityUomRelationValue),
              yarnQuantityCalculatedValue: item.yarnQuantityCalculatedValue,
              yarnVariableRelationValue: item.yarnVariableRelationValue,
              yarnRateIncludingBrokerage:limitDecimals(item.yarnRateIncludingBrokerage,3),
              yarnRateExcludingBrokerage:limitDecimals(item.yarnRateExcludingBrokerage,3),
              yarnUpdatedBy: item.yarnUpdatedBy,
              yarnUpdatedOn: item.yarnUpdatedOn,
              yarnIsActive:item.yarnIsActive,

              hessianDeliveryOrderLineItemId: "",
              hessianDeliveryOrderId: "",
              hessianQuantityUom: "",
              hessianQuantityUomId: "",
              hessianQuantityRelationValue: "",
              hessianQuantityCalculatedValue: "",
              hessianRateUom: "",
              hessianRateUomId: "",
              hessianRateRelationValue: "",
              hessianRateCalculatedValue: "",
              hessianRateIncludingBrokerage: "",
              hessianRateExcludingBrokerage: "",
              hessianBalesPacketSlNo: "",
              hessianUpdatedBy: "",
              hessianUpdatedOn: "",
              hessianIsActive: "",

              govtDeliveryOrderLineItemId: "",
              govtDeliveryOrderId: "",
              govtDeliveryRateUom: "",
              govtDeliveryRateUom2: "",
              govtDeliveryRateUom3: "",
              govtDeliveryQuantityUom: "",
              govtDeliveryQuantityUom2: "",
              govtDeliveryQuantityUom3: "",
              govtDeliveryRelationValue: "",
              govtDeliveryRelationValue2: "",
              govtDeliveryRelationValue3: "",
              govtDeliveryQtyCalculatedValue: "",
              govtDeliveryQtyCalculatedValue2: "",
              govtDeliveryQtyCalculatedValue3: "",
              govtDeliveryRateCalculatedValue: "",
              govtDeliveryRateCalculatedValue2: "",
              govtDeliveryRateCalculatedValue3: "",
              govtDeliveryBalesPacketSlNo: "",
              govtUpdatedBy: "",
              govtUpdatedOn: "",
              ItemNameData: [],
              });
            }
          );
          yield put(getJuteYarnDeliveryOrderLineItems(JuteYarnUpdate));
        }
      }
      var TotalCGST =JuteYarnUpdate.length !==0 ?JuteYarnUpdate
        .filter((item) => item.isActive != 0)
        .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
        .reduce((cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind)):"0.00";
      var TotalSGST =JuteYarnUpdate.length !==0 ?JuteYarnUpdate
        .filter((item) => item.isActive != 0)
        .map((p) => (p.sgstAmount == null ? 0 : p.sgstAmount))
        .reduce((sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind)):"0.00";
      var TotalIGST =JuteYarnUpdate.length !==0 ?JuteYarnUpdate
        .filter((item) => item.isActive != 0)
        .map((p) => (p.igstAmount == null ? 0 : p.igstAmount))
        .reduce((igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind)):"0.00";
      var netAmount = JuteYarnUpdate.length !==0 ?JuteYarnUpdate
        .filter((item) => item.isActive != 0)
        .map((p) => p.netAmount)
        .reduce(
          (netAmount, ind) => parseFloat(netAmount) + parseFloat(ind)
        ):"0.00";
      var taxTotals = Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);
      var createddate =
      response.data.deliveryOrderDTO.createdDate !== null &&
        response.data.deliveryOrderDTO.createdDate.split("T",2);
      var salesdate =
        response.data.deliveryOrderDTO.salesOrderDate !== null &&
        response.data.deliveryOrderDTO.salesOrderDate.split(" ",2);
      var deliveryDate =
        response.data.deliveryOrderDTO.deliveryDate !== null &&
        response.data.deliveryOrderDTO.deliveryDate.split("T",2);
        var ApproveButton = false;
          var RejectButton = false;
          var UpdateButton = true;
          var EditButton = false;
          if (response.data.deliveryOrderDTO.approveStatus == true) {
            if (
              response.data.deliveryOrderDTO.status == 1 ||
              response.data.deliveryOrderDTO.status == 17 ||
              response.data.deliveryOrderDTO.status == 18 ||
              response.data.deliveryOrderDTO.status == 19 ||
              response.data.deliveryOrderDTO.status == 20
            ) {
              ApproveButton = true;
              RejectButton = true;
              UpdateButton = true;
              EditButton = true;
            }
          } else if (
            response.data.deliveryOrderDTO.status == 1 ||
            response.data.deliveryOrderDTO.status == 17 ||
            response.data.deliveryOrderDTO.status == 18 ||
            response.data.deliveryOrderDTO.status == 19 ||
            response.data.deliveryOrderDTO.status == 20
          ) {
            UpdateButton = true;
            EditButton = true;
          }

          if (
            response.data.deliveryOrderDTO.status == 4 ||
            response.data.deliveryOrderDTO.status == 3 ||
            response.data.deliveryOrderDTO.status == 5
          ) {
            ApproveButton = false;
            RejectButton = false;
            UpdateButton = false;
            EditButton = false;
          }
      const Header = {
        regDeliveryOrderId: response.data.deliveryOrderDTO.regDeliveryOrderId,
        yarnDeliveryOrderId:response.data.yarnDeliveryOrderId,
        finYear:  response.data.deliveryOrderDTO.finYear,
        createdBy:  response.data.deliveryOrderDTO.createdBy,
        createdByDesc:  response.data.deliveryOrderDTO.createdByDesc,
        createdDate: createddate[0],
        deliveryDate: deliveryDate[0],
        deliveryOrderNo: response.data.deliveryOrderDTO.deliveryOrderNo,
        invoiceType:  response.data.deliveryOrderDTO.invoiceType,
        invoiceTypeName: response.data.deliveryOrderDTO.invoiceTypeName,
        company:response.data.deliveryOrderDTO.company,
        companyName: response.data.deliveryOrderDTO.companyName,
        branch:response.data.deliveryOrderDTO.branch,
        branchName:response.data.deliveryOrderDTO.branchName,
        salesOrderId:response.data.deliveryOrderDTO.salesOrderId,
        salesOrderNumber:response.data.deliveryOrderDTO.salesOrderNumber,
        salesOrderDate: salesdate[0],
        brokerId:response.data.deliveryOrderDTO.brokerId,
        brokerName:response.data.deliveryOrderDTO.brokerName,
        bankId:response.data.deliveryOrderDTO.bankId,
        bankBranch:response.data.deliveryOrderDTO.bankBranch,
        bankName:response.data.deliveryOrderDTO.bankName,
        ifscCode:response.data.deliveryOrderDTO.ifscCode,
        accountNo:response.data.deliveryOrderDTO.accountNo,
        micrCode: response.data.deliveryOrderDTO.micrCode,
        accountantName:response.data.deliveryOrderDTO.accountantName,
        brokerContactPerson: "",
        brokerContactNumber: "",
        consigneeContactPerson: "",
        consigneeContactNumber: "",
        customerId:response.data.deliveryOrderDTO.customerId,
        customerName:response.data.deliveryOrderDTO.customerName,

        billingTo:response.data.deliveryOrderDTO.billingTo,
        billingToAddress: response.data.deliveryOrderDTO.billingToAddress,
        billingStateName: response.data.deliveryOrderDTO.billingStateName,
        billedToContactNumber:response.data.deliveryOrderDTO.billedToContactNumber,
        billedToGstin:response.data.deliveryOrderDTO.billedToGstin,
        billedToContactPerson:response.data.deliveryOrderDTO.billedToContactPerson,
        billedToEmail: response.data.deliveryOrderDTO.billedToEmail,
        shippingTo:response.data.deliveryOrderDTO.shippingTo,
        shippingToAddress: response.data.deliveryOrderDTO.shippingToAddress,
        shippingStateName:response.data.deliveryOrderDTO.shippingStateName,
        shippedToContactNumber:response.data.deliveryOrderDTO.shippedToContactNumber,
        shippedToGstin:response.data.deliveryOrderDTO.shippedToGstin,
        shippedToContactPerson:response.data.deliveryOrderDTO.shippedToContactPerson,
        shippedToEmail: response.data.deliveryOrderDTO.shippedToEmail,
        quotationDate: "",
        quotationNumber: "",
        printedOn:response.data.deliveryOrderDTO.printedOn,
        termsConditionType:response.data.deliveryOrderDTO.termsConditionType,
        termsConditionName:response.data.deliveryOrderDTO.termsConditionName,
        transporter:response.data.deliveryOrderDTO.transporter,
        transporterName:response.data.deliveryOrderDTO.transporterName,
        transporterAddress: response.data.deliveryOrderDTO.transporterAddress,
        transporterStateCode:response.data.deliveryOrderDTO.transporterStateCode,
        transporterStateName: response.data.deliveryOrderDTO.transporterStateName,
        transporterGst: response.data.deliveryOrderDTO.transporterGst,
        vehicleNumber:response.data.deliveryOrderDTO.vehicleNumber,
        status: response.data.deliveryOrderDTO.status,
        statusDesc: response.data.deliveryOrderDTO.statusDesc,
        challanNo: response.data.deliveryOrderDTO.challanNo,
        challanDate: response.data.deliveryOrderDTO.challanDate,
        taxType:response.data.deliveryOrderDTO.taxType,
        taxName:response.data.deliveryOrderDTO.taxTypeName,
        footerNote:response.data.deliveryOrderDTO.footerNote,
        internalNote:response.data.deliveryOrderDTO.internalNote,
        termsCondition:response.data.deliveryOrderDTO.termsCondition,
        deliveryTerms: response.data.deliveryOrderDTO.deliveryTerms,
        paymentTerms:response.data.deliveryOrderDTO.paymentTerms,
        deliveryDays: response.data.deliveryOrderDTO.deliveryDays,
        freightCharges:response.data.deliveryOrderDTO.freightCharges,
        amountWithoutTax:limitDecimals( response.data.deliveryOrderDTO.amountWithoutTax,2),
        amountWithTax: limitDecimals(response.data.deliveryOrderDTO.amountWithTax,2),
        roundOffValue: response.data.deliveryOrderDTO.roundOffValue,
        roundingFlag: response.data.deliveryOrderDTO.roundingFlag,
        updatedBy: response.data.deliveryOrderDTO.updatedBy,
        updatedByDesc: response.data.deliveryOrderDTO.updatedByDesc,
        updatedOn: response.data.deliveryOrderDTO.updatedOn,
        approvedBy: response.data.deliveryOrderDTO.approvedBy,
        approvedByDesc: response.data.deliveryOrderDTO.approvedByDesc,
        approvedOn: response.data.deliveryOrderDTO.approvedOn,
        isActive: response.data.deliveryOrderDTO.isActive,
        approveStatus: response.data.deliveryOrderDTO.approveStatus,
        brokerCommisionPercent:  response.data.deliveryOrderDTO.brokerCommisionPercent,
        statusName:response.data.deliveryOrderDTO.statusName,
        totalIGST:
        TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
      totalCGST:
        TotalCGST == null ? "0.00" : parseFloat(TotalCGST).toFixed(2),
      totalSGST:
        TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),
      subTotal: parseFloat(netAmount).toFixed(2),
      taxTotal: parseFloat(taxTotals).toFixed(2),
      netTotal: parseFloat(netAmount).toFixed(2),
      TotalAmount: parseFloat(Number(netAmount) +Number(taxTotals)).toFixed(2),
        ApproveButton: ApproveButton,
        RejectButton: RejectButton,
        UpdateButton: UpdateButton,
        EditButton: EditButton,
      };
      yield put(getJuteYarnDeliveryOrderHeader(Header));
    }
    yield put(JuteYarnDeliveryOrderViewByIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* SalesOrderDataViewByIdForJuteYarn({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (isEmptyObject(response.data)) {
      swal("Something Went Wrong");
    } else {
      var CustomerUrl =
        serverApi.GET_ALL_BILLING_SHIPPING +
        response.data.salesOrderDTO.customerId +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher +
        "?isForSalesModule=1";
      yield put(getBillingShippingList(CustomerUrl, history));
      if (response.data.salesOrderDTO.salesOrderLineItemDTO) {
        if (response.data.salesOrderDTO.salesOrderLineItemDTO.length !== 0) {
          var SalesUpdate = [];
          const FilteredItem = response.data.salesOrderDTO.salesOrderLineItemDTO.filter(
            (item) => {
              return item.doBalQty !== 0 && item.isActive !== 0;
            }
          );        
          FilteredItem.map((item, i) => {
            var createddate =
              item.createdDate !== null
                ? item.createdDate.split("T", 2)[0]
                : null;
                let calculatedPryToSecValue =limitDecimals(convertPrimaryToSecondary(item.doBalQty,item.yarnQuantityUomRelationValue),3)
                let calculatedRatePcsToMt =limitDecimals(convertRatePcsToMt(item.yarnRateIncludingBrokerage,item.yarnRateUomRelationValue),3)
            let netAmount= limitDecimals(Number(item.doBalQty)*Number(item.yarnRateIncludingBrokerage))

            SalesUpdate.push({
              id: i,
              regularDeliveryOrderLineItemId: "",
              createdBy: item.createdBy,
              createdDate: createddate,
              regDeliveryOrderId: "",
              companyId: item.companyId,
              salesId: item.regularSalesOrderId,
              salesLineItemId: item.regularSalesOrderLineItemId,
              itemGroupId: item.itemGroupId,
              itemGroupName: item.itemGroupName,
              itemGroupValue: item.itemGroupValue,
              itemId: item.itemId,
              itemName: item.itemName,
              itemCode: item.itemCode,
              itemValue: item.itemValue,
              hsncode: item.hsncode,
              itemMake: item.itemMake,
              doBalQty: limitDecimals(item.doBalQty, 3),
              quantity: limitDecimals(item.doBalQty, 3),
              uom: item.uom,
              rate: limitDecimals(item.yarnRateIncludingBrokerage, 3),
              invoiceQty: "",
              taxPercent: limitDecimals(item.itemTaxPercentage, 2),
              igstAmount :limitDecimals((netAmount)*(item.igstPercent ==null?0:item.igstPercent)/100,2),
              igstPercent: limitDecimals(
                item.igstPercent == null ? "0" : item.igstPercent,
                2
              ),
              cgstAmount :limitDecimals((netAmount)*  (item.cgstPercent ==null?0:item.cgstPercent)/100,2),

              cgstPercent: limitDecimals(
                item.cgstPercent == null ? "0" : item.cgstPercent,
                2
              ),
              sgstAmount :limitDecimals((netAmount)*  (item.sgstPercent ==null?0:item.sgstPercent)/100,2),
              sgstPercent: limitDecimals(
                item.sgstPercent == null ? "0" : item.sgstPercent,
                2
              ),
              cessAmount: "",
              discountType: item.discountType,
              discount: limitDecimals(item.discount, 2),
              discountName: item.discountTypeName,
              discountedRate: limitDecimals(item.discountedRate, 3),
              discountAmount:limitDecimals(item.discountAmount,2),
              totalAmount: limitDecimals(item.totalAmount, 2),
              netAmount:limitDecimals(netAmount,2),
              remarks: item.remarks,
              balesPacketSlNo: "",
              isActive: item.isActive,
              yarnIsActive:item.yarnIsActive,
              status: item.status,
              updatedBy: null,
              updatedOn: null,
              yarnDeliveryOrderLineItemId:"",
              yarnDeliveryOrderId: "",
              yarnRateUom: item.yarnRateUom,
              yarnRateUomId: item.yarnRateUomId,
              yarnRateUomRelationValue: item.yarnRateUomRelationValue,
              yarnRateCalculatedValue:limitDecimals(convertRatePcsToMt(item.yarnRateIncludingBrokerage,item.yarnRateUomRelationValue),3),
              yarnSalesOrderLineItemId:item.yarnSalesOrderLineItemId,
              yarnQuantityUom: item.yarnQuantityUom,
              yarnQuantityUomId: item.yarnQuantityUomId,
              yarnQuantityUomRelationValue: item.yarnQuantityUomRelationValue,
              yarnQuantityCalculatedValue:limitDecimals(convertPrimaryToSecondary(item.doBalQty,item.yarnQuantityUomRelationValue),3),
              yarnVariableRelationValue: item.yarnVariableRelationValue,
              yarnRateIncludingBrokerage:limitDecimals(item.yarnRateIncludingBrokerage,3),
              yarnRateExcludingBrokerage:limitDecimals(item.yarnRateExcludingBrokerage,3),
              yarnUpdatedBy: "",
              yarnUpdatedOn: "",
              yarnIsActive: 1,
              hessianDeliveryOrderLineItemId: "",
              hessianDeliveryOrderId: "",
              hessianQuantityUom: "",
              hessianQuantityUomId: "",
              hessianQuantityRelationValue: "",
              hessianQuantityCalculatedValue: "",
              hessianRateUom: "",
              hessianRateUomId: "",
              hessianRateRelationValue: "",
              hessianRateIncludingBrokerage: "",
              hessianRateExcludingBrokerage: "",
              hessianBalesPacketSlNo: "",
              hessianUpdatedBy: "",
              hessianUpdatedOn: "",
              hessianIsActive: "",
              govtDeliveryOrderLineItemId: "",
              govtDeliveryOrderId: "",
              govtDeliveryRateUom: "",
              govtDeliveryRateUom2: "",
              govtDeliveryRateUom3: "",
              govtDeliveryQuantityUom: "",
              govtDeliveryQuantityUom2: "",
              govtDeliveryQuantityUom3: "",
              govtDeliveryRelationValue: "",
              govtDeliveryRelationValue2: "",
              govtDeliveryRelationValue3: "",
              govtDeliveryQtyCalculatedValue: "",
              govtDeliveryQtyCalculatedValue2: "",
              govtDeliveryQtyCalculatedValue3: "",
              govtDeliveryRateCalculatedValue: "",
              govtDeliveryRateCalculatedValue2: "",
              govtDeliveryRateCalculatedValue3: "",
              govtDeliveryBalesPacketSlNo: "",
              govtUpdatedBy: "",
              govtUpdatedOn: "",
              ItemNameData: [],
            });
          });
          yield put(getJuteYarnDeliveryOrderLineItems(SalesUpdate));
        }
      }
      var TotalCGST =SalesUpdate.length !==0 ? SalesUpdate.map((p) =>
        p.cgstAmount == null ? 0 : p.cgstAmount
      ).reduce((cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind)):"0.00";
      var TotalSGST = SalesUpdate.length !==0 ?SalesUpdate.map((p) =>
        p.sgstAmount == null ? 0 : p.sgstAmount
      ).reduce((sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind)):"0.00";
      var TotalIGST = SalesUpdate.length !==0 ?SalesUpdate.map((p) =>
        p.igstAmount == null ? 0 : p.igstAmount
      ).reduce((igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind)):"0.00";
      var netAmount = SalesUpdate.length !==0 ? SalesUpdate.map((p) =>
        p.netAmount == null ? 0 : p.netAmount
      ).reduce((netAmount, ind) => parseFloat(netAmount) + parseFloat(ind)):"0.00";
      var taxTotals = Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);

      var createddate =
      response.data.salesOrderDTO.createdDate !== null &&
        response.data.salesOrderDTO.createdDate.split("T");
      var salesdate =
        response.data.salesOrderDTO.salesDate !== null && response.data.salesOrderDTO.salesDate.split("T",2);
      const Header = {
        regDeliveryOrderId:"",
        yarnDeliveryOrderId:"",
        finYear:  response.data.salesOrderDTO.finYear,
        createdBy:  response.data.salesOrderDTO.createdBy,
        createdByDesc:  response.data.salesOrderDTO.createdByDesc,
        createdDate: createddate,
        deliveryDate:null,
        deliveryOrderNo: "",
        invoiceType:  response.data.salesOrderDTO.invoiceTypeId,
        invoiceTypeName: response.data.salesOrderDTO.invoiceTypeName,
        company:response.data.salesOrderDTO.company,
        companyName: response.data.salesOrderDTO.companyName,
        branch:response.data.salesOrderDTO.branchId,
        branchName:response.data.salesOrderDTO.branchName,
        salesOrderId:response.data.salesOrderDTO.regularSalesId,
        salesOrderNumber:response.data.salesOrderDTO.salesNo,
        salesOrderDate: salesdate[0],
        brokerId:response.data.salesOrderDTO.brokerId,
        brokerName:response.data.salesOrderDTO.brokerName,
        bankId:response.data.salesOrderDTO.bankId,
        bankBranch:response.data.salesOrderDTO.bankBranch,
        bankName:response.data.salesOrderDTO.bankName,
        ifscCode:response.data.salesOrderDTO.ifscCode,
        accountNo:response.data.salesOrderDTO.accountNo,
        micrCode: response.data.salesOrderDTO.micrCode,
        brokerContactPerson: "",
        brokerContactNumber: "",
        consigneeContactPerson: "",
        consigneeContactNumber: "",
        customerId:response.data.salesOrderDTO.customerId,
        customerName:response.data.salesOrderDTO.customerName,
        billingTo:response.data.salesOrderDTO.billingTo,
        billingToAddress: response.data.salesOrderDTO.billingToAddress,
        billingStateName: response.data.salesOrderDTO.billingToState,
        billedToContactNumber:response.data.salesOrderDTO.billedToContactNumber,
        billedToGstin:response.data.salesOrderDTO.billedToGstin,
        billedToContactPerson:response.data.salesOrderDTO.billedToContactPerson,
        billedToEmail: response.data.salesOrderDTO.billedToEmail,
        shippingTo:response.data.salesOrderDTO.shippingTo,
        shippingToAddress: response.data.salesOrderDTO.shippingToAddress,
        shippingStateName:response.data.salesOrderDTO.shippingToState,
        shippedToContactNumber:response.data.salesOrderDTO.shippedToContactNumber,
        shippedToGstin:response.data.salesOrderDTO.shippedToGstin,
        shippedToContactPerson:response.data.salesOrderDTO.shippedToContactPerson,
        shippedToEmail: response.data.salesOrderDTO.shippedToEmail,
        quotationDate: "",
        quotationNumber: "",
        printedOn:response.data.salesOrderDTO.printedOn,
        termsConditionType:response.data.salesOrderDTO.termsConditionType,
        termsConditionName:response.data.salesOrderDTO.termsConditionName,
        transporter:response.data.salesOrderDTO.transporterId,
        transporterName:response.data.salesOrderDTO.transporterName,
        transporterAddress: response.data.salesOrderDTO.transporterAddress,
        transporterStateCode:response.data.salesOrderDTO.transporterStateCode,
        transporterStateName: response.data.salesOrderDTO.transporterStateName,
        transporterGst: response.data.salesOrderDTO.transporterGst,
        vehicleNumber:"",
        status: 1,
        statusDesc: "",
        challanNo: "",
        challanDate: null,
        taxType:response.data.salesOrderDTO.taxType,
        taxName:response.data.salesOrderDTO.taxTypeName,
        footerNote:response.data.salesOrderDTO.footerNote,
        internalNote:response.data.salesOrderDTO.internalNote,
        termsCondition:response.data.salesOrderDTO.termsCondition,
        deliveryTerms: response.data.salesOrderDTO.deliveryTerms,
        paymentTerms:response.data.salesOrderDTO.paymentTerms,
        deliveryDays: response.data.salesOrderDTO.deliveryDays,
        freightCharges:response.data.salesOrderDTO.freightCharges,
        amountWithoutTax:limitDecimals( response.data.salesOrderDTO.amountWithoutTax,2),
        amountWithTax: limitDecimals(response.data.salesOrderDTO.amountWithTax,2),
        roundOffValue: response.data.salesOrderDTO.roundOffValue,
        roundingFlag: response.data.salesOrderDTO.roundingFlag,
        updatedBy: "",
        updatedByDesc: "",
        updatedOn: "",
        approvedBy: "",
        approvedByDesc: "",
        approvedOn: "",
        isActive: 1,
        approveStatus: false,
        brokerCommisionPercent:  response.data.brokerCommisionPercent,
        statusName:response.data.salesOrderDTO.statusName,
        totalIGST:
        TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
      totalCGST:
        TotalCGST == null ? "0.00" : parseFloat(TotalCGST).toFixed(2),
      totalSGST:
        TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),
      subTotal: parseFloat(netAmount).toFixed(2),
      taxTotal: parseFloat(taxTotals).toFixed(2),
      netTotal: parseFloat(netAmount).toFixed(2),
      TotalAmount: parseFloat(Number(netAmount) +Number(taxTotals)).toFixed(2),

        ApproveButton: false,
        RejectButton: false,
        UpdateButton: true,
        EditButton: false,
      };
      yield put(getJuteYarnDeliveryOrderHeader(Header));
    }
    yield put(SalesOrderViewByIdForJuteYarnSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

//.....JuteGovt......

function* CreateJuteGovtDeliveryOrder({ payload: { url, data, history } }) {
  try {
    showSpinner()
    const response = yield call(postAdd, url, data);
    yield put(CreateJuteGovtDeliveryOrderSuccessfull({ response }));
    if (response.data.status) {
      hideSpinner()
      swal(response.data.message, { icon: "success" });
      history.push("/delivery_order");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    console.log(error);
  }
}

function* UpdateJuteGovtDeliveryOrder({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(UpdateJuteGovtDeliveryOrderSuccessfull({ response }));
    if (response.data.status) {
      swal(response.data.message, { icon: "success" });
      history.push("/delivery_order");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    console.log(error);
  }
}

function* JuteGovtDeliveryOrderViewByIdData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (isEmptyObject(response)) {
      swal("Something Went Wrong");
    } else {
      var CustomerUrl =
        serverApi.GET_ALL_BILLING_SHIPPING +
        response.data.deliveryOrderDTO.customerId +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher +
        "?isForSalesModule=1";
      yield put(getBillingShippingList(CustomerUrl, history));
      if (response.data.deliveryOrderDTO.deliveryOrderLineItemDTO) {
        if (
          response.data.deliveryOrderDTO.deliveryOrderLineItemDTO.length !== 0
        ) {
          var JuteGovtUpdate = [];
          const firstTaxPercentage = response.data.deliveryOrderDTO.deliveryOrderLineItemDTO[0].itemTaxPercentage;
          response.data.deliveryOrderDTO.deliveryOrderLineItemDTO.map(
            (item, i) => {
              var createddate =
                item.createdDate !== null && item.createdDate.split("T");
              JuteGovtUpdate.push({
                id: i,
                regularDeliveryOrderLineItemId:
                  item.regularDeliveryOrderLineItemId,
                createdBy: item.createdBy,
                createdDate: createddate[0],
                regDeliveryOrderId:
                  response.data.deliveryOrderDTO.regDeliveryOrderId,
                companyId: item.companyId,
                salesId: response.data.deliveryOrderDTO.salesOrderId,
                salesLineItemId: item.salesLineItemId,
                itemGroupId: item.itemGroupId,
                itemGroupName: item.itemGroupName,
                itemGroupValue: item.itemGroupValue,
                itemId: item.itemId,
                itemName: item.itemName,
                itemCode: item.itemCode,
                itemValue: item.itemValue,
                hsncode: item.hsncode,
                itemMake: item.itemMake,
                quantity: limitDecimals(item.quantity, 3),
                // taxPercent: limitDecimals(item.itemTaxPercentage, 2),
                taxPercent: limitDecimals(firstTaxPercentage, 2),
                                uom: item.uom,
                rate: limitDecimals(item.rate, 3),
                invoiceQty: "",
                igstAmount:
                  item.igstAmount == null
                    ? "0.00"
                    : limitDecimals(item.igstAmount, 2),
                igstPercent:
                  item.igstPercent == null
                    ? "0"
                    : limitDecimals(item.igstPercent, 2),
                cgstAmount:
                  item.cgstAmount == null
                    ? "0.00"
                    : limitDecimals(item.cgstAmount, 2),
                cgstPercent:
                  item.cgstPercent == null
                    ? "0"
                    : limitDecimals(item.cgstPercent, 2),
                sgstAmount:
                  item.sgstAmount == null
                    ? "0.00"
                    : limitDecimals(item.sgstAmount, 2),
                sgstPercent:
                  item.sgstPercent == null
                    ? "0"
                    : limitDecimals(item.sgstPercent, 2),
                cessAmount: "",
                discountType: item.discountType,
                discount: limitDecimals(item.discount, 2),
                discountName: item.discountName,
                discountedRate: item.discountedRate,
                discountAmount:limitDecimals(item.discountAmount,2),
                netAmount: limitDecimals(item.netAmount,2),
                totalAmount: limitDecimals(item.totalAmount,2),
                remarks: item.remarks,
                balesPacketSlNo: "",
                isActive: item.isActive,
                govtIsActive:item.govtIsActive,
                status: item.status,
                updatedBy: null,
                updatedOn: null,
                govtDeliveryOrderLineItemId: item.govtDeliveryOrderLineItemId,
                govtDeliveryOrderId: item.govtDeliveryOrderId,
                govtDeliveryRateUom: item.govtDeliveryRateUom,
                govtDeliveryRateUom2: item.govtDeliveryRateUom2,
                govtDeliveryRateUom3: item.govtDeliveryRateUom3,
                govtDeliveryQuantityUom: item.govtDeliveryQuantityUom,
                govtDeliveryQuantityUom2: item.govtDeliveryQuantityUom2,
                govtDeliveryQuantityUom3: item.govtDeliveryQuantityUom3,
                govtDeliveryRelationValue: item.govtDeliveryRelationValue,
                govtDeliveryRelationValue2: item.govtDeliveryRelationValue2,
                govtDeliveryRelationValue3: item.govtDeliveryRelationValue3,
                govtDeliveryQtyCalculatedValue:
                  item.govtDeliveryQtyCalculatedValue,
                govtDeliveryQtyCalculatedValue2:
                  item.govtDeliveryQtyCalculatedValue2,
                govtDeliveryQtyCalculatedValue3:
                  item.govtDeliveryQtyCalculatedValue3,
                govtDeliveryRateCalculatedValue:
                  item.govtDeliveryRateCalculatedValue,
                govtDeliveryRateCalculatedValue2:
                  item.govtDeliveryRateCalculatedValue2,
                govtDeliveryRateCalculatedValue3:
                  item.govtDeliveryRateCalculatedValue3,
                govtDeliveryBalesPacketSlNo: item.govtDeliveryBalesPacketSlNo,
                govtUpdatedBy: item.govtUpdatedBy,
                govtUpdatedOn: item.govtUpdatedOn,
                currency: "INR",
                doBalQty: parseFloat(item.quantity + item.doBalQty).toFixed(3),
              });
            }
          );
          yield put(getJuteGovtDeliveryOrderLineItems(JuteGovtUpdate));
        }
      }
      var TotalCGST = response.data.deliveryOrderDTO.deliveryOrderLineItemDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
        .reduce((cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind));
      var TotalSGST = response.data.deliveryOrderDTO.deliveryOrderLineItemDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.sgstAmount == null ? 0 : p.sgstAmount))
        .reduce((sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind));
      var TotalIGST = response.data.deliveryOrderDTO.deliveryOrderLineItemDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.igstAmount == null ? 0 : p.igstAmount))
        .reduce((igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind));
      var netAmount = response.data.deliveryOrderDTO.deliveryOrderLineItemDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => p.netAmount)
        .reduce(
          (netAmount, ind) => parseFloat(netAmount) + parseFloat(ind)
        );
      var taxTotals = Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);

      var createddate =
        response.data.deliveryOrderDTO.createdDate !== null &&
        response.data.deliveryOrderDTO.createdDate.split("T");
      var salesdate =
        response.data.deliveryOrderDTO.salesOrderDate !== null &&
        response.data.deliveryOrderDTO.salesOrderDate.split("");
      var deliveryDate =
        response.data.deliveryOrderDTO.deliveryDate !== null &&
        response.data.deliveryOrderDTO.deliveryDate.split("T");
      var quotationDate =
        response.data.deliveryOrderDTO.quotationDate !== null &&
        response.data.deliveryOrderDTO.quotationDate.split("T");
      var printedOn =
        response.data.deliveryOrderDTO.printedOn !== null &&
        response.data.deliveryOrderDTO.printedOn.split("T");

      var ApproveButton = false;
      var RejectButton = false;
      var UpdateButton = true;
      var EditButton = true;

      if (response.data.deliveryOrderDTO.approveStatus === true) {
        if (
          response.data.deliveryOrderDTO.status === 1 ||
          response.data.deliveryOrderDTO.status === 17 ||
          response.data.deliveryOrderDTO.status === 18 ||
          response.data.deliveryOrderDTO.status === 19 ||
          response.data.deliveryOrderDTO.status === 20
        ) {
          ApproveButton = true;
          RejectButton = true;
          UpdateButton = true;
          EditButton = true;
        }
      } else if (
        response.data.deliveryOrderDTO.status === 1 ||
        response.data.deliveryOrderDTO.status === 17 ||
        response.data.deliveryOrderDTO.status === 18 ||
        response.data.deliveryOrderDTO.status === 19 ||
        response.data.deliveryOrderDTO.status === 20
      ) {
        UpdateButton = true;
        EditButton = true;
      }
      if (
        response.data.deliveryOrderDTO.status === 4 ||
        response.data.deliveryOrderDTO.status === 3 ||
        response.data.deliveryOrderDTO.status === 5
      ) {
        ApproveButton = false;
        RejectButton = false;
        UpdateButton = false;
        EditButton = false;
      }

      const Header = {
        regDeliveryOrderId: response.data.deliveryOrderDTO.regDeliveryOrderId,
        govtSalesOrderId: response.data.deliveryOrderDTO.govtSalesOrderId,
        finYear: response.data.deliveryOrderDTO.finYear,
        createdBy: response.data.deliveryOrderDTO.createdBy,
        createdByDesc: response.data.deliveryOrderDTO.createdByDesc,
        createdDate: createddate[0],
        deliveryDate: deliveryDate[0],
        deliveryOrderNo: response.data.deliveryOrderDTO.deliveryOrderNo,
        invoiceType: response.data.deliveryOrderDTO.invoiceType,
        invoiceTypeName: response.data.deliveryOrderDTO.invoiceTypeName,
        company: response.data.deliveryOrderDTO.company,
        companyName: response.data.deliveryOrderDTO.companyName,
        branch: response.data.deliveryOrderDTO.branch,
        branchName: response.data.deliveryOrderDTO.branchName,
        branchAddress: response.data.deliveryOrderDTO.branchAddress,
        branchGstNo: response.data.deliveryOrderDTO.branchGstNo,
        branchContactPerson: response.data.deliveryOrderDTO.branchContactPerson,
        branchContactNo: response.data.deliveryOrderDTO.branchContactNo,
        branchEmail: response.data.deliveryOrderDTO.branchEmail,
        salesOrderId: response.data.deliveryOrderDTO.salesOrderId,
        salesOrderNumber: response.data.deliveryOrderDTO.salesOrderNumber,
        salesOrderDate: response.data.deliveryOrderDTO.salesOrderDate,
        brokerId: response.data.deliveryOrderDTO.brokerId,
        brokerName: response.data.deliveryOrderDTO.brokerName,
        bankId:response.data.deliveryOrderDTO.bankId,
        bankBranch:response.data.deliveryOrderDTO.bankBranch,
        bankName:response.data.deliveryOrderDTO.bankName,
        ifscCode:response.data.deliveryOrderDTO.ifscCode,
        accountNo:response.data.deliveryOrderDTO.accountNo,
        micrCode: response.data.deliveryOrderDTO.micrCode,
        customerId: response.data.deliveryOrderDTO.customerId,
        customerName: response.data.deliveryOrderDTO.customerName,

        billingTo: response.data.deliveryOrderDTO.billingTo,
        billingToAddress: response.data.deliveryOrderDTO.billingToAddress,
        billingToGST: response.data.deliveryOrderDTO.billedToGstin,
        billingContactNo: response.data.deliveryOrderDTO.billedToContactNumber,
        billingContactPerson:
          response.data.deliveryOrderDTO.billedToContactPerson,
        billingToEmail: response.data.deliveryOrderDTO.billedToEmail,
        billingState: response.data.deliveryOrderDTO.billingStateName,

        shippingTo: response.data.deliveryOrderDTO.shippingTo,
        shippingToAddress: response.data.deliveryOrderDTO.shippingToAddress,
        shippingToGST: response.data.deliveryOrderDTO.shippedToGstin,
        shippingContactNo:
          response.data.deliveryOrderDTO.shippedToContactNumber,
        shippingContactPerson:
          response.data.deliveryOrderDTO.shippedToContactPerson,
        shippingToEmail: response.data.deliveryOrderDTO.shippedToEmail,
        shippingState: response.data.deliveryOrderDTO.shippingStateName,

        termsConditionType: response.data.deliveryOrderDTO.termsConditionType,
        termsConditionName: response.data.deliveryOrderDTO.termsConditionName,

        transporter: response.data.deliveryOrderDTO.transporter,
        transporterName: response.data.deliveryOrderDTO.transporterName,
        transporterStateCode:
          response.data.deliveryOrderDTO.transporterStateCode,
        transporterStateName:
          response.data.deliveryOrderDTO.transporterStateName,
        transporterAddress: response.data.deliveryOrderDTO.transporterAddress,

        status: response.data.deliveryOrderDTO.status,
        govtDeliveryOrderId: response.data.govtDeliveryOrderId,
        challanNo: response.data.deliveryOrderDTO.challanNo,
        challanDate: response.data.deliveryOrderDTO.challanDate,
        taxType: response.data.deliveryOrderDTO.taxType,
        taxName: response.data.deliveryOrderDTO.taxName,
        footerNote: response.data.deliveryOrderDTO.footerNote,
        internalNote: response.data.deliveryOrderDTO.internalNote,
        termsCondition: response.data.deliveryOrderDTO.termsCondition,
        deliveryTerms: response.data.deliveryOrderDTO.deliveryTerms,
        paymentTerms: response.data.deliveryOrderDTO.paymentTerms,
        deliveryDays: response.data.deliveryOrderDTO.deliveryDays,
        freightCharges: response.data.deliveryOrderDTO.freightCharges,
        amountWithoutTax: response.data.deliveryOrderDTO.amountWithoutTax,
        amountWithTax: response.data.deliveryOrderDTO.amountWithTax,
        updatedBy: response.data.deliveryOrderDTO.updatedBy,
        updatedByDesc: response.data.deliveryOrderDTO.updatedByDesc,
        updatedOn: response.data.deliveryOrderDTO.updatedOn,
        vehicleNo: response.data.deliveryOrderDTO.vehicleNumber,
        approvedBy: response.data.deliveryOrderDTO.approvedBy,
        approvedByDesc: response.data.deliveryOrderDTO.approvedBydesc,
        approvedOn: response.data.deliveryOrderDTO.approvedOn,
        approveStatus: response.data.deliveryOrderDTO.approveStatus,
        isActive: null,
        consigneeContactNumber:
          response.data.deliveryOrderDTO.consigneeContactNumber,
        consigneeContactPerson:
          response.data.deliveryOrderDTO.consigneeContactPerson,
        loadingPoint: response.data.loadingPoint,
        destinationRailHead: response.data.destinationRailHead,
        pcsoNo: response.data.pcsoNo,
        pcsoDate: response.data.pcsoDate,
        ammendmentNo: response.data.ammendmentNo||"",
        ammendmentDate: response.data.ammendmentDate,
        ammendmentNo2: "",
        ammendmentDate2: null,
        ammendmentNo3: "",
        ammendmentDate3: null,
        ammendmentNo4: "",
        ammendmentDate4: null,
        ammendmentNo5: "",
        ammendmentDate5: null,

        quotationNumber: response.data.deliveryOrderDTO.quotationSalesOrderNo,
        quotationDate: response.data.deliveryOrderDTO.quotationDate,
        printedOn: printedOn[0],

        totalIGST:
          TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
        totalCGST:
          TotalCGST == null ? "0.00" : parseFloat(TotalCGST).toFixed(2),
        totalSGST:
          TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),
        subTotal: parseFloat(netAmount).toFixed(2),
        taxTotal: parseFloat(taxTotals).toFixed(2),
        netTotal: parseFloat(netAmount).toFixed(2),
        TotalAmount: parseFloat(Number(netAmount )+Number(taxTotals)).toFixed(2),

        ApproveButton: ApproveButton,
        RejectButton: RejectButton,
        UpdateButton: UpdateButton,
        EditButton: EditButton,
        statusName: response.data.deliveryOrderDTO.statusName,
      };
      yield put(getJuteGovtDeliveryOrderHeader(Header));
    }
    yield put(JuteGovtDeliveryOrderViewByIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* SalesOrderDataViewByIdForJuteGovt({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (isEmptyObject(response.data)) {
      swal("Something went wrong");
    } else {
      var CustomerUrl =
        serverApi.GET_ALL_BILLING_SHIPPING +
        response.data.salesOrderDTO.customerId +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher +
        "?isForSalesModule=1";
      yield put(getBillingShippingList(CustomerUrl, history));

      if (response.data.salesOrderDTO.salesOrderLineItemDTO) {
        if (response.data.salesOrderDTO.salesOrderLineItemDTO.length !== 0) {
                    var SalesUpdate = [];
                    const firstTaxPercentage = response.data.salesOrderDTO.salesOrderLineItemDTO[0].itemTaxPercentage;
          response.data.salesOrderDTO.salesOrderLineItemDTO.map((item, i) => {
            SalesUpdate.push({
              id: i,
              regularDeliveryOrderLineItemId:
                item.regularDeliveryOrderLineItemId,
              createdBy: item.createdBy,
              createdDate: item.createdDate,
              regDeliveryOrderId:
                response.data.salesOrderDTO.regDeliveryOrderId,
              companyId: item.companyId,
              salesId: response.data.salesOrderDTO.regularSalesId,
              salesLineItemId: item.regularSalesOrderLineItemId,
              itemGroupId: item.itemGroupId,
              itemGroupName: item.itemGroupName,
              itemGroupValue: item.itemGroupValue,
              itemId: item.itemId,
              itemName: item.itemName,
              itemCode: item.itemCode,
              itemValue: item.itemValue,
              hsncode: item.hsncode,
              itemMake: item.itemMake,
              quantity: limitDecimals(item.doBalQty, 3),
              // taxPercent: limitDecimals(item.itemTaxPercentage, 2),
              taxPercent: limitDecimals(firstTaxPercentage, 2),
                            uom: item.uom,
              rate: limitDecimals(item.rate, 3),
              invoiceQty: "",
              igstAmount:
                item.igstAmount == null
                  ? "0.00"
                  : limitDecimals(item.igstAmount, 2),
              igstPercent:
                item.igstPercent == null
                  ? "0"
                  : limitDecimals(item.igstPercent, 2),
              cgstAmount:
                item.cgstAmount == null
                  ? "0.00"
                  : limitDecimals(item.cgstAmount, 2),
              cgstPercent:
                item.cgstPercent == null
                  ? "0"
                  : limitDecimals(item.cgstPercent, 2),
              sgstAmount:
                item.sgstAmount == null
                  ? "0.00"
                  : limitDecimals(item.sgstAmount, 2),
              sgstPercent:
                item.sgstPercent == null
                  ? "0"
                  : limitDecimals(item.sgstPercent, 2),
              cessAmount: "",
              discountType: item.discountType,
              discount: limitDecimals(item.discount, 2),
              discountName: item.discountName,
              discountedRate: limitDecimals(item.discountedRate,3),
              discountAmount:limitDecimals(item.discountAmount,2),
              netAmount: limitDecimals(item.discountedRate*item.doBalQty, 2),
              totalAmount: limitDecimals(item.totalAmount, 2),
              remarks: item.remarks,
              balesPacketSlNo: "",
              isActive: item.isActive,
              govtIsActive:item.govtIsActive,
              status: item.status,
              updatedBy: null,
              updatedOn: null,
              govtDeliveryOrderLineItemId: "",
              govtDeliveryOrderId: "",
              govtDeliveryBalesPacketSlNo: item.govtDeliveryBalesPacketSlNo,
              govtDeliveryRateUom: item.govtRateUom,
              govtDeliveryRateUom2: item.govtRateUom2,
              govtDeliveryRateUom3: item.govtRateUom3,
              govtDeliveryQuantityUom: item.govtQuantityUom,
              govtDeliveryQuantityUom2: item.govtQuantityUom2,
              govtDeliveryQuantityUom3: item.govtQuantityUom3,
              govtDeliveryRelationValue: item.govtRelationValue,
              govtDeliveryRelationValue2: item.govtRelationValue2,
              govtDeliveryRelationValue3: item.govtRelationValue3,
              govtDeliveryQtyCalculatedValue:
              convertPrimaryToSecondary(
                item.doBalQty,
                item.govtRelationValue
              ),
              govtDeliveryQtyCalculatedValue2:  convertPrimaryToSecondary(
                item.doBalQty,
                item.govtRelationValue2
              ),
              govtDeliveryQtyCalculatedValue3: convertPrimaryToSecondary(
                item.doBalQty,
                item.govtRelationValue3
              ),
              govtDeliveryRateCalculatedValue: convertRatePcsToMt(
                item.rate,
                item.govtRelationValue
              ),
              govtDeliveryRateCalculatedValue2:convertRatePcsToMt(
                item.rate,
                item.govtRelationValue2
              ),
              govtDeliveryRateCalculatedValue3:convertRatePcsToMt(
                item.rate,
                item.govtRelationValue3
              ),
              govtUpdatedBy: item.govtUpdatedBy,
              govtUpdatedOn: item.govtUpdatedOn,
              currency: "INR",
              doBalQty: limitDecimals(item.doBalQty, 3),
            });
          });
          yield put(getJuteGovtDeliveryOrderLineItems(SalesUpdate));
        }
      }

      var TotalCGST = response.data.salesOrderDTO.salesOrderLineItemDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
        .reduce((cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind));
      var TotalSGST = response.data.salesOrderDTO.salesOrderLineItemDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.sgstAmount == null ? 0 : p.sgstAmount))
        .reduce((sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind));
      var TotalIGST = response.data.salesOrderDTO.salesOrderLineItemDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.igstAmount == null ? 0 : p.igstAmount))
        .reduce((igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind));
      var netAmount = response.data.salesOrderDTO.salesOrderLineItemDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => p.netAmount)
        .reduce(
          (netAmount, ind) => parseFloat(netAmount) + parseFloat(ind)
        );
      var taxTotals = Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);

      var createddate =
        response.data.salesOrderDTO.createdDate !== null &&
        response.data.salesOrderDTO.createdDate.split("T");
      var salesdate =
        response.data.salesOrderDTO.salesDate !== null &&
        response.data.salesOrderDTO.salesDate.split("T");

      const Header = {
        salesOrderId: response.data.salesOrderDTO.regularSalesId,
        salesOrderDate: salesdate[0],
        invoiceType: response.data.salesOrderDTO.invoiceTypeId,
        branch: response.data.salesOrderDTO.branchId,
        branchName: response.data.salesOrderDTO.branchName,
        branchAddress: response.data.salesOrderDTO.branchAddress,
        branchGstNo: response.data.salesOrderDTO.branchGstNo,
        branchContactPerson: response.data.salesOrderDTO.branchContactPerson,
        branchContactNo: response.data.salesOrderDTO.branchContactNo,
        branchEmail: response.data.salesOrderDTO.branchEmail,
        deliveryDate: null,
        salesOrderNumber: response.data.salesOrderDTO.salesNo,
        quotationNumber: response.data.salesOrderDTOquotationSalesOrderNo,
        quotationDate: response.data.salesOrderDTO.quotationDate,
        vehicleNo: "",
        finYear: response.data.salesOrderDTO.finYear,
        createdBy: response.data.salesOrderDTO.createdBy,
        createdDate: createddate[0],
        company: response.data.salesOrderDTO.companyId,
        brokerId: response.data.salesOrderDTO.brokerId,
        brokerName: response.data.salesOrderDTO.brokerName,
        bankId:response.data.salesOrderDTO.bankId,
        bankBranch:response.data.salesOrderDTO.bankBranch,
        bankName:response.data.salesOrderDTO.bankName,
        ifscCode:response.data.salesOrderDTO.ifscCode,
        accountNo:response.data.salesOrderDTO.accountNo,
        micrCode: response.data.salesOrderDTO.micrCode,
        customerId: response.data.salesOrderDTO.customerId,
        customerName: response.data.salesOrderDTO.customerName,
        customerLineItemId: response.data.salesOrderDTO.customerLineItemId,
        brokerContactNumber: response.data.salesOrderDTO.brokerContactNumber,
        brokerContactPerson: response.data.salesOrderDTO.brokerContactPerson,
        consigneeContactNumber:
          response.data.salesOrderDTO.consigneeContactNumber,
        consigneeContactPerson:
          response.data.salesOrderDTO.consigneeContactPerson,
        billingTo: response.data.salesOrderDTO.billingTo,
        billingToAddress: response.data.salesOrderDTO.billingToAddress,
        billingToGST: response.data.salesOrderDTO.billedToGstin,
        billingContactNo: response.data.salesOrderDTO.billedToContactNumber,
        billingContactPerson: response.data.salesOrderDTO.billedToContactPerson,
        billingToEmail: response.data.salesOrderDTO.billedToEmail,
        billingState: response.data.salesOrderDTO.billingToState,
        shippingTo: response.data.salesOrderDTO.shippingTo || "",
        shippingToAddress: response.data.salesOrderDTO.shippingToAddress,
        shippingToGST: response.data.salesOrderDTO.shippedToGstin,
        shippingContactNo: response.data.salesOrderDTO.shippedToContactNumber,
        shippingContactPerson:
          response.data.salesOrderDTO.shippedToContactPerson,
        shippingToEmail: response.data.salesOrderDTO.shippedToEmail,
        shippingState: response.data.salesOrderDTO.shippingToState,
        termsConditionType: response.data.salesOrderDTO.termsConditionType,
        transporter: response.data.salesOrderDTO.transporterId,
        transporterName: response.data.salesOrderDTO.transporterName,
        transporterAddress: response.data.salesOrderDTO.transporterAddress,
        transporterStateCode: response.data.salesOrderDTO.transporterStateCode,
        transporterStateName: response.data.salesOrderDTO.transporterStateName,
        status: response.data.salesOrderDTO.statusId,
        statusName: response.data.salesOrderDTO.statusName,
        challanNo: response.data.salesOrderDTO.challanNo,
        challanDate: response.data.salesOrderDTO.challanDate,
        taxType: response.data.salesOrderDTO.taxType,
        taxTypeName: response.data.salesOrderDTO.taxTypeName,
        footerNote: response.data.salesOrderDTO.footerNote,
        internalNote: response.data.salesOrderDTO.internalNote,
        termsCondition: response.data.salesOrderDTO.termsCondition,
        deliveryTerms: response.data.salesOrderDTO.deliveryTerms,
        paymentTerms: response.data.salesOrderDTO.paymentTerms,
        deliveryDays: response.data.salesOrderDTO.deliveryDays,
        freightCharges: response.data.salesOrderDTO.freightCharges,
        amountWithoutTax: response.data.salesOrderDTO.amountWithoutTax,
        amountWithTax: response.data.salesOrderDTO.amountWithTax,
        updatedBy: response.data.salesOrderDTO.updatedBy,
        updatedByDesc: response.data.salesOrderDTO.updatedByDesc,
        updatedOn: response.data.salesOrderDTO.updatedOn,
        approvedBy: response.data.salesOrderDTO.approvedBy,
        approvedBydesc: response.data.salesOrderDTO.approvedBydesc,
        approvedOn: response.data.salesOrderDTO.approvedOn,
        totalIGST:
          TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
        totalCGST:
          TotalCGST == null ? "0.00" : parseFloat(TotalCGST).toFixed(2),
        totalSGST:
          TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),
        subTotal: parseFloat(netAmount).toFixed(2),
        taxTotal: parseFloat(taxTotals).toFixed(2),
        netTotal: parseFloat(netAmount).toFixed(2),
        TotalAmount: parseFloat(Number(netAmount) + Number(taxTotals)).toFixed(2),
        pcsoNo: response.data.pcsoNo,
        pcsoDate: response.data.pcsoDate,
        ammendmentNo: response.data.ammendmentNo || "",
        ammendmentDate: response.data.ammendmentDate || null,
        ammendmentNo2: response.data.ammendmentNo2 || "",
        ammendmentDate2: response.data.ammendmentDate2 || null,
        ammendmentNo3: response.data.ammendmentNo3 || "",
        ammendmentDate3: response.data.ammendmentDate3 || null,
        ammendmentNo4: response.data.ammendmentNo4 || "",
        ammendmentDate4: response.data.ammendmentDate4 || null,
        ammendmentNo5: response.data.ammendmentNo5 || "",
        ammendmentDate5: response.data.ammendmentDate5 || null,
        ApproveButton: response.data.salesOrderDTO.ApproveButton || false,
        RejectButton: response.data.salesOrderDTO.RejectButton || false,
        UpdateButton: response.data.salesOrderDTO.UpdateButton || true,
      };
      yield put(getJuteGovtDeliveryOrderHeader(Header));
    }
    yield put(SalesOrderViewByIdForJuteGovtSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* JuteGovtOtherCharges({ payload: { url, history } }) {
  const response = yield call(getListurl, url);
  if (response.data.deliveryOrderDTO.deliveryOrderLineItemDTO) {
    if (response.data.deliveryOrderDTO.deliveryOrderLineItemDTO.length !== 0) {
      var JuteGovtOtherCharges = [];
      response.data.deliveryOrderDTO.deliveryOrderLineItemDTO.map((item, i) => {
        var createddate =
          item.createdDate !== null && item.createdDate.split("T");
        JuteGovtOtherCharges.push({
          id: i,
          regularDeliveryOrderLineItemId: item.regularDeliveryOrderLineItemId,
          createdBy: item.createdBy,
          createdDate: createddate[0],
          regDeliveryOrderId: response.data.deliveryOrderDTO.regDeliveryOrderId,
          companyId: item.companyId,
          salesId: response.data.deliveryOrderDTO.salesOrderId,
          salesLineItemId: item.salesLineItemId,
          itemGroupId: item.itemGroupId,
          itemGroupName: item.itemGroupName,
          itemGroupValue: item.itemGroupValue,
          itemId: item.itemId,
          itemName: item.itemName,
          itemCode: item.itemCode,
          itemValue: item.itemValue,
          hsncode: item.hsncode,
          itemMake: item.itemMake,
          quantity: item.quantity,
          uom: item.uom,
          rate: item.rate,
          invoiceQty: "",
          igstAmount: item.igstAmount == null ? "0.00" : item.igstAmount,
          igstPercent: item.igstPercent == null ? "0" : item.igstPercent,
          cgstAmount: item.cgstAmount == null ? "0.00" : item.cgstAmount,
          cgstPercent: item.cgstPercent == null ? "0" : item.cgstPercent,
          sgstAmount: item.sgstAmount == null ? "0.00" : item.sgstAmount,
          sgstPercent: item.sgstPercent == null ? "0" : item.sgstPercent,
          cessAmount: "",
          discountType: item.discountType,
          discount: item.discount,
          discountName: item.discountName,
          discountedRate: item.discountedRate,
          netAmount: item.netAmount,
          totalAmount: item.totalAmount,
          remarks: item.remarks,
          balesPacketSlNo: "",
          isActive: item.isActive,
          govtIsActive:item.govtIsActive,
          status: item.status,
          updatedBy: null,
          updatedOn: null,
          govtDeliveryOrderLineItemId: item.govtDeliveryOrderLineItemId,
          govtDeliveryOrderId: item.govtDeliveryOrderId,
          govtDeliveryRateUom: item.govtDeliveryRateUom,
          govtDeliveryRateUom2: item.govtDeliveryRateUom2,
          govtDeliveryQuantityUom: item.govtDeliveryQuantityUom,
          govtDeliveryQuantityUom2: item.govtDeliveryQuantityUom2,
          govtDeliveryBalesPacketSlNo: item.govtDeliveryBalesPacketSlNo,
          govtUpdatedBy: item.govtUpdatedBy,
          govtUpdatedOn: item.govtUpdatedOn,
        });
      });
      yield put(
        JuteGovtDeliveryOrderOtherChargesSuccessfull(JuteGovtOtherCharges)
      );
    }
  }
}

function* getDelOrderJuteGovtItemByItemGroupData({
  payload: { url, data, history, rowId },
}) {
  const { JuteGovtDeliveryOrderLineItems } = yield select(
    (state) => state.DeliveryOrderReducer
  );
  try {
    const response = yield yield call(getList, url, data);
    if (response) {
      yield put(getJuteGovtDelOrderItemsByItemGroupIdSuccessfull({ response }));
      if (response) {
        let ListFilter = response.filter((data) => {
          if (data.value !== "0") {
            return data;
          }
        });
        var list = [];
        ListFilter.map((prop) => {
          list.push({
            label: prop.label,
            name: prop.name,
            value: prop.value,
            variant: prop.itemCode,
          });
        });
      }
    }
    if (JuteGovtDeliveryOrderLineItems) {
      var LineItemsRecords = [];
      if (JuteGovtDeliveryOrderLineItems.length !== 0) {
        LineItemsRecords = JuteGovtDeliveryOrderLineItems.map((item) => {
          if (rowId == item.id) {
            item.ItemNameData = list;
          }
          return item;
        });
        yield put(getJuteGovtDeliveryOrderLineItems(LineItemsRecords));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

// ------------------------------

export function* watchDeliveryOrderList() {
  yield takeEvery(DELIVERY_ORDER_LIST, getDeliveryOrderListData);
  yield takeEvery(UPLOAD_DO_FILES, UploadedDoFileList);
  yield takeEvery(GET_DO_FILES, getAllDoFileList);
  yield takeEvery(DELETE_DO_FILE, deleteDoFile);
  yield takeEvery(GET_SO_DROPDOWN, getSoDropDown);
  yield takeEvery(ACCOUNT_LIST,getBankDetailsData);
  yield takeEvery(SALES_ORDER_VIEW_BY_ID, SalesOrderDataViewById);
  yield takeEvery(CREATE_DELIVERY_ORDER, setDeliveryOrder);
  yield takeEvery(GET_ALL_TRANSPORTER, getTransporterData);
  yield takeEvery(
    REGULAR_DELIVERY_ORDER_VIEWBY_ID,
    RegularDeliveryOrderViewBYIdData
  );
  yield takeEvery(UPDATE_REGULAR_DELIVERY_ORDER, UpdateDeliveryOrderData);
  yield takeEvery(CREATE_JUTE_GOVT_DELIVERY_ORDER, CreateJuteGovtDeliveryOrder);
  yield takeEvery(UPDATE_JUTE_GOVT_DELIVERY_ORDER, UpdateJuteGovtDeliveryOrder);
  yield takeEvery(
    JUTE_GOVT_DELIVERY_ORDER_VIEWBY_ID,
    JuteGovtDeliveryOrderViewByIdData
  );
  yield takeEvery(
    SALES_ORDER_VIEW_BY_ID_FOR_JUTE_HESSIAN,
    SalesOrderDataViewByIdForJuteHessian
  );
  yield takeEvery(
    SALES_ORDER_VIEW_BY_ID_FOR_JUTE_YARN,
    SalesOrderDataViewByIdForJuteYarn
  );
  yield takeEvery(CREATE_JUTE_YARN_DELIVERY_ORDER, CreateJuteYarnDeliveryOrder);
  yield takeEvery(
    JUTE_YARN_DELIVERY_ORDER_VIEW_BY_ID,
    JuteYarnDeliveryOrderViewBYIdData
  );
  yield takeEvery(UPDATE_JUTE_YARN_DELIVERY_ORDER, UpdateJuteYarnDeliveryOrder);
  yield takeEvery(
    CREATE_JUTEHESSIAN_DELIVERY_ORDER,
    CreateDeliveryOrderForJuteHessian
  );
  yield takeEvery(
    DELIVERY_ORDER_JUTEHESSIAN_BY_ID,
    JuteHessianDeliveryOrderViewBYIdData
  );
  yield takeEvery(
    UPDATE_JUTEHESSIAN_DELIVERY_ORDER,
    UpdateDeliveryOrderForJuteHessian
  );
  yield takeEvery(
    SALES_ORDER_VIEW_BY_ID_FOR_JUTE_GOVT,
    SalesOrderDataViewByIdForJuteGovt
  );
  yield takeEvery(JUTE_GOVT_DELIVERY_ORDER_OTHER_CHARGES, JuteGovtOtherCharges);
  yield takeEvery(
    DELIVERY_ORDER_ITEMS_BY_ITEM_GROUP_ID,
    getDeliveryOrderItemByItemGroupData
  );
  yield takeEvery(
    JUTE_GOVT_DELORDER_ITEMS_BY_ITEM_GROUP_ID,
    getDelOrderJuteGovtItemByItemGroupData
  );
}

function* DeliveryOrderSaga() {
  yield all([fork(watchDeliveryOrderList)]);
}

export default DeliveryOrderSaga;
