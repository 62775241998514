import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import DynamicSelect from "../../components/Dropdown/Master/DynamicSelectMasters";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextFieldNormal from "../../components/TextField/TextFieldNormal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { getBranchList } from "../../store/Global/DropDownApis/actions";
import { getProjectList } from "../../store/Global/DropDownApis/actions";
import { getStatusList } from "../../store/Global/DropDownApis/actions";
import { getSupplierList } from "../../store/Global/DropDownApis/actions";

class InwardFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      supplier: "",
      supplierValue: "",
      projectListData: [],
      branchListData: [],
      statusListData: [],
      supplierListData: [],
      project: "",
      projctValue: "",
      branch: "",
      branchValue: "",
      status: "",
      fromDate: moment().day(-7).format("YYYY-MM-DD").toString(),
      toDate: moment().format("YYYY-MM-DD").toString(),
      statusValue: null,
      inwardNum: "",
      poNum: "",
    };
  }
componentDidMount(){
  const financialYearStartMonth = 3; // Assuming financial year starts in April
  let financialYearStart = moment()
    .year(localStorage.getItem("acadamicYear"))
    .month(financialYearStartMonth)
    .startOf("month");

  const fromDate = financialYearStart.day(-7).format("YYYY-MM-DD");
  const toDate = moment().format("YYYY-MM-DD");

  this.setState({
    fromDate:fromDate,
    toDate:toDate ,
  })
}
  componentWillReceiveProps(props) {

    if (props.projectList && Array.isArray(props.projectList.data)) {
      let data = props.projectList.data
      if (Array.isArray(data) || (typeof data === "object" && data !== null)) {
      const newObj ={
        value:"",
        name:"Select...",
        label:"Select..."
      }
      const newArray = [
        newObj,
        ...(Array.isArray(data) ? data : Object.values(data)),
      ];
        this.setState({
          projectListData: newArray,
        });
      }
    }
    if (props.branchList) {
      this.setState({
        branchListData: props.branchList.data,
      });
    }
    if (props.statusList) {
      this.setState({
        statusListData: props.statusList.data,
      });
    }
    if (props.supplierList) {
      this.setState({
        supplierListData: props.supplierList.data,
      });
    }
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "project") {
      this.setState({
        projctValue: selectedValue,
        project: selectedName,
      });
    }
    if (name === "Supplier") {
      this.setState({
        supplierValue: selectedValue,
        supplier: selectedName,
      });
    }

    if (name === "branch") {
      this.setState({
        branchValue: selectedValue,
        branch: selectedName,
      });
    }

    
    if (name === "status") {
      this.setState({
        statusValue: selectedValue,
        status: selectedName,
      });
    }
  };
  handleSelectDate = (e, name) => {
    if (name === "FromDate") {
      let date = moment(e).format();
      let formattedFromDate = date.split("T", 1).toString();

      this.setState({
        fromDate: formattedFromDate,
      });
    }

    if (name === "ToDate") {
      let date = moment(e).format();
      let formattedToDate = date.split("T", 1).toString();

      this.setState({
        toDate: formattedToDate,
      });
    }
  };
  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
  };

  handleSubmit = () => {
    var data = {
      fromDate: moment(this.state.fromDate, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      ),
      toDate: moment(this.state.toDate, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      ),
      project: this.state.project,
      projctValue: this.state.projctValue,
      branch: this.state.branch,
      branchValue: this.state.branchValue,
      supplier: this.state.supplier,
      supplierValue: this.state.supplierValue,
      Status: this.state.status,
      StatusValue: this.state.statusValue,
      inwardNum: this.state.inwardNum,
      poNum: this.state.poNum,
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid spacing={0}>
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
              </div>
            </div>
            <div className="procurementFilter">
              <Grid container spacing={1} className="filterBlockContainer">
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <div className="TextFieldBlock">
                      <label>From Date</label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="dd-MM-yyyy"
                        value={this.state.fromDate}
                        fullWidth
                        onChange={(e) => this.handleSelectDate(e, "FromDate")}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input ref={inputRef} {...inputProps} />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <div className="TextFieldBlock">
                      <label>To Date</label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="dd-MM-yyyy"
                        value={this.state.toDate}
                        fullWidth
                        onChange={(e) => this.handleSelectDate(e, "ToDate")}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input ref={inputRef} {...inputProps} />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Inward Number"
                      placeholder="Enter here"
                      value={this.state.inwardNum}
                      onChange={this.onhandlechange("inwardNum")}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="PO Number"
                      placeholder="Enter here"
                      value={this.state.poNum}
                      onChange={this.onhandlechange("poNum")}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={
                        this.state.supplierListData &&
                        this.state.supplierListData.map((item) => ({
                          label: item.label,
                          value: item.value,
                          name: item.label,
                        }))
                      }
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.supplier}
                      update={this.state.supplier ? 1 : 0}
                      name="Supplier"
                      label="Supplier"
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={
                        this.state.projectListData &&
                        this.state.projectListData.map((item) => ({
                          label: item.name,
                          value: item.projectId,
                          name: item.name,
                        }))
                      }
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.projctValue ? 1 : 0}
                      update={this.state.projctValue ? 1 : 0}
                      name="project"
                      label="Project"
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={
                        this.state.branchListData &&
                        this.state.branchListData.map((item) => ({
                          label: item.label,
                          name: item.label,
                          value: item.value,
                        }))
                      }
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.branchValue ? 1 : 0}
                      update={this.state.branchValue ? 1 : 0}
                      name="branch"
                      label="Branch"
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                     arrayOfData={this.state.statusListData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.statusValue }
                      update={this.state.statusValue ? 1:0 }
                      name="status"
                      label="Status"
                    />
                  </div>
                </Grid>
                
              </Grid>
            </div>
            <div className="consoleFormButtonBlock">
              <CustomButton
                label={"Search"}
                className="greenButton"
                handleClick={this.handleSubmit}
                type="sumbit"
              />
            </div>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  const { indentList, indentListColumns } = state.Indent;

  const { branchList, projectList, statusList, supplierList } =
    state.DropDownListReducer;
  return {
    indentList,
    indentListColumns,
    projectList,
    branchList,
    statusList,
    supplierList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getBranchList,
    getProjectList,
    getStatusList,
    getSupplierList,
  })(InwardFilter)
);
