export const MATERIAL_REQUEST_LIST_SUCCESSFULL = "material_request_successfull";
export const MATERIAL_REQUEST_LIST = "material_request_list";
export const API_ERRORS = 'api_failed';
export const GET_CUSTOMER_LIST = "get_customer_list";
export const GET_CUSTOMER_LIST_SUCCESSFULL = "get_customer_list_successfull";
export const GET_PROJECT_LIST = "get_project_list";
export const GET_PROJECT_LIST_SUCCESSFULL = "get_project_list_successfull";
export const GET_ITEM_GROUP_BY_ID = "get_item_group_by_id";
export const GET_ITEM_GROUP_BY_ID_SUCCESSFULL = "get_item_group_by_id_successfull";
export const GET_SR_STOCK_LIST = "get_sr_stock_list";
export const GET_SR_STOCK_LIST_SUCCESSFULL = "get_sr_stock_list_successfull";
export const GET_SR_LIST = "get_sr_list";
export const GET_SR_LIST_SUCCESSFULL = "get_sr_list_successfull";
export const GET_MACHINE_NO_LIST = "get_machine_no_list";
export const GET_MACHINE_NO_LIST_SUCCESSFULL = "get_machine_no_list_successfull";
export const ADD_MATERIAL_REQUEST = "add_material_request";
export const ADD_MATERIAL_REQUEST_SUCCESSFULL = "add_material_request_successfull";
export const ITEM_LIST = "item_list";
export const ITEM_LIST_SUCCESSFULL = "item_list_successfull";
export const CREATE_STORE_ISSUE_LINE_ITEM = "create_store_issue_line_item";
export const CREATE_STORE_ISSUE_LINE_ITEM_SUCCESSFULLY = "create_store_issue_linewise_succesfully";
export const STORE_ISSUE_LINE_ITEM = "store_issue_line_item";
export const STORE_ISSUE_VIEW_BY_ID = "store_issue_view_by_id";
export const STORE_ISSUE_VIEW_BY_ID_SUCCESSFULL = "store_issue_view_by_id_successfull";
export const STORE_HEADER_DATA = "store_header_data";
export const BRANCH_WISE_STOCK_LIST = "branch_wise_stock_list";
export const BRANCH_WISE_STOCK_LIST_SUCCESSFULL = "branch_wise_stock_list_successfull";
export const CLEAR_HEADER_DATA='clear_header_data'
export const GETITEMSLISTFORCONSUMPTION =  "getItemsListForConsumption"
export const GETITEMSLISTFORCONSUMPTION_SUCCESSFULL =  "getItemsListForConsumption_successfull";
export const GETITEMGROUPSFORCONSUMPTION="getItemgroupsForConsumption"
export const GETITEMGROUPSFORCONSUMPTION_SUCCESSFULL="getItemgroupsForConsumption_successfull";
export const GETSRNOFORCONSUMPTION =  "getSrNoForConsumption"
export const GETSRNOFORCONSUMPTION_SUCCESSFULL =  "getSrNoForConsumption_successfull";
export const GETCOSTFACTORCONSUMPTION =  "getCostFactorConsumption"
export const GETCOSTFACTORCONSUMPTION_SUCCESSFULL =  "getCostFactorConsumption_successfull";

// export const UNMOUNT_CREATE_STORE_ISSUE_LINE_ITEM="UNMOUNT_CREATE_STORE_ISSUE_LINE_ITEMS"
