import {BRANCH_MASTER_LIST,BRANCH_MASTER_LIST_SUCCESSFULL,CREATE_BRANCH_MASTER_SUCCESSFULL,CREATE_BRANCH_MASTER,
    UPDATE_BRANCH_MASTER,UPDATE_BRANCH_MASTER_SUCCESSFULL,
    API_ERRORS,
    DELETE_BRANCH_FILES,
    DELETE_BRANCH_FILES_SUCCESSFULL,
    GET_ALL_FILES,
    GET_ALL_FILES_SUCCESSFULL,
    UPLOAD_BRANCH_FILES,
    UPLOAD_BRANCH_FILES_SUCCESSFULL,} from './actionTypes';

export const branchMasterList = (url,data,history) =>{
    return {
        type:BRANCH_MASTER_LIST,
        payload:{url,data,history}
    }
}
export const branchMasterListSuccessfull = (data)=>{
    return {
        type:BRANCH_MASTER_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createBranchMaster = (url, data, isPOandWO,history) => {
    return {
        type: CREATE_BRANCH_MASTER,
        payload: { url, data, isPOandWO,history }
    }
}

export const createBranchMasterSuccessfull = (data) => {
    return {
        type: CREATE_BRANCH_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const updateBranchMaster = (url, data, history,isPOWOActive) => {
    return {
        type: UPDATE_BRANCH_MASTER,
        payload: { url, data, history,isPOWOActive }
    }
}

export const updateBranchMasterSuccessfull = (data) => {
    return {
        type: UPDATE_BRANCH_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const  deleteBranchMasterFiles=(url,history,id)=>{
    return{
        type: DELETE_BRANCH_FILES,
        payload:{url,history,id}
    }
}
export const deleteBranchMasterFilesSuccessfull=(data)=>{
    return{
        type:DELETE_BRANCH_FILES_SUCCESSFULL,
        payload:data
    }
}
export const getBranchFiles=(url,history)=>{
    return{
        type:GET_ALL_FILES,
        payload:{url,history}
    }
}
export const getBranchFilesSuccessfull=(data)=>{
    return{
        type:GET_ALL_FILES_SUCCESSFULL,
        payload:data
    }
}
export const UploadBranchMasterFiles=(url,data,history,id)=>{
    return{
        type:UPLOAD_BRANCH_FILES,
        payload:{url,data,history,id}
    }
}
export const UploadBranchMasterFilesSuccessfull=(data)=>{
    return{
        type:UPLOAD_BRANCH_FILES_SUCCESSFULL,
        payload:data
    }
}
export const apiErrors = (error) => {
    return {
        type: API_ERRORS,
        payload: error,
    };
};