import {
  FINISHING_ENTRY_LIST,
  FINISHING_ENTRY_LIST_SUCCESSFULL,
  FINISHING_ENTRY_LIST_DELETE,
  FINISHING_ENTRY_LIST_DELETE_SUCCESSFULL,
  GET_PROCESS_MASTER_LIST,
  GET_PROCESS_MASTER_LIST_SUCCESSFULL,
  GET_ALL_MACHINE_DETAILS_DD,
  GET_ALL_MACHINE_DETAILS_DD_SUCCESSFULL,
  ADD_FINISHING_ENTRY,
  ADD_FINISHING_ENTRY_SUCCESSFULL,
  DELETE_FINISHING_ENTRY_DATA,
  DELETE_FINISHING_ENTRY_DATA_SUCCESSFULL
} from "./actionTypes";

export const finishingEntryActionList = (url, data, history) => {
  return {
    type: FINISHING_ENTRY_LIST,
    payload: { url, data, history },
  };
};
export const finishingEntryActionListSuccessfull = (data) => {
  return {
    type: FINISHING_ENTRY_LIST_SUCCESSFULL,
    payload: data,
  };
};



export const finishingEntryActionListDelete = (url, data, history) => {
  return {
    type: FINISHING_ENTRY_LIST_DELETE,
    payload: { url, data, history },
  };
};
export const finishingEntryActionListDeleteSuccessfull = (data) => {
  return {
    type: FINISHING_ENTRY_LIST_DELETE_SUCCESSFULL,
    payload: data,
  };
};
/***************************Get Process Master list****************************/
export const getProcessMasterList = (url, data, history) => {
  return {
    type: GET_PROCESS_MASTER_LIST,
    payload: { url, data, history },
  };
};
export const getProcessMasterListSuccessfull = (data) => {
  return {
    type: GET_PROCESS_MASTER_LIST_SUCCESSFULL,
    payload: data,
  };
};
/******************************Get All Machine Details*************************/
export const getMachineDetailsAction = (url, data, history) => {
  return{
    type: GET_ALL_MACHINE_DETAILS_DD,
    payload: { url, data, history},
  }
}
export const getMachineDetailsActionSuccessfull = (data) => {
  return{
    type: GET_ALL_MACHINE_DETAILS_DD_SUCCESSFULL,
    payload: data,
  }
}
// add finishing entry
export const addFinishingEntry = (url, data, history) => {
  return {
    type: ADD_FINISHING_ENTRY,
    payload: { url, data, history },
  };
};
export const addFinishingEntrySuccessfull = (data) => {
  return {
    type: ADD_FINISHING_ENTRY_SUCCESSFULL,
    payload: data,
  };
};

export const deleteFinishingEntry = (url,history,fromDate, toDate) => {
  return {
    type: DELETE_FINISHING_ENTRY_DATA,
    payload: { url,history,fromDate, toDate},
  };
};
export const deleteFinishingEntrySuccessfull = (data) => {
  return {
    type: DELETE_FINISHING_ENTRY_DATA_SUCCESSFULL,
    payload: data,
  };
};