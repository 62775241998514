import { WORKER_CATEGORY_LIST, CREATE_WORKER_CATEGORY, UPDATE_WORKER_CATEGORY } from './actionTypes';
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { postAdd, getList } from '../../../helpers/Server_Helper';
import { workerCategoryListSuccessfull, createWorkerCategorySuccessfull, updateWorkerCategorySuccessfull } from './actions';
import swal from "sweetalert";

function* WorkerCategoryList({ payload: { url, data, history } }) {
    try {
        const response = yield call(getList, url, data);
        yield put(workerCategoryListSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
function* CreateWorkerCategory({ payload: { url, data, history } }) {
    try {
        const response = yield call(postAdd, url, data);
        yield put(createWorkerCategorySuccessfull({ response }));
        if (response.data.status === true) {
            swal(response.data.message, { icon: "success" })
            history.push("/Worker_Category_Master");
        } else {
            swal(response.data.message, { icon: "error" })
        }
    } catch (error) {
        if (error.response) {
            if (error.response.data.status) {
              swal(error.response.data.title, { icon: "error" });
              console.log(error);
            }
          }
        // swal("error:" + error, { icon: "danger" });
        // console.log(error)
    }
}
function* UpdateWorkerCategory({ payload: { url, data, history } }) {
    try {
        const response = yield call(postAdd, url, data);
        yield put(updateWorkerCategorySuccessfull({ response }));
        if (response.data.status === true) {
            swal(response.data.message, { icon: "success" })
            history.push("/Worker_Category_Master");
        } else {
            swal(response.data.message, { icon: "error" })
        }
    } catch (error) {
        if (error.response) {
            if (error.response.data.status) {
              swal(error.response.data.title, { icon: "error" });
              console.log(error);
            }
          }
        // console.log(error)
        // swal("error:" + error, { icon: "danger" });
    }
}


export function* watchWorkerCategoryList() {
    yield takeEvery(WORKER_CATEGORY_LIST, WorkerCategoryList)
}
export function* watchCreateWorkerCategory() {
    yield takeEvery(CREATE_WORKER_CATEGORY, CreateWorkerCategory)
}
export function* watchUpdateWorkerCategory() {
    yield takeEvery(UPDATE_WORKER_CATEGORY, UpdateWorkerCategory)
}

function* WorkerCategorySaga() {
    yield all([
        fork(watchWorkerCategoryList),
        fork(watchCreateWorkerCategory),
        fork(watchUpdateWorkerCategory)
    ])
}



export default WorkerCategorySaga;