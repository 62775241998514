import React, { Component } from "react";
import { Grid, Box, Button, Tooltip } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi, serverConfig } from "../../../helpers/Consts";
import { getItemsListForIndent } from "../../../store/Global/DropDownApis/actions";
import {
  InwardLineItemsProps,
  getFilesofInward,
  getFilesofSRSuccessfull,
} from "../../../store/Purchase/Inward/actions";
import {
  getDepartmentList,
  allItemGroupMasterList,
  getParentBudgetHeads,
} from "../../../store/Global/DropDownApis/actions";
import { isEmpty } from "../../../Utilities/helper";

import Fade from "@mui/material/Fade";
import CustomButton from "../../../components/Buttons/Buttons";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import { getFileDownload } from "../../../helpers/server.js";
import {
  getPendingStockPoandReceipt,
  getPendingStock,
  actions,
  logs,
  getFiles,
} from "../../../store/Purchase/Indent/actions";
import {
  postUploadFileList,
  deleteFile,
} from "../../../store/MyProfile/UploadFile/actions";
import { getwareHouseDataList } from "../../../store/StoreModule/StockTransfer/actions";
import { getTax_List } from "../../../store/Global/DropDownApis/actions";
import DeleteIcons from "../../../assets/images/picEditIcon.png";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import swal from "sweetalert";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal.js";

class StoreRecepitLineItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      rowCount: 0,
      amount: 0,
      itemsList: [],
      taxList: [],
      files_List: [],
      fileName: "",
      fileType: "",
      lineItemId: 1,
      delete: false,
      deletedId: "",
      Inward_LineItems: this.props.Inward_LineItems,
      Logs: [],
      DepartmentList: [],
      AllItemGroupMasterList: [],
      termsNconditions: [],
      ItemGroupMasterList: [],
      parentBudgetHeadsList: [],
      wareHouseList: [],
      internalNote: this.props.InwardFillDetails.internalNote,
      igstPercentage: 0,
      cgstPercentage: 0,
      sgstPercentage: 0,
      AdvancePercentage: this.props.InwardFillDetails.AdvancePercentage,
      AdvanceAmount: this.props.InwardFillDetails.AdvanceAmount,
      termsNcondition: this.props.InwardFillDetails.termsNcondition,
      tcsPercentage: this.props.InwardFillDetails.tcsPercentage,
      tcsPercentageValue: this.props.InwardFillDetails.tcsPercentageValue,
      tdsPercentage: this.props.InwardFillDetails.tdsPercentage,
      tdsPercentageValue: this.props.InwardFillDetails.tdsPercentageValue,
      checkRoundOffValue: this.props.InwardFillDetails.checkRoundOffValue,
      selectTermsNconditions:
        this.props.InwardFillDetails.selectTermsNconditions,
      toggle: Math.sign(this.props.InwardFillDetails.roundOffValue) === -1,
      uploadFile: false,
      seeLogs: false,
      roundOffPos: false,
      roundOffNeg: false,
      locationState: "",
      tcsTdsValue: this.props.InwardFillDetails.tcsTds,
      tcsTds: this.props.InwardFillDetails.tcsTds,
    };

  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    this.setState({
      locationState: this.props.location,

    });
    if (this.props.InwardFillDetails.roundOffValue < 0) {
      this.setState({
        roundOffPos: true,
      });
    } else {
      this.setState({
        roundOffPos: false,
      });
    }

    if (this.props.Upload_File_List) {
      if (this.props.location.state) {
        let id = this.props.location.state.rowData.id;
        this.props.getFilesofInward(
          serverApi.GET_FILES +
            "18/" +
            id +
            "/" +
            localStorage.getItem("companyId"),
          this.props.history,
          18,
          "SR"
        );
      }
    }
    const data = {
      branchId: "", //this.props.headerData.branchId,
      companyId: localStorage.getItem("companyId"),
      type: "S",
    };
    this.props.getwareHouseDataList(
      serverApi.WARE_HOUSE_LIST,
      data,
      this.props.history
    );
  }

  componentWillReceiveProps(props) {
    if (props.wareHouseList) {
      if (props.wareHouseList) {
        let list = props.wareHouseList.filter((data) => {
          if (data.value !== 0) {
            return data;
          }
        });
        this.setState({
          wareHouseList: list,
        });
      }
    }

    if (props.Inward_LineItems) {
      this.setState({
        Inward_LineItems: props.Inward_LineItems,
      });
    }
    if (props.logs_List) {
      this.setState({
        Logs: props.logs_List.data,
      });
    }
    if (this.props.InwardFillDetails) {
      let taxType = this.props.InwardFillDetails.taxType;
      if (taxType === 1 || taxType === 3) {
        this.setState({
          igstPercentage: 1,
          cgstPercentage: 0,
          sgstPercentage: 0,
        });
      } else {
        this.setState({
          igstPercentage: 0,
          cgstPercentage: 1,
          sgstPercentage: 1,
        });
      }
    }
    if (this.state.seeLogs === false) {
      if (props.srfilesList) {
        if (props.srfilesList.data) {
          let filesList = props.srfilesList.data;
          this.setState({
            files_List: filesList.data,
          });
        }
      }
    }
    if (this.state.uploadFile === true) {
      if (this.state.fileName !== "") {
        if (props.Upload_File_List) {
          var Filedata = props.Upload_File_List.data;
          if (props.Upload_File_List.status) {
            this.setState({
              uploadFile: false,
            });
            if (this.state.files_List !== undefined) {
              var arr = this.state.files_List;
              Filedata = {
                fileUploadId: Filedata.id,
                fileName: this.state.fileName,
                fileExtension: this.state.fileType,
                retrievalPath: Filedata.fileUrl,
                sourceMenu: 2,
              };
              arr.splice(arr.length, 0, Filedata);
              this.setState({
                files_List: arr,
              });
            }
          }
        }
      }
    }
  }

  handleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    this.setState({ [key]: event });
    if (key === "qty") {
      row.inwardQty = Number(value);
      row.amount = Number(row.rate) * Number(row.inwardQty);
    }
    if (key === "Rate") {
      row.rate = Number(value);
      row.amount = Number(row.rate) * Number(row.inwardQty);
    }
  };

  onClickToSeeLogs = () => {
    this.setState({
      seeLogs: true,
    });
    if (this.props.location.state) {
      let id = this.props.location.state.rowData.id;
      this.props.logs(serverApi.SEE_THE_LOGSOF_INWARD + id, this.props.history);
    }
  };

  handleChangetextAreas = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "internalNote") {
      this.props.InwardFillDetails.internalNote = event;
      this.setState({
        internalNote: event,
      });
    }
  };
  handleChangedropdown = (selectedValue, selectedName, name, selectedRow) => {
    if (name === "Tax") {
      selectedRow.tax = selectedValue;
      let taxPercentage = selectedName.split("^");
      selectedRow.taxPercentage = taxPercentage[1];
      if (this.props.InwardFillDetails.taxType === 1) {
        selectedRow.igstPercentage = Number(taxPercentage[1]);
      } else {
        selectedRow.cgstPercentage = Number(taxPercentage[1] / 2);
        selectedRow.sgstPercentage = Number(taxPercentage[1] / 2);
      }
    }

    if (name === "StoreNo") {
      let Inward_LineItems = this.state.Inward_LineItems.filter((data) => {
        if (data.id === selectedRow.id) {
          selectedRow.warehouseno = selectedValue;
          this.props.InwardFillDetails.warehouseno = selectedValue;
        }
        return data;
      });
      this.setState({
        Inward_LineItems: Inward_LineItems,
      });
      this.props.InwardLineItemsProps(Inward_LineItems);
    }
  };
  onhandleChangeDropdown = (selectedValue, selectedName, name) => {
    if (name === "TcsPercentage") {
      this.setState({
        tcsPercentage: selectedName,
        tcsPercentageValue: selectedValue,
        tdsPercentage: "",
        tdsPercentageValue: "",
        checkRoundOffValue: "",
      });
      this.props.InwardFillDetails.tcsPercentage = selectedName;
      this.props.InwardFillDetails.tcsPercentageValue = selectedValue;
      this.props.InwardFillDetails.tdsPercentage = "";
      this.props.InwardFillDetails.tdsPercentageValue = "";
    }

    if (name === "TCS/TDS") {
      if (selectedName === "N/A") {
        this.setState({
          tcsTds: "", 
          tcsTdsValue: "",
          tcsPercentageValue: "",
          tdsPercentageValue: "",
          // checkRoundOffValue:""
        });
      } else {
        this.setState({
          tcsTds: selectedValue,
          tcsTdsValue: selectedName,
          // checkRoundOffValue:""
        });
        this.props.InwardFillDetails.tcsTds = selectedValue;
        this.props.InwardFillDetails.tcsTdsValue = selectedName;
      }
    }
  };

  onDelEvent = (event) => {
    this.setState({ delete: true, deletedId: event.target.name });
  };

  onDel = (event) => {
    this.setState({ delete: false });
    if (event.target.value === "Yes") {
      if (this.state.Inward_LineItems.length > 1) {
        let lineItemId = this.state.deletedId;
        var object = this.state.Inward_LineItems.filter((row) => {
          if (row.lineitemId !== lineItemId) {
            return row;
          }
        });

        this.setState({
          Inward_LineItems: object,
          lineItemId: lineItemId,
        });
        this.props.InwardLineItemsProps(object);
      }
    }
  };

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "termsNconditions") {
      this.props.InwardFillDetails.termsNconditions = selectedName;
      this.props.InwardFillDetails.selectTermsNconditions = selectedName;
      this.setState({
        termsNconditions: selectedName,
        selectTermsNconditions: selectedName,
      });
    }
  };

  onhandleChange = (key) => (value) => {
    this.setState({ [key]: value });
    if (key === "advance_per") {
      this.props.PoFillDetails.AdvancePercentage = value;
      this.setState({
        AdvancePercentage: value,
      });
    }
    if (key === "advance_amt") {
      this.props.PoFillDetails.AdvanceAmount = value;
      this.setState({
        AdvanceAmount: value,
      });
    }

    if (key === "TdsPercentage") {
      if (value <= 100) {
        const stringValue = value.toString();
        const [integerPart, decimalPart] = stringValue.split(".");
        const truncatedDecimalPart = decimalPart ? decimalPart.slice(0, 2) : "";
        
        if (decimalPart && decimalPart.length > 2) {
          swal("Can't Enter more than 2 Decimals!");
          return; 
        }
        
        const newValue = decimalPart
          ? `${integerPart}.${truncatedDecimalPart}`
          : stringValue;        
        this.setState({
          tdsPercentageValue: newValue,
          tcsPercentage: newValue,
          tcsPercentageValue: "",
          checkRoundOffValue: "",
        });
      } else {
        swal("Tds Percentage cannot be greater than 100");
      }
      
      this.props.InwardFillDetails.tdsPercentageValue = value;
      this.props.InwardFillDetails.tcsPercentage = value;
      this.props.InwardFillDetails.tcsPercentageValue = "";
      this.props.InwardFillDetails.checkRoundOffValue = "";
    }

    if (key === "TcsPercentage") {
      if (value <= 100) {
        const stringValue = value.toString();
        const [integerPart, decimalPart] = stringValue.split(".");
        const truncatedDecimalPart = decimalPart ? decimalPart.slice(0, 2) : "";

        
        if (decimalPart && decimalPart.length > 2) {
          swal("Can't Enter more than 2 Decimals!");
          return; 
        }

        const newValue = decimalPart
          ? `${integerPart}.${truncatedDecimalPart}`
          : stringValue;
        this.setState({
          tcsPercentage: newValue,
          tcsPercentageValue: newValue,
          tdsPercentage: "",
          tdsPercentageValue: "",
          checkRoundOffValue: "",
        });
      } else {
        swal("Tcs Percentage cannot be greater than 100");
      }

      this.props.InwardFillDetails.tcsPercentage = value;
      this.props.InwardFillDetails.tcsPercentageValue = value;
      this.props.InwardFillDetails.tdsPercentage = "";
      this.props.InwardFillDetails.tdsPercentageValue = "";
    }
  };
  UploadDocuments = (key) => (e) => {
    if (this.props.locationState.state) {
      var inwardId = this.props.locationState.state.rowData.id;
    }
    e.preventDefault();
    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 18);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", inwardId);
    formData.append("createdBy", this.state.userDit.userId);
    formData.append("fileCompanyId", localStorage.getItem("companyId"));
    this.setState({
      fileName: file.name,
      fileType: fileExt,
      uploadFile: true,
    });
    this.props.postUploadFileList(
      serverApi.UPLOADFILE,
      formData,
      this.props.history
    );
  };

  onDeleteFile = (fileId) => {
    let data = this.state.files_List.filter(
      (item) => item.fileUploadId !== fileId
    );
    this.props.deleteFile(serverApi.DELETEFILE + fileId);
    this.setState({
      files_List: data,
    });
    this.props.getFilesofSRSuccessfull({ data });
  };

  toggleState = (roundoff, eventValue) => {
    this.props.InwardFillDetails.checkRoundOffValue = Number(roundoff);
    this.props.InwardFillDetails.roundOffValue = Number(roundoff);

    if (eventValue === true) {
      this.setState({
        toggle: false,
      });
    } else {
      this.setState({
        toggle: true,
      });
    }
    this.setState({
      roundOffPos: !this.state.roundOffPos,
      checkRoundOffValue: Number(roundoff),
    });
  };

  onDelLineItem = (row) => {
    if (this.state.Inward_LineItems == 1) {
      swal("You con't delete the data");
    } else {
      var indexitem = this.state.Inward_LineItems.findIndex(
        (item) => item == row
      );
      var lineItems = this.state.Inward_LineItems;
      lineItems[indexitem].isActive = 0;
      this.setState({
        Inward_LineItems: lineItems,
      });
      this.props.InwardLineItemsProps(lineItems);
    }
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  getAmount = (qty, rate, discountMode, discountPercentage) => {
    let amount = 0;
    if (discountMode) {
      if (discountMode === 1) {
        amount =
          // (Number(qty) - (qtyPo === undefined ? 0 : Number(qtyPo)))
          Number(qty) * Number(rate);
        if (
          discountPercentage !== 0 &&
          discountPercentage !== undefined &&
          discountPercentage !== ""
        ) {
          amount = amount - Number(discountPercentage);
        }
        // return amount;
        return parseFloat(amount).toFixed(2);
      } else {
        amount =
          // (Number(qty) - (qtyPo === undefined ? 0 : Number(qtyPo)))
          Number(qty) * Number(rate);
        if (
          discountPercentage !== 0 &&
          discountPercentage !== undefined &&
          discountPercentage !== ""
        ) {
          let disAmount = (amount * Number(discountPercentage)) / 100;
          amount = amount - disAmount;
        }
        // return amount;
        return parseFloat(amount).toFixed(2);
      }
    } else {
      amount =
        // (Number(qty) - (qtyPo === undefined ? 0 : Number(qtyPo)))
        Number(qty) * Number(rate);
      return parseFloat(amount).toFixed(2);
      // return amount;
    }
  };

  render() {
    var filteredproducts = this.state.Inward_LineItems.filter(
      (item) => item.isActive !== 0 && item.approvedQty !== 0
    );

    var totalAmount = 0;
    var totalIgst = 0;
    var totalCgst = 0;
    var totalSgst = 0;
    var TcsAmount = 0;
    var positiveRF = 0;
    var NegativeRF = 0;
    var showRoundOff = false;
    var TdsAmount = 0;

    // var positiveVL = 0
    // var NegativeVL = 0;

    var IndividualAmount = 0;
    var totalCgstValue = 0;
    if (filteredproducts) {
      filteredproducts.forEach((data) => {
        if (data.approvedQty && data.rate) {
          totalAmount =
            totalAmount +
            (data.approvedQty * data.rate);
            if (Number(data.discountMode !== null) && data.discountMode !== "" ) {
            let amount =
              Number(data.approvedQty) * Number(data.rate);
              if (Number(data.discountMode) ===1 ){
              if (
                Number(data.discountPercentage) !== 0 &&
                Number(data.discountPercentage) !== undefined &&
                Number(data.discountPercentage) !== "" &&
                Number(data.discountPercentage) !== ""
              ) {

                IndividualAmount = amount - Number( data.discountPercentage)
                totalAmount = totalAmount - Number( data.discountPercentage);
               
              }
            } else if (data.discountMode === 2){
              if (
                Number(data.discountPercentage) !== 0 &&
                Number(data.discountPercentage)  !== undefined &&
                Number(data.discountPercentage)  !== "" &&
                Number(data.discountPercentage)  !== null

              ) {
                let disAmount = (amount * Number(data.discountPercentage)) / 100;
                IndividualAmount = amount - disAmount
                totalAmount = totalAmount - disAmount;
                
              }
            }
          }
         
        }

        if (Number(this.props.InwardFillDetails.taxType) === 3) {
          totalIgst = 0;
          totalCgst = 0;
          totalSgst = 0;
        } else {
          if (this.props.InwardFillDetails.taxType === 1) {
            let amountWithDiscount = 0
            if (data.discountMode !== undefined && data.discountMode !== "" && data.discountMode !== null) {
              if (data.discountMode === 1) {
                amountWithDiscount =
                  (data.approvedQty * data.rate) -
                  Number(data.discountPercentage);
                totalIgst = totalIgst + ((amountWithDiscount) * data.taxPercentage) / 100;
              } else if (data.discountMode === 2) {
                amountWithDiscount = ((data.approvedQty * data.rate) *
                  Number(data.discountPercentage)) /
                  100;
                amountWithDiscount = (data.approvedQty * data.rate) - amountWithDiscount
                totalIgst = totalIgst + ((amountWithDiscount) * data.taxPercentage) / 100;
              } else {
                amountWithDiscount = (data.approvedQty * data.rate) - amountWithDiscount
                totalIgst = totalIgst + ((amountWithDiscount) * data.taxPercentage) / 100;
              }
            } else {
            
              totalIgst = totalIgst + ((
                data.approvedQty * data.rate) * Number(data.taxPercentage)) / 100;
            }

          } else {
            let amountWithDiscount = 0
            if (data.discountMode !== undefined && data.discountMode !== "" && data.discountMode !== null) {
              if (data.discountMode === 1) {
                amountWithDiscount =
                  (data.approvedQty * data.rate) -
                  Number(data.discountPercentage);
                totalCgst = totalCgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
                totalSgst = totalSgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;

              } else if (data.discountMode === 2) {
                amountWithDiscount = ((data.approvedQty * data.rate) *
                  Number(data.discountPercentage)) /
                  100;
                amountWithDiscount = (data.approvedQty * data.rate) - amountWithDiscount
                totalCgst = totalCgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
                totalSgst = totalSgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
              } else {
                amountWithDiscount = (data.approvedQty * data.rate) - amountWithDiscount
                totalCgst = totalCgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
                totalSgst = totalSgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
              }
            }
            else{
              amountWithDiscount =(data.approvedQty * data.rate) 
                totalCgst = totalCgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
                totalSgst = totalSgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
            }
          }
        }

      });
    }
    totalIgst = totalIgst.toFixed(2);
    totalCgst = totalCgst.toFixed(2);
    totalSgst = totalSgst.toFixed(2);
    totalAmount = totalAmount.toFixed(2);

    var netTotal =
      Number(totalAmount) +
      Number(totalCgst) +
      Number(totalIgst) +
      Number(totalSgst);

    if (this.state.tcsPercentageValue) {
      TcsAmount =
        (Number(totalAmount) * Number(this.state.tcsPercentageValue)) / 100;
    }
    if (this.state.tdsPercentageValue) {
      TdsAmount =
        (Number(totalAmount) * Number(this.state.tdsPercentageValue)) / 100;
    }

    this.props.InwardFillDetails.tdsAmount = TdsAmount;

    // calculation of netTotal including TcsAmount
    var netTotalwithTcs = parseFloat(netTotal) + parseFloat(TcsAmount);
    netTotalwithTcs = netTotalwithTcs.toFixed(2);
    TcsAmount = TcsAmount.toFixed(2);

    // calculation of netTotal excluding TdsAmount
    var netTotalwithTds = parseFloat(netTotal) - parseFloat(TdsAmount);
    netTotalwithTds = netTotalwithTds.toFixed(2);
    TdsAmount = TdsAmount.toFixed(2);

  
    if (netTotal) {
      // Use toFixed(2) to ensure netTotal is always in the format with 2 decimal places
      let splitNetTotal = netTotal.toFixed(2);
    
      // Split the netTotal into integer and fractional parts
      splitNetTotal =
        TcsAmount !== "0.00" && TdsAmount === "0.00"
          ? netTotalwithTcs.split(".")
          : TdsAmount !== "0.00" && TcsAmount === "0.00"
          ? netTotalwithTds.split(".")
          : splitNetTotal.split(".");
  
      // Parse the fractional part
      const fractionalPart = Number(splitNetTotal[1]);

      if (fractionalPart > 0) {
        showRoundOff = true;
        // Calculate the positive round-off
        positiveRF = (100 - fractionalPart) / 100;
        
        // Calculate the negative round-off
        NegativeRF = fractionalPart / 100;
    
        // Ensure the values are formatted correctly
        positiveRF = parseFloat(positiveRF.toFixed(2));
        NegativeRF = parseFloat((-NegativeRF).toFixed(2));
      }
    }

    if (this.state.toggle === false) {
      if (positiveRF) {
        this.props.InwardFillDetails.roundOffValue = Number(positiveRF);
        if (TcsAmount !== "0.00") {
          if (TcsAmount) {
            netTotalwithTcs = Number(netTotalwithTcs) + Number(positiveRF);
            netTotalwithTcs = netTotalwithTcs.toFixed(2);
          }
        } else if (TdsAmount !== "0.00") {
          if (TdsAmount) {
            netTotalwithTds = Number(netTotalwithTds) + Number(positiveRF);
            netTotalwithTds = netTotalwithTds.toFixed(2);
          }
        } else if (netTotal) {
          netTotal = Number(netTotal) + Number(positiveRF);
          netTotal = netTotal.toFixed(2);
        }
      }
    } else if (this.state.toggle === true) {
      if (NegativeRF) {
        this.props.InwardFillDetails.roundOffValue = Number(NegativeRF);
          if (TcsAmount && TcsAmount !== "0.00") {
            netTotalwithTcs = Number(netTotalwithTcs) + Number(NegativeRF);
            netTotalwithTcs = netTotalwithTcs.toFixed(2);
          } else if (TdsAmount && TdsAmount !== "0.00") {
            netTotalwithTds = Number(netTotalwithTds) + Number(NegativeRF);
            netTotalwithTds = netTotalwithTds.toFixed(2);
          }else if (netTotal !== null) {
            netTotal = Number(netTotal) +  Number(NegativeRF);
            netTotal = netTotal.toFixed(2);
          } 
      }
    }

    this.props.InwardFillDetails.payableAmount = netTotal;
    this.props.InwardFillDetails.totalAmount =
      this.state.tcsTds === "N/A"
        ? netTotal
        : this.state.tcsTds === "TCS"
        ? netTotalwithTcs
        : this.state.tcsTds === "TDS"
        ? netTotalwithTds
        : netTotal;

    this.props.InwardFillDetails.tcsAmount = TcsAmount;

    return (
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent" onScroll={this.handleScroll}>
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {tableHeaders.map((column) => (
                    <>
                      {column.header === "Store No" ? (
                        <th>
                          {column.header}
                          <span style={{ color: "red" }}>*</span>
                        </th>
                      ) : (
                        <th>{column.header}</th>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredproducts.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <>
                      {RowData &&
                        RowData.map((col) =>
                          col.component === "EditableCell" ? (
                            <>
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        type="text"
                                        name={col.type}
                                        id={col.no}
                                        // disabled
                                        Value={
                                          col.type === "qty"
                                            ? row.approvedQty
                                              ? row.approvedQty
                                              : row.qty
                                            : col.type === "Unit"
                                            ? row.uomCode
                                            : col.type === "Rate"
                                            ? row.rate
                                            : col.type === "amount"
                                            ? row.approvedQty
                                              ? parseFloat(
                                                  this.getAmount(
                                                    Number(row.approvedQty),
                                                    row.rate,
                                                    Number(row.discountMode),
                                                    this.state.locationState
                                                      .state !== undefined
                                                      ? row.discount
                                                      : row.discountPercentage
                                                  )
                                                ).toFixed(2)
                                              : row.amount
                                            : col.type === "NetAmount"
                                            ? this.state.amount
                                            : col.type === "department"
                                            ? row.departmentName
                                            : col.type === "itemGroup"
                                            ? row.itemGroupName
                                            : col.type === "item"
                                            ? row.itemName
                                            : col.type === "Tax"
                                            ? row.taxPercentage
                                            : col.type === "Tax Amount"
                                            ? parseFloat(
                                                (row.taxPercentage *
                                                  (Number(row.approvedQty) *
                                                    Number(row.rate))) /
                                                  100
                                              ).toFixed(2)
                                            : col.type === "BudgetHead"
                                            ? row.budgetHeadName
                                            : col.type === "discountMode"
                                            ? row.discountMode !== null
                                              ? row.discountMode === 1
                                                ? "Fixed"
                                                : "Percentage"
                                              : ""
                                            : col.type === "discountPercentage"
                                            ? row.discountPercentage !== null
                                              ? row.discountPercentage
                                              : "0"
                                            : ""
                                        }
                                        onChange={this.handleChange({
                                          key: col.type,
                                          row,
                                        })}
                                        onWheel={(e) => e.target.blur()}
                                        className="inputBlockContainer"
                                        style={{
                                          textAlign:
                                            col.type === "Tax Amount" ||
                                            col.type === "Tax" ||
                                            col.type === "amount" ||
                                            col.type === "Rate" ||
                                            col.type === "qty"
                                              ? "right"
                                              : "",
                                          paddingRight: "8px",
                                          width:
                                            col.type === "item" ||
                                            col.type === "itemGroup"
                                              ? "280px"
                                              : "",
                                        }}
                                        readOnly={true}
                                      />
                                      <span>
                                        {col.type === "discountPercentage"
                                          ? Number(row.discountMode) === 2
                                            ? "%"
                                            : ""
                                          : ""}
                                      </span>
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            </>
                          ) : (
                            <td>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <DynamicSelect
                                      arrayOfData={
                                        col.type === "StoreNo"
                                          ? this.state.wareHouseList
                                          : []
                                      }
                                      className="dropdownBlockContainer"
                                      onSelectChange={this.handleChangedropdown}
                                      isDropdownOpen={this.state.isDropdownOpen}
                                      handleMenuOpen={this.handleMenuOpen}
                                      selected={
                                        col.type === "StoreNo"
                                          ? row.warehouseno
                                          : ""
                                      }
                                      name={col.type}
                                      row={row}
                                      update={row.warehouseno ? 1 : 0}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          )
                        )}
                    </>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="consoleFormContainer">
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 4">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={TCSTDSDropdown}
                          className="dropdownBlockContainer"
                          onSelectChange={this.onhandleChangeDropdown}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.tcsTds}
                          name={"TCS/TDS"}
                          label="TCS/TDS"
                          update={this.state.tcsTdsValue ? 1 : 0}
                        />
                      </div>
                    </Box>

                    <Box gridColumn="span 4">
                      <div className="consoleTextFieldBlock">
                        {this.state.tcsTds === "TCS" ? (
                          <Box gridColumn="span 3">
                            <TextFieldNormal
                              type="number"
                              label="TCS Percentage"
                              placeholder="Enter here"
                              value={this.state.tcsPercentageValue}
                              onChange={this.onhandleChange("TcsPercentage")}
                            />
                          </Box>
                        ) : (
                          ""
                        )}

                        {this.state.tcsTds === "TDS" ? (
                          <Box gridColumn="span 3">
                            <TextFieldNormal
                              type="number"
                              label="TDS Percentage"
                              placeholder="Enter here"
                              value={this.state.tdsPercentageValue}
                              onChange={this.onhandleChange("TdsPercentage")}
                            />
                          </Box>
                        ) : (
                          ""
                        )}
                      </div>
                    </Box>
                    <Box gridColumn="span 4">
                      <div className="consoleTextFieldBlock">
                        <TableContainer className="amountTable">
                          <Table aria-label="simple table">
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="noBorder"
                                >
                                  Total IGST:
                                </TableCell>
                                <TableCell align="right" className="noBorder">
                                  <strong>{totalIgst}</strong>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="noBorder"
                                >
                                  Total SGST:
                                </TableCell>
                                <TableCell align="right" className="noBorder">
                                  <strong>{totalCgst}</strong>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="noBorder"
                                >
                                  Total CGST:
                                </TableCell>
                                <TableCell align="right" className="noBorder">
                                  <strong>{totalSgst}</strong>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="noBorder"
                                >
                                  Net Total:
                                </TableCell>
                                <TableCell align="right" className="noBorder">
                                  <strong>{totalAmount}</strong>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="noBorder"
                                >
                                  {this.state.tcsTds === "N/A" ||
                                  isEmpty(this.state.tcsTds)
                                    ? "TCS/TDS Amount:"
                                    : this.state.tcsTds === "TCS"
                                    ? "TCS Amount:"
                                    : "TDS Amount:"}
                                </TableCell>
                                <TableCell align="right" className="noBorder">
                                  <strong>
                                    {this.state.tcsTds === "N/A" ||
                                    this.state.tcsTds === null
                                      ? "0.00"
                                      : this.state.tcsTds === "TCS"
                                      ? TcsAmount
                                      : this.state.tcsTds === "TDS"
                                      ? TdsAmount
                                      : "0.00"}
                                  </strong>
                                </TableCell>
                              </TableRow>
                              {showRoundOff === true && (
                                <TableRow>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="noBorder"
                                  >
                                    Round Off:
                                  </TableCell>
                                  <TableCell align="right" className="noBorder">
                                    <strong>
                                      <input
                                        type="radio"
                                        id="switch_left"
                                        name="switchToggle"
                                        value={!this.state.checkRoundOffValue}
                                        onChange={() => {
                                          this.toggleState(positiveRF, true);
                                        }}
                                        // checked={!this.state.toggle}
                                        checked={!this.state.roundOffPos}
                                      />
                                      <span>{positiveRF}</span>
                                      <input
                                        type="radio"
                                        id="switch_right"
                                        name="switchToggle"
                                        value={this.state.checkRoundOffValue}
                                        onChange={() => {
                                          this.toggleState(NegativeRF, false);
                                        }}
                                        checked={this.state.roundOffPos}
                                      />
                                      <span>{NegativeRF}</span>
                                    </strong>
                                  </TableCell>
                                </TableRow>
                              )}
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="noBorder"
                                >
                                  Total Amount:
                                </TableCell>
                                <TableCell align="right" className="noBorder">
                                  <strong>
                                    {this.state.tcsTds === null
                                      ? parseFloat(netTotal).toFixed(2)
                                      : this.state.tcsTds === "TCS" &&
                                        this.state.tcsPercentageValue !== null
                                      ? parseFloat(netTotalwithTcs).toFixed(2)
                                      : this.state.tcsTds === "TDS" &&
                                        this.state.tdsPercentageValue !== null
                                      ? parseFloat(netTotalwithTds).toFixed(2)
                                      : this.state.tcsTds === "N/A"
                                      ? parseFloat(netTotal).toFixed(2)
                                      : parseFloat(netTotal).toFixed(2)}

                                  </strong>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className="consoleFormContainer">
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                    className="ducumentUploadBlock uploadedContent"
                  >
                    <Box gridColumn="span 3">
                      <label>Support Documents:</label>
                      <div className="personalDetailsUpload">
                        <div className="ducumentUploadBlock">
                          <div className="documentUploadContent">
                            <span className="btn_upload documentUpload">
                              <img src={panUploadIcon} alt="" />
                              <input
                                type="file"
                                id="imag"
                                title=""
                                className="input-img"
                                onChange={this.UploadDocuments()}
                              />
                              Upload Document
                            </span>
                          </div>
                        </div>
                      </div>
                    </Box>
                    {this.state.files_List &&
                      this.state.files_List.map((item) => (
                        <>
                          <Box
                            gridColumn="span 3"
                            style={{ marginTop: "28px" }}
                          >
                            <div className="documentUploadContent">
                              <div
                                className="uploadedInfo"
                                style={{ display: "flex" }}
                              >
                                <img
                                  src={TickIcon}
                                  alt=""
                                  className="tickIcon"
                                  height={"20px"}
                                  width={"20px"}
                                />
                                <Tooltip
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 600 }}
                                  title="Click to download File"
                                >
                                  <div
                                    className="uplodedFileName"
                                    onClick={() => {
                                      getFileDownload(
                                        serverConfig.SERVER_URL +
                                          "security-api/api/files/downloadfile/" +
                                          item.fileUploadId,
                                        item.fileName,
                                        item.fileExtension
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {item.fileName}
                                  </div>
                                </Tooltip>
                                <Tooltip
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 100 }}
                                  title="Click to Delete File"
                                >
                                  <Button
                                    onClick={() => {
                                      this.onDeleteFile(item.fileUploadId);
                                    }}
                                    className="deleteButton"
                                  >
                                    <img
                                      src={DeleteIcons}
                                      alt=""
                                      height={"20px"}
                                      width={"20px"}
                                    />
                                  </Button>
                                </Tooltip>
                              </div>
                            </div>
                          </Box>
                        </>
                      ))}
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
              <Box gridColumn="span 3" style={{ marginTop: "15px" }}>
                <CustomButton
                  label={"CLICK HERE TO SEE THE LOGS"}
                  className="greenButton"
                  handleClick={this.onClickToSeeLogs}
                  type="sumbit"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="logsContainer">
          <Grid item xs={12}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
              <Box gridColumn="span 6">
                {this.state.Logs &&
                  this.state.Logs.map((item) => {
                    let date = moment(item.actionDate)
                      .format("DD-MM-YYYY")
                      .split("T")
                      .toString();
                    let time = moment(item.actionDate).format().split("T", 2);
                    return (
                      <ul>
                        <li className="logsContent">
                          <strong>{date}</strong>
                          <span> </span>
                          {time[1]}
                          <span> </span>
                          {item.remarks}
                        </li>
                      </ul>
                    );
                  })}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const RowData = [
  {
    no: 1,
    component: "EditableCell",
    type: "department",
  },
  {
    no: 2,
    component: "EditableCell",
    type: "BudgetHead",
  },

  {
    no: 3,
    component: "EditableCell",
    type: "itemGroup",
  },
  {
    no: 4,
    component: "EditableCell",
    type: "item",
  },
  {
    no: 5,
    component: "EditableCell",
    type: "qty",
  },
  {
    no: 6,
    component: "EditableCell",
    type: "Unit",
  },
  {
    no: 7,
    component: "DynamicSelect",
    type: "StoreNo",
  },
  {
    no: 8,
    component: "EditableCell",
    type: "Rate",
  },
  {
    no: 9,
    component: "EditableCell",
    type: "Tax",
  },
  {
    no: 10,
    component: "EditableCell",
    type: "Tax Amount",
  },
  {
    no: 11,
    component: "EditableCell",
    type: "discountMode",
  },
  {
    no: 12,
    component: "EditableCell",
    type: "discountPercentage",
  },
  {
    no: 13,
    component: "EditableCell",
    type: "amount",
  },
];
const tableHeaders = [
  {
    header: "Department",
  },
  {
    header: "Budget Head",
  },
  {
    header: "Item Group",
  },
  {
    header: "Item",
  },
  {
    header: "Quantity",
  },
  {
    header: "Unit",
  },
  {
    header: "Store No",
  },
  {
    header: "Rate",
  },
  {
    header: "Tax",
  },
  {
    header: "Tax Amount",
  },
  {
    header: "Discount Mode",
  },
  {
    header: "Discount/Amount",
  },
  {
    header: "Amount",
  },
];

const TCSPercentages = [
  { label: "0%", value: 0, name: "0%" },
  { label: "0.075%", value: 0.075, name: "0.075%" },
  { label: "0.1%", value: 0.1, name: "0.1%" },
  { label: "1%", value: 1, name: "1%" },
];

const TCSTDSDropdown = [
  { label: "N/A", value: "N/A", name: "N/A" },
  { label: "TCS", value: "TCS", name: "TCS" },
  { label: "TDS", value: "TDS", name: "TDS" },
];
const mapStatetoProps = (state) => {
  const { itemsListForIndent } = state.itemGroupMasterList;
  const { Inward_LineItems, srfilesList } = state.InwardReducer;
  const { taxList } = state.taxList;
  const { files_List, logs_List } = state.IndentReducer;
  const { Upload_File_List } = state.UploadFileReducer;
  const { wareHouseList } = state.StockTransfer_List;

  const { departmentList, allItemGroupMaster_List, parentBudgetHeadsList } =
    state.DropDownListReducer;

  return {
    itemsListForIndent,
    Inward_LineItems,
    departmentList,
    allItemGroupMaster_List,
    parentBudgetHeadsList,
    taxList,
    srfilesList,
    files_List,
    Upload_File_List,
    logs_List,
    wareHouseList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    InwardLineItemsProps,
    postUploadFileList,
    getFilesofInward,
    deleteFile,
    actions,
    logs,
    getFiles,
    getFilesofSRSuccessfull,
    getPendingStockPoandReceipt,
    getPendingStock,
    getTax_List,
    getwareHouseDataList,
  })(StoreRecepitLineItems)
);
