import React, { Component } from "react";
import { Grid, Box, Button, IconButton, Tooltip } from "@mui/material";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import DynamicSelect from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi ,serverConfig} from "../../../helpers/Consts";
import {
  getStateNameList,
  getBranchList,
} from "../../../store/Global/DropDownApis/actions";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import DeleteFileIcon from "../../../assets/images/picEditIcon.png";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import swal from "sweetalert";
import DeleteIcon from "@mui/icons-material/Delete";
import Fade from "@mui/material/Fade";

import {
  UploadCustomerMasterFiles,
  deleteCustomerMasterFiles,
  getCustomerGstVerification,
} from "../../../store/Master/CustomerMaster/actions";
import CheckIcon from "@mui/icons-material/Check";
import { getLedgerGroupsList } from "../../../store/Master/LedgerGroupsMaster/actions";
import { getFileDownload } from "../../../helpers/server.js";

class CustomerLineItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      customerLineitems: this.props.customerLineitems,
      stateNameList: [],
      rowCount: 1,
      branchData: [],
      ledgerGroupsListData: [],
    };
  }

  // function for textfields
  OnhandleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    this.setState({ [key]: event });
    this.setState({
      rowCount: this.state.rowCount + 1,
    });
    let lastRow =
      this.state.customerLineitems[this.state.customerLineitems.length - 1];

    let mname = key.split("_");
    if (mname[0] === "address") {
      if (
        /^[a-zA-Z0-9\s!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/.test(value) ||
        value === ""
      ) {
        row.address = value;
      }
    }

    if (mname[0] === "city") {
      if (/^[a-zA-Z\s]+$/.test(value) || value === "") {
        row.city = value;
      }
    }
    if (mname[0] === "stateCode") {
      if (/^[0-9]+$/.test(value) || value === "") {
        row.stateCode = value;
      }
    }
    if (mname[0] === "gstNo") {
      if (/^[A-Za-z0-9]+$/.test(value)) {
        row.gstNo = value;
      } else {
        swal("Please enter valid GST Number");
      }
    }
    if (mname[0] === "pincode") {
      row.pincode = value;
    }

    if (mname[0] === "contactNo") {
      const phoneNumber = value.replace(/\D/g, "");

      if (/^[0-9]+$/.test(phoneNumber) || phoneNumber === "") {
        row.contactNo = phoneNumber;
      }
    }

    if (mname[0] === "contactPerson") {
      if (/^[a-zA-Z\s]+$/.test(value) || value === "") {
        row.contactPerson = value;
      }
    }

    if (mname[0] === "openningBalance") {
      if (/^[0-9]+(\.[0-9]{0,2})?$/.test(value) || value === "") {
        row.openningBalance = value;

        var TotalOpeningBalance = this.state.customerLineitems
          .filter((item) => item.isActive != 0)
          .map((p) => Number(p.openningBalance))
          .reduce((total, ind) => parseFloat(total) + parseFloat(ind));
        this.props.customerHdr.openningBalance = TotalOpeningBalance;
      }
    }

    if (mname[0] === "accountNumber") {
      if (/^[0-9]+$/.test(value) || value === "") {
        row.accountNumber = value;
      }
    }

    if (mname[0] === "ifscCode") {
      if (/^[a-zA-Z0-9]+$/.test(value) || value === "") {
        row.ifscCode = value;
      }
    }
    if (mname[0] === "ledgerName") {
      row.ledgerName = row.branchName + "_" + this.props.customerHdr.name;
    }
    if (mname[0] === "branchName") {
      row.branchName = value;
    }
  };
  handleAddEvent = () => {
    let rowId = this.state.rowCount;
    let product = {
      address: "",
      city: "",
      createdBy: "",
      customerId: null,
      customerLineId: "",
      gstNo: "",
      isActive: 1,
      pincode: "",
      state: "",
      stateCode: "",
      displayname: null,
      fileUploadId: null,
      GstfileExtension:"",
      GstFileRetrivePath:"", 
            contactNo: "",
      contactPerson: "",
      openningBalance: "",
      accountNumber: "",
      ifscCode: "",
      Bankdisplayname: null,
      BankfileUploadId: null,
      BankFileExtension:"",
      BankRetrivePath:"",
      isGstVerified: 2,
      branchName: "",
      ledgerGroupId: null,
      ledgerGroupDesc: null,
      ledgerId: null,
      ledgerName: "",
      addressType: null,
    };
    this.setState({ rowCount: rowId + 1 });
    this.state.customerLineitems.push(product);
    this.props.UpdateLineItems(this.state.customerLineitems);
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getStateNameList(
      serverApi.GET_STATE_NAME_LIST,
      this.props.history
    );
    this.props.getBranchList(
      serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
      this.props.history
    );
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
    };
    this.props.getLedgerGroupsList(
      serverApi.GET_ALL_LEDGER_GROUPS_LIST,
      data,
      this.props.history
    );
  }

  componentWillReceiveProps(props) {
    if (props.stateNameList.data) {
      var list = [];

      let listData = props.stateNameList.data.filter((item) => {
        if (item.label !== "Select........") {
          return item;
        }
      });
      listData.map((item) => {
        list.push({
          label: item.label,
          name: item.name,
          value: item.name.split("^", 2)[1],
        });
      });
      this.setState({
        stateNameList: list,
      });
    }

    if (props.GetAllFiles_Data) {
      if (props.GetAllFiles_Data.data) {
        if (props.GetAllFiles_Data.data.length !== 0) {
          props.GetAllFiles_Data.data.map((data) => {
            this.state.customerLineitems.map((item) => {
              if (data.subTaskUniqueId == item.customerLineId) {
                item.displayname = data.displayName;
                item.fileUploadId = data.fileUploadId;
                item.GstfileExtension=data.fileExtension;
                item.GstFileRetrivePath=data.fileRetrivePath;
                            } else if (
                data.subTaskUniqueId ==
                item.customerLineId.toString() + item.customerLineId.toString()
              ) {
                item.Bankdisplayname = data.displayName;
                item.BankfileUploadId = data.fileUploadId;
                item.BankFileExtension=data.fileExtension;
                item.BankRetrivePath=data.retrievalPath;
              }
            });
          });
        } else {
          this.state.customerLineitems.map((item) => {
            item.displayname = null;
            item.fileUploadId = null;
            item.GstfileExtension="";
            item.GstFileRetrivePath="";
                      item.Bankdisplayname = null;
            item.BankfileUploadId = null;
            item.BankFileExtension="";
            item.BankRetrivePath="";
          });
        }
      }
    }
    if (props.getCustometGstData) {
      if (props.getCustometGstData.data) {
        this.state.customerLineitems.map((item) => {
          if (item.id == props.getCustometGstData.id) {
            if (props.getCustometGstData.data.status == true) {
              item.isGstVerified = 1;
            } else {
              item.isGstVerified = 2;
            }
          }
        });
      }
    }
    if (props.branchList) {
      if (props.branchList.data) {
        if (props.branchList.data.length !== 0) {
          var List = [];
          var ListData = props.branchList.data.filter(
            (item) => item.value !== 0
          );
          if (ListData.length !== 0) {
            ListData.map((item) => {
              List.push({
                value: item.value,
                name: item.label,
                label: item.label,
              });
            });
          }
          this.setState({
            branchData: List,
          });
        }
      }
    }
    if (props.ledgerGroupsList) {
      var List = [];
      if (props.ledgerGroupsList.data) {
        if (props.ledgerGroupsList.data.length !== 0) {
          props.ledgerGroupsList.data.map((item) => {
            List.push({
              value: item.id,
              name: item.name,
              label: item.name,
            });
          });
        }
      }
      this.setState({
        ledgerGroupsListData: List,
      });
    }
  }

  onDel = (row) => {
    const { customerLineitems } = this.state;

    const lastRow = customerLineitems[customerLineitems.length - 1];

    if (lastRow === row) {
      swal("You can't delete the empty line item");
    } else {
      const indexItem = customerLineitems.findIndex((item) => item === row);

      if (indexItem !== -1) {
        const updatedLineItems = customerLineitems.map((item, index) =>
          index === indexItem ? { ...item, isActive: 0 } : item
        );

        this.setState({
          customerLineitems: updatedLineItems,
        });
        console.log(this.state.customerLineitems, "updated after delete")
      } else {
        swal("Item not found");
      }
    }
  };

  handleChange = (selectedValue, selectedName, name, selectedRow) => {
    this.setState({
      rowCount: this.state.rowCount + 1,
    });
    let lastRow =
      this.state.customerLineitems[this.state.customerLineitems.length - 1];
    if (name === "state") {
      if (selectedRow === lastRow) {
        this.handleAddEvent();
      }
      let stateCode = selectedName.split("^");
      selectedRow.stateCode = stateCode[1];
      selectedRow.state = stateCode[0];
      selectedRow.gstNo = stateCode[2];
      selectedRow.pincode=""

      this.setState({
        state: stateCode[0],
        stateCode: stateCode[1]
      });
    }

    if (name === "ledgerGroupId") {
      selectedRow.ledgerGroupId = selectedValue;
      selectedRow.ledgerGroupDesc = selectedName;
    }
    if (name === "addressType") {
      selectedRow.addressType = selectedValue;
    }
  };
  handleselectedFile = (value) => (e) => {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData !== undefined) {
        e.preventDefault();
        let file = e.target.files[0];
        let fileExt = file.name.split(".").pop();
        const formData = new FormData();
        formData.append("fileName", file.name);
        formData.append("fileUpload", file);
        formData.append("fileExtension", fileExt);
        formData.append("sourceMenu", 40);
        formData.append("displayName", file.name);
        formData.append("taskUniqueId", this.props.location.state.rowData.id);
        formData.append("createdBy", this.state.userDit.userId);
        formData.append("fileCompanyId", this.state.companyId);
        formData.append("subTaskUniqueId", value);
        this.setState({
          fileName: file.name,
          fileExtension: file.name.split(".").pop(),
        });
        this.props.UploadCustomerMasterFiles(
          serverApi.UPLOADFILE,
          formData,
          this.props.history,
          this.props.location.state.rowData.id
        );
      }
    }
  };
  handleDeleteFile = (fileUploadId, value) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.state.customerLineitems.map((item) => {
          if (item.customerLineId == value) {
            item.displayname = null;
            item.fileUploadId = null;
            item.GstfileExtension="";
            item.GstFileRetrivePath="";
                    }
          if (
            item.customerLineId.toString() + item.customerLineId.toString() ==
            value
          ) {
            item.Bankdisplayname = null;
            item.BankfileUploadId = null;
            item.BankFileExtension="";
            item.BankRetrivePath="";
          }
        });

        if (this.props.location.state !== undefined) {
          if (this.props.location.state.rowData !== undefined) {
            this.props.deleteCustomerMasterFiles(
              serverApi.DELETE_FILE_BI_ID + fileUploadId,
              this.props.history,
              this.props.location.state.rowData.id
            );
          }
        }
      } else {
        swal("Your action is canceled!");
      }
    });
    //
  };
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  OnClickVerify = (row) => {
    this.props.getCustomerGstVerification(
      serverApi.GST_VERIFICATION_API + row.gstNo,
      row.id,
      this.props.history
    );
  };

  render() {
    var filteredproducts = this.state.customerLineitems.filter(
      (item) => item.isActive !== 0
    );
  let lastLineItem = this.state.customerLineitems[this.state.customerLineitems.length - 1];

    return (
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent" onScroll={this.handleScroll}>
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {tableHeaders.map((column) => (
                    <>
                      {column.header === "Branch Name" ||
                      column.header === "Branch Address" ||
                      column.header === "City" ||
                      column.header === "State" ||
                      column.header === "State Code" ||
                      column.header === "Pin Code" ? (
                        <>
                          <th>
                            {column.header}
                            <label style={{ color: "red" }}>*</label>
                          </th>
                        </>
                      ) : (
                        <th>{column.header}</th>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredproducts.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <>
                      {RowData &&
                        RowData.map((col) =>
                          col.component === "EditableCell" ? (
                            <td>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      type={
                                        col.type === "pincode"
                                          ? "number"
                                          : "text"
                                      }
                                      name={col.type + "_" + rowIndex}
                                      id={col.no}
                                      value={
                                        col.type === "address"
                                          ? row.address
                                          : col.type === "city"
                                          ? row.city
                                          : col.type === "stateCode"
                                          ? row.stateCode
                                          : col.type === "gstNo"
                                          ? row.gstNo
                                          : col.type === "pincode"
                                          ? row.pincode
                                          : col.type === "contactNo"
                                          ? row.contactNo
                                          : col.type === "contactPerson"
                                          ? row.contactPerson
                                          : col.type === "openningBalance"
                                          ? row.openningBalance
                                          : col.type === "accountNumber"
                                          ? row.accountNumber
                                          : col.type === "ifscCode"
                                          ? row.ifscCode
                                          : col.type === "ledgerName"
                                          ? row.ledgerName
                                          : col.type === "branchName"
                                          ? row.branchName
                                          : ""
                                      }
                                      onChange={this.OnhandleChange({
                                        key: col.type + "_" + rowIndex,
                                        row,
                                      })}
                                      disabled={
                                        col.type === "stateCode"
                                          ? true
                                          : col.type === "ledgerName"
                                          ? true
                                          : false
                                      }
                                      className="inputBlockContainer"
                                      style={{
                                        textAlign:
                                          col.type === "stateCode" ||
                                          col.type === "gstNo" ||
                                          col.type === "openningBalance"
                                            ? "right"
                                            : "",
                                        paddingRight: "8px",
                                        backgroundColor:
                                          col.type === "ledgerName"
                                            ? "#ccd6db"
                                            : col.type === "ledgerName"
                                            ? "#ccd6db"
                                            : "",
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          ) : col.component === "TextFieldGstVerify" ? (
                            <td>
                              <div className="verificationContainer">
                                {/* <div className="incDecContent"> */}
                                <input
                                  className="inputBlockContainer"
                                  value={row.gstNo}
                                  onChange={this.OnhandleChange({
                                    key: col.type + "_" + rowIndex,
                                    row,
                                  })}
                                  name={col.type}
                                  id={col.no}
                                />
                                {row.isGstVerified == 1 ? (
                                  <button className="verifiedBlock">
                                    <CheckIcon style={{ color: "green" }} />
                                    <span
                                      style={{
                                        margin: "0px",
                                        marginRight: "5px",
                                      }}
                                    >
                                      Verified
                                    </span>
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => this.OnClickVerify(row)}
                                    className="verifiedBlock"
                                  >
                                    verify
                                  </button>
                                )}
                                {/* </div> */}
                              </div>
                            </td>
                          ) : col.type === "UploadDocument" ? (
                            <>
                              <td>
                                <div className="d-flex cloumnButtonBlock">
                                  <Box gridColumn="span 3">
                                    <div className="personalDetailsUpload">
                                      <div className="ducumentUploadBlock">
                                        <div className="documentUploadContent">
                                          {row.displayname === null ? (
                                            <span
                                              className={
                                                row.customerLineId
                                                  ? "btn_upload documentUpload "
                                                  : "btn_upload documentUpload disabled"
                                              }
                                            >
                                              <img src={panUploadIcon} alt="" />
                                              <input
                                                type="file"
                                                id="imag"
                                                disabled={
                                                  row.customerLineId
                                                    ? false
                                                    : true
                                                }
                                                title=""
                                                className="input-img"
                                                onChange={this.handleselectedFile(
                                                  row.customerLineId
                                                )}
                                              />
                                              Upload Document
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          {row.displayname !== null ? (
                                            <div className="uploadedInfo">
                                              <img
                                                src={TickIcon}
                                                alt=""
                                                className="tickIcon"
                                              />
                                               <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Click to download File"
                            >
                              <div
                                onClick={() => {
                                  getFileDownload(
                                    serverConfig.SERVER_URL +
                                    "security-api/api/files/downloadfile/" +
                                    row.fileUploadId,
                                    row.displayname,
                                    row.GstfileExtension
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {row.displayname}
                              </div>

                            </Tooltip>
                                              <Button
                                                onClick={() =>
                                                  this.handleDeleteFile(
                                                    row.fileUploadId,
                                                    row.customerLineId
                                                  )
                                                }
                                                className="deleteButton"
                                              >
                                                <img
                                                  src={DeleteFileIcon}
                                                  alt=""
                                                />
                                              </Button>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </Box>
                                </div>
                              </td>
                            </>
                          ) : col.type === "BankUploadDocument" ? (
                            <>
                              <td>
                                <div className="d-flex cloumnButtonBlock">
                                  <Box gridColumn="span 3">
                                    <div className="personalDetailsUpload">
                                      <div className="ducumentUploadBlock">
                                        <div className="documentUploadContent">
                                          {row.Bankdisplayname === null ? (
                                            <span
                                              className={
                                                row.customerLineId
                                                  ? "btn_upload documentUpload "
                                                  : "btn_upload documentUpload disabled"
                                              }
                                            >
                                              <img src={panUploadIcon} alt="" />
                                              <input
                                                type="file"
                                                id="imag"
                                                disabled={
                                                  row.customerLineId
                                                    ? false
                                                    : true
                                                }
                                                title=""
                                                className="input-img"
                                                onChange={this.handleselectedFile(
                                                  row.customerLineId.toString() +
                                                    row.customerLineId.toString()
                                                )}
                                              />
                                              Upload Document
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          {row.Bankdisplayname !== null ? (
                                            <div className="uploadedInfo">
                                              <img
                                                src={TickIcon}
                                                alt=""
                                                className="tickIcon"
                                              />
                                               <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Click to download File"
                            >
                              <div
                                onClick={() => {
                                  getFileDownload(
                                    serverConfig.SERVER_URL +
                                    "security-api/api/files/downloadfile/" +
                                    row.BankfileUploadId,
                                    row.Bankdisplayname,
                                    row.BankFileExtension
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {row.Bankdisplayname}
                              </div>

                            </Tooltip>
                                              
                                              <Button
                                                onClick={() =>
                                                  this.handleDeleteFile(
                                                    row.BankfileUploadId,
                                                    row.customerLineId.toString() +
                                                      row.customerLineId.toString()
                                                  )
                                                }
                                                className="deleteButton"
                                              >
                                                <img
                                                  src={DeleteFileIcon}
                                                  alt=""
                                                />
                                              </Button>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </Box>
                                </div>
                              </td>
                            </>
                          ) : col.component === "DynamicSelectNew" ? (
                            <td>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <DynamicSelectNew
                                      arrayOfData={
                                        col.type === "state"
                                          ? this.state.stateNameList
                                          : col.type === "ledgerGroupId"
                                          ? this.state.ledgerGroupsListData :
                                          col.type === "addressType"
                                          ? address_Type
                                          : ""
                                      }
                                      className="dropdownBlockContainer"
                                      onSelectChange={this.handleChange}
                                      isDropdownOpen={this.state.isDropdownOpen}
                                      handleMenuOpen={this.handleMenuOpen}
                                      selected={
                                        col.type === "state"
                                          ? row.state
                                          : col.type === "ledgerGroupId"
                                          ? row.ledgerGroupDesc
                                          : col.type === "addressType"
                                          ? row.addressType
                                          : ""
                                      }
                                      name={
                                        col.type === "state"
                                          ? "state"
                                          : col.type === "ledgerGroupId"
                                          ? "ledgerGroupId"
                                          : col.type === "addressType"
                                          ? "addressType"
                                          : ""
                                      }
                                      row={row}
                                      update={
                                        col.type === "state"
                                          ? row.state
                                            ? 1
                                            : 0
                                          : col.type === "ledgerGroupId"
                                          ? row.ledgerGroupDesc
                                            ? 1
                                            : 0
                                          : col.type === "addressType"
                                          ? row.addressType
                                            ? 1
                                            : 0
                                          : ""
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          ) : col.component === "DynamicSelect" ? (
                            <td>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <DynamicSelect
                                      arrayOfData={
                                        col.type === "addressType"
                                          ? address_Type
                                          : ""
                                      }
                                      className="dropdownBlockContainer"
                                      onSelectChange={this.handleChange}
                                      isDropdownOpen={this.state.isDropdownOpen}
                                      handleMenuOpen={this.handleMenuOpen}
                                      selected={
                                        col.type === "addressType"
                                          ? row.addressType
                                          : ""
                                      }
                                      name={
                                        col.type === "addressType"
                                          ? "addressType"
                                          : ""
                                      }
                                      row={row}
                                      update={
                                        col.type === "addressType"
                                          ? row.addressType
                                            ? 1
                                            : 0
                                          : ""
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          ) : (
                            ""
                          )
                        )}
                    </>
                    <td className="">
                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title="Delete"
                      >
                        <IconButton
                          onClick={() => {
                            this.onDel(row);
                          }}
                          disabled={
                            filteredproducts.length === 1
                              ? true
                              : lastLineItem === row
                              ? true
                              : false
                          }
                        >
                          <DeleteIcon className="deleteDisplayButton" />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
const RowData = [
  {
    no: 1,
    component: "EditableCell",
    type: "branchName",
  },
  {
    no: 2,
    component: "EditableCell",
    type: "address",
    Items: true,
  },
  {
    no: 3,
    component: "EditableCell",
    type: "city",
  },
  {
    no: 4,
    component: "DynamicSelectNew",
    type: "state",
    dropDown: "dropDown",
    name: "state",
    Items: true,
  },
  {
    no: 5,
    component: "EditableCell",
    type: "stateCode",
  },
  {
    no: 6,
    component: "TextFieldGstVerify",
    type: "gstNo",
  },
  {
    no: 7,
    component: "UploadDocument",
    type: "UploadDocument",
    Items: true,
  },
  {
    no: 8,
    component: "EditableCell",
    type: "pincode",
  },
  {
    no: 9,
    component: "EditableCell",
    type: "contactNo",
  },
  {
    no: 10,
    component: "EditableCell",
    type: "contactPerson",
  },
  {
    no: 11,
    component: "EditableCell",
    type: "openningBalance",
  },
  {
    no: 12,
    component: "EditableCell",
    type: "accountNumber",
  },
  {
    no: 13,
    component: "EditableCell",
    type: "ifscCode",
  },
  {
    no: 14,
    component: "UploadDocument",
    type: "BankUploadDocument",
    Items: true,
  },
  {
    no: 15,
    component: "DynamicSelectNew",
    type: "ledgerGroupId",
  },
  {
    no: 16,
    component: "EditableCell",
    type: "ledgerName",
  },
  {
    no: 17,
    component: "DynamicSelectNew",
    type: "addressType",
  },
];
const tableHeaders = [
  {
    header: "Branch Name",
  },
  {
    header: "Branch Address",
  },
  {
    header: "City",
  },
  {
    header: "State",
  },
  {
    header: "State Code",
  },
  {
    header: "GST Number",
  },
  {
    header: "Support Documents",
  },
  {
    header: "Pin Code",
  },
  {
    header: "Contact Number",
  },
  {
    header: "Contact Person",
  },
  {
    header: "Openning Balance",
  },
  {
    header: "Account Number",
  },
  {
    header: "IFSC Code",
  },
  {
    header: "Support Documents",
  },
  {
    header: "Ledger Group",
  },
  {
    header: "Ledger Name",
  },
  {
    header: "Address Type",
  },
  {
    header: "Actions",
  },
];
const address_Type = [
  {
    value: 1,
    label: "Shipping",
    name: "Shipping",
  },
  {
    value: 2,
    label: "Billing and Shipping",
    name: "Billing and Shipping",
  },
];
const mapStatetoProps = (state) => {
  const { stateNameList, branchList, ledgerGroupsList } =
    state.DropDownListReducer;
  const { GetAllFiles_Data, getCustometGstData } = state.CustomerListReducer;
  return {
    stateNameList,
    GetAllFiles_Data,
    getCustometGstData,
    branchList,
    ledgerGroupsList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getStateNameList,
    UploadCustomerMasterFiles,
    deleteCustomerMasterFiles,
    getCustomerGstVerification,
    getBranchList,
    getLedgerGroupsList,
  })(CustomerLineItems)
);
