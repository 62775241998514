import React, { Component } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import CustomButton from "../../../components/Buttons/Buttons";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi,showSpinner } from "../../../helpers/Consts";
import {
  getPayRegisterList,
  getBranchListData,
  setPayPeriod,
} from "../../../store/HRMS/PayRegister/actions";
import moment from "moment";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { getPayScheme } from "../../../store/HRMS/EmployeeDataBase/actions";
import { uploadFormdataWithPayProcess } from "../../../helpers/server";
import swal from "sweetalert";
class CreateNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      payRegisterListData: [],
      PayScheme: [],
      branchListData: [],
      branchLists: [],
      payScheme_name: "",
      branch_name: "",
      FromDate: "",
      ToDate: "",
      payScheme: [],
      showPopup: false,
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getBranchListData(
      serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
      this.props.history
    );
    const data2 = {
      activeStatus: "",
      isCompanyFiltered: "Y",
      status: "32",
    };
    this.props.getPayScheme(
      serverApi.EMPLOYEE_PAY_SCHEME,
      data2,
      this.props.history
    );
  }

  componentWillReceiveProps(props) {
    if (props.branchList) {
      this.setState({
        branchListData: props.branchList.data,
      });
    }
    if (props.payScheme_List) {
      this.setState({
        payScheme: props.payScheme_List,
      });
    }
  }
  handleSelect = (selectedValue, selectedName, name) => {
    if (name === "payScheme") {
      this.setState({
        payScheme_name: selectedValue,
      });
    }
    if (name === "Branch") {
      this.setState({
        branch_name: selectedValue,
      });
    }
  };
  handleSelectDate = (e, name) => {
    if (name === "dateOne") {
      var date = moment(e).format();
      var fromdate = date.split("T", 1).toString();

      this.setState({
        FromDate: fromdate,
      });
    }

    if (name === "dateTwo") {
      var date = moment(e).format();
      var toDate = date.split("T", 1).toString();

      this.setState({
        ToDate: toDate,
      });
    }
  };
  onClickNext = () => {
    // if(
    //   this.state.payScheme_name!== "" && this.state.payScheme_name!== undefined &&
    //   this.state.branch_name!== "" && this.state.branch_name!== undefined &&
    //   this.state.FromDate !== ''&&   this.state.FromDate!== undefined &&
    //   this.state.ToDate!== '' &&     this.state.ToDate!== undefined
    // ){

    if (this.state.FromDate === "") {
      swal("Please Enter The From Date ");
      return false;
    }
    if (this.state.ToDate === "") {
      swal("Please Enter The To Date ");
      return false;
    }
    if (this.state.payScheme_name === "") {
      swal("Please Enter The Pay Scheme ");
      return false;
    }
    if (this.state.branch_name == "") {
      swal("Please Enter The Branch ");
      return false;
    }

    var data = {
      branchId: this.state.branch_name,
      createdBy: this.state.userDit.userId.toString(),
      fromDate: this.state.FromDate,
      paySchemeId: this.state.payScheme_name,
      toDate: this.state.ToDate,
    };
    this.props.setPayPeriod(
      serverApi.CREATE_PAY_PERIOD,
      data,
      this.props.history
    );
    showSpinner();
    // }else{
    //   swal('Please Enter All Mandatory Fields ')
    // }
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <>
        <div className="consoleFormContainer">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            From Date<span className="mandatoryField">*</span>
                          </label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="dd-MM-yyyy"
                            name="dateOne"
                            value={this.state.FromDate}
                            fullWidth
                            onChange={(e) =>
                              this.handleSelectDate(e, "dateOne")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  readOnly={true}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            To Date<span className="mandatoryField">*</span>
                          </label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="dd-MM-yyyy"
                            name="dateTwo"
                            value={this.state.ToDate}
                            minDate={this.state.FromDate}
                            fullWidth
                            onChange={(date) =>
                              this.handleSelectDate(date, "dateTwo")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  readOnly={true}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        required
                        arrayOfData={
                          this.state.payScheme
                            ? this.state.payScheme.map((item) => ({
                                label: item.name,
                                value: item.id,
                                name: item.name,
                              }))
                            : ""
                        }
                        label={"Pay Scheme"}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelect}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.payScheme_name}
                        name="payScheme"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        label={"Branch"}
                        required
                        arrayOfData={
                          this.state.branchListData
                            ? this.state.branchListData.map((item) => ({
                                label: item.label,
                                value: item.value,
                                name: item.label,
                              }))
                            : ""
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelect}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.branch_name}
                        name="Branch"
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="consoleFormButtonBlock">
          <Link to="/payregister">
            <CustomButton label={"Cancel"} className="greenBorderButton" />
          </Link>
          <CustomButton
            label={"Add"}
            className="greenButton"
            handleClick={this.onClickNext}
            type="sumbit"
          />
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { payRegisterList, branchList, payPeriod } = state.PayRegisterReducer;
  const { payScheme_List } = state.EmployeeReducer;
  return { payRegisterList, branchList, payPeriod, payScheme_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getPayRegisterList,
    getBranchListData,
    setPayPeriod,
    getPayScheme,
    uploadFormdataWithPayProcess,
  })(CreateNew)
);
