import React, { Component } from "react";
import TextFieldAlfaNum from "../../../components/TextField/TextFieldAlfaNum";
import { Grid, Box } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import CustomButton from "../../../components/Buttons/Buttons";
import { Link } from "react-router-dom";
import TextFieldAlfaNumReq from "../../../components/TextField/TextFieldAlfaNumReq";
import TextField from "../../../components/TextField/TextField";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Profile from "../../../assets/images/profileIcon.png";
class ViewAttendanceApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      display: false,
      locaion: 0,
      location_name: "",
      emp_code: "",
      name: "",
      attendence_mark: 0,
      attendence_mark_name: "",
      attendence_source: 0,
      attendence_source_name: "",
      attendence_type: 0,
      attendence_type_name: "",
      machine_no: 0,
      machine_numbers: "",
    };
  }
  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
  };

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "attendence_mark") {
      this.setState({
        attendence_mark: selectedValue,
        attendence_mark_name: selectedName,
      });
    }
    if (name === "attendence_source") {
      this.setState({
        attendence_source: selectedValue,
        attendence_source_name: selectedName,
      });
    }
    if (name === "attendence_type") {
      this.setState({
        attendence_type: selectedValue,
        attendence_type_name: selectedName,
      });
    }
    if (name === "machine_no") {
      this.setState({
        machine_no: selectedValue,
        machine_numbers: selectedName,
      });
    }
    var a = (selectedValue, selectedName, name);
  };
  render() {
    return (
      <>
        <div className="consoleFormContainer d-flex">
          <Grid item xs={6}>
            <div className="consoleFormBlock">
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 6">
                  <div className="consoleTextFieldBlock">
                    <div className="TextFieldBlock">
                      <label>Date</label>
                    </div>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        name="financial_year"
                        inputFormat="dd.MM.yyyy"
                        id="financial_year"
                        value={this.state.dtvalue}
                        fullWidth
                        onChange={(newValue) => {
                          // setValue(newValue);
                          this.setState({ dtvalue: newValue });
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input
                              ref={inputRef}
                              {...inputProps}
                              readOnly={true}
                            />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </Box>
                <Box gridColumn="span 6">
                  <div className="consoleTextFieldBlock">
                    <TextFieldAlfaNumReq
                      value={this.state.emp_code}
                      label="Employee Code"
                      caps="ON"
                      placeholder="Enter here"
                      onChange={this.handleChange("emp_code")}
                    />
                  </div>
                </Box>
              </Box>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="leaveBalanceCardContainer">
              <Grid className="employeeProfileContainer">
                <div>
                  <Grid className="employeeProfileMainContainer">
                    <div className="mainContainerBlock">
                      <Grid container className="employeeProfileBlock">
                        <Grid item className="profileBlockContainer">
                          <div>
                            <img
                              src={Profile}
                              alt="playstore"
                              className="ProfileImageBlock"
                            />
                          </div>

                          <Grid item>
                            <h1 className="profileNameBlock">Pavani Gupta</h1>
                            <p className="profileDesignation">Software</p>
                            <p className="profileDepartment">UX/UI Designer</p>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <span className="registerButtonBlock">STPL009</span>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </div>
              </Grid>
            </div>
          </Grid>
        </div>
        <div className="consoleFormContainer">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>Attendance Mark</label>
                      </div>
                      <DynamicSelect
                        arrayOfData={attendence_mark}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.state.attendence_mark}
                        name="type"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>Attendance Source</label>
                      </div>
                      <DynamicSelect
                        arrayOfData={attendence_source}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.state.attendence_source}
                        name="type"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>Attendance Type</label>
                      </div>
                      <DynamicSelect
                        arrayOfData={attendence_type}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.state.attendence_type}
                        name="Branch"
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div className="consoleFormContainer">
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextField label="Spell" placeholder="Enter Here" />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextField
                          label="Entry Time"
                          placeholder="Enter Here"
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextField label="Exit Time" placeholder="Enter Here" />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextField
                          label="Working Hours"
                          placeholder="Enter Here"
                        />
                      </div>
                    </Box>
                  </Box>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextField
                          label="Idle Hours"
                          placeholder="Enter Here"
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <div className="TextFieldBlock">
                          <label>Machine no</label>
                        </div>
                        <DynamicSelect
                          arrayOfData={machine_no}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          selected={this.state.machine_no}
                          name="type"
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 6">
                      <div className="consoleTextFieldBlock">
                        <TextField label="Remarks" placeholder="Enter Here" />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>

            <div className="consoleFormButtonBlock">
              <Link to="client_visitPlan">
                <CustomButton label={"Cancel"} className="cancelButton" />
              </Link>
              <Link to="/attendance_approval">
                <CustomButton
                  label={"Submit"}
                  className="saveButton"
                  handleClick={this.onClickNext}
                  type="sumbit"
                />
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const attendence_mark = [
  {
    value: "1",
    label: "Absent",
    name: "Absent",
  },
  {
    value: "2",
    label: "Present",
    name: "Present",
  },
  {
    value: "3",
    label: "Late",
    name: "Late",
  },
];
const attendence_source = [
  {
    value: "1",
    label: "Manual",
    name: "Manual",
  },
];
const attendence_type = [
  {
    value: "1",
    label: "Over time",
    name: "Over time",
  },
  {
    value: "2",
    label: "Regular",
    name: "Regular",
  },
  {
    value: "1",
    label: "Cash",
    name: "Cash",
  },
];
const machine_no = [
  {
    value: "1",
    label: "001",
    name: "001",
  },
];
const mapStatetoProps = (state) => {};

export default withRouter(connect(mapStatetoProps, {})(ViewAttendanceApproval));
