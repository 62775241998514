export const PAYSLIP_LIST = "payslip_list"; 
export const PAYSLIP_LIST_SUCCESSFULL = "payslip_list_successfull";
export const PAYSLIP_DATA = "payslip_data"; 
export const PAYSLIP_DATA_SUCCESSFULL = "payslip_data_successfull";
export const PAYSLIP_DATA_CLEAR = "payslip_data_clear"; 

export const PAYSLIP_AVAILABLE_LIST =  "paysilp_available_list";
export const PAYSLIP_AVAILABLE_LIST_SUCCESSFULL =  "paysilp_available_list_successfull";
export const PAY_SUMMARY = "pay_summary";
export const PAY_SUMMARY_SUCCESSFULL = "pay_summary_successfull";
export const PAYSLIP_DUP_DATA = "payslip_dup_data";
export const PAYSLIP_DUP_DATA_SUCCESSFULL = "payslip_dup_data_successfull";
