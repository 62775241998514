import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {
  BALANCE_QUANTITY,
  CREATE_DEBIT_NOTE,
  DEBIT_CREDIT_LIST,
  DELETE_DEBIT_FILES,
  GET_DEBIT_FILES,
  GET_DEBIT_NOTE_BRANCH,
  GET_DEBIT_NOTE_BY_ID,
  GET_DEBIT_PROJECTS,
  GET_SR_NO,
  GET_SUPPLIER_FOR_JUTE_BY_ID,
  SR_NO_VIEW_BY_ID,
  UPDATE_DEBIT_NOTE,
  UPLOAD_DEBIT_FILES,
} from "./actionTypes";
import {
  DebitCreditListSuccessfull,
  getSupplierForJuteByIdSuccessfull,
  getSrNumberSuccessfull,
  getSrNoViewByIdSuccessfull,
  getDebitNoteBranchSuccessfull,
  getDebitNoteProjectsSuccessfull,
  getCreateDataSuccessfull,
  getBalanceQuantitySuccessfull,
  getUplaodedDebitFiles,
  getDebitNoteByIdSuccessfull,
  UploadDebitFilesSuccessfull,
  getUplaodedDebitFilesSuccessfull,
  DebitNoteLineItemsProps,
  getUpdateDataSuccessfull,
  deleteUplaodedDebitFilesSuccessfull,
  DebitNoteHeaderProps
} from "./actions";
import { getList, getListurl, postAdd } from "../../../helpers/Server_Helper";
import { hideSpinner, serverApi, showSpinner } from "../../../helpers/Consts";
import swal from "sweetalert";

function* getDebitCreditList({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    yield put(DebitCreditListSuccessfull({ response }));
    if (response) {
      hideSpinner();
    }
  } catch (error) {
    hideSpinner();
    console.log(error);
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}
function* getSupplierForJuteByIdData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getSupplierForJuteByIdSuccessfull({ response }));
    if (response) {
    }
  } catch (error) {
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}
function* getSrNumberData({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(getListurl, url, data);
    yield put(getSrNumberSuccessfull({ response }));
    if (response) {
      hideSpinner();
    }
  } catch (error) {
    hideSpinner();

    console.log(error);
  }
}
function* getSrNumberViewById({ payload: { url, history } }) {
  try {
    showSpinner();
    const response = yield call(getListurl, url);
    yield put(getSrNoViewByIdSuccessfull({ response }));
    if (response) {
      if(response.data){
      if(response.data.inwardDetails){
        if (response.data.inwardDetails) {
          let inwardDetails = [];
          if (response.data.inwardDetails.length !== 0) {

            const FilteredItem = response.data.inwardDetails.filter(
              (item) => {
                return item.balanceQuantity !== 0 && item.isActive !== 0;
              }
            );
            FilteredItem.map((item, i) => {

              let amtWithDisc = 0

              if(item.discountMode === 1){
                amtWithDisc = (item.rate * item.approvedQty) - item.discount
                
              }
              else if(item.discountMode === 2){
                amtWithDisc = (item.rate * item.approvedQty * (100 - item.discount)) / 100  
              }
              else {
                amtWithDisc = item.rate * item.approvedQty
              
              }
            
              var amt =item.rate * item.approvedQty
              var debitVal =
                (item.taxPercentage * (item.rate * item.approvedQty)) / 100;
              var igst = (amtWithDisc * item.igstPercentage) / 100;
              var cgst = (amtWithDisc * item.cgstPercentage) / 100;
              var sgst = (amtWithDisc * item.sgstPercentage) / 100;
              
              

              inwardDetails.push({
                id: i,
                itemCode: item.itemCode,
                itemGroup: item.itemGroup,
                itemGroupId: item.itemGroupId,
                itemId: item.itemId,
                itemName: item.itemName,
                approvedQty: item.approvedQty,
                debitnoteValue: parseFloat(amtWithDisc).toFixed(2),
                warehouseno: item.warehouseno,
                inwardDetailId: item.id,
                quantity: item.approvedQty,
                rate:  parseFloat(item.rate).toFixed(3),
                srRate:parseFloat(item.srRate).toFixed(3),
                discount: item.discount,
                reason: item.reason,
                amount:parseFloat( amt).toFixed(2),
                ctaxAmount: cgst,
                staxAmount: sgst,
                itaxAmount: igst,
                itaxPer: item.igstPercentage,
                ctaxPer: item.cgstPercentage,
                staxPer: item.sgstPercentage,
                debitnoteQty: item.debitnoteQty,
                uom: item.uom,
                isActive: 1,
                balanceQuantity: item.balanceQuantity,
                taxPercentage:item.taxPercentage,
                taxAmount: parseFloat(debitVal).toFixed(2),
                qtyDisable:true,
                rateDisable:true,
                discountMode:item.discountMode

              });
            });
          }
    yield put(DebitNoteLineItemsProps(inwardDetails));

          // props.DebitNoteLineItemsProps(inwardDetails);

        }
      }}
      hideSpinner();
    }
  } catch (error) {
    hideSpinner();
    console.log(error);
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}
function* getDebitNoteBranchData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getDebitNoteBranchSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getDebitNoteProjectData({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(getDebitNoteProjectsSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getCreate({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(getCreateDataSuccessfull({ response }));
    if (response) {
      if (response.data) {
        if (response.data.message) {
          swal(response.data.message, { icon: "success" });
          if(response.data.status ==true){
            history.push("/store_debit_credit_note");
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}
function* getUpdate({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(getUpdateDataSuccessfull({ response }));
    if (response) {
      if (response.data) {
        if (response.data.message) {
          swal(response.data.message, { icon: "success" });
          if(response.data.status ==true){
            history.push("/store_debit_credit_note");
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}
function* getBalanceQuantitydata({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(getBalanceQuantitySuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getDebitNoteByIdData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);

    if (response.data) {
      if (response.data.debitNoteLineDetails) {
        if (response.data.debitNoteLineDetails.length !== 0) {
          var dateUpdate = [];
          const FilteredItem = response.data.debitNoteLineDetails.filter(item => item.isActive !== 0)
            FilteredItem.map((item, i) => {

            let amtWithDisc = 0
            if(item.discountMode === 1){
              amtWithDisc = (item.rate * item.quantity) - item.discount      
            }
            else if(item.discountMode === 2){
              amtWithDisc = (item.rate * item.quantity * (100 - item.discount)) / 100  
            }
            else {
              amtWithDisc = item.rate * item.quantity          
            }
          
            var amount = item.rate*item.approvedQty
            var debitVal =((item.igstPercentage +item.cgstPercentage +item.sgstPercentage) *amount) /100;

            var igst = (amtWithDisc * item.igstPercentage) / 100;
            var cgst = (amtWithDisc * item.cgstPercentage) / 100;
            var sgst = (amtWithDisc * item.sgstPercentage) / 100;
            dateUpdate.push({
              id: i,
              itemCode: item.itemCode,
              itemGroup: item.itemGroupDesc,
              itemGroupId: item.itemGroup,
              itemId: item.itemId,
              itemName: item.itemDesc,
              approvedQty: item.approvedQty,
              debitnoteValue: parseFloat(amtWithDisc).toFixed(2),
              warehouseno: item.warehouseno,
              inwardDetailId: item.inwardDetailId,
              quantity: item.quantity,
              rate: parseFloat(item.rate).toFixed(3),
              srRate: parseFloat(item.srRate).toFixed(3),
              discount: item.discount,
              reason: item.reason,
              amount: parseFloat(+Number(amount)).toFixed(2),
              ctaxAmount:cgst,
              staxAmount:sgst,
              itaxAmount:igst,

              taxPercentage:item.igstPercentage +item.cgstPercentage +item.sgstPercentage,
              taxAmount: parseFloat(debitVal).toFixed(2),

              itaxPer: item.igstPercentage,
              ctaxPer: item.cgstPercentage,
              staxPer: item.sgstPercentage,
              debitnoteQty: item.debitnoteQty,
              uom: item.uom,
              isActive:item.isActive,
              balanceQuantity: item.balanceQunatity,
              adjustmentDetailId: item.adjustmentDetailId,
              debitnoteType:item.debitnoteType,
              debitNoteTypeDesc:item.debitNoteTypeDesc,
              qtyDisable:item.debitnoteType==2?false:true,
              rateDisable:item.debitnoteType==1?false:true,
              discountMode:item.discountMode
            
            });
          });
          yield put(DebitNoteLineItemsProps(dateUpdate));
      
        }
      }
    }
    yield put(getDebitNoteByIdSuccessfull({ response }));
  } catch (error) {
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}
function* UploadedDebitDocumentsData({ payload: { url, data,inwardId,history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(UploadDebitFilesSuccessfull({ response }));
    if (response) {
      yield put(getUplaodedDebitFiles(serverApi.GET_FILES +
        "29/" +
        inwardId +
        "/" +
        localStorage.getItem("companyId"),history));
    }
  } catch (error) {
    console.log(error);
  }
}
function* deleteUplaodedDebitFilesData({ payload: { url,inwardId,history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(deleteUplaodedDebitFilesSuccessfull({ response }));
    if (response) {
      yield put(getUplaodedDebitFiles(serverApi.GET_FILES +
        "29/" +
        inwardId +
        "/" +
        localStorage.getItem("companyId"),history));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getUploadedDebitDocument({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getUplaodedDebitFilesSuccessfull({ response }));
 
  } catch (error) {
    console.log(error);
  }
}
export function* watchDebitCreditList() {
  yield takeEvery(DEBIT_CREDIT_LIST, getDebitCreditList);
  yield takeEvery(GET_SUPPLIER_FOR_JUTE_BY_ID, getSupplierForJuteByIdData);
  yield takeEvery(GET_SR_NO, getSrNumberData);
  yield takeEvery(SR_NO_VIEW_BY_ID, getSrNumberViewById);
  yield takeEvery(GET_DEBIT_NOTE_BRANCH, getDebitNoteBranchData);
  yield takeEvery(GET_DEBIT_PROJECTS, getDebitNoteProjectData);
  yield takeEvery(CREATE_DEBIT_NOTE, getCreate);
  yield takeEvery(BALANCE_QUANTITY, getBalanceQuantitydata);
  yield takeEvery(GET_DEBIT_NOTE_BY_ID, getDebitNoteByIdData);
  yield takeEvery(UPDATE_DEBIT_NOTE, getUpdate);
  yield takeEvery(UPLOAD_DEBIT_FILES, UploadedDebitDocumentsData);
  yield takeEvery(GET_DEBIT_FILES, getUploadedDebitDocument);
  yield takeEvery(DELETE_DEBIT_FILES,deleteUplaodedDebitFilesData)
}
function* DebitCreditSaga() {
  yield all([fork(watchDebitCreditList)]);
}

export default DebitCreditSaga;
