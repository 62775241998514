import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import EmployeeDetailsCard from "../../../../../components/EmployeeDetailsCard/EmployeeDetailsCard";
import { serverApi } from "../../../../../helpers/Consts";
import { getEmployeeList } from "../../../../../store/HRMS/EmployeeDataBase/actions";

import ReactPaginate from "react-paginate";

class TotalEmployeesGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      employeeListData: [],
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
    };
  }
  componentDidMount() {
    const data = {
      companyId: localStorage.getItem("companyId"),
      departmentId: "",
      mobileNo: "",
      firstName: "",
      middleName: "",
      lastName: "",
      catagoryId: "",
      gender: "",
      status: "",
      branchId: "",
      contractorId: "",
      reportingEbId: "",
      esiNo: "",
      pfNo: "",
      empCode: "",
      designationId: "",
      isActive: null,
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
    };
    this.props.getEmployeeList(
      serverApi.NEW_EMPLOYEE_LIST,
      data,
      this.props.history
    );
  }
  componentWillReceiveProps(props) {
    if (props.employeeList) {
      if (props.employeeList.data) {
        this.setState({
          employeeListData: props.employeeList.data,
          totalRecs: props.employeeList.totalRecords,
        });
        var data = props.employeeList.data.length;
        this.props.handleData("1", data);
      }
    }
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected === 0 ? 1 : e.selected * 10;
    const offset = selectedPage * this.state.lastIndex;
    this.setState({
      currentPage: selectedPage,
      offset: offset,
    });

    const data = {
      companyId: localStorage.getItem("companyId"),
      departmentId: "",
      mobileNo: "",
      firstName: "",
      middleName: "",
      lastName: "",
      catagoryId: "",
      gender: "",
      status: "",
      branchId: "",
      contractorId: "",
      reportingEbId: "",
      esiNo: "",
      pfNo: "",
      empCode: "",
      designationId: "",
      isActive: null,
      lastIndex: this.state.lastIndex,
      startIndex: selectedPage,
    };
    this.props.getEmployeeList(
      serverApi.NEW_EMPLOYEE_LIST,
      data,
      this.props.history
    );
  };
  render() {
    let pages = this.state.totalRecs / this.state.lastIndex;
    return (
      <>
        <div className="cardViewWithPagination">
          <EmployeeDetailsCard data={this.state.employeeListData} />
          <div>
            <ReactPaginate
              previousLabel={"< Prev"}
              nextLabel={"Next >"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pages}
              onPageChange={this.handlePageClick}
              containerClassName={"cardViewPagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { employeeList } = state.EmployeeReducer;
  return { employeeList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getEmployeeList,
  })(TotalEmployeesGrid)
);
