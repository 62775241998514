import { JUTE_ISSUE_LIST, JUTE_ISSUE_LIST_SUCCESSFULL, JUTE_ISSUE_LINE_ITEMS, JUTE_ISSUE_CREATE, JUTE_ISSUE_VIEW_BY_ID, JUTE_ISSUE_VIEW_BY_ID_SCCESSFULL } from "./actionTypes";

export const JuteIssueList = (url, data, history) => {
  return {
    type: JUTE_ISSUE_LIST,
    payload: { url, data, history },
  };
};
export const JuteIssueSuccessfull = (data) => {
  return {
    type: JUTE_ISSUE_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const LineItemsJuteIssuePros = (data) => {
  return {type: JUTE_ISSUE_LINE_ITEMS, payload:data}
}

export const juteIssueUpdate = (url, data, history) => {  
  return {
      type: JUTE_ISSUE_CREATE,
      payload: {url, data, history}
  }
}

export const juteIssueViewById = (url,history) =>{
  return {
      type:JUTE_ISSUE_VIEW_BY_ID,
      payload:{url,history}
  }
}

export const juteIssueViewByIdSuccessfull = (data)=>{
  return {
      type:JUTE_ISSUE_VIEW_BY_ID_SCCESSFULL,
      payload:data
  }
}