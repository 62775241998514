import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import {
  DESIGNATION_MASTER_LIST,
  CREATE_DESIGNATION_MASTER,
  UPDATE_DESIGNATION_MASTER,
} from "./actionTypes";
import {
  DesignationMasterListSuccessfull,
  createDesignationMasterSuccessfull,
  updateDesignationMasterSuccessfull,
} from "./actions";
import { getList, postAdd } from "../../../helpers/Server_Helper";
import swal from "sweetalert";

function* getDesignationMasterList({ payload: { url, data } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(DesignationMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateDesignationMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createDesignationMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/designation_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateDesignationMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateDesignationMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/designation_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

export function* watchDesignationMasterList() {
  yield takeEvery(DESIGNATION_MASTER_LIST, getDesignationMasterList);
}
export function* watchCreateDesignationMaster() {
  yield takeEvery(CREATE_DESIGNATION_MASTER, CreateDesignationMaster);
}
export function* watchUpdateDesignationMaster() {
  yield takeEvery(UPDATE_DESIGNATION_MASTER, UpdateDesignationMaster);
}

function* DesignationListSaga() {
  yield all([
    fork(watchDesignationMasterList),
    fork(watchCreateDesignationMaster),
    fork(watchUpdateDesignationMaster),
  ]);
}

export default DesignationListSaga;
