import * as React from 'react'
import TextField from '@mui/material/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';   
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';   
import { DatePicker } from '@mui/x-date-pickers/DatePicker'; 
import Stack from '@mui/material/Stack'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

export default function MonthPicker() {
  const [value, setValue] = React.useState(new Date())

  const handleChange = (newValue) => {
    setValue(newValue)
  }

  return (
    <div className="monthPickerDropdown">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={3}>
          <DatePicker
            views={['year', 'month']}
            value={value}
            minDate={new Date('2012-03-01')}
            maxDate={new Date('2023-06-01')}
            onChange={handleChange}
            InputAdornmentProps={{}}
            components={{
              // OpenPickerIcon: ArrowDropDownIcon,
              OpenPickerIcon: KeyboardArrowDownIcon,
            }}
            renderInput={(params) => (
              <TextField {...params} className="monthPicker" />
            )}
          />
        </Stack>
      </LocalizationProvider>
    </div>
  )
}
