import {AGENT_MASTER_LIST,AGENT_MASTER_LIST_SUCCESSFULL,CREATE_AGENT_MASTER_SUCCESSFULL,CREATE_AGENT_MASTER,
    UPDATE_AGENT_MASTER,UPDATE_AGENT_MASTER_SUCCESSFULL,
    API_ERRORS,} from './actionTypes';

export const agentMasterList = (url,data,history) =>{
    return {
        type:AGENT_MASTER_LIST,
        payload:{url,data,history}
    }
}
export const agentMasterListSuccessfull = (data)=>{
    return {
        type:AGENT_MASTER_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createAgentMaster = (url, data, history) => {
    return {
        type: CREATE_AGENT_MASTER,
        payload: { url, data, history }
    }
}

export const createAgentMasterSuccessfull = (data) => {
    return {
        type: CREATE_AGENT_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const updateAgentMaster = (url, data, history) => {
    return {
        type: UPDATE_AGENT_MASTER,
        payload: { url, data, history }
    }
}

export const updateAgentMasterSuccessfull = (data) => {
    return {
        type: UPDATE_AGENT_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const apiErrors = (error) => {
    return {
        type: API_ERRORS,
        payload: error,
    };
};