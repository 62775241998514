import swal from "sweetalert";

export const showSpinner = () => {
  document.getElementById("spinner").classList.add("show");
};

export const hideSpinner = () => {
    document.getElementById("spinner").classList.remove("show");
};

export const hideSpinnerS = () => {
    document.getElementById("spinner").classList.remove("show");
};

export const nullChecker = cell => (!cell ? "-" : cell);

export const cons = {
  AUTH: "auth",
  USER_ROLE_ID: "roleId",
  SUPP_CODE: "supplierCode",
  TOKEN_DATA: "tokenData",
  TOKEN_ID: "tokenId",
  TOKEN_TYPE: "tokentype",
  ACCESS_TOKEN: "accesstoken",
  CIPHER: "cipher",
  COMPANY_ID: "companyId",
  SELECTED_COMPANY: "SelectedValue",
  SELECTED_COMPANY_ID: "SelectedId",
  CONFIG_DATA: "config_data",
  ACADEMIC_YEAR: "academic_year",
};

export const configNames = {
  INVOICING_MODEL: "invoicingModel",
  INVOICING_PRINT_MODEL: "invoicingPrintModel",
  BACK_DATE_ALLOWABLE: "backDateAllowable",
  GATE_ENTRY_OUT: "gateEntryOut",
  MR_PRINT_MODE: "mrPrintMode",
  SUPPLIER_TYPES: "supplierTypes",
  LABELS_TYPE: "labelType",
  FUTURE_DATE_ALLOWABLE: "futureDateAllowable",
  INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS: "indentWithMultiDepartments",
  APPROVAL_TASKS_LIST: "tasksList",
  GATE_ENTRY_WITHOUT_PO: "gateEntryWithoutPo",
  PO_PRINT_BEFORE_APPROVE: "poPrintBeforeApproval",
  EMAIL_ALERTS: "emailAlert",
  TAX_TYPES: "taxTypes",
  BROKER_TYPES: "brokerTypes",
  SENDING_MR_INVOICE: "sendingMR",
  CUSTOMER_INTERNAL_MAPPING: "customerInternalMapping",
  SALE_ORDER_TYPES: "saleOrderTypes",
  RAIL_RATE: "rail_rate",
  CONTAINER_RATE: "container_rate",
  TALLY_SYNC: "autoSync",
  INVOICE_NO_MODIFY: "invoiceNoMdicfication",
  CONSTRUCTION_FIELDS: "constructionFields",
  WHATSAPP_CONFIG: "whatsappConfiguration",
  FUTURE_DAYS_ALLOWABLE: "futureDaysAllowable",
  EMAIL_CONFIGURATION: "emailconfiguration",
};

export const MULTI_TENANT_DATA = [
  {
    host: "localhost",
    serverIp: "https://devapi.vowerp.com",
    tenantId: "devsls.vowerp.com",
    // serverIp: "https://devapi.vowerp.com",
    // tenantId: "devsw.vowerp.com",
    // serverIp: "https://qaapi.vowerp.com",
    // tenantId: "qasls.vowerp.com",
    // serverIp: "https://tenant2.vowerp.com",
    // tenantId: "smartworks.vowerp.com",
    // serverIp: "https://tenantec2.vowerp.com",
    // tenantId: "sls.vowerp.com",
    // serverIp: "https://devapi.vowerp.com",
    // tenantId: "dev.vowerp.com",
    // serverIp: "https://tenantec2.vowerp.com",
    // tenantId: "cloud.vowerp.com",
    // serverIp: "https://tenantec2.vowerp.com",
    // tenantId: "talbot2.vowerp.com",
    // serverIp: "https://devapi.vowerp.com",
    // tenantId: "slstest.vowerp.com",
    // serverIp: "https://tenantec2.vowerp.com",
    // tenantId: "sls.vowerp.com",
    // serverIp: "https://devapi.vowerp.com",
    // tenantId:"devslstest.vowerp.com"
    // serverIp: "https://devapi.vowerp.com",
    // tenantId: "capextest.vowerp.com",

  },
  {
    host: "talbot2.vowerp.com",
    serverIp: "https://tenantec2.vowerp.com",
    tenantId: "talbot2.vowerp.com",
  },

  // dev 2.0 sls
  {
    host: "devsls.vowerp.com",
    serverIp: "https://devapi.vowerp.com",
    tenantId: "devsls.vowerp.com",
  },
  {
    host: "qasls.vowerp.com",
    serverIp: "https://qaapi.vowerp.com",
    tenantId: "qasls.vowerp.com",
  },
  {
    host: "demo.vowerp.com",
    serverIp: "https://tenantec2.vowerp.com",
    tenantId: "demo.vowerp.com",
  },
  // {
  //   host: "qa.vowerp.com",
  //   serverIp: "https://qaapi.vowerp.com",
  //   tenantId: "qa.vowerp.com",
  // },
  {
    host: "cloud.vowerp.com",
    serverIp: "https://tenantec2.vowerp.com",
    tenantId: "cloud.vowerp.com",
  },

  {
    host: "sls2.vowerp.com",
    serverIp: "https://tenantec2.vowerp.com",
    tenantId: "sls.vowerp.com",
  },
  {
    host: "anay.vowerp.com",
    serverIp: "https://tenantec2.vowerp.com",
    tenantId: "anay.vowerp.com",
  },
  {
    host: "amcl.vowerp.com",
    serverIp: "https://tenantec2.vowerp.com",
    tenantId: "amcl.vowerp.com",
  }
];

export const serverConfig = {
  SERVER_URL:
    process.env.NODE_ENV === "development"
      ? getHostIp("localhost") + "/"
      : getHostIp(window.location.hostname) + "/", //put dev
};

export const serverVars = {
  SECURITY: serverConfig.SERVER_URL + "security-api/",
  USER_MANAGEMENT_API: serverConfig.SERVER_URL + "security-api/api/",
  SECURITY_API: serverConfig.SERVER_URL + "security-api/api/",
  MASTER_API: serverConfig.SERVER_URL + "security-api/api/",
  PO_API: serverConfig.SERVER_URL + "security-api/api/poapi/",
  PAY_ROLL_DATA: serverConfig.SERVER_URL + "payroll-v2/",
  HRMS: serverConfig.SERVER_URL + "hrms-v2/",
  MASTER: serverConfig.SERVER_URL + "master-v2/machinemaster/",
  MASTERS: serverConfig.SERVER_URL + "master-v2/",
  SALES_INVOICE: serverConfig.SERVER_URL + "master-v2/",
  GST_VERIFICATION: serverConfig.SERVER_URL + "master-v2/thirdParty/api/",
  GOOGLE_LINK: serverConfig.SERVER_URL,
  USERMANAGEMENT_API:serverConfig.SERVER_URL + "usermanagement-v2/",
  JUTEPURCHASE: serverConfig.SERVER_URL + "security-api/api/jutepurchase/",
};

export const serverApi = {
  OAUTH_LOGIN: serverVars.SECURITY + "oauth/token",
  LOGIN:
    serverVars.USER_MANAGEMENT_API + "easybusiness/user/authenticateUserLogin",
  INDENT_LIST: serverVars.MASTER_API + "budget-heads/getAllIndentsPagination",
  INDENT_LIST_WITHOUT_PAGINATION: serverVars.MASTER_API + "budget-heads/getAllIndents",
  GET_ITEMLIST_BYITEMGROUPANDCOMPANYID:  serverVars.MASTER_API + "item/getItemsListByItemGroupAndCompanyId",
  GET_ITEMDETAILS_BYITEM_ITEMGRP_COMPANYID:serverVars.MASTER_API+ "item/getItemDetailsByItemGroupAndItemIdAndCompanyId",
  VALIDATE_ITEM_OI:serverVars.MASTER_API + "budget-heads/searchOpenIndentForItem/",
  VALIDATE_SUPPID_CHALLAN_INVOICE_NO :serverVars.MASTER_API +"budget-heads/forCheckingChallaNoAndInvoiceNoAndSupplier",
  GET_PENDING_STOCK: serverVars.PO_API + "api/v1/getPendingStock",
  GET_PENDING_STOCK_BRANCH_WISE: serverVars.PO_API + '/api/v1/getBranchWiseStock',
  GET_CENTRAL_STOCK: serverVars.PO_API +'api/v1/getBalStockByDeptAndItemId',
  GET_PENDING_STOCKPO_AND_RECEIPT:
    serverVars.PO_API + "api/v1/getPendingStockatPoAndReceipt",
  COMPANYMASTER_LIST_BY_ORG_ID:
    serverVars.MASTER_API + "company/getAllCompanyListByOrgId",

  // INDENT APIS
  GET_ITEM_DETAILS_BY_PO_ID: serverVars.MASTER_API + "budget-heads/getItemDetailsByPoId/",
  CREATE_INDENT: serverVars.MASTER_API + "budget-heads/indents",
  UPDATE_INDENT: serverVars.MASTER_API + "budget-heads/update-indents",
  ACTIONS: serverVars.MASTER_API + "budget-heads/actions",
  SEE_THE_LOGS:
    serverVars.MASTER_API +
    "budget-heads/actions/internalComments/module/INDENTS/action/LOGS/",
  SEE_THE_LOGSOF_INWARD:
    serverVars.MASTER_API +
    "budget-heads/actions/internalComments/module/INWARD/action/LOGS/",
  SEE_THE_LOGSOF_PO:
    serverVars.MASTER_API +
    "budget-heads/actions/internalComments/module/PO/action/LOGS/",
  GET_FILES:
    serverVars.MASTER_API + "files/getFileBySourcemenuAndTaskIdAndCompanyId/",
  DOWNLOAD_PDF:
    serverVars.MASTER_API + "budget-heads/downloadProcurementServicePdfFiles/",
  
 DEBIT_NOTE_DOWNLOAD_PDF :serverVars.MASTER_API +"poapi/api/v2/downloadingDebitNotePrintout/",

  DOWNLOAD_BPLI_PDF:
    serverVars.MASTER_API + "budget-heads/downloadDebitNotePdf/",
  GET_COPYOFINDNETS: serverVars.MASTER_API + "budget-heads/getCopyOfIndents",
  GET_POLINEITEMS_BYPOIDS:
    serverVars.MASTER_API + "budget-heads/getPOLineItemsByPoIds",
  CREATE_PROJECT: serverVars.MASTER_API + "budget-heads/projects",
  UPDATE_PROJECT: serverVars.MASTER_API + "budget-heads/update-projects",
  GET_ITEM_DETAILS_BY_PO_ID_FOR_INDENT: serverVars.MASTER_API + "budget-heads/getItemDetailsByIndentId/",

  // Price Enquiry....
  PRICE_ENQUIRY_LIST_BY_CMPY_ID:
    serverVars.PO_API + "PriceMaster/getPriceMasterList",
  ADD_PRICE_ENQUIRY: serverVars.PO_API + "/PriceMaster/addPriceMaster",
  GETPRICE_ENQUIRY_BYID: serverVars.PO_API + "PriceMaster/getPriceMasterById/",
  WORKORDER_LIST_BY_CMPY_ID:
    serverVars.MASTER_API + "budget-heads/getAllPOSPagination",
  OLDWORKORDER_LIST_BY_CMPY_ID:
    serverVars.MASTER_API + "budget-heads/getAllPOS",
  DOWNLOAD_PO: serverVars.MASTER_API + "budget-heads/downloadPO/",
  PR_RESPONSE_LIST_BY_CMPY_ID:
    serverVars.PO_API + "PriceMaster/getListBySuppCode",
  ALLINWARDS: serverVars.MASTER_API + "budget-heads/getAllInwardsPagination",
  ALLINWARDS_WITHOUT_PAGINATION: serverVars.MASTER_API + "budget-heads/getAllInwards",
  SR_VIEW_BY_ID_DATA:  serverVars.PO_API + "api/v2/StoreReceiptViewByIdForDebitNote/",
  DEBIT_CREDIT_LIST: serverVars.PO_API + "api/v2/getDebitCreditNoteListView",
  CREATE_DEBIT_NOTE: serverVars.PO_API + "api/v2/addDebitCreditNote",
  UPDATE_DEBIT_NOTE: serverVars.PO_API + "api/v2/updateDebitCreditNote",

  SR_NO_LIST: serverVars.PO_API + "api/v2/getSrDropDownList/",
  BALANCE_QUANTITY_DATA: serverVars.PO_API + "api/v2/getBalanceQuantityOfStoreReceipt",
  GET_ALL_BILL_PASS_LIST: serverVars.MASTER + "budget-heads/getAllInwards",

  ///Inward
  CREATE_INWARD: serverVars.MASTER_API + "budget-heads/inwards",
  UPDATE_INWARD: serverVars.MASTER_API + "budget-heads/update-inwards",
  UPDATE_STORE: serverVars.MASTER_API + "budget-heads/update-store",
  UPDATE_MATERIAL_INSPECTION:
    serverVars.MASTER_API + "budget-heads/material-inspection",
  GETPREVPURCHASERATE: serverVars.PO_API + "v1/getLastPurchaseRate/",

  GET_ACADEMIC_YEARS_LIST: serverVars.PO_API + "api/v1/getAcademicYearsList/",

  // document upload and delete apis
  UPLOADFILE: serverVars.MASTER_API + "files/Uploadfile",
  DELETEFILE: serverVars.MASTER_API + "files/deleteFile/",
  GET_ALL_FILES:
    serverVars.MASTER_API + "files/getFileBySourcemenuAndTaskIdAndCompanyId/",
  
  // master dropdown apis
  GST_VERIFICATION_API:
  serverVars.GST_VERIFICATION + "getGstDetails/",

  GET_AGENT_NAME_LIST:
    serverVars.MASTER_API + "company/companyForAgentsSelectionList",
  VEHICLE_TYPE_LIST_DROPDOWN: serverVars.MASTER_API + "vehicle/getAllVehicles/",
  GET_SUPPLIER_BY_MUKAM_ID: serverVars.PO_API + "api/v1/getSuppListByMukamId/",
  GET_BROKER_BY_SUPPLIER:
    serverVars.MASTER_API + "mbroker/getBrokersBySupplier/",
  SUPPLIER_TYPES_LIST:
    serverVars.SECURITY_API + "mastersupplier/getSupplierTypeMasterList",
  GET_QUALITY_BY_ITEM: serverVars.PO_API + "api/v1/",
  GET_VEHICLE_DATA: serverVars.MASTER_API + "vehicle/api/v1/getVehicleNumList/",
  DATATABLE_SERVICE_URL: serverVars.PO_API + "api/v1/",
  GET_MATERIAL_GR_VIEW_BYID:
    serverVars.JUTEPURCHASE + "api/v1/getMaterialGoodReceiveDetailsById/",
  GET_BROKERS_BY_SUPPLIER:
    serverVars.MASTER_API + "mbroker/getBrokersBySupplier/",
  AGENTS_LIST: serverVars.PO_API + "api/v1/getSupplierForJuteByType/O/",
  GET_GODOWN_NUMBER: serverVars.PO_API + "api/v1/getGodownNos",
  // global apis...
  GET_ALL_PROJECTS: serverVars.MASTER_API + "budget-heads/getAllprojects",
  GET_ALL_PROJECT_MASTER:
    serverVars.MASTER_API + "project/getProjectMasterList/",
  BRANCH_LIST: serverVars.MASTER_API + "branchMaster/getBranchSelectionList/",
  GET_ITEMGROUP_LIST: serverVars.PO_API + "api/v1/",
  GET_SUB_BUDGET_HEADS:
    serverVars.MASTER_API + "budget-heads/fetchSubExpenseTypes/",
  GET_PASHEBY_PROJECT:
    serverVars.MASTER_API + "budget-heads/getPhasesByProject",
  GET_ITEMGROUPS_BYBUDGET_HEADS:
    serverVars.MASTER_API + "budget-heads/getItemsGroupsByBudgetHeads/company",
  GET_ALL_SUPPLIERS_LIST:
    serverVars.PO_API + "api/v1/getSupplierForJuteByType/S/",
  DEBIT_NOTE_VIEW_BY_ID: serverVars.PO_API + "api/v2/debitCreditNoteViewById/",
  GET_ALL_CATEGORY_TYPE:
    serverVars.MASTER_API + "budget-heads/fetchExpenseTypes",
  GET_DEPARTMENT_LIST: serverVars.MASTER_API + "mdepartment/getDeptList/",
  GET_CLIENT_LIST:
    serverVars.MASTER_API + "customerMaster/getCustomersByCompany/",
  GET_STATUS_LIST: serverVars.MASTER_API + "statusMaster/getAllStatusMaster/S",
  STATUS_LISTS: serverVars.MASTER_API + "statusMaster/getAllStatusMaster/J",
  GET_PARENT_LEDGER_LIST:
    serverVars.MASTER_API + "ledgerMaster/getParentLedgerList/",
  GET_LEDGER_GROUPS_LIST:
    serverVars.MASTER_API + "ledgerGroup/getLedgerGroupList/",
  GET_STATE_NAME_LIST:
    serverVars.MASTER_API + "state/getStateNameSelectionList",
    
  GET_ALL_CUSTOMERS: serverVars.MASTER_API + "customerMaster/getAllCustomers",
  GET_ALL_BILLING_SHIPPING:
    serverVars.MASTER_API + "customerMaster/getCustomerById/",
  GET_ALL_ITEMGROUP_MASTER_LIST:
    serverVars.MASTER_API + "itemgroup/getAllItemGroupMasterList/N/",
  GET_ITEMS_BY_ITEMGROUP_ID:
    serverVars.MASTER_API + "item/getItemsByItemGroupId/",
  GET_ALL_TAXLIST: serverVars.MASTER_API + "tax/getAllTax/",
  GET_ITEMSBY_FORPROCUREMENT:
    serverVars.SECURITY_API + "item/getItemsByItemGroupIdForProcurement",
  GET_APPROVEDINDENTS:
    serverVars.MASTER_API + "budget-heads/getApprovedIndents",
  GET_APPROVEDINDENTS_FOR_JUTE_PO:serverVars.MASTER_API +"poapi/api/v1/getApprovedIndents",
  GET_SUPPLIER_BY_SUPPLIER_ID:
    serverVars.MASTER_API + "mastersupplier/getSupplierBySuppId/",
  GET_EMPLOYEE_CATAGERIES_LIST:
    serverVars.MASTER_API + "category/getCategories/",
  GET_SUPPLIER_LIST_MULTISELECT:
    serverVars.JUTEPURCHASE + "api/v1/getSupplierForJuteByType/J/",
  GET_SUPPLIER_LIST_JPO: serverVars.PO_API + "api/v1/getSupplierForJuteByType/",
  GET_TYPES_OF_MACHINE:
    serverVars.MASTER_API + "machinemaster/getAllMachineTypes",
  GET_YARN_TYPE_LIST: serverVars.MASTER_API + "yarn/getYarnTypeLists/",
  GET_PLAN_NAME:serverVars.PO_API+"/getPlanNames/",

  GET_CONFIGURATION_DATA:
    serverVars.MASTER_API + "poapi/api/v1/getConfiguration/",

    GET_GST_VERIFICATION:
    serverVars.MASTER_API + "state/getStateNameSelectionList",

  GET_ITEMS_BY_CONSUMPTION:
    serverVars.SECURITY_API + "item/getItemListByItemGroupIdForConsumption", 

  // GET_ALLITEM_GROUPMASTER_LIST:serverVars.MASTER_API + 'itemgroup/getAllItemG
  GET_BUDGET_HEADS_LIST: serverVars.MASTER_API + "budget-heads/budget-heads",
  MUKAM_GET_LIST: serverVars.JUTEPURCHASE + "api/v1/getAllMukam/",
  MUKAM_BY_ITEM_LIST: serverVars.PO_API + "api/v1/",
  ITEM_LIST_BY_MUKAM:serverVars.MASTER_API+"poapi/api/v1/",
  GET_JUTE_TYPE:serverVars.MASTER_API+"poapi/api/v1/",
  // GET_ALLITEM_GROUPMASTER_LIST:serverVars.MASTER_API + 'itemgroup/getAllItemGroupMasterList/N/',
  GET_PARENT_BUDGET_HEADS_LIST:
    serverVars.MASTER_API + "budget-heads/getBudgetHeadsinProcurement/",
  GET_BILLING_ADDRESS_SHIPPING_ADDRESS:
    serverVars.MASTER_API + "branchMaster/getAllBranchList",
  GET_BUDGET_HEADES:
    serverVars.MASTER_API + "budget-heads/getBudgetHeadsinProcurement/company/",
  GET_PROCUREMENT_ITEMGRP_MASTERLIST:
    serverVars.MASTER_API + "itemgroup/getProcurementItemGroupMasterList",
  // workorder create api
  CREATE_WORK_ORDER: serverVars.MASTER_API + "budget-heads/purchase-order",
  UPDATE_PO_WO: serverVars.MASTER_API + "budget-heads/update-purchase-order",
  SR_NUMBER_DATA:
    serverVars.MASTER_API +
    "poapi/api/v1/getStoreGoodReceiveDetailsBySuppCode/",
  // master module starts
  GET_ALL_TAXMASTERLIST: serverVars.MASTER_API + "tax/getAllTaxList",
  ADDITEM_TO_TAXLIST: serverVars.MASTER_API + "tax/addTax",
  UPDATE_TAXLIST: serverVars.MASTER_API + "tax/updateTax",
  //Supplier Master

  // GET_ALL_SUPPLIER_LIST : serverVars.MASTER_API + '/mastersupplier/supplierMaster',
  //pagination api for supplier master list
  // GET_ALL_SUPPLIER_MASTER_LIST:serverVars.MASTER_API + "mastersupplier/supplierMaster",
  // GET_ALL_SUPPLIER_MASTER_LIST:
  //   serverVars.MASTER_API + "mastersupplier/supplierMasterWithLineItems",
  GET_ALL_SUPPLIER_MASTER_LIST:
    serverVars.MASTER_API + "mastersupplier/supplierMasterWithLineItemsv2",

  // CREATE_SUPPLIER_MASTER_API:
  //   serverVars.MASTER_API + "mastersupplier/addSupplierMaster",
  CREATE_SUPPLIER_MASTER_API:
    serverVars.MASTER_API + "mastersupplier/addSupplierMasterv2",

  SUPPLIER_VIEWBY_ID: serverVars.MASTER_API + "mastersupplier/getSupplierById",
  // UPDATE_SUPPLIER_MASTER:
  //   serverVars.MASTER_API + "mastersupplier/updateSupplierMaster",
  UPDATE_SUPPLIER_MASTER:
    serverVars.MASTER_API + "mastersupplier/updateSupplierMasterv2",

  //Department Master
  GET_ALL_DEPARTMENT_MASTER_LIST:
    serverVars.MASTER_API + "mdepartment/getAllDepartmentList",
  CREATE_DEPARTMENT: serverVars.MASTER_API + "mdepartment/addDepartmentList",
  UPDATE_DEPARTMENT: serverVars.MASTER_API + "mdepartment/updateDepartmentList",
  //SubDepartment Master
  GET_ALL_SUB_DEPARTMENT_LIST:
    serverVars.MASTER_API + "subDepartmentMaster/getAllSubDepartments",
  CREATE_SUB_DEPARTMENT:
    serverVars.MASTER_API + "subDepartmentMaster/addSubDepartment",
  UPDATE_SUB_DEPARTMENT:
    serverVars.MASTER_API + "subDepartmentMaster/updateSubDepartment",
  //Ledger Master
  GET_ALL_LEDGER_MASTER_LIST:
    serverVars.MASTER_API + "ledgerMaster/getAllLedgerMaster",
  CREATE_LEDGER_MASTER: serverVars.MASTER_API + "ledgerMaster/addLedger",
  UPDATE_LEDGER_MASTER: serverVars.MASTER_API + "ledgerMaster/updateLedger",
  //LedgerGroups Master
  GET_ALL_LEDGER_GROUPS_LIST:
    serverVars.MASTER_API + "ledgerGroup/getAllLedgerGroup",
  CREATE_LEDGER_GROUPS_MASTER:
    serverVars.MASTER_API + "ledgerGroup/addLedgerGroup",
  UPDATE_LEDGER_GROUPS_MASTER:
    serverVars.MASTER_API + "ledgerGroup/updateLedgerGroup",
  // Terms Master
  GET_ALL_TERMS_LIST:
    serverVars.MASTER_API + "termsAndconditions/getTermsAndConditionsList",
  CREATE_TERMS:
    serverVars.MASTER_API + "termsAndconditions/addUpdateTermsAndConditions",
  //Transporter Master
  GET_ALL_TRANSPORTER_MASTER_LIST:
    serverVars.MASTER_API + "transport/getTransportersList/",
  TRASPORTER_MASTER_LIST_NEW_API:
    serverVars.MASTER_API + "transport/getTransporterMasterList",
  CREATE_TRANSPORTER_MASTER: serverVars.MASTER_API + "transport/addTransporter",
  UPDATE_TRANSPORTER_MASTER:
    serverVars.MASTER_API + "transport/updateTransporter",
  UPDATE_TRANSPORTER_MASTER_API:
    serverVars.MASTER_API + "transport/updateTransporterMaster",
  DELETE_TRANSPORTER_MASTER_API:
    serverVars.MASTER_API + "transport/deleteTransporterMaster",

  //Travel Catageries Master
  //GET_ALL_TRAVEL_CATAGERIES_LIST:serverVars.MASTER_API + "travelController/getAllTravelCategories",
  //pagination api for category master
  GET_ALL_TRAVEL_CATAGERIES_LIST:
    serverVars.MASTER_API + "travelController/getAllTravelCategories",
  CREATE_TRAVEL_CATAGERIES:
    serverVars.MASTER_API + "travelController/addTravelCategory",
  UPDATE_TRAVEL_CATAGERIES:
    serverVars.MASTER_API + "travelController/updateTravelCategory",
  // Bank Accounts Master
  GET_ALL_BANK_ACCOUNT_LIST:
    serverVars.MASTER_API + "bankAccountsController/getAllBankAccountsDetails",
  CREATE_BANK_ACCOUNT:
    serverVars.MASTER_API + "bankAccountsController/addBankAccount",
  UPDATE_BANK_ACCOUNT:
    serverVars.MASTER_API + "bankAccountsController/updateBankAccount",
  // Designation Master
  GET_ALL_DESIGNATION_MASTER_LIST:
    serverVars.MASTER_API + "mdesignation/getAllDesignationList",
  CREATE_DESIGNATION_MASTER:
    serverVars.MASTER_API + "mdesignation/addDesignation",
  GET_DESIGNATION_API_LIST:
    serverVars.MASTER_API + "mdesignation/getAllDesignationListView",

  UPDATE_DESIGNATION_MASTER:
    serverVars.MASTER_API + "mdesignation/updateDesignation",
  // Cost Center Master
  GET_ALL_COST_CENTER_LIST:
    serverVars.MASTER_API + "CostCenterMaster/getAllCostCenterList",
  CREATE_COSTCENTER_MASTER:
    serverVars.MASTER_API + "CostCenterMaster/addCostCenter",
  UPDATE_COSTCENTER_MASTER:
    serverVars.MASTER_API + "CostCenterMaster/updateCostCenter",

  // Leave Type Master
  GET_ALL_LEAVE_TYPE_LIST:
    serverVars.MASTER_API + "LeaveTypes/getLeaveTypesList/",
  GET_ALL_LEAVE_TYPES_LIST:
    serverVars.MASTER_API + "LeaveTypes/getLeaveTypesListView",
  CREATE_LEAVE_TYPE: serverVars.MASTER_API + "LeaveTypes/postLeaveTypes",
  UPDATE_LEAVE_TYPE: serverVars.MASTER_API + "LeaveTypes/editLeaveTypes",

  // Customer Master
  // Customer MasterList API
  GET_ALL_CUSTOMER_LIST:
    serverVars.MASTER_API + "customerMaster/getAllCustomers",
  // Customer Master Pagination List API
  GET_ALL_CUSTOMER_PAGINATED_LIST:
    serverVars.MASTER_API + "customerMaster/getAllCustomersPaginatedV2",
  CREATE_NEW_CUSTOMER_MASTER:
    serverVars.MASTER_API + "customerMaster/addCustomerV2",
  CUSTOMER_MASTER_VIEW_BY_ID:
    serverVars.MASTER_API + "customerMaster/getCustomerById",
  UPDATE_CUSTOMER_MASTER:
    serverVars.MASTER_API + "customerMaster/updateCustomerV2",
   GET_ALL_ENTITIES_LIST:serverVars.MASTER_API +"entityType/getEntityTypeDetails", 
  UPDATE_CUSTOMER_MASTER_CACHE:
    serverVars.MASTER_API + "customerMaster/updateCustomerInCache",

  // Item Master
  GET_ALL_ITEM_LIST: serverVars.MASTER_API + "item/getMasterItemList",
  CREATE_ITEM_MASTER: serverVars.MASTER_API + "item/addItemMaster",
  UPDATE_ITEM_MASTER: serverVars.MASTER_API + "item/updateItmMaster",
  ITEM_MASTER_VIEW_BY_ID: serverVars.MASTER_API + "item/getItemsById/",
  // Item Group Master
  GET_ALL_ITEM_GROUP_LIST:
    serverVars.MASTER_API + "itemgroup/itemGroupTableList",
  CREATE_ITEM_GROUP_MASTER: serverVars.MASTER_API + "itemgroup/addItemGroup",
  UPDATE_ITEM_GROUP_MASTER: serverVars.MASTER_API + "itemgroup/updateItemGroup",
  // UOM Master
  GET_ALL_UOM_LIST: serverVars.MASTER_API + "uommaster/getUomMasterCompanyId",
  CREATE_UOM_MASTER: serverVars.MASTER_API + "uommaster/addNewUomMaster",
  GET_UOM_RELATION: serverVars.MASTER_API + "uommaster/getUomMasterRelation",

  // UOM LIST based on item ID:
  UOM_LIST_MASTERS_BASEDON_ITEM: serverVars.MASTER_API + "uommaster/getUomRelationValueByItemIdAndCompanyId/",

  // UOM Dropdown
  GET_ALL_UOM_DD_LIST:
    serverVars.MASTER_API + "uommaster/getUomMasterByRecordType/",
    GET_UOM_RELATION_VALUES:
    serverVars.MASTER_API + "uommaster/getUomRelation/",
    GET_UOM_RELATION_VALUES_FROMUOM:
    serverVars.MASTER_API + "uommaster/getUomRelationValue/fromUom/",
    GET_UOM_RELATION_VALUE_BY_UOM_CODE:
    serverVars.MASTER_API + "uommaster/getUomRelationValueByUomCodeAndItemId/",
  //BudgetHeads
  GET_ALL_BUDGET_HEADS_LIST:
    serverVars.MASTER_API + "budget-heads/getBudget-heads",

  GET_ALL_BUDGETHEADS_LIST:
    serverVars.MASTER_API + "budget-heads/getBudget-heads-list",

  CREATE_BUDGET_HEADS: serverVars.MASTER_API + "budget-heads/budget-heads",
  UPDATE_BUDGET_HEADS:
    serverVars.MASTER_API + "budget-heads/update-budget-heads",
  // worker category
  GET_ALL_WORKER_CATEGORY_LIST:
    serverVars.MASTER_API + "category/getAllCategories",
  CREATE_WORKER_CATEGORY: serverVars.MASTER_API + "category/addCategories",
  UPDATE_WORKER_CATEGORY: serverVars.MASTER_API + "category/updateCategory",
  // vehicle Types Master
  GET_ALL_VEHICLE_TYPES_MASTER_LIST:
    serverVars.MASTER_API + "vehicle/getAllVehicles",
  CREATE_VEHICLE_TYPES_MASTER: serverVars.MASTER_API + "vehicle/addVehicle",
  UPDATE_VEHICLE_TYPES_MASTER: serverVars.MASTER_API + "vehicle/updateVehicle",
  // weaving Quality master
  GET_ALL_WEAVING_QUALITY_MASTER_LIST:
    serverVars.MASTER_API + "weavingQualityMaster/getWeavingQualities",
  CREATE_WEAVING_QUALITY_MASTER:
    serverVars.MASTER_API + "weavingQualityMaster/addWeavingQualities",
  UPDATE_WEAVING_QUALITY_MASTER:
    serverVars.MASTER_API + "weavingQualityMaster/updateWeavingQuality",
  //Mukam Master
  GET_ALL_MUKAM_MASTER_LIST:
    serverVars.MASTER_API + "mmukam/getAllMukamListWithItemsList",
  CREATE_MUKAM_MASTER: serverVars.MASTER_API + "mmukam/addMukam",
  UPDATE_MUKAM_MASTER: serverVars.MASTER_API + "mmukam/updateMukamList",
  //Quality master
  //GET_ALL_QUALITY_MASTER_LIST: serverVars.MASTER_API + 'qualityPriceMaster/getAllQualityMasterList',
  //pagination api for quality master
  GET_ALL_QUALITY_MASTER_LIST:
    serverVars.MASTER_API + "qualityPriceMaster/getQualityMasterPagination",
  CREATE_QUALITY_MASTER:
    serverVars.MASTER_API + "qualityPriceMaster/addQualityPriceMaster",
  UPDATE_QUALITY_MASTER:
    serverVars.MASTER_API + "qualityPriceMaster/updateQualityPriceMaster",
  QUALITY_MASTER_VIEW_BY_ID:
    serverVars.MASTER_API + "qualityPriceMaster/getJuteQualityPriceMasterById",
  // Broker master
  GET_ALL_BROKER_MASTER_LIST:
    serverVars.MASTER_API + "mbroker/getAllBrokerList",
  GET_ALL_BROKER_LIST: serverVars.MASTER_API + "mbroker/getAllBroker/",
  CREATE_BROKER_MASTER: serverVars.MASTER_API + "mbroker/addBroker",
  UPDATE_BROKER_MASTER: serverVars.MASTER_API + "mbroker/updateBroker",
  // Machine master
  GET_ALL_MACHINE_MASTER_LIST:
    serverVars.MASTER_API + "machinemaster/getAllMachineMaster",
  CREATE_MACHINE_MASTER:
    serverVars.MASTER_API + "machinemaster/addMachineMaster",
  UPDATE_MACHINE_MASTER:
    serverVars.MASTER_API + "machinemaster/updateMachineMaster",
  MACHINE_MASTER_VIEW_BY_ID:
    serverVars.MASTER_API + "machinemaster/getMachineMasterById/",
  // SPINNING QUALITY MASTER
  GET_ALL_SPINNING_QUALITY_LIST:
    serverVars.MASTER_API + "weavingQualityMaster/getWeavingQualities",
  CREATE_SPINNING_QUALITY:
    serverVars.MASTER_API + "weavingQualityMaster/addWeavingQualities",
  UPDATE_SPINNING_QUALITY:
    serverVars.MASTER_API + "weavingQualityMaster/updateWeavingQuality",
  // Yarn master
  GET_ALL_YARN_MASTER_LIST: serverVars.MASTER_API + "yarn/getYarnMasterList",
  CREATE_YARN_MASTER: serverVars.MASTER_API + "yarn/addYarnMaster",
  UPDATE_YARN_MASTER: serverVars.MASTER_API + "yarn/updateYarnMaster",
  // Company  master
  GET_ALL_COMPANY_MASTER_LIST:
    serverVars.MASTER_API + "company/getAllCompanyList",
  CREATE_COMPANY_MASTER: serverVars.MASTER_API + "company/addCompany",
  UPDATE_COMPANY_MASTER: serverVars.MASTER_API + "company/updateCompany",
  // Branch  master
  GET_ALL_BRANCH_MASTER_LIST:
    serverVars.MASTER_API + "branchMaster/getAllBranchList",
  CREATE_BRANCH_MASTER: serverVars.MASTER_API + "branchMaster/addBranchList",
  UPDATE_BRANCH_MASTER: serverVars.MASTER_API + "branchMaster/updateBranchList",
  // Agent Master
  GET_ALL_AGENT_MASTER_LIST:
    serverVars.MASTER_API + "agent/companyAgentsTableList",
  CREATE_AGENT_MASTER: serverVars.MASTER_API + "agent/addCompanyAgent",
  // UPDATE_BRANCH_MASTER: serverVars.MASTER_API + "branchMaster/updateBranchList",
  //Holiday Calendar
  GET_ALL_HOLIDAY_MASTER_LIST:
    serverVars.MASTER_API + "holidaymaster/getAllHolidayMaster",
  ADD_HOLIDAY_MASTER: serverVars.MASTER_API + "holidaymaster/addHolidayMaster",
  DELETE_HOLIDAY_ITEM:
    serverVars.MASTER_API + "holidaymaster/deleteHolidayMaster/",
  UPDATE_HOLIDAY_LIST_EMPLOYEE:
    serverVars.MASTER_API +
    "alm_service/holiday/updateHolidayListForEmployees/",

  // Carriers
  GET_ALL_CARRIERS_LIST:
    serverVars.MASTER_API + "WithCarriers/getWithCarrierlist",
  // CREATE_AGENT_MASTER: serverVars.MASTER_API + "agent/addCompanyAgent",
  // UPDATE_BRANCH_MASTER: serverVars.MASTER_API + "branchMaster/updateBranchList",

  UPDATE_HOLIDAY_ITEM:
    serverVars.MASTER_API + "holidaymaster/updateHolidayMaster",
  // master module ends
  //Store Module//

  // GET_ALL_MATERIAL_REQUEST_LIST: serverVars.PO_API + "api/v1/getStoreIssueList",
  GET_ALL_MATERIAL_REQUEST_LIST:
    serverVars.PO_API + "api/v1/getStoreIssueListPagination",
  GET_ALL_STOCK_TRANSFER_LIST:
    serverVars.PO_API + "api/v1/getAllStoreTransferList",
  GET_ALL_STOCK_RETURN_LIST: serverVars.PO_API + "api/v1/getReturnList",
  // GET_ALL_MATERIAL_REQUEST_LIST: serverVars.PO_API + "api/v1/getStoreIssueList",
  STORE_ISSUE_VIEW_BY_ID: serverVars.MASTER_API + "poapi/api/v1/getStoreIssueById",


  //Store Module ends//
  EMPLOYEE_LIST: serverVars.MASTER_API + "workerMaster/getFullWorkersList",
  DOWNLOAD_BULK_EMPLOYEE_TEMPLATE:serverVars.MASTER_API+'employeeDatabase/downloadEmpDatabaseTemplate',
  DOWNLOAD_BULK_ATTENDANCE_TEMPLATE:serverVars.MASTER_API+'employeeDatabase/downloadAttendanceBulkTemplate',
  NEW_EMPLOYEE_LIST:
    serverVars.MASTER_API + "employeeDatabase/listViewOfFullWorkers",
  ADD_EMPLOYEE: serverVars.HRMS + "workerMaster/addOrUpdateEmployeeDetails",
  UPDATE_WORKER_STATUS: serverVars.HRMS + "workerMaster/updateWorkerStatus/",

  // ADD_EMPLOYEE:
  //   "https://qaapi.vowerp.com/hrms-v2/workerMaster/addOrUpdateEmployeeDetails",
  // PAYSLIP_LIST:serverVars.MASTER_API + "workerMaster/getWorkersList",
  PAYSLIP_DATA: serverVars.MASTER_API + "workerMaster/getWorkersByEbNo",
  PAYSLIP_AVAILABLE_DATA:
    serverVars.MASTER_API + "PayEmployeePayroll/getAvailablePayslips",
  PAY_SUMMARY: serverVars.MASTER_API + "PayEmployeePayroll/getPayslipSummary",
  GET_MONTHLY_PAYSALARY:
    serverVars.MASTER_API + "PayEmployeePayroll/getMonthlySalaryPayment",
  DOWNLOAD_PAYSLIP:
    serverVars.MASTER_API + "PayEmployeePayroll/downloadPaySlip",
  PAY_REGISTER_LIST: serverVars.MASTER_API + "PayPeriod/getAllPayPeriods",
  GENERATE_INVOICE: serverVars.MASTER_API + "poapi/api/v1/autoInvoice",
  UPDATE_PAY_REGISTER: serverVars.MASTER_API + "PayPeriod/updatePayPeriod",
  LEAVE_REQUEST_LIST:
    serverVars.MASTER_API + "leavetransaction/getLeaveTransactionsList/",
  PAY_SCHEME: serverVars.MASTER_API + "PayEmployeePayroll/getProcessedList/",
  LEFT_SIDE_MENU: serverVars.MASTER_API + "/easybusiness/menu/getUserMenus/",
  VENDOR_LEFT_SIDE_MENU: serverVars.MASTER_API + "/easybusiness/usergroupmenusubmenu/getUserMenusResultByGroupId/100",

  //spell master
  SPELL_MASTER_LIST: serverVars.MASTER_API + "/dutyspell/getAllSpells",
  //pagination for spell master list
  SPELL_MASTER: serverVars.MASTER_API + "/dutyspell/getSpellMasterPagination",
  CREATE_SPELL_MASTER: serverVars.MASTER_API + "dutyspell/addSpell",
  UPDATE_SPELL_MASTER: serverVars.MASTER_API + "dutyspell/updateSpell",
  GET_PARENT_SPELL: serverVars.MASTER_API + "dutyspell/getCompanyParentSpell/",
  SHIFT_SPELL_MASTER:
    serverVars.MASTER_API + "dutyspell/getSpellListByCompanyId",
  // Ware House Master
  GET_ALL_WARE_HOUSE_LIST: serverVars.MASTER_API + "godownmaster/getWareHouses",
  GET_ALL_ENTITIES_LIST:serverVars.MASTER_API +"entityType/getEntityTypeDetails",
  CREATE_WAREHOUSE_MASTER:
    serverVars.MASTER_API + "godownmaster/addWarehouseDetails",
  UPDATE_WAREHOUSE_MASTER:
    serverVars.MASTER_API + "godownmaster/updateWarehouseDetails",
  // Customer Master
  // Item Master
  // Item Group Master
  // shift Master
  CREATE_SHIFT_MASTER:
    serverVars.MASTER_API + "employeeShiftMapping/addEmployeeShiftMapping",
  BIO_METRIC_DEVICE:
    serverVars.MASTER_API + "biometricmaster/getBiometricmasterSelectionList",
  CREATE_BIO_METRIC_DEVICE:
    serverVars.MASTER_API + "biometricEmpMap/addBiometric",
  // master module ends

  // sales module
  GET_QUOTATION_LIST: serverVars.PO_API + "api/v1/getQuoteListPagination",
  QUOTATION_ITEM_BY_ITEM_GROUP:
    serverVars.MASTER_API + "item/getItemsByItemGroupId",
  ADD_QUOTATIONS:
    serverVars.MASTER_API + "poapi/api/v1/addOrUpdateQuotationDetails",
  QUOTATION_VIEW_BYID:serverVars.PO_API+"api/v2/getSalesQuotationByQuotationId/",
  ITEM_UOM_RELATION_BY_UOM_CODE:
    serverVars.MASTER_API + "uommaster/getItemUomRelationValueByUomCode",
  GET_SALESORDER_LIST: serverVars.PO_API + "api/v1/getSalesOrderList",
  SALES_ORDER_DROPDOWN :serverVars.PO_API+"api/v2/getSalesOrderDropDownListForDeliveryOrder/",
  SALES_ORDER_DROPDOWN_FOR_INVOICE:serverVars.PO_API+"api/v2/getSalesOrderDropDownList/",
  GET_DELIVERY_LIST: serverVars.PO_API + "api/v1/getDeliveryOrderList",
  CREATE_DELIVERY_ORDER :serverVars.PO_API+"api/v2/deliveryOrder/addRegularDeliveryOrder",
  UPDATE_DELIVERY_ORDER :serverVars.PO_API+"api/v2/deliveryOrder/updateRegularDeliveryOrder",
  CREATE_JUTE_GOVT_DELIVERY_ORDER :serverVars.PO_API+"api/v2/deliveryOrder/addGovtDeliveryOrder",
  UPDATE_JUTE_GOVT_DELIVERY_ORDER :serverVars.PO_API+"api/v2/deliveryOrder/updateGovtDeliveryOrder",
  CREATE_JUTE_YARN_DELIVERY_ORDER:serverVars.PO_API+"api/v2/deliveryOrder/addYarnDeliveryOrder",
  UPDATE_JUTE_YARN_DELIVERY_ORDER :serverVars.PO_API+"api/v2/deliveryOrder/updateYarnDeliveryOrder",
  UOM_RELATION_BY_UOM_CODE:
  serverVars.MASTER_API + "uommaster/getUomRelationValueByUomCodeAndItemId/",

  JOTE_GOVT_DELIVERY_ORDER_VIEW_BY_ID :serverVars.PO_API+"api/v2/deliveryOrder/deliveryOrderByInvoiceType/",

  CREATE_REGULAR_INVOICE : serverVars.PO_API +"api/v2/invoice/addRegularInvoice",
  CREATE_JUTE_GOVT_INVOICE : serverVars.PO_API +"api/v2/invoice/addGovtInvoice",
  CREATE_JUTE_HESSIAN_INVOICE:serverVars.PO_API+"api/v2/invoice/addHessianInvoice",
  CREATE_JUTE_YARN_INVOICE : serverVars.PO_API +"api/v2/invoice/addYarnInvoice",


  INVOICE_VIEW_BY_ID:serverVars.PO_API +"api/v2/invoice/getInvoiceById/",
  UPDATE_REGULAR_INVOICE:serverVars.PO_API+"api/v2/invoice/updateRegularInvoice",
  UPDATE_JUTE_GOVT_INVOICE:serverVars.PO_API+"api/v2/invoice/updateGovtInvoice",
  UPDATE_JUTE_YARN_INVOICE:serverVars.PO_API+"api/v2/invoice/updateYarnInvoice",


  UPDATE_JUTE_HESSIAN_INVOICE:serverVars.PO_API+"api/v2/invoice/updateHessianInvoice",

  DELIVERY_ORDER_VIEW_BY_ID :serverVars.PO_API+"api/v2/deliveryOrder/deliveryOrderByInvoiceType/",
  GET_INVOICE_LIST: serverVars.PO_API + "api/v1/getInvoiceList",
  GET_MATERIAL_DETAILS_BY_ID:
    serverVars.PO_API + "api/v1/getMaterialGoodReceiveDetailsById/",
  CREATE_SALES: serverVars.PO_API + "api/v1/addSalesOrder",
  SALES_ORDER_VIEW_BYID: serverVars.PO_API + "api/v2/getSalesOrderById/",
  UPDATE_SALES_ORDER: serverVars.PO_API + "api/v1/updateSalesOrder",
  SALES_ORDER_PDF_DOWNLOAD:
    serverVars.MASTER_API + "poapi/api/v1/dowunloadSalesOrderPdf/",
  REGULAR_INVOICE_PDF_DOWNLOAD :serverVars.PO_API +"api/v2/invoice/downloadInvoicePrintOut/",
  REGULAR_DELIVERY_PDF_DOWNLOAD:serverVars.PO_API +"api/v2/deliveryOrder/downloadDeliveryOrderPrintOut/",
  REGULAR_INVOICE_EWAY_BILL_PDF_DOWNLOAD :serverVars.PO_API +"api/v2/invoice/downloadEwayBill/",

  //GST MODULE
  UPDATE_CLIENT_CREDENTIALS: serverVars.PO_API+"api/v2/invoice/updateClientCredentials",
  REQUEST_OTP:serverVars.PO_API+"/thirdParty/api/gstr2a/authentication/otprequest",
  SUBMIT_GST_CREDENTIALS_OTP:serverVars.PO_API+"/thirdParty/api/gstr2a/authentication/authtoken",

  // Sales Module V2
  GET_QUOTATION_LIST_V2: serverVars.PO_API + "api/v2/getSalesQuotationList",
  GET_QUOTATION_DROPDOWN: serverVars.PO_API + "api/v2/salesQuotationDropDownList/",
  CREATE_QUOTATION: serverVars.PO_API + "api/v2/addSalesQuotation",
  UPDATE_SALES_QUOTATION: serverVars.PO_API + "api/v2/updateSaleQuotation",
  QUOTATION_PDF_DOWNLOAD: serverVars.PO_API + "api/v2/downloadQuotationPrintOut/",
  DOWNLOAD_REGULAR_SO_PRINTOUT: serverVars.PO_API+"api/v2/downloadSalesOrderPrintOut/",

  SALES_ORDER_LIST:serverVars.PO_API+"api/v2/getRegularSalesOrderListPagination",
  CREATE_SO_JUTE_GOVT:serverVars.PO_API+"api/v2/addGovernmentSalesOrder",
  UPDATE_SO_JUTE_GOVT:serverVars.PO_API+"api/v2/updateGovernmentSalesOrder",
  UPDATE_SO_JUTE_YARN:serverVars.PO_API+"api/v2/updateYarnSalesOrder",
  
  CREATE_SO_JUTE_YARN:serverVars.PO_API+"api/v2/addYarnSalesOrder",
  SO_JUTE_YARN_VIEW_BY_ID: serverVars.PO_API + "api/v2/getSalesOrderById/",
  UPDATE_JUTE_YARN_SALES_ORDER: serverVars.PO_API + "api/v2/updateYarnSalesOrder",
  CREATE_DO_JUTE_YARN:serverVars.PO_API+"api/v2/deliveryOrder/addYarnDeliveryOrder",
  UPDATE_DO_JUTE_YARN:serverVars.PO_API+"api/v2/deliveryOrder/updateYarnDeliveryOrder",
  
  CREATE_SO_JUTE_HESSIAN:serverVars.PO_API+"api/v2/addHessianSalesOrder",
  SO_JUTE_HESSIAN_VIEW_BY_ID: serverVars.PO_API + "api/v2/getSalesOrderById/",
  UPDATE_JUTE_HESSIAN_SALES_ORDER: serverVars.PO_API + "api/v2/updateHessianSalesOrder",
  CREATE_REGULAR_SALES_ORDER:serverVars.PO_API+"api/v2/addRegularSalesOrder",
  UPDATE_REGULAR_SALES_ORDER:serverVars.PO_API+"api/v2/updateRegularSalesOrder",

  GET_INVOICE_LIST_V2: serverVars.PO_API + "api/v2/invoice/getRegularInvoiceListPagination",
  CREATE_DO_JUTE_HESSIAN:serverVars.PO_API+"api/v2/deliveryOrder/addHessianDeliveryOrder",
  UPDATE_DO_JUTE_HESSIAN:serverVars.PO_API+"api/v2/deliveryOrder/updateHessianDeliveryOrder",

  GENERATE_E_INVOICE: serverVars.PO_API + "api/v2/invoice/UploadEInvoice/",
  GENERATE_E_WAY_BILL: serverVars.PO_API + "api/v2/invoice/uploadEwayBill/",

  // HRMS
  HRMS_DASHBOARD_DATA: serverVars.MASTER_API + "workerMaster/getDashBoard",
  // BRANCH_LIST:serverVars.MASTER_API+'branchMaster/getBranchSelectionList/',
  CREATE_PAY_PERIOD: serverVars.MASTER_API + "PayPeriod/addPayPeriod",
  PAYROLL_DATA_ITEM: serverVars.MASTER_API + "PayProcess/getEmployeeComponents",
  DOWNLOAD_PAYROLL_TEMPLATE:
    serverVars.MASTER_API + "PayProcess/downloadPayrollTemplate",
  EXPENSE_BOOKING_LIST:
    serverVars.MASTER_API + "expensesBooking/getExpenseBookingList/",
  WORKER_LIST: serverVars.MASTER_API + "workerMaster/getWorkersByEbNo/",
  ADVANCE_REQUEST_LIST:
    serverVars.MASTER_API + "advancerequest/getAdvanceRequestList/",
  ADD_EXPENSE_BOOKING:
    serverVars.MASTER_API + "expensesBooking/addExpenseBooking",
  UPDATE_ADVANCE_REQUEST:
    serverVars.MASTER_API + "advancerequest/updateAdvanceRequest",
  EXPENSE_BOOKING_VIEW_BYID:
    serverVars.MASTER_API + "expensesBooking/getExpenseBookingById/",
  CREATE_ADVANCE_REQUEST:
    serverVars.MASTER_API + "advancerequest/addAdvanceRequest",
  ADVANCE_REQUEST_VIEW_BY_ID:
    serverVars.MASTER_API + "advancerequest/getAdvanceRequestById/",
  UPDATE_EXPENSE_BOOKING:
    serverVars.MASTER_API + "expensesBooking/updateExpenseBooking",
  PERSONAL_DETAILS: serverVars.HRMS + "workerMaster/addWorkerDetails",
  EMPLOYEE_DETAILS_VIEWBYID:
    serverVars.MASTER_API + "workerMaster/getWorkerDetailsById/",
  DELETE_BIOMETRIC_DEVICE_ID:serverVars.MASTER_API +'biometricEmpMap/deleteBiometricEmpMap/',
  EXPERIENCE_DETAILS: serverVars.MASTER_API + "experience/addExperienceDetails",
  EXPERIENCE_DETAILS_VIEW_BY_ID:
    serverVars.MASTER_API + "experience/getExperienceDetaislByEmpId/",
  ATTENDANCE_LIST:
    serverVars.MASTER_API + "dailyAttendance/getDailyAttendanceList",
  CHECK_ZERO_WORKING_HOURS:
    serverVars.MASTER_API + "dailyAttendance/getZeroWorkingHoursList",
  SPELL: serverVars.MASTER_API + "dutyspell/getAllSpellSelection/",
  MACHINE_NUMBER: serverVars.MASTER_API + "machinemaster/getMachineMasterByDesignation/",
  MARK_ATTENDANCE:
    serverVars.MASTER_API + "dailyAttendance/addWorkerDailyAttendance",
  PROCESS_LOGS:
    serverVars.MASTER_API + "PayProcess/processDataFromLogsToDailyAttendance/",
  PAY_PERIOD_BY_ID: serverVars.MASTER_API + "PayPeriod/getPayPeriodById/",
  PROCESSED_LIST:
    serverVars.MASTER_API + "PayEmployeePayroll/getProcessedList/",
  
  // Add Employees
  DEPARTMENT_LIST:
    serverVars.MASTER_API +
    "subDepartmentMaster/getSubDepartmentSelectionListByCompany/",
  CATEGORY_LIST: serverVars.MASTER_API + "category/getCategories/",
  EMP_LEVEL:
    serverVars.MASTER_API + "workerLevel/getWorkersHierarchySelectionList/",
  REPORTING_MANAGER_LIST:
    serverVars.MASTER_API + "easybusiness/user/getAllUserByCompanyIdAndCipher/",
  CONTRACTOR: serverVars.MASTER_API + "contractor/getContractorSelectionList/",
  WORK_LOCATION: serverVars.MASTER_API + "CustomerBranch/AllBranch/",
  PAY_COMPONENTS: serverVars.MASTER_API + "PayComponents/getAllPayComponents",
  DESIGNATION:
    serverVars.MASTER_API + "mdesignation/getDesignationSelectionList/",
  EMPLOYEE_PAY_SCHEME: serverVars.MASTER_API + "PayScheme/getAllPaySchemes",
  PAY_SCHEME_BY_ID:
    serverVars.MASTER_API + "PayScheme/getPaySchemeByPaySchemeId/",
  ALL_PAY_WAGESMODES:
    serverVars.MASTER_API + "PayWagesMode/getAllPayWagesModes",
  CHECK_PAY_SCHEME: serverVars.MASTER_API + "PayProcess/checkPayscheme/",
  DOWNLOAD_FILE: serverConfig.SERVER_URL + "security-api/api/files/downloadfile/",
  UPDATE_FILE: serverVars.MASTER_API + "files/updateFile",
  EDUCATIONAL_DETAILS:
    serverVars.MASTER_API + "workersubDetails/addWorkerEducationDetailsList",
  DOWNLOAD_ASSET_FORM: serverVars.MASTER_API + "workerMaster/downloadAssetForm",
  DOWNLOAD_WELCOME_LETTER:
    serverVars.MASTER_API + "workerMaster/downloadWelcomeLetter/ebId/",
  DOWNLOAD_OFFER_LETTER:
    serverVars.MASTER_API + "workerMaster/downloadOfferLetter/",
  ATTENDANCE_BULK_UPLOAD:
    serverVars.MASTER_API + "alm_service/attendancelog/uploadAttendance/",
  EMPLOYEE_BULK_UPLOAD: serverVars.MASTER_API + "workerMaster/uploadEmployees/",
  OFFER_LETTER_EMAIL_SERVICE:
    serverVars.MASTER_API + "workerMaster/sendOfferLetterEmail",
  WELCOME_LETTER_EMAIL_SERVICE:
    serverVars.MASTER_API + "workerMaster/sendWelcomeLetterEmail/",
  EMAIL_TEMPLATE_DISPLAY:
    serverVars.MASTER_API + "workerMaster/offerLetterMailBody/",
  PROFILE_PIC_DISPLAY: " https://qaapi.vowerp.com/",
  COST_CENTER: serverVars.MASTER_API + "costmaster/getCostMasterList/",
  // Accounting
  BILLS: serverVars.MASTER_API + "accounting/bills/getAccountingBillsList",
  BILLS_EXPORT: serverVars.MASTER_API + "accounting/bills/downloadAccountingBillsList",

  VENDOR_LIST:
    serverVars.MASTER_API + "poapi/api/v1/getSupplierForJuteByType/V/",
  PAYMENT: serverVars.PO_API + "api/v1/getTransactionsList",
  // LeavePolicy
  LEAVE_POLICY_LIST: serverVars.MASTER_API + "leavepolicy/getLeavePolicyList/",
  LEAVE_LIST: serverVars.MASTER_API + "leavepolicy/getLeavePolicyById/",
  LEAVE_POLICY_MAPPING:
    serverVars.MASTER_API + "leavePolicymap/postEmpLeavePolicyMapping",
  UPDATE_LEAVE_POLICY:
    serverVars.MASTER_API + "leavePolicymap/updateEmpLeavePolicyMapping",
  LEAVE_LEDGER_LIST: serverVars.MASTER_API + "LeaveLedger/getLeaveLedgerList/",
  LEAVE_TYPE_SELECTION_LIST:
    serverVars.MASTER_API + "LeaveTypes/getLeaveTypesSelectionList/",
  ADD_LEAVE_TRANSACTION:
    serverVars.MASTER_API + "leavetransaction/addLeaveTransaction",
  UPDATE_LEAVE:
    serverVars.MASTER_API + "leavetransaction/updateLeaveTransactions",
  LOGS:
    serverVars.MASTER_API +
    "alm_service/attendancelog/fetchDateConditionTransaction",
  UPDATE_ATTENDANCE:
    serverVars.MASTER_API + "dailyAttendance/updateWorkerDailyAttendance",
  ATTENDANCE_SUMMARY:
    serverVars.MASTER_API + "dailyAttendance/getDailyAttendanceSummaryList",
  ADD_BILLS: serverVars.PO_API + "api/v1/addBillPass",
  BILLS_NO_LIST: serverVars.PO_API + "api/v1/getTransactionsList",
  COST_FACTOR_LIST: serverVars.MASTER_API + "costmaster/getCostMasterList/",
  CREATE_PAYMENT: serverVars.PO_API + "api/v1/addTransactions",
  UPDATE_PAYMENT: serverVars.PO_API + "api/v1/updateTransactions",
  GET_ITEM_CODE:
    serverVars.MASTER_API + "item/getItemsByItemGroupIdForProcurement",
  GET_ITEM_CODE_BY_ITEM_GROUP: serverVars.MASTER_API + "item/getMaxOfItemCode",
  INDENT_TYPE_LIST_MASTERS:
    serverVars.MASTER_API + "scmIndentTypeMaster/api/v1/S/getIndentTypeList",
  ADD_SALARY:
    serverVars.MASTER_API + "PayEmployeeStructure/addPayEmployeeStructure",
  UPDATE_SALARY:
    serverVars.MASTER_API + "PayEmployeeStructure/updatePayEmployeeStructure",
  ADD_ITEM_GROUP: serverVars.MASTER_API + "itemgroup/addItemGroup",
  UPDATE_ITEM_GROUP: serverVars.MASTER_API + "itemgroup/updateItemGroup",
  ITEM_GROUP_VIEW_BY_ID: serverVars.MASTER_API + "itemgroup/getItemGroupById",
  CUSTOMER_LIST:
    serverVars.MASTER_API + "customerMaster/getCustomerSelectionList/",
  ADD_PAYSCHEME: serverVars.MASTER_API + "PayScheme/addPayScheme",
  UPDATE_PAY_SCHEME: serverVars.MASTER_API + "PayScheme/updatePayScheme",
  // LeavePolicy
  ITEM_GROUP_LIST: serverVars.MASTER_API + "poapi/api/v1/",
  CREATE_MATERIAL_REQUEST: serverVars.MASTER_API + "poapi/api/v1/addStoreIssue",
PRINT_STORE_ISSUE: serverVars.MASTER_API + "poapi/api/v1/downloadStoreIssue",

  UPDATE_MATERIAL_REQUEST:
    serverVars.MASTER_API + "poapi/api/v1/updateStoreIssue",
  PROJECT_LIST:
    serverVars.MASTER_API + "project/getProjectMasterSelectionListCompany/",
  SR_STOCK_LIST:
    serverVars.MASTER_API + "poapi/api/v1/getBalStockByDeptAndItemId",
    BRANCH_WISE_STOCK_LIST:
    serverVars.MASTER_API + "poapi/api/v1/getBranchWiseStock",
  SR_LIST: serverVars.MASTER_API + "poapi/api/v1/getSrStockByDeptAndItemId",
  MACHINE_NO_LIST:
    serverVars.MASTER_API + "machinemaster/getMachineMasterList/",
  COMPANY_LIST: serverVars.MASTER_API + "company/getAllCompanyList",
  DELIVERY_ORDER_GET_API:
    serverVars.PO_API + "api/v2/deliveryOrder/getRegularDeliveryOrderListPagination",
  GET_ITEM_BY_ITEM_GROUP: serverVars.MASTER_API + "item/getItemsByItemGroupId",
  // GET_ALL_QUALITY_MASTER_LIST:
  //   serverVars.MASTER_API + "qualityPriceMaster/getQualityMasterPagination",
  //Payroll Data api
  WARE_HOUSE_LIST:
    serverVars.MASTER_API + "godownmaster/getSelectonListWarehouses",
  FETCH_ATTENDENCE:
    serverVars.MASTER_API +
    "PayProcess/processDatacollectionFromDailyAttendance/",
  // CREATE_PAYROLL_DATA:
  //   serverVars.PAY_ROLL_DATA + "PayProcess/processDatacollection/",
  CREATE_PAYROLL_DATA:
    serverVars.MASTER_API + "PayProcess/processDatacollection/",
  FETCH_PAY_GENERIC:
    serverVars.MASTER_API + "PayProcess/processDataFromPayGeneric/",
  FETCH_CUMULATIVE:
    serverVars.MASTER_API + "/PayProcess/processEmployeesCummulativeAmount/",
  CREATE_STOCK_TRANSFER: serverVars.PO_API + "api/v1/addStoreStockTransfer",
  UPLOAD_PAYROLL_DATA:
    serverVars.MASTER_API + "/PayProcess/processDatacollectionSheet/",
  APPROVAL_BUTTON:
    serverVars.MASTER_API +
    "easybusiness/approverflow/findUserApproverForTaskId/",
  UPLOAD_OR_PAYPROCESS:
    serverVars.MASTER_API + "PayProcess/uploadOrPayProcessPay/",
  VIEW_BY_ID_LEAVE_REQUEST:
    serverVars.MASTER_API + "leavetransaction/getLeaveTransactionById/",
  WORKER_DETAILS_STATUS:
    serverVars.MASTER_API + "workerMaster/updateProgressBarDetails",
  GET_WORKER_DETAILS_STATUS:
    serverVars.MASTER_API + "workerMaster/getProgressBarDetails/",
  DELETE_EDUCATION:
    serverVars.MASTER_API + "workersubDetails/deleteWorkerEducationDetails/",
  DELETE_FILES: serverVars.MASTER_API + "files/deleteFile/",
  DOWNLOAD_PAY_REGISTER_EXCEL:
    serverVars.MASTER_API +
    "PayEmployeePayroll/downloadMonthlySalaryPaymentExcel",
  DOWNLOAD_PAY_REGISTER_PDF:
    serverVars.MASTER_API +
    "PayEmployeePayroll/downloadMonthlySalaryPaymentPdf",
  UPDATE_UOM_MASTER: serverVars.MASTER_API + "uommaster/updateUomMaster",
  /**********************************Jute Module********************************/

  JUTE_INDENT_LIST: serverVars.JUTEPURCHASE + "api/v1/getAllIndent",
  JUTE_PO_LIST: serverVars.JUTEPURCHASE + "api/v1/getAllPOList",
  GET_LATEST_MARKET_RATE: serverVars.PO_API + "api/v1/getLatestMarketJuteRate",
  
  ADD_JUTE_PO: serverVars.JUTEPURCHASE + "api/v1/addPO",
  UPDATE_JUTE_PO: serverVars.JUTEPURCHASE + "api/v1/updatePO",
  GET_PO_DETAILS: serverVars.JUTEPURCHASE + "api/v1/getPODetails",
  SELECT_ALL_JUTE_TYPE: serverVars.PO_API + "api/v1/",
  QUALITY_BY_JUTE_TYPE: serverVars.PO_API + "api/v1/",
  DOWNLOAD_JUTE_PO: serverVars.JUTEPURCHASE + "api/v1/downloadJutePurchaseOrderPdf",
  GET_APPROVED_JUTE_PO:serverVars.JUTEPURCHASE +"api/v1/getApprovedPOList/J/",
  DOWNLOAD_JUTE_MR:
    serverVars.JUTEPURCHASE + "api/v1/downloadJuteMaterialReceiptPdf/",
  UPDATE_JUTE_MR: serverVars.JUTEPURCHASE + "api/v1/updateMaterialGoodReceiveDetails",
  JUTE_GATE_ENTRY_LIST:
  serverVars.JUTEPURCHASE+ "getAllJuteEntryHdr",
  ADD_JUTE_GATE_ENTRY:
    serverVars.JUTEPURCHASE + "saveJuteEntryHdr",
  UPDATE_JUTE_GATE_ENTRY:
    serverVars.JUTEPURCHASE + "updateJuteEntryHdr",
  GET_JUTE_GATE_ENTRY_VIEW_BY_ID:
    serverVars.MASTER_API + "jutepurchase/getAllJuteEntryHdrById/",
  FILE_UPLOAD: serverVars.MASTER_API + "files/Uploadfile",
  DELETE_FILE_BI_ID: serverVars.MASTER_API + "files/deleteFile/",
  CREATE_JUTE_INDENT: serverVars.JUTEPURCHASE+"api/v1/addIndent",
  UPDATE_JUTE_INDENT:serverVars.JUTEPURCHASE+"api/v1/updateIndent",
  JUTE_INDENT_VIEWBY_ID: serverVars.JUTEPURCHASE+"api/v1/getIndentDetailsByIndentId/userid/",
  /*******************************Jute Quality Check***************************/
  JUTE_QUALITY_CHECK_LIST:
    serverVars.JUTEPURCHASE + "getQualityCheckEntries",
  ADD_OR_UPDATE_JUTE_QUALITY_CHECK:
    serverVars.JUTEPURCHASE + "addUpdateJuteQualityReading",
  COMMIT_QUALITY_CHECK_READINGS:
    serverVars.JUTEPURCHASE + "CommitMaterialReading/",
  /*******************************Jute Material Request***************************/
  JUTE_MATERIAL_RECEIPT_LIST:
    serverVars.JUTEPURCHASE + "api/v1/getAllMaterialGoodReceiveDetails",
  /*******************************Jute Material Request***************************/
  JUTE_BILL_PASS_DATA_LIST: serverVars.JUTEPURCHASE + "api/v1/getAllBillPass",
  JUTE_BILL_PASS_VIEWBY_ID:serverVars.JUTEPURCHASE + "api/v1/getBillPassDetails/J/",
  JUTE_BILL_PASS_UPDATE:serverVars.JUTEPURCHASE+"api/v1/updateBillPass",
  DELETE_UOM_DATA: serverVars.MASTER_API + "uommaster/deleteUomMaster/",
  VIEW_BY_ID_STOCK_TRANSFER:
    serverVars.PO_API + "getStoreMaterialGoodReceiveDetailsById/",

  JUTE_MARKET_RATE_API:
    serverVars.MASTER_API + "qualityPriceMaster/inputRateQualityList",
  JUTE_CREATE_MARKET_RATE: serverVars.JUTEPURCHASE+"api/v1/addDailyJuteRate",  
  
  DOWNLOAD_PAYMENT: serverVars.PO_API + "downloadPayment/",
  DOWNLOAD_RECEIPT: serverVars.PO_API + "downloadReceipt/",
  JUTE_ISSUE_LIST: serverVars.JUTEPURCHASE + "api/v1/getJuteIssueList",

  GET_BATCH_PLAN_LIST: serverVars.PO_API + "getBatchPlanList",

  ASSIGN_JUTE_PLAN:serverVars.PO_API + "addBatchPlanDailyImpl",

  GET_SUPPLIER_FOR_JUTE:serverVars.PO_API + "api/v1/getSupplierForJuteByType/JO/",

  CREATE_STORE_ISSUE_LINEWISE:
    serverVars.PO_API + "api/v1/addupdateStoreIssueItemWise",
  TERMS_AND_CONDITIONS_DATA:
    serverVars.MASTER_API +
    "budget-heads/getTermsAndConditionsListByOrgIdandType/",
  GET_STOCK_DETAILS_BY_GODOWN_NUMBER:
    serverVars.PO_API + "api/v1/getStockDetailsByGodownNo",
  JUTE_CONVERSION_LIST: serverVars.PO_API + "api/v1/getJuteConersionList",

  /***********************Jute Production****************************************************/
  GET_FINISHING_ENTRIES: serverVars.PO_API + "api/v1/getFinishingEntries",
  DELETE_FINISHING_ENTRY: serverVars.PO_API + "api/v1/deleteFinishingEntry/",
  GET_PROCESS_MASTER_LIST:
    serverVars.MASTER_API + "processMaster/getProcessMasterList/",
  GET_ALL_MACHINE_DETAILS_DROPDOWN:
    serverVars.MASTER_API + "machinemaster/getAllMachineDetails",
  ADD_FINISHING_ENTRY: serverVars.PO_API + "api/v1/addFinishingEntries",

  /*Weaving Production*/
  GET_WEAVING_PRODUCTION: serverVars.PO_API + "api/v1/getWeavingProduction",
  GET_CUTS_FROM_CUTS: serverVars.PO_API + "api/v1/getCutsFromCutsBuff",
  ADD_WEAVING_PRODUCTION: serverVars.PO_API + "api/v1/addWeavingProduction",
  DELETE_WEAVING_PRODUCTION:
    serverVars.PO_API + "api/v1/deleteWeavingProduction/",
  UPDATE_EB_NO_WORKING_HOURS:
    serverVars.PO_API + "api/v1/updateEbNoInCutsJugarBuff1",
  UPDATE_PRODUCTION_EFFECIENCY:
    serverVars.PO_API + "api/v1/updateProductionInCutsJugarBuff1",
  /*Beaming Production*/
  GET_BEAMING_PRODUCTION: serverVars.PO_API + "api/v1/getBeamingProduction",
  GET_BEAMING_DD_LIST: serverVars.MASTER_API + "machinemaster/getFrameNoList/",
  ADD_BEAMING_PRODUCTION: serverVars.PO_API + "api/v1/addBeamingProduction",
  DEL_ROW_BEAMING_PRODUCTION:
    serverVars.PO_API + "api/v1/deleteBeamingProduction/",
  /*Wastage Entry */
  GET_WASTAGE_ENTRY: serverVars.PO_API + "api/v1/getWastageEntry",
  GET_TROLLY_DD_LIST_WASTAGE:
    serverVars.MASTER_API + "trollyMaster/getTrollys/",
  WASTAGE_TYPE_LIST: serverVars.PO_API + "api/v1/getWastageTypeList/",
  WASTAGE_STOCK_LIST: serverVars.PO_API + "api/v1/getWastageStock",
  CREATE_WASTAGE_ENTRY: serverVars.PO_API + "api/v1/addWastageEntry",
  DELETE_WASTAGE_ENTRY: serverVars.PO_API + "api/v1/deleteWastageEntry",
  /*Winding Doff Entry*/
  GET_DOFF_ENTRY_LIST: serverVars.PO_API + "winding/getDoffEntryList",
  GET_DOFF_DELETE_ROW: serverVars.PO_API + "winding/deleteDoffEntry/",
  GET_TROLLY_DD_LIST: serverVars.MASTER_API + "trollyMaster/getTrollys/5/",
  /*Spinning Doff Entry*/
  GET_SPINNING_DOFF_ENTRY_LIST: serverVars.PO_API + "spinning/getDoffEntryList",
  /*Cuts and Jugar*/
  GET_CUTS_AND_JUGAR_ENTRY_LIST:
    serverVars.PO_API + "api/v1/getCutsJugarProductionList",
  // DEL_CUTS_AND_JUGAR_ENTRY_LIST:
  //   serverVars.PO_API + "api/v1/deleteWeavingQualityMapping/",
  DEL_CUTS_AND_JUGAR_ENTRY_LIST: serverVars.PO_API + "api/v1/deleteCutsJugar/",
  GET_LOOM_NO: serverVars.PO_API + "api/v1/getEmpNoBySpell",
  GET_CUTS_BY_LOOM: serverVars.PO_API + "api/v1/getsCutsJugarByLoomAndSpell",
  GET_DAILY_QUALITY_MAPPING:
    serverVars.PO_API + "api/v1/getDailyWeavingQualityMapping",
  CUTS_JUGAR_DATA_LIST: serverVars.PO_API + "api/v1/getWeavingQualityMapping/",
  ADD_ACTUAL_SHOTS: serverVars.PO_API + "api/v1/addWeavingQualityMapping",
  DELETE_ACTUAL_SHOTS:
    serverVars.PO_API + "api/v1/deleteWeavingQualityMapping/",
  CREATE_CUTS_AND_JUGAR: serverVars.PO_API + "api/v1/addCutsJugar",
  UPDATE_WEAVING_MACHINE_MAPPING:
    serverVars.PO_API + "api/v1/UpadteDailyWeavingQualityMapping",

  /***********************EMPLOYEE DATA BASE NEW API's ************************************ */
  ADD_EMPLOYEE_PERSONAL_DIT:
    serverVars.SECURITY_API + "employeeDatabase/addOrModifyPersonalInformation",
  VIEW_EMPLOYEE_PERSONAL_DIT:
    serverVars.SECURITY_API + "employeeDatabase/viewPersonalInformationByEbId",
  VIEW_EMPLOYEE_CONTACT_DIT:
    serverVars.SECURITY_API + "employeeDatabase/viewContactDetailsByEbId",
  VIEW_EMPLOYEE_ADDRESS_DIT:
    serverVars.SECURITY_API + "employeeDatabase/viewAddressDetailsByEbId",
  VIEW_EMPLOYEE_EDUCATION_DIT:
    serverVars.SECURITY_API + "employeeDatabase/viewEducationDetailsByEbId",
  UPDATE_EMPLOYEE_CONTACT_DIT:
    serverVars.SECURITY_API + "employeeDatabase/updateContactDetails",
  UPDATE_EMPLOYEE_ADDRESS_DIT:
    serverVars.SECURITY_API + "employeeDatabase/updateAddressDetails",
  VIEW_EMPLOYEE_WORKER_DIT:
    serverVars.SECURITY_API + "employeeDatabase/getWorkDetailsByebId",
  VIEW_EMPLOYEE_BANK_DIT:
    serverVars.SECURITY_API + "employeeDatabase/getBankDetailsByebId",
  VIEW_EMPLOYEE_PF_DIT:
    serverVars.SECURITY_API + "employeeDatabase/viewPFDetailsByebId",
  VIEW_EMPLOYEE_ESI_DIT:
    serverVars.SECURITY_API + "employeeDatabase/viewESIDetailsByebId",

  VIEW_EMPLOYEE_SALARY_DIT:
    serverVars.SECURITY_API +
    "employeeDatabase/getSalartStructureListViewByEbId",

  UPDATE_WORKER_DETAILS:
    serverVars.SECURITY_API + "employeeDatabase/updateWorkDetailsById",
  UPDATE_BANK_DETAILS:
    serverVars.SECURITY_API + "employeeDatabase/updateBankDetailsByebId",

  GET_PROGRESS_BAR_EMP_DATA:
    serverVars.SECURITY_API + "employeeDatabase/employeeStateAndProgressBar",
  GET_COUNTRYS_NAME_LIST: serverVars.SECURITY_API + "country/getAllCountry",
  GET_STATES_NEW_NAME_LIST:
    serverVars.SECURITY_API + "state/viewStatesByCountryId/",
  VIEW_BY_ID_EMPLOYEE_SHIFT:
    serverVars.SECURITY_API + "employeeDatabase/getshiftPolicyViewById",
  VIEW_BY_ID_EMPLOYEE_LEAVE:
    serverVars.SECURITY_API + "employeeDatabase/getLeavePolicyViewById",
  UPDATE_EMPLOYEE_SHIFT:
    serverVars.SECURITY_API + "employeeShiftMapping/updateEmployeeShiftMapping",

  DELETE_EDUCATION_DETAILS:
    serverVars.SECURITY_API +
    "employeeDatabase/deleteEducationDetailsByEducationId/",

  VIEW_ESI_DETAILS_BY_ID:
    serverVars.SECURITY_API + "employeeDatabase/viewESIDetailsByebId",
  VIEW_PF_DETAILS_BY_ID:
    serverVars.SECURITY_API + "employeeDatabase/viewPFDetailsByebId",

  UPDATE_PF_DETAILS:
    serverVars.SECURITY_API + "employeeDatabase/updatePFDetails",
  UPDATE_ESI_DETAILS:
    serverVars.SECURITY_API + "employeeDatabase/updateESIDetails",
  DELETE_PREVIOUS_EXP_DETAILS:
    serverVars.SECURITY_API +
    "employeeDatabase/deletePreviousExperienceByAutoId/",
  SHIFT_ADD_UPDATE:
    serverVars.SECURITY_API +
    "employeeDatabase/addOrUpdateEmployeeShiftMapping",

  GOOGLE_LINKS_VIEW:
    "https://data." +
    getTenantIdNew(serverVars.GOOGLE_LINK) +
    "/welcome/getGoogleLinkData",

  AADHAR_NUMBER_VALIDATION:
    serverVars.MASTER_API + "employeeDatabase/checkAadharApi",

  // Forgot and Reset Password
  FORGOT_PASSWORD_API:  serverVars.USERMANAGEMENT_API + "easybusiness/user/forgotPassword",
  RESET_USER_PASSWORD:
    serverVars.USERMANAGEMENT_API + "easybusiness/user/modifyPassword",
};

export function getTenantIdNew(hostName) {
  var hostId = "";
  MULTI_TENANT_DATA.forEach((prop) => {
    if (hostName === prop.host) {
      hostId = prop.tenantId;
    }
    if (prop.host === "localhost") {
      hostId = "dev.vowerp.com";
    }
  });
  if (hostId !== "") return hostId;
  else {
    return "";
  }
}
export function getHostIp(hostName) {
  var hostIp = "";
  MULTI_TENANT_DATA.forEach((prop) => {
    if (hostName === prop.host) {
      hostIp = prop.serverIp;
    }
  });
  if (hostIp !== "") return hostIp;
  else {
    swal("Host not found, Please contact your administrator..!!");
    return null;
  }
}

export function getTenantId(hostName) {
  var hostId = "";
  MULTI_TENANT_DATA.forEach((prop) => {
    if (hostName === prop.host) {
      hostId = prop.tenantId;
    }
  });
  if (hostId !== "") return hostId;
  else {
    return "";
  }
}

export const taskCodes = {
  LOGIN: 247,
  LOGOUT: 248,
};

export function getWorkerStatusName(status) {
  if (status == 1) {
    return "OPEN";
  }
  if (status == 4) {
    return "REJECTED";
  }
  if (status == 3) {
    return "APPROVED";
  }
  if (status == 5) {
    return "CLOSED";
  }
  if (status == 21) {
    return "DRAFTED";
  }
  if (status == 6) {
    return "CANCELLED";
  }
  if (status == 23) {
    return "UPLOADED";
  }
  if (status == 31) {
    return "PARTIALLY_CANCELLED";
  }
  if (status == 36) {
    return "DISPATCHED";
  }
}

export function getWorkerStatusId(status) {
  if (status == "OPEN") {
    return 1;
  }
  if (status == "APPROVED") {
    return 3;
  }
  if (status == "REJECTED") {
    return 4;
  }
  if (status == "CLOSED") {
    return 5;
  }
  if (status == "DRAFTED") {
    return 21;
  }
  if (status == "CANCELLED") {
    return 6;
  }
  if (status == "UPLOADED") {
    return 23;
  }
  if (status == "PARTIALLY_CANCELLED") {
    return 31;
  }
  if (status == "DISPATCHED") {
    return 36;
  }
}

export const ApprovalTasks = [
  { taskName: "Indent", id: 1 },
  { taskName: "PO", id: 2 },
  { taskName: "SR", id: 3 },
  { taskName: "MR", id: 4 },
  { taskName: "Jute Indent", id: 5 },
  { taskName: "Jute PO", id: 6 },
  { taskName: "Bill Pass", id: 7 },
  { taskName: "Jute Issue", id: 8 },
  { taskName: "Store Issue", id: 9 },
  { taskName: "Store Bill Pass", id: 10 },
  { taskName: "General Bills", id: 11 },
  { taskName: "Transactions", id: 12 },
  { taskName: "Quotations", id: 13 },
  { taskName: "Invoices", id: 14 },
  { taskName: "Employee Database", id: 15 },
  { taskName: "Employee Attendance", id: 16 },
  { taskName: "Client Visit Plan", id: 17 },
  { taskName: "Employee Leave Request", id: 18 },
  { taskName: "Expense Booking", id: 19 },
  { taskName: "Salary Adjustment", id: 20 },
  { taskName: "Advance Request", id: 21 },
  { taskName: "Enquiries", id: 22 },
  { taskName: "Customer Package Mapping", id: 23 },
  { taskName: "Price Master", id: 24 },
  { taskName: "Store Stock Transfer", id: 25 },
  { taskName: "Store Stock Return", id: 26 },
  { taskName: "Pay Register", id: 27 },
  { taskName: "Sales Order", id: 28 },
  { taskName: "Jobs", id: 29 },
  { taskName: "Customer Employee Billing Pay Register", id: 30 },
  { taskName: "Jute Conversion", id: 31 },
  { taskName: "Supplier", id: 32 },
  { taskName: "Work Order", id: 33 },
  { taskName: "Material Inspection", id: 34 },
  { taskName: "Work Inspection", id: 35 },
  { taskName: "BOM", id: 36 },
  { taskName: "BOQ", id: 37 },
  { taskName: "Work Report", id: 38 },
  { taskName: "Delivery Order", id: 39 },
  // { taskName: "Costomer_master", id: 40 },
  { taskName: "Debit Note", id: 41 },
  { taskName: "Credit Note", id: 42 },

];

export default cons;