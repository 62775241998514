import React from "react";
import EditableCell from "../EditableCell/EditableCell";
import { Grid } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";

export default class ProductRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Uom: [
        { label: "PCS", value: "PCS", name: "PCS" },
        { label: "BLS", value: "BLS", name: "BLS" },
        { label: "TNS", value: "TNS", name: "TNS" },
        { label: "KGS", value: "KGS", name: "KGS" },
      ],
      itemGroup:'',
      selectedUom: "",
      delete: false,
      showToolTip: true,
      rateValue:null,
    };
  }
  onDelEvent() {
    this.setState({
      delete: true,
    });
  }
  onDel(e) {
    this.setState({
      delete: false,
    });
    if (e.target.value === "Yes") {
      this.props.onDelEvent(this.props.product);
    }
  }
  

  handleChange = (selectedValue, selectedName, name) => {
    this.props.onSelectDep(selectedValue, selectedName, name);
  };
  handleChangedropdown =(selectedValue, selectedName, name) => {
    this.props.handleSelectDropDown(selectedValue, selectedName, name);
  };
  tooltip = () => {
    this.setState({
      showToolTip: !this.showToolTip,
    });
  };
  render() {
    let ItemGrpMasterList = this.props.ItemGrpMasterList;
    let itemsList = this.props.itemsList;
    let taxList = this.props.taxList.data

    return (
      <tr>
        {this.props.rowData.map((row, index) =>
          row.component === "EditableCell" ? (
            <>
              <EditableCell
                onhandlechangeValue={this.props.onhandlechangeValue}
                onKeyDown={this.props.onKeyDown}
                cellData={{
                  type: row.type,
                  value: this.props.product[row.type],
                  name: this.props.product[row.type],
                  id: this.props.product.id,
                }}
              />
            </>
          ) : row.dropDown === "dropDown" ? (
            <td>
              <div className="quantityDropdownBlock">
                <Grid className="selectAndTextfield">
                  <Grid
                    xs={12}
                    md={12}
                    className="dynamicSelectBlock"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DynamicSelect
                      arrayOfData={row.ItemGrp?ItemGrpMasterList:row.Items?itemsList:taxList?taxList:''}
                      className="dropdownBlockContainer"
                      onSelectChange={row.ItemGrp?this.handleChange:this.handleChangedropdown}
                      selected={this.state.itemGroup}
                      name={this.props.product[row.type]}
                      value={this.props.product[row.type]}
                    />
                  </Grid>
                </Grid>
              </div>
            </td>
          ) : (
            <td style={{width:"160px"}}>              
              <div className="quantityDropdownBlock">
                <Grid className="selectAndTextfield">
                  <Grid
                    xs={12}
                    md={12}
                    className="dynamicSelectBlock"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <EditableCell
                      onProductTableUpdate={this.props.onProductTableUpdate}
                      cellData={{
                        type: row.type,
                        value: this.state.rateValue?this.state.rateValue:this.props.product[row.type],
                        name: this.props.product[row.type],
                        id: this.props.product.id,
                      }}
                    />
                    <DynamicSelect
                      arrayOfData={this.state.Uom}
                      className="dropdownBlockContainer"
                      onSelectChange={this.handleChangedropdown}
                      selected={this.state.selectedUom}
                      name={this.props.product[row.type]}
                    />
                  </Grid>
                </Grid>
              </div>
            </td>
          )
        )}
        <td className="">
          <input
            type="button"
            onClick={this.onDelEvent.bind(this)}
            value="X"
            className="deleteDisplayButton"
          />
          {this.state.delete && (
            <CommonPopup>
              <div className="delAddItem">
                <div>Are you sure you want to delete?</div>
                <div className="delAddItemBtns">
                  <input
                    type="button"
                    onClick={this.onDel.bind(this)}
                    value="Yes"
                    className="delYesBtn"
                  />
                  <input
                    type="button"
                    onClick={this.onDel.bind(this)}
                    value="No"
                    className="delNoBtn"
                  />
                </div>
              </div>
            </CommonPopup>
          )}
        </td>
      </tr>
    );
  }
}

export const Popup = () => {
  return (
    <div>
      <CommonPopup>
        <div p={10}>jhelooooo</div>
      </CommonPopup>
    </div>
  );
};
