import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SearchBar from "../../../components/EditableTable/SearchBar/SearchBar";
import ProductTable from "../../../components/EditableTable/ProductTable/ProductTable";

class CostPlanAddItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowcount: 1,
      show: false,
      checkAll: false,
    };
    this.state.filterText = "";
    this.state.tooltip = "item description....";
    this.state.products = [
      {
        id: 0,
        item_code: "",
        department: "",
        item_group: "",
        quantity: 0,
        item: "",
        amount: 0,
        rate: 0,
      },
    ];
  }
  handleUserInput(filterText) {
    this.setState({ filterText: filterText });
  }
  handleCheckbox(isChecked, id, isAllCheck) {
    let list = this.state.products;
    if (isAllCheck) {
      list = list.map((product) => {
        return { ...product, checked: isChecked };
      });
      this.setState({ products: list, allChecked: isChecked });
    } else {
      list.forEach((product, i) => {
        if (product?.id === id) {
          list[i].checked = isChecked;
        }
      });
      let allChecked = list.every((item) => item.checked);
      this.setState({ products: list, allChecked: allChecked });
    }
  }

  handleRowDel(product) {
    var index = this.state.products.indexOf(product);

    this.state.products.splice(index, 1);
    this.setState(this.state.products);
  }
  handleKeydownEvent(event) {
    this.props.getIndentItems();
    const resultData = [];
    this.props.indentList.data.map((prop, i) => {
      if (event.target.value === prop.item_code) {
        resultData.push({
          id: prop.id,
          item_code: prop.item_code,
          department: prop.department,
          item_group: prop.item_group,
          item: prop.item,
          quantity: prop.quantity,
          rate: prop.rate,
          amount: prop.amount,
        });
      }
    });

    if (event.keyCode == 9) {
      if (resultData.length > 0) {
        this.state.products.map((prop) => {
          resultData.push({
            id: prop.id,
            item_code: prop.item_code,
            department: prop.department,
            item_group: prop.item_group,
            item: prop.item,
            quantity: prop.quantity,
            rate: prop.rate,
            amount: prop.amount,
          });
          // }
        });
        this.setState({
          products: resultData,
        });
      }
      let id = 0;
      this.state.products.map((prop) => (id = prop.id));
      if (event.target.id == id) this.handleAddEvent(event);
    }
  }

  handleAddEvent(evt) {
    var id = this.state.rowcount;

    var product = {
      id: id,
      item_code: "",
      department: "",
      quantity: 0,
      item: "",
      amount: 0,
      rate: 0,
      item_group: "",
    };
    var mcount = Number(this.state.rowcount) + 1;
    this.state.products.push(product);
    this.setState(this.state.products);
    this.setState({ rowcount: mcount });
  }

  handleProductTable(evt) {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    var products = this.state.products.slice();
    var newProducts = products.map(function (product) {
      for (var key in product) {
        if (key == item.name && product.id == item.id) {
          product[key] = item.value;
        }
      }
      return product;
    });
    this.setState({ products: newProducts });
  }
  handleChangeQuantity(selectedValue, selectedName, id) {
    this.setState({
      qty: selectedValue,
      uomCode: selectedName,
    });
    const resultData = [];
    if (this.state.products) {
      this.state.products.map((prop) => {
        if (prop.id == id) {
          var qty = prop.quantity;
          var vsqty = "";
          if (qty) {
            qty.map((val) => {
              if (selectedValue == val.value) {
                vsqty = val;
              }
            });
          }
          resultData.push({
            id: prop.id,
            item_code: prop.item_code,
            department: prop.department,
            item_group: prop.item_group,
            item: prop.item,
            quantity: prop.quantity,
            rate: prop.rate,
            amount: prop.amount,
            qty: vsqty.value,
            uomCode: vsqty.name,
          });
        } else {
          resultData.push({
            id: prop.id,
            item_code: prop.item_code,
            department: prop.department,
            item_group: prop.item_group,
            item: prop.item,
            quantity: prop.quantity,
            rate: prop.rate,
            amount: prop.amount,
            qty: prop.qty,
            uomCode: prop.uomCode,
          });
        }
      });

      this.setState({
        products: resultData,
      });
      this.props.onclickSave(resultData);
    }
  }

  render() {
    return (
      <div>
        <SearchBar
          filterText={this.state.filterText}
          onUserInput={this.handleUserInput.bind(this)}
        />
        <ProductTable
          onProductTableUpdate={this.handleProductTable.bind(this)}
          onRowAdd={this.handleAddEvent.bind(this)}
          onKeyDown={this.handleKeydownEvent.bind(this)}
          onChange={this.handleCheckbox.bind(this)}
          onRowDel={this.handleRowDel.bind(this)}
          toolTipMsg={this.state.tooltip}
          products={this.state.products}
          tableHeaders={tableHeaders}
          RowData={RowData}
          filterText={this.state.filterText}
          allChecked={this.state.allChecked}
          onSelectHandle={this.handleChangeQuantity.bind(this)}
        />
      </div>
    );
  }
}
const RowData = [
  {
    no: 1,
    component: "EditableCell",
    item_code: "NJM101",
    type: "item_code",
    value: "NJM101",
  },
  {
    no: 2,
    component: "EditableCell",
    type: "department",
    value: "Spinning",
  },
  {
    no: 3,
    component: "EditableCell",
    type: "department",
    value: "Spinning",
  },
  {
    no: 4,
    component: "EditableCell",
    type: "department",
    value: "Spinning",
  },
  {
    no: 5,
    component: "EditableCell",
    type: "item_group",
    value: "Jute",
  },
  {
    no: 6,
    component: "EditableCell",
    type: "juteInput",
    value: "juteInput",
  },
];

const tableHeaders = [
  {
    header: "Budget Head",
  },
  {
    header: "Budget Sub Head",
  },
  {
    header: "Item Group / Cost Center",
  },
  {
    header: "Cost per sft",
  },
  {
    header: "Total sft",
  },
  {
    header: "Total Amount",
  },
  {
    header: "Actions",
  },
];

const mapStatetoProps = (state) => {
  const { indentList } = state.Indent;
  return { indentList };
};

export default withRouter(connect(mapStatetoProps, {})(CostPlanAddItems));
