import { Component } from "react";
import { connect } from "react-redux";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import { withRouter } from "react-router-dom";
import { JuteMaterialReceipt } from "../../../store/Jute/JuteMaterialReceipt/actions";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";

class JuteMaterialReceiptIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      startIndex: "",
      lastIndex: "",
      JuteMaterialReceiptContent: [],
      loadingdata: false,
      mrPrintNo:"",
      gateEntryNum: "",
      supplierName:"",
      supplierCode: "",
      agentId:"",
    };
  }
  componentDidMount() {
    const data = {
      frmDt: "",
      toDt: "",
      vehicleNum: "",
      companyId: localStorage.getItem("companyId"),
      cipher: this.state.userDit.cipher,
      acYear: localStorage.getItem("acadamicYear"),
    };
    this.props.JuteMaterialReceipt(
      serverApi.JUTE_MATERIAL_RECEIPT_LIST,
      data,
      this.props.history
    );
    showSpinner();
  }
  componentWillReceiveProps(props) {
    if (props.JuteMaterialReceipt) {
      this.setState({
        JuteMaterialReceiptContent: props.JuteMaterialReceiptData.data,
        loadingdata: false,
      });
      hideSpinner();
    }
  }
  handleSearch = (searchData) => {
    console.log(searchData)
    const data = {
      fromDate: searchData.fromDate,
      toDate: searchData.toDate,
      supplierCode:searchData.supplierValue,
      vehicleNo: searchData.vehicleValue,
      gateEntryNo: searchData.gateEntryNum,
      companyId: localStorage.getItem("companyId"),
      cipher: this.state.userDit.cipher,
      acYear: localStorage.getItem("acadamicYear"),
      status: searchData.statusValue,
      agentId: searchData.agentValue,
      mrPrintNo: searchData.mrPrintNo,
      poNum:searchData.poNum,
    };
    this.props.JuteMaterialReceipt(
      serverApi.JUTE_MATERIAL_RECEIPT_LIST,
      data,
      this.props.history
    );
    showSpinner();
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.JuteMaterialReceiptContent}
          mcolumns={Columns}
          status={true}
          micon={GridArrowIcon}
          actions={true}
          filter_form={"JuteMaterialReceipt"}
          handleSearch={this.handleSearch}
          url="/update_jute_material_receipt"
        />
      </>
    );
  }
}
// machine master header
const Columns = [
  {
    Header: "MR Id",
    accessor: "mrNo",
    hidden: false,
  },
  {
    Header: "MR No",
    accessor: "mrPrintNo",
    hidden: false,
  },
  {
    Header: "Gate Ref No",
    accessor: "gateEntryNo",
    hidden: false,
  },
  {
    Header: "PO No.",
    accessor: "poNo",
    hidden: false,
  },
  {
    Header: "MR Date",
    accessor: "mrDate",
    hidden: false,
  },
  {
    Header: "Type",
    accessor: "unitConversion",
    hidden: false,
  },
  {
    Header: "Supplier",
    accessor: "supplierName",
    hidden: false,
  },
  {
    Header: "Vh. No",
    accessor: "vehicleNo",
    hidden: false,
  },
  {
    Header: "Mukham",
    accessor: "mukham",
    hidden: false,
  },
  {
    Header: "Weight",
    accessor: "weight",
    hidden: true,
  },
  {
    Header: "Agent Id",
    accessor: "agentId",
    hidden: true,
  },
  {
    Header: "Status",
    accessor: "mrStatus",
    hidden: false,
  },
];
const mapStatetoProps = (state) => {
  const { JuteMaterialReceiptData } = state.JuteMaterialReceiptReducer;
  return { JuteMaterialReceiptData };     
};

export default withRouter(
  connect(mapStatetoProps, {
    JuteMaterialReceipt,
  })(JuteMaterialReceiptIndex)
);
