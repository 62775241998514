import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/Jute/DynamicSelect";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import { getYarnTypeList } from "../../../store/Global/DropDownApis/actions";
import { getPlanNameList } from "../../../store/MasterDropdownApis/actions";
import { serverApi } from "../../../helpers/Consts";

class FillDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      yarnTypeListData: [],
      getPlanNameListContent: [],
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getYarnTypeList(
      serverApi.GET_YARN_TYPE_LIST + this.state.companyId,
      this.props.history
    );
    this.props.getPlanNameList(
      serverApi.GET_PLAN_NAME + this.state.companyId,
      this.props.history
    );
  }
  componentWillReceiveProps(props) {
    var arrayList = [];
    var obj = { value: 1, label: "NEW", name: "NEW" };

    arrayList.push(obj);

    if (props.yarnTypeList) {
      this.setState({
        yarnTypeListData: props.yarnTypeList.data, // updating department list
      });
    }
    if (props.getPlanNameListData) {
      {
        props.getPlanNameListData.data &&
          props.getPlanNameListData.data.map((prop) => {
            let list = {
              label: prop.label,
              name: prop.name,
              value: prop.value,
            };
            arrayList.push(list);
          });
      }
      this.setState({
        getPlanNameListContent: arrayList,
      });
    }
  }
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    this.props.headerData[key] = event;
  };
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    this.setState({ [name]: selectedValue });
    this.props.headerData[name] = selectedValue;
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div className="juteIndent">
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormContainer">
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.getPlanNameListContent}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.planName}
                          update={this.props.location.state ? 1 : 0}
                          name="branchId"
                          label="Plan"
                          required
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <TextFieldReq
                        label="Plan Name"
                        placeholder="Enter here"
                        value={this.state.planName}
                        onChange={this.onhandlechangeValue("transporter")}
                      />
                    </Box>
                    <Box gridColumn="span 3">
                      <TextFieldReq
                        label="Plan Code"
                        placeholder="Enter here"
                        value={this.state.planCode}
                        onChange={this.onhandlechangeValue("transporter")}
                      />
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.yarnTypeListData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.yarnId}
                          update={this.props.location.state ? 1 : 0}
                          name="yarnType"
                          label="Yarn Type"
                          required
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { yarnTypeList } = state.DropDownListReducer;
  const { getPlanNameListData } = state.MasterDropDownListReducer;
  return {
    yarnTypeList,
    getPlanNameListData,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getYarnTypeList,
    getPlanNameList,
  })(FillDetails)
);
