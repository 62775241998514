import React, { Component } from "react";

class TextFieldNormal extends Component {
  handleChange = (event) => {
    const { value } = event.target;

    if (this.props.caps === "ON") {
      this.props.onChange(value.toUpperCase());
    } else {
      this.props.onChange(value);
    }
  };
  handleBlur = (event) => {
    const { value } = event.target;
    if(this.props.handleBlur){
      this.props.handleBlur(value);
    }
  };
  render() {
    return (
      <div className="TextFieldBlock">
        {this.props.label && (
          <label htmlFor="app-input-field">
            {this.props.label}{" "}
            <label className="starcolor">{this.props.required && "*"}</label>
          </label>
        )}
        <input
          disabled={this.props.disabled}
          readOnly={this.props.readOnly}
          type={this.props.type}
          value={this.props.value}
          maxlength={this.props.maxlength}
          className="TextField"
          // placeholder={this.props.placeholder}
          placeholder="Enter Here"
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          InputProps={this.props.InputProps}
          pattern="[0-9]+"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
          onWheel={e => e.target.blur()}
          onKeyDown={this.props.type ==='number'? (e) =>["e", "E", "+", "-", ].includes(e.key) && e.preventDefault():()=>{}}
          style={{textAlign: this.props.rightAlign}}
        />
      </div>
    );
  }
}
export default TextFieldNormal;
