import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Box } from "@mui/material";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import cons, { serverApi } from "../../../helpers/Consts";
import moment from "moment";
import { getSpell } from "../../../store/HRMS/AttendanceCalender/actions";
import {
  setShiftMaster,
  getBioMetriDevice,
  createBioMetriDevice,
} from "../../../store/Master/ShiftMaster/actions";
import CustomButton from "../../../components/Buttons/Buttons";
class CreateShift extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      ebId: localStorage.getItem("ebId"),
      late_min1: "",
      late_min2: "",
      roaster_days: "",
      roaster_gap_days: "",
      roaster_start_date: "",
      spell: [],
      spell_1: "",
      spell_2: "",
      week_off_day: "",
      week_off_day2: "",
      week_off_half_day: "",
      bio_metric_device: "",
      rf_id_card: "",
      emp_biometri_id: "",
      bio_metric_device_list: [],
      create_bio_metric_device: [],
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getSpell(
      serverApi.SPELL + this.state.companyId + "/" + this.state.userDit.cipher,
      this.props.history
    );
    this.props.getBioMetriDevice(
      serverApi.BIO_METRIC_DEVICE,
      this.props.history
    );
  }
  componentWillReceiveProps(props) {
    if (props.spell) {
      this.setState({
        spell: props.spell,
      });
    }
    if (props.bio_metric_device.data) {
      this.setState({
        bio_metric_device_list: props.bio_metric_device.data,
      });
    }
    if (props.create_bio_metric_device) {
      this.setState({
        create_bio_metric_device: props.create_bio_metric_device,
      });
    }
  }
  handleSelect = (selectedValue, selectedName, name) => {
    if (name === "Spell1") {
      this.setState({
        spell_1: selectedName,
      });
    }
    if (name === "Spell2") {
      this.setState({
        spell_2: selectedName,
      });
    }
    if (name === "WeekOffDay") {
      this.setState({
        week_off_day: selectedValue,
      });
    }
    if (name === "WeekOffDay2") {
      this.setState({
        week_off_day2: selectedValue,
      });
    }
    if (name === "WeekOffHalfDay") {
      this.setState({
        week_off_half_day: selectedValue,
      });
    }
    if (name === "BioMetriceDevice") {
      this.setState({
        bio_metric_device: selectedValue,
      });
    }
  };
  handleSelectDate = (e, name) => {
    if (name === "Date") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();

      this.setState({
        roaster_start_date: Date,
      });
    }
  };
  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "LateMin1") {
      this.setState({
        late_min1: event,
      });
    }
    if (key === "LateMin2") {
      this.setState({
        late_min2: event,
      });
    }
    if (key === "RoasterDays") {
      this.setState({
        roaster_days: event,
      });
    }
    if (key === "RoasterGapDays") {
      this.setState({
        roaster_gap_days: event,
      });
    }
    if (key === "RfIdCard") {
      this.setState({
        rf_id_card: event,
      });
    }
    if (key === "EmpBioMetricId") {
      this.setState({
        emp_biometri_id: event,
      });
    }
  };

  onClickSave = () => {
    const data = {
      companyId: this.state.companyId,
      createdBy: this.state.userDit.userId,
      ebId: this.state.ebId,
      lateMins1: this.state.late_min1,
      lateMins2: this.state.late_min2,
      roasterDays: this.state.roaster_days,
      roasterGapDays: this.state.roaster_gap_days,
      roasterStartDate: this.state.roaster_start_date,
      spell1: this.state.spell_1,
      spell2: this.state.spell_2,
      userId: this.state.companyId,
      weekOffDay: this.state.week_off_day,
      weekOffDay2: this.state.week_off_day2,
      weekOffHalfDay: this.state.week_off_half_day,
    };
    this.props.setShiftMaster(
      serverApi.CREATE_SHIFT_MASTER,
      data,
      this.props.history
    );
  };
  onClickAdd = () => {
    const data = {
      bioDeviceId: this.state.bio_metric_device,
      companyId: this.state.companyId,
      createdBy: this.state.userDit.userId,
      ebId: this.state.ebId,
      empBioDeviceId: this.state.emp_biometri_id,
      empRFNo: this.state.rf_id_card,
      userId: this.state.userDit.userId,
    };
    this.props.createBioMetriDevice(
      serverApi.CREATE_BIO_METRIC_DEVICE,
      data,
      this.props.history
    );
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <>
        {/* BioMetric */}
        <div className="contactDetailsBlock">
          <div className="consoleFormContainer">
            <h1>BioMetric</h1>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={
                            this.state.bio_metric_device_list
                              ? this.state.bio_metric_device_list.map(
                                  (item) => ({
                                    label: item.label,
                                    value: item.value,
                                    name: item.name,
                                  })
                                )
                              : ""
                          }
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handleSelect}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.bio_metric_device}
                          name="BioMetriceDevice"
                          label="Bio Metric Device"
                          required
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          label="RF ID Card"
                          caps="OFF"
                          type="number"
                          value={this.state.rf_id_card}
                          onChange={this.onhandlechange("RfIdCard")}
                          disabled={
                            this.state.bio_metric_device === "" ? true : false
                          }
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          label="Employee Bio Metric Id"
                          caps="OFF"
                          type="number"
                          value={this.state.emp_biometri_id}
                          onChange={this.onhandlechange("EmpBioMetricId")}
                          disabled={
                            this.state.bio_metric_device === "" ? true : false
                          }
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="consoleFormButtonBlock">
            <CustomButton label={"Cancel"} className="greenBorderButton" />
            <CustomButton
              label={"Add"}
              className="greenButton"
              handleClick={this.onClickAdd}
              type="sumbit"
            />
          </div>
        </div>

        {/* shift master */}
        <div className="contactDetailsBlock">
          <div className="consoleFormContainer">
            <h1>Create Shift Master</h1>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          label="Late Mins1"
                          caps="OFF"
                          type="number"
                          value={this.state.late_min1}
                          onChange={this.onhandlechange("LateMin1")}
                          disabled={
                            this.state.bio_metric_device === "" ? true : false
                          }
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          label="Late Mins2"
                          caps="OFF"
                          type="number"
                          value={this.state.late_min2}
                          onChange={this.onhandlechange("LateMin2")}
                          disabled={
                            this.state.bio_metric_device === "" ? true : false
                          }
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextFieldReq
                          label="Roaster Days"
                          caps="OFF"
                          type="number"
                          value={this.state.roaster_days}
                          onChange={this.onhandlechange("RoasterDays")}
                          disabled={
                            this.state.bio_metric_device === "" ? true : false
                          }
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextFieldReq
                          label="Roaster Gap Days"
                          caps="OFF"
                          type="number"
                          value={this.state.roaster_gap_days}
                          onChange={this.onhandlechange("RoasterGapDays")}
                          disabled={
                            this.state.roaster_days === "" ? true : false
                          }
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <div className="TextFieldBlock">
                          <label>Roaster Start Date</label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            disabled={
                              this.state.roaster_days === "" ? true : false
                            }
                            inputFormat="dd-MM-yyyy"
                            name="Date"
                            value={this.state.roaster_start_date}
                            fullWidth
                            onChange={(e) => this.handleSelectDate(e, "Date")}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={
                            this.state.spell
                              ? this.state.spell.map((item) => ({
                                  label: item.label,
                                  value: item.value,
                                  name: item.name,
                                }))
                              : ""
                          }
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handleSelect}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.spell_1}
                          name="Spell1"
                          label="Spell 1"
                          required
                          isDisabled={
                            this.state.roaster_gap_days === "" ? true : false
                          }
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={
                            this.state.spell
                              ? this.state.spell.map((item) => ({
                                  label: item.label,
                                  value: item.value,
                                  name: item.name,
                                }))
                              : ""
                          }
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handleSelect}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.spell_2}
                          name="Spell2"
                          label="Spell 2"
                          isDisabled={
                            this.state.roaster_gap_days === "" ? true : false
                          }
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={Days}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handleSelect}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.week_off_day}
                          name="WeekOffDay"
                          label="Week Off Day"
                          isDisabled={
                            this.state.roaster_gap_days === "" ? true : false
                          }
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={Days}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handleSelect}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.week_off_day2}
                          name="WeekOffDay2"
                          label="Week Off Day2"
                          isDisabled={
                            this.state.roaster_gap_days === "" ? true : false
                          }
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={Days}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handleSelect}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.week_off_half_day}
                          name="WeekOffHalfDay"
                          label="Week Off Half Day"
                          isDisabled={
                            this.state.roaster_gap_days === "" ? true : false
                          }
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
            <div className="consoleFormButtonBlock">
              <CustomButton label={"Cancel"} className="greenBorderButton" />

              <CustomButton
                label={"Create"}
                className="greenButton"
                handleClick={this.onClickSave}
                type="sumbit"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
const Days = [
  {
    value: "",
    label: "Select",
    name: "Select",
  },
  {
    value: "1",
    label: "Sunday",
    name: "Sunday",
  },
  {
    value: "2",
    label: "Monday",
    name: "Monday",
  },
  {
    value: "3",
    label: "Tuesday",
    name: "Tuesday",
  },
  {
    value: "4",
    label: "Wednesday",
    name: "Wednesday",
  },
  {
    value: "5",
    label: "Thursday",
    name: "Thursday",
  },
  {
    value: "6",
    label: "Friday",
    name: "Friday",
  },
  {
    value: "7",
    label: "Saturday",
    name: "Saturday",
  },
];
const mapStatetoProps = (state) => {
  const { spell } = state.AttendanceReducer;
  const { shift_master, bio_metric_device, create_bio_metric_device } =
    state.ShiftMasterReducer;
  return { spell, shift_master, bio_metric_device, create_bio_metric_device };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSpell,
    setShiftMaster,
    getBioMetriDevice,
    createBioMetriDevice,
  })(CreateShift)
);
