import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Grid, Box } from '@mui/material'
// import LocalizationProvider from '@mui/lab/LocalizationProvider'
// import AdapterDateFns from '@mui/lab/AdapterDateFns'
// import DesktopDatePicker from '@mui/lab/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';   
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';   
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'; 
import DynamicSelect from '../../../components/Dropdown/DynamicSelect'
import TextField from '../../../components/TextField/TextField'
import TextFieldReq from '../../../components/TextField/TextFieldReq'
import CustomButton from '../../../components/Buttons/Buttons'
import Link from '@mui/material/Link'
import InputAdornment from '../../../components/InputAdorment/InputAdorment'
import TextFieldAlfaNum from '../../../components/TextField/TextFieldAlfaNum'
import TextFieldAlfaNumReq from '../../../components/TextField/TextFieldAlfaNumReq'
import DocumentUpload from '../../../components/DocumentUpload/DocumentUpload'

class FillDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
        Package_Name:'',
        Branch:'',
        Package_Price:'',
        Type:'',
        Created_By:'',
    }
  }

  handlePropSelectChange = (selectedValue, selectedName, name) => {
  
    
  }
  
  render() {
    return (
      <div className="packageMasterContainer">
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                     <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq label="Package Name" required />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={phase}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.state.Branch}
                        name="Branch"
                        label="Branch"
                        required 
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq label="Package Price" required />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextField label="Max Discount Value"  />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={phase}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.state.Type}
                        name="Type"
                        label="Type"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                    <div className="TextFieldBlock">
                          <label>Elapsed Time</label>
                        </div>
                      <InputAdornment label="Days" />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>Valid From</label>
                        </div>
                        {/* <ConsoleDatePicker /> */}
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="Valid_From"
                            inputFormat="dd.MM.yyyy"
                            id="Valid_From"
                            value={this.state.dtvalue}
                            fullWidth
                            onChange={(newValue) => {
                              // setValue(newValue);
                              this.setState({ dtvalue: newValue })
                            }}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>Valid Till</label>
                        </div>
                        {/* <ConsoleDatePicker /> */}
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="Valid_Till;"
                            inputFormat="dd.MM.yyyy"
                            id="Valid_Till"
                            value={this.state.dtvalue}
                            fullWidth
                            onChange={(newValue) => {
                              // setValue(newValue);
                              this.setState({ dtvalue: newValue })
                            }}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextField
                          label="Description"
                          type="textarea"
                          onChange={this.handlePropSelectChange}
                          rows={5}
                          // required
                        />
                      </div>
                    </Box>
                  <Box gridColumn="span 3">
                    <div className="d-flex cloumnButtonBlock">
                    <DocumentUpload label="Upload Image" />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}

const arrayOfIndent = [
  {
    value: '1',
    label: 'Capital',
    name: 'Capital',
  },
  {
    value: '2',
    label: 'General',
    name: 'General',
  },
  {
    value: '3',
    label: 'Maintainence',
    name: 'Maintainence',
  },
  {
    value: '4',
    label: 'Open',
    name: 'Open',
  },
  {
    value: '5',
    label: 'Production',
    name: 'Production',
  },
]
const Project = [
  {
    value: 1,
    label: 'Project1',
    name: 'Project 1',
  },
  {
    value: 2,
    label: 'Project2',
    name: 'Project 2',
  },
  {
    value: 3,
    label: 'Project3',
    name: 'Project 3',
  },
]

const phase = [
  {
    value: '1',
    label: 'Ho',
    name: 'Ho',
  },
  {
    value: '2',
    label: 'Factory',
    name: 'Factory',
  },
  {
    value: '3',
    label: 'BNG',
    name: 'BNG',
  },
  {
    value: '4',
    label: 'Kolkatta',
    name: 'Kolkatta',
  },
  {
    value: '5',
    label: 'Test',
    name: 'Test',
  },
]

const client = [
  {
    value: '1',
    title: 'Center',
    name: 'Center',
  },
  {
    value: '2',
    title: 'TechStar',
    name: 'TechStar',
  },
  {
    value: '3',
    title: 'Mahindra Logistics',
    name: 'Mahindra Logistics',
  },
  {
    value: '4',
    title: 'Smartworks',
    name: 'Smartworks',
  },
]

const CategoryList = [
  {
    value: '1',
    label: 'OPEN',
    name: 'OPEN',
  },
  {
    value: '2',
    label: 'OPEX',
    name: 'OPEX',
  },
  {
    value: '3',
    label: 'CAPEX',
    name: 'CAPEX',
  },
]

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {},
)(withRouter(FillDetails))
