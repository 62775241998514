import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {
  LEAVETYPELIST,
  CREATE_LEAVE_TYPE,
  UPDATE_LEAVE_TYPE,
  LEAVETYPESLIST,
} from "./actionTypes";
import {
  LeaveTypeListSuccessfull,
  LeaveTypesListSuccessfull,
  createLeaveTypeSuccessfull,
  updateLeaveTypeSuccessfull,
} from "./actions";
import { getList, getListurl, postAdd } from "../../../helpers/Server_Helper";
import swal from "sweetalert";

function* getLeaveTypeList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(LeaveTypeListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getLeaveTypesList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(LeaveTypesListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateLeaveType({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createLeaveTypeSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
    history.push("/leave_types");
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateLeaveType({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateLeaveTypeSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
    history.push("/leave_types");
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

export function* watchLeaveTypeList() {
  yield takeEvery(LEAVETYPELIST, getLeaveTypeList);
  yield takeEvery(LEAVETYPESLIST, getLeaveTypesList);
}
export function* watchCreateLeaveTypeMaster() {
  yield takeEvery(CREATE_LEAVE_TYPE, CreateLeaveType);
}
export function* watchUpdateLeaveTypeMaster() {
  yield takeEvery(UPDATE_LEAVE_TYPE, UpdateLeaveType);
}

function* LeaveTypeListSaga() {
  yield all([
    fork(watchLeaveTypeList),
    fork(watchCreateLeaveTypeMaster),
    fork(watchUpdateLeaveTypeMaster),
  ]);
}

export default LeaveTypeListSaga;
