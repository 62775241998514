export const DELIVERY_ORDER_LIST = "delivery_order_list";
export const DELIVERY_ORDER_LIST_SUCCESSFULL = "delivery_order_list_successfull";
export const CLEAR_DELIVERY_ORDER_LIST = "clear_delivery_order_list";
export const REGULAR_DELIVERY_ORDER_HEADER ='regular_delivery_order_header';
export const REGULAR_DELIVERY_ORDER_LINE_ITEMS ='regular_delivery_order_line_items';
export const GET_SO_DROPDOWN ='get_so_dropdown';
export const GET_SO_DROPDOWN_SUCCESSFULL='get_so_dropdown_successfull';
export const SALES_ORDER_VIEW_BY_ID ="sales_order_view_by_id";
export const SALES_ORDER_VIEW_BY_ID_SUCCESSFULL ="sales_order_view_by_id_successfull";

export const CREATE_DELIVERY_ORDER ="create_delivery_order";
export const CREATE_DELIVERY_ORDER_SUCCESSFULL ="create_delivery_order_successfull";

export const ACCOUNT_LIST = 'account_list';
export const ACCOUNT_LIST_SUCCESSFULL = 'account_list_successfull';

export const CLEAR_REGULAR_DELIVERY_ORDER_HEADER ='clear_regular_delivery_order_header';
export const CLEAR_REGULAR_DELIVERY_ORDER_LINE_ITEMS ='clear_regular_delivery_order_line_items';

export const GET_ALL_TRANSPORTER ="get_all_transporter";
export const GET_ALL_TRANSPORTER_SUCCESSFULL ="get_all_transporter_successfull";

export const REGULAR_DELIVERY_ORDER_VIEWBY_ID ="regular_delivery_order_viewby_id";
export const REGULAR_DELIVERY_ORDER_VIEWBY_ID_SUCCESSFULL ="regular_delivery_order_viewby_id_successfull";

export const UPDATE_REGULAR_DELIVERY_ORDER="update_regular_delivery_order";
export const UPDATE_REGULAR_DELIVERY_ORDER_SUCCESSFULL ="update_regular_delivery_order_successfull";
export const DELIVERY_ORDER_ITEMS_BY_ITEM_GROUP_ID="delivery_order_items_by_items_group_id";
export const DELIVERY_ORDER_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL="delivery_order_items_by_items_group_id_successfull";

export const JUTEGOVT_DELIVERY_ORDER_HEADER ="jute_govt_delivery_order_header";
export const JUTEGOVT_DELIVERY_ORDER_LINEITEMS="jute_govt_delivery_order_lineitems";

export const CLEAR_JUTEGOVT_DELIVERY_ORDER_HEADER="clear_jute_govt_delivery_order_header";
export const CLEAR_JUTEGOVT_DELIVERY_ORDER_LINEITEMS="clear_jute_govt_delivery_order_lineitems";

export const CREATE_JUTE_GOVT_DELIVERY_ORDER ="create_jute_govt_delivery_order";
export const CREATE_JUTE_GOVT_DELIVERY_ORDER_SUCCESSFULL ="create_jute_govt_delivery_order_successfull";

export const UPDATE_JUTE_GOVT_DELIVERY_ORDER="update_jute_govt_delivery_order";
export const UPDATE_JUTE_GOVT_DELIVERY_ORDER_SUCCESSFULL ="update_jute_govt_delivery_order_successfull";

export const JUTE_GOVT_DELIVERY_ORDER_VIEWBY_ID="jute_govt_delivery_order_viewby_id";
export const JUTE_GOVT_DELIVERY_ORDER_VIEWBY_ID_SUCCESSFULL="jute_govt_delivery_order_viewby_id_successfull";
//Jute Yarn Delivery order
export const  CREATE_JUTE_YARN_DELIVERY_ORDER = "create_jute_yarn_delivery_order";

export const CREATE_JUTE_YARN_DELIVERY_ORDER_SUCCESSFULL="create_jute_yarn_delivery_order_successfull";

export const JUTE_YARN_DELIVERY_ORDER_HEADER_SUCCESSFULL =
  "jute_yarn_delivery_order_header_successfull";

export const JUTE_YARN_DELIVERY_ORDER_HEADER =
  "jute_yarn_delivery_order_header";

  export const CLEAR_JUTE_YARN_DELIVERY_ORDER_HEADER =
"clear_jute_yarn_delivery_order_header";
  
export const GET_QUOTATION_RECORDS =
"get_quotation_records";

export const GET_QUOTATION_VIEW_BY_ID_FOR_JUTE_YARN =
"get_quotation_view_by_id_for_jute_yarn";

export const JUTE_YARN_DELIVERY_ORDER_LINE_ITEMS =
  "jute_yarn_delivery_order_line_items";

export const CLEAR_JUTE_YARN_DELIVERY_ORDER_LINE_ITEMS = "clear_jute_yarn_delivery_order_line_items";

export const JUTE_YARN_DELIVERY_ORDER_VIEW_BY_ID =
  "jute_yarn_delivery_order_view_by_id";

export const JUTE_YARN_DELIVERY_ORDER_VIEW_BY_ID_SUCCESSFULL = "jute_yarn_delivery_order_view_by_id_successfull";

export const UPDATE_JUTE_YARN_DELIVERY_ORDER =
  "update_jute_yarn_delivery_order";

export const UPDATE_JUTE_YARN_DELIVERY_ORDER_SUCCESSFULL ="update_jute_yarn_delivery_order_successfull";

export const SALES_ORDER_VIEW_BY_ID_FOR_JUTE_YARN ="sales_order_view_by_id_for_jute_yarn";
export const SALES_ORDER_VIEW_BY_ID_FOR_JUTE_YARN_SUCCESSFULL ="sales_order_view_by_id_for_jute_yarn_successfull";

export const CLEAR_DELIVERY_ORDER_JUTE_YARN_HEADERS =
  "clear_delivery_order_jute_yarn_headers";

export const JUTE_YARN_DELIVERY_ORDER_OTHER_CHARGES = "jute_yarn_deliver_order_other_charges";
export const JUTE_YARN_DELIVERY_ORDER_OTHER_CHARGES_SUCCESSFULL="jute_yarn_deliver_order_other_charges_successfull"

export const JUTE_YARN_ITEMS_BY_ITEM_GROUP_ID ="jute_yarn_items_by_item_group_id";
export const JUTE_YARN_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL ="jute_yarn_items_by_item_group_id_successfull";



//Jute Hessian Delivery Order
export const CREATE_JUTEHESSIAN_DELIVERY_ORDER = "create_jutehessian_delivery_order";
export const CREATE_JUTEHESSIAN_DELIVERY_ORDER_SUCCESSFULL =
  "create_jutehessian_delivery_order_successfull";
export const DELIVERY_ORDER_JUTEHESSIAN_HEADERS =
  "delivery_order_jutehessian_headers";
export const DELIVERY_ORDER_JUTEHESSIAN_LINE_ITEMS =
  "delivery_order_jutehessian_line_items";

export const DELIVERY_ORDER_JUTEHESSIAN_BY_ID = "delivery_order_jutehessian_by_id";
export const DELIVERY_ORDER_JUTEHESSIAN_BY_ID_SUCCESSFULL =
  "delivery_order_jutehessian_by_id_successsfull";

export const UPDATE_JUTEHESSIAN_DELIVERY_ORDER = "update_jutehessian_delivery_order";
export const UPDATE_JUTEHESSIAN_DELIVERY_ORDER_SUCCESSFULL =
  "update_jutehessian_delivery_order_successfull";
export const SALES_ORDER_VIEW_BY_ID_FOR_JUTE_HESSIAN ="sales_order_view_by_id_for_jute_hessian";
export const SALES_ORDER_VIEW_BY_ID_FOR_JUTE_HESSIAN_SUCCESSFULL ="sales_order_view_by_id_for_jute_hessian_successfull";
export const CLEAR_DELIVERY_ORDER_JUTEHESSIAN_HEADERS =
  "clear_delivery_order_jutehessian_headers";
  export const CLEAR_DELIVERY_ORDER_JUTEHESSIAN_LINE_ITEMS =
  "clear_delivery_order_jutehessian_line_items";

export const SALES_ORDER_VIEW_BY_ID_FOR_JUTE_GOVT="sales_order_view_by_id_for_jute_govt";
export const SALES_ORDER_VIEW_BY_ID_FOR_JUTE_GOVT_SUCCESSFULL="sales_order_view_by_id_for_govt_successfull";  

export const JUTE_GOVT_DELIVERY_ORDER_OTHER_CHARGES = "jute_govt_deliver_order_other_charges";
export const JUTE_GOVT_DELIVERY_ORDER_OTHER_CHARGES_SUCCESSFULL="jute_govt_deliver_order_other_charges_successfull"

export const JUTE_GOVT_DELORDER_ITEMS_BY_ITEM_GROUP_ID="jute_govt_delorder_items_by_item_group_id"
export const JUTE_GOVT_DELORDER_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL="jute_govt_delorder_items_by_item_group_id_successfull"

export const API_ERRORS = 'api_failed';

// for upload files and delete files Delivery order
export const UPLOAD_DO_FILES = "upload_do_files";
export const UPLOAD_DO_FILES_SUCCESSFULL = "upload_do_files_successfull";
export const GET_DO_FILES= "get_do_files";
export const DO_FILES_SUCCESSFULL= "do_files_successfull";
export const CLEAR_ALL_DO_FILES='clear_all_do_files';
export const DELETE_DO_FILE = "delete_do_file";
export const DELETE_DO_FILE_SUCCESSFULL = "delete_do_file_successfull";