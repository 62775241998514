import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import DynamicSelect from "../../../components/Dropdown/Master/DynamicSelectMasters";
import { serverApi } from "../../../helpers/Consts";
import { getBranchList } from "../../../store/Global/DropDownApis/actions";

class HolidayMasterFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      holidayDesc: "",
      branchId: "",
      holidayDate: "",
      holidayType: "",
      Branch_List: [],
    };
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "holidayDesc") {
      this.setState({
        holidayDesc: event,
      });
    }
  };
  handleSubmit = () => {
    const data = {
      holidayDate: this.state.holidayDate !==''?this.state.holidayDate:null,
      holidayDesc: this.state.holidayDesc !==""? this.state.holidayDesc:null,
      branchId: this.state.branchId !==""?this.state.branchId:null,
      holidayType: this.state.holidayType !==""?this.state.holidayType:null,
    };
    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };
  handleSelectDate = (e, name) => {
    if (name === "holidayDate") {
      let date = moment(e).format();
      let formattedFromDate = date.split("T", 1).toString();
      this.setState({
        holidayDate: formattedFromDate,
      });
    }
  };
  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "branchId") {
      this.setState({
        branchId: selectedValue,
      });
    }
    if (name === "holidayType") {
      if(selectedValue==""){
        this.setState({
          holidayType: null,
        });
      }else{
        this.setState({
          holidayType: selectedValue,
        });
      }
    }
  };
  componentDidMount() {
    this.props.getBranchList(
      serverApi.BRANCH_LIST + this.state.companyId,
      this.props.history
    );
  }
  componentWillReceiveProps(props) {
    if (props.branchList) {
      this.setState({
        Branch_List: props.branchList.data,
      });
    }
  }
  render() {
    return (
      <>
        <Box>
          <Button onClick={this.handleClickOpen}>
            <Tooltip title="Filter">
              <FilterListOutlinedIcon />
            </Tooltip>
          </Button>
          <Dialog
            open={this.state.open}
            PaperProps={{
              sx: {
                position: " fixed",
                top: 0,
                right: 0,
                margin: 0,
                bottom: 0,
                maxHeight: "100vh",
              },
            }}
            keepMounted
            onClose={this.handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <Grid spacing={0}>
              <div className="consoleTextFieldBlock">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div className="filterBlockHeader">
                    <FilterListOutlinedIcon />
                    <span>Filter</span>
                  </div>
                </div>
              </div>
              <Grid item xs={12} className="filterBlockContainer">
                <Box gridColumn="span 4">
                  <div className="filterDateBlock">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>Holiday Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.holidayDate}
                          fullWidth
                          onChange={(e) =>
                            this.handleSelectDate(e, "holidayDate")
                          }
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="fromDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <TextFieldNormal
                        label="Description"
                        value={this.state.holidayDesc}
                        onChange={this.onhandlechange("holidayDesc")}
                      />
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={
                        this.state.Branch_List
                          ? this.state.Branch_List.map((item) => ({
                              label: item.label,
                              value: item.value,
                              name: item.label,
                            }))
                          : ""
                      }
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.branchId}
                      name="branchId"
                      label="Branch"
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={HolidayTypeList}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.holidayType}
                      name="holidayType"
                      label="Holiday Type"
                    />
                  </div>
                </Box>
                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Search"}
                    className="greenButton"
                    handleClick={this.handleSubmit}
                    type="sumbit"
                  />
                </div>
              </Grid>
            </Grid>
          </Dialog>
        </Box>
      </>
    );
  }
}
const HolidayTypeList = [
  {
    value: "",
    label: "Select...",
    name: "Select...",
  },
  {
    value: "1",
    label: "Fixed Holiday",
    name: "Fixed Holiday",
  },
  {
    value: "2",
    label: "Restricted Holiday",
    name: "Restricted Holiday",
  },
];
const mapStatetoProps = (state) => {
  const { branchList } = state.DropDownListReducer;
  return { branchList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getBranchList,
  })(HolidayMasterFilter)
);
