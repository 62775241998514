import {EXPENSE_BOOKING_LIST,EXPENSE_BOOKING_LIST_SUCCESSFULL,
  WORKER_LIST,WORKER_LIST_SUCCESSFULL,
  ADD_EXPENSE_BOOKING,
  ADD_EXPENSE_BOOKING_SUCCESSFULL,
  COST_CENTER,
  WORKER_DATA_CLEAR,
  COST_CENTER_SUCCESSFULL,
  EXPENSE_BOOKING_VIEW_BY_ID,
  EXPENSE_BOOKING_VIEW_BY_ID_SUCCESSFULL,
  DELETE_FILES,
  DELETE_FILES_SUCCESSFULL,
  UPLOAD_FILES,
  UPLOAD_FILES_SUCCESSFULL

} from './actionType'

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
   expenseBooking_list: [],
   worker_List:[],
   AddExpenseBooking:[],
   Cost_Center_List:[],
   ExpenseBookingViewById:[],
   deleteFiles:[],
   Upload_files:[]
  };

  const ExpenseBookingReducer = (state = initialState, action) => {
    switch (action.type) {
        case EXPENSE_BOOKING_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case EXPENSE_BOOKING_LIST_SUCCESSFULL:
            state = {
              ...state,
              expenseBooking_list: action.payload.response.data,
              loading: true,
            };
            break;
            case WORKER_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case WORKER_LIST_SUCCESSFULL:
            state = {
              ...state,
              worker_List: action.payload.response.data,
              loading: true,
            };
            break;
            case ADD_EXPENSE_BOOKING:
              state = {
                ...state,
                loading: true,
              };
              break;
            case ADD_EXPENSE_BOOKING_SUCCESSFULL:
              state = {
                ...state,
                AddExpenseBooking: action.payload.response.data,
                loading: true,
              };
              break;
              case EXPENSE_BOOKING_VIEW_BY_ID:
                state = {
                  ...state,
                  loading: true,
                };
                break;
              case EXPENSE_BOOKING_VIEW_BY_ID_SUCCESSFULL:
                state = {
                  ...state,
                  ExpenseBookingViewById: action.payload.response.data,
                  loading: true,
                };
              break;
              case COST_CENTER:
                state = {
                  ...state,
                  loading: true,
                };
                break;
              case COST_CENTER_SUCCESSFULL:
                state = {
                  ...state,
                  Cost_Center_List: action.payload.response.data,
                  loading: true,
                };
                break;

                case DELETE_FILES:
                  state = {
                    ...state,
                    loading: true,
                  };
                  break;
                case DELETE_FILES_SUCCESSFULL:
                  state = {
                    ...state,
                    deleteFiles: action.payload.response.data,
                    loading: true,
                  };
                  break;
                  case UPLOAD_FILES:
                    state = {
                      ...state,
                      loading: true,
                    };
                    break;
                  case UPLOAD_FILES_SUCCESSFULL:
                    state = {
                      ...state,
                      Upload_files: action.payload.response,
                      loading: true,
                    };
                    break;
                case WORKER_DATA_CLEAR:
                  state = {
                    ...state,
                    worker_List: action.payload,
                    loading: true,
                  };
                  break;
            
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default ExpenseBookingReducer ;