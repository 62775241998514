import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import { branchMasterList } from "../../../store/Master/BranchMaster/actions";
import { paySlipDataClear } from "../../../store/HRMS/PaySlip/actions";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      branchMasterListData: [], // to display branch master list data
    };
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
    };
    this.props.branchMasterList(
      serverApi.GET_ALL_BRANCH_MASTER_LIST,
      data,
      this.props.history
    ); // calling Branch master list API
    this.props.paySlipDataClear(null);
  }
  componentWillReceiveProps(props) {
    if (props.BranchMasterList) {
      this.setState({
        branchMasterListData: props.BranchMasterList.data, // updating branch master list data
      });
    }
  }

  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.branchMasterListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          url="/create_branch_master"
        />
      </>
    );
  }
}
// Branch master list Headers
const Columns = [
  {
    Header: "Branch Id",
    accessor: "id",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Branch Name",
    accessor: "brname",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Address",
    accessor: "address",
    hidden: false,
    csvExport: true,
  },
];
const mapStatetoProps = (state) => {
  const { BranchMasterList } = state.BranchMasterReducer; // fetching list from Branch master reducer
  return { BranchMasterList };
};

export default withRouter(
  connect(mapStatetoProps, {
    branchMasterList,
    paySlipDataClear,
  })(Index)
);
