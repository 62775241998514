import {ADVANCE_REQUEST_LIST, ADVANCE_REQUEST_VIEW_BY_ID, CREATE_ADVANCE_REQUEST, GET_WORKER_DATA} from './actionType'
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {getListurl, postAdd} from '../../../helpers/Server_Helper';
import {AdvanceRequestListSuccessfull,AdvanceRequestSuccessfull,AdvanceRequestViewByIdSuccessfull,getWorkerDataByEbNOSuccessfull,getWorkerDataByEbNO} from './actions'
import swal from "sweetalert";
import {  hideSpinner, serverApi, showSpinner, } from "../../../helpers/Consts";

function* getAdvanceRequestList({payload:{url,data,history}}) {
    try{
        const response = yield call(getListurl,url,data);
        yield put(AdvanceRequestListSuccessfull({response}))
    }catch(error){
        console.log(error)
        if (error) {
            if (error.response) {
              if (error.response.data) {
                swal(error.response.data.error,{icon:"error"});
              }
            }
          }
    }
}
function* setAdvanceRequest({payload:{url,data,history}}) {
    try{
        showSpinner()
        const response = yield call(postAdd,url,data);
        yield put(AdvanceRequestSuccessfull({response}))
        if(response){
            swal(response.data.message, { icon: "success" })
            history.push("/advancerequest")
        hideSpinner()
        }  
    }catch(error){
        hideSpinner()
        console.log(error)
        if (error) {
            if (error.response) {
              if (error.response.data) {
                swal(error.response.data.error,{icon:"error"});
              }
            }
          }
    }
}
function* AdvanceRequestViewByid({payload:{url,data,history}}) {
    try{
        const response = yield call(getListurl,url,data);
        yield put(AdvanceRequestViewByIdSuccessfull({response}))

        if(response){
            yield put( getWorkerDataByEbNO(serverApi.WORKER_LIST + response.data.hdrDto.ebNo+"/" +localStorage.getItem("companyId") +  "/" +JSON.parse(localStorage.getItem("authUser")).userId,history))
        }
        // swal(response.message, { icon: "success" })
    }catch(error){
        console.log(error)
        if (error) {
            if (error.response) {
              if (error.response.data) {
                swal(error.response.data.error,{icon:"error"});
              }
            }
          }
    }
}
function* getWorkerByEbNOData({payload:{url,history}}) {
    try{
        const response = yield call(getListurl,url);
        yield put(getWorkerDataByEbNOSuccessfull({response}))
        swal(response.message, { icon: "success" })
    }catch(error){
        console.log(error)
        if (error) {
            if (error.response) {
              if (error.response.data) {
                swal(error.response.data.error,{icon:"error"});
              }
            }
          }
    }
}


export function* watchAdvanceRequestList () {
    yield takeEvery(ADVANCE_REQUEST_LIST,getAdvanceRequestList)
    yield takeEvery(CREATE_ADVANCE_REQUEST,setAdvanceRequest)
    yield takeEvery(ADVANCE_REQUEST_VIEW_BY_ID,AdvanceRequestViewByid)
    yield takeEvery(GET_WORKER_DATA,getWorkerByEbNOData)
}

function* AdvanceRequestSaga() {
    yield all([
        fork(watchAdvanceRequestList)
    ])
}

export default AdvanceRequestSaga;