import {DESIGNATION_MASTER_LIST,CREATE_DESIGNATION_MASTER,UPDATE_DESIGNATION_MASTER,
    DESIGNATION_MASTER_LIST_SUCCESSFULL,CREATE_DESIGNATION_MASTER_SUCCESSFULL,UPDATE_DESIGNATION_MASTER_SUCCESSFULL,API_ERRORS} from './actionTypes';

export const getDesignationMasterList = (url,data,history) =>{
    return {
        type:DESIGNATION_MASTER_LIST,
        payload:{url,data,history}
    }
}
export const DesignationMasterListSuccessfull = (data)=>{
    return {
        type:DESIGNATION_MASTER_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createDesignationMaster = (url, data, history) => {
    return {
        type: CREATE_DESIGNATION_MASTER,
        payload: { url, data, history }
    }
}
export const createDesignationMasterSuccessfull = (data) => {
    return {
        type: CREATE_DESIGNATION_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const updateDesignationMaster = (url, data, history) => {
    return {
        type: UPDATE_DESIGNATION_MASTER,
        payload: { url, data, history }
    }
}
export const updateDesignationMasterSuccessfull = (data) => {
    return {
        type: UPDATE_DESIGNATION_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const apiErrors = (error) => {
    return {
      type: API_ERRORS,
      payload: error,
    };
  };

