import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Box } from "@mui/material";
import TextFieldNormal from "../../../../../components/TextField/TextFieldNormal";
import TextFieldReq from "../../../../../components/TextField/TextFieldReq";
import TextFieldPincode from "../../../../../components/TextField/TextFieldPincode";
import { serverApi } from "../../../../../helpers/Consts";
import CustomButton from "../../../../../components/Buttons/Buttons";
import { Link } from "react-router-dom";
import Checkbox from "../../../../../components/CheckBox/CheckboxIsCorr";
import {
  getStateNameList,
  getCountryNameList,
} from "../../../../../store/Global/DropDownApis/actions";
import DynamicSelect from "../../../../../components/Dropdown/DynamicSelect";
import {
  viewbyidPersonalDetails,
  updateWorkerDetails,
  clearProfileData,
} from "../../../../../store/HRMS/EmployeeDataBase/actions";
import swal from "sweetalert";
class AddressDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ebId: this.props.getEbId,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
      stateNameList: [],
      countryNameList: [],

      iscorrTrue: true,
      iscorrFalse: false,
      isc: 0,

      edContactDetailsId: "",
      edContactDetailsId1: "",
      addressType: "",
      countryId: "73",
      stateId: "",
      cityId: "",
      addressLine1: "",
      addressLine2: "",
      pinCode: "",
      isActive: 1,
      isCorrespondentAddress: false,
      createdBy: "",
      createdTime: "",

      countryId1: "73",
      stateId1: "",
      cityId1: "",
      addressLine11: "",
      addressLine21: "",
      pinCode1: "",
    };
  }
  onhandlechange = (key) => (event) => {
    if (!this.state.isCorrespondentAddress) {
      this.setState({ [key]: event });
    } else {
      this.setState({ [key]: event, [key + "1"]: event });
    }
  };

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (!this.state.isCorrespondentAddress) {
      this.setState({ [name]: selectedValue });
    } else {
      this.setState({ [name]: selectedValue, [name + "1"]: selectedValue });
    }
    if (name === "countryId") {
      this.props.getStateNameList(
        serverApi.GET_STATES_NEW_NAME_LIST + selectedValue,
        this.props.history
      );
    }
  };
  onColumnToggle = (e) => {
    //check box toggle //

    if (this.state.isCorrespondentAddress) {
      this.setState({
        isCorrespondentAddress: false,
        countryId1: "",
        stateId1: "",
        cityId1: "",
        addressLine11: "",
        addressLine21: "",
        pinCode1: "",
      });
    } else {
      this.setState({
        isCorrespondentAddress: true,
        countryId1: this.state.countryId,
        stateId1: this.state.stateId,
        cityId1: this.state.cityId,
        addressLine11: this.state.addressLine1,
        addressLine21: this.state.addressLine2,
        pinCode1: this.state.pinCode,
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    this.props.getCountryNameList(
      serverApi.GET_COUNTRYS_NAME_LIST,
      this.props.history
    ); // Getting Countrys Api
    this.props.getStateNameList(
      serverApi.GET_STATES_NEW_NAME_LIST + 73,
      this.props.history
    ); // Getting States Api  // security-api/api/state/viewStatesByCountryId/73
    this.props.viewbyidPersonalDetails(
      serverApi.VIEW_EMPLOYEE_ADDRESS_DIT + "/" + this.props.getEbId,
      this.props.history,
      3
    ); // Getting Contact and Address details api
    this.props.clearProfileData(null);
  }
  componentWillReceiveProps(props) {
    if (props.stateNameList) {
      if (props.stateNameList) {
        var stateData = props.stateNameList.data;
        if (stateData) {
          var statesMdata = stateData.map((state) => {
            var obj = {
              label: state.stateName,
              value: state.id,
              name: state.stateName,
            };
            return obj;
          });

          this.setState({
            stateNameList: statesMdata,
          });
        }
      }
    }
    if (props.countryNameList) {
      if (props.countryNameList) {
        var countryData = props.countryNameList.data;
        if (countryData) {
          var countryMdata = countryData.map((country) => {
            var obj = {
              label: country.countryName,
              value: country.id,
              name: country.countryName,
            };
            return obj;
          });
          this.setState({
            countryNameList: countryMdata,
          });
        }
      }
    }

    if (props.personalDetails) {
      if (props.personalDetails.data) {
        var viewdetails1 = props.personalDetails.data[0];
        var viewdetails2 = props.personalDetails.data[1];
        if (viewdetails1) {
          if (viewdetails1.addressType === 1) {
            this.setState({
              edContactDetailsId: viewdetails1.edContactDetailsId,
              addressType: viewdetails1.addressType,
              countryId: viewdetails1.countryId,
              stateId: viewdetails1.stateId,
              cityId: viewdetails1.cityName,
              addressLine1: viewdetails1.addressLine1,
              addressLine2: viewdetails1.addressLine2,
              pinCode: viewdetails1.pinCode,
              isActive: viewdetails1.isActive,
              createdBy: viewdetails1.createdBy,
            });
          }
          if (viewdetails1.addressType === 2) {
            this.setState({
              edContactDetailsId1: viewdetails1.edContactDetailsId,
              addressType: viewdetails1.addressType,
              countryId1: viewdetails1.countryId,
              stateId1: viewdetails1.stateId,
              cityId1: viewdetails1.cityName,
              addressLine11: viewdetails1.addressLine1,
              addressLine21: viewdetails1.addressLine2,
              pinCode1: viewdetails1.pinCode,
              isActive: viewdetails1.isActive,
              createdBy: viewdetails1.createdBy,
            });
          }
        }
        if (viewdetails2) {
          if (viewdetails2.addressType === 1) {
            this.setState({
              edContactDetailsId: viewdetails2.edContactDetailsId,
              addressType: viewdetails2.addressType,
              countryId: viewdetails2.countryId,
              stateId: viewdetails2.stateId,
              cityId: viewdetails2.cityName,
              addressLine1: viewdetails2.addressLine1,
              addressLine2: viewdetails2.addressLine2,
              pinCode: viewdetails2.pinCode,
              isActive: viewdetails2.isActive,
              createdBy: viewdetails2.createdBy,
            });
          }
          this.setState({
            edContactDetailsId1: viewdetails2.edContactDetailsId,
            countryId1: viewdetails2.countryId,
            stateId1: viewdetails2.stateId,
            cityId1: viewdetails2.cityName,
            addressLine11: viewdetails2.addressLine1,
            addressLine21: viewdetails2.addressLine2,
            pinCode1: viewdetails2.pinCode,
            isc: viewdetails2.isCorrespondentAddress,
            isCorrespondentAddress:
              viewdetails2.isCorrespondentAddress == 1 ? true : false,
          });
        }
      }
    }
  }

  onClickSave = () => {
    if (this.state.addressLine1 == "") {
      swal("Please Enter the Address Line 1");
    } else if (this.state.pinCode == "") {
      swal("Please Enter the Pincode");
    } else if (this.state.countryId == "") {
      swal("Please Select the Country");
    } else if (this.state.stateId == "" || this.state.stateId == null) {
      swal("Please Select the State");
    } else {
      const data = [
        {
          edContactDetailsId: this.state.edContactDetailsId,
          ebId: this.state.ebId,
          addressType: 1,
          countryId: this.state.countryId,
          stateId: this.state.stateId,
          cityName: this.state.cityId,
          addressLine1: this.state.addressLine1,
          addressLine2: this.state.addressLine2,
          pinCode: this.state.pinCode,
          isCorrespondentAddress: this.state.isCorrespondentAddress ? 1 : 0,
          createdBy: this.state.updatedBy,
        },
        {
          edContactDetailsId: this.state.edContactDetailsId1,
          ebId: this.state.ebId,
          addressType: 2,
          countryId: this.state.countryId1,
          stateId: this.state.stateId1,
          cityName: this.state.cityId1,
          addressLine1: this.state.addressLine11,
          addressLine2: this.state.addressLine21,
          pinCode: this.state.pinCode1,
          isCorrespondentAddress: this.state.isCorrespondentAddress ? 1 : 0,
          createdBy: this.state.updatedBy,
        },
      ];
      this.props.updateWorkerDetails(
        serverApi.UPDATE_EMPLOYEE_ADDRESS_DIT,
        data,
        this.props.history,
        "address_details"
      );
    }
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div className="contactDetailsBlock">
        <div className="consoleFormContainer">
          <h1>Permanent Address</h1>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                  className="contactDetails"
                >
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        label="Address Line 1"
                        placeholder="Enter Here"
                        value={this.state.addressLine1}
                        onChange={this.onhandlechange("addressLine1")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Address Line 2"
                        placeholder="Street name / Area name"
                        value={this.state.addressLine2}
                        onChange={this.onhandlechange("addressLine2")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldPincode
                        required
                        label="Pincode"
                        placeholder="Enter Here"
                        value={this.state.pinCode}
                        onChange={this.onhandlechange("pinCode")}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.countryNameList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.countryId}
                        name="countryId"
                        label="Country"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.stateNameList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.stateId}
                        name="stateId"
                        label="State"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="City"
                        placeholder="Enter Here"
                        value={this.state.cityId}
                        onChange={this.onhandlechange("cityId")}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="consoleFormContainer">
          <div className="addressTextStyle">
            <Checkbox
              label={"Correspondence address is same as Permanent address"}
              value="Check All"
              checked={this.state.isCorrespondentAddress}
              onChange={() => {
                this.onColumnToggle("isCorrespondentAddress");
              }}
            />
          </div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                  className="contactDetails"
                >
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Address Line 1"
                        placeholder="Enter Here"
                        value={this.state.addressLine11}
                        onChange={this.onhandlechange("addressLine11")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Address Line 2"
                        placeholder="Enter Here"
                        value={this.state.addressLine21}
                        onChange={this.onhandlechange("addressLine21")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Pincode"
                        placeholder="Enter Pincode"
                        value={this.state.pinCode1}
                        onChange={this.onhandlechange("pinCode1")}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.countryNameList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.countryId1}
                        name="countryId1"
                        label="Country"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.stateNameList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.stateId1}
                        name="stateId1"
                        label="State"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="City"
                        placeholder="Enter Here"
                        value={this.state.cityId1}
                        onChange={this.onhandlechange("cityId1")}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="consoleFormButtonBlock">
          <Link to="/add_employee">
            <CustomButton label={"Cancel"} className="greenBorderButton" />
          </Link>

          <CustomButton
            label={"Update"}
            className="greenButton"
            handleClick={this.onClickSave}
            type="sumbit"
          />
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { personalDetails, getEbId } = state.EmployeeReducer;
  const { stateNameList, countryNameList } = state.DropDownListReducer;
  return { stateNameList, countryNameList, personalDetails, getEbId };
};

export default withRouter(
  connect(mapStatetoProps, {
    viewbyidPersonalDetails,
    updateWorkerDetails,
    getStateNameList,
    getCountryNameList,
    clearProfileData,
  })(AddressDetails)
);
