export const LineItemsValidtions = (fieldsList) => {
  let returnObj;
  for (var i = 0; i <= fieldsList.length - 1; i++) {
    if (fieldsList) {
      if(fieldsList[i].msg === "Tax Percentage"){
        if (
          fieldsList[i].field === "" ||
          fieldsList[i].field === undefined ||
          fieldsList[i].field === null ||
          fieldsList[i].field === "null" 
        ) {
          returnObj = { type: fieldsList[i].type, message: fieldsList[i].msg };
          break;
        }else{
       
        returnObj = ""
        }
      }else
      if (
        fieldsList[i].field === "" ||
        fieldsList[i].field === undefined ||
        fieldsList[i].field === "Invalid date" ||
        fieldsList[i].field === null ||
        fieldsList[i].field === "null" ||
        fieldsList[i].field === 0 ||
        fieldsList[i].field === "0" ||
        fieldsList[i].field === "0.000" ||
        fieldsList[i].field.length === 0 ||
        fieldsList.length === 0 ||
        fieldsList[i].field <= 0
      ) {
        returnObj = { type: fieldsList[i].type, message: fieldsList[i].msg };
        break;
      }
    }
  }
  return returnObj;
};
