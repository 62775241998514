import { JUTE_GATE_ENTRY_LIST, JUTE_GATE_ENTRY_LIST_SUCCESSFULL, JUTE_GATE_ENTRY_LINE_ITEMS, JUTE_GATE_ENTRY_CREATE, JUTE_GATE_ENTRY_VIEW_BY_ID, JUTE_GATE_ENTRY_VIEW_BY_ID_SCCESSFULL, JUTE_GATE_ENTRY_HEADER, CLEAR_JUTE_GATE_ENTRY_HEADER, CLEAR_JUTE_GATE_ENTRY_LINE_ITEMS, GET_QUALITY_BY_ITEM, GET_QUALITY_BY_ITEM_SUCCESSFULL,SET_ENTRY_HEADER_LINEITEMS, GET_ALL_APPROVED_PO, GET_ALL_APPROVED_PO_SUCCESSFULL, JUTE_PO_VIEW_BY_ID_FOR_GATE_ENTRY, JUTE_PO_VIEW_BY_ID_FOR_GATE_ENTRY_SCCESSFULL, JUTE_GATE_ENTRY_UPLOAD_FILE, JUTE_GATE_ENTRY_UPLOAD_FILE_SUCCESSFULL, JUTE_GATE_ENTRY_GET_ALL_FILES, JUTE_GATE_ENTRY_GET_ALL_FILES_SUCCESSFULL, JUTE_GATE_ENTRY_DELETE_FILE, JUTE_GATE_ENTRY_DELETE_FILE_SUCCESSFULL } from "./actionTypes";

export const JuteGateEntry = (url, data, history) => {
  return {
    type: JUTE_GATE_ENTRY_LIST,
    payload: { url, data, history },
  };
};
export const JuteGateEntrySuccessfull = (data) => {
  return {
    type: JUTE_GATE_ENTRY_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const LineItemsGateEntryPros = (data) => {
  return {type: JUTE_GATE_ENTRY_LINE_ITEMS, payload:data}
}
export const SetEntryHeaderAndLineItems = (data) => {
  return {type: SET_ENTRY_HEADER_LINEITEMS, payload:data}
}

export const juteGateEntryUpdate = (url, data, history) => {  
  return {
      type: JUTE_GATE_ENTRY_CREATE,
      payload: {url, data, history}
  }
}

export const juteGateEntryViewById = (url,history) =>{
  return {
      type:JUTE_GATE_ENTRY_VIEW_BY_ID,
      payload:{url,history}
  }
}

export const juteGateEntryViewByIdSuccessfull = (data)=>{
  return {
      type:JUTE_GATE_ENTRY_VIEW_BY_ID_SCCESSFULL,
      payload:data
  }
}

export const getJuteGateEntryHeader = (data) => {
  return {
    type: JUTE_GATE_ENTRY_HEADER,
    payload: data,
  };
};
export const ClearJuteGateEntryHeader  = (data) => {
  return {
    type:CLEAR_JUTE_GATE_ENTRY_HEADER,
    payload: data,
  };
};
export const ClearJuteGateEntryLineItems = (data) => {
  return {
    type: CLEAR_JUTE_GATE_ENTRY_LINE_ITEMS,
    payload: data,
  };
};
export const getQualityByItem= (url,rowId) => {
  return {
    type: GET_QUALITY_BY_ITEM,
    payload: { url,rowId },
  };
};
export const getQualityByItemSuccessfull = (data) => {
  return {
    type: GET_QUALITY_BY_ITEM_SUCCESSFULL,
    payload: data,
  };
};

export const getAllApprovedPO = (url,history) =>{
  return {
      type:GET_ALL_APPROVED_PO,
      payload:{url,history}
  }
}

export const getAllApprovedPOSuccessfull = (data)=>{
  return {
      type:GET_ALL_APPROVED_PO_SUCCESSFULL,
      payload:data
  }
}
export const jutePoViewByIdForGateEntry = (url,data,history) =>{
  return {
      type:JUTE_PO_VIEW_BY_ID_FOR_GATE_ENTRY,
      payload:{url,data,history}
  }
}

export const jutePoViewByIdForGateEntrySuccessfull = (data)=>{
  return {
      type:JUTE_PO_VIEW_BY_ID_FOR_GATE_ENTRY_SCCESSFULL,
      payload:data
  }
}
export const JuteGateEntryUploadFiles = (url, data,id) =>{
  return {
      type:JUTE_GATE_ENTRY_UPLOAD_FILE,
      payload:{url,data,id}
  }
}

export const JuteGateEntryUploadFilesSuccessfull = (data)=>{
  return {
      type:JUTE_GATE_ENTRY_UPLOAD_FILE_SUCCESSFULL,
      payload:data
  }
}

export const JuteGateEntryGetAllFiles = (url, history, type) => {
  return {
    type: JUTE_GATE_ENTRY_GET_ALL_FILES,
    payload: { url, history, type},
  };
};
export const JuteGateEntryGetAllFilesSuccessfull = (data) => {
  return {
    type: JUTE_GATE_ENTRY_GET_ALL_FILES_SUCCESSFULL,
    payload: data,
  };
};
export const JuteGateEntryDeleteFiles = (url,id) =>{
  return {
      type:JUTE_GATE_ENTRY_DELETE_FILE,
      payload:{url,id}
  }
}

export const JuteGateEntryDeleteFilesSuccessfull = (data)=>{
  return {
      type:JUTE_GATE_ENTRY_DELETE_FILE_SUCCESSFULL,
      payload:data
  }
}