import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import {
  STOCK_TRANSFER_LIST,
  COMPANY_ALL_LIST,
  ALL_BRANCH_LIST,
  GET_WARE_HOUSE_LIST,
  ADD_STOCK_TRANSFER,
} from "./actionTypes";
import {
  StockTransferListSuccessfull,
  getAllCompanyListSuccessfull,
  allGetBranchListSuccessfull,
  getwareHouseDataListSuccessfull,
  addStockTransferSuccessfull,
} from "./actions";
import { getList, getListurl } from "../../../helpers/Server_Helper";

function* getStockTransferList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(StockTransferListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getWarehouseData({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(getwareHouseDataListSuccessfull({ response }));
  } catch (error) {
    // console.log(error);
  }
}

function* companyAllList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(getAllCompanyListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* branchAllList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(allGetBranchListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* addStockTransfer({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(addStockTransferSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

export function* watchStockTransferList() {
  yield takeEvery(STOCK_TRANSFER_LIST, getStockTransferList);
}
export function* watchAllCompanyList() {
  yield takeEvery(COMPANY_ALL_LIST, companyAllList);
}

export function* watchAllBranchList() {
  yield takeEvery(ALL_BRANCH_LIST, branchAllList);
}

export function* watchAllWareHouseData() {
  yield takeEvery(GET_WARE_HOUSE_LIST, getWarehouseData);
}

export function* watchAddStockTransfer() {
  yield takeEvery(ADD_STOCK_TRANSFER, addStockTransfer);
}

function* StockTransferSaga() {
  yield all([
    fork(watchStockTransferList),
    fork(watchAllCompanyList),
    fork(watchAllBranchList),
    fork(watchAllWareHouseData),
    fork(watchAddStockTransfer),
  ]);
}

export default StockTransferSaga;
