import React, { Component } from 'react';
import TextField from './TextField';





class TextFieldEmailReq extends Component {
    constructor(props) {
        super(props);
    }

    render() {
      const Humbug = {
        value: this.props.value,
        label: this.props.lable,
        placeholder: this.props.placeholder,
        type:this.props.type,
        onChange:this.props.onChange,
        caps:this.props.caps,
        disabled: this.props.disabled
      }
        return(
          <div className="TextFieldBlock">
            {this.props.label && <label htmlFor="app-input-field">{this.props.label} <label className="starcolor">*</label></label>}
                <TextField emailreq={true} {...Humbug}></TextField>
            </div>
        )
    }
}

export default TextFieldEmailReq;