import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../../../components/Buttons/Buttons";
import { Link } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import DynamicSelectNew from "../../../../../components/Dropdown/DropdownSelectNew";
import DynamicSelect from "../../../../../components/Dropdown/DynamicSelect";
import { getSpell } from "../../../../../store/HRMS/AttendanceCalender/actions";
import { setShiftMaster } from "../../../../../store/Master/ShiftMaster/actions";
import { serverApi } from "../../../../../helpers/Consts";
import { ViewByIdShiftMaster } from "../../../../../store/Master/ShiftMaster/actions";
import { getSpellMasterList } from "../../../../../store/Master/SpellMaster/actions";
import TextFieldNormal from "../../../../../components/TextField/TextFieldNormal";
import moment from "moment";
import {
  getBioMetriDevice,
  createBioMetriDevice,
  getWorkerDetailsById,
  deleteBioMetriDevice,
} from "../../../../../store/Master/ShiftMaster/actions";
import { Button } from "@mui/material";
import DeleteIcon from "../../../../../assets/images/picEditIcon.png";
import swal from "sweetalert";

class ShiftTiming extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      ebId: this.props.getEbId,
      empShiftMapId: null,
      Shifts: [],
      shift_id: "",
      shift_name: "",
      response: [],
      spellMasterListData: [],
      spellMasterListTotalData: [],
      shiftList_display: false,
      bioDeviceId: null,
      empBioDeviceId: "",
      empRFNo: "",
      bioMetricEmpMapList: [
        {
          id: 1,
          bioDeviceId: "",
          companyId: localStorage.getItem("companyId"),
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          ebId: "",
          empBioDeviceId: "",
          empRFNo: "",
          userId: JSON.parse(localStorage.getItem("authUser")).userId,
          activeStatus: "",
          type: true,
        },
      ],
    };
  }
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "shift") {
      if (this.state.spellMasterListTotalData) {
        var spelldata = this.state.spellMasterListTotalData.filter((row) => {
          if (selectedValue === row.spellName) {
            return row;
          }
        });
      }
      this.setState({
        shift_id: selectedValue,
        shift_name: selectedName,
        spellMasterListData: spelldata,
        shiftList_display: true,
      });
    }
  };

  componentWillReceiveProps(props) {
    if (props.spell) {
      this.setState({
        Shifts: props.spell,
      });
    }
    if (props.SpellMaster_List) {
      this.setState({
        spellMasterListData: props.SpellMaster_List.data, // updating spell master list data
        spellMasterListTotalData: props.SpellMaster_List.data,
      });
    }
    if (props.bio_metric_device.data) {
      this.setState({
        bio_metric_device_list: props.bio_metric_device.data,
      });
    }
    if (this.props.getEbId) {
      if (props.ShiftMasterViewById_data) {
        if (props.ShiftMasterViewById_data.data) {
          if (props.ShiftMasterViewById_data.data.data) {
            if (props.ShiftMasterViewById_data.data.data.employeeShiftMapList) {
              var shift =
                props.ShiftMasterViewById_data.data.data.employeeShiftMapList;
              var shift_arr = shift.spell1.split("_");
              this.setState({
                shift_name: shift_arr[0],
                empShiftMapId: shift.empShiftMapId,
              });
              if (props.SpellMaster_List) {
                if (props.SpellMaster_List.data) {
                  var spelldata = props.SpellMaster_List.data.filter((row) => {
                    if (shift_arr[0] === row.spellName) {
                      return row;
                    }
                  });
                  this.setState({
                    spellMasterListData: spelldata,
                    shiftList_display: true,
                  });
                }
              }
            }
          } else {
            this.setState({
              spellMasterListData: [],
              shiftList_display: false,
              shift_name: "",
              empShiftMapId: "",
            });
          }
        }
      }
    }
    if (props.GetWorker_DetailsById) {
      if (props.GetWorker_DetailsById.data) {
        if (props.GetWorker_DetailsById.data.data) {
          if (props.GetWorker_DetailsById.data.data.bioMetricEmpMapList) {
            if (
              props.GetWorker_DetailsById.data.data.bioMetricEmpMapList
                .length !== 0
            ) {
              var i = 1;
              let tableDataList =
                props.GetWorker_DetailsById.data.data.bioMetricEmpMapList.map(
                  (row) => {
                    var rowdata = {
                      id: i,
                      bioDeviceId: row.bioDeviceId,
                      companyId: row.companyId,
                      createdBy: row.companyId,
                      ebId: row.ebId,
                      empBioDeviceId: row.empBioDeviceId,
                      empRFNo: row.empRFNo,
                      userId: JSON.parse(localStorage.getItem("authUser"))
                        .userId,
                      activeStatus:
                        row.activeStatus == 1 ? "Active" : "InActive",
                      type: false,
                      mappingId: row.mappingId,
                    };
                    i++;
                    return rowdata;
                  }
                );
              this.setState({
                bioMetricEmpMapList: tableDataList,
              });
            } else {
              var data = [
                {
                  id: 1,
                  bioDeviceId: "",
                  companyId: localStorage.getItem("companyId"),
                  createdBy: JSON.parse(localStorage.getItem("authUser"))
                    .userId,
                  ebId: "",
                  empBioDeviceId: "",
                  empRFNo: "",
                  userId: JSON.parse(localStorage.getItem("authUser")).userId,
                  activeStatus: "",
                  type: true,
                },
              ];
              this.setState({
                bioMetricEmpMapList: data,
              });
            }
          }
        }
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getSpell(
      serverApi.SPELL + this.state.companyId + "/" + this.state.userDit.cipher,
      this.props.history
    );
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      startIndex: 0,
      lastIndex: 0,
    };
    this.props.getSpellMasterList(
      serverApi.SPELL_MASTER,
      data,
      this.props.history
    );
    this.props.ViewByIdShiftMaster(
      serverApi.VIEW_BY_ID_EMPLOYEE_SHIFT + "/" + localStorage.getItem("ebId"),
      this.props.history,
      null
    );
    this.props.getBioMetriDevice(
      serverApi.BIO_METRIC_DEVICE,
      this.props.history
    );
    this.props.getWorkerDetailsById(
      serverApi.EMPLOYEE_DETAILS_VIEWBYID +
        this.props.getEbId +
        "/userId/" +
        this.state.userDit.userId,
      this.props.history
    );
  }
  AddBioMetricDevice = () => {
    const mdata = [];
    this.state.bioMetricEmpMapList.map((row) => {
      if (row.type == true) {
        const obj = {
          bioDeviceId: row.bioDeviceId,
          companyId: localStorage.getItem("companyId"),
          createdBy: this.state.userDit.userId,
          ebId: this.props.getEbId,
          empBioDeviceId: row.empBioDeviceId,
          empRFNo: row.empRFNo,
          userId: this.state.userDit.userId,
        };
        mdata.push(obj);
      }
    });
    if (mdata.length > 0) {
      this.props.createBioMetriDevice(
        serverApi.CREATE_BIO_METRIC_DEVICE,
        mdata,
        this.props.history,
        this.props.getEbId
      );
    } else {
      swal("Sorry! Please add Bio Metric Details", { icon: "error" });
    }

    // const data = {
    //   bioDeviceId: this.state.bioDeviceId,
    //   companyId: localStorage.getItem("companyId"),
    //   createdBy: this.state.userDit.userId,
    //   ebId: this.props.getEbId,
    //   empBioDeviceId: this.state.empBioDeviceId,
    //   empRFNo: this.state.empRFNo,
    //   userId: this.state.userDit.userId,
    // };
    // this.props.createBioMetriDevice(
    //   serverApi.CREATE_BIO_METRIC_DEVICE,
    //   data,
    //   this.props.history
    // );
  };
  onClickNext = () => {
    const data = {
      empShiftMapId: this.state.empShiftMapId,
      companyId: localStorage.getItem("companyId"),
      createdBy: this.state.userDit.userId,
      ebId: this.props.getEbId,
      lateMins1: "",
      lateMins2: "",
      roasterDays: "",
      roasterGapDays: "",
      roasterStartDate: moment().format("YYYY-MM-DD"),
      spell1: this.state.shift_name,
      spell2: "",
      userId: this.state.userDit.userId,
      weekOffDay: "",
      weekOffDay2: "",
      weekOffHalfDay: "",
    };
    this.props.setShiftMaster(
      serverApi.SHIFT_ADD_UPDATE,
      data,
      this.props.history,
      localStorage.getItem("ebId")
    );
  };
  onClickUpdate = () => {
    const data = {
      empShiftMapId: this.state.empShiftMapId,
      companyId: localStorage.getItem("companyId"),
      createdBy: this.state.userDit.userId,
      ebId: this.props.getEbId,
      lateMins1: "",
      lateMins2: "",
      roasterDays: "",
      roasterGapDays: "",
      roasterStartDate: moment().format("YYYY-MM-DD"),
      spell1: this.state.shift_name,
      spell2: "",
      userId: this.state.userDit.userId,
      weekOffDay: "",
      weekOffDay2: "",
      weekOffHalfDay: "",
    };
    this.props.setShiftMaster(
      serverApi.UPDATE_EMPLOYEE_SHIFT,
      data,
      this.props.history,
      localStorage.getItem("ebId")
    );
  };

  onhandleRfNo = (data) => (event) => {
    const { key, i } = data;
    // this.setState({ [key]: event });
    if (data.key == data.i + 1) {
      this.state.bioMetricEmpMapList[i].empRFNo = event;
    }
    this.setState({
      bioMetricEmpMapList: this.state.bioMetricEmpMapList,
    });
  };

  onhandleBioDevice = (data) => (event) => {
    const { key, i } = data;
    this.setState({ [key]: event });
    if (data.key == data.i + 1) {
      this.state.bioMetricEmpMapList[i].empBioDeviceId = event;
    }
    this.setState({
      bioMetricEmpMapList: this.state.bioMetricEmpMapList,
    });
  };
  onhandleActive = (data) => (event) => {
    const { key, i } = data;
    this.setState({ [key]: event });
    if (data.key == data.i + 1) {
      this.state.bioMetricEmpMapList[i].activeStatus = event;
    }
    this.setState({
      bioMetricEmpMapList: this.state.bioMetricEmpMapList,
    });
  };
  handleSelectBioMetric = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    i
  ) => {
    if (name === selectedRow.id) {
      selectedRow.bioDeviceId = selectedValue;
    }
    var tabdata = this.state.bioMetricEmpMapList.filter((row) => {
      if (row.id == name) {
        row = selectedRow;
      }
      return row;
    });
    this.setState({ bioMetricEmpMapList: tabdata });
  };
  handleAddRow = () => {
    var tableDataAdd = [];
    this.state.bioMetricEmpMapList.map((row) => {
      tableDataAdd.push(row);
    });
    const res = {
      id: this.state.bioMetricEmpMapList.length + 1,
      bioDeviceId: "",
      companyId: localStorage.getItem("companyId"),
      createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
      ebId: "",
      empBioDeviceId: "",
      empRFNo: "",
      userId: JSON.parse(localStorage.getItem("authUser")).userId,
      activeStatus: "",
      type: true,
    };
    tableDataAdd.push(res);
    this.setState({
      bioMetricEmpMapList: tableDataAdd,
    });
  };
  handleDeleteRow = (id) => {
    this.props.deleteBioMetriDevice(
      serverApi.DELETE_BIOMETRIC_DEVICE_ID +
        id +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).userId,
      this.props.history,
      this.props.getEbId
    );
  };
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <>
        <div className="educationalDetailsBlock">
          <h1>Bio Metric Device</h1>
          <div className="gridContainer">
            <div className="gridComponent">
              <table>
                <tr>
                  <th style={{ width: "20%" }}>Bio Metric Device</th>
                  <th style={{ width: "20%" }}>RF ID Card</th>
                  <th style={{ width: "20%" }}>Emp Biometric Id</th>
                  <th style={{ width: "20%" }}>Status</th>
                  <th style={{ width: "5%" }}>Actions</th>
                </tr>
                {this.state.bioMetricEmpMapList.map((data, i) => (
                  <tr key={i}>
                    <td>
                      <DynamicSelect
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        update={data.bioDeviceId ? 1 : 0}
                        arrayOfData={this.state.bio_metric_device_list}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectBioMetric}
                        selected={data.bioDeviceId}
                        row={data}
                        index={i}
                        name={data.id}
                        isDisabled={data.mappingId ? true : false}
                      />
                    </td>
                    <td>
                      <TextFieldNormal
                        value={data.empRFNo}
                        onChange={this.onhandleRfNo({
                          key: data.id,
                          i,
                        })}
                        disabled={data.mappingId ? true : false}
                      />
                    </td>
                    <td>
                      <TextFieldNormal
                        value={data.empBioDeviceId}
                        onChange={this.onhandleBioDevice({
                          key: data.id,
                          i,
                        })}
                        disabled={data.mappingId ? true : false}
                      />
                    </td>
                    <td>
                      <TextFieldNormal
                        value={data.activeStatus}
                        onChange={this.onhandleActive({
                          key: data.id,
                          i,
                        })}
                        disabled={true}
                      />
                    </td>
                    <td>
                      <Button
                        onClick={() => this.handleDeleteRow(data.mappingId)}
                        className="deleteButton"
                      >
                        <img
                          src={DeleteIcon}
                          alt=""
                          style={{ width: "35px", color: "red" }}
                        />
                      </Button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>

          <span className="addMoreAction" onClick={this.handleAddRow}>
            + Add more
          </span>

          <div className="consoleFormButtonBlock">
            <Link to="/add_employee">
              <CustomButton label={"Cancel"} className="greenBorderButton" />
            </Link>
            {localStorage.getItem("EmpData") === null ? (
              <CustomButton
                label={"Save"}
                className="greenButton"
                handleClick={this.AddBioMetricDevice}
                type="sumbit"
              />
            ) : (
              <CustomButton
                label={"Update"}
                className="greenButton"
                handleClick={this.AddBioMetricDevice}
                type="sumbit"
              />
            )}
          </div>
        </div>

        <div className="shiftTiming">
          <h1>Shift Timings</h1>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectNew
                        update={this.state.shift_name ? 1 : 0}
                        arrayOfData={
                          this.state.Shifts
                            ? this.state.Shifts.map((item) => ({
                                label: item.label,
                                value: item.value,
                                name: item.label,
                              }))
                            : ""
                        }
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.state.shift_name}
                        name="shift"
                        label="Shift Structure"
                      />
                    </div>
                  </Box>
                </Box>
                <>
                  {this.state.shiftList_display ? (
                    <div className="gridContainer">
                      <div className="gridComponentNoBorder">
                        <table className="table">
                          <thead>
                            <tr>
                              {Columns.map((item, i) => (
                                <th scope="col" key={i}>
                                  {item.Header}
                                </th>
                              ))}
                            </tr>
                          </thead>

                          <tbody>
                            {this.state.spellMasterListData
                              ? this.state.spellMasterListData.map(
                                  (item, i) => (
                                    <>
                                      <tr>
                                        <td>
                                          <span>{item.spellCode}</span>
                                        </td>
                                        <td>
                                          <span>{item.spellName}</span>
                                        </td>
                                        <td>
                                          <span>{item.workingHours} Hrs.</span>
                                        </td>
                                        <td>
                                          <span>{item.startingTime}</span>
                                        </td>
                                        <td>
                                          <span>{item.endTime}</span>
                                        </td>
                                        <td>
                                          <span>{item.lateMinutes2}</span>
                                        </td>
                                        <td>
                                          <span>{item.minimumWorkHours}</span>
                                        </td>
                                        <td>
                                          <span>{item.halfdayWorkHours}</span>
                                        </td>
                                        <td>
                                          <span>{item.weekOffDay}</span>
                                        </td>
                                        <td>
                                          <span>{item.weekOffDay2}</span>
                                        </td>
                                        <td>
                                          <span>{item.weekOffHalfday}</span>
                                        </td>
                                        <td>
                                          <span>
                                            {item.isOvernight == 0
                                              ? "NO"
                                              : "YES"}
                                          </span>
                                        </td>
                                      </tr>
                                    </>
                                  )
                                )
                              : []}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              </Grid>
            </Grid>
          </div>

          <div className="consoleFormButtonBlock">
            <Link to="/add_employee">
              <CustomButton label={"Cancel"} className="greenBorderButton" />
            </Link>
            {localStorage.getItem("EmpData") === null &&
            !this.state.empShiftMapId ? (
              <CustomButton
                label={"Save"}
                className="greenButton"
                handleClick={this.onClickNext}
                type="sumbit"
              />
            ) : (
              <CustomButton
                label={"Update"}
                className="greenButton"
                handleClick={this.onClickUpdate}
                type="sumbit"
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
const Columns = [
  {
    Header: "Spell Code",
    accessor: "spellCode",
    hidden: false,
  },
  {
    Header: "Spell Name",
    accessor: "spellName",
    hidden: false,
  },
  {
    Header: "Working Hours",
    accessor: "workingHours",
    hidden: false,
  },
  {
    Header: "Starting Time",
    accessor: "startingTime",
    hidden: false,
  },
  {
    Header: "End Time",
    accessor: "endTime",
    hidden: false,
  },
  {
    Header: "Late Minutes2",
    accessor: "lateMinutes2",
    hidden: false,
  },
  {
    Header: "Minimum Working Hours",
    accessor: "minimumWorkHours",
    hidden: false,
  },
  {
    Header: "Half Day Work Hours",
    accessor: "halfdayWorkHours",
    hidden: false,
  },
  {
    Header: "Week Off Day",
    accessor: "weekOffDay",
    hidden: false,
  },
  {
    Header: "Week Off Day 2",
    accessor: "weekOffDay2",
    hidden: false,
  },
  {
    Header: "Week Off Half Day",
    accessor: "weekOffHalfday",
    hidden: false,
  },
  {
    Header: "Night Shift ",
    accessor: "isOvernight",
    hidden: false,
  },
];
const mapStatetoProps = (state) => {
  const { spell } = state.AttendanceReducer;
  const { SpellMaster_List } = state.SpellMasterListReducer;
  const { personalDetails, getEbId } = state.EmployeeReducer;
  const {
    shift_master,
    bio_metric_device,
    GetWorker_DetailsById,
    ShiftMasterViewById_data,
  } = state.ShiftMasterReducer;

  return {
    spell,
    personalDetails,
    ShiftMasterViewById_data,
    shift_master,
    SpellMaster_List,
    getEbId,
    bio_metric_device,
    GetWorker_DetailsById,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSpell,
    setShiftMaster,
    ViewByIdShiftMaster,
    getSpellMasterList,
    getBioMetriDevice,
    createBioMetriDevice,
    getWorkerDetailsById,
    deleteBioMetriDevice,
  })(ShiftTiming)
);
