import {
  BROKER_MASTER_LIST,
  CREATE_BROKER_MASTER,
  UPDATE_BROKER_MASTER,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { postAdd, getList } from "../../../helpers/Server_Helper";
import {
  brokerMasterListSuccessfull,
  createBrokerMasterSuccessfull,
  updateBrokerMasterSuccessfull,
} from "./actions";
import swal from "sweetalert";

function* BrokerMasterList({ payload: { url, data } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(brokerMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateBrokerMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createBrokerMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/broker_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateBrokerMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateBrokerMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/broker_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

export function* watchBrokerMasterList() {
  yield takeEvery(BROKER_MASTER_LIST, BrokerMasterList);
}
export function* watchCreateBrokerMaster() {
  yield takeEvery(CREATE_BROKER_MASTER, CreateBrokerMaster);
}
export function* watchUpdateBrokerMaster() {
  yield takeEvery(UPDATE_BROKER_MASTER, UpdateBrokerMaster);
}

function* BrokerMasterSaga() {
  yield all([
    fork(watchBrokerMasterList),
    fork(watchCreateBrokerMaster),
    fork(watchUpdateBrokerMaster),
  ]);
}

export default BrokerMasterSaga;
