import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomButton from "../../../../components/Buttons/Buttons";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import TickIcon from "../../../../assets/images/fileUploadedTick.png";
import { getFileDownload } from "../../../../helpers/server.js";
import { Box, Tooltip,Grid } from "@mui/material";
import Fade from "@mui/material/Fade";
import { serverConfig } from "../../../../helpers/Consts";
import { serverApi, getTenantId } from "../../../../helpers/Consts";
import PrintIcon from "@mui/icons-material/Print";
import { isEmpty } from "../../../../Utilities/helper";

const RegularPreview = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [gstNo1, setGstNo1] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [phoneNo, setphoneNo] = useState("");
  const [email, setemail] = useState("");
  const [companyLogo, setcompanyLogo] = useState("");
  const [currentStep, setcurrentStep] = useState(1);
  const [currentDate, setcurrentDate] = useState(new Date().toLocaleString());
  const [editButton, seteditButton] = useState(true);
  const [LineItems, setLineItems] = useState([]);
  const [HeaderData, setHeaderData] = useState({});
  const { InvoiceFiles } = useSelector((state) => state.InvoiceReducer);
  const [invoiceFiles, SetInvoiceFiles] = useState(
    InvoiceFiles?.data?.data || []
  );
  useEffect(() => {
    SetInvoiceFiles(InvoiceFiles?.data?.data);
  }, [InvoiceFiles]);


  useEffect(() => {
    let companyData = JSON.parse(localStorage.getItem("authUser"));
    let companyId = localStorage.getItem("companyId");
    companyData.companyDetails.map((p) => {
      let id = p.value;
      if (companyId === id.toString()) {
        setcompanyName(p.label);
        setcompanyLogo(p.companyLogo);
        setphoneNo(p.contactNo);
        setemail(p.emailId);
        setGstNo1(p.gstNo)
        setaddress1(p.address1);
        setaddress2(p.address2);
      }
    });
  }, []);

  const { JuteGovtInvoiceLineItems, juteGovtInvoiceHeader } = useSelector(
    (state) => state.InvoiceReducer
  );
  useEffect(() => {
    setLineItems(JuteGovtInvoiceLineItems);
    setHeaderData(juteGovtInvoiceHeader);
  }, [JuteGovtInvoiceLineItems, juteGovtInvoiceHeader]);
  const convertNumberToWords = (amount) => {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();

    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = "";
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  };

  const handleEdit = () => {
    props.handleCurrentStep(2);
  };

  const withDecimal = (n) => {
    if (n !== undefined) {
      var nums = n.toString().split(".");
      var whole = convertNumberToWords(nums[0]);

      if (nums.length === 2) {
        var decimal = convertNumberToWords(nums[1]);
        if (parseInt(nums[1]) > 0) {
          return whole + " Rupees and " + decimal + " Paise only";
        }
      }

      return whole + " Rupees only";
    }
  };
  const handlePdf = (printType) => {
    if (props.locationState.state !== undefined) {
      fetch(
        serverApi.REGULAR_INVOICE_PDF_DOWNLOAD +
          "2/" +
          HeaderData.regInvoiceId +
          "/" +
          JSON.parse(localStorage.getItem("authUser")).userId +
          "/" +
          printType,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            "X-TenantId": getTenantId(window.location.hostname),
            Authorization:
              localStorage.getItem("token_type") +
              localStorage.getItem("access_token"),
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", HeaderData.invoiceNo + ".pdf");
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
    }
  };
  const handleEWayBill = (printType) => {
  if(props.locationState.state !== "undefined") {
    fetch(
      serverApi.REGULAR_INVOICE_EWAY_BILL_PDF_DOWNLOAD +
        "2/" +
        juteGovtInvoiceHeader.regInvoiceId +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).userId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          "X-TenantId": getTenantId(window.location.hostname),
          Authorization:
            localStorage.getItem("token_type") +
            localStorage.getItem("access_token"),
        },
      }
    ).then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", HeaderData.invoiceNo + ".pdf");
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  }
  }

  let filterProduct = LineItems.filter(
    (item) =>
      item.isActive != 0 &&
      item.itemGroupId !== null &&
      item.itemGroupId !== "" &&
      item.itemGroupValue !== "997"
  );
  let otherChargesItems = LineItems.filter(
    (item) => item.isActive !== 0 && item.itemGroupValue === "997"
  );

  if (
    HeaderData.salesOrderDate !== null &&
    HeaderData.salesOrderDate !== "" &&
    HeaderData.salesOrderDate !== undefined
  ) {
    var salesOrderDate = moment(
      HeaderData.salesOrderDate,
      "YYYY-MM-DD",
      true
    ).format("DD-MM-YYYY");
  }
  if (
    HeaderData.invoiceDate !== null &&
    HeaderData.invoiceDate !== "" &&
    HeaderData.invoiceDate !== undefined
  ) {
    var invoiceDate = moment(HeaderData.invoiceDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
  }
  if (
    HeaderData.deliveryDate !== null &&
    HeaderData.deliveryDate !== "" &&
    HeaderData.deliveryDate !== undefined
  ) {
    var deliveryDate = moment(
      HeaderData.deliveryDate,
      "YYYY-MM-DD",
      true
    ).format("DD-MM-YYYY");
  }
  if (
    HeaderData.createdDate !== null &&
    HeaderData.createdDate !== "" &&
    HeaderData.createdDate !== undefined
  ) {
    var createdDate = moment(HeaderData.createdDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
  }
  if (
    HeaderData.pcsoDate !== null &&
    HeaderData.pcsoDate !== "" &&
    HeaderData.pcsoDate !== undefined
  ) {
    var pcsoDate = moment(HeaderData.pcsoDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
  }
  if (
    HeaderData.updatedOn !== null &&
    HeaderData.updatedOn !== "" &&
    HeaderData.updatedOn !== undefined
  ) {
    var updatedOn = moment(HeaderData.updatedOn, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
  }
  if (
    HeaderData.ammendmentDate !== null &&
    HeaderData.ammendmentDate !== "" &&
    HeaderData.ammendmentDate !== undefined
  ) {
    var ammendmentDate = moment(
      HeaderData.ammendmentDate,
      "YYYY-MM-DD",
      true
    ).format("DD-MM-YYYY");
  }
  return (
    <>
      <div className="previewBlock">
        <div
          style={{ display: "flex", justifyContent: "end", padding: "10px" }}
        >
           {props.locationState.state &&
            juteGovtInvoiceHeader.statusId !== "" &&
            !isEmpty(juteGovtInvoiceHeader.ewayBillNo) && (
              <CustomButton
                label="e-way Bill Copy Print"
                className="lightBlueButton"
                muIcon={<PrintIcon />}
                handleClick={() => {
                  handleEWayBill(1);
                }}
                type="button"
              ></CustomButton>
            )}


          {props.locationState.state &&
            HeaderData.statusId !== "" &&
            !isEmpty(HeaderData.brokerId) && (
              <CustomButton
                label="Broker Copy Print"
                className="lightBlueButton"
                muIcon={<PrintIcon />}
                handleClick={() => {
                  handlePdf(2);
                }}
                type="button"
              ></CustomButton>
            )}
          {props.locationState.state &&
            HeaderData.statusId !== "" &&
            !isEmpty(HeaderData.customerId) && (
              <CustomButton
                label="Consignee Copy Print"
                className="lightBlueButton"
                muIcon={<PrintIcon />}
                handleClick={() => {
                  handlePdf(1);
                }}
                type="button"
              ></CustomButton>
            )}
          {HeaderData.EditButton && (
            <CustomButton
              label="Edit"
              handleClick={handleEdit}
              type="button"
              className="lightBlueButton"
              muIcon={<EditIcon />}
            ></CustomButton>
          )}
        </div>
        <table
          cellpadding="0"
          cellspacing="0"
          width="90%"
          className="previewTable"
        >
          <tbody>
            <tr>
              <td
                colspan="5"
                style={{ borderRight: "none", borderBottom: "none" }}
              >
                <img src={companyLogo} width="100%" alt="" />
              </td>
              <td
                colspan="10"
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  textAlign: "right",
                }}
              >
               <h2>{companyName}</h2>
                  {address1}
                  <br/>
                  {address2}
                  <br/>
                  <span><b>Ph.No: </b>{phoneNo}, </span>
                  <span><b>Email: </b> {email}</span>
                  <br/>
                  <b>GST No:</b> {gstNo1 || "n/a"}
                  <br/>
                <br></br>
              </td>
            </tr>
            <tr>
              <td
                colspan="12"
                style={{ textAlign: "center", borderTop: "none" }}
              >
                <h2>INVOICE</h2>
              </td>
            </tr>

            <tr>
              <td colspan="2">
                <b>Invoice NO : </b>
              </td>
              <td
                colspan="2"
                className={HeaderData.invoiceNo == "" ? "blur-text" : ""}
              >
                {HeaderData.invoiceNo == ""
                  ? "Will be generated after creation"
                  : HeaderData.invoiceNo}
              </td>
              <td colspan="3">
                <b>Invoice Date :</b>
              </td>
              <td colspan="5">{invoiceDate}</td>
            </tr>

            <tr>
              <td colspan="2">
                <b>Delivery Order No:</b>
              </td>
              <td
                colspan="2"
                // className={HeaderData.deliveryOrderNo == "" ? "blur-text" : ""}
              >
                {/* {HeaderData.deliveryOrderNo == ""
                  ? "Will be generated after creation"
                  : HeaderData.deliveryOrderNo} */}
                {HeaderData.deliveryOrderNo}
              </td>
              <td colspan="3">
                <b>Delivery Order Date :</b>
              </td>

              <td colspan="5">{deliveryDate}</td>
            </tr>
            <tr>
              <td colspan="2">
                <b>Sales Order No:</b>
              </td>
              <td
                colspan="2"
                // className={HeaderData.salesOrderNo == "" ? "blur-text" : ""}
              >
                {/* {HeaderData.salesOrderNo == ""
                  ? "Will be generated after creation"
                  : HeaderData.salesOrderNo} */}
                {HeaderData.salesOrderNumber}
              </td>
              <td colspan="3">
                <b>Sales Order Date :</b>
              </td>

              <td colspan="5">{salesOrderDate}</td>
            </tr>

            <tr>
              <td colspan="2">
                <b>PCSO No:</b>
              </td>
              <td colspan="2">{HeaderData.pcsoNo}</td>
              <td colspan="3">
                <b>PCSO Date :</b>
              </td>

              <td colspan="5">{pcsoDate}</td>
            </tr>
            <tr>
              <td colspan="2">
                <b>Consignee :</b>
              </td>
              <td colspan="2">{HeaderData.customerName}</td>
              <td colspan="3">
                <b>Branch : </b>
              </td>

              <td colspan="5">{HeaderData.branchName}</td>
            </tr>
            <tr>
              <td colspan="2">
                <b>Amendment No :</b>
              </td>
              <td colspan="2">{HeaderData.ammendmentNo}</td>
              <td colspan="3">
                <b>Amendment Date : </b>
              </td>

              <td colspan="5">{ammendmentDate}</td>
            </tr>
            <tr>
              <td
                colspan="4"
                style={{
                  borderTop: "none",
                  borderBottom: "none",
                  padding: "none",
                }}
              >
                <br />
                <b> Billed To :{"  "}</b>
                <span>
                  {" "}
                  {HeaderData.customerName}-
                  <br />
                  {HeaderData.billingAddress}{" "}
                </span>
                <br />
                <br />
                <b>
                  GSTIN : {"  "} </b>
                  <span>{HeaderData.billedToGstin}</span>
               
                <br />
                <b>
                  Contact Person : {"  "}</b>
                  <span> {HeaderData.billedToContactPerson}</span>
                
                <br />
                <b>
                  Contact Number : {"  "} </b>
                  <span> {HeaderData.billedToContactNumber}</span>
               
                <br />
                <b>
                  EMAIL ID : {"  "}  </b>
                  <span>{HeaderData.billedToEmail}</span> 
               
              </td>
              <td
                colspan="8"
                style={{
                  borderTop: "none",
                  borderBottom: "none",
                  padding: "none",
                }}
              >
                <br />
                <b> Shipped To :{"  "}</b>
                <span>
                  {" "}
                  {HeaderData.customerName}-
                  <br />
                  {HeaderData.shippingAddress}
                </span>
                <br />
                <br />
                <b>GSTIN : </b>  <span>{HeaderData.shippedToGstin}</span>
                <br />
                <b>
                  Contact Person : {"  "} </b><span>{HeaderData.shippedToContactPerson}</span> 
               
                <br />
                <b>
                  Contact Number :{"  "}
                  </b>
                  <span> {HeaderData.shippedToContactNumber}</span>
              
                <br />
                <b>EMAIL ID :</b> <span>{HeaderData.shippedToEmail}</span> 
              </td>
              {/* <td
                colspan="6"
                style={{
                  borderTop: "none",
                  borderBottom: "none",
                  padding: "none",
                }}
              >
                <br />
                <b>Transporter Name :</b> {HeaderData.transporterName}
                <br />
                <br />
                <b> Transport :{"  "}</b>
                <span> {HeaderData.transporterAddress}</span>
                <br />
                <b>Vehicle No: </b> {"  "} {HeaderData.vehicleNumber} <br />
              </td> */}
            </tr>
            <tr>
              <td rowSpan="2" width={"2%"}>
                <b>S.No</b>
              </td>
              <td rowSpan="2" align="center" width={"8%"}>
                <b>Item CODE</b>
              </td>
              <td rowSpan="2" align="center" width={"30%"}>
                <b>Item Name</b>
              </td>
              <td rowSpan="2" align="center" width={"15%"}>
                <b>Quantity</b>
              </td>

              <td rowSpan="2" align="center" width={"10%"}>
                <b>RATE</b>
              </td>

              <td rowSpan="2" align="center" width={"7%"}>
                <b>Amount</b>
              </td>
              <td colSpan="2" align="center" width={"10%"}>
                <b>IGST</b>
              </td>
              <td colSpan="2" align="center" width={"10%"}>
                <b>CGST</b>
              </td>
              <td colSpan="2" align="center" width={"10%"}>
                <b>SGST</b>
              </td>
            </tr>
            <tr>
              <td>
                <b>%</b>
              </td>
              <td>
                <b>Amt</b>
              </td>
              <td>
                <b>%</b>
              </td>
              <td>
                <b>Amt</b>
              </td>
              <td>
                <b>%</b>
              </td>
              <td>
                <b>Amt</b>
              </td>
            </tr>
            {filterProduct &&
              filterProduct.map((item, i) => {
                return (
                  <>
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item.itemCode}</td>
                      <td>
                        {item.itemName}
                        <br />
                        <span>
                          <b>Remarks :</b> {item.remarks}
                        </span>{" "}
                        <br />
                        <span>
                          <b>Make :</b> {item.itemMake}
                        </span>{" "}
                        <br />
                        <span>
                          <b>HSN Code :</b> {item.hsncode}
                        </span>
                      </td>
                      <td align="right">
                        {parseFloat(item.quantity).toFixed(3)}- {item.uom}{" "}
                        <br />
                        {item.govtInvoiceQtyCalculatedValue !== 0 && (
                          <>
                            {parseFloat(
                              item.govtInvoiceQtyCalculatedValue
                            ).toFixed(3)}{" "}
                            - {item.govtInvoiceQuantityUom}
                            <br />
                          </>
                        )}
                        {item.govtInvoiceQtyCalculatedValue2 !== 0 && (
                          <>
                            {parseFloat(
                              item.govtInvoiceQtyCalculatedValue2
                            ).toFixed(3)}{" "}
                            - {item.govtInvoiceQuantityUom2}
                            <br />
                          </>
                        )}
                         {item.govtInvoiceQtyCalculatedValue3 !== 0 && (
                          <>
                            {parseFloat(
                              item.govtInvoiceQtyCalculatedValue3
                            ).toFixed(3)}{" "}
                            - {item.govtInvoiceQuantityUom3}
                            <br />
                          </>
                        )}
                      </td>
                      <td align="right">
                        {item.rate} - RS/{item.uom}
                        <br />
                        {item.govtInvoiceRateCalculatedValue2 !== "" && (
                          <>
                            {parseFloat(
                              item.govtInvoiceRateCalculatedValue2
                            ).toFixed(3)}{" "}
                            - RS/{item.govtInvoiceRateUom2}
                            <br />
                          </>
                        )}
                      </td>

                      <td align="right">
                        {parseFloat(item.netAmount).toFixed(2)}
                      </td>
                      <td align="right">
                        {parseFloat(item.igstPercent).toFixed(2)}
                      </td>
                      <td align="right">
                        {parseFloat(item.igstAmount).toFixed(2)}{" "}
                      </td>
                      <td align="right">
                        {parseFloat(item.cgstPercent).toFixed(2)}
                      </td>
                      <td align="right">
                        {parseFloat(item.cgstAmount).toFixed(2)}
                      </td>
                      <td align="right">
                        {parseFloat(item.sgstPercent).toFixed(2)}
                      </td>
                      <td align="right">
                        {parseFloat(item.sgstAmount).toFixed(2)}
                      </td>
                    </tr>
                  </>
                );
              })}
            {otherChargesItems &&
              otherChargesItems.map((item) => {
                return (
                  <tr>
                    <td colspan="3">
                      <b> {item.itemName}</b>
                    </td>
                    <td colspan="13">{item.netAmount}</td>
                  </tr>
                );
              })}
            <tr>
              <td colspan="3">
                <b>Taxable Value</b>
              </td>
              <td colspan="13">{parseFloat(HeaderData.netTotal).toFixed(2)}</td>
            </tr>
            <tr>
              <td colspan="3">
                <b>Total IGST</b>
              </td>
              <td colspan="13">
                {parseFloat(HeaderData.totalIGST).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <b>Total CGST</b>
              </td>
              <td colspan="13">
                {parseFloat(HeaderData.totalCGST).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <b>Total SGST</b>
              </td>
              <td colspan="13">
                {parseFloat(HeaderData.totalSGST).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <b>Total Value with Tax</b>
              </td>
              <td colspan="13">
                {parseFloat(HeaderData.TotalAmount).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <b>Total Amount in words</b>
              </td>
              <td colspan="13">
                {withDecimal(parseFloat(HeaderData.TotalAmount).toFixed(2))}
              </td>
            </tr>
            <tr>
              <td
                colspan="9"
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderBottom: "none",
                  marginTop: "5px",
                }}
              >
                 <br />
                <b>Bank Details:</b>
                <br />
                <b>{companyName}</b>
                <br />
                 <b> Bank:{"  "} 
                  </b>{HeaderData.bankName}
                  <br />
                  <b> Branch: {"  "} 
                  </b>{HeaderData.bankBranch}
                  <br />
                  <b> A/c No:{"  "} 
                  </b>{HeaderData.accountNo}
                  <br />
                  <b> IFSC Code:{"  "} 
                  </b>{HeaderData.ifscCode}
                  <br />
                  <b> MICR No:{"  "} 
                  </b>{HeaderData.micrCode}
                  <br />
                  <br />
                <br />
                <b>Delivery Terms: </b> {HeaderData.deliveryTerms}
                <br />
                <b>Payment Terms (In Days): </b> {HeaderData.paymentTerms}
                <br />
                <b>Footer Notes: </b> {HeaderData.footerNote}
                <br />
                <b>Internal Notes: </b> {HeaderData.internalNote}
                <br />
                <br />
                <tr>
                    <td
                      colSpan="10"
                      style={{
                        borderTop: "none",
                        borderBottom: "none",
                        borderRight: "none",
                        borderLeft: "none",
                      }}
                    >
                {props.locationState.state !== undefined ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box gridColumn="span 12">
                            <span>
                              <b>Support Documents:</b>
                            </span>
                          </Box>
                          <Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={2}
                            className="ducumentUploadBlock uploadedContent"
                            style={{ padding: "10px" }}
                          >
                            {invoiceFiles?.length > 0 &&
                              invoiceFiles.map((item) => (
                                <Box gridColumn="span 6">
                                <div
                                  className="documentUploadContent"
                                  key={item.fileUploadId}
                                >
                                  <div
                                    className="uploadedInfo"
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <img
                                      src={TickIcon}
                                      alt=""
                                      className="tickIcon"
                                      style={{
                                        height: "16px",
                                        width: "16px",
                                        marginRight: "8px",
                                      }}
                                    />
                                    <Tooltip
                                      TransitionComponent={Fade}
                                      TransitionProps={{ timeout: 600 }}
                                      title="Click to download File"
                                    >
                                      <div
                                        className="uplodedFileName"
                                        onClick={() => {
                                          getFileDownload(
                                            serverConfig.SERVER_URL +
                                              "security-api/api/files/downloadfile/" +
                                              item.fileUploadId,
                                            item.fileName,
                                            item.fileExtension
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.fileName}
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                                </Box>
                              ))}
                          </Box>
                        </Grid>
                      </Grid>
                   
                ) : (
                  ""
                )}
                 </td>
                  </tr>
                <b>Terms and Conditions: </b> {HeaderData.termsCondition}
                <br />
                <br />
                {props.locationState.state !== undefined ? (
                  <>
                    <b>Last Modified On: </b> {updatedOn}
                  </>
                ) : (
                  ""
                )}
                <br />
                {props.locationState.state !== undefined ? (
                  <>
                    <b>Last Modified By: </b> {HeaderData.updatedBy}
                  </>
                ) : (
                  ""
                )}
                <br />
                {props.locationState.state !== undefined ? (
                  <b>Status: {HeaderData.statusName}</b>
                ) : (
                  ""
                )}
                <br />
                <br />
              </td>
              {/* <td
                colspan="9"
                align="right"
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "none",
                }}
              >
                Authorised Signature
              </td> */}
            </tr>

            {/* <tr>
              <td
                colspan="4"
                style={{ borderTop: "none", borderRight: "none" }}
              >
                Printed on : {createdDate}
              </td>

              <td colspan="12"
                style={{ borderTop: "none", borderRight: "none" ,borderLeft: "none",}}>
                 This is Computer generated Print,Signature is not required
              </td>
              <td
                colspan="3"
                style={{ borderTop: "none", borderRight: "none" ,borderLeft: "none"}}
                align="right"
              >
                Printed By:
                {JSON.parse(localStorage.getItem("authUser")).userId}
              </td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RegularPreview;
