import {QUALITY_MASTER_LIST,QUALITY_MASTER_LIST_SUCCESSFULL,
    CREATE_QUALITY_MASTER,CREATE_QUALITY_MASTER_SUCCESSFULL,
    UPDATE_QUALITY_MASTER,UPDATE_QUALITY_MASTER_SUCCESSFULL,
    QUALITY_MASTER_VIEW_BY_ID,QUALITY_MASTER_VIEW_BY_ID_SUCCESSFULL,
    API_ERRORS,
    JUTE_QUALITIES_LIST,
    JUTE_QUALITIES_LIST_SUCCESSFULL,} from './actionTypes';

export const qualityMasterList = (url,data,history) =>{
    return {
        type:QUALITY_MASTER_LIST,
        payload:{url,data,history}
    }
}
export const qualityMasterListSuccessfull = (data)=>{
    return {
        type:QUALITY_MASTER_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createQualityMaster = (url, data, history) => {
    return {
        type: CREATE_QUALITY_MASTER,
        payload: { url, data, history }
    }
}

export const createQualityMasterSuccessfull = (data) => {
    return {
        type: CREATE_QUALITY_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const updateQualityMaster = (url, data, history) => {
    return {
        type: UPDATE_QUALITY_MASTER,
        payload: { url, data, history }
    }
}

export const updateQualityMasterSuccessfull = (data) => {
    return {
        type: UPDATE_QUALITY_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const getQualityMasterViewById = (url,data,history) =>{
    
    return {
        type: QUALITY_MASTER_VIEW_BY_ID,
        payload:{url,data,history}
    }
}

export const getQualityMasterViewByIdSuccessfull = (data)=>{
    return {
        type: QUALITY_MASTER_VIEW_BY_ID_SUCCESSFULL,
        payload:data
    }
}
export const getJuteQualityMasterListValues = (url,data,history) =>{
    return {
        type: JUTE_QUALITIES_LIST,
        payload:{url,data,history}
    }
}

export const getJuteQualityMasterListValuesSuccessfull = (data)=>{
    return {
        type: JUTE_QUALITIES_LIST_SUCCESSFULL,
        payload:data
    }
}
export const apiErrors = (error) => {
    return {
        type: API_ERRORS,
        payload: error,
    };
};