//import { Switch } from "react-router-dom";

import {
    API_ERRORS,
    CREATE_WORK_ORDER,
    CREATE_WORK_ORDER_SUCCESSFULL,
    UPDATE_PO_WO,
    UPDATE_PO_WO_SUCCESSFULL,
    WORK_ORDER_LIST,
    WORK_ORDER_LIST_SUCCESSFULL,
  } from "./actionTypes";
  
  const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    workOrder_List: [],
    CreateWorkOrder:[],
    error_msg: null,
    updatePoWo:[]
  };
  
  const WorkOrderReducer = (state = initialState, action) => {
    switch (action.type) {
      case WORK_ORDER_LIST:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case WORK_ORDER_LIST_SUCCESSFULL:
        state = {
          ...state,
          workOrder_List: action.payload.response,
          loading: false,
        };
        break;
        case CREATE_WORK_ORDER:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case CREATE_WORK_ORDER_SUCCESSFULL:
        state = {
          ...state,
          CreateWorkOrder: action.payload.response,
          loading: false,
        };
        break;
        
    case UPDATE_PO_WO:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_PO_WO_SUCCESSFULL:
      state = {
        ...state,
        updatePoWo: action.payload.response,
        loading: true,
      };
      break;
        case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
      default:
        state = { ...state };
        break;
    }
  
    return state;
  };
  
  export default WorkOrderReducer;
  