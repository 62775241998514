export const SUPPLIER_MASTER_LIST = "supplier_master_list";
export const SUPPLIER_MASTER_LIST_SUCCESSFULL = "supplier_master_list_successfull";
export const SUPPLIER_LINE_ITEMS = "supplier_lineitmes";
export const SUPPLIER_FILLDETAILS = "supplier_filldetails";
export const SUPPLIER_MASTER_VIEW_BY_ID = "supplier_master_view_by_id";
export const SUPPLIER_MASTER_VIEW_BY_ID_SUCCESSFULL = "supplier_master_view_by_id_successfull";
export const UPDATE_SUPPLIER_MASTER = "update_supplier_master";
export const UPDATE_SUPPLIER_MASTER_SUCCESSFULL = "update_supplier_master_successfull";
// supplier master
export const CREATE_SUPPLIER_MASTER = "create_supplier_master";
export const CREATE_SUPPLIER_MASTER_SUCCESSFULL = "create_supplier_master_successfull";
export const API_ERRORS = 'api_failed';

export const GET_ALL_FILES='get_all_files';
export const GET_ALL_FILES_SUCCESSFULL='get_all_files_successfull';
export const CLEAR_ALL_FILES='clear_all_files';
export const DELETE_SUPPLIER_FILES='delete_supplier_files';
export const DELETE_SUPPLIER_FILES_SUCCESSFULL='delete_supplier_files-successfull';
export const UPLOAD_SUPPLIER_FILES='upload_supplier_files';
export const UPLOAD_SUPPLIER_FILES_SUCCESSFULL='upload_supplier_files_successfull';

export const SUPPLIER_TYPES_LIST='supplier_types_list';
export const SUPPLIER_TYPES_LIST_SUCCESSFULL='supplier_types_list_successfull';

export const GET_ALL_ENTITY_TYPES ="get_all_entity_types";
export const GET_ALL_ENTITY_TYPES_SUCCESSFULL ="get_all_entity_types_successfull";

export const SUPPLIER_GST_VERIFICATION ="supplier_gst_verification"
export const SUPPLIER_GST_VERIFICATION_SUCCESSFULL ="supplier_gst_verification_successfull"



