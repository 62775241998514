import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Box, TextField, InputAdornment, Button } from "@mui/material";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { getRegularDeliveryOrderHeader } from "../../../store/Sales/DeliveryOrder/actions";
import { getTermsConditions } from "../../../store/Sales/SalesOrder/actions";
import { serverApi } from "../../../helpers/Consts";
import DynamicSelect from "../../../components/Dropdown/DropdownSelectNew";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";


const RegularTransportation = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [ HeaderData, setHeaderData] = useState({});
  const [ isDropdownOpen, setisDropdownOpen] = useState(false);
  const [ TermsData, setTermsData] = useState([]);
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const { RegularDeliveryOrderHeader } = useSelector((state) => state.DeliveryOrderReducer);
  const { TermsConditions } = useSelector((state) => state.SalesOrderReducer);

  useEffect(() => {
    if (TermsConditions) {
      if (TermsConditions.length !== 0) {
        var list = [];
        TermsConditions.map((item) => {
          list.push({
            value: item.autoId,
            label: item.termsAndConditions,
            name: item.termsAndConditions,
          });
        });
      }
      setTermsData(list);
    }
  }, [TermsConditions]);
useEffect(()=>{
  setHeaderData(RegularDeliveryOrderHeader);
},[RegularDeliveryOrderHeader])

  useEffect(() => {
    const data = {
      orgId: userDit.orgId,
      autoId: "",
      termsAndConditions: "",
      startIndex:"",
      lastIndex:"",
    };
    dispatch(
    getTermsConditions(
      serverApi.GET_ALL_TERMS_LIST,data,
      history
    )
    )
  }, []);

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };

  const handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name == "termsCondition") {
      setHeaderData((prev) => ({
        ...prev,
        termsCondition: selectedName,
        termsConditionType:selectedValue
      }));
      const UpdatedRec = {
        ...HeaderData,
        termsCondition: selectedName ,
        termsConditionType:selectedValue
      };
      dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
    }
  };

  const onhandlechange = (key) => (event) => {

    if (key === "footerNote") {
      setHeaderData((prev) => ({
        ...prev,
        footerNote: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        footerNote: event,
      };
      dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
    }
    if (key === "deliveryDays") {
      setHeaderData((prev) => ({
        ...prev,
        deliveryDays: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        deliveryDays: event,
      };
      dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
    }
    if (key === "paymentTerms") {
      setHeaderData((prev) => ({
        ...prev,
        paymentTerms: event.target.value,
      }));
      const UpdatedRec = {
        ...HeaderData,
        paymentTerms: event.target.value,
      };
      dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
    }
    if (key === "otherCharges") {
      setHeaderData((prev) => ({
        ...prev,
        otherCharges: event,
      }));
    }
    if (key === "otherChargeAmt") {
      setHeaderData((prev) => ({
        ...prev,
        otherChargeAmt: event,
      }));
    }
    if (key === "internalNote") {
      setHeaderData((prev) => ({
        ...prev,
        internalNote: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        internalNote: event,
      };
      dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
    }
  };
  return (
    <>
      <div>
        <div className="consoleFormContainer">
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >

                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Footer Notes"
                      value={HeaderData.footerNote}
                      onChange={onhandlechange("footerNote")}
                      disabled={true}
                  
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      type="number"
                      label="Delivery Days"
                      value={HeaderData.deliveryDays}
                      onChange={onhandlechange("deliveryDays")}
                      disabled={true}
                    />
                  </Box>

                      <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>Payment Terms (In Days)</label>
                      </div>
                      <div className="consoleAdormentBlock">
                      <TextField
                          value={HeaderData.paymentTerms}
                          onChange={onhandlechange("paymentTerms")}
                          type="number"
                          disabled={true}
                          placeholder="Enter here"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={onclick}>Days</Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>  
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelectNew
                      update={HeaderData.termsCondition ? 1 : 0}
                      arrayOfData={TermsData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.termsCondition}
                      name="termsCondition"
                      label="Terms & Conditions"
                      isDisabled={true}
                    />
                  </Box>
                  {/* <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Other Charges Name"
                      value={HeaderData.otherCharges}
                      onChange={onhandlechange("otherCharges")}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Other Charge Amount"
                      value={HeaderData.otherChargeAmt}
                      onChange={onhandlechange("otherChargeAmt")}
                    />
                  </Box> */}
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Internal Note"
                      value={HeaderData.internalNote}
                      onChange={onhandlechange("internalNote")}
                      disabled={true}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};
export default RegularTransportation;
