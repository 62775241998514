//import { Switch } from "react-router-dom";

import {
  ADD_PRICE_ENQUIRY,
  ADD_PRICE_ENQUIRY_SUCCESSFUL,
  PRICE_ENQUIRY_LIST,
  PRICE_ENQUIRY_LIST_SUCCESSFULL,
  PRICE_ENQUIRY_LINEITEMS,
  GET_PRICE_ENQUIRY_BYID,
  GET_PRICE_ENQUIRY_BYID_SUCCESSFUL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  price_enquiry_List: [],
  addPriceEnquiry: [],
  error_msg: null,
  PELineitems: [
    {
      dtlId: 1,
      companyId: "",
      createdBy: "",
      indentNo: "",
      isRateActive: 1,
      isActive: 1,
      itemId: "",
      itemName: "",
      itemGroupCode: "",
      itemCode: "",
      suppCode: "",
      itemRate: "",
      suppComments: "",
      suppQuoteFile: "",
      suppResponseDateTime: "",
      itemDesc: "",
    },
  ],
  price_enquiry_ListBYID: [],
};

const PriceEnquiryReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRICE_ENQUIRY_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case PRICE_ENQUIRY_LIST_SUCCESSFULL:
      state = {
        ...state,
        price_enquiry_List: action.payload.response,
        loading: false,
      };
      break;
    case ADD_PRICE_ENQUIRY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_PRICE_ENQUIRY_SUCCESSFUL:
      state = {
        ...state,
        addPriceEnquiry: action.payload.response,
        loading: false,
      };
      break;

    case GET_PRICE_ENQUIRY_BYID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PRICE_ENQUIRY_LINEITEMS:
      state = {
        ...state,
        PELineitems: action.payload,
        loading: false,
      };
      break;
    case GET_PRICE_ENQUIRY_BYID_SUCCESSFUL:
      state = {
        ...state,
        price_enquiry_ListBYID: action.payload.response,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default PriceEnquiryReducer;
