import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextField from "../../../components/TextField/TextField";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import CustomButton from "../../../components/Buttons/Buttons";
import { serverApi } from "../../../helpers/Consts";
import { getClientList } from "../../../store/Global/DropDownApis/actions";
import {
  createProject,
  updateProject,
} from "../../../store/Purchase/Indent/actions";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import moment from "moment";
import swal from "sweetalert";
class CreateProjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      name: "",
      customerId: "",
      customerName: "",
      carpetArea: "",
      sft: "",
      address: "",
      expectedCloserDate: "",
      startDate: "",
      GST: "",
      createdBy: "",
      clientList: [],
      createdOn: "",
      projectId: "",
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getClientList(
      serverApi.GET_CLIENT_LIST + localStorage.getItem("companyId"),
      this.props.history
    );

    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData) {
        this.setState({
          projectId: this.props.location.state.rowData.projectId,
          name: this.props.location.state.rowData.name,
          customerId: this.props.location.state.rowData.customerId,
          customerName: this.props.location.state.rowData.customerName,
          carpetArea: this.props.location.state.rowData.carpetArea,
          sft: this.props.location.state.rowData.sft,
          address: this.props.location.state.rowData.address,
          expectedCloserDate:
            this.props.location.state.rowData.expectedCloserDate,
          startDate: this.props.location.state.rowData.startDate,
          createdBy: this.props.location.state.rowData.createdBy,
          createdOn: this.props.location.state.rowData.createdOn,
        });
      }
    }
  }

  componentWillReceiveProps(props) {
    if (props.clientList) {
      this.setState({
        clientList: props.clientList.data, // updating client dropdown api
      });
    }
  }

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "customerId") {
      this.setState({
        customerId: selectedValue,
        customerName: selectedName,
      });
    }
  };
  onhandleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  onClickCreate = () => {
    if (this.state.name == "") {
      swal("Please Enter the Project");
    } else if (this.state.carpetArea == "") {
      swal("Please Enter the Carpet Area ");
    } else if (this.state.sft == "") {
      swal("Please Enter Super built-up area");
    } else {
      if (this.props.location.state === undefined) {
        let data = {
          name: this.state.name,
          customerId: this.state.customerId,
          customerName: this.state.customerName,
          startDate: this.state.startDate,
          expectedCloserDate: this.state.expectedCloserDate,
          branchId: "",
          sft: this.state.sft,
          carpetArea: this.state.carpetArea,
          phaseName: "",
          createdBy: this.state.userDit.userId,
          companyId: localStorage.getItem("companyId"),
          address: this.state.address,
        };
        this.props.createProject(
          serverApi.CREATE_PROJECT,
          data,
          this.props.history
        );
      } else {
        let data = {
          projectId: this.state.projectId,
          name: this.state.name,
          customerId: this.state.customerId,
          customerName: this.state.customerName,
          startDate: this.state.startDate,
          expectedCloserDate: this.state.expectedCloserDate,
          branchId: "",
          sft: this.state.sft,
          carpetArea: this.state.carpetArea,
          phaseName: "",
          createdBy: this.state.userDit.userId,
          companyId: localStorage.getItem("companyId"),
          address: this.state.address,
        };
        this.props.updateProject(
          serverApi.UPDATE_PROJECT,
          data,
          this.props.history
        );
      }
    }
  };

  onClickCancel = () => {
    this.props.history.push("/project_list");
  };
  handleSelectDate = (e, name) => {
    if (name === "startDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      this.setState({
        startDate: Date,
      });
    }
    if (name === "expectedCloserDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      this.setState({
        expectedCloserDate: Date,
      });
    }
  };
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        label="Project Name"
                        required
                        onChange={this.onhandleChange("name")}
                        name="projectName"
                        value={this.state.name}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.clientList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.customerId}
                        update={this.state.customerId ? 1 : 0}
                        name="customerId"
                        label="Client"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        label="Carpet Area"
                        required
                        type="number"
                        value={this.state.carpetArea}
                        onChange={this.onhandleChange("carpetArea")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        label="Super Built-up Area"
                        required
                        type="number"
                        value={this.state.sft}
                        onChange={this.onhandleChange("sft")}
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>From Date</label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            name="From_Date"
                            inputFormat="dd-MM-yyyy"
                            id="From_Date"
                            value={this.state.startDate}
                            fullWidth
                            onChange={(e) =>
                              this.handleSelectDate(e, "startDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>To Date</label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            name="To_Date"
                            inputFormat="dd-MM-yyyy"
                            id="To_Date"
                            value={this.state.expectedCloserDate}
                            fullWidth
                            onChange={(e) =>
                              this.handleSelectDate(e, "expectedCloserDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Address"
                        type="textarea"
                        value={this.state.address}
                        onChange={this.onhandleChange("address")}
                        rows={5}
                        // required
                      />
                    </div>
                  </Box>
                  {this.props.location.state && (
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          disabled={true}
                          label="Created By"
                          value={this.state.createdBy}
                        />
                      </div>
                    </Box>
                  )}
                  {this.props.location.state && (
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <div>
                          <div className="TextFieldBlock">
                            <label>Created On</label>
                          </div>

                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              disabled={true}
                              name="Created_on"
                              inputFormat="dd-MM-yyyy"
                              id="Created_on"
                              value={this.state.createdOn}
                              fullWidth
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  style={{
                                    backgroundColor: "#ccd6db",
                                  }}
                                  className="consoleDatePicker"
                                >
                                  <input
                                    ref={inputRef}
                                    {...inputProps}
                                    style={{
                                      backgroundColor: "#ccd6db",
                                    }}
                                  />
                                  {InputProps?.endAdornment}
                                </Box>
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <CustomButton
            label={"Cancel"}
            className="greenBorderButton"
            handleClick={this.onClickCancel}
          />
          {this.props.location.state === undefined ? (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreate}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { clientList } = state.DropDownListReducer;

  return {
    ...state.Layout,
    clientList,
  };
};
export default connect(mapStatetoProps, {
  getClientList,
  createProject,
  updateProject,
})(withRouter(CreateProjectList));
