import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { STOCK_RETURN_LIST } from "./actionTypes";
import { StockReturnListSuccessfull } from "./actions";
import { getList } from "../../../helpers/Server_Helper";

function* getStockReturnList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(StockReturnListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

export function* watchStockReturnList() {
  yield takeEvery(STOCK_RETURN_LIST, getStockReturnList);
}

function* StockReturnSaga() {
  yield all([fork(watchStockReturnList)]);
}

export default StockReturnSaga;
