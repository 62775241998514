import {LEDGER_GROUPS_LIST,CREATE_LEDGER_GROUPS_MASTER,UPDATE_LEDGER_GROUPS_MASTER,
    LEDGER_GROUPS_LIST_SUCCESSFULL,CREATE_LEDGER_GROUPS_MASTER_SUCCESSFULL,UPDATE_LEDGER_GROUPS_MASTER_SUCCESSFULL} from './actionType';

export const getLedgerGroupsList = (url,data,history) =>{
    return {
        type:LEDGER_GROUPS_LIST,
        payload:{url,data,history}
    }
}
export const ledgerGroupsListSuccessfull = (data)=>{
    return {
        type:LEDGER_GROUPS_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createLedgerGroupsMaster = (url,data,history) =>{
    return {
        type:CREATE_LEDGER_GROUPS_MASTER,
        payload:{url,data,history}
    }
}
export const createLedgerGroupsMasterSuccessfull = (data)=>{
    return {
        type:CREATE_LEDGER_GROUPS_MASTER_SUCCESSFULL,
        payload:data
    }
}
export const updateLedgerGroupsMaster = (url,data,history) =>{
    return {
        type:UPDATE_LEDGER_GROUPS_MASTER,
        payload:{url,data,history}
    }
}
export const updateLedgerGroupsMasterSuccessfull = (data)=>{
    return {
        type:UPDATE_LEDGER_GROUPS_MASTER_SUCCESSFULL,
        payload:data
    }
}