import {CREATE_JUTE_INDENT, JUTETYPE_BY_MUKAM, JUTE_INDENT_BY_ID, JUTE_INDENT_LIST, QUALITY_BY_JUTETYPE, UPDATE_JUTE_INDENT } from './actionTypes';
import { all, call, fork, takeEvery, put,select } from "redux-saga/effects";
import { getList,getListurl, postAdd} from '../../../helpers/Server_Helper';
import { JuteIndentListSuccessfull, JuteTypeByMukamSuccessfull,
     QualityByJuteTypeSuccessfull, create_jute_indent_successfull, 
     jute_indent_by_id_successfull, update_jute_indent_successfull,
     JuteIndentLineItemsProps,getJuteIndentHeader} from './actions';
import swal from 'sweetalert';
import { isEmpty } from "../../../Utilities/helper";
import moment from "moment";
function* JuteIndentSagaList({ payload: { url, data, history } }) {
    try {
        const response = yield call(getList, url, data);
        yield put(JuteIndentListSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}

function* JuteTypeByMukam({ payload: { url, data, history } }) {
    try {
        const response = yield call(getListurl, url, data);
        yield put(JuteTypeByMukamSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}

function* QualityByJuteType({ payload: { url,rowId  } }) {
    const { JuteindentLineItems } = yield select(
        (state) => state.JuteIndentReducer
      );
    try {
        const response = yield call(getListurl, url);
        yield put(QualityByJuteTypeSuccessfull({ response }))
        if (response) {
            if (JuteindentLineItems) {
              var LineItemsRecords = [];
              if (JuteindentLineItems.length !== 0) {
                LineItemsRecords = JuteindentLineItems.map((item) => {
                  if (rowId == item.id) {
                    item.qualityByJuteList = response.data;
                  }
                  return item;
                });
                console.log('cvbnm',LineItemsRecords)
                yield put(JuteIndentLineItemsProps(LineItemsRecords));
              }
            }

        }


    } catch (error) {
        console.log(error)
    }
}
function* CreateJuteIndent({payload:{url, data, history}}){
    try{
        const response=yield call(postAdd,url,data);
        yield put(create_jute_indent_successfull({response}));
        if(response.data.status===true){
            swal(response.data.message,{icon:"success"})
            history.push("/jute_indent");
        }else{
            swal(response.data.message,{icon:"error"})
        }
    }catch(error){
        if(error){
            if(error.response){
                if(error.response.data){
                    swal(error.response.data.error,{icon:"error"});
                }
            }
        }
    }
}
function* UpdateJuteIndent({payload:{url,data,history}}){
    try{
        const response=yield call(postAdd,url,data);
        yield put(update_jute_indent_successfull({response}))
        if(response.data.status===true){
            swal(response.data.message,{icon:"success",})
            history.push("/jute_indent");
        }else{
            swal(response.data.message,{icon:"error"})
        }
    }catch(error){
        if(error.response){
            if(error.response.data.status){
                swal(error.response.data.message,{icon:"error"});
                console.log(error);
            }
        }
    }
}

function* JuteIndentBYID({
    payload:{url,data,history}
}){
    try{
        const response=yield call(getListurl,url,data);
        yield put(jute_indent_by_id_successfull({response}))

        if(response){
            if(response.data){
            if(response.data.indentItems){
            if(response.data.indentItems.length !==0){
          var juteIndentLineItems = [];
          response.data.indentItems.map((item, i) => {
            juteIndentLineItems.push({
                id:i,
                IndentLineItemId:item.indentLineItemId,
                indentHeaderId: item.indentHeaderId,
                itemId: item.itemId,
                itemIdDesc: item.itemIdDesc,
                itemGroupId: item.itemGroupId,
                itemGroupIdDesc: item.itemGroupIdDesc,
                qualityCode:item.qualityCode,
                qualityCodeDesc: item.qualityCodeDesc,
                deptId:item.deptId,
                deptIdDesc: item.deptIdDesc,
                stock: item.stock,
                indentQuantity:item.indentQuantity,
                unitId: item.unitId,
                additionalRequirement: item.additionalRequirement,
                dtlItemDesc: item.dtlItemDesc,
                noOfBales: item.noOfBales,
                hsnCode: item.hsnCode,
                rate: item.rate,
                moisture: item.moisture,
                taxId: item.taxId,
                taxPercentage: item.taxPercentage,
                remainingQuantity: item.remainingQuantity,
                branchStock: item.branchStock,
                pendingAtPo: item.pendingAtPo,
                indentType: item.indentType,
                isActive: item?.isActive || 1
            })
        })
        juteIndentLineItems.push({
                id: juteIndentLineItems.length + 1,
                IndentLineItemId: null,
                indentHeaderId: null,
                itemId: null,
                itemIdDesc: "",
                itemGroupId: null,
                itemGroupIdDesc: "",
                qualityCode: "",
                qualityCodeDesc: "",
                deptId: null,
                deptIdDesc: "",
                stock: "0",
                indentQuantity: "",
                unitId: "",
                additionalRequirement: "",
                dtlItemDesc: "",
                noOfBales: "",
                hsnCode: "",
                rate: "",
                moisture: "",
                taxId: null,
                taxPercentage: "",
                remainingQuantity: "",
                branchStock: "",
                pendingAtPo: "",
                indentType: "",
                isActive: 1
        })
        
        yield put(JuteIndentLineItemsProps(juteIndentLineItems));
        var convertedQuantity =""
        if(response.data.indentHeader.unitConversion=="BALE"){
            convertedQuantity= Math.round((Number(response.data.indentHeader.indentWeight )* 100) / 150);
        }else{
            convertedQuantity=""
        }
        var ApproveButton = false;
        var RejectButton = false;
        var UpdateButton = true;
        var EditButton = false;
       
        if (response.data.indentHeader.approveButton === true) {
            if (
              response.data.indentHeader.status === "1"||
              response.data.indentHeader.status === "17" ||
              response.data.indentHeader.status === "18" ||
              response.data.indentHeader.status === "19" ||
              response.data.indentHeader.status === "20"
            ) {
              ApproveButton = true;
              RejectButton = true;
              UpdateButton = true;
              EditButton = true;
            }
        
          } else if (
            response.data.indentHeader.status === "1"||
            response.data.indentHeader.status === "17" ||
            response.data.indentHeader.status === "18" ||
            response.data.indentHeader.status === "19" ||
            response.data.indentHeader.status === "20"
          ) {
            UpdateButton = true;
            EditButton = true;
          }
    
          if (
            response.data.indentHeader.status === "4" ||
            response.data.indentHeader.status === "3" ||
            response.data.indentHeader.status === "5"
          ) {
            ApproveButton = false;
            RejectButton = false;
            UpdateButton = false;
            EditButton = false;
          }
          var indentDate = ""
          if(!isEmpty(response.data.indentHeader.indentDateDesc)){
             indentDate = moment(response.data.indentHeader.indentDateDesc,"DD-MM-YYYY", true).format("YYYY-MM-DD")
          }else{
            indentDate =""
          }
        const Header = {
          hdrId:response.data.indentHeader.hdrId,
          id:response.data.indentHeader.id,
          indentHeaderId:response.data.indentHeader.id,
          type: response.data.indentHeadertype,
          status:response.data.indentHeader.status,
          submitter:response.data.indentHeader.submitter,
          finnacialYear:response.data.indentHeader.finnacialYear,
          indentDate:indentDate,
          mukam:response.data.indentHeader.mukamNo,
          printTag: response.data.indentHeader.printTag,
          isActive: response.data.indentHeader.isActive,
          vehicleTypeId:response.data.indentHeader. vehicleTypeId,
          vehicleQuantity:response.data.indentHeader. vehicleQuantity,
          quantityInTon: Number(response.data.indentHeader.indentWeight)/10,
          convertedQuantity: convertedQuantity,
          modOn:response.data.indentHeader. modOn,
          modBy: response.data.indentHeader.modBy,
          indentWeight:response.data.indentHeader. indentWeight,
          unitConversion: response.data.indentHeader.unitConversion,
          autoDateTime:response.data.indentHeader. autoDateTime,
          statusDesc: response.data.indentHeader.statusDesc,
          indentDateDesc:response.data.indentHeader. indentDateDesc,
          approveButton: true,
          mukamNo: response.data.indentHeader.mukamNo,
          companyId:response.data.indentHeader. companyId,
          finalApprover:response.data.indentHeader. finalApprover,
          branchId:response.data.indentHeader. branchId,
          projectId: response.data.indentHeader.projectId,
          internalNote: response.data.indentHeader.internalNote,
          remarks:response.data.indentHeader. remarks,
          userDesc:response.data.indentHeader.userDesc,
          ApproveButton :ApproveButton,
          RejectButton : RejectButton,
          UpdateButton : UpdateButton,
          EditButton : EditButton
        };
      yield put(getJuteIndentHeader(Header));

             }
            }
         }
        }
    }catch(error){
        console.log(error);
    }
}

export function* watchJuteIndentList() {
    yield takeEvery(JUTE_INDENT_LIST, JuteIndentSagaList)
    yield takeEvery(JUTETYPE_BY_MUKAM, JuteTypeByMukam)
    yield takeEvery(QUALITY_BY_JUTETYPE, QualityByJuteType)
    yield takeEvery(CREATE_JUTE_INDENT,CreateJuteIndent);
    yield takeEvery(UPDATE_JUTE_INDENT,UpdateJuteIndent);
    yield takeEvery(JUTE_INDENT_BY_ID,JuteIndentBYID);
}

function* JuteIndentSaga() {
    yield all([
        fork(watchJuteIndentList),
        // fork(JuteTypeByMukam),
    ])
}



export default JuteIndentSaga;