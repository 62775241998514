import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/PaginationGridView";
import { getPrResponseList } from "../../../store/Purchase/PR_Response/actions";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      PrResponseListData: [],
      totalRecords: 0,
      startIndex: 1,
      lastIndex: 10,
      dataexp:"",
      suppCode:'',
      itemId:'',
    };
  }

  componentDidMount() {
    const data = {
      companyId:localStorage.getItem("companyId"),
      suppCode: '',
      hdrId: "",
      itemId: "",
      startIndex : 1,
      lastIndex : 10,
    };
    const expdata = {
      ...data,
      startIndex : 0,
      lastIndex : 0,
    }
    this.setState({ dataexp: expdata });

    this.props.getPrResponseList(
      serverApi.PR_RESPONSE_LIST_BY_CMPY_ID,
      data,
      this.props.history
    );
  }
  componentWillReceiveProps(props) {
    if (props.PrResponse_List) {
      this.setState({
        PrResponseListData: props.PrResponse_List.data,
        totalRecords:props.PrResponse_List.totalRecords
        //PrResponseListColumns: props.PrResponse_List.column,
      });
    }
  }

  handleSearch = (data1) => {
    const data = {
      companyId: localStorage.getItem("companyId"),
      suppCode:data1.suppCode,
      hdrId: "",
      itemId:data1.itemId,
      startIndex : 1,
      lastIndex : 10,
    };
    this.setState({
      suppCode:data1.suppCode,
      itemId:data1.itemId,
    })
    this.props.getPrResponseList(
      serverApi.PR_RESPONSE_LIST_BY_CMPY_ID,
      data,
      this.props.history
    );
  };
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    const data = {
      companyId:localStorage.getItem("companyId"),
      suppCode: this.state.suppCode,
      hdrId: "",
      itemId: this.state.itemId,
    };
    if (type === 1) {
      data.startIndex = sizePerPage;
      data.lastIndex = currentIndex;
      if (currentIndex >= 0) {
        this.props.getPrResponseList(
          serverApi.PR_RESPONSE_LIST_BY_CMPY_ID,
          data,
          this.props.history
        );
      }
    } else {
      data.startIndex = currentIndex ===0?currentIndex+1:currentIndex;
      data.lastIndex = sizePerPage;
      if (currentIndex >= 0) {
        this.props.getPrResponseList(
          serverApi.PR_RESPONSE_LIST_BY_CMPY_ID,
          data,
          this.props.history
        );
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });

  }
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.PrResponseListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={false}
          actions={true}
          filter_form={"PrResponse"}
          handleSearch={this.handleSearch}
          url="/create_pr_response"
          apiexp={serverApi.PR_RESPONSE_LIST_BY_CMPY_ID}
          payloadexp={this.state.dataexp}
          expName={"PrResponse.csv"}
          totalRecs={this.state.totalRecords}
          pageno={this.state.pageno}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
        />
      </>
    );
  }
}
const Columns = [
  {
    Header: "Price Request Id",
    accessor: "hdrId",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Item Group Code",
    accessor: "itemGroupCode",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Item Description",
    accessor: "itemDesc",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Supplier Code",
    accessor: "suppCode",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Supplier Name",
    accessor: "suppName",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Supplier Rate",
    accessor: "itemRate",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Is Active",
    accessor: "isActive",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Response Time",
    accessor: "suppResponseDateTimeDesc",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Status",
    accessor: "statusDesc",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Price Request Dtl Id",
    accessor: "dtlId",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Item Code",
    accessor: "itemCode",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Is Rate Active",
    accessor: "isRateActive",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Supplier Comments",
    accessor: "suppComments",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Supplier Quote File",
    accessor: "suppQuoteFile",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Company Id",
    accessor: "companyId",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Created By User",
    accessor: "createdByUser",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Created Date Time",
    accessor: "createDateTime",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Updated By",
    accessor: "updatedBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Updated By User",
    accessor: "updatedByUser",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Updated Date Time",
    accessor: "updatedDateTime",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },

  {
    Header: "Price Request Status",
    accessor: "hdrPriceRequestStatus",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Item Group Description",
    accessor: "itemGrpDesc",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Indent Number",
    accessor: "indentNo",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Delivery Timeline",
    accessor: "deliveryTimeLine",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false,
  },
];
// export default withRouter(connect(null,{ logoutUser })(CreateIndent));

const mapStatetoProps = (state) => {
  const { PrResponse_List } = state.PrResponse_List;
  return { PrResponse_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getPrResponseList,
    // getInwardList
  })(Index)
);
