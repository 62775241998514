export const UOM_MASTER_LIST = "uom_master_list";
export const UOM_LIST_SUCCESSFULL = "uom_list_successfull";
export const CREATE_UOM_MASTER = "create_uom_master";
export const CREATE_UOM_MASTER_SUCCESSFULL = "create_uom_master_successfull";
export const GET_UOM_RELATION_DATA = "get_uom_relation";
export const GET_UOM_RELATION_DATA_SUCCESSFULL = "get_uom_relation_successfull";
export const DELETE_UOM_RELATION = "delete_uom_relation";
export const DELETE_UOM_RELATION_SUCCESSFULL = "delete_uom_relation_successfull";

export const UOM_LIST_BASEDON_ITEM_ID = "uom_master_list_basedon_itemId";
export const UOM_LIST_BASEDON_ITEM_ID_SUCCESS = "uom_master_list_basedon_itemId_success";


