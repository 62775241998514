//import { Switch } from "react-router-dom";

import {
  API_ERRORS,
  LEAVETYPELIST,
  LEAVETYPELIST_SUCCESSFULL,
  CREATE_LEAVE_TYPE,
  CREATE_LEAVE_TYPE_SUCCESSFULL,
  UPDATE_LEAVE_TYPE_SUCCESSFULL,
  UPDATE_LEAVE_TYPE,
  LEAVETYPESLIST,
  LEAVETYPESLIST_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  LeaveTypeList: [],
  Create_Leave_Type: [],
  Update_Leave_Type: [],
  error_msg: null,
};

const LeaveTypeListReducer = (state = initialState, action) => {
  switch (action.type) {
    case LEAVETYPELIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case LEAVETYPELIST_SUCCESSFULL:
      state = {
        ...state,
        LeaveType_List: action.payload.response.data,
        loading: false,
      };
      break;
      case LEAVETYPESLIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case LEAVETYPESLIST_SUCCESSFULL:
      state = {
        ...state,
        LeaveType_List: action.payload.response,
        loading: false,
      };
      break;
    case CREATE_LEAVE_TYPE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_LEAVE_TYPE_SUCCESSFULL:
      state = {
        ...state,
        Create_Leave_Type: action.payload.response,
        loading: true,
      };
      break;
    case UPDATE_LEAVE_TYPE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_LEAVE_TYPE_SUCCESSFULL:
      state = {
        ...state,
        Update_Leave_Type: action.payload.response,
        loading: true,
      };
      break;
    case API_ERRORS:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default LeaveTypeListReducer;
