import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import {
  SUPPLIER_MASTER_LIST,
  UPDATE_SUPPLIER_MASTER,
  SUPPLIER_MASTER_VIEW_BY_ID,
  CREATE_SUPPLIER_MASTER,
  GET_ALL_FILES,
  DELETE_SUPPLIER_FILES,
  UPLOAD_SUPPLIER_FILES,
  SUPPLIER_TYPES_LIST,
  GET_ALL_ENTITY_TYPES,
  SUPPLIER_GST_VERIFICATION,
} from "./actionTypes";
import {
  SupplierMasterListSuccessfull,
  UpdateSupplierMasterSuccessfull,
  SupplierMasterViewByIdSuccessfull,
  CreateSupplierMasterSuccessfull,
  getSuppFilesData,
  deleteSupplierMasterFilesSuccessfull,
  UploadSupplierMasterFilesSuccessfull,
  getSuppFilesDataSuccessfull,
  supplierTypesListSuccessfull,
  getAllEntityTypesSuccessfull,
  gstSupplierVerificationSuccessfull,
} from "./actions";
import { getList, getListurl, postAdd } from "../../../helpers/Server_Helper";
import swal from "sweetalert";
import { hideSpinner, serverApi, showSpinner } from "../../../helpers/Consts";

function* getSupplierMasterList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(SupplierMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* SupplierMasterViewById({ payload: { url, data, history } }) {
  try {
    const response = yield call(getListurl, url, data);
    yield put(SupplierMasterViewByIdSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/supplier_master");
    }
  } catch (error) {
    swal("error:" + error, { icon: "danger" });
    console.log(error);
  }
}
function* CreateSupplierMaster({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(CreateSupplierMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/supplier_master");
      hideSpinner(true);
    } else {
      hideSpinner(true);
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateSupplierMasterSaga({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(UpdateSupplierMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/supplier_master");
      hideSpinner(true);
    } else {
      swal(response.data.message, { icon: "error" });
      hideSpinner(true);
    }
  } catch (error) {
    hideSpinner(true);
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
      }
    }
  }
}
function* getAllSupplierFiles({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getSuppFilesDataSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* deleteSupplierFiles({ payload: { url, suppId, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(deleteSupplierMasterFilesSuccessfull({ response }));
    if (response) {
      swal("Deleted Successfully", { icon: "success" });
      let Url =
        serverApi.GET_ALL_FILES +
        "32/" +
        suppId +
        "/" +
        localStorage.getItem("companyId");
      yield put(getSuppFilesData(Url, history));
    }
  } catch (error) {
    console.log(error);
  }
}
function* UploadSupplierMasterFilesData({
  payload: { url, data, suppId, history },
}) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(UploadSupplierMasterFilesSuccessfull({ response }));
    if (response) {
      swal("Uploaded Successfully", { icon: "success" });
      let Url =
        serverApi.GET_ALL_FILES +
        "32/" +
        suppId +
        "/" +
        localStorage.getItem("companyId");
      yield put(getSuppFilesData(Url, history));
    }
  } catch (error) {
    console.log(error);
  }
}

function* SupplierTypesListSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(supplierTypesListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getAllEntityTypesData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getAllEntityTypesSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* gstSupplierVerificationData({ payload: { url, id, history } }) {
  try {
    const response = yield call(getListurl, url, id);
    var data = response;
    data.id = id;
    yield put(gstSupplierVerificationSuccessfull({ response }));
    if (response.data.status) {
      swal("Supplier GST Verified Successfully!", { icon: "success" });
    } else {
      swal("Supplier GST Verification Failed", { icon: "error" });
    }
  } catch (error) {
    console.log(error);
  }
}
export function* watchSupplierMasterList() {
  yield takeEvery(SUPPLIER_MASTER_LIST, getSupplierMasterList);
  yield takeEvery(SUPPLIER_MASTER_VIEW_BY_ID, SupplierMasterViewById);
  yield takeEvery(UPDATE_SUPPLIER_MASTER, UpdateSupplierMasterSaga);
  yield takeEvery(CREATE_SUPPLIER_MASTER, CreateSupplierMaster);
  yield takeEvery(GET_ALL_FILES, getAllSupplierFiles);
  yield takeEvery(DELETE_SUPPLIER_FILES, deleteSupplierFiles);
  yield takeEvery(UPLOAD_SUPPLIER_FILES, UploadSupplierMasterFilesData);
  yield takeEvery(SUPPLIER_TYPES_LIST, SupplierTypesListSaga);
  yield takeEvery(GET_ALL_ENTITY_TYPES, getAllEntityTypesData);
  yield takeEvery(SUPPLIER_GST_VERIFICATION, gstSupplierVerificationData);
}

function* SupplierListSaga() {
  yield all([fork(watchSupplierMasterList)]);
}

export default SupplierListSaga;
