import {
  EMPLOYEE_LIST,
  EMPLOYEE_LIST_SUCCESSFULL,
  EDUCATIONAL_DETAILS,
  EDUCATIONAL_DETAILS_SUCCESSFULL,
  PERSONAL_DETAILS,
  PERSONAL_DETAILS_SUCCCESSFULL,
  DEPARTMENT_LIST_DATA,
  DEPARTMENT_LIST_DATA_SUCCESSFULL,
  CATEGORY_LIST,
  CATEGORY_LIST_SUCCESSFULL,
  EMP_LEVEL,
  EMP_LEVEL_SUCCESSFULL,
  REPORTING_MANAGER_LIST,
  REPORTING_MANAGER_LIST_SUCCESSFULL,
  CONTRACTOR,
  CONTRACTOR_SUCCESSFULL,
  WORK_LOCATION,
  WORK_LOCATION_SUCCESSFULL,
  DESIGNATION,
  DESIGNATION_SUCCESSFULL,
  LEAVE_POLICY_LIST,
  LEAVE_POLICY_LIST_SUCCESSFULL,
  LEAVE_LIST,
  LEAVE_LIST_SUCCESSFULL,
  PAY_SCHEME,
  PAY_SCHEME_SUCCESSFULL,
  EMPLOYEE_DETAILS_VIEW_BY_ID,
  EMPLOYEE_DETAILS_VIEW_BY_ID_SUCCESSFULL,
  EXPERIENCE_DETAILS,
  EXPERIENCE_DETAILS_SUCCESSFULL,
  PAY_SCHEME_BY_ID,
  PAY_SCHEME_BY_ID_SUCCESSFULL,
  CHECK_PAY_SCHEME,
  CHECK_PAY_SCHEME_SUCCESSFULL,
  LEAVE_POLICY_MAPPING,
  LEAVE_POLICY_MAPPING_SUCCESSFULL,
  GET_ALL_FILES_DATA,
  GET_ALL_FILES_SUCCESSFULL,
  CLEAR_ALL_FILES,
  PROFILE_PIC_DETAILS,
  PROFILE_PIC_DETAILS_SUCCESSFULL,
  DOWNLOAD_FILES,
  DOWNLOAD_FILES_SUCCESSFULL,
  UPDATE_FILE,
  UPDATE_FILE_SUCCESSFULL,
  OFFER_LETTER_EMAIL_SERVICE,
  OFFER_LETTER_EMAIL_SERVICE_SUCCESSFULL,
  EXPERIENCE_DETAILS_VIEW_BY_ID,
  EXPERIENCE_DETAILS_VIEW_BY_ID_SUCCESSFULL_,
  GET_PAYSCHEME_LIST,
  GET_PAYSCHEME_LIST_SUCCESSFULL,
  ADD_SALARY,
  ADD_SALARY_SUCCESSFULLY,
  DISPLAY_EMAIL_TEMPLATE,
  DISPLAY_EMAIL_TEMPLATE_SUCCESSFULLY,
  PAY_WAGES_MODES,
  PAY_WAGES_MODES_SUCCESSFULL,
  PAY_COMPONENTS,
  PAY_COMPONENTS_SUCCESSFULL,
  ADD_PAY_SCHEME,
  ADD_PAY_SCHEME_SUCCESSFULL,
  UPDATE_PAY_SCHEME,
  UPDATE_PAY_SCHEME_SUCCESSFULL,
  UPDATE_SALARY,
  UPDATE_SALARY_SUCCESSFULLY,
  HRMS_DASHBOARD,
  HRMS_DASHBOARD_SUCCESSFULLY,
  PAYSCHEME_BY_ID,
  PAYSCHEME_BY_ID_SUCCESSFULL,
  APPROVAL_HIERARCHY,
  APPROVAL_HIERARCHY_SUCCESSFULLY,
  VIEW_BY_ID_APPROVAL,
  VIEW_BY_ID_APPROVAL_SUCCESSFULLY,
  UPDATE_LEAVE_POLICY,
  UPDATE_LEAVE_POLICY_SUCCESSFULL,
  WORKER_DETAILS_PROGRESS,
  WORKER_DETAILS_PROGRESS_SUCCESSFULLY,
  GET_WORKER_DETAILS_PROGRESS,
  GET_WORKER_DETAILS_PROGRESS_SUCCESSFULLY,
  GET_LIST_ROW_DATA,
  DELETE_WORKER_EDUCATIONAL_DETAILS,
  DELETE_WORKER_EDUCATIONAL_DETAILS_SUCCESSFULLY,
  UPDATE_WORKER_STATUS,
  UPDATE_WORKER_STATUS_SECCESSFULL,
  VIEW_BY_ID_PERSONAL_DETAILS,
  VIEW_BY_ID_PERSONAL_DETAILS_SUCCESSFULL,
  UPDATE_WORKER_DETAILS,
  UPDATE_WORKER_DETAILS_SUCCESSFULL,
  EMPLOYEE_PROGRESS_BAR_DATA,
  EMPLOYEE_PROGRESS_BAR_DATA_SUCCESSFULL,
  VIEW_BY_ID_SALARY_STRUCTURE,
  VIEW_BY_ID_SALARY_STRUCTURE_SUCCESSFULL,
  GET_EBID_SECCESSFULL,
  ESI_DETAILS,
  GET_PROGRESSBARDATA_SECCESSFULL,
  AADHAR_VALIDATION,
  AADHAR_VALIDATION_SUCCESSFULL,
  CLEAR_AADHAR_DATA,
  FILE_UPLOAD,
  FILE_UPLOAD_SUCCESSFULL,
  CLEAR_FILES,
  DELETE_PROFILE_PIC,
  DELETE_PROFILE_PIC_SUCCESSFULL,
  UPLOAD_PROFILE_PIC,
  UPLOAD_PROFILE_PIC_SUCCESSFULL,
  DELETE_MEDICAL_POLICY,
  DELETE_MEDICAL_POLICY_SUCCESSFULL,
  VIEW_BY_ID_EMP_DATA,
  DELETE_FILES,
  DELETE_FILES_SUCCESSFULL,
  VIEW_BY_ID_LEAVE_POLICY,
  VIEW_BY_ID_LEAVE_POLICY_SUCCESSFULL,
  CLEAR_PAY_SCHEME,
  CLEAR_CHECK_SCHEME,
  GET_WORKER_DATA,
  GET_WORKER_DATA_SUCCESSFULL,
  CLEAR_PAY_SCHEME_BYID,
  DOWNLOAD_BULK_EMPLOYEE_TEMPLATE,
  DOWNLOAD_BULK_EMPLOYEE_TEMPLATE_SUCCESSFULL,
  CLEAR_PROFILE_PIC_DATA,
  CLEAR_EMPLOYEE_PROGRESS_BAR_DATA,
  ESI_DETAILS_SUCCESSFULL,
} from "./actionType";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  page: "",
  employeeList: [],
  employeeListColumns: [],
  employeeListItemsList: [],
  educationalDetails: [],
  personalDetails: [],
  departmentList: [],
  categoryList: [],
  empLevel: [],
  reporting_Manager_List: [],
  contractor: [],
  workLocation: [],
  designation: [],
  leavePolicy_List: [],
  leave_List: [],
  payScheme_List: [],
  EmployeeviewById: [],
  experienceDetails: [],
  paySchemeById: [],
  paySchemeByIdList: [],
  checkPayScheme: [],
  pay_policy_mapping: [],
  allFiles: [],
  profilepicFiles: [],
  Download_File: [],
  Update_file: [],
  assetForm: [],
  EmailOfferLetter: [],
  experienceDetailsViewById: [],
  // getPayschemeList: [],
  checkScheme: [],
  addingsalary: [],
  DisplayEmailTemplate: [],
  payWagesModes_list: [],
  payComponents_list: [],
  addPayscheme: [],
  updatePayScheme: [],
  profilePicUrl: [],
  profilePicFile: [],
  HrmsDashboardData: [],
  approval_hierarchy: [],
  approval_hierarchy_view_byId: [],
  Update_leave_policy: [],
  worker_details_progress: [],
  Get_worker_progress: [],
  pagereder: 1,
  delete_educational_details: [],
  update_worker_status: [],
  progressbar: [],
  getEbId: "",
  progressbar_data: [],
  Aadhar_validation: [],
  ViewById_Educational_Details: [],
  fileUpload: [],
  Delete_profilePic: [],
  Profile_Upload: [],
  Delete_MedicalPolicy_File: [],
  getEmpData: [],
  Delete_Files_data: [],
  viewById_LeavePlocy: [],
  SalaryStructureList: [],
  Get_Worker_Details:[],
  Download_Bulk_Template:[],
  EsiDetailsData:[]
};

const EmployeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYEE_LIST:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case EMPLOYEE_LIST_SUCCESSFULL:
      state = {
        ...state,
        employeeList: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case EDUCATIONAL_DETAILS:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case EDUCATIONAL_DETAILS_SUCCESSFULL:
      state = {
        ...state,
        educationalDetails: action.payload.response.data,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case PERSONAL_DETAILS:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case PERSONAL_DETAILS_SUCCCESSFULL:
      state = {
        ...state,
        personalDetails: action.payload.response.data,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case GET_LIST_ROW_DATA:
      state = {
        ...state,
        personalDetails: action.payload,
        loading: true,
      };
      break;
    case CLEAR_PAY_SCHEME:
      state = {
        ...state,
        SalaryStructureList: action.payload,
        loading: true,
      };
      break;
      case CLEAR_CHECK_SCHEME:
      state = {
        ...state,
        checkScheme: action.payload,
        loading: true,
      };
      break;
      
      case CLEAR_PAY_SCHEME_BYID:
      state = {
        ...state,
        checkScheme: action.payload,
        loading: true,
      };
      break;
    // View by Id
    case EMPLOYEE_DETAILS_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case EMPLOYEE_DETAILS_VIEW_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        EmployeeviewById: action.payload.response.data,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    // experience Details
    case EXPERIENCE_DETAILS:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case EXPERIENCE_DETAILS_SUCCESSFULL:
      state = {
        ...state,
        experienceDetails: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    //
    case EXPERIENCE_DETAILS_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case EXPERIENCE_DETAILS_VIEW_BY_ID_SUCCESSFULL_:
      state = {
        ...state,
        experienceDetailsViewById: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    // Work Details
    case DEPARTMENT_LIST_DATA:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case DEPARTMENT_LIST_DATA_SUCCESSFULL:
      state = {
        ...state,
        departmentList: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case CATEGORY_LIST:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case CATEGORY_LIST_SUCCESSFULL:
      state = {
        ...state,
        categoryList: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case EMP_LEVEL:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case EMP_LEVEL_SUCCESSFULL:
      state = {
        ...state,
        empLevel: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case REPORTING_MANAGER_LIST:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case REPORTING_MANAGER_LIST_SUCCESSFULL:
      state = {
        ...state,
        reporting_Manager_List: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case CONTRACTOR:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case CONTRACTOR_SUCCESSFULL:
      state = {
        ...state,
        contractor: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case WORK_LOCATION:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case WORK_LOCATION_SUCCESSFULL:
      state = {
        ...state,
        workLocation: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case DESIGNATION:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case DESIGNATION_SUCCESSFULL:
      state = {
        ...state,
        designation: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case LEAVE_POLICY_LIST:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case LEAVE_POLICY_LIST_SUCCESSFULL:
      state = {
        ...state,
        leavePolicy_List: action.payload.response.data,
        loading: false,
        page: "EmployeeReducer",
      };
      break;

    case LEAVE_LIST:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case LEAVE_LIST_SUCCESSFULL:
      state = {
        ...state,
        leave_List: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case PAY_SCHEME:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case PAY_SCHEME_SUCCESSFULL:
      state = {
        ...state,
        payScheme_List: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;

    case PAY_SCHEME_BY_ID:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case PAY_SCHEME_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        paySchemeById: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;

    case PAYSCHEME_BY_ID:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case PAYSCHEME_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        paySchemeByIdList: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case CHECK_PAY_SCHEME:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case CHECK_PAY_SCHEME_SUCCESSFULL:
      state = {
        ...state,
        checkPayScheme: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case LEAVE_POLICY_MAPPING:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case LEAVE_POLICY_MAPPING_SUCCESSFULL:
      state = {
        ...state,
        pay_policy_mapping: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case UPDATE_LEAVE_POLICY:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case UPDATE_LEAVE_POLICY_SUCCESSFULL:
      state = {
        ...state,
        Update_leave_policy: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case GET_ALL_FILES_DATA:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case GET_ALL_FILES_SUCCESSFULL:
      state = {
        ...state,
        allFiles: action.payload.response.data,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
      case CLEAR_ALL_FILES:
        state = {
          ...state,
          allFiles: action.payload,
          loading: false,
          page: "EmployeeReducer",
        };
        break;
      
    case PROFILE_PIC_DETAILS:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case PROFILE_PIC_DETAILS_SUCCESSFULL:
      state = {
        ...state,
        profilepicFiles: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
      case CLEAR_PROFILE_PIC_DATA:
        state = {
          ...state,
          profilepicFiles: action.payload,
          loading: false,
          page: "EmployeeReducer",
        };
        break;
    case DOWNLOAD_FILES:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case DOWNLOAD_FILES_SUCCESSFULL:
      state = {
        ...state,
        Download_File: action.payload.response.data,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case UPDATE_FILE:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case UPDATE_FILE_SUCCESSFULL:
      state = {
        ...state,
        Update_file: action.payload.response.data,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case OFFER_LETTER_EMAIL_SERVICE:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case OFFER_LETTER_EMAIL_SERVICE_SUCCESSFULL:
      state = {
        ...state,
        EmailOfferLetter: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case GET_PAYSCHEME_LIST:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case GET_PAYSCHEME_LIST_SUCCESSFULL:
      state = {
        ...state,
        checkScheme: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case ADD_PAY_SCHEME:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case ADD_PAY_SCHEME_SUCCESSFULL:
      state = {
        ...state,
        addPayscheme: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;

    case UPDATE_PAY_SCHEME:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case UPDATE_PAY_SCHEME_SUCCESSFULL:
      state = {
        ...state,
        updatePayScheme: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case ADD_SALARY:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case ADD_SALARY_SUCCESSFULLY:
      state = {
        ...state,
        addingsalary: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case UPDATE_SALARY:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case UPDATE_SALARY_SUCCESSFULLY:
      state = {
        ...state,
        addingsalary: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case DISPLAY_EMAIL_TEMPLATE:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case DISPLAY_EMAIL_TEMPLATE_SUCCESSFULLY:
      state = {
        ...state,
        DisplayEmailTemplate: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case PAY_WAGES_MODES:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case PAY_WAGES_MODES_SUCCESSFULL:
      state = {
        ...state,
        payWagesModes_list: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case PAY_COMPONENTS:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case PAY_COMPONENTS_SUCCESSFULL:
      state = {
        ...state,
        payComponents_list: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;

    case HRMS_DASHBOARD:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case HRMS_DASHBOARD_SUCCESSFULLY:
      state = {
        ...state,
        HrmsDashboardData: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case APPROVAL_HIERARCHY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case APPROVAL_HIERARCHY_SUCCESSFULLY:
      state = {
        ...state,
        approval_hierarchy: action.payload.response,
        loading: true,
      };
      break;
    case VIEW_BY_ID_APPROVAL:
      state = {
        ...state,
        loading: true,
      };
      break;
    case VIEW_BY_ID_APPROVAL_SUCCESSFULLY:
      state = {
        ...state,
        approval_hierarchy_view_byId: action.payload.response,
        loading: true,
      };
      break;
    case WORKER_DETAILS_PROGRESS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case WORKER_DETAILS_PROGRESS_SUCCESSFULLY:
      state = {
        ...state,
        worker_details_progress: action.payload.response,
        loading: true,
      };
      break;
    case GET_WORKER_DETAILS_PROGRESS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_WORKER_DETAILS_PROGRESS_SUCCESSFULLY:
      state = {
        ...state,
        Get_worker_progress: action.payload.response,
        loading: true,
      };
      break;
    case DELETE_WORKER_EDUCATIONAL_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_WORKER_EDUCATIONAL_DETAILS_SUCCESSFULLY:
      state = {
        ...state,
        delete_educational_details: action.payload.response,
        loading: true,
      };
      break;

    case UPDATE_WORKER_STATUS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_WORKER_STATUS_SECCESSFULL:
      state = {
        ...state,
        update_worker_status: action.payload.response,
        loading: true,
      };
      break;
    /***************************************************************************EMPLOYEE DATABSE NEW APIS ************************** */
    case VIEW_BY_ID_PERSONAL_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case VIEW_BY_ID_PERSONAL_DETAILS_SUCCESSFULL:
      state = {
        ...state,
        personalDetails: action.payload.response.data,
        loading: true,
      };
      break;
    case UPDATE_WORKER_DETAILS:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case UPDATE_WORKER_DETAILS_SUCCESSFULL:
      state = {
        ...state,
        personalDetails: action.payload.response.data,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case EMPLOYEE_PROGRESS_BAR_DATA:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case EMPLOYEE_PROGRESS_BAR_DATA_SUCCESSFULL:
      state = {
        ...state,
        progressbar_data: action.payload.response.data,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
      case CLEAR_EMPLOYEE_PROGRESS_BAR_DATA:
        state = {
          ...state,
          progressbar_data: action.payload,
          loading: false,
        };
        break;
    case VIEW_BY_ID_SALARY_STRUCTURE:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case VIEW_BY_ID_SALARY_STRUCTURE_SUCCESSFULL:
      state = {
        ...state,
        SalaryStructureList: action.payload.response.data,
        loading: false,
        page: "EmployeeReducer",
      };
      break;

    case GET_EBID_SECCESSFULL:
      state = {
        ...state,
        getEbId: action.payload,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case VIEW_BY_ID_EMP_DATA:
      state = {
        ...state,
        getEmpData: action.payload,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case ESI_DETAILS:
      state = {
        ...state,
        loading: true,
      };
      break;
      case ESI_DETAILS_SUCCESSFULL:
        state = {
          ...state,
          EsiDetailsData: action.payload.response,
          loading: true,
        };
        break;
    case AADHAR_VALIDATION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AADHAR_VALIDATION_SUCCESSFULL:
      state = {
        ...state,
        Aadhar_validation: action.payload.response,
        loading: true,
      };
      break;
    case FILE_UPLOAD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FILE_UPLOAD_SUCCESSFULL:
      state = {
        ...state,
        fileUpload: action.payload.response,
        loading: true,
      };
      break;
    case UPLOAD_PROFILE_PIC:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPLOAD_PROFILE_PIC_SUCCESSFULL:
      state = {
        ...state,
        Profile_Upload: action.payload.response,
        loading: true,
      };
      break;
    case DELETE_PROFILE_PIC:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_PROFILE_PIC_SUCCESSFULL:
      state = {
        ...state,
        Delete_profilePic: action.payload.response,
        loading: true,
      };
      break;
    case DELETE_FILES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_FILES_SUCCESSFULL:
      state = {
        ...state,
        Delete_Files_data: action.payload.response,
        loading: true,
      };
      break;
    case DELETE_MEDICAL_POLICY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_MEDICAL_POLICY_SUCCESSFULL:
      state = {
        ...state,
        Delete_MedicalPolicy_File: action.payload.response,
        loading: true,
      };
      break;
    case CLEAR_FILES:
      state = {
        ...state,
        fileUpload: action.payload,
        loading: true,
      };
      break;

    case CLEAR_AADHAR_DATA:
      state = {
        ...state,
        Aadhar_validation: action.payload,
        loading: true,
      };
      break;
    case VIEW_BY_ID_LEAVE_POLICY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case VIEW_BY_ID_LEAVE_POLICY_SUCCESSFULL:
      state = {
        ...state,
        viewById_LeavePlocy: action.payload.response.data,
        loading: true,
      };
      break;
      case GET_WORKER_DATA:
        state = {
          ...state,
          loading: true,
        };
        break;
      case GET_WORKER_DATA_SUCCESSFULL:
        state = {
          ...state,
          Get_Worker_Details: action.payload.response.data,
          loading: true,
        };
        break;
        case DOWNLOAD_BULK_EMPLOYEE_TEMPLATE:
          state = {
            ...state,
            loading: true,
          };
          break;
        case DOWNLOAD_BULK_EMPLOYEE_TEMPLATE_SUCCESSFULL:
          state = {
            ...state,
            Download_Bulk_Template: action.payload.response.data,
            loading: true,
          };
          break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default EmployeeReducer;
