import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import DynamicSelect from "../../../components/Dropdown/Master/DynamicSelectMasters";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { serverApi } from "../../../helpers/Consts";

import {
  getSupplierListMultiselect,
  getStatusList,
  getStateNameList,
  getDepartmentList,
} from "../../../store/Global/DropDownApis/actions";
import {
  getVehicleList,
  getAgentDropdownList,
} from "../../../store/MasterDropdownApis/actions";
import TextFieldNormal from "../../TextField/TextFieldNormal";

class DesignationMasterFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      masterDept: "",
      desgName: "",
      designationList: [],
      departmentValue: "",
      departmentName: "",
    };
  }

  componentDidMount() {
    this.props.getDepartmentList(
      serverApi.GET_DEPARTMENT_LIST +
      this.state.companyId +
      "/" +
      this.state.userDit.cipher,
      this.props.history
    ); // calling for department list api
  }
  componentWillReceiveProps(props) {
    if (props.departmentList) {
      var list = [];

      if (props.departmentList.data) {
        props.departmentList.data.map((prop) => {
          list.push({
            label: prop.label,
            name: prop.name,
            value: prop.masterDeptId,
          });
        });

        this.setState({
          departmentList: list, // Updating department List
        });
      }
    }
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "desgName") {
      this.setState({
        desgName: event,
      });
    }
    if (key === "masterDept") {
      this.setState({
        masterDept: event,
      });
    }
  };

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "department") {
      if (selectedName == 'Select....') {
        this.setState({
          departmentName: "",
          departmentValue: "",
        });
      } else {
        this.setState({
          departmentValue: selectedValue,
          departmentName: selectedName,
        });
      }
    }
  };

  handleSubmit = () => {
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: this.state.userDit.cipher,
      startIndex: 1,
      lastIndex: 10,
      desig: this.state.desgName,
      departmentName: this.state.departmentName,
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleClear = () => {
    this.setState({
      subDeptCode: "",
      subDeptName: "",
      parentDeptName: "",
    });
  };

  render() {
    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid spacing={0}>
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
              </div>
            </div>
            <div className="procurementFilter">
              <Grid
                item
                xs={12}
                className="filterBlockContainer juteFilterBlock"
              >
                <Box gridColumn="span 3">
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.departmentList}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          selected={this.state.departmentValue}
                          update={this.props.location.state ? 1 : 0}
                          name="department"
                          label="Department"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          value={this.state.desgName}
                          placeholder={"Enter here"}
                          label="Designation Name"
                          caps="OFF"
                          onChange={this.onhandlechange("desgName")}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Box>

                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Search"}
                    className="greenButton"
                    handleClick={this.handleSubmit}
                    type="sumbit"
                  />
                </div>
              </Grid>
            </div>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  const { supplierListMultiselect, statusList, stateNameList, departmentList } =
    state.DropDownListReducer;
  const { VehicleData, agentDropdownList } = state.MasterDropDownListReducer;
  return {
    supplierListMultiselect,
    VehicleData,
    statusList,
    agentDropdownList,
    stateNameList,
    departmentList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSupplierListMultiselect,
    getVehicleList,
    getStatusList,
    getAgentDropdownList,
    getStateNameList,
    getDepartmentList,
  })(DesignationMasterFilter)
);
