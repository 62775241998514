import { all, call, fork, takeEvery, put, select } from "redux-saga/effects";

import { GET_ITEM_DETAILS_BY_POID, INDENT_VIEW_BY_ID, PURCHASE_ORDER_LIST , GET_ITEMLIST_BY_ITEM_GROUP_FOR_PURCHASE, GET_ITEMDETAILS_BYITEM_ITEMGRP_FOR_PURCHASE} from "./actionTypes";
import {
  IndentViewByIdSuccessfull,
  POWOLineItemsProps,
  PurchaseOrderListSuccessfull,
  getItemDetailsByItemItemGrpForPurchaseSuccessfull,
  getItemListByItemGroupForPurcahseSuccessfull,
  getItemDetailsByPoIdSuccessfull,
} from "./actions";
import { getList, getListurl, postCallBacklistData } from "../../../helpers/Server_Helper";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import {limitDecimals} from "../../../Utilities/helper"
import {
  getCateogryTypeList,
  getProjectList,
  getStatusList,
  getSupplierList,
  getClientList,
} from "../../Global/DropDownApis/actions";
import swal from "sweetalert";

function* getPurchaseOrderList({ payload: { url, data, history } }) {
  let userDit = JSON.parse(localStorage.getItem("authUser"));
  showSpinner();
  try {
    const response = yield call(getList, url, data);

    if (response) {
      hideSpinner();
      if (response.data) {
        yield put(
          getCateogryTypeList(serverApi.GET_ALL_CATEGORY_TYPE, history)
        );
        const data1 = {
          cipher: userDit.cipher,
          companyId: localStorage.getItem("companyId"),
        };
        const Url = serverApi.GET_ALL_PROJECTS;
        yield put(getProjectList(Url, data1, history));
        yield put(getStatusList(serverApi.GET_STATUS_LIST, history));
        yield put(
          getClientList(
            serverApi.GET_CLIENT_LIST + localStorage.getItem("companyId"),
            history
          )
        );

        yield put(
          getSupplierList(
            serverApi.GET_ALL_SUPPLIERS_LIST +
              localStorage.getItem("companyId"),
            history
          )
        );
      }
    }
    yield put(PurchaseOrderListSuccessfull({ response }));
  } catch (error) {
    hideSpinner();
    console.log(error);
  }
}
function* IndentViewById({ payload: { url, history,source } }) {
  try {
    const response = yield call(getListurl, url, history);
    hideSpinner();
    if (response) {
      if (response.data) {
        if (response.data.indentDetails) {
          var indentDetailsList = [];
          response.data.indentDetails.map((item) => {
            if (source === "WITHOPENINDENT") {
              indentDetailsList.push({
                id: item.id,
                description: item.description,
                qty: item.qtyPo !== null ? item.qty - item.qtyPo : item.qty,
                uomCode: item.uomCode,
                qtyPo: limitDecimals(item.qtyPo, 3),
                qtyPoOpen: limitDecimals(item.qtyPoOpen, 3),
                cancelledQty: limitDecimals(item.cancelledQty, 3),
                qohCompany: item.qohCompany,
                qohBranch: item.qohBranch,
                qohProject: item.qohProject,
                cancelledBy: item.cancelledBy,
                cancelledByUser: item.cancelledByUser,
                cancelledDate: item.cancelledDate,
                cancelledReasons: item.cancelledReasons,
                rate: limitDecimals(item.rate, 3),
                rateSource: limitDecimals(item.rateSource, 3),
                remarks: item.remarks,
                expectedDeliveryDate: item.expectedDeliveryDate,
                isActive: item.isActive,
                indentId: item.indentId,
                indentName: item.indentName,
                departmentId: item.departmentId,
                departmentName: item.departmentName,
                itemId: item.itemId,
                itemName: item.itemName,
                itemList: [],
                hsnCode:item.hsnCode,
                costcenterId: item.costcenterId,
                costcenterName: item.costcenterName,
                itemGroup: item.itemGroup,
                itemGroupName: item.itemGroup,
                status: item.status,
                statusName: item.statusName,
                lastPurchaseDate: item.lastPurchaseDate,
                lastPurchaseRate: item.lastPurchaseRate,
                budgetHeadId: item.budgetHeadId,
                discountMode: "",
                discountPercentage: "0.00",
                budgetHeadName: item.budgetHeadName,
                itemGroupId: item.itemGroupId,
                remainingQuantity: limitDecimals(item.remainingQuantity, 3),
                stRemQty: item.stRemQty,
                categoryId: item.categoryId,
                taxId: item.taxId,
                taxPercentage: limitDecimals(item.taxPercentage, 2),
                installationRate: limitDecimals(item.installationRate, 3),
                selectedExpenseType: item.selectedExpenseType,
                selectedSubExpenseType: item.selectedSubExpenseType,
                expenseTypeName: item.expenseTypeName,
                subExpenseTypeName: item.subExpenseTypeName,
                make: item.make,
                itemCode: item.itemGroupId + item.itemCode,
                currentStock: "0.000",
                minStock: item.minQty,
                maxStock: item.maxQty,
                minAllowedQty: item.minAllowedQty,
                maxAllowedQty: item.maxAllowedQty,
                reOrderQty: item.reOrderQty,
                tangible : item.tangible,
              });
            } else if (item.qty !== 0) {
              if (Number(item.qty) - Number(item.qtyPo) !== 0) {
                indentDetailsList.push({
                  id: item.id,
                  description: item.description,
                  qty:
                    item.qtyPo !== null
                      ? item.qty - item.qtyPo
                      : limitDecimals(item.qty, 3),
                  uomCode: item.uomCode,
                  qtyPo: limitDecimals(item.qtyPo, 3),
                  qtyPoOpen: limitDecimals(item.qtyPoOpen, 3),
                  cancelledQty: limitDecimals(item.cancelledQty, 3),
                  qohCompany: item.qohCompany,
                  qohBranch: item.qohBranch,
                  qohProject: item.qohProject,
                  cancelledBy: item.cancelledBy,
                  cancelledByUser: item.cancelledByUser,
                  cancelledDate: item.cancelledDate,
                  cancelledReasons: item.cancelledReasons,
                  rate: limitDecimals(item.rate, 3),
                  rateSource: item.rateSource,
                  remarks: item.remarks,
                  expectedDeliveryDate: item.expectedDeliveryDate,
                  isActive: item.isActive,
                  indentId: item.indentId,
                  indentName: item.indentName,
                  departmentId: item.departmentId,
                  departmentName: item.departmentName,
                  discountMode: "",
                  discountPercentage: "0.00",
                  itemId: item.itemId,
                  itemName: item.itemName,
                  itemList: [],
                hsnCode:item.hsnCode,
                  costcenterId: item.costcenterId,
                  costcenterName: item.costcenterName,
                  itemGroup: item.itemGroup,
                  itemGroupName: item.itemGroup,
                  status: item.status,
                  statusName: item.statusName,
                  lastPurchaseDate: item.lastPurchaseDate,
                  lastPurchaseRate: item.lastPurchaseRate,
                  budgetHeadId: item.budgetHeadId,
                  budgetHeadName: item.budgetHeadName,
                  itemGroupId: item.itemGroupId,
                  remainingQuantity: limitDecimals(item.remainingQuantity, 3),
                  stRemQty: limitDecimals(item.stRemQty, 3),
                  categoryId: item.categoryId,
                  taxId: item.taxId,
                  taxPercentage: limitDecimals(item.taxPercentage, 2),
                  installationRate: limitDecimals(item.installationRate, 3),
                  selectedExpenseType: item.selectedExpenseType,
                  selectedSubExpenseType: item.selectedSubExpenseType,
                  expenseTypeName: item.expenseTypeName,
                  subExpenseTypeName: item.subExpenseTypeName,
                  make: item.make,
                  itemCode: item.itemGroupId + item.itemCode,
                  currentStock: "0.000",
                  minStock: item.maxQty,
                  maxStock: item.minQty,
                  minAllowedQty: item.minAllowedQty,
                  maxAllowedQty: item.maxAllowedQty,
                  reOrderQty: "0.000",
                });
              }
            }
          });
          yield put(POWOLineItemsProps(indentDetailsList))
          hideSpinner();
        }
      }
      yield put(IndentViewByIdSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
    hideSpinner();
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        yield put(IndentViewByIdSuccessfull({}));
      }
    }
  }
}


function* getItemDetailsByPoId({ payload: { url, history, source } }) {
  try {
    const response = yield call(getListurl, url, history);
    const { po_wo_lineitems } = yield select((state) => state.PurchaseOrderReducer);

    if (response && response.data) {
      let ItemDetailsID = response.data.data;
      let POWOLineItems = [...po_wo_lineitems];

      if (ItemDetailsID.length !== 0) {
        ItemDetailsID.forEach((item) => {
          if (POWOLineItems && POWOLineItems.length !== 0) {
            POWOLineItems = POWOLineItems.map((lineItem) => {
              if (item.itemId === Number(lineItem.itemId)) {
                return {
                  ...lineItem,
                  branchStock: item.branchStock,
                  centralStock: item.centralStock,
                  maxAllowedQty: item.maxAllowedQty,
                  maxStock: item.maxQty,
                  minAllowedQty: item.minAllowedQty,
                  minStock: item.minQty,
                  poID: item.poID,
                  poLineItemID: item.poLineItemID,
                  reorderQty: item.reorderQty,
                  tangible: item.tangible,
                };
              } else {
                return lineItem;
              }
            });
          }
        });
        yield put(POWOLineItemsProps(POWOLineItems))
      }
      yield put(getItemDetailsByPoIdSuccessfull({ response }));
    }
  } catch (error) {
    if (error.response) {
      console.log(error);
    }
  }
}


function* getItemListByItemItemGroupForPurchase({payload : {url, data, callback, itemgrpId}}){
  try {
    const response = yield call(postCallBacklistData,url, data, callback, itemgrpId);
    yield put(getItemListByItemGroupForPurcahseSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }

}

// getItemListByItemItemGroupAndCompanyId
function* getItemDetailsByItemItemGroupForPurchase({ payload: { url, data, callback, itemId } }) {
  try {
    const response = yield call(postCallBacklistData, url, data, callback, itemId);
    if (response) {
    
      // Handle successful response
      yield put(getItemDetailsByItemItemGrpForPurchaseSuccessfull({ response }));
    }
  } catch (error) {
    hideSpinner(); 
    if (error === undefined) {
      
      // swal("An unexpected error occurred. Please try again later.", { icon: "error" });
    } else if (error.response) {
  
      hideSpinner();
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.error('API error:', error.response.data); 
      } else {
        swal("An unexpected error occurred. Please try again later.", { icon: "error" });
        } 
    } 
  }
}

export function* watchPurchaseOrderList() {
  yield takeEvery(PURCHASE_ORDER_LIST, getPurchaseOrderList);
  yield takeEvery(INDENT_VIEW_BY_ID, IndentViewById);
  yield takeEvery(GET_ITEM_DETAILS_BY_POID, getItemDetailsByPoId);
  yield takeEvery(GET_ITEMLIST_BY_ITEM_GROUP_FOR_PURCHASE, getItemListByItemItemGroupForPurchase);
  yield takeEvery(GET_ITEMDETAILS_BYITEM_ITEMGRP_FOR_PURCHASE, getItemDetailsByItemItemGroupForPurchase);
}

function* PurchaseOrderSaga() {
  yield all([fork(watchPurchaseOrderList)]);
}

export default PurchaseOrderSaga;
