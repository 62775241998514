import { Box, Fade, Grid, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
// import DynamicSelect from "../../../components/Dropdown/Jute/DynamicSelect";
import DynamicSelect from "../../StoreModule/MaterialRequest/DynamicSelect";
import InputAdornment from "../../../components/InputAdorment/InputAdorment";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { Delete } from "@mui/icons-material";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import Button from "../../../components/Buttons/Buttons";
import DeleteIcons from "../../../assets/images/picEditIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { serverApi, serverVars } from "../../../helpers/Consts";
import { getMukamByItemList } from "../../../store/MasterDropdownApis/actions";
import { getJutePoQualitybyItem } from "../../../store/Global/DropDownApis/actions";
import { useHistory, useLocation } from "react-router-dom";
import { getJuteQualityCheckLineItems } from "../../../store/Jute/JuteQualityCheck/actions";
import swal from "sweetalert";
import { limitDecimals } from "../../../Utilities/helper";

const JuteQualityCheckLineItems = () => {
  const [QualityCheckLineItems, setQualityCheckLineItems] = useState([]);
  const [QualityCheckHeaders, setQualityCheckHeaders] = useState([]);
  let dispatch = useDispatch();
  let history = useHistory();
  const [userDit, setuserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const locationState = useLocation();
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [mukamList, setmukamList] = useState([]);
  const [qualitybyItemList, setQualitybyItemList] = useState([]);
  const [avgReadingList, setAvgReadingList] = useState([
    { id: 0, reading: 0, readingId: 1 },
  ]);
  const [avgReading, setAvgReading] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { qualityCheckHeader, juteQualityCheckLineItems } = useSelector(
    (state) => state.JuteQualityCheckReducer
  );

  const { MukamByItemList } = useSelector(
    (state) => state.MasterDropDownListReducer
  );
  const { qualitybyItem } = useSelector((state) => state.DropDownListReducer);
  useEffect(() => {
    if (qualityCheckHeader) {
      dispatch(
        getMukamByItemList(
          serverApi.MUKAM_BY_ITEM_LIST +
            qualityCheckHeader.mukam +
            "/getAllItemByMukam/" +
            localStorage.getItem("companyId") +
            "/" +
            userDit.cipher,
          history
        )
      );
    }
  }, []);

  useEffect(() => {
    if (juteQualityCheckLineItems) {
      setQualityCheckLineItems(juteQualityCheckLineItems);
    }
  }, [juteQualityCheckLineItems]);
  useEffect(() => {
    if (qualityCheckHeader) {
      setQualityCheckHeaders(qualityCheckHeader);
    }
  }, [qualityCheckHeader]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (qualitybyItem) {
      const qualitybyItemList = qualitybyItem.filter((item) => {
        return item.value !== 0;
      });
      setQualitybyItemList(qualitybyItemList);
    }
  }, []);

  useEffect(() => {
    if (MukamByItemList.data) {
      if (MukamByItemList.data.length !== 0) {
        let ListFilter = MukamByItemList.data.filter((item) => {
          if (item.value !== 0) {
            return item;
          }
        });
        let list = [];
        ListFilter.map((prop) => {
          list.push({
            label: prop.label,
            name: prop.name,
            value: prop.value,
            variant: prop.name.split("^")[1],
          });
        });
        setmukamList(list);
      }
    }
  }, [MukamByItemList]);

  const handlePropSelectChange = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    lineItemId
  ) => {
    var newline = 1;
    var lastRow = QualityCheckLineItems[QualityCheckLineItems.length - 1];
    if (name === "actualJuteTyp") {
      let lineItems = QualityCheckLineItems.filter((row) => {
        if (row.lineItemId === selectedRow.lineItemId) {
          let splittedName = selectedName.split("^");
          row.actualJuteTyp = selectedValue;
          row.qcJuteType = selectedValue;
          row.advisedJuteTyp = selectedValue;
          row.createdBy = userDit.userId;
          row.itemDesc = splittedName[0];
          row.itemGroupId = splittedName[1];
          row.average_reading = 0;
          var url =
            serverVars.PO_API +
            "api/v1/" +
            selectedValue +
            "/" +
            localStorage.getItem("companyId") +
            "/getQualityByItem";
          dispatch(getJutePoQualitybyItem(url, history));
        }
        newline = Number(row.lineItemId);
        return row;
      });
      if (name === "actualJuteTyp") {
        console.log(selectedRow, lastRow);
        if (selectedRow === lastRow) {
          var obj = {
            lineItemId: Number(newline) + 1,
            actualJuteTyp: 0,
            actualQuality: 0,
            actualQuantity: "0",
            actualWeight: "0",
            advisedJuteTyp: 0,
            actualQualityDesc: "",
            advisedQuality: 0,
            advisedQuantity: "0",
            advisedWeight: "0",
            createdBy: "",
            isPoAmment: "",
            itemDesc: "",
            juteGateSrlNo: "",
            kgs: "",
            poLineItemNum: "",
            qcJuteQuality: 0,
            qcJuteQuantity: "0",
            qcJuteType: 0,
            qcJuteWeight: "0",
            quantity: "0",
            receivedIn: "",
            unitId: "",
            recId: 0,
            hdrId: 0,
            readings: [
              {
                id: 0,
                reading: 0,
                readingId: 1,
              },
            ],
            avgReading: 0,
          };
          lineItems.push(obj);
        }
        console.log(lineItems);
        setQualityCheckLineItems(lineItems);
        dispatch(getJuteQualityCheckLineItems(lineItems));
      }
    }
    if (name === "actualQuality") {
      let lineItems = QualityCheckLineItems.filter((row) => {
        if (row.lineItemId === selectedRow.lineItemId) {
          let splittedName = selectedName.split("^");
          row.actualQuality = selectedValue;
          row.advisedQuality = selectedValue;
          row.qcJuteQuality = selectedValue;
          row.qcJuteQuantity = selectedValue;
          row.actualQualityDesc = splittedName[0];
          row.itemCode = row.itemGroupId + selectedValue;
        }
        return row;
      });
      setQualityCheckLineItems(lineItems);
      dispatch(getJuteQualityCheckLineItems(lineItems));
    }
  };

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };

  const handleChange = (key, value, lineItemId) => {
    if (key === "actualQuantity") {
      let lineItems = juteQualityCheckLineItems.filter((row) => {
        if (row.lineItemId === lineItemId) {
          row.actualQuantity = value;
          row.advisedQuantity = value;
          row.qcJuteQuantity = value;
          row.quantity = value;
          row.receivedIn =
            qualityCheckHeader.unitConversionId === 1 ? "DRUMS" : "BALE";
          row.unitId = "QNT";
          row.average_reading = 0;
        }
        return row;
      });
      setQualityCheckLineItems(lineItems);
      dispatch(getJuteQualityCheckLineItems(lineItems));
    }
    if (key === "actualWeight") {
      let lineItems = juteQualityCheckLineItems.filter((row) => {
        if (row.lineItemId === lineItemId) {
          const actualWeight = checkActualWeight(lineItemId, value);
          let net_total = 0;

          if (locationState.pathname === "/update_jute_quality_check") {
            row.actualWeight = value;
            row.qcJuteWeight = value;

            net_total = QualityCheckLineItems.map((p) => p.actualWeight).reduce(
              (total, ind) =>
                Number(parseFloat(total).toFixed(2)) +
                Number(parseFloat(ind).toFixed(2))
            );
            qualityCheckHeader.net_total = net_total;
          } else {
            if (
              Number(actualWeight) >
              Number(qualityCheckHeader.challanWeight_Qtl)
            ) {
              row.actualWeight = 0;
              row.qcJuteWeight = 0;

              swal(
                "Sum of weight should not exceed Challan Weight if" +
                  qualityCheckHeader.challanWeight_Qtl +
                  "..!!",
                { icon: "error" }
              );
            } else {
              row.actualWeight = value;
              row.qcJuteWeight = value;
              net_total = QualityCheckLineItems.map(
                (p) => p.actualWeight
              ).reduce(
                (total, ind) =>
                  Number(parseFloat(total).toFixed(2)) +
                  Number(parseFloat(ind).toFixed(2))
              );
              qualityCheckHeader.net_total = net_total;
            }
          }
        }
        return row;
      });
      setQualityCheckLineItems(lineItems);
      dispatch(getJuteQualityCheckLineItems(lineItems));
    }
  };
  const checkActualWeight = (lineItemId, value) => {
    let actualWeight = 0;
    QualityCheckLineItems.filter((row) => {
      if (row.lineItemId == lineItemId) {
        actualWeight = Number(actualWeight) + Number(value);
      } else {
        actualWeight = Number(actualWeight) + Number(row.actualWeight);
      }
    });
    return actualWeight;
  };

  const onReadingDelete = (readingId) => {
    let avgReadingData = avgReadingList.filter(
      (item) => item.readingId !== readingId
    );
    let lineItems = juteQualityCheckLineItems.map((row) => {
      row.readings.filter((readingItem) => {
        if (readingItem.readingId !== readingId) {
          row.readings = avgReadingData;
          row.avgReadings = calculateAverage(avgReadingData);
        }
      });
      return row;
    });
    setAvgReadingList(avgReadingData);
    setQualityCheckLineItems(lineItems);
    dispatch(getJuteQualityCheckLineItems(lineItems));
  };

  const handleOpenRedings = (lineItemId, readings) => {
    let readingsList = readings;
    if (readings.length === 0) {
      let newItem = { id: 0, reading: 0, readingId: readings.length + 1 };
      readingsList.push(newItem);
      setAvgReadingList(readingsList);
      console.log(juteQualityCheckLineItems)
      let lineItems = juteQualityCheckLineItems.map((item) => {
        if (item.lineItemId === lineItemId) {
          item.readings = readingsList;
        }
        return item
      });
      console.log(lineItems)
      setQualityCheckLineItems(lineItems);
      dispatch(getJuteQualityCheckLineItems(lineItems));
    } else {
      let lineItems = juteQualityCheckLineItems.map((item) => {
          if (item.lineItemId === lineItemId) {
            item.readings = readingsList;
          }
          return item
        });
        console.log(lineItems)
      setAvgReadingList(readings);
      setQualityCheckLineItems(lineItems);
      dispatch(getJuteQualityCheckLineItems(lineItems));
    }
    setIsModalOpen(true);
  };

  const handleReadingData = (event, readingId) => {
    let avgReadingData = avgReadingList;
    const validValue = event.target.value.match(/^\d*\.?\d{0,2}$/)
      ? event.target.value
      : event.target.value.slice(0, -1);
    let value = validValue ? parseFloat(validValue) : event.target.value;
    if (validValue !== event.target.value) {
      swal(" Decimal Values should not be more than two digits.");
    }
  
    let newObject = {
      id: 0,
      reading: 0,
      readingId: avgReadingList.length + 1,
    };
    if (avgReadingData) {
      avgReadingData = avgReadingData.filter((row) => {
        if (row.readingId === readingId) {
          if (value <= 100) {
            row.reading = value;
          } else {
            swal("Readings should not be more than 100.");
          }
        }
        return row;
      });
    }
    if (
      avgReadingData[event.target.id].reading !== 0 &&
      event.target.value.length === 1 &&
      avgReadingData[parseInt(event.target.id) + 1] === undefined
    ) {
      avgReadingData.push(newObject);
    }
    setAvgReadingList(avgReadingData);
    let lineItems = juteQualityCheckLineItems.map((row) => {
      row.readings.filter((readingItem) => {
        if (readingItem.readingId === readingId) {
          row.readings = avgReadingData;
          row.avgReadings = limitDecimals(calculateAverage(avgReadingData), 2);
        }
      });
      return row;
    });
    setQualityCheckLineItems(lineItems);
    dispatch(getJuteQualityCheckLineItems(lineItems));
  };
  const calculateAverage = (arr) => {
    const avgValTotal =
      arr.reduce((acc, el) => acc + Number(el.reading), 0) || 0;
    const validArrLength = arr.filter((el) => el.reading).length;
    return avgValTotal / validArrLength || 0;
  };
  //  for the future use i am commenting this code
  // const addReading = () => {
  //   let avgReadingData = avgReadingList;
  //   let newObject = {
  //     id: avgReadingList.length + 1,
  //     reading: 0,
  //     rowId: avgReadingList.length + 1,
  //   };
  //   if (avgReadingList.length) {
  //     avgReadingData.push(newObject);
  //   }
  //   console.log(avgReadingData)
  //   setAvgReadingList(avgReadingData);
  // };

  const onDel = (row) => {
    let FilterLineItems = QualityCheckLineItems.filter(
      (item) => item.isActive !== 0
    );
    if (FilterLineItems.length === 1) {
      swal("you can't delete the last lineitem");
    } else {
      console.log(QualityCheckLineItems);
      let indexItem = QualityCheckLineItems.findIndex((item) => item === row);
      let lineItems = [...QualityCheckLineItems];
      lineItems[indexItem].isActive = 0;
      setQualityCheckLineItems(lineItems);
      dispatch(getJuteQualityCheckLineItems(lineItems));
      console.log(lineItems);
    }
  };

  const saveReadings = () => {
    if (avgReadingList.length >= 3) {
      setIsModalOpen(false);
    } else {
      swal("Atleast 3 Readings Mandatory..!!", { icon: "error" });
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let FilterLineItems = QualityCheckLineItems.filter(
    (item) => item.isActive !== 0
  );
  let lastLineItem = QualityCheckLineItems[QualityCheckLineItems.length - 1];
  return (
    <div className="editableTableContainer">
      <div className="gridContainer">
        <div className="gridComponent">
          <table cellSpacing="0px" cellPadding="0px">
            <thead>
              <tr className="tableRowBlock">
                {tableHeaders.map((column) => (
                  <>
                    {column.header === "Item" ||
                    column.header === "Quality" ||
                    column.header === "Quantity" ||
                    column.header === "Weight" ? (
                      <th>
                        {column.header}
                        <span style={{ color: "red" }}>*</span>
                      </th>
                    ) : (
                      <th>{column.header}</th>
                    )}
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {FilterLineItems &&
                FilterLineItems.map((row) => (
                  <tr>
                    <>
                      {RowData
                        ? RowData.map((item) =>
                            item.component === "DynamicSelect" &&
                            item.name === "actualJuteTyp" ? (
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <DynamicSelect
                                        arrayOfData={mukamList}
                                        className="dropdownBlockContainer"
                                        onSelectChange={handlePropSelectChange}
                                        isDropdownOpen={isDropdownOpen}
                                        handleMenuOpen={handleMenuOpen}
                                        selected={Number(row.actualJuteTyp)}
                                        name="actualJuteTyp"
                                        row={row}
                                        update={row.actualJuteTyp ? 1 : 0}
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            ) : item.component === "DynamicSelect" &&
                              item.name === "actualQuality" ? (
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <DynamicSelect
                                        arrayOfData={qualitybyItemList}
                                        className="dropdownBlockContainer"
                                        onSelectChange={handlePropSelectChange}
                                        isDropdownOpen={isDropdownOpen}
                                        handleMenuOpen={handleMenuOpen}
                                        selected={Number(row.actualQuality)}
                                        name="actualQuality"
                                        row={row}
                                        update={row.actualQuality ? 1 : 0}
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            ) : item.component === "InputField" ? (
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        type="text"
                                        name={item.type}
                                        Value={row[item.type]}
                                        disabled={true}
                                        className="inputBlockContainer"
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            ) : item.component === "InputAdoment" ? (
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <InputAdornment
                                        label={
                                          item.type === "quantity"
                                            ? qualityCheckHeader.unitConversionId ===
                                              1
                                              ? "DRUMS"
                                              : "BALE"
                                            : item.label
                                        }
                                        type="text"
                                        isNumber={true}
                                        name={item.type}
                                        lineItemId={row.lineItemId}
                                        handleChange={handleChange}
                                        // unitConv={this.state.unitConversionId}
                                        value={row[item.type]}
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            ) : (
                              ""
                            )
                          )
                        : ""}
                    </>
                    <td className="">
                      {locationState.pathname ===
                      "/update_jute_quality_check" ? (
                        <button
                          className="deleteDisplayButton"
                          type="button"
                          onClick={() =>
                            handleOpenRedings(row.lineItemId, row.readings)
                          }
                        >
                          <i
                            className="fa fa-thermometer-full"
                            style={{ marginRight: "5px" }}
                          />
                        </button>
                      ) : (
                        ""
                      )}
                      <span>&nbsp;</span>
                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title="Delete"
                      >
                        <IconButton
                          onClick={() => {
                            onDel(row);
                          }}
                          disabled={
                            FilterLineItems.length === 1
                              ? true
                              : lastLineItem === row
                              ? true
                              : false
                          }
                        >
                          <Delete className="deleteDisplayButton" />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {/* {this.props.location.state ? (
            <>
              <div
                className="d-flex cloumnButtonBlock"
                style={{ marginTop: "10px" }}
              >
                <Box gridColumn="span 4">
                  <div>Support Documents:</div>
                  <div className="personalDetailsUpload">
                    <div className="ducumentUploadBlock">
                      <div className="documentUploadContent">
                        <span className="btn_upload documentUpload">
                          <img src={panUploadIcon} alt="" />
                          <input
                            type="file"
                            id="imag"
                            title=""
                            className="input-img"
                            onChange={this.UploadDocuments()}
                          />
                          Upload Document
                        </span>
                      </div>
                    </div>
                  </div>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 3">
                        {this.state.files_List &&
                          this.state.files_List.map((item) => (
                            <>
                              <div>
                                <div
                                  className="uploadedInfo"
                                  style={{ display: "flex" }}
                                >
                                  <img
                                    src={TickIcon}
                                    alt=""
                                    className="tickIcon"
                                    height={"20px"}
                                    width={"20px"}
                                  />
                                  <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    title="Click to download File"
                                  >
                                    <div
                                      onClick={() => {
                                        // getFileDownload(
                                        //   serverConfig.SERVER_URL +
                                        //   "security-api/api/files/downloadfile/" +
                                        //   item.fileUploadId,
                                        //   item.fileName,
                                        //   item.fileExtension
                                        // );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {item.fileName}
                                    </div>
                                  </Tooltip>

                                  <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 100 }}
                                    title="Click to Delete File"
                                  >
                                    <div
                                      onClick={() => {
                                        this.onDeleteFile(item.fileUploadId);
                                      }}
                                      className="deleteButton"
                                    >
                                      <img
                                        src={DeleteIcons}
                                        alt=""
                                        height={"20px"}
                                        width={"20px"}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>
                            </>
                          ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </>
          ) : (
            <>
              <div></div>
            </>
          )} */}
        </div>
        {isModalOpen && (
          <CommonPopup>
            <div className="editableTableContainer">
              <div className="holidayTable">
                <div className="gridContainer">
                  <div className="gridComponent">
                    <table>
                      <thead>
                        <tr>
                          <th>Sl No.</th>
                          <th>Readings</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {avgReadingList ? (
                          avgReadingList.map((item, index) => (
                            <tr>
                              <td>{item.readingId}</td>
                              <td>
                                <input
                                  type="number"
                                  id={index}
                                  step="any"
                                  className="inputBlockContainer"
                                  value={item.reading}
                                  onChange={(e) =>
                                    handleReadingData(e, item.readingId)
                                  }
                                />
                              </td>
                              <td>
                                <Tooltip
                                  TransitionComponent={Fade}
                                  TransitionProps={{
                                    timeout: 100,
                                  }}
                                  title="Click to Delete File"
                                >
                                  <div
                                    onClick={() => {
                                      onReadingDelete(item.readingId);
                                    }}
                                    className="deleteButton"
                                  >
                                    <img
                                      src={DeleteIcons}
                                      alt=""
                                      height={"20px"}
                                      width={"20px"}
                                    />
                                  </div>
                                </Tooltip>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                    {/* for the future use i am commenting this code */}
                    {/* <div>
                                    <Button
                                      label={"Add Reading"}
                                      className="greenBorderButton"
                                      handleClick={() => addReading()}
                                    />
                                  </div> */}
                    <div className="consoleFormButtonBlock">
                      <Button
                        label={"Save"}
                        className="greenBorderButton"
                        handleClick={() => saveReadings()}
                        type="submit"
                      />
                      <Button
                        label={"Cancel"}
                        className="greenBorderButton"
                        type="button"
                        handleClick={() => handleCancel()} // Call handleCancel on Cancel button click
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CommonPopup>
        )}
      </div>
    </div>
  );
};
const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "actualJuteTyp",
    dropDown: "dropDown",
    name: "actualJuteTyp",
    Items: true,
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "actualQuality",
    dropDown: "dropDown",
    name: "actualQuality",
    Items: true,
  },
  {
    no: 3,
    component: "InputAdoment",
    type: "actualQuantity",
    label: "DRUM",
  },
  {
    no: 4,
    component: "InputAdoment",
    type: "actualWeight",
    label: "QTL",
  },
  {
    no: 5,
    component: "InputField",
    type: "avgReadings",
  },
  {
    no: 6,
    component: "InputField",
    type: "unitId",
  },
];

const tableHeaders = [
  {
    header: "Item",
  },
  {
    header: "Quality",
  },
  {
    header: "Quantity",
  },
  {
    header: "Weight",
  },
  {
    header: "Average Reading",
  },
  {
    header: "Unit",
  },

  {
    header: "Action",
  },
];

export default JuteQualityCheckLineItems;
