import {ADDITEM_TO_TAX_LIST,ADDITEM_TO_TAX_LIST_SUCCESSFULL} from './actionTypes';



export const postAddItemToTaxList = (url,data,history) =>{
    return {
        type:ADDITEM_TO_TAX_LIST,
        payload:{url,data,history}
    }
}

export const addItemToTaxListSuccessfull = (data)=>{
    return {
        type:ADDITEM_TO_TAX_LIST_SUCCESSFULL,
        payload:data
    }
}



