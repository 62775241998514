import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/PaginationGridView";
import { getPurchaseOrderList } from "../../../store/Purchase/PurchaseOrder/actions";
import { getWorkOrderList } from "../../../store/Purchase/WorkOrder/actions";

import moment from "moment";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      purchaseOrderListData: [],
      workOrderListData: [],
      startDate: moment().day(-7).format("YYYY-MM-DD").toString(),
      endDate: moment().format("YYYY-MM-DD").toString(),
      pototalRecs: 0,
      wototalRecs:0,
      startIndex: 1,
      lastIndex: 10,
      dataexp:"",
      branchId:"",
      project:"",
      projectId:""

    };
  }

  componentDidMount() {
    const financialYearStartMonth = 3; // Assuming financial year starts in April
    let financialYearStart = moment()
      .year(localStorage.getItem("acadamicYear"))
      .month(financialYearStartMonth)
      .startOf("month");

    const fromDate = financialYearStart.day(-7).format("YYYY-MM-DD");
    const toDate = moment().format("YYYY-MM-DD");

    var startDate = moment(fromDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var endDate = moment(toDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    if (this.props.location.pathname === "/purchase_purchase_order") {
      // for newpaginationapi
      let datanew = {
        companyId: localStorage.getItem("companyId"),
        startDate: startDate,
        endDate: endDate,
        financialYear: localStorage.getItem("acadamicYear"),
        purchaseType: "store",
        status: "",
        branchId: "",
        phase: "",
        project: "",
        // supplierId: "11032",
        supplierId: this.state.userDit.roleId ===100 ?this.state.userDit.suppCode :null,
        recordType: "PO",
        customerId: "",
        lastIndex: 10,
        startIndex: 0,
        statusList:this.state.userDit.roleId === 100?["3","5","6"]:[]
      };
  
      this.props.getPurchaseOrderList(
        serverApi.WORKORDER_LIST_BY_CMPY_ID,
        datanew,
        this.props.history
      );
      showSpinner();
      const expdata = {
        ...datanew,
        startIndex : 0,
        lastIndex : 0,
      }
      this.setState({ 
         dataexp: expdata,
         startDate: startDate,
         endDate: endDate,
         });
    } else {
      const data = {
        branchId: "",
        companyId: localStorage.getItem("companyId"),
        endDate: endDate,
        financialYear: localStorage.getItem("acadamicYear"),
        phase: "",
        project: "",
        purchaseType: "store",
        recordType: "WO",
        startDate: startDate,
        status: "",
        supplierId:
           this.state.userDit.roleId === 100
             ? this.state.userDit.suppCode: null,
        lastIndex: 10,
        startIndex: 0,
        statusList:this.state.userDit.roleId === 100?["3","5","6"]:[]
      };
      this.props.getWorkOrderList(
        serverApi.WORKORDER_LIST_BY_CMPY_ID,
        data,
        this.props.history
      );
      showSpinner();
      const expdata = {
        ...data,
        startIndex : 0,
        lastIndex : 0,
      }
      this.setState({  
        dataexp: expdata ,
        startDate: startDate,
        endDate: endDate,
      });
    }
  }
  componentWillReceiveProps(props) {
    if (props.PurchaseOrder_List) {
      this.setState({
        purchaseOrderListData: props.PurchaseOrder_List.data,
        pototalRecs: props.PurchaseOrder_List.totalRecords,
      });
     
    }
    if (props.workOrder_List) {

      this.setState({
        workOrderListData: props.workOrder_List.data,
        wototalRecs: props.workOrder_List.totalRecords,
      });
    
    }
  }

  handleSearch = (data1) => {

    if (this.props.location.pathname === "/purchase_purchase_order") {
      // for newpaginationapi
      let datanew = {
        companyId: localStorage.getItem("companyId"),
        startDate: data1.fromDate,
        endDate: data1.toDate,
        financialYear: localStorage.getItem("acadamicYear"),
        purchaseType: "store",
        status: data1.statusValue,
        branchId: data1.branchId,
        phase: "",
        projectId: data1.projctValue,
        // supplierId: this.state.userDit.roleId ===100 ?this.state.userDit.suppCode :data1.supplierValue,
        supplierId: data1.supplierValue,
        recordType: "PO",
        poNum: data1.PoNO,
        customerId: data1.customerId,
        lastIndex: 10,
        startIndex: 0,
        category: data1.category,
        statusList:this.state.userDit.roleId === 100?["3","5","6"]:[]
      };
      const expdata = {
        ...datanew,
        startIndex : 0,
        lastIndex : 0,
      }
  this.setState({ 
     dataexp: expdata,
     projectId: data1.projctValue,
     status: data1.statusValue, 
     supplierId: data1.supplierValue,
     startDate: data1.fromDate,
     endDate: data1.toDate,
    });
      this.props.getPurchaseOrderList(
        serverApi.WORKORDER_LIST_BY_CMPY_ID,
        datanew,
        this.props.history
      );
      showSpinner();
    } 
    if(this.props.location.pathname === "/work_order")
    {
      const data = {
        companyId: localStorage.getItem("companyId"),
        startDate: data1.fromDate,
        endDate: data1.toDate,
        financialYear: localStorage.getItem("acadamicYear"),
        purchaseType: "store",
        status: data1.statusValue,
        branchId: "",
        phase: "",
        projectId: data1.projctValue,
        // supplierId: this.state.userDit.roleId ===100 ?this.state.userDit.suppCode :data1.supplierValue,
        supplierId: data1.supplierValue,
        recordType: "WO",
        poNum: data1.WoNO,
        customerId: data1.customerId,
        lastIndex: 10,
        startIndex: 0,
        category: data1.category,
        statusList:this.state.userDit.roleId === 100?["3","5","6"]:[]
      };
      const expdata = {
        ...data,
        startIndex : 0,
        lastIndex : 0,
      }
  this.setState({  dataexp: expdata , projectId: data1.projctValue,status: data1.statusValue, supplierId: data1.supplierValue, category:data1.category,});
      this.props.getWorkOrderList(
        serverApi.WORKORDER_LIST_BY_CMPY_ID,
        data,
        this.props.history
      );
      showSpinner();
      this.setState({
        startDate: data1.fromDate,
        endDate: data1.toDate
      })
    }
  
  };

  handlePagination = (page, sizePerPage, type) => {
    // let startDate = moment(this.state.startDate, "YYYY-MM-DD", true).format(
    //   "DD-MM-YYYY"
    // );
    // let endDate = moment(this.state.endDate, "YYYY-MM-DD", true).format(
    //   "DD-MM-YYYY"
    // );
    if (this.props.location.pathname === "/purchase_purchase_order") {
      const data = {
        companyId: localStorage.getItem("companyId"),
        startDate:this.state.startDate,
        endDate: this.state.endDate,
        financialYear: localStorage.getItem("acadamicYear"),
        purchaseType: "store",
        status: "",
        branchId: "",
        phase: "",
        project: "",
        projectId:this.state.projectId,
        supplierId: this.state.supplierId,
        // supplierId:
        //    this.state.userDit.roleId === 100
        //      ? this.state.userDit.suppCode: null,
        recordType: "PO",
        customerId: "",
        lastIndex: sizePerPage,
        startIndex: page - 1,
        statusList:this.state.userDit.roleId === 100?["3","5","6"]:[]
      };
      this.props.getPurchaseOrderList(
        serverApi.WORKORDER_LIST_BY_CMPY_ID,
        data,
        this.props.history
      );
      showSpinner();
    } else {
      const data = {
        companyId: localStorage.getItem("companyId"),
        startDate:this.state.startDate,
        endDate: this.state.endDate,
        financialYear: localStorage.getItem("acadamicYear"),
        purchaseType: "store",
        status: this.state.status,
        branchId: "",
        phase: "",
        project: "",
        projectId:this.state.projectId,
        supplierId: this.state.supplierId,
        category: this.state.category,
        recordType: "WO",
        customerId: "",
        lastIndex: sizePerPage,
        startIndex: page - 1,
        statusList:this.state.userDit.roleId === 100?["3","5","6"]:[]
      };
      this.props.getWorkOrderList(
        serverApi.WORKORDER_LIST_BY_CMPY_ID,
        data,
        this.props.history
      );
      showSpinner();
    }
    this.setState({
      startIndex: page - 1,
      lastIndex: sizePerPage,
      loadingdata: true,
    });
    // }
  };

  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={
            this.props.location.pathname === "/purchase_purchase_order"
              ? this.state.purchaseOrderListData
              : this.state.workOrderListData
          }
          mcolumns={
            this.props.location.pathname === "/purchase_purchase_order"
              ? purchaseOrderListColumns
              : workOrderListColumns
          }
          micon={GridArrowIcon}
          status={true}
          actions={true}
          filter_form={
            this.props.location.pathname === "/purchase_purchase_order"
              ? "PurchaseOrder"
              : "workOrder"
          }
          handleSearch={this.handleSearch}
          url={
            this.props.location.pathname === "/purchase_purchase_order"
              ? "/create_purchase_order"
              : "/create_workorder"
          }
          totalRecs={ this.props.location.pathname === "/purchase_purchase_order"?this.state.pototalRecs:this.state.wototalRecs}
          pageno={this.state.pageno}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          apiexp={ serverApi.OLDWORKORDER_LIST_BY_CMPY_ID}
          payloadexp={this.state.dataexp}
          expName={this.props.location.pathname === "/purchase_purchase_order"?"PurchaseOrder.csv":"WorkOrder.csv"}
          fromDate={this.state.startDate}
          toDate={this.state.endDate}
        />
      </>
    );
  }
}

const purchaseOrderListColumns = [
  {
    Header: "Po Number",
    accessor: "poSequenceNo",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "PO Id",
    accessor: "id",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Po Date",
    accessor: "poDate",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Indent/BOM",
    accessor: "indentSequence",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Supplier Name",
    accessor: "supplierName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Po Value",
    accessor: "poValueString",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "statusName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Po Approve Date",
    accessor: "poApproveDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Project Id",
    accessor: "projectId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

  {
    Header: "Source",
    accessor: "source",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Supplier Id",
    accessor: "supplierId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Company Id",
    accessor: "companyId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Branch Id",
    accessor: "branchId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "TaxType Id",
    accessor: "taxTypeId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tax Type",
    accessor: "taxType",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tax Type Name",
    accessor: "taxTypeName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Discount Mode",
    accessor: "discountMode",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Discount Value",
    accessor: "discountValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Shop Mode",
    accessor: "shopMode",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill To Address",
    accessor: "billToAddress",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill To State Code",
    accessor: "billToStateCode",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill To State Name",
    accessor: "billToStateName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ship To Address",
    accessor: "shipToAddress",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ship To State Code",
    accessor: "shipToStateCode",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ship To State Name",
    accessor: "shipToStateName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Freight Charges",
    accessor: "freightCharges",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Terms and Conditions",
    accessor: "termsNconditions",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Footer Notes",
    accessor: "footerNotes",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Delivery Instructions",
    accessor: "deliveryInstructions",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Status",
    accessor: "status",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

  {
    Header: "Credit Days",
    accessor: "creditDays",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Expected Delivery Date",
    accessor: "expectedDeliveryDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Remarks",
    accessor: "remarks",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Indent Id",
    accessor: "indentId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Price Enquiry Id",
    accessor: "priceEnquiryId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Company Name",
    accessor: "companyName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Branch Name",
    accessor: "branchName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Project Name",
    accessor: "projectName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  // {
  //   Header: "Supplier Name",
  //   accessor: "supplierName",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: false,
  // },
  {
    Header: "Supplier Branch Id",
    accessor: "supplierBranchId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Supplier Branch Name",
    accessor: "supplierBranchName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tax Payable",
    accessor: "taxPayable",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Deliver TimeLine",
    accessor: "deliverTimeLine",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Po Till Date",
    accessor: "poTillDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "RQN Name",
    accessor: "rqnName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Phase Id",
    accessor: "phaseId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Phase Name",
    accessor: "phaseName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Category",
    accessor: "category",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Category Name",
    accessor: "categoryName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Po Details",
    accessor: "poDetails",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

  {
    Header: "Value Without Tax",
    accessor: "valueWithoutTax",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Shipping Branch Id",
    accessor: "supplierBranchId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Shipping Branch Name",
    accessor: "shippingBranchName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Billing Branch Id",
    accessor: "billingBranchId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Billing Branch Name",
    accessor: "billingBranchName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Approve Button",
    accessor: "approveButton",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Final Approver",
    accessor: "finalApprover",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Item Group",
    accessor: "itemGroup",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Budget Head Id",
    accessor: "budgetHeadId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Last Modified By",
    accessor: "lastModifiedBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Last Modified Date",
    accessor: "lastModifiedDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Customer Id",
    accessor: "customerId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Client",
    accessor: "customerName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Advance Type",
    accessor: "advanceType",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Advance Percentage",
    accessor: "advancePercentage",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Advance Amount",
    accessor: "advanceAmount",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Status Desc",
    accessor: "statusDesc",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Po Note",
    accessor: "poNote",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Contact Person",
    accessor: "contactPerson",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Contact No",
    accessor: "contactNo",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

  {
    Header: "Indent Title",
    accessor: "indentTitle",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
];

const workOrderListColumns = [
  {
    Header: "Wo Number",
    accessor: "poSequenceNo",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "WO Date",
    accessor: "poApproveDate",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Supplier Name",
    accessor: "supplierName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Wo Value",
    accessor: "valueWithTax",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Status Name",
    accessor: "statusName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Branch Name",
    accessor: "branchName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Project Name",
    accessor: "projectName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Client",
    accessor: "customerName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Project Id",
    accessor: "projectId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Source",
    accessor: "source",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Supplier Id",
    accessor: "supplierId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Company Id",
    accessor: "companyId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Branch Id",
    accessor: "branchId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tax TypeId",
    accessor: "taxTypeId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tax Type",
    accessor: "taxType",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tax Type Name",
    accessor: "taxTypeName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Discount  Mode",
    accessor: "discountMode",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Discount Value",
    accessor: "discountValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Shop Mode",
    accessor: "shopMode",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill To Address",
    accessor: "billToAddress",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill To State Code",
    accessor: "billToStateCode",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill To State Name",
    accessor: "billToStateName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ship To Address",
    accessor: "shipToAddress",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

  {
    Header: "Ship To State Code",
    accessor: "shipToStateCode",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ship To State Name",
    accessor: "shipToStateName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Freight Charges",
    accessor: "freightCharges",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Terms and conditions",
    accessor: "termsNconditions",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Footer Notes",
    accessor: "footerNotes",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Delivery Instructions",
    accessor: "deliveryInstructions",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Status",
    accessor: "status",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

  {
    Header: "Credit Days",
    accessor: "creditDays",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Expected Delivery Date",
    accessor: "expectedDeliveryDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Remarks",
    accessor: "remarks",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Indent Id",
    accessor: "indentId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Price Enquiry Id",
    accessor: "priceEnquiryId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

  {
    Header: "Supplier Branch Id",
    accessor: "supplierBranchId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Supplier Branch Name",
    accessor: "supplierBranchName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tax Payable",
    accessor: "taxPayable",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Deliver TimeLine",
    accessor: "deliverTimeLine",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Po Till Date",
    accessor: "poTillDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Rqn Name",
    accessor: "rqnName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "phase Id",
    accessor: "phaseId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Phase Name",
    accessor: "phaseName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Category",
    accessor: "category",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Category Name",
    accessor: "categoryName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Po Details",
    accessor: "poDetails",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

  {
    Header: "Value Without Tax",
    accessor: "valueWithoutTax",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Shipping Branch Id",
    accessor: "shippingBranchId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Shipping Branch Name",
    accessor: "shippingBranchName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Billing Branch Id",
    accessor: "billingBranchId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Billing Branch Name",
    accessor: "billingBranchName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Item Group",
    accessor: "itemGroup",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Budget Head Id",
    accessor: "budgetHeadId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Last Modified By",
    accessor: "lastModifiedBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Last Modified Date",
    accessor: "lastModifiedDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Customer Id",
    accessor: "customerId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

  {
    Header: "Advance Type",
    accessor: "advanceType",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Advance Percentage",
    accessor: "advancePercentage",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Advance Amount",
    accessor: "advanceAmount",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Status Desc",
    accessor: "statusDesc",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Po Note",
    accessor: "poNote",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Contact Person",
    accessor: "contactPerson",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Contact No",
    accessor: "contactNo",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Indent Sequence",
    accessor: "indentSequence",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Indent Title",
    accessor: "indentTitle",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
];

const mapStatetoProps = (state) => {
  const { PurchaseOrder_List } = state.PurchaseOrderReducer;
  const { workOrder_List } = state.workOrder_List;
  return { PurchaseOrder_List, workOrder_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getPurchaseOrderList,
    getWorkOrderList,
  })(Index)
);
