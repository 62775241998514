import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Button, Step, Stepper, StepLabel } from "@mui/material";
import Link from "@mui/material/Link";
import JuteQualityCheckFillDetails from "./JuteQualityCheckFillDetails";
import JuteQualityCheckLineItems from "./JuteQualityCheckLineItems";
import CustomButton from "../../../components/Buttons/Buttons";
import JuteQualityCheckPreview from "./JuteQualityCheckPreview";
import { serverApi } from "../../../helpers/Consts";
import {
  juteQualityCheckViewById,
  juteQualityCheckUpdate,
} from "../../../store/Jute/JuteQualityCheck/actions";
import { getMukamByItemList } from "../../../store/MasterDropdownApis/actions";
import swal from "sweetalert";

const CreateJuteQualityCheck = () => {
  const history = useHistory();
  const locationState = useLocation();
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(2);
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [companyId] = useState(localStorage.getItem("companyId"));
  const [steps] = useState([
    {
      title: "Update Details",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Update Items",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Preview",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive03.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
  ]);
  const { qualityCheckHeader, juteQualityCheckLineItems } = useSelector(
    (state) => state.JuteQualityCheckReducer
  );

  useEffect(() => {
    if (locationState.state !== undefined) {
      if (locationState.state.rowData !== undefined) {
        console.log(locationState.state.rowData);
        dispatch(
          juteQualityCheckViewById(
            serverApi.GET_JUTE_GATE_ENTRY_VIEW_BY_ID +
              locationState.state.rowData.gateEntryNum +
              "/" +
              userDit.cipher,
            history
          )
        );
        setCurrentStep(4);
      }
    }
  }, []);

  const handleEdit = () => {
    setCurrentStep(2);
  };

  const onClickSave = (type) => {
    // Implementation for save functionality
    let hasReadings = false;
    juteQualityCheckLineItems.filter((prop, key) => {
      if (prop.actualJuteTyp !== "") {
        if (prop.readings.length < 1) {
          hasReadings = true;
        }
      }
    });
    let qualityCheckList = juteQualityCheckLineItems
      .filter((prop) => prop.actualJuteTyp !== "" && prop.actualJuteTyp !==0) // Filtering items where actualJuteTyp is not empty
      .map((prop) => ({
        recId: prop.recId,
        qcJuteType: Number(prop.qcJuteType),
        advisedQuality: Number(prop.advisedQuality),
        qcJuteQuality: Number(prop.qcJuteQuality),
        advisedQuantity: prop.advisedQuantity,
        receivedIn: prop.receivedIn,
        qcJuteQuantity: prop.qcJuteQuantity,
        qcJuteWeight: prop.qcJuteWeight,
        createdBy: userDit.userName,
        isPoAmment: "",
        juteGateSrlNo: "",
        kgs: "",
        poLineItemNum: prop.recId,
        remarks: qualityCheckHeader.remarks,
        isActive:prop.isActive,
        readings: prop.readings
          .filter((readingItem) => readingItem.reading !== 0)
          .map((readingItem) => {
            return {
              id: readingItem.id,
              reading: readingItem.reading,
            };
          }),
      }));

    if (qualityCheckHeader) {
      let chalandate_Timestamp = new Date(qualityCheckHeader.chalanDate);
      let inDate_Timestemp = new Date(qualityCheckHeader.inDate);
      let inTime_Timestemp = new Date(qualityCheckHeader.inTime);
      var headers = {
        chalanNo: qualityCheckHeader.chalanNo,
        chalanDate: chalandate_Timestamp.getTime(),
        vehicleNo: qualityCheckHeader.vehicleNo,
        driverName: qualityCheckHeader.driverName,
        suppCode: qualityCheckHeader.suppCode,
        supplierName: qualityCheckHeader.supplierName,
        transporter: qualityCheckHeader.transporter,
        mukam: Number(qualityCheckHeader.mukam),
        // brokerName: qualityCheckHeader.brokerName,
        // brokerAddress: qualityCheckHeader.brokerAddress,
        grossWeight: qualityCheckHeader.grossWeight,
        actualWeight: qualityCheckHeader.actualWeight,
        challanWeight: qualityCheckHeader.challanWeight,
        // remarks: qualityCheckHeader.remarks,
        inDate: inDate_Timestemp.getTime(),
        inTime: inTime_Timestemp.getTime(),
        id: qualityCheckHeader.id,
        poNo: qualityCheckHeader.poNo,
        mrNo: "",
        createdBy: userDit.userName,
        // unitConversion: qualityCheckHeader.unitConversion,
        companyId: localStorage.getItem("companyId"),
        // finYear: localStorage.getItem("acadamicYear"),
        branchId: qualityCheckHeader.branchId ? qualityCheckHeader.branchId : 0,
      };
    }
    const entrydata = {
      entryHeader: headers,
      juteGateLineItems: qualityCheckList,
      cipher: userDit.cipher,
    };
    if (juteQualityCheckLineItems.length >= 1) {
      let getUrl;
      if (type === 1) {
        getUrl = null;
      } else {
        getUrl =
          serverApi.COMMIT_QUALITY_CHECK_READINGS +
          qualityCheckHeader.id +
          "/" +
          userDit.cipher;
      }
      if (!hasReadings) {
        dispatch(
          juteQualityCheckUpdate(
            serverApi.ADD_OR_UPDATE_JUTE_QUALITY_CHECK,
            entrydata,
            history,
            getUrl
          )
        );
      } else {
        swal("Please Enter Readings for all line Items..!!");
        setCurrentStep(3);
      }
    } else if (juteQualityCheckLineItems.length < 1) {
      swal("Please Add Atleast One Item..!!");
    }
  };

  //   onUpdateButtonClick=()=>{
  //     if(!getMenuPermission(1)){
  //         this.basicAlert("Access Denined. Please contact administrator!!!")
  //     }else {
  //         var hasReadings=false;
  //         var invoiceList = this.state.invoiceList.map((prop, key) => {
  //             if(prop.readingsList.length<1){
  //                 hasReadings=true
  //             }
  //             return ({
  //                 recId: prop.dataId,
  //                 qcJuteType: prop.itemId,
  //                 // itemDesc:prop.item,
  //                 // itemGroupId:prop.itemGroupId,
  //                 advisedQuality: prop.qualityCode,
  //                 qcJuteQuality: prop.qualityCode,
  //                 advisedQuantity: '',
  //               //  actualQuantity: '',
  //                 receivedIn: this.state.unitConversionName,
  //                 // dept:prop.deptId,
  //                 //stock:prop.stockValue,
  //                 qcJuteQuantity: prop.quantity,
  //                 qcJuteWeight: prop.weight,
  //                 advisedJuteTyp: this.state.juteType,
  //                 actualJuteTyp: this.state.juteType,
  //                 uom: prop.unit,
  //                 // "poLineItemNum":"123",
  //                 createdBy: getCookie(cons.USER_NAME),
  //                 isPoAmment: '',
  //                 juteGateSrlNo: '',
  //                 kgs: '',
  //                 poLineItemNum: prop.dataId,
  //                 remarks: this.state.remarks,
  //                 readings: prop.readingsList,
  //             })
  //         });

  //         var data = {
  //             "entryHeader": {
  //                 id: this.state.entryNo,
  //                 chalanNo: this.state.chalanNo,
  //                 chalanDate: this.dateChanged(this.state.chalanDate),
  //                 vehicleNo: this.state.vehicleNum,
  //                 driverName: this.state.driverName,
  //                 suppCode: this.state.selectedSupplier,
  //                 supplierName: this.state.supplierName,
  //                 transporter: this.state.transporter,
  //                 mukam: this.state.selectedMukam,
  //                 brokerName: this.state.brokerName,
  //                 brokerAddress: this.state.brokerAddress,
  //                 netWeight: this.state.netWeight,
  //                 grossWeight: this.state.grossWeightQTL,
  //                 actualWeight: this.state.actualWeightQTL,
  //                 challanWeight: this.state.chalanWeightQTL,
  //                 //  remarks:this.state.remarks,
  //                 inDate: this.dateChanged(this.state.date1),
  //                 inTime: this.timeChangedFormat(this.state.date1,this.state.time2),
  //                 poNo: this.state.selectedPO,
  //                 mrNo: '',
  //                 createdBy: getCookie(cons.USER_NAME),
  //                 "companyId":this.props.state.companyInfo.id,
  //                 branchId:this.state.branchId
  //             },
  //             "juteGateLineItems": invoiceList,
  //             "cipher":getCookie(cons.CIPHER)
  //         };

  //         if (this.state.invoiceList.length >= 1) {
  //            // console.log("selectedPO " + this.state.selectedPO);
  //             if (!hasReadings) {
  //                 this.handleLoading();
  //                 requestList(serverApi.ADD_OR_UPDATE_JUTE_QUALITY_CHECK, data, this.handleAddJuteGate)
  //             }else {
  //                 this.basicAlert("Please Enter Readings for all line Items..!!");

  //             }

  //         } else if (this.state.invoiceList.length < 1) {
  //             this.basicAlert("Please Add Atleast One Item..!!");
  //         }
  //     }

  // };

  const onClickNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const onClickBefore = () => {
    setCurrentStep((prevStep) => prevStep - 1);
    if (currentStep === 1) {
      history.push("jute_quality_check");
    }
  };

  const QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;
    return (
      <div style={{ textAlign: "center" }}>
        <img src={completed ? activeIcon : icon} alt="" />
        <p style={{ margin: 0, color: completed ? "#00abdc" : "inherit" }}>
          {title}
        </p>
      </div>
    );
  };

  return (
    <div>
      <Grid className="indentContainerBlock">
        <Grid sx={12} className="indentContainerMainBlock">
          <div className="iconsBlock">
            <Stepper alternativeLabel activeStep={currentStep}>
              {steps.map((prop) => (
                <Step key={prop.title}>
                  <StepLabel
                    StepIconComponent={(Iconprops) =>
                      QontoStepIcon(
                        Iconprops,
                        prop.icon,
                        prop.activeIcon,
                        prop.title
                      )
                    }
                  />
                </Step>
              ))}
            </Stepper>
          </div>
          {currentStep === 2 && (
            <>
              <JuteQualityCheckFillDetails />
              <div className="consoleFormButtonBlock">
                <Link>
                  <Button
                    variant="contained"
                    className="cancelButton"
                    onClick={onClickBefore}
                  >
                    Back
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  type="submit"
                  className="saveButton"
                  onClick={onClickNext}
                >
                  Next
                </Button>
              </div>
            </>
          )}
          {currentStep === 3 && (
            <>
              <JuteQualityCheckLineItems
                RowData={RowData}
                tableHeaders={tableHeaders}
              />
              <div className="consoleFormButtonBlock">
                <Button
                  variant="contained"
                  className="cancelButton"
                  onClick={onClickBefore}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className="saveButton"
                  onClick={onClickNext}
                >
                  Next
                </Button>
              </div>
            </>
          )}
          {currentStep === 4 && (
            <>
              <JuteQualityCheckPreview handleEdit={handleEdit} />
              <div className="consoleFormButtonBlock">
                <Link></Link>
                <>
                  <CustomButton
                    variant="contained"
                    type="submit"
                    label={"Update"}
                    className="saveButton"
                    handleClick={() => onClickSave(1)}
                    style={{ width: "80px", height: "60px" }}
                  />
                  <CustomButton
                    variant="contained"
                    type="submit"
                    label={"Commit"}
                    className="approved"
                    handleClick={() => onClickSave(2)}
                    style={{ width: "120px", height: "40px" }}
                  />
                </>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "actualJuteTyp",
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "actualQuality",
  },
  {
    no: 3,
    component: "InputAdoment",
    type: "quantity",
    label: "DRUM",
  },
  {
    no: 4,
    component: "InputAdoment",
    type: "actualWeight",
    label: "QTL",
  },
  {
    no: 5,
    component: "Inputadorment",
    type: "unitId",
  },
  {
    no: 6,
    component: "Inputadorment",
    type: "avgReading",
  },
];

const tableHeaders = [
  {
    header: "Item",
  },
  {
    header: "Quality",
  },
  {
    header: "Drums",
  },
  {
    header: "Weight",
  },
  {
    header: "Unit",
  },
  {
    header: "Average Reading",
  },
  {
    header: "Action",
  },
];

export default CreateJuteQualityCheck;
