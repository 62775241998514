import {
  TRAVEL_CATAGERIES_LIST,
  CREATE_TRAVEL_CATAGERIES,
  UPDATE_TRAVEL_CATAGERIES,
  CREATE_NEW_CUSTOMER_MASTER,
} from "./actionTypes";
import { showSpinner } from "../../../helpers/Consts";
import { hideSpinner } from "../../../Utilities/Validations";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { postAdd, getList } from "../../../helpers/Server_Helper";
import {
  travelCatageriesListSuccessfull,
  createTravelCatageriesSuccessfull,
  updateTravelCatageriesSuccessfull,
  CreateCustomerMasterSuccessfull,
} from "./actions";
import swal from "sweetalert";

function* TravelCatageriesList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(travelCatageriesListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateTravelCatageries({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createTravelCatageriesSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/Travel_Catageries");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateTravelCatageries({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateTravelCatageriesSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/Travel_Catageries");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
//CUSTOMEER MASTER
function* createCustomerMaster({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    yield put(CreateCustomerMasterSuccessfull({ response }));
    if(response.status) {
      swal(response.message, { icon: "success" });
      history.push("/Customer_Master");
      hideSpinner();
    } else {
      swal(response.message, { icon: "error" });
      hideSpinner();
    }   
  } catch (error) {
    swal("error:" + error, { icon: "danger" });
  }
}

export function* watchTravelCatageriesList() {
  yield takeEvery(TRAVEL_CATAGERIES_LIST, TravelCatageriesList);
}
export function* watchCreateTravelCatageries() {
  yield takeEvery(CREATE_TRAVEL_CATAGERIES, CreateTravelCatageries);
}
export function* watchUpdateTravelCatageries() {
  yield takeEvery(UPDATE_TRAVEL_CATAGERIES, UpdateTravelCatageries);
}
export function* watchCreateCustomerMaster() {
  yield takeEvery(CREATE_NEW_CUSTOMER_MASTER, createCustomerMaster);
}

function* TravelCatageriesSaga() {
  yield all([
    fork(watchTravelCatageriesList),
    fork(watchCreateTravelCatageries),
    fork(watchUpdateTravelCatageries),
    fork(watchCreateCustomerMaster),
  ]);
}
export default TravelCatageriesSaga;
