import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import {
  getDebitCreditList,
  getSrNoViewByIdClear,
  ClearDebitNoteHeaderProps,
  ClearDebitNoteLineItemsProps
} from "../../../store/Purchase/Debit-credit-note/actions";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import moment from "moment";

function Index(props) {
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [materialListData, setMaterialListData] = useState([]);
  const [materialListColumns, setMaterialListColumns] = useState([]);
  const [companyId] = useState(localStorage.getItem("companyId"));
  const [totalRecs, setTotalRecs] = useState(0);
  const [startIndex, setStartIndex] = useState(1);
  const [lastIndex, setLastIndex] = useState(10);
  const [dataexp, setDataexp] = useState("");
  const [fromDate, setFromDate] = useState(
    moment().day(-7).format("DD-MM-YYYY")
  );
  const [toDate, setToDate] = useState(moment().format("DD-MM-YYYY"));
  const [grnNo, setGrnNo] = useState("");
  const [supplier, setSupplier] = useState("");
  const [branchId, setBranchId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [drcrNo, setDrcrNo] = useState("");
  const [srNo, setSrNo] = useState("");
  const [totalRecords, settotalRecords] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    const financialYearStartMonth = 3; // Assuming financial year starts in April
    let financialYearStart = moment()
      .year(localStorage.getItem("acadamicYear"))
      .month(financialYearStartMonth)
      .startOf("month");

    const fromDate = financialYearStart.day(-7).format("YYYY-MM-DD");
    const toDate = moment().format("YYYY-MM-DD");

    var startDate = moment(fromDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var endDate = moment(toDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    const data = {
      fromDate: startDate,
      toDate: endDate,
      grnNo: "",
      financialYear: localStorage.getItem("acadamicYear"),
      supplier: "",
      type: "DN",
      companyId: localStorage.getItem("companyId"),
      cipher: userDit.cipher,
      erpType: "",
      branchId: "",
      projectId: "",
      drcrNo: "",
      srNo: "",
      startIndex: 0,
      lastIndex: 10,
      status:"",
    };
    const data2 = {
      ...data,
      startIndex: 0,
      lastIndex: 0,

    };
    setDataexp(data2);
    setFromDate(startDate)
    setToDate(endDate)
    props.getDebitCreditList(serverApi.DEBIT_CREDIT_LIST, data, props.history);
    props.getSrNoViewByIdClear(null);
    props.ClearDebitNoteLineItemsProps(null)
  }, []);

  useEffect(() => {
    if (props.Debit_Credit_Data) {
      setTotalRecs(props.Debit_Credit_Data);
      setMaterialListData(props.Debit_Credit_Data.data);
      setMaterialListColumns(props.Debit_Credit_Data.column);
      settotalRecords(props.Debit_Credit_Data.totalRecords);
    }
  }, [props.Debit_Credit_Data]);

  const handleSearch = (data) => {
    const searchData = {
      fromDate: data.fromDate,
      toDate: data.toDate,
      grnNo: data.grnNo,
      financialYear: localStorage.getItem("acadamicYear"),
      supplier: data.supplier,
      status: data.status,
      type: "DN",
      companyId: localStorage.getItem("companyId"),
      cipher: userDit.cipher,
      erpType: "",
      branchId: data.branchId,
      projectId: data.projectId,
      drcrNo: data.drcrNo,
      srNo: data.srNo,
      startIndex: 0,
      lastIndex: 10,
     
    };
    setFromDate(data.fromDate);
    setToDate(data.toDate);
    setGrnNo(data.grnNo);
    setSupplier(data.supplier);
    setBranchId(data.branchId);
    setProjectId(data.projectId);
    setDrcrNo(data.drcrNo);
    setSrNo(data.srNo);
    setStatus(data.status)

    props.getDebitCreditList(
      serverApi.DEBIT_CREDIT_LIST,
      searchData,
      props.history
    );
  };

  const handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    const data = {
      fromDate:fromDate,
      toDate:toDate,
      grnNo,
      financialYear: localStorage.getItem("acadamicYear"),
      supplier,
      type: "DN",
      companyId: localStorage.getItem("companyId"),
      cipher: userDit.cipher,
      erpType: "",
      branchId,
      projectId,
      drcrNo,
      srNo,
      status
    };
    if (type === 1) {
      data.startIndex = sizePerPage;
      data.lastIndex = currentIndex;
      if (currentIndex >= 0) {
        props.getDebitCreditList(
          serverApi.DEBIT_CREDIT_LIST,
          data,
          props.history
        );
      }
    } else {
      data.startIndex = currentIndex ;
      data.lastIndex = sizePerPage;
      if (currentIndex >= 0) {
        props.getDebitCreditList(
          serverApi.DEBIT_CREDIT_LIST,
          data,
          props.history
        );
      }
    }
    setStartIndex(currentIndex);
    setLastIndex(sizePerPage);
  };

  return (
    <>
      <PaginationGridView
        mdata={materialListData}
        mcolumns={Headers}
        micon={GridArrowIcon}
        status={true}
        actions={true}
        url="/create_DC_note"
        filter_form={"DebitCreditNoteFilter"}
        handleSearch={handleSearch}
        totalRecs={totalRecords}
        page={startIndex}
        sizePerPage={lastIndex}
        handlePagination={handlePagination}
        apiexp={serverApi.DEBIT_CREDIT_LIST}
        payloadexp={dataexp}
        expName={"DebitNote.csv"}
        fromDate={fromDate}
        toDate={toDate}
      />
    </>
  );
}

const Headers = [
  {
    Header: "DR/CR No",
    accessor: "debitNoteNo",
    hidden: false,
  },
  {
    Header: "DR/CR Date",
    accessor: "debitNoteDate",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Supplier",
    accessor: "supplier",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Sr Print No",
    accessor: "srPrintNo",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Amount",
    accessor: "debitNoteTotalValue",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Branch",
    accessor: "branch",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "statusDesc",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
];

const mapStatetoProps = (state) => {
  const { Debit_Credit_Data } = state.DebitCreditReducer;
  return { Debit_Credit_Data };
};

export default withRouter(
  connect(mapStatetoProps, {
    getDebitCreditList,
    getSrNoViewByIdClear,
    ClearDebitNoteHeaderProps,
    ClearDebitNoteLineItemsProps
  })(Index)
);
