import {
  TRAVEL_CATAGERIES_LIST, TRAVEL_CATAGERIES_LIST_SUCCESSFULL, CREATE_TRAVEL_CATAGERIES_SUCCESSFULL, CREATE_TRAVEL_CATAGERIES,
  UPDATE_TRAVEL_CATAGERIES,
  UPDATE_TRAVEL_CATAGERIES_SUCCESSFULL, CREATE_NEW_CUSTOMER_MASTER, CREATE_NEW_CUSTOMER_MASTER_SUCCESSFULL,
  API_ERRORS,
} from './actionTypes';

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  TravelCatageriesList: [],
  CreateTravelCatageries: [],
  UpdateTravelCatageries: [],
  createNewCustomerMaster: [],
  CreateSupplierMasterList: [],
};

const TravelCatageriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRAVEL_CATAGERIES_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case TRAVEL_CATAGERIES_LIST_SUCCESSFULL:
      state = {
        ...state,
        TravelCatageriesList: action.payload.response,
        loading: true,
      };
      break;
    case CREATE_TRAVEL_CATAGERIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_TRAVEL_CATAGERIES_SUCCESSFULL:
      state = {
        ...state,
        CreateTravelCatageries: action.payload.response,
        loading: true,
      };
      break;
    case UPDATE_TRAVEL_CATAGERIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_TRAVEL_CATAGERIES_SUCCESSFULL:
      state = {
        ...state,
        UpdateTravelCatageries: action.payload.response,
        loading: true,
      };
      break;
    case CREATE_NEW_CUSTOMER_MASTER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CREATE_NEW_CUSTOMER_MASTER_SUCCESSFULL:
      state = {
        ...state,
        createNewCustomerMaster: action.payload.response,
        loading: false,
      };
      break;
    case API_ERRORS:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
}

export default TravelCatageriesReducer;