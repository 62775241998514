import {
  BEAMING_DD_LIST,
  BEAMING_DD_LIST_SUCCESSFULL,
  BEAMING_LIST,
  BEAMING_LIST_SUCCESSFULL,
  BEAMING_CREATE,
  BEAMING_CREATE_SUCCESSFULL,
  BEAMING_DELETE_ROW,
  BEAMING_DELETE_ROW_SUCCESSFULL,
} from "./actionTypes";

export const beamingList = (url, data, history) => {
  return {
    type: BEAMING_LIST,
    payload: { url, data, history },
  };
};
export const beamingListSuccessfull = (data) => {
  return {
    type: BEAMING_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const beamingDdList = (url, data, history) => {
  return {
    type: BEAMING_DD_LIST,
    payload: { url, data, history },
  };
};
export const beamingDdListSuccessfull = (data) => {
  return {
    type: BEAMING_DD_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const beamingCreate = (url, data, history) => {
  return {
    type: BEAMING_CREATE,
    payload: { url, data, history },
  };
};
export const beamingCreateSuccessfull = (data) => {
  return {
    type: BEAMING_CREATE_SUCCESSFULL,
    payload: data,
  };
};

export const beamingDeleteRow = (url, history, fromDate, toDate) => {
  return {
    type: BEAMING_DELETE_ROW,
    payload: { url, history, fromDate, toDate },
  };
};
export const beamingDeleteRowSuccessfull = (data) => {
  return {
    type: BEAMING_DELETE_ROW_SUCCESSFULL,
    payload: data,
  };
};
