import {BROKER_MASTER_LIST,BROKER_MASTER_LIST_SUCCESSFULL,CREATE_BROKER_MASTER_SUCCESSFULL,CREATE_BROKER_MASTER,
  UPDATE_BROKER_MASTER,
  UPDATE_BROKER_MASTER_SUCCESSFULL,
  API_ERRORS,
 } from './actionTypes';

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    BrokerMasterList: [],
    CreateBrokerMaster:[],
    UpdateBrokerMaster:[],
  };

  const BrokerMasterReducer = (state = initialState, action) => {
    switch (action.type) {
        case BROKER_MASTER_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case BROKER_MASTER_LIST_SUCCESSFULL:
            state = {
              ...state,
              BrokerMasterList: action.payload.response,
              loading: true,
            };
            break;
            case CREATE_BROKER_MASTER:
              state = {
                ...state,
                loading: true,
              };
              break;
            case CREATE_BROKER_MASTER_SUCCESSFULL:
              state = {
                ...state,
                CreateBrokerMaster: action.payload.response,
                loading: true,
              };
              break;
              case UPDATE_BROKER_MASTER:
              state = {
                ...state,
                loading: true,
              };
              break;
            case UPDATE_BROKER_MASTER_SUCCESSFULL:
              state = {
                ...state,
                UpdateBrokerMaster: action.payload.response,
                loading: true,
              };
              break;
              case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default BrokerMasterReducer;