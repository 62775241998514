import {
  WAREHOUSELIST,
  WAREHOUSELIST_SUCCESSFULL,
  CREATE_WAREHOUSE,
  CREATE_WAREHOUSE_SUCCESSFULL,
  UPDATE_WAREHOUSE,
  UPDATE_WAREHOUSE_SUCCESSFULL,
} from "./actionTypes";

export const getWareHouseList = (url, data,history) => {
  return {
    type: WAREHOUSELIST,
    payload: { url, data,history },
  };
};

export const WareHouseListSuccessfull = (data) => {
  return {
    type: WAREHOUSELIST_SUCCESSFULL,
    payload: data,
  };
};
export const createWarehouse = (url, data, history) => {
  return {
    type: CREATE_WAREHOUSE,
    payload: { url, data, history },
  };
};

export const createWarehouseSuccessfull = (data) => {
  return {
    type: CREATE_WAREHOUSE_SUCCESSFULL,
    payload: data,
  };
};
export const updateWarehouse = (url, data, history) => {
  return {
    type: UPDATE_WAREHOUSE,
    payload: { url, data, history },
  };
};

export const updateWarehouseSuccessfull = (data) => {
  return {
    type: UPDATE_WAREHOUSE_SUCCESSFULL,
    payload: data,
  };
};

