export const PAY_REGISTER_LIST = "pay_register_list"; 
export const PAY_REGISTER_LIST_SUCCESSFULL = "pay_register_list_successfull";
export const BRANCH_LIST_DATA = 'branch_list_data';
export const BRANCH_LIST_DATA_SUCCESSFULL ='branch_list__data_successfull'
export const PAY_PERIOD = 'pay_period'
export const PAY_PERIOD_SUCCESSFULL ='pay_period_successfull'
export const PAY_PERIOD_BY_ID ='pay_period_by_id'
export const PAY_PERIOD_BY_ID_SUCCESSFULL ='pay_period_by_id_successfull'
export const PROCESSED_LIST ='processed_list'
export const PROCESSED_LIST_SUCCESSFULL ='processed_list_successfull'
export const UPDATE_PAY_PERIOD ='update_pay_period'
export const UPDATE_PAY_PERIOD_SUCCESSFULL ='update_pay_period_successfull'
export const GENERATE_INVOICE="generate_invoice"
export const GENERATE_INVOICE_SUCCESSFULL="generate_invoice_successfull"
export const GET_MONTHLY_PAYSALARY_LIST = "get_monthly_paysalary_list"
export const GET_MONTHLY_PAYSALARY_LIST_SUCCESSFULL = "get_monthly_paysalary_list_successfull"
