import React, { Component } from "react";
import TextFieldAlfaNum from "../../../components/TextField/TextFieldAlfaNum";
import { Grid, Box } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import CustomButton from "../../../components/Buttons/Buttons";
import { Link } from "react-router-dom";
import TextFieldAlfaNumReq from "../../../components/TextField/TextFieldAlfaNumReq";
import TextField from "../../../components/TextField/TextField";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
class CreateRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      display: false,
      locaion: 0,
      location_name: "",
      type: 0,
      type_name: "",
      emp_code: "",
      name: "",
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
  };

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "location") {
      this.setState({
        location: selectedValue,
        location_name: selectedName,
      });
    }
    if (name === "type") {
      this.setState({
        type: selectedValue,
        type_name: selectedName,
      });
    }
    var a = (selectedValue, selectedName, name);
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <div className="createRequest">
        <div className="consoleFormContainer">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldAlfaNumReq
                        value={this.state.emp_code}
                        label="Employee Code"
                        caps="ON"
                        placeholder="Enter here"
                        onChange={this.handleChange("emp_code")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextField label="Name" placeholder="Name" />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormContainer">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextField
                        label="Company Visited"
                        placeholder="Enter here"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={location}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.location}
                        name="Branch"
                        label="Location"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>From Date</label>
                      </div>
                      {/* <ConsoleDatePicker /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          name="financial_year"
                          inputFormat="dd.MM.yyyy"
                          id="financial_year"
                          value={this.state.dtvalue}
                          fullWidth
                          onChange={(newValue) => {
                            this.setState({ dtvalue: newValue });
                          }}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>To Date</label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          name="financial_year"
                          inputFormat="dd.MM.yyyy"
                          id="financial_year"
                          value={this.state.dtvalue}
                          fullWidth
                          onChange={(newValue) => {
                            this.setState({ dtvalue: newValue });
                          }}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div className="consoleFormContainer">
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={type}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.type}
                          name="type"
                          label="Type of Visit"
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 6">
                      <div className="consoleTextFieldBlock">
                        <TextField label="Purpose" placeholder="Enter Here" />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>

            <div className="consoleFormButtonBlock">
              <Link to="client_visitPlan">
                <CustomButton label={"Cancel"} className="greenBorderButton" />
              </Link>
              <Link to="client_visitPlan">
                <CustomButton
                  label={"Create Request"}
                  className="greenButton"
                  handleClick={this.onClickNext}
                  type="sumbit"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const location = [
  {
    value: "1",
    label: "Hyderabad",
    name: "Hyderabad",
  },
  {
    value: "2",
    label: "Banglore",
    name: "Banglore",
  },
  {
    value: "3",
    label: "Mysore",
    name: "Mysore",
  },
];
const type = [
  {
    value: "1",
    label: "Sales",
    name: "Sales",
  },
  {
    value: "2",
    label: "Service",
    name: "Service",
  },
  {
    value: "3",
    label: "Purchase",
    name: "Purchase",
  },
];
const mapStatetoProps = (state) => {};

export default withRouter(connect(mapStatetoProps, {})(CreateRequest));
