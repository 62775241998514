import {CONSUMPTION_LIST,CONSUMPTION_LIST_SUCCESSFULL,API_ERRORS, ADD_CONSUMPTION, ADD_CONSUMPTION_SUCCESSFULL} from './actionTypes';



export const getConsumptionList = (url,data,history) =>{
    return {
        type:CONSUMPTION_LIST,
        payload:{url,data,history}
    }
}

export const ConsumptionListSuccessfull = (data)=>{
    return {
        type:CONSUMPTION_LIST_SUCCESSFULL,
        payload:data
    }
}

export const apiErrors = (error) => {
    return {
      type: API_ERRORS,
      payload: error,
    };
  };

  export const addConsumption = (url,data,history) =>{
    return {
        type:ADD_CONSUMPTION,
        payload:{url,data,history}
    }
}

export const addConsumptionSuccessfull = (data)=>{
    return {
        type:ADD_CONSUMPTION_SUCCESSFULL,
        payload:data
    }
}

