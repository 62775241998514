import {
  FINISHING_ENTRY_LIST,
  FINISHING_ENTRY_LIST_DELETE,
  GET_ALL_MACHINE_DETAILS_DD,
  GET_PROCESS_MASTER_LIST,
  ADD_FINISHING_ENTRY,
  DELETE_FINISHING_ENTRY_DATA,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getListurl, getList } from "../../../helpers/Server_Helper";
import {
  finishingEntryActionListSuccessfull,
  finishingEntryActionListDeleteSuccessfull,
  getProcessMasterListSuccessfull,
  getMachineDetailsActionSuccessfull,
  addFinishingEntrySuccessfull,
  deleteFinishingEntrySuccessfull,
  finishingEntryActionList,
} from "./actions";
import swal from "sweetalert";
import { serverApi } from "../../../helpers/Consts";

function* FinishingEntrySagaList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(finishingEntryActionListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* FinishingEntryDeleteSagaList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(finishingEntryActionListDeleteSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* GetProcessMasterSagaList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getProcessMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* GetMachineDataSagaList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(getMachineDetailsActionSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* addFinishingEntryData({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(addFinishingEntrySuccessfull({ response }));
    swal(response.message, { icon: "success" });

    if (response.status === true) {
      const Data = {
        companyId: localStorage.getItem("companyId"),
        fromDate: "10-09-1989",
        toDate: "10-09-1989",
        cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      };

      var listApi = serverApi.GET_FINISHING_ENTRIES;
      yield put(finishingEntryActionList(listApi, Data, history));
    }
    history.push("/ad_finishing_entry");
  } catch (error) {
    console.log(error);
  }
}
function* deleteFinishingEntryFunc({
  payload: { url, history, fromDate, toDate },
}) {
  try {
    const response = yield call(getListurl, url, history, fromDate, toDate);
    yield put(deleteFinishingEntrySuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
    if (response.data.status === true) {
      const data = {
        companyId: localStorage.getItem("companyId"),
        fromDate: fromDate,
        toDate: toDate,
        cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      };

      var listApi = serverApi.GET_FINISHING_ENTRIES;
      yield put(finishingEntryActionList(listApi, data, history));
    }
  } catch (error) {
    console.log(error);
  }
}
export function* watchFinishingEntryList() {
  yield takeEvery(FINISHING_ENTRY_LIST, FinishingEntrySagaList);
  yield takeEvery(FINISHING_ENTRY_LIST_DELETE, FinishingEntryDeleteSagaList);
  yield takeEvery(GET_PROCESS_MASTER_LIST, GetProcessMasterSagaList);
  yield takeEvery(GET_ALL_MACHINE_DETAILS_DD, GetMachineDataSagaList);
  yield takeEvery(ADD_FINISHING_ENTRY, addFinishingEntryData);
  yield takeEvery(DELETE_FINISHING_ENTRY_DATA, deleteFinishingEntryFunc);
}

function* FinishingEntry() {
  yield all([fork(watchFinishingEntryList)]);
}

export default FinishingEntry;
