import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import DynamicSelect from "../Dropdown/DynamicSelect";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';  
import TextFieldNormal from "../../components/TextField/TextFieldNormal";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';   
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'; 
import moment from "moment";
import { getClientList } from "../../store/Global/DropDownApis/actions";
import { serverApi } from "../../helpers/Consts";
class ProjectListFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate:"",
      endDate: "",
      name:"",
      customerId:"",
      clientList:[]
    };
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.props.getClientList(
        serverApi.GET_CLIENT_LIST + localStorage.getItem("companyId"),
        this.props.history
      );
  }
  componentWillReceiveProps(props) {
    if (props.clientList) {
      this.setState({
        clientList: props.clientList.data, // updating client dropdown api
      });
    }
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "customerId") {
      this.setState({
        customerId: selectedValue,
      });
    }
  };
  handleSelectDate = (e, name) => {
    if (name === "startDate") {
      let date = moment(e).format();
      let formattedFromDate = date.split("T", 1).toString();

      this.setState({
        startDate: formattedFromDate,
      });
    }

    if (name === "endDate") {
      let date = moment(e).format();
      let formattedToDate = date.split("T", 1).toString();
      this.setState({
        endDate: formattedToDate,
      });
    }
    
  };

  handleChange = (key)=> (event)=>{
    if(key === "name"){
      this.setState({
        name:event
      })
    }
  }

  handleSubmit = () => {
    var data = {
        indentType: "store",
        startDate: this.state.startDate,  
        endDate: this.state.endDate,  
        customerId:this.state.customerId,  
        status: "", 
        companyId:localStorage.getItem("companyId") , 
        cipher: JSON.parse(localStorage.getItem("authUser")).cipher,  
        financialYear: localStorage.getItem("acadamicYear"),
        erpType: "1",
        name:this.state.name
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleClear = () => {
    this.setState({
      Status: '',
      StatusValue:'',
      project: "",
      projctValue: "",
      branchId: "",
      branchValue: "",
      fromDate: "",
      toDate: "",
    });
  };

  handleMenuOpen=()=>{
    this.setState({
      isDropdownOpen:false
    })
  }

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen:true
    })
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.
    this.setState({
      isDropdownOpen:true
    })
  };

  render() {

    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid
            spacing={0}
          >
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
              </div>
            </div>
            <Grid item xs={12} className="filterBlockContainer">
              <Box gridColumn="span 3">
                <div className="filterDateBlock">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>From Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.startDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "startDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="fromDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>To Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.endDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "endDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="toDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                   arrayOfData={this.state.clientList}
                   className="dropdownBlockContainer"
                   menuPosition="fixed"
                   menuPlacement="auto"
                   onSelectChange={this.handleSelectChange}
                   isDropdownOpen={this.state.isDropdownOpen}
                   handleMenuOpen={this.handleMenuOpen}
                   selected={this.state.customerId}
                   update={this.state.customerId ? 1 : 0}
                   name="customerId"
                   label="Client"
                  />
                </div>
              </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Project Name"
                        value={this.state.name}
                        onChange={this.handleChange("name")}
                      />
                    </div>
                  </Box>
              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Search"}
                  className="greenButton"
                  handleClick={this.handleSubmit}
                  type="sumbit"
                />
                {/* <CustomButton
                  label={"Clear"}
                  className="greenButton"
                  handleClick={this.handleClear}
                  type="sumbit"
                /> */}
              </div>
            </Grid>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
    const { clientList } = state.DropDownListReducer;
    return {
      ...state.Layout,
      clientList,
    };
};

export default withRouter(
  connect(mapStatetoProps, {
    getClientList,
  })(ProjectListFilter)
);
