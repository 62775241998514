import { REQUESTOTP, REQUESTOTP_SUCCESSFULL, SUBMIT_GST_CREDENTIALS_OTP, SUBMIT_GST_CREDENTIALS_OTP_SUCCESSFULL, UPDATECLIENTCREDENTIALS, UPDATECLIENTCREDENTIALS_SUCCESSFULL } from "./actionTypes";

export const UpdateClientCredentials = (url, data, history,callback) => {
    return {
      type: UPDATECLIENTCREDENTIALS,
      payload: { url, data, history,callback },
    };
  };
  
  export const UpdateClientCredentials_successfull = (data) => {
    return {
      type: UPDATECLIENTCREDENTIALS_SUCCESSFULL,
      payload: data,
    };
  };
  export const RequestOtp = (url, data, history) => {
    return {
      type: REQUESTOTP,
      payload: { url, data, history },
    };
  };
  
  export const RequestOtp_successfull = (data) => {
    return {
      type: REQUESTOTP_SUCCESSFULL,
      payload: data,
    };
  };
  export const SubmitGSTOtp = (url, data, history) => {
    return {
      type: SUBMIT_GST_CREDENTIALS_OTP,
      payload: { url, data, history },
    };
  };
  
  export const SubmitGSTOtp_successfull = (data) => {
    return {
      type: SUBMIT_GST_CREDENTIALS_OTP_SUCCESSFULL,
      payload: data,
    };
  };