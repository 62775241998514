export const ATTENDANCE_LIST ='attendance_list';
export const ATTENDANCE_LIST_SUCCESSFULL = 'attendance_list_successfull';
export const SPELL='spell';
export const SPELL_SUCCESSFULL ='spell_successfull';
export const MACHINE_NUMBER ='machine_number';
export const MACHINE_NUMBER_SUCCESSFULL ='machine_number_successfull';
export const MACHINE_NUMBER_CLEAR ='machine_number_clear';
export const MARK_ATTENDANCE ="mark_attendance";
export const MARK_ATTENDANCE_SUCCESSFULL="mark_attendance_successfull";
export const LOGS = 'logs';
export const LOGS_SUCCESSFULL = 'logs_successfull';
export const UPDATE_ATTENDANCE = "update_attendance";
export const UPDATE_ATTENDANCE_SUCCESSFULL = "update_attendance_successfull";
export const ATTENDANCE_SUMMARY = 'attendance_summary';
export const ATTENDANCE_SUMMARY_SUCCESSFULL = 'attendance_summary_successfull';
export const PROCESS_LOGS ="process_logs";
export const PROCESS_LOGS_SUCCESSFULL="process_logs_successfull";
export const WORKER_DATA_BY_EB_NUMBER = 'worker_data_by_eb_number'
export const WORKER_DATA_BY_EB_NUMBER_SUCCESSFULL ='worker_data_by_eb_number_successfull'
export const WORKER_DATA_CLEAR = 'worker_data_clear';
export const ATTENDANCE_VIEW ='attendance_view';
export const ATTENDANCE_VIEW_SUCCESSFULL ='attendance_view_successfull';
export const ATTENDANCE_VIEW_CLEAR_PROP ='attendance_view_clear_prop';
export const ATTENDANCE_DESIGNATION_LIST ='attendance_designation_list';
export const ATTENDANCE_DESIGNATION_LIST_SUCCESSFULL ='attendance_designation_list_successfull';



