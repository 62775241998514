import React, { useState, useEffect } from "react";
import { Grid, Step, Stepper, StepLabel, Box } from "@mui/material";
import { Link } from "react-router-dom";
import CustomButton from "../../../components/Buttons/Buttons";
import DynamicSelect from "../../../components/Dropdown/Sales/InvoiceDynamicSelect";
import {useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { serverApi } from "../../../helpers/Consts";
import RegularFillDetails from "./RegularFillDetails";
import RegularLineItems from "./RegularLineItems";
import RegularTransportation from "./RegularTransportation";
import RegularPreview from "./RegularPreview";
import {
  createDeliveryOrder,
  RegularDeliveryOrderViewBYId,
  updateRegularDeliveryOrder,
  CreateJuteGovtDeliveryOrder,
  JuteGovtDeliveryOrderViewById,
  UpdateJuteGovtDeliveryOrder,
  CreateJuteHessianDeliveryOrder,
  DeliveryOrderJuteHessianById,
  ClearDOJuteHessianHeaders,
  ClearDeliveryOrderLineItems,
  UpdateJuteHessianDeliveryOrder,
  clearRegularDeliveryOrderHeader,
  clearRegularDeliveryOrderLineItems,
  clearJuteGovtDeliveryOrderHeader,
  clearJuteGovtDeliveryOrderLineItems,
  CreateJuteYarnDeliveryOrder,
  JuteYarnDeliveryOrderViewById,
  UpdateJuteYarnDeliveryOrder,
  ClearJuteYarnDeliveryOrderHeader,
  clearJuteYarnDeliveryOrderLineItems,
  getDOFiles
} from "../../../store/Sales/DeliveryOrder/actions";
import moment from "moment";
import swal from "sweetalert";
import { isEmpty, isEmptyWithZero } from "../../../Utilities/helper";
import JuteGovtFillDetails from "./JuteGovernment/JuteGovtFillDetails";
import JuteGovtLineItems from "./JuteGovernment/JuteGovtLineItems";
import JuteGovtTransportation from "./JuteGovernment/JuteGovtTransportation";
import JuteGovtPreview from "./JuteGovernment/JuteGovtPreview";
import JuteYarnFillDetails from "./JuteYarn/JuteYarnFillDetails";
import JuteYarnLineItems from "./JuteYarn/JuteYarnLineItems";
import JuteYarnTransportation from "./JuteYarn/JuteYarnTransportation";
import JuteYarnPreview from "./JuteYarn/JuteYarnPreview";
import JuteHessianFillDetails from "./JuteHessianFillDetails";
import JuteHessianLineItems from "./JuteHessianLineItems";
import JuteHessianTransportation from "./JuteHessianTransportation";
import JuteHessianPreview from "./JuteHessianPreview";
import { limitDecimals } from "../../../Utilities/helper";

const CreateDeliveryOrder = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [steps] = useState([
    {
      title: "Select Option",
      icon: "http://vowerp.com/MailImages/assets/stepperSelection-00.png",
      activeIcon:
        "http://vowerp.com/MailImages/assets/stepperSelectionActive-00.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Fill Details",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Add Items",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Terms",
      icon: "http://vowerp.com/MailImages/assets/billing_inactive.png",
      activeIcon: "http://vowerp.com/MailImages/assets/billing_active.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Preview",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive03.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
  ]);

  const [currentStep, setcurrentStep] = useState(1);
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [invoiceType, setinvoiceType] = useState("");
  const [invoiceData, setInvoiceData] = useState([]);
  const [TodayDate, setTodayDate] = useState(moment().format());
  const [JuteGovtLineItemsData, setJuteGovtLineItemsData] = useState([]);
  const [JuteGovtHeadersData, setJuteGovtHeadersData] = useState({});
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [locationState] = useState(useLocation());

  const { JuteGovtDeliveryOrderHeader, JuteGovtDeliveryOrderLineItems } =
    useSelector((state) => state.DeliveryOrderReducer);

  const {
    RegularDeliveryOrderHeader,
    RegularDeliveryOrderLineItems,
    DoJuteHessianHeaders,
    DoJuteHessianLineItems,
    JuteYarnDeliveryOrderHeader,
    JuteYarnDeliveryOrderLineItems,
  } = useSelector((state) => state.DeliveryOrderReducer);

  const QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;
    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };
  const onClickBefore = () => {
    if (currentStep === 1) {
      props.history.push("/delivery_order")
    }
    if (currentStep == 2) {
      let url =
        props.location.pathname === "/create_delivery_order" ? "/delivery_order" : "";
      props.history.push(url);
    } else if (currentStep == 2) {
      setcurrentStep(currentStep - 1);
      dispatch(clearRegularDeliveryOrderHeader());
      dispatch(clearRegularDeliveryOrderLineItems());
      dispatch(ClearDOJuteHessianHeaders());
      dispatch(ClearDeliveryOrderLineItems());
      dispatch(clearJuteGovtDeliveryOrderHeader());
      dispatch(clearJuteGovtDeliveryOrderLineItems());
      dispatch(ClearJuteYarnDeliveryOrderHeader());
      dispatch(clearJuteYarnDeliveryOrderLineItems());
    } else {
      setcurrentStep(currentStep - 1);
    }
  };
  const onClickNext = () => {
    if (currentStep === 1) {
      if (invoiceType === "") {
        swal("Please Select the Invoice Type");
      } else {
        setcurrentStep(currentStep + 1);
      }
    } else if (currentStep == 2) {
      if (invoiceType === 1) {
        if (isEmpty(RegularDeliveryOrderHeader.salesOrderId)) {
          swal("Please Select the Sales Order");
        } else if (isEmpty(RegularDeliveryOrderHeader.branch)) {
          swal("Please Select the Branch");
        } else if (isEmpty(RegularDeliveryOrderHeader.deliveryDate)) {
          swal("Please Select the Delivery Order Date");
        } else if (isEmpty(RegularDeliveryOrderHeader.customerId)) {
          swal("Please Select the Customer/Consignee");
        } else if (isEmpty(RegularDeliveryOrderHeader.billingTo)) {
          swal("Please Select the Billing To");
        } else if (isEmpty(RegularDeliveryOrderHeader.shippingTo)) {
          swal("Please Select the Shipping To");
        } else if (isEmpty(RegularDeliveryOrderHeader.bankId)) {
          swal("Please Select the Bank Name");
        }  else if (RegularDeliveryOrderHeader.deliveryTerms === "") {
          swal("Please Select the Delivery terms");
        } else if (
          (RegularDeliveryOrderHeader.brokerId !== "" &&
            RegularDeliveryOrderHeader.brokerCommisionPercent === "") ||
          RegularDeliveryOrderHeader.brokerCommisionPercent === "0"
        ) {
          swal("Please Select Broker Percent");
        } else if (isEmpty(RegularDeliveryOrderHeader.transporter)) {
          swal("Please Select Transporter Name");
        } else {
          setcurrentStep(currentStep + 1);
        }
      } else if (invoiceType === 4) {
        if (isEmpty(DoJuteHessianHeaders.salesOrderId)) {
          swal("Please Select the Sales Order");
        } else if (isEmpty(DoJuteHessianHeaders.branch)) {
          swal("Please Select the Branch");
        } else if (isEmpty(DoJuteHessianHeaders.deliveryDate)) {
          swal("Please Select the Delivery Order Date");
        } else if (isEmpty(DoJuteHessianHeaders.customerId)) {
          swal("Please Select the Customer/Consignee");
        } else if (isEmpty(DoJuteHessianHeaders.billingTo)) {
          swal("Please Select the Billing To");
        } else if (isEmpty(DoJuteHessianHeaders.shippingTo)) {
          swal("Please Select the Shipping To");
        } else if (isEmpty(DoJuteHessianHeaders.bankId)) {
          swal("Please Select the Bank Name");
        }  else if (DoJuteHessianHeaders.deliveryTerms === "") {
          swal("Please Select the Delivery terms");
        }
        else if (isEmpty(DoJuteHessianHeaders.transporter)) {
          swal("Please Select Transporter Name");
        }
        else {
          setcurrentStep(currentStep + 1);
        }
      } else if (invoiceType === 3) {
        if (isEmpty(JuteYarnDeliveryOrderHeader.salesOrderId)) {
          swal("Please Select the Sales Order");
        } else if (isEmpty(JuteYarnDeliveryOrderHeader.branch)) {
          swal("Please Select the Branch");
        } else if (isEmpty(JuteYarnDeliveryOrderHeader.deliveryDate)) {
          swal("Please Select the Delivery Order Date");
        } else if (isEmpty(JuteYarnDeliveryOrderHeader.customerId)) {
          swal("Please Select the Customer/Consignee");
        } else if (isEmpty(JuteYarnDeliveryOrderHeader.billingTo)) {
          swal("Please Select the Billing To");
        } else if (isEmpty(JuteYarnDeliveryOrderHeader.shippingTo)) {
          swal("Please Select the Shipping To");
        } else if (isEmpty(JuteYarnDeliveryOrderHeader.bankId)) {
          swal("Please Select the Bank Name");
        }  else if (JuteYarnDeliveryOrderHeader.deliveryTerms === "") {
          swal("Please Select the Delivery terms");
        } else if (isEmpty(JuteYarnDeliveryOrderHeader.transporter)) {
          swal("Please Select Transporter Name");
        } else {
          if(JuteYarnDeliveryOrderLineItems.length !==0){
            setcurrentStep(currentStep + 1);
          }else{
          swal('Please select the sales order which has atlease one line item')
          }

        }
      } else if (invoiceType === 2) {
        if (isEmpty(JuteGovtDeliveryOrderHeader.salesOrderId)) {
          swal("Please Select the Sales Order");
        } else if (isEmpty(JuteGovtDeliveryOrderHeader.branch)) {
          swal("Please Select the Branch");
        } else if (isEmpty(JuteGovtDeliveryOrderHeader.deliveryDate)) {
          swal("Please Select the Delivery Order Date");
        } else if (isEmpty(JuteGovtDeliveryOrderHeader.customerId)) {
          swal("Please Select the Customer/Consignee");
        } else if (isEmpty(JuteGovtDeliveryOrderHeader.billingTo)) {
          swal("Please Select the Billing To");
        } else if (isEmpty(JuteGovtDeliveryOrderHeader.shippingTo)) {
          swal("Please Select the Shipping To");
        } else if (isEmpty(JuteGovtDeliveryOrderHeader.bankId)) {
          swal("Please Select the Bank Name");
        }  else if (JuteGovtDeliveryOrderHeader.deliveryTerms === "") {
          swal("Please Select the Delivery terms");
        } else if (isEmpty(JuteGovtDeliveryOrderHeader.transporter)) {
          swal("Please Select Transporter Name");
        } else {
          setcurrentStep(currentStep + 1);
        }
      } else {
        setcurrentStep(currentStep + 1);
      }
    } else if (currentStep == 3) {
      if (invoiceType == 1) {
        var ValidateFields = false;
        var FilteredDatas = RegularDeliveryOrderLineItems.filter(
          (item) => item.isActive !== 0
        );
        if (FilteredDatas.length === 1) {
          FilteredDatas.map((item, index) => {
            if (item.itemGroupId === "") {
              swal("Please select the ItemGroup");
              return false;
            } else if (item.itemId === "") {
              swal("Please select the Item");
              return false;
            } else if (Number(item.rate) <= 0) {
              swal("Please Enter the Rate");
              return false;
            } else if (Number(item.quantity) <= 0) {
              swal("Please Enter the Quantity");
              return false;
            } else if (item.uom === "") {
              swal("Please Enter the uom");
              return false;
            } else {
              ValidateFields = true;
            }
          });
        } else {
          var FilteredData = RegularDeliveryOrderLineItems.filter(
            (item) => item.isActive !== 0 && item.itemGroupId !== ""
          );
          for (var i = 0; i <= FilteredData.length - 1; i++) {
            if (FilteredData[i].itemGroupId === "") {
              swal("Please select the ItemGroup");
              return false;
            } else if (FilteredData[i].itemId == null) {
              swal("Please select the Item");
              return false;
            } else if (Number(FilteredData[i].quantity) <= 0) {
              swal("Please Enter the Quantity");
              return false;
            } else if (Number(FilteredData[i].rate) <= 0) {
              swal("Please Enter the Rate");
              return false;
            } else if (FilteredData[i].uom === "") {
              swal("Please Enter the uom");
              return false;
            } else {
              ValidateFields = true;
            }
          }
        }
        if (ValidateFields === true) {
          setcurrentStep(currentStep + 1);
        }
      } else if (invoiceType == 2) {
        var ValidateFields = false;
        var FilteredDatas = JuteGovtDeliveryOrderLineItems.filter(
          (item) => item.isActive !== 0
        );
        if (FilteredDatas.length == 1) {
          FilteredDatas.map((item, index) => {
            if (item.itemGroupId === "") {
              swal("Please select the ItemGroup");
              return false;
            } else if (item.itemId === "") {
              swal("Please select the Item");
              return false;
            } else if (Number(item.quantity) <= 0) {
              swal("Please Enter the Quantity");
              return false;
            } else if (isEmptyWithZero(item.rate)) {
              swal("Please Enter the Rate");
              return false;
            } else if (item.uom === "") {
              swal("Please Enter the uom");
              return false;
            } else {
              ValidateFields = true;
            }
          });
        } else {
          var FilteredData = JuteGovtDeliveryOrderLineItems.filter(
            (item) => item.isActive !== 0 && item.itemGroupId !== ""
          );
          for (var i = 0; i <= FilteredData.length - 1; i++) {
            if (FilteredData[i].itemGroupId === "") {
              swal("Please select the ItemGroup");
              return false;
            } else if (FilteredData[i].itemId == "") {
              swal("Please select the Item");
              return false;
            } else if (Number(FilteredData[i].quantity) <= 0) {
              swal("Please Enter the Quantity");
              return false;
            } else if (Number(FilteredData[i].rate) <= 0) {
              swal("Please Enter the Rate");
              return false;
            } else if (FilteredData[i].uom === "") {
              swal("Please Enter the uom");
              return false;
            } else {
              ValidateFields = true;
            }
          }
        }
        if (ValidateFields === true) {
          setcurrentStep(currentStep + 1);
        }
      } else if (invoiceType == 3) {
        var ValidateFields = false;
        var FilteredDatas = JuteYarnDeliveryOrderLineItems.filter(
          (item) => item.isActive !== 0
        );
        if (FilteredDatas.length === 1) {
          FilteredDatas.map((item, index) => {
            if (item.itemGroupId === "") {
              swal("Please select the ItemGroup");
              return false;
            } else if (item.itemId === "") {
              swal("Please select the Item");
              return false;
            } else if (item.rate === "") {
              swal("Please Enter the Rate");
              return false;
            } else if (item.yarnRateUomId === null) {
              swal("Please Enter the Rate UOM2");
              return false;
            } else if (item.quantity <= 0) {
              swal("Please Enter the Quantity");
              return false;
            } else if (item.uom === "") {
              swal("Please Enter the uom");
              return false;
            }else if (item.yarnQuantityUomId === "") {
              swal("Please Enter the uom2");
              return false;
            }  else {
              ValidateFields = true;
            }
          });
        } else {
          var FilteredData = JuteYarnDeliveryOrderLineItems.filter(
            (item) => item.isActive !== 0 && item.itemGroupId !== ""
          );
          for (var i = 0; i <= FilteredData.length - 1; i++) {
            if (FilteredData[i].itemGroupId === "") {
              swal("Please select the ItemGroup");
              return false;
            } else if (FilteredData[i].itemId == null) {
              swal("Please select the Item");
              return false;
            } else if (FilteredData[i].rate === "") {
              swal("Please Enter the Rate");
              return false;
            }  else if (FilteredData[i].yarnRateUomId === null) {
              swal("Please Enter the Rate UOM2");
              return false;
            } else if (FilteredData[i].quantity === "") {
              swal("Please Enter the Quantity");
              return false;
            } else if (FilteredData[i].uom === "") {
              swal("Please Enter the uom");
              return false;
            }else if (FilteredData[i].yarnQuantityUomId === "") {
              swal("Please Enter the uom2");
              return false;
            }  else {
              ValidateFields = true;
            }
          }
        }
        if (ValidateFields === true) {
          setcurrentStep(currentStep + 1);
        }
      } else if (invoiceType == 4) {
        var ValidateFields = false;
        var FilteredDatas = DoJuteHessianLineItems.filter(
          (item) => item.isActive !== 0
        );
        if (FilteredDatas.length == 1) {
          FilteredDatas.map((item, index) => {
            if (item.itemGroupId === "") {
              swal("Please select the ItemGroup");
              return false;
            } else if (item.itemId === "") {
              swal("Please select the Item");
              return false;
            } else if (item.rate === "") {
              swal("Please Enter the Rate");
              return false;
            } else if (Number(item.quantity) <= 0) {
              swal("Please Enter the Quantity");
              return false;
            } else if (item.uom === "") {
              swal("Please Enter the uom");
              return false;
            } else {
              ValidateFields = true;
            }
          });
        } else {
          var FilteredData = DoJuteHessianLineItems.filter(
            (item) => item.isActive !== 0 && item.itemGroupId !== ""
          );
          for (var i = 0; i <= FilteredData.length - 1; i++) {
            if (FilteredData[i].itemGroupId === "") {
              swal("Please select the ItemGroup");
              return false;
            } else if (FilteredData[i].itemId == null) {
              swal("Please select the Item");
              return false;
            } else if (FilteredData[i].rate === "") {
              swal("Please Enter the Rate");
              return false;
            } else if (FilteredData[i].quantity <= 0) {
              swal("Please Enter the Quantity");
              return false;
            } else if (FilteredData[i].uom === "") {
              swal("Please Enter the uom");
              return false;
            } else {
              ValidateFields = true;
            }
          }
        }
        if (ValidateFields === true) {
          setcurrentStep(currentStep + 1);
        }
      } else {
        setcurrentStep(currentStep + 1);
      }
    } else {
      setcurrentStep(currentStep + 1);
    }
  };
  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };
  const handleChange = (selectedValue, selectedName, name) => {
    if (name === "invoiceType") {
      setinvoiceType(selectedValue);
    }
  };
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("Configuration")).configList) {
      if (
        JSON.parse(localStorage.getItem("Configuration")).configList.length !==
        0
      ) {
        JSON.parse(localStorage.getItem("Configuration")).configList.map(
          (item) => {
            if (item.configParameter === "invoiceType") {
              if (JSON.parse(item.configValue)) {
                if (JSON.parse(item.configValue).length !== 0) {
                  var List = [];
                  JSON.parse(item.configValue).map((item) => {
                    if (item.juteGovt == 1) {
                      List.push({
                        value: 2,
                        label: "Jute Government",
                        name: "Jute Government",
                      });
                    }
                    if (item.juteHessian == 1) {
                      List.push({
                        value: 4,
                        label: "Jute Hessian",
                        name: "Jute Hessian",
                      });
                    }
                    if (item.juteYarn === 1) {
                      List.push({
                        value: 3,
                        label: "Jute Yarn",
                        name: "Jute yarn",
                      });
                    }
                    if (item.regInvoice === 1) {
                      List.push({
                        value: 1,
                        label: "Regular Invoice",
                        name: "Regular Invoice",
                      });
                    }
                    setInvoiceData(List);
                  });
                }
              }
            }
          }
        );
      }
    }
  }, [localStorage.getItem("Configuration")]);

  useEffect(() => {
    setJuteGovtLineItemsData();
    setJuteGovtHeadersData(JuteGovtHeadersData);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (locationState.state !== undefined) {
      if (locationState.state.rowData !== undefined) {
        if (locationState.state.rowData.invoiceType) {
          setinvoiceType(locationState.state.rowData.invoiceType);
        }
        setcurrentStep(5);
        if (locationState.state.rowData.invoiceType === 2) {
          setinvoiceType(2);
          dispatch(
            JuteGovtDeliveryOrderViewById(
              serverApi.JOTE_GOVT_DELIVERY_ORDER_VIEW_BY_ID +
                "2/" +
                locationState.state.rowData.deliveryOrderId +
                "/" +
                JSON.parse(localStorage.getItem("authUser")).userId,
              history
            )
          );
          dispatch(
            getDOFiles(
              serverApi.GET_FILES +
                "46/" +
                locationState.state.rowData.deliveryOrderId+
                "/" +
                localStorage.getItem("companyId"),
                history
            )
          );
        } else if (locationState.state.rowData.invoiceType === 3) {
          setinvoiceType(3);
          dispatch(
            JuteYarnDeliveryOrderViewById(
              serverApi.DELIVERY_ORDER_VIEW_BY_ID +
                "3/" +
                locationState.state.rowData.deliveryOrderId +
                "/" +
                JSON.parse(localStorage.getItem("authUser")).userId,
              history
            )
          );
          dispatch(
            getDOFiles(
              serverApi.GET_FILES +
                "46/" +
                locationState.state.rowData.deliveryOrderId+
                "/" +
                localStorage.getItem("companyId"),
                history
            )
          );
        } else if (locationState.state.rowData.invoiceType === 4) {
          setinvoiceType(4);
          dispatch(
            DeliveryOrderJuteHessianById(
              serverApi.DELIVERY_ORDER_VIEW_BY_ID +
                "4/" +
                locationState.state.rowData.deliveryOrderId +
                "/" +
                JSON.parse(localStorage.getItem("authUser")).userId,
              history
            )
          );
          dispatch(
            getDOFiles(
              serverApi.GET_FILES +
                "46/" +
                locationState.state.rowData.deliveryOrderId+
                "/" +
                localStorage.getItem("companyId"),
                history
            )
          );
        } else {
          dispatch(
            RegularDeliveryOrderViewBYId(
              serverApi.DELIVERY_ORDER_VIEW_BY_ID +
                "1/" +
                locationState.state.rowData.deliveryOrderId +
                "/" +
                JSON.parse(localStorage.getItem("authUser")).userId,
              history
            )
          );
          dispatch(
            getDOFiles(
              serverApi.GET_FILES +
                "46/" +
                locationState.state.rowData.deliveryOrderId+
                "/" +
                localStorage.getItem("companyId"),
                history
            )
          );
        }
      }
    }
  }, []);
  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };
  const handleCurrentStep = (data) => {
    setcurrentStep(data);
  };
  const onClickCreate = () => {
    if (invoiceType === 2) {
      CreateJuteGovtDelOrder();
    } else if (invoiceType === 3) {
      CreatePayloadForJuteYarn();
    } else if (invoiceType === 4) {
      CreatePayloadForJuteHessian();
    } else {
      CreatePayloadForRegular()
    }
  };
  const CreatePayloadForRegular =() =>{
    var LineItemDTO = [];
    var FilteredData = RegularDeliveryOrderLineItems.filter(
      (item) =>
        item.isActive !== 0 &&
        item.itemGroupId !== null &&
        item.itemGroupId !== ""
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemDTO.push({
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          createdDate: "",
          companyId: localStorage.getItem("companyId"),
          salesId: item.salesId,
          salesLineItemId: item.salesLineItemId,
          hsnCode: item.hsnCode,
          itemGroupId: item.itemGroupId,
          itemId: item.itemId,
          itemGroupValue: item.itemGroupValue,
          hsncode: item.hsncode,
          itemMake: item.itemMake,
          quantity: limitDecimals(item.quantity, 3),
          uom: item.uom,
          rate: limitDecimals(item.rate, 3),
          invoiceQty: item.invoiceQty,
          igstAmount: limitDecimals(item.igstAmount),
          igstPercent: limitDecimals(item.igstPercent),
          cgstAmount: limitDecimals(item.cgstAmount),
          cgstPercent: limitDecimals(item.cgstPercent),
          sgstAmount: limitDecimals(item.sgstAmount),
          sgstPercent: limitDecimals(item.sgstPercent),
          cessAmount: item.cessAmount,
          discountType: item.discountType,
          discount: limitDecimals(item.discount),
          discountedRate: limitDecimals(item.discountedRate, 3),
          discountAmount:limitDecimals(item.discountAmount,2),
          netAmount: limitDecimals(item.netAmount,2),
          totalAmount: limitDecimals(item.totalAmount),
          remarks: item.remarks,
          balesPacketSlNo: item.balesPacketSlNo,
          status: item.status,
        });
      });
    }
    const data = {
      finYear: localStorage.getItem("acadamicYear"),
      createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
      createdDate: TodayDate.split("T", 2)[0],
      invoiceType: RegularDeliveryOrderHeader.invoiceType,
      deliveryDate: moment(RegularDeliveryOrderHeader.deliveryDate).format(
        "YYYY-MM-DD"
      ),
      company: localStorage.getItem("companyId"),
      branch: RegularDeliveryOrderHeader.branch,
      salesOrderId: RegularDeliveryOrderHeader.salesOrderId,
      salesOrderNumber:RegularDeliveryOrderHeader.salesOrderNumber,
      brokerId: RegularDeliveryOrderHeader.brokerId,
      brokerCommisionPercent: RegularDeliveryOrderHeader.brokerCommisionPercent,
      customerId: RegularDeliveryOrderHeader.customerId,
      bankId: RegularDeliveryOrderHeader.bankId,
      bankName: RegularDeliveryOrderHeader.bankName,
      bankBranch :RegularDeliveryOrderHeader.bankBranch,
      ifscCode: RegularDeliveryOrderHeader.ifscCode,
      accountNo: RegularDeliveryOrderHeader.accountNo,
      micrCode: RegularDeliveryOrderHeader.micrCode,
      billingTo: RegularDeliveryOrderHeader.billingTo,
      shippingTo: RegularDeliveryOrderHeader.shippingTo,
      termsConditionType: RegularDeliveryOrderHeader.termsConditionType,
      transporter: RegularDeliveryOrderHeader.transporter,
      status: RegularDeliveryOrderHeader.status,
      challanNo: RegularDeliveryOrderHeader.challanNo,
      challanDate: RegularDeliveryOrderHeader.challanDate,
      taxType: RegularDeliveryOrderHeader.taxType,
      footerNote: RegularDeliveryOrderHeader.footerNote,
      internalNote: RegularDeliveryOrderHeader.internalNote,
      termsCondition: RegularDeliveryOrderHeader.termsCondition,
      deliveryTerms: RegularDeliveryOrderHeader.deliveryTerms,
      paymentTerms: RegularDeliveryOrderHeader.paymentTerms,
      deliveryDays: RegularDeliveryOrderHeader.deliveryDays,
      freightCharges: RegularDeliveryOrderHeader.freightCharges,
      amountWithoutTax: RegularDeliveryOrderHeader.amountWithoutTax,
      amountWithTax: RegularDeliveryOrderHeader.amountWithTax,
      updatedBy: "",
      updatedOn: "",
      approvedBy: "",
      approvedOn: "",
      regularDeliveryOrderLineItemsDTO: LineItemDTO,
      vehicleNumber: RegularDeliveryOrderHeader.vehicleNo,
    };
    dispatch(
      createDeliveryOrder(serverApi.CREATE_DELIVERY_ORDER, data, history)
    );
  }
  const CreateJuteGovtDelOrder = () => {
    let LineItemForJuteGovt = [];
    let FilteredData = JuteGovtDeliveryOrderLineItems.filter(
      (item) =>
        item.isActive !== 0 &&
        item.itemGroupId !== null &&
        item.itemGroupId !== ""
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemForJuteGovt.push({
          createdBy: userDetails.userId,
          createdDate: TodayDate.split("T", 2)[0],
          companyId: localStorage.getItem("companyId"),
          salesId: item.salesId,
          salesLineItemId: item.salesLineItemId,
          hsnCode: item.hsnCode,
          itemGroupId: item.itemGroupId,
          itemId: item.itemId,
          itemGroupValue: item.itemGroupValue,
          hsncode: item.hsncode,
          itemMake: item.itemMake,
          quantity: limitDecimals(item.quantity,3),
          uom: item.uom,
          rate:limitDecimals(item.rate,3),
          invoiceQty: item.invoiceQty,
          igstAmount:limitDecimals(item.igstAmount,2),
          igstPercent: limitDecimals(item.igstPercent,2),
          cgstAmount: limitDecimals(item.cgstAmount,2),
          cgstPercent:limitDecimals(item.cgstPercent,2),
          sgstAmount:limitDecimals(item.sgstAmount,2),
          sgstPercent:limitDecimals(item.sgstPercent,2),
          cessAmount:item.cessAmount,
          discountType: item.discountType,
          discount:limitDecimals(item.discount,2),
          discountedRate:limitDecimals(item.discountedRate,3),
          discountAmount:limitDecimals(item.discountAmount,2),
          netAmount:limitDecimals(item.netAmount,2),
          totalAmount: limitDecimals(item.totalAmount,2),
          remarks: item.remarks,
          balesPacketSlNo: item.balesPacketSlNo,
          status: item.status,
          govtDeliveryOrderId: item.govtDeliveryOrderId,
          govtDeliveryRateUom: item.govtDeliveryRateUom,
          govtDeliveryRateUom2: item.govtDeliveryRateUom2,
          govtDeliveryRateUom3: item.govtDeliveryRateUom3,
          govtDeliveryQuantityUom: item.govtDeliveryQuantityUom,
          govtDeliveryQuantityUom2: item.govtDeliveryQuantityUom2,
          govtDeliveryQuantityUom3: item.govtDeliveryQuantityUom3,
          govtDeliveryRelationValue: item.govtDeliveryRelationValue,
          govtDeliveryRelationValue2: item.govtDeliveryRelationValue2,
          govtDeliveryRelationValue3: item.govtDeliveryRelationValue3,
          govtDeliveryQtyCalculatedValue: item.govtDeliveryQtyCalculatedValue,
          govtDeliveryQtyCalculatedValue2: item.govtDeliveryQtyCalculatedValue2,
          govtDeliveryQtyCalculatedValue3: item.govtDeliveryQtyCalculatedValue3,
          govtDeliveryRateCalculatedValue: item.govtDeliveryRateCalculatedValue,
          govtDeliveryRateCalculatedValue2:
            item.govtDeliveryRateCalculatedValue2,
          govtDeliveryRateCalculatedValue3:
            item.govtDeliveryRateCalculatedValue3,
          isActive: 1,
          govtIsActive:1,
          govtDeliveryBalesPacketSlNo: item.govtDeliveryBalesPacketSlNo,
        });
      });
    }

    const JuteGovtCreateData = {
      deliveryOrderDTO: {
        finYear: localStorage.getItem("acadamicYear"),
        createdBy: userDetails.userId,
        createdDate: TodayDate.split("T", 2)[0],
        invoiceType: "2",
        deliveryDate: JuteGovtDeliveryOrderHeader.deliveryDate,
        company: localStorage.getItem("companyId"),
        branch: JuteGovtDeliveryOrderHeader.branch,
        bankId: JuteGovtDeliveryOrderHeader.bankId,
        bankName: JuteGovtDeliveryOrderHeader.bankName,
        bankBranch :JuteGovtDeliveryOrderHeader.bankBranch,
        ifscCode: JuteGovtDeliveryOrderHeader.ifscCode,
        accountNo: JuteGovtDeliveryOrderHeader.accountNo,
        micrCode: JuteGovtDeliveryOrderHeader.micrCode,
        salesOrderId: JuteGovtDeliveryOrderHeader.salesOrderId,
        brokerId: JuteGovtDeliveryOrderHeader.brokerId,
        customerId: JuteGovtDeliveryOrderHeader.customerId,
        billingTo: JuteGovtDeliveryOrderHeader.billingTo,
        shippingTo: JuteGovtDeliveryOrderHeader.shippingTo,
        transporter: JuteGovtDeliveryOrderHeader.transporter,
        status: 1,
        challanNo: JuteGovtDeliveryOrderHeader.challanNo,
        challanDate: JuteGovtDeliveryOrderHeader.challanDate,
        taxType: JuteGovtDeliveryOrderHeader.taxType,
        footerNote: JuteGovtDeliveryOrderHeader.footerNote,
        internalNote: JuteGovtDeliveryOrderHeader.internalNote,
        deliveryTerms: JuteGovtDeliveryOrderHeader.deliveryTerms,
        paymentTerms: JuteGovtDeliveryOrderHeader.paymentTerms,
        deliveryDays: JuteGovtDeliveryOrderHeader.deliveryDays,
        freightCharges: JuteGovtDeliveryOrderHeader.freightCharges,
        amountWithoutTax:limitDecimals(JuteGovtDeliveryOrderHeader.netTotal,2),
        amountWithTax:limitDecimals(JuteGovtDeliveryOrderHeader.TotalAmount,2),
        updatedBy: "",
        updatedOn: "",
        approvedBy: "",
        approvedOn: "",
        termsCondition: JuteGovtDeliveryOrderHeader.termsCondition,
        termsConditionType: JuteGovtDeliveryOrderHeader.termsConditionType,
        vehicleNumber: JuteGovtDeliveryOrderHeader.vehicleNo,
        deliveryOrderLineItemDTO: LineItemForJuteGovt,
      },
      pcsoNo: JuteGovtDeliveryOrderHeader.pcsoNo,
      pcsoDate: moment(JuteGovtDeliveryOrderHeader.pcsoDate).format(
        "YYYY-MM-DD"
      ),
      ammendmentNo: JuteGovtDeliveryOrderHeader.ammendmentNo,
      ammendmentDate: JuteGovtDeliveryOrderHeader.ammendmentDate,
      ammendmentNo2: JuteGovtDeliveryOrderHeader.ammendmentNo2,
      ammendmentDate2: JuteGovtDeliveryOrderHeader.ammendmentDate2,
      ammendmentNo3: JuteGovtDeliveryOrderHeader.ammendmentNo3,
      ammendmentDate3: JuteGovtDeliveryOrderHeader.ammendmentDate3,
      ammendmentNo4: JuteGovtDeliveryOrderHeader.ammendmentNo4,
      ammendmentDate4: JuteGovtDeliveryOrderHeader.ammendmentDate4,
      ammendmentNo5: JuteGovtDeliveryOrderHeader.ammendmentNo5,
      ammendmentDate5: JuteGovtDeliveryOrderHeader.ammendmentDate5,
      destinationRailHead: JuteGovtDeliveryOrderHeader.destinationRailHead,
      loadingPoint: JuteGovtDeliveryOrderHeader.loadingPoint,
    };
    dispatch(
      CreateJuteGovtDeliveryOrder(
        serverApi.CREATE_JUTE_GOVT_DELIVERY_ORDER,
        JuteGovtCreateData,
        history
      )
    );
  };
  const CreatePayloadForJuteYarn = () => {
    let LineItemForJuteYarn = [];
    let FilteredData = JuteYarnDeliveryOrderLineItems.filter(
      (item) => item.isActive !== 0 && item.itemGroupId !== ""
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemForJuteYarn.push({
          invoiceType: "3",
          balesPacketSlNo: item.balesPacketSlNo,
          cessAmount: item.cessAmount,
          cgstAmount:limitDecimals(item.cgstAmount,2),
          cgstPercent:limitDecimals(item.cgstPercent,2),
          companyId: localStorage.getItem("companyId"),
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          createdDate: TodayDate.split("T", 2)[0],
          discount: limitDecimals(item.discount,2),
          discountedRate:limitDecimals(item.discountedRate,3),
          discountType: item.discountType,
          discountAmount:limitDecimals(item.discountAmount,2),
          hsncode: item.hsncode,
          igstAmount: limitDecimals(item.igstAmount,2),
          igstPercent: limitDecimals(item.igstPercent,2),
          invoiceQty: item.invoiceQty,
          itemGroupId: item.itemGroupId,
          itemGroupValue: item.itemGroupValue,
          itemId: item.itemId,
          itemMake: item.itemMake,
          netAmount:limitDecimals(item.netAmount,2),
          quantity: limitDecimals(item.quantity,3),
          rate: limitDecimals(item.rate,3),
          remarks: item.remarks,
          salesId: item.salesId,
          salesLineItemId: item.salesLineItemId,
          sgstAmount:limitDecimals(item.sgstAmount,2),
          sgstPercent: limitDecimals(item.sgstPercent,2),
          status: item.status,
          totalAmount:limitDecimals(item.totalAmount,2),
          uom: item.uom,
          isActive: item.isActive,
          yarnIsActive: item.yarnIsActive,
          yarnQuantityCalculatedValue: item.yarnQuantityCalculatedValue,
          yarnQuantityUom: item.yarnQuantityUom,
          yarnQuantityUomId: item.yarnQuantityUomId,
          yarnQuantityUomRelationValue: item.yarnQuantityUomRelationValue,
          yarnRateCalculatedValue: item.yarnRateCalculatedValue,
          yarnRateUom: item.yarnRateUom,
          yarnRateUomId: item.yarnRateUomId,
          yarnRateUomRelationValue: item.yarnRateUomRelationValue,
          yarnUpdatedBy: "",
          yarnUpdatedOn: "",
          yarnVariableRelationValue: item.yarnVariableRelationValue,
          yarnRateIncludingBrokerage:limitDecimals(item.yarnRateIncludingBrokerage,3),
          yarnRateExcludingBrokerage:limitDecimals(item.yarnRateExcludingBrokerage,3),
        });
      });
    }
    const data = {
      deliveryOrderDTO: {
        amountWithoutTax:limitDecimals(JuteYarnDeliveryOrderHeader.netTotal,2),
        amountWithTax: limitDecimals(JuteYarnDeliveryOrderHeader.TotalAmount,2),
        approvedBy: "",
        approvedOn: "",
        billingTo: JuteYarnDeliveryOrderHeader.billingTo,
        branch: JuteYarnDeliveryOrderHeader.branch,
        bankId: JuteYarnDeliveryOrderHeader.bankId,
        bankName: JuteYarnDeliveryOrderHeader.bankName,
        bankBranch :JuteYarnDeliveryOrderHeader.bankBranch,
        ifscCode: JuteYarnDeliveryOrderHeader.ifscCode,
        accountNo: JuteYarnDeliveryOrderHeader.accountNo,
        micrCode: JuteYarnDeliveryOrderHeader.micrCode,
        brokerId: JuteYarnDeliveryOrderHeader.brokerId,
        challanDate: "",
        challanNo: "",
        company: localStorage.getItem("companyId"),
        createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
        createdDate: TodayDate.split("T", 2)[0],
        customerId: JuteYarnDeliveryOrderHeader.customerId,
        deliveryDate: JuteYarnDeliveryOrderHeader.deliveryDate,
        deliveryDays: JuteYarnDeliveryOrderHeader.deliveryDays,
        deliveryTerms: JuteYarnDeliveryOrderHeader.deliveryTerms,
        finYear: localStorage.getItem("acadamicYear"),
        footerNote: JuteYarnDeliveryOrderHeader.footerNote,
        freightCharges: JuteYarnDeliveryOrderHeader.freightCharges,
        internalNote: JuteYarnDeliveryOrderHeader.internalNote,
        invoiceType: 3,
        paymentTerms: JuteYarnDeliveryOrderHeader.paymentTerms,
        regDeliveryOrderId: "",
        salesOrderId: JuteYarnDeliveryOrderHeader.salesOrderId,
        shippingTo: JuteYarnDeliveryOrderHeader.shippingTo,
        status: 1,
        taxType: JuteYarnDeliveryOrderHeader.taxType,
        termsCondition: JuteYarnDeliveryOrderHeader.termsCondition,
        termsConditionType: JuteYarnDeliveryOrderHeader.termsConditionType,
        transporter: JuteYarnDeliveryOrderHeader.transporter,
        brokerCommisionPercent:JuteYarnDeliveryOrderHeader.brokerCommisionPercent,
        vehicleNumber:JuteYarnDeliveryOrderHeader.vehicleNumber,
        updatedBy: "",
        updatedOn: "",
        deliveryOrderNo: JuteYarnDeliveryOrderHeader.deliveryOrderNo,
        deliveryOrderLineItemDTO: LineItemForJuteYarn,
  
      },
      destinationRailHead: "",
      invoiceTypeId: "3",
      loadingPoint: "",
      regularDeliveryOrderId: "",
      yarnDeliveryOrderId: "",
    };
    dispatch(
      CreateJuteYarnDeliveryOrder(
        serverApi.CREATE_JUTE_YARN_DELIVERY_ORDER,
        data,
        history
      )
    )
  };
  const CreatePayloadForJuteHessian = () => {
    let LineItemDTO = [];
    let FilteredData = DoJuteHessianLineItems.filter(
      (item) => item.isActive !== 0 && item.itemGroupId !== ""
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemDTO.push({
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          createdDate: "",
          companyId: localStorage.getItem("companyId"),
          salesId: item.salesId,
          salesLineItemId: item.salesLineItemId,
          hsnCode: item.hsnCode,
          itemGroupId: item.itemGroupId,
          itemGroupValue: item.itemGroupValue,
          itemId: item.itemId,
          hsncode: item.hsncode,
          itemMake: item.itemMake,
          quantity:limitDecimals(item.quantity,3),
          uom: item.uom,
          rate:limitDecimals(item.rate,3),
          invoiceQty: item.invoiceQty,
          igstAmount:limitDecimals(item.igstAmount,3),
          igstPercent:limitDecimals(item.igstPercent,3),
          cgstAmount:limitDecimals(item.cgstAmount,3),
          cgstPercent:limitDecimals(item.cgstPercent,3),
          sgstAmount: limitDecimals(item.sgstAmount,3),
          sgstPercent:limitDecimals(item.sgstPercent,3),
          cessAmount: item.cessAmount,
          discountType: item.discountType,
          discount:limitDecimals(item.discount,2),
          discountedRate:limitDecimals(item.discountedRate,3),
          discountAmount:limitDecimals(item.discountAmount,2),
          netAmount: limitDecimals(item.netAmount,2),
          totalAmount:limitDecimals(item.totalAmount,2),
          hessianAmountExcludingBrokerage:limitDecimals(item.hessianAmountExcludingBrokerage,3) ,
          remarks: item.remarks,
          balesPacketSlNo: item.balesPacketSlNo,
          status: item.status,
          hessianBalesPacketSlNo: "",
          hessianRateIncludingBrokerage: limitDecimals(item.hessianRateIncludingBrokerage,3),
          hessianQuantityUom: item.hessianQuantityUom,
          hessianQuantityUom2: item.hessianQuantityUom2,
          hessianQuantityUomId: item.hessianQuantityUomId,
          hessianQuantityRelationValue: item.hessianQuantityRelationValue,
          hessianQuantityCalculatedValue:item.hessianQuantityCalculatedValue,
          hessianRateUom: item.hessianRateUom,
          hessianRateUomId: item.hessianRateUomId,
          hessianRateRelationValue: item.hessianRateRelationValue,
          hessianRateCalculatedValue:item.hessianRateCalculatedValue,
          isActive:item.isActive,
          hessianIsActive:item.hessianIsActive
        });
      });
    }
    const data = {
      deliveryOrderDTO: {
        finYear: localStorage.getItem("acadamicYear"),
        createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
        createdDate: TodayDate.split("T", 2)[0],
        invoiceType: DoJuteHessianHeaders.invoiceType,
        deliveryDate: DoJuteHessianHeaders.deliveryDate,
        company: localStorage.getItem("companyId"),
        branch: DoJuteHessianHeaders.branch,
        salesOrderId: DoJuteHessianHeaders.salesOrderId,
        brokerId: DoJuteHessianHeaders.brokerId,
        customerId: DoJuteHessianHeaders.customerId,
        bankId: DoJuteHessianHeaders.bankId,
        bankBranch :DoJuteHessianHeaders.bankBranch,
        bankName: DoJuteHessianHeaders.bankName,
        ifscCode: DoJuteHessianHeaders.ifscCode,
        accountNo: DoJuteHessianHeaders.accountNo,
        micrCode: DoJuteHessianHeaders.micrCode,
        billingTo: DoJuteHessianHeaders.billingTo,
        shippingTo: DoJuteHessianHeaders.shippingTo,
        termsConditionType: DoJuteHessianHeaders.termsConditionType,
        transporter: DoJuteHessianHeaders.transporter,
        vehicleNumber: DoJuteHessianHeaders.vehicleNo,
        status: DoJuteHessianHeaders.status,
        challanNo: DoJuteHessianHeaders.challanNo,
        challanDate: DoJuteHessianHeaders.challanDate,
        taxType: DoJuteHessianHeaders.taxType,
        footerNote: DoJuteHessianHeaders.footerNote,
        internalNote: DoJuteHessianHeaders.internalNote,
        termsCondition: DoJuteHessianHeaders.termsCondition,
        deliveryTerms: DoJuteHessianHeaders.deliveryTerms,
        paymentTerms: DoJuteHessianHeaders.paymentTerms,
        deliveryDays: DoJuteHessianHeaders.deliveryDays,
        freightCharges: DoJuteHessianHeaders.freightCharges,
        amountWithoutTax: DoJuteHessianHeaders.netTotal,
        amountWithTax: DoJuteHessianHeaders.TotalAmount,
        brokerCommisionPercent: DoJuteHessianHeaders.brokerCommisionPercent,
        updatedBy: "",
        updatedOn: "",
        approvedBy: "",
        approvedOn: "",
        deliveryOrderLineItemDTO: LineItemDTO,
      },
      invoiceTypeId: "4",
      loadingPoint: "",
      destinationRailHead: "",
    };
    dispatch(
      CreateJuteHessianDeliveryOrder(
        serverApi.CREATE_DO_JUTE_HESSIAN,
        data,
        history
      )
    );
  };
  const onClickApprove = (status) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Approve",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willApprove) => {
      if (willApprove) {
        OnUpdate(status);
      }
    });
  };
  const onClickReject = (status) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Reject",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willreject) => {
      if (willreject) {
        OnUpdate(status);
      }
    });
  };
  const OnUpdate = (status) => {
    if (invoiceType === 2) {
      UpdateJuteGovtDelOrder(status);
    } else if (invoiceType === 3) {
      UpdatePayloadForJuteYarn(status);
    } else if (invoiceType === 4) {
      UpdatePayloadForJuteHessian(status);
    } else {
      UpdatePayloadForRegular(status)
    }
  };
  const UpdatePayloadForRegular =(status) =>{
    let LineItemDTO = [];
    // let RegularDOLineItems = RegularDeliveryOrderLineItems.filter((item) => item.itemGroupId !== null )
    let RegularDOLineItems = RegularDeliveryOrderLineItems.filter(
      (item) => {
        if(item.isActive ==1 || item.regularDeliveryOrderLineItemId !== ''){
          return item;

        }
      }
    );
    if (RegularDOLineItems.length !== 0) {
      RegularDOLineItems.map((item) => {
        LineItemDTO.push({
          createdBy: item.createdBy,
          createdDate: TodayDate.split("T", 2)[0],
          companyId: localStorage.getItem("companyId"),
          salesId: item.salesId,
          salesLineItemId: item.salesLineItemId,
          itemGroupId: item.itemGroupId,
          itemId: item.itemId,
          itemGroupValue: item.itemGroupValue,
          hsncode: item.hsncode,
          itemMake: item.itemMake,
          quantity: limitDecimals(item.quantity,3),
          uom: item.uom,
          rate:limitDecimals(item.rate,3),
          invoiceQty: item.invoiceQty,
          igstAmount:limitDecimals(item.igstAmount,3),
          igstPercent: limitDecimals(item.igstPercent,3),
          cgstAmount:limitDecimals(item.cgstAmount,3),
          cgstPercent: limitDecimals(item.cgstPercent,3),
          sgstAmount:limitDecimals(item.sgstAmount,3),
          sgstPercent: limitDecimals(item.sgstPercent,3),
          cessAmount: item.cessAmount,
          discountType: item.discountType,
          discount:limitDecimals(item.discount,3),
          discountedRate:limitDecimals(item.discountedRate,3),
          discountAmount:limitDecimals(item.discountAmount,2),
          netAmount:limitDecimals(item.netAmount,2),
          totalAmount:limitDecimals(item.totalAmount,2),
          remarks: item.remarks,
          balesPacketSlNo: item.balesPacketSlNo,
          status: item.status,
          isActive: item.isActive,
          regDeliveryOrderId: item.regDeliveryOrderId,
          regularDeliveryOrderLineItemId: item.regularDeliveryOrderLineItemId,
        });
      });
    }
    const data = {
      finYear: localStorage.getItem("acadamicYear"),
      createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
      createdDate: TodayDate.split("T", 2)[0],
      invoiceType: RegularDeliveryOrderHeader.invoiceType,
      deliveryDate: RegularDeliveryOrderHeader.deliveryDate,
      company: localStorage.getItem("companyId"),
      branch: RegularDeliveryOrderHeader.branch,
      salesOrderId: RegularDeliveryOrderHeader.salesOrderId,
      salesOrderNumber:RegularDeliveryOrderHeader.salesOrderNumber,
      brokerId: RegularDeliveryOrderHeader.brokerId,
      bankId: RegularDeliveryOrderHeader.bankId,
      bankBranch :RegularDeliveryOrderHeader.bankBranch,
      bankName: RegularDeliveryOrderHeader.bankName,
      ifscCode: RegularDeliveryOrderHeader.ifscCode,
      accountNo: RegularDeliveryOrderHeader.accountNo,
      micrCode: RegularDeliveryOrderHeader.micrCode,
      brokerCommisionPercent: RegularDeliveryOrderHeader.brokerCommisionPercent,
      customerId: RegularDeliveryOrderHeader.customerId,
      billingTo: RegularDeliveryOrderHeader.billingTo,
      shippingTo: RegularDeliveryOrderHeader.shippingTo,
      termsConditionType: RegularDeliveryOrderHeader.termsConditionType,
      transporter: RegularDeliveryOrderHeader.transporter,
      status: status,
      challanNo: RegularDeliveryOrderHeader.challanNo,
      challanDate: RegularDeliveryOrderHeader.challanDate,
      taxType: RegularDeliveryOrderHeader.taxType,
      footerNote: RegularDeliveryOrderHeader.footerNote,
      internalNote: RegularDeliveryOrderHeader.internalNote,
      termsCondition: RegularDeliveryOrderHeader.termsCondition,
      deliveryTerms: RegularDeliveryOrderHeader.deliveryTerms,
      paymentTerms: RegularDeliveryOrderHeader.paymentTerms,
      deliveryDays: RegularDeliveryOrderHeader.deliveryDays,
      freightCharges: RegularDeliveryOrderHeader.freightCharges,
      amountWithoutTax: RegularDeliveryOrderHeader.netTotal,
      amountWithTax: RegularDeliveryOrderHeader.TotalAmount,
      updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
      updatedOn: moment().format("YYYY-MM-DD"),
      approvedBy: "",
      approvedOn: "",
      isActive: RegularDeliveryOrderHeader.isActive,
      regDeliveryOrderId: RegularDeliveryOrderHeader.regDeliveryOrderId,
      deliveryOrderNo: RegularDeliveryOrderHeader.deliveryOrderNo,
      regularDeliveryOrderLineItemsDTO: LineItemDTO,
      vehicleNumber: RegularDeliveryOrderHeader.vehicleNo,
    };
    dispatch(
      updateRegularDeliveryOrder(
        serverApi.UPDATE_DELIVERY_ORDER,
        data,
        history
      )
    );
  }
  const UpdateJuteGovtDelOrder = (status) => {
    var LineItemForJuteGovt = [];
    let FilteredData = JuteGovtDeliveryOrderLineItems.filter(
      (item) => {
        if(item.isActive ==1 || item.regularDeliveryOrderLineItemId !== ''){
          return item;
        }
      }
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemForJuteGovt.push({
          regDeliveryOrderId: item.regDeliveryOrderId,
          regularDeliveryOrderLineItemId: item.regularDeliveryOrderLineItemId,
          govtDeliveryOrderId: item.govtDeliveryOrderId,
          govtDeliveryOrderLineItemId: item.govtDeliveryOrderLineItemId,
          createdBy: userDetails.userId,
          createdDate: TodayDate.split("T", 2)[0],
          companyId: localStorage.getItem("companyId"),
          salesId: item.salesId,
          salesLineItemId: item.salesLineItemId,
          hsnCode: item.hsnCode,
          itemGroupId: item.itemGroupId,
          itemId: item.itemId,
          itemGroupValue: item.itemGroupValue,
          hsncode: item.hsncode,
          itemMake: item.itemMake,
          quantity: limitDecimals(item.quantity,3),
          uom: item.uom,
          rate: limitDecimals(item.rate,3),
          invoiceQty: item.invoiceQty,
          igstAmount:limitDecimals(item.igstAmount,2),
          igstPercent: limitDecimals(item.igstPercent,2),
          cgstAmount:limitDecimals(item.cgstAmount,2),
          cgstPercent: limitDecimals(item.cgstPercent,2),
          sgstAmount:limitDecimals(item.sgstAmount,2),
          sgstPercent:limitDecimals(item.sgstPercent,2),
          cessAmount: item.cessAmount,
          discountType: item.discountType,
          discount: limitDecimals(item.discount,2),
          discountedRate:limitDecimals(item.discountedRate,2),
          discountAmount:limitDecimals(item.discountAmount),
          netAmount:limitDecimals(item.netAmount,2),
          totalAmount: limitDecimals(item.totalAmount,2),
          remarks: item.remarks,
          balesPacketSlNo: item.balesPacketSlNo,
          status: item.status,
          govtDeliveryRateUom: item.govtDeliveryRateUom,
          govtDeliveryRateUom2: item.govtDeliveryRateUom2,
          govtDeliveryRateUom3: item.govtDeliveryRateUom3,
          govtDeliveryQuantityUom: item.govtDeliveryQuantityUom,
          govtDeliveryQuantityUom2: item.govtDeliveryQuantityUom2,
          govtDeliveryQuantityUom3: item.govtDeliveryQuantityUom3,
          govtDeliveryRelationValue: item.govtDeliveryRelationValue,
          govtDeliveryRelationValue2: item.govtDeliveryRelationValue2,
          govtDeliveryRelationValue3: item.govtDeliveryRelationValue3,
          govtDeliveryQtyCalculatedValue: item.govtDeliveryQtyCalculatedValue,
          govtDeliveryQtyCalculatedValue2: item.govtDeliveryQtyCalculatedValue2,
          govtDeliveryQtyCalculatedValue3: item.govtDeliveryQtyCalculatedValue3,
          govtDeliveryRateCalculatedValue: item.govtDeliveryRateCalculatedValue,
          govtDeliveryRateCalculatedValue2:
            item.govtDeliveryRateCalculatedValue2,
          govtDeliveryRateCalculatedValue3:
            item.govtDeliveryRateCalculatedValue3,
          isActive: item.isActive,
          govtIsActive:item.govtIsActive,
          govtDeliveryBalesPacketSlNo: item.govtDeliveryBalesPacketSlNo,
        });
      });
    }

    const JuteGovtUpdateData = {
      deliveryOrderDTO: {
        regDeliveryOrderId: JuteGovtDeliveryOrderHeader.regDeliveryOrderId,
        finYear: localStorage.getItem("acadamicYear"),
        createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
        createdDate: TodayDate.split("T", 2)[0],
        invoiceType: JuteGovtDeliveryOrderHeader.invoiceType,
        deliveryDate: JuteGovtDeliveryOrderHeader.deliveryDate,
        company: localStorage.getItem("companyId"),
        branch: JuteGovtDeliveryOrderHeader.branch,
        salesOrderId: JuteGovtDeliveryOrderHeader.salesOrderId,
        brokerId: JuteGovtDeliveryOrderHeader.brokerId,
        customerId: JuteGovtDeliveryOrderHeader.customerId,
        bankId: JuteGovtDeliveryOrderHeader.bankId,
        bankBranch :JuteYarnDeliveryOrderHeader.bankBranch,
        bankName: JuteGovtDeliveryOrderHeader.bankName,
        ifscCode: JuteGovtDeliveryOrderHeader.ifscCode,
        accountNo: JuteGovtDeliveryOrderHeader.accountNo,
        micrCode: JuteGovtDeliveryOrderHeader.micrCode,
        billingTo: JuteGovtDeliveryOrderHeader.billingTo,
        shippingTo: JuteGovtDeliveryOrderHeader.shippingTo,
        termsConditionType: JuteGovtDeliveryOrderHeader.termsConditionType,
        transporter: JuteGovtDeliveryOrderHeader.transporter,
        status: status,
        challanNo: JuteGovtDeliveryOrderHeader.challanNo,
        challanDate: JuteGovtDeliveryOrderHeader.challanDate,
        taxType: JuteGovtDeliveryOrderHeader.taxType,
        footerNote: JuteGovtDeliveryOrderHeader.footerNote,
        internalNote: JuteGovtDeliveryOrderHeader.internalNote,
        termsCondition: JuteGovtDeliveryOrderHeader.termsCondition,
        deliveryTerms: JuteGovtDeliveryOrderHeader.deliveryTerms,
        paymentTerms: JuteGovtDeliveryOrderHeader.paymentTerms,
        deliveryDays: JuteGovtDeliveryOrderHeader.deliveryDays,
        freightCharges: JuteGovtDeliveryOrderHeader.freightCharges,
        amountWithoutTax: JuteGovtDeliveryOrderHeader.netAmount,
        amountWithTax: JuteGovtDeliveryOrderHeader.TotalAmount,
        deliveryOrderNo: JuteGovtDeliveryOrderHeader.deliveryOrderNo,
        vehicleNumber: JuteGovtDeliveryOrderHeader.vehicleNo,
        updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
        updatedOn: "",
        approvedBy: "",
        approvedOn: "",
        deliveryOrderLineItemDTO: LineItemForJuteGovt,
      },
      pcsoNo: JuteGovtDeliveryOrderHeader.pcsoNo,
      pcsoDate: moment(JuteGovtDeliveryOrderHeader.pcsoDate).format(
        "YYYY-MM-DD"
      ),
      ammendmentNo: JuteGovtDeliveryOrderHeader.ammendmentNo,
      ammendmentDate: JuteGovtDeliveryOrderHeader.ammendmentDate,
      ammendmentNo2: JuteGovtDeliveryOrderHeader.ammendmentNo2,
      ammendmentDate2: JuteGovtDeliveryOrderHeader.ammendmentDate2,
      ammendmentNo3: JuteGovtDeliveryOrderHeader.ammendmentNo3,
      ammendmentDate3: JuteGovtDeliveryOrderHeader.ammendmentDate3,
      ammendmentNo4: JuteGovtDeliveryOrderHeader.ammendmentNo4,
      ammendmentDate4: JuteGovtDeliveryOrderHeader.ammendmentDate4,
      ammendmentNo5: JuteGovtDeliveryOrderHeader.ammendmentNo5,
      ammendmentDate5: JuteGovtDeliveryOrderHeader.ammendmentDate5,
      destinationRailHead: JuteGovtDeliveryOrderHeader.destinationRailHead,
      loadingPoint: JuteGovtDeliveryOrderHeader.loadingPoint,
      govtDeliveryOrderId: JuteGovtDeliveryOrderHeader.govtDeliveryOrderId,
    };

    dispatch(
      UpdateJuteGovtDeliveryOrder(
        serverApi.UPDATE_JUTE_GOVT_DELIVERY_ORDER,
        JuteGovtUpdateData,
        history
      )
    );
  };
  const UpdatePayloadForJuteYarn = (status) => {
    var LineItemForJuteYarn = [];
    let FilteredData = JuteYarnDeliveryOrderLineItems.filter(
      (item) =>  item.itemGroupId !== null
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemForJuteYarn.push({
          regularDeliveryOrderId: item.regDeliveryOrderId,
          regularDeliveryOrderLineItemId: item.regularDeliveryOrderLineItemId,
          yarnDeliveryOrderId: item.yarnDeliveryOrderId,
          yarnDeliveryOrderLineItemId: item.yarnDeliveryOrderLineItemId,
          invoiceType: item.invoiceType,
          balesPacketSlNo: item.balesPacketSlNo,
          cessAmount: item.cessAmount,
          cgstAmount: limitDecimals(item.cgstAmount,2),
          cgstPercent:limitDecimals(item.cgstPercent,2),
          companyId: item.companyId,
          createdBy: item.createdBy,
          createdDate: item.createdDate,
          discount: limitDecimals(item.discount,2),
          discountedRate: limitDecimals(item.discountedRate,3),
          discountType: item.discountType,
          discountAmount:limitDecimals(item.discountAmount,2),
          hsncode: item.hsncode,
          igstAmount:limitDecimals(item.igstAmount,2),
          igstPercent:limitDecimals(item.igstPercent,2),
          invoiceQty:item.invoiceQty,
          itemGroupId: item.itemGroupId,
          itemGroupValue: item.itemGroupValue,
          itemId: item.itemId,
          itemMake: item.itemMake,
          netAmount:limitDecimals(item.netAmount,2),
          quantity:limitDecimals(item.quantity,3),
          rate:limitDecimals(item.rate,3),
          remarks: item.remarks,
          salesId: item.salesId,
          salesLineItemId: item.salesLineItemId,
          sgstAmount:limitDecimals(item.sgstAmount,2),
          sgstPercent: limitDecimals(item.sgstPercent,2),
          status: item.status,
          totalAmount:limitDecimals(item.totalAmount,2),
          uom: item.uom,
          isActive: item.isActive,
          yarnIsActive: item.yarnIsActive,
          yarnQuantityCalculatedValue: item.yarnQuantityCalculatedValue,
          yarnQuantityUom: item.yarnQuantityUom,
          yarnQuantityUomId: item.yarnQuantityUomId,
          yarnQuantityUomRelationValue: item.yarnQuantityUomRelationValue,
          yarnRateCalculatedValue: item.yarnRateCalculatedValue,
          yarnRateIncludingBrokerage:limitDecimals(item.yarnRateIncludingBrokerage,3),
          yarnRateExcludingBrokerage:limitDecimals(item.yarnRateExcludingBrokerage,3),
          yarnRateUom: item.yarnRateUom,
          yarnRateUomId: item.yarnRateUomId,
          yarnRateUomRelationValue: item.yarnRateUomRelationValue,
          yarnUpdatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
          yarnUpdatedOn: TodayDate.split("T", 2)[0],
          yarnVariableRelationValue: item.yarnVariableRelationValue,
        });
      });
    }

    const JuteYarnUpdatedata = {
      deliveryOrderDTO: {
        amountWithoutTax:limitDecimals(JuteYarnDeliveryOrderHeader.netTotal,2),
        amountWithTax: limitDecimals(JuteYarnDeliveryOrderHeader.TotalAmount,2),
        approvedBy: JSON.parse(localStorage.getItem("authUser")).userId,
        approvedOn: TodayDate.split("T", 2)[0],
        billingTo: JuteYarnDeliveryOrderHeader.billingTo,
        branch: JuteYarnDeliveryOrderHeader.branch,
        brokerId: JuteYarnDeliveryOrderHeader.brokerId,
        bankId: JuteYarnDeliveryOrderHeader.bankId,
        bankBranch :JuteYarnDeliveryOrderHeader.bankBranch,
        bankName: JuteYarnDeliveryOrderHeader.bankName,
        ifscCode: JuteYarnDeliveryOrderHeader.ifscCode,
        accountNo: JuteYarnDeliveryOrderHeader.accountNo,
        micrCode: JuteYarnDeliveryOrderHeader.micrCode,
        challanDate: "",
        challanNo: "",
        company: JuteYarnDeliveryOrderHeader.company,
        createdBy: JuteYarnDeliveryOrderHeader.createdBy,
        createdDate: JuteYarnDeliveryOrderHeader.createdDate,
        customerId: JuteYarnDeliveryOrderHeader.customerId,
        deliveryDate: JuteYarnDeliveryOrderHeader.deliveryDate,
        deliveryDays: JuteYarnDeliveryOrderHeader.deliveryDays,
        deliveryTerms: JuteYarnDeliveryOrderHeader.deliveryTerms,
        finYear: JuteYarnDeliveryOrderHeader.finYear,
        footerNote: JuteYarnDeliveryOrderHeader.footerNote,
        freightCharges: JuteYarnDeliveryOrderHeader.freightCharges,
        internalNote: JuteYarnDeliveryOrderHeader.internalNote,
        invoiceType: JuteYarnDeliveryOrderHeader.invoiceType,
        paymentTerms: JuteYarnDeliveryOrderHeader.paymentTerms,
        regDeliveryOrderId: JuteYarnDeliveryOrderHeader.regDeliveryOrderId,
        salesOrderId: JuteYarnDeliveryOrderHeader.salesOrderId,
        shippingTo: JuteYarnDeliveryOrderHeader.shippingTo,
        status: status,
        taxType: JuteYarnDeliveryOrderHeader.taxType,
        termsCondition: JuteYarnDeliveryOrderHeader.termsCondition,
        termsConditionType: JuteYarnDeliveryOrderHeader.termsConditionType,
        transporter: JuteYarnDeliveryOrderHeader.transporter,
        brokerCommisionPercent:JuteYarnDeliveryOrderHeader.brokerCommisionPercent,
        vehicleNumber:JuteYarnDeliveryOrderHeader.vehicleNumber,
        updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
        updatedOn: TodayDate.split("T", 2)[0],
        deliveryOrderNo: JuteYarnDeliveryOrderHeader.deliveryOrderNo,
        deliveryOrderLineItemDTO: LineItemForJuteYarn,

      },
      yarnDeliveryOrderId: JuteYarnDeliveryOrderHeader.yarnDeliveryOrderId,
      destinationRailHead: "",
      invoiceTypeId: 3,
      loadingPoint: "",
      regularDeliveryOrderId:JuteYarnDeliveryOrderHeader.regDeliveryOrderId,
    };
    dispatch(
      UpdateJuteYarnDeliveryOrder(
        serverApi.UPDATE_JUTE_YARN_DELIVERY_ORDER,
        JuteYarnUpdatedata,
        history
      )
    );
  };
  const UpdatePayloadForJuteHessian = (status) => {
    var LineItemDTO = [];
    var FilteredData = DoJuteHessianLineItems.filter(
      (item) =>
        item.itemGroupId !== null &&
        item.itemGroupId !== ""
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemDTO.push({
          deliveryOrderLineItemId: item.deliveryOrderLineItemId,
          regDeliveryOrderId: item.regDeliveryOrderId,
          regularDeliveryOrderLineItemId: item.regularDeliveryOrderLineItemId,
          createdBy: item.createdBy,
          createdDate: item.createdDate,
          companyId: item.companyId,
          salesId: item.salesId,
          salesLineItemId: item.salesLineItemId,
          itemGroupId: item.itemGroupId,
          itemGroupValue: item.itemGroupValue,
          itemId: item.itemId,
          hsncode: item.hsncode,
          itemMake: item.itemMake,
          quantity:limitDecimals(item.quantity,3),
          uom: item.uom,
          rate:limitDecimals(item.rate,3),
          invoiceQty: item.invoiceQty,
          igstAmount: limitDecimals(item.igstAmount,2),
          igstPercent: limitDecimals(item.igstPercent,2),
          cgstAmount: limitDecimals(item.cgstAmount,2),
          cgstPercent: limitDecimals(item.cgstPercent,2),
          sgstAmount: limitDecimals(item.sgstAmount,2),
          sgstPercent: limitDecimals(item.sgstPercent,2),
          cessAmount: item.cessAmount,
          discountType: item.discountType,
          discount:limitDecimals(item.discount,2),
          discountedRate: limitDecimals(item.discountedRate,3),
          discountAmount:limitDecimals(item.discountAmount,2),
          netAmount:limitDecimals(item.netAmount,2),
          totalAmount:limitDecimals(item.totalAmount,2),
          remarks: item.remarks,
          balesPacketSlNo: item.balesPacketSlNo,
          status: item.status,
          isActive: item.isActive,
          hessianIsActive:item.hessianIsActive,
          hessianDeliveryOrderLineItemId: item.hessianDeliveryOrderLineItemId,
          hessianDeliveryOrderId: item.hessianDeliveryOrderId,
          hessianRateIncludingBrokerage: limitDecimals(item.hessianRateIncludingBrokerage,3),
          hessianBalesPacketSlNo: item.hessianBalesPacketSlNo,
          hessianQuantityUom: item.hessianQuantityUom,
          hessianQuantityUom2: item.hessianQuantityUom2,
          hessianQuantityUomId: item.hessianQuantityUomId,
          hessianQuantityRelationValue: item.hessianQuantityRelationValue,
          hessianQuantityCalculatedValue: item.hessianQuantityCalculatedValue,
          hessianRateUom: item.hessianRateUom,
          hessianRateUomId: item.hessianRateUomId,
          hessianRateRelationValue: item.hessianRateRelationValue,
          hessianRateCalculatedValue:item.hessianRateCalculatedValue,
          hessianAmountExcludingBrokerage:limitDecimals(item.hessianAmountExcludingBrokerage,3) ,
        });
      });
    }

    const data = {
      deliveryOrderDTO: {
        regDeliveryOrderId: DoJuteHessianHeaders.regDeliveryOrderId,
        finYear: localStorage.getItem("acadamicYear"),
        deliveryOrderNo: DoJuteHessianHeaders.deliveryOrderNo,
        createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
        createdDate: TodayDate.split("T", 2)[0],
        invoiceType: DoJuteHessianHeaders.invoiceType,
        deliveryDate: DoJuteHessianHeaders.deliveryDate,
        company: localStorage.getItem("companyId"),
        branch: DoJuteHessianHeaders.branch,
        salesOrderId: DoJuteHessianHeaders.salesOrderId,
        brokerId: DoJuteHessianHeaders.brokerId,
        bankId:DoJuteHessianHeaders.bankId,
        bankBranch :DoJuteHessianHeaders.bankBranch,
        bankName:DoJuteHessianHeaders.bankName,
        ifscCode:DoJuteHessianHeaders.ifscCode,
        accountNo:DoJuteHessianHeaders.accountNo,
        micrCode:DoJuteHessianHeaders.micrCode,
        customerId: DoJuteHessianHeaders.customerId,
        billingTo: DoJuteHessianHeaders.billingTo,
        shippingTo: DoJuteHessianHeaders.shippingTo,
        termsConditionType: DoJuteHessianHeaders.termsConditionType,
        transporter: DoJuteHessianHeaders.transporter,
        vehicleNumber: DoJuteHessianHeaders.vehicleNo,
        status: status,
        challanNo: DoJuteHessianHeaders.challanNo,
        challanDate: DoJuteHessianHeaders.challanDate,
        taxType: DoJuteHessianHeaders.taxType,
        footerNote: DoJuteHessianHeaders.footerNote,
        internalNote: DoJuteHessianHeaders.internalNote,
        termsCondition: DoJuteHessianHeaders.termsCondition,
        deliveryTerms: DoJuteHessianHeaders.deliveryTerms,
        paymentTerms: DoJuteHessianHeaders.paymentTerms,
        deliveryDays: DoJuteHessianHeaders.deliveryDays,
        freightCharges: DoJuteHessianHeaders.freightCharges,
        amountWithoutTax: DoJuteHessianHeaders.netTotal,
        amountWithTax: DoJuteHessianHeaders.TotalAmount,
        updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
        updatedOn: "",
        approvedBy: "",
        approvedOn: "",
        isActive: DoJuteHessianHeaders.isActive,
        deliveryOrderLineItemDTO: LineItemDTO,
        brokerCommisionPercent: DoJuteHessianHeaders.brokerCommisionPercent,
      },
      hessianDeliveryOrderId: DoJuteHessianHeaders.hessianDeliveryOrderId,
      invoiceTypeId: 4,
      loadingPoint: "",
      destinationRailHead: "BNG",
    };

    dispatch(
      UpdateJuteHessianDeliveryOrder(
        serverApi.UPDATE_DO_JUTE_HESSIAN,
        data,
        history
      )
    );
  };
  return (
    <>
      <div className="createQuotationContainer">
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            <div className="iconsBlock">
              <Stepper alternativeLabel activeStep={currentStep}>
                {steps.map((prop) => (
                  <Step key={prop.title}>
                    <StepLabel
                      StepIconComponent={(Iconprops) => {
                        return QontoStepIcon(
                          Iconprops,
                          prop.icon,
                          prop.activeIcon,
                          prop.title
                        );
                      }}
                    ></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
          </Grid>
        </Grid>
      </div>
      {currentStep === 1 && (
        <div>
          <div className="consoleFormButtonBlock">
            <h4>What is the type of Invoice?</h4>
          </div>
          <div className="consoleFormButtonBlock">
            <DynamicSelect
              arrayOfData={invoiceData}
              className="dropdownBlockContainer"
              menuPosition="fixed"
              menuPlacement="auto"
              onSelectChange={handleChange}
              isDropdownOpen={isDropdownOpen}
              handleMenuOpen={handleMenuOpen}
              selected={invoiceType}
              name="invoiceType"
            />
          </div>
          <div className="consoleFormButtonBlock">
            <Link>
              <CustomButton
                label={"Back"}
                className="greenBorderButton"
                handleClick={onClickBefore}
              />
            </Link>
            <CustomButton
              label={"Next"}
              className={invoiceType === "" ? "disabled" : "greenButton"}
              handleClick={onClickNext}
              type="sumbit"
              disabled={invoiceType === "" ? true : false}
            />
          </div>
        </div>
      )}
      {currentStep === 2 && (
        <>
          {invoiceType === 1 ? (
            <RegularFillDetails
              invoiceType={invoiceType}
              invoiceData={invoiceData}
              locationState={locationState}
            />
          ) : invoiceType === 2 ? (
            <>
              <JuteGovtFillDetails
                invoiceData={invoiceData}
                invoiceType={invoiceType}
                locationState={locationState}
              />
            </>
          ) : invoiceType == 3 ? (
            <JuteYarnFillDetails
              invoiceType={invoiceType}
              invoiceData={invoiceData}
              locationState={locationState}
            />
          ) : invoiceType == 4 ? (
            <JuteHessianFillDetails
              invoiceType={invoiceType}
              invoiceData={invoiceData}
              locationState={locationState}
            />
          ) : (
            ""
          )}
          <div className="consoleFormButtonBlock">
            <Link>
              <CustomButton
                label={"Back"}
                className="greenBorderButton"
                handleClick={onClickBefore}
              />
            </Link>
            <CustomButton
              label={"Next"}
              className="greenButton"
              handleClick={onClickNext}
              type="sumbit"
            />
          </div>
        </>
      )}
      {currentStep === 3 && (
        <>
          {invoiceType === 1 ? (
            <>
              <RegularLineItems locationState={locationState}/>
            </>
          ) : invoiceType === 2 ? (
            <>
              <JuteGovtLineItems locationState={locationState}/>
            </>
          ) : invoiceType == 3 ? (
            <>
              <JuteYarnLineItems locationState={locationState}/>
            </>
          ) : invoiceType == 4 ? (
            <JuteHessianLineItems locationState={locationState}/>
          ) : (
            ""
          )}
          <div className="consoleFormButtonBlock">
            <Link>
              <CustomButton
                label={"Back"}
                className="greenBorderButton"
                handleClick={onClickBefore}
              />
            </Link>
            <CustomButton
              label={"Next"}
              className="greenButton"
              handleClick={onClickNext}
              type="sumbit"
            />
          </div>
        </>
      )}
      {currentStep === 4 && (
        <>
          {invoiceType === 1 ? (
            <>
              <RegularTransportation locationState={locationState}/>
            </>
          ) : invoiceType === 2 ? (
            <>
              <JuteGovtTransportation locationState={locationState}/>
            </>
          ) : invoiceType === 3 ? (
            <>
              <JuteYarnTransportation locationState={locationState}/>
            </>
          ) : invoiceType == 4 ? (
            <JuteHessianTransportation locationState={locationState}/>
          ) : (
            ""
          )}
          <div className="consoleFormButtonBlock">
            <Link>
              <CustomButton
                label={"Back"}
                className="greenBorderButton"
                handleClick={onClickBefore}
              />
            </Link>
            <CustomButton
              label={"Next"}
              className="greenButton"
              handleClick={onClickNext}
              type="sumbit"
            />
          </div>
        </>
      )}
      {currentStep === 5 && (
        <>
          {invoiceType === 1 ? (
            <>
              <RegularPreview handleCurrentStep={handleCurrentStep} locationState={locationState}/>
            </>
          ) : invoiceType === 2 ? (
            <>
              <JuteGovtPreview handleCurrentStep={handleCurrentStep} locationState={locationState}/>
            </>
          ) : invoiceType === 3 ? (
            <>
              <JuteYarnPreview handleCurrentStep={handleCurrentStep} locationState={locationState}/>
            </>
          ) : invoiceType === 4 ? (
            <JuteHessianPreview handleCurrentStep={handleCurrentStep} locationState={locationState}/>
          ) : (
            ""
          )}
          <div className="consoleFormButtonBlock">
            {locationState.state === undefined ? (
              <Link>
                <CustomButton
                  label={"Back"}
                  className="greenBorderButton"
                  handleClick={onClickBefore}
                />
              </Link>
            ) : (
              ""
            )}
            {locationState.state === undefined ? (
              <CustomButton
                label={"Create"}
                className="greenButton"
                handleClick={onClickCreate}
                type="sumbit"
              />
            ) : (
              <>
                {RegularDeliveryOrderHeader.UpdateButton === true &&
                JuteYarnDeliveryOrderHeader.UpdateButton === true &&
                DoJuteHessianHeaders.UpdateButton === true &&
                JuteGovtDeliveryOrderHeader.UpdateButton === true ? (
                  <CustomButton
                    label={"Update"}
                    className="greenButton"
                    handleClick={() => OnUpdate("")}
                    type="sumbit"
                  />
                ) : (
                  ""
                )}
              </>
            )}

            {locationState.state !== undefined &&
            RegularDeliveryOrderHeader.ApproveButton === true ? (
              <>
                <CustomButton
                  label={"Approve"}
                  className="approved"
                  handleClick={() =>
                    onClickApprove(RegularDeliveryOrderHeader.status)
                  }
                  type="sumbit"
                />
              </>
            ) : (
              ""
            )}
            {locationState.state !== undefined &&
            RegularDeliveryOrderHeader.RejectButton === true ? (
              <>
                <CustomButton
                  label={"Reject"}
                  className="rejected"
                  handleClick={() => onClickReject("4")}
                  type="sumbit"
                />
              </>
            ) : (
              ""
            )}
            {locationState.state !== undefined &&
            DoJuteHessianHeaders.ApproveButton === true ? (
              <>
                <CustomButton
                  label={"Approve"}
                  className="approved"
                  handleClick={() => onClickApprove(DoJuteHessianHeaders.status)}
                  type="sumbit"
                />
              </>
            ) : (
              ""
            )}
            {locationState.state !== undefined &&
            DoJuteHessianHeaders.RejectButton === true ? (
              <>
                <CustomButton
                  label={"Reject"}
                  className="rejected"
                  handleClick={() => onClickReject("4")}
                  type="sumbit"
                />
              </>
            ) : (
              ""
            )}
            {locationState.state !== undefined &&
            JuteYarnDeliveryOrderHeader.ApproveButton === true ? (
              <>
                <CustomButton
                  label={"Approve"}
                  className="approved"
                  handleClick={() =>
                    onClickApprove(JuteYarnDeliveryOrderHeader.status)
                  }
                  type="sumbit"
                />
              </>
            ) : (
              ""
            )}
            {locationState.state !== undefined &&
            JuteYarnDeliveryOrderHeader.RejectButton === true ? (
              <>
                <CustomButton
                  label={"Reject"}
                  className="rejected"
                  handleClick={() => onClickReject("4")}
                  type="sumbit"
                />
              </>
            ) : (
              ""
            )}
            {locationState.state !== undefined &&
            JuteGovtDeliveryOrderHeader.ApproveButton === true ? (
              <>
                <CustomButton
                  label={"Approve"}
                  className="approved"
                  handleClick={() =>
                    onClickApprove(JuteGovtDeliveryOrderHeader.status)
                  }
                  type="sumbit"
                />
              </>
            ) : (
              ""
            )}
            {locationState.state !== undefined &&
            JuteGovtDeliveryOrderHeader.RejectButton === true ? (
              <>
                <CustomButton
                  label={"Reject"}
                  className="rejected"
                  handleClick={() => onClickReject("4")}
                  type="sumbit"
                />
              </>
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </>
  );
};
export default CreateDeliveryOrder;
