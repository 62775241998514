import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/PaginationGridView";
import { getCostCenterList } from "../../../store/Master/CostCenterMaster/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      costCenterListData: [], // To Displaying cost center list data
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      costDesc: "",
      costCode: "",
      deptDesc: "",
      dataexp: "",
    };
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
    };

    this.props.getCostCenterList(
      serverApi.GET_ALL_COST_CENTER_LIST,
      data,
      this.props.history
    ); // calling cost center list api
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.CostCenter_List) {
      this.setState({
        totalRecs: props.CostCenter_List.totalRecords,
        costCenterListData: props.CostCenter_List.data, // updating cost center list data
      });
    }
  }
  handleSearch = (data) => {
    const searchdata = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      costDesc: data.costDesc,
      costCode: data.costCode,
      deptDesc: data.deptDesc,
      startIndex: 1,
      lastIndex: 10,
    };
    this.setState({
      costDesc: data.costDesc,
      costCode: data.costCode,
      deptDesc: data.deptDesc,
    });
    this.props.getCostCenterList(
      serverApi.GET_ALL_COST_CENTER_LIST,
      searchdata,
      this.props.history
    ); // calling cost center list api
  };
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const data = {
        cipher: this.state.userDit.cipher,
        companyId: localStorage.getItem("companyId"),
        costDesc: this.state.costDesc,
        costCode: this.state.costCode,
        deptDesc: this.state.deptDesc,
        startIndex: sizePerPage,
        lastIndex: currentIndex,
      };
      if (currentIndex >= 0) {
        this.props.getCostCenterList(
          serverApi.GET_ALL_COST_CENTER_LIST,
          data,
          this.props.history
        ); // calling pagination api for department master
      }
    } else {
      const data = {
        companyId: localStorage.getItem("companyId"),
        cipher: this.state.userDit.cipher,
        costDesc: this.state.costDesc,
        costCode: this.state.costCode,
        deptDesc: this.state.deptDesc,
        startIndex: currentIndex == 0 ? currentIndex + 1 : currentIndex,
        lastIndex: sizePerPage,
      };
      if (currentIndex >= 0) {
        this.props.getCostCenterList(
          serverApi.GET_ALL_COST_CENTER_LIST,
          data,
          this.props.history
        ); // calling Pagination api for department master
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.costCenterListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={false}
          actions={true}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          url="/create_Cost_Center_Master"
          filter_form={"Cost_Center_Master"}
          handleSearch={this.handleSearch}
          apiexp={serverApi.GET_ALL_COST_CENTER_LIST}
          payloadexp={this.state.dataexp}
          expName={"Cost-Center-Master.csv"}
        />
      </>
    );
  }
}
// cost center list headers
const Columns = [
  {
    Header: "Cost Code",
    accessor: "costCode",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Cost Center Name",
    accessor: "costDesc",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  // As per the new requirement we are hiding these columns
  // {
  //   Header: "Department",
  //   accessor: "deptDesc",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: false,
  // },
  // {
  //   Header: "Parent",
  //   accessor: "parentId",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: false,
  // },
];

const mapStatetoProps = (state) => {
  const { CostCenter_List } = state.CostCenterListReducer; // fetching list from cost center list reducer
  return { CostCenter_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getCostCenterList,
  })(Index)
);
