import {
  BEAMING_CREATE,
  BEAMING_DD_LIST,
  BEAMING_DELETE_ROW,
  BEAMING_LIST,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getListurl, getList } from "../../../helpers/Server_Helper";
import {
  beamingListSuccessfull,
  beamingDdListSuccessfull,
  beamingCreateSuccessfull,
  beamingDeleteRowSuccessfull,
  beamingList
} from "./actions";
import swal from "sweetalert";
import { serverApi} from "../../../helpers/Consts";

function* beamingProductionListSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(beamingListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* beamingDdListSaga({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(beamingDdListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* beamingCreateSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(beamingCreateSuccessfull({ response }));
    swal("Created Successfully", { icon: "success" });
    history.push("/pr_beaming_production");
  } catch (error) {
    console.log(error);
  }
}
function* beamingDelSaga({ payload: { url,history, fromDate, toDate } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(beamingDeleteRowSuccessfull({ response }));
    swal(response.data.message, {icon:"success"})

    if(response) {
      const data = {
        companyId: localStorage.getItem("companyId"),
        fromDate: fromDate,
        toDate: toDate,
      };
      yield put(beamingList(
        serverApi.GET_BEAMING_PRODUCTION,
        data,
        history
      ));
    }
    // if (response) {
     
      // swal(response.message, { icon: "success" });
    // }
  } catch (error) {
    console.log(error);
  }
}
export function* watchBeamingProdList() {
  yield takeEvery(BEAMING_LIST, beamingProductionListSaga);
  yield takeEvery(BEAMING_DD_LIST, beamingDdListSaga);
  yield takeEvery(BEAMING_CREATE, beamingCreateSaga);
  yield takeEvery(BEAMING_DELETE_ROW, beamingDelSaga);
}

function* BeamingProd() {
  yield all([fork(watchBeamingProdList)]);
}

export default BeamingProd;
