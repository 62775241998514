import { Tooltip } from "@material-ui/core";
import React from "react";
import DescriptionSVG from '../../../assets/images/description.svg'

export default class EditableCell extends React.Component {
  render() {
    return (
      <td style={{ padding: "0px 10px" }} className="tableDisplayBlock">
        <div className="EditableCellBlock">
        <input
          type="text"
          name={this.props.cellData.type}
          id={this.props.cellData.id}
          value={this.props.cellData.value}
          onChange={this.props.onhandlechangeValue}
          onKeyDown={this.props.onKeyDown}
          className="inputBlockContainer"
          onClick={this.props.tooltip}>
          </input>

        {this.props.showToolTip ? (
          <Tooltip title={this.props.toolTipMsg} className="toolTip">
            {/* <DescriptionSharpIcon/> */}
            <img src={DescriptionSVG} alt='tooltipIcon' width='18px'/>
          </Tooltip>
        ) : (
          ""
        )}
        </div>
      </td>
    );
  }
}
