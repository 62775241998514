import {CREATE_RE_OCCURING_INVOICE, CREATE_RE_OCCURING_INVOICE_SUCCESSFULL, RE_OCCURING_INVOICE_LIST,RE_OCCURING_INVOICE_LIST_SUCCESSFULL} from './actionType'

export const getReOccuringInvoiceList = (url,data,history) =>{
    return {
        type:RE_OCCURING_INVOICE_LIST,
        payload:{url,data,history}
    }
}

export const getReOccuringInvoiceListSuccessfull = (data)=>{
    return {
        type:RE_OCCURING_INVOICE_LIST_SUCCESSFULL,
        payload:data
    }
}
export const setReOccuringInvoice = (url,data,history) =>{
    return {
        type:CREATE_RE_OCCURING_INVOICE,
        payload:{url,data,history}
    }
}

export const setReOccuringInvoiceSuccessfull = (data)=>{
    return {
        type:CREATE_RE_OCCURING_INVOICE_SUCCESSFULL,
        payload:data
    }
}