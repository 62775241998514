import {BILLPASS_LIST,BILLPASS_LIST_SUCCESSFULL,API_ERRORS} from './actionTypes';



export const getBillPassList = (url,data,history) =>{
    return {
        type:BILLPASS_LIST,
        payload:{url,data,history}
    }
}

export const BillPassListSuccessfull = (data)=>{
    return {
        type:BILLPASS_LIST_SUCCESSFULL,
        payload:data
    }
}

export const apiErrors = (error) => {
    return {
      type: API_ERRORS,
      payload: error,
    };
  };

