import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { CONSUMPTION_LIST, ADD_CONSUMPTION } from "./actionTypes";
import {
  ConsumptionListSuccessfull,
  addConsumptionSuccessfull,
} from "./actions";
import { getList } from "../../../helpers/Server_Helper";
import swal from "sweetalert";

function* getConsumptionList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(ConsumptionListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* addConsumption({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(addConsumptionSuccessfull({ response }));
    swal(response.message, { icon: "success" });
    history.push("/store_issue");
  } catch (error) {
    console.log(error);
  }
}

export function* watchConsumptionList() {
  yield takeEvery(CONSUMPTION_LIST, getConsumptionList);
}
export function* watchAddConsumption() {
  yield takeEvery(ADD_CONSUMPTION, addConsumption);
}

function* ConsumptionSaga() {
  yield all([fork(watchConsumptionList), fork(watchAddConsumption)]);
}

export default ConsumptionSaga;
