import {ADD_LEAVE_TRANSACTION, ADD_LEAVE_TRANSACTION_SUCCESSFULL, LEAVE_LEDGERLIST, LEAVE_LEDGERLIST_SUCCESSFULL, 
    LEAVE_REQUEST_LIST,LEAVE_REQUEST_LIST_SUCCESSFULL, UPDATE_LEAVE_REQUEST, UPDATE_LEAVE_REQUEST_SUCCESSFULL,
LEAVE_REQUEST_VIEW_BY_ID, LEAVE_REQUEST_VIEW_BY_ID_SUCCESSFULL, LEAVE_LEDGERLIST_FOR_EMPLOYEE, LEAVE_LEDGERLIST_FOR_EMPLOYEE_SUCCESSFULL, VIEW_BY_ID_FOR_LEAVEREQUEST, VIEW_BY_ID_FOR_LEAVEREQUEST_SUCCESSFULL, LEAVE_TYPE_SELECTION,
 LEAVE_TYPE_SELECTION_SUCCESSFULL,
 WORKER_DATA_BY_EB_NO_SUCCESSFULL,
 WORKER_DATA_BY_EB_NO, WORKER_DATA_CLEAR} from './actionType'

export const getLeaveRequestList = (url,data,history) =>{
    return {
        type:LEAVE_REQUEST_LIST,
        payload:{url,data,history}
    }
}

export const leaveRequestListSuccessfull = (data)=>{
    return {
        type:LEAVE_REQUEST_LIST_SUCCESSFULL,
        payload:data
    }
}
 
export const getLeaveLedgerList = (url,history) =>{
    return {
        type:LEAVE_LEDGERLIST,
        payload:{url,history}
    }
}

export const LeaveLedgerSuccessfullList = (data)=>{
    return {
        type:LEAVE_LEDGERLIST_SUCCESSFULL,
        payload:data
    }
}
export const addLeaveTransaction = (url,data,history) =>{
    return {
        type:ADD_LEAVE_TRANSACTION,
        payload:{url,data,history}
    }
}

export const addLeaveTransactionSuccessfull = (data)=>{
    return {
        type:ADD_LEAVE_TRANSACTION_SUCCESSFULL,
        payload:data
    }
}
export const updateLeave= (url,data,history) =>{
    return {
        type:UPDATE_LEAVE_REQUEST,
        payload:{url,data,history}
    }
}

export const updateLeaveSuccessfull = (data)=>{
    return {
        type:UPDATE_LEAVE_REQUEST_SUCCESSFULL,
        payload:data
    }
}

export const getLeaveRequestViewByIdList = (url,history) =>{
    return {
        type:LEAVE_REQUEST_VIEW_BY_ID,
        payload:{url,history}
    }
}

export const LeaveViewByIdListSuccessfull = (data)=>{
    return {
        type:LEAVE_REQUEST_VIEW_BY_ID_SUCCESSFULL,
        payload:data
    }
}

export const getLeaveLedgerForEmployee = (url,history) =>{
    return {
        type:LEAVE_LEDGERLIST_FOR_EMPLOYEE,
        payload:{url,history}
    }
}

export const getLeaveLedgerForEmployeeSuccessfull = (data)=>{
    return {
        type:LEAVE_LEDGERLIST_FOR_EMPLOYEE_SUCCESSFULL,
        payload:data
    }
}

export const getViewByIdForLeaveRequest = (url,history) =>{
    return {
        type:VIEW_BY_ID_FOR_LEAVEREQUEST,
        payload:{url,history}
    }
}

export const getViewByIdForLeaveRequestSuccessfull = (data)=>{
    return {
        type:VIEW_BY_ID_FOR_LEAVEREQUEST_SUCCESSFULL,
        payload:data
    }
}


export const leaveTypeSelection = (url,history) =>{
    return {
        type:LEAVE_TYPE_SELECTION,
        payload:{url,history}
    }
}

export const leaveTypeSelectionSuccessfull = (data)=>{
    return {
        type:LEAVE_TYPE_SELECTION_SUCCESSFULL,
        payload:data
    }
}


export const getWorkerDataByEbNo = (url,history) =>{
    return {
        type:WORKER_DATA_BY_EB_NO,
        payload:{url,history}
    }
}
export const getWorkerDataByEbNoSuccessfull =(data)=>{
    return {
        type:WORKER_DATA_BY_EB_NO_SUCCESSFULL,
        payload:data
    }
}

export const getWorkerDataClear =(data)=>{
    return {
        type:WORKER_DATA_CLEAR,
        payload:data
    }
}