import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import { Tooltip } from "reactstrap";
import { IconButton, Tooltip } from "@mui/material";
import { deleteUomRelation } from "../../store/Master/UomMaster/actions";

// import { IconButton } from "@mui/material";
import Fade from "@mui/material/Fade";
import ClearIcon from "@mui/icons-material/Clear";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { serverApi } from "../../helpers/Consts";

class UomRelationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
     mdata:[]
    };

  }
  onDel = (data) => (event) => {
    const {key} = data
    this.setState({[key] : event})

    this.props.mdata.filter((item) => item.id === key ? this.props.deleteUomRelation(serverApi.DELETE_UOM_DATA + item.id, item.relationUom, item.itemId,this.props.history): "")
  }

  render() {
    return (
      <>
        <div className="gridContainer">
          <div className="gridComponentNoBorder">
            <table className="table">
              <thead>
                <tr>
                  {this.props.mcolumns.map((item, i) => (
                    <th scope="col" key={i}>
                      {item.label}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                <>
                  {
                   this.props.mdata ?  this.props.mdata.map((prop) => {
                      return (
                        <>
                          <tr>
                          <td style={{ textAlign: "center" }}>{prop.itemDesc}</td>
                            <td style={{ textAlign: "center" }}>{prop.relation}</td>
                            <td style={{ textAlign: "center" }}>{prop.relationValue}</td>
                            {/* <td style={{ textAlign: "center"}}> */}
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title="Remove"
                              >
                                <IconButton
                                  onClick={this.onDel({ key: prop.id, prop })}
                                  className="closeButtonForInput" 
                                >
                                  <ClearIcon />
                                  {/* <HighlightOffIcon /> */}
                                </IconButton>
                              </Tooltip>
                            </div>
                            {/* </td> */}
                          </tr>

                        </>
                      )
                    }) : ""
                  }
                </>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {};

export default withRouter(connect(mapStatetoProps, {
  deleteUomRelation
})(UomRelationTable));
