//************************************************************************** */
//date of creation : 01-08-2023 :: 12:00 AM Author : Nagesh Medisetty //
//************************************************************************** */
import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import { departmentMasterList } from "../../../store/Master/DepartmentMaster/actions";

function Index(props) {
  const [userDit, setUserDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const companyId = localStorage.getItem("companyId");
  const [totalRecs, setTotalRecs] = useState(0);
  const [startIndex, setStartIndex] = useState(1);
  const [lastIndex, setLastIndex] = useState(10);
  const [departmentName, setDepartmentName] = useState(null);
  const [departmentMasterListData, setDepartmentMasterListData] = useState([]); // To display department list
  const [mDeptCode, setMDeptCode] = useState(null);
  const [dataexp, setDataExp] = useState("");


  const handleCallApi = (type,currentIndex,sizePerPage,filterdata=null) => {    
      const data = {
        companyId: localStorage.getItem("companyId"),
        cipher: userDit.cipher,
        startIndex: type === 1 ? sizePerPage : currentIndex === 0 ? currentIndex + 1 : currentIndex,
        lastIndex: type === 1 ? currentIndex : sizePerPage,
        departmentName: filterdata ? filterdata.department : departmentName,
        mDeptCode: filterdata ? filterdata.mDeptCode : mDeptCode,
      };
      const expdata = {
        ...data,
        startIndex: 0,
        lastIndex: 0,
      };
      setDataExp(expdata);
      if (currentIndex >= 0) {
        props.departmentMasterList(serverApi.GET_ALL_DEPARTMENT_MASTER_LIST, data, props.history); // calling pagination api for department master
      }    
  }

  const handlePagination = useCallback(
    (page, sizePerPage, type) => {
      const currentIndex = (page - 1) * sizePerPage;
      handleCallApi(type,currentIndex,sizePerPage)
      setStartIndex(currentIndex);
      setLastIndex(sizePerPage);
    },
    [departmentName, mDeptCode, props]
  );

  const handleSearch = (data) => {
    setDepartmentName(data.department);
    setMDeptCode(data.mDeptCode);
    handleCallApi(1,10,1, data);
  };

  useEffect(() => {
    // API For Department Master list
    handleCallApi(1,lastIndex, startIndex);
    
  }, []);

  useEffect(() => {
    if (props.DepartmentMasterList) {
      setTotalRecs(props.DepartmentMasterList.totalRecords);
      setDepartmentMasterListData(props.DepartmentMasterList.data); // State Updating to departmentMasterListData
    }
  }, [props.DepartmentMasterList]);

  return (
    <PaginationGridView
      mdata={departmentMasterListData}
      mcolumns={Columns}
      micon={GridArrowIcon}
      status={true}
      actions={true}
      filter_form={"DepartmentMaster"}
      totalRecs={totalRecs}
      page={startIndex}
      sizePerPage={lastIndex}
      handlePagination={handlePagination}
      handleSearch={handleSearch}
      url="/create_department_master"
      apiexp={serverApi.GET_ALL_DEPARTMENT_MASTER_LIST}
      payloadexp={dataexp}
      expName={"Department-Master.csv"}
    />
  );
}

//department list headers
const Columns = [
  {
    Header: "Department Name",
    accessor: "departmentName",
    hidden: false,
  },
  {
    Header: "Department Code",
    accessor: "mDeptCode",
    hidden: true,
  },
  {
    Header: "Modified By",
    accessor: "modby",
    hidden: true,
  },
  {
    Header: "User Id",
    accessor: "userId",
    hidden: true,
  },
  {
    Header: "Order Id",
    accessor: "orderId",
    hidden: true,
  },
  {
    Header: "Company Id",
    accessor: "companyId",
    hidden: true,
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    hidden: true,
  },
  {
    Header: "Created Date",
    accessor: "createdDate",
    hidden: true,
  },
  {
    Header: "Modified Description ",
    accessor: "modByDesc",
    hidden: true,
  },
  {
    Header: "Created Date Description",
    accessor: "createdDateDesc",
    hidden: true,
  },
];

const mapStatetoProps = (state) => {
  const { DepartmentMasterList } = state.DepartmentReducer; // Fetching List From Department Reducers
  return { DepartmentMasterList };
};

export default withRouter(connect(mapStatetoProps, { departmentMasterList })(Index));
