import React, { Component } from "react";
import { Grid, Step, Stepper, StepLabel } from "@mui/material";
import Link from "@mui/material/Link";
import { serverApi } from "../../../helpers/Consts";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import FillDetailsSupplier from "./fillDetailsSupplier";
import SupplierLineItems from "./SupplierLineItems";
import {
  supplierMasterViewById,
  updateSupplierMaster,
} from "../../../store/Master/SupplierMaster/actions";
import {
  createSupplierMaster,
  SupplierFilldetailsProps,
} from "../../../store/Master/SupplierMaster/actions";
import swal from "sweetalert";
import { SupplierLineItemsProps } from "../../../store/Master/SupplierMaster/actions";

class CreateSupplierMaster extends Component {
  constructor() {
    super();
    this.state = {
      steps: [
        {
          title: "Fill Details",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Add Branch",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
      ],
      currentStep: 1,
      date: null,
      supplierTypeList: [],
      addListItem: [],
      CreateSupplierMaster: [],
      SupplierViewById: [],
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      UpdateButton: true,
      locationState: "",
      SupplierfillDetails: {
        activeFlag: "",
        address2: "",
        autoCreate: false,
        cellPhone: "",
        cin: "",
        cipher: "",
        comments: "",
        companyId: localStorage.getItem("companyId"),
        contactNo: "",
        contactPerson: "",
        designation: "",
        email: "",
        gstNo: "",
        ledgerGroup: "",
        msmeCertified: "",
        mukam: [],
        panNo: "",
        state: "",
        status: 1,
        suppName: "",
        suppTyp: "",
        tanNo: "",
        totalOpeningBalance: "",
        userId: "",
        vatno: "",
        selectedMukamList: [],
        activeYes: true,
        activeNo: false,
        msmeCertifiedYes: true,
        msmeCertifiedNo: false,
        PanValidation: false,
        PanMandatory: false,
        TanValidation: false,
        AccountNoValidation: false,
        IFScValidation: false,
        EmailFieldValidation: false,
        EmailMandatory: false,
        suppId: "",
        suppCode: "",
        createdBy: "",
        createdOn: "",
        vendorLoginYes: false,
        vendorLoginNo: true,
        ApproveButton: false,
        entityTypeId: "",
        accountNumber: "",
        ifscCode: "",

      selectedTanFile: "",
      selectedPanFile: "",
      selectedCinFile: "",
      selectedBankFile: "",

      panfileId: "",
      tanfileId: "",
      chequqfileId: "",
      msmefileId: "",
      cinfileId: "",
      bankfileId: "",

      tanFileExtension: "",
      tanRetrivePath: "",
      panFileExtension: "",
      panRetrivePath: "",
      cinFileExtension: "",
      cinRetrivePath: "",
      msmeFileExtension: "",
      msmeRetrivePath: "",
      chequqFileExtension: "",
      chequqRetrivePath: "",
      bankFileExtension:"",
      bankRetrivePath: "",

      },
      Supplier_LineItems: [
        {
          id: 1,
          accountNumber: "",
          autoId: "",
          branchAddress: "",
          branchId: "",
          branchName: "",
          city: "",
          contactNo: "",
          contactPerson: "",
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          email: "",
          gstNo: "",
          ifscCode: "",
          isActive: 1,
          openningBalance: "",
          rcCopy: "",
          stateCode: "",
          stateName: "",
          emailValidation: false,
          CityValidation: false,
          contactPersonValidation: false,
          isGstVerified: 2,
          ledgerGroupId: null,
          ledgerGroupDesc: null,
          ledgerName: "",
        },
      ],
    };
  }
  componentDidMount() {
     this.setState({
      locationState: this.props.location,
    });
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData !== undefined) {
        if (this.props.location.state.rowData.supLineItems) {
          if (this.props.location.state.rowData.supLineItems.length !== 0) {
            let obj = {};
            var LineItemData =
              this.props.location.state.rowData.supLineItems.map((data, i) => {
                return {
                  id: i,
                  autoId: data.autoId,
                  branchAddress: data.branchAddress,
                  city: data.city,
                  contactNo: data.contactNo,
                  contactPerson: data.contactPerson,
                  createdBy: data.createdBy,
                  gstNo: data.gstNo,
                  isActive: data.isActive,
                  rcCopy: data.rcCopy,
                  stateCode: data.stateCode,
                  stateName: data.stateName,
                  email: data.email,
                  branchId: data.branchId,
                  branchName: data.branchName,
                  accountNumber: data.accountNumber,
                  openningBalance: data.openningBalance,
                  ifscCode: data.ifscCode,
                  emailValidation: false,
                  isGstVerified: data.isGstVerified,
                  ledgerGroupId: data.ledgerGroupId,
                  ledgerGroupDesc: data.ledgerGroupDesc,
                  ledgerName: data.ledgerName,
                };
              });
            LineItemData.push({
              id: LineItemData.length + 1,
              accountNumber: "",
              autoId: "",
              branchAddress: "",
              branchId: "",
              branchName: "",
              city: "",
              contactNo: "",
              contactPerson: "",
              createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
              email: "",
              gstNo: "",
              ifscCode: "",
              isActive: 1,
              openningBalance: "",
              rcCopy: "",
              stateCode: "",
              stateName: "",
              emailValidation: false,
              isGstVerified: 2,
              ledgerGroupId: null,
              ledgerGroupDesc: null,
              ledgerName: "",
            });
            this.setState({
              Supplier_LineItems: LineItemData,
            });
          }
        }
      }
    }
  }

  ///function for stepper
  QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;
    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };

  // function for onClick back
  onClickBack = () => {
    const { currentStep } = this.state;
    if (currentStep > 1) {
      this.setState({
        currentStep: currentStep - 1,
      });
    } else {
      this.props.history.push("/supplier_master");
    }
  };
  onClickCancel = () => {
    this.props.history.push("/supplier_master");
  };
  // function for Create API
  onCreate = () => {
    var filterProducts = this.state.Supplier_LineItems.filter((prop) => {
      if (prop.stateName !== "" && prop.isActive !== 0) {
        return prop;
      }
    });
    var filterProductdata = this.state.Supplier_LineItems.filter((prop) => {
      if ( prop.isActive !== 0) {
        return prop;
      }
    });
    let lastRow =
      this.state.Supplier_LineItems[this.state.Supplier_LineItems.length - 1];

    var Validate = false;
    if (filterProductdata.length === 1) {
      filterProductdata.map((prop) => {
        if (prop.emailValidation == true) {
          swal("Please Enter the valid Email ");
          return false;
        }
        if (prop.CityValidation == true) {
          swal("Please Enter the valid City ");
          return false;
        }
        if (prop.contactPersonValidation == true) {
          swal("Contact Person Field Shoudn't Contain Numbers");
          return false;
        }else if (prop.stateCode == "" || prop.stateCode == undefined) {
          swal("Please Select State");
          return false;
        } else if (prop.branchName =="") {
          swal("Please enter the Branch Name");
        }  else if (prop.branchAddress == "") {
          swal("Please Select Branch");
          return false;
        } else if (prop.contactNo == "") {
          swal("Please Enter Contact Number");
          return false;
        } else if (prop.email == "") {
          swal("Please Enter Email");
          return false;
        } else if (prop.openningBalance === "") {
          swal("Please Enter Openning Balance");
          return false;
        } else {
          Validate = true;
        }
      });
    } else {
      for (var i = 0; i <= filterProducts.length - 1; i++) {
        // if (this.state.Supplier_LineItems[i] !== lastRow) {
        if (filterProducts[i].emailValidation == true) {
          swal("Please Enter the valid Email ");
          return false;
        }
        if (filterProducts[i].CityValidation == true) {
          swal("Please Enter the valid City ");
          return false;
        }
        if (filterProducts[i].contactPersonValidation == true) {
          swal("Conatact Person Field shouldn't contain Numbers ");
          return false;
        }else if (
          filterProducts[i].stateCode == "" ||
          filterProducts[i].stateCode == undefined
        ) {
          swal("Please Select the State ");
          return false;
        } else if (
          filterProducts[i].branchName == "" 
        ) {
          swal("Please Enter the Branch Name");
          return false;
        }  else if (filterProducts[i].branchAddress == "") {
          swal("Please Select the Branch");
          return false;
        } else if (filterProducts[i].contactNo == "") {
          swal("Please Enter the contact Number ");
          return false;
        } else if (filterProducts[i].email == "") {
          swal("Please enter the Email ");
          return false;
        } else if (filterProducts[i].openningBalance === "") {
          swal("Please Enter the Opening Balance ");
          return false;
        } else {
          Validate = true;
        }
        // }
      }
    }

    if (Validate == true) {
      var filterProducts = this.state.Supplier_LineItems.filter((prop) => {
        if (prop.isActive == 1 && prop.stateName !== "" && prop.id !== "")
          {
          return prop;
        }
      });
      let MukhamData = [];
      if (this.state.SupplierfillDetails.mukam) {
        if (this.state.SupplierfillDetails.mukam.length !== 0) {
          this.state.SupplierfillDetails.mukam.map((item) => {
            MukhamData.push({
              id: item.value,
              mukamName: item.name,
              isMapped: true,
            });
          });
        }
      }

      const data = {
        activeFlag: this.state.SupplierfillDetails.activeFlag == 2 ? "N" : "Y",
        address2: "",
        autoCreate: false,
        cellPhone: "",
        cin: this.state.SupplierfillDetails.cin,
        cipher: this.state.userDit.cipher,
        comments: this.state.SupplierfillDetails.comments,
        companyId: localStorage.getItem("companyId"),
        contactNo: this.state.SupplierfillDetails.contactNo,
        contactPerson: this.state.SupplierfillDetails.contactPerson,
        createdBy: this.state.userDit.userId,
        designation: "",
        email: this.state.SupplierfillDetails.email,
        gstNo: "",
        ledgerGroup: this.state.SupplierfillDetails.ledgerGroup,
        msmeCertified:
          this.state.SupplierfillDetails.msmeCertifiedYes !== true
            ? "NO"
            : "YES",
        mukam: MukhamData,
        panNo: this.state.SupplierfillDetails.panNo,
        state: "",
        status: 1,
        suppName: this.state.SupplierfillDetails.suppName,
        suppTyp: this.state.SupplierfillDetails.suppTyp,
        tanNo: this.state.SupplierfillDetails.tanNo,
        totalOpeningBalance: this.state.SupplierfillDetails.totalOpeningBalance,
        userId: this.state.userDit.userId,
        entityTypeId: this.state.SupplierfillDetails.entityTypeId,
        vatno: "",
        accountNumber: this.state.SupplierfillDetails.accountNumber,
        ifscCode: this.state.SupplierfillDetails.ifscCode,
        supLineItems: filterProducts,
      };
      this.props.createSupplierMaster(
        serverApi.CREATE_SUPPLIER_MASTER_API,
        data,
        this.props.history
      );
    }
  };

  onUpdate = () => {
    // var filterProducts = this.state.Supplier_LineItems.filter((prop) => {
    //   if (prop.stateName !== "" && prop.isActive !== 0 && prop.branchName !=="" && prop.branchAddress !=="") {
    //     return prop;
    //   }
    // });
    var filterProductsData = this.state.Supplier_LineItems.filter((prop) => {
      if (prop.isActive !== 0) {
        return prop;
      }
    });
    let lastRow =
      this.state.Supplier_LineItems[this.state.Supplier_LineItems.length - 1];

    var Validate2 = false;
    if (filterProductsData.length === 1) {
      filterProductsData.map((prop) => {
        if (prop.emailValidation == true) {
          swal("Please Enter the valid Email ");
          return false;
        }
        if (prop.CityValidation == true) {
          swal("Please Enter the valid City ");
          return false;
        }
        if (prop.contactPersonValidation == true) {
          swal("Contact Person Field Shoudn't Contain Numbers");
          return false;
        }  else if (prop.stateCode == "" || prop.stateCode == undefined) {
          swal("Please Select State");
          return false;
        } else if (prop.branchName =="") {
          swal("Please enter the Branch Name");
        }else if (prop.branchAddress == "") {
          swal("Please Select Branch");
          return false;
        } else if (prop.contactNo == "") {
          swal("Please Enter Contact Number");
          return false;
        } else if (prop.email == "") {
          swal("Please Enter Email");
          return false;
        } else if (prop.openningBalance === "") {
          swal("Please Enter Openning Balance");
          return false;
        } else {
          Validate2 = true;
        }
      });
    } else {
      for (var i = 0; i < filterProductsData.length - 1; i++) {
        // if (this.state.Supplier_LineItems[i] !== lastRow) {
        if (filterProductsData[i].emailValidation === true) {
          swal("Please Enter the valid Email ");
          return false;
        }
        if (filterProductsData[i].CityValidation === true) {
          swal("Please Enter the valid City ");
          return false;
        }
        if (filterProductsData[i].contactPersonValidation === true) {
          swal("Conatact Person Field shouldn't contain Numbers ");
          return false;
        } else if (
          filterProductsData[i].stateCode === "" ||
          filterProductsData[i].stateCode === undefined
        ) {
          swal("Please Select the State ");
          return false;
        } else if (
          filterProductsData[i].branchName === "" ||
          filterProductsData[i].branchName == null 
        ) {
          swal("Please Enter the Branch Name");
          return false;
        } else if (filterProductsData[i].branchAddress === "") {
          swal("Please Select the Branch ");
          return false;
        } else if (filterProductsData[i].contactNo === "") {
          swal("Please Enter the contact Number ");
          return false;
        } else if (filterProductsData[i].email === "") {
          swal("Please enter the Email ");
          return false;
        } else if (filterProductsData[i].openningBalance === "") {
          swal("Please Enter the Opening Balance ");
          return false;
        } else {
          Validate2 = true;
        }
        // }
      }
    }
    if (Validate2 === true) {
      var filterProduct = this.state.Supplier_LineItems.filter((prop) => {
        if 
          ((prop.isActive === 1 && prop.branchName) || 
        (prop.isActive === 0 && prop.autoId))
          {
          return prop;
        }
      });
      let MukhamData = [];
      if (this.state.SupplierfillDetails.mukam) {
        if (this.state.SupplierfillDetails.mukam.length !== 0) {
          this.state.SupplierfillDetails.mukam.map((item) => {
            MukhamData.push({
              id: item.value,
              mukamName: item.name,
              isMapped: true,
            });
          });
        }
      }
      const data = {
        cipher: this.state.userDit.cipher,
        companyId: localStorage.getItem("companyId"),
        suppName: this.state.SupplierfillDetails.suppName,
        suppTyp: this.state.SupplierfillDetails.suppTyp,
        cellPhone: "",
        email: this.state.SupplierfillDetails.email,
        activeFlag: this.state.SupplierfillDetails.activeFlag == 2 ? "N" : "Y",
        vatno: "",
        contactNo: this.state.SupplierfillDetails.contactNo,
        contactPerson: this.state.SupplierfillDetails.contactPerson,
        designation: "",
        cin: this.state.SupplierfillDetails.cin,
        gstNo: "",
        panNo: this.state.SupplierfillDetails.panNo,
        accountNumber: this.state.SupplierfillDetails.accountNumber,
        ifscCode: this.state.SupplierfillDetails.ifscCode,
        state: "",
        createdBy: this.state.SupplierfillDetails.userId,
        address2: "",
        ledgerGroup: this.state.SupplierfillDetails.ledgerGroup,
        autoCreate: false,
        openningBalance: "",
        mukam: MukhamData,
        userId: this.state.userDit.userId,
        tanNo: this.state.SupplierfillDetails.tanNo,
        comments: this.state.SupplierfillDetails.comments,
        msmeCertified:
          this.state.SupplierfillDetails.msmeCertifiedYes !== true
            ? "NO"
            : "YES",
        activeStatus: "",
        totalOpeningBalance: this.state.SupplierfillDetails.totalOpeningBalance,
        suppId: this.state.SupplierfillDetails.suppId,
        id: this.state.SupplierfillDetails.suppCode,
        entityTypeId: this.state.SupplierfillDetails.entityTypeId,
        status: this.state.SupplierfillDetails.status,
        supLineItems: filterProduct,
      };
      this.props.updateSupplierMaster(
        serverApi.UPDATE_SUPPLIER_MASTER,
        data,
        this.props.history
      );
    }
  };

  handleCurrentStep = () => {
    const { currentStep } = this.state;

    if (currentStep === 1) {
      if (this.state.SupplierfillDetails.EmailFieldValidation == true) {
        swal("Please enter the valid Email", { icon: "error" });
      } else if (this.state.SupplierfillDetails.PanValidation == true) {
        swal("Please enter the valid Pan Number", { icon: "error" });
      } else if (this.state.SupplierfillDetails.TanValidation == true) {
        swal("Please enter the valid Tan Number", { icon: "error" });
      } else if (this.state.SupplierfillDetails.AccountNoValidation == true) {
        swal("Please enter the valid Account Number", { icon: "error" });
      } else if (this.state.SupplierfillDetails.IFScValidation == true) {
        swal("Please enter the valid IFSC code", { icon: "error" });
      } else {
        if(this.state.SupplierfillDetails.entityTypeId ==""){
          swal("Please Select the Entity Type");
        } else if (this.state.SupplierfillDetails.suppName == "") {
          swal("Please enter Supplier Name");
        } else if (this.state.SupplierfillDetails.suppTyp == "") {
          swal("Please Select Supplier Type");
        } else if (this.state.SupplierfillDetails.email == "") {
          swal("Please Enter the email Id ");
        } else if (this.state.SupplierfillDetails.contactPerson == "") {
          swal("Please Enter the contactPerson");
        } else if (this.state.SupplierfillDetails.contactNo == "") {
          swal("Please Enter the Contact Number ");
        } else if (this.state.SupplierfillDetails.suppTyp == "JUTE") {
          if (this.state.SupplierfillDetails.mukam.length == 0) {
            swal("Please Select the Mukam List");
          } else {
            this.setState({
              currentStep: currentStep + 1,
            });
          }
        } else {
          this.setState({
            currentStep: currentStep + 1,
          });
        }
      }
    }
  };
  render() {
    const { steps, currentStep } = this.state;
    return (
      <div>
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            <div className="iconsBlock">
              <Stepper alternativeLabel activeStep={currentStep}>
                {steps.map((prop) => (
                  <Step key={prop.title}>
                    <StepLabel
                      StepIconComponent={(Iconprops) => {
                        return this.QontoStepIcon(
                          Iconprops,
                          prop.icon,
                          prop.activeIcon,
                          prop.title
                        );
                      }}
                    ></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {currentStep === 1 && (
              <>
                <FillDetailsSupplier
                  Supplier_LineItems={this.state.Supplier_LineItems}
                  SupplierfillDetails={this.state.SupplierfillDetails}
                  locationState={this.state.locationState}
                />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={this.onClickCancel}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="greenButton"
                    handleClick={this.handleCurrentStep}
                    type="sumbit"
                  />
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                <SupplierLineItems
                  Supplier_LineItems={this.state.Supplier_LineItems}
                  SupplierfillDetails={this.state.SupplierfillDetails}
                />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={this.onClickCancel}
                    />
                  </Link>
                  <CustomButton
                    label={"Back"}
                    className="greenBorderButton"
                    handleClick={this.onClickBack}
                  />
                  {this.state.locationState.state !== undefined ? (
                    <>
                      <CustomButton
                        label="Update"
                        className="greenButton"
                        handleClick={() => this.onUpdate("")}
                        type="sumbit"
                      />
                      {/* {this.state.SupplierfillDetails.UpdateButton && (
                        <CustomButton
                          label="Update"
                          className="greenButton"
                          handleClick={() => this.onUpdate("")}
                          type="sumbit"
                        />
                      )}
                      {this.state.SupplierfillDetails.ApproveButton && (
                        <CustomButton
                          label="Approve"
                          className="greenButton"
                          handleClick={() =>
                            this.onUpdate(this.state.SupplierfillDetails.status)
                          }
                          type="sumbit"
                        />
                      )}
                      {this.state.SupplierfillDetails.ApproveButton && (
                        <CustomButton
                          label="Reject"
                          className="rejected"
                          handleClick={() => this.onUpdate(4)}
                          type="sumbit"
                        />
                      )} */}
                    </>
                  ) : (
                    <CustomButton
                      label="Create"
                      className="greenButton"
                      handleClick={this.onCreate}
                      type="sumbit"
                    />
                  )}
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {};

export default withRouter(
  connect(mapStatetoProps, {
    createSupplierMaster,
    SupplierLineItemsProps,
    updateSupplierMaster,
    supplierMasterViewById,
    SupplierFilldetailsProps,
  })(CreateSupplierMaster)
);
