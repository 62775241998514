import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import {
  createCostCenterMaster,
  updateCostCenterMaster,
} from "../../../store/Master/CostCenterMaster/actions";
import { getDepartmentList } from "../../../store/Global/DropDownApis/actions";
import { BudgetHeads } from "../../../store/Global/DropDownApis/actions";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import moment from "moment/moment";
import swal from "sweetalert";
import { validate } from "../../../Utilities/Validations";

class CreateCostCenterMasters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      costCenterId: "",
      costCenterCode: "",
      costCenterName: "",
      parentCostCenter: "",
      parentCostCenterVal: "",
      department: "",
      departmentVal: "",
      budgetHeads: "",
      budgetHeadsVal: "",
      budgetHeadId: "",
      createdBy: null,
      createdOn: "",
      deptCode: "",
      departmentList: [], // To displaying department list
      budgetHeadsList: [], // To displaying budget heads list
      locationState: "",
    };
  }
  /* Calling Create and Update Api's to Actions */
  onClickCreateUpdate = () => {
    if (
      this.state.costCenterCode === "" ||
      this.state.costCenterCode === undefined ||
      this.state.costCenterCode === null
    ) {
      swal("Please Enter The Cost Center Code");
      return false;
    }
    if (
      this.state.costCenterName === "" ||
      this.state.costCenterName === undefined ||
      this.state.costCenterName === null
    ) {
      swal("Please Enter The Cost Center Name");
      return false;
    }
    if (this.props.location.state === undefined) {
      const data = {
        companyId: this.state.companyId,
        createdBy: this.state.userDit.userId.toString(),
        costCode: this.state.costCenterCode,
        costDesc: this.state.costCenterName,
        parentId: this.state.parentCostCenterVal,
        deptCode: this.state.departmentVal,
        budgetHeadId: Number(this.state.budgetHeadsVal),
      };
      this.props.createCostCenterMaster(
        serverApi.CREATE_COSTCENTER_MASTER,
        data,
        this.props.history
      ); // calling create cost center list api
    } else {
      const data = {
        companyId: this.state.companyId,
        autoId: this.state.id,
        createdBy: this.state.userDit.createdBy,
        id: this.state.costCenterId,
        costCode: this.state.costCenterCode,
        costDesc: this.state.costCenterName,
        parentId: this.state.parentId,
        deptCode: this.state.deptCode,
        budgetHeadId: this.state.budgetHeadId,
      };

      if (
        this.state.costCenterId === "" ||
        this.state.costCenterId === undefined ||
        this.state.costCenterId === null
      ) {
        swal("Please Enter The Cost Center Id");
        return false;
      }
      this.props.updateCostCenterMaster(
        serverApi.UPDATE_COSTCENTER_MASTER,
        data,
        this.props.history
      ); //  calling update cost center master api
    }
    // }
  };
  // Function for previous page
  onClickCancel = () => {
    this.props.history.push("/Cost_Center_Master");
  };
  // Function for TextFields
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "costCenterName") {
      this.setState({
        costCenterName: event,
      });
    }

    if (key === "costCenterCode") {
      const costletter = event.replace(/[^a-zA-Z0-9\s]+/g, "");
      this.setState({
        costCenterCode: costletter,
      });
    }
  };
  // function for popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "parentCostCenter") {
      this.setState({
        parentId: selectedValue,
        parentCostCenterVal: selectedValue,
      });
    }
    if (name === "budgetHeads") {
      this.setState({
        budgetHeadId: selectedValue,
        budgetHeadsVal: selectedValue,
      });
    }
    if (name === "department") {
      this.setState({
        deptCode: selectedValue,
        departmentVal: selectedValue,
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    this.setState({
      locationState: this.props.location
    })
    window.addEventListener("scroll", this.handleScroll);
    this.props.getDepartmentList(
      serverApi.GET_DEPARTMENT_LIST +
      this.state.companyId +
      "/" +
      this.state.userDit.cipher,
      this.props.history
    ); // calling department dropdown list
    this.props.BudgetHeads(serverApi.GET_BUDGET_HEADS_LIST, this.state.history); // calling budget heads dropdown api
    if (this.props.location.state) {
      this.setState({
        id: this.props.location.state.rowData.autoId,
        costCenterId: this.props.location.state.rowData.id,
        costCenterCode: this.props.location.state.rowData.costCode,
        costCenterName: this.props.location.state.rowData.costDesc,
        deptCode: this.props.location.state.rowData.deptCode,
        budgetHeadId: this.props.location.state.rowData.budgetHeadId,
        parentId: this.props.location.state.rowData.parentId,
        createdBy: this.props.location.state.rowData.createdBy,
        createdOn: this.props.location.state.rowData.createdOn ?
         moment(this.props.location.state.rowData.createdOn).format('DD-MM-YYYY') : null,
      });
    }
  }
  componentWillReceiveProps(props) {
    if (props.departmentList.data) {
      var list = [];
      list = props.departmentList.data.filter((item) => {
        if (item.value !== 0) {
          return item;
        }
      });

      this.setState({
        departmentList: list, //  updating department list
      });
    }

    if (props.budgetHeadsList.data) {
      let budgetList = [];
      if (props.budgetHeadsList.data) {
        if (props.budgetHeadsList.data.data) {
          budgetList = props.budgetHeadsList.data.data.map((item) => {
            return {
              value: item.budgetHeadId,
              name: item.name,
              label: item.name,
            };
          });
        }
      }

      this.setState({
        budgetHeadsList: budgetList, // updating budgetheadslist
      });
    }
  }

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {this.state.locationState.state=== undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 3">
                      <TextFieldReq
                        value={this.state.costCenterId}
                        caps="OFF"
                        minValue="0"
                        onChange={this.onhandlechangeValue("costCenterId")}
                        label="Cost Center Id"
                        disabled={true}
                      />
                    </Box>
                  )}
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.costCenterCode}
                      caps="OFF"
                      minValue="0"
                      onChange={this.onhandlechangeValue("costCenterCode")}
                      label="Cost Center Code"
                      disabled={
                        this.state.locationState.state !== undefined ? true : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.costCenterName}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("costCenterName")}
                      label="Cost center Name"
                      disabled={this.state.costCenterCode === "" ? true : false || this.state.locationState.state !== undefined ? true : false}
                    />
                  </Box>
                  {this.state.locationState.state !== undefined && (
                    <>
                    {/* as per the new requirements we are hiding these fields */}
                      {/* <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            arrayOfData={parentCostCenter}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            isDropdownOpen={this.state.isDropdownOpen}
                            handleMenuOpen={this.handleMenuOpen}
                            selected={this.state.parentId}
                            update={this.props.location.state ? 1 : 0}
                            name="parentCostCenter"
                            label="Parent Cost center"
                            isDisabled={this.state.costCenterCode === "" ? true : false}
                          />
                        </div>
                      </Box> */}

                      {/* <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            arrayOfData={this.state.departmentList}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            isDropdownOpen={this.state.isDropdownOpen}
                            handleMenuOpen={this.handleMenuOpen}
                            selected={this.state.deptCode}
                            update={this.props.location.state ? 1 : 0}
                            name="department"
                            label="Department"
                            isDisabled={this.state.costCenterName === "" ? true : false}
                          />
                        </div>
                      </Box> */}
{/* 
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            arrayOfData={this.state.budgetHeadsList}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            isDropdownOpen={this.state.isDropdownOpen}
                            handleMenuOpen={this.handleMenuOpen}
                            selected={this.state.budgetHeadId}
                            update={this.props.location.state ? 1 : 0}
                            name="budgetHeads"
                            label="Budget Head"
                            isDisabled={this.state.costCenterName === "" ? true : false}
                          />
                        </div>
                      </Box> */}
                    </>
                  )}
              
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> { this.state.createdOn }</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.state.locationState.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}

const parentCostCenter = [
  {
    value: "1",
    label: "Capital",
    name: "Capital",
  },
  {
    value: "2",
    label: "General",
    name: "General",
  },
  {
    value: "3",
    label: "Maintainence",
    name: "Maintainence",
  },
  {
    value: "4",
    label: "Open",
    name: "Open",
  },
  {
    value: "5",
    label: "Production",
    name: "Production",
  },
];

const mapStatetoProps = (state) => {
  const { CreateCostCenterMaster, UpdateCostCenterMaster } =
    state.CostCenterListReducer; // fetching create and update from cost center list reducer
  const { departmentList, budgetHeadsList } = state.DropDownListReducer; // fetching department and budget heads dropdowns from global api
  return {
    departmentList,
    budgetHeadsList,
    CreateCostCenterMaster,
    UpdateCostCenterMaster,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    createCostCenterMaster,
    updateCostCenterMaster,
    getDepartmentList,
    BudgetHeads,
  })(CreateCostCenterMasters)
);
