//import { Switch } from "react-router-dom";

import {
    API_ERRORS,
    BANK_ACCOUNT_LIST,CREATE_BANK_ACCOUNT,UPDATE_BANK_ACCOUNT,
    BANK_ACCOUNT_LIST_SUCCESSFULL,CREATE_BANK_ACCOUNT_SUCCESSFULL,UPDATE_BANK_ACCOUNT_SUCCESSFULL
  } from "./actionTypes";
  
  const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    BankAccountList: [],
    Create_Bank_Account:[],
    Update_Bank_Account:[],
    error_msg: null,
  };
  
  const BankAccountListReducer = (state = initialState, action) => {
    switch (action.type) {
      case BANK_ACCOUNT_LIST:
        state = {
          ...state,
          loading: true,
        };
        break; 
      case BANK_ACCOUNT_LIST_SUCCESSFULL:
        state = {
          ...state,
          BankAccountList: action.payload.response,
          loading: false,
        };
        break;
        case CREATE_BANK_ACCOUNT:
          state = {
            ...state,
            loading: true,
          };
          break;
        case CREATE_BANK_ACCOUNT_SUCCESSFULL:
          state = {
            ...state,
            Create_Bank_Account: action.payload.response,
            loading: true,
          };
          break;
          case UPDATE_BANK_ACCOUNT:
          state = {
            ...state,
            loading: true,
          };
          break;
        case UPDATE_BANK_ACCOUNT_SUCCESSFULL:
          state = {
            ...state,
            Update_Bank_Account: action.payload.response,
            loading: true,
          };
          break;
        case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
      default:
        state = { ...state };
        break;
    }
  
    return state;
  };
  
  export default BankAccountListReducer;
  