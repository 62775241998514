import { PAYSLIP_LIST, PAYSLIP_DATA, PAYSLIP_AVAILABLE_LIST, PAY_SUMMARY, PAYSLIP_DUP_DATA } from "./actionType";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { payslipListSuccessfull, paySlipsByEbNoSuccessfull, getAllPaySlipsSuccessfull, getAllpaySlips, getPaySummarySuccessfull, getPaySummary, paySlipsByEbNoSuccessfull2 } from "./actions";
import { getList, getListurl } from "../../../helpers/Server_Helper";
import swal from "sweetalert";
import { hideSpinner,serverApi, showSpinner,} from "../../../helpers/Consts";

function* getPayslipList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(payslipListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getPaySummaryList({ payload: { url, data, history } }) {
  try {
    showSpinner()
    const response = yield call(getList, url, data);
    yield put(getPaySummarySuccessfull({ response }));
    if(response){
      hideSpinner()
    }
  } catch (error) {
    hideSpinner()
    console.log(error);
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
  }
}
function* setAllPayslipList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(getAllPaySlipsSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getPayslipData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(paySlipsByEbNoSuccessfull({ response }));
     if(response.data.status === true) {
      const data = {
        ebId: response.data.data.ebId,
        companyId: localStorage.getItem("companyId"),
      };
     
      yield put(getAllpaySlips(serverApi.PAYSLIP_AVAILABLE_DATA, data,history));

     }else {
      swal(response.data.message, { icon: "error" });
     }
   
  } catch (error) {
    console.log(error);
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
  }
}

function* getPayslipData2({ payload: { url, fromDate, toDate, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(paySlipsByEbNoSuccessfull2({ response }));
     if(response.data.status === true) {
     
      const data2 = {
        ebId: response.data.data.ebId,
        companyId: localStorage.getItem('companyId'),
        fromDate: fromDate,
        toDate: toDate
      }
      let Url = serverApi.PAY_SUMMARY;
     }
  } catch (error) {
    console.log(error);
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
  }
}

export function* watchPaySlipData() {
  yield takeEvery(PAYSLIP_DATA, getPayslipData);
  yield takeEvery(PAYSLIP_DUP_DATA, getPayslipData2);
  yield takeEvery(PAYSLIP_LIST, getPayslipList);
  yield takeEvery(PAYSLIP_AVAILABLE_LIST, setAllPayslipList);
  yield takeEvery(PAY_SUMMARY, getPaySummaryList);

}
function* PayslipSaga() {
  yield all([
    fork(watchPaySlipData),
  ]);
}

export default PayslipSaga;
