export const JUTE_QUALITY_CHECK = "jute_quality_check_list";
export const JUTE_QUALITY_CHECK_SUCCESSFULL = "jute_quality_check_list_successfull";
export const JUTE_QUALITY_CHECK_UPDATE = "jute_quality_check_create";
export const JUTE_QUALITY_CHECK_UPDATE_SUCCESSFULL = "jute_quality_check_create_successfull"
export const JUTE_COMMIT_MATERIAL_READING = "jute_commit_material_reading";

export const JUTE_QUALITYCHECK_VIEWBYID = "jute_qualitycheck_viewbyid";
export const JUTE_QUALITYCHECK_VIEWBYID_SUCCESSFULL = "jute_qualitycheck_viewbyid_successfull";

export const JUTE_QUALITY_CHECK_HEADERS = "jute_quality_check_headers"
export const JUTE_QUALITY_CHECK_LINEITEMS = "jute_quality_check_lineitems"
export const CLEAR_JUTE_QUALITY_CHECK_HEADERS = "clear_jute_quality_check_headers"
export const CLEAR_JUTE_QUALITY_CHECK_LINEITEMS = "clear_jute_quality_check_lineitems"

