import {CARRIERS_LIST,CARRIERS_LIST_SUCCESSFULL,
    CREATE_CARRIERS,CREATE_CARRIERS_SUCCESSFULL,
    UPDATE_CARRIERS,UPDATE_CARRIERS_SUCCESSFULL,
    API_ERRORS,} from './actionTypes';

export const carriersList = (url,data,history) =>{
    return {
        type:CARRIERS_LIST,
        payload:{url,data,history}
    }
}
export const carriersListSuccessfull = (data)=>{
    return {
        type:CARRIERS_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createCarriers = (url, data, history) => {
    return {
        type: CREATE_CARRIERS,
        payload: { url, data, history }
    }
}

export const createCarriersSuccessfull = (data) => {
    return {
        type: CREATE_CARRIERS_SUCCESSFULL,
        payload: data
    }
}
export const updateCarriers = (url, data, history) => {
    return {
        type: UPDATE_CARRIERS,
        payload: { url, data, history }
    }
}
export const updateCarriersSuccessfull = (data) => {
    return {
        type: UPDATE_CARRIERS_SUCCESSFULL,
        payload: data
    }
}

export const apiErrors = (error) => {
    return {
        type: API_ERRORS,
        payload: error,
    };
};