import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import moment from "moment";
import {
  createYarnMaster,
  updateYarnMaster,
} from "../../../store/Master/YarnMaster/actions";
import InputAdormentSearch from "../../../components/InputAdorment/InputAdormentSearch";
import swal from "sweetalert";

class CreateYarnMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      createdBy: "",
      yarnType: "",
      yarnGroup: "",
      yarnId: "",
      createdDate: "",
    };
  }
  /* Calling Create and Update Api's to Actions */
  onClickCreateUpdate = () => {
    const { yarnType } = this.state;
    if (yarnType !== "" && yarnType !== undefined) {
      if (this.props.location.state === undefined) {
        const data = {
          yarnType: this.state.yarnType,
          yarnGroup: this.state.yarnGroup,
          companyId: this.state.companyId,
          createdBy: this.state.userDit.userId.toString(),
        };
        this.props.createYarnMaster(
          serverApi.CREATE_YARN_MASTER,
          data,
          this.props.history
        ); // calling create yarn master api
      } else {
        const data = {
          companyId: this.state.companyId,
          yarnType: this.state.yarnType,
          yarnTypeId: this.state.yarnId,
          createdDateTime: this.state.createdOn,
          yarnGroup: this.state.yarnGroup,
        };

        this.props.updateYarnMaster(
          serverApi.UPDATE_YARN_MASTER,
          data,
          this.props.history
        ); // calling update yarn master api
      }
    } else {
      swal("Please Enter Yarn Type");
    }
  };
  // function for previous page
  onClickCancel = () => {
    this.props.history.push("/yarn_master");
  };
  //function for TextFields
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "yarnType") {
      this.setState({
        yarnType: event,
      });
    }
    if (key === "yarnGroup") {
      this.setState({
        yarnGroup: event,
      });
    }
  };
  // function for popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({
        cipher: this.props.location.state.rowData.cipher,
        createdBy: this.props.location.state.rowData.createdBy,
        createdOn: this.props.location.state.rowData.createdDateTime,
        companyId: this.props.location.state.rowData.companyId,
        yarnType: this.props.location.state.rowData.yarnType,
        yarnId: this.props.location.state.rowData.yarnTypeId,
        yarnGroup: this.props.location.state.rowData.yarnGroup,
      });
      const dateEntered = this.props.location.state.rowData.createdDateTime;
      const dateIsValid = moment(dateEntered).format("LLLL");
      this.setState({
        createdDate: dateIsValid,
      });
    }
  }

  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {this.props.location.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 3">
                      <TextFieldNormal
                        value={this.state.yarnId}
                        readOnly={
                          this.props.location.state !== undefined ? true : false
                        }
                        caps="OFF"
                        onChange={this.onhandlechangeValue("yarnId")}
                        label="Yarn Id"
                      />
                    </Box>
                  )}
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <InputAdormentSearch
                        btnLable={"LBS"}
                        label="Yarn Type"
                        handleChange={this.onhandlechangeValue("yarnType")}
                        onClick={this.onClickAdorment}
                        value={this.state.yarnType}
                        maxLength={15}
                        placeholder="Enter here."
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.yarnGroup}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("yarnGroup")}
                      label="Yarn Group"
                    />
                  </Box>
                  {this.props.location.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdDate}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.props.location.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
          {/* common popup for validation */}
          {this.state.confirm && (
            <CommonPopup>
              <div className="delAddItem">
                <div className="mandatoryFieldText">
                  Please select all the mandatory fields
                </div>
                <div className="delAddItemBtns">
                  <input
                    type="button"
                    onClick={this.onclickPopUP.bind(this)}
                    value="Ok"
                    className="delYesBtn"
                  />
                </div>
              </div>
            </CommonPopup>
          )}
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {};

export default withRouter(
  connect(mapStatetoProps, {
    createYarnMaster,
    updateYarnMaster,
  })(CreateYarnMaster)
);
