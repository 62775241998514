import React, { Component } from "react";
import {
  Grid,
  Box,
  Button,
  Tooltip,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableBody,
  IconButton
} from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  serverApi,
  serverConfig,
  showSpinner,
  hideSpinner,
} from "../../../helpers/Consts";
import TextArea from "../../../components/TextField/TextArea";
import { getItemsListForIndent } from "../../../store/Global/DropDownApis/actions";
import { InwardLineItemsProps } from "../../../store/Purchase/Inward/actions";
import {
  getDepartmentList,
  allItemGroupMasterList,
  getParentBudgetHeads,
} from "../../../store/Global/DropDownApis/actions";

import Fade from "@mui/material/Fade";
import CustomButton from "../../../components/Buttons/Buttons";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import { getFileDownload } from "../../../helpers/server.js";
import { getFilesofInward } from "../../../store/Purchase/Inward/actions";
import {
  getPendingStockPoandReceipt,
  getPendingStock,
  actions,
  logs,
  getFiles,
} from "../../../store/Purchase/Indent/actions";
import {
  postUploadFileList,
  deleteFile,
} from "../../../store/MyProfile/UploadFile/actions";
import { getTax_List } from "../../../store/Global/DropDownApis/actions";
import DeleteIcons from "../../../assets/images/picEditIcon.png";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import moment from "moment";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import swal from "sweetalert";
import DeleteIcon from "@mui/icons-material/Delete";

class WorkReportLineitems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      rowCount: 0,
      amount: 0,
      itemsList: [],
      taxList: [],
      files_List: [],
      fileName: "",
      fileType: "",
      lineItemId: 1,
      delete: false,
      deletedId: "",
      Inward_LineItems: this.props.Inward_LineItems,
      Logs: [],
      DepartmentList: [],
      termsNconditions: [],
      ItemGroupMasterList: [],
      parentBudgetHeadsList: [],
      internalNote: this.props.InwardFillDetails.internalNote,
      igstPercentage: 0,
      cgstPercentage: 0,
      sgstPercentage: 0,
      AdvancePercentage: this.props.InwardFillDetails.AdvancePercentage,
      AdvanceAmount: this.props.InwardFillDetails.AdvanceAmount,
      termsNcondition: this.props.InwardFillDetails.termsNcondition,
      selectTermsNconditions:
        this.props.InwardFillDetails.selectTermsNconditions,
      AllItemGroupMasterList: [],
      uploadFile: false,
      seeLogs: false,
      isDropdownOpen: false,
      locationState: this.props.locationState
    };
  }

 
  componentDidMount() {
    this.setState({
      locationState:this.props.locationState
    })
    window.addEventListener('scroll', this.handleScroll);
    showSpinner();
    this.props.getParentBudgetHeads(
      serverApi.GET_PARENT_BUDGET_HEADS_LIST +
      "company/" +
      localStorage.getItem("companyId"),
      this.props.history
    ); // calling parent budget heads api

    this.props.getDepartmentList(
      serverApi.GET_DEPARTMENT_LIST +
      localStorage.getItem("companyId") +
      "/" +
      this.state.userDit.cipher,
      this.props.history
    );
    this.props.getTax_List(
      serverApi.GET_ALL_TAXLIST +
      localStorage.getItem("companyId") +
      "/" +
      this.state.userDit.cipher,
      this.props.history
    );

    if (
      this.props.Inward_LineItems &&
      this.props.Inward_LineItems !== undefined
    ) {
      if (this.props.Inward_LineItems) {
        this.props.Inward_LineItems.forEach((item) => {
          if (this.props.source === "WITHPO") {
            if (item.departmentId !== null && item.departmentId !== "") {

              this.props.allItemGroupMasterList(
                serverApi.GET_ITEMGROUP_LIST +
                item.departmentId +
                "/" +
                localStorage.getItem("companyId") +
                "/getAllItemGroupsByDepartmentId/" +
                this.state.userDit.cipher,
                this.props.history
              );
            }
          } else {
            if (item.budgetHeadId !== null && item.budgetHeadId !== "") {
              this.props.allItemGroupMasterList(
                serverApi.GET_ITEMGROUPS_BYBUDGET_HEADS +
                "/" +
                localStorage.getItem("companyId") +
                "/budgetHead/" +
                item.budgetHeadId,
                this.props.history
              );
            }
          }
          if (item.itemGroup !== null && item.itemGroup !== "") {
            const data = {
              companyId: localStorage.getItem("companyId"),
              consumable: "",
              indentTypeId: "",
              itemGroupId: item.itemGroup,
              saleable: "",
              branchId:this.props.InwardFillDetails.branchId,
              // tangible: "Y",
            };
            this.props.getItemsListForIndent(
              serverApi.GET_ITEMSBY_FORPROCUREMENT,
              data,
              this.props.history
            );
          }
        });
      }
      hideSpinner();
    } else {
      let Inward_LineItems = [
        {
          lineItemId: 1,
          departmentId: "",
          itemId: "",
          itemGroupId: "",
          rate: 0,
          budgetHeadId: "",
          inwardQty: 0,
          uomCode: "",
          description: "",
          poId: "",
          poDetailsId: "",
          oldQty: 0,
          hdrStatus: 1,
          isActive: 1,
          tax: "",
          igstPercentage: "",
          cgstPercentage: "",
          sgstPercentage: "",
          hsnCode: "",
          taxPercentage: "",
          amount: 0,
        },
      ];
      this.props.InwardLineItemsProps(Inward_LineItems);
    }

    if (this.props.Upload_File_List) {
      if (this.props.location.state) {
        let id = this.props.location.state.rowData.id;
        this.props.getFilesofInward(
          serverApi.GET_FILES +
          18 +
          "/" +
          id +
          "/" +
          localStorage.getItem("companyId"),
          this.props.history,
          18,
          "MI"
        );
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentWillReceiveProps(props) {
    if (props.departmentList) {
      this.setState({
        DepartmentList: props.departmentList.data,
      });
    }
    if (props.allItemGroupMaster_List) {
      this.setState({
        AllItemGroupMasterList: props.allItemGroupMaster_List.data,
      });
    }
    let itemsList = props.itemsListForIndent;
    this.setState({
      itemsList: itemsList,
    });
    if (props.taxList) {
      this.setState({
        taxList: props.taxList.data,
      });
    }

    if (props.Inward_LineItems) {
      let Inward_LineItems = props.Inward_LineItems.map((data) => {
        data.approvedQty =
          data.approvedQty === null ? data.inwardQty : data.approvedQty;
        return data
      });
      this.setState({
        Inward_LineItems: props.Inward_LineItems,
      });
    }
    if (props.parentBudgetHeadsList) {
      this.setState({
        parentBudgetHeadsList: props.parentBudgetHeadsList.data, // updating for parent budget heads list
      });
    }

    if (this.props.InwardFillDetails) {
      let taxType = this.props.InwardFillDetails.taxType;
      if (taxType === 1 || taxType === 3) {
        this.setState({
          igstPercentage: 1,
          cgstPercentage: 0,
          sgstPercentage: 0,
        });
      } else {
        this.setState({
          igstPercentage: 0,
          cgstPercentage: 1,
          sgstPercentage: 1,
        });
      }
    }
    if (props.logs_List) {
      this.setState({
        Logs: props.logs_List.data,
      });
    }

    if (this.state.seeLogs === false) {
      if (props.mifilesList) {
        if (props.mifilesList.data) {
          let filesList = props.mifilesList.data;
          this.setState({
            files_List: filesList.data,
          });
        }
      }
    }
    if (this.state.uploadFile === true) {
      if (this.state.fileName !== "") {
        if (props.Upload_File_List) {
          var Filedata = props.Upload_File_List.data;
          if (props.Upload_File_List.status) {
            this.setState({
              uploadFile: false,
            });
            if (this.state.files_List !== undefined) {
              var arr = this.state.files_List;
              Filedata = {
                fileUploadId: Filedata.id,
                fileName: this.state.fileName,
                fileExtension: this.state.fileType,
                retrievalPath: Filedata.fileUrl,
                sourceMenu: 18,
              };
              arr.splice(arr.length, 0, Filedata);
              this.setState({
                files_List: arr,
              });
            }
          }
        }
      }
    }
  }

  handleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    this.setState({ [key]: event });
    if (key === "qty") {
      if (Number(value) >= 0) {

        row.inwardQty = Number(value);
        row.amount = Number(row.rate) * Number(row.inwardQty);
      }
    }
    if (key === "Rate") {
      row.rate = Number(value);
      row.amount = Number(row.rate) * Number(row.inwardQty);
    }
    if (key === "Approved Quantity") {
      if (Number(value) <= row.inwardQty) {
        row.approvedQty = Number(value);
        row.rejectedQty = Number(row.inwardQty) - Number(row.approvedQty);
      }
      else {
        swal("Approved Quantity Shouldn't be greater than Inward Quantity")
        row.approvedQty = row.inwardQty
        row.rejectedQty = 0
      }


    }
    if (key === "Rejected Quantity") {
      if (Number(value) <= row.inwardQty) {
        row.rejectedQty = Number(value);
        row.approvedQty = Number(row.inwardQty) - Number(value);
      }
      else {
        swal("Rejected Quantity Shouldn't be greater than Inward Quantity")
        row.approvedQty = row.inwardQty
        row.rejectedQty = 0
      }


    }
  };


  onClickToSeeLogs = () => {
    this.setState({
      uploadFile: false,
      seeLogs: true,
    });
    if (this.props.location.state) {
      let id = this.props.location.state.rowData.id;
      this.props.logs(serverApi.SEE_THE_LOGSOF_INWARD + id, this.props.history);
    }
  };

  handleChangetextAreas = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "internalNote") {
      this.props.InwardFillDetails.internalNote = event;
      this.setState({
        internalNote: event,
      });
    }
  };
  handleChangedropdown = (selectedValue, selectedName, name, selectedRow) => {
    this.setState({
      isDropdownOpen: false
    })
    var newline = 1;
    var Object = this.state.Inward_LineItems.filter((row) => {
      if (row.lineItemId === selectedRow.lineItemId) {
        if (name === "department") {
          row.departmentId = selectedValue;
        }
      }
      newline = Number(row.lineItemId);
      return row;
    });
    if (name === "department") {
      let obj = {
        lineItemId: Number(newline) + 1,
        departmentId: "",
        itemId: "",
        itemGroupId: "",
        rate: 0,
        budgetHeadId: "",
        inwardQty: 0,
        uomCode: "",
        description: "",
        poId: "",
        poDetailsId: "",
        oldQty: 0,
        hdrStatus: 1,
        isActive: 1,
        tax: "",
        igstPercentage: "",
        cgstPercentage: "",
        sgstPercentage: "",
        hsnCode: "",
        taxPercentage: "",
      };
      Object.push(obj);
      this.setState({
        Inward_LineItems: Object,
        lineItemId: selectedRow.lineItemId,
      });
      this.props.InwardLineItemsProps(Object);
    }
    if (name === "itemGroup") {
      const data = {
        companyId: localStorage.getItem("companyId"),
        consumable: "",
        indentTypeId: "",
        itemGroupId: selectedValue,
        saleable: "",
        branchId:this.props.InwardFillDetails.branchId,

      };
      this.props.getItemsListForIndent(
        serverApi.GET_ITEMSBY_FORPROCUREMENT,
        data,
        this.props.history
      );
      selectedRow.itemGroupId = selectedValue;
      selectedRow.itemGroup = selectedValue;
    }
    if (name === "item") {
      let value = selectedName.split("^");
      selectedRow.itemId = selectedValue;
      let rate = 0;
      if (value[3]) {
        rate = Number(value[3]);
      }
      let HSN = value[4];
      let price = rate;
      let uom = value[1];
      selectedRow.hsnCode = HSN;
      selectedRow.rate = Number(price);
      selectedRow.uomCode = uom;
      selectedRow.description = selectedName[1];
    }
   //adding here
   if (name === "BudgetHead") {
    let obj = {
      lineItemId: Number(newline) + 1,
      departmentId: "",
      itemId: "",
      itemGroupId: "",
      rate: 0,
      budgetHeadId: "",
      inwardQty: 0,
      uomCode: "",
      description: "",
      poId: "",
      poDetailsId: "",
      oldQty: 0,
      hdrStatus: 1,
      isActive: 1,
      tax: "",
      igstPercentage: "",
      cgstPercentage: "",
      sgstPercentage: "",
      hsnCode: "",
      taxPercentage: "",
    };
    Object.push(obj);
    this.setState({
      Inward_LineItems: Object,
      lineItemId: selectedRow.lineItemId,
    });
    this.props.InwardLineItemsProps(Object);
  }

    if (name === "BudgetHead") {
      selectedRow.budgetHeadId = selectedValue;
      selectedRow.budgetHeadName = selectedName
      this.props.allItemGroupMasterList(
        serverApi.GET_ITEMGROUPS_BYBUDGET_HEADS +
        "/" +
        localStorage.getItem("companyId") +
        "/budgetHead/" +
        selectedValue,
        this.props.history
      );
    }
    if (name === "Tax") {
      selectedRow.tax = selectedValue;
      let taxPercentage = selectedName.split("^");
      selectedRow.taxPercentage = taxPercentage[1];
      selectedRow.cgstPercentage = Number(taxPercentage[1] / 2);
      selectedRow.sgstPercentage = Number(taxPercentage[1] / 2);
      if (this.props.InwardFillDetails.taxType === 1) {
        selectedRow.igstPercentage = Number(taxPercentage[1]);
      }
    }

    if (name === "Reason") {
      let Inward_LineItems = this.state.Inward_LineItems.filter((row) => {
        if (selectedRow.id === row.id) {
          row.reason = selectedValue;
        }
        return row;
      });
      this.setState({
        Inward_LineItems: Inward_LineItems,
      });
      this.props.InwardLineItemsProps(Inward_LineItems);
    }
  };

  onDelEvent = (event) => {
    this.setState({ delete: true, deletedId: event.target.name });
  };

  onDel = (event) => {
    this.setState({ delete: false });
    if (event.target.value === "Yes") {
      if (this.state.Inward_LineItems.length > 1) {
        let lineItemId = this.state.deletedId;
        var object = this.state.Inward_LineItems.filter((row) => {
          if (row.lineItemId != lineItemId) {
            return row;
          }
        });

        this.setState({
          Inward_LineItems: object,
          lineItemId: lineItemId,
        });
        this.props.InwardLineItemsProps(object);
      }
    }
  };

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    this.setState({
      isDropdownOpen: false
    })
    if (name === "termsNconditions") {
      this.props.InwardFillDetails.termsNconditions = selectedName;
      this.props.InwardFillDetails.selectTermsNconditions = selectedName;
      this.setState({
        termsNconditions: selectedName,
        selectTermsNconditions: selectedName,
      });
    }
  };

  onhandleChange = (key) => (value) => {
    this.setState({ [key]: value });
    if (key === "advance_per") {
      this.props.PoFillDetails.AdvancePercentage = value;
      this.setState({
        AdvancePercentage: value,
      });
    }
    if (key === "advance_amt") {
      this.props.PoFillDetails.AdvanceAmount = value;
      this.setState({
        AdvanceAmount: value,
      });
    }
  };
  UploadDocuments = (key) => (e) => {
    if (this.props.location.state) {
      var inwardId = this.props.location.state.rowData.id;
    }
    e.preventDefault();
    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 18);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", inwardId);
    formData.append("createdBy", this.state.userDit.userId);
    formData.append("fileCompanyId", localStorage.getItem("companyId"));
    this.setState({
      fileName: file.name,
      fileType: fileExt,
      uploadFile: true,
    });
    this.props.postUploadFileList(
      serverApi.UPLOADFILE,
      formData,
      this.props.history
    );
  };

  onDeleteFile = (fileId) => {
    let data = this.state.files_List.filter(
      (item) => item.fileUploadId !== fileId
    );
    this.props.deleteFile(serverApi.DELETEFILE + fileId);
    this.setState({
      files_List: data,
    });
  };
  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true
    })
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false
    })
  }

  FieldDisable = (row, col) => {
    if (this.props.source === "WITHOUTWO" && (row.inwardSequenceNo === "" || row.inwardSequenceNo === undefined || row.inwardSequenceNo === null)) {
      
      return false;
    } else
      if (
        this.props.source === "WITHOUTWO" &&
        (row.inwardSequenceNo === "" || row.inwardSequenceNo === undefined || row.inwardSequenceNo === null) &&
        (col.type === "BudgetHead" || col.type === "department") &&
        row.others === true
      ) {
        return false;
      } else if (this.props.location.state === undefined) {
        return false;
      } else if ((this.props.source === "WITHWO" || this.props.source === "WITHOUTWO") && this.props.location.state !== undefined) {
        if(row.rejectedQty > 0 && col.type === "Reason")
        return false;
      else {
        return true;
      }
    } else {
        return true;
      }

  }

  returnDynamicSlect = (row,col) => {
    return (
      <div className="quantityDropdownBlock">
        <Grid className="selectAndTextfield">
          <Grid
            xs={12}
            md={12}
            className="dynamicSelectBlock"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            
            <DynamicSelect
              arrayOfData={
                col.type === "department"
                  ? this.state.DepartmentList
                  : col.type === "BudgetHead"
                    ? this.state.parentBudgetHeadsList
                    : col.type === "itemGroup"
                      ? this.state.AllItemGroupMasterList
                      : col.type === "item"
                        ? this.state.itemsList
                        : col.type === "discountMode"
                        ? discountModeList
                        : col.type === "Reason"
                          ? ReasonTypes
                          : []
              }
              className="dropdownBlockContainer"

              onSelectChange={
                this.handleChangedropdown
              }
              isDropdownOpen={this.state.isDropdownOpen}
              handleMenuOpen={this.handleMenuOpen}
              selected={
                col.type === "department"
                  ? row.departmentId
                  : col.type === "itemGroup"
                    ? row.itemGroup
                    : col.type === "item"
                      ? row.itemId
                      : col.type === "Tax"
                        ? row.tax
                        : col.type === "BudgetHead"
                          ? row.budgetHeadId
                        : col.type === "discountMode"
                        ? row.discountMode
                          : col.type === "Reason"
                            ? row.reason
                            : ""
              }
              placeholder={"extra emplty line item"}
              name={col.type}
              row={row}
              update={
                row.itemGroup ||
                  row.departmentId ||
                  row.itemId ||
                  row.budgetHeadId ||
                  row.tax ||
                  row.reason
                  ? 1
                  : 0
              }
              isDisabled={this.FieldDisable(row, col)}

            />
          </Grid>
        </Grid>
      </div>
    )
  }

  onDelLineItem = (row) => {
    var lastRow =
    this.state.Inward_LineItems[this.state.Inward_LineItems.length - 1];
  if (lastRow === row) {
   
    swal("you can't delete the empty lineitem");
  }
     else {
    
      var indexitem = this.state.Inward_LineItems.findIndex(
        (item) => item === row
      );
      var lineItems = this.state.Inward_LineItems;
      lineItems[indexitem].isActive = 0;
    
      this.setState({
        Inward_LineItems: lineItems,
      });
      this.props.InwardLineItemsProps(lineItems);
    }
  };

  render() {
  var filteredproducts = this.state.Inward_LineItems.filter(
    (item) => item.departmentId !== "" && item.isActive !== 0
  );
    let DepartmentList = this.state.DepartmentList;
    let AllItemGroupMasterList = this.state.AllItemGroupMasterList;
    let totalAmount = 0;
    let totalIgst = 0;
    let totalCgst = 0;
    let totalSgst = 0;
    let totalResult = 0
    if (filteredproducts) {
      filteredproducts.forEach((data) => {
        if (data.amount || (data.approvedQty && data.rate)) {
          totalAmount =
            totalAmount +
            (data.amount ? data.amount : data.approvedQty * data.rate);
          if (data.discountMode !== undefined && data.discountMode !== "" && data.discountMode !== null) {
            let amount =
              Number(data.approvedQty) * Number(data.rate);
            if (Number(data.discountMode) === 1) {
              amount = amount - data.discount;
              totalAmount = amount
            } else if (Number(data.discountMode) === 2) {
              let disAmount = (amount * data.discount) / 100;
              totalAmount = amount - disAmount;
            }

            totalResult = totalResult + totalAmount;

          } else {
            totalAmount = data.approvedQty * data.rate
            totalResult = totalResult + totalAmount;

          }
        }
        if (Number(this.props.InwardFillDetails.taxType) === 4) {
          totalIgst = 0;
          totalCgst = 0;
          totalSgst = 0;
        } else {
          if (this.props.InwardFillDetails.taxType === 1) {
            totalIgst =
              totalIgst + ((totalAmount * data.taxPercentage) / 100)
          } else if (this.props.InwardFillDetails.taxType === 2) {
            let taxPercentage = data.taxPercentage / 2;
            totalCgst = ((totalAmount * taxPercentage) / 100)
            totalSgst = ((totalAmount * taxPercentage) / 100)
          }
        }
      });
    }
    var netTotal =
      totalResult + Number(totalCgst) + totalIgst + Number(totalSgst);
      this.props.InwardFillDetails.netTotal = totalResult;
      this.props.InwardFillDetails.totalAmount = netTotal;

    let Inward_LineItems = filteredproducts

    var tableHeadersData = tableHeaders.filter(item => item.header !== "Department")
    var RowDatatable = RowData.filter(item => item.type !== "department")
   

    let lastLineItems = this.state.Inward_LineItems[this.state.Inward_LineItems.length - 1]
    
    return (
      // work report on update time
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent" onScroll={this.handleScroll}>
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {this.props.source == "WITHWO" ? tableHeaders.map((column) => (
                    <th>{column.header}</th>
                  )) :
                    tableHeaders.map((column) => (
                      <th>{column.header}</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {Inward_LineItems.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <>
                      {RowData &&
                        RowData.map((col) =>
                          col.component === "EditableCell" ? (
                            <>
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {/* discount perecentage - dicount/Amount */}
                                      <input
                                        type="text"
                                        name={col.type}
                                        style={{
                                          textAlign:
                                            col.type === "amount" ||
                                            col.type === "Rate" ||
                                            col.type === "qty" || 
                                            col.type === "Approved Quantity" || 
                                            col.type === "Rejected Quantity" ||
                                            col.type === "discountPercentage"
                                              ? "right"
                                              : "",
                                          paddingRight: "8px",
                                        }}
                                        id={col.no}
                                        value={
                                          col.type === "qty"
                                            ? row.inwardQty
                                              ? row.inwardQty
                                              : row.qty
                                            : col.type === "Unit"
                                              ? row.uomCode
                                                ? row.uomCode
                                                : row.uom
                                              : col.type === "Approved Quantity"
                                                ? row.approvedQty !== null
                                                  ? row.approvedQty
                                                  : row.inwardQty
                                                : col.type === "Rejected Quantity"
                                                  ? row.rejectedQty
                                                    ? row.rejectedQty
                                                    : 0
                                                  : col.type === "Rate"
                                                    ? row.rate
                                                    : col.type === "Tax"
                                                      ? row.taxPercentage
                                                      : col.type === "discountPercentage"
                                                        ? row.discountPercentage
                                                        : col.type === "discountMode"
                                                          ? row.discountMode !== null ? row.discountMode === 1 ? "Amount" : "Percentage" : ""
                                                          : ""


                                          // ?  row.discountMode ===null?0:Number(row.discountMode) ==="Fixed"
                                          //   ? this.props.location.state !== undefined ?row.discount:row.discountPercentage:row.discountPercentage 
                                          // : ""
                                        }
                                        onChange={this.handleChange({
                                          key: col.type,
                                          row,
                                        })}
                                        className="inputBlockContainer"
                                        readOnly={
                                          col.type === "qty"
                                            ? true
                                            : col.type === "Unit"
                                              ? true
                                              : ""
                                        }
                                        onWheel={e => e.target.blur()}

                                      />
                                      <span>{col.type === "discountPercentage" ? Number(row.discountMode) === 2 ? "%" : "" : ""}</span>
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            </>
                          ) : (
                            <td>
                              {(this.props.Inward_LineItems.inwardSequenceNo === "" ||
                                this.props.Inward_LineItems.inwardSequenceNo === null ||
                              
                                this.props.Inward_LineItems.inwardSequenceNo === undefined)  && this.props.source !== "WITHWO" && this.props.source !== "WITHPO" ?
                                <>
                                  {this.returnDynamicSlect(row,col)}
                                </> : <>
                                  {this.props.location.state !== undefined ? (
                                    <div className="quantityDropdownBlock">
                                      <Grid className="selectAndTextfield">
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        > 
                                          {col.type === "Reason" ? (

                                            <DynamicSelect
                                              arrayOfData={
                                                col.type === "Reason"
                                                  ? ReasonTypes
                                                  : []
                                              }
                                              className="dropdownBlockContainer"
                                              onSelectChange={
                                                this.handleChangedropdown
                                              }
                                              isDropdownOpen={this.state.isDropdownOpen}
                                              handleMenuOpen={this.handleMenuOpen}
                                              selected={
                                                col.type === "Status"
                                                  ? row.status > 1
                                                    ? row.status
                                                    : 3
                                                  : col.type === "Reason"
                                                    ? row.reason
                                                    : ""
                                              }
                                              name={col.type}
                                              row={row}
                                              update={
                                                row.status || row.reason ? 1 : 0
                                              }
                                              isDisabled={this.FieldDisable(row, col)}
                                            />
                                          ) : (
                                            <>
                                              
                                              <input
                                                type="text"
                                                name={col.type}
                                                id={col.no}
                                                readOnly
                                                value={
                                                  col.type === "department"
                                                    ? row.departmentName
                                                    : col.type === "itemGroup"
                                                      ? row.itemGroupName
                                                      : col.type === "item"
                                                        ? row.itemName
                                                        : col.type === "Tax"
                                                          ? row.taxName
                                                          : col.type === "BudgetHead"
                                                            ? row.budgetHeadName
                                                            : col.type === "Reason"
                                                              ? row.reason
                                                              : col.type === "discountMode"
                                                                ? row.discountMode !== null ? row.discountMode === 1 ? "Amount" : "Percentage" : ""
                                                                : ""
                                                }

                                                onChange={this.handleChange({
                                                  key: col.type,
                                                  row,
                                                })}
                                                className="inputBlockContainer"
                                                style={{
                                                  textAlign: col.type === "Rate" || col.type === "qty" ? "right" : "", paddingRight: "8px",
                                                  width: col.type == "item" ? "400px" : ""
                                                }}

                                              />
                                            </>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </div>
                                  ) : (
                                    <div className="quantityDropdownBlock">
                                      <Grid className="selectAndTextfield">
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                      
                                          <DynamicSelect
                                            arrayOfData={
                                              col.type === "department"
                                                ? DepartmentList
                                                : col.type === "BudgetHead"
                                                  ? this.state.parentBudgetHeadsList
                                                  : col.type === "itemGroup"
                                                    ? AllItemGroupMasterList
                                                    : col.type === "item"
                                                      ? this.state.itemsList
                                                      : col.type === "Reason"
                                                        ? ReasonTypes
                                                        : []
                                            }
                                            className="dropdownBlockContainer"

                                            onSelectChange={
                                              this.handleChangedropdown
                                            }
                                            isDropdownOpen={this.state.isDropdownOpen}
                                            handleMenuOpen={this.handleMenuOpen}
                                            selected={
                                              col.type === "department"
                                                ? row.departmentId
                                                : col.type === "itemGroup"
                                                  ? row.itemGroup
                                                  : col.type === "item"
                                                    ? row.itemId
                                                    : col.type === "Tax"
                                                      ? row.tax
                                                      : col.type === "BudgetHead"
                                                        ? row.budgetHeadId

                                                        : col.type === "Reason"
                                                          ? row.reason
                                                          : ""
                                            }

                                            name={col.type}
                                            row={row}
                                            update={
                                              row.itemGroup ||
                                                row.departmentId ||
                                                row.itemId ||
                                                row.budgetHeadId ||
                                                row.tax ||
                                                row.reason
                                                ? 1
                                                : 0
                                            }

                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  )}
                                </>}
                            </td>
                          )
                        )}
                    </>
                    <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title="Delete"
                      >
                        <IconButton
                          onClick={() => { 
                            this.onDelLineItem(row);
                          }}
                          disabled={
                            Inward_LineItems.length === 1 ? true :
                            lastLineItems === row ?true: false
                          }
                        >
                          <DeleteIcon className="deleteDisplayButton" />
                        </IconButton>
                      </Tooltip>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

         
            <>
              <div className="consoleFormContainer">
                <div style={{ height: "15px" }}></div>
                <div className="consoleFormBlock">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        display="grid"
                        justifyContent={"space-between"}
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={2}
                      >
                        <Box gridColumn="span 9">
                          <Box
                            display="grid"
                            justifyContent={"space-between"}
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={2}
                          >
                            <Box gridColumn="span 4">
                              <div className="consoleTextFieldBlock">
                                <TextFieldNormal
                                  type="textarea"
                                  label="Internal Note"
                                  value={this.state.internalNote}
                                  onChange={this.handleChangetextAreas(
                                    "internalNote"
                                  )}
                                />
                              </div>
                            </Box>
                          </Box>
                        </Box>
                        <Box gridColumn="span 3">
                          <div className="consoleTextFieldBlock">
                            <TableContainer className="amountTable">
                              <Table aria-label="simple table">
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      component="td"
                                      scope="row"
                                      className="noBorder"
                                    >
                                      Total IGST:
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className="noBorder"
                                    >
                                      <strong>
                                        {parseFloat(totalIgst).toFixed(2)}
                                      </strong>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      component="td"
                                      scope="row"
                                      className="noBorder"
                                    >
                                      Total SGST:
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className="noBorder"
                                    >
                                      <strong>
                                        {" "}
                                        {parseFloat(totalCgst).toFixed(2)}
                                      </strong>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      component="td"
                                      scope="row"
                                      className="noBorder"
                                    >
                                      Total CGST:
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className="noBorder"
                                    >
                                      <strong>
                                        {parseFloat(totalSgst).toFixed(2)}
                                      </strong>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      component="td"
                                      scope="row"
                                      className="noBorder"
                                    >Net Total:

                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className="noBorder"
                                    >
                                      <strong>
                                        {" "}
                                        {/* {parseFloat(totalAmount).toFixed(2)} */}
                                        {parseFloat(totalResult).toFixed(2)}
                                      </strong>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      component="td"
                                      scope="row"
                                      className="noBorder"
                                    >
                                      Total Amount:
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className="noBorder"
                                    >
                                      <strong>
                                        {parseFloat(Math.round(netTotal)).toFixed(2)}
                                      </strong>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </div>
              {
              this.props.locationState !== undefined &&(
              <div className="consoleFormContainer">
                <Grid container spacing={2}>
                  <Grid item xs={12} className="personalDetailsUpload">
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                      className="ducumentUploadBlock"
                    >
                      <Box gridColumn="span 6">
                        <div className="consoleFormContainer">
                          <div className="consoleFormBlock filesUploadedBlock">
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Box
                                  display="grid"
                                  gridTemplateColumns="repeat(12, 1fr)"
                                  gap={2}
                                >
                                  <Box gridColumn="span 12">
                                    <div className="consoleTextFieldBlock remarksBlock">
                                      <TextArea
                                        label="Internal Note"
                                        value={this.state.internalNote}
                                        onChange={this.handleChangetextAreas(
                                          "internalNote"
                                        )}
                                      />
                                    </div>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </Box>
                      <Box gridColumn="span 6">
                        <div className="consoleFormContainer">
                          <div className="consoleFormBlock filesUploadedBlock">
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Box
                                  display="grid"
                                  gridTemplateColumns="repeat(12, 1fr)"
                                  gap={2}
                                  className="ducumentUploadBlock uploadedContent"
                                >
                                  <Box gridColumn="span 6">
                                    <label>Support Documents:</label>
                                    <div className="personalDetailsUpload">
                                      <div className="ducumentUploadBlock">
                                        <div className="documentUploadContent">
                                          <span className="btn_upload documentUpload">
                                            <img src={panUploadIcon} alt="" />
                                            <input
                                              type="file"
                                              id="imag"
                                              title=""
                                              className="input-img"
                                              onChange={this.UploadDocuments()}
                                            />
                                            Upload Document
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Box
                                  display="grid"
                                  gridTemplateColumns="repeat(12, 1fr)"
                                  gap={2}
                                  className="ducumentUploadBlock uploadedContent"
                                >
                                  {this.state.files_List &&
                                    this.state.files_List.map((item) => (
                                      <>
                                        <Box gridColumn="span 6">
                                          <div>
                                            {/* {item.fileName} */}
                                            <div
                                              className="uploadedInfo"
                                              style={{ display: "flex" }}
                                            >
                                              <img
                                                src={TickIcon}
                                                alt=""
                                                className="tickIcon"
                                                height={"20px"}
                                                width={"20px"}
                                              />
                                              <Tooltip
                                                TransitionComponent={Fade}
                                                TransitionProps={{
                                                  timeout: 600,
                                                }}
                                                title="Click to download File"
                                              >
                                                <div
                                                  className="uplodedFileName"
                                                  onClick={() => {
                                                    getFileDownload(
                                                      serverConfig.SERVER_URL +
                                                      "security-api/api/files/downloadfile/" +
                                                      item.fileUploadId,
                                                      item.fileName,
                                                      item.fileExtension
                                                    );
                                                  }}
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {item.fileName}
                                                </div>
                                              </Tooltip>
                                              <Tooltip
                                                TransitionComponent={Fade}
                                                TransitionProps={{
                                                  timeout: 100,
                                                }}
                                                title="Click to Delete File"
                                              > 
                                                <Button
                                                  onClick={() => {
                                                    this.onDeleteFile(
                                                      item.fileUploadId
                                                    );
                                                  }}
                                                  className="deleteButton"
                                                >
                                                  <img
                                                    src={DeleteIcons}
                                                    alt=""
                                                    height={"20px"}
                                                    width={"20px"}
                                                  />
                                                </Button>
                                              </Tooltip>
                                            </div>
                                          </div>
                                        </Box>
                                      </>
                                    ))}
                                </Box>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box>
                  <Grid spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={2}
                        style={{ marginTop: "15px" }}
                      >
                        <Box gridColumn="span 3">
                          <div className="">
                            <CustomButton
                              label={"CLICK HERE TO SEE THE LOGS"}
                              className="greenButton"
                              handleClick={this.onClickToSeeLogs}
                              type="sumbit"
                            />
                          </div>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="logsContainer">
                    <Grid item xs={12}>
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={2}
                      >
                        <Box gridColumn="span 6">
                          {this.state.Logs &&
                            this.state.Logs.map((item) => {
                              let date = moment(item.actionDate)
                                .format("DD-MM-YYYY")
                                .split("T")
                                .toString();
                              let time = moment(item.actionDate)
                                .format()
                                .split("T", 2);
                              return (
                                <ul>
                                  <li className="logsContent">
                                    <strong>{date}</strong>
                                    <span> </span>
                                    {time[1]}
                                    <span> </span>
                                    {item.remarks}
                                  </li>
                                </ul>
                              );
                            })}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </div>
              )}
            </>
            

        </div>
      </div>
    );
  }
}

const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "department",
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "BudgetHead",
  },

  {
    no: 3,
    component: "DynamicSelect",
    type: "itemGroup",
  },
  {
    no: 4,
    component: "DynamicSelect",
    type: "item",
  },
  {
    no: 5,
    component: "EditableCell",
    type: "qty",
  },
  {
    no: 6,
    component: "EditableCell",
    type: "Unit",
  },
  {
    no: 7,
    component: "EditableCell",
    type: "Rate",
  },
  {
    no: 8,
    component: "EditableCell",
    type: "Tax",
  },
  {
    no: 9,
    component: "DynamicSelect",
    type: "discountMode",
    dropDown: "dropDown",
  },
  {
    no: 10,
    component: "EditableCell",
    type: "discountPercentage",
  },

  {
    no: 11,
    component: "EditableCell",
    type: "Approved Quantity",
  },
  {
    no: 12,
    component: "EditableCell",
    type: "Rejected Quantity",
  },
  {
    no: 13,
    component: "DynamicSelect",
    type: "Reason",
  },

];
const tableHeaders = [
  {
    header: "Department",
  },
  {
    header: "Budget Head",
  },
  {
    header: "Item Group",
  },
  {
    header: "Item",
  },
  {
    header: "Quantity",
  },
  {
    header: "Unit",
  },
  {
    header: "Item Rate",
  },
  {
    header: "Tax",
  },
  {
    header: "Discount Mode",
  },
  {
    header: "Discount/Amount",
  },

  {
    header: "Approved Quantity",
  },
  {
    header: "Rejected Quantity",
  },
  {
    header: "Reason",
  },
  {
    header: "Actions",
  },

];


const ReasonTypes = [

  { label: "Damage", value: "Damage" },
  { label: "Defect", value: "Defect" },
  { label: "Shortage", value: "Shortage" },
  {
    label: "Not as per order specification",
    value: "Not as per order specification",
  },
  { label: "Quality Issue", value: "Quality Issue" },
  { label: "Close to expire", value: "Close to expire" },
  { label: "Expired", value: "Expired" },
];

const discountModeList = [
  {
    label: "Amount",
    name: "Amount",
    value: 1,
  },
  {
    label: "Percentage",
    name: "Percentage",
    value: 2,
  },
];

const mapStatetoProps = (state) => {
  const { itemsListForIndent } = state.itemGroupMasterList;
  const { Inward_LineItems, mifilesList } = state.InwardReducer;
  const { taxList } = state.taxList;
  const { files_List, logs_List } = state.IndentReducer;
  const { Upload_File_List } = state.UploadFileReducer;
  const { departmentList, allItemGroupMaster_List, parentBudgetHeadsList } =
    state.DropDownListReducer;

  return {
    itemsListForIndent,
    Inward_LineItems,
    departmentList,
    allItemGroupMaster_List,
    parentBudgetHeadsList,
    taxList,
    files_List,
    mifilesList,
    Upload_File_List,
    logs_List,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getItemsListForIndent,
    InwardLineItemsProps,
    getDepartmentList,
    allItemGroupMasterList,
    getParentBudgetHeads,
    postUploadFileList,
    deleteFile,
    actions,
    logs,
    getFiles,
    getFilesofInward,
    getPendingStockPoandReceipt,
    getPendingStock,
    getTax_List,
  })(WorkReportLineitems)
);
