import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box, IconButton, Tooltip } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import CustomButton from "../../../components/Buttons/Buttons";
import ClearIcon from "@mui/icons-material/Clear";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import Fade from "@mui/material/Fade";
import PaySchemeAddItems from "./PaySchemeAddItems";
import { getPayschemeList } from "../../../store/HRMS/EmployeeDataBase/actions";

import {
  getDesignation,
  getWorkLocation,
  getPayScheme,
  payWagesModes,
  PaySchemeById,
  AddPayScheme,
  UpdatePayScheme,
} from "../../../store/HRMS/EmployeeDataBase/actions";
import { serverApi } from "../../../helpers/Consts";
import swal from "sweetalert";

class PaySchemeFillDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      currentStep: this.props.currentStep,
      rowId: 0,
      status: "",
      isActive: "",
      statusDesc: "",
      Code: "",
      Name: "",
      Wage_type: "",
      Wage_typevalue: "",
      Description: "",
      designation: "",
      designationValue: "",
      Location: "",
      LocationValue: "",
      copy_from_payscheme: "",
      copy_from_payschemeValue: "",
      paySchemeName: "",
      package_amount: "",
      effective_date: "",
      no_of_elements: "",
      yearly_package: "",
      working_days: "",
      payable_days: "",
      late_coming_days: "",
      ot_days: "",
      calendar_days: "",
      net_ctc: "",
      yearly_gross: "",
      net_payable: "",
      display: false,
      showPayscheme: false,
      calculationError: "",
      DesignationList: [],
      Work_LocationList: [],
      PaySchemeList: [],
      payWagesModesList: [],
      InputValues: [],
      earningList: [],
      deductionList: [],
      summary: [],
      allData: [],
      confirmPopUp: false,
      summary1: [],
      showBtns: true,
      checkCal: false,
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    // if (this.props.location.state) {
    //   this.setState({
    //     InputValues: [],
    //     earningList: [],
    //     deductionList: [],
    //     summary: [],
    //   });
    // }
    const data = {
      activeStatus: "",
      status: "",
    };
    this.props.getPayScheme(
      serverApi.EMPLOYEE_PAY_SCHEME,
      data,
      this.props.history
    );
    this.props.getDesignation(
      serverApi.DESIGNATION + localStorage.getItem("companyId") + "/0",
      this.props.history
    );
    this.props.getWorkLocation(
      serverApi.WORK_LOCATION + localStorage.getItem("companyId"),
      this.props.history
    );
    this.props.payWagesModes(serverApi.ALL_PAY_WAGESMODES, this.props.history);

    if (this.props.location.state) {
      if (this.props.location.state.rowData) {
        let rowData = this.props.location.state.rowData;
        this.setState({
          rowId: rowData.id,
          showPayscheme: true,
          display: true,
          Code: rowData.code,
          Name: rowData.name,
          Description: rowData.description,
          Wage_typevalue: rowData.wageId,
          designation: rowData.designation,
          designationValue: rowData.designation,
          LocationValue: rowData.branchid,
          status: rowData.status,
          statusDesc: rowData.statusDesc,
          activeStatus: rowData.activeStatus,
          copy_from_payschemeValue: rowData.id,
        });
        let PayschemeId = this.props.location.state.rowData.id;
        this.props.PaySchemeById(
          serverApi.PAY_SCHEME_BY_ID + PayschemeId,
          PayschemeId,
          this.props.history
        );
      }
    } else {
      this.setState({
        InputValues: [],
        earningList: [],
        deductionList: [],
        summary: [],
      });
    }
  }
  componentWillReceiveProps(props) {
    if (props.payScheme_List) {
      this.setState({
        PaySchemeList: props.payScheme_List,
      });
    }
    if (props.designation) {
      this.setState({
        DesignationList: props.designation.data,
      });
    }
    if (props.workLocation) {
      this.setState({
        Work_LocationList: props.workLocation.data,
      });
    }
    if (props.payWagesModes_list.data) {
      let payWagesModes_list = [];
      props.payWagesModes_list.data.map((item) => {
        payWagesModes_list.push({
          label: item.name,
          value: item.id,
          name: item.name,
        });
      });
      this.setState({
        payWagesModesList: payWagesModes_list,
      });
    }

    //for create method making the states empty....
    if (!this.props.location.state && !this.state.copy_from_payschemeValue) {
      return;
    }

    if (props.paySchemeByIdList) {
      let InputValueslist = [];
      let earningList = [];
      let deductionList = [];
      let summary = [];
      if (props.paySchemeByIdList.data) {
        props.paySchemeByIdList.data.paySchemeDetailsDTO.forEach(
          (prop, key) => {
            this.setState({
              checkCal: true,
            });
            let obj = {
              id: prop.id,
              componentId: prop.componentId,
              componentName: prop.componentName,
              formula: prop.formula,
              type: prop.type,
              isActive: prop.status,
            };

            if (obj.type === "0") {
              // obj.id = InputValueslist.length;
              InputValueslist.push(obj);
            }
            if (obj.type === "1") {
              // obj.id = earningList.length;
              earningList.push(obj);
            }
            if (obj.type === "2") {
              // obj.id = deductionList.length;
              deductionList.push(obj);
            }
            if (obj.type === "3") {
              // obj.id = summary.length;
              summary.push(obj);
            }
          }
        );

        this.setState({
          InputValues: InputValueslist,
          earningList: earningList,
          deductionList: deductionList,
          summary: summary,
        });
      }
    }

    if (this.state.checkCal === true) {
      if (props.checkScheme) {
        if (props.checkScheme.status === true) {
          const { earningList, deductionList, InputValues, summary } =
            this.state;

          var paySchemeListEarningsData1 = earningList.map((prop) => {
            let amount = "";
            if (props.checkScheme.list)
              props.checkScheme.list.find((o) => {
                if (o.componentId === prop.componentId) {
                  amount = parseFloat(o.amount);
                  return true;
                }
              });
            return { ...prop, value: amount };
          });
          var paySchemeListDeductionData1 = deductionList.map((prop) => {
            let amount = "";
            if (props.checkScheme.list)
              props.checkScheme.list.find((o) => {
                if (o.componentId === prop.componentId) {
                  amount = parseFloat(o.amount);
                  return true;
                }
              });
            return { ...prop, value: amount };
          });
          var paySchemeListNoCalData1 = InputValues.map((prop) => {
            let amount = "";
            if (props.checkScheme.list)
              props.checkScheme.list.find((o) => {
                if (o.componentId === prop.componentId) {
                  amount = parseFloat(o.amount);

                  return true;
                }
              });
            return { ...prop, value: amount, formula: amount };
          });
          var paySchemeListSummaryData1 = summary.map((prop) => {
            let amount = "";
            if (props.checkScheme.list)
              props.checkScheme.list.find((o) => {
                if (o.componentId === prop.componentId) {
                  amount = parseFloat(o.amount);
                  return true;
                }
              });
            return { ...prop, value: amount };
          });
          summary.push(...paySchemeListSummaryData1);

          this.setState({
            earningList: paySchemeListEarningsData1,
            deductionList: paySchemeListDeductionData1,
            InputValues: paySchemeListNoCalData1,
            summary: paySchemeListSummaryData1,
            calculationError: "",
            checkCal: false,
          });
        } else {
          this.setState({ calculationError: this.props.checkScheme.message });
        }
      }
    }
  }

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "copy_from_payscheme") {
      this.props.PaySchemeById(
        serverApi.PAY_SCHEME_BY_ID + selectedValue,
        selectedValue,
        this.props.history
      );
      this.setState({
        copy_from_payscheme: selectedName,
        copy_from_payschemeValue: selectedValue,
        display: true,
      });
    }
    if (name === "Wage_type") {
      this.setState({
        Wage_type: selectedName,
        Wage_typevalue: selectedValue,
      });
    }

    if (name === "designation") {
      this.setState({
        designation: selectedName,
        designationValue: selectedValue,
      });
    }
    if (name === "Location") {
      this.setState({
        Location: selectedName,
        LocationValue: selectedValue,
      });
    }
  };

  checkCalculations = () => {
    let payloadData = {};
    if (this.state.InputValues) {
      this.state.InputValues.map((row) => {
        payloadData = { ...payloadData, [row.componentId]: row.formula };
      });
    }

    if (this.state.earningList) {
      this.state.earningList.map((row) => {
        payloadData = { ...payloadData, [row.componentId]: row.formula };
      });
    }
    if (this.state.deductionList) {
      this.state.deductionList.map((row) => {
        payloadData = { ...payloadData, [row.componentId]: row.formula };
      });
    }
    if (this.state.summary) {
      this.state.summary.map((row) => {
        payloadData = { ...payloadData, [row.componentId]: row.formula };
      });
    }

    if (
      this.state.copy_from_payschemeValue !== "" &&
      this.state.copy_from_payschemeValue !== undefined
    ) {
      this.props.getPayschemeList(
        serverApi.CHECK_PAY_SCHEME +
          "1111/" +
          this.state.copy_from_payschemeValue,
        payloadData,
        this.props.history
      );
      this.setState({
        checkCal: true,
      });
    } else {
      this.props.getPayschemeList(
        serverApi.CHECK_PAY_SCHEME + "1111/" + this.state.rowId,
        payloadData,
        this.props.history
      );
      this.setState({
        checkCal: true,
      });
    }
  };

  onDel = (type, prop) => {
    swal({
      title: "Are you sure?",
      text: "Do You Like To Delete This Value",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        var data = [...this.state[type]];
        data.find((v) => v === prop).isActive = 0;
        this.setState({
          [type]: data,
        });
        /*
        if (type === "prop") {
          // const data = this.state.InputValues.filter((item, i) => i !== index);
          var data = [...this.state.InputValues];
          data.find(v => v === prop).isActive = 0;
          this.setState({
            InputValues: data,
          });
        }
        if (type === "earnings") {
          // const data = this.state.earningList.filter((item, i) => i !== index);
          let data = [...this.state.InputValues];
          data.find(v => v === prop).isActive = 0;
          this.setState({
            earningList: data,
          });
        }
        if (type === "deductions") {
          // const data = this.state.deductionList.filter(
          //   (item, i) => i !== index
          // );
          let data = [...this.state.InputValues];
          data.find(v => v === prop).isActive = 0;
          this.setState({
            deductionList: data,
          });
        }
        if (type === "summary") {
          // const data = this.state.summary.filter((item, i) => i !== index);
          let data = [...this.state.InputValues];
          data.find(v => v === prop).isActive = 0;
          this.setState({
            summary: data,
          });
        }
        */
      }
    });
  };
  onClickNext = () => {
    this.props.history.push("/payscheme");
  };

  addItems = () => {
    this.setState({
      confirmPopUp: true,
    });
  };

  ///function for PopUp
  onclickOk = () => {
    this.setState({
      confirmPopUp: false,
    });
  };
  getFormula = (obj, formulaType) => {
    this.setState({
      confirmPopUp: false,
    });

    if (formulaType === 0) {
      this.setState({
        display: true,
      });
      this.state.InputValues.push(obj);
    }
    if (formulaType === 1) {
      this.setState({
        display: true,
      });
      this.state.earningList.push(obj);
    }
    if (formulaType === 2) {
      this.setState({
        display: true,
      });
      this.state.deductionList.push(obj);
    }
    if (formulaType === 3) {
      this.setState({
        display: true,
      });
      this.state.summary.push(obj);
    }
  };
  onClickSave = () => {
    const {
      Description,
      Code,
      Name,
      Wage_typevalue,
      LocationValue,
      InputValues,
      designationValue,
      earningList,
      deductionList,
      summary,
    } = this.state;

    var PayschemeList = [
      ...InputValues,
      ...earningList,
      ...deductionList,
      ...summary,
    ];

    PayschemeList = PayschemeList.map((prop) => {
      var obj = {
        componentId: prop.componentId,
        formula: prop.formula,
        type: prop.type,
        status: 1,
      };
      return obj;
    });

    var data = {
      paySchemeDTO: {
        code: Code,
        businessunitId: localStorage.getItem("companyId"),
        designation: designationValue,
        branchid: LocationValue,
        description: Description,
        name: Name,
        wageId: Wage_typevalue,
        id: 0,
        payschemeId: 0,
      },
      paySchemeDetailsDTO: PayschemeList,
    };

    // if (
    //   this.state.Name !== "" &&
    //   this.state.Wage_Type !== "" &&
    //   this.state.Code !== ""
    // ) {
    if (this.state.Code === "") {
      swal("Please Enter Code");
      return false;
    }
    if (this.state.Wage_typevalue === "") {
      swal("Please Enter the Wage Type");
      return false;
    }
    if (this.state.Name === "") {
      swal("Please Enter Name");
      return false;
    }
    this.props.AddPayScheme(serverApi.ADD_PAYSCHEME, data, this.props.history);
    this.props.history.push("/payscheme");
    // } else {
    //   swal("Please fill all the mandatory fields");
    // }
  };

  onClickUpdate = (scheme_status, isActive) => {
    const {
      Description,
      Code,
      Name,
      Wage_typevalue,
      earningList,
      deductionList,
      InputValues,
      // status,
      // isActive,
      summary,
      designationValue,
      LocationValue,
    } = this.state;

    var PayschemeList = [
      ...earningList,
      ...deductionList,
      ...InputValues,
      ...summary,
    ];

    PayschemeList = PayschemeList.map((prop) => {
      var obj = {
        id: prop.id,
        componentId: prop.componentId,
        formula: prop.formula,
        paySchemeId: this.state.copy_from_payschemeValue,
        type: parseInt(prop.type),
        status: prop.isActive,
      };
      return obj;
    });

    var data = {
      paySchemeDTO: {
        id: this.state.rowId,
        code: Code,
        businessunitId: localStorage.getItem("companyId"),
        designation: designationValue === "" ? null : designationValue,
        branchid: LocationValue === "" ? null : LocationValue,
        description: Description,
        name: Name,
        wageId: Wage_typevalue,
        status: scheme_status === 32 ? 32 : 1,
        activeStatus: isActive === 0 ? 0 : 1,
      },
      paySchemeDetailsDTO: PayschemeList,
    };

    if (this.state.Code === "") {
      swal("Please Enter Code");
      return false;
    }
    if (this.state.Wage_typevalue === "") {
      swal("Please Enter the Wage Type");
      return false;
    }
    if (this.state.Name === "") {
      swal("Please Enter Name");
      return false;
    }

    this.props.UpdatePayScheme(
      serverApi.UPDATE_PAY_SCHEME,
      data,
      this.props.history
    );
    this.props.history.push("/payscheme");
  };

  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "Code") {
      this.setState({
        Code: event,
        Name: event,
      });
    }
    if (key === "Name") {
      this.setState({
        Name: event,
      });
    }
    if (key === "Description") {
      this.setState({
        Description: event,
      });
    }
    var result = [...this.state.InputValues]; //<- copy roomRent into result
    result = result.map((x) => {
      //<- use map on result to find element to update using id
      if (x.componentId === key) x.formula = event;
      return x;
    });
    this.setState({
      InputValues: result,
    });
  };

  handleKeyDown = (event) => {
    if (event === 32) {
      swal("Space is not Allowed..!!");
    }
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div className="packageMasterContainer">
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        label="Code"
                        required
                        value={this.state.Code}
                        onChange={this.onhandlechange("Code")}
                        handleKeyDown={this.handleKeyDown}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        label="Name"
                        required
                        value={this.state.Name}
                        onChange={this.onhandlechange("Name")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.payWagesModesList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.Wage_typevalue}
                        update={this.props.location.state ? 1 : 0}
                        name="Wage_type"
                        label="Wage Type"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Description"
                        type="textarea"
                        onChange={this.onhandlechange("Description")}
                        value={this.state.Description}
                        rows={5}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.DesignationList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.designation}
                        update={this.props.location.state ? 1 : 0}
                        name="designation"
                        label="Designation"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={
                          this.state.Work_LocationList
                            ? this.state.Work_LocationList.map((item) => ({
                                label: item.address,
                                value: item.customerId,
                                name: item.address,
                              }))
                            : ""
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.LocationValue}
                        update={this.props.location.state ? 1 : 0}
                        name="Location"
                        label="Location"
                      />
                    </div>
                  </Box>
                  {this.state.showPayscheme === false && (
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={
                            this.state.PaySchemeList
                              ? this.state.PaySchemeList.map((item) => ({
                                  label: item.name,
                                  value: item.id,
                                  name: item.name,
                                }))
                              : ""
                          }
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.copy_from_payscheme}
                          update={this.props.location.state ? 1 : 0}
                          name="copy_from_payscheme"
                          label="Copy From Pay Scheme"
                        />
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>

          <div>
            <div className="consoleFormContainer">
              <h5>Input Values</h5>
              <div className="consoleFormBlock">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                      className="contactDetails"
                    >
                      {this.state.InputValues.filter((iv) => iv.isActive).map(
                        (prop, index) => {
                          return (
                            <>
                              {/* {this.state.display === true && ( */}
                              <Box gridColumn="span 3" key={index}>
                                <div className="consoleTextFieldBlock">
                                  <div style={{ display: "flex" }}>
                                    <TextFieldReq
                                      label={prop.componentName}
                                      placeholder="Enter Here"
                                      value={prop.formula}
                                      onChange={this.onhandlechange(
                                        prop.componentId
                                      )}
                                    />
                                    {this.state.activeStatus !== 0 &&
                                      this.state.statusDesc !== "LOCKED" && (
                                        <Tooltip
                                          TransitionComponent={Fade}
                                          TransitionProps={{ timeout: 600 }}
                                          title="Remove"
                                        >
                                          <IconButton
                                            onClick={(e) => {
                                              this.onDel("InputValues", prop);
                                            }}
                                            className="closeButtonForInput"
                                          >
                                            <ClearIcon className="deleteDisplayButton" />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                  </div>
                                </div>
                              </Box>
                              {/* )} */}
                            </>
                          );
                        }
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div style={{ height: "15px" }}></div>
            <div style={{ display: "flex" }}>
              <Grid item xs={6} style={{ margin: "0 5px 0 0" }}>
                <div className="educationalDetailsBlock">
                  <h5>Earnings</h5>
                  <div className="gridContainer">
                    <div className="gridComponent formulaTable">
                      <table>
                        <thead>
                          <tr>
                            <th style={{ width: "20%" }}>Component</th>
                            <th style={{ width: "60%" }}>Formula</th>
                            <th style={{ width: "20%" }}>Value</th>
                            {this.state.activeStatus !== 0 && (
                              <th style={{ width: "20%" }}>Action</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.earningList
                            .filter((iv) => iv.isActive)
                            .map((prop, index) => {
                              return (
                                <tr key={index}>
                                  <td style={{ width: "20%" }}>
                                    {prop.componentName}
                                  </td>
                                  <td style={{ width: "60%" }}>
                                    {prop.formula}
                                  </td>
                                  <td style={{ width: "20%" }}>{prop.value}</td>
                                  {this.state.activeStatus !== 0 &&
                                    this.state.statusDesc !== "LOCKED" && (
                                      <td style={{ width: "20%" }}>
                                        <Tooltip
                                          TransitionComponent={Fade}
                                          TransitionProps={{ timeout: 600 }}
                                          title="Remove"
                                        >
                                          <IconButton
                                            onClick={(e) => {
                                              this.onDel("earningList", prop);
                                            }}
                                            className="closeButtonForInput"
                                          >
                                            <ClearIcon className="deleteDisplayButton" />
                                          </IconButton>
                                        </Tooltip>
                                      </td>
                                    )}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} style={{ margin: "0 0 0 5px" }}>
                <div className="educationalDetailsBlock">
                  <h5>Deductions</h5>
                  <div className="gridContainer">
                    <div className="gridComponent formulaTable">
                      <table>
                        <thead>
                          <tr>
                            <th style={{ width: "20%" }}>Component</th>
                            <th style={{ width: "60%" }}>Formula</th>
                            <th style={{ width: "20%" }}>Value</th>
                            {this.state.activeStatus !== 0 && (
                              <th style={{ width: "20%" }}>Action</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.deductionList
                            .filter((iv) => iv.isActive)
                            .map((prop, index) => {
                              return (
                                <tr key={index}>
                                  <td style={{ width: "20%" }}>
                                    {prop.componentName}
                                  </td>
                                  <td style={{ width: "60%" }}>
                                    {prop.formula}
                                  </td>
                                  <td style={{ width: "20%" }}>{prop.value}</td>
                                  {this.state.activeStatus !== 0 &&
                                    this.state.statusDesc !== "LOCKED" && (
                                      <td style={{ width: "20%" }}>
                                        <Tooltip
                                          TransitionComponent={Fade}
                                          TransitionProps={{ timeout: 600 }}
                                          title="Remove"
                                        >
                                          <IconButton
                                            onClick={(e) => {
                                              this.onDel("deductionList", prop);
                                            }}
                                            className="closeButtonForInput"
                                          >
                                            <ClearIcon className="deleteDisplayButton" />
                                          </IconButton>
                                        </Tooltip>
                                      </td>
                                    )}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Grid>
            </div>
            <div className="consoleFormContainer">
              <h5>Summary</h5>
              <div className="consoleFormBlock">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                      className="contactDetails"
                    >
                      {this.state.summary
                        ? this.state.summary
                            .filter((iv) => iv.isActive)
                            .map((prop, index) => {
                              return (
                                <>
                                  <Box gridColumn="span 3" key={index}>
                                    <div className="consoleTextFieldBlock">
                                      <div style={{ display: "flex" }}>
                                        <TextFieldReq
                                          readOnly={true}
                                          label={prop.componentName}
                                          placeholder="Enter Here"
                                          value={prop.value}
                                          onChange={this.onhandlechange(
                                            "NoOfElements"
                                          )}
                                        />
                                        {this.state.activeStatus !== 0 &&
                                          this.state.statusDesc !==
                                            "LOCKED" && (
                                            <Tooltip
                                              TransitionComponent={Fade}
                                              TransitionProps={{ timeout: 600 }}
                                              title="Remove"
                                            >
                                              <IconButton
                                                onClick={(e) => {
                                                  this.onDel("summary", prop);
                                                }}
                                                className="closeButtonForInput"
                                              >
                                                <ClearIcon className="deleteDisplayButton" />
                                              </IconButton>
                                            </Tooltip>
                                          )}
                                      </div>
                                      <div className="formula">
                                        <strong>Formula:&nbsp;</strong>
                                        <span>{prop.formula}</span>
                                      </div>
                                    </div>
                                  </Box>
                                </>
                              );
                            })
                        : ""}
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </div>
            {this.state.calculationError !== "" && (
              <div>Error Message: {this.state.calculationError}</div>
            )}
          </div>
        </div>
        {this.state.activeStatus !== 0 && (
          <div className="consoleFormButtonBlock addFormulaBlock">
            <CustomButton
              label={"Add Item"}
              className="greenButton"
              handleClick={this.addItems}
            />
            {this.state.confirmPopUp && (
              <CommonPopup>
                <div>
                  <div>
                    <PaySchemeAddItems
                      onclickOk={this.onclickOk}
                      getFormula={this.getFormula}
                      getInputValues={this.getInputValues}
                    />
                  </div>
                </div>
              </CommonPopup>
            )}
          </div>
        )}
        {this.state.activeStatus !== 0 && (
          <div className="consoleFormButtonBlock">
            {this.state.status !== 32 &&
              this.state.isActive !== 0 &&
              this.props.location.state !== undefined && (
                <CustomButton
                  label={"Update"}
                  className="greenBorderButton"
                  handleClick={() => {
                    this.onClickUpdate();
                  }}
                />
              )}
            {this.props.location.state === undefined && (
              <CustomButton
                label={"Save"}
                className="greenBorderButton"
                handleClick={this.onClickSave}
              />
            )}
            {this.state.isActive !== 0 ? (
              <CustomButton
                label={"Check Calculation"}
                className="greenButton"
                handleClick={this.checkCalculations}
              />
            ) : null}

            {this.props.location.state === undefined && (
              <CustomButton
                label={"Cancel"}
                className="greenButton"
                handleClick={() => {
                  this.onClickNext();
                }}
                type="sumbit"
              />
            )}
            {this.state.status !== 32 &&
              this.props.location.state !== undefined && (
                <CustomButton
                  label={"LOCk"}
                  className="greenBorderButton"
                  handleClick={() => {
                    this.onClickUpdate(32);
                  }}
                />
              )}
            {this.state.status === 32 && this.state.isActive !== 0 && (
              <CustomButton
                label={"DEACTIVATE"}
                className="greenBorderButton"
                handleClick={() => {
                  this.onClickUpdate(32, 0);
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    designation,
    workLocation,
    payScheme_List,
    payWagesModes_list,
    paySchemeById,
    paySchemeByIdList,
    checkScheme,
    loading,
  } = state.EmployeeReducer;
  return {
    designation,
    workLocation,
    payScheme_List,
    payWagesModes_list,
    paySchemeById,
    paySchemeByIdList,
    checkScheme,
    loading,
  };
};
export default connect(mapStatetoProps, {
  getPayScheme,
  getDesignation,
  getWorkLocation,
  payWagesModes,
  PaySchemeById,
  getPayschemeList,
  AddPayScheme,
  UpdatePayScheme,
})(withRouter(PaySchemeFillDetails));
