import React, { Component } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export default class InputCounter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || 0
    };
  }

  incrementValue = () => {
    const newValue = this.state.value + 1;
    this.setState({
      value: newValue,
    });
    this.props.handleChange(newValue, this.props.field);
  };

  decrementValue = () => {
    const newValue = Math.max(this.state.value - 1, 0); // Ensure it doesn't go below 0
    this.setState({
      value: newValue,
    });
    this.props.handleChange(newValue, this.props.field);
  };

  render() {
    return (
      <div className="incrementDecrementContainer">
        <label className="probitionBlock">
          {this.props.label}
          {this.props.required && <span className="mandatoryField">*</span>}
        </label>
        <div className="incDecContent">
          <input
            type="text"
            value={this.props.value}
            defaultValue={this.props.value}
            readOnly={true}
            onChange={(event) => this.handleChange(event)}
            className="textfiledContainer"
          />
          <button onClick={this.decrementValue} className="decrementBlock">
            <RemoveIcon />
          </button>
          <button onClick={this.incrementValue} className="incrementBlock">
            <AddIcon />
          </button>
        </div>
      </div>
    );
  }
}
