import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import CustomButton from "../../../components/Buttons/Buttons";
import { getAttendanceSummaryList } from "../../../store/HRMS/AttendanceCalender/actions";
import { Grid, Box } from "@mui/material";
import moment from "moment";
import { viewAttendance } from "../../../store/HRMS/AttendanceCalender/actions";
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import ReactTooltip from "react-tooltip";
const randomDate = (start, end, startHour, endHour) => {
  var date = new Date(+start + Math.random() * (end - start));
  var hour = (startHour + Math.random() * (endHour - startHour)) | 0;
  date.setHours(hour);
  return date;
};

class AttendanceCalendarView extends React.Component {
  calendarComponentRef = React.createRef();
  state = {
    fase: false,
    calendarWeekends: true,
    ebId: localStorage.getItem("ebId"),
    userDit: JSON.parse(localStorage.getItem("authUser")),
    Emp_code: JSON.parse(localStorage.getItem("authUser")).ebNo,
    AttendanceSummaryList: [],
    companyId: localStorage.getItem("companyId"),
    firstDay: moment().startOf("month").format("DD-MM-YYYY"),
    lastDay: moment().endOf("month").format("DD-MM-YYYY"),
    calendarDate: {
      start: new Date(),
      end: new Date(),
    },
    calendarEvents: [],
  };

  handleEventPositioned = (info) => {
    if (info.event.extendedProps.checkIn) {
      info.el.setAttribute(
        "data-tip",
        `<p>${info.event.extendedProps.checkIn}</p>
        <p>${info.event.extendedProps.checkOut}</p>`
      );
      ReactTooltip.rebuild();
    } else {
      info.el.style.borderColor = "red";
    }
  };

  componentWillReceiveProps(props) {
    if (this.state.Emp_code !== null) {
      if (props.view_attendance) {
        const evtData = [];
        const evtDataTime = [];
        var selectedDate = "";
        if (props.view_attendance.data) {
          if (props.view_attendance.data.length !== 0) {
            var checkIn = null;
            props.view_attendance.data.map((row) => {
              if (row.entryTime) {
                var checkInTime = moment(row.entryTime)
                  .format("YYYY/MM/DD h:mm A")
                  .split(" ");
                if (checkInTime) {
                  checkIn = checkInTime[1] + " " + checkInTime[2];
                } else {
                  checkIn = null;
                }
              } else {
                checkIn = null;
              }
              var checkOut = null;
              if (row.exitTime) {
                var checkoutTime = moment(row.exitTime1)
                  .format("YYYY/MM/DD h:mm A")
                  .split(" ");
                if (checkoutTime) {
                  checkOut = checkoutTime[1] + " " + checkoutTime[2];
                }
              }
              if (row.attendanceMark === "P") {
                let event1 = {
                  id: row.attSummaryId,

                  start: row.attendanceDate,
                  allDay: false,
                  editable: false,
                  clickable: false,
                  overlap: true,
                  extendedProps: null,
                };
                evtData.push(event1);
                var event = {
                  id: row.attSummaryId,
                  title: "Present",
                  start: row.attendanceDate,
                  allDay: true,
                  editable: false,
                  clickable: false,
                  overlap: true,
                  borderColor: row.colourForAttendanceSource,

                  color: row.colourForAttendanceSource,
                  extendedProps: {
                    checkIn: checkIn ? " Check in : " + checkIn : " Check in: ",
                    checkOut: checkOut
                      ? " Check out : " + checkOut
                      : " Check out: ",
                  },
                };
                evtData.push(event);
              } else if (row.attendanceMark === "L") {
                let event1 = {
                  id: row.attSummaryId,
                  title: "Check in : " + checkIn + " Check out : " + checkOut,
                  start: row.attendanceDate,
                  allDay: false,
                  editable: false,
                  clickable: false,
                  overlap: true,
                  extendedProps: null,
                };
                evtData.push(event1);
                var Event = {
                  id: row.attSummaryId,
                  title: "Late",
                  start: row.attendanceDate,
                  allDay: true,
                  editable: false,
                  clickable: false,
                  overlap: true,
                  borderColor: row.colourForAttendanceSource,

                  color: row.colourForAttendanceSource,
                  extendedProps: {
                    checkIn: checkIn ? " Check in : " + checkIn : " Check in: ",
                    checkOut: checkOut
                      ? " Check out : " + checkOut
                      : " Check out: ",
                  },
                };
                evtData.push(Event);
              }

              evtDataTime.push(event);
              selectedDate = row.attendanceDate;
            });
            var my_date = selectedDate.split("-");
            var year = parseInt(my_date[0]);
            var month = parseInt(my_date[1]) - 1;

            var alldays = [];

            for (let i = 1; i <= new Date(year, month, 0).getDate(); i++) {
              let date = new Date(year, month, i);

              let sunday = {
                id: "0",
                title: "Holiday",
                start: moment(date).format("YYYY-MM-DD"),

                editable: false,
                clickable: false,
                overlap: true,
                borderColor: "#D9D9D9",
                color: "#F2F2F2",
                extendedProps: null,
              };
              if (date.getDay() == 6) {
                evtData.push(sunday);
              } else if (date.getDay() == 0) {
                evtData.push(sunday);
              } else {
                var today = new Date();
                if (today >= date) {
                  alldays.push(moment(date).format("YYYY-MM-DD"));
                }
              }
            }

            //Only Absents//
            let absents = alldays.filter((itemA) => {
              return !evtDataTime.find((itemB) => {
                if (itemB) {
                  return itemA === itemB.start;
                }
              });
            });

            absents.map((date) => {
              return evtData.push({
                id: "0",
                title: "Absent",
                start: date,
                allDay: true,
                editable: false,
                clickable: false,
                overlap: true,
                borderColor: "red",
                color: "#FFE9ED",
                extendedProps: null,
              });
            });
            this.setState({
              calendarEvents: evtData,
            });
          } else {
            this.setState({
              calendarEvents: this.state.calendarEvents,
            });
          }
        }
      }
    } else {
      this.setState({
        calendarEvents: [],
        calendarWeekends: [],
      });
    }
  }

  componentDidMount() {}
  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "EmpCode") {
      this.setState({
        Emp_code: event,
      });
    }
  };
  handleSearch = () => {
    var first_day = new Date(
      new Date(this.state.calendarDate.start).getFullYear(),
      new Date(this.state.calendarDate.start).getMonth(),
      1
    );
    var last_day = new Date(
      new Date(
        new Date(this.state.calendarDate.start).getFullYear(),
        new Date(this.state.calendarDate.start).getMonth() + 1,
        0
      )
    );
    var newFirstDay = moment(first_day).format("DD-MM-YYYY");
    var newLastDay = moment(last_day).format("DD-MM-YYYY");
    const data = {
      companyId: localStorage.getItem("companyId"),
      ebId: "",
      startingDate: newFirstDay,
      toDate: newLastDay,
      userId: JSON.parse(localStorage.getItem("authUser")).userId,
      subDeptId: 0,
      designationId: 0,
      cataId: 0,
      workType: "",
      ebNo: this.state.Emp_code,
      spell: "",
    };
    this.props.viewAttendance(
      serverApi.ATTENDANCE_LIST,
      data,
      this.props.history
    );
  };
  handleToday = () => {
    this.setState({
      calendarDate: {
        start: new Date(),
        end: new Date(),
      },
    });
  };
  handlePrev = () => {
    this.setState({
      calendarDate: {
        start: new Date(
          this.state.calendarDate.start.setMonth(
            this.state.calendarDate.start.getMonth() - 1
          )
        ),
        end: new Date(
          this.state.calendarDate.end.setMonth(
            this.state.calendarDate.end.getMonth() - 1
          )
        ),
      },
    });
    //call api hear
    var first_day = new Date(
      new Date(this.state.calendarDate.start).getFullYear(),
      new Date(this.state.calendarDate.start).getMonth(),
      1
    );
    var last_day = new Date(
      new Date(
        new Date(this.state.calendarDate.start).getFullYear(),
        new Date(this.state.calendarDate.start).getMonth() + 1,
        0
      )
    );
    var newFirstDay = moment(first_day).format("DD-MM-YYYY");
    var newLastDay = moment(last_day).format("DD-MM-YYYY");
    const data = {
      companyId: localStorage.getItem("companyId"),
      ebId: "",
      startingDate: newFirstDay,
      toDate: newLastDay,
      userId: JSON.parse(localStorage.getItem("authUser")).userId,
      subDeptId: 0,
      designationId: 0,
      cataId: 0,
      workType: "",
      ebNo: this.state.Emp_code,
      spell: "",
    };
    this.props.viewAttendance(
      serverApi.ATTENDANCE_LIST,
      data,
      this.props.history
    );
  };
  handleNext = () => {
    this.setState({
      calendarDate: {
        start: new Date(
          this.state.calendarDate.start.setMonth(
            this.state.calendarDate.start.getMonth() + 1
          )
        ),
        end: new Date(
          this.state.calendarDate.end.setMonth(
            this.state.calendarDate.end.getMonth() + 1
          )
        ),
      },
    });
    var first_day = new Date(
      new Date(this.state.calendarDate.start).getFullYear(),
      new Date(this.state.calendarDate.start).getMonth(),
      1
    );
    var last_day = new Date(
      new Date(
        new Date(this.state.calendarDate.start).getFullYear(),
        new Date(this.state.calendarDate.start).getMonth() + 1,
        0
      )
    );
    var newFirstDay = moment(first_day).format("DD-MM-YYYY");
    var newLastDay = moment(last_day).format("DD-MM-YYYY");
    const data = {
      companyId: localStorage.getItem("companyId"),
      ebId: "",
      startingDate: newFirstDay,
      toDate: newLastDay,
      userId: JSON.parse(localStorage.getItem("authUser")).userId,
      subDeptId: 0,
      designationId: 0,
      cataId: 0,
      workType: "",
      ebNo: this.state.Emp_code,
      spell: "",
    };
    this.props.viewAttendance(
      serverApi.ATTENDANCE_LIST,
      data,
      this.props.history
    );
  };
  render() {
    if (this.state.Emp_code == "") {
      this.state.calendarWeekends = [];
      this.state.calendarEvents = [];
    }
    return (
      <div className="attendanceCalendar">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
              <Box gridColumn="span 3">
                <TextFieldNormal
                  value={this.state.Emp_code}
                  onChange={this.handleChange("EmpCode")}
                />
              </Box>
              <Box gridColumn="span 3">
                <CustomButton
                  label={"Search"}
                  className="greenButton"
                  handleClick={this.handleSearch}
                  type="sumbit"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <div className="demo-app-calendar">
          <FullCalendar
            defaultView="dayGridMonth"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            ref={this.calendarComponentRef}
            weekends={this.state.calendarWeekends}
            events={this.state.calendarEvents}
            eventMouseEnter={this.handleEventPositioned}
            buttonText={{
              today: <span onClick={this.handleToday}>This Month</span>,
              prev: <span onClick={this.handlePrev}>Prev</span>,
              next: <span onClick={this.handleNext}>Next</span>,
            }}
          />
          <ReactTooltip effect={"solid"} html={true} place={"left"} />
        </div>
      </div>
    );
  }

  toggleWeekends = () => {
    this.setState({
      // update a property
      calendarWeekends: !this.state.calendarWeekends,
    });
  };

  gotoPast = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.gotoDate("2000-01-01"); // call a method on the Calendar object
  };
}

const mapStatetoProps = (state) => {
  const { attendance_Summary_List, view_attendance } = state.AttendanceReducer;
  return { attendance_Summary_List, view_attendance };
};

export default withRouter(
  connect(mapStatetoProps, {
    getAttendanceSummaryList,

    viewAttendance,
  })(AttendanceCalendarView)
);
