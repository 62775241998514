import React from "react";
import { Navigation } from "react-minimal-side-navigation";
import Logo from "../assets/images/logo.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi } from "../helpers/Consts";
import Select from "react-select";
import {
  getVendorLeftSideMenu,
} from "../store/Company/BasicDetails/LeftSideBar/actions";
import { getTenantId } from "../helpers/Consts";
import { getConfiguration } from "../store/Global/DropDownApis/actions";
class LeftSidebarForVendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarOpen: true,
      options: [],
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyIdconfig: JSON.parse(localStorage.getItem("companyIdconfig")),
      data: "",
      companyId: localStorage.getItem("companyId")
        ? localStorage.getItem("companyId")
        : 118,
      companyName: "",
      option: 0,
      displayOpenIndent: false,
      option_name: {
      },
    };
  }

  handleChangeDashboard = () => {
    this.props.history.push("/dashboard");
  };

  handleChange = (e) => {
    this.state.userDit.companyDetails.map((i) => {
      if (e.value === i.value) {
        let val = i.value;
        this.setState({
          companyId: i.value,
          companyName: i.name,
          option_name: { value: e.value, label: i.name },
        });
        localStorage.setItem("companyId", val);
        localStorage.setItem("companyName", i.name);
        this.props.getConfiguration(
          serverApi.GET_CONFIGURATION_DATA + val,
          this.props.history
        );

        localStorage.setItem("HrmsAccess", i.hrmsFullAccess);
        this.props.history.push("/dashboard");
      }
    });
    this.props.getVendorLeftSideMenu(serverApi.VENDOR_LEFT_SIDE_MENU);
  };

  componentDidMount() {
    var i = 0;
    this.state.userDit.companyDetails.map((item) => {
      if (i === 0) {
        if (!localStorage.getItem("companyId")) {
          localStorage.setItem("companyId", item.value);
          localStorage.setItem("HrmsAccess", item.hrmsFullAccess);
          this.props.getConfiguration(
            serverApi.GET_CONFIGURATION_DATA + item.value,
            this.props.history
          );
        }
      }
      i++;
    });

    this.setState({
      options: this.state.userDit.companyDetails.map((item) => ({
        label: item.label,
        value: item.value,
      })),
    });
    if (localStorage.getItem("companyId")) {
      this.state.userDit.companyDetails.map((elem) => {
        if (localStorage.getItem("companyId") == elem.value) {
          this.setState({
            option_name: { value: elem.value, label: elem.name },
          });
        }
      });
    }
  }
  componentWillReceiveProps(props) {
    if (props.ConfigurationApi) {
      if (props.ConfigurationApi.configList) {
        props.ConfigurationApi.configList.map((item) => {
          if (item.configParameter === "openIndent") {
            if (item.configValue) {
              if (JSON.parse(item.configValue).openIndent === "1") {
                this.setState({
                  displayOpenIndent: true,
                });
              }
            }
          }
        });
      }
    }
  }
  render() {
    const leftsideMenu = [];
    if (this.props.vendorLeftsideMenu) {
      if (this.props.vendorLeftsideMenu.data === undefined) {
      } else {
        if (this.props.vendorLeftsideMenu.data.Data !== undefined) {
          this.props.vendorLeftsideMenu.data.Data.map((item) => {
            var submenu = [];
            if (item.subMenus) {
              item.subMenus.map((data) => {
                // if (data.p2DisplayMenu === 1) {
                var submenuitems = {
                  title: data.menuName,
                  itemId: "/" + data.menuPath,
                  elemBefore: () => <img src={data.menuIcon} alt="" />,
                };
                if (item.menuId !== 68 && item.menuId !== 97) {
                  if (this.state.displayOpenIndent === true) {
                    if (item.menuId === 65) {
                      if (data.menuId !== 633) {
                        submenu.push(submenuitems);
                      }
                    } else {
                      submenu.push(submenuitems);
                    }
                  } else {
                    submenu.push(submenuitems);
                  }
                }
              });
            }
            // if(item.p2DisplayMenu === 1){
            var menuitem = {
              title: item.menuName,
              itemId: "/" + item.menuPath,
              elemBefore: () => (
                <img src={item.menuIcon} alt="" width="25px" height="25px" />
              ),
              subNav: submenu,
            };
            leftsideMenu.push(menuitem);
            // }
          });
        }
      }
    }
    return (
      <React.Fragment>
        <div className="leftSideBarContainer">
          <div className="menuAccordion">
            <div className="logoBlock">
              <img src={Logo} alt="" onClick={this.handleChangeDashboard} />
            </div>
            <div className="react-dropdown-select">
              <Select
                className="select"
                noDataRenderer={this.customNoDataRenderer}
                onChange={this.handleChange}
                options={this.state.options}
                defaultValue={this.state.option[1]}
                value={this.state.option_name}
                classNamePrefix="companyDropDown"
              />
            </div>
            <div
              className={`navContainer fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 ease-out transform translate-x-0 bg-white border-r-2 lg:translate-x-0 lg:static lg:inset-0 ${
                this.state.isSidebarOpen
                  ? "ease-out translate-x-0"
                  : "ease-in -translate-x-full"
              }`}
            >
              {leftsideMenu.length > 0 ? (
                <div></div>
              ) : (
                <div
                  style={{ textAlign: "center", color: "#FFF", fontSize: 10 }}
                >
                  <i
                    className="fa fa-refresh fa-spin"
                    style={{ marginRight: "5px" }}
                  />{" "}
                  Menu Loading...
                </div>
              )}
              <Navigation
                activeItemId={this.props.location.pathname}
                onSelect={({ itemId }) => {
                  if (itemId === 68 || itemId === 97) {
                    window.open(
                      `http://data.${getTenantId(window.location.hostname)}/`
                    );
                    this.props.history.push("dashboard");
                  }
                  else {
                    this.props.history.push(itemId);
                  }
                }}
                items={leftsideMenu ? leftsideMenu : []}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStatetoProps = (state) => {
  const { vendorLeftsideMenu, loading } = state.LeftSideMenuReducer;
  const { ConfigurationApi } = state.DropDownListReducer;
  return { vendorLeftsideMenu, loading, ConfigurationApi };
};

export default withRouter(
  connect(mapStatetoProps, {
    getVendorLeftSideMenu,
    getConfiguration,
  })(LeftSidebarForVendor)
);
