import {
  JUTE_PO_LIST,
  JUTE_PO_CREATE,
  JUTE_PO_VIEW_BY_ID,
  GET_JUTE_QUANTITY_BY_JUTE_TYPE,
  JUTE_PO_UPDATE,
  GET_INDENT_DATA,
  JUTE_INDENT_VIEW_BY_ID_FOR_PO,
  GET_LATEST_MARKET_RATE,
  GET_SUPPORT_FILES,
  JUTEPO_UPLOAD_FILES,
  JUTE_PURCHASE_DELETE_FILE,
  
} from "./actionTypes";
import { all, call, fork, takeEvery, put, select } from "redux-saga/effects";
import { postAdd, getList, getListurl } from "../../../helpers/Server_Helper";
import { attachNextYear } from "../../../Utilities/helper"
import {
  JutePOListSuccessfull,
  jutePOViewByIdSuccessfull,
  GetPoLineItems,
  getJuteQuantityByJuteTypeSuccessfull,
  getJutePOHeader,
  GetIndentDataSuccessfull,
  juteIndentViewByIdForPOSuccessfull,
  getLatestMarketRateSuccessfull,
  UploadJutePOFilesSuccessfull,
  getSupportFilesSuccessfull,
  getSupportFiles,
  JutePODeleteFilesSuccessfull
} from "./actions";
import {
  juteGateEntryUpdate,
  LineItemsGateEntryPros,
} from "../JuteGateEntry/actions";
import { showSpinner, hideSpinner, serverApi } from "../../../helpers/Consts";
import { getFiles } from "../../Purchase/Indent/actions";
import moment from "moment";
import swal from "sweetalert";
import {
  getSupplierByMukamIdList,
  getBrokerBySupplierIdList,
} from "../../MasterDropdownApis/actions";
import { isEmpty, limitDecimals } from "../../../Utilities/helper";

function* JutePoSagaList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(JutePOListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* CreateJutePo({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/jute_purchase_order");
    } else {
      swal(response.data.message, { icon: "error" });
    }
    hideSpinner();
  } catch (error) {
    swal("error:" + error, { icon: "danger" });
    hideSpinner();
  }
}

function* UpdateJutePo({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/jute_purchase_order");
    } else {
      swal(response.data.message, { icon: "error" });
    }
    hideSpinner();
  } catch (error) {
    swal("error:" + error, { icon: "danger" });
    hideSpinner();
  }
}

function* ViewByIdJutePO({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    if (response) {
      if (response.poHeader) {
        hideSpinner();
        yield put(jutePOViewByIdSuccessfull({ response }));
        yield put(
          getSupplierByMukamIdList(
            serverApi.GET_SUPPLIER_BY_MUKAM_ID +
              response.poHeader.mukam +
              "/company/" +
              localStorage.getItem("companyId")
          )
        );
        yield put(
          getBrokerBySupplierIdList(
            serverApi.GET_BROKER_BY_SUPPLIER + response.poHeader.supplierId
          )
        );
      }
      if (response.poLineItemVo) {
        if (response.poLineItemVo.length !== 0) {
          var JutePOLineItemsData = [];
          response.poLineItemVo.map((item, i) => {
            JutePOLineItemsData.push({
              id: i,
              jutepoLineItemId:item.id,
              itemId: item.itemId,
              itemDesc: item.itemDesc,
              "itemCode":item.itemGroupId+item.itemId,
              qualityCode: item.qualityCode,
              qualityCodeName: item.juteQuality,
              stock: 0,
              amount: limitDecimals(Number(item.actualQuantity)*Number(item.rate)),
              uom: "",
              unitId: item.unitId,
              quantity: item.quantity,
              rate: item.rate,
              allowableMoisturePercentage: limitDecimals(item.allowableMoisturePercentage,2),
              tax: item.tax,
              valueWithoutTax: item.valueWithoutTax,
              cTax: item.cTax,
              sTax: item.sTax,
              iTax: item.iTax,
              bale: item.bale,
              loose: item.loose,
              poId: item.poId,
              deptId: item.deptId,
              valueWithTax: item.valueWithTax,
              indentId: item.indentId,
              actualQuantity: item.actualQuantity,
              itemGroupId: item.itemGroupId,
              discount: item.discount,
              taxId: item.taxId,
              taxPercentage: item.taxPercentage,
              deptName: item.deptName,
              juteQuality: item.juteQuality,
              hsnCode: item.hsnCode,
              marka: item.marka,
              cropYear: attachNextYear(item.cropYear),
              gatePoPlaced: item.gatePoPlaced,
              remaingQuantity: item.remaingQuantity,
              lastPurchaseRate: item.lastPurchaseRate,
              lastPurchaseDate: item.lastPurchaseDate,
              lastPurchaseSupp: item.lastPurchaseSupp,
              stRemQty: item.stRemQty,
              pendingAtRcpt: item.pendingAtRcpt,
              itemGroupName: item.itemGroupName,
              QuantityListData: [],
              isActive:item.isActive

            });
          });
          JutePOLineItemsData.push({
            id: JutePOLineItemsData.length + 1,
            jutepoLineItemId:"",
            itemId: "",
            itemDesc: "",
            "itemCode":"",
            qualityCode: "",
            qualityCodeName: "",
            unitId: "",
            quantity: "",
            rate: "",
            allowableMoisturePercentage: 0.00,
            tax: 0,
            valueWithoutTax: "",
            cTax: 0,
            sTax: 0,
            iTax: 0,
            bale: 0,
            loose: 0,
            poId: null,
            deptId: "",
            valueWithTax: 0,
            indentId: "",
            actualQuantity: "",
            itemGroupId: "",
            discount: 0,
            taxId: 0,
            taxPercentage: 0,
            deptName: "",
            juteQuality: "",
            hsnCode: "",
            marka: "",
            cropYear: "",
            gatePoPlaced: 0,
            remaingQuantity: 0,
            lastPurchaseRate: null,
            lastPurchaseDate: null,
            lastPurchaseSupp: null,
            stRemQty: 0,
            pendingAtRcpt: 0,
            itemGroupName: "",
            stock: 0,
            amount: "",
            QuantityListData: [],
            uom: "",
            isActive:1
          });
          yield put(GetPoLineItems(JutePOLineItemsData));
        }
        var TotalNet =  response.poLineItemVo.filter((item) => item.isActive !== 0 && item.itemId !=="").map((p) => (limitDecimals(Number(p.actualQuantity)*Number(p.rate)) == null ? 0 : limitDecimals(Number(p.actualQuantity)*Number(p.rate)))).reduce((amount, ind) => parseFloat(amount) + parseFloat(ind));
        var TotalWeight =  response.poLineItemVo.filter((item) => item.isActive !== 0 && item.itemId !=="").map((p) => (p.actualQuantity == null ? 0 : p.actualQuantity)).reduce((actualQuantity, ind) => parseFloat(actualQuantity) + parseFloat(ind));
      }
      if (response.poHeader) {

        var ApproveButton = false;
        var RejectButton = false;
        var UpdateButton = true;
        var EditButton = false;

        if (response.poHeader.approveButton === true) {
          if (
            response.poHeader.status === "1"||
            response.poHeader.status === "17" ||
            response.poHeader.status === "18" ||
            response.poHeader.status === "19" ||
            response.poHeader.status === "20"
          ) {
            ApproveButton = true;
            RejectButton = true;
            UpdateButton = true;
            EditButton = true;
          }
      
        } else if (
          response.poHeader.status === "1"||
          response.poHeader.status === "17" ||
          response.poHeader.status === "18" ||
          response.poHeader.status === "19" ||
          response.poHeader.status === "20"
        ) {
          UpdateButton = true;
          EditButton = true;
        }
  
        if (
          response.poHeader.status === "4" ||
          response.poHeader.status === "3" ||
          response.poHeader.status === "5"
        ) {
          ApproveButton = false;
          RejectButton = false;
          UpdateButton = false;
          EditButton = false;
        }
  
        if(!isEmpty(response.poHeader.poDate)){
          var createdDate = moment(response.poHeader.poDate, "DD-MM-YYYY", true).format("YYYY-MM-DD")
             }
        const Header = {
          hdrId: response.poHeader.hdrId,
          id: response.poHeader.id,
          type: response.poHeader.type,
          status: response.poHeader.status,
          statusName: response.poHeader.statusName,
          submitter: response.poHeader.submitter,
          finnacialYear: response.poHeader.finnacialYear,
          poDate:createdDate,
          footerNote: response.poHeader.footerNote,
          supplierId: response.poHeader.supplierId,
          suppCode: response.poHeader.suppCode,
          mukam: response.poHeader.mukam,
          deliveryAddress: response.poHeader.deliveryAddress,
          tax: response.poHeader.tax,
          valueWithTax: response.poHeader.valueWithTax,
          valueWithoutTax: response.poHeader.valueWithoutTax,
          discount: response.poHeader.discount,
          frieghtCharge: response.poHeader.frieghtCharge,
          deliveryTimeline: response.poHeader.deliveryTimeline,
          juteUnit: response.poHeader.juteUnit,
          vehicleTypeId: response.poHeader.vehicleTypeId,
          vehicleTypeName:response.poHeader.vehicleTypeName,
          vehicleQuantity: response.poHeader.vehicleQuantity,
          brokerId: response.poHeader.brokerId,
          brokerName: response.poHeader.brokerName,
          modOn: response.poHeader.modOn,
          modBy: response.poHeader.modBy,
          weight: response.poHeader.weight,
          creditTerm: response.poHeader.creditTerm,
          remarks: response.poHeader.remarks,
          indentTypeId: response.poHeader.indentTypeId,
          exceptedDate: response.poHeader.exceptedDate,
          indentNum: response.poHeader.indentNum,
          cTax: response.poHeader.cTax,
          sTax: response.poHeader.sTax,
          iTax: response.poHeader.iTax,
          createdBy: response.poHeader.createdBy,
          createDateTime: response.poHeader.createDateTime,
          statusName: response.poHeader.statusName,
          approveButton: response.poHeader.approveButton,
          taxType: response.poHeader.taxType,
          companyId: response.poHeader.companyId,
          withOrWithout: response.poHeader.withOrWithout,
          poComSeq: response.poHeader.poComSeq,
          channelCode: response.poHeader.channelCode,
          contrctNo: response.poHeader.contrctNo,
          contractDate: response.poHeader.null,
          currencyCode: response.poHeader.contractDate,
          conversationRate: response.poHeader.conversationRate,
          brokerageRate: response.poHeader.brokerageRate,
          brokeragePercentage: response.poHeader.brokeragePercentage,
          penality: response.poHeader.penality,
          branchId: response.poHeader.branchId,
          projectId: response.poHeader.projectId,
          internalNote: response.poHeader.internalNote,
          poValidDate: response.poHeader.poValidDate,
          billingBranchId: response.poHeader.billingBranchId,
          rqName: response.poHeader.rqName,
          suppName: response.poHeader.suppName,
          contracDatedes: response.poHeader.contracDatedes,
          poValidDateDes: response.poHeader.poValidDateDes,
          supplierAddress: response.poHeader.supplierAddress,
          supplierPhoneNo: response.poHeader.supplierPhoneNo,
          supplierGst: response.poHeader.supplierGst,
          email: response.poHeader.email,
          branchname: response.poHeader.branchname,
          cellPhone: response.poHeader.cellPhone,
          contactPerson: response.poHeader.contactPerson,
          modOndes: response.poHeader.modOndes,
          indentHdrId: response.poHeader.indentHdrId,
          serviceUrl: response.poHeader.serviceUrl,
          tenantId: response.poHeader.tenantId,
          srcCompanyId: response.poHeader.srcCompanyId,
          mukamName: response.poHeader.mukamName,
          JutePoType: response.poHeader.withOrWithout == 0 ?"WITHINDENT":"WITHOUTINDENT",
          files_List: [],
          weightTotal:limitDecimals(TotalWeight,2),
          netTotal:limitDecimals(Number(TotalNet)+Number(response.poHeader.frieghtCharge),2),
          ApproveButton :ApproveButton,
          RejectButton : RejectButton,
          UpdateButton : UpdateButton,
          EditButton : EditButton
        };
        yield put(getJutePOHeader(Header));
      }
    }
  } catch (error) {
    swal("error:" + error, { icon: "danger" });
    hideSpinner();
  }
}

function* getJuteQuantityByJuteTypeData({ payload: { url, rowId } }) {
  console.log(url, rowId);
  const { JutePoLineItems } = yield select((state) => state.JutePoReducer);
  try {
    const response = yield call(getListurl, url, rowId);
    if (response) {
      if (response.data) {
        if (response.data.length !== 0) {
          yield put(getJuteQuantityByJuteTypeSuccessfull({ response }));
          if (response) {
            let ListFilter = response.data.filter((data) => {
              if (data.value !== 0) {
                return data;
              }
            });
            var list = [];
            ListFilter.map((prop) => {
              list.push({
                label: prop.label,
                name: prop.name,
                value: prop.value,
              });
            });

            if (JutePoLineItems) {
              var LineItemsRecords = [];
              if (JutePoLineItems.length !== 0) {
                LineItemsRecords = JutePoLineItems.map((item) => {
                  if (rowId === item.id) {
                    item.QuantityListData = list;
                  }
                  return item;
                });
                yield put(GetPoLineItems(LineItemsRecords));
              }
            }
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* getLatestMarketRateData({ payload: { url, rowId } }) {
  console.log(url, rowId);
  const { JutePoLineItems } = yield select((state) => state.JutePoReducer);
  try {
    const response = yield call(getListurl, url, rowId);
    if (response) {
      if (response.data) {
        if (response.data.length !== 0) {
          yield put(getLatestMarketRateSuccessfull({ response }));
          if (response) {
          if (response.data) {
            if (response.data.data) {
            if (JutePoLineItems) {
              var LineItemsRecords = [];
              if (JutePoLineItems.length !== 0) {
                LineItemsRecords = JutePoLineItems.map((item) => {
                  if (rowId === item.id) {
                    item.lastPurchaseRate= response.data.data.latestRate
                    item.lastPurchaseDate= response.data.data.latestRateDate
               
                  }
                  return item;
                });
                yield put(GetPoLineItems(LineItemsRecords));
              }
            }
          }}}
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function* GetIndentApprovedData({ payload: { url,history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(GetIndentDataSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* ViewByIdJuteIndentForPO({ payload: { url, history } }) {
  const { JutePoLineItems,JutePoHeader } = yield select((state) => state.JutePoReducer);
  try {
    showSpinner();
    const response = yield call(getListurl, url);
    yield put(juteIndentViewByIdForPOSuccessfull({ response }));
    if (response) {
    if (response.data) {
    hideSpinner();
      if (response.data.indentItems) {
        if (response.data.indentItems.length !== 0) {
          var JutePOLineItemsData = [];
          response.data.indentItems.map((item, i) => {
            JutePOLineItemsData.push({
              id: i,
              itemId: item.itemId,
              itemDesc: item.itemIdDesc,
              itemCode:item.itemGroupId+ item.itemId,
              qualityCode: item.qualityCode,
              qualityCodeName: item.qualityCodeDesc,
              stock: item.stock,
              amount:limitDecimals(Number(item.indentQuantity)*Number(item.rate)),
              uom: "",
              unitId: item.unitId,
              quantity: item.noOfBales,
              rate: item.rate,
              allowableMoisturePercentage:0.00,
              tax: item.tax,
              valueWithoutTax: item.valueWithoutTax,
              cTax:"",
              sTax:"",
              iTax:"",
              bale: "",
              loose:"",
              poId:"",
              deptId: "",
              valueWithTax: "",
              indentId:  item.indentLineItemId,
              actualQuantity: item.indentQuantity,
              itemGroupId: item.itemGroupId,
              discount:"",
              taxId: item.taxId,
              taxPercentage: item.taxPercentage,
              deptName:"",
              juteQuality: item.juteQuality,
              hsnCode: item.hsnCode,
              marka:"",
              cropYear:"",
              gatePoPlaced:"",
              remaingQuantity:"",
              lastPurchaseRate:"",
              lastPurchaseDate:"",
              lastPurchaseSupp: "",
              stRemQty: "",
              pendingAtRcpt:"",
              itemGroupName:"",
              QuantityListData: [],
              "isActive":1
            });
          });
          JutePOLineItemsData.push({
            id: JutePOLineItemsData.length + 1,
            itemId: "",
            itemDesc: "",
            "itemCode":"",
            qualityCode: "",
            qualityCodeName: "",
            unitId: "",
            quantity: "",
            rate: "",
            allowableMoisturePercentage: 0.00,
            tax: 0,
            valueWithoutTax: "",
            cTax: 0,
            sTax: 0,
            iTax: 0,
            bale: 0,
            loose: 0,
            poId: null,
            deptId: "",
            valueWithTax: 0,
            indentId: "",
            actualQuantity: "",
            itemGroupId: "",
            discount: 0,
            taxId: 0,
            taxPercentage: 0,
            deptName: "",
            juteQuality: "",
            hsnCode: "",
            marka: "",
            cropYear: "",
            gatePoPlaced: 0,
            remaingQuantity: 0,
            lastPurchaseRate: null,
            lastPurchaseDate: null,
            lastPurchaseSupp: null,
            stRemQty: 0,
            pendingAtRcpt: 0,
            itemGroupName: "",
            stock: 0,
            amount: "",
            QuantityListData: [],
            uom: "",
            "isActive":1

          });
          yield put(GetPoLineItems(JutePOLineItemsData));
        }
        var TotalWeight =  response.data.indentItems
        .filter((item) => item.isActive != 0)
        .map((p) => (p.indentQuantity == null ? 0 : p.indentQuantity))
        .reduce((indentQuantity, ind) => parseFloat(indentQuantity) + parseFloat(ind));
      }
      if (response.data.indentHeader) {
        yield put(
          getSupplierByMukamIdList(
            serverApi.GET_SUPPLIER_BY_MUKAM_ID +
            response.data.indentHeader.mukamNo +
              "/company/" +
              localStorage.getItem("companyId")
          )
        );
        const Header = {
          ...JutePoHeader,
          hdrId: "",
          id:"",
          type:response.data.indentHeadertype,
          status:response.data.indentHeader.status,
          statusName: "",
          submitter: response.data.indentHeader.submitter,
          finnacialYear: response.data.indentHeader.finnacialYear,
          // poDate: "",
          footerNote: "",
          supplierId: "",
          suppCode: "",
          mukam:response.data.indentHeader.mukamNo,
          deliveryAddress: "",
          tax:"",
          valueWithTax: "",
          valueWithoutTax:"",
          discount: "",
          frieghtCharge: "",
          deliveryTimeline: "15",
          juteUnit: response.data.indentHeader.unitConversion,
          vehicleTypeId: response.data.indentHeader. vehicleTypeId,
          vehicleQuantity: response.data.indentHeader. vehicleQuantity,
          brokerId: "",
          brokerName:"",
          modOn: response.data.indentHeader. modOn,
          modBy: response.data.indentHeader.modBy,
          weight: "",
          creditTerm: "",
          remarks:response.data.indentHeader. remarks,
          indentTypeId:response.data.indentHeader.hdrId,
          exceptedDate: moment().add(15, "days").format("DD-MM-YYYY"),
          indentNum:response.data.indentHeader.id,
          cTax: "",
          sTax: "",
          iTax: "",
          createdBy: "",
          createDateTime:"",
          statusName:"",
          approveButton: "",
          taxType: "",
          companyId: response.data.indentHeader. companyId,
          withOrWithout: "",
          poComSeq:"",
          channelCode: "",
          contrctNo:"",
          contractDate: "",
          currencyCode: "",
          conversationRate:"",
          brokerageRate:"",
          brokeragePercentage: "",
          penality:"",
          // branchId:response.data.indentHeader. branchId,
          projectId:response.data.indentHeader.projectId,
          internalNote:  response.data.indentHeader.internalNote,
          poValidDate: "",
          billingBranchId:"",
          rqName: "",
          suppName:"",
          contracDatedes: "",
          poValidDateDes:"",
          supplierAddress:"",
          supplierPhoneNo:"",
          supplierGst:"",
          email:"",
          branchname:"",
          cellPhone:"",
          contactPerson:"",
          modOndes:"",
          indentHdrId:response.data.indentHeader.hdrId,
          serviceUrl:"",
          tenantId:"",
          srcCompanyId:"",
          // mukamName:response.data.indentHeader.mukamName,
          mukamName:"",

          JutePoType:"WITHINDENT",
          files_List: [],
          weightTotal:TotalWeight == null ? "0.00":limitDecimals(TotalWeight,2),
          netTotal:"0.00",

        };
        yield put(getJutePOHeader(Header));
      }
    }}
  } catch (error) {
    // swal("error:" + error, { icon: "danger" });
    hideSpinner();
  }
}

function* getFilesOfSupport({ payload: { url, history, sourceMenu } }) {
  try {
    const response = yield call(getListurl, url, history);
    if (response) {
      yield put(getSupportFilesSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* UploadJutePOFilesData({
  payload: { url,data,id},
}) {
  try {
    const response = yield call(postAdd, url, data);
        yield put(UploadJutePOFilesSuccessfull({ response }));
        yield put(
          getSupportFiles(
            serverApi.GET_FILES + "12/" + id + "/" + localStorage.getItem("companyId"),
          )
        );
        if(response.status){
          swal("Uploaded Successfully", { icon: "success" });
        }

  } catch (error) {
    console.log(error);
  }
};

function* getJutePODeleteFileData({ payload: { url,id } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(JutePODeleteFilesSuccessfull({ response }));
    if (response.data.message == "Success.") {
      yield put(
        getSupportFiles(
          serverApi.GET_FILES + "12/" + id + "/" + localStorage.getItem("companyId"),
        )
      );
      swal("Successfully Deleted", { icon: "success" });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchJutePo() {
  yield takeEvery(JUTE_PO_LIST, JutePoSagaList);
  yield takeEvery(JUTE_PO_CREATE, CreateJutePo);
  yield takeEvery(JUTE_PO_UPDATE, UpdateJutePo);
  yield takeEvery(JUTE_PO_VIEW_BY_ID, ViewByIdJutePO);
  yield takeEvery(
    GET_JUTE_QUANTITY_BY_JUTE_TYPE,
    getJuteQuantityByJuteTypeData
  );
  yield takeEvery(GET_INDENT_DATA,GetIndentApprovedData)
  yield takeEvery(JUTE_INDENT_VIEW_BY_ID_FOR_PO,ViewByIdJuteIndentForPO)
  yield takeEvery(GET_LATEST_MARKET_RATE,getLatestMarketRateData)
  yield takeEvery(JUTEPO_UPLOAD_FILES, UploadJutePOFilesData);
  yield takeEvery(GET_SUPPORT_FILES, getFilesOfSupport);
  yield takeEvery(JUTE_PURCHASE_DELETE_FILE, getJutePODeleteFileData);
}
function* JutePoSaga() {
  yield all([fork(watchJutePo)]);
}
export default JutePoSaga;
