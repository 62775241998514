import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import CustomButton from "../Buttons/Buttons";
import DynamicSelect from "../Dropdown/Master/DynamicSelectMasters";
import { serverApi } from "../../helpers/Consts";
import TextFieldNormal from "../TextField/TextFieldNormal";
import { getStatusList } from "../../store/Global/DropDownApis/actions";
import { getCustomerList } from "../../store/Global/DropDownApis/actions";
import { getBranchList } from "../../store/Global/DropDownApis/actions";

const QuotationListFilter = ({
  handle_search
}) => {
    let dispatch = useDispatch();
    let history = useHistory();
    const userDit = JSON.parse(localStorage.getItem("authUser"));

    const [open, setOpnedDialog] = useState(false);
    const [customerListData, setCustomerList] = useState([]);
    const [branchListData, setBranchList] = useState([]);
    const [branchId, setBranchId] = useState("");
    const [status, setStatus] = useState([]);
    const [fromDate, setFromDate] = useState(moment().day(-7).format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
    const [statusId, setStatusId] = useState("");
    const [quotationNo, setQuotationNo] = useState(""); 
    const [customerName, setCustomerName] = useState("");

    const handleClickOpen = () => {
      setOpnedDialog(true)
    };
  
    const handleClose = () => {
      setOpnedDialog(false)
    };
  
    const handleSelectChange = (selectedValue, selectedName, name) => {
      if (name === "Status") {
        setStatusId(selectedValue)
      }
      if (name === "CustomerName") {
        setCustomerName(selectedValue)
      }
      if (name === "branchId") {
        if(selectedValue === 0) {
          setBranchId("");

          return;
        }

        setBranchId(selectedValue)
      }
    };

    const onhandlechange = (value) => {
      setQuotationNo(value)
    };

    const handleSelectDate = (e, name) => {
      if (name === "FromDate") {
        var date = moment(e).format();
        var Date = date.split("T", 1).toString();
        setFromDate(Date)
      }
      if (name === "ToDate") {
        var date = moment(e).format();
        var Date = date.split("T", 1).toString();
        setToDate(Date)
      }
    };
    const handleSubmit = () => {
      let modifiedFromDate = "";
      let modifiedToDate = "";

      if (fromDate !== "") {
        modifiedFromDate = moment(fromDate, "YYYY-MM-DD", true).format(
          "DD-MM-YYYY"
        );
      } else {
        modifiedFromDate = fromDate
      }
      if (toDate !== "") {
        modifiedToDate = moment(toDate, "YYYY-MM-DD", true).format(
          "DD-MM-YYYY"
        );
      } else {
        modifiedToDate = toDate;
      }
  
      const data = {
        acYear: localStorage.getItem("acadamicYear"),
        companyId: localStorage.getItem("companyId"),
        cusId: customerName,
        fromDate: modifiedFromDate,
        quoteNo: quotationNo,
        status: statusId,
        toDate: modifiedToDate,
        branchId: branchId
      };

      handle_search(data);
      setOpnedDialog(false)
    };

    useEffect(() => {
      const financialYearStartMonth = 3; // Assuming financial year starts in April
      let financialYearStart = moment()
        .year(localStorage.getItem("acadamicYear"))
        .month(financialYearStartMonth)
        .startOf("month");
  
      const fromDate = financialYearStart.day(-7).format("YYYY-MM-DD");
      const toDate = moment().format("YYYY-MM-DD");
  
      setFromDate(fromDate)
      setToDate(toDate)
        dispatch(getStatusList(serverApi.STATUS_LISTS, history));
        const data = {
          companyId: localStorage.getItem("companyId"),
          cipher: userDit.cipher,
        };
        dispatch(getCustomerList(serverApi.GET_ALL_CUSTOMERS, data, history));
        dispatch(getBranchList(
          serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
          history
        ));
    }, [getStatusList, getBranchList, getCustomerList])

    const { statusList, customerList, branchList} = useSelector(
      (state) => state.DropDownListReducer
    );
  
    useEffect(() => {
        if (statusList) {
          setStatus(statusList.data)
        }
        if (customerList) {
          if (customerList.data) {
            if (customerList.data.length !== 0) {
              var CusList = [{
                value: "",
                name: "Select....",
                label: "Select...."
              }];
  
              customerList.data.map((item) => {
                CusList.push({
                  value: item.id,
                  name: item.name,
                  label: item.name,
                });
              });
              setCustomerList(CusList)
            }
          }
        }

        if(branchList) {
          setBranchList(branchList.data)
        }
    }, [statusList, customerList, branchList])


    return (
        <>
        <Box>
          <Button onClick={handleClickOpen}>
            <Tooltip title="Filter">
              <FilterListOutlinedIcon />
            </Tooltip>
          </Button>
          <Dialog
            open={open}
            PaperProps={{
              sx: {
                position: " fixed",
                top: 0,
                right: 0,
                margin: 0,
                bottom: 0,
                maxHeight: "100vh",
              },
            }}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <Grid spacing={0}>
              <div className="consoleTextFieldBlock">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div className="filterBlockHeader">
                    <FilterListOutlinedIcon />
                    <span>Filter</span>
                  </div>
                </div>
              </div>
              <div className="prResponseFilter">
                <Grid item xs={12} className="filterBlockContainer">
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>From Date</label>
                      </div>
                      {/* <ConsoleDatePicker /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="FromDate"
                          value={fromDate}
                          fullWidth
                          onChange={(e) => handleSelectDate(e, "FromDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>To Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="ToDate"
                          value={toDate}
                          fullWidth
                          onChange={(e) => handleSelectDate(e, "ToDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={
                          status
                            ? status.map((item) => ({
                                label: item.label,
                                value: item.value,
                                name: item.name,
                              }))
                            : ""
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handleSelectChange}
                        selected={statusId}
                        name="Status"
                        label="Status"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="filterDateBlock">
                      <div>
                        <TextFieldNormal
                          label="Quotation Number"
                          value={quotationNo}
                          onChange={onhandlechange}
                        />
                      </div>
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={
                          customerListData
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handleSelectChange}
                        selected={customerName}
                        name="CustomerName"
                        label="Customer Name"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData= {branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handleSelectChange}
                        selected={branchId}
                        name="branchId"
                        label="Branch"
                      />
                    </div>
                  </Box>
                  <div className="consoleFormButtonBlock">
                    <CustomButton
                      label={"Search"}
                      className="greenButton"
                      handleClick={handleSubmit}
                      type="sumbit"
                    />
                  </div>
                </Grid>
              </div>
            </Grid>
          </Dialog>
        </Box>
      </>
    )
}


export default QuotationListFilter