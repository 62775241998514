export const JUTE_INDENT_LIST = "jute_indent_list";
export const JUTE_INDENT_LIST_SUCCESSFULL = "jute_indent_list_successfull";
export const JUTETYPE_BY_MUKAM = "jutetype_by_mukam";
export const JUTETYPE_BY_MUKAM_SUCCESSFULL = "jutetype_by_mukam_successfull";
export const QUALITY_BY_JUTETYPE = "quality_by_jutetype";
export const QUALITY_BY_JUTETYPE_SUCCESSFULL = "quality_by_jutetype_successfull";
export const INDENT_LINEITEMS_JUTE="indent_lineItems";
export const CLEAR_INDENT_LINEITEMS_JUTE="clear_indent_lineItems";
export const CREATE_JUTE_INDENT="create_jute_indent";
export const CREATE_JUTE_INDENT_SUCCESSFULL = "create_jute_indent_successfull";
export const UPDATE_JUTE_INDENT="update_jute_indent";
export const UPDATE_JUTE_INDENT_SUCCESSFULL = "update_jute_indent_successfull";
export const JUTE_INDENT_BY_ID = "jute_indent_by_id";
export const JUTE_INDENT_BY_ID_SUCCESSFULL = "jute_indent_by_id_successfull";

export const JUTE_INDENT_HEADER="jute_indent_header";
export const CLEAR_JUTE_INDENT_HEADER="clear_jute_indent_header";
