import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import CustomButton from "../../../components/Buttons/Buttons";
import {
  DebitNoteLineItemsProps,
  DebitNoteHeaderProps,
  getUplaodedDebitFiles,
} from "../../../store/Purchase/Debit-credit-note/actions";
import { getTenantId, serverApi, serverVars } from "../../../helpers/Consts";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Grid } from "@mui/material";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import { getFileDownload } from "../../../helpers/server.js";
import { isEmptyWithZero } from "../../../Utilities/helper.js";

const DebitCreditNotePreview = (props) => {
  const [editButton, seteditButton] = useState(props.DebCreditHdr.editButton);
  const [companyLogo, setcompanyLogo] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [phoneNo, setphoneNo] = useState("");
  const [email, setemail] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [comName, setcomName] = useState("");
  const [debitNoteLineDetails, setdebitNoteLineDetails] = useState([]);
  const [supplierName] = useState(props.DebCreditHdr.supplierName);
  const [inwardId, setinwardId] = useState(props.DebCreditHdr.inwardId);
  const [Debit_Date] = useState(props.DebCreditHdr.Debit_Date);
  const [SrNo] = useState(props.DebCreditHdr.SrNo);
  const [srPrintNo] = useState(props.DebCreditHdr.srPrintNo);
  const [DebitNoteNo] = useState(props.DebCreditHdr.DebitNoteNo);
  const [SR_Date] = useState(props.DebCreditHdr.SR_Date);
  const [PoDate] = useState(props.DebCreditHdr.PoDate);
  const [poSequence] = useState(props.DebCreditHdr.poSequence);
  const [challanno] = useState(props.DebCreditHdr.challanno);
  const [status,setStatus]=useState(props.DebCreditHdr.status);
  const [challannoDate, setchallannoDate] = useState(
    props.DebCreditHdr.ChallannoDate
  );
  const [vehicleNumber] = useState(props.DebCreditHdr.vehicleNumber);
  const [Todaydate, setTodaydate] = useState(moment().format());
  const [TotalAmount, setTotalAmount] = useState(
    props.DebCreditHdr.TotalAmount
  );
  const [TotalValue, setTotalValue] = useState(props.DebCreditHdr.TotalValue);
  const [TotalIGST, setTotalIGST] = useState(props.DebCreditHdr.TotalIGST);
  const [TotalCGST, setTotalCGST] = useState(props.DebCreditHdr.TotalCGST);
  const [TotalSGST, setTotalSGST] = useState(props.DebCreditHdr.TotalSGST);

  const [roundOffValue, setroundOffValue] = useState(
    props.DebCreditHdr.roundOffValue
  );
  const [roundingFlag, setroundingFlag] = useState(
    props.DebCreditHdr.roundingFlag
  );
  const [roundingPositiveFlag, setroundingPositiveFlag] = useState(
    props.DebCreditHdr.roundingPositiveFlag
  );
  const [roundingNegetiveFlag, setroundingNegetiveFlag] = useState(
    props.DebCreditHdr.roundingNegetiveFlag
  );
  const [roundingPositiveValue, setroundingPositiveValue] = useState(
    props.DebCreditHdr.roundingPositiveValue
  );
  const [roundingNegetiveValue, setroundingNegetiveValue] = useState(
    props.DebCreditHdr.roundingNegetiveValue
  );

  const [GST, setGST] = useState("");
  const [files_List, setfiles_List] = useState([]);

  useEffect(() => {
    setdebitNoteLineDetails(props.debitNoteLineItems);
    let companyData = JSON.parse(localStorage.getItem("authUser"));
    let companyId = localStorage.getItem("companyId");
    companyData.companyDetails.map((p) => {
      let id = p.value;
      if (companyId === id.toString()) {
        setcompanyName(p.label);
        setcompanyLogo(p.companyLogo);
        setaddress1(p.address1);
        setaddress2(p.address2);
        setcomName(p.name);
        setphoneNo(p.contactNo);
        setemail(p.emailId);
        setGST(p.gstNo);
      }
    });

    if (props.location.state) {
      props.getUplaodedDebitFiles(
        serverApi.GET_FILES +
          "29/" +
          props.DebCreditHdr.adjustmentId +
          "/" +
          localStorage.getItem("companyId"),
        props.history
      );
    }
  }, []);

  useEffect(() => {
    if (props.getDebitFilesData) {
      if (props.getDebitFilesData.data) {
        if (props.getDebitFilesData.data.data) {
          setfiles_List(props.getDebitFilesData.data.data);
        }
      }
    }
  }, [props]);
  useEffect(() => {
    if (props.debitNoteLineItems) {
      if (props.debitNoteLineItems.length !== 0) {
        var TotalValue = "0.00";
        var TotalAmount = props.debitNoteLineItems
          .filter((item) => item.isActive != 0)
          .map((p) => p.debitnoteValue)
          .reduce(
            (debitnoteValue, ind) =>
              parseFloat(debitnoteValue) + parseFloat(ind)
          );

        var TotalIGST = props.debitNoteLineItems
          .filter((item) => item.isActive != 0)
          .map((p) => p.itaxAmount)
          .reduce(
            (itaxAmount, ind) => parseFloat(itaxAmount) + parseFloat(ind)
          );
        var TotalCGST = props.debitNoteLineItems
          .filter((item) => item.isActive != 0)
          .map((p) => p.ctaxAmount)
          .reduce(
            (ctaxAmount, ind) => parseFloat(ctaxAmount) + parseFloat(ind)
          );

        var TotalSGST = props.debitNoteLineItems
          .filter((item) => item.isActive != 0)
          .map((p) => p.staxAmount)
          .reduce(
            (staxAmount, ind) => parseFloat(staxAmount) + parseFloat(ind)
          );
        TotalValue =
          Number(TotalAmount) +
          Number(TotalIGST) +
          Number(TotalCGST) +
          Number(TotalSGST);

        setTotalAmount(TotalAmount);
        setTotalIGST(TotalIGST);
        setTotalCGST(TotalCGST);
        setTotalSGST(TotalSGST);
        if (props.location.state !== undefined) {
          if (props.location.state.rowData !== undefined) {
            if (props.location.state.rowData.roundingFlag == true) {
              setTotalValue(
                TotalValue + props.location.state.rowData.roundOffValue
              );
              props.DebCreditHdr.TotalValue =
                TotalValue + props.location.state.rowData.roundOffValue;
            } else if (props.location.state.rowData.roundingFlag == false) {
              setTotalValue(
                Number(TotalValue) +
                  Number(props.location.state.rowData.roundOffValue)
              );
              props.DebCreditHdr.TotalValue =
                Number(TotalValue) +
                Number(props.location.state.rowData.roundOffValue);
            }else{
              setTotalValue(Number(TotalValue));
              props.DebCreditHdr.TotalValue = Number(TotalValue)
            }
          }
        } else {
          if (roundingPositiveFlag == true) {
            setTotalValue(Number(TotalValue) + Number(roundingPositiveValue));
            props.DebCreditHdr.TotalValue =
              Number(TotalValue) + Number(roundingPositiveValue);
          } else if (roundingNegetiveFlag == true) {
            setTotalValue(Number(TotalValue) + Number(roundingNegetiveValue));
            props.DebCreditHdr.TotalValue =
              Number(TotalValue) + Number(roundingNegetiveValue);
          } else {
            setTotalValue(TotalValue);
            props.DebCreditHdr.TotalValue = TotalValue;
          }
        }
        let positiveRF = "";
        let NegativeRF = "";
        if (!isEmptyWithZero(TotalValue)) {
          let totValue = TotalValue.toString();
          let splitTotal = totValue.split(".");
          if (splitTotal[1] > 0) {
            positiveRF = 100 - splitTotal[1];
            positiveRF = "0." + positiveRF;
            NegativeRF = splitTotal[1];
            NegativeRF = "-0." + NegativeRF;
            setroundingPositiveValue(positiveRF);
            setroundingNegetiveValue(NegativeRF);
            props.DebCreditHdr.roundingPositiveValue = positiveRF;
            props.DebCreditHdr.roundingNegetiveValue = NegativeRF;
          } else {
            setroundingPositiveValue("0.00");
            setroundingNegetiveValue("0.00");
            props.DebCreditHdr.roundingPositiveValue = "0.00";
            props.DebCreditHdr.roundingNegetiveValue = "0.00";
          }
        } else {
          setroundingPositiveValue("0.00");
          setroundingNegetiveValue("0.00");
          props.DebCreditHdr.roundingPositiveValue = "0.00";
          props.DebCreditHdr.roundingNegetiveValue = "0.00";
        }
      }
    }
  }, [props.debitNoteLineItems]);

  const handleEdit = () => {
    props.handleCurrentStep(1);
  };
  const getFilesDownload = (fileUploadId, fileName, fileExtension) => {
    let url = serverVars.SECURITY_API + "files/downloadfile/" + fileUploadId;
    getFileDownload(url, fileName, fileExtension);
  };
  const handlePdf = () => {
    if (props.location.state !== undefined) {
      if (props.location.state.rowData !== undefined) {
        fetch(
          serverApi.DEBIT_NOTE_DOWNLOAD_PDF +
            props.location.state.rowData.debitNoteId +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).userId,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/pdf",
              "X-TenantId": getTenantId(window.location.hostname),
              Authorization:
                localStorage.getItem("token_type") +
                localStorage.getItem("access_token"),
            },
          }
        )
          .then((response) => response.blob())
          .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              props.location.state.rowData.debitNoteNo + ".pdf"
            );
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          });
      }
    }
  };
  const convertNumberToWords = (amount) => {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = "";
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  };
  const withDecimal = (n) => {
    if (n !== undefined) {
      var nums = n.toString().split(".");
      var whole = convertNumberToWords(nums[0]);

      if (nums.length == 2) {
        if (parseInt(nums[1]) > 0) {
          return whole + "only";
        }
      }
      return whole + "only";
    }
  };
  var DateTime = Todaydate.split("T", 2);
  var TodayDate = moment(DateTime[0], "YYYY-MM-DD", true).format("DD-MM-YYYY");
  var Time = DateTime[1].split("+")[0];
  var filteredLineitems =
    props.debitNoteLineItems !== null
      ? props.debitNoteLineItems.filter((item) => item.isActive !== 0)
      : [];
  if (SR_Date !== null) {
    var SRDate = moment(SR_Date, "YYYY-MM-DD", true).format("DD-MM-YYYY");
  }
  if (Debit_Date !== null) {
    var DebitDate = moment(Debit_Date, "YYYY-MM-DD", true).format("DD-MM-YYYY");
  }
  if (challannoDate !== null) {
    var challanno_Date = moment(challannoDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
  }

  return (
    <>
      <div className="previewBlock">
        <div
          style={{ display: "flex", justifyContent: "end", padding: "10px" }}
        >
          {props.location.state && (
            <CustomButton
              label="Print"
              handleClick={handlePdf}
              type="button"
              className="lightBlueButton"
              muIcon={<PrintIcon />}
            />
          )}
          {props.DebCreditHdr.editButton && (
            <CustomButton
              label="Edit"
              handleClick={handleEdit}
              type="button"
              className="lightBlueButton"
              muIcon={<EditIcon />}
            />
          )}
        </div>
        <table
          cellpadding="0"
          cellspacing="0"
          width="90%"
          className="previewTable"
        >
          <tbody>
            <tr>
              <td
                colspan="6"
                style={{ borderRight: "none", borderBottom: "none" }}
              >
                <img src={companyLogo} width="100%" alt="" />
              </td>
              <td
                colspan="10"
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  textAlign: "right",
                }}
              >
                <h2>{companyName}</h2>
                {address1}
                <br></br>
                {/* {address2} */}
                GST No: {GST}
                <br></br>
                Phone No:- {phoneNo}
                <br></br>
                Email Address:- {email}
                <br></br>
              </td>
            </tr>
          </tbody>
          <tr>
            <td colspan="16" style={{ textAlign: "center", borderTop: "none" }}>
              <h2>DEBIT NOTE</h2>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <b>SUPPLIER</b>
            </td>
            <td colspan="3">{supplierName}</td>
            <td colspan="2">
              <b>DATE</b>
            </td>
            <td colspan="7">{DebitDate}</td>
          </tr>
          <tr>
            <td colspan="2">
              <b>SR NO</b>
            </td>
            <td colspan="3">{srPrintNo}</td>
            <td colspan="2">
              <b>SR DATE</b>
            </td>
            <td colspan="7">{SRDate}</td>
          </tr>
          {/* <tr>
            <td colspan="4">
              <b>PO NO</b>
            </td>
            <td colspan="4">{poSequence}</td>
            <td colspan="4">
              <b>PO DATE</b>
            </td>
            <td colspan="4">{PoDate}</td>
          </tr> */}
          <tr>
            <td colspan="2">
              <b>CHALLAN NO</b>
            </td>
            <td colspan="3">{challanno}</td>
            <td colspan="2">
              <b>CHALLAN DATE</b>
            </td>
            <td colspan="7">{challanno_Date}</td>
          </tr>
          <tr>
            <td colspan="2">
              <b>VEHICLE NO </b>
            </td>
            <td colspan="3">{vehicleNumber}</td>
            <td colspan="2">
              <b>Debit Note No</b>
            </td>
            <td colspan="7">
              {DebitNoteNo == ""
                ? "Will be Generated After Creation"
                : DebitNoteNo}
            </td>
          </tr>
          <tr>
            <td rowspan="2">
              <b>Sl No</b>
            </td>
            <td rowspan="2">
              <b>ITEM CODE</b>
            </td>
            <td rowspan="2">
              <b>ITEM DESCRIPTION</b>
            </td>
            <td rowspan="2">
              <b>DEBIT QUANTITY</b>
            </td>
            <td rowspan="2">
              <b>UOM</b>
            </td>

            <td rowspan="2">
              <b>RATE</b>
            </td>
            <td rowspan="2">
              <b>DEBIT AMOUNT</b>
            </td>
            {/* <td rowspan="2">
              <b>DEBIT QTY</b>
            </td> */}
            {/* <td rowspan="2">
              <b> AMOUNT</b>
            </td> */}
            <td colSpan={2} align="center">
              <b>IGST</b>
            </td>
            <td colSpan={2} align="center">
              <b>CGST</b>
            </td>
            <td colSpan={2} align="center">
              <b>SGST</b>
            </td>
            <td rowspan="2">
              <b>REASON</b>
            </td>
          </tr>
          <tr>
            <td align="center">
              <b>%</b>
            </td>
            <td align="center">
              <b>Amt</b>
            </td>
            <td align="center">
              <b>%</b>
            </td>
            <td align="center">
              <b>Amt</b>
            </td>
            <td align="center">
              <b>%</b>
            </td>
            <td align="center">
              <b>Amt</b>
            </td>
          </tr>
          {filteredLineitems.length !== 0 &&
            filteredLineitems.map((item, i) => (
              <>
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>
                    {item.itemGroupId}
                    {item.itemCode}
                  </td>
                  <td>{item.itemName}</td>
                  <td>{item.quantity}</td>
                  <td>{item.uom}</td>
                  <td align="right">{item.rate}</td>
                  <td align="right">
                    {" "}
                    {parseFloat(item.debitnoteValue).toFixed(2)}
                  </td>
                  {/* <td align="right">{item.debitnoteQty} </td> */}
                  {/* <td align="right">{ parseFloat(item.amount).toFixed(2)}</td> */}
                  <td align="right">{item.itaxPer}</td>
                  <td align="right">
                    {parseFloat(item.itaxAmount).toFixed(2)}
                  </td>
                  <td align="right">{item.ctaxPer}</td>
                  <td align="right">
                    {parseFloat(item.ctaxAmount).toFixed(2)}
                  </td>
                  <td align="right">{item.staxPer}</td>
                  <td align="right">
                    {parseFloat(item.staxAmount).toFixed(2)}
                  </td>
                  <td align="left">{item.reason}</td>
                </tr>
              </>
            ))}
          <tr>
            <td colSpan={7}>
              <span>
                <b>Total</b>
              </span>{" "}
              <span style={{ float: "right" }}>
                <b>{parseFloat(TotalAmount).toFixed(2)} </b>
              </span>
            </td>

            <td colSpan={2} align="right">
              {parseFloat(TotalIGST).toFixed(2)}
            </td>
            <td colSpan={2} align="right">
              {parseFloat(TotalCGST).toFixed(2)}
            </td>
            <td colSpan={2} align="right">
              {parseFloat(TotalSGST).toFixed(2)}
            </td>
          </tr>

          <tr>
            <td colspan="4">
              <b>Total Values</b>
            </td>
            <td colspan="12">
              <b>{parseFloat(TotalValue).toFixed(2)} </b>
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <b>Total Value(In Words)</b>
            </td>
            <td colspan="12">
              <b>{withDecimal(parseFloat(TotalValue).toFixed(2))} </b>
            </td>
          </tr>
          {props.location.state &&<span><b>Status:</b>{status}</span>}
          <tr>
            <td
              colspan="16"
              align="right"
              style={{ borderTop: "none", borderBottom: "none" }}
            >
              FOR {companyName}
            </td>
          </tr>
          <tr>
            <td
              colspan="6"
              style={{
                borderTop: "none",
                borderBottom: "none",
                borderRight: "none",
              }}
            >
              {props.location.state && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box gridColumn="span 12">
                      {files_List.length !== 0 && (
                        <span>
                          <b>Uploaded Documents</b>
                        </span>
                      )}
                    </Box>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                      className="ducumentUploadBlock uploadedContent"
                      style={{ padding: "10px" }}
                    >
                      {files_List &&
                        files_List.map((item) => (
                          <Box gridColumn="span 6">
                            <div className="documentUploadContent">
                              <div
                                className="uploadedInfo"
                                style={{ display: "flex" }}
                              >
                                <img
                                  src={TickIcon}
                                  alt=""
                                  className="tickIcon"
                                  height={"20px"}
                                  width={"20px"}
                                />
                                <Tooltip
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 600 }}
                                  title="Click to download File"
                                >
                                  <div
                                    className="uplodedFileName"
                                    onClick={() => {
                                      getFilesDownload(
                                        item.fileUploadId,
                                        item.fileName,
                                        item.fileExtension
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {item.fileName}
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                          </Box>
                        ))}
                    </Box>
                  </Grid>
                </Grid>
              )}
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};

const mapStatetoProps = (state) => {
  const { debitNoteLineItems, debitNoteHeaders, getDebitFilesData } =
    state.DebitCreditReducer;
  return {
    debitNoteLineItems,
    debitNoteHeaders,
    getDebitFilesData,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    DebitNoteLineItemsProps,
    DebitNoteHeaderProps,
    getUplaodedDebitFiles,
  })(DebitCreditNotePreview)
);
