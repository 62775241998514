import {
  SPINNING_DOFF_ENTRY_LIST,
  SPINNING_DOFF_ENTRY_LIST_SUCCESSFULL,
} from "./actionTypes";

export const spinningDoffList = (url, data, history) => {
  return {
    type: SPINNING_DOFF_ENTRY_LIST,
    payload: { url, data, history },
  };
};
export const spinningDoffListSuccessfull = (data) => {
  return {
    type: SPINNING_DOFF_ENTRY_LIST_SUCCESSFULL,
    payload: data,
  };
};


