import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextFieldNormal from "../../TextField/TextFieldNormal";
import DynamicSelect from "../../../components/Dropdown/Master/DynamicSelectMasters";




class LeaveTypeMasterFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      leaveTypeCode:"",
      leaveDesc:"",
      payable:"",
    };
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  handleChange = (key)=> (event)=>{
    if(key === "leaveTypeCode"){
      this.setState({
        leaveTypeCode:event
      })
    }
    if(key === "leaveDesc"){
      this.setState({
        leaveDesc:event
      })
    }
  }

  handleSubmit = () => {
    var data = {
      leaveTypeCode:this.state.leaveTypeCode,
      leaveDesc:this.state.leaveDesc,
      payable:this.state.payable
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleClear = () => {
    this.setState({
    });
  };
  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "payable") {
      if(selectedValue ==""){
        this.setState({
          payable: '',
        });
      }else{
        this.setState({
          payable: selectedValue,
        });
      }
    
    }
  };

  render() {

    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          // TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid
            spacing={0}
            // maxWidth="800px"
            //style={{ padding: "20px" }}
          >
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
                {/* <div>Save View</div> */}
              </div>
            </div>
            <Grid item xs={12} className="filterBlockContainer">
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Leave Type Code"
                        value={this.state.leaveTypeCode}
                        onChange={this.handleChange("leaveTypeCode")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Leave Discription"
                        value={this.state.leaveDesc}
                        onChange={this.handleChange("leaveDesc")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={payableList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.state.payable}
                        update={this.props.location.state ? 1 : 0}
                        name="payable"
                        label="Payable"
                      />
                    </div>
                  </Box>

              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Search"}
                  className="greenButton"
                  handleClick={this.handleSubmit}
                  type="sumbit"
                />
              </div>
            </Grid>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const payableList = [
  
  {
    value: "",
    label: "Select...",
    name: "Select...",
  },
    {
      value: "Y",
      label: "Yes",
      name: "Yes",
    },
    {
      value: "N",
      label: "No",
      name: "No",
    },
  ];

const mapStatetoProps = (state) => {
};

export default withRouter(
  connect(mapStatetoProps, {
      })(LeaveTypeMasterFilter)
);
