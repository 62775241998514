export const DEBIT_CREDIT_LIST = "debit_credit_list";
export const DEBIT_CREDIT_LIST_SUCCESSFULL = "debit_credit_list_successfull";
export const API_ERRORS = 'api_failed';
export const GET_SUPPLIER_FOR_JUTE_BY_ID ='get_supplier_for_jute_by_id';
export const GET_SUPPLIER_FOR_JUTE_BY_ID_SUCCESSFULL ='get_supplier_for_jute_by_id_successfull';
export const GET_SR_NO ='get_sr_no';
export const GET_SR_NO_SUCCESSFULL ='get_sr_no_successfull';
export const SR_NO_VIEW_BY_ID ='sr_no_view_by_id'
export const SR_NO_VIEW_BY_ID_SUCCESSFULL='sr_no_view_by_id_successfull';
export const SR_NO_VIEW_BY_ID_CLEAR='sr_no_view_by_id_clear';
export const GET_DEBIT_NOTE_BRANCH="get_debit_note_branch";
export const GET_DEBIT_NOTE_BRANCH_SUCCESSFULL="get_debit_note_branch_successfull";
export const GET_DEBIT_PROJECTS="get_debit_projects";
export const GET_DEBIT_PROJECTS_SUCCESSFULL="get_debit_projects_successfull";
export const DEBIT_NOTE_LINE_ITEM="debit_note_line_item";
export const DEBIT_NOTE_HEADER="debit_note_header";
export const CLEAR_DEBIT_NOTE_HEADER="clear_debit_note_header";
export const CLEAR_DEBIT_NOTE_LINE_ITEM="clear_debit_note_line_item";
export const CREATE_DEBIT_NOTE="create_debit_note";
export const CREATE_DEBIT_NOTE_SUCCESSFULL="create_debit_note_successfull";
export const BALANCE_QUANTITY ='balance_quantity';
export const BALANCE_QUANTITY_SUCCESSFULL ='balance_quantity_successfull';
export const GET_DEBIT_NOTE_BY_ID="get_debit_note_by_id";
export const GET_DEBIT_NOTE_BY_ID_SUCCESSFULL="get_debit_note_by_id_successfull";
export const UPDATE_DEBIT_NOTE="update_debit_note";
export const UPDATE_DEBIT_NOTE_SUCCESSFULL="update_debit_note_successfull";
export const UPLOAD_DEBIT_FILES ='upload_debit_files';
export const UPLOAD_DEBIT_FILES_SUCCESSFULL ='upload_debit_files_successfull';
export const GET_DEBIT_FILES ="get_debit_files";
export const GET_DEBIT_FILES_SUCCESSFULL ="get_debit_files_successfull";
export const DELETE_DEBIT_FILES ="delete_debit_files";
export const DELETE_DEBIT_FILES_SUCCESSFULL ="delete_debit_files_successfull";
