import {YARN_MASTER_LIST,YARN_MASTER_LIST_SUCCESSFULL,CREATE_YARN_MASTER_SUCCESSFULL,CREATE_YARN_MASTER,
  UPDATE_YARN_MASTER,
  UPDATE_YARN_MASTER_SUCCESSFULL,
  API_ERRORS,
 } from './actionTypes';

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    YarnMasterList: [],
    CreateYarnMaster:[],
    UpdateYarnMaster:[],
  };

  const YarnMasterReducer = (state = initialState, action) => {
    switch (action.type) {
        case YARN_MASTER_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case YARN_MASTER_LIST_SUCCESSFULL:
            state = {
              ...state,
              YarnMasterList: action.payload.response,
              loading: true,
            };
            break;
            case CREATE_YARN_MASTER:
              state = {
                ...state,
                loading: true,
              };
              break;
            case CREATE_YARN_MASTER_SUCCESSFULL:
              state = {
                ...state,
                CreateYarnMaster: action.payload.response,
                loading: true,
              };
              break;
              case UPDATE_YARN_MASTER:
              state = {
                ...state,
                loading: true,
              };
              break;
            case UPDATE_YARN_MASTER_SUCCESSFULL:
              state = {
                ...state,
                UpdateYarnMaster: action.payload.response,
                loading: true,
              };
              break;
              case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default YarnMasterReducer;