/****
 * Created By Nagesh Medisetty 01-02-2022
 * TextField With Validation Required
 */

import React, { Component } from "react";
import {
  required,
  number,
  email,
  landnumber,
  pincode,
  special,
} from "../../Utilities/Validations";

class TextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      show: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  /* Handle Validations */
  handleChange = (event) => {
    const { value } = event.target;
    if (this.props.required) {
      let error = required(value, "This field is required");
      this.setState({ error: error });
      setTimeout(() => {
        this.setState({ error: false });
      }, 5000);
      if (this.props.caps === "ON") {
        this.props.onChange(value.toUpperCase());
      } else {
        this.props.onChange(value);
      }
    }
    if (this.props.number) {
      if (value) {
        let errornum = number(value, "Invalid Phone Number");
        this.setState({ error: errornum });
        setTimeout(() => {
          this.setState({ error: false });
        }, 5000);
        if (!errornum) {
          this.props.onChange(value);
        } else {
          if (value.length > 0) {
          }
        }
      } else {
        this.props.onChange(value);
      }
    }
    if (this.props.numberreq) {
      if (value) {
        let errornum = number(value, "Invalid Phone Number");
        this.setState({ error: errornum });
        setTimeout(() => {
          this.setState({ error: false });
        }, 5000);
        if (!errornum) {
          this.props.onChange(value);
        }
      } else {
        let errornum = required(value, "This field is required");
        this.setState({ error: errornum });
        setTimeout(() => {
          this.setState({ error: false });
        }, 5000);
        this.props.onChange(value);
      }
    }
    if (this.props.email) {
      let erroremail = email(value, "Invalid Email Address");
      this.setState({ error: erroremail });
      if (this.props.caps === "ON") {
        this.props.onChange(value.toUpperCase());
      } else {
        this.props.onChange(value);
      }
    }
    if (this.props.landnumber) {
      if (value) {
        let errornum = landnumber(value, "Invalid Phone Number");
        this.setState({ error: errornum });
        setTimeout(() => {
          this.setState({ error: false });
        }, 5000);
        if (errornum === false) this.props.onChange(value);
      } else {
        let errornum = landnumber(value, "Invalid Phone Number");
        this.setState({ error: errornum });
        setTimeout(() => {
          this.setState({ error: false });
        }, 5000);
        if (errornum === false) this.props.onChange("");
      }
    }
    if (this.props.pincode) {
      if (value) {
        let errornum = pincode(value, "Invalid Pincode Number");
        this.setState({ error: errornum });
        setTimeout(() => {
          this.setState({ error: false });
        }, 5000);
        if (errornum === false) this.props.onChange(value);
      } else {
        let errornum = pincode(value, "Invalid Pincode Number");
        this.setState({ error: errornum });
        setTimeout(() => {
          this.setState({ error: false });
        }, 5000);
        this.props.onChange("");
      }
    }
    if (this.props.emailreq) {
      if (value) {
        let erroremail = email(value, "Invalid Email Address");
        this.setState({ error: erroremail });
        setTimeout(() => {
          this.setState({ error: false });
        }, 5000);
      } else {
        let error = required(value, "This field is required");
        this.setState({ error: error });
        setTimeout(() => {
          this.setState({ error: false });
        }, 5000);
      }
      if (this.props.caps === "ON") {
        this.props.onChange(value.toUpperCase());
      } else {
        this.props.onChange(value);
      }
    }
    if (this.props.special) {
      let errorSpecial = special(value, "Invalid Text");
      this.setState({ error: errorSpecial });
      const re = /^[0-9a-zA-Z\b]+$/;
      if (value === "" || re.test(value)) {
        if (this.props.caps === "ON") {
          this.props.onChange(value.toUpperCase());
        } else {
          this.props.onChange(value);
        }
      }
    }
  };

  onhandleKeyDown = (event) => {
    if (this.props.handleKeyDown) {
      this.props.handleKeyDown(event.keyCode);
    }
  };
  validate = (event) => {
    var controlKeys = [8, 9, 13, 37, 39, 46];
    var isControlKey = controlKeys.join(",").match(new RegExp(event.which));
    if (
      !event.which ||
      (48 <= event.which && event.which <= 57) ||
      isControlKey
    ) {
      return;
    } else {
      event.preventDefault();
    }
  };

  render() {
    return (
      <div className="TextFieldBlock">
        {this.props.label && (
          <label htmlFor="app-input-field">{this.props.label}</label>
        )}
        <input
          type={this.props.type}
          value={this.props.value}
          className={
            this.state.error ? "TextField errorTextField" : "TextField"
          }
          placeholder={this.props.rightAlign ? "" : "Enter Here"}
          onChange={this.handleChange}
          onKeyDown={this.onhandleKeyDown}
          disabled={this.props.disabled}
          min={this.props.minValue}
          onInput={this.props.oninput}
          readOnly={this.props.readOnly}
          style={{ textAlign: this.props.rightAlign }}
        />
        {this.state.error && (
          <span className="text-danger">{this.state.error.message}</span>
        )}
      </div>
    );
  }
}

export default TextField;
