import {
  API_ERRORS,
  SALESORDER_LIST,
  SALESORDER_LIST_SUCCESSFULL,
  GET_ALL_BROKER,
  GET_ALL_BROKER_SUCCESSFULL,
  CREATE_SALES_ORDER,
  CREATE_SALES_ORDER_SUCCESSFULL,
  UPDATE_SALES_ORDER,
  UPDATE_SALES_ORDER_SUCCESSFULL,
  SALES_ORDER_BY_ID,
  SALES_ORDER_BY_ID_SUCCESSFULL,
  GET_BRANCH,
  GET_BRANCH_SUCCESSFULL,
  ACCOUNT_LIST,
  ACCOUNT_LIST_SUCCESSFULL,
  SALES_ORDER_HEADER,
  SALES_ORDER_LINE_ITEMS,
  GET_QUOTATION_RECORDS,
  GET_QUOTATION_RECORDS_SUCCESSFULL,
  CLEAR_SALES_ORDER_HEADER,
  QUOTATION_VIEW_BY_ID,
  QUOTATION_VIEW_BY_ID_SUCCESSFULL,
  SALES_ORDER_JUTEGOVT_HEADERS,
  GET_UOM_RELATION_VALUE_BY_UOM_CODE,
  GET_UOM_RELATION_VALUE_BY_UOM_CODE_SUCCESSFULL,
  CLEAR_SO_JUTEGOVT_HEADERS,
  SALES_ORDER_JUTEGOVT_LINE_ITEMS,
  CLEAR_SO_JUTEGOVT_LINE_ITEMS,
  CREATE_JUTEGOVT_SALES_ORDER,
  CREATE_JUTEGOVT_SALES_ORDER_SUCCESSFULL,
  CLEAR_SALES_ORDER_LINEITEMS,
  SALES_ORDER_JUTEYARN_HEADERS,
  CLEAR_SO_JUTEYARN_HEADERS,
  SALES_ORDER_JUTEYARN_LINE_ITEMS,
  CLEAR_SO_JUTEYARN_LINE_ITEMS,
  CREATE_JUTEYARN_SALES_ORDER,
  CREATE_JUTEYARN_SALES_ORDER_SUCCESSFULL,
  UPDATE_JUTEYARN_SALES_ORDER,
  UPDATE_JUTEYARN_SALES_ORDER_SUCCESSFULL,
  QUOTE_VIEW_BY_ID_FOR_JUTEYARN,
  QUOTE_VIEW_BY_ID_FOR_JUTEYARN_SUCCESSFULL,
  S_O_BY_ID_FOR_JUTEYARN,
  S_O_BY_ID_FOR_JUTEYARN_SUCCESSFULL,
  QUOTE_VIEW_BY_ID_FOR_JUTEGOVT,
  QUOTE_VIEW_BY_ID_FOR_JUTEGOVT_SUCCESSFULL,
  UPDATE_JUTEGOVT_SALES_ORDER,
  UPDATE_JUTEGOVT_SALES_ORDER_SUCCESSFULL,
  CREATE_JUTEHESSIAN_SALES_ORDER,
  SALES_ORDER_JUTEHESSIAN_HEADERS,
  CREATE_JUTEHESSIAN_SALES_ORDER_SUCCESSFULL,
  SALES_ORDER_JUTEHESSIAN_LINE_ITEMS,
  CLEAR_SO_JUTEHESSIAN_HEADERS,
  CLEAR_SO_JUTEHESSIAN_LINE_ITEMS,
  SALES_ORDER_JUTEHESSIAN_BY_ID_SUCCESSFULL,
  SALES_ORDER_JUTEHESSIAN_BY_ID,
  UPDATE_JUTEHESSIAN_SALES_ORDER_SUCCESSFULL,
  UPDATE_JUTEHESSIAN_SALES_ORDER,
  SO_JUTE_HESSIAN_QUOTATION_VIEW_BY_ID_SUCCESSFULL,
  SO_JUTE_HESSIAN_QUOTATION_VIEW_BY_ID,
  SALES_ORDER_ITEMS_BY_ITEM_GROUP_ID,
  SALES_ORDER_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL,
  TERMS_CONDITIONS_API,
  TERMS_CONDITIONS_API_SUCCESSFULL,
  SALES_ORDER_HESSIAN_ITEMS_BY_ITEM_GROUP_ID,
  SALES_ORDER_HESSIAN_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL,
  GET_UOM_RELATION_VALUE_HESSIAN_SUCCESSFULL,
  GET_UOM_RELATION_VALUE_HESSIAN,
  DELETE_SO_FILE,
  DELETE_SO_FILE_SUCCESSFULL,
  UPLOAD_SO_FILES,
  UPLOAD_SO_FILES_SUCCESSFULL,
  GET_SO_FILES,
  SO_FILES_SUCCESSFULL,
  CLEAR_ALL_SO_FILES,
  UOM_LIST_BASEDON_ITEM_ID_HESSIAN,
  UOM_LIST_BASEDON_ITEM_ID_HESSIAN_SUCCESS,
  SO_JUTE_YARN_UOM_LIST,
  SO_JUTE_YARN_UOM_LIST_SUCCESSFULL,
  REGULAR_SO_ITEM_NAME_DATA,
  REGULAR_SO_ITEM_NAME_DATA_SUCCESSFULL,
  JUTE_GOVT_SO_ITEM_NAME_DATA,
  JUTE_GOVT_SO_ITEM_NAME_DATA_SUCCESSFULL,
  JUTE_YARN_SO_ITEM_NAME_DATA,
  JUTE_YARN_SO_ITEM_NAME_DATA_SUCCESSFULL,
  JUTE_HESSIAN_SO_ITEM_NAME_DATA,
  JUTE_HESSIAN_SO_ITEM_NAME_DATA_SUCCESSFULL,
} from "./actionTypes";
import moment from "moment";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  SalesOrder_List: [],
  error_msg: null,
  Broker_List: [],
  SalesById: [],
  getUomByCode: [],
  getUomRelationByCode: [],
  Update_sales: [],
  Create_sales: [],
  Create_JuteGovt: [],
  BranchPropdata: [],
  bankAccountList: [],
  QuotationApprovedData: [],
  ViewByIdQuotation: [],
  quoteViewByIDRecordForJuteGovt: "",
  Create_JuteYarn: [],
  quoteViewByIDRecordForJuteYarn: "",
  Create_JuteHessian: [],
  SalesOrderJuteHessianById: [],
  UpdateJuteHessian: [],
  SOJuteHessianViewByIdQuotation: [],
  SalesOrderItemBy_ItemGroupId: [],
  SalesOrderHessianItemBy_ItemGroupId: [],
  UomRelationValueHessianList: [],
  TermsConditions: [],
  SOFiles:{},
  Uploaded_so_Files: [],
  delete_File: [],
  SalesOrderHeader: {
    regularSalesId: "",
    finYear: "",
    createdBy: null,
    createdByDesc: "",
    createdDate: "",
    salesDate:null,
    quotationDate: null,
    salesNo: "",
    invoiceTypeId: null,
    invoiceTypeName: "",
    companyId: "",
    companyName: "",
    branchId: "",
    branchName: "",
    quotationId: "",
    customerId: null,
    customerName: "",
    bankId: "",
    bankName: "",
    branch: "",
    accountNo: "",
    ifscCode: "",
    micrCode: "",
    customerLineItemId: null,
    brokerId: null,
    brokerName: "",
    ContactNumber: "",
    ContactPerson: "",
    billingTo: "",
    billingToAddress: "",
    billingToGST: "",
    billingContactNo: "",
    billingContactPerson: "",
    billingToEmail: "",
    shippingTo: "",
    shippingToAddress: "",
    shippingToGST: "",
    shippingContactNo: "",
    shippingContactPerson: "",
    shippingToEmail: "",
    salesOrderExpiryDate: null,
    challanNo: "",
    challanDate: "",
    taxType: "",
    taxName: "",
    brokerCommision: "",
    brokerCommisionPercent: "",
    footerNote: "",
    internalNote: "",
    termsConditionType: "",
    termsCondition: "",
    deliveryTerms: "",
    paymentTerms: "",
    deliveryDays: "",
    freightCharges: "",
    amountWithoutTax: "",
    amountWithTax: "",
    updatedBy: "",
    updatedByDesc: "",
    updatedOn: "",
    approvedBy: "",
    approvedBydesc: "",
    approvedOn: "",
    statusId: 1,
    statusName: "",
    doStatus: "",
    isActive: 1,
    approveStatus: false,
    totalIGST: "0.00",
    totalCGST: "0.00",
    totalSGST: "0.00",
    netTotal: "0.00",
    taxTotal: "0.00",
    TotalAmount: "0.00",
    subTotal: "0.00",
    quotationNo: null,
    ApproveButton: false,
    RejectButton: false,
    UpdateButton: true,
    disableBroker: false,
    disableCustomer: false,
  },
  SoJuteYarnHeaders: {
    regularSalesId: "",
    salesOrderLineItemDTO: "",
    finYear: "",
    createdBy: null,
    salesId: "",
    createdDate: "",
    salesDate: moment().format("YYYY-MM-DD"),
    salesNo: "",
    invoiceTypeId: null,
    invoiceTypeName: "",
    companyId: "",
    companyName: "",
    branchId: "",
    branchName: "",
    quotationId: "",
    quotationDate: null,
    customerId: "",
    customerName: "",
    customerLineItemId: null,
    brokerId: null,
    brokerName: "",
    ContactNumber: "",
    ContactPerson: "",
    billingTo: "",
    billingToAddress: "",
    billingToGST: "",
    billingContactNo: "",
    billingContactPerson: "",
    billingToEmail: "",
    shippingTo: "",
    shippingToAddress: "",
    shippingToGST: "",
    shippingContactNo: "",
    shippingContactPerson: "",
    shippingToEmail: "",
    salesOrderExpiryDate: null,
    challanNo: "",
    challanDate: "",
    taxType: "",
    taxName: "",
    brokerCommisionSource: "",
    brokerCommision: "",
    brokerCommisionPercent: "",
    footerNote: "",
    internalNote: "",
    termsConditionType: "",
    termsCondition: "",
    deliveryTerms: "",
    paymentTerms: "",
    deliveryDays: "",
    freightCharges: "",
    amountWithoutTax: "",
    amountWithTax: "",
    updatedBy: "",
    updatedByDesc: "",
    updatedOn: "",
    approvedBy: "",
    approvedBydesc: "",
    approvedOn: "",
    statusId: 1,
    statusName: "",
    doStatus: "",
    isActive: 1,
    approveStatus: false,
    totalIGST: "0.00",
    totalCGST: "0.00",
    totalSGST: "0.00",
    netTotal: "0.00",
    taxTotal: "0.00",
    TotalAmount: "0.00",
    subTotal: "0.00",
    quotationNo: "",
    yarnRateUom: "",
    yarnQuantityUom: "",
    yarnRateUom2: "",
    yarnQuantityUom2: "",
  },
  SoJuteYarnLineItems: [
    {
      id: 1,
      regularSalesOrderId: "",
      yarnSalesOrderLineItemId: "",
      yarnSalesOrderId: "",
      createdBy: "",
      createdDate: "",
      companyId: "",
      companyName: "",
      quotationId: "",
      quotationLineitemId: "",
      hsncode: "",
      itemGroupId: "",
      itemGroupValue: "",
      itemGroupName: "",
      itemId: null,
      itemName: "",
      itemCode: "",
      itemValue: "",
      itemMake: "",
      quantity: "",
      uom: "",
      uom2: "",
      rate: "",
      doBalQty: "",
      invoiceBalQty: "",
      igstAmount: "",
      igstPercent: "",
      cgstAmount: "",
      cgstPercent: "",
      sgstAmount: "",
      sgstPercent: "",
      discountType: "",
      discountName: "",
      discountAmount:"",
      discount: "",
      discountedRate: "",
      netAmount: "",
      totalAmount: "",
      remarks: "",
      taxPercent: "",
      isActive: 1,
      yarnIsActive:1,
      currency: "INR",
      status: "",
      yarnRateUom: "",
      yarnRateUomId: "",
      yarnRateUomRelationValue: "",
      yarnRateCalculatedValue: "",
      yarnQuantityUom: "",
      yarnQuantityUomId: "",
      yarnQuantityUomRelationValue: "",
      yarnQuantityCalculatedValue: "",
      yarnVariableRelationValue: "",
      yarnRateIncludingBrokerage: "",
      yarnRateExcludingBrokerage:"",
      updatedBy: null,
      updatedOn: null,
      uom2Data:[]
    },
  ],
  LineItemsData: [
    {
      id: 1,
      regularSalesOrderLineItemId: null,
      regularSalesOrderId: null,
      createdBy: "",
      createdDate: "",
      companyId: "",
      companyName: "",
      quotationId: "",
      quotationLineitemId: "",
      hsncode: "",
      itemGroupId: "",
      itemGroupValue: "",
      itemGroupName: "",
      itemId: null,
      itemName: "",
      itemCode: "",
      itemValue: "",
      itemMake: "",
      quantity: "0.000",
      uom: "",
      rate: "0.000",
      doBalQty: "",
      invoiceBalQty: "",
      igstAmount: "",
      igstPercent: "",
      cgstAmount: "",
      cgstPercent: "",
      sgstAmount: "",
      sgstPercent: "",
      discountType: "",
      discountName: "",
      discount: "",
      discountedRate: "",
      discountAmount:"0.00",
      netAmount: "",
      totalAmount: "",
      remarks: "",
      taxPercent: "",
      isActive: 1,
      currency: "INR",
      status: "",
      updatedBy: null,
      updatedOn: null,
      ItemNameData: [],
    },
  ],
  SoJuteGovtLineItems: [
    {
      lineItemId: 1,
      salesOrderLineItemId: "",
      regularSalesOrderId: "",
      govtSalesOrderLineItemId: "",
      createdBy: "",
      createdDate: "",
      companyId: "",
      currency: "INR",
      hsnCode: "",
      itemGroupName: "",
      itemGroupValue: "",
      itemGroupId: null,
      itemId: null,
      itemName: "",
      itemCode: "",
      itemMake: "",
      quantity: "0.000",
      uom: "",
      rate: "0.000",
      doBalQty: "",
      invoiceBalQty: "",
      igstAmount: "0.0",
      igstPercent: "0.0",
      cgstAmount: "0.0",
      cgstPercent: "0.0",
      sgstAmount: "0.0",
      sgstPercent: "0.0",
      discountType: "",
      discount: "",
      discountedRate: "",
      discountAmount:"0.00",
      netAmount: "0.00",
      totalAmount: "0.00",
      taxPercent: null,
      remarks: "",
      rateUom: "",
      isActive: 1,
      govtIsActive:1,
      rateUom2: "",
      status: "",
      updatedBy: null,
      updatedOn: null,
      itemsListData: [],
      govtRateUom: "",
      govtRateCalCulatedValue: "",
      govtRateUom2: "",
      govtRateCalculatedValue2: "",
      govtQuantityUom: "",
      govtRelationValue: 0,
      govtQtyCalculatedValue: 0,
      govtQuantityUom2: "",
      govtRelationValue2: 0,
      govtQtyCalculatedValue2: 0,
      govtQuantityUom3: "",
      govtRelationValue3: 0,
      govtQtyCalculatedValue3: 0,
    },
    {
      lineItemId: 2,
      salesOrderLineItemId: "",
      regularSalesOrderId: "",
      govtSalesOrderLineItemId: "",
      createdBy: "",
      createdDate: "",
      companyId: "",
      currency: "INR",
      hsnCode: "",
      itemGroupName: "Other Charges",
      itemGroupValue: "997",
      itemGroupId: null,
      itemId: null,
      itemName: "",
      itemCode: "",
      itemMake: "",
      quantity: "0.000",
      uom: "",
      rate: "0.000",
      doBalQty: "",
      invoiceBalQty: "",
      igstAmount: "0.0",
      igstPercent: "0.0",
      cgstAmount: "0.0",
      cgstPercent: "0.0",
      sgstAmount: "0.0",
      sgstPercent: "0.0",
      discountType: "",
      discount: "",
      discountedRate: "",
      netAmount: "0.00",
      totalAmount: "0.00",
      taxPercent: null,
      remarks: "",
      rateUom: "",
      isActive: 1,
      govtIsActive:1,
      rateUom2: "",
      status: "",
      updatedBy: null,
      updatedOn: null,
      itemsListData: [],
      govtRateUom: "",
      govtRateCalCulatedValue: "",
      govtRateUom2: "",
      govtRateCalculatedValue2: "",
      govtQuantityUom: "",
      govtRelationValue: 0,
      govtQtyCalculatedValue: 0,
      govtQuantityUom2: "",
      govtRelationValue2: 0,
      govtQtyCalculatedValue2: 0,
      govtQuantityUom3: "",
      govtRelationValue3: 0,
      govtQtyCalculatedValue3: 0,
    },
  ],
  SoJuteGovtHeaders: {
    regularSalesId: "",
    regularSalesOrderLineItemId: "",
    govtSalesOrderId: "",
    salesNo: "",
    finYear: "",
    createdBy: "",
    createdDate: "",
    salesDate: moment().format("YYYY-MM-DD"),
    companyId: "",
    branchId: "",
    branchName: "",
    quotationId: "",
    customerId: null,
    customerName: "",
    customerLineItemId: "",
    brokerId: null,
    brokerName: "",
    billingTo: "",
    billingToAddress: "",
    billingToGST: "",
    billingContactNo: "",
    billingContactPerson: "",
    billingToEmail: "",
    shippingTo: "",
    shippingToAddress: "",
    shippingToGST: "",
    shippingContactNo: "",
    shippingContactPerson: "",
    shippingToEmail: "",
    salesOrderExpiryDate: null,
    challanNo: "",
    challanDate: "",
    taxType: "",
    brokerCommision: "",
    brokerCommisionPercent: "",
    footerNote: "",
    internalNote: "",
    termsConditionType: "",
    termsCondition: "",
    transporterId: "",
    transporterName: "",
    transporterAddress: "",
    transporterStateName: "",
    transporterStateCode: "",
    approveStatus: false,
    expiredDate: "",
    deliveryTerms: "",
    paymentTerms: "",
    deliveryDays: "",
    freightCharges: "",
    amountWithoutTax: "",
    amountWithTax: "",
    updatedBy: "",
    updatedOn: "",
    approvedBy: "",
    approvedBydesc: "",
    approvedOn: "",
    statusId: "",
    statusName: "",
    doStatus: "",
    invoiceTypeId: "",
    totalIGST: "0.00",
    totalCGST: "0.00",
    totalSGST: "0.00",
    netTotal: "0.00",
    taxTotal: "0.00",
    TotalAmount: "0.00",
    subTotal: "0.00",
    pcsoNo: "",
    pcsoDate: moment().format("YYYY-MM-DD"),
    ammendmendNo: "",
    ammendmendDate: "",
    ammendmendNo2: "",
    ammendmendDate2: "",
    ammendmendNo3: "",
    ammendmendDate3: "",
    ammendmendNo4: "",
    ammendmendDate4: "",
    ammendmendNo5: "",
    ammendmendDate5: "",
    ApproveButton: false,
    RejectButton: false,
    UpdateButton: true,
  },

  //For Jute Hessian
  SoJuteHessianHeaders: {
    finYear: "",
    createdBy: "",
    createdDate: "",
    salesDate: moment().format("YYYY-MM-DD"),
    companyId: "",
    branchId: "",
    quotationId: "",
    transporterId: "",
    customerId: "",
    customerLineItemId: "",
    brokerId: "",
    brokerName: "",
    salesNo: "",
    billingTo: "",
    shippingTo: "",
    salesOrderExpiryDate: null,
    challanNo: "",
    challanDate: "",
    taxType: "",
    brokerCommision: "",
    brokerCommisionPercent: "",
    footerNote: "",
    internalNote: "",
    termsConditionType: "",
    termsCondition: "",
    deliveryTerms: "",
    paymentTerms: "",
    deliveryDays: "",
    freightCharges: "",
    totalIGST: "0.00",
    totalCGST: "0.00",
    totalSGST: "0.00",
    netTotal: "0.00",
    taxTotal: "0.00",
    TotalAmount: "0.00",
    subTotal: "0.00",
    amountWithoutTax: "",
    amountWithTax: "",
    updatedBy: "",
    updatedOn: "",
    approvedBy: "",
    approvedOn: "",
    statusId: "",
    statusName: "",
    doStatus: "",
    invoiceTypeId: "",
    ApproveButton: false,
    RejectButton: false,
    UpdateButton: true,
    EditButton: false,
  },

  SoJuteHessianLineItems: [
    {
      id: 1,
      regularSalesOrderLineItemId: null,
      regularSalesOrderId: null,
      hessianSalesOrderLineItemId: null,
      createdBy: "",
      createdDate: "",
      companyId: "",
      hsnCode: "",
      itemGroupId: "",
      itemId: "",
      itemMake: "",
      quantity: "0.000",
      uom: "",
      uom2: "",
      rate: "",
      doBalQty: "",
      invoiceBalQty: "",
      igstAmount: "0.0",
      igstPercent: "0.0",
      cgstAmount: "0.0",
      cgstPercent: "0.0",
      sgstAmount: "0.0",
      sgstPercent: "0.0",
      discountType: "",
      discount: "",
      discountedRate: "",
      discountAmount:"0.00",
      netAmount: "0.0",
      totalAmount: "0.00",
      taxPercent: null,
      remarks: "",
      hessianRateIncludingBrokerage: "",
      hessianRateExcludingBrokerage: "",
      hessianQuantityUom: "",
      hessianQuantityUomId: "",
      hessianQuantityRelationValue: "",
      hessianQuantityCalculatedValue: "",
      hessianRateUom: "",
      hessianRateUomId: "",
      hessianRateRelationValue: "",
      hessianRateCalculatedValue: "",
      hessianQuantityUom2: "",
      hessianRemarks: "",
      isActive: 1,
      hessianIsActive:1,
      ItemNameData: [],
      uomMastersListHessian:[],
    },
  ],
  uomHessian:[],
  SoJuteYarnUomListData:[],
  RegularSoItemNameData:[],
  JuteGovtSoItemNameData:[],
  JuteYarnSoItemNameData:[],
  JuteHessianSoItemNameData:[]
};

const SalesOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SALESORDER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case SALESORDER_LIST_SUCCESSFULL:
      state = {
        ...state,
        SalesOrder_List: action.payload.response,
        loading: false,
      };
      break;
    case API_ERRORS:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;
    case GET_ALL_BROKER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_ALL_BROKER_SUCCESSFULL:
      state = {
        ...state,
        Broker_List: action.payload.response,
        loading: false,
      };
      break;
    case CREATE_SALES_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CREATE_SALES_ORDER_SUCCESSFULL:
      state = {
        ...state,
        Create_sales: action.payload.response,
        loading: false,
      };
      break;

    case UPDATE_SALES_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_SALES_ORDER_SUCCESSFULL:
      state = {
        ...state,
        Update_sales: action.payload.response,
        loading: false,
      };
      break;
    case SALES_ORDER_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;

    case SALES_ORDER_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        SalesById: action.payload.response,
        loading: false,
      };
      break;

    case GET_BRANCH:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_BRANCH_SUCCESSFULL:
      state = {
        ...state,
        BranchPropdata: action.payload.response,
        loading: false,
      };
      break;
    case ACCOUNT_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case ACCOUNT_LIST_SUCCESSFULL:
      state = {
        ...state,
        bankAccountList: action.payload.response,
        loading: false,
      };
      break;
    case SALES_ORDER_HEADER:
      state = {
        ...state,
        SalesOrderHeader: action.payload,
        loading: true,
      };
      break;

    case SALES_ORDER_LINE_ITEMS:
      state = {
        ...state,
        LineItemsData: action.payload,
      };
      break;
    // upload documents and delete documents
    case "UPDATE_SO_FILES":
      state = {
        ...state,
        SOFiles: {
          ...state.SOFiles,
          data: { ...state.SOFiles.data, data: action.payload },
        },
      };

    case UPLOAD_SO_FILES:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPLOAD_SO_FILES_SUCCESSFULL:
      state = {
        ...state,
        Uploaded_so_Files: action.payload.response,
        loading: false,
      };
      break;
    case DELETE_SO_FILE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_SO_FILE_SUCCESSFULL:
      state = {
        ...state,
        delete_File: action.payload.response,
        loading: false,
      };
      break;
      case GET_SO_FILES:
        state = {
          ...state,
          loading: true,
        };
        break;
      case SO_FILES_SUCCESSFULL:
        state = {
          ...state,
          SOFiles: action.payload.response,
          loading: false,
        };
        break;
        case CLEAR_ALL_SO_FILES:
        state = {
          ...state,
          allFiles: action.payload,
          loading: false,
        };
        break;
    //jute govt sales order
    case CREATE_JUTEGOVT_SALES_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CREATE_JUTEGOVT_SALES_ORDER_SUCCESSFULL:
      state = {
        ...state,
        Create_JuteGovt: action.payload.response,
        loading: false,
      };
      break;
    case UPDATE_JUTEGOVT_SALES_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_JUTEGOVT_SALES_ORDER_SUCCESSFULL:
      state = {
        ...state,
        Create_JuteGovt: action.payload.response,
        loading: false,
      };
      break;
    case SALES_ORDER_JUTEGOVT_HEADERS:
      state = {
        ...state,
        SoJuteGovtHeaders: action.payload,
        loading: true,
      };
      break;

    case SALES_ORDER_JUTEGOVT_LINE_ITEMS:
      state = {
        ...state,
        SoJuteGovtLineItems: action.payload,
        loading: true,
      };
      break;
    case TERMS_CONDITIONS_API:
      state = {
        ...state,
        loading: true,
      };
      break;
    case TERMS_CONDITIONS_API_SUCCESSFULL:
      state = {
        ...state,
        TermsConditions: action.payload.response.data,
        loading: true,
      };
      break;
    case GET_QUOTATION_RECORDS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_QUOTATION_RECORDS_SUCCESSFULL:
      state = {
        ...state,
        QuotationApprovedData: action.payload.response,
        loading: false,
      };
      break;

    case QUOTATION_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;

    case QUOTATION_VIEW_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        ViewByIdQuotation: action.payload.response,
        loading: false,
      };
      break;

    case QUOTE_VIEW_BY_ID_FOR_JUTEGOVT:
      state = {
        ...state,
        loading: true,
      };
      break;
    //jute yarn sales order
    case CREATE_JUTEYARN_SALES_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CREATE_JUTEYARN_SALES_ORDER_SUCCESSFULL:
      state = {
        ...state,
        Create_JuteYarn: action.payload.response,
        loading: false,
      };
      break;
    case UPDATE_JUTEYARN_SALES_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_JUTEYARN_SALES_ORDER_SUCCESSFULL:
      state = {
        ...state,
        Create_JuteYarn: action.payload.response,
        loading: false,
      };
      break;
    case SALES_ORDER_JUTEYARN_HEADERS:
      state = {
        ...state,
        SoJuteYarnHeaders: action.payload,
        loading: true,
      };
      break;

    case SALES_ORDER_JUTEYARN_LINE_ITEMS:
      state = {
        ...state,
        SoJuteYarnLineItems: action.payload,
        loading: true,
      };
      break;
    case QUOTE_VIEW_BY_ID_FOR_JUTEYARN:
      state = {
        ...state,
        loading: true,
      };
      break;

    case QUOTE_VIEW_BY_ID_FOR_JUTEYARN_SUCCESSFULL:
      state = {
        ...state,
        JuteYarnViewByIdQuotation: action.payload.response,
        loading: false,
      };
      break;
    case S_O_BY_ID_FOR_JUTEYARN:
      state = {
        ...state,
        loading: true,
      };
      break;

    case S_O_BY_ID_FOR_JUTEYARN_SUCCESSFULL:
      state = {
        ...state,
        JuteYarnById: action.payload.response,
        loading: false,
      };
      break;
    case GET_UOM_RELATION_VALUE_BY_UOM_CODE:
      state = {
        ...state,
        getUomByCode: action.payload.response,
        loading: true,
        dataLoaded: false,
      };
      break;

    case GET_UOM_RELATION_VALUE_BY_UOM_CODE_SUCCESSFULL:
      state = {
        ...state,
        getUomRelationByCode: action.payload.response,
        loading: false,
        dataLoaded: true,
      };
      break;

      case UOM_LIST_BASEDON_ITEM_ID_HESSIAN:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case UOM_LIST_BASEDON_ITEM_ID_HESSIAN_SUCCESS:
        state = {
          ...state,
          uomHessian: action.payload.response,
          loading: false,
        };
        break
    //For Jute Hessian
    case CREATE_JUTEHESSIAN_SALES_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case QUOTE_VIEW_BY_ID_FOR_JUTEGOVT_SUCCESSFULL:
      state = {
        ...state,
        quoteViewByIDRecordForJuteGovt: action.payload.response,
        loading: false,
      };
      break;
    case CREATE_JUTEHESSIAN_SALES_ORDER_SUCCESSFULL:
      state = {
        ...state,
        Create_JuteHessian: action.payload.response,
        loading: false,
      };
      break;
    case SALES_ORDER_JUTEHESSIAN_HEADERS:
      state = {
        ...state,
        SoJuteHessianHeaders: action.payload,
        loading: true,
      };
      break;

    case SALES_ORDER_JUTEHESSIAN_LINE_ITEMS:
      state = {
        ...state,
        SoJuteHessianLineItems: action.payload,
        loading: true,
      };
      break;

    case SALES_ORDER_JUTEHESSIAN_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;

    case SALES_ORDER_JUTEHESSIAN_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        SalesOrderJuteHessianById: action.payload.response,
        loading: false,
      };
      break;
    case UPDATE_JUTEHESSIAN_SALES_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_JUTEHESSIAN_SALES_ORDER_SUCCESSFULL:
      state = {
        ...state,
        UpdateJuteHessian: action.payload.response,
        loading: false,
      };
      break;

    case SO_JUTE_HESSIAN_QUOTATION_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;

    case SO_JUTE_HESSIAN_QUOTATION_VIEW_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        SOJuteHessianViewByIdQuotation: action.payload.response,
        loading: false,
      };
      break;
    case SALES_ORDER_ITEMS_BY_ITEM_GROUP_ID:
      state = {
        ...state,
        loading: true,
        dataLoaded: false,
      };
      break;

    case GET_UOM_RELATION_VALUE_HESSIAN:
      state = {
        ...state,
        loading: true,
        dataLoaded: false,
      };
      break;
    case GET_UOM_RELATION_VALUE_HESSIAN_SUCCESSFULL:
      state = {
        ...state,
        UomRelationValueHessianList: action.payload.response,
        loading: false,
        dataLoaded: true,
      };
      break;

    case SALES_ORDER_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL:
      state = {
        ...state,
        SalesOrderItemBy_ItemGroupId: action.payload.response,
        loading: false,
        dataLoaded: true,
      };
      break;

    case SALES_ORDER_HESSIAN_ITEMS_BY_ITEM_GROUP_ID:
      state = {
        ...state,
        loading: true,
        dataLoaded: false,
      };
      break;

    case SALES_ORDER_HESSIAN_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL:
      state = {
        ...state,
        SalesOrderHessianItemBy_ItemGroupId: action.payload.response,
        loading: false,
        dataLoaded: true,
      };
      break;
    //for clearing the props
    case CLEAR_SALES_ORDER_HEADER:
      state = {
        ...state,
        SalesOrderHeader: initialState.SalesOrderHeader,
      };
      break;
    case CLEAR_SALES_ORDER_LINEITEMS:
      state = {
        ...state,
        LineItemsData: [
          {
            id: 1,
            regularSalesOrderLineItemId: null,
            regularSalesOrderId: null,
            createdBy: "",
            createdDate: "",
            companyId: "",
            companyName: "",
            quotationId: "",
            quotationLineitemId: "",
            hsncode: "",
            itemGroupId: "",
            itemGroupValue: "",
            itemGroupName: "",
            itemId: null,
            itemName: "",
            itemCode: "",
            itemValue: "",
            itemMake: "",
            quantity: "0.000",
            uom: "",
            rate: "",
            doBalQty: "",
            invoiceBalQty: "",
            igstAmount: "",
            igstPercent: "",
            cgstAmount: "",
            cgstPercent: "",
            sgstAmount: "",
            sgstPercent: "",
            discountType: "",
            discountName: "",
            discount: "",
            discountedRate: "",
            discountAmount:"",
            netAmount: "",
            totalAmount: "",
            remarks: "",
            taxPercent: null,
            isActive: 1,
            currency: "INR",
            status: "",
            updatedBy: null,
            updatedOn: null,
          },
        ],
      };
      break;
    case CLEAR_SO_JUTEGOVT_HEADERS:
      state = {
        ...state,
        SoJuteGovtHeaders: initialState.SoJuteGovtHeaders,
      };
      break;
    case CLEAR_SO_JUTEGOVT_LINE_ITEMS:
      state = {
        ...state,
        SoJuteGovtLineItems: [
          {
            lineItemId: 1,
            salesOrderLineItemId: "",
            regularSalesOrderId: "",
            govtSalesOrderLineItemId: "",
            createdBy: "",
            createdDate: "",
            companyId: "",
            currency: "INR",
            hsnCode: "",
            itemGroupName: "",
            itemGroupValue: "",
            itemGroupId: null,
            itemId: null,
            itemName: "",
            itemCode: "",
            itemMake: "",
            quantity: "0.000",
            uom: "",
            rate: "0.000",
            doBalQty: "",
            invoiceBalQty: "",
            igstAmount: "0.0",
            igstPercent: "0.0",
            cgstAmount: "0.0",
            cgstPercent: "0.0",
            sgstAmount: "0.0",
            sgstPercent: "0.0",
            discountType: "",
            discount: "",
            discountedRate: "",
            discountAmount:"",
            netAmount: "0.00",
            totalAmount: "0.00",
            taxPercent: null,
            remarks: "",
            rateUom: "",
            isActive: 1,
            govtIsActive:1,
            rateUom2: "",
            status: "",
            updatedBy: null,
            updatedOn: null,
            itemsListData: [],
            govtRateUom: "",
            govtRateUom2: "",
            govtRateUom3: "",
            govtQuantityUom: "",
            govtQuantityUom2: "",
            govtQuantityUom3: "",
            govtRelationValue: 0,
            govtRelationValue2: 0,
            govtRelationValue3: 0,
            govtQtyCalculatedValue: 0,
            govtQtyCalculatedValue2: 0,
            govtQtyCalculatedValue3: 0,
            govtRateCalculatedValue: "",
            govtRateCalculatedValue2: "",
            govtRateCalculatedValue3: "",
          },
          {
            lineItemId: 2,
            salesOrderLineItemId: "",
            regularSalesOrderId: "",
            govtSalesOrderLineItemId: "",
            createdBy: "",
            createdDate: "",
            companyId: "",
            currency: "INR",
            hsnCode: "",
            itemGroupName: "Other Charges",
            itemGroupValue: "997",
            itemGroupId: null,
            itemId: null,
            itemName: "",
            itemCode: "",
            itemMake: "",
            quantity: "0.000",
            uom: "",
            rate: "0.000",
            doBalQty: "",
            invoiceBalQty: "",
            igstAmount: "0.0",
            igstPercent: "0.0",
            cgstAmount: "0.0",
            cgstPercent: "0.0",
            sgstAmount: "0.0",
            sgstPercent: "0.0",
            discountType: "",
            discount: "",
            discountedRate: "",
            netAmount: "0.00",
            totalAmount: "0.00",
            taxPercent: null,
            remarks: "",
            rateUom: "",
            isActive: 1,
            govtIsActive:1,
            status: "",
            updatedBy: null,
            updatedOn: null,
            itemsListData: [],
            govtRateUom: "",
            govtRateUom2: "",
            govtRateUom3: "",
            govtQuantityUom: "",
            govtQuantityUom2: "",
            govtQuantityUom3: "",
            govtRelationValue: 0,
            govtRelationValue2: 0,
            govtRelationValue3: 0,
            govtQtyCalculatedValue: 0,
            govtQtyCalculatedValue2: 0,
            govtQtyCalculatedValue3: 0,
            govtRateCalculatedValue: "",
            govtRateCalculatedValue2: "",
            govtRateCalculatedValue3: "",
          },
        ],
      };
      break;
    case CLEAR_SO_JUTEYARN_HEADERS:
      state = {
        ...state,
        SoJuteYarnHeaders: initialState.SoJuteYarnHeaders,
      };
      break;
    case CLEAR_SO_JUTEYARN_LINE_ITEMS:
      state = {
        ...state,
        SoJuteYarnLineItems: [
          {
            id: 1,
            salesOrderLineItemDTO: "",
            regularSalesOrderLineItemId: "",
            regularSalesOrderId: "",
            yarnSalesOrderLineItemId: "",
            createdBy: "",
            createdDate: "",
            companyId: "",
            currency: "INR",
            hsnCode: "",
            itemGroupName: "",
            itemGroupValue: "",
            itemGroupId: null,
            itemId: null,
            itemName: "",
            itemCode: "",
            itemMake: "",
            quantity: "0.000",
            uom: "",
            rate: "0.000",
            doBalQty: "",
            invoiceBalQty: "",
            igstAmount: "0.0",
            igstPercent: "0.0",
            cgstAmount: "0.0",
            cgstPercent: "0.0",
            sgstAmount: "0.0",
            sgstPercent: "0.0",
            discountType: "",
            discount: "",
            discountedRate: "",
            discountAmount:"",
            netAmount: "0.00",
            totalAmount: "0.00",
            taxPercent: null,
            remarks: "",
            rateUom: "",
            isActive: 1,
            yarnIsActive:1,
            yarnRateUom: "",
            yarnRateUomId: "",
            yarnRateUomRelationValue: "",
            yarnRateCalculatedValue: "",
            yarnQuantityUom: "",
            yarnQuantityUomId: "",
            yarnQuantityUomRelationValue: "",
            yarnQuantityCalculatedValue: "",
            yarnVariableRelationValue: "",
            yarnRateIncludingBrokerage: "",
            yarnRateExcludingBrokerage:"",
            status: "",
            updatedBy: null,
            updatedOn: null,
            itemsListData: [],
          },
        ],
      };
      break;
    case CLEAR_SO_JUTEHESSIAN_HEADERS:
      state = {
        ...state,
        SoJuteHessianHeaders: initialState.SoJuteHessianHeaders,
      };
      break;
    case CLEAR_SO_JUTEHESSIAN_LINE_ITEMS:
      state = {
        ...state,
        SoJuteHessianLineItems: [
          {
            id: 1,
            regularSalesOrderLineItemId: null,
            regularSalesOrderId: null,
            hessianSalesOrderLineItemId: null,
            createdBy: "",
            createdDate: "",
            companyId: "",
            hsnCode: "",
            itemGroupId: "",
            itemId: "",
            itemMake: "",
            quantity: "",
            uom: "",
            rate: "",
            doBalQty: "",
            invoiceBalQty: "",
            igstAmount: "",
            igstPercent: "",
            cgstAmount: "",
            cgstPercent: "",
            sgstAmount: "",
            sgstPercent: "",
            discountType: "",
            discount: "",
            discountedRate: "",
            discountAmount:"",
            netAmount: "",
            totalAmount: "",
            remarks: "",
            taxPercent: null,
            hessianRateIncludingBrokerage: "",
            hessianQuantityUom2: "",
            hessianRateExcludingBrokerage: "",
            hessianQuantityUom: "",
            hessianQuantityUomId: "",
            hessianQuantityRelationValue: "",
            hessianQuantityCalculatedValue: "",
            hessianRateUom: "",
            hessianRateUomId: "",
            hessianRateRelationValue: "",
            hessianRateCalculatedValue: "",
            hessianRemarks: "",
            isActive: 1,
            hessianIsActive:1,
            ItemNameData: [],
            uomMastersListHessian:[],
          },
        ],
      };
      break;


      case SO_JUTE_YARN_UOM_LIST:
        state = {
          ...state,
          loading: true,
          dataLoaded: false,
        };
        break;
      case SO_JUTE_YARN_UOM_LIST_SUCCESSFULL:
        state = {
          ...state,
          SoJuteYarnUomListData: action.payload.response,
          loading: false,
          dataLoaded: true,
        };
        break;

        case REGULAR_SO_ITEM_NAME_DATA:
          state = {
            ...state,
            loading: true,
          };
          break;
    
        case REGULAR_SO_ITEM_NAME_DATA_SUCCESSFULL:
          state = {
            ...state,
            RegularSoItemNameData: action.payload.response,
            loading: false,
          };
          break;

          
        case JUTE_GOVT_SO_ITEM_NAME_DATA:
          state = {
            ...state,
            loading: true,
          };
          break;
    
        case JUTE_GOVT_SO_ITEM_NAME_DATA_SUCCESSFULL:
          state = {
            ...state,
            JuteGovtSoItemNameData: action.payload.response,
            loading: false,
          };
          break;
          case JUTE_YARN_SO_ITEM_NAME_DATA:
            state = {
              ...state,
              loading: true,
            };
            break;
      
          case JUTE_YARN_SO_ITEM_NAME_DATA_SUCCESSFULL:
            state = {
              ...state,
              JuteYarnSoItemNameData: action.payload.response,
              loading: false,
            };
            break;
            case JUTE_HESSIAN_SO_ITEM_NAME_DATA:
              state = {
                ...state,
                loading: true,
              };
              break;
        
            case JUTE_HESSIAN_SO_ITEM_NAME_DATA_SUCCESSFULL:
              state = {
                ...state,
                JuteHessianSoItemNameData: action.payload.response,
                loading: false,
              };
              break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default SalesOrderReducer;
