import React, { Component } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import VowLogo from "../../assets/images/vow_logo.png";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import IconButton from "@mui/material/IconButton";
import CustomButton from "../../components/Buttons/Buttons";
import Checkbox from "../../components/CheckBox/Checkbox";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// actions
import { resetpassword, apiError } from "../../store/auth/login/actions";

import { serverApi } from "../../helpers/Consts";

class changePassword extends Component {
  constructor(props) {
    super(props);
    this.state = { username: "su", password: "welcome" };
    this.state = {
      show: "false",
      hidden: "false",
      passwordExist: "",
      passwordVerify: "",
      message: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputOnChange = this.handleInputOnChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputOnChangeShowPassword =
      this.handleInputOnChangeShowPassword.bind(this);
    this.handleInputChangeNewPassword =
      this.handleInputChangeNewPassword.bind(this);
  }
  handleInputOnChange(event) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  }
  handleInputOnChangeShowPassword(event) {
    event.preventDefault();

    this.setState({ hidden: !this.state.hidden });
  }
  handleInputChangeNewPassword(event) {
    event.preventDefault();
    this.setState({ hiddenShow: !this.state.hiddenShow });
  }
  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    if (this.state.passwordExist) {
      if (target.value !== this.state.passwordExist) {
        this.setState({ message: "Passwords must be same" });
      } else {
        this.setState({ message: "" });
      }
    } else {
      this.setState({ message: "Sorry! Enter Password" });
    }
    this.setState({
      [target.name]: target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.passwordExist === this.state.passwordVerify) {
      let oldpassword = "welcome";
      let password = this.state.passwordVerify;
      let reqSource = 0;
      let userDit = JSON.parse(localStorage.getItem("authUser"));
      let userId = userDit.userId;

      const data = {
        oldpassword,
        password,
        reqSource,
        userId,
      };

      /* Login  */
      this.props.resetpassword(
        serverApi.MODIFY_PASSWORD,
        data,
        this.props.history
      );
    } else {
      this.setState({ message: "Passwords must be same" });
    }
  }

  render() {
    const { show } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          sx={{ height: "100vh" }}
          className="loginBackground"
        >
          <Grid
            container
            component="main"
            sx={{ height: "100vh" }}
            className="loginPatternOne"
          >
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              component={Paper}
              elevation={6}
              square
              className="loginContainer"
            >
              <Box
                className="loginBlock"
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={VowLogo} alt="" />
                <Typography component="h1">Change Password</Typography>
                <Typography component="p">
                  Change Password and proceed with the company login process
                </Typography>
                <div className="changePasswordBlock">
                  <form
                    className="changePassword"
                    spacing={2}
                    onSubmit={this.handleSubmit}
                  >
                    <label>Current Password</label>
                    <div className="resetPasswordTextField">
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="passwordNew"
                        value={this.state.passwordNew}
                        onChange={this.handleInputChangeNew}
                        autoComplete="current-password"
                        autoFocus
                        className="loginInput"
                        placeholder="Current Password"
                        type={this.state.hiddenShow ? "text" : "password"}
                      />
                      <IconButton
                        onClick={this.handleInputChangeNewPassword}
                        className="displayIcon"
                      >
                        {this.state.hiddenShow ? (
                          <VisibilityRoundedIcon />
                        ) : (
                          <VisibilityOffRoundedIcon />
                        )}
                      </IconButton>
                    </div>
                    <label>New Password</label>
                    <div className="resetPasswordTextField">
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="passwordExist"
                        value={this.state.passwordExist}
                        onChange={this.handleInputOnChange}
                        autoComplete="current-password"
                        autoFocus
                        className="loginInput"
                        placeholder="New Password"
                        type={this.state.hidden ? "password" : "text"}
                      />
                      <IconButton
                        onClick={this.handleInputOnChangeShowPassword}
                        className="displayIcon"
                      >
                        {this.state.hidden ? (
                          <VisibilityOffRoundedIcon />
                        ) : (
                          <VisibilityRoundedIcon />
                        )}
                      </IconButton>
                    </div>
                    <label>Re-enter New Password</label>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="passwordVerify"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={this.state.passwordVerify}
                      onChange={this.handleInputChange}
                      className="loginInput"
                      placeholder="Re-enter New Password"
                    />
                    <div
                      style={{ display: show ? "none" : "block" }}
                      className="resetPasswordErrorMessage"
                    >
                      Passwords must be same
                    </div>
                    <div className="">
                      <Checkbox label="Keep me signed in for other devices" />
                      <p className="changePasswordSigned"> </p>
                    </div>
                    <div
                      className="consoleFormButtonBlock"
                      sx={{ padding: "0px" }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <CustomButton
                            label="Cancel"
                            className="greenBorderButton"
                            handleClick={this.handleClose}
                            type="button"
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <CustomButton
                            label="Change Password"
                            className="greenButton"
                            type="submit"
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </form>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}
const theme = createTheme();
const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(
  connect(mapStatetoProps, { resetpassword, apiError })(changePassword)
);
