import {
  CUTS_FROM_CUTS,
  CUTS_FROM_CUTS_SUCCESSFULL,
  WEAVING_LIST,
  WEAVING_LIST_SUCCESSFULL,
  WEAVING_PRODUCTION_ADD,
  WEAVING_PRODUCTION_ADD_SUCCESSFULL,
  WEAVING_PRODUCTION_DELETE,
  WEAVING_PRODUCTION_DELETE_SUCCESSFULL,
} from "./actionTypes";

export const weavingList = (url, data, history) => {
  return {
    type: WEAVING_LIST,
    payload: {url, data, history}
  }
}
export const weavingListSuccessfull = (data) => {
  return {
    type: WEAVING_LIST_SUCCESSFULL,
    payload: data
  }
}

export const cutsListAction = (url, data, history) => {
  return {
    type: CUTS_FROM_CUTS,
    payload: {url, data, history}
  }
}
export const cutsListActionSuccessfull = (data) => {
  return {
    type: CUTS_FROM_CUTS_SUCCESSFULL,
    payload: data
  }
}
/********Create Weaving Production*******/
export const WeavingProductionCreateAction = (url, data, history) => {
  return {
    type: WEAVING_PRODUCTION_ADD,
    payload: {url, data, history}
  }
}
export const WeavingProductionCreateActionSuccessfull = (data) => {
  return {
    type: WEAVING_PRODUCTION_ADD_SUCCESSFULL,
    payload: data
  }
}
// delete waeving production
export const WeavingProductionDelete = (url,history, fromDate, toDate) => {
  return {
    type: WEAVING_PRODUCTION_DELETE,
    payload: {url, history, fromDate, toDate}
  }
}
export const WeavingProductionDeleteSuccessfull = (data) => {
  return {
    type: WEAVING_PRODUCTION_DELETE_SUCCESSFULL,
    payload: data
  }
}