import React, { useState, useEffect } from "react";
import { Box, Grid, Tooltip } from "@mui/material";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import InputAdornment from "../../../components/InputAdorment/InputAdorment";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextArea from "../../../components/TextField/TextArea";
import cons, { serverApi, serverConfig } from "../../../helpers/Consts";
import moment from "moment";
import {
  getBranchList,
  getMukamList,
} from "../../../store/Global/DropDownApis/actions";
import { getSupplierByMukamIdList } from "../../../store/MasterDropdownApis/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {JuteQualityCheckHeader} from "../../../store/Jute/JuteQualityCheck/actions.js";
import {
  isEmpty,
  isEmptyWithZero,
  limitDecimals,
} from "../../../Utilities/helper.js";
import swal from "sweetalert";
const JuteQualityCheckFillDetails = (props) => {
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [ProjectData, setProjectData] = useState([]);
  const [ApprovedPoData, setApprovedPoData] = useState([]);
  const [MukamData, setMukamData] = useState([]);
  const [SupplierByMukamListData, setSupplierByMukamList] = useState([]);
  const [VehicleTypeList, setVehicleTypeList] = useState([]);
  const [BrokerList, setBrokerList] = useState([]);
  const [HeaderData, setHeaderData] = useState({});
  const [LineItems, setLineItems] = useState([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const dispatch = useDispatch();
  let history = useHistory();
  const [locationState] = useState(useLocation());

  const { qualityCheckHeader, juteQualityCheckLineItems } = useSelector(
    (state) => state.JuteQualityCheckReducer
  );
  const { branchList, mukamList } = useSelector(
    (state) => state.DropDownListReducer
  );
  const { vehicleTypeList, SupplierByMukamList, BrokerBySupplierId } =
    useSelector((state) => state.MasterDropDownListReducer);

  useEffect(() => {
    setHeaderData(qualityCheckHeader);
    setLineItems(juteQualityCheckLineItems);
  }, [qualityCheckHeader, juteQualityCheckLineItems]);


  useEffect(() => {
    dispatch(
      getBranchList(
        serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
        history
      )
    );

    dispatch(
      getMukamList(
        serverApi.MUKAM_GET_LIST + localStorage.getItem("companyId"),
        history
      )
    );


    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (branchList) {
      if (branchList.data) {
        let list = branchList.data.filter((props) => {
          if (props.value !== 0) {
            return props;
          }
        });
        var ListData = [];
        list.map((item) => {
          ListData.push({
            value: item.value,
            label: item.label,
            name: item.label,
          });
        });
        setProjectData(ListData);
      }
    }
    if (mukamList) {
      if (mukamList.data) {
        let list = mukamList.data.filter((props) => {
          if (props.value !== 0) {
            return props;
          }
        });
        setMukamData(list);
      }
    }
    if (SupplierByMukamList) {
      if (SupplierByMukamList.data) {
        let FilteredList = SupplierByMukamList.data.filter((props) => {
          if (props.value !== "0") {
            return props;
          }
        });
        setSupplierByMukamList(FilteredList);
      }
    }

  }, [branchList, SupplierByMukamList, mukamList]);
  const handleScroll = () => {
    setDropdownOpen(true);
  };
  const handleMenuOpen = () => {
    setDropdownOpen(false);
  };
  const handleSelectDate = (e, name) => {
    if (name === "inDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      if(HeaderData.JuteGateEntryType == "WITHPO"){
       if(Date < HeaderData.poDate){
        swal("Date Shouldn't be Less than Po Date")
        setHeaderData((prev) => ({
          ...prev,
          inDate: null,
        }));
        const UpdatedRec = {
          ...HeaderData,
          inDate:  null,
        };
        dispatch(JuteQualityCheckHeader(UpdatedRec));
       }else{
        setHeaderData((prev) => ({
          ...prev,
          inDate: Date,
        }));
        const UpdatedRec = {
          ...HeaderData,
          inDate: Date,
        };
        dispatch(JuteQualityCheckHeader(UpdatedRec));
       }
      }else{
        setHeaderData((prev) => ({
          ...prev,
          inDate: Date,
        }));
        const UpdatedRec = {
          ...HeaderData,
          inDate: Date,
        };
        dispatch(JuteQualityCheckHeader(UpdatedRec));
      }
    }

    if (name === "outDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      if(Date<HeaderData.inDate){
        swal('Out Date Should not be less than In Date')
        setHeaderData((prev) => ({
          ...prev,
          outDate: null,
        }));
        const UpdatedRec = {
          ...HeaderData,
          outDate: null,
        };
        dispatch(JuteQualityCheckHeader(UpdatedRec));
      }
      else{
      setHeaderData((prev) => ({
        ...prev,
        outDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        outDate: Date,
      };
      dispatch(JuteQualityCheckHeader(UpdatedRec));
    }
    }
    
    if (name === "chalanDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      if(Date < HeaderData.inDate){
        swal("Chalan Date Shouldn't be Less than Gate Entry Date")
        setHeaderData((prev) => ({
          ...prev,
          chalanDate: null,
        }));
        const UpdatedRec = {
          ...HeaderData,
          chalanDate:  null,
        };
        dispatch(JuteQualityCheckHeader(UpdatedRec));
       }else
      {setHeaderData((prev) => ({
        ...prev,
        chalanDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        chalanDate: Date,
      };
      dispatch(JuteQualityCheckHeader(UpdatedRec));
    }
    }
  };
  const handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "branchId") {
      setHeaderData((prev) => ({
        ...prev,
        branchId: selectedValue,
        branchname: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        branchId: selectedValue,
        branchName: selectedName,
      };
      dispatch(JuteQualityCheckHeader(UpdatedRec));
    }

    if (name === "unitConversion") {
      setHeaderData((prev) => ({
        ...prev,
        unitConversion: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        unitConversion: selectedName,
      };
      dispatch(JuteQualityCheckHeader(UpdatedRec));
    }
    if (name === "suppCode") {
      setHeaderData((prev) => ({
        ...prev,
        suppCode: selectedValue,
        supplierName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        suppCode: selectedValue,
        supplierName: selectedName,
      };
      dispatch(JuteQualityCheckHeader(UpdatedRec));
    }
    if (name === "mukam") {
      setHeaderData((prev) => ({
        ...prev,
        mukam: selectedValue,
        mukamName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        mukam: selectedValue,
        mukamName: selectedName,
      };
      dispatch(JuteQualityCheckHeader(UpdatedRec));
      dispatch(
        getSupplierByMukamIdList(
          serverApi.GET_SUPPLIER_BY_MUKAM_ID +
            selectedValue +
            "/company/" +
            localStorage.getItem("companyId"),
          props.history
        )
      );
    }

    if (name === "supplierId") {
      setHeaderData((prev) => ({
        ...prev,
        supplierId: selectedValue,
        suppName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        supplierId: selectedValue,
        suppName: selectedName,
      };
      dispatch(JuteQualityCheckHeader(UpdatedRec));
    }
  };

  const onhandlechangeValue = (key) => (event) => {
    if (key === "chalanNo") {
      setHeaderData((prev) => ({
        ...prev,
        chalanNo: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        chalanNo: event,
      };
      dispatch(JuteQualityCheckHeader(UpdatedRec));
    }
    if (key === "vehicleNo") {
      setHeaderData((prev) => ({
        ...prev,
        vehicleNo: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        vehicleNo: event,
      };
      dispatch(JuteQualityCheckHeader(UpdatedRec));
    }
    if (key === "driverName") {
      setHeaderData((prev) => ({
        ...prev,
        driverName: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        driverName: event,
      };
      dispatch(JuteQualityCheckHeader(UpdatedRec));
    }
    if (key === "transporter") {
      setHeaderData((prev) => ({
        ...prev,
        transporter: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        transporter: event,
      };
      dispatch(JuteQualityCheckHeader(UpdatedRec));
    }
    if (key === "remarks") {
      setHeaderData((prev) => ({
        ...prev,
        remarks: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        remarks: event,
      };
      dispatch(JuteQualityCheckHeader(UpdatedRec));
    }
  };
console.log(qualityCheckHeader);

  return (
    <div className="juteIndent">
      <div className="consoleFormContainer">
        <h5>Fill Details</h5>
        <div className="consoleFormContainer">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div>
                      <div className="TextFieldBlock">
                        <label>
                          Date <div className="mandatoryField">*</div>
                        </label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          value={HeaderData.inDate}
                          fullWidth
                          onChange={(e) => handleSelectDate(e, "inDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="TextFieldBlock">
                      <label>
                        Time <div className="mandatoryField">*</div>
                      </label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="Time"
                        value={HeaderData.inTime}
                        onChange={(newValue) => {
                          setHeaderData((prev) => ({
                            ...prev,
                            inTime: newValue.getTime(),
                          }));
                          const UpdatedRec = {
                            ...HeaderData,
                            inTime: newValue.getTime(),
                          };
                          dispatch(JuteQualityCheckHeader(UpdatedRec));
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input ref={inputRef} {...inputProps} />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Challan Number"
                      placeholder="Enter here"
                      value={HeaderData.chalanNo}
                      disabled={true}
                      onChange={onhandlechangeValue("chalanNo")}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div>
                      <div className="TextFieldBlock">
                        <label>Challan Date</label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          value={HeaderData.chalanDate}
                          fullWidth
                          onChange={(e) => handleSelectDate(e, "chalanDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Vehicle Number"
                      placeholder="Enter here"
                      value={HeaderData.vehicleNo}
                      onChange={onhandlechangeValue("vehicleNo")}
                      disabled={true}
                      required
                      caps="ON"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Driver Name"
                      placeholder="Enter here"
                      value={HeaderData.driverName}
                      disabled={true}
                      onChange={onhandlechangeValue("driverName")}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Transporter"
                      placeholder="Enter here"
                      value={HeaderData.transporter}
                      disabled={true}
                      onChange={onhandlechangeValue("transporter")}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={ProjectData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.branchId}
                        isDisabled={true}
                        update={HeaderData.branchId ? 1 : 0}
                        name="branchId"
                        label="Branch"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectNew
                        arrayOfData={UnitConversionData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        isDisabled={true}
                        selected={HeaderData.unitConversion}
                        name="unitConversion"
                        label="Unit Convertion"
                        update={HeaderData.unitConversion ? 1 : 0}
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={MukamData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.mukam}
                        isDisabled={true}
                        name="mukam"
                        label="Mukam"
                        required
                        update={HeaderData.mukam ? 1 : 0}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div
                      className="consoleTextFieldBlock"
                    >
                      <DynamicSelect
                        arrayOfData={SupplierByMukamListData}
                        update={HeaderData.suppCode ? 1 : 0}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.suppCode}
                        isDisabled={locationState.state ? true : false}
                        name="suppCode"
                        label="Supplier"
                        required
                      />
                    </div>
                  </Box> 
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextArea
                        label="Remarks"
                        onChange={onhandlechangeValue("remarks")}
                        value={HeaderData.remarks}
                      />
                    </div>
                  </Box>         
                  
                </Box>
              </Grid>
            </Grid>
        
          </div>
        </div>
      </div>
    </div>
  );
};
const UnitConversionData = [
  {
    value: 1,
    label: "LOOSE",
    name: "LOOSE",
  },
  {
    value: 2,
    label: "BALE",
    name: "BALE",
  },
];
export default JuteQualityCheckFillDetails;
