//import { Switch } from "react-router-dom";

import {
    // API_ERRORS,
    ITEMGROUP_LIST,CREATE_ITEMGROUP_MASTER,UPDATE_ITEMGROUP_MASTER,
    ITEMGROUP_LIST_SUCCESSFULL,CREATE_ITEMGROUP_MASTER_SUCCESSFULL,UPDATE_ITEMGROUP_MASTER_SUCCESSFULL,
    ITEM_GROUP_VIEW_BY_ID, ITEM_GROUP_VIEW_BY_ID_SUCCESSFULLY, ITEM_GROUP_DROPDOWN_API, ITEM_GROUP_DROPDOWN_API_SUCCESSFULL
  } from "./actionTypes";
  
  const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    ItemGroup_List: [],
    CreateItemGroupMaster: [],
    UpdateItemGroupMaster: [],
    error_msg: null,
    getViewByIdresp: [],
    ItemGroupList:[],
  };
  
  const ItemGroupListReducer = (state = initialState, action) => {
    switch (action.type) {
      case ITEMGROUP_LIST:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case ITEMGROUP_LIST_SUCCESSFULL:
        state = {
          ...state,
          ItemGroup_List: action.payload.response,
          loading: false,
        };
        break;
        case CREATE_ITEMGROUP_MASTER:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case CREATE_ITEMGROUP_MASTER_SUCCESSFULL:
        state = {
          ...state,
          CreateItemGroupMaster: action.payload.response,
          loading: false,
        };
        break;
        case UPDATE_ITEMGROUP_MASTER:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case UPDATE_ITEMGROUP_MASTER_SUCCESSFULL:
        state = {
          ...state,
          UpdateItemGroupMaster: action.payload.response,
          loading: false,
        };
        break;

        case ITEM_GROUP_VIEW_BY_ID:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case ITEM_GROUP_VIEW_BY_ID_SUCCESSFULLY:
        state = {
          ...state,
          getViewByIdresp: action.payload.response,
          loading: false,
        };
        break;

      case ITEM_GROUP_DROPDOWN_API:  
      state={
        ...state,
        loading:true,
      };
      break;

      case ITEM_GROUP_DROPDOWN_API_SUCCESSFULL:
        state={
          ...state,
          ItemGroupList:action.payload.response,
          loading:false
        };
        break;
        
      default:
        state = { ...state };
        break;
    }
  
    return state;
  };
  
  export default ItemGroupListReducer;
  