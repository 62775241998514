export const ITEM_LISTS = "item_lists";
export const ITEM_LISTS_SUCCESSFULL = "item_lists_successfull";
export const CREATE_ITEM_MASTER = "create_item_master";
export const CREATE_ITEM_MASTER_SUCCESSFULL = "create_item_master_successfull";
export const UPDATE_ITEM_MASTER = "update_item_master";
export const UPDATE_ITEM_MASTER_SUCCESSFULL = "update_item_master_successfull";
// export const API_ERRORS = 'api_failed';
export const ITEM_CODE_BY_ITEM_GROUP = "item_code_by_item_group";
export const ITEM_CODE_BY_ITEM_GROUP_SUCCESSFULL = "item_code_by_item_group_successfull";
export const INDENT_TYPE_LIST_MASTERS = 'indent_type_list_masters';
export const INDENT_TYPE_LIST_MASTERS_SUCCESSFULL = 'indent_type_list_masters_successfull';
export const CREATE_LEAVE_TYPE_MASTER = "create_leave_master_type";
export const CREATE_LEAVE_TYPE_MASTER_SUCCESSFULL = "create_leave_type_master_successfull";
export const GET_ITEM_CODE_BY_ITEM_GROUP = "get_itemCode_by_item_id";
export const GET_ITEM_CODE_BY_ITEM_GROUP_SUCCESSFULL = "get_itemCode_by_item_id_successfull";
export const GET_UOM_IN_ITEM_MASTER = "get_uom_in_item_master";
export const GET_UOM_IN_ITEM_MASTER_SUCCESSFULLY = "get_uom_in_item_master_successfully";
export const GET_ITEM_MASTER_VIEW_BY_ID = "get_item_master_view_by_id";
export const GET_ITEM_MASTER_VIEW_BY_ID_SUCCESSFULL = "get_item_master_view_by_id_successfully";



