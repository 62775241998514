import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import {
  getLogs,
  getProcessLogs,
} from "../../../store/HRMS/AttendanceCalender/actions";
import CustomButton from "../../../components/Buttons/Buttons";
import moment from "moment";
class AttendanceLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      LogList: [],
      From_date: moment().day(-7).format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
    };
  }
  componentDidMount() {
    var data = {
      companyid: this.state.companyId,
      employeeid: "",
      from: this.state.From_date,
      to: this.state.toDate,
    };
    this.props.getLogs(serverApi.LOGS, data, this.props.history);
  }
  componentWillReceiveProps(props) {
    if (props.logs) {
      this.setState({
        LogList: props.logs.data,
      });
    }
  }
  handleProcessLogs = () => {
    this.props.getProcessLogs(
      serverApi.PROCESS_LOGS +
        this.state.From_date +
        "/" +
        this.state.toDate +
        "/" +
        this.state.userDit.userId,
      this.props.history
    );
  };
  handleSearch = (data1) => {
    const data = {
      companyid: data1.companyid,
      employeeid: data1.employeeid,
      from: data1.from,
      to: data1.to,
    };
    this.props.getLogs(serverApi.LOGS, data, this.props.history);
  };
  handlegetdates = (fromdate, todate) => {
    this.setState({
      From_date: fromdate,
      toDate: todate,
    });
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          From_date={this.state.From_date}
          toDate={this.state.toDate}
          mdata={this.state.LogList}
          mcolumns={Columns}
          micon={GridArrowIcon}
          actions={false}
          filter_form={"AttendanceLogs"}
          handleSearch={this.handleSearch}
          handlegetdates={this.handlegetdates}
        />
      </>
    );
  }
}
const Columns = [
  {
    Header: "Employee Code",
    accessor: "employeeNo",
    hidden: false,
  },
  {
    Header: "Employee Name",
    accessor: "workerName",
    hidden: false,
  },
  {
    Header: "Reader",
    accessor: "deviceName",
    hidden: false,
  },
  {
    Header: "Location",
    accessor: "location_description",
    hidden: false,
  },
  {
    Header: "Network",
    accessor: "network_Name",
    hidden: false,
  },
  {
    Header: "Ip Address",
    accessor: "ip_Address",
    hidden: false,
  },
  {
    Header: "Remarks",
    accessor: "remarks",
    hidden: false,
  },
  {
    Header: "Transaction Time",
    accessor: "transaction_time",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "statusDesc",
    hidden: false,
  },
  {
    Header: "Id",
    accessor: "id",
    hidden: false,
  },
  {
    Header: "Employee Id",
    accessor: "employeeid",
    hidden: true,
  },
  {
    Header: "Status Id",
    accessor: "status",
    hidden: true,
  },
  {
    Header: "Reader Id",
    accessor: "reader_id",
    hidden: true,
  },
  {
    Header: "Source",
    accessor: "source",
    hidden: true,
  },
  {
    Header: "Pending",
    accessor: "pending",
    hidden: true,
  },
  {
    Header: "Company Id",
    accessor: "companyId",
    hidden: true,
  },
  {
    Header: "Latitude",
    accessor: "latitude",
    hidden: true,
  },
  {
    Header: "Longitude",
    accessor: "longitude",
    hidden: true,
  },
  {
    Header: "Ip Address",
    accessor: "ip_Address",
    hidden: true,
  },
];
const mapStatetoProps = (state) => {
  const { logs, ProcessLogs } = state.AttendanceReducer;
  return { logs, ProcessLogs };
};

export default withRouter(
  connect(mapStatetoProps, {
    getLogs,
    getProcessLogs,
  })(AttendanceLogs)
);
