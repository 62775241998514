import { Component } from "react";
import { connect } from "react-redux";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import { withRouter } from "react-router-dom";
import {
  finishingEntryActionList,
  finishingEntryActionListDelete,
} from "../../../store/JuteProduction/FinishingEntry/actions";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridDeleteIcon from "../../../assets/images/deleteIconButton.png";
import { deleteFinishingEntry } from "../../../store/JuteProduction/FinishingEntry/actions";

class FinishingEntryIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      startIndex: "",
      lastIndex: "",
      FinishingEntryListContent: [],
      FinishingEntryListDelContent: [],
    };
  }
  componentDidMount() {
    const data = {
      companyId: localStorage.getItem("companyId"),
      fromDate: "10-09-1989",
      toDate: "10-09-1989",
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
    };
    this.props.finishingEntryActionList(
      serverApi.GET_FINISHING_ENTRIES,
      data,
      this.props.history
    );
  }

  handleSearch = (searchData) => {
    const data = {
      companyId: localStorage.getItem("companyId"),
      fromDate: searchData.fromDate,
      toDate: searchData.toDate,
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
    };
    this.props.finishingEntryActionList(
      serverApi.GET_FINISHING_ENTRIES,
      data,
      this.props.history
    );
    this.setState({
      fromDate: searchData.fromDate,
      toDate: searchData.toDate,
    });
    showSpinner();
  };
  componentWillReceiveProps(props) {
    if (props.finishingEntryActionList) {
      this.setState({
        FinishingEntryListContent: props.FinishingEntryListData.data,
      });
      hideSpinner();
    }
    if (props.finishingEntryActionListDelete) {
      this.setState({
        FinishingEntryListDelContent: props.FinishingEntryListDelete,
      });
      hideSpinner();
    }
  }
  handledeleteRecord = (row) => {
    this.props.deleteFinishingEntry(
      serverApi.DELETE_FINISHING_ENTRY +
        row.id +
        "/" +
        this.state.userDit.cipher,
      this.props.history,
      this.state.fromDate,
      this.state.toDate
    );
    showSpinner();
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.FinishingEntryListContent}
          mcolumns={Columns}
          status={true}
          micon={GridDeleteIcon}
          actions={false}
          deleteIcon={true}
          filter_form={"FinishingEntryFilter"}
          handleSearch={this.handleSearch}
          handledeleteRecord={this.handledeleteRecord}
          url="/create_finishing_entry"
        />
      </>
    );
  }
}
// machine master header
const Columns = [
  {
    hidden: false,
    Header: "Entry Date Time",
    accessor: "entryDate",
  },
  {
    hidden: false,
    Header: "Spell",
    accessor: "spell",
  },
  {
    hidden: false,
    Header: "Work Type",
    accessor: "workType",
  },
  {
    hidden: false,
    Header: "Emp Name",
    accessor: "empNo",
  },
  {
    hidden: false,
    Header: "Production",
    accessor: "production",
  },
  {
    hidden: false,
    Header: "Machine Id",
    accessor: "machineId",
  },
];
const mapStatetoProps = (state) => {
  const { FinishingEntryListData, FinishingEntryListDelContent } =
    state.FinishingEntryListReducer;
  return { FinishingEntryListData, FinishingEntryListDelContent };
};

export default withRouter(
  connect(mapStatetoProps, {
    finishingEntryActionList,
    finishingEntryActionListDelete,
    deleteFinishingEntry,
  })(FinishingEntryIndex)
);
