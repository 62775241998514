import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import { serverApi } from "../../../helpers/Consts";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import {
  createBrokerMaster,
  updateBrokerMaster,
} from "../../../store/Master/BrokerMaster/actions";
import { getSupplierListMultiselect } from "../../../store/Global/DropDownApis/actions";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import AutoMultiselect from "../../../components/Dropdown/AutoMultiselect";
import moment from "moment";
import swal from "sweetalert";
import { validate } from "../../../Utilities/Validations";
import TextFieldMobileReq from "../../../components/TextField/TextFieldMobileReq";
import { isEmpty } from "../../../Utilities/helper";

class CreateBrokerMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      createdBy: "",
      brokerName: "",
      brokerId: "",
      address: "",
      emailAddress: "",
      panNo: "",
      supplierListMultiselectData: [],
      supplierList: [],
      supplierListValue: "",
      createdOn: "",
      defaultSelected: "Jute",
      createdDate: "",
      brokerType: "",
      PanValidation: false,
      EmailValidation: false,
      EmailMandatory: false,
      contactNo:null,
      locationState: "",
    };
  }

  //function for TextFields
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "brokerName") {
      this.setState({
        brokerName: event,
      });
    }
    if (key === "brokerId") {
      this.setState({
        brokerId: event,
      });
    }
    if (key === "address") {
      this.setState({
        address: event,
      });
    }
    if(key==="contactNo"){
      this.setState({
        contactNo:event,
      })
    }
    if (key === "emailAddress") {
      this.setState({
        emailAddress: event,
      });
      if (event === "") {
        this.setState({
          EmailValidation: false,
          EmailMandatory: true,
        });
      } else {
        this.setState({
          EmailMandatory: false,
        });
        let exp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (exp.test(event)) {
          this.setState({
            EmailValidation: false,
          });
        } else {
          this.setState({
            EmailValidation: true,
          });
        }
      }
    }
    if (key === "panNo") {
      this.setState({
        panNo: event,
      });
      if (event == "") {
        this.setState({
          PanValidation: false,
        });
      } else {
        let Exp1 = /^[A-Za-z0-9 ]+$/;
        if (Exp1.test(event)) {
          let Exp2 = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
          if (Exp2.test(event)) {
            this.setState({
              PanValidation: false,
            });
          } else {
            this.setState({
              PanValidation: true,
            });
          }
        } else {
          this.setState({
            PanValidation: true,
          });
        }
      }
    }
  };
  // Multiselect Dropdown
  getMopt = (selectedValue, name) => {
    if (name === "supplierlist") {
      let supplier = [];
      selectedValue.map((id) => {
        var dept = { suppCode: id.value, isMapped: true };
        supplier.push(dept);
      });
      this.setState({
        supplierList: supplier,
      });
    }
  };
  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "brokerType") {
      this.setState({
        brokerType: selectedName,
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    this.setState({
      locationState:this.props.location
    })
    window.addEventListener("scroll", this.handleScroll);
    this.props.getSupplierListMultiselect(
      serverApi.GET_SUPPLIER_LIST_MULTISELECT + this.state.companyId,
      this.props.history
    );
    if (this.props.location.state) {
      if (this.props.location.state.rowData) {
        this.setState({
          address: this.props.location.state.rowData.address,
          brokerId: this.props.location.state.rowData.brokerId,
          brokerName: this.props.location.state.rowData.brokerName,
          companyId: this.props.location.state.rowData.companyId,
          panNo: this.props.location.state.rowData.pan,
          emailAddress: this.props.location.state.rowData.email,
          createdOn: this.props.location.state.rowData.createdOn,
          createdBy: this.props.location.state.rowData.createdBy,
          gst: this.props.location.state.rowData.gst,
          supplierList: this.props.location.state.rowData.supplierDtl,
          brokerType: this.props.location.state.rowData.type,
          contactNo:this.props.location.state.rowData.contactNo
        });
        const dateEntered = this.props.location.state.rowData.createdOn;
        const dateIsValid = moment(dateEntered).format("LLLL");
        this.setState({
          createdDate: dateIsValid,
        });
      }
    }
  }
  componentWillReceiveProps(props) {
    if (props.supplierListMultiselect.data) {
      var list = [];
      list = props.supplierListMultiselect.data.filter((item) => {
        if (item.value !== "0") {
          return item;
        }
      });

      this.setState({
        supplierListMultiselectData: list,
      });
    }
  }

  onClickCreate = () => {
    if (this.state.PanValidation === true) {
      swal("Please enter the valid PAN Number", { icon: "error" });
    }

    if (
      this.state.brokerName === "" ||
      this.state.brokerName === null ||
      this.state.brokerName === undefined
    ) {
      swal("Please Enter The Broker Name");
      return false;
    }

    if (
      this.state.brokerName === "" ||
      this.state.brokerName === null ||
      this.state.brokerName === undefined
    ) {
      swal("Please Enter The Broker Name");
      return false;
    }
    if (
      this.state.address === "" ||
      this.state.address === null ||
      this.state.address === undefined
    ) {
      swal("Please Enter The Address");
      return false;
    }
    if (
      this.state.contactNo === "" ||
      this.state.contactNo === null ||
      this.state.contactNo === undefined
    ) {
      swal("Please Enter The Contact Number");
      return false;
    }
    if (
      this.state.emailAddress === "" ||
      this.state.emailAddress === null ||
      this.state.emailAddress === undefined
    ) {
      swal("Please Enter The Email");
      return false;
    }
    if (isEmpty(this.state.brokerType)) {
      swal("Please Enter The Broker Type");
      return false;
    }
    if (isEmpty(this.state.supplierList)) {
      swal("Please Enter The Supplier List");
      return false;
    }

    var list = [];
    if (this.state.supplierList) {
      this.state.supplierList.map((supp) => {
        list.push({
          suppCode: supp.suppCode,
          isMapped: supp.isMapped,
        });
      });
    }
    const data = {
      broker: {
        brokerName: this.state.brokerName,
        address: this.state.address,
        contactNo:this.state.contactNo,
        email: this.state.emailAddress,
        pan: this.state.panNo,
        gst: "",
        companyId: this.state.companyId,
        supplierDtl: list,
        type: this.state.brokerType,
      },
      postAuthenticationDto: {
        userId: this.state.userDit.userId,
        cipher: this.state.userDit.cipher,
      },
    };
    this.props.createBrokerMaster(
      serverApi.CREATE_BROKER_MASTER,
      data,
      this.props.history
    );
  };
  onClickUpdate = () => {
    if (this.state.PanValidation === true) {
      swal("Please enter the valid PAN Number", { icon: "error" });
    }
    if (!this.state.emailAddress.includes("@")) {
      swal("Please enter a valid email address", { icon: "error" });
      return;
    }

    if (
      this.state.brokerName === "" ||
      this.state.brokerName === null ||
      this.state.brokerName === undefined
    ) {
      swal("Please Enter The Broker Name");
      return false;
    } else if (
      this.state.address === "" ||
      this.state.address === null ||
      this.state.address === undefined
    ) {
      swal("Please Enter The Address");
      return false;
    } else if(
      this.state.contactNo === "" ||
      this.state.contactNo === null ||
      this.state.contactNo === undefined
    ){
      swal("Please Enter The Contact Number");
      return false;
    }
    else if (
      this.state.emailAddress === "" ||
      this.state.emailAddress === null ||
      this.state.emailAddress === undefined
    ) {
      swal("Please Enter The Email");
      return false;
    } else if (
      this.state.brokerType === "" ||
      this.state.brokerType === null ||
      this.state.brokerType === undefined
    ) {
      swal("Please Enter The Broker Type");
      return false;
    }
    if (isEmpty(this.state.supplierList)) {
      swal("Please Enter The Supplier List");
      return false;
    }

    var list = [];
    this.state.supplierList.map((supp) => {
      list.push({
        suppCode: supp.suppCode,
        isMapped: supp.isMapped,
      });
    });
    const data = {
      broker: {
        brokerId: this.state.brokerId,
        brokerName: this.state.brokerName,
        address: this.state.address,
        contactNo:this.state.contactNo,
        email: this.state.emailAddress,
        pan: this.state.panNo,
        gst: null,
        companyId: this.state.companyId,
        supplierDtl: list,
        type: this.state.brokerType,
      },
      postAuthenticationDto: {
        userId: this.state.userDit.userId,
        cipher: this.state.userDit.cipher,
      },
    };
    this.props.updateBrokerMaster(
      serverApi.UPDATE_BROKER_MASTER,
      data,
      this.props.history
    );
  };
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    var supplierListData = [];
    if (this.state.locationState.state && this.state.supplierListMultiselectData) {
      this.state.supplierListMultiselectData.map((p, i) => {
        this.state.locationState.state.rowData.supplierDtl.map((prop) => {
          if (prop.suppCode === p.value) {
            supplierListData.push({
              name: p.name,
              value: p.value,
              label: p.label,
            });
          }
        });
      });
    }
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 3">
                      <TextFieldReq
                        value={this.state.brokerId}
                        readOnly={
                          this.state.locationState.state !== undefined ? true : false
                        }
                        caps="OFF"
                        onChange={this.onhandlechangeValue("brokerId")}
                        label="Broker Id"
                      />
                    </Box>
                  )}
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.brokerName}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("brokerName")}
                      label="Broker Name"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.address}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("address")}
                      label="Address"
                      disabled={this.state.brokerName === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldMobileReq
                      value={this.state.contactNo}
                      onChange={this.onhandlechangeValue("contactNo")}
                      label="Contact Number"
                      disabled={this.state.brokerName === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.emailAddress}
                      onChange={this.onhandlechangeValue("emailAddress")}
                      label="Email"
                      required
                      disabled={this.state.address === "" ? true : false}
                    />
                    {this.state.EmailMandatory && (
                      <div className="TextFieldBlock">
                        <span className="text-danger">
                          This Field is Required
                        </span>
                      </div>
                    )}
                    {this.state.EmailValidation && (
                      <div className="TextFieldBlock">
                        <span className="text-danger">
                          Invalid Email Address
                        </span>
                      </div>
                    )}
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.panNo}
                      onChange={this.onhandlechangeValue("panNo")}
                      label="PAN No"
                      caps="ON"
                      disabled={this.state.address === "" ? true : false}
                    />
                    {this.state.PanValidation && (
                      <div className="TextFieldBlock">
                        <span className="text-danger">Invalid PAN Number</span>
                      </div>
                    )}
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectNew
                        arrayOfData={BrokerType}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.brokerType}
                        update={this.state.brokerType ? 1 : 0}
                        name="brokerType"
                        label="Broker Type"
                        required
                        isDisabled={
                          this.state.emailAddress === "" ? true : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <AutoMultiselect
                        options={this.state.supplierListMultiselectData} // Options to display in the dropdown
                        showCheckbox={true}
                        selectedValues={supplierListData} // Preselected value to persist in dropdown
                        label="Supplier List"
                        name={"supplierlist"}
                        getOpt={this.getMopt}
                        mandatory="*"
                        disable={this.state.brokerType === "" ? true : false}
                      />
                    </div>
                  </Box>
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdDate}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link to="/broker_master">
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.state.locationState.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreate}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}
const BrokerType = [
  { label: "JUTE", value: 1, name: "JUTE" },
  { label: "SALE", value: 2, name: "SALE" },
];
const mapStatetoProps = (state) => {
  const { supplierListMultiselect } = state.DropDownListReducer;
  return { supplierListMultiselect };
};

export default withRouter(
  connect(mapStatetoProps, {
    createBrokerMaster,
    updateBrokerMaster,
    getSupplierListMultiselect,
  })(CreateBrokerMaster)
);
