import {VEHICLE_TYPES_MASTER_LIST,VEHICLE_TYPES_MASTER_LIST_SUCCESSFULL,CREATE_VEHICLE_TYPES_MASTER_SUCCESSFULL,CREATE_VEHICLE_TYPES_MASTER,
  UPDATE_VEHICLE_TYPES_MASTER,
  UPDATE_VEHICLE_TYPES_MASTER_SUCCESSFULL,
  API_ERRORS,
 } from './actionTypes';

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    VehicleTypesMasterList: [],
    CreateVehicleTypesMaster:[],
    UpdateVehicleTypesMaster:[],
  };

  const VehicleTypesMasterReducer = (state = initialState, action) => {
    switch (action.type) {
        case VEHICLE_TYPES_MASTER_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case VEHICLE_TYPES_MASTER_LIST_SUCCESSFULL:
            state = {
              ...state,
              VehicleTypesMasterList: action.payload.response,
              loading: true,
            };
            break;
            case CREATE_VEHICLE_TYPES_MASTER:
              state = {
                ...state,
                loading: true,
              };
              break;
            case CREATE_VEHICLE_TYPES_MASTER_SUCCESSFULL:
              state = {
                ...state,
                CreateVehicleTypesMaster: action.payload.response,
                loading: true,
              };
              break;
              case UPDATE_VEHICLE_TYPES_MASTER:
              state = {
                ...state,
                loading: true,
              };
              break;
            case UPDATE_VEHICLE_TYPES_MASTER_SUCCESSFULL:
              state = {
                ...state,
                UpdateVehicleTypesMaster: action.payload.response,
                loading: true,
              };
              break;
              case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default VehicleTypesMasterReducer;