import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/PaginationGridView";
import { spinningQualityList } from "../../../store/Master/SpinningQualityMaster/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      spinningQualityListData: [], // to display list data
      qualityCode: null,
      qualityName: null,
      yarnType: null,
      yarnCount: null,
      speed: null,
      spindleCount: null,
      frameType: null,
      tpi: null,
      qualityType: 2,
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      dataexp: "",
    };
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      qualityType: 2,
      qualityCode: this.state.qualityCode,
      qualityName: this.state.qualityName,
      yarnType: this.state.yarnType,
      yarnCount: this.state.yarnCount,
      speed: this.state.speed,
      spindleCount: this.state.spindleCount,
      frameType: this.state.frameType,
      tpi: this.state.tpi,
      startIndex: 1,
      lastIndex: this.state.lastIndex,
    };
    this.props.spinningQualityList(
      serverApi.GET_ALL_SPINNING_QUALITY_LIST,
      data,
      this.props.history
    ); // calling Spinning quality  master list API
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.SpinningQualityList) {
      this.setState({
        totalRecs: props.SpinningQualityList.totalRecords,
      });
      if (props.SpinningQualityList.data) {
        if (props.SpinningQualityList.data.length !== 0) {
          var data = [];
          data = props.SpinningQualityList.data.filter((item, i) => i != 0);
          this.setState({
            spinningQualityListData: data,
          });
        }
      }
    }
  }
  handleSearch = (data1) => {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      qualityType: data1.qualityType,
      juteQualityCode: data1.qualityCode,
      qualityName: data1.qualityName,
      yarnType: data1.yarnType,
      yarnCount: data1.yarnCount,
      speed: data1.speed,
      spindleCount: data1.spindleCount,
      frameType: data1.frameType,
      tpi: data1.tpi,
      startIndex: 1,
      lastIndex: 10,
    };
    this.setState({
      qualityType: data1.qualityType,
      qualityCode: data1.qualityCode,
      qualityName: data1.qualityName,
      yarnType: data1.yarnType,
      yarnCount: data1.yarnCount,
      speed: data1.speed,
      spindleCount: data1.spindleCount,
      frameType: data1.frameType,
      tpi: data1.tpi,
    });
    this.props.spinningQualityList(
      serverApi.GET_ALL_SPINNING_QUALITY_LIST,
      data,
      this.props.history
    );
  };
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;

    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      qualityType: this.state.qualityType,
      qualityCode: this.state.qualityCode,
      qualityName: this.state.qualityName,
      yarnType: this.state.yarnType,
      yarnCount: this.state.yarnCount,
      speed: this.state.speed,
      spindleCount: this.state.spindleCount,
      frameType: this.state.frameType,
      tpi: this.state.tpi,
    };
    if (type === 1) {
      data.startIndex = sizePerPage;
      data.lastIndex = currentIndex;
      if (currentIndex >= 0) {
        this.props.spinningQualityList(
          serverApi.GET_ALL_SPINNING_QUALITY_LIST,
          data,
          this.props.history
        );
      }
    } else {
      data.startIndex = currentIndex === 0 ? currentIndex + 1 : currentIndex;
      data.lastIndex = sizePerPage;
      if (currentIndex >= 0) {
        this.props.spinningQualityList(
          serverApi.GET_ALL_SPINNING_QUALITY_LIST,
          data,
          this.props.history
        );
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
      // loadingdata: true,
    });
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.spinningQualityListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          filter_form={"SpinningQualityMaster"}
          url="/create_spinning_quality_master"
          handleSearch={this.handleSearch}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          apiexp={serverApi.GET_ALL_SPINNING_QUALITY_LIST}
          payloadexp={this.state.dataexp}
          expName={"Spinning-Quality-Master.csv"}
        />
      </>
    );
  }
}
// Spinning quality list Headers
const Columns = [
  {
    Header: "Quality Code",
    accessor: "qualityCode",
    hidden: false,
  },
  {
    Header: "Quality Name",
    accessor: "qualityName",
    hidden: false,
  },
  {
    Header: "Speed",
    accessor: "speed",
    hidden: false,
  },
  {
    Header: "Yarn Type",
    accessor: "yarnType",
    hidden: false,
  },
  {
    Header: "Machine",
    accessor: "machineType",
    hidden: false,
  },
  {
    Header: "JBO/RBO",
    accessor: "jboRbo",
    hidden: false,
  },
  {
    Header: "Yarn Count",
    accessor: "yarnCount",
    hidden: false,
  },
  {
    Header: "Tar Eff",
    accessor: "tarEff",
    hidden: false,
  },
  {
    Header: "TPI",
    accessor: "tpi",
    hidden: false,
  },
  {
    Header: "Spindle",
    accessor: "spindleCount",
    hidden: false,
  },
  {
    Header: "Std Doff",
    accessor: "stdDoff",
    hidden: false,
  },
  {
    Header: "Std Doff Weight",
    accessor: "stdDoffWt",
    hidden: false,
  },
  {
    Header: "Frame Type",
    accessor: "frameType",
    hidden: false,
  },
];
const mapStatetoProps = (state) => {
  const { SpinningQualityList } = state.SpinningQualityReducer; // fetching list from spinning quality reducer
  return { SpinningQualityList };
};

export default withRouter(
  connect(mapStatetoProps, {
    spinningQualityList,
  })(Index)
);
