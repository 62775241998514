import {
  CUTS_AND_JUGAR_ENTRY_LIST,
  CUTS_AND_JUGAR_ENTRY_LIST_DEL,
  GET_LOOM_NO,
  GET_CUTS_JUGAR_BY_LOOM,
  GET_DAILY_QUALITY_MAPPING,
  CUTS_JUGAR_DATA_LIST,
  CREATE_ACTUAL_SHOTS,
  UPDATE_EB_NO_WORKING_HOURS,
  CUTS_JUGAR_LIST,
  CREATE_CUTS_AND_JUGAR,
  UPDATE_WEAVING_MACHINE_MAPPING,
  DELETE_ACTUAL_SHOTS,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getListurl, getList } from "../../../helpers/Server_Helper";
import {
  CutsAndJugarListListSuccessfull,
  CutsAndJugarListListDelSuccessfull,
  getLoomNoSuccessfull,
  getcutsandjugarByLoomNoSuccessfull,
  dailyQualityMappingSuccessfull,
  CutsAndJugarDataListSuccessfull,
  createActualShotSuccessfull,
  CutsAndJugarDataList,
  updateEbNoWorkingHrsSuccessfull,
  CutsJugarListListSuccessfull,
  CutsJugarList,
  createCutsandJugarSuccessfull,
  updateWeavingMachineMappingSuccessfull,
  CutsAndJugarList,
  DeleteActualShotSuccessfull,
} from "./actions";
import swal from "sweetalert";
import { serverApi } from "../../../helpers/Consts";

function* CutsAndJugarEntrySaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(CutsAndJugarListListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* CutsAndJugarEntryDelSaga({
  payload: { url, history, fromDate, toDate },
}) {
  try {
    const response = yield call(getListurl, url);
    yield put(DeleteActualShotSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
    if (response) {
      const data = {
        companyId: localStorage.getItem("companyId"),
        cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
        fromDate: fromDate,
        toDate: toDate,
      };
      let listUrl = serverApi.GET_CUTS_AND_JUGAR_ENTRY_LIST;
      yield put(CutsAndJugarList(listUrl, data, history));
    }
  } catch (error) {
    console.log(error);
  }
}

function* ActualShotDelSaga({ payload: { url, history, fromDate, toDate } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(CutsAndJugarListListDelSuccessfull({ response }));
    if (response.data.message === "Updated Successfully.") {
      swal("Deleted Successfully.", { icon: "success" });
    } else {
      swal(response.data.message, { icon: "success" });
    }

    if (response) {
      var listApi =
        serverApi.CUTS_JUGAR_DATA_LIST +
        localStorage.getItem("companyId") +
        "/1";
      yield put(CutsAndJugarDataList(listApi, history));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getLoomNoListSaga({ payload: { url, data, history, date } }) {
  try {
    const response = yield call(getList, url, data, date);
    yield put(getLoomNoSuccessfull({ response }));
    if (response) {
      let Data = {
        companyId: localStorage.getItem("companyId"),
        date: date,
        loomId: response.data.machineId,
      };
      yield put(CutsJugarList(serverApi.GET_CUTS_BY_LOOM, data, history));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getCutsandJugarLoomNoListSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(getcutsandjugarByLoomNoSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* dailyQualityMappingSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(dailyQualityMappingSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* cutsJugarDataListSaga({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(CutsAndJugarDataListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* createActualShotSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(createActualShotSuccessfull({ response }));
    swal(response.message, { icon: "success" });
    if (response) {
      var listApi =
        serverApi.CUTS_JUGAR_DATA_LIST +
        localStorage.getItem("companyId") +
        "/1";
      yield put(CutsAndJugarDataList(listApi, history));
    }
  } catch (error) {
    console.log(error);
  }
}

function* updateEbNoWorkingHoursSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(updateEbNoWorkingHrsSuccessfull({ response }));
    swal(response.message, { icon: "success" });
  } catch (error) {
    console.log(error);
  }
}

function* cutsjugarlistSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(CutsJugarListListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* createCutsJugarSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(createCutsandJugarSuccessfull({ response }));
    swal(response.message, { icon: "success" });
  } catch (error) {
    console.log(error);
  }
}

function* updateWeavingMAchineMappingSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(updateWeavingMachineMappingSuccessfull({ response }));
    swal(response.message, { icon: "success" });
  } catch (error) {
    console.log(error);
  }
}

export function* watchCutsAndJugarEntry() {
  yield takeEvery(CUTS_AND_JUGAR_ENTRY_LIST, CutsAndJugarEntrySaga);
  yield takeEvery(CUTS_AND_JUGAR_ENTRY_LIST_DEL, CutsAndJugarEntryDelSaga);
  yield takeEvery(GET_LOOM_NO, getLoomNoListSaga);
  yield takeEvery(GET_CUTS_JUGAR_BY_LOOM, getCutsandJugarLoomNoListSaga);
  yield takeEvery(GET_DAILY_QUALITY_MAPPING, dailyQualityMappingSaga);
  yield takeEvery(CUTS_JUGAR_DATA_LIST, cutsJugarDataListSaga);
  yield takeEvery(CREATE_ACTUAL_SHOTS, createActualShotSaga);
  yield takeEvery(UPDATE_EB_NO_WORKING_HOURS, updateEbNoWorkingHoursSaga);
  yield takeEvery(CUTS_JUGAR_LIST, cutsjugarlistSaga);
  yield takeEvery(CREATE_CUTS_AND_JUGAR, createCutsJugarSaga);
  yield takeEvery(
    UPDATE_WEAVING_MACHINE_MAPPING,
    updateWeavingMAchineMappingSaga
  );
  yield takeEvery(DELETE_ACTUAL_SHOTS, ActualShotDelSaga);
}

function* CutsAndJugarSaga() {
  yield all([fork(watchCutsAndJugarEntry)]);
}

export default CutsAndJugarSaga;
