import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { serverApi } from "../../../helpers/Consts";
import TextFieldNormal from "../../TextField/TextFieldNormal";
import DynamicSelect from "../../../components/Dropdown/Master/DynamicSelectMasters";
import { getParentBudgetHeads } from "../../../store/Global/DropDownApis/actions";

class BudgetHeadsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      budgetHeadId: null,
      budgetHeadName: "",
      parentBudgetHeads:"",
      parentBudgetId:"",
      parentBudgetHeadsList: [], // parent budget heads list
    };
  }

  componentDidMount() {
    this.props.getParentBudgetHeads(
      serverApi.GET_PARENT_BUDGET_HEADS_LIST +
        "company/" +
        this.state.companyId,
      this.props.history
    ); // calling parent budget heads api
  }
  componentWillReceiveProps(props) {
    if (props.parentBudgetHeadsList) {
      this.setState({
        parentBudgetHeadsList: props.parentBudgetHeadsList.data, // updating for parent budget heads list
      });
    }
  }

  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "parentBudgetHeads") {
      if(selectedValue==0){
        this.setState({
          parentBudgetId: '',
        });
      }else{
        this.setState({
          // parentBudgetHeads: selectedName,
          parentBudgetId: selectedValue,
        }); 
      }
   
    }
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleChange = (key) => (event) => {
    if (key === "budgetHeadName") {
      this.setState({
        budgetHeadName: event,
      });
    }
    if (key === "budgetHeadId") {
      this.setState({
        budgetHeadId: event,
      });
    }
  };

  handleSubmit = () => {
    var data = {
      budgetHeadName: this.state.budgetHeadName !==""?this.state.budgetHeadName:null,
      // budgetHeadId: this.state.budgetHeadId,
      parentBudgetId: this.state.parentBudgetId,
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleClear = () => {
    this.setState({});
  };

  render() {
    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          // TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid
            spacing={0}
            // maxWidth="800px"
            //style={{ padding: "20px" }}
          >
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
                {/* <div>Save View</div> */}
              </div>
            </div>
            <Grid item xs={12} className="filterBlockContainer">
              {/* <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <TextFieldNormal
                    label="Budget Head Id"
                    value={this.state.budgetHeadId}
                    onChange={this.handleChange("budgetHeadId")}
                  />
                </div>
              </Box> */}
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <TextFieldNormal
                    label="Budget Head Name"
                    value={this.state.budgetHeadName}
                    onChange={this.handleChange("budgetHeadName")}
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={this.state.parentBudgetHeadsList}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handlePropSelectChange}
                    selected={this.state.parentBudgetId}
                    update={this.props.location.state ? 1 : 0}
                    name="parentBudgetHeads"
                    label="Parent Budget Heads"
                  />
                </div>
              </Box>
              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Search"}
                  className="greenButton"
                  handleClick={this.handleSubmit}
                  type="sumbit"
                />
              </div>
            </Grid>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  const { parentBudgetHeadsList } = state.DropDownListReducer; // fetching api from global api
  return { parentBudgetHeadsList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getParentBudgetHeads,
  })(BudgetHeadsFilter)
);
