import {
  PAY_REGISTER_LIST,
  BRANCH_LIST_DATA,
  PAY_PERIOD,
  PAY_PERIOD_BY_ID,
  PROCESSED_LIST,
  GENERATE_INVOICE,
  UPDATE_PAY_PERIOD,
  GET_MONTHLY_PAYSALARY_LIST,
} from "./actionType";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {
  payRegisterListSuccessfull,
  BranchListDataSuccessfull,
  payPeriodSuccessfull,
  PayPeriodByIdSuccessfull,
  processedListSuccessfull,
  updatePayPeriodSuccessfull,
  generateInvoiceSuccessfull,
  setPayPeriod,
  getPayRegisterList,
  getPaySlaryListSuccessfull,
} from "./actions";
import { getList, getListurl, postAdd } from "../../../helpers/Server_Helper";
import swal from "sweetalert";
import {
  hideSpinner,
  serverApi, showSpinner,
} from "../../../helpers/Consts";

//Get Pay Salary List
function* getPaySalaryListSaga({ payload: { url, data, history } }) {
  try {
    showSpinner()
    const response = yield call(getList, url, data);
    yield put(getPaySlaryListSuccessfull({ response }));
    if(response.data){
      hideSpinner()
    }
  } catch (error) {
    hideSpinner()
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
  }
}

// Get Pay Register
function* getPayRegisterLists({ payload: { url, data, history } }) {
  try {
    showSpinner()
    const response = yield call(getList, url, data);
    yield put(payRegisterListSuccessfull({ response }));
    if(response){
      hideSpinner()
    }else{
      hideSpinner()
    }
  } catch (error) {
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
    hideSpinner()
    console.log(error);
  }
}
//BRANCH LIST
function* getBranch({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(BranchListDataSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
// Pay period
function* setPayPeriods({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    if (response) {
      if (response.data) {
        if(response.data.status){
        if(data.paySchemeId !== undefined && response.data.id !== undefined){
          var url1 =
          serverApi.UPLOAD_OR_PAYPROCESS +
          data.paySchemeId +
          "/" +
          response.data.id;
        let Data = new FormData();
        Data.append("multipartFile", "undefined");
        yield put(setPayPeriod(url1, Data, history));
        }
        }
          const data1 = {
            fromDate: "",
            lastIndex: 10,
            startIndex: 1,
            status: "",
            toDate: ""
          };
          yield put (getPayRegisterList(serverApi.PAY_REGISTER_LIST, data1,history))
      }
    }
    yield put(payPeriodSuccessfull({ response }));
    if (response.data.status === false) {
      swal(response.data.message, { icon: "warning" });
      return;
    }
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
    }
    history.push("/payregister");
  } catch (error) {
    console.log(error);
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
  }
}
// Pay period by id
function* getPayPeriodById({ payload: { url, history } }) {
  try {
    showSpinner();
    const response = yield call(getListurl, url);
    if (response) {
      hideSpinner();
    }
    yield put(PayPeriodByIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
  }
}

function* getProcessedList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(processedListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* PayPeriodUpdate({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updatePayPeriodSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
    history.push("/payregister");
  } catch (error) {
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
  }
}
function* InvoiceGeneration({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(generateInvoiceSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
    history.push("/payregister");
  } catch (error) {
    console.log(error);
  }
}
export function* watchPayRegisterList() {
  yield takeEvery(PAY_REGISTER_LIST, getPayRegisterLists);
  yield takeEvery(PAY_PERIOD, setPayPeriods);
  yield takeEvery(PAY_PERIOD_BY_ID, getPayPeriodById);
  yield takeEvery(PROCESSED_LIST, getProcessedList);
  yield takeEvery(BRANCH_LIST_DATA, getBranch);
  yield takeEvery(GENERATE_INVOICE, InvoiceGeneration);
  yield takeEvery(UPDATE_PAY_PERIOD, PayPeriodUpdate);
  yield takeEvery(GET_MONTHLY_PAYSALARY_LIST, getPaySalaryListSaga);
}
function* PayRegisterSaga() {
  yield all([
    fork(watchPayRegisterList),
  ]);
}

export default PayRegisterSaga;
