import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import CustomButton from "../../../components/Buttons/Buttons";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { serverApi } from "../../../helpers/Consts";
import {
  JuteMarketRateData,
  JuteCreateMarketRate,
} from "../../../store/Jute/JuteMarketRate/actions";
import moment from "moment";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import swal from "sweetalert";

const JuteMarketRate = ({ history, JuteMarketRateDataArray, JuteMarketRateData, JuteCreateMarketRate }) => {
  const [userDit, setUserDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [companyId, setCompanyId] = useState(localStorage.getItem("companyId"));
  const [dateValue, setDateValue] = useState(moment().format("YYYY-MM-DD").toString());
  const [display, setDisplay] = useState(false);
  const [JuteMarketRateContent, setJuteMarketRateContent] = useState([]);
  const [id, setId] = useState("");
  const [juteQuality, setJuteQuality] = useState("");
  const [rate, setRate] = useState("");
  const [itemDesc, setItemDesc] = useState("");
  const [showGrid, setShowGrid] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    if (JuteMarketRateDataArray) {
      if (JuteMarketRateDataArray.data) {
        if (JuteMarketRateDataArray.data.length !== 0) {
          setJuteMarketRateContent(JuteMarketRateDataArray.data);
          setShowButtons(true);
          setShowGrid(true);
        } else {
          setShowButtons(false);
          setShowGrid(false);
        }
      }
    }
  }, [JuteMarketRateDataArray]);

  const handleSelectDate = (e, name) => {
    if (name === "date") {
      var Date = moment(e).format().split("T", 1).toString();
      setDateValue(Date);
    }
  };

  const onClickNext = () => {
    var DateValue = moment(dateValue, "YYYY-MM-DD", true).format("DD-MM-YYYY");
    const data = {
      entryDate: DateValue,
      companyId: localStorage.getItem("companyId"),
      cipher: userDit.cipher,
    };
    JuteMarketRateData(serverApi.JUTE_MARKET_RATE_API, data, history);
  };

  const handleRateChange = (index) => (value) => {
    console.log("value", value)
    // Check if the value has more than 3 decimal places
    if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
      const updatedRate = value;
      const updatedJuteMarketRateContent = [...JuteMarketRateContent];
      updatedJuteMarketRateContent[index].rate = updatedRate;
      setJuteMarketRateContent(updatedJuteMarketRateContent);
    } else {
      swal("Please enter a positive number with up to 3 decimal places.")
      return;
    }
  };

  const handleCancel = () => {
    setDisplay(true);
    setShowGrid(false);
    setShowButtons(false);
  };

  const handleSave = () => {
    var DateValue = moment(dateValue, "YYYY-MM-DD", true).format("DD-MM-YYYY");
    let ListData = [];
    JuteMarketRateContent.forEach((item) => {
      ListData.push({
        qualityId: item.id,
        juteRate: item.rate,
      });
    });
    const Data = {
      companyId: localStorage.getItem("companyId"),
      userId: userDit.userId,
      juteRateList: ListData,
      entryDate: DateValue,
    };
    JuteCreateMarketRate(serverApi.JUTE_CREATE_MARKET_RATE, Data, history);
  };

  return (
    <div className="juteIndent">
      <div className="consoleFormContainer">
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <div>
                    <div className="TextFieldBlock">
                      <label>Date</label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        name="date"
                        inputFormat="dd-MM-yyyy"
                        value={dateValue}
                        fullWidth
                        maxDate={new Date()}
                        onChange={(e) => handleSelectDate(e, "date")}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input ref={inputRef} {...inputProps} readOnly={true} />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </Box>
                <div className="consoleFormButtonBlock" style={{ marginTop: "15px" }}>
                  <CustomButton
                    label={"Go"}
                    className="greenButton"
                    handleClick={onClickNext}
                    type="sumbit"
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
      {showGrid && (
        <div className="educationalDetailsBlock">
          <div className="gridContainer educational_details_table">
            <div className="gridComponent">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "30%" }}>Quality Code</th>
                    <th style={{ width: "30%" }}>Jute Type</th>
                    <th style={{ width: "25%" }}>Quality Name</th>
                    <th style={{ width: "20%" }}>Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {JuteMarketRateContent.map((data, index) => (
                    <tr key={index}>
                      <td>{data.id}</td>
                      <td>{data.itemDesc}</td>
                      <td>{data.juteQuality}</td>
                      <td>
                        <TextFieldNormal
                          value={data.rate !== null ? data.rate : ""}
                          type="text"
                          onChange={handleRateChange(index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {showButtons && (
        <div className="consoleFormButtonBlock">
          <CustomButton
            label={"Cancel"}
            className="greenButton"
            handleClick={handleCancel}
            type="submit"
          />
          <CustomButton
            label={"Save"}
            className="greenButton"
            handleClick={handleSave}
            type="submit"
          />
        </div>
      )}
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { JuteMarketRateDataArray } = state.JuteMarketRateReducer;
  return { JuteMarketRateDataArray };
};

export default withRouter(
  connect(mapStatetoProps, {
    JuteMarketRateData,
    JuteCreateMarketRate,
  })(JuteMarketRate)
);
