import React, { Component } from "react";
import CustomButton from "../../../components/Buttons/Buttons";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi, getTenantId ,serverVars} from "../../../helpers/Consts";
import { getFileDownload } from "../../../helpers/server.js";
import { InwardLineItemsProps } from "../../../store/Purchase/Inward/actions";
import { Box, Grid, } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import { getFilesofInward } from "../../../store/Purchase/Inward/actions";


class InwardPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      date: moment().format("YYYY-MM-DD"),
      currentStep: 1,
      companyLogo: "",
      source: "",
      Inward_LineItems: [],
      inwardfilesList: [],
      RowData: "",
      supplierName: "",
      SequenceNo: "",
      TodayDate: moment().format(),
      locationState: ""
    };
  }
  componentDidMount() {
    let companyData = JSON.parse(localStorage.getItem("authUser"));
    let companyId = localStorage.getItem("companyId");
    companyData.companyDetails.map((p) => {
      let id = p.value;
      if (companyId === id.toString()) {
        let companyName = p.label.split("-");
        this.setState({
          companyName: companyName[1],
          address1: p.address1,
          companyLogo: p.companyLogo,
          gstNo: p.gstNo,
          address2: p.address2,
          comName: p.name,
          phoneNo: p.contactNo,
          email: p.emailId,
        });
      }
    });

    if (this.props.locationState.state !==undefined) {
      let id = this.props.locationState.state.rowData.id;
      this.props.getFilesofInward(
        serverApi.GET_FILES +
          18 +
          "/" +
          id +
          "/" +
          localStorage.getItem("companyId"),
        this.props.history,
        18,
        "inward"
      );
    }
    if (this.props.Inward_LineItems) {
      let inwardLineitems = this.props.Inward_LineItems.filter(
        (item) => item.isActive !== 0
      );
      this.setState({
        Inward_LineItems: inwardLineitems,
      });
    }
    if (this.props.location) {
      if (this.props.location.state !== undefined) {
        if (this.props.location.state.rowData) {
          this.setState({
            RowData: this.props.location.state.rowData,
          });
          if (this.props.location.pathname === "/create_material_inspection") {
            this.setState({
              RowData: this.props.location.state.rowData,
              supplierName: this.props.location.state.rowData.supplierName,
              SequenceNo: this.props.location.state.rowData.inwardSequenceNo,
            });
          }
          if (this.props.location.pathname === "/create_WorkReport") {
            this.setState({
              RowData: this.props.location.state.rowData,
              supplierName: this.props.location.state.rowData.supplierName,
              SequenceNo: this.props.location.state.rowData.inwardSequenceNo,
            });
          }
          if (this.props.location.pathname === "/create_inward") {
            this.setState({
              RowData: this.props.location.state.rowData,
              supplierName: this.props.location.state.rowData.supplierName,
              SequenceNo: this.props.location.state.rowData.inwardSequenceNo,
            });
          }
          if (this.props.location.pathname === "/create_store_bill_pass") {
            this.setState({
              RowData: this.props.location.state.rowData,
              supplierName: this.props.location.state.rowData.supplierName,
              SequenceNo: this.props.location.state.rowData.billpassNumber,
            });
          }
          if (this.props.location.pathname === "/Create_store_receipt") {
            this.setState({
              RowData: this.props.location.state.rowData,
              supplierName: this.props.location.state.rowData.supplierName,
              SequenceNo: this.props.location.state.storeReceiptNo,
            });
          }
        }
      }
    }
  }

  componentWillReceiveProps(props){
    if (props.inwardfilesList) {
      if (props.inwardfilesList.data) {
        let filesList = props.inwardfilesList.data;
        this.setState({
          inwardfilesList: filesList.data,
        });
      }
    }
  }
  getFilesDownload = (fileUploadId, fileName, fileExtension) => {
    let url = serverVars.SECURITY_API + "files/downloadfile/" + fileUploadId;
    getFileDownload(url, fileName, fileExtension);
  };
  handleEdit = () => {
    this.props.handleCurrentStep(this.state.currentStep);
  };
  handlePdf = () => {
    let Id = this.state.RowData.id;
    let taskId = 0;
    if (this.props.location.pathname === "/create_material_inspection") {
      taskId = 4;
    }
    if (this.props.location.pathname === "/create_WorkReport") {
      taskId = 38;
    }
    if (this.props.location.pathname === "/create_inward") {
      taskId = 34;
    }
    if (this.props.location.pathname === "/create_store_bill_pass") {
      taskId = 7;
    }
    if (this.props.location.pathname === "/Create_store_receipt") {
      taskId = 3;
    }

    fetch(
      serverApi.DOWNLOAD_PDF +
      Id +
      "/" +
      this.state.userDit.userId +
      "/" +
      taskId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          "X-TenantId": getTenantId(window.location.hostname),
          Authorization:
            localStorage.getItem("token_type") +
            localStorage.getItem("access_token"),
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          this.state.SequenceNo + "_" + this.state.supplierName + ".pdf"
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  convertNumberToWords(amount) {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = "";
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakh ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  }

  withDecimal(n) {
    if (n !== undefined) {
      var nums = n.toString().split(".");
      var whole = this.convertNumberToWords(nums[0]);

      if (nums.length == 2) {
        if (parseInt(nums[1]) > 0) {
          return whole + "Rupees only";
        }
      }
      return whole + "Rupees only";
    }
  }
  getIgstAmount = (lineItem)=>{
    let lineItemAmount = Number(lineItem.inwardQty) * Number(lineItem.rate)
      let amountAfterDiscount = 0
      let amountWithDiscount = 0
      let totalIGSTValue = 0
      let totalGSTValue = 0
      if (lineItem.discountMode !== undefined && lineItem.discountMode !== "" && lineItem.discountMode !==null) {
        if (lineItem.discountMode === 1) {
          amountWithDiscount =
          Number(lineItem.inwardQty) * Number(lineItem.rate) -
            Number(lineItem.discountPercentage);
          amountAfterDiscount = amountWithDiscount
        } else {
          let amountWithDiscountPercentage = (Number(lineItem.inwardQty) * Number(lineItem.rate) *
            Number(lineItem.discountPercentage)) /
            100;
            amountWithDiscount =Number(lineItem.inwardQty) * Number(lineItem.rate) - amountWithDiscountPercentage
          amountAfterDiscount = amountWithDiscount

        }
      }
    if (this.props.InwardFillDetails.taxType === 1) {
      if (lineItem.discountMode !== undefined && lineItem.discountMode !== "" && lineItem.discountMode !==null) {
        totalIGSTValue = (amountAfterDiscount * lineItem.taxPercentage) / 100
        return totalIGSTValue
      } else {
        totalIGSTValue = (Number(lineItem.inwardQty) * Number(lineItem.rate) * lineItem.taxPercentage) / 100
        return totalIGSTValue
       
      }
    } else {
      if (lineItem.discountMode !== undefined && lineItem.discountMode !== "" && lineItem.discountMode !==null) {
        totalGSTValue = (amountAfterDiscount * lineItem.taxPercentage) / 100
        return  totalGSTValue /2
      } else {
        totalGSTValue = (lineItemAmount * lineItem.taxPercentage) / 100
        return  totalGSTValue /2
      }
    }
  }
  render() {
    var totalAmount = 0;
    var totalIgst = 0;
    var totalCgst = 0;
    var totalSgst = 0;
    var IndividualAmount = 0;
    var totalCgstValue = 0
    if (this.state.Inward_LineItems) {
      var filteredproducts = this.state.Inward_LineItems.filter(
        (item) => {
          if (this.props.source === "WITHOUTPO" && Number(item.itemGroupId) === 997) {
            if (item.isActive !== 0 && Number(item.taxPercentage)!== 0) {
              return item;
            }
          }
           else if(this.props.source === "WITHPO"&& Number(item.itemGroupId) === 997) {
            if (item.isActive !== 0 && Number(item.taxPercentage)!== 0) {
              return item;
            }
          }
          
          else {
            if (item.departmentId !== "" && item.isActive !== 0) {
              return item;
            }
          }
        }
      );

      filteredproducts.forEach((data) => {
        if (data.inwardQty && data.rate) {
          totalAmount =
            totalAmount +
            (data.inwardQty * data.rate);
            if (Number(data.discountMode !== null) && data.discountMode !== "" ) {
            let amount =
              Number(data.inwardQty) * Number(data.rate);
              if (Number(data.discountMode) ===1 ){
              if (
                Number(data.discountPercentage) !== 0 &&
                Number(data.discountPercentage) !== undefined &&
                Number(data.discountPercentage) !== "" &&
                Number(data.discountPercentage) !== ""
              ) {

                IndividualAmount = amount - Number( data.discountPercentage)
                totalAmount = totalAmount - Number( data.discountPercentage);
               
              }
            } else if (data.discountMode === 2){
              if (
                Number(data.discountPercentage) !== 0 &&
                Number(data.discountPercentage)  !== undefined &&
                Number(data.discountPercentage)  !== "" &&
                Number(data.discountPercentage)  !== null

              ) {
                let disAmount = (amount * Number(data.discountPercentage)) / 100;
                IndividualAmount = amount - disAmount
                totalAmount = totalAmount - disAmount;
                
              }
            }
          }
         
        }
        if (Number(this.props.InwardFillDetails.taxType) === 3) {
          
          totalIgst = 0;
          totalCgst = 0;
          totalSgst = 0;
        } else {
          if (this.props.InwardFillDetails.taxType === 1) {
            let amountWithDiscount = 0
            if (data.discountMode !== undefined && data.discountMode !== "" && data.discountMode !== null) {
              if (data.discountMode === 1) {
                amountWithDiscount =
                  (data.inwardQty * data.rate) -
                  Number(data.discountPercentage);
                totalIgst = totalIgst + ((amountWithDiscount) * data.taxPercentage) / 100;
              } else if (data.discountMode === 2) {
                amountWithDiscount = ((data.inwardQty * data.rate) *
                  Number(data.discountPercentage)) /
                  100;
                amountWithDiscount = (data.inwardQty * data.rate) - amountWithDiscount
                totalIgst = totalIgst + ((amountWithDiscount) * data.taxPercentage) / 100;
              } else {
                amountWithDiscount = (data.inwardQty * data.rate) - amountWithDiscount
                totalIgst = totalIgst + ((amountWithDiscount) * data.taxPercentage) / 100;
              }
            } else {
            
              totalIgst = totalIgst + ((
                data.inwardQty * data.rate) * Number(data.taxPercentage)) / 100;
            }

          } else {
            let amountWithDiscount = 0
            if (data.discountMode !== undefined && data.discountMode !== "" && data.discountMode !== null) {
              if (data.discountMode === 1) {
                amountWithDiscount =
                  (data.inwardQty * data.rate) -
                  Number(data.discountPercentage);
                totalCgst = totalCgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
                totalSgst = totalSgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;

              } else if (data.discountMode === 2) {
                amountWithDiscount = ((data.inwardQty * data.rate) *
                  Number(data.discountPercentage)) /
                  100;
                amountWithDiscount = (data.inwardQty * data.rate) - amountWithDiscount
                totalCgst = totalCgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
                totalSgst = totalSgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
              } else {
                amountWithDiscount = (data.inwardQty * data.rate) - amountWithDiscount
                totalCgst = totalCgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
                totalSgst = totalSgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
              }
            }
            else{
              amountWithDiscount =(data.inwardQty * data.rate) 
                totalCgst = totalCgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
                totalSgst = totalSgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
            }
          }
        }

      });
    }


    var otherChargesListWithNoTax = [];
    otherChargesListWithNoTax = this.props.Inward_LineItems.filter((item) => {
      if (Number(item.itemGroupId) === 997) {
        return item.isActive !== 0 && Number(item.taxPercentage) === 0;
      }
    });


    const totalOtherChargesWithNoTax = otherChargesListWithNoTax?.reduce((acc, data) => {
      return data.discountMode === "" || data.discountMode === 0.0
          ? acc + Number(parseFloat((Number(data.inwardQty) * Number(data.rate)) - Number(data.discountPercentage)).toFixed(2))
          : data.discountMode === 1
              ? acc + Number(parseFloat((Number(data.inwardQty) * Number(data.rate)) - Number(data.discountPercentage)).toFixed(2))
              : data.discountMode === 2
                  ? acc + Number(parseFloat((Number(data.inwardQty) * Number(data.rate)) - (Number(data.discountPercentage) / 100) * Number(data.inwardQty) * Number(data.rate)).toFixed(2))
                  : acc;
  }, 0);



    let netTotal = totalAmount + totalIgst + totalCgst + totalSgst;
    if (netTotal != null || netTotal !== undefined || netTotal !== "") {
      var convertion = this.withDecimal(Math.round( totalAmount + totalIgst + totalCgst + totalSgst + Number(totalOtherChargesWithNoTax)));
    }

   this.props.InwardFillDetails.totalAmount = totalAmount 
   this.props.InwardFillDetails.netTotal = netTotal +Number(totalOtherChargesWithNoTax); 


    var DateTime = this.state.TodayDate.split("T", 2);
    var TodayDate = moment(DateTime[0], "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var Time = DateTime[1].split("+")[0];

    return (
      <>
        <div className="previewBlock">
          <div
            style={{ display: "flex", justifyContent: "end", padding: "10px" }}
          >
            {this.props.locationState.state && (
              <CustomButton
                label="Print"
                className="lightBlueButton"
                muIcon={<PrintIcon />}
                handleClick={this.handlePdf}
                type="button"
              />
            )}
            {this.props.locationState.state !== undefined && this.props.InwardFillDetails.statusName !== "APPROVED" && this.props.InwardFillDetails.statusName !== "REJECTED" && (
              <CustomButton
                label="Edit"
                className="lightBlueButton"
                muIcon={<EditIcon />}
                handleClick={this.handleEdit}
                type="button"
              />)}
          </div>
          <table
            cellpadding="0"
            cellspacing="0"
            width="90%"
            className="previewTable"
          >
            <tbody>
              <tr>
                <td
                  colspan="3"
                  style={{ borderRight: "none", borderBottom: "none" }}
                >
                  {/* <h3>{this.state.companyName}</h3> */}
                  <img src={this.state.companyLogo} width="100%" alt="" />
                </td>
                <td
                  colspan="11"
                  style={{
                    borderBottom: "none",
                    borderLeft: "none",
                    textAlign: "right",
                  }}
                >
                  <h2 style={{ margin: "0px" }}> {this.state.companyName}</h2>
                  <p style={{ margin: "0px" }}>{this.state.address1}</p>
                  <span>GST No:{this.state.gstNo}</span>
                </td>
              </tr>
              <tr>
                <td
                  colspan="14"
                  style={{ textAlign: "center", borderTop: "none" }}
                >
                  {/* {this.props.location.pathname === "/Create_store_receipt" ?    <h2>STORE RECEIPT</h2> :     */}
                  <h2>INWARD RECEIPT</h2>
                  {/* } */}
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  {this.props.location.pathname === "/Create_store_receipt" ? (
                    <b>GRN Number: </b>
                  ) : (
                    <b>Entry Number: </b>
                  )}
                </td>
                <td
                  colspan="3"
                  className={
                    this.props.InwardFillDetails.GateEntryNo === "" ||
                      this.props.InwardFillDetails.GateEntryNo === undefined
                      ? "blur-text"
                      : ""
                  }
                >
                  {this.props.location.pathname === "/Create_store_receipt" ? (
                    <span>{this.props.InwardFillDetails.SRPrintNO}</span>
                  ) : (
                    <span>
                      {this.props.InwardFillDetails.GateEntryNo === "" ||
                        this.props.InwardFillDetails.GateEntryNo === undefined
                        ? "Will be generated after creation"
                        : this.props.InwardFillDetails.GateEntryNo}
                    </span>
                  )}
                </td>
                <td colspan="2">
                  {this.props.location.pathname === "/Create_store_receipt" ? (
                    <b>GRN Date: </b>
                  ) : (
                    <b>Date: </b>
                  )}
                </td>
                <td colspan="6">
                  {this.props.location.pathname === "/Create_store_receipt" ? (
                    <span>
                      {moment(
                        this.props.InwardFillDetails.storeReceiptDate
                      ).format("DD-MM-YYYY")}
                    </span>
                  ) : (
                    <span>
                      {moment(this.props.InwardFillDetails.inwardDate).format(
                        "DD-MM-YYYY"
                      )}
                    </span>
                  )}
                </td>
              </tr>
              {this.props.location.pathname === "/Create_store_receipt" && (
                <tr>
                  <td colspan="2">
                    <b>Supplier</b>
                  </td>
                  <td colspan="3">
                    <span>{this.props.InwardFillDetails.supplierName}</span>
                  </td>
                  <td colspan="2">
                    <b>Entry Number:</b>
                  </td>
                  <td colspan="6">
                    <span>{this.props.InwardFillDetails.GateEntryNo}</span>
                  </td>
                </tr>
              )}
              <tr>
                <td colspan="2">
                  <b>Challan Number: </b>
                </td>
                <td colspan="3">
                  <span>{this.props.InwardFillDetails.challanno}</span>
                </td>
                <td colspan="2">
                  <b>Challan Date: </b>
                </td>
                <td colspan="6">
                  <span>
                    {this.props.InwardFillDetails.challannoDate !== ""
                      ? moment(
                        this.props.InwardFillDetails.challannoDate
                      ).format("DD-MM-YYYY")
                      : ""}
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Vehicle Number: </b>
                </td>
                <td colspan="3">
                  <span>{this.props.InwardFillDetails.vehicleNumber}</span>
                </td>
                <td colspan="2">
                  <b>Driver Name: </b>
                </td>
                <td colspan="6">
                  <span>{this.props.InwardFillDetails.driverName}</span>
                </td>
              </tr>
              {this.props.location.pathname === "/Create_store_receipt" ? (
                ""
              ) : (
                <>
                <tr>
                <td colspan="2">
                    <b>PO Number</b>
                  </td>
                  <td colspan="3">
                    <span>{this.props.InwardFillDetails.poSequence}</span>
                  </td>
                  <td colspan="2">
                    <b>Supplier</b>
                  </td>
                  <td colspan="6">
                    <span>{this.props.InwardFillDetails.supplierName}</span>
                  </td>
                  
                </tr>
                </>
              )}
              <tr>
                <td colspan="2">
                  <b>Consignment Number: </b>
                </td>
                <td colspan="3">
                  <span>{this.props.InwardFillDetails.consignmentNumber}</span>
                </td>
                <td colspan="2">
                  <b>Consignment Date:</b>
                </td>
                <td colspan="6">
                  <span>
                    {this.props.InwardFillDetails.consignmentDate !== ""
                      ? moment(
                        this.props.InwardFillDetails.consignmentDate
                      ).format("DD-MM-YYYY")
                      : ""}
                  </span>
                </td>
              </tr>
              <tr align="center" valign="top">

                <td rowSpan={2} width="10%">
                  <b>Item Code</b>
                </td>
                <td rowSpan={2} width="20%">
                  <b>Item Description</b>
                </td>
                <td rowSpan={2} width="10%">
                  <b>Quantity</b>
                </td>
                <td rowSpan={2} width="5%">
                  <b>Unit</b>
                </td>
                <td rowSpan={2} width="10%">
                  <b>Rate</b>
                </td>
                <td rowSpan={2} width="10%">
                  <b>Discount</b>
                </td>
                <td rowSpan={2} width="10%">
                  <b>Amount</b>
                </td>
                <td width="10%" colSpan={2}>
                  <b>IGST </b>
                </td>
                <td width="10%" colSpan={2}>
                  <b>CGST</b>
                </td>
                <td width="10%" colSpan={2}>
                  <b>SGST</b>
                </td>
              </tr>
              <tr>
                <td align="center">%</td>
                <td align="center">Amt</td>
                <td align="center">%</td>
                <td align="center">Amt</td>
                <td align="center">%</td>
                <td align="center">Amt</td>
              </tr>
              {filteredproducts.map((data, index) => (
                <tr key={index}>
                  <td>
                    {this.props.source === "WITHPO" &&
                      this.props.locationState.state === undefined &&
                      data.itemGroupId
                      ? data.itemGroupId
                      : this.props.source === "WITHOUTPO" && this.props.locationState.state === undefined ? data.itemGroupId : data.itemGroup}
                    {data.itemCode}
                  </td>
                  <td>{data.itemName}
                  <br/>
                  <tr>Make: {data.make}</tr>
                  <tr>Remarks: {data.remarks}</tr>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {" "}
                    {data.itemGroup === "997" ? 1 : parseFloat(data.inwardQty).toFixed(3)}
                  </td>
                  <td>{data.uom ? data.uom : data.uomCode}</td>
                  <td align="right">{parseFloat(data.rate).toFixed(3)}</td>
                  {/* <td align="right">{this.state.locationState.state === undefined ? data.discountPercentage : data.discount}</td> */}
                  <td align="right">
                          {data.discountPercentage
                            ? data.discountPercentage
                            : 0}
                          {data.discountMode && data.discountMode !== 1
                            ? "%"
                            : ""}
                        </td>
                  <td align="right">
                    {parseFloat(
                      Number(data.discountMode === 1) ? (Number(data.inwardQty) * Number(data.rate) - Number(data.discountPercentage))
                        : (Number(data.inwardQty) * Number(data.rate) - (Number(data.inwardQty) * Number(data.rate) * Number(data.discountPercentage) / 100))
                    ).toFixed(2)}
                  </td>
                  <td align="right">
                    {this.props.InwardFillDetails.taxType === 1?parseFloat(Number(data.taxPercentage)).toFixed(2):parseFloat(0).toFixed(2)}
                  </td>
                  <td align="right">
                   {parseFloat(this.props.InwardFillDetails.taxType === 1?this.getIgstAmount(data):0).toFixed(2)}
                  </td>
                  <td align="right">
                    {this.props.InwardFillDetails.taxType ===2?parseFloat(Number(data.taxPercentage)/2).toFixed(2):parseFloat(0).toFixed(2)}
                  
                  </td>
                  <td align="right">
                   {parseFloat(this.props.InwardFillDetails.taxType === 2?this.getIgstAmount(data):0).toFixed(2)}
                  </td>
                  <td align="right"> 
                  {this.props.InwardFillDetails.taxType ===2?parseFloat(Number(data.taxPercentage)/2).toFixed(2):parseFloat(0).toFixed(2)}
                   
                  </td>
                  <td align="right">
                   {parseFloat(this.props.InwardFillDetails.taxType === 2?this.getIgstAmount(data):0).toFixed(2)}
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={5}>
                  <b>Total</b>
                </td>
                <td colSpan={2} align="right">
                  <b> {parseFloat(totalAmount).toFixed(2)}</b>
                </td>
                <td colSpan={2} align="right">
                  <b> {parseFloat(totalIgst).toFixed(2)}</b>
                </td>
                <td colSpan={2} align="right">
                  <b> {parseFloat(totalCgst).toFixed(2)}</b>
                </td>
                <td colSpan={2} align="right">
                  <b>{parseFloat(totalSgst).toFixed(2)}</b>
                </td>
              </tr>
              {otherChargesListWithNoTax.map((data, index) => {
                return (
                  <tr key={index}>
                    <td colspan="3">
                      <b>{data.itemName}</b>
                    </td>
                    <td colSpan={10}>
                      <b>
                        {data.discountMode === "" || data.discountMode === 0.0
                          ? parseFloat(
                              Number(data.inwardQty) * Number(data.rate) -
                                Number(data.discountPercentage)
                            ).toFixed(2)
                          : data.discountMode === 1
                          ? parseFloat(
                              Number(data.inwardQty) * Number(data.rate) -
                                Number(data.discountPercentage)
                            ).toFixed(2)
                          : data.discountMode === 2
                          ? parseFloat(
                              Number(data.inwardQty) * Number(data.rate) -
                                (Number(data.discountPercentage) / 100) *
                                  Number(data.inwardQty) *
                                  Number(data.rate)
                            ).toFixed(2)
                          : null}
                      </b>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan={3}>
                  <b>IGST</b>
                </td>
                <td colSpan={11}>
                  <b>
                    {this.props.InwardFillDetails.taxType === 1
                      ? parseFloat(totalIgst).toFixed(2)
                      : "0.00"}
                  </b>
                </td>
              </tr>

              <tr>
                <td colSpan={3}>
                  <b>CGST</b>
                </td>
                <td colSpan={11}>
                  <b>
                    {this.props.InwardFillDetails.taxType === 1
                      ? "0.00"
                      : parseFloat(totalCgst).toFixed(2)}
                  </b>
                </td>
              </tr>

              <tr>
                <td colSpan={3}>
                  <b>SGST</b>
                </td>
                <td colSpan={11}>
                  <b>
                    {this.props.InwardFillDetails.taxType === 1
                      ? "0.00"
                      : parseFloat(totalSgst).toFixed(2)}
                  </b>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <b>Total Value</b>
                </td>
                <td colSpan={11}>
                  <b>
                    {parseFloat(Math.round( 
                      totalAmount + totalIgst + totalCgst + totalSgst + Number(totalOtherChargesWithNoTax)
                    )).toFixed(2)}{" "}
                  </b>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <b>Total Value(in Words)</b>
                </td>
                <td colSpan={11}>
                  <b>{convertion}</b>
                </td>
              </tr>
              <tr>
                <td
                  colspan="13"
                  // align="left"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {this.props.locationState.state !== undefined ? (
                      <b>
                        Status: {this.props.InwardFillDetails.statusName}
                      </b>
                    ) : (
                      ""
                    )}
                  </div>
                </td>
              </tr>
              {/* <tr>
                {this.props.location.pathname === "/Create_store_receipt" ? (
                  <>
                    <td
                      colspan="3"
                      style={{
                        borderTop: "none",
                        borderBottom: "none",
                        marginTop: "40px",
                        borderRight: "1px solid transparent",
                      }}
                    >
                      <b>Receipt Signature </b>
                    </td>
                    <td
                      colspan="5"
                      style={{
                        borderTop: "none",
                        borderBottom: "none",
                        marginTop: "40px",
                      }}
                    >
                      <b>Store Authority</b>
                    </td>
                    <td
                      colspan="5"
                      style={{
                        borderTop: "none",
                        borderBottom: "none",
                        marginTop: "40px",
                        textAlign: "right",
                        borderLeft: "1px solid transparent",
                      }}
                    >
                      <b>Authorised Signature </b>
                    </td>
                  </>
                ) : (
                  <td
                    colspan="14"
                    style={{
                      borderTop: "none",
                      borderBottom: "none",
                      marginTop: "40px",
                      textAlign: "right",
                    }}
                  >
                    <b>Authorised Signature </b>
                  </td>
                )}
              </tr> */}
              <tr>
                <td
                  colspan="15"
                  style={{ borderTop: "none", borderBottom: "none" }}
                >
                <span><b>Remarks :</b> {this.props.InwardFillDetails.remarks}</span> 
                <br />
                <span><b>Internal Note :</b> {this.props.InwardFillDetails.internalNote}</span> 
                </td>
              </tr>
              <tr>
                <td
                  colspan="6"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {this.props.locationState.state && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box gridColumn="span 12">
                          {this.state.inwardfilesList.length !== 0 &&(<span>
                            <b>Uploaded Documents</b>
                          </span>)}
                        </Box>
                        <Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={2}
                          className="ducumentUploadBlock uploadedContent"
                          style={{ padding: "10px" }}
                        >
                          {this.state.inwardfilesList &&
                            this.state.inwardfilesList.map((item) => (
                              <Box gridColumn="span 6">
                                <div className="documentUploadContent">
                                  <div
                                    className="uploadedInfo"
                                    style={{ display: "flex" }}
                                  >
                                    <img
                                      src={TickIcon}
                                      alt=""
                                      className="tickIcon"
                                      height={"20px"}
                                      width={"20px"}
                                    />
                                    <Tooltip
                                      TransitionComponent={Fade}
                                      TransitionProps={{ timeout: 600 }}
                                      title="Click to download File"
                                    >
                                      <div
                                        className="uplodedFileName"
                                        onClick={() => {
                                          this.getFilesDownload(
                                            item.fileUploadId,
                                            item.fileName,
                                            item.fileExtension
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.fileName}
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </Box>
                            ))}
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Inward_LineItems ,inwardfilesList} = state.InwardReducer;
  return {
    Inward_LineItems,
    inwardfilesList
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getFilesofInward,
    getFileDownload,
    InwardLineItemsProps,
  })(InwardPreview)
);
