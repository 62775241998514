import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import CustomButton from "../../../../../components/Buttons/Buttons";
import TextFieldAlfaNum from "../../../../../components/TextField/TextFieldAlfaNum";
import { serverApi } from "../../../../../helpers/Consts";
import {
  setPersonalDetails,
  viewbyidPersonalDetails,
  viewbyidPersonalDetailsSuccessfull,
  clearPayScheme,
  ClearPaySchemeById,
} from "../../../../../store/HRMS/EmployeeDataBase/actions";
import Button from "@mui/material/Button";
import CommonPopup from "../../../../../components/CommonPopup/CommonPopup";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextFieldNormal from "../../../../../components/TextField/TextFieldNormal";

class BankDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      show: false,
      hide: true,
      response: "",
      handleNext: false,
      tblHrmsEdBankDetailId: null,
      ebId: this.props.getEbId,
      ifscCode: "",
      bankAccNo: "",
      isActive: 1,
      updatedBy: "",
      updatedTime: "",
      bankName: "",
      isVerified: 0,
      bankBranchName: "",
      // Commented for now might need in future.
      // accountHolderName: ""
    };
  }

  onhandlechange = (key) => (event) => {
    if (key === "ifscCode") {
      this.setState({ [key]: event });
    } 
    // Commented for now might need in future.

    // if (key === "accountHolderName") {
    //   this.setState({ [key]: event });
    // }
    else {
      this.setState({ [key]: event.target.value });
    }
  };

  componentWillReceiveProps(props) {
    var ebId = localStorage.getItem("ebId");
    if (ebId > 0) {
      if (props.personalDetails) {
        var personal = props.personalDetails.data;
        if (personal) {
          Object.keys(personal).map((key, index) => {
            this.setState({ [key]: personal[key] });
            return true;
          });
        }
      }
    }
  }
  componentDidMount() {
    var ebId = localStorage.getItem("ebId");
    if (ebId > 0) {
      this.props.viewbyidPersonalDetails(
        serverApi.VIEW_EMPLOYEE_BANK_DIT + "/" + localStorage.getItem("ebId"),
        this.props.history,
        7
      );
    } else {
      const response = { data: null };
      this.props.viewbyidPersonalDetailsSuccessfull({ response });
    }
    this.props.clearPayScheme(null);
    this.props.ClearPaySchemeById(null);
  }

  onClickSave = () => {
    const data = {
      tblHrmsEdBankDetailId: this.state.tblHrmsEdBankDetailId,
      ebId: this.props.getEbId,
      ifscCode: this.state.ifscCode,
      bankAccNo: this.state.bankAccNo,
      isActive: 1,
      updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
      bankName: this.state.bankName,
      isVerified: this.state.isVerified,
      bankBranchName: this.state.bankBranchName
      // accountHolderName: this.state.accountHolderName
    };

    this.props.setPersonalDetails(
      serverApi.UPDATE_BANK_DETAILS,
      data,
      this.props.history,
      "bank"
    );
    this.setState({ handleNext: true });
  };
  onClick = () => {
    this.setState({
      show: !this.state.show,
    });
  };
  render() {
    return (
      <div className="bankDetails">
        <h1>Bank Details</h1>
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
              {/* <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      placeholder="Enter here"
                      label="Account Holder Name"
                      caps="YES"
                      value={this.state.accountHolderName}
                      onChange={this.onhandlechange("accountHolderName")}
                    />
                  </div>
                </Box> */}
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      placeholder="Enter here"
                      label="IFSC Code"
                      caps="YES"
                      value={this.state.ifscCode}
                      onChange={this.onhandlechange("ifscCode")}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 5">
                  <div className="consoleTextFieldBlock">
                    <div className="TextFieldBlock">
                      <label>Account Number </label>
                    </div>

                    <div
                      className={
                        this.props.imgUrl
                          ? "consoleAdormentImgBlock"
                          : "consoleAdormentBlock"
                      }
                    >
                      <TextField
                        type="tel"
                        onChange={this.onhandlechange("bankAccNo")}
                        value={this.state.bankAccNo}
                        placeholder="Enter Account Number"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button onClick={this.onClick}>Verify</Button>
                              <img src={this.props.imgUrl} alt="" />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {this.state.show && (
                        <CommonPopup>
                          Kindly please configure the Payment GateWay to proceed
                          with verification
                          <div className="consoleFormButtonBlock">
                            <CustomButton
                              label={"Cancel"}
                              className="greenBorderButton"
                              handleClick={this.onClick}
                            />
                            <CustomButton
                              label={"OK"}
                              className="greenButton"
                              handleClick={this.onClick}
                              type="sumbit"
                            />
                          </div>
                        </CommonPopup>
                      )}
                    </div>
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
        <div className="consoleFormButtonBlock">
          <Link to="/listOf_employees">
            <CustomButton label={"Cancel"} className="greenBorderButton" />
          </Link>
          {localStorage.getItem("EmpData") === null ? (
            <CustomButton
              label={"Save"}
              className="greenButton"
              handleClick={this.onClickSave}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickSave}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { personalDetails, getEbId } = state.EmployeeReducer;
  return { personalDetails, getEbId };
};

export default withRouter(
  connect(mapStatetoProps, {
    setPersonalDetails,
    viewbyidPersonalDetails,
    viewbyidPersonalDetailsSuccessfull,
    clearPayScheme,
    ClearPaySchemeById,
  })(BankDetails)
);
