import {PAY_REGISTER_LIST,PAY_REGISTER_LIST_SUCCESSFULL,BRANCH_LIST_DATA,BRANCH_LIST_DATA_SUCCESSFULL,
    PAY_PERIOD,PAY_PERIOD_SUCCESSFULL, PAY_PERIOD_BY_ID, PAY_PERIOD_BY_ID_SUCCESSFULL, PROCESSED_LIST,
     PROCESSED_LIST_SUCCESSFULL, UPDATE_PAY_PERIOD, UPDATE_PAY_PERIOD_SUCCESSFULL, GENERATE_INVOICE, 
     GENERATE_INVOICE_SUCCESSFULL, GET_MONTHLY_PAYSALARY_LIST, GET_MONTHLY_PAYSALARY_LIST_SUCCESSFULL} from './actionType'



// get pay salary list 
export const getPaySalaryList = (url,data,history) =>{
    return {
        type:GET_MONTHLY_PAYSALARY_LIST,
        payload:{url,data,history}
    }
}
export const getPaySlaryListSuccessfull = (data)=>{
    return {
        type:GET_MONTHLY_PAYSALARY_LIST_SUCCESSFULL,
        payload:data
    }
}

export const getPayRegisterList = (url,data,history) =>{
    return {
        type:PAY_REGISTER_LIST,
        payload:{url,data,history}
    }
}
export const payRegisterListSuccessfull = (data)=>{
    return {
        type:PAY_REGISTER_LIST_SUCCESSFULL,
        payload:data
    }
}

// BRANCH LIST
export const getBranchListData = (url,history) =>{
    return {
        type:BRANCH_LIST_DATA,
        payload:{url,history}
    }
}
export const BranchListDataSuccessfull = (data)=>{
    return {
        type:BRANCH_LIST_DATA_SUCCESSFULL,
        payload:data
    }
}

// Add pay period
export const setPayPeriod = (url,data,history) =>{
    return {
        type:PAY_PERIOD,
        payload:{url,data,history}
    }
}
export const payPeriodSuccessfull = (data)=>{
    return {
        type:PAY_PERIOD_SUCCESSFULL,
        payload:data
    }
}

// PAY_PERIOD_BY_ID
export const PayPeriodById = (url,history) =>{
    return {
        type:PAY_PERIOD_BY_ID,
        payload:{url,history}
    }
}
export const  PayPeriodByIdSuccessfull = (data)=>{
    return {
        type:PAY_PERIOD_BY_ID_SUCCESSFULL,
        payload:data
    }
}
// PROCESSED_LIST
export const processedList = (url,history) =>{
    return {
        type:PROCESSED_LIST,
        payload:{url,history}
    }
}
export const  processedListSuccessfull = (data)=>{
    return {
        type:PROCESSED_LIST_SUCCESSFULL,
        payload:data
    }
}
// UPDATE_PAY_PERIOD
export const updatePayPeriod = (url,data,history) =>{
    return {
        type:UPDATE_PAY_PERIOD,
        payload:{url,data,history}
    }
}
export const  updatePayPeriodSuccessfull = (data)=>{
    return {
        type:UPDATE_PAY_PERIOD_SUCCESSFULL,
        payload:data
    }
}
// iNVOICE 
export const generateInvoice = (url,data,history) =>{
    return {
        type:GENERATE_INVOICE,
        payload:{url,data,history}
    }
}
export const  generateInvoiceSuccessfull = (data)=>{
    return {
        type:GENERATE_INVOICE_SUCCESSFULL,
        payload:data
    }
}