import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Box, TextField, Button, InputAdornment } from "@mui/material";
import TextFieldNormal from "../../../../components/TextField/TextFieldNormal";
import { getJuteYarnInvoiceHeader,getVehicleType } from "../../../../store/Sales/InVoice/actions";
import { getTermsConditions } from "../../../../store/Sales/SalesOrder/actions";
import { serverApi } from "../../../../helpers/Consts";
import DynamicSelect from "../../../../components/Dropdown/DynamicSelect";
import DynamicSelectNew from "../../../../components/Dropdown/DropdownSelectNew";
import swal from "sweetalert";
const JuteYarnTransportation = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [HeaderData, setHeaderData] = useState({});
  const [ vehicleTypeData, setVehicleTypeData ]=useState([]);
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [TermsData, setTermsData] = useState([]);
  const { JuteYarnInvoiceHeader,vehicleType } = useSelector(
    (state) => state.InvoiceReducer
  );
  const { TermsConditions } = useSelector((state) => state.SalesOrderReducer);

  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  useEffect(() => {
    setHeaderData(JuteYarnInvoiceHeader);
    if (TermsConditions) {
      if (TermsConditions.length !== 0) {
        var list = [];
        TermsConditions.map((item) => {
          list.push({
            value: item.autoId,
            label: item.termsAndConditions,
            name: item.termsAndConditions,
          });
        });
      }
      setTermsData(list);
    }
    if (vehicleType) {
      if (vehicleType.length !== 0) {
        var list = [];
        list.push({
          value: "",
          label: "Select",
          name:  "Select",
        });

        vehicleType.map((item) => {
          list.push({
            value: item.id,
            label: item.vehicleType,
            name: item.vehicleType,
          });
        });
      }
      setVehicleTypeData(list);
    }
  }, [TermsConditions, JuteYarnInvoiceHeader,vehicleType]);

  useEffect(() => {
    const data = {
      orgId: userDit.orgId,
      autoId: "",
      termsAndConditions: "",
      startIndex: "",
      lastIndex: "",
    };
    dispatch(getTermsConditions(serverApi.GET_ALL_TERMS_LIST, data, history));
    const vehicleData={
      cipher: userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      startIndex:"",
      lastIndex:"",
    }
    dispatch(
      getVehicleType(
        serverApi.GET_ALL_VEHICLE_TYPES_MASTER_LIST,vehicleData,
        history
      )
    );
  }, []);

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };

  const handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name == "termsCondition") {
      setHeaderData((prev) => ({
        ...prev,
        termsCondition: selectedName,
        termsConditionType: selectedValue,
      }));
      const UpdatedRec = {
        ...HeaderData,
        termsCondition: selectedName,
        termsConditionType: selectedValue,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (name === "transportationMode") {
      setHeaderData((prev) => ({
        ...prev,
        transportationMode: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        transportationMode: selectedName,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    };

    if (name === "vehicleType") {
      setHeaderData((prev) => ({
        ...prev,
        vehicleType: selectedValue,
      }));
      const UpdatedRec = {
        ...HeaderData,
        vehicleType: selectedValue,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
  };
  

  const onhandlechange = (key) => (event) => {
    if (key === "vehicleNumber") {
      setHeaderData((prev) => ({
        ...prev,
        vehicleNumber: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        vehicleNumber: event,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (key === "footerNote") {
      setHeaderData((prev) => ({
        ...prev,
        footerNote: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        footerNote: event,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (key === "paymentTerms") {
      setHeaderData((prev) => ({
        ...prev,
        paymentTerms: event.target.value,
      }));
      const UpdatedRec = {
        ...HeaderData,
        paymentTerms: event.target.value,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (key === "otherCharges") {
      setHeaderData((prev) => ({
        ...prev,
        otherCharges: event,
      }));
    }
    if (key === "otherChargeAmt") {
      setHeaderData((prev) => ({
        ...prev,
        otherChargeAmt: event,
      }));
    }
    if (key === "internalNote") {
      setHeaderData((prev) => ({
        ...prev,
        internalNote: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        internalNote: event,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (key === "transportationDistance") {
      setHeaderData((prev) => ({
        ...prev,
        transportationDistance: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        transportationDistance: event,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (key === "transporterDocNo") {
      setHeaderData((prev) => ({
        ...prev,
        transporterDocNo: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        transporterDocNo: event,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (key === "transporterNumber") {
      setHeaderData((prev) => ({
        ...prev,
        transporterNumber: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        transporterNumber: event,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
  };
  return (
    <>
      <div>
        Jute Yarn Transportation
        <div className="consoleFormContainer">
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Footer Notes"
                      value={HeaderData.footerNote}
                      onChange={onhandlechange("footerNote")}
                      disabled={true}
                    />
                  </Box>

                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Delivery Days"
                      type="number"
                      value={HeaderData.deliveryDays}
                      onChange={onhandlechange("deliveryDays")}
                      disabled={true}
                    />
                  </Box>
                 
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>Payment Terms (In Days)</label>
                      </div>
                      <div className="consoleAdormentBlock">
                      <TextField
                        value={HeaderData.paymentTerms}
                        onChange={onhandlechange("paymentTerms")}
                        type="number"
                        disabled={true}
                          placeholder="Enter here"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={onclick}>Days</Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>  
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <DynamicSelect
                      update={HeaderData.termsConditionType ? 1 : 0}
                      arrayOfData={TermsData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.termsConditionType}
                      name="termsCondition"
                      label="Terms & Conditions"
                      isDisabled={true}
                    />
                  </Box>

                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Internal Note"
                      value={HeaderData.internalNote}
                      onChange={onhandlechange("internalNote")}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelectNew
                      update={HeaderData.deliveryTerms ? 1 : 0}
                      arrayOfData={TransportationModeData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.transportationMode}
                      name="transportationMode"
                      label="Transportation Mode"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelect
                      update={HeaderData.deliveryTerms ? 1 : 0}
                      arrayOfData={vehicleTypeData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.vehicleType}
                      name="vehicleType"
                      label="vehicle Type"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Vehicle No"
                      value={HeaderData.vehicleNumber}
                      onChange={onhandlechange("vehicleNumber")}
                      caps="ON"
                      required={HeaderData.transportationMode === "Road"}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      type="number"
                      label="Transportation Distance (Kms)"
                      value={HeaderData.transportationDistance}
                      onChange={onhandlechange("transportationDistance")}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      type="text"
                      label="Transporter Document No."
                      value={HeaderData.transporterDocNo}
                      onChange={onhandlechange("transporterDocNo")}
                      caps="ON"
                      required={HeaderData.transportationMode === "Rail"}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      type="text"
                      label="Transporter No."
                      value={HeaderData.transporterNumber}
                      onChange={onhandlechange("transporterNumber")}
                      caps="ON"
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};
const TransportationModeData=[
  {
    value: 1,
    label: "Road",
    name: "Road",
  }, {
    value: 2,
    label: "Rail",
    name: "Rail",
  }, {
    value: 3,
    label: "Air",
    name: "Air",
  }, {
    value: 4,
    label: "Ship",
    name: "Ship",
  },
  {
    value: 5,
    label: "Ship Cum Road/Rail",
    name: "Ship Cum Road/Rail",
  },
]

export default JuteYarnTransportation;
