import React, { useState, useEffect } from "react";
import {
  Grid,
  Step,
  Stepper,
  StepLabel,
  Box,
  Link as MuiLink,
} from "@mui/material";
import Link from "@mui/material/Link";
import moment from "moment";
import swal from "sweetalert";
import CustomButton from "../../../components/Buttons/Buttons";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import cons, { serverApi } from "../../../helpers/Consts";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { getBankAccountList } from "../../../store/Sales/DeliveryOrder/actions";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import {
  getSoDropDown,
  getRegularDeliveryOrderHeader,
  getAllTransporter,
} from "../../../store/Sales/DeliveryOrder/actions";
import { SalesOrderViewById } from "../../../store/Sales/DeliveryOrder/actions";
import {
  getBranchList,
  getCustomerList,
  getBillingShippingList,
} from "../../../store/Global/DropDownApis/actions";
import { getQuotationBroker } from "../../../store/Sales/Qutation/actions";
import FormPopup from "../../../components/FormlPopup/FormPopup";
import { isEmpty,isEmptyWithZero } from "../../../Utilities/helper";
import { SalesGstCalculations } from "../../../Utilities/SalesGstCalculation";
const RegularFillDetails = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  let location=useLocation();
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [HeaderData, setHeaderData] = useState({});
  const [invoiceData, setinvoiceData] = useState(props.invoiceData);
  const [SoDropdownVal, setSoDropdownVal] = useState([]);
  const [branchListData, setbranchListData] = useState([]);
  const [bankListData,setbankListData]=useState([]);
  const [brokerData, setbrokerData] = useState([]);
  const [CustomerData, setCustomerData] = useState([]);
  const [billingAddressData, setbillingAddressData] = useState([]);
  const [shippingAddressData, setshippingAddressData] = useState([]);
  const [transporterData, settransporterData] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [LineItems, setLineItems] = useState([]);
  const [customerListData, SetCustomerListData] = useState([]);
  const [emailCustomer, setEmailCustomer] = useState("");
  const [backDaysAllowable, setBackDaysAllowable] = useState("");
  const [futureDaysAllowable, setFutureDaysAllowable] = useState("");
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));

  const { RegularDeliveryOrderHeader, SrDropDownData, TransporterList,bankAccountList, RegularDeliveryOrderLineItems } =
    useSelector((state) => state.DeliveryOrderReducer);
  const { BrokerData } = useSelector((state) => state.QuotationReducer);
  
  useEffect(() => {
    setLineItems(RegularDeliveryOrderLineItems)
    setHeaderData(RegularDeliveryOrderHeader);
  }, [RegularDeliveryOrderHeader]);
 
  const handlePropSelectChange = (selectedValue, selectedName, name,selectedlable) => {
    if (name == "salesOrderId") {
      setHeaderData((prev) => ({
        ...prev,
        salesOrderId: selectedValue,
        salesOrderNumber: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        salesOrderId: selectedValue,
        salesOrderNumber: selectedName,
      };
      dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
      dispatch(
        SalesOrderViewById(
          serverApi.SALES_ORDER_VIEW_BYID +
            "1/" +
            selectedValue +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).userId,
          history
        )
      );
    }
    if (name == "invoiceType") {
      setHeaderData((prev) => ({
        ...prev,
        invoiceType: selectedValue,
      }));
      const UpdatedRec = {
        ...HeaderData,
        invoiceType: selectedValue,
      };
      dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
    }
    if (name == "branch") {
      setHeaderData((prev) => ({
        ...prev,
        branch: selectedValue,
        branchName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        branch: selectedValue,
        branchName: selectedName,
      };
      dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
    }
    if (name == "customerId") {
      setHeaderData((prev) => ({
        ...prev,
        customerId: selectedValue,
        customerName: selectedName,
      }));
      let TaxPer = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: 2,
        },
        "SALES_REGULAR_FLOW"
      );
      handleCalculationCustomer(TaxPer,selectedValue,selectedName);
  
      dispatch(
        getBillingShippingList(
          serverApi.GET_ALL_BILLING_SHIPPING +
            selectedValue +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).cipher +
            "?isForSalesModule=1",
          history
        )
      );
    }
    if (name === "bankId") {
      var Data = selectedName.split("^", 5);
      setHeaderData((prev) => ({
        ...prev,
        bankId: selectedValue,
        bankName: Data[0],
        accountNo:selectedValue ===0?"": Data[1],
        ifscCode:selectedValue ===0?"": Data[2],
        micrCode:selectedValue ===0?"": Data[3],
        bankBranch :selectedValue ===0?"": Data[4],
      }));
      const UpdatedRec = {
        ...HeaderData,
        bankId: selectedValue,
        bankName: Data[0],
        accountNo: Data[1],
        ifscCode:Data[2],
        micrCode: Data[3],
        bankBranch: Data[4],
      };
      dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
    }
    if (name == "brokerId") {
      if(selectedValue === 0) {
        setHeaderData((prev) => ({
          ...prev,
          brokerId: "",
          brokerCommisionPercent: "",
          brokerName: ""
        }));
        const UpdatedRec = {
          ...HeaderData,
          brokerId: "",
          brokerCommisionPercent: "",
          brokerName: ""
        };
        dispatch(getRegularDeliveryOrderHeader(UpdatedRec));

        return
      }
      setHeaderData((prev) => ({
        ...prev,
        brokerId: selectedValue,
        brokerCommisionPercent: "",
        brokerName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        brokerId: selectedValue,
        brokerCommisionPercent: "",
        brokerName: selectedName,
      };
      dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
    }
    if (name == "billingTo") {
      customerListData.filter((prop) => {
          if (prop.customerLineId === selectedValue) {
      setHeaderData((prev) => ({
        ...prev,
        billingTo: selectedValue,
        billingToAddress: selectedName,
        billingToGST: prop.gstNo,
        billingContactNo: prop.contactNo,
        billingContactPerson: prop.contactPerson,
          billingToEmail: emailCustomer,
          billingState : prop.state,
      }));
      const UpdatedRec = {
        ...HeaderData,
        billingTo: selectedValue,
        billingToAddress: selectedName,
        billingToGST: prop.gstNo,
        billingContactNo: prop.contactNo,
        billingContactPerson: prop.contactPerson,
          billingToEmail: emailCustomer,
          billingState : prop.state,
      };
      dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
      return prop;
    }
  });
}
if (name == "shippingTo") {
  customerListData.filter((prop) => {
    if (prop.customerLineId === selectedValue) {
      setHeaderData((prev) => ({
        ...prev,
        shippingTo: selectedValue,
        shippingToAddress: selectedName,
        shippingToGST: prop.gstNo,
        shippingContactNo: prop.contactNo,
        shippingContactPerson: prop.contactPerson,
          shippingToEmail: emailCustomer,
          shippingState :prop.state,
      }));
      const UpdatedRec = {
        ...HeaderData,
        shippingTo: selectedValue,
        shippingToAddress: selectedName,
        shippingToGST: prop.gstNo,
        shippingContactNo: prop.contactNo,
        shippingContactPerson: prop.contactPerson,
        shippingToEmail: emailCustomer,
        shippingState :prop.state,
      };
      dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
      return prop;
    }
  });
}
    if ((name == "taxType")) {
      setHeaderData((prev) => ({
        ...prev,
        taxType: selectedValue,
      }));
      const UpdatedRec = {
        ...HeaderData,
        taxType: selectedValue,
      };
      dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
    }
    if (name === "deliveryTerms") {
      setHeaderData((prev) => ({
        ...prev,
        deliveryTerms: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        deliveryTerms: selectedName,
      };
      dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
        }
    if(name =="transporter"){
      var data = selectedName.split("^");
      setHeaderData((prev) => ({
        ...prev,
        transporter: selectedValue,
        transporterName: data[0],
        transporterAddress: data[1],
        transporterStateCode: data[3],
        transporterStateName: data[2],
          transporterGst: data[4],
      }));
      const UpdatedRec = {
        ...HeaderData,
        transporter: selectedValue,
        transporterName: data[0],
        transporterAddress: data[1],
        transporterStateCode: data[3],
        transporterStateName: data[2],
          transporterGst: data[4],
      };
      dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
    }
  };
  const handleCalculationCustomer = (Calculations,selectedValue,selectedName) => {
    setLineItems(Calculations.lineItems);
     dispatch(getRegularDeliveryOrderHeader(Calculations.lineItems));
 
     let Headerdata = {
       ...RegularDeliveryOrderHeader,
       totalIGST: Calculations.totalIGST,
       totalCGST: Calculations.totalCGST,
       totalSGST: Calculations.totalSGST,
       taxTotal: Calculations.taxTotal,
       TotalAmount: Calculations.TotalAmount,
       netTotal: Calculations.netTotal,
       subTotal: Calculations.subTotal,
       customerId: selectedValue,
       customerName: selectedName,
     };
     setbillingAddressData([])
     setshippingAddressData([])
     setHeaderData((prev) => ({
       ...prev,
       totalIGST: Calculations.totalIGST,
       totalCGST: Calculations.totalCGST,
       totalSGST: Calculations.totalSGST,
       taxTotal: Calculations.taxTotal,
       TotalAmount: Calculations.TotalAmount,
       netTotal: Calculations.netTotal,
       subTotal: Calculations.subTotal,
       customerId: selectedValue,
       customerName: selectedName,
 
     }));
     dispatch(getRegularDeliveryOrderHeader(Headerdata));
   };
  const { branchList, customerList, billingShippingList } = useSelector(
    (state) => state.DropDownListReducer
  );
 

  useEffect(() => {
    const UpdatedRec = {
      ...RegularDeliveryOrderHeader,
      invoiceType: props.invoiceType,
    };
    setHeaderData(UpdatedRec);
  }, [RegularDeliveryOrderHeader]);

  const handleSelect_Date = (e, name) => {
    if (name === "deliveryDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      if(Date<HeaderData.salesOrderDate){
        swal('Delivery Order Date Should be greater than or equals to Sale Date')
        setHeaderData((prev) => ({
          ...prev,
          deliveryDate: HeaderData.salesOrderDate,
        }));
        const UpdatedRec = {
          ...HeaderData,
          deliveryDate: HeaderData.salesOrderDate,
        };
        dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
      }else{
        setHeaderData((prev) => ({
          ...prev,
          deliveryDate: Date,
        }));
        const UpdatedRec = {
          ...HeaderData,
          deliveryDate: Date,
        };
        dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
      }
    
    }
  };
  const onhandlechange = (key) => (event) => {
    if (key === "quotationNumber") {
      setHeaderData((prev) => ({
        ...prev,
        quotationNumber: event,
      }));
      const UpdatedRec = {
        ...RegularDeliveryOrderHeader,
        quotationNumber: event,
      };
      dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
    }
    if (key === "transporter") {
      setHeaderData((prev) => ({
        ...prev,
        transporter: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        transporter: event,
      };
      dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
    }
    if (key === "vehicleNo") {
      setHeaderData((prev) => ({
        ...prev,
        vehicleNo: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        vehicleNo: event,
      };
      dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
    }

    if (key === "brokerCommisionPercent") {
      let Val = event.match(/^(\d*\.{0,1}\d{0,2}$)/);
      var Event = "";
      if (Val) {
        if (event > 100) {
          swal("Cannot enter more than 100%");
          Event = "";
        } else {
          Event = event;
        }
      } else {
        swal("Cannot enter more than two decimals");
        Event = "";
      }
      setHeaderData((prev) => ({
        ...prev,
        brokerCommisionPercent: Event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        brokerCommisionPercent: Event,
      };
      dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
    }
  
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    dispatch(
      getSoDropDown(
        serverApi.SALES_ORDER_DROPDOWN +
          props.invoiceType +
          "/" +
          localStorage.getItem("companyId")
      )
    );
    dispatch(
      getBranchList(
        serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
        history
      )
    );
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
    };
    dispatch(getCustomerList(serverApi.GET_ALL_CUSTOMERS, data, history));
    dispatch(
      getQuotationBroker(
        serverApi.GET_ALL_BROKER_LIST +
          localStorage.getItem("companyId") +
          "/SALE",
        history
      )
    );
    dispatch(getBankAccountList(serverApi.GET_ALL_BANK_ACCOUNT_LIST, data,history));
    dispatch(
      getAllTransporter(
        serverApi.GET_ALL_TRANSPORTER_MASTER_LIST +
          localStorage.getItem("companyId")
      )
    );
    const currentMonth = moment().format('MM');
    const currentDate = moment().format('DD');
    const fiscalYearStart = moment(`${localStorage.getItem("acadamicYear")}-${currentMonth}-${currentDate}`);
    const today = moment();
    let dateValue;
    if (today.isBefore(fiscalYearStart)) {
      dateValue = today.format("YYYY-MM-DD");
    } else {
      dateValue = fiscalYearStart.format("YYYY-MM-DD");
    }

    if (userDit) {
      userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              if (item.backDaysAllowable !== undefined) {
                let backDaysAllowable = JSON.parse(item.backDaysAllowable);
                let backDays = backDaysAllowable.bdaystype;
                if (Number(backDays) === 1) {
                  backDays = 7;
                } else if (Number(backDays) === 2) {
                  backDays = 14;
                } else if (Number(backDays) === 3) {
                  backDays = "";
                } else if (Number(backDays) === 4) {
                  backDays = backDaysAllowable.otherBackDateValue;
                } else {
                  backDays = backDaysAllowable.bdaystype;
                }
                console.log(dateValue)
                let minDate = moment(dateValue).subtract(
                  Number(backDays),

                  "days"
                );
                let setMinDate = moment(minDate).format("YYYY-MM-DD");
                if(backDays !==""){
                setBackDaysAllowable(setMinDate);
                }
              }
              if (item.futureDaysAllowable !== undefined) {
                let futureDaysAllowable = JSON.parse(item.futureDaysAllowable);
                let futureDays = futureDaysAllowable.fdaystype;
                if (Number(futureDays) === 1) {
                  futureDays = 7;
                } else if (Number(futureDays) === 2) {
                  futureDays = 14;
                } else if (Number(futureDays) === 3) {
                  futureDays = "";
                } else if (Number(futureDays) === 4) {
                  futureDays = futureDaysAllowable.othervalue;
                } else {
                  futureDays = futureDaysAllowable.fdaystype;
                }
                let maxDate = moment(dateValue).add(
                  Number(futureDays),

                  "days"
                );
                let setMaxDate = moment(maxDate).format("YYYY-MM-DD");
                if(futureDays!==""){
                  setFutureDaysAllowable(setMaxDate);
                }
              }
            });
            setHeaderData((prev) => ({
              ...prev,
              // deliveryDate: dateValue,
            }));
            const UpdatedRec = {
              ...RegularDeliveryOrderHeader,
              // deliveryDate: dateValue,
            };
            dispatch(getRegularDeliveryOrderHeader(UpdatedRec));
          }
      });
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (SrDropDownData) {
      if (SrDropDownData.data) {
        let List = [];
        SrDropDownData.data.map((item) => {
          List.push({
            value: item.id,
            name: item.Value,
            label: item.Value,
          });
        });
        setSoDropdownVal(List);
      }
    }
    if (branchList) {
      if (branchList.data) {
        if (branchList.data.length !== 0) {
          let List = branchList.data.filter((item) => item.value !== 0);
          setbranchListData(List);
        }
      }
    }
    if (customerList) {
      if (customerList.data) {
        if (customerList.data.length !== 0) {
          var CusList = [];
          customerList.data.map((item) => {
            CusList.push({
              value: item.id,
              name: item.name,
              label: item.name,
            });
          });
          setCustomerData(CusList);
        }
      }
    }
    if (bankAccountList) {
      if (bankAccountList.data) {
        if (bankAccountList.data.length !== 0) {
          var BankList = [];
          // BankList.push(bankSelect)
          bankAccountList.data.data.map((item) => {
            BankList.push({
              value: item.id,
              name:
              item.bankName +
              "^" +
              item.accountNo +
              "^" +
              item.ifscCode +
              "^" +
              item.micrCode +
              "^"+
              item.branch,
              label: item.bankName,
            });
          });
          
          setbankListData(BankList);
        }
      }
    }
    if (BrokerData) {
      if (BrokerData.data) {
        setbrokerData(BrokerData.data);
      }
    }
    if(TransporterList){
    if (TransporterList.data) {
      if (TransporterList.data.data.length !== 0) {
        let TransporterLists = TransporterList.data.data.map((item) => ({
          name:
            item.transporterName +
            "^" +
            item.transporterAddress +
            "^" +
            item.stateName +
            "^" +
            item.stateCode +
            "^" +
            item.transporterGst,
            label: item.transporterName,
            value: item.transporterId,
          }));
          settransporterData(TransporterLists);
      }
    }
  }
  }, [SrDropDownData, branchList, customerList, BrokerData,TransporterList,bankAccountList]);
  const isEmptyObj = (myObject) => {
    const keys = Object.keys(myObject);
    return keys.length === 0;
  };
  useEffect(() => {
    if (billingShippingList) {
      if (billingShippingList.data) {
        setEmailCustomer(billingShippingList.data.customerHdr.emailId);
        if (billingShippingList.data.customerLineitems) {
          if (billingShippingList.data.customerLineitems.length !== 0) {
            var list = [];
            let billingAddressList = [];
            let shippingaddressList = [];
            let RegularDOHeaderObj = RegularDeliveryOrderHeader
            let customerLineitems = billingShippingList.data.customerLineitems;
            SetCustomerListData(customerLineitems);
            billingShippingList.data.customerLineitems.map((prop, i) => {
              if (prop.addressType === 2 || prop.addressType === null) {
                if (
                  (prop.customerLineId === RegularDeliveryOrderHeader.shippingTo &&
                    prop.isActive === 0) ||
                  prop.isActive !== 0
                ) {
                  billingAddressList.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
              list.push({
                value: prop.customerLineId,
                label: prop.address,
                name: prop.address,
              });
            }
          }
            if (prop.addressType === 1 || prop.addressType === null || prop.addressType === 2) {
              if (
                (prop.customerLineId === RegularDeliveryOrderHeader.shippingTo &&
                  prop.isActive === 0) ||
                prop.isActive !== 0
              ) {
                shippingaddressList.push({
                  value: prop.customerLineId,
                  label: prop.address,
                  name: prop.address,
                });
                list.push({
                  value: prop.customerLineId,
                  label: prop.address,
                  name: prop.address,
                });
              }
            }

        });

        if(RegularDeliveryOrderHeader.customerResponse){
          billingShippingList.data.customerLineitems.map((prop, i) => {
            if (billingAddressList.length !== 0 &&  HeaderData.customerId !== "" &&
            HeaderData.customerId !== null) {
              billingAddressList.map((billingdata, ind) => {
                if (ind === 0) {
                  if (!isEmptyObj(HeaderData)) {
                    if (
                      HeaderData.customerId !== "" ||
                      HeaderData.customerId !== null
                    ) {
                      if (prop.customerLineId === billingdata.value) {
                        setHeaderData((prev) => ({
                          ...prev,
                          ContactNumber: billingShippingList.data.customerHdr.contactNo,
                          ContactPerson:
                            billingShippingList.data.customerHdr.contactPerson,
                          billingTo: prop.customerLineId,
                          billingToAddress: prop.address,
                          billingToGST: prop.gstNo,
                          billingContactNo: prop.contactNo,
                          billingContactPerson: prop.contactPerson,
                          billingToEmail: emailCustomer,
                          billingState : prop.state,
                          taxType: 2,
                        }));
                        const UpdatedRec = {
                          ...RegularDOHeaderObj,
                          ContactNumber: billingShippingList.data.customerHdr.contactNo,
                          ContactPerson:
                            billingShippingList.data.customerHdr.contactPerson,
                          billingTo: prop.customerLineId,
                          billingToAddress: prop.address,
                          billingToGST: prop.gstNo,
                          billingContactNo: prop.contactNo,
                          billingContactPerson: prop.contactPerson,
                          billingToEmail: emailCustomer,
                          billingState : prop.state,
                          taxType: 2,
                        };
                        RegularDOHeaderObj = UpdatedRec
                      }
                    }else{
                      setHeaderData((prev) => ({
                        ...prev,
                        ContactNumber: "",
                        ContactPerson: "",
                        billingTo: "",
                        billingToAddress: "",
                        billingToGST: "",
                        billingContactNo: "",
                        billingContactPerson: "",
                        billingToEmail: "",
                        billingState : "",
                        taxType: "",
                      }));
                      const UpdatedRec = {
                        ...RegularDOHeaderObj,
                        ContactNumber: "",
                        ContactPerson: "",
                        billingTo: "",
                        billingToAddress: "",
                        billingToGST: "",
                        billingContactNo: "",
                        billingContactPerson: "",
                        billingToEmail: "",
                        billingState : "",
                        taxType: "",
                      };
                      RegularDOHeaderObj = UpdatedRec
                    }
                  }
                }
              });
            } else {
              setHeaderData((prev) => ({
                ...prev,
                ContactNumber: "",
                ContactPerson: "",
                billingTo: "",
                billingToAddress: "",
                billingToGST: "",
                billingContactNo: "",
                billingContactPerson: "",
                billingToEmail: "",
                billingState : "",
                taxType: "",
              }));
              const UpdatedRec = {
                ...RegularDOHeaderObj,
                ContactNumber: "",
                ContactPerson: "",
                billingTo: "",
                billingToAddress: "",
                billingToGST: "",
                billingContactNo: "",
                billingContactPerson: "",
                billingToEmail: "",
                billingState : "",
                taxType: "",
              };
              RegularDOHeaderObj = UpdatedRec

            }
          })
        }
        if(RegularDeliveryOrderHeader.customerResponse){
          billingShippingList.data.customerLineitems.map((prop, i) => {
            if (shippingaddressList.length !== 0 &&  HeaderData.customerId !== "" &&
            HeaderData.customerId !== null) {
              shippingaddressList.map((shippingdata, ind) => {
                if (ind === 0) {
                  if (!isEmptyObj(HeaderData)) {
                    if (
                      HeaderData.customerId !== "" ||
                      HeaderData.customerId !== null
                    ) {
                      if (prop.customerLineId === shippingdata.value) {
                        setHeaderData((prev) => ({
                          ...prev,
                          ContactNumber: billingShippingList.data.customerHdr.contactNo,
                          ContactPerson:
                            billingShippingList.data.customerHdr.contactPerson,
                          shippingTo: prop.customerLineId,
                          shippingToAddress: prop.address,
                          shippingToGST: prop.gstNo,
                          shippingContactNo: prop.contactNo,
                          shippingContactPerson: prop.contactPerson,
                          shippingToEmail: emailCustomer,
                          shippingState :prop.state,
                          taxType: 2,
                        }));
                        const UpdatedRec = {
                          ...RegularDOHeaderObj,
                          ContactNumber: billingShippingList.data.customerHdr.contactNo,
                          ContactPerson:
                            billingShippingList.data.customerHdr.contactPerson,
                          shippingTo: prop.customerLineId,
                          shippingToAddress: prop.address,
                          shippingToGST: prop.gstNo,
                          shippingContactNo: prop.contactNo,
                          shippingContactPerson: prop.contactPerson,
                          shippingToEmail: emailCustomer,
                          shippingState :prop.state,
                          taxType: 2,
                        };
                        RegularDOHeaderObj = UpdatedRec
                      }
                    }else{
                      setHeaderData((prev) => ({
                        ...prev,
                        ContactNumber: "",
                        ContactPerson: "",
                        shippingTo: "",
                        shippingToAddress: "",
                        shippingToGST: "",
                        shippingContactNo: "",
                        shippingContactPerson: "",
                        shippingToEmail: "",
                        shippingState :"",
                        taxType: "",
                      }));
                      const UpdatedRec = {
                        ...RegularDOHeaderObj,
                        ContactNumber: "",
                        ContactPerson: "",
                        shippingTo: "",
                        shippingToAddress: "",
                        shippingToGST: "",
                        shippingContactNo: "",
                        shippingContactPerson: "",
                        shippingToEmail: "",
                        shippingState :"",
                        taxType: "",
                      };
                      RegularDOHeaderObj = UpdatedRec
                    }
                  }
                }
              });
            }  else {
              setHeaderData((prev) => ({
                ...prev,
                ContactNumber: "",
                ContactPerson: "",
                shippingTo: "",
                shippingToAddress: "",
                shippingToGST: "",
                shippingContactNo: "",
                shippingContactPerson: "",
                shippingToEmail: "",
                shippingState :"",
                taxType: "",
              }));
              const UpdatedRec = {
                ...RegularDOHeaderObj,
                ContactNumber: "",
                ContactPerson: "",
                shippingTo: "",
                shippingToAddress: "",
                shippingToGST: "",
                shippingContactNo: "",
                shippingContactPerson: "",
                shippingToEmail: "",
                shippingState :"",
                taxType: "",
              };
              RegularDOHeaderObj = UpdatedRec
            }
          });
        }
            if (RegularDeliveryOrderHeader.customerId !== "") {
              dispatch(getRegularDeliveryOrderHeader(RegularDOHeaderObj));
              setbillingAddressData(billingAddressList);
              setshippingAddressData(shippingaddressList);
            }
          }
        }
      }
    }
  }, [billingShippingList]);

  const handleScroll = () => {
    setisDropdownOpen(true)
  };
  const handlemenuOpen=()=>{
      setisDropdownOpen(false)
  }

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };
console.log(RegularDeliveryOrderHeader)
  return (
    <>
      <div>
        <div className="consoleFormContainer">
          <h5>Regular Fill Details </h5>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                    {props.locationState.state == undefined ? ( <DynamicSelect
                        update={HeaderData.salesOrderId ? 1 : 0}
                        arrayOfData={SoDropdownVal}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handlemenuOpen}
                        selected={HeaderData.salesOrderId}
                        label="Sales Order"
                        name="salesOrderId"
                        required
                        // isDisabled={location.state !== undefined ? true : false}
                      />)
                       : (
                          <TextFieldNormal
                            label="Sales Order"
                            value={HeaderData.salesOrderNumber}
                            onChange={onhandlechange("salesOrderNumber")}
                            disabled={true}
                          />
                        )}
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>Sales Order Date</label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            disabled={true}
                            name="salesOrderDate"
                            inputFormat="dd-MM-yyyy"
                            id="salesOrderDate"
                            value={HeaderData.salesOrderDate}
                            fullWidth
                            onChange={(e) =>
                              handleSelect_Date(e, "salesOrderDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                                style={{
                                  backgroundColor: "#ccd6db",
                                }}
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  style={{
                                    backgroundColor: "#ccd6db",
                                  }}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.invoiceType ? 1 : 0}
                        arrayOfData={invoiceData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handlemenuOpen}
                        selected={HeaderData.invoiceType}
                        label="Invoice Type"
                        name="invoiceType"
                        isDisabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.branch ? 1 : 0}
                        arrayOfData={branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handlemenuOpen}
                        selected={HeaderData.branch}
                        label="Branch"
                        name="branch"
                        required
                        isDisabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Delivery Order Date{" "}
                            <span className="starcolor">*</span>
                          </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="deliveryDate"
                            inputFormat="dd-MM-yyyy"
                            id="deliveryDate"
                            minDate={
                              backDaysAllowable !== ""
                                ? backDaysAllowable
                                :HeaderData.deliveryDate
                            }
                            maxDate={
                              futureDaysAllowable !== ""
                                ? futureDaysAllowable
                                : HeaderData.deliveryDate
                            }
                            value={HeaderData.deliveryDate}
                            // minDate={HeaderData.deliveryDate}
                            fullWidth
                            onChange={(e) =>
                              handleSelect_Date(e, "deliveryDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Quotation No"
                      value={HeaderData.quotationNumber}
                      onChange={onhandlechange("quotationNumber")}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                        <label>Quotation Date {" "}</label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            disabled={true}
                            name="quotationDate"
                            inputFormat="dd-MM-yyyy"
                            id="quotationDate"
                            value={HeaderData.quotationDate || null}
                            fullWidth
                            onChange={(e) =>
                              handleSelect_Date(e, "quotationDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                                style={{
                                  backgroundColor: "#ccd6db",
                                }}
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  style={{
                                    backgroundColor: "#ccd6db",
                                  }}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.brokerId ? 1 : 0}
                        arrayOfData={brokerData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handlemenuOpen}
                        selected={HeaderData.brokerId}
                        label="Broker"
                        name="brokerId"
                        isDisabled={HeaderData.salesOrderId ==="" ? true :  HeaderData.disableBroker ===true ? true:false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.customerId ? 1 : 0}
                        arrayOfData={CustomerData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handlemenuOpen}
                        selected={HeaderData.customerId}
                        label="Customer/Consignee"
                        name="customerId"
                        required
                        isDisabled={HeaderData.salesOrderId ==="" ? true :  HeaderData.disabledCustomer ===true ? true:false}
                      />
                    </div>
                  </Box>
                 
                </Box>
              </Grid>
            </Grid>
          </div>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.billingTo ? 1 : 0}
                        arrayOfData={billingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handlemenuOpen}
                        selected={HeaderData.billingTo}
                        label="Billing To"
                        name="billingTo"
                        required
                        isDisabled={isEmpty(HeaderData.customerId) && billingAddressData.length <= 1}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.shippingTo ? 1 : 0}
                        arrayOfData={shippingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handlemenuOpen}
                        selected={HeaderData.shippingTo}
                        label="Shipping To"
                        name="shippingTo"
                        required
                        isDisabled={isEmpty(HeaderData.customerId) && shippingAddressData.length <= 1}
                      />
                    </div>
                    {!isEmpty(HeaderData.customerId) && shippingAddressData.length > 1 && (
                    <span
                      onClick={openPopup}
                      style={{
                        color: "red",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 100,
                        fontSize: "15px",
                        marginTop: "5px",
                        cursor: "pointer",
                        padding: "5px",
                      }}>
                        Add a new Customer Branch Address
                    </span>
                    )}
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Billing State"
                      value={HeaderData.billingState}
                      onChange={onhandlechange("billingState")}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Shipping State"
                      value={HeaderData.shippingState}
                      onChange={onhandlechange("shippingState")}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      required={HeaderData.brokerId ? true : false}
                      label="Broker %"
                      value={HeaderData.brokerCommisionPercent}
                      onChange={onhandlechange("brokerCommisionPercent")}
                      disabled={isEmptyWithZero(HeaderData.brokerId) ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.taxType ? 1 : 0}
                        arrayOfData={taxTypeData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handlemenuOpen}
                        selected={HeaderData.taxType}
                        label="Tax type"
                        name="taxType"
                        required
                        isDisabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.bankId ? 1 : 0}
                        arrayOfData={bankListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handlemenuOpen}
                        selected={HeaderData.bankId}
                        name="bankId"
                        label="Bank Name"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                        <DynamicSelectNew
                          update={HeaderData.deliveryTerms ? 1 : 0}
                          arrayOfData={DeliveryTermsData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={handlePropSelectChange}
                          isDropdownOpen={isDropdownOpen}
                          handleMenuOpen={handlemenuOpen}
                          selected={HeaderData.deliveryTerms}
                          name="deliveryTerms"
                          label="Delivery Terms"
                          required={true} 
                          isDisabled={true}
                        />
	 	                   </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelect
                      update={HeaderData.transporter ? 1 : 0}
                      arrayOfData={transporterData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handlemenuOpen}
                      selected={HeaderData.transporter}
                      label="Transporter Name"
                      name="transporter"
                      required
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Vehicle No"
                      value={HeaderData.vehicleNo}
                      onChange={onhandlechange("vehicleNo")}
                      caps='ON'
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <FormPopup isOpen={isPopupOpen} onClose={closePopup} customerData={billingShippingList} customerId={RegularDeliveryOrderHeader.customerId}/>
      </div>
    </>
  );
};
const DeliveryTermsData = [
  {
    value: 1,
    label: "Ex-Works",
    name: "Ex-Works",
  },
  {
    value: 2,
    label: "Delivery to Destination",
    name: "Delivery to Destination",
  },
];
const taxTypeData = [
  {
    value: 1,
    name: "IGST",
    label: "IGST",
  },
  {
    value: 2,
    name: "CGST & SGST",
    label: "CGST & SGST",
  },
];
// const bankSelect = {
//   value: "",
//   name: "Select",
//   label: "Select",
// }
export default RegularFillDetails;
