import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import DynamicSelect from "../../../../components/Dropdown/DynamicSelect";
import { Grid, Box, IconButton,Button } from "@mui/material";
import { allItemGroupMasterList } from "../../../../store/Global/DropDownApis/actions";
import { serverApi } from "../../../../helpers/Consts";
import {getJuteGovtInvoiceLineItems,getJuteGovtInvoiceHeader,getJuteGovtInvoiceItemsByItemGroupId,
  UploadInvoiceFiles,
  deleteInvoiceFile,} from "../../../../store/Sales/InVoice/actions";
import swal from "sweetalert";
import { serverConfig } from "../../../../helpers/Consts";
import TickIcon from "../../../../assets/images/fileUploadedTick.png";
import { getFileDownload } from "../../../../helpers/server.js";
import DeleteIcons from "../../../../assets/images/picEditIcon.png";
import panUploadIcon from "../../../../assets/images/panUploadIcon.png";
import { SalesGstCalculations } from "../../../../Utilities/SalesGstCalculation";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { limitDecimals,convertPrimaryToSecondary,convertRatePcsToMt, isEmpty, roundAndRemainingValues} from "../../../../Utilities/helper";
import ReactTooltip from "react-tooltip";

const JuteGovtLineItems = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [LineItems, setLineItems] = useState([]);
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [LineItemsDisplayTxtField, setLineItemsDisplayTxtField] = useState({});
  const [LineItemsDisplayDropField, setLineItemsDisplayDropField] = useState({});
  const [ItemGroupData, setItemGroupData] = useState([]);
  const [ItemNameData, setItemNameData] = useState([]);
  const [userDit, setuserDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [HeaderData, setHeaderData] = useState({});
  const { allItemGroupMaster_List } = useSelector((state) => state.DropDownListReducer);
  const { JuteGovtInvoiceLineItems, juteGovtInvoiceHeader,InvoiceFiles } =useSelector((state) => state.InvoiceReducer);
  const { quotationItemBy_ItemGroupId } = useSelector((state) => state.QuotationReducer);
  const [invoiceFiles, SetInvoiceFiles] = useState(
    InvoiceFiles?.data?.data || []
  );
  useEffect(() => {
    SetInvoiceFiles(InvoiceFiles?.data?.data);
  }, [InvoiceFiles]);
  
  const onDeleteFile = (fileId) => {
    let data = invoiceFiles.filter(
      (item) => item.fileUploadId !== fileId
    );
    SetInvoiceFiles(data);
    dispatch({ type: "UPDATE_INVOICE_FILES", payload: data });
    dispatch(deleteInvoiceFile(serverApi.DELETEFILE + fileId));
  };
  const handleUpload = (e) => {
    e.preventDefault();
    let sourceMenu = 47;
  
    let file = e.target.files[0];
    let fileExt = file.name.split('.').pop();
    const formData = new FormData();
    formData.append('fileName', file.name);
    formData.append('fileUpload', file);
    formData.append('fileExtension', fileExt);
    formData.append('sourceMenu', sourceMenu);
    formData.append('displayName', file.name);
    formData.append('taskUniqueId', HeaderData.regInvoiceId);
    formData.append('createdBy',JSON.parse(localStorage.getItem("authUser")).userId);
    formData.append('fileCompanyId', localStorage.getItem('companyId'));
    dispatch(
      UploadInvoiceFiles(
        serverApi.UPLOADFILE,
        formData,
        history,
        sourceMenu,
        HeaderData.regInvoiceId
      )
    );
    
  };
  useEffect(() => {
    setLineItems(JuteGovtInvoiceLineItems);
    setHeaderData(juteGovtInvoiceHeader);
    if (allItemGroupMaster_List) {
      if (allItemGroupMaster_List.data) {
        let ListFilter = allItemGroupMaster_List.data.filter((props) => {
          if (props.value !== "0") {
            return props;
          }
        });
        let list = [];
        ListFilter.map((prop) => {
          list.push({
            label: prop.label,
            name: prop.name,
            value: prop.value,
            id: prop.id,
          });
        });
        setItemGroupData(list);
      }
    }
    if (quotationItemBy_ItemGroupId) {
      let ListFilter = quotationItemBy_ItemGroupId.filter((data) => {
        if (data.value !== "0") {
          return props;
        }
      });
      let list = [];
      ListFilter.map((prop) => {
        list.push({
          label: prop.label,
          name: prop.name,
          value: prop.value,
          id: prop.itemId,
        });
      });
      setItemNameData(list);
    }
  }, [
    JuteGovtInvoiceLineItems,
    allItemGroupMaster_List,
    quotationItemBy_ItemGroupId,
    juteGovtInvoiceHeader,
  ]);
  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };
  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };
  const onDel = (row) => {
    var FilterLineItems = LineItems.filter((item) => item.isActive !== 0);
    if (FilterLineItems.length == 1) {
      swal("you can't delete the last lineitem");
    } else {
      let indexItem = LineItems.findIndex((item) => item === row);
      let lineItems = [...LineItems];
      lineItems[indexItem].isActive = 0;
      lineItems[indexItem].govtInvoiceIsActive = 0;
      setLineItems(lineItems);
      dispatch(getJuteGovtInvoiceLineItems(lineItems));
      let DiscType = SalesGstCalculations(
        {
          lineItems: lineItems,
          taxType: HeaderData.taxType,
        },
        "INVOICE"
      );
      handleCalculation(DiscType);
    }
  };
  const displayField = (row, colname, type) => {
    if (colname === "itemGroupId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type == 1) {
        dispatch(
          allItemGroupMasterList(
            serverApi.GET_ALL_ITEMGROUP_MASTER_LIST +
              localStorage.getItem("companyId") +
              "/" +
              userDit.cipher,
            history
          )
        );
      }
    } else if (colname === "itemId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      const data = {
        companyId: localStorage.getItem("companyId"),
        consumable: "",
        itemGroupId: row.itemGroupValue,
        saleable: "Y",
        branchId:HeaderData.branchId,
      };
      dispatch(
        getJuteGovtInvoiceItemsByItemGroupId(
          serverApi.GET_ITEMSBY_FORPROCUREMENT,
          data,
          history,
          row.id
        )
      );

    } else if(colname =='discountType'){
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
    }
  };
  const OnhandleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    let mname = key.split("_");
    if (mname[0] === "remarks") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.remarks = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteGovtInvoiceLineItems(LineItemsRec));
    }
    if (mname[0] === "itemMake") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.itemMake = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteGovtInvoiceLineItems(LineItemsRec));
    }
    if (mname[0] === "hsncode") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.hsncode = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteGovtInvoiceLineItems(LineItemsRec));
    }
    if (mname[0] === "rate") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            rowItem.rate = value;
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }

          if (rowItem.govtInvoiceQuantityUom !== "") {
            const balesResult = convertPrimaryToSecondary(
              rowItem.quantity,
              rowItem.govtInvoiceRelationValue
            );
            if (balesResult !== null) {
              rowItem.govtInvoiceQtyCalculatedValue = balesResult;
            }
          }
          if (rowItem.govtInvoiceQuantityUom2 !== "") {
            const mtResult = convertPrimaryToSecondary(
              rowItem.quantity,
              rowItem.govtInvoiceRelationValue2
            );
            if (mtResult !== null && rowItem.govtInvoiceQuantityUom2 !== "") {
              rowItem.govtInvoiceQtyCalculatedValue2 = mtResult;
            } 
          }
          if (rowItem.govtInvoiceQuantityUom3 !== "") {
            const kgsResult = convertPrimaryToSecondary(
              rowItem.quantity,
              rowItem.govtInvoiceRelationValue3
            );
            if (kgsResult !== null && rowItem.govtInvoiceQuantityUom3 !== "") {
              rowItem.govtInvoiceQtyCalculatedValue3 = kgsResult;
            } 
          }
          if (rowItem.govtInvoiceQuantityUom !== "") {
            // rate UOM conversion
            const mtRateResult = convertRatePcsToMt(
              value,
              rowItem.govtInvoiceRelationValue2
            );
            if (mtRateResult !== null && rowItem.govtInvoiceQuantityUom !== "") {
              rowItem.govtInvoiceRateCalculatedValue2 = mtRateResult;
            }
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteGovtInvoiceLineItems(LineItemsRec));
      let Rate = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
        },
        "INVOICE"
      );
      handleCalculation(Rate);
    }

    if (mname[0] === "discountedRate") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.discountedRate = limitDecimals(value, 3)
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteGovtInvoiceLineItems(LineItemsRec));
    }
    if (mname[0] === "quantity") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        // if (row.id === rowItem.id) {
          if(Number(value) > Number(rowItem.invoiceQty)){
            swal("You Cann't enter the Quantity more than a Balance Quantity");
            rowItem.quantity = "0.000";
          }else{
            if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
              rowItem.quantity = value;
              if (rowItem.govtInvoiceQuantityUom !== "") {
                const balesResult = convertPrimaryToSecondary(
                  value,
                  rowItem.govtInvoiceRelationValue
                );
                if (balesResult !== null) {
                  rowItem.govtInvoiceQtyCalculatedValue = balesResult;
                } 
              }
              if (rowItem.govtInvoiceQuantityUom2 !== "") {
                const mtResult = convertPrimaryToSecondary(
                  value,
                  rowItem.govtInvoiceRelationValue2
                );
                if (mtResult !== null && rowItem.govtInvoiceQuantityUom2 !== "") {
                  rowItem.govtInvoiceQtyCalculatedValue2 = mtResult;
                } 
              }
              if (rowItem.govtInvoiceQuantityUom3 !== "") {
                const kgsResult = convertPrimaryToSecondary(
                  value,
                  rowItem.govtInvoiceRelationValue3
                );
                if (kgsResult !== null && rowItem.govtInvoiceQuantityUom3 !== "") {
                  rowItem.govtInvoiceQtyCalculatedValue3 = kgsResult;
                } 
              }
            } else {
              swal("Please enter a positive number with up to 3 decimal places.");
            }
          }
        // }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteGovtInvoiceLineItems(LineItemsRec));
      let Qty = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
        },
        "INVOICE"
      );
      handleCalculation(Qty);
    }
    if (mname[0] === "uom") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.uom = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteGovtInvoiceLineItems(LineItemsRec));
    }
    if (mname[0] === "taxPercent") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.taxPercent = limitDecimals(value, 2);
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteGovtInvoiceLineItems(LineItemsRec));
      let TaxPer = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
        },
        "INVOICE"
      );
      handleCalculation(TaxPer);
    }
    if (mname[0] === "netAmount") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.netAmount = limitDecimals(value);
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(getJuteGovtInvoiceLineItems(LineItemsRec));
    }
  };
  const handleChange = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    selectedItemCode
  ) => {
    if (name === "itemGroupId") {
      let LineItemdata = LineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          row.itemGroupId = selectedItemCode;
          row.itemGroupName = selectedName;
          row.itemGroupValue = selectedValue;
        }
        return row;
      });
      setLineItems(LineItemdata);
      dispatch(getJuteGovtInvoiceLineItems(LineItemdata));
      displayField(selectedRow, "itemGroupId", 2);
      const data = {
        companyId: localStorage.getItem("companyId"),
        consumable: "",
        itemGroupId: selectedValue,
        saleable: "Y",
        branchId:HeaderData.branchId,
      };
      dispatch(
        getJuteGovtInvoiceItemsByItemGroupId(
          serverApi.GET_ITEMSBY_FORPROCUREMENT,
          data,
          history
        )
      );
    }
    if (name === "itemId") {
        let LineItemdata = LineItems.filter((row) => {
          if (row.id === selectedRow.id) {
            row.itemId = selectedValue;
            let splitedSelectedName = selectedName.split("^");
            row.itemName = splitedSelectedName[0];
            row.itemValue = selectedValue;
            row.itemCode = row.itemGroupValue + selectedItemCode;
            row.hsncode = splitedSelectedName[4];
            row.rate = limitDecimals(splitedSelectedName[3],3);
            row.discountedRate = limitDecimals(splitedSelectedName[3],3);
            row.uom = splitedSelectedName[1];
          }
          return row;
        });
        setLineItems(LineItemdata);
        dispatch(getJuteGovtInvoiceLineItems(LineItemdata));
        displayField(selectedRow, "itemId", 2); 
    }
    if (name === "discountType") {
      let LineItemdata = LineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          row.discountName = selectedName;
          row.discountType = selectedValue;
        }
        return row;
      });
      setLineItems(LineItemdata);
      dispatch(getJuteGovtInvoiceLineItems(LineItemdata));
      displayField(selectedRow, "discountType", 2);
    }
    
    let DiscType = SalesGstCalculations(
      {
        lineItems: LineItems,
        taxType: HeaderData.taxType,
      },
      "INVOICE"
    );
    handleCalculation(DiscType);
  };
  const handleCalculation = (Calculations) => {
    setLineItems(Calculations.lineItems);
    dispatch(getJuteGovtInvoiceLineItems(Calculations.lineItems));

    var Headerdata = {
      ...HeaderData,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
      roundingPositiveValue: Calculations.roundingPositiveValue,
      roundingNegetiveValue:Calculations.roundingNegetiveValue,
      roundingPositiveFlag: null,
      roundingNegetiveFlag: null,
    };
    setHeaderData((prev) => ({
      ...prev,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
      roundingPositiveValue: Calculations.roundingPositiveValue,
      roundingNegetiveValue:Calculations.roundingNegetiveValue,
      roundingPositiveFlag: null,
      roundingNegetiveFlag: null,
    }));
    dispatch(getJuteGovtInvoiceHeader(Headerdata));
  };
//Other Charges
const addOtherCharges = () => {
  let filterProduct = LineItems.filter((item) => item.isActive != 0);
  let newline = 1;
  let filteredproducts = filterProduct.map((item) => {
    newline = Number(item.id);
    return item;
  });
  let obj={
    id: Number(newline) + 1,
    itemGroupId: 1252,
    itemGroupName: "Other Charges",
    itemGroupValue: "997",
    itemId: "",
    itemName: "",
    itemCode: "",
    itemValue: "",
    remarks: "",
    itemMake: "",
    hsncode: "",
    rate: "0.000",
    discountedRate: "",
    quantity: "0.000",
    uom: "",
    taxPercent: "",
    createdBy: "",
    createdDate: "",
    companyId: "",
    salesId: HeaderData.salesOrderId,
    salesLineItemId: "",
    invoiceQty: "",
    igstAmount: "",
    igstPercent: "",
    cgstAmount: "",
    cgstPercent: "",
    sgstAmount: "",
    sgstPercent: "",
    cessAmount: "",
    discountType: "",
    discountName: "",
    discount: "",
    discountedRate: "",
    netAmount: "0.00",
    totalAmount: "0.00",
    balesPacketSlNo: "",
    status: 1,
    isActive: 1,
    govtInvoiceIsActive:1,
    currency: "INR",
    doBalQty:null,
    deliveryOrderLineItemId: "",
    regDeliveryOrderId: "",
    updatedBy: null,
    updatedOn: null,
    ItemNameData:[]
  }
  if (filteredproducts.length == 1) {
    filteredproducts.push(obj);
    setLineItems(filteredproducts);
    dispatch(getJuteGovtInvoiceLineItems(filteredproducts));
  } else {
    filteredproducts.splice(filterProduct.length, 0, obj);
    setLineItems(filteredproducts);
    dispatch(getJuteGovtInvoiceLineItems(filteredproducts));
  }
};
const handleEventPositioned = (info, id) => {
  var filteredLineitems = LineItems.filter((item) => item.isActive !== 0);

  filteredLineitems.map((item) => {
    if (item.id == id) {
      if (info.target) {
        info.target.setAttribute(
          "data-tip",
          `
        <p>Balance Quantity = ${
          item.invoiceQty === undefined || item.invoiceQty === null
            ? 0
            : item.invoiceQty
        }</p>
        `
        );
        ReactTooltip.rebuild();
      }
    }
  });
};
const toggleState = (roundoff) => {
  var RoundOffAndDownVal = roundAndRemainingValues(JuteGovtInvoiceLineItems, roundoff);
  if (roundoff == 1) {
    const UpdatedRec = {
      ...juteGovtInvoiceHeader,
      roundOffValue:RoundOffAndDownVal.positiveRF,
      roundingFlag: true,
      roundingPositiveFlag:true,
      roundingNegetiveFlag:false,
      roundingPositiveValue: RoundOffAndDownVal.positiveRF,
      roundingNegetiveValue: RoundOffAndDownVal.NegativeRF,
      TotalAmount:RoundOffAndDownVal.RoundoffValue
    };
    dispatch(getJuteGovtInvoiceHeader(UpdatedRec));
  } else {
    const UpdatedRec = {
      ...juteGovtInvoiceHeader,
      roundOffValue: RoundOffAndDownVal.NegativeRF,
      roundingFlag: false,
      roundingPositiveFlag:false,
      roundingNegetiveFlag:true,
      roundingPositiveValue: RoundOffAndDownVal.positiveRF,
      roundingNegetiveValue: RoundOffAndDownVal.NegativeRF,
      TotalAmount:RoundOffAndDownVal.RoundoffValue
    };
    dispatch(getJuteGovtInvoiceHeader(UpdatedRec));
  }
};
  let filterProduct = LineItems.filter((item) => item.isActive != 0);
  let FirstLineItem = LineItems[LineItems.length,0];
  return (
    <>
      <div className="editableTableContainer">
    
        <div className="gridContainer">
          <div className="gridComponent">
            <table cellSpacing="0px" cellPadding="0px">
            
              <thead>
                <tr className="tableRowBlock">
                  {tableHeaders.map((column) => (
                    <>
                      {column.header == "Item Group" ||
                      column.header == "Item Name" ||
                      column.header == "Rate" ||
                      column.header == "Quantity" ||
                      column.header == "UOM" ? (
                        <>
                          <th>
                            {column.header}
                            <label style={{ color: "red" }}>*</label>
                          </th>
                        </>
                      ) : (
                        <>
                          <th>{column.header}</th>
                        </>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filterProduct.length !== 0 &&
                  filterProduct.map((row, i) => {
                    let rowIndex = filterProduct.findIndex((idd) => idd == row);

                    return (
                      <>
                        <tr>
                          <>
                            {RowData &&
                              RowData.map((col) =>
                                col.component === "Editablecell" ? (
                                  <>
                                    <td>
                                      <div className="quantityDropdownBlock">
                                        <Grid className="selectAndTextfield">
                                          <Grid
                                            xs={12}
                                            md={12}
                                            className="dynamicSelectBlock"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                         
                                            <input
                                                onMouseOver={(e) =>
                                                  col.type === "quantity" &&
                                                 handleEventPositioned(
                                                        e,
                                                        row.id
                                                      )
                                                }
                                              onWheel={(e) => e.target.blur()}
                                              type={
                                                col.type == "rate"
                                                  ? "number"
                                                  // : col.type ===
                                                  //   "discountedRate"
                                                  // ? "number"
                                                  : col.type === "quantity"
                                                  ? "number"
                                                  : col.type === "taxPercent"
                                                  ? "number"
                                                  : col.type == "netAmount"
                                                  ? "number"
                                                  : "text"
                                              }
                                              name={col.type + "_" + rowIndex}
                                              disabled={  
                                                col.type === "quantity" ? false 
                                                // : col.type === "discountedRate"? true
                                                : col.type === "rate" && isEmpty(HeaderData.ammendmentNo)? true
                                                : col.type === "uom"? true
                                                : col.type === "taxPercent"? true
                                                : col.type == "netAmount"? true
                                                : col.type=="hsncode" ? true 
                                                : col.type=="discount"? true 
                                                : col.type === "remarks" ? true
                                                : col.type === "itemMake"? true
                                                :false
                                              }
                                              value={
                                                col.type === "remarks"
                                                  ? row.remarks
                                                  : col.type === "itemMake"
                                                  ? row.itemMake
                                                  : col.type === "hsncode"
                                                  ? row.hsncode
                                                  : col.type == "rate"
                                                  ? row.rate
                                                  // : col.type ==="discountedRate"
                                                  // ? row.discountedRate
                                                  : col.type === "quantity"
                                                  ? row.quantity
                                                  : col.type === "uom"
                                                  ? row.uom
                                                  : col.type === "taxPercent"
                                                  ? row.taxPercent
                                                  : col.type == "netAmount"
                                                  ? parseFloat(row.netAmount).toFixed(2)
                                                  // : col.type === "discount"
                                                  // ? row.discount 
                                                  : ""
                                              }
                                              onChange={OnhandleChange({
                                                key: col.type + "_" + rowIndex,
                                                row,
                                              })}
                                              className="inputBlockContainer"
                                              style={{
                                                backgroundColor:
                                                  col.type === "quantity"?""
                                                // : col.type === "discountedRate"? "#ccd6db"
                                                : col.type === "rate" && isEmpty(HeaderData.ammendmentNo) ?"#ccd6db"
                                                : col.type === "uom"? "#ccd6db"
                                                : col.type === "taxPercent"? "#ccd6db"
                                                : col.type == "netAmount"? "#ccd6db"
                                                : col.type=="hsncode" ? "#ccd6db"
                                                : col.type=="discount"?"#ccd6db" 
                                                : col.type === "remarks" ? "#ccd6db"
                                                : col.type === "itemMake"? "#ccd6db":
                                                "",

                                                textAlign:
                                                  col.type !== "remarks" &&
                                                  col.type !== "itemMake" &&
                                                  col.type !== "hsncode" &&
                                                  col.type !== "uom"
                                                  // &&
                                                  // col.type !=="discountType"
                                                    ? "right"
                                                    : "",
                                                paddingRight: "8px",
                                              }}
                                            />
                                                <ReactTooltip
                                              effect={"solid"}
                                              html={true}
                                              place={"BottomCenter"}
                                              // backgroundColor="#ebfaff"
                                              className="tooltipClassBorder"
                                              backgroundColor="rgba(0,127,173,0.5)"
                                              textColor="#FFF"
                                              border={true}
                                              borderColor="#04749c"
                                            />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </td>
                                  </>
                                ) : col.component === "DynamicSelect" ? (
                                  <td>
                                    <div className="quantityDropdownBlock">
                                      <Grid className="selectAndTextfield">
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: LineItemsDisplayTxtField[
                                              col.type + row.id + "1"
                                            ]
                                              ? "none"
                                              : "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <input
                                            type="text"
                                            className="inputBlockContainer"
                                            style={{
                                              paddingRight: "8px",
                                              backgroundColor:"#ccd6db"
                                                  // col.type === "itemGroupId"
                                                  // ? "#ccd6db"
                                                  // : col.type === "itemId"? row.itemGroupValue === "997" ? "":"#ccd6db"
                                                  // : col.type == "discountType" ?"#ccd6db":"",
                                            }}
                                            value={
                                              col.type === "itemGroupId"
                                                ? row.itemGroupName
                                                : col.type === "itemId"
                                                ? row.itemName
                                                // : col.type ==="discountType" 
                                                // ?row.discountType 
                                                :""
                                            }
                                            onChange={OnhandleChange({
                                              key: col.type + "_" + rowIndex,
                                              row,
                                            })}
                                            onClick={() => {
                                              displayField(row, col.type, 1);
                                            }}
                                            disabled={true
                                              // col.type === "itemGroupId"? true
                                              // : col.type === "itemId" ?  row.itemGroupValue === "997" ? false : true
                                              // : col.type ==="discountType" ?true:false
                                            }
                                          />
                                        </Grid>
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: LineItemsDisplayDropField[
                                              col.type + row.id + "2"
                                            ]
                                              ? "flex"
                                              : "none",
                                            alignItems: "center",
                                          }}
                                        >
                                          <DynamicSelect
                                            arrayOfData={
                                              col.type == "itemGroupId"
                                                ? ItemGroupData
                                                : col.type == "itemId"
                                                ? row.ItemNameData
                                                // : col.type ==="discountType" 
                                                // ?discount_Type 
                                                :""
                                            }
                                            className="dropdownBlockContainer"
                                            onSelectChange={handleChange}
                                            selected={
                                              col.type === "itemGroupId"
                                                ? row.itemGroupId
                                                : col.type === "itemId"
                                                ? row.itemId
                                                // : col.type ==="discountType" 
                                                // ?row.discountType 
                                                :""
                                            }
                                            name={
                                              col.type === "itemGroupId"
                                                ? "itemGroupId"
                                                : col.type === "itemId"
                                                ? "itemId"
                                                // : col.type ==="discountType" 
                                                // ?row.discountType 
                                                :""
                                            }
                                            row={row}
                                            update={
                                              col.type === "itemGroupId"
                                                ? row.itemGroupId
                                                : col.type === "itemId"
                                                ? row.itemId
                                                // : col.type ==="discountType" 
                                                // ?row.discountType 
                                                :""
                                            }
                                            isDropdownOpen={isDropdownOpen}
                                            handleMenuOpen={handleMenuOpen}
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </td>
                                ) : (
                                  ""
                                )
                              )}
                          </>
                          
                          <td>
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Delete"
                            >
                              <IconButton
                                onClick={() => {
                                  onDel(row, i);
                                }}
                                disabled={
                                  filterProduct.length === 1
                                    ? true
                                    : FirstLineItem === row 
                                    ? true
                                    : false
                                }
                              >
                                <DeleteIcon className="deleteDisplayButton" />
                              </IconButton>
                            </Tooltip>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {/* <div style={{ marginTop: "5px" }}>
            <span
              className="OtherCharges"
              onClick={() => {
                addOtherCharges();
              }}
            >
              Click Here To Add Printing Charges/Labour Charges/Transport
              Charges/Delivery Charges/Packing & Forwarding Charges/Other
              Charges
            </span>
          </div> */}
          <div className="consoleFormContainer">
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 12">
                      <div
                        className="calculationBlockTable"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <table>
                        <tr>
                            <td>Taxable Value:</td>
                            <b>{parseFloat(HeaderData.netTotal).toFixed(2)}</b>
                          </tr>
                          <tr>
                            <td>Total IGST:</td>
                            <b>
                              {" "}
                              {parseFloat(HeaderData.totalIGST).toFixed(2)}
                            </b>
                          </tr>
                          <tr>
                            <td>Total SGST:</td>
                            <td>
                              {parseFloat(HeaderData.totalSGST).toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td>Total CGST:</td>
                            <td>
                              {parseFloat(HeaderData.totalCGST).toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td>Round Off</td>

                            <input
                              type="radio"
                              id="switch_left"
                              name="switchToggle"
                              value={HeaderData.roundingPositiveFlag}
                              onChange={() => {
                                toggleState(1);
                              }}
                              checked={HeaderData.roundingPositiveFlag}
                            />
                            <span>{HeaderData.roundingPositiveValue}</span>
                            <input
                              type="radio"
                              id="switch_right"
                              name="switchToggle"
                              value={HeaderData.roundingNegetiveFlag}
                              onChange={() => {
                                toggleState(2);
                              }}
                              checked={HeaderData.roundingNegetiveFlag}
                            />
                            <span>{HeaderData.roundingNegetiveValue}</span>
                          </tr>
                          <tr>
                       
                            <td>Total Value With Tax:</td>
                            <b>
                              {parseFloat(HeaderData.TotalAmount).toFixed(2)}
                            </b>
                          </tr>
                        </table>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
          {props.locationState.state !== undefined ? (
        <div className="consoleFormContainer">
          <div style={{ height: '15px' }}></div>
          <div className="consoleFormBlock" style={{ display: 'flex' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2} className="ducumentUploadBlock uploadedContent">
                  <Box gridColumn="span 10">
                    {invoiceFiles?.length > 0 &&
                      invoiceFiles.map((item) => (
                        <div key={item.fileUploadId} className="uploadedInfo" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', padding: '5px', border: '1px solid #ccc', borderRadius: '5px' }}>
                          <img src={TickIcon} alt="" className="tickIcon" height={'20px'} width={'20px'} />
                          <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} title="Click to download File">
                            <div
                              className="uplodedFileName"
                              onClick={() => {
                                getFileDownload(
                                  serverConfig.SERVER_URL +
                                  'security-api/api/files/downloadfile/' +
                                  item.fileUploadId,
                                  item.fileName,
                                  item.fileExtension
                                );
                              }}
                              style={{ cursor: 'pointer', marginLeft: '10px', marginRight: '10px' }}
                            >
                              {item.fileName}
                            </div>
                          </Tooltip>
                          <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 100 }} title="Click to Delete File">
                            <Button
                              onClick={() => {
                                onDeleteFile(item.fileUploadId);
                              }}
                              className="deleteButton"
                              style={{ marginLeft: '10px' }}
                            >
                              <img src={DeleteIcons} alt="" height={'20px'} width={'20px'} />
                            </Button>
                          </Tooltip>
                        </div>
                      ))}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                  <Box gridColumn="span 4">
                    <div style={{ marginBottom: '10px' }}>
                      <b>Support Documents:</b><span style={{ color: 'red' }}></span>
                    </div>
                    <div className="personalDetailsUpload">
                      <div className="ducumentUploadBlock">
                        <div className="documentUploadContent">
                          <span className="btn_upload documentUpload" style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={panUploadIcon} alt="" style={{ marginRight: '10px' }} />
                            <input
                              type="file"
                              id="imag"
                              title=""
                              className="input-img"
                              onChange={handleUpload}
                            />
                            Upload Document
                          </span>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        ""
      )}
        </div>
      </div>
    </>
  );
};
const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "itemGroupId",
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "itemId",
  },
  {
    no: 3,
    component: "Editablecell",
    type: "remarks",
  },
  {
    no: 4,
    component: "Editablecell",
    type: "itemMake",
  },
  {
    no: 5,
    component: "Editablecell",
    type: "hsncode",
  },
  {
    no: 6,
    component: "Editablecell",
    type: "quantity",
  },
  {
    no: 7,
    component: "Editablecell",
    type: "uom",
  },
  {
    no: 8,
    component: "Editablecell",
    type: "rate",
  },
  // {
  //   no: 9,
  //   component: "DynamicSelect",
  //   type: "discountType",
  // },
  // {
  //   no: 10,
  //   component: "Editablecell",
  //   type: "discount",
  // },
  // {
  //   no: 11,
  //   component: "Editablecell",
  //   type: "discountedRate",
  // },
  {
    no: 12,
    component: "Editablecell",
    type: "taxPercent",
  },
  {
    no: 13,
    component: "Editablecell",
    type: "netAmount",
  },
];
const tableHeaders = [
  {
    header: "Item Group",
  },
  {
    header: "Item Name",
  },
  {
    header: "Remarks",
  },
  {
    header: "Make",
  },
  {
    header: "HSN",
  },
  {
    header: "Quantity",
  },
  {
    header: "UOM",
  },
  {
    header: "Rate",
  },
  // {
  //   header: "Discount Mode",
  // },
  // {
  //   header: "Discount",
  // },
  // {
  //   header: "Rate After Discount",
  // },
  {
    header: "Tax%",
  },
  {
    header: "Amount",
  },
  {
    header: "Actions",
  },
];
const discount_Type = [
  {
    value: 1,
    label: "Amount",
    name: "Amount",
  },
  {
    value: 2,
    label: "Percentage",
    name: "Percentage",
  },
];

export default JuteGovtLineItems;
