
import swal from 'sweetalert'
import {ATTENDANCE_LIST,ATTENDANCE_LIST_SUCCESSFULL,
    SPELL,SPELL_SUCCESSFULL,
    MACHINE_NUMBER,MACHINE_NUMBER_SUCCESSFULL, MARK_ATTENDANCE, MARK_ATTENDANCE_SUCCESSFULL, LOGS, LOGS_SUCCESSFULL, UPDATE_ATTENDANCE, UPDATE_ATTENDANCE_SUCCESSFULL, ATTENDANCE_SUMMARY, ATTENDANCE_SUMMARY_SUCCESSFULL, PROCESS_LOGS, PROCESS_LOGS_SUCCESSFULL
,WORKER_DATA_CLEAR,WORKER_DATA_BY_EB_NUMBER,
WORKER_DATA_BY_EB_NUMBER_SUCCESSFULL,
ATTENDANCE_VIEW,
ATTENDANCE_VIEW_SUCCESSFULL,
ATTENDANCE_VIEW_CLEAR_PROP,
MACHINE_NUMBER_CLEAR,
ATTENDANCE_DESIGNATION_LIST,
ATTENDANCE_DESIGNATION_LIST_SUCCESSFULL} from './actionType'

export const getAttendanceList = (url,data,history) =>{
    return {
        type:ATTENDANCE_LIST,
        payload:{url,data,history}
    }
}

export const AttendanceListSuccessfull = (data)=>{
    return {
        type:ATTENDANCE_LIST_SUCCESSFULL,
        payload:data
    }
}
export const viewAttendance = (url,data,history) =>{
    return {
        type:ATTENDANCE_VIEW,
        payload:{url,data,history}
    }
}

export const viewAttendanceSuccessfull = (data)=>{
    return {
        type:ATTENDANCE_VIEW_SUCCESSFULL,
        payload:data
    }
}
export const viewAttendancePropClear= (data)=>{
    return {
        type:ATTENDANCE_VIEW_CLEAR_PROP,
        payload:data
    }
}
// Spell 
export const getSpell = (url,history) =>{
    return {
        type:SPELL,
        payload:{url,history}
    }
}

export const SpellSuccessfull = (data)=>{
    return {
        type:SPELL_SUCCESSFULL,
        payload:data
    }
}

// Machine Number
export const getMachineNumber = (url,history) =>{
    return {
        type:MACHINE_NUMBER,
        payload:{url,history}
    }
}

export const MachineNumberSuccessfull = (data)=>{
    return {
        type:MACHINE_NUMBER_SUCCESSFULL,
        payload:data
    }
}
export const MachineNumberClear = (data)=>{
    return {
        type:MACHINE_NUMBER_CLEAR,
        payload:data
    }
}
// Mark Attendace 
export const MarkAttendance = (url,data,history) =>{
    return {
        type:MARK_ATTENDANCE,
        payload:{url,data,history}
    }
}

export const MarkAttendanceSuccessfull = (data)=>{
    return {
        type:MARK_ATTENDANCE_SUCCESSFULL,
        payload:data
    }
}
// Logs
export const getLogs = (url,data,history) =>{
    return {
        type:LOGS,
        payload:{url,data,history}
    }
}

export const LogsSuccessfull = (data)=>{
    return {
        type:LOGS_SUCCESSFULL,
        payload:data
    }
}
export const getUpdateAttendance = (url,data,history) =>{
    return {
        type:UPDATE_ATTENDANCE,
        payload:{url,data,history}
    }
}

export const UpdateAttendanceSuccessfull = (data)=>{
    return {
        type:UPDATE_ATTENDANCE_SUCCESSFULL,
        payload:data
    }
}
export const getAttendanceSummaryList = (url,data,history) =>{
    return {
        type:ATTENDANCE_SUMMARY,
        payload:{url,data,history}
    }
}

export const AttendanceSummaryListSuccessfull = (data)=>{
    return {
        type:ATTENDANCE_SUMMARY_SUCCESSFULL,
        payload:data
    }
}
export const getProcessLogs = (url,data,history) =>{
    return {
        type:PROCESS_LOGS,
        payload:{url,data,history}
    }
}

export const ProcessLogsSuccessfull = (data)=>{
    return {
        type:PROCESS_LOGS_SUCCESSFULL,
        payload:data
    }
}

export const getWorkerByEBNumber = (url,history) =>{
    return {
        type:WORKER_DATA_BY_EB_NUMBER,
        payload:{url,history}
    }
}

export const getWorkerByEBNumberSuccessfull = (data)=>{
    return {
        type:WORKER_DATA_BY_EB_NUMBER_SUCCESSFULL,
        payload:data
    }
}

export const getWorkerDataClearForAttendance =(data)=>{
    return {
        type:WORKER_DATA_CLEAR,
        payload:data
    }
}
export const getAttendanceDesignation = (url,history)=>{
    return {
        type:ATTENDANCE_DESIGNATION_LIST,
        payload:{url,history}

    }
}

export const getAttendanceDesignationSuccessfull = (data) =>{
    return {
        type:ATTENDANCE_DESIGNATION_LIST_SUCCESSFULL,
        payload:data
    }
}