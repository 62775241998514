import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/Jute/DynamicSelect";
import { serverApi } from "../../../helpers/Consts";
import InputAdornment from "../../../components/InputAdorment/InputAdorment";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { LineItemsJuteIssuePros } from "../../../store/Jute/JuteIssue/actions";
import {
  getYarnTypeList,
  getItemsList,
} from "../../../store/Global/DropDownApis/actions";
import {
  getMukamByItemList,
  getQualityByItemList,
  getGodownNumberList,
} from "../../../store/MasterDropdownApis/actions";

class JuteIssueAddItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      juteIssueLineItems: this.props.juteIssueLineItems,
      yarnTypeListData: [],
      itemsListData: [],
      itemsByQualityData: [],
      godownListData: [],
      yarnId: "",
      yarnType: "",
      juteType: "",
      juteId: "",
      quantity: "",
      godownNo: "",
    };

    this.state.filterText = "";
    this.state.tooltip = "item description....";
  }
  handleUserInput(filterText) {
    this.setState({ filterText: filterText });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getYarnTypeList(
      serverApi.GET_YARN_TYPE_LIST + this.state.companyId,
      this.props.history
    );
    const data = {
      companyId: localStorage.getItem("companyId"),
      itemGroupId: "999",
    };
    this.props.getItemsList(
      serverApi.GET_ITEMS_BY_ITEMGROUP_ID,
      data,
      this.props.history
    );
  }
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "yarnType") {
      this.setState({
        yarnType: selectedValue,
        yarnId: selectedValue,
      });
    }
    if (name === "juteType") {
      this.setState({
        juteType: selectedValue,
        juteId: selectedValue,
        quantity: 0,
        godownNo: 0,
      });
      this.props.getQualityByItemList(
        serverApi.GET_QUALITY_BY_ITEM +
          selectedValue +
          "/" +
          localStorage.getItem("companyId") +
          "/getQualityByItem",
        this.props.history
      );
    }
    if (name === "quantity") {
      this.setState({
        quantity: selectedValue,
        godownNo: 0,
      });
      const data = {
        juteType: this.state.juteType,
        qualityId: selectedValue,
        unitConversion: "LOOSE",
        cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
        companyId: localStorage.getItem("companyId"),
        branchId: 4,
      };
      this.props.getGodownNumberList(
        serverApi.GET_GODOWN_NUMBER,
        data,
        this.props.history
      );
    }
    if (name === "godown") {
      this.setState({
        godownNo: selectedValue,
      });
    }
  };
  componentWillReceiveProps(props) {
    if (props.yarnTypeList) {
      this.setState({
        yarnTypeListData: props.yarnTypeList.data, // updating department list
      });
    }
    if (props.itemsByItmGrpIdList) {
      this.setState({
        itemsListData: props.itemsByItmGrpIdList,
      });
    }
    if (props.QualityByItemId) {
      this.setState({
        itemsByQualityData: props.QualityByItemId.data,
      });
    }
    if (props.getGodownListData) {
      var arrayOfData = [];

      var obj1 = { label: "Select....", value: 0, name: "Select Company" };
      arrayOfData.push(obj1);
      props.getGodownListData.forEach((prop) => {
        var obj = {
          label: prop.label,
          value: prop.value,
          name: prop.name,
        };
        arrayOfData.push(obj);
      });

      this.setState({
        godownListData: arrayOfData,
      });
    }
    // if (props.juteIssueLineItems) {
    //   this.setState({ juteIssueLineItems: props.juteIssueLineItems });
    // }
  }

  OnhandleChange = (data) => (event) => {
    var nameArr = data.key.split("_");
    let lineItemId = nameArr[1];
    var object = this.state.juteIssueLineItems.filter((row) => {
      if (row.lineItemId == lineItemId) {
        if (nameArr[0] == "unit") {
          row.unitId = event.target.value;
        }
      }
      return row;
    });
    this.setState({
      juteIssueLineItems: object,
      lineItemId: lineItemId,
    });
    this.props.LineItemsJuteIssuePros(object);
  };

  onDelEvent = (event) => {
    this.setState({ delete: true, deletedId: event.target.name });
  };
  onDel = (event) => {
    this.setState({ delete: false });
    if (event.target.value == "Yes") {
      if (this.state.juteIssueLineItems.length > 1) {
        let lineItemId = this.state.deletedId;
        var object = this.state.juteIssueLineItems.filter((row) => {
          if (row.lineItemId != lineItemId) {
            return row;
          }
        });

        this.setState({
          juteIssueLineItems: object,
          lineItemId: lineItemId,
        });
        this.props.LineItemsJuteIssuePros(object);
      }
    }
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent">
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {this.props.tableHeaders.map((column) => (
                    <th>{column.header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <>
                    <td>
                      <div className="quantityDropdownBlock">
                        <Grid className="selectAndTextfield">
                          <Grid
                            xs={12}
                            md={12}
                            className="dynamicSelectBlock"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <DynamicSelect
                              arrayOfData={this.state.yarnTypeListData}
                              className="dropdownBlockContainer"
                              menuPosition="fixed"
                              menuPlacement="auto"
                              onSelectChange={this.handlePropSelectChange}
                              isDropdownOpen={this.state.isDropdownOpen}
                              handleMenuOpen={this.handleMenuOpen}
                              selected={this.state.yarnId}
                              update={this.props.location.state ? 1 : 0}
                              name="yarnType"
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </td>

                    <td>
                      <div className="quantityDropdownBlock">
                        <Grid className="selectAndTextfield">
                          <Grid
                            xs={12}
                            md={12}
                            className="dynamicSelectBlock"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <DynamicSelect
                              arrayOfData={UnitConversion}
                              className="dropdownBlockContainer"
                              menuPosition="fixed"
                              menuPlacement="auto"
                              onSelectChange={this.handlePropSelectChange}
                              isDropdownOpen={this.state.isDropdownOpen}
                              handleMenuOpen={this.handleMenuOpen}
                              selected={this.state.unitConversion}
                              name="unitConversion"
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </td>
                    <td>
                      <div className="quantityDropdownBlock">
                        <Grid className="selectAndTextfield">
                          <Grid
                            xs={12}
                            md={12}
                            className="dynamicSelectBlock"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <DynamicSelect
                              arrayOfData={this.state.itemsListData}
                              className="dropdownBlockContainer"
                              menuPosition="fixed"
                              menuPlacement="auto"
                              onSelectChange={this.handlePropSelectChange}
                              isDropdownOpen={this.state.isDropdownOpen}
                              handleMenuOpen={this.handleMenuOpen}
                              selected={this.state.juteType}
                              name="juteType"
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </td>

                    <td>
                      <div className="quantityDropdownBlock">
                        <Grid className="selectAndTextfield">
                          <Grid
                            xs={12}
                            md={12}
                            className="dynamicSelectBlock"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <DynamicSelect
                              arrayOfData={this.state.itemsByQualityData}
                              className="dropdownBlockContainer"
                              menuPosition="fixed"
                              menuPlacement="auto"
                              onSelectChange={this.handlePropSelectChange}
                              isDropdownOpen={this.state.isDropdownOpen}
                              handleMenuOpen={this.handleMenuOpen}
                              selected={this.state.quantity}
                              name="quantity"
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </td>

                    <td>
                      <div className="quantityDropdownBlock">
                        <Grid className="selectAndTextfield">
                          <Grid
                            xs={12}
                            md={12}
                            className="dynamicSelectBlock"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <DynamicSelect
                              arrayOfData={this.state.godownListData}
                              className="dropdownBlockContainer"
                              menuPosition="fixed"
                              menuPlacement="auto"
                              onSelectChange={this.handlePropSelectChange}
                              isDropdownOpen={this.state.isDropdownOpen}
                              handleMenuOpen={this.handleMenuOpen}
                              selected={this.state.godownNo}
                              name="godown"
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </td>
                    <td>
                      <div className="quantityDropdownBlock">
                        <Grid className="selectAndTextfield">
                          <Grid
                            xs={12}
                            md={12}
                            className="dynamicSelectBlock"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <InputAdornment
                              type="text"
                              isNumber={true}
                              handleChange={this.handleChange}
                              unitConv={this.state.unitConversionId}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </td>
                    {/* ) : (
                                ""
                              )
                            )
                          : ""} */}
                  </>
                  <td className="">
                    <input
                      type="button"
                      onClick={this.onDelEvent}
                      value="X"
                      className="deleteDisplayButton"
                    />
                    {this.state.delete && (
                      <CommonPopup>
                        <div className="delAddItem">
                          <div>Are you sure you want to delete?</div>
                          <div className="delAddItemBtns">
                            <input
                              type="button"
                              onClick={this.onDel}
                              value="Yes"
                              className="delYesBtn"
                            />
                            <input
                              type="button"
                              onClick={this.onDel}
                              value="No"
                              className="delNoBtn"
                            />
                          </div>
                        </div>
                      </CommonPopup>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const UnitConversion = [
  {
    value: "",
    label: "Select...",
    name: "Select...",
  },
  {
    value: "LOOSE",
    label: "LOOSE",
    name: "LOOSE",
  },
  {
    value: "BALE",
    label: "BALE",
    name: "BALE",
  },
  {
    value: "WASTAGE",
    label: "WASTAGE",
    name: "WASTAGE",
  },
];
const mapStatetoProps = (state) => {
  const { QualityByItemId, getGodownListData } =
    state.MasterDropDownListReducer;

  const { yarnTypeList, itemsByItmGrpIdList } = state.DropDownListReducer;
  return {
    yarnTypeList,
    itemsByItmGrpIdList,
    QualityByItemId,
    getGodownListData,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getMukamByItemList,
    getQualityByItemList,
    LineItemsJuteIssuePros,
    getYarnTypeList,
    getItemsList,
    getGodownNumberList,
  })(JuteIssueAddItems)
);
