import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Grid, Box } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/JuteProduction/DynamicSelect";
import Datetime from "react-datetime";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import moment from "moment";
import { getSpell } from "../../../store/HRMS/AttendanceCalender/actions";
import {
  cutsListAction,
  WeavingProductionCreateAction,
} from "../../../store/JuteProduction/WeavingProduction/actions";
import { spinningQualityList } from "../../../store/Master/SpinningQualityMaster/actions";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import CustomButton from "../../../components/Buttons/Buttons";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import swal from "sweetalert";
import { Api } from "@mui/icons-material";
import { validate } from "../../../Utilities/Validations";

class CreateWeavingProduction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProcessMasterContent: [],
      userDit: JSON.parse(localStorage.getItem("authUser")),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      spellContent: [],
      spinningQualityListData: [],
      qualityType: "",
      cutsValue: 0,
      noOfLoomsValue: 0,
      looms: 0,
      cuts: 0,
      weavingDate: moment().format("YYYY-MM-DD"),
      time: moment().format().split("T"),
      qualityCode: "",
      display: 0,
    };
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      qualityType: 1,
    };
    this.props.spinningQualityList(
      serverApi.GET_ALL_WEAVING_QUALITY_MASTER_LIST,
      data,
      this.props.history
    );
    this.props.getSpell(
      serverApi.SPELL +
        localStorage.getItem("companyId") +
        "/" +
        this.state.cipher,
      this.props.history
    );
  }

  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    this.props.headerData[key] = event;
  };
  handlePropSelectChange = (selectedValue, selectedName, name, qtyType) => {
    this.setState({ [name]: selectedValue });
    if (name === "qualityType") {
      var data = {
        qCode: selectedName,
        spell: this.state.spell_name,
        companyId: localStorage.getItem("companyId"),
      };
      this.props.cutsListAction(
        serverApi.GET_CUTS_FROM_CUTS,
        data,
        this.props.history
      );
      this.setState({
        qualityType: selectedValue,
        qualityCode: selectedName,
        qutyType: qtyType,
      });
    }
  };
  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "looms") {
      this.setState({
        looms: event,
      });
    }
    if (key === "cuts") {
      this.setState({
        cuts: event,
      });
    }
  };

  componentWillReceiveProps(props) {
    if (props.SpinningQualityList) {
      let qualityListData = [];
      if (props.SpinningQualityList.data) {
        let list = props.SpinningQualityList.data.filter((prop) => {
          if (prop.qualityId !== 0) {
            return prop;
          }
        });
        list.map((item) => {
          qualityListData.push({
            label: item.qualityName + "_" + item.qualityCode,
            name: item.qualityCode,
            value: item.qualityId,
            variant: item.qualityType,
          });
        });
        this.setState({
          spinningQualityListData: qualityListData, // updating spinning quality master
        });
      }
    }
    if (props.spell) {
      if (props.spell) {
        let list = props.spell.filter((prop) => {
          if (prop.value !== "0") {
            return prop;
          }
        });
        this.setState({
          spellContent: list, // updating state name list dropdown
        });
      }
    }

    if (props.CutsListArray) {
      this.setState({
        cutsValue: this.state.qualityType ? props.CutsListArray.cuts : "",
        noOfLoomsValue: this.state.qualityType
          ? props.CutsListArray.noOfLooms
          : "",
      });
    }
  }

  handleSelect_Date = (e, name) => {
    if (name === "time") {
      let actualtime = moment(e).format();
      let formattedTime = actualtime.split("T", 2);

      this.setState({
        time: formattedTime,
      });
    }

    if (name === "weavingDate") {
      let date = moment(e).format();
      let formattedFromDate = date.split("T", 1).toString();
      this.setState({
        weavingDate: formattedFromDate,
      });
    }
  };

  onClickCreate = () => {
    let newTime = this.state.time[1].split("+");
    var data = {
      weavingDate: moment(this.state.weavingDate).format("DD-MM-YYYY"),
      time: newTime[0],
      noOfLoom: this.state.looms,
      cuts: this.state.cuts,
      createdBy: this.state.userDit.userId,
      isActive: 1,
      qualityCode: this.state.qualityCode,

      qualityType: this.state.qutyType,

      spell: this.state.spell_name,
      companyId: localStorage.getItem("companyId"),
    };

    const validateFields = [
      { field: this.state.spell_name, msg: "Spell Name", type: 2 },
      { field: this.state.qualityType, msg: "Quality", type: 2 },
      { field: this.state.looms, msg: "Number of Looms", type: 1 },
      { field: this.state.cuts, msg: "Cuts", type: 1 },
    ];

    let validObj = validate(validateFields);
    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message}`);
      }
      if (validObj.type === 2) {
        swal(`Please Select ${validObj.message}`);
      }
    } else {
      this.props.WeavingProductionCreateAction(
        serverApi.ADD_WEAVING_PRODUCTION,
        data,
        this.props.history
      );
    }
  };
  onClickCancel = () => {};

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div className="finishingEntriesContainer">
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div>
                      <div className="TextFieldBlock">
                        <label>
                          Date<div className="mandatoryField">*</div>
                        </label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          name="weavingDate"
                          inputFormat="dd-MM-yyyy"
                          value={this.state.weavingDate}
                          fullWidth
                          onChange={(e) =>
                            this.handleSelect_Date(e, "weavingDate")
                          }
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="TextFieldBlock">
                      <label>
                        Time<span className="starcolor">*</span>
                      </label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="Time"
                        name="time"
                        value={this.state.time}
                        onChange={(e) => this.handleSelect_Date(e, "time")}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input ref={inputRef} {...inputProps} />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={
                          this.state.spellContent
                            ? this.state.spellContent.map((item) => ({
                                label: item.label,
                                value: item.value,
                                name: item.name,
                              }))
                            : ""
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.spell_name}
                        name="spell_name"
                        label="Spell"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.spinningQualityListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.qualityType}
                        name="qualityType"
                        label="Quality"
                        required
                        update={
                          this.props.location.state !== undefined ? "" : 1
                        }
                        isDisabled={
                          this.state.spell_name === "" ||
                          this.state.spell_name === undefined ||
                          this.state.spell_name.length === 0
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.looms}
                      caps="OFF"
                      type="number"
                      onChange={this.handleChange("looms")}
                      label="Number of Loom"
                      required
                      disabled={
                        this.state.qualityType === "" ||
                        this.state.qualityType === undefined ||
                        this.state.qualityType.length === 0
                          ? true
                          : false
                      }
                    />
                    <div>
                      From Jugar Entry :{" "}
                      <span>{this.state.noOfLoomsValue}</span>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.cuts}
                      caps="OFF"
                      type="number"
                      onChange={this.handleChange("cuts")}
                      label="Cuts"
                      required
                      disabled={
                        this.state.looms === "" ||
                        this.state.looms === undefined ||
                        this.state.looms === 0
                          ? true
                          : false
                      }
                    />
                    <div>
                      From Jugar Entry : <span>{this.state.cutsValue}</span>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <div className="consoleFormButtonBlock">
              <Link to="/pr_weaving_production">
                <CustomButton
                  label={"Cancel"}
                  className="greenBorderButton"
                  handleClick={this.onClickCancel}
                />
              </Link>
              <CustomButton
                label={"Create"}
                className="greenButton"
                handleClick={this.onClickCreate}
                type="sumbit"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { spell } = state.AttendanceReducer;
  const { SpinningQualityList } = state.SpinningQualityReducer; // fetching list from spinning quality reducer
  const { CutsListArray, WeavingProductionArray } =
    state.WeavingProductionListReducer;

  return {
    spell,
    SpinningQualityList,
    CutsListArray,
    WeavingProductionArray,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSpell,
    cutsListAction,
    spinningQualityList,
    WeavingProductionCreateAction,
  })(CreateWeavingProduction)
);
