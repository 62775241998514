import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import {
  transporterMasterList,
  transporterMasterNewListApi,
} from "../../../store/Master/TransporterMaster/actions";
class ActiveTransporterApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      transporterMasterListData: [], // Transporter master list
      newTransporterMasterListData: [],
    };
  }
  componentDidMount() {
    const data = {
      companyId: localStorage.getItem("companyId"),
      isActive: 1,
      transporterName: null,
      transporterAddress: null,
      stateCode: null,
      stateName: null,
      transporterContact: null,
      transporterEmail: null,
    };
    this.props.transporterMasterNewListApi(
      serverApi.TRASPORTER_MASTER_LIST_NEW_API,
      data,
      this.props.history
    );
  }
  componentWillReceiveProps(props) {
    if (props.newTransporterMasterList) {
      if (props.newTransporterMasterList.data) {
        if (props.newTransporterMasterList.data.data) {
          var list = props.newTransporterMasterList.data.data.map((prop) => ({
            companyId: prop.companyId,
            isActive: 1,
            contact: prop.contact,
            createdBy: prop.createdBy,
            createdOn: prop.createdOn,
            email: prop.email,
            stateCode: prop.stateCode,
            stateName: prop.stateName,
            transporterAddress: prop.transporterAddress,
            transporterGst: prop.transporterGst,
            transporterId: prop.transporterId,
            transporterName: prop.transporterName,
          }));

          this.setState({
            newTransporterMasterListData: list,
          });
        }
      }
    }
  }

  handleSearch = (SearchData) => {
    const data = {
      companyId: SearchData.companyId,
      isActive: 1,
      transporterName: SearchData.transporterName,
      transporterAddress: SearchData.transporterAddress,
      stateCode: SearchData.stateCode,
      stateName: SearchData.stateName,
      transporterContact: SearchData.transporterContact,
      transporterEmail: SearchData.transporterEmail,
    };

    this.props.transporterMasterNewListApi(
      serverApi.TRASPORTER_MASTER_LIST_NEW_API,
      data,
      this.props.history
    );
  };

  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.newTransporterMasterListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          handleSearch={this.handleSearch}
          filter_form={"ActiveTransporterMaster"}
          url="/create_Transporter_master"
        />
      </>
    );
  }
}
// Transporter list Headers
const Columns = [
  {
    Header: "Transporter Name",
    accessor: "transporterName",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Transporter Address",
    accessor: "transporterAddress",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "State Code",
    accessor: "stateCode",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "State Name",
    accessor: "stateName",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Contact",
    accessor: "contact",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Email",
    accessor: "email",
    hidden: false,
    csvExport: true,
  },
  {
    Header:"Transporter Gst No",
    accessor: "transporterGst",
    hidden: false,
    csvExport: true,
  },
];
const mapStatetoProps = (state) => {
  const { TransporterMasterList, newTransporterMasterList } =
    state.TransporterReducer; // fetching list from transporter reducer
  return { TransporterMasterList, newTransporterMasterList };
};
export default withRouter(
  connect(mapStatetoProps, {
    transporterMasterList,
    transporterMasterNewListApi,
  })(ActiveTransporterApp)
);
