import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import {
  CreateBudgetHeads,
  UpdateBudgetHeads,
} from "../../../store/Master/BudgetHeads/actions";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import moment from "moment";
import { getParentBudgetHeads } from "../../../store/Global/DropDownApis/actions";
import swal from "sweetalert";

class CreateBudgetHeadsMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      parentBudgetHeads: "",
      budgetHeadsCode: "",
      budgetHeadsName: "",
      budgetHeadId: "",
      createdBy: null,
      createdOn: "",
      createdDate: "",
      parentBudgetId: "",
      parentBudgetHeadsList: [], // parent budget heads list
      locationState: "",
    };
  }
  onClickCreateUpdate = () => {
    const userId = this.state.userDit.userId;
    const { budgetHeadsName } = this.state;
    if (budgetHeadsName === "" || budgetHeadsName === undefined) {
      swal("Please Enter Budget Head Name");
      return false;
    }
    if (this.props.location.state === undefined) {
      const data = {
        budgetHeadId: "",
        code: this.state.budgetHeadsName,
        description: this.state.parentBudgetHeads,
        name: this.state.budgetHeadsName,
        isActive: 1,
        companyId: localStorage.getItem("companyId"),
        createdBy: this.state.userDit.userId.toString(),
        parentBudgetId: this.state.parentBudgetId,
        status: "",
        createdDate: this.state.createdOn,
        lastModifiedBy: moment().format(),
      };
      this.props.CreateBudgetHeads(
        serverApi.CREATE_BUDGET_HEADS,
        data,
        this.props.history
      ); // calling create budget heads api
    } else {
      const data = {
        cipher: this.state.userDit.cipher,
        companyId: this.state.companyId,
        name: this.state.budgetHeadsName,
        parentBudgetHeads: this.state.parentBudgetId,
        budgetHeadId: this.state.budgetHeadId,
        code: this.state.budgetHeadsName,
        description: this.state.parentBudgetHeads,
        isActive: this.props.location.state.rowData.isActive,
        parentBudgetId: this.state.parentBudgetId,
        createdBy: userId.toString(),
        createdOn: this.state.createdOn,
      };
      this.props.UpdateBudgetHeads(
        serverApi.UPDATE_BUDGET_HEADS,
        data,
        this.props.history
      ); // calling update budget heads api
    }
  };
  // function for previous page
  onClickCancel = () => {
    this.props.history.push("/budget_heads");
  };
  // function for Textfields
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "budgetHeadsName") {
      this.setState({
        budgetHeadsName: event,
      });
    }
  };
  // function for popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "parentBudgetHeads") {
      this.setState({
        parentBudgetHeads: selectedName,
        parentBudgetId: selectedValue,
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    this.setState({
      locationState: this.props.location,
    });
    window.addEventListener("scroll", this.handleScroll);
    this.props.getParentBudgetHeads(
      serverApi.GET_PARENT_BUDGET_HEADS_LIST +
        "company/" +
        this.state.companyId,
      this.props.history
    ); // calling parent budget heads api
  }
  componentWillReceiveProps(props) {
    if (props.parentBudgetHeadsList) {
      this.setState({
        parentBudgetHeadsList: props.parentBudgetHeadsList.data, // updating for parent budget heads list
      });
    }
    if (this.props.location.state) {
      this.setState({
        budgetHeadsName: this.props.location.state.rowData.name,
        createdBy: this.props.location.state.rowData.createdBy,
        parentBudgetId: this.props.location.state.rowData.parentBudgetId,
        budgetHeadId: this.props.location.state.rowData.budgetHeadId,
        budgetHeadsCode: this.props.location.state.rowData.code,
        companyId: this.props.location.state.rowData.companyId,
        companyName: this.props.location.state.rowData.companyName,
        description: this.props.location.state.rowData.description,
        createdOn: this.props.location.state.rowData.createdOn,
        isActive: this.props.location.state.rowData.isActive,
      });
      const dateEntered = this.props.location.state.rowData.createdOn;
      const dateIsValid = moment(dateEntered).format("LLLL");
      this.setState({
        createdDate: dateIsValid,
      });
    }
  }

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <>
                      <Box gridColumn="span 3">
                        <TextFieldReq
                          value={this.state.budgetHeadId}
                          caps="OFF"
                          minValue="0"
                          onChange={this.onhandlechangeValue("budgetHeadsId")}
                          label="Budget Heads Id"
                        />
                      </Box>
                    </>
                  )}
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.budgetHeadsName}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("budgetHeadsName")}
                      label="Budget Heads Name"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.parentBudgetHeadsList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.parentBudgetId}
                        update={this.props.location.state ? 1 : 0}
                        name="parentBudgetHeads"
                        label="Parent Budget Heads"
                      />
                    </div>
                  </Box>
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <>
                      <Box gridColumn="span 6">
                        <div className="consoleUpdateTextBlock">
                          <div>
                            <span className="taxLabel">
                              Created By : <b>{this.state.createdBy}</b>
                            </span>
                          </div>
                          <div>
                            <span className="taxLabel">
                              Created On :<b> {this.state.createdDate}</b>
                            </span>
                          </div>
                        </div>
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.state.locationState.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  // fetching create and update from budgetheads list
  const { parentBudgetHeadsList } = state.DropDownListReducer; // fetching api from global api
  return { parentBudgetHeadsList };
};

export default withRouter(
  connect(mapStatetoProps, {
    CreateBudgetHeads,
    UpdateBudgetHeads,
    getParentBudgetHeads,
  })(CreateBudgetHeadsMaster)
);
