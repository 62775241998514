import {
  UOM_MASTER_LIST,
  UOM_LIST_SUCCESSFULL,
  CREATE_UOM_MASTER,
  CREATE_UOM_MASTER_SUCCESSFULL,
  GET_UOM_RELATION_DATA,
  GET_UOM_RELATION_DATA_SUCCESSFULL,
  DELETE_UOM_RELATION,
  DELETE_UOM_RELATION_SUCCESSFULL,
  UOM_LIST_BASEDON_ITEM_ID,
  UOM_LIST_BASEDON_ITEM_ID_SUCCESS
} from "./actionTypes";

export const deleteUomRelation = (url, uomCode, id, history) => {
  return {
    type: DELETE_UOM_RELATION,
    payload: { url, uomCode, id, history },
  };
};

export const deleteUomRelationSuccessfull = (data) => {
  return {
    type: DELETE_UOM_RELATION_SUCCESSFULL,
    payload: data,
  };
};

export const getUomMasterData = (url, data, history) => {
  return {
    type: UOM_MASTER_LIST,
    payload: { url, data, history },
  };
};

export const UomMasterSuccessfull = (data) => {
  return {
    type: UOM_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const createUomMaster = (url, data, history) => {
  return {
    type: CREATE_UOM_MASTER,
    payload: { url, data, history },
  };
};

export const createUomMasterSuccessfull = (data) => {
  return {
    type: CREATE_UOM_MASTER_SUCCESSFULL,
    payload: data,
  };
};
export const getUomRelation = (url, data, history) => {
  return {
    type: GET_UOM_RELATION_DATA,
    payload: { url, data, history },
  };
};

export const getUomRelationSuccessfull = (data) => {
  return {
    type: GET_UOM_RELATION_DATA_SUCCESSFULL,
    payload: data,
  };
};

export const getUomListBasedOnItemId = (url, data, history) => {
    return {
      type: UOM_LIST_BASEDON_ITEM_ID,
      payload: { url, data, history },
    };
  };
  
  export const getUomListBasedOnItemIdSuccess = (data) => {
    return {
      type: UOM_LIST_BASEDON_ITEM_ID_SUCCESS,
      payload: data,
    };
  };

