import {
  LEDGER_GROUPS_LIST,
  CREATE_LEDGER_GROUPS_MASTER,
  UPDATE_LEDGER_GROUPS_MASTER,
  LEDGER_GROUPS_LIST_SUCCESSFULL,
  CREATE_LEDGER_GROUPS_MASTER_SUCCESSFULL,
  UPDATE_LEDGER_GROUPS_MASTER_SUCCESSFULL,
} from "./actionType";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  LedgerGroupsList: [],
  CreateLedgerGroupsmaster: [],
  UpdateLedgerGroupsMaster: [],
};

const LedgerGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LEDGER_GROUPS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LEDGER_GROUPS_LIST_SUCCESSFULL:
      state = {
        ...state,
        LedgerGroupsList: action.payload.response,
        loading: true,
      };
      break;
    case CREATE_LEDGER_GROUPS_MASTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_LEDGER_GROUPS_MASTER_SUCCESSFULL:
      state = {
        ...state,
        CreateLedgerGroupsmaster: action.payload.response,
        loading: true,
      };
      break;
    case UPDATE_LEDGER_GROUPS_MASTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_LEDGER_GROUPS_MASTER_SUCCESSFULL:
      state = {
        ...state,
        UpdateLedgerGroupsMaster: action.payload.response,
        loading: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LedgerGroupsReducer;
