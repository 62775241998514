import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import TextFieldNumber from "../../../components/TextField/TextFieldNumber";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import {
  createMachineMaster,
  updateMachineMaster,
  getMachineViewById,
} from "../../../store/Master/MachineMaster/actions";
import {
  getTypesOfMachine,
  getDepartmentList,
} from "../../../store/Global/DropDownApis/actions";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import swal from "sweetalert";
import { validate } from "../../../Utilities/Validations";

class CreateMachineMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      createdBy: "",
      mcCode: "",
      mcName: "",
      department: "",
      deptId: "",
      typeOfMC: "",
      mcPostingCode: "",
      speed: "",
      reedSpeed: "",
      teeth: "",
      lineNumber: "",
      displayOrderNo: "",
      bobbinCount: "",
      bobbinWeight: "",
      deliverySpeed: "",
      sliverWeight: "",
      twistPerInch: "",
      openOutNoOfCans: "",
      prodAtEff: "",
      targetEff: "",
      createdOn: "",
      departmentList: [], // To displaying department list
      typesOfMachineList: [], // to displaying types of machine
      typesOfMachine: "",
      typesOfMachineValue: "",
      departmentId: "",
      machineId: "",
      machineType: "",
      id: "",
      locationState:"",
    };
  }
  /* Calling Create and Update Api's to Actions */
  onClickCreateUpdate = () => {
    const {
      mcName,
      deptId,
      lineNumber,
      mcPostingCode,
      typesOfMachineValue,
      typesOfMachine,
    } = this.state;

    const validateFields = [
      { field: mcName, msg: "Machine Name", type: 1 },
      { field: deptId, msg: "Department", type: 2 },
      { field: typesOfMachineValue, msg: "Types of Machines", type: 2 },
      { field: mcPostingCode, msg: "Machine Posting Code", type: 1 }
    ];
    let validObj = validate(validateFields);
    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message}`);
      }
      if (validObj.type === 2) {
        swal(`Please Select ${validObj.message}`);
      }
    } else {
      if (this.props.location.state === undefined) {
        const data = {
          machineName: this.state.mcName,
          deptId: this.state.deptId,
          typeOfMC: this.state.typesOfMachineValue,
          machineType: this.state.typesOfMachineValue,
          machineCode: this.state.mcCode,
          companyId: this.state.companyId,
          createdBy: this.state.userDit.userId.toString(),
          mcPostingCode: this.state.mcPostingCode,
          speed: this.state.speed,
          reedSpeed: this.state.reedSpeed,
          teeth: this.state.teeth,
          lineNumber: this.state.lineNumber,
          displayOrderNo: this.state.displayOrderNo,
          bobbinCount: this.state.bobbinCount,
          bobbinWeight: this.state.bobbinWeight,
          deliverySpeed: this.state.deliverySpeed,
          sliverWeight: this.state.sliverWeight,
          twistPerInch: this.state.twistPerInch,
          outputNoOfCans: this.state.openOutNoOfCans,
          prodEff: this.state.prodAtEff,
          targetEff: this.state.targetEff,
        };
        this.props.createMachineMaster(
          serverApi.CREATE_MACHINE_MASTER,
          data,
          this.props.history
        ); // calling create Machine master api
      } else {
        const data = {
          machineId: this.state.machineId,
          machineName: this.state.mcName,
          deptId: this.state.deptId,
          machineType: this.state.typesOfMachineValue,
          machineCode: this.state.mcCode,
          createdBy: this.state.userDit.userId.toString(),
          typeOfMC: this.state.typesOfMachineValue,
          mcPostingCode: this.state.mcPostingCode,
          speed: this.state.speed,
          reedSpeed: this.state.reedSpeed,
          teeth: this.state.teeth,
          lineNumber: this.state.lineNumber,
          displayOrderNo: this.state.displayOrderNo,
          bobbinCount: this.state.bobbinCount,
          bobbinWeight: this.state.bobbinWeight,
          deliverySpeed: this.state.deliverySpeed,
          sliverWeight: this.state.sliverWeight,
          twistPerInch: this.state.twistPerInch,
          outputNoOfCans: this.state.openOutNoOfCans,
          prodEff: this.state.prodAtEff,
          targetEff: this.state.targetEff,
          companyId: this.state.companyId,
        };
        this.props.updateMachineMaster(
          serverApi.UPDATE_MACHINE_MASTER,
          data,
          this.props.history
        ); // calling update Machine master api
        // }
      }
    }
  };
  // function for previous page
  onClickCancel = () => {
    this.props.history.push("/machine_master");
  };
  //function for TextFields
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });

    if (key === "mcCode") {
      const Code = event.replace(/[^a-zA-Z0-9\s]+/g, "");
      this.setState({
        mcCode: Code,
      });
    }
    if (key === "mcName") {
      this.setState({
        mcName: event,
      });
    }
    if (key === "mcPostingCode") {
      this.setState({
        mcPostingCode: event,
      });
    }
    if (key === "speed") {
      this.setState({
        speed: event,
      });
    }
    if (key === "reedSpeed") {
      this.setState({
        reedSpeed: event,
      });
    }
    if (key === "teeth") {
      this.setState({
        teeth: event,
      });
    }
    if (key === "lineNumber") {
      this.setState({
        lineNumber: event,
      });
    }
    if (key === "displayOrderNo") {
      this.setState({
        displayOrderNo: event,
      });
    }
    if (key === "bobbinCount") {
      this.setState({
        bobbinCount: event,
      });
    }
    if (key === "bobbinWeight") {
      this.setState({
        bobbinWeight: event,
      });
    }
    if (key === "deliverySpeed") {
      this.setState({
        deliverySpeed: event,
      });
    }
    if (key === "sliverWeight") {
      this.setState({
        sliverWeight: event,
      });
    }
    if (key === "twistPerInch") {
      this.setState({
        twistPerInch: event,
      });
    }
    if (key === "openOutNoOfCans") {
      this.setState({
        openOutNoOfCans: event,
      });
    }
    if (key === "prodAtEff") {
      this.setState({
        prodAtEff: event,
      });
    }
    if (key === "targetEff") {
      this.setState({
        targetEff: event,
      });
    }
  };

  handleKeydownEvent(event) {}
  // function for popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "typesOfMachine") {
      this.setState({
        typesOfMachine: selectedValue,
        typesOfMachineValue: selectedValue,
      });
    }
    if (name === "department") {
      this.setState({
        department: selectedValue,
        deptId: selectedValue,
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    this.setState({
      locationState: this.props.location,
    })
    window.addEventListener("scroll", this.handleScroll);
    if (this.props.location.state) {
      this.props.getMachineViewById(
        serverApi.MACHINE_MASTER_VIEW_BY_ID +
          this.props.location.state.rowData.machineId +
          "/" +
          this.state.userDit.cipher
      );
    }
    this.props.getDepartmentList(
      serverApi.GET_DEPARTMENT_LIST +
        this.state.companyId +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    ); // calling for department list api
    this.props.getTypesOfMachine(
      serverApi.GET_TYPES_OF_MACHINE,
      this.props.history
    );
    if (this.props.location.state) {
      if (this.props.machineViewByIdList) {
        if (this.props.machineViewByIdList.data) {
          this.setState({});
        }
      }
    }
  }
  componentWillReceiveProps(props) {
    //! departmentList
    if (props.departmentList.data) {
      var list = [];

      let listData = props.departmentList.data.filter((item) => {
        if (item.label != "Select....") {
          return item;
        }
      });

      listData.map((item) => {
        list.push({
          label: item.label,
          name: item.name,
          value: item.masterDeptId,
          variant: null,
        });
      });
      this.setState({
        departmentList: list,
      });
    }

    if (props.typesOfMachine) {
      if (props.typesOfMachine.data) {
        if (props.typesOfMachine.data.length !== 0) {
          var list = [];
          list = props.typesOfMachine.data.filter((item) => {
            if (item.value !== 0) {
              return item;
            }
          });

          this.setState({
            typesOfMachineList: list,
          });
        }
      }
    }
    if (props.location.state) {
      if (props.machineViewByIdList) {
        if (props.machineViewByIdList.data) {
          this.setState({
            bobbinCount: props.machineViewByIdList.data.bobbinCount,
            mcName: props.machineViewByIdList.data.machineName,
            deptId: props.machineViewByIdList.data.deptId,
            typesOfMachine: props.machineViewByIdList.data.machineType,
            typesOfMachineValue: props.machineViewByIdList.data.machineType,
            mcCode: props.machineViewByIdList.data.machineCode,
            mcPostingCode: props.machineViewByIdList.data.mcPostingCode,
            speed: props.machineViewByIdList.data.speed,
            reedSpeed: props.machineViewByIdList.data.reedSpeed,
            bobbinWeight: props.machineViewByIdList.data.bobbinWeight,
            displayOrderNo: props.machineViewByIdList.data.displayOrderNo,
            lineNumber: props.machineViewByIdList.data.lineNumber,
            deliverySpeed: props.machineViewByIdList.data.deliverySpeed,
            sliverWeight: props.machineViewByIdList.data.sliverWeight,
            twistPerInch: props.machineViewByIdList.data.twistPerInch,
            openOutNoOfCans: props.machineViewByIdList.data.outputNoOfCans,
            machineId: props.machineViewByIdList.data.machineId,
            teeth: props.machineViewByIdList.data.teeth,
            prodAtEff: props.machineViewByIdList.data.prodEff,
            targetEff: props.machineViewByIdList.data.targetEff,
            createdBy: props.machineViewByIdList.data.createdBy,
          });
        }
      }
    }
  }

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.mcCode}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("mcCode")}
                      label="Machine Code"
                      // type="number"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.mcName}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("mcName")}
                      label="Machine Name"
                      // type="number"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.departmentList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.deptId}
                        update={this.props.location.state ? 1 : 0}
                        name="department"
                        label="Department"
                        required
                        isDisabled={this.state.mcName === "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.typesOfMachineList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.typesOfMachine}
                        update={this.props.location.state ? 1 : 0}
                        name="typesOfMachine"
                        label="Types Of Machine"
                        required
                        isDisabled={
                          this.state.deptId === ""
                            ? true
                            : false || this.state.deptId === 0
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.mcPostingCode}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("mcPostingCode")}
                      label="Machine Posting Code"
                      type="number"
                      disabled={
                        this.state.typesOfMachine === ""
                          ? true
                          : false || this.state.typesOfMachine === 0
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNumber
                      value={this.state.speed}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("speed")}
                      label="Speed(RPM)"
                      type="number"
                      disabled={
                        this.state.typesOfMachine === ""
                          ? true
                          : false || this.state.typesOfMachine === 0
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNumber
                      value={this.state.reedSpeed}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("reedSpeed")}
                      label="Reed Speed"
                      type="number"
                      disabled={
                        this.state.typesOfMachine === ""
                          ? true
                          : false || this.state.typesOfMachine === 0
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.teeth}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("teeth")}
                      label="Teeth"
                      type="number"
                      disabled={
                        this.state.typesOfMachine === ""
                          ? true
                          : false || this.state.typesOfMachine === 0
                          ? true
                          : false
                      }
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <TextFieldNumber
                      value={this.state.lineNumber}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("lineNumber")}
                      label="Line Number"
                      type="number"
                      required={false}
                      disabled={this.state.mcPostingCode === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNumber
                      value={this.state.displayOrderNo}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("displayOrderNo")}
                      label="Display Order No"
                      type="number"
                      disabled={this.state.mcPostingCode === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNumber
                      value={this.state.bobbinCount}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("bobbinCount")}
                      label="Bobbin Count"
                      type="number"
                      disabled={this.state.mcPostingCode === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNumber
                      value={this.state.bobbinWeight}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("bobbinWeight")}
                      label="Bobbin Weight"
                      type="number"
                      disabled={this.state.mcPostingCode === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNumber
                      value={this.state.deliverySpeed}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("deliverySpeed")}
                      label="Delivery Speed(Ft/Min)"
                      type="number"
                      disabled={this.state.mcPostingCode === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNumber
                      value={this.state.sliverWeight}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("sliverWeight")}
                      label="Sliver Weight (Per 100Yds)"
                      type="number"
                      disabled={this.state.mcPostingCode === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNumber
                      value={this.state.twistPerInch}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("twistPerInch")}
                      label="Twist Per Inch"
                      type="number"
                      disabled={this.state.mcPostingCode === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNumber
                      value={this.state.openOutNoOfCans}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("openOutNoOfCans")}
                      label="O/P No Of Cans"
                      type="number"
                      disabled={this.state.mcPostingCode === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNumber
                      value={this.state.prodAtEff}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("prodAtEff")}
                      label="Prod at 100% EFF(Tons per 24Hours)"
                      type="number"
                      disabled={this.state.mcPostingCode === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNumber
                      value={this.state.targetEff}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("targetEff")}
                      label="Target Eff%"
                      type="number"
                      disabled={this.state.mcPostingCode === "" ? true : false}
                    />
                  </Box>
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.state.locationState.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
          {/* common popup for validation */}
          {this.state.confirm && (
            <CommonPopup>
              <div className="delAddItem">
                <div className="mandatoryFieldText">
                  Please select all the mandatory fields
                </div>
                <div className="delAddItemBtns">
                  <input
                    type="button"
                    onClick={this.onclickPopUP.bind(this)}
                    value="Ok"
                    className="delYesBtn"
                  />
                </div>
              </div>
            </CommonPopup>
          )}
        </div>
      </div>
    );
  }
}
const mapStatetoProps = (state) => {
  const { machineViewByIdList } = state.MachineMasterReducer; // fetching create and update from Machine master list reducer
  const { typesOfMachine, departmentList } = state.DropDownListReducer;
  return {
    typesOfMachine,
    departmentList,
    machineViewByIdList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    createMachineMaster,
    updateMachineMaster,
    getTypesOfMachine,
    getDepartmentList,
    getMachineViewById,
  })(CreateMachineMaster)
);
