import {PAYMENT_LIST, BILLS_NO_LIST, PAYMENT_CREATE} from './actionType';
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {getList} from '../../../helpers/Server_Helper';
import {paymentListSuccessfull, getBillNoListSuccessfull, paymentCreatedSuccessfull} from './actions';
import swal from 'sweetalert';

function* getPaymentList({payload:{url,data,history}}) {
    try{
        const response = yield call(getList,url,data);
        yield put(paymentListSuccessfull({response}))
    }catch(error){
        console.log(error)
    }
}

function* getBillList({payload:{url,data,history}}) {
    try{
        const response = yield call(getList,url,data);
        yield put(getBillNoListSuccessfull({response}))
    }catch(error){
        console.log(error)
    }
}

function* getPaymentCreated({payload:{url,data,history}}) {
    try{
        const response = yield call(getList,url,data);
        yield put(paymentCreatedSuccessfull({response}))
        swal(response.message, { icon: "success" });
        if(data.transactionType === 'P') {
            history.push('/payments')
        }else if(data.transactionType === 'R') {
            history.push('/receipts')
        } 
    }catch(error){
        swal('Something went wrong', { icon: "error" });
        console.log(error)
    }
}

export function* watchPaySlipList () {
    yield takeEvery(PAYMENT_LIST, getPaymentList)
}

export function* watchPaymentCreateList () {
    yield takeEvery(PAYMENT_CREATE, getPaymentCreated)
}


export function* watchBillList () {
    yield takeEvery(BILLS_NO_LIST, getBillList)
}

function* PaymentSaga() {
    yield all([
        fork(watchPaySlipList),
        fork(watchBillList),
        fork(watchPaymentCreateList)
    ])
}
export default PaymentSaga;