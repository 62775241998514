import React, { Component } from "react";
import Select from "react-select";
import FormControl from "@mui/material/FormControl";

class DynamicSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedlable: "",
      selectedValue: "",
      selectedName: "",
      selectedItemCode: "",
      isDropdownOpen: false,
    };
  }

  handleChange = (event) => {
    let selectedValue = event.value;
    let selectedName = event.name;
    let selectedItemCode = event.variant ? event.variant : event.itemCode;

    this.setState({
      selectedValue: selectedValue,
      selectedName: selectedName,
      selectedItemCode: selectedItemCode,
    });
    this.props.onSelectChange(
      selectedValue,
      selectedName,
      this.props.name,
      this.props.row,
      selectedItemCode
    );
  };

  handleMenuOpen = () => {
    this.props.handleMenuOpen();
  };

  componentWillReceiveProps(props) {
    if (this.props.arrayOfData) {
      this.props.arrayOfData.forEach((row) => {
        if (this.props.selected == row.value) {
          this.setState({
            selectedlable: row.label,
            selectedValue: this.props.selected,
            selectedName: row.label,
            selectedItemCode: row.variant,
          });
        }
      });
    }
  }

  render() {
    const { isDropdownOpen } = this.state;

    if (this.props.arrayOfData) {
      this.props.arrayOfData.map((row) => {
        if (this.props.selected === row.value) {
          this.state.selectedValue = this.props.selected;
          this.state.selectedlable = row.label;
          this.state.selectedName = row.label;
          this.state.selectedItemCode = row.variant;
        }
      });
    }

    // }

    return (
      <FormControl sx={{ width: "100%" }} className="customDropdown">
        <div>
          <label htmlFor="app-input-field">
            {this.props.label} {isDropdownOpen}
          </label>
          {this.props.required && <div className="mandatoryField">*</div>}
        </div>

        <Select
          classNamePrefix="react-select"
          options={this.props.arrayOfData}
          menuPosition="fixed"
          menuPlacement="auto"
          closeMenuOnScroll={() => this.props.isDropdownOpen}
          defaultValue={
            this.props.selected
              ? {
                  value: this.props.selected,
                  label: this.state.selectedlable,
                  variant: this.state.selectedItemCode,
                }
              : ""
          }
          value={
            this.props.selected
              ? {
                  value: this.state.selectedValue,
                  label: this.state.selectedName,
                  variant: this.state.selectedItemCode,
                }
              : ""
          }
          placeholder="Select"
          onChange={this.handleChange}
          onMenuOpen={this.handleMenuOpen}
          isDisabled={this.props.isDisabled ? true :this.props.update === 1 ? this.props.isDisabled : false}
        />
      </FormControl>
    );
  }
}
export default DynamicSelect;
