import React, { Component } from "react";
import CustomButton from "../../../components/Buttons/Buttons";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi, getTenantId ,serverVars} from "../../../helpers/Consts";
import { InwardLineItemsProps } from "../../../store/Purchase/Inward/actions";
import { getFileDownload } from "../../../helpers/server.js";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { Box, Grid, } from "@mui/material";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import { getFilesofInward } from "../../../store/Purchase/Inward/actions";


class WorkReportPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      date: moment().format("YYYY-MM-DD"),
      currentStep: 1,
      companyLogo: "",
      source: "",
      Inward_LineItems: [],
      inwardfilesList: [],
      RowData: "",
      supplierName: "",
      SequenceNo: "",
      locationState: "",
    };
  }
  componentDidMount() {
    this.setState({
      locationState: this.props.location,
    });
    let companyData = JSON.parse(localStorage.getItem("authUser"));
    let companyId = localStorage.getItem("companyId");
    companyData.companyDetails.map((p) => {
      let id = p.value;
      if (companyId === id.toString()) {
        let companyName = p.label.split("-");
        this.setState({
          companyName: companyName[1],
          address1: p.address1,
          companyLogo: p.companyLogo,
          gstNo: p.gstNo,
          address2: p.address2,
          comName: p.name,
          phoneNo: p.contactNo,
          email: p.emailId,
        });
      }
    });
    if (this.props.locationState.state !==undefined) {
      let id = this.props.locationState.state.rowData.id;
      this.props.getFilesofInward(
        serverApi.GET_FILES +
          18 +
          "/" +
          id +
          "/" +
          localStorage.getItem("companyId"),
        this.props.history,
        18,
        "inward"
      );
    }
    if (this.props.Inward_LineItems) {
      let inwardLineitems = this.props.Inward_LineItems.filter(
        (item) => item.isActive !== 0
      );

      this.setState({
        Inward_LineItems: inwardLineitems,
      });
    }

    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData) {
        if (this.props.location.pathname === "/create_WorkReport") {
          this.setState({
            RowData: this.props.location.state.rowData,
            supplierName: this.props.location.state.rowData.supplierName,
            SequenceNo: this.props.location.state.rowData.inwardSequenceNo,
          });
        }
      }
    }
  }

  componentWillReceiveProps(props){
    if (props.inwardfilesList) {
      if (props.inwardfilesList.data) {
        let filesList = props.inwardfilesList.data;
        this.setState({
          inwardfilesList: filesList.data,
        });
      }
    }
  }
  getFilesDownload = (fileUploadId, fileName, fileExtension) => {
    let url = serverVars.SECURITY_API + "files/downloadfile/" + fileUploadId;
    getFileDownload(url, fileName, fileExtension);
  };
  handleEdit = () => {
    this.props.handleCurrentStep(this.state.currentStep);
  };
  handlePdf = () => {
    let Id = this.state.RowData.id;
    // this.props.getFileDownload(serverApi.DOWNLOAD_PDF+indentId+'/'+this.state.userDit.userId)

    fetch(
      serverApi.DOWNLOAD_PDF + Id + "/" + this.state.userDit.userId + "/" + 38,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          "X-TenantId": getTenantId(window.location.hostname),
          Authorization:
            localStorage.getItem("token_type") +
            localStorage.getItem("access_token"),
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          this.state.SequenceNo + "_" + this.state.supplierName + ".pdf"
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };
  render() {
    var filteredproducts = this.state.Inward_LineItems.filter((item) => {
      if (this.props.source === "WITHOUTWO" && item.itemGroupId === "997") {
        if (item.isActive !== 0) {
          return item;
        }
      } else {
        if (item.departmentId !== "" && item.isActive !== 0) {
          return item;
        }
      }
    });

    return (
      <>
        <div className="previewBlock">
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              padding: "10px",
            }}
          >
            {this.state.locationState && (
              <CustomButton
                label="Print"
                handleClick={this.handlePdf}
                type="button"
                className="lightBlueButton"
                muIcon={<PrintIcon />}
              />
            )}

            {this.state.locationState.state !== undefined &&
            (this.props.InwardFillDetails.statusName === "APPROVED" ||
              this.props.InwardFillDetails.statusName === "REJECTED") ? (
              ""
            ) : (
              <CustomButton
                label="Edit"
                handleClick={this.handleEdit}
                type="button"
                className="lightBlueButton"
                muIcon={<EditIcon />}
              />
            )}
          </div>

          <table
            cellpadding="0"
            cellspacing="0"
            width="90%"
            className="previewTable"
          >
            <tbody>
              <tr>
                <td
                  colspan="5"
                  style={{ borderRight: "none", borderBottom: "none" }}
                >
                  {/* <h3>{this.state.companyName}</h3> */}
                  <img src={this.state.companyLogo} width="100%" alt="" />
                </td>
                <td
                  colspan="8"
                  style={{
                    borderBottom: "none",
                    borderLeft: "none",
                    textAlign: "right",
                  }}
                >
                  <h2 style={{ margin: "0px" }}>
                   {this.state.companyName}
                  </h2>
                  <p style={{ margin: "0px" }}>{this.state.address1}</p>
                  <span>{this.state.gstNo}</span>
                </td>
              </tr>
              <tr>
                <td
                  colspan="13"
                  style={{ textAlign: "center", borderTop: "none" }}
                >
                  <h2>WORK REPORT</h2>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Work Report No:</b>
                </td>
                {/* <td colspan="3">
                  {this.props.RowData && this.props.RowData.indentSquenceNo}
                </td> */}
                <td
                  colspan="4"
                  className={
                    this.props.InwardFillDetails.GateEntryNo === "" ||
                    this.props.InwardFillDetails.GateEntryNo === undefined
                      ? "blur-text"
                      : ""
                  }
                >
                  {this.props.InwardFillDetails.GateEntryNo === "" ||
                  this.props.InwardFillDetails.GateEntryNo === undefined
                    ? "Will be generated after creation"
                    : this.props.InwardFillDetails.GateEntryNo}
                </td>
                <td colspan="3">
                  <b>Work Report Date : </b>
                </td>
                <td colspan="4">
                  {/* {this.props.InwardFillDetails.inwardDate
                    ? this.props.InwardFillDetails.inwardDate
                    :  */}
                  {moment(this.props.InwardFillDetails.inwardDate).format(
                    "DD-MM-YYYY"
                  )}
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Type</b>
                </td>
                <td colspan="10">SRN</td>
              </tr>
              <tr>
                <td colSpan="1">
                  <b>S.No</b>
                </td>
                <td width="10%">
                  <b>Item Code</b>
                </td>
                <td width="20%">
                  <b>Budget Head</b>
                </td>
                <td width="20%">
                  <b>Department</b>
                </td>
                <td width="10%">
                  <b>Item Group</b>
                </td>
                <td width="20%">
                  <b>Item Description</b>
                </td>
                <td width="10%">
                  <b>Quantity</b>
                </td>
                <td width="10%">
                  <b>Unit</b>
                </td>

                <td>
                  <b>Approved Quantity</b>
                </td>
                <td>
                  <b>Rejected Quantity</b>
                </td>
                <td>
                  <b>Reason</b>
                </td>
                <td width="14%">
                  <b>Stock</b>
                </td>
              </tr>

              {filteredproducts &&
                filteredproducts.map((data, index) => (
                  <tr>
                    <td style={{ textAlign: "right" }}>{index + 1}</td>
                    <td>
                      {this.props.source === "WITHWO" &&
                      this.state.locationState.state === undefined &&
                      data.itemGroupId
                        ? data.itemGroupId
                        : this.props.source === "WITHOUTWO" &&
                          this.state.locationState.state === undefined
                        ? data.itemGroupId
                        : data.itemGroup}
                      {data.itemCode}
                    </td>
                    <td>{data.budgetHeadName}</td>
                    <td>{data.departmentName}</td>
                    <td>
                      {data.itemGroupName ? data.itemGroupName : data.itemGroup}
                    </td>
                    <td>{data.itemName}</td>
                    <td style={{ textAlign: "right" }}>{data.inwardQty}</td>
                    <td>{data.uom ? data.uom : data.uomCode}</td>
                    <td align="right">
                      {data.approvedQty === null ||
                      data.approvedQty === undefined
                        ? parseFloat(data.inwardQty).toFixed(3)
                        : parseFloat(data.approvedQty).toFixed(3)}
                    </td>
                    <td align="right">
                      {data.rejectedQty === null ||
                      data.rejectedQty === undefined
                        ? "0"
                        : parseFloat(data.rejectedQty).toFixed(3)}
                    </td>
                    <td align="right">{data.reason}</td>
                    <td style={{ textAlign: "right" }}>0.000</td>
                  </tr>
                ))}
              <tr style={{ marginTop: "10px" }}>
                <td
                  colspan="6"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {this.props.locationState.state && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box gridColumn="span 12">
                          {this.state.inwardfilesList.length !== 0 &&(<span>
                            <b>Uploaded Documents</b>
                          </span>)}
                        </Box>
                        <Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={2}
                          className="ducumentUploadBlock uploadedContent"
                          style={{ padding: "10px" }}
                        >
                          {this.state.inwardfilesList &&
                            this.state.inwardfilesList.map((item) => (
                              <Box gridColumn="span 6">
                                <div className="documentUploadContent">
                                  <div
                                    className="uploadedInfo"
                                    style={{ display: "flex" }}
                                  >
                                    <img
                                      src={TickIcon}
                                      alt=""
                                      className="tickIcon"
                                      height={"20px"}
                                      width={"20px"}
                                    />
                                    <Tooltip
                                      TransitionComponent={Fade}
                                      TransitionProps={{ timeout: 600 }}
                                      title="Click to download File"
                                    >
                                      <div
                                        className="uplodedFileName"
                                        onClick={() => {
                                          this.getFilesDownload(
                                            item.fileUploadId,
                                            item.fileName,
                                            item.fileExtension
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.fileName}
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </Box>
                            ))}
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Inward_LineItems,inwardfilesList } = state.InwardReducer;
  return {
    Inward_LineItems,
    inwardfilesList
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getFilesofInward,
    getFileDownload,
    InwardLineItemsProps,
  })(WorkReportPreview)
);
