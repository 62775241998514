import {
  MATERIAL_REQUEST_LIST,
  MATERIAL_REQUEST_LIST_SUCCESSFULL,
  API_ERRORS,
  GET_CUSTOMER_LIST,
  GET_CUSTOMER_LIST_SUCCESSFULL,
  GET_PROJECT_LIST,
  GET_PROJECT_LIST_SUCCESSFULL,
  GET_ITEM_GROUP_BY_ID,
  GET_ITEM_GROUP_BY_ID_SUCCESSFULL,
  GET_SR_STOCK_LIST,
  GET_SR_STOCK_LIST_SUCCESSFULL,
  GET_SR_LIST,
  GET_SR_LIST_SUCCESSFULL,
  GET_MACHINE_NO_LIST,
  GET_MACHINE_NO_LIST_SUCCESSFULL,
  ADD_MATERIAL_REQUEST,
  ADD_MATERIAL_REQUEST_SUCCESSFULL,
  ITEM_LIST,
  ITEM_LIST_SUCCESSFULL,
  CREATE_STORE_ISSUE_LINE_ITEM,
  CREATE_STORE_ISSUE_LINE_ITEM_SUCCESSFULLY,
  STORE_ISSUE_LINE_ITEM,
  STORE_ISSUE_VIEW_BY_ID,
  STORE_ISSUE_VIEW_BY_ID_SUCCESSFULL,
  STORE_HEADER_DATA,
  BRANCH_WISE_STOCK_LIST,
  BRANCH_WISE_STOCK_LIST_SUCCESSFULL,
  CLEAR_HEADER_DATA,
  GETITEMSLISTFORCONSUMPTION,
  GETITEMSLISTFORCONSUMPTION_SUCCESSFULL,
  GETITEMGROUPSFORCONSUMPTION,
  GETITEMGROUPSFORCONSUMPTION_SUCCESSFULL,
  GETSRNOFORCONSUMPTION_SUCCESSFULL,
  GETSRNOFORCONSUMPTION,
  GETCOSTFACTORCONSUMPTION,
  GETCOSTFACTORCONSUMPTION_SUCCESSFULL,
} from "./actionTypes";

export const getMaterialRequestList = (url, data, history) => {
  return {
    type: MATERIAL_REQUEST_LIST,
    payload: { url, data, history },
  };
};

export const MaterialRequestListSuccessfull = (data) => {
  return {
    type: MATERIAL_REQUEST_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getStoreIssueViewById = (url, data, history) => {
  return {
    type: STORE_ISSUE_VIEW_BY_ID,
    payload: { url, data, history },
  };
};

export const getStoreIssueViewByIdSuccessfull = (data) => {
  return {
    type: STORE_ISSUE_VIEW_BY_ID_SUCCESSFULL,
    payload: data,
  };
};
export const setStoreIssueViewByIdNull = (data) => {
  return {
    type: STORE_ISSUE_VIEW_BY_ID_SUCCESSFULL,
    payload: data,
  };
};

export const getCustomerList = (url, history) => {
  return {
    type: GET_CUSTOMER_LIST,
    payload: { url, history },
  };
};

export const getCustomerListtSuccessfull = (data) => {
  return {
    type: GET_CUSTOMER_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getProjectList = (url, history) => {
  return {
    type: GET_PROJECT_LIST,
    payload: { url, history },
  };
};

export const getProjectListtSuccessfull = (data) => {
  return {
    type: GET_PROJECT_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getItemGroupById = (url, rowId, history) => {
  return {
    type: GET_ITEM_GROUP_BY_ID,
    payload: { url, rowId, history },
  };
};

export const getItemGroupByIdSuccessfull = (data) => {
  return {
    type: GET_ITEM_GROUP_BY_ID_SUCCESSFULL,
    payload: data,
  };
};

export const getSrStockList = (url, data, history) => {
  return {
    type: GET_SR_LIST,
    payload: { url, data, history },
  };
};

export const getSrStockListSuccessfull = (data) => {
  return {
    type: GET_SR_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getBranchStockList = (url, data, history) => {
  return {
    type: BRANCH_WISE_STOCK_LIST,
    payload: { url, data, history },
  };
};

export const getBranchStockListSuccessfull = (data) => {
  return {
    type: BRANCH_WISE_STOCK_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const ClearHeaderData = (data) => {
  return {
    type: CLEAR_HEADER_DATA,
    payload: data,
  };
};

export const getSrList = (url, data, history) => {
  return {
    type: GET_SR_STOCK_LIST,
    payload: { url, data, history },
  };
};

export const getSrListSuccessfull = (data) => {
  return {
    type: GET_SR_STOCK_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getMachineNoList = (url, history) => {
  return {
    type: GET_MACHINE_NO_LIST,
    payload: { url, history },
  };
};

export const getMachineNoListSuccessfull = (data) => {
  return {
    type: GET_MACHINE_NO_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const addMaterialRequest = (url, data, history) => {
  return {
    type: ADD_MATERIAL_REQUEST,
    payload: { url, data, history },
  };
};

export const addMaterialRequestSuccessfull = (data) => {
  return {
    type: ADD_MATERIAL_REQUEST_SUCCESSFULL,
    payload: data,
  };
};

export const apiErrors = (error) => {
  return {
    type: API_ERRORS,
    payload: error,
  };
};

export const getAllItemList = (url, data, history) => {
  return {
    type: ITEM_LIST,
    payload: { url, data, history },
  };
};

export const getAllItemListSuccessfull = (data) => {
  return {
    type: ITEM_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const createStoreIssueLinewise = (url, data, history, products) => {
  return {
    type: CREATE_STORE_ISSUE_LINE_ITEM,
    payload: { url, data, history, products },
  };
};

export const createStoreIssueLinewiseSuccessfull = (data) => {
  return {
    type: CREATE_STORE_ISSUE_LINE_ITEM_SUCCESSFULLY,
    payload: data,
  };
};

export const storeIssueLineItemWise = (data) => {
  return {
    type: STORE_ISSUE_LINE_ITEM,
    payload: data,
  };
};

export const storeIssueHeaderData = (data) => {
  return {
    type: STORE_HEADER_DATA,
    payload: data,
  };
};

//call back methods
export const getItemsListforConsumption = (url, data, callback, itemgrpId) => {
  return {
    type: GETITEMSLISTFORCONSUMPTION,
    payload: { url, data, callback, itemgrpId },
  };
};

export const getItemsListforConsumptionSuccessfull = (data) => {
  return {
    type: GETITEMSLISTFORCONSUMPTION_SUCCESSFULL,
    payload: data,
  };
};

export const getItemGroupListforConsumption = (url, history, callback, Id) => {
  return {
    type: GETITEMGROUPSFORCONSUMPTION,
    payload: { url, history, callback, Id },
  };
};
export const ItemGroupListForConsumptionSuccessfull = (data) => {
  return {
    type: GETITEMGROUPSFORCONSUMPTION_SUCCESSFULL,
    payload: data,
  };
};

export const getSrNoForConsumption = (url, data, callback, itemgrpId) => {
  return {
    type: GETSRNOFORCONSUMPTION,
    payload: { url, data, callback, itemgrpId },
  };
};

export const getSrNoForConsumptionSuccessfull = (data) => {
  return {
    type: GETSRNOFORCONSUMPTION_SUCCESSFULL,
    payload: data,
  };
};

export const getCostFactorConsumption = (url, history, callback, deptId) => {
  return {
    type: GETCOSTFACTORCONSUMPTION,
    payload: { url, history, callback, deptId },
  };
};

export const getCostFactorConsumptionSuccessfull = (data) => {
  return {
    type: GETCOSTFACTORCONSUMPTION_SUCCESSFULL,
    payload: data,
  };
};
