/*
 * Author: Pritam Kumar Shahi
 * Date: March 04, 2024
 */

import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import VowLogo from "../../assets/images/vow_logo.png";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Link, withRouter } from "react-router-dom";
import CustomButton from "../../components/Buttons/Buttons";
import { useDispatch } from "react-redux";
import { forgetUser } from "../../store/auth/forgetpwd/actions";
import { serverApi } from "../../helpers/Consts";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

const ForgotPassword = () => {
  const [state, setState] = useState({
    show: "false",
    hidden: "false",
    passwordExist: "",
    userName: "",
    message: "",
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const handleInputOnChange = (event) => {
    event.preventDefault();
    const target = event.target;
    setState({
      ...state,
      [target.name]: target.value,
    });
  };

  const handleSubmit = (event) => {
    if (state.userName !== "") {
      const host = `${window.location.origin}/ResetPassword?`;
      const data = {
        userName: state.userName,
        link: host,
      };
      dispatch(forgetUser(serverApi.FORGOT_PASSWORD_API, data, history));
    } else {
      swal("Please Enter All Mandatory Fields..!!");
    }
  };

  const onClickBefore = () => {
    history.push("/login");
  };

  return (
    <ThemeProvider theme={createTheme()}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        className="loginBackground"
      >
        <Grid
          container
          component="main"
          sx={{ height: "100vh" }}
          className="loginPatternOne"
        >
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            component={Paper}
            elevation={6}
            square
            style={{ height: "80%" }}
            className="loginContainer"
          >
            <Box
              className="loginBlock"
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={VowLogo} alt="" />
              <Typography component="h1">Forgot Password</Typography>
              <Typography component="p">
                Forgot Password and proceed with the company login process
              </Typography>
              <div className="resetPasswordFormValidation">
                <Box noValidate sx={{ mt: 1 }}>
                  <div className="resetPasswordTextField">
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="userName"
                      value={state.userName}
                      onChange={handleInputOnChange}
                      autoComplete="current-password"
                      autoFocus
                      className="loginInput"
                      placeholder="Enter your user name."
                    />
                  </div>

                  <div className="resetPasswordErrorMessage">
                    {state.message}
                  </div>
                  <Button
                    className="loginButton"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                  <br></br>
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={onClickBefore}
                      width={"100%"}
                    />
                  </Link>
                </Box>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default ForgotPassword;
