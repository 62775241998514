export const INVOICE_LIST = "invoice_list";
export const INVOICE_LIST_SUCCESSFULL = "invoice_list_successfull";
export const MATERIAL_DETAILS_BY_ID='material_details_by_id';
export const MATERIAL_DETAILS_BY_ID_SUCCESSFULL='material_details_by_id_successfull';

export const REGULAR_INVOICE_HEADER='regular_invoice_header';
export const CLEAR_REGULAR_INVOICE_HEADER='clear_regular_invoice_header';
export const REGULAR_INVOICE_LINE_ITEMS='regular_invoice_line_items';
export const CLEAR_REGULAR_INVOICE_LINE_ITEMS='clear_regular_invoice_line_items';
export const CREATE_REGULAR_INVOICE='create_regular_invoice';
export const CREATE_REGULAR_INVOICE_SUCCESSFULL='create_regular_invoice_successfull';
export const REGULAR_SALES_ORDER_VIEW_BYID_FOR_INVOICE ='regular_sales_order_view_byid_for_invoice';
export const REGULAR_SALES_ORDER_VIEW_BYID_FOR_INVOICE_SUCCESSFULL ='regular_sales_order_view_byid_for_invoice_successfull';

export const REGULAR_DELIVERY_ORDER_VIEW_BYID_FOR_INVOICE ='regular_delivery_order_view_byid_for_invoice';
export const REGULAR_DELIVERY_ORDER_VIEW_BYID_FOR_INVOICE_SUCCESSFULL ='regular_delivery_order_view_byid_for_invoice_successfull';

export const REGULAR_INVOICE_VIEW_BY_ID = "regular_invoice_view_by_id";
export const REGULAR_INVOICE_VIEW_BY_ID_SUCCESSFULL = "regular_invoice_view_by_id_successfull";

export const UPDATE_REGULAR_INVOICE ="update_regular_invoice";
export const UPDATE_REGULAR_INVOICE_SUCCESSFULL ="update_regular_invoice_successfull";

export const REGULAR_INVOICE_ITEMS_BY_ITEM_GROUP_ID ="regular_invoice_items_by_item_group_id";
export const REGULAR_INVOICE_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL ="regular_invoice_items_by_item_group_id_successfull";
export const REGULAR_INVOICE_ITEMS_DETAILS_BY_ITEM_ID ="regular_invoice_items_details_by_item_id";
export const REGULAR_INVOICE_ITEMS_DETAILS_BY_ITEM_ID_SUCCESSFULL ="regular_invoice_items_details_by_item_id_successfull";

// Jute Govt 
export const SALES_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE ='sales_order_view_byid_for_jute_govt_invoice';
export const SALES_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE_SUCCESSFULL ='sales_order_view_byid_for_jute_govt_invoice_successfull';

export const DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE ='Delivery_order_view_byid_for_jute_govt_invoice';
export const DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE_SUCCESSFULL ='Delivery_order_view_byid_for_jute_govt_invoice_successfull';

export const JUTE_GOVT_INVOICE_HEADER='jute_govt_invoice_header';
export const CLEAR_JUTE_GOVT_INVOICE_HEADER='clear_jute_govt_invoice_header';

export const JUTE_GOVT_INVOICE_LINE_ITEMS='jute_govt_invoice_line_items';
export const CLEAR_JUTE_GOVT_INVOICE_LINE_ITEMS='clear_jute_govt_invoice_line_items';

export const JUTE_GOVT_INVOICE_ITEMS_BY_ITEM_GROUP_ID ="jute_govt_invoice_items_by_item_group_id";
export const JUTE_GOVT_INVOICE_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL ="jute_govt_invoice_items_by_item_group_id_successfull";

export const CREATE_JUTE_GOVT_INVOICE='create_jute_govt_invoice';
export const CREATE_JUTE_GOVT_INVOICE_SUCCESSFULL='create_jute_govt_invoice_successfull';

export const JUTE_GOVT_INVOICE_VIEW_BY_ID = "jute_govt_invoice_view_by_id";
export const JUTE_GOVT_INVOICE_VIEW_BY_ID_SUCCESSFULL = "jute_govt_invoice_view_by_id_successfull";

export const UPDATE_JUTE_GOVT_INVOICE='update_jute_govt_invoice';
export const UPDATE_JUTE_GOVT_INVOICE_SUCCESSFULL='update_jute_govt_invoice_successfull';

export const GET_VEHICLE_TYPE = "get_Vehicle_type";
export const GET_VEHICLE_TYPE_SUCCESSFULL = "get_Vehicle_type_successfull";

/** For Jute Hessian */
export const SALES_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE  = "sales_order_view_byid_for_jute_hessian_invoice";
export const SALES_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE_SUCCESSFULL = "sales_order_view_byid_for_jute_hessian_invoicesuccessfull";

export const JUTE_HESSIAN_INVOICE_HEADER = "jute_hessian_invoice_header";
export const CLEAR_JUTE_HESSIAN_INVOICE_HEADER = "clear_jute_hessian_invoice_header";

export const JUTE_HESSIAN_INVOICE_LINE_ITEMS = "jute_hessian_invoice_line_items";
export const CLEAR_JUTE_HESSIAN_INVOICE_LINE_ITEMS = "clear_jute_hessian_invoice_line_items"

export const DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE ='Delivery_order_view_byid_for_jute_hessian_invoice';
export const DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE_SUCCESSFULL ='Delivery_order_view_byid_for_jute_hessian_invoice_successfull';

export const CREATE_JUTE_HESSIAN_INVOICE = "create_jute_hessian_invoice";
export const CREATE_JUTE_HESSIAN_INVOICE_SUCCESSFULL ="create_jute_hessian_invoices_successfull";

export const JUTE_HESSIAN_INVOICE_VIEW_BY_ID = "jute_hessian_invoice_view_by_id";
export const JUTE_HESSIAN_INVOICE_VIEW_BY_ID_SUCCESSFULL = "jute_hessian_invoice_view_by_id_successfull";

export const UPDATE_JUTE_HESSIAN_INVOICE = "update_jute_hessian_invoice";
export const UPDATE_JUTE_HESSIAN_INVOICE_SUCCESSFULL = "update_jute_hessian_invoice_successfull";


/**Jute Yarn */
export const JUTE_YARN_INVOICE_HEADER='jute_yarn_invoice_header';
export const CLEAR_JUTE_YARN_INVOICE_HEADER='clear_jute_yarn_invoice_header';

export const JUTE_YARN_INVOICE_LINE_ITEMS='jute_yarn_invoice_line_items';
export const CLEAR_JUTE_YARN_INVOICE_LINE_ITEMS='clear_jute_yarn_invoice_line_items';


export const SALES_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE ='sales_order_view_byid_for_jute_yarn_invoice';
export const SALES_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE_SUCCESSFULL ='sales_order_view_byid_for_jute_yarn_invoice_successfull';

export const DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE ='Delivery_order_view_byid_for_jute_yarn_invoice';
export const DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE_SUCCESSFULL ='Delivery_order_view_byid_for_jute_yarn_invoice_successfull';

export const API_ERRORS = 'api_failed';

// for upload files and delete files in invoice
export const UPLOAD_INVOICE_FILES = "upload_invoice_files";
export const UPLOAD_INVOICE_FILES_SUCCESSFULL = "upload_invoice_files_successfull";
export const GET_INVOICE_FILES= "get_invoice_files";
export const INVOICE_FILES_SUCCESSFULL= "invoice_files_successfull";
export const CLEAR_ALL_INVOICE_FILES ="clear_all_invoice_files";
export const DELETE_INVOICE_FILE = "delete_invoice_file";
export const DELETE_INVOICE_FILE_SUCCESSFULL = "delete_invoice_file_successfull";
export const CREATE_JUTE_YARN_INVOICE="create_jute_yarn_invoice";
export const CREATE_JUTE_YARN_INVOICE_SUCCESSFULL='create_jute_yarn_invoice_successfull';

export const INVOICE_JUTE_YARN_GET_UOM_RELATION_VALUE ="invoice_jute_yarn_get_uom_relation_value";
export const INVOICE_JUTE_YARN_GET_UOM_RELATION_VALUE_SUCCESSFULL ="invoice_jute_yarn_get_uom_relation_value_successfull";

// Invoice section E-invoice and E-way bill generation actions types
export const GENERATE_E_INVOICE = "generate_e_invoice";
export const CLEAR_E_INVOICE_MESSAGE = "clear_e_invoice_message";
export const CLEAR_E_WAY_BILL_MESSAGE = "clear_e_way_bill_message";

export const GENERATE_E_INVOICE_SUCCESSFULL = "generate_e_invoice_successfull";
export const GENERATE_E_WAY_BILL = "generate_e_way_bill";
export const GENERATE_E_WAY_BILL_SUCCESSFULL = "generate_e_way_bill_successfull";
export const INVOICE_JUTE_YARN_VIEW_BY_ID ="invoice_jute_yarn_view_by_id";
export const INVOICE_JUTE_YARN_VIEW_BY_ID_SUCCESSFULL ="invoice_jute_yarn_view_by_id_successfull";
export const UPDATE_JUTE_YARN_INVOICE='update_jute_yarn_invoice';
export const UPDATE_JUTE_YARN_INVOICE_SUCCESSFULL='update_jute_yarn_invoice_successfull';

export const INVOICE_JUTE_YARN_UOM_LIST="invoice_jute_yarn_uom_list";
export const INVOICE_JUTE_YARN_UOM_LIST_SUCCESSFULL="invoice_jute_yarn_uom_list_successfull";