import {
  CUSTOMER_PAGINATED_LIST,
  CUSTOMER_PAGINATED_LIST_SUCCESSFULL,
  CUSTOMER_MASTER_VIEW_BY_ID,
  CREATE_CUSTOMER_MASTER,
  CREATE_CUSTOMER_MASTER_SUCCESSFULL,
  API_ERRORS,
  CUSTOMER_MASTER_VIEW_BY_ID_SUCCESSFULL,
  CUSTOMER_LINE_ITEMS,
  CUSTOMER_MASTER_UPDATE,
  CUSTOMER_MASTER_UPDATE_SUCCESSFULL,
  GET_ALL_FILES,
  GET_ALL_FILES_SUCCESSFULL,
  DELETE_CUSTOMER_FILES,
  DELETE_CUSTOMER_FILES_SUCCESSFULL,
  UPLOAD_CUSTOMER_FILES,
  UPLOAD_CUSTOMER_FILES_SUCCESSFULL,
  GET_ALL_LEDGER_DATA,
  GET_ALL_LEDGER_DATA_SUCCESSFULL,
  ENTITY_TYPE_DATA,
  ENTITY_TYPE_DATA_SUCCESSFULL,
  CUSTOMER_GST_VERIFICATION,
  CUSTOMER_GST_VERIFICATION_SUCCESSFULL,
} from "./actionTypes";

export const getCustomerPaginatedList = (url, data, history) => {
  return {
    type: CUSTOMER_PAGINATED_LIST,
    payload: { url, data, history },
  };
};

export const CustomerPaginatedListSuccessfull = (data) => {
  return {
    type: CUSTOMER_PAGINATED_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const createCustomerMaster = (url, data, history) => {
  return {
    type: CREATE_CUSTOMER_MASTER,
    payload: { url, data, history },
  };
};

export const CreateCustomerMasterSuccessfull = (data) => {
  return {
    type: CREATE_CUSTOMER_MASTER_SUCCESSFULL,
    payload: data,
  };
};
export const CustomerLineItemsProps = (data) => {
  console.log(data);
  return {
    type: CUSTOMER_LINE_ITEMS,
    payload: data,
  };
};
export const customerMasterViewById = (url, data, history) => {
  return {
    type: CUSTOMER_MASTER_VIEW_BY_ID,
    payload: { url, data, history },
  };
};

export const CustomerMasterViewByIdSuccessfull = (data) => {
  return {
    type: CUSTOMER_MASTER_VIEW_BY_ID_SUCCESSFULL,
    payload: data,
  };
};
export const setCustomerMasterUpdate = (
  url,
  data,
  history,
  isSales,
  customerId
) => {
  return {
    type: CUSTOMER_MASTER_UPDATE,
    payload: { url, data, history, isSales, customerId },
  };
};

export const setCustomerMasterUpdateSuccessfull = (data) => {
  return {
    type: CUSTOMER_MASTER_UPDATE_SUCCESSFULL,
    payload: data,
  };
};

export const getFilesData = (url, history) => {
  return {
    type: GET_ALL_FILES,
    payload: { url, history },
  };
};

export const getFilesDataSuccessfull = (data) => {
  return {
    type: GET_ALL_FILES_SUCCESSFULL,
    payload: data,
  };
};

export const deleteCustomerMasterFiles = (url, history, id) => {
  return {
    type: DELETE_CUSTOMER_FILES,
    payload: { url, history, id },
  };
};

export const deleteCustomerMasterFilesSuccessfull = (data) => {
  return {
    type: DELETE_CUSTOMER_FILES_SUCCESSFULL,
    payload: data,
  };
};
export const UploadCustomerMasterFiles = (url, data, history, id) => {
  return {
    type: UPLOAD_CUSTOMER_FILES,
    payload: { url, data, history, id },
  };
};

export const UploadCustomerMasterFilesSuccessfull = (data) => {
  return {
    type: UPLOAD_CUSTOMER_FILES_SUCCESSFULL,
    payload: data,
  };
};
export const getAllLedgerData = (url, history) => {
  return {
    type: GET_ALL_LEDGER_DATA,
    payload: { url, history },
  };
};

export const getAllLedgerDataSuccessfull = (data) => {
  return {
    type: GET_ALL_LEDGER_DATA_SUCCESSFULL,
    payload: data,
  };
};
export const getEntityTypeData = (url, history) => {
  return {
    type: ENTITY_TYPE_DATA,
    payload: { url, history },
  };
};
export const getEntityTypeDataSuccessfull = (data) => {
  return {
    type: ENTITY_TYPE_DATA_SUCCESSFULL,
    payload: data,
  };
};

export const getCustomerGstVerification = (url, id, history) => {
  return {
    type: CUSTOMER_GST_VERIFICATION,
    payload: { url, id, history },
  };
};

export const getCustomerGstVerificationSuccessfull = (data) => {
  return {
    type: CUSTOMER_GST_VERIFICATION_SUCCESSFULL,
    payload: data,
  };
};
export const apiErrors = (error) => {
  return {
    type: API_ERRORS,
    payload: error,
  };
};
