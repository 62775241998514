import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import { getReOccuringInvoiceList } from "../../../store/Sales/ReOccuringInVoice/actions";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      ReOccuringInvoices: [],
      ReOccuringInvoiceColumns: [
        {
          Header: "Customer/Broker",
          accessor: "customerName",
          hidden: false,
        },
      ],
    };
  }

  componentDidMount() {}
  componentWillReceiveProps(props) {}

  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.ReOccuringInvoices}
          mcolumns={this.state.ReOccuringInvoiceColumns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          url="/create_delivery_order"
        />
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { ReOccuringInvoiceList } = state.ReOccuringInvoiceReducer;
  return { ReOccuringInvoiceList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getReOccuringInvoiceList,
  })(Index)
);
