import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import { getItemList } from "../../../store/Master/ItemMaster/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      itemListData: [],
      groupCode: null,
      isActive: null,
      itemCode: null,
      itemGroupName: null,
      itemName: null,
      dataexp: "",
    };
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      groupCode: "",
      isActive: "",
      itemCode: "",
      itemGroupName: "",
      itemName: "",
      startIndex: 1,
      lastIndex: 10,
    };

    this.props.getItemList(
      serverApi.GET_ALL_ITEM_LIST,
      data,
      this.props.history
    ); // calling  list Api for item master
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.Item_List) {
      this.setState({
        totalRecs: props.Item_List.totalRecords,
        itemListData: props.Item_List.data, // updating list api data
      });
    }
  }
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const data = {
        cipher: this.state.userDit.cipher,
        companyId: localStorage.getItem("companyId"),
        startIndex: sizePerPage,
        lastIndex: currentIndex,
        groupCode: this.state.groupCode,
        isActive: this.state.isActive,
        itemCode: this.state.itemCode,
        itemGroupName: this.state.itemGroupName,
        itemName: this.state.itemName,
      };
      if (currentIndex >= 0) {
        this.props.getItemList(
          serverApi.GET_ALL_ITEM_LIST,
          data,
          this.props.history
        ); // CALLING item MASTER LIST API
      }
    } else {
      const data = {
        cipher: this.state.userDit.cipher,
        companyId: localStorage.getItem("companyId"),
        startIndex: currentIndex === 0 ? currentIndex + 1 : currentIndex,
        lastIndex: sizePerPage,
        groupCode: this.state.groupCode,
        isActive: this.state.isActive,
        itemCode: this.state.itemCode,
        itemGroupName: this.state.itemGroupName,
        itemName: this.state.itemName,
      };
      if (currentIndex >= 0) {
        this.props.getItemList(
          serverApi.GET_ALL_ITEM_LIST,
          data,
          this.props.history
        ); // CALLING item MASTER LIST API
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };

  handleSearch = (searchData) => {
    var data = {
      cipher: searchData.cipher,
      companyId: searchData.companyId,
      groupCode: searchData.groupCode,
      isActive: searchData.isActive,
      itemCode: searchData.itemCode,
      itemGroupName: searchData.itemGroupName,
      itemName: searchData.itemName,
      lastIndex: searchData.lastIndex,
      startIndex: searchData.startIndex,
    };
    this.setState({
      groupCode: searchData.groupCode,
      isActive: searchData.isActive,
      itemCode: searchData.itemCode,
      itemGroupName: searchData.itemGroupName,
      itemName: searchData.itemName,
    });

    this.props.getItemList(
      serverApi.GET_ALL_ITEM_LIST,
      data,
      this.props.history
    ); // calling  list Api for item master

    // showSpinner()
  };

  render() {
    return (
      <>
        <PaginationGridView
          mdata={this.state.itemListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={false}
          actions={true}
          filter_form={"ItemMaster"}
          handleSearch={this.handleSearch}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          url="/create_item_master"
          apiexp={serverApi.GET_ALL_ITEM_LIST}
          payloadexp={this.state.dataexp}
          expName={"Item-Master.csv"}
        />
      </>
    );
  }
}
const Columns = [
  {
    Header: "Item Code",
    accessor: "itemCode",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Item Description",
    accessor: "itemDescription",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Group Code",
    accessor: "grpCode",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Group Description",
    accessor: "groupDes",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "UOM Code",
    accessor: "uomCode",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "HSN Code",
    accessor: "hsnCode",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Tax",
    accessor: "tax",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Direct/Indirect",
    accessor: "directIndirectTag",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Legacy Item Code",
    accessor: "legacyItemCode",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Item Rate",
    accessor: "srRate",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "status",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
];

const mapStatetoProps = (state) => {
  const { Item_List } = state.ItemListReducer; // fetching itemlist from reducer
  return { Item_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getItemList,
  })(Index)
);
