import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import { getItemGroupMasterList } from "../../../store/Master/ItemGroupMaster/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      itemGroupListData: [],
      startIndex: 1,
      lastIndex: 10,
      // itemGroupListColumns: [],
      totalRecords: 0,
      itemGrpCode:null,
      itemGrpDsc:null,
      dataexp:"",
    };
  }

  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      userId: this.state.userDit.userId,
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
    };
    this.props.getItemGroupMasterList(
      serverApi.GET_ALL_ITEM_GROUP_LIST,
      data,
      this.props.history
    );
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.ItemGroup_List) {
      this.setState({
        itemGroupListData: props.ItemGroup_List.data,
        totalRecords: props.ItemGroup_List.totalRecords,
      });
    }
  }

  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const data = {
        companyId: localStorage.getItem("companyId"),
        cipher: this.state.userDit.cipher,
        startIndex: sizePerPage,
        lastIndex: currentIndex,
      itemGrpCode: this.state.itemGrpCode === "" ? null : this.state.itemGrpCode,
      itemGrpDsc: this.state.itemGrpDsc === "" ? null : this.state.itemGrpDsc,
      };
      if (currentIndex >= 0) {
        this.props.getItemGroupMasterList(serverApi.GET_ALL_ITEM_GROUP_LIST, data, this.props.history); // calling pagination api for item group master
      }
    } else {
      const data = {
        companyId: localStorage.getItem("companyId"),
        cipher: this.state.userDit.cipher,
        startIndex: currentIndex ===0?currentIndex+1:currentIndex,
        lastIndex: sizePerPage,
        itemGrpCode: this.state.itemGrpCode === "" ? null : this.state.itemGrpCode,
      itemGrpDsc: this.state.itemGrpDsc === "" ? null : this.state.itemGrpDsc,
      };
      if (currentIndex >= 0) {
        this.props.getItemGroupMasterList(serverApi.GET_ALL_ITEM_GROUP_LIST, data, this.props.history);  // calling Pagination api for item group master
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage
    })
  }

  handleSearch = (data) => {
    
    const searchData = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      userId: this.state.userDit.userId.toString(),
      itemGrpCode: data.itemGroupCode === "" ? null : data.itemGroupCode,
      itemGrpDsc: data.itemGroupDesc === "" ? null : data.itemGroupDesc,
      startIndex: "1",
      lastIndex: "10",
    };
    this.setState({
      itemGrpCode: data.itemGroupCode === "" ? null : data.itemGroupCode,
      itemGrpDsc: data.itemGroupDesc === "" ? null : data.itemGroupDesc,
    })
    this.props.getItemGroupMasterList(
      serverApi.GET_ALL_ITEM_GROUP_LIST,
      searchData,
      this.props.history
    );

  };
  render() {
    return (
      <>
        <PaginationGridView
          mdata={this.state.itemGroupListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={false}
          actions={true}
          url="/create_item_group_master"
          filter_form={"itemGroupMaster"}
          handleSearch={this.handleSearch}
          totalRecs={this.state.totalRecords}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          apiexp={serverApi.GET_ALL_ITEM_GROUP_LIST}
          payloadexp={this.state.dataexp}
          expName={"Item-group-Master.csv"}
        />
      </>
    );
  }
}
const Columns = [
  {
    Header: "Item group code",
    accessor: "id",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Item group Description",
    accessor: "grpDsc",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
];

const mapStatetoProps = (state) => {
  const { ItemGroup_List } = state.ItemGroupListReducer;
  return { ItemGroup_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getItemGroupMasterList,
  })(Index)
);
