import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import CustomButton from "../../../../../components/Buttons/Buttons";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi } from "../../../../../helpers/Consts";
import { setPersonalDetails } from "../../../../../store/HRMS/EmployeeDataBase/actions";
import moment from "moment";
import TextFieldNormal from "../../../../../components/TextField/TextFieldNormal";
import swal from "sweetalert";
import {
  viewbyidPersonalDetails,
  viewbyidPersonalDetailsSuccessfull,
  getWorkerData,
} from "../../../../../store/HRMS/EmployeeDataBase/actions";
import TextFieldReq from "../../../../../components/TextField/TextFieldReq";
class ProvidentFund extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      tblHrmsEdPfId: null,
      ebId: this.props.getEbId,
      isActive: 1,
      updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
      updatedTime: "",
      pfDateOfJoining: null,
      pfNo: "",
      pfUanNo: "",
      pfTransferNo: null,
      pfPreviousNo: "",
      nomineeName: null,
      relationshipName: null,
      dateOfJoin: null,
      UanValidation: false,
    };
  }

  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key == "pfUanNo") {
      if (event.length < 12 || event.length > 12) {
        this.setState({
          UanValidation: true,
        });
      } else {
        this.setState({
          pfUanNo: event,
          UanValidation: false,
        });
      }
    }
  };
  handleSelectDate = (e, name) => {
    if (name === "Date") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      if (Date < this.state.dateOfJoin) {
        swal("EPF Joing Date cannot be less than joining Date");
      } else {
        this.setState({
          pfDateOfJoining: Date,
        });
      }
    }
  };

  componentWillReceiveProps(props) {
    var ebId = this.props.getEbId;
    if (ebId > 0) {
      if (props.personalDetails) {
        if (props.personalDetails.data) {
          var personal = props.personalDetails.data;
          if (personal) {
            Object.keys(personal).map((key, index) => {
              this.setState({ [key]: personal[key] });
            });
          }
        }
      }
    }
    if (props.Get_Worker_Details) {
      if (props.Get_Worker_Details.data) {
        this.setState({
          dateOfJoin: props.Get_Worker_Details.data.dateOfJoin,
        });
      }
    }
  }
  componentDidMount() {
    var ebId = this.props.getEbId;
    if (ebId > 0) {
      this.props.viewbyidPersonalDetails(
        serverApi.VIEW_PF_DETAILS_BY_ID + "/" + this.props.getEbId,
        this.props.history,
        0
      );
    } else {
      const response = { data: null };
      this.props.viewbyidPersonalDetailsSuccessfull({ response });
    }
    this.props.getWorkerData(
      serverApi.VIEW_EMPLOYEE_WORKER_DIT + "/" + this.props.getEbId,
      this.props.history
    );
  }
  onClickSave = () => {
    if (this.state.UanValidation) {
      swal("Please Enter the Valid UAN Number", { icon: "error" });
    } else {
      const data = {
        tblHrmsEdPfId: this.state.tblHrmsEdPfId,
        ebId: this.props.getEbId,
        updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
        pfDateOfJoining: this.state.pfDateOfJoining,
        pfNo: this.state.pfNo,
        pfUanNo: this.state.pfUanNo,
        pfTransferNo: this.state.pfTransferNo,
        pfPreviousNo: this.state.pfPreviousNo,
        nomineeName: this.state.nomineeName,
        relationshipName: this.state.relationshipName,
      };
      this.props.setPersonalDetails(
        serverApi.UPDATE_PF_DETAILS,
        data,
        this.props.history,
        "pf"
      );
    }
  };
  render() {
    return (
      <>
        <div className="providentFund">
          <h1>Provident Fund</h1>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="PF Account Number (Previous)"
                        caps="ON"
                        placeholder="Enter here"
                        value={this.state.pfPreviousNo}
                        onChange={this.handleChange("pfPreviousNo")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        value={this.state.pfNo}
                        label="PF Account Number (Current)"
                        caps="ON"
                        placeholder="Enter here"
                        onChange={this.handleChange("pfNo")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        value={this.state.pfUanNo}
                        label="UAN"
                        placeholder="10 digit number"
                        onChange={this.handleChange("pfUanNo")}
                        type="number"
                      />
                      {this.state.UanValidation && (
                        <div className="TextFieldBlock">
                          <span className="text-danger">
                            Please Enter 12 digit UAN Number
                          </span>
                        </div>
                      )}
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        value={this.state.pfTransferNo}
                        label="PF Transfer Number"
                        placeholder="PF Transfer Number"
                        onChange={this.handleChange("pfTransferNo")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>EPF Joining Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          name="Date"
                          inputFormat="dd-MM-yyyy"
                          id="financial_year"
                          value={this.state.pfDateOfJoining}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "Date")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        value={this.state.nomineeName}
                        label="Nominee Name"
                        placeholder="Enter Here"
                        onChange={this.handleChange("nomineeName")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        value={this.state.relationshipName}
                        label="Relationship Name"
                        placeholder="Enter Here"
                        onChange={this.handleChange("relationshipName")}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div className="consoleFormButtonBlock">
            <Link to="/add_employee">
              <CustomButton label={"Cancel"} className="greenBorderButton" />
            </Link>
            {localStorage.getItem("EmpData") === null ? (
              <CustomButton
                label={"Save"}
                className="greenButton"
                handleClick={this.onClickSave}
                type="sumbit"
              />
            ) : (
              <CustomButton
                label={"Update"}
                className="greenButton"
                handleClick={this.onClickSave}
                type="sumbit"
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { personalDetails, getEbId, Get_Worker_Details } =
    state.EmployeeReducer;
  return { personalDetails, getEbId, Get_Worker_Details };
};

export default withRouter(
  connect(mapStatetoProps, {
    setPersonalDetails,
    viewbyidPersonalDetails,
    viewbyidPersonalDetailsSuccessfull,
    getWorkerData,
  })(ProvidentFund)
);
