import {WORKER_CATEGORY_LIST,WORKER_CATEGORY_LIST_SUCCESSFULL,CREATE_WORKER_CATEGORY_SUCCESSFULL,CREATE_WORKER_CATEGORY,
    UPDATE_WORKER_CATEGORY,UPDATE_WORKER_CATEGORY_SUCCESSFULL,
    API_ERRORS,} from './actionTypes';

export const workerCategoryList = (url,data,history) =>{
    return {
        type:WORKER_CATEGORY_LIST,
        payload:{url,data,history}
    }
}
export const workerCategoryListSuccessfull = (data)=>{
    return {
        type:WORKER_CATEGORY_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createWorkerCategory = (url, data, history) => {
    return {
        type: CREATE_WORKER_CATEGORY,
        payload: { url, data, history }
    }
}

export const createWorkerCategorySuccessfull = (data) => {
    return {
        type: CREATE_WORKER_CATEGORY_SUCCESSFULL,
        payload: data
    }
}
export const updateWorkerCategory = (url, data, history) => {
    return {
        type: UPDATE_WORKER_CATEGORY,
        payload: { url, data, history }
    }
}

export const updateWorkerCategorySuccessfull = (data) => {
    return {
        type: UPDATE_WORKER_CATEGORY_SUCCESSFULL,
        payload: data
    }
}
export const apiErrors = (error) => {
    return {
        type: API_ERRORS,
        payload: error,
    };
};