import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import { withRouter } from "react-router-dom";
import { ClearJuteQualityCheckLineItems, JuteQualityCheck } from "../../../store/Jute/JuteQualityCheck/actions";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";

const JuteQualityCheckIndex2 = (props) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    userDit: JSON.parse(localStorage.getItem("authUser")),
    companyId: localStorage.getItem("companyId"),
    startIndex: "",
    lastIndex: "",
    JuteQualityCheckData: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = {
        frmDt: "",
        toDt: "",
        status: "",
        supCode: "",
        gateEntryNum: "",
        vehicleNum: "",
        companyId: localStorage.getItem("companyId"),
        cipher: state.userDit.cipher,
        financialYear: localStorage.getItem("acadamicYear"),
      };

      props.JuteQualityCheck(
        serverApi.JUTE_QUALITY_CHECK_LIST,
        data,
        props.history
      );
      showSpinner();
      dispatch(ClearJuteQualityCheckLineItems())
    };

    fetchData();
    
  }, []);

  useEffect(() => {
    if (props.JuteQualityCheckData) {
      setState((prevState) => ({
        ...prevState,
        JuteQualityCheckData: props.JuteQualityCheckData.data,
      }));
      hideSpinner();
    }
  }, [props.JuteQualityCheckData]);

  const handleSearch = (searchData) => {
    const data = {
      frmDt: searchData.frmDt,
      toDt: searchData.toDt,
      supCode: searchData.supplier,
      vehicleNum: searchData.vehicleValue,
      status: "",
      gateEntryNum: searchData.gateEntryNum,
      companyId: localStorage.getItem("companyId"),
      cipher: state.userDit.cipher,
      financialYear: localStorage.getItem("acadamicYear"),
    };
    props.JuteQualityCheck(
      serverApi.JUTE_QUALITY_CHECK_LIST,
      data,
      props.history
    );
    showSpinner();
  };

  return (
    <>
      <Gridwithcustomview
        mdata={state.JuteQualityCheckData}
        mcolumns={Columns}
        status={true}
        micon={GridArrowIcon}
        actions={true}
        filter_form={"JuteQualityCheck"}
        handleSearch={handleSearch}
        url="/update_jute_quality_check"
      />
    </>
  );
};

const Columns = [
  {
    Header: "Ref No",
    accessor: "gateEntryNum",
    hidden: false,
  },
  {
    Header: "Entry Date",
    accessor: "chalanDate",
    hidden: false,
  },
  {
    Header: "Vehicle No",
    accessor: "vehicleNo",
    hidden: false,
  },
  {
    Header: "Supplier",
    accessor: "supplierName",
    hidden: false,
  },
  {
    Header: "Driver",
    accessor: "driver",
    hidden: false,
  },
];

const mapStateToProps = (state) => {
  const { JuteQualityCheckData } = state.JuteQualityCheckReducer;
  return { JuteQualityCheckData };
};

export default withRouter(
  connect(mapStateToProps, {
    JuteQualityCheck,
  })(JuteQualityCheckIndex2)
);
