import React, { Component } from "react";
import CustomButton from "../../../components/Buttons/Buttons";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi, getTenantId,serverVars } from "../../../helpers/Consts";
import { getIndentPdf } from "../../../store/Purchase/Indent/actions";
import { getFileDownload } from "../../../helpers/server.js";
import { InwardLineItemsProps } from "../../../store/Purchase/Inward/actions";
import { Box, Grid, } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import { getFilesofInward } from "../../../store/Purchase/Inward/actions";


class MIPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      date: new Date().toLocaleDateString(),
      currentStep: 1,
      companyLogo: "",
      source: "",
      Inward_LineItems: [],
      material_inspecFilesList:[],
      RowData: "",
      supplierName: "",
      SequenceNo: "",
      TodayDate: moment().format(),
      locationState:""
    };
  }
  componentDidMount() {
    let companyData = JSON.parse(localStorage.getItem("authUser"));
    let companyId = localStorage.getItem("companyId");
    companyData.companyDetails.map((p) => {
      let id = p.value;
      if (companyId === id.toString()) {
        let companyName = p.label.split("-");
        this.setState({
          companyName: companyName[1],
          address1: p.address1,
          companyLogo: p.companyLogo,
          gstNo: p.gstNo,
          address2: p.address2,
          comName: p.name,
          phoneNo: p.contactNo,
          email: p.emailId,
        });
      }
    });

    if (this.props.locationState.state !==undefined) {
      let id = this.props.locationState.state.rowData.id;
      this.props.getFilesofInward(
        serverApi.GET_FILES +
          18 +
          "/" +
          id +
          "/" +
          localStorage.getItem("companyId"),
        this.props.history,
        18,
        "MI"
      );
    }
    if (this.props.Inward_LineItems) {
      let inwardLineitems = this.props.Inward_LineItems.filter(
        (item) => item.departmentId !== ""
      );
      this.setState({
        Inward_LineItems: inwardLineitems,
      });
    }
    if (this.props.locationState) {
      if (this.props.locationState.state !== undefined) {
          if (this.props.locationState.state.rowData) {
          this.setState({
            RowData: this.props.locationState.state.rowData,
          });
          if (this.props.locationState.pathname === "/create_material_inspection") {
            this.setState({
              RowData: this.props.locationState.state.rowData,
              supplierName: this.props.locationState.state.rowData.supplierName,
              SequenceNo: this.props.locationState.state.rowData.inwardSequenceNo,
            });
          }
          if (this.props.locationState.pathname === "/create_WorkReport") {
            this.setState({
              RowData: this.props.locationState.state.rowData,
              supplierName: this.props.locationState.state.rowData.supplierName,
              SequenceNo: this.props.locationState.state.rowData.inwardSequenceNo,
            });
          }
          if (this.props.locationState.pathname === "/create_inward") {
            this.setState({
              RowData: this.props.locationState.state.rowData,
              supplierName: this.props.locationState.state.rowData.supplierName,
              SequenceNo: this.props.locationState.state.rowData.inwardSequenceNo,
            });
          }
          if (this.props.locationState.pathname === "/create_store_bill_pass") {
            this.setState({
              RowData: this.props.locationState.state.rowData,
              supplierName: this.props.locationState.state.rowData.supplierName,
              SequenceNo: this.props.locationState.state.rowData.billpassNumber,
            });
          }
          if (this.props.locationState.pathname === "/Create_store_receipt") {
            this.setState({
              RowData: this.props.locationState.state.rowData,
              supplierName: this.props.locationState.state.rowData.supplierName,
              SequenceNo: this.props.locationState.state.storeReceiptNo,
            });
          }
        }
      }
      if (this.props.locationState.pathname === "/create_inward") {
        this.setState({
          source: "INWARD RECEIPT",
        });
      }
    }
  }
  componentWillReceiveProps(props){
    if (props.mifilesList) {
      if (props.mifilesList.data) {
        let filesList = props.mifilesList.data;
        this.setState({
          material_inspecFilesList: filesList.data,
        });
      }
    }
  }
  getFilesDownload = (fileUploadId, fileName, fileExtension) => {
    let url = serverVars.SECURITY_API + "files/downloadfile/" + fileUploadId;
    getFileDownload(url, fileName, fileExtension);
  };
  handleEdit = () => {
    this.props.handleCurrentStep(this.state.currentStep);
  };
  handlePdf = () => {
    let Id = this.state.RowData.id;
    // this.props.getFileDownload(serverApi.DOWNLOAD_PDF+indentId+'/'+this.state.userDit.userId)
    let taskId = 0;
    if (this.props.locationState.pathname === "/create_material_inspection") {
      taskId = 4;
    }
    if (this.props.locationState.pathname === "/create_WorkReport") {
      taskId = 38;
    }
    if (this.props.locationState.pathname === "/create_inward") {
      taskId = 34;
    }
    if (this.props.locationState.pathname === "/create_store_bill_pass") {
      taskId = 7;
    }
    if (this.props.locationState.pathname === "/Create_store_receipt") {
      taskId = 3;
    }

    fetch(
      serverApi.DOWNLOAD_PDF +
        Id +
        "/" +
        this.state.userDit.userId +
        "/" +
        taskId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          "X-TenantId": getTenantId(window.location.hostname),
          Authorization:
            localStorage.getItem("token_type") +
            localStorage.getItem("access_token"),
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          this.state.SequenceNo + "_" + this.state.supplierName + ".pdf"
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  render() {
    var DateTime = this.state.TodayDate.split("T", 2);
    var TodayDate = moment(DateTime[0], "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var Time = DateTime[1].split("+")[0];

    var filteredproducts = this.state.Inward_LineItems.filter(
      (item) => item.isActive !== 0
    );
    
    let Inward_LineItems = filteredproducts.sort((a, b) =>
    a.lineitemId > b.lineitemId ? -1 : 1
  );
    return (
      <>
        <div className="previewBlock">
          <div
            style={{ display: "flex", justifyContent: "end", padding: "10px" }}
          >
            {this.props.locationState.state && (
              <CustomButton
                label="Print"
                className="lightBlueButton"
                muIcon={<PrintIcon />}
                handleClick={this.handlePdf}
                type="button"
              />
            )}
            {this.props.locationState.state !== undefined && (this.props.InwardFillDetails.statusName === "REJECTED" || this.props.InwardFillDetails.statusName ==="APPROVED" || this.props.InwardFillDetails.statusName ==="PENDING APPROVAL LEVEL 2") ?"":
            <CustomButton
              label="Edit"
              className="lightBlueButton"
              muIcon={<EditIcon />}
              handleClick={this.handleEdit}
              type="button"
            />}
          </div>
          <table
            cellpadding="0"
            cellspacing="0"
            width="90%"
            className="previewTable"
          >
            <tbody>
              <tr>
                <td
                  colspan="3"
                  style={{ borderRight: "none", borderBottom: "none" }}
                >
                  {/* <h3>{this.state.companyName}</h3> */}
                  <img src={this.state.companyLogo} width="100%" alt="" />
                </td>
                <td
                  colspan="10"
                  style={{
                    borderBottom: "none",
                    borderLeft: "none",
                    textAlign: "right",
                  }}
                >
                  <h2 style={{ margin: "0px" }}> {this.state.companyName}</h2>
                  <p style={{ margin: "0px" }}>{this.state.address1}</p>
                  <span>GST Number:{this.state.gstNo}</span>
                </td>
              </tr>
              <tr>
                <td
                  colspan="13"
                  style={{ textAlign: "center", borderTop: "none" }}
                >
                  <h2> MATERIAL INSPECTION REPORT</h2>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Entry Number: </b>
                </td>
                <td colspan="2">
                  <b>{this.props.InwardFillDetails.GateEntryNo}</b>
                </td>
                <td colspan="2">
                  <b>Date Time: </b>
                </td>
                <td colspan="6">
                  <b>
                    {moment(this.props.InwardFillDetails.inwardDate).format(
                      "DD-MM-YYYY"
                    )}
                  </b>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Challan Number: </b>
                </td>
                <td colspan="2">
                  <b>{this.props.InwardFillDetails.challanno}</b>
                </td>
                <td colspan="2">
                  <b>Challan Date: </b>
                </td>
                <td colspan="4">
                  <b>
                    {moment(this.props.InwardFillDetails.challannoDate).format(
                      "DD-MM-YYYY"
                    )}
                  </b>
                </td>
              </tr>
              <tr align="center" valign="top">
                <td  >
                  <b>Item Code</b>
                </td>
                <td  >
                  <b>Department</b>
                </td>
                <td  >
                  <b>Item Group</b>
                </td>
                <td  >
                  <b>Item</b>
                </td>
                <td  >
                  <b>Quantity</b>
                </td>
                <td >
                  <b>Unit</b>
                </td>
                <td  >
                  <b>Approved Quantity</b>
                </td>
                <td  >
                  <b>Rejected Quantity</b>
                </td>
                <td  >
                  <b>Reason</b>
                </td>
              </tr>
              {Inward_LineItems.map((data, index) => (
                <tr key={index}>
                   <td>
                    {data.itemGroup}
                    {data.itemCode}
                  </td>
                  <td>{data.departmentName}</td>
                  <td>{data.itemGroupName}</td>
                  <td>{data.itemName}</td>
                  <td style={{ textAlign: "right" }}> {data.itemGroup==="997"?1:parseFloat(data.inwardQty).toFixed(3)}</td>
                  <td>{data.uom?data.uom:data.uomCode}</td>
                  <td align="right">{data.itemGroup==="997"?1:data.approvedQty ===null?parseFloat(data.inwardQty).toFixed(3):parseFloat(data.approvedQty).toFixed(3)}</td>
                  {/* <td align="right">{data.itemGroup==="997"?1:parseFloat(data.approvedQty).toFixed(2)}</td> */}
                  <td align="right">{data.rejectedQty === null ?"0.000":parseFloat(data.rejectedQty).toFixed(3)}</td>
                  <td align="right">{data.reason}</td>
                </tr>
              ))}
              <tr>
                <td
                  colspan="13"
                  // align="left"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                 { this.props.locationState.state !==undefined ? <b>Status: {this.props.InwardFillDetails.statusName}</b>:""}
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  colspan="13"
                  style={{ borderTop: "none", borderBottom: "none" }}
                >
                  <span><b>Remarks :</b> {this.props.InwardFillDetails.remarks}</span>
                  <br/>
                  <span><b>Internal Note :</b> {this.props.InwardFillDetails.internalNote}</span>
                </td>
              </tr>
              <tr>
                <td
                  colspan="6"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {this.props.locationState.state && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box gridColumn="span 12">
                          {this.state.material_inspecFilesList.length !== 0 &&(<span>
                            <b>Uploaded Documents</b>
                          </span>)}
                        </Box>
                        <Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={2}
                          className="ducumentUploadBlock uploadedContent"
                          style={{ padding: "10px" }}
                        >
                          {this.state.material_inspecFilesList &&
                            this.state.material_inspecFilesList.map((item) => (
                              <Box gridColumn="span 6">
                                <div className="documentUploadContent">
                                  <div
                                    className="uploadedInfo"
                                    style={{ display: "flex" }}
                                  >
                                    <img
                                      src={TickIcon}
                                      alt=""
                                      className="tickIcon"
                                      height={"20px"}
                                      width={"20px"}
                                    />
                                    <Tooltip
                                      TransitionComponent={Fade}
                                      TransitionProps={{ timeout: 600 }}
                                      title="Click to download File"
                                    >
                                      <div
                                        className="uplodedFileName"
                                        onClick={() => {
                                          this.getFilesDownload(
                                            item.fileUploadId,
                                            item.fileName,
                                            item.fileExtension
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.fileName}
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </Box>
                            ))}
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Inward_LineItems,mifilesList } = state.InwardReducer;
  return {
    Inward_LineItems,
    mifilesList
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getIndentPdf,
    getFilesofInward,
    getFileDownload,
    InwardLineItemsProps,
  })(MIPreview)
);
