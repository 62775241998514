import {
  CUTS_FROM_CUTS,
  WEAVING_LIST,
  WEAVING_PRODUCTION_ADD,
  WEAVING_PRODUCTION_DELETE,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getListurl, getList } from "../../../helpers/Server_Helper";
import {
  weavingListSuccessfull,
  cutsListActionSuccessfull,
  WeavingProductionCreateActionSuccessfull,
  WeavingProductionDeleteSuccessfull,
  weavingList,
} from "./actions";
import swal from "sweetalert";
import { serverApi } from "../../../helpers/Consts";

function* weavingProductionListSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(weavingListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* CutsListSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(cutsListActionSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* WeavingProductionCreateSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);

    yield put(WeavingProductionCreateActionSuccessfull({ response }));
    if (response.status === true) {
      swal(response.message, { icon: "success" });
      history.push("/pr_weaving_production");
    } else {
      swal(response.message, { icon: "error" });
    }
  } catch (error) {
    console.log(error);
  }
}

function* weavingProductionDeleteSaga({
  payload: { url, history, fromDate, toDate },
}) {
  try {
    const response = yield call(getListurl, url);
    yield put(WeavingProductionDeleteSuccessfull({ response }));

    swal(response.data.message, { icon: "success" });

    if (response.data.status === true) {
      const data = {
        companyId: localStorage.getItem("companyId"),
        fromDate: fromDate,
        toDate: toDate,
      };
      var listApi = serverApi.GET_WEAVING_PRODUCTION;
      yield put(weavingList(listApi, data, history));
    }
  } catch (error) {
    console.log(error);
  }
}
export function* watchWeavingProdList() {
  yield takeEvery(WEAVING_LIST, weavingProductionListSaga);
  yield takeEvery(CUTS_FROM_CUTS, CutsListSaga);
  yield takeEvery(WEAVING_PRODUCTION_ADD, WeavingProductionCreateSaga);
  yield takeEvery(WEAVING_PRODUCTION_DELETE, weavingProductionDeleteSaga);
}

function* WeavingProd() {
  yield all([fork(watchWeavingProdList)]);
}

export default WeavingProd;
