import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import InputAdormentButton from "../../../../../components/InputAdorment/InputAdormentButton";
import exportIcon from "../../../../../assets/images/exportIcon.png";
import CustomButton from "../../../../../components/Buttons/Buttons";
import Link from "@mui/material/Link";

class AadharVerification extends Component {
  render() {
    return (
      <>
        <div className="aadharVerificationBlock">
          <div className="TextFieldBlock">
            <label>Aadhar Card No.</label>
          </div>
          <InputAdormentButton imgUrl={exportIcon} placeholder="12 digits" />
          <div className="aadharVerificationContent">
            <p>Not having the aadhar card?</p>
            <a hrf="#">Manual Fill</a>
          </div>
          <div style={{ height: "35px" }}></div>
          <div className="consoleFormButtonBlock">
            <Link>
              <CustomButton
                label={"Cancel"}
                className="greenBorderButton"
                handleClick={this.onClickBefore}
              />
            </Link>
            <CustomButton
              label={"Send OTP"}
              className="greenButton"
              handleClick={this.onClickNext}
              type="sumbit"
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {};

export default withRouter(connect(mapStatetoProps, {})(AadharVerification));
