import {PURCHASE_ORDER_LIST,PURCHASE_ORDER_LIST_SUCCESSFULL,API_ERRORS, PO_WO_LINEITEMS, INDENT_VIEW_BY_ID, INDENT_VIEW_BY_ID_SUCCESSFULL, PURCHASE_ORDER_HEADER, GET_ITEM_DETAILS_BY_POID, GET_ITEM_DETAILS_BY_POID_SUCCESSFULL
,GET_ITEMLIST_BY_ITEM_GROUP_FOR_PURCHASE,GET_ITEM_LIST_BY_ITEM_GROUP_FOR_PURCHASE_SUCCESSFULL,GET_ITEMDETAILS_BYITEM_ITEMGRP_FOR_PURCHASE, GET_ITEMDETAILS_BYITEM_ITEMGRP_FOR_PURCHASE_SUCCESSFULL } from './actionTypes';



export const getPurchaseOrderList = (url,data,history) =>{
    return {
        type:PURCHASE_ORDER_LIST,
        payload:{url,data,history}
    }
}

export const PurchaseOrderListSuccessfull = (data)=>{
    return {
        type:PURCHASE_ORDER_LIST_SUCCESSFULL,
        payload:data
    }
}
export const POWOLineItemsProps = (data)=>{
    return {
        type:PO_WO_LINEITEMS,
        payload:data
    }
}

export const IndentViewById = (url, history,source) => {
    return {
      type: INDENT_VIEW_BY_ID,
      payload: { url, history ,source},
    };
  };
  
  export const IndentViewByIdSuccessfull = (data) => {
    return {
      type: INDENT_VIEW_BY_ID_SUCCESSFULL,
      payload: data,
    };
  };

  export const getPurchaseOrderHeader = (data) => {
    return {
      type: PURCHASE_ORDER_HEADER,
      payload: data,
    };
  };
export const getItemDetailsByPoId = (url, history, source) => {
   return {
    type: GET_ITEM_DETAILS_BY_POID,
    payload: {url, history, source},
   }
}

export const getItemDetailsByPoIdSuccessfull = (data) => {
  return {
    type: GET_ITEM_DETAILS_BY_POID_SUCCESSFULL,
    payload: data,

  }
}

export const getItemListByItemGroupForPurchase = (url, data, callback, itemgrpId) => {
   return {
    type: GET_ITEMLIST_BY_ITEM_GROUP_FOR_PURCHASE,
    payload: {url, data, callback, itemgrpId},
   }
} 

export const getItemListByItemGroupForPurcahseSuccessfull = (data) =>{
    return {
        type: GET_ITEM_LIST_BY_ITEM_GROUP_FOR_PURCHASE_SUCCESSFULL,
        payload: data,
    }
} 

export const getItemDetailsByItemItemGrpForPurchase = (url, data, callback, itemId) => {
  return {
   type: GET_ITEMDETAILS_BYITEM_ITEMGRP_FOR_PURCHASE,
   payload: {url, data, callback, itemId},
  }
} 

export const getItemDetailsByItemItemGrpForPurchaseSuccessfull = (data) =>{
   return {
       type: GET_ITEMDETAILS_BYITEM_ITEMGRP_FOR_PURCHASE_SUCCESSFULL,
       payload: data,
   }
} 

export const apiErrors = (error) => {
    return {
      type: API_ERRORS,
      payload: error,
    };
  };

