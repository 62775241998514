import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import DynamicSelectNew from "../../../components/Dropdown/Master/DynamicSelectMasters";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getSupplierListMultiselect,
  getStatusList,
  getStateNameList,
} from "../../../store/Global/DropDownApis/actions";
import {
  getVehicleList,
  getAgentDropdownList,
} from "../../../store/MasterDropdownApis/actions";
import TextFieldNormal from "../../TextField/TextFieldNormal";

class BrokerMasterFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      brokerType: null,
      brokerName: null,
    };
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "brokerName") {
      this.setState({
        brokerName: event,
      });
    }
  };
  handleSubmit = () => {
    var data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      userId: this.state.userDit.userId,
      brokerName: this.state.brokerName !==''?this.state.brokerName:null,
      brokerType: this.state.brokerType,
      startIndex: 0,
      lastIndex: 10,
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleClear = () => {
    this.setState({
      subDeptCode: "",
      subDeptName: "",
      parentDeptName: "",
    });
  };

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "brokerType") {
      if(selectedName=='Select...'){
        this.setState({
          brokerType: null,
        });
      }else{
        this.setState({
          brokerType: selectedName,
        });
      }
    }
  };

  render() {
    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid spacing={0}>
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
                {/* <div>Save View</div> */}
              </div>
            </div>
            <div className="procurementFilter">
              <Grid
                item
                xs={12}
                className="filterBlockContainer juteFilterBlock"
              >
                <Box gridColumn="span 3">
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          value={this.state.brokerName}
                          placeholder={"Enter here"}
                          label="Broker Name"
                          caps="OFF"
                          onChange={this.onhandlechange("brokerName")}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <DynamicSelectNew
                          arrayOfData={BrokerType}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          selected={this.state.brokerType}
                          update={this.state.type ? 1 : 0}
                          name="brokerType"
                          label="Broker Type"
                          
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Box>

                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Search"}
                    className="greenButton"
                    handleClick={this.handleSubmit}
                    type="sumbit"
                  />
                </div>
              </Grid>
            </div>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const BrokerType = [
  { label: "Select...", value: 0, name: "Select..." },
  { label: "JUTE", value: 1, name: "JUTE" },
  { label: "SALE", value: 2, name: "SALE" },
];

const mapStatetoProps = (state) => {
  const { supplierListMultiselect, statusList, stateNameList } =
    state.DropDownListReducer;
  const { VehicleData, agentDropdownList } = state.MasterDropDownListReducer;
  return {
    supplierListMultiselect,
    VehicleData,
    statusList,
    agentDropdownList,
    stateNameList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSupplierListMultiselect,
    getVehicleList,
    getStatusList,
    getAgentDropdownList,
    getStateNameList,
  })(BrokerMasterFilter)
);
