import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import CustomButton from "../../../components/Buttons/Buttons";
import { serverApi, getTenantId } from "../../../helpers/Consts";
import { isEmpty } from "../../../Utilities/helper";
import { Grid } from "@mui/material";
import { Box, Tooltip } from "@mui/material";

const JuteMrPreviewPage = (props) => {
  const [rowCount, setRowCount] = useState(1);
  const [show, setShow] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [companyId, setCompanyId] = useState(localStorage.getItem("companyId"));
  const [companyName, setCompanyName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [comName, setComName] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [filterText, setFilterText] = useState("");
  const [tooltip, setTooltip] = useState("item description....");

  const { juteMrHeaderData, juteMrLineItems, quantityByItemIdData } =
    useSelector((state) => state.JuteMaterialReceiptReducer);

  const [juteMrHeaders, setJuteMrHeaderData] = useState({});
  const [juteMrlineItems, setJuteMrLineItems] = useState([]);

  useEffect(() => {
    setJuteMrHeaderData(juteMrHeaderData);
  }, [juteMrHeaderData]);

  useEffect(() => {
    setJuteMrLineItems(juteMrLineItems);
  }, [juteMrLineItems]);

  useEffect(() => {
    const companyData = JSON.parse(localStorage.getItem("authUser"));
    const companyId = localStorage.getItem("companyId");
    companyData.companyDetails.forEach((p) => {
      const id = p.value;
      if (companyId === id.toString()) {
        setCompanyName(p.label);
        setAddress1(p.address1);
        setAddress2(p.address2);
        setComName(p.name);
        setCompanyLogo(p.companyLogo);
      }
    });
  }, []);

  const convertNumberToWords = (amount) => {
    const words = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
      "Twenty",
      "",
      "Thirty",
      "",
      "Forty",
      "",
      "Fifty",
      "",
      "Sixty",
      "",
      "Seventy",
      "",
      "Eighty",
      "",
      "Ninety",
    ];
    amount = amount.toString();
    const atemp = amount.split(".");
    let number = atemp[0].split(",").join("");
    const n_length = number.length;
    let words_string = "";
    if (n_length <= 9) {
      let n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      let received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i === 0 || i === 2 || i === 4 || i === 7) {
          if (n_array[i] === 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      let value = "";
      for (let i = 0; i < 9; i++) {
        if (i === 0 || i === 2 || i === 4 || i === 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value !== 0) {
          words_string += words[value] + " ";
        }
        if (
          (i === 1 && value !== 0) ||
          (i === 0 && value !== 0 && n_array[i + 1] === 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i === 3 && value !== 0) ||
          (i === 2 && value !== 0 && n_array[i + 1] === 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i === 5 && value !== 0) ||
          (i === 4 && value !== 0 && n_array[i + 1] === 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i === 6 &&
          value !== 0 &&
          n_array[i + 1] !== 0 &&
          n_array[i + 2] !== 0
        ) {
          words_string += "Hundred and ";
        } else if (i === 6 && value !== 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  };

  const withDecimal = (n) => {
    const nums = n.toString().split(".");
    const whole = convertNumberToWords(nums[0]);

    if (nums.length === 2) {
      if (parseInt(nums[1]) > 0) {
        const fraction = convertNumberToWords(parseInt(nums[1]));
        return `${whole} Rupees and ${fraction} Paisa`;
      } else {
        return `${whole} Rupees`;
      }
    } else {
      return `${whole} Rupees`;
    }
  };

  const handlePdf = () => {
    fetch(`${serverApi.DOWNLOAD_JUTE_MR}${props.location.state.rowData.mrNo}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        "X-TenantId": getTenantId(window.location.hostname),
        Authorization:
          localStorage.getItem("token_type") +
          localStorage.getItem("access_token"),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "JuteMR.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };
  const handleEdit = () => {
    props.handleCurrentStep();
  };
  const getTotalBales_drums = (lineItems) => {
    let TotalBales_drums = 0;
    lineItems.map((prop, index) => {
      if (prop.actualBale !== null) {
        TotalBales_drums += prop.actualBale;
      } else {
        TotalBales_drums += prop.actualLoose;
      }
    });
    return TotalBales_drums;
  };
  const getAdvisedWeight = (lineItems) => {
    let TotalAdvisedWeight = 0;
    lineItems.map((prop, index) => {
      if (prop.advisedWeight !== null) {
        TotalAdvisedWeight += Number(prop.advisedWeight) * 100;
      } else {
        TotalAdvisedWeight += Number(prop.advisedWeight) * 100;
      }
    });
    return TotalAdvisedWeight;
  };
  const getMillWeight = (lineItems) => {
    let TotalMillWeight = 0;
    lineItems.map((prop, index) => {
      if (prop.acceptedWeight !== null) {
        TotalMillWeight += prop.acceptedWeight;
      } else {
        TotalMillWeight += prop.acceptedWeight;
      }
    });
    return TotalMillWeight;
  };
  const getApprovedWeight = (lineItems) => {
    let TotalApprovedWeight = 0;
    lineItems.map((prop, index) => {
      if (prop.acceptedWeight !== null) {
        TotalApprovedWeight += Number(prop.acceptedWeight);
      } else {
        TotalApprovedWeight += Number(prop.acceptedWeight);
      }
    });
    return TotalApprovedWeight;
  };

  const filterData = juteMrlineItems.filter((item) => item.itemId !== "");

  const textAlign = { textAlign: "right" };
  return (
    <>
      <div className="previewBlock">
        <div
          style={{ display: "flex", justifyContent: "end", padding: "10px" }}
        >
          {juteMrHeaders.EditButton && (
            <CustomButton
              label="Edit"
              handleClick={handleEdit}
              type="button"
              className="lightBlueButton"
              muIcon={<EditIcon />}
            ></CustomButton>
          )}
          {props.locationState.state !== undefined && (
            <CustomButton
              label="Print"
              handleClick={handlePdf}
              type="button"
              className="lightBlueButton"
              muIcon={<PrintIcon />}
            />
          )}
        </div>
        <div id="JuteMrPreview">
          <table
            cellPadding="0"
            cellSpacing="0"
            width="100%"
            className="previewTable"
          >
            <tbody>
              <tr>
                <td
                  colSpan="5"
                  style={{ borderRight: "none", borderBottom: "none" }}
                >
                  <img src={companyLogo} width="100%" alt="" />
                </td>
                <td
                  colSpan="10"
                  style={{
                    borderBottom: "none",
                    borderLeft: "none",
                    textAlign: "right",
                  }}
                >
                  <h2>{companyName}</h2>
                  {address1}
                  <br />
                  {address2}
                </td>
              </tr>
              <tr>
                <td
                  colSpan="14"
                  style={{ textAlign: "center", borderTop: "none" }}
                >
                  <h2>Material Receipt</h2>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>MR NO : </b>
                </td>
                <td
                  colSpan="3"
                  className={juteMrHeaders.mrPrintNo === "" ? "blur-text" : ""}
                >
                  {juteMrHeaders.mrPrintNo === ""
                    ? "Will be generated after creation"
                    : juteMrHeaders.mrPrintNo}
                </td>
                <td colSpan="2">
                  <b>MR DATE :</b>
                </td>
                <td colSpan="7">{juteMrHeaders.createDateDesc}</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Challan Number:</b>
                </td>
                <td
                  colSpan="3"
                  className={juteMrHeaders.invoiceNo === "" ? "blur-text" : ""}
                >
                  {isEmpty(juteMrHeaders.challanNo)
                    ? "NA"
                    : juteMrHeaders.challanNo}
                </td>
                <td colSpan="2">
                  <b>Challan Date:</b>
                </td>
                <td colSpan="7">{juteMrHeaders.chalanDateDesc}</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Supplier :</b>
                </td>
                <td colSpan="3">{juteMrHeaders.supplierName}</td>
                <td colSpan="2">
                  <b>Branch : </b>
                </td>
                <td colSpan="7">{juteMrHeaders.branchName}</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Consignee :</b>
                </td>
                <td colSpan="12">{juteMrHeaders.customerName}</td>
              </tr>
              <tr>
                <td width={"5%"}>
                  <b>Bales/Drums</b>
                </td>
                <td align="center" width={"10%"}>
                  <b>Marks&Quality</b>
                </td>
                <td align="center" width={"10%"}>
                  <b>Advised weight in kg</b>
                </td>
                <td align="center" width={"10%"}>
                  <b>Mill weight in Kg</b>
                </td>
                <td align="center" width={"10%"}>
                  <b>Claim in Kgs</b>
                </td>
                <td align="center" width={"10%"}>
                  <b>Approved Weight</b>
                </td>
                <td align="center" width={"10%"}>
                  <b>Rate Per Qtls Rs</b>
                </td>
                <td align="center" width={"10%"}>
                  <b>Claim for Quality</b>
                </td>
                <td align="center" width={"10%"}>
                  <b>Claim for Condition</b>
                </td>
                <td align="center" width={"10%"}>
                  <b>Initial</b>
                </td>
              </tr>
              {filterData.map((prop, index) => (
                <tr key={index}>
                  <td style={textAlign}>
                    {prop.actualBale !== null
                      ? prop.actualBale
                      : prop.actualLoose}
                  </td>
                  <td>{prop.itemDesc + " " + prop.qualityDesc}</td>
                  <td style={textAlign}>{Number(prop.advisedWeight) * 100}</td>
                  <td style={textAlign}>{prop.acceptedWeight}</td>
                  <td>{}</td>
                  <td style={textAlign}>{prop.acceptedWeight}</td>
                  <td style={textAlign}>{prop.rate}</td>
                  <td style={textAlign}>{prop.claimQuality}</td>
                  <td style={textAlign}>{prop.claimsCondition}</td>
                  <td>{}</td>
                </tr>
              ))}
              <tr>
                
                <td>{""}</td>
                <td>{""}</td>
                <td style={textAlign}>{getAdvisedWeight(filterData)}</td>
                <td style={textAlign}>{getMillWeight(filterData)}</td>
                <td>{}</td>
                <td style={textAlign}>{getApprovedWeight(filterData)}</td>
                <td>{""}</td>
                <td>{""}</td>
                <td>{""}</td>
                <td>{""}</td>
                <td>{""}</td>
              </tr>
              <tr>
              <td colSpan="10" align="left" style={{ border: "none" }}>
                  Status: {juteMrHeaders.statusDesc}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const mapStatetoProps = (state) => {
  return {
    // map state to props
  };
};

export default withRouter(connect(mapStatetoProps, {})(JuteMrPreviewPage));
