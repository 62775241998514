export const CUTS_AND_JUGAR_ENTRY_LIST = "cuts_and_jugar_entry_list";
export const CUTS_AND_JUGAR_ENTRY_LIST_SUCCESSFULL = "cuts_and_jugar_entry_list_successfull";
export const CUTS_AND_JUGAR_ENTRY_LIST_DEL = "cuts_and_jugar_entry_del_list";
export const CUTS_AND_JUGAR_ENTRY_LIST_DEL_SUCCESSFULL = "cuts_and_jugar_entry_del_list_successfull";
export const GET_LOOM_NO = "get_loom_no";
export const GET_LOOM_NO_SUCCESSFULL = "get_loom_no_successfull";
export const GET_CUTS_JUGAR_BY_LOOM = "get_cuts_jugar_by_loom";
export const GET_CUTS_JUGAR_BY_LOOM_SUCCESSFULL = "get_cuts_jugar_by_loom";
export const GET_DAILY_QUALITY_MAPPING = "get_daily_quality_mapping";
export const GET_DAILY_QUALITY_MAPPING_SUCCESSFULL = "get_daily_quality_mapping_successfull";
export const CUTS_JUGAR_DATA_LIST = "cuts_jugar_data_list";
export const CUTS_JUGAR_DATA_LIST_SUCCESSFULL = "cuts_jugar_data_list_successfull";
export const CREATE_ACTUAL_SHOTS = "create_actual_shots";
export const CREATE_ACTUAL_SHOTS_SUCCESSFULL = "create_actual_shots_successfull";
export const DELETE_ACTUAL_SHOTS = "delete_actual_shot";
export const DELETE_ACTUAL_SHOTS_SUCCESSFULL = "delete_actual_shot_successfull";
export const UPDATE_EB_NO_WORKING_HOURS = "update_eb_no_working_hours";
export const UPDATE_EB_NO_WORKING_HOURS_SUCCESSFULL = "update_eb_no_working_hours_successfull";
export const CUTS_JUGAR_LIST = "cuts_jugar_list";
export const CUTS_JUGAR_LIST_SUCCESSFULL = "cuts_jugar_list_successfull";
export const CREATE_CUTS_AND_JUGAR = "create_cuts_and_jugar";
export const CREATE_CUTS_AND_JUGAR_SUCCESSFULL = "create_cuts_and_jugar_successfull";
export const UPDATE_WEAVING_MACHINE_MAPPING = "update_weaving_machine_mapping";
export const UPDATE_WEAVING_MACHINE_MAPPING_SUCCESSFULL = "update_weaving_machine_mapping_successfull";


