import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Grid, Box } from '@mui/material'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';   
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';   
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'; 
import DynamicSelect from '../DynamicSelect'
import {getBranchList} from '../../../store/Global/DropDownApis/actions'
import { serverApi } from '../../../helpers/Consts'
import {ledgerMasterList} from '../../../store/Master/LedgerMaster/actions'
import {getBankAccountList} from '../../../store/Master/BankAccountMaster/actions'
import moment from 'moment'
import TextFieldNormal from '../../../components/TextField/TextFieldNormal'

class ReceiptFillDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
       branchListData: [],
       ledgerMasterListData: [],
       accountListData: [],
       userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem('companyId'),
      ledgerId: this.props.header.ledgerId,
      ledgerName: this.props.header.ledgerName,
      transactionType: "P",
      fundsIn: this.props.header.fundsIn,
      fundsOut: this.props.header.fundsOut,
      bankId: this.props.header.bankId,
      transactionDate: this.props.header.transactionDate,
      tdsAmount: this.props.header.tdsAmount,
      tdsReason: this.props.header.tdsReason,
      createdBy: this.props.header.createdBy,
      companyId: this.props.header.companyId,
      paymentSource: this.props.header.paymentSource,
      bankRefnumber: this.props.header.bankRefnumber,
      description: this.props.header.description,
      acYear: '2022',
      tranStatus: this.props.header.tranStatus,
      advanceId: this.props.header.advanceId,
      branchId: this.props.header.branchId,
      transactionId: this.props.header.transactionId
    }
  }

  handlePropSelectChange = (selectedValue, selectedName, name) => {
   
    this.props.onSelectDep(selectedValue, selectedName, name)
    if(name === 'branch') {
      this.props.header.branchId = selectedValue

      this.setState({
        branchId: selectedValue
      })
    }
    if(name === 'ledger') {
    
      this.props.header.ledgerId = selectedValue
      this.props.header.ledgerName = selectedName
      

      this.setState({
        ledgerId: selectedValue,
        ledgerName:  selectedName
      })
    }
    if(name === 'account') {
   
      this.props.header.bankId = selectedValue
      this.setState({
        bankId: selectedValue
      })
    }
    if(name === 'paymentSource') {

      this.props.header.paymentSource = selectedValue
      this.setState({
        paymentSource: selectedValue
      })

    }
  }

  componentDidMount() {
    this.props.getBranchList(
      serverApi.BRANCH_LIST + localStorage.getItem('companyId'),
      this.props.history
    );

    const data = {
      cipher: this.state.userDit.cipher,
     companyId: localStorage.getItem('companyId')
   }
   this.props.ledgerMasterList(serverApi.GET_ALL_LEDGER_MASTER_LIST, data, this.props.history);

   const data1 = {
    cipher: this.state.userDit.cipher,
    companyId: localStorage.getItem('companyId'),
  };
  this.props.getBankAccountList(
    serverApi.GET_ALL_BANK_ACCOUNT_LIST, data1, this.props.history
  );

  }

  handleChange = (key) =>(event) => {
    this.setState({[key] : event})
    this.props.onhandlechangeValue(event, key);
    if(key === 'bankRefnumber'){
     
      this.props.header.bankRefnumber = event
      this.setState({
        bankRefnumber: event
      })
    }
   
  }

  handleSelect_Date = (e, name) => {
    

    if (name === "transactionDate") {
      var dateVal = e;
      var date = moment(dateVal).format();
     

      var fromdate = date.split("T", 1).toString();
      this.props.header.transactionDate = fromdate;
     
     

        this.setState({
          transactionDate: fromdate,
        });
      
    this.props.handleSelectDate(fromdate, name);
      
    }

  };

  componentWillReceiveProps(props) {
 
    var ledgerList = [];
    var accountList = [];
    if(props.LedgerMasterList.data) {
      props.LedgerMasterList.data.map(prop => {
      
        ledgerList.push({
          value: prop.id,
          label: prop.ledgerName,
          name: prop.ledgerName
        })
      })
    }
    
    if(props.BankAccountList.data) {
      props.BankAccountList.data.map((prop) => {
      accountList.push({
        value: prop.id,
        name: prop.accountantName,
        label: prop.accountantName
      })
    })
    }

    if (props.branchList) {

      if(props.branchList.data){
        let list=props.branchList.data.filter((props)=>{
          if(props.value !==0){
            return props
          }
        })
        this.setState({
          branchListData: list,
        });
      }    
    }
    
    
    this.setState({
    
      ledgerMasterListData: ledgerList,
      accountListData: accountList

    })


      
  }
  
  render() {

    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {this.props.location ? <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.props.header.branchId}
                        name="branch"
                        label="Branch"
                        update={this.props.location.state ? 1 : 0}
                        isDisabled={true}
                        required
                      />
                    </div>
                  </Box>: <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.props.header.branchId}
                        name="branch"
                        label="Branch"
                        update={this.props.location.state ? 1 : 0}
                        required 
                      />
                    </div>
                  </Box>}
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>Date <span style={{color: 'red'}}>*</span></label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                              inputFormat="dd-MM-yyyy"
                              name="transactionDate"
                              value={this.props.header.transactionDate}
                              
                              fullWidth
                              onChange={(e) => this.handleSelect_Date(e, 'transactionDate')}
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  className="consoleDatePicker"
                                >
                                  <input ref={inputRef} {...inputProps} />
                                  {InputProps?.endAdornment}
                                </Box>
                                )}
                                />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                     
                      <DynamicSelect
                        arrayOfData={this.state.ledgerMasterListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.props.header.ledgerId}
                        update={this.props.location.state ? 1 : 0}
                        name="ledger"
                        label="Ledger"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.accountListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.props.header.bankId}
                        update={this.props.location.state ? 1 : 0}
                        name="account"
                        label="Account"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={paymentSource}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.props.header.paymentSource}
                        name="paymentSource"
                        label="Receipt Source"
                        update={this.props.location.state ? 1 : 0}
                        required 
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal label="Cheque/Utr No" 
                        value={this.props.header.bankRefnumber}
                        caps="OFF"
                         minValue="0"
                        onChange={this.handleChange("bankRefnumber")}
                      />
                    </div>
                  </Box>
                 
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}

const paymentSource = [
 
  { label: "CHEQUE", value: "C" },
  { label: "CASH", value: "CH" },
  { label: "NEFT", value: "N" },
  { label: "RTGS", value: "R" },
  { label: "JOURNAL", value: "J" },
  { label: "IMPS", value: "I" },
  { label: "TPT", value: "T" },
]



const mapStatetoProps = (state) => {
  const { branchList} = state.DropDownListReducer;
  const { LedgerMasterList } = state.LedgerReducer;
  const { Bank_Account_List, BankAccountList } = state.BankAccountListReducer;
    return {  branchList, LedgerMasterList, Bank_Account_List, BankAccountList };
 
}
export default connect(
  mapStatetoProps,
  {getBranchList, ledgerMasterList,
    getBankAccountList},
)(withRouter(ReceiptFillDetails))
