export const CUSTOMER_PAGINATED_LIST = "customer_paginated_list";
export const CUSTOMER_PAGINATED_LIST_SUCCESSFULL = "customer_paginated_list_successfull";
export const CREATE_CUSTOMER_MASTER = "create_customer_master";
export const CREATE_CUSTOMER_MASTER_SUCCESSFULL = "create_customer_master_successfull";
export const CUSTOMER_LINE_ITEMS = "customer_lineitmes";
export const CUSTOMER_MASTER_VIEW_BY_ID = "customer_master_view_by_id";
export const CUSTOMER_MASTER_VIEW_BY_ID_SUCCESSFULL = "customer_master_view_by_id_successfull";
export const API_ERRORS = 'api_failed';
export const CUSTOMER_MASTER_UPDATE='customer_master_update';
export const CUSTOMER_MASTER_UPDATE_SUCCESSFULL='customer_master_update_successfull';
export const GET_ALL_FILES='get_all_files';
export const GET_ALL_FILES_SUCCESSFULL='get_all_files_successfull';
export const DELETE_CUSTOMER_FILES='delete_customer_files';
export const DELETE_CUSTOMER_FILES_SUCCESSFULL='delete_customer_files-successfull';
export const UPLOAD_CUSTOMER_FILES='upload_customer_files';
export const UPLOAD_CUSTOMER_FILES_SUCCESSFULL='upload_customer_files_successfull'
export const GET_ALL_LEDGER_DATA='get_all_ledger_data';
export const GET_ALL_LEDGER_DATA_SUCCESSFULL='get_all_ledger_data_successfull';
export const ENTITY_TYPE_DATA="entity_type_data";
export const ENTITY_TYPE_DATA_SUCCESSFULL='entity_type_data_successfull';
export const CUSTOMER_GST_VERIFICATION='customer_gst_verification';
export const CUSTOMER_GST_VERIFICATION_SUCCESSFULL='customer_gst_verification_successfull';


