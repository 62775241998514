import React, { Component } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Grid, Box } from "@mui/material";
import CustomButton from "../../../components/Buttons/Buttons";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi, cons, getTenantId } from "../../../helpers/Consts";
import {
  getPayRollData,
  getAttendence,
} from "../../../store/HRMS/PayRollData/actions";
import {
  getPayScheme,
  getDepartmentListData,
} from "../../../store/HRMS/EmployeeDataBase/actions";
import {
  getBranchList,
  getEmployeeCategoryList,
} from "../../../store/Global/DropDownApis/actions";
import DeleteIcon from "../../../assets/images/picEditIcon.png";
import moment from "moment";
import PayRollAddItem from "./PayRollAddItem";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import { Button } from "@mui/material";
import EmployeeData from "../../../assets/CSV/PayRollData.xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import {
  uploadFormdataWithImage,
  uploadExcelData,
} from "../../../helpers/server";
import swal from "sweetalert";

class CreateNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      display: false,
      companyId: localStorage.getItem("companyId"),
      fromDate: "",
      toDate: "",
      payRollListData: [],
      dataField: [],
      payScheme_List: [],
      Department_List: [],
      branchList: [],
      CatageriesListData: [],
      paySchemeId: 0,
      categoryId: 0,
      deptId: 0,
      branchId: 0,
      payRollData: [],
      payRollConstData: [],
      empPayschemeList: "",
      CalenderDate: "",
      uploadFileName: "d-none",
      uploadFileBlock: "d-block",
      // loading: "none",
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    const data = {
      activeStatus: "",
      isCompanyFiltered: "Y",
      status: "32",
    };
    this.props.getPayScheme(
      serverApi.EMPLOYEE_PAY_SCHEME,
      data,
      this.props.history
    );
    this.props.getDepartmentListData(
      serverApi.DEPARTMENT_LIST + localStorage.getItem("companyId"),
      this.props.history
    );
    this.props.getBranchList(
      serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
      this.props.history
    );
    this.props.getEmployeeCategoryList(
      serverApi.GET_EMPLOYEE_CATAGERIES_LIST + 1,
      this.props.history
    );
  }
  hideTableRows = () => {
    this.setState({
      display: false,
      //  loading: "none"
    });
  };
  handleAttendence = () => {
    this.setState({ display: true });
    var fromDate = moment(this.state.fromDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var toDate = moment(this.state.toDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );

    var startDate = moment(fromDate, "DD-MM-YYYY");
    var endDate = moment(toDate, "DD-MM-YYYY");

    var result = endDate.diff(startDate, "days") + 1;
    this.setState({
      CalenderDate: result,
      // loading: "block",
    });
    var data = {
      branchId: this.state.branchId,
      companyId: localStorage.getItem("companyId"),
      deptId: this.state.deptId,
      empCategoryId: this.state.categoryId,
      fromDate: fromDate,
      payschemeId: this.state.paySchemeId,
      toDate: toDate,
    };

    if (this.state.fromDate == "" || this.state.fromDate == null) {
      swal("Please Enter the From Date");
    } else if (this.state.toDate == "" || this.state.toDate == null) {
      swal("Please Enter the To Date");
    } else if (this.state.branchId == 0) {
      swal("Please Enter the Branch");
    } else {
      this.props.getPayRollData(
        serverApi.PAYROLL_DATA_ITEM,
        data,
        this.props.history
      );
    }
    // if (
    //   this.state.fromDate !== "" &&
    //   this.state.toDate !== "" &&
    //   this.state.branchId !== 0
    // ) {

    // } else {
    //   swal("Please Select From Date,To Date and Branch", { icon: "error" });
    // }
  };
  handleSelectDate = (e, name) => {
    if (name === "dateOne") {
      var date = moment(e).format();
      var fromdate = date.split("T", 1).toString();
      if (this.state.toDate !== "") {
        if (fromdate > this.state.toDate) {
          swal("From Date Shouldn't be Greater Than To Date");
          this.setState({
            fromDate: null,
            toDate: null,
          });
        } else {
          this.setState({
            fromDate: fromdate,
          });
        }
      } else {
        this.setState({
          fromDate: fromdate,
        });
      }
    }

    if (name === "dateTwo") {
      var date = moment(e).format();
      var toDate = date.split("T", 1).toString();
      if (toDate < this.state.fromDate) {
        swal("To Date Should be Greater Than From Date");
        this.setState({
          toDate: null,
        });
      } else {
        this.setState({
          toDate: toDate,
        });
      }
    }
  };

  componentWillReceiveProps(props) {
    let payRollData = [];
    let payRollConstData = [];
    let paySchemeList = [];
    // this.setState({
    //   loading: "none",
    // });

    if (props.payRoll_data.payComponents) {
      props.payRoll_data.payComponents.map((prop) => {
        payRollData.push({
          dataField: prop.code,
          id: prop.id,
          text: prop.code,
          name: prop.name,
          [prop.code]: 0,
          hidden: false,
        });
      });

      this.setState({
        payRollData: payRollData,
      });
    }

    if (props.payRoll_data) {
      if (props.payRoll_data.empPayschemeList) {
        if (props.payRoll_data.empPayschemeList.length > 0) {
          this.setState({
            empPayschemeList: props.payRoll_data.empPayschemeList,
          });
        } else {
          this.setState({
            empPayschemeList: [],
          });
        }
      } else {
        this.setState({
          empPayschemeList: [],
        });
      }
    }

    if (props.payScheme_List) {
      props.payScheme_List.map((prop) => {
        paySchemeList.push({
          name: prop.name,
          label: prop.name,
          value: prop.id,
        });
      });

      if (props.departmentList.data) {
        this.setState({
          Department_List: props.departmentList.data,
        });
      }

      if (props.branchList.data) {
        this.setState({
          branchList: props.branchList.data,
        });
      }

      this.setState({
        payScheme_List: paySchemeList,
      });
    }

    if (props.payRoll_data.empPayschemeList) {
      if (props.payRoll_data.empPayschemeList.length > 0) {
        props.payRoll_data.empPayschemeList.map((prop) => {
          payRollConstData.push({
            empCode: prop.empCode,
            empName: prop.empName,
            paySchemeName: prop.paySchemeName,
            empId: prop.empId,
          });
          this.setState({
            payRollConstData: payRollConstData,
          });
        });
      } else {
        this.setState({
          payRollConstData: [],
        });
      }
    } else {
      this.setState({
        payRollConstData: [],
      });
    }

    if (props.employeeCategoryList) {
      this.setState({
        CatageriesListData: props.employeeCategoryList.data, // updating employee Catageries list
      });
    }
  }

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "payScheme") {
      this.setState({
        paySchemeId: selectedValue,
      });
    }

    if (name === "department") {
      this.setState({
        deptId: selectedValue,
      });
    }

    if (name === "branch") {
      this.setState({
        branchId: selectedValue,
      });
    }
    if (name === "category") {
      this.setState({
        categoryId: selectedValue,
      });
    }
  };

  handleFetchAttendence = () => {
    var fromDate = moment(this.state.fromDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var toDate = moment(this.state.toDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );

    if (this.state.fromDate == "" || this.state.fromDate == null) {
      swal("Please Enter the From Date");
    } else if (this.state.toDate == "" || this.state.toDate == null) {
      swal("Please Enter the To Date");
    } else {
      this.props.getAttendence(
        serverApi.FETCH_ATTENDENCE +
          fromDate +
          "/" +
          toDate +
          "/0/0/0/0/0/" +
          localStorage.getItem("companyId") +
          "/" +
          this.state.userDit.userId
      );
    }
  };

  handleFetchGeneric = () => {
    var fromDate = moment(this.state.fromDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var toDate = moment(this.state.toDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );

    if (this.state.fromDate == "" || this.state.fromDate == null) {
      swal("Please Enter the From Date");
    } else if (this.state.toDate == "" || this.state.toDate == null) {
      swal("Please Enter the To Date");
    } else {
      this.props.getAttendence(
        serverApi.FETCH_PAY_GENERIC +
          fromDate +
          "/" +
          toDate +
          "/" +
          this.state.userDit.ebId
      );
    }
  };

  handlefetchCumalitive = () => {
    var fromDate = moment(this.state.fromDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var toDate = moment(this.state.toDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    if (this.state.fromDate == "" || this.state.fromDate == null) {
      swal("Please Enter the From Date");
    } else if (this.state.toDate == "" || this.state.toDate == null) {
      swal("Please Enter the To Date");
    } else {
      this.props.getAttendence(
        serverApi.FETCH_CUMULATIVE +
          fromDate +
          "/" +
          toDate +
          "/0/0/0/0/0/" +
          localStorage.getItem("companyId") +
          "/" +
          this.state.userDit.ebId
      );
    }
  };

  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }

  handleselectedFile = (e) => {
    if (this.state.fromDate == "" || this.state.fromDate == null) {
      swal("Please Enter the From Date");
    } else if (this.state.toDate == "" || this.state.toDate == null) {
      swal("Please Enter the To Date");
    } else {
      e.preventDefault();
      var fromDate = moment(this.state.fromDate, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      );
      var toDate = moment(this.state.toDate, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      );
      let reader = new FileReader();
      let file = e.target.files[0];
      let fileExt = file.name.split(".").pop();
      this.setState({
        fileName: file.name,
        fileExtension: file.name.split(".").pop(),
      });
      this.setState({
        selectedFile: e.target.files[0],
        selectedFileName: e.target.files[0].name,
        uploadFileName: "d-block",
        uploadFileBlock: "d-none",
      });
      this.props.uploadExcelData(
        serverApi.UPLOAD_PAYROLL_DATA +
          fromDate +
          "/" +
          toDate +
          "/" +
          localStorage.getItem("companyId") +
          "/" +
          this.state.userDit.userId,
        file,
        this.handleFile
      );
    }
  };

  handleDeleteFile = (e) => {
    this.setState({
      selectedFileName: "",
      showDocument: false,
      uploadFileName: "d-none",
      uploadFileBlock: "d-block",
    });
  };

  DownloadTemplate = () => {
    var headers = {
      Authorization:
        localStorage.getItem("token_type") +
        localStorage.getItem("access_token"),
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    if (getTenantId(window.location.hostname) !== null) {
      headers = {
        Authorization:
          localStorage.getItem("token_type") +
          localStorage.getItem("access_token"),
        "X-TenantId": getTenantId(window.location.hostname),
        Accept: "application/json",
        // "Access-Control-Allow-Origin": "localhost:3000",
        "Content-Type": "application/json",
        CompanyID: localStorage.getItem("CompanyId"),
      };
    }
    fetch(serverApi.DOWNLOAD_PAYROLL_TEMPLATE, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        branchId: this.state.branchId,
        companyId: localStorage.getItem("companyId"),
        deptId: this.state.deptId,
        empCategoryId: 0,
        payschemeId: this.state.paySchemeId,
      }),
    })
      .then((response) =>
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "PayRoll.xlsx";
          a.click();
        })
      )

      .catch((error) => {});
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    var fromDate = moment(this.state.fromDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var toDate = moment(this.state.toDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );

    return (
      <div className="payRollData">
        <h1>Upload Payroll Data</h1>
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>
                          From Date <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.fromDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "dateOne")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>
                          To Date <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          name="dateTwo"
                          inputFormat="dd-MM-yyyy"
                          value={this.state.toDate}
                          fullWidth
                          onChange={(date) =>
                            this.handleSelectDate(date, "dateTwo")
                          }
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.payScheme_List}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.paySchemeId}
                      name="payScheme"
                      label="Pay Scheme Name"
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.Department_List}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.deptId}
                      name="department"
                      label="Department"
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.branchList}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.branchId}
                      name="branch"
                      label="Branch"
                      required
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock"></div>
                </Box>
              </Box>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box gridColumn="span 3">
                  <div className="consoleFormButtonBlock">
                    <CustomButton
                      label={"Search Attendance"}
                      className="greenButton"
                      handleClick={this.handleAttendence}
                      type="sumbit"
                    />

                    <CustomButton
                      label={"Fetch Attendance"}
                      className="greenButton"
                      handleClick={this.handleFetchAttendence}
                      type="sumbit"
                    />

                    <CustomButton
                      label={"Fetch Paygeneric"}
                      className="greenButton"
                      handleClick={this.handleFetchGeneric}
                      type="sumbit"
                    />

                    <CustomButton
                      label={"Fetch Cumulative"}
                      className="greenButton"
                      handleClick={this.handlefetchCumalitive}
                      type="sumbit"
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <div>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="uploadAndDownloadBtn"
              >
                <Grid item xs={6} className="ducumentUploadBlock">
                  <span onClick={this.DownloadTemplate}>
                    <a className="documentDownload ">
                      <DownloadIcon />
                      Download Excel For Reference
                    </a>
                  </span>
                </Grid>
                <Grid item xs={6}>
                  {this.props.label && <label>&nbsp;</label>}
                  <div className="ducumentUploadBlock">
                    <div
                      className={
                        "documentUploadContent " + this.state.uploadFileBlock
                      }
                    >
                      <input
                        type="file"
                        name="file"
                        id="file-upload"
                        onChange={this.handleselectedFile}
                        className="hiddenInput"
                      />
                      <label htmlFor="file-upload" style={{ display: "flex" }}>
                        <Button
                          variant="contained"
                          component="span"
                          className="documentUpload"
                        >
                          <img src={panUploadIcon} alt="" /> Upload Excel File
                        </Button>
                      </label>
                    </div>
                    <div className={this.state.uploadFileName}>
                      <div className="uploadedInfo">
                        <div className="selectedFileName">
                          <img src={TickIcon} alt="" className="tickIcon" />
                          {this.state.selectedFileName}
                          <Button
                            onClick={this.handleDeleteFile}
                            className="deleteButton"
                          ></Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        {this.state.display ? (
          <>
            <PayRollAddItem
              CalenderDate={this.state.CalenderDate}
              headerName={this.state.payRollData}
              type={this.state.payRollConstData}
              products={this.state.products}
              fromDate={fromDate}
              toDate={toDate}
              ebId={this.state.userDit.ebId}
              empPayschemeList={this.state.empPayschemeList}
              hideTableRows={this.hideTableRows}
            />
          </>
        ) : null}
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { payRoll_data, addAttendence } = state.PayRollDataReducer;
  const { payScheme_List, departmentList } = state.EmployeeReducer;
  const { branchList, employeeCategoryList } = state.DropDownListReducer;

  return {
    payRoll_data,
    payScheme_List,
    departmentList,
    branchList,
    employeeCategoryList,
    addAttendence,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getPayRollData,
    getPayScheme,
    getDepartmentListData,
    getBranchList,
    getEmployeeCategoryList,
    getAttendence,
    uploadFormdataWithImage,
    uploadExcelData,
  })(CreateNew)
);
