import {BILLS_LIST,BILLS_LIST_SUCCESSFULL, VENDOR_LIST, VENDOR_LIST_SUCCESSFULL, COST_FACTOR_LIST, COST_FACTOR_LIST_SUCCESSFULL, ADD_BILLS, ADD_BILLS_SUCCESSFULL} from './actionType'

export const getBillsList = (url,data,history) =>{
    return {
        type:BILLS_LIST,
        payload:{url,data,history}
    }
}

export const billsListSuccessfull = (data)=>{
    return {
        type:BILLS_LIST_SUCCESSFULL,
        payload:data
    }
}

export const addBill = (url, data, history) => {
    return {
        type: ADD_BILLS,
        payload: {url, data, history}
    }
}

export const addBillSuccess = (data) => {
    return {
        type: ADD_BILLS_SUCCESSFULL,
        payload: data
    }
}

export const getVendorList = (url,history) =>{
    return {
        type:VENDOR_LIST,
        payload:{url,history}
    }
}

export const vendor_list_Successfull = (data) => {
    return {
        type: VENDOR_LIST_SUCCESSFULL,
        payload: data
    }
}

export const getCostList = (url,history) =>{
    return {
        type:COST_FACTOR_LIST,
        payload:{url,history}
    }
}

export const cost_list_Successfull = (data) => {
    return {
        type: COST_FACTOR_LIST_SUCCESSFULL,
        payload: data
    }
}