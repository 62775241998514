import {GETALL_PAYSCHEMES_LIST} from "./actionType";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {allPaySchemeListSuccessfull} from "./actions";
import {getList} from '../../../helpers/Server_Helper';

function* getPaySchemeList({payload:{url,data,history}}) {
    try{
        const response = yield call(getList,url,data);
        yield put(allPaySchemeListSuccessfull({response}))
    }catch(error){
        console.log(error)
    }
}

export function* watchPaySchemeList () {
    yield takeEvery(GETALL_PAYSCHEMES_LIST, getPaySchemeList)
}

function* PaySchemeSaga() {
    yield all([
        fork(watchPaySchemeList)

    ])
}

export default PaySchemeSaga;
