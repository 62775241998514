import {
  CREATE_WASTAGE_ENTRY,
  CREATE_WASTAGE_ENTRY_SUCCESSFULL,
  DELETE_WASTAGE_ENTRY,
  DELETE_WASTAGE_ENTRY_SUCCESSFULL,
  GET_WASTAGE_ENTRY,
  GET_WASTAGE_ENTRY_SUCCESSFULL,
  STOCK_WASTAGE_LIST_TYPE,
  STOCK_WASTAGE_LIST_TYPE_SUCCESSFULL,
  WASTAGE_LIST_TYPE,
  WASTAGE_LIST_TYPE_SUCCESSFULL,
} from "./actionTypes";

export const wastageEntryList = (url, data, history) => {
  return {
    type: GET_WASTAGE_ENTRY,
    payload: { url, data, history },
  };
};
export const wastageEntryListSuccessfull = (data) => {
  return {
    type: GET_WASTAGE_ENTRY_SUCCESSFULL,
    payload: data,
  };
};
export const wastageTypeList = (url, history) => {
  return {
    type: WASTAGE_LIST_TYPE,
    payload: { url, history },
  };
};
export const wastageTypeListSuccessfull = (data) => {
  return {
    type: WASTAGE_LIST_TYPE_SUCCESSFULL,
    payload: data,
  };
};

export const stockwastageTypeList = (url, data, history) => {
  return {
    type: STOCK_WASTAGE_LIST_TYPE,
    payload: { url, data, history },
  };
};
export const stockwastageTypeListSuccessfull = (data) => {
  return {
    type: STOCK_WASTAGE_LIST_TYPE_SUCCESSFULL,
    payload: data,
  };
};

export const createWastageType = (url, data, history) => {
  return {
    type: CREATE_WASTAGE_ENTRY,
    payload: { url, data, history },
  };
};
export const createWastageTypeSuccessfull = (data) => {
  return {
    type: CREATE_WASTAGE_ENTRY_SUCCESSFULL,
    payload: data,
  };
};
export const DeleteWastageTypeEntry = (url, history,fromDate, toDate) => {
  return {
    type: DELETE_WASTAGE_ENTRY,
    payload: { url, history,fromDate, toDate},
  };
};
export const DeleteWastageTypeEntrySuccessfull = (data) => {
  return {
    type: DELETE_WASTAGE_ENTRY_SUCCESSFULL,
    payload: data,
  };
};
