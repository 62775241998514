import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import moment from "moment";
import { serverApi } from "../../../helpers/Consts";
import { getBranchList } from "../../../store/Global/DropDownApis/actions";
import { getSupplierList } from "../../../store/Global/DropDownApis/actions";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import AutoMultiselect from "../../../components/Dropdown/AutoMultiselect";

class CreateFilldetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      comments: this.props.hdrdto.comments,
      priceRequestDate:this.props.hdrdto.priceRequestDate,
      priceRequestStatus:this.props.hdrdto.priceRequestStatus,
      priceRequestSubject:this.props.hdrdto.priceRequestSubject,
      createdBy: this.props.hdrdto.createdBy,
      branchId: this.props.hdrdto.branchId,
      branchListData: [],
      supplierListData: [],
      futureDaysAllowable:"",
      backDaysAllowable:"",
      
    };
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.props.getBranchList(
      serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
      this.props.history
    );
    this.props.getSupplierList(
      serverApi.GET_ALL_SUPPLIERS_LIST +localStorage.getItem("companyId"),
      this.state.history
    );
    if (this.state.userDit) {
      this.state.userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              if(item.backDaysAllowable !==undefined){
                let backDaysAllowable = JSON.parse(item.backDaysAllowable)
                let backDays = backDaysAllowable.bdaystype
                if(Number(backDays)===1){
                  backDays = 7
                }else if(Number(backDays)===2){
                  backDays = 14
                }else if(Number(backDays)===3){
                  backDays = ""
                }else if(Number(backDays)===4){
                  backDays = backDaysAllowable.otherBackDateValue
                }else{
                  backDays = backDaysAllowable.bdaystype
                }
                let minDate = moment(this.state.priceRequestDate).subtract(Number(backDays), 'days');
                let setMinDate = moment(
                  minDate).format("YYYY-MM-DD")
                this.setState({
                  backDaysAllowable:setMinDate
                })
              }
              if(item.futureDaysAllowable !==undefined){
                let futureDaysAllowable = JSON.parse(item.futureDaysAllowable)
                let futureDays = futureDaysAllowable.fdaystype
                if(Number(futureDays)===1){
                  futureDays = 7
                }else if(Number(futureDays)===2){
                  futureDays = 14
                }else if(Number(futureDays)===3){
                  futureDays = ""
                }else if(Number(futureDays)===4){
                  futureDays = futureDaysAllowable.othervalue
                }else{
                  futureDays =futureDaysAllowable.fdaystype
                }
                let maxDate = moment(this.state.priceRequestDate).add(Number(futureDays), 'days');
                let setMaxDate = moment(
                  maxDate).format("YYYY-MM-DD")
                this.setState({
                  futureDaysAllowable:setMaxDate
                })
              }
              
            });
          }
      });
    }
  }

  componentWillReceiveProps(props) {  
    if (props.supplierList) {
      this.setState({
        supplierListData: props.supplierList.data,
      });
    }
    if (props.branchList) {
      this.setState({
        branchListData: props.branchList.data,
      });
    }
  }
  handleChangeMultiSel = (event) => {
    let selectedValue = event.value;
    let selectedName = event.name;
    this.setState({
      selectedValue: selectedValue,
      selectedName: selectedName,
      supplier: event,
    });
    this.props.PEFillDetails.supplier = event;
  };

  handleSelect_Date = (e, name) => {
    if (name === "priceRequestDate") {
      var date = moment(e).format();
      var fromdate = date.split("T", 1).toString();
      this.setState({
        priceRequestDate: fromdate,
      });
      this.props.hdrdto.priceRequestDate = fromdate;
    }
  };

  handlePropSelectChange = (selectedValue, selectedName, name, label) => {
    if (name === "branchId") {
      this.setState({
        branchId: selectedValue,
      });
      this.props.hdrdto.branchId = selectedValue;
    }
  };

  handleChange = (key) => (value) => {
    if (key === "priceRequestSubject") {
      this.setState({
        priceRequestSubject: value,
      });
      this.props.hdrdto.priceRequestSubject = value;
    }
    if (key === "comments") {
      this.setState({
        comments: value,
      });
      this.props.hdrdto.comments = value;
    }
    
  };

  handleMenuOpen=()=>{
    this.setState({
      isDropdownOpen:false
    })
  }

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen:true
    })
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.
    this.setState({
      isDropdownOpen:true
    })
  };

  render() {
    return (
      <div className="consoleFormContainer">
        <h5>Fill Details</h5>
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>Price Request Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="date"
                          minDate={this.state.backDaysAllowable!==""&&this.state.backDaysAllowable!== undefined?this.state.backDaysAllowable:this.state.inwardDate
                          }
                          maxDate={this.state.futureDaysAllowable!==""&&this.state.futureDaysAllowable!== undefined?this.state.futureDaysAllowable:this.state.inwardDate
                        }
                          value={this.state.priceRequestDate}
                          fullWidth
                          onChange={(e) => this.handleSelect_Date(e, "priceRequestDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",

                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                     isDropdownOpen={this.state.isDropdownOpen}
                      arrayOfData={this.state.branchListData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.branchId}
                      update={this.state.branchId ? 1 : 0}
                      name="branchId"
                      label="Branch"
                      required
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldReq
                      value={this.state.priceRequestSubject}
                      label="Subject"
                      caps="OFF"
                      onChange={this.handleChange("priceRequestSubject")}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <AutoMultiselect
                      options={this.state.supplierListData} // Options to display in the dropdown
                      showCheckbox={true}
                      selectedValues={this.state.supplier} // Preselected value to persist in dropdown
                      name={"departmentListData"}
                      getOpt={this.handleChangeMultiSel}
                      label="Supplier"
                      mandatory="*"
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      value={this.state.comments}
                      type="textarea"
                      label="Comments"
                      cpas="OFF"
                      onChange={this.handleChange("comments")}
                    />
                  </div>
                </Box>
                {this.props.location.state && (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Created By"
                        value={this.state.createdBy}
                        disabled={true}
                      />
                    </div>
                  </Box>
                )}
                {/* {this.props.location.state && (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Created Date"
                        value={this.state.CreatedDate}
                        disabled={true}
                      />
                    </div>
                  </Box>
                )} */}
                {/* {this.props.location.state && (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Status"
                        value={this.state.status}
                        disabled={true}
                      />
                    </div>
                  </Box>
                )} */}
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
const mapStatetoProps = (state) => {
  const { branchList, supplierList ,} = state.DropDownListReducer;
  const {price_enquiry_ListBYID } =
    state.PriceEnquiryReducer;
  return { branchList, supplierList, price_enquiry_ListBYID};
};
export default connect(mapStatetoProps, {
  getBranchList,
  getSupplierList,
})(withRouter(CreateFilldetails));
