import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TabsNavigation from "../../../../../components/TabsNavigation/TabsNavigation";
import ShiftTiming from "./ShiftTiming";
import LeavePolicy from "./LeavePolicy";
class ShiftAndLeavePolicy extends Component {
  render() {
    return (
      <>
        <TabsNavigation
          tabOneLabel="Shift Timings"
          tabTwoLabel="Leave Policies"
          tabOneContent={<ShiftTiming />}
          tabTwoContent={<LeavePolicy />}
        />
      </>
    );
  }
}

const mapStatetoProps = (state) => {};

export default withRouter(connect(mapStatetoProps, {})(ShiftAndLeavePolicy));
