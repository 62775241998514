import {BILLS_LIST,BILLS_LIST_SUCCESSFULL, VENDOR_LIST, VENDOR_LIST_SUCCESSFULL, COST_FACTOR_LIST, COST_FACTOR_LIST_SUCCESSFULL, ADD_BILLS, ADD_BILLS_SUCCESSFULL} from './actionType'

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    BillsList: [],
    VendorList:[],
    costFactorList: [],
    createBills: []

  };

  const BillsReducer = (state = initialState, action) => {
    switch (action.type) {
        case BILLS_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case BILLS_LIST_SUCCESSFULL:
            state = {
              ...state,
              BillsList: action.payload.response,
              loading: true,
            };
            break;
          case VENDOR_LIST:
            state = {
              ...state,
              loading: true,
            }  
            break;
          case VENDOR_LIST_SUCCESSFULL: 
          state = {
            ...state,
            VendorList: action.payload.response,
            loading: true
          }  
          break;
          case COST_FACTOR_LIST: 
          state = {
            ...state,
            loading: true
          }
          break;
          case COST_FACTOR_LIST_SUCCESSFULL:
            state = {
              ...state,
              costFactorList: action.payload.response,
              loading: true
            }
          break;
          case ADD_BILLS:
            state = {
              ...state,
              loading: true,
            };
          break;
          case ADD_BILLS_SUCCESSFULL:
            state = {
              ...state,
              createBills: action.payload.response,
              loading: true,
            };
          break;
          default:
            state = { ...state };
            break;
            

    }
    return state;
  }

  export default BillsReducer;