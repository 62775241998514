import {EXPENSE_BOOKING_LIST,EXPENSE_BOOKING_LIST_SUCCESSFULL,
    WORKER_LIST,WORKER_LIST_SUCCESSFULL,
    ADD_EXPENSE_BOOKING,
    ADD_EXPENSE_BOOKING_SUCCESSFULL,
    COST_CENTER,
    COST_CENTER_SUCCESSFULL,
    EXPENSE_BOOKING_VIEW_BY_ID,
    EXPENSE_BOOKING_VIEW_BY_ID_SUCCESSFULL,
    WORKER_DATA_CLEAR,
    DELETE_FILES,
    DELETE_FILES_SUCCESSFULL,
    UPLOAD_FILES,
    UPLOAD_FILES_SUCCESSFULL
} from './actionType';

export const getExpenseBookingList= (url,history) =>{
    return {
        type:EXPENSE_BOOKING_LIST,
        payload:{url,history}
    }
}
export const ExpenseBookingListSuccessfull = (data)=>{
    return {
        type:EXPENSE_BOOKING_LIST_SUCCESSFULL,
        payload:data
    }
}
export const getWorkerList= (url,history) =>{
    return {
        type:WORKER_LIST,
        payload:{url,history}
    }
}
export const WorkerListSuccessfull = (data)=>{
    return {
        type:WORKER_LIST_SUCCESSFULL,
        payload:data
    }
}

export const setExpenseBookingList = (url,data,history) =>{
    return {
        type:ADD_EXPENSE_BOOKING,
        payload:{url,data,history}
    }
}
export const AddExpenseBookingListSuccessfull = (data)=>{
    return {
        type:ADD_EXPENSE_BOOKING_SUCCESSFULL,
        payload:data
    }
}
export const getExpenseBookingViewById = (url,history) =>{
    return {
        type:EXPENSE_BOOKING_VIEW_BY_ID,
        payload:{url,history}
    }
}
export const getExpenseBookingViewByIdSuccessfull = (data)=>{
    return {
        type:EXPENSE_BOOKING_VIEW_BY_ID_SUCCESSFULL,
        payload:data
    }
}
export const costCenterList = (url,history) =>{
    return {
        type:COST_CENTER,
        payload:{url,history}
    }
}
export const costCenterListSuccessfull = (data)=>{
    return {
        type:COST_CENTER_SUCCESSFULL,
        payload:data
    }
}

export const getWorkerDataClearForExpenseBooking =(data)=>{
    return {
        type:WORKER_DATA_CLEAR,
        payload:data
    }
}
export const DeleteUploadedfiles = (url,history,rowId) => {
    return {
      type: DELETE_FILES,
      payload: { url,history,rowId },
   
    };
  };
  
  export const DeleteUploadedfilesSuccessfull = (data) => {
    return {
      type: DELETE_FILES_SUCCESSFULL,
      payload: data,
    };
  };
  export const Uploadfiles = (url,data,history,recId) => {
    return {
      type: UPLOAD_FILES,
      payload: { url,data,history,recId },
   
    };
  };
  
  export const UploadfilesSuccessfull = (data) => {
    return {
      type: UPLOAD_FILES_SUCCESSFULL,
      payload: data,
    };
  };