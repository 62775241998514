import {BUDGET_HEADS_LIST,BUDGET_HEADS_LIST_SUCCESSFULL,
    CREATE_BUDGET_HEADS,CREATE_BUDGET_HEADS_SUCCESSFULL,
    UPDATE_BUDGET_HEADS,UPDATE_BUDGET_HEADS_SUCCESSFULL,
    BUDGETHEADS_LIST,BUDGETHEADS_LIST_SUCCESSFULL
} from './actionTypes';

export const budgetHeadsList = (url,data,history) =>{
    return {
        type:BUDGET_HEADS_LIST,
        payload:{url,data,history}
    }
}
export const budgetHeadsListSuccessfull = (data)=>{
    return {
        type:BUDGET_HEADS_LIST_SUCCESSFULL,
        payload:data
    }
}
export const budgetHeads_List = (url,data,history) =>{
    return {
        type:BUDGETHEADS_LIST,
        payload:{url,data,history}
    }
}
export const budgetHeads_ListSuccessfull = (data)=>{
    return {
        type:BUDGETHEADS_LIST_SUCCESSFULL,
        payload:data
    }
}
export const CreateBudgetHeads = (url, data, history) => {
    return {
        type: CREATE_BUDGET_HEADS,
        payload: { url, data, history }
    }
}

export const createBudgetHeadsSuccessfull = (data) => {
    return {
        type: CREATE_BUDGET_HEADS_SUCCESSFULL,
        payload: data
    }
}
export const UpdateBudgetHeads = (url, data, history) => {
    return {
        type: UPDATE_BUDGET_HEADS,
        payload: { url, data, history }
    }
}

export const updateBudgetHeadsSuccessfull = (data) => {
    return {
        type: UPDATE_BUDGET_HEADS_SUCCESSFULL,
        payload: data
    }
}
