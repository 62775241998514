import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
// import Checkbox from "../../components/CheckBox/Checkbox";
import Checkbox from '@mui/material/Checkbox'

export default class AutoCompleteSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tags: [],
    }
    this.onTagsChange = this.onTagsChange.bind(this)
  }

  onTagsChange = (event, values) => {
    let tagsIds = null
    let mtags = []
    let i = 0
    values.map((prop, key) => {
      if (i === 0) {
        tagsIds = prop.id
      } else {
        tagsIds = tagsIds + ',' + prop.id
      }
      mtags.push(prop.id)
      i++
    })

    this.setState(
      {
        tags: values,
      },
      () => {
        // This will output an array of objects
        // given by Autocompelte options property.
      },
    )
 
  }

  render() {
    return (
      <div className="autoCompleteDropdownContainer" style={{ width: '100%' }}>
        {/* <label>{this.props.label}</label> */}
        {this.props.label && (
          <label htmlFor="app-input-field">
            {this.props.label} <label className="starcolor">*</label>
          </label>
        )}
        <Autocomplete
          multiple
          required={true}
          id="checkboxes-tags-demo"
          options={this.props.options}
          disableCloseOnSelect
          getOptionLabel={(option) => option.label}
          defaultValue={this.props.defaultValue}
          onChange={this.onTagsChange}
          limitTags={1}
          popupIcon={
            <KeyboardArrowDownIcon sx={{ color: 'hsl(0, 0%, 80%)' }} />
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              {this.props.checkbox ? <Checkbox checked={selected} /> : ''}
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select..." />
          )}
        />
        {/* <Autocomplete
          multiple
          limitTags={1}
          id="multiple-limit-tags"
          options={top100Films}
          getOptionLabel={(option) => option.title}
          //defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
          renderInput={(params) => (
            <TextField {...params} placeholder="Favorites" />
          )}
          sx={{ width: "500px" }}
        /> */}
      </div>
    )
  }
}

// const top100Films = [
//   { title: "The Shawshank Redemption", year: 1994 },
//   { title: "The Godfather", year: 1972 },
//   { title: "The Godfather: Part II", year: 1974 },
//   { title: "The Dark Knight", year: 2008 },
//   { title: "12 Angry Men", year: 1957 },
//   { title: "Schindler's List", year: 1993 },
//   { title: "Pulp Fiction", year: 1994 },
//   { title: "The Lord of the Rings", year: 2003 },
//   { title: "The Good, the Bad and the Ugly", year: 1966 },
//   { title: "Fight Club", year: 1999 },
//   { title: "The Lord of the Rings", year: 2001 },
//   { title: "Star Wars: Episode V", year: 1980 },
//   { title: "Forrest Gump", year: 1994 },
//   { title: "Inception", year: 2010 },
// ];
