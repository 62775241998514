import {
  AGENT_MASTER_LIST,
  CREATE_AGENT_MASTER,
  UPDATE_AGENT_MASTER,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { postAdd, getList } from "../../../helpers/Server_Helper";
import {
  agentMasterListSuccessfull,
  createAgentMasterSuccessfull,
  updateAgentMasterSuccessfull,
} from "./actions";
import swal from "sweetalert";

function* AgentMasterList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(agentMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateAgentMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createAgentMasterSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
    history.push("/agent_master");
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateAgentMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateAgentMasterSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
    history.push("/agent_master");
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

export function* watchAgentMasterList() {
  yield takeEvery(AGENT_MASTER_LIST, AgentMasterList);
}
export function* watchCreateAgentMaster() {
  yield takeEvery(CREATE_AGENT_MASTER, CreateAgentMaster);
}
export function* watchUpdateAgentMaster() {
  yield takeEvery(UPDATE_AGENT_MASTER, UpdateAgentMaster);
}

function* AgentMasterSaga() {
  yield all([
    fork(watchAgentMasterList),
    fork(watchCreateAgentMaster),
    fork(watchUpdateAgentMaster),
  ]);
}

export default AgentMasterSaga;
