import React, { Component } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "../../components/Buttons/Buttons";
import CustomButton from "../../components/Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Logout from "../../assets/images/logout.png";
import ProfileArrowIcon from "../../assets/images/profileHeaderArrowIcon.png";
import backicon from "../../assets/images/backicon.svg";
import "react-datepicker/dist/react-datepicker.css";
// import NotificationsNoneIcon from "../../assets/images/notification.png";
import NotificationIcon from "@mui/icons-material/NotificationsNoneOutlined";
import NotificationPopup from "../CommonPopup/NotificationPopup";
// import { getAcadamicYears } from "../../store/auth/FinancialYear/actions";
import ListIcon from "@mui/icons-material/List";
import ArticleIcon from "@mui/icons-material/Article";
import Select from "react-select";
import { getEBIdSuccessfull } from "../../store/HRMS/EmployeeDataBase/actions";
// import { getLeftSideMenu } from "../../store/Company/BasicDetails/LeftSideBar/actions";


// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { serverApi } from "../../helpers/Consts";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorElUser: false,
      anchorElNav: false,
      buttonbranch: this.props.buttonbranch,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      startDate: new Date(),
      showNotification: false,
      fYListData: JSON.parse(window.localStorage.getItem("state")),
      financialYearsList: [],
      acadamicYear: "",
      showFy: false,
      FinancialYears: [
        {
          value: "",
          label: "",
          name: "",
        },
      ],
      selectedValue: {
        value: 0,
        label: "",
        name: "",
      },
    };

    this.handleOpenUserMenu = this.handleOpenUserMenu.bind(this);
    this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);
    this.handleCloseNavMenu = this.handleCloseNavMenu.bind(this);
    this.handleOpenNotification = this.handleOpenNotification.bind(this);
    this.broadcastChannel = new BroadcastChannel("session");
    this.broadcastChannel = new BroadcastChannel('duplicate-tab-channel');
  }
  setState(state) {
    // window.localStorage.setItem('state', JSON.stringify(state))
    super.setState(state);
  }
  
  handleClearUser = () => {
    localStorage.removeItem("ProgressBar");
    localStorage.removeItem("EmpData");
    localStorage.removeItem("ebId");
    localStorage.removeItem("empStatus");

    this.props.getEBIdSuccessfull(null);
  };

  componentDidMount() {  
    const decodedJwt = parseJwt(localStorage.getItem("access_token"));
    if (decodedJwt.exp * 1000 < Date.now()) {
      this.handleLogout();
      return; // Exit early if user is not authenticated
    }
  
    const { acadamicYearsList, location } = this.props;
    const storedYear = localStorage.getItem("acadamicYear");
    const storedYearName = localStorage.getItem("acadamicYearName");
  
    if (storedYear && storedYearName) {
      // Use the values from localStorage if available
      const selectedValue = {
        value: storedYear,
        name: storedYearName,
        label: storedYearName,
      };
  
      this.setState({
        selectedValue,
      });
    } else if (acadamicYearsList && acadamicYearsList.data && acadamicYearsList.data.length > 0) {
      // Use academic years data if available and localStorage values are not set
      const defaultYearData = acadamicYearsList.data[0];
      const selectedYearData = location.pathname === "/dashboard"
        ? acadamicYearsList.data[0] // Use the first year data if on dashboard
        : acadamicYearsList.data.find(yearData => yearData.year.toString() === storedYear);
  
      const selectedValue = {
        value: selectedYearData ? selectedYearData.year : defaultYearData.year,
        name: selectedYearData ? selectedYearData.displayLable : "2024-2025",
        label: selectedYearData ? selectedYearData.displayLable : "2024-2025",
      };
  
      // Set the selected value and store in localStorage
      this.setState({
        selectedValue,
      });
      localStorage.setItem("acadamicYear", selectedValue.value);
      localStorage.setItem("acadamicYearName", selectedValue.name);
    } else {
      // Set default year if academic years data is not available or empty
      const defaultYear = 2024;
      const defaultYearName = "2024-2025";
      const selectedValue = {
        value: defaultYear,
        name: defaultYearName,
        label: defaultYearName,
      };
  
      // Set the selected value and store in localStorage
      this.setState({
        selectedValue,
      });
      localStorage.setItem("acadamicYear", selectedValue.value);
      localStorage.setItem("acadamicYearName", selectedValue.name);
    }
  
    this.broadcastChannel.onmessage = (event) => {
      if (event.data === "logout") {
        // Handle logout event
      }
    };
  
    document.title = `${this.capitalizeFirstLetter(location.pathname)} | VOW`;
  }
  
  capitalizeFirstLetter = (str) => {
    var title = str.charAt(1).toUpperCase() + str.slice(2);
    var i,
      frags = title.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  };

  componentWillReceiveProps(props) {
    if (props.acadamicYearsList) {
      let financialyearsList = [];
      if (props.acadamicYearsList) {
        if (props.acadamicYearsList.data !== undefined) {
          props.acadamicYearsList.data.map((yearData, index) => {
            financialyearsList.push({
              value: yearData.year,
              label: yearData.displayLable,
              name: yearData.displayLable,
            });
          });
        }
      }
      this.setState({
        financialYearsList: financialyearsList,
      });
    }

    this.handleOpenUserMenu = this.handleOpenUserMenu.bind(this);
    this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);
    this.handleCloseNavMenu = this.handleCloseNavMenu.bind(this);
    this.handleOpenNotification = this.handleOpenNotification.bind(this);
  }
  handleCreateBranch = () => {
    this.setState({
      buttonbranch: 0,
    });
  };
  handleCreateRole = () => {
    this.setState({
      buttonbranch: 0,
    });

    this.props.onNameChange("Role Creation", 0);
    // <CreateRole />
  };
  handleCreateUser = () => {
    this.setState({
      buttonbranch: 0,
    });

    this.props.getEBIdSuccessfull(null);
  };

  handleBillingDetials = () => {
    this.setState({
      buttonbranch: 0,
    });
  };

  handleOpenUserMenu(event) {
    this.setState({
      anchorElUser: event.currentTarget,
    });}
  handleOpenNotification() {
    this.setState({
      showNotification: !this.state.showNotification,
    });
  }
  handleCloseNavMenu = () => {
    this.setState({
      anchorElNav: false,
      anchorElUser: false,
    });
  };

  handleCloseUserMenu = () => {
    this.setState({
      anchorElNav: false,
      anchorElUser: false,
    });
  };

  handleClick = (value) => () => {
    if (value === 2) {
      this.props.history.push("/CreatePassword");
    }
    if (value === 1) {
      this.props.history.push("/myProfile");
    }
    this.setState({
      anchorElNav: false,
      anchorElUser: false,
    });
  };

  handleLogout = () => {
    localStorage.clear();
    this.props.history.push("/Logout");
    this.broadcastChannel.postMessage("logout");
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    this.setState({
      acadamicYear: selectedValue.value,
      showFy: true,
      selectedValue: selectedValue,
    });
    localStorage.setItem("acadamicYear", selectedValue.value);
    localStorage.setItem("acadamicYearName", selectedValue.name);
    this.props.history.push("/dashboard");
  };

  render() {
    var data = [
      {
        menuId: 1,
        menuName: "My Profile",
        link: "/myProfile",
      },
      {
        menuId: 2,
        menuName: "Settings",
        link: "ResetPassword",
      },
    ];

    return (
      <>
        <AppBar
          position="static"
          className="consoleHeaderContainer"
          style={{ background: "transparent", boxShadow: "none" }}
        >
          <Toolbar disableGutters>
            <Grid container xs={12}>
              <Grid item xs={6}>
                {this.state.value}
                <div className="academicDropdown">
                  <Select
                    classNamePrefix="react-select"
                    options={this.state.financialYearsList}
                    value={this.state.selectedValue}
                    onChange={this.handleSelectChange}
                    defaultValue={this.state.selectedValue}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              xs={5}
              className="headerRightBlock"
              style={{ marginRight: "0px" }}
            ></Grid>
            <Grid item xs={1} className="headerRightBlock">
              <Box sx={{ flexGrow: 0, display: "flex" }}>
                {/* Notification  */}

                <div className="notification-block" style={{ display: "none" }}>
                  <Tooltip title="Notification">
                    <div className="notificationIconContainer">
                      <IconButton onClick={this.handleOpenNotification}>
                        <NotificationIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </div>

                {this.state.showNotification && <NotificationPopup />}
                <Tooltip title="Profile Settings">
                  <IconButton
                    onClick={this.handleOpenUserMenu}
                    sx={{ p: 0 }}
                    className="profileSettingBlock"
                  >
                    <Avatar alt="Remy Sharp" src="" />
                    <img
                      src={ProfileArrowIcon}
                      alt=""
                      className="profileArrowIcon"
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "25px" }}
                  id="menu-appbar"
                  className="headerProfilePicBlock"
                  anchorEl={this.state.anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(this.state.anchorElUser)}
                  onClose={this.handleCloseUserMenu}
                >
                  <div className="profileContainer">
                    <Grid container className="profileHeader">
                      <Grid item xs={2}>
                        <Avatar alt="Remy Sharp" src="" />
                      </Grid>
                      <Grid item xs={10}>
                        <Grid item xs={12}>
                          <h3>
                            {this.state.userDit
                              ? this.state.userDit.userName
                              : ""}
                          </h3>
                        </Grid>
                        <Grid item xs={12}>
                          <h6>
                            {this.state.userDit ? this.state.userDit.email : ""}
                          </h6>
                        </Grid>
                      </Grid>
                    </Grid>
                    {data.map((menu) => (
                      <MenuItem
                        key={menu.menuId}
                        className="profileLinksBlock"
                        onClick={this.handleClick(menu.menuId)}
                        disabled={true}
                      >
                        <Typography textAlign="center" name={menu.menuName}>
                          {menu.menuName}
                        </Typography>
                      </MenuItem>
                    ))}
                    <Link to="/logout" className="">
                      <Grid container className="profileFooter">
                        <Grid item xs={12}>
                          <MenuItem className="profileLinksBlock">
                            <Typography textAlign="center">
                              <img src={Logout} alt="" />
                              Logout
                            </Typography>
                          </MenuItem>
                        </Grid>
                      </Grid>
                    </Link>
                  </div>
                </Menu>
              </Box>
            </Grid>
          </Toolbar>
        </AppBar>
        <Toolbar disableGutters className="headerHeadingAndButtonBlock">
          <Grid container xs={12}>
            <Grid item xs={4}>
              <h3 className="consoleHeadingText">
                {this.props.backIcon > 0 ? (
                  <Link to={this.props.backLink}>
                    <img src={backicon} alt="back" />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.childName}
              </h3>
              <h6 className="consoleHeadingText">{this.props.headdate}</h6>
            </Grid>

            <Grid
              item
              xs={8}
              className="headerRightBlock"
              style={{ marginRight: "0px" }}
            >
              <Box sx={{ flexGrow: 0 }}>
                {this.props.buttonbranch === "purchase_1" ? (
                  <Link to="/create_indent">
                    <Button
                      label="+ Create Indent"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}

                {this.props.buttonbranch === "purchase_2" ? (
                  <Link to="/create_priceenquiry">
                    <Button
                      label="+ Create Price Enquiry"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                
                {this.state.userDit.roleId !==100 &&
                this.props.buttonbranch === "purchase_3" ? (
                  <Link to="/create_workorder">
                    <Button
                      label="+ Create Work Order"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "purchase_4" ? (
                  <Link to="/create_inward">
                    <Button
                      label={this.state.userDit.roleId === 100?"+ Create Dispatch":"+ Create Inward Entry"}
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "purchase_8" ? (
                  <Link to="/create_WorkReport">
                    <Button
                      label="+ Create Work Report"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.state.userDit.roleId !==100 &&
                this.props.buttonbranch === "purchase_5" ? (
                  <Link to="/create_purchase_order">
                    <Button
                      label="+ Create Purchase Order"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "purchase_6" ? (
                  <Link to="/create_DC_note">
                    <Button
                      label="+ Create Debit Note"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "purchase_7" ? (
                  <Link to="/create_openIndent">
                    <Button
                      label="+ Create Open Indent"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "store_1" ? (
                  <Link to="/create_material_request">
                    <Button
                      label="+ Create Material Request"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "store_2" ? (
                  <Link to="/create_consumption">
                    <Button
                      label="+ Create Consumption"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "store_3" ? (
                  <Link to="/createStockTransfer">
                    <Button
                      label="+ Create Stock Transfer"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "store_4" ? (
                  <Link to="/createStockReturn">
                    <Button
                      label="+ Create Stock Return"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "HRMS_01" ? (
                  <>
                    <div className="listOfEmployeeBlock">
                      <Link to="/bulk_employee">
                        <CustomButton
                          label="Employee Bulk Upload"
                          className="greenBorderButton"
                        />
                      </Link>
                      <Link to="/listOf_employees">
                        <CustomButton
                          label="List of Employees"
                          className="greenBorderButton"
                        />
                      </Link>

                      {localStorage.getItem("HrmsAccess") === "true" ? (
                        <Link
                          to="/add_employee"
                          handleClick={this.handleClearUser}
                        >
                          <CustomButton
                            label="+ Add Employee"
                            className="greenButton"
                            handleClick={this.handleClearUser}
                          />
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "HRMS_13" ? (
                  <>
                    <div className="listOfEmployeeBlock">
                      {localStorage.getItem("HrmsAccess") === "true" ? (
                        <Link to="/bulk_employee">
                          <CustomButton
                            label="Employee Bulk Upload"
                            className="greenBorderButton"
                          />
                        </Link>
                      ) : (
                        ""
                      )}
                      <Link to="/listOf_employees">
                        <CustomButton
                          label="List of Employees"
                          className="greenBorderButton"
                        />
                      </Link>

                      {localStorage.getItem("HrmsAccess") === "true" ? (
                        <Link to="/add_employee">
                          <CustomButton
                            label="+ Add Employee"
                            className="greenButton"
                            handleClick={this.handleClearUser}
                          />
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "HRMS_12" ? (
                  <>
                    {localStorage.getItem("HrmsAccess") === "true" ? (
                      <Link to="/add_employee">
                        <Button
                          label="+ Add Employee"
                          className="greenButton"
                          handleClick={this.handleCreateUser}
                        />
                      </Link>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
                {/* Attendance Calender sub-module */}
                {this.props.buttonbranch === "HRMS_02" ? (
                  <>
                    <div className="listOfAttendanceBlock">
                      <Link to="/Attendance_list">
                        <CustomButton
                          label={" Attendance List"}
                          className="greenBorderButton"
                        />
                      </Link>
                      <Link to="/logs">
                        <CustomButton
                          label={"Attendance Logs"}
                          className="greenBorderButton"
                        />
                      </Link>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "HRMS_03" ? (
                  <div className="listOfAttendanceBlock">
                    <Link to="/attencalendar">
                      <Button
                        label="Attendance Calendar"
                        className="greenBorderButton"
                      />
                    </Link>
                    <Link to="/logs">
                      <Button
                        label="Attendance Logs"
                        className="greenBorderButton"
                      />
                    </Link>

                    <Link to="/mark_attendance">
                      <Button label="Mark Attendance" className="greenButton" />
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "HRMS_04" ? (
                  <Link to="/bulk_attendance">
                    <Button label="Bulk Attendance" className="greenButton" />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "HRMS_14" ? (
                  <>
                    <div className="listOfAttendanceBlock">
                      <Link to="/Attendance_list">
                        <CustomButton
                          label={"Attendance Lists"}
                          className="greenBorderButton"
                        />
                      </Link>
                      <Link to="/attencalendar">
                        <Button
                          label="Attendance Calender"
                          className="greenButton"
                          handleClick={this.handleCreateUser}
                        />
                      </Link>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "HRMS_15" ? (
                  <>
                    <div className="iconButtonsHrms">
                      <Link to="/attencalendar">
                        <CustomButton
                          muIcon={<ListIcon />}
                          className="blueBorderIconButton"
                        />
                      </Link>
                      <Link to="/logs">
                        <Button
                          muIcon={<ArticleIcon />}
                          className="blueBorderIconButton"
                          handleClick={this.handleCreateUser}
                        />
                      </Link>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "HRMS_06" ? (
                  <Link to="/apply_leave">
                    <Button
                      label="Apply Leave"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "HRMS_07" ? (
                  <Link to="/create_request">
                    <Button
                      label="+ Create Request"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}

                {this.props.buttonbranch === "HRMS_08" ? (
                  <Link to="/create_expense">
                    <Button
                      label="+ Create Expense Booking"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "HRMS_09" ? (
                  <Link to="/create_advanceRequest">
                    <Button
                      label="+ Create Advance Request"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "HRMS_10" ? (
                  <Link to="/create_new">
                    <Button
                      label="+ Create Pay Register"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "PayScheme_1" ? (
                  <Link to="/create_payScheme_parameters">
                    <Button
                      label="+ Create PayScheme Parameters"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "PayScheme_2" ? (
                  <Link to="/create_payScheme_creation">
                    <Button
                      label="+ Create Pay Scheme"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "spell_master_1" ? (
                  <Link to="/create_spell_master">
                    <Button
                      label="+ Create Spell master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_3" ? (
                  <Link to="/create_department_master">
                    <Button
                      label="+ Create Department Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_4" ? (
                  <Link to="/create_supplier_items">
                    <Button
                      label="+ Create Supplier Items"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_5" ? (
                  <Link to="/create_item_master">
                    <Button
                      label="+ Create Item Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_6" ? (
                  <Link to="/create_item_group_master">
                    <Button
                      label="+ Create ItemGroup Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_7" ? (
                  <Link to="/create_quality_master">
                    <Button
                      label="+ Create Jute Quality Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_8" ? (
                  <Link to="/create_weaving_quality_master">
                    <Button
                      label="+ Create Weaving Quality Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_9" ? (
                  <Link to="/create_spinning_quality_master">
                    <Button
                      label="+ Create Spinning Quality Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_10" ? (
                  <Link to="/create_machine_master">
                    <Button
                      label="+ Create Machine Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_11" ? (
                  <Link to="/create_company_master">
                    <Button
                      label="+ Create Company Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_12" ? (
                  <Link to="/create_branch_master">
                    <Button
                      label="+ Create Branch Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_13" ? (
                  <Link to="/create_sub_department_master">
                    <Button
                      label="+ Create Sub Department Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_14" ? (
                  <Link to="/create_vehicle_types_master">
                    <Button
                      label="+ Create Vehicle Types Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_15" ? (
                  <Link to="/create_mukam_master">
                    <Button
                      label="+ Create Mukam Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_16" ? (
                  <Link to="/create_yarn_master">
                    <Button
                      label="+ Create Yarn Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_17" ? (
                  <Link to="/create_Ledger_Master">
                    <Button
                      label="+ Create Ledger Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_18" ? (
                  <Link to="/create_Transporter_master">
                    <Button
                      label="+ Create Transporter Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_19" ? (
                  <Link to="/create_designation_master">
                    <Button
                      label="+ Create Designation Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_20" ? (
                  <Link to="/create_terms_master">
                    <Button
                      label="+ Create Terms "
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_21" ? (
                  <Link to="/create_broker_master">
                    <Button
                      label="+ Create Broker Master "
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_22" ? (
                  <Link to="/create_agent_master">
                    <Button
                      label="+ Create Agent Master "
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_23" ? (
                  <Link to="/create__Worker_Category_Master">
                    <Button
                      label="+ Create Worker Category Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_24" ? (
                  <Link to="/create_Cost_Center_Master">
                    <Button
                      label="+ Create Cost Center Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_25" ? (
                  <Link to="/create_Travel_Catageries">
                    <Button
                      label="+ Create Travel Categories"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_26" ? (
                  <Link to="/create_Bank_Accounts">
                    <Button
                      label="+ Create Bank Account"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_27" ? (
                  <Link to="/create_leave_types">
                    <Button
                      label="+ Create Leave Types"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_28" ? (
                  <Link to="/create_helptopic">
                    <Button
                      label="+ Create Help Topic"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_29" ? (
                  <Link to="/create__problem_master">
                    <Button
                      label="+ Create Problem Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_30" ? (
                  <Link to="/create__carriers">
                    <Button
                      label="+ Create Carriers"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_31" ? (
                  <Link to="/create__packagemaster">
                    <Button
                      label="+ Create Package Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_32" ? (
                  <Link to="/create_Customer_Master">
                    <Button
                      label="+ Create Customer Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_33" ? (
                  <Link to="/create_warehouse_master">
                    <Button
                      label="+ Create Warehouse Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_34" ? (
                  <Link to="/create_Ledger_Groups">
                    <Button
                      label="+ Create Ledger Groups Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_35" ? (
                  <Link to="/create_supplier_master">
                    <Button
                      label="+ Create Supplier Master"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_36" ? (
                  <Link to="/create_budget_heads">
                    <Button
                      label="+ Create Budget Heads"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_37" ? (
                  <Link to="/Holidays_List">
                    <Button
                      label="Holiday Lists"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "master_38" ? (
                  <Link to="/create_holiday">
                    <Button
                      label="+Create Holiday"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {/* Accounting Module */}
                {this.props.buttonbranch === "accounting_1" ? (
                  <Link to="/create_bills">
                    <Button
                      label="+ Create Bills"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "accounting_2" ? (
                  <Link to="/create_payments">
                    <Button
                      label="+ Create Payments"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "accounting_3" ? (
                  <Link to="/create_receipts">
                    <Button
                      label="+ Create Receipts"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {/* Project Modules */}
                {this.props.buttonbranch === "project_1" ? (
                  <Link to="/create_project_list">
                    <Button
                      label="+ Create Project List"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "project_2" ? (
                  <Link to="/create_bom">
                    <Button
                      label="+ Create BOM"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "project_3" ? (
                  <Link to="/create_boq">
                    <Button
                      label="+ Create BOQ"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "project_4" ? (
                  <Link to="/create_cost_plan">
                    <Button
                      label="+ Create Cost Plan"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "sales_1" ? (
                  <Link to="/create_quotation">
                    <Button
                      label="+ Create Quotation"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "sales_2" ? (
                  <Link to="/create_sales_order">
                    <Button
                      label="+ Create Sales Order "
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "sales_3" ? (
                  <Link to="/create_delivery_order">
                    <Button
                      label="+ Create Delivery Order"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "sales_4" ? (
                  <Link to="/create_invoice">
                    <Button
                      label="+ Create Invoice"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "sales_5" ? (
                  <Link to="/create_ReOccuring_invoice">
                    <Button
                      label="+ Create Re-Occuring Invoice"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                  {this.props.buttonbranch === "juteIndent_1" ? (
                  <Link to="/create_jute_indent">
                    <Button
                      label="+ Create Jute Indent"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "jutePO_2" ? (
                  <Link to="/create_jute_purchase_order">
                    <Button
                      label="+ Create Jute Purchase Order"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "juteIndent_2" ? (
                  <Link to="/create_jute_gate_entry">
                    <Button
                      label="+ Create Jute Gate Entry"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "jute_7" ? (
                  <Link to="/create_jute_issue">
                    <Button
                      label="+ Create Jute Issue"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "jute_8" ? (
                  <Link to="/create_jute_conversion">
                    <Button
                      label="+ Create Jute Converstion"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "jute_9" ? (
                  <Link to="/create_batch_planning_form">
                    <Button
                      label="+ Create Jute Batch Planning Form"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "jp_finishing_entry" ? (
                  <Link to="/create_finishing_entry">
                    <Button
                      label="+ Create Fininshing Enrty"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "jp_weaving_production" ? (
                  <Link to="/create_weaving_production">
                    <Button
                      label="+ Create Weaving Production"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "jp_beaming_production" ? (
                  <Link to="/create_beaming_production">
                    <Button
                      label="+ Create Beaming Production"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "jp_cuts_and_jugar_entry" ? (
                  <Link to="/create_cuts_and_jugar_entry">
                    <Button
                      label="+ Create Cuts and Jugar Entry"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "jp_wastage_entry" ? (
                  <Link to="/create_wasteage_entry">
                    <Button
                      label="+ Create Wastage Entry"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "jp_winding_doff_entry" ? (
                  <Link to="/create_winding_doff_entry">
                    <Button
                      label="+ Create Winding Doff Entry"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === "jp_spinning_doff_entry" ? (
                  <Link to="/create_spinning_doff_entry">
                    <Button
                      label="+ Create Spinning Doff Entry"
                      className="greenButton"
                      handleClick={this.handleCreateUser}
                    />
                  </Link>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
        {/* </Container> */}
      </>
    );
  }
}

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  const { acadamicYearsList } = state.acadamicYearsList;
  return { loginError, acadamicYearsList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getEBIdSuccessfull,
  })(Header)
);
