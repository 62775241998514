import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import RadioButton from "../../../components/RadioButton/RadioButton";
import CustomButton from "../../../components/Buttons/Buttons";
import Link from "@mui/material/Link";
import { serverApi } from "../../../helpers/Consts";
import {
  getItemGroupMasterList,
  allItemGroupMasterList,
  getTax_List,
  getBranchList,
} from "../../../store/Global/DropDownApis/actions";
import { isEmptyWithZero } from "../../../Utilities/helper";

import {
  getItemCodeByItemGroup,
  getIndentListMaster,
  getItemByItemCode,
  getUomInItemMaster,
  getItemMasterViewById,
} from "../../../store/Master/ItemMaster/actions";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import {
  createItemMaster,
  updateItemMaster,
} from "../../../store/Master/ItemMaster/actions";
// import AutoMultiselect from "../../../components/Dropdown/AutoMultiselect";
import AutoMultiselect from "../../../components/Dropdown/AutoMultiSelectNew";

import swal from "sweetalert";
import { StockForm } from "./BranchStockForm";

class CreateItemMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      item_group: "",
      item_Code: "",
      item_Description: "",
      UOM_Code: "",
      HSN_Code: "",
      displyHSN_Code: "",
      Item_Rate: "",
      direct_indirect: "",
      Legacy_Item_Code: "",
      Max_Value: "",
      Min_Order_Value: "",
      tax: "",
      taxId: "",
      consumable: "",
      saleable: "",
      finished_Goods_saleable: "",
      Tangible: "",
      stand_By: "",
      item_status: "",
      payment: "",
      item_types: "",
      indentTypeId: "",
      installation_rate: "",
      created_by: "",
      installationRateYes: false,
      installationRateNo: true,
      installationValue: "",
      textFieldNormalBlock: "none",
      reorder_qunatity: "",
      Indent_List_Master: [],
      installationRate: 0,
      uom_List: [],
      ItemGroupMasterList: [],
      indentItemId: [],
      createdBy: "",
      createdOn: "",
      tangible: null,
      locationState: "",
      minMax: [],
      formData: null,
    };
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    this.setState({
      locationState: this.props.location,
    });
    window.addEventListener("scroll", this.handleScroll);
    if (this.props.location.state) {
      this.props.getItemMasterViewById(
        serverApi.ITEM_MASTER_VIEW_BY_ID +
          this.props.location.state.rowData.itemId +
          "/" +
          this.state.userDit.cipher,
        this.props.history
      );
    }

    this.props.allItemGroupMasterList(
      serverApi.GET_ALL_ITEMGROUP_MASTER_LIST +
        localStorage.getItem("companyId") +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    );
    this.props.getTax_List(
      serverApi.GET_ALL_TAXLIST +
        this.state.companyId +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    );

    this.props.getUomInItemMaster(
      serverApi.GET_ALL_UOM_DD_LIST + 1 + "/companyId/" + 0,
      this.props.history
    );
    this.props.getIndentListMaster(
      serverApi.INDENT_TYPE_LIST_MASTERS,
      this.props.history
    );
    this.props.getBranchList(
      serverApi.BRANCH_LIST + this.state.companyId,
      this.props.history
    );

    if (this.state.userDit) {
      this.state.userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              if (item.hsnCode !== undefined) {
                this.setState({
                  displyHSN_Code: item.hsnCode,
                });
              }
            });
          }
      });
    }
  }
  componentWillReceiveProps(props) {
    if (props.taxList) {
      if (props.taxList.data) {
        let list = props.taxList.data.filter((item) => {
          if (item.value != 0) {
            return item;
          }
        });
        this.setState({
          taxList: list,
        });
      }
    }

    if (props.Indent_List_Master.data) {
      var list = [];

      let listData = props.Indent_List_Master.data.filter((item) => {
        if (item.label != "Select....") {
          return item;
        }
      });

      listData.map((item) => {
        list.push({
          label: item.label,
          name: item.label,
          value: item.name.split("_", 2)[1],
        });
      });
      this.setState({
        Indent_List_Master: list,
      });
    }
    if (props.allItemGroupMaster_List) {
      if (props.allItemGroupMaster_List.data) {
        let list = props.allItemGroupMaster_List.data.filter((prop) => {
          if (prop.value !== "0") {
            return prop;
          }
        });
        this.setState({
          ItemGroupMasterList: list, // updating state name list dropdown
        });
      }
    }
    if (props.branchList) {
      if (props.branchList.data) {
        let list = props.branchList.data.filter((prop) => {
          if (prop.value !== 0) {
            return prop;
          }
        });
        this.setState({
          branchList: list, // updating state name list dropdown
        });
      }
    }
    if (props.itemMasterUomList) {
      var list = [];
      if (props.itemMasterUomList.data) {
        props.itemMasterUomList.data.map((prop) => {
          list.push({
            name: prop.uomDsc,
            value: prop.uomCode,
            label: prop.uomCode,
          });
        });
        this.setState({
          uom_List: list,
        });
      }
    }

    if (props.location.state) {
      if (props.itemMasterViewByIdList) {
        if (props.itemMasterViewByIdList.data) {
          if (
            props.itemMasterViewByIdList.data.data.installationRate !== 0 &&
            props.itemMasterViewByIdList.data.data.installationRate !== null
          ) {
            this.setState({
              installationRateYes: true,
              installationRateNo: false,
            });
          } else {
            this.setState({
              installationRateYes: false,
              installationRateNo: true,
            });
          }

          if (props.itemMasterViewByIdList.data.data.tangible === "Y") {
            this.setState({
              tangibleYes: true,
              tangibleNo: false,
            });
          } else {
            this.setState({
              tangibleYes: false,
              tangibleNo: true,
            });
          }

          this.setState({
            item_group: props.itemMasterViewByIdList.data.data.grpCode,
            item_Code: props.location.state.rowData.itemCode,
            item_Description: props.itemMasterViewByIdList.data.data.itemDsc,
            UOM_Code: props.itemMasterViewByIdList.data.data.uomCode,
            HSN_Code: props.itemMasterViewByIdList.data.data.hsnCode,
            Item_Rate: props.itemMasterViewByIdList.data.data.srRate,
            tax: props.itemMasterViewByIdList.data.data.taxId,
            Legacy_Item_Code:
              props.itemMasterViewByIdList.data.data.legacyItemCode,
            Max_Value: props.itemMasterViewByIdList.data.data.max,
            Min_Order_Value:
              props.itemMasterViewByIdList.data.data.maxOrderValue,
            item_status:
              props.itemMasterViewByIdList.data.data.activeFlag == "Y" ? 1 : 2,
            installationRate:
              props.itemMasterViewByIdList.data.data.installationRate,
            item_types: props.itemMasterViewByIdList.data.data.mappDto.map(
              (item) => {
                return {
                  indentTypeId: item.indentTypeId,
                  autoId: item.autoId,
                  isActive: item.isActive,
                  isMapped: true,
                };
              }
            ),
            tangible: props.itemMasterViewByIdList.data.data.tangible,
            reorder_qunatity:
              props.itemMasterViewByIdList.data.data.reorderQuantity,
            createdBy: props.itemMasterViewByIdList.data.data.createdBy,
            createdOn:
              props.itemMasterViewByIdList.data.data.autoInsertDateTime,
            minMax: props.itemMasterViewByIdList.data.data.minMax || [],
          });
          var deptlist = [];
          if (props.itemMasterViewByIdList.data.data) {
            if (props.itemMasterViewByIdList.data.data.mappDto) {
              props.itemMasterViewByIdList.data.data.mappDto.map((arr) => {
                this.state.Indent_List_Master.map((p, i) => {
                  if (arr.indentTypeId == p.value) {
                    deptlist.push({
                      name: p.name,
                      value: p.value,
                      label: p.label,
                      autoId: arr.autoId,
                    });
                  }
                });
              });
              this.setState({
                indentItemId: deptlist,
              });
            }
          }
        }
      }
    }
  }
  // function for radio buttons
  onHandleChange = (installationValue) => {
    let installationRateYes = false;
    let installationRateNo = false;
    if (installationValue === "Yes") {
      installationRateYes = true;
      this.setState({
        installationRate: 0,
      });
      // installationRateNo = false;
    } else if (installationValue === "No") {
      installationRateNo = true;
      // installationRateYes = false;
      this.setState({
        installationRate: null,
      });
    }
    this.setState({
      installationValue: installationValue,
      installationRateYes: installationRateYes,
      installationRateNo: installationRateNo,
    });
  };
  onhandleChange = (tangibleValue) => {
    let tangibleYes = false;
    let tangibleNo = false;
    if (tangibleValue === "Yes") {
      tangibleYes = true;
      tangibleNo = false;
    } else if (tangibleValue === "No") {
      tangibleNo = true;
      tangibleYes = false;
    }
    this.setState({
      tangibleValue: tangibleValue,
      tangibleYes: tangibleYes,
      tangibleNo: tangibleNo,
    });
  };
  // function for create and update api integration
  onClickCreateUpdate = () => {
    let itemTypes = [];
    this.state.indentItemId.map((item) => {
      itemTypes.push({
        indentTypeId: item.value,
      });
    });
    const {
      item_group,
      item_Code,
      item_Description,
      tax,
      item_types,
      installationRateYes,
      installationRate,
      tangible,
    } = this.state;
   
    if (tangible === null || tangible === "") {
      swal("Please Select the Tangible");
      return false;
    }
    if (item_group === "" || item_group === undefined || item_group === "0") {
      swal("Please Select the Item Group");
      return false;
    }
    if (item_Code === "" || item_Code === undefined) {
      swal("Please Enter Item Code");
      return false;
    }
    if (item_Description === "" || item_Description === undefined) {
      swal("Please Enter Item Description");
      return false;
    }
    if (this.state.tangible !== "N" && isEmptyWithZero(this.state.UOM_Code)) {
      swal("Please Enter UOM Code");
      return false;
    }
    // if (this.state.displyHSN_Code === "1") {
    if (this.state.HSN_Code === "") {
      swal("Please Enter the HSN Code");
      return false;
    }
    // }
    if (tax === "" || tax === 0) {
      swal("Please Select Tax Type");
      return false;
    }

    if (
      item_types.length === 0
    ) {
      swal("Please Select Item type");
      return false;
    }

    if (installationRateYes) {
      if (installationRate == 0) {
        swal("Please enter installation rate.");
        return false;
      }
    }
    if (this.props.location.state === undefined) {
      const data = {
        activeFlag: "Y",
        balesPerBag: "",
        companyId: this.state.companyId,
        consumable: "Y",
        createdBy: this.state.userDit.userId,
        deptId: 1,
        directIndirectTag: "",
        expiryDate: this.state.userDit.expiryDate,
        grpCode: this.state.item_group,
        hsnCode: this.state.HSN_Code,
        id: this.state.item_Code,
        isActive: 1,
        itemDsc: this.state.item_Description,
        legacyItemCode: this.state.Legacy_Item_Code,
        mappDto: itemTypes,
        max: this.state.Max_Value,
        maxOrderValue: this.state.Min_Order_Value,
        modBy: "string",
        multiFact: 0,
        op2018: 0,
        saleable: "Y",
        srRate: this.state.Item_Rate,
        standBy: "Y",
        taxId: this.state.taxId,
        threshold: 0,
        uomCode: this.state.UOM_Code,
        weight: "",
        installationRate: this.state.installationRate,
        reorderQuantity: Number(this.state.reorder_qunatity),
        finishedGoodSaleable: "N",
        tangible: this.state.tangible,
        minMax: this.state.formData,
      };

      this.props.createItemMaster(
        serverApi.CREATE_ITEM_MASTER,
        data,
        this.props.history
      ); // calling create api for item master
    } else {
      //update payload
      let itemTypesData = [];
      this.state.indentItemId.map((item) => {
        itemTypesData.push({
          indentTypeId: item.value,
          autoId: item.autoId,
          isActive: 1,
        });
      });

      if (this.props.itemMasterViewByIdList.data) {
        if (this.props.itemMasterViewByIdList.data.data) {
          const data = {
            activeFlag: this.state.item_status === "2" ? "N" : "Y",
            balesPerBag: "",
            itemId: this.props.location.state.rowData.itemId,
            companyId: this.state.companyId,
            consumable: "Y",
            modBy: "2",
            deptId: 1,
            directIndirectTag: "I",
            expiryDate: null,
            grpCode: this.state.item_group,
            hsnCode: this.state.HSN_Code,
            id: this.props.itemMasterViewByIdList.data.data.id,
            isActive: this.state.item_status === "2" ? 0 : 1,
            itemDsc: this.state.item_Description,
            legacyItemCode: this.state.Legacy_Item_Code,
            mappDto: itemTypesData,
            max: this.state.Max_Value,
            maxOrderValue: this.state.Min_Order_Value,
            multiFact: 0,
            op2018: 0,
            saleable: "Y",
            srRate: this.state.Item_Rate,
            standBy: "Y",
            taxId: this.state.taxId,
            threshold: 0,
            uomCode: this.state.UOM_Code,
            weight: "",
            installationRate: this.state.installationRate,
            reorderQuantity: Number(this.state.reorder_qunatity),
            finishedGoodSaleable: "Y",
            tangible: this.state.tangible,
            minMax: this.state.formData,
          };
          this.props.createItemMaster(
            serverApi.UPDATE_ITEM_MASTER,
            data,
            this.props.history
          );
        }
      }
    }
    // }
  };
  // function for previous page
  onClickCancel = () => {
    this.props.history.push("/item_master");
  };
  // function for textfileds
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "item_Code") {
      this.setState({
        item_Code: event,
      });
    }
    if (key === "item_Description") {
      this.setState({
        item_Description: event,
      });
    }
    if (key === "HSN_Code") {
      this.setState({
        HSN_Code: event,
      });
    }
    if (key === "Item_Rate") {
      if (event.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
        this.setState({
          Item_Rate: event,
        });
      } else {
        swal("Please enter a positive number with up to 3 decimal places.");
        this.setState({
          Item_Rate: "",
        });
      }
    }
    if (key === "Legacy_Item_Code") {
      const legacycode = event.replace(/[^a-zA-Z0-9\s]+/g, "");
      this.setState({
        Legacy_Item_Code: legacycode,
      });
    }
    if (key === "Max_Value") {
      if (event.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
        const eventFloat = parseFloat(event);
        const minOrderValueFloat = parseFloat(this.state.Min_Order_Value);
        if (this.state.Min_Order_Value !== "") {
          if (eventFloat < minOrderValueFloat || event === "") {
            this.setState({
              Max_Value: "",
              Min_Order_Value: "",
            });
          } else {
            this.setState((prevState) => ({
              Max_Value: event,
              Min_Order_Value:
                eventFloat < minOrderValueFloat
                  ? ""
                  : prevState.Min_Order_Value,
            }));
          }
        } else {
          this.setState({
            Max_Value: event,
          });
        }
      } else {
        swal("Please enter a positive number with up to 3 decimal places.");
        this.setState({
          Max_Value: "",
        });
      }
    }

    if (key === "Min_Order_Value") {
      if (event.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
        const eventFloat = parseFloat(event);
        const maxOrderValueFloat = parseFloat(this.state.Max_Value);

        if (event !== "" && (!eventFloat || eventFloat > maxOrderValueFloat)) {
          swal("Minimum Stock Can't be Greater than Max Stock Limit");
          this.setState({
            Min_Order_Value: "",
          });
        } else {
          this.setState({
            Min_Order_Value: event,
          });
        }
      } else {
        swal("Please enter a positive number with up to 3 decimal places.");
        this.setState({
          Min_Order_Value: "",
        });
      }
    }
    if (key === "reorder_qunatity") {
      if (event.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
        this.setState({
          reorder_qunatity: event,
        });
      } else {
        swal("Please enter a positive number with up to 3 decimal places.");
        this.setState({
          reorder_qunatity: "",
        });
      }
    }
    if (key === "installationRate") {
      this.setState({
        installationRate: event,
      });
    }

    if (key === "tangibleRate") {
      this.setState({
        tangibleRate: event,
      });
    }
  };
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  // multi select dropdown
  getMopt = (selectedValue, name) => {
    if (name === "itemListData") {
      // let depts = [];
      // selectedValue.map((id) => {
      //   var item = {
      //     value: id.value,
      //     label: id.label,
      //     isActive: 1,
      //     autoId: id.autoId ? id.autoId : null,
      //   };
      //   depts.push(item);
      // });
      // this.setState({
      //   indentItemId: depts,
      //   item_types: depts,
      // });

      let newDepts = [];
      if (selectedValue) {
        selectedValue.map((arr) => {
          this.state.Indent_List_Master.map((p, i) => {
            if (arr.value == Number(p.value))
              newDepts.push({
                name: p.name,
                value: p.value,
                label: p.label,
                isActive: 1,
                autoId: p.autoId ? p.autoId : null,
              });
          });
        });
      }
      this.setState({
        indentItemId: newDepts,
        item_types: newDepts,
      });
    }
  };

  // textfield dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "item_group") {
      this.props.getItemByItemCode(
        serverApi.GET_ITEM_CODE_BY_ITEM_GROUP +
          "/" +
          selectedValue +
          "/" +
          localStorage.getItem("companyId")
      );
      this.setState({
        item_group: selectedValue,
      });
    }
    if (name === "direct_indirect") {
      this.setState({
        direct_indirect: selectedValue,
      });
    }

    if (name === "uom_code") {  
      this.setState({
        UOM_Code: selectedValue,
      });
    }
    if (name === "tax") {
      this.setState({
        tax: selectedValue,
        taxId: selectedValue,
      });
    }
    if (name === "status") {
      this.setState({
        item_status: selectedValue,
      });
    }
    if (name === "tangible") {
      this.setState({
        tangible: selectedValue,
      });
      if(selectedValue === "N") {
        this.setState({
          UOM_Code: "NOS",
        });
      }else {
        this.setState({
          UOM_Code: "",
        });
      }
    }
  };
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };
  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  handleFieldUpdate = (formData) => {
    this.setState({
      formData: formData,
    });
  };

  render() {
    return (
      <div className="itemMasterContainer">
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={Tangible_Data}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.tangible}
                        update={this.state.tangible ? 1 : 0}
                        isDisabled={
                          this.state.locationState.state !== undefined
                        }
                        required
                        name="tangible"
                        label="Tangible"
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.ItemGroupMasterList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.item_group}
                        update={this.props.location.state ? 1 : 0}
                        name="item_group"
                        label="Item Group"
                        required
                        isDisabled={
                          this.state.locationState.state !== undefined
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        type="text"
                        value={this.state.item_Code}
                        caps="OFF"
                        minValue="0"
                        onChange={this.onhandlechangeValue("item_Code")}
                        label="Item Code"
                        disabled={
                          this.state.locationState.state ||
                          this.state.item_group === ""
                        }
                        required
                        rightAlign="right"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        type="text"
                        value={this.state.item_Description}
                        caps="OFF"
                        minValue="0"
                        onChange={this.onhandlechangeValue("item_Description")}
                        label="Item Description"
                        required
                        disabled={
                          this.state.locationState.state !== undefined
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.uom_List}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.UOM_Code}
                        update={this.props.location.state ? 1 : 0}
                        name="uom_code"
                        label="UOM Code"
                        required
                        isDisabled={
                          (this.state.locationState.state &&
                          this.state.item_status === 2) ||
                          this.state.item_Description === ""}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        type="number"
                        value={this.state.HSN_Code}
                        caps="OFF"
                        minValue="0"
                        onChange={this.onhandlechangeValue("HSN_Code")}
                        label="HSN/SAC Code"
                        required={true}
                        disabled={
                          (this.state.locationState.state &&
                            this.state.item_status === 2) ||
                          (this.state.UOM_Code === "" &&
                            this.state.item_Description === "")
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        type="number"
                        value={this.state.Item_Rate}
                        caps="OFF"
                        minValue="0"
                        onChange={this.onhandlechangeValue("Item_Rate")}
                        label="Item Rate"
                        disabled={
                          (this.state.locationState.state &&
                            this.state.item_status === 2) ||
                          (this.state.HSN_Code === "" &&
                            this.state.UOM_Code === "")
                            ? true
                            : false
                        }
                        rightAlign="right"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        type="number"
                        value={this.state.Legacy_Item_Code}
                        caps="OFF"
                        minValue="0"
                        onChange={this.onhandlechangeValue("Legacy_Item_Code")}
                        label="Legacy Item Code"
                        disabled={
                          (this.state.locationState.state &&
                            this.state.item_status === 2) ||
                          (this.state.HSN_Code === "" &&
                            this.state.UOM_Code === "")
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.taxList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.tax}
                        name="tax"
                        label="Tax"
                        update={this.props.location.state ? 1 : 0}
                        required
                        isDisabled={
                          (this.state.locationState.state &&
                            this.state.item_status === 2) ||
                          this.state.HSN_Code === ""
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    {/* <AutoMultiselect
                      options={this.state.Indent_List_Master} // Options to display in the dropdown
                      showCheckbox={true}
                      selectedValues={this.state.indentItemId}
                      name={"itemListData"}
                      getOpt={this.getMopt}
                      label="Item Types"
                      mandatory="*"
                      disable={
                        (this.state.locationState.state &&
                          this.state.item_status === 2) ||
                        this.state.tax === ""
                          ? true
                          : false
                      }
                    /> */}
                    <div className="consoleTextFieldBlock">
                    <AutoMultiselect
                      options={this.state.Indent_List_Master} // Options to display in the dropdown
                      showCheckbox={true}
                      selectedValues={this.state.indentItemId} // Preselected value to persist in dropdown
                      name={"itemListData"}
                      getOpt={this.getMopt}
                      label="Item Types"
                      mandatory="*"
                      disable={
                        (this.state.locationState.state &&
                          this.state.item_status === 2) ||
                        this.state.tax === ""
                          ? true
                          : false
                      }
                    />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <label>
                        Installation Rate{" "}
                        <div className="mandatoryField">*</div>
                      </label>
                      <div className="taxPayableContent">
                        <RadioButton
                          value={this.state.installationRateYes}
                          labelText="Yes"
                          checkednew={this.state.installationRateYes}
                          onChange={this.onHandleChange}
                          disabled={
                            this.state.locationState.state &&
                            this.state.item_status === 2
                          }
                        />
                        <RadioButton
                          value={this.state.installationRateNo}
                          checkednew={this.state.installationRateNo}
                          onChange={this.onHandleChange}
                          labelText="No"
                          disabled={
                            this.state.locationState.state &&
                            this.state.item_status === 2
                          }
                        />
                      </div>
                    </div>
                  </Box>
                  {this.state.installationRateYes === true ? (
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          type="number"
                          value={this.state.installationRate}
                          caps="OFF"
                          minValue="0"
                          onChange={this.onhandlechangeValue(
                            "installationRate"
                          )}
                          label="Rate"
                          required
                          disabled={
                            this.state.locationState.state &&
                            this.state.item_status === 2
                          }
                        />
                      </div>
                    </Box>
                  ) : (
                    ""
                  )}
                  {this.state.locationState.state ? (
                    <Box gridColumn="span 3">
                      <DynamicSelect
                        update={this.state.item_status ? 1 : 0}
                        arrayOfData={Data}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.item_status}
                        name="status"
                        label="Status"
                      />
                    </Box>
                  ) : (
                    ""
                  )}

                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <>
                      <Box gridColumn="span 6">
                        <div className="consoleUpdateTextBlock">
                          <div>
                            <span className="taxLabel">
                              Created By : <b>{this.state.createdBy}</b>
                            </span>
                          </div>
                          <div>
                            <span className="taxLabel">
                              Created On :<b> {this.state.createdOn}</b>
                            </span>
                          </div>
                        </div>
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
          <StockForm
            isEdit={this.state.locationState.state}
            minMax={this?.state?.minMax}
            branchList={this?.state?.branchList}
            onFieldUpdate={this.handleFieldUpdate}
            tax={this.state.tax}
            tangible={this.state.tangible}
            status={this.state.item_status}
          />
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.state.locationState.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}

          {this.state.confirm && (
            <CommonPopup>
              <div className="delAddItem">
                <div className="mandatoryFieldText">
                  Please select all the mandatory fields
                </div>
                <div className="delAddItemBtns">
                  <input
                    type="button"
                    onClick={this.onclickPopUP.bind(this)}
                    value="Ok"
                    className="delYesBtn"
                  />
                </div>
              </div>
            </CommonPopup>
          )}
        </div>
      </div>
    );
  }
}

const Data = [
  {
    value: "1",
    label: "Active",
    name: "Active",
  },
  {
    value: "2",
    label: "Inactive",
    name: "Inactive",
  },
];

const Tangible_Data = [
  {
    value: "Y",
    label: "Goods",
    name: "Goods",
  },
  {
    value: "N",
    label: "Services",
    name: "Services",
  },
];

const mapStatetoProps = (state) => {
  const { itemGroupMasterList } = state.itemGroupMasterList;
  const { taxList } = state.taxList;
  const { uomList, allItemGroupMaster_List, branchList } =
    state.DropDownListReducer;
  const {
    Indent_List_Master,
    itemByItemCodeList,
    itemMasterUomList,
    itemMasterViewByIdList,
  } = state.ItemListReducer;

  return {
    itemGroupMasterList,
    taxList,
    uomList,

    allItemGroupMaster_List,
    Indent_List_Master,
    itemByItemCodeList,
    itemMasterUomList,
    itemMasterViewByIdList,
    branchList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    createItemMaster,
    updateItemMaster,
    getItemGroupMasterList,
    getTax_List,
    getItemCodeByItemGroup,
    getIndentListMaster,
    allItemGroupMasterList,
    getItemByItemCode,
    getUomInItemMaster,
    getItemMasterViewById,
    getBranchList,
  })(CreateItemMaster)
);
