import { JUTE_PO_LIST, JUTE_PO_LIST_SUCCESSFULL, JUTE_PO_LINE_ITEMS, JUTE_PO_CREATE, JUTE_PO_VIEW_BY_ID, JUTE_PO_VIEW_BY_ID_SUCCESSFULL, JUTE_PO_HEADER, CLEAR_JUTE_PO_HEADER, CLEAR_JUTE_PO_LINE_ITEMS, GET_JUTE_QUANTITY_BY_JUTE_TYPE, GET_JUTE_QUANTITY_BY_JUTE_TYPE_SUCCESSFULL, JUTE_PO_CREATE_SUCCESSFULL, JUTE_PO_UPDATE, JUTE_PO_UPDATE_SUCCESSFULL, GET_INDENT_DATA, GET_INDENT_DATA_SUCCESSFULL, JUTE_INDENT_VIEW_BY_ID_FOR_PO, GET_LATEST_MARKET_RATE, GET_LATEST_MARKET_RATE_SUCCESSFULL, JUTEPO_UPLOAD_FILES, JUTEPO_UPLOAD_FILES_SUCCESSFULL, GET_SUPPORT_FILES, GET_SUPPORT_FILES_SUCCESSFULL, JUTE_PURCHASE_DELETE_FILE, JUTE_PURCHASE_DELETE_FILE_SUCCESSFULL } from "./actionTypes";

export const JutePurchaseList = (url, data, history) => {
  return {
    type: JUTE_PO_LIST,
    payload: { url, data, history },
  };
};
export const JutePOListSuccessfull = (data) => {
  return {
    type: JUTE_PO_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const jutePOCreate = (url, data, history) => { 
  return {
      type: JUTE_PO_CREATE,
      payload: {url, data, history}
  }
}
export const jutePOCreateSuccessfull = (data) => {
  return {
    type: JUTE_PO_CREATE_SUCCESSFULL,
    payload: data,
  };
};
export const jutePOupdate = (url, data, history) => { 
  return {
      type: JUTE_PO_UPDATE,
      payload: {url, data, history}
  }
}
export const jutePOupdateSuccessfull = (data) => {
  return {
    type: JUTE_PO_UPDATE_SUCCESSFULL,
    payload: data,
  };
};
export const jutePOViewById = (url,data,history) =>{
  return {
      type:JUTE_PO_VIEW_BY_ID,
      payload:{url,data,history}
  }
}

export const jutePOViewByIdSuccessfull = (data)=>{
  return {
      type:JUTE_PO_VIEW_BY_ID_SUCCESSFULL,
      payload:data
  }
}
export const juteIndentViewByIdForPO = (url,history) =>{
  return {
      type:JUTE_INDENT_VIEW_BY_ID_FOR_PO,
      payload:{url,history}
  }
}

export const juteIndentViewByIdForPOSuccessfull = (data)=>{
  return {
      type:JUTE_INDENT_VIEW_BY_ID_FOR_PO,
      payload:data
  }
}
export const getJutePOHeader = (data) => {
  return {
    type: JUTE_PO_HEADER,
    payload: data,
  };
};
export const ClearJutePOHeader = (data) => {
  return {
    type:CLEAR_JUTE_PO_HEADER,
    payload: data,
  };
};

export const GetPoLineItems = (data) => {
  return {
    type: JUTE_PO_LINE_ITEMS,
    payload:data
  }
}

export const ClearPoLineItems = (data) => {
  return {
    type:CLEAR_JUTE_PO_LINE_ITEMS,
    payload:data
  }
}

export const getJuteQuantityByJuteType= (url,rowId) => {
  return {
    type: GET_JUTE_QUANTITY_BY_JUTE_TYPE,
    payload: { url,rowId },
  };
};
export const getJuteQuantityByJuteTypeSuccessfull = (data) => {
  return {
    type: GET_JUTE_QUANTITY_BY_JUTE_TYPE_SUCCESSFULL,
    payload: data,
  };
};

export const GetIndentData = (url, history) => { 
  return {
      type: GET_INDENT_DATA,
      payload: {url,history}
  }
}
export const GetIndentDataSuccessfull = (data) => {
  return {
    type: GET_INDENT_DATA_SUCCESSFULL,
    payload: data,
  };
};

export const getLatestMarketRate= (url,rowId) => {
  return {
    type: GET_LATEST_MARKET_RATE,
    payload: { url,rowId },
  };
};
export const getLatestMarketRateSuccessfull = (data) => {
  return {
    type: GET_LATEST_MARKET_RATE_SUCCESSFULL,
    payload: data,
  };
};

export const getSupportFiles = (url, history, sourceMenu) => {
  return {
    type: GET_SUPPORT_FILES,
    payload: { url, history, sourceMenu },
  };
};
export const getSupportFilesSuccessfull = (data) => {
  return {
    type: GET_SUPPORT_FILES_SUCCESSFULL,
    payload: data,
  };
};

export const UploadJutePOFiles = (url,data,id) =>{
  return {
      type:JUTEPO_UPLOAD_FILES,
      payload:{url,data,id}
  }
}

export const UploadJutePOFilesSuccessfull = (data)=>{
  return {
      type:JUTEPO_UPLOAD_FILES_SUCCESSFULL,
      payload:data
  }
}

export const JutePODeleteFiles = (url,id) =>{
  return {
      type:JUTE_PURCHASE_DELETE_FILE,
      payload:{url,id}
  }
}
 
export const JutePODeleteFilesSuccessfull = (data)=>{
  return {
      type:JUTE_PURCHASE_DELETE_FILE_SUCCESSFULL,
      payload:data
  }
}
