import React from "react";
import { serverApi } from "../../../helpers/Consts";
import {
  holidayDataAdd,
  holidayDataUpdate,
  UpdateholidayListForEmployees,
} from "../../../store/Master/HolidayCalendar/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import { getSpellMasterList } from "../../../store/Master/SpellMaster/actions";
import moment from "moment";
import CustomButton from "../../../components/Buttons/Buttons";
import { Link } from "react-router-dom";
import { getBranchList } from "../../../store/Global/DropDownApis/actions";
import swal from "sweetalert";
import { validate } from "../../../Utilities/Validations";

class CreateHoliday extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      holiday: "",
      description: "",
      spell: "",
      spellMasterListData: "",
      Branch_List: [],
      branchId: null,
      holidayType: null,
      locationState:"",
    };
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    this.setState({
      locationState: this.props.location,
    });
    window.addEventListener("scroll", this.handleScroll);
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
    };
    this.props.getSpellMasterList(
      serverApi.SPELL_MASTER_LIST,
      data,
      this.props.history
    );
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData !== undefined) {
        this.setState({
          holiday: this.props.location.state.rowData.holiday,
          description: this.props.location.state.rowData.description,
          spell: this.props.location.state.rowData.spell,
          branchId: this.props.location.state.rowData.branchId,
          holidayType: this.props.location.state.rowData.holidayType,
        });
      }
    }
    this.props.getBranchList(
      serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
      this.props.history
    );
  }
  componentWillReceiveProps(props) {
    if (props.SpellMaster_List) {
      this.setState({
        spellMasterListData: props.SpellMaster_List.data,
      });
    }

    ///branch list

    if (props.branchList) {
      if (props.branchList.data) {
        if (props.branchList.data.length !== 0) {
          var list = [];
          list = props.branchList.data.filter((item) => {
            if (item.value !== 0) {
              return item;
            }
          });

          this.setState({
            Branch_List: list,
          });
        }
      }
    }
  }
  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
  };
  handleChange = (selectedValue, selectedName, name) => {
    if (name === "Shift") {
      this.setState({
        spell: selectedName,
      });
    }
    if (name === "branchId") {
      this.setState({
        branchId: selectedValue,
      });
    }
    if (name === "holidayType") {
      this.setState({
        holidayType: selectedValue,
      });
    }
  };
  handleSelectDate = (e, name) => {
    if (name === "holiday") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();

      this.setState({
        holiday: Date,
      });
    }
  };
  onClickSave = () => {
    var Date = moment(this.state.holiday, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    const data = {
      holiday: Date,
      description: this.state.description,
      createdby: this.state.userDit.userName,
      companyId: localStorage.getItem("companyId"),
      spell: this.state.spell,
      branchId: this.state.branchId,
      holidayType: this.state.holidayType,
    };

    if (
      this.state.holiday === "" ||
      this.state.holiday === undefined ||
      this.state.holiday === null
    ) {
      swal("Please Enter The Holiday Date");
      return false;
    }
    if (
      this.state.description === "" ||
      this.state.description === undefined ||
      this.state.description === null
    ) {
      swal("Please Enter The Holiday Description");
      return false;
    }
    if (
      this.state.spell === "" ||
      this.state.spell === undefined ||
      this.state.spell === null
    ) {
      swal("Please Enter The Shift");
      return false;
    }
    if (
      this.state.branchId === "" ||
      this.state.branchId === undefined ||
      this.state.branchId === null
    ) {
      swal("Please Enter The Branch");
      return false;
    } else {
      this.props.holidayDataAdd(
        serverApi.ADD_HOLIDAY_MASTER,
        data,
        this.props.history
      );
    }
  };
  onClickUpdate = () => {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData !== undefined) {
        var Date = moment(this.state.holiday, "YYYY-MM-DD", true).format(
          "DD-MM-YYYY"
        );
        const data = {
          holiday: Date,
          description: this.state.description,
          createdby: this.props.location.state.rowData.createdby,
          companyId: this.props.location.state.rowData.companyId,
          spell: this.state.spell,
          id: this.props.location.state.rowData.id,
          branchId: this.state.branchId,
          holidayType: this.state.holidayType,
        };

        if (
          this.state.holiday === "" ||
          this.state.holiday === undefined ||
          this.state.holiday === null
        ) {
          swal("Please Enter The Holiday Date");
          return false;
        }
        if (
          this.state.description === "" ||
          this.state.description === undefined ||
          this.state.description === null
        ) {
          swal("Please Enter The Holiday Description");
          return false;
        }
        if (
          this.state.spell === "" ||
          this.state.spell === undefined ||
          this.state.spell === null
        ) {
          swal("Please Enter The Shift");
          return false;
        }
        if (this.state.branchId == "") {
          swal("Please Enter The Branch");
          return false;
        } else {
          this.props.holidayDataUpdate(
            serverApi.UPDATE_HOLIDAY_ITEM,
            data,
            this.props.history
          );
        }
      }
    }
  };
  onClickProcess = () => {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData !== undefined) {
        if (
          this.state.holiday === "" ||
          this.state.holiday === undefined ||
          this.state.holiday === null
        ) {
          swal("Please Enter The Holiday Date");
          return false;
        }
        if (
          this.state.description === "" ||
          this.state.description === undefined ||
          this.state.description === null
        ) {
          swal("Please Enter The Holiday Description");
          return false;
        }
        if (
          this.state.spell === "" ||
          this.state.spell === undefined ||
          this.state.spell === null
        ) {
          swal("Please Enter The Shift");
          return false;
        }
        if (
          this.state.branchId === "" ||
          this.state.branchId === undefined ||
          this.state.branchId === null
        ) {
          swal("Please Enter The Branch");
          return false;
        } else {
          this.props.UpdateholidayListForEmployees(
            serverApi.UPDATE_HOLIDAY_LIST_EMPLOYEE +
              this.props.location.state.rowData.companyId +
              "/" +
              this.props.location.state.rowData.holiday +
              "/" +
              JSON.parse(localStorage.getItem("authUser")).userId,
            this.props.history
          );
        }
      }
    }
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <>
        <div className="workDetailsBlock">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                  className="workDetails"
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>
                          Holiday Date <span className="mandatoryField">*</span>
                        </label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="holiday"
                          value={this.state.holiday}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "holiday")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        type="text"
                        label="Holiday Description"
                        value={this.state.description}
                        onChange={this.onhandlechange("description")}
                        disabled={this.state.holiday === "" ? true : false}
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectNew
                        update={this.state.spell ? 1 : 0}
                        arrayOfData={
                          this.state.spellMasterListData &&
                          this.state.spellMasterListData.map((item) => ({
                            label: item.spellName,
                            name: item.spellName,
                            value: item.workingHours,
                          }))
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.spell}
                        name="Shift"
                        label="Shift"
                        required
                        isDisabled={
                          this.state.description === "" ? true : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={this.state.branchId ? 1 : 0}
                        arrayOfData={
                          this.state.Branch_List
                            ? this.state.Branch_List.map((item) => ({
                                label: item.label,
                                value: item.value,
                                name: item.label,
                              }))
                            : ""
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.branchId}
                        name="branchId"
                        label="Branch"
                        required
                        isDisabled={this.state.spell === "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={this.state.holidayType ? 1 : 0}
                        arrayOfData={HolidayTypeList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.holidayType}
                        name="holidayType"
                        label="Holiday Type"
                        isDisabled={this.state.spell === "" ? true : false}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div className="consoleFormButtonBlock">
            <Link to="/Holidays_List">
              <CustomButton label={"Cancel"} className="greenBorderButton" />
            </Link>
            {this.state.locationState.state ? (
              <CustomButton
                label={"Update"}
                className="greenButton"
                handleClick={this.onClickUpdate}
                type="sumbit"
              />
            ) : (
              <CustomButton
                label={"Add"}
                className="greenButton"
                handleClick={this.onClickSave}
                type="sumbit"
              />
            )}
            {this.state.locationState.state !== undefined && (
              <CustomButton
                label={"Process"}
                className="lightBlueButton"
                handleClick={this.onClickProcess}
                type="sumbit"
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
const HolidayTypeList = [
  {
    value: "1",
    label: "Fixed Holiday",
    name: "Fixed Holiday",
  },
  {
    value: "2",
    label: "Restricted Holiday",
    name: "Restricted Holiday",
  },
];
const mapStatetoProps = (state) => {
  const { SpellMaster_List } = state.SpellMasterListReducer;
  const { holiday_add_data } = state.HolidayListReducer;
  const { branchList } = state.DropDownListReducer;
  return { SpellMaster_List, holiday_add_data, branchList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSpellMasterList,
    holidayDataAdd,
    holidayDataUpdate,
    UpdateholidayListForEmployees,
    getBranchList,
  })(CreateHoliday)
);
