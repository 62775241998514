import { Component } from "react";
import { connect } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import { withRouter } from "react-router-dom";
import { spinningDoffList } from "../../../store/JuteProduction/SpinningDoffEntry/actions";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridDeleteIcon from "../../../assets/images/deleteIconButton.png";

class SpinningDoffEntryIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      startIndex: "",
      lastIndex: "",
      SpinningDoffContent: [],
      fromDate: "",
      toDate: "",
    };
  }
  componentDidMount() {
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: this.state.cipher,
      fromDate: "",
      toDate: "",
    };
    this.props.spinningDoffList(
      serverApi.GET_SPINNING_DOFF_ENTRY_LIST,
      data,
      this.props.history
    );
  }

  handleSearch = (searchData) => {
    this.setState({
      fromDate: searchData.fromDate,
      toDate: searchData.toDate,
    });
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: this.state.cipher,
      fromDate: searchData.fromDate,
      toDate: searchData.toDate,
    };
    this.props.spinningDoffList(
      serverApi.GET_SPINNING_DOFF_ENTRY_LIST,
      data,
      this.props.history
    );
  };
  componentWillReceiveProps(props) {
    if (props.SpinningDoffData) {
      this.setState({
        SpinningDoffContent: props.SpinningDoffData.data,
      });
    }
  }
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.SpinningDoffContent}
          mcolumns={Columns}
          status={true}
          micon={GridDeleteIcon}
          actions={false}
          deleteIcon={true}
          filter_form={"SpinningDoffFilter"}
          handleSearch={this.handleSearch}
          handledeleteRecord={this.handledeleteRecord}
        />
      </>
    );
  }
}
const Columns = [
  {
    Header: "DoffEntry Id",
    accessor: "doffId",
    hidden: false,
  },
  {
    Header: "Doff Entry Date",
    accessor: "doffEntryDate",
    hidden: false,
  },
  {
    Header: "Spell",
    accessor: "spell",
    hidden: false,
  },
  {
    Header: "Trolly No",
    accessor: "trollyNo",
    hidden: false,
  },
  {
    Header: "Machine Name",
    accessor: "machine",
    hidden: false,
  },
  {
    Header: "Net Weight",
    accessor: "netWT",
    hidden: false,
  },
];
const mapStatetoProps = (state) => {
  const { SpinningDoffData } = state.SpinningDoffEntryReducer;
  return { SpinningDoffData };
};

export default withRouter(
  connect(mapStatetoProps, {
    spinningDoffList,
  })(SpinningDoffEntryIndex)
);
