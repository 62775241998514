import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
// import DynamicSelect from "../Dropdown/DynamicSelect";
import DynamicSelectMasters from "../Dropdown/Master/DynamicSelectMasters";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { serverApi } from "../../helpers/Consts";
import { getCategoryList } from "../../store/HRMS/EmployeeDataBase/actions";
import TextFieldNormal from "../../components/TextField/TextFieldNormal";
import TextFieldMobile from "../../components/TextField/TextFieldMobile";
import {
  getDepartmentListData,
  getDesignation,
  getReportingManagerList,
  getContractor,
} from "../../store/HRMS/EmployeeDataBase/actions";
import {
  getStatusList,
  getBranchList,
} from "../../store/Global/DropDownApis/actions";

class EmployeeData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      Designation: [],
      Department_List: [],
      CategoryList: [],
      ReportingManager: [],
      Status: [],
      Branch_List: [],
      Contractor: [],
      department_id: "",
      reporting_manager_id: "",
      esi_no: "",
      pf_no: "",
      emp_code: "",
      Category_Id: "",
      designation_id: "",
      status_id: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      mobile: "",
      branch_id: "",
      contractor_id: "",
    };
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "Department") {
      this.setState({
        department_id: selectedValue,
      });
    }
    if (name === "Designation") {
      this.setState({
        designation_id: selectedValue,
      });
    }
    if (name === "Category") {
      this.setState({
        Category_Id: selectedValue,
      });
    }
    if (name === "ReportingManager") {
      this.setState({
        reporting_manager_id: selectedValue,
      });
    }
    if (name === "Status") {
      this.setState({
        status_id: selectedValue,
      });
    }
    if (name === "gender") {
      this.setState({
        gender: selectedName,
      });
    }

    if (name === "branch_id") {
      this.setState({
        branch_id: selectedValue,
      });
    }
    if (name === "contractor_id") {
      this.setState({
        contractor_id: selectedValue,
      });
    }
  };
  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "EmpCode") {
      this.setState({
        emp_code: event,
      });
    }
    if (key === "first_name") {
      this.setState({
        first_name: event,
      });
    }
    if (key === "middle_name") {
      this.setState({
        middle_name: event,
      });
    }
    if (key === "last_name") {
      this.setState({
        last_name: event,
      });
    }
    if (key === "mobile") {
      this.setState({
        mobile: event,
      });
    }
    if (key === "PF") {
      this.setState({
        pf_no: event,
      });
    }
    if (key === "ESI") {
      this.setState({
        esi_no: event,
      });
    }
  };
  handleSubmit = () => {
    const data = {
      companyId: localStorage.getItem("companyId"),
      departmentId: this.state.department_id,
      mobileNo: this.state.mobile,
      firstName: this.state.first_name,
      middleName: this.state.middle_name,
      lastName: this.state.last_name,
      catagoryId: this.state.Category_Id,
      gender: this.state.gender,
      status: this.state.status_id,
      branchId: this.state.branch_id,
      contractorId: this.state.contractor_id,
      reportingEbId: this.state.reporting_manager_id,
      esiNo: this.state.esi_no,
      pfNo: this.state.pf_no,
      empCode: this.state.emp_code,
      designationId: this.state.designation_id,
      startIndex: 0,
      lastIndex: 0,
      isActive: 1,
      userId:JSON.parse(localStorage.getItem("authUser")).userId
    };
    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };
  componentWillReceiveProps(props) {
    // if (props.employeeList) {
    //     this.setState({
    //         employeeListData: props.employeeList.data,
    //     });
    // }
    if (props.designation) {
      this.setState({
        Designation: props.designation.data,
      });
    }
    if (props.departmentList) {
      this.setState({
        Department_List: props.departmentList.data,
      });
    }
    if (props.categoryList) {
      this.setState({
        CategoryList: props.categoryList.data,
      });
    }
    if (props.statusList) {
      this.setState({
        Status: props.statusList.data,
      });
    }
    if (props.reporting_Manager_List) {
   
      this.setState({
        ReportingManager: props.reporting_Manager_List.data,
      });
    }
    if (props.branchList) {
      this.setState({
        Branch_List: props.branchList.data,
      });
    }
    if (props.contractor) {
      this.setState({
        Contractor: props.contractor.data,
      });
    }
  }
  componentDidMount() {
    this.props.getDepartmentListData(
      serverApi.DEPARTMENT_LIST + localStorage.getItem("companyId"),
      this.props.history
    );
    this.props.getDesignation(
      serverApi.DESIGNATION + localStorage.getItem("companyId") + "/0",
      this.props.history
    );
    this.props.getCategoryList(
      serverApi.CATEGORY_LIST + localStorage.getItem("companyId"),
      this.props.history
    );
    this.props.getStatusList(serverApi.GET_STATUS_LIST, this.props.history);
    this.props.getReportingManagerList(
      serverApi.REPORTING_MANAGER_LIST+
      localStorage.getItem("companyId") +
      "/" +
      JSON.parse(localStorage.getItem("authUser")).cipher,
      this.state.history
    );
    this.props.getContractor(
      serverApi.CONTRACTOR + localStorage.getItem("companyId"),
      this.props.history
    );
    this.props.getBranchList(
      serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
      this.props.history
    );
  }
  render() {
    return (
      <>
        <Box>
          <Button onClick={this.handleClickOpen}>
            <Tooltip title="Filter">
              <FilterListOutlinedIcon />
            </Tooltip>
          </Button>
          <Dialog
            open={this.state.open}
            PaperProps={{
              sx: {
                position: " fixed",
                top: 0,
                right: 0,
                margin: 0,
                bottom: 0,
                maxHeight: "100vh",
              },
            }}
            keepMounted
            onClose={this.handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <Grid spacing={0}>
              <div className="consoleTextFieldBlock">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div className="filterBlockHeader">
                    <FilterListOutlinedIcon />
                    <span>Filter</span>
                  </div>
                </div>
              </div>
              <div className="hrmsFilter">
                <Grid container spacing={1} className="filterBlockContainer">
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectMasters
                        arrayOfData={
                          this.state.Department_List
                            ? this.state.Department_List.map((item) => ({
                                label: item.label,
                                value: item.value,
                                name: item.name,
                              }))
                            : ""
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectChange}
                        selected={this.state.department_id}
                        name="Department"
                        label="Department"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectMasters
                        arrayOfData={
                          this.state.ReportingManager
                            ? this.state.ReportingManager.map((item) => ({
                                label: item.label,
                                value: item.value,
                                name: item.name,
                              }))
                            : ""
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectChange}
                        selected={this.state.reporting_manager_id}
                        name="ReportingManager"
                        label="Reporting Manager"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="ESI Number"
                        value={this.state.esi_no}
                        onChange={this.onhandlechange("ESI")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="PF Number"
                        value={this.state.pf_no}
                        onChange={this.onhandlechange("PF")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="EMP Code"
                        value={this.state.emp_code}
                        onChange={this.onhandlechange("EmpCode")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="First Name"
                        value={this.state.first_name}
                        onChange={this.onhandlechange("first_name")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Middle Name"
                        value={this.state.middle_name}
                        onChange={this.onhandlechange("middle_name")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Last Name"
                        value={this.state.last_name}
                        onChange={this.onhandlechange("last_name")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <TextFieldMobile
                        label="Mobile Number"
                        value={this.state.mobile}
                        onChange={this.onhandlechange("mobile")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectMasters
                        label="Gender"
                        // required
                        arrayOfData={gender}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectChange}
                        selected={this.state.gender}
                        name="gender"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectMasters
                        arrayOfData={this.state.Branch_List}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectChange}
                        selected={this.state.branch_id}
                        name="branch_id"
                        label="Branch"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectMasters
                        arrayOfData={this.state.Contractor}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectChange}
                        selected={this.state.contractor_id}
                        name="contractor_id"
                        label="Contractor"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectMasters
                        arrayOfData={
                          this.state.CategoryList
                            ? this.state.CategoryList.map((item) => ({
                                label: item.label,
                                value: item.value,
                                name: item.name,
                              }))
                            : ""
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectChange}
                        selected={this.state.Category_Id}
                        name="Category"
                        label="Category"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectMasters
                        arrayOfData={
                          this.state.Designation
                            ? this.state.Designation.map((item) => ({
                                label: item.label,
                                value: item.value,
                                name: item.name,
                              }))
                            : ""
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectChange}
                        selected={this.state.designation_id}
                        name="Designation"
                        label="Designation"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectMasters
                        arrayOfData={
                          this.state.Status
                            ? this.state.Status.map((item) => ({
                                label: item.label,
                                value: item.value,
                                name: item.name,
                              }))
                            : ""
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectChange}
                        selected={this.state.status_id}
                        name="Status"
                        label="Status"
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Search"}
                  className="greenButton"
                  handleClick={this.handleSubmit}
                  type="sumbit"
                />
              </div>
              {/* </Grid> */}
            </Grid>
          </Dialog>
        </Box>
      </>
    );
  }
}
const gender = [
  {
    value: "1",
    label: "Male",
    name: "Male",
  },
  {
    value: "2",
    label: "Female",
    name: "Female",
  },
  {
    value: "3",
    label: "Trans Gender",
    name: "Trans Gender",
  },
];
const mapStatetoProps = (state) => {
  const { spell } = state.AttendanceReducer;
  const {
    departmentList,
    designation,
    categoryList,
    reporting_Manager_List,
    contractor,
  } = state.EmployeeReducer;
  const { statusList, branchList } = state.DropDownListReducer;
  return {
    departmentList,
    spell,
    designation,
    categoryList,
    statusList,
    reporting_Manager_List,
    branchList,
    contractor,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getDepartmentListData,
    getDesignation,
    getReportingManagerList,
    getCategoryList,
    getStatusList,
    getBranchList,
    getContractor,
  })(EmployeeData)
);
