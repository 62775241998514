import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import DesktopDatePicker from "@mui/lab/DatePicker";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';   
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';   
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'; 
import { Grid, Box } from "@mui/material";
import moment from 'moment';
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import TextFieldReq from "../../../components/TextField/TextFieldReq";

class CreatePrResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      SupplierResDate: null,
      itemGroup: '',
      item: '',
      supplierCode: '',
      Supplier: '',
      SupplierRate: '',
      SupplierCmts: '',
      Status: '',
      delivery_timeLine: '',
    };
  }


  componentDidMount() {
    if (this.props.location) {
      let date = this.props.location.state.rowData.suppResponseDateTimeDesc
      this.setState({
        SupplierResDate:
          moment(date,"DD-MM-YYYY",true).format('YYYY-MM-DD'),
        itemGroup: this.props.location.state.rowData.itemGrpDesc,
        item: this.props.location.state.rowData.itemDesc,
        supplierCode: this.props.location.state.rowData.suppCode,
        Supplier: this.props.location.state.rowData.suppName,
        SupplierRate: this.props.location.state.rowData.itemRate,
        SupplierCmts: this.props.location.state.rowData.suppComments,
        Status: this.props.location.state.rowData.statusDesc,
        delivery_timeLine: this.props.location.state.rowData.deliveryTimeLine,
      });
    }
  }

  render() {
    return (
      <div className="consoleFormContainer">
        <h5>Fill Details</h5>
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>Supplier Response Date</label>
                      </div>
                      {/* <ConsoleDatePicker /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          // name="financial_year"
                          inputFormat="dd-MM-yyyy"
                          // id="financial_year"
                          value={this.state.SupplierResDate}
                          fullWidth
                          onChange={(e) =>{}}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} readOnly={true}/>
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    {/* {this.state.itemGroup} */}
                    <TextFieldNormal
                      label="Item Group"
                      type={"text"}
                      value={this.state.itemGroup}
                      readOnly={true}
                      onChange={()=>{}}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Item"
                      type={"text"}
                      value={this.state.item}
                      readOnly={true}
                      onChange={()=>{}}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Supplier Code"
                      type={"text"}
                      value={this.state.supplierCode}
                      readOnly={true}
                      onChange={()=>{}}
                    />
                  </div>
                </Box>

                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Supplier"
                      type={"text"}
                      value={this.state.Supplier}
                      readOnly={true}
                      onChange={()=>{}}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldReq
                      label="Supplier Rate"
                      type={"text"}
                      value={this.state.SupplierRate}
                      readOnly={true}
                      onChange={()=>{}}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Supplier Comments"
                      type={"textarea"}
                      value={this.state.SupplierCmts}
                      readOnly={true}
                      onChange={()=>{}}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Status"
                      type={"text"}
                      value={this.state.Status}
                      readOnly={true}
                      onChange={()=>{}}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Delivery_TimeLine"
                      type={"text"}
                      value={this.state.delivery_timeLine}
                      readOnly={true}
                      onChange={()=>{}}
                    />
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
const mapStatetoProps = (state) => {};

export default withRouter(connect(mapStatetoProps, {})(CreatePrResponse));
