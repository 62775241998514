import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { ADD_PRICE_ENQUIRY, GET_PRICE_ENQUIRY_BYID, PRICE_ENQUIRY_LIST } from "./actionTypes";
import {
  addPriceEnquirySuccessfull,
  PriceEnquiryListSuccessfull,
  getPriceEnquiryByIdSuccessfull
} from "./actions";
// import {getCreateIndentAddList} from '../../../helpers/fackBackend_Helper';
import { getList, getListurl } from "../../../helpers/Server_Helper";
import swal from "sweetalert";


function* getPriceEnquiryList({ payload: { url, data, history } }) {
  
  try {
    const response = yield call(getList, url, data);
    if(response){
      //  if(response.data){
      //   yield put(getSupplierList(
      //     serverApi.GET_ALL_SUPPLIERS_LIST + localStorage.getItem("companyId"),
      //     this.state.history
      //   ))
      //  }
       yield put(PriceEnquiryListSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* addPriceEnquiry({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(addPriceEnquirySuccessfull({ response }));
    if(response){
      swal(response.message, { icon: "success" })
    }
    history.push('/purchase_price_request')
  } catch (error) {
    console.log(error);
  }
}

function* priceEnquiryById({ payload: { url,history } }) {
  try {
    const response = yield call(getListurl, url,);
    yield put(getPriceEnquiryByIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}


export function* watchPriceEnquiryList() {
  yield takeEvery(PRICE_ENQUIRY_LIST, getPriceEnquiryList);
}
export function* watchAddPriceEnquiry() {
  yield takeEvery(ADD_PRICE_ENQUIRY, addPriceEnquiry);
  yield takeEvery(GET_PRICE_ENQUIRY_BYID,priceEnquiryById)
}

function* PriceEnquirySaga() {
  yield all([fork(watchPriceEnquiryList), fork(watchAddPriceEnquiry)]);
}

export default PriceEnquirySaga;
