import DynamicSelect from "../../../components/Dropdown/Jute/DynamicSelect";
import { Grid, Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import {
  getBranchList,
} from "../../../store/Global/DropDownApis/actions";
import moment from "moment";
import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import { useState } from "react";
import {
  getVehicleTypeList,
  getSupplierByMukamIdList,
  getBrokerBySupplierIdList,
  getPoDropdownList,
  getPoDetailsList,
  getAgentDropdownList,
} from "../../../store/MasterDropdownApis/actions";
import {
  getSupplierListMultiselect,
  getSupplierListJ,
  getSupplierList,
} from "../../../store/Global/DropDownApis/actions";
import { getJuteBillPassHeader } from "../../../store/Jute/JuteBillPass/actions";
import { headerData } from "../../../store/actions";
// import state from "sweetalert/typings/modules/state";
const JuteBillPassFillDetails = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState();
  const [branchListData, setBranchListData] = useState();
  const [agentsList, setAgentsList] = useState();
  const [supplierListData, setSupplierListData] = useState();
  const dispatch = useDispatch();
  const [HeaderData, setHeaderData] = useState({});
  const [LineItems, setLineItems] = useState([]);


  const { branchList, supplierList } = useSelector(
    (state) => state.DropDownListReducer
  )
  const { agentDropdownList } = useSelector(
    (state) => state.MasterDropDownListReducer
  )
  const { JuteBillPassHeader, billpasslineItems } = useSelector(
    (state) => state.JuteBillPassReducer
  );


  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    dispatch(getBranchList(serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
      props.history
    ));

    dispatch(getSupplierList(
      serverApi.GET_SUPPLIER_FOR_JUTE + localStorage.getItem("companyId"),
      props.history
    ));

    dispatch(getAgentDropdownList(//GET_SUPPLIER_LIST_MULTISELECT
      serverApi.AGENTS_LIST + localStorage.getItem("companyId"),
      props.history
    ));

    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  useEffect(() => {
    if (branchList) {
      if (branchList.data) {
        let branchListData = branchList.data.filter((data) => data.value !== 0);
        setBranchListData(branchListData);
      }
    }

    if (agentDropdownList) {
      if (agentDropdownList.data) {
        if (agentDropdownList.data.length !== 0) {
          let list = agentDropdownList.data.filter((data) => data.value !== "0");
          setAgentsList(list);
        }
      }
    }

    if (supplierList) {
      if (supplierList.data) {
        if (supplierList.data.length !== 0) {
          let list = supplierList.data.filter((data) => data.value !== 0);
          setSupplierListData(list);
        }
      }
    }

  }, [branchList, agentDropdownList, supplierList]);

  useEffect(() => {
    setLineItems(billpasslineItems);
    setHeaderData(JuteBillPassHeader);
  }, [JuteBillPassHeader, billpasslineItems]);


  const handleMenuOpen = () => {
    setIsDropdownOpen(false);
  };
  const handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    setIsDropdownOpen(true);
  };

  const handlePropSelectChange = (
    selectedValue,
    selectedName,
    name,
    row,
    selectedlable
  ) => {
    if (name === "branchId") {
      setHeaderData((prev) => ({
        ...prev,
        branchId: selectedValue,
      }));
      const UpdatedRec = {
        ...HeaderData,
        branchId: selectedValue,
      };
      dispatch(getJuteBillPassHeader(UpdatedRec));
    }

 
    if (name === "agentId") {
      setHeaderData((prev) =>({
        ...prev,
        agentName: selectedName,
        agentId: selectedValue,
      }));
      const UpdatedRec = {
        ...HeaderData,
        agentName: selectedName,
        agentId: selectedValue,
      };
      dispatch(getJuteBillPassHeader(UpdatedRec))
    }
  }

  const onhandlechange=(key)=>(event)=>{
    if(key ==="billPassNo"){
      setHeaderData((prev)=>({
        ...prev,
        billPassNo:event,
      }));
      const UpdatedRec={
        ...HeaderData,
        billPassNo:event,
      };
      dispatch(getJuteBillPassHeader(UpdatedRec));
    }
    if(key ==="mrNo"){
      setHeaderData((prev)=>({
        ...prev,
        mrNo:event,
      }));
      const UpdatedRec={
        ...HeaderData,
        mrNo:event,
      };
      dispatch(getJuteBillPassHeader(UpdatedRec));
    }
    if(key ==="supplierCode"){
      setHeaderData((prev)=>({
        ...prev,
        gateEntry:event,
      }));
      const UpdatedRec={
        ...HeaderData,
        gateEntry:event,
      };
      dispatch(getJuteBillPassHeader(UpdatedRec));
    }
    if(key ==="gateEntry"){
      setHeaderData((prev)=>({
        ...prev,
        gateEntry:event,
      }));
      const UpdatedRec={
        ...HeaderData,
        gateEntry:event,
      };
      dispatch(getJuteBillPassHeader(UpdatedRec));
    }
    if(key ==="poNum"){
      setHeaderData((prev)=>({
        ...prev,
        poNum:event,
      }));
      const UpdatedRec={
        ...HeaderData,
        poNum:event,
      };
      dispatch(getJuteBillPassHeader(UpdatedRec));
    }
    if(key ==="chalanNo"){
      setHeaderData((prev)=>({
        ...prev,
        chalanNo:event,
      }));
      const UpdatedRec={
        ...HeaderData,
        chalanNo:event,
      };
      dispatch(getJuteBillPassHeader(UpdatedRec));
    }
    if(key ==="vehicleNo"){
      setHeaderData((prev)=>({
        ...prev,
        vehicleNo:event,
      }));
      const UpdatedRec={
        ...HeaderData,
        vehicleNo:event,
      };
      dispatch(getJuteBillPassHeader(UpdatedRec));
    }
  }

const handleSelectDate =(e,name)=>{
  if(name==="billDate"){
    let date=moment(e).format();
    let Date=date.split("T",1).toString();
    setHeaderData((prev)=>({
      ...prev,
      billDate:Date,
    }));
    const UpdatedRec={
      ...HeaderData,
      billDate:Date,
    };
    dispatch(getJuteBillPassHeader(UpdatedRec));
  }

  if(name==="poDate"){
    let date=moment(e).format();
    let Date=date.split("T",1).toString();
    setHeaderData((prev)=>({
      ...prev,
      poDate:Date,
    }));
    const UpdatedRec={
      ...HeaderData,
      poDate:Date,
    };
    dispatch(getJuteBillPassHeader(UpdatedRec));
  }

  if(name==="chalanDate"){
    let date=moment(e).format();
    let Date=date.split("T",1).toString();
    setHeaderData((prev)=>({
      ...prev,
      chalanDate:Date,
    }));
    const UpdatedRec={
      ...HeaderData,
      chalanDate:Date,
    };
    dispatch(getJuteBillPassHeader(UpdatedRec));
  }
}



  return (
    <>
      <div className="juteIndent">
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormContainer">
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            BillPass Date<div className="mandatoryField">*</div>
                          </label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                           inputFormat="dd-MM-yyyy"
                            readOnly={true}
                            value={HeaderData.billDate}
                            fullWidth
                            onChange={(e) =>
                              handleSelectDate(e, "billDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  readOnly={true}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <TextFieldReq
                        label="BillPass No."
                        placeholder="Enter here"
                        value={HeaderData.billPassNo}
                        onChange={onhandlechange("billPassNo")}
                        disabled={true}
                      />
                    </Box>
                    <Box gridColumn="span 3">
                      <TextFieldReq
                        label="MR No."
                        placeholder="Enter here"
                        value={HeaderData.mrNo}
                        onChange={onhandlechange("mrNo")}
                        disabled={true}
                      />
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextFieldReq
                          value={HeaderData.supplierName}
                          name="supplierCode"
                          label="Supplier"
                          required
                          disabled={true}
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <TextFieldReq
                        label="Gate Entry No."
                        placeholder="Enter here"
                        value={HeaderData.gateEntry}
                        onChange={onhandlechange("gateEntry")}
                        disabled={true}
                      />
                    </Box>
                    <Box gridColumn="span 3">
                      <TextFieldReq
                        label="PO No."
                        placeholder="Enter here"
                        value={HeaderData.poNum}
                        onChange={onhandlechange("poNum")}
                        disabled={true}
                      />
                    </Box>
                    <Box gridColumn="span 3">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            PO Date<div className="mandatoryField">*</div>
                          </label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            readOnly={true}
                            value={HeaderData.poDate}
                            fullWidth
                            onChange={(e) =>
                              handleSelectDate(e, "poDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  readOnly={true}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <TextFieldReq
                        label="Challan No."
                        placeholder="Enter here"
                        value={HeaderData.chalanNo}
                        onChange={onhandlechange("chalanNo")}
                        disabled={true}
                      />
                    </Box>
                    <Box gridColumn="span 3">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Challan Date<div className="mandatoryField">*</div>
                          </label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            readOnly={true}
                            value={
                              HeaderData.chalanDate
                            }
                            fullWidth
                            onChange={(e) =>
                              handleSelectDate(e, "chalanDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  readOnly={true}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextFieldReq
                          value={HeaderData.agentName}
                          name="agentId"
                          label="Agent"
                          required
                          disabled={true}
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <TextFieldReq
                        label="Vehicle No."
                        placeholder="Enter here"
                        value={HeaderData.vehicleNo}
                        onChange={onhandlechange("vehicleNo")}
                        disabled={true}
                      />
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={branchListData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={handlePropSelectChange}
                          isDropdownOpen={isDropdownOpen}
                          handleMenuOpen={handleMenuOpen}
                          selected={HeaderData.branchId}
                        update={HeaderData.branchId ? 1 : 0}
                          name="branchId"
                          label="branch"
                          required
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}
export default JuteBillPassFillDetails;