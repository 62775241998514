// Authentication related pages
import Login from "../Pages/Authentication/Login";
import Logout from "../Pages/Authentication/Logout";
// Dashboard
import Dashboard from "../Pages/Dashboard/Dashboard";
import ResetPassword from "../Pages/Authentication/ResetPassword";
import CreatePassword from "../Pages/Authentication/CreatePassword";
import ForgotPassword from "../Pages/Authentication/ForgetPassword";
import Indent from "../Pages/Purchase/Indent/index";
import CreateIndentBOMBOQ from "../Pages/Purchase/Indent/CreatePage";
import PriceEnquiry from "../Pages/Purchase/PriceEnquiry/index";
import CreatePriceEnquiry from "../Pages/Purchase/PriceEnquiry/CreatePriceEnquiry";
import Pr_response from "../Pages/Purchase/Pr_response/index";
import CreatePrResponse from "../Pages/Purchase/Pr_response/CreatePrResponse";
import Inward from "../Pages/Purchase/Inward_WorkReport/Index";
import HR_dashboard from "../Pages/HR_Management/EmployeeDatabase/HR_dashboard";
import CreateInwardEntry from "../Pages/Purchase/Inward_WorkReport/CreatePage";
import CreateDebitCreditNote from "../Pages/Purchase/Debit_Credit_Note/CreateDebitCreditNote";
import CreatePurchaseOrder from "../Pages/Purchase/PO_WO/index";
import CreatePagePOWO from "../Pages/Purchase/PO_WO/CreatePage";
import PaySlip from "../Pages/HR_Management/PaySlip/index";
import PayRegister from "../Pages/HR_Management/PayRegister/index";
import MaterialRequest from "../Pages/StoreModule/MaterialRequest";
import CreateMaterialRequest from "../Pages/StoreModule/MaterialRequest/MaterialRequestCreate";
import CreateNew from "../Pages/HR_Management/PayRegister/CreateNew";
import AdvanceRequest from "../Pages/HR_Management/AdvanceRequest";
import CreateAdvanceRequest from "../Pages/HR_Management/AdvanceRequest/CreateAdvanceRequest";
import ExpenseBooking from "../Pages/HR_Management/ExpenseBooking";
import StockTransfer from "../Pages/StoreModule/StockTransfer/index";
import CreateStockTransfer from "../Pages/StoreModule/StockTransfer/StockTransferCreate";
import StockReturn from "../Pages/StoreModule/StockReturn/index";
import CreateStockReturn from "../Pages/StoreModule/StockReturn/StockReturnCreate";
import ClientVisitPlan from "../Pages/HR_Management/ClientVisitPlan";
import LeaveRequest from "../Pages/HR_Management/LeaveRequest";
import PayRollData from "../Pages/HR_Management/PayRollData";
import CreateRequest from "../Pages/HR_Management/ClientVisitPlan/CreateRequest";
import CreateExpense from "../Pages/HR_Management/ExpenseBooking/CreateExpense";
import ApplyLeave from "../Pages/HR_Management/LeaveRequest/ApplyLeave";
import AttendanceCalender from "../Pages/HR_Management/AttendanceCalender/index";
import MarkAttendence from "../Pages/HR_Management/AttendanceCalender/MarkAttendence";
import BulkAttendance from "../Pages/HR_Management/AttendanceCalender/BulkAttendance";
import AttendanceCalendarView from "../Pages/HR_Management/AttendanceCalender/AttendanceCalendarView";
import ViewPayRegister from "../Pages/HR_Management/PayRegister/ViewPayRegister";
import ListOfEmployees from "../Pages/HR_Management/EmployeeDatabase/ListOfEmployees/index";
import AddEmployee from "../Pages/HR_Management/EmployeeDatabase/AddEmployees/index";
import debit_credit_note from "../Pages/Purchase/Debit_Credit_Note/index";
import PersonalInformation from "../Pages/HR_Management/EmployeeDatabase/AddEmployees/PersonalInformation/index";
import OfficalInformation from "../Pages/HR_Management/EmployeeDatabase/AddEmployees/OfficialInformation/index";
import UploadDocument from "../Pages/HR_Management/EmployeeDatabase/AddEmployees/UploadDocuments/UploadDocument";
import ShiftAndLeavePolicy from "../Pages/HR_Management/EmployeeDatabase/AddEmployees/ShiftAndLeavePolicy/index";
import MedicalEnrollment from "../Pages/HR_Management/EmployeeDatabase/AddEmployees/MedicalEnrollment/index";
import ViewPaySlip from "../Pages/HR_Management/PaySlip/ViewPaySlip";

import Onboarding from "../Pages/HR_Management/EmployeeDatabase/AddEmployees/Onboarding/Onboarding";
import ViewLeaveRequest from "../Pages/HR_Management/LeaveRequest/ViewLeaveRequest";
import ViewAttendanceApproval from "../Pages/HR_Management/AttendanceCalender/ViewAttendanceApproval";
import AttendanceLogs from "../Pages/HR_Management/AttendanceCalender/Logs";
import TaxMaster from "../Pages/Master/TaxMaster/index";
import CreateTaxMaster from "../Pages/Master/TaxMaster/createTaxMaster";
import OfferLetter from "../Pages/HR_Management/EmployeeDatabase/AddEmployees/GenerateLetters/OfferLetter";
import ChangePassword from "../Pages/Authentication/ChangePassword";
import MyProfile from "../components/Header/MyProfile";
//payscheme Parameters
import PaySchemeIndex from "../Pages/HR_Management/PaySchemeParameters/index";
import CreatePayScheme from "../Pages/HR_Management/PaySchemeParameters/CreatePayScheme";
//payscheme Creation
import PaySchemeCreationIndex from "../Pages/HR_Management/PaySchemeCreation/index";
import CreatePayCreation from "../Pages/HR_Management/PaySchemeCreation/CreatePayCreation";
// Spell master
import SpellMaster from "../Pages/Master/SpellMaster/index";
import CreateSpellMaster from "../Pages/Master/SpellMaster/CreateSpellMaster";
import ViewSpellMaster from "../Pages/Master/SpellMaster/ViewSpellMaster";
import QuotationGridView from "../Pages/SalesModule/Quotation/QutationGridView";
import CreateQuotation from "../Pages/SalesModule/Quotation/CreateQuotation";
import SalesOrderIndex from "../Pages/SalesModule/SalesOrder/index";
import DeliveryOrderIndex from "../Pages/SalesModule/DeliveryOrder/index";
import CreateDeliveryOrder from "../Pages/SalesModule/DeliveryOrder/CreateDeliveryOrder";
import CreateInvoice from "../Pages/SalesModule/Invoice/CreateInvoice";

import ReOcurringInvoiceIndex from "../Pages/SalesModule/Re-OccuringInvoice/index";
import CreateReOcurringInvoice from "../Pages/SalesModule/Re-OccuringInvoice/CreateRe-OccuringInvoice";
//Supplier Master
import SupplierMaster from "../Pages/Master/SupplierMaster/index";
import CreateSupplierMaster from "../Pages/Master/SupplierMaster/createSupplierMaster";
import AadharVerification from "../Pages/HR_Management/EmployeeDatabase/AddEmployees/PersonalInformation/AadharVerification";

//Uom Master
import UomMaster from "../Pages/Master/UomMaster/index";
import CreateUomMaster from "../Pages/Master/UomMaster/CreateUomMaster";

//Uom Master end
//Department Master
import DepartmentMaster from "../Pages/Master/DepartmentMaster/index";
import CreateDepartmentMaster from "../Pages/Master/DepartmentMaster/CreateDepartmentMaster";
//Supplier Items
import SupplierItems from "../Pages/Master/SupplierItems/index";
import CreateSupplierItems from "../Pages/Master/SupplierItems/CreateSupplierItems";
//Item Master
import ItemMaster from "../Pages/Master/ItemMaster/index";
import CreateItemMaster from "../Pages/Master/ItemMaster/CreateItemMaster";
//ItemGroup Master
import ItemGroupMaster from "../Pages/Master/ItemGroupMaster/index";
import CreateItemGroupMaster from "../Pages/Master/ItemGroupMaster/CreateItemGroupMaster";
//Quality master
import QualityMaster from "../Pages/Master/QualityMaster/index";
import CreateQualityMaster from "../Pages/Master/QualityMaster/CreateQualityMaster";
//Weaving Quality master
import WeavingQualityMaster from "../Pages/Master/WeavingQualityMaster/index";
import CreateWeavingQualityMaster from "../Pages/Master/WeavingQualityMaster/CreateWeavingQualityMaster";
//Spinninging Quality master
import SpinningQualityMaster from "../Pages/Master/SpinningQualityMaster";
import CreateSpinningQualityMaster from "../Pages/Master/SpinningQualityMaster/CreateSpinningQuality";
//Machine master
import MachineMaster from "../Pages/Master/MachineMaster";
import CreateMachineMaster from "../Pages/Master/MachineMaster/CreateMachineMaster";
//Company master
import CompanyMaster from "../Pages/Master/CompanyMaster";
import CreateCompanyMaster from "../Pages/Master/CompanyMaster/CreateCompanyMaster";
//Branch master
import BranchMaster from "../Pages/Master/BranchMaster";
import CreateBranchMaster from "../Pages/Master/BranchMaster/CreateBranchMaster";
//Sub Department Master
import SubDepartmentMaster from "../Pages/Master/SubDepartmentMaster";
import CreateSubDepartmentMaster from "../Pages/Master/SubDepartmentMaster/CreateSubDepartment";
//VehicleTypes Master
import VehicleTypesMaster from "../Pages/Master/VehicleTypesMaster";
import CreateVehicleTypesMaster from "../Pages/Master/VehicleTypesMaster/CreateVehicleType";
//Mukam Master
import MukamMaster from "../Pages/Master/MukamMaster";
import CreateMukamMaster from "../Pages/Master/MukamMaster/CreateMukamMaster";
//Yarn Master
import YarnMaster from "../Pages/Master/YarnMaster";
import CreateYarnMaster from "../Pages/Master/YarnMaster/CreateYarnMaster";
//Ledger Master
import LedgerMaster from "../Pages/Master/LedgerMaster";
import CreateLedgerMaster from "../Pages/Master/LedgerMaster/CreateLedgerMaster";
//Ledger Groups Master
import LedgerGroups from "../Pages/Master/LedgerGroups";
import CreateLedgerGroups from "../Pages/Master/LedgerGroups/CreateLedgerGroups";
//Transporter Master
import TransporterMaster from "../Pages/Master/TransporterMaster";
import CreateTransporterMaster from "../Pages/Master/TransporterMaster/CreateTransporterMaster";
//Designation Master
import DesignationMaster from "../Pages/Master/DesignationMaster";
import CreateDesignationMaster from "../Pages/Master/DesignationMaster/CreateDesignation";
import Bills from "../Pages/Accounting/Bills/index";
import SalesBillsGridView from "../Pages/Accounting/SalesBills/SalesBillsGridView";
//Terms
import Terms from "../Pages/Master/Terms";
import CreateTermsMaster from "../Pages/Master/Terms/CreateTermsMaster";
//Broker MASTER
import BrokerMaster from "../Pages/Master/BrokerMaster";
import CreateBrokerMaster from "../Pages/Master/BrokerMaster/CreateBrokerMaster";
//Agent MASTER
import AgentMaster from "../Pages/Master/AgentMaster";
import CreateAgentMaster from "../Pages/Master/AgentMaster/CreateAgentMaster";
//Workwer Category MASTER
import WorkerCategoryMaster from "../Pages/Master/WorkerCategoryMaster";
import CreateWorkerCategory from "../Pages/Master/WorkerCategoryMaster/CreateWorkerCategory";
//Cost Center MASTER
import CostCenterMaster from "../Pages/Master/CostCenterMaster";
import CreateCostCenterMaster from "../Pages/Master/CostCenterMaster/CreateCostCenterMaster";
//Travel Catageries
import TravelCatageries from "../Pages/Master/TravelCatageries";
import CreateTravelCatageries from "../Pages/Master/TravelCatageries/CreateTravelCatageries";
//Bank Accounts
import BankAccounts from "../Pages/Master/BankAccounts";
import CreateBankAccounts from "../Pages/Master/BankAccounts/CreateBankAccounts";
//Leave Types
import LeaveTypes from "../Pages/Master/LeaveTypes";
import CreateLeaveTypes from "../Pages/Master/LeaveTypes/CreateLeaveTypes";
//Help Topic
import HelpTopic from "../Pages/Master/HelpTopic";
import CreateHelpTopic from "../Pages/Master/HelpTopic/CreateHelpTopic";
//Problem Master
import ProblemMaster from "../Pages/Master/ProblemMaster";
import CreateProblemMaster from "../Pages/Master/ProblemMaster/CreateProblemMaster";
//Carriers
import Carriers from "../Pages/Master/Carriers";
import CreateCarriers from "../Pages/Master/Carriers/CreateCarriers";
//Package Master
import PackageMaster from "../Pages/Master/PackageMaster";
import CreatePackageMaster from "../Pages/Master/PackageMaster/CreatePackageMaster";
//Customer Master
import CustomerMaster from "../Pages/Master/CustomerMaster";
import CreateCustomerMaster from "../Pages/Master/CustomerMaster/CreateCustomerMaster";
//Warehouse Master
import WarehouseMaster from "../Pages/Master/WarehouseMaster";
import CreateWarehouseMaster from "../Pages/Master/WarehouseMaster/CreateWarehouseMaster";
//Budget Heads Master
import BudgetHeads from "../Pages/Master/BudgetHeads";
import CreateBudgetHeads from "../Pages/Master/BudgetHeads/CreateBudgetHeads";

// Shift Master
import CreateShift from "../Pages/Master/ShiftMaster/CreateShift";
// Holiday Calendar
import HolidayCalendar from "../Pages/Master/HolidayCalendar/HolidayCalendar";
import HolidayList from "../Pages/Master/HolidayCalendar/HolidayList";
import CreateHoliday from "../Pages/Master/HolidayCalendar/CreateHoliday";
//Accounting Module starts
//Bills
import CreateBills from "../Pages/Accounting/Bills/CreateBills";
//payments
import Payments from "../Pages/Accounting/Payments/index";
import CreatePayments from "../Pages/Accounting/Payments/CreatePayments";
//Receipts
import Receipts from "../Pages/Accounting/Receipts/index";
import CreateReceipts from "../Pages/Accounting/Receipts/CreateReceipts";
//LedgerWise Transactions
import LedgerWiseTransactions from "../Pages/Accounting/LedgerWiseTransactions";
//Daybook module
import Daybook from "../Pages/Accounting/Daybook";
//Projects Module
//ProjectList Module
import ProjectList from "../Pages/Projects/ProjectList";
import CreateProjectList from "../Pages/Projects/ProjectList/CreateProjectList";

//Cost Plan module
import CostPlan from "../Pages/Projects/CostPlan";
import CreateCostPlan from "../Pages/Projects/CostPlan/CreateCostPlan";
import BulkEmployeeUpload from "../Pages/HR_Management/EmployeeDatabase/BulkEmployeeUpload/BulkEmployeeUpload";
// Jute Module
import JuteIndentList from "../Pages/Jute/JuteIndent";
import CreateJuteIndent from "../Pages/Jute/JuteIndent/CreateJuteIndent";
import JuteGateEntry from "../Pages/Jute/JuteGateEntry";
import CreateJuteGateEntry from "../Pages/Jute/JuteGateEntry/CreateJuteGateEntry.jsx";
// import CreateJuteIndent from "../Pages/Jute/JuteIndent/CreateJuteIndent";

import JutePurchaeOrder from "../Pages/Jute/JutePurchaseOrder/index.js";
import CreateJutePurchaseOrder from "../Pages/Jute/JutePurchaseOrder/CreateJutePurchaseOrder.jsx";

import JuteQualityCheck from "../Pages/Jute/JuteQualityCheck/index2.js";
import CreateJuteQualityCheck from "../Pages/Jute/JuteQualityCheck/CreateJuteQualityCheck";
import JuteMaterialReceipt from "../Pages/Jute/JuteMaterialReceipt";
import JuteMaterialReceiptFillDetails from "../Pages/Jute/JuteMaterialReceipt/CreateJuteMaterialReceipt";
// import BillPassFillDetals from "../Pages/Jute/JuteBillPass/BillPassFillDetals";
import JuteBillPassFillDetals from "../Pages/Jute/JuteBillPass/UpdateBillPass";

import JuteBillPass from "../Pages/Jute/JuteBillPass";
import JuteMarketRate from "../Pages/Jute/JuteMarketRate/JuteMarketRate";

import JuteIssue from "../Pages/Jute/JuteIssue";
import CreateJuteIssue from "../Pages/Jute/JuteIssue/CreateJuteIssue";

import JuteConversion from "../Pages/Jute/JuteConversion";
import CreateJuteConversion from "../Pages/Jute/JuteConversion/CreateJuteConversionFillDetails";

import JuteBatchPlanningForm from "../Pages/Jute/JuteBatchPlanningForm";
import CreateJuteBatchFillDetails from "../Pages/Jute/JuteBatchPlanningForm/CreateJuteBatchFillDetails";

import FinishingEntry from "../Pages/JuteProduction/FinishingEntry";
import CreateFinishingEntry from "../Pages/JuteProduction/FinishingEntry/CreateFinishingEntry";

import WeavingProduction from "../Pages/JuteProduction/WeavingProduction";
import CreateWeavingProduction from "../Pages/JuteProduction/WeavingProduction/CreateWeavingProduction";

import BeamingProduction from "../Pages/JuteProduction/BeamingProduction";
import CreateBeamingProduction from "../Pages/JuteProduction/BeamingProduction/CreateBeamingProduction";

import WindingDoffEntry from "../Pages/JuteProduction/WindingDoffEntry";
import CreateWindingDoffEntry from "../Pages/JuteProduction/WindingDoffEntry/CreateWindingDoffEntry";

import CutsAndJugarEntry from "../Pages/JuteProduction/CutsAndJugarEntry";
import CreateCutsAndJugarEntry from "../Pages/JuteProduction/CutsAndJugarEntry/CreateCutsAndJugarEntry";

import SpinningDoffEntry from "../Pages/JuteProduction/SpinningDoffEntry";
import CreateSpinningDoffEntry from "../Pages/JuteProduction/SpinningDoffEntry/CreateSpinningDoffEntry";
import WastageEntry from "../Pages/JuteProduction/WastageEntry";
import CreateWastageEntry from "../Pages/JuteProduction/WastageEntry/CreateWastageEntry";

// Sales Order
import CreateSalesOrder from "../Pages/SalesModule/SalesOrder/CreateSalesOrder";
import InvoiceGrid from "../Pages/SalesModule/Invoice/InvoiceGrid";
import GST_Credentials from "../Pages/GST/Credentails/GST_Credentials";
const authProtectedRoutes = [
  // {path: "/dashboard", component: Dashboard },

  { path: "/dashboard", component: Dashboard },
  // ******Purchase module starts******
  { path: "/Purchase", component: Dashboard },
  { path: "/store_indent", component: Indent },
  { path: "/openIndent", component: Indent },
  { path: "/create_indent", component: CreateIndentBOMBOQ },
  { path: "/create_openIndent", component: CreateIndentBOMBOQ },
  { path: "/purchase_price_request", component: PriceEnquiry },
  { path: "/create_priceenquiry", component: CreatePriceEnquiry },
  { path: "/work_order", component: CreatePurchaseOrder },
  { path: "/purchase_purchase_order", component: CreatePurchaseOrder },
  { path: "/create_workorder", component: CreatePagePOWO },
  { path: "/purchase_purchase_order", component: CreatePurchaseOrder },
  { path: "/create_purchase_order", component: CreatePagePOWO },
  { path: "/pr_response", component: Pr_response },
  { path: "/create_pr_response", component: CreatePrResponse },
  { path: "/security_store_gate_entry", component: Inward },
  { path: "/workReport", component: Inward },
  { path: "/create_WorkReport", component: CreateInwardEntry },
  { path: "/create_inward", component: CreateInwardEntry },
  { path: "/store_receipt", component: Inward },
  { path: "/Create_store_receipt", component: CreateInwardEntry },
  { path: "/store_material_inspection", component: Inward },
  { path: "/create_material_inspection", component: CreateInwardEntry },
  { path: "/store_debit_credit_note", component: debit_credit_note },
  { path: "/Create_DC_note", component: CreateDebitCreditNote },
  { path: "/store_bill_pass", component: Inward },
  { path: "/create_store_bill_pass", component: CreateInwardEntry },
  // {path:'/Reports', component:'https://data.smartworks.vowerp.com/'},
  // ******Purchase module ends******
  { path: "/Store", component: MaterialRequest },
  { path: "/store_issue", component: MaterialRequest },
  { path: "/create_consumption", component: CreateMaterialRequest },
  { path: "/payslip", component: PaySlip },
  { path: "/payregister", component: PayRegister },
  { path: "/material_request", component: MaterialRequest },
  { path: "/create_material_request", component: CreateMaterialRequest },
  { path: "/create_new", component: CreateNew },
  { path: "/advancerequest", component: AdvanceRequest },
  { path: "/create_advanceRequest", component: CreateAdvanceRequest },
  { path: "/expensebooking", component: ExpenseBooking },
  { path: "/stock_transfer", component: StockTransfer },
  { path: "/createStockTransfer", component: CreateStockTransfer },
  { path: "/stock_return", component: StockReturn },
  { path: "/createStockReturn", component: CreateStockReturn },
  { path: "/clientvisit", component: ClientVisitPlan },
  { path: "/leaverequest", component: LeaveRequest },
  { path: "/data_collection", component: PayRollData },
  { path: "/create_request", component: CreateRequest },
  { path: "/create_expense", component: CreateExpense },
  { path: "/apply_leave", component: ApplyLeave },

  { path: "/hrms", component: HR_dashboard },
  { path: "/employeedb", component: HR_dashboard },
  { path: "/bulk_employee", component: BulkEmployeeUpload },
  { path: "/bulk_attendance", component: BulkAttendance },
  { path: "/listOf_employees", component: ListOfEmployees },
  { path: "/add_employee", component: AddEmployee },
  { path: "/ViewPayRegister", component: ViewPayRegister },
  { path: "/add_employee", component: AddEmployee },
  { path: "/personal_information", component: PersonalInformation },
  { path: "/offical_information", component: OfficalInformation },
  { path: "/upload_document", component: UploadDocument },
  { path: "/shift_leavePolicy", component: ShiftAndLeavePolicy },
  { path: "/medical_enrollment", component: MedicalEnrollment },
  { path: "/view_paySlip", component: ViewPaySlip },
  { path: "/onboarding", component: Onboarding },
  { path: "/view_leaveRequest", component: ViewLeaveRequest },
  { path: "/view_attendanceApproval", component: ViewAttendanceApproval },
  { path: "/myProfile", component: MyProfile },
  { path: "/AadharVerification", component: AadharVerification },
  { path: "/mark_attendance", component: MarkAttendence },
  { path: "/attencalendar", component: AttendanceCalendarView },
  { path: "/logs", component: AttendanceLogs },
  { path: "/Attendance_list", component: AttendanceCalender },
  //payscheme Parameters
  { path: "/psparameter", component: PaySchemeIndex },
  { path: "/create_payScheme_parameters", component: CreatePayScheme },

  //payscheme Creation
  { path: "/payscheme", component: PaySchemeCreationIndex },
  { path: "/create_payScheme_creation", component: CreatePayCreation },

  //  Sales Modules starts
  { path: "/sales", component: Dashboard },
  { path: "/quotation", component: QuotationGridView },
  { path: "/create_quotation", component: CreateQuotation },
  { path: "/s_order", component: SalesOrderIndex },
  { path: "/create_sales_order", component: CreateSalesOrder },
  { path: "/delivery_order", component: DeliveryOrderIndex },
  { path: "/create_delivery_order", component: CreateDeliveryOrder },
  { path: "/invoice", component: InvoiceGrid },
  { path: "/create_invoice", component: CreateInvoice },
  { path: "/reoccurinvoice", component: ReOcurringInvoiceIndex },
  { path: "/create_ReOccuring_invoice", component: CreateReOcurringInvoice },

  // Master module starts
  { path: "/Master", component: Dashboard },
  { path: "/tax_master", component: TaxMaster },
  { path: "/create_tax_master", component: CreateTaxMaster },

  // Master module ends

  { path: "/offerLetter", component: OfferLetter },
  // Spell master
  { path: "/spell_master", component: SpellMaster },
  { path: "/create_spell_master", component: CreateSpellMaster },
  { path: "/view_spellMaster", component: ViewSpellMaster },
  //Supplier Master
  { path: "/supplier_master", component: SupplierMaster },
  { path: "/create_supplier_master", component: CreateSupplierMaster },

  { path: "/offerLetter", component: OfferLetter },
  { path: "/view_attendanceApproval", component: ViewAttendanceApproval },
  //Uom Master
  { path: "/uom_master", component: UomMaster },
  { path: "/create_uom_master", component: CreateUomMaster },
  //Department Master
  { path: "/department_master", component: DepartmentMaster },
  { path: "/create_department_master", component: CreateDepartmentMaster },
  //Supplier Items
  { path: "/supplier_items", component: SupplierItems },
  { path: "/create_supplier_items", component: CreateSupplierItems },
  //Item Master
  { path: "/item_master", component: ItemMaster },
  { path: "/create_item_master", component: CreateItemMaster },
  //ItemGroup Master
  { path: "/item_group_master", component: ItemGroupMaster },
  { path: "/create_item_group_master", component: CreateItemGroupMaster },
  //Quality Master
  { path: "/quality_master", component: QualityMaster },
  { path: "/create_quality_master", component: CreateQualityMaster },
  //Weaving Quality Master
  { path: "/weaving_quality_master", component: WeavingQualityMaster },
  {
    path: "/create_weaving_quality_master",
    component: CreateWeavingQualityMaster,
  },
  //spinning quality master
  { path: "/spinning_quality_master", component: SpinningQualityMaster },
  {
    path: "/create_spinning_quality_master",
    component: CreateSpinningQualityMaster,
  },
  //Machine master
  { path: "/machine_master", component: MachineMaster },
  { path: "/create_machine_master", component: CreateMachineMaster },
  //Company master
  { path: "/company_master", component: CompanyMaster },
  { path: "/create_company_master", component: CreateCompanyMaster },
  //Branch master
  { path: "/branch_master", component: BranchMaster },
  { path: "/create_branch_master", component: CreateBranchMaster },
  //sub Department Master
  { path: "/sub_department_master", component: SubDepartmentMaster },
  {
    path: "/create_sub_department_master",
    component: CreateSubDepartmentMaster,
  },
  // Vehicle Type Master
  { path: "/vehicle_types_master", component: VehicleTypesMaster },
  { path: "/create_vehicle_types_master", component: CreateVehicleTypesMaster },
  // Mukam Master
  { path: "/mukam_master", component: MukamMaster },
  { path: "/create_mukam_master", component: CreateMukamMaster },
  // Yarn Master
  { path: "/yarn_master", component: YarnMaster },
  { path: "/create_yarn_master", component: CreateYarnMaster },
  // Ledger Master
  { path: "/Ledger_Master", component: LedgerMaster },
  { path: "/create_Ledger_Master", component: CreateLedgerMaster },
  // Ledger Groups Master
  { path: "/Ledger_Groups", component: LedgerGroups },
  { path: "/create_Ledger_Groups", component: CreateLedgerGroups },
  // Transporter Master
  { path: "/transporter_master", component: TransporterMaster },
  { path: "/create_Transporter_master", component: CreateTransporterMaster },
  // Designation Master
  { path: "/designation_master", component: DesignationMaster },
  { path: "/create_designation_master", component: CreateDesignationMaster },
  // Terms Master
  { path: "/terms", component: Terms },
  { path: "/create_terms_master", component: CreateTermsMaster },
  // Broker Master
  { path: "/broker_master", component: BrokerMaster },
  { path: "/create_broker_master", component: CreateBrokerMaster },
  // Agent Master
  { path: "/agent_master", component: AgentMaster },
  { path: "/create_agent_master", component: CreateAgentMaster },
  // Worker Categotry Master
  { path: "/Worker_Category_Master", component: WorkerCategoryMaster },
  { path: "/create__Worker_Category_Master", component: CreateWorkerCategory },
  // Cost Center Master
  { path: "/Cost_Center_Master", component: CostCenterMaster },
  { path: "/create_Cost_Center_Master", component: CreateCostCenterMaster },
  // Travel Catageries
  { path: "/Travel_Catageries", component: TravelCatageries },
  { path: "/create_Travel_Catageries", component: CreateTravelCatageries },
  // Bank Accounts
  { path: "/Bank_Accounts", component: BankAccounts },
  { path: "/create_Bank_Accounts", component: CreateBankAccounts },
  // Leave Types
  { path: "/leave_types", component: LeaveTypes },
  { path: "/create_leave_types", component: CreateLeaveTypes },
  // Help Topic
  { path: "/helptopic", component: HelpTopic },
  { path: "/create_helptopic", component: CreateHelpTopic },
  // Problem Master
  { path: "/problemmaster", component: ProblemMaster },
  { path: "/create__problem_master", component: CreateProblemMaster },
  // Carriers
  { path: "/carriers", component: Carriers },
  { path: "/create__carriers", component: CreateCarriers },
  // Package Master
  { path: "/packagemaster", component: PackageMaster },
  { path: "/create__packagemaster", component: CreatePackageMaster },
  // Customer Master
  { path: "/Customer_Master", component: CustomerMaster },
  { path: "/create_Customer_Master", component: CreateCustomerMaster },
  // Warehouse Master
  { path: "/warehouse_master", component: WarehouseMaster },
  { path: "/create_warehouse_master", component: CreateWarehouseMaster },
  //Shift Master
  { path: "/shift_master", component: CreateShift },
  //Holiday Calendar
  { path: "/Holidays_Calendar", component: HolidayCalendar },
  { path: "/Holidays_List", component: HolidayList },
  { path: "/create_holiday", component: CreateHoliday },
  //  Accounting Module
  { path: "/accounting", component: Dashboard },
  // sales bills
  { path: "/sales_bills", component: SalesBillsGridView },
  //Bills
  { path: "/bills", component: Bills },
  { path: "/create_bills", component: CreateBills },
  //payments
  { path: "/payments", component: Payments },
  { path: "/create_payments", component: CreatePayments },
  //Receipts
  { path: "/receipts", component: Receipts },
  { path: "/create_receipts", component: CreateReceipts },
  //LedgerWiseTransactions
  { path: "/ledgerwisetrans", component: LedgerWiseTransactions },
  //Daybooks
  { path: "/daybook", component: Daybook },
  //Projects Module
  { path: "/projects", component: ProjectList },
  //project List
  { path: "/project_list", component: ProjectList },
  { path: "/create_project_list", component: CreateProjectList },
  //BOM Module
  { path: "/bom", component: Indent },
  { path: "/create_bom", component: CreateIndentBOMBOQ },
  //BOQ Module
  { path: "/boq", component: Indent },
  { path: "/create_boq", component: CreateIndentBOMBOQ },
  //Cost Plan Module
  { path: "/cost_plan", component: CostPlan },
  { path: "/create_cost_plan", component: CreateCostPlan },
  //budget Heads
  { path: "/budget_heads", component: BudgetHeads },
  { path: "/create_budget_heads", component: CreateBudgetHeads },

  //*************************************************************Jute Module starts**************************************//
  //Jute Indent
  { path: "/jute_indent", component: JuteIndentList },
  { path: "/create_jute_indent", component: CreateJuteIndent },
  //Jute Indent
  { path: "/security_jute_gate_entry", component: JuteGateEntry },
  { path: "/create_jute_gate_entry", component: CreateJuteGateEntry },
  { path: "/jute_purchase_order", component: JutePurchaeOrder },
  { path: "/create_jute_purchase_order", component: CreateJutePurchaseOrder },
  //Jute Quality Check
  { path: "/jute_quality_check", component: JuteQualityCheck },
  {
    path: "/update_jute_quality_check",
    component: CreateJuteQualityCheck,
  },
  //Jute Material Receipt
  { path: "/jute_material_receipt", component: JuteMaterialReceipt },
  {
    path: "/update_jute_material_receipt",
    component: JuteMaterialReceiptFillDetails,
  },
  {
    path: "/update_jute_bill_pass",
    component: JuteBillPassFillDetals,
  },
  //Jute Bill Pass
  { path: "/jute_bill_pass", component: JuteBillPass },
  //Jute Market rate
  { path: "/jute_market_rate", component: JuteMarketRate },
  // Jute Issue
  { path: "/jute_issue", component: JuteIssue },
  { path: "/create_jute_issue", component: CreateJuteIssue },
  // Jute Conversion
  { path: "/jute_conversion", component: JuteConversion },
  { path: "/create_jute_conversion", component: CreateJuteConversion },
  // Jute Batch Planning Form
  { path: "/batch_planning_form", component: JuteBatchPlanningForm },
  {
    path: "/create_batch_planning_form",
    component: CreateJuteBatchFillDetails,
  },
  // Jute Production
  // Finishing Entry
  { path: "/ad_finishing_entry", component: FinishingEntry },
  { path: "/create_finishing_entry", component: CreateFinishingEntry },
  // Weaving production
  { path: "/pr_weaving_production", component: WeavingProduction },
  { path: "/create_weaving_production", component: CreateWeavingProduction },
  //wastage entry
  { path: "/ad_wastage_entry", component: WastageEntry },
  { path: "/create_wasteage_entry", component: CreateWastageEntry },
  // Beaming production
  { path: "/pr_beaming_production", component: BeamingProduction },
  { path: "/create_beaming_production", component: CreateBeamingProduction },
  // Winding Doff Entry
  { path: "/ad_winding_doff_entry", component: WindingDoffEntry },
  { path: "/create_winding_doff_entry", component: CreateWindingDoffEntry },
  // Cuts and Jugar Entry
  { path: "/ad_cuts_jugar_entry", component: CutsAndJugarEntry },
  { path: "/create_cuts_and_jugar_entry", component: CreateCutsAndJugarEntry },
  // Spinning Doff Entry
  { path: "/ad_spinning_doff_entry", component: SpinningDoffEntry },
  { path: "/create_spinning_doff_entry", component: CreateSpinningDoffEntry },


  //GST 
  {path:"/gst_credentials",component:GST_Credentials}
];
const authProtectedRoutesWithOutInnerNavigation = [
  { path: "/createpassword", component: CreatePassword },
  { path: "/forgotPassword", component: ForgotPassword },
  { path: "/changePassword", component: ChangePassword },
  { path: "/resetpassword", component: ResetPassword },

];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/", component: Login },
  { path: "/login", component: Login },
];

export {
  authProtectedRoutes,
  publicRoutes,
  authProtectedRoutesWithOutInnerNavigation,
};
