import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Grid } from "@mui/material";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import { serverApi, getTenantId } from "../../../helpers/Consts";
import { postUpload } from "../../../helpers/Server_Helper";
import { postUploadFileList } from "../../../store/MyProfile/UploadFile/actions";
import { uploadFormdataWithImage } from "../../../helpers/server";
import DownloadIcon from "@mui/icons-material/Download";
import AttnLogs from "../../../assets/CSV/AttnLogs.xlsx";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import swal from "sweetalert";

class BulkAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      imagePreview_Url: "",
      fileUpload: "",
      imagePreview: "d-none",
      uploadFileName: "d-none",
      uploadFileBlock: "d-block",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      ebId: localStorage.getItem("ebId"),
    };
  }
  handleFileClick() {
    var input = document.createElement("input");
    input.type = "file";
    input.onchange = this.handleFileChange;
    input.click();
  }

  handleselectedFile = (e) => {
    swal({
      title: "",
      text: "As the first row of data is simply Added for reference, kindly remove it.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        e.preventDefault();
        let file = e.target.files[0];
        this.setState({
          fileName: file.name,
          fileExtension: file.name.split(".").pop(),
        });
        this.setState({
          selectedFile: e.target.files[0],
          selectedFileName: e.target.files[0].name,
          uploadFileName: "d-block",
          uploadFileBlock: "d-none",
        });
        this.props.uploadFormdataWithImage(
          serverApi.ATTENDANCE_BULK_UPLOAD + this.state.userDit.userId,
          file,
          this.handleFile
        );
      } else {
        swal("Your action is canceled!");
      }
    });
  };
  handleDownloadFile = () => {
    var headers = {
      Authorization:
        localStorage.getItem("token_type") +
        localStorage.getItem("access_token"),
      Accept: "application/json",

      "Content-Type": "application/json",
    };
    if (getTenantId(window.location.hostname) !== null) {
      headers = {
        Authorization:
          localStorage.getItem("token_type") +
          localStorage.getItem("access_token"),
        "X-TenantId": getTenantId(window.location.hostname),
        Accept: "application/json",

        "Content-Type": "application/json",
        CompanyID: localStorage.getItem("CompanyId"),
      };
    }
    fetch(serverApi.DOWNLOAD_BULK_ATTENDANCE_TEMPLATE, {
      method: "GET",
      headers: headers,
    })
      .then((response) =>
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "AttendanceBulkTemplate.xlsx";
          a.click();
        })
      )
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            xs={6}
            mt={20}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="uploadAndDownloadBtn"
          >
            <Grid item xs={6} className="ducumentUploadBlock">
              <div
                className="documentDownload "
                onClick={() => this.handleDownloadFile()}
              >
                <DownloadIcon />
                Download Excel For Reference
              </div>
            </Grid>

            <Grid item xs={6}>
              <div>
                {this.props.label && <label>&nbsp;</label>}
                <div className="ducumentUploadBlock">
                  <div
                    className={
                      "documentUploadContent " + this.state.uploadFileBlock
                    }
                  >
                    <input
                      type="file"
                      name="file"
                      id="file-upload"
                      onChange={this.handleselectedFile}
                      className="hiddenInput"
                    />
                    <label htmlFor="file-upload" style={{ display: "flex" }}>
                      <Button
                        variant="contained"
                        component="span"
                        className="documentUpload"
                      >
                        <img src={panUploadIcon} alt="" /> Upload Excel File
                      </Button>
                    </label>
                  </div>
                  <div className={this.state.uploadFileName}>
                    <div className="uploadedInfo">
                      <div className="selectedFileName">
                        <img src={TickIcon} alt="" className="tickIcon" />
                        {this.state.selectedFileName}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Upload_File_List } = state.UploadFileReducer;
  return { Upload_File_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    postUploadFileList,
    postUpload,
    uploadFormdataWithImage,
  })(BulkAttendance)
);
