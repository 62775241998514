import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { serverApi } from "../../helpers/Consts";
import { viewbyidPersonalDetails } from "../../store/HRMS/EmployeeDataBase/actions";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  render() {
    return (
      <>
        <div></div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { personalDetails } = state.EmployeeReducer;
  return { personalDetails };
};

export default withRouter(
  connect(mapStatetoProps, {
    viewbyidPersonalDetails,
  })(Dashboard)
);
