import React from "react";
import ProductRow from "../ProductRow/ProductRow";
import { serverApi } from "../../../helpers/Consts";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {getTax_List} from '../../../store/Global/DropDownApis/actions';
class ProductTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {    
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
    };
  }

  componentDidMount() {
    this.props.getTax_List(
      serverApi.GET_ALL_TAXLIST +
        this.state.userDit.userId +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    );
  }

  onDelEvent() {
    this.setState({
      delete: true,
    });
  }
  render() {
    let ItemGrpMasterList = this.props.ItemGrp;
    let taxList = this.props.taxList
    var onhandlechangeValue = this.props.onProductTableUpdate;
    var rowDel = this.props.onRowDel;
    var onHandleQty = this.props.onSelectHandle;
    var keyDown = this.props.onKeyDown;
    var checkboxBlock = this.props.onChange;
    var toolTipmsg = this.props.toolTipMsg;
    var rowData = this.props.RowData;
    let itemsList = this.props.itemsList;
   let onSelectDep = this.props.onSelectDep; 
   let handleSelectDropDown = this.props.handleSelectDropDown;

    var product = this.props.products.map(function (products) {
      return (
        <ProductRow
        onhandlechangeValue={onhandlechangeValue}
        handleSelectDropDown={handleSelectDropDown}
        onSelectDep={onSelectDep}
        taxList={taxList}
        ItemGrpMasterList={ItemGrpMasterList}
          onSelecthandle={onHandleQty.bind(this)}
          itemsList={itemsList}
          onKeyDown={keyDown}
          onCheckbox={checkboxBlock}
          product={products}
          onDelEvent={rowDel.bind(this)}
          key={products.id}
          toolTipMsg={toolTipmsg}
          rowData={rowData}
        />
      );
    });
    return (
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent">
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {this.props.tableHeaders.map((column) => (
                    <th>{column.header}</th>
                  ))}
                </tr>
              </thead>

              <tbody>{product}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const {taxList} = state.taxList
  return { taxList};
};

export default withRouter(
  connect(mapStatetoProps, {
    getTax_List
  })(ProductTable)
);
