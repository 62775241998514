export const LEAVE_REQUEST_LIST = "leave_request_list"; 
export const LEAVE_REQUEST_LIST_SUCCESSFULL = "leave_request_list_successfull";

export const LEAVE_LEDGERLIST ="leave_ledger_list";
export const LEAVE_LEDGERLIST_SUCCESSFULL ="leave_ledger_list_successfull";

export const ADD_LEAVE_TRANSACTION ='add_leave_transaction';
export const ADD_LEAVE_TRANSACTION_SUCCESSFULL ='add_leave_transaction_successfull';

export const UPDATE_LEAVE_REQUEST ='update_leave';
export const UPDATE_LEAVE_REQUEST_SUCCESSFULL ='update_leave_successfull';

export const LEAVE_REQUEST_VIEW_BY_ID = "leave_request_view_by_id";
export const LEAVE_REQUEST_VIEW_BY_ID_SUCCESSFULL = "leave_request_view_by_id_successfull";


export const LEAVE_LEDGERLIST_FOR_EMPLOYEE ='leave_ledgerlist_for_employee';
export const LEAVE_LEDGERLIST_FOR_EMPLOYEE_SUCCESSFULL ='leave_ledgerlist_for_employee_successfull';

export const VIEW_BY_ID_FOR_LEAVEREQUEST ='view_by_id_for_leaverequest';
export const VIEW_BY_ID_FOR_LEAVEREQUEST_SUCCESSFULL ='view_by_id_for_leaverequest_successfull';

export const LEAVE_TYPE_SELECTION ='leave_type_selection'
export const LEAVE_TYPE_SELECTION_SUCCESSFULL ='leave_type_selection_successfull'

export const WORKER_DATA_BY_EB_NO = 'worker_data_by_eb_no'
export const WORKER_DATA_BY_EB_NO_SUCCESSFULL ='worker_data_by_eb_no_successfull'

export const WORKER_DATA_CLEAR = 'worker_data_clear';