import { Component } from "react";
import { connect } from "react-redux";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import { withRouter } from "react-router-dom";
import {
  beamingList,
  beamingDeleteRow,
} from "../../../store/JuteProduction/BeamingProduction/actions";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridDeleteIcon from "../../../assets/images/deleteIconButton.png";

class BeamingProductionIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      startIndex: "",
      lastIndex: "",
      BeamingProductionContent: [],
      fromDate: "",
      toDate: "",
    };
  }
  componentDidMount() {
    const data = {
      companyId: localStorage.getItem("companyId"),
      fromDate: "10-01-1989",
      toDate: "10-01-1989",
    };
    this.props.beamingList(
      serverApi.GET_BEAMING_PRODUCTION,
      data,
      this.props.history
    );
  }

  handleSearch = (searchData) => {
    this.state.toDate = searchData.toDate;

    const data = {
      companyId: localStorage.getItem("companyId"),
      fromDate: searchData.fromDate,
      toDate: searchData.toDate,
    };
    this.props.beamingList(
      serverApi.GET_BEAMING_PRODUCTION,
      data,
      this.props.history
    );
    this.setState({
      fromDate: searchData.fromDate,
      toDate: searchData.toDate,
    });
    showSpinner();
  };
  componentWillReceiveProps(props) {
    if (props.beamingProductionLists) {
      this.setState({
        BeamingProductionContent: props.beamingProductionLists.data,
      });
      hideSpinner();
    }
  }

  handledeleteRecord = (row) => {
    this.props.beamingDeleteRow(
      serverApi.DEL_ROW_BEAMING_PRODUCTION + row.id,
      this.props.history,
      this.state.fromDate,
      this.state.toDate
    );
    showSpinner();
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.BeamingProductionContent}
          mcolumns={Columns}
          status={true}
          micon={GridDeleteIcon}
          actions={false}
          deleteIcon={true}
          filter_form={"BeamingProductionFilter"}
          handleSearch={this.handleSearch}
          handledeleteRecord={this.handledeleteRecord}
          url="/create_beaming_production"
        />
      </>
    );
  }
}
// machine master header
const Columns = [
  {
    hidden: true,
    Header: "Id",
    accessor: "id",
  },
  {
    hidden: false,
    Header: "Beaming Date",
    accessor: "tranDate",
  },
  {
    hidden: false,
    Header: "Spell",
    accessor: "spell",
  },
  {
    hidden: false,
    Header: "Quality",
    accessor: "qualityCodeDesc",
  },
  {
    hidden: false,
    Header: "Machine Name",
    accessor: "machine",
  },
  {
    hidden: false,
    Header: "ENDS",
    accessor: "ends",
  },
  {
    hidden: false,
    Header: "Cuts",
    accessor: "noOfCuts",
  },
  {
    hidden: false,
    Header: "Beams",
    accessor: "noOfBeams",
  },
];
const mapStatetoProps = (state) => {
  const { beamingProductionLists, beamingDelRowData } =
    state.BeamingProductionListReducer;
  return { beamingProductionLists, beamingDelRowData };
};

export default withRouter(
  connect(mapStatetoProps, {
    beamingList,
    beamingDeleteRow,
  })(BeamingProductionIndex)
);
