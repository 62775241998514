import React, { Component } from "react";
import { Grid, Box, Button, Tooltip, IconButton } from "@mui/material";
import DynamicSelect from "../../StoreModule/MaterialRequest/DynamicSelect";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import { getItemsListforIndent } from "../../../store/Purchase/Indent/actions";
import {getItemListByItemGroupForInward,getItemDetailsByItemItemGrpForInward} from "../../../store/Purchase/Inward/actions.js"
import {
  InwardLineItemsProps,
  getFilesofInwardSuccessfull,
} from "../../../store/Purchase/Inward/actions";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getDepartmentList,
  allItemGroupMasterList,
  getParentBudgetHeads,
} from "../../../store/Global/DropDownApis/actions";
import Fade from "@mui/material/Fade";
import CustomButton from "../../../components/Buttons/Buttons";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { getFileDownload } from "../../../helpers/server.js";
import { serverVars } from "../../../helpers/Consts";
import {
  getPendingStockPoandReceipt,
  getPendingStock,
  actions,
  logs,
  getFiles,
  getItemGroupListforindent
} from "../../../store/Purchase/Indent/actions";
import { getFilesofInward } from "../../../store/Purchase/Inward/actions";
import {
  postUploadFileList,
  deleteFile,
} from "../../../store/MyProfile/UploadFile/actions";
import { getTax_List } from "../../../store/Global/DropDownApis/actions";
import DeleteIcons from "../../../assets/images/picEditIcon.png";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import swal from "sweetalert";
import { isEmpty, limitDecimals } from "../../../Utilities/helper.js";

class IN_WR_LineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      rowCount: 0,
      amount: 0,
      itemsList: [],
      taxList: [],
      inwardfilesList: [],
      fileName: "",
      fileType: "",
      lineItemId: 1,
      delete: false,
      deletedId: "",
      Inward_LineItems: this.props.Inward_LineItems,
      Logs: [],
      DepartmentList: [],
      AllItemGroupMasterList: [],
      termsNconditions: [],
      ItemGroupMasterList: [],
      parentBudgetHeadsList: [],
      internalNote: this.props.InwardFillDetails.internalNote,
      igstPercentage: 0,
      cgstPercentage: 0,
      sgstPercentage: 0,
      AdvancePercentage: this.props.InwardFillDetails.AdvancePercentage,
      AdvanceAmount: this.props.InwardFillDetails.AdvanceAmount,
      termsNcondition: this.props.InwardFillDetails.termsNcondition,
      selectTermsNconditions:
        this.props.InwardFillDetails.selectTermsNconditions,
      addlineItemId: 0,
      uploadFile: false,
      seeLogs: false,
      isDropdownOpen: false,
      qty: 0,
      locationState: "",
      make:"",
      remarks:"",
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.setState({
      locationState: this.props.location,
    });
    if (this.props.source !== "WITHPO") {
      this.props.getParentBudgetHeads(
        serverApi.GET_PARENT_BUDGET_HEADS_LIST +
          "company/" +
          localStorage.getItem("companyId"),
        this.props.history
      );
    } // calling parent budget heads api

    this.props.getDepartmentList(
      serverApi.GET_DEPARTMENT_LIST +
        localStorage.getItem("companyId") +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    );
    this.props.getTax_List(
      serverApi.GET_ALL_TAXLIST +
        localStorage.getItem("companyId") +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    );

    if (
      this.props.Inward_LineItems &&
      this.props.Inward_LineItems !== undefined
    ) {
      if (this.props.Inward_LineItems) {
        this.props.Inward_LineItems.map((item) => {
          if (this.props.source === "WITHPO") {
            if (item.departmentId !== null && item.departmentId !== "") {
              this.props.allItemGroupMasterList(
                serverApi.GET_ITEMGROUP_LIST +
                  item.departmentId +
                  "/" +
                  localStorage.getItem("companyId") +
                  "/getAllItemGroupsByDepartmentId/" +
                  this.state.userDit.cipher,
                this.props.history
              );
            }
            if (item.discountMode === 1) {
              let totalAmt = item.inwardQty * item.rate;
              let discountAmt = totalAmt - Number(item.discountPercentage);

              item.amount = discountAmt;
              this.setState({
                totalAmount: item.amount,
              });
              this.props.InwardFillDetails.totalAmount = item.amount;
            } else {
              let totalAmt = item.inwardQty * item.rate;
              let discountAmt =
                (totalAmt * Number(item.discountPercentage)) / 100;
              item.amount = totalAmt - discountAmt;
              this.setState({
                totalAmount: item.amount,
              });
              this.props.InwardFillDetails.totalAmount = item.amount;
            }
          } else {
            if (item.budgetHeadId !== null && item.budgetHeadId !== "") {
              this.props.allItemGroupMasterList(
                serverApi.GET_ITEMGROUPS_BYBUDGET_HEADS +
                  "/" +
                  localStorage.getItem("companyId") +
                  "/budgetHead/" +
                  item.budgetHeadId,
                this.props.history
              );
            }
          }
          if (item.itemGroupId !== null && item.itemGroupId !== "") {
            const data = {
              companyId: localStorage.getItem("companyId"),
              // consumable: "",
              // indentTypeId: "",
              itemGroupId: item.itemGroupId,
              // saleable: "",
              // branchId:this.props.InwardFillDetails.branchId,
              //write condition fror update view
              // this.props.source === "WITHPO"
              //   ? item.itemGroupId
              //   : item.itemGroup,

              // tangible: "",
            };
            // this.props.getItemsListforIndent(
            //   serverApi.GET_ITEMSBY_FORPROCUREMENT,
            //   data,
            //   this.getitemListByitemgrpId,
            //   item.itemGroupId
            // );
            this.props.getItemListByItemGroupForInward(
              serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
              data,
              this.getitemListByitemgrpId,
              item.itemGroupId
            );
          }
        });
      }
    } else {
      let Inward_LineItems = [
        {
          lineItemId: 1,
          departmentId: "",
          departmentName: "",
          itemCode: "",
          itemId: "",
          itemGroupId: "",
          itemList: [],
          itemGroupList: [],
          rate: 0,
          budgetHeadId: "",
          inwardQty: 0,
          uomCode: "",
          description: "",
          poId: "",
          discountedRate: "",
          discountedAmount: "",
          remarks:"",
          make:"",
          poDetailsId: "",
          oldQty: 0,
          hdrStatus: 1,
          isActive: 1,
          tax: "",
          igstPercentage: 0,
          cgstPercentage: 0,
          sgstPercentage: 0,
          hsnCode: "",
          taxPercentage: "",
          amount: 0,
          discountMode: "",
          discountPercentage: "0.00",
          qty: 0,
        },
      ];
      this.props.InwardLineItemsProps(Inward_LineItems);
    }

    if (this.props.Upload_File_List) {
      if (this.props.locationState.state) {
        let id = this.props.locationState.state.rowData.id;
        this.props.getFilesofInward(
          serverApi.GET_FILES +
            18 +
            "/" +
            id +
            "/" +
            localStorage.getItem("companyId"),
          this.props.history,
          18,
          "inward"
        );
      }
    }
  }

  componentWillReceiveProps(props) {
    if (props.departmentList) {
      if (props.departmentList.data) {
        let list = props.departmentList.data.filter((data) => {
          if (data.value !== 0) {
            return data;
          }
        });
        this.setState({
          DepartmentList: list,
        });
      }
    }
    if (props.allItemGroupMaster_List) {
      if (props.allItemGroupMaster_List.data) {
        let list = props.allItemGroupMaster_List.data.filter((data) => {
          if (data.value !== "0") {
            return data;
          }
        });
        this.setState({
          AllItemGroupMasterList: list,
        });
      }
    }
    if (props.itemsListForIndent) {
      let filteredList = props.itemsListForIndent.filter((data) => {
        if (data.value !== "0") {
          return data;
        }
      });
      var list = [];
      filteredList.map((prop) => {
        list.push({
          label: prop.label,
          name: prop.name,
          value: prop.value,
          variant: prop.itemCode,
        });
      });
      this.setState({
        itemsList: list,
      });
    }
    if (props.taxList) {
      if (props.taxList.data) {
        if (props.taxList.data.length !== 0) {
          let taxList = props.taxList.data.filter((item) => {
            if (item.value !== 0 && item.value !== "0") {
              return item;
            }
          });

          this.setState({
            taxList: taxList,
          });
        }
      }
    }

    if (props.Inward_LineItems) {
      this.setState({
        Inward_LineItems: props.Inward_LineItems,
      });
    }
    if (props.parentBudgetHeadsList) {
      if (props.parentBudgetHeadsList.data) {
        let list = props.parentBudgetHeadsList.data.filter((data) => {
          if (data.value !== "0") {
            return data;
          }
        });
        this.setState({
          parentBudgetHeadsList: list, // updating for parent budget heads list
        });
      }
    }

    if (this.props.InwardFillDetails) {
      let taxType = this.props.InwardFillDetails.taxType;
      if (taxType === 1) {
        this.setState({
          igstPercentage: 1,
          cgstPercentage: 0,
          sgstPercentage: 0,
        });
      } else {
        this.setState({
          igstPercentage: 0,
          cgstPercentage: 1,
          sgstPercentage: 1,
        });
      }
    }
    if (props.logs_List) {
      this.setState({
        Logs: props.logs_List.data,
      });
    }

    if (this.state.seeLogs === false) {
      if (props.inwardfilesList) {
        if (props.inwardfilesList.data) {
          let filesList = props.inwardfilesList.data;
          this.setState({
            files_List: filesList.data,
          });
        }
      }
    }
    if (this.state.uploadFile === true) {
      if (this.state.fileName !== "") {
        if (props.Upload_File_List) {
          var Filedata = props.Upload_File_List.data;
          if (props.Upload_File_List.status) {
            this.setState({
              uploadFile: false,
            });
            if (this.state.files_List !== undefined) {
              var arr = this.state.files_List;
              Filedata = {
                fileUploadId: Filedata.id,
                fileName: this.state.fileName,
                fileExtension: this.state.fileType,
                retrievalPath: Filedata.fileUrl,
                sourceMenu: 18,
              };
              arr.splice(arr.length, 0, Filedata);
              this.setState({
                files_List: arr,
              });
            }
          }
        }
      }
    }
  }

  getFilesDownload = (fileUploadId, fileName, fileExtension) => {
    let url = serverVars.SECURITY_API + "files/downloadfile/" + fileUploadId;
    getFileDownload(url, fileName, fileExtension);
  };

  handleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    this.setState({ [key]: event });

    if (key === "qty") {
      const validated = value.match(/^(\d*\.{0,1}\d{0,3}$)/);
      // if (Number(value) >= 0) {
      if (validated) {
        let indentLineItemsObj = this.state.Inward_LineItems.filter(
          (rowItem) => {
            if (row.lineItemId === rowItem.lineItemId) {
              if (this.props.source === "WITHPO") {
                if (value <= Number(row.remainingQuantity)) {
                  row.inwardQty = value;
                  row.amount = Number(row.rate) * Number(row.inwardQty);
                } else {
                  swal(
                    "Quantity should not be less than 1 or more than General Quantity"
                  );
                  row.inwardQty = 0;
                }
              } else if (this.props.source === "WITHWO") {
                if (value <= Number(row.remainingQuantity)) {
                  row.inwardQty = value;
                  row.amount = Number(row.rate) * Number(row.inwardQty);
                } else {
                  swal("Quantity shouldn't be greater than Remaining Quantity");
                  row.inwardQty = row.remainingQuantity;
                }
              } else {
                row.inwardQty = value;
                row.amount = Number(row.rate) * Number(row.inwardQty);
              }
            }
            return rowItem;
          }
        );
        this.setState({
          Inward_LineItems: indentLineItemsObj,
        });
        this.props.InwardLineItemsProps(indentLineItemsObj);
      } else {
        swal("Can't Enter more than 3 Decimals!");
      }
      // }
    }
    if (key === "Rate") {
      const validated = value.match(/^(\d*\.{0,1}\d{0,3}$)/);
      if (validated) {
        row.rate = value;
      } else {
        swal("Can't Enter more than 3 Decimals!");
      }
      row.amount = Number(row.rate) * Number(row.inwardQty);
    }

    if (key === "discountPercentage") {
      if (value < 0) {
        swal("Discount value Shouldn't be less than 0");
        row.discountPercentage = 0;
      } else {
        if (!value.match(/^\d{1,2}\.\d{3}$/)) {
          // row.qty = Number(value);
          row.discountPercentage = value;
          if (row.discountMode === 1) {
            if (!value.match(/^\d{1,2}\.\d{3}$/)) {
              let totalAmt =
                // (Number(row.qty) -
                //   (Number(row.qtyPo) === undefined ? 0 : Number(row.qtyPo)))
                Number(row.inwardQty) * Number(row.rate);
              let discountAmt = totalAmt - Number(value);
              row.discountedAmount = Number(discountAmt);
              row.discountedRate = Number(discountAmt);
              let itemRate = Number(row.rate)
              let amount=Number(row.amount)
              if (value > amount) {
                swal("Discount amount can't be greater than amount");
                row.amount = totalAmt;
                row.discountPercentage = 0;
                this.setState({
                  totalAmount: totalAmt,
                });
              } else {
                row.amount = discountAmt;
                this.setState({
                  totalAmount: row.amount,
                });
                this.props.InwardFillDetails.totalAmount = row.amount;
              }
            } else {
              swal("Can't Enter more than 2 Decimals!");
            }
          } else {
            if (value > 100) {
              if (!value.match(/^\d{1,2}\.\d{3}$/)) {
                swal("Discount value Shouldn't be greater than 100");
                row.discountPercentage = 0;
                let totalAmt =
                  // (Number(row.qty) -
                  //   (Number(row.qtyPo) === undefined ? 0 : Number(row.qtyPo)))
                  Number(row.qty) * row.rate;
                row.amount = totalAmt;
              } else {
                swal("Can't Enter more than 2 Decimals!");
              }
            } else {
              if (value) {
                let totalAmt =
                  // (Number(row.qty) -
                  //   (Number(row.qtyPo) === undefined ? 0 : Number(row.qtyPo)))
                  Number(row.qty) * row.rate;
                let discountAmt = (totalAmt * Number(value)) / 100;
                totalAmt = totalAmt - discountAmt;
                row.amount = totalAmt;
                row.discountedAmount = totalAmt;
                this.setState({
                  totalAmount: row.amount,
                });
                this.props.InwardFillDetails.totalAmount = row.amount;
              } else {
                let totalAmt =
                  // (Number(row.qty) -
                  //   (Number(row.qtyPo) === undefined ? 0 : Number(row.qtyPo)))
                  Number(row.qty) * row.rate;
                row.amount = totalAmt;
              }
            }
          }
        } else {
          swal("Can't Enter more than 2 Decimals!");
        }
      }
    }
    if (key === "remarks") {
      row.remarks = value;
    }

    if (key === "make") {
      row.make = value;
    }
  };
  handleBlur = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    this.setState({ [key]: event });
    if (key === "qty") {
      if (/^[0-9]+$/.test(value)) {
        if (!value.match(/^\d{1,3}\.\d{4}$/)) {
          let indentLineItemsObj = this.state.Inward_LineItems.filter(
            (rowItem) => {
              if (row.lineItemId === rowItem.lineItemId) {
                if (this.props.source === "WITHPO") {
                  if (Number(value) <= Number(row.remainingQuantity)) {
                    row.inwardQty = value;
                    row.amount = Number(row.rate) * Number(row.inwardQty);
                  } else {
                    swal(
                      "Quantity should not be less than 1 or more than General Quantity"
                    );
                    row.inwardQty = 0;
                  }
                } else {
                  row.inwardQty = Number(value);
                  row.amount = Number(row.rate) * Number(row.inwardQty);
                }
              }
              return rowItem;
            }
          );
          this.setState({
            Inward_LineItems: indentLineItemsObj,
          });
          this.props.InwardLineItemsProps(indentLineItemsObj);
        } else {
          swal("Can't Enter more than 3 Decimals!");
        }
      }
    }
  };

  handleKeyPress = (event) => {
    if(["remarks","make"].includes(event.target.name)) return
    // Prevent 'e' and '-' characters
    if (event.key === "e" || event.key === "-") {
      event.preventDefault();
    }
  };

  onClickToSeeLogs = () => {
    this.setState({
      uploadFile: false,
      seeLogs: true,
    });
    if (this.props.locationState.state) {
      let id = this.props.locationState.state.rowData.id;
      this.props.logs(serverApi.SEE_THE_LOGSOF_INWARD + id, this.props.history);
    }
  };

  handleChangetextAreas = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "internalNote") {
      this.props.InwardFillDetails.internalNote = event;
      this.setState({
        internalNote: event,
      });
    }
  };
  handleChangedropdown = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    selectedItemCode
  ) => {
    this.setState({
      isDropdownOpen: false,
    });
    var newline = 1;
    var addnewline = true;
    var Object = this.state.Inward_LineItems.filter((row) => {
      if (selectedValue !== selectedRow.departmentId) {
        if (row.lineItemId === selectedRow.lineItemId) {
          if (name === "department") {
            row.departmentId = selectedValue;
            row.departmentName = selectedName;
            row.budgetHeadId="";
            selectedRow.itemGroupId = "";
            selectedRow.itemGroup = "";
            selectedRow.itemCode = "";
            selectedRow.itemId = "";
            selectedRow.itemName = "";
            selectedRow.hsnCode = "";
            selectedRow.rate = "";
            selectedRow.discountedRate = "";
            selectedRow.uomCode = "";
            selectedRow.description = "";
            selectedRow.tax = "";
            selectedRow.igstPercentage = 0;
            selectedRow.taxPercentage = "";
            selectedRow.cgstPercentage = 0;
            selectedRow.sgstPercentage = 0;
            selectedRow.inwardQty = 0;
            selectedRow.qty = 0;
            selectedRow.amount = 0;
            selectedRow.discountPercentage = "0.00";
            selectedRow.discountMode = "";
            this.props.getItemGroupListforindent(
              serverApi.GET_ITEMGROUP_LIST +
                selectedValue +
                "/" +
                localStorage.getItem("companyId") +
                "/getAllItemGroupsByDepartmentId/" +
                this.state.userDit.cipher,
              this.props.history,
              this.getitemListByitemgrpId,
              selectedValue
            );
          }
        }
        newline = Number(selectedRow.lineItemId);
        return row;
      } else {
        addnewline = false;
      }
    });
    this.setState({
      addlineItemId: newline,
    });
    if (name === "department") {
      let obj = {
        lineItemId: Number(newline) + 1,
        departmentId: "",
        departmentName: "",
        amount: 0,
        itemId: "",
        itemGroupId: "",
        itemList: [],
        itemGroupList: [],
        rate: 0,
        discountedRate: "",
        budgetHeadId: "",
        inwardQty: 0,
        uomCode: "",
        description: "",
        poId: "",
        poDetailsId: "",
        oldQty: 0,
        hdrStatus: 1,
        isActive: 1,
        tax: "",
        igstPercentage: 0,
        cgstPercentage: 0,
        sgstPercentage: 0,
        hsnCode: "",
        taxPercentage: "",
        discountMode: "",
        discountPercentage: "0.00",
        qty: 0,
        make:"",
        remarks:"",
      };
      if (addnewline) {
        Object.push(obj);
        this.setState({
          Inward_LineItems: Object,
          lineItemId: selectedRow.lineItemId,
        });
        this.props.InwardLineItemsProps(Object);
      }
    }
    if (name === "itemGroup") {
      let filtereditems = this.state.Inward_LineItems.filter(
        (item) => item.isActive !== 0
      );

      var checkitem = !!filtereditems.find(
        ({ itemGroupId }) => itemGroupId === selectedValue
      );
      if (checkitem) {
        selectedRow.itemGroupId = selectedValue;
        selectedRow.itemGroup = selectedValue;
        const data = {
          companyId: localStorage.getItem("companyId"),
                    itemGroupId: selectedValue,
          };
        
        this.props.getItemListByItemGroupForInward(
          serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
          data,
          this.getitemListByitemgrpId,
          selectedRow.itemGroupId
        );
      } else {
        // onselect of itemGroup other field will be refreshed..

        selectedRow.itemGroupId = selectedValue;
        selectedRow.itemGroup = selectedValue;
        const data = {
          companyId: localStorage.getItem("companyId"),
                    itemGroupId: selectedValue,
          };

        this.props.getItemListByItemGroupForInward(
          serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
          data,
          this.getitemListByitemgrpId,
          selectedValue
        );
        selectedRow.itemCode = "";
        selectedRow.itemId = "";
        selectedRow.itemName = "";
        selectedRow.hsnCode = "";
        selectedRow.rate = "";
        selectedRow.discountedRate = "";
        selectedRow.discountedAmount = "";
        selectedRow.uomCode = "";
        selectedRow.description = "";
        selectedRow.tax = "";
        selectedRow.igstPercentage = "";
        selectedRow.taxPercentage = "";
        selectedRow.cgstPercentage = "";
        selectedRow.sgstPercentage = "";
        selectedRow.inwardQty = "";
        selectedRow.qty = "";
        selectedRow.remarks = "";
        selectedRow.make = "";
        selectedRow.amount = 0;
        selectedRow.discountMode = "";
        selectedRow.discountPercentage = "0.00";
      }
    }
    if (name === "item") {
           var filteredproducts = this.state.Inward_LineItems.filter(
        (item) => item.isActive !== 0

      );
      // if (Number(selectedRow.itemId) !== Number(selectedValue)) {
      var check = !!filteredproducts.find(
        ({ itemId }) => Number(itemId) === Number(selectedValue)
      );
      
      // }
      if (check) {
        swal("You can't create the record with the duplicate item");
      } else {
        if (check !== undefined) {
          let value = selectedName.split("^");
             // let rate = 0;
          // if (value[3]) {
          //   rate = Number(value[3]);
          // }
          // let HSN = value[4];
          // let price = rate;
          // let uom = value[1];


          // selectedRow.hsnCode = HSN;
          // selectedRow.rate = Number(price);
          // selectedRow.uomCode = uom;
          selectedRow.itemCode = selectedItemCode;
          selectedRow.itemId = selectedValue;
          selectedRow.itemName = value[0];
       
          selectedRow.description = value[0];

          selectedRow.tax = "";
          selectedRow.igstPercentage = 0;
          selectedRow.taxPercentage = 0;
          selectedRow.cgstPercentage = 0;
          selectedRow.sgstPercentage = 0;
          selectedRow.inwardQty =
            Number(selectedRow.itemGroupId) === 997 ? 1 : "";
          selectedRow.qty = "";
          selectedRow.amount = 0;
          selectedRow.discountPercentage = "0.00";
          selectedRow.discountMode = "";
          selectedRow.make = "";
          selectedRow.remarks = "";
        }
      }
      const data = {
        itemGroupId: selectedRow.itemGroupId,
        companyId: localStorage.getItem("companyId"),
        itemId:selectedValue,
        // branchId: this.props.Inward_LineItems.shippingAddress_Value,
          branchId:this.props.InwardFillDetails.branchId,

    }
        this.props.getItemDetailsByItemItemGrpForInward(serverApi.GET_ITEMDETAILS_BYITEM_ITEMGRP_COMPANYID,data,this.getItemDetails,selectedValue)
    }
    if (name === "BudgetHead") {
      let filtereditems = this.state.Inward_LineItems.filter(
        (item) => item.isActive !== 0
      );

      let checkitem = !!filtereditems.find(
        ({ budgetHeadId }) => budgetHeadId === selectedValue
      );

      if (checkitem) {
        selectedRow.budgetHeadId = selectedValue;
        selectedRow.budgetHeadName = selectedName;
        this.props.allItemGroupMasterList(
          serverApi.GET_ITEMGROUPS_BYBUDGET_HEADS +
            "/" +
            localStorage.getItem("companyId") +
            "/budgetHead/" +
            selectedValue,
          this.props.history
        );
      } else {
        selectedRow.budgetHeadId = selectedValue;
        selectedRow.budgetHeadName = selectedName;

      }
    }
    if (name === "Tax") {
      selectedRow.tax = selectedValue;
      let taxPercentage = selectedName.split("^");
      selectedRow.taxPercentage = taxPercentage[1];
      if (this.props.InwardFillDetails.taxType === 1) {
        selectedRow.igstPercentage = Number(taxPercentage[1]);
      } else {
        selectedRow.cgstPercentage = Number(taxPercentage[1] / 2);
        selectedRow.sgstPercentage = Number(taxPercentage[1] / 2);
      }
    }

    if (name === "discountMode") {
      selectedRow.discountMode = selectedValue;
      selectedRow.discountPercentage = "";
    }
  };

 
  onDelLineItem = (row) => {
    var lastRow =
      this.state.Inward_LineItems[this.state.Inward_LineItems.length - 1];

    if (lastRow === row && this.props.source !== "WITHPO") {
      swal("you can't delete the Empty Line Item");
    } else { 
        var indexitem = this.state.Inward_LineItems.findIndex((item) => item == row);
        var lineItems = this.state.Inward_LineItems;
        lineItems[indexitem].isActive = 0;
      this.setState({
        Inward_LineItems: lineItems,
      });
      this.props.InwardLineItemsProps(lineItems);
    }
  };

  onDel = (event) => {};

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "termsNconditions") {
      this.props.InwardFillDetails.termsNconditions = selectedName;
      this.props.InwardFillDetails.selectTermsNconditions = selectedName;
      this.setState({
        termsNconditions: selectedName,
        selectTermsNconditions: selectedName,
      });
    }
  };

  onhandleChange = (key) => (value) => {
    this.setState({ [key]: value });
    if (key === "advance_per") {
      this.props.InwardFillDetails.AdvancePercentage = value;
      this.setState({
        AdvancePercentage: value,
      });
    }
    if (key === "advance_amt") {
      this.props.InwardFillDetails.AdvanceAmount = value;
      this.setState({
        AdvanceAmount: value,
      });
    }
  };
  UploadDocuments = (key) => (e) => {
    if (this.props.locationState.state) {
      var inwardId = this.props.locationState.state.rowData.id;
    }
    e.preventDefault();
    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 18);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", inwardId);
    formData.append("createdBy", this.state.userDit.userId);
    formData.append("fileCompanyId", localStorage.getItem("companyId"));
    this.setState({
      fileName: file.name,
      fileType: fileExt,
      uploadFile: true,
    });
    this.props.postUploadFileList(
      serverApi.UPLOADFILE,
      formData,
      this.props.history
    );
  };

  onDeleteFile = (fileId) => {
    let data = this.state.files_List.filter(
      (item) => item.fileUploadId !== fileId
    );
    this.props.deleteFile(serverApi.DELETEFILE + fileId);
    this.setState({
      files_List: data,
    });
    this.props.getFilesofInwardSuccessfull({ data });

    this.props.filesofInvoice = data;
  };

  ///to add other charges
  addOtherCharges = () => {
    this.props.getItemGroupListforindent(
      serverApi.GET_ITEMGROUP_LIST +
        "29" +
        "/" +
        localStorage.getItem("companyId") +
        "/getAllItemGroupsByDepartmentId/" +
        this.state.userDit.cipher,
      this.props.history,
      this.getitemListByitemgrpId,
      "29"
    );
    const data = {
      itemGroupId: "997",
            companyId: localStorage.getItem("companyId"),
            branchId:this.props.InwardFillDetails.branchId,
    };

      this.props.getItemListByItemGroupForInward(
      serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
      data,
      this.getitemListByitemgrpId,
      "997"
    );
    this.setState({
      othercharges: true,
    });

    let newline = 1;
    let budgetHeadId = "";
    let departmentId = "";
    let departmentName = "";
    let filteredproducts = this.state.Inward_LineItems.map((item) => {
      // newline = Number(item.lineitemId);
      if (item.budgetHeadId !== "") {
        budgetHeadId = item.budgetHeadId;
        departmentId = item.departmentId;
        departmentName = item.departmentName;
      }
      return item;
    });
    let obj = {
      lineitemId: this.state.Inward_LineItems.length + 1,
      departmentId: 29,
      departmentName: departmentName,
      amount: 0,
      itemId: "",
      itemGroup: "997",
      itemGroupId: "997",
      itemGroupName: "Other Charges",
      itemName: "",
      itemCode: "",
      taxPercentage: 0,
      taxAmount: 0,
      itemList: [],
        itemGroupList: [],
      rate: 0,
      discountedRate:"",
      discountedAmount:"",
      discountMode: "",
      discountPercentage: 0,
      discount:0,
      budgetHeadId: budgetHeadId,
      inwardQty: 1,
      uomCode: "",
      description: "",
      poId: "",
      poDetailsId: "",
      oldQty: 0,
      hdrStatus: 1,
      isActive: 1,
      tax: "",
      igstPercentage: 0,
      cgstPercentage: 0,
      sgstPercentage: 0,
      hsnCode: "",
      others: true,
      qty: 1,
      make:"",
      remarks:"",
    };

    if (this.props.locationState.state !== undefined) {
      // filteredproducts.push(obj);
      if (this.props.source !== "WITHOUTPO") {
        // new code
        filteredproducts.push(obj);
      } else {
        filteredproducts.splice(filteredproducts.length - 1, 0, obj);
      }
    } else {
      if (this.props.source === "WITHTPO" || this.props.source === "WITHWO") {
        // new Code
        if (filteredproducts.length === 1) {
          filteredproducts.splice(1, 0, obj);
        } else {
          filteredproducts.splice(filteredproducts.length, 0, obj);
        }
      } else {
        // new code
        if (filteredproducts.length === 1) {
          filteredproducts.splice(1, 0, obj);
        } else {
          filteredproducts.splice(filteredproducts.length - 1, 0, obj);
        }
      }
    }
    this.setState({
      poDetails: filteredproducts,
    });
    this.props.InwardLineItemsProps(filteredproducts);
  };

  getAmount = (qty, rate, discountMode, discountPercentage) => {
    let amount = 0;
    if (discountMode) {
      if (discountMode === 1) {
        amount =
          // (Number(qty) - (qtyPo === undefined ? 0 : Number(qtyPo)))
          Number(qty) * Number(rate);
        if (
          Number(discountPercentage) !== 0 &&
          Number(discountPercentage) !== undefined &&
          Number(discountPercentage) !== "" &&
          Number(discountPercentage) !== null
        ) {
          amount = amount - Number(discountPercentage);
        }
        // return amount;
        return parseFloat(amount).toFixed(2);
      } else {
        amount =
          // (Number(qty) - (qtyPo === undefined ? 0 : Number(qtyPo)))
          Number(qty) * Number(rate);
        if (
          discountPercentage !== 0 &&
          discountPercentage !== undefined &&
          discountPercentage !== ""
        ) {
          let disAmount = (amount * Number(discountPercentage)) / 100;
          amount = amount - disAmount;
        }
        // return amount;
        return parseFloat(amount).toFixed(2);
      }
    } else {
      amount =
        // (Number(qty) - (qtyPo === undefined ? 0 : Number(qtyPo)))
        Number(qty) * Number(rate);
      return parseFloat(amount).toFixed(2);
      // return amount;
    }
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  getitemListByitemgrpId = (response, itemGrpId) => {
        if (itemGrpId !== undefined) {
      if (response) {
        let Inward_LineItems = this.state.Inward_LineItems.filter((row) => {
          if (row.itemGroup == itemGrpId || row.itemGroupId == itemGrpId) {
            let list = [];
            response.data.map((prop) => {
              list.push({
                label: prop.itemDesc+ `(${prop.itemCode})`,
                name: prop.itemDesc+ `(${prop.itemCode})`,
                value: prop.itemId,
                variant: prop.itemCode,
              });
            });
            let itemsList = list.filter((item) => {
              if (item.value == row.itemId) {
                let value = item.name.split("^");
                row.min = value[8];
                row.max = value[6];
                row.tangible = value[16];
              }
              if (item.value !== 0 && item.value !== "0") {
                return item;
              }
            });
            row.itemList = itemsList;
          } else if (row.departmentId == itemGrpId) {
            let itemGRPList = [];
            response.data.map((prop) => {
              itemGRPList.push({
                label: prop.label,
                name: prop.name,
                value: prop.value,
              });
            });
            let itemsgroupList = itemGRPList.filter((item) => {
              if (item.value !== 0 && item.value !== "0") {
                return item;
              }
            });
            row.itemGroupList = itemsgroupList;
          }
          return row;
        });
        this.setState({
          Inward_LineItems: Inward_LineItems,
        });
        this.props.InwardLineItemsProps(Inward_LineItems);
      }
      // return this.state.itemsList
    }
  };

  getItemDetails = (response, itemId)=>{
    if (itemId !== undefined) {
      if (response) {
        let Inward_LineItems = this.state.Inward_LineItems.filter((row) => {
          if (row.itemId === itemId ) {
            let itemDetails = response.data
              if (itemId === row.itemId) {
                row.min = itemDetails.minQty
                row.max = itemDetails.maxQty
                row.maxAllowedQty = itemDetails.maxAllowableQty
                row.minAllowedQty = itemDetails.minAllowableQty
                row.reOrderQty = itemDetails.reorderQty
                row.uomCode = itemDetails.uomCode
                row.rate = itemDetails.srRate
              }
          }
          return row;
        });
        this.setState({
          Inward_LineItems: Inward_LineItems,
        });
        this.props.InwardLineItemsProps(Inward_LineItems);
      }
      // return this.state.itemsList
    }
  }

  FieldDisable = (row, col) => {
    if (
      this.props.source === "WITHOUTPO" &&
      this.props.locationState.state === undefined &&
      row.itemGroupId === "997" &&
      (col.type === "department" || col.type === "BudgetHead" || col.type === "itemGroup")
    ) {
      return true;
    } else if (
      this.props.source === "WITHOUTWO" &&
      (row.inwardSequenceNo === "" ||
        row.inwardSequenceNo === undefined ||
        row.inwardSequenceNo === null) &&
      (col.type === "BudgetHead" || col.type === "department") &&
      row.others === true
    ) {
      return false;
    } else if (this.props.locationState.state === undefined) {
      return false;
    } else if (
      (this.props.locationState.state !== undefined && 
      this.props.source === "WITHOUTPO" && 
      isEmpty(row.inwardSequenceNo)) ||
      (col.type === "Tax" || col.type === "discountMode")
    ) {
      return false;
    } else {
      return true;
    }
  };
  

  returnInputTextField = (row, col) => {
    return (
      <Tooltip title={col.type === "item" ? row.itemName : ""}>
        <div className="quantityDropdownBlock">
          <Grid className="selectAndTextfield">
            <Grid
              xs={12}
              md={12}
              className="dynamicSelectBlock"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="text"
                name={col.type}
                id={col.no}
                // disabled
                readOnly
                value={
                  col.type === "item"
                    ? row.itemName
                    : col.type === "Unit"
                    ? row.uom
                      ? row.uom
                      : row.uomCode
                    : col.type === "department"
                    ? row.departmentName
                    : col.type === "itemGroup"
                    ? row.itemGroup
                    : ""
                }
                onChange={this.handleChange({
                  key: col.type,
                  row,
                })}
                onWheel={(e) => e.target.blur()}
                className="inputBlockContainer"
                style={{
                  width: col.type === "item" ? "300px" : "",
                }}

                // onClick={this.props.tooltip}
              />
            </Grid>
          </Grid>
        </div>
      </Tooltip>
    );
  };

  render() {
    var filteredproducts = this.state.Inward_LineItems.filter(
      (item) => item.isActive !== 0
    );

    let DepartmentList = this.state.DepartmentList;
    let AllItemGroupMasterList = this.state.AllItemGroupMasterList;
    var totalAmount = 0;
    var totalIgst = 0;
    var totalCgst = 0;
    var totalSgst = 0;
    var IndividualAmount = 0;
    var totalIgstValue = 0;
    if (this.state.Inward_LineItems) {
      const filteredItems = this.state.Inward_LineItems.filter((item) => {
        if (
          this.props.source === "WITHOUTPO" ||
          this.props.source === "WITHOUTWO" ||
          Number(item.itemGroupId) === 997
        ) {
          if (item.isActive !== 0) {
            return item;
          }
        } else {
          if (item.departmentId !== "" && item.isActive !== 0) {
            return item;
          }
        }
      });

      filteredItems.forEach((data) => {
        if (data.inwardQty && data.rate) {
          totalAmount = totalAmount + data.inwardQty * data.rate;

          if (Number(data.discountMode !== null) && data.discountMode !== "") {
            let amount = Number(data.inwardQty) * Number(data.rate);
            if (Number(data.discountMode) === 1) {
              if (Number(data.discountPercentage) !== 0 &&
              Number(data.discountPercentage) !== undefined &&
              Number(data.discountPercentage) !== "" &&
              Number(data.discountPercentage) !== null
              ){
                IndividualAmount =
                  amount -
                    Number(data.discountPercentage)
                  ;
                totalAmount =
                  totalAmount -
                  Number( data.discountPercentage);
              }
            } else if (data.discountMode === 2) {
              if (Number(data.discountPercentage) !== 0 &&
               Number(data.discountPercentage)  !== undefined &&
                Number(data.discountPercentage) !== "" &&
                Number(data.discountPercentage) !== null
              ) {
                let disAmount =
                  (amount *
                    Number(
                       data.discountPercentage
                    )) /
                  100;
                IndividualAmount = amount - disAmount;
                totalAmount = totalAmount - disAmount;
              }
            }
          }
        }

        if (Number(this.props.InwardFillDetails.taxType) === 3) {
          totalIgst = 0;
          totalCgst = 0;
          totalSgst = 0;
        } else {
          if (this.props.InwardFillDetails.taxType === 1) {
            let amountWithDiscount = 0;
            if (
              data.discountMode !== undefined &&
              data.discountMode !== "" &&
              data.discountMode !== null
            ) {
              if (data.discountMode === 1) {
                amountWithDiscount =
                  data.inwardQty * data.rate - Number(data.discountPercentage);
                totalIgst =
                  totalIgst + (amountWithDiscount * data.taxPercentage) / 100;
              } else if (data.discountMode === 2) {
                amountWithDiscount =
                  (data.inwardQty *
                    data.rate *
                    Number(data.discountPercentage)) /
                  100;
                amountWithDiscount =
                  data.inwardQty * data.rate - amountWithDiscount;
                totalIgst =
                  totalIgst + (amountWithDiscount * data.taxPercentage) / 100;
              } else {
                amountWithDiscount =
                  data.inwardQty * data.rate - amountWithDiscount;
                totalIgst =
                  totalIgst + (amountWithDiscount * data.taxPercentage) / 100;
              }
            } else {
              totalIgst =
                totalIgst +
                (data.inwardQty * data.rate * Number(data.taxPercentage)) / 100;
            }
          } else {
            let amountWithDiscount = 0;
            if (
              data.discountMode !== undefined &&
              data.discountMode !== "" &&
              data.discountMode !== null
            ) {
              if (data.discountMode === 1) {
                amountWithDiscount =
                  data.inwardQty * data.rate - Number(data.discountPercentage);
                totalCgst =
                  totalCgst +
                  (amountWithDiscount * data.taxPercentage) / 100 / 2;
                totalSgst =
                  totalSgst +
                  (amountWithDiscount * data.taxPercentage) / 100 / 2;
              } else if (data.discountMode === 2) {
                amountWithDiscount =
                  (data.inwardQty *
                    data.rate *
                    Number(data.discountPercentage)) /
                  100;
                amountWithDiscount =
                  data.inwardQty * data.rate - amountWithDiscount;
                totalCgst =
                  totalCgst +
                  (amountWithDiscount * data.taxPercentage) / 100 / 2;
                totalSgst =
                  totalSgst +
                  (amountWithDiscount * data.taxPercentage) / 100 / 2;
              } else {
                amountWithDiscount =
                  data.inwardQty * data.rate - amountWithDiscount;
                totalCgst =
                  totalCgst +
                  (amountWithDiscount * data.taxPercentage) / 100 / 2;
                totalSgst =
                  totalSgst +
                  (amountWithDiscount * data.taxPercentage) / 100 / 2;
              }
            } else {
              amountWithDiscount = data.inwardQty * data.rate;
              totalCgst =
                totalCgst + (amountWithDiscount * data.taxPercentage) / 100 / 2;
              totalSgst =
                totalSgst + (amountWithDiscount * data.taxPercentage) / 100 / 2;
            }
          }
        }
      });
    }

    var netTotal = totalAmount + totalCgst + totalIgst + totalSgst;
    this.props.InwardFillDetails.totalAmount = totalAmount;
    this.props.InwardFillDetails.totalIgst = totalIgst;
    this.props.InwardFillDetails.totalCgst = totalCgst;
    this.props.InwardFillDetails.totalSgst = totalSgst;
    this.props.InwardFillDetails.netTotal = netTotal;

    let RowData1 = RowData;
    let HeadData = tableHeaders;
    if (this.props.source === "WITHPO") {
      RowData1 = RowData1.filter((row) => {
        return row.no !== 7 && row.no !== 9 && row.no !== 10 && row.no !== 8;
      });
      HeadData = tableHeaders.filter(
        (column) =>
          column.header !== "Item Rate" &&
          column.header !== "Discount Mode" &&
          column.header !== "Discount/Amount" &&
          column.header !== "Tax"

      );
    } else {
      RowData1 = RowData;
      HeadData = tableHeaders;
    }
    return (
      // work report on create time
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent" onScroll={this.handleScroll}>
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {HeadData.map((column) => (
                    <>
                      {column.header === "Quantity" ||
                      column.header === "Budget Head" ||
                      column.header === "Department" ||
                      column.header === "Item" ||
                      column.header === "Item Group" ? (
                        <th>
                          {column.header}
                          {(this.props.source === "WITHPO" ||
                            this.props.source === "WITHWO") &&
                          column.header !== "Quantity" ? (
                            ""
                          ) : (
                            <span style={{ color: "red" }}>*</span>
                          )}
                        </th>
                      ) : (
                        <th>{column.header}</th>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredproducts.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <>
                      {RowData1 &&
                        RowData1.map((col) =>
                          col.component === "EditableCell" ? (
                            <>
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        type={
                                          col.type === "discountPercentage" &&
                                          col.type === "remarks" &&
                                          col.type === "make" 
                                            ? "number"
                                            : "text"
                                        }
                                        name={col.type}
                                        id={col.no}
                                        value={
                                          col.type === "qty"
                                            ? row.itemGroup === "997"
                                              ? "NA"
                                              : row.inwardQty
                                              ? row.inwardQty
                                              : ""
                                            : col.type === "discountPercentage"
                                            ? row.discountMode === null ||
                                              row.discountMode === ""
                                              ? 0
                                              : row.discountPercentage
                                            : col.type === "Unit"
                                            ? row.uom
                                              ? row.uom
                                              : row.uomCode
                                            : col.type === "Rate"
                                            ? row.rate
                                            : col.type === "remarks"
                                            ? row.remarks
                                            : col.type === "make"
                                            ? row.make
                                            : col.type === "amount"
                                            ? row.inwardQty
                                              ? Number(
                                                  this.getAmount(
                                                    Number(row.inwardQty),
                                                    row.rate,
                                                    Number(row.discountMode),
                                                   Number(row.discountPercentage)
                                                  )
                                                ).toFixed(2)
                                              : parseFloat(row.amount).toFixed(
                                                  2
                                                )
                                            : ""
                                        }
                                        onChange={this.handleChange({
                                          key: col.type,
                                          row,
                                        })}
                                        onBlur={
                                          col.type === "qty"
                                            ? () => {
                                                row.itemGroup !== "997" &&
                                                  this.handleBlur({
                                                    key: col.type,
                                                    row,
                                                  });
                                              }
                                            : () => {}
                                        }
                                        onKeyPress={this.handleKeyPress}
                                        className="inputBlockContainer"
                                        // onClick={this.props.tooltip}
                                        style={{
                                          textAlign:
                                            col.type !== "remarks" &&
                                            col.type !== "make" &&
                                            col.type !== "Unit" 
                                              ? "right"
                                              : "",
                                          paddingRight: "8px",
                                        }}
                                        readOnly={
                                          col.type === "Unit"
                                            ? true
                                            : col.type === "qty"
                                            ? row.itemGroup === "997"
                                              ? true
                                              : false
                                            : false
                                        }
                                        onWheel={(e) => e.target.blur()}
                                        disabled={
                                          this.props.source === "WITHPO"
                                            ? col.type === "Rate" ||
                                              col.type === "amount" ||
                                              col.type === "discountPercentage"
                                              ? true
                                              : false
                                            : col.type === "discountPercentage"
                                            ? row.discountMode === 0 ||
                                              row.discountMode === "" ||
                                              row.discountMode === undefined
                                              ? true
                                              : false
                                            : false
                                        }
                                      />
                                      <span>
                                        {col.type === "discountPercentage"
                                          ? Number(row.discountMode) === 2
                                            ? "%"
                                            : ""
                                          : ""}
                                      </span>
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            </>
                          ) : col.component === "DynamicSelectNew" ? (
                            <td>
                              {(this.props.locationState.state !== undefined ||
                                this.props.locationState.state === undefined) &&
                              (this.props.source === "WITHPO" ||
                                this.props.source === "WITHWO") ? (
                                <>{this.returnInputTextField(row, col)}</>
                              ) : (
                                <Tooltip
                                  title={
                                    col.type === "item" ? row.itemName : ""
                                  }
                                >
                                  <div className="quantityDropdownBlock">
                                    <Grid className="selectAndTextfield">
                                      <Grid
                                        xs={12}
                                        md={12}
                                        className="dynamicSelectBlock"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <DynamicSelect
                                          arrayOfData={
                                            col.type === "item"
                                              ? row.itemList
                                                ? row.itemList
                                                : this.state.itemsList
                                              : []
                                          }
                                          className="dropdownBlockContainer"
                                          onSelectChange={
                                            this.handleChangedropdown
                                          }
                                          handleMenuOpen={this.handleMenuOpen}
                                          isDropdownOpen={
                                            this.state.isDropdownOpen
                                          }
                                          selected={
                                            col.type === "item"
                                              ? row.itemId
                                              : ""
                                          }
                                          name={col.type}
                                          row={row}
                                          update={row.itemId ? 1 : 0}
                                          isDisabled={this.FieldDisable(
                                            row,
                                            col
                                          )}
                                        />
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Tooltip>
                              )}
                            </td>
                          ) : (
                            <td>
                              {(this.props.locationState.state !== undefined ||
                                this.props.locationState.state === undefined) &&
                              (this.props.source === "WITHPO" ||
                                this.props.source === "WITHWO") ? (
                                <Tooltip
                                  title={
                                    col.type === "department"
                                      ? row.departmentName
                                      : col.type === "itemGroup"
                                      ? this.props.locationState.state ===
                                          undefined &&
                                        (this.props.source === "WITHPO" ||
                                          this.props.source === "WITHWO")
                                        ? row.itemGroup
                                        : row.itemGroupName
                                      : col.type === "Tax"
                                      ? row.taxPercentage
                                      : col.type === "BudgetHead"
                                      ? row.budgetHeadName
                                      : ""
                                  }
                                >
                                  <div className="quantityDropdownBlock">
                                    <Grid className="selectAndTextfield">
                                      <Grid
                                        xs={12}
                                        md={12}
                                        className="dynamicSelectBlock"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          name={col.type}
                                          id={col.no}
                                          disabled
                                          value={
                                            col.type === "department"
                                              ? row.departmentName
                                              : col.type === "itemGroup"
                                              ? this.props.locationState
                                                  .state === undefined &&
                                                (this.props.source ===
                                                  "WITHPO" ||
                                                  this.props.source ===
                                                    "WITHWO")
                                                ? row.itemGroup
                                                : row.itemGroupName
                                              : col.type === "Tax"
                                              ? this.props.InwardFillDetails
                                                  .taxType === 1
                                                ? row.taxPercentage
                                                : row.taxPercentage / 2
                                              : col.type === "BudgetHead"
                                              ? row.budgetHeadName
                                              : col.type === "discountMode"
                                              ? row.discountMode === 1
                                                ? "Amount"
                                                : row.discountMode === 2
                                                ? "Percentage"
                                                : row.discountMode === null
                                                ? ""
                                                : ""
                                              : col.type ===
                                                "discountPercentage"
                                              ? row.discountPercentage
                                              : 0
                                          }
                                          onChange={this.handleChange({
                                            key: col.type,
                                            row,
                                          })}
                                          className="inputBlockContainer"
                                          style={{
                                            textAlign:
                                              col.type === "Tax" ? "right" : "",
                                            paddingRight: "8px",
                                            width:
                                              col.type === "item" ||
                                              col.type === "itemGroup"
                                                ? "230px"
                                                : "",
                                          }}
                                          onWheel={(e) => e.target.blur()}
                                        />
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title={
                                    col.type === "department"
                                      ? row.departmentName
                                      : col.type === "itemGroup"
                                      ? row.itemGroup
                                      : col.type === "BudgetHead"
                                      ? row.budgetHeadName
                                      : ""
                                  }
                                >
                                  <div className="quantityDropdownBlock">
                                    <Grid className="selectAndTextfield">
                                      <Grid
                                        xs={12}
                                        md={12}
                                        className="dynamicSelectBlock"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <DynamicSelect
                                          arrayOfData={
                                            col.type === "discountMode"
                                              ? discountModeList
                                              : col.type === "department"
                                              ? DepartmentList
                                              : col.type === "BudgetHead"
                                              ? row.departmentId !== ""
                                                ? this.state
                                                    .parentBudgetHeadsList
                                                : []
                                              : col.type === "itemGroup"
                                              ? row.departmentId !== ""
                                                ?  row.itemGroupList
                                                : []
                                              : col.type === "Tax"
                                              ? this.state.taxList
                                              : []
                                          }
                                          className="dropdownBlockContainer"
                                          onSelectChange={
                                            this.handleChangedropdown
                                          }
                                          handleMenuOpen={this.handleMenuOpen}
                                          isDropdownOpen={
                                            this.state.isDropdownOpen
                                          }
                                          selected={
                                            col.type === "discountMode"
                                              ? row.discountMode
                                              : col.type === "department"
                                              ? row.departmentId
                                              : col.type === "itemGroup"
                                              ? this.props.source === "WITHPO"
                                                ? row.itemGroup
                                                : row.itemGroupId
                                              : // :""
                                              col.type === "Tax"
                                              ? row.tax
                                                ? row.tax
                                                : row.taxPercentage
                                              : col.type === "BudgetHead"
                                              ? row.budgetHeadId
                                              : ""
                                          }
                                          name={col.type}
                                          row={row}
                                          update={
                                            row.itemGroup ||
                                            row.departmentId ||
                                            row.budgetHeadId ||
                                            row.tax
                                              ? 1
                                              : 0
                                          }
                                          isDisabled={this.FieldDisable(
                                            row,
                                            col
                                          )}
                                        />
                                         
                                      </Grid>
                                     
                                    </Grid>
                                  </div>
                                </Tooltip>
                              )}
                            </td>
                          )
                        )}
                    </>
                    <td className="">
                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title="Delete"
                      >
                        <IconButton
                          onClick={() => {
                            this.onDelLineItem(row);
                          }}
                          disabled={
                            filteredproducts.length === 1 ? true : false
                          }
                        >
                          <DeleteIcon className="deleteDisplayButton" />
                        </IconButton>
                      </Tooltip>
                      {this.state.delete && (
                        <CommonPopup>
                          <div className="delAddItem">
                            <div>Are you sure you want to delete?</div>
                            <div className="delAddItemBtns">
                              <input
                                type="button"
                                onClick={this.onDel}
                                value="Yes"
                                name={row ? row.lineItemId : ""}
                                className="delYesBtn"
                              />
                              <input
                                type="button"
                                onClick={this.onDel}
                                value="No"
                                name={row ? row.lineItemId : ""}
                                className="delNoBtn"
                              />
                            </div>
                          </div>
                        </CommonPopup>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {(this.props.source === "WITHOUTPO" ||
            this.props.source === "WITHOUTWO") && (
            <div style={{ marginTop: "5px" }}>
              <span
                className="OtherCharges"
                onClick={() => {
                  this.addOtherCharges();
                }}
              >
                Click Here To Add Printing Charges/Labour Charges/Transport
                Charges/Delivery Charges/Packing & Forwarding Charges/Other
                Charges
              </span>
            </div>
          )}

          <div className="consoleFormContainer">
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    justifyContent={"space-between"}
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 9">
                      <Box
                        display="grid"
                        justifyContent={"space-between"}
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={2}
                      >
                        <Box gridColumn="span 4">
                          <div className="consoleTextFieldBlock">
                            <TextFieldNormal
                              type="textarea"
                              label="Internal Note"
                              value={this.state.internalNote}
                              onChange={this.handleChangetextAreas(
                                "internalNote"
                              )}
                            />
                          </div>
                        </Box>
                      </Box>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TableContainer className="amountTable">
                          <Table aria-label="simple table">
                            <TableBody>
                              {this.props.source !== "WITHPO" ? (
                                <TableRow>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="noBorder"
                                  >
                                    Total IGST:
                                  </TableCell>
                                  <TableCell align="right" className="noBorder">
                                    <strong>
                                      {parseFloat(totalIgst).toFixed(2)}
                                    </strong>
                                  </TableCell>
                                </TableRow>
                              ) : (
                                ""
                              )}
                              {this.props.source !== "WITHPO" ? (
                                <TableRow>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="noBorder"
                                  >
                                    Total SGST:
                                  </TableCell>
                                  <TableCell align="right" className="noBorder">
                                    <strong>
                                      {" "}
                                      {parseFloat(totalSgst).toFixed(2)}
                                    </strong>
                                  </TableCell>
                                </TableRow>
                              ) : (
                                ""
                              )}

                              {this.props.source !== "WITHPO" ? (
                                <TableRow>
                                  <TableCell
                                    component="td"
                                    scope="row"
                                    className="noBorder"
                                  >
                                    Total CGST:
                                  </TableCell>
                                  <TableCell align="right" className="noBorder">
                                    <strong>
                                      {parseFloat(totalCgst).toFixed(2)}
                                    </strong>
                                  </TableCell>
                                </TableRow>
                              ) : (
                                ""
                              )}
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="noBorder"
                                >
                                  {" "}
                                  Net Total:
                                </TableCell>
                                <TableCell align="right" className="noBorder">
                                  <strong>
                                    {" "}
                                    {parseFloat(totalAmount).toFixed(2)}
                                  </strong>
                                </TableCell>
                              </TableRow>
                              {this.props.source !== "WITHPO" ? 
                              (
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="noBorder"
                                >
                                  Total Amount:
                                </TableCell>
                                <TableCell align="right" className="noBorder">
                                  <strong>
                                    {parseFloat(Math.round(netTotal)).toFixed(
                                      2
                                    )}
                                  </strong>
                                </TableCell>
                              </TableRow> ) : ""}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
          {this.props.locationState.state && (
            <div className="consoleFormContainer">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                    className="ducumentUploadBlock uploadedContent"
                  >
                    <Box gridColumn="span 12">
                      <div className="consoleFormBlock filesUploadedBlock">
                        <Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={2}
                        >
                          <Box gridColumn="span 4">
                            <label>Support Documents:</label>
                            <div className="personalDetailsUpload">
                              <div className="ducumentUploadBlock">
                                <div className="documentUploadContent">
                                  <span className="btn_upload documentUpload">
                                    <img src={panUploadIcon} alt="" />
                                    <input
                                      type="file"
                                      id="imag"
                                      title=""
                                      className="input-img"
                                      onChange={this.UploadDocuments()}
                                    />
                                    Upload Document
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Box>
                          {this.state.files_List &&
                            this.state.files_List.map((item) => (
                              <Box
                                gridColumn="span 3"
                                style={{ marginTop: "28px" }}
                              >
                                <div className="documentUploadContent">
                                  {/* {item.fileName} */}
                                  <div
                                    className="uploadedInfo"
                                    style={{ display: "flex" }}
                                  >
                                    <img
                                      src={TickIcon}
                                      alt=""
                                      className="tickIcon"
                                      height={"20px"}
                                      width={"20px"}
                                    />
                                    <Tooltip
                                      TransitionComponent={Fade}
                                      TransitionProps={{ timeout: 600 }}
                                      title="Click to download File"
                                    >
                                      <div
                                        className="uplodedFileName"
                                        onClick={() => {
                                          this.getFilesDownload(
                                            item.fileUploadId,
                                            item.fileName,
                                            item.fileExtension
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.fileName}
                                      </div>
                                    </Tooltip>
                                    <Tooltip
                                      TransitionComponent={Fade}
                                      TransitionProps={{ timeout: 100 }}
                                      title="Click to Delete File"
                                    >
                                      <Button
                                        onClick={() => {
                                          this.onDeleteFile(item.fileUploadId);
                                        }}
                                        className="deleteButton"
                                      >
                                        <img
                                          src={DeleteIcons}
                                          alt=""
                                          height={"20px"}
                                          width={"20px"}
                                        />
                                      </Button>
                                    </Tooltip>
                                  </div>
                                </div>
                              </Box>
                            ))}
                        </Box>
                        {/* </Box> */}
                      </div>
                    </Box>
                  </Box>
                </Grid>
                <Box>
                  <Grid spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={2}
                      >
                        <Box gridColumn="span 3">
                          <div className="consoleFormButtonBlock">
                            <CustomButton
                              label={"CLICK HERE TO SEE THE LOGS"}
                              className="greenButton"
                              handleClick={this.onClickToSeeLogs}
                              type="sumbit"
                            />
                          </div>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid spacing={2} className="logsContainer">
                    <Grid item xs={12}>
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={2}
                      >
                        <Box gridColumn="span 6">
                          {this.state.Logs &&
                            this.state.Logs.map((item) => {
                              let date = moment(item.actionDate)
                                .format("DD-MM-YYYY")
                                .split("T")
                                .toString();
                              let time = moment(item.actionDate)
                                .format()
                                .split("T", 2);
                              return (
                                <ul>
                                  <li className="logsContent">
                                    <strong>{date}</strong>
                                    <span> </span>
                                    {time[1]}
                                    <span> </span>
                                    {item.remarks}
                                  </li>
                                </ul>
                              );
                            })}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const discountModeList = [
  {
    label: "Amount",
    name: "Amount",
    value: 1,
  },
  {
    label: "Percentage",
    name: "Percentage",
    value: 2,
  },
];

const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "department",
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "BudgetHead",
  },

  {
    no: 3,
    component: "DynamicSelect",
    type: "itemGroup",
  },
  {
    no: 4,
    component: "DynamicSelectNew",
    type: "item",
  },
  {
    no: 5,
    component: "EditableCell",
    type: "qty",
  },
  {
    no: 6,
    component: "EditableCell",
    type: "Unit",
  },
  {
    no: 12,
    component: "EditableCell",
    type: "remarks",
  },
  {
    no: 13,
    component: "EditableCell",
    type: "make",
  },
  {
    no: 7,
    component: "EditableCell",
    type: "Rate",
  },
  {
    no: 8,
    component: "DynamicSelect",
    type: "Tax",
  },
  {
    no: 9,
    component: "DynamicSelect",
    type: "discountMode",
    dropDown: "dropDown",
  },
  {
    no: 10,
    component: "EditableCell",
    type: "discountPercentage",
  },
  {
    no: 11,
    component: "EditableCell",
    type: "amount",
  },
];

const tableHeaders = [
  {
    header: "Department",
  },
  {
    header: "Budget Head",
  },
  {
    header: "Item Group",
  },
  {
    header: "Item",
  },
  {
    header: "Quantity",
  },
  {
    header: "Unit",
  },
  {
    header: "Remarks",
  },
  {
    header: "Make",
  },
  {
    header: "Item Rate",
  },
  {
    header: "Tax",
  },
  {
    header: "Discount Mode",
  },
  {
    header: "Discount/Amount",
  },
  {
    header: "Amount",
  },
  // {
  //   header: "Net Amount",
  // },
  {
    header: "Actions",
  },
];

const mapStatetoProps = (state) => {
  const { itemsListForIndent } = state.itemGroupMasterList;
  const { Inward_LineItems, inwardfilesList } = state.InwardReducer;
  const { taxList } = state.taxList;
  const { logs_List } = state.IndentReducer;
  const { Upload_File_List } = state.UploadFileReducer;
  const { departmentList, allItemGroupMaster_List, parentBudgetHeadsList } =
    state.DropDownListReducer;

  return {
    itemsListForIndent,
    Inward_LineItems,
    departmentList,
    allItemGroupMaster_List,
    parentBudgetHeadsList,
    taxList,
    inwardfilesList,
    Upload_File_List,
    logs_List,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
        getItemListByItemGroupForInward,
    getItemDetailsByItemItemGrpForInward,
    InwardLineItemsProps,
    getDepartmentList,
    allItemGroupMasterList,
    getParentBudgetHeads,
    postUploadFileList,
    deleteFile,
    actions,
    logs,
    getFiles,
    getFilesofInward,
    getFilesofInwardSuccessfull,
    getPendingStockPoandReceipt,
    getPendingStock,
    getTax_List,
    getItemGroupListforindent
  })(IN_WR_LineItem)
);
