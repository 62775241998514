//import { Switch } from "react-router-dom";

import {
  INWARD_LIST,
  INWARD_LIST_SUCCESSFULL,
  INWARD_LINE_ITEMS,
  POLINEITEMSBYPOIDS,
  POLINEITEMSBYPOIDS_SUCCESSFULL,
  CREATE_INWARD,
  CREATE_INWARD_SUCCESSFULL,
  UPDATE_INWARD,
  UPDATE_INWARD_SUCCESSFULL,
  UPDATE_STORE,
  UPDATE_STORE_SUCCESSFULL,
  UPDATE_MATERIAL_INSPECTION,
  UPDATE_MATERIAL_INSPECTION_SUCCESSFULL,
  GET_FILES_OF_INWARD,
  GET_FILES_OF_INWARD_SUCCESSFULL,
  GET_FILES_OF_MI_SUCCESSFULL,
  GET_FILES_OF_SR_SUCCESSFULL,
  CHECKVALIDSUPPID_INVOICE_CHALLAN,
  CHECKVALIDSUPPID_INVOICE_CHALLAN_SUCCESSFULL,
  GET_ITEMLIST_BYITEMGROUP_FORINWARD,
  GET_ITEMLIST_BYITEMGROUP_FORINWARD_SUCCESSFULL,
  GET_ITEMDETAILS_BYITEM_ITEMGRP_FORINWARD,
  GET_ITEMDETAILS_BYITEM_ITEMGRP_FORINWARD_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  Inward_List: [],
  POLineItemsList: [],
  ItemListByItemGroupForInward:[],
  ItemDetailsByItemItemGroupForInward:[],
  Inward_LineItems: [
    {
      lineitemId: 1,
      departmentId: "",
      departmentName:"",
      itemCode:"",
      itemId: "",
      itemName: "",
      itemGroup: "",
      itemGroupId: "",
      itemList:[],
      itemGroupList:[],
      rate: 0,
      discountedRate: "",
      discountedAmount:"0.00",
      discountPercentage:"0.00",
      budgetHeadId: "",
      inwardQty: 0,
      uomCode: "",
      description: "",
      poId: "",
      poNo: "",
      poDetailsId: "",
      oldQty: 0,
      hdrStatus: 1,
      isActive: 1,
      tax: "",
      igstPercentage: "",
      cgstPercentage: "",
      sgstPercentage: "",
      hsnCode: "",
      taxPercentage: "",
      amount: 0,
      checkRoundOffValue: 0,
      inwardSequenceNo:"",
    },
  ],
  error_msg: null,
  createInward: [],
  updateInwardData: [],
  updateStore: [],
  inwardfilesList: [],
  mifilesList: [],
  srfilesList: [],
  updatematerialInspection: [],
  CheckValidData:[]
};

const InwardReducer = (state = initialState, action) => {
  switch (action.type) {
    case INWARD_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case INWARD_LIST_SUCCESSFULL:
      state = {
        ...state,
        Inward_List: action.payload.response,
        loading: false,
      };
      break;
    case POLINEITEMSBYPOIDS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case POLINEITEMSBYPOIDS_SUCCESSFULL:
      state = {
        ...state,
        POLineItemsList: action.payload.response,
        loading: false,
      };
      break;
    case INWARD_LINE_ITEMS:
      state = {
        ...state,
        Inward_LineItems: action.payload,
      };
      break;
    case CREATE_INWARD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_INWARD_SUCCESSFULL:
      state = {
        ...state,
        createInward: action.payload.response,
        loading: true,
      };
      break;
    case UPDATE_INWARD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_INWARD_SUCCESSFULL:
      state = {
        ...state,
        updateInwardData: action.payload.response,
        loading: true,
      };
      break;
    case UPDATE_STORE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_STORE_SUCCESSFULL:
      state = {
        ...state,
        updateStore: action.payload.response,
        loading: true,
      };
      break;
    case UPDATE_MATERIAL_INSPECTION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_MATERIAL_INSPECTION_SUCCESSFULL:
      state = {
        ...state,
        updatematerialInspection: action.payload.response,
        loading: true,
      };
      break;
    case GET_FILES_OF_INWARD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_FILES_OF_INWARD_SUCCESSFULL:
      state = {
        ...state,
        inwardfilesList: action.payload.response,
        loading: true,
      };
      break;
      case CHECKVALIDSUPPID_INVOICE_CHALLAN:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CHECKVALIDSUPPID_INVOICE_CHALLAN_SUCCESSFULL:
      state = {
        ...state,
        CheckValidData: action.payload.response,
        loading: true,
      };
      break;
    case GET_FILES_OF_MI_SUCCESSFULL:
      state = {
        ...state,
        mifilesList: action.payload.response,
        loading: true,
      };
      break;
    case GET_FILES_OF_SR_SUCCESSFULL:
      state = {
        ...state,
        srfilesList: action.payload.response,
        loading: true,
      };
      break;
    
  case GET_ITEMLIST_BYITEMGROUP_FORINWARD:
      state = {
           ...state,
           loading: true,
     }
     break;
 
     case GET_ITEMLIST_BYITEMGROUP_FORINWARD_SUCCESSFULL:
      state = {
        ...state,
        ItemListByItemGroupForInward : action.payload.response,
        loading: false,
      }
      break;
      case GET_ITEMDETAILS_BYITEM_ITEMGRP_FORINWARD:
      state = {
           ...state,
           loading: true,
     }
     break;
 
     case GET_ITEMDETAILS_BYITEM_ITEMGRP_FORINWARD_SUCCESSFULL:
 state = {
        ...state,
        ItemDetailsByItemItemGroupForInward : action.payload.response,
        loading: false,
      }
      break;
     
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default InwardReducer;
