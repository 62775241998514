import { logoutUser } from "../../store/actions";
import React, { Component, Fragment } from "react";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import CustomButton from "../../components/Buttons/Buttons";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import filterFactory from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from "../../components/CheckBox/Checkbox";
import { withRouter } from "react-router-dom";
import Button from "../../components/Buttons/Buttons";
import "../../assets/css/style.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Dropdown, DropdownToggle } from "reactstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  ColumnToggle,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import MonthPicker from "../MonthPicker/MonthPicker";
import IndentFilter from "../FilterPopup/IndentFilter";
import PriceEnquiryFilter from "../FilterPopup/PriceEnquiryFilter";
import InwardFilter from "../FilterPopup/InwardFilter";
import WorkOrderFilter from "../FilterPopup/WorkOrderFilter";
import PrResponseFilter from "../FilterPopup/PrResponseFilter";
import MaterialRequest from "../FilterPopup/MaterialInspection";
import StoreReceiptFilter from "../FilterPopup/StoreReceiptFilter";
import PayRegisterFilter from "../FilterPopup/PayRegisterFilter";
import Leaverequest from "../FilterPopup/Leaverequest";
import AttendanceLogs from "../FilterPopup/AttendanceLogs";
import AttendanceList from "../FilterPopup/AttendanceList";
import LoadingImage from "../../assets/images/loading.gif";
import EmployeeData from "../FilterPopup/EmployeeData";
import ActiveEmployeeData from "../FilterPopup/ActiveEmployeeData";
import InActiveEmployeeData from "../FilterPopup/InActiveEmployeeData";
import JuteGateEntryFilter from "../FilterPopup/JuteGateEntryFilter";
import JuteQualityCheckFilter from "../FilterPopup/JuteQualityCheckFilter";
import JutePoFilter from "../FilterPopup/JutePoFilter";
import JuteBillPassFilter from "../FilterPopup/JuteBillPassFilter";
import JuteMaterialReceiptFilter from "../FilterPopup/JuteMaterialReceiptFilter";
import JuteIssueFilter from "../FilterPopup/JuteIssueFilter";
import LedgerWiseTransactionFilter from "../FilterPopup/LedgerWiseTransactionFilter";
import DayBookFilter from "../FilterPopup/DayBookFilter";
import FinishingEntryFilter from "../FilterPopup/FinishingEntryFilter";
import WeavingProductionFilter from "../FilterPopup/WeavingProductionFilter";
import BeamingProductionFilter from "../FilterPopup/BeamingProductionFilter";
import WindingDoffFilter from "../FilterPopup/WindingDoffFilter";
import SpinningDoffFilter from "../FilterPopup/SpinningDoffFilter";
import CutsAndJugarFilter from "../FilterPopup/CutsAndJugarFilter";
import WastageEntryFilter from "../FilterPopup/WastageEntryFilter";
import CompanyMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/CompanyMasterFilter";
import QualityMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/QualityMasterFilter";
import TermsMasterFilter from "../../components/FilterPopup/MasterModuleFilterPopUps/TermsMasterFilter";
import HolidayMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/HolidayMasterFilter";
import ItemGroupFilter from "../FilterPopup/MasterModuleFilterPopUps/ItemGroupFilter";
import TaxMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/TaxMasterFilter";
import LeaveTypeMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/LeaveTypeMasterFilter";
import WorkerCategoryMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/WorkerCategoryMasterFilter";
import SubDeptMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/SubDeptMasterFilter";
import InActiveTransporterMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/InActiveTransporterMasterFilter";
import ActiveTransporterMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/ActiveTransporterMasterFilter";
import StoreModuleFilter from "../FilterPopup/StoreModuleFilter"
import DebitCreditNoteFilter from "../FilterPopup/DebitCreditNoteFilter";
import JuteIndentFilter from "../FilterPopup/JuteModuleFilter/JuteIndentFilter";

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    Showing {from} to {to} of {size} Results
  </span>
);
const { ToggleList } = ColumnToggle;
const CustomToggleList = ({ columns, onColumnToggle, toggles }) => (
  <div
    className="btn-group btn-group-toggle btn-group-vertical"
    data-toggle="buttons"
  >
    {columns
      .map((column) => ({
        ...column,
        toggle: toggles[column.dataField],
      }))
      .map((column) => (
        <div className="form-check form-check-inline">
          <Checkbox
            label={column.text}
            value={column.text}
            selected={column.hidden ? false : true}
            onChange={() => {
              onColumnToggle(column.dataField);
            }}
          />
        </div>
      ))}
  </div>
);

class Gridwithcustomview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      productsData: this.props.mdata,
      menu1: "none",
      filterList: [],
      searchLists: [],
      checkLists: [],
      activeFilter: [],
      pathName: this.props.url,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      years: 0,
      year_data: "",
      name: "update the indent",
      ShowApp_rej: false,
      showCustonView: false,
      showFilter: false,
      testState: false,
      filter_form: this.props.filter_form,
      columns: [
        {
          text: "",
          dataField: "",
          headerAlign: "",
          align: "",
          hidden: false,
          csvExport: false,
        },
      ],
    };
  }

  componentDidMount() {
    var mcolumns = this.props.mcolumns;
    if (mcolumns) {
      this.setState({
        columns: [],
      });
      const columnsnew = [];
      for (let i = 0; i < mcolumns.length; i++) {
        var coldata = {
          dataField: mcolumns[i].accessor,
          text: mcolumns[i].Header,
          headerAlign: "left",
          align: "left",
          hidden: mcolumns[i].hidden,
          csvExport: mcolumns[i].csvExport,
        };
        columnsnew.push(coldata);
      }
      if (this.props.actions) {
        var actions = {
          dataField: "actions",
          text: "Actions",
          headerAlign: "left",
          align: "left",
          csvExport: false,
        };
        columnsnew.push(actions);
      }
      if (this.props.inputFields) {
        var actioninputFieldss = {
          dataField: "inputFields",
          text: "Actions",
          headerAlign: "left",
          align: "left",
          csvExport: false,
        };
        columnsnew.push(actioninputFieldss);
      }
      if (this.props.deleteIcon) {
        var deleteIcon = {
          dataField: "deleteIcon",
          text: "Actions",
          headerAlign: "left",
          align: "left",
          csvExport: false,
        };

        columnsnew.push(deleteIcon);
      }
      this.setState({
        columns: columnsnew,
      });
    }
  }

  componentWillReceiveProps = (props) => {
    var mcolumns = props.mcolumns;
    if (mcolumns) {
      this.setState({
        columns: [],
      });
      const columnsnew = [];
      for (let i = 0; i < mcolumns.length; i++) {
        var coldata = {
          dataField: mcolumns[i].accessor,
          text: mcolumns[i].Header,
          headerAlign: "left",
          align: "left",
          hidden: mcolumns[i].hidden,
          csvExport: mcolumns[i].csvExport,
        };
        columnsnew.push(coldata);
      }
      if (props.actions) {
        var actions = {
          dataField: "actions",
          text: "Actions",
          headerAlign: "left",
          align: "left",
          csvExport: false,
        };
        columnsnew.push(actions);
      }
      if (props.inputFields) {
        var actioninputFieldss = {
          dataField: "inputFields",
          text: "Actions",
          headerAlign: "left",
          align: "left",
          csvExport: false,
        };
        columnsnew.push(actioninputFieldss);
      }
      if (this.props.deleteIcon) {
        var deleteIcon = {
          dataField: "deleteIcon",
          text: "Actions",
          headerAlign: "left",
          align: "left",
          csvExport: false,
        };
        columnsnew.push(deleteIcon);
      }

      this.setState({
        columns: columnsnew,
      });
    }
  };
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "years") {
      this.setState({
        years: selectedValue,
        year_data: selectedName,
      });
    }
  };
  hide = () => {
    if (this.state.menu === "none") {
      this.setState({ menu: "block" });
    } else {
      this.setState({ menu: "none" });
    }
  };
  state = { showing: false };

  priceFormatter = (column, colIndex) => {
    return (
      <div style={{ position: "relative" }}>
        {column.text}
        <button
          onClick={() => this.getTableData()}
          className="filterDropdownArrow"
        >
          <ArrowDropDownIcon />
        </button>
        <div className="filterContainer" style={{ display: this.state.menu1 }}>
          <form>
            <div className="columnFilterContainer">
              <div className="columnFilterCheckBoxContent">
                {this.state.checkLists.map((filter) => (
                  <div className="columnFilterList">
                    <Checkbox
                      id={filter.id}
                      handleCheckChieldElement={() =>
                        this.onFilterChange(filter.source)
                      }
                    />
                    <label htmlFor={filter.id}>{filter.name}</label>
                  </div>
                ))}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  getTableData() {
    if (this.state.menu1 === "none") {
      this.setState({ menu1: "block" });
    } else {
      this.setState({ menu1: "none" });
    }
    const filterList = [];
    const activeFilter = [];
    const checkLists = [];

    this.props.mdata.forEach((product) => {
      var arr = {
        id: product.id + 100,
        name: product.name,
        value: product.value == null ? "kkkk" : product.value,
        source: product.name.replace(/ /g, ""),
      };
      var arr2 = {
        id: product.id + 100,
        name: product.name,
        value: product.value == null ? "kkkk" : product.value,
        source: product.name.replace(/ /g, ""),
      };

      var arr1 = {
        id: product.id,
        type: product.name.replace(/ /g, ""),
        name: product.name,
        source: product.name.replace(/ /g, ""),
        value: product.value == null ? "kkkk" : product.value,
      };
      filterList.push(arr);
      activeFilter.push(arr1);
      checkLists.push(arr2);
    });

    let checkListsF = checkLists.filter(
      (ele, ind) =>
        ind === checkLists.findIndex((elem) => elem.name === ele.name)
    );

    this.setState({
      filterList: filterList,
      searchLists: activeFilter,
      checkLists: checkListsF,
    });
  }

  onFilterChange(filter) {
    const { filterList, activeFilter } = this.state;

    if (filter === "ALL") {
      if (activeFilter.length === filterList.length) {
        this.setState({ activeFilter: [] });
      } else {
        this.setState({
          activeFilter: filterList.map((filters) => filters.value),
        });
      }
    } else {
      if (activeFilter.includes(filter)) {
        const filterIndex = activeFilter.indexOf(filter);
        const newFilter = [...activeFilter];
        newFilter.splice(filterIndex, 1);
        this.setState({ activeFilter: newFilter });
      } else {
        this.setState({ activeFilter: [...activeFilter, filter] });
      }
    }
  }
  handleClick = (pathUrl) => {
    if (pathUrl) {
      this.props.history.push(`/${pathUrl}`);
    }
  };

  handledeleteRecord = (row) => {
    this.props.handledeleteRecord(row);
  };

  render() {
    const mdata = this.props.mdata;
    if (this.props.loading === true) {
      return (
        <div className="loading">
          <img src={LoadingImage} alt="" />
        </div>
      );
    }
    let str = JSON.stringify(mdata, (k, v) => (v == null ? "" : v));
    let mdataaaa = JSON.parse(str);
    if (mdataaaa) {
      var products = mdataaaa.map((row, order) => ({
        ...row,
        chk: (
          <div className="checkbox form-check">
            <div className="custom-checkbox custom-control custom-control-inline">
              <input
                type="checkbox"
                className="custom-control-input"
                onChange={this.handleChange}
                value={row.id}
                id={row.id}
              />
              <label className="custom-control-label" for={row.id}></label>
            </div>{" "}
          </div>
        ),
        actions: (
          <Fragment>
            <CustomButton
              className="gridPlusButton"
              icon={this.props.micon}
              handleClick={(e, id) => {
                this.props.history.push(this.state.pathName, {
                  rowData: row,
                });
              }}
            />
          </Fragment>
        ),
        inputFields: (
          <Fragment>
            <CustomButton
              className="gridPlusButton"
              icon={this.props.micon}
              handleClick={(e, id) => {
                this.props.history.push(this.state.pathName, {
                  rowData: row,
                });
              }}
            />
          </Fragment>
        ),
        deleteIcon: (
          <Fragment>
            <CustomButton
              className="gridDeleteButton"
              icon={this.props.micon}
              handleClick={() => this.handledeleteRecord(row)}
            />
          </Fragment>
        ),
      }));
    } else {
      var products = [];
    }
    const customHandleToggleColumnVisiblity = (dataField) => {
      const updateHiddenProperty = (column) => {
        if (column.hidden === undefined) {
          return true;
        } else {
          return !column.hidden;
        }
      };
      const UpdatedCsvExportProperty = (column) => {
        if (column.csvExport == undefined) {
          return true;
        } else {
          return column.dataField == "actions" ? false : !column.csvExport;
        }
      };
      const newState = this.state.columns.map((column) => {
        if (column.dataField === dataField) {
          return {
            ...column,
            hidden: updateHiddenProperty(column),
            csvExport: UpdatedCsvExportProperty(column),
          };
        }
        return column;
      });
      this.setState({
        columns: newState,
      });
    };

    const MyExportCSV = (props) => {
      const handleExportClick = () => {
        props.onExport();
      };
      return (
        <div onClick={handleExportClick} className="more_orderList">
          <FileUploadOutlinedIcon />
          <span>Export list</span>
        </div>
      );
    };
    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
    };
    const selectRowFalse = {
      mode: "checkbox",
      clickToSelect: true,
      headerColumnStyle: {
        display: "none",
      },

      selectColumnStyle: {
        display: "none",
      },
    };
    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="btn-group" role="group" style={{ float: "left" }}>
        {options.map((option) => {
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
            >
              {option.text}
            </button>
          );
        })}
      </div>
    );

    const options = {
      sizePerPageRenderer,
      firstPageText: "First",
      prePageText: "< Prev",
      nextPageText: "Next >",
      lastPageText: "Last",
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      // extra line added sizePerpage if problem come please remove.
      sizePerPage: this.props.sizePerpage ? this.props.sizePerpage : 10,
      showTotal: true,
      paginationTotalRenderer: customTotal,
    };
    return (
      <div className="gridMonthSelection">
        <div className="gridContainer">
          <div className="gridComponent">
            <PaginationProvider
              pagination={paginationFactory({
                custom: true,
                totalSize: products.length,
                sizePerPageList: [
                  {
                    text: "5",
                    value: 5,
                  },
                  {
                    text: "10",
                    value: 10,
                  },
                  {
                    text: "30",
                    value: 30,
                  },
                  {
                    text: "50",
                    value: 50,
                  },
                  {
                    text: "All",
                    value: products.length,
                  },
                ],
                hideSizePerPage: products.length === 0,
              })}
              keyField="companyId"
              columns={this.state.columns}
              data={products}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="companyId"
                  columns={this.state.columns}
                  data={products}
                  columnToggle
                  search
                  exportCSV={{
                    fileName: this.state.filter_form,
                    exportAll: true,
                    noAutoBOM: false,
                    blobType: "text/csv;charset=ansi",
                  }}
                >
                  {(toolkitprops) => (
                    <div>
                      <div className="buttonBlock">
                        <Grid item xs={12} md={3}>
                          {this.props.monthPicker && <MonthPicker />}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <div
                            style={{ display: "flex", position: "relative" }}
                          >
                                      {this.props.fromDate && this.props.toDate &&
                          <div style={{ marginTop: "5px" }}>
                            <span
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                                color: "#969c99",
                                fontWeight: "600",
                              }}
                            >
                              From {this.props.fromDate}
                            </span>
                            <span
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                                color: "#969c99",
                                fontWeight: "600",
                              }}
                            >
                              To
                            </span>
                            <span
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                                color: "#969c99",
                                fontWeight: "600",
                              }}
                            >
                              {this.props.toDate}
                            </span>
                          </div>}
                            <Button
                              label="More Actions"
                              className="lightBlueButton"
                              muIcon={<KeyboardArrowDownIcon />}
                              handleClick={() => {
                                this.setState({
                                  showMoreOpts: !this.state.showMoreOpts,
                                  isDropdown: false,
                                });
                              }}
                            />

                            <Dropdown
                              toggle={() =>
                                this.setState({
                                })
                              }
                            >
                              <DropdownToggle className="custom_view">
                                <CustomButton
                                  label="Customize View"
                                  type="button"
                                  className="lightBlueButton"
                                  handleClick={() => {
                                    this.setState({
                                      isDropdown: !this.state.isDropdown,
                                    });
                                  }}
                                />
                              </DropdownToggle>
                              {this.state.isDropdown && (
                                <>
                                  <div
                                    className={
                                      this.state.isDropdown === true
                                        ? "backdropNew"
                                        : ""
                                    }
                                    onClick={() => {
                                      this.setState({
                                        isDropdown: !this.state.isDropdown,
                                      });
                                    }}
                                  ></div>
                                  <div className="customizeViewContainer">
                                    <div className="customizeViewContent">
                                      <CustomToggleList
                                        onColumnToggle={
                                          customHandleToggleColumnVisiblity
                                        }
                                        columns={
                                          toolkitprops.columnToggleProps.columns
                                        }
                                        toggles={
                                          toolkitprops.columnToggleProps.columns
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </Dropdown>

                            {this.state.showMoreOpts && (
                              <div className="more_actions">
                                <div
                                  onClick={() => {
                                    this.setState({
                                      showMoreOpts: false,
                                      isDropdown: false,
                                    });
                                  }}
                                  className="Invisible"
                                ></div>
                                <div style={{ dispay: "flex" }}>
                                  <MyExportCSV {...toolkitprops.csvProps} />
                                </div>
                              </div>
                            )}
                          </div>

                          <div
                            className="searchBlock"
                            onClick={() => {
                              this.setState({
                                showFilter: !this.state.showFilter,
                              });
                            }}
                          >
                            {this.state.filter_form === "indent" ? (
                              <IndentFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "priceEnquiry" ? (
                              <PriceEnquiryFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "inward" ? (
                              <InwardFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "workOrder" ? (
                              <WorkOrderFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "PrResponse" ? (
                              <PrResponseFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "PurchaseOrder" ? (
                              <WorkOrderFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                                PoNo={"1"}
                              />
                            ) : this.state.filter_form ===
                              "wastageEntryFilter" ? (
                              <WastageEntryFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form ===
                              "MaterialInspection" ? (
                              <MaterialRequest
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form ===
                              "InActiveTransporterMaster" ? (
                              <InActiveTransporterMasterFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form ===
                              "ActiveTransporterMaster" ? (
                              <ActiveTransporterMasterFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "StoreReceipt" ? (
                              <StoreReceiptFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                                PoNo={"1"}
                              />
                            ) : this.state.filter_form === "billPass" ? (
                              <StoreReceiptFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "storeModule" ? (
                              <StoreModuleFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "PayRegister" ? (
                              <PayRegisterFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "LeaveRequest" ? (
                              <Leaverequest
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "AttendanceLogs" ? (
                              <>
                                <AttendanceLogs
                                  From_date={this.props.From_date}
                                  toDate={this.props.toDate}
                                  handleChange={this.props.handleChange}
                                  toolkitprops={toolkitprops}
                                  columns={this.state.columns}
                                  showFilter={this.state.showFilter}
                                  handle_search={this.props.handleSearch}
                                  filter_form={this.props.filter_form}
                                  handlegetdates={this.props.handlegetdates}
                                />
                              </>
                            ) : this.state.filter_form === "AttendanceList" ? (
                              <AttendanceList
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form ===
                              "TotalEmployeedata" ? (
                              <EmployeeData
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form ===
                              "ActiveEmployeedata" ? (
                              <ActiveEmployeeData
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form ===
                              "InActiveEmployeedata" ? (
                              <InActiveEmployeeData
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form ===
                              "JuteMaterialReceipt" ? (
                              <JuteMaterialReceiptFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form ===
                              "JuteQualityCheck" ? (
                              <JuteQualityCheckFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "JuteGateEntry" ? (
                              <JuteGateEntryFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "SubDeptMaster" ? (
                              <SubDeptMasterFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form ===
                              "JutePurchaseOrder" ? (
                              <JutePoFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "JuteBillPass" ? (
                              <JuteBillPassFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form ===
                              "LedgerWiseTransaction" ? (
                              <LedgerWiseTransactionFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "daybook" ? (
                              <DayBookFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "JuteIssueFilter" ? (
                              <JuteIssueFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form ===
                              "FinishingEntryFilter" ? (
                              <FinishingEntryFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form ===
                              "WeavingProductionFilter" ? (
                              <WeavingProductionFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form ===
                              "BeamingProductionFilter" ? (
                              <BeamingProductionFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form ===
                              "WindingDoffFilter" ? (
                              <WindingDoffFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form ===
                              "SpinningDoffFilter" ? (
                              <SpinningDoffFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form ===
                              "CutsAndJugarFilter" ? (
                              <CutsAndJugarFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "Company_Master" ? (
                              <CompanyMasterFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "QualityMaster" ? (
                              <QualityMasterFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "TermsMaster" ? (
                              <TermsMasterFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "TaxMasterFilter" ? (
                              <TaxMasterFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "HolidayMaster" ? (
                              <HolidayMasterFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "TaxMasterFilter" ? (
                              <TaxMasterFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "HolidayMaster" ? (
                              <>
                                <HolidayMasterFilter
                                  toolkitprops={toolkitprops}
                                  columns={this.state.columns}
                                  showFilter={this.state.showFilter}
                                  handle_search={this.props.handleSearch}
                                  filter_form={this.props.filter_form}
                                />
                              </>
                            ) : this.state.filter_form === "itemGroupMaster" ? (
                              <ItemGroupFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) : this.state.filter_form === "leaveType" ? (
                              <LeaveTypeMasterFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            ) 
                            : this.state.filter_form === "DebitCreditNoteFilter" ? (
                              <DebitCreditNoteFilter
                                toolkitprops={toolkitprops}
                                columns={this.state.columns}
                                showFilter={this.state.showFilter}
                                handle_search={this.props.handleSearch}
                                filter_form={this.props.filter_form}
                              />
                            )
                            
                            
                            : this.state.filter_form ===
                              "WorkerCategoryMaster" ? (
                              <>
                                <WorkerCategoryMasterFilter
                                  toolkitprops={toolkitprops}
                                  columns={this.state.columns}
                                  showFilter={this.state.showFilter}
                                  handle_search={this.props.handleSearch}
                                  filter_form={this.props.filter_form}
                                />
                              </>
                            ) :this.state.filter_form ==="JuteIndentFilter" ?(
                              <JuteIndentFilter
                              toolkitprops={toolkitprops}
                              columns={this.state.columns}
                              showFilter={this.state.showFilter}
                              handle_search={this.props.handleSearch}
                              filter_form={this.props.filter_form}
                              /> 
                            ):
                             (
                              ""
                            )}
                          </div>
                        </Grid>
                      </div>
                      <div
                        className="customizeViewContainer"
                        style={{ display: this.state.menu }}
                      >
                        <span className="customizeViewContent">
                        </span>
                      </div>
                      <BootstrapTable
                        {...toolkitprops.baseProps}
                        {...paginationTableProps}
                        selectRow={
                          this.state.ShowApp_rej ? selectRow : selectRowFalse
                        }
                        defaultSortDirection="asc"
                        striped
                        noDataIndication="No Data Is Available"
                        keyField="id"
                        filter={filterFactory()}
                        pagination={
                          this.props.pagination !== 1
                            ? products.length === 0
                              ? ""
                              : paginationFactory(options)
                            : ""
                        }
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
            {this.state.ShowApp_rej && (
              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Approve"}
                  className="greenButton"
                  style={{ marginRight: "10px" }}
                  handleClick={this.onClickNext}
                  type="sumbit"
                />

                <CustomButton
                  label={"Reject"}
                  className="rejected"
                  handleClick={this.onClickNext}
                  type="sumbit"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(connect(null, { logoutUser })(Gridwithcustomview));
