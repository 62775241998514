import React from "react";
import Radio from "../../components/RadioButton/Radio";
import RadioGroup from "../../components/RadioButton/RadioGroup"

class RadioButton extends React.Component {
  state = { selectedValue: "" };
  onClickRadioButton = selectedValue => this.setState({ selectedValue });
  
  render() {    
    return (
      <RadioGroup
        name={this.props.groupname}
        onClickRadioButton={this.props.onChange}
        selectedValue={this.state.selectedValue}
        className="radioBlock"
      >
        <Radio value={this.props.value} labelText={this.props.labelText} checkednew={this.props.checkednew} onChange={this.props.onChange} disabled={this.props.disabled}/>
      </RadioGroup>
    );
  }
}

export default RadioButton;
