import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/PaginationGridView";
import moment from "moment";
import {
  getMaterialRequestList,
  ClearHeaderData,
  setStoreIssueViewByIdNull,
} from "../../../store/StoreModule/MaterialRequest/actions";

class MaterialRequestMasterIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      materialRequestListData: [],
      dataexp: [],
      startIndex: 1,
      lastIndex: 10,
      fromDate: moment().day(-7).format("YYYY-MM-DD").toString(),
      toDate: moment().format("YYYY-MM-DD").toString(),
      materialRequestListColumns: [
        {
          Header: "Date",
          accessor: "date",
          headerAlign: "left",
          align: "left",
          hidden: false,
        },
        {
          Header: "Project",
          accessor: "project",
          headerAlign: "left",
          align: "left",
          hidden: false,
        },
        {
          Header: "Branch",
          accessor: "branch",
          headerAlign: "left",
          align: "left",
          hidden: false,
        },
        {
          Header: "Customer Name",
          accessor: "customer",
          headerAlign: "left",
          align: "left",
          hidden: false,
        },
        {
          Header: "Expenses Type",
          accessor: "expeType",
          headerAlign: "left",
          align: "left",
          hidden: false,
        },
        {
          Header: "Issue No",
          accessor: "hdrId",
          headerAlign: "left",
          align: "left",
          hidden: false,
        },
        {
          Header: "Status",
          accessor: "status",
          headerAlign: "left",
          align: "left",
          hidden: false,
        },
      ],
    };
  }

  componentDidMount() {
    this.props.ClearHeaderData([]);
    if (this.props.location.pathname === "/material_request") {
      const data = {
        branchId: "",
        companyId: localStorage.getItem("companyId"),
        customerId: "",
        erpType: "2",
        exeType: "",
        finnacialYear: localStorage.getItem("acadamicYear"),
        fromDate: "",
        hdrId: "",
        projectId: "",
        status: "29",
        toDate: "",
      };
      const expdata = {
        ...data,
        startIndex: 0,
        lastIndex: 0,
      };
      this.setState({
        dataexp: expdata,
      });
      this.props.getMaterialRequestList(
        serverApi.GET_ALL_MATERIAL_REQUEST_LIST,
        data,
        this.props.history
      );
    } else {
      const data = {
        branchId: "",
        companyId: localStorage.getItem("companyId"),
        customerId: "",
        erpType: "1",
        finnacialYear: localStorage.getItem("acadamicYear"),
        fromDate: moment(this.state.fromDate).format("DD-MM-YYYY"),
        hdrId: "",
        indentTypeId: "",
        projectId: "",
        status: "",
        toDate: moment(this.state.toDate).format("DD-MM-YYYY"),
        startIndex: 1,
        lastIndex: 10,
      };
      const expdata = {
        ...data,
        startIndex: 0,
        lastIndex: 0,
      };
      this.setState({
        dataexp: expdata,
      });
      this.props.getMaterialRequestList(
        serverApi.GET_ALL_MATERIAL_REQUEST_LIST,
        data,
        this.props.history
      );
      this.props.setStoreIssueViewByIdNull([]);
    }
  }
  componentWillReceiveProps(props) {
    if (props.MaterialRequest_List) {
      this.setState({
        materialRequestListData: props.MaterialRequest_List.data,
        totalRecs: props.MaterialRequest_List.totalRecords,
      });
    }
  }

  handleSearch = (searchData) => {
    const data = {
      branchId: searchData.branchId,
      companyId: searchData.companyId,
      customerId: searchData.customerId,
      erpType: "2",
      exeType: searchData.exeType === "0" ? "" : searchData.exeType,
      finnacialYear: searchData.finnacialYear,
      fromDate:
        moment(searchData.fromDate).format("DD-MM-YYYY") === "Invalid date"
          ? ""
          : moment(searchData.fromDate).format("DD-MM-YYYY"),
      hdrId: searchData.hdrId,
      projectId: searchData.projectId,
      status:
        this.props.location.pathname === "/material_request"
          ? "29"
          : searchData.status === 0
          ? ""
          : searchData.status,
      toDate:
        moment(searchData.toDate).format("DD-MM-YYYY") === "Invalid date"
          ? ""
          : moment(searchData.toDate).format("DD-MM-YYYY"),
      startIndex: searchData.startIndex,
      lastIndex: searchData.lastIndex,
    };
    this.setState({
      exeType: searchData.exeType === "0" ? "" : searchData.exeType,
      branchId: searchData.branchId,
      status: searchData.status === 0 ? "" : searchData.status,
      projectId: searchData.projectId,
      fromDate: searchData.fromDate,
      toDate: searchData.toDate,
      customerId: searchData.customerId,
    });
    this.props.getMaterialRequestList(
      serverApi.GET_ALL_MATERIAL_REQUEST_LIST,
      data,
      this.props.history
    );
  };
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    const data = {
      branchId: this.state.branchId,
      companyId: localStorage.getItem("companyId"),
      customerId: this.state.customerId,
      erpType: "1",
      finnacialYear: localStorage.getItem("acadamicYear"),
      fromDate: this.state.fromDate
        ? moment(this.state.fromDate).format("DD-MM-YYYY")
        : "",
      hdrId: "",
      indentTypeId: "",
      exeType: this.state.exeType,
      projectId: this.state.projectId,
      status: this.state.status,
      toDate: this.state.toDate
        ? moment(this.state.toDate).format("DD-MM-YYYY")
        : "",
    };
    if (type === 1) {
      data.startIndex = sizePerPage;
      data.lastIndex = currentIndex;
      data.isActive = null;
      if (currentIndex >= 0) {
        this.props.getMaterialRequestList(
          serverApi.GET_ALL_MATERIAL_REQUEST_LIST,
          data,
          this.props.history
        );
      }
    } else {
      data.startIndex = currentIndex + 1;
      data.lastIndex = sizePerPage;
      data.isActive = null;
      if (currentIndex >= 0) {
        this.props.getMaterialRequestList(
          serverApi.GET_ALL_MATERIAL_REQUEST_LIST,
          data,
          this.props.history
        );
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.materialRequestListData}
          mcolumns={this.state.materialRequestListColumns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          filter_form={"storeModule"}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          url={
            this.props.location.pathname === "/material_request"
              ? "/create_material_request"
              : "/create_consumption"
          }
          pageno={this.state.pageno}
          handleSearch={this.handleSearch}
          apiexp={serverApi.GET_ALL_MATERIAL_REQUEST_LIST}
          payloadexp={this.state.dataexp}
          expName={"StoreIssue.csv"}
          fromDate={moment(this.state.fromDate, "YYYY-MM-DD", true).format(
            "DD-MM-YYYY"
          )}
          toDate={moment(this.state.toDate, "YYYY-MM-DD", true).format(
            "DD-MM-YYYY"
          )}
        />
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { MaterialRequest_List } = state.Material_Request_List;
  return { MaterialRequest_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getMaterialRequestList,
    ClearHeaderData,
    setStoreIssueViewByIdNull,
  })(MaterialRequestMasterIndex)
);
