import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import CustomButton from "../../../components/Buttons/Buttons";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import {
  getMachineNumber,
  getSpell,
  MarkAttendance,
  getUpdateAttendance,
  getWorkerByEBNumber,
  getWorkerDataClearForAttendance,
  getAttendanceDesignation,
} from "../../../store/HRMS/AttendanceCalender/actions";
import {
  getDepartmentListData,
  getEmployeeList,
} from "../../../store/HRMS/EmployeeDataBase/actions";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import moment from "moment";
import Datetime from "react-datetime";
import searchIcon from "../../../assets/images/search.png";
import InputAdormentSearch from "../../../components/InputAdorment/InputAdormentSearch";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import swal from "sweetalert";
import { getSpellMasterList } from "../../../store/Master/SpellMaster/actions";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import AutoMultiselect from "../../../components/Dropdown/AutoMultiselect";
class MarkAttendence extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      emp_code: JSON.parse(localStorage.getItem("authUser")).ebNo,
      ebId: "",
      date: moment().format("YYYY-MM-DD"),

      attendanceDate: "",
      attendance_mark: "",
      attendance_source: "M",
      attendence_type: "",
      idle_hours: "0",
      entry_time: "",
      exit_time: "",
      EntryTime: "",
      ExitTime: "",
      working_hours: "",
      spell: [],
      spell_name: "",
      Department_List: [],
      department_name: "",
      dept: [],
      Designation: [],
      designation_name: "",
      machineList: [],
      machineIds: [],
      Remarks: "",
      createdBy: "",
      createdByDesc: "",
      createdOn: "",
      updateSpell: [],
      employeeListData: [],
      show: false,
      showData: false,
      minimumDate: "",
      maximumDate: "",
      NewMaxData: 1,
      NewMinData: 1,
      HideUpdateButton: true,
      spellMasterListData: [],
      MachineFieldDisplay: false,
      deptChange: false,
      Preselect: false,
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      spellName: null,
      spellCode: null,
    };

    this.props.getSpellMasterList(
      serverApi.SHIFT_SPELL_MASTER,
      data,
      this.props.history
    );
    if (JSON.parse(localStorage.getItem("Configuration"))) {
      if (
        JSON.parse(localStorage.getItem("Configuration")).configList.length !==
        0
      ) {
        JSON.parse(localStorage.getItem("Configuration")).configList.map(
          (item) => {
            if (item.configParameter == "backDateAllowable") {
              if (item.configValue == "1") {
              } else {
                this.setState({
                  minimumDate: 2,
                });
              }
            } else if (item.configParameter == "backDaysAllowable") {
              if (item.configValue) {
                var value7 = item.configValue
                  .split('"', 3)[2]
                  .split(":")[1]
                  .split(",")[0];
                if (value7 === "1") {
                  this.setState({
                    minimumDate: 7,
                  });
                } else if (value7 == 2) {
                  this.setState({
                    minimumDate: 14,
                  });
                } else if (value7 == 3) {
                  this.setState({
                    minimumDate: 0,
                  });
                } else if (value7 == 4) {
                  if (
                    item.configValue
                      .split(",")[1]
                      .split(":")[1]
                      .split("}")[0] !== 0
                  ) {
                    this.setState({
                      minimumDate: 1,
                      NewMinData: Number(
                        item.configValue
                          .split(",")[1]
                          .split(":")[1]
                          .split("}")[0]
                      ),
                    });
                  }
                }
              }
            } else if (item.configParameter == "futureDateAllowable") {
              if (item.configValue == "1") {
              } else {
                this.setState({
                  maximumDate: 2,
                });
              }
            } else if (item.configParameter == "futureDaysAllowable") {
              if (item.configValue) {
                var value7 = item.configValue
                  .split('"', 3)[2]
                  .split(":")[1]
                  .split(",")[0];
                if (value7 === "1") {
                  this.setState({
                    maximumDate: 7,
                  });
                } else if (value7 == 2) {
                  this.setState({
                    maximumDate: 14,
                  });
                } else if (value7 == 3) {
                  this.setState({
                    maximumDate: 0,
                  });
                } else if (value7 == 4) {
                  if (
                    item.configValue
                      .split(",")[1]
                      .split(":")[1]
                      .split("}")[0] !== 0
                  ) {
                    this.setState({
                      maximumDate: 1,
                      NewMaxData: Number(
                        item.configValue
                          .split(",")[1]
                          .split(":")[1]
                          .split("}")[0]
                      ),
                    });
                  }
                }
              }
            }
          }
        );
      }
    }
    // this.props.getSpell(
    //   serverApi.SPELL +
    //     localStorage.getItem("companyId") +
    //     "/" +
    //     this.state.userDit.cipher,
    //   this.props.history
    // );
    this.props.getDepartmentListData(
      serverApi.DEPARTMENT_LIST + localStorage.getItem("companyId"),
      this.props.history
    );

    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData !== undefined) {
        if (this.props.location.state.rowData.statusId == "3") {
          this.setState({
            HideUpdateButton: false,
          });
        }
      }
      var Created = this.props.location.state.rowData.createdDateTime.split(
        " ",
        1
      );
      if (Created) {
        var createdDatee = moment(Created, "YYYY-MM-DD", true).format(
          "DD-MM-YYYY"
        );
      }
      var entry = new Date(
        this.props.location.state.rowData.entryTime
      ).getTime();
      var exit = new Date(this.props.location.state.rowData.exitTime).getTime();
      this.setState({
        emp_code: this.props.location.state.rowData.ebNo,
        date: this.props.location.state.rowData.attendanceDate,
        attendance_mark: this.props.location.state.rowData.attendanceMark,
        attendance_source: this.props.location.state.rowData.attendanceSource,
        attendence_type: this.props.location.state.rowData.attendanceType,
        idle_hours: this.props.location.state.rowData.idleHours,
        working_hours: this.props.location.state.rowData.workHours,
        spell_name: this.props.location.state.rowData.spell,
        department_name: this.props.location.state.rowData.workedDepartmentId,
        designation_name: this.props.location.state.rowData.workedDesignationId,
        createdBy: this.props.location.state.rowData.createdBy,
        createdByDesc: this.props.location.state.rowData.createdByDesc,
        Remarks: this.props.location.state.rowData.remarks,
        entry_time: this.props.location.state.rowData.entryTimeDesc,
        exit_time: this.props.location.state.rowData.exitTimeDesc,
        ebId: this.props.location.state.rowData.ebId,
        ExitTime: exit,
        EntryTime: entry,
        createdOn: createdDatee,
        ApproveButton: this.props.location.state.rowData.approveButton,
      });
      this.props.getAttendanceDesignation(
        serverApi.DESIGNATION +
          localStorage.getItem("companyId") +
          "/" +
          this.props.location.state.rowData.workedDepartmentId,
        this.props.history
      );
      if (this.props.location.state.rowData.machineDetails) {
        if (this.props.location.state.rowData.machineDetails.length !== 0) {
          let machineList = [];
          this.props.location.state.rowData.machineDetails.map((item) => {
            machineList.push({
              value: item.machineId,
            });
            this.setState({
              machineIds: machineList,
            });
          });
          // this.setState({
          //   MachineFieldDisplay: true,
          // });
        }
        //  else {
        //   this.setState({
        //     MachineFieldDisplay: false,
        //   });
        // }
      }
      this.props.getMachineNumber(
        serverApi.MACHINE_NUMBER +
          this.props.location.state.rowData.workedDesignationId +
          "/company/" +
          localStorage.getItem("companyId"),
        this.props.history
      );
      // if (this.props.location.state.rowData.machineDetails) {
      //   if (this.props.location.state.rowData.machineDetails.length !== 0) {
      //     this.setState({
      //       MachineFieldDisplay: true,
      //     });
      //   }
      // }
    }
  }
  componentWillReceiveProps(props) {
    if (props.WorkerData_ByEb_No) {
      if (props.WorkerData_ByEb_No.data) {
        if (props.WorkerData_ByEb_No.data.empStatus == 35) {
          if (
            // this.state.department_name == "" &&
            // this.state.designation_name == "" &&
            this.state.Preselect == false
          ) {
            this.setState({
              department_name: props.WorkerData_ByEb_No.data.deptId,
              designation_name: props.WorkerData_ByEb_No.data.designationId,
              ebId: props.WorkerData_ByEb_No.data.ebId,
            });
          } else {
          }
        } else {
          this.setState({
            show: true,
          });
        }
      } else {
        this.setState({
          showData: true,
        });
      }
    }
    if (props.machine_number) {
      if (props.machine_number.data) {
        if (props.machine_number.data.length !== 0) {
          // if (this.props.location.state == undefined) {
            this.setState({
              MachineFieldDisplay: true,
            });
          // }
          let List = [];
          props.machine_number.data.map((item) => {
            List.push({
              value: item.value,
              label: item.label,
              name: item.name,
            });
          });
          this.setState({
            machineList: List,
          });
        } else {
          this.setState({
            MachineFieldDisplay: false,
          });
        }
      }
    }
    if (props.spell) {
      // this.setState({
      //   spell: props.spell,
      // });
    }
    if (props.departmentList) {
      if (props.departmentList.data) {
        if (props.departmentList.data.length !== 0) {
          let DeptData = props.departmentList.data.filter((item) => {
            if (item.value !== 0) {
              return item;
            }
          });

          this.setState({
            Department_List: DeptData,
          });
        }
      }
    }
    if (props.AttendanceDesignation) {
      if (props.AttendanceDesignation.length !== 0) {
        var DesignationListData = props.AttendanceDesignation.filter(
          (item) => item.value !== 0
        );
        this.setState({
          Designation: DesignationListData,
        });
      }
    }
    if (props.MarkAttendance) {
      this.setState({
        MarkAttendance: props.MarkAttendance,
      });
    }
    if (this.props.location.state !== undefined) {
      var AttendanceMark = attendence_mark.filter(
        (item) => item.name === this.props.location.state.rowData.attendanceMark
      );
      if (AttendanceMark[0] !== undefined) {
        this.setState({
          attendance_mark: AttendanceMark[0].value,
        });
      }
      var AttendanceSource = attendence_source.filter(
        (item) =>
          item.name === this.props.location.state.rowData.attendanceSource
      );
      if (AttendanceSource[0] !== undefined) {
        this.setState({
          attendance_source: AttendanceSource[0].value,
        });
      }
      var AttendanceType = attendence_type.filter(
        (item) => item.name === this.props.location.state.rowData.attendanceType
      );
      if (AttendanceType[0] !== undefined) {
        this.setState({
          attendence_type: AttendanceType[0].value,
        });
      }
    }
    if (props.SpellMaster_List) {
      if (props.SpellMaster_List.data) {
        if (props.SpellMaster_List.data.length !== 0) {
          this.setState({
            spellMasterListData: props.SpellMaster_List.data, // updating spell master list data
          });
          let list = [];
          props.SpellMaster_List.data.map((item) => {
            list.push({
              label: item.spellName,
              value: item.spellId,
              name: item.spellName,
            });
          });
          this.setState({
            spell: list,
          });
        }
      }
    }
  }
  onclickOk = () => {
    this.setState({
      show: false,
      showData: false,
    });
    this.props.getWorkerDataClearForAttendance(null);
  };
  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "EmpCode") {
      this.setState({
        emp_code: event,
      });
    }
    if (key === "IdleHours") {
      if (event > this.state.working_hours) {
        swal("Idle Hours should not be Greater than Working hours");
        this.setState({
          idle_hours: 0,
        });
      } else {
        this.setState({
          idle_hours: event,
        });
      }
    }
    if (key === "EntryTime") {
      this.setState({
        entry_time: event,
      });
    }
    if (key === "ExitTime") {
      this.setState({
        exit_time: event,
      });
    }
    if (key === "WorkingHours") {
      this.setState({
        working_hours: event,
      });
    }
    if (key === "Remarks") {
      this.setState({
        Remarks: event,
      });
    }
    if (key === "createdBy") {
      this.setState({
        createdBy: event,
      });
    }
    if (key === "createdOn") {
      this.setState({
        createdOn: event,
      });
    }
  };
  handleSelectDate = (e, name) => {
    if (name === "Date") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();

      this.setState({
        date: Date,
      });
    }
  };
  onClickSearch = () => {
    this.props.getWorkerByEBNumber(
      serverApi.WORKER_LIST +
        this.state.emp_code +
        "/" +
        localStorage.getItem("companyId") +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).userId,
      this.props.history
    );
    this.setState({
      Preselect: false,
    });
  };

  onUpdate = (status) => {
    if (this.state.emp_code == "") {
      swal("Please Enter the Employee Code ");
    } else if (this.state.date == "") {
      swal("Please Enter the Attendance Date ");
    } else if (this.state.attendence_type == "") {
      swal("Please Enter the Attendance Type ");
    } else if (this.state.spell_name == "" || this.state.spell_name == 0) {
      swal("Please Select the Spell ");
    } else if (this.state.EntryTime == "") {
      swal("Please Enter the Entry Time ");
    } else if (this.state.ExitTime == "") {
      swal("Please Enter the Exit Time ");
    } else if (
      this.state.working_hours == "" ||
      this.state.working_hours == null
    ) {
      swal("Please Enter the Working hour ");
    } else if (this.state.idle_hours === "") {
      swal("Please Enter the Idle hours");
    } else if (
      this.state.department_name == "" ||
      this.state.department_name == 0
    ) {
      swal("Please Select the Department ");
    } else if (
      this.state.designation_name == "" ||
      this.state.designation_name == 0
    ) {
      swal("Please Select the Designation ");
    } else {
      let MachineData = [];
      this.state.machineIds.map((item) => {
        MachineData.push(item.value);
      });
      if (this.props.location.state) {
        if (this.props.location.state.rowData) {
          const data = {
            dailyAttenId: this.props.location.state.rowData.dailyAttenId,
            attendanceDate: this.state.date,
            attendanceMark: this.state.attendance_mark,
            attendanceSource: this.state.attendance_source,
            attendanceType: this.state.attendence_type,
            deviceId: 0,
            ebCode: this.state.emp_code,
            ebId: this.state.ebId,
            ebNo: this.state.emp_code,
            entryTime: this.state.EntryTime,
            exitTime: this.state.ExitTime,
            idleHours: this.state.idle_hours,
            remarks: this.state.Remarks,
            spell: this.state.spell_name,
            workedDepartmentId: this.state.department_name,
            workedDesignationId: this.state.designation_name,
            workingHours: this.state.working_hours,
            statusId: status,
            machineIds: MachineData,
            updatedBy: this.state.userDit.userId,
            createdBy: this.props.location.state.rowData.createdBy,
            companyId: this.props.location.state.rowData.companyId,
          };
          this.props.getUpdateAttendance(
            serverApi.UPDATE_ATTENDANCE,
            data,
            this.props.history
          );
        }
      }
    }
  };
  onClickNext = () => {
    if (this.state.emp_code == "") {
      swal("Please Enter the Employee Code ");
    } else if (this.state.date == "") {
      swal("Please Enter the Attendance Date ");
    } else if (this.state.attendence_type == "") {
      swal("Please Enter the Attendance Type ");
    } else if (this.state.spell_name == "" || this.state.spell_name == 0) {
      swal("Please Select the Spell ");
    } else if (this.state.EntryTime == "") {
      swal("Please Enter the Entry Time ");
    } else if (this.state.ExitTime == "") {
      swal("Please Enter the Exit Time ");
    } else if (
      this.state.working_hours == "" &&
      this.state.working_hours == 0
    ) {
      swal("Please Enter the Working hour ");
    } else if (this.state.idle_hours == "") {
      swal("Please Enter the Idle hours ");
    } else if (this.state.department_name == "") {
      swal("Please Select the Department ");
    } else if (this.state.designation_name == "") {
      swal("Please Select the Designation ");
    } else {
      let MachineData = [];
      this.state.machineIds.map((item) => {
        MachineData.push(item.value);
      });
      const data = {
        attendanceDate: this.state.date,
        attendanceMark: this.state.attendance_mark,
        attendanceSource: this.state.attendance_source,
        attendanceType: this.state.attendence_type,
        deviceId: 0,
        ebCode: this.state.emp_code,
        ebId: this.state.ebId,
        ebNo: this.state.emp_code,
        entryTime: this.state.EntryTime,
        exitTime: this.state.ExitTime,
        idleHours: this.state.idle_hours,
        remarks: this.state.Remarks,
        spell: this.state.spell_name,
        workedDepartmentId: this.state.department_name,
        workedDesignationId: this.state.designation_name,
        workingHours: this.state.working_hours,
        statusId: "1",
        machineIds: MachineData,
        createdBy: this.state.userDit.userId,
        companyId: localStorage.getItem("companyId"),
      };
      this.props.MarkAttendance(
        serverApi.MARK_ATTENDANCE,
        data,
        this.props.history
      );
    }
  };
  handleSelect = (selectedValue, selectedName, name) => {
    if (name === "AttendanceMark") {
      this.setState({
        attendance_mark: selectedValue,
      });
    }
    if (name === "AttendanceSource") {
      this.setState({
        attendance_source: selectedValue,
      });
    }
    if (name === "AttendanceType") {
      this.setState({
        attendence_type: selectedValue,
      });
    }
    if (name === "Spell") {
      this.setState({
        spell_name: selectedName,
      });
      this.state.spellMasterListData.map((item) => {
        if (item.spellId == selectedValue) {
          var entDate = item.startingTime + ":00";
          var date = new Date();
          var entryData = entDate.split(":", 3);
          date.setHours(Number(entryData[0]));
          date.setMinutes(Number(entryData[1]));
          date.setSeconds(Number(entryData[2]));
          const momentDate = moment(date);
          const formattedDate = momentDate.format("YYYY-MM-DD hh:mm:ss");
          let Entry = new Date(momentDate).getTime();
          const formattedDateTime = moment(Entry).format("YYYY-MM-DD HH:mm:ss");

          var exiDate = item.endTime + ":00";
          var exitDate = new Date();
          var exitData = exiDate.split(":", 3);
          exitDate.setHours(exitData[0]);
          exitDate.setMinutes(exitData[1]);
          exitDate.setSeconds(exitData[2]);
          const momentDatee = moment(exitDate);
          const formattedDatee = momentDatee.format("YYYY-MM-DD hh:mm:ss");
          let Exit = new Date(momentDatee).getTime();
          const formattedDateTimee = moment(Exit).format("YYYY-MM-DD HH:mm:ss");
          this.setState({
            entry_time: entDate,
            exit_time: exiDate,
            working_hours: item.workingHours,
          });
          this.setState({
            EntryTime: Entry,
            ExitTime: Exit,
          });
        }
      });
    }
    if (name === "Department") {
      this.setState({
        department_name: selectedValue,
        deptChange: true,
        designation_name: "",
        Preselect: true,
      });
      this.props.getAttendanceDesignation(
        serverApi.DESIGNATION +
          localStorage.getItem("companyId") +
          "/" +
          selectedValue,
        this.props.history
      );
    }
    if (name === "Designation") {
      this.setState({
        designation_name: selectedValue,
        Preselect: true,
      });
      this.props.getMachineNumber(
        serverApi.MACHINE_NUMBER +
          selectedValue +
          "/company/" +
          localStorage.getItem("companyId"),
        this.props.history
      );
    }
    if (name === "MachineNumber") {
      this.setState({
        machine_number: selectedName,
      });
    }
  };
  time = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("HH:mm:ss"),
      });
    if (id === "entry_time") {
      var entry = new Date(dateObj).getTime();

      const formattedDateTime = moment(entry).format("YYYY-MM-DD HH:mm:ss");

      this.setState({
        EntryTime: entry,
      });
    }
    if (id === "exit_time") {
      var exit = new Date(dateObj).getTime();
      this.setState({
        ExitTime: exit,
      });
    }
  };
  getMopt = (selectedValue, name) => {
    if (name === "machineIds") {
      var selectitem = selectedValue.filter((item) => {
        return item;
      });

      this.setState({
        machineIds: selectitem,
      });
    }
  };
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    var TodayDate = moment().format().split("T")[0];
    const currentDate = moment();

    const FutureDates7 = moment(currentDate).add(7, "days");
    const FutureDate7 = FutureDates7.format("YYYY-MM-DD");

    const FutureDates14 = moment(currentDate).add(14, "days");
    const FutureDate14 = FutureDates14.format("YYYY-MM-DD");
    const numberOfDays = this.state.NewMaxData;
    const futureDate = moment(currentDate).add(numberOfDays, "days");
    const FutureDateValue = futureDate.format("YYYY-MM-DD");

    const currentDatee = moment();

    const BackDates7 = moment(currentDatee).subtract(7, "days");
    const BackDate7 = BackDates7.format("YYYY-MM-DD");

    const BackDates14 = moment(currentDatee).subtract(14, "days");
    const BackDate14 = BackDates14.format("YYYY-MM-DD");
    const NoOfDays = this.state.NewMinData;
    const BackDate = moment(currentDatee).subtract(NoOfDays, "days");
    const BackDateValue = BackDate.format("YYYY-MM-DD");

    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData) {
        if (this.props.location.state.rowData.machineDetails) {
          if (this.props.location.state.rowData.machineDetails.length !== 0) {
            var machineList = [];
            this.props.location.state.rowData.machineDetails.map((item) => {
              this.state.machineList.map((data) => {
                if (item.machineId == data.value) {
                  machineList.push({
                    value: data.value,
                    label: data.label,
                    name: item.name,
                  });
                }
              });
            });
          }
        }
      }
    } else {
      var machineList = this.state.machineIds;
    }
    return (
      <>
        <div className="markAttendance">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <InputAdormentSearch
                        required
                        label={"Employee Code"}
                        placeholder="Enter here"
                        imgUrl={searchIcon}
                        handleChange={this.onhandlechange("EmpCode")}
                        onClick={this.onClickSearch}
                        value={this.state.emp_code}
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>
                          Attendance Date{" "}
                          <div className="mandatoryField">*</div>
                        </label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          minDate={
                            this.state.minimumDate == 7
                              ? BackDate7
                              : this.state.minimumDate == 14
                              ? BackDate14
                              : this.state.minimumDate == 1
                              ? BackDateValue
                              : this.state.minimumDate == 2
                              ? TodayDate
                              : ""
                          }
                          maxDate={
                            this.state.maximumDate == 7
                              ? FutureDate7
                              : this.state.maximumDate == 14
                              ? FutureDate14
                              : this.state.maximumDate == 1
                              ? FutureDateValue
                              : this.state.maximumDate == 2
                              ? TodayDate
                              : ""
                          }
                          name="Date"
                          inputFormat="dd-MM-yyyy"
                          id="financial_year"
                          value={this.state.date}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "Date")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={
                          this.props.location.state !== undefined &&
                          this.state.attendance_mark
                            ? 1
                            : 0
                        }
                        arrayOfData={attendence_mark}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelect}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.attendance_mark}
                        name="AttendanceMark"
                        label="Attendance Mark"
                        equired
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={this.state.attendance_source ? 1 : 0}
                        arrayOfData={attendence_source}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelect}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.attendance_source}
                        name="AttendanceSource"
                        label="Attendance Source"
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>

          <div className="consoleFormContainer">
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={attendence_type}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handleSelect}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          update={
                            this.props.location.state !== undefined &&
                            this.state.attendence_type
                              ? 1
                              : 0
                          }
                          selected={this.state.attendence_type}
                          name="AttendanceType"
                          label="Attendance Type"
                          required
                        />
                      </div>
                    </Box>

                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelectNew
                          update={this.state.spell_name ? 1 : 0}
                          arrayOfData={
                            this.state.spell
                              ? this.state.spell.map((item) => ({
                                  label: item.label,
                                  value: item.value,
                                  name: item.name,
                                }))
                              : ""
                          }
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handleSelect}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.spell_name}
                          name="Spell"
                          label="Spell"
                          required
                        />
                      </div>
                    </Box>

                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <div className="TextFieldBlock">
                          <label>
                            Entry Time<span className="starcolor">*</span>
                          </label>
                        </div>
                        <Datetime
                          defaultValue={new Date()}
                          closeOnSelect={true}
                          timeFormat={"HH:mm:ss"}
                          dateFormat={false}
                          onChange={(timeObj) => {
                            this.time(timeObj, "entry_time");
                          }}
                          height={"15px"}
                          formControlProps={{
                            height: "15px",
                            inlineBlock: "none",
                          }}
                          inputProps={{
                            id: "entry_time",
                            value: this.state.entry_time,
                            autoComplete: "off",
                          }}
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <div className="TextFieldBlock">
                          <label>
                            Exit Time<span className="starcolor">*</span>
                          </label>
                        </div>
                        <Datetime
                          defaultValue={new Date()}
                          closeOnSelect={true}
                          timeFormat={"HH:mm:ss"}
                          dateFormat={false}
                          onChange={(timeObj) => {
                            this.time(timeObj, "exit_time");
                          }}
                          height={"15px"}
                          formControlProps={{
                            height: "15px",
                            inlineBlock: "none",
                          }}
                          inputProps={{
                            id: "exit_time",
                            value: this.state.exit_time,
                            autoComplete: "off",
                          }}
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
            <div className="consoleFormContainer">
              <div className="consoleFormBlock">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <TextFieldNormal
                            type="Number"
                            label="Working Hours"
                            placeholder="Enter Here"
                            value={this.state.working_hours}
                            onChange={this.onhandlechange("WorkingHours")}
                            required
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <TextFieldNormal
                            type="number"
                            label="Idle Hours"
                            caps="ON"
                            placeholder="Enter here"
                            value={this.state.idle_hours}
                            onChange={this.onhandlechange("IdleHours")}
                            required
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            update={this.state.department_name ? 1 : 0}
                            arrayOfData={this.state.Department_List}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handleSelect}
                            isDropdownOpen={this.state.isDropdownOpen}
                            handleMenuOpen={this.handleMenuOpen}
                            selected={this.state.department_name}
                            name="Department"
                            label="Department"
                            required
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            update={this.state.designation_name ? 1 : 0}
                            arrayOfData={this.state.Designation}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handleSelect}
                            isDropdownOpen={this.state.isDropdownOpen}
                            handleMenuOpen={this.handleMenuOpen}
                            selected={this.state.designation_name}
                            name="Designation"
                            label="Designation"
                            required
                          />
                        </div>
                      </Box>
                    </Box>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                      className="markAttendanceContent"
                    >
                      {this.state.MachineFieldDisplay == true ? (
                        <Box gridColumn="span 3">
                          <div className="consoleTextFieldBlock">
                            <AutoMultiselect
                              options={this.state.machineList}
                              showCheckbox={true}
                              selectedValues={machineList}
                              label="Machine No"
                              name={"machineIds"}
                              getOpt={this.getMopt}
                            />
                          </div>
                        </Box>
                      ) : (
                        ""
                      )}

                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <TextFieldNormal
                            label="Remarks"
                            placeholder="Enter Here"
                            value={this.state.Remarks}
                            onChange={this.onhandlechange("Remarks")}
                          />
                        </div>
                      </Box>
                      {this.props.location.state !== undefined && (
                        <>
                          <Box gridColumn="span 3">
                            <div className="consoleTextFieldBlock">
                              <TextFieldNormal
                                label="Created By"
                                placeholder="Enter Here"
                                value={this.state.createdByDesc}
                                disabled={true}
                              />
                            </div>
                          </Box>
                          <Box gridColumn="span 3">
                            <div className="consoleTextFieldBlock">
                              <TextFieldNormal
                                label="Created On"
                                placeholder="Enter Here"
                                value={this.state.createdOn}
                                onChange={this.onhandlechange("createdOn")}
                                disabled={true}
                              />
                            </div>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </div>
              {this.state.show && (
                <CommonPopup>
                  <div className="delAddItem">
                    <div>Only Joined User can Post the Attendance</div>
                    <div className="delAddItemBtns">
                      <input
                        type="button"
                        onClick={this.onclickOk.bind(this)}
                        value="Ok!"
                        className="delYesBtn"
                      />
                    </div>
                  </div>
                </CommonPopup>
              )}
              {this.state.showData && (
                <CommonPopup>
                  <div className="delAddItem">
                    <div>No Employee found in this EB Number</div>
                    <div className="delAddItemBtns">
                      <input
                        type="button"
                        onClick={this.onclickOk.bind(this)}
                        value="Ok!"
                        className="delYesBtn"
                      />
                    </div>
                  </div>
                </CommonPopup>
              )}
              {this.props.location.state === undefined && (
                <div className="consoleFormButtonBlock">
                  <Link to="/Attendance_list">
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                    />
                  </Link>

                  <CustomButton
                    label={"Submit"}
                    className="greenButton"
                    handleClick={this.onClickNext}
                    type="sumbit"
                  />
                </div>
              )}

              {this.props.location.state !== undefined && (
                <div className="consoleFormButtonBlock">
                  <>
                    {this.state.ApproveButton && (
                      <CustomButton
                        label={"Approve"}
                        className="approved"
                        handleClick={() => this.onUpdate(1)}
                      />
                    )}
                    {this.state.HideUpdateButton && (
                      <CustomButton
                        label={"Update"}
                        className="greenButton"
                        handleClick={() => this.onUpdate("")}
                      />
                    )}
                  </>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const attendence_mark = [
  {
    value: "",
    label: "Select",
    name: "Select",
  },
  {
    value: "P",
    label: "Present",
    name: "Present",
  },
  {
    value: "L",
    label: "Late",
    name: "Late",
  },
];
const attendence_source = [
  {
    value: "",
    label: "Select",
    name: "Select",
  },
  {
    value: "M",
    label: "Manual",
    name: "Manual",
  },
  {
    value: "F",
    label: "Facial",
    name: "Facial",
    isDisabled: true,
  },
  {
    value: "B",
    label: "Biometric",
    name: "Biometric",
    isDisabled: true,
  },
];
const attendence_type = [
  {
    value: "R",
    label: "Regular",
    name: "Regular",
  },
  {
    value: "O",
    label: "Over time",
    name: "Over time",
  },
  {
    value: "C",
    label: "Cash",
    name: "Cash",
  },
];

const mapStatetoProps = (state) => {
  const {
    machine_number,
    spell,
    MarkAttendance,
    WorkerData_ByEb_No,
    AttendanceDesignation,
  } = state.AttendanceReducer;
  const { SpellMaster_List } = state.SpellMasterListReducer; // fetching spell matster list
  const { departmentList, designation, employeeList } = state.EmployeeReducer;
  return {
    departmentList,
    machine_number,
    spell,
    designation,
    MarkAttendance,
    employeeList,
    WorkerData_ByEb_No,
    SpellMaster_List,
    AttendanceDesignation,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSpell,
    getMachineNumber,
    getDepartmentListData,
    getEmployeeList,
    getAttendanceDesignation,
    MarkAttendance,
    getUpdateAttendance,
    getWorkerByEBNumber,
    getWorkerDataClearForAttendance,
    getSpellMasterList,
  })(MarkAttendence)
);
