//import { Switch } from "react-router-dom";

import moment from "moment";
import {
  API_ERRORS,
  GET_ITEM_DETAILS_BY_POID,
  GET_ITEM_DETAILS_BY_POID_SUCCESSFULL,
  INDENT_VIEW_BY_ID,
  INDENT_VIEW_BY_ID_SUCCESSFULL,
  PO_WO_LINEITEMS,
  PURCHASE_ORDER_HEADER,
  PURCHASE_ORDER_LIST,
  PURCHASE_ORDER_LIST_SUCCESSFULL,
  GET_ITEMLIST_BY_ITEM_GROUP_FOR_PURCHASE,
  GET_ITEM_LIST_BY_ITEM_GROUP_FOR_PURCHASE_SUCCESSFULL,
  GET_ITEMDETAILS_BYITEM_ITEMGRP_FOR_PURCHASE,
  GET_ITEMDETAILS_BYITEM_ITEMGRP_FOR_PURCHASE_SUCCESSFULL
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  PurchaseOrder_List: [],
  workOrder_ListColumns: [],
  workOrder_ItemsList: [],
  error_msg: null,
  PoFillDetails: {
    datevalue: moment().format().split("T", 1).toString(),
    budgetHeads: "null",
    budgetHead_value: "",
    phaseId: "",
    indentListItem: "",
    indentListItemValue: "",
    itemGroup: "",
    itemGroup_Value: "",
    branch: "",
    branchValue: "",
    boqList: "",
    boqList_value: "",
    WO_Source: "",
    WO_Source_value: "",
    Supplier: "",
    Supplier_value: "",
    Supplier_Branch: "",
    Supplier_Branch_value: "",
    supplierBranchName: "",
    Billing_Address: "",
    Billing_Address_value: "",
    Billing_state_code: "",
    Billing_state_name: "",
    shippingAddress: "",
    shippingAddress_Value: "",
    shipping_state_code: "",
    shipping_state_name: "",
    tax_payable: 1,
    tax_payable_value: 1,
    tax_type: "",
    tax_type_value: "",
    Credit_term: 60,
    delivery_timeLine: "",
    expected_date: "",
    poTillDate: moment().format().split("T", 1).toString(),
    indentId: "",
    project: "",
    project_value: "",
    client: "",
    client_value: "",
    category_type: "",
    companyName: "",
    category_type_value: "",
    RQN_name: "",
    contact_person: "",
    contact_no: "",
    advanceAmount: "",
    advancePercentage: "",
    advanceType: "",
    selectTermsNconditions: "",
    freightCharges: "",
    termsNconditions: "",
    totalAmount: 0,
    totalIgst: 0,
    totalCgst: 0,
    totalSgst: 0,
    netTotal: 0,
    status: "",
    statusDesc: null,
    statusName: "",
    poSequenceNo: "",
    footerNotes: "",
    remarks: "",
    currency: "",
    currencyoptions: [],
    branchListData: [],
    itemGrpMasterList: [],
    approvedIndentsList: [],
    budgetHeadsList: [],
    setIndentProps: false,
  },
  po_wo_lineitems: [
    {
      indentId: null,
      indentDetailsId: null,
      id: 1,
      budgetHeadId: "",
      departmentId: 15,
      departmentName:"",
      itemGroupName:"",
      budgetHeadName:"",
      itemId: "",
      itemName:"",
      itemGroup:"",
      itemGroupId: "",
      itemCode:"",
      qty: 0,
      qtyPo:0,
      qohBranch:null,
      qohCompany: null,
      rate: 0,
      tax: 0,
      taxId: 0,
      ctaxPercentage: 0,
      staxPercentage: 0,
      iTaxPercentage: 0,
      description: "",
      discountMode: "",
      discountPercentage: 0,
      hsnCode: "",
      uomCode: null,
      installationRate: 0,
      installationAmount: 0,
      make: "",
      remarks: "",
      taxPercentage:null,
      oldQty: 0,
      isActive: 1,
      amount:0,
      currentStock:0,
      minStock:0,
      maxStock:0,
      minAllowedQty: 0,
      maxAllowedQty: 0,
      reOrderQty:0,
      branchStock:0,
      tangible: "",
      poID:0,
      poLineItemID: 0,
    },
  ],
  IndentListById:[],
  ItemDetailsByPOId:[],
  itemDetails :{},
  ItemListByItemGroupAndCompanyId:[],
};

const PurchaseOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case PURCHASE_ORDER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case PURCHASE_ORDER_LIST_SUCCESSFULL:
      state = {
        ...state,
        PurchaseOrder_List: action.payload.response,
        loading: false,
      };
      break;
    case PO_WO_LINEITEMS:
      state = {
        ...state,
        po_wo_lineitems: action.payload,
      };
      break;
      case INDENT_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;

    case INDENT_VIEW_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        IndentListById: action.payload.response,
        loading: false,
      };
      break;
      case PURCHASE_ORDER_HEADER:
      state = {
        ...state,
        PoFillDetails: action.payload,
        loading: true,
      };
      break;

     case GET_ITEM_DETAILS_BY_POID:
      state = {
           ...state,
           loading: true,
     }
     break;

     case GET_ITEM_DETAILS_BY_POID_SUCCESSFULL:
      state = {
        ...state,
        ItemDetailsByPOId : action.payload.response,
        loading: false,
      }
      break;
     
      case GET_ITEMLIST_BY_ITEM_GROUP_FOR_PURCHASE: 
      state = {
           ...state,
           loading: true,
     }
     break;

     case GET_ITEM_LIST_BY_ITEM_GROUP_FOR_PURCHASE_SUCCESSFULL:
      state = {
        ...state,
        ItemListByItemGroupAndCompanyId : action.payload.response,
        loading: false,
      }
      break;
      case GET_ITEMDETAILS_BYITEM_ITEMGRP_FOR_PURCHASE: 
      state = {
           ...state,
           loading: true,
     }
     break;

     case GET_ITEMDETAILS_BYITEM_ITEMGRP_FOR_PURCHASE_SUCCESSFULL:
      state = {
        ...state,
        itemDetails : action.payload.response,
        loading: false,
      }
      break;



    case API_ERRORS:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default PurchaseOrderReducer;
