import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { COMPANIES } from "./actionTypes";
import { apiErrors, companiesListSuccessful } from "./actions";

// AUTH related methods
import { getList } from "../../../helpers/Server_Helper";

function* getCompaniesList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);

    yield put(companiesListSuccessful({ response }));
  } catch (error) {
    yield put(apiErrors(error));
  }
}

export function* watchgetCompanies() {
  yield takeEvery(COMPANIES, getCompaniesList);
}

function* BasicDetailsSaga() {
  yield all([fork(watchgetCompanies)]);
}

export default BasicDetailsSaga;
