import {
  GET_WASTAGE_ENTRY,
  GET_WASTAGE_ENTRY_SUCCESSFULL,
  WASTAGE_LIST_TYPE,
  WASTAGE_LIST_TYPE_SUCCESSFULL,
  STOCK_WASTAGE_LIST_TYPE,
  STOCK_WASTAGE_LIST_TYPE_SUCCESSFULL,
  CREATE_WASTAGE_ENTRY,
  CREATE_WASTAGE_ENTRY_SUCCESSFULL,
  DELETE_WASTAGE_ENTRY,
  DELETE_WASTAGE_ENTRY_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  wastageEntryListData: [],
  wastageTypeListData: [],
  stockWastageList: [],
  getWastageTypeListData: [],
  deleteWastageEntry: [],
};

const WastageEntryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WASTAGE_ENTRY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_WASTAGE_ENTRY_SUCCESSFULL:
      state = {
        ...state,
        wastageEntryListData: action.payload.response,
        loading: true,
      };
      break;
    case WASTAGE_LIST_TYPE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case WASTAGE_LIST_TYPE_SUCCESSFULL:
      state = {
        ...state,
        wastageTypeListData: action.payload.response,
        loading: true,
      };
      break;
    case STOCK_WASTAGE_LIST_TYPE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case STOCK_WASTAGE_LIST_TYPE_SUCCESSFULL:
      state = {
        ...state,
        stockWastageList: action.payload.response,
        loading: true,
      };
      break;
    case CREATE_WASTAGE_ENTRY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_WASTAGE_ENTRY_SUCCESSFULL:
      state = {
        ...state,
        getWastageTypeListData: action.payload.response,
        loading: true,
      };
      break;
    case DELETE_WASTAGE_ENTRY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_WASTAGE_ENTRY_SUCCESSFULL:
      state = {
        ...state,
        deleteWastageEntry: action.payload.response,
        loading: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default WastageEntryReducer;
