export const JUTE_BILL_PASS_LIST = "jute_bill_pass_list";
export const JUTE_BILL_PASS_LIST_SUCCESSFULL =
  "jute_bill_pass_list_successfull";
export const JUTE_BILL_PASS_VIEW_BY_ID = "jute_bill_pass_view_By_Id";
export const JUTE_BILL_PASS_VIEW_BY_ID_SUCCESSFULL =
  "jute_bill_pass_view_By_Id_successfull";

export const GET_FILES_OF_BILLPASS = "get_files_of_billPass";
export const GET_FILES_OF_BILLPASS_SUCCESSFULL =
  "get_files_of_billPass_successfull";
export const GET_FILES_OF_INVOICE = "get_files_of_invoice";
export const GET_FILES_OF_CHALLAN = "get_files_of_challan";
export const GET_FILES_OF_SUPPDOCS = "get_files_of_suppdocs";
export const UPDATE_JUTE_BILL_PASS = "update_jute_billpass";
export const UPDATE_JUTE_BILL_PASS_SUCCESSFULL =
  "update_jute_billpass_successfull";

export const JUTE_BILL_PASS_HEADER = "jute_bill_pass_header";
export const CLEAR_JUTE_PASS_HEADER = "clear_jute_pass_header";

export const BILLPASS_LINE_ITEMS = "bill_pass_line_items";
export const CLEAR_BILLPASS_LINE_ITEMS = "clear_BILL_pass_line_items";

export const INVOICE_UPLOAD_FILES = "invoice_upload_files";
export const INVOICE_UPLOAD_FILES_SUCCESSFULL =
  "invoice_upload_files_successfull";

export const SUPPORT_UPLOAD_FILES_SUCCESSFULL =
  "support_upload_files_successfull";
export const CHALLAN_UPLOAD_FILES_SUCCESSFULL =
  "challan_upload_files_successfull";

export const GET_INVOICE_FILES = "get_invoice_files";
export const GET_INVOICE_FILES_SUCCESSFULL = "get_invoice_files_successfull";

export const GET_SUPPORT_FILES = "get_support_files";
export const GET_SUPPORT_FILES_SUCCESSFULL = "get_support_files_successfull";

export const GET_CHALLAN_FILES = "get_challan_files";
export const GET_CHALLAN_FILES_SUCCESSFULL = "get_challan_files_successfull";
