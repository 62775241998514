import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import { serverApi } from "../../../helpers/Consts";
import {
  createItemGroupMaster,
  updateItemGroupMaster,
  getViewById,
  getItemGroupDropdownList,
} from "../../../store/Master/ItemGroupMaster/actions";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import {
  getDepartmentList,
  BudgetHeads,
  ItemGrpMasterList,
  allItemGroupMasterList,
} from "../../../store/Global/DropDownApis/actions";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import AutoMultiselect from "../../../components/Dropdown/AutoMultiSelectNew";
import { getItemGroupMasterList } from "../../../store/Master/ItemGroupMaster/actions";
import swal from "sweetalert";
import { validate } from "../../../Utilities/Validations";

class CreateItemGroupMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      id: "",
      itemGroup: "",
      itemGroupCode: "",
      itemCodeDescription: "",
      budgetHeadsValue: "",
      budgetHeads: "",
      department: "",
      departmentListValue: "",
      createdBy: null,
      createdOn: "",
      departmentList: [],
      budgetHeadsList: [],
      selectedBudgetHeads: [],
      selectedDeptList: [],
      DepartmentList: [],
      upbudjetheads: [],
      CompanyList: [],
      company_Id: "",
      itemGroupListData: [],
      itemGroup_status: "",
      locationState: "",
      parentGroupId: null,
      primary: "1",
      itemGroupData: [],
      ItemGroupMasterList: [],
      type: "1",
    };
  }

  onClickCancel = () => {
    this.props.history.push("/item_group_master");
  };

  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "itemGroupCode") {
      this.setState({
        itemGroupCode: event,
      });
    }
    if (key === "itemCodeDescription") {
      this.setState({
        itemCodeDescription: event,
      });
    }
  };
  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "companyid") {
      this.setState({
        company_Id: selectedValue,
      });
      if(this.state.company_Id !==""){
      this.setState({
        itemGroup:"",
        itemGroupCode:"",
        itemCodeDescription:"",
        selectedBudgetHeads: [],
        selectedDeptList: [],
        parentGroupId:"",
        primary:"",
      })
      }
      const data = {
        cipher: this.state.userDit.cipher,
        companyId: selectedValue,
        userId: this.state.userDit.userId,
        startIndex: this.state.startIndex,
        lastIndex: this.state.lastIndex,
      };
      this.props.getItemGroupMasterList(
        serverApi.GET_ALL_ITEM_GROUP_LIST,
        data,
        this.props.history
      );
    }
    if (name === "itemGroup") {
      this.setState({
        itemGroup: Number(selectedValue),
      });

      this.state.itemGroupListData.map((item) => {
        if (selectedValue === item.groupId) {
          var data1 = {
            postAuthenticationDto: {
              userId: this.state.userDit.userId,
              cipher: this.state.userDit.cipher,
            },
            itemGrpDtl: {
              id: item.id,
              companyId: this.state.company_Id,
              createdBy: this.state.createdBy,
              createdOn: this.state.createdOn,
            },
          };
          this.props.getViewById(
            serverApi.ITEM_GROUP_VIEW_BY_ID,
            data1,
            this.props.history
          );
        }
      });
    }
    if (name === "status") {
      this.setState({
        itemGroup_status: selectedValue,
      });
    }
    if (name === "parentGroupId") {
      this.setState({
        parentGroupId: selectedValue,
      });
    }

    if (name === "primary") {
      if (selectedValue === "1") {
        this.setState({
          parentGroupId: null,
        });
      }
      this.setState({
        primary: selectedValue,
      });
    }
  };
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    this.setState({
      locationState: this.props.location,
    });
    window.addEventListener("scroll", this.handleScroll);
    this.props.getDepartmentList(
      serverApi.GET_DEPARTMENT_LIST +
        this.state.companyId +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    );
    this.props.BudgetHeads(
      serverApi.GET_BUDGET_HEADES + this.state.companyId,
      this.state.history
    );

    this.props.allItemGroupMasterList(
      serverApi.GET_ALL_ITEMGROUP_MASTER_LIST +
        localStorage.getItem("companyId") +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    );

    if (this.props.location.state) {
      if (this.props.location.state.rowData) {
        var data1 = {
          postAuthenticationDto: {
            userId: this.state.userDit.userId,
            cipher: this.state.userDit.cipher,
          },
          itemGrpDtl: {
            id: this.props.location.state.rowData.id,
            companyId: this.state.companyId,
          },
        };
        this.props.getViewById(
          serverApi.ITEM_GROUP_VIEW_BY_ID,
          data1,
          this.props.history
        );
      }
    }

    const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    function generateString(length) {
      let result = "";
      const charactersLength = 20;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    }

    let code = generateString(5);
    this.setState({
      itemGroupCode: code,
    });
    let companyDetails = this.state.userDit.companyDetails.map((item) => ({
      label: item.label,
      value: item.value,
      name: item.label,
    }));
    this.setState({
      CompanyList: companyDetails,
    });
  }
  componentWillReceiveProps(props) {
    if (props.ItemGroup_List) {
      if (props.ItemGroup_List.data) {
        this.setState({
          itemGroupListData: props.ItemGroup_List.data,
        });
      }
    }
    if (props.departmentList.data) {
      var listdept = [];
      listdept = props.departmentList.data.filter((item) => {
        if (item.value !== 0) {
          return item;
        }
      });

      this.setState({
        departmentList: listdept,
      });
    }

    if (props.budgetHeadsList.data) {
      var list = [];
      list = props.budgetHeadsList.data.filter((item) => {
        if (item.value !== "0") {
          return item;
        }
      });

      this.setState({
        budgetHeadsList: list,
      });
    }

    if (props.allItemGroupMaster_List) {
      if (props.allItemGroupMaster_List.data) {
        let list = props.allItemGroupMaster_List.data.filter((prop) => {
          if (prop.value !== "0") {
            return prop;
          }
        });
        this.setState({
          ItemGroupMasterList: list, // updating state name list dropdown
        });
      }
    }

    if (this.props.location.state || this.state.itemGroup !== "") {
      if (props.getViewByIdresp.data) {
        let depts = [];
        let budget = [];
        depts = this.state.department;
        budget = this.state.budgetHeads;
        if (props.getViewByIdresp.data.itemGrpDtl) {
          this.setState({
            itemGroupCode: props.getViewByIdresp.data.itemGrpDtl.id,
            itemCodeDescription: props.getViewByIdresp.data.itemGrpDtl.grpDsc,
            createdBy: props.getViewByIdresp.data.itemGrpDtl.createdBy,
            createdOn: props.getViewByIdresp.data.itemGrpDtl.createdOn,
            itemGroup_status:
              props.getViewByIdresp.data.itemGrpDtl.inactiveTag === "Y"
                ? "1"
                : "2", //itemGroup_status
            parentGroupId: this.props.location.state
              ? props.getViewByIdresp.data.itemGrpDtl.parentGroupId
              : null,
            primary:
              this.props.location.state &&
              props.getViewByIdresp.data.itemGrpDtl.parentGroupId
                ? "2"
                : "1",
            department: depts,
            budgetHeads: budget,
          });
        }
      }

      ///

      let budgetlist = [];
      let deptlist = [];
      if (this.props.location.state || this.state.itemGroup !== "") {
        if (this.props.getViewByIdresp) {
          if (props.getViewByIdresp.data) {
            if (props.getViewByIdresp.data.budgetHeads) {
              props.getViewByIdresp.data.budgetHeads.map((arr) => {
                this.state.budgetHeadsList.map((p, i) => {
                  if (arr.budgetHeadId === Number(p.value))
                    budgetlist.push({
                      name: p.name,
                      value: p.value,
                      label: p.label,
                    });
                });
              });
              this.setState({
                selectedBudgetHeads: budgetlist,
              });
            }
            if (props.getViewByIdresp.data.itemGrpDpt) {
              props.getViewByIdresp.data.itemGrpDpt.map((arr) => {
                this.state.departmentList.map((p, i) => {
                  if (arr.deptId === Number(p.value))
                    deptlist.push({
                      name: p.name,
                      value: p.value,
                      label: p.label,
                    });
                });
              });
              this.setState({
                selectedDeptList: deptlist,
              });
            }
          }
        }
      }
    }
  }

  onSelectChange = (val1, val2, val3) => {};
  getMopt = (selectedValue, name) => {
    if (name === "departmentListData") {
      let newDepts = [];
      if (selectedValue) {
        selectedValue.map((arr) => {
          this.state.departmentList.map((p, i) => {
            if (arr.value === Number(p.value))
              newDepts.push({
                name: p.name,
                value: p.value,
                label: p.label,
              });
          });
        });
      }

      this.setState({
        selectedDeptList: newDepts,
      });
    }

    if (name === "budgetheads") {
      let newBudget = [];
      if (selectedValue) {
        selectedValue.map((arr) => {
          this.state.budgetHeadsList.map((p, i) => {
            if (arr.value === p.value)
              newBudget.push({
                name: p.name,
                value: p.value,
                label: p.label,
              });
          });
        });
      }
      this.setState({
        selectedBudgetHeads: newBudget,
      });
    }
  };

  onClickCreateUpdate = () => {
    const {
      itemGroupCode,
      itemCodeDescription,
      selectedBudgetHeads,
      selectedDeptList,
    } = this.state;

    const validateFields = [
      { field: itemGroupCode, msg: "Item Group Code", type: 1 },
      { field: itemCodeDescription, msg: "Item Group Description", type: 1 },
      { field: selectedDeptList, msg: "Department List", type: 2 },
    ];
    let validObj = validate(validateFields);
    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message}`);
      }
      if (validObj.type === 2) {
        swal(`Please Select ${validObj.message}`);
      }
    } else {
      var selectedDeptLists = selectedDeptList.filter(
        (item) => item.value != 0
      );
      var selectedBudgetHead = selectedBudgetHeads.filter(
        (item) => item.value != 0
      );
      if (this.props.location.state === undefined) {
        const data = {
          itemGrpDtl: {
            id: this.state.itemGroupCode,
            grpDsc: this.state.itemCodeDescription,
            companyId: this.state.companyId,
            inactiveTag: this.state.itemGroup_status === "2" ? "N" : "Y",
            parentGroupId: this.state.parentGroupId,
          },
          itemGrpDpt: selectedDeptLists.map((item) => {
            return { deptId: item.value, isMapped: true };
          }),
          budgetHeads: selectedBudgetHead.map((item) => {
            return { budgetHeadId: item.value, isMapped: true };
          }),
          postAuthenticationDto: {
            userId: this.state.userDit.userId,
            cipher: this.state.userDit.cipher,
          },
        };
        this.props.createItemGroupMaster(
          serverApi.ADD_ITEM_GROUP,
          data,
          this.props.history
        );
      } else {
        const data = {
          itemGrpDtl: {
            id: this.props.location.state.rowData.id,
            grpDsc: this.props.location.state.rowData.grpDsc,
            companyId: this.state.companyId,
            groupId: this.props.location.state.rowData.groupId,
            inactiveTag: this.state.itemGroup_status === "2" ? "N" : "Y",
            parentGroupId: this.state.parentGroupId,
          },
          itemGrpDpt: selectedDeptLists.map((item) => {
            return { deptId: item.value, isMapped: true };
          }),
          budgetHeads: selectedBudgetHead.map((item) => {
            return { budgetHeadId: item.value, isMapped: true };
          }),
          postAuthenticationDto: {
            userId: this.state.userDit.userId,
            cipher: this.state.userDit.cipher,
          },
        };
        this.props.updateItemGroupMaster(
          serverApi.UPDATE_ITEM_GROUP,
          data,
          this.props.history
        );
      }
    }
  };
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.
    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div>
        {this.state.locationState.state == undefined && (
          <div className="consoleFormContainer">
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.CompanyList}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.company_Id}
                          update={this.state.company_Id ? 1 : 0}
                          name="companyid"
                          label="Company List"
                        />
                      </div>
                    </Box>

                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={
                            this.state.itemGroupListData &&
                            this.state.itemGroupListData.map((item) => ({
                              label: item.grpDsc,
                              name: item.grpDsc,
                              value: item.groupId,
                            }))
                          }
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.itemGroup}
                          update={this.state.itemGroup ? 1 : 0}
                          name="itemGroup"
                          label="Previous Item Groups"
                          isDisabled={
                            this.state.company_Id === "" ||
                            this.state.company_Id === null
                          }
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.itemGroupCode}
                      caps="OFF"
                      minValue="0"
                      onChange={this.onhandlechangeValue("itemGroupCode")}
                      label="Item Group Code"
                      required
                      disabled={this.state.locationState.state !== undefined}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.itemCodeDescription}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("itemCodeDescription")}
                      label="item Group Description"
                      type="text"
                      disabled={
                        this.state.itemGroupCode === ""
                          ? true
                          : false && this.state.itemGroup === ""
                          ? true
                          : false ||
                            this.state.locationState.state !== undefined
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <AutoMultiselect
                        options={this.state.budgetHeadsList} // Options to display in the dropdown
                        selectedValues={this.state.selectedBudgetHeads} // Preselected value to persist in dropdown
                        name={"budgetheads"}
                        getOpt={this.getMopt}
                        label="Budget Heads"
                        disable={
                          this.state.itemCodeDescription === ""
                            ? true
                            : false && this.state.itemGroup === ""
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <AutoMultiselect
                        options={this.state.departmentList} // Options to display in the dropdown
                        showCheckbox={true}
                        selectedValues={this.state.selectedDeptList} // Preselected value to persist in dropdown
                        name={"departmentListData"}
                        getOpt={this.getMopt}
                        label="Department List"
                        mandatory="*"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelect
                      update={this.props.location.state ? 1 : 0}
                      arrayOfData={PrimaryData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.primary}
                      name="primary"
                      label="Primary"
                      required
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelect
                      update={this.state.parentGroupId ? 1 : 0}
                      arrayOfData={this.state.ItemGroupMasterList}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.parentGroupId}
                      name="parentGroupId"
                      label="Parent Group"
                      isDisabled={this.state.primary === "1"}
                    />
                  </Box>

                  {this.state.locationState.state !== undefined ? (
                    <Box gridColumn="span 3">
                      <DynamicSelect
                        update={this.state.itemGroup_status ? 1 : 0}
                        arrayOfData={Data}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.itemGroup_status}
                        name="status"
                        label="Status"
                      />
                    </Box>
                  ) : (
                    ""
                  )}
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <>
                      <Box gridColumn="span 6">
                        <div className="consoleUpdateTextBlock">
                          <div>
                            <span className="taxLabel">
                              Created By : <b>{this.state.createdBy}</b>
                            </span>
                          </div>
                          <div>
                            <span className="taxLabel">
                              Created On :<b> {this.state.createdOn}</b>
                            </span>
                          </div>
                        </div>
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.state.locationState.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}
const Data = [
  {
    value: "1",
    label: "Active",
    name: "Active",
  },
  {
    value: "2",
    label: "Inactive",
    name: "Inactive",
  },
];
const PrimaryData = [
  {
    value: "1",
    label: "Primary",
    name: "Primary",
  },
  {
    value: "2",
    label: "Additional",
    name: "Additional",
  },
];

const mapStatetoProps = (state) => {
  const { getViewByIdresp, ItemGroup_List, ItemGroupList } =
    state.ItemGroupListReducer;
  const { departmentList, budgetHeadsList, allItemGroupMaster_List } =
    state.DropDownListReducer;
  return {
    departmentList,
    budgetHeadsList,
    getViewByIdresp,
    ItemGroup_List,
    allItemGroupMaster_List,
    ItemGroupList,
  };
};
export default connect(mapStatetoProps, {
  getDepartmentList,
  createItemGroupMaster,
  BudgetHeads,
  updateItemGroupMaster,
  getViewById,
  getItemGroupMasterList,
  getItemGroupDropdownList,
  ItemGrpMasterList,
  allItemGroupMasterList,
})(withRouter(CreateItemGroupMaster));
