import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/Buttons/Buttons";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import {useSelector } from "react-redux";
import { isEmpty } from "../../../Utilities/helper";
import moment from "moment";
const JuteIndentPreview = (props) => {
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [gstNo, setGstNo] = useState("");
  const [HeaderData, setHeaderData] = useState({});
  const [LineItems, setLineItems] = useState([]);
  const {JuteindentLineItems ,JuteIndentHeader} = useSelector(
    (state) => state.JuteIndentReducer
  );
  const [date, setDate] = useState(moment().format());
  var DateTime = date.split("T", 2);
  var TodayDate = moment(DateTime[0], "YYYY-MM-DD", true).format("DD-MM-YYYY");
  var Time = DateTime[1].split("+")[0];
  useEffect(() => {
    let companyData = JSON.parse(localStorage.getItem("authUser"));
    let companyId = localStorage.getItem("companyId");
    companyData.companyDetails.map((p) => {
      let id = p.value;
      if (companyId === id.toString()) {
        let companyName = p.label.split("-");
        setCompanyLogo(p.companyLogo);
        setCompanyName(p.label);
        setGstNo(p.gstNo);
      }
    });
  }, []);

  useEffect(() => {
    setLineItems(JuteindentLineItems);
    setHeaderData(JuteIndentHeader);
  }, [JuteIndentHeader, JuteindentLineItems]);

  var juteIndentLineItem = JuteindentLineItems.filter(
    (row) => row.isActive !== 0 && !isEmpty(row.itemId)
  );
  console.log(juteIndentLineItem, "juteIndentLineItem");
  const handleEdit = () => {
    props.handleCurrentStep(1);
  };
  var indentDate = ""
  if(!isEmpty(JuteIndentHeader.indentDate)){
     indentDate = moment(JuteIndentHeader.indentDate, "YYYY-MM-DD", true).format("DD-MM-YYYY")
  }else{
    indentDate =""
  }
  return (
    <>
      <>
        <div className="previewBlock">
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              padding: "10px",
            }}
          >
            {/* {props.location.state && (
              <CustomButton
                label="Print"
                type="button"
                className="lightBlueButton"
                muIcon={<PrintIcon />}
              />
            )} */}
            {HeaderData.EditButton ? (
              <CustomButton
              handleClick={handleEdit}
                label="Edit"
                type="button"
                className="lightBlueButton"
                muIcon={<EditIcon />}
              />
            ) : (
              ""
            )
            }
          </div>
          <table
            cellpadding="0"
            cellspacing="0"
            width="90%"
            className="previewTable"
          >
            <tbody>
              <tr>
                <td
                  colspan="3"
                  style={{ borderRight: "none", borderBottom: "none" }}
                >
                  <img src={companyLogo} width="100%" alt="" />
                </td>
                <td
                  colspan="6"
                  style={{
                    borderBottom: "none",
                    borderLeft: "none",
                    textAlign: "right",
                  }}
                >
                  <p style={{ margin: "0px" }}>
                    <b>{companyName}</b>
                  </p>
                  <span>{gstNo}</span>
                </td>
              </tr>
              <tr>
                <td
                  colspan="10"
                  style={{ textAlign: "center", borderTop: "none" }}
                >
                  <h2>{"INDENT"}</h2>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Indent No: </b>
                </td>
                <td colspan="3" 
                className={isEmpty(HeaderData.indentHeaderId)? "blur-text" : ""}> {isEmpty(HeaderData.indentHeaderId)
                  ? "Will be generated after creation"
                  : HeaderData.indentHeaderId}</td>
                <td colspan="1">
                  <b>Indent Date : </b>
                </td>
                <td colspan="2">{indentDate}</td>
              </tr>
              {/* <tr>
                <td colspan="2">
                  <b>Indent Type</b>
                </td>
                <td colSpan="5">{"Indent"}</td>
              </tr> */}
              <tr>
                <td colSpan="1">
                  <b>S.No</b>
                </td>
                <td width="13%">
                  <b>Jute Type</b>
                </td>
                <td width="20%">
                  <b>Quality</b>
                </td>
                <td width="15%">
                  <b>Quantity %</b>
                </td>
                <td width="20%">
                  <b>Quantity</b>
                </td>
                <td width="13%">
                  <b>Unit</b>
                </td>
                <td width="13%">
                  <b>Stock</b>
                </td>
              </tr>

              {juteIndentLineItem &&
                juteIndentLineItem.map((data, index) => (
                  <tr>
                    <td style={{ textAlign: "right" }}>{index + 1}</td>
                    <td>{data.itemIdDesc}</td>
                    <td>{data.qualityCodeDesc}</td>
                    <td>{data.noOfBales}</td>
                    <td style={{ textAlign: "left" }}>{data.indentQuantity}</td>
                    <td style={{ textAlign: "left" }}>{data.unitId}</td>
                    <td style={{ textAlign: "left" }}>{data.stock}</td>
                  </tr>
                ))}
              <tr>
                <td
                  colspan="10"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    marginTop: "20px",
                  }}
                ></td>
              </tr>
              <tr>
                <td
                  colspan="10"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                  </div>
                </td>
              </tr>

              <tr>
                <td
                  style={{ borderTop: "none", borderBottom: "none" }}
                  colspan="10"
                >
                  <b>
                    Note* : This is a computer generated print, Signature is not
                    required.
                  </b>
                  <br></br>
                  <b>
                    1. This indent copy is made only for our office use, no
                    other outside persons are entitled to see that.
                  </b>
                  <br></br>
                  <b>
                    2. Please make sure the order item code and unit are tally
                    with the indent.
                  </b>
                  <br></br>
                  <b>
                    3. Indent Serial No is the unique number for all the indents
                    of that particular financial year.
                  </b>
                </td>
              </tr>
              <tr>
                <td
                  colspan="5"
                  style={{ borderTop: "none", borderRight: "none" }}
                >
                  Printed on:  {TodayDate} {Time}
                </td>
                <td
                  colspan="5"
                  align="right"
                  style={{ borderTop: "none", borderLeft: "none" }}
                >
                  Printed By: {userDit.userName}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    </>
  );
};

export default JuteIndentPreview
