export const BRANCH_MASTER_LIST = "branch_master_list"; 
export const BRANCH_MASTER_LIST_SUCCESSFULL = "branch_master_list_successfull";
export const CREATE_BRANCH_MASTER = "create_branch_master";
export const CREATE_BRANCH_MASTER_SUCCESSFULL = "create_branch_master_successfull";
export const UPDATE_BRANCH_MASTER = "update_branch_master";
export const UPDATE_BRANCH_MASTER_SUCCESSFULL = "update_branch_master_successfull";
export const API_ERRORS = 'api_failed';

export const DELETE_BRANCH_FILES="delete_branch_files";
export const DELETE_BRANCH_FILES_SUCCESSFULL="delete_branch_files_successfull";

export const GET_ALL_FILES="get_all_files";
export const GET_ALL_FILES_SUCCESSFULL = "get_all_files_successfull";

export const UPLOAD_BRANCH_FILES='upload_branch_files';
export const UPLOAD_BRANCH_FILES_SUCCESSFULL='upload_branch_files_successfull'