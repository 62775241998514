import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../../../components/Dropdown/DynamicSelect";
import TextFieldNormal from "../../../../../components/TextField/TextFieldNormal";
import { Link } from "react-router-dom";
import CustomButton from "../../../../../components/Buttons/Buttons";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  serverApi,
  showSpinner,
  hideSpinner,
} from "../../../../../helpers/Consts";
import moment from "moment";
import {
  PaySchemeById,
  checkPayScheme,
  getPayschemeList,
  getPayScheme,
  addSalary,
  updateSalary,
  PaySchemeViewById,
  clearCheckScheme,
  PayComponents,
} from "../../../../../store/HRMS/EmployeeDataBase/actions";
import swal from "sweetalert";
class SalaryStructure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      ebId: this.props.getEbId,
      payHead: [],
      payLinItems: [],
      empPaySchemeId: "",
      checkCal: false,
      effective_date: moment().format(),
      display: false,
      InputValues: [],
      earningList: [],
      deductionList: [],
      summary: [],
      paySchemeName: "",
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    const data = {
      activeStatus: "1",
      status: "32",
      orgId: JSON.parse(localStorage.getItem("authUser")).orgId,
    };
    ///To get the payschemes for payScheme dropdown.......
    this.props.getPayScheme(
      serverApi.EMPLOYEE_PAY_SCHEME,
      data,
      this.props.history
    );
    //To the Employee payscheme data By payscheme Id
    if (this.props.getEbId) {
      this.props.PaySchemeViewById(
        serverApi.VIEW_EMPLOYEE_SALARY_DIT + "/" + this.state.ebId
      );
    }
    ///To get the Components Names we are using this API here......
    this.props.PayComponents(serverApi.PAY_COMPONENTS, this.props.history);
  }

  componentWillReceiveProps(props) {
    ///for payScheme dropdown.......
    if (props.payScheme_List) {
      this.setState({
        payScheme: props.payScheme_List,
      });
    }

    //If the Employee is having already the salary structure this code will execute other wise it will go to else part
    if (props.SalaryStructureList && this.state.checkCal === false) {
      if (props.SalaryStructureList.data !== undefined) {
        if (props.SalaryStructureList.data.EmployeePayscheme) {
          if (props.paySchemeByIdList) {
            if (props.paySchemeByIdList.data) {
              if (props.paySchemeByIdList.data.paySchemeDetailsDTO) {
                if (
                  props.paySchemeByIdList.data.paySchemeDetailsDTO.length !== 0
                ) {
                  var InputValueslist = [];
                  var earningList = [];
                  var deductionList = [];
                  var summary = [];
                  //viewBYID
                  props.SalaryStructureList.data.EmployeePayscheme.payEmployeeStructureDTO.map(
                    (Item) => {
                      this.setState({
                        empPaySchemeId: Item.id,
                      });
                      if (this.state.paySchemeName === "") {
                        this.setState({
                          paySchemeName: Item.payschemeId,
                        });
                      }
                    }
                  );
                  if (props.payComponents_list) {
                    if (props.payComponents_list.data) {
                      if (props.payComponents_list.data.length !== 0) {
                        props.paySchemeByIdList.data.paySchemeDetailsDTO.map(
                          (data) => {
                            props.payComponents_list.data.map((item) => {
                              if (data.componentId === item.id) {
                                let obj;
                                if (data.type === "0") {
                                  obj = {
                                    id: data.id,
                                    componentId: item.id,
                                    componentName: data.componentName,
                                    //formula is noty updating......
                                    // formula: data.formula,
                                    formula: this.getAmountFromViewById(
                                      data.componentId,
                                      props.SalaryStructureList.data
                                        .EmployeePayscheme
                                        .payEmployeeStructureDTO,
                                      "formula"
                                    ),
                                    amount: this.getAmountFromViewById(
                                      data.componentId,
                                      props.SalaryStructureList.data
                                        .EmployeePayscheme
                                        .payEmployeeStructureDTO,
                                      "amount"
                                    ),
                                    type: data.type,

                                    isActive: item.status,
                                  };
                                } else {
                                  obj = {
                                    id: data.id,
                                    componentId: item.id,
                                    componentName: data.componentName,
                                    //formula is noty updating......
                                    formula: data.formula,
                                    amount: this.getAmountFromViewById(
                                      data.componentId,
                                      props.SalaryStructureList.data
                                        .EmployeePayscheme
                                        .payEmployeeStructureDTO,
                                      "amount"
                                    ),
                                    type: data.type,
                                    isActive: item.status,
                                  };
                                }
                                if (data.type === "0") {
                                  InputValueslist.push(obj);
                                }
                                if (data.type === "1") {
                                  earningList.push(obj);
                                }
                                if (data.type === "2") {
                                  deductionList.push(obj);
                                }
                                if (data.type === "3") {
                                  summary.push(obj);
                                }

                                this.setState({
                                  InputValues: InputValueslist,
                                  earningList: earningList,
                                  deductionList: deductionList,
                                  summary: summary,
                                  display: true,
                                });
                              }
                            });
                          }
                        );
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
          this.setState({
            display: false,
            InputValues: [],
            earningList: [],
            deductionList: [],
            summary: [],
            paySchemeName: "",
          });
        }
      }
    }
    // if the user not having salary stucture then this code will execute and update the state with response value
    if (
      props.checkScheme &&
      this.state.checkCal === false &&
      this.state.paySchemeName !== ""
    ) {
      if (props.checkScheme.list !== null) {
        if (props.checkScheme.list) {
          if (props.paySchemeByIdList) {
            if (props.paySchemeByIdList.data) {
              if (props.paySchemeByIdList.data.paySchemeDetailsDTO) {
                let InputValueslist = [];
                let earningList = [];
                let deductionList = [];
                let summary = [];
                props.checkScheme.list.map((item) => {
                  props.paySchemeByIdList.data.paySchemeDetailsDTO.map(
                    (data) => {
                      if (item.componentId === data.componentId) {
                        let obj = {
                          id: data.id,
                          componentId: item.componentId,
                          componentName: data.componentName,
                          amount: item.amount,
                          formula: data.formula,
                          type: data.type,
                          isActive: data.status,
                        };
                        if (data.type === "0") {
                          InputValueslist.push(obj);
                        }
                        if (data.type === "1") {
                          earningList.push(obj);
                        }
                        if (data.type === "2") {
                          deductionList.push(obj);
                        }
                        if (data.type === "3") {
                          summary.push(obj);
                        }
                        this.setState({
                          InputValues: InputValueslist,
                          earningList: earningList,
                          deductionList: deductionList,
                          summary: summary,
                          display: true,
                        });
                      }
                    }
                  );
                });
              }
            }
          }
        }
      } else {
        this.setState({
          InputValues: [],
          earningList: [],
          deductionList: [],
          summary: [],
          display: false,
        });
      }
    }

    // if already user is having salary structure and if we are trying or updating the input values then at that time this code will execute and upadte the state
    if (props.checkScheme && this.state.checkCal === true) {
      if (props.checkScheme !== null) {
        if (props.checkScheme.list !== null) {
          if (props.checkScheme.list.length !== 0) {
            let paySchemeListInputValues = [];
            let paySchemeListEarnings = [];
            let paySchemeListDeductions = [];
            let paySchemeListSummary = [];
            paySchemeListInputValues = this.state.InputValues.map((data) => {
              let amount = 0;
              props.checkScheme.list.map((item) => {
                if (item.componentId == data.componentId) {
                  amount = item.amount;
                }
              });
              return { ...data, amount: amount };
            });
            paySchemeListEarnings = this.state.earningList.map((data) => {
              let amount = 0;
              props.checkScheme.list.map((item) => {
                if (data.componentId == item.componentId) {
                  amount = item.amount;
                }
              });
              return { ...data, amount: amount };
            });
            paySchemeListDeductions = this.state.deductionList.map((data) => {
              let amount = 0;

              props.checkScheme.list.map((item) => {
                if (data.componentId == item.componentId) {
                  amount = item.amount;
                }
              });
              return { ...data, amount: amount };
            });
            paySchemeListSummary = this.state.summary.map((data) => {
              let amount = 0;
              props.checkScheme.list.map((item) => {
                if (data.componentId == item.componentId) {
                  amount = item.amount;
                }
              });
              return { ...data, amount: amount };
            });
            this.setState({
              InputValues: paySchemeListInputValues,
              earningList: paySchemeListEarnings,
              deductionList: paySchemeListDeductions,
              summary: paySchemeListSummary,
            });
            this.props.clearCheckScheme(null);
          }
        }
      } else {
      }
    }
  }

  getAmountFromViewById = (ItemId, ViewByIDList, type) => {
    if (ViewByIDList) {
      if (ItemId) {
        let amount = 0;
        ViewByIDList.map((obj) => {
          if (obj.componentId == ItemId) {
            amount = obj.amount;
          }
        });
        if (type === "formula") {
          return amount.toString();
        } else {
          return amount;
        }
      }
    }
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "payScheme") {
      this.props.PaySchemeById(
        serverApi.PAY_SCHEME_BY_ID + selectedValue,
        selectedValue,
        this.props.history
      );
      this.setState({
        paySchemeName: selectedValue,
        checkCal: false,
      });
    }
  };
  handleSelectDate = (e, name) => {
    if (name === "EffectiveDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();

      this.setState({
        effective_date: Date,
      });
    }
  };
  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });

    var result = [...this.state.InputValues];
    result = result.map((x) => {
      if (x.componentId === key) {
        x.amount = event;
        if (event === "") {
          let FormulaNum = Number(event);

          x.formula = FormulaNum.toString();
        } else {
          let FormulaNum = Number(event);

          x.formula = FormulaNum.toString();
        }
      }
      return x;
    });
    this.setState({
      InputValues: result,
    });
  };

  onUpdate = () => {
    if (this.state.paySchemeName == "") {
      swal("Please Select the Pay Scheme");
    } else if (this.state.effective_date == "") {
      swal("Please Select the Effective Date");
    } else {
      // showSpinner();
      let payEmployeeStructureDTO = [
        ...this.state.earningList,
        ...this.state.deductionList,
        ...this.state.InputValues,
        ...this.state.summary,
      ];
      if (this.state.effective_date !== "") {
        var effective_date = this.state.effective_date.split(" ");
        var lastDate = moment(effective_date[0]).format("DD-MM-YYYY");
      }
      payEmployeeStructureDTO = payEmployeeStructureDTO.map((prop) => {
        var obj = {
          amount: prop.amount,
          componentId: prop.componentId,

          employeeid: this.props.getEbId,
          endsOn: "",
          payschemeId: this.state.paySchemeName,
          remarks: "",
        };
        return obj;
      });
      var data = {
        payEmployeeDTO: {
          id: this.state.empPaySchemeId,
          employeeid: this.props.getEbId,
          paySchemeId: this.state.paySchemeName,
        },
        payEmployeeStructureDTO: payEmployeeStructureDTO,
      };
      this.props.updateSalary(
        serverApi.UPDATE_SALARY,
        data,
        this.props.history
      );
    }
  };
  onCreateData = () => {
    if (this.state.paySchemeName == "") {
      swal("Please Select the Pay Scheme");
    } else if (this.state.effective_date == "") {
      swal("Please Select the Effective Date");
    } else {
      let payEmployeeStructureDTO = [
        ...this.state.earningList,
        ...this.state.deductionList,
        ...this.state.InputValues,
        ...this.state.summary,
      ];
      if (this.state.effective_date !== "") {
        var effective_date = this.state.effective_date.split(" ");
        var lastDate = moment(effective_date[0]).format("DD-MM-YYYY");
      }
      payEmployeeStructureDTO = payEmployeeStructureDTO.map((prop) => {
        var obj = {
          amount: prop.amount,
          componentId: prop.componentId,

          employeeid: this.props.getEbId,
          endsOn: "",
          payschemeId: this.state.paySchemeName,
          remarks: "",
        };
        return obj;
      });
      var data = {
        payEmployeeDTO: {
          employeeid: this.props.getEbId,
          paySchemeId: this.state.paySchemeName,
        },
        payEmployeeStructureDTO: payEmployeeStructureDTO,
      };
      this.props.addSalary(serverApi.ADD_SALARY, data, this.props.history);
    }
  };

  checkCalculations = () => {
    let payloadData = {};
    if (this.state.InputValues) {
      this.state.InputValues.map((row) => {
        if (this.state.checkCal === false) {
          payloadData = { ...payloadData, [row.componentId]: row.amount };
        } else {
          payloadData = { ...payloadData, [row.componentId]: row.formula };
        }
      });
    }

    if (this.state.earningList) {
      this.state.earningList.map((row) => {
        payloadData = { ...payloadData, [row.componentId]: row.formula };
      });
    }
    if (this.state.deductionList) {
      this.state.deductionList.map((row) => {
        payloadData = { ...payloadData, [row.componentId]: row.formula };
      });
    }
    if (this.state.summary) {
      this.state.summary.map((row) => {
        payloadData = { ...payloadData, [row.componentId]: row.formula };
      });
    }

    if (
      this.state.paySchemeName !== "" &&
      this.state.paySchemeName !== undefined
    ) {
      this.props.getPayschemeList(
        serverApi.CHECK_PAY_SCHEME + "1111/" + this.state.paySchemeName,
        payloadData,
        this.props.history
      );
      this.setState({
        checkCal: true,
      });
    } else {
      this.props.getPayschemeList(
        serverApi.CHECK_PAY_SCHEME + "1111/" + this.state.rowId,
        payloadData,
        this.props.history
      );
    }
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    const SortedInputValues = this.state.InputValues.sort((a, b) => {
      return a.componentId - b.componentId;
    });
    const SortedEarningValues = this.state.earningList.sort((a, b) => {
      return a.componentId - b.componentId;
    });
    const SortedDeductionListValues = this.state.deductionList.sort((a, b) => {
      return a.componentId - b.componentId;
    });
    const SortedSummaryListValues = this.state.summary.sort((a, b) => {
      return a.componentId - b.componentId;
    });

    return (
      <>
        <div className="salaryStructure">
          <h1>Salary Structure</h1>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={
                          this.state.payScheme
                            ? this.state.payScheme.map((item) => ({
                                label: item.name,
                                value: item.id,
                                name: item.name,
                              }))
                            : ""
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.paySchemeName}
                        update={this.state.paySchemeName !== undefined ? 1 : 0}
                        name="payScheme"
                        label="Pay Scheme Name"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>
                          Effective Date <div className="mandatoryField">*</div>
                        </label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          name="EffectiveDate"
                          inputFormat="dd-MM-yyyy"
                          value={this.state.effective_date}
                          fullWidth
                          onChange={(e) =>
                            this.handleSelectDate(e, "EffectiveDate")
                          }
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
          {this.state.display && (
            <div>
              <div className="consoleFormContainer">
                <h1>Input Values</h1>
                <div className="consoleFormBlock">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={2}
                        className="contactDetails"
                      >
                        {SortedInputValues.length !== 0 &&
                          SortedInputValues.map((prop, i) => {
                            return (
                              <>
                                <Box gridColumn="span 3" key={i}>
                                  <div className="consoleTextFieldBlock">
                                    <TextFieldNormal
                                      type="Number"
                                      label={prop.componentName}
                                      placeholder="Enter Here"
                                      value={prop.amount}
                                      onChange={this.onhandlechange(
                                        prop.componentId
                                      )}
                                    />
                                  </div>
                                </Box>
                              </>
                            );
                          })}
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Check Calculation"}
                  className="greenButton"
                  handleClick={this.checkCalculations}
                />
              </div>

              <div style={{ display: "flex" }}>
                <Grid item xs={6} style={{ margin: "0 5px 0 0" }}>
                  <div className="educationalDetailsBlock">
                    <h1>Earnings</h1>
                    <div className="gridContainer">
                      <div className="gridComponent formulaTable">
                        <table>
                          <thead>
                            <tr>
                              <th style={{ width: "25%" }}>Component</th>

                              <th style={{ width: "25%" }}>Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {SortedEarningValues.length !== 0 &&
                              SortedEarningValues.map((prop, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{prop.componentName}</td>

                                    <td>{prop.amount}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} style={{ margin: "0 0 0 5px" }}>
                  <div className="educationalDetailsBlock">
                    <h1>Deductions</h1>
                    <div className="gridContainer">
                      <div className="gridComponent formulaTable">
                        <table>
                          <thead>
                            <tr>
                              <th style={{ width: "25%" }}>Component</th>

                              <th style={{ width: "25%" }}>Value</th>
                            </tr>
                          </thead>

                          <tbody>
                            {SortedDeductionListValues.length !== 0 &&
                              SortedDeductionListValues.map((prop, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{prop.componentName}</td>

                                    <td>{prop.amount}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Grid>
              </div>
              <div className="consoleFormContainer">
                <h1>Summary</h1>
                <div className="consoleFormBlock">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={2}
                        className="contactDetails"
                      >
                        {SortedSummaryListValues.length !== 0 &&
                          SortedSummaryListValues.map((prop, i) => {
                            return (
                              <>
                                <Box gridColumn="span 3" key={i}>
                                  <div className="consoleTextFieldBlock">
                                    <TextFieldNormal
                                      disabled={true}
                                      label={prop.componentName}
                                      readOnly={"true"}
                                      placeholder="Enter Here"
                                      value={prop.amount}
                                      onChange={this.onhandlechange(
                                        "NoOfElements"
                                      )}
                                    />
                                  </div>
                                </Box>
                              </>
                            );
                          })}
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          )}
          <div className="consoleFormButtonBlock">
            <Link to="/listOf_employees">
              <CustomButton label={"Cancel"} className="greenBorderButton" />
            </Link>

            {this.state.empPaySchemeId ? (
              <CustomButton
                label={"Update"}
                className="greenButton"
                handleClick={this.onUpdate}
                type="sumbit"
              />
            ) : (
              <CustomButton
                label={"Save"}
                className="greenButton"
                handleClick={this.onCreateData}
                type="sumbit"
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    payScheme_List,
    paySchemeById,
    paySchemeByIdList,
    checkPayScheme,
    viewById,
    checkScheme,
    addingsalary,
    getEbId,
    SalaryStructureList,
    payComponents_list,
  } = state.EmployeeReducer;
  return {
    payScheme_List,
    paySchemeById,
    paySchemeByIdList,
    checkPayScheme,
    viewById,
    checkScheme,
    addingsalary,
    getEbId,
    SalaryStructureList,
    payComponents_list,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getPayScheme,
    PaySchemeById,
    checkPayScheme,
    getPayschemeList,
    addSalary,
    updateSalary,
    PaySchemeViewById,
    clearCheckScheme,
    PayComponents,
  })(SalaryStructure)
);
