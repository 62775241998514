import React, { Component } from "react";
import { Grid } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getCostList } from "../../../store/Accounting/Bills/actions";

class BillLineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCount: 1,
      items_List: [],
      indentLineItems: [],
    };
  }

  handleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;

    this.setState({ [key]: event });
    let mname = key.split("_");
    if (mname[0] === "description") {
      row.description = value;
    }
    if (mname[0] === "rate") {
      row.rate = value;
    }
    if (mname[0] === "qty") {
      row.quantity = value;
    }
    if (mname[0] === "uom") {
      row.uom = value;
    }

    row.amount = row.rate * row.quantity;
  };
  handleChangedropdown = (selectedValue, selectedName, name, selectedRow) => {
    let lastVal = selectedRow.id;
    this.setState({
      rowCount: this.state.rowCount + 1,
    });
    let lastRow = this.props.products[this.props.products.length - 1];

    if (name === "costCenter") {
      selectedRow.costCenter = selectedValue;
    }
    if (name === "taxType") {
      selectedRow.taxId = selectedValue;
      var val = selectedName;
      val = val.split("^");
      var taxPercentage = val[1];

      selectedRow.taxRate = parseFloat(taxPercentage);
    }
    if (selectedRow === lastRow && selectedValue !== "0" && lastVal === "0") {
      this.handleAddEvent();
    }
  };

  handleAddEvent(evt) {
    let rowId = this.state.rowCount;
    let product = {
      id: "0",
      no: rowId,
      costCenter: "",
      uom: "",
      quantity: 0,
      rate: 0,
      amount: 0,
      taxId: "",
      description: "",
      taxRate: 0,
      cgstPercentage: 0,
      sgstPercentage: 0,
      igstPercentage: 0,
      cgstAmount: 0,
      sgstAmount: 0,
      igstAmount: 0,
    };
    this.setState({ rowCount: rowId + 1 });
    this.props.products.push(product);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillReceiveProps() {
    if (this.props.itemsList) {
      this.setState({
        items_List: this.props.itemsList,
      });
    }
  }

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    let costfactorList = this.props.costFactorList.data;
    let taxType = this.props.taxList.data;
    return (
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent" onScroll={this.handleScroll}>
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {this.props.tableHeaders.map((column) => (
                    <th>{column.header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {this.props.products.map((row, rowIndex) => (
                  <tr>
                    <>
                      {this.props.RowData &&
                        this.props.RowData.map((col) =>
                          col.component === "EditableCell" ? (
                            <>
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        type="text"
                                        name={col.type + "_" + rowIndex}
                                        id={col.no}
                                        disabled
                                        Value={
                                          col.type === "amount"
                                            ? row.amount
                                            : ""
                                        }
                                        onChange={this.handleChange(
                                          col.type + "_" + rowIndex,
                                          row
                                        )}
                                        className="inputBlockContainer"
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            </>
                          ) : col.component === "Inputadorment" ? (
                            <td>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      name={col.type + "_" + rowIndex}
                                      id={col.no}
                                      Value={
                                        col.type === "description"
                                          ? row.description
                                          : col.type === "rate"
                                          ? row.rate
                                          : col.type === "qty"
                                          ? row.quantity
                                          : col.type === "uom"
                                          ? row.uom
                                          : ""
                                      }
                                      onChange={this.handleChange({
                                        key: col.type + "_" + rowIndex,
                                        row,
                                      })}
                                      className="inputBlockContainer"
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          ) : (
                            <td>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <DynamicSelect
                                      arrayOfData={
                                        col.type === "taxType"
                                          ? taxType
                                          : costfactorList
                                      }
                                      className="dropdownBlockContainer"
                                      onSelectChange={this.handleChangedropdown}
                                      isDropdownOpen={this.state.isDropdownOpen}
                                      handleMenuOpen={this.handleMenuOpen}
                                      selected={
                                        col.type === "taxType"
                                          ? this.state.taxId
                                          : this.state.costCenter
                                      }
                                      name={
                                        col.name === "taxType"
                                          ? "taxType"
                                          : "costCenter"
                                      }
                                      row={row}
                                      value={
                                        col.type === "taxType"
                                          ? this.state.taxId
                                          : this.state.costCenter
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          )
                        )}
                    </>
                    <td className="">
                      <input
                        type="button"
                        value="X"
                        className="deleteDisplayButton"
                      />
                      {this.state.delete && (
                        <CommonPopup>
                          <div className="delAddItem">
                            <div>Are you sure you want to delete?</div>
                            <div className="delAddItemBtns">
                              <input
                                type="button"
                                onClick={this.onDel.bind(this)}
                                value="Yes"
                                className="delYesBtn"
                              />
                              <input
                                type="button"
                                onClick={this.onDel.bind(this)}
                                value="No"
                                className="delNoBtn"
                              />
                            </div>
                          </div>
                        </CommonPopup>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { costFactorList } = state.BillsReducer;
  const { taxList } = state.taxList;
  return { costFactorList, taxList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getCostList,
  })(BillLineItem)
);
