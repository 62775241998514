import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { serverApi } from "../../../helpers/Consts";
import {
  getSupplierForJuteById,
  getSrNumber,
  getSrNoViewById,
  getDebitNoteBranch,
  getDebitNoteProjects,
} from "../../../store/Purchase/Debit-credit-note/actions";
import moment from "moment";
import { DebitNoteLineItemsProps } from "../../../store/Purchase/Debit-credit-note/actions";
import swal from "sweetalert";

function DebitCreditFillDetails(props) {
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [SupplierData, setSupplierData] = useState([]);
  const [SR_Data, setSR_Data] = useState([]);
  const [BranchList, setBranchList] = useState([]);
  const [supplierId, setSupplierId] = useState(props.DebCreditHdr.supplierId);
  const [supplierName,setsupplierName] =useState(props.DebCreditHdr.supplierName)
  const [poSequence, setPoSequence] = useState(props.DebCreditHdr.poSequence);
  const [challanno, setChallanno] = useState(props.DebCreditHdr.challanno);
  const [challannoDate, setChallannoDate] = useState(
    props.DebCreditHdr.challannoDate
  );
  const [vehicleNumber, setVehicleNumber] = useState(
    props.DebCreditHdr.vehicleNumber
  );
  const [branchId, setBranchId] = useState(props.DebCreditHdr.branchId);
  const [inwardId, setInwardId] = useState(props.DebCreditHdr.id);
  const [remarks, setRemarks] = useState(props.DebCreditHdr.remarks);
  const [PoDate, setPODate] = useState(props.DebCreditHdr.PoDate);
  const [SR_Date, setSR_Date] = useState(props.DebCreditHdr.SR_Date);
  const [Debit_Date, setDebit_Date] = useState(props.DebCreditHdr.Debit_Date);
  const [SrNo, setSrNo] = useState(props.DebCreditHdr.SrNo);
  const [DebitNoteNo,setDebitNoteNo]=useState(props.DebCreditHdr.DebitNoteNo)
  const [srPrintNo,setsrPrintNo]=useState(props.DebCreditHdr.srPrintNo)
  const [taxType,settaxType]=useState(props.DebCreditHdr.taxType)
  const [status,setStatus]=useState(props.DebCreditHdr.status);
  const [roundOffValue,setroundOffValue]=useState(props.DebCreditHdr.roundOffValue)
  const [roundingFlag,setroundingFlag]=useState(props.DebCreditHdr.roundingFlag)
  const [roundingPositiveFlag,setroundingPositiveFlag]=useState(props.DebCreditHdr.roundingPositiveFlag)
  const [roundingNegetiveFlag,setroundingNegetiveFlag]=useState(props.DebCreditHdr.roundingNegetiveFlag)
  const [roundingPositiveValue,setroundingPositiveValue]=useState(props.DebCreditHdr.roundingNegetiveFlag)
  const [roundingNegetiveValue,setroundingNegetiveValue]=useState(props.DebCreditHdr.roundingNegetiveFlag)



  const [debitNoteLineDetails, setdebitNoteLineDetails] = useState([]);
  const [adjustmentId, setadjustmentId] = useState(
    props.DebCreditHdr.adjustmentId
  );
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  useEffect(() => {
    props.getSupplierForJuteById(
      serverApi.GET_ALL_SUPPLIERS_LIST + localStorage.getItem("companyId"),
      props.history
    );
    props.getDebitNoteBranch(
      serverApi.BRANCH_LIST + localStorage.getItem("companyId")
    );

    props.getSrNumber(
      serverApi.SR_NO_LIST + localStorage.getItem("companyId"),
      props.history
    );
    if (props.location.state !== undefined) {
      if (props.location.state.rowData !== undefined) {
        if (props.location.state.rowData.debitNoteDate !== null) {
          var DebitDate = moment(
            props.location.state.rowData.debitNoteDate,
            "DD-MM-YYYY",
            true
          ).format("YYYY-MM-DD");
        }
        if (props.location.state.rowData.storeReceiptDate !== null) {
          var Srdate = moment(
            props.location.state.rowData.storeReceiptDate,
            "DD-MM-YYYY",
            true
          ).format("YYYY-MM-DD");
        }
        if (props.location.state.rowData.poApproveDate !== null) {
          var poDate = moment(
            props.location.state.rowData.poApproveDate,
            "DD-MM-YYYY",
            true
          ).format("YYYY-MM-DD");
        }
        if (props.location.state.rowData.challannoDate !== null) {
          var challanDate = moment(
            props.location.state.rowData.challannoDate,
            "DD-MM-YYYY",
            true
          ).format("YYYY-MM-DD");
        }

        setSrNo(props.location.state.rowData.inwardId);
        setSupplierId(props.location.state.rowData.supplierId);
        setsupplierName(props.location.state.rowData.supplier)
        setSR_Date(Srdate);
        setPoSequence(props.location.state.rowData.poSequenceNo);
        setChallanno(props.location.state.rowData.challanno);
        setVehicleNumber(props.location.state.rowData.vehicleNumber);
        setBranchId(props.location.state.rowData.branchId);
        setInwardId(props.location.state.rowData.inwardId);
        setRemarks(props.location.state.rowData.remarks);
        setPODate(poDate);
        setChallannoDate(challanDate);
        setDebit_Date(DebitDate);
        setadjustmentId(props.location.state.rowData.debitNoteId);
        setsrPrintNo(props.location.state.rowData.srPrintNo)
        setDebitNoteNo(props.location.state.rowData.debitNoteNo)
        setStatus(props.location.state.rowData.statusDesc)
        setroundOffValue(props.location.state.rowData.roundOffValue)
        setroundingFlag(props.location.state.rowData.roundingFlag)
        setroundingPositiveFlag(props.location.state.rowData.roundingFlag ==true ? true:false)
        setroundingNegetiveFlag(props.location.state.rowData.roundingFlag ==false? true:false)

        props.DebCreditHdr.Debit_Date = DebitDate;
        props.DebCreditHdr.SrNo = props.location.state.rowData.inwardId;
        props.DebCreditHdr.supplierId = props.location.state.rowData.supplierId;
        props.DebCreditHdr.supplierName = props.location.state.rowData.supplier;
        props.DebCreditHdr.SR_Date = Srdate;
        props.DebCreditHdr.poSequence = props.location.state.rowData.poSequenceNo;
        props.DebCreditHdr.challanno = props.location.state.rowData.challanno;
        props.DebCreditHdr.vehicleNumber =props.location.state.rowData.vehicleNumber;
        props.DebCreditHdr.branchId = props.location.state.rowData.branchId;
        props.DebCreditHdr.inwardId = props.location.state.rowData.inwardId;
        props.DebCreditHdr.remarks = props.location.state.rowData.remarks;
        props.DebCreditHdr.srPrintNo=props.location.state.rowData.srPrintNo
        props.DebCreditHdr.PoDate = poDate;
        props.DebCreditHdr.adjustmentId =props.location.state.rowData.debitNoteId;
        props.DebCreditHdr.ChallannoDate =challanDate
        props.DebCreditHdr.DebitNoteNo=props.location.state.rowData.debitNoteNo
         props.DebCreditHdr.status= props.location.state.rowData.statusDesc

        props.DebCreditHdr.roundOffValue=props.location.state.rowData.roundOffValue
        props.DebCreditHdr.roundingFlag=props.location.state.rowData.roundingFlag
        props.DebCreditHdr.roundingPositiveFlag= props.location.state.rowData.roundingFlag ==true ? true:false
        props.DebCreditHdr.roundingNegetiveFlag= props.location.state.rowData.roundingFlag ==false? true:false
      }
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (props.SrNoViewByID) {
      if (props.SrNoViewByID.data) {
        const data = props.SrNoViewByID.data;
        setSupplierId(data.supplierId);
        setsupplierName(data.supplierName)
        setSR_Date(data.storeReceiptDate);
        setPoSequence(data.poSequence);
        setChallanno(data.challanno);
        setVehicleNumber(data.vehicleNumber);
        setBranchId(data.branchId);
        setInwardId(data.id);
        setRemarks(data.remarks);
        settaxType(data.taxType)

        setroundOffValue(null)
        setroundingFlag(null)
        setroundingPositiveFlag(null)
        setroundingNegetiveFlag(null)
        setroundingPositiveValue(null)
        setroundingNegetiveValue(null)

        props.DebCreditHdr.supplierId = data.supplierId;
        props.DebCreditHdr.supplierName = data.supplierName;
        props.DebCreditHdr.storeReceiptDate = data.storeReceiptDate;
        props.DebCreditHdr.poSequence = data.poSequence;
        props.DebCreditHdr.challanno = data.challanno;
        props.DebCreditHdr.vehicleNumber = data.vehicleNumber;
        props.DebCreditHdr.branchId = data.branchId;
        props.DebCreditHdr.inwardId = data.id;
        props.DebCreditHdr.remarks = data.remarks;
        props.DebCreditHdr.taxType = data.taxType;

        props.DebCreditHdr.roundOffValue=null
        props.DebCreditHdr.roundingFlag=null
        props.DebCreditHdr.roundingPositiveFlag=null
        props.DebCreditHdr.roundingNegetiveFlag=null
        props.DebCreditHdr.roundingPositiveValue=null
        props.DebCreditHdr.roundingNegetiveValue=null
        

        if (data.challannoDate !== "") {
          let challanDate = moment(
            data.challannoDate,
            "DD-MM-YYYY",
            true
          ).format("YYYY-MM-DD");
          setChallannoDate(challanDate);
          props.DebCreditHdr.challannoDate = challanDate;
        }
        if (data.storeReceiptDate !== "") {
          let srDate = moment(data.storeReceiptDate, "DD-MM-YYYY", true).format(
            "YYYY-MM-DD"
          );
          setSR_Date(srDate);
          props.DebCreditHdr.SR_Date = srDate;
        }
        if (data.poApproveDate !== null) {
          let poApproveDate = moment(
            data.poApproveDate,
            "DD-MM-YYYY",
            true
          ).format("YYYY-MM-DD");
          setPODate(poApproveDate);
          props.DebCreditHdr.PoDate = poApproveDate;
        }
      
      }
    }
  }, [props.SrNoViewByID]);

  useEffect(()=>{
    if (props.SupplierForJuteById && props.SupplierForJuteById.data) {
      const List = props.SupplierForJuteById.data.map((item) => ({
        label: item.label,
        name: item.label,
        value: item.value,
      }));
      setSupplierData(List);
    }

    if (
      props.GetSR_No &&
      props.GetSR_No.data &&
      props.GetSR_No.data.length !== 0
    ) {
      const ListData = props.GetSR_No.data.map((item) => ({
        value: item.Id,
        label: item.Value,
        name: item.Value,
      }));
      setSR_Data(ListData);
    }
    if (props.getDebitBranch && props.getDebitBranch.data) {
      const BranchList = props.getDebitBranch.data.map((item) => ({
        value: item.value,
        label: item.label,
        name: item.label,
      }));
      setBranchList(BranchList);
    }
  },[props])
  const handleSelect_Date = (e, name) => {
    if (name === "Debit_Date") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      if (SR_Date !== "") {
        if (Date < SR_Date) {
          swal("Debit Note Date Should be Greater than SR Date");
          setDebit_Date(null);
          props.DebCreditHdr.Debit_Date = null;
        } else {
          setDebit_Date(Date);
          props.DebCreditHdr.Debit_Date = Date;
        }
      } else {
        setDebit_Date(Date);
        props.DebCreditHdr.Debit_Date = Date;
      }
    }
  };
  const handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name == "SrNo") {
      setSrNo(selectedValue);
      props.DebCreditHdr.SrNo = selectedValue;
      props.DebCreditHdr.srPrintNo=selectedName
      props.getSrNoViewById(serverApi.SR_VIEW_BY_ID_DATA + selectedValue);
    }
  };

  const onhandlechange = (key) => (event) => {};

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
 
  return (
    <div>
      <div className="consoleFormContainer">
        <h5>Fill Details </h5>

        <div style={{ height: "15px" }}></div>
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      update={SrNo ? 1 : 0}
                      arrayOfData={SR_Data}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={SrNo}
                      name="SrNo"
                      label="SR No"
                      required
                      isDisabled ={props.location.state !== undefined ? true : false}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>
                          Debit Note Date<span className="starcolor">* </span>
                        </label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          disabled={
                            props.location.state !== undefined
                              ? Debit_Date == SR_Date
                                ? true
                                : false
                              : SrNo == ""
                              ? true
                              : false
                          }
                          name="Debit_Date"
                          inputFormat="dd-MM-yyyy"
                          id="Debit_Date"
                          value={Debit_Date}
                          fullWidth
                          onChange={(e) => handleSelect_Date(e, "Debit_Date")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                              style={{
                                backgroundColor:
                                  SrNo == "" ? "#ccd6db" : "#ffffff",
                              }}
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                style={{
                                  backgroundColor:
                                    SrNo == "" ? "#ccd6db" : "#ffffff",
                                }}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      update={supplierId ? 1 : 0}
                      arrayOfData={SupplierData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={supplierId}
                      name="supplierName"
                      label="Supplier"
                      isDisabled={true}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>SR Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          disabled={true}
                          name="SR_Date"
                          inputFormat="dd-MM-yyyy"
                          id="SR_Date"
                          value={SR_Date}
                          fullWidth
                          onChange={(e) => handleSelect_Date(e, "SR_Date")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                              style={{
                                backgroundColor: "#ccd6db",
                              }}
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                style={{
                                  backgroundColor: "#ccd6db",
                                }}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <TextFieldNormal
                    disabled={true}
                    label="PO NO"
                    value={poSequence}
                    onChange={onhandlechange("poSequence")}
                  />
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>PO Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          isDisabled={true}
                          name="PoDate"
                          inputFormat="dd-MM-yyyy"
                          id="PoDate"
                          value={PoDate}
                          fullWidth
                          onChange={(e) => handleSelect_Date(e, "PoDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                              style={{
                                backgroundColor: "#ccd6db",
                              }}
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                style={{
                                  backgroundColor: "#ccd6db",
                                }}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <TextFieldNormal
                    label="Challan NO"
                    value={challanno}
                    disabled={true}
                  />
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>Challan Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          disabled={true}
                          name="Challan_Date"
                          inputFormat="dd-MM-yyyy"
                          id="Challan_Date"
                          value={challannoDate}
                          fullWidth
                          onChange={(e) => handleSelect_Date(e, "Challan_Date")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                              style={{
                                backgroundColor: "#ccd6db",
                              }}
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                style={{
                                  backgroundColor: "#ccd6db",
                                }}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <TextFieldNormal
                    label="Vehicle NO"
                    value={vehicleNumber}
                    disabled={true}
                  />
                </Box>

                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      update={branchId ? 1 : 0}
                      arrayOfData={BranchList}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={branchId}
                      name="branchId"
                      label="Branch"
                      isDisabled={true}
                    />
                  </div>
                </Box>

                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      update={taxType ? 1 : 0}
                      arrayOfData={taxTypeData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={taxType}
                      name="taxType"
                      label="Tax Type"
                      isDisabled={true}
                    />
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
const taxTypeData = [
  {
    value: 1,
    name: "IGST",
    label: "IGST",
  },
  {
    value: 2,
    name: "CGST & SGST",
    label: "CGST & SGST",
  },
];
const mapStatetoProps = (state) => {
  const {
    SupplierForJuteById,
    GetSR_No,
    SrNoViewByID,
    getDebitBranch,
    getDebitProjects,
    debitNoteLineItems

  } = state.DebitCreditReducer;
  return {
    SupplierForJuteById,
    GetSR_No,
    SrNoViewByID,
    getDebitBranch,
    getDebitProjects,
  };
};
export default connect(mapStatetoProps, {
  getSupplierForJuteById,
  getSrNumber,
  getSrNoViewById,
  getDebitNoteBranch,
  getDebitNoteProjects,
  DebitNoteLineItemsProps,
})(withRouter(DebitCreditFillDetails));
