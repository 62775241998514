import {MUKAM_MASTER_LIST,MUKAM_MASTER_LIST_SUCCESSFULL,
  API_ERRORS,
  CREATE_MUKAM_MASTER, CREATE_MUKAM_MASTER_SUCCESSFULL, UPDATE_MUKAM_MASTER_SUCCESSFULL, UPDATE_MUKAM_MASTER,} from './actionTypes';

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    MukamListData: [],
    CreateMukamMaster:[],
    UpdateMukamMaster:[],
  };

  const MukamMasterReducer = (state = initialState, action) => {
    switch (action.type) {
        case MUKAM_MASTER_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case MUKAM_MASTER_LIST_SUCCESSFULL:
            state = {
              ...state,
              MukamListData: action.payload.response,
              loading: true,
            };
            break;
            case CREATE_MUKAM_MASTER:
              state = {
                ...state,
                loading: true,
              };
              break;
            case CREATE_MUKAM_MASTER_SUCCESSFULL:
              state = {
                ...state,
                CreateMUKAMMaster: action.payload.response,
                loading: true,
              };
              break;
              case UPDATE_MUKAM_MASTER:
              state = {
                ...state,
                loading: true,
              };
              break;
            case UPDATE_MUKAM_MASTER_SUCCESSFULL:
              state = {
                ...state,
                UpdateMUKAMMaster: action.payload.response,
                loading: true,
              };
              break;
              case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default MukamMasterReducer;