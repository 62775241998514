import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
// import DynamicSelect from "../Dropdown/DynamicSelect";
import DynamicSelect from "../../components/Dropdown/Master/DynamicSelectMasters";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DesktopDatePicker from "@mui/lab/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextFieldAlfaNum from "../../components/TextField/TextFieldAlfaNum";
import TextFieldNormal from "../../components/TextField/TextFieldNormal";

import moment from "moment";
import { serverApi } from "../../helpers/Consts";
import { getProjectList } from "../../store/Global/DropDownApis/actions";
import { getCateogryTypeList } from "../../store/Global/DropDownApis/actions";
import { getStatusList } from "../../store/Global/DropDownApis/actions";
import { getSupplierList } from "../../store/Global/DropDownApis/actions";

class WorkOrderFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      status: "",
      statusValue: "",
      supplier: "",
      supplierValue: "",
      projectListData: [],
      statusListData: [],
      supplierListData: [],
      categoryTypeList: [],
      ClientList: [],
      project: "",
      projctValue: "",
      category: "",
      categoryName: "",
      WoNO: "",
      PoNO: "",
      fromDate: moment().day(-7).format("YYYY-MM-DD").toString(),
      toDate: moment().format("YYYY-MM-DD").toString(),
      customerId: "",
    };
  }

  componentDidMount() {
    // const data = {
    //   cipher: this.state.userDit.cipher,
    //   companyId: this.state.companyId,
    // };
    // this.props.getProjectList(
    //   serverApi.GET_ALL_PROJECTS,
    //   data,
    //   this.props.history
    // );
    // this.props.getStatusList(serverApi.GET_STATUS_LIST, this.props.history);
    // this.props.getCateogryTypeList(serverApi.GET_ALL_CATEGORY_TYPE,this.props.history);
    // this.props.getSupplierList(
    //   serverApi.GET_ALL_SUPPLIERS_LIST + this.state.companyId,
    //   this.state.history
    // );
    const financialYearStartMonth = 3; // Assuming financial year starts in April
    let financialYearStart = moment()
      .year(localStorage.getItem("acadamicYear"))
      .month(financialYearStartMonth)
      .startOf("month");

    const fromDate = financialYearStart.day(-7).format("YYYY-MM-DD");
    const toDate = moment().format("YYYY-MM-DD");

    this.setState({
      fromDate:fromDate,
      toDate:toDate
    })
  }

  componentWillReceiveProps(props) {
    if (props.projectList && Array.isArray(props.projectList.data)) {
      let data = props.projectList.data;
      if (Array.isArray(data) || (typeof data === "object" && data !== null)) {
        const newObj = {
          value: "",
          name: "Select...",
          label: "Select...",
        };
        // const NewArray = [NewObj, ...data];
        const newArray = [
          newObj,
          ...(Array.isArray(data) ? data : Object.values(data)),
        ];
        this.setState({
          projectListData: newArray,
        });
      }
    }
    if (props.statusList) {
      this.setState({
        statusListData: props.statusList.data,
      });
    }
    if (props.supplierList) {
      this.setState({
        supplierListData: props.supplierList.data,
      });
    }
    if (props.CategoryTypeList && Array.isArray(props.CategoryTypeList.data)) {
      let data = props.CategoryTypeList.data;
      if (Array.isArray(data) || (typeof data === "object" && data !== null)) {
        const newObj = {
          value: "",
          name: "Select...",
          label: "Select...",
        };
        // const NewCategoryTypeList = [newObj, ...data];
        const newCategoryTypeList = [
          newObj,
          ...(Array.isArray(data) ? data : Object.values(data)),
        ];

        this.setState({
          categoryTypeList: newCategoryTypeList,
        });
      }
    }
    if (props.clientList) {
      this.setState({
        ClientList: props.clientList.data,
      });
    }
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "project") {
      this.setState({
        projctValue: selectedValue,
        project: selectedName,
      });
    }
    if (name === "Supplier") {
      let supplier = selectedName.split("_");
      this.setState({
        supplierValue: selectedValue,
        supplier: supplier[0],
      });
    }
    if (name === "Status") {
      this.setState({
        statusValue: selectedValue,
        status: selectedName,
      });
    }
    if (name === "Category_Type") {
      this.setState({
        category: selectedValue,
      });
    }
    if (name === "customerId") {
      this.setState({
        customerId: selectedValue,
      });
    }
  };

  handleSelectDate = (e, name) => {
    if (name === "FromDate") {
      let date = e; // value from your state
      let formattedFromDate = moment(date, "DD-MM-YYYY").format();
      var fromdate = formattedFromDate.split("T", 1).toString();

      this.setState({
        fromDate: fromdate,
      });
    }

    if (name === "ToDate") {
      let date = e; // value from your state
      let formattedToDate = moment(date, "DD-MM-YYYY").format();
      var toDate = formattedToDate.split("T", 1).toString();

      this.setState({
        toDate: toDate,
      });
    }
  };
  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "WoNO") {
      this.setState({
        WoNO: event,
      });
    }
    if (key === "PoNO") {
      this.setState({
        PoNO: event,
      });
    }
  };

  handleSubmit = () => {
    var data = {
      fromDate: moment(this.state.fromDate, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      ),
      toDate: moment(this.state.toDate, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      ),
      project: this.state.project,
      projctValue: this.state.projctValue,
      supplier: this.state.supplier,
      supplierValue: this.state.supplierValue,
      status: this.state.status,
      statusValue: this.state.statusValue,
      WoNO: this.state.WoNO,
      PoNO: this.state.PoNO,
      customerId: this.state.customerId,
      category: this.state.category,
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid spacing={0}>
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span> Filter</span>
                </div>
              </div>
            </div>
            <div className="procurementFilter">
              <Grid container spacing={1} className="filterBlockContainer">
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>From Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          value={this.state.fromDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "FromDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>To Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          value={this.state.toDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "ToDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.supplierListData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.supplierValue}
                      name="Supplier"
                      label="Supplier"
                      update={this.state.supplierValue ? 1 : 0}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.ClientList}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.customerId}
                      name="customerId"
                      label="Client"
                      update={this.state.customerId ? 1 : 0}
                    />
                  </div>
                </Grid>

                {this.props.filter_form === "PurchaseOrder" ? (
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        value={this.state.PoNO}
                        placeholder={"Enter here"}
                        label="PO Number"
                        caps="OFF"
                        onChange={this.handleChange("PoNO")}
                      />
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        value={this.state.WoNO}
                        placeholder={"Enter here"}
                        label="WO Number"
                        caps="OFF"
                        onChange={this.handleChange("WoNO")}
                      />
                    </div>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.categoryTypeList}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.category}
                      name="Category_Type"
                      label="Category Type"
                      update={this.state.category ? 1 : 0}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.statusListData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.statusValue}
                      name="Status"
                      label="Status"
                      update={this.state.statusValue ? 1 : 0}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={
                        this.state.projectListData &&
                        this.state.projectListData.map((item) => ({
                          label: item.name,
                          value: item.projectId,
                          name: item.name,
                        }))
                      }
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.projctValue}
                      name="project"
                      label="Project"
                      update={this.state.projctValue ? 1 : 0}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="consoleFormButtonBlock">
              <CustomButton
                label={"Search"}
                className="greenButton"
                handleClick={this.handleSubmit}
                type="sumbit"
              />
            </div>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    projectList,
    statusList,
    supplierList,
    CategoryTypeList,
    clientList,
  } = state.DropDownListReducer;
  return {
    projectList,
    statusList,
    supplierList,
    CategoryTypeList,
    clientList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getProjectList,
    getStatusList,
    getSupplierList,
    getCateogryTypeList,
  })(WorkOrderFilter)
);
