import { Component } from "react";
import { connect } from "react-redux";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import { withRouter } from "react-router-dom";
import {
  CutsAndJugarList,
  CutsAndJugarListDel,
} from "../../../store/JuteProduction/CutsAndJugarEntry/actions";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridDeleteIcon from "../../../assets/images/deleteIcon.png";

class CutsAndJugarEntryIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      startIndex: "",
      lastIndex: "",
      CutsAndJugarContent: [],
      CutsAndJugarContentDelete: [],
      fromDate: "",
      toDate: "",
    };
  }
  componentDidMount() {
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: this.state.cipher,
      fromDate: "10-01-1989",
      toDate: "10-01-1989",
    };
    this.props.CutsAndJugarList(
      serverApi.GET_CUTS_AND_JUGAR_ENTRY_LIST,
      data,
      this.props.history
    );
    showSpinner();
  }

  handleSearch = (searchData) => {
    this.setState({
      fromDate: searchData.fromDate,
      toDate: searchData.toDate,
    });
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: this.state.cipher,
      fromDate: searchData.fromDate,
      toDate: searchData.toDate,
    };
    this.props.CutsAndJugarList(
      serverApi.GET_CUTS_AND_JUGAR_ENTRY_LIST,
      data,
      this.props.history
    );
    showSpinner();
  };
  componentWillReceiveProps(props) {
    if (props.CutsAndJugarData) {
      this.setState({
        CutsAndJugarContent: props.CutsAndJugarData.data,
      });
      hideSpinner();
    }
    if (props.CutsAndJugarDelData) {
      this.setState({
        CutsAndJugarContentDelete: props.CutsAndJugarDelData.data,
      });
    }
  }

  handledeleteRecord = (row) => {
    this.props.CutsAndJugarListDel(
      serverApi.DEL_CUTS_AND_JUGAR_ENTRY_LIST + row.id,
      this.props.history,
      this.state.fromDate,
      this.state.toDate
    );
    showSpinner();
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.CutsAndJugarContent}
          mcolumns={Columns}
          status={true}
          micon={GridDeleteIcon}
          actions={false}
          deleteIcon={true}
          filter_form={"CutsAndJugarFilter"}
          handleSearch={this.handleSearch}
          handledeleteRecord={this.handledeleteRecord}
        />
      </>
    );
  }
}
const Columns = [
  {
    hidden: false,
    Header: "Date",
    accessor: "date",
  },
  {
    hidden: false,
    Header: "Machine Name",
    accessor: "machine",
  },
  {
    hidden: false,
    Header: "Line Number",
    accessor: "lineNumber",
  },
  {
    hidden: false,
    Header: "A1 Open",
    accessor: "openA1",
  },
  {
    hidden: false,
    Header: "A1 Cuts",
    accessor: "cutsA1",
  },
  {
    hidden: false,
    Header: "A1 Jugar",
    accessor: "jugarA1",
  },
  {
    hidden: false,
    Header: "A1 Production(YDS)",
    accessor: "productionA1",
  },
  {
    hidden: false,
    Header: "A1 Efficiency",
    accessor: "efficiencyA1",
  },
  {
    hidden: false,
    Header: "A1 WorkingHours",
    accessor: "workingHoursA1",
  },
  {
    hidden: false,
    Header: "A1 EB No",
    accessor: "ticketNoA1",
  },
  {
    hidden: true,
    Header: "A1 QCode",
    accessor: "qualityCodeA1",
  },
  {
    hidden: true,
    Header: "B1 Open",
    accessor: "openB1",
  },
  {
    hidden: true,
    Header: "B1 Cuts",
    accessor: "cutsB1",
  },
  {
    hidden: true,
    Header: "B1 Jugar",
    accessor: "jugarB1",
  },
  {
    hidden: true,
    Header: "B1 Production(YDS)",
    accessor: "productionB1",
  },
  {
    hidden: true,
    Header: "B1 Efficiency",
    accessor: "efficiencyB1",
  },
  {
    hidden: true,
    Header: "B1 WorkingHours",
    accessor: "workingHoursB1",
  },
  {
    hidden: true,
    Header: "B1 EB No",
    accessor: "ticketNoB1",
  },
  {
    hidden: true,
    Header: "B1 QCode",
    accessor: "qualityCodeB1",
  },
  {
    hidden: true,
    Header: "A2 Open",
    accessor: "openA2",
  },
  {
    hidden: true,
    Header: "A2 Cuts",
    accessor: "cutsA2",
  },
  {
    hidden: true,
    Header: "A2 Jugar",
    accessor: "jugarA2",
  },
  {
    hidden: true,
    Header: "A2 Production(YDS)",
    accessor: "productionA2",
  },
  {
    hidden: true,
    Header: "A2 Efficiency",
    accessor: "efficiencyA2",
  },
  {
    hidden: true,
    Header: "A2 WorkingHours",
    accessor: "workingHoursA2",
  },
  {
    hidden: true,
    Header: "A2 EB No",
    accessor: "ticketNoA2",
  },
  {
    hidden: true,
    Header: "A2 QCode",
    accessor: "qualityCodeA2",
  },
  {
    hidden: true,
    Header: "B2 Open",
    accessor: "openB2",
  },
  {
    hidden: true,
    Header: "B2 Cuts",
    accessor: "cutsB2",
  },
  {
    hidden: true,
    Header: "B2 Jugar",
    accessor: "jugarB2",
  },
  {
    hidden: true,
    Header: "B2 Production(YDS)",
    accessor: "productionB2",
  },
  {
    hidden: true,
    Header: "B2 Efficiency",
    accessor: "efficiencyB2",
  },
  {
    hidden: true,
    Header: "B2 WorkingHours",
    accessor: "workingHoursB2",
  },
  {
    hidden: true,
    Header: "B2 EB No",
    accessor: "ticketNoB2",
  },
  {
    hidden: true,
    Header: "B2 QCode",
    accessor: "qualityCodeB2",
  },
  {
    hidden: true,
    Header: "C Open",
    accessor: "openC",
  },
  {
    hidden: true,
    Header: "C Cuts",
    accessor: "cutsC",
  },
  {
    hidden: true,
    Header: "C Jugar",
    accessor: "jugarC",
  },
  {
    hidden: true,
    Header: "C Production(YDS)",
    accessor: "productionC",
  },
  {
    hidden: true,
    Header: "C Efficiency",
    accessor: "efficiencyC",
  },
  {
    hidden: true,
    Header: "C WorkingHours",
    accessor: "workingHoursC",
  },
  {
    hidden: true,
    Header: "C EB No",
    accessor: "ticketNoC",
  },
  {
    hidden: true,
    Header: "C QCode",
    accessor: "qualityCodeC",
  },
  {
    hidden: true,
    Header: "Total Cuts",
    accessor: "totCuts",
  },
  {
    hidden: true,
    Header: "Total Production",
    accessor: "totProduction",
  },
  {
    hidden: true,
    Header: "Total Efficiency",
    accessor: "totEfficiency",
  },
];
const mapStatetoProps = (state) => {
  const { CutsAndJugarData } = state.CutsAndJugarEntryReducer;
  return { CutsAndJugarData };
};

export default withRouter(
  connect(mapStatetoProps, {
    CutsAndJugarList,
    CutsAndJugarListDel,
  })(CutsAndJugarEntryIndex)
);
