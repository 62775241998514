export const BRANCH_LIST = 'branch_list';
export const BRANCH_LIST_SUCCESSFULL ='branch_list_successfull'
export const DEPARTMENT_LIST = 'department_list';
export const DEPARTMENT_LIST_SUCCESSFULL ='department_list_successfull'
export const CLIENT_LIST = 'client_list';
export const CLIENT_LIST_SUCCESSFULL ='client_list_successfull'
export const ITEMGROUP_LIST = 'itemGroup_list';
export const ITEMGROUP_LIST_SUCCESSFULL ='itemGroup_list_successfull'
export const ITEMGROUPBYBUDGET_HEADS_LIST = 'itemGroupByBudegtHeads_list';
export const ITEMGROUPBYBUDGET_HEADS_LIST_SUCCESSFULL ='itemGroupByBudegtHeads_list_successfull'
export const SUB_BUDGET_HEADS_LIST = 'sub_budget_Head_list'
export const SUB_BUDGET_HEADS_LIST_SUCCESSFULL = 'sub_budget_Head_list_successfull'
export const PROJECT_LIST = 'project_list';
export const PROJECT_LIST_SUCCESSFULL ='project_list_successfull'
export const CATEGORY_TYPE_LIST = 'categoryType_list';
export const CATEGORY_TYPE_LIST_SUCCESSFULL ='categoryType_list_successfull';
export const SUPPLIER_LIST = 'supplier_list';
export const SUPPLIER_LIST_SUCCESSFULL ='supplier_list_successfull';
export const STATUS_LIST = 'status_list';
export const STATUS_LIST_SUCCESSFUL = 'status_list_successfull'
export const PARENT_LEDGER_LIST = 'parent_ledger_list';
export const PARENT_LEDGER_LIST_SUCCESSFULL = 'parent_ledger_list_successfull'
export const LEDGER_GROUPS_LIST = 'ledger_groups_list';
export const LEDGER_GROUPS_LIST_SUCCESSFULL = 'ledger_groups_list_successfull'
export const STATE_NAME_LIST = 'state_name_list';
export const STATE_NAME_LIST_SUCCESSFULL = 'state_name_list_successfull'
export const CUSTOMER_LIST = 'customer_list';
export const CUSTOMER_LIST_SUCCESSFUL = 'customer_list_successfull';
export const BILLING_SHIPPING_LIST = 'billing_shipping_list';
export const BILLING_SHIPPING_LIST_SUCCESSFUL = 'billing_shipping_list_list_successfull';
export const ITEMGROUP_MASTER_LIST = 'itemgroup_master_list';
export const ITEMGROUP_MASTER_LIST_SUCCESSFUL = 'itemgroup_master_list_successfull';
export const ITEMS_BY_ITEMGROUP_BY_ID = 'items_by_item_grp_byId';
export const ITEMS_BY_ITEMGROUP_BY_ID_SUCCESSFUL = 'items_by_item_grp_byId_successful';
export const ITEMGRPBYBDGT_HEAD_ID = "itemgrpbybdgt_head_id";
export const ITEMGRPBYBDGT_HEAD_ID_SUCCESSFULL = "itemgrpbybdgt_head_id_successful";
export const TAX_LIST = 'Tax_List';
export const BUDGET_HEADS_LIST = 'budget_heads_List';
export const BUDGET_HEADS_LIST_SUCCESSFULL = 'budget_heads_List_successfull'
export const Tax_List_SUCCESSFULL = 'Tax_List_successfull';
export const ITEMSLIST_FORINDENT = 'itemslist_forIndent';
export const ITEMSLIST_FORINDENT_SUCCESSFULL = 'itemslist_forIndent_successfull';
export const UOM_LIST = 'uom_list';
export const UOM_LIST_SUCCESSFULL = 'uom_list_successfull';
export const APPROVED_INDENTS ='approved_indents';
export const APPROVED_INDENTS_SUCCESSFUL = 'approved_indents_successful';
export const ALLITEMGROUP_MASTERLIST ='allItemGroup_Master_List';
export const ALLITEMGROUP_MASTERLIST_SUCCESSFULL ='allItemGroup_Master_List_successfull';
export const SUPPLIERLISTBY_SUPP_ID ='supplierby_supp_list';
export const SUPPLIERLISTBY_SUPP_ID_SUCCESSFULL = 'supplierby_supp_list_successfull';
export const BILLING_ADDRESS_SHIPPING_ADDRESS = 'billing_address_shipping_address';
export const BILLING_ADS_SHIPPING_ADS_SUCCESSFULL = 'billing_ads_shipping_ads_successfull';
export const BUDGETHEADS = 'budget_heads';
export const BUDGETHEADS_SUCCESSFULL = 'budget_heads_successfull';
export const ITEMGRP_MASTER_LIST = 'itemGrp_master_list'
export const ITEMGRP_MASTER_LIST_SUCCESSFULL = 'itemGrp_master_list_successfull';
export const BOQ_List = 'boq_list';
export const BOQList_SUCCESSFULL = 'boq_list_successfull';
export const PARENT_BUDGET_HEADS_LIST='parent_budget_heads_List';
export const PARENT_BUDGET_HEADS_LIST_SUCCESSFULL ='parent_budget_heads_List_successfull';
export const MUKAM_LIST='mukam_List';
export const MUKAM_LIST_SUCCESSFULL ='mukam_List_successfull';
export const EMPLOYEE_CATEGORY_LIST='employee_category_List';
export const EMPLOYEE_CATEGORY_LIST_SUCCESSFULL ='employee_category_List_successfull';
export const SUPPLIER_LIST_MULTISELECT='supplier_List_multiselect';
export const SUPPLIER_LIST_MULTISELECT_SUCCESSFULL ='supplier_List_multiselect_successfull';
export const TYPES_OF_MACHINE='types_of_machine';
export const TYPES_OF_MACHINE_SUCCESSFULL ='types_of_machine_successfull';
export const YARN_TYPE_LIST='yarn_type_list';
export const YARN_TYPE_LIST_SUCCESSFULL ='yarn_type_list_successfull';

export const QUALITY_BY_ITEM = "quality_by_item"
export const QUALITY_BY_ITEM_SUCCESSFULL = "quality_by_item_successfull"

export const LAST_MARKET_RATE = "last_market_rate"
export const LAST_MARKET_RATE_SUCCESSFULL = "last_market_rate_successfull"

export const PROJECT_MASTER_LIST='project_master_list'
export const PROJECT_MASTER_LIST_SUCCESSFULL='project_master_list_successfull'

export const SUPPLIER_LIST_MULTISELECT_J ="supplier_list_multiselect_j"
export const SUPPLIER_LIST_MULTISELECT_J_SECCESSFULL ="supplier_list_multiselect_j_successfull"

export const TERMS_CONDITIONS ='terms_conditions'
export const TERMS_CONDITIONS_SUCCESSFULL='terms_conditions_successfull';

export const COUNTRY_NAME_LIST = 'country_name_list';
export const COUNTRY_NAME_LIST_SUCCESSFULL = 'country_name_list_successfull';

export const DASHBOARD_GOOGLE_LINK_API ='dashboard_google_link_api'
export const DASHBOARD_GOOGLE_LINK_API_SUCCESSFULL ='dashboard_google_link_api_successfull'

export const CONFIGURATION_API ='configuration_api'
export const CONFIGURATION_API_SUCCESSFULL ='configuration_api_successfull'

export const EXPORT_DATA_LIST ='export_data_list'
export const EXPORT_DATA_LIST_SUCCESSFULL ='export_data_list_successfull'

export const GST_VERIFICATION = 'gst_verification';
export const GST_VERIFICATION_SUCCESSFULL = 'gst_verirfication_successfull';
