import {
  GETQUALITY_BY_ITEMID,
  GET_MR_FILES,
  JUTE_MATERIAL_RECEIPT,
  JUTE_MATERIAL_VIEW_BYID,
  JUTE_MR_UPLOAD_FILES,
  UPDATE_JUTE_MR,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getListurl, getList, postAdd } from "../../../helpers/Server_Helper";
import {
  JuteMaterialReceiptSuccessfull,
  getMaterialGoodReceiveDetailsByIdSuccessfull,
  juteMrHeaderAction,
  getMrLineItems,
  updateJuteMaterialReceiptSuccessfull,
  getQuantity_ByItemIdSuccessfull,
  getQuantity_ByItemId,
  UploadJuteMrFilesSuccessfull,
  getMrFilesSuccessfull,
  getMrFiles,
} from "./actions";
import {
  getBrokerBySupplierIdList,
  getAgentDropdownList,
} from "../../MasterDropdownApis/actions";
import { serverApi } from "../../../helpers/Consts";
import swal from "sweetalert";

function* JuteMaterialReceiptListSagaList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(JuteMaterialReceiptSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getJuteMaterialViewById({ payload: { url } }) {
  try {
    const response = yield call(getListurl, url);
    if (response) {
      if (response.status == 200) {
        let juteMrHeaderDataFromSource =
          response.data.materialGoodReceiveHeader;
        var actualSupplier =
          response.data.materialGoodReceiveHeader.actualSupplier;
        if (actualSupplier) {
          var murl = serverApi.GET_BROKER_BY_SUPPLIER + actualSupplier;
          yield put(getBrokerBySupplierIdList(murl, null));
          var agentUrl =
            serverApi.GET_SUPPLIER_LIST_JPO +
            "O/" +
            localStorage.getItem("companyId");
          yield put(getAgentDropdownList(agentUrl, null));
          let mrlineItemsdata = response.data.materialGRItemList;
          let juteMrLineitems = [];
          let qtyUrl = "";
          let addItem = 0;
          if (mrlineItemsdata) {
            juteMrLineitems = mrlineItemsdata.map((row) => {
              row.VariableShortage =
                Number(row.advisedQuantity) - Number(row.acceptedWeight);
              qtyUrl =
                serverApi.GET_QUALITY_BY_ITEM +
                row.advisedItemId +
                "/" +
                localStorage.getItem("companyId") +
                "/getQualityByItem";
              addItem = Number(row.id);
              return {
                lineItemId: row.id,
                id: row.id,
                materialGrnHdrId: row.materialGrnHdrId,
                itemId: row.itemId,
                quantity: row.quantity,
                quantityUnit: row.quantityUnit,
                advisedQuality: row.advisedQuality,
                actualQuality: row.actualQuality,
                advisedWeight: row.advisedWeight,
                actualWeight: row.actualWeight,
                deviation: row.deviation,
                rate: row.rate,
                claimsCondition: row.claimsCondition,
                status: row.status,
                warehouseNo: row.warehouseNo,
                remarks: row.remarks,
                totalPrice: row.totalPrice,
                totalPriceWithTax: row.totalPriceWithTax,
                debitNotesFlag: row.debitNotesFlag,
                bale: row.bale,
                loose: row.loose,
                actualBale: row.actualBale,
                actualLoose: row.actualLoose,
                unitConversion: row.unitConversion,
                advisedItemId: row.advisedItemId,
                wareHouseName: row.wareHouseName,
                autoDateTimeInsert: row.autoDateTimeInsert,
                createdBy: row.createdBy,
                isActive: row.isActive,
                advisedQuantity: row.advisedQuantity,
                shortageKgs: row.shortageKgs,
                claimDust: row.claimDust,
                claimQuality: row.claimQuality,
                allowableMoisture: row.allowableMoisture,
                premiumAmount: row.premiumAmount,
                waterDamageAmount: row.waterDamageAmount,
                juteGateEntryLineItemNo: row.juteGateEntryLineItemNo,
                variableShortage: row.variableShortage,
                acceptedWeight: row.acceptedWeight,
                agentMRRate: row.agentMRRate,
                stockQuantityConsumed: row.stockQuantityConsumed,
                drumConsumed: row.drumConsumed,
                balesConsumed: row.balesConsumed,
                companyId: row.companyId,
                srcLineId: row.srcLineId,
                srcCompId: row.srcCompId,
                cropYear: row.cropYear,
                marka: row.marka,
                pota: row.pota,
                salesBale: row.salesBale,
                salesDrum: row.salesDrum,
                salesWeight: row.salesWeight,
                juteIssueRate: row.juteIssueRate,
                claimRate: row.claimRate,
                srcMrNo: row.srcMrNo,
                srcMrLineId: row.srcMrLineId,
                convertedQuality: row.convertedQuality,
                convertedJuteType: row.convertedJuteType,
                percentage: row.percentage,
                srcMrPrintNo: row.srcMrPrintNo,
                convertedBale: row.convertedBale,
                convertedDrum: row.convertedDrum,
                convertedWeight: row.convertedWeight,
                jcStock: row.jcStock,
                itemDesc: row.itemDesc,
                qualityDesc: row.qualityDesc,
                conItemDesc: row.conItemDesc,
                conQltyDesc: row.conQltyDesc,
              };
            });

            juteMrLineitems.push({
              lineItemId: Number(addItem) + 1,
              materialGrnHdrId: 0,
              itemId: "",
              quantity: 0,
              quantityUnit: "",
              advisedQuality: 0,
              actualQuality: 0,
              advisedWeight: 0,
              actualWeight: 0,
              deviation: 0,
              rate: 0,
              claimsCondition: "0.0",
              status: "",
              warehouseNo: 0,
              remarks: null,
              totalPrice: 0,
              totalPriceWithTax: null,
              debitNotesFlag: null,
              bale: null,
              loose: 0,
              actualBale: null,
              actualLoose: 0,
              unitConversion: null,
              advisedItemId: "",
              wareHouseName: null,
              autoDateTimeInsert: "",
              createdBy: "",
              isActive: 1,
              advisedQuantity: 1000,
              shortageKgs: 0,
              claimDust: 0,
              claimQuality: 0,
              allowableMoisture: 0,
              premiumAmount: null,
              waterDamageAmount: null,
              juteGateEntryLineItemNo: 0,
              variableShortage: 0,
              acceptedWeight: 0,
              agentMRRate: null,
              stockQuantityConsumed: 0,
              drumConsumed: 0,
              balesConsumed: 0,
              companyId: 1,
              srcLineId: null,
              srcCompId: null,
              cropYear: null,
              marka: null,
              pota: null,
              salesBale: 0,
              salesDrum: 0,
              salesWeight: 0,
              juteIssueRate: 0,
              claimRate: 0,
              srcMrNo: null,
              srcMrLineId: null,
              convertedQuality: null,
              convertedJuteType: null,
              percentage: null,
              srcMrPrintNo: null,
              convertedBale: 0,
              convertedDrum: 0,
              convertedWeight: 0,
              jcStock: 0,
              itemDesc: "",
              qualityDesc: "",
              conItemDesc: null,
              conQltyDesc: null,
              VariableShortage: 0,
            });
            yield put(getMrLineItems(juteMrLineitems));
            var ApproveButton = false;
            var RejectButton = false;
            var UpdateButton = true;
            var EditButton = false;

            if (juteMrHeaderDataFromSource.approveStatus === true) {
              if (
                juteMrHeaderDataFromSource.status === "1" ||
                juteMrHeaderDataFromSource.status === "17" ||
                juteMrHeaderDataFromSource.status === "18" ||
                juteMrHeaderDataFromSource.status === "19" ||
                juteMrHeaderDataFromSource.status === "20" ||
                juteMrHeaderDataFromSource.status === "21"
              ) {
                ApproveButton = true;
                RejectButton = true;
                UpdateButton = true;
                EditButton = true;
              }
            } else if (
              juteMrHeaderDataFromSource.status === "1" ||
              juteMrHeaderDataFromSource.status === "17" ||
              juteMrHeaderDataFromSource.status === "18" ||
              juteMrHeaderDataFromSource.status === "19" ||
              juteMrHeaderDataFromSource.status === "20" ||
              juteMrHeaderDataFromSource.status === "21"
            ) {
              UpdateButton = true;
              EditButton = true;
            }

            if (
              juteMrHeaderDataFromSource.status === "4" ||
              juteMrHeaderDataFromSource.status === "3" ||
              juteMrHeaderDataFromSource.status === "5"
            ) {
              ApproveButton = false;
              RejectButton = false;
              UpdateButton = false;
              EditButton = false;
            }

            const juteMrHeaderFormData = {
              id: juteMrHeaderDataFromSource.id,
              goodReceiptDate: juteMrHeaderDataFromSource.goodReceiptDate,
              supplierId: juteMrHeaderDataFromSource.supplierId,
              poId: juteMrHeaderDataFromSource.poId,
              poDate: juteMrHeaderDataFromSource.poDate,
              gateEntryNo: juteMrHeaderDataFromSource.gateEntryNo,
              chalanDate: juteMrHeaderDataFromSource.chalanDate,
              challanNo: juteMrHeaderDataFromSource.challanNo,
              status: juteMrHeaderDataFromSource.status,
              submitter: juteMrHeaderDataFromSource.submitter,
              createDate: juteMrHeaderDataFromSource.createDate,
              agentId: juteMrHeaderDataFromSource.agentId,
              agentName: juteMrHeaderDataFromSource.agentName,
              sourceId: juteMrHeaderDataFromSource.sourceId,
              vehicleNo: juteMrHeaderDataFromSource.vehicleNo,
              modOn: juteMrHeaderDataFromSource.modOn,
              modBy: juteMrHeaderDataFromSource.modBy,
              weight: juteMrHeaderDataFromSource.weight,
              mukamId: juteMrHeaderDataFromSource.mukamId,
              autoDateTimeInsert: juteMrHeaderDataFromSource.autoDateTimeInsert,
              createdBy: juteMrHeaderDataFromSource.createdBy,
              companyId: juteMrHeaderDataFromSource.companyId,
              acYear: juteMrHeaderDataFromSource.acYear,
              userId: juteMrHeaderDataFromSource.userId,
              actualSupplier: juteMrHeaderDataFromSource.actualSupplier,
              advisedBroker: juteMrHeaderDataFromSource.advisedBroker,
              actualBroker: juteMrHeaderDataFromSource.actualBroker,
              brokerName: juteMrHeaderDataFromSource.brokerName,
              goodReceiptDateDesc:
                juteMrHeaderDataFromSource.goodReceiptDateDesc,
              poDateDesc: juteMrHeaderDataFromSource.poDateDesc,
              contractDateDesc: juteMrHeaderDataFromSource.contractDateDesc,
              chalanDateDesc: juteMrHeaderDataFromSource.chalanDateDesc,
              statusDesc: juteMrHeaderDataFromSource.statusDesc,
              createDateDesc: juteMrHeaderDataFromSource.createDateDesc,
              approveStatus: juteMrHeaderDataFromSource.approveStatus,
              gateEntryDate: juteMrHeaderDataFromSource.gateEntryDate,
              gateEntryChallanDate:
                juteMrHeaderDataFromSource.gateEntryChallanDate,
              juteGateNetWeight: juteMrHeaderDataFromSource.juteGateNetWeight,
              createStatus: juteMrHeaderDataFromSource.createStatus,
              unitConversion: juteMrHeaderDataFromSource.unitConversion,
              printCount: juteMrHeaderDataFromSource.printCount,
              agentAddr1: juteMrHeaderDataFromSource.agentAddr1,
              agentAddr2: juteMrHeaderDataFromSource.agentAddr2,
              juteReceiptNo: juteMrHeaderDataFromSource.juteReceiptNo,
              mrPrintNo: juteMrHeaderDataFromSource.mrPrintNo,
              frieghtPaid: juteMrHeaderDataFromSource.frieghtPaid,
              agentMRDate: juteMrHeaderDataFromSource.agentMRDate,
              agentMrPrintNo: juteMrHeaderDataFromSource.agentMrPrintNo,
              agentMrReceiptNo: juteMrHeaderDataFromSource.agentMrReceiptNo,
              srcHdrId: juteMrHeaderDataFromSource.srcHdrId,
              srcComId: juteMrHeaderDataFromSource.srcComId,
              consignmentDate: juteMrHeaderDataFromSource.consignmentDate,
              consignmentNo: juteMrHeaderDataFromSource.consignmentNo,
              remarks: juteMrHeaderDataFromSource.remarks,
              branchId: juteMrHeaderDataFromSource.branchId,
              branchName: juteMrHeaderDataFromSource.branchName,
              mrType: juteMrHeaderDataFromSource.mrType,
              tcsPercentage: juteMrHeaderDataFromSource.tcsPercentage,
              tcsAmount: juteMrHeaderDataFromSource.tcsAmount,
              mukamName: juteMrHeaderDataFromSource.mukamName,
              companyName: juteMrHeaderDataFromSource.companyName,
              transporter: juteMrHeaderDataFromSource.transporter,
              supplierName: juteMrHeaderDataFromSource.supplierName,
              advisedSuppName: juteMrHeaderDataFromSource.advisedSuppName,
              driverName: juteMrHeaderDataFromSource.driverName,
              actSup: juteMrHeaderDataFromSource.actSup,
              advisedBrokerName: juteMrHeaderDataFromSource.advisedBrokerName,
              rollbak: juteMrHeaderDataFromSource.rollbak,
              indentHdrId: juteMrHeaderDataFromSource.indentHdrId,
              poHdrId: juteMrHeaderDataFromSource.poHdrId,
              ApproveButton: ApproveButton,
              RejectButton: RejectButton,
              UpdateButton: UpdateButton,
              EditButton: EditButton,
            };
            yield put(getQuantity_ByItemId(qtyUrl));
            yield put(juteMrHeaderAction(juteMrHeaderFormData));
          }
        }
        yield put(getMaterialGoodReceiveDetailsByIdSuccessfull({ response }));
      }
    }
  } catch (error) {
    console.log(error);
    // const response = null;
    // yield put(getMaterialGoodReceiveDetailsByIdSuccessfull({ response }))
  }
}

function* UpdateJuteMRSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(updateJuteMaterialReceiptSuccessfull({ response }));
    if (response.status === true) {
      swal(response.message, { icon: "success" });
      history.push("/jute_material_receipt");
    }
  } catch (error) {
    console.log(error);
  }
}
function* quantityByItemId({ payload: { url } }) {
  try {
    const response = yield call(getListurl, url);
    if (response) {
      console.log(response);
      yield put(getQuantity_ByItemIdSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* UploadJuteMRFilesData({
  payload: { url, data, id},
}) {
  console.log(url, data,id);
  try {
    const response = yield call(postAdd, url, data);
        yield put(
          getMrFiles(
            serverApi.GET_FILES + "2/" + id + "/" + localStorage.getItem("companyId"),
          )
        );
    yield put(UploadJuteMrFilesSuccessfull({ response }));
    swal("Uploaded Successfully", { icon: "success" });
  } catch (error) {
    console.log(error);
  }
}
function* getFilesOfMr({ payload: { url, history, sourceMenu } }) {
  try {
    const response = yield call(getListurl, url, history);
    if (response) {
      yield put(getMrFilesSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}

// export function* watchJuteMaterialReceiptList() {
//   yield takeEvery(JUTE_MATERIAL_RECEIPT, JuteMaterialReceiptListSagaList);
//   yield takeEvery(GETQUALITY_BY_ITEMID, quantityByItemId);
// }
export function* watchJuteMaterialReceipt() {
  yield takeEvery(JUTE_MATERIAL_RECEIPT, JuteMaterialReceiptListSagaList);
  yield takeEvery(GETQUALITY_BY_ITEMID, quantityByItemId);
  yield takeEvery(JUTE_MATERIAL_VIEW_BYID, getJuteMaterialViewById);
  yield takeEvery(UPDATE_JUTE_MR, UpdateJuteMRSaga);
  yield takeEvery(JUTE_MR_UPLOAD_FILES,UploadJuteMRFilesData);
  yield takeEvery(GET_MR_FILES,getFilesOfMr);
}
// export function* watchJuteMaterialReceiptViewById() {
//   yield takeEvery(JUTE_MATERIAL_VIEW_BYID, getJuteMaterialViewById);
// }

// export function* watchJuteMaterialReceiptUpdate() {
//   yield takeEvery(UPDATE_JUTE_MR, UpdateJuteMRSaga);
// }

function* JuteMaterialReceiptSaga() {
  yield all([
    fork(watchJuteMaterialReceipt),
    // fork(watchJuteMaterialReceiptList),
    // fork(watchJuteMaterialReceiptViewById),
    // fork(watchJuteMaterialReceiptUpdate),
  ]);
}

export default JuteMaterialReceiptSaga;
