export const QUOTATION_LIST = "quotation_list";
export const QUOTATION_LIST_SUCCESSFULL = "quotation_list_successfull";
export const API_ERRORS = 'api_failed';
export const QUOTATION_UOM_LIST ='quotation_uom_list';
export const QUOTATION_UOM_LIST_SUCCESSFULL ='quotation_uom_list_successfull';
export const GET_UOM_RELATION_VALUES ='get_uom_relation_values';
export const GET_UOM_RELATION_VALUES_SUCCESSFULL ='get_uom_relation_values_successfull';
export const QUOTATION_ITEM_BY_ITEM_GROUP_ID ='quotation_item_by_item_group_id';
export const QUOTATION_ITEM_BY_ITEM_GROUP_ID_SUCCESSFULL ='quotation_item_by_item_group_id_successfull';

export const GET_ITEM_DETAILS_BY_ITEM_GROUP_AND_ITEM_ID_AND_COMPANY_ID ='get_item_details_by_item_group_and_item_id_and_company_id';
export const GET_ITEM_DETAILS_BY_ITEM_GROUP_AND_ITEM_ID_AND_COMPANY_ID_SUCCESSFULL ='get_item_details_by_item_group_and_item_id_and_company_id_successfull';
export const GET_ITEM_UOM_RELATION_VALUE='get_item_uom_relation_value';
export const GET_ITEM_UOM_RELATION_VALUE_SUCCESSFULL='get_item_uom_relation_value_successfull';
export const CREATE_QUOTATIONS='create_quotations';
export const CREATE_QUOTATIONS_SUCCESSFULL='create_quotations_successfull';
export const GET_QUOTATION_BROKER='get_quotation_broker';
export const GET_QUOTATION_BROKER_SUCCESSFULL='get_quotation_broker_successfull';
export const QUOTATION_HEADERS='quotation_headers';
export const CLEAR_QUOTATION_HEADERS='clear_quotation_headers';
export const QUOTATION_LINEITEMS='quotation_lineitems';
export const CLEAR_QUOTATION_LINEITEMS = 'clear_quotation_lineitems';
export const QUOTAION_VIEW_BY_ID_IN_QUOTATION = 'quotation_view_by_id-in_quotation';
export const QUOTAION_VIEW_BY_ID_IN_SUCCESSFULL = 'quotation_view_by_id_in_quotation_successfull';

export const QUOTATION_UPDATE = 'update_quotation';
export const QUOTATION_UPDATE_SUCCESSFUL = 'update_quotation_successful';





