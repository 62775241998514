import React, { Component } from 'react'
import notificationIcon from '../../assets/images/notification.png'
import CloseIcon from '../../assets/images/CloseIcon.png'

export default class NotificationPopup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorElUser: false, anchorElNav: false, open: false,
      Data: [
        {
          id: 1,
          notification: 'Leave Balance Approval',
          Description: 'Your leave request successfully approved.',
        },
        {
          id: 2,
          notification: 'Onboard Successful',
          Description: 'You have been successfully onboarded',
        },
        {
          id: 3,
          notification: 'Expense Booking Rejected',
          Description: 'Your expense booking request has been rejected.',
        },
        {
          id: 4,
          notification: 'Payslips Updated',
          Description: '   The payslips have been uploaded with the latest month payslips.',
        },
      ]
    }
  }

  removeNotification () {

  }


  render() {

    return (
      <div className='notification'>
        {this.state.Data.map((item, index) => (
          <div className='notification_content'>
            <div className='notificationIconContainer'>
              <img
                src={notificationIcon}
                alt="playstore"
                className='notificationIconBlock'
              />
            </div>
            <div className='Notifications'>
              <h3>{item.notification}</h3>
              <p>{item.Description}</p>
            </div>
            <div>
              <button
                type="button"
                className='CloseButton'
                onClick={()=>this.removeNotification(item.id)}
              >
                <img
                  src={CloseIcon}
                  alt="playstore"
                  width={10}
                  height={10}
                />
              </button>
            </div>

          </div>
        ))}



      </div>
    )
  }
}
