import React, { Component } from "react";
import { Grid } from "@mui/material";
import DynamicSelect from "./DynamicSelect";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import { getCostList } from "../../../store/Accounting/Bills/actions";
import { getDepartmentList } from "../../../store/Global/DropDownApis/actions";
import {
  getItemGroupById,
  getSrStockList,
  getSrList,
  getMachineNoList,
  getAllItemList,
} from "../../../store/StoreModule/MaterialRequest/actions";
import { getwareHouseDataList } from "../../../store/StoreModule/StockTransfer/actions";

class StockTransferLineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCount: 1,
      selectedRowId: [],

      items_List: [],
      indentLineItems: [],
      BillListData: [],
      description: this.props.header.description,
      fundsOut: this.props.header.fundsOut,
      delete: false,
      products: this.props.products,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      departmentList: [],
      itemGroupList: [],
      itemsList: [],
      itemGroupId: 0,
      srList: [],
      qunatity: 0,
      costFactorList: [],
      machineList: [],
      issueValue: 0,
      wareHouseList: [],

      hasItem: false,
    };
  }

  handleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;

    this.setState({ [key]: event });
    let mname = key.split("_");

    if (mname[0] === "quantity") {
      row.quantity = value;
      var quantityCal = row.quantity * this.state.issueValue;
      row.issueValue = quantityCal;
    }
  };
  headerValue = (key) => (event) => {
    this.setState({ [key]: event });

    if (key === "quantity") {
      this.props.products.quantity = event;
      this.setState({
        quantity: event,
      });
    }
  };
  handleChangedropdown = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    selectedItemCode
  ) => {
    let lastVal = selectedRow.id;

    this.setState({
      selectedRowId: selectedRow,
    });
    if (name === "department") {
      this.props.getItemGroupById(
        serverApi.ITEM_GROUP_LIST +
          selectedValue +
          "/" +
          localStorage.getItem("companyId") +
          "/getAllItemGroupsByDepartmentId/" +
          this.state.userDit.cipher,
        this.state.rowCount,
        this.props.history
      );

      this.props.getCostList(
        serverApi.COST_FACTOR_LIST +
          localStorage.getItem("companyId") +
          "/" +
          selectedValue,
        this.props.history
      );
      selectedRow.deptId = selectedValue;
      selectedRow.departmentName = selectedName;
      let lastRow = this.props.products[this.props.products.length - 1];
      this.setState({
        rowCount: this.state.rowCount + 1,
      });
      if (selectedRow === lastRow && selectedValue !== "0" && lastVal === "0") {
        this.handleAddEvent();
      }
    }

    if (name === "itemGroup") {
      const data = {
        companyId: localStorage.getItem("companyId"),
        consumable: "",
        indentTypeId: this.props.header.indentTypeId,
        itemGroupId: selectedValue,
        saleable: "",
        inActiveItems: true,
      };
      this.props.getAllItemList(
        serverApi.GET_ITEMSBY_FORPROCUREMENT,
        data,
        this.props.history
      );

      selectedRow.itemGroupId = selectedValue;
      selectedRow.itemGroupName = selectedName;

      this.setState({
        itemGroupId: selectedValue,
      });
    }

    if (name === "item") {
      let uomValue = selectedName;
      let uom = uomValue.split("^");

      const data = {
        branchId: this.props.header.branchId,
        companyId: localStorage.getItem("companyId"),
        itemGrpId: this.state.itemGroupId,
        itemId: selectedValue,
      };
      this.props.getSrStockList(
        serverApi.SR_STOCK_LIST,
        data,
        this.props.history
      );

      const data1 = {
        branchId: this.props.header.branchId,
        companyId: localStorage.getItem("companyId"),
        itemGrpId: this.state.itemGroupId,
        itemId: selectedValue,
      };
      this.props.getSrList(serverApi.SR_LIST, data1, this.props.history);

      let itemName = selectedName;
      let itemName2 = itemName.split("^");
      selectedRow.itemId = selectedValue;

      selectedRow.itemCode = selectedItemCode;
      selectedRow.itemName = itemName2[0];
      selectedRow.uom = uom[1];
    }

    if (name === "srNo") {
      let quantityCal = selectedName;
      let quantity = quantityCal.split("_");
      let issueValue = quantityCal.split("_");
      let srPrintNo = quantityCal.split("_");

      selectedRow.srNo = selectedValue;
      selectedRow.quantity = quantity[2];
      selectedRow.srStock = quantity[2];
      selectedRow.issueValue = issueValue[1] * selectedRow.quantity;
      selectedRow.srPrintNo = srPrintNo[5];
      selectedRow.inwardId = srPrintNo[3];

      this.setState({
        issueValue: issueValue[1],
      });
    }

    if (name === "wareHouseNo") {
      selectedRow.wareHouseNo = selectedValue;

      this.setState({
        wareHouseNo: selectedValue,
      });
    }
  };

  handleAddEvent(evt) {
    let rowId = this.state.rowCount;

    let product = {
      id: "0",
      no: rowId,
      deptId: 0,
      quantity: 0,

      branchStock: 0,
      itemId: 0,
      itemGroupId: 0,

      issueValue: 0,

      srNo: "",
      srStock: 0,
      itemCode: 0,
      itemName: "",
      departmentName: "",
      itemGroupName: "",
      uom: "",
      costFactorName: "",
      srPrintNo: "",
      inwardId: 0,
      wareHouseNo: 0,
    };

    this.setState({ rowCount: rowId + 1 });
    this.props.products.push(product);
  }

  componentDidMount() {
    this.props.getDepartmentList(
      serverApi.GET_DEPARTMENT_LIST +
        localStorage.getItem("companyId") +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    );

    const data = {
      branchId: this.props.header.branchId,
      companyId: localStorage.getItem("companyId"),
      type: "S",
    };
    this.props.getwareHouseDataList(
      serverApi.WARE_HOUSE_LIST,
      data,
      this.props.history
    );
  }

  onDel() {
    this.setState({
      delete: false,
    });
  }

  onDelEvent = (data) => (event) => {
    const { key } = data;
    this.setState({ [key]: event });

    let filterProducts = this.state.products.filter(
      (item) => item.trnDetailId != key
    );

    this.setState({
      delete: true,
      products: filterProducts,
    });

    this.props.delete(filterProducts, key);
  };

  componentWillReceiveProps(props) {
    if (props.itemGroupList.data) {
      this.state.selectedRowId.itemGroupList = props.itemGroupList.data;
      this.setState({
        itemGroupList: props.itemGroupList.data,
      });
    }

    if (props.srList) {
      this.state.selectedRowId.srList = this.state.selectedRowId.itemId
        ? props.srList
        : [];
      this.setState({
        srList: props.srList,
      });
    }

    if (props.departmentList.data) {
      this.setState({
        departmentList: props.departmentList.data,
      });
    }

    if (props.itemList) {
      var list = [];
      props.itemList.map((prop) => {
        list.push({
          label: prop.label,
          name: prop.name,
          value: prop.value,
          variant: prop.itemCode,
        });
      });
      this.state.selectedRowId.itemsList = this.state.selectedRowId.itemGroupId
        ? list
        : [];

      this.state.selectedRowId.branchStock = this.state.selectedRowId.itemId
        ? props.srStockList.data
        : 0;
      this.setState({
        itemsList: list,
      });
    }

    if (props.wareHouseList) {
      this.setState({
        wareHouseList: props.wareHouseList,
      });
    }
  }

  render() {
    return (
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent">
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {this.props.tableHeaders.map((column) => (
                    <th>{column.header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {this.props.products.map((row, rowIndex) => (
                  <tr>
                    <>
                      {this.props.RowData &&
                        this.props.RowData.map((col) =>
                          col.component === "Inputadorment" ? (
                            <td>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      name={col.type + "_" + rowIndex}
                                      id={col.no}
                                      Value={
                                        col.type === "quantity"
                                          ? row.quantity
                                          : col.type === "branchStock"
                                          ? row.branchStock
                                          : col.type === "srStock"
                                          ? row.srStock
                                          : col.type === "issueValue"
                                          ? row.issueValue
                                          : ""
                                      }
                                      onChange={this.handleChange({
                                        key: col.type + "_" + rowIndex,
                                        row,
                                      })}
                                      className="inputBlockContainer"
                                    />
                                    <span style={{ zIndex: "1" }}>
                                      {col.type === "quantity" ? row.uom : ""}
                                    </span>
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          ) : (
                            <td>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <DynamicSelect
                                      arrayOfData={
                                        col.type === "department"
                                          ? this.state.departmentList
                                          : col.type === "itemGroup"
                                          ? row.itemGroupList
                                          : col.type === "item"
                                          ? row.itemsList
                                          : col.type === "srNo"
                                          ? row.srList
                                          : col.type === "wareHouseNo"
                                          ? this.state.wareHouseList
                                          : []
                                      }
                                      className="dropdownBlockContainer"
                                      onSelectChange={this.handleChangedropdown}
                                      selected={
                                        col.type === "department"
                                          ? row.deptId
                                          : col.type === "itemGroup"
                                          ? row.itemGroupId
                                          : col.type === "item"
                                          ? row.itemId
                                          : col.type === "srNo"
                                          ? row.srNo
                                          : col.type === "wareHouseNo"
                                          ? row.wareHouseNo
                                          : ""
                                      }
                                      name={
                                        col.name === "department"
                                          ? "department"
                                          : col.name === "itemGroup"
                                          ? "itemGroup"
                                          : col.name === "item"
                                          ? "item"
                                          : col.name === "srNo"
                                          ? "srNo"
                                          : col.name === "wareHouseNo"
                                          ? "wareHouseNo"
                                          : ""
                                      }
                                      row={row}
                                      value={
                                        col.type === "department"
                                          ? row.deptId
                                          : col.type === "itemGroup"
                                          ? row.itemGroupId
                                          : col.type === "item"
                                          ? row.itemId
                                          : col.type === "srNo"
                                          ? row.srNo
                                          : col.type === "wareHouseNo"
                                          ? "wareHouseNo"
                                          : ""
                                      }
                                      update={this.props.location.state ? 1 : 0}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          )
                        )}
                    </>
                    <td className="">
                      <input
                        type="button"
                        onClick={this.onDelEvent({ key: row.trnDetailId, row })}
                        value="X"
                        className="deleteDisplayButton"
                      />
                      {this.state.delete && (
                        <CommonPopup>
                          <div className="delAddItem">
                            <div>Are you sure you want to delete?</div>
                            <div className="delAddItemBtns">
                              <input
                                type="button"
                                onClick={this.onDel.bind(this)}
                                value="Yes"
                                className="delYesBtn"
                              />
                              <input
                                type="button"
                                onClick={this.onDel.bind(this)}
                                value="No"
                                className="delNoBtn"
                              />
                            </div>
                          </div>
                        </CommonPopup>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { itemGroupList, srStockList, srList, machineList, itemList } =
    state.Material_Request_List;
  const { wareHouseList } = state.StockTransfer_List;
  const { costFactorList } = state.BillsReducer;
  const { departmentList, itemsListForIndent } = state.DropDownListReducer;

  return {
    departmentList,
    itemGroupList,
    itemsListForIndent,
    srStockList,
    srList,
    costFactorList,
    machineList,
    itemList,
    wareHouseList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getDepartmentList,
    getItemGroupById,
    getAllItemList,
    getSrStockList,
    getSrList,
    getCostList,
    getMachineNoList,
    getwareHouseDataList,
  })(StockTransferLineItem)
);
