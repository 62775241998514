import {
  TROLLY_DD_LIST,
  WINDING_DOFF_ENTRY_LIST,
  WINDING_DOFF_ROW_DELETE,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getListurl, getList } from "../../../helpers/Server_Helper";
import {
  windingDoffListSuccessfull,
  windingDoffDeleteRowSuccessfull,
  windingDoffList,
  getTrollyDdListSuccessfull,
} from "./actions";
import swal from "sweetalert";
import { serverApi } from "../../../helpers/Consts";

function* windingDoffEntrySaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(windingDoffListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* windingDelSaga({
  payload: { url, data, history, fromDate, toDate },
}) {
  try {
    const response = yield call(getListurl, url);
    if (response) {
      const Data = {
        companyId: localStorage.getItem("companyId"),
        fromDate: fromDate,
        toDate: toDate,
      };
      yield put(windingDoffList(serverApi.GET_DOFF_ENTRY_LIST, Data, history));
      yield put(windingDoffDeleteRowSuccessfull({ response }));
      swal(response.message, { icon: "error" });
    }
  } catch (error) {
    console.log(error);
  }
}
function* trollyDdListSaga({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getTrollyDdListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

export function* watchWindingDoffEntry() {
  yield takeEvery(WINDING_DOFF_ENTRY_LIST, windingDoffEntrySaga);
  yield takeEvery(WINDING_DOFF_ROW_DELETE, windingDelSaga);
  yield takeEvery(TROLLY_DD_LIST, trollyDdListSaga);
}

function* windingDoffSaga() {
  yield all([fork(watchWindingDoffEntry)]);
}

export default windingDoffSaga;
