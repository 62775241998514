import React, { Component } from "react";
import Select from "react-select";
import FormControl from "@mui/material/FormControl";

class DynamicSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedlable: "",
      selectedValue:"",
      selectedName:"",
    };
  }

  handleChange = (event) => {
    let selectedValue = event.value;
    let selectedName = event.name;
    this.setState({
      selectedValue: selectedValue,
      selectedName: selectedName,
    })
    this.props.onSelectChange(selectedValue, selectedName, this.props.name, this.props.row);
  };

  componentWillReceiveProps(props) {
    if (this.props.arrayOfData) {
      this.props.arrayOfData.forEach((row) => {
        if (this.props.selected == row.value) {
          this.setState({
            selectedlable: row.label,
            selectedValue: this.props.selected,
            selectedName: row.label,
          })    
        }
      });
    }
  }

  render() {
    // if(this.props.update === 1) {
    //   if(this.props.arrayOfData){
    //     this.props.arrayOfData.map((row) => {
    //       if(this.props.selected === row.value) {
    //         this.state.selectedValue = this.props.selected
    //         this.state.selectedlable = row.label
    //         this.state.selectedName = row.label
    //       }
    //     })
    //   }
    // }else {

      if(this.props.arrayOfData){
        this.props.arrayOfData.map((row) => {
          if(this.props.selected === row.value) {
            this.state.selectedValue = this.props.selected
            this.state.selectedlable = row.label
            this.state.selectedName = row.label
          }
        })
      }
    // }
  
    return (
      <FormControl sx={{ width: "100%" }} className="customDropdown">
        <div>
          <label htmlFor="app-input-field">{this.props.label} </label>
          {this.props.required && <div className="mandatoryField">*</div>}
          
        </div>

      <Select
          classNamePrefix="react-select"
          options={this.props.arrayOfData}
          menuPosition="fixed"
          menuPlacement="auto"
          defaultValue={this.props.selected ? { value: this.props.selected, label: this.state.selectedlable}: ""}
          value={this.props.selected ? { value: this.state.selectedValue, label: this.state.selectedName}: ""}
          placeholder="Select"
          onChange={this.handleChange}
          isDisabled={this.props.update === 1 ? this.props.isDisabled : false}
        />
        {/* } */}
      </FormControl>
    );
  }
}
export default DynamicSelect;
