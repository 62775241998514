import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import DynamicSelect from "../Dropdown/DynamicSelect";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DesktopDatePicker from "@mui/lab/DatePicker";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';   
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';   
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'; 
import moment from "moment";
import { serverApi } from "../../helpers/Consts";
import { getEmployeeList } from '../../store/HRMS/EmployeeDataBase/actions'

class AttendanceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userDit: JSON.parse(localStorage.getItem("authUser")),
            Company_ID: localStorage.getItem("companyId"),
            open: false,
            fromDate: this.props.From_date,
            toDate: this.props.toDate,
            // fromDate: this.props.From_date,
            // toDate: this.props.toDate,
            employeeListData: [],
            employee_code: "",
            // Company_ID: "",
            options: []
        }
    }
    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    };

    handleClose = () => {
        this.setState({
            open: false,
        });
    };
    handleSelectDate = (e, name) => {
        if (name === "FromDate") {
            let date = moment(e).format()
            let formattedFromDate = date.split("T", 1).toString()
            this.setState({
                fromDate: formattedFromDate
            })
            this.props.handlegetdates(formattedFromDate, this.state.toDate);
            // this.props.From_date=formattedFromDate
        }
        if (name === "ToDate") {
            let date = moment(e).format()
            let formattedToDate = date.split("T", 1).toString()
            this.setState({
                toDate: formattedToDate
            })
            this.props.handlegetdates(this.state.fromDate, formattedToDate);
        //  this.props.toDate=formattedToDate
        }
    }
    handleSelectChange = (selectedValue, selectedName, name) => {
        if (name === "Employee") {
            this.setState({
                employee_code: selectedValue
            });
        }
        if (name === 'Company') {
            this.setState({
                Company_ID: selectedValue
            })
        }
    }
    handleSubmit = () => {
        const data = {
            companyid: this.state.Company_ID,
            employeeid: this.state.employee_code,
            from: this.state.fromDate,
            to: this.state.toDate
        }
        this.props.handle_search(data);
        this.setState({
            open: false,
        });
    }
    componentWillReceiveProps(props) {
        if (props.employeeList) {
            this.setState({
                employeeListData: props.employeeList.data,
                // employeeListColumn: props.employeeList.column,

            });
        }
    }
    componentDidMount() {
        this.setState({
            options: this.state.userDit.companyDetails.map((item) => ({
                label: item.label,
                value: item.value,
            })),
        });
        const data = {   
                companyId: localStorage.getItem("companyId"),
                deptId: 0,
                ebNo: "",
                empName: "",
                empStatus: "",
                esiNo: "",
                lastIndex: 0,
                payScheme: 0,
                pfNo: "",
                reporting: 0,
                startIndex: 1,
                userId: this.state.userDit.userId,
              }
              this.props.getEmployeeList(serverApi.EMPLOYEE_LIST, data, this.props.history);
    }
    render() {
        return (
            <>
                <Box>
        
                    <Button onClick={this.handleClickOpen}>
                        <Tooltip title="Filter">
                            <FilterListOutlinedIcon />
                        </Tooltip>
                    </Button>
                    <Dialog
                        open={this.state.open}
                        PaperProps={{
                            sx: {
                                position: " fixed",
                                top: 0,
                                right: 0,
                                margin: 0,
                                bottom: 0,
                                maxHeight: "100vh",
                            },
                        }}
                        keepMounted
                        onClose={this.handleClose}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <Grid
                            spacing={0}
                        >
                            <div className="consoleTextFieldBlock">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginBottom: "20px",
                                    }}
                                >
                                    <div className="filterBlockHeader">
                                        <FilterListOutlinedIcon />
                                        <span>Filter</span>
                                    </div>
                                </div>
                            </div>
                            <Grid item xs={12} className="filterBlockContainer">
                                <Box gridColumn="span 3">
                                    <div className="filterDateBlock">
                                        <div className="consoleTextFieldBlock">
                                            <div>
                                                <div className="TextFieldBlock">
                                                    <label>From Date</label>
                                                </div>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        inputFormat="dd-MM-yyyy"
                                                        name="dateOne"
                                                        value={this.state.fromDate}
                                                        fullWidth
                                                        onChange={(e) => this.handleSelectDate(e, "FromDate")}
                                                        renderInput={({
                                                            inputRef,
                                                            inputProps,
                                                            InputProps,
                                                        }) => (
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                                className="fromDatePicker"
                                                            >
                                                                <input ref={inputRef} {...inputProps} />
                                                                {InputProps?.endAdornment}
                                                            </Box>
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                        <div className="consoleTextFieldBlock">
                                            <div>
                                                <div className="TextFieldBlock">
                                                    <label>To Date</label>
                                                </div>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        inputFormat="dd-MM-yyyy"
                                                        name="dateOne"
                                                        value={this.state.toDate}
                                                        fullWidth
                                                        onChange={(e) => this.handleSelectDate(e, "ToDate")}
                                                        renderInput={({
                                                            inputRef,
                                                            inputProps,
                                                            InputProps,
                                                        }) => (
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}
                                                                className="toDatePicker"
                                                            >
                                                                <input ref={inputRef} {...inputProps} />
                                                                {InputProps?.endAdornment}
                                                            </Box>
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                                <Box gridColumn="span 3">
                                    <div className="consoleTextFieldBlock">
                                        <DynamicSelect
                                            arrayOfData={this.state.employeeListData &&this.state.employeeListData.map(item=>({
                                                label:item.ebNo,
                                                value:item.ebId
                                            })) }
                                            className="dropdownBlockContainer"
                                            menuPosition="fixed"
                                            menuPlacement="auto"
                                            onSelectChange={this.handleSelectChange}
                                            selected={this.state.employee_code}
                                            name="Employee"
                                            label="Employee Code"
                                        />
                                    </div>
                                </Box>
                                {/* <Box gridColumn="span 3">
                                    <div className="consoleTextFieldBlock">
                                        <DynamicSelect
                                            arrayOfData={this.state.options}
                                            className="dropdownBlockContainer"
                                            menuPosition="fixed"
                                            menuPlacement="auto"
                                            onSelectChange={this.handleSelectChange}
                                            selected={this.state.Company_ID}
                                            name="Company"
                                            label="Company"
                                        />
                                    </div>
                                </Box> */}
                                <div className="consoleFormButtonBlock">
                                    <CustomButton
                                        label={"Search"}
                                        className="greenButton"
                                        handleClick={this.handleSubmit}
                                        type="sumbit"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Dialog>
                </Box>
            </>
        )
    }
}
const mapStatetoProps = (state) => {
    const { employeeList } = state.EmployeeReducer;
    return { employeeList };
};

export default withRouter(
    connect(mapStatetoProps, {
        getEmployeeList
    })(AttendanceList)
);