import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  CHECK_LOGIN,
  LOGOUT_USER,
  CHECK_TOKEN,
  RESET_PASSWORD,
} from "./actionTypes";
import {
  apiError,
  loginUserSuccessful,
  checkTokenUserSuccessful,
  checkLogin,
} from "./actions";
import { getAcadamicYears } from "../FinancialYear/actions";
import {
  getLeftSideMenu,
  getVendorLeftSideMenu,
} from "../../Company/BasicDetails/LeftSideBar/actions";
// AUTH related methods
import {
  postLogin,
  getToken,
  postResetPwd,
} from "../../../helpers/fackBackend_Helper";
import { serverApi } from "../../../helpers/Consts";
import { supplierMasterViewById } from "../../../store/Master/SupplierMaster/actions";
import swal from "sweetalert";

//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: { url, user, history } }) {
  try {
    const response = yield call(postLogin, url, {
      userName: user.username,
      password: user.password,
      reqSource: user.reqSource,
    });

    if (response.loginStatus) {
      localStorage.setItem("authUser", JSON.stringify(response));
      localStorage.setItem("dashboard", 1);
      //THIS CODE IS FOR VENDOR LOGIN
      if (response.roleId) {
        if (response.roleId === 100) {
          yield put(
            supplierMasterViewById(
              serverApi.SUPPLIER_VIEWBY_ID +
                "/" +
                response.suppCode +
                "/" +
                response.userId
            )
          );
        }
      }

      let companyId = response.companyDetails[0].value;
      if (companyId) {
        var urlfy = serverApi.GET_ACADEMIC_YEARS_LIST + companyId;
        yield put(getAcadamicYears(urlfy, history));
        let vendorMenuUrl = serverApi.VENDOR_LEFT_SIDE_MENU;
        if (response.roleId === 100) {
          yield put(getVendorLeftSideMenu(vendorMenuUrl));
        } else {
          let menuurl = serverApi.LEFT_SIDE_MENU + "0/" + companyId;
          yield put(getLeftSideMenu(menuurl));
        }
      }
    }
    yield put(loginUserSuccessful(response));
    if (user.password === "welcome") {
      history.push("/CreatePassword");
    } else {
      history.push("/dashboard");
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* checkToken({ payload: { url, user, history } }) {
  try {
    const response = yield call(getToken, url, {
      username: user.username,
      password: user.password,
      grant_type: "password",
    });

    if (response) {
      localStorage.setItem("myToken", JSON.stringify(response));
      localStorage.setItem("access_token", response.access_token);
      localStorage.setItem("token_type", response.token_type);
      let username = user.username;
      let password = user.password;
      let reqSource = 0;
      const mdata = {
        username,
        password,
        reqSource,
      };

      yield put(checkLogin(serverApi.LOGIN, mdata, history));

      yield put(checkTokenUserSuccessful(response));
    }
  } catch (error) {
    swal("Enter correct username or password", { icon: "error" });
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    localStorage.removeItem("myToken");
    localStorage.removeItem("access_token");
    localStorage.removeItem("token_type");
    localStorage.removeItem("dashboard", 0);
    localStorage.removeItem("company", 0);
    localStorage.removeItem("config", 0);
    localStorage.removeItem("module", 0);
    localStorage.removeItem("usermange", 0);
    localStorage.removeItem("billing", 0);
    localStorage.removeItem("companyId", 0);
    localStorage.removeItem("acadamicYear");
    localStorage.removeItem("acadamicYearName");
    localStorage.removeItem("acadamicYearsList");
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* resetPassword({ payload: { url, user, history } }) {
  try {
    const response = yield call(postResetPwd, url, {
      userId: user.userId,
      forgotPassword: false,
      newPassword: user.password,
    });
    history.push("/logout");
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(CHECK_LOGIN, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}
export function* watchUserToken() {
  yield takeEvery(CHECK_TOKEN, checkToken);
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

function* loginSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchUserToken),
    fork(watchResetPassword),
  ]);
}

export default loginSaga;
