import {
  all,
  call,
  fork,
  takeEvery,
  put,
  select,
  take,
} from "redux-saga/effects";

import {
  CREATE_SALES_ORDER,
  CREATE_JUTEGOVT_SALES_ORDER,
  GET_UOM_RELATION_VALUE_BY_UOM_CODE,
  GET_ALL_BROKER,
  ACCOUNT_LIST,
  GET_BRANCH,
  GET_QUOTATION_RECORDS,
  QUOTATION_VIEW_BY_ID,
  SALESORDER_LIST,
  SALES_ORDER_BY_ID,
  UPDATE_SALES_ORDER,
  QUOTE_VIEW_BY_ID_FOR_JUTEGOVT,
  S_O_BY_ID_FOR_JUTEGOVT,
  UPDATE_JUTEGOVT_SALES_ORDER,
  CREATE_JUTEYARN_SALES_ORDER,
  QUOTE_VIEW_BY_ID_FOR_JUTEYARN,
  S_O_BY_ID_FOR_JUTEYARN,
  UPDATE_JUTEYARN_SALES_ORDER,
  CREATE_JUTEHESSIAN_SALES_ORDER,
  SALES_ORDER_JUTEHESSIAN_BY_ID,
  UPDATE_JUTEHESSIAN_SALES_ORDER,
  SO_JUTE_HESSIAN_QUOTATION_VIEW_BY_ID,
  SALES_ORDER_ITEMS_BY_ITEM_GROUP_ID,
  ITEMS_BY_ITEM_GROUP_ID_FOR_JUTEGOVT,
  ITEMS_BY_ITEM_GROUP_ID_FOR_JUTEYARN,
  TERMS_CONDITIONS_API,
  SALES_ORDER_HESSIAN_ITEMS_BY_ITEM_GROUP_ID,
  GET_UOM_RELATION_VALUE_HESSIAN,
  GET_SO_FILES,
  DELETE_SO_FILE,
  UPLOAD_SO_FILES,
  UOM_LIST_BASEDON_ITEM_ID_HESSIAN,
  SO_JUTE_YARN_UOM_LIST,
  REGULAR_SO_ITEM_NAME_DATA,
  JUTE_GOVT_SO_ITEM_NAME_DATA,
  JUTE_YARN_SO_ITEM_NAME_DATA,
  JUTE_HESSIAN_SO_ITEM_NAME_DATA,
} from "./actionTypes";
import {
  SalesOrderListSuccessfull,
  getAllBrokerListSuccessfull,
  getSalesOrderSuccessfull,
  setSalesOrderSuccessfull,
  SalesOrderByIdSuccessfull,
  getSalesOrderBranchSuccessfull,
  getBankAccountListSuccessfull,
  getQuotationRecordsSuccessfull,
  getSalesOrderHeader,
  SalesOrderLineItems,
  SalesOrderJuteGovtHeaders,
  SalesOrderJuteGovtLineItems,
  SalesOrderJuteYarnHeaders,
  SalesOrderJuteYarnLineItems,
  CreateJuteYarnSalesOrderSuccessfull,
  getQuoteViewByIdForJuteYarnSuccessfull,
  SOByIdForJuteYarnSuccessfull,
  getUomRelationValueByUomCodeSuccessfull,
  UpdateJuteYarnSalesOrderSuccessfull,
  getItemsByItemGroupIdForJuteYarnSuccessfull,
  getQuotationViewByIdSuccessfull,
  CreateJuteGovtSalesOrderSuccessfull,
  getQuoteViewByIdForJuteGovtSuccessfull,
  SOByIdForJuteGovtSuccessfull,
  UpdateJuteGovtSalesOrderSuccessfull,
  CreateJuteHessianSalesOrderSuccessfull,
  SalesOrderJuteHessianLineItems,
  SalesOrderJuteHessianHeaders,
  SalesOrderJuteHessianByIdSuccessfull,
  UpdateJuteHessianSalesOrderSuccessfull,
  getQuotationViewByIdForJuteHessianSuccessfull,
  getSalesOrderItemsByItemGroupIdSuccessfull,
  getTermsConditionsSuccessfull,
  getSalesOrderHessianItemsByItemGroupIdSuccessfull,
  getUomRelationHessianSuccessfull,
  deleteSoFileSuccessfull,
  SOFilesSuccessfull,
  getSOFiles,
  getUomListBasedOnItemIdHessianSuccess,
  getSoYarnUomListSuccessfull,
  getRegularSoItemNameDataSuccessfull,
  getJuteGovtSoItemNameDataSuccessfull,
  getJuteYarnSoItemNameDataSuccessfull,
  getJuteHessianSoItemNameDataSuccessfull,
} from "./actions";

import { getList, getListurl, postAdd } from "../../../helpers/Server_Helper";
import { InvoiceLineItemsData } from "../../Sales/InVoice/actions";
import swal from "sweetalert";
import { getBillingShippingList } from "../../../store/Global/DropDownApis/actions";
import { serverApi } from "../../../helpers/Consts";
import moment from "moment";
import { number } from "../../../Utilities/Validations";
import {
  convertOtherUOMTOPrimaryUOM,
  limitDecimals,
  convertOtherRateUOmToPrimaryUOM,
  getStatus,
  isEmpty,
} from "../../../Utilities/helper";
import { showSpinner, hideSpinner } from "../../../helpers/Consts";
import { SalesGstCalculations } from "../../../Utilities/SalesGstCalculation";
import { getUomRelationValues } from "../../../store/Sales/Qutation/actions";

function* UploadedFileList({
  payload: { url, data, history, sourceMenu, regularSalesId },
}) {
  try {
    const response = yield call(postAdd, url, data);
    if (response) {
      swal("Uploaded Successfully", { icon: "success" });
      let Url =
        serverApi.GET_FILES +
        "45/" +
        regularSalesId +
        "/" +
        localStorage.getItem("companyId");
      yield put(getSOFiles(Url, history));
      yield put(SOFilesSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getAllFileList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url, history);
    yield put(SOFilesSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* deleteFile({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(deleteSoFileSuccessfull({ response }));
    if (response.data.message == "Success.") {
      swal("Successfully Deleted", { icon: "success" });
    }
  } catch (error) {
    console.log(error);
  }
}
function* getSalesOrderList({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    if (response) {
      hideSpinner();
      yield put(SalesOrderListSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getBankDetailsData({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(getBankAccountListSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
    history.push("/s_order");
  } catch (error) {
    console.log(error);
  }
}
function* getAllBroker({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getAllBrokerListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getSalesOrderBranchData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getSalesOrderBranchSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getSalesOrderData({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    if (response) {
      hideSpinner();
      yield put(getSalesOrderSuccessfull({ response }));
      swal(response.data.message, { icon: "success" });
      history.push("/s_order");
    }
  } catch (error) {
    console.log(error);
  }
}
//Jute Hessian Sales Order
function* CreateJuteHessianSalesOrderData({ payload: { url, data, history } }) {
  try {
    showSpinner()
    const response = yield call(postAdd, url, data);
    yield put(CreateJuteHessianSalesOrderSuccessfull({ response }));
    if(response.data.status){
      hideSpinner()
      swal(response.data.message, { icon: "success" });
      history.push("/s_order");
    } 
  }catch (error) {
    console.log(error);
  }
}

function* SalesOrderJuteHessianById({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (response.data) {
      var CustomerUrl =
        serverApi.GET_ALL_BILLING_SHIPPING +
        response.data.salesOrderDTO.customerId +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher +
        "?isForSalesModule=1";
      yield put(getBillingShippingList(CustomerUrl, history));
      if (response.data.salesOrderDTO.salesOrderLineItemDTO) {
        if (response.data.salesOrderDTO.salesOrderLineItemDTO.length !== 0) {
          var SOJuteHessianUpdate = [];
          response.data.salesOrderDTO.salesOrderLineItemDTO.map((item, i) => {
            SOJuteHessianUpdate.push({
              id: i,
              regularSalesOrderLineItemId: item.regularSalesOrderLineItemId,
              regularSalesOrderId: item.regularSalesOrderId,
              createdBy: item.createdBy,
              createdDate: item.createdDate,
              companyId: item.companyId,
              hsncode: item.hsncode,
              itemGroupId: item.itemGroupId,
              itemId: item.itemId,
              itemGroupName: item.itemGroupName,
              itemGroupValue: item.itemGroupValue,
              itemName: item.itemName,
              itemCode: item.itemCode,
              itemMake: item.itemMake,
              quantity: limitDecimals(item.quantity, 3),
              uom: item.uom,
              rate: limitDecimals(item.rate, 3),
              doBalQty: item.doBalQty,
              invoiceBalQty: item.invoiceBalQty,
              igstAmount: item.igstAmount == null ? "0.00" : item.igstAmount,
              igstPercent: item.igstPercent == null ? "0" : item.igstPercent,
              cgstAmount: item.cgstAmount == null ? "0.00" : item.cgstAmount,
              cgstPercent: item.cgstPercent == null ? "0" : item.cgstPercent,
              sgstAmount: item.sgstAmount == null ? "0.00" : item.sgstAmount,
              sgstPercent: item.sgstPercent == null ? "0" : item.sgstPercent,
              taxPercent: limitDecimals(item.itemTaxPercentage, 2),
              discountType: item.discountType,
              discount: limitDecimals(item.discount, 2),
              discountedRate: limitDecimals(item.discountedRate, 3),
              discountName: item.discountTypeName,
              discountAmount: limitDecimals(item.discountAmount),
              netAmount: limitDecimals(item.netAmount, 2),
              totalAmount: limitDecimals(item.totalAmount, 2),
              remarks: item.remarks,
              hessianSalesOrderLineItemId: item.hessianSalesOrderLineItemId,
              hessianRateIncludingBrokerage: limitDecimals(
                item.hessianRateIncludingBrokerage,
                3
              ),
              hessianRateExcludingBrokerage: limitDecimals(
                item.hessianRateExcludingBrokerage,
                2
              ),
              hessianQuantityUom: item.hessianQuantityUom,
              hessianQuantityUom2: item.hessianQuantityUom2,
              hessianRemarks: item.hessianRemarks,
              hessianQuantityUomId: item.hessianQuantityUomId,
              hessianQuantityRelationValue: item.hessianQuantityRelationValue,
              hessianQuantityCalculatedValue: limitDecimals(
                item.hessianQuantityCalculatedValue,
                3
              ),
              hessianRateUom: item.hessianRateUom,
              hessianRateUomId: item.hessianRateUomId,
              hessianRateRelationValue: item.hessianRateRelationValue,
              hessianRateCalculatedValue: limitDecimals(
                item.hessianRateCalculatedValue,
                3
              ),
              updatedBy: item.updatedBy,
              updatedOn: item.updatedOn,
              isActive: item.isActive,
              hessianIsActive:item.hessianIsActive
            });
          });
          SOJuteHessianUpdate.push({
            id: SOJuteHessianUpdate.length + 1,
            regularSalesOrderLineItemId: null,
            regularSalesOrderId: null,
            createdBy: "",
            createdDate: "",
            companyId: "",
            hsnCode: "",
            itemGroupId: "",
            itemId: "",
            itemMake: "",
            quantity: "",
            uom: "",
            rate: "",
            doBalQty: "",
            invoiceBalQty: "",
            igstAmount: "",
            igstPercent: "",
            cgstAmount: "",
            cgstPercent: "",
            sgstAmount: "",
            sgstPercent: "",
            discountType: "",
            discount: "",
            discountedRate: "",
            netAmount: "",
            totalAmount: "",
            remarks: "",
            hessianRateIncludingBrokerage: "",
            hessianRateExcludingBrokerage: "",
            hessianQuantityUom: "",
            hessianQuantityUom2: "",
            hessianRemarks: "",
            updatedBy: null,
            updatedOn: null,
          });
          yield put(SalesOrderJuteHessianLineItems(SOJuteHessianUpdate));
        }
      }
      var TotalCGST = response.data.salesOrderDTO.salesOrderLineItemDTO
        .filter((item) => item.isActive != 0)
        .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
        .reduce((cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind));
      var TotalSGST = response.data.salesOrderDTO.salesOrderLineItemDTO
        .filter((item) => item.isActive != 0)
        .map((p) => (p.sgstAmount == null ? 0 : p.sgstAmount))
        .reduce((sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind));
      var TotalIGST = response.data.salesOrderDTO.salesOrderLineItemDTO
        .filter((item) => item.isActive != 0)
        .map((p) => (p.igstAmount == null ? 0 : p.igstAmount))
        .reduce((igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind));
      var netAmount = response.data.salesOrderDTO.salesOrderLineItemDTO
        .filter((item) => item.isActive != 0)
        .map((p) => p.netAmount)
        .reduce((netAmount, ind) => parseFloat(netAmount) + parseFloat(ind));
      var taxTotals = Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);

      var createddate =
        response.data.salesOrderDTO.createdDate !== null &&
        response.data.salesOrderDTO.createdDate.split("T");
      var salesdate =
        response.data.salesOrderDTO.salesDate !== null &&
        response.data.salesOrderDTO.salesDate.split("T");
      var salesOrderExpirydate =
        response.data.salesOrderDTO.salesOrderExpiryDate !== null &&
        response.data.salesOrderDTO.salesOrderExpiryDate.split("T");
      var ApproveButton = false;
      var RejectButton = false;
      var UpdateButton = true;
      var EditButton = false;

      if (response.data.salesOrderDTO.approveStatus === true) {
        if (
          response.data.salesOrderDTO.statusId === 1 ||
          response.data.salesOrderDTO.statusId === 17 ||
          response.data.salesOrderDTO.statusId === 18 ||
          response.data.salesOrderDTO.statusId === 19 ||
          response.data.salesOrderDTO.statusId === 20
        ) {
          ApproveButton = true;
          RejectButton = true;
          UpdateButton = true;
          EditButton = true;
        }
      } else if (
        response.data.salesOrderDTO.statusId === 1 ||
        response.data.salesOrderDTO.statusId === 17 ||
        response.data.salesOrderDTO.statusId === 18 ||
        response.data.salesOrderDTO.statusId === 19 ||
        response.data.salesOrderDTO.statusId === 20
      ) {
        UpdateButton = true;
        EditButton = true;
      }

      if (
        response.data.salesOrderDTO.statusId === 4 ||
        response.data.salesOrderDTO.statusId === 3 ||
        response.data.salesOrderDTO.statusId == 5
      ) {
        ApproveButton = false;
        RejectButton = false;
        UpdateButton = false;
        EditButton = false;
      }

      const Header = {
        regularSalesId: response.data.salesOrderDTO.regularSalesId,
        finYear: response.data.salesOrderDTO.finYear,
        createdBy: response.data.salesOrderDTO.createdBy,
        createdByDesc: response.data.salesOrderDTO.createdByDesc,
        createdDate: createddate[0],
        salesDate: salesdate[0],
        salesNo: response.data.salesOrderDTO.salesNo,
        invoiceTypeId: response.data.salesOrderDTO.invoiceTypeId,
        invoiceTypeName: response.data.salesOrderDTO.invoiceTypeName,
        companyId: response.data.salesOrderDTO.companyId,
        companyName: response.data.salesOrderDTO.companyName,
        branchId: response.data.salesOrderDTO.branchId,
        branchName: response.data.salesOrderDTO.branchName,
        transporterId: response.data.salesOrderDTO.transporterId,
        transporterAddressName: response.data.salesOrderDTO.transporterAddress,
        transporterStateCode: response.data.salesOrderDTO.transporterId,
        transporterStateName: response.data.salesOrderDTO.transporterStateName,
        quotationId: response.data.salesOrderDTO.quotationId,
        quotationNo: response.data.salesOrderDTO.quotationSalesOrderNo,
        customerId: response.data.salesOrderDTO.customerId,
        customerName: response.data.salesOrderDTO.customerName,
        customerLineItemId: response.data.salesOrderDTO.customerLineItemId,
        brokerId: response.data.salesOrderDTO.brokerId,
        brokerName: response.data.salesOrderDTO.brokerName,
        ContactNumber: "",
        ContactPerson: "",
        billingTo: response.data.salesOrderDTO.billingTo,
        billingToAddress: response.data.salesOrderDTO.billingToAddress,
        billingToGST: response.data.salesOrderDTO.billedToGstin,
        billingContactNo: response.data.salesOrderDTO.billedToContactNumber,
        billingContactPerson: response.data.salesOrderDTO.billedToContactPerson,
        billingToEmail: response.data.salesOrderDTO.billedToEmail,
        shippingTo: response.data.salesOrderDTO.shippingTo,
        shippingToAddress: response.data.salesOrderDTO.shippingToAddress,
        shippingToGST: response.data.salesOrderDTO.shippedToGstin,
        shippingContactNo: response.data.salesOrderDTO.shippedToContactNumber,
        shippingContactPerson:
          response.data.salesOrderDTO.shippedToContactPerson,
        shippingToEmail: response.data.salesOrderDTO.shippedToEmail,
        salesOrderExpiryDate: salesOrderExpirydate[0],
        challanNo: response.data.salesOrderDTO.challanNo,
        challanDate: response.data.salesOrderDTO.challanDate,
        taxType: response.data.salesOrderDTO.taxType,
        taxName: response.data.salesOrderDTO.taxName,
        brokerCommision: response.data.salesOrderDTO.brokerCommision,
        brokerCommisionPercent:
          response.data.salesOrderDTO.brokerCommisionPercent,
        footerNote: response.data.salesOrderDTO.footerNote,
        internalNote: response.data.salesOrderDTO.internalNote,
        termsConditionType: response.data.salesOrderDTO.termsConditionType,
        termsCondition: response.data.salesOrderDTO.termsCondition,
        deliveryTerms: response.data.salesOrderDTO.deliveryTerms,
        paymentTerms: response.data.salesOrderDTO.paymentTerms,
        deliveryDays: response.data.salesOrderDTO.deliveryDays,
        freightCharges: response.data.salesOrderDTO.freightCharges,
        amountWithoutTax: response.data.salesOrderDTO.amountWithoutTax,
        amountWithTax: response.data.salesOrderDTO.amountWithTax,
        updatedBy: response.data.salesOrderDTO.updatedBy,
        updatedByDesc: response.data.salesOrderDTO.updatedByDesc,
        updatedOn: response.data.salesOrderDTO.updatedOn,
        approvedBy: response.data.salesOrderDTO.approvedBy,
        approvedBydesc: response.data.salesOrderDTO.approvedBydesc,
        approvedOn: response.data.salesOrderDTO.approvedOn,
        statusId: response.data.salesOrderDTO.statusId,
        statusName: response.data.salesOrderDTO.statusName,
        doStatus:
          (!isEmpty(response.data.salesOrderDTO.doStatus) &&
            getStatus(response.data.salesOrderDTO.doStatus)) ||
          response.data.salesOrderDTO.statusName ||
          "",
        isActive: 1,
        hessianSalesId: response.data.hessianSalesId,
        approveStatus: response.data.salesOrderDTO.approveStatus,
        brokerCommisionSource: response.data.brokerCommisionSource,

        totalIGST: TotalIGST == null ? "0.00" : limitDecimals(TotalIGST, 2),
        totalCGST: TotalCGST == null ? "0.00" : limitDecimals(TotalCGST, 2),
        totalSGST: TotalSGST == null ? "0.00" : limitDecimals(TotalSGST, 2),

        subTotal: limitDecimals(netAmount, 2),
        taxTotal: limitDecimals(taxTotals, 2),
        netTotal: limitDecimals(netAmount, 2),
        TotalAmount: limitDecimals(Number(netAmount) + Number(taxTotals), 2),
        ApproveButton: ApproveButton,
        RejectButton: RejectButton,
        UpdateButton: UpdateButton,
        EditButton: EditButton,
      };
      yield put(SalesOrderJuteHessianHeaders(Header));
    }
    yield put(SalesOrderJuteHessianByIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* UpdateJuteHessianSalesOrderData({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(UpdateJuteHessianSalesOrderSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
    history.push("/s_order");
  } catch (error) {
    console.log(error);
  }
}

function* SalesOrderJuteHessianByQuotationId({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getQuotationViewByIdForJuteHessianSuccessfull({ response }));
    if (response.data) {
      var CustomerUrl =
        serverApi.GET_ALL_BILLING_SHIPPING +
        response.data.customerId +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher +
        "?isForSalesModule=1";
      yield put(getBillingShippingList(CustomerUrl, history));
      if (response.data.salesQuotationLineItems) {
        if (response.data.salesQuotationLineItems.length !== 0) {
          var SOJuteHessianUpdate = [];
          response.data.salesQuotationLineItems.map((item, i) => {
            let RateAfterBrokerage = parseFloat(
              item.rate - (item.rate * response.data.brokerCommision) / 100
            ).toFixed(3);
            SOJuteHessianUpdate.push({
              id: i,
              createdBy: item.createdBy,
              createdDate: item.createdDate,
              companyId: item.companyId,
              hsnCode: item.hsnCode,
              itemGroupId: item.itemGroupId,
              itemId: item.itemId,
              itemGroupName: item.itemGroupName,
              itemName: item.itemName,
              itemCode: item.itemCode,
              itemMake: item.make,
              quantity: item.quantity,
              uom: item.uom,
              rate: item.rate,
              doBalQty: item.doBalQty,
              invoiceBalQty: item.invoiceBalQty,
              igstAmount: item.igstAmount == null ? "0.00" : item.igstAmount,
              igstPercent: item.igstPercent == null ? "0" : item.igstPercent,
              cgstAmount: item.cgstAmount == null ? "0.00" : item.cgstAmount,
              cgstPercent: item.cgstPercent == null ? "0" : item.cgstPercent,
              sgstAmount: item.sgstAmount == null ? "0.00" : item.sgstAmount,
              sgstPercent: item.sgstPercent == null ? "0" : item.sgstPercent,
              taxPercent: limitDecimals(item.itemTaxPercentage, 2),
              discountType: item.discountType,
              discount: item.discount,
              discountedRate: item.discountedRate,
              discountName: item.discountName,
              discountAmount: item.discountAmount,
              netAmount: item.netAmount,
              totalAmount: item.totalAmount,
              remarks: item.remarks,
              updatedBy: item.updatedBy,
              updatedOn: item.updatedOn,
              isActive: item.isActive,
              hessianIsActive:item.hessianIsActive,
              hessianRateIncludingBrokerage: item.hessianRateIncludingBrokerage,
            });
          });
          SOJuteHessianUpdate.push({
            id: SOJuteHessianUpdate.length + 1,
            createdBy: "",
            createdDate: "",
            companyId: "",
            hsnCode: "",
            itemGroupId: "",
            itemId: "",
            itemMake: "",
            quantity: "",
            uom: "",
            rate: "",
            doBalQty: "",
            invoiceBalQty: "",
            igstAmount: "",
            igstPercent: "",
            cgstAmount: "",
            cgstPercent: "",
            sgstAmount: "",
            sgstPercent: "",
            discountType: "",
            discount: "",
            discountAmount: "",
            discountedRate: "",
            netAmount: "",
            totalAmount: "",
            remarks: "",
            taxPercent: null,
            hessianRateIncludingBrokerage: "",
            hessianQuantityUom: "",
            hessianQuantityUom2: "",
            hessianRemarks: "",
            updatedBy: null,
            updatedOn: null,
            isActive: 1,
            hessianIsActive:1,
          });
          yield put(SalesOrderJuteHessianLineItems(SOJuteHessianUpdate));
        }
      }
      var TotalCGST = response.data.salesQuotationLineItems
        .filter((item) => item.isActive != 0)
        .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
        .reduce((cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind));
      var TotalSGST = response.data.salesQuotationLineItems
        .filter((item) => item.isActive != 0)
        .map((p) => (p.sgstAmount == null ? 0 : p.sgstAmount))
        .reduce((sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind));
      var TotalIGST = response.data.salesQuotationLineItems
        .filter((item) => item.isActive != 0)
        .map((p) => (p.igstAmount == null ? 0 : p.igstAmount))
        .reduce((igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind));
      var netAmount = response.data.salesQuotationLineItems
        .filter((item) => item.isActive != 0)
        .map((p) => p.netAmount)
        .reduce((netAmount, ind) => parseFloat(netAmount) + parseFloat(ind));
      var taxTotals = Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);

      var createddate =
        response.data.createdDate !== null &&
        response.data.createdDate.split("T");
      var salesdate =
        response.data.quotationDate !== null &&
        response.data.quotationDate.split("T");
      var salesOrderExpirydate =
        response.data.quotationExpiryDate !== null &&
        response.data.quotationExpiryDate.split("T");
      var ApproveButton = false;
      var RejectButton = false;
      var UpdateButton = true;
      if (response.data.approveStatus == true && response.data.statusId == 1) {
        ApproveButton = true;
        RejectButton = true;
        UpdateButton = true;
      } else if (
        response.data.approveStatus == true &&
        response.data.statusId == 17
      ) {
        ApproveButton = true;
        RejectButton = true;
        UpdateButton = true;
      }
      if (response.data.statusId == 4 || response.data.statusId == 3) {
        ApproveButton = false;
        RejectButton = false;
        UpdateButton = false;
      }
      const Header = {
        finYear: response.data.finYear,
        createdBy: response.data.createdBy,
        createdByDesc: response.data.createdByDesc,
        createdDate: createddate[0],
        salesDate: salesdate[0],
        salesNo: response.data.salesNo,
        invoiceTypeId: response.data.invoiceTypeId,
        invoiceTypeName: response.data.invoiceTypeName,
        companyId: response.data.companyId,
        companyName: response.data.companyName,
        branchId: response.data.branchId,
        branchName: response.data.branchName,
        transporterId: response.data.transporterId,
        quotationId: response.data.quotationId,
        quotationNo: response.data.quotationNo,
        customerId: response.data.customerId,
        customerName: response.data.customerName,
        customerLineItemId: response.data.customerLineItemId,
        brokerId: response.data.brokerId,
        brokerName: response.data.brokerName,
        ContactNumber: "",
        ContactPerson: "",
        billingTo: response.data.billingTo,
        billingToAddress: response.data.billingToAddress,
        billingToGST: "",
        billingContactNo: "",
        billingContactPerson: "",
        billingToEmail: "",
        shippingTo: response.data.shippingTo,
        shippingToAddress: response.data.shippingToAddress,
        shippingToGST: "",
        shippingContactNo: "",
        shippingContactPerson: "",
        shippingToEmail: "",
        salesOrderExpiryDate: salesOrderExpirydate[0],
        challanNo: response.data.challanNo,
        challanDate: response.data.challanDate,
        taxType: response.data.taxType,
        taxName: response.data.taxName,
        brokerCommision: response.data.brokerCommision,
        brokerCommisionPercent: response.data.brokeragePercentage,
        footerNote: response.data.footerNotes,
        internalNote: response.data.internalNote,
        termsConditionType: response.data.termsConditionType,
        termsCondition: response.data.termsCondition,
        deliveryTerms: response.data.deliveryTerms,
        paymentTerms: response.data.paymentTerms,
        deliveryDays: response.data.deliveryDays,
        freightCharges: response.data.frieghtCharges,
        amountWithoutTax: response.data.amountWithoutTax,
        amountWithTax: response.data.amountWithTax,
        updatedBy: response.data.updatedBy,
        updatedByDesc: response.data.updatedByDesc,
        updatedOn: response.data.updatedOn,
        approvedBy: response.data.approvedBy,
        approvedBydesc: response.data.approvedBydesc,
        approvedOn: response.data.approvedOn,
        statusId: response.data.statusId,
        statusName: response.data.statusName,
        isActive: 1,
        hessianSalesId: response.data.hessianSalesId,
        approveStatus: response.data.approveStatus,
        brokerCommisionSource: response.data.brokerCommisionSource,

        totalIGST:
          TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
        totalCGST:
          TotalCGST == null ? "0.00" : parseFloat(TotalCGST).toFixed(2),
        totalSGST:
          TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),

        subTotal: parseFloat(netAmount).toFixed(2),
        taxTotal: parseFloat(taxTotals).toFixed(2),
        netTotal: parseFloat(netAmount - taxTotals).toFixed(2),
        TotalAmount: parseFloat(Number(netAmount) + Number(taxTotals)).toFixed(
          2
        ),

        ApproveButton: ApproveButton,
        RejectButton: RejectButton,
        UpdateButton: UpdateButton,
      };
      yield put(SalesOrderJuteHessianHeaders(Header));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getUomBasedOnItemIdListHessian({ payload: { url, history, rowId } }) {
  const { SoJuteHessianLineItems } = yield select(
    (state) => state.SalesOrderReducer
  );
  try {
    const response = yield call(getListurl, url);
    if (response) {
      yield put(getUomListBasedOnItemIdHessianSuccess({ response }));

      let list = [];
      if (response.data) {
        let ListFilter = response.data.filter((data) => {
          if (data.value !== "0") {
            return data;
          }
        });
        ListFilter.map((prop) => {
          list.push({
            label: prop.uomDsc,
            name: prop.uomDsc,
            value: prop.uomCode,
            variant: prop.id,
          });
        });
      }
      if (SoJuteHessianLineItems) {
        var LineItemsRecords = [];
        if (SoJuteHessianLineItems.length !== 0) {
          LineItemsRecords = SoJuteHessianLineItems.map((item) => {
            if (rowId == item.id) {
              item.uomMastersListHessian = list;
            }
            return item;
          });
          yield put(SalesOrderJuteHessianLineItems(LineItemsRecords));
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* getUomRelationByUomCodeHessian({
  payload: { url, ItemId, type, history },
}) {
  try {
    const response = yield call(getListurl, url);
    const { SoJuteHessianLineItems, SoJuteHessianHeaders } = yield select(
      (state) => state.SalesOrderReducer
    );
    if (response) {
      let SecondaryUOMObj = response.data;
      let LineItemsRecords = [...SoJuteHessianLineItems];
      if (SecondaryUOMObj) {
        if (type === "quantityUOM") {
          LineItemsRecords = LineItemsRecords.map((item, index) => {
            if (ItemId === item.itemId) {
              return {
                ...item,
                hessianQuantityRelationValue: SecondaryUOMObj.relationValue,
                hessianQuantityCalculatedValue: limitDecimals(
                  convertOtherUOMTOPrimaryUOM(
                    item.quantity,
                    SecondaryUOMObj.relationValue
                  ),
                  3
                ),
              };
            }
            return item;
          });
          let Calculations = SalesGstCalculations(
            {
              lineItems: LineItemsRecords,
              taxType: SoJuteHessianHeaders.taxType,
              brokerCommisionPercent:
                SoJuteHessianHeaders.brokerCommisionPercent,
            },
            "SO_HESSIAN"
          );
          yield put(SalesOrderJuteHessianLineItems(Calculations.lineItems));
          let SoJuteHessianHeadersData = {
            ...SoJuteHessianHeaders,
            totalIGST: limitDecimals(Calculations.totalIGST, 2),
            totalCGST: limitDecimals(Calculations.totalCGST, 2),
            totalSGST: limitDecimals(Calculations.totalSGST, 2),
            taxTotal: limitDecimals(Calculations.taxTotal, 2),
            TotalAmount: limitDecimals(Calculations.TotalAmount, 2),
            netTotal: limitDecimals(Calculations.netTotal, 2),
            subTotal: limitDecimals(Calculations.subTotal, 2),
          };
          yield put(SalesOrderJuteHessianHeaders(SoJuteHessianHeadersData));
        } else if (type === "rateUOM") {
          LineItemsRecords = LineItemsRecords.map((item, index) => {
            if (ItemId === item.itemId) {
              return {
                ...item,
                hessianRateRelationValue: SecondaryUOMObj.relationValue,
                hessianRateCalculatedValue: limitDecimals(
                  convertOtherRateUOmToPrimaryUOM(
                    item.rate,
                    SecondaryUOMObj.relationValue
                  ),
                  3
                ),
                discountedRate: limitDecimals(
                  convertOtherRateUOmToPrimaryUOM(
                    item.rate,
                    SecondaryUOMObj.relationValue
                  ),
                  3
                ),
              };
            }
            return item;
          });
          let Calculations = SalesGstCalculations(
            {
              lineItems: LineItemsRecords,
              taxType: SoJuteHessianHeaders.taxType,
              brokerCommisionPercent:
                SoJuteHessianHeaders.brokerCommisionPercent,
            },
            "SO_HESSIAN"
          );
          yield put(SalesOrderJuteHessianLineItems(Calculations.lineItems));
          let SoJuteHessianHeadersData = {
            ...SoJuteHessianHeaders,
            totalIGST: limitDecimals(Calculations.totalIGST, 2),
            totalCGST: limitDecimals(Calculations.totalCGST, 2),
            totalSGST: limitDecimals(Calculations.totalSGST, 2),
            taxTotal: limitDecimals(Calculations.taxTotal, 2),
            TotalAmount: limitDecimals(Calculations.TotalAmount, 2),
            netTotal: limitDecimals(Calculations.netTotal, 2),
            subTotal: limitDecimals(Calculations.subTotal, 2),
          };
          yield put(SalesOrderJuteHessianHeaders(SoJuteHessianHeadersData));
        }
      }

      yield put(getUomRelationHessianSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}

// --------------------------------------------------------
//Jute Yarn sales order
function* CreateJuteYarnSalesOrderData({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(CreateJuteYarnSalesOrderSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
    history.push("/s_order");
    hideSpinner();
  } catch (error) {
    console.log(error);
  }
}

function* UpdateJuteYarnSalesOrderData({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(UpdateJuteYarnSalesOrderSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
    history.push("/s_order");
  } catch (error) {
    console.log(error);
  }
}

//Jute Govt sales order
function* CreateJuteGovtSalesOrderData({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(CreateJuteGovtSalesOrderSuccessfull({ response }));
    if (response.data.status) {
      hideSpinner();
      swal(response.data.message, { icon: "success" });
      history.push("/s_order");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    console.log(error);
  }
}
function* UpdateJuteGovtSalesOrderData({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(UpdateJuteGovtSalesOrderSuccessfull({ response }));
    if (response.data.status) {
      swal(response.data.message, { icon: "success" });
      history.push("/s_order");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    console.log(error);
  }
}

function* SetSalesOrderData({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    if (response) {
      hideSpinner();
      yield put(setSalesOrderSuccessfull({ response }));
      swal(response.data.message, { icon: "success" });
      history.push("/s_order");
    }
  } catch (error) {
    console.log(error);
  }
}

function* SalesOrderDataById({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (response.data) {
      var CustomerUrl =
        serverApi.GET_ALL_BILLING_SHIPPING +
        response.data.customerId +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher +
        "?isForSalesModule=1";
      yield put(getBillingShippingList(CustomerUrl, history));
      if (response.data.salesOrderLineItemDTO) {
        if (response.data.salesOrderLineItemDTO.length !== 0) {
          var SalesUpdate = [];
          response.data.salesOrderLineItemDTO.map((item, i) => {
            SalesUpdate.push({
              id: i,
              regularSalesOrderLineItemId: item.regularSalesOrderLineItemId,
              regularSalesOrderId: item.regularSalesOrderId,
              createdBy: item.createdBy,
              createdDate: item.createdDate,
              companyId: item.companyId,
              companyName: item.companyName,
              quotationId: item.quotationId,
              quotationLineitemId: item.quotationLineitemId,
              hsncode: item.hsncode,
              itemGroupId: item.itemGroupId,
              discountAmount: item.discountAmount,
              itemGroupValue: item.itemGroupValue,
              itemGroupName: item.itemGroupName,
              itemId: item.itemId,
              itemName: item.itemName,
              itemCode: item.itemCode,
              itemValue: item.itemValue,
              itemMake: item.itemMake,
              quantity: limitDecimals(item.quantity, 3),
              uom: item.uom,
              rate: limitDecimals(item.rate, 3),
              doBalQty: limitDecimals(item.doBalQty, 3),
              invoiceBalQty: limitDecimals(item.invoiceBalQty, 3),
              igstAmount:
                item.igstAmount == null
                  ? "0.00"
                  : limitDecimals(item.igstAmount, 2),
              igstPercent:
                item.igstPercent == null
                  ? "0.00"
                  : limitDecimals(item.igstPercent, 2),
              cgstAmount:
                item.cgstAmount == null
                  ? "0.00"
                  : limitDecimals(item.cgstAmount, 2),
              cgstPercent:
                item.cgstPercent == null
                  ? "0.00"
                  : limitDecimals(item.cgstPercent, 2),
              sgstAmount:
                item.sgstAmount == null
                  ? "0.00"
                  : limitDecimals(item.sgstAmount, 2),
              sgstPercent:
                item.sgstPercent == null
                  ? "0.00"
                  : limitDecimals(item.sgstPercent, 2),
              discountType: item.discountType,
              discountName: item.discountName,
              discount: limitDecimals(item.discount, 2),
              discountedRate: limitDecimals(item.discountedRate, 3),
              discountAmount: limitDecimals(item.discountAmount, 2),
              netAmount: limitDecimals(item.netAmount, 2),
              totalAmount: limitDecimals(item.totalAmount, 2),
              remarks: item.remarks,
              taxPercent: limitDecimals(item.itemTaxPercentage, 2),
              isActive: item.isActive,
              currency: "INR",
              status: item.status,
              updatedBy: item.updatedBy,
              updatedOn: item.updatedOn,
              ItemNameData: [],
            });
          });
          SalesUpdate.push({
            id: SalesUpdate.length + 1,
            regularSalesOrderLineItemId: null,
            regularSalesOrderId: response.data.regularSalesId,
            createdBy: "",
            createdDate: "",
            companyId: "",
            companyName: "",
            quotationId: "",
            quotationLineitemId: "",
            hsncode: "",
            itemGroupId: "",
            itemGroupValue: "",
            itemGroupName: "",
            discountAmount: "",
            itemId: null,
            itemName: "",
            itemCode: "",
            itemValue: "",
            itemMake: "",
            quantity: "",
            uom: "",
            rate: "",
            doBalQty: "",
            invoiceBalQty: "",
            igstAmount: "",
            igstPercent: "",
            cgstAmount: "",
            cgstPercent: "",
            sgstAmount: "",
            sgstPercent: "",
            discountType: "",
            discountName: "",
            discount: "",
            discountedRate: "",
            netAmount: "",
            totalAmount: "",
            remarks: "",
            taxPercent: "",
            isActive: 1,
            currency: "INR",
            status: "",
            updatedBy: null,
            updatedOn: null,
            ItemNameData: [],
          });
          yield put(SalesOrderLineItems(SalesUpdate));
        }
      }
      var TotalCGST = response.data.salesOrderLineItemDTO
        .filter((item) => item.isActive != 0)
        .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
        .reduce((cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind));
      var TotalSGST = response.data.salesOrderLineItemDTO
        .filter((item) => item.isActive != 0)
        .map((p) => (p.sgstAmount == null ? 0 : p.sgstAmount))
        .reduce((sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind));
      var TotalIGST = response.data.salesOrderLineItemDTO
        .filter((item) => item.isActive != 0)
        .map((p) => (p.igstAmount == null ? 0 : p.igstAmount))
        .reduce((igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind));
      var totalAmount = response.data.salesOrderLineItemDTO
        .filter((item) => item.isActive != 0)
        .map((p) => p.netAmount)
        .reduce((netAmount, ind) => parseFloat(netAmount) + parseFloat(ind));
      var taxTotals = Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);

      var createddate =
        response.data.createdDate !== null &&
        response.data.createdDate.split("T");
      var salesdate =
        response.data.salesDate !== null && response.data.salesDate.split("T");
      var salesOrderExpirydate =
        response.data.salesOrderExpiryDate !== null &&
        response.data.salesOrderExpiryDate.split("T");

      var ApproveButton = false;
      var RejectButton = false;
      var UpdateButton = true;
      var EditButton = true;

      if (response.data.approveStatus == true) {
        if (
          response.data.statusId == 1 ||
          response.data.statusId == 17 ||
          response.data.statusId == 18 ||
          response.data.statusId == 19 ||
          response.data.statusId == 20
        ) {
          ApproveButton = true;
          RejectButton = true;
          UpdateButton = true;
          EditButton = true;
        }
      } else if (
        response.data.statusId == 1 ||
        response.data.statusId == 17 ||
        response.data.statusId == 18 ||
        response.data.statusId == 19 ||
        response.data.statusId == 20
      ) {
        UpdateButton = true;
        EditButton = true;
      }

      if (
        response.data.statusId == 4 ||
        response.data.statusId == 3 ||
        response.data.statusId == 5
      ) {
        ApproveButton = false;
        RejectButton = false;
        UpdateButton = false;
        EditButton = false;
      }

      const Header = {
        regularSalesId: response.data.regularSalesId,
        finYear: response.data.finYear,
        createdBy: response.data.createdBy,
        createdByDesc: response.data.createdByDesc,
        createdDate: createddate[0],
        salesDate: salesdate[0],
        quotationDate:response.data.quotationDate,
        salesNo: response.data.salesNo,
        invoiceTypeId: response.data.invoiceTypeId,
        invoiceTypeName: response.data.invoiceTypeName,
        companyId: response.data.companyId,
        companyName: response.data.companyName,
        branchId: response.data.branchId,
        branchName: response.data.branchName,
        bankId: response.data.bankId,
        bankName: response.data.bankName,
        ifscCode: response.data.ifscCode,
        micrCode: response.data.micrCode,
        accountNo: response.data.accountNo,
        quotationId: response.data.quotationId,
        quotationNo: response.data.quotationSalesOrderNo,
        customerId: response.data.customerId,
        customerName: response.data.customerName,
        customerResponse: response.data.customerId === null ? true : false,
        customerLineItemId: response.data.customerLineItemId,
        brokerId: response.data.brokerId,
        brokerName: response.data.brokerName,
        ContactNumber: "",
        ContactPerson: "",
        billingTo: response.data.billingTo,
        billingToAddress: response.data.billingToAddress,
        billingToGST: response.data.billedToGstin,
        billingContactNo: response.data.billedToContactNumber,
        billingContactPerson: response.data.billedToContactPerson,
        billingToEmail: response.data.billedToEmail,
        shippingTo: response.data.shippingTo,
        shippingToAddress: response.data.shippingToAddress,
        shippingToGST: response.data.shippedToGstin,
        shippingContactNo: response.data.shippedToContactNumber,
        shippingContactPerson: response.data.shippedToContactPerson,
        shippingToEmail: response.data.shippedToEmail,
        salesOrderExpiryDate: salesOrderExpirydate[0],
        challanNo: response.data.challanNo,
        challanDate: response.data.challanDate,
        taxType: response.data.taxType,
        doStatus:
          (!isEmpty(response.data.doStatus) &&
            getStatus(response.data.doStatus)) ||
          response.data.statusName ||
          "",
        taxName: response.data.taxName,
        brokerCommision: response.data.brokerCommision,
        brokerCommisionPercent: response.data.brokerCommisionPercent,
        footerNote: response.data.footerNote,
        internalNote: response.data.internalNote,
        termsConditionType: response.data.termsConditionType,
        termsCondition: response.data.termsCondition,
        deliveryTerms: response.data.deliveryTerms,
        paymentTerms: response.data.paymentTerms,
        deliveryDays: response.data.deliveryDays,
        freightCharges: response.data.freightCharges,
        amountWithoutTax: response.data.amountWithoutTax,
        amountWithTax: response.data.amountWithTax,
        updatedBy: response.data.updatedBy,
        updatedByDesc: response.data.updatedByDesc,
        updatedOn: response.data.updatedOn,
        approvedBy: response.data.approvedBy,
        approvedBydesc: response.data.approvedBydesc,
        approvedOn: response.data.approvedOn,
        statusId: response.data.statusId,
        statusName: response.data.statusName,
        isActive: 1,
        approveStatus: response.data.approveStatus,
        disableBroker: response.data.brokerId !== null ? true : false,
        disableCustomer: response.data.customerId !== null ? true : false,

        totalIGST:
          TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
        totalCGST:
          TotalCGST == null ? "0.00" : parseFloat(TotalCGST).toFixed(2),
        totalSGST:
          TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),

        subTotal: parseFloat(totalAmount).toFixed(2),
        taxTotal: parseFloat(taxTotals).toFixed(2),
        netTotal: parseFloat(totalAmount).toFixed(2),
        TotalAmount: parseFloat(
          Number(totalAmount) + Number(taxTotals)
        ).toFixed(2),

        ApproveButton: ApproveButton,
        RejectButton: RejectButton,
        UpdateButton: UpdateButton,
        EditButton: EditButton,
      };
      yield put(getSalesOrderHeader(Header));
    }
    yield put(SalesOrderByIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getTermsConditionsData({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data, history);
    yield put(getTermsConditionsSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getQuotationRecordsData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getQuotationRecordsSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getQuotationViewByIdData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getQuotationViewByIdSuccessfull({ response }));
    if (response.data) {
      if (response.data.customerId !== null) {
        var CustomerUrl =
          serverApi.GET_ALL_BILLING_SHIPPING +
          response.data.customerId +
          "/" +
          JSON.parse(localStorage.getItem("authUser")).cipher +
          "?isForSalesModule=1";
        yield put(getBillingShippingList(CustomerUrl, history));
      }
      if (response.data.salesQuotationLineItems) {
        if (response.data.salesQuotationLineItems.length !== 0) {
          var SalesUpdate = [];
          response.data.salesQuotationLineItems.map((item, i) => {
            SalesUpdate.push({
              id: i,
              regularSalesOrderLineItemId: "",
              regularSalesOrderId: "",
              createdBy: item.createdBy,
              createdDate: item.createdDate,
              companyId: item.companyId,
              companyName: item.companyName,
              quotationId: item.quotationId,
              quotationLineitemId: item.quotationLineitemId,
              hsncode: item.hsnCode,
              itemGroupId: item.itemGroupId,
              itemGroupValue: item.itemGroupValue,
              itemGroupName: item.itemGroupName,
              itemId: item.itemId,
              itemName: item.itemName,
              itemCode: item.itemCode,
              itemValue: item.itemValue,
              itemMake: item.make,
              quantity: limitDecimals(item.quantity, 3),
              rate: limitDecimals(item.rate, 3),
              uom: item.uom,
              doBalQty: "",
              invoiceBalQty: limitDecimals(item.invoiceBalQty, 3),
              igstAmount:
                item.igstAmount == null
                  ? "0.00"
                  : limitDecimals(item.igstAmount, 2),
              igstPercent:
                item.igstPercent == null
                  ? "0"
                  : limitDecimals(item.igstPercent, 2),
              cgstAmount:
                item.cgstAmount == null
                  ? "0.00"
                  : limitDecimals(item.cgstAmount, 2),
              cgstPercent:
                item.cgstPercent == null
                  ? "0"
                  : limitDecimals(item.cgstPercent, 2),
              sgstAmount:
                item.sgstAmount == null
                  ? "0.00"
                  : limitDecimals(item.sgstAmount, 2),
              sgstPercent:
                item.sgstPercent == null
                  ? "0"
                  : limitDecimals(item.sgstPercent, 2),
              discountType: item.discountType === null ? "" : item.discountType,
              discountName: item.discountName,
              discount: limitDecimals(item.discount, 2),
              discountedRate: limitDecimals(item.discountedRate, 3),
              discountAmount: limitDecimals(item.discountAmount),
              netAmount: limitDecimals(item.netAmount, 2),
              totalAmount: limitDecimals(item.totalAmount, 2),
              remarks: item.remarks,
              taxPercent: limitDecimals(item.itemTaxPercentage, 2),
              isActive: item.isActive,
              currency: "INR",
              status: "",
              updatedBy: item.updatedBy,
              updatedOn: item.updatedOn,
              ItemNameData: [],
            });
          });
          SalesUpdate.push({
            id: SalesUpdate.length,
            regularSalesOrderLineItemId: null,
            regularSalesOrderId: null,
            createdBy: "",
            createdDate: "",
            companyId: "",
            companyName: "",
            quotationId: "",
            quotationLineitemId: "",
            hsncode: "",
            itemGroupId: "",
            itemGroupValue: "",
            itemGroupName: "",
            itemId: null,
            itemName: "",
            itemCode: "",
            itemValue: "",
            itemMake: "",
            quantity: "",
            uom: "",
            rate: "0.000",
            doBalQty: "",
            invoiceBalQty: "",
            igstAmount: "",
            igstPercent: "",
            cgstAmount: "",
            cgstPercent: "",
            sgstAmount: "",
            sgstPercent: "",
            discountType: "",
            discountName: "",
            discountAmount: "",
            discount: "",
            discountedRate: "",
            netAmount: "",
            totalAmount: "",
            remarks: "",
            taxPercent: "",
            isActive: 1,
            currency: "INR",
            status: "",
            updatedBy: null,
            updatedOn: null,
            ItemNameData: [],
          });
          yield put(SalesOrderLineItems(SalesUpdate));
        }
      }
      var TotalCGST = response.data.salesQuotationLineItems
        .filter((item) => item.isActive != 0)
        .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
        .reduce((cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind));
      var TotalSGST = response.data.salesQuotationLineItems
        .filter((item) => item.isActive != 0)
        .map((p) => (p.sgstAmount == null ? 0 : p.sgstAmount))
        .reduce((sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind));
      var TotalIGST = response.data.salesQuotationLineItems
        .filter((item) => item.isActive != 0)
        .map((p) => (p.igstAmount == null ? 0 : p.igstAmount))
        .reduce((igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind));

      var netAmount = response.data.salesQuotationLineItems
        .filter((item) => item.isActive != 0)
        .map((p) => p.netAmount)
        .reduce((netAmount, ind) => parseFloat(netAmount) + parseFloat(ind));
      var taxTotals = Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);
      var createddate = response.data.createdDate.split("T");
      var quotationDate =
        response.data.quotationDate !== null &&
        response.data.quotationDate.split("T");
      var quotationExpiryDate =
        response.data.quotationExpiryDate !== null &&
        response.data.quotationExpiryDate.split("T");
      const Header = {
        regularSalesId: "",
        finYear: response.data.finYear,
        createdBy: response.data.createdBy,
        createdByDesc: response.data.createdByDesc,
        createdDate: createddate[0],
        salesDate: null,
        salesNo: "",
        invoiceTypeId: "",
        invoiceTypeName: "",
        quotationDate: quotationDate[0],
        companyId: response.data.companyId,
        companyName: response.data.companyName,
        branchId: response.data.branchId,
        branchName: response.data.branchName,
        quotationId: response.data.quotationId,
        bankId: response.data.bankId,
        bankName: response.data.bankName,
        ifscCode: response.data.ifscCode,
        accountNo: response.data.accountNo,
        quotationNo: response.data.quotationNo,
        customerId: response.data.customerId,
        customerName: response.data.customerName,
        customerLineItemId: "",
        customerResponse: response.data.customerId === null ? true : false,
        brokerId: response.data.brokerId,
        brokerName: response.data.brokerName,
        ContactNumber: "",
        ContactPerson: "",
        billingTo: response.data.billingAddress,
        billingToAddress: response.data.billingToAddress,
        billingToGST: response.data.billedToGstin,
        billingContactNo: response.data.billedToContactNumber,
        billingContactPerson: response.data.billedToContactPerson,
        billingToEmail: response.data.billedToEmail,
        shippingTo: response.data.shippingAddress,
        shippingToAddress: response.data.shippingToAddress,
        shippingToGST: response.data.shippedToGstin,
        shippingContactNo: response.data.shippedToContactNumber,
        shippingContactPerson: response.data.shippedToContactPerson,
        shippingToEmail: response.data.shippedToEmail,
        salesOrderExpiryDate: null,
        challanNo: "",
        challanDate: "",
        taxType: response.data.taxType === null ? 0 : response.data.taxType,
        taxName: response.data.taxTypeName,
        brokerCommision: response.data.brokerCommision,
        brokerCommisionPercent:
          response.data.brokeragePercentage === null
            ? ""
            : response.data.brokeragePercentage,
        footerNote: response.data.footerNotes,
        internalNote: response.data.internalNote,
        termsConditionType: response.data.termsConditionType,
        termsCondition: response.data.termsCondition,
        deliveryTerms: response.data.deliveryTerms,
        paymentTerms: response.data.paymentTerms,
        deliveryDays:
          response.data.deliveryDays == null ? "" : response.data.deliveryDays,
        freightCharges: response.data.frieghtCharges,
        amountWithoutTax: "",
        amountWithTax: "",
        updatedBy: response.data.updatedBy,
        updatedName: response.data.updatedByDesc,
        updatedOn: response.data.updatedOn,
        approvedBy: response.data.approvedBy,
        approvedBydesc: response.data.approvedName,
        approvedOn: "",
        statusId: "",
        statusName: "",
        disableBroker: response.data.brokerId !== null ? true : false,
        disableCustomer: response.data.customerId !== null ? true : false,
        disabledDeliveryTerms:
          response.data.deliveryTerms !== null &&
          response.data.deliveryTerms !== ""
            ? true
            : false,
        isActive: response.data.isActive,
        approveStatus: response.data.approveStatus,
        totalIGST:
          TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
        totalCGST:
          TotalCGST == null ? "0.00" : parseFloat(TotalCGST).toFixed(2),
        totalSGST:
          TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),

        subTotal: parseFloat(netAmount).toFixed(2),
        taxTotal: parseFloat(taxTotals).toFixed(2),
        netTotal: parseFloat(netAmount).toFixed(2),
        netAmount: parseFloat(netAmount - taxTotals).toFixed(2),
        TotalAmount: parseFloat(Number(taxTotals) + Number(netAmount)).toFixed(
          2
        ),
      };
      yield put(getSalesOrderHeader(Header));
    }
  } catch (error) {
    console.log(error);
  }
}
// jute yarn sales order
function* getQuotesViewByIdDataForJuteYarn({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getQuoteViewByIdForJuteYarnSuccessfull({ response }));
    if (response.data) {
      var CustomerUrl =
        serverApi.GET_ALL_BILLING_SHIPPING +
        response.data.customerId +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher +
        "?isForSalesModule=1";
      yield put(getBillingShippingList(CustomerUrl, history));
      if (response.data.salesQuotationLineItems) {
        if (response.data.salesQuotationLineItems.length !== 0) {
          var juteYarnItems = [];
          response.data.salesQuotationLineItems.map((item, i) => {
            let yarnRateExcludingBrokerage = parseFloat(
              item.rate - (item.rate * response.data.brokerCommision) / 100
            ).toFixed(3);
            juteYarnItems.push({
              id: i,
              createdBy: item.createdBy,
              createdDate: item.createdDate,
              companyId: item.companyId,
              currency: "INR",
              hsncode: item.hsncode,
              itemGroupName: item.itemGroupName,
              itemGroupValue: item.itemGroupValue,
              itemGroupId: item.itemGroupId,
              itemGroupValue: item.itemGroupValue,
              itemGroupName: item.itemGroupName,
              itemId: item.itemId,
              itemName: item.itemName,
              itemCode: item.itemCode,
              itemMake: item.itemMake,
              quantity: item.quantity,
              uom: item.uom,
              rate: item.rate,
              doBalQty: "",
              invoiceBalQty: item.invoiceBalQty,
              rateUom: "",
              igstAmount: item.igstAmount == null ? "0.00" : item.igstAmount,
              igstPercent: item.igstPercent == null ? "0" : item.igstPercent,
              cgstAmount: item.cgstAmount == null ? "0.00" : item.cgstAmount,
              cgstPercent: item.cgstPercent == null ? "0" : item.cgstPercent,
              sgstAmount: item.sgstAmount == null ? "0.00" : item.sgstAmount,
              sgstPercent: item.sgstPercent == null ? "0" : item.sgstPercent,
              discountType: item.discountType,
              discountName: item.discountName,
              discount: item.discount,
              discountedRate: item.discountedRate,
              netAmount: item.netAmount,
              totalAmount: item.totalAmount,
              yarnRateIncludingBrokerage: parseFloat(
                item.rate -
                  (item.rate * response.data.brokeragePercentage) / 100
              ).toFixed(3),
              remarks: item.remarks,
              taxPercent: limitDecimals(item.itemTaxPercentage, 2),
              isActive: item.isActive,
              yarnQuantityUom: "",
              rateUom2: "",
              status: "",
              yarnQuantityUom2: "",
              updatedBy: item.updatedBy,
              updatedOn: item.updatedOn,
            });
          });

          juteYarnItems.push({
            lineItemId: juteYarnItems.length + 1,
            createdBy: "",
            createdDate: "",
            companyId: "",
            currency: "INR",
            hsncode: "",
            itemGroupName: "",
            itemGroupValue: "",
            itemGroupId: "",
            itemId: "",
            itemName: "",
            itemCode: "",
            itemMake: "",
            quantity: "0.0",
            uom: "",
            rate: "",
            doBalQty: "",
            invoiceBalQty: "",
            igstAmount: "0.0",
            igstPercent: "0.0",
            cgstAmount: "0.0",
            cgstPercent: "0.0",
            sgstAmount: "0.0",
            sgstPercent: "0.0",
            discountType: "",
            discount: "",
            discountedRate: "",
            yarnRateIncludingBrokerage: "",
            netAmount: "0.0",
            totalAmount: "0.0",
            taxPercent: null,
            remarks: "",
            rateUom: "",
            isActive: 1,
            yarnQuantityUom: "",
            rateUom2: "",
            status: "",
            yarnQuantityUom2: "",
            updatedBy: null,
            updatedOn: null,
          });
          yield put(SalesOrderJuteYarnLineItems(juteYarnItems));
        }
      }
      var TotalCGST = response.data.salesQuotationLineItems
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
        .reduce((cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind));
      var TotalSGST = response.data.salesQuotationLineItems
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.sgstAmount == null ? 0 : p.sgstAmount))
        .reduce((sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind));
      var TotalIGST = response.data.salesQuotationLineItems
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.igstAmount == null ? 0 : p.igstAmount))
        .reduce((igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind));

      var totalAmount = response.data.salesQuotationLineItems
        .filter((item) => item.isActive !== 0)
        .map((p) => p.totalAmount)
        .reduce(
          (totalAmount, ind) => parseFloat(totalAmount) + parseFloat(ind)
        );
      var taxTotals = Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);

      var createddate = response.data.createdDate.split("T");
      var quotationDate =
        response.data.quotationDate !== null &&
        response.data.quotationDate.split("T");
      var quotationExpiryDate =
        response.data.quotationExpiryDate !== null &&
        response.data.quotationExpiryDate.split("T");

      const SoJuteYarnHeaders = {
        regularSalesId: response.data,
        salesId: response.data.salesId,
        yarnSalesId: response.data,
        // Map other properties to their respective keys in response.data
        finYear: response.data.finYear,
        createdBy: response.data.createdBy,
        createdDate: createddate[0],
        salesDate: quotationDate[0],
        companyId: response.data.companyId,
        branchId: response.data.branchId,
        quotationId: response.data.quotationId,
        customerId: response.data.customerId,
        bankId: response.data.bankId,
        bankName: response.data.bankName,
        bankId: response.data.bankId,
        ifscCode: response.data.ifscCode,
        accountNo: response.data.accountNo,
        customerName: response.data.customerName,
        customerLineItemId: response.data.customerLineItemId,
        brokerId: response.data.brokerId,
        brokerName: response.data.brokerName,
        billingTo: response.data.billingTo,
        billingToAddress: response.data.billingToAddress,
        billingToGST: response.data.billedToGstin,
        billingContactNo: response.data.billedToContactNumber,
        billingContactPerson: response.data.billedToContactPerson,
        billingToEmail: response.data.billedToEmail,
        shippingTo: response.data.shippingTo,
        shippingToAddress: response.data.shippingToAddress,
        shippingToGST: response.data.shippedToGstin,
        shippingContactNo: response.data.shippedToContactNumber,
        shippingContactPerson: response.data.shippedToContactPerson,
        shippingToEmail: response.data.shippedToEmail,
        salesOrderExpiryDate: quotationExpiryDate[0],
        challanNo: response.data.challanNo,
        challanDate: response.data.challanDate,
        taxType: response.data.taxType,
        brokerCommision: response.data.brokerCommision,
        brokerCommisionPercent: response.data.brokerCommisionPercent,
        footerNote: response.data.footerNote,
        internalNote: response.data.internalNote,
        termsConditionType: response.data.termsConditionType,
        termsCondition: response.data.termsCondition,
        transporterId: response.data.transporterId,
        transporterName: response.data.transporterName,
        transporterAddress: response.data.transporterAddress,
        transporterStateName: response.data.transporterStateName,
        transporterStateCode: response.data.transporterStateCode,
        expiredDate: response.data.expiredDate,
        approveStatus: response.data.approveStatus,
        deliveryTerms: response.data.deliveryTerms,
        paymentTerms: response.data.paymentTerms,
        deliveryDays: response.data.deliveryDays,
        freightCharges: response.data.freightCharges,
        amountWithoutTax: response.data.amountWithoutTax,
        amountWithTax: response.data.amountWithTax,
        updatedBy: response.data.updatedBy,
        updatedOn: response.data.updatedOn,
        approvedBy: response.data.approvedBy,
        approvedBydesc: response.data.approvedBydesc,
        approvedOn: response.data.approvedOn,
        statusId: response.data.statusId,
        statusName: response.data.statusName,
        invoiceTypeId: response.data.invoiceTypeId,
        totalIGST:
          TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
        totalCGST:
          TotalCGST == null ? "0.00" : parseFloat(TotalCGST).toFixed(2),
        totalSGST:
          TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),
        subTotal: parseFloat(totalAmount).toFixed(2),
        taxTotal: parseFloat(taxTotals).toFixed(2),
        netTotal: parseFloat(totalAmount).toFixed(2),
        TotalAmount: parseFloat(
          Number(taxTotals) + Number(totalAmount)
        ).toFixed(2),
        ApproveButton: response.data.ApproveButton,
        RejectButton: response.data.RejectButton,
        UpdateButton: response.data.UpdateButton,
      };
      yield put(SalesOrderJuteYarnHeaders(SoJuteYarnHeaders));
    }
  } catch (error) {
    console.log(error);
  }
}

function* SODataByIdForJuteYarn({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(SOByIdForJuteYarnSuccessfull({ response }));
    if (response.data) {
      if (response.data) {
        var CustomerUrl =
          serverApi.GET_ALL_BILLING_SHIPPING +
          response.data.salesOrderDTO.customerId +
          "/" +
          JSON.parse(localStorage.getItem("authUser")).cipher +
          "?isForSalesModule=1";
        yield put(getBillingShippingList(CustomerUrl, history));
        if (response.data.salesOrderDTO.salesOrderLineItemDTO) {
          if (response.data.salesOrderDTO.salesOrderLineItemDTO.length !== 0) {
            var juteYarnItems = [];
            response.data.salesOrderDTO.salesOrderLineItemDTO.map((item, i) => {
              juteYarnItems.push({
                id: i,
                salesOrderLineItemDTO: item.salesOrderLineItemDTO,
                regularSalesOrderLineItemId: item.regularSalesOrderLineItemId,
                salesRegularInvoiceLineitemId:
                  item.salesRegularInvoiceLineitemId,
                regularSalesOrderId: item.regularSalesOrderId,
                yarnSalesOrderLineItemId: item.yarnSalesOrderLineItemId,
                createdBy: item.createdBy,
                createdDate: item.createdDate,
                companyId: item.companyId,
                currency: "INR",
                hsncode: item.hsncode,
                itemGroupName: item.itemGroupName,
                itemGroupValue: item.itemGroupValue,
                itemGroupId: item.itemGroupId,
                itemGroupName: item.itemGroupName,
                itemId: item.itemId,
                itemName: item.itemName,
                itemCode: item.itemCode,
                itemMake: item.itemMake,
                quantity: limitDecimals(item.quantity, 3),
                uom: item.uom,
                rate: limitDecimals(item.rate, 3),
                doBalQty: limitDecimals(item.doBalQty, 3),
                invoiceBalQty: limitDecimals(item.invoiceBalQty, 3),
                igstAmount:
                  item.igstAmount == null
                    ? "0.00"
                    : limitDecimals(item.igstAmount, 2),
                igstPercent:
                  item.igstPercent == null
                    ? "0.00"
                    : limitDecimals(item.igstPercent, 2),
                cgstAmount:
                  item.cgstAmount == null
                    ? "0.00"
                    : limitDecimals(item.cgstAmount, 2),
                cgstPercent:
                  item.cgstPercent == null
                    ? "0.00"
                    : limitDecimals(item.cgstPercent, 2),
                sgstAmount:
                  item.sgstAmount == null
                    ? "0.00"
                    : limitDecimals(item.sgstAmount, 2),
                sgstPercent:
                  item.sgstPercent == null
                    ? "0.00"
                    : limitDecimals(item.sgstPercent, 2),
                discountType: item.discountType,
                discountName: item.discountTypeName,
                discount: limitDecimals(item.discount, 2),
                discountedRate: limitDecimals(item.discountedRate, 3),
                discountAmount: limitDecimals(item.discountAmount, 2),
                netAmount: limitDecimals(item.netAmount, 2),
                totalAmount: limitDecimals(item.totalAmount, 2),
                remarks: item.remarks,
                taxPercent: limitDecimals(item.itemTaxPercentage, 2),
                isActive: item.isActive,
                yarnIsActive:item.yarnIsActive,
                yarnQuantityUom: "",
                rateUom2: "",
                status: "",
                yarnQuantityUom2: "",
                updatedBy: item.updatedBy,
                updatedOn: item.updatedOn,
                yarnSalesOrderLineItemId: item.yarnSalesOrderLineItemId,
                yarnRateIncludingBrokerage: limitDecimals(
                  item.yarnRateIncludingBrokerage,
                  3
                ),
                yarnRateExcludingBrokerage: limitDecimals(
                  item.yarnRateExcludingBrokerage,
                  3
                ),
                yarnRateUom: item.yarnRateUom,
                yarnRateUomId: item.yarnRateUomId,
                yarnRateUomRelationValue: item.yarnRateUomRelationValue,
                yarnRateCalculatedValue: item.yarnRateCalculatedValue,
                yarnQuantityUom: item.yarnQuantityUom,
                yarnQuantityUomId: item.yarnQuantityUomId,
                yarnQuantityUomRelationValue: item.yarnQuantityUomRelationValue,
                yarnQuantityCalculatedValue: item.yarnQuantityCalculatedValue,
                yarnVariableRelationValue: item.yarnVariableRelationValue,
                ItemNameData: [],
              });
            });

            juteYarnItems.push({
              id: juteYarnItems.length + 1,
              regularSalesOrderId: response.data.regularSalesId,
              yarnSalesOrderLineItemId: "",
              yarnSalesOrderId: "",
              createdBy: "",
              createdDate: "",
              companyId: "",
              companyName: "",
              quotationId: "",
              quotationLineitemId: "",
              hsncode: "",
              itemGroupId: "",
              itemGroupValue: "",
              itemGroupName: "",
              itemId: null,
              itemName: "",
              itemCode: "",
              itemValue: "",
              itemMake: "",
              quantity: "",
              uom: "",
              uom2: "",
              rate: "",
              doBalQty: "",
              invoiceBalQty: "",
              igstAmount: "0.00",
              igstPercent: "0.00",
              cgstAmount: "0.00",
              cgstPercent: "0.00",
              sgstAmount: "0.00",
              sgstPercent: "0.00",
              discountType: "",
              discountName: "",
              discountAmount: "",
              discount: "",
              discountedRate: "",
              netAmount: "0.00",
              totalAmount: "0.00",
              remarks: "",
              taxPercent: "",
              isActive: 1,
              yarnIsActive:1,
              currency: "INR",
              status: "",
              yarnRateUom: "",
              yarnRateUomId: "",
              yarnRateUomRelationValue: "",
              yarnRateCalculatedValue: "",
              yarnQuantityUom: "",
              yarnQuantityUomId: "",
              yarnQuantityUomRelationValue: "",
              yarnQuantityCalculatedValue: "",
              yarnVariableRelationValue: "",
              yarnRateIncludingBrokerage: "",
              yarnRateExcludingBrokerage: "",
              updatedBy: null,
              updatedOn: null,
              uom2Data: [],
            });

            yield put(SalesOrderJuteYarnLineItems(juteYarnItems));
          }
        }

        var TotalCGST = response.data.salesOrderDTO.salesOrderLineItemDTO
          .filter((item) => item.isActive !== 0)
          .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
          .reduce(
            (cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind)
          );
        var TotalSGST = response.data.salesOrderDTO.salesOrderLineItemDTO
          .filter((item) => item.isActive !== 0)
          .map((p) => (p.sgstAmount == null ? 0 : p.sgstAmount))
          .reduce(
            (sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind)
          );
        var TotalIGST = response.data.salesOrderDTO.salesOrderLineItemDTO
          .filter((item) => item.isActive !== 0)
          .map((p) => (p.igstAmount == null ? 0 : p.igstAmount))
          .reduce(
            (igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind)
          );
        var netAmount = response.data.salesOrderDTO.salesOrderLineItemDTO
          .filter((item) => item.isActive !== 0)
          .map((p) => p.netAmount)
          .reduce((netAmount, ind) => parseFloat(netAmount) + parseFloat(ind));
        var taxTotals =
          Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);

        var createddate =
          response.data.salesOrderDTO.createdDate !== null &&
          response.data.salesOrderDTO.createdDate.split("T");
        var salesdate =
          response.data.salesOrderDTO.salesDate !== null &&
          response.data.salesOrderDTO.salesDate.split("T");
        var salesOrderExpirydate =
          response.data.salesOrderDTO.salesOrderExpiryDate !== null &&
          response.data.salesOrderDTO.salesOrderExpiryDate.split("T");
        var ApproveButton = false;
        var RejectButton = false;
        var UpdateButton = true;
        var EditButton = true;

        if (response.data.salesOrderDTO.approveStatus == true) {
          if (
            response.data.salesOrderDTO.statusId == 1 ||
            response.data.salesOrderDTO.statusId == 17 ||
            response.data.salesOrderDTO.statusId == 18 ||
            response.data.salesOrderDTO.statusId == 19 ||
            response.data.salesOrderDTO.statusId == 20
          ) {
            ApproveButton = true;
            RejectButton = true;
            UpdateButton = true;
            EditButton = true;
          }
        } else if (
          response.data.salesOrderDTO.statusId == 1 ||
          response.data.salesOrderDTO.statusId == 17 ||
          response.data.salesOrderDTO.statusId == 18 ||
          response.data.salesOrderDTO.statusId == 19 ||
          response.data.salesOrderDTO.statusId == 20
        ) {
          UpdateButton = true;
          EditButton = true;
        }
        if (
          response.data.salesOrderDTO.statusId == 4 ||
          response.data.salesOrderDTO.statusId == 3 ||
          response.data.salesOrderDTO.statusId == 5
        ) {
          ApproveButton = false;
          RejectButton = false;
          UpdateButton = false;
          EditButton = false;
        }
        const SoJuteYarnHeaders = {
          regularSalesId: response.data.salesOrderDTO.regularSalesId,
          finYear: response.data.salesOrderDTO.finYear,
          createdBy: response.data.salesOrderDTO.createdBy,
          createdByDesc: response.data.salesOrderDTO.createdByDesc,
          createdDate: createddate[0],
          salesDate: salesdate[0],
          salesNo: response.data.salesOrderDTO.salesNo,
          invoiceTypeId: response.data.salesOrderDTO.invoiceTypeId,
          invoiceTypeName: response.data.salesOrderDTO.invoiceTypeName,
          companyId: response.data.salesOrderDTO.companyId,
          companyName: response.data.salesOrderDTO.companyName,
          branchId: response.data.salesOrderDTO.branchId,
          branchName: response.data.salesOrderDTO.branchName,
          transporterId: response.data.salesOrderDTO.transporterId,
          transporterAddressName:
            response.data.salesOrderDTO.transporterAddress,
          transporterStateCode: response.data.salesOrderDTO.transporterId,
          transporterStateName:
            response.data.salesOrderDTO.transporterStateName,
          quotationId: response.data.salesOrderDTO.quotationId,
          quotationNo: response.data.salesOrderDTO.quotationSalesOrderNo,
          customerId: response.data.salesOrderDTO.customerId,
          bankId: response.data.salesOrderDTO.bankId,
          bankName: response.data.salesOrderDTO.bankName,
          bankId: response.data.salesOrderDTO.bankId,
          ifscCode: response.data.salesOrderDTO.ifscCode,
          accountNo: response.data.salesOrderDTO.accountNo,
          customerName: response.data.salesOrderDTO.customerName,
          customerLineItemId: response.data.salesOrderDTO.customerLineItemId,
          brokerId: response.data.salesOrderDTO.brokerId,
          brokerName: response.data.salesOrderDTO.brokerName,
          ContactNumber: "",
          ContactPerson: "",
          billingTo: response.data.salesOrderDTO.billingTo,
          billingToAddress: response.data.salesOrderDTO.billingToAddress,
          billingToGST: response.data.salesOrderDTO.billedToGstin,
          billingContactNo: response.data.salesOrderDTO.billedToContactNumber,
          billingContactPerson:
            response.data.salesOrderDTO.billedToContactPerson,
          billingToEmail: response.data.salesOrderDTO.billedToEmail,
          shippingTo: response.data.salesOrderDTO.shippingTo,
          shippingToAddress: response.data.salesOrderDTO.shippingToAddress,
          shippingToGST: response.data.salesOrderDTO.shippedToGstin,
          shippingContactNo: response.data.salesOrderDTO.shippedToContactNumber,
          shippingContactPerson:
            response.data.salesOrderDTO.shippedToContactPerson,
          shippingToEmail: response.data.salesOrderDTO.shippedToEmail,
          salesOrderExpiryDate: salesOrderExpirydate[0],
          challanNo: response.data.salesOrderDTO.challanNo,
          challanDate: response.data.salesOrderDTO.challanDate,
          taxType: response.data.salesOrderDTO.taxType,
          taxName: response.data.salesOrderDTO.taxName,
          brokerCommision: response.data.salesOrderDTO.brokerCommision,
          brokerCommisionPercent:
            response.data.salesOrderDTO.brokerCommisionPercent,
          footerNote: response.data.salesOrderDTO.footerNote,
          internalNote: response.data.salesOrderDTO.internalNote,
          termsConditionType: response.data.salesOrderDTO.termsConditionType,
          termsCondition: response.data.salesOrderDTO.termsCondition,
          deliveryTerms: response.data.salesOrderDTO.deliveryTerms,
          paymentTerms: response.data.salesOrderDTO.paymentTerms,
          deliveryDays: response.data.salesOrderDTO.deliveryDays,
          freightCharges: response.data.salesOrderDTO.freightCharges,
          amountWithoutTax: response.data.salesOrderDTO.amountWithoutTax,
          amountWithTax: response.data.salesOrderDTO.amountWithTax,
          updatedBy: response.data.salesOrderDTO.updatedBy,
          updatedByDesc: response.data.salesOrderDTO.updatedByDesc,
          updatedOn: response.data.salesOrderDTO.updatedOn,
          approvedBy: response.data.salesOrderDTO.approvedBy,
          approvedBydesc: response.data.salesOrderDTO.approvedBydesc,
          approvedOn: response.data.salesOrderDTO.approvedOn,
          doStatus:
            (!isEmpty(response.data.salesOrderDTO.doStatus) &&
              getStatus(response.data.salesOrderDTO.doStatus)) ||
            response.data.salesOrderDTO.statusName ||
            "",
          statusId: response.data.salesOrderDTO.statusId,
          statusName: response.data.salesOrderDTO.statusName,
          isActive: 1,
          yarnSalesId: response.data.yarnSalesId,
          approveStatus: response.data.salesOrderDTO.approveStatus,
          brokerCommisionSource: response.data.brokerCommisionSource,

          totalIGST: TotalIGST == null ? "0.00" : limitDecimals(TotalIGST, 2),
          totalCGST: TotalCGST == null ? "0.00" : limitDecimals(TotalCGST, 2),
          totalSGST: TotalSGST == null ? "0.00" : limitDecimals(TotalSGST, 2),

          subTotal: limitDecimals(netAmount, 2),
          taxTotal: limitDecimals(taxTotals, 2),
          netTotal: limitDecimals(netAmount, 2),
          TotalAmount: limitDecimals(Number(netAmount) + Number(taxTotals), 2),

          ApproveButton: ApproveButton,
          RejectButton: RejectButton,
          UpdateButton: UpdateButton,
          EditButton: EditButton,
        };
        yield put(SalesOrderJuteYarnHeaders(SoJuteYarnHeaders));
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function* getItemUomRelationValueByUomCode({
  payload: { url, ItemId, type, history },
}) {
  try {
    const response = yield call(getListurl, url);
    const { SoJuteYarnLineItems, SoJuteYarnHeaders } = yield select(
      (state) => state.SalesOrderReducer
    );
    if (response) {
      let SecondaryUOMObj = response.data;
      let LineItemsRecords = [...SoJuteYarnLineItems];
      if (SecondaryUOMObj) {
        if (type === "quantityUOM") {
          LineItemsRecords = LineItemsRecords.map((item, index) => {
            if (ItemId === item.itemId) {
              return {
                ...item,
                yarnQuantityUomRelationValue: SecondaryUOMObj.relationValue,
                yarnQuantityCalculatedValue: limitDecimals(
                  convertOtherUOMTOPrimaryUOM(
                    item.quantity,
                    SecondaryUOMObj.relationValue
                  ),
                  3
                ),
              };
            }
            return item;
          });
          let Calculations = SalesGstCalculations(
            {
              lineItems: LineItemsRecords,
              taxType: SoJuteYarnHeaders.taxType,
              brokerCommisionPercent: SoJuteYarnHeaders.brokerCommisionPercent,
            },
            "SO_JUTE_YARN_UOM"
          );

          yield put(SalesOrderJuteYarnLineItems(Calculations.lineItems));
          let JuteYarnHeadersData = {
            ...SoJuteYarnHeaders,
            totalIGST: Calculations.totalIGST,
            totalCGST: Calculations.totalCGST,
            totalSGST: Calculations.totalSGST,
            taxTotal: Calculations.taxTotal,
            TotalAmount: Calculations.TotalAmount,
            netTotal: Calculations.netTotal,
            subTotal: Calculations.subTotal,
          };
          yield put(SalesOrderJuteYarnHeaders(JuteYarnHeadersData));
        } else if (type === "yarnRateUom") {
          LineItemsRecords = LineItemsRecords.map((item, index) => {
            if (ItemId === item.itemId) {
              return {
                ...item,
                yarnRateUomRelationValue: SecondaryUOMObj.relationValue,
                yarnRateCalculatedValue: limitDecimals(
                  convertOtherRateUOmToPrimaryUOM(
                    item.rate,
                    SecondaryUOMObj.relationValue
                  ),
                  3
                ),
                discountedRate: limitDecimals(
                  convertOtherRateUOmToPrimaryUOM(
                    item.rate,
                    SecondaryUOMObj.relationValue
                  ),
                  3
                ),
              };
            }
            return item;
          });

          let Calculations = SalesGstCalculations(
            {
              lineItems: LineItemsRecords,
              taxType: SoJuteYarnHeaders.taxType,
              brokerCommisionPercent: SoJuteYarnHeaders.brokerCommisionPercent,
            },
            "SO_JUTE_YARN_UOM"
          );

          yield put(SalesOrderJuteYarnLineItems(Calculations.lineItems));
          let JuteYarnHeadersData = {
            ...SoJuteYarnHeaders,
            totalIGST: Calculations.totalIGST,
            totalCGST: Calculations.totalCGST,
            totalSGST: Calculations.totalSGST,
            taxTotal: Calculations.taxTotal,
            TotalAmount: Calculations.TotalAmount,
            netTotal: Calculations.netTotal,
            subTotal: Calculations.subTotal,
          };
          yield put(SalesOrderJuteYarnHeaders(JuteYarnHeadersData));
        }
      }

      yield put(getUomRelationValueByUomCodeSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getQuotesViewByIdDataForJuteGovt({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getQuoteViewByIdForJuteGovtSuccessfull({ response }));
    if (response.data) {
      let CustomerUrl =
        serverApi.GET_ALL_BILLING_SHIPPING +
        response.data.customerId +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher +
        "?isForSalesModule=1";
      yield put(getBillingShippingList(CustomerUrl, history));
      let responseData = response.data;
      if (response.data.salesQuotationLineItems) {
        if (response.data.salesQuotationLineItems.length !== 0) {
          let juteGovtItems = [];
          response.data.salesQuotationLineItems.map((item, i) => {
            juteGovtItems.push({
              lineItemId: item.id,
              salesOrderLineItemId: item.salesOrderLineItemId,
              regularSalesOrderId: item.regularSalesOrderId || "",
              govtSalesOrderLineItemId: item.govtSalesOrderLineItemId || "",
              createdBy: item.createdBy || "",
              createdDate: item.createdDate || "",
              companyId: item.companyId || "",
              currency: "INR",
              hsnCode: item.hsnCode || "",
              itemGroupName: item.itemGroupName || "",
              itemGroupValue: item.itemGroupValue || "",
              itemGroupId: item.itemGroupId || null,
              itemId: item.itemId || null,
              itemName: item.itemName || "",
              itemCode: item.itemCode || "",
              itemMake: item.itemMake || "",
              quantity: item.quantity,
              uom: item.uom || "",
              rate: item.rate,
              doBalQty: "",
              invoiceBalQty: item.invoiceBalQty,
              rateUom: "",
              igstAmount: item.igstAmount == null ? "0.00" : item.igstAmount,
              igstPercent: item.igstPercent == null ? "0" : item.igstPercent,
              cgstAmount: item.cgstAmount == null ? "0.00" : item.cgstAmount,
              cgstPercent: item.cgstPercent == null ? "0" : item.cgstPercent,
              sgstAmount: item.sgstAmount == null ? "0.00" : item.sgstAmount,
              sgstPercent: item.sgstPercent == null ? "0" : item.sgstPercent,
              discountType: item.discountType,
              discountName: item.discountName,
              discount: item.discount,
              discountedRate: item.discountedRate,
              discountAmount: item.discountAmount,
              netAmount: item.netAmount,
              totalAmount: item.totalAmount,
              remarks: item.remarks,
              taxPercent: limitDecimals(item.itemTaxPercentage, 2),
              isActive: item.isActive,
              govtIsActive:item.govtIsActive,
              govtQuantityUom: "",
              rateUom2: "",
              status: "",
              govtQuantityUom2: "",
              updatedBy: null,
              updatedOn: null,
            });
          });

          juteGovtItems.push({
            lineItemId: juteGovtItems.length + 1,
            salesOrderLineItemId: "",
            regularSalesOrderId: "",
            govtSalesOrderLineItemId: "",
            createdBy: "",
            createdDate: "",
            companyId: "",
            currency: "INR",
            hsnCode: "",
            itemGroupName: "",
            itemGroupValue: "",
            itemGroupId: null,
            itemId: null,
            itemName: "",
            itemCode: "",
            itemMake: "",
            quantity: "0.0",
            uom: "",
            rate: "",
            doBalQty: "",
            invoiceBalQty: "",
            igstAmount: "0.0",
            igstPercent: "0.0",
            cgstAmount: "0.0",
            cgstPercent: "0.0",
            sgstAmount: "0.0",
            sgstPercent: "0.0",
            discountType: "",
            discount: "",
            discountedRate: "",
            discountAmount: "",
            netAmount: "0.0",
            totalAmount: "0.0",
            taxPercent: null,
            remarks: "",
            rateUom: "",
            isActive: 1,
            govtIsActive:1,
            govtQuantityUom: "",
            rateUom2: "",
            status: "",
            govtQuantityUom2: "",
            updatedBy: null,
            updatedOn: null,
          });
          yield put(SalesOrderJuteGovtLineItems(juteGovtItems));
        }
      }
      let TotalCGST = responseData.salesQuotationLineItems
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
        .reduce((cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind));
      let TotalSGST = responseData.salesQuotationLineItems
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.sgstAmount == null ? 0 : p.sgstAmount))
        .reduce((sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind));
      let TotalIGST = responseData.salesQuotationLineItems
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.igstAmount == null ? 0 : p.igstAmount))
        .reduce((igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind));

      let netAmount = responseData.salesQuotationLineItems
        .filter((item) => item.isActive !== 0)
        .map((p) => p.netAmount)
        .reduce((netAmount, ind) => parseFloat(netAmount) + parseFloat(ind));
      let taxTotals = Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);

      let createddate = responseData.createdDate.split("T");
      let quotationDate =
        responseData.quotationDate !== null &&
        responseData.quotationDate.split("T");
      let quotationExpiryDate =
        responseData.quotationExpiryDate !== null &&
        responseData.quotationExpiryDate.split("T");
      const SoJuteGovtHeaders = {
        salesId: responseData.salesId || "",
        // Map other properties to their respective keys in responseData
        finYear: responseData.finYear || "",
        createdBy: responseData.createdBy || "",
        createdDate: createddate[0],
        salesDate: quotationDate[0],
        companyId: responseData.companyId || "",
        branchId: responseData.branchId || "",
        quotationId: responseData.quotationId || "",
        customerId: responseData.customerId || "",
        customerName: responseData.customerName || "",
        customerLineItemId: responseData.customerLineItemId || "",
        brokerId: responseData.brokerId || "",
        brokerName: responseData.brokerName || "",
        billingTo: responseData.billingTo || "",
        billingToAddress: responseData.billingToAddress || "",
        billingToGST: responseData.billingToGST || "",
        billingContactNo: responseData.billingContactNo || "",
        billingContactPerson: responseData.billingContactPerson || "",
        billingToEmail: responseData.billingToEmail || "",
        shippingTo: responseData.shippingTo || "",
        shippingToAddress: responseData.shippingToAddress || "",
        shippingToGST: responseData.shippingToGST || "",
        shippingContactNo: responseData.shippingContactNo || "",
        shippingContactPerson: responseData.shippingContactPerson || "",
        shippingToEmail: responseData.shippingToEmail || "",
        salesOrderExpiryDate: quotationExpiryDate[0],
        challanNo: responseData.challanNo || "",
        challanDate: responseData.challanDate || "",
        taxType: responseData.taxType || "",
        brokerCommision: responseData.brokerCommision || "",
        brokerCommisionPercent: responseData.brokerCommisionPercent || "",
        footerNote: responseData.footerNote || "",
        internalNote: responseData.internalNote || "",
        termsConditionType: responseData.termsConditionType || "",
        termsCondition: responseData.termsCondition || "",
        transporterId: responseData.transporterId || "",
        transporterName: responseData.transporterName || "",
        transporterAddress: responseData.transporterAddress || "",
        transporterStateName: responseData.transporterStateName || "",
        transporterStateCode: responseData.transporterStateCode || "",
        expiredDate: responseData.expiredDate || null,
        approveStatus: responseData.approveStatus || false,
        deliveryTerms: responseData.deliveryTerms || "",
        paymentTerms: responseData.paymentTerms || "",
        deliveryDays: responseData.deliveryDays || "",
        freightCharges: responseData.freightCharges || "",
        amountWithoutTax: responseData.amountWithoutTax || "",
        amountWithTax: responseData.amountWithTax || "",
        updatedBy: responseData.updatedBy || "",
        updatedOn: responseData.updatedOn || "",
        approvedBy: responseData.approvedBy || "",
        approvedBydesc: responseData.approvedBydesc || "",
        approvedOn: responseData.approvedOn || "",
        statusId: responseData.statusId || "",
        statusName: responseData.statusName || "",
        invoiceTypeId: responseData.invoiceTypeId || "",
        totalIGST:
          TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
        totalCGST:
          TotalCGST == null ? "0.00" : parseFloat(TotalCGST).toFixed(2),
        totalSGST:
          TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),
        subTotal: parseFloat(netAmount).toFixed(2),
        taxTotal: parseFloat(taxTotals).toFixed(2),
        netTotal: parseFloat(netAmount - taxTotals).toFixed(2),
        TotalAmount: parseFloat(Number(taxTotals) + Number(netAmount)).toFixed(
          2
        ),
        pcsoNo: responseData.pcsoNo || "",
        pcsoDate: responseData.pcsoDate || null,
        ammendmentNo: responseData.ammendmentNo || "",
        ammendmentDate: responseData.ammendmentDate || null,
        ammendmentNo2: responseData.ammendmentNo2 || "",
        ammendmentDate2: responseData.ammendmentDate2 || null,
        ammendmentNo3: responseData.ammendmentNo3 || "",
        ammendmentDate3: responseData.ammendmentDate3 || null,
        ammendmentNo4: responseData.ammendmentNo4 || "",
        ammendmentDate4: responseData.ammendmentDate4 || null,
        ammendmentNo5: responseData.ammendmentNo5 || "",
        ammendmentDate5: responseData.ammendmentDate5 || null,
        ApproveButton: responseData.ApproveButton,
        RejectButton: responseData.RejectButton,
        UpdateButton: responseData.UpdateButton,
      };
      yield put(SalesOrderJuteGovtHeaders(SoJuteGovtHeaders));
    }
  } catch (error) {
    console.log(error);
  }
}

function* SODataByIdForJuteGovt({ payload: { url, history, Val } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(SOByIdForJuteGovtSuccessfull({ response }));
    if (response.data) {
      if (Val) {
        let CustomerUrl =
          serverApi.GET_ALL_BILLING_SHIPPING +
          response.data.salesOrderDTO.customerId +
          "/" +
          JSON.parse(localStorage.getItem("authUser")).cipher +
          "?isForSalesModule=1";
        yield put(getBillingShippingList(CustomerUrl, history));
      }
      let responseData = response.data.salesOrderDTO;
      if (response.data.salesOrderDTO) {
        if (response.data.salesOrderDTO.salesOrderLineItemDTO.length !== 0) {
            const firstLineItemData = responseData.salesOrderLineItemDTO[0];
          const firstLineItemTaxPercentageData = firstLineItemData.itemTaxPercentage;
          let juteGovtItems = [];
          response.data.salesOrderDTO.salesOrderLineItemDTO.map((item, i) => {
            juteGovtItems.push({
              lineItemId: item.regularSalesOrderLineItemId,
              regularSalesOrderLineItemId: item.regularSalesOrderLineItemId,
              regularSalesOrderId: item.regularSalesOrderId || "",
              govtSalesOrderLineItemId: item.govtSalesOrderLineItemId || "",
              createdBy: item.createdBy || "",
              createdDate: item.createdDate || "",
              companyId: item.companyId || "",
              currency: "INR",
              hsnCode: item.hsncode || "",
              itemGroupName: item.itemGroupName || "",
              itemGroupValue: item.itemGroupValue || "",
              itemGroupId: item.itemGroupId || null,
              itemId: item.itemId || null,
              itemName: item.itemName || "",
              itemCode: item.itemCode || "",
              itemMake: item.itemMake || "",
              quantity: limitDecimals(item.quantity, 3),
              uom: item.uom || "",
              rate: limitDecimals(item.rate, 3),
              doBalQty: "",
              invoiceBalQty: item.invoiceBalQty,
              rateUom: "",
              igstAmount: item.igstAmount == null ? "0.00" : item.igstAmount,
              igstPercent: item.igstPercent == null ? "0" : item.igstPercent,
              cgstAmount: item.cgstAmount == null ? "0.00" : item.cgstAmount,
              cgstPercent: item.cgstPercent == null ? "0" : item.cgstPercent,
              sgstAmount: item.sgstAmount == null ? "0.00" : item.sgstAmount,
              sgstPercent: item.sgstPercent == null ? "0" : item.sgstPercent,
              discountType: item.discountType,
              discountName: item.discountName,
              discount: item.discount,
              discountedRate: item.discountedRate,
              discountAmount: limitDecimals(item.discountAmount, 2),
              netAmount: limitDecimals(item.netAmount, 2),
              totalAmount: limitDecimals(item.totalAmount, 2),
              remarks: item.remarks,
              // taxPercent: limitDecimals(item.itemTaxPercentage, 2),
              taxPercent:limitDecimals(firstLineItemTaxPercentageData,2),
              isActive: item.isActive,
              govtIsActive:item.govtIsActive,
              rateUom2: "",
              status: "",
              govtRateUom: item.govtRateUom,
              govtRateUom2: item.govtRateUom2,
              govtRateUom3: item.govtRateUom3,
              govtQuantityUom: item.govtQuantityUom,
              govtQuantityUom2: item.govtQuantityUom2,
              govtQuantityUom3: item.govtQuantityUom3,
              govtRelationValue: item.govtRelationValue,
              govtRelationValue2: item.govtRelationValue2,
              govtRelationValue3: item.govtRelationValue3,
              govtQtyCalculatedValue: item.govtQtyCalculatedValue,
              govtQtyCalculatedValue2: item.govtQtyCalculatedValue2,
              govtQtyCalculatedValue3: item.govtQtyCalculatedValue3,
              govtRateCalculatedValue: item.govtRateCalculatedValue || "",
              govtRateCalculatedValue2: item.govtRateCalculatedValue2 || "",
              govtRateCalculatedValue3: item.govtRateCalculatedValue3 || "",
              updatedBy: null,
              updatedOn: null,
            });
          });
          yield put(SalesOrderJuteGovtLineItems(juteGovtItems));
        }
      }

      var TotalCGST = response.data.salesOrderDTO.salesOrderLineItemDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
        .reduce((cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind));
      var TotalSGST = response.data.salesOrderDTO.salesOrderLineItemDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.sgstAmount == null ? 0 : p.sgstAmount))
        .reduce((sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind));
      var TotalIGST = response.data.salesOrderDTO.salesOrderLineItemDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => (p.igstAmount == null ? 0 : p.igstAmount))
        .reduce((igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind));
      var netAmount = response.data.salesOrderDTO.salesOrderLineItemDTO
        .filter((item) => item.isActive !== 0)
        .map((p) => p.netAmount)
        .reduce((netAmount, ind) => parseFloat(netAmount) + parseFloat(ind));
      var taxTotals = Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);

      var createddate =
        responseData.createdDate !== null &&
        responseData.createdDate.split("T");
      var salesdate =
        responseData.salesDate !== null
          ? responseData.salesDate.split("T")
          : null;
      var pcsoDate =
        response.data.pcsoDate !== null
          ? response.data.pcsoDate.split("T")
          : null;
      var salesOrderExpirydate =
        responseData.salesOrderExpiryDate !== null
          ? responseData.salesOrderExpiryDate.split("T")
          : "";

      var ApproveButton = false;
      var RejectButton = false;
      var UpdateButton = true;
      var EditButton = true;

      if (responseData.approveStatus == true) {
        if (
          responseData.statusId == 1 ||
          responseData.statusId == 17 ||
          responseData.statusId == 18 ||
          responseData.statusId == 19 ||
          responseData.statusId == 20
        ) {
          ApproveButton = true;
          RejectButton = true;
          UpdateButton = true;
          EditButton = true;
        }
      } else if (
        response.data.statusId == 1 ||
        response.data.statusId == 17 ||
        response.data.statusId == 18 ||
        response.data.statusId == 19 ||
        response.data.statusId == 20
      ) {
        UpdateButton = true;
        EditButton = true;
      }

      if (
        responseData.statusId == 4 ||
        responseData.statusId == 3 ||
        responseData.statusId == 5
      ) {
        ApproveButton = false;
        RejectButton = false;
        UpdateButton = false;
        EditButton = false;
      }
      const SoJuteGovtHeaders = {
        regularSalesId: responseData.regularSalesId || "",
        salesNo: responseData.salesNo || "",
        // Map other properties to their respective keys in responseData
        finYear: responseData.finYear || "",
        createdBy: responseData.createdBy || "",
        createdDate: createddate[0],
        salesDate: salesdate[0],
        companyId: responseData.companyId || "",
        branchId: responseData.branchId || null,
        branchName: responseData.branchName || "",
        quotationId: responseData.quotationId || "",
        customerId: responseData.customerId || null,
        customerName: responseData.customerName || "",
        customerLineItemId: responseData.customerLineItemId || "",
        brokerId: responseData.brokerId || "",
        brokerName: responseData.brokerName || "",
        billingTo: responseData.billingTo || "",
        billingToAddress: responseData.billingToAddress || "",
        billingToGST: responseData.billedToGstin || "",
        billingContactNo: responseData.billedToContactNumber || "",
        billingContactPerson: responseData.billedToContactPerson || "",
        billingToEmail: responseData.billedToEmail || "",
        shippingTo: responseData.shippingTo || "",
        shippingToAddress: responseData.shippingToAddress || "",
        shippingToGST: responseData.shippedToGstin || "",
        shippingContactNo: responseData.shippedToContactNumber || "",
        shippingContactPerson: responseData.shippedToContactPerson || "",
        shippingToEmail: responseData.shippedToEmail || "",
        salesOrderExpiryDate: salesOrderExpirydate[0],
        challanNo: responseData.challanNo || "",
        challanDate: responseData.challanDate || "",
        taxType: responseData.taxType || "",
        brokerCommision: responseData.brokerCommision || "",
        brokerCommisionPercent: responseData.brokerCommisionPercent || "",
        footerNote: responseData.footerNote || "",
        internalNote: responseData.internalNote || "",
        termsConditionType: responseData.termsConditionType || "",
        termsCondition: responseData.termsCondition || "",
        transporterId: responseData.transporterId || "",
        transporterName: responseData.transporterName || "",
        transporterAddress: responseData.transporterAddress || "",
        transporterStateName: responseData.transporterStateName || "",
        transporterStateCode: responseData.transporterStateCode || "",
        expiredDate: responseData.expiredDate || null,
        approveStatus: responseData.approveStatus || false,
        deliveryTerms: responseData.deliveryTerms || "",
        paymentTerms: responseData.paymentTerms || "",
        deliveryDays: responseData.deliveryDays || "",
        freightCharges: responseData.freightCharges || "",
        amountWithoutTax: responseData.amountWithoutTax || "",
        amountWithTax: responseData.amountWithTax || "",
        updatedBy: responseData.updatedBy || "",
        updatedOn: responseData.updatedOn || "",
        approvedBy: responseData.approvedBy || "",
        approvedBydesc: responseData.approvedBydesc || "",
        approvedOn: responseData.approvedOn || "",
        statusId: responseData.statusId || "",
        statusName: responseData.statusName || "",
        doStatus:
          (!isEmpty(responseData.doStatus) &&
            getStatus(responseData.doStatus)) ||
          responseData.statusName ||
          "",
        invoiceTypeId: 2,
        totalIGST:
          TotalIGST == null ? "0.00" : parseFloat(TotalIGST).toFixed(2),
        totalCGST:
          TotalCGST == null ? "0.00" : parseFloat(TotalCGST).toFixed(2),
        totalSGST:
          TotalSGST == null ? "0.00" : parseFloat(TotalSGST).toFixed(2),
        subTotal: parseFloat(netAmount).toFixed(2),
        taxTotal: parseFloat(taxTotals).toFixed(2),
        netTotal: parseFloat(netAmount).toFixed(2),
        TotalAmount: parseFloat(Number(taxTotals) + Number(netAmount)).toFixed(
          2
        ),
        pcsoNo: response.data.pcsoNo || "",
        pcsoDate: pcsoDate !== null ? pcsoDate[0] : "",
        govtSalesOrderId: response.data.govtSalesOrderId,
        ammendmentNo: response.data.ammendmentNo || "",
        ammendmentDate: response.data.ammendmentDate || null,
        ApproveButton: ApproveButton,
        RejectButton: RejectButton,
        UpdateButton: UpdateButton,
        EditButton: EditButton,
      };
      yield put(SalesOrderJuteGovtHeaders(SoJuteGovtHeaders));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getSalesOrderItemByItemGroupData({
  payload: { url, data, history, rowId },
}) {
  const { LineItemsData } = yield select((state) => state.SalesOrderReducer);
  try {
    const response = yield call(getList, url, data);
    if (response) {
      yield put(getSalesOrderItemsByItemGroupIdSuccessfull({ response }));
      if (response) {
        let ListFilter = response.filter((data) => {
          if (data.value !== "0") {
            return data;
          }
        });
        var list = [];
        ListFilter.map((prop) => {
          list.push({
            label: prop.itemDesc,
            name: prop.itemDesc,
            value: prop.itemId,
            variant: prop.itemCode,
          });
        });
      }
    }
    if (LineItemsData) {
      var LineItemsRecords = [];
      if (LineItemsData.length !== 0) {
        LineItemsRecords = LineItemsData.map((item) => {
          if (rowId == item.id) {
            item.ItemNameData = list;
          }
          return item;
        });
        yield put(SalesOrderLineItems(LineItemsRecords));
      }
    }
  } catch (error) {
    console.error("An error occurred:", error);
  }
}

function* getSalesOrderHessianItemByItemGroupData({
  payload: { url, data, history, rowId },
}) {
  const { SoJuteHessianLineItems } = yield select(
    (state) => state.SalesOrderReducer
  );
  try {
    const response = yield call(getList, url, data);
    if (response) {
      yield put(
        getSalesOrderHessianItemsByItemGroupIdSuccessfull({ response })
      );
      if (response) {
        let ListFilter = response.filter((data) => {
          if (data.value !== "0") {
            return data;
          }
        });
        var list = [];
        ListFilter.map((prop) => {
          list.push({
            label: prop.itemDesc,
            name: prop.itemDesc,
            value: prop.itemId,
            variant: prop.itemCode,
          });
        });
      }
    }
    if (SoJuteHessianLineItems) {
      var LineItemsRecords = [];
      if (SoJuteHessianLineItems.length !== 0) {
        LineItemsRecords = SoJuteHessianLineItems.map((item) => {
          if (rowId == item.id) {
            item.ItemNameData = list;
          }
          return item;
        });
        yield put(SalesOrderJuteHessianLineItems(LineItemsRecords));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* ItemByItemGroupDataForJuteGovt({
  payload: { url, data, history, rowId },
}) {
  const { SoJuteGovtLineItems } = yield select(
    (state) => state.SalesOrderReducer
  );
  try {
    const response = yield call(getList, url, data);
    if (response) {
      if (response) {
        let ListFilter = response.filter((data) => {
          if (data.value !== "0") {
            return data;
          }
        });
        var list = [];
        ListFilter.map((prop) => {
          list.push({
            label: prop.itemDesc,
            name: prop.itemDesc,
            value: prop.itemId,
            variant: prop.itemCode,
          });
        });
      }

      if (SoJuteGovtLineItems) {
        var LineItemsRecords = [];
        if (SoJuteGovtLineItems.length !== 0) {
          LineItemsRecords = SoJuteGovtLineItems.map((item) => {
            if (rowId == item.lineItemId) {
              item.itemsListData = list;
            }
            return item;
          });
          yield put(SalesOrderLineItems(LineItemsRecords));
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function* getItemsByItemGroupIdForJuteYarnData({
  payload: { url, data, history, rowId },
}) {
  const { SoJuteYarnLineItems } = yield select(
    (state) => state.SalesOrderReducer
  );
  try {
    const response = yield call(getList, url, data);
    if (response) {
      yield put(getItemsByItemGroupIdForJuteYarnSuccessfull({ response }));
      if (response) {
        let ListFilter = response.filter((data) => {
          if (data.value !== "0") {
            return data;
          }
        });
        var list = [];
        ListFilter.map((prop) => {
          list.push({
            label: prop.itemDesc,
            name: prop.itemDesc,
            value: prop.itemId,
            variant: prop.itemCode,
          });
        });
        if (SoJuteYarnLineItems) {
          var LineItemsRecords = [];
          if (SoJuteYarnLineItems.length !== 0) {
            LineItemsRecords = SoJuteYarnLineItems.map((item) => {
              if (rowId === item.id) {
                item.itemsListData = list;
              }
              return item;
            });
            yield put(SalesOrderJuteYarnLineItems(LineItemsRecords));
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* getSoYarnUomListData({ payload: { url, rowId ,uom} }) {
  const { SoJuteYarnLineItems } = yield select(
    (state) => state.SalesOrderReducer
  );
  try {
    const response = yield call(getListurl, url);
    if (response) {
      yield put(getSoYarnUomListSuccessfull({ response }));
      if (response) {
        var list = [];
        if (response.data) {
          if (response.data.length !== 0) {
            let ListFilter = response.data.filter((data) => {
              if (data.variable !== "0") {
                return data;
              }
            });
            ListFilter.map((prop) => {
              list.push({
                label: prop.uomCode,
                name: prop.uomCode,
                value: prop.uomCode,
                variant: prop.id,
              });
            });
          }else{
            if(uom=="UOM"){
              list=[]
            }
          }
        }else{
          list=[]
        }
      }
    }
    if (SoJuteYarnLineItems) {
      var LineUomRecords = [];
      if (SoJuteYarnLineItems.length !== 0) {
        LineUomRecords = SoJuteYarnLineItems.map((item) => {
          if (rowId == item.id) {
            if (list.length !== 0) {
              item.uom2Data = list;
            } else {
            if(uom==="UOM"){
              item.uom2Data = [];
            }else{
              item.uom2Data = [];
            }
            }
          }
          return item;
        });
        yield put(SalesOrderJuteYarnLineItems(LineUomRecords));
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function* getRegularSoItemNameData({ payload: { url, data, history, rowId } }) {
  const { LineItemsData, SalesOrderHeader } = yield select(
    (state) => state.SalesOrderReducer
  );
  try {
    const response = yield call(getList, url, data);
    if (response) {
      yield put(getRegularSoItemNameDataSuccessfull({ response }));
      if (response) {
        if (LineItemsData) {
          var LineItemsRecords = [];
          if (LineItemsData.length !== 0) {
            LineItemsRecords = LineItemsData.map((item) => {
              if (rowId === item.id) {
                item.hsncode = response.hsnCode; //hsn
                item.rate = limitDecimals(response.srRate, 3); //rate
                item.discountedRate = limitDecimals(response.srRate, 3); //discounted rate
                item.uom = response.uomCode; //uom
                item.remarks = "";
                item.itemMake = "";
                item.discountType = "";
                item.discountName = "";
                item.discount = "0.00";
                item.discountAmount = "0.00";
                item.taxPercent = limitDecimals(response.taxPercentage, 2); //tax per
                item.totalAmount = "0.00";
                item.netAmount = "0.00";
                item.quantity = "0.000";
              }
              return item;
            });
            let Calculations = SalesGstCalculations(
              {
                lineItems: LineItemsRecords,
                taxType: SalesOrderHeader.taxType,
              },
              "SALES_REGULAR_FLOW"
            );
            yield put(SalesOrderLineItems(LineItemsRecords));

            var Headerdata = {
              ...SalesOrderHeader,
              totalIGST: Calculations.totalIGST,
              totalCGST: Calculations.totalCGST,
              totalSGST: Calculations.totalSGST,
              taxTotal: Calculations.taxTotal,
              TotalAmount: Calculations.TotalAmount,
              netTotal: Calculations.netTotal,
              subTotal: Calculations.subTotal,
            };
            yield put(getSalesOrderHeader(Headerdata));
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function* getJuteGovtSoItemNameData({ payload: { url, data, rowId } }) {
  try {
    const { SoJuteGovtLineItems, SoJuteGovtHeaders } = yield select(
      (state) => state.SalesOrderReducer
    );
    const response = yield call(getList, url, data);

    if (response) {

      yield put(getJuteGovtSoItemNameDataSuccessfull({ response }));
      if (SoJuteGovtLineItems) {
        let LineItemsRecords = [];
        if (SoJuteGovtLineItems.length !== 0) {
          for (let item of SoJuteGovtLineItems) {
            if (rowId === item.lineItemId) {
              if (item.itemGroupValue !== "997") {
                item.rate = limitDecimals(response.srRate, 3);
                item.discountedRate = limitDecimals(response.srRate, 3);
                item.hsnCode = response.hsnCode;
                item.uom = response.uomCode;
                item.discount = "";
                item.discountType = "";
                item.discountName = "";
                item.discountAmount = "";
                item.quantity = "0.000";
                item.totalAmount = "";
                item.netAmount = "";
                item.taxPercent = limitDecimals(response.taxPercentage, 2);
                yield put(
                  getUomRelationValues(
                    serverApi.GET_UOM_RELATION_VALUES +
                      response.uomCode +
                      "/" +
                      response.itemId +
                      "/" +
                      localStorage.getItem("companyId")
                  )
                );
              } else {
                item.hsnCode = response.hsnCode;
                item.rate = limitDecimals(response.srRate, 3);
                item.discountedRate = limitDecimals(response.srRate, 3);
              }
            } else {
              if (item.itemGroupValue === "997") {
                if (rowId === item.lineItemId) {
                  item.make = "";
                  item.itemMake = "";
                  item.remarks = "";
                }
                item.quantity = limitDecimals(SoJuteGovtLineItems[0].quantity,3);
                item.uom = SoJuteGovtLineItems[0].uom;
                item.totalAmount = "";
                item.netAmount = "";
                item.taxPercent = limitDecimals(
                  SoJuteGovtLineItems[0].taxPercent,
                  2
                );
              }
            }
            LineItemsRecords.push(item);
          }
          let Calculations = SalesGstCalculations(
            {
              lineItems: LineItemsRecords,
              taxType: SoJuteGovtHeaders.taxType,
            },
            "SALES_REGULAR_FLOW"
          );
          let JuteGovtHeadersData = {
            ...SoJuteGovtHeaders,
            totalIGST: Calculations.totalIGST,
            totalCGST: Calculations.totalCGST,
            totalSGST: Calculations.totalSGST,
            taxTotal: Calculations.taxTotal,
            TotalAmount: Calculations.TotalAmount,
            netTotal: Calculations.netTotal,
            subTotal: Calculations.subTotal,
          };
          yield put(SalesOrderJuteGovtHeaders(JuteGovtHeadersData));
          yield put(SalesOrderJuteGovtLineItems(LineItemsRecords));
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function* getJuteYarnSoItemNameData({
  payload: { url, data, history, rowId },
}) {
  const { SoJuteYarnLineItems, SoJuteYarnHeaders } = yield select(
    (state) => state.SalesOrderReducer
  );
  try {
    const response = yield call(getList, url, data);
    if (response) {
      yield put(getJuteYarnSoItemNameDataSuccessfull({ response }));
      if (response) {
        if (SoJuteYarnLineItems) {
          var LineItemsRecords = [];
          if (SoJuteYarnLineItems.length !== 0) {
            LineItemsRecords = SoJuteYarnLineItems.map((item) => {
              if (rowId === item.id) {
                item.hsncode = response.hsnCode; //hsn
                item.rate = limitDecimals(response.srRate, 3); //rate
                item.discountedRate = limitDecimals(response.srRate, 3); //discounted rate
                item.uom = response.uomCode; //uom
                item.remarks = "";
                item.itemMake = "";
                item.discountType = "";
                item.discountName = "";
                item.discount = "0.00";
                item.discountAmount = "0.00";
                item.taxPercent = limitDecimals(response.taxPercentage, 2); //tax per
                item.totalAmount = "0.00";
                item.netAmount = "0.00";
                item.quantity = "0.000";
                item.yarnQuantityCalculatedValue="";
              }
              return item;
            });
            let Calculations = SalesGstCalculations(
              {
                lineItems: SoJuteYarnLineItems,
                taxType: SoJuteYarnHeaders.taxType,
                brokerCommisionPercent:
                  SoJuteYarnHeaders.brokerCommisionPercent,
              },
              "SO_JUTE_YARN_UOM"
            );
            yield put(SalesOrderJuteYarnLineItems(LineItemsRecords));

            let JuteYarnHeadersData = {
              ...SoJuteYarnHeaders,
              totalIGST: Calculations.totalIGST,
              totalCGST: Calculations.totalCGST,
              totalSGST: Calculations.totalSGST,
              taxTotal: Calculations.taxTotal,
              TotalAmount: Calculations.TotalAmount,
              netTotal: Calculations.netTotal,
              subTotal: Calculations.subTotal,
            };
            yield put(SalesOrderJuteYarnHeaders(JuteYarnHeadersData));
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function* getJuteHessianSoItemNameData({
  payload: { url, data, history, rowId },
}) {
  const { SoJuteHessianLineItems,SoJuteHessianHeaders } = yield select(
    (state) => state.SalesOrderReducer
  );
  try {
    const response = yield call(getList, url, data);
    if (response) {
      yield put(getJuteHessianSoItemNameDataSuccessfull({ response }));
      if (response) {
        if (SoJuteHessianLineItems) {
          var LineItemsRecords = [];
          if (SoJuteHessianLineItems.length !== 0) {
            LineItemsRecords = SoJuteHessianLineItems.map((item) => {
              if (rowId === item.id) {
                item.hsncode = response.hsnCode; //hsn
                item.rate = limitDecimals(response.srRate, 3); //rate
                item.discountedRate = limitDecimals(response.srRate, 3); //discounted rate
                item.uom = response.uomCode; //uom
                item.remarks = "";
                item.itemMake = "";
                item.discountType = "";
                item.discountName = "";
                item.discount = "0.00";
                item.discountAmount = "0.00";
                item.taxPercent = limitDecimals(response.taxPercentage, 2); //tax per
                item.totalAmount = "0.00";
                item.netAmount = "0.00";
                item.quantity = "0.000";
                item.hessianQuantityCalculatedValue="";
                item.hessianRateUom= "";
                item.hessianRateUomId= "";
                item.hessianQuantityUom="";
                item.hessianQuantityUomId=""
              }
              return item;
            });

            let Calculations = SalesGstCalculations(
              {
                lineItems: LineItemsRecords,
                taxType: SoJuteHessianHeaders.taxType,
                brokerCommisionPercent: SoJuteHessianHeaders.brokerCommisionPercent,
              },
              "SO_HESSIAN"
            );
            yield put(SalesOrderJuteHessianLineItems(LineItemsRecords));
            var Headerdata = {
              ...SoJuteHessianHeaders,
              totalIGST: Calculations.totalIGST,
              totalCGST: Calculations.totalCGST,
              totalSGST: Calculations.totalSGST,
              taxTotal: Calculations.taxTotal,
              TotalAmount: Calculations.TotalAmount,
              netTotal: Calculations.netTotal,
              subTotal: Calculations.subTotal,
            };
            yield put(SalesOrderJuteHessianHeaders(Headerdata));
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
export function* watchSalesOrderList() {
  yield takeEvery(UPLOAD_SO_FILES, UploadedFileList);
  yield takeEvery(DELETE_SO_FILE, deleteFile);
  yield takeEvery(GET_SO_FILES, getAllFileList);
  yield takeEvery(SALESORDER_LIST, getSalesOrderList);
  yield takeEvery(GET_ALL_BROKER, getAllBroker);
  yield takeEvery(ACCOUNT_LIST, getBankDetailsData);

  yield takeEvery(CREATE_SALES_ORDER, getSalesOrderData);
  yield takeEvery(
    GET_UOM_RELATION_VALUE_BY_UOM_CODE,
    getItemUomRelationValueByUomCode
  );
  yield takeEvery(UPDATE_SALES_ORDER, SetSalesOrderData);
  yield takeEvery(SALES_ORDER_BY_ID, SalesOrderDataById);
  yield takeEvery(TERMS_CONDITIONS_API, getTermsConditionsData);
  yield takeEvery(GET_BRANCH, getSalesOrderBranchData);
  yield takeEvery(GET_QUOTATION_RECORDS, getQuotationRecordsData);
  yield takeEvery(QUOTATION_VIEW_BY_ID, getQuotationViewByIdData);
  yield takeEvery(CREATE_JUTEGOVT_SALES_ORDER, CreateJuteGovtSalesOrderData);
  yield takeEvery(
    SALES_ORDER_ITEMS_BY_ITEM_GROUP_ID,
    getSalesOrderItemByItemGroupData
  );
  yield takeEvery(
    QUOTE_VIEW_BY_ID_FOR_JUTEGOVT,
    getQuotesViewByIdDataForJuteGovt
  );
  yield takeEvery(S_O_BY_ID_FOR_JUTEGOVT, SODataByIdForJuteGovt);
  yield takeEvery(UPDATE_JUTEGOVT_SALES_ORDER, UpdateJuteGovtSalesOrderData);
  yield takeEvery(
    CREATE_JUTEHESSIAN_SALES_ORDER,
    CreateJuteHessianSalesOrderData
  );
  yield takeEvery(SALES_ORDER_JUTEHESSIAN_BY_ID, SalesOrderJuteHessianById);
  yield takeEvery(
    UPDATE_JUTEHESSIAN_SALES_ORDER,
    UpdateJuteHessianSalesOrderData
  );
  yield takeEvery(
    SO_JUTE_HESSIAN_QUOTATION_VIEW_BY_ID,
    SalesOrderJuteHessianByQuotationId
  );
  yield takeEvery(
    SALES_ORDER_HESSIAN_ITEMS_BY_ITEM_GROUP_ID,
    getSalesOrderHessianItemByItemGroupData
  );
  yield takeEvery(
    GET_UOM_RELATION_VALUE_HESSIAN,
    getUomRelationByUomCodeHessian
  );
  yield takeEvery(
    ITEMS_BY_ITEM_GROUP_ID_FOR_JUTEGOVT,
    ItemByItemGroupDataForJuteGovt
  );
  yield takeEvery(CREATE_JUTEYARN_SALES_ORDER, CreateJuteYarnSalesOrderData);
  yield takeEvery(
    QUOTE_VIEW_BY_ID_FOR_JUTEYARN,
    getQuotesViewByIdDataForJuteYarn
  );
  yield takeEvery(S_O_BY_ID_FOR_JUTEYARN, SODataByIdForJuteYarn);
  yield takeEvery(UPDATE_JUTEYARN_SALES_ORDER, UpdateJuteYarnSalesOrderData);
  yield takeEvery(
    ITEMS_BY_ITEM_GROUP_ID_FOR_JUTEYARN,
    getItemsByItemGroupIdForJuteYarnData
  );
  yield takeEvery(
    UOM_LIST_BASEDON_ITEM_ID_HESSIAN,
    getUomBasedOnItemIdListHessian
  );
  yield takeEvery(SO_JUTE_YARN_UOM_LIST, getSoYarnUomListData);
  yield takeEvery(REGULAR_SO_ITEM_NAME_DATA, getRegularSoItemNameData);
  yield takeEvery(JUTE_GOVT_SO_ITEM_NAME_DATA, getJuteGovtSoItemNameData);
  yield takeEvery(JUTE_YARN_SO_ITEM_NAME_DATA, getJuteYarnSoItemNameData);
  yield takeEvery(JUTE_HESSIAN_SO_ITEM_NAME_DATA, getJuteHessianSoItemNameData);
}

function* SalesOrderSaga() {
  yield all([fork(watchSalesOrderList)]);
}

export default SalesOrderSaga;
