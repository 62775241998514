import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import {
  createBankAccount,
  updateBankAccount,
} from "../../../store/Master/BankAccountMaster/actions";
import DynamicSelect from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import moment from "moment";
import swal from "sweetalert";

class CreateBankAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      accountNo: "",
      accountName: "",
      IFSCCode: "",
      bankName: "",
      MICRCode: "",
      accountType: "",
      accountPurpose: "",
      createdBy: null,
      createdOn: "",
      accountTypeValue: "",
      id: "",
      createdDate: "",
      branch: "",
      locationSate:"",
    };
  }
  /* Calling Create and Update Api's to Actions */
  onClickCreateUpdate = () => {
    const userId = this.state.userDit.userId;
    const { accountNo, accountName, IFSCCode, bankName } = this.state;
    if (
      accountNo !== "" &&
      accountNo !== undefined &&
      accountName !== "" &&
      accountName !== undefined &&
      IFSCCode !== "" &&
      IFSCCode !== undefined &&
      bankName !== "" &&
      bankName !== undefined
    ) {
      if (this.props.location.state === undefined) {
        const data = {
          companyId: this.state.companyId,
          createdBy: userId.toString(),
          accountNo: this.state.accountNo,
          accountantName: this.state.accountName,
          ifscCode: this.state.IFSCCode,
          bankName: this.state.bankName,
          micrCode: this.state.MICRCode,
          accountType: this.state.accountTypeValue,
          accountPurpose: this.state.accountPurpose,
          createdOn: moment().format(),
          branch: this.state.branch,
        };
        let Regex = /^([0-9]{11})|([0-9]{2}-[0-9]{3}-[0-9]{6})$/;
        let Pattern = new RegExp("^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$");
        if (Regex.test(this.state.accountNo)) {
          if (Pattern.test(this.state.IFSCCode)) {
            this.props.createBankAccount(
              serverApi.CREATE_BANK_ACCOUNT,
              data,
              this.props.history
            ); // calling create bank account api
          } else {
            swal("Please enter valid ifsc code....");
          }
        } else {
          swal("Please enter valid account number....");
        }
      } else {
        const data = {
          companyId: this.state.companyId,
          createdBy: userId.toString(),
          accountNo: this.state.accountNo.toString(),
          accountantName: this.state.accountName,
          ifscCode: this.state.IFSCCode,
          bankName: this.state.bankName,
          micrCode: this.state.MICRCode,
          accountType: this.state.accountTypeValue,
          accountPurpose: this.state.accountPurpose,
          createdOn: moment().format(),
          id: this.state.id,
          branch: this.state.branch,
        };
        let regex = /^([0-9]{11})|([0-9]{2}-[0-9]{3}-[0-9]{6})$/;
        let pattern = new RegExp("^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$");
        if (regex.test(this.state.accountNo)) {
          if (pattern.test(this.state.IFSCCode)) {
            this.props.createBankAccount(
              serverApi.UPDATE_BANK_ACCOUNT,
              data,
              this.props.history
            ); // calling create bank account api
          } else {
            swal("Please enter valid ifsc code....");
          }
        } else {
          swal("Please enter valid account number....");
        }
      }
    } else {
      this.setState({
        confirm: true,
      });
    }
  };
  // function for previous page
  onClickCancel = () => {
    this.props.history.push("/Bank_Accounts");
  };
  //function for TextFields
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "transporterName") {
      this.setState({
        accountNo: event,
      });
    }
    if (key === "accountName") {
      this.setState({
        accountName: event,
      });
    }
    if (key === "IFSCCode") {
      this.setState({
        IFSCCode: event,
      });
    }
    if (key === "bankName") {
      this.setState({
        bankName: event,
      });
    }
    if (key === "MICRCode") {
      this.setState({
        MICRCode: event,
      });
    }
    if (key === "accountPurpose") {
      this.setState({
        accountPurpose: event,
      });
    }
    if (key === "branch") {
      this.setState({
        branch: event,
      });
    }
  };
  // Function for popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  // Function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "accountType") {
      this.setState({
        accountType: selectedName,
        accountTypeValue: selectedValue,
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    this.setState({
      locationState: this.props.location,
    });
    window.addEventListener("scroll", this.handleScroll);
    if (localStorage.getItem("companyName")) {
      this.setState({
        accountName: localStorage.getItem("companyName"),
      });
    }
    if (this.props.location.state) {
      this.setState({
        accountNo: this.props.location.state.rowData.accountNo,
        createdBy: this.props.location.state.rowData.createdBy,
        accountName: this.props.location.state.rowData.accountantName,
        IFSCCode: this.props.location.state.rowData.ifscCode,
        bankName: this.props.location.state.rowData.bankName,
        MICRCode: this.props.location.state.rowData.micrCode,
        accountTypeValue: this.props.location.state.rowData.accountType,
        accountPurpose: this.props.location.state.rowData.accountPurpose,
        id: this.props.location.state.rowData.id,
        createdOn: this.props.location.state.rowData.createDateTime,
        branch: this.props.location.state.rowData.branch,
      });
      const dateEntered = this.props.location.state.rowData.createDateTime;
      const dateIsValid = moment(dateEntered).format("LLLL");
      this.setState({
        createdDate: dateIsValid,
      });
    }
  }

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.
    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.accountNo}
                      caps="OFF"
                      minValue="0"
                      onChange={this.onhandlechangeValue("accountNo")}
                      label="Account No"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.accountName}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("accountName")}
                      label="Account Holder Name"
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.IFSCCode}
                      caps="OFF"
                      minValue="0"
                      onChange={this.onhandlechangeValue("IFSCCode")}
                      label="IFSC Code"
                      disabled={this.state.accountNo === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.bankName}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("bankName")}
                      label="Bank Name"
                      disabled={this.state.IFSCCode === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.MICRCode}
                      caps="OFF"
                      minValue="0"
                      onChange={this.onhandlechangeValue("MICRCode")}
                      label="MICR Code"
                      disabled={this.state.IFSCCode === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={accountType}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.accountTypeValue}
                        update={this.state.accountTypeValue ? 1 : 0}
                        name="accountType"
                        label="Account Type"
                        isDisabled={this.state.IFSCCode === "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.accountPurpose}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("accountPurpose")}
                      label="Account Purpose"
                      disabled={this.state.IFSCCode === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.branch}
                      caps="OFF"
                      minValue="0"
                      onChange={this.onhandlechangeValue("branch")}
                      label="Branch"
                      disabled={this.state.IFSCCode === "" ? true : false}
                    />
                  </Box>

                  {this.state.locationSate.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdDate}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.state.locationSate.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
          {/* common popup for validation */}
          {this.state.confirm && (
            <CommonPopup>
              <div className="delAddItem">
                <div className="mandatoryFieldText">
                  Please select all the mandatory fields
                </div>
                <div className="delAddItemBtns">
                  <input
                    type="button"
                    onClick={this.onclickPopUP.bind(this)}
                    value="Ok"
                    className="delYesBtn"
                  />
                </div>
              </div>
            </CommonPopup>
          )}
        </div>
      </div>
    );
  }
}
const accountType = [
  {
    value: "null",
    label: "Select",
    name: "Select",
  },
  {
    value: "Savings",
    label: "Savings",
    name: "Savings",
  },
  {
    value: "Cash Credit",
    label: "Cash Credit",
    name: "Cash Credit",
  },
  {
    value: "Current",
    label: "Current",
    name: "Current",
  },
  {
    value: "Over Draft",
    label: "Over Draft",
    name: "Over Draft",
  },
  {
    value: "Term Loan",
    label: "Term Loan",
    name: "Term Loan",
  },
  {
    value: "Checking",
    label: "Checking",
    name: "Checking",
  },
  {
    value: "Fixed Diposit",
    label: "Fixed Diposit",
    name: "Fixed Diposit",
  },
];

const mapStatetoProps = (state) => {
  const { Create_Bank_Account, Update_Bank_Account } =
    state.BankAccountListReducer; // fetching create and update from bank account reducer
  return { Create_Bank_Account, Update_Bank_Account };
};
export default connect(mapStatetoProps, {
  createBankAccount,
  updateBankAccount,
})(withRouter(CreateBankAccounts));
