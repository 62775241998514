import React, { Component } from "react";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import {
  getLeaveRequestList,
  getWorkerDataClear,
} from "../../../store/HRMS/LeaveRequest/actions";

class LeaveRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      leaveRequestListData: [],
    };
  }
  componentDidMount() {
    let userid = this.state.userDit.userId.toString();
    const data = {
      companyId: this.state.companyId,
      leaveTypeId: "",
      status: "",
      userId: userid,
    };

    this.props.getLeaveRequestList(
      serverApi.LEAVE_REQUEST_LIST,
      data,
      this.props.history
    );
    this.props.getWorkerDataClear(null);
  }
  componentWillReceiveProps(props) {
    if (props.leaveRequestList) {
      this.setState({
        leaveRequestListData: props.leaveRequestList.data,
      });
    }
  }
  handleSearch = (data1) => {
    const data = {
      companyId: data1.companyId,
      ebNo: data1.ebNo,
      leaveTypeId: data1.leaveTypeId,
      status: data1.status,
      userId: data1.userId,
    };
    this.props.getLeaveRequestList(
      serverApi.LEAVE_REQUEST_LIST,
      data,
      this.props.history
    );
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.leaveRequestListData}
          mcolumns={leaveRequestListColumn}
          actions={true}
          micon={GridArrowIcon}
          url="/apply_leave"
          filter_form={"LeaveRequest"}
          handleSearch={this.handleSearch}
        />
      </>
    );
  }
}
const leaveRequestListColumn = [
  {
    Header: "Worker Name",
    accessor: "workerName",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "EB No",
    accessor: "ebNo",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "From Date",
    accessor: "leaveFromDate",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "To Date",
    accessor: "leaveToDate",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Leave Type",
    accessor: "leaveType",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Leave Ledger",
    accessor: "leaveLedgerId",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Status",
    accessor: "status",
    hidden: false,
    csvExport: true,
  },
];
const mapStatetoProps = (state) => {
  const { leaveRequestList } = state.LeaveRequestReducer;
  return { leaveRequestList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getLeaveRequestList,
    getWorkerDataClear,
  })(LeaveRequest)
);
