import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import DynamicSelect from "../../../components/Dropdown/Master/DynamicSelectMasters";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getSupplierListMultiselect,
  getStatusList,
} from "../../../store/Global/DropDownApis/actions";
import {
  getVehicleList,
  getAgentDropdownList,
} from "../../../store/MasterDropdownApis/actions";
import TextFieldNormal from "../../TextField/TextFieldNormal";

class ItemMasterFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      supplierListMultiselectData: [],
      VehicleListData: [],
      StatusListData: [],
      agentListDropdownData: [],
      itemCode: "",
      itemGrpCode: "",
      itemName: "",
      itemGrpName: "",
      statusValue: "",
    };
  }

  componentDidMount() {}
  componentWillReceiveProps(props) {}
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
  
    if(name === "Status"){
      if(selectedValue==null){
        this.setState({
          statusValue: null
      })
      }else{
        this.setState({
          statusValue: selectedValue
      })
      }
      
    }

  };

  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "itemGrpCode") {
      this.setState({
        itemGrpCode: event,
      });
    }
    if (key === "itemGrpName") {
      this.setState({
        itemGrpName: event,
      });
    }

    if (key === "itemCode") {
      this.setState({
        itemCode: event,
      });
    }

    if (key === "itemName") {
      this.setState({
        itemName: event,
      });
    }
  };
  handleSubmit = () => {
    var data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      groupCode: this.state.itemGrpCode === "" ? null : this.state.itemGrpCode,
      isActive: this.state.statusValue === "" ? null : this.state.statusValue,
      itemCode: this.state.itemCode === "" ? null : this.state.itemCode,
      itemGroupName: this.state.itemGrpName === "" ? null : this.state.itemGrpName,
      itemName: this.state.itemName === "" ? null : this.state.itemName,
      lastIndex: 10,
      startIndex: 1,
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleClear = () => {
    this.setState({
        groupCode: "",
        isActive: "",
        itemCode: "",
        itemGroupName: "",
        itemName: "",
    });
  };

  render() {
    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid spacing={0}>
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
                {/* <div>Save View</div> */}
              </div>
            </div>
            <div className="procurementFilter">
              <Grid
                item
                xs={12}
                className="filterBlockContainer juteFilterBlock"
              >
                <Box gridColumn="span 3">
                  <Grid container spacing={1}>
                  <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          value={this.state.itemCode}
                          placeholder={"Enter here"}
                          label="Item Code"
                          // caps="OFF"
                          onChange={this.onhandlechange("itemCode")}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          value={this.state.itemName}
                          placeholder={"Enter here"}
                          label="Item Description"
                          // caps="OFF"
                          onChange={this.onhandlechange("itemName")}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          value={this.state.itemGrpCode}
                          placeholder={"Enter here"}
                          label="Item Group Code"
                          // caps="OFF"
                          onChange={this.onhandlechange("itemGrpCode")}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          value={this.state.itemGrpName}
                          placeholder={"Enter here"}
                          label="Item Group Description"
                          // caps="OFF"
                          onChange={this.onhandlechange("itemGrpName")}
                        />
                      </div>
                    </Grid>
             

                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={statusListdata}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handleSelectChange}
                          selected={this.state.statusValue}
                          // update={this.state.statusValue ? 1 : 0}
                          name="Status"
                          label="Status"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Box>

                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Search"}
                    className="greenButton"
                    handleClick={this.handleSubmit}
                    type="sumbit"
                  />
                </div>
              </Grid>
            </div>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const statusListdata = [
  {
    value: null,
    label: "Select...",
    name: "Select...",
  },
  {
    value: "1",
    label: "Active",
    name: "Active",
  },
  {
    value: "0",
    label: "Inactive",
    name: "Inactive",
  },
];

const mapStatetoProps = (state) => {
  const { supplierListMultiselect, statusList } = state.DropDownListReducer;
  const { VehicleData, agentDropdownList } = state.MasterDropDownListReducer;
  return {
    supplierListMultiselect,
    VehicleData,
    statusList,
    agentDropdownList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSupplierListMultiselect,
    getVehicleList,
    getStatusList,
    getAgentDropdownList,
  })(ItemMasterFilter)
);
