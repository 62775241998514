import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "@mui/material";
import EditLogoIcon from "../../assets/images/picEditIcon.png";
import { postUploadFileList } from "../../store/MyProfile/UploadFile/actions";
import CommonPopup from "../CommonPopup/CommonPopup";
class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      ebId: localStorage.getItem("ebId"),
      file: "",
      imagePreviewUrl: this.props.imagePreviewUrl,
      fileUpload: "",
      imagePreview: "none",
      fileName: "",
      fileExtension: "",
      showPopup: false,
    };

    this.handleImageChange = this.handleImageChange.bind(this);
    this.SaveSubmit = this.SaveSubmit.bind(this);
    this.handleImageRemove = this.handleImageRemove.bind(this);
  }

  SaveSubmit(e) {
    e.preventDefault();
  }

  componentDidMount = () => {
    if (this.props.imagePreviewUrl) {
      this.setState({
        fileUpload: "none",
        imagePreview: "block",
        imagePreviewUrl: this.props.imagePreviewUrl,
      });
    }
  };

  handleImageChange(e) {
    if (this.state.ebId === null) {
      this.setState({
        showPopup: true,
      });
    } else {
      e.preventDefault();

      let reader = new FileReader();
      let file = e.target.files[0];
      let fileExt = file.name.split(".").pop();

      const formData = new FormData();
      formData.append("fileName", file.name);
      formData.append("fileUpload", file);
      formData.append("fileExtension", fileExt);
      formData.append("sourceMenu", 1);
      formData.append("displayName", file.name);
      formData.append("taskUniqueId", this.props.taskUniqueId);
      formData.append("createdBy", this.state.userDit.userId);
      formData.append("fileCompanyId", this.state.companyId);
      this.setState({
        fileName: file.name,
        fileExtension: file.name.split(".").pop(),
      });

      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
          fileUpload: "none",
          imagePreview: "block",
        });
      };
      reader.readAsDataURL(file);
    }
  }
  onclickOk = () => {
    this.setState({
      showPopup: false,
    });
  };
  handleImageRemove(e) {
    e.preventDefault();
    this.setState({
      fileUpload: "block",
      imagePreview: "none",
      file: "",
      imagePreviewUrl: "",
    });
  }
  render() {
    var imagePreviewUrl = this.state.imagePreviewUrl;
    if (
      !this.state.imagePreviewUrl ||
      this.state.imagePreviewUrl !== undefined ||
      this.state.imagePreviewUrl !== "undefined"
    ) {
      imagePreviewUrl = this.props.imagePreviewUrl;
    }
    return (
      <div className="uploadBlock">
        <div style={{ display: this.state.fileUpload }}>
          <Button component="label" className="uploadBtn">
            <div className="uploadInfo">
              <h1>+</h1>
              <h3>Upload Photo</h3>
              <p>(Format: jpeg, png)</p>
            </div>
            <input
              type="file"
              hidden
              onChange={this.handleImageChange}
              accept="image/jpeg, image/png"
            />
          </Button>
        </div>
        <div
          className="imagePreview"
          style={{
            display: this.state.imagePreview,
          }}
        >
          <img src={imagePreviewUrl} alt="" />
          <button onClick={this.handleImageRemove} className="editIconStyle">
            <img src={EditLogoIcon} alt="" />
          </button>
        </div>
        {this.state.showPopup && (
          <CommonPopup>
            <div className="delAddItem">
              <div>"Please Create the User"</div>
              <div className="delAddItemBtns">
                <input
                  type="button"
                  onClick={this.onclickOk.bind(this)}
                  value="Ok!"
                  className="delYesBtn"
                />
              </div>
            </div>
          </CommonPopup>
        )}
      </div>
    );
  }
}
const mapStatetoProps = (state) => {
  const { Upload_File_List } = state.UploadFileReducer;
  return { Upload_File_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    postUploadFileList,
  })(ImageUpload)
);
