import React, { Component } from "react";
import CustomButton from "../../../components/Buttons/Buttons";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi, getTenantId, serverVars } from "../../../helpers/Consts";
import {
  getIndentPdf,
  IndentLineItemsProps,
  getFiles,
} from "../../../store/Purchase/Indent/actions";
import { getFileDownload } from "../../../helpers/server.js";
import { Box, Grid } from "@mui/material";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import moment from "moment";
import { limitDecimals } from "../../../Utilities/helper.js";

class BOQPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      date: moment().format(),
      currentStep: 1,
      companyLogo: "",
      indentLineItems: [],
      indentStatusName: "",
      locationState: "",
      files_List: [],
    };
  }
  componentDidMount() {
    let companyData = JSON.parse(localStorage.getItem("authUser"));
    let companyId = localStorage.getItem("companyId");
    companyData.companyDetails.map((p) => {
      let id = p.value;
      if (companyId === id.toString()) {
        let companyName = p.label.split("-");
        this.setState({
          companyName: companyName[1],
          address1: p.address1,
          companyLogo: p.companyLogo,
          gstNo: p.gstNo,
          address2: p.address2,
          comName: p.name,
          phoneNo: p.contactNo,
          email: p.emailId,
        });
      }
    });
    if (
      this.props.indentLineItems &&
      this.props.indentLineItems !== undefined
    ) {
      this.setState({
        indentLineItems: this.props.indentLineItems,
        locationState: this.props.location,
      });
    } else {
      let IndentLineItems = [
        {
          id: 0,
          qty: "",
          uomCode: "",
          qohCompany: "",
          qohBranch: 0,
          qohProject: 0,
          rate: "",
          rateSource: 1,
          remarks: "",
          departmentId: " ",
          itemId: "",
          lastPurchaseDate: "",
          lastPurchaseRate: 0,
          qtyPo: "",
          amount: 0,
        },
      ];
      this.props.IndentLineItemsProps(IndentLineItems);
    }
    if (this.props.location.state) {
      let id = this.props.location.state.rowData.id;
      this.props.getFiles(
        serverApi.GET_FILES +
          "10/" +
          id +
          "/" +
          localStorage.getItem("companyId"),
        this.props.history
      );
    }
    if (this.props.RowData) {
      this.setState({
        indentStatusName: this.props.RowData.indentStatusName,
      });
    }
  }

  componentWillReceiveProps(props) {
    if (props.indentLineItems) {
      this.setState({
        indentLineItems: props.indentLineItems,
      });
    }
    if (props.files_List) {
      if (props.files_List.data) {
        let filesList = props.files_List.data;
        this.setState({
          files_List: filesList.data,
        });
      }
    }
  }
  handleEdit = () => {
    this.props.handleCurrentStep(this.state.currentStep);
  };
  handlePdf = () => {
    let indentId = this.props.RowData.id;

    fetch(serverApi.DOWNLOAD_PDF + indentId + "/" + this.state.userDit.userId, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        "X-TenantId": getTenantId(window.location.hostname),
        Authorization:
          localStorage.getItem("token_type") +
          localStorage.getItem("access_token"),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          this.props.RowData.indentSquenceNo + ".pdf"
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };
  getFileDownload = (fileUploadId, fileName, fileExtension) => {
    let url = serverVars.SECURITY_API + "files/downloadfile/" + fileUploadId;
    getFileDownload(url, fileName, fileExtension);
  };
  render() {
    var DateTime = this.state.date.split("T", 2);
    var TodayDate = moment(DateTime[0], "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var Time = DateTime[1].split("+")[0];
    var indentLineItems = this.state.indentLineItems.filter(
      (item) =>
        item.qty !== "" &&
        item.isActive !== 0 &&
        item.qty !== 0 &&
        item.budgetHeadId !== ""
    );

    var IndentLineItems = indentLineItems.sort((a, b) => {
      if (this.state.locationState.state !== undefined) {
        return b.id - b.id;
      } else {
        return a.id - b.id;
      }
    });

    return (
      <>
        <div className="previewBlock">
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              padding: "10px",
            }}
          >
            {this.state.locationState.state !== undefined &&
            this.state.indentStatusName !== "APPROVED" &&
            this.state.indentStatusName !== "REJECTED" &&
            this.state.indentStatusName !== "CLOSED" &&
            this.state.indentStatusName !== "CANCELLED" ? (
              <CustomButton
                label="Print"
                handleClick={this.handlePdf}
                type="button"
                className="lightBlueButton"
                muIcon={<PrintIcon />}
              />
            ) : (
              ""
            )}

            {this.state.locationState.state !== undefined &&
            this.state.indentStatusName !== "APPROVED" &&
            this.state.indentStatusName !== "REJECTED" &&
            this.state.indentStatusName !== "CLOSED" &&
            this.state.indentStatusName !== "CANCELLED" ? (
              <CustomButton
                label="Edit"
                handleClick={this.handleEdit}
                type="button"
                className="lightBlueButton"
                muIcon={<EditIcon />}
              />
            ) : (
              ""
            )}
          </div>
          <table
            cellpadding="0"
            cellSpacing="0"
            width={"90%"}
            className="previewTable"
          >
            <tbody>
              <tr>
                <td
                  colSpan="4"
                  style={{ borderRight: "none", borderBottom: "none" }}
                >
                  <img src={this.state.companyLogo} alt="" />
                </td>
                <td
                  colSpan="8"
                  style={{
                    borderBottom: "none",
                    borderLeft: "none",
                    textAlign: "right",
                  }}
                >
                  <p style={{ margin: "0px" }}>
                    {" "}
                    <b>{this.state.companyName}</b>
                  </p>
                  <br />
                  <span>{this.state.address1}</span>
                  <br />
                  <span>{this.state.address2}</span>
                  <br />
                  <span>
                    {" "}
                    <b>Ph.No:</b> {this.state.phoneNo}
                  </span>
                  <span>
                    <b> Email:</b> {this.state.email}
                  </span>
                  <br />
                  <span>
                    <b>GST No:</b> {this.state.gstNo}
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  colSpan="13"
                  style={{ textAlign: "center", borderTop: "none" }}
                >
                  <h2>
                    {this.props.location.pathname === "/create_bom"
                      ? "BILL OF MATERIAL"
                      : this.props.location.pathname === "/create_boq"
                      ? "BILL OF QUANTITY"
                      : "INDENT"}
                  </h2>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>
                    {this.props.location.pathname === "/create_bom"
                      ? "BOM No"
                      : "BOQ No"}
                  </b>
                </td>
                <td
                  colSpan="2"
                  className={
                    this.props.RowData.indentSquenceNo === "" ||
                    this.props.RowData.indentSquenceNo === undefined
                      ? "blur-text"
                      : ""
                  }
                >
                  {this.props.RowData.indentSquenceNo === "" ||
                  this.props.RowData.indentSquenceNo === undefined
                    ? "Will be generated after creation"
                    : this.props.RowData.indentSquenceNo}
                </td>
                <td colSpan="2">
                  <b>Indent Date : </b>
                </td>
                <td colSpan="6">
                  {this.props.RowData
                    ? this.props.RowData.indentDate
                    : moment(this.props.indentFillDetails.dateValue).format(
                        "DD-MM-YYYY"
                      )}
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Title</b>
                </td>
                <td colSpan="2">{this.props.indentFillDetails.title}</td>
                <td colSpan="2">
                  <b>Client</b>
                </td>
                <td colSpan="6">{this.props.indentFillDetails.client}</td>
              </tr>
              <tr>
                <td>
                  <b>S.No</b>
                </td>
                <td>
                  <b>Item Code</b>
                </td>
                <td>
                  <b>Budget Head</b>
                </td>
                <td>
                  <b>Item Group</b>
                </td>
                <td>
                  <b>Item Description</b>
                </td>
                <td>
                  <b>Quantity</b>
                </td>
                <td>
                  <b>Unit</b>
                </td>
                <td>
                  <b>Inst.Rate</b>
                </td>
                <td>
                  <b>Rate</b>
                </td>
                <td>
                  <b>Inst.Amount</b>
                </td>
                <td>
                  <b>Amount</b>
                </td>
                <td>
                  <b>Total</b>
                </td>
              </tr>

              {IndentLineItems &&
                IndentLineItems.map((data, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "right" }}>{index + 1}</td>
                    <td>
                      {data.itemGroupId}
                      {data.itemCode}
                    </td>
                    <td>
                      {this.state.locationState.state !== undefined ||
                      data.budgetHeadName !== ""
                        ? data.budgetHeadName
                        : this.props.indentFillDetails.budget_Head}
                    </td>
                    <td>
                      {this.state.locationState.state !== undefined ||
                      data.itemGroup !== ""
                        ? data.itemGroup
                        : this.props.indentFillDetails.itemGroup !== ""
                        ? this.props.indentFillDetails.itemGroup
                        : data.itemGroupValue}
                    </td>
                    <td>
                      {data.itemName}
                      <br />
                      Make: {data.make}
                      <br />
                      Remarks: {data.remarks}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {limitDecimals(data.qty, 3)}
                    </td>
                    <td style={{ textAlign: "right" }}>{data.uomCode}</td>
                    <td style={{ textAlign: "right" }}>
                      {limitDecimals(data.installationRate, 3)}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {limitDecimals(data.rate, 3)}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {limitDecimals(
                        Number(data.installationRate) * Number(data.qty),
                        3
                      )}
                    </td>
                    <td>
                      {limitDecimals(Number(data.qty) * Number(data.rate), 3)}
                    </td>
                    <td>
                      {limitDecimals(
                        Number(data.qty) * Number(data.rate) +
                          Number(data.installationRate) * Number(data.qty),
                        2
                      )}
                    </td>
                  </tr>
                ))}
              <tr>
                <td
                  colspan="13"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    marginTop: "20px",
                  }}
                >
                  <div>Status: {this.props.indentStatusName}</div>
                  <div>Make: {this.props.indentFillDetails.remarks}</div>
                  <div>
                    Internal Note:{" "}
                    {this.props.indentFillDetails.internalRemarks}
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  colspan="6"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {this.state.locationState.state && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box gridColumn="span 12">
                          {this.state.files_List.length !== 0 && (
                            <span>
                              <b>Uploaded Documents</b>
                            </span>
                          )}
                        </Box>
                        <Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={2}
                          className="ducumentUploadBlock uploadedContent"
                          style={{ padding: "10px" }}
                        >
                          {this.state.files_List &&
                            this.state.files_List.map((item) => (
                              <Box gridColumn="span 6">
                                <Tooltip
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 600 }}
                                  title="Click to download File"
                                >
                                  <div
                                    className="documentUploadContent"
                                    onClick={() => {
                                      this.getFileDownload(
                                        item.fileUploadId,
                                        item.fileName,
                                        item.fileExtension
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div
                                      className="uploadedInfo"
                                      style={{ display: "flex" }}
                                    >
                                      <img
                                        src={TickIcon}
                                        alt=""
                                        className="tickIcon"
                                        height={"20px"}
                                        width={"20px"}
                                      />
                                      <div className="uplodedFileName">
                                        {item.fileName}
                                      </div>
                                    </div>
                                  </div>
                                </Tooltip>
                              </Box>
                            ))}
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { indentLineItems, files_List } = state.IndentReducer;
  return {
    indentLineItems,
    files_List,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getIndentPdf,
    getFileDownload,
    IndentLineItemsProps,
    getFiles,
  })(BOQPreview)
);
