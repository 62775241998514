import React, { Component } from "react";
import { Grid, Box } from '@mui/material';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

export default class EmployeeCategory extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        return (
            <div className="employeeCategorySection">
                <h3>Employee Categories</h3>
                <Grid spacing={2}>
                    <Grid>
                        <Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={2}
                            className="employeeDashboard"
                        >
                            <Box gridColumn="span 6">
                                <Grid spacing={2}>
                                    <Grid>
                                        <Box
                                            display="grid"
                                            gridTemplateColumns="repeat(12, 1fr)"
                                            gap={2}
                                            className="employeeDashboard"
                                        >
                                            <Box gridColumn="span 12">
                                                <Card className="dashBoardTotalEmployee">
                                                    <CardContent>
                                                        <span>528</span>
                                                        <p>Total Employees</p>
                                                    </CardContent>
                                                </Card>
                                            </Box>
                                            <Box gridColumn="span 6">
                                                <Card className="employeeWithESI">
                                                    <CardContent>
                                                        <span>157</span>
                                                        <p>With ESI</p>
                                                    </CardContent>
                                                </Card>
                                            </Box>
                                            <Box gridColumn="span 6">
                                                <Card className="employeeWithESI">
                                                    <CardContent>
                                                        <span>90</span>
                                                        <p>With PF</p>
                                                    </CardContent>
                                                </Card>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box gridColumn="span 6">
                                <Grid spacing={2}>
                                    <Grid>
                                        <Box
                                            display="grid"
                                            gridTemplateColumns="repeat(12, 1fr)"
                                            gap={2}
                                            className="employeeDashboard"
                                        >
                                            <Box gridColumn="span 6">
                                                <Card className="employeeWithOnrollandContract">
                                                    <CardContent>
                                                        <span>474</span>
                                                        <p>On-roll</p>
                                                    </CardContent>
                                                </Card>
                                            </Box>
                                            <Box gridColumn="span 6">
                                                <Card className="employeeWithOnrollandContract">
                                                    <CardContent>
                                                        <span>54</span>
                                                        <p>Contract</p>
                                                    </CardContent>
                                                </Card>
                                            </Box>
                                            <Box gridColumn="span 6">
                                                <Card className="employeeWithESI">
                                                    <CardContent>
                                                        <span>20</span>
                                                        <p>Without Bank Account</p>
                                                    </CardContent>
                                                </Card>
                                            </Box>
                                            <Box gridColumn="span 6">
                                                <Card className="users">
                                                    <CardContent>
                                                        <span>80</span>
                                                        <p>Users</p>
                                                    </CardContent>
                                                </Card>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>

                            </Box>
                        </Box>
                    </Grid>
                </Grid>

            </div>
        )
    }
}