import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { serverApi, serverConfig } from "../../../helpers/Consts";
import { Grid, Box, Tooltip } from "@mui/material";
import Fade from "@mui/material/Fade";

import DeleteIcons from "../../../assets/images/picEditIcon.png";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import { getFileDownload } from "../../../helpers/server";
import {
  getMrLineItems,
  juteMrHeaderAction,
  UploadJuteMrFiles,
} from "../../../store/Jute/JuteMaterialReceipt/actions";
import DynamicSelect from "../../../components/Dropdown/Jute/JuteDynamicSelect";
import { deleteFile } from "../../../store/MyProfile/UploadFile/actions";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import TextFieldAlfaNum from "../../../components/TextField/TextFieldAlfaNum";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { getFiles } from "../../../store/Purchase/Indent/actions";
import InputAdornment from "../../../components/InputAdorment/InputAdorment";
import {
  getMukamByItemList,
  getQualityByItemList,
} from "../../../store/MasterDropdownApis/actions";
import { Col } from "react-bootstrap";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Button from "../../../components/Buttons/Buttons";
import SpeedIcon from "@mui/icons-material/Speed";
import { getwareHouseDataList } from "../../../store/StoreModule/StockTransfer/actions";
import moment from "moment";
import { TCSPercentages } from "../../../helpers/TableData";
import swal from "sweetalert";

const JuteMaterialReceiptAddItems = (props) => {
  let dispatch = useDispatch();
  const initialState = {
    rowcount: 1,
    show: false,
    checkAll: false,
    qty: "",
    uomCode: "",
    poLineItem: props.poLineItem,
    userDit: JSON.parse(localStorage.getItem("authUser")),
    companyId: localStorage.getItem("companyId"),
    ItemGroupMasterList: [],
    itemsList: [],
    mukamList: [],
    cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
    total: 0,
    tcsAmount: 0,
    tcsPercentage: 0,
    totalAmount: 0,
    variableShortageEnabled: true,
    uploadFile: false,
    files_List: [],
    filterText: "",
    tooltip: "item description....",
  };

  const [state, setState] = useState(initialState);
  const [juteMrHeaders, setJuteMrHeaderData] = useState({});
  const [juteMrlineItems, setJuteMrLineItems] = useState([]);
  const [mukamList, setMukamList] = useState([]);
  const [QualityByItemIdList, setQualityByItemIdList] = useState([]);
  const [wareHouseListData, setWareHouseListData] = useState([]);
  const [mrDocsListData, setMrDocsList] = useState([]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const {
    juteMrHeaderData,
    juteMrLineItems,
    quantityByItemIdData,
    mrFilesList,
  } = useSelector((state) => state.JuteMaterialReceiptReducer);

  useEffect(() => {
    if (mrFilesList) {
      if (mrFilesList.data) {
        setMrDocsList(mrFilesList.data);
      }
    }
  }, [mrFilesList]);
  const { wareHouseList } = useSelector((state) => state.StockTransfer_List);
  const { MukamByItemList } = useSelector(
    (state) => state.MasterDropDownListReducer
  );
  useEffect(() => {
    setJuteMrHeaderData(juteMrHeaderData);
  }, [juteMrHeaderData]);

  useEffect(() => {
    setJuteMrLineItems(juteMrLineItems);
  }, [juteMrLineItems]);

  useEffect(() => {
    const quantityByItemIdDataList = quantityByItemIdData?.data?.filter(
      (item) => {
        return item.value !== 0;
      }
    );
    setQualityByItemIdList(quantityByItemIdDataList);
  }, [quantityByItemIdData]);

  useEffect(() => {
    const wareHouseListData = wareHouseList.filter((item) => {
      return item.value !== 0;
    });
    setWareHouseListData(wareHouseListData);
  }, [wareHouseList]);

  useEffect(() => {
    //   props.getFiles(
    //     `${serverApi.GET_FILES}26/11652/${localStorage.getItem("companyId")}`,
    //     props.history
    //   );

    dispatch(
      getMukamByItemList(
        `${serverApi.ITEM_LIST_BY_MUKAM}${
          juteMrHeaderData.mukamId
        }/getAllItemByMukam/${localStorage.getItem("companyId")}/${
          state.cipher
        }`,
        props.history
      )
    );
    const data = {
      branchId: "",
      companyId: localStorage.getItem("companyId"),
      type: "J",
    };
    dispatch(
      getwareHouseDataList(serverApi.WARE_HOUSE_LIST, data, props.history)
    );
  }, []);

  useEffect(() => {
    if (MukamByItemList) {
      if (MukamByItemList.data) {
        const list = MukamByItemList.data.filter((item) => item.value !== 0);
        setMukamList(list);
      }
    }
  }, [MukamByItemList]);

  useEffect(() => {
    //   if (props.wareHouseList) {
    //     const list = props.wareHouseList.filter((item) => item.value !== 0);
    //     setState((prevState) => ({ ...prevState, wareHouseList: list }));
    //   }
    //   if (props.files_List.data) {
    //     const filesList = props.files_List.data;
    //     setState((prevState) => ({ ...prevState, files_List: filesList }));
    //   }
    //   if (state.uploadFile && state.fileName !== "") {
    //     if (props.Upload_File_List.data && props.Upload_File_List.status) {
    //       setState((prevState) => ({
    //         ...prevState,
    //         uploadFile: false,
    //         files_List: [
    //           ...prevState.files_List,
    //           {
    //             fileUploadId: props.Upload_File_List.data.id,
    //             fileName: state.fileName,
    //             fileExtension: state.fileType,
    //             retrievalPath: props.Upload_File_List.data.fileUrl,
    //             sourceMenu: 26,
    //           },
    //         ],
    //       }));
    //     }
    //   }
  }, []);

  const handleScroll = () => {
    setState((prevState) => ({ ...prevState, isDropdownOpen: true }));
  };

  const UploadDocuments = (key) => async (e) => {
    e.preventDefault();
    // await getFiles(
    //   `${serverApi.GET_FILES}26/11652/${localStorage.getItem("companyId")}`,
    //   props.history
    // );

    const file = e.target.files[0];
    const fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 26);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", 11652);
    formData.append("createdBy", state.userDit.userId);
    formData.append("fileCompanyId", localStorage.getItem("companyId"));

    setState((prevState) => ({
      ...prevState,
      fileName: file.name,
      fileType: fileExt,
      uploadFile: true,
    }));

    dispatch(UploadJuteMrFiles(serverApi.UPLOADFILE, formData, juteMrHeaders.id));
    // await props.getFiles(
    //   `${serverApi.GET_FILES}26/11652/${localStorage.getItem("companyId")}`,
    //   props.history
    // );
  };

  const onhandlechangeOutValue = (key) => (event) => {
    const value = event.target.value;
    if (value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
      setState((prevState) => ({ ...prevState, [key]: value }));
      props.headerData[key] = value;
    } else {
      swal("Please enter a positive number with up to 2 decimal places.");
    }
  };

  const handleOutPropSelectChange = (selectedValue, selectedName, name) => {
    let totalAmount = juteMrlineItems
      .map((p) => p.totalPrice)
      .reduce((total, ind) => Number(total) + Number(ind));
    let tcsAmount =
      Math.round(Number(totalAmount) * Number(selectedValue)) / 100;
    setJuteMrHeaderData((prev) => ({
      ...prev,
      tcsPercentage: selectedValue,
      tcsAmount: tcsAmount,
      totalAmount: totalAmount,
    }));

    const UpdatedRec = {
      ...juteMrHeaders,
      tcsPercentage: selectedValue,
      tcsAmount: tcsAmount,
      totalAmount: totalAmount,
    };
    dispatch(juteMrHeaderAction(UpdatedRec));
  };

  const handlePropSelectChange = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    lineItemId
  ) => {
    var lastRow = juteMrlineItems[juteMrlineItems.length - 1];
    if (name === "itemId") {
      let filteredLineItems = juteMrlineItems.filter(
        (item) => item.isActive !== 0
      );
      if (selectedRow.itemId !== selectedValue) {
        var check = !!filteredLineItems.find(
          ({ itemId }) => Number(itemId) === Number(selectedValue)
        );
      }

      if (check) {
        swal("You can't create the record with the duplicate quality");
        let LineItemdata = juteMrlineItems.filter((row) => {
          if (row.id === selectedRow.id) {
            row.itemId = "";
            row.variableShortage = 0;
            row.availableStock = 0;
          }
          return row;
        });
        setJuteMrLineItems(LineItemdata);
      } else {
        const updatedItems = juteMrlineItems.map((row) => {
          if (row.lineItemId === lineItemId) {
            row.itemId = selectedValue;
            row.variableShortage =
              juteMrHeaders.unitConversion === "LOOSE"
                ? 110
                : juteMrHeaders.unitConversion === "JUMBO"
                ? 1.1
                : 1;
            row.availableStock = row.stock;
            row.actualQuality = "";
          }
          return row;
        });
        setJuteMrLineItems(updatedItems);
        if (selectedRow === lastRow) {
          let juteMrLineItemsData = juteMrlineItems;
          let newObj = {
            lineItemId: juteMrLineItemsData.length + 1,
            materialGrnHdrId: 0,
            itemId: "",
            quantity: 0,
            quantityUnit: "",
            advisedQuality: 0,
            actualQuality: 0,
            advisedWeight: 0,
            actualWeight: 0,
            deviation: 0,
            rate: 0,
            claimsCondition: "0.0",
            status: "",
            warehouseNo: 0,
            remarks: null,
            totalPrice: 0,
            totalPriceWithTax: null,
            debitNotesFlag: null,
            bale: null,
            loose: 0,
            actualBale: null,
            actualLoose: 0,
            unitConversion: null,
            advisedItemId: "",
            wareHouseName: null,
            autoDateTimeInsert: "",
            createdBy: "",
            isActive: 1,
            advisedQuantity: 1000,
            shortageKgs: 0,
            claimDust: 0,
            claimQuality: 0,
            allowableMoisture: 0,
            premiumAmount: null,
            waterDamageAmount: null,
            juteGateEntryLineItemNo: 0,
            variableShortage: 0,
            acceptedWeight: 0,
            agentMRRate: null,
            stockQuantityConsumed: 0,
            drumConsumed: 0,
            balesConsumed: 0,
            companyId: 1,
            srcLineId: null,
            srcCompId: null,
            cropYear: null,
            marka: null,
            pota: null,
            salesBale: 0,
            salesDrum: 0,
            salesWeight: 0,
            juteIssueRate: 0,
            claimRate: 0,
            srcMrNo: null,
            srcMrLineId: null,
            convertedQuality: null,
            convertedJuteType: null,
            percentage: null,
            srcMrPrintNo: null,
            convertedBale: 0,
            convertedDrum: 0,
            convertedWeight: 0,
            jcStock: 0,
            itemDesc: "",
            qualityDesc: "",
            conItemDesc: null,
            conQltyDesc: null,
            VariableShortage: 0,
          };
          juteMrLineItemsData.push(newObj);
          setJuteMrLineItems(juteMrLineItemsData);
        }
      }
    }
    if (name === "warehouseNo") {
      const updatedItems = juteMrlineItems.map((row) => {
        if (row.lineItemId === lineItemId) {
          row.warehouseNo = selectedValue;
        }
        return row;
      });
      setJuteMrLineItems(updatedItems);
    }
    if (name === "stock") {
      const updatedItems = juteMrlineItems.map((row) => {
        if (row.lineItemId === lineItemId) {
          row.stock = selectedValue;
        }
        return row;
      });
      setJuteMrLineItems(updatedItems);
    }
    if (name === "qualityCode") {
      const updatedItems = juteMrlineItems.map((row) => {
        if (row.lineItemId === lineItemId) {
          row.qualityCode = selectedValue;
        }
        return row;
      });
      setJuteMrLineItems(updatedItems);
    }
    if (name === "uomCode") {
      const updatedItems = juteMrlineItems.map((row) => {
        if (row.lineItemId === lineItemId) {
          row.uomCode = selectedValue;
        }
        return row;
      });
      setJuteMrLineItems(updatedItems);
    }
    if (name === "actualQuality") {
      const updatedItems = juteMrlineItems.map((row) => {
        if (row.lineItemId === lineItemId) {
          row.actualQuality = selectedValue;
        }
        return row;
      });
      setJuteMrLineItems(updatedItems);
    }

    const total = juteMrlineItems.reduce((sum, item) => {
      return sum + Number(item.totalAmount);
    }, 0);

    setState((prevState) => ({
      ...prevState,
      total,
    }));
    dispatch(getMrLineItems(juteMrlineItems));
  };

  const handleChange = (key, value, lineItemId) => {
    let LineItemsRec = juteMrlineItems.filter((row) => {
      if (row.lineItemId === lineItemId) {
        if (key === "quantity") {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            row.quantity = value;
            row.totalPrice = getAmount(
              Number(row.rate) * Number(value),
              row.premiumAmount,
              row.waterDamageAmount
            );
            if (juteMrHeaders.unitConversion === "BALE") {
              let calMillWeight = parseFloat(
                (parseFloat(value) * 150) / 100
              ).toFixed(2);

              if (row.rate !== "") {
                var calAmount = parseFloat(
                  parseFloat(row.rate) * calMillWeight
                ).toFixed(2);
                row.actualWeight = calMillWeight;
                row.totalPrice = getAmount(
                  calAmount,
                  row.premiumAmount,
                  row.waterDamageAmount
                );
              }
            } else {
              var calMillWeight =
                (parseFloat(row.rate) * parseFloat(value)) / 100;
              row.actualWeight = calMillWeight;
            }
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
          if (juteMrHeaders.unitConversion === "LOOSE") {
            row.actualLoose = value;
          }
          if (juteMrHeaders.unitConversion === "BALE") {
            row.actualBale = value;
          }
        }
        if (key === "advisedWeight") {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            row.advisedWeight = value;
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        if (key === "shortageKgs") {
          if (value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
            row.shortageKgs = value;
          } else {
            swal("Please enter a positive number with up to 2 decimal places.");
          }
          row.acceptedWeight = getApprovedWeight(
            value,
            row.variableShortage,
            value
          );
        }
        if (key === "actualWeight") {
          const weight = calculateActualWeight(lineItemId, value);
          if (Number(weight) > Number(juteMrHeaders.juteGateNetWeight)) {
            row.actualWeight = 0;
            swal(
              "Sum of weight should not exceed " +
                juteMrHeaders.juteGateNetWeight +
                "...!!",
              { icon: "error" }
            );
          } else {
            if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
              row.actualWeight = value;
            } else {
              swal(
                "Please enter a positive number with up to 3 decimal places."
              );
            }
            row.totalPrice = Math.round(Number(value) * Number(row.rate));
            row.shortageKgs = getShortageWeight(
              value,
              row.claimsCondition,
              row.allowableMoisture,
              row.claimDust
            );
            row.acceptedWeight = getApprovedWeight(
              value,
              row.variableShortage,
              row.shortageKgs
            );
          }
        }
        if (key === "claimsCondition") {
          if (value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
            if (value > 100) {
              swal("Moisture Percentage can not be more than 100");
              row.claimsCondition = "0.00";
            } else {
              row.claimsCondition = value;
            }
          } else {
            swal("Please enter a positive number with up to 2 decimal places.");
          }
          row.shortageKgs = getShortageWeight(
            row.actualWeight,
            value,
            row.allowableMoisture,
            row.claimDust
          );
        }
        if (key === "allowableMoisture") {
          if (value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
            if (value > 100) {
              swal("Moisture Percentage can not be more than 100");
              row.allowableMoisture = "0.00";
            } else {
              row.allowableMoisture = value;
            }
          } else {
            swal("Please enter a positive number with up to 2 decimal places.");
          }
          row.shortageKgs = getShortageWeight(
            row.actualWeight,
            row.claimsCondition,
            value,
            row.claimDust
          );
        }

        if (key === "claimDust") {
          if (value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
            if (value > 100) {
              swal("Moisture Percentage can not be more than 100");
              row.claimDust = "0.00";
            } else {
              row.claimDust = value;
            }
          } else {
            swal("Please enter a positive number with up to 2 decimal places.");
          }
          row.shortageKgs = getShortageWeight(
            row.actualWeight,
            row.claimsCondition,
            row.allowableMoisture,
            value
          );
        }

        if (key === "acceptedWeight") {
          row.acceptedWeight = value;
        }
        if (key === "variableShortage") {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            row.variableShortage = value;
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
          row.acceptedWeight = getApprovedWeight(
            row.actualWeight,
            value,
            row.shortageKgs
          );
        }
      }
      return row;
    });
    setJuteMrLineItems(LineItemsRec);
  };
  const calculateActualWeight = (lineItemId, value) => {
    var actualWeight = 0;
    juteMrlineItems.filter((row) => {
      if (row.lineItemId === lineItemId) {
        actualWeight = Number(actualWeight) + Number(value);
      } else {
        actualWeight = Number(actualWeight) + Number(row.actualWeight);
      }
    });
    return actualWeight;
  };
  const getShortageWeight = (
    actualWeight,
    qcMoisture,
    allowableMoisture,
    claimDust
  ) => {
    let Moisture = Number(qcMoisture) - Number(allowableMoisture);
    if (Moisture > 0 && actualWeight !== "") {
      Moisture = Moisture + Number(claimDust);
      return Math.round(Number(Moisture) * Number(actualWeight));
    } else if (Number(claimDust) > 0 && actualWeight !== "") {
      return Math.round(Number(claimDust) * Number(actualWeight));
    } else return 0;
  };

  const OnhandleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    let name = key.split("_");
    let LineItemsRec = juteMrlineItems.filter((rowItem) => {
      if (row.lineItemId === rowItem.lineItemId) {
        if (name[0] === "rate") {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            rowItem.rate = value;
            rowItem.totalPrice = getAmount(
              Number(value) * Number(rowItem.rate),
              rowItem.premiumAmount,
              rowItem.waterDamageAmount
            );
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        if (name[0] === "premiumAmount") {
          if (value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
            row.premiumAmount = value;
          } else {
            swal("Please enter a positive number with up to 2 decimal places.");
          }
        }
        if (name[0] === "waterDamageAmount") {
          if (value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
            row.waterDamageAmount = value;
          } else {
            swal("Please enter a positive number with up to 2 decimal places.");
          }
        }
        if (name[0] === "claimQuality") {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            row.claimQuality = value;
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        if (name[0] === "claimRate") {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            row.claimRate = value;
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
      }
      return rowItem;
    });
    setJuteMrLineItems(LineItemsRec);
  };

  const handleMenuOpen = () => {
    setState({
      isDropdownOpen: true,
    });
  };
  const onChecked = (data) => (event) => {
    const { key, row } = data;
    const { checked } = event.target;
    let LineItemsRec = juteMrlineItems.filter((rowItem) => {
      if (row.lineItemId === rowItem.lineItemId) {
        if (event.target.checked) {
          if (juteMrHeaderData.unitConversion === "LOOSE") {
            row.variableShortage = 110;
          }
          if (juteMrHeaderData.unitConversion === "BALE") {
            if (row.quantity > 0) {
              row.variableShortage = parseFloat(row.quantity) * 1;
            }
          }
        } else {
          row.variableShortage = 0;
          row.approvedWeight = 0;
        }
      }
      return rowItem;
    });
    setJuteMrLineItems(LineItemsRec);
    setState(!state.variableShortageEnabled);
  };

  const getApprovedWeight = (actualWeight, variableShortage, shortageKgs) => {
    var actualWeightKg = Number(actualWeight * 100),
      variableShortageWeight = variableShortage,
      shortageWeight = shortageKgs;
    if (
      actualWeightKg > 0 &&
      variableShortageWeight !== "" &&
      shortageWeight !== ""
    )
      return Math.round(
        Number(actualWeightKg) -
          Number(variableShortageWeight) -
          Number(shortageWeight),
        2
      );
    else return 0;
  };
  // function to calculate premium amount and waterDamage Amount with total amount
  const getAmount = (amount, premiumAmount, waterDamageAmount) => {
    var totalAmount = 0;
    if (amount && Number(amount) > 0)
      totalAmount = Number(totalAmount) + Number(amount);

    // premium amount will be added with total price
    if (premiumAmount && Number(premiumAmount) > 0)
      totalAmount = Number(totalAmount) + Number(premiumAmount);

    // water damage amount will be substracted with total amount
    if (waterDamageAmount && Number(waterDamageAmount) > 0)
      totalAmount = Number(totalAmount) - Number(waterDamageAmount);

    //returning the value
    return totalAmount;
  };
  return (
    <div className="editableTableContainer">
      <div className="gridContainer">
        <div className="gridComponent">
          <table cellSpacing="0px" cellPadding="0px">
            <thead>
              <tr className="tableRowBlock">
                {tableHeaders.map((column, index) => (
                  <>
                    {column.type === 1 ||
                    column.type === 2 ||
                    column.type === 4 ||
                    column.type === 5 ||
                    column.type === 6 ||
                    column.type === 7 ||
                    column.type === 8 ||
                    column.type === 10 ||
                    column.type === 11 ||
                    column.type === 13 ||
                    column.type === 14 ||
                    column.type === 17 ||
                    column.type === 18 ||
                    column.type === 19 ? (
                      <th key={index}>
                        {column.header}
                        <span style={{ color: "red" }}>*</span>
                      </th>
                    ) : (
                      <th key={index}>{column.header}</th>
                    )}
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {juteMrlineItems ? (
                juteMrlineItems.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <>
                      {row
                        ? RowData.map((item) =>
                            item.component === "DynamicSelect" &&
                            item.name === "itemId" ? (
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <DynamicSelect
                                        arrayOfData={mukamList}
                                        className="dropdownBlockContainer"
                                        onSelectChange={handlePropSelectChange}
                                        isDropdownOpen={state.isDropdownOpen}
                                        handleMenuOpen={handleMenuOpen}
                                        selected={Number(row.itemId)}
                                        name="itemId"
                                        lineItemId={row.lineItemId}
                                        row={row}
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            ) : item.component === "DynamicSelect" &&
                              item.name === "Quality" ? (
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <DynamicSelect
                                        arrayOfData={QualityByItemIdList}
                                        className="dropdownBlockContainer"
                                        onSelectChange={handlePropSelectChange}
                                        isDropdownOpen={state.isDropdownOpen}
                                        handleMenuOpen={handleMenuOpen}
                                        selected={Number(row.actualQuality)}
                                        name="actualQuality"
                                        lineItemId={row.lineItemId}
                                        row={row}
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            ) : item.component === "DynamicSelect" &&
                              item.name === "warehouseNo" ? (
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <DynamicSelect
                                        arrayOfData={wareHouseListData}
                                        className="dropdownBlockContainer"
                                        onSelectChange={handlePropSelectChange}
                                        isDropdownOpen={state.isDropdownOpen}
                                        handleMenuOpen={handleMenuOpen}
                                        selected={Number(row.warehouseNo)}
                                        name="warehouseNo"
                                        lineItemId={row.lineItemId}
                                        row={row}
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            ) : item.component === "DynamicSelect" &&
                              item.name === "cropYear" ? (
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <DynamicSelect
                                        arrayOfData={yearOptions}
                                        className="dropdownBlockContainer"
                                        onSelectChange={handlePropSelectChange}
                                        isDropdownOpen={state.isDropdownOpen}
                                        handleMenuOpen={handleMenuOpen}
                                        selected={
                                          Number(row.cropYear)
                                            ? Number(row.cropYear)
                                            : moment().year()
                                        }
                                        name="cropYear"
                                        lineItemId={row.lineItemId}
                                        row={row}
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            ) : item.component === "Inputadorment" &&
                              item.name !== "amount" ? (
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        type="text"
                                        name={item.name}
                                        value={row[item.name]}
                                        disabled={item.disabled}
                                        onChange={OnhandleChange({
                                          key: item.name + "_" + rowIndex,
                                          row,
                                        })}
                                        className="inputBlockContainer"
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            ) : item.component === "Inputadorment" &&
                              item.name === "amount" ? (
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        type="text"
                                        name={item.name}
                                        value={getAmount(
                                          row.totalPrice,
                                          row.premiumAmount,
                                          row.waterDamageAmount
                                        )}
                                        className="inputBlockContainer"
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            ) : item.component === "InputAdoment" &&
                              item.type === "acceptedWeight" ? (
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <InputAdornment
                                        label={
                                          item.type === "quantity"
                                            ? juteMrHeaders.unitConversion ===
                                              "LOOSE"
                                              ? "DRUMS"
                                              : "BALE"
                                            : item.label
                                        }
                                        type="text"
                                        isNumber={true}
                                        name={item.name}
                                        lineItemId={row.lineItemId}
                                        handleChange={handleChange}
                                        unitConv={state.unitConversionId}
                                        value={getApprovedWeight(
                                          row.actualWeight,
                                          row.variableShortage,
                                          row.shortageKgs
                                        )}
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            ) : item.component === "InputAdoment" ? (
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {item.name === "variableShortage" && (
                                        <input
                                          type="checkbox"
                                          value={state.variableShortageEnabled}
                                          checked={
                                            state.variableShortageEnabled
                                          }
                                          name={"variableShortage"}
                                          onChange={onChecked({
                                            key: item.type + "_" + rowIndex,
                                            row,
                                          })}
                                        />
                                      )}
                                      <InputAdornment
                                        label={
                                          item.type === "quantity"
                                            ? juteMrHeaders.unitConversion ===
                                              "LOOSE"
                                              ? "DRUMS"
                                              : "BALE"
                                            : item.label
                                        }
                                        type="text"
                                        isNumber={true}
                                        name={item.name}
                                        lineItemId={row.lineItemId}
                                        handleChange={handleChange}
                                        unitConv={state.unitConversionId}
                                        value={row[item.name]}
                                        //here actualWeight is mill weight
                                        //here acceptedWeight is Approved Weight
                                        //here QC Moisture is claims Condition
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            ) : (
                              ""
                            )
                          )
                        : ""}
                    </>
                    <td className="">
                      <input
                        type="button"
                        onClick={() => {}}
                        value="X"
                        name={row ? row.lineItemId : ""}
                        className="deleteDisplayButton"
                      />
                      {state.delete && (
                        <CommonPopup>
                          <div className="delAddItem">
                            <div>Are you sure you want to delete?</div>
                            <div className="delAddItemBtns">
                              <input
                                type="button"
                                onClick={this.onDel}
                                value="Yes"
                                name={row ? row.lineItemId : ""}
                                className="delYesBtn"
                              />
                              <input
                                type="button"
                                onClick={this.onDel}
                                value="No"
                                name={row ? row.lineItemId : ""}
                                className="delNoBtn"
                              />
                            </div>
                          </div>
                        </CommonPopup>
                      )}
                      <span>&nbsp;</span>
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="consoleFormContainer">
        <div className="consoleFormBlock">
          <table cellSpacing="0px" cellPadding="0px" width="100%">
            <tr>
              <td style={{ width: "30%", padding: "10px" }}>
                <Box gridColumn="span 5">
                  <div className="personalDetailsUpload">
                    <div className="ducumentUploadBlock">
                      <div className="documentUploadContent">
                        <label>Support Documents:</label>
                        <span className="btn_upload documentUpload">
                          <img src={panUploadIcon} alt="" />
                          <input
                            type="file"
                            id="imag"
                            title=""
                            className="input-img"
                            onChange={UploadDocuments()}
                          />
                          Upload Document
                        </span>
                      </div>
                    </div>
                  </div>
                </Box>
              </td>
              <td style={{ width: "20%", padding: "10px" }}>&nbsp;</td>
              <td style={{ width: "50%", padding: "10px" }}>
                <table cellSpacing="0px" cellPadding="0px" width="100%">
                  <tr>
                    <td>TCS Percentage : </td>
                    <td style={{ padding: "10px" }}>
                      <DynamicSelect
                        arrayOfData={TCSPercentages}
                        className="dropdownBlockContainer"
                        onSelectChange={handleOutPropSelectChange}
                        isDropdownOpen={state.isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={juteMrHeaders.tcsPercentage}
                        name="tcsPercentage"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>TCS Amount : </td>
                    <td style={{ padding: "5px" }}>
                      <input
                        type="text"
                        name="tcsAmount"
                        value={juteMrHeaders.tcsAmount}
                        onChange={onhandlechangeOutValue("tcsAmount")}
                        className="inputBlockContainer"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Freight Charges ( If Any ) : </td>
                    <td style={{ padding: "5px" }}>
                      <input
                        type="text"
                        name="frieghtPaid"
                        value={juteMrHeaders.frieghtPaid}
                        onChange={onhandlechangeOutValue("frieghtPaid")}
                        className="inputBlockContainer"
                      />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </div>
      {/* {props.locationState.state !== undefined && (
        <div className="consoleFormContainer">
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock" style={{ display: "flex" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  className="ducumentUploadBlock"
                  gap={2}
                >
                  <Box gridColumn="span 8">
                    {mrDocsListData &&
                      mrDocsListData.map((item) => (
                        <>
                          <div>
                            <div
                              className="uploadedInfo"
                              style={{ display: "flex", marginBottom: "3px" }}
                            >
                              <img
                                src={TickIcon}
                                alt=""
                                className="tickIcon"
                                height={"20px"}
                                width={"20px"}
                              />
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title="Click to download File"
                              >
                                <div
                                  onClick={() => {
                                    getFileDownload(
                                      serverConfig.SERVER_URL +
                                        "security-api/api/files/downloadfile/" +
                                        item.fileUploadId,
                                      item.fileName,
                                      item.fileExtension
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {item.fileName}
                                </div>
                              </Tooltip>
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 100 }}
                                title="Click to Delete File"
                              >
                                <div
                                  onClick={() => {
                                    // handleDeleteFile(item.fileUploadId);
                                  }}
                                  className="deleteButton"
                                >
                                  <img
                                    src={DeleteIcons}
                                    alt=""
                                    height={"20px"}
                                    width={"20px"}
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        </>
                      ))}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {props.locationState.state !== undefined && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 8">
                      <div>Support Documents:</div>
                      <div className="personalDetailsUpload">
                        <div className="ducumentUploadBlock">
                          <div className="documentUploadContent">
                            <span className="btn_upload documentUpload">
                              <img src={panUploadIcon} alt="" />
                              <input
                                type="file"
                                id="imag"
                                title=""
                                className="input-img"
                                onChange={UploadDocuments()}
                              />
                              Upload Document
                            </span>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      )} */}
    </div>
  );
};
let year = parseInt(moment().year());
const yearOptions = [
  { value: year, label: year + "-" + (year + 1), name: year },
  { value: year - 1, label: year - 1 + "-" + year, name: year - 1 },
  { value: year - 2, label: year - 2 + "-" + (year - 1), name: year - 2 },
];

const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    name: "itemId",
  },
  {
    no: 2,
    component: "DynamicSelect",
    name: "Quality",
  },
  {
    no: 3,
    component: "Inputadorment",
    name: "stock",
    disabled: true,
  },
  {
    no: 4,
    component: "Inputadorment",
    name: "rate",
  },
  {
    no: 5,
    component: "DynamicSelect",
    name: "warehouseNo",
  },
  {
    no: 6,
    component: "InputAdoment",
    name: "quantity",
    label: "DRUM",
  },
  {
    no: 7,
    component: "InputAdoment",
    name: "advisedWeight",
    label: "QTL",
  },
  {
    no: 8,
    component: "InputAdoment",
    name: "actualWeight",
    label: "QTL",
  },
  {
    no: 9,
    component: "Inputadorment",
    name: "totalPrice",
  },
  {
    no: 10,
    component: "Inputadorment",
    name: "claimQuality",
  },
  {
    no: 11,
    component: "InputAdoment",
    name: "claimDust",
    label: "%",
  },
  {
    no: 12,
    component: "Inputadorment",
    name: "claimRate",
  },
  {
    no: 13,
    component: "InputAdoment",
    name: "claimsCondition",
    label: "%",
  },
  {
    no: 14,
    component: "InputAdoment",
    name: "allowableMoisture",
    label: "%",
  },
  {
    no: 15,
    component: "Inputadorment",
    name: "premiumAmount",
  },
  {
    no: 16,
    component: "Inputadorment",
    name: "waterDamageAmount",
  },
  {
    no: 17,
    component: "InputAdoment",
    name: "variableShortage",
    label: "KG",
  },
  {
    no: 18,
    component: "InputAdoment",
    name: "shortageKgs",
    label: "KG",
  },
  {
    no: 19,
    component: "InputAdoment",
    name: "acceptedWeight",
    label: "KG",
  },
  {
    no: 20,
    component: "Inputadorment",
    name: "marka",
  },
  {
    no: 21,
    component: "Inputadorment",
    name: "pota",
  },
  {
    no: 22,
    component: "DynamicSelect",
    name: "cropYear",
  },
];

const tableHeaders = [
  {
    header: "Item",
    type: 1,
  },
  {
    header: "Quality",
    type: 2,
  },
  {
    header: "Stock",
    type: 3,
  },
  {
    header: "Rate",
    type: 4,
  },
  {
    header: "Warehouse No",
    type: 5,
  },
  {
    header: "Quantity",
    type: 6,
  },
  {
    header: "Advised Weight",
    type: 7,
  },
  {
    header: "Mill Weight",
    type: 8,
  },
  {
    header: "Amount",
    type: 9,
  },
  {
    header: "Claim Quality",
    type: 10,
  },
  {
    header: "Claim Dust",
    type: 11,
  },
  {
    header: "Claim Rate",
    type: 12,
  },
  {
    header: "QC Moisture",
    type: 13,
  },
  {
    header: "Allowable Moisture",
    type: 14,
  },
  {
    header: "Prem Amt",
    type: 15,
  },
  {
    header: "Water Dmg Amt",
    type: 16,
  },
  {
    header: "Variable Shortage",
    type: 17,
  },
  {
    header: "Shortage in Weight",
    type: 18,
  },
  {
    header: "Approved Weight",
    type: 19,
  },
  {
    header: "Marka",
    type: 20,
  },
  {
    header: "Pota",
    type: 21,
  },
  {
    header: "Crop Year",
    type: 22,
  },
  {
    header: "Action",
  },
];

export default JuteMaterialReceiptAddItems;
