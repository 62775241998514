import { Component } from "react";
import { connect } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import { withRouter } from "react-router-dom";
import { JuteIssueList } from "../../../store/Jute/JuteIssue/actions";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";

class JuteIssueIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      startIndex: "",
      lastIndex: "",
      JuteIssueContent: [],
      loadingdata: false,
    };
  }
  componentDidMount() {
    const data = {
      fromDate: "",
      toDate: "",
      companyId: localStorage.getItem("companyId"),
      finYear: localStorage.getItem("acadamicYear"),
    };
    this.props.JuteIssueList(
      serverApi.JUTE_ISSUE_LIST,
      data,
      this.props.history
    );
    this.setState({ loadingdata: true });
  }
  componentWillReceiveProps(props) {
    if (props.JuteIssueList) {
      this.setState({
        JuteIssueContent: props.JuteIssueData.data,
        loadingdata: false,
      });
    }
  }
  handleSearch = (searchData) => {
    const data = {
      fromDate: searchData.fromDate,
      toDate: searchData.toDate,
      companyId: localStorage.getItem("companyId"),
      finYear: localStorage.getItem("acadamicYear"),
    };
    this.props.JuteIssueList(
      serverApi.JUTE_ISSUE_LIST,
      data,
      this.props.history
    );
    this.setState({ loadingdata: true });
  };
  render() {
    return (
      <>
        {this.state.loadingdata ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
            <sapn>Loading....</sapn>
          </div>
        ) : (
          ""
        )}
        <Gridwithcustomview
          mdata={this.state.JuteIssueContent}
          mcolumns={Columns}
          status={true}
          micon={GridArrowIcon}
          actions={true}
          filter_form={"JuteIssueFilter"}
          handleSearch={this.handleSearch}
          url="/create_jute_gate_entry"
        />
      </>
    );
  }
}
// machine master header
const Columns = [
  {
    Header: "Issue Date",
    accessor: "issueDate",
    hidden: false,
  },
  {
    Header: "Batch Cost",
    accessor: "batchCost",
    hidden: false,
  },
  {
    Header: "Bales",
    accessor: "noOfBales",
    hidden: false,
  },
  {
    Header: "Issue Weight",
    accessor: "issueWeight",
    hidden: false,
  },
  {
    Header: "Issue Value",
    accessor: "issueValue",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "issueStatus",
    hidden: false,
  },
];
const mapStatetoProps = (state) => {
  const { JuteIssueData } = state.JuteIssueReducer;
  return { JuteIssueData };
};

export default withRouter(
  connect(mapStatetoProps, {
    JuteIssueList,
  })(JuteIssueIndex)
);
