import {SPINNING_QUALITY_LIST,SPINNING_QUALITY_LIST_SUCCESSFULL,
    CREATE_SPINNING_QUALITY,CREATE_SPINNING_QUALITY_SUCCESSFULL,
    UPDATE_SPINNING_QUALITY,UPDATE_SPINNING_QUALITY_SUCCESSFULL,
    API_ERRORS,} from './actionTypes';

export const spinningQualityList = (url,data,history) =>{
    return {
        type:SPINNING_QUALITY_LIST,
        payload:{url,data,history}
    }
}
export const spinningQualityListSuccessfull = (data)=>{
    return {
        type:SPINNING_QUALITY_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createSpinningQuality = (url, data, history) => {
    return {
        type: CREATE_SPINNING_QUALITY,
        payload: { url, data, history }
    }
}

export const createSpinningQualitySuccessfull = (data) => {
    return {
        type: CREATE_SPINNING_QUALITY_SUCCESSFULL,
        payload: data
    }
}
export const updateSpinningQuality = (url, data, history) => {
    return {
        type: UPDATE_SPINNING_QUALITY,
        payload: { url, data, history }
    }
}

export const updateSpinningQualitySuccessfull = (data) => {
    return {
        type: UPDATE_SPINNING_QUALITY_SUCCESSFULL,
        payload: data
    }
}
export const apiErrors = (error) => {
    return {
        type: API_ERRORS,
        payload: error,
    };
};