import { all, call, fork, takeEvery, put, select } from "redux-saga/effects";

import {
  INDENT_LIST,
  CREATE_INDNET,
  ACTIONS,
  SEE_THE_LOGS,
  UPDATE_INDNET,
  PENDING_STOCK,
  PENDING_STOCKPO_AND_RECEIPT,
  GET_FILES_OF_INDENT,
  GET_DOWNLOAD_PDF,
  GET_COPYOF_INDENTS,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  VALIDATE_ITEM_IN_OPENINDENT,
  GETITEMSLISTFORINDENT_SUCCESSFULL,
  GETITEMSLISTFORINDENT,
  GETITEMGROUPSFORINDENT,
  GET_ITEM_DETAILS_BY_POID_FOR_INDENT,
  GET_ITEMSLIST_BY_ITEMGROUPID_COMPANYID,
  GET_ITEMDETAILS_BY_ITEMID,
  GET_ITEMDETAILS,
  VALIDATE_ITEM,
} from "./actionTypes";
import {
  indentListSuccessfull,
  CreateIndentSuccessfull,
  actionsSuccessfull,
  logsSuccessfull,
  UpdateIndentSuccessfull,
  PendingStockSuccessfull,
  PendingStockPoandReceiptSuccessfull,
  getFilesSuccessfull,
  getIndentPdfSuccessfull,
  getCopyOfIndentsSuccessfull,
  projectSuccessfull,
  updateProjectSuccessfull,
  getInvoiceFilesSuccessfull,
  getChallanFilesSuccessfull,
  getSuppdocsFilesSuccessfull,
  validateItemSuccessfull,
  validateItem,
  ItemGroupListForIndentSuccessfull,
  getItemsListForIndentSuccessfull,
  getItemDetailsByPoIdForIndentSuccessfull,
  getItemsListByItemGroupIdSuccessfull,
  getItemdetailsByItemIdSuccessfull,
  ItemDetailsSuccessfull,
  itemValidateSuccessfull,
  itemValidate,
} from "./actions";
import {
  getProjectList,
  getBranchList,
  getStatusList,
} from "../../Global/DropDownApis/actions";
import {
  getCallBackGetData,
  getList,
  getListurl,
  postCallBacklistData,
  getCallBacklistData,
} from "../../../helpers/Server_Helper";
import { hideSpinner, serverApi, showSpinner } from "../../../helpers/Consts";
import swal from "sweetalert";
import { IndentLineItemsProps } from "../../../store/Purchase/Indent/actions";
function* getIndentList({ payload: { url, data, history } }) {
  let userDit = JSON.parse(localStorage.getItem("authUser"));
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    if (response) {
      hideSpinner();
      if (response.data) {
        const data1 = {
          cipher: userDit.cipher,
          companyId: localStorage.getItem("companyId"),
        };
        const Url = serverApi.GET_ALL_PROJECTS;
        yield put(getProjectList(Url, data1, history));
        const url2 = serverApi.BRANCH_LIST + localStorage.getItem("companyId");
        yield put(getBranchList(url2, history));
        yield put(getStatusList(serverApi.GET_STATUS_LIST, history));
      }
      yield put(indentListSuccessfull({ response }));
    }
  } catch (error) {
    hideSpinner();
    if (error.response) {
      hideSpinner();
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
      }
    }
  }
}
function* getItemDetailsByPoIdForIndent({ payload: { url, history, source } }) {
  try {
    const response = yield call(getListurl, url, history);
    const { indentLineItems } = yield select((state) => state.IndentReducer);

    if (response && response.data) {
      let ItemDetailsID = response.data.data;
      let POWOLineItems = [...indentLineItems];

      if (ItemDetailsID.length !== 0) {
        ItemDetailsID.forEach((item) => {
          if (POWOLineItems && POWOLineItems.length !== 0) {
            POWOLineItems = POWOLineItems.map((lineItem) => {
              if (item.itemId === Number(lineItem.itemId)) {
                return {
                  ...lineItem,
                  branchStock: item.branchStock,
                  centralStock: item.centralStock,
                  maxAllowedQty: item.maxAllowedQty,
                  maxStock: item.maxQty,
                  minAllowedQty: item.minAllowedQty,
                  minStock: item.minQty,
                  poID: item.poID,
                  poLineItemID: item.poLineItemID,
                  reorderQty: item.reorderQty,
                };
              } else {
                return lineItem;
              }
            });
          }
        });
        yield put(IndentLineItemsProps(POWOLineItems));
      }
      yield put(getItemDetailsByPoIdForIndentSuccessfull({ response }));
    }
  } catch (error) {
    if (error.response) {
      console.log(error);
    }
  }
}
function* postCreateIndnet({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    if (response) {
      swal(response.message, { icon: "success" });
      yield put(CreateIndentSuccessfull({ response }));
      if (data.recordType === "BOQ") {
        history.push("/boq");
        hideSpinner(true);
      } else if (data.recordType === "BOM") {
        history.push("/bom");
        hideSpinner(true);
      } else if (data.recordType === "OPENINDENT") {
        history.push("/openIndent");
        hideSpinner(true);
      } else {
        history.push("/store_indent");
        hideSpinner(true);
      }
    } else {
      swal("Internal Server Error", { icon: "error" });
      hideSpinner(true);
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
      }
    }
  }
}
function* updateIndnet({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    if (response) {
      swal(response.message, { icon: "success" });
    }
    yield put(UpdateIndentSuccessfull({ response }));
    if (data.recordType === "BOQ") {
      history.push("/boq");
      hideSpinner(true);
    } else if (data.recordType === "BOM") {
      history.push("/bom");
      hideSpinner(true);
    } else if (data.recordType === "OPENINDENT") {
      history.push("/openIndent");
      hideSpinner(true);
    } else {
      history.push("/store_indent");
      hideSpinner(true);
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        hideSpinner(true);
      }
      hideSpinner(true);
    }
  }
}

function* postActions({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(actionsSuccessfull({ response }));
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
      }
    }
  }
}

function* logs({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(logsSuccessfull({ response }));
    if (response.data) {
      if (response.data.length == 0) {
        swal("No Logs Found");
      }
    }
  } catch (error) {
    if (error.response) {
      hideSpinner();
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
      }
    }
  }
}
function* getFilesofIndent({ payload: { url, history, sourceMenu } }) {
  try {
    // showSpinner();
    const response = yield call(getListurl, url);

    if (sourceMenu) {
      if (sourceMenu === "2") {
        yield put(getInvoiceFilesSuccessfull({ response }));
      } else if (sourceMenu === "3") {
        yield put(getChallanFilesSuccessfull({ response }));
      } else if (sourceMenu === "18") {
        yield put(getSuppdocsFilesSuccessfull({ response }));
      }
    } else {
      yield put(getFilesSuccessfull({ response }));
    }

    // hideSpinner();
  } catch (error) {
    console.log(error);
    if (error.response) {
      hideSpinner();
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

function* getPdfIndent({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getIndentPdfSuccessfull({ response }));
  } catch (error) {
    console.log(error);
    if (error.response) {
      hideSpinner();
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

function* pendingStock({ payload: { url, data, history, id } }) {
  try {
    const response = yield call(getList, url, data);
    response.id = id;
    yield put(PendingStockSuccessfull({ response }));
  } catch (error) {
    console.log(error);
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* copyOfIndents({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);

    yield put(getCopyOfIndentsSuccessfull({ response }));
  } catch (error) {
    console.log(error);
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* pendingStockPoandREceipt({ payload: { url, data, history, id } }) {
  try {
    const response = yield call(getList, url, data);
    response.id = id;
    yield put(PendingStockPoandReceiptSuccessfull({ response }));
  } catch (error) {
    console.log(error);
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

function* createProjectList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    if (response) {
      if (response.message === "Project name already exist!!..") {
        return swal(response.message, { icon: "warning" });
      }
      swal(response.message, { icon: "success" });
      history.push("/project_list");
      yield put(projectSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

function* updateProject({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    if (response) {
      swal(response.message, { icon: "success" });
    }
    yield put(updateProjectSuccessfull({ response }));
    history.push("/project_list");
  } catch (error) {
    console.log(error);
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

function* validateItemForOpenIndent({
  payload: { url, callback, dropdowndata },
}) {
  try {
    const response = yield call(
      getCallBackGetData,
      url,
      callback,
      dropdowndata
    );
    if (response) {
      // swal(response.data.message, { icon: "success" });
      yield put(validateItemSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

function* ItemsListforIndent({ payload: { url, data, callback, itemgrpId } }) {
  try {
    const response = yield call(
      postCallBacklistData,
      url,
      data,
      callback,
      itemgrpId
    );
    if (response) {
      // Handle successful response
      yield put(getItemsListForIndentSuccessfull({ response }));
    }
  } catch (error) {
    hideSpinner(); // Hide spinner or loading indicator

    // Check if the error is undefined
    if (error === undefined) {
      // Handle undefined error gracefully
      // Display informative message to the user
      swal("An unexpected error occurred. Please try again later.", {
        icon: "error",
      });
    } else if (error.response) {
      // Handle other types of errors (e.g., API errors)
      hideSpinner();
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.error("API error:", error.response.data); // Log detailed error information
      }
    } else {
      // Handle other types of errors
      swal("An unexpected error occurred. Please try again later.", {
        icon: "error",
      });
    }
  }
}

function* ItemsGroupListforIndent({ payload: { url, history, callback, Id } }) {
  try {
    const response = yield call(
      getCallBacklistData,
      url,
      history,
      callback,
      Id
    );
    if (response) {
      // swal(response.data.message, { icon: "success" });
      yield put(ItemGroupListForIndentSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
    if (error.response) {
      hideSpinner();
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

function* ItemsListByItemGroupId({
  payload: { url, data, callback, itemgrpId },
}) {
  try {
    const response = yield call(
      postCallBacklistData,
      url,
      data,
      callback,
      itemgrpId
    );
    if (response) {
      // Handle successful response
      yield put(getItemsListByItemGroupIdSuccessfull({ response }));
    }
  } catch (error) {
    hideSpinner(); // Hide spinner or loading indicator

    // Check if the error is undefined
    if (error === undefined) {
      // Handle undefined error gracefully
      // Display informative message to the user
      swal("An unexpected error occurred. Please try again later.", {
        icon: "error",
      });
    } else if (error.response) {
      // Handle other types of errors (e.g., API errors)
      hideSpinner();
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.error("API error:", error.response.data); // Log detailed error information
      }
    } else {
      // Handle other types of errors
      swal("An unexpected error occurred. Please try again later.", {
        icon: "error",
      });
    }
  }
}

function* ItemDetailsByItemId({ payload: { url, data, callback, itemId } }) {
  try {
    const response = yield call(
      postCallBacklistData,
      url,
      data,
      callback,
      itemId
    );
    if (response) {
      // Handle successful response
      yield put(getItemdetailsByItemIdSuccessfull({ response }));
    }
  } catch (error) {
    hideSpinner(); // Hide spinner or loading indicator
    // Check if the error is undefined
    if (error === undefined) {
      // Handle undefined error gracefully
      // Display informative message to the user
      swal("An unexpected error occurred. Please try again later.", {
        icon: "error",
      });
    } else if (error.response) {
      // Handle other types of errors (e.g., API errors)
      hideSpinner();
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.error("API error:", error.response.data); // Log detailed error information
      }
    } else {
      // Handle other types of errors
      swal("An unexpected error occurred. Please try again later.", {
        icon: "error",
      });
    }
  }
}

function* getItemDetailsData({
  payload: { url, data, selectedValue, branchId, selectedItemCode, history },
}) {
  try {
    const response = yield call(
      getList,
      url,
      data,
      selectedValue,
      branchId,
      selectedItemCode,
      history
    );
    const { indentLineItems } = yield select((state) => state.IndentReducer);
    if (response) {
      if (response.minQty !== 0 || response.maxQty !== 0) {
        yield put(
          itemValidate(
            serverApi.VALIDATE_ITEM_OI +
              selectedValue +
              "/" +
              localStorage.getItem("companyId") +
              "/" +
              localStorage.getItem("acadamicYear") +
              "/" +
              branchId
          )
        );
      } else {
        swal(
          `The MinMax for the selected item (${response.itemDesc} and ${selectedItemCode}) is not entered. Please enter the same in the Item Master`
        );
        let indentLineItemsData = indentLineItems.filter((item) => {
          if (Number(item.itemId) === Number(selectedValue)) {
            item.rate = 0;
            item.uomCode = "";
            item.itemId = "";
            item.itemName = "";
            item.itemCode = "";
            item.min = 0;
            item.max = 0;
            item.minAllowedQty = 0;
            item.maxAllowedQty = 0;
            item.reOrderQty = 0;
          }
          return item;
        });
        yield put(IndentLineItemsProps(indentLineItemsData));
      }
      yield put(ItemDetailsSuccessfull({ response }));
    } else {
      swal("Internal Server Error", { icon: "error" });
      hideSpinner(true);
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
      }
    }
  }
}

function* itemValidation({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(itemValidateSuccessfull({ response }));
    const { itemDetailsData, indentLineItems } = yield select(
      (state) => state.IndentReducer
    );
    if (response) {
      if (response.data) {
        if (response.data.message) {
          if (response.data.message !== "Indent Not Found") {
            swal(response.data.message, { icon: "error" });
            let indentLineItemsData = indentLineItems.filter((item) => {
              if (Number(item.itemId) === Number(itemDetailsData.itemId)) {
                item.rate = 0;
                item.uomCode = "";
                item.itemId = "";
                item.itemName = "";
                item.itemCode = "";
                item.min = 0;
                item.max = 0;
                item.minAllowedQty = 0;
                item.maxAllowedQty = 0;
                item.reOrderQty = 0;
              }
              return item;
            });
            yield put(IndentLineItemsProps(indentLineItemsData));
          } else {
            let indentLineItemsData = indentLineItems.filter((item) => {
              if (Number(item.itemId) === Number(itemDetailsData.itemId)) {
                item.rate =
                  itemDetailsData.srRate === "null"
                    ? 0
                    : parseInt(itemDetailsData.srRate);
                item.uomCode = itemDetailsData.uomCode;
                item.itemName = itemDetailsData.itemDesc;
                item.min = itemDetailsData.minQty;
                item.max = itemDetailsData.maxQty;
                item.minAllowedQty = itemDetailsData.maxAllowableQty;
                item.maxAllowedQty = itemDetailsData.minAllowableQty;
                item.reOrderQty = itemDetailsData.reOrderQty;
              }
              return item;
            });
            yield put(IndentLineItemsProps(indentLineItemsData));
          }
        }
      }
    }
  } catch (error) {
    if (error.response) {
      hideSpinner();
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
      }
    }
  }
}

export function* watchIndentList() {
  yield takeEvery(INDENT_LIST, getIndentList);
  yield takeEvery(CREATE_INDNET, postCreateIndnet);
  yield takeEvery(ACTIONS, postActions);
  yield takeEvery(SEE_THE_LOGS, logs);
  yield takeEvery(GET_FILES_OF_INDENT, getFilesofIndent);
  yield takeEvery(GET_DOWNLOAD_PDF, getPdfIndent);
  yield takeEvery(UPDATE_INDNET, updateIndnet);
  yield takeEvery(PENDING_STOCK, pendingStock);
  yield takeEvery(PENDING_STOCKPO_AND_RECEIPT, pendingStockPoandREceipt);
  yield takeEvery(GET_COPYOF_INDENTS, copyOfIndents);
  yield takeEvery(CREATE_PROJECT, createProjectList);
  yield takeEvery(UPDATE_PROJECT, updateProject);
  yield takeEvery(VALIDATE_ITEM_IN_OPENINDENT, validateItemForOpenIndent);
  yield takeEvery(GETITEMSLISTFORINDENT, ItemsListforIndent);
  yield takeEvery(GETITEMGROUPSFORINDENT, ItemsGroupListforIndent);
  yield takeEvery(
    GET_ITEM_DETAILS_BY_POID_FOR_INDENT,
    getItemDetailsByPoIdForIndent
  );
  yield takeEvery(
    GET_ITEMSLIST_BY_ITEMGROUPID_COMPANYID,
    ItemsListByItemGroupId
  );
  yield takeEvery(GET_ITEMDETAILS_BY_ITEMID, ItemDetailsByItemId);
  yield takeEvery(GET_ITEMDETAILS, getItemDetailsData);
  yield takeEvery(VALIDATE_ITEM, itemValidation);
}

function* IndentSaga() {
  yield all([fork(watchIndentList)]);
}

export default IndentSaga;
