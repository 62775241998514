import {
  JUTE_BATCH_PLANNING_LIST,
  JUTE_BATCH_PLANNING_LIST_SUCCESSFULL,
  JUTE_PLAN_ASSIGN,
  JUTE_PLAN_ASSIGN_SUCCESSFULL,
  // JUTE_GATE_ENTRY_LINE_ITEMS,
  // JUTE_GATE_ENTRY_CREATE,
  // JUTE_GATE_ENTRY_VIEW_BY_ID,
  // JUTE_GATE_ENTRY_VIEW_BY_ID_SCCESSFULL,
} from "./actionTypes";

export const JuteBatchPlanning = (url, data, history) => {
  return {
    type: JUTE_BATCH_PLANNING_LIST,
    payload: { url, data, history },
  };
};
export const JuteBatchPlanningSuccessfull = (data) => {
  return {
    type: JUTE_BATCH_PLANNING_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const JuteBatchPlanAssign = (url, data, history) => {
  return {
    type: JUTE_PLAN_ASSIGN,
    payload: { url, data, history },
  };
};
export const JuteBatchPlanAssignSuccessfull = (data) => {
  return {
    type: JUTE_PLAN_ASSIGN_SUCCESSFULL,
    payload: data,
  };
};
// export const LineItemsGateEntryPros = (data) => {
//   return {type: JUTE_GATE_ENTRY_LINE_ITEMS, payload:data}
// }

// export const juteGateEntryUpdate = (url, data, history) => {
//   return {
//       type: JUTE_GATE_ENTRY_CREATE,
//       payload: {url, data, history}
//   }
// }

// export const juteGateEntryViewById = (url,history) =>{
//   return {
//       type:JUTE_GATE_ENTRY_VIEW_BY_ID,
//       payload:{url,history}
//   }
// }

// export const juteGateEntryViewByIdSuccessfull = (data)=>{
//   return {
//       type:JUTE_GATE_ENTRY_VIEW_BY_ID_SCCESSFULL,
//       payload:data
//   }
// }
