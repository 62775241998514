import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../../../components/Buttons/Buttons";
import { serverApi, getTenantId } from "../../../../helpers/Consts";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { Box, Tooltip, Grid } from "@mui/material";
import TickIcon from "../../../../assets/images/fileUploadedTick.png";
import Fade from "@mui/material/Fade";
import { serverConfig } from "../../../../helpers/Consts";
import { getFileDownload } from "../../../../helpers/server.js";
import { isEmpty } from "../../../../Utilities/helper.js";

const JuteGovtPreview = (props) => {
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [companyName, setcompanyName] = useState("");
  const [phoneNo, setphoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [companyLogo, setcompanyLogo] = useState("");
  const [addressOne, setAddressOne] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [SoJuteGovtHeadersData, setSoJuteGovtHeadersData] = useState({});
  const [SoJuteGovtLineItemsData, SetSoJuteGovtLineItemsData] = useState([]);
  const { SOFiles, LineItemsData } = useSelector(
    (state) => state.SalesOrderReducer
  );
  const [salesOrderFiles, SetSalesOrderFiles] = useState(
    SOFiles?.data?.data || []
  );
  useEffect(() => {
    SetSalesOrderFiles(SOFiles?.data?.data);
  }, [SOFiles]);

  useEffect(() => {
    let companyData = JSON.parse(localStorage.getItem("authUser"));
    let companyId = localStorage.getItem("companyId");
    companyData.companyDetails.map((p) => {
      let id = p.value;
      if (companyId === id.toString()) {
        setcompanyName(p.label);
        setcompanyLogo(p.companyLogo);
        setphoneNo(p.contactNo);
        setGstNo(p.gstNo);
        setAddressOne(p.address1);
        setAddressTwo(p.address2);
        setGstNo(p.gstNo);
        setEmail(p.emailId);
      }
    });
  });

  const { SoJuteGovtLineItems, SoJuteGovtHeaders } = useSelector(
    (state) => state.SalesOrderReducer
  );

  useEffect(() => {
    SetSoJuteGovtLineItemsData(SoJuteGovtLineItems);
    setSoJuteGovtHeadersData(SoJuteGovtHeaders);
  }, [SoJuteGovtLineItems, SoJuteGovtHeaders]);

  const handlePdf = (printType) => {
    if (props.locationState.state !== undefined) {
      if (props.locationState.state.rowData !== undefined) {
        fetch(
          serverApi.DOWNLOAD_REGULAR_SO_PRINTOUT +
            2 +
            "/" +
            SoJuteGovtHeadersData.regularSalesId +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).userId +
            "/" +
            printType,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/pdf",
              "X-TenantId": getTenantId(window.location.hostname),
              Authorization:
                localStorage.getItem("token_type") +
                localStorage.getItem("access_token"),
            },
          }
        )
          .then((response) => response.blob())
          .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              SoJuteGovtHeadersData.salesNo + ".pdf"
            );
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          });
      }
    }
  };
  const convertNumberToWords = (amount) => {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();

    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = "";
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  };
  const handleEdit = () => {
    props.handleCurrentStep(2);
  };
  const withDecimal = (n) => {
    if (n !== undefined) {
      let totalAmount = Number(n).toFixed(2);
      var nums = totalAmount.split(".");
      var whole = convertNumberToWords(nums[0]);

      if (nums.length === 2) {
        let numberAfterDecimal = Number(nums[1]).toFixed(2);
        var decimal = convertNumberToWords(numberAfterDecimal);
        if (parseInt(nums[1]) > 0) {
          return whole + " Rupees and " + decimal + " Paise only";
        }
      }
      return whole + " Rupees only";
    }
  };
  let filterProduct = SoJuteGovtLineItemsData.filter(
    (item) =>
      item.isActive !== 0 &&
      item.itemGroupId !== null &&
      item.itemGroupValue !== "997"
  );

  let otherChargesItems = SoJuteGovtLineItemsData.filter(
    (item) => item.isActive !== 0 && item.itemGroupValue === "997"
  );
  var updatedOn = SoJuteGovtHeadersData.updatedOn
    ? moment(SoJuteGovtHeadersData.updatedOn, "YYYY-MM-DD")?.format(
        "DD-MM-YYYY"
      )
    : null;

  return (
    <>
      <div className="previewBlock">
        <div
          style={{ display: "flex", justifyContent: "end", padding: "10px" }}
        >
          {props.locationState.state && (
            <CustomButton
              label="Consignee Copy Print"
              handleClick={() => {
                handlePdf(1);
              }}
              type="button"
              className="lightBlueButton"
              muIcon={<PrintIcon />}
            />
          )}
          {SoJuteGovtHeadersData.EditButton === true && (
            <CustomButton
              label="Edit"
              handleClick={() => {
                handleEdit();
              }}
              type="button"
              className="lightBlueButton"
              muIcon={<EditIcon />}
            />
          )}
        </div>
        <div id="SOJuteGovtPreview">
          <table
            cellpadding="0"
            cellspacing="0"
            width="90%"
            className="previewTable"
          >
            <tbody>
              <tr>
                <td
                  colSpan="4"
                  style={{ borderRight: "none", borderBottom: "none" }}
                >
                  <img src={companyLogo} width="100%" alt="" />
                </td>
                <td
                  colSpan="8"
                  style={{
                    borderBottom: "none",
                    borderLeft: "none",
                    textAlign: "right",
                  }}
                >
                  <h2>{companyName}</h2>
                  {addressOne}
                  <br />
                  {addressTwo}
                  <br />
                  <span>
                    <b>Ph.No: </b>
                    {phoneNo},{" "}
                  </span>
                  <span>
                    <b>Email: </b> {email}
                  </span>
                  <br />
                  <b>GST No:</b> {gstNo || "n/a"}
                  <br />
                  <br></br>
                </td>
              </tr>
              <tr>
                <td
                  colSpan="32"
                  style={{ textAlign: "center", borderTop: "none" }}
                >
                  <h2>SALES ORDER</h2>
                </td>
              </tr>

              <tr>
                <td colSpan="2">
                  <b>Sale Order NO : </b>
                </td>
                <td
                  colSpan="2"
                  className={
                    SoJuteGovtHeadersData.salesNo === "" ? "blur-text" : ""
                  }
                >
                  {SoJuteGovtHeadersData.salesNo === ""
                    ? "Will be generated after creation"
                    : SoJuteGovtHeadersData.salesNo}
                </td>
              </tr>

              <tr>
                <td colSpan="2">
                  <b>Sale Order DATE :</b>
                </td>
                <td colSpan="2">
                  {moment(SoJuteGovtHeadersData.salesDate).format("DD-MM-YYYY")}
                </td>
                <td colSpan="2">
                  <b>Expire Date :</b>
                </td>
                <td colSpan="6">
                  {moment(SoJuteGovtHeadersData.salesOrderExpiryDate).format(
                    "DD-MM-YYYY"
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>Consignee :</b>
                </td>
                <td colSpan="2">{SoJuteGovtHeadersData.customerName}</td>
                <td colSpan="2">
                  <b>Branch : </b>
                </td>

                <td colSpan="6">{SoJuteGovtHeadersData.branchName}</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <b>PCSO Number :</b>
                </td>
                <td colSpan="2">{SoJuteGovtHeadersData.pcsoNo}</td>
                <td colSpan="2">
                  <b>PCSO Date : </b>
                </td>

                <td colSpan="6">
                  {" "}
                  {moment(SoJuteGovtHeadersData.pcsoDate).format("DD-MM-YYYY")}
                </td>
              </tr>
              <tr>
                <td
                  colSpan="4"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    padding: "none",
                  }}
                >
                  <br />
                  <b>Billed To : </b>
                  {"  "}
                  {SoJuteGovtHeadersData.customerName
                    ? SoJuteGovtHeadersData.customerName
                    : ""}{" "}
                  , <br />
                  {SoJuteGovtHeadersData.billingToAddress} <br />
                  <br />
                  <b>GSTIN : </b>
                  {"  "}
                  {SoJuteGovtHeadersData.billingToGST}
                  <br />
                  <b>Contact Person :</b> {"  "}
                  {SoJuteGovtHeadersData.billingContactPerson}
                  <br />
                  <b>Contact Number :</b> {"  "}
                  {SoJuteGovtHeadersData.billingContactNo}
                  <br />
                  <b>EMAIL ID : </b>
                  {"  "}
                  {SoJuteGovtHeadersData.billingToEmail}
                </td>
                <td
                  colSpan="8"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    padding: "none",
                  }}
                >
                  <br />
                  <b> Shipped To : </b>
                  {"  "}
                  {SoJuteGovtHeadersData.customerName
                    ? SoJuteGovtHeadersData.customerName
                    : ""}{" "}
                  , <br />
                  {SoJuteGovtHeadersData.shippingToAddress}
                  <br />
                  <br />
                  <b>GSTIN :</b> {SoJuteGovtHeadersData.shippingToGST}
                  <br />
                  <b>Contact Pesron : </b>
                  {"  "} {SoJuteGovtHeadersData.shippingContactPerson} <br />
                  <b>Contact Number : </b>
                  {"  "}
                  {SoJuteGovtHeadersData.shippingContactNo}
                  <br />
                  <b>EMAIL ID :</b> {SoJuteGovtHeadersData.shippingToEmail}
                </td>
              </tr>
              <tr>
                <td rowSpan="2" width={"5%"}>
                  <b>S.No</b>
                </td>
                <td rowSpan="2" align="center" width={"8%"}>
                  <b>Item CODE</b>
                </td>
                <td rowSpan="2" align="center" width={"30%"}>
                  <b>Item Name</b>
                </td>
                <td rowSpan="2" align="center" width={"10%"}>
                  <b>Quantity</b>
                </td>
                <td rowSpan="2" align="center" width={"10%"}>
                  <b>RATE</b>
                </td>
                <td rowSpan="2" align="center" width={"7%"}>
                  <b>Amount</b>
                </td>
                <td colSpan="2" align="center" width={"10%"}>
                  <b>IGST</b>
                </td>
                <td colSpan="2" align="center" width={"10%"}>
                  <b>CGST</b>
                </td>
                <td colSpan="2" align="center" width={"10%"}>
                  <b>SGST</b>
                </td>
              </tr>
              <tr>
                <td>
                  <b>%</b>
                </td>
                <td>
                  <b>Amt</b>
                </td>
                <td>
                  <b>%</b>
                </td>
                <td>
                  <b>Amt</b>
                </td>
                <td>
                  <b>%</b>
                </td>
                <td>
                  <b>Amt</b>
                </td>
              </tr>
              {filterProduct &&
                filterProduct.map((item, i) => {
                  return (
                    <>
                      <tr>
                        <td>{i + 1}</td>
                        <td>{item.itemCode}</td>
                        <td>
                          {item.itemName}
                          <br />
                          <span>
                            <b>Remarks :</b> {item.remarks}
                          </span>{" "}
                          <br />
                          <span>
                            <b>Make :</b> {item.itemMake}
                          </span>{" "}
                          <br />
                          <span>
                            <b>HSN Code :</b> {item.hsnCode}
                          </span>
                        </td>
                        <td align="right">
                          {parseFloat(item.quantity).toFixed(3)} - {item.uom}
                          <br />
                          {item.govtQtyCalculatedValue !== 0 && (
                            <>
                              {parseFloat(item.govtQtyCalculatedValue).toFixed(
                                3
                              )}{" "}
                              - {item.govtQuantityUom}
                              <br />
                            </>
                          )}
                          {item.govtQtyCalculatedValue2 !== 0 && (
                            <>
                              {parseFloat(item.govtQtyCalculatedValue2).toFixed(
                                3
                              )}{" "}
                              - {item.govtQuantityUom2}
                              <br />
                            </>
                          )}
                          {item.govtQtyCalculatedValue3 !== 0 && (
                            <>
                              {parseFloat(item.govtQtyCalculatedValue3).toFixed(
                                3
                              )}{" "}
                              - {item.govtQuantityUom3}
                              <br />
                            </>
                          )}
                        </td>
                        <td align="right">
                          {parseFloat(item.rate).toFixed(3)} - RS/{item.uom}
                          <br />

                          { !isEmpty(item.govtRateCalculatedValue2) && (
                            <>
                              {parseFloat(
                                item.govtRateCalculatedValue2
                              ).toFixed(3)}{" "}
                              - RS/{item.govtRateUom2}
                              <br />
                            </>
                          )}
                        </td>

                        <td align="right">
                          {parseFloat(item.netAmount).toFixed(2)}
                        </td>
                        <td align="right">
                          {parseFloat(item.igstPercent).toFixed(2)}
                        </td>
                        <td align="right">
                          {parseFloat(item.igstAmount).toFixed(2)}
                        </td>
                        <td align="right">
                          {parseFloat(item.cgstPercent).toFixed(2)}
                        </td>
                        <td align="right">
                          {parseFloat(item.cgstAmount).toFixed(2)}
                        </td>
                        <td align="right">
                          {parseFloat(item.sgstPercent).toFixed(2)}
                        </td>
                        <td align="right">
                          {parseFloat(item.sgstAmount).toFixed(2)}
                        </td>
                      </tr>
                    </>
                  );
                })}
              {otherChargesItems &&
                otherChargesItems.map((item) => {
                  return (
                    <tr>
                      <td colspan="3">
                        <b> {item.itemName}</b>
                      </td>
                      <td colspan="16">
                        {parseFloat(item.netAmount).toFixed(2)}
                      </td>
                    </tr>
                  );
                })}

              <tr>
                <td colspan="3">
                  <b>Taxable Value</b>
                </td>
                <td colspan="16">
                  {parseFloat(SoJuteGovtHeadersData.netTotal).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Total IGST</b>
                </td>
                <td colspan="16">
                  {parseFloat(SoJuteGovtHeadersData.totalIGST).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Total CGST</b>
                </td>
                <td colspan="16">
                  {parseFloat(SoJuteGovtHeadersData.totalCGST).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Total SGST</b>
                </td>
                <td colspan="16">
                  {parseFloat(SoJuteGovtHeadersData.totalSGST).toFixed(2)}
                </td>
              </tr>

              <tr>
                <td colspan="3">
                  <b>Total Value with Tax</b>
                </td>
                <td colspan="16">
                  {parseFloat(SoJuteGovtHeadersData.TotalAmount).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <b>Total Amount in words</b>
                </td>
                <td colspan="16">
                  {withDecimal(SoJuteGovtHeadersData.TotalAmount)}
                </td>
              </tr>
              <tr>
                <td
                  colspan="9"
                  style={{
                    borderTop: "none",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  <div style={{ height: "10px" }}></div>
                  <b>Delivery Days: </b>{" "}
                  {SoJuteGovtHeadersData.deliveryDays
                    ? SoJuteGovtHeadersData.deliveryDays + " Days"
                    : "N/A"}
                  <br />
                  <b>Delivery Terms: </b>{" "}
                  <span>{SoJuteGovtHeadersData.deliveryTerms}</span>
                  <br />
                  <b>Payment Terms (In Days): </b>{" "}
                  {SoJuteGovtHeadersData.paymentTerms || "N/A"}
                  <br />
                  <b>Footer Notes: </b>{" "}
                  {SoJuteGovtHeadersData.footerNote || "N/A"}
                  <br />
                  <b>Internal Notes: </b>{" "}
                  {SoJuteGovtHeadersData.internalNote || "N/A"}
                  <br />
                  <br />
                </td>
              </tr>
              <tr>
                <td
                  colSpan="10"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    borderRight: "none",
                    borderLeft: "none",
                  }}
                >
                  {props.locationState.state !== undefined ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box gridColumn="span 12">
                          <span>
                            <b>Support Documents:</b>
                          </span>
                        </Box>
                        <Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={2}
                          className="ducumentUploadBlock uploadedContent"
                          style={{ padding: "10px" }}
                        >
                          {salesOrderFiles?.length > 0 &&
                            salesOrderFiles.map((item) => (
                              <Box gridColumn="span 6">
                                <div
                                  className="documentUploadContent"
                                  key={item.fileUploadId}
                                >
                                  <div
                                    className="uploadedInfo"
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <img
                                      src={TickIcon}
                                      alt=""
                                      className="tickIcon"
                                      style={{
                                        height: "16px",
                                        width: "16px",
                                        marginRight: "8px",
                                      }}
                                    />
                                    <Tooltip
                                      TransitionComponent={Fade}
                                      TransitionProps={{ timeout: 600 }}
                                      title="Click to download File"
                                    >
                                      <div
                                        className="uplodedFileName"
                                        onClick={() => {
                                          getFileDownload(
                                            serverConfig.SERVER_URL +
                                              "security-api/api/files/downloadfile/" +
                                              item.fileUploadId,
                                            item.fileName,
                                            item.fileExtension
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.fileName}
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </Box>
                            ))}
                        </Box>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
              <tr>
                <td
                  colSpan="9"
                  style={{
                    borderTop: "none",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  <b>Terms and Conditions: </b>

                  <br />
                  {SoJuteGovtHeadersData.termsCondition}
                </td>
              </tr>
              <tr>
                <td
                  colSpan="9"
                  style={{
                    borderTop: "none",
                    borderRight: "none",
                    borderBottom: "none",
                  }}
                >
                  <div style={{ height: "10px" }}></div>
                  <br />
                  <b>Last Modified On: </b> {updatedOn}
                  <br />
                  <b>Last Modified By: </b> {SoJuteGovtHeadersData.updatedBy}
                  <br />
                  <br />
                  <b>Status: {SoJuteGovtHeadersData.doStatus}</b>
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default JuteGovtPreview;
