import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {
  WAREHOUSELIST,
  CREATE_WAREHOUSE,
  UPDATE_WAREHOUSE,
} from "./actionTypes";
import {
  WareHouseListSuccessfull,
  createWarehouseSuccessfull,
  updateWarehouseSuccessfull,
} from "./actions";
import { postAdd } from "../../../helpers/Server_Helper";
import swal from "sweetalert";

function* getWareHouseList({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(WareHouseListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* createWarehouseMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createWarehouseSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/warehouse_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* updateWareHouseMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateWarehouseSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/warehouse_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

export function* watchWareHouseList() {
  yield takeEvery(WAREHOUSELIST, getWareHouseList);
}
export function* watchCreateWarehouseMaster() {
  yield takeEvery(CREATE_WAREHOUSE, createWarehouseMaster);
}
export function* watchUpdateWarehouseMaster() {
  yield takeEvery(UPDATE_WAREHOUSE, updateWareHouseMaster);
}

function* WareHouseListSaga() {
  yield all([
    fork(watchWareHouseList),
    fork(watchCreateWarehouseMaster),
    fork(watchUpdateWarehouseMaster),
  ]);
}

export default WareHouseListSaga;
