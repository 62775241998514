import {PAYMENT_LIST,PAYMENT_LIST_SUCCESSFULL, BILLS_NO_LIST, BILLS_NO_LIST_SUCCESSFULL, PAYMENT_CREATE, PAYMENT_CREATE_SUCCESSFULL} from './actionType';

export const getPaymentList = (url,data,history) =>{
    return {
        type:PAYMENT_LIST,
        payload:{url,data,history}
    }
}
export const paymentListSuccessfull = (data)=>{
    return {
        type:PAYMENT_LIST_SUCCESSFULL,
        payload:data
    }
}

export const getPaymentCreate = (url, data, history) => {
    return {
        type: PAYMENT_CREATE,
        payload: {url, data, history}
    }
}

export const paymentCreatedSuccessfull = (data) =>{
    return{
        type: PAYMENT_CREATE_SUCCESSFULL,
        payload: data
    }
}

export const getBillNoList = (url, data, history) =>{
    return {
        type: BILLS_NO_LIST,
        payload: {url, data, history}
    }
}

export const getBillNoListSuccessfull = (data) => {
    return {
        type: BILLS_NO_LIST_SUCCESSFULL,
        payload: data
    }
}