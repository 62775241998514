import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi, getTenantId } from "../../../helpers/Consts";
import {
  paySlipsByEbNo,
  getAllpaySlips,
  getPaySummary,
  paySlipsByEbNo2,
} from "../../../store/HRMS/PaySlip/actions";
import { Grid, Box } from "@mui/material";
import searchIcon from "../../../assets/images/search.png";
import InputAdormentSearch from "../../../components/InputAdorment/InputAdormentSearch";
import { Table } from "reactstrap";
import CustomButton from "../../../components/Buttons/Buttons";
import { getAcadamicYears } from "../../../store/auth/FinancialYear/actions";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import moment from "moment";
// import { PieChart } from "recharts";
import PieChart from "./paySummaryPieChart";
import { isEmpty } from "validator";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      ebno: "",
      ebid: "",
      empCode: "",
      getAllpaySlips_list: [],
      employeeData: [],
      resultTable: "none",
      loading: "none",
      employee_name: "",
      employee_Id: 0,
      financialYear: [],
      selectedDate: null,
      fromDate: null,
      toDate: null,
      paySummaryList: [],
      Netcal: 0,
    };
    this.DownloadAssetform = this.DownloadAssetform.bind();
  }
  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "ebno") {
      this.setState({
        ebno: event,
      });
    }
  };
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.props.paySlipsByEbNo(
        serverApi.PAYSLIP_DATA +
          "/" +
          this.state.ebno +
          "/" +
          localStorage.getItem("companyId") +
          "/" +
          this.state.userDit.userId
      );
      if (this.state.resultTable === "none" && this.state.loading === "none") {
        this.setState({ resultTable: "block", loading: "block" });
      }
    }
  };
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillReceiveProps(props) {
    if (props.payslipAvailable.length > 0) {
      var payslipList = [];
      var i = 0;
      props.payslipAvailable.map((row) => {
        var year = row.name.split("_");
        year = year[0].split("-");
        year = year[2];
        var Data =""
        var Date =""
        var Date1 =""
        if(this.state.selectedDate !=="" && this.state.selectedDate!==null){
           Data =this.state.selectedDate.split("-",2)
           Date =Data[0]
           Date1 =Data[1]
        }
        if (i !== 0 && year == Date ) {
          payslipList.push(row);
      
        }
         if (i !== 0 && year ==Date1){
          payslipList.push(row);
        }
         i++
      
      });

      this.setState({
        getAllpaySlips_list: payslipList,
      });
    }
    if (props.payslipData) {
      if (props.payslipData.data) {
        this.setState({
          employee_name: props.payslipData.data.workerName,
          employee_Id: props.payslipData.data.ebId,
        });
      } else {
        this.setState({
          employee_name: "",
          employee_Id: "",
        });
      }
    }

    var list = [];
    if (props.acadamicYearsList.data) {
      props.acadamicYearsList.data.map((prop) => {
        let datelabel = prop.displayLable;
        datelabel = datelabel.split("-");

        list.push({
          label:prop.displayLable,
          name: prop.displayLable,
          value: prop.fromDate + "_" + prop.toDate + "_" + datelabel[0],
        });
      });
    }

    this.setState({
      financialYear: list,
    });

    if (props.paySummaryList.data) {
      var filterData = props.paySummaryList.data;
      var Total =
        props.paySummaryList.data.length > 0
          ? filterData
              .map((p) => p.amount)
              .reduce((total, ind) => parseFloat(total) + parseFloat(ind))
          : "";
      this.setState({
        paySummaryList: props.paySummaryList,
        Netcal: Total,
      });
    }
  }
  onClick = () => {
    this.props.paySlipsByEbNo(
      serverApi.PAYSLIP_DATA +
        "/" +
        this.state.ebno +
        "/" +
        localStorage.getItem("companyId") +
        "/" +
        this.state.userDit.userId
    );
    this.props.paySlipsByEbNo2(
      serverApi.PAYSLIP_DATA +
        "/" +
        this.state.ebno +
        "/" +
        localStorage.getItem("companyId") +
        "/" +
        this.state.userDit.userId,
      this.state.fromDate,
      this.state.toDate,
      this.props.history
    );
    if (this.props.payslipData.data) {
      this.props.getAcadamicYears(
        serverApi.GET_ACADEMIC_YEARS_LIST + localStorage.getItem("companyId"),
        this.props.history
      );
    }

    //Hide and show
    if (this.state.resultTable === "none" && this.state.loading === "none") {
      this.setState({ resultTable: "block", loading: "block" });
    }
  };
  downloadPayslip(row) {}
  DownloadAssetform = (row) => {
    // debugger;
    const splitDate = row.name.split("_");
    const data = {
      ebId: this.props.payslipData.data.ebId,
      companyId: localStorage.getItem("companyId"),
      ebNo: this.state.ebno,
      fromDate: splitDate[0],
      toDate: splitDate[1],
      payPeriodId: row.value,
    };
    var headers = {
      Authorization:
        localStorage.getItem("token_type") +
        localStorage.getItem("access_token"),

      "X-TenantId": getTenantId(window.location.hostname),

      Accept: "application/json",

      // "Access-Control-Allow-Origin": "localhost:3000",

      "Content-Type": "application/json",
      CompanyID: 1,
    };
    fetch(serverApi.DOWNLOAD_PAYSLIP, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        var file_name =
          "payslip_" +
          data.fromDate +
          "_" +
          data.toDate +
          "_" +
          data.ebId +
          ".pdf";
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_name);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "year") {
      let date = selectedValue.split("_");
      let fromDate = date[0];
      let toDate = date[1];
      let frmDate = moment(fromDate, "YYYY-MM-DD", true).format("DD-MM-YYYY");
      let Todate = moment(toDate, "YYYY-MM-DD", true).format("DD-MM-YYYY");

      const data = {
        ebId: this.props.payslipData.data.ebId,
        companyId: localStorage.getItem("companyId"),
        fromDate: frmDate,
        toDate: Todate,
      };
      this.props.getPaySummary(serverApi.PAY_SUMMARY, data, this.props.history);
      this.setState({
        selectedDate: selectedName,
        fromDate: frmDate,
        toDate: Todate,
      });
    }
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    if (this.props.loading === true) {
    } else {
    }

    return (
      <>
        <div className="payRollData">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <InputAdormentSearch
                        imgUrl={searchIcon}
                        label="EB Number"
                        handleChange={this.handleChange("ebno")}
                        onClick={this.onClick}
                        value={this.state.ebno}
                        onKeyPress={this.handleKeyPress}
                        maxLength={15}
                        placeholder="Enter EB No."
                      />
                    </div>

                    {this.state.employee_name &&
                    this.state.loading === "block" ? (
                      <i>{this.state.employee_name}</i>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={
                          this.props.payslipData.data
                            ? this.state.financialYear
                            : []
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.selectedDate}
                        name="year"
                        label="Financial Year"
                        update={this.props.location.state ? 1 : 0}
                        required
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        {this.state.selectedDate ? (
          <Grid container spacing={2} className="LeaveRequestCardContainer">
            <Grid item xs={4} md={4}>
              <PieChart
                paySummaryList={this.state.paySummaryList}
                netcal={this.state.Netcal}
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <div
                className="payslipResultContainer"
                style={{ display: this.state.resultTable }}
              >
                <div className="gridContainer">
                  <div className="gridComponentNoBorder">
                    <Table striped className="payslipTable">
                      <thead>
                        <tr>
                          <th>Components</th>
                          <th style={{ width: "5%" }}>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.paySummaryList.data ? (
                          this.props.paySummaryList.data.length > 0 ? (
                            this.props.paySummaryList.data.map(
                              (prop, count) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{prop.name}</td>
                                      <td
                                        onClick={() =>
                                          this.DownloadAssetform(prop)
                                        }
                                      >
                                        <div class="ducumentUploadBlock">
                                          <div class="documentUploadContent">
                                            {prop.amount}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            )
                          ) : (
                            <tr>
                              <td colSpan={2} style={{ textAlign: "center" }}>
                                Data Not available
                              </td>
                            </tr>
                          )
                        ) : (
                          ""
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={4} md={4}>
              <Box>
                <div
                  className="payslipResultContainer"
                  style={{ display: this.state.resultTable }}
                >
                  <div className="gridContainer">
                    <div className="gridComponentNoBorder">
                      <Table striped className="payslipTable">
                        <thead>
                          <tr>
                            <th>Pay Period</th>
                            <th style={{ width: "5%" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.getAllpaySlips_list ? (
                            this.state.getAllpaySlips_list.length > 0 ? (
                              this.state.getAllpaySlips_list.map(
                                (prop, count) => {
                                  const splitDate = prop.name.split("_");

                                  return (
                                    <>
                                      <tr>
                                        <td>
                                          {splitDate[0] + " To " + splitDate[1]}
                                        </td>
                                        <td
                                          onClick={() =>
                                            this.DownloadAssetform(prop)
                                          }
                                        >
                                          <div class="ducumentUploadBlock">
                                            <div class="documentUploadContent">
                                              <CustomButton
                                                label={"Download"}
                                                className="documentDownload"
                                                disabled={this.state.disable}
                                                value={prop}
                                              />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td colSpan={2} style={{ textAlign: "center" }}>
                                  Data Not available
                                </td>
                              </tr>
                            )
                          ) : (
                            ""
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { payslipData, payslipAvailable, paySummaryList, loading } =
    state.PayslipReducer;
  const { acadamicYearsList } = state.acadamicYearsList;

  return {
    payslipData,
    payslipAvailable,
    acadamicYearsList,
    paySummaryList,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    paySlipsByEbNo,
    getAllpaySlips,
    getAcadamicYears,
    getPaySummary,
    paySlipsByEbNo2,
  })(Index)
);
