import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import GridArrowIcon from '../../../assets/images/gridArrowIcon.png'
import { serverApi } from '../../../helpers/Consts'
import Gridwithcustomview from '../../../components/Grid/Gridwithcustomview'
import { companyMasterList } from '../../../store/Master/CompanyMaster/actions'

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userDit: JSON.parse(localStorage.getItem('authUser')),
      companyId: localStorage.getItem("companyId"),
      companyMasterListData:[], // to display company master list data
    }
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId:localStorage.getItem("companyId"),
   }
    this.props.companyMasterList(serverApi.GET_ALL_COMPANY_MASTER_LIST, data, this.props.history)  // calling Company master list API
  }
  componentWillReceiveProps(props) {
    if (props.CompanyMasterList) {
      this.setState({
        companyMasterListData: props.CompanyMasterList.data, // updating company master list data
      })
    }
  }
  handleSearch = (data) => {

     const searchdata = {
      cipher: this.state.userDit.cipher,
      companyId:localStorage.getItem("companyId"),
      companyCode:data.companyCode===""?null:data.companyCode,
      companyName:data.companyName===""?null:data.companyName,
      
   }
    this.props.companyMasterList(serverApi.GET_ALL_COMPANY_MASTER_LIST, searchdata, this.props.history)  // calling Company master list API

     };

  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.companyMasterListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          url="/create_company_master"
          filter_form={
            "Company_Master"
          }
          handleSearch={this.handleSearch}
        />
      </>
    )
  }
}
// Company master list Headers
const Columns = [
  {
    Header: "Company Name",
    accessor: "companyName",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Company Code",
    accessor: "companyCode",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Company Address",
    accessor: "companyAddress",
    hidden: true,
    csvExport: false,
  },
]
const mapStatetoProps = (state) => {
  const {CompanyMasterList } = state.CompanyMasterReducer;  // fetching list from company master reducer
  return {CompanyMasterList};
}

export default withRouter(
  connect(mapStatetoProps, {
    companyMasterList,
  })(Index),
)
