export const JUTE_MATERIAL_RECEIPT = "jute_material_receipt_list";
export const JUTE_MATERIAL_RECEIPT_SUCCESSFULL = "jute_material_receipt_list_successfull";
export const JUTE_MATERIAL_VIEW_BYID = "jute_material_view_byid"; 
export const JUTE_MATERIAL_VIEW_BYID_SUCCESSFULL = "jute_material_view_byid_successfull"; 
export const MR_LINE_ITEMS = "mr_line_items";
export const UPDATE_JUTE_MR = "update_jute_mr";
export const UPDATE_JUTE_MR_SUCCESSFULL = "update_jute_mr_successfull";
export const JUTE_MR_HEADERDATA = "jute_mr_headerdata";
export const JUTE_MR_LINEITEMS = "jute_mr_lineitems";
export const GETQUALITY_BY_ITEMID = "getQuality_by_itemid";
export const GETQUALITY_BY_ITEMID_SUCCESSFULL = "getQuality_by_itemid_successfull"

export const JUTE_MR_UPLOAD_FILES = "jute_mr_upload_files";
export const JUTE_MR_UPLOAD_FILES_SUCCESSFULL =
  "jute_mr_upload_files_successfull";

export const GET_MR_FILES = "GET_MR_FILES";
export const GET_MR_FILES_SUCCESSFULL = "GET_MR_FILES_SUCCESSFULL";




