import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import { carriersList } from "../../../store/Master/Carriers/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      CarriersListData: [], // to display carriers list data
      dataexp: "",
    };
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
    };
    this.props.carriersList(
      serverApi.GET_ALL_CARRIERS_LIST,
      data,
      this.props.history
    ); // calling Carriers list API
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.CarriersList) {
      this.setState({
        totalRecs: props.CarriersList.totalRecords,
        CarriersListData: props.CarriersList.data, // updating carriers list data
      });
    }
  }
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const data = {
        companyId: localStorage.getItem("companyId"),
        cipher: this.state.userDit.cipher,
        startIndex: sizePerPage,
        lastIndex: currentIndex,
      };
      if (currentIndex > 0) {
        this.props.carriersList(
          serverApi.GET_ALL_CARRIERS_LIST,
          data,
          this.props.history
        ); // calling pagination api for carriers
      }
    } else {
      const data = {
        companyId: localStorage.getItem("companyId"),
        cipher: this.state.userDit.cipher,
        startIndex: currentIndex,
        lastIndex: sizePerPage,
      };
      if (currentIndex > 0) {
        this.props.carriersList(
          serverApi.GET_ALL_CARRIERS_LIST,
          data,
          this.props.history
        ); // calling Pagination api for carriers
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  render() {
    return (
      <>
        <PaginationGridView
          mdata={this.state.CarriersListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          filter_form={"Carriers"}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          url="/create__carriers"
          apiexp={serverApi.GET_ALL_CARRIERS_LIST}
          payloadexp={this.state.dataexp}
          expName={"Carriers-Master.csv"}
        />
      </>
    );
  }
}
// Carriers list Headers
const Columns = [
  {
    Header: "Carrier Code",
    accessor: "hdrId",
    hidden: false,
  },
  {
    Header: "Carrier Name",
    accessor: "carrierName",
    hidden: false,
  },
  {
    Header: "Declaration",
    accessor: "declaration",
    hidden: true,
  },
  {
    Header: "Item Group",
    accessor: "grpDsc",
    hidden: true,
  },
];
const mapStatetoProps = (state) => {
  const { CarriersList } = state.CarriersReducer; // fetching list from carriers reducer
  return { CarriersList };
};

export default withRouter(
  connect(mapStatetoProps, {
    carriersList,
  })(Index)
);
