import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi, showSpinner } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/PaginationGridView";
import {
  getIndentList,
  IndentLineItemsProps,
  ClearIndentLineItemsProps,
} from "../../../store/Purchase/Indent/actions";
import moment from "moment";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      indentListData: [],
      status: "",
      totalRecs: 0,
      startIndex: 0,
      lastIndex: 10,
      fromDate: moment().day(-7).format("YYYY-MM-DD").toString(),
      toDate: moment().format("YYYY-MM-DD").toString(),
      dataexp: "",
      branchId: "",
      projectId: "",
    };
  }
  componentDidMount() {
    const financialYearStartMonth = 3; // Assuming financial year starts in April
    let financialYearStart = moment()
      .year(localStorage.getItem("acadamicYear"))
      .month(financialYearStartMonth)
      .startOf("month");

    const fromDate = financialYearStart.day(-7).format("YYYY-MM-DD");
    const toDate = moment().format("YYYY-MM-DD");

    var startDate = moment(fromDate, "YYYY-MM-DD", true).format("DD-MM-YYYY");
    var endDate = moment(toDate, "YYYY-MM-DD", true).format("DD-MM-YYYY");
    const data = {
      startDate: startDate,
      endDate: endDate,
      status: this.state.status,
      companyId: localStorage.getItem("companyId"),
      financialYear: localStorage.getItem("acadamicYear"),
      recordType:
        this.props.location.pathname === "/bom"
          ? "BOM"
          : this.props.location.pathname === "/store_indent"
          ? "INDENT"
          : this.props.location.pathname === "/openIndent"
          ? "OPENINDENT"
          : "BOQ",
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
      projectId: "",
      branchId: "",
    };

    this.props.getIndentList(serverApi.INDENT_LIST, data, this.props.history);
    showSpinner();
    this.props.IndentLineItemsProps(null);
    this.props.ClearIndentLineItemsProps(null);
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({
      dataexp: expdata,
      fromDate: startDate,
      toDate: endDate,
    });
  }
  componentWillReceiveProps(props) {
    if (props.indentList) {
      this.setState({
        indentListData: props.indentList.data,
        totalRecs: props.indentList.totalRecords,
      });
    }
  }
  handleSearch = (data1) => {
    const IndentData = {
      branchId: data1.branchId,
      companyId: this.state.companyId,
      endDate: data1.toDate,
      financialYear: localStorage.getItem("acadamicYear"),
      projectId: data1.projctValue,
      recordType:
        this.props.location.pathname === "/openIndent"
          ? "OPENINDENT"
          : "INDENT",
      startDate: data1.fromDate,
      status: data1.statusValue,
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
      indentNum: data1.indentNum,
    };
    const BOMBOQdata = {
      startDate: data1.fromDate,
      endDate: data1.toDate,
      status: data1.statusValue,
      companyId: localStorage.getItem("companyId"),
      financialYear: localStorage.getItem("acadamicYear"),
      recordType: this.props.location.pathname === "/bom" ? "BOM" : "BOQ",
      projectId: data1.projctValue,
      branchId: data1.branchId,
      indentNum: data1.indentNum,
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
    };
    if (
      this.props.location.pathname !== "/store_indent" &&
      this.props.location.pathname !== "/openIndent"
    ) {
      const expdata = {
        ...BOMBOQdata,
        startIndex: 0,
        lastIndex: 0,
      };
      this.setState({
        dataexp: expdata,
        branchId: data1.branchId,
        projectId: data1.projctValue,
      });
      this.props.getIndentList(
        serverApi.INDENT_LIST,
        BOMBOQdata,
        this.props.history
      );
    } else {
      const expdata = {
        ...IndentData,
        startIndex: 0,
        lastIndex: 0,
      };
      this.setState({
        dataexp: expdata,
        branchId: data1.branchId,
        projectId: data1.projctValue,
      });
      this.props.getIndentList(
        serverApi.INDENT_LIST,
        IndentData,
        this.props.history
      );
    }
    this.setState({
      fromDate: data1.fromDate,
      toDate: data1.toDate,
      status: data1.statusValue,
    });
    showSpinner();
  };
  handlePagination = (page, sizePerPage, type) => {
    let startDate = this.state.fromDate;
    let endDate = this.state.toDate;
    const data = {
      startDate: startDate,
      endDate: endDate,
      status: this.state.status,
      companyId: localStorage.getItem("companyId"),
      financialYear: localStorage.getItem("acadamicYear"),
      recordType:
        this.props.location.pathname === "/bom"
          ? "BOM"
          : this.props.location.pathname === "/store_indent"
          ? "INDENT"
          : this.props.location.pathname === "/openIndent"
          ? "OPENINDENT"
          : "BOQ",
      startIndex: page - 1,
      lastIndex: sizePerPage,
      projectId: this.state.projectId,
      branchId: this.state.branchId,
    };
    this.props.getIndentList(serverApi.INDENT_LIST, data, this.props.history);
    showSpinner();
  };

  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.indentListData}
          mcolumns={
            this.props.location.pathname === "/bom"
              ? BOMColumns
              : this.props.location.pathname === "/store_indent" ||
                this.props.location.pathname === "/openIndent"
              ? IndentColumns
              : BOQColumns
          }
          micon={GridArrowIcon}
          status={true}
          actions={true}
          filter_form={
            this.props.location.pathname === "/bom"
              ? "bom"
              : this.props.location.pathname === "/store_indent"
              ? "indent"
              : this.props.location.pathname === "/openIndent"
              ? "openIndent"
              : "boq"
          }
          handleSearch={this.handleSearch}
          url={
            this.props.location.pathname === "/bom"
              ? "/create_bom"
              : this.props.location.pathname === "/store_indent"
              ? "/create_indent"
              : this.props.location.pathname === "/openIndent"
              ? "/create_openIndent"
              : "/create_boq"
          }
          totalRecs={this.state.totalRecs}
          pageno={this.state.pageno}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          apiexp={serverApi.INDENT_LIST_WITHOUT_PAGINATION}
          payloadexp={this.state.dataexp}
          expName={"Indent.csv"}
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
        />
      </>
    );
  }
}

const IndentColumns = [
  {
    Header: "Indent Id",
    accessor: "id",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Indent Number",
    accessor: "indentSquenceNo",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Financial Year",
    accessor: "fy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Record Type",
    accessor: "recordType",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Total Value",
    accessor: "totalValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Title",
    accessor: "title",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Remarks",
    accessor: "remarks  ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Internal Remarks",
    accessor: "internalRemarks",
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Source",
    accessor: "source",
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Indent Date",
    accessor: "indentDate",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Approved Date",
    accessor: "approvedDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Approved By",
    accessor: "approvedBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Rejected Date",
    accessor: "rejectedDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Rejected By",
    accessor: "rejectedBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Rejected Reasons",
    accessor: "rejectedReasons",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Is Active",
    accessor: "isActive",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Company Name",
    accessor: "companyName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Branch Name",
    accessor: "branchName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Project Name",
    accessor: "projectName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Category Name",
    accessor: "categoryName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Customer Name",
    accessor: "customerName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Store Name",
    accessor: "storeName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

  {
    Header: "LastModified By",
    accessor: "lastModifiedBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Phase Name",
    accessor: "phaseName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  // {
  //   Header: "Indent Details",
  //   accessor: "indentDetails",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: true,
  // },
  {
    Header: "Indent Status",
    accessor: "indentStatusName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Customer Id",
    accessor: "customerId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
];

const BOQColumns = [
  {
    Header: "Indent Id",
    accessor: "id",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "BOQ Number",
    accessor: "indentSquenceNo",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "BOQ Date",
    accessor: "indentDate",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Financial Year",
    accessor: "fy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Record Type",
    accessor: "recordType",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Category Name",
    accessor: "categoryName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Total Value",
    accessor: "totalValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Title",
    accessor: "title",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Remarks",
    accessor: "remarks  ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Internal Remarks",
    accessor: "internalRemarks",
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Source",
    accessor: "source",
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

  {
    Header: "Approved Date",
    accessor: "approvedDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Approved By",
    accessor: "approvedBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Rejected Date",
    accessor: "rejectedDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Rejected By",
    accessor: "rejectedBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Rejected Reasons",
    accessor: "rejectedReasons",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Is Active",
    accessor: "isActive",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Company Name",
    accessor: "companyName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Branch",
    accessor: "branchName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Project",
    accessor: "projectName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },

  {
    Header: "Customer Name",
    accessor: "customerName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Store Name",
    accessor: "storeName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

  {
    Header: "LastModified By",
    accessor: "lastModifiedBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Phase Name",
    accessor: "phaseName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  // {
  //   Header: "BOQ Details",
  //   accessor: "indentDetails",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: true,
  // },
  {
    Header: "BOQ StatusName",
    accessor: "indentStatusName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Customer Id",
    accessor: "customerId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
];

const BOMColumns = [
  {
    Header: "Indent Id",
    accessor: "id",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "BOM Number",
    accessor: "indentSquenceNo",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "BOM Date",
    accessor: "indentDate",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Financial Year",
    accessor: "fy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Record Type",
    accessor: "recordType",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Category Name",
    accessor: "categoryName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Total Value",
    accessor: "totalValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Title",
    accessor: "title",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Remarks",
    accessor: "remarks  ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Internal Remarks",
    accessor: "internalRemarks",
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Source",
    accessor: "source",
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

  {
    Header: "Approved Date",
    accessor: "approvedDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Approved By",
    accessor: "approvedBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Rejected Date",
    accessor: "rejectedDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Rejected By",
    accessor: "rejectedBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Rejected Reasons",
    accessor: "rejectedReasons",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Is Active",
    accessor: "isActive",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Company Name",
    accessor: "companyName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Branch",
    accessor: "branchName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Project",
    accessor: "projectName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },

  {
    Header: "Customer Name",
    accessor: "customerName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Store Name",
    accessor: "storeName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

  {
    Header: "LastModified By",
    accessor: "lastModifiedBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Phase Name",
    accessor: "phaseName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  // {
  //   Header: "BOM Details",
  //   accessor: "indentDetails",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: true,
  // },
  {
    Header: "BOM StatusName",
    accessor: "indentStatusName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Customer Id",
    accessor: "customerId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
];

const mapStatetoProps = (state) => {
  const { indentList } = state.Indent;
  return { indentList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getIndentList,
    IndentLineItemsProps,
    ClearIndentLineItemsProps,
  })(Index)
);
