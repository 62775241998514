import {
  PAY_REGISTER_LIST, PAY_REGISTER_LIST_SUCCESSFULL,
  BRANCH_LIST_DATA, BRANCH_LIST_DATA_SUCCESSFULL,
  PAY_PERIOD, PAY_PERIOD_SUCCESSFULL, PAY_PERIOD_BY_ID, PAY_PERIOD_BY_ID_SUCCESSFULL,
   PROCESSED_LIST, PROCESSED_LIST_SUCCESSFULL, UPDATE_PAY_PERIOD, UPDATE_PAY_PERIOD_SUCCESSFULL,
    GENERATE_INVOICE, GENERATE_INVOICE_SUCCESSFULL, GET_MONTHLY_PAYSALARY_LIST, GET_MONTHLY_PAYSALARY_LIST_SUCCESSFULL
} from './actionType'

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  payRegisterList: [],
  branchList: [],
  payPeriod: [],
  payPeriodById: [],
  processed_List: [],
  Update_PayPeriod:[],
  generateInvoice:[],
  paySalaryList: [],
  newLoader:false
};
const PayRegisterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MONTHLY_PAYSALARY_LIST:
      state = {
        ...state,
        newLoader: true,

      };
      break;
    case GET_MONTHLY_PAYSALARY_LIST_SUCCESSFULL:
      state = {
        ...state,
        paySalaryList: action.payload.response,
        newLoader: false,
      };
      break;
    case PAY_REGISTER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PAY_REGISTER_LIST_SUCCESSFULL:
      state = {
        ...state,
        payRegisterList: action.payload.response,
        loading: true,
      };
      break;
    case BRANCH_LIST_DATA:
      state = {
        ...state,
        loading: true,
      };
      break;
    case BRANCH_LIST_DATA_SUCCESSFULL:
      state = {
        ...state,
        branchList: action.payload.response,
        loading: true,
      };
      break;
    case PAY_PERIOD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PAY_PERIOD_SUCCESSFULL:
      state = {
        ...state,
        payPeriod: action.payload.response,
        loading: true,
      };
      break;
    case PAY_PERIOD_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PAY_PERIOD_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        payPeriodById: action.payload.response,
        loading: true,
      };
      break;

    case PROCESSED_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PROCESSED_LIST_SUCCESSFULL:
      state = {
        ...state,
        processed_List: action.payload.response.data,
        loading: true,
      };
      break;
      case UPDATE_PAY_PERIOD:
        state = {
          ...state,
          loading: true,
        };
        break;
      case UPDATE_PAY_PERIOD_SUCCESSFULL:
        state = {
          ...state,
         Update_PayPeriod: action.payload.response.data,
          loading: true,
        };
        break;
        case GENERATE_INVOICE:
          state = {
            ...state,
            loading: true,
          };
          break;
        case GENERATE_INVOICE_SUCCESSFULL:
          state = {
            ...state,
           generateInvoice: action.payload.response.data,
            loading: true,
          };
          break;
    default:
      state = { ...state };
      break;
  }
  return state;
}

export default PayRegisterReducer;