import React, { Component } from "react";
import { Grid, Box, Button, Tooltip } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { serverApi, serverConfig } from "../../../helpers/Consts";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import { getBranchList } from "../../../store/Global/DropDownApis/actions";
import { companyMasterList } from "../../../store/Master/CompanyMaster/actions";
import AutoMultiselect from "../../../components/Dropdown/AutoMultiselect";
import TextFieldMobile from "../../../components/TextField/TextFieldMobile";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import DeleteIcon from "../../../assets/images/picEditIcon.png";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import moment from "moment";
import swal from "sweetalert";
import { getFileDownload } from "../../../helpers/server.js";
import Fade from "@mui/material/Fade";
import {
  getFilesData,
  deleteCustomerMasterFiles,
  UploadCustomerMasterFiles,
  getAllLedgerData,
  getEntityTypeData,
} from "../../../store/Master/CustomerMaster/actions";
import { getCostCenterList } from "../../../store/Master/CostCenterMaster/actions";
import RadioButton from "../../../components/RadioButton/RadioButton";

class FillDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      alternateContactNo: this.props.customerHdr.alternateContactNo,
      cinNo: this.props.customerHdr.cinNo,
      companyId: localStorage.getItem("companyId"),
      contactNo: this.props.customerHdr.contactNo,
      contactPerson: this.props.customerHdr.contactPerson,
      createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
      customerCode: this.props.customerHdr.customerCode,
      emailId: this.props.customerHdr.emailId,
      finalStatus: this.props.customerHdr.finalStatus,
      id: this.props.customerHdr.id,
      internalCompanyId: this.props.customerHdr.internalCompanyId,
      ledgerGroup: this.props.customerHdr.ledgerGroup,
      name: this.props.customerHdr.name,
      openningBalance: this.props.customerHdr.openningBalance,
      panNo: this.props.customerHdr.panNo,
      mappedBranchIds: this.props.customerHdr.mappedBranchIds,
      createdOn: this.props.customerHdr.createdOn,
      ledgerGroupsList: [],
      companyMasterListData: [],
      Cost_Center_Data: [],
      showPan: false,
      showCin: false,
      CinFile: this.props.customerHdr.CinFile,
      PanFile: this.props.customerHdr.PanFile,
      PanFileId: this.props.customerHdr.PanFileId,
      CinFileId: this.props.customerHdr.CinFileId,
      TanFile: this.props.customerHdr.TanFile,
      TanFileId: this.props.customerHdr.TanFileId,
      Time: this.props.customerHdr.Time,
      costCenter: this.props.customerHdr.costCenter,
      ShowInValidText: this.props.customerHdr.ShowInValidText,
      showMandatoryField: this.props.customerHdr.showMandatoryField,
      PanNumberValidation: this.props.customerHdr.PanNumberValidation,
      CinNumberValidation: this.props.customerHdr.CinNumberValidation,
      tdsNo: this.props.customerHdr.tdsNo,
      tdsYes: this.props.customerHdr.tdsYes,
      entityTypeId: this.props.customerHdr.entityTypeId,
      EntityTypeRecords: [],
      tanNumber: this.props.customerHdr.tanNumber,
      TanValidation: this.props.customerHdr.TanValidation,
      chargesYes: this.props.customerHdr.chargesYes,
      chargesNo: this.props.customerHdr.chargesNo,
      sezOrExport: this.props.customerHdr.sezOrExport,//sezOrExport
      msmeValue: this.props.customerHdr.msmeValue,
      msmeRegistration: this.props.customerHdr.msmeRegistration,
      msmeFileId: this.props.customerHdr.msmeFileId,
      MsmeFile: this.props.customerHdr.MsmeFile,//msmeRegistration
      locationState: "",
      tanFileExtension: this.props.customerHdr.tanFileExtension,
      tanRetrivePath: this.props.customerHdr.tanRetrivePath,
      panFileExtension: this.props.customerHdr.panFileExtension,
      panRetrivePath: this.props.customerHdr.panRetrivePath,
      cinFileExtension: this.props.customerHdr.cinFileExtension,
      cinRetrivePath: this.props.customerHdr.cinRetrivePath,
      msmeFileExtension: this.props.customerHdr.msmeFileExtension,
      msmeRetrivePath: this.props.customerHdr.msmeRetrivePath,
      customerFiles: [],
    };
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    this.setState({
      locationState: this.props.location,
    })
    window.addEventListener("scroll", this.handleScroll);
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
    };
    this.props.companyMasterList(
      serverApi.GET_ALL_COMPANY_MASTER_LIST,
      data,
      this.props.history
    );

    const datas = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
    };
    this.props.getCostCenterList(
      serverApi.GET_ALL_COST_CENTER_LIST,
      datas,
      this.props.history
    );

    this.props.getBranchList(
      serverApi.BRANCH_LIST + this.state.companyId,
      this.props.history
    );
    this.props.getAllLedgerData(
      serverApi.GET_LEDGER_GROUPS_LIST +
      this.state.companyId +
      "/" +
      this.state.userDit.cipher,
      this.props.history
    );
    this.props.getEntityTypeData(
      serverApi.GET_ALL_ENTITIES_LIST,
      this.props.history
    )

    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData !== undefined) {
        if (
          this.state.alternateContactNo == "" &&
          this.state.cinNo == "" &&
          this.state.contactNo == "" &&
          this.state.contactPerson == "" &&
          this.state.customerCode == "" &&
          this.state.emailId == "" &&
          this.state.finalStatus == "" &&
          this.state.id == null &&
          this.state.internalCompanyId == null &&
          this.state.ledgerGroup == null &&
          this.state.name == "" &&
          this.state.openningBalance == null &&
          this.state.panNo == "" &&
          this.state.mappedBranchIds.length == 0 &&
          this.state.costCenter == "" &&
          this.state.tdsYes == true &&
          this.state.tdsNo == false
        ) {
          this.props.customerHdr.alternateContactNo =
            this.props.location.state.rowData.alternateContactNo;
          this.props.customerHdr.cinNo =
            this.props.location.state.rowData.cinNo;
          this.props.customerHdr.companyId =
            this.props.location.state.rowData.companyId;
          this.props.customerHdr.contactNo =
            this.props.location.state.rowData.contactNo;
          this.props.customerHdr.contactPerson =
            this.props.location.state.rowData.contactPerson;
          this.props.customerHdr.createdBy =
            this.props.location.state.rowData.createdBy;
          this.props.customerHdr.customerCode =
            this.props.location.state.rowData.customerCode;
          this.props.customerHdr.emailId =
            this.props.location.state.rowData.emailId;
          this.props.customerHdr.finalStatus =
            this.props.location.state.rowData.finalStatus;
          this.props.customerHdr.id = this.props.location.state.rowData.id;
          this.props.customerHdr.internalCompanyId =
            this.props.location.state.rowData.internalCompanyId;
          this.props.customerHdr.ledgerGroup =
            this.props.location.state.rowData.ledgerGroup;
          this.props.customerHdr.name = this.props.location.state.rowData.name;
          this.props.customerHdr.openningBalance =
            this.props.location.state.rowData.openningBalance;
          this.props.customerHdr.panNo =
            this.props.location.state.rowData.panNo;
          this.props.customerHdr.costCenter =
            this.props.location.state.rowData.costCenter;
          this.props.customerHdr.tdsYes =
            this.props.location.state.rowData.tds == "Y" ? true : false;
          this.props.customerHdr.tdsNo =
            this.props.location.state.rowData.tds == "N" ? true : false;
          this.props.customerHdr.entityTypeId =
            this.props.location.state.rowData.entityTypeId;
          this.props.customerHdr.tanNumber =
            this.props.location.state.rowData.tanNumber;
          this.props.customerHdr.chargesYes =
            this.props.location.state.rowData.reverseChargesApplicable == "Y" ? true : false;
          this.props.customerHdr.chargesNo =
            this.props.location.state.rowData.reverseChargesApplicable == "N" ? true : false;
          this.props.customerHdr.sezOrExport =
            this.props.location.state.rowData.sezOrExport;
          this.props.customerHdr.msmeValue =
            this.props.location.state.rowData.msmeValue;
          this.props.customerHdr.msmeRegistration =
            this.props.location.state.rowData.msmeRegistration;

          const initialMsmeValue = this.props.location.state.rowData.msmeRegistration ? "1" : "0";
          this.setState({
            alternateContactNo:
              this.props.location.state.rowData.alternateContactNo,
            cinNo: this.props.location.state.rowData.cinNo,
            companyId: this.props.location.state.rowData.companyId,
            contactNo: this.props.location.state.rowData.contactNo,
            contactPerson: this.props.location.state.rowData.contactPerson,
            createdBy: this.props.location.state.rowData.createdBy,
            customerCode: this.props.location.state.rowData.customerCode,
            emailId: this.props.location.state.rowData.emailId,
            finalStatus: this.props.location.state.rowData.finalStatus,
            id: this.props.location.state.rowData.id,
            internalCompanyId:
              this.props.location.state.rowData.internalCompanyId,
            ledgerGroup: this.props.location.state.rowData.ledgerGroup,
            name: this.props.location.state.rowData.name,
            openningBalance: this.props.location.state.rowData.openningBalance,
            panNo: this.props.location.state.rowData.panNo,
            costCenter: this.props.location.state.rowData.costCenter,

            tdsYes: this.props.location.state.rowData.tds == "Y" ? true : false,
            tdsNo: this.props.location.state.rowData.tds == "N" ? true : false,
            entityTypeId: this.props.location.state.rowData.entityTypeId,
            tanNumber: this.props.location.state.rowData.tanNumber,
            chargesYes: this.props.location.state.rowData.reverseChargesApplicable == "Y" ? true : false,
            chargesNo: this.props.location.state.rowData.reverseChargesApplicable == "N" ? true : false,
            sezOrExport: this.props.location.state.rowData.sezOrExport,
            msmeValue: initialMsmeValue, // Default to "Yes" if not provided
            msmeRegistration: this.props.location.state.rowData.msmeRegistration,

          });
          if (
            this.props.location.state.rowData.createdOn !== null &&
            this.props.location.state.rowData.createdOn !== undefined
          ) {
            var DateTime = this.props.location.state.rowData.createdOn.split(
              "T",
              2
            );
            var TodayDate = moment(DateTime[0], "YYYY-MM-DD", true).format(
              "DD-MM-YYYY"
            );
            var Time = DateTime[1].split("+")[0];
            this.setState({
              createdOn: TodayDate,
              Time: Time,
            });
            this.props.customerHdr.createdOn = TodayDate;
          }
          if (this.props.branchList) {
            if (this.props.branchList.data) {
              if (this.props.branchList.data.length !== 0) {
                var deptlist = [];
                if (this.props.location.state.rowData.mappedBranchIds) {
                  if (
                    this.props.location.state.rowData.mappedBranchIds.length !==
                    0
                  ) {
                    this.props.branchList.data.map((p, i) => {
                      this.props.location.state.rowData.mappedBranchIds.map(
                        (prop) => {
                          if (prop === p.value) {
                            deptlist.push({
                              name: p.name,
                              value: p.value,
                              label: p.label,
                            });
                          }
                        }
                      );
                    });
                    this.setState({
                      mappedBranchIds: deptlist,
                    });
                    this.props.customerHdr.mappedBranchIds = deptlist;
                  }
                }
              }
            }
          }
        }
      }
    }
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData !== undefined) {
        this.props.getFilesData(
          serverApi.GET_ALL_FILES +
          "40/" +
          this.props.location.state.rowData.id +
          "/" +
          localStorage.getItem("companyId"),
          this.props.history
        );
      }
    }
  }
  componentWillReceiveProps(props) {
    if (props.branchList) {
      if (props.branchList.data) {
        if (props.branchList.data.length !== 0) {
          var branches = props.branchList.data.filter(
            (item) => item.value !== 0
          );
          this.setState({
            branchListData: branches, // updating branch dropdown api
          });
        }
      }
    }
    if (props.ledgerGroupsList) {
      this.setState({
        ledgerGroupsList: props.ledgerGroupsList.data, // updating ledgergroups list dropdown api
      });
    }
    if (props.CompanyMasterList) {
      this.setState({
        companyMasterListData: props.CompanyMasterList.data, // updating company master list data
      });
    }

    if(this.props.location.state !== undefined){ 
    if  (props.GetAllFiles_Data) {
      if (props.GetAllFiles_Data.data) {
        if (props.GetAllFiles_Data.data.length !== 0) {
          props.GetAllFiles_Data.data.map((item) => {
            if (item.subTaskUniqueId == 401) {
              this.setState({
                PanFile: item.displayName,
                PanFileId: item.fileUploadId,
                panFileExtension: item.fileExtension,
                panRetrivePath: item.retrievalPath,

              });
              this.props.customerHdr.PanFile = item.displayName;
              this.props.customerHdr.PanFileId = item.fileUploadId;
              this.props.customerHdr.panFileExtension = item.fileExtension;
              this.props.customerHdr.panRetrivePath = item.retrievalPath;

            } else if (item.subTaskUniqueId == 402) {
              this.setState({
                CinFile: item.displayName,
                CinFileId: item.fileUploadId,
                cinFileExtension: item.fileExtension,
                cinRetrivePath: item.retrievalPath,
              });

              this.props.customerHdr.CinFile = item.displayName;
              this.props.customerHdr.CinFileId = item.fileUploadId;
              this.props.customerHdr.cinFileExtension = item.fileExtension;
              this.props.customerHdr.cinRetrivePath = item.retrievalPath;

            } else if (item.subTaskUniqueId === 403) {
              this.setState({
                MsmeFile: item.displayName,
                msmeFileId: item.fileUploadId,
                msmeFileExtension: item.msmeFileExtension,
                msmeRetrivePath: item.retrievalPath,
              });

              this.props.customerHdr.MsmeFile = item.displayName;
              this.props.customerHdr.msmeFileId = item.fileUploadId;
              this.props.customerHdr.msmeFileExtension = item.fileExtension;
              this.props.customerHdr.msmeRetrivePath = item.retrievalPath;

            } else if (item.subTaskUniqueId === 404) {
              this.setState({
                TanFile: item.displayName,
                TanFileId: item.fileUploadId,
                tanFileExtension: item.fileExtension,
                tanRetrivePath: item.retrievalPath,
              });

              this.props.customerHdr.TanFile = item.displayName;
              this.props.customerHdr.TanFileId = item.fileUploadId;
              this.props.customerHdr.tanFileExtension = item.fileExtension;
              this.props.customerHdr.tanRetrivePath = item.retrievalPath;
            }
          });
        } else {
          this.setState({
            PanFile: "",
            PanFileId: "",
            CinFile: "",
            CinFileId: "",
            MsmeFile: "",
            msmeFileId: "",
            TanFile: "",
            TanFileId: "",
            tanFileExtension: "",
            tanRetrivePath: "",
            panFileExtension: "",
            panRetrivePath: "",
            cinFileExtension: "",
            cinRetrivePath: "",
            msmeFileExtension: "",
            msmeRetrivePath: "",

          });
          this.props.customerHdr.PanFile = "";
          this.props.customerHdr.PanFileId = "";
          this.props.customerHdr.CinFile = "";
          this.props.customerHdr.CinFileId = "";
          this.props.customerHdr.MsmeFile = "";
          this.props.customerHdr.msmeFileId = "";
          this.props.customerHdr.TanFile = "";
          this.props.customerHdr.TanFileId = "";
          this.props.customerHdr.tanFileExtension = "";
          this.props.customerHdr.tanRetrivePath = "";
          this.props.customerHdr.panFileExtension = "";
          this.props.customerHdr.panRetrivePath = "";
          this.props.customerHdr.cinFileExtension = "";
          this.props.customerHdr.cinRetrivePath = "";
          this.props.customerHdr.msmeFileExtension = "";
          this.props.customerHdr.msmeRetrivePath = "";
        }
      }
    }
  }
    else {
      this.setState({
        PanFile: "",
        PanFileId: "",
        panFileExtension: "",
        panRetrivePath: "",
        TanFile: "",
        TanFileId: "",
        tanFileExtension: "",
        tanRetrivePath: "",
        MsmeFile: "",
        msmeFileId: "",
        msmeFileExtension: "",
        msmeRetrivePath: "",
        CinFile: "",
        CinFileId: "",
        cinFileExtension: "",
        cinRetrivePath: "",

      });
    }
    if (props.CostCenter_List) {
      if (props.CostCenter_List.data) {
        if (props.CostCenter_List.data.length !== 0) {
          var list = [];
          props.CostCenter_List.data.map((item) => {
            list.push({
              label: item.costDesc,
              name: item.costDesc,
              value: item.autoId,
            });
          });
          this.setState({
            Cost_Center_Data: list,
          });
        }
      }
    }
    if (props.entityTypeList) {
      if (props.entityTypeList.data) {
        if (props.entityTypeList.data.length !== 0) {
          let list = [];
          props.entityTypeList.data.map((item) => {
            list.push({
              value: item.id,
              name: item.Value,
              label: item.Value,
            });
          });
          this.setState({
            EntityTypeRecords: list,
          });

        }
      }


    }


  }//entityTypeList
  // Uplaod document api Integration
  handleselectedFile = (key, value) => (e) => {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData !== undefined) {
        e.preventDefault();
        let file = e.target.files[0];
        let fileExt = file.name.split(".").pop();
        const formData = new FormData();
        formData.append("fileName", file.name);
        formData.append("fileUpload", file);
        formData.append("fileExtension", fileExt);
        formData.append("sourceMenu", 40);
        formData.append("displayName", file.name);
        formData.append("taskUniqueId", this.props.location.state.rowData.id);
        formData.append("createdBy", this.state.userDit.userId);
        formData.append("fileCompanyId", this.state.companyId);
        formData.append("subTaskUniqueId", value);
        this.setState({
          fileName: file.name,
          fileExtension: file.name.split(".").pop(),
        });

        this.props.UploadCustomerMasterFiles(
          serverApi.UPLOADFILE,
          formData,
          this.props.history,
          this.props.location.state.rowData.id
        );

        if (key === "panUpload") {
          this.setState({
            PanFile: e.target.files[0].name,
          });
        }
        if (key === "cinUpload") {
          this.setState({
            CinFile: e.target.files[0].name,
          });
        }
        if (key === "MsmeUpload") {
          this.setState({
            MsmeFile: e.target.files[0].name,
          })
        }
        if (key === "tanUpload") {
          this.setState({
            TanFile: e.target.files[0].name,
          })
        }
      }
    }
  };
  handleDeleteFile = (key, fileUploadId) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (key === "panFile") {
          this.setState({
            PanFile: "",
            PanFileId: "",
            panFileExtension: "",
            panRetrivePath: "",

          });
          this.props.customerHdr.PanFile = "";
          this.props.customerHdr.PanFileId = "";
          this.props.customerHdr.panFileExtension = "";
          this.props.customerHdr.panRetrivePath = "";
        }
        if (key === "cinFile") {
          this.setState({
            CinFile: "",
            CinFileId: "",
            cinFileExtension: "",
            cinRetrivePath: "",

          });
          this.props.customerHdr.CinFile = "";
          this.props.customerHdr.CinFileId = "";
          this.props.customerHdr.cinFileExtension = "";
          this.props.customerHdr.cinRetrivePath = "";
        }
        if (key === "msmeFile") {
          this.setState({
            MsmeFile: "",
            msmeFileId: "",
            msmeFileExtension: "",
            msmeRetrivePath: "",

          });
          this.props.customerHdr.MsmeFile = "";
          this.props.customerHdr.msmeFileId = "";
          this.props.customerHdr.msmeFileExtension = "";
          this.props.customerHdr.msmeRetrivePath = "";
        }
        if (key === "tanFile") {
          this.setState({
            TanFile: "",
            TanFileId: "",
            tanFileExtension: "",
            tanRetrivePath: "",

          });
          this.props.customerHdr.TanFile = "";
          this.props.customerHdr.TanFileId = "";
          this.props.customerHdr.tanFileExtension = "";
          this.props.customerHdr.tanRetrivePath = "";
        }
        if (this.props.location.state !== undefined) {
          if (this.props.location.state.rowData !== undefined) {
            this.props.deleteCustomerMasterFiles(
              serverApi.DELETE_FILE_BI_ID + fileUploadId,
              this.props.history,
              this.props.location.state.rowData.id
            );
          }
        }
      } else {
        swal("Your action is canceled!");
      }
    });
  };

  // function for multi select dropdown
  getMopt = (selectedValue, name) => {
    if (name === "branchListData") {
      let depts = [];
      selectedValue.map((id) => {
        var item = { value: id.value, label: id.label, isMapped: true };
        depts.push(item);
      });
      this.setState({
        mappedBranchIds: depts,
      });
      this.props.customerHdr.mappedBranchIds = depts;
    }
  };
  // function for textfiled
  handleChange = (key) => (event) => {
    if (key == "customerCode") {
      this.props.customerHdr.customerCode = event;
      this.setState({
        customerCode: event,
      });
    }
    if (key === "name") {
      this.props.customerHdr.name = event;
      this.setState({
        name: event,
      });
    }
    if (key === "contactPerson") {
      var contactPerson = /^[A-Za-z\s]+$/;
      if (event === "" || contactPerson.test(event)) {
        this.props.customerHdr.contactPerson = event;
        this.setState({
          contactPerson: event,
        });
      }
    }

    if (key == "contactNo") {
      this.props.customerHdr.contactNo = event;
      this.setState({
        contactNo: event,
      });
    }
    if (key == "alternateContactNo") {
      this.props.customerHdr.alternateContactNo = event;
      this.setState({
        alternateContactNo: event,
      });
    }
    if (key == "emailId") {
      if (event === "") {
        this.setState({
          showMandatoryField: true,
          ShowInValidText: false,
        });
        this.props.customerHdr.showMandatoryField = true;
        this.props.customerHdr.ShowInValidText = false;
      } else {
        this.setState({
          showMandatoryField: false,
        });
        this.props.customerHdr.showMandatoryField = false;
        let exp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (exp.test(event)) {
          this.setState({
            ShowInValidText: false,
          });
          this.props.customerHdr.ShowInValidText = false;
        } else {
          this.setState({
            ShowInValidText: true,
          });
          this.props.customerHdr.ShowInValidText = true;
        }
      }

      this.props.customerHdr.emailId = event;
      this.setState({
        emailId: event,
      });
    }
    if (key == "panNo") {
      this.props.customerHdr.panNo = event;
      this.setState({
        panNo: event,
      });
      if (event !== undefined && event !== "") {
        this.setState({ showPan: !this.state.showPan });
      } else {
        this.setState({ showPan: false });
      }
      // Validation
      if (event == "") {
        this.setState({
          PanNumberValidation: false,
        });
        this.props.customerHdr.PanNumberValidation = false;
      } else {
        let exp1 = /^[A-Za-z0-9]+$/;
        if (exp1.test(event)) {
          let exp2 = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
          if (exp2.test(event)) {
            this.setState({
              PanNumberValidation: false,
            });
            this.props.customerHdr.PanNumberValidation = false;
          } else {
            this.setState({
              PanNumberValidation: true,
            });
            this.props.customerHdr.PanNumberValidation = true;
          }
        } else {
          this.setState({
            PanNumberValidation: true,
          });
          this.props.customerHdr.PanNumberValidation = true;
        }
      }
    }
    if (key === "cinNo") {
      if (event == "") {
        this.setState({
          CinNumberValidation: false,
        });
        this.props.customerHdr.CinNumberValidation = false;
      } else {
        let exp3 =
          /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;
        if (exp3.test(event)) {
          this.setState({
            CinNumberValidation: false,
          });
          this.props.customerHdr.CinNumberValidation = false;
        } else {
          this.setState({
            CinNumberValidation: true,
          });
          this.props.customerHdr.CinNumberValidation = true;
        }
      }

      if (event !== undefined && event !== "") {
        this.setState({ showCin: !this.state.showCin });
      } else {
        this.setState({ showCin: false });
      }
      this.setState({
        cinNo: event,
      });
      this.props.customerHdr.cinNo = event;
    }
    if (key == "openningBalance") {
      this.props.customerHdr.openningBalance = event;
      this.setState({
        openningBalance: event,
      });
    }
    if (key === "tanNumber") {
      if (event !== undefined && event !== "") {
        this.setState({ showTan: !this.showTan });
      } else {
        this.setState({ showTan: false });
      }
      this.props.customerHdr.tanNumber = event;
      this.setState({
        tanNumber: event,
      });
      let exp3 = /^[A-Za-z0-9 ]+$/;
      if (exp3.test(event)) {
        let exp4 = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
        if (exp4.test(event)) {
          this.setState({
            TanValidation: false,
          });
          this.props.customerHdr.TanValidation = false;
        } else {
          this.setState({
            TanValidation: true,
          });
          this.props.customerHdr.TanValidation = true;
        }
      } else {
        this.setState({
          TanValidation: true,
        });
        this.props.customerHdr.TanValidation = true;
      }
    }
    if (key === "msmeRegistration") {
      this.props.customerHdr.msmeRegistration = event;
      this.setState({
        msmeRegistration: event,
      });
    }
  };
  //function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "ledgerGroup") {
      this.props.customerHdr.ledgerGroup = selectedValue;
      this.setState({
        ledgerGroup: selectedValue,
      });
    }
    if (name === "internalCompany") {
      this.props.customerHdr.internalCompanyId = selectedValue;
      this.setState({
        internalCompanyId: selectedValue,
      });
    }
    if (name === "finalStatus") {
      this.props.customerHdr.finalStatus = selectedValue;
      this.setState({
        finalStatus: selectedValue,
      });
    }
    if (name === "costCenter") {
      this.props.customerHdr.costCenter = selectedValue;
      this.setState({
        costCenter: selectedValue,
      });
    }
    if (name === "entityTypeId") {
      this.props.customerHdr.entityTypeId = selectedValue;
      this.setState({
        entityTypeId: selectedValue,
      })
    }

    if (name === "sezOrExport") {
      this.props.customerHdr.sezOrExport = selectedValue;
      this.setState({
        sezOrExport: selectedValue,
      })
    }
    if (name === "msmeValue") {
      this.props.customerHdr.msmeValue = selectedValue;
      this.setState({
        msmeValue: selectedValue,
      })
    }
  };
  TdshandleChange = (activeValue) => {
    let activeYes = false,
      activeNo = false;
    if (activeValue === "Yes") {
      this.props.customerHdr.tdsYes = true;
      this.props.customerHdr.tdsNo = false;
      activeYes = true;
    } else if (activeValue === "No") {
      this.props.customerHdr.tdsNo = true;
      this.props.customerHdr.tdsYes = false;
      activeNo = true;
    }
    this.setState({
      tdsYes: activeYes,
      tdsNo: activeNo,
    });
  };
  ReverseCharges = (activeValue) => {
    let activeYes = false,
      activeNo = false;
    if (activeValue === "Yes") {
      this.props.customerHdr.chargesYes = true;
      this.props.customerHdr.chargesNo = false;
      activeYes = true;
    } else if (activeValue === "No") {
      this.props.customerHdr.chargesNo = true;
      this.props.customerHdr.chargesYes = false;
      activeNo = true;
    }
    this.setState({
      chargesYes: activeYes,
      chargesNo: activeNo,
    })
  }


  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div className="SupplierMasterContainer">
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 3">
                      <TextFieldReq
                        value={this.state.customerCode}
                        caps="OFF"
                        onChange={this.handleChange("customerCode")}
                        label="Customer Code"
                      />
                    </Box>
                  )}
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.EntityTypeRecords}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.entityTypeId}
                        update={this.state.entityTypeId ? 1 : 0}
                        name="entityTypeId"
                        label="Entity Type"
                        required
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.name}
                      caps="OFF"
                      onChange={this.handleChange("name")}
                      label="Name"
                      disabled={this.state.entityTypeId === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.contactPerson}
                      onChange={this.handleChange("contactPerson")}
                      label="Contact Person"
                      disabled={this.state.entityTypeId === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldMobile
                      value={this.state.contactNo}
                      caps="OFF"
                      onChange={this.handleChange("contactNo")}
                      label="Contact Number"
                      disabled={this.state.entityTypeId === "" ? true : false}
                    />
                  </Box>
                  {/* <Box gridColumn="span 3">
                    <TextFieldMobile
                      value={this.state.alternateContactNo}
                      caps="OFF"
                      onChange={this.handleChange("alternateContactNo")}
                      label="Alternate Contact Number"
                    />
                  </Box> */}
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.emailId}
                      onChange={this.handleChange("emailId")}
                      label="Email Id"
                      disabled={this.state.name === "" ? true : false}
                    />
                    {this.state.ShowInValidText && (
                      <div className="TextFieldBlock">
                        <span className="text-danger">
                          Invalid Email Address
                        </span>
                      </div>
                    )}
                  </Box>


                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <br />

        <br />
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>

                <Box gridColumn="span 3">
                  <TextFieldNormal
                    value={this.state.tanNumber}
                    caps="ON"
                    minValue="0"
                    onChange={this.handleChange("tanNumber")}
                    label="TAN Number"
                    disabled={this.state.name === "" ? true : false}
                  />
                  {this.state.TanValidation && (
                    <div className="TextFieldBlock">
                      <span className="text-danger">Invalid TAN Number</span>
                    </div>
                  )}
                </Box>
                <Box gridColumn="span 3">
                  {/* {this.state.customerFiles &&
                    this.state.customerFiles.map((item)=>( */}
                  <div className="supplierDocumentUpload">
                    <div className="ducumentUploadBlock">
                      <div className="documentUploadContent">
                        {this.state.TanFile === "" ? (
                          <span
                            className={
                              this.state.locationState.state !== undefined
                                ? "btn_upload documentUpload "
                                : "btn_upload documentUpload disabled"
                            }
                          >
                            <img src={panUploadIcon} alt="" />
                            <input
                              type="file"
                              id="imag"
                              title=""
                              className="input-img"
                              onChange={this.handleselectedFile(
                                "tanUpload",
                                404
                              )}
                              disabled={
                                this.state.locationState.state !== undefined
                                  ? false
                                  : true
                              }
                            />
                            <p> Upload Document</p>
                          </span>
                        ) : (
                          ""
                        )}
                        {this.state.TanFile !== "" && (
                          <div className="uploadedInfo">
                            <img
                              src={TickIcon}
                              alt=""
                              className="tickIcon"
                            />

                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Click to download File"
                            >
                              <div
                                onClick={() => {
                                  getFileDownload(
                                    serverConfig.SERVER_URL +
                                    "security-api/api/files/downloadfile/" +
                                    this.state.TanFileId,
                                    this.state.TanFile,
                                    this.state.tanFileExtension
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {/* {this.state.fileName} */}
                                {this.state.TanFile}
                              </div>

                            </Tooltip>

                            <Button
                              onClick={() => {
                                this.handleDeleteFile(
                                  "tanFile",
                                  this.state.TanFileId
                                );
                              }}
                              className="deleteButton"
                            >
                              <img src={DeleteIcon} alt="" />
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 6"></Box>
                <Box gridColumn="span 3">
                  <TextFieldNormal
                    value={this.state.panNo}
                    caps="ON"
                    minValue="0"
                    maxlength="10"
                    onChange={this.handleChange("panNo")}
                    label="PAN Number"
                    required
                    disabled={this.state.name === "" ? true : false}
                  />
                  {this.state.PanNumberValidation && (
                    <div className="TextFieldBlock">
                      <span className="text-danger">Invalid PAN Number</span>
                    </div>
                  )}
                </Box>

                <Box gridColumn="span 3">
                  <div className="supplierDocumentUpload">
                    <div className="ducumentUploadBlock">
                      <div className="documentUploadContent">
                        {this.state.PanFile === "" ? (
                          <span
                            className={
                              this.state.locationState.state !== undefined
                                ? "btn_upload documentUpload "
                                : "btn_upload documentUpload disabled"
                            }
                          >
                            <img src={panUploadIcon} alt="" />
                            <input
                              type="file"
                              id="imag"
                              title=""
                              className="input-img"
                              onChange={this.handleselectedFile(
                                "panUpload",
                                401
                              )}
                              disabled={
                                this.state.locationState.state !== undefined
                                  ? false
                                  : true
                              }
                            />
                            <p> Upload Document</p>
                          </span>
                        ) : (
                          ""
                        )}
                        {this.state.PanFile !== "" && (
                          <div className="uploadedInfo">
                            <img
                              src={TickIcon}
                              alt=""
                              className="tickIcon"
                            />

                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Click to download File"
                            >
                              <div
                                onClick={() => {
                                  getFileDownload(
                                    serverConfig.SERVER_URL +
                                    "security-api/api/files/downloadfile/" +
                                    this.state.PanFileId,
                                    this.state.PanFile,
                                    this.state.panFileExtension
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {/* {this.state.fileName} */}
                                {this.state.PanFile}
                              </div>

                            </Tooltip>

                            <Button
                              onClick={() => {
                                this.handleDeleteFile(
                                  "panFile",
                                  this.state.PanFileId
                                );
                              }}
                              className="deleteButton"
                            >
                              <img src={DeleteIcon} alt="" />
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Box>

                <Box gridColumn="span 6"></Box>
                <Box gridColumn="span 3">
                  <TextFieldNormal
                    value={this.state.cinNo}
                    caps="OFF"
                    minValue="0"
                    onChange={this.handleChange("cinNo")}
                    label="CIN Number"
                    disabled={this.state.name === "" ? true : false}
                  />
                  {this.state.CinNumberValidation && (
                    <div className="TextFieldBlock">
                      <span className="text-danger">Invalid CIN Number</span>
                    </div>
                  )}
                </Box>
                {/* {this.state.locationState.state && ( */}
                <Box gridColumn="span 3">
                  <div className="supplierDocumentUpload">
                    <div className="ducumentUploadBlock">
                      <div className="documentUploadContent">
                        {this.state.CinFile === "" ? (
                          <span
                            className={
                              this.state.locationState.state !== undefined
                                ? "btn_upload documentUpload "
                                : "btn_upload documentUpload disabled"
                            }
                          >
                            <img src={panUploadIcon} alt="" />
                            <input
                              type="file"
                              id="imag"
                              title=""
                              className="input-img"
                              onChange={this.handleselectedFile(
                                "cinUpload",
                                402
                              )}
                              disabled={
                                this.state.locationState.state !== undefined
                                  ? false
                                  : true
                              }
                            />
                            Upload Document
                          </span>
                        ) : (
                          ""
                        )}
                        {this.state.CinFile !== "" && (
                          <div className="uploadedInfo">
                            <img
                              src={TickIcon}
                              alt=""
                              className="tickIcon"
                            />
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Click to download File"
                            >
                              <div
                                onClick={() => {
                                  getFileDownload(
                                    serverConfig.SERVER_URL +
                                    "security-api/api/files/downloadfile/" +
                                    this.state.CinFileId,
                                    this.state.CinFile,
                                    this.state.cinFileExtension
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {/* {this.state.fileName} */}
                                {this.state.CinFile}
                              </div>

                            </Tooltip>

                            <Button
                              onClick={() => {
                                this.handleDeleteFile(
                                  "cinFile",
                                  this.state.CinFileId
                                );
                              }}
                              className="deleteButton"
                            >
                              <img src={DeleteIcon} alt="" />
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Box>
                {/* )} */}

              </Box>
            </Grid>
          </Grid>
        </div>
        <br />

        <br />
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>

                <Box gridColumn="span 3">
                  <div
                    className="consoleTextFieldBlock"
                    style={{ paddingTop: "5px" }}
                  >
                    <label>Reverse Charges Applicable</label>
                    <div className="taxPayableContent">
                      <RadioButton
                        value={this.state.chargesYes}
                        labelText="Yes"
                        checkednew={this.state.chargesYes}
                        onChange={this.ReverseCharges}
                      />
                      <RadioButton
                        value={this.state.chargesNo}
                        checkednew={this.state.chargesNo}
                        onChange={this.ReverseCharges}
                        labelText="No"
                      />
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={
                        this.state.companyMasterListData &&
                        this.state.companyMasterListData.length !== 0 &&
                        this.state.companyMasterListData.map((item) => ({
                          label: item.companyName,
                          value: item.companyId,
                          name: item.companyName,
                        }))
                      }
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.internalCompanyId}
                      update={this.state.internalCompanyId ? 1 : 0}
                      name="internalCompany"
                      label="Internal Company"
                      isDisabled={this.state.name === "" ? true : false}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={FinalStatus}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.finalStatus}
                      update={this.state.finalStatus ? 1 : 0}
                      name="finalStatus"
                      label="Final Status"
                      required
                      isDisabled={this.state.panNo === "" ? true : false}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <TextFieldNormal
                    value={this.state.openningBalance}
                    caps="OFF"
                    onChange={this.handleChange("openningBalance")}
                    label="Opening Balance"
                    disabled={true}
                    rightAlign="right"
                  />
                </Box>

                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      update={this.state.sezOrExport ? 1 : 0}
                      arrayOfData={ExportData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.sezOrExport}
                      name="sezOrExport"
                      label="SEZ/Export"
                      isDisabled={this.state.panNo === "" ? true : false}
                    />
                  </div>
                </Box>

                <Box gridColumn="span 3">
                  <TextFieldNormal
                    value={this.state.msmeRegistration}
                    caps="ON"
                    type="text"
                    onChange={this.handleChange("msmeRegistration")}
                    label="MSME Registration No"
                    disabled={this.state.panNo === "" ? true : false}
                  />
                </Box>

                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={msmedata}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.msmeValue || (this.state.msmeRegistration ? "1" : "2")}
                      update={this.state.msmeValue ? 1 : 0}
                      name="msmeValue"
                      label="MSME Certified"
                      isDisabled={this.state.panNo === "" ? true : false}
                    />
                  </div>
                </Box>


                {this.state.msmeValue === "1" && this.state.locationState.state && (
                  <Box gridColumn="span 3">
                    <div className="supplierDocumentUpload">
                      <div className="ducumentUploadBlock">
                        <div className="documentUploadContent">
                          {this.state.MsmeFile === "" ? (
                            <span
                              className={
                                this.state.locationState.state !== undefined
                                  ? "btn_upload documentUpload "
                                  : "btn_upload documentUpload disabled"
                              }
                            >
                              <img src={panUploadIcon} alt="" />
                              <input
                                type="file"
                                id="imag"
                                title=""
                                className="input-img"
                                onChange={this.handleselectedFile("MsmeUpload", 403)}
                                disabled={
                                  this.state.locationState.state !== undefined
                                    ? false
                                    : true
                                }
                              />
                              Upload Document
                            </span>
                          ) : (
                            ""
                          )}
                          {this.state.MsmeFile !== "" && (
                            <div className="uploadedInfo">
                              <img src={TickIcon} alt="" className="tickIcon" />
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title="Click to download File"
                              >
                                <div
                                  onClick={() => {
                                    getFileDownload(
                                      serverConfig.SERVER_URL +
                                      "security-api/api/files/downloadfile/" +
                                      this.state.msmeFileId,
                                      this.state.MsmeFile,
                                      this.state.msmeFileExtension
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {/* {this.state.fileName} */}
                                  {this.state.MsmeFile}
                                </div>

                              </Tooltip>

                              <Button
                                onClick={() => {
                                  this.handleDeleteFile("msmeFile", this.state.msmeFileId);
                                }}
                                className="deleteButton"
                              >
                                <img src={DeleteIcon} alt="" />
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Box>
                )}

                {this.state.locationState.state === undefined ? (
                  ""
                ) : (
                  <>
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdOn} </b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
const FinalStatus = [
  { label: "Yes", value: "Y", name: "Yes" },
  { label: "No", value: "N", name: "No" },
];

const ExportData = [
  {
    value: 1,
    label: "Not Applicable",
    name: "Not Applicable"

  },
  {
    value: 2,
    label: "Exports",
    name: "Exports"

  },
  {
    value: 3,
    label: "Deemed Exports",
    name: "Deemed Exports"

  },

  {
    value: 4,
    label: "SEZ",
    name: "SEZ"

  }
]

const msmedata = [
  {
    value: "1",
    label: "Yes",
    name: "Yes"
  },
  {
    value: "2",
    label: "No",
    name: "No"
  },
]

const mapStatetoProps = (state) => {
  const { GetAllFiles_Data, ledgerGroupsList, entityTypeList } = state.CustomerListReducer;
  const { branchList } = state.DropDownListReducer;
  const { CompanyMasterList } = state.CompanyMasterReducer;
  const { payslipData } = state.PayslipReducer;
  const { CostCenter_List } = state.CostCenterListReducer;

  return {
    GetAllFiles_Data,
    ledgerGroupsList,
    CompanyMasterList,
    branchList,
    payslipData,
    CostCenter_List,
    entityTypeList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getBranchList,
    getAllLedgerData,
    UploadCustomerMasterFiles,
    companyMasterList,
    getFilesData,
    deleteCustomerMasterFiles,
    getCostCenterList,
    getEntityTypeData,
  })(FillDetails)
);
