import {
  BRANCH_MASTER_LIST,
  CREATE_BRANCH_MASTER,
  DELETE_BRANCH_FILES,
  GET_ALL_FILES,
  UPDATE_BRANCH_MASTER,
  UPLOAD_BRANCH_FILES,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { postAdd, getList } from "../../../helpers/Server_Helper";
import { serverApi } from "../../../helpers/Consts";
import {
  UploadBranchMasterFilesSuccessfull,
  branchMasterListSuccessfull,
  createBranchMasterSuccessfull,
  deleteBranchMasterFilesSuccessfull,
  getBranchFiles,
  getBranchFilesSuccessfull,
  updateBranchMasterSuccessfull,
} from "./actions";
import { BillingAdsShippingAds } from "../../../store/Global/DropDownApis/actions";
import swal from "sweetalert";

function* BranchMasterList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(branchMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateBranchMaster({ payload: { url, data, isPOandWO, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createBranchMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      const branchData = {
        cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
        companyId: localStorage.getItem("companyId"),
      };
      yield put(
        BillingAdsShippingAds(
          serverApi.GET_BILLING_ADDRESS_SHIPPING_ADDRESS,
          branchData,
          history
        )
      );
      if (!isPOandWO) {
        history.push("/branch_master");
      }
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateBranchMaster({
  payload: { url, data, history, isPOWOActive },
}) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateBranchMasterSuccessfull({ response }));
    if (response.data.status === true) {
      if (!isPOWOActive) {
        swal(response.data.message, { icon: "success" });
        history.push("/branch_master");
      }
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* getAllBranchFiles({ payload: { url, history } }) {
  try {
    const response = yield call(getList, url);
    yield put(getBranchFilesSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* deleteBranchFiles({ payload: { url, history, id } }) {
  try {
    const response = yield call(getList, url);
    yield put(deleteBranchMasterFilesSuccessfull({ response }));
    if (response) {
      swal("Deleted Successfully", { icon: "success" });
      let Url =
        serverApi.GET_ALL_FILES +
        "50/" +
        id +
        "/" +
        localStorage.getItem("companyId");
      yield put(getBranchFiles(Url, history));
    }
  } catch (error) {
    console.log(error);
  }
}
function* UploadBranchMasterFilesData({ payload: { url, data, history, id } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(UploadBranchMasterFilesSuccessfull({ response }));
    if (response) {
      swal("Uploaded Successfully", { icon: "success" });
      let Url =
        serverApi.GET_ALL_FILES +
        "50/" +
        id +
        "/" +
        localStorage.getItem("companyId");
      yield put(getBranchFiles(Url, history));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchBranchMasterList() {
  yield takeEvery(BRANCH_MASTER_LIST, BranchMasterList);
}
export function* watchCreateBranchMaster() {
  yield takeEvery(CREATE_BRANCH_MASTER, CreateBranchMaster);
}
export function* watchUpdateBranchMaster() {
  yield takeEvery(UPDATE_BRANCH_MASTER, UpdateBranchMaster);
}
export function* watchBranchFilesData() {
  yield takeEvery(GET_ALL_FILES, getAllBranchFiles);
}
export function* watchDeleteBranchfiles() {
  yield takeEvery(DELETE_BRANCH_FILES, deleteBranchFiles);
}
export function* watchBranchFilesUpload() {
  yield takeEvery(UPLOAD_BRANCH_FILES, UploadBranchMasterFilesData);
}

function* BranchMasterSaga() {
  yield all([
    fork(watchBranchMasterList),
    fork(watchCreateBranchMaster),
    fork(watchUpdateBranchMaster),
    fork(watchBranchFilesData),
    fork(watchDeleteBranchfiles),
    fork(watchBranchFilesUpload),
  ]);
}

export default BranchMasterSaga;
