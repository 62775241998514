import {ADD_LEAVE_TRANSACTION, LEAVE_LEDGERLIST, LEAVE_REQUEST_LIST, UPDATE_LEAVE_REQUEST, LEAVE_REQUEST_VIEW_BY_ID, LEAVE_LEDGERLIST_FOR_EMPLOYEE, VIEW_BY_ID_FOR_LEAVEREQUEST, LEAVE_TYPE_SELECTION, WORKER_DATA_BY_EB_NO} from './actionType';
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {leaveRequestListSuccessfull,LeaveLedgerSuccessfullList,addLeaveTransactionSuccessfull,
    updateLeaveSuccessfull, LeaveViewByIdListSuccessfull,getLeaveLedgerForEmployeeSuccessfull,
    getViewByIdForLeaveRequestSuccessfull,leaveTypeSelectionSuccessfull,
    getWorkerDataByEbNoSuccessfull,
    getWorkerDataByEbNo,
    getLeaveLedgerList
} from './actions'
import {getList, getListurl, postAdd} from '../../../helpers/Server_Helper';
import swal from "sweetalert";
import { hideSpinner, serverApi, showSpinner } from "../../../helpers/Consts";


function* getLeaveRequestList({payload:{url,data,history}}) {
    try{
        const response = yield call(getList,url,data);
        yield put(leaveRequestListSuccessfull({response}))
    }catch(error){
        console.log(error)
        if (error) {
            if (error.response) {
              if (error.response.data) {
                swal(error.response.data.error,{icon:"error"});
              }
            }
          }
    }
}
function* leaveLedgerList({payload:{url,history}}) {
    try{
        const response = yield call(getListurl,url);
        yield put(LeaveLedgerSuccessfullList({response}))
        // yield put (getWorkerDataByEbNoSuccessfull({Value}))
    }catch(error){
        console.log(error)
    }
}
function* leaveViewByIdList({payload:{url,history}}) {
    try{
        const response = yield call(getListurl,url);
        yield put(LeaveViewByIdListSuccessfull({response}))
        if(response.data){
            yield put( getWorkerDataByEbNo(serverApi.WORKER_LIST + response.data.ebNo+"/" +localStorage.getItem("companyId") +  "/" +JSON.parse(localStorage.getItem("authUser")).userId,history))
        }
    }catch(error){
        console.log(error)
        if (error) {
            if (error.response) {
              if (error.response.data) {
                swal(error.response.data.error,{icon:"error"});
              }
            }
          }
    }
}

function* leaveLedgerListForEmployee({payload:{url,history}}) {
    try{
        const response = yield call(getListurl,url);
        yield put(getLeaveLedgerForEmployeeSuccessfull({response}))
    }catch(error){
        console.log(error)
    }
}
function* ViewByIdForLeaveRequest({payload:{url,history}}) {
    try{
        const response = yield call(getListurl,url);
        yield put(getViewByIdForLeaveRequestSuccessfull({response}))
    }catch(error){
        console.log(error)
        // WORKER_LIST
    }
}
function* getLeaveTypeSelection({payload:{url,history}}) {
    try{
        const response = yield call(getListurl,url);
        yield put(leaveTypeSelectionSuccessfull({response}))
    }catch(error){
        console.log(error)
        // WORKER_LIST
    }
}

function* AddLeaveTransaction({payload:{url,data,history}}) {
    try{
        showSpinner()
        const response = yield call(postAdd,url,data);
        if(response){
            hideSpinner()
        if(response.data.status === true) {
            yield put(addLeaveTransactionSuccessfull({response}))
        swal(response.data.message, { icon: "success" })
        history.push('/leaverequest')
        }else {
        swal(response.data.message, { icon: "error" })
        }
    }
    }catch(error){
        hideSpinner()
        console.log(error)
        if (error) {
            if (error.response) {
              if (error.response.data) {
                swal(error.response.data.error,{icon:"error"});
              }
            }
          }
    }
}
function* updateLeave({payload:{url,data,history}}) {
    try{
        showSpinner()
        const response = yield call(postAdd,url,data);
        if(response){
            hideSpinner()
        if(response.data.status === true) {
            yield put(updateLeaveSuccessfull({response}))
            swal(response.data.message, { icon: "success" })
            history.push('/leaverequest')
        }else {
            swal(response.data.message, { icon: "error" })

        }
    }
    }catch(error){
        hideSpinner()
        console.log(error)
        if (error) {
            if (error.response) {
              if (error.response.data) {
                swal(error.response.data.error,{icon:"error"});
              }
            }
          }
    }
}
function* getWorkerByEbNo({payload:{url,callback}}) {
    try{
        const response = yield call(getListurl,url);
        // const res = yield call(getCallBackGetData, url, callback);
        yield put(getWorkerDataByEbNoSuccessfull({response}))
          if (response.data) {
        let ebId = response.data.data.ebId;
        var userDit = JSON.parse(localStorage.getItem("authUser"));
        yield put(
          getLeaveLedgerList(
            serverApi.LEAVE_LEDGER_LIST + ebId + "/" + userDit.userId,
            null
          )
        );
      }
    }catch(error){
        console.log(error)
        if (error) {
            if (error.response) {
              if (error.response.data) {
                swal(error.response.data.error,{icon:"error"});
              }
            }
          }
    }
}
export function* watchLeaveRequestList () {
    yield takeEvery(LEAVE_REQUEST_LIST,  getLeaveRequestList)
    yield takeEvery(LEAVE_LEDGERLIST,  leaveLedgerList)
    yield takeEvery(ADD_LEAVE_TRANSACTION,  AddLeaveTransaction)
    yield takeEvery(UPDATE_LEAVE_REQUEST,  updateLeave)
    yield takeEvery(LEAVE_REQUEST_VIEW_BY_ID, leaveViewByIdList)
    yield takeEvery(LEAVE_LEDGERLIST_FOR_EMPLOYEE,leaveLedgerListForEmployee)
    yield takeEvery(VIEW_BY_ID_FOR_LEAVEREQUEST,ViewByIdForLeaveRequest)
    yield takeEvery(LEAVE_TYPE_SELECTION,getLeaveTypeSelection)
    yield takeEvery(WORKER_DATA_BY_EB_NO,getWorkerByEbNo)
}

function* LeaveRequestSaga() {
    yield all([
        fork(watchLeaveRequestList )

    ])
}

export default  LeaveRequestSaga;