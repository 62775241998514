/* Created By Nagesh Medisetty on 19-04-2022 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import moment from "moment";
import { getTaxMasterList } from "../../../store/Master/TaxMaster/actions";

class TaxMasterFillDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      taxName: "",
      taxPercentage: "",
      createdBy: null,
      createdOn: "",
      createdDate: "",
      taxListData: [], // To displaying Tax list data
    };
  }

  handleChange = (key) => (event) => {
    this.setState({ [key]: event });

    if (key === "taxName") {
      this.setState({
        taxName: event,
      });
    }

    if (key === "taxPercentage") {
      if (
        (/^[0-9]+(\.[0-9]{0,2})?$/.test(event) || event === "") &&
        event <= 100
      ) {
        this.setState({
          taxPercentage: event,
        });
      }
    }
  };

  componentDidMount() {}
  componentWillReceiveProps(props) {
    if (props.location.state !== undefined) {
      props.TaxMasterFill_Details.taxName =
        this.props.location.state.rowData.taxName;
      props.TaxMasterFill_Details.taxPercentage =
        this.props.location.state.rowData.taxPercentage;
      this.setState({
        taxName: this.props.location.state.rowData.taxName,
        taxPercentage: this.props.location.state.rowData.taxPercentage,
        createdBy: this.props.location.state.rowData.createdBy,
        createdOn: this.props.location.state.rowData.createdOn,
      });
      const dateEntered = this.props.location.state.rowData.createdOn;
      const dateIsValid = moment(dateEntered).format("LLLL");
      this.setState({
        createdDate: dateIsValid,
      });
    }
  }

  render() {
    return (
      <div className="consoleFormContainer">
        <h5>Fill Details</h5>
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldReq
                      value={this.state.taxName}
                      label="Tax Name"
                      caps="OFF"
                      onChange={this.handleChange("taxName")}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldReq
                      type="number"
                      value={this.state.taxPercentage}
                      label="Tax percentage"
                      caps="OFF"
                      minValue="0"
                      onChange={this.handleChange("taxPercentage")}
                      disabled={this.state.taxName === "" ? true : false}
                    />
                  </div>
                </Box>
                {this.props.location.state === undefined ? (
                  ""
                ) : (
                  <>
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdDate}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Tax_master_List } = state.Tax_master_List; // Fetching list from tax master list
  return {
    ...state.Layout,
    Tax_master_List,
  };
};
export default connect(mapStatetoProps, {
  getTaxMasterList,
})(withRouter(TaxMasterFillDetails));
