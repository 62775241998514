import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import { getBranchList } from "../../../store/Global/DropDownApis/actions";
import { serverApi } from "../../../helpers/Consts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  RequestOtp,
  SubmitGSTOtp,
  UpdateClientCredentials,
} from "../../../store/GST_module/InvoiceCredentials/actions";

const CredentialsComponent = () => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [showEInvoice, setShowEInvoice] = useState(false);
  const [showGST, setShowGST] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [branchId, setBranch] = useState("");
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [branchListData, setbranchListData] = useState([]);
  const [gstUsername, setGstUsername] = useState("");
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    dispatch(
      getBranchList(
        serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
        history
      )
    );
  }, []);
  const { branchList } = useSelector((state) => state.DropDownListReducer);
  useEffect(() => {
    if (branchList) {
      if (branchList.data) {
        if (branchList.data.length !== 0) {
          let List = branchList.data.filter((item) => item.value !== 0);
          setbranchListData(List);
        }
      }
    }
  }, [branchList]);

  const handleShowEInvoice = () => {
    setShowEInvoice(true);
    setShowGST(false);
    setActiveTab("eInvoice");
  };

  const handleShowGST = () => {
    setShowGST(true);
    setShowEInvoice(false);
    setActiveTab("gst");
  };

  


const handleUpdateEInvoice = () => {
  if (!email || !password || !branchId) {
    setErrorMessage("Please fill in all mandatory fields.");
    swal("Please fill in all mandatory fields.");
    return;
  }

  // Perform update action
  const Credentials = {
    eInvoiceUserName: email,
    eInvoicePassword: password,
    referenceBranchId: branchId,
    isActive: 1,
    userExists: "N"
  };

  const callback = (response) => {
    console.log(response,"response")
    if (response.message === "UserName Already Exists") {
      swal({
        text: "The username already exists. Do you want to continue?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willContinue) => {
        if (willContinue) {
          Credentials.userExists = "Y";
          dispatch(UpdateClientCredentials(serverApi.UPDATE_CLIENT_CREDENTIALS, Credentials, history));
        } else {
          setErrorMessage("Update canceled by user.");
        }
      });
    } else {
      setErrorMessage("");
      setEmail("");
      setPassword("");
      setBranch("");
    }
  };

  dispatch(UpdateClientCredentials(serverApi.UPDATE_CLIENT_CREDENTIALS, Credentials, history, callback));
};

  const handleRequestOTP = () => {
    if (!gstUsername) {
      swal("Please fill in the username to request OTP.");
      return;
    }
    let PayloadForOtp = {
      companyId: localStorage.getItem("companyId"),
      gstrUserName: gstUsername,
      referenceBranchId: branchId,
    };
    dispatch(RequestOtp(serverApi.REQUEST_OTP, PayloadForOtp, history));
    // Perform OTP request action
  };

  const handleSubmitOTP = () => {
    if (!gstUsername || !otp) {
      setErrorMessage("Please fill in all mandatory fields.");
      swal("Please fill in all mandatory fields.");
      return;
    }
    // Perform OTP submission action
    let submitOtpPayload = {
      otp: otp,
      referenceBranchId: branchId,
      gstrTransactionId: "",
      gstrUserName: gstUsername,
    };
    dispatch(SubmitGSTOtp(serverApi.SUBMIT_GST_CREDENTIALS_OTP,submitOtpPayload,history));
  };

  const handlePropSelectChange = (selectedValue, selectedName, name) => {
    console.log(selectedValue, selectedName, name);
    if (name === "branch") {
      setBranch(selectedValue);
    }
  };
  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };
  return (
    <div className="credentials-container">
      <div className="buttons-container">
        <button
          className={`gst_button ${activeTab === "eInvoice" ? "active" : ""}`}
          onClick={handleShowEInvoice}
        >
          e-invoice credentials
        </button>
        <button
          className={`gst_button ${activeTab === "gst" ? "active" : ""}`}
          onClick={handleShowGST}
        >
          GST credentials
        </button>
      </div>

      {showEInvoice && (
        <div className="credentials-card">
          <div className="card-header">e-Invoice Credentials</div>
          <div className="card-body">
            <label htmlFor="email" className="label-input-group">
              Email<span className="mandatory">*</span>
            </label>
            <input
              id="email"
              className="gst_input_fields"
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="password" className="label-input-group">
              Password<span className="mandatory">*</span>
            </label>
            <input
              id="password"
              className="gst_input_fields"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="consoleTextFieldBlock">
              <DynamicSelect
                update={branchId ? 1 : 0}
                arrayOfData={branchListData}
                className="dropdownBlockContainer"
                menuPosition="fixed"
                menuPlacement="auto"
                onSelectChange={handlePropSelectChange}
                handleMenuOpen={handleMenuOpen}
                isDropdownOpen={isDropdownOpen}
                selected={branchId}
                label="Branch"
                name="branch"
                required={true}
              />
            </div>
          </div>
          <button className="update-button" onClick={handleUpdateEInvoice}>
            Update
          </button>
        </div>
      )}

      {showGST && (
        <div className="credentials-card">
          <div className="card-header">GST Credentials</div>
          <div className="card-body">
            <label htmlFor="gst-username" className="label-input-group">
              Username<span className="mandatory">*</span>
            </label>
            <input
              id="gst-username"
              className="gst_input_fields"
              type="text"
              placeholder="Username"
              value={gstUsername}
              onChange={(e) => setGstUsername(e.target.value)}
            />
            <div className="consoleTextFieldBlock">
              <DynamicSelect
                update={branchId ? 1 : 0}
                arrayOfData={branchListData}
                className="dropdownBlockContainer"
                menuPosition="fixed"
                menuPlacement="auto"
                onSelectChange={handlePropSelectChange}
                handleMenuOpen={handleMenuOpen}
                isDropdownOpen={isDropdownOpen}
                selected={branchId}
                label="Branch"
                name="branch"
                required={true}
              />
            </div>
            <div className="otp-request-container">
              <button className="request-otp-button" onClick={handleRequestOTP}>
                Request for OTP
              </button>
            </div>
            <label htmlFor="otp" className="label-input-group">
              Enter OTP<span className="mandatory">*</span>
            </label>
            <input
              id="otp"
              className="gst_input_fields"
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
          <button className="submit-otp-button" onClick={handleSubmitOTP}>
            Submit OTP
          </button>
        </div>
      )}
    </div>
  );
};

export default CredentialsComponent;
