import {
    API_ERRORS,
    COMPANIES,
    COMPANIES_LIST_SUCCESSFUL,
  } from "./actionTypes";
  
  
  
  export const getCompanies = (url, data, history) => { 
    return {
      type: COMPANIES,
      payload: {url, data, history }
    };
  };
  
  export const companiesListSuccessful = (companys) => {
    return {
      type: COMPANIES_LIST_SUCCESSFUL,
      payload: companys,
    };
  };
  export const apiErrors = (error) => {
    return {
      type: API_ERRORS,
      payload: error,
    };
  };