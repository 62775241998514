import React, { Component } from "react";
import Select from "react-select";
import FormControl from "@mui/material/FormControl";

class DynamicSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedlable: "",
      selectedValue:"",
      selectedName:"",
      selectedItemCode: "",
    };
  }

  handleChange = (event) => {
    let selectedValue = event.value;
    let selectedName = event.name;
    let selectedlable = event.label;
    let selectedItemCode = event.variant
    this.setState({
      selectedValue: selectedValue,
      selectedName: selectedName,
      selectedlable:selectedlable,
      selectedItemCode: selectedItemCode,
    })
    this.props.onSelectChange(selectedValue, selectedName, this.props.name, this.props.row,selectedlable,this.props.index,this.props.row,selectedItemCode);
  };
  handleMenuOpen = () => {
      this.props.handleMenuOpen()
  }
  componentWillReceiveProps(props) {
    if (props.arrayOfData) {
      props.arrayOfData.forEach((row) => {
        if (props.selected == row.value) {    
          this.setState({
            selectedlable: row.label,
            selectedValue: props.selected,
            selectedName: row.label,
            selectedItemCode:row.variant
          })    
        }
      });
    }
  }

  colourStyles ={
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#5ED5F7" : null,
        color: "#333333",
        fontSize: "16px",
        fontWeight: "bold" 
      };
    }
  };
  render() {
      if(this.props.arrayOfData){
          this.props.arrayOfData.map((row) => {
            if(this.props.selected === row.value) {
              this.state.selectedValue = this.props.selected
              this.state.selectedlable = row.label
              this.state.selectedName = row.label
              this.state.selectedItemCode =row.variant
            }
          })
        }  
    return (
      <FormControl sx={{ width: "30%" }} className="invoice_type_block">
        <div>
          <label htmlFor="app-input-field">{this.props.label} </label>
          {this.props.required && <div className="mandatoryField">*</div>}
          
        </div>
        <div className="">
      <Select
          className="invoice_select"
          classNamePrefix="react-select"
          options={this.props.arrayOfData}
          menuPosition="fixed"
          menuPlacement="auto"
          closeMenuOnScroll={() => this.props.isDropdownOpen}
          defaultValue={this.props.selected ? { value: this.props.selected, label: this.state.selectedlable,variant: this.state.selectedItemCode}: ""}
          value={this.props.selected ? { value: this.state.selectedValue, label: this.state.selectedName, variant: this.state.selectedItemCode}: ""}
          placeholder="Select"
          onChange={this.handleChange}
          onMenuOpen={this.handleMenuOpen}
          isDisabled={this.props.isDisabled ? true :this.props.update === 1 ? this.props.isDisabled : false}
          styles={this.colourStyles}

        />
        </div>
      </FormControl>
    );
  }
}
// const arrayOfData =[
// {
//     value:1,
//     name:"Regular Invoice",
//     label:"Regular Invoice"
// },
// {
//     value:2,
//     name:"Jute Govt",
//     label:"Jute Govt"
// },
// {
//     value:3,
//     name:"Regular Invoice",
//     label:"Regular Invoice"
// },
// {
//     value:4,
//     name:"Jute Govt",
//     label:"Jute Govt"
// }
// ]
export default DynamicSelect;
