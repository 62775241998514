import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { serverApi } from "../../../helpers/Consts";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import Gridwithcustomview from "../../../components/Grid/PaginationGridView";
import { getPriceEnquiryList } from "../../../store/Purchase/PriceEnquiry/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      PriceEnquiryListData: [],
      totalRecords:0,
      startIndex: 1,
      lastIndex: 10,
      dataexp:"",

    };
  }

  componentDidMount() {

    const data = {
      companyId: localStorage.getItem("companyId"),
      suppCode: JSON.parse(localStorage.getItem("authUser")).suppCode,
      hdrId: null,
      itemId: null,
      startIndex : 1,
      lastIndex : 10,
       // acYear: localStorage.getItem("acadamicYear"),
    };
    const expdata = {
      ...data,
      startIndex : 0,
      lastIndex : 0,
    }
    this.setState({ dataexp: expdata });
    this.props.getPriceEnquiryList(
      serverApi.PRICE_ENQUIRY_LIST_BY_CMPY_ID,
      data,
      this.props.history
    );
  }
  componentWillReceiveProps(props) {
    if (props.price_enquiry_List) {
      this.setState({
        PriceEnquiryListData: props.price_enquiry_List.data,
        totalRecords:props.price_enquiry_List.totalRecords
        // PriceEnquiryListColumns: props.price_enquiry_List.column,
      });
    }
  }

  // handleSearch = (data1) => {
  //   const data = {
  //     companyId: localStorage.getItem("companyId"),
  //     acYear: localStorage.getItem("acadamicYear"),
  //     status: data1.StatusValue,
  //     branch: data1.branchValue,
  //     project: data1.projctValue,
  //   };

  //   // orgId: this.state.userDit.orgId,
  //   this.props.getPriceEnquiryList(
  //     serverApi.PRICE_ENQUIRY_LIST_BY_CMPY_ID,
  //     data,
  //     this.props.history
  //   );
  // };
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    const data = {
      companyId: localStorage.getItem("companyId"),
      suppCode: JSON.parse(localStorage.getItem("authUser")).suppCode,
      hdrId: null,
      itemId: null,
    };
    if (type === 1) {
      data.startIndex = sizePerPage;
      data.lastIndex = currentIndex;
      if (currentIndex >= 0) {
        this.props.getPriceEnquiryList(
          serverApi.PRICE_ENQUIRY_LIST_BY_CMPY_ID,
          data,
          this.props.history
        );
      }
    } else {
      data.startIndex = currentIndex ===0?currentIndex+1:currentIndex;
      data.lastIndex = sizePerPage;
      if (currentIndex >= 0) {
        this.props.getPriceEnquiryList(
          serverApi.PRICE_ENQUIRY_LIST_BY_CMPY_ID,
          data,
          this.props.history
        );
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });

  }
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.PriceEnquiryListData}
          mcolumns={PriceEnquiryListColumns}
          micon={GridArrowIcon}
          // handleSearch={this.handleSearch}
          status={true}
          actions={true}
          // filter_form={"priceEnquiry"}
          url="/create_priceenquiry"
          totalRecs={this.state.totalRecords}
          pageno={this.state.pageno}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          apiexp={serverApi.PRICE_ENQUIRY_LIST_BY_CMPY_ID}
          payloadexp={this.state.dataexp}
          expName={"PriceEnquiry.csv"}
        />
      </>
    );
  }
}

const PriceEnquiryListColumns = [
  {
    Header: "Price Request Id",
    accessor: "hdrId",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false
  },
  {
    Header: "Price Request Date",
    accessor: "priceRequestDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false
  },
  {
    Header: "Price Request Subject",
    accessor: "priceRequestSubject",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true
  },
  {
    Header: "Price Request Status",
    accessor: "priceRequestStatus",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false
  },
  {
    Header: "Date ",
    accessor: "priceRequestDateDesc",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true
  },
  {
    Header: "Status",
    accessor: "statusdesc",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true

  },
  {
    Header: "Comments",
    accessor: "comments",
    headerAlign: "left",
    align: "left",
    hidden: false,
    csvExport: true

  },
  {
    Header: "Company Id",
    accessor: "companyId",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false

  },
  {
    Header: "Created By",
    accessor: "createdBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false

  },
  {
    Header: "Create DateTime",
    accessor: "createDateTime",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false

  },
  {
    Header: "Updated By",
    accessor: "updatedBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false

  },
  {
    Header: "Updated DateTime",
    accessor: "updatedDateTime",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false

  },

  {
    Header: "Branch Id",
    accessor: "branchId",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false

  },
  {
    Header: "Approved Status",
    accessor: "approvedStatus",
    headerAlign: "left",
    align: "left",
    hidden: true,
    csvExport: false
  },
];

const mapStatetoProps = (state) => {
  const { price_enquiry_List } = state.price_enquiry_List;
  return { price_enquiry_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getPriceEnquiryList,
  })(Index)
);
