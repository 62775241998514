import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import Link from "@mui/material/Link";
import { Grid, Step, Stepper, StepLabel } from "@mui/material";

import QuotationFillDetails from "./QuotationFillDetailsSections";
import QuotationLineItems from "./QuotationLineItems";
import QuotationTransportation from "./QuotationTransportation";
import QuotationPreview from "./QuotationPreview";
import { serverApi } from "../../../helpers/Consts";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { isEmpty, isEmptyWithZero } from "../../../Utilities/helper";
import moment from "moment";
import { limitDecimals } from "../../../Utilities/helper";

import {
  setQuotation,
  getQuotationViewById,
  updateQuotation,
} from "../../../store/Sales/Qutation/actions";

import CustomButton from "../../../components/Buttons/Buttons";


const CreateQuotation = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation()
  const [locationState, setLocationState] = useState("")

  const steps = [
    {
      title: "Fill Details",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Add Items",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Terms",
      icon: "http://vowerp.com/MailImages/assets/billing_inactive.png",
      activeIcon: "http://vowerp.com/MailImages/assets/billing_active.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Preview",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive03.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
  ];

  const { quoteLineItems, quoteHeader } = useSelector(
    (state) => state.QuotationReducer
  );
  
  const TodayDate = moment().format();

  useEffect(() => {
    setLocationState(location)
    if (locationState.state !== undefined) {
      if (locationState.state.rowData !== undefined) {
        setCurrentStep(4);
        dispatch(
          getQuotationViewById(
            serverApi.QUOTATION_VIEW_BYID +
            locationState.state.rowData.quotationId +
              "/" + JSON.parse(localStorage.getItem("authUser")).userId
          )
        );
      }
    }
  }, [locationState]);


  const handleCurrentStep = (data) => {
    setCurrentStep(data);
  };

  const onClickNext = () => {
    if (currentStep == 1) {
      if (isEmpty(quoteHeader.branchId)) {
        swal("Please Select the Branch");
      }
      else if (
        (isEmpty(quoteHeader.customerId) || quoteHeader.customerId === 0) &&  (isEmpty(quoteHeader.brokerId) || quoteHeader.brokerId === 0)
      ) {
        swal("Please Select the Broker Or Consignee");
      } else if (!isEmpty(quoteHeader.brokerId) && quoteHeader.brokerId !== 0) {
        if (isEmpty(quoteHeader.quotationDate)) {
          swal("Please Enter Quotation Date");
        } else if (isEmpty(quoteHeader.quotationExpiryDate)) {
          swal("Please Enter the Expiry Date");
        } else if (isEmptyWithZero(quoteHeader.brokeragePercentage)) {
          swal("Please Enter the Broker %");
        }  else if (quoteHeader.deliveryTerms === "") {
          swal("Please Select the Delivery terms");
        }
        else {
          setCurrentStep(currentStep + 1);
        }
      } else if (!isEmpty(quoteHeader.customerId)) {
        if (isEmpty(quoteHeader.quotationDate)) {
          swal("Please Enter Quotation Date");
        } else if (isEmpty(quoteHeader.quotationExpiryDate)) {
          swal("Please Enter the Expiry Date");
        } else if (isEmpty(quoteHeader.billingToAddress)) {
          swal("Please Select the Billing Address");
        } else if (isEmpty(quoteHeader.shippingToAddress)) {
          swal("Please Select the Shipping Address");
        } else if (quoteHeader.deliveryTerms === "") {
          swal("Please Select the Delivery terms");
        }
        else {
          setCurrentStep(currentStep + 1);
        }
      }
    } else if (currentStep == 2) {
      let ValidateFields = false;
      let lastRow = quoteLineItems[quoteLineItems.length - 1];

      let FilteredData = quoteLineItems.filter((item) => item.isActive !== 0);
      if (FilteredData.length == 1) {
        FilteredData.map((item, index) => {
          if (item.itemGroupValue === null || item.itemGroupValue === "") {
            swal("Please select the ItemGroup");
            return false;
          } else if (isEmpty(item.itemId)) {
            swal("Please select the Item");
            return false;
          } else if (Number(item.quantity) <= 0) {
            swal("Please Enter the Quantity");
            return false;
          } else if (item.rate <= 0  || item.rate === "" || item.rate === "0.000") {
            swal("Please Enter the Rate");
            return false;
          } else if (isEmpty(item.uom)) {
            swal("Please Enter the uom");
            return false;
          } else {
            ValidateFields = true;
          }
        });
      } else {
        let FilteredData = quoteLineItems.filter(
          (item) => item.isActive !== 0 && item.itemGroupId !== ""
        );
        for (let i = 0; i <= FilteredData.length - 1; i++) {
          if (FilteredData[i].itemGroupId === "") {
            swal("Please select the ItemGroup");
            return false;
          } else if (isEmpty(FilteredData[i].itemId)) {
            swal("Please select the Item");
            return false;
          } else if (Number(FilteredData[i].quantity) <= 0) {
            swal("Please Enter the Quantity");
            return false;
          } else if (FilteredData[i].rate <= 0 || FilteredData[i].rate === ""|| FilteredData[i].rate=== "0.000") {
            swal("Please Enter the Rate");
            return false;
          } else if (FilteredData[i].uom === "") {
            swal("Please Enter the uom");
            return false;
          } else {
            ValidateFields = true;
          }
        }
      }
      if (ValidateFields) {
        setCurrentStep(currentStep + 1);
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const onClickBefore = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    } else {
      history.push("/quotation");
    }
  };

  const onClickCreate = (status) => {
    let LineItemDTO = [];
    const filterProduct = quoteLineItems.filter(
      (item) =>
        item.isActive !== 0 &&
        item.itemGroupId !== null &&
        item.itemGroupId !== ""
    );
    if (filterProduct.length !== 0) {
      filterProduct.map((item) => {
        LineItemDTO.push({
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          companyId: localStorage.getItem("companyId"),
          hsnCode: item.hsnCode,
          itemGroupId: item.itemGroupId,
          itemId: item.itemId,
          make: item.make,
          quantity: limitDecimals(item.quantity, 3),
          uom: item.uom,
          rate: limitDecimals(item.rate, 3),
          salesBalQty: limitDecimals(item.salesBalQty, 3),
          invoiceBalQty: limitDecimals(item.salesBalQty, 3),
          igstAmount: limitDecimals(item.igstAmount,2),
          igstPercent: limitDecimals(item.igstPercent,2),
          cgstAmount: limitDecimals(item.cgstAmount,2),
          cgstPercent: limitDecimals(item.cgstPercent,2),
          sgstAmount: limitDecimals(item.sgstAmount,2),
          sgstPercent: limitDecimals(item.sgstPercent, 2),
          discountType: item.discountType,
          discount: limitDecimals(item.discount, 2),
          discountAmount:limitDecimals(item.discountAmount,2),
          discountedRate: limitDecimals(item.discountedRate, 3),
          netAmount: limitDecimals(item.netAmount, 2),
          totalAmount: limitDecimals(item.totalAmount, 2),
          remarks: item.remarks,
          isActive: item.isActive,
        });
      });
    }

    let expireDate = new Date(quoteHeader.quotationExpiryDate).getTime();

    const data = {
      finYear: localStorage.getItem("acadamicYear"),
      createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
      quotationDate: quoteHeader.quotationDate,
      companyId: localStorage.getItem("companyId"),
      branchId: quoteHeader.branchId,
      customerId: quoteHeader.customerId,
      brokerId: quoteHeader.brokerId,
      billingAddress: quoteHeader.billingAddress,
      shippingAddress: quoteHeader.shippingAddress,
      quotationExpiryDate: quoteHeader.quotationExpiryDate,
      footerNotes: quoteHeader.footerNotes,
      frieghtCharges: limitDecimals(quoteHeader.frieghtCharges, 2),
      taxType: quoteHeader.taxType,
      taxAmount: limitDecimals(quoteHeader.taxTotal, 2),
      netAmount: limitDecimals(quoteHeader.netTotal, 2),
      totalAmount: limitDecimals(quoteHeader.totalAmount,2),
      paymentTerms: quoteHeader.paymentTerms,
      deliveryTerms: quoteHeader.deliveryTerms,
      brokeragePercentage: limitDecimals(quoteHeader.brokeragePercentage,2),
      deliveryDays: quoteHeader.deliveryDays,
      internalNote: quoteHeader.internalNote,
      termsCondition: quoteHeader.termsCondition,
      approvedBy: JSON.parse(localStorage.getItem("authUser")).userId,
      userId: JSON.parse(localStorage.getItem("authUser")).userId,
      updatedBy: "",
      updatedOn: "",
      approvedBy: "",
      approvedOn: "",
      statusId: 1,
      isActive: 1,
      salesQuotationLineItems: LineItemDTO,
    };

    dispatch(setQuotation(serverApi.CREATE_QUOTATION, data, history));
  };
  const OnUpdate = (status) => {
    var LineItemDTO = [];
    let FilteredData = quoteLineItems.filter(item => { return !isEmpty(item.itemGroupId) && (item.isActive === 1 || !isEmpty(item.quotationLineitemId))});
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemDTO.push({
          quotationLineitemId: item.quotationLineitemId,
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          createdDate: moment().format().split("T", 2)[0],
          quotationId: isEmpty(item.quotationId) ? quoteHeader.quotationId : item.quotationId,
          companyId: localStorage.getItem("companyId"),
          hsnCode: item.hsnCode,
          itemGroupId: item.itemGroupId,
          itemId: item.itemId,
          make: item.make,
          quantity: limitDecimals(item.quantity, 3),
          uom: item.uom,
          rate: limitDecimals(item.rate, 3),
          salesBalQty: limitDecimals(item.salesBalQty, 3),
          invoiceBalQty: limitDecimals(item.invoiceBalQty, 3),
          igstAmount: limitDecimals(item.igstAmount, 2),
          igstPercent: limitDecimals(item.igstPercent, 2),
          cgstAmount: limitDecimals(item.cgstAmount, 2),
          cgstPercent: limitDecimals(item.cgstPercent, 2),
          sgstAmount: limitDecimals(item.sgstAmount, 2),
          sgstPercent: limitDecimals(item.sgstPercent, 2),
          discountType: item.discountType,
          discount: limitDecimals(item.discount, 2),
          discountAmount:limitDecimals(item.discountAmount,2),
          discountedRate: limitDecimals(item.discountedRate, 2),
          netAmount: limitDecimals(item.netAmount, 2),
          totalAmount: limitDecimals(item.totalAmount, 2),
          remarks: item.remarks,
          status: item.status,
          updatedBy: item.updatedBy,
          isActive: item.isActive,
        });
      });
    }

    const data = {
      quotationId: quoteHeader.quotationId,
      finYear: localStorage.getItem("acadamicYear"),
      createdBy: quoteHeader.createdBy,
      createdDate: quoteHeader.createdDate,
      quotationDate: quoteHeader.quotationDate,
      quotationNo: quoteHeader.quotationNo,
      companyId: localStorage.getItem("companyId"),
      branchId: quoteHeader.branchId,
      customerId: quoteHeader.customerId,
      deliveryTerms: quoteHeader.deliveryTerms,
      brokerId: quoteHeader.brokerId,
      statusId: status,
      billingAddress: quoteHeader.billingAddress,
      shippingAddress: quoteHeader.shippingAddress,
      quotationExpiryDate: quoteHeader.quotationExpiryDate,
      footerNotes: quoteHeader.footerNotes,
      frieghtCharges: limitDecimals(quoteHeader.frieghtCharges, 2),
      taxType: quoteHeader.taxType,
      taxAmount: limitDecimals(quoteHeader.taxAmount, 2),
      netAmount: limitDecimals(quoteHeader.netAmount, 2),
      totalAmount: limitDecimals(quoteHeader.totalAmount, 2),
      paymentTerms: quoteHeader.paymentTerms,
      brokeragePercentage: limitDecimals(quoteHeader.brokeragePercentage, 2),
      deliveryDays: quoteHeader.deliveryDays,
      internalNote: quoteHeader.internalNote,
      termsCondition: quoteHeader.termsCondition,
      updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
      updatedOn: TodayDate.split("T", 2)[0],
      approvedBy: JSON.parse(localStorage.getItem("authUser")).userId,
      approvedOn: TodayDate.split("T", 2)[0],
      userId: JSON.parse(localStorage.getItem("authUser")).userId,
      salesQuotationLineItems: LineItemDTO,
      isActive: 1,
    };

    // We have to send the blob to server once the record is compleletly approved
    dispatch(updateQuotation(serverApi.UPDATE_SALES_QUOTATION, data, history));
  };

  const onClickApprove = (Status) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Approve",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willapprove) => {
      if (willapprove) {
        OnUpdate(Status);
      }
    })
  };

  const onClickRejected = (Status) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Reject",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReject) => {
      if (willReject) {
        OnUpdate(Status);
      }
    })
  };

  const QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;

    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };

  return (
    <div className="createQuotationContainer">
      <Grid className="indentContainerBlock">
        <Grid sx={12} className="indentContainerMainBlock">
          <div className="iconsBlock">
            <Stepper alternativeLabel activeStep={currentStep}>
              {steps.map((prop) => (
                <Step key={prop.title}>
                  <StepLabel
                    StepIconComponent={(Iconprops) => {
                      return QontoStepIcon(
                        Iconprops,
                        prop.icon,
                        prop.activeIcon,
                        prop.title
                      );
                    }}
                  ></StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          {currentStep === 1 && (
            <>
              <QuotationFillDetails locationState={locationState}/>
              <div className="consoleFormButtonBlock">
                <Link>
                  <CustomButton
                    label={"Back"}
                    className="greenBorderButton"
                    handleClick={onClickBefore}
                  />
                </Link>
                <CustomButton
                  label={"Next"}
                  className="greenButton"
                  handleClick={onClickNext}
                  type="sumbit"
                />
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <QuotationLineItems />
              <div className="consoleFormButtonBlock">
                <Link>
                  <CustomButton
                    label={"Back"}
                    className="greenBorderButton"
                    handleClick={onClickBefore}
                  />
                </Link>
                <CustomButton
                  label={"Next"}
                  className="greenButton"
                  handleClick={onClickNext}
                  type="sumbit"
                />
              </div>
            </>
          )}
          {currentStep === 3 && (
            <>
              <QuotationTransportation  />
              <div className="consoleFormButtonBlock">
                <Link>
                  <CustomButton
                    label={"Back"}
                    className="greenBorderButton"
                    handleClick={onClickBefore}
                  />
                </Link>
                <CustomButton
                  label={"Next"}
                  className="greenButton"
                  handleClick={onClickNext}
                  type="sumbit"
                />
              </div>
            </>
          )}
          {currentStep === 4 && (
            <>
              <QuotationPreview handleCurrentStep={handleCurrentStep} locationState={locationState}/>
              <div className="consoleFormButtonBlock">
                {locationState.state === undefined ? (
                  <>
                    <Link>
                      <CustomButton
                        label={"Back"}
                        className="greenBorderButton"
                        handleClick={onClickBefore}
                      />
                    </Link>
                    <CustomButton
                      label={"Create"}
                      className="greenButton"
                      type="sumbit"
                      handleClick={onClickCreate}
                    />
                  </>
                ) : (
                  <>
                    {quoteHeader.UpdateButton == true ? (
                      <CustomButton
                        label={"Update"}
                        className="greenButton"
                        handleClick={() => OnUpdate("")}
                        type="sumbit"
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
                {locationState.state !== undefined &&
                quoteHeader.ApproveButton === true ? (
                  <>
                    <CustomButton
                      label={"Approve"}
                      className="approved"
                      handleClick={() => onClickApprove(quoteHeader.statusId)}
                      type="sumbit"
                    />
                  </>
                ) : (
                  ""
                )}
                {locationState.state !== undefined &&
                quoteHeader.RejectButton == true ? (
                  <>
                    <CustomButton
                      label={"Reject"}
                      className="rejected"
                      handleClick={() => onClickRejected(4)}
                      type="sumbit"
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateQuotation;
