import React, { Component } from "react";
import CustomButton from "../../../../../components/Buttons/Buttons";
import { serverApi, getTenantId } from "../../../../../helpers/Consts";
import {
  offerLetterEmail,
  displayEmailTemplate,
  setPersonalDetails,
  ApprovalHierarchy,
} from "../../../../../store/HRMS/EmployeeDataBase/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { makePayloaddata } from "../Makepayload.js";
class OfferLetter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: localStorage.getItem("companyId"),
      response: [],
      userDit: JSON.parse(localStorage.getItem("authUser")),

      ebId: this.props.getEbId,
      display: false,
      TemplateData: "",
      show: false,
      reOpen: false,
      toGenerateLetters: JSON.parse(localStorage.getItem("toGenerateLetters")),
      showButton: false,
    };
  }

  SendOfferLetter = () => {
    if (this.props.getEbId) {
      const data = {
        companyId: this.state.companyId,
        ebId: this.props.getEbId,
      };
      this.props.offerLetterEmail(
        serverApi.OFFER_LETTER_EMAIL_SERVICE,
        data,
        this.props.history
      );
    }
  };
  DownloadOfferLetter = () => {
    if (this.props.getEbId) {
      fetch(serverApi.DOWNLOAD_OFFER_LETTER + this.props.getEbId, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          "X-TenantId": getTenantId(window.location.hostname),
          Authorization:
            localStorage.getItem("token_type") +
            localStorage.getItem("access_token"),
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "OfferLetter" + ".pdf");
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
    }
  };

  componentWillReceiveProps(props) {
    if (props.DisplayEmailTemplate) {
      if (props.DisplayEmailTemplate.data) {
        this.setState({
          TemplateData: props.DisplayEmailTemplate.data,
        });
      }
    }
  }
  componentDidMount() {
    if (this.props.getEmpData) {
      if (this.props.getEmpData.status) {
        if (this.props.getEmpData.status == 35) {
          this.setState({
            showButton: true,
          });
        }
      }
    }
    if (this.props.getEbId) {
      this.props.displayEmailTemplate(
        serverApi.EMAIL_TEMPLATE_DISPLAY + this.props.getEbId,

        this.props.history
      );
    }
  }

  handleAffered = (status) => {
    const data = makePayloaddata(this.state);
    if (this.props.getEbId) {
      data.ebId = this.props.getEbId;
      data.empStatus = status;
      data.generateLetters = 1;
      data.dateOfJoining = this.state.dateOfJoining;
    }

    this.props.setPersonalDetails(
      serverApi.ADD_EMPLOYEE,
      data,
      this.props.history
    );
  };
  onhandleUpdate = (status) => {
    const data = makePayloaddata(this.state);
    if (this.props.getEbId) {
      data.ebId = this.props.getEbId;
      data.empStatus = status;
      data.generateLetters = 1;
      data.dateOfJoining = this.state.dateOfJoining;
    }

    this.props.setPersonalDetails(
      serverApi.ADD_EMPLOYEE,
      data,
      this.props.history
    );
  };

  render() {
    var d = this.state.TemplateData;
    var data = d.split("~");
    var data1 = data;

    return (
      <>
        {this.state.showButton && (
          <div>
            {data1.map((datas) => {
              var entry = datas.split("^");
              var entry2 = entry[0].split("@");
              return (
                <>
                  <h4>{entry2[0]}</h4>
                  <p>{entry[1]}</p>
                  <p>{entry[2]}</p>
                  <p>{entry[3]}</p>
                  <p>{entry[4]}</p>
                </>
              );
            })}
          </div>
        )}
        <div className="consoleFormButtonBlock offerLetterButtons">
          {this.state.showButton && (
            <>
              <CustomButton
                label={"Download Offer Letter"}
                className="greenButton"
                handleClick={this.DownloadOfferLetter}
              />
              <CustomButton
                label={"Send Email"}
                className="greenButton"
                handleClick={this.SendOfferLetter}
              />
            </>
          )}

          {this.state.display && (
            <CustomButton
              label={"Mark as Offered"}
              className="greenButton"
              handleClick={() =>
                localStorage.getItem("EmpData") === null
                  ? this.handleAffered(33)
                  : this.onhandleUpdate(33)
              }
            />
          )}
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    EmailOfferLetter,
    personalDetails,
    DisplayEmailTemplate,
    approval_hierarchy,
    getEbId,
    progressbar_data,
    getEmpData,
  } = state.EmployeeReducer;
  return {
    EmailOfferLetter,
    personalDetails,
    DisplayEmailTemplate,
    approval_hierarchy,
    getEbId,
    progressbar_data,
    getEmpData,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    offerLetterEmail,
    setPersonalDetails,
    displayEmailTemplate,
    ApprovalHierarchy,
  })(OfferLetter)
);
