import {
  FORGET_USER,
  FORGET_USER_SUCCESSFUL,
  FORGET_PASSWORD_API_FAILED,
  RESET_USER_PASSWORD,
  RESET_PASSWORD_USER_SUCCESSFUL,
  RESET_PASSWORD_USER_ERROR
} from "./actionTypes";

export const forgetUser = (url, data, history) => {
  return {
    type: FORGET_USER,
    payload: { url, data, history },
  };
};

export const forgetUserSuccessful = (data) => {
  return {
    type: FORGET_USER_SUCCESSFUL,
    payload: data,
  };
};

export const userForgetPasswordError = (error) => {
  return {
    type: FORGET_PASSWORD_API_FAILED,
    payload: error,
  };
};

export const resetUserPassword = (url, data, history) => {
  return {
    type: RESET_USER_PASSWORD,
    payload: { url, data, history },
  };
};

export const resetUserPasswordSuccessful = (data) => {
  return {
    type: RESET_PASSWORD_USER_SUCCESSFUL,
    payload: data,
  };
};

export const resetUserPasswordError = (error) => {
  return {
    type: RESET_PASSWORD_USER_ERROR,
    payload: error,
  };
};
