import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import {
  getSalesOrderList,
  clearSalesOrderHeader,
  ClearSalesOrderLineItems,
  ClearSOJuteGovtLineItems,
  ClearSOJuteGovtHeaders,
  ClearSOJuteYarnLineItems,
  ClearSOJuteYarnHeaders,
  ClearSOJuteHessianHeaders,
  ClearSOJuteHessianLineItems,
} from "../../../store/Sales/SalesOrder/actions";
import{BillingShippingListSuccessfull} from "../../../store/Global/DropDownApis/actions"

import moment from "moment";

function Index(props) {
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [totalRecs, setTotalRecs] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(10);
  const [dataexp, setDataExp] = useState("");
  const [SalesOderData, setSalesOderData] = useState([]);
  const [saleNo, setsaleNo] = useState("");
  const [status, setstatus] = useState("");
  const [cusId, setcusId] = useState("");
  const [branchId, setbranchId] = useState("");
  const [fromDate, setfromDate] = useState( moment().day(-7).format("DD-MM-YYYY"));
  const [toDate, settoDate] = useState(moment().format("DD-MM-YYYY"));
  const [pageno, setpageno] = useState("");
  const [InvoiceType, setInvoiceType] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const financialYearStartMonth = 3; // Assuming financial year starts in April
    let financialYearStart = moment()
      .year(localStorage.getItem("acadamicYear"))
      .month(financialYearStartMonth)
      .startOf("month");

    const fromDate = financialYearStart.day(-7).format("YYYY-MM-DD");
    const toDate = moment().format("YYYY-MM-DD");

    var startDate = moment(fromDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var endDate = moment(toDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    const data = {
      type:"",
      salesOrderNo: "",
      status: "",
      cusId: "",
      branchId: "",
      cipher: userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      financialYear: localStorage.getItem("acadamicYear"),
      fromDate: startDate,
      projectId: "",
      supplierId: null,
      toDate: endDate,
      lastIndex: lastIndex,
      startIndex: startIndex,
    };

    const UpdatedVal = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    setDataExp(UpdatedVal);
    setfromDate(startDate)
    settoDate(endDate)
    dispatch(
      getSalesOrderList(serverApi.SALES_ORDER_LIST, data, props.history)
    );
  }, []);

  const { SalesOrder_List } = useSelector((state) => state.SalesOrderReducer);

  useEffect(() => {
    // Dispatch the action to reset the reducer
      dispatch(clearSalesOrderHeader());
      dispatch(ClearSalesOrderLineItems());
      dispatch(ClearSOJuteHessianHeaders());
      dispatch(ClearSOJuteHessianLineItems());
      dispatch(ClearSOJuteYarnLineItems());
      dispatch(ClearSOJuteYarnHeaders());
      dispatch(ClearSOJuteGovtHeaders());
      dispatch(ClearSOJuteGovtLineItems());
      dispatch(BillingShippingListSuccessfull({}))
  },[])
  useEffect(() => {
    if (SalesOrder_List) {
      if(SalesOrder_List.data){ 
      setSalesOderData(SalesOrder_List.data);
      setTotalRecs(SalesOrder_List.totalRecords);
    }
   }
  }, [SalesOrder_List]);

  const handleSearch = (data) => {
      var from_Date = moment(data.fromDate,"YYYY-MM-DD", true).format("DD-MM-YYYY");
    var to_Date =  moment(data.toDate,"YYYY-MM-DD", true).format("DD-MM-YYYY")
    setsaleNo(data.saleNo);
    setstatus(data.status);
    setcusId(data.cusId);
    setbranchId(data.branchId);
    setfromDate(from_Date);
    settoDate(to_Date);
    setInvoiceType(data.type);
    const payload = {
      type: data.type,
      salesOrderNo: data.saleNo,
      status: data.status,
      cusId: data.cusId,
      branchId: data.branchId,
      cipher: userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      financialYear: localStorage.getItem("acadamicYear"),
      fromDate: from_Date,
      projectId: "",
      supplierId: null,
      toDate:to_Date,
      lastIndex: 10,
      startIndex: 0,
    };
    
    const UpdatedVal = {
      ...payload,
      startIndex: 0,
      lastIndex: 0,
    };
    setDataExp(UpdatedVal);
    dispatch(
      getSalesOrderList(serverApi.SALES_ORDER_LIST, payload, props.history)
    );
  };

  const handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    const data = {
      type:InvoiceType,
      salesOrderNo: saleNo,
      status: status,
      cusId: cusId,
      branchId: branchId,
      cipher: userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      financialYear: localStorage.getItem("acadamicYear"),
      fromDate: fromDate,
      projectId: "",
      supplierId: null,
      toDate: toDate,
    };
    if (type === 1) {
      data.startIndex = sizePerPage;
      data.lastIndex = currentIndex;
      if (currentIndex >= 0) {
        dispatch(
          getSalesOrderList(serverApi.SALES_ORDER_LIST, data, props.history)
        );
      }
    } else {
      data.startIndex = currentIndex;
      data.lastIndex = sizePerPage;
      if (currentIndex >= 0) {
        dispatch(
          getSalesOrderList(serverApi.SALES_ORDER_LIST, data, props.history)
        );
      }
    }
    setStartIndex(currentIndex);
    setLastIndex(sizePerPage);
  };

    //  var from_Date = moment(fromDate,"YYYY-MM-DD", true).format("DD-MM-YYYY");
    // var to_Date =  moment(toDate,"YYYY-MM-DD", true).format("DD-MM-YYYY")

  return (
    <PaginationGridView
      mdata={SalesOderData}
      mcolumns={Columns}
      micon={GridArrowIcon}
      status={true}
      actions={true}
      filter_form={"SalesOrder"}
      totalRecs={totalRecs}
      page={pageno}
      sizePerPage={lastIndex}
      handlePagination={handlePagination}
      handleSearch={handleSearch}
      url="/create_sales_order"
      apiexp={serverApi.SALES_ORDER_LIST}
      payloadexp={dataexp}
      expName={"SalerOrderList.csv"}
      fromDate={fromDate}
      toDate={toDate}
    />
  );
}
const Columns = [
  {
    Header: "Invoice Type",
    accessor: "invoiceTypeDesc ",
    hidden: false,
  },
  {
    Header: "Sale Number",
    accessor: "saleNoString",
    hidden: false,
  },
  {
    Header: "Customer",
    accessor: "customer",
    hidden: false,
  },
  {
    Header: "Sale Date",
    accessor: "saleDate",
    hidden: false,
  },
  {
    Header: "Expiry Date",
    accessor: "expiryDate",
    hidden: false,
  },
  {
    Header: "Amount",
    accessor: "amount",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "status",
    hidden: false,
  },
];

export default Index;
