import React, { useState, useEffect } from "react";
import { Grid, Step, Stepper, StepLabel, Link as MuiLink } from "@mui/material";
import Link from "@mui/material/Link";
import CustomButton from "../../../components/Buttons/Buttons";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import DebitCreditFillDetails from "./DebitCreditFillDetails";
import DebitCreditNoteLineItems from "./DebitCreditNoteLineItems";
import DebitCreditNotePreview from "./DebitCreditNotePreview";
import moment from "moment";
import { isEmpty, isEmptyWithZero } from "../../../Utilities/helper";
import {
  getCreateData,
  getUpdateData,
  getDebitNoteById,
  DebitNoteLineItemsProps,
} from "../../../store/Purchase/Debit-credit-note/actions";
import { serverApi } from "../../../helpers/Consts";
import swal from "sweetalert";
function CreateDebitCreditNote(props) {
  const [confirm, setConfirm] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [steps] = useState([
    {
      title: "Fill Details",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Add Items",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Preview",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
  ]);
  const [debitNoteLineDetails, setdebitNoteLineDetails] = useState([]);

  const [DebCreditHdr, setDebCreditHdr] = useState({
    Debit_Date: moment().format("YYYY-MM-DD"),
    SrNo: "",
    DebitNoteNo:"",
    srPrintNo:"",
    supplierId: "",
    supplierName: "",
    poSequence: "",
    challanno: "",
    challannoDate: "",
    vehicleNumber: "",
    branchId: "",
    branchName: "",
    inwardId: "",
    remarks: "",
    SR_Date: "",
    PoDate: "",
    adjustmentId: "",
    createdBy: "",
    createdTime: "",
    autoCreate: null,
    adjustmentSeqNo: "",
    editButton: true,
    ApproveButton: false,
    RejectButton: false,
    UpdateButton: true,
    adjustmentStatus: "",
    status:"",
    taxType: "",
    roundOffValue: null,
    roundingFlag: null,
    roundingPositiveFlag: null,
    roundingNegetiveFlag: null,
    roundingPositiveValue: "0.00",
    roundingNegetiveValue: "0.00",
    TotalAmount:"0.00",
    TotalValue:"0.00",
    TotalIGST:"0.00",
    TotalCGST:"0.00",
    TotalSGST:"0.00"
  });
  const [files_List, setfiles_List] = useState([]);

  const onClickNext = () => {
    if (currentStep == 1) {
       if (DebCreditHdr.SrNo == "") {
        swal("Please Select SR No");
      } else if  (DebCreditHdr.Debit_Date == ""){
        swal("Please Select Debit Date");
      } else {
        setCurrentStep(currentStep + 1);
      }
    } else {
      var FilterLineItems = props.debitNoteLineItems.filter(
        (item) => item.isActive !== 0
      );

      if (props.debitNoteLineItems) {
        var ValidateFields = false;
        if (FilterLineItems.length == 1) {
          FilterLineItems.map((item, index) => {
            if (isEmpty(item.debitnoteType)) {
              swal("Please Select the Debit Mode");
              return false;
            } else if (isEmptyWithZero(item.quantity)) {
              swal("Please Enter Quantity");
              return false;
            } else if (isEmpty(item.reason)) {
              swal("Please Enter Reason");
              return false;
            } else if (isEmptyWithZero(item.rate)) {
              swal("Please Enter Price");
              return false;
            } else if (isEmpty(item.amount)) {
              swal("Please Enter Amount");
              return false;
            } else {
              ValidateFields = true;
            }
          });
        } else {
          for (var i = 0; i <= FilterLineItems.length - 1; i++) {
            if (isEmpty(FilterLineItems[i].debitnoteType)) {
              swal("Please Select the Debit Mode");
              return false;
            } else if (isEmptyWithZero(FilterLineItems[i].quantity)) {
              swal("Please Enter Quantity");
              return false;
            } else if (isEmpty(FilterLineItems[i].reason)) {
              swal("Please Enter Reason");
              return false;
            } else if (isEmptyWithZero(FilterLineItems[i].rate)) {
              swal("Please Enter Price");
              return false;
            } else if (isEmpty(FilterLineItems[i].amount)) {
              swal("Please Enter Amount");
              return false;
            } else {
              ValidateFields = true;
            }
          }
        }
        if (ValidateFields == true) {
          setCurrentStep(currentStep + 1);
        }
      }
    }
  };
  const onclickOk = () => {
    setConfirm(false);
  };

  const handleCurrentStep = () => {
    setCurrentStep(1);
  };

  const onClickBefore = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    } else {
      props.history.push("/store_debit_credit_note");
    }
  };
  const onClickbefore =() =>{
    props.history.push("/store_debit_credit_note");
  }
  const QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;

    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };
  const onClickCreate = () => {
    var Linetitems = [];
    if (props.debitNoteLineItems) {
      if (props.debitNoteLineItems.length !== 0) {
        var Filtered = props.debitNoteLineItems.filter(
          (item) => item.isActive !== 0
        );
        if (Filtered.length !== 0) {
          Filtered.map((item, index) => {
            Linetitems.push({
              inwardDetailId: item.inwardDetailId,
              quantity: item.quantity,
              rate: item.rate,
              discount: item.discount,
              reason: item.reason,
              debitnoteType: item.debitnoteType,
            });
          });
        }
      }
    }
    const data = {
      finYear: localStorage.getItem("acadamicYear"),
      adjustmentType: "DN",
      inwardId: DebCreditHdr.inwardId,
      remarks: DebCreditHdr.remarks,
      companyId: localStorage.getItem("companyId"),
      branchId: DebCreditHdr.branchId,
      createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
      adjustmentDate: DebCreditHdr.Debit_Date,
      roundOffValue: DebCreditHdr.roundOffValue,
      roundingFlag: DebCreditHdr.roundingFlag,
      debitNoteLineDetails: Linetitems,
    };
    props.getCreateData(serverApi.CREATE_DEBIT_NOTE, data, props.history);
  };

  const onClickApprove = (status) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Approve",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willApprove) => {
      if (willApprove) {
        onClickUpdate(status);
      }
    });
  };

  const onClickReject = () => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Reject",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willreject) => {
      if (willreject) {
        onClickUpdate("4");
      }
    });
  };

  const onClickUpdate = (status) => {
    var Linetitems = [];
    if (props.debitNoteLineItems) {
      if (props.debitNoteLineItems.length !== 0) {
        if (props.debitNoteLineItems.length !== 0) {
          props.debitNoteLineItems.map((item, index) => {
            Linetitems.push({
              adjustmentDetailId: item.adjustmentDetailId,
              adjustmentId: DebCreditHdr.adjustmentId,
              cgstTaxAmount: item.ctaxAmount,
              discount: item.discount,
              igstTaxAmount: item.itaxAmount,
              inwardDetailId: item.inwardDetailId,
              isActive: item.isActive,
              quantity: item.quantity,
              rate: item.rate,
              reason: item.reason,
              sgstTaxAmount: item.staxAmount,
              debitnoteType: item.debitnoteType,
            });
          });
        }
      }
    }
    const data = {
      adjustmentDate: DebCreditHdr.Debit_Date,
      adjustmentId: DebCreditHdr.adjustmentId,
      adjustmentSeqNo: DebCreditHdr.adjustmentSeqNo,
      adjustmentStatus: status,
      adjustmentType: "DN",
      autoCreate: "",
      branchId: DebCreditHdr.branchId,
      companyId: localStorage.getItem("companyId"),
      companySeqNo: "",
      createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
      createdTime: DebCreditHdr.createdTime,
      finYear: localStorage.getItem("acadamicYear"),
      inwardId: DebCreditHdr.inwardId,
      modBy: JSON.parse(localStorage.getItem("authUser")).userId,
      remarks: DebCreditHdr.remarks,
      roundOffValue: DebCreditHdr.roundOffValue,
      roundingFlag: DebCreditHdr.roundingFlag,
      debitNoteLineDetails: Linetitems,
    };
    props.getUpdateData(serverApi.UPDATE_DEBIT_NOTE, data, props.history);
  };
  useEffect(() => {
    setdebitNoteLineDetails(props.debitNoteLineItems);
    if (props.location.state !== undefined) {
      if (props.location.state.rowData !== undefined) {
        setCurrentStep(3);
        props.getDebitNoteById(
          serverApi.DEBIT_NOTE_VIEW_BY_ID +
            props.location.state.rowData.debitNoteId +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).userId,
          props.history
        );
      }
    }
  }, []);
  useEffect(() => {
    if (props.DebitNoteById) {
      if (props.DebitNoteById.data) {
        setDebCreditHdr((prev) => ({
          ...prev,
          createdBy: props.DebitNoteById.data.createdBy,
          createdTime: props.DebitNoteById.data.createdTime,
          autoCreate: props.DebitNoteById.data.autoCreate,
          adjustmentSeqNo: props.DebitNoteById.data.adjustmentSeqNo,
          adjustmentStatus: props.DebitNoteById.data.adjustmentStatus,
        }));
        if (
          props.DebitNoteById.data.approveStatus == true &&
          props.DebitNoteById.data.adjustmentStatus == 1
        ) {
          setDebCreditHdr((prev) => ({
            ...prev,
            ApproveButton: true,
            RejectButton: true,
            UpdateButton: true,
            editButton: true,
          }));
        } else if (
          props.DebitNoteById.data.approveStatus == true &&
          props.DebitNoteById.data.adjustmentStatus == 3
        ) {
          setDebCreditHdr((prev) => ({
            ...prev,
            ApproveButton: false,
            RejectButton: false,
            UpdateButton: false,
            editButton: false,
          }));
        } else if (
          props.DebitNoteById.data.approveStatus == true &&
          props.DebitNoteById.data.adjustmentStatus == 4
        ) {
          setDebCreditHdr((prev) => ({
            ...prev,
            ApproveButton: false,
            RejectButton: false,
            UpdateButton: false,
            editButton: false,
          }));
        }
        else if (
          props.DebitNoteById.data.approveStatus == false &&
          props.DebitNoteById.data.adjustmentStatus == 4
        ) {
          setDebCreditHdr((prev) => ({
            ...prev,
            ApproveButton: false,
            RejectButton: false,
            UpdateButton: false,
            editButton: false,
          }));
        }
        else if (
          props.DebitNoteById.data.approveStatus == false &&
          props.DebitNoteById.data.adjustmentStatus == 3
        ) {
          setDebCreditHdr((prev) => ({
            ...prev,
            ApproveButton: false,
            RejectButton: false,
            UpdateButton: false,
            editButton: false,
          }));
        }
      }
    }
  }, [props.DebitNoteById]);
  return (
    <div className="createQuotationContainer">
      <Grid className="indentContainerBlock">
        <Grid sx={12} className="indentContainerMainBlock">
          <div className="iconsBlock">
            <Stepper alternativeLabel activeStep={currentStep}>
              {steps.map((prop) => (
                <Step key={prop.title}>
                  <StepLabel
                    StepIconComponent={(Iconprops) => {
                      return QontoStepIcon(
                        Iconprops,
                        prop.icon,
                        prop.activeIcon,
                        prop.title
                      );
                    }}
                  ></StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          {currentStep === 1 && (
            <>
              <DebitCreditFillDetails
                DebCreditHdr={DebCreditHdr}
                debitNoteLineDetails={debitNoteLineDetails}
              />
              <div className="consoleFormButtonBlock">
                <Link>
                  <CustomButton
                    label={"Cancel"}
                    className="greenBorderButton"
                    handleClick={onClickBefore}
                  />
                </Link>
                <CustomButton
                  label={"Next"}
                  className="greenButton"
                  handleClick={onClickNext}
                  type="sumbit"
                />
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <DebitCreditNoteLineItems
                DebCreditHdr={DebCreditHdr}
                debitNoteLineDetails={debitNoteLineDetails}
              />
              <div className="consoleFormButtonBlock">
                <Link>
                  <CustomButton
                    label={"Back"}
                    className="greenBorderButton"
                    handleClick={onClickBefore}
                  />
                </Link>
                <CustomButton
                  label={"Next"}
                  className="greenButton"
                  handleClick={onClickNext}
                  type="sumbit"
                />
              </div>
            </>
          )}

          {currentStep === 3 && (
            <>
              <DebitCreditNotePreview
                DebCreditHdr={DebCreditHdr}
                handleCurrentStep={handleCurrentStep}
              />
              <div className="consoleFormButtonBlock">
                <>
                {props.location.state !== undefined && DebCreditHdr.RejectButton &&(
                    <Link>
                      <CustomButton
                        label={"Cancel"}
                        className="greenBorderButton"
                        handleClick={onClickbefore}
                      />
                    </Link>
                  )}
                  {props.location.state == undefined  && <Link>
                    <CustomButton
                      label={"Back"}
                      className="greenBorderButton"
                      handleClick={onClickBefore}
                    />
                  </Link>}
                  {props.location.state === undefined && (
                    <CustomButton
                      label={"Create"}
                      className="greenButton"
                      handleClick={onClickCreate}
                      type="sumbit"
                    />
                  )}
                  {props.location.state !== undefined &&
                    DebCreditHdr.UpdateButton && (
                      <CustomButton
                        label={"Update"}
                        className="greenButton"
                        handleClick={() => onClickUpdate("")}
                        type="sumbit"
                      />
                    )}
                  {props.location.state !== undefined &&
                    DebCreditHdr.ApproveButton && (
                      <CustomButton
                        label={"Approve"}
                        className="approved"
                        handleClick={() => onClickApprove(1)}
                        type="sumbit"
                      />
                    )}
                  {props.location.state !== undefined &&
                    DebCreditHdr.RejectButton && (
                      <CustomButton
                        label={"Reject"}
                        className="rejected"
                        handleClick={() => onClickReject(4)}
                        type="sumbit"
                      />
                    )}
                </>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

const mapStatetoProps = (state) => {
  const { debitNoteLineItems, DebitNoteById } = state.DebitCreditReducer;
  return {
    debitNoteLineItems,
    DebitNoteById,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getCreateData,
    getUpdateData,
    getDebitNoteById,
    DebitNoteLineItemsProps,
  })(CreateDebitCreditNote)
);
