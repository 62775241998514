import React from "react";
import { serverApi } from "../../../helpers/Consts";
import { HolidayMasterList } from "../../../store/Master/HolidayCalendar/actions";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
class HolidayCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      holidayMasterListData: [],
      holidayDate: null,
      holidayDesc: null,
      branchId: null,
      holidayType: null,
      holidayMasterList: [
        {
          Header: "Holiday Date",
          accessor: "holidayDesc",
          hidden: false,
          csvExport: true,
        },
        {
          Header: "Description",
          accessor: "description",
          hidden: false,
          csvExport: true,
        },
        {
          Header: "Holiday Type",
          accessor: "holidayType",
          hidden: false,
          csvExport: true,
        },
        {
          Header: "Period End Date",
          accessor: "periodEndDate",
          hidden: false,
          csvExport: true,
        },
        {
          Header: "Period Start Date",
          accessor: "periodStartDate",
          hidden: false,
          csvExport: true,
        },
        {
          Header: "Shifts",
          accessor: "spell",
          hidden: false,
          csvExport: true,
        },
        {
          Header: "Branch",
          accessor: "branchId",
          hidden: false,
          csvExport: true,
        },
      ],
    };
  }
  componentDidMount() {
    const data = {
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      companyId: localStorage.getItem("companyId"),
      holidayDate: null,
      holidayDesc: null,
      branchId: null,
      holidayType: null,
    };
    this.props.HolidayMasterList(
      serverApi.GET_ALL_HOLIDAY_MASTER_LIST,
      data,
      this.props.history
    ); // calling Holiday List api
  }
  componentWillReceiveProps(props) {
    this.setState({
      holidayMasterListData: props.holiday_master_List.data,
    });
  }
  handleSearch = (data1) => {
    const data = {
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      companyId: localStorage.getItem("companyId"),
      holidayDate: data1.holidayDate,
      holidayDesc: data1.holidayDesc,
      branchId: data1.branchId,
      holidayType: data1.holidayType,
    };
    this.setState({
      holidayDate: data1.holidayDate,
      holidayDesc: data1.holidayDesc,
      branchId: data1.branchId,
      holidayType: data1.holidayType,
    });
    this.props.HolidayMasterList(
      serverApi.GET_ALL_HOLIDAY_MASTER_LIST,
      data,
      this.props.history
    );
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.holidayMasterListData}
          mcolumns={this.state.holidayMasterList}
          micon={GridArrowIcon}
          actions={true}
          url="/create_holiday"
          handleSearch={this.handleSearch}
          filter_form={"HolidayMaster"}
        />
      </>
    );
  }
}
const mapStatetoProps = (state) => {
  const { holiday_master_List } = state.HolidayListReducer;
  return { holiday_master_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    HolidayMasterList,
  })(HolidayCalendar)
);
