import { logoutUser } from "../../store/actions";
import React, { Component, Fragment } from "react";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import CustomButton from "../Buttons/Buttons";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import filterFactory from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from "../CheckBox/Checkbox";
import { withRouter } from "react-router-dom";
import Button from "../Buttons/Buttons";
import "../../assets/css/style.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import {getTenantId, serverApi} from "../../helpers/Consts";

import { Dropdown, DropdownToggle } from "reactstrap";
import ToolkitProvider, {
  ColumnToggle,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import MonthPicker from "../MonthPicker/MonthPicker";
import { getExportDataList } from "../../store/Global/DropDownApis/actions";
import { CSVLink } from "react-csv";
import LoadingImage from "../../assets/images/loading.gif";
import ReactPaginate from "react-paginate";
import FilterComponet from "./filterComponet";

const CustomToggleList = ({ columns, onColumnToggle, toggles }) => (
  <div
    className="btn-group btn-group-toggle btn-group-vertical"
    data-toggle="buttons"
  >
    {columns
      .map((column) => ({
        ...column,
        toggle: toggles[column.dataField],
      }))
      .map((column) => (
        <div className="form-check form-check-inline">
          <Checkbox
            label={column.text}
            value={column.text}
            selected={column.hidden ? false : true}
            onChange={() => {
              onColumnToggle(column.dataField);
            }}
          />
        </div>
      ))}
  </div>
);

class Gridwithcustomview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      productsData: this.props.mdata,
      menu1: "none",
      filterList: [],
      searchLists: [],
      checkLists: [],
      activeFilter: [],
      pathName: this.props.url,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      years: 0,
      year_data: "",
      name: "update the indent",
      ShowApp_rej: false,
      showCustonView: false,
      showFilter: false,
      testState: false,
      filter_form: this.props.filter_form,
      exportlistdata: "",
      columnsCSV: [],
      columns: [
        {
          text: "",
          dataField: "",
          headerAlign: "",
          align: "",
          hidden: false,
        },
      ],
    };
  }

  componentDidMount() {
    var mcolumns = this.props.mcolumns;
    if (mcolumns) {
      this.setState({
        columns: [],
      });
      const columnsnew = [];
      for (let i = 0; i < mcolumns.length; i++) {
        var coldata = {
          dataField: mcolumns[i].accessor,
          text: mcolumns[i].Header,
          headerAlign: "left",
          align: "left",
          hidden: mcolumns[i].hidden,
        };
        columnsnew.push(coldata);
      }
      if (this.props.actions) {
        var actions = {
          dataField: "actions",
          text: "Actions",
          headerAlign: "left",
          align: "left",
          hidden: false,
        };
        columnsnew.push(actions);
      }
      if (this.props.rowAction) {
        var actions = {
          dataField: "actions",
          text: "Actions",
          headerAlign: "left",
          align: "left",
          hidden: false,
        };
        columnsnew.push(actions);
      }
      if (this.props.inputFields) {
        var actioninputFieldss = {
          dataField: "inputFields",
          text: "Actions",
          headerAlign: "left",
          align: "left",
          hidden: false,
        };
        columnsnew.push(actioninputFieldss);
      }

      this.setState({
        columns: columnsnew,
      });
    }
  }
  componentWillReceiveProps(props) { }

  getListData = (res) => {
    let response = [];
    let columnsCSVData = [];
    if (this.props.type) {
      var objdata = res?.data?.data.map((row) => {
        row.mukam.itemList = row.itemList;
        return row.mukam;
      });
      response = objdata;
    } else {
      response = res?.data?.data;
    }
    const cust_columns = this.state.columns.filter((coumn) => {
      if (!coumn.hidden && coumn.text !== "Actions") {
        var obj = { key: coumn.dataField, label: coumn.text };
        columnsCSVData.push(obj);
        return coumn;
      }
    });

    const response_data = [];
    response.map((data) => {
      var dataobj = {};
      Object.keys(data).map((key, index) => {
        cust_columns?.filter((col) => {
          if (key === col.dataField) {
            dataobj[key] = data[key];
          }
        });
      });
      response_data.push(dataobj);
    });
    this.setState({
      exportlistdata: response_data,
      columnsCSV: columnsCSVData,
    });
  };
  handleExportClick = () => {
    this.setState({
      showMoreOpts: false,
      isDropdown: false,
    });
  };

  ExportCSVList = () => {
      this.props.getExportDataList(
        this.props.apiexp,
        this.props.payloadexp,
        this.getListData
      );
  };

  handlePrint = (row) => {
    console.log(row, "row")
      fetch(
        serverApi.REGULAR_INVOICE_PDF_DOWNLOAD +
          "1/" +
          row.invoiceId +
          "/" +
          JSON.parse(localStorage.getItem("authUser")).userId +
          "/" +
          1,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            "X-TenantId": getTenantId(window.location.hostname),
            Authorization:
              localStorage.getItem("token_type") +
              localStorage.getItem("access_token"),
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", row.invoiceNumber + ".pdf");
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
  };

  handleExportBills = () => {
    let headers = {
        Authorization: localStorage.getItem("token_type") + localStorage.getItem("access_token"),
        Accept: "application/json",
        "Content-Type": "application/json",
    };

    const tenantId = getTenantId(window.location.hostname);
    if (tenantId !== null) {
        headers = {
            ...headers,
            "X-TenantId": tenantId,
            CompanyID: localStorage.getItem("CompanyId"),
        };
    }

    fetch(serverApi.BILLS_EXPORT, {
        method: "POST", // Change method to "POST"
        headers: headers,
        body: JSON.stringify(this.props.payloadexp)
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        return response.blob();
    })
    .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "AccountingBills.xlsx";
        a.click();
    })
    .catch((error) => {
        console.error("Error occurred while downloading file:", error);
        // Optionally display an error message to the user
    });
};


  handlePageClick = (e) => {
    const selectedPage = e.selected + 1;
    const offset = selectedPage * this.state.lastIndex;
    this.setState({
      currentPage: selectedPage,
      offset: offset,
    });

    this.props.handlePagination(selectedPage, 10, 2);
  };
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "years") {
      this.setState({
        years: selectedValue,
        year_data: selectedName,
      });
    }
  };
  hide = () => {
    if (this.state.menu === "none") {
      this.setState({ menu: "block" });
    } else {
      this.setState({ menu: "none" });
    }
  };
  state = { showing: false };
  priceFormatter = (column, colIndex) => {
    return (
      <div style={{ position: "relative" }}>
        {column.text}
        <button
          onClick={() => this.getTableData()}
          className="filterDropdownArrow"
        >
          <ArrowDropDownIcon />
        </button>
        <div className="filterContainer" style={{ display: this.state.menu1 }}>
          <form>
            <div className="columnFilterContainer">
              <div className="columnFilterCheckBoxContent">
                {this.state.checkLists.map((filter) => (
                  <div className="columnFilterList">
                    <Checkbox
                      id={filter.id}
                      handleCheckChieldElement={() =>
                        this.onFilterChange(filter.source)
                      }
                    />
                    <label htmlFor={filter.id}>{filter.name}</label>
                  </div>
                ))}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };
  getTableData() {
    if (this.state.menu1 === "none") {
      this.setState({ menu1: "block" });
    } else {
      this.setState({ menu1: "none" });
    }
    const filterList = [];
    const activeFilter = [];
    const checkLists = [];

    this.props.mdata.forEach((product) => {
      var arr = {
        id: product.id + 100,
        name: product.name,
        value: product.value,
        source: product.name.replace(/ /g, ""),
      };
      var arr2 = {
        id: product.id + 100,
        name: product.name,
        value: product.value,
        source: product.name.replace(/ /g, ""),
      };

      var arr1 = {
        id: product.id,
        type: product.name.replace(/ /g, ""),
        name: product.name,
        source: product.name.replace(/ /g, ""),
        value: product.value,
      };
      filterList.push(arr);
      activeFilter.push(arr1);
      checkLists.push(arr2);
    });

    let checkListsF = checkLists.filter(
      (ele, ind) =>
        ind === checkLists.findIndex((elem) => elem.name === ele.name)
    );

    this.setState({
      filterList: filterList,
      searchLists: activeFilter,
      checkLists: checkListsF,
    });
  }

  onFilterChange(filter) {
    const { filterList, activeFilter } = this.state;
    if (filter === "ALL") {
      if (activeFilter.length === filterList.length) {
        this.setState({ activeFilter: [] });
      } else {
        this.setState({
          activeFilter: filterList.map((filters) => filters.value),
        });
      }
    } else {
      if (activeFilter.includes(filter)) {
        const filterIndex = activeFilter.indexOf(filter);
        const newFilter = [...activeFilter];
        newFilter.splice(filterIndex, 1);
        this.setState({ activeFilter: newFilter });
      } else {
        this.setState({ activeFilter: [...activeFilter, filter] });
      }
    }
  }
  handleClick = (pathUrl) => {
    if (pathUrl) {
      this.props.history.push(`/${pathUrl}`);
    }
  };

  render() {
    const mdata = this.props.mdata;
    if (this.props.loading === true) {
      return (
        <div className="loading">
          <img src={LoadingImage} alt="" />
        </div>
      );
    }

    if (mdata) {
      var products = mdata.map((row, order) => ({
        ...row,
        chk: (
          <div className="checkbox form-check">
            <div className="custom-checkbox custom-control custom-control-inline">
              <input
                type="checkbox"
                className="custom-control-input"
                onChange={this.handleChange}
                value={row.id}
                id={row.id}
              />
              <label className="custom-control-label" for={row.id}></label>
            </div>{" "}
          </div>
        ),
        actions: (
          <Fragment>
            {
              this.props.actions && (
              <CustomButton
                className="gridPlusButton"
                icon={this.props.micon} 
                handleClick={(e, id) => {
                  localStorage.removeItem("ProgressBar");
                  this.props.history.push(this.state.pathName, {
                    // textName: 2,
                    // rowData: row.taxId,
                    rowData: row,
                  });
                }}
            />)}
            {
                this.props.rowAction && (<CustomButton
                className="gridPlusButton"
                icon={this.props.rowIcon} 
                handleClick={(e, id) => {
                  this.handlePrint(row)
                }}/>
                )
              }
                      
          </Fragment>
        ),
        inputFields: (
          <Fragment>
            <CustomButton
              className="gridPlusButton"
              icon={this.props.micon}
              handleClick={(e, id) => {
                localStorage.removeItem("ProgressBar");
                this.props.history.push(this.state.pathName, {
                  // textName: 2,
                  // rowData: row.taxId,
                  rowData: row,
                });
              }}
            />
          </Fragment>
        ),
      }));
    } else {
      var products = [];
    }
  
    const customHandleToggleColumnVisiblity = (dataField) => {
      const updateHiddenProperty = (column) => {
        if (column.hidden === undefined) {
          return true;
        } else {
          return !column.hidden;
        }
      };

      const newState = this.state.columns.map((column) => {
        if (column.dataField === dataField) {
          return {
            ...column,
            hidden: updateHiddenProperty(column),
          };
        }
        return column;
      });
      this.setState({
        columns: newState,
      });
      this.props.getExportDataList(
        this.props.apiexp,
        this.props.payloadexp,
        this.getListData
      );
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
    };

    const selectRowFalse = {
      mode: "checkbox",
      clickToSelect: true,
      headerColumnStyle: {
        display: "none",
      },

      selectColumnStyle: {
        display: "none",
      },
    };

    return (
      <div className="gridMonthSelection">
        <div className="gridContainer">
          <div className="gridComponent">
            <ToolkitProvider
              keyField="companyId"
              columns={this.state.columns}
              data={products}
              columnToggle
              search
              exportCSV={{
                fileName: this.state.filter_form,
                exportAll: true,
                noAutoBOM: false,
                blobType: "text/csv;charset=ansi",
              }}
            >
              {(toolkitprops) => (
                <div>
                  <div className="buttonBlock">
                    <Grid item xs={12} md={3}>
                      {this.props.monthPicker && <MonthPicker />}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <div style={{ display: "flex", position: "relative" }}>
                        {this.props.fromDate && this.props.toDate &&
                          <div style={{ marginTop: "5px" }}>
                            <span
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                                color: "#969c99",
                                fontWeight: "600",
                              }}
                            >
                              From {this.props.fromDate}
                            </span>
                            <span
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                                color: "#969c99",
                                fontWeight: "600",
                              }}
                            >
                              To
                            </span>
                            <span
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                                color: "#969c99",
                                fontWeight: "600",
                              }}
                            >
                              {this.props.toDate}
                            </span>
                          </div>}
                        { this.props.isExcelExport &&
                          <Button
                          label={this.props.exportButtonLabel}
                          className="lightBlueButton"
                          muIcon={<SystemUpdateAltIcon sx={{ fontSize: 16, marginLeft: 1 }} />}
                          handleClick={() => {this.handleExportBills()}}
                        />
                        }
                        { !this.props.isExcelExport && 
                          <Button
                          label="More Actions"
                          className="lightBlueButton"
                          muIcon={<KeyboardArrowDownIcon />}
                          handleClick={() => {
                            this.setState({
                              showMoreOpts: !this.state.showMoreOpts,
                              isDropdown: false,
                            });
                            this.ExportCSVList()
                          }}
                        />
                        }
                       
                        <Dropdown
                          toggle={() =>
                            this.setState({
                              // isDropdown: !this.state.isDropdown,
                            })
                          }
                        >
                          <DropdownToggle className="custom_view">
                            <CustomButton
                              label="Customize View"
                              type="button"
                              className="lightBlueButton"
                              handleClick={() => {
                                this.setState({
                                  isDropdown: !this.state.isDropdown,
                                });
                              }}
                            />
                          </DropdownToggle>
                          {this.state.isDropdown && (
                            <>
                              <div
                                className={
                                  this.state.isDropdown === true
                                    ? "backdropNew"
                                    : ""
                                }
                                onClick={() => {
                                  this.setState({
                                    isDropdown: !this.state.isDropdown,
                                  });
                                }}
                              ></div>
                              <div className="customizeViewContainer">
                                <div
                                  className="customizeViewContent"
                                  aria-expanded="true"
                                >
                                  <CustomToggleList
                                    onColumnToggle={
                                      customHandleToggleColumnVisiblity
                                    }
                                    columns={
                                      toolkitprops.columnToggleProps.columns
                                    }
                                    toggles={
                                      toolkitprops.columnToggleProps.columns
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </Dropdown>

                        {this.state.showMoreOpts && (
                          <div className="more_actions">
                            <div className="Invisible"
                              onClick={this.handleExportClick}
                            > </div>
                            <div style={{ dispay: "flex" }}>
                              <CSVLink
                                data={this.state.exportlistdata}
                                headers={this.state.columnsCSV}
                                asyncOnClick={true}
                                className="more_orderList"
                                filename={this.props.expName}
                              >
                                <FileUploadOutlinedIcon />
                                <span>Export list</span>
                              </CSVLink>
                            </div>

                          </div>
                        )}
                      </div>

                      <div className="searchBlock" onClick={() => {
                        this.setState({
                          showFilter: !this.state.showFilter,
                        });
                      }}
                      >
                        {this.state.filter_form && (
                          <FilterComponet
                            toolkitprops={toolkitprops}
                            columns={this.state.columns}
                            showFilter={this.state.showFilter}
                            handle_search={this.props.handleSearch}
                            filter_form={this.props.filter_form} />
                        )}
                      </div>
                    </Grid>
                  </div>

                  <div className="customizeViewContainer" style={{ display: this.state.menu }} >
                    <span className="customizeViewContent"></span>
                  </div>
                  <div class="cardViewWithPagination">
                    <BootstrapTable
                      {...toolkitprops.baseProps}
                      selectRow={
                        this.state.ShowApp_rej ? selectRow : selectRowFalse
                      }
                      defaultSortDirection="asc"
                      striped
                      noDataIndication="No Data Is Available"
                      keyField="id"
                      filter={filterFactory()}
                    />
                    <ReactPaginate
                      previousLabel={"< Prev"}
                      nextLabel={"Next >"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.props.totalRecs / 10}
                      onPageChange={this.handlePageClick}
                      containerClassName={"cardViewPagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
            {this.state.ShowApp_rej && (
              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Approve"}
                  className="greenButton"
                  style={{ marginRight: "10px" }}
                  handleClick={this.onClickNext}
                  type="sumbit"
                />

                <CustomButton
                  label={"Reject"}
                  className="rejected"
                  handleClick={this.onClickNext}
                  type="sumbit"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { exportlistdata } = state.DropDownListReducer;

  return {
    exportlistdata,
  };

};
export default withRouter(
  connect(mapStatetoProps, { logoutUser, getExportDataList })(
    Gridwithcustomview
  )
);
