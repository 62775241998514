import React, { Component } from "react";
import { Grid, Step, Stepper, StepLabel } from "@mui/material";
import Link from "@mui/material/Link";
import CustomButton from "../../../components/Buttons/Buttons";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import {
  addPriceEnquiry,
  PriceEnquiryLineitemsProps,
  getPriceEnquiryById,
} from "../../../store/Purchase/PriceEnquiry/actions";
import PriceEnquiryFilldetails from "./PriceEnquiryFilldetails";
import { ApprovalHierarchy } from "../../../store/HRMS/EmployeeDataBase/actions";
import swal from "sweetalert";
import PriceEnquiryPreview from "./PriceEnquiryPreview";
import PriceEnquiryLineItems from "./PriceEnquiryLineItems";
import moment from "moment";
class CreatePrice_Enquiry extends Component {
  constructor() {
    super();
    this.state = {
      steps: [
        {
          title: "Fill Details",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Add Items",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Preview",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive03.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
      ],
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      currentStep: 1,
      confirm: false,
      RejectButton: false,
      UpdateButton: false,
      ApproveButton: false,
      CancelButton: true,
      hdrdto: {
        comments: "",
        priceRequestDate: moment().format("YYYY-MM-DD"),
        priceRequestStatus: "",
        priceRequestSubject: "",
        createdBy: "",
        companyId: "",
        branchId: null
      },
      dtlList: [
        {
          companyId: localStorage.getItem("companyId"),
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          indentNo: "",
          isRateActive: 1,
          isActive: 1,
          itemId: "",
          itemGroupCode: "",
          suppCode: "",
          itemRate: "",
          suppComments: "",
          suppQuoteFile: "",
          suppResponseDateTime: "",
          itemDesc: ""
        }
      ],
    
    };
  }

  componentDidMount() {
   
  }

  componentWillReceiveProps(props) {
    
  }
 

  onClickNext = () => {
   
  };

  onClickBefore = () => {
    const { currentStep } = this.state;
    if (currentStep > 1) {
      this.setState({
        currentStep: currentStep - 1,
      });
    } else {
      this.props.history.push("/purchase_price_request");
    }
  };
  QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;

    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };

  onConfirm = () => {
   
  };

  onUpdate = () => {
  
   
  };
  handleCurrentStep = () => {
    this.setState({
      currentStep: 1,
    });
  };

  render() {
    const { steps, currentStep } = this.state;
    return (
      <div>
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            <div className="iconsBlock">
              <Stepper alternativeLabel activeStep={currentStep}>
                {steps.map((prop) => (
                  <Step key={prop.title}>
                    <StepLabel
                      StepIconComponent={(Iconprops) => {
                        return this.QontoStepIcon(
                          Iconprops,
                          prop.icon,
                          prop.activeIcon,
                          prop.title
                        );
                      }}
                    >
                      {/* {prop.title} */}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {currentStep === 1 && (
              <>
                <PriceEnquiryFilldetails
              hdrdto={this.state.hdrdto}
              dtlList={this.state.dtlList}
                />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="greenButton"
                    handleClick={this.onClickNext}
                    type="sumbit"
                  />
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                <PriceEnquiryLineItems
                  hdrdto={this.state.hdrdto}
                  dtlList={this.state.dtlList}
                />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="greenButton"
                    handleClick={this.onClickNext}
                    type="sumbit"
                  />
                </div>
              </>
            )}
            {currentStep === 3 && (
              <>
                <PriceEnquiryPreview
                 hdrdto={this.state.hdrdto}
                 dtlList={this.state.dtlList}
                />
                <div className="consoleFormButtonBlock">
                  {this.props.location.state !== undefined &&
                  this.state.ApproveButton ? (
                    <CustomButton
                      label={"APPROVE"}
                      className="greenBorderButton"
                      handleClick={() => {
                        this.onClickApprove(this.state.PoFillDetails.status);
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {this.props.location.state !== undefined &&
                  this.state.RejectButton ? (
                    <CustomButton
                      label={"REJECT"}
                      className="greenButton"
                      handleClick={() => {
                        this.onClickReject("4");
                      }}
                      type="sumbit"
                    />
                  ) : (
                    ""
                  )}
                  {this.props.location.state !== undefined &&
                  this.state.UpdateButton ? (
                    <CustomButton
                      label={"UPDATE"}
                      className="greenBorderButton"
                      handleClick={() => {
                        this.onUpdate("");
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {this.props.location.state !== undefined ? (
                    ""
                  ) : (
                    <CustomButton
                      label={"Back"}
                      className="greenBorderButton"
                      handleClick={this.onClickBefore}
                      type="sumbit"
                    />
                  )}
                  {this.props.location.state !== undefined ? (
                    ""
                  ) : (
                    <CustomButton
                      label={"Create"}
                      className="greenButton"
                      handleClick={this.onConfirm}
                    />
                  )}
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { PELineitems, price_enquiry_ListBYID } =
    state.PriceEnquiryReducer;
  const { approval_hierarchy } = state.EmployeeReducer;

  return {
    PELineitems,
    price_enquiry_ListBYID,
    approval_hierarchy,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    addPriceEnquiry,
    PriceEnquiryLineitemsProps,
    getPriceEnquiryById,
    ApprovalHierarchy,
  })(CreatePrice_Enquiry)
);
