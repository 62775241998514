import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import { getCustomerPaginatedList } from "../../../store/Master/CustomerMaster/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      customerListData: [], //displaying customr list data
      custname: "",
      custphone: "",
      custemail: "",
      ledgergroup: "",
      contactPerson: "",
      dataexp: "",
    };
  }

  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      customerName: this.state.custname,
      customerContactNo: this.state.custphone,
      customerEmailId: this.state.custemail,
      ledgerGroup: this.state.ledgergroup,
      customerContactPerson: this.state.contactPerson,
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
    };
    this.props.getCustomerPaginatedList(
      serverApi.GET_ALL_CUSTOMER_PAGINATED_LIST,
      data,
      this.props.history
    ); // calling customer pagination list api
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.Customer_Paginated_List) {
      this.setState({
        totalRecs: props.Customer_Paginated_List.totalRecords,
        customerListData: props.Customer_Paginated_List.data, //updating customer list data
      });
    }
  }
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const data = {
        cipher: this.state.userDit.cipher,
        companyId: localStorage.getItem("companyId"),

        customerName: this.state.custname,
        customerContactNo: this.state.custphone,
        customerEmailId: this.state.custemail,
        ledgerGroup: this.state.ledgergroup,
        customerContactPerson: this.state.contactPerson,

        startIndex: sizePerPage,
        lastIndex: currentIndex,
      };
      if (currentIndex >= 0) {
        this.props.getCustomerPaginatedList(
          serverApi.GET_ALL_CUSTOMER_PAGINATED_LIST,
          data,
          this.props.history
        ); // CALLING customer MASTER LIST API
      }
    } else {
      const data = {
        cipher: this.state.userDit.cipher,
        companyId: localStorage.getItem("companyId"),

        customerName: this.state.custname,
        customerContactNo: this.state.custphone,
        customerEmailId: this.state.custemail,
        ledgerGroup: this.state.ledgergroup,
        customerContactPerson: this.state.contactPerson,
        startIndex: currentIndex === 0 ? currentIndex + 1 : currentIndex,
        lastIndex: sizePerPage,
      };
      if (currentIndex >= 0) {
        this.props.getCustomerPaginatedList(
          serverApi.GET_ALL_CUSTOMER_PAGINATED_LIST,
          data,
          this.props.history
        ); // CALLING customer MASTER LIST API
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };

  handleSearch = (data) => {
    const searchdata = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),

      customerName: data.name,
      customerContactNo: data.contactNo,
      customerEmailId: data.emailId,
      ledgerGroup: data.ledgerGroup,
      customerContactPerson: data.contactPerson,

      startIndex: 1,
      lastIndex: 10,
    };
    this.props.getCustomerPaginatedList(
      serverApi.GET_ALL_CUSTOMER_PAGINATED_LIST,
      searchdata,
      this.props.history
    );
    this.setState({
      custname: data.name,
      custphone: data.contactNo,
      custemail: data.emailId,
      ledgergroup: data.ledgerGroup,
      contactPerson: data.contactPerson,
    });
  };
  render() {
    return (
      <>
        <PaginationGridView
          mdata={this.state.customerListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={false}
          actions={true}
          handleSearch={this.handleSearch}
          filter_form={"CustomerMaster"}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          url="/create_Customer_Master"
          apiexp={serverApi.GET_ALL_CUSTOMER_PAGINATED_LIST}
          payloadexp={this.state.dataexp}
          expName={"Customer-Master.csv"}
        />
      </>
    );
  }
}
const Columns = [
  {
    Header: "Customer Name",
    accessor: "name",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Contact Number",
    accessor: "contactNo",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Contact Person",
    accessor: "contactPerson",
    hidden: false,
  },
  {
    Header: "Email",
    accessor: "emailId",
    headerAlign: "left",
    align: "left",
    hidden: false,
  }
];

const mapStatetoProps = (state) => {
  const { Customer_Paginated_List } = state.CustomerListReducer; // fetching list data from reducer
  return { Customer_Paginated_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getCustomerPaginatedList,
  })(Index)
);
