export const InwardCols = [
  {
    Header: "Inward Id",
    accessor: "id",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Inward Number",
    accessor: "inwardSequenceNo",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Inward Date",
    accessor: "inwardDate",
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Purchase Orders",
    accessor: "poSequence",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Supplier Name",
    accessor: "supplierName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Record Type",
    accessor: "recordType",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Dispatch Date",
    accessor: "dispatchDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Expected InwardDate",
    accessor: "expectedInwardDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Vehicle Number",
    accessor: "vehicleNumber  ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Driver Contact Number",
    accessor: "driverContactNumber",
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  
  {
    Header: "Status Name",
    accessor: "statusName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Source",
    accessor: "source",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Dispatch Remarks",
    accessor: "dispatchRemarks",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Receipts Remarks",
    accessor: "receiptsRemarks",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Invoice Number",
    accessor: "invoiceNumber",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Invoice Date",
    accessor: "invoiceDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Invoice Recieved Date",
    accessor: "invoiceRecvdDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Invoice Due Date",
    accessor: "invoiceDueDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Invoice Amount",
    accessor: "invoiceAmount",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Challan Number",
    accessor: "challanno",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Challan Number Date",
    accessor: "challannoDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Consignment Number",
    accessor: "consignmentNumber",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Consignment Date",
    accessor: "consignmentDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Eway Bill Number",
    accessor: "ewaybillno",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ewaybill Number Date",
    accessor: "ewaybillnoDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Store Receipt Number",
    accessor: "storeReceiptNo",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Store Receipt Date",
    accessor: "storeReceiptDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Store Receipt Actual Date",
    accessor: "indentStatusName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Store Receipt Value",
    accessor: "storeReceiptValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Creditnote Value",
    accessor: "creditnoteValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Debitnote Cgst Value",
    accessor: "debitnoteCgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Debitnote Igst Value",
    accessor: "debitnoteIgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Debitnote Sgst Value",
    accessor: "debitnoteSgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Creditnote Cgst Value",
    accessor: "creditnoteCgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Creditnote Igst Value",
    accessor: "creditnoteIgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Creditnote Sgst Value",
    accessor: "creditnoteSgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Alert MailId",
    accessor: "AlertMailId ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Creditnote Reason",
    accessor: "creditnoteReason",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Debitnote Reason",
    accessor: "debitnoteReason",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Remarks",
    accessor: "remarks",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill To Address",
    accessor: "billToAddress",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill To State Code",
    accessor: "billToStateCode",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill To State Name",
    accessor: "billToStateName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ship To Address",
    accessor: "shipToAddress ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ship To State Code",
    accessor: "shipToStateCode",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ship To State Name",
    accessor: "shipToStateName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Company Id",
    accessor: "companyId",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "BranchId",
    accessor: "branchId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Project Id",
    accessor: "projectId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Phase Id",
    accessor: "phaseId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des CompanyId",
    accessor: "desCompanyId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des Branch Id",
    accessor: "desBranchId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des Project Id",
    accessor: "desProjectId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des PhaseId",
    accessor: "desPhaseId ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Debitnote Number",
    accessor: "debitnoteNumber",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill Pass Number",
    accessor: "billpassNumber",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Customer Id",
    accessor: "customerId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Customer Name",
    accessor: "customerName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill Pass Date",
    accessor: "billpassDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill Pass ApproveDate",
    accessor: "billpassApproveDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tcs Percentage",
    accessor: "tcsPercentage",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tcs Amount",
    accessor: "desProjectId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tds Percentage",
    accessor: "tdsPercentage ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tds Amount",
    accessor: "tdsAmount",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tds Payable",
    accessor: "tdsPayable",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tds Reason",
    accessor: "tdsReason",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "RoundOff Value",
    accessor: "roundOffValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Po Id",
    accessor: "poId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Company Name",
    accessor: "companyName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Branch Name",
    accessor: "branchName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Project Name",
    accessor: "projectName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Phase Name",
    accessor: "phaseName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des CompanyName",
    accessor: "desCompanyName",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des BranchName",
    accessor: "desBranchName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des ProjectName",
    accessor: "desProjectName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des PhaseName",
    accessor: "desPhaseName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  // {
  //   Header: "Inward Details",
  //   accessor: "inwardDetails",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: true,
  // },
  {
    Header: "Time",
    accessor: "time",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Internal Note",
    accessor: "internalNote",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Last ModifiedBy",
    accessor: "lastModifiedBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Is Committed",
    accessor: "isCommitted",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Inspection Check",
    accessor: "inspectionChk",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "User Name",
    accessor: "userName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Billpass Status Id",
    accessor: "billpassStatusId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bp Status Name",
    accessor: "bpStatusName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Sr Status Id",
    accessor: "srStatusId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Isr Status Name",
    accessor: "srStatusName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Status Desc",
    accessor: "statusDesc",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Approve Button",
    accessor: "approveButton",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Sr Approve Button",
    accessor: "srApproveButton",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bp Approve Button",
    accessor: "bpApproveButton",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tax Type",
    accessor: "taxType",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tax Type Desc",
    accessor: "taxTypeDesc",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Accept Status",
    accessor: "acceptStatus",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
];

export const WorkReportCols = [
  {
    Header: "Work Report Id",
    accessor: "id",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Work Report Number",
    accessor: "inwardSequenceNo",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Work Report Date",
    accessor: "inwardDate",
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Work Orders",
    accessor: "poSequence",
    headerAlign: "left",
    align: "left",
    hidden:false,
  },
  {
    Header: "Supplier Name",
    accessor: "supplierName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Status Name",
    accessor: "statusName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Branch Name",
    accessor: "branchName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Project Name",
    accessor: "projectName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Invoice Number",
    accessor: "invoiceNumber",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Invoice Date",
    accessor: "invoiceDate",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Record Type",
    accessor: "recordType",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Dispatch Date",
    accessor: "dispatchDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Expected Work Report Date",
    accessor: "expectedInwardDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Vehicle Number",
    accessor: "vehicleNumber  ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Driver Contact Number",
    accessor: "driverContactNumber",
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

 
  {
    Header: "Source",
    accessor: "source",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Dispatch Remarks",
    accessor: "dispatchRemarks",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Receipts Remarks",
    accessor: "receiptsRemarks",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
 
 
  {
    Header: "Invoice Recvd Date",
    accessor: "invoiceRecvdDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Invoice Due Date",
    accessor: "invoiceDueDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Invoice Amount",
    accessor: "invoiceAmount",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Challan Number",
    accessor: "challanno",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Challan Number Date",
    accessor: "challannoDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Consignment Number",
    accessor: "consignmentNumber",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Consignment Date",
    accessor: "consignmentDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Eway Bill Number",
    accessor: "ewaybillno",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ewaybill Number Date",
    accessor: "ewaybillnoDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Store Receipt Number",
    accessor: "storeReceiptNo",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Store Receipt Date",
    accessor: "storeReceiptDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Store Receipt Actual Date",
    accessor: "indentStatusName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Store Receipt Value",
    accessor: "storeReceiptValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Creditnote Value",
    accessor: "creditnoteValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "DebitnoteCgst Value",
    accessor: "debitnoteCgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "DebitnoteIgst Value",
    accessor: "debitnoteIgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "DebitnoteSgst Value",
    accessor: "debitnoteSgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "CreditnoteCgst Value",
    accessor: "creditnoteCgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "CreditnoteIgst Value",
    accessor: "creditnoteIgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "CreditnoteSgst Value",
    accessor: "creditnoteSgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Alert MailId",
    accessor: "AlertMailId ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Creditnote Reason",
    accessor: "creditnoteReason",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Debitnote Reason",
    accessor: "debitnoteReason",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Remarks",
    accessor: "remarks",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill To Address",
    accessor: "billToAddress",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill To State Code",
    accessor: "billToStateCode",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill To State Name",
    accessor: "billToStateName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ship To Address",
    accessor: "shipToAddress ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ship To State Code",
    accessor: "shipToStateCode",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ship To State Name",
    accessor: "shipToStateName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Company Id",
    accessor: "companyId",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Branch Id",
    accessor: "branchId",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Project Id",
    accessor: "projectId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Phase Id",
    accessor: "phaseId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des CompanyId",
    accessor: "desCompanyId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des Branch Id",
    accessor: "desBranchId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des Project Id",
    accessor: "desProjectId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des PhaseId",
    accessor: "desPhaseId ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Debitnote Number",
    accessor: "debitnoteNumber",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Billpass Number",
    accessor: "billpassNumber",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Customer Id",
    accessor: "customerId",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "customer Name",
    accessor: "customerName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill Pass Date",
    accessor: "billpassDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill Pass Approve Date",
    accessor: "billpassApproveDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tcs Percentage",
    accessor: "tcsPercentage",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tcs Amount",
    accessor: "desProjectId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tds Percentage",
    accessor: "tdsPercentage ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tds Amount",
    accessor: "tdsAmount",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tds Payable",
    accessor: "tdsPayable",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tds Reason",
    accessor: "tdsReason",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "RoundOff Value",
    accessor: "roundOffValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Po Id",
    accessor: "poId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Company Name",
    accessor: "companyName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

  {
    Header: "Phase Name",
    accessor: "phaseName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des Company Name",
    accessor: "desCompanyName",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des Branch Name",
    accessor: "desBranchName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des Project Name",
    accessor: "desProjectName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des Phase Name",
    accessor: "desPhaseName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  // {
  //   Header: "Inward Details",
  //   accessor: "inwardDetails",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: true,
  // },
  {
    Header: "Time",
    accessor: "time",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Internal Note",
    accessor: "internalNote",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Last ModifiedBy",
    accessor: "lastModifiedBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Is Committed",
    accessor: "isCommitted",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Inspection Check",
    accessor: "inspectionChk",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "UserName",
    accessor: "userName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Billpass StatusId",
    accessor: "billpassStatusId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bp Status Name",
    accessor: "bpStatusName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Sr StatusId",
    accessor: "srStatusId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Isr Status Name",
    accessor: "srStatusName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Status Desc",
    accessor: "statusDesc",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Approve Button",
    accessor: "approveButton",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Sr Approve Button",
    accessor: "srApproveButton",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bp Approve Button",
    accessor: "bpApproveButton",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tax Type",
    accessor: "taxType",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tax TypeDesc",
    accessor: "taxTypeDesc",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Accept Status",
    accessor: "acceptStatus",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

];

export const materialListColumns = [
  {
    Header: "Material Inspection id",
    accessor: "id",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Inward Number",
    accessor: "inwardSequenceNo",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Inward Date",
    accessor: "inwardDate",
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Purchase Orders",
    accessor: "poSequence",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
 
  {
    Header: "Supplier Name",
    accessor: "supplierName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
 
  {
    Header: "Record Type",
    accessor: "recordType",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Dispatch Date",
    accessor: "dispatchDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Expected Material Inspection Date",
    accessor: "expectedInwardDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Vehicle Number",
    accessor: "vehicleNumber  ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Driver Contact Number",
    accessor: "driverContactNumber",
    minWidth: 100,
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

  {
    Header: "Status",
    accessor: "statusName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Source",
    accessor: "source",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Dispatch Remarks",
    accessor: "dispatchRemarks",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Receipts Remarks",
    accessor: "receiptsRemarks",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Invoice Number",
    accessor: "invoiceNumber",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Invoice Date",
    accessor: "invoiceDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Invoice RecvdDate",
    accessor: "invoiceRecvdDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Invoice Due Date",
    accessor: "invoiceDueDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Invoice Amount",
    accessor: "invoiceAmount",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Challan Number",
    accessor: "challanno",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Challan Number Date",
    accessor: "challannoDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Consignment Number",
    accessor: "consignmentNumber",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Consignment Date",
    accessor: "consignmentDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Eway Bill Number",
    accessor: "ewaybillno",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ewaybill Number Date",
    accessor: "ewaybillnoDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Store Receipt Number",
    accessor: "storeReceiptNo",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Store Receipt Date",
    accessor: "storeReceiptDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Store Receipt Actual Date",
    accessor: "indentStatusName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Store Receipt Value",
    accessor: "storeReceiptValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Creditnote Value",
    accessor: "creditnoteValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "DebitnoteCgst Value",
    accessor: "debitnoteCgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "DebitnoteIgst Value",
    accessor: "debitnoteIgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "DebitnoteSgst Value",
    accessor: "debitnoteSgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "CreditnoteCgst Value",
    accessor: "creditnoteCgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "CreditnoteIgst Value",
    accessor: "creditnoteIgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "CreditnoteSgst Value",
    accessor: "creditnoteSgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Alert MailId",
    accessor: "AlertMailId ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Creditnote Reason",
    accessor: "creditnoteReason",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Debitnote Reason",
    accessor: "debitnoteReason",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Remarks",
    accessor: "remarks",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill To Address",
    accessor: "billToAddress",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill To State Code",
    accessor: "billToStateCode",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill To State Name",
    accessor: "billToStateName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ship To Address",
    accessor: "shipToAddress ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ship To State Code",
    accessor: "shipToStateCode",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ship To State Name",
    accessor: "shipToStateName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "companyId",
    accessor: "companyId",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "BranchId",
    accessor: "branchId",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Project Id",
    accessor: "projectId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Phase Id",
    accessor: "phaseId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des CompanyId",
    accessor: "desCompanyId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "DesBranch Id",
    accessor: "desBranchId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "DesProject Id",
    accessor: "desProjectId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des PhaseId",
    accessor: "desPhaseId ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Debitnote Number",
    accessor: "debitnoteNumber",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Billpass Number",
    accessor: "billpassNumber",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Customer Id",
    accessor: "customerId",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "customer Name",
    accessor: "customerName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill Pass Date",
    accessor: "billpassDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Billpass ApproveDate",
    accessor: "billpassApproveDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tcs Percentage",
    accessor: "tcsPercentage",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tcs Amount",
    accessor: "desProjectId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tds Percentage",
    accessor: "tdsPercentage ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tds Amount",
    accessor: "tdsAmount",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tds Payable",
    accessor: "tdsPayable",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tds Reason",
    accessor: "tdsReason",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "RoundOff Value",
    accessor: "roundOffValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Po Id",
    accessor: "poId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Company Name",
    accessor: "companyName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Branch Name",
    accessor: "branchName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Project Name",
    accessor: "projectName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Phase Name",
    accessor: "phaseName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des CompanyName",
    accessor: "desCompanyName",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des BranchName",
    accessor: "desBranchName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des ProjectName",
    accessor: "desProjectName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des PhaseName",
    accessor: "desPhaseName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Time",
    accessor: "time",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Internal Note",
    accessor: "internalNote",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Last ModifiedBy",
    accessor: "lastModifiedBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Is Committed",
    accessor: "isCommitted",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Inspection Check",
    accessor: "inspectionChk",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "UserName",
    accessor: "userName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Billpass StatusId",
    accessor: "billpassStatusId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "bp StatusName",
    accessor: "bpStatusName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Sr StatusId",
    accessor: "srStatusId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Isr StatusName",
    accessor: "srStatusName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Status Desc",
    accessor: "statusDesc",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Approve Button",
    accessor: "approveButton",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Sr ApproveButton",
    accessor: "srApproveButton",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bp ApproveButton",
    accessor: "bpApproveButton",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tax Type",
    accessor: "taxType",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tax TypeDesc",
    accessor: "taxTypeDesc",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Accept Status",
    accessor: "acceptStatus",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  
];

export const SRColumns = [
  //Existing
  {
    Header: "GRN Number",
    accessor: "storeReceiptNo",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "GRN Date",
    accessor: "storeReceiptDate",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Dispatch Entry Number",
    accessor: "inwardSequenceNo",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Purchase Orders",
    accessor: "poSequence",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Supplier Name",
    accessor: "supplierName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "srStatusName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Branch",
    accessor: "branchName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Project",
    accessor: "projectName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Invoice Number",
    accessor: "invoiceNumber",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Invoice Date",
    accessor: "invoiceDate",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  //Existing end
  {
    Header: "Inward id",
    accessor: "id",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

  {
    Header: "Record Type",
    accessor: "recordType",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Dispatch Date",
    accessor: "dispatchDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Expected InwardDate",
    accessor: "expectedInwardDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Vehicle Number",
    accessor: "vehicleNumber  ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Driver ContactNumber",
    accessor: "driverContactNumber",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Inward Date",
    accessor: "inwardDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Status Name",
    accessor: "statusName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Source",
    accessor: "source",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Dispatch Remarks",
    accessor: "dispatchRemarks",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Receipts Remarks",
    accessor: "receiptsRemarks",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  // {
  //   Header: "Invoice Number",
  //   accessor: "invoiceNumber",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: true
  // },
  // {
  //   Header: "Invoice Date",
  //   accessor: "invoiceDate",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: true
  // },
  {
    Header: "Invoice RecvdDate",
    accessor: "invoiceRecvdDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Invoice Due Date",
    accessor: "invoiceDueDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Invoice Amount",
    accessor: "invoiceAmount",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Challan Number",
    accessor: "challanno",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Challan Number Date",
    accessor: "challannoDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Consignment Number",
    accessor: "consignmentNumber",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Consignment Date",
    accessor: "consignmentDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Eway Bill Number",
    accessor: "ewaybillno",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ewaybill Number Date",
    accessor: "ewaybillnoDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  // {
  //   Header: "Store Receipt No",
  //   accessor: "storeReceiptNo",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: true
  // },
  // {
  //   Header: "Store Receipt Date",
  //   accessor: "storeReceiptDate",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: true
  // },
  {
    Header: "Store Receipt Actual Date",
    accessor: "indentStatusName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Store Receipt Value",
    accessor: "storeReceiptValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Creditnote Value",
    accessor: "creditnoteValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "DebitnoteCgst Value",
    accessor: "debitnoteCgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "DebitnoteIgst Value",
    accessor: "debitnoteIgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "DebitnoteSgst Value",
    accessor: "debitnoteSgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "CreditnoteCgst Value",
    accessor: "creditnoteCgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "CreditnoteIgst Value",
    accessor: "creditnoteIgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "CreditnoteSgst Value",
    accessor: "creditnoteSgstValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Alert MailId",
    accessor: "AlertMailId ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Creditnote Reason",
    accessor: "creditnoteReason",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Debitnote Reason",
    accessor: "debitnoteReason",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Remarks",
    accessor: "remarks",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill To Address",
    accessor: "billToAddress",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill To State Code",
    accessor: "billToStateCode",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill To State Name",
    accessor: "billToStateName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ship To Address",
    accessor: "shipToAddress ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ship To State Code",
    accessor: "shipToStateCode",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Ship To State Name",
    accessor: "shipToStateName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "companyId",
    accessor: "companyId",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "BranchId",
    accessor: "branchId",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Project Id",
    accessor: "projectId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Phase Id",
    accessor: "phaseId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des CompanyId",
    accessor: "desCompanyId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "DesBranch Id",
    accessor: "desBranchId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "DesProject Id",
    accessor: "desProjectId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des PhaseId",
    accessor: "desPhaseId ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Debitnote Number",
    accessor: "debitnoteNumber",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Billpass Number",
    accessor: "billpassNumber",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Customer Id",
    accessor: "customerId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "customer Name",
    accessor: "customerName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bill Pass Date",
    accessor: "billpassDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Billpass ApproveDate",
    accessor: "billpassApproveDate",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tcs Percentage",
    accessor: "tcsPercentage",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tcs Amount",
    accessor: "desProjectId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tds Percentage",
    accessor: "tdsPercentage ",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tds Amount",
    accessor: "tdsAmount",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tds Payable",
    accessor: "tdsPayable",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tds Reason",
    accessor: "tdsReason",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "RoundOff Value",
    accessor: "roundOffValue",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Po Id",
    accessor: "poId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Company Name",
    accessor: "companyName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  // {
  //   Header: "BranchName",
  //   accessor: "branchName",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: true
  // },
  // {
  //   Header: "Project Name",
  //   accessor: "projectName",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: true
  // },
  {
    Header: "Phase Name",
    accessor: "phaseName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des CompanyName",
    accessor: "desCompanyName",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des BranchName",
    accessor: "desBranchName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des ProjectName",
    accessor: "desProjectName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des PhaseName",
    accessor: "desPhaseName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  // {
  //   Header: "Inward Details",
  //   accessor: "inwardDetails",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: true,
  // },
  {
    Header: "Time",
    accessor: "time",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Internal Note",
    accessor: "internalNote",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Last ModifiedBy",
    accessor: "lastModifiedBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Is Committed",
    accessor: "isCommitted",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Inspection Check",
    accessor: "inspectionChk",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "UserName",
    accessor: "userName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Billpass StatusId",
    accessor: "billpassStatusId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "bp StatusName",
    accessor: "bpStatusName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Sr StatusId",
    accessor: "srStatusId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  // {
  //   Header: "Isr StatusName",
  //   accessor: "srStatusName",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: true
  // },
  {
    Header: "Status Desc",
    accessor: "statusDesc",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Approve Button",
    accessor: "approveButton",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Sr ApproveButton",
    accessor: "srApproveButton",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bp ApproveButton",
    accessor: "bpApproveButton",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tax Type",
    accessor: "taxType",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tax TypeDesc",
    accessor: "taxTypeDesc",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Accept Status",
    accessor: "acceptStatus",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  // {
  //   Header: "Po Sequence",
  //   accessor: "poSequence",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: true
  // },
];

export const BillPassColumns = [
  {
    Header: "BillPass Id",
    accessor: "id",
    headerAlign: "left",
    align: "left",
    hidden: true
  },
  {
    Header: "Bill Pass Number",
    accessor: "billpassNumber",
    headerAlign: "left",
    align: "left",
    hidden: false
  },
  {
    Header: "Bill Pass Date",
    accessor: "billpassDate",
    headerAlign: "left",
    align: "left",
    hidden: false
  },
  {
    Header: "Bill Amount",
    accessor: "totalAmount",
    headerAlign: "left",
    align: "left",
    hidden: false
  },
  {
    Header: "Inward Number/Work Report Number",
    accessor: "inwardSequenceNo",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Sr Receipt Number",
    accessor: "storeReceiptNo",
    headerAlign: "left",
    align: "left",
    hidden: false
  },
  {
    Header: "Purchase Orders",
    accessor: "poSequence",
    headerAlign: "left",
    align: "left",
    hidden: false
  },
  {
    Header: "Supplier Name",
    accessor: "supplierName",
    headerAlign: "left",
    align: "left",
    hidden: false

  },
  {
    Header: "Status",
    accessor: "bpStatusName",
    headerAlign: "left",
    align: "left",
    hidden: false
  },
  {
    Header: "Branch",
    accessor: "branchName",
    headerAlign: "left",
    align: "left",
    hidden: false
  },
  {
    Header: "Project",
    accessor: "projectName",
    headerAlign: "left",
    align: "left",
    hidden: false
  },
  {
    Header: "Invoice Number",
    accessor: "invoiceNumber",
    headerAlign: "left",
    align: "left",
    hidden: false
  },
  {
    Header: "Invoice Date",
    accessor: "invoiceDate",
    headerAlign: "left",
    align: "left",
    hidden: false
    
  },
  
  {
    Header: "Phase Name",
    accessor: "phaseName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des CompanyName",
    accessor: "desCompanyName",

    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des BranchName",
    accessor: "desBranchName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des ProjectName",
    accessor: "desProjectName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Des PhaseName",
    accessor: "desPhaseName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  // {
  //   Header: "Inward Details",
  //   accessor: "inwardDetails",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: true,
  // },
  {
    Header: "Time",
    accessor: "time",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Internal Note",
    accessor: "internalNote",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Last ModifiedBy",
    accessor: "lastModifiedBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Is Committed",
    accessor: "isCommitted",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Inspection Check",
    accessor: "inspectionChk",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "UserName",
    accessor: "userName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Billpass StatusId",
    accessor: "billpassStatusId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  // {
  //   Header: "Bp StatusName",
  //   accessor: "bpStatusName",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: false,
  // },
  {
    Header: "Sr StatusId",
    accessor: "srStatusId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Isr StatusName",
    accessor: "srStatusName",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Status Desc",
    accessor: "statusDesc",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Approve Button",
    accessor: "approveButton",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Sr ApproveButton",
    accessor: "srApproveButton",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Bp ApproveButton",
    accessor: "bpApproveButton",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tax Type",
    accessor: "taxType",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Tax TypeDesc",
    accessor: "taxTypeDesc",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Accept Status",
    accessor: "acceptStatus",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
];
