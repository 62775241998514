import moment from "moment";
export const makePayloaddata = (state) => {
  const data = {
    aadharNo: state.aadharNo,
    accommodatioType: state.accommodatioType,
    active: state.active,
    appRoleId: state.appRoleId,
    approverButton: state.approverButton,
    autoDateTime: state.autoDateTime,
    bloodGroup: state.bloodGroup,
    branchId: state.branchId,
    branchIdDesc: state.branchIdDesc,
    cAddressLine1: state.cAddressLine1,
    cAddressLine2: state.cAddressLine2,
    cataId: state.cataId,
    cataIdDesc: state.cataIdDesc,
    cCity: state.cCity,
    cCountry: state.cCountry,
    companyId: state.companyId,
    contractDurationInMonths: state.contractDurationInMonths,
    contractorId: state.contractorId,
    country: state.country,
    cPincode: state.cPincode,
    createdBy: state.createdBy,
    cState: state.cState,
    dateOfBirth: state.dateOfBirth
      ? moment(state.dateOfBirth, "YYYY-MM-DD", true).format("DD-MM-YYYY")
      : null,
    dateOfInActive: state.dateOfInActive,
    dateOfJoining: state.dateOfJoining
      ? moment(state.dateOfJoining, "YYYY-MM-DD", true).format("DD-MM-YYYY")
      : null,
    dateOfPfJoining: state.dateOfPfJoining,
    deptId: state.deptId,
    deptIdDesc: state.deptIdDesc,
    designationId: state.designationId,
    designationIdDesc: state.designationIdDesc,
    drivingExpiryDate: state.drivingExpiryDate,
    drivingIssueDate: state.drivingIssueDate,
    drivingLicenseNo: state.drivingLicenseNo,
    emergencyMobileNo: state.emergencyMobileNo,
    empAddr: state.empAddr,
    empAddress2: state.empAddress2,
    empCity: state.empCity,
    empLevelId: state.empLevelId,
    empLevelIdDesc: state.empLevelIdDesc,
    empPincode: state.empPincode,
    empStatus: state.empStatus,
    empStatusId: state.empStatusId,
    esiNo: state.esiNo,
    facebookId: state.facebookId,
    faceId: state.faceId,
    faceImageTypeId: state.faceImageTypeId,
    fatherOrHusbandName: state.fatherOrHusbandName,
    fnfDate: state.fnfDate,
    grossSalary: state.grossSalary,
    hrmsFullAccess: state.hrmsFullAccess,
    landLineNo: state.landLineNo,
    lastName: state.lastName,
    ledgerGroupId: state.ledgerGroupId,
    licNo: state.licNo,
    mailAlert: state.mailAlert,
    maritalStatus: state.maritalStatus,
    middleName: state.middleName,
    mobileNo: state.mobileNo,
    nationality: state.nationality,
    netSettlementAmount: state.netSettlementAmount,
    nomineeName: state.nomineeName,
    nomineeRelationship: state.nomineeRelationship,
    noticeDays: state.noticeDays,
    officeBankAcNo: state.officeBankAcNo,
    officeBankBranch: state.officeBankBranch,
    officeBankIFSC: state.officeBankIFSC,
    officeBankName: state.officeBankName,
    officeMobileNo: state.officeMobileNo,
    officialEmailId: state.officialEmailId,
    panNo: state.panNo,
    parentLedger: state.parentLedger,
    passportExpiryDate: state.passportExpiryDate
      ? moment(state.passportExpiryDate, "YYYY-MM-DD", true).format(
          "DD-MM-YYYY"
        )
      : null,
    passportIssueDate: state.passportIssueDate
      ? moment(state.passportIssueDate, "YYYY-MM-DD", true).format("DD-MM-YYYY")
      : null,
    passportNo: state.passportNo,
    personalBankAcNo: state.personalBankAcNo,
    personalBankBranch: state.personalBankBranch,
    personalBankIFSC: state.personalBankIFSC,
    personalBankName: state.personalBankName,
    personalEmailId: state.personalEmailId,
    pfNo: state.pfNo,
    pfUANName: state.pfUANName,
    phoneNo: state.phoneNo,
    portalRoleId: state.portalRoleId,
    previousPfNo: state.previousPfNo,
    profilePicPath: state.profilePicPath,
    prohibitionDays: state.prohibitionDays,
    prohibitionInMonths: state.prohibitionInMonths,
    quarterNo: state.quarterNo,
    releaseDate: state.releaseDate
      ? moment(state.releaseDate, "YYYY-MM-DD", true).format("DD-MM-YYYY")
      : null,
    religion: state.religion,
    reportingToEbId: state.reportingToEbId,
    resignReason: state.resignReason,
    resignRemarks: state.resignRemarks,
    retiredDate: state.retiredDate,
    sex: state.sex,
    state: state.state,
    tempEmailPwd: state.tempEmailPwd,
    typeOfResign: state.typeOfResign,
    updatedBy: state.updatedBy,
    updatedDateTime: state.updatedDateTime
      ? moment(state.updatedDateTime, "YYYY-MM-DD", true).format("DD-MM-YYYY")
      : null,
    userName: state.userName,
    visaExpiryDate: state.visaExpiryDate
      ? moment(state.visaExpiryDate, "YYYY-MM-DD", true).format("DD-MM-YYYY")
      : null,
    visaIssueDate: state.visaIssueDate
      ? moment(state.visaIssueDate, "YYYY-MM-DD", true).format("DD-MM-YYYY")
      : null,
    visaNo: state.visaNo,
    workerImage: state.workerImage,
    workerName: state.workerName,
    workLocation: state.workLocation,
    personalInfo: state.personalInfo,
    generateLetters: state.generateLetters,
    uploadDocuments: state.uploadDocuments,
    onboarding: state.onboarding,
    shiftPolicy: state.shiftPolicy,
    leavePolicy: state.leavePolicy,
    contactDetails: state.contactDetails,
    educationalDetails: state.educationalDetails,
    previousExperience: state.previousExperience,
    workerDetails: state.workerDetails,
    salaryStructure: state.salaryStructure,
    bankDetails: state.bankDetails,
    esi: state.esi,
    pf: state.pf,
  };
  return data;
};
