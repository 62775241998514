import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import { agentMasterList } from "../../../store/Master/AgentMaster/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      agentMasterListData: [], // to display list data
    };
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
    };
    this.props.agentMasterList(
      serverApi.GET_ALL_AGENT_MASTER_LIST,
      data,
      this.props.history
    ); // calling Agent master list API
  }
  componentWillReceiveProps(props) {
    if (props.AgentMasterList) {
      this.setState({
        agentMasterListData: props.AgentMasterList.data, // updating agent master list data
      });
    }
  }

  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.agentMasterListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          url="/create_agent_master"
        />
      </>
    );
  }
}
// Agent master list Headers
const Columns = [
  {
    Header: "Agent Id",
    accessor: "agentId",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Agent Name",
    accessor: "companyName",
    hidden: false,
    csvExport: true,
  },
];
const mapStatetoProps = (state) => {
  const { AgentMasterList } = state.AgentMasterReducer; // fetching list from agent master reducer
  return { AgentMasterList };
};
export default withRouter(
  connect(mapStatetoProps, {
    agentMasterList,
  })(Index)
);
