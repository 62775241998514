import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Grid, Box } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/JuteProduction/DynamicSelect";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { getSpell } from "../../../store/HRMS/AttendanceCalender/actions";
import { getYarnTypeList } from "../../../store/Global/DropDownApis/actions";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import CustomButton from "../../../components/Buttons/Buttons";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { getTrollyDdList } from "../../../store/JuteProduction/WindingDoffEntry/actions";
import InputAdormentSearch from "../../../components/InputAdorment/InputAdormentSearch";
import searchIcon from "../../../assets/images/search.png";
import { getWorkerList } from "../../../store/HRMS/ExpenseBooking/actions";
import swal from "sweetalert";
import InputAdornmentText from "../../../components/InputAdorment/InputAdorment";
import { beamingDdList } from "../../../store/JuteProduction/BeamingProduction/actions";

class CreateSpinningDoffEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProcessMasterContent: [],
      userDit: JSON.parse(localStorage.getItem("authUser")),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      spellContent: [],
      yarnTypeListData: [],
      trollyDdList: [],
      tranDate: moment().format("YYYY-MM-DD"),
      time: moment().format().split("T"),
      spell: "",
      confirm: false,
      ebno: "",
      employee_name: "",
      employee_dept: "",
      employee_occuption: "",
      employeeDetailsBlock: "none",
      tareWeight: "",
      grossWeight: "",
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.beamingDdList(
      serverApi.GET_BEAMING_DD_LIST +
        localStorage.getItem("companyId") +
        "/SPINNING",
      this.props.history
    );
    this.props.getYarnTypeList(
      serverApi.GET_YARN_TYPE_LIST + localStorage.getItem("companyId"),
      this.props.history
    );
    this.props.getSpell(
      serverApi.SPELL +
        localStorage.getItem("companyId") +
        "/" +
        this.state.cipher,
      this.props.history
    );
    this.props.getTrollyDdList(
      serverApi.GET_TROLLY_DD_LIST + "2/" + "/" + this.state.cipher,
      this.props.history
    );
  }

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    this.setState({ [name]: selectedValue });
    if (name === "trollyNo") {
      var tareWeightCal = selectedName.split("^")[1];
      this.setState({
        tareWeight: tareWeightCal,
      });
    }
  };
  onhandleChange = (key) => (name, value) => {
    if (key === "grossWeight") {
      this.setState({
        grossWeight: value,
      });
    }
  };
  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "ebno") {
      this.setState({
        ebno: event,
      });
    }

    // if(key === "grossWeight"){
    //   this.setState({
    //     grossWeight: event,
    //   });
    //  //var netWeightCal = parseInt(this.state.grossWeight) - parseInt(this.state.tareWeight);
    // }
  };
  componentWillReceiveProps(props) {
    if (props.spell) {
      let list = props.spell.filter((props) => {
        if (props.value !== "0") {
          return props;
        }
      });
      this.setState({
        spellContent: list,
      });
    }
    if (props.yarnTypeList) {
      this.setState({
        yarnTypeListData: props.yarnTypeList.data,
      });
    }
    if (props.trollyDdListData) {
      if (props.trollyDdListData.data) {
        let list = props.trollyDdListData.data.filter((props) => {
          if (props.value !== 0) {
            return props;
          }
        });

        this.setState({
          trollyDdList: list,
        });
      }
    }
    // if (props.trollyDdListData) {
    //   this.setState({
    //     trollyDdList: props.trollyDdListData.data,
    //   });
    // }
    if (props.worker_List.data) {
      this.setState({
        employee_name: props.worker_List.data.workerName,
        employee_dept: props.worker_List.data.deptIdDesc,
        employee_occuption: props.worker_List.data.designationIdDesc,
      });
    }
    if (props.beamingProductionDdLists) {
      if (props.beamingProductionDdLists.data) {
        let list = props.beamingProductionDdLists.data.filter((props) => {
          if (props.value !== 0) {
            return props;
          }
        });
        this.setState({
          BeamingDropdownData: list,
        });
      }
    }
  }
  onClick = () => {
    this.props.getWorkerList(
      serverApi.WORKER_LIST +
        this.state.ebno +
        "/" +
        localStorage.getItem("companyId") +
        "/" +
        this.state.userDit.userId,
      this.props.history
    );
    if (this.state.employeeDetailsBlock === "none") {
      this.setState({
        employeeDetailsBlock: "block",
      });
    }
  };
  handleInputChange = (e, name) => {
    if (name === "grossWeight") {
    }
  };
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.props.getWorkerList(
        serverApi.WORKER_LIST +
          this.state.ebno +
          "/" +
          localStorage.getItem("companyId") +
          "/" +
          this.state.userDit.userId,
        this.props.history
      );
    }
    if (this.state.employeeDetailsBlock === "none") {
      this.setState({ employeeDetailsBlock: "block" });
    }
  };
  onClickCreate = () => {
    if (
      this.state.tranDate !== "" &&
      this.state.time !== "" &&
      this.state.noOfBeams !== "" &&
      this.state.spell !== "" &&
      this.state.ends !== "" &&
      this.state.beamMcNo !== "" &&
      this.state.noOfCuts !== "" &&
      this.state.qualityCode !== ""
    ) {
      let time = this.state.time[1].split("+");

      var beamingCreatePayload = {
        tranDate: moment(this.state.tranDate).format("DD-MM-YYYY"),
        time: time[0],
        noOfBeams: this.state.noOfBeams,
        noOfCuts: this.state.noOfCuts,
        beamMcNo: this.state.beamMcNo,
        ends: this.state.ends,
        qualityCode: this.state.qualityCode.toString(),
        spell: this.state.spell,
        companyId: localStorage.getItem("companyId"),
        createdBy: this.state.userDit.userId.toString(),
      };
      this.props.beamingCreate(
        serverApi.ADD_BEAMING_PRODUCTION,
        beamingCreatePayload,
        this.props.history
      );
      // }
    } else {
      this.setState({
        confirm: true,
      });
    }
  };
  onClickCancel = () => {
    // this.state.employee_name = ""
    // this.setState({
    //   employee_name: ""
    // })
  };
  // Function For Displaying Common Popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  handleSelectDate = (e, name) => {
    if (name === "tranDate") {
      let date = moment(e).format();
      let formattedFromDate = date.split("T", 1).toString();
      this.setState({
        tranDate: formattedFromDate,
      });
    }
    if (name === "time") {
      let actualtime = moment(e).format();
      let formattedTime = actualtime.split("T", 2);

      this.setState({
        time: formattedTime,
      });
    }
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div className="finishingEntriesContainer">
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormContainer">
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Date<div className="mandatoryField">*</div>
                          </label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="tranDate"
                            inputFormat="dd-MM-yyyy"
                            value={this.state.tranDate}
                            fullWidth
                            onChange={(e) =>
                              this.handleSelectDate(e, "tranDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  readOnly={true}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="TextFieldBlock">
                        <label>
                          Time<label className="starcolor">*</label>
                        </label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          label="Time"
                          name="time"
                          value={this.state.time}
                          onChange={(e) => this.handleSelectDate(e, "time")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.trollyDdList}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.trollyNo}
                          name="trollyNo"
                          label="Trolly Number"
                          required
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={
                            this.state.spellContent
                              ? this.state.spellContent.map((item) => ({
                                  label: item.label,
                                  value: item.value,
                                  name: item.name,
                                }))
                              : ""
                          }
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.spell}
                          name="spell"
                          label="Spell"
                          required
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.BeamingDropdownData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.beamMcNo}
                          name="beamMcNo"
                          label="Frame Number"
                          required
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3" className="inputAdornmentText">
                      <div className="consoleTextFieldBlock ">
                        <div className="TextFieldBlock">
                          <label>
                            Tare Weight<label className="starcolor">*</label>
                          </label>
                        </div>
                        <InputAdornmentText
                          label="KG"
                          type="number"
                          disabled
                          value={this.state.tareWeight}
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { spell } = state.AttendanceReducer;
  const { yarnTypeList } = state.DropDownListReducer;
  const { trollyDdListData } = state.WindingDoffEntryReducer;
  const { worker_List } = state.ExpenseBookingReducer;
  const { beamingProductionDdLists } = state.BeamingProductionListReducer;

  return {
    spell,
    yarnTypeList,
    trollyDdListData,
    worker_List,
    beamingProductionDdLists,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSpell,
    getYarnTypeList,
    getTrollyDdList,
    getWorkerList,
    beamingDdList,
  })(CreateSpinningDoffEntry)
);
