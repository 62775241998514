import {PRICE_ENQUIRY_LIST, PRICE_ENQUIRY_LIST_SUCCESSFULL,ADD_PRICE_ENQUIRY, ADD_PRICE_ENQUIRY_SUCCESSFUL,PRICE_ENQUIRY_LINEITEMS, GET_PRICE_ENQUIRY_BYID, GET_PRICE_ENQUIRY_BYID_SUCCESSFUL} from './actionTypes';



export const getPriceEnquiryList = (url,data,history) =>{
    return {
        type:PRICE_ENQUIRY_LIST,
        payload:{url,data,history}
    }
}

export const PriceEnquiryListSuccessfull = (data)=>{
    return {
        type:PRICE_ENQUIRY_LIST_SUCCESSFULL,
        payload:data
    }
}

export const addPriceEnquiry = (url,data,history) =>{
    return {
        type:ADD_PRICE_ENQUIRY,
        payload:{url,data,history}
    }
}

export const addPriceEnquirySuccessfull = (data)=>{
    return {
        type:ADD_PRICE_ENQUIRY_SUCCESSFUL,
        payload:data
    }
}
export const PriceEnquiryLineitemsProps = (data)=>{
    return {
        type:PRICE_ENQUIRY_LINEITEMS,
        payload:data
    }
}

export const getPriceEnquiryById = (url, history) => {
    return {
      type: GET_PRICE_ENQUIRY_BYID,
      payload: { url, history },
    };
  };

  export const getPriceEnquiryByIdSuccessfull = (data) => {
    return {
      type: GET_PRICE_ENQUIRY_BYID_SUCCESSFUL,
      payload: data,
    };
  };


