import { CREATE_RE_OCCURING_INVOICE, CREATE_RE_OCCURING_INVOICE_SUCCESSFULL, RE_OCCURING_INVOICE_LIST, RE_OCCURING_INVOICE_LIST_SUCCESSFULL } from './actionType'

const initialState = {
    ReOccuringInvoiceList:[],
    ReOccuringInvoices:[]
}

const ReOccuringInvoiceReducer = (state = initialState, action) => {
    switch (action.type) {

        case RE_OCCURING_INVOICE_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
      
          case RE_OCCURING_INVOICE_LIST_SUCCESSFULL:
            state = {
              ...state,
              ReOccuringInvoiceList: action.payload.response,
              loading: false,
            };
            break;
            case CREATE_RE_OCCURING_INVOICE:
              state = {
                ...state,
                loading: true,
              };
              break;
        
            case CREATE_RE_OCCURING_INVOICE_SUCCESSFULL:
              state = {
                ...state,
                ReOccuringInvoices: action.payload.response,
                loading: false,
              };
              break;
        default:
            state = { ...state };
            break;
    }
    return state;
}
export default ReOccuringInvoiceReducer;