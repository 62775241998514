import React, { Component } from "react";
import { Grid, Box, Fade, IconButton, Tooltip } from "@mui/material";
import DynamicSelect from "./DynamicSelect";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import { getCostList } from "../../../store/Accounting/Bills/actions";
import { getDepartmentList } from "../../../store/Global/DropDownApis/actions";
import {
  getItemGroupById,
  getSrStockList,
  getSrList,
  getMachineNoList,
  getAllItemList,
  createStoreIssueLinewise,
  storeIssueLineItemWise,
  getBranchStockList,
  getItemGroupListforConsumption,
  getItemsListforConsumption,
  getSrNoForConsumption,
  getCostFactorConsumption,
} from "../../../store/StoreModule/MaterialRequest/actions";

import DeleteIcon from "@mui/icons-material/Delete";
import ReactTooltip from "react-tooltip";
import swal from "sweetalert";
import { isEmpty } from "validator";

class MaterialRequestLineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCount: 1,
      selectedRowId: [],
      items_List: [],
      indentLineItems: [],
      BillListData: [],
      description: this.props.header.description,
      fundsOut: this.props.header.fundsOut,
      delete: false,
      products: this.props.storeIssueLineItem,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      departmentList: [],
      itemGroupList: [],
      itemsList: [],
      itemGroupId: 0,
      srList: [],
      qunatity: 0,
      costFactorList: [],
      machineList: [],
      issueValue: 0,
      centralStock: 0,
      hasItem: false,
      lineitemId: 1,
      inputColor: "",
      companyId: localStorage.getItem("companyId"),
      storeRecieptViewById: [],
    };
  }

  handleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;

    this.setState({ [key]: event });
    let mname = key.split("_");

    if (mname[0] === "quantity") {
      let Val = value.match(/^(\d*\.{0,1}\d{0,3}$)/);
      if (Val) {
        if (this.props.location.state !== undefined) {
          let object = this.props.storeIssueLineItem.map((rowItem) => {
            if (row.lineitemId === rowItem.lineitemId) {
              var originalQty =
                row.hdrId === ""
                  ? row.originalQty
                  : row.srStock + row.originalQty;
              if (
                Number(parseFloat(value).toFixed(3)) >
                Number(parseFloat(originalQty).toFixed(3))
              ) {
                swal(
                  `Quantity cannot be greater than SR Qunatity!! ${parseFloat(
                    originalQty
                  ).toFixed(2)}`
                );

                value = Number(parseFloat(originalQty).toFixed(3));
                row.quantity = Number(parseFloat(originalQty).toFixed(3));
                row.issueValue =
                  Number(parseFloat(originalQty).toFixed(2)) *
                  Number(parseFloat(row.rate).toFixed(2));

                  // Reverting Discount Type in Consumption

                  // if(row.discountType == 1) {  
                  //   row.issueValue = Number(row.quantity) > 0 ? Number(row.rate) * Number(row.quantity) - row.discount : "0.00"
                  // } if(row.discountType == 2) {  
                  //   let discountAmount = (row.rate * Number(row.quantity) * row.discount) / 100
                  //   row.issueValue =  Number(row.quantity) > 0 ? row.rate * Number(row.quantity) - discountAmount : "0.00"
                  // }

              } else {
                row.quantity = value;
                row.issueValue =
                  Number(parseFloat(value).toFixed(2)) *
                  Number(parseFloat(row.rate).toFixed(2));

                  // Reverting Discount Type in Consumption

                  // if(row.discountType == 1) {  
                  //   row.issueValue = Number(row.quantity) > 0 ? Number(row.rate) * Number(row.quantity) - row.discount : "0.00"
                  // } if(row.discountType == 2) {  
                  //   let discountAmount = (row.rate * Number(row.quantity) * row.discount) / 100
                  //   row.issueValue =  Number(row.quantity) > 0 ? row.rate * Number(row.quantity) - discountAmount : "0.00"
                  // }
              }
            } else {
              if (this.state.inputColor === "removeData") {
                row.quantity = "";
                this.setState({
                  inputColor: "",
                });
              } else {
                row.issueValue = Number(row.rate) * Number(row.quantity)
                
                // Reverting Discount Type in Consumption

                // if(row.discountType == 1) {
                //   row.issueValue = Number(row.quantity) > 0 ? Number(row.rate) * Number(row.quantity) - row.discount : "0.00"
                // } if(row.discountType == 2) {
                //   let discountAmount = (row.rate * Number(row.quantity) * row.discount) / 100
                //   row.issueValue =  Number(row.quantity) > 0 ? row.rate * Number(row.quantity) - discountAmount : "0.00"
                // }
              }
            }
            return rowItem;
          });

          this.setState({
            products: object,
          });

          this.props.storeIssueLineItemWise(object);
        } else {
          let object = this.props.storeIssueLineItem.map((rowItem) => {
            if (row.lineitemId === rowItem.lineitemId) {
              if (
                Number(parseFloat(value).toFixed(3)) >
                Number(parseFloat(row.originalQty).toFixed(3))
              ) {
                swal("Quantity cannot be greater than SR Qunatity!!");
                value = Number(parseFloat(row.originalQty).toFixed(3));
                row.quantity = Number(parseFloat(row.originalQty).toFixed(3));
                row.issueValue =
                  Number(parseFloat(row.originalQty).toFixed(2)) *
                  Number(parseFloat(row.newIssueValue).toFixed(2));
              } else {
                row.quantity = value;
                row.issueValue = Number(row.quantity) > 0 ? Number(row.newIssueValue) * Number(value) : "0.00"

                // Reverting Discount Type in Consumption
          
                // if(row.discountType == 1) {
                //   row.issueValue = Number(row.quantity) > 0 ? Number(row.newIssueValue) * Number(value) - row.discount : "0.00"
                // } if(row.discountType == 2) {
                //   let discountAmount = (row.rate * Number(row.quantity) * row.discount) / 100
                //   row.issueValue =  Number(row.quantity) > 0 ? row.rate * Number(row.quantity) - discountAmount : "0.00"
                // }
              }
            } else {
              if (this.state.inputColor === "removeData") {
                row.quantity = "";
                this.setState({
                  inputColor: "",
                });
              } else {
                row.issueValue = Number(row.newIssueValue) * Number(row.quantity)

                // Reverting Discount Type in Consumption

                // if(row.discountType == 1) {
                //   row.issueValue = Number(row.quantity) > 0 ? Number(row.newIssueValue) * Number(row.quantity) - row.discount : "0.00"
                // }
                // if(row.discountType == 2) {
                //   let discountAmount = (row.rate * Number(row.quantity) * row.discount) / 100
                //   row.issueValue =  Number(row.quantity) > 0 ? row.rate * Number(row.quantity) - discountAmount : "0.00"
                // }
              }
            }
            return rowItem;
          });

          this.setState({
            products: object,
          });

          this.props.storeIssueLineItemWise(object);
        }
      } else {
        swal("Quantity shouldn't be more than 3 decimal");
      }
    }
  };

  getitemListByitemgrpId = (response, itemGrpId) => {
    if (itemGrpId !== undefined) {
      if (response) {
        let ConsumptionLineItemsObj = this.state.products.filter((row) => {
          if (row.itemGroupId === itemGrpId || row.itemGroupId === itemGrpId) {
            let list = [];
            response.data.map((prop) => {
              list.push({
                label: prop.label,
                name: prop.name,
                value: prop.value,
                variant: prop.itemCode,
              });
            });
            let itemsList = list.filter((item) => {
              if (item.value == row.itemId) {
                let value = item.name.split("^");
                row.min = value[8];
                row.max = value[6];
              }
              if (item.value !== 0 && item.value !== "0") {
                return item;
              }
            });
            row.itemsList = itemsList;
          } else if (row.deptId === itemGrpId) {
            let itemGRPList = [];
            response.data.map((prop) => {
              itemGRPList.push({
                label: prop.label,
                name: prop.name,
                value: prop.value,
              });
            });
            let itemsgroupList = itemGRPList.filter((item) => {
              if (item.value !== 0 && item.value !== "0") {
                return item;
              }
            });
            row.itemGroupList = itemsgroupList;
          } else if (Number(row.itemId) === Number(itemGrpId)) {
            let SrList = [];
            response.data.map((prop) => {
              SrList.push({
                label: prop.label,
                name: prop.name,
                value: prop.value,
              });
            });
            let SRList = SrList.filter((item) => {
              if (item.value !== 0 && item.value !== "0") {
                return item;
              }
            });
            row.srList = SRList;
          } else if (
            itemGrpId.Type === "costFactor" &&
            row.deptId === itemGrpId.selectedValue
          ) {
            let CostFactorList = [];
            response.data.map((prop) => {
              CostFactorList.push({
                label: prop.label,
                name: prop.name,
                value: prop.value,
              });
            });
            let CFactorList = CostFactorList.filter((item) => {
              if (item.value !== 0 && item.value !== "0") {
                return item;
              }
            });
            row.costFactorList = CFactorList;
          }
          return row;
        });
        this.setState({
          products: ConsumptionLineItemsObj,
        });
        this.props.storeIssueLineItemWise(ConsumptionLineItemsObj);
      }
      // return this.state.itemsList
    }
  };

  handleChangedropdown = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    selectedItemCode
  ) => {
    let lastVal = selectedRow.id;
    var itemGroupIdData = "";
    this.setState({
      selectedRowId: selectedRow,
    });

    var newline = 1;

    var Object = this.state.products.filter((row) => {
      if (row.lineitemId === selectedRow.lineitemId) {
        if (name === "department") {
          row.deptId = selectedValue;
          row.departmentName = selectedName;
          row.deleteStatus =
            this.props.location.state !== undefined ? null : false;
        }
      }

      newline = Number(row.lineitemId);
      return row;
    });

    if (name === "department") {
      this.props.getItemGroupListforConsumption(
        serverApi.ITEM_GROUP_LIST +
          selectedValue +
          "/" +
          localStorage.getItem("companyId") +
          "/getAllItemGroupsByDepartmentId/" +
          this.state.userDit.cipher,
        this.props.history,
        this.getitemListByitemgrpId,
        selectedValue
      );

      let costFacObj = { selectedValue: selectedValue, Type: "costFactor" };
      this.props.getCostFactorConsumption(
        serverApi.COST_FACTOR_LIST +
          localStorage.getItem("companyId") +
          "/" +
          selectedValue,
        this.props.history,
        this.getitemListByitemgrpId,
        costFacObj
      );

      selectedRow.costfactor = "";

      let lastRow = this.state.products[this.state.products.length - 1];

      if (selectedRow === lastRow) {
        let obj = {
          id: "0",
          no: 0,
          lineitemId: Number(newline) + 1,
          hdrId: "",
          itemGroupList: [],
          itemsList: [],
          srList: [],
          departmentList: [],
          deptId: 0,
          itemGroupId: 0,
          itemId: 0,
          srNo: "",
          quantity: 0,
          costfactor: 0,
          machineNo: 0,
          centralStock: 0,
          branchStock: 0,
          srStock: 0,
          discount:0,
          issueValue: 0,
          itemName: "",
          departmentName: "",
          itemGroupName: "",
          uom: "",
          costFactorName: "",
          srPrintNo: "",
          inwardId: 0,
          newIssueValue: 0,
          deleteStatus: this.props.location.state !== undefined ? null : false,
        };
        Object.push(obj);
        this.setState({
          products: Object,
          lineItemId: selectedRow.lineItemId,
        });
        this.props.storeIssueLineItemWise(Object);
      }
    }

    if (name === "itemGroup") {
      let filtereditems = this.state.products.filter(
        (item) => item.isActive !== 0
      );
      if (selectedRow.itemGroupId === selectedValue) {
        var checkitem = !!filtereditems.find(
          ({ itemGroupId }) => itemGroupId === selectedValue
        );
      }

      if (checkitem) {
        const data = {
          companyId: localStorage.getItem("companyId"),
          itemGroupId: selectedValue,
          saleable: "",
        };

        this.props.getItemsListforConsumption(
          serverApi.GET_ITEMS_BY_CONSUMPTION,
          data,
          this.getitemListByitemgrpId,
          selectedValue
        );

        selectedRow.itemGroupId = selectedValue;
        selectedRow.itemGroupName = selectedName;
        itemGroupIdData = selectedValue;
        this.setState({
          itemGroupId: selectedValue,
        });
      } else {
        const data = {
          companyId: localStorage.getItem("companyId"),
          itemGroupId: selectedValue,
          saleable: "",
        };

        this.props.getItemsListforConsumption(
          serverApi.GET_ITEMS_BY_CONSUMPTION,
          data,
          this.getitemListByitemgrpId,
          selectedValue
        );

        selectedRow.itemGroupId = selectedValue;
        selectedRow.itemGroupName = selectedName;

        /******** OnSelct of Item Group make other field empty ********/
        selectedRow.itemId = "";
        selectedRow.srNo = "";
        selectedRow.quantity = "";
        selectedRow.issueValue = 0;
        // selectedRow.srPrintNo = srPrintNo[5];
        selectedRow.srPrintNo = "";
        selectedRow.inwardId = "";
        selectedRow.costfactor = "";
        selectedRow.costFactorName = "";
        selectedRow.machineNo = "";
        selectedRow.srStock = 0;
        selectedRow.centralStock = 0;
        selectedRow.branchStock = 0;
        selectedRow.uom = "";

        /******* OnSelct of Item Group make other field empty *****/

        itemGroupIdData = selectedValue;
        this.setState({
          itemGroupId: selectedValue,
        });
      }
    }

    if (name === "item") {
      let uomValue = selectedName;
      let uom = uomValue.split("^");
      let filtereditems = this.state.products.filter(
        (item) => item.isActive !== 0
      );

      let checkitem = !!filtereditems.find(
        ({ itemId }) => itemId === selectedValue
      );

      if (checkitem) {
        const data = {
          branchId: this.props.header.branchId,
          companyId: localStorage.getItem("companyId"),
          itemGrpId: this.state.itemGroupId,
          itemId: selectedValue,
        };
        this.props.getSrStockList(
          serverApi.SR_STOCK_LIST,
          data,
          this.props.history
        );

        this.props.getBranchStockList(
          serverApi.BRANCH_WISE_STOCK_LIST,
          data,
          this.props.history
        );

        const data1 = {
          branchId: this.props.header.branchId,
          companyId: localStorage.getItem("companyId"),
          itemGrpId: this.state.itemGroupId,
          itemId: selectedValue,
          issueDate: this.props.header.date
        };

        this.props.getSrNoForConsumption(
          serverApi.SR_LIST,
          data1,
          this.getitemListByitemgrpId,
          selectedValue
        );

        let itemName = selectedName;
        let itemName2 = itemName.split("^");
        selectedRow.itemId = selectedValue;

        selectedRow.itemCode = selectedItemCode;
        selectedRow.itemName = itemName2[0];
        selectedRow.uom = uom[1];
      } else {
        const data = {
          branchId: this.props.header.branchId,
          companyId: localStorage.getItem("companyId"),
          itemGrpId: this.state.itemGroupId,
          itemId: selectedValue,
        };
        this.props.getSrStockList(
          serverApi.SR_STOCK_LIST,
          data,
          this.props.history
        );

        this.props.getBranchStockList(
          serverApi.BRANCH_WISE_STOCK_LIST,
          data,
          this.props.history
        );

        const data1 = {
          branchId: this.props.header.branchId,
          companyId: localStorage.getItem("companyId"),
          itemGrpId: this.state.itemGroupId,
          itemId: selectedValue,
          issueDate: this.props.header.date
        };

        this.props.getSrNoForConsumption(
          serverApi.SR_LIST,
          data1,
          this.getitemListByitemgrpId,
          selectedValue
        );

        let itemName = selectedName;
        let itemName2 = itemName.split("^");
        selectedRow.itemId = selectedValue;
        selectedRow.itemCode = selectedItemCode;
        selectedRow.itemName = itemName2[0];
        selectedRow.uom = uom[1];
        selectedRow.srNo = "";
        selectedRow.quantity = "";
        selectedRow.issueValue = 0;
        selectedRow.srPrintNo = "";
        selectedRow.inwardId = "";
        selectedRow.costfactor = "";
        selectedRow.costFactorName = "";
        selectedRow.machineNo = "";
        selectedRow.srStock = 0;
        selectedRow.centralStock = 0;
        selectedRow.branchStock = 0;
        selectedRow.discount=0;
      }
    }

    // sr handleChange
    if (name === "srNo") {
      let quantityCal = selectedName;
      let quantity = quantityCal.split("_");
      let issueValue = quantityCal.split("_");
      let srPrintNo = quantityCal.split("_");

      let filterData = this.state.products.filter((row) => {
        if (row.deleteStatus !== true && row.isActive !== 0) {
          return row;
        }
      });

      var check = !!filterData.find(({ srNo }) => srNo === selectedValue);

      var dataTrnasfer = this.props.storeIssueLineItem.map((row) => {
        if (row.lineitemId === selectedRow.lineitemId) {

          const isQuantityAvailableFromRow = quantity[2] ? quantity[2] : row.quantity;

          row.srNo = selectedValue;
          row.quantity = quantity[2] ? quantity[2] : row.quantity;
          row.originalQty = quantity[2] ? quantity[2] : row.quantity;
          row.newIssueValue = Number(issueValue[1]);
          row.rate = Number(issueValue[1]);
          row.discountType = Number(issueValue[7]);
          row.discount = Number(issueValue[8]);
          row.srPrintNo = srPrintNo[6];
          row.inwardId = srPrintNo[3];
          row.srStock = srPrintNo[2];
          row.issueValue = Number(issueValue[1]) * Number(isQuantityAvailableFromRow);

          // Reverting Discount Changes in Consumption

          // if (issueValue[7] == 1) {
          //   row.issueValue =
          //     Number(issueValue[1]) * Number(row.quantity) -
          //     Number(row.discount);
          // }
          // if (issueValue[7] == 2) {
          //   let discountAmount = (issueValue[1] * Number(row.quantity) * row.discount) / 100
          //   row.issueValue = issueValue[1] * Number(row.quantity) - discountAmount;
          // }

        }
        return row;
      });

      if (check) {
        swal("You Cannot Create lineItem with same SR at a time");
        selectedRow.srNo = "";
        selectedRow.quantity = 0;
        selectedRow.srPrintNo = "";
        selectedRow.inwardId = "";
        selectedRow.srStock = 0;
        selectedRow.centralStock = 0;
        selectedRow.branchStock = 0;
        selectedRow.issueValue = 0;
      } else {
        this.setState({
          issueValue: issueValue[1],
          inputColor: "removeData",
        });
      }
    }

    if (name === "costFactor") {
      const lineItems = this.props.storeIssueLineItem.map((row) => {
        if (row.lineitemId === selectedRow.lineitemId) {
          selectedRow.costfactor = selectedValue;
          selectedRow.costFactorName = selectedName;
        }
        return row;
      });

      this.setState({
        costfactor: selectedValue,
        products: lineItems,
      });
      this.props.storeIssueLineItemWise(lineItems);
    }

    if (name === "machineNo") {
      const lineItems = this.props.storeIssueLineItem.map((row) => {
        if (row.lineitemId === selectedRow.lineitemId) {
          selectedRow.machineNo = selectedValue;
          selectedRow.machineName = selectedName;
        }
        return row;
      });

      this.setState({
        machineNo: selectedValue,
        products: lineItems,
      });
      this.props.storeIssueLineItemWise(lineItems);
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    this.props.getDepartmentList(
      serverApi.GET_DEPARTMENT_LIST +
        localStorage.getItem("companyId") +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    );

    this.props.getMachineNoList(
      serverApi.MACHINE_NO_LIST +
        "/" +
        localStorage.getItem("companyId") +
        "/" +
        this.state.userDit.cipher
    );

    if (this.props.storeIssueViewByIdList) {
      this.props.storeIssueLineItem.map((row) => {
        let costFacObj = { selectedValue: row.deptId, Type: "costFactor" };
        this.props.getCostFactorConsumption(
          serverApi.COST_FACTOR_LIST +
            localStorage.getItem("companyId") +
            "/" +
            row.deptId,
          this.props.history,
          this.getitemListByitemgrpId,
          costFacObj
        );
      });
    }

    if (
      this.props.storeIssueLineItem &&
      this.props.storeIssueLineItem !== undefined
    ) {
    } else {
      var store_issue_lineITem = [
        {
          id: "0",
          no: 0,
          lineitemId: 1,
          hdrId: "",
          itemGroupList: [],
          itemsList: [],
          srList: [],
          departmentList: [],
          deptId: 0,
          itemGroupId: 0,
          itemId: 0,
          srNo: "",
          quantity: 0,
          costfactor: 0,
          machineNo: 0,
          centralStock: 0,
          branchStock: 0,
          srStock: 0,
          issueValue: 0,
          itemName: "",
          departmentName: "",
          itemGroupName: "",
          uom: "",
          costFactorName: "",
          srPrintNo: "",
          inwardId: 0,
          newIssueValue: 0,
          deleteStatus: null,
        },
      ];

      this.props.storeIssueLineItemWise(store_issue_lineITem);
    }
  }

  onDelEvent = (event) => {
    this.setState({ delete: true, deletedId: event.target.name });
  };

  onDel = (id) => {
    this.setState({ delete: false });
    const updatedObj = this.props.storeIssueLineItem.map((item) =>
      item.lineitemId === Number(this.state.deletedId)
        ? { ...item, deleteStatus: true }
        : item
    );

    this.setState({
      products: updatedObj,
    });

    this.props.storeIssueLineItemWise(updatedObj);
  };

  componentWillReceiveProps(props) {
    this.setState({
      storeRecieptViewById: this.props.storeIssueViewByIdList,
    });
    this.setState({
      locationState: this.props.location,
    });

    if (this.props.storeIssueLineItem) {
      this.setState({
        setHdrId: props.storeIssueLineItem[0].hdrId,
      });
    }

    if (props.itemGroupList) {
      if (props.itemGroupList.data) {
        var list = [];

        list = props.itemGroupList.data.filter((item) => {
          if (item.value !== "0") {
            return item;
          }
        });
        this.state.selectedRowId.itemGroupList = list;

        this.setState({
          itemGroupList: list,
        });
      }
    }

    if (props.departmentList) {
      if (props.departmentList.data) {
        if (props.departmentList.data.length > 0) {
          var list = [];
          list = props.departmentList.data.filter((item) => {
            if (item.value !== 0) {
              return item;
            }
          });
          this.setState({
            departmentList: list,
          });
        }
      }
    }

    if (props.costFactorList) {
      if (props.costFactorList.data) {
        if (props.costFactorList.data.length !== 0) {
          let list = [];
          list = props.costFactorList.data.filter((item) => {
            if (item.value !== "0") {
              return item;
            }
          });
          this.setState({
            costFactorList: list,
          });
        }
      }
    }

    if (props.machineList.data) {
      this.setState({
        machineList: props.machineList.data,
      });
    }

    if (props.location.state !== undefined) {
      //list setting....
      props.storeIssueLineItem.filter((row) => {
        var list = props.srList.filter((prop) => {
          if (row.srNo === prop.value) {
            var stock = prop.name;
            var newStock = stock.split("_");
            row.srStock = Number(parseFloat(newStock[2]).toFixed(2));
          }
          return list;
        });
        return row;
      });

      // for item group
      if (props.itemGroupList) {
        if (props.itemGroupList.data) {
          props.storeIssueLineItem.filter((row) => {
            var list = props.itemGroupList.data.filter((prop) => {
              if (row.itemGroupId === prop.value) {
                row.itemGroupList = props.itemGroupList.data;
              }
              return list;
            });
            return row;
          });
        }
      }

      if (props.itemList) {
        var newItemList = [];

        props.itemList.map((prop) => {
          newItemList.push({
            label: prop.label,
            name: prop.name,
            value: prop.value,
            variant: prop.itemCode,
          });
        });

        props.storeIssueLineItem.filter((row) => {
          var list = newItemList.filter((prop) => {
            if (row.itemId === Number(prop.value)) {
              row.itemsList = props.itemList;
              row.centralStock = props.srStockList.data;
              row.branchStock = props.branchStockList.data;
            }
            return list;
          });
          return row;
        });
      }

      if (props.srList) {
        props.storeIssueLineItem.filter((row) => {
          var list = props.srList.filter((prop) => {
            if (row.srNo === prop.value) {
              row.srList = props.srList;
            }
            return list;
          });
          return row;
        });
      }
    }
  }

  handleEventPositioned = (info, id) => {
    this.props.storeIssueLineItem.map((item) => {
      if (item.lineitemId == id) {
        if (info.target) {
          info.target.setAttribute(
            "data-tip",
            `<p>Central Stock = ${
              item.centralStock == "" || item.centralStock == null
                ? 0
                : Number(parseFloat(item.centralStock).toFixed(2))
            }</p>
          <p> Branch Stock = ${Number(
            parseFloat(item.branchStock).toFixed(2)
          )}</p>
          <p> SR Stock = ${
            item.srStock == "" || item.srStock == null
              ? 0
              : Number(parseFloat(item.srStock).toFixed(2))
          }
          </p>
         
          `
          );
          ReactTooltip.rebuild();
        }
      }
    });
  };

  onDelLineItem = (row) => {
    let lastRow = this.state.products[this.state.products.length - 1];
    let selectedRow = row.itemId;
    if (lastRow === row && selectedRow === "") {
      swal("You can't delete the data");
    } else {
      var indexitem = this.state.products.findIndex((item) => item == row);
      var lineItems = this.state.products;
      if (this.props.location.state !== undefined) {
        if (lineItems[indexitem].deleteStatus === null) {
          lineItems = lineItems.filter((item) => row !== item);
        } else {
          lineItems[indexitem].isActive = 0;
          lineItems[indexitem].deleteStatus = true;
        }
      } else {
        lineItems[indexitem].isActive = 0;
        lineItems[indexitem].deleteStatus = true;
      }

      this.setState({
        products: lineItems,
      });
      this.props.storeIssueLineItemWise(lineItems);
    }
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    let lastRow = this.state.products[this.state.products.length - 1];

    let filterData = this.state.products.filter((row) => {
      if (row.deleteStatus !== true && row.isActive !== 0) {
        return row;
      }
    });

    return (
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent" onScroll={this.handleScroll}>
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {this.props.tableHeaders.map((column) => (
                    <>
                      {column.header === "Department" ||
                      column.header === "Item Group" ||
                      column.header === "Item List" ||
                      column.header === "Store Recepit No" ||
                      column.header === "quantity" ||
                      column.header === "Cost Factor" ? (
                        <th>
                          {column.header}
                          <span style={{ color: "red" }}>*</span>
                        </th>
                      ) : (
                        <th>{column.header}</th>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filterData.map((row, rowIndex) => (
                  <tr>
                    <>
                      {this.props.RowData &&
                        this.props.RowData.map((col) =>
                          col.component === "Inputadorment" ? (
                            <td>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      type={
                                        col.type === "unit" ? "text" : "number"
                                      }
                                      name={col.type + "_" + rowIndex}
                                      id={col.no}
                                      onKeyDown={(evt) =>
                                        ["e", "E", "+", "-"].includes(
                                          evt.key
                                        ) && evt.preventDefault()
                                      }
                                      value={
                                        col.type === "quantity"
                                          ? row.quantity
                                          : col.type === "issueValue"
                                          ? parseFloat(
                                              Number(row.issueValue)
                                            ).toFixed(2)
                                          : col.type === "unit"
                                          ? row.uom
                                          : ""
                                      }
                                      onChange={this.handleChange({
                                        key: col.type + "_" + rowIndex,
                                        row,
                                      })}
                                      onWheel={(e) => e.target.blur()}
                                      onMouseOver={(e) =>
                                        col.type === "quantity"
                                          ? this.handleEventPositioned(
                                              e,
                                              row.lineitemId
                                            )
                                          : ""
                                      }
                                      className="inputBlockContainer"
                                      style={
                                        col.type === "quantity" && row.quantity
                                          ? {
                                              textAlign: "right",
                                            }
                                          : col.type === "unit"
                                          ? {
                                              textAlign: "left",
                                            }
                                          : { textAlign: "right" }
                                      }
                                    />
                                    <ReactTooltip
                                      effect={"solid"}
                                      html={true}
                                      place={"BottomCenter"}
                                      className="tooltipClassBorder"
                                      backgroundColor="rgba(0,127,173,0.5)"
                                      textColor="#FFF"
                                      border={true}
                                      borderColor="#04749c"
                                    />
                                    {/* <span style={{ zIndex: "1" }}>
                                      {col.type === "quantity" ? row.uom : ""}
                                    </span> */}
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          ) : (
                            <td>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {this.props.storeIssueViewByIdList &&
                                    row.status === "1" &&
                                    (col.type === "srNo" ||
                                      col.type === "department" ||
                                      col.type === "itemGroup" ||
                                      col.type === "item") ? (
                                      <>
                                        <input
                                          type="text"
                                          value={
                                            col.type === "department"
                                              ? row.departmentName
                                              : col.type === "itemGroup"
                                              ? row.itemGroupName
                                              : col.type === "item"
                                              ? row.itemName
                                              : col.type === "srNo"
                                              ? row.srPrintNo
                                              : ""
                                          }
                                          disabled="true"
                                          className="inputBlockContainer"
                                        />
                                      </>
                                    ) : (
                                      <DynamicSelect
                                        isDropdownOpen={
                                          this.state.isDropdownOpen
                                        }
                                        handleMenuOpen={this.handleMenuOpen}
                                        arrayOfData={
                                          col.type === "department"
                                            ? this.state.departmentList
                                            : col.type === "itemGroup"
                                            ? row.itemGroupList
                                            : col.type === "item"
                                            ? row.itemsList
                                            : col.type === "srNo"
                                            ? row.srList
                                            : col.type === "costFactor"
                                            ? row.deptId !== 0
                                              ? row.costFactorList
                                              : []
                                            : col.type === "machineNo"
                                            ? this.state.machineList
                                            : []
                                        }
                                        className="dropdownBlockContainer"
                                        onSelectChange={
                                          this.handleChangedropdown
                                        }
                                        isDisabled={
                                          col.type === "itemGroup" &&
                                          row.deptId === 0
                                            ? true
                                            : col.type === "item" &&
                                              row.itemGroupId === 0
                                            ? true
                                            : col.type === "srNo" &&
                                              row.itemId === 0
                                            ? true
                                            : col.type === "costFactor" &&
                                              row.srNo == ""
                                            ? true
                                            : col.type === "machineNo" &&
                                              row.costfactor == ""
                                            ? true
                                            : false
                                        }
                                        selected={
                                          col.type === "department"
                                            ? row.deptId
                                            : col.type === "itemGroup"
                                            ? row.itemGroupId
                                            : col.type === "item"
                                            ? row.itemId
                                            : col.type === "costFactor"
                                            ? row.costfactor
                                            : col.type === "machineNo"
                                            ? row.machineNo
                                            : col.type === "srNo"
                                            ? row.srNo
                                            : ""
                                        }
                                        name={
                                          col.name === "department"
                                            ? "department"
                                            : col.name === "itemGroup"
                                            ? "itemGroup"
                                            : col.name === "item"
                                            ? "item"
                                            : col.name === "srNo"
                                            ? "srNo"
                                            : col.name === "costFactor"
                                            ? "costFactor"
                                            : col.name === "machineNo"
                                            ? "machineNo"
                                            : ""
                                        }
                                        row={row}
                                        value={
                                          col.type === "department"
                                            ? row.deptId
                                            : col.type === "itemGroup"
                                            ? row.itemGroupId
                                            : col.type === "item"
                                            ? row.itemId
                                            : col.type === "costFactor"
                                            ? row.costfactor
                                            : col.type === "machineNo"
                                            ? row.machineNo
                                            : col.type === "srNo"
                                            ? row.srNo
                                            : ""
                                        }
                                        update={
                                          this.props.location.state ? 1 : 0
                                        }
                                        // isDisabled={}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          )
                        )}
                    </>

                    <td className="">
                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 100 }}
                        title="Delete"
                      >
                        <IconButton
                          onClick={() => {
                            this.onDelLineItem(row);
                          }}
                          disabled={filterData.length === 1 ? true : false}
                        >
                          <DeleteIcon className="deleteDisplayButton" />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    itemGroupList,
    srStockList,
    srList,
    machineList,
    itemList,
    createStoreIssue,
    storeIssueLineItem,
    branchStockList,
    storeIssueViewByIdList,
  } = state.Material_Request_List;
  const { costFactorList } = state.BillsReducer;
  const { departmentList, itemsListForIndent } = state.DropDownListReducer;

  return {
    departmentList,
    itemGroupList,
    itemsListForIndent,
    srStockList,
    srList,
    costFactorList,
    machineList,
    itemList,
    createStoreIssue,
    storeIssueLineItem,
    branchStockList,
    storeIssueViewByIdList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getDepartmentList,
    getItemGroupById,
    getAllItemList,
    getSrStockList,
    getSrList,
    getCostList,
    getMachineNoList,
    createStoreIssueLinewise,
    storeIssueLineItemWise,
    getBranchStockList,
    getItemGroupListforConsumption,
    getItemsListforConsumption,
    getSrNoForConsumption,
    getCostFactorConsumption,
  })(MaterialRequestLineItem)
);
