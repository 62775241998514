import {
  UOM_MASTER_LIST,
  UOM_LIST_SUCCESSFULL,
  CREATE_UOM_MASTER,
  CREATE_UOM_MASTER_SUCCESSFULL,
  GET_UOM_RELATION_DATA,
  GET_UOM_RELATION_DATA_SUCCESSFULL,
  DELETE_UOM_RELATION,
  DELETE_UOM_RELATION_SUCCESSFULL,
  UOM_LIST_BASEDON_ITEM_ID,
  UOM_LIST_BASEDON_ITEM_ID_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  relationLoading: false,
  Uom_List: [],
  uomMastersList: [],
  uom_relation_data: [],
  create_uom_master: [],
  error_msg: null,
  deleteList: [],
};

const UomListReducer = (state = initialState, action) => {
  switch (action.type) {
    // delete uom
    case DELETE_UOM_RELATION:
      state = {
        ...state,
        loading: true,
      };
      break;

    case DELETE_UOM_RELATION_SUCCESSFULL:
      state = {
        ...state,
        deleteList: action.payload.response,
        loading: false,
      };
      break;
    case UOM_MASTER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UOM_LIST_SUCCESSFULL:
      state = {
        ...state,
        Uom_List: action.payload.response,
        loading: false,
      };
      break;
    //Create UOM Master
    case CREATE_UOM_MASTER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CREATE_UOM_MASTER_SUCCESSFULL:
      state = {
        ...state,
        create_uom_master: action.payload.response,
        loading: false,
      };
      break;
    // GET UOM RELATION
    case GET_UOM_RELATION_DATA:
      state = {
        ...state,
        relationLoading: true,
      };
      break;

    case GET_UOM_RELATION_DATA_SUCCESSFULL:
      state = {
        ...state,
        uom_relation_data: action.payload.response,
        relationLoading: false,
      };
      break;
    case UOM_LIST_BASEDON_ITEM_ID:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UOM_LIST_BASEDON_ITEM_ID_SUCCESS:
      state = {
        ...state,
        uomMastersList: action.payload.response,
        loading: false,
      };
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default UomListReducer;
