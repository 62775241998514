import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import { getInwardList } from "../../../store/Purchase/Inward/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      storeReceiptListData: [],
      storeReceiptListColumns: [],
    };
  }

  componentDidMount() {
    const data = {
      branchId: "",
      cipher: "d351f787f92fd392b0c30979e85630ef",
      companyId: "119",
      erpType: "1",
      financialYear: "2022",
      fromDate: "",
      projectId: "",
      recordType: "SR",
      supplierId: null,
      toDate: "",
    };

    this.props.getInwardList(serverApi.ALLINWARDS, data, this.props.history);
  }
  componentWillReceiveProps(props) {
    if (props.Inward_List) {
      this.setState({
        storeReceiptListData: props.Inward_List.data,
        storeReceiptListColumns: props.Inward_List.column,
      });
    }
  }

  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.storeReceiptListData}
          mcolumns={this.state.storeReceiptListColumns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          url="create_cost_plan"
        />
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Inward_List } = state.Inward_List;
  return { Inward_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getInwardList,
  })(Index)
);
