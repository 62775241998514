import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { Grid, Box } from "@mui/material";

import TextFieldNormal from "../TextField/TextFieldNormal";
import TextFieldGstVerify from "../TextField/TextFieldGstVerify";
import CustomButton from "../Buttons/Buttons";
import { getStateNameList, getGstVerification } from "../../store/Global/DropDownApis/actions";
import {
  setCustomerMasterUpdate, getAllLedgerData
} from "../../store/Master/CustomerMaster/actions";

import { serverApi } from "../../helpers/Consts";
import DynamicSelect from "../Dropdown/DynamicSelect";
import swal from "sweetalert";

const FormPopup = ({ isOpen, onClose, customerData, customerId }) => {
  const [stateList, setStateNameList] = useState([]);
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [state, setStateState] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [branchAddress, setBranchAddress] = useState("");
  const [city, setCity] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [openingBalance, setOpeningBalance] = useState("");
  const [accNumber, setAccNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [isChecked, setChecked] = useState(false);
  const [branchName, setBranchName] = useState("");
  const [ledgerGroup, setledgerGroup] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  
  const { stateNameList } = useSelector((state) => state.DropDownListReducer);
  const { gstVerificationStatus } = useSelector(
    (state) => state.DropDownListReducer
  );

  const { ledgerGroupsList } = useSelector((state) => state.CustomerListReducer);

  useEffect(() => {
    dispatch(getStateNameList(serverApi.GET_STATE_NAME_LIST, history));
  }, [isOpen]);

  const companyId = localStorage.getItem("companyId");
  const userDit =  JSON.parse(localStorage.getItem("authUser"));

  useEffect(() => {
    if(userDit && userDit.userId) {
      dispatch(getAllLedgerData(
        serverApi.GET_LEDGER_GROUPS_LIST +
        companyId +
        "/" +
        userDit.userId,
        history
      ))
    }
  }, [])
  
  useEffect(() => {
    if (stateNameList.data) {
      setStateNameList(stateNameList.data);
    }
  }, [stateNameList.data]);

  const initializeState = () => {
    setStateNameList([]);
    setStateValue("");
    setStateCode("");
    setBranchAddress("");
    setCity("");
    setGstNo("");
    setPinCode("");
    setContactPerson("");
    setContactNumber("");
    setOpeningBalance("");
    setAccNumber("");
    setIfscCode("");
    setChecked(false);
    setBranchName("");
    setledgerGroup("")
    // ... (reset other state variables)
  };

  const onClickVerify = (name) => {
    dispatch(getGstVerification(
      serverApi.GST_VERIFICATION_API + gstNo,
      history,
      )
    );
  }

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const onhandlechange = (key) => (event) => {
    if(key === "branchName") {
      setBranchName(event)
    }
    if(key === "branchAddress") {
      setBranchAddress(event)
    }
    if(key === "city") {
      setCity(event)
    }
    if(key === "gstNumber") {
      setGstNo(event)
    }
    if(key === "pinCode") {
      setPinCode(event)
    }
    if(key === "openinigBalance") {
      setOpeningBalance(event)
    }
    if(key === "accountNumber") {
      setAccNumber(event)
    }
    if(key === "ifscCode") {
      setIfscCode(event)
    }
    if(key === "contactPerson") {
      setContactPerson(event)
    }
    if(key === "contactNumber") {
      setContactNumber(event)
    }
  };

  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };

  const handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "state") {
      let stateDetails = selectedName.split("^");
      setStateState(stateDetails[0]);
      setStateValue(selectedValue);
      setStateCode(stateDetails[1]);
    } if (name === "ledgerGroup") {
      if(selectedValue === 0) {
        setledgerGroup("");

        return;
      }
      setledgerGroup(selectedValue)
    } 
  };

  //
  const checkboxStyle = {
    width: "20px", // Increase the width of the checkbox
    height: "20px", // Increase the height of the checkbox
    color: "#000000"
  };

  const labelStyle = {
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
    fontWeight: 400,
  };

  const headerStyle = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 550,
    fontSize: "18px",
    color: "#004B73",
    marginBottom: "25px",
  };

  const handleSubmit = () => {
    if(branchName === "") {
      swal("Fill in the branch name");
      return
    }
    if(branchAddress === "") {
      swal("Fill in the branch address");
      return
    }
    if(city === "") {
      swal("Fill in the city");
      return
    }
    if(stateValue === "") {
      swal("Please select an state to proceed.");
      return
    }
    if(gstNo === "") {
      swal("Please enter a valid GST number.");
      return
    }
    if(pinCode === "") {
      swal("Please enter a valid PIN code.");
      return
    }

    const branchDetails = {
        address: branchAddress,
        city: city,
        createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
        customerId: customerId,
        customerLineId: "",
        gstNo: gstNo,
        isActive: 1,
        pincode: pinCode,
        state: stateValue,
        stateCode: stateCode,
        displayname: null,
        fileUploadId: null,
        Bankdisplayname: null,
        BankfileUploadId: null,
        contactNo: contactNumber,
        contactPerson: contactPerson,
        openningBalance: openingBalance,
        accountNumber: accNumber,
        ifscCode: ifscCode,
        isGstVerified: gstVerificationStatus ? 1 : 0,
        futureReference: isChecked ? 1 : 0,
        addressType: 1,
        branchName: branchName,
        ledgerGroup: ledgerGroup
    }

    var filterProductSSSSS = customerData?.data?.customerLineitems.filter((prop) => {
      if (prop.isActive === 1 && prop.address !== "") {
        return prop;
      }
    }) || []

    filterProductSSSSS.push(branchDetails)
  
    const data = {
      customerMasterHeader: customerData?.data?.customerHdr,
      customerMasterLineItems: filterProductSSSSS
    }

    const isSales = true;

    dispatch(
      setCustomerMasterUpdate(
        serverApi.UPDATE_CUSTOMER_MASTER,
        data,
        history,
        isSales,
        customerId
      )
    )
    onClose();
    initializeState();
  }

  const handleOnCancel = () => {
    if(onClose) {
      onClose();
      initializeState();
    }
  }

  return (
    <>
      {isOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h1 style={headerStyle}>Branch Details</h1>
            <div style={{ marginBottom: "16px" }}>
              <label style={labelStyle} htmlFor={"myCheckbox"}>
                <input type="checkbox" checked={isChecked} style={checkboxStyle} onChange={handleCheckboxChange}/>
                <span style={{ marginLeft: "5px" }}>
                  Save for Future Reference
                </span>
              </label>
            </div>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 4">
                    <TextFieldNormal
                        label="Branch Name"
                        value={branchName}
                        onChange={onhandlechange("branchName")}
                        autoComplete="off"
                        required
                      />
                    </Box>
                    <Box gridColumn="span 4">
                      <TextFieldNormal
                        type="text"
                        label="Branch Address"
                        onChange={onhandlechange("branchAddress")}
                        value={branchAddress}
                        autoComplete="off"
                        required
                      />
                    </Box>
                    <Box gridColumn="span 4">
                      <TextFieldNormal
                        type="text"
                        label="City"
                        onChange={onhandlechange("city")}
                        value={city}
                        autoComplete="off"
                        required
                      />
                    </Box>
                    <Box gridColumn="span 4">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={stateList.filter(state => state.value !== 0)}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={handlePropSelectChange}
                          isDropdownOpen={isDropdownOpen}
                          handleMenuOpen={handleMenuOpen}
                          selected={stateValue}
                          update={location.state ? 1 : 0}
                          name="state"
                          label="State"
                          required
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 4">
                      <TextFieldNormal
                        label="State Code"
                        onChange={onhandlechange("stateCode")}
                        value={stateCode}
                        disabled={true}
                        required
                        autoComplete="off"
                      />
                    </Box>
                    <Box gridColumn="span 4">
                      <TextFieldGstVerify
                        label="GST Number"
                        onChange={onhandlechange("gstNumber")}
                        value={gstNo}
                        onVerify={onClickVerify}
                        isGstVerified={gstVerificationStatus}
                        required
                        autoComplete="off"
                      />  
                      
                    </Box>
                    <Box gridColumn="span 4">
                      <TextFieldNormal
                        type="number"
                        label="Pincode"
                        onChange={onhandlechange("pinCode")}
                        value={pinCode}
                        required
                        autoComplete="off"
                      />
                    </Box>
                    <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={
                         ledgerGroupsList.data &&
                          ledgerGroupsList.data.length !== 0 &&
                          ledgerGroupsList.data.map((item) => ({
                            label: item.name,
                            value: item.value,
                            name: item.name,
                          }))
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={ledgerGroup}
                        update={ledgerGroup ? 1 : 0}
                        name="ledgerGroup"
                        label="Ledger Group"
                      />
                    </div>
                  </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
            <div className="consoleFormBlock" style={{ marginTop: "15px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 4">
                      <TextFieldNormal
                        label="Contact Person"
                        value={contactPerson}
                        onChange={onhandlechange("contactPerson")}
                        autoComplete="off"
                      />
                    </Box>
                    <Box gridColumn="span 4">
                      <TextFieldNormal
                        label="Contact Number"
                        value={contactNumber}
                        onChange={onhandlechange("contactNumber")}
                        autoComplete="off"
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>

            <div className="consoleFormBlock" style={{ marginTop: "15px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 4">
                      <TextFieldNormal
                        type="number"
                        label="Opening Balance"
                        onChange={onhandlechange("openinigBalance")}
                        value={openingBalance}
                        autoComplete="off"
                      />
                    </Box>
                    <Box gridColumn="span 4">
                      <TextFieldNormal
                        type="number"
                        label="Account Number"
                        value={accNumber}
                        onChange={onhandlechange("accountNumber")}
                        autoComplete="off"
                      />
                    </Box>
                    <Box gridColumn="span 4">
                      <TextFieldNormal
                        label="IFSC code"
                        value={ifscCode}
                        onChange={onhandlechange("ifscCode")}
                        autoComplete="off"
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>

            <div className="consoleFormButtonBlock">
              <CustomButton
                label={"Cancel"}
                className="greenBorderButton"
                handleClick={handleOnCancel}
              />
              <CustomButton
                label={"Save"}
                className="greenButton"
                type="sumbit"
                handleClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FormPopup;
