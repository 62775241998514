import {
  CUTS_AND_JUGAR_ENTRY_LIST,
  CUTS_AND_JUGAR_ENTRY_LIST_DEL,
  CUTS_AND_JUGAR_ENTRY_LIST_DEL_SUCCESSFULL,
  CUTS_AND_JUGAR_ENTRY_LIST_SUCCESSFULL,
  GET_LOOM_NO,
  GET_LOOM_NO_SUCCESSFULL,
  GET_CUTS_JUGAR_BY_LOOM,
  GET_CUTS_JUGAR_BY_LOOM_SUCCESSFULL,
  GET_DAILY_QUALITY_MAPPING,
  GET_DAILY_QUALITY_MAPPING_SUCCESSFULL,
  CUTS_JUGAR_DATA_LIST,
  CUTS_JUGAR_DATA_LIST_SUCCESSFULL,
  CREATE_ACTUAL_SHOTS,
  CREATE_ACTUAL_SHOTS_SUCCESSFULL,
  DELETE_ACTUAL_SHOTS,
  DELETE_ACTUAL_SHOTS_SUCCESSFULL,
  UPDATE_EB_NO_WORKING_HOURS,
  UPDATE_EB_NO_WORKING_HOURS_SUCCESSFULL,
  CUTS_JUGAR_LIST,
  CUTS_JUGAR_LIST_SUCCESSFULL,
  CREATE_CUTS_AND_JUGAR,
  CREATE_CUTS_AND_JUGAR_SUCCESSFULL,
  UPDATE_WEAVING_MACHINE_MAPPING,
  UPDATE_WEAVING_MACHINE_MAPPING_SUCCESSFULL,
} from "./actionTypes";

export const CutsAndJugarList = (url, data, history) => {
  return {
    type: CUTS_AND_JUGAR_ENTRY_LIST,
    payload: { url, data, history },
  };
};
export const CutsAndJugarListListSuccessfull = (data) => {
  return {
    type: CUTS_AND_JUGAR_ENTRY_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const CutsAndJugarListDel = (url, history, fromDate, toDate) => {
  return {
    type: CUTS_AND_JUGAR_ENTRY_LIST_DEL,
    payload: { url, history, fromDate, toDate },
  };
};
export const CutsAndJugarListListDelSuccessfull = (data) => {
  return {
    type: CUTS_AND_JUGAR_ENTRY_LIST_DEL_SUCCESSFULL,
    payload: data,
  };
};
export const getLoomNo = (url, data, history, date) => {
  return {
    type: GET_LOOM_NO,
    payload: { url, data, history, date },
  };
};
export const getLoomNoSuccessfull = (data) => {
  return {
    type: GET_LOOM_NO_SUCCESSFULL,
    payload: data,
  };
};
export const getcutsandjugarByLoomNo = (url, data, history) => {
  return {
    type: GET_CUTS_JUGAR_BY_LOOM,
    payload: { url, data, history },
  };
};
export const getcutsandjugarByLoomNoSuccessfull = (data) => {
  return {
    type: GET_CUTS_JUGAR_BY_LOOM_SUCCESSFULL,
    payload: data,
  };
};
export const dailyQualityMapping = (url, data, history) => {
  return {
    type: GET_DAILY_QUALITY_MAPPING,
    payload: { url, data, history },
  };
};
export const dailyQualityMappingSuccessfull = (data) => {
  return {
    type: GET_DAILY_QUALITY_MAPPING_SUCCESSFULL,
    payload: data,
  };
};
export const CutsAndJugarDataList = (url, history) => {
  return {
    type: CUTS_JUGAR_DATA_LIST,
    payload: { url, history },
  };
};
export const CutsAndJugarDataListSuccessfull = (data) => {
  return {
    type: CUTS_JUGAR_DATA_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const createActualShot = (url, data, history) => {
  return {
    type: CREATE_ACTUAL_SHOTS,
    payload: { url, data, history },
  };
};
export const createActualShotSuccessfull = (data) => {
  return {
    type: CREATE_ACTUAL_SHOTS_SUCCESSFULL,
    payload: data,
  };
};

export const DeleteActualShot = (url, history) => {
  return {
    type: DELETE_ACTUAL_SHOTS,
    payload: { url, history },
  };
};
export const DeleteActualShotSuccessfull = (data) => {
  return {
    type: DELETE_ACTUAL_SHOTS_SUCCESSFULL,
    payload: data,
  };
};

export const updateEbNoWorkingHrs = (url, data, history) => {
  return {
    type: UPDATE_EB_NO_WORKING_HOURS,
    payload: { url, data, history },
  };
};
export const updateEbNoWorkingHrsSuccessfull = (data) => {
  return {
    type: UPDATE_EB_NO_WORKING_HOURS_SUCCESSFULL,
    payload: data,
  };
};

export const CutsJugarList = (url, data, history) => {
  return {
    type: CUTS_JUGAR_LIST,
    payload: { url, data, history },
  };
};
export const CutsJugarListListSuccessfull = (data) => {
  return {
    type: CUTS_JUGAR_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const createCutsandJugar = (url, data, history) => {
  return {
    type: CREATE_CUTS_AND_JUGAR,
    payload: { url, data, history },
  };
};
export const createCutsandJugarSuccessfull = (data) => {
  return {
    type: CREATE_CUTS_AND_JUGAR_SUCCESSFULL,
    payload: data,
  };
};

export const updateWeavingMachineMapping = (url, data, history) => {
  return {
    type: UPDATE_WEAVING_MACHINE_MAPPING,
    payload: { url, data, history },
  };
};
export const updateWeavingMachineMappingSuccessfull = (data) => {
  return {
    type: UPDATE_WEAVING_MACHINE_MAPPING_SUCCESSFULL,
    payload: data,
  };
};
