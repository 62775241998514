import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { Grid, Button, Step, Stepper, StepLabel } from "@mui/material";
import Link from "@mui/material/Link";
import FillDetails from "./FillDetails";

import JuteIssueAddItems from "./JuteIssueLineItems";
import JuteIssuePreview from "./JuteIssuePreview";

class CreateJuteIssue extends Component {
  constructor() {
    super();
    this.state = {
      steps: [
        {
          title: "Fill Details",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Add Items",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Preview",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive03.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
      ],
      currentStep: 1,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),

      headerData: {
        branchId: 0,
        issueDate: "",
      },
    };
  }

  onClickNext = () => {
    const { currentStep } = this.state;

    this.setState({
      currentStep: currentStep + 1,
      loading: false,
    });
  };

  onClickCancle = () => {
    this.props.history.push("jute_issue");
  };

  onClickBefore = () => {
    const { currentStep } = this.state;
    this.setState({
      currentStep: currentStep - 1,
    });
  };

  onClickSave = (type) => {};

  componentDidMount() {}
  componentDidUpdate() {}

  ///function for stepper
  QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;
    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };

  handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    this.setState({
      alignment: newAlignment,
      workOrderType: newAlignment,
      withorWithOutPo: newAlignment,
    });
  };

  render() {
    const { steps, currentStep } = this.state;
    return (
      <div>
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            <div className="iconsBlock">
              <Stepper alternativeLabel activeStep={currentStep}>
                {steps.map((prop) => (
                  <Step key={prop.title}>
                    <StepLabel
                      StepIconComponent={(Iconprops) => {
                        return this.QontoStepIcon(
                          Iconprops,
                          prop.icon,
                          prop.activeIcon,
                          prop.title
                        );
                      }}
                    ></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>

            {currentStep === 1 && (
              <>
                <FillDetails headerData={this.state.headerData} />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <Button
                      variant="contained"
                      className="cancelButton"
                      onClick={this.onClickBefore}
                    >
                      Back
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    type="sumbit"
                    className="saveButton"
                    onClick={this.onClickNext}
                  >
                    {this.state.loading && (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    {!this.state.loading && <span>Next</span>}
                  </Button>
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                <JuteIssueAddItems
                  RowData={RowData}
                  tableHeaders={tableHeaders}
                  headerData={this.state.headerData}
                  hdrId={this.state.hdrId}
                />
                <div className="consoleFormButtonBlock">
                  <Button
                    variant="contained"
                    className="cancelButton"
                    onClick={this.onClickBefore}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    type="sumbit"
                    className="saveButton"
                    onClick={this.onClickNext}
                  >
                    Next
                  </Button>
                </div>
              </>
            )}
            {currentStep === 3 && (
              <>
                <JuteIssuePreview
                  headerData={this.state.headerData}
                  hdrId={this.state.hdrId}
                  poNo={this.state.poNo}
                  qualityCheck={false}
                />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <Button
                      variant="contained"
                      className="cancelButton"
                      onClick={this.onClickBefore}
                    >
                      Back
                    </Button>
                  </Link>
                  {this.state.headerData.updateView ? (
                    <>
                      <Button
                        variant="contained"
                        type="button"
                        className="updateButton"
                        onClick={() => this.onClickSave(1)}
                      >
                        Update
                      </Button>
                      {this.state.headerData.editOutDate == "" ? (
                        <>
                          <Button
                            variant="contained"
                            type="button"
                            className="saveButton"
                            onClick={() => this.onClickSave(2)}
                          >
                            OUT
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                      <Button
                        variant="contained"
                        type="button"
                        className="rejectButton"
                        onClick={() => this.onClickCancle()}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      type="button"
                      className="saveButton"
                      onClick={() => this.onClickSave(0)}
                    >
                      {" "}
                      In{" "}
                    </Button>
                  )}
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "actualJuteTyp",
    dropDown: "dropDown",
    name: "actualJuteTyp",
    Items: true,
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "actualQuality",
    dropDown: "dropDown",
    name: "actualQuality",
    Items: true,
  },
  {
    no: 3,
    component: "InputAdoment",
    type: "quantity",
    label: "DRUM",
  },
  {
    no: 4,
    component: "InputAdoment",
    type: "actualWeight",
    label: "QTL",
  },
  {
    no: 5,
    component: "Inputadorment",
    type: "unitId",
  },
];

const tableHeaders = [
  {
    header: "Yarn Type",
  },
  {
    header: "Unit Conversion",
  },
  {
    header: "Jute Type",
  },
  {
    header: "Quality",
  },
  {
    header: "Godown Number",
  },
  {
    header: "MR Id",
  },
  {
    header: "Stock",
  },
  {
    header: "Quantity",
  },
  {
    header: "Total Value",
  },
  {
    header: "Action",
  },
];

const mapStatetoProps = (state) => {};

export default withRouter(connect(mapStatetoProps, {})(CreateJuteIssue));
