import {
  limitDecimals,
  roundAndRemainingValues,
  isEmpty,
  isEmptyWithZero,
} from "./helper";

export const SalesGstCalculations = (responseData, Type) => {
  let totalIGST = "0.00";
  let totalCGST = "0.00";
  let totalSGST = "0.00";
  let subTotal = "0.00";
  let taxTotal = "0.00";
  let netTotal = "0.00";
  let TotalAmount = "0.00";
  let roundingPositiveValue = "";
  let roundingNegetiveValue = "";
  switch (Type) {
    case "SALES_REGULAR_FLOW":
      const lineItems = responseData.lineItems.filter(
        (item) =>
          item.isActive !== 0 &&
          item.itemGroupId !== null &&
          item.itemGroupId !== ""
      );
      if (lineItems.length !== 0) {
        lineItems.map((row) => {
            var totAMt = Number(row.quantity) * Number(row.discountedRate);
            row.netAmount = limitDecimals(totAMt, 2);
          // Discount Amount Calculation
          if (row.discountType == 1 && !isEmpty(row.discount)) {
            row.discountAmount = limitDecimals(
              Number(row.discount) * Number(row.quantity),
              2
            );
          } else if (row.discountType == 2 && !isEmpty(row.discount)) {
            let data = (row.rate * row.discount) / 100;
            row.discountAmount = limitDecimals(
              Number(data) * Number(row.quantity),
              2
            );
          } else {
            row.discountAmount = "0.00";
          }

          const taxPercent = Number(row.taxPercent) / 2;
          const taxPercentageVal = limitDecimals(Number(taxPercent),2);
          const taxAmount = (Number(taxPercentageVal) * Number(row.netAmount)) / 100;
          const GST_taxAmount = Number(taxAmount);

          // Calculation for No Tax
          if (isEmptyWithZero(responseData.taxType)) {
            row.igstPercent = 0;
            row.cgstPercent = 0;
            row.sgstPercent = 0;
            row.igstAmount = 0;
            row.cgstAmount = 0;
            row.sgstAmount = 0;
            row.totalAmount = limitDecimals(totAMt);
            let netAmount = lineItems
              .map((p) => p.netAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            subTotal = netAmount;
            netTotal = netAmount;
            TotalAmount = Number(netAmount);
            totalIGST= "0.00";
            totalCGST= "0.00";
            totalSGST= "0.00";
            taxTotal=  "0.00";
            //  Calculation For IGST
          } else if (responseData.taxType == 1) {
            const taxAmount =
            (Number(row.taxPercent) * Number(row.netAmount)) / 100;
            row.igstPercent = row.taxPercent;
            row.cgstPercent = 0;
            row.sgstPercent = 0;
            row.igstAmount = limitDecimals(taxAmount);
            row.cgstAmount = 0;
            row.sgstAmount = 0;
            row.totalAmount = limitDecimals(Number(totAMt) + Number(taxAmount));
            let netAmount = lineItems
              .map((p) => p.netAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            totalIGST = lineItems
              .map((p) => p.igstAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            totalCGST = "0.00";
            totalSGST = "0.00";
            subTotal = limitDecimals(netAmount);
            taxTotal =limitDecimals(totalIGST);
            netTotal =limitDecimals(netAmount);
            TotalAmount =limitDecimals(Number(netAmount) +Number(totalIGST));
            var RoundOffVal = roundAndRemainingValues(lineItems);
            roundingPositiveValue = RoundOffVal.positiveRF;
            roundingNegetiveValue = RoundOffVal.NegativeRF;
            // Calculation For CGST and SGST
          } else {
            row.igstPercent = "0.00";
            row.cgstPercent = taxPercent;
            row.sgstPercent = taxPercent;
            row.igstAmount = "0.00";
            row.cgstAmount =limitDecimals(GST_taxAmount)
            row.sgstAmount =limitDecimals(GST_taxAmount)
            row.totalAmount = limitDecimals(
             Number(totAMt) + Number(GST_taxAmount) +Number(GST_taxAmount)
            );
            totalIGST = "0.00";
            totalCGST = lineItems
              .map((p) => p.cgstAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            totalSGST = lineItems
              .map((p) => p.sgstAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            let netAmount = lineItems
              .map((p) => p.netAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            subTotal = limitDecimals(netAmount)
            taxTotal =limitDecimals(Number(totalCGST) + Number(totalSGST))
            netTotal = limitDecimals(netAmount);
            TotalAmount =limitDecimals(Number(netAmount) + Number(taxTotal))
            var RoundOffVal = roundAndRemainingValues(lineItems);
            roundingPositiveValue = RoundOffVal.positiveRF;
            roundingNegetiveValue = RoundOffVal.NegativeRF;
          }
        });
        return {
          lineItems: responseData.lineItems,
          totalIGST: totalIGST,
          totalCGST: totalCGST,
          totalSGST: totalSGST,
          subTotal: subTotal,
          taxTotal: taxTotal,
          netTotal: netTotal,
          TotalAmount: TotalAmount,
          roundingPositiveValue: roundingPositiveValue,
          roundingNegetiveValue: roundingNegetiveValue,
        };
      } else {
        return {
          lineItems: responseData.lineItems,
          totalIGST: totalIGST,
          totalCGST: totalCGST,
          totalSGST: totalSGST,
          subTotal: subTotal,
          taxTotal: taxTotal,
          netTotal: netTotal,
          TotalAmount: TotalAmount,
        };
      }
    case "REGULAR_SALESORDER":
      const lineItemsObj = responseData.lineItems.filter(
        (item) =>
          item.isActive !== 0 &&
          item.itemGroupId !== null &&
          item.itemGroupId !== ""
      );
      if (lineItemsObj.length !== 0) {
        lineItemsObj.map((row) => {
          if (row.quantity !== "") {
            var totAMt = Number(row.quantity) * Number(row.discountedRate);
            row.totalAmount = limitDecimals(totAMt);
          }
          var taxPercent = Number(row.taxPercent) / 2;
          var taxAmount =
            (Number(row.taxPercent) * Number(row.totalAmount)) / 100;
          var GST_taxAmount = Number(taxAmount) / 2;
          if (responseData.taxType === 0) {
            row.igstPercent = 0;
            row.cgstPercent = 0;
            row.sgstPercent = 0;
            row.igstAmount = 0;
            row.cgstAmount = 0;
            row.sgstAmount = 0;
            let totalAmtt = lineItemsObj
              .map((p) => p.totalAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            subTotal = totalAmtt;
            netTotal = totalAmtt;
            TotalAmount = Number(totalAmtt);
          } else if (responseData.taxType === 1) {
            row.igstPercent = row.taxPercent;
            row.cgstPercent = 0;
            row.sgstPercent = 0;
            row.igstAmount = limitDecimals(taxAmount);
            row.cgstAmount = 0;
            row.sgstAmount = 0;
            let totalAmtt = lineItemsObj
              .map((p) => p.totalAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            totalIGST = lineItemsObj
              .map((p) => p.igstAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            totalCGST = "0.00";
            totalSGST = "0.00";
            subTotal =limitDecimals(totalAmtt)
            taxTotal =limitDecimals(totalIGST)
            netTotal =limitDecimals(totalAmtt)
            TotalAmount =limitDecimals(Number(totalAmtt) + Number(totalIGST))
            var RoundOffVal = roundAndRemainingValues(lineItemsObj);
            roundingPositiveValue = RoundOffVal.positiveRF;
            roundingNegetiveValue = RoundOffVal.NegativeRF;
          } else {
            row.igstPercent = "0.00";
            row.cgstPercent = limitDecimals(taxPercent);
            row.sgstPercent = limitDecimals(taxPercent)
            row.igstAmount = "0.00";
            row.cgstAmount =limitDecimals(GST_taxAmount)
            row.sgstAmount =limitDecimals(GST_taxAmount)
            totalIGST = "0.00";
            totalCGST = lineItemsObj
              .map((p) => p.cgstAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            totalSGST = lineItemsObj
              .map((p) => p.sgstAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            let totalAmt = lineItemsObj
              .map((p) => p.totalAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            subTotal =limitDecimals(totalAmt)
            taxTotal =limitDecimals(Number(totalCGST) + Number(totalSGST))
            netTotal = limitDecimals(totalAmt)
            TotalAmount =limitDecimals(Number(totalAmt) + Number(taxTotal))
            var RoundOffVal = roundAndRemainingValues(lineItemsObj);
            roundingPositiveValue = RoundOffVal.positiveRF;
            roundingNegetiveValue = RoundOffVal.NegativeRF;
          }
        });
        return {
          lineItems: responseData.lineItems,
          totalIGST: totalIGST,
          totalCGST: totalCGST,
          totalSGST: totalSGST,
          subTotal: subTotal,
          taxTotal: taxTotal,
          netTotal: netTotal,
          TotalAmount: TotalAmount,
          roundingPositiveValue: roundingPositiveValue,
          roundingNegetiveValue: roundingNegetiveValue,
        };
      } else {
        return {
          lineItems: responseData.lineItems,
          totalIGST: totalIGST,
          totalCGST: totalCGST,
          totalSGST: totalSGST,
          subTotal: subTotal,
          taxTotal: taxTotal,
          netTotal: netTotal,
          TotalAmount: TotalAmount,
        };
      }
    case "INVOICE":
      const lineItemsobj = responseData.lineItems.filter(
        (item) =>
          item.isActive !== 0 &&
          item.itemGroupId !== null &&
          item.itemGroupId !== ""
      );
      if (lineItemsobj.length !== 0) {
        lineItemsobj.map((row) => {
          // if (row.quantity !== "") {
            var totAMt = Number(row.quantity) * Number(row.discountedRate);
            row.netAmount = limitDecimals(totAMt);
          // }
          if (row.discountType == 1 && !isEmpty(row.discount)) {
            row.discountAmount = limitDecimals(
              Number(row.discount) * Number(row.quantity),
              2
            );
          } else if (row.discountType == 2 && !isEmpty(row.discount)) {
            let data = (row.rate * row.discount) / 100;
            row.discountAmount = limitDecimals(
              Number(data) * Number(row.quantity),
              2
            );
          } else {
            row.discountAmount = "0.00";
          }
          const taxPercent = Number(row.taxPercent) / 2;
          const taxPercentageVal = limitDecimals(Number(taxPercent),2);
          const taxAmount = (Number(taxPercentageVal) * Number(row.netAmount)) / 100;
          const GST_taxAmount = Number(taxAmount);
          if (responseData.taxType === 0) {
            row.igstPercent = 0;
            row.cgstPercent = 0;
            row.sgstPercent = 0;
            row.igstAmount = 0;
            row.cgstAmount = 0;
            row.sgstAmount = 0;
            row.totalAmount = limitDecimals(totAMt);
            let totalAmtt = lineItemsObj
              .map((p) => p.netAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            subTotal = totalAmtt;
            netTotal = totalAmtt;
            TotalAmount = Number(totalAmtt);
          } else if (responseData.taxType === 1) {
            const taxAmount =
            (Number(row.taxPercent) * Number(row.netAmount)) / 100;
            row.igstPercent = row.taxPercent;
            row.cgstPercent = 0;
            row.sgstPercent = 0;
            row.igstAmount =limitDecimals(taxAmount)
            row.cgstAmount = 0;
            row.sgstAmount = 0;
            row.totalAmount = limitDecimals(Number(totAMt) + Number(taxAmount));

            let totalAmtt = lineItemsobj
              .map((p) => p.netAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            totalIGST = lineItemsobj
              .map((p) => p.igstAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            totalCGST = "0.00";
            totalSGST = "0.00";
            subTotal =limitDecimals(totalAmtt)
            taxTotal =limitDecimals(totalIGST)
            netTotal = limitDecimals(totalAmtt)
            TotalAmount =limitDecimals(Number(totalAmtt) + Number(totalIGST))
            var RoundOffVal = roundAndRemainingValues(lineItemsobj);
            roundingPositiveValue = RoundOffVal.positiveRF;
            roundingNegetiveValue = RoundOffVal.NegativeRF;
          } else {
            row.igstPercent = "0.00";
            row.cgstPercent = limitDecimals(taxPercent)
            row.sgstPercent =limitDecimals(taxPercent)
            row.igstAmount = "0.00";
            row.cgstAmount = limitDecimals(GST_taxAmount)
            row.sgstAmount =limitDecimals(GST_taxAmount)
            totalIGST = "0.00";
            row.totalAmount = limitDecimals(
              totAMt + GST_taxAmount + GST_taxAmount
            );

            totalCGST = lineItemsobj
              .map((p) => p.cgstAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            totalSGST = lineItemsobj
              .map((p) => p.sgstAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            let totalAmt = lineItemsobj
              .map((p) => p.netAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            subTotal =limitDecimals(totalAmt)
            taxTotal = limitDecimals(Number(totalCGST )+Number(totalSGST))
            netTotal =limitDecimals(totalAmt)
            TotalAmount =limitDecimals(Number(totalAmt) +Number(taxTotal))
            var RoundOffVal = roundAndRemainingValues(lineItemsobj);
            roundingPositiveValue = RoundOffVal.positiveRF;
            roundingNegetiveValue = RoundOffVal.NegativeRF;
          }
        });
        return {
          lineItems: responseData.lineItems,
          totalIGST: totalIGST,
          totalCGST: totalCGST,
          totalSGST: totalSGST,
          subTotal: subTotal,
          taxTotal: taxTotal,
          netTotal: netTotal,
          TotalAmount: TotalAmount,
          roundingPositiveValue: roundingPositiveValue,
          roundingNegetiveValue: roundingNegetiveValue,
        };
      } else {
        return {
          lineItems: responseData.lineItems,
          totalIGST: totalIGST,
          totalCGST: totalCGST,
          totalSGST: totalSGST,
          subTotal: subTotal,
          taxTotal: taxTotal,
          netTotal: netTotal,
          TotalAmount: TotalAmount,
        };
      }
    case "SO_JUTE_HESSIAN":
      const LineItemsobjdata = responseData.lineItems.filter(
        (item) =>
          item.isActive !== 0 &&
          item.itemGroupId !== null &&
          item.itemGroupId !== ""
      );
      if (LineItemsobjdata.length !== 0) {
        LineItemsobjdata.map((row) => {
          // if (row.quantity !== "") {
          if (isEmptyWithZero(responseData.brokerCommisionPercent)) {
            row.hessianRateExcludingBrokerage = row.discountedRate;
            row.hessianRateIncludingBrokerage = row.discountedRate;
          } else {
            row.hessianRateExcludingBrokerage = row.discountedRate;
            var RateAfterBroker = limitDecimals(
              (Number(row.discountedRate) *
                Number(responseData.brokerCommisionPercent)) /
                100,
              3
            );
            row.hessianRateIncludingBrokerage = limitDecimals(
              Number(row.discountedRate) - Number(RateAfterBroker),
              3
            );
          }
          var totAMt =
            Number(row.quantity) * Number(row.hessianRateIncludingBrokerage);
          row.netAmount = limitDecimals(totAMt);
          // }
          if (row.discountType == 1 && !isEmpty(row.discount)) {
            row.discountAmount = limitDecimals(
              Number(row.discount) * Number(row.quantity),
              2
            );
          } else if (row.discountType == 2 && !isEmpty(row.discount)) {
            let data = (row.rate * row.discount) / 100;
            row.discountAmount = limitDecimals(
              Number(data) * Number(row.quantity),
              2
            );
          } else {
            row.discountAmount = "0.00";
          }
          var taxPercent = Number(row.taxPercent) / 2;
          var taxAmount =
            (Number(row.taxPercent) * Number(row.netAmount)) / 100;
          var GST_taxAmount = Number(taxAmount) / 2;
          if (responseData.taxType === 0) {
            row.igstPercent = 0;
            row.cgstPercent = 0;
            row.sgstPercent = 0;
            row.igstAmount = 0;
            row.cgstAmount = 0;
            row.sgstAmount = 0;
            row.totalAmount = limitDecimals(totAMt);

            let totalAmtt = LineItemsobjdata.map((p) => p.netAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            subTotal =limitDecimals(totalAmtt)
            netTotal = limitDecimals(totalAmtt)
            TotalAmount = limitDecimals(Number(totalAmtt))
          } else if (responseData.taxType === 1) {
            row.igstPercent = row.taxPercent;
            row.cgstPercent = 0;
            row.sgstPercent = 0;
            row.igstAmount = limitDecimals(taxAmount)
            row.cgstAmount = 0;
            row.sgstAmount = 0;
            row.totalAmount = limitDecimals(Number(totAMt) +Number(taxAmount));

            let totalAmtt = LineItemsobjdata.map((p) => p.netAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            totalIGST = LineItemsobjdata.map((p) => p.igstAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            totalCGST = "0.00";
            totalSGST = "0.00";
            subTotal = limitDecimals(totalAmtt);
            taxTotal = limitDecimals(totalIGST)
            netTotal =limitDecimals(totalAmtt)
            TotalAmount = limitDecimals(Number(totalAmtt) + Number(totalIGST))
            var RoundOffVal = roundAndRemainingValues(LineItemsobjdata);
            roundingPositiveValue = RoundOffVal.positiveRF;
            roundingNegetiveValue = RoundOffVal.NegativeRF;
          } else {
            row.igstPercent = "0.00";
            row.cgstPercent = taxPercent;
            row.sgstPercent = taxPercent;
            row.igstAmount = "0.00";
            row.cgstAmount =limitDecimals(GST_taxAmount)
            row.sgstAmount =limitDecimals(GST_taxAmount)
            totalIGST = "0.00";
            row.totalAmount = limitDecimals(
              Number(totAMt)+ Number(GST_taxAmount) + Number(GST_taxAmount)
            );

            totalCGST = LineItemsobjdata.map((p) => p.cgstAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            totalSGST = LineItemsobjdata.map((p) => p.sgstAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            let totalAmt = LineItemsobjdata.map((p) => p.netAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            subTotal =limitDecimals(totalAmt)
            taxTotal = limitDecimals(Number(totalCGST) +Number(totalSGST))
            netTotal =limitDecimals(totalAmt)
            TotalAmount =limitDecimals(Number(totalAmt) + Number(taxTotal))
            var RoundOffVal = roundAndRemainingValues(LineItemsobjdata);
            roundingPositiveValue = RoundOffVal.positiveRF;
            roundingNegetiveValue = RoundOffVal.NegativeRF;
          }
        });
        return {
          lineItems: responseData.lineItems,
          totalIGST:limitDecimals(totalIGST),
          totalCGST:limitDecimals(totalCGST),
          totalSGST: limitDecimals(totalSGST),
          subTotal:limitDecimals( subTotal),
          taxTotal:limitDecimals( taxTotal),
          netTotal:limitDecimals( netTotal),
          TotalAmount: limitDecimals(TotalAmount),
          roundingPositiveValue: roundingPositiveValue,
          roundingNegetiveValue: roundingNegetiveValue,
        };
      } else {
        return {
          lineItems: responseData.lineItems,
          totalIGST: totalIGST,
          totalCGST: totalCGST,
          totalSGST: totalSGST,
          subTotal: subTotal,
          taxTotal: taxTotal,
          netTotal: netTotal,
          TotalAmount: TotalAmount,
        };
      }
      case "SO_HESSIAN":
        const LineItemsobjdataa = responseData.lineItems.filter(
          (item) =>
            item.isActive !== 0 &&
            item.itemGroupId !== null &&
            item.itemGroupId !== ""
        );
        if (LineItemsobjdataa.length !== 0) {
          LineItemsobjdataa.map((row) => {
            if (row.hessianQuantityCalculatedValue !== "") {
              if (
               isEmptyWithZero(responseData.brokerCommisionPercent)
              ) {
                row.hessianRateExcludingBrokerage =row.discountedRate;
                row.hessianRateIncludingBrokerage = row.discountedRate
              } else {
                row.hessianRateExcludingBrokerage =row.discountedRate;
                var RateAfterBroker = limitDecimals(Number(row.discountedRate)* Number(responseData.brokerCommisionPercent) / 100,3)
                row.hessianRateIncludingBrokerage = limitDecimals(Number(row.discountedRate) - Number(RateAfterBroker),3)
              }
              if(row.hessianRateRelationValue !==0){
                var totAMt =Number(row.hessianQuantityCalculatedValue) *Number(row.hessianRateIncludingBrokerage);
              }else{
              var totAMt =Number(row.hessianQuantityCalculatedValue) *Number(row.hessianRateCalculatedValue);
              }
              row.netAmount = limitDecimals(totAMt);
            }
            if (row.discountType == 1 && !isEmpty(row.discount)) {
              row.discountAmount = limitDecimals(
                Number(row.discount) * Number(row.hessianQuantityCalculatedValue),
                2
              );
            } else if (row.discountType == 2 && !isEmpty(row.discount)) {
              let data = (row.hessianRateCalculatedValue * row.discount) / 100;
              row.discountAmount = limitDecimals(
                Number(data) * Number(row.hessianQuantityCalculatedValue),
                2
              );
            } else {
              row.discountAmount = "0.00";
            }
            const taxPercent = Number(row.taxPercent) / 2;
            const taxPercentageVal = limitDecimals(Number(taxPercent),2);
            const taxAmount = (Number(taxPercentageVal) * Number(row.netAmount)) / 100;
            const GST_taxAmount = Number(taxAmount);
            if (responseData.taxType === 0 || responseData.taxType === "") {
              row.igstPercent = 0;
              row.cgstPercent = 0;
              row.sgstPercent = 0;
              row.igstAmount = 0;
              row.cgstAmount = 0;
              row.sgstAmount = 0;
              row.totalAmount = limitDecimals(totAMt);
   
              let totalAmtt = LineItemsobjdataa.map((p) => p.netAmount).reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
              subTotal = limitDecimals(totalAmtt);
              netTotal = limitDecimals(totalAmtt);
              TotalAmount = limitDecimals(totalAmtt);
            } else if (responseData.taxType === 1) {
              const taxAmount =
             (Number(row.taxPercent) * Number(row.netAmount)) / 100;
              row.igstPercent = row.taxPercent;
              row.cgstPercent = 0;
              row.sgstPercent = 0;
              row.igstAmount =limitDecimals(taxAmount);
              row.cgstAmount = 0;
              row.sgstAmount = 0;
              row.totalAmount = limitDecimals(Number(totAMt) + Number(taxAmount));
   
              let totalAmtt = LineItemsobjdataa.map((p) => p.netAmount).reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
              totalIGST = LineItemsobjdataa.map((p) => p.igstAmount).reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
              totalCGST = "0.00";
              totalSGST = "0.00";
              subTotal =limitDecimals(totalAmtt);
              taxTotal = limitDecimals(totalIGST);
              netTotal = limitDecimals(totalAmtt);
              TotalAmount =limitDecimals(Number(totalAmtt) + Number(totalIGST));
              var RoundOffVal = roundAndRemainingValues(LineItemsobjdataa);
              roundingPositiveValue = RoundOffVal.positiveRF;
              roundingNegetiveValue = RoundOffVal.NegativeRF;
            } else {
              row.igstPercent = "0.00";
              row.cgstPercent = taxPercent;
              row.sgstPercent = taxPercent;
              row.igstAmount = "0.00";
              row.cgstAmount =limitDecimals(GST_taxAmount);
              row.sgstAmount =limitDecimals(GST_taxAmount);
              totalIGST = "0.00";
              row.totalAmount = limitDecimals(
                Number(totAMt) +Number(GST_taxAmount) + Number(GST_taxAmount)
              );
   
              totalCGST = LineItemsobjdataa.map((p) => p.cgstAmount).reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
              totalSGST = LineItemsobjdataa.map((p) => p.sgstAmount).reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
              let totalAmt = LineItemsobjdataa.map((p) => p.netAmount).reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
              subTotal =limitDecimals(totalAmt);
              taxTotal =limitDecimals(Number(totalCGST) + Number(totalSGST));
              netTotal = limitDecimals(totalAmt);
              TotalAmount = limitDecimals(Number(totalAmt) + Number(taxTotal));
              var RoundOffVal = roundAndRemainingValues(LineItemsobjdataa);
              roundingPositiveValue = RoundOffVal.positiveRF;
              roundingNegetiveValue = RoundOffVal.NegativeRF;
            }
          });
          return {
            lineItems: responseData.lineItems,
            totalIGST: totalIGST,
            totalCGST: totalCGST,
            totalSGST: totalSGST,
            subTotal: subTotal,
            taxTotal: taxTotal,
            netTotal: netTotal,
            TotalAmount: TotalAmount,
            roundingPositiveValue: roundingPositiveValue,
            roundingNegetiveValue: roundingNegetiveValue,
          };
        } else {
          return {
            lineItems: responseData.lineItems,
            totalIGST: totalIGST,
            totalCGST: totalCGST,
            totalSGST: totalSGST,
            subTotal: subTotal,
            taxTotal: taxTotal,
            netTotal: netTotal,
            TotalAmount: TotalAmount,
          };
        }
        case "DO_HESSIAN_UOM":
          const DoHessianLineItems = responseData.lineItems.filter(
            (item) =>
              item.isActive !== 0 &&
              item.itemGroupId !== null &&
              item.itemGroupId !== ""
          );
          if (DoHessianLineItems.length !== 0) {
            DoHessianLineItems.map((row) => {
              if (row.hessianQuantityCalculatedValue !== "") {
                var totAMt = Number(row.hessianQuantityCalculatedValue) *Number(row.hessianRateCalculatedValue)
                row.netAmount = limitDecimals(totAMt);
              }
              if (row.discountType == 1 && !isEmpty(row.discount)) {
                row.discountAmount = limitDecimals(
                  Number(row.discount) * Number(row.hessianQuantityCalculatedValue),
                  2
                );
              } else if (row.discountType == 2 && !isEmpty(row.discount)) {
                let data = (row.hessianRateCalculatedValue * row.discount) / 100;
                row.discountAmount = limitDecimals(
                  Number(data) * Number(row.hessianQuantityCalculatedValue),
                  2
                );
              } else {
                row.discountAmount = "0.00";
              }
              var taxPercent = Number(row.taxPercent) / 2;
              var taxAmount =
                (Number(row.taxPercent) * Number(row.netAmount)) / 100;
              var GST_taxAmount = Number(taxAmount) / 2;
              if (responseData.taxType === 0) {
                row.igstPercent = 0;
                row.cgstPercent = 0;
                row.sgstPercent = 0;
                row.igstAmount = 0;
                row.cgstAmount = 0;
                row.sgstAmount = 0;
                row.totalAmount = limitDecimals(totAMt);
     
                let totalAmtt = DoHessianLineItems.map((p) => p.netAmount).reduce(
                  (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                );
                subTotal =limitDecimals (totalAmtt)
                netTotal =limitDecimals( totalAmtt)
                TotalAmount = Number(totalAmtt);
              } else if (responseData.taxType === 1) {
                row.igstPercent = row.taxPercent;
                row.cgstPercent = 0;
                row.sgstPercent = 0;
                row.igstAmount =limitDecimals(taxAmount)
                row.cgstAmount = 0;
                row.sgstAmount = 0;
                row.totalAmount = limitDecimals(Number(totAMt)+ Number(taxAmount));
     
                let totalAmtt = DoHessianLineItems.map((p) => p.netAmount).reduce(
                  (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                );
                totalIGST = DoHessianLineItems.map((p) => p.igstAmount).reduce(
                  (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                );
                totalCGST = "0.00";
                totalSGST = "0.00";
                subTotal = limitDecimals(totalAmtt,2);
                taxTotal = limitDecimals(totalIGST,2);
                netTotal = limitDecimals(totalAmtt,2);
                TotalAmount =limitDecimals(Number(totalAmtt) + Number(totalIGST),2);
                var RoundOffVal = roundAndRemainingValues(DoHessianLineItems);
                roundingPositiveValue = RoundOffVal.positiveRF;
                roundingNegetiveValue = RoundOffVal.NegativeRF;
              } else {
                row.igstPercent = "0.00";
                row.cgstPercent = taxPercent;
                row.sgstPercent = taxPercent;
                row.igstAmount = "0.00";
                row.cgstAmount =limitDecimals(GST_taxAmount,2);
                row.sgstAmount =limitDecimals(GST_taxAmount,2);
                totalIGST = "0.00";
                row.totalAmount = limitDecimals(
                  Number(totAMt) +Number(GST_taxAmount) +Number(GST_taxAmount)
                ,2);
     
                totalCGST = DoHessianLineItems.map((p) => p.cgstAmount).reduce(
                  (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                );
                totalSGST = DoHessianLineItems.map((p) => p.sgstAmount).reduce(
                  (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                );
                let totalAmt = DoHessianLineItems.map((p) => p.netAmount).reduce(
                  (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                );
                subTotal =limitDecimals(totalAmt,2);
                taxTotal =limitDecimals(Number(totalCGST) + Number(totalSGST),2);
                netTotal = totalAmt;
                TotalAmount = limitDecimals(Number(totalAmt) + Number(taxTotal),2);
                var RoundOffVal = roundAndRemainingValues(DoHessianLineItems);
                roundingPositiveValue = RoundOffVal.positiveRF;
                roundingNegetiveValue = RoundOffVal.NegativeRF;
              }
            });
            return {
              lineItems: responseData.lineItems,
              totalIGST:limitDecimals(totalIGST,2),
              totalCGST:limitDecimals(totalCGST,2),
              totalSGST: limitDecimals(totalSGST,2),
              subTotal:limitDecimals(subTotal,2),
              taxTotal:limitDecimals(taxTotal,2),
              netTotal: limitDecimals(netTotal,2),
              TotalAmount:limitDecimals(TotalAmount,2),
              roundingPositiveValue: roundingPositiveValue,
              roundingNegetiveValue: roundingNegetiveValue,
            };
          } else {
            return {
              lineItems: responseData.lineItems,
              totalIGST: totalIGST,
              totalCGST: totalCGST,
              totalSGST: totalSGST,
              subTotal: subTotal,
              taxTotal: taxTotal,
              netTotal: netTotal,
              TotalAmount: TotalAmount,
            };
          }
          case "DO_HESSIAN_UOM_NEW":
            const DoHessianLineItemsNew = responseData.lineItems.filter(
              (item) =>
                item.isActive !== 0 &&
                item.itemGroupId !== null &&
                item.itemGroupId !== ""
            );
            if (DoHessianLineItemsNew.length !== 0) {
              DoHessianLineItemsNew.map((row) => {
                  var totAMt = Number(row.quantity) *Number(row.hessianRateIncludingBrokerage)
                  row.netAmount = limitDecimals(totAMt);
                
                if (row.discountType == 1 && !isEmpty(row.discount)) {
                  row.discountAmount = limitDecimals(
                    Number(row.discount) * Number(row.quantity),
                    2
                  );
                } else if (row.discountType == 2 && !isEmpty(row.discount)) {
                  let data = (row.hessianRateIncludingBrokerage * row.discount) / 100;
                  row.discountAmount = limitDecimals(
                    Number(data) * Number(row.quantity),
                    2
                  );
                } else {
                  row.discountAmount = "0.00";
                }
                const taxPercent = Number(row.taxPercent) / 2;
                const taxPercentageVal = limitDecimals(Number(taxPercent),2);
                const taxAmount = (Number(taxPercentageVal) * Number(row.netAmount)) / 100;
                const GST_taxAmount = Number(taxAmount);
                if (responseData.taxType === 0 || isEmptyWithZero(responseData.taxType)) {
                  row.igstPercent = 0;
                  row.cgstPercent = 0;
                  row.sgstPercent = 0;
                  row.igstAmount = 0;
                  row.cgstAmount = 0;
                  row.sgstAmount = 0;
                  row.totalAmount = limitDecimals(totAMt);
       
                  let totalAmtt = DoHessianLineItemsNew.map((p) => p.netAmount).reduce(
                    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                  );
                  subTotal =limitDecimals (totalAmtt)
                  netTotal =limitDecimals( totalAmtt)
                  TotalAmount = Number(totalAmtt);              
                } else if (responseData.taxType === 1) {
                  const taxAmount =
                  (Number(row.taxPercent) * Number(row.netAmount)) / 100;
                  row.igstPercent = row.taxPercent;
                  row.cgstPercent = 0;
                  row.sgstPercent = 0;
                  row.igstAmount =limitDecimals(taxAmount)
                  row.cgstAmount = 0;
                  row.sgstAmount = 0;
                  row.totalAmount = limitDecimals(Number(totAMt)+ Number(taxAmount));
       
                  let totalAmtt = DoHessianLineItemsNew.map((p) => p.netAmount).reduce(
                    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                  );
                  totalIGST = DoHessianLineItemsNew.map((p) => p.igstAmount).reduce(
                    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                  );
                  totalCGST = "0.00";
                  totalSGST = "0.00";
                  subTotal = limitDecimals(totalAmtt,2);
                  taxTotal = limitDecimals(totalIGST,2);
                  netTotal = limitDecimals(totalAmtt,2);
                  TotalAmount =limitDecimals(Number(totalAmtt) + Number(totalIGST),2);
                  var RoundOffVal = roundAndRemainingValues(DoHessianLineItemsNew);
                  roundingPositiveValue = RoundOffVal.positiveRF;
                  roundingNegetiveValue = RoundOffVal.NegativeRF;
                } else {
                  row.igstPercent = "0.00";
                  row.cgstPercent = taxPercent;
                  row.sgstPercent = taxPercent;
                  row.igstAmount = "0.00";
                  row.cgstAmount =limitDecimals(GST_taxAmount,2);
                  row.sgstAmount =limitDecimals(GST_taxAmount,2);
                  totalIGST = "0.00";
                  row.totalAmount = limitDecimals(
                    Number(totAMt) +Number(GST_taxAmount) +Number(GST_taxAmount)
                  ,2);
       
                  totalCGST = DoHessianLineItemsNew.map((p) => p.cgstAmount).reduce(
                    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                  );
                  totalSGST = DoHessianLineItemsNew.map((p) => p.sgstAmount).reduce(
                    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                  );
                  let totalAmt = DoHessianLineItemsNew.map((p) => p.netAmount).reduce(
                    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                  );
                  subTotal =limitDecimals(totalAmt,2);
                  taxTotal =limitDecimals(Number(totalCGST) + Number(totalSGST),2);
                  netTotal = totalAmt;
                  TotalAmount = limitDecimals(Number(totalAmt) + Number(taxTotal),2);
                  var RoundOffVal = roundAndRemainingValues(DoHessianLineItemsNew);
                  roundingPositiveValue = RoundOffVal.positiveRF;
                  roundingNegetiveValue = RoundOffVal.NegativeRF;
                }
              });
              return {
                lineItems: responseData.lineItems,
                totalIGST:limitDecimals(totalIGST,2),
                totalCGST:limitDecimals(totalCGST,2),
                totalSGST: limitDecimals(totalSGST,2),
                subTotal:limitDecimals(subTotal,2),
                taxTotal:limitDecimals(taxTotal,2),
                netTotal: limitDecimals(netTotal,2),
                TotalAmount:limitDecimals(TotalAmount,2),
                roundingPositiveValue: roundingPositiveValue,
                roundingNegetiveValue: roundingNegetiveValue,
              };
            } else {
              return {
                lineItems: responseData.lineItems,
                totalIGST: totalIGST,
                totalCGST: totalCGST,
                totalSGST: totalSGST,
                subTotal: subTotal,
                taxTotal: taxTotal,
                netTotal: netTotal,
                TotalAmount: TotalAmount,
              };
            }
          case "INVOICE_HESSIAN_UOM":
          const InvoiceHessianLineItems = responseData.lineItems.filter(
            (item) =>
              item.isActive !== 0 &&
              item.itemGroupId !== null &&
              item.itemGroupId !== ""
          );
          if (InvoiceHessianLineItems.length !== 0) {
            InvoiceHessianLineItems.map((row) => {
              if (row.hessianQuantityCalculatedValue !== "") {
                var totAMt = Number(row.hessianQuantityCalculatedValue) *Number(row.hessianRateCalculatedValue)
                row.netAmount = limitDecimals(totAMt);
              }
              if (row.discountType == 1 && !isEmpty(row.discount)) {
                row.discountAmount = limitDecimals(
                  Number(row.discount) * Number(row.hessianQuantityCalculatedValue),
                  2
                );
              } else if (row.discountType == 2 && !isEmpty(row.discount)) {
                let data = (row.hessianRateCalculatedValue * row.discount) / 100;
                row.discountAmount = limitDecimals(
                  Number(data) * Number(row.hessianQuantityCalculatedValue),
                  2
                );
              } else {
                row.discountAmount = "0.00";
              }
              var taxPercent = Number(row.taxPercent) / 2;
              var taxAmount =
                (Number(row.taxPercent) * Number(row.netAmount)) / 100;
              var GST_taxAmount = Number(taxAmount) / 2;
              if (responseData.taxType === 0) {
                row.igstPercent = 0;
                row.cgstPercent = 0;
                row.sgstPercent = 0;
                row.igstAmount = 0;
                row.cgstAmount = 0;
                row.sgstAmount = 0;
                row.totalAmount = limitDecimals(totAMt);
     
                let totalAmtt = InvoiceHessianLineItems.map((p) => p.netAmount).reduce(
                  (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                );
                subTotal =limitDecimals(totalAmtt);
                netTotal = limitDecimals(totalAmtt);
                TotalAmount = Number(totalAmtt);
              } else if (responseData.taxType === 1) {
                row.igstPercent = row.taxPercent;
                row.cgstPercent = 0;
                row.sgstPercent = 0;
                row.igstAmount =limitDecimals(taxAmount,2);
                row.cgstAmount = 0;
                row.sgstAmount = 0;
                row.totalAmount = limitDecimals(Number(totAMt) +Number(taxAmount));
     
                let totalAmtt = InvoiceHessianLineItems.map((p) => p.netAmount).reduce(
                  (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                );
                totalIGST = InvoiceHessianLineItems.map((p) => p.igstAmount).reduce(
                  (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                );
                totalCGST = "0.00";
                totalSGST = "0.00";
                subTotal = limitDecimals(totalAmtt,2);
                taxTotal = limitDecimals(totalIGST,2);
                netTotal = limitDecimals(totalAmtt,2);
                TotalAmount = limitDecimals(Number(totalAmtt) +Number(totalIGST),2);
                var RoundOffVal = roundAndRemainingValues(InvoiceHessianLineItems);
                roundingPositiveValue = RoundOffVal.positiveRF;
                roundingNegetiveValue = RoundOffVal.NegativeRF;
              } else {
                row.igstPercent = "0.00";
                row.cgstPercent = taxPercent;
                row.sgstPercent = taxPercent;
                row.igstAmount = "0.00";
                row.cgstAmount =limitDecimals(GST_taxAmount,2);
                row.sgstAmount = limitDecimals(GST_taxAmount,2);
                totalIGST = "0.00";
                row.totalAmount = limitDecimals(
                  Number(totAMt) +Number(GST_taxAmount )+Number(GST_taxAmount)
                );
     
                totalCGST = InvoiceHessianLineItems.map((p) => p.cgstAmount).reduce(
                  (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                );
                totalSGST = InvoiceHessianLineItems.map((p) => p.sgstAmount).reduce(
                  (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                );
                let totalAmt = InvoiceHessianLineItems.map((p) => p.netAmount).reduce(
                  (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                );
                subTotal = limitDecimals(totalAmt,2);
                taxTotal = limitDecimals(Number(totalCGST) +Number(totalSGST),2);
                netTotal = limitDecimals(totalAmt,2);
                TotalAmount = limitDecimals(Number(totalAmt) +Number(taxTotal),2);
                var RoundOffVal = roundAndRemainingValues(InvoiceHessianLineItems);
                roundingPositiveValue = RoundOffVal.positiveRF;
                roundingNegetiveValue = RoundOffVal.NegativeRF;
              }
            });
            return {
              lineItems: responseData.lineItems,
              totalIGST: totalIGST,
              totalCGST: totalCGST,
              totalSGST: totalSGST,
              subTotal: subTotal,
              taxTotal: taxTotal,
              netTotal: netTotal,
              TotalAmount: TotalAmount,
              roundingPositiveValue: roundingPositiveValue,
              roundingNegetiveValue: roundingNegetiveValue,
            };
          } else {
            return {
              lineItems: responseData.lineItems,
              totalIGST: totalIGST,
              totalCGST: totalCGST,
              totalSGST: totalSGST,
              subTotal: subTotal,
              taxTotal: taxTotal,
              netTotal: netTotal,
              TotalAmount: TotalAmount,
            };
          }
          case "INVOICE_HESSIAN_UOM_NEW":
            const InvoiceHessianLineItemsNew = responseData.lineItems.filter(
              (item) =>
                item.isActive !== 0 &&
                item.itemGroupId !== null &&
                item.itemGroupId !== ""
            );
            if (InvoiceHessianLineItemsNew.length !== 0) {
              InvoiceHessianLineItemsNew.map((row) => {
                  var totAMt = Number(row.quantity) *Number(row.rate)
                  row.netAmount = limitDecimals(totAMt);
                if (row.discountType == 1 && !isEmpty(row.discount)) {
                  row.discountAmount = limitDecimals(
                    Number(row.discount) * Number(row.quantity),
                    2
                  );
                } else if (row.discountType == 2 && !isEmpty(row.discount)) {
                  let data = (row.rate * row.discount) / 100;
                  row.discountAmount = limitDecimals(
                    Number(data) * Number(row.quantity),
                    2
                  );
                } else {
                  row.discountAmount = "0.00";
                }
                const taxPercent = Number(row.taxPercent) / 2;
                const taxPercentageVal = limitDecimals(Number(taxPercent),2);
                const taxAmount = (Number(taxPercentageVal) * Number(row.netAmount)) / 100;
                const GST_taxAmount = Number(taxAmount);
                if (responseData.taxType === 0) {
                  row.igstPercent = 0;
                  row.cgstPercent = 0;
                  row.sgstPercent = 0;
                  row.igstAmount = 0;
                  row.cgstAmount = 0;
                  row.sgstAmount = 0;
                  row.totalAmount = limitDecimals(totAMt);
       
                  let totalAmtt = InvoiceHessianLineItemsNew.map((p) => p.netAmount).reduce(
                    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                  );
                  subTotal =limitDecimals(totalAmtt);
                  netTotal = limitDecimals(totalAmtt);
                  TotalAmount = Number(totalAmtt);
                } else if (responseData.taxType === 1) {
                  const taxAmount =
                  (Number(row.taxPercent) * Number(row.netAmount)) / 100;
                  row.igstPercent = row.taxPercent;
                  row.cgstPercent = 0;
                  row.sgstPercent = 0;
                  row.igstAmount =limitDecimals(taxAmount,2);
                  row.cgstAmount = 0;
                  row.sgstAmount = 0;
                  row.totalAmount = limitDecimals(Number(totAMt) +Number(taxAmount));
       
                  let totalAmtt = InvoiceHessianLineItemsNew.map((p) => p.netAmount).reduce(
                    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                  );
                  totalIGST = InvoiceHessianLineItemsNew.map((p) => p.igstAmount).reduce(
                    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                  );
                  totalCGST = "0.00";
                  totalSGST = "0.00";
                  subTotal = limitDecimals(totalAmtt,2);
                  taxTotal = limitDecimals(totalIGST,2);
                  netTotal = limitDecimals(totalAmtt,2);
                  TotalAmount = limitDecimals(Number(totalAmtt) +Number(totalIGST),2);
                  var RoundOffVal = roundAndRemainingValues(InvoiceHessianLineItemsNew);
                  roundingPositiveValue = RoundOffVal.positiveRF;
                  roundingNegetiveValue = RoundOffVal.NegativeRF;
                } else {
                  row.igstPercent = "0.00";
                  row.cgstPercent = taxPercent;
                  row.sgstPercent = taxPercent;
                  row.igstAmount = "0.00";
                  row.cgstAmount =limitDecimals(GST_taxAmount,2);
                  row.sgstAmount = limitDecimals(GST_taxAmount,2);
                  totalIGST = "0.00";
                  row.totalAmount = limitDecimals(
                    Number(totAMt) +Number(GST_taxAmount )+Number(GST_taxAmount)
                  );
       
                  totalCGST = InvoiceHessianLineItemsNew.map((p) => p.cgstAmount).reduce(
                    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                  );
                  totalSGST = InvoiceHessianLineItemsNew.map((p) => p.sgstAmount).reduce(
                    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                  );
                  let totalAmt = InvoiceHessianLineItemsNew.map((p) => p.netAmount).reduce(
                    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                  );
                  subTotal = limitDecimals(totalAmt,2);
                  taxTotal = limitDecimals(Number(totalCGST) +Number(totalSGST),2);
                  netTotal = limitDecimals(totalAmt,2);
                  TotalAmount = limitDecimals(Number(totalAmt) +Number(taxTotal),2);
                  var RoundOffVal = roundAndRemainingValues(InvoiceHessianLineItemsNew);
                  roundingPositiveValue = RoundOffVal.positiveRF;
                  roundingNegetiveValue = RoundOffVal.NegativeRF;
                }
              });
              return {
                lineItems: responseData.lineItems,
                totalIGST: totalIGST,
                totalCGST: totalCGST,
                totalSGST: totalSGST,
                subTotal: subTotal,
                taxTotal: taxTotal,
                netTotal: netTotal,
                TotalAmount: TotalAmount,
                roundingPositiveValue: roundingPositiveValue,
                roundingNegetiveValue: roundingNegetiveValue,
              };
            } else {
              return {
                lineItems: responseData.lineItems,
                totalIGST: totalIGST,
                totalCGST: totalCGST,
                totalSGST: totalSGST,
                subTotal: subTotal,
                taxTotal: taxTotal,
                netTotal: netTotal,
                TotalAmount: TotalAmount,
              };
            }
    case "DO_HESSIAN":
      const LineItemsObjData = responseData.lineItems.filter(
        (item) =>
          item.isActive !== 0 &&
          item.itemGroupId !== null &&
          item.itemGroupId !== ""
      );
      if (LineItemsObjData.length !== 0) {
        LineItemsObjData.map((row) => {
          if (row.quantity !== "") {
            var totAMt =
              Number(row.quantity) *
              Number(
                row.hessianRateIncludingBrokerage
                  ? row.hessianRateIncludingBrokerage
                  : row.discountedRate
              );
            row.totalAmount = limitDecimals(totAMt);
            row.hessianAmountExcludingBrokerage =
              Number(row.quantity) * row.discountedRate;
          }
          var taxPercent = Number(row.taxPercent) / 2;
          var taxAmount =
            (Number(row.taxPercent) * Number(row.totalAmount)) / 100;
          var GST_taxAmount = Number(taxAmount) / 2;
          if (responseData.taxType === 0) {
            row.igstPercent = 0;
            row.cgstPercent = 0;
            row.sgstPercent = 0;
            row.igstAmount = 0;
            row.cgstAmount = 0;
            row.sgstAmount = 0;
            let totalAmtt = LineItemsObjData.map((p) => p.totalAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            subTotal =limitDecimals(totalAmtt)
            netTotal = limitDecimals(totalAmtt)
            TotalAmount = limitDecimals(Number(totalAmtt))
          } else if (responseData.taxType === 1) {
            row.igstPercent = row.taxPercent;
            row.cgstPercent = 0;
            row.sgstPercent = 0;
            row.igstAmount =limitDecimals(taxAmount)
            row.cgstAmount = 0;
            row.sgstAmount = 0;
            let totalAmtt = LineItemsObjData.map((p) => p.totalAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            totalIGST = LineItemsObjData.map((p) => p.igstAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            totalCGST = "0.00";
            totalSGST = "0.00";
            subTotal = limitDecimals(totalAmtt)
            taxTotal =limitDecimals(totalIGST)
            netTotal =limitDecimals(totalAmtt)
            TotalAmount =limitDecimals(Number(totalAmtt) + Number(totalIGST))
            var RoundOffVal = roundAndRemainingValues(LineItemsObjData);
            roundingPositiveValue = RoundOffVal.positiveRF;
            roundingNegetiveValue = RoundOffVal.NegativeRF;
          } else {
            row.igstPercent = "0.00";
            row.cgstPercent =limitDecimals(taxPercent)
            row.sgstPercent =limitDecimals(taxPercent)
            row.igstAmount = "0.00";
            row.cgstAmount = limitDecimals(GST_taxAmount)
            row.sgstAmount = limitDecimals(GST_taxAmount)
            totalIGST = "0.00";
            totalCGST = LineItemsObjData.map((p) => p.cgstAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            totalSGST = LineItemsObjData.map((p) => p.sgstAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            let totalAmt = LineItemsObjData.map((p) => p.totalAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            subTotal = limitDecimals(totalAmt)
            taxTotal = limitDecimals(Number(totalCGST) + Number(totalSGST))
            netTotal = limitDecimals(totalAmt)
            TotalAmount = limitDecimals(Number(totalAmt) + Number(taxTotal))
            var RoundOffVal = roundAndRemainingValues(LineItemsObjData);
            roundingPositiveValue = RoundOffVal.positiveRF;
            roundingNegetiveValue = RoundOffVal.NegativeRF;
          }
        });
        return {
          lineItems: responseData.lineItems,
          totalIGST: totalIGST,
          totalCGST: totalCGST,
          totalSGST: totalSGST,
          subTotal: subTotal,
          taxTotal: taxTotal,
          netTotal: netTotal,
          TotalAmount: TotalAmount,
          roundingPositiveValue: roundingPositiveValue,
          roundingNegetiveValue: roundingNegetiveValue,
        };
      } else {
        return {
          lineItems: responseData.lineItems,
          totalIGST: totalIGST,
          totalCGST: totalCGST,
          totalSGST: totalSGST,
          subTotal: subTotal,
          taxTotal: taxTotal,
          netTotal: netTotal,
          TotalAmount: TotalAmount,
        };
      }
    case "JUTE_HESSIAN_INVOICE":
      const lineItemsobjdata = responseData.lineItems.filter(
        (item) =>
          item.isActive !== 0 &&
          item.itemGroupId !== null &&
          item.itemGroupId !== ""
      );
      if (lineItemsobjdata.length !== 0) {
        lineItemsobjdata.map((row) => {
          if (row.quantity !== "") {
            var totAMt = Number(row.quantity) * Number(row.rate);
            row.netAmount = limitDecimals(totAMt);
          }
          var taxPercent = Number(row.taxPercent) / 2;
          var taxAmount =
            (Number(row.taxPercent) * Number(row.netAmount)) / 100;
          var GST_taxAmount = Number(taxAmount) / 2;
          if (responseData.taxType === 0) {
            row.igstPercent = 0;
            row.cgstPercent = 0;
            row.sgstPercent = 0;
            row.igstAmount = 0;
            row.cgstAmount = 0;
            row.sgstAmount = 0;
            row.totalAmount = limitDecimals(totAMt);
            let totalAmtt = lineItemsobjdata
              .map((p) => p.netAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );

            subTotal =limitDecimals(totalAmtt);
            netTotal = limitDecimals(totalAmtt);
            TotalAmount =limitDecimals(Number(totalAmtt) + Number(taxAmount))
          } else if (responseData.taxType === 1) {
            row.igstPercent = row.taxPercent;
            row.cgstPercent = 0;
            row.sgstPercent = 0;
            row.igstAmount = limitDecimals(taxAmount)
            row.cgstAmount = 0;
            row.sgstAmount = 0;
            row.totalAmount = limitDecimals(Number(totAMt) + Number(taxAmount));

            let totalAmtt = lineItemsobjdata
              .map((p) => p.netAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            totalIGST = lineItemsobjdata
              .map((p) => p.igstAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            totalCGST = "0.00";
            totalSGST = "0.00";
            subTotal = limitDecimals(totalAmtt);
            taxTotal =limitDecimals(totalIGST)
            netTotal = limitDecimals(totalAmtt)
            TotalAmount = limitDecimals(Number(totalAmtt) + Number(totalIGST)) 
            var RoundOffVal = roundAndRemainingValues(lineItemsobjdata);
            roundingPositiveValue = RoundOffVal.positiveRF;
            roundingNegetiveValue = RoundOffVal.NegativeRF;
          } else {
            row.igstPercent = "0.00";
            row.cgstPercent = taxPercent;
            row.sgstPercent = taxPercent;
            row.igstAmount = "0.00";
            row.cgstAmount =limitDecimals(GST_taxAmount)
            row.sgstAmount = limitDecimals(GST_taxAmount)
            totalIGST = "0.00";
            row.totalAmount = limitDecimals(
              Number(totAMt) +Number( GST_taxAmount) + Number(GST_taxAmount)
            );

            totalCGST = lineItemsobjdata
              .map((p) => p.cgstAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            totalSGST = lineItemsobjdata
              .map((p) => p.sgstAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            let totalAmt = lineItemsobjdata
              .map((p) => p.netAmount)
              .reduce(
                (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
              );
            subTotal =limitDecimals(totalAmt)
            taxTotal = limitDecimals(Number(totalCGST)+ Number(totalSGST))
            netTotal =limitDecimals(totalAmt)
            TotalAmount =
            limitDecimals( Number(totalAmt) + Number(totalCGST) + Number(totalSGST))
            var RoundOffVal = roundAndRemainingValues(lineItemsobjdata);
            roundingPositiveValue = RoundOffVal.positiveRF;
            roundingNegetiveValue = RoundOffVal.NegativeRF;
          }
        });
        return {
          lineItems: responseData.lineItems,
          totalIGST: totalIGST,
          totalCGST: totalCGST,
          totalSGST: totalSGST,
          subTotal: subTotal,
          taxTotal: taxTotal,
          netTotal: netTotal,
          TotalAmount: TotalAmount,
          roundingPositiveValue: roundingPositiveValue,
          roundingNegetiveValue: roundingNegetiveValue,
        };
      } else {
        return {
          lineItems: responseData.lineItems,
          totalIGST: totalIGST,
          totalCGST: totalCGST,
          totalSGST: totalSGST,
          subTotal: subTotal,
          taxTotal: taxTotal,
          netTotal: netTotal,
          TotalAmount: TotalAmount,
        };
      }
      
    case "SO_JUTE_YARN":
      const LineItems_so_jute_yarn = responseData.lineItems.filter(
        (item) =>
          item.isActive !== 0 &&
          item.itemGroupId !== null &&
          item.itemGroupId !== ""
      );
      if (LineItems_so_jute_yarn.length !== 0) {
        LineItems_so_jute_yarn.map((row) => {
          if (row.quantity !== "") {
            if (isEmptyWithZero(responseData.brokerCommisionPercent)) {
              row.yarnRateExcludingBrokerage = row.discountedRate;
              row.yarnRateIncludingBrokerage = row.discountedRate;
            } else {
              row.yarnRateExcludingBrokerage = row.discountedRate;
              var RateAfterBroker = limitDecimals(
                (Number(row.discountedRate) *
                  Number(responseData.brokerCommisionPercent)) /
                  100,
                3
              );
              row.yarnRateIncludingBrokerage = limitDecimals(
                Number(row.discountedRate) - Number(RateAfterBroker),
                3
              );
            }
            var totAMt =
              Number(row.quantity) * Number(row.yarnRateIncludingBrokerage);
            row.netAmount = limitDecimals(totAMt);
          }
          if (row.discountType == 1 && !isEmpty(row.discount)) {
            row.discountAmount = limitDecimals(
              Number(row.discount) * Number(row.quantity),
              2
            );
          } else if (row.discountType == 2 && !isEmpty(row.discount)) {
            let data = (row.rate * row.discount) / 100;
            row.discountAmount = limitDecimals(
              Number(data) * Number(row.quantity),
              2
            );
          } else {
            row.discountAmount = "0.00";
          }
          var taxPercent = Number(row.taxPercent) / 2;
          var taxAmount =
            (Number(row.taxPercent) * Number(row.netAmount)) / 100;
          var GST_taxAmount = Number(taxAmount) / 2;
          if (responseData.taxType === 0) {
            row.igstPercent = 0;
            row.cgstPercent = 0;
            row.sgstPercent = 0;
            row.igstAmount = 0;
            row.cgstAmount = 0;
            row.sgstAmount = 0;
            row.totalAmount = limitDecimals(totAMt);

            let totalAmtt = LineItems_so_jute_yarn.map(
              (p) => p.netAmount
            ).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            subTotal = limitDecimals(totalAmtt)
            netTotal =limitDecimals(totalAmtt)
            TotalAmount = Number(totalAmtt);
          } else if (responseData.taxType === 1) {
            row.igstPercent = row.taxPercent;
            row.cgstPercent = 0;
            row.sgstPercent = 0;
            row.igstAmount =limitDecimals(taxAmount)
            row.cgstAmount = 0;
            row.sgstAmount = 0;
            row.totalAmount = limitDecimals(Number(totAMt )+ Number(taxAmount))

            let totalAmtt = LineItems_so_jute_yarn.map(
              (p) => p.netAmount
            ).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            totalIGST = LineItems_so_jute_yarn.map((p) => p.igstAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            totalCGST = "0.00";
            totalSGST = "0.00";
            subTotal =limitDecimals (totalAmtt)
            taxTotal =limitDecimals(totalIGST)
            netTotal = limitDecimals(totalAmtt)
            TotalAmount =limitDecimals(Number(totalAmtt) + Number(totalIGST))
            var RoundOffVal = roundAndRemainingValues(LineItems_so_jute_yarn);
            roundingPositiveValue = RoundOffVal.positiveRF;
            roundingNegetiveValue = RoundOffVal.NegativeRF;
          } else {
            row.igstPercent = "0.00";
            row.cgstPercent = taxPercent;
            row.sgstPercent = taxPercent;
            row.igstAmount = "0.00";
            row.cgstAmount =limitDecimals(GST_taxAmount)
            row.sgstAmount =limitDecimals(GST_taxAmount)
            totalIGST = "0.00";
            row.totalAmount = limitDecimals(
              Number(totAMt) +Number(GST_taxAmount )+ Number(GST_taxAmount)
            );

            totalCGST = LineItems_so_jute_yarn.map((p) => p.cgstAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            totalSGST = LineItems_so_jute_yarn.map((p) => p.sgstAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            let totalAmt = LineItems_so_jute_yarn.map(
              (p) => p.netAmount
            ).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            subTotal = limitDecimals(totalAmt)
            taxTotal =limitDecimals(Number(totalCGST) + Number(totalSGST));
            netTotal = limitDecimals(totalAmt);
            TotalAmount =limitDecimals(Number(totalAmt) + Number(taxTotal))
            var RoundOffVal = roundAndRemainingValues(LineItems_so_jute_yarn);
            roundingPositiveValue = RoundOffVal.positiveRF;
            roundingNegetiveValue = RoundOffVal.NegativeRF;
          }
        });
        return {
          lineItems: responseData.lineItems,
          totalIGST: totalIGST,
          totalCGST: totalCGST,
          totalSGST: totalSGST,
          subTotal: subTotal,
          taxTotal: taxTotal,
          netTotal: netTotal,
          TotalAmount: TotalAmount,
          roundingPositiveValue: roundingPositiveValue,
          roundingNegetiveValue: roundingNegetiveValue,
        };
      } else {
        return {
          lineItems: responseData.lineItems,
          totalIGST: totalIGST,
          totalCGST: totalCGST,
          totalSGST: totalSGST,
          subTotal: subTotal,
          taxTotal: taxTotal,
          netTotal: netTotal,
          TotalAmount: TotalAmount,
        };
      }
    case "SO_JUTE_YARN_UOM":
      const LineItemsObj = responseData.lineItems.filter(
        (item) =>
          item.isActive !== 0 &&
          item.itemGroupId !== null &&
          item.itemGroupId !== ""
      );
      if (LineItemsObj.length !== 0) {
        let totAMt = 0;
        LineItemsObj.map((row) => {
          if (row.yarnQuantityCalculatedValue !== "") {
            if (isEmptyWithZero(responseData.brokerCommisionPercent)) {
              row.yarnRateExcludingBrokerage = row.discountedRate;
              row.yarnRateIncludingBrokerage = row.discountedRate;
            } else {
              row.yarnRateExcludingBrokerage = row.discountedRate;
              var RateAfterBroker = limitDecimals(
                (Number(row.discountedRate) *
                  Number(responseData.brokerCommisionPercent)) /
                  100,
                3
              );
              row.yarnRateIncludingBrokerage = limitDecimals(
                Number(row.discountedRate) - Number(RateAfterBroker),
                3
              );
            }
            if (row.yarnRateUomRelationValue !== 0) {
              totAMt =
                Number(row.yarnQuantityCalculatedValue) *
                Number(row.yarnRateIncludingBrokerage);
            } else {
              totAMt =
                Number(row.yarnQuantityCalculatedValue) *
                Number(row.yarnRateCalculatedValue);
            }
            row.netAmount = limitDecimals(totAMt);

            if (row.discountType == 1 && !isEmpty(row.discount)) {
              row.discountAmount = limitDecimals(
                Number(row.discount) * Number(row.yarnQuantityCalculatedValue),
                2
              );
            } else if (row.discountType == 2 && !isEmpty(row.discount)) {
              let data = (row.yarnRateCalculatedValue * row.discount) / 100;
              row.discountAmount = limitDecimals(
                Number(data) * Number(row.yarnQuantityCalculatedValue),
                2
              );
            } else {
              row.discountAmount = "0.00";
            }
          }
          const taxPercent = Number(row.taxPercent) / 2;
          const taxPercentageVal = limitDecimals(Number(taxPercent),2);
          const taxAmount = (Number(taxPercentageVal) * Number(row.netAmount)) / 100;
          const GST_taxAmount = Number(taxAmount);

          if (responseData.taxType === 0) {
            row.igstPercent = 0;
            row.cgstPercent = 0;
            row.sgstPercent = 0;
            row.igstAmount = 0;
            row.cgstAmount = 0;
            row.sgstAmount = 0;
            row.totalAmount = limitDecimals(totAMt);

            let totalAmtt = LineItemsObj.map((p) => p.netAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            subTotal = totalAmtt;
            netTotal = totalAmtt;
            TotalAmount = Number(totalAmtt);
          } else if (responseData.taxType === 1) {
            const taxAmount =
            (Number(row.taxPercent) * Number(row.netAmount)) / 100;

            row.igstPercent = row.taxPercent;
            row.cgstPercent = 0;
            row.sgstPercent = 0;
            row.igstAmount =limitDecimals(taxAmount,2);
            row.cgstAmount = 0;
            row.sgstAmount = 0;
            row.totalAmount = limitDecimals(Number(totAMt)+Number(taxAmount));

            let totalAmtt = LineItemsObj.map((p) => p.netAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            totalIGST = LineItemsObj.map((p) => p.igstAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            totalCGST = "0.00";
            totalSGST = "0.00";
            subTotal =limitDecimals(totalAmtt,2);
            taxTotal =limitDecimals(totalIGST,2);
            netTotal = limitDecimals(totalAmtt,2);
            TotalAmount =limitDecimals(Number(totalAmtt) +Number(totalIGST),2);
            var RoundOffVal = roundAndRemainingValues(LineItemsObj);
            roundingPositiveValue = RoundOffVal.positiveRF;
            roundingNegetiveValue = RoundOffVal.NegativeRF;
          } else {
            row.igstPercent = "0.00";
            row.cgstPercent = taxPercent;
            row.sgstPercent = taxPercent;
            row.igstAmount = "0.00";
            row.cgstAmount =limitDecimals(GST_taxAmount,2);
            row.sgstAmount =limitDecimals(GST_taxAmount,2);
            totalIGST = "0.00";
            row.totalAmount = limitDecimals(
              Number(totAMt) + Number(GST_taxAmount) + Number(GST_taxAmount)
            ,2);

            totalCGST = LineItemsObj.map((p) => p.cgstAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            totalSGST = LineItemsObj.map((p) => p.sgstAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            let totalAmt = LineItemsObj.map((p) => p.netAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            subTotal =limitDecimals(totalAmt,2);
            taxTotal = limitDecimals(Number(totalCGST)+Number(totalSGST),2);
            netTotal =limitDecimals(totalAmt,2);
            TotalAmount = limitDecimals(Number(totalAmt) + Number(taxTotal),2);
            var RoundOffVal = roundAndRemainingValues(LineItemsObj);
            roundingPositiveValue = RoundOffVal.positiveRF;
            roundingNegetiveValue = RoundOffVal.NegativeRF;
          }
        });
        return {
          lineItems: responseData.lineItems,
          totalIGST: totalIGST,
          totalCGST: totalCGST,
          totalSGST: totalSGST,
          subTotal: subTotal,
          taxTotal: taxTotal,
          netTotal: netTotal,
          TotalAmount: TotalAmount,
          roundingPositiveValue: roundingPositiveValue,
          roundingNegetiveValue: roundingNegetiveValue,
        };
      } else {
        return {
          lineItems: responseData.lineItems,
          totalIGST: totalIGST,
          totalCGST: totalCGST,
          totalSGST: totalSGST,
          subTotal: subTotal,
          taxTotal: taxTotal,
          netTotal: netTotal,
          TotalAmount: TotalAmount,
        };
      }
    case "DO_JUTE_YARN":
      const LineItemsObjdata = responseData.lineItems.filter(
        (item) =>
          item.isActive !== 0 &&
          item.itemGroupId !== null &&
          item.itemGroupId !== ""
      );
      if (LineItemsObjdata.length !== 0) {
        LineItemsObjdata.map((row) => {
          // if (row.quantity !== "") {
            if (isEmptyWithZero(responseData.brokerCommisionPercent)) {
              row.yarnRateExcludingBrokerage = row.discountedRate;
              row.yarnRateIncludingBrokerage = row.discountedRate;
            } else {
              row.yarnRateExcludingBrokerage = row.discountedRate;
              var RateAfterBroker = limitDecimals(
                (Number(row.discountedRate) *
                  Number(responseData.brokerCommisionPercent)) /
                  100,
                3
              );
              row.yarnRateIncludingBrokerage = limitDecimals(
                Number(row.discountedRate) - Number(RateAfterBroker),
                3
              );
            }
            var totAMt =
              Number(row.quantity) * Number(row.yarnRateIncludingBrokerage);
            row.netAmount = limitDecimals(totAMt);
          // }
          if (row.discountType == 1 && !isEmpty(row.discount)) {
            row.discountAmount = limitDecimals(
              Number(row.discount) * Number(row.quantity),
              2
            );
          } else if (row.discountType == 2 && !isEmpty(row.discount)) {
            let data = (row.rate * row.discount) / 100;
            row.discountAmount = limitDecimals(
              Number(data) * Number(row.quantity),
              2
            );
          } else {
            row.discountAmount = "0.00";
          }
          const taxPercent = Number(row.taxPercent) / 2;
          const taxPercentageVal = limitDecimals(Number(taxPercent),2);
          const taxAmount = (Number(taxPercentageVal) * Number(row.netAmount)) / 100;
          const GST_taxAmount = Number(taxAmount);
          if (responseData.taxType === 0) {
            row.igstPercent = 0;
            row.cgstPercent = 0;
            row.sgstPercent = 0;
            row.igstAmount = 0;
            row.cgstAmount = 0;
            row.sgstAmount = 0;
            row.totalAmount = limitDecimals(totAMt);

            let totalAmtt = LineItemsObjdata.map((p) => p.netAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            subTotal = totalAmtt;
            netTotal = totalAmtt;
            TotalAmount = Number(totalAmtt);
          } else if (responseData.taxType === 1) {          
           const taxAmount =
            (Number(row.taxPercent) * Number(row.netAmount)) / 100;
            row.igstPercent = row.taxPercent;
            row.cgstPercent = 0;
            row.sgstPercent = 0;
            row.igstAmount =limitDecimals(taxAmount,2);
            row.cgstAmount = 0;
            row.sgstAmount = 0;
            row.totalAmount = limitDecimals(Number(totAMt) +Number(taxAmount),2);

            let totalAmtt = LineItemsObjdata.map((p) => p.netAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            totalIGST = LineItemsObjdata.map((p) => p.igstAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            totalCGST = "0.00";
            totalSGST = "0.00";
            subTotal = limitDecimals(totalAmtt,2);
            taxTotal = limitDecimals(totalIGST,2);
            netTotal = limitDecimals(totalAmtt,2);
            TotalAmount = limitDecimals(Number(totalAmtt) + Number(totalIGST),2);
            var RoundOffVal = roundAndRemainingValues(LineItemsObjdata);
            roundingPositiveValue = RoundOffVal.positiveRF;
            roundingNegetiveValue = RoundOffVal.NegativeRF;
          } else {
            row.igstPercent = "0.00";
            row.cgstPercent = taxPercent;
            row.sgstPercent = taxPercent;
            row.igstAmount = "0.00";
            row.cgstAmount =limitDecimals(GST_taxAmount,2);
            row.sgstAmount =limitDecimals(GST_taxAmount,2)
            totalIGST = "0.00";
            row.totalAmount = limitDecimals(
             Number(totAMt )+ Number(GST_taxAmount) + Number(GST_taxAmount),2
            );

            totalCGST = LineItemsObjdata.map((p) => p.cgstAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            totalSGST = LineItemsObjdata.map((p) => p.sgstAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            let totalAmt = LineItemsObjdata.map((p) => p.netAmount).reduce(
              (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
            );
            subTotal =limitDecimals(totalAmt,2);
            taxTotal = limitDecimals(Number(totalCGST) + Number(totalSGST),2);
            netTotal =limitDecimals(totalAmt,2);
            TotalAmount =limitDecimals(Number(totalAmt) + Number(taxTotal),2);
            var RoundOffVal = roundAndRemainingValues(LineItemsObjdata);
            roundingPositiveValue = RoundOffVal.positiveRF;
            roundingNegetiveValue = RoundOffVal.NegativeRF;
          }
        });
        return {
          lineItems: responseData.lineItems,
          totalIGST: totalIGST,
          totalCGST: totalCGST,
          totalSGST: totalSGST,
          subTotal: subTotal,
          taxTotal: taxTotal,
          netTotal: netTotal,
          TotalAmount: TotalAmount,
          roundingPositiveValue: roundingPositiveValue,
          roundingNegetiveValue: roundingNegetiveValue,
        };
      } else {
        return {
          lineItems: responseData.lineItems,
          totalIGST: totalIGST,
          totalCGST: totalCGST,
          totalSGST: totalSGST,
          subTotal: subTotal,
          taxTotal: taxTotal,
          netTotal: netTotal,
          TotalAmount: TotalAmount,
        };
      }
      case "INVOICE_YARN_UOM":
          const InvoiceYarnLineItems = responseData.lineItems.filter(
            (item) =>
              item.isActive !== 0 &&
              item.itemGroupId !== null &&
              item.itemGroupId !== ""
          );
          if (InvoiceYarnLineItems.length !== 0) {
            InvoiceYarnLineItems.map((row) => {
              if (row.yarnQuantityCalculatedValue !== "") {
                var totAMt = limitDecimals(row.yarnQuantityCalculatedValue,3) *limitDecimals(row.yarnRateCalculatedValue,3)
                row.netAmount = limitDecimals(totAMt);
              }
              if (row.discountType == 1 && !isEmpty(row.discount)) {
                row.discountAmount = limitDecimals(
                  Number(row.discount) * Number(row.yarnQuantityCalculatedValue),
                  2
                );
              } else if (row.discountType == 2 && !isEmpty(row.discount)) {
                let data = (row.yarnRateCalculatedValue * row.discount) / 100;
                row.discountAmount = limitDecimals(
                  Number(data) * Number(row.yarnQuantityCalculatedValue),
                  2
                );
              } else {
                row.discountAmount = "0.00";
              }
              var taxPercent = Number(row.taxPercent) / 2;
              var taxAmount =
                (Number(row.taxPercent) * Number(row.netAmount)) / 100;
              var GST_taxAmount = Number(taxAmount) / 2;
              if (isEmptyWithZero(responseData.taxType)) {
                row.igstPercent = 0;
                row.cgstPercent = 0;
                row.sgstPercent = 0;
                row.igstAmount = 0;
                row.cgstAmount = 0;
                row.sgstAmount = 0;
                row.totalAmount = limitDecimals(totAMt);
     
                let totalAmtt = InvoiceYarnLineItems.map((p) => p.netAmount).reduce(
                  (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                );
                subTotal =limitDecimals(totalAmtt)
                netTotal =limitDecimals(totalAmtt)
                TotalAmount =limitDecimals(Number(totalAmtt))
              } else if (responseData.taxType === 1) {
                row.igstPercent = row.taxPercent;
                row.cgstPercent = 0;
                row.sgstPercent = 0;
                row.igstAmount = limitDecimals(taxAmount,2);
                row.cgstAmount = 0;
                row.sgstAmount = 0;
                row.totalAmount = limitDecimals(Number(totAMt)+Number(taxAmount));
     
                let totalAmtt = InvoiceYarnLineItems.map((p) => p.netAmount).reduce(
                  (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                );
                totalIGST = InvoiceYarnLineItems.map((p) => p.igstAmount).reduce(
                  (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                );
                totalCGST = "0.00";
                totalSGST = "0.00";
                subTotal = limitDecimals(totalAmtt,2);
                taxTotal =limitDecimals(totalIGST,2);
                netTotal =limitDecimals(totalAmtt,2);
                TotalAmount =limitDecimals(Number(totalAmtt) +Number(totalIGST),2);
                var RoundOffVal = roundAndRemainingValues(InvoiceYarnLineItems);
                roundingPositiveValue = RoundOffVal.positiveRF;
                roundingNegetiveValue = RoundOffVal.NegativeRF;
              } else {
                row.igstPercent = "0.00";
                row.cgstPercent = taxPercent;
                row.sgstPercent = taxPercent;
                row.igstAmount = "0.00";
                row.cgstAmount = limitDecimals(GST_taxAmount,2);
                row.sgstAmount = limitDecimals(GST_taxAmount,2);
                totalIGST = "0.00";
                row.totalAmount = limitDecimals(
                  Number(totAMt) +Number(GST_taxAmount )+Number(GST_taxAmount)
                ,2);
     
                totalCGST = InvoiceYarnLineItems.map((p) => p.cgstAmount).reduce(
                  (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                );
                totalSGST = InvoiceYarnLineItems.map((p) => p.sgstAmount).reduce(
                  (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                );
                let totalAmt = InvoiceYarnLineItems.map((p) => p.netAmount).reduce(
                  (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                );
                subTotal =limitDecimals(totalAmt,2);
                taxTotal =limitDecimals(Number(totalCGST) + Number(totalSGST),2);
                netTotal =limitDecimals(totalAmt,2);
                TotalAmount =limitDecimals(Number(totalAmt) + Number(taxTotal),2);
                var RoundOffVal = roundAndRemainingValues(InvoiceYarnLineItems);
                roundingPositiveValue = RoundOffVal.positiveRF;
                roundingNegetiveValue = RoundOffVal.NegativeRF;
              }
            });
            return {
              lineItems: responseData.lineItems,
              totalIGST: totalIGST,
              totalCGST: totalCGST,
              totalSGST: totalSGST,
              subTotal: subTotal,
              taxTotal: taxTotal,
              netTotal: netTotal,
              TotalAmount: TotalAmount,
              roundingPositiveValue: roundingPositiveValue,
              roundingNegetiveValue: roundingNegetiveValue,
            };
          } else {
            return {
              lineItems: responseData.lineItems,
              totalIGST: totalIGST,
              totalCGST: totalCGST,
              totalSGST: totalSGST,
              subTotal: subTotal,
              taxTotal: taxTotal,
              netTotal: netTotal,
              TotalAmount: TotalAmount,
            };
          }
          case "INVOICE_YARN_UOM_NEW":
            const InvoiceYarnLineItemsNew = responseData.lineItems.filter(
              (item) =>
                item.isActive !== 0 &&
                item.itemGroupId !== null &&
                item.itemGroupId !== ""
            );
            if (InvoiceYarnLineItemsNew.length !== 0) {
              InvoiceYarnLineItemsNew.map((row) => {
                  var totAMt = limitDecimals(row.quantity,3) *limitDecimals(row.rate,3)
                  row.netAmount = limitDecimals(totAMt);

                if (row.discountType == 1 && !isEmpty(row.discount)) {
                  row.discountAmount = limitDecimals(
                    Number(row.discount) * Number(row.quantity),
                    2
                  );
                } else if (row.discountType == 2 && !isEmpty(row.discount)) {
                  let data = (row.rate * row.discount) / 100;
                  row.discountAmount = limitDecimals(
                    Number(data) * Number(row.quantity),
                    2
                  );
                } else {
                  row.discountAmount = "0.00";
                }
                const taxPercent = Number(row.taxPercent) / 2;
                const taxPercentageVal = limitDecimals(Number(taxPercent),2);
                const taxAmount = (Number(taxPercentageVal) * Number(row.netAmount)) / 100;
                const GST_taxAmount = Number(taxAmount);
      
                if (isEmptyWithZero(responseData.taxType)) {
                  row.igstPercent = 0;
                  row.cgstPercent = 0;
                  row.sgstPercent = 0;
                  row.igstAmount = 0;
                  row.cgstAmount = 0;
                  row.sgstAmount = 0;
                  row.totalAmount = limitDecimals(totAMt);
       
                  let totalAmtt = InvoiceYarnLineItemsNew.map((p) => p.netAmount).reduce(
                    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                  );
                  subTotal =limitDecimals(totalAmtt)
                  netTotal =limitDecimals(totalAmtt)
                  TotalAmount =limitDecimals(Number(totalAmtt))
                } else if (responseData.taxType === 1) {
                  const taxAmount =
                  (Number(row.taxPercent) * Number(row.netAmount)) / 100;
                  row.igstPercent = row.taxPercent;
                  row.cgstPercent = 0;
                  row.sgstPercent = 0;
                  row.igstAmount = limitDecimals(taxAmount,2);
                  row.cgstAmount = 0;
                  row.sgstAmount = 0;
                  row.totalAmount = limitDecimals(Number(totAMt)+Number(taxAmount));
       
                  let totalAmtt = InvoiceYarnLineItemsNew.map((p) => p.netAmount).reduce(
                    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                  );
                  totalIGST = InvoiceYarnLineItemsNew.map((p) => p.igstAmount).reduce(
                    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                  );
                  totalCGST = "0.00";
                  totalSGST = "0.00";
                  subTotal = limitDecimals(totalAmtt,2);
                  taxTotal =limitDecimals(totalIGST,2);
                  netTotal =limitDecimals(totalAmtt,2);
                  TotalAmount =limitDecimals(Number(totalAmtt) +Number(totalIGST),2);
                  var RoundOffVal = roundAndRemainingValues(InvoiceYarnLineItemsNew);
                  roundingPositiveValue = RoundOffVal.positiveRF;
                  roundingNegetiveValue = RoundOffVal.NegativeRF;
                } else {
                  row.igstPercent = "0.00";
                  row.cgstPercent = taxPercent;
                  row.sgstPercent = taxPercent;
                  row.igstAmount = "0.00";
                  row.cgstAmount = limitDecimals(GST_taxAmount,2);
                  row.sgstAmount = limitDecimals(GST_taxAmount,2);
                  totalIGST = "0.00";
                  row.totalAmount = limitDecimals(
                    Number(totAMt) +Number(GST_taxAmount )+Number(GST_taxAmount)
                  ,2);
       
                  totalCGST = InvoiceYarnLineItemsNew.map((p) => p.cgstAmount).reduce(
                    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                  );
                  totalSGST = InvoiceYarnLineItemsNew.map((p) => p.sgstAmount).reduce(
                    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                  );
                  let totalAmt = InvoiceYarnLineItemsNew.map((p) => p.netAmount).reduce(
                    (sale_Amount, ind) => parseFloat(sale_Amount) + parseFloat(ind)
                  );
                  subTotal =limitDecimals(totalAmt,2);
                  taxTotal =limitDecimals(Number(totalCGST) + Number(totalSGST),2);
                  netTotal =limitDecimals(totalAmt,2);
                  TotalAmount =limitDecimals(Number(totalAmt) + Number(taxTotal),2);
                  var RoundOffVal = roundAndRemainingValues(InvoiceYarnLineItemsNew);
                  roundingPositiveValue = RoundOffVal.positiveRF;
                  roundingNegetiveValue = RoundOffVal.NegativeRF;
                }
              });
              return {
                lineItems: responseData.lineItems,
                totalIGST: totalIGST,
                totalCGST: totalCGST,
                totalSGST: totalSGST,
                subTotal: subTotal,
                taxTotal: taxTotal,
                netTotal: netTotal,
                TotalAmount: TotalAmount,
                roundingPositiveValue: roundingPositiveValue,
                roundingNegetiveValue: roundingNegetiveValue,
              };
            } else {
              return {
                lineItems: responseData.lineItems,
                totalIGST: totalIGST,
                totalCGST: totalCGST,
                totalSGST: totalSGST,
                subTotal: subTotal,
                taxTotal: taxTotal,
                netTotal: netTotal,
                TotalAmount: TotalAmount,
              };
            }
    default:
      return {
        lineItems: responseData.lineItems,
        totalIGST: "0.00",
        totalCGST: "0.00",
        totalSGST: "0.00",
        subTotal: "0.00",
        taxTotal: "0.00",
        netTotal: "0.00",
        TotalAmount: "0.00",
      };
  }
};
