import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import { getWareHouseList } from "../../../store/Master/WareHouseMaster/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      wareHouseListData: [], // TO Displaying wareHouse List data
      warehouseName: null,
      warehouseType: null,
      branchId: null,
      dataexp: "",
    };
  }
  componentDidMount() {
    const data = {
      companyId: localStorage.getItem("companyId"),
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
    };
    this.props.getWareHouseList(
      serverApi.GET_ALL_WARE_HOUSE_LIST,
      data,
      this.props.history
    ); // Calling ware House List API
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.WareHouse_List) {
      this.setState({
        totalRecs: props.WareHouse_List.totalRecords,
        wareHouseListData: props.WareHouse_List.data, // fetching ware house list data
      });
    }
  }
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const data = {
        companyId: localStorage.getItem("companyId"),
        startIndex: sizePerPage,
        lastIndex: currentIndex,
        warehouseName: this.state.warehouseName,
        warehouseType: this.state.warehouseType,
        branchId: this.state.branchId,
      };
      if (currentIndex >= 0) {
        this.props.getWareHouseList(
          serverApi.GET_ALL_WARE_HOUSE_LIST,
          data,
          this.props.history
        ); // calling pagination api for warehouse
      }
    } else {
      const data = {
        companyId: localStorage.getItem("companyId"),
        startIndex: currentIndex === 0 ? currentIndex + 1 : currentIndex,
        lastIndex: sizePerPage,
        warehouseName: this.state.warehouseName,
        warehouseType: this.state.warehouseType,
        branchId: this.state.branchId,
      };
      if (currentIndex >= 0) {
        this.props.getWareHouseList(
          serverApi.GET_ALL_WARE_HOUSE_LIST,
          data,
          this.props.history
        ); // calling Pagination api for warehouse
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  handleSearch = (data) => {
    const searchdata = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      warehouseName: data.warehouseName,
      warehouseType: data.warehouseType,
      branchId: data.branchId,
    };
    this.setState({
      warehouseName: data.warehouseName,
      warehouseType: data.warehouseType,
      branchId: data.branchId,
    });
    this.props.getWareHouseList(
      serverApi.GET_ALL_WARE_HOUSE_LIST,
      searchdata,
      this.props.history
    ); // Calling ware House List API
  };

  render() {
    return (
      <>
        <PaginationGridView
          mdata={this.state.wareHouseListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={false}
          filter_form={"warehouseMaster"}
          handleSearch={this.handleSearch}
          actions={true}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          url="/create_warehouse_master"
          apiexp={serverApi.GET_ALL_WARE_HOUSE_LIST}
          payloadexp={this.state.dataexp}
          expName={"Warehouse-Master.csv"}
        />
      </>
    );
  }
}

// warehouse list headers
const Columns = [
  {
    Header: "Warehouse Name",
    accessor: "name",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Branch",
    accessor: "branchName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Address",
    accessor: "address",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Date",
    accessor: "autoDateTime",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Company Id",
    accessor: "companyId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Branch Id",
    accessor: "branchId",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    Header: "Cipher",
    accessor: "cipher",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
];
//
const mapStatetoProps = (state) => {
  const { WareHouse_List } = state.WareHouseListReducer; // fetching list from wareList reducer
  return { WareHouse_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getWareHouseList,
  })(Index)
);
