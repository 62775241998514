import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextFieldNormal from "../../TextField/TextFieldNormal";
// import DynamicSelect from "../../Dropdown/DynamicSelect";
import DynamicSelect from "../../../components/Dropdown/Master/DynamicSelectMasters";
import { serverApi } from "../../../helpers/Consts";
import { getBranchList } from "../../../store/Global/DropDownApis/actions";

class WareHouseMasterFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      warehouseName:null,
      // warehouseType:null,
      branchId:null,
      branchListData:[]
    };
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };
 
  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "warehouseName") {
      this.setState({
        warehouseName: event,
      });
    }

  };
  handleSubmit = () => {
    const data = {
      warehouseName:this.state.warehouseName !==""?this.state.warehouseName:null,
      // warehouseType:this.state.warehouseType,
        branchId:this.state.branchId,
    };
    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    // if (name === "type") {
    //   if(selectedName =='Select...'){
    //     this.setState({
    //       warehouseType: null,
    //     });
    //   }else{
    //     this.setState({
    //       warehouseType: selectedName,
    //     });
    //   }
     
    // }
    if (name === "branchId") {
      if(selectedValue ==0){
        this.setState({
          branchId: null,
      });
      }else{
        this.setState({
          branchId: selectedValue,
      });
      }
       
      }
  };
  handleClear = () => {
    this.setState({
      warehouseName:"",
      branchId:"",
      // warehouseType:"",

     
    });
  };
  componentDidMount =() =>{
    this.props.getBranchList(serverApi.BRANCH_LIST + this.state.companyId, this.props.history); // calling branch list api
 
  }
  componentWillReceiveProps(props) {
    if (props.branchList) {
      this.setState({
        branchListData: props.branchList.data, // updating branch list data
      });
    }
  }
  render() {
    return (
      <>
        <Box>
          <Button onClick={this.handleClickOpen}>
            <Tooltip title="Filter">
              <FilterListOutlinedIcon />
            </Tooltip>
          </Button>
          <Dialog
            open={this.state.open}
            PaperProps={{
              sx: {
                position: " fixed",
                top: 0,
                right: 0,
                margin: 0,
                bottom: 0,
                maxHeight: "100vh",
              },
            }}
            keepMounted
            onClose={this.handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <Grid spacing={0}>
              <div className="consoleTextFieldBlock">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div className="filterBlockHeader">
                    <FilterListOutlinedIcon />
                    <span>Filter</span>
                  </div>
                </div>
              </div>
              <Grid item xs={12} className="filterBlockContainer">
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Ware House Name"
                      value={this.state.warehouseName}
                      onChange={this.onhandlechange("warehouseName")}
                    />
                  </div>
                </Box>
                {/* <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={warehouseType}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.warehouseType}
                      name="type"
                      label="Ware House Type"
                    />
                  </div>
                </Box> */}
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={
                        this.state.branchListData
                      }
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.branchId}
                      name="branchId"
                      label="Branch"
                    />
                  </div>
                </Box>
                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Search"}
                    className="greenButton"
                    handleClick={this.handleSubmit}
                    type="sumbit"
                  />
                </div>
              </Grid>
            </Grid>
          </Dialog>
        </Box>
      </>
    );
  }
}
const warehouseType = [
  {
    value: null,
    label: "Select...",
    name: "Select...",
  },
  {
    value: "S",
    label: "Store Warehouse",
    name: "StorewareHouse",
  },
  {
    value: "J",
    label: "Jute Warehouse",
    name: "JutewareHouse",
  },


];
const mapStatetoProps = (state) => {
  const { branchList } = state.DropDownListReducer; // fetching branch list from global api
  return{
    branchList 
 }
};

export default withRouter(connect(mapStatetoProps, {
  getBranchList
})(WareHouseMasterFilter));
