import {BILLS_LIST, VENDOR_LIST, COST_FACTOR_LIST, ADD_BILLS} from "./actionType";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {getList, getListurl} from '../../../helpers/Server_Helper';
import {billsListSuccessfull, vendor_list_Successfull, cost_list_Successfull} from './actions'
import swal from "sweetalert";


function* getBillsList({payload:{url,data,history}}) {
    try{
        const response = yield call(getList,url,data);
        yield put(billsListSuccessfull({response}))
    }catch(error){
        console.log(error)
    }
}
function* getVendorsList({payload:{url,history}}) {
    try{
        const response = yield call(getListurl,url);
        yield put(vendor_list_Successfull({response}))
    }catch(error){
        console.log(error)
    }
}
function* getCostFactorList({payload:{url,history}}) {
    try{
        const response = yield call(getListurl,url);
        yield put(cost_list_Successfull({response}))
    }catch(error){
        console.log(error)
    }
}
function* addBills({payload:{url,data,history}}) {
    try{
        const response = yield call(getList,url,data);
        yield put(billsListSuccessfull({response}))
        swal(response.message, { icon: "success" });
        history.push('/bills')

    }catch(error){
        console.log(error)
        swal('Something went wrong', { icon: "error" });
    }
}
export function* watchPaySlipList () {
    yield takeEvery(BILLS_LIST, getBillsList)
}
export function* watchVendorList () {
    yield takeEvery(VENDOR_LIST, getVendorsList)
}
export function* watchGetCostFactorList () {
    yield takeEvery(COST_FACTOR_LIST, getCostFactorList)
}
export function* watchAddBillList () {
    yield takeEvery(ADD_BILLS, addBills)
}

function* BillsSaga() {
    yield all([
        fork(watchPaySlipList),
        fork(watchVendorList),
        fork(watchGetCostFactorList),
        fork(watchAddBillList)

    ])
}

export default BillsSaga;