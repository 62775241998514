import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import moment from "moment";
import {
  createWeavingQualityMaster,
  updateWeavingQualityMaster,
} from "../../../store/Master/WeavingQualityMaster/actions";
import { validate } from "../../../Utilities/Validations";
import swal from "sweetalert";

class CreateWeavingQualityMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      createdBy: "",
      qualityId: "",
      qualityCode: "",
      qualityName: "",
      clothWidth: "",
      ports: "",
      shots: "",
      ends: "",
      speed: "",
      ouncePerYard: "",
      noOfTeeth: "",
      createdOn: "",
      qualityType: "",
      createdDate: "",
      jugarPerCut:""
    };
  }
  /* Calling Create and Update Api's to Actions */
  onClickCreateUpdate = () => {
    const {
      qualityCode,
      qualityName,
      ports,
      shots,
      ends,
      speed,
      ouncePerYard,
    } = this.state;

    const validateFields = [
      { field: qualityCode, msg: "Quality Code", type: 1 },
      { field: qualityName, msg: "Quality Name", type: 1 },
      { field: ports, msg: "Ports", type: 1 },
      { field: shots, msg: "Shots", type: 1 },
      { field: ends, msg: "End", type: 1 },
      { field: speed, msg: "Speed", type: 1 },
      { field: ouncePerYard, msg: "Ounc Per yard", type: 1 },
    ];
    let validObj = validate(validateFields);
    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message}`);
      }
      if (validObj.type === 2) {
        swal(`Please Select ${validObj.message}`);
      }
    } else {
      if (this.props.location.state === undefined) {
        const data = {
          companyId: this.state.companyId,
          createdBy: this.state.userDit.userId.toString(),
          ends: this.state.ends,
          mcTeeth: this.state.noOfTeeth,
          ozsYds: this.state.ouncePerYard,
          ports: this.state.ports,
          qualityCode: this.state.qualityCode,
          qualityName: this.state.qualityName,
          qualityType: 1,
          shots: this.state.shots,
          speed: this.state.speed,
          width: this.state.clothWidth,
          jugarPerCut:this.state.jugarPerCut
        };
        this.props.createWeavingQualityMaster(
          serverApi.CREATE_WEAVING_QUALITY_MASTER,
          data,
          this.props.history
        ); // calling create weaving quality master api
      } else {
        const data = {
          createdBy: this.state.userDit.userId.toString(),
          companyId: this.state.companyId,
          createdOn: this.props.location.state.rowData.createDateTime,
          ends: this.state.ends,
          mcTeeth: this.state.noOfTeeth,
          ozsYds: this.state.ouncePerYard,
          ports: this.state.ports,
          qualityCode: this.state.qualityCode,
          qualityId: this.state.qualityId,
          qualityName: this.state.qualityName,
          qualityType: 1,
          shots: this.state.shots,
          speed: this.state.speed,
          width: this.state.clothWidth,
          jugarPerCut:this.state.jugarPerCut
        };
        this.props.updateWeavingQualityMaster(
          serverApi.UPDATE_WEAVING_QUALITY_MASTER,
          data,
          this.props.history
        ); // calling update Weaving quality master api
      }
    }
  };
  // function for previous page
  onClickCancel = () => {
    this.setState({
      qualityId: "",
      qualityCode: "",
      qualityName: "",
      clothWidth: "",
      ports: "",
      shots: "",
      ends: "",
      speed: "",
      ouncePerYard: "",
      noOfTeeth: "",
    });
    this.props.history.push("/weaving_quality_master");
  };
  //function for TextFields
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "qualityId") {
      this.setState({
        qualityId: event,
      });
    }
    if (key === "qualityCode") {
      const qtycode = event.replace(/[^a-zA-Z0-9\s]+/g, "");
      this.setState({
        qualityCode: qtycode,
      });
    }
    if (key === "qualityName") {
      this.setState({
        qualityName: event,
      });
    }
    if (key === "clothWidth") {
      this.setState({
        clothWidth: event,
      });
    }
    if (key === "ports") {
      this.setState({
        ports: event,
      });
    }
    if (key === "shots") {
      this.setState({
        shots: event,
      });
    }
    if (key === "ends") {
      this.setState({
        ends: event,
      });
    }
    if (key === "speed") {
      this.setState({
        speed: event,
      });
    }
    if (key === "ouncePerYard") {
      this.setState({
        ouncePerYard: event,
      });
    }
    if (key === "noOfTeeth") {
      this.setState({
        noOfTeeth: event,
      });
    }
    if (key === "jugarPerCut") {
      this.setState({
        jugarPerCut: event,
      });
    }
  };
  // function for popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  componentDidMount() {
    if (this.props.location.state) {
      this.setState({
        createdBy: this.props.location.state.rowData.createdBy,
        createdOn: this.props.location.state.rowData.createDateTime,
        companyId: this.props.location.state.rowData.companyId,
        ends: this.props.location.state.rowData.ends,
        noOfTeeth: this.props.location.state.rowData.mcTeeth,
        ouncePerYard: this.props.location.state.rowData.ozsYds,
        ports: this.props.location.state.rowData.ports,
        qualityCode: this.props.location.state.rowData.qualityCode,
        qualityId: this.props.location.state.rowData.qualityId,
        qualityName: this.props.location.state.rowData.qualityName,
        qualityType: this.props.location.state.rowData.qualityType,
        shots: this.props.location.state.rowData.shots,
        speed: this.props.location.state.rowData.speed,
        clothWidth: this.props.location.state.rowData.width,
        jugarPerCut:this.props.location.state.rowData.jugarPerCut
      });
      const dateEntered = this.props.location.state.rowData.createDateTime;
      const dateIsValid = moment(dateEntered).format("LLLL");
      this.setState({
        createdDate: dateIsValid,
      });
    }
  }
  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {this.props.location.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 3">
                      <TextFieldReq
                        value={this.state.qualityId}
                        readOnly={this.props.location.state ? true : false}
                        caps="OFF"
                        onChange={this.onhandlechangeValue("qualityId")}
                        label="Quality Id"
                      />
                    </Box>
                  )}
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.qualityCode}
                      readOnly={this.props.location.state ? true : false}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("qualityCode")}
                      label="Quality Code"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.qualityName}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("qualityName")}
                      label="Quality Name"
                      disabled={this.state.qualityCode === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      type="number"
                      value={this.state.clothWidth}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("clothWidth")}
                      label="Cloth Width"
                      disabled={this.state.qualityCode === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      type="number"
                      value={this.state.ports}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("ports")}
                      label="Ports"
                      disabled={this.state.qualityName === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      type="number"
                      value={this.state.shots}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("shots")}
                      label="Shots"
                      disabled={this.state.ports === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      type="number"
                      value={this.state.ends}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("ends")}
                      label="Ends"
                      disabled={this.state.shots === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      type="number"
                      value={this.state.speed}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("speed")}
                      label="Speed"
                      disabled={this.state.ends === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      type="number"
                      value={this.state.ouncePerYard}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("ouncePerYard")}
                      label="Ounce Per Yard"
                      disabled={this.state.speed === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      type="number"
                      value={this.state.noOfTeeth}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("noOfTeeth")}
                      label="No Of Teeth"
                      disabled={this.state.speed === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      type="number"
                      value={this.state.jugarPerCut}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("jugarPerCut")}
                      label="jugar per cut"
                    />
                  </Box>
                  {this.props.location.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdDate}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.props.location.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
          {/* common popup for validation */}
          {this.state.confirm && (
            <CommonPopup>
              <div className="delAddItem">
                <div className="mandatoryFieldText">
                  Please select all the mandatory fields
                </div>
                <div className="delAddItemBtns">
                  <input
                    type="button"
                    onClick={this.onclickPopUP.bind(this)}
                    value="Ok"
                    className="delYesBtn"
                  />
                </div>
              </div>
            </CommonPopup>
          )}
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {};

export default withRouter(
  connect(mapStatetoProps, {
    createWeavingQualityMaster,
    updateWeavingQualityMaster,
  })(CreateWeavingQualityMaster)
);
