import {LEFT_SIDE_MENU,LEFT_SIDE_MENU_SUCCESSFULL, VENDOR_LEFT_SIDE_MENU, VENDOR_LEFT_SIDE_MENU_SUCCESSFULL} from "./actionType";

export const getLeftSideMenu = (url,history) =>{
    return {
        type:LEFT_SIDE_MENU,
        payload:{url,history}
    }
}

export const LeftSideMenuSuccessfull = (data)=>{
    return {
        type:LEFT_SIDE_MENU_SUCCESSFULL,
        payload:data
    }
}

export const getVendorLeftSideMenu = (url,history) =>{
    return {
        type:VENDOR_LEFT_SIDE_MENU,
        payload:{url,history}
    }
}

export const VendorLeftSideMenuSuccessfull = (data)=>{
    return {
        type:VENDOR_LEFT_SIDE_MENU_SUCCESSFULL,
        payload:data
    }
}