import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import { getCostList } from "../../../store/Accounting/Bills/actions";
import CustomButton from "../../../components/Buttons/Buttons";
import { payrollcreate } from "../../../store/HRMS/PayRollData/actions";
import Checkbox from "../../../components/CheckBox/Checkbox";
import BootstrapTable from "react-bootstrap-table-next";
import { Dropdown, DropdownToggle } from "reactstrap";
import { Grid } from "@mui/material";
import swal from "sweetalert";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

const pageListRenderer = ({ pages, onPageChange }) => {
  const pageWithoutIndication = pages.filter((p) => typeof p.page !== "string");
  return (
    <div>
      {pageWithoutIndication.map((p) => (
        <button
          className="btn btn-success"
          onClick={() => onPageChange(p.page)}
        >
          {p.page}
        </button>
      ))}
    </div>
  );
};

const options = {
  pageListRenderer,
};
const CustomToggleList = ({ columns, onColumnToggle, toggles, checkall }) => (
  <div
    className="btn-group btn-group-toggle btn-group-vertical"
    data-toggle="buttons"
  >
    <div className="form-check form-check-inline">
      <Checkbox
        label="Check All"
        value="Check All"
        selected={checkall ? false : true}
        onChange={() => {
          onColumnToggle("checkall");
        }}
      />
    </div>
    {columns
      .map((column) => ({
        ...column,
        toggle: toggles[column.dataField],
      }))
      .map((column) => (
        <div className="form-check form-check-inline">
          <Checkbox
            label={column.dataField}
            value={column.text}
            selected={column.hidden ? false : true}
            onChange={() => {
              onColumnToggle(column.dataField);
            }}
          />
        </div>
      ))}
  </div>
);

class PayRollLineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RowData: [],
      rowdata: [],
      data: this.props.RowData,
      errorMessage: null,
      columns: this.props.tableHeaders,
      checkall: false,
      loading: "none",
      saveData: [],
      newCol: [],
    };
    this.handleTableChange = this.handleTableChange.bind();
  }

  handleCreatePayRollData = () => {
    var obj = {};
    let empIdList = [];

    if (this.props.rawData) {
      this.props.rawData.map((m) => {
        if (m.rowData) {
          let data;
          m.rowData.map((p) => {
            const found = this.state.saveData.find((objv) => {
              return objv.empId === p.empId;
            });
            if (p.empId == found.empId) {
              obj = { ...obj, [p.id]: found[p.dataField_name] };
              return (data = {
                empId: found.empId,
                payCompValues: obj,
              });
            }
          });
          empIdList.push(data);
        }
      });
    }

    this.setState({
      loading: "block",
    });
    this.props.payrollcreate(
      serverApi.CREATE_PAYROLL_DATA +
        this.props.fromDate +
        "/" +
        this.props.toDate +
        "/" +
        localStorage.getItem("companyId") +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).userId,
      empIdList,
      this.props.history
    );
    setTimeout(
      function () {
        //Start the timer
        this.setState({ loading: "none" }); //After 1 second, set render to true
        this.props.hideTableRows();
      }.bind(this),
      5000
    );
  };

  handleChange = (newValue, main, sub) => {
    const result = this.state.saveData.map((row) => {
      if (row.empId === main.empId) {
        const newRow = { ...row };
        newRow[sub.dataField_name] = newValue;

        return newRow;
      }
      return row;
    });

    this.setState(() => ({
      saveData: result,
      errorMessage: null,
    }));

    const result1 = this.state.RowData.map((row) => {
      if (row.empId === sub.empId) {
        const newRow = { ...row };
        newRow[sub.dataField_name] = (
          <Fragment>
            <input
              type="number"
              className="custom-control-input"
              onChange={(e) => this.handleChange(e.target.value, row, sub)}
              value={newValue}
            />
          </Fragment>
        );
        return newRow;
      }
      return row;
    });
    this.setState(() => ({
      RowData: result1,
      errorMessage: null,
    }));
  };

  componentWillReceiveProps(props) {
    if (props.RowData) {
      var resultdata = props.RowData.map((row, index) => ({
        ...row.rowData,
      }));

      var mainData = [];
      var saveData = [];
      props.rawData.map((main) => {
        const arr1 = {
          empCode: main.empCode,
          empId: main.empId,
          empName: main.empName,
          paySchemeName: main.paySchemeName,
        };
        main.rowData.map((sub, index) => {
          Object.assign(arr1, {
            [sub.dataField_name]: (
              <Fragment>
                <input
                  type="number"
                  className="custom-control-input"
                  onChange={(e) => this.handleChange(e.target.value, main, sub)}
                  value={sub.value}
                  onWheel={(e) => e.target.blur()}
                />
              </Fragment>
            ),
          });
        });
        mainData.push(arr1);
        const arr2 = {
          empCode: main.empCode,
          empId: main.empId,
          empName: main.empName,
          paySchemeName: main.paySchemeName,
        };
        main.rowData.map((sub1, index) => {
          Object.assign(arr2, { [sub1.dataField_name]: sub1.value });
        });
        saveData.push(arr2);
      });

      this.setState({
        RowData: mainData,
        saveData: saveData,
        rowdata: resultdata,
        columns: props.tableHeaders,
      });
    }
  }

  handleTableChange = (
    type,
    { data, cellEdit: { rowId, dataField, newValue } }
  ) => {
    setTimeout(() => {
      if (
        dataField === "empCode" ||
        dataField === "empName" ||
        dataField === "paySchemeName"
      ) {
        this.setState(() => ({
          data,
          errorMessage: 'Oops, product name shouldnt be "test"',
        }));
        swal("Sorry! employee code, name and pay scheme name not editable ");
      } else {
        const result = data.map((row) => {
          if (row.empId === rowId) {
            const newRow = { ...row };
            newRow[dataField] = newValue;
            return newRow;
          }
          return row;
        });
        this.setState(() => ({
          RowData: result,
          errorMessage: null,
        }));
      }
    }, 10);
  };

  updateHiddenProperty = (column) => {
    if (column.hidden === undefined) {
      return true;
    } else {
      return !column.hidden;
    }
  };

  customHandleToggleColumnVisiblity = (dataField) => {
    if (dataField === "checkall") {
      // check all event code

      const newState = this.state.columns.map((column) => {
        if (column.dataField === "empCode") {
          return column;
        } else if (column.dataField === "empName") {
          return column;
        } else if (column.dataField === "paySchemeName") {
          return column;
        } else {
          if (this.state.checkall === false) {
            this.setState({
              checkall: true,
            });
            return { ...column, hidden: true };
          } else {
            this.setState({
              checkall: false,
            });
            return { ...column, hidden: false };
          }
        }
      });

      this.setState({
        columns: newState,
        isDropdown: false,
      });
    } else {
      // single chek event code
      const newState = this.state.columns.map((column) => {
        if (column.dataField === dataField) {
          return {
            ...column,
            hidden: this.updateHiddenProperty(column),
          };
        }
        return column;
      });
      this.setState({ columns: newState });
    }
  };

  render() {
    const afterSearch = (newResult) => {};
    return (
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent">
            <ToolkitProvider
              keyField="id"
              data={this.state.RowData}
              columns={this.state.columns}
              columnToggle
              search={{ afterSearch }}
            >
              {(props) => (
                <div>
                  <div>
                    <div className="buttonBlock">
                      <Grid item xs={12} md={3}></Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <div style={{ display: "flex", position: "relative" }}>
                          <Dropdown toggle={() => this.setState({})}>
                            <DropdownToggle className="custom_view">
                              <CustomButton
                                label="Customize View"
                                type="button"
                                className="lightBlueButton"
                                handleClick={() => {
                                  this.setState({
                                    isDropdown: !this.state.isDropdown,
                                  });
                                }}
                              />
                            </DropdownToggle>
                            {this.state.isDropdown && (
                              <>
                                <div
                                  className={
                                    this.state.isDropdown === true
                                      ? "backdropNew"
                                      : ""
                                  }
                                  onClick={() => {
                                    this.setState({
                                      isDropdown: !this.state.isDropdown,
                                    });
                                  }}
                                ></div>
                                <div className="customizeViewContainer">
                                  <div
                                    className="customizeViewContent"
                                    aria-expanded="true"
                                  >
                                    <input
                                      type="search"
                                      placeholder="search"
                                      onChange={(e) => {
                                        let letters = e.target.value;
                                        let fullData =
                                          props.columnToggleProps.columns.filter(
                                            (prop) => {
                                              let data =
                                                prop.dataField.match(letters);
                                              if (data) {
                                                return data;
                                              }
                                            }
                                          );

                                        this.setState({
                                          newCol: fullData,
                                        });
                                      }}
                                    />

                                    <CustomToggleList
                                      onColumnToggle={
                                        this.customHandleToggleColumnVisiblity
                                      }
                                      columns={
                                        this.state.newCol.length != 0
                                          ? this.state.newCol
                                          : props.columnToggleProps.columns
                                      }
                                      toggles={
                                        this.state.newCol.length != 0
                                          ? this.state.newCol
                                          : props.columnToggleProps.columns
                                      }
                                      checkall={this.state.checkall}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </Dropdown>
                        </div>
                      </Grid>
                    </div>
                  </div>
                  <BootstrapTable
                    {...props.baseProps}
                    keyField="empId"
                    remote={{ cellEdit: true }}
                    onTableChange={this.handleTableChange}
                    startEditing={true}
                    enableCellSelect={true}
                  />
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <CustomButton
            label={"Save"}
            className="greenButton"
            handleClick={this.handleCreatePayRollData}
            type="sumbit"
          />
          <CustomButton
            label={"Cancel"}
            className="greenButton"
            type="sumbit"
          />
        </div>
        <div id="overlays" style={{ display: this.state.loading }}>
          <div class="loader-wrapper">
            <div class="loader"></div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { createPayRollData, payrolldata_success } = state.PayRollDataReducer;
  const { BillNoList } = state.PaymentReducer;

  return { BillNoList, createPayRollData, payrolldata_success };
};

export default withRouter(
  connect(mapStatetoProps, {
    getCostList,

    payrollcreate,
  })(PayRollLineItem)
);
