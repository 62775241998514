import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CustomButton from "../../../components/Buttons/Buttons";
import { serverApi, getTenantId } from "../../../helpers/Consts";
import EditIcon from "@mui/icons-material/Edit";
import { SalesOrderJuteHessianHeaders} from "../../../store/Sales/SalesOrder/actions";
import moment from "moment";
import { isEmpty, limitDecimals} from "../../../Utilities/helper";
import PrintIcon from "@mui/icons-material/Print";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import { getFileDownload } from "../../../helpers/server.js";
import { Box, Tooltip,Grid } from "@mui/material";
import Fade from "@mui/material/Fade";
import { serverConfig } from "../../../helpers/Consts";


const JuteHessianInvoicePreview = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [phoneNo, setphoneNo] = useState("");
  const [email, setemail] = useState("");
  const [companyLogo, setcompanyLogo] = useState("");
  const [currentStep, setcurrentStep] = useState(1);
  const [currentDate, setcurrentDate] = useState(new Date().toLocaleString());
  const [editButton, seteditButton] = useState(true);
  const [LineItems, setLineItems] = useState([]);
  const [HeaderData, setHeaderData] = useState({});
  const [gstNo1, setGstNo1] = useState("");
  const { InvoiceFiles } = useSelector((state) => state.InvoiceReducer);
  const [invoiceFiles, SetInvoiceFiles] = useState(
    InvoiceFiles?.data?.data || []
  );
  useEffect(() => {
    SetInvoiceFiles(InvoiceFiles?.data?.data);
  }, [InvoiceFiles]);


  const {JuteHassianInvoiceLineitems, JuteHassianInvoiceHeader } = useSelector(
    (state) => state.InvoiceReducer
  );

  const { billingShippingList } = useSelector(
    (state) => state.DropDownListReducer
  );

  useEffect(() => {
    let companyData = JSON.parse(localStorage.getItem("authUser"));
    let companyId = localStorage.getItem("companyId");
    companyData.companyDetails.map((p) => {
      let id = p.value;
      if (companyId === id.toString()) {
        setcompanyName(p.label);
        setcompanyLogo(p.companyLogo);
        setphoneNo(p.contactNo);
        setemail(p.emailId);
        setGstNo1(p.gstNo)
        setaddress1(p.address1);
        setaddress2(p.address2);
      }
    });
  }, []);


  useEffect(() => {
    setLineItems(JuteHassianInvoiceLineitems);
    setHeaderData(JuteHassianInvoiceHeader);
  }, [JuteHassianInvoiceLineitems, JuteHassianInvoiceHeader]);

  useEffect(() => {
    if (billingShippingList) {
      if (billingShippingList.data) {
        if (billingShippingList.data.customerLineitems) {
          if (billingShippingList.data.customerLineitems.length !== 0) {
            billingShippingList.data.customerLineitems.map((prop, i) => {
              if (props.locationState.state !== undefined) {
                if (props.locationState.state.rowData !== undefined) {
                  if (prop.address == props.locationState.state.rowData.billingTo) {
                    const UpdatedRec = {
                      ...JuteHassianInvoiceHeader,
                      ContactNumber: billingShippingList.data.contactNo,
                      ContactPerson: billingShippingList.data.contactPerson,
                      billingToGST: prop.gstNo,
                      billingContactNo: prop.contactNo,
                      billingContactPerson: prop.contactPerson,
                      billingToEmail:
                        billingShippingList.data.customerHdr.emailId,

                      shippingToGST: prop.gstNo,
                      shippingContactNo: prop.contactNo,
                      shippingContactPerson: prop.contactPerson,
                      shippingToEmail:
                        billingShippingList.data.customerHdr.emailId,
                    };
                    dispatch(SalesOrderJuteHessianHeaders(UpdatedRec));
                  }
                }
              }
            });
          }
        }
      }
    }
  }, [billingShippingList]);

  const handlePdf = (printType) => {
    if (props.locationState.state !== undefined) {
      if (props.locationState.state.rowData !== undefined) {
        fetch(
          serverApi.REGULAR_INVOICE_PDF_DOWNLOAD +
            4 +
            "/" +
            HeaderData.regInvoiceId +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).userId +
            "/" +
            printType,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/pdf",
              "X-TenantId": getTenantId(window.location.hostname),
              Authorization:
                localStorage.getItem("token_type") +
                localStorage.getItem("access_token"),
            },
          }
        )
          .then((response) => response.blob())
          .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", HeaderData.invoiceNo + ".pdf");
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          });
      }
    }
  };

  const convertNumberToWords = (amount) => {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();

    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = "";
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  };

  const handleEdit = () => {
    props.handleCurrentStep(2);
  };

  const handleEWayBill = (printType) => {
  if(props.locationState.state !== "undefined") {
    fetch(
      serverApi.REGULAR_INVOICE_EWAY_BILL_PDF_DOWNLOAD +
        "4/" +
        JuteHassianInvoiceHeader.regInvoiceId +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).userId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          "X-TenantId": getTenantId(window.location.hostname),
          Authorization:
            localStorage.getItem("token_type") +
            localStorage.getItem("access_token"),
        },
      }
    ).then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", HeaderData.invoiceNo + ".pdf");
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  }
  }

  const withDecimal = (n) => {
    if (n !== undefined) {
      var nums = n.toString().split(".");
      var whole = convertNumberToWords(nums[0]);

      if (nums.length === 2) {
        var decimal = convertNumberToWords(nums[1]);
        if (parseInt(nums[1]) > 0) {
          return whole + " Rupees and " + decimal + " Paise only";
        }
      }

      return whole + " Rupees only";
    }
  };

  let filterProduct = LineItems.filter(
    (item) =>
      item.isActive != 0 && item.itemGroupId !== null && item.itemGroupId !== ""
  );
  if (
    HeaderData.salesDate !== null &&
    HeaderData.salesDate !== "" &&
    HeaderData.salesDate !== undefined
  ) {
    var salesDate = moment(HeaderData.salesDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
  }
  if (
    HeaderData.salesOrderExpiryDate !== null &&
    HeaderData.salesOrderExpiryDate !== "" &&
    HeaderData.salesOrderExpiryDate !== undefined
  ) {
    var salesOrderExpiryDate = moment(
      HeaderData.salesOrderExpiryDate,
      "YYYY-MM-DD",
      true
    ).format("DD-MM-YYYY");
  }
  if (
    HeaderData.createdDate !== null &&
    HeaderData.createdDate !== "" &&
    HeaderData.createdDate !== undefined
  ) {
    var createdDate = moment(HeaderData.createdDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
  }

  if (
    HeaderData.createdDate !== null &&
    HeaderData.createdDate !== "" &&
    HeaderData.createdDate !== undefined
  ) {
    var salesOrderDate = moment(HeaderData.salesOrderDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
  }

  if (
    HeaderData.createdDate !== null &&
    HeaderData.createdDate !== "" &&
    HeaderData.createdDate !== undefined
  ) {
    var deliveryDate = moment(HeaderData.deliveryDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
  }

  if (
    HeaderData.createdDate !== null &&
    HeaderData.createdDate !== "" &&
    HeaderData.createdDate !== undefined
  ) {
    var invoiceDate = moment(HeaderData.invoiceDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
  }

  if (
    HeaderData.updatedOn !== null &&
    HeaderData.updatedOn !== "" &&
    HeaderData.updatedOn !== undefined
  ) {
    var updatedOn = moment(HeaderData.updatedOn, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
  }
  return (
    <>
    <div className="previewBlock">
      <div
        style={{ display: "flex", justifyContent: "end", padding: "10px" }}
      >
           {props.locationState.state &&
            HeaderData.statusId !== "" &&
            !isEmpty(HeaderData.ewayBillNo) && (
              <CustomButton
                label="e-way Bill Copy Print"
                className="lightBlueButton"
                muIcon={<PrintIcon />}
                handleClick={() => {
                  handleEWayBill(1);
                }}
                type="button"
              ></CustomButton>
            )}
           {props.locationState.state &&
            JuteHassianInvoiceHeader.statusId !== "" &&
            !isEmpty(JuteHassianInvoiceHeader.brokerId) && (
              <CustomButton
                label="Broker Copy Print"
                className="lightBlueButton"
                muIcon={<PrintIcon />}
                handleClick={() => {
                  handlePdf(2);
                }}
                type="button"
              ></CustomButton>
            )}

         {props.locationState.state &&
            HeaderData.statusId !== "" &&
            !isEmpty(HeaderData.customerId) && (
              <CustomButton
                label="Consignee Copy Print"
                className="lightBlueButton"
                muIcon={<PrintIcon />}
                handleClick={() => {
                  handlePdf(1);
                }}
                type="button"
              ></CustomButton>
            )}

          {props.locationState.state && 
          HeaderData.UpdateButton && (
            <CustomButton
              label="Edit"
              handleClick={handleEdit}
              type="button"
              className="lightBlueButton"
              muIcon={<EditIcon />}
            ></CustomButton>
          )}

        
      </div>
      <table
        cellpadding="0"
        cellspacing="0"
        width="90%"
        className="previewTable"
      >
        <tbody>
          <tr>
            <td
              colspan="5"
              style={{ borderRight: "none", borderBottom: "none" }}
            >
              <img src={companyLogo} width="100%" alt="" />
            </td>
            <td
              colspan="9"
              style={{
                border: "none",
                textAlign: "right",
              }}
            >
               <h2>{companyName}</h2>
                {address1}
                <br/>
                {address2}
                <br/>
                <span><b>Ph.No: </b>{phoneNo}, </span>
                  <span><b>Email: </b> {email}</span>
                  <br/>
                <b>GST No:</b> {gstNo1 || "n/a"}
                <br/>
              <br></br>
            </td>
          </tr>
          <tr>
            <td
              colspan="14"
              style={{ textAlign: "center", borderTop: "none" }}
            >
              <h2> Invoice </h2>
            </td>
          </tr>
          
            <tr>
              <td colspan="3">
                <b>Invoice NO : </b>
              </td>
              <td
                colspan="3"
                className={isEmpty(HeaderData.invoiceNo) ? "blur-text" : ""}
              >
                {isEmpty(HeaderData.invoiceNo)
                  ? "Will be generated after creation"
                  : HeaderData.invoiceNo}
              </td>
              <td colspan="3">
                <b>Invoice DATE :</b>
              </td>
              <td colspan="6">{invoiceDate}</td>
            </tr>

          <tr>
            <td colspan="3">
              <b>Delivery Order NO : </b>
            </td>
            <td
              colspan="3"
              className={HeaderData.deliveryOrderNo == "" ? "blur-text" : ""}
            >
              {HeaderData.deliveryOrderNo == "" || HeaderData.deliveryOrderNo == undefined
                ? "Will be generated after creation"
                : HeaderData.deliveryOrderNo}
            </td>
            <td colspan="3">
              <b>Delivery Order DATE :</b>
            </td>
            <td colspan="6">{deliveryDate}</td>
          </tr>
          <tr>
            <td colspan="3">
              <b>Sales Order No:</b>
            </td>
            <td
              colspan="3"
              className={HeaderData.salesOrderNumber == "" ? "blur-text" : ""}
            >
              {HeaderData.salesOrderNumber}
            </td>
            <td colspan="3">
              <b>Sales Order Date :</b>
            </td>

            <td colspan="6">{salesOrderDate}</td>
          </tr>
          <tr>
            <td colspan="3">
              <b> Consignee :</b>
            </td>
            <td colspan="3">{HeaderData.consigneeContactPerson}</td>
            <td colspan="3">
              <b> Branch : </b>
            </td>

            <td colspan="6">{HeaderData.branchName}</td>
          </tr>

          <tr>
            <td colspan="3">
              <b> Broker :</b>
            </td>
            <td colspan="12">{HeaderData.brokerName}</td>
            
          </tr>
         
          <tr>
            <td
              colspan="4"
              style={{
                borderTop: "none",
                borderBottom: "none",
                padding: "none",
              }}
            >
              <br />
              <b>
                {" "}
                Billed To :</b>{HeaderData.customerName}-
                <br />
                {" "}
                {HeaderData.billingToAddress}

              <br />
              <br />
              
              <b>
                GSTIN : {"  "}</b>
                {HeaderData.billingToGST}
              
              <br />
              <b>
                Contact Person : {"  "} </b>
                {HeaderData.billingContactPerson}
             
              <br />
              <b>
                Contact Number : {"  "} </b>
                {HeaderData.billingContactNo}
             
              <br />
              <b>
                EMAIL ID : {"  "} </b>
                {HeaderData.billingToEmail}
             
            </td>
            <td
              colspan="4"
              style={{
                borderTop: "none",
                borderBottom: "none",
                padding: "none",
              }}
            >
              <br />
              <b>
                {" "}
                Shipped To: </b>{HeaderData.customerName}-
                <br />
                {""}
                {HeaderData.shippingToAddress}
              <br />
              
              <b>GSTIN :</b> {HeaderData.shippingToGST}
              <br />
              <b>
                Contact Person :</b> {"  "} {HeaderData.shippingContactPerson}{" "}
              
              <br />
              <b>
                Contact Number :</b>{"  "}
                {HeaderData.shippingContactNo}
              
              <br />
              <b>EMAIL ID : </b>{HeaderData.shippingToEmail}
            </td>
            <td
              colspan="7"
              style={{
                borderTop: "none",
                borderBottom: "none",
                padding: "none",
              }}
            >
              <br />
              <b>
                {" "}
                Transport : </b>{"  "}
                {HeaderData.transporterAddress}
             
              <br />
              <br />
              <b>Transporter Name : </b>{HeaderData.transporterName}
              <br />
              <b>C.N.No : </b>{HeaderData.transporterName}
              <br />
              <b>Transporter GSTNo : </b>
                <span>{HeaderData.transporterGst}</span>
                <br />
              <b>Vehicle No: </b>{HeaderData.vehicleNo}
              <br />
              <br />
            </td>
          </tr>
          <tr>
            <td rowspan="2" align="center" width={"4%"}>
              <b>S.No</b>
            </td>
            <td rowspan="2" align="center" width={"6%"}>
              <b>Item CODE</b>
            </td>
            <td rowspan="2" align="center" width={"8%"}>
              <b>Item Group</b>
            </td>
            <td rowspan="2" align="center" width={"12%"}>
              <b>Item Desc</b>
            </td>
            <td rowspan="2" align="center" width={"7%"}>
              <b>Quantity/UOM</b>
            </td>
            <td rowspan="2" align="center" width={"8%"}>
              <b>RATE</b>
            </td>
            <td rowspan="2" align="center" width={"8%"}>
              <b>Amount</b>
            </td>
            <td colspan="2" align="center" width={"8%"}>
              <b>IGST</b>
            </td>
            <td colspan="2" align="center" width={"8%"}>
              <b>CGST</b>
            </td>
            <td colSpan="3" align="center" width={"8%"}>
              <b>SGST</b>
            </td>
          </tr>
          <tr>
            <td>
              <b>%</b>
            </td>
            <td>
              <b>Amt</b>
            </td>
            <td>
              <b>%</b>
            </td>
            <td>
              <b>Amt</b>
            </td>
            <td>
              <b>%</b>
            </td>
            <td>
              <b>Amt</b>
            </td>
          </tr>
          {filterProduct &&
            filterProduct.map((item, i) => {
              return (
                <>
                  <tr>
                    <td>{i + 1}</td>
                    <td>{item.itemCode}</td>
                    <td>{item.itemGroupName}</td>
                    <td>
                      {item.itemName}
                      <br />
                        <span><b>Remarks :</b> {item.remarks}</span> <br />
                        <span><b>HSN Code :</b>  {item.hsncode}</span>
                    </td>

                    <td align="right">
                    {item.hessianQuantityRelationValue === 0 ||item.hessianQuantityRelationValue === "0" ? "": (
                            <>
                              {limitDecimals(item.hessianQuantityCalculatedValue,3)}{" "}
                              - {item.hessianQuantityUom}
                              <br />
                              {limitDecimals(item.quantity,3)} - { item.uom}
                            </>
                          )}
                    </td>

                    <td align="right">
                    {item.hessianRateRelationValue === "" || item.hessianRateRelationValue === 0 || item.hessianRateRelationValue === "0" ? "": (
                            <>
                              {limitDecimals(
                                item.hessianRateCalculatedValue
                              ,3)}{" "}
                              - RS/{item.hessianRateUom}
                              <br />
                              {limitDecimals(item.rate,3)} - RS/{item.uom}
                            </>
                          )}
                      </td>

                    <td align="right">
                     {limitDecimals(item.netAmount,2)}
                    </td>
                    <td align="right">{limitDecimals(item.igstPercent,2)}</td>
                    <td align="right">
                      {limitDecimals(item.igstAmount,2)}{" "}
                    </td>
                    <td align="right">
                      {limitDecimals(item.cgstPercent,2)}
                    </td>
                    <td align="right">{limitDecimals(item.cgstAmount,2)}</td>
                    <td align="right">
                      {limitDecimals(item.sgstPercent,2)}
                    </td>
                    <td align="right">
                      {limitDecimals(item.sgstAmount,2)}
                    </td>
                    {/* <td align="right">{item.currency}</td> */}
                  </tr>
                </>
              );
            })}
          <tr>
            <td colspan="5"> {}
              <b>Taxable Value </b>
            </td>
            <td colspan="13">{limitDecimals(HeaderData.netTotal,2)}</td>
          </tr>
          <tr>
            <td colspan="5">
              <b>Total IGST</b>
            </td>
            <td colspan="13">
              {limitDecimals(HeaderData.totalIGST,2)}
            </td>
          </tr>
          <tr>
            <td colspan="5">
              <b>Total CGST</b>
            </td>
            <td colspan="13">
              {limitDecimals(HeaderData.totalCGST,2)}
            </td>
          </tr>
          <tr>
            <td colspan="5">
              <b>Total SGST</b>
            </td>
            <td colspan="13">
              {limitDecimals(HeaderData.totalSGST,2)}
            </td>
          </tr>
          <tr>
            <td colspan="5">
              <b>Total Amount</b>
            </td>
            <td colspan="13">
              {limitDecimals(HeaderData.TotalAmount,2)}
            </td>
          </tr>
          <tr>
            <td colspan="5">
              <b>Total Amount in words</b>
            </td>
            <td colspan="13">{withDecimal(parseFloat(HeaderData.TotalAmount).toFixed(2))}</td>
          </tr>
          <tr>
            <td
              colspan="14"
              style={{
                borderTop: "none",
                borderRight: "none",
                borderBottom: "none",
                marginTop: "5px",
              }}
            >
              <span>
                <br/>
                <b>Bank Details:</b>
                <br />
                <b>{companyName}</b>
                <br />
                <b> Bank:{"  "} 
                </b>{HeaderData.bankName}
                <br />
                <b> Branch: {"  "} 
                </b>{HeaderData.bankBranch}
                <br />
                <b> A/c No:{"  "} 
                </b>{HeaderData.accountNo}
                <br />
                <b> IFSC Code:{"  "} 
                </b>{HeaderData.ifscCode}
                <br />
                <b> MICR No:{"  "} 
                </b>{HeaderData.micrCode}
                <br />
                <br />
                <br />
              </span>
              <br />
              <span>
                <b>Delivery Days :</b>{" "}
                <span>
                  {HeaderData.deliveryDays}{" "}
                  {HeaderData.deliveryDays ? "Days" : ""}
                </span>{" "}
              </span>
              <br />
              <span>
                <b>Delivery terms :</b>{" "}
                <span>{HeaderData.deliveryTerms}</span>
              </span>
              <br />
              <span>
                <b>Payment Terms (In Days) : </b> <span>{HeaderData.paymentTerms}</span>{" "}
              </span>
              <br />
              <span>
                <b>Footer Notes : </b> {HeaderData.footerNote}
              </span>
              <br />

              <span>
                <b>Internal Notes : </b>
                {HeaderData.internalNote}{" "}
              </span>
              <br/>
              <span>
              <b>Broker Percentage : </b> {HeaderData.brokerCommisionPercent ? limitDecimals(HeaderData.brokerCommisionPercent,2) : "0.00"}%
              </span>
              <br />
              <br />
              <tr>
                    <td
                      colSpan="10"
                      style={{
                        borderTop: "none",
                        borderBottom: "none",
                        borderRight: "none",
                        borderLeft: "none",
                      }}
                    >
                {props.locationState.state !== undefined ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box gridColumn="span 12">
                            <span>
                              <b>Support Documents:</b>
                            </span>
                          </Box>
                          <Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={2}
                            className="ducumentUploadBlock uploadedContent"
                            style={{ padding: "10px" }}
                          >
                            {invoiceFiles?.length > 0 &&
                              invoiceFiles.map((item) => (
                                <Box gridColumn="span 6">
                                <div
                                  className="documentUploadContent"
                                  key={item.fileUploadId}
                                >
                                  <div
                                    className="uploadedInfo"
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <img
                                      src={TickIcon}
                                      alt=""
                                      className="tickIcon"
                                      style={{
                                        height: "16px",
                                        width: "16px",
                                        marginRight: "8px",
                                      }}
                                    />
                                    <Tooltip
                                      TransitionComponent={Fade}
                                      TransitionProps={{ timeout: 600 }}
                                      title="Click to download File"
                                    >
                                      <div
                                        className="uplodedFileName"
                                        onClick={() => {
                                          getFileDownload(
                                            serverConfig.SERVER_URL +
                                              "security-api/api/files/downloadfile/" +
                                              item.fileUploadId,
                                            item.fileName,
                                            item.fileExtension
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.fileName}
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                                </Box>
                              ))}
                          </Box>
                        </Grid>
                      </Grid>
                   
                ) : (
                  ""
                )}
                 </td>
                  </tr>
              <span>
                <b>Terms and Conditions: </b> <br />
                {HeaderData.termsCondition}
              </span>
              <br />
              <br />
              {props.locationState.state !== undefined ? (
                <>
                  <b>Last Modified On: </b> {updatedOn}
                </>
              ) : (
                ""
              )}
              <br />
              {props.locationState.state !== undefined ? (
                <>
                  <b>Last Modified By: </b> {updatedOn}
                </>
              ) : (
                ""
              )}
              <br />
              <br />
              {props.locationState.state !== undefined ? (
                <b>Status: {HeaderData.statusName}</b>
              ) : (
                ""
              )}
              <br />
              <br />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </>
  );
};

export default JuteHessianInvoicePreview;
