import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Box } from "@mui/material";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { serverApi } from "../../../helpers/Consts";
import moment from "moment";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { getEmployeeList } from "../../../store/HRMS/EmployeeDataBase/actions";
import searchIcon from "../../../assets/images/search.png";
import InputAdormentSearch from "../../../components/InputAdorment/InputAdormentSearch";
import { getProjectMasterList } from "../../../store/Global/DropDownApis/actions";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import {
  getWorkerList,
  getWorkerDataClearForExpenseBooking,
} from "../../../store/HRMS/ExpenseBooking/actions";
import swal from "sweetalert";
class CreateExpenseFillDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      companyId: localStorage.getItem("companyId"),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      ebNo: this.props.header.ebNo,
      ebId: this.props.header.ebId,
      deptId: this.props.header.deptId,
      name: this.props.header.name,
      department: this.props.header.department,
      designation: this.props.header.designation,
      project: this.props.header.project,
      description: this.props.header.description,
      createDate: this.props.header.createDate,
      fromDate: this.props.header.fromDate,
      toDate: this.props.header.toDate,
      total: "",
      payNote: "",
      payDate: "",
      employeeListData: [],
      ProjectListData: [],
      dateOfJoining: "",
    };
  }
  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "EbNo") {
      this.props.header.ebNo = event;
      this.setState({
        ebNo: event,
      });
    }
    if (key === "Name") {
      this.props.header.name = event;
      this.setState({
        name: event,
      });
    }
    if (key === "Department") {
      this.props.header.department = event;
      this.setState({
        department: event,
      });
    }
    if (key === "Designation") {
      this.props.header.designation = event;
      this.setState({
        designation: event,
      });
    }
    if (key === "Description") {
      this.props.header.description = event;
      this.setState({
        description: event,
      });
    }
  };
  componentWillReceiveProps(props) {
    if (props.ProjectMastersData) {
      this.setState({
        ProjectListData: props.ProjectMastersData.data,
      });
    }
    if (props.worker_List) {
      if (props.worker_List.data) {
        if (props.worker_List.data.empStatus == 35) {
          this.setState({
            name: props.worker_List.data.workerName,
            ebId: props.worker_List.data.ebId,
            department: props.worker_List.data.deptIdDesc,
            designation: props.worker_List.data.designationIdDesc,
            deptId: props.worker_List.data.deptId,
            ebNo: props.worker_List.data.ebNo,
            dateOfJoining: props.worker_List.data.dateOfJoining,
          });
          this.props.header.name = props.worker_List.data.workerName;
          this.props.header.ebId = props.worker_List.data.ebId;
          this.props.header.department = props.worker_List.data.deptIdDesc;
          this.props.header.deptId = props.worker_List.data.deptId;
          this.props.header.designation =
            props.worker_List.data.designationIdDesc;
        } else {
          swal("Only Joined Employees can Create the expense");
        }
      } else {
        swal("No Employee found under the Current User!");
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.props.getProjectMasterList(
      serverApi.GET_ALL_PROJECT_MASTER + localStorage.getItem("companyId"),
      this.props.history
    );
    if (this.props.location.state !== undefined) {
      if (this.props.employeeList.data) {
        this.state.empData = this.props.employeeList.data.filter(
          (data) => data.ebId === this.props.location.state.rowData.ebId
        );
      }

      if (this.props.location.state.rowData !== undefined) {
       
        this.props.header.ebId = this.props.location.state.rowData.ebId;
        this.props.header.ebNo = this.props.location.state.rowData.ebNo;
        this.props.header.name = this.props.location.state.rowData.workerName;
        this.props.header.project = this.props.location.state.rowData.project;
        this.props.header.description =
          this.props.location.state.rowData.description;
        this.props.header.createDate =
          this.props.location.state.rowData.createDate;
        this.props.header.fromDate = this.props.location.state.rowData.fromDate;
        this.props.header.toDate = this.props.location.state.rowData.toDate;

        this.setState({
          ebNo: this.props.location.state.rowData.ebNo,
          ebId: this.props.location.state.rowData.ebId,
          name: this.props.location.state.rowData.workerName,
          project: this.props.location.state.rowData.project,
          description: this.props.location.state.rowData.description,
          createDate: this.props.location.state.rowData.createDate,
          fromDate: this.props.location.state.rowData.fromDate,
          toDate: this.props.location.state.rowData.toDate,
        });
      }
    }
  }
  onclickOk = () => {
    this.setState({
      show: false,
    });
    this.props.getWorkerDataClearForExpenseBooking(null);
  };
  onClick = () => {
    this.props.getWorkerList(
      serverApi.WORKER_LIST +
        this.state.ebNo +
        "/" +
        localStorage.getItem("companyId") +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).userId,
      this.props.history
    );
  };
 
  handleSelectDate = (e, name) => {
    if (name === "CreateDate") {
      var date = moment(e).format();
      var Createdate = date.split("T", 1).toString();
      this.props.header.createDate = e.getTime();
      this.setState({
        createDate: e.getTime(),
      });
    }
    if (name === "ExpenseFrom") {
      var date2 = moment(e).format();
      var expenseFrom = date2.split("T", 1).toString();
      if (expenseFrom < this.state.dateOfJoining) {
        swal("Expense From Date Shouldn't be Less Than The Employee Joining Date");
        this.setState({
          fromDate: null,
        });
      } else {
        if (this.state.toDate != "") {
          if (expenseFrom > this.state.toDate) {
            swal("Expense From Date Shouldn't be Greater than Expense To Date");
            this.props.header.fromDate = null;
            this.setState({
              fromDate: null,
            });
          } else {
            this.props.header.fromDate = expenseFrom;
            this.setState({
              fromDate: expenseFrom,
            });
          }
        } else {
          this.props.header.fromDate = expenseFrom;
          this.setState({
            fromDate: expenseFrom,
          });
        }
      }
    }
    if (name === "ExpenseTo") {
      var date3 = moment(e).format();
      var expenseTo = date3.split("T", 1).toString();
      if (expenseTo < this.state.fromDate) {
        swal("Expense To Date Should be Greater than Expense From Date");
        this.props.header.toDate = null;
        this.setState({
          toDate: null,
        });
      } else {
        this.props.header.toDate = expenseTo;
        this.setState({
          toDate: expenseTo,
        });
      }
    }
  };
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "project") {
      this.props.header.project = selectedName;
      this.setState({
        project: selectedName,
      });
    }
  };
  handleMenuOpen=()=>{
    this.setState({
      isDropdownOpen:false
    })
  }
 
  handleScroll = () => {
    this.setState({
      isDropdownOpen:true
    })
  };
  render() {
    return (
      <>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={2}
              className="expenseBlock"
            >
              <Box gridColumn="span 4">
                <div className="consoleTextFieldBlock">
                  
                  <InputAdormentSearch
                    label={"Employee Code"}
                    placeholder="Enter here"
                    imgUrl={searchIcon}
                    handleChange={this.handleChange("EbNo")}
                    onClick={this.onClick}
                    value={this.state.ebNo}
                   
                  />
                </div>
              </Box>
              <Box gridColumn="span 4">
                <div className="consoleTextFieldBlock">
                  <TextFieldNormal
                    value={this.state.name}
                    label="Name"
                    placeholder="Name"
                    onChange={this.handleChange("name")}
                    disabled={true}
                  />
                </div>
              </Box>
              <Box gridColumn="span 4">
                <div className="consoleTextFieldBlock">
                  <TextFieldNormal
                    value={this.state.department}
                    label="Department"
                    placeholder="Department"
                    onChange={this.handleChange("Department")}
                    disabled={true}
                  />
                </div>
              </Box>
            </Box>

            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={2}
              className="expenseBlock"
            >
              <Box gridColumn="span 4">
                <div className="consoleTextFieldBlock">
                  <TextFieldNormal
                    value={this.state.designation}
                    label="Designation"
                    placeholder="Designation"
                    onChange={this.handleChange("Designation")}
                    disabled={true}
                  />
                </div>
              </Box>
              <Box gridColumn="span 4">
                <div className="consoleTextFieldBlock">
                  <DynamicSelectNew
                     handleMenuOpen={this.handleMenuOpen}
                     isDropdownOpen={this.state.isDropdownOpen}
                    arrayOfData={
                      this.state.ProjectListData &&
                      this.state.ProjectListData.map((item) => ({
                        label: item.prjName,
                        value: item.id,
                        name: item.prjName,
                      }))
                    }
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handlePropSelectChange}
                    selected={this.state.project}
                    update={this.state.project ? 1 : 0}
                    name="project"
                    label="Project"
                    required
                  />
                </div>
              </Box>
              <Box gridColumn="span 4">
                <div className="consoleTextFieldBlock">
                  <TextFieldReq
                    label="Description"
                    onChange={this.handleChange("Description")}
                    value={this.state.description}
                    placeholder="Enter here"
                  />
                </div>
              </Box>
            </Box>
          </div>
        </div>
        <div className="consoleFormBlock">
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
            <Box gridColumn="span 4">
              <div className="consoleTextFieldBlock">
                <div>
                  <div className="TextFieldBlock">
                    <label>Create Date</label>
                  </div>
            
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                    
                      inputFormat="dd-MM-yyyy"
                      id="date"
                      value={this.state.createDate}
                      fullWidth
                      onChange={(e) => this.handleSelectDate(e, "CreateDate")}
                      renderInput={({ inputRef, inputProps, InputProps }) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          className="consoleDatePicker"
                        >
                          <input
                            ref={inputRef}
                            {...inputProps}
                            readOnly={true}
                          />
                          {InputProps?.endAdornment}
                        </Box>
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </Box>
            <Box gridColumn="span 4">
              <div className="consoleTextFieldBlock">
                <div>
                  <div className="TextFieldBlock">
                    <label>
                      Expenses From<span className="starcolor">*</span>
                    </label>
                  </div>
               
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      name="date"
                      inputFormat="dd-MM-yyyy"
                      id="date"
                      value={this.state.fromDate}
                      fullWidth
                      onChange={(e) => this.handleSelectDate(e, "ExpenseFrom")}
                      renderInput={({ inputRef, inputProps, InputProps }) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          className="consoleDatePicker"
                        >
                          <input
                            ref={inputRef}
                            {...inputProps}
                            readOnly={true}
                          />
                          {InputProps?.endAdornment}
                        </Box>
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </Box>
            <Box gridColumn="span 4">
              <div className="consoleTextFieldBlock">
                <div>
                  <div className="TextFieldBlock">
                    <label>
                      Expenses To <span className="starcolor">*</span>
                    </label>
                  </div>
               
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      name="date"
                      inputFormat="dd-MM-yyyy"
                      id="date"
                      value={this.state.toDate}
                      fullWidth
                      onChange={(e) => this.handleSelectDate(e, "ExpenseTo")}
                      renderInput={({ inputRef, inputProps, InputProps }) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          className="consoleDatePicker"
                        >
                          <input
                            ref={inputRef}
                            {...inputProps}
                            readOnly={true}
                          />
                          {InputProps?.endAdornment}
                        </Box>
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              {this.state.show && (
                <CommonPopup>
                  <div className="delAddItem">
                    <div>No Employee found under the Current User!</div>
                    <div className="delAddItemBtns">
                      <input
                        type="button"
                        onClick={this.onclickOk.bind(this)}
                        value="Ok!"
                        className="delYesBtn"
                      />
                    </div>
                  </div>
                </CommonPopup>
              )}
            </Box>
          </Box>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { ExpenseBookingViewById, worker_List } = state.ExpenseBookingReducer;
  const { employeeList } = state.EmployeeReducer;
  const { ProjectMastersData } = state.DropDownListReducer;
  return {
    employeeList,
    ExpenseBookingViewById,
    ProjectMastersData,
    worker_List,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getEmployeeList,
    getProjectMasterList,
    getWorkerList,
    getWorkerDataClearForExpenseBooking,
  })(CreateExpenseFillDetails)
);
