import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import CustomButton from "../../../components/Buttons/Buttons";
import Link from "@mui/material/Link";
import { serverApi } from "../../../helpers/Consts";
import {
  getItemGroupMasterList,
  getUomMasterList,
  getItemsListForIndent,
} from "../../../store/Global/DropDownApis/actions";
import {
  createUomMaster,
  getUomRelation,
} from "../../../store/Master/UomMaster/actions";
import UomRelationTable from "../../../components/Grid/UomRelationTable";
import RadioButton from "../../../components/RadioButton/RadioButton";
import swal from "sweetalert";
import { isEmpty } from "../../../Utilities/helper";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
class CreateUom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      uomCodeTextField: "",
      uomDescTextField: "",
      value: "",
      UOM_Code: "",
      itemDesc: "",
      item: "",
      itemId: "",
      create_uom_master_data: [],
      ItemCodeByItemGroup: [],
      GetItem: [],
      products: [],
      variable: 0,
      variableValue: false,
      fixedValue: true,
      locationState: "",
    };
  }
  //
  onClickCancel = () => {
    this.state.products = [];
    this.setState({
      UOM: " ",
      uomDescTextField: " ",
      relation_uom: " ",
      item_Group: " ",
      products: [],
    });
    this.props.history.push("/uom_master");
  };

  onHandleChange = (variable) => {
    let variableValue = false;
    let fixedValue = false;

    if (variable === "variable") {
      variableValue = true;
      fixedValue = false;

      this.setState({
        variableValue: true,
        fixedValue: false,
      });
    } else if (variable === "fixed") {
      fixedValue = true;
      variableValue = false;

      this.setState({
        fixedValue: true,
        variableValue: false,
      });
    }
    this.setState({
      variable: variableValue ? 1 : 0,
      variableValue: variableValue,
      fixedValue: fixedValue,
    });
  };

  // Function for TextFields
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "uomCode") {
      const uomcode = event.replace(/[^a-zA-Z0-9\s]+/g, "");
      this.setState({
        uomCodeTextField: uomcode,
      });
    }
    if (key === "uomDescription") {
      this.setState({
        uomDescTextField: event,
      });
    }

    if (key === "relationValue") {
      const uomval = event.replace(/[^0-9.\s]+/g, "");

      this.setState({
        relationValue: uomval,
      });
    }
  };
  //Function for Dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "relation_uom") {
      this.setState({
        UOM_Code: selectedValue,
      });
    }

    if (name === "item_Group") {
      if (selectedValue === "0") {
        this.setState({
          itemDesc: "",
          UOM_Code: "", 
          relationValue: "", 
          itemId: "", 
        });

        return;
      }

      const data = {
        itemGroupId: selectedValue,
        saleable: "Y",
        consumable: "",
        companyId: this.state.companyId,
        indentTypeId: "",
        tangible: "",
        uomCode: this.state.uomCodeTextField,
      };

      this.props.getItemsListForIndent(
        serverApi.GET_ITEMSBY_FORPROCUREMENT,
        data,
        this.props.history
      );

      this.setState({
        itemDesc: selectedValue,
        UOM_Code: "", 
        relationValue: "", 
        itemId: "", 
      });
    }

    if (name === "item") {
      if (selectedValue === "0") {
        this.setState({
          itemId: "",
        });
      } else {
        this.props.getUomRelation(
          serverApi.GET_UOM_RELATION +
            "/" +
            this.state.uomCodeTextField +
            "/" +
            "companyId" +
            "/" +
            localStorage.getItem("companyId") +
            "/" +
            "itemId" +
            "/" +
            selectedValue,
          this.props.history
        );

        this.setState({
          itemId: selectedValue,
        });
      }
    }
  };

  onClickCreateUpdate = () => {
    if (this.props.location.state === undefined) {
      const data = {
        companyID: this.state.companyId,
        createdBy: this.state.userDit.userId,
        recordType: 1,
        relation: 0,
        relationTo: 0,
        relationValue: 0,
        uomCode: this.state.uomCodeTextField,
        uomDsc: this.state.uomDescTextField,
        variable: this.state.variable,
      };
      if (this.state.uomCodeTextField == "") {
        swal("Please Enter the UOM Code");
      } else if (this.state.uomDescTextField == "") {
        swal("Please Enter the UOM Description");
      } else {
        this.props.createUomMaster(
          serverApi.CREATE_UOM_MASTER,
          data,
          this.props.history
        );
      }
    } else {
      if (this.state.itemDesc == "") {
        if (isEmpty(this.state.UOM_Code)) {
          swal("Please Enter the Relation UOM");
        } else if (
          isEmpty(this.state.relationValue) &&
          !this.state.variableValue
        ) {
          swal("Please Enter the Value");
        } else {
          const data = {
            uomCode: this.state.uomCodeTextField,
            uomDsc: this.state.uomDescTextField,
            companyID: localStorage.getItem("companyId"),
            recordType: 2,
            createdBy: this.props.location.state.rowData.createdBy,
            relation: this.props.location.state.rowData.id,
            relationValue: this.state.relationValue,
            relationTo: this.state.UOM_Code,
            activityFlag: 1,
            variable: this.state.variable ? 1 : 0,
          };
          this.props.createUomMaster(
            serverApi.UPDATE_UOM_MASTER,
            data,
            this.props.history
          );
        }
      } else {
        if (isEmpty(this.state.UOM_Code)) {
          swal("Please Enter the Relation UOM");
        } else if (
          isEmpty(this.state.relationValue) &&
          !this.state.variableValue
        ) {
          swal("Please Enter the Value");
        } else {
          const data = {
            uomCode: this.state.uomCodeTextField,
            uomDsc: this.state.uomDescTextField,
            companyID: localStorage.getItem("companyId"),
            recordType: 3,
            createdBy: this.props.location.state.rowData.createdBy,
            relation: this.props.location.state.rowData.id,
            relationValue: this.state.relationValue,
            relationTo: this.state.UOM_Code,
            activityFlag: 1,
            itemDesc: this.state.itemDesc,
            itemId: this.state.itemId,
            variable: this.state.variable ? 1 : 0,
          };
          this.props.createUomMaster(
            serverApi.UPDATE_UOM_MASTER,
            data,
            this.props.history
          );
          this.setState({
            relationValue: "",
            UOM_Code: "",
          });
        }
      }
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    this.setState({
      locationState: this.props.location,
    });
    window.addEventListener("scroll", this.handleScroll);
    this.props.getItemGroupMasterList(
      serverApi.GET_ALL_ITEMGROUP_MASTER_LIST +
        this.state.companyId +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    ); // Calling Item group master list api
    this.props.getUomMasterList(
      serverApi.GET_ALL_UOM_DD_LIST + 1 + "/companyId/" + this.state.companyId,
      this.props.history
    ); // Calling UOm master List for API

    if (this.props.location.state) {
      // alert("update page")
      this.props.getUomRelation(
        serverApi.GET_UOM_RELATION +
          "/" +
          this.props.location.state.rowData.uomCode +
          "/" +
          "companyId" +
          "/" +
          localStorage.getItem("companyId") +
          "/" +
          "itemId" +
          "/" +
          0,
        this.props.history
      );
    }
  }
  componentWillReceiveProps(props) {
    if (props.create_uom_master) {
      this.setState({
        create_uom_master_data: props.create_uom_master.data, // updating create uom master list
      });
    }
    if (props.uomList) {
      if (props.uomList.data) {
        this.setState({
          uomList: props.uomList.data, // updating uomList
        });
      }
    }
    if (props.itemGroupMasterList) {
      if (props.itemGroupMasterList.data) {
        this.setState({
          itemMasterListData: props.itemGroupMasterList.data, // updating item master list data
        });
      }
    }
    if (this.props.itemsListForIndent) {
      let itemsList = this.props.itemsListForIndent; //updating item list
      this.setState({
        itemsList: itemsList,
      });
    }

    if (props.itemsListForIndent) {
      this.setState({
        GetItem: props.itemsListForIndent,
      });
    }

    if (props.uom_relation_data.data) {
      let list = [];
      props.uom_relation_data.data.map((prop) => {
        list.push({
          itemDesc: prop.itemDesc,
          relationValue: prop.relationValue,
          relation: prop.relationToUom,
          recordType: prop.recordType,
          relationUom: prop.relationUom,
          itemId: prop.itemId,
          id: prop.id,
        });
      });
      // this.state.products.push(list)

      var recordtypetwo = list.filter((data) => data.recordType < 3);

      this.setState({
        products: recordtypetwo,
      });
      if (this.state.itemId) {
        this.setState({
          products: list,
        });
      }
    }

    if (props.location.state) {
      this.state.uomCodeTextField = props.location.state.rowData.uomCode;
      this.state.uomDescTextField = props.location.state.rowData.uomDsc;

      if (props.create_uom_master.data) {
        var product = {
          relationValue: props.create_uom_master.data.relationValue,
          relation: props.create_uom_master.data.relationTo,
          itemDesc: props.create_uom_master.data.itemDesc,
        };
        this.state.products.push(product);
      }

      this.setState({
        uomCodeTextField: props.location.state.rowData.uomCode,
        uomDescTextField: props.location.state.rowData.uomDsc,
      });
    }
  }

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    const mcolumns = [
      {
        id: 1,
        label: "Item Description",
      },
      {
        id: 2,
        label: "Relation",
      },
      {
        id: 3,
        label: "Relation Value",
      },
      {
        id: 4,
        label: "Action",
      },
    ];

    return (
      <div className="uomMasterContainer">
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        value={this.state.uomCodeTextField}
                        onChange={this.onhandlechangeValue("uomCode")}
                        label="UOM Code"
                        required
                        disabled={
                          this.state.locationState.state !== undefined
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        value={this.state.uomDescTextField}
                        onChange={this.onhandlechangeValue("uomDescription")}
                        label="UOM Description"
                        required
                        disabled={
                          this.state.uomCodeTextField === "" ? true : false
                        }
                      />
                    </div>
                  </Box>
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            arrayOfData={
                              this.state.itemMasterListData
                                ? this.state.itemMasterListData.map((item) => ({
                                    label: item.label,
                                    value: item.value,
                                    name: item.name,
                                  }))
                                : ""
                            }
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            isDropdownOpen={this.state.isDropdownOpen}
                            handleMenuOpen={this.handleMenuOpen}
                            selected={this.state.itemDesc}
                            name="item_Group"
                            label="Item Group"
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            arrayOfData={this.state.GetItem}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            isDropdownOpen={this.state.isDropdownOpen}
                            handleMenuOpen={this.handleMenuOpen}
                            selected={this.state.itemId}
                            name="item"
                            label="Item"
                            isDisabled={isEmpty(this.state.itemDesc)}
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            arrayOfData={
                              this.state.uomList
                                ? this.state.uomList.map((item) => ({
                                    label: item.uomCode,
                                    value: item.id,
                                    name: item.uomCode,
                                  }))
                                : ""
                            }
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            isDropdownOpen={this.state.isDropdownOpen}
                            handleMenuOpen={this.handleMenuOpen}
                            selected={this.state.UOM_Code}
                            name="relation_uom"
                            label="Relation Uom"
                            required
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <label>
                            UOM <div className="mandatoryField">*</div>
                          </label>
                          <div className="taxPayableContent">
                            <RadioButton
                              value={this.state.variable}
                              labelText="variable"
                              checkednew={this.state.variableValue}
                              onChange={this.onHandleChange}
                            />
                            <RadioButton
                              value={this.state.variable}
                              checkednew={this.state.fixedValue}
                              onChange={this.onHandleChange}
                              labelText="fixed"
                            />
                          </div>
                        </div>
                      </Box>

                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <TextFieldNormal
                            value={this.state.relationValue}
                            onChange={this.onhandlechangeValue("relationValue")}
                            label="Value"
                            disabled={
                              this.state.uomDescTextField === "" ||
                              isEmpty(this.state.UOM_Code)
                                ? true
                                : false
                            }
                            placeholder="Relation value"
                            required={!this.state.variableValue}
                          />
                        </div>
                      </Box>

                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <div className="TextFieldBlock">
                            <label>&nbsp;</label>
                          </div>
                          <CustomButton
                            label={"Save Relation"}
                            className="greenButton"
                            handleClick={this.onClickCreateUpdate}
                            type="sumbit"
                          />
                        </div>
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        {this.state.locationState.state === undefined ? (
          ""
        ) : (
          <>
            <div className="consoleFormContainer">
              <h5>Relation Table</h5>
            </div>

            <UomRelationTable mcolumns={mcolumns} mdata={this.state.products} />
          </>
        )}
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.state.locationState.state !== undefined ? (
            ""
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { create_uom_master, uom_relation_data, loading } =
    state.UomListReducer; // fetching create and uom_relation from uomListReducer
  const { indentList } = state.Indent;
  const { itemsListForIndent } = state.DropDownListReducer; // fetching itemListForIndent from global DropdownListReducer
  const { uomList } = state.DropDownListReducer; // fetching uomList from global DropdownListReducer
  const { itemGroupMasterList } = state.itemGroupMasterList;
  return {
    create_uom_master,
    uomList,
    itemGroupMasterList,
    indentList,
    itemsListForIndent,
    uom_relation_data,
    loading,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    createUomMaster,
    getUomMasterList,
    getItemGroupMasterList,
    getItemsListForIndent,
    getUomRelation,
  })(CreateUom)
);
