/* Created By Nagesh Medisetty on 19-04-2022 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import Link from "@mui/material/Link";
import CustomButton from "../../../components/Buttons/Buttons";
import TextFieldAlfaNum from "../../../components/TextField/TextFieldAlfaNum";
import InputAdormentButton from "../../../components/InputAdorment/InputAdormentButton";
import searchIcon from "../../../assets/images/search.png";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";

class StockReturnFillDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      expense_type: 0,
      expense_name: 0,
      branch_type: 0,
      branch_name: 0,
      project_type: 0,
      project_name: 0,
    };
  }

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "expenseType") {
      this.setState({
        expense_type: selectedValue,
        expense_name: selectedName,
      });
    }
    if (name === "branchName") {
      this.setState({
        branch_type: selectedValue,
        branch_name: selectedName,
      });
    }
    if (name === "projectName") {
      this.setState({
        project_type: selectedValue,
        project_name: selectedName,
      });
    }
    this.props.onSelectDep(selectedValue, selectedName, name);
  };
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  

  render() {
    return (
      <div className="consoleFormContainer">
        <h5>Fill Details</h5>
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div className="TextFieldBlock">
                      <label>Issue No</label>
                    </div>
                    <TextFieldNormal imgUrl={searchIcon} />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>Stock Return Date</label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          name="financial_year"
                          inputFormat="dd.MM.yyyy"
                          id="financial_year"
                          value={this.state.dtvalue}
                          fullWidth
                          onChange={(newValue) => {
                            this.setState({ dtvalue: newValue });
                          }}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={expense_type}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      selected={this.state.expense_type}
                      isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                      name="expenseType"
                      label="Expenses type"
                      required
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={branch_type}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      selected={this.state.branch_type}
                      isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                      name="branchName"
                      label="Branch"
                      required
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={project_type}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      selected={this.state.project_type}
                      isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                      name="projectName"
                      label="Project"
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal type="textarea" label="Internal Note" />
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const expense_type = [
  {
    value: "1",
    label: "Capital",
    name: "Capital",
  },
  {
    value: "2",
    label: "General",
    name: "General",
  },
  {
    value: "3",
    label: "Maintainence",
    name: "Maintainence",
  },
  {
    value: "4",
    label: "Open",
    name: "Open",
  },
  {
    value: "5",
    label: "Production",
    name: "Production",
  },
];
const branch_type = [
  {
    value: "1",
    label: "Ho",
    name: "Ho",
  },
  {
    value: "2",
    label: "Factory",
    name: "Factory",
  },
  {
    value: "3",
    label: "BNG",
    name: "BNG",
  },
  {
    value: "4",
    label: "Kolkatta",
    name: "Kolkatta",
  },
  {
    value: "5",
    label: "Test",
    name: "Test",
  },
];
const project_type = [
  {
    value: 1,
    label: "project1",
    name: "Project 1",
  },
  {
    value: 2,
    label: "project2",
    name: "Project 2",
  },
  {
    value: 3,
    label: "project3",
    name: "Project 3",
  },
];

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};
export default connect(mapStatetoProps, {})(withRouter(StockReturnFillDetails));
