import {
  JUTE_CRAETE_MARKET_RATE_SUCCESSFULL,
  JUTE_CREATE_MARKET_RATE,
  JUTE_MARKET_RATE,
  JUTE_MARKET_RATE_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  JuteMarketRateDataArray: [],
  Create_Jute_Market_Rate: [],
};

const JuteMarketRateReducer = (state = initialState, action) => {
  switch (action.type) {
    case JUTE_MARKET_RATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTE_MARKET_RATE_SUCCESSFULL:
      state = {
        ...state,
        JuteMarketRateDataArray: action.payload.response,
        loading: true,
      };
      break;

    case JUTE_CREATE_MARKET_RATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTE_CRAETE_MARKET_RATE_SUCCESSFULL:
      state = {
        ...state,
        createJuteMarketRate: action.payload.response,
        loading: false,
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default JuteMarketRateReducer;
