//import { Switch } from "react-router-dom";

import {
    API_ERRORS,
    DESIGNATION_MASTER_LIST,CREATE_DESIGNATION_MASTER,UPDATE_DESIGNATION_MASTER,
    DESIGNATION_MASTER_LIST_SUCCESSFULL,CREATE_DESIGNATION_MASTER_SUCCESSFULL,UPDATE_DESIGNATION_MASTER_SUCCESSFULL
  } from "./actionTypes";
  
  const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    Designation_List: [],
    Create_Designation_Master:[],
    Update_Designation_Master:[],
    error_msg: null,
  };
  
  const DesignationListReducer = (state = initialState, action) => {
    switch (action.type) {
      case DESIGNATION_MASTER_LIST:
        state = {
          ...state,
          loading: true,
        };
        break;
      case DESIGNATION_MASTER_LIST_SUCCESSFULL:
        state = {
          ...state,
          Designation_List: action.payload.response,
          loading: false,
        };
        break;
        case CREATE_DESIGNATION_MASTER:
          state = {
            ...state,
            loading: true,
          };
          break;
        case CREATE_DESIGNATION_MASTER_SUCCESSFULL:
          state = {
            ...state,
            Create_Designation_Master: action.payload.response,
            loading: true,
          };
          break;
          case UPDATE_DESIGNATION_MASTER:
          state = {
            ...state,
            loading: true,
          };
          break;
        case UPDATE_DESIGNATION_MASTER_SUCCESSFULL:
          state = {
            ...state,
            Update_Designation_Master: action.payload.response,
            loading: true,
          };
          break;
        case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default DesignationListReducer;
  