export const STOCK_TRANSFER_LIST_SUCCESSFULL = "stockTansfer_successfull";
export const STOCK_TRANSFER_LIST = "stockTansfer_list";
export const API_ERRORS = 'api_failed';
export const COMPANY_ALL_LIST = "company_all_list";
export const COMPANY_ALL_LIST_SUCCESSFULL = "company_all_list_successfull";
export const ALL_BRANCH_LIST = "all_branch_list";
export const ALL_BRANCH_LIST_SUCCESSFULL = "all_branch_list_successfull";
export const GET_WARE_HOUSE_LIST = "get_ware_house_list";
export const GET_WARE_HOUSE_LIST_SUCCESSFULL = "get_ware_house_list_successfull";
export const ADD_STOCK_TRANSFER = "add_stock_transfer";
export const ADD_STOCK_TRANSFER_SUCCESSFULL = "add_stock_transfer_successfull";
