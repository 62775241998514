import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Box, TextField, InputAdornment, Button } from "@mui/material";
import TextFieldNormal from "../../../../components/TextField/TextFieldNormal";
import { getTermsConditions } from "../../../../store/Sales/SalesOrder/actions";
import { serverApi } from "../../../../helpers/Consts";
import DynamicSelect from "../../../../components/Dropdown/DynamicSelect";
import { getJuteGovtDeliveryOrderHeader } from "../../../../store/Sales/DeliveryOrder/actions";

const JuteGovtTransportation = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [SoJuteGovtHeadersData, setHeaderData] = useState({});
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [TermsData, setTermsData] = useState([]);
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const { JuteGovtDeliveryOrderHeader } = useSelector(
    (state) => state.DeliveryOrderReducer
  );
  const { TermsConditions } = useSelector((state) => state.SalesOrderReducer);

  useEffect(() => {
    setHeaderData(JuteGovtDeliveryOrderHeader);
    if (TermsConditions) {
      if (TermsConditions.length !== 0) {
        var list = [];
        TermsConditions.map((item) => {
          list.push({
            value: item.autoId,
            label: item.termsAndConditions,
            name: item.termsAndConditions,
          });
        });
      }
      setTermsData(list);
    }
  }, [TermsConditions, JuteGovtDeliveryOrderHeader]);
  useEffect(() => {
    const data = {
      orgId: userDit.orgId,
      autoId: "",
      termsAndConditions: "",
      startIndex: "",
      lastIndex: "",
    };
    dispatch(getTermsConditions(serverApi.GET_ALL_TERMS_LIST, data, history));
  }, []);
  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };

  const handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "termsCondition") {
      setHeaderData((prev) => ({
        ...prev,
        termsCondition: selectedValue,
        termsConditionName: selectedName,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        termsCondition: selectedValue,
        termsConditionName: selectedName,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
  };
  const onhandlechange = (key) => (event) => {
    if (key === "footerNote") {
      setHeaderData((prev) => ({
        ...prev,
        footerNote: event,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        footerNote: event,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (key === "deliveryDays") {
      setHeaderData((prev) => ({
        ...prev,
        deliveryDays: event,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        deliveryDays: event,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (key === "paymentTerms") {
      setHeaderData((prev) => ({
        ...prev,
        paymentTerms: event.target.value,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        paymentTerms: event.target.value,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (key === "otherCharges") {
      setHeaderData((prev) => ({
        ...prev,
        otherCharges: event,
      }));
    }
    if (key === "otherChargeAmt") {
      setHeaderData((prev) => ({
        ...prev,
        otherChargeAmt: event,
      }));
    }
    if (key === "internalNote") {
      setHeaderData((prev) => ({
        ...prev,
        internalNote: event,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        internalNote: event,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (key === "destinationRailHead") {
      setHeaderData((prev) => ({
        ...prev,
        destinationRailHead: event,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        destinationRailHead: event,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (key === "loadingPoint") {
      setHeaderData((prev) => ({
        ...prev,
        loadingPoint: event,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        loadingPoint: event,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
  };

  return (
    <>
      <div>
        <div className="consoleFormContainer">
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {/* <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Freight Charges"
                      value={SoJuteGovtHeadersData.freightCharges}
                      onChange={onhandlechange("freightCharges")}
                      type="number"
                    />

                  </Box> */}
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Footer Notes"
                      value={SoJuteGovtHeadersData.footerNote}
                      onChange={onhandlechange("footerNote")}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      type="number"
                      label="Delivery Days"
                      value={SoJuteGovtHeadersData.deliveryDays}
                      onChange={onhandlechange("deliveryDays")}
                      disabled={true}
                    />
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>Payment Terms (In Days)</label>
                      </div>
                      <div className="consoleAdormentBlock">
                        <TextField
                          value={SoJuteGovtHeadersData.paymentTerms}
                          onChange={onhandlechange("paymentTerms")}
                          type="number"
                          disabled={true}
                          placeholder="Enter here"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={onclick}>Days</Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelect
                      update={SoJuteGovtHeadersData.termsConditionType ? 1 : 0}
                      arrayOfData={TermsData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={SoJuteGovtHeadersData.termsConditionType}
                      name="termsCondition"
                      label="Terms & Conditions"
                      isDisabled={true}
                    />
                  </Box>
                  {/* <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Other Charges Name"
                      value={HeaderData.otherCharges}
                      onChange={onhandlechange("otherCharges")}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Other Charge Amount"
                      value={HeaderData.otherChargeAmt}
                      onChange={onhandlechange("otherChargeAmt")}
                    />
                  </Box> */}
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Internal Note"
                      value={SoJuteGovtHeadersData.internalNote}
                      onChange={onhandlechange("internalNote")}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Destination RailHead"
                      value={SoJuteGovtHeadersData.destinationRailHead}
                      onChange={onhandlechange("destinationRailHead")}
                      type="text"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Loading Point"
                      value={SoJuteGovtHeadersData.loadingPoint}
                      onChange={onhandlechange("loadingPoint")}
                      type="text"
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default JuteGovtTransportation;
