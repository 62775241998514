import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import { weavingQualityMasterList } from "../../../store/Master/WeavingQualityMaster/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      weavingQualityMasterListData: [], // to display list data
      juteQualityCode: "",
      qualityName: "",
      speed: "",
      ozsYds: "",
      dataexp: "",
    };
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      qualityType: 1,
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
    };
    this.props.weavingQualityMasterList(
      serverApi.GET_ALL_WEAVING_QUALITY_MASTER_LIST,
      data,
      this.props.history
    ); // calling weaving Quality Master list API
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.WeavingQualityMasterList) {
      this.setState({
        totalRecs: props.WeavingQualityMasterList.totalRecords,
      });
      if (props.WeavingQualityMasterList.data) {
        if (props.WeavingQualityMasterList.data.length !== 0) {
          var list = [];
          list = props.WeavingQualityMasterList.data.filter(
            (item, i) => i !== 0
          );
          this.setState({
            weavingQualityMasterListData: list,
          });
        }
      }
    }
  }
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const data = {
        cipher: this.state.userDit.cipher,
        companyId: localStorage.getItem("companyId"),
        userId: this.state.userDit.userId.toString(),
        qualityType: 1,
        startIndex: sizePerPage,
        lastIndex: currentIndex,
        juteQualityCode: this.state.qltyCode,
        qualityName: this.state.qltyName,
        speed: this.state.speed,
        ozsYds: this.state.OunceYrd,
      };
      if (currentIndex >= 0) {
        this.props.weavingQualityMasterList(
          serverApi.GET_ALL_WEAVING_QUALITY_MASTER_LIST,
          data,
          this.props.history
        ); // calling weaving Quality Master list API
      }
    } else {
      const data = {
        cipher: this.state.userDit.cipher,
        companyId: localStorage.getItem("companyId"),
        userId: this.state.userDit.userId.toString(),
        qualityType: 1,
        startIndex: currentIndex === 0 ? currentIndex + 1 : currentIndex,
        lastIndex: sizePerPage,
        juteQualityCode: this.state.qltyCode,
        qualityName: this.state.qltyName,
        speed: this.state.speed,
        ozsYds: this.state.OunceYrd,
      };
      if (currentIndex >= 0) {
        this.props.weavingQualityMasterList(
          serverApi.GET_ALL_WEAVING_QUALITY_MASTER_LIST,
          data,
          this.props.history
        ); // calling weaving Quality Master list API
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  handleSearch = (data) => {
    const searchData = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      startIndex: 1,
      lastIndex: 10,

      juteQualityCode: data.qltyCode,
      qualityName: data.qltyName,
      speed: data.speed,
      ozsYds: data.OunceYrd,
      qualityType: 1,
    };
    this.setState({
      juteQualityCode: data.qltyCode,
      qualityName: data.qltyName,
      speed: data.speed,
      ozsYds: data.OunceYrd,
    });
    this.props.weavingQualityMasterList(
      serverApi.GET_ALL_WEAVING_QUALITY_MASTER_LIST,
      searchData,
      this.props.history
    ); // calling weaving Quality Master list API
  };
  render() {
    return (
      <>
        <PaginationGridView
          mdata={this.state.weavingQualityMasterListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          filter_form={"WeavingQualityMaster"}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          url="/create_weaving_quality_master"
          handleSearch={this.handleSearch}
          apiexp={serverApi.GET_ALL_WEAVING_QUALITY_MASTER_LIST}
          payloadexp={this.state.dataexp}
          expName={"Weaving-Quality-Master.csv"}
        />
      </>
    );
  }
}
// Weaving Quality master list Headers
const Columns = [
  {
    Header: "Quality Code",
    accessor: "qualityCode",
    hidden: false,
  },
  {
    Header: "Quality Name",
    accessor: "qualityName",
    hidden: false,
  },
  {
    Header: "Ends",
    accessor: "ends",
    hidden: false,
  },
  {
    Header: "Speed",
    accessor: "speed",
    hidden: false,
  },
  {
    Header: "Ounce/Yrd",
    accessor: "ozsYds",
    hidden: false,
  },
  {
    Header: "Shots",
    accessor: "shots",
    hidden: false,
  },
];
const mapStatetoProps = (state) => {
  const { WeavingQualityMasterList } = state.WeavingQualityMasterReducer; // fetching list from weavingQuality master reducer
  return { WeavingQualityMasterList };
};
export default withRouter(
  connect(mapStatetoProps, {
    weavingQualityMasterList,
  })(Index)
);
