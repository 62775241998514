import React from "react";
import {Grid} from "@mui/material"
export default class SearchBar extends React.Component {
    handleChange() {
      this.props.onUserInput(this.refs.filterTextInput.value);
    }
    render() {
      return (
        <div>
          <Grid container spacing={2} className="addItemsBlockContainer">
            <Grid item xs={6}>
              {" "}
              <div className="createIndentContainerBlock">
                <h1 className="createIndentBlock">Add Items</h1>
              </div>
            </Grid>
  
            <Grid item xs={6}>
              <input
                type="text"
                placeholder="Search"
                value={this.props.filterText}
                ref="filterTextInput"
                onChange={this.handleChange.bind(this)}
                className="searchBlockContainer"
              />
            </Grid>
          </Grid>
        </div>
      );
    }
  }