

import {
 SPELL_MASTER_LIST,
 SPELL_MASTER_LIST_SUCCESSFULL,
 ADDITEM_TO_SPELL_MASTER_LIST,
 ADDITEM_TO_SPELL_MASTER_LIST_SUCCESSFULL,
 UPDATE_SPELL_MASTER_LIST,
 UPDATE_SPELL_MASTER_LIST_SUCCESSFULL,
 PARENT_SPELL,
 PARENT_SPELL_SUCCESSFULL
  } from "./actionTypes";
  
  const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    SpellMaster_List: [],
    AddItem_To_Spell_master_List:[],
    Update_Spell_master:[],
    error_msg: null,
    parent_spell_data:[]
  };
  
  const SpellMasterListReducer = (state = initialState, action) => {
    switch (action.type) {
      case  SPELL_MASTER_LIST:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case SPELL_MASTER_LIST_SUCCESSFULL:
        state = {
          ...state,
          SpellMaster_List: action.payload.response,
          loading: false,
        };
        break;
        case ADDITEM_TO_SPELL_MASTER_LIST:
          state = {
            ...state,
            loading: true,
          };
          break;
    
        case ADDITEM_TO_SPELL_MASTER_LIST_SUCCESSFULL:
          state = {
            ...state,
            AddItem_To_Spell_master_List: action.payload.response,
            loading: false,
          };
          break;
          case UPDATE_SPELL_MASTER_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
      
          case UPDATE_SPELL_MASTER_LIST_SUCCESSFULL:
            state = {
              ...state,
              Update_Spell_master: action.payload.response,
              loading: false,
            };
            break;
          case PARENT_SPELL:
            state ={
              ...state,
              loading: true,
            }
            break;
          case PARENT_SPELL_SUCCESSFULL:
            state ={
              ...state,
              parent_spell_data:action.payload.response
            }
            break;
      default:
        state = { ...state };
        break;
    }
  
    return state;
  };
  
  export default SpellMasterListReducer;
  