import {COMPANY_MASTER_LIST,COMPANY_MASTER_LIST_SUCCESSFULL,
    CREATE_COMPANY_MASTER,CREATE_COMPANY_MASTER_SUCCESSFULL,
    UPDATE_COMPANY_MASTER,UPDATE_COMPANY_MASTER_SUCCESSFULL,
    API_ERRORS,} from './actionTypes';

export const companyMasterList = (url,data,history) =>{
    return {
        type:COMPANY_MASTER_LIST,
        payload:{url,data,history}
    }
}
export const companyMasterListSuccessfull = (data)=>{
    return {
        type:COMPANY_MASTER_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createCompanyMaster = (url, data, history) => {
    return {
        type: CREATE_COMPANY_MASTER,
        payload: { url, data, history }
    }
}

export const createCompanyMasterSuccessfull = (data) => {
    return {
        type: CREATE_COMPANY_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const updateCompanyMaster = (url, data, history) => {
    return {
        type: UPDATE_COMPANY_MASTER,
        payload: { url, data, history }
    }
}
export const updateCompanyMasterSuccessfull = (data) => {
    return {
        type: UPDATE_COMPANY_MASTER_SUCCESSFULL,
        payload: data
    }
}

export const apiErrors = (error) => {
    return {
        type: API_ERRORS,
        payload: error,
    };
};