import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../../components/Dropdown/DynamicSelect";
import TextFieldNormal from "../../../../components/TextField/TextFieldNormal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { getBankAccountList } from "../../../../store/Sales/DeliveryOrder/actions";
import DynamicSelectNew from "../../../../components/Dropdown/DropdownSelectNew";
import moment from "moment";
import { serverApi } from "../../../../helpers/Consts";
import swal from "sweetalert";
import {
  getBranchList,
  getCustomerList,
  getBillingShippingList,
} from "../../../../store/Global/DropDownApis/actions";
import { getQuotationBroker } from "../../../../store/Sales/Qutation/actions";
import {
  getAllTransporter,
  getDeliveryOrderList,
} from "../../../../store/Sales/DeliveryOrder/actions";
import {
  getJuteYarnInvoiceHeader,
  getSalesOrderViewByIdForJuteYarnInvoice,
  getDeliveryOrderViewByIdForJuteYarnInvoice,
} from "../../../../store/Sales/InVoice/actions";
import { getSoDropDown } from "../../../../store/Sales/DeliveryOrder/actions";
import { isEmpty } from "../../../../Utilities/helper";

const JuteYarnFillDetails = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const location = useLocation();
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [HeaderData, setHeaderData] = useState({});
  const [branchListData, setbranchListData] = useState([]);
  const [brokerData, setbrokerData] = useState([]);
  const [bankListData, setbankListData] = useState([]);
  const [CustomerData, setCustomerData] = useState([]);
  const [billingAddressData, setbillingAddressData] = useState([]);
  const [shippingAddressData, setshippingAddressData] = useState([]);
  const [transporterData, settransporterData] = useState([]);
  const [invoiceData, setinvoiceData] = useState(props.invoiceData);
  const [SoDropdownVal, setSoDropdownVal] = useState([]);
  const [DoDropdownVal, setDoDropdownVal] = useState([]);
  const [backDaysAllowable, setBackDaysAllowable] = useState("");
  const [futureDaysAllowable, setFutureDaysAllowable] = useState("");
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };
  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    dispatch(
      getSoDropDown(
        serverApi.SALES_ORDER_DROPDOWN_FOR_INVOICE +
          "3/" +
          localStorage.getItem("companyId")
      )
    );
    dispatch(
      getBranchList(
        serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
        history
      )
    );
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
    };
    dispatch(getCustomerList(serverApi.GET_ALL_CUSTOMERS, data, history));
    dispatch(
      getBankAccountList(serverApi.GET_ALL_BANK_ACCOUNT_LIST, data, history)
    );
    dispatch(
      getQuotationBroker(
        serverApi.GET_ALL_BROKER_LIST +
          localStorage.getItem("companyId") +
          "/SALE",
        history
      )
    );
    dispatch(
      getAllTransporter(
        serverApi.GET_ALL_TRANSPORTER_MASTER_LIST +
          localStorage.getItem("companyId")
      )
    );
    const currentMonth = moment().format('MM');
    const currentDate = moment().format('DD');
    const fiscalYearStart = moment(`${localStorage.getItem("acadamicYear")}-${currentMonth}-${currentDate}`);
    const today = moment();
    let dateValue;
    if (today.isBefore(fiscalYearStart)) {
      dateValue = today.format("YYYY-MM-DD");
    } else {
      dateValue = fiscalYearStart.format("YYYY-MM-DD");
    }

    if (userDit) {
      userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              if (item.backDaysAllowable !== undefined) {
                let backDaysAllowable = JSON.parse(item.backDaysAllowable);
                let backDays = backDaysAllowable.bdaystype;
                if (Number(backDays) === 1) {
                  backDays = 7;
                } else if (Number(backDays) === 2) {
                  backDays = 14;
                } else if (Number(backDays) === 3) {
                  backDays = "";
                } else if (Number(backDays) === 4) {
                  backDays = backDaysAllowable.otherBackDateValue;
                } else {
                  backDays = backDaysAllowable.bdaystype;
                }
                console.log(dateValue)
                let minDate = moment(dateValue).subtract(
                  Number(backDays),

                  "days"
                );
                let setMinDate = moment(minDate).format("YYYY-MM-DD");
                if(backDays!==""){
                  setBackDaysAllowable(setMinDate);
                }
              }
              if (item.futureDaysAllowable !== undefined) {
                let futureDaysAllowable = JSON.parse(item.futureDaysAllowable);
                let futureDays = futureDaysAllowable.fdaystype;
                if (Number(futureDays) === 1) {
                  futureDays = 7;
                } else if (Number(futureDays) === 2) {
                  futureDays = 14;
                } else if (Number(futureDays) === 3) {
                  futureDays = "";
                } else if (Number(futureDays) === 4) {
                  futureDays = futureDaysAllowable.othervalue;
                } else {
                  futureDays = futureDaysAllowable.fdaystype;
                }
                let maxDate = moment(dateValue).add(
                  Number(futureDays),

                  "days"
                );
                let setMaxDate = moment(maxDate).format("YYYY-MM-DD");
                if(futureDays!==""){
                setFutureDaysAllowable(setMaxDate);
                }
              }
            });
            setHeaderData((prev) => ({
              ...prev,
              // invoiceDate: dateValue,
            }));
            const UpdatedRec = {
              ...JuteYarnInvoiceHeader,
              // invoiceDate: dateValue,
            };
            dispatch(getJuteYarnInvoiceHeader(UpdatedRec));

          }
      });
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const { branchList, customerList, billingShippingList } = useSelector(
    (state) => state.DropDownListReducer
  );
  const { BrokerData } = useSelector((state) => state.QuotationReducer);
  const { JuteYarnInvoiceHeader } = useSelector(
    (state) => state.InvoiceReducer
  );
  const {
    TransporterList,
    SrDropDownData,
    DeliveryOrder_List,
    bankAccountList,
  } = useSelector((state) => state.DeliveryOrderReducer);
  const isEmptyObj = (myObject) => {
    const keys = Object.keys(myObject);
    return keys.length === 0;
  };

  useEffect(() => {
    if (branchList) {
      if (branchList.data) {
        if (branchList.data.length !== 0) {
          let List = branchList.data.filter((item) => item.value !== 0);
          if (List.length !== 0) {
            var BranchList = [];
            List.map((item) => {
              BranchList.push({
                value: item.value,
                name: item.label,
                label: item.label,
              });
            });
          }
          setbranchListData(BranchList);
        }
      }
    }
    if (bankAccountList) {
      if (bankAccountList.data) {
        if (bankAccountList.data.length !== 0) {
          var BankList = [];
          BankList.push(bankSelect);
          bankAccountList.data.data.map((item) => {
            BankList.push({
              value: item.id,
              name:
                item.bankName +
                "^" +
                item.accountNo +
                "^" +
                item.ifscCode +
                "^" +
                item.micrCode +
                "^" +
                item.branch,
              label: item.bankName,
            });
          });

          setbankListData(BankList);
        }
      }
    }
    if (customerList) {
      if (customerList.data) {
        if (customerList.data.length !== 0) {
          var CusList = [];
          customerList.data.map((item) => {
            CusList.push({
              value: item.id,
              name: item.name,
              label: item.name,
            });
          });
          setCustomerData(CusList);
        }
      }
    }
    if (BrokerData) {
      if (BrokerData.data) {
        setbrokerData(BrokerData.data);
      }
    }
    if (billingShippingList) {
      if (billingShippingList.data) {
        if (billingShippingList.data.customerLineitems) {
          if (billingShippingList.data.customerLineitems.length !== 0) {
            var list = [];
            let billingAddressList = [];
            let shippingaddressList = [];
            billingShippingList.data.customerLineitems.map((prop, i) => {
              if (prop.addressType === 2 || prop.addressType === null) {
                if (
                  (prop.customerLineId === JuteYarnInvoiceHeader.shippingTo &&
                    prop.isActive === 0) ||
                  prop.isActive !== 0
                ) {
                  billingAddressList.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
              list.push({
                value: prop.customerLineId,
                label: prop.address,
                name: prop.address,
              });
            }
          }
            if (prop.addressType === 1 || prop.addressType === null || prop.addressType === 2 ) {
              if (
                (prop.customerLineId === JuteYarnInvoiceHeader.shippingTo &&
                  prop.isActive === 0) ||
                prop.isActive !== 0
              ) {
                shippingaddressList.push({
                  value: prop.customerLineId,
                  label: prop.address,
                  name: prop.address,
                });
                list.push({
                  value: prop.customerLineId,
                  label: prop.address,
                  name: prop.address,
                });
              }
            }
          });
            if (JuteYarnInvoiceHeader.customerId !== "") {
              setbillingAddressData(billingAddressList);
              setshippingAddressData(shippingaddressList);
            }
          }
        }
      }
    }
    if (TransporterList) {
      if (TransporterList.data) {
        if (TransporterList.data.data.length !== 0) {
          var TransporterData = [];
          TransporterList.data.data.map((item) => {
            TransporterData.push({
              value: item.transporterId,
              name: item.transporterName,
              label: item.transporterName,
            });
          });
          settransporterData(TransporterData);
        }
      }
    }
    if (DeliveryOrder_List) {
      if (DeliveryOrder_List.data) {
        var List = [];
        if (DeliveryOrder_List.data.length !== 0) {
          DeliveryOrder_List.data.map((item) => {
            List.push({
              value: item.deliveryOrderId,
              label: item.deliveryOrderNumber,
              name: item.deliveryOrderNumber,
            });
          });
          setDoDropdownVal(List);
        } else {
          setDoDropdownVal([]);
        }
      } else {
        setDoDropdownVal([]);
      }
    }
    if (SrDropDownData) {
      if (SrDropDownData.data) {
        var srDropDownData = [];
        if (SrDropDownData.data.length !== 0) {
          SrDropDownData.data.map((item) => {
            srDropDownData.push({
              value: item.id,
              name: item.Value,
              label: item.Value,
            });
          });
          setSoDropdownVal(srDropDownData);
        }
      }
    }
  }, [
    branchList,
    bankAccountList,
    customerList,
    BrokerData,
    billingShippingList,
    TransporterList,
    DeliveryOrder_List,
    SrDropDownData,
  ]);
  useEffect(() => {
    setHeaderData(JuteYarnInvoiceHeader);
  }, [JuteYarnInvoiceHeader]);
  const handleSelect_Date = (e, name) => {
    if (name === "invoiceDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      if (Date < HeaderData.deliveryDate) {
        swal("Invoice Date Shouldn't be less than the Delivery Order Date");
        setHeaderData((prev) => ({
          ...prev,
          invoiceDate: HeaderData.deliveryDate,
        }));
        const UpdatedRec = {
          ...HeaderData,
          invoiceDate: HeaderData.deliveryDate,
        };
        dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
      } else {
        setHeaderData((prev) => ({
          ...prev,
          invoiceDate: Date,
        }));
        const UpdatedRec = {
          ...HeaderData,
          invoiceDate: Date,
        };
        dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
      }
    }
    if (name === "salesOrderDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        salesOrderDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        salesOrderDate: Date,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (name === "pcsoDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        pcsoDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        pcsoDate: Date,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }

    if (name === "deliveryDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        deliveryDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        deliveryDate: Date,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (name === "quotationDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        quotationDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        quotationDate: Date,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (name === "challanDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        challanDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        challanDate: Date,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
  };
  const onhandlechange = (key) => (event) => {
    if (key === "quotationNo") {
      setHeaderData((prev) => ({
        ...prev,
        quotationNo: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        quotationNo: event,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    // if (key === "vehicleNumber") {
    //   setHeaderData((prev) => ({
    //     ...prev,
    //     vehicleNumber: event,
    //   }));
    //   const UpdatedRec = {
    //     ...HeaderData,
    //     vehicleNumber: event,
    //   };
    //   dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    // }
    if (key === "poNumber") {
      setHeaderData((prev) => ({
        ...prev,
        poNumber: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        poNumber: event,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (key === "customerReferenceNumber") {
      setHeaderData((prev) => ({
        ...prev,
        customerReferenceNumber: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        customerReferenceNumber: event,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (key === "billingState") {
      setHeaderData((prev) => ({
        ...prev,
        billingState: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        billingState: event,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (key === "shippingState") {
      setHeaderData((prev) => ({
        ...prev,
        shippingState: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        shippingState: event,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (key === "brokerCommissionPercent") {
      if (event.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
        setHeaderData((prev) => ({
          ...prev,
          brokerCommissionPercent: event,
        }));
        const UpdatedRec = {
          ...HeaderData,
          brokerCommissionPercent: event,
        };
        dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
      } else {
        swal("Please enter a positive number with up to 2 decimal places.");
      }
    }
    if (key === "pcsoNo") {
      setHeaderData((prev) => ({
        ...prev,
        pcsoNo: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        pcsoNo: event,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (key === "containerNo") {
      setHeaderData((prev) => ({
        ...prev,
        containerNo: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        containerNo: event,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
  };
  const handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name == "deliveryTerms") {
      setHeaderData((prev) => ({
        ...prev,
        deliveryTerms: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        deliveryTerms: selectedName,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (name === "regularSalesOrderId") {
      setHeaderData((prev) => ({
        ...prev,
        regularSalesOrderId: selectedValue,
        salesOrderNo: selectedName,
        deliveryOrderId: "",
        deliveryOrderNo: "",
        transporterId: "",
        transporterName: "",
        transporterAddress: "",
        transporterStateCode: "",
        transporterStateName: "",
        brokerCommissionPercent: "",
      }));
      const UpdatedRec = {
        ...HeaderData,
        regularSalesOrderId: selectedValue,
        salesOrderNo: selectedName,
        deliveryOrderId: "",
        deliveryOrderNo: "",
        transporterId: "",
        transporterName: "",
        transporterAddress: "",
        transporterStateCode: "",
        transporterStateName: "",
        brokerCommissionPercent: "",
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
      const data = {
        fromDate: "",
        toDate: "",
        cusId: "",
        branchId: null,
        status: "3",
        companyId: localStorage.getItem("companyId"),
        acYear: localStorage.getItem("acadamicYear"),
        deliveryOrderNo: "",
        type: 3,
        startIndex: 0,
        lastIndex: 0,
        salesOrderNo: selectedName,
      };
      dispatch(
        getDeliveryOrderList(
          serverApi.DELIVERY_ORDER_GET_API,
          data,
          props.history
        )
      );

      dispatch(
        getSalesOrderViewByIdForJuteYarnInvoice(
          serverApi.SALES_ORDER_VIEW_BYID +
            "3/" +
            selectedValue +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).userId,
          history
        )
      );
    }
    if (name == "deliveryOrderId") {
      setHeaderData((prev) => ({
        ...prev,
        deliveryOrderId: selectedValue,
        deliveryOrderNo: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        deliveryOrderId: selectedValue,
        deliveryOrderNo: selectedName,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));

      dispatch(
        getDeliveryOrderViewByIdForJuteYarnInvoice(
          serverApi.DELIVERY_ORDER_VIEW_BY_ID +
            "3/" +
            selectedValue +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).userId,
          history
        )
      );
    }
    if (name == "invoiceType") {
      setHeaderData((prev) => ({
        ...prev,
        invoiceType: selectedValue,
        invoiceTypeDesc: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        invoiceType: selectedValue,
        invoiceTypeDesc: selectedName,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (name == "branchId") {
      setHeaderData((prev) => ({
        ...prev,
        branchId: selectedValue,
        branchName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        branchId: selectedValue,
        branchName: selectedName,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (name == "brokerId") {
      setHeaderData((prev) => ({
        ...prev,
        brokerId: selectedValue,
        brokerName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        brokerId: selectedValue,
        brokerName: selectedName,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (name == "customerId") {
      setHeaderData((prev) => ({
        ...prev,
        customerId: selectedValue,
        customerName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        customerId: selectedValue,
        customerName: selectedName,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));

      dispatch(
        getBillingShippingList(
          serverApi.GET_ALL_BILLING_SHIPPING +
            selectedValue +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).cipher +
            "?isForSalesModule=1",
          history
        )
      );
    }
    if (name == "billingFrom") {
      setHeaderData((prev) => ({
        ...prev,
        billingFrom: selectedValue,
        billingFromAddress: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        billingFrom: selectedValue,
        billingFromAddress: selectedName,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (name == "shippingFrom") {
      setHeaderData((prev) => ({
        ...prev,
        shippingFrom: selectedValue,
        shippingFromAddress: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        shippingFrom: selectedValue,
        shippingFromAddress: selectedName,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (name == "billingTo") {
      setHeaderData((prev) => ({
        ...prev,
        billingTo: selectedValue,
        billingAddress: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        billingTo: selectedValue,
        billingAddress: selectedName,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (name == "shippingTo") {
      setHeaderData((prev) => ({
        ...prev,
        shippingTo: selectedValue,
        shippingAddress: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        shippingTo: selectedValue,
        shippingAddress: selectedName,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (name == "taxType") {
      setHeaderData((prev) => ({
        ...prev,
        taxType: selectedValue,
        taxName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        taxType: selectedValue,
        taxName: selectedName,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (name === "bankId") {
      var Data = selectedName.split("^", 5);
      setHeaderData((prev) => ({
        ...prev,
        bankId: selectedValue,
        bankName: selectedName,
        accountNo: selectedValue === 0 ? "" : Data[1],
        ifscCode: selectedValue === 0 ? "" : Data[2],
        micrCode: selectedValue === 0 ? "" : Data[3],
        bankBranch: selectedValue === 0 ? "" : Data[4],
      }));
      const UpdatedRec = {
        ...HeaderData,
        bankId: selectedValue,
        bankName: selectedName,
        accountNo: Data[1],
        ifscCode: Data[2],
        micrCode: Data[3],
        bankBranch: Data[4],
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (name == "gstInvoiceType") {
      setHeaderData((prev) => ({
        ...prev,
        gstInvoiceType: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        gstInvoiceType: selectedName,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
    if (name == "transporterId") {
      setHeaderData((prev) => ({
        ...prev,
        transporterName: selectedName,
        transporterId: selectedValue,
      }));
      const UpdatedRec = {
        ...HeaderData,
        transporterName: selectedName,
        transporterId: selectedValue,
      };
      dispatch(getJuteYarnInvoiceHeader(UpdatedRec));
    }
  };
  return (
    <>
      <div>
        <div className="consoleFormContainer">
          <h5>Jute Yarn Fill Details </h5>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      {props.locationState.state == undefined ? (
                        <DynamicSelect
                          update={HeaderData.regularSalesOrderId ? 1 : 0}
                          arrayOfData={SoDropdownVal}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={handlePropSelectChange}
                          isDropdownOpen={isDropdownOpen}
                          handleMenuOpen={handleMenuOpen}
                          selected={HeaderData.regularSalesOrderId}
                          label="Sales Order"
                          name="regularSalesOrderId"
                          required={true}
                        />
                      ) : (
                        <TextFieldNormal
                          label="Sales Order"
                          value={HeaderData.salesOrderNumber}
                          onChange={onhandlechange("salesOrderNumber")}
                          disabled={true}
                        />
                      )}
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      {props.locationState.state == undefined ? (
                        <DynamicSelect
                          update={HeaderData.deliveryOrderId ? 1 : 0}
                          arrayOfData={DoDropdownVal}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={handlePropSelectChange}
                          isDropdownOpen={isDropdownOpen}
                          handleMenuOpen={handleMenuOpen}
                          selected={HeaderData.deliveryOrderId}
                          label="Delivery Order Number"
                          name="deliveryOrderId"
                          required={true}
                        />
                      ) : (
                        <TextFieldNormal
                          label="Delivery Order Number"
                          value={HeaderData.deliveryOrderNo}
                          onChange={onhandlechange("deliveryOrderNo")}
                          disabled={true}
                        />
                      )}
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.invoiceType ? 1 : 0}
                        arrayOfData={invoiceData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.invoiceType}
                        label="Invoice Type"
                        name="invoiceType"
                        isDisabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Sales Order Date{" "}
                            <span className="starcolor">*</span>
                          </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            disabled={true}
                            name="salesOrderDate"
                            inputFormat="dd-MM-yyyy"
                            id="salesOrderDate"
                            value={HeaderData.salesOrderDate}
                            fullWidth
                            onChange={(e) =>
                              handleSelect_Date(e, "salesOrderDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                                style={{
                                  backgroundColor: "#ccd6db",
                                }}
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  style={{
                                    backgroundColor: "#ccd6db",
                                  }}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Delivery Order Date{" "}
                            <span className="starcolor">*</span>
                          </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            disabled={true}
                            name="deliveryDate"
                            inputFormat="dd-MM-yyyy"
                            id="deliveryDate"
                            value={HeaderData.deliveryDate}
                            fullWidth
                            onChange={(e) =>
                              handleSelect_Date(e, "deliveryDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                                style={{
                                  backgroundColor: "#ccd6db",
                                }}
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  style={{
                                    backgroundColor: "#ccd6db",
                                  }}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Invoice Date<span className="starcolor">*</span>
                          </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="invoiceDate"
                            inputFormat="dd-MM-yyyy"
                            id="invoiceDate"
                            minDate={
                              backDaysAllowable !== ""
                                ? backDaysAllowable
                                :HeaderData.invoiceDate
                            }
                            maxDate={
                              futureDaysAllowable !== ""
                                ? futureDaysAllowable
                                : HeaderData.invoiceDate
                            }
                            value={HeaderData.invoiceDate}
                            fullWidth
                            onChange={(e) =>
                              handleSelect_Date(e, "invoiceDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.branchId ? 1 : 0}
                        arrayOfData={branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.branchId}
                        label="Branch"
                        name="branchId"
                        required
                        isDisabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.customerId ? 1 : 0}
                        arrayOfData={CustomerData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.customerId}
                        label="Customer"
                        name="customerId"
                        required
                        isDisabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.brokerId ? 1 : 0}
                        arrayOfData={brokerData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.brokerId}
                        label="Broker"
                        name="brokerId"
                        ErrorOutlineIcon={true}
                        toolTipTitle={
                          "Broker or Consignee is mandetory Select At least one and Proceed"
                        }
                        isDisabled={
                          // props.SalesOrderMandatory == true &&
                          // HeaderData.createWithDirectInvoice
                          //   ? true
                          //   : false
                          true
                        }
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.billingFrom ? 1 : 0}
                        arrayOfData={branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.billingFrom}
                        label="Bill From"
                        name="billingFrom"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.shippingFrom ? 1 : 0}
                        arrayOfData={branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.shippingFrom}
                        label="Ship From"
                        name="shippingFrom"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.billingTo ? 1 : 0}
                        arrayOfData={billingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.billingTo}
                        label="Billing To"
                        name="billingTo"
                        required
                        isDisabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.shippingTo ? 1 : 0}
                        arrayOfData={shippingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.shippingTo}
                        label="Shipping To"
                        name="shippingTo"
                        required
                        isDisabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Billing State"
                      value={HeaderData.billingState}
                      onChange={onhandlechange("billingState")}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Shipping State"
                      value={HeaderData.shippingState}
                      onChange={onhandlechange("shippingState")}
                      disabled={true}
                    />
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.taxType ? 1 : 0}
                        arrayOfData={taxTypeData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.taxType}
                        label="Tax type"
                        name="taxType"
                        required
                        isDisabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelectNew
                      update={HeaderData.deliveryTerms ? 1 : 0}
                      arrayOfData={DeliveryTermsData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.deliveryTerms}
                      name="deliveryTerms"
                      label="Delivery Terms"
                      isDisabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      {props.locationState.state == undefined ? (
                        <DynamicSelect
                          update={HeaderData.bankId ? 1 : 0}
                          arrayOfData={bankListData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={handlePropSelectChange}
                          isDropdownOpen={isDropdownOpen}
                          handleMenuOpen={handleMenuOpen}
                          selected={HeaderData.bankId}
                          name="bankId"
                          label="Bank Name"
                          required
                          isDisabled={true}
                        />
                      ) : (
                        <TextFieldNormal
                          label="Bank Name"
                          value={HeaderData.bankName}
                          onChange={onhandlechange("bankName")}
                          disabled={true}
                          required
                        />
                      )}
                    </div>
                  </Box>
                  {/* <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Vehicle No"
                      value={HeaderData.vehicleNumber}
                      onChange={onhandlechange("vehicleNumber")}
                      caps="ON"
                    />
                  </Box> */}
                  <Box gridColumn="span 3">
                    <DynamicSelect
                      update={HeaderData.transporterId ? 1 : 0}
                      arrayOfData={transporterData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.transporterId}
                      label="Transporter Name"
                      name="transporterId"
                      isDisabled={true}
                      required={true}
                    />
                  </Box>

                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Transporter Address"
                      value={HeaderData.transporterAddress}
                      onChange={onhandlechange("transporterAddress")}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Transporter State Name"
                      value={HeaderData.transporterStateName}
                      onChange={onhandlechange("transporterStateName")}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Transporter State Code"
                      value={HeaderData.transporterStateCode}
                      onChange={onhandlechange("transporterStateCode")}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      // required={(HeaderData.brokerId !== "" && HeaderData.brokerId !==0) ? true : false}
                      type="number"
                      label="Broker %"
                      value={HeaderData.brokerCommissionPercent}
                      onChange={onhandlechange("brokerCommissionPercent")}
                      disabled={
                        // isEmpty(HeaderData.brokerId)?true:false
                        true
                      }
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};
const taxTypeData = [
  // {
  //   value: 0,
  //   name: "Select...",
  //   label: "Select...",
  // },
  {
    value: 1,
    name: "IGST",
    label: "IGST",
  },
  {
    value: 2,
    name: "CGST & SGST",
    label: "CGST & SGST",
  },
];
const GstInvoiceTypeData = [
  {
    value: 1,
    name: "B2B",
    label: "B2B",
  },
  {
    value: 2,
    name: "B2C",
    label: "B2C",
  },
  {
    value: 3,
    name: "Export",
    label: "Export",
  },
];
const DeliveryTermsData = [
  {
    value: 1,
    label: "Ex-Works",
    name: "Ex-Works",
  },
  {
    value: 2,
    label: "Delivery to Destination",
    name: "Delivery to Destination",
  },
];
const bankSelect = {
  value: "",
  name: "Select",
  label: "Select",
};

export default JuteYarnFillDetails;
