import React, { Component } from "react";
import { Grid, Box, Button, Tooltip } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi, serverConfig } from "../../../helpers/Consts";
import TextArea from "../../../components/TextField/TextArea";
import {
  InwardLineItemsProps,
  getFilesofMISuccessfull,
} from "../../../store/Purchase/Inward/actions";
import Fade from "@mui/material/Fade";
import CustomButton from "../../../components/Buttons/Buttons";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import { getFileDownload } from "../../../helpers/server.js";
import { getFilesofInward } from "../../../store/Purchase/Inward/actions";
import {
  getPendingStockPoandReceipt,
  getPendingStock,
  actions,
  logs,
  getFiles,
} from "../../../store/Purchase/Indent/actions";
import {
  postUploadFileList,
  deleteFile,
} from "../../../store/MyProfile/UploadFile/actions";
import { getTax_List } from "../../../store/Global/DropDownApis/actions";
import DeleteIcons from "../../../assets/images/picEditIcon.png";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import moment from "moment";
import swal from "sweetalert";
import { isEmpty, limitDecimals } from "../../../Utilities/helper.js";

class MaterialInspecLineItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      rowCount: 0,
      amount: 0,
      itemsList: [],
      taxList: [],
      files_List: [],
      fileName: "",
      fileType: "",
      lineItemId: 1,
      delete: false,
      deletedId: "",
      Inward_LineItems: this.props.Inward_LineItems,
      Logs: [],
      DepartmentList: [],
      termsNconditions: [],
      ItemGroupMasterList: [],
      parentBudgetHeadsList: [],
      internalNote: this.props.InwardFillDetails.internalNote,
      igstPercentage: 0,
      cgstPercentage: 0,
      sgstPercentage: 0,
      AdvancePercentage: this.props.InwardFillDetails.AdvancePercentage,
      AdvanceAmount: this.props.InwardFillDetails.AdvanceAmount,
      termsNcondition: this.props.InwardFillDetails.termsNcondition,
      selectTermsNconditions:
        this.props.InwardFillDetails.selectTermsNconditions,
      AllItemGroupMasterList: [],
      uploadFile: false,
      seeLogs: false,
      locationState: "",
    };
  }

  componentDidMount() {
    this.setState({
      locationState: this.props.location,
    });

    if (this.props.Upload_File_List) {
      if (this.props.locationState.state) {
        let id = this.props.locationState.state.rowData.id;
        this.props.getFilesofInward(
          serverApi.GET_FILES +
            18 +
            "/" +
            id +
            "/" +
            localStorage.getItem("companyId"),
          this.props.history,
          18,
          "MI"
        );
      }
    }
  }

  componentWillReceiveProps(props) {
    if (props.taxList) {
      this.setState({
        taxList: props.taxList.data,
      });
    }

    if (props.Inward_LineItems) {
      this.setState({
        Inward_LineItems: props.Inward_LineItems,
      });
    }

    if (this.props.InwardFillDetails) {
      let taxType = this.props.InwardFillDetails.taxType;
      if (taxType === 1 || taxType === 3) {
        this.setState({
          igstPercentage: 1,
          cgstPercentage: 0,
          sgstPercentage: 0,
        });
      } else {
        this.setState({
          igstPercentage: 0,
          cgstPercentage: 1,
          sgstPercentage: 1,
        });
      }
    }
    if (props.logs_List) {
      this.setState({
        Logs: props.logs_List.data,
      });
    }

    if (this.state.seeLogs === false) {
      if (props.mifilesList) {
        if (props.mifilesList.data) {
          let filesList = props.mifilesList.data;
          this.setState({
            files_List: filesList.data,
          });
        }
      }
    }
    if (this.state.uploadFile === true) {
      if (this.state.fileName !== "") {
        if (props.Upload_File_List) {
          var Filedata = props.Upload_File_List.data;
          if (props.Upload_File_List.status) {
            this.setState({
              uploadFile: false,
            });
            if (this.state.files_List !== undefined) {
              var arr = this.state.files_List;
              Filedata = {
                fileUploadId: Filedata.id,
                fileName: this.state.fileName,
                fileExtension: this.state.fileType,
                retrievalPath: Filedata.fileUrl,
                sourceMenu: 18,
              };
              arr.splice(arr.length, 0, Filedata);
              this.setState({
                files_List: arr,
              });
            }
          }
        }
      }
    }
  }

  handleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    this.setState({ [key]: event });
    if (key === "Reason") {
      row.reason = value;
    }
    if (key === "qty") {
      row.inwardQty = Number(value);
      row.amount = Number(row.rate) * Number(row.inwardQty);
    }
    if (key === "Rate") {
      row.rate = Number(value);
      row.amount = Number(row.rate) * Number(row.inwardQty);
    }
    if (key === "Approved Quantity") {
      let Val = value.match(/^(\d*\.{0,1}\d{0,3}$)/);
      if (Val) {
        let inwardDetails = this.state.Inward_LineItems.filter((data) => {
          if (data.id === row.id) {
            if (row.inwardQty < Number(value)) {
              data.approvedQty = 0;
              swal(
                "Approved quantity cannot be more then Quantity " +
                  row.inwardQty
              );
            } else {
              data.approvedQty = Number(value);
              data.rejectedQty = limitDecimals(Number(row.inwardQty) - Number(value),3);
            }
          }
          return data;
        });
        this.setState({
          Inward_LineItems: inwardDetails,
        });

        this.props.InwardLineItemsProps(inwardDetails);
      } else {
        swal("Can't Enter more than 3 Decimals!");
      }
    }
    if (key === "Rejected Quantity") {
      let Val = value.match(/^(\d*\.{0,1}\d{0,3}$)/);
      if (Val) {
        let inwardDetails = this.state.Inward_LineItems.filter((data) => {
          if (data.id === row.id) {
            if (Number(value) === 0 || value === "") {
              data.rejectedQty = 0;
              data.approvedQty = Number(row.inwardQty);
              data.reason = "";
            } else if (row.inwardQty < Number(value)) {
              swal(
                "Approved quantity cannot be more then Quantity " +
                  row.inwardQty
              );
              data.rejectedQty = 0;
              data.approvedQty = Number(row.inwardQty);
              data.reason = "";
            } else {
              data.rejectedQty = Number(value);
              data.approvedQty = limitDecimals(Number(row.inwardQty) - Number(value),3);
            }
          }
          return data;
        });
        this.setState({
          Inward_LineItems: inwardDetails,
        });
        this.props.InwardLineItemsProps(inwardDetails);
      } else {
        swal("Can't Enter more than 3 Decimals!");
      }
    }
  };

  getHandleApQty = (inwardQty, aprQty) => {
    if (aprQty > inwardQty) {
      return 0;
    } else {
      return aprQty;
    }
  };

  onClickToSeeLogs = () => {
    this.setState({
      uploadFile: false,
      seeLogs: true,
    });
    if (this.props.locationState.state) {
      let id = this.props.locationState.state.rowData.id;
      this.props.logs(serverApi.SEE_THE_LOGSOF_INWARD + id, this.props.history);
    }
  };

  handleChangetextAreas = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "internalNote") {
      this.props.InwardFillDetails.internalNote = event;
      this.setState({
        internalNote: event,
      });
    }
  };
  handleChangedropdown = (selectedValue, selectedName, name, selectedRow) => {
    if (name === "Tax") {
      selectedRow.tax = selectedValue;
      let taxPercentage = selectedName.split("^");
      selectedRow.taxPercentage = taxPercentage[1];
      selectedRow.cgstPercentage = Number(taxPercentage[1] / 2);
      selectedRow.sgstPercentage = Number(taxPercentage[1] / 2);
      if (this.props.InwardFillDetails.taxType === 1) {
        selectedRow.igstPercentage = Number(taxPercentage[1]);
      }
    }
    if (name === "Reason") {
      let Inward_LineItems = this.state.Inward_LineItems.filter((row) => {
        if (selectedRow.id === row.id) {
          row.reason = selectedValue;
        }
        return row;
      });
      this.setState({
        Inward_LineItems: Inward_LineItems,
      });
      this.props.InwardLineItemsProps(Inward_LineItems);
    }
  };

  onDelEvent = (event) => {
    this.setState({ delete: true, deletedId: event.target.name });
  };

  onDel = (event) => {
    this.setState({ delete: false });
    if (event.target.value === "Yes") {
      if (this.state.Inward_LineItems.length > 1) {
        let lineItemId = this.state.deletedId;
        var object = this.state.Inward_LineItems.filter((row) => {
          if (row.lineitemId != lineItemId) {
            return row;
          }
        });

        this.setState({
          Inward_LineItems: object,
          lineItemId: lineItemId,
        });
        this.props.InwardLineItemsProps(object);
      }
    }
  };

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "termsNconditions") {
      this.props.InwardFillDetails.termsNconditions = selectedName;
      this.props.InwardFillDetails.selectTermsNconditions = selectedName;
      this.setState({
        termsNconditions: selectedName,
        selectTermsNconditions: selectedName,
      });
    }
  };

  onhandleChange = (key) => (value) => {
    this.setState({ [key]: value });
    if (key === "advance_per") {
      this.props.PoFillDetails.AdvancePercentage = value;
      this.setState({
        AdvancePercentage: value,
      });
    }
    if (key === "advance_amt") {
      this.props.PoFillDetails.AdvanceAmount = value;
      this.setState({
        AdvanceAmount: value,
      });
    }
  };
  UploadDocuments = (key) => (e) => {
    if (this.state.locationState.state) {
      var inwardId = this.state.locationState.state.rowData.id;
    }
    e.preventDefault();
    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 18);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", inwardId);
    formData.append("createdBy", this.state.userDit.userId);
    formData.append("fileCompanyId", localStorage.getItem("companyId"));
    this.setState({
      fileName: file.name,
      fileType: fileExt,
      uploadFile: true,
    });
    this.props.postUploadFileList(
      serverApi.UPLOADFILE,
      formData,
      this.props.history
    );
  };

  onDeleteFile = (fileId) => {
    let data = this.state.files_List.filter(
      (item) => item.fileUploadId !== fileId
    );
    this.props.deleteFile(serverApi.DELETEFILE + fileId);
    this.setState({
      files_List: data,
    });
    this.props.getFilesofMISuccessfull({ data });
  };

  //   filteredproducts = filteredproducts.sort((a, b) =>
  //   a.lineitemId > b.lineitemId ? 1 : -1
  // );

  // handleKeyPress = (event) => {
  //   // Prevent 'e' and '-' characters
  //   if (event.key === "e" || event.key === "-" || event.key === "E") {
  //     event.preventDefault();
  //   }
  // };

  render() {
    let DepartmentList = this.state.DepartmentList;
    let AllItemGroupMasterList = this.state.AllItemGroupMasterList;

    var filteredproducts = this.state.Inward_LineItems.filter(
      (item) => item.isActive !== 0
    );
    let Inward_LineItems = filteredproducts.map((items) => items);
    // This is to display the Mandotary icon for the Reason for Rejection* column
    let markAsMandotary = filteredproducts.some((items) => {
      if (items.rejectedQty > 0) {
        return true;
      }
    });

    var totalAmount = 0;
    var totalIgst = 0;
    var totalCgst = 0;
    var totalSgst = 0;
  
    var IndividualAmount = 0;
    var totalCgstValue = 0;
    if (Inward_LineItems) {
      Inward_LineItems.forEach((data) => {
        if (data.approvedQty && data.rate) {
          totalAmount =
            totalAmount +
            (data.approvedQty * data.rate);
            if (!isEmpty(data.discountMode)) {
            let amount = Number(data.approvedQty) * Number(data.rate);
              if (Number(data.discountMode) === 1 ){
              if (Number(data.discountPercentage) !== 0 && !isEmpty(data.discountPercentage)
              ) {

                IndividualAmount = amount - Number( data.discountPercentage)
                totalAmount = totalAmount - Number( data.discountPercentage);               
              }
            } else if (data.discountMode === 2){
              if (
                Number(data.discountPercentage) !== 0 && !isEmpty(data.discountPercentage)

              ) {
                let disAmount = (amount * Number(data.discountPercentage)) / 100;
                IndividualAmount = amount - disAmount
                totalAmount = totalAmount - disAmount;
                
              }
            }
          }
         
        }
        if (Number(this.props.InwardFillDetails.taxType) === 4) {
          totalIgst = 0;
          totalCgst = 0;
          totalSgst = 0;
        } else {
          if (this.props.InwardFillDetails.taxType === 1) {
            let amountWithDiscount = 0
            if (
              !isEmpty(data.discountMode)
              ) {
              if (data.discountMode === 1) {
                amountWithDiscount =
                  (data.approvedQty * data.rate) -
                  Number(data.discountPercentage);
                totalIgst = totalIgst + ((amountWithDiscount) * data.taxPercentage) / 100;
              } else if (data.discountMode === 2) {
                amountWithDiscount = ((data.approvedQty * data.rate) *
                  Number(data.discountPercentage)) /
                  100;
                amountWithDiscount = (data.approvedQty * data.rate) - amountWithDiscount
                totalIgst = totalIgst + ((amountWithDiscount) * data.taxPercentage) / 100;
              } else {
                amountWithDiscount = (data.approvedQty * data.rate) - amountWithDiscount
                totalIgst = totalIgst + ((amountWithDiscount) * data.taxPercentage) / 100;
              }
            } else {
            
              totalIgst = totalIgst + ((
                data.approvedQty * data.rate) * Number(data.taxPercentage)) / 100;
            }

          } else {
            let amountWithDiscount = 0
            if (
              !isEmpty(data.discountMode)
              ) {
              if (data.discountMode === 1) {
                amountWithDiscount =
                  (data.approvedQty * data.rate) -
                  Number(data.discountPercentage);
                
                totalCgst = totalCgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
                totalSgst = totalSgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;

              } else if (data.discountMode === 2) {
                amountWithDiscount = ((data.approvedQty * data.rate) *
                  Number(data.discountPercentage)) /
                  100;
                amountWithDiscount = (data.approvedQty * data.rate) - amountWithDiscount
                totalCgst = totalCgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
                totalSgst = totalSgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
              } else {
                amountWithDiscount = (data.approvedQty * data.rate) - amountWithDiscount
                totalCgst = totalCgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
                totalSgst = totalSgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
              }
            }
            else{
              amountWithDiscount =(data.approvedQty * data.rate) 
                totalCgst = totalCgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
                totalSgst = totalSgst + (((amountWithDiscount) * data.taxPercentage) / 100) / 2;
            }
          }
        }
      });


    }
    totalIgst = totalIgst.toFixed(2);
    totalCgst = totalCgst.toFixed(2);
    totalSgst = totalSgst.toFixed(2); 
    totalAmount = totalAmount.toFixed(2);
    
    var netTotal =
      Number(totalAmount) +
      Number(totalCgst) +
      Number(totalIgst) +
      Number(totalSgst);

    this.props.InwardFillDetails.totalAmount = netTotal

    return (
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent">
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {tableHeaders.map((column) => (
                    // <th>{column.header}</th>
                    <>
                      {column.header === "Reason for Rejection" ? (
                        <th>
                          {column.header}
                          {markAsMandotary && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                        </th>
                      ) : (
                        <th>{column.header}</th>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Inward_LineItems.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <>
                      {RowData &&
                        RowData.map((col) =>
                          col.component === "EditableCell" ? (
                            <>
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        type={
                                          col.type === "Approved Quantity" ||
                                          col.type === "Rejected Quantity"
                                            ? "number"
                                            : "text"
                                        }
                                        onKeyDown={
                                          col.type !== "qty" &&
                                          col.type !== "Unit" &&
                                          col.type !== "Reason"
                                            ? (e) =>
                                                [
                                                  "e",
                                                  "E",
                                                  "+",
                                                  "-",
                                                ].includes(e.key) &&
                                                e.preventDefault()
                                            : () => {}
                                        }
                                        name={col.type}
                                        id={col.no}
                                        value={
                                          col.type === "qty"
                                            ? row.itemGroup === "997"
                                              ? "NA"
                                              : row.inwardQty
                                              ? parseFloat(
                                                  row.inwardQty
                                                ).toFixed(3)
                                              : parseFloat(row.qty).toFixed(3)
                                            : col.type === "Unit"
                                            ? row.uomCode
                                              ? row.uomCode
                                              : row.uom
                                            : col.type === "Approved Quantity"
                                            ? row.itemGroup === "997"
                                              ? 1
                                              : row.approvedQty !== null
                                              ? // this.getHandleApQty(row.inwardQty,row.approvedQty)
                                                row.approvedQty > row.inwardQty
                                                ? 0
                                                : row.approvedQty
                                              : row.inwardQty
                                            : col.type === "Rejected Quantity"
                                            ? row.rejectedQty
                                              ? row.rejectedQty
                                              : ""
                                            : col.type === "Reason"
                                            ? row.reason
                                            : ""
                                        }
                                        onChange={this.handleChange({
                                          key: col.type,
                                          row,
                                        })}
                                        onWheel={(e) => e.target.blur()}
                                        className="inputBlockContainer"
                                        style={{
                                          textAlign:
                                            col.type === "qty" ||
                                            col.type === "Approved Quantity" ||
                                            col.type === "Rejected Quantity"
                                              ? "right"
                                              : "",
                                          paddingRight: "8px",
                                        }}
                                        readOnly={
                                          col.type === "qty"
                                            ? true
                                            : col.type === "Unit"
                                            ? true
                                            : ""
                                        }
                                        // onKeyPress={this.handleKeyPress}
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            </>
                          ) : (
                            <td>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {col.type === "Reason" ? (
                                      <DynamicSelect
                                        arrayOfData={
                                          col.type === "Reason"
                                            ? ReasonTypes
                                            : []
                                        }
                                        className="dropdownBlockContainer"
                                        onSelectChange={
                                          this.handleChangedropdown
                                        }
                                        selected={
                                          col.type === "Reason"
                                            ? row.reason
                                            : ""
                                        }
                                        name={col.type}
                                        row={row}
                                        update={row.reason ? 1 : 0}
                                      />
                                    ) : (
                                      <input
                                        type="text"
                                        name={col.type}
                                        id={col.no}
                                        readOnly
                                        value={
                                          col.type === "department"
                                            ? row.departmentName
                                            : col.type === "itemGroup"
                                            ? row.itemGroupName
                                            : col.type === "item"
                                            ? row.itemName
                                            : col.type === "Tax"
                                            ? row.taxName
                                            : col.type === "BudgetHead"
                                            ? row.budgetHeadName
                                            : col.type === "Reason"
                                            ? row.reason
                                            : ""
                                        }
                                        className="inputBlockContainer"
                                        style={{
                                          textAlign:
                                            col.type === "Status"
                                              ? "right"
                                              : "",
                                          paddingRight: "8px",
                                          width:
                                            col.type === "itemGroup" ||
                                            col.type === "item"
                                              ? "300px"
                                              : "",
                                        }}
                                        onChange={this.handleChange({
                                          key: col.type,
                                          row,
                                        })}
                                        onWheel={(e) => e.target.blur()}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          )
                        )}
                    </>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <>
            <div className="consoleFormContainer">
              <Grid container spacing={2}>
                <Grid item xs={12} className="personalDetailsUpload">
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                    className="ducumentUploadBlock"
                  >
                    <Box gridColumn="span 6">
                      <div className="consoleFormContainer">
                        <div className="consoleFormBlock filesUploadedBlock">
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={2}
                              >
                                <Box gridColumn="span 12">
                                  <div className="consoleTextFieldBlock remarksBlock">
                                    <TextArea
                                      label="Internal Note"
                                      value={this.state.internalNote}
                                      onChange={this.handleChangetextAreas(
                                        "internalNote"
                                      )}
                                    />
                                  </div>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Box>
                    {this.props.locationState.state && (
                      <Box gridColumn="span 6">
                        <div className="consoleFormContainer">
                          <div className="consoleFormBlock filesUploadedBlock">
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Box
                                  display="grid"
                                  gridTemplateColumns="repeat(12, 1fr)"
                                  gap={2}
                                  className="ducumentUploadBlock uploadedContent"
                                >
                                  <Box gridColumn="span 6">
                                    <label>Support Documents:</label>
                                    <div className="personalDetailsUpload">
                                      <div className="ducumentUploadBlock">
                                        <div className="documentUploadContent">
                                          <span className="btn_upload documentUpload">
                                            <img src={panUploadIcon} alt="" />
                                            <input
                                              type="file"
                                              id="imag"
                                              title=""
                                              className="input-img"
                                              onChange={this.UploadDocuments()}
                                            />
                                            Upload Document
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Box
                                  display="grid"
                                  gridTemplateColumns="repeat(12, 1fr)"
                                  gap={2}
                                  className="ducumentUploadBlock uploadedContent"
                                >
                                  {this.state.files_List &&
                                    this.state.files_List.map((item) => (
                                      <>
                                        <Box gridColumn="span 6">
                                          <div>
                                            {/* {item.fileName} */}
                                            <div
                                              className="uploadedInfo"
                                              style={{ display: "flex" }}
                                            >
                                              <img
                                                src={TickIcon}
                                                alt=""
                                                className="tickIcon"
                                                height={"20px"}
                                                width={"20px"}
                                              />
                                              <Tooltip
                                                TransitionComponent={Fade}
                                                TransitionProps={{
                                                  timeout: 600,
                                                }}
                                                title="Click to download File"
                                              >
                                                <div
                                                  className="uplodedFileName"
                                                  onClick={() => {
                                                    getFileDownload(
                                                      serverConfig.SERVER_URL +
                                                        "security-api/api/files/downloadfile/" +
                                                        item.fileUploadId,
                                                      item.fileName,
                                                      item.fileExtension
                                                    );
                                                  }}
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {item.fileName}
                                                </div>
                                              </Tooltip>
                                              <Tooltip
                                                TransitionComponent={Fade}
                                                TransitionProps={{
                                                  timeout: 100,
                                                }}
                                                title="Click to Delete File"
                                              >
                                                <Button
                                                  onClick={() => {
                                                    this.onDeleteFile(
                                                      item.fileUploadId
                                                    );
                                                  }}
                                                  className="deleteButton"
                                                >
                                                  <img
                                                    src={DeleteIcons}
                                                    alt=""
                                                    height={"20px"}
                                                    width={"20px"}
                                                  />
                                                </Button>
                                              </Tooltip>
                                            </div>
                                          </div>
                                        </Box>
                                      </>
                                    ))}
                                </Box>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Box>
                <Grid spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                      style={{ marginTop: "15px" }}
                    >
                      <Box gridColumn="span 3">
                        <div className="">
                          <CustomButton
                            label={"CLICK HERE TO SEE THE LOGS"}
                            className="greenButton"
                            handleClick={this.onClickToSeeLogs}
                            type="sumbit"
                          />
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="logsContainer">
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 6">
                        {this.state.Logs &&
                          this.state.Logs.map((item) => {
                            let date = moment(item.actionDate)
                              .format("DD-MM-YYYY")
                              .split("T")
                              .toString();
                            let time = moment(item.actionDate)
                              .format()
                              .split("T", 2);
                            return (
                              <ul>
                                <li className="logsContent">
                                  <strong>{date}</strong>
                                  <span> </span>
                                  {time[1]}
                                  {/* {moment(item.actionDate).format().split("T", 2).toString()} */}
                                  <span> </span>
                                  {item.remarks}
                                </li>
                              </ul>
                            );
                          })}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </>
        </div>
      </div>
    );
  }
}

const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "department",
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "BudgetHead",
  },

  {
    no: 3,
    component: "DynamicSelect",
    type: "itemGroup",
  },
  {
    no: 4,
    component: "DynamicSelect",
    type: "item",
  },
  {
    no: 5,
    component: "EditableCell",
    type: "qty",
  },
  {
    no: 6,
    component: "EditableCell",
    type: "Unit",
  },
  {
    no: 8,
    component: "EditableCell",
    type: "Approved Quantity",
  },
  {
    no: 9,
    component: "EditableCell",
    type: "Rejected Quantity",
  },
  {
    no: 10,
    component: "EditableCell",
    type: "Reason",
  },
];
const tableHeaders = [
  {
    header: "Department",
  },
  {
    header: "Budget Head",
  },
  {
    header: "Item Group",
  },
  {
    header: "Item",
  },
  {
    header: "Quantity",
  },
  {
    header: "Unit",
  },
  {
    header: "Approved Quantity",
  },
  {
    header: "Rejected Quantity",
  },
  {
    header: "Reason for Rejection",
  },
];

const ReasonTypes = [
  { label: "Select", value: null },
  { label: "Damage", value: "Damage" },
  { label: "Defect", value: "Defect" },
  { label: "Shortage", value: "Shortage" },
  {
    label: "Not as per order specification",
    value: "Not as per order specification",
  },
  { label: "Quality Issue", value: "Quality Issue" },
  { label: "Close to expire", value: "Close to expire" },
  { label: "Expired", value: "Expired" },
];

const mapStatetoProps = (state) => {
  const { itemsListForIndent } = state.itemGroupMasterList;
  const { Inward_LineItems, mifilesList } = state.InwardReducer;
  const { taxList } = state.taxList;
  const { files_List, logs_List } = state.IndentReducer;
  const { Upload_File_List } = state.UploadFileReducer;
  const { departmentList, allItemGroupMaster_List, parentBudgetHeadsList } =
    state.DropDownListReducer;

  return {
    itemsListForIndent,
    Inward_LineItems,
    departmentList,
    allItemGroupMaster_List,
    parentBudgetHeadsList,
    taxList,
    files_List,
    mifilesList,
    Upload_File_List,
    logs_List,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    InwardLineItemsProps,
    postUploadFileList,
    deleteFile,
    actions,
    logs,
    getFiles,
    getFilesofInward,
    getFilesofMISuccessfull,
    getPendingStockPoandReceipt,
    getPendingStock,
    getTax_List,
  })(MaterialInspecLineItems)
);
