import {LEDGER_MASTER_LIST,LEDGER_MASTER_LIST_SUCCESSFULL,
  API_ERRORS,
  CREATE_LEDGER_MASTER, CREATE_LEDGER_MASTER_SUCCESSFULL, UPDATE_LEDGER_MASTER_SUCCESSFULL, UPDATE_LEDGER_MASTER,} from './actionTypes';

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    LedgerMasterList: [],
    Create_Ledger_Master:[],
    Update_Ledger_Master:[],
  };

  const LedgerReducer = (state = initialState, action) => {
    switch (action.type) {
        case LEDGER_MASTER_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case LEDGER_MASTER_LIST_SUCCESSFULL:
            state = {
              ...state,
              LedgerMasterList: action.payload.response,
              loading: true,
            };
            break;
            case CREATE_LEDGER_MASTER:
              state = {
                ...state,
                loading: true,
              };
              break;
            case CREATE_LEDGER_MASTER_SUCCESSFULL:
              state = {
                ...state,
                Create_Ledger_Master: action.payload.response,
                loading: true,
              };
              break;
              case UPDATE_LEDGER_MASTER:
              state = {
                ...state,
                loading: true,
              };
              break;
            case UPDATE_LEDGER_MASTER_SUCCESSFULL:
              state = {
                ...state,
                Update_Ledger_Master: action.payload.response,
                loading: true,
              };
              break;
              case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default LedgerReducer;