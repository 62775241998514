import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import { getBankAccountList } from "../../../store/Master/BankAccountMaster/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      bankAccountListData: [], // to display bank account list data
      bankName: "",
      accountantName: "",
      accountNo: "",
      ifscCode: "",
      dataexp: "",
    };
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
    };
    this.props.getBankAccountList(
      serverApi.GET_ALL_BANK_ACCOUNT_LIST,
      data,
      this.props.history
    ); // calling bank account list api
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.BankAccountList) {
      this.setState({
        totalRecs: props.BankAccountList.totalRecords,
        bankAccountListData: props.BankAccountList.data, // updating bank account list data
      });
    }
  }
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const data = {
        companyId: localStorage.getItem("companyId"),
        cipher: this.state.userDit.cipher,
        startIndex: sizePerPage,
        lastIndex: currentIndex,
        bankName: this.state.bankName == "" ? null : this.state.bankName,
        accountantName:
          this.state.accountantName == "" ? null : this.state.accountantName,
        accountNo: this.state.accountNo == "" ? null : this.state.accountNo,
        ifscCode: this.state.ifscCode == "" ? null : this.state.ifscCode,
      };
      if (currentIndex >= 0) {
        this.props.getBankAccountList(
          serverApi.GET_ALL_BANK_ACCOUNT_LIST,
          data,
          this.props.history
        ); // calling pagination api for bank account master
      }
    } else {
      const data = {
        companyId: localStorage.getItem("companyId"),
        cipher: this.state.userDit.cipher,
        startIndex: currentIndex == 0 ? currentIndex + 1 : currentIndex,
        lastIndex: sizePerPage,
        bankName: this.state.bankName == "" ? null : this.state.bankName,
        accountantName:
          this.state.accountantName == "" ? null : this.state.accountantName,
        accountNo: this.state.accountNo == "" ? null : this.state.accountNo,
        ifscCode: this.state.ifscCode == "" ? null : this.state.ifscCode,
      };
      if (currentIndex >= 0) {
        this.props.getBankAccountList(
          serverApi.GET_ALL_BANK_ACCOUNT_LIST,
          data,
          this.props.history
        ); // calling Pagination api for bank accounts master
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };

  handleSearch = (searchData) => {
    var data = {
      companyId: searchData.companyId,
      cipher: searchData.cipher,
      bankName: searchData.bankName == "" ? null : searchData.bankName,
      accountantName:
        searchData.accountantName == "" ? null : searchData.accountantName,
      accountNo: searchData.accountNo == "" ? null : searchData.accountNo,
      ifscCode: searchData.ifscCode == "" ? null : searchData.ifscCode,
      startIndex: 1,
      lastIndex: 10,
    };
    this.setState({
      bankName: searchData.bankName == "" ? null : searchData.bankName,
      accountantName:
        searchData.accountantName == "" ? null : searchData.accountantName,
      accountNo: searchData.accountNo == "" ? null : searchData.accountNo,
      ifscCode: searchData.ifscCode == "" ? null : searchData.ifscCode,
    });
    this.props.getBankAccountList(
      serverApi.GET_ALL_BANK_ACCOUNT_LIST,
      data,
      this.props.history
    ); // calling bank account list api
  };

  render() {
    return (
      <>
        <PaginationGridView
          mdata={this.state.bankAccountListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={false}
          actions={true}
          filter_form={"BankAccounts"}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          url="create_Bank_Accounts"
          handleSearch={this.handleSearch}
          apiexp={serverApi.GET_ALL_BANK_ACCOUNT_LIST}
          payloadexp={this.state.dataexp}
          expName={"CteateBankAccount-Master.csv"}
        />
      </>
    );
  }
}
// bank account list header
const Columns = [
  {
    Header: "Bank Name",
    accessor: "bankName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Account Holder Name",
    accessor: "accountantName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Account Number",
    accessor: "accountNo",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "IFSC Code",
    accessor: "ifscCode",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "MICR Code",
    accessor: "micrCode",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Account Type",
    accessor: "accountType",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Branch",
    accessor: "branch",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
];
const mapStatetoProps = (state) => {
  const { BankAccountList } = state.BankAccountListReducer; // fetching list from bank account list reducer
  return { BankAccountList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getBankAccountList,
  })(Index)
);
