import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Box, TextField, InputAdornment, Button } from "@mui/material";
import TextFieldNormal from "../../../../components/TextField/TextFieldNormal";
import TextFieldCharNums from "../../../../components/TextField/TextFieldCharNums";
import { SalesOrderJuteGovtHeaders } from "../../../../store/Sales/SalesOrder/actions";
import { getTermsConditions } from "../../../../store/Sales/SalesOrder/actions";
import { serverApi } from "../../../../helpers/Consts";
import DynamicSelect from "../../../../components/Dropdown/DropdownSelectNew";

const JuteGovtTransportation = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [SoJuteGovtHeadersData, setHeaderData] = useState({});
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [TermsData, setTermsData] = useState([]);
  const { SoJuteGovtHeaders } = useSelector((state) => state.SalesOrderReducer);
  const { TermsConditions } = useSelector((state) => state.SalesOrderReducer);

  useEffect(() => {
    setHeaderData(SoJuteGovtHeaders);
    if (TermsConditions) {
      if (TermsConditions.length !== 0) {
        var list = [];
        TermsConditions.map((item) => {
          list.push({
            value: item.autoId,
            label: item.termsAndConditions,
            name: item.termsAndConditions,
          });
        });
      }
      setTermsData(list);
    }
  }, [TermsConditions, SoJuteGovtHeaders]);
  useEffect(() => {
    const data = {
      orgId: userDit.orgId,
      autoId: "",
      termsAndConditions: "",
      startIndex: "",
      lastIndex: "",
    };
    dispatch(getTermsConditions(serverApi.GET_ALL_TERMS_LIST, data, history));
  }, []);
  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };

  const handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "termsCondition") {
      setHeaderData((prev) => ({
        ...prev,
        termsConditionType: selectedValue,
        termsCondition: selectedName,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        termsConditionType: selectedValue,
        termsCondition: selectedName,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
  };
  const onhandlechange = (key) => (event) => {
    if (key === "footerNote") {
      setHeaderData((prev) => ({
        ...prev,
        footerNote: event,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        footerNote: event,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
    if (key === "deliveryDays") {
      const deliveryDaysRegex = /^\d+$/;
      if (deliveryDaysRegex.test(event)) {
        // Update the state only if the input is valid
        setHeaderData((prev) => ({
          ...prev,
          deliveryDays: event,
        }));
        const UpdatedRec = {
          ...SoJuteGovtHeadersData,
          deliveryDays: event,
        };
        dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
      } else {
        // Update the state only if the input is valid
        if (event === "") {
          setHeaderData((prev) => ({
            ...prev,
            deliveryDays: "",
          }));
          const UpdatedRec = {
            ...SoJuteGovtHeadersData,
            deliveryDays: "",
          };
          dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
        }
      }
    }
    if (key === "paymentTerms") {
      setHeaderData((prev) => ({
        ...prev,
        paymentTerms: event.target.value,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        paymentTerms: event.target.value,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
    if (key === "otherCharges") {
      setHeaderData((prev) => ({
        ...prev,
        otherCharges: event,
      }));
    }
    if (key === "otherChargeAmt") {
      setHeaderData((prev) => ({
        ...prev,
        otherChargeAmt: event,
      }));
    }
    if (key === "internalNote") {
      setHeaderData((prev) => ({
        ...prev,
        internalNote: event,
      }));
      const UpdatedRec = {
        ...SoJuteGovtHeadersData,
        internalNote: event,
      };
      dispatch(SalesOrderJuteGovtHeaders(UpdatedRec));
    }
  };
  return (
    <>
      <div>
        <div className="consoleFormContainer">
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Footer Notes"
                      value={SoJuteGovtHeadersData.footerNote}
                      onChange={onhandlechange("footerNote")}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldCharNums
                      label="Delivery Days"
                      value={SoJuteGovtHeadersData.deliveryDays}
                      onChange={onhandlechange("deliveryDays")}
                    />
                  </Box>
                  
                    <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>Payment Terms (In Days)</label>
                      </div>
                      <div className="consoleAdormentBlock">
                      <TextField
                        value={SoJuteGovtHeadersData.paymentTerms}
                        onChange={onhandlechange("paymentTerms")}
                        type="number"
                          placeholder="Enter here"
                          fullWidth
                          onWheel={(e) => e.target.blur()} 
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={onclick}>Days</Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>  
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelect
                      update={SoJuteGovtHeadersData.termsCondition ? 1 : 0}
                      arrayOfData={TermsData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={SoJuteGovtHeadersData.termsCondition}
                      name="termsCondition"
                      label="Terms & Conditions"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Internal Note"
                      value={SoJuteGovtHeadersData.internalNote}
                      onChange={onhandlechange("internalNote")}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default JuteGovtTransportation;
