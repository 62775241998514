import React, { Component } from "react";
import { Box, Grid, Button, Tooltip, IconButton } from "@mui/material";
import Fade from "@mui/material/Fade";
import DynamicSelect from "../../StoreModule/MaterialRequest/DynamicSelect";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi, serverConfig } from "../../../helpers/Consts";
import { getFileDownload } from "../../../helpers/server.js";
import {
  getPendingStockPoandReceipt,
  getPendingStock,
  actions,
  logs,
  getFiles,
  getFilesSuccessfull,
  IndentLineItemsProps,
  getItemsListforIndent,
  getItemGroupListforindent,
  getItemsListByItemGroupId,
  getItemdetailsByItemId,
} from "../../../store/Purchase/Indent/actions";
import TextArea from "../../../components/TextField/TextArea";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "../../../components/Buttons/Buttons";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import {
  postUploadFileList,
  deleteFile,
} from "../../../store/MyProfile/UploadFile/actions";
import {
  getItemGroupList,
  getDepartmentList,
  getItemsListForIndent,
  getSubBudgetHeadsList,
  BudgetHeads,
} from "../../../store/Global/DropDownApis/actions";
import DeleteIcons from "../../../assets/images/picEditIcon.png";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import swal from "sweetalert";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import ReactTooltip from "react-tooltip";
import { QtyCalculation } from "../../../Utilities/QtyCalculation";

class BOQLineItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCount: 0,
      indentLineItems: [],
      itemsList: [],
      totalAmt: 0,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      indentId: "",
      internalRemarks: this.props.indentFillDetails.internalRemarks,
      remarks: this.props.indentFillDetails.remarks,
      submitRemarks: "",
      indentWithMultiDepartments: "",
      Logs: [],
      files_List: [],
      uploadedFiles: [],
      ItemGroupList: [],
      DepartmentList: [],
      budgetHeadsList: [],
      subBudgetHeadsList: [],
      fileName: "",
      fileType: "",
      lineitemId: 1,
      addlineItemId: 0,
      uploadFile: false,
      seeLogs: false,
      isDropdownOpen: false,
      BudgetHeadDisplayTxtField: {},
      BudgetHeadDisplayDropField: {},
      locationState: "",
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    if (this.props.location.state !== undefined) {
      if (
        this.props.indentLineItems &&
        this.props.indentLineItems !== undefined
      ) {
        this.setState({
          indentLineItems: this.props.indentLineItems,
        });
      } else {
        let IndentLineItems = [
          {
            id: 1,
            qty: "0.000",
            uomCode: "",
            qohCompany: "",
            qohBranch: 0,
            qohProject: 0,
            rate: "0.000",
            rateSource: 1,
            isActive: 1,
            remarks: "",
            itemGroup: this.props.indentFillDetails.itemGroup,
            itemGroupValue: this.props.indentFillDetails.itemGroupValue,
            departmentName: this.props.indentFillDetails.departmentName,
            departmentId: this.props.indentFillDetails.departmentId,
            budgetHeadName: this.props.indentFillDetails.budget_Head,
            budgetHeadId: this.props.indentFillDetails.budget_Head_Value,
            itemList: [],
            departmentList: [],
            itemGroupList: [],
            subExpenseTypeName: null,
            selectedExpenseType: null,
            selectedSubExpenseType: null,
            itemId: "",
            lastPurchaseDate: "",
            lastPurchaseRate: 0,
            qtyPo: "",
            amount: "0.00",
            make: "",
          },
        ];
        this.props.IndentLineItemsProps(IndentLineItems);
      }
    } else {
      if (
        this.props.indentLineItems &&
        this.props.indentLineItems !== undefined
      ) {
        this.setState({
          indentLineItems: this.props.indentLineItems,
        });
      } else {
        let IndentLineItems = [
          {
            id: 1,
            qty: "0.000",
            uomCode: "",
            qohCompany: "",
            qohBranch: 0,
            qohProject: 0,
            rate: "0.000",
            rateSource: 1,
            isActive: 1,
            remarks: "",
            itemGroup: this.props.indentFillDetails.itemGroup,
            itemGroupValue: this.props.indentFillDetails.itemGroupValue,
            departmentName: this.props.indentFillDetails.departmentName,
            departmentId: this.props.indentFillDetails.departmentId,
            budgetHeadName: this.props.indentFillDetails.budget_Head,
            budgetHeadId: this.props.indentFillDetails.budget_Head_Value,
            itemList: [],
            departmentList: [],
            itemGroupList: [],
            subExpenseTypeName: null,
            selectedExpenseType: null,
            selectedSubExpenseType: null,
            itemId: "",
            lastPurchaseDate: "",
            lastPurchaseRate: "0.000",
            qtyPo: "",
            amount: "0.00",
            make: "",
          },
        ];
        this.props.IndentLineItemsProps(IndentLineItems);
      }
    }

    if (this.props.locationState.state !== undefined) {
      let id = this.props.locationState.state.rowData.id;
      this.props.getFiles(
        serverApi.GET_FILES +
          "10/" +
          id +
          "/" +
          localStorage.getItem("companyId"),
        this.props.history
      );
    }

    if (this.props.Upload_File_List) {
      if (this.props.location.state) {
        // let id = this.props.location.state.rowData.id;
        // this.props.getFiles(
        //   serverApi.GET_FILES +
        //   "10/" +
        //   id +
        //   "/" +
        //   localStorage.getItem("companyId"),
        //   this.props.history
        // );
      }
    }
    if (this.state.userDit) {
      this.state.userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              if (item.indentWithMultiDepartments !== undefined) {
                this.setState({
                  indentWithMultiDepartments: item.indentWithMultiDepartments,
                });
              }
            });
          }
      });
    }
  }

  componentWillReceiveProps(props) {
    if (props.indentLineItems) {
      this.setState({
        indentLineItems: props.indentLineItems,
      });
    }
    if (props.location.state) {
      let id = props.location.state.rowData.id;
      this.setState({
        indentId: id,
      });
    }

    if (props.departmentList) {
      this.setState({
        DepartmentList: props.departmentList.data,
      });
    }
    //budgetHeads
    if (props.budgetHeadsList.data) {
      if (props.budgetHeadsList.data !== undefined) {
        if (props.budgetHeadsList.data.length !== 0) {
          let budgetHeadsList = props.budgetHeadsList.data.filter((item) => {
            if (item.value !== 0 && item.value !== "0") {
              return item;
            }
          });
          this.setState({
            budgetHeadsList: budgetHeadsList,
          });
          this.props.indentFillDetails.budgetHeadsList = budgetHeadsList;
        }
      }
    }
    if (props.itemGroupsForIndent) {
      this.setState({
        ItemGroupList: props.itemGroupsForIndent,
      });
    }
    if (props.subBudgetHeadsList) {
      this.setState({
        subBudgetHeadsList: props.subBudgetHeadsList.data,
      });
    }

    if (props.itemsListForIndent) {
      var list = [];
      props.itemsListForIndent.map((prop) => {
        list.push({
          label: prop.label,
          name: prop.name,
          value: prop.value,
          variant: prop.itemCode,
        });
      });
      let itemsList = list.filter((item) => {
        if (item.value !== 0 && item.value !== "0") {
          return item;
        }
      });
      this.setState({
        itemsList: itemsList,
      });
    }

    if (props.logs_List) {
      this.setState({
        Logs: props.logs_List.data,
      });
    }
    if (this.state.seeLogs === false) {
      if (props.files_List) {
        if (props.files_List.data) {
          let filesList = props.files_List.data;
          this.setState({
            files_List: filesList.data,
          });
        }
      }
    }
    if (this.state.uploadFile === true) {
      if (this.state.fileName !== "") {
        if (props.Upload_File_List) {
          var Filedata = props.Upload_File_List.data;
          if (props.Upload_File_List.status) {
            this.setState({
              uploadFile: false,
            });
            if (this.state.files_List !== undefined) {
              var arr = this.state.files_List;
              Filedata = {
                fileUploadId: Filedata.id,
                fileName: this.state.fileName,
                fileExtension: this.state.fileType,
                retrievalPath: Filedata.fileUrl,
                sourceMenu: 2,
              };
              arr.splice(arr.length, 0, Filedata);
              this.setState({
                files_List: arr,
              });
            }
          }
        }
      }
    }
    if (props.pendingStock) {
      if (props.pendingStock.data !== null) {
        if (props.pendingStock.id) {
          let indentLineItemsObj = this.state.indentLineItems.filter(
            (rowItem) => {
              if (rowItem.id !== 0 && props.pendingStock.id == rowItem.itemId) {
                rowItem.qohBranch = props.pendingStock.data;
              }
              return rowItem;
            }
          );
        }
      }
    }
    if (props.pendingStockPoandReceipt) {
      if (props.pendingStockPoandReceipt.data !== null) {
        if (props.pendingStockPoandReceipt.id) {
          let indentLineItemsObj = this.state.indentLineItems.filter(
            (rowItem) => {
              if (props.pendingStockPoandReceipt.id == rowItem.itemId) {
                rowItem.qohCompany = props.pendingStockPoandReceipt.data;
              }
              return rowItem;
            }
          );
        }
      }
    }
  }
  // function for input onchange
  handleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    this.setState({ [key]: event });
    let mname = key.split("_");
    if (mname[0] === "remarks") {
      let indentLineItemsObj = this.state.indentLineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.remarks = value;
        }
        return rowItem;
      });
      this.setState({
        indentLineItems: indentLineItemsObj,
      });
      this.props.IndentLineItemsProps(indentLineItemsObj);
    }

    if (mname[0] === "itemQty") {
      if (Number(value) >= 0) {
        if (!value.match(/^\d{1,3}\.\d{4}$/)) {
          let indentLineItemsObj = this.state.indentLineItems.filter(
            (rowItem) => {
              if (row.id === rowItem.id) {
                row.qty = value;
                row.amount = row.rate * row.qty;
              }
              return rowItem;
            }
          );
          this.setState({
            indentLineItems: indentLineItemsObj,
          });
          this.props.IndentLineItemsProps(indentLineItemsObj);
        } else {
          swal("Can't Enter more than 3 Decimals!");
        }
      }
    }
    if (mname[0] === "Make") {
      let indentLineItemsObj = this.state.indentLineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          row.make = value;
          row.amount = row.rate * row.qty;
        }
        return rowItem;
      });

      this.setState({
        indentLineItems: indentLineItemsObj,
      });
      this.props.IndentLineItemsProps(indentLineItemsObj);
    }
    if (mname[0] === "installationRate") {
      if (Number(value) >= 0) {
        if (!value.match(/^\d{1,3}\.\d{4}$/)) {
          let indentLineItemsObj = this.state.indentLineItems.filter(
            (rowItem) => {
              if (row.id === rowItem.id) {
                row.installationRate = value;
                row.amount = (row.rate + Number(value)) * row.qty;
              }
              return rowItem;
            }
          );

          this.setState({
            indentLineItems: indentLineItemsObj,
          });
          this.props.IndentLineItemsProps(indentLineItemsObj);
        } else {
          swal("Can't Enter more than 3 Decimals!");
        }
      }
    }
  };

  handleBlur = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    this.setState({ [key]: event });
    let mname = key.split("_");
    if (mname[0] === "itemQty") {
      if (Number(value) >= 0) {
        if (!value.match(/^\d{1,3}\.\d{4}$/)) {
          let indentLineItemsObj = this.state.indentLineItems.filter(
            (rowItem) => {
              if (row.id === rowItem.id) {
                if (
                  this.props.indentFillDetails.categoryTypeValue !== "7" &&
                  this.props.indentFillDetails.categoryTypeValue !== "4"
                ) {
                  let minqty = row.min === undefined ? 0 : Number(row.min);
                  let maxqty = row.max === undefined ? 0 : Number(row.max);
                  let qty = Number(value);
                  let reOrderQty =
                    row.reOrderqty === undefined ? 0 : Number(row.reOrderqty);
                  let branchqty = Number(row.qohBranch);
                  let centralqty = Number(row.qohCompany);
                  let poqty = Number(row.qty);
                  let updatetype =
                    this.props.location.state !== undefined ? 0 : 1;
                  let type = "CASE1";
                  let resultQty = QtyCalculation({
                    type,
                    minqty,
                    maxqty,
                    qty,
                    reOrderQty,
                    branchqty,
                    centralqty,
                    updatetype,
                    poqty,
                  });

                  if (resultQty.error === true) {
                    swal(resultQty.message);
                    row.qty = resultQty.value;
                  }
                } else {
                  row.qty = value;
                  row.amount = row.rate * row.qty;
                }
              }
              return rowItem;
            }
          );

          this.setState({
            indentLineItems: indentLineItemsObj,
          });
          this.props.IndentLineItemsProps(indentLineItemsObj);
        } else {
          swal("Can't Enter more than 3 Decimals!");
        }
      } else {
      }
    }
  };

  getItemDetails = (response, itemId) => {
    if (
      this.props.indentFillDetails.categoryTypeValue !== "7" ||
      this.props.indentFillDetails.categoryTypeValue !== "4"
    ) {
    if (itemId !== undefined) {
      if (response) {
        let indentLineItemsObj = this.state.indentLineItems.filter((row) => {
          let itemDetails = response.data;
          if (Number(itemDetails.itemId) === Number(row.itemId)) {
            row.min = itemDetails.minQty;
            row.max = itemDetails.maxQty;
            row.maxAllowedQty = itemDetails.maxAllowableQty;
            row.minAllowedQty = itemDetails.minAllowableQty;
            row.reOrderQty = itemDetails.reorderQty;
            row.uomCode = itemDetails.uomCode;
            row.rate = itemDetails.srRate;
            row.remarks = "";
            row.qohCompany = row.centralStock;
            // if (this.props.location.pathname === "/create_openIndent") {
            //   row.qty = 0;
            // } else {
            //   row.qty =
            //     itemDetails.minQty !== 0 && itemDetails.minQty !== 0
            //       ? 0
            //       : row.qty;
            // }
          }
          return row;
        });
        this.setState({
          indentLineItems: indentLineItemsObj,
        });
        this.props.IndentLineItemsProps(indentLineItemsObj);
      }
    }
  }
  };

  handleChangetextAreas = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "internalRemarks") {
      this.props.indentFillDetails.internalRemarks = event;
      this.setState({
        internalRemarks: event,
      });
    }
    if (key === "remarks") {
      this.props.indentFillDetails.remarks = event;
      this.setState({
        remarks: event,
      });
    }
  };
  handleChangeSubmit = (key) => (event) => {
    this.setState({ [key]: event });
    this.setState({
      submitRemarks: event,
    });
  };

  // function for dropdown

  handleChangedropdown = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    selectedItemCode
  ) => {
    this.setState({
      isDropdownOpen: false,
    });

    var lastRow =
      this.state.indentLineItems[this.state.indentLineItems.length - 1];
    var newLine = 1;

    if (name === "expenseType") {
      let indentLineItemsObj = this.state.indentLineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          row.selectedSubExpenseType = selectedValue;
          row.subExpenseTypeName = selectedName;

          if (this.props.indentWithMultiDepartments === "2") {
            row.itemGroup = this.props.indentFillDetails.itemGroup;
            row.itemGroupValue = this.props.indentFillDetails.itemGroupValue;
            row.budgetHeadId = this.props.indentFillDetails.budget_Head_Value;
            row.budgetHeadName = this.props.indentFillDetails.budget_Head;
          }
        }
        return row;
      });
      newLine = Number(selectedRow.id);
      this.setState({
        addlineItemId: newLine,
      });

      this.setState({
        indentLineItems: indentLineItemsObj,
      });
      this.props.IndentLineItemsProps(indentLineItemsObj);
      this.displayField(selectedRow, name, 2);
    }

    if (name === "expenseType") {
      if (selectedRow === lastRow) {
        if (this.props.indentWithMultiDepartments === "2") {
          let indentLineItems = this.state.indentLineItems;
          let obj = {
            id: newLine + 1,
            qty: "0.000",
            uomCode: "",
            qohCompany: "",
            qohBranch: 0,
            qohProject: 0,
            rate: "0.000",
            rateSource: 1,
            remarks: "",
            itemGroup: this.props.indentFillDetails.itemGroup,
            itemGroupId: this.props.indentFillDetails.itemGroupValue,
            itemGroupValue: this.props.indentFillDetails.itemGroupValue,
            departmentName: this.props.indentFillDetails.departmentName,
            departmentId: this.props.indentFillDetails.departmentId,
            budgetHeadId: this.props.indentFillDetails.budget_Head_Value,
            budgetHeadName: this.props.indentFillDetails.budget_Head,
            itemList: [],
            departmentList: [],
            itemGroupList: [],
            subExpenseTypeName: null,
            selectedExpenseType: null,
            selectedSubExpenseType: null,
            installationRate: "0.000",
            isActive: 1,
            itemId: "",
            itemCode: "",
            lastPurchaseDate: "",
            lastPurchaseRate: "0.000",
            qtyPo: "",
            amount: "0.00",
            make: "",
            status: 1,
          };
          indentLineItems.push(obj);
          this.setState({
            indentLineItems: indentLineItems,
          });
          this.props.IndentLineItemsProps(indentLineItems);
        } else {
          let indentLineItems = this.state.indentLineItems;
          let obj = {
            id: newLine + 1,
            qty: "0.000",
            uomCode: "",
            qohCompany: "",
            qohBranch: 0,
            qohProject: 0,
            rate: "0.000",
            rateSource: 1,
            remarks: "",
            itemGroup: "",
            itemGroupId: "",
            itemGroupValue: "",
            departmentName: "",
            departmentId: "",
            budgetHeadId: "",
            budgetHeadName: "",
            itemList: [],
            departmentList: [],
            itemGroupList: [],
            subExpenseTypeName: null,
            selectedExpenseType: null,
            selectedSubExpenseType: null,
            installationRate: "0.000",
            isActive: 1,
            itemId: "",
            itemCode: "",
            lastPurchaseDate: "",
            lastPurchaseRate: "0.000",
            qtyPo: "0.000",
            amount: "0.00",
            make: "",
            status: 1,
          };
          indentLineItems.push(obj);
          this.setState({
            indentLineItems: indentLineItems,
          });
          this.props.IndentLineItemsProps(indentLineItems);
        }
      }
      // }
      this.displayField(selectedRow, name, 2);
    }
    if (name === "item_code") {
      const data = {
        itemGroupId: selectedRow.itemGroupId,
        companyId: localStorage.getItem("companyId"),
        itemId: selectedValue,
        branchId: this.props.indentFillDetails.branchValue,
      };
      this.props.getItemdetailsByItemId(
        serverApi.GET_ITEMDETAILS_BYITEM_ITEMGRP_COMPANYID,
        data,
        this.getItemDetails,
        selectedValue
      );

      let indentLineItems = this.state.indentLineItems.filter(
        (item) => item.isActive !== 0
      );
      if (selectedRow.itemId !== selectedValue) {
        var checkItem = !!indentLineItems.find(
          ({ itemId }) => Number(itemId) === Number(selectedValue)
        );
      }
      if (checkItem) {
        swal("You can't create the record with the duplicate item");
      } else {
        let indentLineItemsObj = this.state.indentLineItems.filter((row) => {
          if (row.id === selectedRow.id) {
            if (row.itemId === selectedValue) {
              let value = selectedName.split("^");
              let resVal = value[3];
              let uom = value[1];
              row.rate = resVal === "null" ? 0 : parseInt(resVal);
              row.uomCode = uom;
              row.itemId = selectedValue;
              row.itemName = value[0];
              row.itemCode = selectedItemCode;
              row.isActive = 1;

              const data1 = {
                companyId: localStorage.getItem("companyId"),
                itemId: selectedValue,
                branchId: this.props.indentFillDetails.branchValue,
              };
              this.props.getPendingStock(
                serverApi.GET_PENDING_STOCK,
                data1,
                this.props.history,
                selectedValue
              );

              const data2 = {
                companyId: localStorage.getItem("companyId"),
                itemId: selectedValue,
              };
              this.props.getPendingStockPoandReceipt(
                serverApi.GET_CENTRAL_STOCK,
                data2,
                this.props.history,
                selectedValue
              );
            } else {
              let value = selectedName.split("^");
              let resVal = value[3];
              let uom = value[1];
              row.rate = resVal === "null" ? 0 : parseInt(resVal);
              row.uomCode = uom;
              row.itemId = selectedValue;
              row.itemName = value[0];
              row.itemCode = selectedItemCode;
              row.isActive = 1;

              const data1 = {
                companyId: localStorage.getItem("companyId"),
                itemId: selectedValue,
                branchId: this.props.indentFillDetails.branchValue,
              };
              this.props.getPendingStock(
                serverApi.GET_PENDING_STOCK,
                data1,
                this.props.history,
                selectedValue
              );

              const data2 = {
                companyId: localStorage.getItem("companyId"),
                itemId: selectedValue,
              };
              this.props.getPendingStockPoandReceipt(
                serverApi.GET_CENTRAL_STOCK,
                data2,
                this.props.history,
                selectedValue
              );
              row.qty = 0;
              row.installationRate = 0;
              row.remarks = "";
              row.make = "";
            }

            this.setState({
              rowCount: this.state.rowCount + 1,
            });
          }
          return row;
        });

        this.setState({
          indentLineItems: indentLineItemsObj,
        });
        this.props.IndentLineItemsProps(indentLineItemsObj);
        this.displayField(selectedRow, name, 2);
      }

    }

    //for department
    if (name === "budgetHead") {
      let filtereditems = this.state.indentLineItems.filter(
        (item) => item.isActive !== 0
      );
      let checkitem = !!filtereditems.find(
        ({ budgetHeadId }) => budgetHeadId === selectedValue
      );
      var newLine2 = 1;
      if (checkitem) {
        let indentLineItemsObj = this.state.indentLineItems.filter((row) => {
          if (row.id === selectedRow.id) {
            row.budgetHeadId = selectedValue;
            row.budgetHeadName = selectedName;
            this.props.indentFillDetails.departmentId = selectedValue;
            this.props.indentFillDetails.departmentName = selectedName;
            this.props.getItemGroupListforindent(
              serverApi.GET_ITEMGROUPS_BYBUDGET_HEADS +
                "/" +
                localStorage.getItem("companyId") +
                "/budgetHead/" +
                selectedValue,
              this.props.history,
              this.getitemListByitemgrpId,
              selectedValue
            );
          }
          return row;
        });
        newLine2 = Number(selectedRow.id);
        this.setState({
          addlineItemId: newLine2,
        });

        this.setState({
          indentLineItems: indentLineItemsObj,
        });
        this.props.IndentLineItemsProps(indentLineItemsObj);
        this.displayField(selectedRow, "budgetHead", 2);
      } else {
        let indentLineItemsObj = this.state.indentLineItems.filter((row) => {
          if (row.id === selectedRow.id) {
            row.budgetHeadId = selectedValue;
            row.budgetHeadName = selectedName;

            row.itemGroup = "";
            row.itemGroupId = "";
            row.itemGroupValue = "";
            row.itemId = "";
            row.itemGroupList = [];
            row.itemList = [];
            row.itemName = "";
            row.remarks = "";
            row.rate = 0;
            row.qty = 0;
            row.uomCode = "";
            row.installationRate = 0;
            row.make = "";
            this.props.indentFillDetails.departmentId = selectedValue;
            this.props.indentFillDetails.departmentName = selectedName;
            this.props.getItemGroupListforindent(
              serverApi.GET_ITEMGROUPS_BYBUDGET_HEADS +
                "/" +
                localStorage.getItem("companyId") +
                "/budgetHead/" +
                selectedValue,

              this.props.history,
              this.getitemListByitemgrpId,
              selectedValue
            );
          }
          return row;
        });
        newLine2 = Number(selectedRow.id);
        this.setState({
          addlineItemId: newLine2,
        });

        this.setState({
          indentLineItems: indentLineItemsObj,
        });
        this.props.IndentLineItemsProps(indentLineItemsObj);
        this.displayField(selectedRow, "budgetHead", 2);
      }
    }
    /*for budgetHead -> adding empty line */

    if (name === "budgetHead") {
      if (selectedRow === lastRow) {
        if (this.props.indentWithMultiDepartments === "2") {
          let indentLineItems = this.state.indentLineItems;
          let obj = {
            id: newLine + 1,
            qty: "0.000",
            uomCode: "",
            qohCompany: "",
            qohBranch: 0,
            qohProject: 0,
            rate: "0.000",
            rateSource: 1,
            remarks: "",
            itemGroup: this.props.indentFillDetails.itemGroup,
            itemGroupId: this.props.indentFillDetails.itemGroupValue,
            itemGroupValue: this.props.indentFillDetails.itemGroupValue,
            departmentName: this.props.indentFillDetails.departmentName,
            departmentId: this.props.indentFillDetails.departmentId,
            budgetHeadId: this.props.indentFillDetails.budget_Head_Value,
            budgetHeadName: this.props.indentFillDetails.budget_Head,
            itemList: [],
            departmentList: [],
            itemGroupList: [],
            subExpenseTypeName: null,
            selectedExpenseType: null,
            selectedSubExpenseType: null,
            installationRate: "0.000",
            isActive: 1,
            itemId: "",
            itemCode: "",
            lastPurchaseDate: "",
            lastPurchaseRate: "0.000",
            qtyPo: "",
            amount: "0.00",
            make: "",
            status: 1,
          };
          indentLineItems.push(obj);
          this.setState({
            indentLineItems: indentLineItems,
          });
          this.props.IndentLineItemsProps(indentLineItems);
        } else {
          let indentLineItems = this.state.indentLineItems;
          let obj = {
            id: selectedRow.id + 1,
            qty: "0.000",
            uomCode: "",
            qohCompany: "",
            qohBranch: 0,
            qohProject: 0,
            rate: "0.000",
            rateSource: 1,
            remarks: "",
            itemGroup: "",
            itemGroupId: "",
            itemGroupValue: "",
            departmentName: "",
            departmentId: "",
            budgetHeadId: "",
            budgetHeadName: "",
            itemList: [],
            departmentList: [],
            itemGroupList: [],
            subExpenseTypeName: null,
            selectedExpenseType: null,
            selectedSubExpenseType: null,
            installationRate: "0.000",
            isActive: 1,
            itemId: "",
            itemCode: "",
            lastPurchaseDate: "",
            lastPurchaseRate: "0.000",
            qtyPo: "",
            amount: "0.000",
            make: "",
            status: 1,
            isUpdate:true
          };
          indentLineItems.push(obj);
          this.setState({
            indentLineItems: indentLineItems,
          });
          this.props.IndentLineItemsProps(indentLineItems);
        }
      }
      // }
      this.displayField(selectedRow, name, 2);
    }
    if (name === "itemGroup") {
      let indentLineItemsObj = this.state.indentLineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          if (row.itemGroupId === selectedValue) {
            row.itemGroup = selectedName;
            row.itemGroupId = selectedValue;
            row.itemGroupValue = selectedName;
            // const data = {
            //   companyId: localStorage.getItem("companyId"),
            //   consumable: "",
            //   indentTypeId: this.props.indentFillDetails.categoryTypeValue,
            //   itemGroupId: selectedValue,
            //   saleable: "",
            //   branchId: this.props.indentFillDetails.branchValue,
              
            //   // tangible: "Y",
            // };
            // this.props.getItemsListforIndent(
            //   serverApi.GET_ITEMSBY_FORPROCUREMENT,
            //   data,
            //   this.getitemListByitemgrpId,
            //   selectedValue
            // );
            const data2 = {
              itemGroupId: selectedValue,
              companyId: localStorage.getItem("companyId"),
            };
            this.props.getItemsListByItemGroupId(
              serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
              data2,
              this.getitemListByitemgrpId,
              selectedValue
            );
          } else {
            row.itemGroup = selectedName;
            row.itemGroupId = selectedValue;
            row.itemGroupValue = selectedName;
            // const data = {
            //   companyId: localStorage.getItem("companyId"),
            //   consumable: "",
            //   indentTypeId: this.props.indentFillDetails.categoryTypeValue,
            //   itemGroupId: selectedValue,
            //   saleable: "",
            //   branchId: this.props.indentFillDetails.branchValue,
            //   // tangible: "Y",
            // };
            // this.props.getItemsListforIndent(
            //   serverApi.GET_ITEMSBY_FORPROCUREMENT,
            //   data,
            //   this.getitemListByitemgrpId,
            //   selectedValue
            // );
            const data2 = {
              itemGroupId: selectedValue,
              companyId: localStorage.getItem("companyId"),
            };
            this.props.getItemsListByItemGroupId(
              serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
              data2,
              this.getitemListByitemgrpId,
              selectedValue
            );
            row.itemId = "";
            row.itemName = "";
            row.remarks = "";
            row.rate = 0;
            row.qty = 0;
            row.uomCode = "";
            row.installationRate = 0;
            row.make = "";
          }
        }
        return row;
      });

      this.setState({
        indentLineItems: indentLineItemsObj,
      });

      this.props.IndentLineItemsProps(indentLineItemsObj);
      this.displayField(selectedRow, "itemGroup", 2);
    }
  };

  onSubmitnotes = () => {
    if (this.props.location.state.rowData) {
      const data = {
        sourceModuleId: this.state.indentId,
        createdBy: this.state.userDit.userId.toString(),
        oldId: "",
        internalRemarks: this.state.submitRemarks,
        companyId: localStorage.getItem("companyId"),
      };
      this.props.actions(serverApi.ACTIONS, data, this.props.history);
    }

    if (this.state.submitRemarks) {
      if (
        this.state.internalRemarks === "" ||
        this.state.internalRemarks === null
      ) {
        let internalNotes = this.state.submitRemarks;
        this.props.indentFillDetails.internalRemarks = internalNotes;
        this.setState({
          internalRemarks: internalNotes,
          submitRemarks: "",
        });
      } else {
        let internalNotes =
          this.state.internalRemarks + "\n" + this.state.submitRemarks;
        this.props.indentFillDetails.internalRemarks = internalNotes;
        this.setState({
          internalRemarks: internalNotes,
          submitRemarks: "",
        });
      }
    }
  };
  UploadDocuments = (key) => (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 10);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", this.state.indentId);
    formData.append("createdBy", this.state.userDit.userId);
    formData.append("fileCompanyId", localStorage.getItem("companyId"));
    this.setState({
      fileName: file.name,
      fileType: fileExt,
      uploadFile: true,
    });
    this.props.postUploadFileList(
      serverApi.UPLOADFILE,
      formData,
      this.props.history
    );
  };
  onDeleteFile = (fileId) => {
    let data = this.state.files_List.filter(
      (item) => item.fileUploadId !== fileId
    );
    this.props.deleteFile(serverApi.DELETEFILE + fileId);
    this.setState({
      files_List: data,
    });
    this.props.getFilesSuccessfull({ data });
  };

  onDelLineItem = (row) => {
    var lastRow =
      this.state.indentLineItems[this.state.indentLineItems.length - 1];
    if (lastRow === row) {
      swal("you can't delete the empty line item");
    } else {
      let indexItem = this.state.indentLineItems.findIndex(
        (item) => item === row
      );
      let lineItems = this.state.indentLineItems;
      lineItems[indexItem].isActive = 0;
      this.setState({
        indentLineItems: lineItems,
      });
    }
  };

  onClickToSeeLogs = () => {
    this.setState({
      uploadFile: false,
      seeLogs: true,
    });
    if (this.props.location.state) {
      let id = this.props.location.state.rowData.id;
      this.props.logs(serverApi.SEE_THE_LOGS + id, this.props.history);
    }
  };

  getTotalAmount = () => {
    var totalAmount = 0;
    var data = this.state.indentLineItems.filter((item) => item.isActive !== 0);

    data.forEach((prop, key) => {
      if (prop.itemId !== "" && prop.qty !== "" && prop.amount !== "") {
        totalAmount =
          totalAmount +
          parseFloat(prop.rate) * parseFloat(Number(prop.qty)) +
          Number(prop.qty) * Number(prop.installationRate);
      }
    });
    return totalAmount.toFixed(2);
  };

  getFileDownload = (fileUploadId, fileName, fileExtension) => {
    getFileDownload(
      serverConfig.SERVER_URL +
        "security-api/api/files/downloadfile/" +
        fileUploadId,
      fileName,
      fileExtension
    );
  };

  getitemListByitemgrpId = (response, itemGrpId) => {
    if (itemGrpId !== undefined) {
      if (response) {
        let indentLineItemsObj = this.state.indentLineItems.filter((row) => {
          if (row.itemGroup === itemGrpId || row.itemGroupId === itemGrpId) {
            let list = [];
            response.data.map((prop) => {
              list.push({
                label: prop.itemDesc+ `(${prop.itemCode})`,
                name: prop.itemDesc+ `(${prop.itemCode})`,
                value: prop.itemId,
                variant: prop.itemCode,
              });
            });
            // let itemsList = list.filter((item) => {
            //   if (item.value == row.itemId) {
            //     let value = item.name.split("^");
            //     row.min = value[8];
            //     row.max = value[6];
            //   }
            //   if (item.value !== 0 && item.value !== "0") {
            //     return item;
            //   }
            // });
            row.itemList = list;
          } else if (row.budgetHeadId === itemGrpId) {
            let itemGRPList = [];
            response.data.map((prop) => {
              itemGRPList.push({
                label: prop.label,
                name: prop.name,
                value: prop.value,
              });
            });
            let itemsgroupList = itemGRPList.filter((item) => {
              if (item.value !== 0 && item.value !== "0") {
                return item;
              }
            });
            row.itemGroupList = itemsgroupList;
          }
          return row;
        });
        this.setState({
          indentLineItems: indentLineItemsObj,
        });
        this.props.IndentLineItemsProps(indentLineItemsObj);
      }
    }
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleEventPositioned = (info, id) => {
    let filteredLineitems = this.state.indentLineItems.filter(
      (item) => item.isActive !== 0
    );

    filteredLineitems.map((item) => {
      if (item.id == id) {
        if (info.target) {
          info.target.setAttribute(
            "data-tip",
            `<p>Central Stock = ${
              item.qohCompany == "" || item.qohCompany == null
                ? 0
                : Number(item.qohCompany).toFixed(3)
            }</p>
          <p> Branch Stock = ${Number(item.qohBranch).toFixed(3)}</p>
          <p> Qty pending for Po = ${
            item.qtyPo == "" || item.qtyPo == null ? 0 : item.qtyPo
          }</p>
          <p>Re Order Quantity =${
            item.reOrderQty === undefined ? 0 : item.reOrderQty
          }</p>
          <p>Max = ${item.max === undefined ? 0 : item.max}</p>
          <p>Min = ${item.min === undefined ? 0 : item.min}</p>
          `
          );
          ReactTooltip.rebuild();
        }
      }
    });
  };

  displayField = (row, colname, type) => {
    if (colname === "budgetHead") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";
      this.setState((prevState) => ({
        BudgetHeadDisplayTxtField: {
          ...prevState.BudgetHeadDisplayTxtField,
          [fieldKey1]: !prevState.BudgetHeadDisplayTxtField[fieldKey1],
        },
        BudgetHeadDisplayDropField: {
          ...prevState.BudgetHeadDisplayDropField,
          [fieldKey2]: !prevState.BudgetHeadDisplayDropField[fieldKey2],
        },
      }));
      if (type !== 2) {
        this.props.BudgetHeads(
          serverApi.GET_BUDGET_HEADES + localStorage.getItem("companyId"),
          this.state.history
        );
      }
    } else if (colname === "expenseType") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";
      this.setState((prevState) => ({
        BudgetHeadDisplayTxtField: {
          ...prevState.BudgetHeadDisplayTxtField,
          [fieldKey1]: !prevState.BudgetHeadDisplayTxtField[fieldKey1],
        },
        BudgetHeadDisplayDropField: {
          ...prevState.BudgetHeadDisplayDropField,
          [fieldKey2]: !prevState.BudgetHeadDisplayDropField[fieldKey2],
        },
      }));
      if (type !== 2) {
        this.props.getSubBudgetHeadsList(
          serverApi.GET_SUB_BUDGET_HEADS + "7",
          this.props.history
        );
      }
    } else if (colname === "itemGroup") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";
      this.setState((prevState) => ({
        BudgetHeadDisplayTxtField: {
          ...prevState.BudgetHeadDisplayTxtField,
          [fieldKey1]: !prevState.BudgetHeadDisplayTxtField[fieldKey1],
        },
        BudgetHeadDisplayDropField: {
          ...prevState.BudgetHeadDisplayDropField,
          [fieldKey2]: !prevState.BudgetHeadDisplayDropField[fieldKey2],
        },
      }));
      if (type !== 2) {
        if (row.budgetHeadId !== undefined) {
          this.props.getItemGroupListforindent(
            serverApi.GET_ITEMGROUPS_BYBUDGET_HEADS +
              "/" +
              localStorage.getItem("companyId") +
              "/budgetHead/" +
              row.budgetHeadId,

            this.props.history,
            this.getitemListByitemgrpId,
            row.budgetHeadId
          );
        }
        if (row.itemGroupId !== "" && row.itemGroupId !== undefined) {
          // const data = {
          //   companyId: localStorage.getItem("companyId"),
          //   consumable: "",
          //   indentTypeId: this.props.indentFillDetails.categoryTypeValue,
          //   itemGroupId: row.itemGroupId,
          //   saleable: "",
          //   tangible:
          //     this.props.location.pathname === "/create_boq" ? "N" : "Y",
          //     branchId:this.props.indentFillDetails.branchValue,
          // };
          // this.props.getItemsListforIndent(
          //   serverApi.GET_ITEMSBY_FORPROCUREMENT,
          //   data,
          //   this.getitemListByitemgrpId,
          //   row.itemGroupId
          // );

          const data2 = {
            itemGroupId: row.itemGroupId,
            companyId: localStorage.getItem("companyId"),
          };
          this.props.getItemsListByItemGroupId(
            serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
            data2,
            this.getitemListByitemgrpId,
            row.itemGroupId
          );
        }
      }
    } else if ((colname = "item_code")) {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";
      this.setState((prevState) => ({
        BudgetHeadDisplayTxtField: {
          ...prevState.BudgetHeadDisplayTxtField,
          [fieldKey1]: !prevState.BudgetHeadDisplayTxtField[fieldKey1],
        },
        BudgetHeadDisplayDropField: {
          ...prevState.BudgetHeadDisplayDropField,
          [fieldKey2]: !prevState.BudgetHeadDisplayDropField[fieldKey2],
        },
      }));
      if (type !== 2) {
        if (row.itemId !== "" && row.itemId !== undefined) {
          const data1 = {
            companyId: localStorage.getItem("companyId"),
            itemId: row.itemId,
            branchId: this.props.indentFillDetails.branchValue,
          };
          this.props.getPendingStock(
            serverApi.GET_PENDING_STOCK_BRANCH_WISE,
            data1,
            this.props.history,
            row.itemId
          );
          const data2 = {
            companyId: localStorage.getItem("companyId"),
            itemId: row.itemId,
          };
          this.props.getPendingStockPoandReceipt(
            serverApi.GET_CENTRAL_STOCK,
            data2,
            this.props.history,
            row.itemId
          );

          const data3 = {
            itemGroupId: row.itemGroupId,
            companyId: localStorage.getItem("companyId"),
          };
          this.props.getItemsListByItemGroupId(
            serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
            data3,
            this.getitemListByitemgrpId,
            row.itemGroupId
          );
        }
      }
    }
  };

  render() {
    let itemsList = this.state.itemsList;
    if (this.state.indentWithMultiDepartments === "1") {
      var RowData = RowData1;
      var HeadData = tableHeaders;
    } else {
      RowData = RowData1.filter((row) => {
        if (row.no !== 1 && row.no !== 3) {
          return row;
        }
      });
      HeadData = tableHeaders.filter((row) => {
        if (row.header !== "Budget Head" && row.header !== "Item Group") {
          return row;
        }
      });
    }
    var filteredLineitems = this.state.indentLineItems.filter(
      (item) => item.isActive !== 0
    );

    return (
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent" onScroll={this.handleScroll}>
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {HeadData.map((column) => (
                    <>
                      {column.header === "Quantity" ||
                      column.header === "Amount" ||
                      column.header === "Budget Head" ||
                      column.header === "Item Group" ||
                      column.header === "Item" ? (
                        <th>
                          {column.header}
                          <span style={{ color: "red" }}>*</span>
                        </th>
                      ) : (
                        <th>{column.header}</th>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredLineitems.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <>
                      {RowData &&
                        RowData.map((col) =>
                          col.component === "NotEditableCell" ? (
                            <>
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        type="text"
                                        name={col.type + "_" + rowIndex}
                                        id={col.no}
                                        Value={
                                          col.type === "rate"
                                            ? parseFloat(row.rate).toFixed(3)
                                            : col.type === "Central_Stock"
                                            ? row.qohCompany
                                            : col.type === "Unit"
                                            ? row.uomCode
                                            : col.type === "Branch_Stock"
                                            ? row.qohBranch
                                            : col.type === "Project_QOH"
                                            ? row.qohProject
                                            : col.type === "Qty_Pending_for_PO"
                                            ? parseFloat(row.qtyPo).toFixed(3)
                                            : col.type === "Amount"
                                            ? parseFloat(
                                                (row.rate +
                                                  Number(
                                                    row.installationRate
                                                  )) *
                                                  row.qty
                                              ).toFixed(2)
                                            : col.type === "installationAmount"
                                            ? parseFloat(
                                                row.installationRate * row.qty
                                              ).toFixed(2)
                                            : ""
                                        }
                                        disabled
                                        onChange={this.handleChange({
                                          key: col.type + "_" + rowIndex,
                                          row,
                                        })}
                                        onBlur={this.handleBlur({
                                          key: col.type + "_" + rowIndex,
                                          row,
                                        })}
                                        onKeyDown={(evt) =>
                                          ["e", "E", "+", "-"].includes(
                                            evt.key
                                          ) && evt.preventDefault()
                                        }
                                        className="inputBlockContainer"
                                        style={{
                                          textAlign:
                                            col.type === "installationRate" ||
                                            col.type === "Amount" ||
                                            col.type === "installationAmount" ||
                                            col.type === "rate" ||
                                            col.type === "itemQty"
                                              ? "right"
                                              : "",
                                          paddingRight: "8px",
                                        }}
                                      />
                                    </Grid>

                                    <ReactTooltip
                                      effect={"solid"}
                                      html={true}
                                      place={"BottomCenter"}
                                      className="tooltipClassBorder"
                                      backgroundColor="rgba(0,127,173,0.5)"
                                      textColor="#FFF"
                                      border={true}
                                      borderColor="#04749c"
                                    />
                                  </Grid>
                                </div>
                              </td>
                            </>
                          ) : col.component === "EditableCell" ? (
                            <td>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      name={col.type + "_" + rowIndex}
                                      id={col.no}
                                      type={
                                        col.type === "itemQty" ||
                                        col.type === "installationRate"
                                          ? "number"
                                          : "text"
                                      }
                                      value={
                                        col.type === "itemQty"
                                          ? row.qty
                                          : col.type === "remarks"
                                          ? row.remarks
                                          : col.type === "Make"
                                          ? row.make
                                          : col.type === "installationRate"
                                          ? row.installationRate
                                          : ""
                                      }
                                      onChange={this.handleChange({
                                        key: col.type + "_" + rowIndex,
                                        row,
                                      })}
                                      onWheel={(e) => e.target.blur()}
                                      onBlur={this.handleBlur({
                                        key: col.type + "_" + rowIndex,
                                        row,
                                      })}
                                      onKeyDown={(evt) =>
                                        (
                                          col.type === "remarks" ||
                                          col.type === "Make"
                                            ? ["E", "+", "-"].includes(evt.key)
                                            : ["e", "E", "+", "-"].includes(
                                                evt.key
                                              )
                                        )
                                          ? evt.preventDefault()
                                          : null
                                      }
                                      className="inputBlockContainer"
                                      style={{
                                        textAlign:
                                          col.type === "installationRate" ||
                                          col.type === "itemQty"
                                            ? "right"
                                            : "",
                                        paddingRight: "8px",
                                      }}
                                      onMouseOver={(e) =>
                                        col.type === "itemQty"
                                          ? this.handleEventPositioned(
                                              e,
                                              row.id
                                            )
                                          : ""
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          ) : (
                            <>
                              <td>
                                <Tooltip
                                  title={
                                    col.type === "budgetHead"
                                      ? row.budgetHeadName
                                      : col.type === "itemGroup"
                                      ? this.props.location.state !== undefined
                                        ? row.itemGroup
                                        : row.itemGroupValue
                                      : col.type === "item_code"
                                      ? row.itemName
                                      : col.type === "expenseType"
                                      ? row.subExpenseTypeName
                                      : ""
                                  }
                                >
                                  <div className="quantityDropdownBlock">
                                    <Grid className="selectAndTextfield">
                                      <Grid
                                        Grid
                                        xs={12}
                                        md={12}
                                        className="dynamicSelectBlock"
                                        style={{
                                          display: this.state
                                            .BudgetHeadDisplayTxtField[
                                            col.type + row.id + "1"
                                          ]
                                            ? "none"
                                            : "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          className="inputBlockContainer"
                                          style={{ paddingRight: "8px" }}
                                          value={
                                            col.type === "budgetHead"
                                              ? row.budgetHeadName
                                              : col.type === "itemGroup"
                                              ? row.itemGroup
                                              : col.type === "item_code"
                                              ? row.itemName
                                              : col.type === "expenseType"
                                              ? row.subExpenseTypeName
                                              : ""
                                          }
                                          onChange={this.handleChange({
                                            key: col.type + "_" + rowIndex,
                                            row,
                                          })}
                                          onClick={() => {
                                            this.displayField(row, col.type, 1);
                                          }}
                                        />
                                      </Grid>
                                      <Grid
                                        xs={12}
                                        md={12}
                                        className="dynamicSelectBlock"
                                        style={{
                                          display: this.state
                                            .BudgetHeadDisplayDropField[
                                            col.type + row.id + "2"
                                          ]
                                            ? "flex"
                                            : "none",
                                          alignItems: "center",
                                        }}
                                      >
                                        <DynamicSelect
                                          isDropdownOpen={
                                            this.state.isDropdownOpen
                                          }
                                          arrayOfData={
                                            col.type === "budgetHead"
                                              ? this.state.budgetHeadsList
                                              : col.type === "item_code"
                                              ? this.props
                                                  .indentWithMultiDepartments ===
                                                "2"
                                                ? itemsList
                                                : row.itemList
                                              : col.type === "itemGroup"
                                              ? row.itemGroupList
                                              : col.type === "expenseType"
                                              ? this.state.subBudgetHeadsList
                                              : []
                                          }
                                          className="dropdownBlockContainer"
                                          handleMenuOpen={this.handleMenuOpen}
                                          onSelectChange={
                                            this.handleChangedropdown
                                          }
                                          selected={
                                            col.type === "budgetHead"
                                              ? row.budgetHeadId
                                              : col.type === "item_code"
                                              ? row.itemId
                                              : col.type === "expenseType"
                                              ? row.selectedSubExpenseType
                                              : this.props.location.state ===
                                                undefined
                                              ? this.props.indentFillDetails
                                                  .copy_from_BOM !== ""
                                                ? row.itemGroupId
                                                : row.itemGroup
                                              : row.itemGroupId
                                              ? row.itemGroupId
                                              : Number(row.itemGroupId)
                                          }
                                          name={col.type}
                                          row={row}
                                          update={
                                            row.budgetHeadId ||
                                            row.itemId ||
                                            Number(row.itemGroup) ||
                                            row.selectedExpenseType
                                              ? 1
                                              : 0
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Tooltip>
                              </td>
                            </>
                          )
                        )}
                    </>
                    <td className="">
                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title="Delete"
                      >
                        <IconButton
                          onClick={() => {
                            this.onDelLineItem(row);
                          }}
                        >
                          <DeleteIcon className="deleteDisplayButton" />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="consoleFormContainer">
          <div style={{ height: "10px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 10">
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 6">
                        <div className="consoleTextFieldBlock">
                          <TextArea
                            label="Remarks"
                            onChange={this.handleChangetextAreas("remarks")}
                            value={this.state.remarks}
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 6">
                        <div className="consoleTextFieldBlock">
                          <TextArea
                            label="Internal Note"
                            onChange={this.handleChangetextAreas(
                              "internalRemarks"
                            )}
                            value={this.state.internalRemarks}
                          ></TextArea>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                  <Box gridColumn="span 2">
                    <div className="consoleTextFieldBlock">
                      <TableContainer className="amountTable">
                        <Table aria-label="simple table">
                          <TableBody>
                            <TableRow>
                              <TableCell
                                component="td"
                                scope="row"
                                className="noBorder"
                              >
                                Total Amount:
                              </TableCell>
                              <TableCell align="right" className="noBorder">
                                <strong>{this.getTotalAmount()}</strong>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>

        {this.props.locationState.state && (
          <div className="consoleFormContainer">
            <Grid container spacing={2}>
              <Grid item xs={12} className="personalDetailsUpload">
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                  className="ducumentUploadBlock"
                >
                  <Box gridColumn="span 6">
                    <div className="consoleFormBlock filesUploadedBlock">
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={2}
                        className="ducumentUploadBlock uploadedContent"
                      >
                        <Box gridColumn="span 6">
                          <label>Support Documents:</label>
                          <div className="personalDetailsUpload">
                            <div className="ducumentUploadBlock">
                              <div className="documentUploadContent">
                                <span className="btn_upload documentUpload">
                                  <img src={panUploadIcon} alt="" />
                                  <input
                                    type="file"
                                    id="imag"
                                    title=""
                                    className="input-img"
                                    onChange={this.UploadDocuments()}
                                  />
                                  Upload Document
                                </span>
                              </div>
                            </div>
                          </div>
                        </Box>
                      </Box>
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={2}
                        className="ducumentUploadBlock uploadedContent"
                      >
                        {this.state.files_List &&
                          this.state.files_List.map((item) => (
                            <Box gridColumn="span 6">
                              <div className="documentUploadContent">
                                <div
                                  className="uploadedInfo"
                                  style={{ display: "flex" }}
                                >
                                  <img
                                    src={TickIcon}
                                    alt=""
                                    className="tickIcon"
                                    height={"20px"}
                                    width={"20px"}
                                  />
                                  <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    title="Click to download File"
                                  >
                                    <div
                                      className="uplodedFileName"
                                      onClick={() => {
                                        getFileDownload(
                                          serverConfig.SERVER_URL +
                                            "security-api/api/files/downloadfile/" +
                                            item.fileUploadId,
                                          item.fileName,
                                          item.fileExtension
                                        );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {item.fileName}
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 100 }}
                                    title="Click to Delete File"
                                  >
                                    <Button
                                      onClick={() => {
                                        this.onDeleteFile(item.fileUploadId);
                                      }}
                                      className="deleteButton"
                                    >
                                      <img
                                        src={DeleteIcons}
                                        alt=""
                                        height={"20px"}
                                        width={"20px"}
                                      />
                                    </Button>
                                  </Tooltip>
                                </div>
                              </div>
                            </Box>
                          ))}
                      </Box>
                    </div>
                  </Box>
                  <Box gridColumn="span 6">
                    <div className="consoleFormBlock">
                      <div className="consoleTextFieldBlock remarksBlock">
                        <TextArea
                          label="Internal Notes"
                          onChange={this.handleChangeSubmit("submitRemarks")}
                          value={this.state.submitRemarks}
                        />
                        <CustomButton
                          label={"Submit"}
                          className="greenButton"
                          handleClick={this.onSubmitnotes}
                          type="sumbit"
                        />
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
              <Box>
                <Grid spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 3">
                        <div className="consoleFormButtonBlock">
                          <CustomButton
                            label={"CLICK HERE TO SEE THE LOGS"}
                            className="greenButton"
                            handleClick={this.onClickToSeeLogs}
                            type="sumbit"
                          />
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid spacing={2} className="logsContainer">
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 6">
                        {this.state.Logs &&
                          this.state.Logs.map((item) => {
                            let date = moment(item.actionDate)
                              .format("DD-MM-YYYY")
                              .split("T")
                              .toString();
                            let time = moment(item.actionDate)
                              .format()
                              .split("T", 2);
                            return (
                              <ul>
                                <li className="logsContent">
                                  <strong>{date}</strong>
                                  <span> </span>
                                  {time[1]}
                                  <span> </span>
                                  {item.remarks}
                                </li>
                              </ul>
                            );
                          })}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

const RowData1 = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "budgetHead",
  },
  // {
  //   no: 2,
  //   component: "DynamicSelect",
  //   type: "expenseType",
  // },
  {
    no: 3,
    component: "DynamicSelect",
    type: "itemGroup",
  },
  {
    no: 4,
    component: "DynamicSelect",
    type: "item_code",
  },
  {
    no: 5,
    component: "NotEditableCell",
    type: "rate",
  },
  {
    no: 6,
    component: "EditableCell",
    type: "itemQty",
  },
  {
    no: 7,
    component: "NotEditableCell",
    type: "Unit",
  },
  {
    no: 8,
    component: "EditableCell",
    type: "remarks",
  },
  {
    no: 9,
    component: "EditableCell",
    type: "installationRate",
  },
  {
    no: 10,
    component: "NotEditableCell",
    type: "installationAmount",
  },
  {
    no: 11,
    component: "EditableCell",
    type: "Make",
  },
  {
    no: 12,
    component: "NotEditableCell",
    type: "Amount",
  },
];
const tableHeaders = [
  {
    header: "Budget Head",
  },
  // {
  //   header: "Expense Type",
  // },
  {
    header: "Item Group",
  },
  {
    header: "Item",
  },
  {
    header: "Item Rate",
  },
  {
    header: "Quantity",
  },
  {
    header: "Unit",
  },
  {
    header: "Remarks",
  },
  {
    header: "Installation Rate",
  },
  {
    header: "Installation Amount",
  },
  {
    header: "Make",
  },
  {
    header: "Amount",
  },
  {
    header: "Actions",
  },
];

const mapStatetoProps = (state) => {
  const { ItemGroupList, departmentList, subBudgetHeadsList, budgetHeadsList } =
    state.DropDownListReducer;
  const { itemsListForIndent } = state.itemsListForIndent;
  const {
    logs_List,
    files_List,
    indentLineItems,
    pendingStockPoandReceipt,
    pendingStock,
    itemGroupsForIndent,
  } = state.IndentReducer;
  const { Upload_File_List } = state.UploadFileReducer;
  return {
    pendingStockPoandReceipt,
    pendingStock,
    logs_List,
    files_List,
    Upload_File_List,
    ItemGroupList,
    departmentList,
    itemsListForIndent,
    itemGroupsForIndent,
    indentLineItems,
    subBudgetHeadsList,
    budgetHeadsList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getPendingStockPoandReceipt,
    getItemsListForIndent,
    getPendingStock,
    postUploadFileList,
    deleteFile,
    actions,
    logs,
    getFiles,
    getFilesSuccessfull,
    getDepartmentList,
    getItemGroupList,
    IndentLineItemsProps,
    getSubBudgetHeadsList,
    BudgetHeads,
    getItemsListforIndent,
    getItemGroupListforindent,
    getItemsListByItemGroupId,
    getItemdetailsByItemId,
  })(BOQLineItems)
);
