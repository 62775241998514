import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { serverApi } from "../../../helpers/Consts";
import {
  createDesignationMaster,
  updateDesignationMaster,
} from "../../../store/Master/DesignationMaster/actions";
import DynamicSelect from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import { getDepartmentList } from "../../../store/Global/DropDownApis/actions";
import moment from "moment";
import swal from "sweetalert";
import { validate } from "../../../Utilities/Validations";

class CreateDesignation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      occCode: "",
      occupation: "",
      norms: "",
      department: "",
      departmentValue: "",
      machineType: "",
      machineTypeValue: "",
      directIndirect: "",
      directIndirectValue: "",
      onMachine: "",
      costCode: "",
      costDescription: "",
      timePiece: "",
      noOfMachines: "",
      pieceRateType: "",
      pieceRateTypeValue: "",
      createdBy: "",
      id: "",
      createdName: "",
      FactoryFields: "No",
      departmentList: [], // To displaying department list
      locationState:"",
      // designationShortCode: ""
    };
  }
  /* Calling Create and Update Api's to Actions */
  onClickCreateUpdate = () => {
    const userId = this.state.userDit.userId;
    const {
      occupation,
      norms,
      departmentValue,
      directIndirectValue,
      onMachine,
      // designationShortCode
    } = this.state;

    const validateFields = [
      { field: occupation, msg: "Occupation", type: 1 },
      // { field: designationShortCode, msg: "Designation Short Code", type: 1 },
      { field: departmentValue, msg: "Department", type: 2 },
    ];
    const validateFieldsWithTime = [
      { field: occupation, msg: "Occupation", type: 1 },
      // { field: designationShortCode, msg: "Designation Short Code", type: 1 },
      { field: departmentValue, msg: "Department", type: 2 },
      { field: norms, msg: "Norms", type: 1 },
      { field: directIndirectValue, msg: "Direct/InDirect", type: 2 },
      { field: onMachine, msg: "On Machine", type: 2 },
    ];
    let validObj;
    if (this.state.timePiece !== "") {
      validObj = validate(validateFieldsWithTime);
    } else {
      validObj = validate(validateFields);
    }
    // const validObj = validate(validateFields);

    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message} ..!!`);
      } else {
        swal(`Please Select ${validObj.message} ..!!`);
      }
    } else {
      if (this.props.location.state === undefined) {
        const data = {
          companyId: this.state.companyId,
          costCode: this.state.costCode,
          costDescription: this.state.costDescription,
          createdBy: userId.toString(),
          department: this.state.departmentValue,
          desig: this.state.occupation,
          directIndirect: this.state.directIndirectValue,
          machineType: this.state.machineType,
          noOfMachines: this.state.noOfMachines,
          norms: this.state.norms,
          onMachine: this.state.onMachine,
          pieceRateType: this.state.pieceRateTypeValue,
          timePiece: this.state.timePiece,
          // designationShortCode: this.state.designationShortCode
        };
        this.props.createDesignationMaster(
          serverApi.CREATE_DESIGNATION_MASTER,
          data,
          this.props.history
        ); // calling create Designation  api
      } else {
        const data = {
          companyId: this.state.companyId,
          costCode: this.state.costCode,
          costDescription: this.state.costDescription,
          createdBy: userId.toString(),
          id: this.state.occCode,
          department: this.state.departmentValue,
          desig: this.state.occupation,
          directIndirect: this.state.directIndirectValue,
          machineType: this.state.machineType,
          noOfMachines: this.state.noOfMachines,
          norms: this.state.norms,
          onMachine: this.state.onMachine,
          pieceRateType: this.state.pieceRateTypeValue,
          timePiece: this.state.timePiece,
          createdName: this.state.createdName,
          createdOn: this.state.createdOn,
          // designationShortCode: this.state.designationShortCode
        };
        this.props.updateDesignationMaster(
          serverApi.UPDATE_DESIGNATION_MASTER,
          data,
          this.props.history
        ); // calling update designation api
      }
    }
  };
  // function for previous page
  onClickCancel = () => {
    this.props.history.push("/designation_master");
  };
  //function for TextFields
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "occCode") {
      this.setState({
        occCode: event,
      });
    }
    if (key === "occupation") {
      this.setState({
        occupation: event,
      });
    }
    // if (key === "designationShortCode") {
    //   this.setState({
    //     designationShortCode: event,
    //   });
    // }
    if (key === "norms") {
      this.setState({
        norms: event,
      });
    }
    if (key === "costCode") {
      this.setState({
        costCode: event,
      });
    }
    if (key === "costDescription") {
      this.setState({
        costDescription: event,
      });
    }
  };
  // function for popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "department") {
      this.setState({
        department: selectedValue,
        departmentValue: selectedValue,
      });
    }
    if (name === "machineType") {
      this.setState({
        machineType: selectedValue,
        machineTypeValue: selectedValue,
      });
    }
    if (name === "directIndirect") {
      this.setState({
        directIndirect: selectedValue,
        directIndirectValue: selectedValue,
      });
    }
    if (name === "onMachine") {
      this.setState({
        onMachine: selectedValue,
        onMachineValue: selectedValue,
      });
    }
    if (name === "timePiece") {
      this.setState({
        timePiece: selectedValue,
        onMachineValue: selectedValue,
      });
    }
    if (name === "pieceRateType") {
      this.setState({
        pieceRateType: selectedValue,
        pieceRateTypeValue: selectedValue,
      });
    }
    if (name === "factoryfields") {
      this.setState({
        FactoryFields: selectedValue,
      });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    this.setState({
      locationState: this.props.location,
    });
    window.addEventListener("scroll", this.handleScroll);
    this.props.getDepartmentList(
      serverApi.GET_DEPARTMENT_LIST +
        this.state.companyId +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    ); // calling for department list api
    if (this.props.location.state) {
      this.setState({
        occCode: this.props.location.state.rowData.id,
        departmentValue: this.props.location.state.rowData.department,
        norms: this.props.location.state.rowData.norms,
        occupation: this.props.location.state.rowData.desig,
        machineType: this.props.location.state.rowData.machineType,
        directIndirectValue: this.props.location.state.rowData.directIndirect,
        onMachine: this.props.location.state.rowData.onMachine,
        costCode: this.props.location.state.rowData.costCode,
        costDescription: this.props.location.state.rowData.costDescription,
        timePiece: this.props.location.state.rowData.timePiece,
        noOfMachines: this.props.location.state.rowData.noOfMachines,
        pieceRateTypeValue: this.props.location.state.rowData.pieceRateType,
        createdBy: this.props.location.state.rowData.createdBy,
        createdName: this.props.location.state.rowData.createdName,
        // designationShortCode: this.props.location.state.rowData.designationShortCode,
      });
      const dateEntered = this.props.location.state.rowData.createDateTime;
      const dateIsValid = moment(dateEntered).format("LLLL");
      this.setState({
        createdOn: dateIsValid,
      });
    }
  }
  componentWillReceiveProps(props) {
    //dept list
    if (props.departmentList.data) {
      var list = [];

      let listData = props.departmentList.data.filter((item) => {
        if (item.label != "Select....") {
          return item;
        }
      });

      listData.map((item) => {
        list.push({
          label: item.label,
          name: item.name,
          value: item.masterDeptId,
        });
      });

      this.setState({
        departmentList: list,
      });
    }
  }

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.occupation}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("occupation")}
                      label="Designation Name"
                    />
                  </Box>
                  {/* <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.designationShortCode}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("designationShortCode")}
                      label="Designation Short Code"
                    />
                  </Box> */}
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.departmentList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.departmentValue}
                        update={this.state.locationState.state ? 1 : 0}
                        name="department"
                        label="Department"
                        required
                        isDisabled={this.state.occupation === "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={TimePiece}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.timePiece}
                        update={this.state.locationState.state ? 1 : 0}
                        name="timePiece"
                        label="Time/Piece"
                        isDisabled={this.state.occupation === "" ? true : false}
                      />
                    </div>
                  </Box>
                  {this.state.timePiece === "Time" ||
                  this.state.timePiece === "Piece" ? (
                    <>
                      <Box gridColumn="span 3">
                        <TextFieldReq
                          value={this.state.norms}
                          caps="OFF"
                          onChange={this.onhandlechangeValue("norms")}
                          label="Norms"
                          disabled={
                            this.state.departmentValue === "" ? true : false
                          }
                        />
                      </Box>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            arrayOfData={machineType}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            isDropdownOpen={this.state.isDropdownOpen}
                            handleMenuOpen={this.handleMenuOpen}
                            selected={this.state.machineType}
                            update={this.state.locationState.state ? 1 : 0}
                            name="machineType"
                            label="Machine Type"
                            isDisabled={
                              this.state.departmentValue === "" ? true : false
                            }
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            arrayOfData={directIndirect}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            isDropdownOpen={this.state.isDropdownOpen}
                            handleMenuOpen={this.handleMenuOpen}
                            selected={this.state.directIndirectValue}
                            update={this.state.locationState.state ? 1 : 0}
                            name="directIndirect"
                            label="Direct/Indirect"
                            required
                            isDisabled={this.state.norms === "" ? true : false}
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            arrayOfData={OnMachine}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            isDropdownOpen={this.state.isDropdownOpen}
                            handleMenuOpen={this.handleMenuOpen}
                            selected={this.state.onMachine}
                            update={this.state.locationState.state? 1 : 0}
                            name="onMachine"
                            label="On Machine"
                            required
                            isDisabled={
                              this.state.directIndirectValue === ""
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <TextFieldNormal
                            label="Cost Code"
                            value={this.state.costCode}
                            caps="OFF"
                            onChange={this.onhandlechangeValue("costCode")}
                            disabled={
                              this.state.directIndirectValue === ""
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <TextFieldNormal
                            label="Cost Description"
                            value={this.state.costDescription}
                            caps="OFF"
                            onChange={this.onhandlechangeValue(
                              "costDescription"
                            )}
                            disabled={
                              this.state.directIndirectValue === ""
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Box>

                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <TextFieldNormal
                            type="number"
                            label="No Of Machines"
                            value={this.state.noOfMachines}
                            caps="OFF"
                            onChange={this.onhandlechangeValue("noOfMachines")}
                            disabled={
                              this.state.directIndirectValue === ""
                                ? true
                                : false
                            }
                          />
                        </div>
                      </Box>
                      {this.state.timePiece === "Piece" ? (
                        <Box gridColumn="span 3">
                          <div className="consoleTextFieldBlock">
                            <DynamicSelect
                              arrayOfData={PieceRateType}
                              className="dropdownBlockContainer"
                              menuPosition="fixed"
                              menuPlacement="auto"
                              onSelectChange={this.handlePropSelectChange}
                              isDropdownOpen={this.state.isDropdownOpen}
                              handleMenuOpen={this.handleMenuOpen}
                              selected={this.state.pieceRateTypeValue}
                              update={this.state.locationState.state ? 1 : 0}
                              name="pieceRateType"
                              label="Piece Rate Type"
                              isDisabled={
                                this.state.directIndirectValue === ""
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </Box>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdOn}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.state.locationState.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}

const directIndirect = [
  { label: "Direct", value: "D", name: "D" },
  { label: "Indirect", value: "I", name: "I" },
];
const PieceRateType = [
  { label: "Select", value: null, name: "Select" },
  { label: "Supervision", value: "1", name: "Supervision" },
  { label: "Machine", value: "2", name: "Machine" },
];

const OnMachine = [
  { label: "Yes", value: "Yes", name: "Yes" },
  { label: "No", value: "No", name: "No" },
];

const machineType = [
  { label: "Select", value: null, name: "Select" },
  { label: "R/G", value: "R/G", name: "R/G" },
  { label: "DH", value: "DH", name: "DH" },
  { label: "Loom", value: "Loom", name: "Loom" },
  { label: "S4 Loom", value: "S4 Loom", name: "S4 Loom" },
  { label: "SPREADER", value: "SPREADER", name: "SPREADER" },
  { label: "SPINNING", value: "SPINNING", name: "SPINNING" },
];

const TimePiece = [
  { label: "Select", value: null, name: "Select" },
  { label: "Time", value: "Time", name: "Time" },
  { label: "Piece", value: "Piece", name: "Piece" },
];

const mapStatetoProps = (state) => {
  const { Create_Designation_Master, Update_Designation_Master } =
    state.DesignationListReducer; // fetching create and update from Designation list reducer
  const { departmentList } = state.DropDownListReducer; // fetching department list from global dropdown reducer
  return {
    departmentList,
    Create_Designation_Master,
    Update_Designation_Master,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    createDesignationMaster,
    updateDesignationMaster,
    getDepartmentList,
  })(CreateDesignation)
);
