import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button, Step, Stepper, StepLabel } from "@mui/material";
import CustomButton from "../../../components/Buttons/Buttons";
import Link from "@mui/material/Link";
import { serverApi } from "../../../helpers/Consts";
import JuteMrPreview from "./JuteMRPreviewPage";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import JuteMaterialReceiptLineItems from "./JuteMaterialReceiptLineItems";
import JuteMaterialReceiptFillDetails from "./JuteMaterialReceiptFillDetails";
import {
  getMaterialGoodReceiveDetailsById,
  updateJuteMaterialReceipt,
} from "../../../store/Jute/JuteMaterialReceipt/actions";
import swal from "sweetalert";
import { isEmpty, isEmptyWithZero } from "../../../Utilities/helper";

const CreateJuteMaterialReceipt = (props) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const cipher = JSON.parse(localStorage.getItem("authUser")).cipher;
  const [currentStep, setCurrentStep] = useState(3);
  const userDit = JSON.parse(localStorage.getItem("authUser"));
  const [locationState] = useState(useLocation());

  const [steps] = useState([
    {
      title: "Fill Details",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Add Items",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Preview",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive03.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
  ]);
  const [headerData, setHeaderData] = useState({});
  const [juteMrlineItems, setJuteMrLineItems] = useState([]);
  const { juteMrHeaderData, juteMrLineItems } = useSelector(
    (state) => state.JuteMaterialReceiptReducer
  );

  useEffect(() => {
    setHeaderData(juteMrHeaderData);
  }, [juteMrHeaderData]);

  useEffect(() => {
    setJuteMrLineItems(juteMrLineItems);
  }, [juteMrLineItems]);

  useEffect(() => {
    dispatch(
      getMaterialGoodReceiveDetailsById(
        serverApi.GET_MATERIAL_GR_VIEW_BYID +
          props.location.state.rowData.mrNo +
          "/" +
          userDit.userId +
          "/" +
          userDit.cipher
      )
    );
  }, []);

  const onClickBefore = () => {
    setCurrentStep(currentStep - 1);
    if (currentStep === 1) {
      history.push("jute_material_receipt");
    }
  };

  const MandatoryCheck = () => {
    if (isEmpty(headerData.goodReceiptDate)) {
      swal("Please Select MR Date");
    } else if (isEmpty(headerData.actualSupplier)) {
      swal("Please Select Advised Supplier");
    } else if (isEmpty(headerData.actualSupplier)) {
      swal("Please Select Actual Supplier");
    } else if (isEmpty(headerData.gateEntryNo)) {
      swal("Please Enter Gate Entry Number");
    } else if (isEmpty(headerData.gateEntryDate)) {
      swal("Please Select Gate Entry Date");
    } else if (isEmpty(headerData.mukamName)) {
      swal("Please Enter Mukam");
    } else if (isEmpty(headerData.vehicleNo)) {
      swal("Please Enter Vehicle Number");
    } else if (isEmpty(headerData.unitConversion)) {
      swal("Please Enter Unit Conversion");
    } else if (isEmpty(headerData.weight)) {
      swal("Please Select Supplier");
    } else if (isEmpty(headerData.branchId)) {
      swal("Please Select branch");
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const onClickNext = () => {
    if (currentStep === 1) {
      // setCurrentStep(currentStep + 1);
      MandatoryCheck();
    } else if (currentStep === 2) {
      // setCurrentStep(currentStep + 1);
      var ValidateFields = false;
      let FilteredData = juteMrlineItems.filter((item) => item.isActive !== 0);
      if (FilteredData.length === 1) {
        
        for (let i = 0; i <= FilteredData.length - 1; i++) {

          if (isEmpty(FilteredData[i].itemId)) {
            swal("Please select the Item");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].actualQuality)) {
            swal("Please Enter the Quantity");
            return false;
          } else if (FilteredData[i].warehouseNo ===0 || FilteredData[i].warehouseNo ==="") {
            swal("Please select the Warehouse No");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].rate)) {
            swal("Please Enter the rate");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].quantity)) {
            swal("Please Enter the actual Weight");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].advisedWeight)) {
            swal("Please Enter the advised Weight");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].actualWeight)) {
            //here actualWeight is mill weight
            swal("Please Enter the Mill Weight");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].claimQuality)) {
            swal("Please Enter the claim Weight");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].claimDust)) {
            swal("Please Enter the claim Dust");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].claimsCondition)) {
            //here QC Moisture is claims Condition
            swal("Please Enter the QC Moisture");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].allowableMoisture)) {
            swal("Please Enter the allowable Moisture");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].variableShortage)) {
            swal("Please Enter the variable Shortage");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].shortageKgs)) {
            swal("Please Enter the shortage in Weight");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].acceptedWeight)) {
            swal("Please Enter the Approved Weight");
            return false;
          } else {
            ValidateFields = true;
          }
        }
      } else {
        let FilteredData = juteMrlineItems.filter(
          (item) => item.isActive !== 0 && item.itemId !== ""
        );
        for (let i = 0; i <= FilteredData.length - 1; i++) {

          if (isEmpty(FilteredData[i].itemId)) {
            swal("Please select the Item");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].actualQuality)) {
            swal("Please Enter the Quantity");
            return false;
          } else if (FilteredData[i].warehouseNo ===0 || FilteredData[i].warehouseNo ==="") {
            swal("Please select the Warehouse No");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].rate)) {
            swal("Please Enter the rate");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].quantity)) {
            swal("Please Enter the actual Weight");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].advisedWeight)) {
            swal("Please Enter the advised Weight");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].actualWeight)) {
            //here actualWeight is mill weight
            swal("Please Enter the Mill Weight");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].claimQuality)) {
            swal("Please Enter the claim Weight");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].claimDust)) {
            swal("Please Enter the claim Dust");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].claimsCondition)) {
            //here QC Moisture is claims Condition
            swal("Please Enter the QC Moisture");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].allowableMoisture)) {
            swal("Please Enter the allowable Moisture");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].variableShortage)) {
            swal("Please Enter the variable Shortage");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].shortageKgs)) {
            swal("Please Enter the shortage in Weight");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].acceptedWeight)) {
            swal("Please Enter the Approved Weight");
            return false;
          } else {
            ValidateFields = true;
          }
        }
      }
      if (ValidateFields === true) {
        setCurrentStep(currentStep + 1);
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { active, completed, className } = iconProp;

    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };

  const onClickUpdate = (status) => {
    var filterData = juteMrLineItems.filter((row) => {
      if (row.itemId !== "") {
        return row;
      }
    });

    const data = {
      materialGoodReceiveHeader: {
        id: location.state.rowData.mrNo,
        goodReceiptDate: "",
        supplierId: headerData.supplierId,
        actualSupplier: headerData.actualSupplier,
        advisedBroker: headerData.advisedBroker,
        actualBroker: headerData.actualBroker,
        poId: headerData.poId,
        poDate: headerData.poDate,
        chalanNo: headerData.challanNo,
        chalanDate: headerData.chalanDate,
        warehouseNo: headerData.warehouseNo,
        userId: userDit.userId,
        agentId: headerData.agentId,
        agentName: headerData.agentName,
        sourceId: headerData.sourceId,
        vehicleNo: headerData.vehicleNo,
        weight: headerData.juteGateNetWeight,
        mukamId: headerData.mukamId,
        frieghtPaid: headerData.frieghtPaid,
        modBy: headerData.modBy,
        status: status,
        createStatus: headerData.createStatus,
        remarks: headerData.remarks,
        branchId: headerData.branchId,
        tcsPercentage: headerData.tcsPercentage,
        tcsAmount: headerData.tcsAmount,
      },
      materialGRItemList: filterData.map((row) => ({
        id: row.id ? row.id : "",
        materialGrnHdrId: row.materialGrnHdrId,
        quantity: row.quantity,
        advisedQuality: row.advisedQuality,
        actualQuality: row.actualQuality,
        actualWeight: row.actualWeight,
        premiumAmount: row.premiumAmount,
        waterDamageAmount: row.waterDamageAmount,
        deviation: 0,
        rate: row.rate,
        claimsQuality: row.claimsQuality,
        claimQuality: row.claimQuality,
        claimsCondition: row.claimsCondition,
        claimDust: row.claimDust,
        warehouseNo: row.warehouseNo,
        remarks: "",
        totalPrice: row.totalPrice,
        itemId: row.itemId,
        debitNotesFlag: "",
        quantityUnit: "",
        issuableWeight: "0",
        bale: 0,
        loose: 0,
        actualLoose: 0,
        advisedItemId: row.itemId,
        allowableMoisture: row.allowableMoisture,
        advisedWeight: row.advisedWeight,
        shortageKgs: row.shortageKgs,
        variableShortage: row.variableShortage,
        acceptedWeight: row.acceptedWeight,
        marka: "",
        pota: "",
        cropYear: 2022,
        claimRate: row.claimRate,
        convertedBale: row.convertedBale,
        convertedDrum: row.convertedDrum,
        convertedWeight: row.convertedWeight,
      })),
      cipher: userDit.cipher,
    };

    dispatch(
      updateJuteMaterialReceipt(serverApi.UPDATE_JUTE_MR, data, history)
    );
  };
  const handleCurrentStep = () => {
    setCurrentStep(1);
  };
  return (
    <div>
      <Grid className="indentContainerBlock">
        <Grid xs={12} className="indentContainerMainBlock">
          <div className="iconsBlock">
            <Stepper alternativeLabel activeStep={currentStep}>
              {steps.map((prop) => (
                <Step key={prop.title}>
                  <StepLabel
                    StepIconComponent={(Iconprops) => {
                      return QontoStepIcon(
                        Iconprops,
                        prop.icon,
                        prop.activeIcon,
                        prop.title
                      );
                    }}
                  ></StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>

          {currentStep === 1 && (
            <>
              <JuteMaterialReceiptFillDetails headerData={headerData} locationState={locationState}/>
              <div className="consoleFormButtonBlock">
                <Link>
                  <CustomButton
                    label={"Cancel"}
                    className="greenBorderButton"
                    handleClick={onClickBefore}
                  />
                </Link>
                <CustomButton
                  label={"Next"}
                  className="greenButton"
                  handleClick={onClickNext}
                  type="sumbit"
                />
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <JuteMaterialReceiptLineItems headerData={headerData} locationState={locationState}/>
              <div className="consoleFormButtonBlock">
                <Link>
                  <CustomButton
                    label={"Cancel"}
                    className="greenBorderButton"
                    handleClick={onClickBefore}
                  />
                </Link>
                <CustomButton
                  label={"Next"}
                  className="greenButton"
                  handleClick={onClickNext}
                  type="sumbit"
                />
              </div>
            </>
          )}
          {currentStep === 3 && (
            <>
              <JuteMrPreview
                products={juteMrlineItems}
                headerData={headerData}
                handleCurrentStep={handleCurrentStep}
                locationState={locationState}
              />
              <div className="consoleFormButtonBlock">
                {juteMrHeaderData.status === "21" &&
                  locationState.state !== undefined && (
                    <CustomButton
                      label={"Open"}
                      className="approved"
                      handleClick={() => onClickUpdate("1")}
                      type="sumbit"
                    />
                  )}
                {juteMrHeaderData.UpdateButton &&
                  locationState.state !== undefined && (
                    <CustomButton
                      label={"Update"}
                      className="greenButton"
                      handleClick={() => onClickUpdate("")}
                      type="sumbit"
                    />
                  )}
                {locationState.state !== undefined &&
                  juteMrHeaderData.ApproveButton && (
                    <CustomButton
                      label={"Approve"}
                      className="approved"
                      handleClick={() => onClickUpdate(juteMrHeaderData.status)}
                      type="sumbit"
                    />
                  )}
                {locationState.state !== undefined &&
                  juteMrHeaderData.RejectButton && (
                    <CustomButton
                      label={"Reject"}
                      className="rejected"
                      handleClick={() => onClickUpdate("4")}
                      type="sumbit"
                    />
                  )}
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateJuteMaterialReceipt;
