import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import SearchBar from "../../../components/EditableTable/SearchBar/SearchBar";
import ProductTable from "../../../components/EditableTable/ProductTable/ProductTable";
import { getCostList } from "../../../store/Accounting/Bills/actions";
import BillLineItem from "./BillLineItem";
import { serverApi } from "../../../helpers/Consts";
import { getTax_List } from "../../../store/Global/DropDownApis/actions";

class BillsAddItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowcount: 1,
      show: false,
      checkAll: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
    };
    this.state.filterText = "";
    this.state.tooltip = "item description....";
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getCostList(
      serverApi.COST_FACTOR_LIST + "1/0",
      this.props.history
    );
    this.props.getTax_List(
      serverApi.GET_ALL_TAXLIST +
        this.state.userDit.userId +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    );
  }
  handleUserInput(filterText) {
    this.setState({ filterText: filterText });
  }
  handleCheckbox(isChecked, id, isAllCheck) {
    let list = this.state.products;
    if (isAllCheck) {
      list = list.map((product) => {
        return { ...product, checked: isChecked };
      });
      this.setState({ products: list, allChecked: isChecked });
    } else {
      list.forEach((product, i) => {
        if (product?.id === id) {
          list[i].checked = isChecked;
        }
      });
      let allChecked = list.every((item) => item.checked);
      this.setState({ products: list, allChecked: allChecked });
    }
  }

  handleRowDel(product) {
    var index = this.state.products.indexOf(product);

    this.state.products.splice(index, 1);
    this.setState(this.state.products);
  }
  handleKeydownEvent(event) {
    const resultData = [];
    this.props.indentList.data.map((prop, i) => {
      if (event.target.value === prop.item_code) {
        resultData.push({
          id: prop.id,
          costCenter: prop.costCenter,
          description: prop.description,
          uom: prop.uom,
          quantity: prop.quantity,
          rate: prop.rate,
          amount: prop.amount,
        });
      }
    });

    if (event.keyCode == 13) {
     
      let id = 0;
      this.state.products.map((prop) => (id = prop.id));
      if (event.target.id == id) this.handleAddEvent(event);
    }
  }

  handleAddEvent(evt) {
    var id = this.state.rowcount; 
    var product = {
      id: id,
      costCenter: "",
      description: "",
      uom: "",
      quantity: "",
      rate: 0,
      amount: 0,
    };
    var mcount = Number(this.state.rowcount) + 1;
    this.state.products.push(product);
    this.setState(this.state.products);
    this.setState({ rowcount: mcount });
  }

  handleProductTable(evt) {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    var products = this.state.products.slice();
    var newProducts = products.map(function (product) {
      for (var key in product) {
        if (key == item.name && product.id == item.id) {
          product[key] = item.value;
        }
      }
      return product;
    });
    this.setState({ products: newProducts });
  }
  handleChangeQuantity(selectedValue, selectedName, id) {
    this.setState({
      qty: selectedValue,
      uomCode: selectedName,
    });
    const resultData = [];
    if (this.state.products) {
      this.state.products.map((prop) => {
        if (prop.id == id) {
          var qty = prop.quantity;
          var vsqty = "";
          if (qty) {
            qty.map((val) => {
              if (selectedValue == val.value) {
                vsqty = val;
              }
            });
          }
          resultData.push({
            id: prop.id,
            costCenter: prop.costCenter,
            description: prop.description,
            uom: prop.uom,
            quantity: prop.quantity,
            rate: prop.rate,
            amount: prop.amount,
          });
        } else {
          resultData.push({
            id: prop.id,
            costCenter: prop.costCenter,
            description: prop.description,
            uom: prop.uom,
            quantity: prop.quantity,
            rate: prop.rate,
            amount: prop.amount,
          });
        }
      });

      this.setState({
        products: resultData,
      });
      this.props.onclickSave(resultData);
    }
  }

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <div>
        <SearchBar
          filterText={this.state.filterText}
          onUserInput={this.handleUserInput.bind(this)}
        />

        <BillLineItem
          tableHeaders={tableHeaders}
          RowData={RowData}
          products={this.props.products}
        />
      </div>
    );
  }
}
const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "costCenter",
    dropDown: "dropDown",
    name: "costCenter",
    Items: true,
  },
  {
    no: 2,
    component: "Inputadorment",
    type: "description",
  },
  {
    no: 3,
    component: "Inputadorment",
    type: "rate",
  },
  {
    no: 4,
    component: "Inputadorment",
    type: "qty",
  },
  {
    no: 5,
    component: "Inputadorment",
    type: "uom",
  },
  {
    no: 6,
    component: "EditableCell",
    type: "amount",
  },
  {
    no: 7,
    component: "DynamicSelect",
    type: "taxType",
    dropDown: "dropDown",
    name: "taxType",
    Items: true,
  },
];

const tableHeaders = [
  {
    header: "Cost Center",
  },
  {
    header: "Description",
  },
  {
    header: "Price",
  },
  {
    header: "Quantity",
  },
  {
    header: "UOM",
  },
  {
    header: "Amount",
  },
  {
    header: "Tax",
  },
  {
    header: "Actions",
  },
];

const mapStatetoProps = (state) => {
  const { indentList } = state.Indent;
  return { indentList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getCostList,
    getTax_List,
  })(BillsAddItems)
);
