/* Created By Nagesh Medisetty on 19-04-2022 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { headerData, changeLayout } from "../../store/layout/actions";

import LeftSidebar from "../../Sidebar/LeftSideBar";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import LeftSideBarForVendor from "../../Sidebar/LeftSideBarForVendor";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      drawerPos: 2,
      vendorLogin: false,
    };
  }

  handleDrawer = () => {
    if (this.state.drawerPos < 2) {
      this.setState({
        drawerPos: this.state.drawerPos + 1,
      });
    } else {
      this.setState({
        drawerPos: 1,
      });
    }
  };
  componentDidUpdate(prevProps) {}
  componentDidMount() {
    if (localStorage.getItem("authUser")) {
      let AuthUserData = JSON.parse(localStorage.getItem("authUser"));
      if (AuthUserData.roleId) {
        if (AuthUserData.roleId === 100) {
          this.setState({
            vendorLogin: true,
          });
        }
      }
    }
    this.props.headerData(this.props.location);
  }
  componentWillReceiveProps(props) {
    this.setState({
      rowdata: this.props.location.rowdata,
    });
  }

  render() {
    const headdata = this.props.header_data;

    let drawerClass = [];
    let mainClass = [];
    if (this.state.drawerPos === 1) {
      drawerClass.push("drawerMin");
      mainClass.push("mainMin");
    } else if (this.state.drawerPos === 2) {
      drawerClass.push("drawerOpen");
      mainClass.push("mainOpen");
    } else {
      drawerClass = [];
      mainClass = [];
    }
    return (
      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <i className="ri-loader-line spin-icon"></i>
            </div>
          </div>
        </div>
        <div className="">
          <aside className={drawerClass.join(" ")}>
            {this.state.vendorLogin === true ? (
              <LeftSideBarForVendor />
            ) : (
              <LeftSidebar />
            )}

            <div className="drawerIconLeft" onClick={this.handleDrawer}>
              <KeyboardArrowLeftOutlinedIcon />
            </div>
            <div className="drawerIconRight" onClick={this.handleDrawer}>
              <KeyboardArrowRightOutlinedIcon />
            </div>
          </aside>

          <section className={mainClass.join(" ")}>
            <Header
              headdate={headdata.subtitle}
              childName={headdata.parentName}
              buttonbranch={headdata.createbranchbtn}
              onNameChange={this.onChange}
              myCompanyId={this.state.userDit.orgId}
              backIcon={headdata.backicon}
              backLink={headdata.backlink}
              moreAbtn={headdata.moreAbtn}
            />
            <div className="portalMainContainer">{this.props.children}</div>
          </section>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};
export default connect(mapStatetoProps, {
  changeLayout,
  headerData,
})(withRouter(Layout));
