import {
  AGENT_NAME_LIST,
  AGENT_LIST_DROPDOWN,
  GET_BROKER_BY_SUPP_LIST,
  GET_PO_APPROVED_LIST,
  GET_QUALITY_BY_ITEM_LIST,
  GET_SUPP_BY_MUKAM_LIST,
  MUKAM_BY_ITEM_LIST,
  VEHICLE_TYPE_LIST,
  GET_PO_DETAILS_LIST,
  GET_VEHICLE_LIST,
  GET_GODOWN_NUMBER_LIST,
  GET_PLAN_NAME_LIST
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {
  getMukamByItemSuccessfull,
  getVehicleTypeSuccessfull,
  getSupplierByMukamIdSuccessfull,
  getBrokerBySupplierIdListSuccessfull,
  getQualityByItemListSuccessfull,
  getPoDropdownListSuccessfull,
  getPoDetailsListSuccessfull,
  getVehicleListSuccessfull,
  AgentListDropdownSuccessfull,
  getGodownNumberSuccessfull,
  getPlanNameSuccessfull,
} from "./actions";
import { getListurl, getList } from "../../helpers/Server_Helper";

function* getAgentNameList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    if (response) {
      yield put(AgentListDropdownSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getAgentDropdownList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getListurl, url, data);
    if (response) {
      yield put(AgentListDropdownSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* mukamByItemList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getListurl, url, data);
    if (response) {
      yield put(getMukamByItemSuccessfull({ response }));
    }
  } catch (error) {
    // console.log(error);
  }
}
function* vehicleTypeListData({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    if (response) {
      yield put(getVehicleTypeSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* SupplierByMukamData({ payload: { url, data, history } }) {
  try {
    const response = yield call(getListurl, url, data);
    if (response) {
      yield put(getSupplierByMukamIdSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* BorkerBySupplierData({ payload: { url, data, history } }) {
  try {
    const response = yield call(getListurl, url, data);
    if (response) {
      yield put(getBrokerBySupplierIdListSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* QualityByItemIdData({ payload: { url, data, history } }) {
  try {
    const response = yield call(getListurl, url, data);
    if (response) {
      yield put(getQualityByItemListSuccessfull({ response }));
    }
  } catch (error) {
    // console.log(error);
  }
}
function* PoApporvedListData({ payload: { url, data, history } }) {
  try {
    const response = yield call(getListurl, url, data);
    if (response) {
      yield put(getPoDropdownListSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* PoDetailsData({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    if (response) {
      yield put(getPoDetailsListSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* VehicleListData({ payload: { url, data, history } }) {
  try {
    const response = yield call(getListurl, url, data);
    if (response) {
      yield put(getVehicleListSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* godownListData({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    if (response) {
      yield put(getGodownNumberSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* PlanNameListData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (response) {
      yield put(getPlanNameSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
export function* watchAgentNameList() {
  yield takeEvery(AGENT_NAME_LIST, getAgentNameList);
  yield takeEvery(MUKAM_BY_ITEM_LIST, mukamByItemList);
  yield takeEvery(VEHICLE_TYPE_LIST, vehicleTypeListData);
  yield takeEvery(GET_SUPP_BY_MUKAM_LIST, SupplierByMukamData);
  yield takeEvery(GET_BROKER_BY_SUPP_LIST, BorkerBySupplierData);
  yield takeEvery(GET_QUALITY_BY_ITEM_LIST, QualityByItemIdData);
  yield takeEvery(GET_PO_APPROVED_LIST, PoApporvedListData);
  yield takeEvery(GET_PO_DETAILS_LIST, PoDetailsData);
  yield takeEvery(GET_VEHICLE_LIST, VehicleListData);
  yield takeEvery(AGENT_LIST_DROPDOWN, getAgentDropdownList);
  yield takeEvery(GET_GODOWN_NUMBER_LIST,godownListData);
  yield takeEvery(GET_PLAN_NAME_LIST,PlanNameListData);
}
function* MasterDropDownListSaga() {
  yield all([fork(watchAgentNameList)]);
}

export default MasterDropDownListSaga;
