import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {
  COSTCENTER_LIST,
  CREATE_COSTCENTER_MASTER,
  UPDATE_COSTCENTER_MASTER,
} from "./actionTypes";
import {
  CostCenterListSuccessfull,
  createCostCenterMasterSuccessfull,
  updateCostCenterMasterSuccessfull,
} from "./actions";
import { getList, postAdd } from "../../../helpers/Server_Helper";
import swal from "sweetalert";
import { hideSpinner, showSpinner } from "../../../helpers/Consts";

function* getCostCenterList({ payload: { url, data } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(CostCenterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateCostCenterMaster({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(createCostCenterMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/Cost_Center_Master");
      hideSpinner(true);
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateCostCenterMaster({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(updateCostCenterMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/Cost_Center_Master");
      hideSpinner(true);
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

export function* watchCostCenterList() {
  yield takeEvery(COSTCENTER_LIST, getCostCenterList);
}
export function* watchCreateCostCenterMaster() {
  yield takeEvery(CREATE_COSTCENTER_MASTER, CreateCostCenterMaster);
}
export function* watchUpdateCostCenterMaster() {
  yield takeEvery(UPDATE_COSTCENTER_MASTER, UpdateCostCenterMaster);
}

function* CostCenterListSaga() {
  yield all([
    fork(watchCostCenterList),
    fork(watchCreateCostCenterMaster),
    fork(watchUpdateCostCenterMaster),
  ]);
}

export default CostCenterListSaga;
