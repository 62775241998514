import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Gridwithcustomview from "../../../../components/Grid/PaginationGridView";
import GridArrowIcon from "../../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../../helpers/Consts";
import {
  getEmployeeList,
  getListRowData,
  clearProfileData,
  ClearFilesData,
} from "../../../../store/HRMS/EmployeeDataBase/actions";
class TotalEmployees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      employeeListData: [],
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      loadingdata: false,
      dataexp: "",
      departmentId: "",
      mobileNo: "",
      firstName: "",
      middleName: "",
      lastName: "",
      catagoryId: "",
      gender: "",
      status: "",
      branchId: "",
      contractorId: "",
      reportingEbId: "",
      esiNo: "",
      pfNo: "",
      empCode: "",
      designationId: "",
    };
  }

  componentDidMount() {
    this.props.getListRowData(null);
    this.props.clearProfileData(null);
    this.props.ClearFilesData(null);

    const data = {
      companyId: localStorage.getItem("companyId"),
      departmentId: "",
      mobileNo: "",
      firstName: "",
      middleName: "",
      lastName: "",
      catagoryId: "",
      gender: "",
      status: "",
      branchId: "",
      contractorId: "",
      reportingEbId: "",
      esiNo: "",
      pfNo: "",
      empCode: "",
      designationId: "",
      isActive: null,
      userId: JSON.parse(localStorage.getItem("authUser")).userId,
      startIndex: 1,
      lastIndex: 10,
      isActive: null,
    };
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };

    this.props.getEmployeeList(
      serverApi.NEW_EMPLOYEE_LIST,
      data,
      this.props.history
    );
    this.setState({ loadingdata: true, dataexp: expdata });
  }
  handleSearch = (data1) => {
    data1.lastIndex = 10;
    data1.startIndex = 1;
    data1.isActive = null;
    this.props.getEmployeeList(
      serverApi.NEW_EMPLOYEE_LIST,
      data1,
      this.props.history
    );
    this.setState({
      departmentId: data1.departmentId,
      mobileNo: data1.mobileNo,
      firstName: data1.firstName,
      middleName: data1.middleName,
      lastName: data1.lastName,
      catagoryId: data1.catagoryId,
      gender: data1.gender,
      status: data1.status,
      branchId: data1.branchId,
      contractorId: data1.contractorId,
      reportingEbId: data1.reportingEbId,
      esiNo: data1.esiNo,
      pfNo: data1.pfNo,
      empCode: data1.empCode,
      designationId: data1.designationId,
    });
  };
  componentWillReceiveProps(props) {
    if (props.employeeList) {
      if (props.employeeList.data) {
        this.setState({
          employeeListData: props.employeeList.data,
          totalRecs: props.employeeList.totalRecords,
          pageno: props.employeeList.page,
          loadingdata: false,
        });
        this.props.handleData("1", props.employeeList.totalRecords);
      }
    }
  }
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    const data = {
      companyId: localStorage.getItem("companyId"),
      departmentId: this.state.departmentId,
      mobileNo: this.state.mobileNo,
      firstName: this.state.firstName,
      middleName: this.state.middleName,
      lastName: this.state.lastName,
      catagoryId: this.state.catagoryId,
      gender: this.state.gender,
      status: this.state.status,
      branchId: this.state.branchId,
      contractorId: this.state.contractorId,
      reportingEbId: this.state.reportingEbId,
      esiNo: this.state.esiNo,
      pfNo: this.state.pfNo,
      empCode: this.state.empCode,
      designationId: this.state.designationId,
      isActive: null,
      userId: JSON.parse(localStorage.getItem("authUser")).userId,
    };
    if (type === 1) {
      data.startIndex = sizePerPage;
      data.lastIndex = currentIndex;
      data.isActive = null;
      if (currentIndex > 0) {
        this.props.getEmployeeList(
          serverApi.NEW_EMPLOYEE_LIST,
          data,
          this.props.history
        );
      }
    } else {
      data.startIndex = currentIndex;
      data.lastIndex = sizePerPage;
      data.isActive = null;
      if (currentIndex > 0) {
        this.props.getEmployeeList(
          serverApi.NEW_EMPLOYEE_LIST,
          data,
          this.props.history
        );
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.employeeListData}
          mcolumns={this.props.mcolumns}
          micon={GridArrowIcon}
          filter_form={"TotalEmployeedata"}
          handleSearch={this.handleSearch}
          actions={true}
          url="/add_employee"
          totalRecs={this.state.totalRecs}
          pageno={this.state.pageno}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          apiexp={serverApi.NEW_EMPLOYEE_LIST}
          payloadexp={this.state.dataexp}
          expName={"Total-Employees.csv"}
        />
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { employeeList } = state.EmployeeReducer;
  return { employeeList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getEmployeeList,
    getListRowData,
    clearProfileData,
    ClearFilesData,
  })(TotalEmployees)
);
