import {
  BUDGET_HEADS_LIST, BUDGET_HEADS_LIST_SUCCESSFULL,
  CREATE_BUDGET_HEADS, CREATE_BUDGET_HEADS_SUCCESSFULL, UPDATE_BUDGET_HEADS_SUCCESSFULL, UPDATE_BUDGET_HEADS, BUDGETHEADS_LIST,BUDGETHEADS_LIST_SUCCESSFULL
} from './actionTypes';

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  BudgetHeadsList: [],
  BudgetHeads_List: [],
  CreateBudgetHeads: [],
  UpdateBudgetHeads: [],
};

const BudgetHeadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case BUDGET_HEADS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case BUDGET_HEADS_LIST_SUCCESSFULL:
      state = {
        ...state,
        BudgetHeadsList: action.payload.response.data,
        loading: true,
      };
      break;
      case BUDGETHEADS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case BUDGETHEADS_LIST_SUCCESSFULL:
      state = {
        ...state,
        BudgetHeads_List: action.payload.response.data,
        loading: true,
      };
      break;
    case CREATE_BUDGET_HEADS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_BUDGET_HEADS_SUCCESSFULL:
      state = {
        ...state,
        CreateBudgetHeads: action.payload.response,
        loading: true,
      };
      break;
      case UPDATE_BUDGET_HEADS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_BUDGET_HEADS_SUCCESSFULL:
      state = {
        ...state,
        UpdateBudgetHeads: action.payload.response,
        loading: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
}

export default BudgetHeadsReducer;