import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// import DynamicSelect from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import DynamicSelect from "../../../Pages/StoreModule/MaterialRequest/DynamicSelect";
import { Grid, Box, IconButton, Button } from "@mui/material";
import { allItemGroupMasterList } from "../../../store/Global/DropDownApis/actions";
import { serverApi,serverConfig } from "../../../helpers/Consts";
import { getUomRelationValueByUomCode } from "../../../store/Sales/SalesOrder/actions";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import DeleteIcons from "../../../assets/images/picEditIcon.png";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import { getFileDownload } from "../../../helpers/server.js";
import {
  deleteSoFile,
  UploadSOFiles,
  getJuteYarnSoItemNameData
} from "../../../store/Sales/SalesOrder/actions";
import {
  convertOtherUOMTOPrimaryUOM,
  convertOtherRateUOmToPrimaryUOM,
  isEmpty,
} from "../../../Utilities/helper";
import {
  SalesOrderJuteYarnHeaders,
  SalesOrderJuteYarnLineItems,
  getItemsByItemGroupIdForJuteYarn,
} from "../../../store/Sales/SalesOrder/actions";
import swal from "sweetalert";
import { SalesGstCalculations } from "../../../Utilities/SalesGstCalculation";
import { limitDecimals } from "../../../Utilities/helper";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import {getSoYarnUomList} from '../../../store/Sales/SalesOrder/actions.js'
const JuteYarnLineItems = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [LineItemsDisplayTxtField, setLineItemsDisplayTxtField] = useState({});
  const [locationState] = useState(useLocation());
  const [LineItemsDisplayDropField, setLineItemsDisplayDropField] = useState(
    {}
  );
  const [ItemGroupData, setItemGroupData] = useState([]);
  const [uom2, setUom2] = useState([]);
  const [userDit, setuserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [SoJuteYarnHeadersData, setSoJuteYarnHeadersData] = useState({});
  const [SoJuteYarnLineItemsData, SetSoJuteYarnLineItemsData] = useState([]);

  const { allItemGroupMaster_List } = useSelector(
    (state) => state.DropDownListReducer
  );
  const { uomMastersList } = useSelector((state) => state.UomListReducer);
  const { SoJuteYarnLineItems, SoJuteYarnHeaders,SOFiles } = useSelector(
    (state) => state.SalesOrderReducer
  );

  const { quotationItemBy_ItemGroupId } = useSelector(
    (state) => state.QuotationReducer
  );
  const [salesOrderFiles, SetSalesOrderFiles] = useState(
    SOFiles?.data?.data || []
  );
  useEffect(() => {
    SetSalesOrderFiles(SOFiles?.data?.data);
  }, [SOFiles]);
  const onDeleteFile = (fileId) => {
    let data = salesOrderFiles.filter((item) => item.fileUploadId !== fileId);
    SetSalesOrderFiles(data);
    dispatch({ type: "UPDATE_SO_FILES", payload: data });
    dispatch(deleteSoFile(serverApi.DELETEFILE + fileId));
  };

  const handleUpload = (e) => {
    e.preventDefault();
    let sourceMenu = 45;

    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", sourceMenu);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", SoJuteYarnHeadersData.regularSalesId);
    formData.append(
      "createdBy",
      JSON.parse(localStorage.getItem("authUser")).userId
    );
    formData.append("fileCompanyId", localStorage.getItem("companyId"));

    dispatch(
      UploadSOFiles(
        serverApi.UPLOADFILE,
        formData,
        history,
        sourceMenu,
        SoJuteYarnHeadersData.regularSalesId
      )
    );
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    SetSoJuteYarnLineItemsData(SoJuteYarnLineItems);
    setSoJuteYarnHeadersData(SoJuteYarnHeaders);
  }, [SoJuteYarnLineItems, SoJuteYarnHeaders]);

  useEffect(() => {
    if (allItemGroupMaster_List) {
      if (allItemGroupMaster_List.data) {
        let ListFilter = allItemGroupMaster_List.data.filter((itemgrps) => {
          if (itemgrps.value !== "0") {
            return itemgrps;
          }
        });
        let list = [];
        ListFilter.map((itemGrp) => {
          list.push({
            label: itemGrp.label,
            name: itemGrp.name,
            value: itemGrp.value,
            variant: itemGrp.id,
          });
        });
        setItemGroupData(list);
      }
    }
  }, [allItemGroupMaster_List, quotationItemBy_ItemGroupId, uomMastersList]);
  const handleMenuOpen = (row,colType) => {   
    setisDropdownOpen(false);   
    SoJuteYarnLineItemsData.forEach((item)=>{
      if (item.id === row.id) {
        if(colType ==="yarnRateUom" || colType==="uom2"){
        if(row.uom2Data.length===0){
          swal("Please Add Variable UOM in the UOM Master");         
        }
      }
      }
    })   
  };
  
  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };

  const onDel = (row) => {
    var FilterLineItems = SoJuteYarnLineItemsData.filter(
      (item) => item.isActive !== 0
    );
    if (FilterLineItems.length == 1) {
      swal("you can't delete the last lineitem");
    } else {
      let indexItem = SoJuteYarnLineItemsData.findIndex((item) => item === row);
      let lineItems = [...SoJuteYarnLineItemsData];
      lineItems[indexItem].isActive = 0;
      lineItems[indexItem].yarnIsActive=0;
      SetSoJuteYarnLineItemsData(lineItems);
      dispatch(SalesOrderJuteYarnLineItems(lineItems));
      let Rate = SalesGstCalculations(
        {
          lineItems: lineItems,
          taxType: SoJuteYarnHeadersData.taxType,
          brokerCommisionPercent: SoJuteYarnHeadersData.brokerCommisionPercent,
        },
        "SO_JUTE_YARN_UOM"
      );
      handleCalculation(Rate);
    }
  };

  const displayField = (row, colname, type) => {
    if (colname === "itemGroupId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type === 1) {
        dispatch(
          allItemGroupMasterList(
            serverApi.GET_ALL_ITEMGROUP_MASTER_LIST +
              localStorage.getItem("companyId") +
              "/" +
              userDit.cipher,
            history
          )
        );
      }
    } else if (colname === "itemId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if(type==1){
        const data = {
          companyId: localStorage.getItem("companyId"),
          itemGroupId: row.itemGroupValue,
        };
        dispatch(
          getItemsByItemGroupIdForJuteYarn(
            serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
            data,
            history,
            row.id
          )
        );
      }
  
    } else if (colname === "uom2") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type === 1) {
      dispatch(
        getSoYarnUomList(
          serverApi.UOM_LIST_MASTERS_BASEDON_ITEM + localStorage.getItem("companyId") + "/" + "ItemId" + "/" + row.itemId,
          row.id,
          "UOM"
        )
      ); 
        }
   
    } else if (colname === "yarnRateUom") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type === 1) {

      dispatch(
        getSoYarnUomList(
          serverApi.UOM_LIST_MASTERS_BASEDON_ITEM + localStorage.getItem("companyId") + "/" + "ItemId" + "/" + row.itemId,
          row.id,
          "UOM"
        )
      );
        }
    } else if (colname === "discountType") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
    }
  };

  const OnhandleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    let mname = key.split("_");
    if (mname[0] === "remarks") {
      let LineItemsRec = SoJuteYarnLineItemsData.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.remarks = value;
        }
        return rowItem;
      });
      SetSoJuteYarnLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteYarnLineItems(LineItemsRec));
    }
    if (mname[0] === "itemMake") {
      let LineItemsRec = SoJuteYarnLineItemsData.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.itemMake = value;
        }
        return rowItem;
      });
      SetSoJuteYarnLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteYarnLineItems(LineItemsRec));
    }
    if (mname[0] === "hsncode") {
      let LineItemsRec = SoJuteYarnLineItemsData.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.hsncode = value;
        }
        return rowItem;
      });
      SetSoJuteYarnLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteYarnLineItems(LineItemsRec));
    }
    if (mname[0] === "rate") {
      let LineItemsRec = SoJuteYarnLineItemsData.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            rowItem.rate = value;
            rowItem.discount=0
            let yarnRateCalculatedValue =limitDecimals(convertOtherRateUOmToPrimaryUOM(
              value,
              rowItem.yarnRateUomRelationValue
            ),3);
            rowItem.yarnRateCalculatedValue =limitDecimals(yarnRateCalculatedValue,3);
            if (rowItem.discount !== "") {
              if (
                rowItem.discountType === 1 &&
                yarnRateCalculatedValue !== ""
              ) {
                rowItem.discountedRate = limitDecimals(
                  yarnRateCalculatedValue - rowItem.discount,
                  3
                );
              } else {
                let data = (yarnRateCalculatedValue * rowItem.discount) / 100;
                rowItem.discountedRate = limitDecimals(
                  yarnRateCalculatedValue - data,
                  3
                );
              }
            } else {
              rowItem.discountedRate = limitDecimals(
                yarnRateCalculatedValue,
                3
              );
            }
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        return rowItem;
      });
      SetSoJuteYarnLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteYarnLineItems(LineItemsRec));
      let Rate = SalesGstCalculations(
        {
          lineItems: SoJuteYarnLineItemsData,
          taxType: SoJuteYarnHeadersData.taxType,
          brokerCommisionPercent: SoJuteYarnHeadersData.brokerCommisionPercent,
        },
        "SO_JUTE_YARN_UOM"
      );
      handleCalculation(Rate);
    }
    if (mname[0] === "discount") {
      let LineItemsRec = SoJuteYarnLineItemsData.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (rowItem.discountType === 1) {
            if (Number(value) > Number(rowItem.yarnRateCalculatedValue)) {
              swal(
                `Discount value Shouldn't be greater than Rate ${rowItem.yarnRateCalculatedValue}`
              );
              rowItem.discount = 0;
              rowItem.discountedRate = limitDecimals(rowItem.yarnRateCalculatedValue,3);
              rowItem.discountAmount = "";
            } else {
              if (value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
                rowItem.discount = value;
                rowItem.discountedRate = limitDecimals(
                  rowItem.yarnRateCalculatedValue - value,
                  3
                );
                rowItem.yarnRateCalculatedValue =
                  convertOtherRateUOmToPrimaryUOM(
                    rowItem.rate,
                    rowItem.yarnRateUomRelationValue
                  );
              } else {
                swal(
                  "Please enter a positive number with up to 2 decimal places."
                );
              }
            }
          } else if (rowItem.discountType === 2) {
            if (Number(value) > 100) {
              swal("Discount percentage Shouldn't be greater than 100");
              rowItem.discountedRate = limitDecimals(
                rowItem.yarnRateCalculatedValue,
                3
              );
              rowItem.discount = 0;
              rowItem.discountAmount = "";
            } else {
              if (value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
                rowItem.discount = value;
                let data = (rowItem.yarnRateCalculatedValue * value) / 100;
                rowItem.discountedRate = limitDecimals(
                  rowItem.yarnRateCalculatedValue - data,
                  3
                );
                rowItem.yarnRateCalculatedValue =
                  convertOtherRateUOmToPrimaryUOM(
                    rowItem.rate,
                    rowItem.yarnRateUomRelationValue
                  );
              } else {
                swal(
                  "Please enter a positive number with up to 2 decimal places."
                );
              }
            }
          }
        }
        return rowItem;
      });
      SetSoJuteYarnLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteYarnLineItems(LineItemsRec));
      let Disc = SalesGstCalculations(
        {
          lineItems: SoJuteYarnLineItemsData,
          taxType: SoJuteYarnHeadersData.taxType,
          brokerCommisionPercent: SoJuteYarnHeadersData.brokerCommisionPercent,
        },
        "SO_JUTE_YARN_UOM"
      );
      handleCalculation(Disc);
    }
    if (mname[0] === "discountedRate") {
      let LineItemsRec = SoJuteYarnLineItemsData.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.discountedRate = limitDecimals(value,3);
        }
        return rowItem;
      });
      SetSoJuteYarnLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteYarnLineItems(LineItemsRec));
    }
    if (mname[0] === "quantity") {
      let LineItemsRec = SoJuteYarnLineItemsData.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            rowItem.quantity = value;
            rowItem.yarnQuantityCalculatedValue =limitDecimals(convertOtherUOMTOPrimaryUOM(
              rowItem.quantity,
              rowItem.yarnQuantityUomRelationValue
            ),3);
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        return rowItem;
      });
      SetSoJuteYarnLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteYarnLineItems(LineItemsRec));
      let Qty = SalesGstCalculations(
        {
          lineItems: SoJuteYarnLineItemsData,
          taxType: SoJuteYarnHeadersData.taxType,
          brokerCommisionPercent: SoJuteYarnHeadersData.brokerCommisionPercent,
        },
        "SO_JUTE_YARN_UOM"
      );
      handleCalculation(Qty);
    }
    if (mname[0] === "uom") {
      let LineItemsRec = SoJuteYarnLineItemsData.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.uom = value;
        }
        return rowItem;
      });
      SetSoJuteYarnLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteYarnLineItems(LineItemsRec));
    }
    if (mname[0] === "taxPercent") {
      let LineItemsRec = SoJuteYarnLineItemsData.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.taxPercent = limitDecimals(value);
        }
        return rowItem;
      });
      SetSoJuteYarnLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteYarnLineItems(LineItemsRec));
      let TaxPer = SalesGstCalculations(
        {
          lineItems: SoJuteYarnLineItemsData,
          taxType: SoJuteYarnHeadersData.taxType,
          brokerCommisionPercent: SoJuteYarnHeadersData.brokerCommisionPercent,
        },
        "SO_JUTE_YARN_UOM"
      );
      handleCalculation(TaxPer);
    }
    if (mname[0] === "netAmount") {
      let LineItemsRec = SoJuteYarnLineItemsData.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.netAmount = limitDecimals(value);
        }
        return rowItem;
      });
      SetSoJuteYarnLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteYarnLineItems(LineItemsRec));
    }
  };

  const HandleBlur = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    let mname = key.split("_");
    if (mname[0] === "rate") {
      let LineItemsRec = SoJuteYarnLineItemsData.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            rowItem.rate = value;
            if (Number(value) < Number(rowItem.discount)) {
              rowItem.rate = 0;
              rowItem.discountedRate = 0;
              swal("Rate must be greater than the discount.");
              return rowItem;
            }
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        return rowItem;
      });
      SetSoJuteYarnLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteYarnLineItems(LineItemsRec));
      let Rate = SalesGstCalculations(
        {
          lineItems: SoJuteYarnLineItemsData,
          taxType: SoJuteYarnHeadersData.taxType,
          brokerCommisionPercent: SoJuteYarnHeadersData.brokerCommisionPercent,
        },
        "SO_JUTE_YARN_UOM"
      );
      handleCalculation(Rate);
    }
  };

  const handleChange = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    selectedItemCode
  ) => {
    var lastRow = SoJuteYarnLineItemsData[SoJuteYarnLineItemsData.length - 1];
    if (name === "itemGroupId") {
      if (selectedRow === lastRow) {
        let LineItem = SoJuteYarnLineItemsData;
        let Obj = {
          id: selectedRow.id + 1,
            regularSalesOrderId: "",
            yarnSalesOrderLineItemId: "",
            yarnSalesOrderId: "",
            createdBy: "",
            createdDate: "",
            companyId: "",
            companyName: "",
            quotationId: "",
            quotationLineitemId: "",
            hsncode: "",
            itemGroupId: "",
            itemGroupValue: "",
            itemGroupName: "",
            itemId: null,
            itemName: "",
            itemCode: "",
            itemValue: "",
            itemMake: "",
            quantity: "",
            uom: "",
            uom2: "",
            rate: "",
            doBalQty: "",
            invoiceBalQty: "",
            igstAmount: "",
            igstPercent: "",
            cgstAmount: "",
            cgstPercent: "",
            sgstAmount: "",
            sgstPercent: "",
            discountType: "",
            discountName: "",
            discountAmount:"",
            discount: "",
            discountedRate: "",
            netAmount: "0.00",
            totalAmount: "0.00",
            remarks: "",
            taxPercent: "",
            isActive: 1,
            yarnIsActive:1,
            currency: "INR",
            status: "",
            yarnRateUom: "",
            yarnRateUomId: "",
            yarnRateUomRelationValue: "",
            yarnRateCalculatedValue: "",
            yarnQuantityUom: "",
            yarnQuantityUomId: "",
            yarnQuantityUomRelationValue: "",
            yarnQuantityCalculatedValue: "",
            yarnVariableRelationValue: "",
            yarnRateIncludingBrokerage: "",
            yarnRateExcludingBrokerage:"",
            updatedBy: null,
            updatedOn: null,
            uom2Data:[]
          
        };
        LineItem.push(Obj);
        SetSoJuteYarnLineItemsData(LineItem);
        dispatch(SalesOrderJuteYarnLineItems(LineItem));
      }
    }
    if (name === "itemGroupId") {
      let LineItemdata = SoJuteYarnLineItemsData.filter((row) => {
        if (row.id === selectedRow.id) {
          row.itemGroupId = selectedItemCode;
          row.itemGroupName = selectedName;
          row.itemGroupValue = selectedValue;
          row.itemId = null;
          row.itemName = "";
          row.itemValue = "";
          row.itemCode = "";
          row.remarks = "";
          row.itemMake = "";
          row.hsncode = "";
          row.rate = "0.00";
          row.discountType = "";
          row.discountName = "";
          row.discountAmount = "";
          row.discount = "0.00";
          row.discountedRate = "";
          row.quantity = "0.000";
          row.uom = "";
          row.uom2 = "";
          row.taxPercent = "";
          row.netAmount = "0.00";
          row.yarnQuantityUom = "";
          row.yarnQuantityUomId = null;
          row.yarnRateUom = "";
          row.yarnRateUomId = null;
        }
        return row;
      });
      SetSoJuteYarnLineItemsData(LineItemdata);
      dispatch(SalesOrderJuteYarnLineItems(LineItemdata));
      displayField(selectedRow, "itemGroupId", 2);

      const data = {
        companyId: localStorage.getItem("companyId"),
        itemGroupId: selectedValue,
      };

      dispatch(
        getItemsByItemGroupIdForJuteYarn(
          serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
          data,
          history,
          selectedRow.id
        )
      );
    }
    if (name === "itemId") {
      let filteredLineItems = SoJuteYarnLineItemsData.filter(
        (item) => item.isActive !== 0
      );
      //this code is to check the item duplication.......
      if (selectedRow.itemId !== selectedValue) {
        var checkItem = !!filteredLineItems.find(
          ({ itemId }) => Number(itemId) === Number(selectedValue)
        );
      }
      if (checkItem) {
        swal("You can't create the record with the duplicate item");
        let LineItemdata = SoJuteYarnLineItemsData.filter((row) => {
          if (row.id === selectedRow.id) {
            row.itemId = null;
            row.itemValue = "";
            row.itemCode = "";
            row.itemName = "";
            row.hsncode = "";
            row.rate = "";
            row.discountedRate = "";
            row.uom = "";
            row.uom2 = "";
            row.remarks = "";
            row.itemMake = "";
            row.discountType = "";
            row.discountName = "";
            row.discount = "";
            row.discountAmount = "";
            row.taxPercent = "";
            row.netAmount = "0.00";
            row.yarnQuantityUomId = "";
            row.yarnRateUomId = "";
          
          }
          return row;
        });
        SetSoJuteYarnLineItemsData(LineItemdata);
        dispatch(SalesOrderJuteYarnLineItems(LineItemdata));
        displayField(selectedRow, "itemId", 2);
        displayField(selectedRow, "uom2", 1);
        displayField(selectedRow, "yarnRateUom", 1);
      } else {
        var ItemGroup = "";
        let LineItemdata = SoJuteYarnLineItemsData.filter((row) => {
          if (row.id === selectedRow.id) {
            ItemGroup = row.itemGroupValue;
            row.itemId = selectedValue;
            row.itemValue = selectedValue;
            row.itemName = selectedName;
            row.itemCode = row.itemGroupValue + selectedItemCode;
            row.yarnQuantityUom = "";
            row.yarnQuantityUomId = null;
            row.yarnRateUom = "";
            row.yarnRateUomId = null;

          }
          return row;
        });
        SetSoJuteYarnLineItemsData(LineItemdata);
        dispatch(SalesOrderJuteYarnLineItems(LineItemdata));
        displayField(selectedRow, "itemId", 2);
     
        const data = {
          itemGroupId: ItemGroup,
          companyId: localStorage.getItem("companyId"),
          itemId: selectedValue,
          branchId: SoJuteYarnHeadersData.branchId,
        };
        dispatch(
          getJuteYarnSoItemNameData(
            serverApi.GET_ITEMDETAILS_BYITEM_ITEMGRP_COMPANYID,
            data,
            selectedRow.id
          )
        );
        dispatch(
          getSoYarnUomList(
            serverApi.UOM_LIST_MASTERS_BASEDON_ITEM + localStorage.getItem("companyId") + "/" + "ItemId" + "/" +selectedValue,
            selectedRow.id
          )
        ); 
      }
    }
    if (name === "discountType") {
      let LineItemdata = SoJuteYarnLineItemsData.filter((row) => {
        if (row.id === selectedRow.id) {
          if (selectedValue === "") {
            row.discountType = "";
            row.discountName = "";
            row.discount = "";
            row.discountedRate = limitDecimals(row.yarnRateCalculatedValue, 3);
            row.discountAmount = "0.00";
          } else {
            row.discountType = selectedValue;
            row.discountName = selectedName;
            row.discount = "";
            row.discountedRate = limitDecimals(row.yarnRateCalculatedValue, 3);
            row.discountAmount = "0.00";
            if (Number(selectedValue) === 1) {
              if(!isEmpty(row.discount)){
              row.discountedRate = limitDecimals(
                row.yarnRateCalculatedValue - row.discount,
                3
              );
              }
            } else {
              if(!isEmpty(row.discount)){
              let data = (row.yarnRateCalculatedValue * row.discount) / 100;
              row.discountedRate = limitDecimals(
                row.yarnRateCalculatedValue - data,
                3
              );
             } }
          }
        }
        return row;
      });
      SetSoJuteYarnLineItemsData(LineItemdata);
      dispatch(SalesOrderJuteYarnLineItems(LineItemdata));
      displayField(selectedRow, "discountType", 2);
    }
    if (name === "yarnRateUom") {
      let PrimaryUom = "";
      let ItemId = "";
      let LineItemsRec = SoJuteYarnLineItemsData.filter((row) => {
        if (row.id === selectedRow.id) {
          row.yarnRateUom = selectedValue;
          row.yarnRateUomId = selectedItemCode;
          PrimaryUom = row.uom;
          ItemId = row.itemId;
          row.discount=0
        }
        return row;
      });
      SetSoJuteYarnLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteYarnLineItems(LineItemsRec));
      dispatch(
        getUomRelationValueByUomCode(
          serverApi.GET_UOM_RELATION_VALUE_BY_UOM_CODE +
            localStorage.getItem("companyId") +
            "/fromUom" +
            "/" +
            PrimaryUom +
            "/toUom" +
            "/" +
            selectedValue +
            "/ItemId/"+
            ItemId,
          ItemId,
          "yarnRateUom",
          history
        )
      );
      displayField(selectedRow, "yarnRateUom", 2);
    }
    if (name === "uom2") {
      let PrimaryUom = "";
      let ItemId = "";
      let LineItemsRec = SoJuteYarnLineItemsData.filter((row) => {
        if (row.id === selectedRow.id) {
          row.yarnQuantityUom = selectedValue;
          row.yarnQuantityUomId = selectedItemCode;
          PrimaryUom = row.uom;
          ItemId = row.itemId;
        }
        return row;
      });
      SetSoJuteYarnLineItemsData(LineItemsRec);
      dispatch(SalesOrderJuteYarnLineItems(LineItemsRec));
      dispatch(
        getUomRelationValueByUomCode(
          serverApi.GET_UOM_RELATION_VALUE_BY_UOM_CODE +
            localStorage.getItem("companyId") +
            "/fromUom" +
            "/" +
            PrimaryUom +
            "/toUom" +
            "/" +
            selectedValue +
            "/ItemId/"+
            ItemId,
          ItemId,
          "quantityUOM",
          history
        )
      );
      displayField(selectedRow, "uom2", 2);
    }
    let DiscType = SalesGstCalculations(
      {
        lineItems: SoJuteYarnLineItemsData,
        taxType: SoJuteYarnHeadersData.taxType,
        brokerCommisionPercent: SoJuteYarnHeadersData.brokerCommisionPercent,
      },
      "SO_JUTE_YARN_UOM"
    );
    handleCalculation(DiscType);
  };

  const handleCalculation = (Calculations) => {
    SetSoJuteYarnLineItemsData(Calculations.lineItems);
    dispatch(SalesOrderJuteYarnLineItems(Calculations.lineItems));

    let JuteYarnHeadersData = {
      ...SoJuteYarnHeadersData,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
    };
    setSoJuteYarnHeadersData((prev) => ({
      ...prev,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
    }));
    dispatch(SalesOrderJuteYarnHeaders(JuteYarnHeadersData));
  };
  const disableField = (row, col) => {
    if (row.regularSalesOrderLineItemId === null) {
      return false;
    } else if (
      col.type === "remarks" ||
      col.type === "itemMake" ||
      col.type === "hsncode" ||
      col.type === "rate" ||
      col.type === "discount" ||
      col.type === "discountType" ||
      col.type === "quantity" ||
      col.type === ""
    ) {
      return false;
    } else {
      return true;
    }
  };
  const handleKeyPress = (event) => {
    // Prevent 'e' and '-' characters
    if (event.key === "e" || event.key === "-") {
      event.preventDefault();
    }
  };
  let filterProduct = SoJuteYarnLineItemsData.filter(
    (item) => item.isActive !== 0
  );
  let lastLineItem =
    SoJuteYarnLineItemsData[SoJuteYarnLineItemsData.length - 1];
    
  return (
    <>
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent" onScroll={handleScroll}>
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {tableHeaders.map((column) => (
                    <>
                      {column.header === "Item Group" ||
                      column.header === "Item Name" ||
                      column.header === "Rate" ||
                      column.header === "hsncode" ||
                      column.header === "Quantity" ||
                      column.header === "UOM" || 
                      column.header === "UOM2"||
                      column.header === "Rate UOM" 
                      ? (
                        <>
                          <th>
                            {column.header}
                            <label style={{ color: "red" }}>*</label>
                          </th>
                        </>
                      ) : (
                        <>
                          <th>{column.header}</th>
                        </>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filterProduct.length !== 0 &&
                  filterProduct.map((row, i) => {
                    let rowIndex = filterProduct.findIndex(
                      (idd) => idd === row
                    );
                    return (
                      <>
                        <tr>
                          <>
                            {RowData &&
                              RowData.map((col) =>
                                col.component === "Editablecell" ? (
                                  <>
                                    <td>
                                      <div className="quantityDropdownBlock">
                                        <Grid className="selectAndTextfield">
                                          <Grid
                                            xs={12}
                                            md={12}
                                            className="dynamicSelectBlock"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <input
                                              onWheel={(e) => e.target.blur()}
                                              type={
                                                col.type !== "remarks" &&
                                                col.type !== "hsncode" &&
                                                col.type !== "itemMake" &&
                                                col.type !== "uom"
                                                  ? "number"
                                                  : "text"
                                              }
                                              onKeyPress={
                                                col.type !== "remarks" &&
                                                col.type !== "hsncode" &&
                                                col.type !== "itemMake" &&
                                                col.type !== "uom"
                                                  ? handleKeyPress
                                                  : "text"
                                              }
                                              name={col.type + "_" + rowIndex}
                                              disabled={
                                                col.type === "uom"
                                                  ? true
                                                  : col.type === "netAmount"
                                                  ? true
                                                  : col.type === "taxPercent"
                                                  ? true
                                                  : col.type === "hsncode"
                                                  ? true
                                                  : col.type ===
                                                    "discountedRate"
                                                  ? true
                                                  : col.type === "discount"
                                                  ? row.discountType === ""
                                                    ? true
                                                    : false
                                                  : SoJuteYarnLineItemsData.quotationNo !==
                                                    ""
                                                  ? disableField(row, col)
                                                  : false
                                              }
                                              value={
                                                col.type === "remarks"
                                                  ? row.remarks
                                                  : col.type === "itemMake"
                                                  ? row.itemMake
                                                  : col.type === "hsncode"
                                                  ? row.hsncode
                                                  : col.type == "rate"
                                                  ? row.rate
                                                  : col.type === "discount"
                                                  ? row.discount
                                                  : col.type ===
                                                    "discountedRate"
                                                  ? row.discountedRate
                                                  : col.type === "quantity"
                                                  ? row.quantity
                                                  : col.type === "uom"
                                                  ? row.uom
                                                  : col.type === "taxPercent"
                                                  ? row.taxPercent
                                                  : col.type == "netAmount"
                                                  ? row.netAmount
                                                  : col.type ==
                                                    "yarnRateIncludingBrokerage"
                                                  ? row.yarnRateIncludingBrokerage
                                                  : ""
                                              }
                                              onChange={OnhandleChange({
                                                key: col.type + "_" + rowIndex,
                                                row,
                                              })}
                                              onBlur={HandleBlur({
                                                key: col.type + "_" + rowIndex,
                                                row,
                                              })}
                                              className="inputBlockContainer"
                                              style={{
                                                backgroundColor:
                                                  col.type ===
                                                    "discountedRate" ||
                                                  col.type ===
                                                    "hsncode" ||
                                                  col.type ===
                                                    "yarnRateIncludingBrokerage"
                                                    ? "rgb(179 214 231)"
                                                    : "",
                                                textAlign:
                                                  col.type !== "remarks" &&
                                                  col.type !== "itemMake" &&
                                                  col.type !== "hsncode" &&
                                                  col.type !== "uom"
                                                    ? "right"
                                                    : "",
                                                paddingRight: "8px",
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </td>
                                  </>
                                ) : col.component === "DynamicSelect" ? (
                                  <td>
                                    <div className="quantityDropdownBlock">
                                      <Grid className="selectAndTextfield">
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: LineItemsDisplayTxtField[
                                              col.type + row.id + "1"
                                            ]
                                              ? "none"
                                              : "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          {/* <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={
                                              row.itemGroupId === ""
                                                ? col.type === "itemId"
                                                  ? "Please Select Item Group"
                                                  : col.type === "itemId" &&
                                                    row.itemName
                                                : col.type === "itemId" &&
                                                  row.itemName
                                            }
                                          > */}
                                          <input
                                            type="text"
                                            className="inputBlockContainer"
                                            style={{ paddingRight: "8px" }}
                                            value={
                                              col.type === "itemGroupId"
                                                ? row.itemGroupName
                                                : col.type === "itemId"
                                                ? row.itemName
                                                : col.type === "discountType"
                                                ? row.discountName
                                                : col.type === "uom2"
                                                ? row.yarnQuantityUom
                                                : col.type === "yarnRateUom"
                                                ? row.yarnRateUom
                                                : ""
                                            }
                                            disabled={
                                              col.type === "itemId"
                                                ? row.itemGroupId === ""
                                                  ? true
                                                  : false
                                                : col.type === "discountType"
                                                ? row.rate === ""
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            onChange={OnhandleChange({
                                              key: col.type + "_" + rowIndex,
                                              row,
                                            })}
                                            onClick={() => {
                                              displayField(row, col.type, 1);
                                            }}
                                          />
                                        </Grid>
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: LineItemsDisplayDropField[
                                              col.type + row.id + "2"
                                            ]
                                              ? "flex"
                                              : "none",
                                            alignItems: "center",
                                          }}
                                        >
                                          <DynamicSelect
                                            arrayOfData={
                                              col.type === "itemGroupId"
                                                ? ItemGroupData
                                                : col.type === "itemId"
                                                ? row.itemsListData
                                                : col.type === "discountType"
                                                ? discount_Type
                                                : col.type === "uom2"
                                                ? row.uom2Data
                                                : col.type === "yarnRateUom"
                                                ? row.uom2Data
                                                : ""
                                            }
                                            className="dropdownBlockContainer"
                                            onSelectChange={handleChange}
                                            selected={
                                              col.type === "itemGroupId"
                                                ? row.itemGroupValue
                                                : col.type === "itemId"
                                                ? row.itemId
                                                : col.type === "discountType"
                                                ? row.discountType
                                                : col.type === "uom2"
                                                ? row.yarnQuantityUom
                                                : col.type === "yarnRateUom"
                                                ? row.yarnRateUom
                                                : ""
                                            }
                                            name={
                                              col.type === "itemGroupId"
                                                ? "itemGroupId"
                                                : col.type === "itemId"
                                                ? "itemId"
                                                : col.type === "discountType"
                                                ? "discountType"
                                                : col.type === "uom"
                                                ? "uom"
                                                : col.type === "uom2"
                                                ? "uom2"
                                                : col.type === "yarnRateUom"
                                                ? "yarnRateUom"
                                                : ""
                                            }
                                            row={row}
                                            update={
                                              row.itemGroupValue ||
                                              row.itemId ||
                                              row.discountType ||
                                              row.uom ||
                                              row.yarnQuantityUom ||
                                              row.yarnRateUom
                                                ? 1
                                                : 0
                                            }
                                            isDropdownOpen={isDropdownOpen}
                                            handleMenuOpen={()=>handleMenuOpen(row,col.type)}
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </td>
                                ) : (
                                  ""
                                )
                              )}
                          </>
                          <td>
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Delete"
                            >
                              <IconButton
                                onClick={() => {
                                  onDel(row, i);
                                }}
                                disabled={
                                  filterProduct.length === 1
                                    ? true
                                    : lastLineItem === row
                                    ? true
                                    : false
                                }
                              >
                                <DeleteIcon className="deleteDisplayButton" />
                              </IconButton>
                            </Tooltip>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="consoleFormContainer">
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 12">
                      <div
                        className="calculationBlockTable"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <table>
                          <tr>
                            <td>Taxable Value:</td>
                            <b>
                              {" "}
                              {limitDecimals(
                                SoJuteYarnHeadersData.netTotal
                              ,2)}
                            </b>
                          </tr>
                          <tr>
                            <td>Total IGST:</td>
                            <b>
                              {" "}
                              {limitDecimals(
                                SoJuteYarnHeadersData.totalIGST
                              ,2)}
                            </b>
                          </tr>
                          <tr>
                            <td>Total SGST:</td>
                            <td>
                              <b>
                                {limitDecimals(
                                  SoJuteYarnHeadersData.totalSGST
                                ,2)}
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>Total CGST:</td>
                            <td>
                              <b>
                                {limitDecimals(
                                  SoJuteYarnHeadersData.totalCGST
                                ,2)}
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>
                                Brokerage @
                                {SoJuteYarnHeadersData.brokerCommisionPercent ||
                                  "0.00"}
                                %
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>Total Value with Tax:</td>
                            <b>
                              {limitDecimals(
                                SoJuteYarnHeadersData.TotalAmount
                              ,2)}
                            </b>
                          </tr>
                        </table>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
          {locationState.state !== undefined ? (
            <div className="consoleFormContainer">
              <div style={{ height: "15px" }}></div>
              <div className="consoleFormBlock" style={{ display: "flex" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                      className="ducumentUploadBlock uploadedContent"
                    >
                      <Box gridColumn="span 10">
                        {salesOrderFiles?.length > 0 &&
                          salesOrderFiles.map((item) => (
                            <div
                              key={item.fileUploadId}
                              className="uploadedInfo"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "10px",
                                padding: "5px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                              }}
                            >
                              <img
                                src={TickIcon}
                                alt=""
                                className="tickIcon"
                                height={"20px"}
                                width={"20px"}
                              />
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title="Click to download File"
                              >
                                <div
                                  className="uplodedFileName"
                                  onClick={() => {
                                    getFileDownload(
                                      serverConfig.SERVER_URL +
                                        "security-api/api/files/downloadfile/" +
                                        item.fileUploadId,
                                      item.fileName,
                                      item.fileExtension
                                    );
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                  }}
                                >
                                  {item.fileName}
                                </div>
                              </Tooltip>
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 100 }}
                                title="Click to Delete File"
                              >
                                <Button
                                  onClick={() => {
                                    onDeleteFile(item.fileUploadId);
                                  }}
                                  className="deleteButton"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <img
                                    src={DeleteIcons}
                                    alt=""
                                    height={"20px"}
                                    width={"20px"}
                                  />
                                </Button>
                              </Tooltip>
                            </div>
                          ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 4">
                        <div style={{ marginBottom: "10px" }}>
                          <b>Support Documents:</b>
                          <span style={{ color: "red" }}></span>
                        </div>
                        <div className="personalDetailsUpload">
                          <div className="ducumentUploadBlock">
                            <div className="documentUploadContent">
                              <span
                                className="btn_upload documentUpload"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={panUploadIcon}
                                  alt=""
                                  style={{ marginRight: "10px" }}
                                />
                                <input
                                  type="file"
                                  id="imag"
                                  title=""
                                  className="input-img"
                                  onChange={handleUpload}
                                />
                                Upload Document
                              </span>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "itemGroupId",
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "itemId",
  },
  {
    no: 3,
    component: "Editablecell",
    type: "remarks",
  },
  {
    no: 4,
    component: "Editablecell",
    type: "hsncode",
  },
  {
    no: 5,
    component: "Editablecell",
    type: "quantity",
  },
  {
    no: 6,
    component: "Editablecell",
    type: "uom",
  },
  {
    no: 7,
    component: "DynamicSelect",
    type: "uom2",
  },
  {
    no: 8,
    component: "Editablecell",
    type: "rate",
  },
  {
    no: 9,
    component: "DynamicSelect",
    type: "yarnRateUom",
  },
  {
    no: 10,
    component: "DynamicSelect",
    type: "discountType",
  },
  {
    no: 11,
    component: "Editablecell",
    type: "discount",
  },
  {
    no: 12,
    component: "Editablecell",
    type: "discountedRate",
  },
  {
    no: 13,
    component: "Editablecell",
    type: "yarnRateIncludingBrokerage",
  },
  {
    no: 14,
    component: "Editablecell",
    type: "taxPercent",
  },
  {
    no: 15,
    component: "Editablecell",
    type: "netAmount",
  },
];
const tableHeaders = [
  {
    header: "Item Group",
  },
  {
    header: "Item Name",
  },
  {
    header: "Remarks",
  },
  {
    header: "HSN",
  },
  {
    header: "Quantity",
  },
  {
    header: "UOM",
  },
  {
    header: "UOM2",
  },
  {
    header: "Rate",
  },
  {
    header: "Rate UOM",
  },
  {
    header: "Discount Mode",
  },
  {
    header: "Discount",
  },
  {
    header: "Rate After Discount",
  },
  {
    header: "Rate After Brokerage",
  },
  {
    header: "Tax%",
  },
  {
    header: "Amount",
  },
  {
    header: "Actions",
  },
];
const discount_Type = [
  {
    value: "",
    label: "Select",
    name: "Select",
  },
  {
    value: 1,
    label: "Amount",
    name: "Amount",
  },
  {
    value: 2,
    label: "Percentage",
    name: "Percentage",
  },
];

export default JuteYarnLineItems;
