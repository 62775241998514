import {LEAVETYPELIST, LEAVETYPELIST_SUCCESSFULL,
    CREATE_LEAVE_TYPE, CREATE_LEAVE_TYPE_SUCCESSFULL,
    UPDATE_LEAVE_TYPE, UPDATE_LEAVE_TYPE_SUCCESSFULL, LEAVETYPESLIST, LEAVETYPESLIST_SUCCESSFULL} from './actionTypes';



export const getLeaveTypeList = (url,history) =>{
    return {
        type:LEAVETYPELIST,
        payload:{url,history}
    }
}

export const LeaveTypeListSuccessfull = (data)=>{
    return {
        type:LEAVETYPELIST_SUCCESSFULL,
        payload:data
    }
}
//Post method
export const getLeaveTypesList = (url,data,history) =>{
    return {
        type:LEAVETYPESLIST,
        payload:{url,data,history}
    }
}

export const LeaveTypesListSuccessfull = (data)=>{
    return {
        type:LEAVETYPESLIST_SUCCESSFULL,
        payload:data
    }
}
export const createLeaveType = (url, data, history) => {
    return {
        type: CREATE_LEAVE_TYPE,
        payload: { url, data, history }
    }
}

export const createLeaveTypeSuccessfull = (data) => {
    return {
        type: CREATE_LEAVE_TYPE_SUCCESSFULL,
        payload: data
    }
}
export const updateLeaveType = (url, data, history) => {
    return {
        type: UPDATE_LEAVE_TYPE,
        payload: { url, data, history }
    }
}

export const updateLeaveTypeSuccessfull = (data) => {
    return {
        type: UPDATE_LEAVE_TYPE_SUCCESSFULL,
        payload: data
    }
}

