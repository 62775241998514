import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import {
  serverApi,
  getTenantId,
  showSpinner,
  hideSpinner,
} from "../../../helpers/Consts";
import {
  PayPeriodById,
  processedList,
  updatePayPeriod,
  generateInvoice,
  getPayRegisterList,
  getPaySalaryList,
} from "../../../store/HRMS/PayRegister/actions";
import CustomButton from "../../../components/Buttons/Buttons";
import moment from "moment";
import pdfIcon from "../../../assets/images/pdfIcon.png";
class ViewPayRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      pay_PeriodById: [],
      pay_PeriodById_col: [],
      Processed_List: [],
      paySalaryData: [],
      Processed_List_col: [],
      show: false,
      EPF: [],
      paySalaryColumn: [],
      // startIndex: 1,
      // lastIndex: 10,
    };
  }
  componentDidMount() {
    this.props.PayPeriodById(
      serverApi.PAY_PERIOD_BY_ID +
        this.props.location.state.rowData.id +
        "/" +
        this.state.userDit.userId,
      this.props.history
    );

    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData !== undefined) {
        //  var DateOfBirth = moment(Date, "YYYY-MM-DD", true ).format("DD-MM-YYYY");
        // var fromdate = this.props.location.state.rowData.fromDate.split("T", 1).toString();
        // var NewfromDate = moment(fromdate, "YYYY-MM-DD", true).format("DD-MM-YYYY");

        // var todate = this.props.location.state.rowData.toDate.split("T", 1).toString();
        // var NewToDate = moment(todate, "YYYY-MM-DD", true).format("DD-MM-YYYY");
        const data1 = {
          companyId: this.props.location.state.rowData.companyId,
          branchId: this.props.location.state.rowData.branchId,
          paySchemeId: this.props.location.state.rowData.paySchemeId,
          fromDate: this.props.location.state.rowData.fromDateDesc,
          toDate: this.props.location.state.rowData.toDateDesc,
          payPeriodId: this.props.location.state.rowData.id,
        };
        this.props.getPaySalaryList(
          serverApi.GET_MONTHLY_PAYSALARY,
          data1,
          this.props.history
        );
      }
    }

    // this.props.processedList(
    //   serverApi.PROCESSED_LIST +
    //     this.props.location.state.rowData.paySchemeId +
    //     "/" +
    //     this.props.location.state.rowData.id,
    //   this.props.history
    // );
  }

  componentWillReceiveProps(props) {
    if (props.payPeriodById) {
      this.setState({
        pay_PeriodById: props.payPeriodById.data,
        pay_PeriodById_col: props.payPeriodById.column,
      });
      if (props.payPeriodById) {
        if (props.payPeriodById.data) {
          if (props.payPeriodById.data) {
            if (props.payPeriodById.data.approveButton === true) {
              this.setState({
                show: true,
              });
            }
          }
        }
      }
    }
    // if (props.processed_List) {
    //   this.setState({
    //     Processed_List: props.processed_List.data,
    //     Processed_List_col: props.processed_List.column,
    //   });
    // }
    if (props.paySalaryList) {
      if (props.paySalaryList.data) {
        const column = [];
        let obj = {};
        let keys = [];
        if (props.paySalaryList.data.length !== 0) {
          props.paySalaryList.data.map((prop) => {
            keys = Object.keys(prop);
          });
          keys.map((key) => {
            obj = {
              ...obj,
              Header: key,
              accessor: key,
              hidden: false,
              csvExport: true,
            };
            column.push(obj);
          });
          this.setState({
            paySalaryData: props.paySalaryList.data,
            paySalaryColumn: column,
            // EPF: list
          });
        }
      }
    }
  }
  handleClick = (status) => {
    if (this.props.location.state) {
      const data = {
        id: this.props.location.state.rowData.id,
        userId: this.state.userDit.userId,
        status: status,
      };
      this.props.updatePayPeriod(
        serverApi.UPDATE_PAY_REGISTER,
        data,
        this.props.history
      );
    }
  };

  downloadExcel = () => {
    var date = this.props.payPeriodById.data.fromDate;
    var newDate = moment(date).format();
    var fromdate = newDate.split("T", 1).toString();
    var NewfromDate = moment(fromdate, "YYYY-MM-DD", true).format("DD-MM-YYYY");

    var date1 = this.props.payPeriodById.data.toDate;
    var newDate1 = moment(date1).format();
    var todate = newDate1.split("T", 1).toString();
    var NewtoDate = moment(todate, "YYYY-MM-DD", true).format("DD-MM-YYYY");
    //    const splitDate = row.name.split("_");

    const data = {
      companyId: this.props.payPeriodById.data.companyId,
      branchId: this.props.payPeriodById.data.branchId,
      paySchemeId: this.props.payPeriodById.data.paySchemeId,
      fromDate: NewfromDate,
      toDate: NewtoDate,
    };
    var headers = {
      Authorization:
        localStorage.getItem("token_type") +
        localStorage.getItem("access_token"),

      "X-TenantId": getTenantId(window.location.hostname),

      Accept: "application/json",

      // "Access-Control-Allow-Origin": "localhost:3000",

      "Content-Type": "application/json",
      CompanyID: 1,
    };
    fetch(serverApi.DOWNLOAD_PAY_REGISTER_EXCEL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        var file_name = "DownloadExcel" + ".xls";
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_name);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  downloadPDF = () => {
    var date = this.props.payPeriodById.data.fromDate;
    var newDate = moment(date).format();
    var fromdate = newDate.split("T", 1).toString();
    var NewfromDate = moment(fromdate, "YYYY-MM-DD", true).format("DD-MM-YYYY");

    var date1 = this.props.payPeriodById.data.toDate;
    var newDate1 = moment(date1).format();
    var todate = newDate1.split("T", 1).toString();
    var NewtoDate = moment(todate, "YYYY-MM-DD", true).format("DD-MM-YYYY");

    const data = {
      companyId: this.props.payPeriodById.data.companyId,
      branchId: this.props.payPeriodById.data.branchId,
      paySchemeId: this.props.payPeriodById.data.paySchemeId,
      fromDate: NewfromDate,
      status: this.props.payPeriodById.data.status,
      toDate: NewtoDate,
      payPeriodId: this.props.location.state.rowData.id,
    };

    var headers = {
      Authorization:
        localStorage.getItem("token_type") +
        localStorage.getItem("access_token"),

      "X-TenantId": getTenantId(window.location.hostname),

      Accept: "application/json",

      // "Access-Control-Allow-Origin": "localhost:3000",

      "Content-Type": "application/json",
      CompanyID: 1,
    };
    showSpinner();
    fetch(serverApi.DOWNLOAD_PAY_REGISTER_PDF, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    })
      .then((response) => response.blob())
      .then((blob) => {
        hideSpinner();

        // Create blob link to download
        var file_name =
          "PayRegister_" + this.props.location.state.rowData.id + ".pdf";
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_name);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  render() {
    if (this.props.newLoader === true) {
      showSpinner();
    } else {
      hideSpinner();
    }

    return (
      <>
        <div className="payRegisterView">
          {this.props.payPeriodById.data ? (
            this.props.payPeriodById.data.status !== "4" ? (
              <div className="pdfAndExcelButtons">
                <CustomButton
                  icon={pdfIcon}
                  className="lightBlueButton"
                  handleClick={this.downloadPDF}
                />
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}

          <Gridwithcustomview
            mdata={
              this.state.paySalaryData.length !== 0
                ? this.state.paySalaryData
                : []
            }
            mcolumns={
              this.state.paySalaryColumn.length !== 0
                ? this.state.paySalaryColumn
                : []
            }
            actions={false}
            filter_form={"ViewPayRegister"}
          />
        </div>

        {this.state.show && (
          <div className="consoleFormButtonBlock">
            {this.props.payPeriodById.data.status !== "3" ? (
              <CustomButton
                label={"APPROVE"}
                className="approved"
                handleClick={() => this.handleClick(3)}
              />
            ) : (
              ""
            )}
            <CustomButton
              label={"REJECT"}
              className="rejected"
              handleClick={() => this.handleClick(4)}
            />
          </div>
        )}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    payPeriodById,
    processed_List,
    payRegisterList,
    paySalaryList,
    newLoader,
  } = state.PayRegisterReducer;
  return {
    payPeriodById,
    processed_List,
    payRegisterList,
    paySalaryList,
    newLoader,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    processedList,
    PayPeriodById,
    generateInvoice,
    updatePayPeriod,
    getPayRegisterList,
    getPaySalaryList,
  })(ViewPayRegister)
);
