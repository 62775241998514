import {
    API_ERRORS,
    FINANCIAL_YEAR,
    FINANCIAL_YEAR_LIST_SUCCESSFUL,
  } from "./actionTypes";
  
  const initialState = {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    acadamicYearsList: [],
    error_msg: null,
  };
  
  const acadamaicYears = (state = initialState, action) => {
    switch (action.type) {    
      case FINANCIAL_YEAR:
        state = {
          ...state,
          loading: true,
        };
        break;
      case FINANCIAL_YEAR_LIST_SUCCESSFUL:
        state = {
          ...state,
          acadamicYearsList: action.payload.response.data,
          loading: false,
        };
        break;
      case API_ERRORS:
        state = {
          ...state,
          loading: false,
          error_msg: action.payload,
        };
        break;
  
      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default acadamaicYears;
  