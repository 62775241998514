import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Profile from "../../assets/images/profileIcon.png";
import MaleProfile from '../../assets/images/image 2.png'

export default class LeaveBalance extends Component {
  constructor() {
    super();
    this.state = {
      display: false,
      name:'',
      department:"",
      designation:""
    };
  }
  handleChange = () => {
    this.setState({ display: !this.state.display });
  };

  render() {
    return (
      <>
        <div className="leaveBalanceCardContainer">
          <Grid className="employeeProfileContainer">
            <div>
              <Grid className="employeeProfileMainContainer">
                <div className="mainContainerBlock">
                  <Grid container className="employeeProfileBlock">
                    <Grid item className="profileBlockContainer">
                      <div>
                       {this.props.data.sex ==='Female' ? <img
                          src={Profile}
                          alt="playstore"
                          className="ProfileImageBlock"
                        />:
                         <img
                          src={MaleProfile}
                          alt="playstore"
                          className="ProfileImageBlock"
                        />
                        
                        }
                      </div>

                      <Grid item>
                        <h1 className="profileNameBlock">{this.props.data.workerName}</h1>
                        <p className="profileDesignation">{this.props.data.designationIdDesc}</p>
                        <p className="profileDepartment">{this.props.data.deptIdDesc }</p>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <span className="registerButtonBlock">{this.props.data.empCode}</span>
                    </Grid>
                  </Grid>
                  <h1 className="LeaveBalanceBlock">Leave Balance</h1>
                </div>
              </Grid>
              <div className="LeaveBalanceMappingBlock">
                {this.props.leaveLedger?this.props.leaveLedger.map((item, idx) => {
                  return (
                    <p key={idx} className="slashBlock">
                      <span className="currentLeavesBlock">
                        <label className={item.usedLeaves>=0?"actualLeavesBlock":"actualLeavesBlockRed"}>{item.usedLeaves===null ? '0' :item.usedLeaves}</label>
                        
                      </span>
                      /
                      <span className="starcolor">
                   <label className={item.availableLeaves>=0?"actualLeavesBlock":"actualLeavesBlockRed"}>     {item.availableLeaves}</label>
                      </span>
                      <br />
                      <span className="LeaveBalanceArrayBlock">
                        {item.leaveTypeName}
                      </span>
                    </p>
                  );
                }):  
             ''
              }
              {  this.props.leaveLedger.length ===0 &&<p  className="slashBlock">
                 <span className="actualLeavesBlock">
              Please map the Leave Policy First!!
              </span>
              </p>}
              </div>
            </div>
          </Grid>
        </div>
        {this.props.leaveBalance && (
          <div className="leaveBalanceCardContainer" style={{marginTop:"15px"}}>
            <Grid container spacing={2} className="LeaveRequestCardContainer">
              <Grid item xs={12} md={12}>
                <Box
                  className="invoiceSection"
                  sx={{
                    boxShadow: 3,
                    m: 0,
                  }}
                >
                  <Box className="invoiceSectionHeader">
                    <h1>Leave History</h1>
                  </Box>
                  {this.state.LeaveRequest.map((temp, idx) => (
                    <Grid
                      sx={{ p: 2.5 }}
                      container
                      className="billingDetailsHeader"
                    >
                      <Grid item xs={5} className="casualLeaveContainer">
                        <span
                          style={{ background: temp.background }}
                          className="instructor1"
                        ></span>
                        <div>
                          <h2>{temp.title}</h2>
                          <p className="reason">{temp.reason}</p>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <p>{temp.fromDate}</p>
                        <p>{temp.toDate}</p>
                      </Grid>
                      <Grid item xs={3}>
                        <h3>{temp.status}</h3>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }
}
