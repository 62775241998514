import {
  QUALITY_MASTER_LIST,
  CREATE_QUALITY_MASTER,
  UPDATE_QUALITY_MASTER,
  QUALITY_MASTER_VIEW_BY_ID,
  JUTE_QUALITIES_LIST,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getList, postAdd } from "../../../helpers/Server_Helper";
import {
  qualityMasterListSuccessfull,
  getJuteQualityMasterListValuesSuccessfull,
  createQualityMasterSuccessfull,
  updateQualityMasterSuccessfull,
  getQualityMasterViewByIdSuccessfull,
} from "./actions";
import swal from "sweetalert";

function* QualityMasterList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(qualityMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* JuteQualityMasterData({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(getJuteQualityMasterListValuesSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateQualityMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createQualityMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/quality_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
      }
    }
  }
}
function* UpdateQualityMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateQualityMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/quality_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
      }
    }
  }
}
function* getQualityMasterViewById({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(getQualityMasterViewByIdSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/quality_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    swal("error:" + error, { icon: "danger" });
  }
}

export function* watchQualityMasterList() {
  yield takeEvery(QUALITY_MASTER_LIST, QualityMasterList);
}
export function* watchCreateQualityMaster() {
  yield takeEvery(CREATE_QUALITY_MASTER, CreateQualityMaster);
}
export function* watchUpdateQualityMaster() {
  yield takeEvery(UPDATE_QUALITY_MASTER, UpdateQualityMaster);
}
export function* watchQualityMasterViewById() {
  yield takeEvery(QUALITY_MASTER_VIEW_BY_ID, getQualityMasterViewById);
}
export function* watchJuteQualityMasterData() {
  yield takeEvery(JUTE_QUALITIES_LIST, JuteQualityMasterData);
}

function* QualityMasterSaga() {
  yield all([
    fork(watchQualityMasterList),
    fork(watchCreateQualityMaster),
    fork(watchUpdateQualityMaster),
    fork(watchQualityMasterViewById),
    fork(watchJuteQualityMasterData),
  ]);
}

export default QualityMasterSaga;
