import {PAYMENT_LIST,PAYMENT_LIST_SUCCESSFULL, BILLS_NO_LIST, BILLS_NO_LIST_SUCCESSFULL, PAYMENT_CREATE, PAYMENT_CREATE_SUCCESSFULL} from './actionType';

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    PaymentList: [],
    BillNoList: [],
    createPayment: []
  };

  const PaymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAYMENT_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case PAYMENT_LIST_SUCCESSFULL:
            state = {
              ...state,
              PaymentList: action.payload.response,
              loading: true,
            };
            break;
          case BILLS_NO_LIST: 
            state = {
              ...state,
              loading: true
            }
            break;
          case BILLS_NO_LIST_SUCCESSFULL: 
            state = {
              ...state,
              BillNoList: action.payload.response,
              loading: true
            }  
          break; 
          case PAYMENT_CREATE: 
            state = {
              ...state,
              loading: true              
            }
            break;
          case PAYMENT_CREATE_SUCCESSFULL: 
            state = {
              ...state,
              createPayment: action.payload.response,
              loading: true
            }  
          break;  
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default PaymentReducer;