export const AGENT_NAME_LIST = "agent_name_list";
export const AGENT_NAME_LIST_SUCCESSFULL = "agent_name_list_successfull";
export const AGENT_LIST_DROPDOWN = "agent_list_dropdown";
export const AGENT_LIST_DROPDOWN_SUCCESSFULL = "agent_list_dropdown_successfull";
export const MUKAM_BY_ITEM_LIST = "mukam_by_item_list";
export const MUKAM_BY_ITEM_LIST_SUCCESSFULL = "mukam_by_item_list_successfull";
export const VEHICLE_TYPE_LIST = "vehicle_type_list";
export const VEHICLE_TYPE_LIST_SUCCESSFULL = "vehicle_type_list_successfull";
export const GET_SUPP_BY_MUKAM_LIST = "get_supplier_by_mukam_list";
export const GET_SUPP_BY_MUKAM_LIST_SUCCESSFULL = "get_supplier_by_mukam_list_successfull";
export const GET_BROKER_BY_SUPP_LIST = "get_borker_by_supplier_list";
export const GET_BROKER_BY_SUPP_LIST_SUCCESSFULL = "get_borker_by_supplier_successfull";
export const GET_QUALITY_BY_ITEM_LIST = "get_quality_by_item_list";
export const GET_QUALITY_BY_ITEM_LIST_SUCCESSFULL = "get_quality_by_item_successfull";
export const GET_PO_APPROVED_LIST = "get_po_approved_list";
export const GET_PO_APPROVED_LIST_SUCCESSFULL = "get_po_approved_list_successfull";
export const GET_PO_DETAILS_LIST = "get_po_details_list";
export const GET_PO_DETAILS_LIST_SUCCESSFULL = "get_po_details_list_successfull";
export const GET_VEHICLE_LIST = "get_vehicle_list";
export const GET_VEHICLE_LIST_SUCCESSFULL = "get_vehicle_list_successfull";
export const GET_GODOWN_NUMBER_LIST = "get_godown_number_list";
export const GET_GODOWN_NUMBER_LIST_SUCCESSFULL = "get_godown_number_list_successfull";
export const GET_PLAN_NAME_LIST = "get_plan_name_list";
export const GET_PLAN_NAME_LIST_SUCCESSFULL = "get_plan_name_list_successfull";