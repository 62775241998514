import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
// import DynamicSelect from "../Dropdown/DynamicSelect";
import DynamicSelect from "../../components/Dropdown/Master/DynamicSelectMasters";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextFieldNormal from "../TextField/TextFieldNormal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { serverApi } from "../../helpers/Consts";
import { getBranchList } from "../../store/Global/DropDownApis/actions";
import { getProjectList } from "../../store/Global/DropDownApis/actions";
import { getStatusList } from "../../store/Global/DropDownApis/actions";
import { getSupplierList } from "../../store/Global/DropDownApis/actions";

class MaterialInspection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      projectListData: [],
      branchListData: [],
      supplierListData: [],
      statusListData: [],
      projectId: "",
      branchId: "",
      supplierValue: null,
      statusValue: null,
      fromDate: moment().day(-7).format("YYYY-MM-DD").toString(),
      toDate: moment().format("YYYY-MM-DD").toString(),
    };
  }

  componentDidMount() {
    // const data = {
    //   cipher: this.state.userDit.cipher,
    //   companyId: this.state.companyId,
    // };
    // this.props.getSupplierList(
    //   serverApi.GET_ALL_SUPPLIERS_LIST + this.state.companyId,
    //   this.state.history
    // );
    // this.props.getProjectList(
    //   serverApi.GET_ALL_PROJECTS,
    //   data,
    //   this.props.history
    // );
    // this.props.getBranchList(
    //   serverApi.BRANCH_LIST + this.state.companyId,
    //   this.props.history
    // );
    // this.props.getStatusList(serverApi.GET_STATUS_LIST, this.props.history);
    const financialYearStartMonth = 3; // Assuming financial year starts in April
    let financialYearStart = moment()
      .year(localStorage.getItem("acadamicYear"))
      .month(financialYearStartMonth)
      .startOf("month");
  
    const fromDate = financialYearStart.day(-7).format("YYYY-MM-DD");
    const toDate = moment().format("YYYY-MM-DD");
  
    this.setState({
      fromDate:fromDate,
      toDate:toDate ,
    })
  }

  componentWillReceiveProps(props) {
    if (props.projectList && Array.isArray(props.projectList.data)) {
      let data = props.projectList.data;
      if (Array.isArray(data) || (typeof data === "object" && data !== null)) {
        const newObj = {
          value: "",
          name: "Select...",
          label: "Select...",
        };
        // const NewArray = [NewObj, ...data];
        const newArray = [
          newObj,
          ...(Array.isArray(data) ? data : Object.values(data)),
        ];
        this.setState({
          projectListData: newArray,
        });
      }
    }
    if (props.branchList) {
      this.setState({
        branchListData: props.branchList.data,
      });
    }
    if (props.supplierList) {
      this.setState({
        supplierListData: props.supplierList.data,
      });
    }
    if (props.statusList) {
      this.setState({
        statusListData: props.statusList.data,
      });
    }
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "project") {
      this.setState({
        projectId: selectedValue,
      });
    }
    if (name === "Supplier") {
      this.setState({
        supplierValue: selectedValue,
      });
    }

    if (name === "branch") {
      this.setState({
        branchId: selectedValue,
      });
    }
    if(name === "status"){
      this.setState({
        statusValue : selectedValue
      })
    }
  };
  handleSelectDate = (e, name) => {
    if (name === "FromDate") {
      let date = moment(e).format();
      let formattedFromDate = date.split("T", 1).toString();
      this.setState({
        fromDate: formattedFromDate,
      });
    }

    if (name === "ToDate") {
      let date = moment(e).format();
      let formattedToDate = date.split("T", 1).toString();

      this.setState({
        toDate: formattedToDate,
      });
    }
  };

  handleSubmit = () => {
    var data = {
      fromDate: moment(this.state.fromDate, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      ),
      toDate: moment(this.state.toDate, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      ),
      projectId: this.state.projectId,
      branchId: this.state.branchId,
      supplierValue: this.state.supplierValue,
      statusValue: this.state.statusValue,
      inwardNum: this.state.inwardNum,
      poNum: this.state.poNum,
    };
    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };
  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
  };
  render() {
    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          // TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid spacing={0}>
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
              </div>
            </div>
            <div className="procurementFilter">
              <Grid container spacing={1} className="filterBlockContainer">
                <Grid item xs={6}>
                  <div className="filterDateBlock">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>From Date</label>
                      </div>
                      {/* <ConsoleDatePicker /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.fromDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "FromDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="fromDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Grid>
                {/* </Box>
              <Box gridColumn="span 3"> */}
                <Grid item xs={6}>
                  <div className="filterDateBlock">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>To Date</label>
                      </div>
                      {/* <ConsoleDatePicker /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.toDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "ToDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="fromDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Inward Number"
                      placeholder="Enter here"
                      value={this.state.inwardNum}
                      onChange={this.onhandlechange("inwardNum")}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="PO Number"
                      placeholder="Enter here"
                      value={this.state.poNum}
                      onChange={this.onhandlechange("poNum")}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.supplierListData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.supplierValue}
                      updat
                      name="Supplier"
                      label="Supplier"
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={
                        this.state.projectListData &&
                        this.state.projectListData.map((item) => ({
                          label: item.name,
                          value: item.projectId,
                          name: item.name,
                        }))
                      }
                      selected={this.state.projectId}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      name="project"
                      label="Project"
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.branchListData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      name="branch"
                      label="Branch"
                      selected={this.state.branchId}
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.statusListData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      name="status"
                      label="Status"
                      selected={this.state.statusValue}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="consoleFormButtonBlock">
              <CustomButton
                label={"Search"}
                className="greenButton"
                handleClick={this.handleSubmit}
                type="sumbit"
              />
            </div>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  const { indentList, indentListColumns } = state.Indent;

  const { branchList, projectList, statusList, supplierList } =
    state.DropDownListReducer;
  return {
    indentList,
    indentListColumns,
    projectList,
    branchList,
    statusList,
    supplierList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getBranchList,
    getProjectList,
    getStatusList,
    getSupplierList,
  })(MaterialInspection)
);
