import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/PaginationGridView";
import { ledgerMasterList } from "../../../store/Master/LedgerMaster/actions";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      ledgerMasterListData: [], // Ledger master list data
      startIndex: 1,
      lastIndex: 10,
      totalRecs:0,
      ledgerCode:'',
      ledgerName:'',
      parentLedgerDesc:'',
      parentLedger:'',
      dataexp:"",
    };
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
    };
   
    this.props.ledgerMasterList(
      serverApi.GET_ALL_LEDGER_MASTER_LIST,
      data,
      this.props.history
    ); // calling ledger master list API
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({  dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.LedgerMasterList) {
      this.setState({
        ledgerMasterListData: props.LedgerMasterList.data, // Updating ledger master list data
        totalRecs: props.LedgerMasterList.totalRecords,

      });
    }
  }
  handleSearch = (data) => {
    
    const searchData = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      ledgerCode:data.ledgerCode,
      ledgerName:data.ledgerName,
      parentLedgerDesc:data.parentLedgerDesc,
      parentLedger:data.parentLedger,
      startIndex:1,
      lastIndex: 10,
    };
    this.setState({
      ledgerCode:data.ledgerCode,
      ledgerName:data.ledgerName,
      parentLedgerDesc:data.parentLedgerDesc,
      parentLedger:data.parentLedger,
    })
    this.props.ledgerMasterList(
      serverApi.GET_ALL_LEDGER_MASTER_LIST,
      searchData,
      this.props.history
    ); 
    
  };
  handlePagination = (page, sizePerPage, type) => {
    
    const currentIndex = (page - 1) * sizePerPage;
    
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      ledgerCode:this.state.ledgerCode,
      ledgerName:this.state.ledgerName,
      parentLedgerDesc:this.state.parentLedgerDesc,
      parentLedger:this.state.parentLedger,
    };
    if (type === 1) {
      data.startIndex = sizePerPage;
      data.lastIndex = currentIndex;
      if (currentIndex >= 0) {
        this.props.ledgerMasterList(
          serverApi.GET_ALL_LEDGER_MASTER_LIST,
          data,
          this.props.history
        );
      }
    } else {
      data.startIndex = currentIndex ===0?currentIndex+1:currentIndex;
      data.lastIndex = sizePerPage;
      if (currentIndex >= 0) {
        this.props.ledgerMasterList(
          serverApi.GET_ALL_LEDGER_MASTER_LIST,
          data,
          this.props.history
        );
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    })
  }
  render() {
    if(this.state.ledgerMasterListData){
      if(this.state.ledgerMasterListData.length !==0){
        var ledgerMasterListData = this.state.ledgerMasterListData.sort((a, b)=>{return b.id - a.id})

      }
    }

    return (
      <>
        <Gridwithcustomview
          mdata={ledgerMasterListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          url="/create_Ledger_Master"
          filter_form={"ledgerMaster"}
          handleSearch={this.handleSearch}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          apiexp={serverApi.GET_ALL_LEDGER_MASTER_LIST}
          payloadexp={this.state.dataexp}
          expName={"Ledger-Master.csv"}
        />
      </>
    );
  }
}
// Ledger master list Headers
const Columns = [
  {
    Header: "Ledger Code",
    accessor: "id",
    hidden: false,
  },
  {
    Header: "Ledger Name",
    accessor: "ledgerName",
    hidden: false,
  },
  {
    Header: "Parent",
    accessor: "parentLedger",
    hidden: false,
  },
  {
    Header: "Parent Ledger Description",
    accessor:'parentLedgerDesc',
    hidden: false,

  },
  {
    Header: "Ledger Groups",
    accessor: "ledgerGroups",
    hidden: true,
  },
  {
    Header: "Company Id",
    accessor: "companyId",
    hidden: true,
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    hidden: true,
  },
  {
    Header: "Supplier Id",
    accessor: "suppId",
    hidden: true,
  },
  {
    Header: "Customer Id",
    accessor: "customerId",
    hidden: true,
  },
  {
    Header: "Opening Balance",
    accessor: "openningBalance",
    hidden: true,
  },
  {
    Header: "EB Id",
    accessor: "ebid",
    hidden: true,
  },
  {
    Header: "GST No",
    accessor: "gstNo",
    hidden: true,
  },

];
const mapStatetoProps = (state) => {
  const { LedgerMasterList } = state.LedgerReducer; // fetching list from ledger reducer
  return { LedgerMasterList };
};
export default withRouter(
  connect(mapStatetoProps, {
    ledgerMasterList,
  })(Index)
);
