//************************************************************************** */
//date of creation : 01-08-2023 :: 12:00 AM Author : Nagesh Medisetty //
//************************************************************************** */

import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import {
  createDepartment,
  updateDepartment,
} from "../../../store/Master/DepartmentMaster/actions";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import moment from "moment";
import swal from "sweetalert";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const CreateDepartmentMaster = (props) => {
  const [confirm, setConfirm] = useState(false);
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [companyId, setCompanyId] = useState(localStorage.getItem("companyId"));
  const [recId, setRecId] = useState("");
  const [departmentCode, setDepartmentCode] = useState(null);
  const [departmentName, setDepartmentName] = useState("");
  const [createdBy, setCreatedBy] = useState(null);
  const [createdOn, setCreatedOn] = useState("");
  const [locationState] = useState(useLocation());

  useEffect(() => {
    if (locationState.state) {
      setCompanyId(locationState.state.rowData.companyId);
      setDepartmentCode(locationState.state.rowData.id);
      setDepartmentName(locationState.state.rowData.departmentName);
      setCreatedBy(locationState.state.rowData.createdBy);
      setRecId(locationState.state.rowData.recId);
      const dateEntered = locationState.state.rowData.createdDate;
      const dateIsValid = moment(dateEntered).format("LLLL");
      setCreatedOn(dateIsValid);
    }
  }, [locationState.state]);

  const onClickCreateUpdate = () => {
    const userId = userDit.userId;
    if (departmentName === "" || departmentName === undefined) {
      swal("Please Enter the Department Name");
      return false;
    }
    if (props.location.state === undefined) {
      const data = {
        companyId,
        departmentName,
        createdBy: userId.toString(),
      };
      props.createDepartment(
        serverApi.CREATE_DEPARTMENT,
        data,
        props.history
      ); // Calling Create Department API
    } else {
      const data = {
        cipher: userDit.cipher,
        companyId: localStorage.getItem("companyId"),
        departmentName,
        id: departmentCode,
        orgId: localStorage.getItem("companyId"),
        recId,
        updatedBy: userDit.userId.toString(),
      };
      props.updateDepartment(
        serverApi.UPDATE_DEPARTMENT,
        data,
        props.history
      ); // Calling Update Department API
    }
  };

  const onClickCancel = () => {
    props.history.push("/department_master");
  };

  const onhandlechangeValue = (key) => (event) => {
    if (key === "departmentName") {
      setDepartmentName(event);
    } else if (key === "departmentCode") {
      setDepartmentCode(event);
    }
  };

  const onclickPopUP = () => {
    setConfirm(false);
  };

  return (
    <div>
      <Grid className="indentContainerBlock">
        <Grid sx={12} className="indentContainerMainBlock">
          <>
            <div className="departmentMasterContainer">
              <div className="consoleFormContainer">
                <h5>Fill Details</h5>
                <div className="consoleFormBlock">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={2}
                      >
                        {locationState.state === undefined ? (
                          ""
                        ) : (
                          <>
                            <Box gridColumn="span 3">
                              <div className="consoleTextFieldBlock">
                                <TextFieldNormal
                                  type="number"
                                  value={departmentCode}
                                  readOnly={
                                    props.location.state !== undefined
                                      ? true
                                      : false
                                  }
                                  caps="OFF"
                                  minValue="0"
                                  onChange={onhandlechangeValue(
                                    "departmentCode"
                                  )}
                                  label="Department Code"
                                />
                              </div>
                            </Box>
                          </>
                        )}
                        <Box gridColumn="span 3">
                          <div className="consoleTextFieldBlock">
                            <TextFieldReq
                              label="Department Name"
                              value={departmentName}
                              caps="OFF"
                              onChange={onhandlechangeValue("departmentName")}
                            />
                          </div>
                        </Box>
                        {locationState.state === undefined ? (
                          ""
                        ) : (
                          <>
                            <Box gridColumn="span 6">
                              <div className="consoleUpdateTextBlock">
                                <div>
                                  <span className="taxLabel">
                                    Created By : <b>{createdBy}</b>
                                  </span>
                                </div>
                                <div>
                                  <span className="taxLabel">
                                    Created On :<b> {createdOn}</b>
                                  </span>
                                </div>
                              </div>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
            <div className="consoleFormButtonBlock">
              <Link>
                <CustomButton
                  label={"Cancel"}
                  className="greenBorderButton"
                  handleClick={onClickCancel}
                />
              </Link>
              {locationState.state !== undefined ? (
                <CustomButton
                  label={"Update"}
                  className="greenButton"
                  handleClick={onClickCreateUpdate}
                  type="submit"
                />
              ) : (
                <CustomButton
                  label={"Create"}
                  className="greenButton"
                  handleClick={onClickCreateUpdate}
                  type="submit"
                />
              )}

              {/* Popup For Validation */}
              {confirm && (
                <CommonPopup>
                  <div className="delAddItem">
                    <div className="mandatoryFieldText">
                      Please select all the mandatory fields
                    </div>
                    <div className="delAddItemBtns">
                      <input
                        type="button"
                        onClick={onclickPopUP}
                        value="Ok"
                        className="delYesBtn"
                      />
                    </div>
                  </div>
                </CommonPopup>
              )}
            </div>
          </>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { Create_Department, Update_Department } = state.DepartmentReducer;
  return { Create_Department, Update_Department };
};

export default withRouter(
  connect(mapStatetoProps, {
    createDepartment,
    updateDepartment,
  })(CreateDepartmentMaster)
);
