import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TabsNavigation from "../../../../../components/TabsNavigation/TabsNavigation";
import WorkDetails from "./WorkDetails";
import SalaryStructure from "./SalaryStructure";
import BankDetails from "./BankDetails";
class OfficialInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      value: 0,
    };
  }

  handleNext = (state, value = null) => {
    this.setState({
      disabled: state,
      value: value ? value : this.state.value,
    });
  };

  onvalue = (id, display) => {
    this.setState({
      value: this.state.value + 1,
    });
    if (display === true) {
      this.props.history.push("/add_employee", { id: id, display: display });
    }
  };
  render() {
    if (this.props.progressbar_data) {
      if (this.props.progressbar_data.data) {
        if (this.props.progressbar_data.data.toDisplayForms) {
          var toDisplayForms = this.props.progressbar_data.data.toDisplayForms;
        }
      }
    }
    return (
      <>
        <TabsNavigation
          firsttab={false}
          secondtab={
            toDisplayForms
              ? toDisplayForms.salaryStructure
                ? false
                : true
              : true
          }
          thirdtab={
            toDisplayForms ? (toDisplayForms.bankDetails ? false : true) : true
          }
          tabOneLabel="Work Details"
          tabTwoLabel="Salary Structure"
          tabThreeLabel="Bank Details"
          tabOneContent={
            <WorkDetails onvalue={this.onvalue} handleNext={this.handleNext} />
          }
          tabTwoContent={
            <SalaryStructure
              onvalue={this.onvalue}
              handleNext={this.handleNext}
            />
          }
          tabThreeContent={
            <BankDetails onvalue={this.onvalue} handleNext={this.handleNext} />
          }
          setValue={this.state.value}
        />
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { getEbId, progressbar_data } = state.EmployeeReducer;
  return { getEbId, progressbar_data };
};

export default withRouter(connect(mapStatetoProps, {})(OfficialInformation));
