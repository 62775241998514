import {
  INVOICE_LIST,
  INVOICE_LIST_SUCCESSFULL,
  API_ERRORS,
  CREATE_REGULAR_INVOICE,
  CREATE_REGULAR_INVOICE_SUCCESSFULL,
  MATERIAL_DETAILS_BY_ID,
  MATERIAL_DETAILS_BY_ID_SUCCESSFULL,
  JUTE_GOVT_INVOICE_HEADER,
  REGULAR_INVOICE_HEADER,
  REGULAR_INVOICE_LINE_ITEMS,
  JUTE_GOVT_INVOICE_LINE_ITEMS,
  CLEAR_REGULAR_INVOICE_HEADER,
  CLEAR_REGULAR_INVOICE_LINE_ITEMS,
  CLEAR_JUTE_GOVT_INVOICE_LINE_ITEMS,
  REGULAR_SALES_ORDER_VIEW_BYID_FOR_INVOICE,
  REGULAR_SALES_ORDER_VIEW_BYID_FOR_INVOICE_SUCCESSFULL,
  SALES_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE,
  SALES_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE_SUCCESSFULL,
  REGULAR_DELIVERY_ORDER_VIEW_BYID_FOR_INVOICE,
  REGULAR_DELIVERY_ORDER_VIEW_BYID_FOR_INVOICE_SUCCESSFULL,
  REGULAR_INVOICE_VIEW_BY_ID_SUCCESSFULL,
  REGULAR_INVOICE_VIEW_BY_ID,
  UPDATE_REGULAR_INVOICE,
  UPDATE_REGULAR_INVOICE_SUCCESSFULL,
  REGULAR_INVOICE_ITEMS_BY_ITEM_GROUP_ID,
  REGULAR_INVOICE_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL,
  DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE,
  DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE_SUCCESSFULL,
  CLEAR_JUTE_GOVT_INVOICE_HEADER,
  JUTE_GOVT_INVOICE_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL,
  JUTE_GOVT_INVOICE_ITEMS_BY_ITEM_GROUP_ID,
  CREATE_JUTE_GOVT_INVOICE,
  CREATE_JUTE_GOVT_INVOICE_SUCCESSFULL,
  JUTE_GOVT_INVOICE_VIEW_BY_ID,
  JUTE_GOVT_INVOICE_VIEW_BY_ID_SUCCESSFULL,
  UPDATE_JUTE_GOVT_INVOICE,
  UPDATE_JUTE_GOVT_INVOICE_SUCCESSFULL,
  GET_VEHICLE_TYPE,
  GET_VEHICLE_TYPE_SUCCESSFULL,
  SALES_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE,
  SALES_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE_SUCCESSFULL,
  JUTE_HESSIAN_INVOICE_HEADER,
  CLEAR_JUTE_HESSIAN_INVOICE_HEADER,
  JUTE_HESSIAN_INVOICE_LINE_ITEMS,
  CLEAR_JUTE_HESSIAN_INVOICE_LINE_ITEMS,
  DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE,
  DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE_SUCCESSFULL,
  CREATE_JUTE_HESSIAN_INVOICE,
  CREATE_JUTE_HESSIAN_INVOICE_SUCCESSFULL,
  JUTE_HESSIAN_INVOICE_VIEW_BY_ID,
  JUTE_HESSIAN_INVOICE_VIEW_BY_ID_SUCCESSFULL,
  UPDATE_JUTE_HESSIAN_INVOICE,
  UPDATE_JUTE_HESSIAN_INVOICE_SUCCESSFULL ,
  JUTE_YARN_INVOICE_HEADER,
  JUTE_YARN_INVOICE_LINE_ITEMS,
  SALES_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE,
  SALES_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE_SUCCESSFULL,
  DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE,
  DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE_SUCCESSFULL,
  DELETE_INVOICE_FILE,
  DELETE_INVOICE_FILE_SUCCESSFULL,
  UPLOAD_INVOICE_FILES,
  UPLOAD_INVOICE_FILES_SUCCESSFULL,
  GET_INVOICE_FILES,
  INVOICE_FILES_SUCCESSFULL,
  CREATE_JUTE_YARN_INVOICE,
  CREATE_JUTE_YARN_INVOICE_SUCCESSFULL,
  CLEAR_JUTE_YARN_INVOICE_HEADER,
  CLEAR_JUTE_YARN_INVOICE_LINE_ITEMS,
  INVOICE_JUTE_YARN_GET_UOM_RELATION_VALUE,
  INVOICE_JUTE_YARN_GET_UOM_RELATION_VALUE_SUCCESSFULL,
  INVOICE_JUTE_YARN_VIEW_BY_ID,
  INVOICE_JUTE_YARN_VIEW_BY_ID_SUCCESSFULL,
  UPDATE_JUTE_YARN_INVOICE,
  UPDATE_JUTE_YARN_INVOICE_SUCCESSFULL,
  GENERATE_E_INVOICE,
  GENERATE_E_INVOICE_SUCCESSFULL,

  CLEAR_E_INVOICE_MESSAGE,
  CLEAR_E_WAY_BILL_MESSAGE,

  GENERATE_E_WAY_BILL,
  GENERATE_E_WAY_BILL_SUCCESSFULL,
  INVOICE_JUTE_YARN_UOM_LIST_SUCCESSFULL,
  INVOICE_JUTE_YARN_UOM_LIST,
  REGULAR_INVOICE_ITEMS_DETAILS_BY_ITEM_ID,
  REGULAR_INVOICE_ITEMS_DETAILS_BY_ITEM_ID_SUCCESSFULL
} from "./actionTypes";

export const getInvoiceList = (url, data, history) => {
  return {
    type: INVOICE_LIST,
    payload: { url, data, history },
  };
};

export const InvoiceListSuccessfull = (data) => {
  return {
    type: INVOICE_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const CreateRegularInvoice = (url, data, history) => {
  return {
    type: CREATE_REGULAR_INVOICE,
    payload: { url, data, history },
  };
};

export const CreateRegularInvoiceSuccessfull = (data) => {
  return {
    type: CREATE_REGULAR_INVOICE_SUCCESSFULL,
    payload: data,
  };
};

export const CreateJuteGovtInvoice = (url, data, history) => {
  return {
    type: CREATE_JUTE_GOVT_INVOICE,
    payload: { url, data, history },
  };
};

export const CreateJuteGovtInvoiceSuccessfull = (data) => {
  return {
    type: CREATE_JUTE_GOVT_INVOICE_SUCCESSFULL,
    payload: data,
  };
};
export const CreateJuteYarnInvoice = (url, data, history) => {
  return {
    type: CREATE_JUTE_YARN_INVOICE,
    payload: { url, data, history },
  };
};

export const CreateJuteYarnInvoiceSuccessfull = (data) => {
  return {
    type: CREATE_JUTE_YARN_INVOICE_SUCCESSFULL,
    payload: data,
  };
};

export const UpdateJuteYarnInvoice = (url, data, history) => {
  return {
    type:  UPDATE_JUTE_YARN_INVOICE,
    payload: { url, data, history },
  };
};

export const UpdateJuteYarnInvoiceSuccessfull = (data) => {
  return {
    type: UPDATE_JUTE_YARN_INVOICE_SUCCESSFULL,
    payload: data,
  };
};
export const UpdateJuteGovtInvoice = (url, data, history) => {
  return {
    type: UPDATE_JUTE_GOVT_INVOICE,
    payload: { url, data, history },
  };
};

export const UpdateJuteGovtInvoiceSuccessfull = (data) => {
  return {
    type: UPDATE_JUTE_GOVT_INVOICE_SUCCESSFULL,
    payload: data,
  };
};
export const getMaterialDetailsById = (url, history) => {
  return {
    type: MATERIAL_DETAILS_BY_ID,
    payload: { url, history },
  };
};
export const getMaterialDetailsByIdSuccessfull = (data) => {
  return {
    type: MATERIAL_DETAILS_BY_ID_SUCCESSFULL,
    payload: data,
  };
};
export const getJuteGovtInvoiceHeader = (data) => {
  return {
    type: JUTE_GOVT_INVOICE_HEADER,
    payload: data,
  };
};
export const getRegularInvoiceHeader = (data) => {
  return {
    type: REGULAR_INVOICE_HEADER,
    payload: data,
  };
};
export const ClearRegularInvoiceHeader = (data) => {
  return {
    type: CLEAR_REGULAR_INVOICE_HEADER,
    payload: data,
  };
};
export const ClearJuteGovtInvoiceHeader = (data) => {
  return {
    type: CLEAR_JUTE_GOVT_INVOICE_HEADER,
    payload: data,
  };
};
export const ClearJuteYarnInvoiceHeader = (data) => {
  return {
    type: CLEAR_JUTE_YARN_INVOICE_HEADER,
    payload: data,
  };
};
export const getJuteYarnInvoiceHeader = (data) => {
  return {
    type: JUTE_YARN_INVOICE_HEADER,
    payload: data,
  };
};
export const getRegularInvoiceLineItems = (data) => {
  return {
    type: REGULAR_INVOICE_LINE_ITEMS,
    payload: data,
  };
};
export const getJuteGovtInvoiceLineItems = (data) => {
  return {
    type: JUTE_GOVT_INVOICE_LINE_ITEMS,
    payload: data,
  };
};
export const getJuteYarnInvoiceLineItems = (data) => {
  return {
    type: JUTE_YARN_INVOICE_LINE_ITEMS,
    payload: data,
  };
};
export const ClearRegularInvoiceLineItems = (data) => {
  return {
    type: CLEAR_REGULAR_INVOICE_LINE_ITEMS,
    payload: data,
  };
};
export const ClearJuteGovtInvoiceLineItems = (data) => {
  return {
    type: CLEAR_JUTE_GOVT_INVOICE_LINE_ITEMS,
    payload: data,
  };
};
export const ClearJuteYarnInvoiceLineItems = (data) => {
  return {
    type: CLEAR_JUTE_YARN_INVOICE_LINE_ITEMS,
    payload: data,
  };
};
export const getSalesOrderViewByIdForInvoice= (url, history) => {
  return {
    type: REGULAR_SALES_ORDER_VIEW_BYID_FOR_INVOICE,
    payload: { url, history },
  };
};
export const getSalesOrderViewByIdForInvoiceSuccessfull = (data) => {
  return {
    type: REGULAR_SALES_ORDER_VIEW_BYID_FOR_INVOICE_SUCCESSFULL,
    payload: data,
  };
};

export const getSalesOrderViewByIdForJuteGovtInvoice= (url, history) => {
  return {
    type: SALES_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE,
    payload: { url, history },
  };
};
export const getSalesOrderViewByIdForJuteGovtInvoiceSuccessfull = (data) => {
  return {
    type: SALES_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE_SUCCESSFULL,
    payload: data,
  };
};
export const getSalesOrderViewByIdForJuteYarnInvoice= (url, history) => {
  return {
    type: SALES_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE,
    payload: { url, history },
  };
};
export const getSalesOrderViewByIdForJuteYarnInvoiceSuccessfull = (data) => {
  return {
    type: SALES_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE_SUCCESSFULL,
    payload: data,
  };
};
export const getDeliveryOrderViewByIdForJuteGovtInvoice= (url, history) => {
  return {
    type: DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE,
    payload: { url, history },
  };
};
export const getDeliveryOrderViewByIdForJuteGovtInvoiceSuccessfull = (data) => {
  return {
    type: DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE_SUCCESSFULL,
    payload: data,
  };
};
export const getDeliveryOrderViewByIdForJuteYarnInvoice= (url, history) => {
  return {
    type: DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE,
    payload: { url, history },
  };
};
export const getDeliveryOrderViewByIdForJuteYarnInvoiceSuccessfull = (data) => {
  return {
    type: DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE_SUCCESSFULL,
    payload: data,
  };
};
export const getJuteYarnInvoiceViewById= (url, history) => {
  return {
    type: INVOICE_JUTE_YARN_VIEW_BY_ID,
    payload: { url, history },
  };
};
export const getJuteYarnInvoiceViewByIdSuccessfull = (data) => {
  return {
    type: INVOICE_JUTE_YARN_VIEW_BY_ID_SUCCESSFULL,
    payload: data,
  };
};
export const getDeliveryOrderViewByIdForInvoice= (url, history) => {
  return {
    type: REGULAR_DELIVERY_ORDER_VIEW_BYID_FOR_INVOICE,
    payload: { url, history },
  };
};
export const getDeliveryOrderViewByIdForInvoiceSuccessfull = (data) => {
  return {
    type: REGULAR_DELIVERY_ORDER_VIEW_BYID_FOR_INVOICE_SUCCESSFULL,
    payload: data,
  };
};
export const getRegularInvoiceViewById= (url, history) => {
  return {
    type: REGULAR_INVOICE_VIEW_BY_ID,
    payload: { url, history },
  };
};
export const getRegularInvoiceViewByIdSuccessfull = (data) => {
  return {
    type: REGULAR_INVOICE_VIEW_BY_ID_SUCCESSFULL,
    payload: data,
  };
};

export const getJuteGovtInvoiceViewById= (url, history) => {
  return {
    type: JUTE_GOVT_INVOICE_VIEW_BY_ID,
    payload: { url, history },
  };
};
export const getJuteGovtInvoiceViewByIdSuccessfull = (data) => {
  return {
    type: JUTE_GOVT_INVOICE_VIEW_BY_ID_SUCCESSFULL,
    payload: data,
  };
};
export const UpdateRegularInvoice = (url, data, history) => {
  return {
    type: UPDATE_REGULAR_INVOICE,
    payload: { url, data, history },
  };
};

export const UpdateRegularInvoiceSuccessfull = (data) => {
  return {
    type: UPDATE_REGULAR_INVOICE_SUCCESSFULL,
    payload: data,
  };
};
export const getRegularInvoiceItemsByItemGroupId = (url, data, history,rowId) => {
  return {
    type: REGULAR_INVOICE_ITEMS_BY_ITEM_GROUP_ID,
    payload: { url, data, history,rowId },
  };
};

export const getRegularInvoiceItemsByItemGroupIdSuccessfull = (data) => {
  return {
    type: REGULAR_INVOICE_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL,
    payload: data,
  };
};
export const getRegularInvoiceItemDetailsByItemId = (url, data, history,rowId) => {
  return {
    type: REGULAR_INVOICE_ITEMS_DETAILS_BY_ITEM_ID,
    payload: { url, data, history,rowId },
  };
};

export const getRegularInvoiceItemDetailsByItemIdSuccessfull = (data) => {
  return {
    type: REGULAR_INVOICE_ITEMS_DETAILS_BY_ITEM_ID_SUCCESSFULL,
    payload: data,
  };
};
export const getJuteGovtInvoiceItemsByItemGroupId = (url, data, history,rowId) => {
  return {
    type: JUTE_GOVT_INVOICE_ITEMS_BY_ITEM_GROUP_ID,
    payload: { url, data, history,rowId },
  };
};

export const getJuteGovtInvoiceItemsByItemGroupIdSuccessfull = (data) => {
  return {
    type: JUTE_GOVT_INVOICE_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL,
    payload: data,
  };
};

export const getVehicleType = (url, data,history) => {
  return {
    type: GET_VEHICLE_TYPE,
    payload: { url,data, history },
  };
};
export const getVehicleTypeSuccessfull = (data) => {
  return {
    type:GET_VEHICLE_TYPE_SUCCESSFULL,
    payload: data,
  };
};

/**Jute Hessian Invoice */
export const getSalesOrderViewByIdForJuteHessian = (url,history) => {
return {
  type: SALES_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE,
  payload: {url,history},
}
}

export const getSalesOrderViewByIdForJuteHessianSuccessfull = (data) => {
  return {
    type: SALES_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE_SUCCESSFULL,
    payload: data
  }
}

export const getJuteHessionInvoiceHeader = (data) => {
  return {
    type: JUTE_HESSIAN_INVOICE_HEADER,
    payload: data,
  }
}

export const ClearJuteHessianHeader = (data) => {
  return {
    type: CLEAR_JUTE_HESSIAN_INVOICE_HEADER,
    payload: data,
  }
}

export const getJuteHessianInvoiceLineItems = (data) => {
  return {
    type: JUTE_HESSIAN_INVOICE_LINE_ITEMS,
    payload: data,
  }
}

export const ClearJuteHessianInvoiceLineItems = (data) => {
  return {
    type: CLEAR_JUTE_HESSIAN_INVOICE_LINE_ITEMS,
    payload: data,
  }
}

export const getDeliveryOrderViewByIdForJuteHessian = (url,history) => {
  return {
    type : DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE,
    payload: {url, history}
  }
}

export const getDeliveryOrderViewByIdForJuteHessianSuccessfull = (data) => {
  return {
    type : DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE_SUCCESSFULL,
    payload: data
  }
}

export const CreateJuteHessionInvoive = (url, data, history) => {
return {
  type: CREATE_JUTE_HESSIAN_INVOICE,
  payload: { url, data, history },
}
}

export const CreateJuteHessionInvoiveSuccessfull = (data) => {
  return {
    type: CREATE_JUTE_HESSIAN_INVOICE_SUCCESSFULL,
    payload: data,
  };
}

export const getJuteHessianInvoiceViewById = (url, history) => {
  return {
    type : JUTE_HESSIAN_INVOICE_VIEW_BY_ID,
    payload : {url, history}
  }
};

export const getJuteHessianInvoiceViewByIdSuccessfull = (data) => {
  return {
    type : JUTE_HESSIAN_INVOICE_VIEW_BY_ID_SUCCESSFULL,
    payload : data
  }
}

export const UpdateJuteHessianInvoice = (url, data, history) => {
  return {
    type: UPDATE_JUTE_HESSIAN_INVOICE,
    payload : {url,data,history}
  }
}

export const UpdateJuteHessianInvoiceSuccessfull = (data) => {
  return {
    type: UPDATE_JUTE_HESSIAN_INVOICE_SUCCESSFULL,
    payload: data,
  }
}
export const getInvoiceJuteYarnUomRelationValue = (url,ItemId ,type,history) => {
  return {
    type:INVOICE_JUTE_YARN_GET_UOM_RELATION_VALUE,
    payload: { url,ItemId ,type,history },
  };
};
export const getInvoiceJuteYarnUomRelationValueSuccessfull = (data) => {
  return {
   type:INVOICE_JUTE_YARN_GET_UOM_RELATION_VALUE_SUCCESSFULL,
   payload: data,
  };
};
export const apiErrors = (error) => {
  return {
    type: API_ERRORS,
    payload: error,
  };
};

// for upload,Download & delete Support documents in Invoice

export const UploadInvoiceFiles = (url,data,history,sourceMenu,regInvoiceId) =>{
  return {
      type:UPLOAD_INVOICE_FILES,
      payload:{url,data,history,sourceMenu,regInvoiceId}
  }
}
export const UploadInvoiceFilesSuccessfull = (data)=>{
  return {
      type:UPLOAD_INVOICE_FILES_SUCCESSFULL,
      payload:data
  }
}

export const deleteInvoiceFile = (url,history) =>{
  return {
      type:DELETE_INVOICE_FILE,
      payload:{url,history}
  }
}
export const getInvoiceFiles = (url, history) => {
  return {
    type: GET_INVOICE_FILES,
    payload: { url, history},
  };
};
export const InvoiceFilesSuccessfull = (data) => {
  return {
    type: INVOICE_FILES_SUCCESSFULL,
    payload: data,
  };
};

export const deleteInvoiceFileSuccessfull = (data)=>{
  return {
      type: DELETE_INVOICE_FILE_SUCCESSFULL,
      payload: data
  }
}

// ===================================== Generate E-Invoice and E-Way Bill actions Ends =======================================
export const generateEInvoice = (url, data, history)=>{
  return {
    type: GENERATE_E_INVOICE,
    payload:{url,data,history}
  };
}

export const clearInvoiceMessage = () => {
  return {
    type: CLEAR_E_INVOICE_MESSAGE,
  };
};

export const generateEInvoiceSuccessfull = (data)=>{
  return {
    type: GENERATE_E_INVOICE_SUCCESSFULL,
    payload: data,
  };
}

export const generateEwayBill = (url, data, history)=>{
  return {
    type: GENERATE_E_WAY_BILL,
    payload:{url,data,history}
  };
}

export const generateEwayBillSuccessfull = (data)=>{
  return {
    type: GENERATE_E_WAY_BILL_SUCCESSFULL,
    payload: data,
  };
}

export const clearEwayBillMessage = () => {
  return {
    type: CLEAR_E_WAY_BILL_MESSAGE,
  };
};

export const getInvoiceYarnUomList = (url, rowId) => {
  return {
    type: INVOICE_JUTE_YARN_UOM_LIST,
    payload: { url,rowId },
  };
};
export const getInvoiceYarnUomListSuccessfull = (data) => {
  return {
    type: INVOICE_JUTE_YARN_UOM_LIST_SUCCESSFULL,
    payload: data,
  };
};
// ===================================== Generate E-Invoice and E-Way Bill actions Ends =======================================