import {
  TROLLY_DD_LIST,
  TROLLY_DD_LIST_SUCCESSFULL,
  // BEAMING_CREATE,
  // BEAMING_CREATE_SUCCESSFULL,
  // BEAMING_DD_LIST,
  // BEAMING_DD_LIST_SUCCESSFULL,
  // BEAMING_DELETE_ROW,
  // BEAMING_DELETE_ROW_SUCCESSFULL,
  WINDING_DOFF_ENTRY_LIST,
  WINDING_DOFF_ENTRY_LIST_SUCCESSFULL,
  WINDING_DOFF_ROW_DELETE,
  WINDING_DOFF_ROW_DELETE_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  WindingDoffData: [],
  windingDelRowData:[],
  trollyDdListData:[],
  // beamingCreateData:[],
  // beamingDelRowData:[],
};

const WindingDoffEntryReducer = (state = initialState, action) => {
  switch (action.type) {
    case WINDING_DOFF_ENTRY_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case WINDING_DOFF_ENTRY_LIST_SUCCESSFULL:
      state = {
        ...state,
        WindingDoffData: action.payload.response,
        loading: true,
      };
      break;
    case WINDING_DOFF_ROW_DELETE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case WINDING_DOFF_ROW_DELETE_SUCCESSFULL:
      state = {
        ...state,
        windingDelRowData: action.payload.response,
        loading: true,
      };
      break;
      case TROLLY_DD_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case TROLLY_DD_LIST_SUCCESSFULL:
      state = {
        ...state,
        trollyDdListData: action.payload.response,
        loading: true,
      };
      break;
      // case BEAMING_CREATE:
      // state = {
      //   ...state,
      //   loading: true,
      // };
      // break;
    // case BEAMING_CREATE_SUCCESSFULL:
    //   state = {
    //     ...state,
    //     beamingCreateData: action.payload.response,
    //     loading: true,
    //   };
    //   break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default WindingDoffEntryReducer;
