import {
  DELETE_HOLIDAY,
  DELETE_HOLIDAY_SUCCESSFULL,
  HOLIDAY_DATA_ADD,
  HOLIDAY_DATA_ADD_SUCCESSFULL,
  HOLIDAY_MASTER_LIST,
  HOLIDAY_MASTER_LIST_SUCCESSFULL,
  HOLIDAY_DATA_UPDATE,
  HOLIDAY_DATA_UPDATE_SUCCESSFULL,
  UPDATE_HOLIDAY_LIST_FOR_EMPLOYEES,
  UPDATE_HOLIDAY_LIST_FOR_EMPLOYEES_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  holiday_master_List: [],
  holiday_add_data: [],
  holiday_delete_data: [],
  holiday_update_data: [],
  error_msg: null,
  update_holiday_list:[]

};

const HolidayListReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOLIDAY_MASTER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case HOLIDAY_MASTER_LIST_SUCCESSFULL:
      state = {
        ...state,
        holiday_master_List: action.payload.response,
        loading: false,
      };
      break;
    case HOLIDAY_DATA_ADD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case HOLIDAY_DATA_ADD_SUCCESSFULL:
      state = {
        ...state,
        holiday_add_data: action.payload.response,
        loading: false,
      };
      break;
    case DELETE_HOLIDAY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_HOLIDAY_SUCCESSFULL:
      state = {
        ...state,
        holiday_delete_data: action.payload.response,
        loading: false,
      };
      break;
    case HOLIDAY_DATA_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case HOLIDAY_DATA_UPDATE_SUCCESSFULL:
      state = {
        ...state,
        holiday_update_data: action.payload.response,
        loading: false,
      };
      break;
      case UPDATE_HOLIDAY_LIST_FOR_EMPLOYEES:
        state = {
          ...state,
          loading: true,
        };
        break;
      case UPDATE_HOLIDAY_LIST_FOR_EMPLOYEES_SUCCESSFULL:
        state = {
          ...state,
        update_holiday_list: action.payload.response,
          loading: false,
        };
        break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default HolidayListReducer;
