import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/PaginationGridView";
import { budgetHeads_List } from "../../../store/Master/BudgetHeads/actions";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      budgetHeadsList: [],
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      budgetHeadName: "",
      parentBudgetId: "",
      parentBudgetName: "",
      dataexp: "",
    };
  }
  componentDidMount() {
    const data = {
      companyId: localStorage.getItem("companyId"),

      budgetHeadName: null,
      parentBudgetId: null,
      parentBudgetName: null,
      startIndex: 1,
      lastIndex: 10,
    };

    this.props.budgetHeads_List(
      serverApi.GET_ALL_BUDGETHEADS_LIST,
      data,
      this.props.history
    );
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.BudgetHeads_List) {
      this.setState({
        budgetHeadsList: props.BudgetHeads_List.data,
        totalRecs: props.BudgetHeads_List.totalRecords,
      });
    }
  }
  handleSearch = (data) => {
    const searchData = {
      companyId: localStorage.getItem("companyId"),

      budgetHeadName: data.budgetHeadName,
      parentBudgetId: data.parentBudgetId,
      parentBudgetName: data.description,
      startIndex: 1,
      lastIndex: 10,
    };
    this.setState({
      budgetHeadName: data.budgetHeadName,
      parentBudgetId: data.parentBudgetId,
      parentBudgetName: data.description,
    });
    this.props.budgetHeads_List(
      serverApi.GET_ALL_BUDGETHEADS_LIST,
      searchData,
      this.props.history
    );
  };
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const data = {
        companyId: localStorage.getItem("companyId"),
        startIndex: sizePerPage,
        lastIndex: currentIndex,
        budgetHeadName: this.state.budgetHeadName,
        parentBudgetId: this.state.parentBudgetId,
        parentBudgetName: this.state.description,
      };
      if (currentIndex >= 0) {
        this.props.budgetHeads_List(
          serverApi.GET_ALL_BUDGETHEADS_LIST,
          data,
          this.props.history
        );
      }
    } else {
      const data = {
        companyId: localStorage.getItem("companyId"),
        startIndex: currentIndex === 0 ? currentIndex + 1 : currentIndex,
        lastIndex: sizePerPage,
        budgetHeadName: this.state.budgetHeadName,
        parentBudgetId: this.state.parentBudgetId,
        parentBudgetName: this.state.description,
      };
      console.log(this.state,"state")
      if (currentIndex >= 0) {
        this.props.budgetHeads_List(
          serverApi.GET_ALL_BUDGETHEADS_LIST,
          data,
          this.props.history
        );
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.budgetHeadsList}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          url="/create_budget_heads"
          filter_form={"budgetHeads"}
          handleSearch={this.handleSearch}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          apiexp={serverApi.GET_ALL_BUDGETHEADS_LIST}
          payloadexp={this.state.dataexp}
          expName={"BudgetHeads.csv"}
        />
      </>
    );
  }
}
const Columns = [
  {
    Header: "Budget Heads Name",
    accessor: "name",
    hidden: false,
  },
  {
    Header: "Description",
    accessor: "code",
    hidden: true,
  },
  {
    Header: "Status",
    accessor: "status",
    hidden: true,
  },
  {
    Header: "Parent Budget Heads",
    accessor: "description",
    hidden: false,
  },
  {
    Header: "Message",
    accessor: "message",
    hidden: true,
  },
  {
    Header: "Created On",
    accessor: "createdOn",
    hidden: true,
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    hidden: true,
  },
  {
    Header: "Is Active",
    accessor: "isActive",
    hidden: true,
  },
  {
    Header: "Company Name",
    accessor: "companyName",
    hidden: true,
  },
  {
    Header: "Company Id",
    accessor: "companyId",
    hidden: true,
  },
  {
    Header: "Last Modified By",
    accessor: "lastModifiedBy",
    hidden: true,
  },
];
const mapStatetoProps = (state) => {
  const { BudgetHeadsList, BudgetHeads_List } = state.BudgetHeadsList;
  return { BudgetHeadsList, BudgetHeads_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    budgetHeads_List,
  })(Index)
);
