import { useEffect } from "react";
import { useState } from "react";
import DonutChart from "react-donut-chart";

const reactDonutChartBackgroundColor = [];
const reactDonutChartInnerRadius = 0.8;
const reactDonutChartSelectedOffset = 0.05;
const reactDonutChartHandleClick = (item, toggled) => {
};

let reactDonutChartStrokeColor = "#FFFFFF";

export default function App(props) {
  const [leaveDetails, setleaveDetails] = useState([]);
  const [totalCount, setTotalCount] = useState();

  useEffect(() => {
    let count = 0;
    let leaveDetailsList = props.leaveDetails.map((item) => {
      if (item.color !== undefined) {
        let color = item.color;
        count = count + item.leaveCount;
        reactDonutChartBackgroundColor.push(color);
        return ({
          label: item.leaveType +' - '+ item.leaveCount,
          value: item.leaveCount,
          color: color,
        });
      }
    });
    setleaveDetails(leaveDetailsList);
    setTotalCount(count);
  }, [props.leaveDetails]);

  return (
    <div className="doughnutContainer">
      <div className="doughnutOne">
        <div className="totalAmount">{totalCount}</div>
        <DonutChart
          width={500}
          height={140}
          strokeColor={reactDonutChartStrokeColor}
          strokeWidth={2}
          data={leaveDetails}
          colors={reactDonutChartBackgroundColor}
          innerRadius={reactDonutChartInnerRadius}
          selectedOffset={reactDonutChartSelectedOffset}
          onClick={(item, toggled) => reactDonutChartHandleClick(item, toggled)}
          className="donutchart"
          clickToggle={false}
        />
      </div>
      <ul className="legend">
        {leaveDetails.map((item, index) => (
          <li key={index}>
            <div
              style={{
                backgroundColor: item.color,
                width: "12px",
                height: "12px",
                display: "inline-block",
              }}
            ></div>
            <div>
              {item.label}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
