import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../../components/Dropdown/DynamicSelect";
import DynamicSelectNew from "../../../../components/Dropdown/DropdownSelectNew";
import TextFieldNormal from "../../../../components/TextField/TextFieldNormal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { getBankAccountList } from "../../../../store/Sales/DeliveryOrder/actions";
import moment from "moment";
import { serverApi } from "../../../../helpers/Consts";
import { SalesGstCalculations } from "../../../../Utilities/SalesGstCalculation";
import swal from "sweetalert";
import {
  getBranchList,
  getCustomerList,
  getBillingShippingList,
} from "../../../../store/Global/DropDownApis/actions";
import { getQuotationBroker } from "../../../../store/Sales/Qutation/actions";
import {
  getAllTransporter,
  getDeliveryOrderList,
} from "../../../../store/Sales/DeliveryOrder/actions";
import {
  getRegularInvoiceHeader,
  getSalesOrderViewByIdForInvoice,
  getDeliveryOrderViewByIdForInvoice,
  getRegularInvoiceLineItems,
} from "../../../../store/Sales/InVoice/actions";
import { getSoDropDown } from "../../../../store/Sales/DeliveryOrder/actions";
import { isEmpty, isEmptyWithZero } from "../../../../Utilities/helper";
import FormPopup from "../../../../components/FormlPopup/FormPopup";

const RegularFillDetails = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const location = useLocation();
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [HeaderData, setHeaderData] = useState({});
  const [branchListData, setbranchListData] = useState([]);
  const [brokerData, setbrokerData] = useState([]);
  const [CustomerData, setCustomerData] = useState([]);
  const [bankListData, setbankListData] = useState([]);
  const [billingAddressData, setbillingAddressData] = useState([]);
  const [shippingAddressData, setshippingAddressData] = useState([]);
  const [transporterData, settransporterData] = useState([]);
  const [invoiceData, setinvoiceData] = useState(props.invoiceData);
  const [SoDropdownVal, setSoDropdownVal] = useState([]);
  const [DoDropdownVal, setDoDropdownVal] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [LineItems, setLineItems] = useState([]);
  const [backDaysAllowable, setBackDaysAllowable] = useState("");
  const [futureDaysAllowable, setFutureDaysAllowable] = useState("");
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };


  useEffect(() => {
    // Check if pathname matches the specific path you want to refresh
    if (location.pathname === '/specific-path') {
      // Perform the actions you want to execute to refresh the component
      console.log('Refreshing component because pathname changed to /specific-path');
    }
  }, [location.pathname]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    dispatch(
      getSoDropDown(
        serverApi.SALES_ORDER_DROPDOWN_FOR_INVOICE +
          "1/" +
          localStorage.getItem("companyId")
      )
    );
    dispatch(
      getBranchList(
        serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
        history
      )
    );
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
    };
    dispatch(getCustomerList(serverApi.GET_ALL_CUSTOMERS, data, history));
    dispatch(
      getBankAccountList(serverApi.GET_ALL_BANK_ACCOUNT_LIST, data, history)
    );
    dispatch(
      getQuotationBroker(
        serverApi.GET_ALL_BROKER_LIST +
          localStorage.getItem("companyId") +
          "/SALE",
        history
      )
    );
    dispatch(
      getAllTransporter(
        serverApi.GET_ALL_TRANSPORTER_MASTER_LIST +
          localStorage.getItem("companyId")
      )
    );

    const currentMonth = moment().format('MM');
    const currentDate = moment().format('DD');
    const fiscalYearStart = moment(`${localStorage.getItem("acadamicYear")}-${currentMonth}-${currentDate}`);
    const today = moment();
    let dateValue;
    if (today.isBefore(fiscalYearStart)) {
      dateValue = today.format("YYYY-MM-DD");
    } else {
      dateValue = fiscalYearStart.format("YYYY-MM-DD");
    }

    if (userDit) {
      userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              if (item.backDaysAllowable !== undefined) {
                let backDaysAllowable = JSON.parse(item.backDaysAllowable);
                let backDays = backDaysAllowable.bdaystype;
                if (Number(backDays) === 1) {
                  backDays = 7;
                } else if (Number(backDays) === 2) {
                  backDays = 14;
                } else if (Number(backDays) === 3) {
                  backDays = "";
                } else if (Number(backDays) === 4) {
                  backDays = backDaysAllowable.otherBackDateValue;
                } else {
                  backDays = backDaysAllowable.bdaystype;
                }
                let minDate = moment(dateValue).subtract(
                  Number(backDays),

                  "days"
                );
                let setMinDate = moment(minDate).format("YYYY-MM-DD");
                if(backDays!==""){
                setBackDaysAllowable(setMinDate);
                }
              }
              if (item.futureDaysAllowable !== undefined) {
                let futureDaysAllowable = JSON.parse(item.futureDaysAllowable);
                let futureDays = futureDaysAllowable.fdaystype;
                if (Number(futureDays) === 1) {
                  futureDays = 7;
                } else if (Number(futureDays) === 2) {
                  futureDays = 14;
                } else if (Number(futureDays) === 3) {
                  futureDays = "";
                } else if (Number(futureDays) === 4) {
                  futureDays = futureDaysAllowable.othervalue;
                } else {
                  futureDays = futureDaysAllowable.fdaystype;
                }
                let maxDate = moment(dateValue).add(
                  Number(futureDays),

                  "days"
                );
                let setMaxDate = moment(maxDate).format("YYYY-MM-DD");
                if(futureDays!==""){
                setFutureDaysAllowable(setMaxDate);
                }
              }
            });
            setHeaderData((prev) => ({
              ...prev,
              invoiceDate: dateValue,
            }));
            const UpdatedRec = {
              ...RegularInvoiceHeader,
              invoiceDate: dateValue,
            };
            dispatch(getRegularInvoiceHeader(UpdatedRec));

          }
      });
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { branchList, customerList, billingShippingList } = useSelector(
    (state) => state.DropDownListReducer
  );

  const { BrokerData } = useSelector((state) => state.QuotationReducer);
  const { RegularInvoiceHeader, RegularInvoiceLineItems } = useSelector(
    (state) => state.InvoiceReducer
  );

  const {
    TransporterList,
    SrDropDownData,
    DeliveryOrder_List,
    bankAccountList,
  } = useSelector((state) => state.DeliveryOrderReducer);

  const isEmptyObj = (myObject) => {
    const keys = Object.keys(myObject);
    return keys.length === 0;
  };

  useEffect(() => {
    if (branchList) {
      if (branchList.data) {
        if (branchList.data.length !== 0) {
          let List = branchList.data.filter((item) => item.value !== 0);
          if (List.length !== 0) {
            var BranchList = [];
            List.map((item) => {
              BranchList.push({
                value: item.value,
                name: item.label,
                label: item.label,
              });
            });
          }
          setbranchListData(BranchList);
        }
      }
    }
    if (bankAccountList) {
      if (bankAccountList.data) {
        if (bankAccountList.data.length !== 0) {
          var BankList = [];
          // BankList.push(bankSelect);
          bankAccountList.data.data.map((item) => {
            BankList.push({
              value: item.id,
              name:
                item.bankName +
                "^" +
                item.accountNo +
                "^" +
                item.ifscCode +
                "^" +
                item.micrCode +
                "^" +
                item.branch,
              label: item.bankName,
            });
          });

          setbankListData(BankList);
        }
      }
    }
    if (customerList) {
      if (customerList.data) {
        if (customerList.data.length !== 0) {
          var CusList = [];
          customerList.data.map((item) => {
            CusList.push({
              value: item.id,
              name: item.name,
              label: item.name,
            });
          });
          setCustomerData(CusList);
        }
      }
    }
    if (BrokerData) {
      if (BrokerData.data) {
        setbrokerData(BrokerData.data);
      }
    }

    if (TransporterList) {
      if (TransporterList.data) {
        if (TransporterList.data.data) {
          let TransporterLists = TransporterList.data.data.map((item) => ({
            name:
              item.transporterName +
              "^" +
              item.transporterAddress +
              "^" +
              item.stateName +
              "^" +
              item.stateCode +
              "^" +
              item.transporterGst,
            label: item.transporterName,
            value: item.transporterId,
          }));
          settransporterData(TransporterLists);
        }
      }
    }
    if (DeliveryOrder_List) {
      if (DeliveryOrder_List.data) {
        var List = [];
        if (props.DeliveryOrderMandatory === false) {
          List.push({
            value: "",
            label: "Select",
            name: "Select",
          });
        }

        if (DeliveryOrder_List.data.length !== 0) {
          DeliveryOrder_List.data.map((item) => {
            List.push({
              value: item.deliveryOrderId,
              label: item.deliveryOrderNumber,
              name: item.deliveryOrderNumber,
            });
          });
          setDoDropdownVal(List);
        } else {
          setDoDropdownVal([]);
        }
      }
    }
    if (SrDropDownData) {
      if (SrDropDownData.data) {
        var srDropDownData = [];
        if (SrDropDownData.data.length !== 0) {
          SrDropDownData.data.map((item) => {
            srDropDownData.push({
              value: item.id,
              name: item.Value,
              label: item.Value,
            });
          });
          setSoDropdownVal(srDropDownData);
        }
      }
    }
  }, [
    branchList,
    customerList,
    BrokerData,
    TransporterList,
    DeliveryOrder_List,
    SrDropDownData,
  ]);


useEffect(() => {
  if (billingShippingList) {
    if (billingShippingList.data) {
      if (billingShippingList.data.customerLineitems) {
        if (billingShippingList.data.customerLineitems.length !== 0) {
          var list = [];
          let billingAddressList = [];
          let shippingaddressList = [];
          let RegularInvoiceHeaderObj = RegularInvoiceHeader
          billingShippingList.data.customerLineitems.map((prop, i) => {
            if (prop.addressType === 2 || prop.addressType === null) {
              if (
                (prop.customerLineId === RegularInvoiceHeader.shippingTo &&
                  prop.isActive === 0) ||
                prop.isActive !== 0
              ) {
                billingAddressList.push({
                  value: prop.customerLineId,
                  label: prop.address,
                  name: prop.address,
                });
                list.push({
                  value: prop.customerLineId,
                  label: prop.address,
                  name: prop.address,
                });
              }
            }
            if (prop.addressType === 1 || prop.addressType === null || prop.addressType === 2 ) {
              if (
                (prop.customerLineId === RegularInvoiceHeader.shippingTo &&
                  prop.isActive === 0) ||
                prop.isActive !== 0
              ) {
                shippingaddressList.push({
                  value: prop.customerLineId,
                  label: prop.address,
                  name: prop.address,
                });
                list.push({
                  value: prop.customerLineId,
                  label: prop.address,
                  name: prop.address,
                });
              }
            }
          });
          if(props.source ==="DIRECTORDER"  || RegularInvoiceHeader.customerResponse===false){
          billingShippingList.data.customerLineitems.map((prop, i) => {
            if (billingAddressList.length !== 0 &&  HeaderData.customerId !== "" &&
            HeaderData.customerId !== null) {
              billingAddressList.map((billingdata, ind) => {
                if (ind === 0) {
                  if (!isEmptyObj(HeaderData)) {
                    if (
                      HeaderData.customerId !== "" ||
                      HeaderData.customerId !== null
                    ) {
                      if (prop.customerLineId === billingdata.value) {
                        setHeaderData((prev) => ({
                          ...prev,
                          billingTo: prop.customerLineId,
                          billingAddress: prop.address,
                          billingState: prop.state,
                          billedToGstin: prop.gstNo,
                          billedToContactNumber: prop.contactNo,
                          billedToContactPerson: prop.contactPerson,
                          billedToEmail:billingShippingList.data.customerHdr.emailId,
                          taxType: 2,
                        }));
                        const UpdatedRec = {
                          ...RegularInvoiceHeaderObj,
                          billingTo: prop.customerLineId,
                          billingAddress: prop.address,
                          billingState: prop.state,
                          billedToGstin: prop.gstNo,
                          billedToContactNumber: prop.contactNo,
                          billedToContactPerson: prop.contactPerson,
                          billedToEmail:billingShippingList.data.customerHdr.emailId,
                          taxType: 2,
                        };
                        RegularInvoiceHeaderObj = UpdatedRec
                      }
                    }else{
                      setHeaderData((prev) => ({
                        ...prev,
                        billingTo:"",
                        billingAddress:"",
                        billingState: "",
                        billedToGstin: "",
                        billedToContactNumber: "",
                        billedToContactPerson: "",
                        billedToEmail:"",
                        taxType: "",
                      }));
                      const UpdatedRec = {
                        ...RegularInvoiceHeaderObj,
                        billingTo:"",
                        billingAddress:"",
                        billingState: "",
                        billedToGstin: "",
                        billedToContactNumber: "",
                        billedToContactPerson: "",
                        billedToEmail:"",
                        taxType: "",
                      };
                      RegularInvoiceHeaderObj = UpdatedRec
                    }
                  }
                }
              });
            } else {
              setHeaderData((prev) => ({
                ...prev,
                billingTo:"",
                        billingAddress:"",
                        billingState: "",
                        billedToGstin: "",
                        billedToContactNumber: "",
                        billedToContactPerson: "",
                        billedToEmail:"",
                        taxType: "",
              }));
              const UpdatedRec = {
                ...RegularInvoiceHeaderObj,
                billingTo:"",
                        billingAddress:"",
                        billingState: "",
                        billedToGstin: "",
                        billedToContactNumber: "",
                        billedToContactPerson: "",
                        billedToEmail:"",
                        taxType: "",
              };
              RegularInvoiceHeaderObj = UpdatedRec

            }
          })
          }
          if(props.source ==="DIRECTORDER"  || RegularInvoiceHeader.customerResponse===false){
          billingShippingList.data.customerLineitems.map((prop, i) => {
            if (shippingaddressList.length !== 0 &&  HeaderData.customerId !== "" &&
            HeaderData.customerId !== null) {
              shippingaddressList.map((shippingdata, ind) => {
                if (ind === 0) {
                  if (!isEmptyObj(HeaderData)) {
                    if (
                      HeaderData.customerId !== "" ||
                      HeaderData.customerId !== null
                    ) {
                      if (prop.customerLineId === shippingdata.value) {
                        setHeaderData((prev) => ({
                          ...prev,
                          shippingTo: prop.customerLineId,
                          shippingAddress: prop.address,
                          shippingState: prop.state,
                          shippedToGstin: prop.gstNo,
                          shippedToContactNumber: prop.contactNo,
                          shippedToContactPerson: prop.contactPerson,
                          shippedToEmail:
                            billingShippingList.data.customerHdr.emailId,
                          taxType: 2,
                        }));
                        const UpdatedRec = {
                          ...RegularInvoiceHeaderObj,
                          shippingTo: prop.customerLineId,
                    shippingAddress: prop.address,
                    shippingState: prop.state,
                    shippedToGstin: prop.gstNo,
                    shippedToContactNumber: prop.contactNo,
                    shippedToContactPerson: prop.contactPerson,
                    shippedToEmail:
                      billingShippingList.data.customerHdr.emailId,
                    taxType: 2,
                        };
                        RegularInvoiceHeaderObj = UpdatedRec
                      }
                    }else{
                      setHeaderData((prev) => ({
                        ...prev,
                        shippingTo: "",
                        shippingAddress: "",
                        shippingState: "",
                        shippedToGstin: "",
                        shippedToContactNumber: "",
                        shippedToContactPerson: "",
                        shippedToEmail:"",
                        taxType: "",
                      }));
                      const UpdatedRec = {
                        ...RegularInvoiceHeaderObj,
                        shippingTo: "",
                        shippingAddress: "",
                        shippingState: "",
                        shippedToGstin: "",
                        shippedToContactNumber: "",
                        shippedToContactPerson: "",
                        shippedToEmail:"",
                        taxType: "",
                      };
                      RegularInvoiceHeaderObj = UpdatedRec
                    }
                  }
                }
              });
            }  else {
              setHeaderData((prev) => ({
                ...prev,
                shippingTo: "",
                shippingAddress: "",
                shippingState: "",
                shippedToGstin: "",
                shippedToContactNumber: "",
                shippedToContactPerson: "",
                shippedToEmail:"",
                taxType: "",
              }));
              const UpdatedRec = {
                ...RegularInvoiceHeaderObj,
                shippingTo: "",
                shippingAddress: "",
                shippingState: "",
                shippedToGstin: "",
                shippedToContactNumber: "",
                shippedToContactPerson: "",
                shippedToEmail:"",
                taxType: "",
              };
              RegularInvoiceHeaderObj = UpdatedRec
            }
          });
          }
          if (RegularInvoiceHeader.customerId !== "") {
            dispatch(getRegularInvoiceHeader(RegularInvoiceHeaderObj));
            setbillingAddressData(billingAddressList);
            setshippingAddressData(shippingaddressList);
          }
        }
      }
    }
  }
  }, [billingShippingList]);

  useEffect(() => {
    setHeaderData(RegularInvoiceHeader);
    setLineItems(RegularInvoiceLineItems);
  }, [RegularInvoiceHeader]);

  const handleSelect_Date = (e, name) => {
    if (name === "invoiceDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      if (isEmpty(HeaderData.deliveryOrderDate)) {
        if (Date < HeaderData.salesOrderDate) {
          swal("Invoice Date Shouldn't be less than the Sales Order Date");
          setHeaderData((prev) => ({
            ...prev,
            invoiceDate: HeaderData.salesOrderDate,
          }));
          const UpdatedRec = {
            ...HeaderData,
            invoiceDate: HeaderData.salesOrderDate,
          };
          dispatch(getRegularInvoiceHeader(UpdatedRec));
        } else {
          setHeaderData((prev) => ({
            ...prev,
            invoiceDate: Date,
          }));
          const UpdatedRec = {
            ...HeaderData,
            invoiceDate: Date,
          };
          dispatch(getRegularInvoiceHeader(UpdatedRec));
        }
      } else {
        if (Date < HeaderData.deliveryOrderDate) {
          swal("Invoice Date Shouldn't be less than the Delivery Order Date");
          setHeaderData((prev) => ({
            ...prev,
            invoiceDate: HeaderData.deliveryOrderDate,
          }));
          const UpdatedRec = {
            ...HeaderData,
            invoiceDate: HeaderData.deliveryOrderDate,
          };
          dispatch(getRegularInvoiceHeader(UpdatedRec));
        } else {
          setHeaderData((prev) => ({
            ...prev,
            invoiceDate: Date,
          }));
          const UpdatedRec = {
            ...HeaderData,
            invoiceDate: Date,
          };
          dispatch(getRegularInvoiceHeader(UpdatedRec));
        }
      }
    }
    if (name === "salesOrderDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        salesOrderDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        salesOrderDate: Date,
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));
    }
    if (name === "deliveryOrderDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        deliveryOrderDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        deliveryOrderDate: Date,
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));
    }
    if (name === "quotationDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        quotationDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        quotationDate: Date,
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));
    }
  };
  const onhandlechange = (key) => (event) => {
    if (key === "quotationNo") {
      setHeaderData((prev) => ({
        ...prev,
        quotationNo: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        quotationNo: event,
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));
    }
    // if (key === "vehicleNo") {
    //   setHeaderData((prev) => ({
    //     ...prev,
    //     vehicleNo: event,
    //   }));
    //   const UpdatedRec = {
    //     ...HeaderData,
    //     vehicleNo: event,
    //   };
    //   dispatch(getRegularInvoiceHeader(UpdatedRec));
    // }
    if (key === "poNumber") {
      setHeaderData((prev) => ({
        ...prev,
        poNumber: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        poNumber: event,
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));
    }
    if (key === "customerReferenceNumber") {
      setHeaderData((prev) => ({
        ...prev,
        customerReferenceNumber: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        customerReferenceNumber: event,
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));
    }
    if (key === "billingState") {
      setHeaderData((prev) => ({
        ...prev,
        billingState: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        billingState: event,
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));
    }
    if (key === "shippingState") {
      setHeaderData((prev) => ({
        ...prev,
        shippingState: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        shippingState: event,
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));
    }
    if (key === "brokerCommisionPercent") {
      let Val = event.match(/^(\d*\.{0,1}\d{0,2}$)/);
      var Event = "";
      if (Val) {
        if (event > 100) {
          swal("Cannot enter more than 100%");
          Event = "";
        } else {
          Event = event;
        }
      } else {
        swal("Cannot enter more than two decimals");
        Event = "";
      }
      setHeaderData((prev) => ({
        ...prev,
        brokerCommisionPercent: Event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        brokerCommisionPercent: Event,
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));
    }
  };
  const handleCalculationCustomer = (
    Calculations,
    selectedValue,
    selectedName,
    taxType,
    taxTypeDesc
  ) => {
    setLineItems(Calculations.lineItems);
    dispatch(getRegularInvoiceLineItems(Calculations.lineItems));

    let Headerdata = {
      ...RegularInvoiceHeader,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
      customerId: selectedValue,
      customerName: selectedName,
      taxType: taxType,
      taxTypeDesc: taxTypeDesc,
    };
    setHeaderData((prev) => ({
      ...prev,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
      customerId: selectedValue,
      customerName: selectedName,
      taxType: taxType,
      taxTypeDesc: taxTypeDesc,
    }));
    dispatch(getRegularInvoiceHeader(Headerdata));
  };
  const handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name == "deliveryTerms") {
      setHeaderData((prev) => ({
        ...prev,
        deliveryTerms: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        deliveryTerms: selectedName,
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));
    }
    if (name == "salesOrderId") {
      setHeaderData((prev) => ({
        ...prev,
        salesOrderId: selectedValue,
        salesOrderNumber: selectedName,
        deliveryOrderId: "",
        deliveryOrderNo: "",
      }));
      const UpdatedRec = {
        ...HeaderData,
        salesOrderId: selectedValue,
        salesOrderNumber: selectedName,
        deliveryOrderId: "",
        deliveryOrderNo: "",
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));
      const data = {
        fromDate: "",
        toDate: "",
        cusId: "",
        branchId: null,
        status: "3",
        companyId: localStorage.getItem("companyId"),
        acYear: localStorage.getItem("acadamicYear"),
        deliveryOrderNo: "",
        type: 1,
        startIndex: 0,
        lastIndex: 0,
        salesOrderNo: selectedName,
      };
      dispatch(
        getDeliveryOrderList(
          serverApi.DELIVERY_ORDER_GET_API,
          data,
          props.history
        )
      );

      dispatch(
        getSalesOrderViewByIdForInvoice(
          serverApi.SALES_ORDER_VIEW_BYID +
            "1/" +
            selectedValue +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).userId,
          history
        )
      );
    }
    if (name == "deliveryOrderId") {
      if (selectedValue === "") {
        setHeaderData((prev) => ({
          ...prev,
          deliveryOrderId: "",
          deliveryOrderNo: "N/A",
        }));

        const UpdatedRec = {
          ...HeaderData,
          deliveryOrderId: "",
          deliveryOrderNo: "N/A",
        };
        dispatch(getRegularInvoiceHeader(UpdatedRec));
        dispatch(
          getSalesOrderViewByIdForInvoice(
            serverApi.SALES_ORDER_VIEW_BYID +
              "1/" +
              HeaderData.salesOrderId +
              "/" +
              JSON.parse(localStorage.getItem("authUser")).userId,
            history
          )
        );

        return;
      }

      setHeaderData((prev) => ({
        ...prev,
        deliveryOrderId: selectedValue,
        deliveryOrderNo: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        deliveryOrderId: selectedValue,
        deliveryOrderNo: selectedName,
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));

      dispatch(
        getDeliveryOrderViewByIdForInvoice(
          serverApi.DELIVERY_ORDER_VIEW_BY_ID +
            "1/" +
            selectedValue +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).userId,
          history
        )
      );
    }
    if (name == "invoiceType") {
      setHeaderData((prev) => ({
        ...prev,
        invoiceType: selectedValue,
        invoiceTypeDesc: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        invoiceType: selectedValue,
        invoiceTypeDesc: selectedName,
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));
    }
    if (name == "branchId") {
      setHeaderData((prev) => ({
        ...prev,
        branchId: selectedValue,
        branchName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        branchId: selectedValue,
        branchName: selectedName,
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));
    }
    if (name === "bankId") {
      var Data = selectedName.split("^", 5);
      setHeaderData((prev) => ({
        ...prev,
        bankId: selectedValue,
        bankName: Data[0],
        accountNo: selectedValue === 0 ? "" : Data[1],
        ifscCode: selectedValue === 0 ? "" : Data[2],
        micrCode: selectedValue === 0 ? "" : Data[3],
        bankBranch: selectedValue === 0 ? "" : Data[4],
      }));
      const UpdatedRec = {
        ...HeaderData,
        bankId: selectedValue,
        bankName: Data[0],
        accountNo: Data[1],
        ifscCode: Data[2],
        micrCode: Data[3],
        bankBranch: Data[4],
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));
    }
    if (name == "brokerId") {
      if (selectedValue === 0) {
        setHeaderData((prev) => ({
          ...prev,
          brokerId: "",
          brokerCommisionPercent: "",
          brokerName: "",
        }));
        const UpdatedRec = {
          ...HeaderData,
          brokerId: "",
          brokerCommisionPercent: "",
          brokerName: "",
        };
        dispatch(getRegularInvoiceHeader(UpdatedRec));

        return;
      }
      setHeaderData((prev) => ({
        ...prev,
        brokerId: selectedValue,
        brokerCommisionPercent: "",
        brokerName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        brokerId: selectedValue,
        brokerCommisionPercent: "",
        brokerName: selectedName,
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));
    }

    if (name == "customerId") {
      setHeaderData((prev) => ({
        ...prev,
        customerId: selectedValue,
        customerName: selectedName,
      }));
      let TaxPer = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: 2,
        },
        "INVOICE"
      );
      handleCalculationCustomer(
        TaxPer,
        selectedValue,
        selectedName,
        HeaderData.taxType,
        HeaderData.taxTypeDesc
      );
      dispatch(
        getBillingShippingList(
          serverApi.GET_ALL_BILLING_SHIPPING +
            selectedValue +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).cipher +
            "?isForSalesModule=1",
          history
        )
      );
    }

    if (name == "billingFrom") {
      setHeaderData((prev) => ({
        ...prev,
        billingFrom: selectedValue,
        billingFromAddress: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        billingFrom: selectedValue,
        billingFromAddress: selectedName,
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));
    }
    if (name == "shippingFrom") {
      setHeaderData((prev) => ({
        ...prev,
        shippingFrom: selectedValue,
        shippingFromAddress: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        shippingFrom: selectedValue,
        shippingFromAddress: selectedName,
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));
    }
    if (name == "billingTo") {
      if (billingShippingList.data.customerLineitems) {
        billingShippingList.data.customerLineitems.map((row) => {
          if (selectedValue === row.customerLineId) {
            setHeaderData((prev) => ({
              ...prev,
              billingTo: selectedValue,
              billingAddress: selectedName,
              billingState: row.state,
              billedToGstin: row.gstNo,
              billedToContactPerson: row.contactPerson,
              billedToContactNumber: row.contactNo,
            }));
            const UpdatedRec = {
              ...HeaderData,
              billingTo: selectedValue,
              billingAddress: selectedName,
              billingState: row.state,
              billedToGstin: row.gstNo,
              billedToContactPerson: row.contactPerson,
              billedToContactNumber: row.contactNo,
            };
            dispatch(getRegularInvoiceHeader(UpdatedRec));
          }
        });
      }
    }
    if (name == "shippingTo") {
      if (billingShippingList.data.customerLineitems) {
        billingShippingList.data.customerLineitems.map((row) => {
          if (selectedValue === row.customerLineId) {
            setHeaderData((prev) => ({
              ...prev,
              shippingTo: selectedValue,
              shippingAddress: selectedName,
              shippingState: row.state,
              shippedToContactNumber: row.contactNo,
              shippedToContactPerson: row.contactPerson,
              shippedToGstin: row.gstNo,
            }));
            const UpdatedRec = {
              ...HeaderData,
              shippingTo: selectedValue,
              shippingAddress: selectedName,
              shippingState: row.state,
              shippedToContactNumber: row.contactNo,
              shippedToContactPerson: row.contactPerson,
              shippedToGstin: row.gstNo,
            };
            dispatch(getRegularInvoiceHeader(UpdatedRec));
          }
        });
      }
    }
    if (name == "taxType") {
      setHeaderData((prev) => ({
        ...prev,
        taxType: selectedValue,
        taxTypeDesc: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        taxType: selectedValue,
        taxTypeDesc: selectedName,
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));

      let TaxPer = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: selectedValue,
        },
        "INVOICE"
      );
      handleCalculationCustomer(
        TaxPer,
        HeaderData.customerId,
        HeaderData.customerName,
        selectedValue,
        selectedName
      );
    }
    if (name == "gstInvoiceType") {
      if (selectedValue === "") {
        setHeaderData((prev) => ({
          ...prev,
          gstInvoiceType: "",
        }));
        const UpdatedRec = {
          ...HeaderData,
          gstInvoiceType: "",
        };
        dispatch(getRegularInvoiceHeader(UpdatedRec));

        return;
      }
      setHeaderData((prev) => ({
        ...prev,
        gstInvoiceType: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        gstInvoiceType: selectedName,
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));
    }
    if (name == "transporter") {
      let data = selectedName.split("^");
      setHeaderData((prev) => ({
        ...prev,
        transporterId: selectedValue,
        transporterName: data[0],
        transporterAddress: data[1],
        transporterStateCode: data[3],
        transporterStateName: data[2],
        transporterGst: data[4],
      }));
      const UpdatedRec = {
        ...HeaderData,
        transporterId: selectedValue,
        transporterName: data[0],
        transporterAddress: data[1],
        transporterStateCode: data[3],
        transporterStateName: data[2],
        transporterGst: data[4],
      };
      dispatch(getRegularInvoiceHeader(UpdatedRec));
    }
  };

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };
console.log(HeaderData)
  return (
    <>
      <div>
        <div className="consoleFormContainer">
          <h5>Regular Fill Details {HeaderData.createWithDirectInvoice}</h5>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {HeaderData.createWithDirectInvoice && (
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        {props.locationState.state == undefined ? (
                          <DynamicSelect
                            update={HeaderData.salesOrderId ? 1 : 0}
                            arrayOfData={SoDropdownVal}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={handlePropSelectChange}
                            isDropdownOpen={isDropdownOpen}
                            handleMenuOpen={handleMenuOpen}
                            selected={HeaderData.salesOrderId}
                            label="Sales Order"
                            name="salesOrderId"
                            required={
                              props.SalesOrderMandatory == true ? true : false
                            }
                          />
                        ) : (
                          <TextFieldNormal
                            label="Sales Order"
                            value={HeaderData.salesOrderNo}
                            onChange={onhandlechange("salesOrderNo")}
                            disabled={true}
                          />
                        )}
                      </div>
                    </Box>
                  )}
                  {HeaderData.createWithDirectInvoice && (
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        {props.locationState.state == undefined ? (
                          <DynamicSelect
                            update={HeaderData.deliveryOrderId ? 1 : 0}
                            arrayOfData={DoDropdownVal}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={handlePropSelectChange}
                            isDropdownOpen={isDropdownOpen}
                            handleMenuOpen={handleMenuOpen}
                            selected={HeaderData.deliveryOrderId}
                            label="Delivery Order Number"
                            name="deliveryOrderId"
                            required={props.DeliveryOrderMandatory}
                          />
                        ) : (
                          <TextFieldNormal
                            label="Delivery Order Number"
                            value={HeaderData.deliveryOrderNo}
                            onChange={onhandlechange("deliveryOrderNo")}
                            disabled={true}
                          />
                        )}
                      </div>
                    </Box>
                  )}
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.invoiceType ? 1 : 0}
                        arrayOfData={invoiceData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.invoiceType}
                        label="Invoice Type"
                        name="invoiceType"
                        isDisabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Invoice Date<span className="starcolor">*</span>
                          </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="invoiceDate"
                            inputFormat="dd-MM-yyyy"
                            id="invoiceDate"
                            minDate={
                              backDaysAllowable !== ""
                                ? backDaysAllowable
                                :HeaderData.invoiceDate
                            }
                            maxDate={
                              futureDaysAllowable !== ""
                                ? futureDaysAllowable
                                : HeaderData.invoiceDate
                            }
                            value={HeaderData.invoiceDate}
                            fullWidth
                            onChange={(e) =>
                              handleSelect_Date(e, "invoiceDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  {HeaderData.createWithDirectInvoice && (
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <div>
                          <div className="TextFieldBlock">
                            <label>Sales Order Date</label>
                          </div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              disabled={
                                props.SalesOrderMandatory == true &&
                                HeaderData.createWithDirectInvoice
                                  ? true
                                  : false
                              }
                              name="salesOrderDate"
                              inputFormat="dd-MM-yyyy"
                              id="salesOrderDate"
                              value={HeaderData.salesOrderDate}
                              fullWidth
                              onChange={(e) =>
                                handleSelect_Date(e, "salesOrderDate")
                              }
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="consoleDatePicker"
                                  style={{
                                    backgroundColor:
                                      props.SalesOrderMandatory == true &&
                                      HeaderData.createWithDirectInvoice
                                        ? "#ccd6db"
                                        : "",
                                  }}
                                >
                                  <input
                                    ref={inputRef}
                                    {...inputProps}
                                    style={{
                                      backgroundColor:
                                        props.SalesOrderMandatory == true &&
                                        HeaderData.createWithDirectInvoice
                                          ? "#ccd6db"
                                          : "",
                                    }}
                                  />
                                  {InputProps?.endAdornment}
                                </Box>
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </Box>
                  )}
                  {HeaderData.createWithDirectInvoice && (
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <div>
                          <div className="TextFieldBlock">
                            <label>
                              Delivery Order Date{" "}
                              {props.DeliveryOrderMandatory == true ? (
                                <span className="starcolor">*</span>
                              ) : (
                                ""
                              )}
                            </label>
                          </div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              disabled={
                                props.SalesOrderMandatory == true &&
                                HeaderData.createWithDirectInvoice
                                  ? true
                                  : false
                              }
                              name="deliveryOrderDate"
                              inputFormat="dd-MM-yyyy"
                              id="deliveryOrderDate"
                              value={HeaderData.deliveryOrderDate}
                              fullWidth
                              onChange={(e) =>
                                handleSelect_Date(e, "deliveryOrderDate")
                              }
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="consoleDatePicker"
                                  style={{
                                    backgroundColor:
                                      props.SalesOrderMandatory == true &&
                                      HeaderData.createWithDirectInvoice
                                        ? "#ccd6db"
                                        : "",
                                  }}
                                >
                                  <input
                                    ref={inputRef}
                                    {...inputProps}
                                    style={{
                                      backgroundColor:
                                        props.SalesOrderMandatory == true &&
                                        HeaderData.createWithDirectInvoice
                                          ? "#ccd6db"
                                          : "",
                                    }}
                                  />
                                  {InputProps?.endAdornment}
                                </Box>
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </Box>
                  )}
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.branchId ? 1 : 0}
                        arrayOfData={branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.branchId}
                        label="Branch"
                        name="branchId"
                        required
                        isDisabled={
                          props.SalesOrderMandatory == true &&
                          HeaderData.createWithDirectInvoice
                            ? true
                            : props.locationState.state !== undefined
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>

                  {HeaderData.createWithDirectInvoice && (
                    <Box gridColumn="span 3">
                      <TextFieldNormal
                        label="Quotation No"
                        value={HeaderData.quotationNo}
                        onChange={onhandlechange("quotationNumber")}
                        disabled={
                          props.SalesOrderMandatory == true &&
                          HeaderData.createWithDirectInvoice
                            ? true
                            : false
                        }
                      />
                    </Box>
                  )}
                  {HeaderData.createWithDirectInvoice && (
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <div>
                          <div className="TextFieldBlock">
                            <label>Quotation Date</label>
                          </div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              disabled={
                                props.SalesOrderMandatory == true &&
                                HeaderData.createWithDirectInvoice
                                  ? true
                                  : false
                              }
                              name="quotationDate"
                              inputFormat="dd-MM-yyyy"
                              id="quotationDate"
                              value={HeaderData.quotationDate}
                              fullWidth
                              onChange={(e) =>
                                handleSelect_Date(e, "quotationDate")
                              }
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="consoleDatePicker"
                                  style={{
                                    backgroundColor:
                                      props.SalesOrderMandatory == true &&
                                      HeaderData.createWithDirectInvoice
                                        ? "#ccd6db"
                                        : "",
                                  }}
                                >
                                  <input
                                    ref={inputRef}
                                    {...inputProps}
                                    style={{
                                      backgroundColor:
                                        props.SalesOrderMandatory == true &&
                                        HeaderData.createWithDirectInvoice
                                          ? "#ccd6db"
                                          : "",
                                    }}
                                  />
                                  {InputProps?.endAdornment}
                                </Box>
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </Box>
                  )}
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.brokerId ? 1 : 0}
                        arrayOfData={brokerData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.brokerId}
                        label="Broker"
                        name="brokerId"
                        ErrorOutlineIcon={true}
                        toolTipTitle={
                          "Broker or Consignee is mandetory Select At least one and Proceed"
                        }
                        isDisabled={
                          (HeaderData.salesOrderId === ""
                            ? true
                            : HeaderData.disableBrokerInvoice === true
                            ? true
                            : false) && HeaderData.createWithDirectInvoice
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.customerId ? 1 : 0}
                        arrayOfData={CustomerData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.customerId}
                        label="Customer/Consignee"
                        name="customerId"
                        required
                        isDisabled={
                          props.source==="WITHSALESORDER" &&isEmpty(HeaderData.salesOrderId) ? true:
                          HeaderData.customerResponse===true ? true:false
                        }
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.billingFrom ? 1 : 0}
                        arrayOfData={branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.billingFrom}
                        label="Bill From"
                        name="billingFrom"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.shippingFrom ? 1 : 0}
                        arrayOfData={branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.shippingFrom}
                        label="Ship From"
                        name="shippingFrom"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.billingTo ? 1 : 0}
                        arrayOfData={billingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.billingTo}
                        label="Billing To"
                        name="billingTo"
                        required
                        isDisabled={
                          props.SalesOrderMandatory == true &&
                          HeaderData.createWithDirectInvoice
                            ? true
                            : false || HeaderData.customerId === ""
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.shippingTo ? 1 : 0}
                        arrayOfData={shippingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.shippingTo}
                        label="Shipping To"
                        name="shippingTo"
                        required
                        isDisabled={
                          props.SalesOrderMandatory == true &&
                          HeaderData.createWithDirectInvoice
                            ? true
                            : false || HeaderData.customerId === ""
                            ? true
                            : false
                        }
                      />
                    </div>
                    {((!props.SalesOrderMandatory &&
                      !HeaderData.createWithDirectInvoice) ||
                      !isEmpty(HeaderData.customerId)) && (
                      <span
                        onClick={openPopup}
                        style={{
                          color: "red",
                          fontFamily: "Poppins, sans-serif",
                          fontWeight: 100,
                          fontSize: "15px",
                          marginTop: "5px",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                      >
                        Add a new Customer Branch Address
                      </span>
                    )}
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Billing State"
                      value={HeaderData.billingState}
                      onChange={onhandlechange("billingState")}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Shipping State"
                      value={HeaderData.shippingState}
                      onChange={onhandlechange("shippingState")}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.taxType ? 1 : 0}
                        arrayOfData={taxTypeData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.taxType}
                        label="Tax type"
                        name="taxType"
                        required
                        isDisabled={
                          props.SalesOrderMandatory == true &&
                          HeaderData.createWithDirectInvoice
                            ? true
                            : false || HeaderData.customerId === ""
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      {props.locationState.state == undefined ? (
                        <DynamicSelect
                          update={HeaderData.bankId ? 1 : 0}
                          arrayOfData={bankListData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={handlePropSelectChange}
                          isDropdownOpen={isDropdownOpen}
                          handleMenuOpen={handleMenuOpen}
                          selected={HeaderData.bankId}
                          name="bankId"
                          label="Bank Name"
                          required
                        />
                      ) : (
                        <TextFieldNormal
                          label="Bank Name"
                          value={HeaderData.bankName}
                          onChange={onhandlechange("bankName")}
                          disabled={true}
                        />
                      )}
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelect
                      update={HeaderData.transporterId ? 1 : 0}
                      arrayOfData={transporterData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.transporterId}
                      label="Transporter Name"
                      name="transporter"

                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      required={
                        isEmptyWithZero(HeaderData.brokerId) ? false : true
                      }
                      type="number"
                      label="Broker %"
                      value={HeaderData.brokerCommisionPercent}
                      onChange={onhandlechange("brokerCommisionPercent")}
                      disabled={isEmpty(HeaderData.brokerId) ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelectNew
                      update={HeaderData.deliveryTerms ? 1 : 0}
                      arrayOfData={DeliveryTermsData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.deliveryTerms}
                      name="deliveryTerms"
                      label="Delivery Terms"
                      isDisabled={
                        HeaderData.createWithDirectInvoice == true
                          ? true
                          : false
                      }
                    />
                  </Box>
                  {/* <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Vehicle No"
                      value={HeaderData.vehicleNo}
                      onChange={onhandlechange("vehicleNo")}
                      caps="ON"
 
                    />
                  </Box> */}
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="PO Number"
                      value={HeaderData.poNumber}
                      onChange={onhandlechange("poNumber")}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Customer Reference Number"
                      value={HeaderData.customerReferenceNumber}
                      onChange={onhandlechange("customerReferenceNumber")}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelectNew
                      update={HeaderData.gstInvoiceType ? 1 : 0}
                      arrayOfData={GstInvoiceTypeData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.gstInvoiceType}
                      label="Gst InvoiceType"
                      name="gstInvoiceType"
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <FormPopup
          isOpen={isPopupOpen}
          onClose={closePopup}
          customerData={billingShippingList}
          customerId={HeaderData.customerId}
        />
      </div>
    </>
  );
};
const DeliveryTermsData = [
  {
    value: 1,
    label: "Ex-Works",
    name: "Ex-Works",
  },
  {
    value: 2,
    label: "Delivery to Destination",
    name: "Delivery to Destination",
  },
];
const taxTypeData = [
  {
    value: 0,
    name: "Select...",
    label: "Select...",
  },
  {
    value: 1,
    name: "IGST",
    label: "IGST",
  },
  {
    value: 2,
    name: "CGST & SGST",
    label: "CGST & SGST",
  },
];
const GstInvoiceTypeData = [
  {
    value: "",
    name: "Select",
    label: "Select",
  },
  {
    value: 1,
    name: "B2B",
    label: "B2B",
  },
  {
    value: 2,
    name: "B2C",
    label: "B2C",
  },
  {
    value: 3,
    name: "Export",
    label: "Export",
  },
];

// const bankSelect = {
//   value: "",
//   name: "Select",
//   label: "Select",
// };

export default RegularFillDetails;
