import {
  AGENT_MASTER_LIST, AGENT_MASTER_LIST_SUCCESSFULL, CREATE_AGENT_MASTER_SUCCESSFULL, CREATE_AGENT_MASTER,
  UPDATE_AGENT_MASTER,
  UPDATE_AGENT_MASTER_SUCCESSFULL,
  API_ERRORS,
} from './actionTypes';

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  AgentMasterList: [],
  CreateAgentMaster: [],
  UpdateAgentMaster: [],
};

const AgentMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case AGENT_MASTER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AGENT_MASTER_LIST_SUCCESSFULL:
      state = {
        ...state,
        AgentMasterList: action.payload.response,
        loading: true,
      };
      break;
    case CREATE_AGENT_MASTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_AGENT_MASTER_SUCCESSFULL:
      state = {
        ...state,
        CreateAgentMaster: action.payload.response,
        loading: true,
      };
      break;
    case UPDATE_AGENT_MASTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_AGENT_MASTER_SUCCESSFULL:
      state = {
        ...state,
        UpdateAgentMaster: action.payload.response,
        loading: true,
      };
      break;
    case API_ERRORS:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
}

export default AgentMasterReducer;