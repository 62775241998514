import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
// import DynamicSelect from "../Dropdown/DynamicSelect";
import DynamicSelect from "../../components/Dropdown/Master/DynamicSelectMasters";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { leaveTypeSelection } from "../../store/HRMS/LeaveRequest/actions";
import { serverApi } from "../../helpers/Consts";
import TextFieldNormal from "../../components/TextField/TextFieldNormal";
import { getStatusList } from "../../store/Global/DropDownApis/actions";

class LeaveRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      leaveLedgerS: [],
      status: null,
      ebNo: "",
      leaveTypeId: "",
      Status: [],
    };
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "status") {
      this.setState({
        status: selectedValue,
      });
    }
    if (name === "Type") {
      this.setState({
        leaveTypeId: selectedValue,
      });
    }
  };
  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "EmpCode") {
      this.setState({
        ebNo: event,
      });
    }
  };
  handleSubmit = () => {
    const data = {
      companyId: localStorage.getItem("companyId"),
      ebNo: this.state.ebNo,
      leaveTypeId: this.state.leaveTypeId,
      status: this.state.status,
      userId: this.state.userDit.userId,
    };
    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.props.leaveTypeSelection(
      serverApi.LEAVE_TYPE_SELECTION_LIST + localStorage.getItem("companyId"),
      this.props.history
    );
    this.props.getStatusList(serverApi.GET_STATUS_LIST, this.props.history);
  }
  componentWillReceiveProps(props) {
    if (props.Leave_type_selection) {
      this.setState({
        leaveLedgerS: props.Leave_type_selection,
      });
    }
    if (props.statusList) {
      this.setState({
        Status: props.statusList.data,
      });
    }
  }
  handleMenuOpen=()=>{
    this.setState({
      isDropdownOpen:false
    })
  }

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen:true
    })
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.
    this.setState({
      isDropdownOpen:true
    })
  };

  render() {
    return (
      <>
        <Box>
          <Button onClick={this.handleClickOpen}>
            <Tooltip title="Filter">
              <FilterListOutlinedIcon />
            </Tooltip>
          </Button>
          <Dialog
            open={this.state.open}
            PaperProps={{
              sx: {
                position: " fixed",
                top: 0,
                right: 0,
                margin: 0,
                bottom: 0,
                maxHeight: "100vh",
              },
            }}
            keepMounted
            onClose={this.handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <Grid spacing={0}>
              <div className="consoleTextFieldBlock">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div className="filterBlockHeader">
                    <FilterListOutlinedIcon />
                    <span>Filter</span>
                  </div>
                </div>
              </div>
              <Grid item xs={12} className="filterBlockContainer">
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    {/* <DynamicSelectNew
                      // arrayOfData={this.state.leaveLedgerS.length !==0 ? this.state.leaveLedgerS :[]  }
                      arrayOfData={[]}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.leaveTypeId}
                      name="Type"
                      label="Leave Type"
                    /> */}
                    <DynamicSelect
                      arrayOfData={
                        this.state.leaveLedgerS
                          ? this.state.leaveLedgerS.map((item) => ({
                              label: item.label,
                              value: item.value,
                              name: item.name,
                            }))
                          : []
                      }
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.leaveTypeId}
                      name="Type"
                      label="Leave Type"
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <TextFieldNormal
                        label="Employee Code"
                        value={this.state.ebNo}
                        onChange={this.onhandlechange("EmpCode")}
                      />
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    {/* <DynamicSelectNew
                      arrayOfData={[]}
                      //   arrayOfData={this.state.Status_list && this.state.Status_list.length !==0  ? this.state.Status_list.map(item => ({
                      //     label: item.label,
                      //     value: item.value,
                      //     name: item.name
                      // })) : []}

                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.status}
                      name="status"
                      label="Status"
                    /> */}
                    <DynamicSelect
                      arrayOfData={
                        this.state.Status
                          ? this.state.Status.map((item) => ({
                              label: item.label,
                              value: item.value,
                              name: item.name,
                            }))
                          : ""
                      }
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.status}
                      name="status"
                      label="Status"
                    />
                  </div>
                </Box>

                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Search"}
                    className="greenButton"
                    handleClick={this.handleSubmit}
                    type="sumbit"
                  />
                </div>
              </Grid>
            </Grid>
          </Dialog>
        </Box>
      </>
    );
  }
}
const options = [
  { value: 0, name: "select...", label: "select..." },
  { value: 1, name: "OPEN", label: "OPEN" },
  { value: 3, name: "APPROVED", label: "APPROVED" },
  { value: 4, name: "REJECTED", label: "REJECTED" },
  { value: 6, name: "CANCELLED", label: "CANCELLED" },
  {
    value: 17,
    name: "PENDING APPROVAL LEVEL 2",
    label: "PENDING APPROVAL LEVEL 2",
  },
  {
    value: 18,
    name: "PENDING APPROVAL LEVEL 3",
    label: "PENDING APPROVAL LEVEL 3",
  },
  {
    value: 19,
    name: "PENDING APPROVAL LEVEL 4",
    label: "PENDING APPROVAL LEVEL 4",
  },
];
const mapStatetoProps = (state) => {
  const { Leave_type_selection } = state.LeaveRequestReducer;
  const { statusList } = state.DropDownListReducer;
  return { Leave_type_selection, statusList };
};

export default withRouter(
  connect(mapStatetoProps, {
    leaveTypeSelection,
    getStatusList,
  })(LeaveRequest)
);
