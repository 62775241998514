// import "./styles.css";
import { useEffect } from "react";
import { useState } from "react";
import DonutChart from "react-donut-chart";
const reactDonutChartBackgroundColor = [];
const reactDonutChartInnerRadius = 0.5;
const reactDonutChartSelectedOffset = 0.04;
const reactDonutChartHandleClick = (item, toggled) => {
};
let reactDonutChartStrokeColor = "#FFFFFF";

export default function App(props) {
  const [onRollEmployees, setOnRollEmployees] = useState([]);
  const [totalCount, setTotalCount] = useState();

  useEffect(() => {
    let total = 0;
    let onRollEmployeesList = props.onRollEmployees.map((item) => {
      let color = item.color;
      total = props.onRoll;
      setTotalCount(total);
      reactDonutChartBackgroundColor.push(color);
      var persent = 0
      if (total > 0) {
        persent =(item.departmentCount / total) * 100 ; 
      }
      return ({
        label: item.departmentName + ' - '+persent.toFixed(2) + "%",
        value: item.departmentCount,
        color: color,
      });
    });
    setOnRollEmployees(onRollEmployeesList);
  }, [props.onRollEmployees]);

  

  return (
    <div className="doughnutContainer">
      <div className="doughnutOne">
        <div className="totalAmount">{totalCount}</div>

        <DonutChart
          width={500}
          height={265}
          strokeColor={reactDonutChartStrokeColor}
          data={onRollEmployees}
          colors={reactDonutChartBackgroundColor}
          innerRadius={reactDonutChartInnerRadius}
          selectedOffset={reactDonutChartSelectedOffset}
          clickToggle={false}
          className="donutchart"
        />
      </div>
      <ul className="legend">
        {onRollEmployees.map((item, index) => (
          <li key={index}>
            <div
              style={{
                backgroundColor: item.color,
                width: "12px",
                height: "12px",
                display: "inline-block",
              }}
            ></div>
            <div>
              {item.label}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
