import {COSTCENTER_LIST,CREATE_COSTCENTER_MASTER,UPDATE_COSTCENTER_MASTER,
    COSTCENTER_LIST_SUCCESSFULL,CREATE_COSTCENTER_MASTER_SUCCESSFULL,UPDATE_COSTCENTER_MASTER_SUCCESSFULL,
    API_ERRORS} from './actionTypes';



export const getCostCenterList = (url,data,history) =>{
    return {
        type:COSTCENTER_LIST,
        payload:{url,data,history}
    }
}

export const CostCenterListSuccessfull = (data)=>{
    return {
        type:COSTCENTER_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createCostCenterMaster = (url, data, history) => {
    return {
        type: CREATE_COSTCENTER_MASTER,
        payload: { url, data, history }
    }
}

export const createCostCenterMasterSuccessfull = (data) => {
    return {
        type: CREATE_COSTCENTER_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const updateCostCenterMaster = (url, data, history) => {
    return {
        type: UPDATE_COSTCENTER_MASTER,
        payload: { url, data, history }
    }
}

export const updateCostCenterMasterSuccessfull = (data) => {
    return {
        type: UPDATE_COSTCENTER_MASTER_SUCCESSFULL,
        payload: data
    }
}

export const apiErrors = (error) => {
    return {
      type: API_ERRORS,
      payload: error,
    };
  };

