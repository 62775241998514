
import {
  API_ERRORS,
  BALANCE_QUANTITY,
  BALANCE_QUANTITY_SUCCESSFULL,
  CLEAR_DEBIT_NOTE_LINE_ITEM,
  CREATE_DEBIT_NOTE,
  CREATE_DEBIT_NOTE_SUCCESSFULL,
  DEBIT_CREDIT_LIST,
  DEBIT_CREDIT_LIST_SUCCESSFULL,
  DEBIT_NOTE_LINE_ITEM,
  GET_DEBIT_NOTE_BRANCH,
  GET_DEBIT_NOTE_BRANCH_SUCCESSFULL,
  GET_DEBIT_NOTE_BY_ID,
  GET_DEBIT_PROJECTS,
  GET_DEBIT_PROJECTS_SUCCESSFULL,
  GET_SR_NO,
  GET_SR_NO_SUCCESSFULL,
  GET_SUPPLIER_FOR_JUTE_BY_ID,
  GET_SUPPLIER_FOR_JUTE_BY_ID_SUCCESSFULL,
  SR_NO_VIEW_BY_ID,
  SR_NO_VIEW_BY_ID_CLEAR,
  SR_NO_VIEW_BY_ID_SUCCESSFULL,
  GET_DEBIT_NOTE_BY_ID_SUCCESSFULL,
  DEBIT_NOTE_HEADER,
  CLEAR_DEBIT_NOTE_HEADER,
  UPDATE_DEBIT_NOTE,
  UPDATE_DEBIT_NOTE_SUCCESSFULL,
  UPLOAD_DEBIT_FILES,
  UPLOAD_DEBIT_FILES_SUCCESSFULL,
  GET_DEBIT_FILES,
  GET_DEBIT_FILES_SUCCESSFULL,
  DELETE_DEBIT_FILES_SUCCESSFULL,
  DELETE_DEBIT_FILES
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  Debit_Credit_Data: [],
  error_msg: null,
  SupplierForJuteById: [],
  GetSR_No: [],
  SrNoViewByID: [],
  getDebitBranch: [],
  getDebitProjects: [],
  createDebitNote: [],
  balance_quantity: [],
  DebitNoteById: [],
  UpdateDebitNote: [],
  debitNoteHeaders: {
    TotalAmount: "",
    TotalIGST: "",
    TotalCGST: "",
    TotalSGST: "",
    TotalValue: ""
  },
  UploadDebitFilesData: [],
  getDebitFilesData: [],
  delete_files: [],
  debitNoteLineItems: [
    {
      id: 1,
      itemCode: "",
      itemGroup: "",
      itemGroupId: "",
      itemId: "",
      itemName: "",
      approvedQty: "",
      debitnoteValue: "",
      warehouseno: "",
      inwardDetailId: "",
      quantity: "",
      rate: "",
      srRate:"",
      discount: "",
      reason: "",
      amount: "",
      ctaxAmount: null,
      staxAmount: null,
      itaxAmount: null,
      itaxPer: null,
      ctaxPer: null,
      staxPer: null,
      debitnoteQty: "",
      uom: "",
      isActive: 1,
      balanceQuantity: "",
      adjustmentDetailId: "",
      adjustmentId: null,
      isActive: "1",
      taxPercentage:"",
      taxAmount:"",
      debitnoteType:"",
      debitNoteTypeDesc:"",
      qtyDisable:true,
      rateDisable:true,
      discountMode:"",
    }
  ]
};

const DebitCreditReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEBIT_CREDIT_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case DEBIT_CREDIT_LIST_SUCCESSFULL:
      state = {
        ...state,
        Debit_Credit_Data: action.payload.response,
        loading: false,
      };
      break;
    case GET_SUPPLIER_FOR_JUTE_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_SUPPLIER_FOR_JUTE_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        SupplierForJuteById: action.payload.response,
        loading: false,
      };
      break;
    case GET_SR_NO:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_SR_NO_SUCCESSFULL:
      state = {
        ...state,
        GetSR_No: action.payload.response,
        loading: false,
      };
      break;
    case SR_NO_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SR_NO_VIEW_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        SrNoViewByID: action.payload.response,
        loading: false,
      };
      break;
    case SR_NO_VIEW_BY_ID_CLEAR:
      state = {
        ...state,
        SrNoViewByID: action.payload,
        loading: false,
      };
      break;
    case GET_DEBIT_NOTE_BRANCH:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_DEBIT_NOTE_BRANCH_SUCCESSFULL:
      state = {
        ...state,
        getDebitBranch: action.payload.response,
        loading: false,
      };
      break;
    case GET_DEBIT_PROJECTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_DEBIT_PROJECTS_SUCCESSFULL:
      state = {
        ...state,
        getDebitProjects: action.payload.response,
        loading: false,
      };
      break;
    case DEBIT_NOTE_LINE_ITEM:
      state = {
        ...state,
        debitNoteLineItems: action.payload,
        loading: true,
      };
      break;
    case CLEAR_DEBIT_NOTE_LINE_ITEM:
      state = {
        ...state,
        debitNoteLineItems:[
          {
            id: 1,
            itemCode: "",
            itemGroup: "",
            itemGroupId: "",
            itemId: "",
            itemName: "",
            approvedQty: "",
            debitnoteValue: "",
            warehouseno: "",
            inwardDetailId: "",
            quantity: "",
            rate: "",
            srRate:"",
            discount: "",
            reason: "",
            amount: "",
            ctaxAmount: null,
            staxAmount: null,
            itaxAmount: null,
            itaxPer: null,
            ctaxPer: null,
            staxPer: null,
            debitnoteQty: "",
            uom: "",
            isActive: 1,
            balanceQuantity: "",
            adjustmentDetailId: "",
            adjustmentId: null,
            isActive: "1",
            taxPercentage:"",
            taxAmount:"",
            debitnoteType:"",
            debitNoteTypeDesc:"",
            qtyDisable:true,
            rateDisable:true,
            discountMode:"",
          }
        ],
        loading: true,
      };
    case DEBIT_NOTE_HEADER:
      state = {
        ...state,
        debitNoteHeaders: action.payload,
        loading: true,
      };
      break;
    case CLEAR_DEBIT_NOTE_HEADER:
      state = {
        ...state,
        debitNoteHeaders: action.payload,
        loading: true,
      };
      break;
    case CREATE_DEBIT_NOTE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_DEBIT_NOTE_SUCCESSFULL:
      state = {
        ...state,
        createDebitNote: action.payload.response,
        loading: false,
      };
    case UPDATE_DEBIT_NOTE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_DEBIT_NOTE_SUCCESSFULL:
      state = {
        ...state,
        UpdateDebitNote: action.payload.response,
        loading: false,
      };
    case BALANCE_QUANTITY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case BALANCE_QUANTITY_SUCCESSFULL:
      state = {
        ...state,
        balance_quantity: action.payload.response,
        loading: false,
      };
    case GET_DEBIT_NOTE_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_DEBIT_NOTE_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        DebitNoteById: action.payload.response,
        loading: false,
      };
    case UPLOAD_DEBIT_FILES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPLOAD_DEBIT_FILES_SUCCESSFULL:
      state = {
        ...state,
        UploadDebitFilesData: action.payload.response,
        loading: false,
      };
    case GET_DEBIT_FILES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_DEBIT_FILES_SUCCESSFULL:
      state = {
        ...state,
        getDebitFilesData: action.payload.response,
        loading: false,
      };
    case DELETE_DEBIT_FILES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_DEBIT_FILES_SUCCESSFULL:
      state = {
        ...state,
        delete_files: action.payload.response,
        loading: false,
      };

    case API_ERRORS:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default DebitCreditReducer;
