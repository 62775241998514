import React, { Component } from "react";
import { Box, Grid, Button, IconButton } from "@mui/material";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import DynamicSelect from "../../../Pages/StoreModule/MaterialRequest/DynamicSelect";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi, serverVars } from "../../../helpers/Consts";
import { getFileDownload } from "../../../helpers/server.js";
import {
  getPendingStockPoandReceipt,
  getPendingStock,
  actions,
  logs,
  getFiles,
  getFilesSuccessfull,
  IndentLineItemsProps,
  validateItem,
  getItemsListforIndent,
  getItemGroupListforindent,
  getItemDetails,
  getItemsListByItemGroupId
} from "../../../store/Purchase/Indent/actions";
import TextArea from "../../../components/TextField/TextArea";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "../../../components/Buttons/Buttons";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import {
  postUploadFileList,
  deleteFile,
} from "../../../store/MyProfile/UploadFile/actions";
import {
  getItemGroupList,
  getDepartmentList,
  getItemsListForIndent,
} from "../../../store/Global/DropDownApis/actions";

import DeleteIcons from "../../../assets/images/picEditIcon.png";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import swal from "sweetalert";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import ReactTooltip from "react-tooltip";
import { QtyCalculation } from "../../../Utilities/QtyCalculation";

class IndentLineItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCount: 0,
      indentLineItems: [],
      itemsList: [],
      totalAmt: 0,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      indentId: "",
      internalRemarks: this.props.indentFillDetails.internalRemarks,
      remarks: this.props.indentFillDetails.remarks,
      submitRemarks: "",
      indentWithMultiDepartments: "",
      Logs: [],
      files_List: [],
      uploadedFiles: [],
      ItemGroupList: [],
      DepartmentList: [],
      fileName: "",
      fileType: "",
      lineitemId: 1,
      addlineItemId: 0,
      uploadFile: false,
      seeLogs: false,
      validateItemMessage: "",
      isDropdownOpen: false,
      deptDisplayTxtField: {},
      deptDisplayDropField: {},
      locationState: "",
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    if (
      this.props.indentLineItems &&
      this.props.indentLineItems !== undefined
    ) {
      this.setState({
        indentLineItems: this.props.indentLineItems,
      });
    } else {
      let IndentLineItem = [
        {
          id: 1,
          qty: "",
          uomCode: "",
          qohCompany: "",
          qohBranch: 0,
          qohProject: 0,
          rate: "",
          rateSource: 1,
          isActive: 1,
          remarks: "",
          make: "",
          itemGroup: this.props.indentFillDetails.itemGroup,
          itemGroupValue: this.props.indentFillDetails.itemGroupValue,
          departmentName: this.props.indentFillDetails.departmentName,
          departmentId: this.props.indentFillDetails.departmentId,
          itemId: "",
          lastPurchaseDate: "",
          lastPurchaseRate: 0,
          installationRate: null,
          qtyPo: "",
          amount: 0,
          min: 0,
          max: 0,
          minAllowedQty: 0,
          maxAllowedQty: 0,
          itemList: [],
          departmentList: [],
          itemGroupList: [],
          reOrderQty: "",
          departmentText: "", //this field textbox department name
          itemName: "", //
          itemText: "", // this field textbox item
          itemGroupText: "", // this field textbox item group
          isEditMode: true,
          isUpdate: true,
        },
      ];
      this.props.IndentLineItemsProps(IndentLineItem);
    }
    if (this.props.locationState.state) {
      let id = this.props.locationState.state.rowData.id;
      this.props.getFiles(
        serverApi.GET_FILES +
          "10/" +
          id +
          "/" +
          localStorage.getItem("companyId"),
        this.props.history
      );
    }
    if (this.props.Upload_File_List) {
      if (this.props.locationState.state) {
        let id = this.props.locationState.state.rowData.id;
        this.props.getFiles(
          serverApi.GET_FILES +
            "10/" +
            id +
            "/" +
            localStorage.getItem("companyId"),
          this.props.history
        );
      }
    }
    if (this.state.userDit) {
      this.state.userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              if (item.indentWithMultiDepartments !== undefined) {
                this.setState({
                  indentWithMultiDepartments: item.indentWithMultiDepartments,
                });
              }
            });
          }
      });
    }
  }

  componentWillReceiveProps(props) {
    if (props.pendingStock) {
      if (props.pendingStock.data !== null) {
        let indentLineItemsObj = this.state.indentLineItems.filter(
          (rowItem) => {
            if (rowItem.id !== 0 && props.pendingStock.id == rowItem.itemId) {
              rowItem.qohBranch = props.pendingStock.data;
            }
            return rowItem;
          }
        );
        this.setState({
          indentLineItems: indentLineItemsObj,
        });
      }
    }
    if (props.pendingStockPoandReceipt) {
      if (props.pendingStockPoandReceipt.data !== null) {
        let indentLineItemsObj = this.state.indentLineItems.filter(
          (rowItem) => {
            if (props.pendingStockPoandReceipt.id == rowItem.itemId) {
              rowItem.qohCompany = props.pendingStockPoandReceipt.data;
            }
            return rowItem;
          }
        );
        this.setState({
          indentLineItems: indentLineItemsObj,
        });
      }
    }
    if (props.indentLineItems) {
      this.setState({
        indentLineItems: props.indentLineItems,
      });
    }
    if (props.location.state) {
      let id = props.location.state.rowData.id;
      this.setState({
        indentId: id,
      });
    }

    if (props.departmentList) {
      if (props.departmentList !== undefined) {
        if (props.departmentList.data !== undefined) {
          let departmentList = props.departmentList.data.filter((item) => {
            if (item.value !== 0 && item.value !== "0") {
              return item;
            }
          });
          this.setState({
            DepartmentList: departmentList,
          });
        }
      }
    }
    if (props.ItemGroupList) {
      if (props.ItemGroupList !== undefined) {
        if (props.ItemGroupList.data !== undefined) {
          let ItemGroupList = props.ItemGroupList.data.filter((item) => {
            if (item.value !== 0 && item.value !== "0") {
              return item;
            }
          });
          this.setState({
            ItemGroupList: ItemGroupList,
          });
        }
      }
    }

    if (props.itemsListForIndent) {
      let list = [];
      props.itemsListForIndent.map((prop) => {
        list.push({
          label: prop.label,
          name: prop.name,
          value: prop.value,
          variant: prop.itemCode,
        });
      });
      let itemsList = list.filter((item) => {
        if (item.value !== 0 && item.value !== "0") {
          return item;
        }
      });
      this.setState({
        itemsList: itemsList,
      });
    }

    if (props.logs_List) {
      this.setState({
        Logs: props.logs_List.data,
      });
    }
    if (this.state.seeLogs === false) {
      if (props.files_List) {
        if (props.files_List.data) {
          let filesList = props.files_List.data;
          this.setState({
            files_List: filesList.data,
          });
        }
      }
    }
    if (this.state.uploadFile === true) {
      if (this.state.fileName !== "") {
        if (props.Upload_File_List) {
          var Filedata = props.Upload_File_List.data;
          if (props.Upload_File_List.status) {
            this.setState({
              uploadFile: false,
            });
            if (this.state.files_List !== undefined) {
              var arr = this.state.files_List;
              Filedata = {
                fileUploadId: Filedata.id,
                fileName: this.state.fileName,
                fileExtension: this.state.fileType,
                retrievalPath: Filedata.fileUrl,
                sourceMenu: 2,
              };
              arr.splice(arr.length, 0, Filedata);
              this.setState({
                files_List: arr,
              });
            }
          }
        }
      }
    }
  }
  // function for input onchange
  handleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    this.setState({ [key]: event });
    let mname = key.split("_");
    if (mname[0] === "remarks") {
      let indentLineItemsObj = this.state.indentLineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.remarks = value;
        }
        return rowItem;
      });
      this.setState({
        indentLineItems: indentLineItemsObj,
      });
      this.props.IndentLineItemsProps(indentLineItemsObj);
    }

    if (mname[0] === "make") {
      let indentLineItemsObj = this.state.indentLineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.make = value;
        }
        return rowItem;
      });
      this.setState({
        indentLineItems: indentLineItemsObj,
      });
      this.props.IndentLineItemsProps(indentLineItemsObj);
    }

    if (mname[0] === "itemQty") {
      if (Number(value) >= 0) {
        if (!value.match(/^\d{1,3}\.\d{4}$/)) {
          let indentLineItemsObj = this.state.indentLineItems.filter(
            (rowItem) => {
              if (row.id === rowItem.id) {
                // swal(typeof this.props.indentFillDetails.categoryTypeValue);
                if (
                  this.props.indentFillDetails.categoryTypeValue !== "7" &&
                  this.props.indentFillDetails.categoryTypeValue !== "4"
                ) {
                  //  let resultqty = QtyCalculation({type,remainqty,indentqty,qty,minqty,maxqty,branchqty,centralqty,updatetype,poqty,reOrderQty})
                  if (
                    row.min !== 0 &&
                    row.max !== 0 &&
                    row.min !== "0.0" &&
                    row.max !== "0.0" &&
                    row.maxAllowedQty !== 0 &&
                    row.minAllowedQty !== 0 &&
                    Number(row.min) !== 0 &&
                    Number(row.max) !== 0
                  ) {
                    let QuantityValue =
                      Number(row.max) -
                      Number(row.qohBranch) -
                      Number(row.qtyPo) -
                      0;
                    if (Number(QuantityValue) > Number(row.reOrderQty)) {
                      if (Number(value) > Number(QuantityValue)) {
                        //   swal(
                        //     "You Can't Enter the Quantity more than General Quantity"
                        //   );
                        row.qty = value;
                        row.amount = row.rate * row.qty;
                      } else {
                        row.qty = value;
                        row.amount = row.rate * row.qty;
                      }
                    } else if (Number(QuantityValue) < Number(row.reOrderQty)) {
                      if (Number(value) > Number(row.reOrderQty)) {
                        // swal(
                        //   "You Can't Enter the Quantity more than Re Order Quantity"
                        // );
                        row.qty = value;
                        row.amount = row.rate * row.qty;
                      } else {
                        row.qty = value;
                        row.amount = row.rate * row.qty;
                      }
                    } else {
                      row.qty = value;
                      row.amount = row.rate * row.qty;
                    }
                  } else {
                    row.qty = value;
                    row.amount = row.rate * row.qty;
                  }
                } else {
                  row.qty = value;
                  row.amount = row.rate * row.qty;
                }
              }
              return rowItem;
            }
          );

          this.setState({
            indentLineItems: indentLineItemsObj,
          });
          this.props.IndentLineItemsProps(indentLineItemsObj);
        } else {
          swal("Can't Enter more than 3 Decimals!");
        }
      }
    }
  };
  handleBlur = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    this.setState({ [key]: event });
    let mname = key.split("_");
    if (mname[0] === "itemQty") {
      if (Number(value) >= 0) {
        if (!value.match(/^\d{1,3}\.\d{4}$/)) {
          let indentLineItemsObj = this.state.indentLineItems.filter(
            (rowItem) => {
              if (row.id === rowItem.id) {
                // swal(typeof this.props.indentFillDetails.categoryTypeValue);
                if (
                  this.props.indentFillDetails.categoryTypeValue !== "7" &&
                  this.props.indentFillDetails.categoryTypeValue !== "4"
                ) {
                  let minqty = row.min === undefined ? 0 : Number(row.min);
                  let maxqty = row.max === undefined ? 0 : Number(row.max);
                  let minAllowedQty =
                    row.minAllowedQty === undefined
                      ? 0
                      : Number(row.minAllowedQty);
                  let maxAllowedQty =
                    row.maxAllowedQty === undefined
                      ? 0
                      : Number(row.maxAllowedQty);
                  let qty = Number(value);
                  let reOrderQty =
                    row.reOrderqty === undefined ? 0 : Number(row.reOrderqty);
                  let branchqty = Number(row.qohBranch);
                  let centralqty = Number(row.qohCompany);
                  let poqty = Number(row.qty);
                  let updatetype =
                    this.props.location.state !== undefined ? 0 : 1;
                  let type = "CASE1";
                  let resultQty = QtyCalculation({
                    type,
                    minqty,
                    maxqty,
                    minAllowedQty,
                    maxAllowedQty,
                    qty,
                    reOrderQty,
                    branchqty,
                    centralqty,
                    updatetype,
                    poqty,
                  });
                  if (resultQty.error === true) {
                    swal(resultQty.message);
                    row.qty = resultQty.value;
                  }
                } else {
                  row.qty = value;
                  row.amount = row.rate * row.qty;
                }
              }
              return rowItem;
            }
          );

          this.setState({
            indentLineItems: indentLineItemsObj,
          });
          this.props.IndentLineItemsProps(indentLineItemsObj);
        } else {
          swal("Can't Enter more than 3 Decimals!");
        }
      }
    }
  };
  handleKeyPress = (event) => {
    // Prevent 'e' and '-' characters
    if (event.key === "e" || event.key === "-") {
      event.preventDefault();
    }
  };

  handleChangetextAreas = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "internalRemarks") {
      this.props.indentFillDetails.internalRemarks = event;
      this.setState({
        internalRemarks: event,
      });
    }
    if (key === "remarks") {
      this.props.indentFillDetails.remarks = event;
      this.setState({
        remarks: event,
      });
    }
  };
  handleChangeSubmit = (key) => (event) => {
    this.setState({ [key]: event });
    this.setState({
      submitRemarks: event,
    });
  };

  // handleValidateItem = (res, dropdowndata) => {
  //   if (res) {
  //     if (res.data) {
  //       if (res.data.message) {
  //         if (res.data.message !== "Indent Not Found") {
  //           swal(res.data.message, { icon: "error" });
  //           dropdowndata.row.itemId = "";
  //           dropdowndata.row.rate = 0;
  //           dropdowndata.row.qty = 0;
  //           dropdowndata.row.uomCode = "";
  //         } else {
  //           if (dropdowndata.row.id === dropdowndata.selectedRow.id) {
  //             let value = dropdowndata.selectedName.split("^");
  //             let resVal = value[3];
  //             let uom = value[1];
  //             dropdowndata.row.rate = resVal === "null" ? 0 : parseInt(resVal);
  //             dropdowndata.row.uomCode = uom;
  //             dropdowndata.row.itemId = dropdowndata.selectedValue;
  //             dropdowndata.row.itemName = value[0];
  //             dropdowndata.row.itemId = dropdowndata.selectedValue;
  //             dropdowndata.row.itemCode = dropdowndata.selectedItemCode;
  //             dropdowndata.row.isActive = 1;
  //             dropdowndata.row.min = value[8];
  //             dropdowndata.row.max = value[6];
  //             dropdowndata.row.minAllowedQty = value[15];
  //             dropdowndata.row.maxAllowedQty = value[14];
  //             dropdowndata.row.reOrderQty = value[12];
  //           }
  //           this.setState({
  //             rowCount: this.state.rowCount + 1,
  //           });

  //           const data1 = {
  //             companyId: localStorage.getItem("companyId"),
  //             itemId: dropdowndata.selectedValue,
  //             branchId: this.props.indentFillDetails.branchValue,
  //           };
  //           this.props.getPendingStock(
  //             serverApi.GET_PENDING_STOCK_BRANCH_WISE,
  //             data1,
  //             this.props.history,
  //             dropdowndata.selectedRow.itemId
  //           );
  //           const data2 = {
  //             companyId: localStorage.getItem("companyId"),
  //             itemId: dropdowndata.selectedRow.itemId,
  //           };
  //           this.props.getPendingStockPoandReceipt(
  //             serverApi.GET_CENTRAL_STOCK,
  //             data2,
  //             this.props.history,
  //             dropdowndata.selectedRow.itemId
  //           );
  //           if (this.props.indentWithMultiDepartments === "2") {
  //             dropdowndata.row.itemGroup =
  //               this.props.indentFillDetails.itemGroup;
  //             dropdowndata.row.itemGroupValue =
  //               this.props.indentFillDetails.itemGroupValue;
  //             dropdowndata.row.departmentName =
  //               this.props.indentFillDetails.departmentName;
  //             dropdowndata.row.departmentId =
  //               this.props.indentFillDetails.departmentId;
  //           }
  //         }
  //         return dropdowndata.row;
  //       }
  //     }
  //   }
  // };

  // function for dropdown
  handleChangedropdown = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    selectedItemCode
  ) => {
    this.setState({
      isDropdownOpen: false,
    });
    var lastRow =
      this.state.indentLineItems[this.state.indentLineItems.length - 1];
    if (name === "item") {
      this.displayField(selectedRow, "item_code", 2);
      var newline = 1;
      let indentLineItems = this.state.indentLineItems.filter(
        (item) => item.isActive !== 0
      );
      //this code is to check the item duplication.......
      if (selectedRow.itemId !== selectedValue) {
        var check = !!indentLineItems.find(
          ({ itemId }) => Number(itemId) === Number(selectedValue)
        );
      }
      if (!check) {
        let indentLineItemsObj = this.state.indentLineItems.filter((row) => {
          // Itemdescription^UOM^centralstock^srRate^HSNCODE^taxId^MAX^MAXVALUE^MIN^WEIGHT^bperbag^prrate
          if (row.id === selectedRow.id) {
            let value = selectedName.split("^");
            // this will execute for open indent............
            // the below validation is for the open indent............
            if (this.props.location.pathname === "/create_openIndent") {
              if (
                value[6] === "0.0" ||
                value[8] === "0.0" ||
                value[6] === 0.0 ||
                value[8] === 0.0
              ) {
                swal(
                  `The MinMax for the selected item (${value[0]} and ${selectedItemCode}) is not entered. Please enter the same in the Item Master`
                );
                row.itemId = "";
              } else {
                const data = {
                  itemGroupId: selectedRow.itemGroupId,
                  companyId: localStorage.getItem("companyId"),
                  itemId: selectedValue,
                  branchId: this.props.indentFillDetails.branchValue,
                };
                this.props.getItemDetails(
                  serverApi.GET_ITEMDETAILS_BYITEM_ITEMGRP_COMPANYID,
                  data,
                  selectedValue,
                  this.props.indentFillDetails.branchValue,
                  selectedItemCode,
                  this.props.history
                );
                row.itemName = selectedName;
                row.itemId = selectedValue;
                row.itemCode = selectedItemCode;
                row.isActive = 1;
                // const Changedropdowndata = {
                //   selectedValue: selectedValue,
                //   selectedName: selectedName,
                //   name: name,
                //   selectedRow: selectedRow,
                //   selectedItemCode: selectedItemCode,
                //   row: row,
                // };
                // row = this.props.validateItem(
                //   serverApi.VALIDATE_ITEM_OI +
                //     selectedValue +
                //     "/" +
                //     localStorage.getItem("companyId") +
                //     "/" +
                //     localStorage.getItem("acadamicYear") +
                //     "/" +
                //     this.props.indentFillDetails.branchValue,
                //   this.handleValidateItem,
                //   Changedropdowndata
                // ); ///calling the Item validation API with callback function(handleValidateItem) and setting item values accordingly in callback function(handleValidateItem).......
              }
              return row;
            }
            // this will execute for regular indent............
            else {
              if (row.id === selectedRow.id) {
                if (
                  this.props.indentFillDetails.categoryTypeValue === "7" ||
                  this.props.indentFillDetails.categoryTypeValue === "4"
                ) {
                  const Changedropdowndata = {
                    selectedValue: selectedValue,
                    selectedName: selectedName,
                    name: name,
                    selectedRow: selectedRow,
                    selectedItemCode: selectedItemCode,
                    row: row,
                  };
                  row = this.props.validateItem(
                    serverApi.VALIDATE_ITEM_OI +
                      selectedValue +
                      "/" +
                      localStorage.getItem("companyId") +
                      "/" +
                      localStorage.getItem("acadamicYear") +
                      "/" +
                      this.props.indentFillDetails.branchValue,
                    this.handleValidateItem,
                    Changedropdowndata
                  ); ///calling the Item validation API with callback function(handleValidateItem) and setting item values accordingly in callback function(handleValidateItem).......
                }
                let value = selectedName.split("^");
                let resVal = value[3];
                let uom = value[1];
                row.rate = resVal === "null" ? 0 : parseInt(resVal);
                row.uomCode = uom;
                row.itemId = selectedValue;
                row.itemName = value[0];
                row.itemId = selectedValue;
                row.itemCode = selectedItemCode;
                row.isActive = 1;
                row.min = value[8];
                row.max = value[6];
                row.minAllowedQty = value[15];
                row.maxAllowedQty = value[14];
                row.reOrderQty = value[12];
                row.remarks = "";
                if (this.props.location.pathname === "/create_openIndent") {
                  row.qty = 0;
                } else {
                  row.qty = row.min !== 0 && row.min !== 0 ? 0 : row.qty;
                }
              }
              this.setState({
                rowCount: this.state.rowCount + 1,
              });

              const data1 = {
                companyId: localStorage.getItem("companyId"),
                itemId: selectedValue,
                branchId: this.props.indentFillDetails.branchValue,
              };
              this.props.getPendingStock(
                serverApi.GET_PENDING_STOCK_BRANCH_WISE,
                data1,
                this.props.history,
                selectedValue
              );
              if (this.props.indentWithMultiDepartments === "2") {
                row.itemGroup = this.props.indentFillDetails.itemGroup;
                row.itemGroupValue =
                  this.props.indentFillDetails.itemGroupValue;
                row.departmentName =
                  this.props.indentFillDetails.departmentName;
                row.departmentId = this.props.indentFillDetails.departmentId;
              }
            }
          }
          return row;
        });
        newline = Number(selectedRow.id);
        this.setState({
          addlineItemId: newline,
        });

        this.setState({
          indentLineItems: indentLineItemsObj,
        });
        this.props.IndentLineItemsProps(indentLineItemsObj);
      }
      if (check) {
        swal("You can't create the record with the duplicate item");
        let indentLineItemsObj = this.state.indentLineItems.filter((row) => {
          // Itemdescription^UOM^centralstock^srRate^HSNCODE^taxId^MAX^MAXVALUE^MIN^WEIGHT^bperbag^prrate
          if (row.id === selectedRow.id) {
            row.itemId = "";
            row.qty = 0;
            row.uomCode = "";
            row.rate = 0;
          }
          return row;
        });

        this.setState({
          indentLineItems: indentLineItemsObj,
        });
        this.props.IndentLineItemsProps(indentLineItemsObj);
      } else {
        if (selectedRow === lastRow) {
          if (this.props.indentWithMultiDepartments === "2") {
            let IndentLineItem = this.state.indentLineItems;
            let obj = {
              id: newline + 1,
              qty: "",
              uomCode: "",
              qohCompany: "",
              qohBranch: 0,
              qohProject: 0,
              rate: 0,
              rateSource: 1,
              remarks: "",
              itemGroup: this.props.indentFillDetails.itemGroup,
              itemGroupValue: this.props.indentFillDetails.itemGroupValue,
              departmentName: this.props.indentFillDetails.departmentName,
              departmentId: this.props.indentFillDetails.departmentId,
              isActive: 1,
              itemId: "",
              itemCode: "",
              lastPurchaseDate: "",
              lastPurchaseRate: 0,
              installationRate: null,
              qtyPo: "",
              amount: 0,
              status: 1,
              min: 0,
              max: 0,
              minAllowedQty: 0,
              maxAllowedQty: 0,
              itemList: [],
              reOrderQty: 0,
              departmentList: [],
              itemGroupList: [],

              departmentText: "", //this field textbox department name
              itemName: "", //
              itemText: "", // this field textbox item
              itemGroupText: "", // this field textbox item group
            };
            IndentLineItem.push(obj);
            this.setState({
              indentLineItems: IndentLineItem,
            });
            this.props.IndentLineItemsProps(IndentLineItem);
          } else {
            let indentLine_Items = this.state.indentLineItems;
            let obj = {
              id: newline + 1,
              qty: "",
              uomCode: "",
              qohCompany: "",
              qohBranch: 0,
              qohProject: 0,
              rate: 0,
              rateSource: 1,
              remarks: "",
              make: "",
              itemGroup: "",
              itemGroupValue: "",
              departmentName: "",
              departmentId: "",
              isActive: 1,
              itemId: "",
              itemCode: "",
              lastPurchaseDate: "",
              lastPurchaseRate: 0,
              installationRate: null,
              qtyPo: "",
              amount: 0,
              status: 1,
              min: 0,
              max: 0,
              minAllowedQty: 0,
              maxAllowedQty: 0,
              itemList: [],
              reOrderQty: 0,
              departmentList: [],
              itemGroupList: [],
              departmentText: "", //this field textbox department name
              itemName: "", //
              itemText: "", // this field textbox item
              itemGroupText: "", // this field textbox item group
              isEditMode: true,
            };
            indentLine_Items.push(obj);
            this.setState({
              indentLineItems: indentLine_Items,
            });
            this.props.IndentLineItemsProps(indentLine_Items);
          }
        }
        const data1 = {
          companyId: localStorage.getItem("companyId"),
          itemId: selectedValue,
        };
        this.props.getPendingStockPoandReceipt(
          serverApi.GET_CENTRAL_STOCK,
          data1,
          this.props.history,
          selectedValue
        );
      }
    }

    ///for department
    if (name === "department") {
      this.displayField(selectedRow, name, 2);
      var newLine = 1;
      let indentLineItemsObj = this.state.indentLineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          row.departmentId = selectedValue;
          row.departmentName = selectedName;
          row.itemGroup = "";
          row.itemGroupValue = "";
          row.itemGroupId = "";
          row.itemGroupList = [];
          row.itemList = [];
          row.itemId = "";
          row.itemName = "";
          row.rate = "";
          row.qty = 0;
          row.remarks = "";
          row.make = "";
          row.uomCode = "";
          this.props.getItemGroupListforindent(
            serverApi.GET_ITEMGROUP_LIST +
              selectedValue +
              "/" +
              localStorage.getItem("companyId") +
              "/getAllItemGroupsByDepartmentId/" +
              this.state.userDit.cipher,
            this.props.history,
            this.getitemListByitemgrpId,
            selectedValue
          );
        }
        return row;
      });
      newLine = Number(selectedRow.id);
      this.setState({
        addlineItemId: newLine,
        itemsList: [],
      });

      this.setState({
        indentLineItems: indentLineItemsObj,
      });
      this.props.IndentLineItemsProps(indentLineItemsObj);
    }
    if (name === "department") {
      if (selectedRow === lastRow) {
        if (this.props.indentWithMultiDepartments === "1") {
          let indentLineItems = this.state.indentLineItems;
          let obj = {
            id: newLine + 1,
            qty: 0,
            uomCode: "",
            qohCompany: "",
            qohBranch: 0,
            qohProject: 0,
            rate: 0,
            rateSource: 1,
            remarks: "",
            make: "",
            itemGroup: "",
            itemGroupValue: "",
            departmentName: "",
            departmentId: "",
            itemId: "",
            itemCode: "",
            isActive: 1,
            lastPurchaseDate: "",
            lastPurchaseRate: 0,
            installationRate: null,
            qtyPo: "",
            amount: 0,
            min: 0,
            max: 0,
            minAllowedQty: 0,
            maxAllowedQty: 0,
            itemList: [],
            departmentList: [],
            itemGroupList: [],
            reOrderQty: 0,
            departmentText: "", //this field textbox department name
            itemName: "", //
            itemText: "", // this field textbox item
            itemGroupText: "", // this field textbox item group
            isEditMode: true,
            isUpdate: true,
          };
          indentLineItems.push(obj);
          this.setState({
            indentLineItems: indentLineItems,
          });
          this.props.IndentLineItemsProps(indentLineItems);
        }
      }
    }
    if (name === "itemGroup") {
      this.displayField(selectedRow, name, 2);
      let indentLineItemsObj = this.state.indentLineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          row.itemGroup = selectedName;
          row.itemGroupValue = selectedValue;
          row.itemGroupId = selectedValue;
          row.itemList = [];
          row.itemId = "";
          row.itemName = "";
          row.rate = "";
          row.qty = 0;
          row.remarks = "";
          row.make = "";
          row.uomCode = "";
          this.props.indentFillDetails.itemGroup = selectedName;
          this.props.indentFillDetails.itemGroupValue = selectedValue;
          // const data = {
          //   companyId: localStorage.getItem("companyId"),
          //   consumable: "",
          //   indentTypeId: this.props.indentFillDetails.categoryTypeValue,
          //   itemGroupId: selectedValue,
          //   saleable: "",
          //   branchId: this.props.indentFillDetails.branchValue,
          // };

          // this.props.getItemsListforIndent(
          //   serverApi.GET_ITEMSBY_FORPROCUREMENT,
          //   data,
          //   this.getitemListByitemgrpId,
          //   selectedValue
          // );
          const data2 = {
            itemGroupId: selectedValue,
            companyId: localStorage.getItem("companyId"),
          };
          this.props.getItemsListByItemGroupId(
            serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
            data2,
            this.getitemListByitemgrpId,
            selectedValue
          );
        }
        return row;
      });

      this.setState({
        indentLineItems: indentLineItemsObj,
      });
      this.props.IndentLineItemsProps(indentLineItemsObj);
    }
  };

  onSubmitnotes = () => {
    if (this.props.location.state.rowData) {
      const data = {
        sourceModuleId: this.state.indentId,
        createdBy: this.state.userDit.userId.toString(),
        oldId: "",
        internalRemarks: this.state.submitRemarks,
        companyId: localStorage.getItem("companyId"),
      };
      this.props.actions(serverApi.ACTIONS, data, this.props.history);
    }

    if (this.state.submitRemarks) {
      if (
        this.state.internalRemarks === "" ||
        this.state.internalRemarks === null
      ) {
        let internalNotes = this.state.submitRemarks;
        this.props.indentFillDetails.internalRemarks = internalNotes;
        this.setState({
          internalRemarks: internalNotes,
          submitRemarks: "",
        });
      } else {
        let internalNotes =
          this.state.internalRemarks + "\n" + this.state.submitRemarks;
        this.props.indentFillDetails.internalRemarks = internalNotes;
        this.setState({
          internalRemarks: internalNotes,
          submitRemarks: "",
        });
      }
    }
  };

  UploadDocuments = (key) => (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 10);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", this.state.indentId);
    formData.append("createdBy", this.state.userDit.userId);
    formData.append("fileCompanyId", localStorage.getItem("companyId"));
    this.setState({
      fileName: file.name,
      fileType: fileExt,
      uploadFile: true,
    });
    this.props.postUploadFileList(
      serverApi.UPLOADFILE,
      formData,
      this.props.history
    );
  };

  onDeleteFile = (fileId) => {
    let data = this.state.files_List.filter(
      (item) => item.fileUploadId !== fileId
    );
    this.props.deleteFile(serverApi.DELETEFILE + fileId);
    this.setState({
      files_List: data,
    });
    this.props.getFilesSuccessfull({ data });
  };

  onDelLineItem = (row) => {
    var lastRow =
      this.state.indentLineItems[this.state.indentLineItems.length - 1];
    if (lastRow === row) {
      swal("you can't delete the empty lineitem");
    } else {
      let indexItem = this.state.indentLineItems.findIndex(
        (item) => item === row
      );
      let lineItems = this.state.indentLineItems;
      lineItems[indexItem].isActive = 0;
      this.setState({
        indentLineItems: lineItems,
      });
    }
  };

  onClickToSeeLogs = () => {
    this.setState({
      uploadFile: false,
      seeLogs: true,
    });
    if (this.props.location.state) {
      let id = this.props.location.state.rowData.id;
      this.props.logs(serverApi.SEE_THE_LOGS + id, this.props.history);
    }
  };

  getTotalAmount = () => {
    var totalAmount = 0;
    var data = this.state.indentLineItems.filter((item) => item.isActive !== 0);

    data.forEach((prop, key) => {
      if (prop.itemId !== "" && prop.qty !== "" && prop.amount !== "") {
        totalAmount =
          totalAmount + parseFloat(prop.rate) * parseFloat(Number(prop.qty));
      }
    });
    return totalAmount.toFixed(2);
  };

  getFileDownload = (fileUploadId, fileName, fileExtension) => {
    let url = serverVars.SECURITY_API + "files/downloadfile/" + fileUploadId;
    getFileDownload(url, fileName, fileExtension);
  };
  handleEventPositioned = (info, id) => {
    var filteredLineitems = this.state.indentLineItems.filter(
      (item) => item.isActive !== 0
    );
    filteredLineitems.map((item) => {
      if (item.id == id) {
        if (info.target) {
          info.target.setAttribute(
            "data-tip",
            `<p>Central Stock = ${
              item.qohCompany == "" || item.qohCompany == null
                ? 0
                : Number(item.qohCompany).toFixed(3)
            }</p>
          <p> Branch Stock = ${
            item.qohBranch == "" || item.qohBranch == null
              ? 0
              : Number(item.qohBranch).toFixed(3)
          }</p>
          <p> Qty pending for Po = ${
            item.qtyPo == "" || item.qtyPo == null
              ? 0
              : Number(item.qtyPo).toFixed(3)
          }</p>
          <p>Re Order Quantity =${
            item.reOrderQty === undefined
              ? 0
              : Number(item.reOrderQty).toFixed(3)
          }</p>
          <p>Max = ${
            item.max === undefined ? 0 : Number(item.max).toFixed(3)
          }</p>
          <p>Min = ${
            item.min === undefined ? 0 : Number(item.min).toFixed(3)
          }</p>
           <p>MaxAllowedQty = ${
             item.maxAllowedQty === undefined
               ? 0
               : Number(item.maxAllowedQty).toFixed(3)
           }</p>
          <p>MinAllowedQty = ${
            item.minAllowedQty === undefined
              ? 0
              : Number(item.minAllowedQty).toFixed(3)
          }</p>
         
          `
          );
          ReactTooltip.rebuild();
        }
      }
    });
  };
  getitemListByitemgrpId = (response, itemGrpId) => {
    if (itemGrpId !== undefined) {
      if (response) {
        let indentLineItemsObj = this.state.indentLineItems.filter((row) => {
          if (row.itemGroup === itemGrpId || row.itemGroupId === itemGrpId) {
            let list = [];
            response.data.map((prop) => {
              list.push({
                label: prop.itemDesc+ `(${prop.itemCode})`,
                name: prop.itemDesc+ `(${prop.itemCode})`,
                value: prop.itemId,
                variant: prop.itemCode,
              });
            });
            row.itemList = list;

            // let list = [];
            // response.data.map((prop) => {
            //   list.push({
            //     label: prop.label,
            //     name: prop.name,
            //     value: prop.value,
            //     variant: prop.itemCode,
            //   });
            // });
            // let itemsList = list.filter((item) => {
            //   if (item.value == row.itemId) {
            //     let value = item.name.split("^");
            //     row.min = value[8];
            //     row.max = value[6];
            //     row.maxAllowedQty = value[14];
            //     row.minAllowedQty = value[15];
            //     row.reOrderQty = value[12];
            //   }
            //   if (item.value !== 0 && item.value !== "0") {
            //     return item;
            //   }
            // });
            // row.itemList = itemsList;
          } else if (row.departmentId === itemGrpId) {
            let itemGRPList = [];
            response.data.map((prop) => {
              itemGRPList.push({
                label: prop.label,
                name: prop.name,
                value: prop.value,
              });
            });
            let itemsgroupList = itemGRPList.filter((item) => {
              if (item.value !== 0 && item.value !== "0") {
                return item;
              }
            });
            row.itemGroupList = itemsgroupList;
          }
          return row;
        });
        this.setState({
          indentLineItems: indentLineItemsObj,
        });
        this.props.IndentLineItemsProps(indentLineItemsObj);
      }
      // return this.state.itemsList
    }
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  displayField = (row, colname, type) => {
    if (colname === "department") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";
      this.setState((prevState) => ({
        deptDisplayTxtField: {
          ...prevState.deptDisplayTxtField,
          [fieldKey1]: !prevState.deptDisplayTxtField[fieldKey1],
        },
        deptDisplayDropField: {
          ...prevState.deptDisplayDropField,
          [fieldKey2]: !prevState.deptDisplayDropField[fieldKey2],
        },
      }));
      //call api
      if (type !== 2) {
        this.props.getDepartmentList(
          serverApi.GET_DEPARTMENT_LIST +
            localStorage.getItem("companyId") +
            "/" +
            this.state.userDit.cipher,
          this.props.history
        );
      }
    } else if (colname === "itemGroup") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";
      this.setState((prevState) => ({
        deptDisplayTxtField: {
          ...prevState.deptDisplayTxtField,
          [fieldKey1]: !prevState.deptDisplayTxtField[fieldKey1],
        },
        deptDisplayDropField: {
          ...prevState.deptDisplayDropField,
          [fieldKey2]: !prevState.deptDisplayDropField[fieldKey2],
        },
      }));
      if (type !== 2) {
        if (row.itemGroupId !== "" && row.itemGroupId !== undefined) {
          const data2 = {
            itemGroupId: row.itemGroupId,
            companyId: localStorage.getItem("companyId"),
          };
          this.props.getItemsListByItemGroupId(
            serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
            data2,
            this.getitemListByitemgrpId,
            row.itemGroupId
          );
        }
      }
    } else if (colname === "item_code") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";
      this.setState((prevState) => ({
        deptDisplayTxtField: {
          ...prevState.deptDisplayTxtField,
          [fieldKey1]: !prevState.deptDisplayTxtField[fieldKey1],
        },
        deptDisplayDropField: {
          ...prevState.deptDisplayDropField,
          [fieldKey2]: !prevState.deptDisplayDropField[fieldKey2],
        },
      }));

      if (type !== 2) {
        if (row.itemId !== "" && row.itemId !== undefined) {
          const data1 = {
            companyId: localStorage.getItem("companyId"),
            itemId: row.itemId,
            branchId: this.props.indentFillDetails.branchValue,
          };
          this.props.getPendingStock(
            serverApi.GET_PENDING_STOCK_BRANCH_WISE,
            data1,
            this.props.history,
            row.itemId
          );
          const data2 = {
            companyId: localStorage.getItem("companyId"),
            itemId: row.itemId,
          };
          this.props.getPendingStockPoandReceipt(
            serverApi.GET_CENTRAL_STOCK,
            data2,
            this.props.history,
            row.itemId
          );
        }
      }
    }
  };

  isdisabledFields = (row) => {
    if (this.props.location.state !== undefined) {
      if (
        row.departmentId === "" ||
        row.itemGroupId === "" ||
        row.itemId === ""
      ) {
        return false;
      }
      return true;
    }
  };

  render() {
    let itemsList = this.state.itemsList;
    if (this.state.indentWithMultiDepartments === "1") {
      var RowData = RowData1;
      var HeadData = tableHeaders;
      if (this.props.locationState.pathname === "/create_openIndent") {
        RowData = RowData1.filter((row) => {
          if (row.no !== 6 && row.no !== 8) {
            return row;
          }
        });
        HeadData = tableHeaders.filter((row) => {
          if (row.header !== "Quantity" && row.header !== "Amount") {
            return row;
          }
        });
      }
    } else {
      RowData = RowData1.filter((row) => {
        if (row.no !== 1 && row.no !== 2) {
          return row;
        }
      });
      HeadData = tableHeaders.filter((row) => {
        if (row.header !== "Department" && row.header !== "Item Group") {
          return row;
        }
      });
    }
    var filteredLineitems = this.state.indentLineItems.filter(
      (item) => item.isActive !== 0
    );
console.log(filteredLineitems)
    return (
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent" onScroll={this.handleScroll}>
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {HeadData.map((column) => (
                    <>
                      {(column.header === "Quantity" &&
                        this.props.locationState.pathname !==
                          "/create_openIndent") ||
                      column.header === "Department" ||
                      column.header === "Item Group" ||
                      column.header === "Item" ? (
                        <th>
                          {column.header}
                          <span style={{ color: "red" }}>*</span>
                        </th>
                      ) : (
                        <th>{column.header}</th>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredLineitems.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <>
                      {RowData &&
                        RowData.map((col) =>
                          col.component === "NonEditableCell" ? (
                            <>
                              <td>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        type="text"
                                        name={col.type + "_" + rowIndex}
                                        id={col.no}
                                        disabled
                                        Value={
                                          col.type === "rate"
                                            ? row.rate === ""
                                              ? row.rate
                                              : parseFloat(row.rate).toFixed(3)
                                            : col.type === "unit"
                                            ? row.uomCode
                                            : col.type === "Amount"
                                            ? parseFloat(
                                                row.rate * row.qty
                                              ).toFixed(2)
                                            : ""
                                        }
                                        onWheel={(event) => {
                                          event.preventDefault();
                                        }}
                                        onChange={this.handleChange({
                                          key: col.type + "_" + rowIndex,
                                          row,
                                        })}
                                        className="inputBlockContainer"
                                        style={{
                                          textAlign:
                                            col.type === "Amount" ||
                                            col.type === "rate"
                                              ? "right"
                                              : "",
                                          paddingRight: "8px",
                                        }}
                                        onKeyDown={(evt) => {
                                          ["e", "E", "+", "-"].includes(
                                            evt.key
                                          ) && evt.preventDefault();
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            </>
                          ) : col.component === "EditableCell" ? (
                            <td>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      name={col.type + "_" + rowIndex}
                                      id={col.no}
                                      style={{
                                        textAlign:
                                          col.type === "itemQty" ? "right" : "",
                                        paddingRight: "8px",
                                      }}
                                      type={
                                        col.type === "itemQty"
                                          ? "number"
                                          : "text"
                                      }
                                      value={
                                        col.type === "itemQty"
                                          ? row.qty === ""
                                            ? ""
                                            : row.qty
                                          : col.type === "remarks"
                                          ? row.remarks
                                          : col.type === "make"
                                          ? row.make
                                          : ""
                                      }
                                      onChange={this.handleChange({
                                        key: col.type + "_" + rowIndex,
                                        row,
                                      })}
                                      onWheel={(e) => e.target.blur()}
                                      onBlur={
                                        this.props.location.pathname ===
                                        "/create_openIndent"
                                          ? () => {}
                                          : this.handleBlur({
                                              key: col.type + "_" + rowIndex,
                                              row,
                                            })
                                      }
                                      // onKeyPress={this.handleKeyPress}
                                      className="inputBlockContainer"
                                      onMouseOver={(e) =>
                                        col.type === "itemQty"
                                          ? this.handleEventPositioned(
                                              e,
                                              row.id
                                            )
                                          : ""
                                      }
                                      readOnly={
                                        this.props.location.pathname ===
                                          "/create_openIndent" &&
                                        col.type === "itemQty"
                                          ? true
                                          : this.props.location.pathname ===
                                            "/create_indent"
                                          ? row.min !== "0.0" ||
                                            row.min !== undefined
                                            ? false
                                            : true
                                          : false
                                      }
                                      onKeyDown={(evt) =>
                                        col.type === "remarks" ||
                                        col.type === "make"
                                          ? () => {}
                                          : ["e", "E", "+", "-"].includes(
                                              evt.key
                                            ) && evt.preventDefault()
                                      }
                                    />
                                    <ReactTooltip
                                      effect={"solid"}
                                      html={true}
                                      place={"BottomCenter"}
                                      className="tooltipClassBorder"
                                      backgroundColor="rgba(0,127,173,0.5)"
                                      textColor="#FFF"
                                      border={true}
                                      borderColor="#04749c"
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          ) : (
                            <>
                              <td>
                                <Tooltip
                                  title={
                                    col.type === "department"
                                      ? row.departmentName
                                      : col.type === "itemGroup"
                                      ? row.itemGroup
                                      : col.type === "item_code"
                                      ? row.itemName
                                      : ""
                                  }
                                >
                                  <div className="quantityDropdownBlock">
                                    <Grid className="selectAndTextfield">
                                      <Grid
                                        xs={12}
                                        md={12}
                                        className="dynamicSelectBlock"
                                        style={{
                                          display: this.state
                                            .deptDisplayTxtField[
                                            col.type + row.id + "1"
                                          ]
                                            ? "none"
                                            : "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          type="text"
                                          className="inputBlockContainer"
                                          style={{
                                            paddingRight: "8px",
                                            minWidth: "250px",
                                            ...(row.isEditMode === false
                                              ? { backgroundColor: "#ccd6db" }
                                              : {}),
                                          }}
                                          value={
                                            col.type === "department"
                                              ? row.departmentName
                                              : col.type === "item_code"
                                              ? row.itemName
                                              : row.itemGroup
                                          }
                                          onChange={this.handleChange({
                                            key: col.type + "_" + rowIndex,
                                            row,
                                          })}
                                          onClick={() => {
                                            if (
                                              this.state.locationState.state ===
                                              undefined
                                            ) {
                                              this.displayField(
                                                row,
                                                col.type,
                                                1
                                              );
                                            } else {
                                              if (
                                                row.isEditMode === true ||
                                                row.isEditMode === undefined
                                              ) {
                                                this.displayField(
                                                  row,
                                                  col.type,
                                                  1
                                                );
                                              }
                                            }
                                          }}
                                          onWheel={(e) => {
                                            e.preventDefault();
                                          }}
                                          disabled={
                                            row.isEditMode === true ||
                                            row.isEditMode === undefined
                                              ? false
                                              : true
                                          }
                                        />
                                      </Grid>
                                      <Grid
                                        xs={12}
                                        md={12}
                                        className="dynamicSelectBlock"
                                        style={{
                                          display: this.state
                                            .deptDisplayDropField[
                                            col.type + row.id + "2"
                                          ]
                                            ? "flex"
                                            : "none",
                                          alignItems: "center",
                                        }}
                                      >
                                        <DynamicSelect
                                          isDropdownOpen={
                                            this.state.isDropdownOpen
                                          }
                                          arrayOfData={
                                            col.type === "department"
                                              ? this.state.DepartmentList
                                              : col.type === "item_code"
                                              ? this.props
                                                  .indentWithMultiDepartments ===
                                                "2"
                                                ? itemsList
                                                : row.itemList
                                              : this.props
                                                  .indentWithMultiDepartments ===
                                                "2"
                                              ? this.state.ItemGroupList
                                              : row.itemGroupList
                                          }
                                          className="dropdownBlockContainer"
                                          handleMenuOpen={this.handleMenuOpen}
                                          onSelectChange={
                                            this.handleChangedropdown
                                          }
                                          selected={
                                            col.type === "department"
                                              ? row.departmentId
                                              : col.type === "item_code"
                                              ? row.itemId
                                              : this.state
                                                  .indentWithMultiDepartments ===
                                                "1"
                                              ? this.props.location.state !==
                                                undefined
                                                ? row.itemGroupId
                                                : row.itemGroupValue
                                              : Number(row.itemGroup)
                                          }
                                          name={col.name}
                                          row={row}
                                          update={
                                            row.departmentId ||
                                            row.itemId ||
                                            this.state
                                              .indentWithMultiDepartments ===
                                              "1"
                                              ? this.props.location.state !==
                                                undefined
                                                ? row.itemGroupId
                                                : row.itemGroupValue
                                              : Number(row.itemGroup)
                                              ? 1
                                              : 0
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Tooltip>
                              </td>
                            </>
                          )
                        )}
                    </>
                    <td className="">
                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title="Delete"
                      >
                        <IconButton
                          onClick={() => {
                            this.onDelLineItem(row);
                          }}
                        >
                          <DeleteIcon className="deleteDisplayButton" />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="consoleFormContainer">
          <div style={{ height: "10px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 10">
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 6">
                        <div className="consoleTextFieldBlock">
                          <TextArea
                            label="Remarks"
                            onChange={this.handleChangetextAreas("remarks")}
                            value={this.state.remarks}
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 6">
                        <div className="consoleTextFieldBlock">
                          <TextArea
                            label="Internal Note"
                            onChange={this.handleChangetextAreas(
                              "internalRemarks"
                            )}
                            value={this.state.internalRemarks}
                          ></TextArea>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                  {this.props.location.pathname !== "/create_openIndent" && (
                    <Box gridColumn="span 2">
                      <div className="consoleTextFieldBlock">
                        <TableContainer className="amountTable">
                          <Table aria-label="simple table">
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  component="td"
                                  scope="row"
                                  className="noBorder"
                                >
                                  Total Amount:
                                </TableCell>
                                <TableCell align="right" className="noBorder">
                                  <strong>{this.getTotalAmount()}</strong>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        {this.props.locationState.state && (
          <div className="consoleFormContainer">
            <Grid container spacing={2}>
              <Grid item xs={12} className="personalDetailsUpload">
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                  className="ducumentUploadBlock"
                >
                  <Box gridColumn="span 6">
                    <div className="consoleFormBlock filesUploadedBlock">
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={2}
                        className="ducumentUploadBlock uploadedContent"
                      >
                        <Box gridColumn="span 6">
                          <label>Support Documents:</label>
                          <div className="personalDetailsUpload">
                            <div className="ducumentUploadBlock">
                              <div className="documentUploadContent">
                                <span className="btn_upload documentUpload">
                                  <img src={panUploadIcon} alt="" />
                                  <input
                                    type="file"
                                    id="imag"
                                    title=""
                                    className="input-img"
                                    onChange={this.UploadDocuments()}
                                  />
                                  Upload Document
                                </span>
                              </div>
                            </div>
                          </div>
                        </Box>
                      </Box>
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={2}
                        className="ducumentUploadBlock uploadedContent"
                      >
                        {this.state.files_List &&
                          this.state.files_List.map((item) => (
                            <Box gridColumn="span 6">
                              <div className="documentUploadContent">
                                <div
                                  className="uploadedInfo"
                                  style={{ display: "flex" }}
                                >
                                  <img
                                    src={TickIcon}
                                    alt=""
                                    className="tickIcon"
                                    height={"20px"}
                                    width={"20px"}
                                  />
                                  <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    title="Click to download File"
                                  >
                                    <div
                                      className="uplodedFileName"
                                      onClick={() => {
                                        this.getFileDownload(
                                          item.fileUploadId,
                                          item.fileName,
                                          item.fileExtension
                                        );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {item.fileName}
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 100 }}
                                    title="Click to Delete File"
                                  >
                                    <Button
                                      onClick={() => {
                                        this.onDeleteFile(item.fileUploadId);
                                      }}
                                      className="deleteButton"
                                    >
                                      <img
                                        src={DeleteIcons}
                                        alt=""
                                        height={"20px"}
                                        width={"20px"}
                                      />
                                    </Button>
                                  </Tooltip>
                                </div>
                              </div>
                            </Box>
                          ))}
                      </Box>
                    </div>
                  </Box>
                  <Box gridColumn="span 6">
                    <div className="consoleFormBlock">
                      <div className="consoleTextFieldBlock remarksBlock">
                        <TextArea
                          label="Internal Notes"
                          onChange={this.handleChangeSubmit("submitRemarks")}
                          value={this.state.submitRemarks}
                        />
                        <CustomButton
                          label={"Submit"}
                          className="greenButton"
                          handleClick={this.onSubmitnotes}
                          type="sumbit"
                        />
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
              <Box>
                <Grid spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 3">
                        <div className="consoleFormButtonBlock">
                          <CustomButton
                            label={"CLICK HERE TO SEE THE LOGS"}
                            className="greenButton"
                            handleClick={this.onClickToSeeLogs}
                            type="sumbit"
                          />
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid spacing={2} className="logsContainer">
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 6">
                        {this.state.Logs &&
                          this.state.Logs.map((item) => {
                            let date = moment(item.actionDate)
                              .format("DD-MM-YYYY")
                              .split("T")
                              .toString();
                            let time = moment(item.actionDate)
                              .format()
                              .split("T", 2);
                            return (
                              <ul>
                                <li className="logsContent">
                                  <strong>{date}</strong>
                                  <span> </span>
                                  {time[1]}
                                  <span> </span>
                                  {item.remarks}
                                </li>
                              </ul>
                            );
                          })}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

const RowData1 = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "department",
    dropDown: "dropDown",
    name: "department",
    Items: true,
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "itemGroup",
    dropDown: "dropDown",
    name: "itemGroup",
    Items: true,
  },
  {
    no: 3,
    component: "DynamicSelect",
    type: "item_code",
    dropDown: "dropDown",
    name: "item",
    Items: true,
  },

  {
    no: 5,
    component: "NonEditableCell",
    type: "rate",
  },
  {
    no: 6,
    component: "EditableCell",
    type: "itemQty",
    uomCode: "Kgs",
  },
  {
    no: 7,
    component: "NonEditableCell",
    type: "unit",
    uomCode: "Kgs",
  },
  {
    no: 4,
    component: "EditableCell",
    type: "remarks",
  },
  {
    no: 9,
    component: "EditableCell",
    type: "make",
  },
  {
    no: 8,
    component: "NonEditableCell",
    type: "Amount",
  },
];
const tableHeaders = [
  {
    header: "Department",
  },
  {
    header: "Item Group",
  },
  {
    header: "Item",
  },
  {
    header: "Item Rate",
  },
  {
    header: "Quantity",
  },
  {
    header: "Unit",
  },
  {
    header: "Remarks",
  },
  {
    header: "Make",
  },
  {
    header: "Amount",
  },
  {
    header: "Actions",
  },
];

const mapStatetoProps = (state) => {
  const { ItemGroupList, departmentList } = state.DropDownListReducer;
  const { itemsListForIndent } = state.itemsListForIndent;
  const {
    logs_List,
    files_List,
    indentLineItems,
    pendingStock,
    pendingStockPoandReceipt,
    validateItemMessage,
  } = state.IndentReducer;
  const { Upload_File_List } = state.UploadFileReducer;
  return {
    pendingStockPoandReceipt,
    logs_List,
    files_List,
    Upload_File_List,
    ItemGroupList,
    departmentList,
    itemsListForIndent,
    indentLineItems,
    pendingStock,
    validateItemMessage,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getPendingStockPoandReceipt,
    getItemsListForIndent,
    getPendingStock,
    postUploadFileList,
    deleteFile,
    actions,
    logs,
    getFiles,
    getFilesSuccessfull,
    getDepartmentList,
    getItemGroupList,
    IndentLineItemsProps,
    validateItem,
    getItemsListforIndent,
    getItemGroupListforindent,
    getItemDetails,
    getItemsListByItemGroupId
  })(IndentLineItems)
);
