// import react from "react";

export const PieceRateType = [
  { label: "Select", value: null },
  { label: "Supervision", value: "2" },
  { label: "Machine", value: "1" },
];
export const TimePiece = [
  { label: "Select", value: null },
  { label: "Time", value: "T" },
  { label: "Piece", value: "P" },
];

export const yesOrNo = [
  { label: "Select", value: null },
  { label: "Yes", value: "Y" },
  { label: "No", value: "N" },
];
export const directIndirect = [
  { label: "Select", value: null },
  { label: "Direct", value: "D" },
  { label: "I", value: "indirect" },
];
export const machineType = [
  { label: "Select", value: null },
  { label: "R/G", value: "R/G" },
  { label: "DH", value: "DH" },
  { label: "Loom", value: "Loom" },
  { label: "S4 Loom", value: "S4 Loom" },
  { label: "SPREADER", value: "SPREADER" },
  { label: "SPINNING", value: "SPINNING" },
];
export const PaymentSource = [
  { label: "Select", value: null },
  { label: "CHEQUE", value: "C" },
  { label: "CASH", value: "CH" },
  { label: "NEFT", value: "N" },
  { label: "RTGS", value: "R" },
  { label: "JOURNAL", value: "J" },
  { label: "IMPS", value: "I" },
  { label: "TPT", value: "T" },
];
export const AccountType = [
  { label: "Select", value: null },
  { label: "Savings", value: "Savings" },
  { label: "CashCredit", value: "CashCredit" },
  { label: "Current", value: "Current" },
  { label: "OverDraft", value: "OverDraft" },
  { label: "TermLoan", value: "TermLoan" },
];
export const MaritalStatus = [
  { label: "Select", value: "" },
  { label: "Married", value: "Married" },
  { label: "Unmarried", value: "Unmarried" },
];
export const GenderType = [
  { label: "Select", value: "" },
  { label: "Male", value: "M" },
  { label: "Female", value: "F" },
  { label: "Trans Gender", value: "T" },
];
export const UserStatus = [
  { label: "Select", value: "" },
  { label: "Active", value: "Y" },
  { label: "InActive", value: "N" },
];
export const Weeks = [
  { label: "Select", value: "" },
  { label: "Sunday", value: 1 },
  { label: "Monday", value: 2 },
  { label: "Tuesday", value: 3 },
  { label: "Wednesday", value: 4 },
  { label: "Thursday", value: 5 },
  { label: "Friday", value: 6 },
  { label: "Saturday", value: 7 },
];
export const BloodGroups = [
  { label: "Select", value: "" },
  { label: "O-", value: "O-" },
  { label: "O+", value: "O+" },
  { label: "A-", value: "A-" },
  { label: "A+", value: "A+" },
  { label: "B-", value: "B-" },
  { label: "B+", value: "B+" },
  { label: "AB-", value: "AB+" },
];
export const EducationTypes = [
  { label: "Select", value: "" },
  { label: "10th", value: "10th" },
  { label: "+2 or Equalent", value: "+2 or Equalent" },
  { label: "Graduation", value: "Graduation" },
  { label: "Ph.D", value: "Ph.D" },
  { label: "Other Course", value: "Other Course" },
];
export const AttendanceMark = [
  { label: "Select", value: "" },
  { label: "Present", value: "P" } /*,{label:"Abscent",value: "A"}*/,
  { label: "Late", value: "L" },
];
export const AttendanceType = [
  { label: "Select", value: "" },
  { label: "Regular", value: "R" },
  { label: "Over Time", value: "O" },
  { label: "Cash", value: "C" },
];

export const Attendancesource = [
  { label: "Select", value: "" },
  { label: "Manual", value: "M" },
];
export const Attendace_Source = [
  { label: "Facial", value: "F" },
  { label: "APP", value: "A" },
  { label: "Manual", value: "M" },
];
export const ParameterTypes = [
  { label: "Select", value: "" },
  { label: "Earning", value: "Earning" },
  { label: "Deduction", value: "Deduction" },
];
export const PayableTypes = [
  { label: "Select", value: "" },
  { label: "Yes", value: "Y" },
  { label: "No", value: "N" },
];
export const NoorYes = [{ label: "Yes", value: 1 }, { label: "No", value: 0 }];
export const PeriodTypes = [
  { label: "QUARTERLY", value: 1 },
  { label: "HALFYEARLY", value: 2 },
  { label: "YEARLY", value: 3 },
  { label: "MONTHLY", value: 4 },
];
export const Status = [
  { label: "Active", value: 1 },
  { label: "InActive", value: 0 },
];
export const JobStatus = [
  { label: "Select", value: "" },
  { label: "OPEN", value: "1" },
  { label: "INPROGRESS", value: "2" },
  { label: "CLOSED", value: "5" },
  { label: "VISITED", value: "27" },
];
export const JobType = [
  { label: "Select", value: "" },
  { label: "Service", value: "SE" },
  { label: "Installation", value: "INS" },
  { label: "Warrenty", value: "WA" },
];
export const ServiceType = [
  { label: "Select", value: "" },
  { label: "INWARD", value: 1 },
  { label: "ONSITE", value: 2 },
];

export const AdvanceType = [
  { label: "Select", value: "" },
  { label: "Borrow Loan", value: "Borrow Loan" },
  { label: "Issue Loan", value: "Issue Loan" },
];
export const acomadationType = [
  { label: "Select", value: "" },
  { label: "Rental", value: 1 },
  { label: "Hostler", value: 2 },
];
/*export const months=[{label:"Select",value:""},{label:"Jan",value:1},{label:"Feb",value:2},{label:"Mar",value:3},{label:"Apr",value:4}
    ,{label:"May",value:5},{label:"Jun",value:6},{label:"Jul",value:7},{label:"Aug",value:8},{label:"Sep",value:9},{label:"Oct",value:10}
    ,{label:"Nov",value:11},{label:"Dec",value:12}];*/
export const months = [
  { label: "Select", value: "" },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 },
  { label: 11, value: 11 },
  { label: 12, value: 12 },
];
export const saleTypes = [
  { label: "Select", value: "" },
  { label: "Sale Of Material", value: "Sale Of Material" },
  { label: "Sale Of Service", value: "Sale Of Service" },
  { label: "Consolidated", value: "Consolidated" },
];

export const ClientVisitTypes = [
  { value: "", label: "Select...", name: "Select..." },
  { value: "Sales", label: "Sales", name: "Sales" },
  { value: "Service", label: "Service", name: "Service" },
  { value: "Purchase", label: "Purchase", name: "Purchase" },
  {
    value: "Sales and Service",
    label: "Sales and Service",
    name: "Sales and Service",
  },
  { value: "Operations", label: "Operations", name: "Operations" },
  { value: "Accounts", label: "Accounts", name: "Accounts" },
  { value: "Legal", label: "Legal", name: "Legal" },
  {
    value: "Training and Seminar",
    label: "Training and Seminar",
    name: "Training and Seminar",
  },
];
export const ChannelTypes = [
  { value: "", label: "Select", name: "Select" },
  { value: "D", label: "Domestic", name: "Domestic" },
  { value: "I", label: "Import", name: "Import" },
  { value: "J", label: "JCI", name: "JCI" },
  { value: "P", label: "PTF", name: "PTF" },
];
export const PackTypes = [
  { value: "", label: "Select", name: "Select" },
  { value: "150 kg/Bale", label: "KB", name: "KB" },
  { value: "450 kg/Drum", label: "DS", name: "DS" },
  { value: "180 kg/Bale", label: "PB", name: "PB" },
];
export const menuTypes = [
  { value: 1, label: "Common", name: "Common" },
  { value: 2, label: "Report", name: "Report" },
  { value: 3, label: "Widget", name: "Widget" },
  { value: 4, label: "Bar Graph", name: "Bar Graph" },
  { value: 5, label: "Line Graph", name: "Line Graph" },
  { value: 6, label: "Pie Chart", name: "Pie Chart" },
];
export const supplierTypesList = [
  { value: "J", label: "JUTE", name: "JUTE" },
  { value: "S", label: "STORE", name: "STORE" },
  { value: "O", label: "AGENT", name: "AGENT" },
  { value: "V", label: "VENDOR", name: "VENDOR" },
];
export const invoiceTypesList = [
  { value: "1", label: "General", name: "General" },
  { value: "2", label: "Raw Jute", name: "Raw Jute" },
  { value: "3", label: "Finished Goods", name: "Finished Goods" },
];
export const ApprovalTasks = [
  { taskName: "Indent", taskId: 1 },
  { taskName: "PO", taskId: 2 },
  { taskName: "SR", taskId: 3 },
  { taskName: "MR", taskId: 4 },
  { taskName: "Jute Indent", taskId: 5 },
  { taskName: "Jute PO", taskId: 6 },
  { taskName: "Bill Pass", taskId: 7 },
  { taskName: "Jute Issue", taskId: 8 },
  { taskName: "Store Issue", taskId: 9 },
  { taskName: "Store Bill Pass", taskId: 10 },
  { taskName: "General Bills", taskId: 11 },
  { taskName: "Transactions", taskId: 12 },
  { taskName: "Quotations", taskId: 13 },
  { taskName: "Delivery Order", taskId: 39 },
  { taskName: "Invoices", taskId: 14 },
  { taskName: "Employee Database", taskId: 15 },
  { taskName: "Employee Attendance", taskId: 16 },
  { taskName: "Client Visit Plan", taskId: 17 },
  { taskName: "Employee Leave Request", taskId: 18 },
  { taskName: "Expense Booking", taskId: 19 },
  { taskName: "Salary Adjustment", taskId: 20 },
  { taskName: "Advance Request", taskId: 21 },
  { taskName: "Enquiries", taskId: 22 },
  { taskName: "Customer Package Mapping", taskId: 23 },
  { taskName: "Price Master", taskId: 24 },
  { taskName: "Store Stock Transfer", taskId: 25 },
  { taskName: "Store Stock Return", taskId: 26 },
  { taskName: "Pay Register", taskId: 27 },
  { taskName: "Sales Order", taskId: 28 },
  { taskName: "Jobs", taskId: 29 },
  { taskName: "Customer Employee Billing Pay Register", taskId: 30 },
  { taskName: "Jute Conversion", taskId: 31 },
  { taskName: "Supplier", taskId: 32 },
  { taskName: "Work Order", taskId: 33 },
  { taskName: "Material Inspection", taskId: 34 },
  { taskName: "Work Inspection", taskId: 35 },
  { taskName: "BOM", taskId: 36 },
  { taskName: "BOQ", taskId: 37 },
  { taskName: "Work Report", taskId: 38 },
];
export const ReasonTypes = [
  { label: "Select", value: null },
  { label: "Damage", value: "Damage" },
  { label: "Defect", value: "Defect" },
  { label: "Shortage", value: "Shortage" },
  {
    label: "Not as per order specification",
    value: "Not as per order specification",
  },
  { label: "Quality Issue", value: "Quality Issue" },
  { label: "Close to expire", value: "Close to expire" },
  { label: "Expired", value: "Expired" },
];
export const GoodTypes = [
  { label: "GRN", value: "GRN" },
  { label: "SRN", value: "SRN" },
];
export const RoundOffTypes = [
  { label: "Select...", value: null },
  { label: "HALF_EVEN", value: 1 },
  { label: "DOWN", value: 2 },
  { label: "UP", value: 2 },
];
export const TCSPercentages = [
  { label: "0%", value: 0 },
  { label: "0.075%", value: 0.075 },
  { label: "0.1%", value: 0.1 },
  { label: "1%", value: 1 },
];
export const packagingType = [
  { label: "select...", value: "" },
  { label: "BALES", value: "BALES" },
  { label: "TRUSS", value: "TRUSS" },
  { label: "ETC,.", value: "ETC,." },
];
export const Operators = [
  { label: "Select", value: "", name: "" },
  { label: "+", value: 0, name: "+" },
  { label: "-", value: 2, name: "-" },
  { label: "*", value: 3, name: "*" },
  { label: "/", value: 4, name: "/" },
  { label: "%", value: 5, name: "%" },
  { label: "=", value: 6, name: "=" },
  { label: ";", value: 7, name: ";" },
  { label: ">", value: 8, name: ">" },
  { label: "<", value: 9, name: "<" },
  { label: "space", value: 10, name: " " },
  ,
  { label: "(", value: 11, name: "(" },
  { label: ")", value: 12, name: ") result=" },
  { label: "if", value: 13, name: "var result=0; if (" },
  { label: "then", value: 14, name: "then" },
  { label: "else if", value: 15, name: "else if (" },
  { label: "else", value: 16, name: "else result=" },
  { label: "end if", value: 17, name: "end if" },
  { label: "result", value: 18, name: "result" },
  { label: "var", value: 19, name: "var" },
];

export const uomType = [
  { label: "KG-kilogram", value: 0 },
  { label: "GM-gram", value: 1 },
  { label: "Dz-dozon", value: 2 },
  { label: "QTY-quantal", value: 3 },
  { label: "123-bangalore branch", value: 4 },
  { label: "yard-yards", value: 5 },
  { label: "kgs-kilogram1", value: 6 },
  { label: "lt-liter", value: 7 },
  { label: "MTS-meters", value: 8 },
  { label: "pds-pounds", value: 9 },
  { label: "123-13", value: 10 },
  { label: "323-232", value: 11 },
  { label: "rqer-2323", value: 12 },
  { label: "1-13", value: 13 },
  { label: "15-133", value: 14 },
  { label: "5-13", value: 15 },
  { label: "12-21", value: 16 },
  { label: "yes-yes or no", value: 17 },
  { label: "AM-kilogram", value: 18 },
  { label: "AM-kilograms", value: 19 },
  { label: "pm-er", value: 20 },
  { label: "DM-kilo", value: 21 },
  { label: "ra-uomdes", value: 22 },
  { label: "lts-liters", value: 23 },
  { label: "EM-kilo1", value: 24 },
];
export const Conditions = [
  { label: "Select Condition", value: "", name: "" },
  { label: "(", value: 0, name: "(" },
  { label: ")", value: 1, name: ")" },
  { label: "if", value: 2, name: "if" },
  { label: "then", value: 3, name: "then" },
  { label: "else if", value: 5, name: "else if" },
  { label: "else", value: 6, name: "else" },
  { label: "end if", value: 7, name: "end if" },
  { label: "result", value: 8, name: "result" },
  { label: "var", value: 9, name: "var" },
];
export const PackageTypes = [
  { label: "Corporate", value: 1 },
  { label: "Individual", value: 2 },
  { label: "Company", value: 3 },
];
export const frequency = [
  { label: "select...", value: 0 },
  { label: "Daily", value: 1 },
  { label: "Weekly", value: 2 },
  { label: "Monthly", value: 3 },
  { label: "Quarterly", value: 4 },
  { label: "HalfYearly", value: 4 },
  { label: "Yearly", value: 5 },
];
export const Categories = [
  { label: "Installation", value: 1 },
  { label: "Service", value: 2 },
  { label: "Warranty", value: 3 },
];
export const JobTypes = [
  { label: "Walkin", value: 1 },
  { label: "Onsite", value: 2 },
];
export const DataTypes = [
  { label: "Number", value: "number" },
  { label: "String", value: "string" },
];
const dataTable = {
  headerRow: ["Name", "Position", "Office", "Age"],
  footerRow: ["Name", "Position", "Office", "Age"],
  dataRows: [
    ["Tiger Nixon", "System Architect", "Edinburgh", "61"],
    ["Garrett Winters", "Accountant", "Tokyo", "63"],
    ["Ashton Cox", "Junior Technical Author", "San Francisco", "66"],
    ["Cedric Kelly", "Senior Javascript Developer", "Edinburgh", "22"],
    ["Airi Satou", "Accountant", "Tokyo", "33"],
    ["Brielle Williamson", "Integration Specialist", "New York", "61"],
    ["Herrod Chandler", "Sales Assistant", "San Francisco", "59"],
    ["Rhona Davidson", "Integration Specialist", "Tokyo", "55"],
    ["Colleen Hurst", "Javascript Developer", "San Francisco", "39"],
    ["Sonya Frost", "Software Engineer", "Edinburgh", "23"],
    ["Jena Gaines", "Office Manager", "London", "30"],
    ["Quinn Flynn", "Support Lead", "Edinburgh", "22"],
    ["Charde Marshall", "Regional Director", "San Francisco", "36"],
    ["Haley Kennedy", "Senior Marketing Designer", "London", "43"],
    ["Tatyana Fitzpatrick", "Regional Director", "London", "19"],
    ["Michael Silva", "Marketing Designer", "London", "66"],
    ["Paul Byrd", "Chief Financial Officer (CFO)", "New York", "64"],
    ["Gloria Little", "Systems Administrator", "New York", "59"],
    ["Bradley Greer", "Software Engineer", "London", "41"],
    ["Dai Rios", "Personnel Lead", "Edinburgh", "35"],
    ["Jenette Caldwell", "Development Lead", "New York", "30"],
    ["Yuri Berry", "Chief Marketing Officer (CMO)", "New York", "40"],
    ["Caesar Vance", "Pre-Sales Support", "New York", "21"],
    ["Doris Wilder", "Sales Assistant", "Sidney", "23"],
    ["Angelica Ramos", "Chief Executive Officer (CEO)", "London", "47"],
    ["Gavin Joyce", "Developer", "Edinburgh", "42"],
    ["Jennifer Chang", "Regional Director", "Singapore", "28"],
    ["Brenden Wagner", "Software Engineer", "San Francisco", "28"],
    ["Fiona Green", "Chief Operating Officer (COO)", "San Francisco", "48"],
    ["Shou Itou", "Regional Marketing", "Tokyo", "20"],
    ["Michelle House", "Integration Specialist", "Sidney", "37"],
    ["Suki Burks", "Developer", "London", "53"],
    ["Prescott Bartlett", "Technical Author", "London", "27"],
    ["Gavin Cortez", "Team Leader", "San Francisco", "22"],
    ["Martena Mccray", "Post-Sales support", "Edinburgh", "46"],
    ["Unity Butler", "Marketing Designer", "San Francisco", "47"],
    ["Howard Hatfield", "Office Manager", "San Francisco", "51"],
    ["Hope Fuentes", "Secretary", "San Francisco", "41"],
    ["Vivian Harrell", "Financial Controller", "San Francisco", "62"],
    ["Timothy Mooney", "Office Manager", "London", "37"],
    ["Jackson Bradshaw", "Director", "New York", "65"],
  ],
};

export default dataTable;
