//import { Switch } from "react-router-dom";

import {
  DELETEFILE,
  DELETEFILE_SUCCESSFULL,
  UPLOADED_FILES_OF_CHALLAN,
  UPLOADED_FILES_OF_CHALLAN_SUCCESSFULL,
  UPLOADED_FILES_OF_INVOICE,
  UPLOADED_FILES_OF_INVOICE_SUCCESSFULL,
  UPLOADED_FILES_OF_SUPPDOCS,
  UPLOADED_FILES_OF_SUPPDOCS_SUCCESSFULL,
  UPLOADFILE,
  UPLOADFILE_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  Upload_File_List: [],
  Uploaded_suppdocsFiles: [],
  Uploaded_invoiceList: [],
  Uploaded_challanFiles: [],
  delete_File: [],
  error_msg: null,
};

const UploadFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOADFILE:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPLOADFILE_SUCCESSFULL:
      state = {
        ...state,
        Upload_File_List: action.payload.response,
        loading: false,
      };
      break;
      case UPLOADED_FILES_OF_INVOICE:
        state = {
          ...state,
          loading: true,
        };
        break;
  
    case UPLOADED_FILES_OF_INVOICE_SUCCESSFULL:
      state = {
        ...state,
        Uploaded_invoiceList: action.payload.response,
        loading: false,
      };
      break;
      case UPLOADED_FILES_OF_CHALLAN:
        state = {
          ...state,
          loading: true,
        };
        break;
  
    case UPLOADED_FILES_OF_CHALLAN_SUCCESSFULL:
      state = {
        ...state,
        Uploaded_challanFiles: action.payload.response,
        loading: false,
      };
      break;
          case UPLOADED_FILES_OF_SUPPDOCS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPLOADED_FILES_OF_SUPPDOCS_SUCCESSFULL:
      state = {
        ...state,
        Uploaded_suppdocsFiles: action.payload.response,
        loading: false,
      };
      break;
    case DELETEFILE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETEFILE_SUCCESSFULL:
      state = {
        ...state,
        delete_File: action.payload.response,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default UploadFileReducer;
