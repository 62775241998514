import {
  GET_ALL_APPROVED_PO,
  GET_QUALITY_BY_ITEM,
  JUTE_GATE_ENTRY_CREATE,
  JUTE_GATE_ENTRY_DELETE_FILE,
  JUTE_GATE_ENTRY_GET_ALL_FILES,
  JUTE_GATE_ENTRY_LIST,
  JUTE_GATE_ENTRY_UPLOAD_FILE,
  JUTE_GATE_ENTRY_VIEW_BY_ID,
  JUTE_PO_VIEW_BY_ID_FOR_GATE_ENTRY,
} from "./actionTypes";
import { all, call, fork, takeEvery, put, select } from "redux-saga/effects";
import { postAdd, getListurl, getList } from "../../../helpers/Server_Helper";
import {
  JuteGateEntrySuccessfull,
  LineItemsGateEntryPros,
  getAllApprovedPOSuccessfull,
  juteGateEntryViewByIdSuccessfull,
  getQualityByItemSuccessfull,
  getJuteGateEntryHeader,
  jutePoViewByIdForGateEntrySuccessfull,
  JuteGateEntryUploadFilesSuccessfull,
  JuteGateEntryGetAllFilesSuccessfull,
  JuteGateEntryDeleteFilesSuccessfull,
  JuteGateEntryGetAllFiles,
} from "./actions";
import { getSupplierByMukamIdList } from "../../../store/MasterDropdownApis/actions";
import swal from "sweetalert";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import { isEmpty, limitDecimals } from "../../../Utilities/helper";
import moment from "moment";

function* JuteGateEntryListSagaList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(JuteGateEntrySuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getJuteGateEntryUpdate({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/security_jute_gate_entry");
      hideSpinner();
    } else {
      swal(response.data.message, { icon: "error" });
      hideSpinner();
    }
  } catch (error) {
    swal("error:" + error, { icon: "danger" });
    console.log(error);
    hideSpinner();
  }
}

function* ViewByIdWorkEntry({ payload: { url, history } }) {
  try {
    showSpinner();
    const response = yield call(getListurl, url);
    if (response) {
      if (response.data) {
        if (response.data.entryHeader) {
          hideSpinner();
          yield put(
            getSupplierByMukamIdList(
              serverApi.GET_SUPPLIER_BY_MUKAM_ID +
                response.data.entryHeader.mukam +
                "/company/" +
                localStorage.getItem("companyId")
            )
          );
        }
        if (response.data.juteGateLineItems) {
          if (response.data.juteGateLineItems.length !== 0) {
            var JuteGateEntryLineItemsData = [];
            response.data.juteGateLineItems.map((item, i) => {
              JuteGateEntryLineItemsData.push({
                id: i,
                recId: item.recId,
                hdrId: item.hdrId,
                quantity: item.actualQuality,
                advisedJuteTyp: item.advisedJuteTyp,
                actualJuteTyp: item.actualJuteTyp,
                advisedQuality: item.advisedQuality,
                actualQuality: item.actualQuality,
                advisedQuantity: item.advisedQuantity,
                actualQuantity: item.actualQuantity,
                receivedIn: item.receivedIn,
                autoDateTime: item.autoDateTime,
                unitId: item.unitId,
                remarks: item.remarks,
                kgs: item.kgs,
                poLineItemNum: item.poLineItemNum,
                createdBy: item.createdBy,
                isActive: item.isActive,
                qcJuteType: item.qcJuteType,
                qcJuteQuality: item.qcJuteQuality,
                quantity: item.qcJuteQuantity,
                advisedWeight: item.advisedWeight,
                actualWeight: item.actualWeight,
                qcJuteWeight: item.qcJuteWeight,
                allowableMoisture: item.allowableMoisture,
                readings: item.readings,
                actualQualityDesc: item.actualQualityDesc,
                advisedQualityDesc: item.advisedQualityDesc,
                itemDesc: item.itemDesc,
                itemId: item.actualJuteTyp,
                qualityCode: item.actualQuality,
                qualityCodeName: item.actualQualityDesc,
                itemCode : item.itemGroupId + item.actualQuality,
                advisedItemDesc: item.advisedItemDesc,
                avgReading: limitDecimals(item.avgReadings, 2),
                qcJuteTypeDesc: item.qcJuteTypeDesc,
                qcJuteQualityDesc: item.qcJuteQualityDesc,
                QuantityListData: [],
                itemGroupId:item.itemGroupId
              });
            });
            JuteGateEntryLineItemsData.push({
              id: JuteGateEntryLineItemsData.length + 1,
              recId: "",
              hdrId: "",
              advisedJuteTyp: "",
              actualJuteTyp: "",
              advisedQuality: "",
              actualQuality: "",
              advisedQuantity: "",
              actualQuantity: "",
              receivedIn: "",
              autoDateTime: "",
              unitId: "",
              remarks: null,
              kgs: null,
              poLineItemNum: "",
              createdBy: "",
              isActive: "",
              qcJuteType: "",
              qcJuteQuality: "",
              qcJuteQuantity: "",
              advisedWeight: null,
              actualWeight: "",
              qcJuteWeight: "",
              allowableMoisture: null,
              readings: [],
              actualQualityDesc: "",
              advisedQualityDesc: "",
              itemDesc: "",
              itemId: "",
              qualityCode: "",
              qualityCodeName: "",
              itemCode:"",
              advisedItemDesc: "",
              avgReadings: "",
              qcJuteTypeDesc: "",
              qcJuteQualityDesc: "",
              unitId: "",
              QuantityListData: [],
              itemGroupId:""
            });
            yield put(LineItemsGateEntryPros(JuteGateEntryLineItemsData));
          }
        }
        if (response.data.entryHeader) {
          if (!isEmpty(response.data.entryHeader.inDate)) {
            var inDate = response.data.entryHeader.inDate.split("T");
          }
          if (!isEmpty(response.data.entryHeader.inTime)) {
            var inTime = response.data.entryHeader.inTime.split("T")[1];
            var inTimes = inTime.split(".");
          }
          var OpenButton = false;
          var OutButton = false;
          var UpdateButton = true;
          var EditButton = true;
          if (isEmpty(response.data.entryHeader.outDate)) {
            OutButton = true;
          }
          const Header = {
            id: response.data.entryHeader.id,
            chalanNo: response.data.entryHeader.chalanNo,
            chalanDate: response.data.entryHeader.chalanDate,
            vehicleNo: response.data.entryHeader.vehicleNo,
            driverName: response.data.entryHeader.driverName,
            brokerName: response.data.entryHeader.brokerName,
            transporter: response.data.entryHeader.transporter,
            brokerAddress: response.data.entryHeader.brokerAddress,
            inDate: inDate[0],
            updateBy: response.data.entryHeader.updateBy,
            inTime: response.data.entryHeader.inTime,
            outTime: response.data.entryHeader.outTime,
            outDate: response.data.entryHeader.outDate,
            updateDateTime: response.data.entryHeader.updateDateTime,
            finYear: response.data.entryHeader.finYear,
            mukam: response.data.entryHeader.mukam,
            mukamName: response.data.entryHeader.mukamName,
            poNo: response.data.entryHeader.poNo,
            poDate:response.data.entryHeader.poDate,
            netWeight: response.data.entryHeader.netWeight,
            grossWeight: limitDecimals(
              Number(response.data.entryHeader.grossWeight) * 100,
              2
            ),
            grossWeightQtl: response.data.entryHeader.grossWeight,
            actualWeight: limitDecimals(
              Number(response.data.entryHeader.actualWeight) * 100,
              2
            ),
            actualWeightQtl: response.data.entryHeader.actualWeight,
            mrNo: response.data.entryHeader.mrNo,
            suppCode: response.data.entryHeader.suppCode,
            supplierName: response.data.entryHeader.supplierName,
            challanWeight: limitDecimals(
              Number(response.data.entryHeader.challanWeight) * 100,
              2
            ),
            challanWeightQtl: response.data.entryHeader.challanWeight,
            vehicleType: response.data.entryHeader.vehicleType,
            qcCheck: response.data.entryHeader.qcCheck,
            createdBy: response.data.entryHeader.createdBy,
            tareWeight: limitDecimals(
              Number(response.data.entryHeader.tareWeight) * 100,
              2
            ),
            tareWeightQtl: response.data.entryHeader.tareWeight,
            remarks: response.data.entryHeader.remarks,
            withorWithOutPo: response.data.entryHeader.withorWithOutPo,
            companyId: response.data.entryHeader.companyId,
            unitConversion: response.data.entryHeader.unitConversion,
            status: response.data.entryHeader.status,
            entryCompSeq: response.data.entryHeader.entryCompSeq,
            consignmentDate: response.data.entryHeader.consignmentDate,
            consignmentNo: response.data.entryHeader.consignmentNo,
            branchId: response.data.entryHeader.branchId,
            branchName: response.data.entryHeader.branchName,
            poHdrId: response.data.entryHeader.poHdrId,
            challanDateDesc: response.data.entryHeader.challanDateDesc,
            createdDate: response.data.entryHeader.createdDate,
            createdTime: response.data.entryHeader.createdTime,
            outTimeDesc: response.data.entryHeader.outTimeDesc,
            outDateDesc: response.data.entryHeader.outDateDesc,
            statusDesc: response.data.entryHeader.statusDesc,
            consignmentDateDesc: response.data.entryHeader.consignmentDateDesc,
            indentHdrId: response.data.entryHeader.indentHdrId,
            source: response.data.entryHeader.source,
            JuteGateEntryType:
              response.data.entryHeader.withorWithOutPo == 1
                ? "WITHPO"
                : "WITHOUTPO",
            supplierId: response.data.entryHeader.supplierId,
            files_List: [],
            OpenButton: OpenButton,
            OutButton: OutButton,
            UpdateButton: UpdateButton,
            EditButton: EditButton,
          };
          yield put(getJuteGateEntryHeader(Header));
        }
      }
    }
    yield put(juteGateEntryViewByIdSuccessfull({ response }));
  } catch (error) {
    hideSpinner();
    swal("error:" + error, { icon: "danger" });
  }
}

function* getJuteQualityByItemData({ payload: { url, rowId } }) {
  console.log(url, rowId);
  const { JuteGateEntrylineItems } = yield select(
    (state) => state.JuteGateEntryReducer
  );
  try {
    const response = yield call(getListurl, url, rowId);
    if (response) {
      if (response.data) {
        if (response.data.length !== 0) {
          yield put(getQualityByItemSuccessfull({ response }));
          if (response) {
            let ListFilter = response.data.filter((data) => {
              if (data.value !== "0") {
                return data;
              }
            });
            var list = [];
            ListFilter.map((prop) => {
              list.push({
                label: prop.label,
                name: prop.name,
                value: prop.value,
              });
            });

            if (JuteGateEntrylineItems) {
              var LineItemsRecords = [];
              if (JuteGateEntrylineItems.length !== 0) {
                LineItemsRecords = JuteGateEntrylineItems.map((item) => {
                  if (rowId === item.id) {
                    item.QuantityListData = list;
                  }
                  return item;
                });
                yield put(LineItemsGateEntryPros(LineItemsRecords));
              }
            }
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* getAllApprovedPoData({ payload: { url, history } }) {
  try {
    showSpinner();
    const response = yield call(getListurl, url);
    yield put(getAllApprovedPOSuccessfull({ response }));
    if (response) {
      hideSpinner();
    }
  } catch (error) {
    swal("error:" + error, { icon: "danger" });
    console.log(error);
    hideSpinner();
  }
}
function* jutePoViewByIdForGateEntryData({ payload: { url, data, history } }) {
  const { JuteGateEntryHeader } = yield select(
    (state) => state.JuteGateEntryReducer
  );
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    if (response) {
      console.log("ashgdgdhsf", response);

      if (response) {
        if (response.poHeader) {
          hideSpinner();
          yield put(
            getSupplierByMukamIdList(
              serverApi.GET_SUPPLIER_BY_MUKAM_ID +
                response.poHeader.mukam +
                "/company/" +
                localStorage.getItem("companyId")
            )
          );
        }
        if (response.poLineItemVo) {
          if (response.poLineItemVo.length !== 0) {
            var JuteGateEntryLineItemsData = [];
            response.poLineItemVo.map((item, i) => {
              JuteGateEntryLineItemsData.push({
                id: i,
                recId: "",
                hdrId: "",
                quantity: item.quantity,
                advisedJuteTyp: item.itemId,
                actualJuteTyp: item.itemId,
                advisedQuality: item.qualityCode,
                actualQuality: item.qualityCode,
                advisedQuantity: item.quantity,
                actualQuantity: item.quantity,
                qcJuteQuantity: item.quantity,
                receivedIn: "",
                autoDateTime: "",
                unitId: item.unitId,
                remarks: "",
                kgs: "",
                poLineItemNum: item.id,
                createdBy: "",
                isActive: item.isActive,
                qcJuteType: item.itemId,
                qcJuteQuality: item.qualityCode,
                quantity: "",
                advisedWeight: "",
                actualWeight: item.actualQuantity,
                qcJuteWeight: item.actualQuantity,
                allowableMoisture: "",
                readings: "",
                actualQualityDesc: "",
                advisedQualityDesc: "",
                itemDesc: item.itemDesc,
                itemId: item.itemId,
                qualityCode: item.qualityCode,
                qualityCodeName: item.juteQuality,
                itemGroupId:item.itemGroupId,
                itemCode : item.itemGroupId + item.qualityCode,
                advisedItemDesc: "",
                avgReading: "",
                qcJuteTypeDesc: "",
                qcJuteQualityDesc: "",
                QuantityListData: []             
              });
            });
            JuteGateEntryLineItemsData.push({
              id: JuteGateEntryLineItemsData.length + 1,
              recId: "",
              hdrId: "",
              advisedJuteTyp: "",
              actualJuteTyp: "",
              advisedQuality: "",
              actualQuality: "",
              advisedQuantity: "",
              actualQuantity: "",
              qcJuteQuantity: "",
              receivedIn: "",
              autoDateTime: "",
              unitId: "",
              remarks: null,
              kgs: null,
              poLineItemNum: "",
              createdBy: "",
              isActive: "",
              qcJuteType: "",
              qcJuteQuality: "",
              qcJuteQuantity: "",
              advisedWeight: null,
              actualWeight: "",
              qcJuteWeight: "",
              allowableMoisture: null,
              readings: [],
              actualQualityDesc: "",
              advisedQualityDesc: "",
              itemDesc: "",
              itemId: "",
              qualityCode: "",
              qualityCodeName: "",
              advisedItemDesc: "",
              avgReadings: "",
              qcJuteTypeDesc: "",
              qcJuteQualityDesc: "",
              unitId: "",
              QuantityListData: [],
            });
            yield put(LineItemsGateEntryPros(JuteGateEntryLineItemsData));
          }
        }
        if (response.poHeader) {
          let  poDate =""
          if(!isEmpty(response.poHeader.poDate)){
              poDate =  moment(response.poHeader.poDate, "DD-MM-YYYY").format(
              "YYYY-MM-DD"
            );
          }
          const Header = {
            ...JuteGateEntryHeader,
            JuteGateEntryType: "WITHPO",
            supplierId: response.poHeader.supplierId,
            suppCode: response.poHeader.suppCode,
            supplierName: response.poHeader.suppName,
            mukam: response.poHeader.mukam,
            mukamName: response.poHeader.mukamName,
            branchId: response.poHeader.branchId,
            branchName: response.poHeader.branchname,
            poNo: response.poHeader.id,
            unitConversion:response.poHeader.juteUnit,
            poDate:poDate
          };
          yield put(getJuteGateEntryHeader(Header));
        }
      }
    }
    yield put(jutePoViewByIdForGateEntrySuccessfull({ response }));
  } catch (error) {
    hideSpinner();
    swal("error:" + error, { icon: "danger" });
  }
}
function* getJuteGateEntryUploadFileData({
  payload: { url, data,id },
}) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(JuteGateEntryUploadFilesSuccessfull({ response }));
    if (response) {
      yield put(
        JuteGateEntryGetAllFiles(
          serverApi.GET_ALL_FILES +
            "20/" +
            id +
            "/" +
            localStorage.getItem("companyId"),
        )
      );
      if(response.status){
        swal("Uploaded Successfully", { icon: "success" });
      }
    }
   
  } catch (error) {
    console.log(error);
  }
}
function* JuteGateEntrygetAllFileList({
  payload: { url, data, history, type },
}) {
  try {
    const response = yield call(getListurl, url, data);
    yield put(JuteGateEntryGetAllFilesSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getJuteGateEntryDeleteFileData({ payload: { url,id } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(JuteGateEntryDeleteFilesSuccessfull({ response }));
    if (response.data.message == "Success.") {
      yield put(
        JuteGateEntryGetAllFiles(
          serverApi.GET_ALL_FILES +
            "20/" +
            id +
            "/" +
            localStorage.getItem("companyId"),
        )
      );
      swal("Successfully Deleted", { icon: "success" });
    }
  } catch (error) {
    console.log(error);
  }
}
export function* watchJuteGateEntryList() {
  yield takeEvery(JUTE_GATE_ENTRY_LIST, JuteGateEntryListSagaList);
}

export function* watchJuteGateEntryCreate() {
  yield takeEvery(JUTE_GATE_ENTRY_CREATE, getJuteGateEntryUpdate);
}

export function* watchJuteWorkEntryViewById() {
  yield takeEvery(JUTE_GATE_ENTRY_VIEW_BY_ID, ViewByIdWorkEntry);
}
export function* watchJuteQualityByItemData() {
  yield takeEvery(GET_QUALITY_BY_ITEM, getJuteQualityByItemData);
}
export function* watchGetAllApprovedPoData() {
  yield takeEvery(GET_ALL_APPROVED_PO, getAllApprovedPoData);
}
export function* watchjutePoViewByIdForGateEntryData() {
  yield takeEvery(
    JUTE_PO_VIEW_BY_ID_FOR_GATE_ENTRY,
    jutePoViewByIdForGateEntryData
  );
}
export function* watchgetJuteGateEntryUploadFileData() {
  yield takeEvery(JUTE_GATE_ENTRY_UPLOAD_FILE, getJuteGateEntryUploadFileData);
}
export function* watchJuteGateEntrygetAllFileList() {
  yield takeEvery(JUTE_GATE_ENTRY_GET_ALL_FILES, JuteGateEntrygetAllFileList);
}
export function* watchgetJuteGateEntryDeleteFileData() {
  yield takeEvery(JUTE_GATE_ENTRY_DELETE_FILE, getJuteGateEntryDeleteFileData);
}

function* JuteGateEntrySaga() {
  yield all([
    fork(watchJuteGateEntryList),
    fork(watchJuteGateEntryCreate),
    fork(watchJuteWorkEntryViewById),
    fork(watchJuteQualityByItemData),
    fork(watchGetAllApprovedPoData),
    fork(watchjutePoViewByIdForGateEntryData),
    fork(watchgetJuteGateEntryUploadFileData),
    fork(watchJuteGateEntrygetAllFileList),
    fork(watchgetJuteGateEntryDeleteFileData),
  ]);
}

export default JuteGateEntrySaga;
