import { Component } from "react";
import { Grid, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PieChart from "../../../components/HRMSModule/JobApplicationSummaryFun";
import DoughnutChartForTodaysLeave from "../../../components/HRMSModule/DoughnutChartForTodaysLeave";
import {
  hrmsDashboard,
  getListRowData,
} from "../../../store/HRMS/EmployeeDataBase/actions";

import { serverApi } from "../../../helpers/Consts";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      widthA: "50",
      widthF: "",
      widthM: "",
      hrms_dashboard_data: [],
      dashBoardMonthlyAttendanceDetail: [],
      dashBoardEmployeeDetails: {
        totalEmployees: "",
        withPf: "",
        withEsi: "",
        withoutBankAccount: "",
        users: "",
        contract: "",
        onRoll: "",
      },
      dashBoardBirthdayDetails: [],
      dashBoardOnRollDetails: [],
      dashBoardLeaveDetails: [],
    };
  }
  componentDidMount() {
    this.props.getListRowData(null);
    const startdate = moment().startOf("month").format("YYYY-MM-DD");
    const enddate = moment().endOf("month").format("YYYY-MM-DD");
    const data = {
      userId: this.state.userDit.userId,
      companyId: localStorage.getItem("companyId"),
      fromDate: startdate,
      toDate: enddate,
    };
    this.props.hrmsDashboard(
      serverApi.HRMS_DASHBOARD_DATA,
      data,
      this.props.history
    );
  }
  componentWillReceiveProps(props) {
    var dashBoardOnRollDetails = [];
    let dashBoardLeaveDetails = [];
    if (props.HrmsDashboardData) {
      if (props.HrmsDashboardData.data) {
        props.HrmsDashboardData.data.map((data) => {
          this.setState({
            totalEmployees: data.dashBoardEmployeeDetails.totalEmployees,
            onRoll: data.dashBoardEmployeeDetails.onRoll,
            contract: data.dashBoardEmployeeDetails.contract,
            withEsi: data.dashBoardEmployeeDetails.withEsi,
            withPf: data.dashBoardEmployeeDetails.withPf,
            withoutBankAccount:
              data.dashBoardEmployeeDetails.withoutBankAccount,
            users: data.dashBoardEmployeeDetails.users,
            dashBoardBirthdayDetails: data.dashBoardBirthdayDetails,
            dashBoardLeaveDetails: data.dashBoardLeaveDetails,
            dashBoardMonthlyAttendanceDetail:
              data.dashBoardMonthlyAttendanceDetails,
          });
          if (data.dashBoardOnRollDetails) {
            let i = 0;
            let arrcolor = [
              "#003B5A",
              "#D1E4EE",
              "#9BC3D8",
              "#5EA6CC",
              "#1B668E",
            ];
            data.dashBoardOnRollDetails.map((row) => {
              if (i > 4) {
                i = 0;
              }
              row.color = arrcolor[i];
              dashBoardOnRollDetails.push(row);
              i++;
            });
          }
          if (data.dashBoardLeaveDetails) {
            let i = 0;
            let arrcolor = ["#003B5A", "#FE7A7A", "#C5DBE7"];
            data.dashBoardLeaveDetails.map((row) => {
              if (i > 2) {
                i = 0;
              }
              row.color = arrcolor[i];
              dashBoardLeaveDetails.push(row);
              i++;
            });
          }
          this.setState({
            dashBoardOnRollDetails: dashBoardOnRollDetails,
            dashBoardLeaveDetails: dashBoardLeaveDetails,
          });

          data.dashBoardMonthlyAttendanceDetails.map((item) => {
            if (item.attendanceType === "A") {
              this.setState({
                widthA: item.attendanceCount,
              });
            }
            if (item.attendanceType === "F") {
              this.setState({
                widthF: item.attendanceCount,
              });
            }
            if (item.attendanceType === "M") {
              this.setState({
                widthM: item.attendanceCount,
              });
            }
          });
        });
      }
    }
  }

  render() {
    let onRollEmployees = this.state.dashBoardOnRollDetails;
    let leaveDetails = this.state.dashBoardLeaveDetails;

    return (
      <div>
        <Grid spacing={2}>
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={2}
            className="hrmsEmployeeDashboardSection"
          >
            <Box gridColumn="span 6">
              <Grid spacing={2}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                  className="employeeDashboardLiftSideContainer"
                >
                  {/* Total Employees with different department */}
                  <Box gridColumn="span 12">
                    <div className="employeeCategorySection">
                      <h3>Employee Categories</h3>
                      <Grid spacing={2}>
                        <Grid>
                          <Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={2}
                            className="employeeDashboard"
                          >
                            <Box gridColumn="span 6">
                              <Grid spacing={2}>
                                <Grid>
                                  <Box
                                    display="grid"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                    gap={2}
                                    className="employeeDashboard"
                                  >
                                    <Box gridColumn="span 12">
                                      <Card className="dashBoardTotalEmployee">
                                        <CardContent>
                                          <span>
                                            {this.state.totalEmployees}
                                          </span>
                                          <p>Total Employees</p>
                                        </CardContent>
                                      </Card>
                                    </Box>
                                    <Box gridColumn="span 6">
                                      <Card className="employeeWithOnrollandContract">
                                        <CardContent>
                                          <span>{this.state.onRoll}</span>
                                          <p>On-roll</p>
                                        </CardContent>
                                      </Card>
                                    </Box>
                                    <Box gridColumn="span 6">
                                      <Card className="employeeWithOnrollandContract">
                                        <CardContent>
                                          <span>{this.state.contract}</span>
                                          <p>Contract</p>
                                        </CardContent>
                                      </Card>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                            <Box gridColumn="span 6">
                              <Grid spacing={2}>
                                <Grid>
                                  <Box
                                    display="grid"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                    gap={2}
                                    className="employeeDashboard"
                                  >
                                    <Box gridColumn="span 6">
                                      <Card className="employeeWithESI">
                                        <CardContent>
                                          <span>{this.state.withEsi}</span>
                                          <p>With ESI</p>
                                        </CardContent>
                                      </Card>
                                    </Box>
                                    <Box gridColumn="span 6">
                                      <Card className="employeeWithESI">
                                        <CardContent>
                                          <span>{this.state.withPf}</span>
                                          <p>With PF</p>
                                        </CardContent>
                                      </Card>
                                    </Box>
                                    <Box gridColumn="span 6">
                                      <Card className="employeeWithESI">
                                        <CardContent>
                                          <span>
                                            {this.state.withoutBankAccount}
                                          </span>
                                          <p>Without Bank Account</p>
                                        </CardContent>
                                      </Card>
                                    </Box>
                                    <Box gridColumn="span 6">
                                      <Card className="users">
                                        <CardContent>
                                          <span>{this.state.users}</span>
                                          <p>Users</p>
                                        </CardContent>
                                      </Card>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                  {/* on-roll Employees pie Graph*/}
                  <Box gridColumn="span 12">
                    <Grid item xs={12} className="jobApplicationSummaryBlock">
                      <div className="applicationInfo">
                        <h3>On-Roll Employees</h3>
                      </div>
                      <PieChart onRollEmployees={onRollEmployees}  onRoll={this.state.onRoll}/>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Box>
            <Box gridColumn="span 6">
              <Grid spacing={2}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                  className="employeeDashboardRightSideContainer"
                >
                  {/* Monthly Attendance Average */}
                  <Box gridColumn="span 12">
                    <div className="monthlyAttendanceAverageBlock">
                      <div className="monthAverageAndDropdown">
                        <h3>Monthly Attendance Average</h3>

                        <div className="attendanceThisMonth">
                          <p>Month to Date</p>
                        </div>
                      </div>
                      <Grid spacing={2}>
                        <Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={6}
                          className="attendancePrograssBlock"
                        >
                          {this.state.dashBoardMonthlyAttendanceDetail.map(
                            (demo, index) => (
                              <Box gridColumn="span 4">
                                <div
                                  className="headingAndProgressbar"
                                  key={index}
                                >
                                  <div className="progressBarBgContainer">
                                    <div className="progressBarBgSubContainer">
                                      <p>
                                        <span>
                                          {demo.attendanceCount
                                            ? demo.attendanceCount
                                            : 0}
                                        </span>
                                        <div>{this.state.totalEmployees}</div>
                                      </p>
                                    </div>
                                    <div className="progressBarBgSubContainer">
                                      <div className="progressBarBg">
                                        <div
                                          className="bar"
                                          style={{
                                            width:
                                              "" +
                                              Math.round(
                                                ((demo.attendanceCount
                                                  ? demo.attendanceCount
                                                  : 0) /
                                                  this.state.totalEmployees) *
                                                  100
                                              ) +
                                              "%",
                                          }}
                                        />
                                      </div>
                                      <p>
                                        {demo.attendanceType == "A"
                                          ? "APP"
                                          : demo.attendanceType == "F"
                                          ? "Facial"
                                          : "Manual"}
                                      </p>
                                      <p className="percentage">
                                        {this.state.widthA}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Box>
                            )
                          )}
                        </Box>
                      </Grid>
                    </div>
                  </Box>
                  {/*Today Leaves */}
                  <Box gridColumn="span 12">
                    <Grid item xs={12} className="todayLeavesContainer">
                      <div className="applicationInfo">
                        <h3>Today's Leaves</h3>
                      </div>

                      <DoughnutChartForTodaysLeave
                        leaveDetails={leaveDetails}
                      />
                    </Grid>
                  </Box>
                  {/* Upcoming events */}
                  <Box gridColumn="span 12">
                    <div className="upcomingEventsSection">
                      <div className="eventContainer">
                        <h3>Upcoming Events</h3>

                        <div className="upcomingEventsMonth">
                          <p>This month</p>
                        </div>
                      </div>
                      <h5>Birthdays in This Month</h5>
                      <div className="upcomingEventsBlock">
                        <table>
                          <tbody className="birthdayTable">
                            {this.state.dashBoardBirthdayDetails.map(
                              (item, ind) => {
                                let birthdaySplit = item.split(",");
                                return (
                                  <tr key={ind}>
                                    <td>{birthdaySplit[0]}</td>
                                    <td>{birthdaySplit[1]}</td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </div>
    );
  }
}
const mapStatetoProps = (state) => {
  const { HrmsDashboardData } = state.EmployeeReducer;
  return { HrmsDashboardData };
};

export default connect(mapStatetoProps, {
  hrmsDashboard,
  getListRowData,
})(withRouter(Dashboard));
