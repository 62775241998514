import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Box, Button } from "@mui/material";
import CustomButton from "../../../../../components/Buttons/Buttons";
import panUploadIcon from "../../../../../assets/images/panUploadIcon.png";
import DeleteIcon from "../../../../../assets/images/picEditIcon.png";
import TickIcon from "../../../../../assets/images/fileUploadedTick.png";
import { Link } from "react-router-dom";
import TextFieldNormal from "../../../../../components/TextField/TextFieldNormal";
import { serverApi } from "../../../../../helpers/Consts";
import {
  deleteEducationalDetails,
  setEducationalList,
  clearProfileData,
} from "../../../../../store/HRMS/EmployeeDataBase/actions";

import DynamicSelectNew from "../../../../../components/Dropdown/DropdownSelectNew";
import {
  viewbyidPersonalDetails,
  setFileUpload,
} from "../../../../../store/HRMS/EmployeeDataBase/actions";
import swal from "sweetalert";
import moment from "moment";

class EducationalDetails extends Component {
  constructor() {
    super();
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),

      companyId: localStorage.getItem("companyId"),
      documentName: "",
      university: "",
      year: "",
      educationalData: [],
      response: [],
      selecteddocFile: "",
      docId: "",
      tableData: [],
      handleNext: false,
    };
  }
  onhandleQualification = (data) => (event) => {
    const { key, i } = data;
    this.setState({ [key]: event });
    if (data.key === data.i + 1) {
      this.state.tableData[i].eduType = event;
    }
  };
  onhandleUniversity = (data) => (event) => {
    const { key, i } = data;

    if (data.key === data.i + 1) {
      this.state.tableData[i].edu_institute = event;
    }
    this.setState({
      tableData: this.state.tableData,
    });
  };
  onhandlePercentage = (data) => (event) => {
    const { key, i } = data;
    if (event > 100) {
      swal("Percentage shouldn't be greater than 100");
      if (data.key === data.i + 1) {
        this.state.tableData[i].edu_percentage = "";
      }
      this.setState({
        tableData: this.state.tableData,
      });
    } else {
      if (data.key === data.i + 1) {
        this.state.tableData[i].edu_percentage = event;
      }
      this.setState({
        tableData: this.state.tableData,
      });
    }
  };
  onhandleYear = (data) => (event) => {
    const { key, i } = data;
    if (event > moment().format("YYYY")) {
      swal("Year of Completion Shouldn't be Greater than  Current Year");
      if (data.key === data.i + 1) {
        this.state.tableData[i].year_of_passing = "";
      }
      this.setState({
        tableData: this.state.tableData,
      });
    } else {
      if (data.key === data.i + 1) {
        this.state.tableData[i].year_of_passing = event;
      }
      this.setState({
        tableData: this.state.tableData,
      });
    }
  };
  handleSelect = (selectedValue, selectedName, name, selectedRow, i) => {
    if (name === selectedRow.education_id) {
      selectedRow.edu_type = selectedName;
    }
    var tabdata = this.state.tableData.filter((row) => {
      if (row.education_id == name) {
        row = selectedRow;
      }
      return row;
    });

    this.setState({ tableData: tabdata });
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    this.props.viewbyidPersonalDetails(
      serverApi.VIEW_EMPLOYEE_EDUCATION_DIT + "/" + this.props.getEbId,
      this.props.history
    );
    this.props.clearProfileData(null);
  }
  componentWillReceiveProps(props) {
    var ebId = this.props.getEbId;
    if (ebId > 0) {
      if (props.personalDetails) {
        if (props.personalDetails.data) {
          if (props.personalDetails.data.length > 0) {
            var personal = props.personalDetails.data;
            if (personal) {
              let edId = 1;
              let tableData = personal.map((data) => {
                data.educationId = edId;
                edId = Number(edId) + 1;
                return data;
              });
              if (this.state.tableData.length > 0) {
                this.setState({ tableData: tableData });
              } else {
                this.setState({ tableData: tableData });
              }
            }
          } else {
            var obj = {
              education_id: 1,
              educationId: 1,
              type: true,
              company_id: localStorage.getItem("companyId"),
              created_by: JSON.parse(localStorage.getItem("authUser")).userId,
              eb_id: this.props.getEbId,
              edu_institute: "",
              edu_percentage: "",
              edu_type: "",
              year_of_passing: "",
              display_name: "",
              task_unique_id: "",
            };
            if (this.state.tableData.length > 0) {
            } else {
              this.state.tableData.push(obj);
            }
          }
        }
      }
    }
  }

  handleAddRow = () => {
    var tableDataAdd = [];
    var education_id = 0;
    var educationId = 0;
    this.state.tableData.map((row) => {
      tableDataAdd.push(row);
      education_id = row.education_id;
      educationId = Number(educationId) + 1;
    });
    const res = {
      education_id: education_id + 1,
      educationId: educationId + 1,
      type: true,
      company_id: localStorage.getItem("companyId"),
      created_by: JSON.parse(localStorage.getItem("authUser")).userId,
      eb_id: this.props.getEbId,
      edu_institute: "",
      edu_percentage: "",
      edu_type: "",
      year_of_passing: "",
      display_name: null,
      task_unique_id: "",
      created_date_time: "",
      emp_code: "",
      is_active: "",
      update_by: "",
      update_date_time: "",
    };
    tableDataAdd.push(res);
    this.setState({
      tableData: tableDataAdd,
    });
  };

  onClickNext = () => {
    //  Educational details API
    const mdata = [];
    this.state.tableData.map((row) => {
      if (row.type == true) {
        row.is_active = 1;
        var obj = {
          ebId: row.eb_id,
          empCode: "",
          eduType: row.edu_type,
          yearOfPassing: row.year_of_passing,
          eduInstitute: row.edu_institute,
          eduPercentage: row.edu_percentage,
          createdBy: row.created_by,
          companyId: row.company_id,
        };
        mdata.push(obj);
      }
    });
    this.props.setEducationalList(
      serverApi.EDUCATIONAL_DETAILS,
      mdata,
      this.props.history,
      this.props.getEbId
    );
    this.props.onvalue(4, this.props.getEbId);
  };

  handleselectedFile = (key, id) => (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 42);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", id);
    formData.append("createdBy", this.state.userDit.userId);
    formData.append("fileCompanyId", this.state.companyId);
    this.setState({
      fileName: file.name,
      fileExtension: file.name.split(".").pop(),
    });
    if (key === "UploadDoc") {
      this.setState({
        selectedFile: e.target.files[0],
        selectedFileName: e.target.files[0].name,
      });
    }
    var tableData = this.state.tableData.filter((row) => {
      if (row.education_id == id) {
        row.display_name = file.name;
        row.task_unique_id = id;
      }
      return row;
    });
    this.setState({ tableData: tableData });
    this.props.setFileUpload(
      serverApi.UPLOADFILE,
      formData,
      this.props.history,
      42,
      this.props.getEbId
    );
  };
  handleDeleteFile = (key, id) => (e) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (key === "deleteFile") {
          this.state.tableData.map((item) => {
            if (item.education_id == id) {
              this.props.deleteEducationalDetails(
                serverApi.DELETE_FILE_BI_ID + item.file_upload_id,
                this.props.history,
                5,
                this.props.getEbId
              );
            }
          });
        }
      } else {
        swal("Your action is canceled!");
      }
    });
  };
  handleDeleteRow = (key, id) => (e) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (key === "deleteFile") {
          this.state.tableData.filter((item) => {
            if (item.education_id === id) {
              item.display_name = "";
              item.task_unique_id = "";
              this.setState({
                selectedFile: "",
                selecteddocFile: "",
                task_unique_id: id,
              });
              this.props.deleteEducationalDetails(
                serverApi.DELETE_EDUCATION_DETAILS + id,
                this.props.history
              );
              return item;
            }
          });

          var tableData = this.state.tableData.filter((item) => {
            if (item.education_id === id) {
            } else {
              return item;
            }
          });
          this.setState({
            tableData: tableData,
          });
        }
      } else {
        swal("Your action is canceled!");
      }
    });
  };
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div className="educationalDetailsBlock">
        <h1>Educational Details</h1>
        <div
          className="gridContainer educational_details_table"
          onScroll={this.handleScroll}
        >
          <div className="gridComponent">
            <table>
              <tr>
                <th style={{ width: "20%" }}>Qualification</th>
                <th style={{ width: "20%" }}>School/University</th>
                <th style={{ width: "20%" }}>Percentage</th>
                <th style={{ width: "20%" }}>Year of Completion</th>
                <th style={{ width: "20%" }}>Upload Certificates</th>
                <th style={{ width: "5%" }}>Actions</th>
              </tr>
              {this.state.tableData.map((data, i) => (
                <tr key={i}>
                  <td>
                    <DynamicSelectNew
                      update={data.edu_type ? 1 : 0}
                      arrayOfData={education_type}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelect}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={data.edu_type}
                      row={data}
                      index={i}
                      name={data.education_id}
                    />
                  </td>
                  <td>
                    <TextFieldNormal
                      value={data.edu_institute}
                      onChange={this.onhandleUniversity({
                        key: data.educationId,
                        i,
                      })}
                      type="text"
                    />
                  </td>
                  <td>
                    <TextFieldNormal
                      value={data.edu_percentage}
                      type="number"
                      onChange={this.onhandlePercentage({
                        key: data.educationId,
                        i,
                      })}
                    />
                  </td>
                  <td style={{ margin: "10px" }}>
                    <TextFieldNormal
                      value={data.year_of_passing}
                      type="number"
                      onChange={this.onhandleYear({ key: data.educationId, i })}
                    />
                  </td>
                  <td>
                    <div className="d-flex cloumnButtonBlock">
                      <Box gridColumn="span 3">
                        <div className="personalDetailsUpload">
                          <div className="ducumentUploadBlock">
                            <div className="documentUploadContent">
                              {data.display_name === null ? (
                                <span
                                  className={
                                    data.type
                                      ? "btn_upload documentUpload disabled"
                                      : "btn_upload documentUpload "
                                  }
                                >
                                  <img src={panUploadIcon} alt="" />
                                  <input
                                    type="file"
                                    id="imag"
                                    disabled={data.type}
                                    title=""
                                    className="input-img"
                                    onChange={this.handleselectedFile(
                                      "UploadDoc",
                                      data.education_id
                                    )}
                                  />
                                  Upload Document
                                </span>
                              ) : (
                                ""
                              )}
                              {data.display_name !== null ? (
                                <div className="uploadedInfo">
                                  <img
                                    src={TickIcon}
                                    alt=""
                                    className="tickIcon"
                                  />
                                  <p>{data.display_name}</p>
                                  <Button
                                    onClick={this.handleDeleteFile(
                                      "deleteFile",
                                      data.education_id
                                    )}
                                    className="deleteButton"
                                  >
                                    <img src={DeleteIcon} alt="" />
                                  </Button>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </Box>
                    </div>
                  </td>
                  <td>
                    <Button
                      onClick={this.handleDeleteRow(
                        "deleteFile",
                        data.education_id
                      )}
                      className="deleteButton"
                    >
                      <img
                        src={DeleteIcon}
                        alt=""
                        style={{ width: "35px", color: "red" }}
                      />
                    </Button>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>

        <span className="addMoreAction" onClick={this.handleAddRow}>
          + Add more
        </span>

        <div className="consoleFormButtonBlock">
          <Link to="/add_employee">
            <CustomButton label={"Cancel"} className="greenBorderButton" />
          </Link>
          {localStorage.getItem("EmpData") === null ? (
            <CustomButton
              label={"Save"}
              className="greenButton"
              handleClick={this.onClickNext}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickNext}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}
const education_type = [
  {
    value: "1",
    label: "10th",
    name: "10th",
  },
  {
    value: "2",
    label: "12th",
    name: "12th",
  },
  {
    value: "3",
    label: "Graduation",
    name: "Graduation",
  },
  {
    value: "4",
    label: "Post Graduation",
    name: "Post Graduation",
  },
  {
    value: "5",
    label: "Other Course",
    name: "Other Course",
  },
];
const mapStatetoProps = (state) => {
  const { personalDetails, getEbId } = state.EmployeeReducer;
  const { Upload_File_List } = state.UploadFileReducer;
  return {
    personalDetails,
    Upload_File_List,
    getEbId,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    clearProfileData,
    setFileUpload,
    deleteEducationalDetails,
    viewbyidPersonalDetails,
    setEducationalList,
  })(EducationalDetails)
);
