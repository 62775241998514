import React, { Component } from "react";
import { Grid, Box, Button, Tooltip } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi,serverConfig } from "../../../helpers/Consts";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import TextFieldEmailReq from "../../../components/TextField/TextFieldEmailReq";
import {
  createBranchMaster,
  updateBranchMaster,
} from "../../../store/Master/BranchMaster/actions";
import { getStateNameList } from "../../../store/Global/DropDownApis/actions";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import swal from "sweetalert";
import moment from "moment";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import TextFieldMobile from "../../../components/TextField/TextFieldMobile";
import searchIcon from "../../../assets/images/search.png";
import InputAdormentSearch from "../../../components/InputAdorment/InputAdormentSearch";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import DeleteIcon from "../../../assets/images/picEditIcon.png";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import { paySlipsByEbNo } from "../../../store/HRMS/PaySlip/actions";
import { postUploadFileList } from "../../../store/MyProfile/UploadFile/actions";
import { getFileDownload } from "../../../helpers/server.js";
import Fade from "@mui/material/Fade";
import { getBranchFiles,
  deleteBranchMasterFiles,
  UploadBranchMasterFiles
 } from "../../../store/Master/BranchMaster/actions";

class CreateBranchMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      createdBy: "",
      branchName: "",
      branchAddress: "",
      state: "",
      stateValue: "",
      stateCode: "",
      personName: "",
      contactNumber: "",
      email: "",
      gstNo: "",
      GstFileId:"",
      selectedGstFile: "",
      GstFileFileExtension:"",
      GstFileRetrivePath:"",
      showGst: false,
      ChequeId:"",
      selectedUploadCld_dCheque: "",
      chequeFileExtension:"",
      chequeRetrivePath:"",
      workerName: "",
      ebno: "",
      ebId: "",
      id: null,
      branchHead: "",
      createdOn: "",
      createdDate: "",
      stateNameList: [], //displaying state Name list data
    };
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getStateNameList(
      serverApi.GET_STATE_NAME_LIST,
      this.props.history
    ); // Calling state Name dropdown API
    if (this.props.location.state) {
      this.setState({
        branchAddress: this.props.location.state.rowData.address,
        bank: this.props.location.state.rowData.bank,
        bankId: this.props.location.state.rowData.bankId,
        branchName: this.props.location.state.rowData.brname,
        companyId: this.props.location.state.rowData.companyId,
        contactNumber: this.props.location.state.rowData.contactNo,
        createdBy: this.props.location.state.rowData.createdBy,
        createdName: this.props.location.state.rowData.createdName,
        createdOn: this.props.location.state.rowData.createdOn,
        ebId: this.props.location.state.rowData.ebId,
        branchHead: this.props.location.state.rowData.createdName,
        email: this.props.location.state.rowData.email,
        gstNo: this.props.location.state.rowData.gstNo,
        id: this.props.location.state.rowData.id,
        ifscCode: this.props.location.state.rowData.ifscCode,
        modifiedBy: this.props.location.state.rowData.modifiedBy,
        modifiedOn: this.props.location.state.rowData.modifiedOn,
        personAddress: this.props.location.state.rowData.personAddress,
        personName: this.props.location.state.rowData.personName,
        stateCode: this.props.location.state.rowData.stateCode,
        state: this.props.location.state.rowData.stateName,
      });
      if (this.props.location.state.rowData.gstNo !== "") {
        this.setState({ showGst: true });
      }
      const dateEntered = this.props.location.state.rowData.createdOn;
      const dateIsValid = moment(dateEntered).format("LLLL");
      this.setState({
        createdDate: dateIsValid,
      });
    }

    if (this.props.location.state) {
      if (this.props.location.state.rowData) {
        this.props.getBranchFiles(
          serverApi.GET_ALL_FILES +
          50 +
          "/"+
          this.props.location.state.rowData.id +
          "/" +
          localStorage.getItem("companyId"),
          this.props.history
        );
      }
    }
  }
  componentWillReceiveProps(props) {
    if (props.stateNameList) {
      this.setState({
        stateNameList: props.stateNameList.data, // updating state name list dropdown
      });
      if (props.stateNameList) {
        var stateData = props.stateNameList.data;
        if (stateData) {
          stateData.map((state) => {
            if (state.name !== undefined) {
              var pieces = state.name.split("^");
              if (this.state.stateCode == pieces[1]) {
                this.setState({
                  stateValue: state.value,
                });
              }
            }
          });
        }
      }
    }

    if (props.stateNameList.data) {
      var list = [];
      list = props.stateNameList.data.filter((item) => {
        if (item.value !== 0) {
          return item;
        }
      });
      this.setState({
        stateNameList: list,
      });
    }

    if (props.payslipData) {
      if (props.payslipData.data) {
        this.setState({
          personName: props.payslipData.data.workerName,
          contactNumber: props.payslipData.data.mobileNo,
        });
      } else {
        this.setState({
          personName: "",
          contactNumber: "",
        });
      }
    }
   
    if(props.GetAllFiles_Data){
      if(props.GetAllFiles_Data.data){
        if(props.GetAllFiles_Data.data.length !==0){
          props.GetAllFiles_Data.data.map((item)=>{
            if(item.subTaskUniqueId===521){
              if (item.displayName !== "") {
                this.setState({
                  showGst: true,
                });
              }
                this.setState({
                  selectedGstFile: item.displayName,
                  GstFileId: item.fileUploadId,
                  GstFileFileExtension:item.fileExtension,
                  GstFileRetrivePath:item.retrievalPath,
                }); 
            }
            if(item.subTaskUniqueId===522){
              this.setState({
                selectedUploadCld_dCheque: item.displayName,
                ChequeId: item.fileUploadId,
                chequeFileExtension:item.fileExtension,
                chequeRetrivePath:item.retrievalPath,
              }); 
            }
          })
        }else{
          this.setState({
            GstFileId:"",
            selectedGstFile: "",
            GstFileFileExtension:"",
            GstFileRetrivePath:"",
            ChequeId:"",
            selectedUploadCld_dCheque: "",
            chequeFileExtension:"",
            chequeRetrivePath:"",
          })
        }
      }
    }
  }
  /* Calling Create and Update Api's to Actions */
  onClickCreateUpdate = () => {
    // branch state and state code and state GST No
    const branchGstVal = this.state.gstNo;
    const stateCodeVal = this.state.stateCode;
    const compareBoth = branchGstVal.slice(0, 2); // "01"
    const { branchName, branchAddress, state, branchHead, email } = this.state;
    if (
      branchName !== "" &&
      branchName !== undefined &&
      branchAddress !== "" &&
      branchAddress !== undefined &&
      state !== "" &&
      state !== undefined &&
      branchHead !== "" &&
      branchHead !== undefined &&
      email !== "" &&
      email !== undefined
    ) {
    }

    if (branchName === "" || branchName === undefined) {
      swal("Please Enter the Branch Name");
      return false;
    }
    if (branchAddress === "" || branchAddress === undefined) {
      swal("Please Enter the Branch Address");
      return false;
    }
    if (state === "" || state === undefined) {
      swal("Please Select the State");
      return false;
    }

    if (email === "" || email === undefined) {
      swal("Please Enter the Email");
      return false;
    }

    if (stateCodeVal === compareBoth) {
      if (this.props.location.state === undefined) {
        const data = {
          branch: {
            brname: this.state.branchName,
            address: this.state.branchAddress,
            gstNo: this.state.gstNo,
            contactNo: this.state.contactNumber,
            personName: this.state.personName,
            personAddress: "",
            email: this.state.email,
            stateName: this.state.state,
            stateCode: this.state.stateCode,
            ebId: parseInt(localStorage.getItem("ebId")),
            createdBy: this.state.userDit.userId.toString(),
          },
          postAuthenticationDto: {
            cipher: this.state.userDit.cipher,
            companyId: localStorage.getItem("companyId"),
          },
        };
        this.props.createBranchMaster(
          serverApi.CREATE_BRANCH_MASTER,
          data,
          this.props.history
        ); // calling create branch master api
      } else {
        const data = {
          branch: {
            brname: this.state.branchName,
            address: this.state.branchAddress,
            id: this.state.id,
            gstNo: this.state.gstNo,
            contactNo: this.state.contactNumber,
            personName: this.state.personName,
            personAddress: "",
            email: this.state.email,
            stateName: this.state.state,
            stateCode: this.state.stateCode,
            ebId: parseInt(localStorage.getItem("ebId")),
            createdBy: this.state.createdBy.toString(),
            empCode: this.state.branchHead,
          },
          postAuthenticationDto: {
            cipher: this.state.userDit.cipher,
            companyId: localStorage.getItem("companyId"),
          },
        };
        this.props.updateBranchMaster(
          serverApi.UPDATE_BRANCH_MASTER,
          data,
          this.props.history
        ); // calling update branch master api
      }
    } else {
      swal(
        " first two letters of statecode and state GST number should be equal"
      );
    }
  };
  // function for previous page
  onClickCancel = () => {
    this.props.history.push("/branch_master");
  };
  // function for inputAdorment
  onClickAdorment = () => {
    this.props.paySlipsByEbNo(
      serverApi.PAYSLIP_DATA +
        "/" +
        this.state.branchHead +
        "/" +
        localStorage.getItem("companyId") +
        "/" +
        this.state.userDit.userId
    );
  };
  //function for TextFields
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "branchName") {
      this.setState({
        branchName: event,
      });
    }
    if (key === "branchAddress") {
      this.setState({
        branchAddress: event,
      });
    }
    if (key === "stateCode") {
      this.setState({
        stateCode: event,
      });
    }
    if (key === "personName") {
      const personletter = event.replace(/[^a-zA-Z\s]+/g, "");
      this.setState({
        personName: personletter,
      });
    }
    if (key === "contactNumber") {
      this.setState({
        contactNumber: event,
      });
    }
    if (key === "email") {
      this.setState({
        email: event,
      });
    }
    if (key === "gstNo") {
      if (event !== undefined && event !== "") {
        this.setState({ showGst: !this.showGst });
      } else {
        this.setState({ showGst: false });
      }
      this.setState({
        gstNo: event,
      });
    }
    if (key === "branchHead") {
      this.setState({
        branchHead: event,
      });
    }
  };
  // function for popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "state") {
      let stateCode = selectedName.split("^");
      this.setState({
        state: stateCode[0],
        stateValue: selectedValue,
        stateCode: stateCode[1],
      });
    }
  };
  // Uplaod document api Integration
  handleselectedFile = (key) => (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 50);
    formData.append("displayName", file.name);
    formData.append("createdBy", this.state.userDit.userId);
    formData.append("fileCompanyId", this.state.companyId);
    formData.append("taskUniqueId", this.props.location.state.rowData.id);

    this.setState({
      fileName: file.name,
      fileExtension: file.name.split(".").pop(),
    });
    this.props.UploadBranchMasterFiles(
      serverApi.UPLOADFILE,
      formData,
      this.props.history
    );
    if (key === "gstUpload") {
      this.setState({
        selectedGstFile: e.target.files[0].name,
      });
    }
    if (key === "uploadOtherDocuments") {
      this.setState({
        selectedUploadCld_dCheque: e.target.files[0].name,
      });
    }
  };
  // handleDeleteFile = (e) => {
  //   this.setState({
  //     selectedFile: "",
  //     GstFileId:"",
  //     selectedGstFile: "",
  //     showDocument: false,
  //     GstFileFileExtension:"",
  //     GstFileRetrivePath:"",
  //   });
  // };
  // handleDelete = (e) => {
  //   this.setState({
  //     ChequeId:"",
  //     selectedUploadCld_dCheque: "",
  //     chequeFileExtension:"",
  //     chequeRetrivePath:"",
  //   });
  // };

  handleDeleteFile = (key, fileUploadId) => {
  swal({
    title: "Are you sure?",
    text: "Do you want to delete",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      if (key === "gstFile") {
        this.setState({
          GstFileId:"",
            selectedGstFile: "",
            GstFileFileExtension:"",
            GstFileRetrivePath:"",
            showDocument: false,

        });
       
      }
      if (key === "chequeFile") {
        this.setState({
          ChequeId:"",
          selectedUploadCld_dCheque: "",
          chequeFileExtension:"",
          chequeRetrivePath:"",

        });
      
      }
      if (this.props.location.state !== undefined) {
        if (this.props.location.state.rowData !== undefined) {
          this.props.deleteBranchMasterFiles(
            serverApi.DELETE_FILE_BI_ID + fileUploadId,
            this.props.history,
            this.props.location.state.id
          );
          }
      }
    } else {
      swal("Your action is canceled!");
    }
  });
};
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.branchName}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("branchName")}
                      label="Branch Name"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.branchAddress}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("branchAddress")}
                      label="Branch Address"
                      disabled={this.state.branchName === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.stateNameList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.stateValue}
                        update={this.props.location.state ? 1 : 0}
                        name="state"
                        label="State"
                        required
                        isDisabled={
                          this.state.branchAddress === "" ? true : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.stateCode}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("stateCode")}
                      label="State Code"
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <InputAdormentSearch
                        imgUrl={searchIcon}
                        label="Branch Head"
                        handleChange={this.onhandlechangeValue("branchHead")}
                        onClick={this.onClickAdorment}
                        value={this.state.branchHead}
                        maxLength={15}
                        placeholder="Enter EB No."
                        mandatory="*"
                        disabled={
                          this.state.stateValue === ""
                            ? true
                            : false || this.state.stateValue === 0
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.personName}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("personName")}
                      label="Person Name"
                      disabled={
                        this.state.stateValue === ""
                          ? true
                          : false || this.state.stateValue === 0
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldMobile
                      value={this.state.contactNumber}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("contactNumber")}
                      label="Contact Number"
                      disabled={
                        this.state.stateValue === ""
                          ? true
                          : false || this.state.stateValue === 0
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldEmailReq
                      value={this.state.email}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("email")}
                      label="Email"
                      disabled={
                        this.state.stateValue === ""
                          ? true
                          : false || this.state.stateValue === 0
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.gstNo}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("gstNo")}
                      label="GST No"
                      disabled={
                        this.state.stateValue === ""
                          ? true
                          : false || this.state.stateValue === 0
                          ? true
                          : false
                      }
                    />
                  </Box>

                  {this.state.showGst ? (
                    <>
                      <Box gridColumn="span 3">
                        <div className="personalDetailsUpload">
                          <div className="ducumentUploadBlock">
                            <div className="documentUploadContent">
                              <label>Gst Document</label>

                              {this.state.selectedGstFile === "" ? (
                                <span className="btn_upload documentUpload">
                                  <img src={panUploadIcon} alt="" />
                                  <input
                                    type="file"
                                    id="imag"
                                    title=""
                                    className="input-img"
                                    onChange={this.handleselectedFile(
                                      "gstUpload"
                                    )}
                                  />
                                  Upload Other Documnets
                                </span>
                              ) : (
                                ""
                              )}
                              {this.state.selectedGstFile !== "" && (
                                <div className="uploadedInfo">
                                  <img
                                    src={TickIcon}
                                    alt=""
                                    className="tickIcon"
                                  />
                                   <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Click to download File"
                            >
                              <div
                                onClick={() => {
                                  getFileDownload(
                                    serverConfig.SERVER_URL +
                                    "security-api/api/files/downloadfile/" +
                                    this.state.GstFileId,
                                    this.state.selectedGstFile,
                                    this.state.GstFileFileExtension
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {/* {this.state.fileName} */}
                                {this.state.selectedGstFile}
                              </div>

                            </Tooltip>
                                 
                            <Button
                                onClick={() => {
                                  this.handleDeleteFile(
                                    "gstFile",
                                    this.state.GstFileId
                                  );
                                }}
                                className="deleteButton"
                              >
                                <img src={DeleteIcon} alt="" />
                              </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Box>
                    </>
                  ) : (
                    ""
                  )}

                  <Box gridColumn="span 3">
                    <div className="personalDetailsUpload">
                      <div className="ducumentUploadBlock">
                        <div className="documentUploadContent">
                          <label>Other Documents</label>
                          {this.state.selectedUploadCld_dCheque === "" ? (
                            <span className="btn_upload documentUpload">
                              <img src={panUploadIcon} alt="" />
                              <input
                                type="file"
                                id="imag"
                                title=""
                                className="input-img"
                                onChange={this.handleselectedFile(
                                  "uploadOtherDocuments"
                                )}
                              />
                              Upload Other Documnets
                            </span>
                          ) : (
                            ""
                          )}
                          {this.state.selectedUploadCld_dCheque !== "" && (
                            <div className="uploadedInfo">
                              <img src={TickIcon} alt="" className="tickIcon" />
                              <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Click to download File"
                            >
                              <div
                                onClick={() => {
                                  getFileDownload(
                                    serverConfig.SERVER_URL +
                                    "security-api/api/files/downloadfile/" +
                                    this.state.ChequeId,
                                    this.state.selectedUploadCld_dCheque,
                                    this.state.chequeFileExtension
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {/* {this.state.fileName} */}
                                {this.state.selectedUploadCld_dCheque}
                              </div>

                            </Tooltip>
                            
                            <Button
                                onClick={() => {
                                  this.handleDeleteFile(
                                    "chequeFile",
                                    this.state.ChequeId
                                  );
                                }}
                                className="deleteButton"
                              >
                                <img src={DeleteIcon} alt="" />
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Box>
                  {this.props.location.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdDate}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.props.location.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
          {/* common popup for validation */}
          {this.state.confirm && (
            <CommonPopup>
              <div className="delAddItem">
                <div className="mandatoryFieldText">
                  Please select all the mandatory fields
                </div>
                <div className="delAddItemBtns">
                  <input
                    type="button"
                    onClick={this.onclickPopUP.bind(this)}
                    value="Ok"
                    className="delYesBtn"
                  />
                </div>
              </div>
            </CommonPopup>
          )}
        </div>
      </div>
    );
  }
}
const mapStatetoProps = (state) => {
  const { payslipData } = state.PayslipReducer;
  const { stateNameList } = state.DropDownListReducer; // fetching globle dropdown from yarn type list
  const {GetAllFiles_Data}=state.BranchMasterReducer;
  return { payslipData, stateNameList,GetAllFiles_Data};
};

export default withRouter(
  connect(mapStatetoProps, {
    createBranchMaster,
    updateBranchMaster,
    getStateNameList,
    paySlipsByEbNo,
    postUploadFileList,
    getBranchFiles,
    deleteBranchMasterFiles,
    UploadBranchMasterFiles
  })(CreateBranchMaster)
);
