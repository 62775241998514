import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import {
  WORK_ORDER_LIST,
  CREATE_WORK_ORDER,
  UPDATE_PO_WO,
} from "./actionTypes";
import {
  CreateWorkOrderSuccessfull,
  UpdatePoWoSuccessfull,
  WorkOrderListSuccessfull,
} from "./actions";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import { getList } from "../../../helpers/Server_Helper";
import {
  getCateogryTypeList,
  getProjectList,
  getStatusList,
  getSupplierList,
  getClientList,
} from "../../Global/DropDownApis/actions";
import swal from "sweetalert";

function* getWorkOrderList({ payload: { url, data, history } }) {
  let userDit = JSON.parse(localStorage.getItem("authUser"));
  showSpinner();
  try {
    const response = yield call(getList, url, data);
   
    if (response) {
      hideSpinner();
      if (response.data) {
       
        yield put(
          getCateogryTypeList(serverApi.GET_ALL_CATEGORY_TYPE, history)
        );
        const data1 = {
          cipher: userDit.cipher,
          companyId: localStorage.getItem("companyId"),
        };
        const Url = serverApi.GET_ALL_PROJECTS;
        yield put(getProjectList(Url, data1, history));
        yield put(getStatusList(serverApi.GET_STATUS_LIST, history));
        yield put(
          getClientList(
            serverApi.GET_CLIENT_LIST + localStorage.getItem("companyId"),
            history
          )
        );
        yield put(
          getSupplierList(
            serverApi.GET_ALL_SUPPLIERS_LIST +
            localStorage.getItem("companyId"),
            history
          )
        );
      }
    }
    yield put(WorkOrderListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        hideSpinner();
        console.log(error);
      }
    }
  }
}

function* createWorkOrder({ payload: { url, data, history, pathname } }) {
  showSpinner();
  try {
    
    const response = yield call(getList, url, data);
    if (response) {
      swal(response.message, { icon: "success" });
      if (pathname === "/purchase_purchase_order") {
        history.push("/purchase_purchase_order");
        hideSpinner(true);
      } else {
        history.push("/work_order");
        hideSpinner(true);
      }
    }
    yield put(CreateWorkOrderSuccessfull({ response }));
  } catch (error) {
    // swal("error:" + error, { icon: "danger" });
    console.log(error);
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        hideSpinner();
        console.log(error);
      }
    }
  }
}

function* updatePOWO({ payload: { url, data, history, pathname } }) {
  try {
    const response = yield call(getList, url, data);
    showSpinner();
    if (response) {
      hideSpinner();
      swal(response.message, { icon: "success" });
      if (pathname === "/purchase_purchase_order") {
        history.push("/purchase_purchase_order");
      } else {
        history.push("/work_order");
      }
    }
    yield put(UpdatePoWoSuccessfull({ response }));
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

export function* watchWorkOrder() {
  yield takeEvery(WORK_ORDER_LIST, getWorkOrderList);
  yield takeEvery(CREATE_WORK_ORDER, createWorkOrder);
  yield takeEvery(UPDATE_PO_WO, updatePOWO);
}

function* WorkOrderSaga() {
  yield all([fork(watchWorkOrder)]);
}

export default WorkOrderSaga;
