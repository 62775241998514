//************************************************************************** */
//date of creation : 02-08-2023 :: 12:00 AM Author : Nagesh Medisetty //
//************************************************************************** */
import React, { useState, useEffect } from "react";
import { Grid, Box, Link } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import { createSubDepartment, updateSubDepartment } from "../../../store/Master/SubDepartmentMaster/actions";
import { getDepartmentList } from "../../../store/Global/DropDownApis/actions";
import DynamicSelect from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import moment from "moment";
import { validate } from "../../../Utilities/Validations";
import swal from "sweetalert";
import {useLocation} from "react-router-dom";


const CreateSubDepartment = (props) => {
  const [date, setDate] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [userDit, setUserDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [companyId, setCompanyId] = useState(localStorage.getItem('companyId'));
  const [departmentCode, setDepartmentCode] = useState("");
  const [departmentName, setDepartmentName] = useState('');
  const [masterDepartment, setMasterDepartment] = useState('');
  const [createdBy, setCreatedBy] = useState(null);
  const [createdOn, setCreatedOn] = useState("");
  const [deptId, setDeptId] = useState('');
  const [mdeptId, setMDeptId] = useState(null);
  const [createdDate, setCreatedDate] = useState('');
  const [DepartmentList, setDepartmentList] = useState([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [locationState] = useState(useLocation());

  useEffect(() => {
    //Department List api call
    props.getDepartmentList(serverApi.GET_DEPARTMENT_LIST + companyId + "/" + userDit.cipher, props.history);
    //setting data for update
    if (props.location.state) {
      setDepartmentCode(props.location.state.rowData.deptCode);
      setDepartmentName(props.location.state.rowData.departmentName);
      setMDeptId(props.location.state.rowData.masterDepartmentId);
      setDeptId(props.location.state.rowData.deptId);
      setCreatedBy(props.location.state.rowData.createdBy);
      setCreatedOn(props.location.state.rowData.autoDateTime);
      const dateEntered = props.location.state.rowData.autoDateTime;
      const dateIsValid = moment(dateEntered).format("LLLL");
      setCreatedDate(dateIsValid);
    }
  }, []);

  useEffect(() => {
    //Department List set
    let list = [];
    if (props.departmentList.data) {
      const listData = props.departmentList.data.filter((item) => item.label !== "Select....");
      listData.forEach((item) => {
        list.push({
          label: item.label,
          name: item.name,
          value: item.masterDeptId
        });
      });
      setDepartmentList(list);
    }
  }, [props.departmentList.data]);


  const onClickCreateUpdate = () => { //Create and Update api call
    const validateFields = [
      { field: departmentName, msg: "Department Name", type: 1 },
      { field: mdeptId, msg: "Master Department", type: 2 }
    ];

    const validObj = validate(validateFields);
    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message} ..!!`);
      } else {
        swal(`Please Select ${validObj.message} ..!!`);
      }
    } else {
      if (props.location.state === undefined) {
        handleCallApi('CREATE');        
      } else {
        handleCallApi('UPDATE');        
      }
    }
  };

  const handleCallApi = (type) => {

    if(type==='CREATE'){
      const data = {
        companyId: companyId,
        createdBy: userDit.userName,
        deptDesc: departmentName,
        deptCode: departmentCode||null,
        mdeptId: mdeptId,
        userId: userDit.userId.toString()
      };
      props.createSubDepartment(serverApi.CREATE_SUB_DEPARTMENT, data, props.history);
    }
    if(type==='UPDATE'){
      const data = {
        companyId: companyId,
        createdBy: createdBy,
        deptCode: departmentCode||null,
        deptDesc: departmentName,
        deptId: deptId,
        mdeptId: mdeptId,
        userId: userDit.userId.toString(),
        autoDateTime: createdOn
      };
      props.updateSubDepartment(serverApi.UPDATE_SUB_DEPARTMENT, data, props.history);
    }

  }

  const onClickCancel = () => {
    props.history.push("/sub_department_master");
  };

  const onhandlechangeValue = (key) => (event) => {
    if (key === "departmentCode") {
      const deptcode = event.replace(/[^a-zA-Z0-9\s]+/g, '');
      setDepartmentCode(deptcode);
    } else if (key === "departmentName") {
      setDepartmentName(event);
    }
  };

  const handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "masterDepartment") {
      setMasterDepartment(selectedName);
      setMDeptId(selectedValue);
    }
  };

  const handleMenuOpen = () => {
    setDropdownOpen(false);
  };

  const handleScroll = () => {
    setDropdownOpen(true);
  };

  return (
    <div>
      <Grid className="indentContainerBlock">
        <Grid sx={12} className="indentContainerMainBlock">
          <>
            <div className="departmentMasterContainer">
              <div className="consoleFormContainer">
                <h5>Fill Details</h5>
                <div className="consoleFormBlock">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={2}
                      >
                        <Box gridColumn="span 3">
                          <div className="consoleTextFieldBlock">
                            <TextFieldNormal
                              value={departmentCode}
                              caps="OFF"
                              minValue="0"
                              onChange={onhandlechangeValue("departmentCode")}
                              label="Department Code"
                              disabled={locationState.state !== undefined ? true : false}
                            />
                          </div>
                        </Box>
                        <Box gridColumn="span 3">
                          <div className="consoleTextFieldBlock">
                            <TextFieldReq
                              label="Department Name"
                              value={departmentName}
                              caps="OFF"
                              onChange={onhandlechangeValue("departmentName")}
                              disabled={departmentCode === "" ? true : false}
                            />
                          </div>
                        </Box>
                        <Box gridColumn="span 3">
                          <div className="consoleTextFieldBlock">
                            <DynamicSelect
                              arrayOfData={DepartmentList}
                              className="dropdownBlockContainer"
                              menuPosition="fixed"
                              menuPlacement="auto"
                              onSelectChange={handlePropSelectChange}
                              isDropdownOpen={isDropdownOpen}
                              handleMenuOpen={handleMenuOpen}
                              selected={mdeptId}
                              update={locationState.state ? 1 : 0}
                              name="masterDepartment"
                              label="Master Department"
                              required
                              isDisabled={departmentName === "" ? true : false}
                            />
                          </div>
                        </Box>
                        {locationState.state === undefined ? (
                          ""
                        ) : (
                          <>
                            <Box gridColumn="span 6">
                              <div className="consoleUpdateTextBlock">
                                <div>
                                  <span className="taxLabel">Created By : <b>{createdBy}</b></span>
                                </div>
                                <div>
                                  <span className="taxLabel">Created On :<b> {createdDate}</b></span>
                                </div>
                              </div>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
            <div className="consoleFormButtonBlock">
              <Link>
                <CustomButton
                  label={"Cancel"}
                  className="greenBorderButton"
                  handleClick={onClickCancel}
                />
              </Link>
              {locationState.state !== undefined ? (
                <CustomButton
                  label={"Update"}
                  className="greenButton"
                  handleClick={onClickCreateUpdate}
                  type="sumbit"
                />
              ) : (
                <CustomButton
                  label={"Create"}
                  className="greenButton"
                  handleClick={onClickCreateUpdate}
                  type="sumbit"
                />
              )}
              {confirm && (
                <CommonPopup>
                  <div className="delAddItem">
                    <div className="mandatoryFieldText">Please select all the mandatory fields</div>
                    <div className="delAddItemBtns">
                      <input
                        type="button"
                        onClick={() => setConfirm(false)}
                        value="Ok"
                        className="delYesBtn"
                      />
                    </div>
                  </div>
                </CommonPopup>
              )}
            </div>
          </>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStatetoProps = (state) => {
  const { Create_Sub_Department, Update_Sub_Department, } = state.SubDepartmentReducer;
  const { departmentList } = state.DropDownListReducer;
  return { departmentList, Create_Sub_Department, Update_Sub_Department, };
};

export default withRouter(
  connect(mapStatetoProps, {
    createSubDepartment,
    updateSubDepartment,
    getDepartmentList,
  })(CreateSubDepartment)
);
