import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Box, Button } from "@mui/material";
import TextFieldReq from "../../../../../components/TextField/TextFieldReq";
import TextFieldEmailReq from "../../../../../components/TextField/TextFieldEmailReq";
import TextFieldNormal from "../../../../../components/TextField/TextFieldNormal";
import TextFieldCharNums from "../../../../../components/TextField/TextFieldCharNums";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import CustomButton from "../../../../../components/Buttons/Buttons";
import EditLogoIcon from "../../../../../assets/images/picEditIcon.png";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import CommonPopup from "../../../../../components/CommonPopup/CommonPopup";
import panUploadIcon from "../../../../../assets/images/panUploadIcon.png";
import DeleteIcon from "../../../../../assets/images/picEditIcon.png";
import TickIcon from "../../../../../assets/images/fileUploadedTick.png";
import moment from "moment";
import { serverApi, serverConfig } from "../../../../../helpers/Consts";
import { getFileDownload } from "../../../../../helpers/Server_Helper";
import { postUploadFileList } from "../../../../../store/MyProfile/UploadFile/actions";
import {
  viewbyidPersonalDetailsSuccessfull,
  getAllFiles,
  ProfilePicFiles,
  deleteProfilePic,
  AadharValidation,
  UploadProfilePic,
  deleteFilesData,
} from "../../../../../store/HRMS/EmployeeDataBase/actions";
import {
  setPersonalDetails,
  viewbyidPersonalDetails,
} from "../../../../../store/HRMS/EmployeeDataBase/actions";
import DynamicSelectNew from "../../../../../components/Dropdown/DropdownSelectNew";
import { validate } from "../../../../../Utilities/Validations";
class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      ebId: this.props.getEbId,
      // Upload Files Parameters //
      driving_file: "",
      pancard_file: "",
      aadhar_file: "",
      driving_file_id: "",
      pancard_file_id: "",
      aadhar_file_id: "",
      //Form Parameters //
      firstName: "",
      middleName: "",
      lastName: "",
      gender: "",
      dateOfBirth: "",
      bloodGroup: "",
      emailId: "",
      maritalStatus: "",
      countryId: "73",
      relegionName: "",
      fatherSpouseName: "",
      passportNo: "",
      drivingLicenceNo: "",
      panNo: "",
      aadharNo: "",
      companyId: "",
      createdBy: "",
      createdTime: "",
      updatedBy: "",
      isActive: "1",
      updatedTime: "",
      status: "21",
      fixedEbId: "",
      // profile Image parameters //
      fileUploadId: null,
      file: "",
      imagePreviewUrl: "",
      fileUpload: "",
      imagePreview: "none",
      fileName: "",
      fileExtension: "",
      showPopup: false,
      ShowInValidText: false,
      aadharVerified: true,
      aadharMandatory: false,
      emailValidation: false,
      emailMandatory: false,
      firstNameMandatory: false,
      firstNameValidation: false,
      middleNameValidation: false,
      lastNameMandatory: false,
      lastNameValidation: false,
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    if(this.state.ebId) {
      this.props.ProfilePicFiles(
        serverApi.GET_ALL_FILES +
          "1/" +
          this.props.getEbId +
          "/" +
          localStorage.getItem("companyId"),
        this.props.history,
        1
      );
    }
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
    this.setState({ ebId: this.props.getEbId });
    var ebId = this.props.getEbId;
    if (ebId) {
      this.props.ProfilePicFiles(
        serverApi.GET_ALL_FILES +
          "1/" +
          this.props.getEbId +
          "/" +
          localStorage.getItem("companyId"),
        this.props.history,
        1
      );
      this.props.getAllFiles(
        serverApi.GET_ALL_FILES +
          "6/" +
          this.props.getEbId +
          "/" +
          localStorage.getItem("companyId"),
        this.props.history,
        2
      );
      this.setState({ ebId: this.props.getEbId });
      this.props.viewbyidPersonalDetails(
        serverApi.VIEW_EMPLOYEE_PERSONAL_DIT + "/" + this.props.getEbId,
        this.props.history,
        5
      );
    } else {
      const response = { data: null };
      this.props.viewbyidPersonalDetailsSuccessfull({ response });
      this.setState({ ebId: null });
    }
    if (this.props.personalDetails) {
      var personal = this.props.personalDetails.data;
      if (personal) {
        Object.keys(personal).map((key, index) => {
          this.setState({ [key]: personal[key] });
        });
      }
    }
    /****************PRESONAL DETAILS VIEW BY ID API CALLING ******************************/ //this.props.getEbId
  };

  componentWillReceiveProps(props) {
    var ebId = this.props.getEbId;
    this.setState({ ebId: this.props.getEbId });
    if (ebId) {
      if (props.personalDetails) {
        if (
          this.state.aadharNo == "" &&
          this.state.panNo == "" &&
          this.state.drivingLicenceNo == "" &&
          this.state.passportNo == "" &&
          this.state.fatherSpouseName == "" &&
          this.state.relegionName == "" &&
          this.state.bloodGroup == ""
        ) {
          var personal = props.personalDetails.data;
          if (personal) {
            Object.keys(personal).map((key, index) => {
              this.setState({ [key]: personal[key] });
            });
          }
        }
      }
      if (props.allFiles) {
        if (props.allFiles.data) {
          props.allFiles.data.map((row) => {
            var filename = row.fileName;
            var chars = filename.split("_");
            var filekey = chars[1] + "_" + chars[2];
            var displayName = filename + "." + row.fileExtension;
            this.setState({ [filekey]: displayName });
          });
          props.allFiles.data.map((row) => {
            var filename = row.fileName;
            var chars = filename.split("_");
            var filekey = chars[1] + "_" + chars[2] + "_id";
            this.setState({ [filekey]: row.fileUploadId });
          });
        }
      }
      var profilepicurl = "";
      var fileName = "";
      var fileExtension = "";
      var fileUploadId = null;
      if (props.profilepicFiles) {
        if (props.profilepicFiles.data) {
          if (
            props.profilepicFiles.data.data != 0 &&
            props.profilepicFiles.data.data !== undefined
          ) {
            if (props.profilepicFiles.data.data.length != 0) {
              props.profilepicFiles.data.data.map((row) => {
                fileUploadId = row.fileUploadId;
                profilepicurl = row.retrievalPath;
                fileName = row.fileName;
                fileExtension = row.fileExtension;
              });
              this.setState({
                fileUploadId: fileUploadId,
              });

              if (profilepicurl) {
                getFileDownload(
                  serverConfig.SERVER_URL + profilepicurl,
                  fileName,
                  fileExtension,
                  this.handleDownloadedFile
                );
              }
            }
          }
        }
      }
    }
    if (props.Aadhar_validation) {
      if (props.Aadhar_validation.data) {
        if (
          props.Aadhar_validation.data.message == "Aadhar No. Already Exist!"
        ) {
          this.setState({
            aadharVerified: false,
          });
        } else {
          this.setState({
            aadharVerified: true,
          });
        }
      }
    }
  }

  handleDownloadedFile = (url) => {
    this.setState({
      imagePreviewUrl: url,
      fileUpload: "none",
      imagePreview: "block",
    });
  };

  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });

    if (key === "firstName") {
      if (event === "") {
        this.setState({
          firstNameMandatory: true,
          firstNameValidation: false,
        });
      } else {
        this.setState({
          firstNameMandatory: false,
        });
        let onlyLetters = /^[A-Za-z]+$/;
        if (onlyLetters.test(event)) {
          this.setState({
            firstNameValidation: false,
          });
        } else {
          this.setState({
            firstNameValidation: true,
          });
        }
      }
    }
    if (key === "middleName") {
      let onlyLetters = /^[A-Za-z]+$/;
      if (onlyLetters.test(event)) {
        this.setState({
          middleNameValidation: false,
        });
      } else {
        this.setState({
          middleNameValidation: true,
        });
      }
    }
    if (key === "lastName") {
      if (event === "") {
        this.setState({
          lastNameMandatory: true,
          lastNameValidation: false,
        });
      } else {
        this.setState({
          lastNameMandatory: false,
        });
        let onlyLetters = /^[A-Za-z]+$/;
        if (onlyLetters.test(event)) {
          this.setState({
            lastNameValidation: false,
          });
        } else {
          this.setState({
            lastNameValidation: true,
          });
        }
      }
    }
    if (key === "aadharNo") {
      if (event === "") {
        this.setState({
          aadharMandatory: true,
          ShowInValidText: false,
        });
      } else {
        this.setState({
          aadharMandatory: false,
        });
        if (event.length == "12") {
          this.setState({
            aadharNo: event,
            ShowInValidText: false,
          });
          const data = {
            ebId: this.state.ebId,
            aadharNo: event,
          };
          this.props.AadharValidation(
            serverApi.AADHAR_NUMBER_VALIDATION,
            data,
            this.props.history
          );
        } else {
          this.setState({
            ShowInValidText: true,
          });
        }
      }
    }
    if (key === "emailId") {
      if (event === "") {
        this.setState({
          emailMandatory: true,
          emailValidation: false,
        });
      } else {
        this.setState({
          emailMandatory: false,
        });
        const val = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (val.test(event)) {
          this.setState({
            emailValidation: false,
          });
        } else {
          this.setState({
            emailValidation: true,
          });
        }
      }
    }
  };

  handleChange = (selectedValue, selectedName, name) => {
    this.setState({ [name]: selectedValue });
  };
  handleSelectDate = (e, name) => {
    if (name === "DOB") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      this.setState({
        dateOfBirth: Date,
      });
    }
  };
  validateAge(dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const ageDiffMs = Date.now() - dob.getTime();
    const ageDate = new Date(ageDiffMs);
    const userAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    return userAge >= 18;
  }

  onClickSave = () => {
    const data = {
      firstName: this.state.firstName,
      middleName: this.state.middleName,
      lastName: this.state.lastName,
      gender: this.state.gender,
      dateOfBirth: this.state.dateOfBirth,
      bloodGroup: this.state.bloodGroup,
      emailId: this.state.emailId,
      maritalStatus: this.state.maritalStatus,
      countryId: this.state.countryId,
      relegionName: this.state.relegionName,
      fatherSpouseName: this.state.fatherSpouseName,
      passportNo: this.state.passportNo,
      drivingLicenceNo: this.state.drivingLicenceNo,
      panNo: this.state.panNo,
      aadharNo: this.state.aadharNo,
      companyId: localStorage.getItem("companyId"),
      fixedEbId: this.state.fixedEbId,
    };
    if (this.state.ebId) {
      data.ebId = this.state.ebId;
      data.updatedBy = this.state.userDit.userId;
      data.updatedTime = moment().format("YYYY-MM-DD");
      data.isActive = this.state.isActive;
      data.status = this.state.status;
    } else {
      data.createdBy = this.state.userDit.userId;
      data.createdTime = moment().format("YYYY-MM-DD");
      data.isActive = "1";
      data.status = "21";
    }
    if (this.state.firstName == "") {
      swal("Please Enter the First Name");
    } else if (this.state.firstNameValidation == true) {
      swal("First Name should Contain only Alphabets", { icon: "error" });
    } else if (this.state.middleNameValidation == true) {
      swal("Middle Name should Contain only Alphabets", { icon: "error" });
    } else if (this.state.lastName == "") {
      swal("Please Enter the Last Name");
    } else if (this.state.lastNameValidation == true) {
      swal("Last Name should Contain only Alphabets", { icon: "error" });
    } else if (this.state.gender == "") {
      swal("Please Select the Gender");
    } else if (this.state.dateOfBirth == "") {
      swal("Please Enter the Date Of Birth");
    } else if (this.state.emailId == "") {
      swal("Please Enter the Email Id");
    } else if (this.state.emailValidation == true) {
      swal("Please Enter the Valid Email Id", { icon: "error" });
    } else if (this.state.aadharNo == "") {
      swal("Please Enter the Aadhar Number");
    } else if (this.state.ShowInValidText == true) {
      swal("Please Enter the 12 Digit Aadhar Number");
    } else if (this.state.aadharVerified == false) {
      swal("Please Enter Valid Aadhar Number");
    } else {
      this.props.setPersonalDetails(
        serverApi.ADD_EMPLOYEE_PERSONAL_DIT,
        data,
        this.props.history
      );
    }
  };

  handleselectedFile = (key) => (e) => {
    var ebId = this.state.ebId;

    var fileName = "";
    if (key === "panUpload") {
      fileName = ebId + "_pancard_file";
    }

    if (key === "aadharUpload") {
      fileName = ebId + "_aadhar_file";
    }

    if (key === "drivingUpload") {
      fileName = ebId + "_driving_file";
    }

    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", fileName);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 6);
    formData.append("displayName", fileName);
    formData.append("taskUniqueId", ebId);
    formData.append("createdBy", this.state.userDit.userId);
    formData.append("fileCompanyId", this.state.companyId);
    if (key === "panUpload") {
      this.setState({
        pancard_file: fileName + "." + fileExt,
      });
    }
    if (key === "aadharUpload") {
      this.setState({
        aadhar_file: fileName + "." + fileExt,
      });
    }
    if (key === "drivingUpload") {
      this.setState({
        driving_file: fileName + "." + fileExt,
      });
    }
    this.props.postUploadFileList(
      serverApi.UPLOADFILE,
      formData,
      this.props.history,
      6,
      this.props.getEbId
    );
  };
  DeleteFiles = (key, fileUploadId) => (e) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (key === "panUpload") {
          this.setState({
            pancard_file: "",
          });
        }

        if (key === "aadharUpload") {
          this.setState({
            aadhar_file: "",
          });
        }

        if (key === "drivingUpload") {
          this.setState({
            driving_file: "",
          });
        }
        this.props.deleteFilesData(
          serverApi.DELETE_FILE_BI_ID + fileUploadId,
          this.props.history,
          this.props.getEbId,
          6
        );
      } else {
        swal("Your action is canceled!");
      }
    });
  };
  handleDeleteFile = (key, fileUploadId) => (e) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (key === "profilePic") {
          this.setState({
            file: "",
            imagePreviewUrl: "",
            fileUpload: "",
            imagePreview: "none",
            fileName: "",
            fileExtension: "",
            showPopup: false,
          });
        }
        this.props.deleteProfilePic(
          serverApi.DELETE_FILE_BI_ID + fileUploadId,
          this.props.history,
          this.props.getEbId,
          6
        );  
      } else {
        swal("Your action is canceled!");
      }
    });
  };

  handleImageChange = (e) => {
    if (
      !this.props.getEbId ||
      this.props.getEbId == 0 ||
      this.props.getEbId == null
    ) {
      swal("Please Create the User");
    } else {
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];
      let fileExt = file.name.split(".").pop();

      const formData = new FormData();
      formData.append("fileName", file.name);
      formData.append("fileUpload", file);
      formData.append("fileExtension", fileExt);
      formData.append("sourceMenu", 1);
      formData.append("displayName", file.name);
      formData.append("taskUniqueId", this.props.getEbId);
      formData.append(
        "createdBy",
        JSON.parse(localStorage.getItem("authUser")).userId
      );
      formData.append("fileCompanyId", localStorage.getItem("companyId"));
      this.setState({
        fileName: file.name,
        fileExtension: file.name.split(".").pop(),
      });

      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result,
          fileUpload: "none",
          imagePreview: "block",
        });
      };
      reader.readAsDataURL(file);

      this.props.UploadProfilePic(
        serverApi.UPLOADFILE,
        formData,
        this.props.history,
        this.props.getEbId
      );
    }
  };
  onclickOk = () => {
    this.setState({
      showPopup: false,
    });
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 18);
    return (
      <div className="personalDetailsInfo">
        <div className="">
          <h1>Personal Details</h1>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <div className="uploadBlock">
                  <div style={{ display: this.state.fileUpload }}>
                    <Button component="label" className="uploadBtn">
                      <div className="uploadInfo">
                        <h1>+</h1>
                        <h3>Upload Photo</h3>
                        <p>(Format: jpeg, png)</p>
                      </div>
                      <input
                        type="file"
                        hidden
                        onChange={this.handleImageChange}
                        accept="image/jpeg, image/png"
                      />
                    </Button>
                  </div>
                  <div
                    className="imagePreview"
                    style={{
                      display: this.state.imagePreview,
                    }}
                  >
                    <img src={this.state.imagePreviewUrl} alt="" />

                    <button
                      onClick={this.handleDeleteFile(
                        "profilePic",
                        this.state.fileUploadId
                      )}
                      className="editIconStyle"
                    >
                      <img src={EditLogoIcon} alt="" />
                    </button>
                  </div>
                  {this.state.showPopup && (
                    <CommonPopup>
                      <div className="delAddItem">
                        <div>"Please Create the User"</div>
                        <div className="delAddItemBtns">
                          <input
                            type="button"
                            onClick={this.onclickOk.bind(this)}
                            value="Ok!"
                            className="delYesBtn"
                          />
                        </div>
                      </div>
                    </CommonPopup>
                  )}
                </div>
              </Grid>
              <Grid item xs={9}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                  className="personalInfo"
                >
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        type="text"
                        label="First Name"
                        value={this.state.firstName}
                        onChange={this.onhandlechange("firstName")}
                        required
                      />
                      {this.state.firstNameValidation && (
                        <div className="TextFieldBlock">
                          <span className="text-danger">
                            First Name Should Contain only Alphabets
                          </span>
                        </div>
                      )}
                      {this.state.firstNameMandatory && (
                        <div className="TextFieldBlock">
                          <span className="text-danger">
                            This Field is Required
                          </span>
                        </div>
                      )}
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Middle Name"
                        placeholder="Enter here"
                        value={this.state.middleName}
                        onChange={this.onhandlechange("middleName")}
                      />
                      {this.state.middleNameValidation && (
                        <div className="TextFieldBlock">
                          <span className="text-danger">
                            Middle Name Should Contain only Alphabets
                          </span>
                        </div>
                      )}
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Last Name"
                        placeholder="Enter here"
                        value={this.state.lastName}
                        onChange={this.onhandlechange("lastName")}
                        required
                      />
                      {this.state.lastNameValidation && (
                        <div className="TextFieldBlock">
                          <span className="text-danger">
                            Last Name Should Contain only Alphabets
                          </span>
                        </div>
                      )}
                      {this.state.lastNameMandatory && (
                        <div className="TextFieldBlock">
                          <span className="text-danger">
                            This Field is Required
                          </span>
                        </div>
                      )}
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectNew
                        update={this.state.gender ? 1 : 0}
                        label="Gender"
                        required
                        arrayOfData={gender}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.gender}
                        name="gender"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>
                          Date of Birth<span className="starcolor">*</span>
                        </label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="DOB"
                          value={this.state.dateOfBirth}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "DOB")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectNew
                        arrayOfData={blood_group}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.bloodGroup}
                        name="bloodGroup"
                        label="Blood Group"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Email Id"
                        placeholder="Email"
                        value={this.state.emailId}
                        onChange={this.onhandlechange("emailId")}
                        required
                      />
                      {this.state.emailValidation && (
                        <div className="TextFieldBlock">
                          <span className="text-danger">
                            Invalid Email Address
                          </span>
                        </div>
                      )}
                      {this.state.emailMandatory && (
                        <div className="TextFieldBlock">
                          <span className="text-danger">
                            This Field is Required
                          </span>
                        </div>
                      )}
                    </div>
                  </Box>

                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectNew
                        arrayOfData={marital_status}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.maritalStatus}
                        name="maritalStatus"
                        label="Marital Status"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectNew
                        update={this.state.countryId}
                        arrayOfData={cunttrysData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.countryId}
                        name="countryId"
                        label="Nationality"
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Father/Spouse Name"
                        placeholder="Enter here"
                        value={this.state.fatherSpouseName}
                        onChange={this.onhandlechange("fatherSpouseName")}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="consoleFormContainer">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldCharNums
                        label="Passport Number"
                        placeholder="Enter here"
                        caps="ON"
                        value={this.state.passportNo}
                        onChange={this.onhandlechange("passportNo")}
                      />
                    </div>
                  </Box>
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                  style={{ marginTop: "15px" }}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldCharNums
                        label="Driving License No"
                        placeholder="Enter here"
                        caps="ON"
                        value={this.state.drivingLicenceNo}
                        onChange={this.onhandlechange("drivingLicenceNo")}
                      />
                    </div>
                  </Box>
                  {this.state.ebId !== null && (
                    <Box gridColumn="span 3">
                      <div className="personalDetailsUpload">
                        <div className="ducumentUploadBlock">
                          <div className="documentUploadContent">
                            {this.state.driving_file === "" ? (
                              <span className="btn_upload documentUpload">
                                <img src={panUploadIcon} alt="" />
                                <input
                                  type="file"
                                  id="imag"
                                  title=""
                                  className="input-img"
                                  onChange={this.handleselectedFile(
                                    "drivingUpload"
                                  )}
                                />
                                Upload Driving License
                              </span>
                            ) : (
                              ""
                            )}
                            {this.state.driving_file !== "" && (
                              <div className="uploadedInfo">
                                <img
                                  src={TickIcon}
                                  alt=""
                                  className="tickIcon"
                                />
                                {this.state.driving_file}
                                <Button
                                  onClick={this.DeleteFiles(
                                    "drivingUpload",
                                    this.state.driving_file_id
                                  )}
                                  className="deleteButton"
                                >
                                  <img src={DeleteIcon} alt="" />
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                  style={{ marginTop: "15px" }}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldCharNums
                        label="PAN No"
                        placeholder="Enter here"
                        caps="ON"
                        value={this.state.panNo}
                        onChange={this.onhandlechange("panNo")}
                      />
                    </div>
                  </Box>
                  {this.state.ebId !== null && (
                    <Box gridColumn="span 3">
                      <div className="personalDetailsUpload">
                        <div className="ducumentUploadBlock">
                          <div className="documentUploadContent">
                            {this.state.pancard_file === "" ? (
                              <span className="btn_upload documentUpload">
                                <img src={panUploadIcon} alt="" />
                                <input
                                  type="file"
                                  id="imag"
                                  title=""
                                  className="input-img"
                                  onChange={this.handleselectedFile(
                                    "panUpload"
                                  )}
                                />
                                Upload PAN Card
                              </span>
                            ) : (
                              ""
                            )}
                            {this.state.pancard_file !== "" && (
                              <div className="uploadedInfo">
                                <img
                                  src={TickIcon}
                                  alt=""
                                  className="tickIcon"
                                />
                                {this.state.pancard_file}
                                <Button
                                  onClick={this.DeleteFiles(
                                    "panUpload",
                                    this.state.pancard_file_id
                                  )}
                                  className="deleteButton"
                                >
                                  <img src={DeleteIcon} alt="" />
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                  style={{ marginTop: "15px" }}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Aadhar No"
                        placeholder="Enter here"
                        type="number"
                        value={this.state.aadharNo}
                        onChange={this.onhandlechange("aadharNo")}
                        required
                      />
                      {this.state.aadharMandatory && (
                        <div className="TextFieldBlock">
                          <span className="text-danger">
                            This Field is Mandatory
                          </span>
                        </div>
                      )}
                      {this.state.ShowInValidText && (
                        <div className="TextFieldBlock">
                          <span className="text-danger">
                            Please Enter 12 digit Aadhar Number
                          </span>
                        </div>
                      )}
                    </div>
                  </Box>

                  {this.state.ebId !== null && (
                    <Box gridColumn="span 3">
                      <div className="personalDetailsUpload">
                        <div className="ducumentUploadBlock">
                          <div className="documentUploadContent">
                            {this.state.aadhar_file === "" ? (
                              <span className="btn_upload documentUpload ">
                                <img src={panUploadIcon} alt="" />
                                <input
                                  type="file"
                                  id="imag"
                                  title=""
                                  className="input-img"
                                  onChange={this.handleselectedFile(
                                    "aadharUpload"
                                  )}
                                />
                                Upload Aadhar Card
                              </span>
                            ) : (
                              ""
                            )}
                            {this.state.aadhar_file !== "" && (
                              <div className="uploadedInfo">
                                <img
                                  src={TickIcon}
                                  alt=""
                                  className="tickIcon"
                                />
                                {this.state.aadhar_file}
                                <Button
                                  onClick={this.DeleteFiles(
                                    "aadharUpload",
                                    this.state.aadhar_file_id
                                  )}
                                  className="deleteButton"
                                >
                                  <img src={DeleteIcon} alt="" />
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Box>
                  )}
                  {localStorage.getItem("EmpData") !== null &&
                    localStorage.getItem("ebId") === null && (
                      <Box gridColumn="span 3">
                        <div className="personalDetailsUpload">
                          <div className="ducumentUploadBlock">
                            <div className="documentUploadContent">
                              {this.state.aadhar_file === "" ? (
                                <span className="btn_upload documentUpload ">
                                  <img src={panUploadIcon} alt="" />
                                  <input
                                    type="file"
                                    id="imag"
                                    title=""
                                    className="input-img"
                                    onChange={this.handleselectedFile(
                                      "AadUpload"
                                    )}
                                  />
                                  Upload AadharCard
                                </span>
                              ) : (
                                ""
                              )}
                              {this.state.aadhar_file !== "" && (
                                <div className="uploadedInfo">
                                  <img
                                    src={TickIcon}
                                    alt=""
                                    className="tickIcon"
                                  />
                                  {this.state.aadhar_file}
                                  <Button
                                    onClick={this.DeleteFiles(
                                      "AadUpload",
                                      this.state.aadhar_file_id
                                    )}
                                    className="deleteButton"
                                  >
                                    <img src={DeleteIcon} alt="" />
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Box>
                    )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="consoleFormButtonBlock">
          <Link to="/add_employee">
            <CustomButton label={"Cancel"} className="greenBorderButton" />
          </Link>
          {this.props.getEbId ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickSave}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Save and Next"}
              className="greenButton"
              handleClick={this.onClickSave}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}
const cunttrysData = [
  {
    value: "1",
    label: "America",
    name: "America",
  },
  {
    value: "73",
    label: "India",
    name: "India",
  },
];
const gender = [
  {
    value: "Male",
    label: "Male",
    name: "Male",
  },
  {
    value: "Female",
    label: "Female",
    name: "Female",
  },
  {
    value: "Others",
    label: "Others",
    name: "Others",
  },
];
const blood_group = [
  {
    value: "O-",
    label: "O-",
    name: "O-",
  },
  {
    value: "O+",
    label: "O+",
    name: "O+",
  },
  {
    value: "A-",
    label: "A-",
    name: "A-",
  },
  {
    value: "A+",
    label: "A+",
    name: "A+",
  },
  {
    value: "B-",
    label: "B-",
    name: "B-",
  },
  {
    value: "B+",
    label: "B+",
    name: "B+",
  },
  {
    value: "AB-",
    label: "AB-",
    name: "AB-",
  },
  {
    value: "AB+",
    label: "AB+",
    name: "AB+",
  },
];
const marital_status = [
  {
    value: 1,
    label: "Select",
    name: "Select",
  },
  {
    value: 2,
    label: "Married",
    name: "Married",
  },
  {
    value: 3,
    label: "Unmarried",
    name: "Unmarried",
  },
];

const mapStatetoProps = (state) => {
  const {
    personalDetails,
    viewById,
    profilePicUrl,
    profilePicFile,
    allFiles,
    profilepicFiles,
    getEbId,
    Aadhar_validation,
  } = state.EmployeeReducer;
  return {
    personalDetails,
    viewById,
    profilePicUrl,
    profilePicFile,
    allFiles,
    profilepicFiles,
    getEbId,
    Aadhar_validation,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    setPersonalDetails,
    getAllFiles,
    ProfilePicFiles,
    postUploadFileList,
    UploadProfilePic,
    viewbyidPersonalDetailsSuccessfull,
    viewbyidPersonalDetails,
    deleteProfilePic,
    AadharValidation,
    deleteFilesData,
  })(PersonalDetails)
);
