import { React, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import { withRouter } from "react-router-dom";
import { ClearJuteBillPassHeader, ClearJuteBillPassLineItemdata, JuteBillPassList } from "../../../store/Jute/JuteBillPass/actions";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
const JuteBillPassIndex = (props) => {
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [companyId, setCompanyId] = useState(localStorage.getItem("companyId"));
  const [JuteBillPassContent, setJuteBillPassContent] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [supplier, setSupplier] = useState("");
  const [gateEntryNo, setGateEntryno] = useState("");
  const [status, setStatus] = useState("");
  const [mrNo, setMrNo] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      fromDate: "",
      toDate: "",
      type: "J",
      supplier: "",
      gateEntryNo: "",
      companyId: companyId,
      cipher: userDit.cipher,
      financialYear: localStorage.getItem("acadamicYear"),
      status: "",
      mrNo: "",
    };
    dispatch(JuteBillPassList(serverApi.JUTE_BILL_PASS_DATA_LIST, data, props.history))
    dispatch(ClearJuteBillPassHeader());
    dispatch(ClearJuteBillPassLineItemdata());
  }, []);
  const handleSearch = (searchData) => {
    const data = {
      fromDate: "",
      toDate: "",
      type: "J",
      supplier: searchData.supplier,
      gateEntryNo: searchData.gateEntryNo,
      companyId: localStorage.getItem("companyId"),
      cipher: userDit.cipher,
      financialYear: localStorage.getItem("acadamicYear"),
      status: searchData.statusValue,
      mrNo: searchData.mrNo,
    };
    setFromDate(searchData.fromDate);
    setToDate(searchData.toDate);
    setSupplier(searchData.supplier);
    setStatus(searchData.statusValue);
    setGateEntryno(searchData.gateEntryNum);
    dispatch(JuteBillPassList(serverApi.JUTE_BILL_PASS_DATA_LIST,
      data, props.history));
  };

  const { JuteBillPassListData } = useSelector(
    (state) => state.JuteBillPassReducer
  );

  useEffect(() => {
    if (JuteBillPassListData) {
      if (JuteBillPassListData.data) {
        console.log(JuteBillPassListData.data,"reponse");
        setJuteBillPassContent(JuteBillPassListData.data);
      }
    }
  }, [JuteBillPassListData])

  return (
    <>
      <Gridwithcustomview
        mdata={JuteBillPassContent}
        mcolumns={Columns}
        status={true}
        micon={GridArrowIcon}
        actions={true}
        filter_form={"JuteBillPass"}
        handleSearch={handleSearch}
        url="/update_jute_bill_pass"
      />
    </>
  )

}
const Columns = [
  {
    Header: "Bill Pass No",
    accessor: "billPassNo",
    hidden: false,
  },
  {
    Header: "Bill Date",
    accessor: "billDate",
    hidden: false,
  },
  {
    Header: "Gate Entry No",
    accessor: "gateEntryNo",
    hidden: false,
  },
  {
    Header: "MR No",
    accessor: "mrNo",
    hidden: false,
  },
  {
    Header: "Mr Print No",
    accessor: "mrPrintNo",
    hidden: false,
  },
  {
    Header: "MR Date",
    accessor: "mrDate",
    hidden: false,
  },
  {
    Header: "Invoice No",
    accessor: "invoiceNo",
    hidden: false,
  },
  {
    Header: "Invoice Date",
    accessor: "invoiceDate",
    hidden: false,
  },
  {
    Header: "Supplier",
    accessor: "supplierName",
    hidden: false,
  },
  {
    Header: "Bill Amount",
    accessor: "billAmount",
    hidden: false,
  },
  {
    Header: "Due Amount",
    accessor: "dueAmount",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "status",
    hidden: false,
  },
];
export default JuteBillPassIndex;