import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Box } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Link } from "react-router-dom";
import CustomButton from "../../../components/Buttons/Buttons";
import LeaveBalance from "../../../components/HRMSModule/LeaveBalance";
import { serverApi } from "../../../helpers/Consts";
import {
  getLeaveLedgerList,
  addLeaveTransaction,
  updateLeave,
  getLeaveRequestViewByIdList,
  getWorkerDataByEbNo,
  getWorkerDataClear,
} from "../../../store/HRMS/LeaveRequest/actions";
import {
  getEmployeeList,
  getDepartmentListData,
} from "../../../store/HRMS/EmployeeDataBase/actions";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import searchIcon from "../../../assets/images/search.png";
import InputAdormentSearch from "../../../components/InputAdorment/InputAdormentSearch";
import moment from "moment";
import swal from "sweetalert";

class ApplyLeave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      employeeListData: [],
      empData: [],
      leaveLedgerType: [],
      leaveLedger: [],
      department: [],
      department_value: "",
      emp_code: JSON.parse(localStorage.getItem("authUser")).ebNo,
      leave_type: "",
      leaves_in_month: "",
      leaves_in_year: "",
      from_date: "",
      to_date: "",
      reason: "",
      display: false,
      showCard: false,
      show: false,
      showPopup: false,
      data: [],
      CreateButton: false,
      ebId: "",
      ApproveButton: false,
      RejectButton: false,
    };
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    if (this.props.location.state !== undefined) {
      this.props.getLeaveRequestViewByIdList(
        serverApi.VIEW_BY_ID_LEAVE_REQUEST +
          this.props.location.state.rowData.leaveTransactionId +
          "/" +
          this.state.userDit.userId
      );

      var fromDate = moment(
        this.props.location.state.rowData.leaveFromDate,
        "DD-MM-YYYY",
        true
      ).format("YYYY-MM-DD");
      var toDate = moment(
        this.props.location.state.rowData.leaveToDate,
        "DD-MM-YYYY",
        true
      ).format("YYYY-MM-DD");
      this.state.reason = this.props.location.state.rowData.leavePurpose;
      this.state.leave_type = this.props.location.state.rowData.leaveTypeId;
      this.state.from_date = fromDate;
      this.state.to_date = toDate;
      this.setState({
        emp_code: this.props.location.state.rowData.ebNo,
        leave_type: this.props.location.state.rowData.leaveTypeId,
        from_date: fromDate,
        to_date: toDate,
        reason: this.props.location.state.rowData.leavePurpose,
      });

      if (this.props.leaveLedgerList.data) {
        this.setState({
          leaveLedgerType: this.props.leaveLedgerList.data.leaveTypes,
          leaveLedger: this.props.leaveLedgerList.data.ledgers,
        });

        if (this.props.leaveLedgerList) {
          this.state.data = this.props.leaveLedgerList.data.ledgers.filter(
            (item) =>
              item.leaveTypeId === this.props.location.state.rowData.leaveTypeId
          );
        }
        if (this.state.data.length !== 0) {
          this.setState({
            leaves_in_month: this.state.data[0].maxLeavesPerMonth,
            leaves_in_year: this.state.data[0].leavesPerYear,
          });
        }
      }
    }
  }
  componentWillReceiveProps(props) {
    if (props.WorkerDataByEbNo) {
      if (props.WorkerDataByEbNo.data) {
        if (props.WorkerDataByEbNo.data !== undefined) {
          if (props.WorkerDataByEbNo.data.empStatus == 35) {
            this.setState({
              empData: props.WorkerDataByEbNo.data,
              ebId: props.WorkerDataByEbNo.data.ebId,
              display: true,
            });
          } else {
            this.setState({
              display: false,
              show: true,
            });
          }
        }
      } else {
        this.setState({
          display: false,
          show: true,
        });
      }
    }
    if (props.leaveLedgerList.data) {
      this.setState({
        leaveLedger: props.leaveLedgerList.data.ledgers,
      });
      if (props.leaveLedgerList.data.leaveTypes) {
        if (props.leaveLedgerList.data.leaveTypes.length !== 0) {
          let List = props.leaveLedgerList.data.leaveTypes.filter(
            (item) => item.value !== 0
          );
          this.setState({
            leaveLedgerType: List,
          });
        }
      }
      if (props.leaveLedgerList.status) {
        if (props.WorkerDataByEbNo) {
          if (props.WorkerDataByEbNo.data) {
            if (props.WorkerDataByEbNo.data !== undefined) {
              if (props.WorkerDataByEbNo.data.empStatus == 35) {
                this.setState({
                  CreateButton: true,
                });
              }
            }
          }
        }
      }
    }
    if (props.leaveRequestViewList) {
      this.setState({
        ApproveButton: props.leaveRequestViewList.approveButton,
        RejectButton: props.leaveRequestViewList.approveButton,
      });
    }
  }
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "type") {
      this.setState({
        leave_type: selectedValue,
      });
      var data = this.state.leaveLedger.filter(
        (item) => item.leaveTypeId === selectedValue
      );
      this.setState({
        leaves_in_month: data[0].maxLeavesPerMonth,
        leaves_in_year: data[0].leavesPerYear,
      });
    }
  };
  handleSelectDate = (e, name) => {
    if (name === "FromDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      if (this.state.to_date !== "") {
        if (Date > this.state.to_date) {
          swal("From Date Shouldn't be Greter Than To Date");
          this.setState({
            from_date: null,
          });
        } else {
          this.setState({
            from_date: Date,
          });
        }
      } else {
        this.setState({
          from_date: Date,
        });
      }
    }
    if (name === "ToDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      if (Date < this.state.from_date) {
        swal("To Date Should be Greter Than From Date");
        this.setState({
          to_date: null,
        });
      } else {
        this.setState({
          to_date: Date,
        });
      }
    }
  };
  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "EmpCode") {
      this.setState({
        emp_code: event,
      });
    }
    if (key === "LeavesInMonth") {
      this.setState({
        leaves_in_month: event,
      });
    }
    if (key === "LeavesInYear") {
      this.setState({
        leaves_in_year: event,
      });
    }
    if (key === "Reason") {
      this.setState({
        reason: event,
      });
    }
  };
  onclickOk = () => {
    this.setState({
      show: false,
      showPopup: false,
    });
    this.props.getWorkerDataClear(null);
  };
  onClick = () => {
    this.props.getWorkerDataByEbNo(
      serverApi.WORKER_LIST +
        this.state.emp_code +
        "/" +
        localStorage.getItem("companyId") +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).userId,
      this.props.history
    );
    this.setState({
      showCard: true,
      leave_type: "",
    });
  };
  handleKeyPress = (event) => {
    this.props.getWorkerDataByEbNo(
      serverApi.WORKER_LIST +
        this.state.emp_code +
        "/" +
        localStorage.getItem("companyId") +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).userId,
      this.props.history
    );
  };
  onClickNext = () => {
    if (
      this.state.leave_type === "" ||
      this.state.leave_type === null ||
      this.state.leave_type === undefined
    ) {
      swal("Please Enter The Leave Type");
    } else if (this.state.from_date == "" || this.state.from_date == null) {
      swal("Please Enter the From Date");
    } else if (this.state.to_date == "" || this.state.to_date == null) {
      swal("Please Enter the To Date");
    } else if (this.state.reason == "") {
      swal("Please Enter The Reason");
    } else {
      var fromDate = moment(this.state.from_date, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      );
      var toDate = moment(this.state.to_date, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      );
      var created = this.state.userDit.userId.toString();

      const data = {
        companyId: this.state.companyId,
        createdBy: created,
        ebId: this.state.ebId,
        leaveFromDate: fromDate,
        leavePurpose: this.state.reason,
        leaveToDate: toDate,
        leaveTypeId: this.state.leave_type,
      };

      this.props.addLeaveTransaction(
        serverApi.ADD_LEAVE_TRANSACTION,
        data,
        this.props.history
      );
    }
  };
  update = () => {
    if (
      this.state.leave_type === "" ||
      this.state.leave_type === null ||
      this.state.leave_type === undefined
    ) {
      swal("Please Enter The Leave Type");
    } else if (this.state.from_date == "") {
      swal("Please Enter the From Date");
    } else if (this.state.to_date == "") {
      swal("Please Enter the To Date");
    } else if (this.state.reason == "") {
      swal("Please Enter The Reason");
    } else {
      var fromDate = moment(this.state.from_date, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      );
      var toDate = moment(this.state.to_date, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      );
      var updated = this.state.userDit.userId.toString();
      const data = {
        companyId: this.state.companyId,
        ebId: this.props.location.state.rowData.ebId,
        leaveFromDate: fromDate,
        leavePurpose: this.state.reason,
        leaveToDate: toDate,
        leaveTransactionId:
          this.props.location.state.rowData.leaveTransactionId,
        leaveTypeId: this.state.leave_type,
        status: "",
        updatedBy: updated,
      };
      this.props.updateLeave(serverApi.UPDATE_LEAVE, data, this.props.history);
    }
  };
  approve = () => {
    if (
      this.state.leave_type === "" ||
      this.state.leave_type === null ||
      this.state.leave_type === undefined
    ) {
      swal("Please Enter The Leave Type");
    } else if (this.state.from_date == "") {
      swal("Please Enter the From Date");
    } else if (this.state.to_date == "") {
      swal("Please Enter the To Date");
    } else if (this.state.reason == "") {
      swal("Please Enter The Reason");
    } else {
      var fromDate = moment(this.state.from_date, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      );
      var toDate = moment(this.state.to_date, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      );
      const data = {
        companyId: this.state.companyId,
        ebId: this.props.location.state.rowData.ebId,
        leaveFromDate: fromDate,
        leavePurpose: this.state.reason,
        leaveToDate: toDate,
        leaveTransactionId:
          this.props.location.state.rowData.leaveTransactionId,
        leaveTypeId: this.state.leave_type,
        status: Number(this.props.leaveRequestViewList.status),
        updatedBy: this.state.userDit.userId,
      };
      this.props.updateLeave(serverApi.UPDATE_LEAVE, data, this.props.history);
    }
  };
  reject = () => {
    if (
      this.state.leave_type === "" ||
      this.state.leave_type === null ||
      this.state.leave_type === undefined
    ) {
      swal("Please Enter The Leave Type");
    } else if (this.state.from_date == "") {
      swal("Please Enter the From Date");
    } else if (this.state.to_date == "") {
      swal("Please Enter the To Date");
    } else if (this.state.reason == "") {
      swal("Please Enter The Reason");
    } else {
      var fromDate = moment(this.state.from_date, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      );
      var toDate = moment(this.state.to_date, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      );
      const data = {
        companyId: this.state.companyId,
        ebId: this.props.location.state.rowData.ebId,
        leaveFromDate: fromDate,
        leavePurpose: this.state.reason,
        leaveToDate: toDate,
        leaveTransactionId:
          this.props.location.state.rowData.leaveTransactionId,
        leaveTypeId: this.state.leave_type,
        status: 4,
        updatedBy: this.state.userDit.userId,
      };
      this.props.updateLeave(serverApi.UPDATE_LEAVE, data, this.props.history);
    }
  };
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <div className="applyLeave">
        <div className=" d-flex">
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            className="leaveRequest"
          >
            <Box gridColumn="span 6">
              <div className="consoleFormBlock">
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                  className="leaveRequest"
                >
                  <Box gridColumn="span 6">
                    <div className="consoleTextFieldBlock">
                      <InputAdormentSearch
                        label={"Employee Code"}
                        placeholder="Enter here"
                        imgUrl={searchIcon}
                        handleChange={this.handleChange("EmpCode")}
                        onClick={this.onClick}
                        value={this.state.emp_code}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 6">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={
                          this.props.location.state !== undefined &&
                          this.props.location.state.rowData.leaveType
                            ? 1
                            : 0
                        }
                        arrayOfData={
                          this.state.leaveLedgerType.length !== 0
                            ? this.state.leaveLedgerType.map((item) => ({
                                label: item.label,
                                name: item.name,
                                value: item.value,
                              }))
                            : []
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.leave_type}
                        name="type"
                        label="Leave Type"
                        required
                      />
                    </div>
                  </Box>
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                  className="leaveRequest"
                >
                  <Box gridColumn="span 6">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        disabled={true}
                        label="Leaves in Month"
                        placeholder="Leaves left"
                        value={this.state.leaves_in_month}
                        onChange={this.handleChange("LeavesInMonth")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 6">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        disabled={true}
                        label="Leaves in Year"
                        placeholder="Leaves left"
                        value={this.state.leaves_in_year}
                        onChange={this.handleChange("LeavesInYear")}
                      />
                    </div>
                  </Box>
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                  className="leaveRequest"
                >
                  <Box gridColumn="span 6">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>
                          From Date<span className="mandatoryField">*</span>
                        </label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          name="FromDate"
                          inputFormat="dd-MM-yyyy"
                          id="FromDate"
                          value={this.state.from_date}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "FromDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box gridColumn="span 6">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>
                          To Date <span className="mandatoryField">*</span>
                        </label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          name="ToDate"
                          inputFormat="dd-MM-yyyy"
                          id="ToDate"
                          value={this.state.to_date}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "ToDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                </Box>
                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)">
                  <Box gridColumn="span 6">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        label="Reason"
                        placeholder="Enter here"
                        value={this.state.reason}
                        onChange={this.handleChange("Reason")}
                      />
                    </div>
                  </Box>
                </Box>
              </div>
            </Box>
            {this.state.display && (
              <Box gridColumn="span 6">
                <LeaveBalance
                  data={this.state.empData}
                  leaveLedger={this.state.leaveLedger}
                />
              </Box>
            )}
          </Box>
        </div>

        {this.props.location.state === undefined && this.state.CreateButton && (
          <div className="consoleFormButtonBlock">
            <Link to="/leaverequest">
              <CustomButton label={"Cancel"} className="greenBorderButton" />
            </Link>
            <CustomButton
              label={"Apply Leave"}
              className="greenButton"
              handleClick={this.onClickNext}
              type="sumbit"
            />
          </div>
        )}
        {this.props.location.state !== undefined && (
          <div className="consoleFormButtonBlock">
            <>
              {this.props.leaveRequestViewList.status !== "3" &&
              this.props.leaveRequestViewList.status !== "4" ? (
                <CustomButton
                  label={"Update"}
                  className="greenButton"
                  handleClick={this.update}
                  type="sumbit"
                />
              ) : (
                ""
              )}

              {this.state.ApproveButton === true ? (
                <CustomButton
                  label={"Approve"}
                  className="greenButton"
                  handleClick={this.approve}
                  type="sumbit"
                />
              ) : (
                ""
              )}
              {this.state.RejectButton == true ? (
                <CustomButton
                  label={"Reject"}
                  className="rejected"
                  handleClick={this.reject}
                  type="sumbit"
                />
              ) : (
                ""
              )}
            </>
          </div>
        )}
        {this.state.showPopup && (
          <CommonPopup>
            <div className="delAddItem">
              <div className="delAddItemBtns">
                <input
                  type="button"
                  onClick={this.onclickOk.bind(this)}
                  value="Ok"
                  className="delYesBtn"
                />
              </div>
            </div>
          </CommonPopup>
        )}
        {this.state.show && (
          <CommonPopup>
            <div className="delAddItem">
              <div>No Employee found under the Current User!</div>
              <div className="delAddItemBtns">
                <input
                  type="button"
                  onClick={this.onclickOk.bind(this)}
                  value="Ok!"
                  className="delYesBtn"
                />
              </div>
            </div>
          </CommonPopup>
        )}
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { employeeList, departmentList } = state.EmployeeReducer;
  const {
    leaveLedgerList,
    addLeaveTransaction,
    leaveRequestViewList,
    WorkerDataByEbNo,
  } = state.LeaveRequestReducer;
  return {
    employeeList,
    leaveLedgerList,
    departmentList,
    leaveRequestViewList,
    WorkerDataByEbNo,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getEmployeeList,
    getLeaveLedgerList,
    getDepartmentListData,
    addLeaveTransaction,
    updateLeave,
    getLeaveRequestViewByIdList,
    getWorkerDataByEbNo,
    getWorkerDataClear,
  })(ApplyLeave)
);
