export const INWARD_LIST = "inward_list";
export const INWARD_LIST_SUCCESSFULL = "inward_list_successfull";
export const INWARD_LINE_ITEMS = "inward_lineitmes";
export const POLINEITEMSBYPOIDS = "polineitems_bypoids";
export const POLINEITEMSBYPOIDS_SUCCESSFULL = "polineitems_bypoids_successfull";
export const CREATE_INWARD = 'create_inward';
export const CREATE_INWARD_SUCCESSFULL ='create_inward_successfull';
export const UPDATE_INWARD = 'update_inward';
export const UPDATE_INWARD_SUCCESSFULL ='update_inward_successfull';
export const UPDATE_STORE = 'update_store';
export const UPDATE_STORE_SUCCESSFULL ='update_store_successfull';
export const UPDATE_MATERIAL_INSPECTION = 'update_material_inspection';
export const UPDATE_MATERIAL_INSPECTION_SUCCESSFULL ='update_material_inspection_successfull';
export const GET_FILES_OF_INWARD = "get_files_of_inward";
export const GET_FILES_OF_INWARD_SUCCESSFULL = "get_files_of_inward_successfull";
export const GET_FILES_OF_MI = "get_files_of_mi";
export const GET_FILES_OF_MI_SUCCESSFULL = "get_files_of_mi_successfull";
export const GET_FILES_OF_SR = "get_files_of_sr";
export const GET_FILES_OF_SR_SUCCESSFULL = "get_files_of_sr_successfull";
export const CHECKVALIDSUPPID_INVOICE_CHALLAN = "CheckValidSuppId_invoice_challan"
export const CHECKVALIDSUPPID_INVOICE_CHALLAN_SUCCESSFULL = "CheckValidSuppId_invoice_challan_successfull"

export const GET_ITEMLIST_BYITEMGROUP_FORINWARD = "get_ItemList_by_itemgroup_forinward";
export const GET_ITEMLIST_BYITEMGROUP_FORINWARD_SUCCESSFULL = "get_ItemList_by_itemgroup_forinward_successfull";
export const GET_ITEMDETAILS_BYITEM_ITEMGRP_FORINWARD = "get_ItemDETAILS_By_itemgroup_forinward";
export const GET_ITEMDETAILS_BYITEM_ITEMGRP_FORINWARD_SUCCESSFULL = "get_ItemDETAILS_By_itemgroup_forinward_successfull";

