/* Created By Nagesh Medisetty on 19-04-2022 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "./DynamicSelect";
import TextFieldAlfaNum from "../../../components/TextField/TextFieldAlfaNum";
import { getIndentListMaster } from "../../../store/Master/ItemMaster/actions";
import { serverApi } from "../../../helpers/Consts";
import { getBranchList } from "../../../store/Global/DropDownApis/actions";
import {
  getCustomerList,
  getProjectList,
} from "../../../store/StoreModule/MaterialRequest/actions";
import {
  getAllCompanyList,
  allGetBranchList,
} from "../../../store/StoreModule/StockTransfer/actions";

import moment from "moment";

class StockTransferFillDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      expense_type: this.props.header.expense_type,
      expense_name: "",
      branchId: this.props.header.branchId,
      branch_name: 0,
      customer_type: this.props.header.customer_type,
      customer_name: "",
      project_type: this.props.header.project_type,
      date: this.props.header.date,
      project_name: 0,
      expenseType: [],
      branchList: [],
      customerList: [],
      projectList: [],
      internalNote: this.props.header.internalNote,
      desgCompanyId: this.props.header.desgCompanyId,
      srcComp: this.props.header.srcComp,
      DesignationList: [],
      destinationBranchId: this.props.header.destinationBranchId,
      DestinationBranch: [],
    };
  }

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "expenseType") {
      this.props.header.expense_type = selectedValue;
      this.props.header.expense_name = selectedName;
      var indentId = selectedName;
      var indentTypeId = indentId.split("_");
      this.props.header.indentTypeId = indentTypeId[1];
      this.setState({
        expense_type: selectedValue,
      });
    }
    if (name === "branch") {
      this.props.getCustomerList(
        serverApi.CUSTOMER_LIST +
          localStorage.getItem("companyId") +
          "/" +
          selectedValue
      );
      this.props.getProjectList(
        serverApi.PROJECT_LIST +
          localStorage.getItem("companyId") +
          "/customer/0/branch/" +
          selectedValue
      );
      this.props.header.branchId = selectedValue;
      this.setState({
        branchId: selectedValue,
      });
    }
    if (name === "desgnationCompany") {
      this.props.header.desgCompanyId = selectedValue;

      this.props.allGetBranchList(
        serverApi.BRANCH_LIST + selectedValue,
        this.props.history
      );
      this.setState({
        desgCompanyId: selectedValue,
      });
    }

    if (name === "sourceCompany") {
      this.props.header.srcComp = selectedValue;

      this.setState({
        srcComp: selectedValue,
      });
    }

    if (name === "destinationBranch") {
      this.props.header.destinationBranchId = selectedValue;

      this.setState({
        destinationBranchId: selectedValue,
      });
    }

    if (name === "customerName") {
      this.props.header.customer_type = selectedValue;

      this.setState({
        customer_type: selectedValue,
        customer_name: selectedName,
      });
    }
    if (name === "project") {
      this.props.header.project_type = selectedValue;
      this.setState({
        project_type: selectedValue,
        project_name: selectedName,
      });
    }
    this.props.onSelectDep(selectedValue, selectedName, name);
  };

  componentDidMount() {
    this.props.getIndentListMaster(
      serverApi.INDENT_TYPE_LIST_MASTERS,
      this.props.history
    );
    this.props.getBranchList(
      serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
      this.props.history
    );
    var data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
    };
    this.props.getAllCompanyList(
      serverApi.COMPANY_LIST,
      data,
      this.props.history
    );

    this.setState({
      branchId: this.props.header.branchId,
    });
  }

  componentWillReceiveProps(props) {
    let DesignationList = [];

    if (props.Indent_List_Master.data) {
      this.setState({
        expenseType: props.Indent_List_Master.data,
      });
    }
    if (props.branchList.data) {
      this.setState({
        branchList: props.branchList.data,
      });
    }

    if (props.companyList.data) {
      props.companyList.data.map((prop) => {
        DesignationList.push({
          value: prop.companyId,
          label: prop.companyCode + "-" + prop.companyName,
          name: prop.companyCode + "-" + prop.companyName,
        });
        this.setState({
          DesignationList: DesignationList,
        });
      });
    }

    if (props.desgbranchList.data) {
      this.setState({
        DestinationBranch: props.desgbranchList.data,
      });
    }

    if (this.props.location.state) {
      this.props.header.date = this.props.location.state.rowData.date;
      this.setState({
        date: this.props.location.state.rowData.date,
      });
    }
  }

  handleSelect_Date = (e, name) => {
    if (name === "date") {
      var dateVal = e;
      var date = moment(dateVal).format();

      var fromdate = date.split("T", 1).toString();

      this.props.header.date = fromdate;

      this.setState({
        date: fromdate,
      });

      this.props.handleSelectDate(fromdate, name);
    }
  };

  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "internalNote") {
      this.props.header.internalNote = event;
      this.setState({
        internalNote: event,
      });
    }
  };

  render() {
    return (
      <div className="consoleFormContainer">
        <h5>Fill Details</h5>
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>Stock Transfer Date</label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="transactionDate"
                          value={this.state.date}
                          fullWidth
                          onChange={(e) => this.handleSelect_Date(e, "date")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Box>
                {this.props.location.state ? (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.DesignationList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.state.srcComp}
                        name="sourceCompany"
                        label="Source Company"
                        update={this.props.location.state ? 1 : 0}
                        required
                      />
                    </div>
                  </Box>
                ) : null}
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.branchList}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      selected={this.state.branchId}
                      name="branch"
                      label="Source Branch"
                      update={this.props.location.state ? 1 : 0}
                      required
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.DesignationList}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      selected={this.state.desgCompanyId}
                      name="desgnationCompany"
                      label="Destination Company"
                      update={this.props.location.state ? 1 : 0}
                      required
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.DestinationBranch}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      selected={this.state.destinationBranchId}
                      name="destinationBranch"
                      label="Destination Branch"
                      update={this.props.location.state ? 1 : 0}
                      required
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={this.state.expenseType}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      selected={this.state.expense_type}
                      update={this.props.location.state ? 1 : 0}
                      name="expenseType"
                      label="Expenses Type"
                      required
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    {/* <TextFieldAlfaNum type="textarea" label="Internal Note" />
                     */}
                    <TextFieldAlfaNum
                      label="Remark"
                      value={this.state.internalNote}
                      type="textarea"
                      onChange={this.handleChange("internalNote")}
                      placeholder="Enter Here"
                    />
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Indent_List_Master } = state.ItemListReducer;
  const { branchList } = state.DropDownListReducer;
  const { customerList, projectList } = state.Material_Request_List;
  const { companyList, desgbranchList } = state.StockTransfer_List;
  return {
    Indent_List_Master,
    branchList,
    customerList,
    projectList,
    companyList,
    desgbranchList,
  };
};
export default connect(mapStatetoProps, {
  getIndentListMaster,
  getBranchList,
  getCustomerList,
  getProjectList,
  getAllCompanyList,
  allGetBranchList,
})(withRouter(StockTransferFillDetails));
