import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TabsNavigation from "../../../../../components/TabsNavigation/TabsNavigation";
import ESI from "./ESI";
import PF from "./PF";

class MedicalEnrollment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      value: 0,
    };
  }
  onvalue = (id, display) => {
    this.setState({
      value: this.state.value + 1,
    });
  };
  render() {
    return (
      <>
        <TabsNavigation
          tabOneLabel="Employee's State Insurance (ESI)"
          tabTwoLabel="Provident Fund"
          tabOneContent={<ESI />}
          tabTwoContent={<PF />}
          setValue={this.state.value}
        />
      </>
    );
  }
}

const mapStatetoProps = (state) => {};

export default withRouter(connect(mapStatetoProps, {})(MedicalEnrollment));
