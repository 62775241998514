import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import DynamicSelect from "../../components/Dropdown/Master/DynamicSelectMasters";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';  
import TextFieldNormal from "../../components/TextField/TextFieldNormal";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';   
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'; 
import moment from "moment";

class IndentFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      Status: '',
      StatusValue: '',
      projectListData: [],
      branchListData: [],
      statusListData: [],
      project: "",
      projctValue: "",
      branchId: "",
      fromDate:moment().day(-7).format("YYYY-MM-DD").toString(),
      toDate: moment().format("YYYY-MM-DD").toString(),
      indentNum:'',
    };
  }

  componentDidMount() {
    const financialYearStartMonth = 3; // Assuming financial year starts in April
    let financialYearStart = moment()
      .year(localStorage.getItem("acadamicYear"))
      .month(financialYearStartMonth)
      .startOf("month");

    const fromDate = financialYearStart.day(-7).format("YYYY-MM-DD");
    const toDate = moment().format("YYYY-MM-DD");

    this.setState({
      fromDate:fromDate,
      toDate:toDate ,
    })
    if (this.props.projectList && Array.isArray(this.props.projectList.data)) {
      let data = this.props.projectList.data
      if (Array.isArray(data) || (typeof data === "object" && data !== null)) {
      const newObj ={
        value:"",
        name:"Select...",
        label:"Select..."
      }
      const newArray = [
        newObj,
        ...(Array.isArray(data) ? data : Object.values(data)),
      ];
        this.setState({
          projectListData: newArray,
        });
      }
    }
    if (this.props.branchList) {
      this.setState({
        branchListData: this.props.branchList.data,
      });
    }
    if (this.props.statusList) {
      this.setState({
        statusListData: this.props.statusList.data,
      });
    }
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "project") {
      this.setState({
        projctValue: selectedValue,
        project: selectedName,
      });
    }
    if (name === "Status") {
      this.setState({
        StatusValue: selectedValue,
        status: selectedName,
      });
    }

    if (name === "branch") {
      this.setState({
        branchId: selectedValue,
      
      });
    }
  };
  handleSelectDate = (e, name) => {
    if (name === "FromDate") {
      let date = moment(e).format();
      let formattedFromDate = date.split("T", 1).toString();

      this.setState({
        fromDate: formattedFromDate,
      });
    }

    if (name === "ToDate") {

      let date = moment(e).format();
      let formattedToDate = date.split("T", 1).toString();
      this.setState({
        toDate: formattedToDate,
      });
    }
    
  };

  handleChange = (key)=> (event)=>{
    if(key === "indentNum"){
      this.setState({
        indentNum:event
      })
    }
  }

  handleSubmit = () => {
    
    var data = {
      fromDate: moment(this.state.fromDate,"YYYY-MM-DD",true).format("DD-MM-YYYY"),
      toDate:  moment(this.state.toDate,"YYYY-MM-DD",true).format("DD-MM-YYYY"),
      project: this.state.project,
      projctValue: this.state.projctValue,
      branchId: this.state.branchId,
      Status: this.state.Status,
      statusValue: this.state.StatusValue,
      indentNum:this.state.indentNum
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleClear = () => {
    this.setState({
      Status: '',
      StatusValue:'',
      project: "",
      projctValue: "",
      branchId: "",
      branchValue: "",
      fromDate: "",
      toDate: "",
    });
  };

  render() {

    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          // TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid
            spacing={0}
          >
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
                {/* <div>Save View</div> */}
              </div>
            </div>
            <Grid item xs={12} className="filterBlockContainer">
              <Box gridColumn="span 3">
           
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>From Date</label>
                      </div>
                      {/* <ConsoleDatePicker /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.fromDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "FromDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                
                  {/* </Box>
              <Box gridColumn="span 3"> */}
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>To Date</label>
                      </div>
                      {/* <ConsoleDatePicker /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.toDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "ToDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={this.state.statusListData}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handleSelectChange}
                    selected={this.state.StatusValue}
                    update={this.state.StatusValue ? 1 : 0}
                    name="Status"
                    label="Status"
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={
                      this.state.projectListData &&
                      this.state.projectListData.map((item) => ({
                        label: item.name,
                        value: item.projectId,
                        name: item.name,
                      }))
                    }
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handleSelectChange}
                    selected={this.state.projctValue}
                    update={this.state.projctValue ? 1 : 0}
                    name="project"
                    label="Project"
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={this.state.branchListData}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handleSelectChange}
                    selected={this.state.branchId}
                    update={this.state.branchId ? 1 : 0}
                    name="branch"
                    label="Branch"
                  />
                </div>
              </Box>
              {this.props.filter_form === "boq" && (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="BOQ No"
                        value={this.state.indentNum}
                        onChange={this.handleChange("indentNum")}
                      />
                    </div>
                  </Box>
                )}

              {this.props.filter_form === "openIndent" && (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Open Indent No"
                        value={this.state.indentNum}
                        onChange={this.handleChange("indentNum")}
                      />
                    </div>
                  </Box>
                )}

                {this.props.filter_form === "bom" && (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="BOM No"
                        value={this.state.indentNum}
                        onChange={this.handleChange("indentNum")}
                      />
                    </div>
                  </Box>
                )}

                {this.props.filter_form === "indent" && (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Indent No"
                        value={this.state.indentNum}
                        onChange={this.handleChange("indentNum")}
                      />
                    </div>
                  </Box>
                )}

              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Search"}
                  className="greenButton"
                  handleClick={this.handleSubmit}
                  type="sumbit"
                />
                {/* <CustomButton
                  label={"Clear"}
                  className="greenButton"
                  handleClick={this.handleClear}
                  type="sumbit"
                /> */}
              </div>
            </Grid>

          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  const { branchList, projectList, statusList } = state.DropDownListReducer;
  return { projectList, branchList, statusList };
};

export default withRouter(
  connect(mapStatetoProps, {
    
  })(IndentFilter)
);
