import React, { Component } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import VowLogo from "../../assets/images/vow_logo.png";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import IconButton from "@mui/material/IconButton";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// actions
import { resetpassword, apiError } from "../../store/auth/login/actions";

import { serverApi } from "../../helpers/Consts";

class CreatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = { username: "su", password: "welcome" };
    this.state = {
      show: "false",
      hidden: "false",
      passwordExist: "",
      passwordVerify: "",
      message: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputOnChange = this.handleInputOnChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputOnChangeShowPassword =
      this.handleInputOnChangeShowPassword.bind(this);
  }
  handleInputOnChange(event) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  }
  handleInputOnChangeShowPassword(event) {
    event.preventDefault();

    this.setState({ hidden: !this.state.hidden });
  }
  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    if (this.state.passwordExist) {
      if (target.value !== this.state.passwordExist) {
        this.setState({ message: "Passwords must be same" });
      } else {
        this.setState({ message: "" });
      }
    } else {
      this.setState({ message: "Sorry! Enter Password" });
    }
    this.setState({
      [target.name]: target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.passwordExist === this.state.passwordVerify) {
      let oldpassword = "welcome";
      let password = this.state.passwordVerify;
      let reqSource = 0;
      let userDit = JSON.parse(localStorage.getItem("authUser"));
      let userId = userDit.userId;

      const data = {
        oldpassword,
        password,
        reqSource,
        userId,
      };

      /* Login  */
      this.props.resetpassword(
        serverApi.MODIFY_PASSWORD,
        data,
        this.props.history
      );
    } else {
      this.setState({ message: "Passwords must be same" });
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          sx={{ height: "100vh" }}
          className="loginPatternOne"
        >
          <Grid item xs={false} sm={4} md={6} className="loginBackground">
            <Box className="loginLeftContent">
              <h2>Nice to meet you</h2>
              <h1>WELCOME</h1>
              <p>
                We have customized this product according to your needs and
                can't wait to see you using this product. This will simplify
                working of your business
              </p>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            component={Paper}
            elevation={6}
            square
            className="loginContainer"
          >
            <Box
              className="loginBlock"
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={VowLogo} alt="" />
              <Typography component="h1">Create Password</Typography>
              <Typography component="p">
                Create Password and proceed with the company login process
              </Typography>
              <form
                onSubmit={this.handleSubmit}
                className="resetPasswordFormValidation"
              >
                <Box noValidate sx={{ mt: 1 }}>
                  <div className="resetPasswordTextField">
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="passwordExist"
                      value={this.state.passwordExist}
                      onChange={this.handleInputOnChange}
                      autoComplete="current-password"
                      autoFocus
                      className="loginInput"
                      placeholder="New Password"
                      type={this.state.hidden ? "password" : "text"}
                    />
                    <IconButton
                      onClick={this.handleInputOnChangeShowPassword}
                      className="displayIcon"
                    >
                      {this.state.hidden ? (
                        <VisibilityOffRoundedIcon />
                      ) : (
                        <VisibilityRoundedIcon />
                      )}
                    </IconButton>
                  </div>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="passwordVerify"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={this.state.passwordVerify}
                    onChange={this.handleInputChange}
                    className="loginInput"
                    placeholder="Re-type Password"
                  />
                  <div className="resetPasswordErrorMessage">
                    {this.state.message}
                  </div>
                  <Button className="loginButton" type="sumbit">
                    Create Password
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}
const theme = createTheme();
const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(
  connect(mapStatetoProps, { resetpassword, apiError })(CreatePassword)
);
