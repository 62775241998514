import {
  CATEGORY_LIST,
  CONTRACTOR,
  DEPARTMENT_LIST_DATA,
  DESIGNATION,
  EDUCATIONAL_DETAILS,
  EMPLOYEE_LIST,
  EMP_LEVEL,
  LEAVE_LIST,
  LEAVE_POLICY_LIST,
  PERSONAL_DETAILS,
  REPORTING_MANAGER_LIST,
  WORK_LOCATION,
  PAY_SCHEME,
  EMPLOYEE_DETAILS_VIEW_BY_ID,
  EXPERIENCE_DETAILS,
  PAY_SCHEME_BY_ID,
  CHECK_PAY_SCHEME,
  GET_PAYSCHEME_LIST,
  LEAVE_POLICY_MAPPING,
  GET_ALL_FILES_DATA,
  DOWNLOAD_FILES,
  UPDATE_FILE,
  DOWNLOAD_ASSET_FORM,
  OFFER_LETTER_EMAIL_SERVICE,
  EXPERIENCE_DETAILS_VIEW_BY_ID,
  DISPLAY_EMAIL_TEMPLATE,
  ADD_SALARY,
  PAY_WAGES_MODES,
  PAY_COMPONENTS,
  ADD_PAY_SCHEME,
  UPDATE_PAY_SCHEME,
  UPDATE_SALARY,
  HRMS_DASHBOARD,
  PAYSCHEME_BY_ID,
  APPROVAL_HIERARCHY,
  VIEW_BY_ID_APPROVAL,
  UPDATE_LEAVE_POLICY,
  WORKER_DETAILS_PROGRESS,
  GET_WORKER_DETAILS_PROGRESS,
  DELETE_WORKER_EDUCATIONAL_DETAILS,
  UPDATE_WORKER_STATUS,
  VIEW_BY_ID_PERSONAL_DETAILS,
  ESI_DETAILS,
  UPDATE_WORKER_DETAILS,
  EMPLOYEE_PROGRESS_BAR_DATA,
  VIEW_BY_ID_SALARY_STRUCTURE,
  AADHAR_VALIDATION,
  FILE_UPLOAD,
  PROFILE_PIC_DETAILS,
  DELETE_PROFILE_PIC,
  UPLOAD_PROFILE_PIC,
  DELETE_MEDICAL_POLICY,
  DELETE_FILES,
  VIEW_BY_ID_LEAVE_POLICY,
  GET_WORKER_DATA,
  DOWNLOAD_BULK_EMPLOYEE_TEMPLATE,
} from "./actionType";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { hideSpinner, serverApi, showSpinner } from "../../../helpers/Consts";
import {
  employeeListSuccessfull,
  educationalListSuccessfull,
  PersonalDetailsSuccessfull,
  departmentListDataSuccessfull,
  categoryListSuccessfull,
  empLevelSuccessfull,
  ReportingManagerListSuccessfull,
  contractorSuccessfull,
  workLocationSuccessfull,
  designationSuccessfull,
  leavePolicySuccessfull,
  leaveListSuccessfull,
  paySchemeSuccessfull,
  ExperienceDetailsSuccessfull,
  downloadAssetFormsuccessfull,
  paySchemeIdSuccessfull,
  checkPaySchemeSuccessfull,
  AllFilesSuccessfull,
  downLoadFiles,
  getAllFiles,
  offerLetterEmailSuccessfull,
  EmployeeDetailsViewByIdSuccessfull,
  ViewByIdExperienceDetailsSuccessfull,
  getPayschemeList,
  getPayschemeListSuccessfull,
  addSalarySuccessfull,
  displayEmailTemplateSuccessfull,
  payWagesModesSuccessfull,
  payComponentsSuccessfull,
  AddPaySchemeSuccessfull,
  UpdatePaySchemeSuccessfull,
  deleteProfilePicSuccessfull,
  WorkerDetailsProgressSuccessfull,
  updateSalarySuccessfull,
  hrmsDashboardSuccessfull,
  PaySchemeById,
  paySchemeByIdSuccessfull,
  ApprovalHierarchySuccessfull,
  ViewByIdApprovalSuccessfull,
  UpdateleavePolicySuccessfull,
  GetWorkerDetailsProgressSuccessfull,
  deleteEducationalDetailsSuccessfull,
  updateWorkerStatusSuccessfull,
  viewbyidPersonalDetailsSuccessfull,
  ProfilePicFilesSuccessfull,
  ProfilePicFiles,
  updateWorkerDetailsSuccessfull,
  getEmployeeProgressBarSuccessfull,
  viewbyidPersonalDetails,
  AadharValidationSuccessfull,
  setFileUploadSuccessfull,
  UploadProfilePicSuccessfull,
  deleteMedicalPolicyFileSuccessfull,
  deleteFilesDataSuccessfull,
  getViewByIdLeavePolicySuccessfull,
  getLeaveList,
  PaySchemeViewByIdSuccessfull,
  getWorkerDataSuccessfull,
  getDownloadBulkEmployeeTemplateSuccessfull,
  setUpdateEsiDetailsSuccessfull,
  getEBIdSuccessfull,
  PaySchemeViewById,
} from "./actions";
import {
  getList,
  postAdd,
  getListurl,
  getListWithDocsurl,
  getCallBackData,
} from "../../../helpers/Server_Helper";
import swal from "sweetalert";
// Employee Data
function* getEmployeeList({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    yield put(employeeListSuccessfull({ response }));
    if (response) {
      hideSpinner();
    }
  } catch (error) {
    hideSpinner();
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
  }
}
// Personal details
function* setPersonalDetailData({ payload: { url, data, history, location } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data, location);
    if (response) {
      if (response.data.status) {
        swal(response.data.message, { icon: "success" });
        yield put(PersonalDetailsSuccessfull({ response }));
        yield put(
          getEBIdSuccessfull(
            response.data.id === undefined ? data.ebId : response.data.id
          )
        );
        if (!location) {
          history.push("/add_employee");
        } else if (
          location === "bank" ||
          location === "pf" ||
          location === "work"
        ) {
          history.push("/add_employee");
        }
        hideSpinner();
      } else {
        if (response.data.message) {
          swal(response.data.message, { icon: "error" });
        } else {
          swal("Something Went Wrong", { icon: "error" });
        }
        hideSpinner();
      }
    } else {
      hideSpinner();
    }
  } catch (error) {
    hideSpinner();
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}
function* updateESIDetails({ payload: { url, data, history, ebId } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    yield put(setUpdateEsiDetailsSuccessfull({ response }));
    if (response.status) {
      swal(response.data.message, { icon: "success" });
      yield put(
        viewbyidPersonalDetails(
          serverApi.VIEW_ESI_DETAILS_BY_ID + "/" + ebId,
          history
        )
      );
    }
    hideSpinner();
  } catch (error) {
    hideSpinner();
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}

// Educational Data
function* setEducationaldetails({ payload: { url, data, history, ebId } }) {
  try {
    const response = yield call(postAdd, url, data);
    if (response.status) {
      yield put(educationalListSuccessfull({ response }));
      yield put(
        viewbyidPersonalDetails(
          serverApi.VIEW_EMPLOYEE_EDUCATION_DIT + "/" + ebId,
          history,
          4
        )
      );
      if (response.data.message) {
        swal(response.data.message, { icon: "success" });
      } else {
        swal("Updated Successfully", { icon: "success" });
      }
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}
// create
function* setExperienceDetails({ payload: { url, data, obj, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    if (response.status) {
      yield put(ExperienceDetailsSuccessfull({ response }));
      swal(response.data.message, { icon: "success" });
      history.push("/add_employee");
      hideSpinner();
    } else {
      swal(response.data.message, { icon: "error" });
      hideSpinner();
    }
  } catch (error) {
    hideSpinner();
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}
function* deleteMedicalPolicyFileData({
  payload: { url, history, fileUploadId },
}) {
  try {
    const response = yield call(getListurl, url);
    yield put(deleteMedicalPolicyFileSuccessfull({ response }));
    swal("Deleted Successfully", { icon: "success" });
  } catch (error) {
    console.log(error);
  }
}

// Work Details
function* getDepartment({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(departmentListDataSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
// Category
function* getCategoryList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(categoryListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
// Emp Level
function* getEmpLevel({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(empLevelSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
// Reporting Manager
function* getReportingManager({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(ReportingManagerListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
// Contractor
function* getContractor({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(contractorSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
// WorkLocation
function* getWorkLocation({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(workLocationSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
// Designation
function* getDesignation({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(designationSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
// Leave Policy
function* getLeavePolicy({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(leavePolicySuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* UpdateLeavePolicy({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    if (response) {
      if (response.data.status) {
        swal(response.data.message, { icon: "success" });
        yield put(UpdateleavePolicySuccessfull({ response }));
        history.push("/add_employee");
      } else {
        swal(response.data.message, { icon: "error" });
      }
      hideSpinner();
    } else {
      hideSpinner();
    }
  } catch (error) {
    hideSpinner();
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}

function* getLeaveListss({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(leaveListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getPayScheme({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(paySchemeSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getPaySchemeId({ payload: { url, value, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (response) {
      var obj = {};
      if (response.data) {
        if (response.data.paySchemeDetailsDTO) {
          response.data.paySchemeDetailsDTO.map((prop) => {
            obj = { ...obj, [prop.componentId]: prop.formula };
          });
          let Url = serverApi.CHECK_PAY_SCHEME + "1111/" + value;
          yield put(getPayschemeList(Url, obj, history));
          //   this.props.getPayschemeList(serverApi.CHECK_PAY_SCHEME + '1111/' + value, obj, history);
        }
      }
      yield put(paySchemeIdSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getPaySchemeById({ payload: { url, value, viewByid, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (response) {
      var obj = {};
      if (response.data) {
        if (response.data.paySchemeDetailsDTO) {
          response.data.paySchemeDetailsDTO.map((prop) => {
            obj = { ...obj, [prop.componentId]: prop.formula };
          });
          if (viewByid == true) {
          } else {
            let Url = serverApi.CHECK_PAY_SCHEME + "1111/" + value;
            yield put(getPayschemeList(Url, obj, history));
          }
        }
      }
    }
    yield put(paySchemeByIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* addingSalary({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    if (response) {
      yield put(addSalarySuccessfull({ response }));
      if (response.message == "Created Successfully.") {
        if (data) {
          if (data.payEmployeeDTO) {
            if (data.payEmployeeDTO.employeeid) {
              yield put(
                PaySchemeViewById(
                  serverApi.VIEW_EMPLOYEE_SALARY_DIT +
                    "/" +
                    data.payEmployeeDTO.employeeid
                )
              );
            }
          }
        }
      }
      swal(response.message, { icon: "success" });
      hideSpinner();
    } else {
      hideSpinner();
    }
  } catch (error) {
    hideSpinner();
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}
function* updateSalary({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    if (response) {
      yield put(updateSalarySuccessfull({ response }));

      if (response.message == "Updated Successfully.") {
        if (data) {
          if (data.payEmployeeDTO) {
            if (data.payEmployeeDTO.employeeid) {
              yield put(
                PaySchemeViewById(
                  serverApi.VIEW_EMPLOYEE_SALARY_DIT +
                    "/" +
                    data.payEmployeeDTO.employeeid
                )
              );
            }
          }
        }
      }
      swal(response.message, { icon: "success" });
      hideSpinner();
    } else {
      hideSpinner();
    }
  } catch (error) {
    hideSpinner();
    console.log(error);
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}

function* checkPayScheme({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(checkPaySchemeSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* addPayScheme({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(AddPaySchemeSuccessfull({ response }));
    swal(response.message, { icon: "success" });
    history.push("/payscheme");
  } catch (error) {
    console.log(error);
  }
}
function* updatePayScheme({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(UpdatePaySchemeSuccessfull({ response }));
    swal(response.message, { icon: "success" });
    history.push("/payscheme");
  } catch (error) {
    // swal("error:" + error,{icon: "danger"});
    console.log(error);
  }
}
function* getPayComponents({ payload: { url, data, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(payComponentsSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getPayschemeLists({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    if (response) {
      if (response.status === true) {
        swal(response.message, { icon: "success" });
        hideSpinner();
      } else {
        swal(response.message, { icon: "error" });
        hideSpinner();
      }
      yield put(getPayschemeListSuccessfull({ response }));
    } else {
      hideSpinner();
    }
  } catch (error) {
    hideSpinner();
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}

function* leavePolicyMapping({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    if (response) {
      if (response.status) {
        swal(response.message, { icon: "success" });
        history.push("/add_employee");
      } else {
        swal(response.message, { icon: "error" });
      }
      hideSpinner();
    } else {
      hideSpinner();
    }
  } catch (error) {
    hideSpinner();
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}
function* getAllFileList({ payload: { url, data, history, type } }) {
  try {
    const response = yield call(getListurl, url, data);
    if (type == 1) {
    } else {
      yield put(AllFilesSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getProfilePictureData({ payload: { url, data, history, type } }) {
  try {
    const response = yield call(getListurl, url, data);
    yield put(ProfilePicFilesSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* downLoadFile({ payload: { url, data, history } }) {
  try {
    const response = yield call(getListurl, url, data);
    yield put(downLoadFiles({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* updateFile({ payload: { url, data, history, fileurl } }) {
  try {
    const response = yield call(postAdd, url, data);
    if (response) {
      if (response.status) {
        yield put(getAllFiles(fileurl, history));
      } else {
        swal(response.message, { icon: "error" });
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function* setOfferLetterEmail({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(offerLetterEmailSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
  } catch (error) {
    swal(error, { icon: "error" });
  }
}
function* getEmailTemplate({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(displayEmailTemplateSuccessfull({ response }));
  } catch (error) {
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error);
        }
      }
    }
  }
}
function* setEmployeeDetailsViewById({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(EmployeeDetailsViewByIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getViewByIdExperienceDetails({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(ViewByIdExperienceDetailsSuccessfull({ response }));
  } catch (error) {
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}
function* downloadAssetForm({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(downloadAssetFormsuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* payWagesModes({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(payWagesModesSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getApprovalHierarchy({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (response) {
      yield put(ApprovalHierarchySuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getApprovalHierarchyViewById({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(ViewByIdApprovalSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
// HRMS DASHBOARD
function* getHrmsDashboardData({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(hrmsDashboardSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* setWorkerDetailsProgress({
  payload: { url, data, history, redirect },
}) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(WorkerDetailsProgressSuccessfull({ response }));
    if (redirect) {
      history.push("/add_employee");
    }
  } catch (error) {
    console.log(error);
  }
}
function* getWorkerProgress({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(GetWorkerDetailsProgressSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* setFileUploadData({
  payload: { url, data, history, sourceMenu, ebId },
}) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(setFileUploadSuccessfull({ response }));
    swal("Uploaded Successfully", { icon: "success" });
    if (response) {
      if (sourceMenu == 42) {
        yield put(
          viewbyidPersonalDetails(
            serverApi.VIEW_EMPLOYEE_EDUCATION_DIT + "/" + ebId,
            history,
            4
          )
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteWorkerEducationalDetails({
  payload: { url, history, sourceMenu, ebId },
}) {
  try {
    const response = yield call(getListurl, url);
    yield put(deleteEducationalDetailsSuccessfull({ response }));
    if (response) {
      swal("deleted Successfully", { icon: "success" });
      yield put(
        viewbyidPersonalDetails(
          serverApi.VIEW_EMPLOYEE_EDUCATION_DIT + "/" + ebId,
          history,
          4
        )
      );
    }
  } catch (error) {
    console.log(error);
  }
}
function* deleteProfilePicData({
  payload: { url, history, ebId, sourceMenu },
}) {
  try {
    const response = yield call(getListurl, url);
    yield put(deleteProfilePicSuccessfull({ response }));
    swal("deleted Successfully", { icon: "success" });
  } catch (error) {
    console.log(error);
  }
}
function* deletePersonalDetailsFiles({
  payload: { url, history, ebId, sourceMenu },
}) {
  try {
    const response = yield call(getListurl, url);
    yield put(deleteFilesDataSuccessfull({ response }));
    swal("deleted Successfully", { icon: "success" });
    if (response) {
      yield put(
        getAllFiles(
          serverApi.GET_ALL_FILES +
            "6/" +
            ebId +
            "/" +
            localStorage.getItem("companyId"),
          history,
          2
        )
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* WorkerStatusUpdate({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    if (response) {
      if (response.data.status) {
        swal(response.data.message, { icon: "success" });
        yield put(updateWorkerStatusSuccessfull({ response }));
      } else {
        swal(response.data.message, { icon: "success" });
      }
    }
  } catch (error) {
    const response = null;
    var message = "Sorry! Worker Details Not Updated.";
    yield put(updateWorkerStatusSuccessfull({ response }));
    swal(message, { icon: "error" });
    console.log(error);
  }
}
function* UploadProfilePictureData({ payload: { url, data, history, ebId } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(UploadProfilePicSuccessfull({ response }));
    if (response) {
      swal("Uploaded Successfully", { icon: "success" });

      yield put(
        ProfilePicFiles(
          serverApi.GET_ALL_FILES +
            "1/" +
            ebId +
            "/" +
            localStorage.getItem("companyId"),
          history
        )
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* ViewByIdEBPersonalDetails({ payload: { url, history, type } }) {
  try {
    showSpinner();
    if (localStorage.getItem("ebId")) {
      if (type == 4) {
        const response = yield call(getListWithDocsurl, url);
        if (response) {
          if (response.status) {
            yield put(viewbyidPersonalDetailsSuccessfull({ response }));
          } else {
            const Response = null;
            var message = "Sorry! Personal Details Not Found.";
            yield put(viewbyidPersonalDetailsSuccessfull({ Response }));
            swal(message, { icon: "error" });
          }
          hideSpinner();
        }
      } else {
        const response = yield call(getListurl, url, type);
        if (response) {
          if (response.status) {
            yield put(viewbyidPersonalDetailsSuccessfull({ response }));
          } else {
            const Response = null;
            let Message = "Sorry! Personal Details Not Found.";
            yield put(viewbyidPersonalDetailsSuccessfull({ Response }));
            swal(Message, { icon: "error" });
          }
          hideSpinner();
        }
      }
    }
  } catch (error) {
    hideSpinner();
    const response = { data: null };
    // var message = "Sorry! Personal Details Not Found.";
    yield put(viewbyidPersonalDetailsSuccessfull({ response }));
    // swal(message, { icon: "error" });
    console.log(error);
    hideSpinner();
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}

function* updateWorkerDetails({ payload: { url, data, history, type, ebId } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    if (response.data.status) {
      swal(response.data.message, { icon: "success" });
      if (type == "address_details") {
        history.push("/add_employee");
      } else {
        yield put(
          viewbyidPersonalDetails(
            serverApi.VIEW_EMPLOYEE_CONTACT_DIT + "/" + ebId,
            history,
            2
          )
        );
      }
      hideSpinner();
    } else {
      const Response = { data: null };
      yield put(updateWorkerDetailsSuccessfull({ Response }));
      swal("Sorry! Details are not updated. Please try again", {
        icon: "error",
      });
      hideSpinner();
    }
  } catch (error) {
    hideSpinner();
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}

function* employeeProgressBarData({ payload: { url, data, callback } }) {
  try {
    const response = yield call(getCallBackData, url, data, callback);
    if (response) {
      if (response.data) {
        if (response.data.status) {
          yield put(getEmployeeProgressBarSuccessfull({ response }));
        } else {
          const Response = { data: null };
          yield put(getEmployeeProgressBarSuccessfull({ Response }));
        }
      } else {
        const Response = { data: null };
        yield put(getEmployeeProgressBarSuccessfull({ Response }));
      }
    }
  } catch (error) {
    console.log(error);
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
  }
}
function* ViewByIdSalaryStructure({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(PaySchemeViewByIdSuccessfull({ response }));
    if (response.data) {
      if (response.data.data) {
        if (response.data.data.EmployeePayscheme) {
          if (response.data.data.EmployeePayscheme.payEmployeeStructureDTO) {
            let payschemeId = 0;
            response.data.data.EmployeePayscheme.payEmployeeStructureDTO.map(
              (item) => {
                payschemeId = item.payschemeId;
              }
            );
            yield put(
              PaySchemeById(
                serverApi.PAY_SCHEME_BY_ID + payschemeId,
                payschemeId,
                true,
                history
              )
            );
          }
        }
      }
    }
  } catch (error) {
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}

function* ValidateAadharNumber({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(AadharValidationSuccessfull({ response }));
    if (response.data.message == "Aadhar No. Already Exist!") {
      swal(response.data.message, { icon: "error" });
    } else {
      swal(response.data.message, { icon: "success" });
    }
  } catch (error) {
    console.log(error);
  }
}
function* viewByidLeavePolicyData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getViewByIdLeavePolicySuccessfull({ response }));
    if (response) {
      if (response.data) {
        if (response.data.data) {
          if (response.data.data.empLeavePolicyMapping) {
            if (response.data.data.empLeavePolicyMapping.leavePolicyId) {
              yield put(
                getLeaveList(
                  serverApi.LEAVE_LIST +
                    response.data.data.empLeavePolicyMapping.leavePolicyId,
                  history
                )
              );
            }
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}
function* getWorkerDeatails({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getWorkerDataSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* downloadBulkEmployeeTemplateData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getDownloadBulkEmployeeTemplateSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

export function* watchEmployeeList() {
  yield takeEvery(
    DOWNLOAD_BULK_EMPLOYEE_TEMPLATE,
    downloadBulkEmployeeTemplateData
  );
  yield takeEvery(EMPLOYEE_LIST, getEmployeeList);
  yield takeEvery(EDUCATIONAL_DETAILS, setEducationaldetails);
  yield takeEvery(PERSONAL_DETAILS, setPersonalDetailData);
  yield takeEvery(ESI_DETAILS, updateESIDetails);
  yield takeEvery(DEPARTMENT_LIST_DATA, getDepartment);
  yield takeEvery(CATEGORY_LIST, getCategoryList);
  yield takeEvery(EMP_LEVEL, getEmpLevel);
  yield takeEvery(REPORTING_MANAGER_LIST, getReportingManager);
  yield takeEvery(CONTRACTOR, getContractor);
  yield takeEvery(WORK_LOCATION, getWorkLocation);
  yield takeEvery(PAY_WAGES_MODES, payWagesModes);
  yield takeEvery(DESIGNATION, getDesignation);
  yield takeEvery(LEAVE_POLICY_LIST, getLeavePolicy);
  yield takeEvery(LEAVE_LIST, getLeaveListss);
  yield takeEvery(PAY_SCHEME, getPayScheme);
  // yield takeEvery(EMPLOYEE_DETAILS_VIEW_BY_ID,setEmployeeDetails)
  yield takeEvery(EXPERIENCE_DETAILS, setExperienceDetails);
  yield takeEvery(EXPERIENCE_DETAILS_VIEW_BY_ID, getViewByIdExperienceDetails);
  yield takeEvery(PAY_SCHEME_BY_ID, getPaySchemeId);
  yield takeEvery(PAYSCHEME_BY_ID, getPaySchemeById);
  yield takeEvery(CHECK_PAY_SCHEME, checkPayScheme);
  yield takeEvery(LEAVE_POLICY_MAPPING, leavePolicyMapping);
  yield takeEvery(GET_ALL_FILES_DATA, getAllFileList);
  yield takeEvery(DOWNLOAD_FILES, downLoadFile);
  yield takeEvery(UPDATE_FILE, updateFile);
  yield takeEvery(DOWNLOAD_ASSET_FORM, downloadAssetForm);
  yield takeEvery(OFFER_LETTER_EMAIL_SERVICE, setOfferLetterEmail);
  yield takeEvery(EMPLOYEE_DETAILS_VIEW_BY_ID, setEmployeeDetailsViewById);
  yield takeEvery(DISPLAY_EMAIL_TEMPLATE, getEmailTemplate);
  yield takeEvery(ADD_SALARY, addingSalary);
  yield takeEvery(UPDATE_SALARY, updateSalary);
  yield takeEvery(GET_PAYSCHEME_LIST, getPayschemeLists);
  // yield takeEvery(EMPLOYEE_DETAILS_VIEW_BY_ID, getWorkerList)

  // yield takeEvery(EMPLOYEE_DETAILS_VIEW_BY_ID,getWorkerList)
  yield takeEvery(PAY_COMPONENTS, getPayComponents);
  yield takeEvery(ADD_PAY_SCHEME, addPayScheme);
  yield takeEvery(UPDATE_PAY_SCHEME, updatePayScheme);
  yield takeEvery(HRMS_DASHBOARD, getHrmsDashboardData);
  yield takeEvery(APPROVAL_HIERARCHY, getApprovalHierarchy);
  yield takeEvery(VIEW_BY_ID_APPROVAL, getApprovalHierarchyViewById);
  yield takeEvery(UPDATE_LEAVE_POLICY, UpdateLeavePolicy);
  yield takeEvery(WORKER_DETAILS_PROGRESS, setWorkerDetailsProgress);
  yield takeEvery(GET_WORKER_DETAILS_PROGRESS, getWorkerProgress);
  yield takeEvery(DELETE_FILES, deletePersonalDetailsFiles);
  yield takeEvery(
    DELETE_WORKER_EDUCATIONAL_DETAILS,
    deleteWorkerEducationalDetails
  );
  yield takeEvery(UPDATE_WORKER_STATUS, WorkerStatusUpdate);
  yield takeEvery(FILE_UPLOAD, setFileUploadData);
  yield takeEvery(GET_WORKER_DATA, getWorkerDeatails);

  /***************************************************************************EMPLOYEE DATABSE NEW APIS ************************** */

  yield takeEvery(VIEW_BY_ID_PERSONAL_DETAILS, ViewByIdEBPersonalDetails);
  yield takeEvery(UPDATE_WORKER_DETAILS, updateWorkerDetails);
  yield takeEvery(EMPLOYEE_PROGRESS_BAR_DATA, employeeProgressBarData);
  yield takeEvery(VIEW_BY_ID_SALARY_STRUCTURE, ViewByIdSalaryStructure);
  yield takeEvery(AADHAR_VALIDATION, ValidateAadharNumber);
  yield takeEvery(PROFILE_PIC_DETAILS, getProfilePictureData);
  yield takeEvery(DELETE_PROFILE_PIC, deleteProfilePicData);
  yield takeEvery(UPLOAD_PROFILE_PIC, UploadProfilePictureData);
  yield takeEvery(DELETE_MEDICAL_POLICY, deleteMedicalPolicyFileData);
  yield takeEvery(VIEW_BY_ID_LEAVE_POLICY, viewByidLeavePolicyData);
}

function* EmployeeSaga() {
  yield all([fork(watchEmployeeList)]);
}

export default EmployeeSaga;
