import {
  STOCK_RETURN_LIST,
  STOCK_RETURN_LIST_SUCCESSFULL,
  API_ERRORS,
} from "./actionTypes";

export const getStockReturnList = (url, data, history) => {
  return {
    type: STOCK_RETURN_LIST,
    payload: { url, data, history },
  };
};

export const StockReturnListSuccessfull = (data) => {
  return {
    type: STOCK_RETURN_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const apiErrors = (error) => {
  return {
    type: API_ERRORS,
    payload: error,
  };
};
