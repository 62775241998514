import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { serverApi, showSpinner } from "../../../helpers/Consts";
import {
  JuteIndentList,
  ClearJuteIndentHeader,
  ClearJuteIndentLineItemsProps
} from "../../../store/Jute/JuteIndent/actions";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { useState } from "react";

const JuteIndentIndex = (props) => {
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [companyId, setCompanyId] = useState(localStorage.getItem("companyId"));
  const [juteIndentListContent, setJuteIndentListContent] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [mukam, setMukam] = useState("");
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      cipher: userDit.cipher,
      companyId: companyId,
      indentType: "J",
      fromDate: "",
      toDate: "",
      mukam: "",
      status: "",
      financialYear: localStorage.getItem("acadamicYear"),
    };
    dispatch(JuteIndentList(serverApi.JUTE_INDENT_LIST, data, props.history));
    dispatch(ClearJuteIndentHeader());
    dispatch(ClearJuteIndentLineItemsProps())
  }, []);

  const handleSearch = (data) => {
    const searchData = {
      cipher: userDit.cipher,
      companyId: companyId,
      indentType: "J",
      fromDate: data.fromDate,
      toDate: data.toDate,
      mukam: data.mukam,
      status: data.status,
      financialYear: localStorage.getItem("acadamicYear"),
    };
    setFromDate(data.fromDate);
    setToDate(data.toDate);
    setMukam(data.mukam);
    setStatus(data.status);
    dispatch(JuteIndentList(serverApi.JUTE_INDENT_LIST, searchData, props.history))
  };

  const { JuteIndentListData } = useSelector(
    (state) => state.JuteIndentReducer
  );

  useEffect(() => {
    if (JuteIndentListData) {
      if (JuteIndentListData.data) {
        setJuteIndentListContent(JuteIndentListData.data);
      }
    }
  }, [JuteIndentListData]);

  return (
    <>
      <Gridwithcustomview
        filter_form={"JuteIndentFilter"}
        mdata={juteIndentListContent}
        mcolumns={Columns}
        status={true}
        micon={GridArrowIcon}
        actions={true}
        url="/create_jute_indent"
        handleSearch={handleSearch}
      />
    </>
  );
};

const Columns = [
  {
    Header: "Indent No",
    accessor: "indentNo",
    hidden: false,
  },
  {
    Header: "Indent Date",
    accessor: "indentDate",
    hidden: false,
  },
  {
    Header: "Mukam",
    accessor: "mukam",
    hidden: false,
  },
  {
    Header: "Vehicle Type",
    accessor: "vehicleName",
    hidden: false,
  },
  {
    Header: "Vehicle Qty",
    accessor: "vehicleQuantity",
    hidden: false,
  },
  {
    Header: "Indent Weight",
    accessor: "indentWeight",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "indentStatus",
    hidden: false,
  },
];

export default JuteIndentIndex;
