import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Step,
  Stepper,
  StepLabel,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import Link from "@mui/material/Link";
import { serverApi } from "../../../helpers/Consts";
import JutePoFillDetails from "./JutePoFillDetails.jsx";
import JutePurchaseOrderLineItems from "./JutePoLineItems.jsx";
import CustomButton from "../../../components/Buttons/Buttons";
import JutePOPreview from "./JutePoPreview.jsx";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty, isEmptyWithZero, limitDecimals } from "../../../Utilities/helper";

import {
  getJutePOHeader,
  jutePOCreate,
  jutePOViewById,
  jutePOupdate,
} from "../../../store/Jute/JutePurchase/actions";
import moment from "moment";
import swal from "sweetalert";

function validateVehicleQuantity(orderQuantity, vehicleCapacity, userVehicles, tolerance = 0.30) {
  // Calculate the required number of vehicles
  const requiredVehicles = Math.ceil(orderQuantity / vehicleCapacity);
  
  // Calculate the tolerance range
  let minVehicles = Math.floor(requiredVehicles * (1 - tolerance));
  let maxVehicles = Math.ceil(requiredVehicles * (1 + tolerance));

  // Ensure minVehicles is at least 1
  if (minVehicles < 1) {
    minVehicles = 1;
  }

  // Validate the user input
  const isValid = userVehicles >= minVehicles && userVehicles <= maxVehicles;
  const message = isValid 
      ? `Valid entry. Required vehicles: ${requiredVehicles}, Acceptable range: ${minVehicles} - ${maxVehicles}`
      : `Required vehicles: ${requiredVehicles}, Acceptable range: ${minVehicles} - ${maxVehicles}, please adjust the vehicle quantity`;
  
  return { isValid, message };
}

function CreateJutePurchaseOrder(props) {
  const [steps, setSteps] = useState([
    {
      title: "Select Option",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Fill Details",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Add Items",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Preview",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive03.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
  ]);
  const [currentStep, setCurrentStep] = useState(1);
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [companyId, setCompanyId] = useState(localStorage.getItem("companyId"));
  const [alignment, setalignment] = useState("");
  const [HeaderData, setHeaderData] = useState({});
  const [LineItems, setLineItems] = useState([]);
  const dispatch = useDispatch();
  let history = useHistory();
  const [locationState] = useState(useLocation());

  const { JutePoHeader, JutePoLineItems } = useSelector(
    (state) => state.JutePurchaseReducer
  );
  useEffect(() => {
    setHeaderData(JutePoHeader);
    setLineItems(JutePoLineItems);
  }, [JutePoHeader, JutePoLineItems]);

  const getValueBeforeHyphen = (input) => {
    // Split the string by the hyphen and return the first part as a number
    const parts = input.split('-');
    const value = Number(parts[0]);
    return isNaN(value) ? null : value; // Return null if the value is not a number
  }

  const handleAlignment = (event, newAlignment) => {
    setalignment(newAlignment);
    let JutePoType = "";
    if (newAlignment == 1) {
      JutePoType = "WITHINDENT";
    } else {
      JutePoType = "WITHOUTINDENT";
    }
    setHeaderData((prev) => ({
      ...prev,
      JutePoType: JutePoType,
    }));
    const UpdatedRec = {
      ...HeaderData,
      JutePoType: JutePoType,
    };
    dispatch(getJutePOHeader(UpdatedRec));
  };
  const MandatoryCheck = () => {
    if (isEmpty(JutePoHeader.juteUnit)) {
      swal("Please Select Unit Conversion");
    } else if (isEmpty(JutePoHeader.vehicleTypeId)) {
      swal("Please Select Vehicle Type");
    } else if (isEmpty(JutePoHeader.vehicleQuantity)) {
      swal("Please Select Vehicle Quantity");
    } else if (isEmpty(JutePoHeader.channelCode)) {
      swal("Please Select Channel Type");
    } else if (isEmpty(JutePoHeader.mukam)) {
      swal("Please Select Mukam");
    } else if (isEmpty(JutePoHeader.supplierId)) {
      swal("Please Select Supplier");
    } else {
      setCurrentStep(currentStep + 1);
    }
  };
  const getConvertedQty = () => {
    let vehicleValue = HeaderData.vehicleTypeName.split("_")[1];
    if (
      HeaderData.juteUnit == "LOOSE" &&
      localStorage.getItem("companyId") !== "116"
    ) {
      //   //   if it is loose
      return limitDecimals(Number(vehicleValue) * Number(HeaderData.vehicleQuantity),2);
    } else {
      // return limitDecimals((150 * Number(value)) / 100, 2);
      return limitDecimals((Number(vehicleValue) * Number(HeaderData.vehicleQuantity) * 100) / 150,2)
    }
  };
  const onClickNext = () => {
    if (currentStep == 2) {
        if (isEmpty(JutePoHeader.branchId)) {
            swal("Please Select the Branch");
        } else if (isEmpty(JutePoHeader.poDate)) {
            swal("Please Select the Date");
        } else if (JutePoHeader.JutePoType == "WITHINDENT") {
            if (isEmpty(JutePoHeader.indentNum)) {
                swal("Please Select the Indent Number");
            } else {
                MandatoryCheck();
            }
        } else {
            MandatoryCheck();
        }
    } else if (currentStep == 3) {
        let ValidateFields = false;
        const FilteredDatas = JutePoLineItems.filter((item) => item.isActive !== 0);
        
        if (FilteredDatas.length === 1) {
            FilteredDatas.forEach((item) => {
                if (isEmpty(item.itemId)) {
                    swal("Please select the Jute Type/Region");
                    return false;
                } else if (isEmpty(item.qualityCode)) {
                    swal("Please select the Quality/Grade");
                    return false;
                } else if (isEmptyWithZero(item.quantity)) {
                    swal("Please Enter the Quantity");
                    return false;
                } else if (isEmptyWithZero(item.rate)) {
                    swal("Please Enter the Price");
                    return false;
                } else if (isEmptyWithZero(item.actualQuantity)) {
                    swal("Please Enter the Weight");
                    return false;
                } else if (isEmptyWithZero(item.amount)) {
                    swal("Please Enter the Amount");
                    return false;
                } else {
                    ValidateFields = true;
                }
            });
        } else {
            const FilteredData = JutePoLineItems.filter(
                (item) => item.isActive !== 0 && item.itemGroupId !== ""
            );
            for (let i = 0; i <= FilteredData.length - 1; i++) {
                if (isEmpty(FilteredData[i].itemId)) {
                    swal("Please select the Jute Type/Region");
                    return false;
                } else if (isEmpty(FilteredData[i].qualityCode)) {
                    swal("Please select the Quality/Grade");
                    return false;
                } else if (isEmptyWithZero(FilteredData[i].quantity)) {
                    swal("Please Enter the Quantity");
                    return false;
                } else if (isEmptyWithZero(FilteredData[i].rate)) {
                    swal("Please Enter the Price");
                    return false;
                } else if (isEmptyWithZero(FilteredData[i].actualQuantity)) {
                    swal("Please Enter the Weight");
                    return false;
                } else if (isEmptyWithZero(FilteredData[i].amount)) {
                    swal("Please Enter the Amount");
                    return false;
                } else {
                    ValidateFields = true;
                }
            }
        }

        if (ValidateFields) {
            let sumOfwgt = 0;
            const totalQuantity = HeaderData.vehicleTypeName.split("_")[1] * HeaderData.vehicleQuantity;
            
            LineItems.forEach((q) => {
                if (q.itemId !== "") {
                    sumOfwgt += Number(q.actualQuantity);
                }
            });

            if (sumOfwgt <= totalQuantity) {
                const { isValid, message } = validateVehicleQuantity(sumOfwgt, HeaderData.vehicleTypeName.split("_")[1], HeaderData.vehicleQuantity);
                
                if (isValid) {
                    setCurrentStep(currentStep + 1);
                    console.log("Proceed to the preview page");
                } else {
                    swal(message);
                }
            } else {
                swal("Weight Should Not Exceed PO Quantity..!!" + getConvertedQty());
            }
        }
    } else {
        setCurrentStep(currentStep + 1);
    }
};

  const onClickBefore = () => {
    if (currentStep == 4 && locationState.state !== undefined) {
      let url = "/jute_purchase_order";
      props.history.push(url);
    } else if (currentStep == 2) {
      let url = "/jute_purchase_order";
      props.history.push(url);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const onClickCreate = () => {
    var LineItemDTO = [];
    var FilteredData = LineItems.filter(
      (item) =>
        item.isActive !== 0 && item.itemId !== null && item.itemId !== ""
    );

    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemDTO.push({
          itemId: item.itemId,
          itemDesc: item.itemDesc,
          qualityCode: item.qualityCode,
          quantity: item.quantity,
          unitId: "QNT",
          rate: item.rate,
          allowableMoisturePercentage: item.allowableMoisturePercentage,
          cTax: "",
          sTax: "",
          iTax: "",
          bale: "",
          loose: "",
          amount: item.amount,
          deptId: "",
          valueWithoutTax: item.amount,
          valueWithTax: "",
          tax: "",
          indentId: item.indentId,
          actualQuantity: item.actualQuantity,
          itemGroupId: item.itemGroupId,
          discount: "",
          taxId: "",
          taxPercentage: "",
          marka: item.marka,
          cropYear: getValueBeforeHyphen(item.cropYear),
          isActive: item.isActive,
        });
      });
    }
    var createdDate = moment(HeaderData.poDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    const data = {
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      poHeader: {
        indentNum: HeaderData.indentNum,
        type: "J",
        poDate: createdDate,
        creditTerm: HeaderData.creditTerm,
        deliveryTimeline: HeaderData.deliveryTimeline,
        vehicleTypeId: HeaderData.vehicleTypeId,
        vehicleTypeName:HeaderData.vehicleTypeName,
        vehicleQuantity: HeaderData.vehicleQuantity,
        supplierId: HeaderData.supplierId,
        mukam: HeaderData.mukam,
        brokerId: HeaderData.brokerId,
        brokerName: HeaderData.brokerName,
        frieghtCharge: HeaderData.frieghtCharge,
        remarks: "",
        juteUnit: HeaderData.juteUnit,
        exceptedDate: HeaderData.exceptedDate,
        cTax: "",
        sTax: "",
        iTax: "",
        valueWithoutTax: HeaderData.netTotal,
        submitter: JSON.parse(localStorage.getItem("authUser")).userId,
        finnacialYear: localStorage.getItem("acadamicYear"),
        footerNote: "",
        valueWithTax: HeaderData.netTotal,
        tax: "",
        deliveryAddress: "",
        discount: "",
        weight: HeaderData.weightTotal,
        companyId: localStorage.getItem("companyId"),
        withOrWithout: HeaderData.JutePoType == "WITHINDENT" ? 0 : 1,
        channelCode: HeaderData.channelCode,
        contrctNo: "",
        contractDate: "",
        currencyCode: "",
        conversationRate: "",
        brokerageRate: "",
        brokeragePercentage: "",
        penality: HeaderData.penality,
        branchId: HeaderData.branchId,
      },
      poLineItem: LineItemDTO,
    };

    dispatch(jutePOCreate(serverApi.ADD_JUTE_PO, data, history, 1, null, null));
  };
  const onClickUpdate = (status) => {
    var LineItemDTO = [];
    var FilteredData = LineItems.filter(
      (item) => item.itemId !== null && item.itemId !== ""
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemDTO.push({
          id: item.jutepoLineItemId || "",
          itemId: item.itemId,
          itemDesc: item.itemDesc,
          qualityCode: item.qualityCode,
          quantity: item.quantity,
          unitId: item.uom,
          rate: item.rate,
          allowableMoisturePercentage: item.allowableMoisturePercentage,
          cTax: "",
          sTax: "",
          iTax: "",
          bale: "",
          loose: "",
          amount: item.amount,
          deptId: "",
          valueWithoutTax: item.amount,
          valueWithTax: item.amount,
          tax: "",
          indentId: item.indentId,
          actualQuantity: item.actualQuantity,
          itemGroupId: item.itemGroupId,
          discount: "",
          taxId: "",
          taxPercentage: "",
          marka: item.marka,
          cropYear: getValueBeforeHyphen(item.cropYear),
          isActive: item.isActive,
        });
      });
    }
    var createdDate = moment(HeaderData.poDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    const data = {
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      poHeader: {
        indentNum: HeaderData.indentNum,
        hdrId: HeaderData.hdrId,
        type: "J",
        poDate: createdDate,
        creditTerm: HeaderData.creditTerm,
        deliveryTimeline: HeaderData.deliveryTimeline,
        vehicleTypeId: HeaderData.vehicleTypeId,
        vehicleQuantity: HeaderData.vehicleQuantity,
        supplierId: HeaderData.supplierId,
        mukam: HeaderData.mukam,
        brokerId: HeaderData.brokerId,
        brokerName: HeaderData.brokerName,
        frieghtCharge: HeaderData.frieghtCharge,
        remarks: "",
        juteUnit: HeaderData.juteUnit,
        exceptedDate: HeaderData.exceptedDate,
        cTax: "",
        sTax: "",
        iTax: "",
        valueWithoutTax: HeaderData.netTotal,
        submitter: JSON.parse(localStorage.getItem("authUser")).userId,
        finnacialYear: localStorage.getItem("acadamicYear"),
        footerNote: "",
        valueWithTax: HeaderData.netTotal,
        tax: "",
        deliveryAddress: "",
        discount: "",
        weight: HeaderData.weightTotal,
        companyId: localStorage.getItem("companyId"),
        withOrWithout: HeaderData.JutePoType == "WITHINDENT" ? 0 : 1,
        channelCode: HeaderData.channelCode,
        contrctNo: "",
        contractDate: "",
        currencyCode: "",
        conversationRate: "",
        brokerageRate: "",
        brokeragePercentage: "",
        penality: HeaderData.penality,
        branchId: HeaderData.branchId,
        modBy: JSON.parse(localStorage.getItem("authUser")).userId,
        status: status,
        poId:HeaderData.id
      },
      poLineItem: LineItemDTO,
    };
    dispatch(jutePOupdate(serverApi.UPDATE_JUTE_PO, data, history));
  };
  const onClickApprove = (Status) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Approve",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willapprove) => {
      if (willapprove) {
        onClickUpdate(Status);
      }
    });
  };

  const onClickReject = (Status) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Reject",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willreject) => {
      if (willreject) {
        onClickUpdate(Status);
      }
    });
  };

  const QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;
    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };
  useEffect(() => {
    if (locationState.state !== undefined) {
      if (locationState.state.rowData !== undefined) {
        setCurrentStep(4);
        var mdata = {
          poNum: locationState.state.rowData.poNo,
          userId: userDit.userId,
          cipher: userDit.cipher,
        };
        dispatch(jutePOViewById(serverApi.GET_PO_DETAILS, mdata, history));
      }
    }
  }, []);
  const handleCurrentStep = (data) => {
    setCurrentStep(data);
  };
  return (
    <div>
      <Grid className="indentContainerBlock">
        <Grid sx={12} className="indentContainerMainBlock">
          <div className="iconsBlock">
            <Stepper alternativeLabel activeStep={currentStep}>
              {steps.map((prop) => (
                <Step key={prop.title}>
                  <StepLabel
                    StepIconComponent={(Iconprops) => {
                      return QontoStepIcon(
                        Iconprops,
                        prop.icon,
                        prop.activeIcon,
                        prop.title
                      );
                    }}
                  ></StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          {currentStep === 1 && (
            <>
              <div>
                <div className="consoleFormButtonBlock">
                  <h4>How do you want to create the Purchase order?</h4>
                </div>
                <div className="consoleFormButtonBlock">
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                    className="btnToggleforIndent"
                  >
                    <ToggleButton value="1" aria-label="centered">
                      <span>With Indent</span>
                    </ToggleButton>

                    <ToggleButton value="2" aria-label="left aligned">
                      <span>Without Indent</span>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Next"}
                  className={
                    HeaderData.JutePoType !== "" ? "greenButton" : "disabled"
                  }
                  handleClick={onClickNext}
                  type="sumbit"
                  disabled={HeaderData.JutePoType == "" ? true : false}
                />
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <JutePoFillDetails />
              <div className="consoleFormButtonBlock">
                <Link>
                  <CustomButton
                    type="sumbit"
                    label={"Cancel"}
                    className={"greenButton"}
                    handleClick={onClickBefore}
                  />
                </Link>
                <CustomButton
                  label={"Next"}
                  className={"greenButton"}
                  handleClick={onClickNext}
                  type="sumbit"
                />
              </div>
            </>
          )}
          {currentStep === 3 && (
            <>
              <JutePurchaseOrderLineItems />
              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Back"}
                  type="sumbit"
                  className={"greenButton"}
                  handleClick={onClickBefore}
                />

                <CustomButton
                  label={"Next"}
                  className={"greenButton"}
                  handleClick={onClickNext}
                  type="sumbit"
                />
              </div>
            </>
          )}
          {currentStep === 4 && (
            <>
              <JutePOPreview handleCurrentStep={handleCurrentStep} />
              <div className="consoleFormButtonBlock">
              {locationState.state === undefined &&(
                <Link>
                <CustomButton
                  type="sumbit"
                  label={"Back"}
                  className={"greenButton"}
                  handleClick={onClickBefore}
                />
              </Link>
              ) }
                
                {HeaderData.UpdateButton &&
                  locationState.state !== undefined && (
                    <CustomButton
                      type="sumbit"
                      label={"Update"}
                      className={"greenButton"}
                      handleClick={() => onClickUpdate("")}
                    />
                  )}
                {HeaderData.ApproveButton &&
                  locationState.state !== undefined && (
                    <CustomButton
                      type="sumbit"
                      label={"Approve"}
                      className={"approved"}
                      handleClick={() => onClickApprove(HeaderData.status)}
                    />
                  )}
                {HeaderData.RejectButton &&
                  locationState.state !== undefined && (
                    <CustomButton
                      type="sumbit"
                      label={"Reject"}
                      className={"rejected"}
                      handleClick={() => onClickReject(4)}
                    />
                  )}
                {locationState.state === undefined ? (
                  <CustomButton
                    type="sumbit"
                    label={"Create"}
                    className={"greenButton"}
                    handleClick={onClickCreate}
                  />
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
export default CreateJutePurchaseOrder;
