import {INWARD_LIST,INWARD_LIST_SUCCESSFULL, INWARD_LINE_ITEMS, POLINEITEMSBYPOIDS, POLINEITEMSBYPOIDS_SUCCESSFULL, CREATE_INWARD, CREATE_INWARD_SUCCESSFULL, UPDATE_INWARD, UPDATE_INWARD_SUCCESSFULL, UPDATE_STORE, UPDATE_STORE_SUCCESSFULL, UPDATE_MATERIAL_INSPECTION, UPDATE_MATERIAL_INSPECTION_SUCCESSFULL, GET_FILES_OF_INWARD, GET_FILES_OF_INWARD_SUCCESSFULL, GET_FILES_OF_MI_SUCCESSFULL, GET_FILES_OF_SR_SUCCESSFULL, CHECKVALIDSUPPID_INVOICE_CHALLAN, CHECKVALIDSUPPID_INVOICE_CHALLAN_SUCCESSFULL,GET_ITEMDETAILS_BYITEM_ITEMGRP_FORINWARD, GET_ITEMDETAILS_BYITEM_ITEMGRP_FORINWARD_SUCCESSFULL, GET_ITEMLIST_BYITEMGROUP_FORINWARD, GET_ITEMLIST_BYITEMGROUP_FORINWARD_SUCCESSFULL} from './actionTypes';



export const getInwardList = (url,data,history) =>{
    return {
        type:INWARD_LIST,
        payload:{url,data,history}
    }
}

export const InwardListSuccessfull = (data)=>{
    return {
        type:INWARD_LIST_SUCCESSFULL,
        payload:data
    }
}
export const getPoLineItemsListByPoIds = (url,data,history) =>{
    return {
        type:POLINEITEMSBYPOIDS,
        payload:{url,data,history}
    }
}

export const PoLineItemsListByPoIdSuccessfull = (data)=>{
    return {
        type:POLINEITEMSBYPOIDS_SUCCESSFULL,
        payload:data
    }
}
export const InwardLineItemsProps = (data)=>{
    return {
        type:INWARD_LINE_ITEMS,
        payload:data
    }
}


export const CreateInward = (url,data,history,pathname) =>{
    return {
        type:CREATE_INWARD,
        payload:{url,data,history,pathname}
    }
}
export const CreateInwardSuccessfull = (data)=>{
    return {
        type:CREATE_INWARD_SUCCESSFULL,
        payload:data
    }
}
export const UpdateInward = (url,data,history,pathname) =>{
    return {
        type:UPDATE_INWARD,
        payload:{url,data,history,pathname}
    }
}
export const UpdateInwardSuccessfull = (data)=>{
    return {
        type:UPDATE_INWARD_SUCCESSFULL,
        payload:data
    }
}

export const UpdateStore = (url,data,history) =>{
    return {
        type:UPDATE_STORE,
        payload:{url,data,history}
    }
}
export const UpdateStoreSuccessfull = (data)=>{
    return {
        type:UPDATE_STORE_SUCCESSFULL,
        payload:data
    }
}
export const UpdateMaterialInspection = (url,data,history) =>{
    return {
        type:UPDATE_MATERIAL_INSPECTION,
        payload:{url,data,history}
    }
}
export const UpdateMaterialInspectionSuccessfull = (data)=>{
    return {
        type:UPDATE_MATERIAL_INSPECTION_SUCCESSFULL,
        payload:data
    }
}


export const getFilesofInward = (url,history,sourceMenu,menu) =>{
    return {
        type:GET_FILES_OF_INWARD,
        payload:{url,history,sourceMenu,menu}
    }
}
export const getFilesofInwardSuccessfull = (data)=>{
    return {
        type:GET_FILES_OF_INWARD_SUCCESSFULL,
        payload:data
    }
}
export const getFilesofMISuccessfull = (data)=>{
    return {
        type:GET_FILES_OF_MI_SUCCESSFULL,
        payload:data
    }
}

export const getFilesofSRSuccessfull = (data)=>{
    return {
        type:GET_FILES_OF_SR_SUCCESSFULL,
        payload:data
    }
}
export const checkValidSuppIdChallanInvoiceNo = (url,data,history)=>{
    return {
        type:CHECKVALIDSUPPID_INVOICE_CHALLAN,
        payload:{url,data,history}
    }
}
export const checkValidSuppIdChallanInvoiceNoSuccessfull = (data)=>{
    return {
        type:CHECKVALIDSUPPID_INVOICE_CHALLAN_SUCCESSFULL,
        payload:data
    }
}

export const getItemListByItemGroupForInward = (url, data, callback, itemgrpId) => {
    return {
     type: GET_ITEMLIST_BYITEMGROUP_FORINWARD,
     payload: {url, data, callback, itemgrpId},
    }
  
 }
  
 export const getItemListByItemGroupForInwardSuccessfull = (data) =>{
  
     return {
         type: GET_ITEMLIST_BYITEMGROUP_FORINWARD_SUCCESSFULL,
         payload: data,
     }
 }
  
 export const getItemDetailsByItemItemGrpForInward = (url, data, callback, itemId) => {
   return {
    type: GET_ITEMDETAILS_BYITEM_ITEMGRP_FORINWARD,
    payload: {url, data, callback, itemId},
   }
  
 }
  
 export const getItemDetailsByItemItemGrpForInwardSuccessfull = (data) =>{
  
    return {
        type: GET_ITEMDETAILS_BYITEM_ITEMGRP_FORINWARD_SUCCESSFULL,
        payload: data,
    }
 }


