import { all, call, fork, takeEvery, put} from "redux-saga/effects";


import {ADDITEM_TO_TAX_LIST} from './actionTypes';
import {addItemToTaxListSuccessfull} from './actions';
import {postAdd} from '../../../helpers/Server_Helper';
import swal from "sweetalert";


function* getAddItemToTaxList({payload:{url,data,history}}) {
    try{
        const response = yield call(postAdd,url,data);
        yield put(addItemToTaxListSuccessfull({response}))
        swal(response.data.message,{icon: "success"})
        history.push("/tax_master");
    }catch(error){
        console.log(error)
    }
}




export function* watchTaxList () {
    yield takeEvery(ADDITEM_TO_TAX_LIST, getAddItemToTaxList)
}




function* addItemToTaxListSaga() {
    yield all([
        fork(watchTaxList),
    ])
}


export default addItemToTaxListSaga;