import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Gridwithcustomview from "../../../components/Grid/PaginationGridView";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import CustomButton from "../../../components/Buttons/Buttons";
import { serverApi } from "../../../helpers/Consts";
import {
  getAttendanceList,
  getWorkerDataClearForAttendance,
  MachineNumberClear,
} from "../../../store/HRMS/AttendanceCalender/actions";
import moment from "moment";
class AttendenceCalender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      AttendanceList: [],
      From_date: moment().day(-7).format("DD-MM-YYYY"),
      toDate: moment().format("DD-MM-YYYY"),
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      designationId: 0,
      subDeptId: 0,
      cataId: 0,
      workType: "",
      spell: "",
      ebNo: "",
      dataexp: "",
    };
  }
  componentDidMount() {
    const data = {
      cataId: 0,
      companyId: localStorage.getItem("companyId"),
      designationId: 0,
      ebId: "",
      ebNo: "",
      lastIndex: this.state.lastIndex,
      spell: "",
      startIndex: this.state.startIndex,
      startingDate: this.state.From_date,
      subDeptId: 0,
      toDate: this.state.toDate,
      userId: this.state.userDit.userId,
      workType: "",
    };
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.props.getAttendanceList(
      serverApi.ATTENDANCE_LIST,
      data,
      this.props.history
    );
    this.props.getWorkerDataClearForAttendance(null);
    this.props.MachineNumberClear(null);
    expdata.startIndex = 0;
    expdata.lastIndex = 0;
    this.setState({ dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.attendanceList) {
      if (props.attendanceList.data) {
        this.setState({
          AttendanceList: props.attendanceList.data,
          totalRecs: props.attendanceList.totalRecords,
        });
      }
    }
  }
  handleSearch = (data1) => {
    const data = {
      cataId: data1.cataId,
      companyId: this.state.companyId,
      designationId: data1.designationId,
      ebId: "",
      ebNo: data1.ebNo,
      spell: data1.spell,
      startingDate: data1.startingDate,
      subDeptId: data1.subDeptId,
      toDate: data1.toDate,
      userId: this.state.userDit.userId,
      workType: data1.workType,
      startIndex: 1,
      lastIndex: 10,
    };
    this.setState({
      From_date: data1.startingDate,
      toDate: data1.toDate,
      designationId: data1.designationId,
      subDeptId: data1.subDeptId,
      cataId: data1.cataId,
      workType: data1.workType,
      spell: data1.spell,
      ebNo: data1.ebNo,
    });
    this.props.getAttendanceList(
      serverApi.ATTENDANCE_LIST,
      data,
      this.props.history
    );
  };
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const data = {
        cataId: 0,
        companyId: localStorage.getItem("companyId"),
        designationId: 0,
        ebId: "",
        ebNo: this.state.ebNo,
        spell: this.state.spell,
        startingDate: this.state.From_date,
        subDeptId: this.state.subDeptId,
        toDate: this.state.toDate,
        userId: this.state.userDit.userId,
        workType: this.state.workType,
        startIndex: sizePerPage,
        lastIndex: currentIndex,
      };
      if (currentIndex >= 0) {
        this.props.getAttendanceList(
          serverApi.ATTENDANCE_LIST,
          data,
          this.props.history
        );
      }
    } else {
      const data = {
        cataId: this.state.cataId,
        companyId: localStorage.getItem("companyId"),
        designationId: this.state.designationId,
        ebId: "",
        ebNo: this.state.ebNo,
        spell: this.state.ebNo,
        startingDate: this.state.From_date,
        subDeptId: this.state.subDeptId,
        toDate: this.state.toDate,
        userId: this.state.userDit.userId,
        workType: this.state.workType,
        startIndex: currentIndex == 0 ? currentIndex + 1 : currentIndex,
        lastIndex: sizePerPage,
      };
      if (currentIndex >= 0) {
        this.props.getAttendanceList(
          serverApi.ATTENDANCE_LIST,
          data,
          this.props.history
        );
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  handleProcessLogs = () => {
    const data = {
      cataId: 0,
      companyId: localStorage.getItem("companyId"),
      designationId: 0,
      ebId: "",
      ebNo: "",
      lastIndex: this.state.lastIndex,
      spell: "",
      startIndex: this.state.startIndex,
      startingDate: this.state.From_date,
      subDeptId: 0,
      toDate: this.state.toDate,
      userId: this.state.userDit.userId,
      workType: "",
    };
    this.props.getAttendanceList(
      serverApi.CHECK_ZERO_WORKING_HOURS,
      data,
      this.props.history
    );
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.AttendanceList}
          mcolumns={Columns}
          micon={GridArrowIcon}
          actions={true}
          url="/mark_attendance"
          filter_form={"AttendanceList"}
          handleSearch={this.handleSearch}
          totalRecs={this.state.totalRecs}
          pageno={this.state.pageno}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          apiexp={serverApi.ATTENDANCE_LIST}
          payloadexp={this.state.dataexp}
          expName={"AttendanceList.csv"}
        />
      </>
    );
  }
}
const Columns = [
  {
    Header: "Eb No",
    accessor: "ebNo",
    hidden: false,
  },
  {
    Header: "Worker Name",
    accessor: "workerName",
    hidden: false,
  },
  {
    Header: "Attendance Date",
    accessor: "attendanceDateDesc",
    hidden: false,
  },
  {
    Header: "Attendance Mark",
    accessor: "attendanceMark",
    hidden: false,
  },
  {
    Header: "Attendance Source",
    accessor: "attendanceSource",
    hidden: false,
  },
  {
    Header: "Attendance Type",
    accessor: "attendanceType",
    hidden: false,
  },
  {
    Header: "Designation",
    accessor: "workedDesignation",
    hidden: false,
  },
  {
    Header: "Department",
    accessor: "workedDepartment",
    hidden: false,
  },
  {
    Header: "Device Name",
    accessor: "deviceName",
    hidden: false,
  },
  {
    Header: "Spell",
    accessor: "spell",
    hidden: true,
  },
  {
    Header: "Spell Hours",
    accessor: "spellHours",
    hidden: true,
  },
  {
    Header: "Idle Hours",
    accessor: "idleHours",
    hidden: true,
  },
  {
    Header: "Work Hours",
    accessor: "workHours",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "status",
    hidden: false,
  },
];
const mapStatetoProps = (state) => {
  const { attendanceList } = state.AttendanceReducer;
  return { attendanceList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getAttendanceList,
    MachineNumberClear,
    getWorkerDataClearForAttendance,
  })(AttendenceCalender)
);
