import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box } from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/Jute/DynamicSelect";
import InputAdornmentText from "../../../components/InputAdorment/InputAdorment";
import CustomButton from "../../../components/Buttons/Buttons";
import MrCommonPopup from "../../../components/CommonPopup/MRPopup";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import moment from "moment";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { juteGetStockDetailsByGodownNumber } from "../../../store/Jute/JuteConversion/actions";
import {
  getBranchList,
  getItemsList,
} from "../../../store/Global/DropDownApis/actions";
import {
  getQualityByItemList,
  getGodownNumberList,
} from "../../../store/MasterDropdownApis/actions";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";

import RadioButton from "../../../components/RadioButton/RadioButton";
import swal from "sweetalert";

class CreateJuteConversion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchListData: [],
      itemsListData: [],
      itemsByQualityData: [],
      godownListData: [],
      unitConversion: "",
      branchId: "",
      godownNo: "",
      show: false,
      MrItemsByGodown: [],
      btnDisabled: true,
      userDit: JSON.parse(localStorage.getItem("authUser")),
    };
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getBranchList(
      serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
      this.props.history
    );
    const data = {
      companyId: localStorage.getItem("companyId"),
      itemGroupId: "999",
    };
    this.props.getItemsList(
      serverApi.GET_ITEMS_BY_ITEMGROUP_ID,
      data,
      this.props.history
    );
  }
  componentWillReceiveProps(props) {
    if (props.branchList) {
      this.setState({
        branchListData: props.branchList.data,
      });
    }
    if (props.itemsByItmGrpIdList) {
      this.setState({
        itemsListData: props.itemsByItmGrpIdList,
      });
    }
    if (props.QualityByItemId) {
      this.setState({
        itemsByQualityData: props.QualityByItemId.data,
      });
    }
    if (props.getGodownListData) {
      var arrayOfData = [];
      props.getGodownListData.forEach((prop) => {
        var obj = {
          label: prop.label,
          value: prop.value,
          name: prop.name,
        };
        arrayOfData.push(obj);
      });

      this.setState({
        godownListData: arrayOfData,
      });
    }
    if (props.StockDetailsByGodown) {
      var arrayOfData = [];
      props.StockDetailsByGodown.forEach((prop) => {
        var obj = {
          label: prop.label,
          value: prop.value,
          name: prop.name,
        };
        arrayOfData.push(obj);
      });

      this.setState({
        MrItemsByGodown: arrayOfData,
      });
    }
  }
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    this.props.headerData[key] = event;
    if (key === "sourceMrNumber") {
      this.setState({ [key]: event });
    } else {
      this.setState({ [key]: event.target.value });
    }
  };
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    this.setState({ [name]: selectedValue });

    if (name === "branchId") {
      this.setState({
        branchId: selectedName,
      });
    }
    if (name === "unitConversion") {
      this.setState({
        unitConversion: selectedName,
      });
      if (selectedName === "LOOSE") {
        this.setState({
          juteType: "",
          quantity: "",
          godownNo: "",
        });
      }
    }
    if (name === "juteType") {
      this.setState({
        juteType: selectedValue,
        juteId: selectedValue,
        quantity: 0,
        godownNo: 0,
      });
      this.props.getQualityByItemList(
        serverApi.GET_QUALITY_BY_ITEM +
          selectedValue +
          "/" +
          localStorage.getItem("companyId") +
          "/getQualityByItem",
        this.props.history
      );
    }
    if (name === "quantity") {
      this.setState({
        quantity: selectedValue,
        godownNo: 0,
      });
      const data = {
        juteType: this.state.juteType,
        qualityId: selectedValue,
        unitConversion: this.state.unitConversion,
        cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
        companyId: localStorage.getItem("companyId"),
        branchId: 4,
      };
      this.props.getGodownNumberList(
        serverApi.GET_GODOWN_NUMBER,
        data,
        this.props.history
      );
    }
    if (name === "godown") {
      this.setState({
        godownNo: selectedValue,
        btnDisabled: false,
      });
      const data = {
        godownNo: selectedValue,
        juteType: this.state.juteType,
        qualityId: this.state.quantity,
        unitConversion: this.state.unitConversion,
        cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
        companyId: localStorage.getItem("companyId"),
      };
      this.props.juteGetStockDetailsByGodownNumber(
        serverApi.GET_STOCK_DETAILS_BY_GODOWN_NUMBER,
        data,
        this.props.history
      );
    }
  };
  onHandleMrData = () => {
    this.setState({
      show: true,
    });
  };
  handleCancel = () => {
    this.setState({
      show: false,
    });
  };

  handleChange = (key, value) => {};

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <div className="juteConversion">
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormContainer">
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            JC Date<div className="mandatoryField">*</div>
                          </label>
                        </div>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat="dd-MM-yyyy"
                            value={this.state.inDate}
                            fullWidth
                            onChange={(e) => this.handleSelectDate(e, "inDate")}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  readOnly={true}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.branchListData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.branchId}
                          update={this.props.location.state ? 1 : 0}
                          name="branchId"
                          label="Branch"
                          required
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={UnitConversion}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.unitConversion}
                          name="unitConversion"
                          label="Unit Convertion"
                          required
                          updated={this.state.unitConversion ? 1 : 0}
                        />
                      </div>
                    </Box>

                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.itemsListData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.juteType}
                          name="juteType"
                          label="Source Jute Type"
                          required
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.itemsByQualityData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.quantity}
                          name="quantity"
                          label="Source Quality"
                          required
                          updated={this.state.quantity ? 1 : 0}
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.godownListData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.godownNo}
                          name="godown"
                          label="Godown Number"
                          required
                          updated={this.state.godownNo ? 1 : 0}
                        />
                      </div>
                    </Box>

                    <Box gridColumn="span 3">
                      <div className={"consoleAdormentBlock"}>
                        <label>
                          Source MR Number
                          <div className="mandatoryField">*</div>
                        </label>
                        <TextField
                          type="tel"
                          placeholder="Click on Get to fetch data"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  onClick={this.onHandleMrData}
                                  disabled={this.state.btnDisabled}
                                >
                                  Get
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {this.state.show && (
                          <MrCommonPopup>
                            <div className="mrPopupContainer">
                              <div className="gridContainer">
                                <div className="gridComponent">
                                  <div className="react-bootstrap-table">
                                    <table cellSpacing="0px" cellPadding="0px">
                                      <thead>
                                        <tr>
                                          <th></th>
                                          <th>MR Number</th>
                                          <th>Date</th>
                                          <th>Stock</th>
                                          <th>Unit Weight</th>
                                          <th>Bales/Drums</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.MrItemsByGodown.map(
                                          (row) => {
                                            let name = row.label.split("_");
                                            let values = row.name.split("^");
                                            return (
                                              <tr>
                                                <td>
                                                  <RadioButton
                                                    labelText=""
                                                    onChange={
                                                      this.onHandleChange
                                                    }
                                                  />
                                                </td>
                                                <td>{name[1]}</td>
                                                <td>{name[0]}</td>
                                                <td>{name[6]}</td>
                                                <td>{values[3]}</td>
                                                <td>{values[6]}</td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="consoleFormButtonBlock">
                              <CustomButton
                                label={"Cancel"}
                                className="greenBorderButton"
                                handleClick={this.handleCancel}
                              />
                              <CustomButton
                                label={"OK"}
                                className="greenButton"
                                handleClick={this.handleSubmit}
                                type="sumbit"
                              />
                            </div>
                          </MrCommonPopup>
                        )}
                      </div>
                    </Box>
                    <Box gridColumn="span 3" className="inputAdornmentText">
                      <div className="consoleTextFieldBlock ">
                        <div className="TextFieldBlock">
                          <label>Stock</label>
                        </div>
                        <InputAdornmentText label="QNT" type="number" />
                      </div>
                    </Box>
                    <Box gridColumn="span 3" className="inputAdornmentText">
                      <div className="consoleTextFieldBlock ">
                        <div className="TextFieldBlock">
                          <label>Quantity</label>
                        </div>
                        <InputAdornmentText label="DRUMS" type="number" />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.itemsListData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.juteType}
                          name="juteTypeConversion"
                          label="Jute Type Conversion"
                          required
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.itemsByQualityData}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.quantity}
                          name="qualityConversion"
                          label="Quality Conversion"
                          required
                          updated={this.state.quantity ? 1 : 0}
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <TextFieldNormal
                        label="Percentage"
                        placeholder="Enter here"
                        value={this.state.transporter}
                        onChange={this.onhandlechangeValue("transporter")}
                      />
                    </Box>
                    <Box gridColumn="span 3" className="inputAdornmentText">
                      <div className="consoleTextFieldBlock ">
                        <div className="TextFieldBlock">
                          <label>Weight</label>
                        </div>
                        <InputAdornmentText label="KG" type="number" />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const UnitConversion = [
  {
    value: 0,
    label: "Select...",
    name: "Select...",
  },
  {
    value: 1,
    label: "LOOSE",
    name: "LOOSE",
  },
  {
    value: 2,
    label: "BALE",
    name: "BALE",
  },
];

const mapStatetoProps = (state) => {
  const { branchList, itemsByItmGrpIdList } = state.DropDownListReducer;
  const { QualityByItemId, getGodownListData } =
    state.MasterDropDownListReducer;
  const { StockDetailsByGodown } = state.JuteConversionReducer;
  return {
    branchList,
    itemsByItmGrpIdList,
    QualityByItemId,
    getGodownListData,
    StockDetailsByGodown,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getBranchList,
    getItemsList,
    getQualityByItemList,
    getGodownNumberList,
    juteGetStockDetailsByGodownNumber,
  })(CreateJuteConversion)
);
