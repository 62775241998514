import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import { brokerMasterList } from "../../../store/Master/BrokerMaster/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      startIndex: 1,
      lastIndex: 10,
      totalRecs: 0,
      pageno: "",
      brokerMasterListData: [],
      brokerName: null,
      brokerType: null,
      dataexp: "",
    };
  }

  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      userId: this.state.userDit.userId.toString(),
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
    };
    this.props.brokerMasterList(
      serverApi.GET_ALL_BROKER_MASTER_LIST,
      data,
      this.props.history
    ); // calling Broker master list API
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.BrokerMasterList) {
      this.setState({
        totalRecs: props.BrokerMasterList.totalRecords,
        brokerMasterListData: props.BrokerMasterList.data,
        pageno: props.BrokerMasterList.pageno,
      });
    }
  }
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;

    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      userId: this.state.userDit.userId.toString(),
      brokerName: this.state.brokerName,
      brokerType: this.state.brokerType,
    };
    if (type === 1) {
      data.startIndex = sizePerPage;
      data.lastIndex = currentIndex;
      if (currentIndex >= 0) {
        this.props.brokerMasterList(
          serverApi.GET_ALL_BROKER_MASTER_LIST,
          data,
          this.props.history
        ); // calling Broker master list API
      }
    } else {
      data.startIndex = currentIndex === 0 ? currentIndex + 1 : currentIndex;
      data.lastIndex = sizePerPage;
      if (currentIndex >= 0) {
        this.props.brokerMasterList(
          serverApi.GET_ALL_BROKER_MASTER_LIST,
          data,
          this.props.history
        ); // calling Broker master list API
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  handleSearch = (searchData) => {
    var data = {
      cipher: searchData.cipher,
      companyId: searchData.companyId,
      userId: searchData.userId,
      brokerName: searchData.brokerName,
      brokerType: searchData.brokerType,
      startIndex: 1,
      lastIndex: 10,
    };
    this.setState({
      brokerName: searchData.brokerName,
      brokerType: searchData.brokerType,
    });
    this.props.brokerMasterList(
      serverApi.GET_ALL_BROKER_MASTER_LIST,
      data,
      this.props.history
    ); // calling Broker master list API
  };
  render() {
    return (
      <>
        <PaginationGridView
          mdata={this.state.brokerMasterListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          filter_form={"BrokerMaster"}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          url="/create_broker_master"
          pageno={this.state.pageno}
          handleSearch={this.handleSearch}
          apiexp={serverApi.GET_ALL_BROKER_MASTER_LIST}
          payloadexp={this.state.dataexp}
          expName={"Broker-Master.csv"}
        />
      </>
    );
  }
}
// Broker master list Headers
const Columns = [
  {
    Header: "Broker Name",
    accessor: "brokerName",
    hidden: false,
  },
  {
    Header: "Email",
    accessor: "email",
    hidden: false,
  },
  {
    Header: "Broker Type",
    accessor: "type",
    hidden: false,
  },
];
const mapStatetoProps = (state) => {
  const { BrokerMasterList } = state.BrokerMasterReducer; // fetching list from Broker master reducer
  return { BrokerMasterList };
};

export default withRouter(
  connect(mapStatetoProps, {
    brokerMasterList,
  })(Index)
);
