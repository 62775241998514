import {
  CUTS_AND_JUGAR_ENTRY_LIST,
  CUTS_AND_JUGAR_ENTRY_LIST_SUCCESSFULL,
  CUTS_AND_JUGAR_ENTRY_LIST_DEL,
  CUTS_AND_JUGAR_ENTRY_LIST_DEL_SUCCESSFULL,
  GET_LOOM_NO,
  GET_LOOM_NO_SUCCESSFULL,
  GET_CUTS_JUGAR_BY_LOOM,
  GET_CUTS_JUGAR_BY_LOOM_SUCCESSFULL,
  GET_DAILY_QUALITY_MAPPING,
  GET_DAILY_QUALITY_MAPPING_SUCCESSFULL,
  CUTS_JUGAR_DATA_LIST,
  CUTS_JUGAR_DATA_LIST_SUCCESSFULL,
  CREATE_ACTUAL_SHOTS,
  CREATE_ACTUAL_SHOTS_SUCCESSFULL,
  UPDATE_EB_NO_WORKING_HOURS,
  UPDATE_EB_NO_WORKING_HOURS_SUCCESSFULL,
  CUTS_JUGAR_LIST,
  CUTS_JUGAR_LIST_SUCCESSFULL,
  CREATE_CUTS_AND_JUGAR,
  CREATE_CUTS_AND_JUGAR_SUCCESSFULL,
  UPDATE_WEAVING_MACHINE_MAPPING,
  UPDATE_WEAVING_MACHINE_MAPPING_SUCCESSFULL,
  DELETE_ACTUAL_SHOTS,
  DELETE_ACTUAL_SHOTS_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  CutsAndJugarData: [],
  CutsAndJugarDelData: [],
  getLoomNoList: [],
  getCutsandJugarByLoom: [],
  dailyQualityList: [],
  cutsJugarDataList: [],
  createActualShotList: [],
  updateEbNoWrkHrsList: [],
  cutsJugarListRed: [],
  CreateCutsAndJugarList: [],
  weavingMachineMappingList: [],
  deletedActualShotList: [],
};

const CutsAndJugarEntryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUTS_AND_JUGAR_ENTRY_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CUTS_AND_JUGAR_ENTRY_LIST_SUCCESSFULL:
      state = {
        ...state,
        CutsAndJugarData: action.payload.response,
        loading: true,
      };
      break;
    case CUTS_AND_JUGAR_ENTRY_LIST_DEL:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CUTS_AND_JUGAR_ENTRY_LIST_DEL_SUCCESSFULL:
      state = {
        ...state,
        CutsAndJugarDelData: action.payload.response,
        loading: true,
      };
      break;
    case GET_LOOM_NO:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LOOM_NO_SUCCESSFULL:
      state = {
        ...state,
        getLoomNoList: action.payload.response,
        loading: true,
      };
      break;
    case GET_CUTS_JUGAR_BY_LOOM:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CUTS_JUGAR_BY_LOOM_SUCCESSFULL:
      state = {
        ...state,
        getCutsandJugarByLoom: action.payload.response,
        loading: true,
      };
      break;
    case GET_DAILY_QUALITY_MAPPING:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_DAILY_QUALITY_MAPPING_SUCCESSFULL:
      state = {
        ...state,
        dailyQualityList: action.payload.response,
        loading: true,
      };
      break;
    case CUTS_JUGAR_DATA_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CUTS_JUGAR_DATA_LIST_SUCCESSFULL:
      state = {
        ...state,
        cutsJugarDataList: action.payload.response.data,
        loading: true,
      };
      break;
    case CREATE_ACTUAL_SHOTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_ACTUAL_SHOTS_SUCCESSFULL:
      state = {
        ...state,
        createActualShotList: action.payload.response,
        loading: true,
      };
      break;
    case UPDATE_EB_NO_WORKING_HOURS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_EB_NO_WORKING_HOURS_SUCCESSFULL:
      state = {
        ...state,
        updateEbNoWrkHrsList: action.payload.response,
        loading: true,
      };
      break;
    case CUTS_JUGAR_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CUTS_JUGAR_LIST_SUCCESSFULL:
      state = {
        ...state,
        cutsJugarListRed: action.payload.response,
        loading: true,
      };
      break;
    case CREATE_CUTS_AND_JUGAR:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_CUTS_AND_JUGAR_SUCCESSFULL:
      state = {
        ...state,
        CreateCutsAndJugarList: action.payload.response,
        loading: true,
      };
      break;
    case UPDATE_WEAVING_MACHINE_MAPPING:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_WEAVING_MACHINE_MAPPING_SUCCESSFULL:
      state = {
        ...state,
        weavingMachineMappingList: action.payload.response,
        loading: true,
      };
      break;
    case DELETE_ACTUAL_SHOTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_ACTUAL_SHOTS_SUCCESSFULL:
      state = {
        ...state,
        deletedActualShotList: action.payload.response,
        loading: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CutsAndJugarEntryReducer;
