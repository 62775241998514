import {
  CLEAR_INDENT_LINEITEMS_JUTE,
  CLEAR_JUTE_INDENT_HEADER,
  CREATE_JUTE_INDENT,
  CREATE_JUTE_INDENT_SUCCESSFULL,
  INDENT_LINEITEMS_JUTE,
  JUTETYPE_BY_MUKAM,
  JUTETYPE_BY_MUKAM_SUCCESSFULL,
  JUTE_INDENT_BY_ID,
  JUTE_INDENT_BY_ID_SUCCESSFULL,
  JUTE_INDENT_HEADER,
  JUTE_INDENT_LIST,
  JUTE_INDENT_LIST_SUCCESSFULL,
  QUALITY_BY_JUTETYPE,
  QUALITY_BY_JUTETYPE_SUCCESSFULL,
  UPDATE_JUTE_INDENT,
  UPDATE_JUTE_INDENT_SUCCESSFULL,
} from "./actionTypes";
import moment from "moment";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  JuteIndentListData: [],
  JuteTypeByMukam: [],
  CreateJuteIndent: [],
  UpdateJuteIndent: [],
  indentViewById: [],
  JuteIndentHeader: {
    hdrId: "",
    id: "",
    type: "",
    status: "",
    submitter: "",
    finnacialYear: "",
    indentDate: moment().format("YYYY-MM-DD"),
    mukam: "",
    printTag: "",
    isActive: "1",
    vehicleTypeId: "",
    vehicleQuantity: "",
    quantityInTon: "",
    convertedQuantity: "",
    modOn: null,
    modBy: null,
    indentWeight: "",
    unitConversion: "BALES",
    autoDateTime: "",
    statusDesc: "",
    indentDateDesc: "",
    mukamNo: "",
    companyId: "",
    finalApprover: true,
    branchId: "",
    projectId: null,
    internalNote: null,
    remarks: null,
    userDesc: "",
    ApproveButton: false,
    RejectButton: false,
    UpdateButton: true,
    EditButton:false
  },
  JuteindentLineItems: [
    {
      id:1,
      IndentLineItemId:"",
      indentHeaderId: "",
      itemId: "",
      itemIdDesc: "",
      itemGroupId: "",
      itemGroupIdDesc: "",
      qualityCode:"",
      qualityCodeDesc: "",
      deptId: "",
      deptIdDesc: null,
      stock: 0,
      indentQuantity:"",
      unitId: "",
      additionalRequirement: null,
      dtlItemDesc: "",
      noOfBales: "",
      hsnCode: "",
      rate: 0,
      moisture: 0,
      taxId: 0,
      taxPercentage: 0,
      remainingQuantity: "",
      branchStock: null,
      pendingAtPo: 0,
      indentLineItemId: "",
      indentType: "",
      isActive:1,
      qualityByJuteList:[]
    },
  ],
};

const JuteIndentReducer = (state = initialState, action) => {
  switch (action.type) {
    case JUTE_INDENT_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTE_INDENT_LIST_SUCCESSFULL:
      state = {
        ...state,
        JuteIndentListData: action.payload.response,
        loading: true,
      };
      break;

    case JUTETYPE_BY_MUKAM:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTETYPE_BY_MUKAM_SUCCESSFULL:
      state = {
        ...state,
        JuteTypeByMukamData: action.payload.response,
        loading: true,
      };
      break;
    case QUALITY_BY_JUTETYPE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case QUALITY_BY_JUTETYPE_SUCCESSFULL:
      state = {
        ...state,
        QualityByJuteTypeData: action.payload.response,
        loading: true,
      };
      break;

    case INDENT_LINEITEMS_JUTE:
      state = {
        ...state,
        JuteindentLineItems: action.payload,
        loading: true,
      };
      break;
    case CLEAR_INDENT_LINEITEMS_JUTE:
      state = {
        ...state,
        JuteindentLineItems: [
          {
            id:1,
            IndentLineItemId:"",
            indentHeaderId: "",
            itemId: "",
            itemIdDesc: "",
            itemGroupId: "",
            itemGroupIdDesc: "",
            qualityCode:null,
            qualityCodeDesc: "",
            deptId: null,
            deptIdDesc: null,
            stock: 0,
            indentQuantity: "",
            unitId: "",
            additionalRequirement: null,
            dtlItemDesc: "",
            noOfBales: null,
            hsnCode: "",
            rate: 0,
            moisture: 0,
            taxId: 0,
            taxPercentage: 0,
            remainingQuantity: null,
            branchStock: null,
            pendingAtPo: 0,
            indentLineItemId: null,
            indentType: "",
            isActive:1,
            qualityByJuteList:[]
          },
        ],
        loading: true,
      };
      break;
    case CREATE_JUTE_INDENT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_JUTE_INDENT_SUCCESSFULL:
      state = {
        ...state,
        CreateJuteIndent: action.payload.response,
        loading: false,
      };
      break;
    case UPDATE_JUTE_INDENT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_JUTE_INDENT_SUCCESSFULL:
      state = {
        ...state,
        UpdateJuteIndent: action.payload.response,
        loading: false,
      };
      break;

    case JUTE_INDENT_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;

    case JUTE_INDENT_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        indentViewById: action.payload.response,
        loading: false,
      };
      break;
    case JUTE_INDENT_HEADER:
      state = {
        ...state,
        JuteIndentHeader: action.payload,
        loading: true,
      };
      break;
    case CLEAR_JUTE_INDENT_HEADER:
      state = {
        ...state,
        JuteIndentHeader: initialState.JuteIndentHeader,
        loading: true,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default JuteIndentReducer;
