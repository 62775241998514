import {WORKER_CATEGORY_LIST,WORKER_CATEGORY_LIST_SUCCESSFULL,CREATE_WORKER_CATEGORY_SUCCESSFULL,CREATE_WORKER_CATEGORY,
  UPDATE_WORKER_CATEGORY,
  UPDATE_WORKER_CATEGORY_SUCCESSFULL,
  API_ERRORS,
 } from './actionTypes';

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    WorkerCategoryList: [],
    CreateWorkerCategory:[],
    UpdateWorkerCategory:[],
  };

  const WorkerCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case WORKER_CATEGORY_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case WORKER_CATEGORY_LIST_SUCCESSFULL:
            state = {
              ...state,
              WorkerCategoryList: action.payload.response,
              loading: true,
            };
            break;
            case CREATE_WORKER_CATEGORY:
              state = {
                ...state,
                loading: true,
              };
              break;
            case CREATE_WORKER_CATEGORY_SUCCESSFULL:
              state = {
                ...state,
                CreateWorkerCategory: action.payload.response,
                loading: true,
              };
              break;
              case UPDATE_WORKER_CATEGORY:
              state = {
                ...state,
                loading: true,
              };
              break;
            case UPDATE_WORKER_CATEGORY_SUCCESSFULL:
              state = {
                ...state,
                UpdateWorkerCategory: action.payload.response,
                loading: true,
              };
              break;
              case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default WorkerCategoryReducer;