import { all, call, fork, takeEvery, put } from "redux-saga/effects";


import {TAX_MASTER_LIST} from './actionTypes';
import {TaxMasterListSuccessfull} from './actions';
import {getList} from '../../../helpers/Server_Helper';



function* getTaxMasterList({payload:{url,data,history}}) {
    try{
        const response = yield call(getList,url,data);
        yield put(TaxMasterListSuccessfull({response}))
    }catch(error){
        console.log(error)
    }
}




export function* watchTaxMasterList () {
    yield takeEvery(TAX_MASTER_LIST, getTaxMasterList)
}




function* TaxMasterListSaga() {
    yield all([
        fork(watchTaxMasterList),
    ])
}


export default TaxMasterListSaga;