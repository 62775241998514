import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextFieldAlfaNumReq from "../../../components/TextField/TextFieldAlfaNumReq";
import TextField from "../../../components/TextField/TextField";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DocumentUpload from "../../../components/DocumentUpload/DocumentUpload";
import { Link } from "react-router-dom";
import CustomButton from "../../../components/Buttons/Buttons";
import LeaveBalance from "../../../components/HRMSModule/LeaveBalance";
class ViewLeaveRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      emp_code: "",
    };
  }
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "type") {
      this.setState({
        location: selectedValue,
        location_name: selectedName,
      });
    }
    var a = (selectedValue, selectedName, name);
  };
  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
  };
  render() {
    return (
      <>
        <div className="consoleFormContainer d-flex">
          <Grid item xs={6}>
            <div className="consoleFormBlock">
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 6">
                  <div className="consoleTextFieldBlock">
                    <TextFieldAlfaNumReq
                      value={this.props.location.state.rowData.id}
                      label="Employee Code"
                      caps="ON"
                      placeholder="Enter here"
                      onChange={this.handleChange("emp_code")}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 6">
                  <div className="consoleTextFieldBlock">
                    <div className="TextFieldBlock">
                      <label>Leave Type</label>
                    </div>
                    <DynamicSelect
                      arrayOfData={type}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      selected={this.state.type}
                      name="type"
                    />
                  </div>
                </Box>
              </Box>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 6">
                  <div className="consoleTextFieldBlock">
                    <TextField
                      label="Leaves in Month"
                      placeholder="Leaves left"
                    />
                  </div>
                </Box>
                <Box gridColumn="span 6">
                  <div className="consoleTextFieldBlock">
                    <TextField
                      label="Leaves in Year"
                      placeholder="Leaves left"
                    />
                  </div>
                </Box>
              </Box>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 6">
                  <div className="consoleTextFieldBlock">
                    <div className="TextFieldBlock">
                      <label>From Date</label>
                    </div>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        name="financial_year"
                        inputFormat="dd.MM.yyyy"
                        id="financial_year"
                        value={this.state.dtvalue}
                        fullWidth
                        onChange={(newValue) => {
                          this.setState({ dtvalue: newValue });
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input
                              ref={inputRef}
                              {...inputProps}
                              readOnly={true}
                            />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </Box>
                <Box gridColumn="span 6">
                  <div className="consoleTextFieldBlock">
                    <div className="TextFieldBlock">
                      <label>To Date</label>
                    </div>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        name="financial_year"
                        inputFormat="dd.MM.yyyy"
                        id="financial_year"
                        value={this.state.dtvalue}
                        fullWidth
                        onChange={(newValue) => {
                          this.setState({ dtvalue: newValue });
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input
                              ref={inputRef}
                              {...inputProps}
                              readOnly={true}
                            />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </Box>
              </Box>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 12">
                  <div className="consoleTextFieldBlock">
                    <TextField
                      label="Reason"
                      placeholder="Enter here"
                      value={this.props.location.state.rowData.reason}
                    />
                  </div>
                </Box>
              </Box>
              <DocumentUpload label="Upload Document" />
            </div>
          </Grid>
          <Grid item xs={6}>
            <LeaveBalance leaveBalance={true} />
          </Grid>
        </div>

        <div className="consoleFormButtonBlock">
          <Link to="client_visitPlan">
            <CustomButton label={"Cancel"} className="cancelButton" />
          </Link>
          <Link to="client_visitPlan">
            <CustomButton
              label={"Apply Leave"}
              className="saveButton"
              handleClick={this.onClickNext}
              type="sumbit"
            />
          </Link>
        </div>
      </>
    );
  }
}

const type = [
  {
    value: "1",
    label: "Casual",
    name: "Casual",
  },
  {
    value: "2",
    label: "Sick",
    name: "Sick",
  },
  {
    value: "3",
    label: "Half Day",
    name: "Half Day",
  },
];
const mapStatetoProps = (state) => {};

export default withRouter(connect(mapStatetoProps, {})(ViewLeaveRequest));
