/*
 * Author: Pritam Kumar Shahi
 * Date: March 04, 2024
 */

import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import VowLogo from "../../assets/images/vow_logo.png";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { checkLogin, apiError, checkToken } from "../../store/actions";
import { serverApi } from "../../helpers/Consts";
import swal from "sweetalert";
import { useHistory, useLocation } from "react-router-dom";
import { resetUserPassword } from "../../store/auth/forgetpwd/actions";

const ResetPassword = () => {
  const [state, setState] = useState({
    confirmPassword: '',
    password: '',
  });

  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const handleInputOnChange = (event) => {
    event.preventDefault();
    const target = event.target;
    setState({
      ...state,
      [target.name]: target.value,
    });
  };

  const onResetPassword = () => {
    const queryParams = new URLSearchParams(location.search);

    const token = queryParams.get('token');
    const userId= queryParams.get('user');
    const {password, confirmPassword} = state;
    if(password === "" || confirmPassword === "") {
      swal("Please Enter All Mandatory Fields..!!");
    }else if (password === confirmPassword && password!=="") {
        var data = {"md5": token, "newPassword": password, "forgotPassword": true, "userId": ""};
        if(token==='1'){
             data = {"newPassword": password, "forgotPassword": false, "userId": userId};
        }
        dispatch(resetUserPassword(serverApi.RESET_USER_PASSWORD, data, history))
    } else {
      swal("Password does not match..!");
    }
};

const handleTogglePassword = () => {
  setShowPassword((prev) => !prev);
};

  return (
    <ThemeProvider theme={createTheme()}>
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        className="loginPatternOne"
      >
        <CssBaseline />
        <Grid item xs={false} sm={4} md={6} className="loginBackground">
          <Box className="loginLeftContent">
            <h2>Nice to meet you</h2>
            <h1>WELCOME</h1>
            <p>
              We have customized this product according to your needs and
              can't wait to see you using this product. This will simplify
              working of your business
            </p>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          component={Paper}
          elevation={6}
          square
          className="loginContainer"
        >
          <Box
            className="loginBlock"
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={VowLogo} alt="" />
            <Typography component="h1">Reset Password</Typography>
            <Typography component="p">
              Reset Password and proceed with the company login process
            </Typography>
            <form>
              <Box noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  value={state.password}
                  onChange={handleInputOnChange}
                  autoFocus
                  className="loginInput"
                  placeholder="New Password"
                  type={"text"}
                />
                <TextField
                  margin="normal"
                  autoComplete="off"
                  required
                  fullWidth
                  name="confirmPassword"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={state.confirmPassword}
                  onChange={handleInputOnChange}
                  className="loginInput"
                  placeholder="Re-type password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePassword}>
                          {showPassword ? <VisibilityRoundedIcon /> : <VisibilityOffRoundedIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    backgroundColor: '#e7f0fe', // Set your desired background color
                    '&:hover': {
                      backgroundColor: 'lightgray', // Change color on hover if needed
                    },
                  }}
                />

                <Button
                  color="primary"
                  className="loginButton"
                  onClick={onResetPassword}
                >
                  Reset Password
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(
  connect(mapStatetoProps, { checkLogin, apiError, checkToken })(ResetPassword)
);
