//************************************************************************** */
//date of creation : 01-08-2023 :: 12:00 AM Author : Nagesh Medisetty //
//************************************************************************** */

import React from "react";
import IndentFilter from "../FilterPopup/IndentFilter";
import StoreModuleFilter from "../FilterPopup/StoreModuleFilter";
import PriceEnquiryFilter from "../FilterPopup/PriceEnquiryFilter";
import InwardFilter from "../FilterPopup/InwardFilter";
import TravelCategoriesMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/TravelCategoriesMasterFilter";
import BrokerMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/BrokerMasterFilter";
import WorkReportFilter from "../FilterPopup/WorkReportFilter";
import WorkOrderFilter from "../FilterPopup/WorkOrderFilter";
import PrResponseFilter from "../FilterPopup/PrResponseFilter";
import MaterialRequest from "../FilterPopup/MaterialInspection";

import StoreReceiptFilter from "../FilterPopup/StoreReceiptFilter";
import PayRegisterFilter from "../FilterPopup/PayRegisterFilter";
import Leaverequest from "../FilterPopup/Leaverequest";
import AttendanceLogs from "../FilterPopup/AttendanceLogs";
import AttendanceList from "../FilterPopup/AttendanceList";
import EmployeeData from "../FilterPopup/EmployeeData";
import ActiveEmployeeData from "../FilterPopup/ActiveEmployeeData";
import InActiveEmployeeData from "../FilterPopup/InActiveEmployeeData";
import QuotationFilter from "../FilterPopup/QuotationFilter";
import QuotationListFilter from "../FilterPopup/QuotationListFilter"
import SalesOrderFilter from "../FilterPopup/SalesModuleFilters/SalesOrderFilter";
import ItemMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/ItemMasterFilter";
import DepartmentFilter from "../FilterPopup/MasterModuleFilterPopUps/DepartmentFilter";
import ProjectListFilter from "../FilterPopup/ProjectListFilter";
import BankAccountMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/BankAccountMasterFilter";
import QualityMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/QualityMasterFilter";
import SpellMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/SpellMasterFilter";
import VehicleTypeMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/VehicleTypeMasterFilter";
import WeavingQltyMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/WeavingQltyMasterFilter";
import YarnMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/YarnMasterFilter";
import MachineMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/MachineMasterFilter";
import DesignationMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/DesignationMasterFilter";
import CustomerMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/CustomerMasterFilter";
import WareHouseMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/WareHouseMasterFilter";
import ItemGroupFilter from "../FilterPopup/MasterModuleFilterPopUps/ItemGroupFilter";
import SupplierMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/SupplierMasterFilter";
import TermsMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/TermsMasterFilter";
import LedgerGroupMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/LedgerGroupMasterFilter";
import MukamFilter from "../FilterPopup/MasterModuleFilterPopUps/MukamFilter";
import BudgetHeadsFilter from "../FilterPopup/MasterModuleFilterPopUps/BudgetHeadsFilter";
import LedgerMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/LedgerMasterFilter";
import SpinningQualityMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/SpinningQualityMasterFilter";
import CostCenterFilter from "../FilterPopup/MasterModuleFilterPopUps/CostCenterFilter";
import DebitCreditNoteFilter from "../FilterPopup/DebitCreditNoteFilter";
import UomMasterFilter from "../FilterPopup/MasterModuleFilterPopUps/uomMasterFilter";
import DeliveryOrderFilter from "../FilterPopup/SalesModuleFilters/DeliveryOrderFilter";
import SalesInvoiceFilter from "../FilterPopup/SalesModuleFilters/SalesInvoiceFilters";
import AccountingBillsFilter from "../FilterPopup/AccountingBillsFilter"
import AccountingSalesBillsFilter from "../FilterPopup/SalesBillsFilters"


const filterComponet = (props) => {
    const filter_form = props.filter_form;
    const toolkitprops = props.toolkitprops;
    const columns = props.columns;
    const showFilter = props.showFilter;
    const handle_search = props.handle_search;

    return (filter_form === "indent" ? (
        <IndentFilter
            toolkitprops={toolkitprops}
            columns={columns}
            showFilter={showFilter}
            handle_search={handle_search}
            filter_form={filter_form}
        />
    ) : filter_form === "storeModule" ? (
        <StoreModuleFilter
            toolkitprops={toolkitprops}
            columns={columns}
            showFilter={showFilter}
            handle_search={handle_search}
            filter_form={filter_form}
        />
    )
    : filter_form === "openIndent" ? (
        <IndentFilter
            toolkitprops={toolkitprops}
            columns={columns}
            showFilter={showFilter}
            handle_search={handle_search}
            filter_form={filter_form}
        />
    )
        : filter_form === "priceEnquiry" ? (
            <PriceEnquiryFilter
                toolkitprops={toolkitprops}
                columns={columns}
                showFilter={showFilter}
                handle_search={handle_search}
                filter_form={filter_form}
            />
        ) : filter_form === "inward" ? (
            <InwardFilter
                toolkitprops={toolkitprops}
                columns={columns}
                showFilter={showFilter}
                handle_search={handle_search}
                filter_form={filter_form}
            />
        ) : filter_form === "TravelCategories" ? (
            <>
                <TravelCategoriesMasterFilter
                    toolkitprops={toolkitprops}
                    columns={columns}
                    showFilter={showFilter}
                    handle_search={handle_search}
                    filter_form={filter_form}
                />
            </>
        ) : filter_form === "BrokerMaster" ? (
            <>
                <BrokerMasterFilter
                    toolkitprops={toolkitprops}
                    columns={columns}
                    showFilter={showFilter}
                    handle_search={handle_search}
                    filter_form={filter_form}
                />
            </>
        ) : filter_form === "workReport" ? (
            <>
                <WorkReportFilter
                    toolkitprops={toolkitprops}
                    columns={columns}
                    showFilter={showFilter}
                    handle_search={handle_search}
                    filter_form={filter_form}
                />
            </>
        ) : filter_form === "workOrder" ? (
            <WorkOrderFilter
                toolkitprops={toolkitprops}
                columns={columns}
                showFilter={showFilter}
                handle_search={handle_search}
                filter_form={filter_form}
            />
        ) : filter_form === "PrResponse" ? (
            <PrResponseFilter
                toolkitprops={toolkitprops}
                columns={columns}
                showFilter={showFilter}
                handle_search={handle_search}
                filter_form={filter_form}
            />
        ) : filter_form === "PurchaseOrder" ? (
            <WorkOrderFilter
                toolkitprops={toolkitprops}
                columns={columns}
                showFilter={showFilter}
                handle_search={handle_search}
                filter_form={filter_form}
                PoNo={"2"}
            />
        ) : filter_form === "MaterialInspection" ? (
            <MaterialRequest
                toolkitprops={toolkitprops}
                columns={columns}
                showFilter={showFilter}
                handle_search={handle_search}
                filter_form={filter_form}
            />
        ) : filter_form === "StoreReceipt" ? (
            <StoreReceiptFilter
                toolkitprops={toolkitprops}
                columns={columns}
                showFilter={showFilter}
                handle_search={handle_search}
                filter_form={filter_form}
                PoNo={"1"}
            />
        ) : filter_form === "billPass" ? (
            <StoreReceiptFilter
                toolkitprops={toolkitprops}
                columns={columns}
                showFilter={showFilter}
                handle_search={handle_search}
                filter_form={filter_form}
            />
        ) : filter_form === "PayRegister" ? (
            <PayRegisterFilter
                toolkitprops={toolkitprops}
                columns={columns}
                showFilter={showFilter}
                handle_search={handle_search}
                filter_form={filter_form}
            />
        ) : filter_form === "LeaveRequest" ? (
            <Leaverequest
                toolkitprops={toolkitprops}
                columns={columns}
                showFilter={showFilter}
                handle_search={handle_search}
                filter_form={filter_form}
            />
        ) : filter_form === "AttendanceLogs" ? (
            <>
                <AttendanceLogs
                    From_date={this.props.From_date}
                    toDate={this.props.toDate}
                    handleChange={this.props.handleChange}
                    toolkitprops={toolkitprops}
                    columns={columns}
                    showFilter={showFilter}
                    handle_search={handle_search}
                    filter_form={filter_form}
                    handlegetdates={this.props.handlegetdates}
                />
            </>
        ) : filter_form === "AttendanceList" ? (
            <AttendanceList
                toolkitprops={toolkitprops}
                columns={columns}
                showFilter={showFilter}
                handle_search={handle_search}
                filter_form={filter_form}
            />
        ) : filter_form === "TotalEmployeedata" ? (
            <EmployeeData
                toolkitprops={toolkitprops}
                columns={columns}
                showFilter={showFilter}
                handle_search={handle_search}
                filter_form={filter_form}
            />
        ) : filter_form === "ActiveEmployeedata" ? (
            <ActiveEmployeeData
                toolkitprops={toolkitprops}
                columns={columns}
                showFilter={showFilter}
                handle_search={handle_search}
                filter_form={filter_form}
            />
        ) : filter_form ===
            "InActiveEmployeedata" ? (
            <InActiveEmployeeData
                toolkitprops={toolkitprops}
                columns={columns}
                showFilter={showFilter}
                handle_search={handle_search}
                filter_form={filter_form}
            />
        ) : filter_form === "Quotation" ? (
            <>
                <QuotationListFilter
                    toolkitprops={toolkitprops}
                    columns={columns}
                    showFilter={showFilter}
                    handle_search={handle_search}
                    filter_form={filter_form}
                />
            </>
        ) : filter_form === "SalesOrder" ? (
            <>
                <SalesOrderFilter
                    toolkitprops={toolkitprops}
                    columns={columns}
                    showFilter={showFilter}
                    handle_search={handle_search}
                    filter_form={filter_form}
                />
            </>
        ) :  filter_form === "DeliveryOrder" ? (
            <>
                <DeliveryOrderFilter
                    toolkitprops={toolkitprops}
                    columns={columns}
                    showFilter={showFilter}
                    handle_search={handle_search}
                    filter_form={filter_form}
                />
            </>
        ) : filter_form === "SalesInvoice" ? (
            <>
                <SalesInvoiceFilter
                    toolkitprops={toolkitprops}
                    columns={columns}
                    showFilter={showFilter}
                    handle_search={handle_search}
                    filter_form={filter_form}
                />
            </>
        ) : 
        
        filter_form === "boq" ? (
            <IndentFilter
                toolkitprops={toolkitprops}
                columns={columns}
                showFilter={showFilter}
                handle_search={handle_search}
                filter_form={filter_form}
            />
        ) : filter_form === "ItemMaster" ? (
            <>
                <ItemMasterFilter
                    toolkitprops={toolkitprops}
                    columns={columns}
                    showFilter={showFilter}
                    handle_search={handle_search}
                    filter_form={filter_form}
                />
            </>
        ) : filter_form === "DepartmentMaster" ? (
            <DepartmentFilter
                toolkitprops={toolkitprops}
                columns={columns}
                showFilter={showFilter}
                handle_search={handle_search}
                filter_form={filter_form}
            />
        ) : filter_form === "bom" ? (
            <IndentFilter
                toolkitprops={toolkitprops}
                columns={columns}
                showFilter={showFilter}
                handle_search={handle_search}
                filter_form={filter_form}
            />
        ) : filter_form === "Project-List" ? (
            <ProjectListFilter
                toolkitprops={toolkitprops}
                columns={columns}
                showFilter={showFilter}
                handle_search={handle_search}
                filter_form={filter_form}
            />
        )
            : filter_form === "BankAccounts" ? (
                <BankAccountMasterFilter
                    toolkitprops={toolkitprops}
                    columns={columns}
                    showFilter={showFilter}
                    handle_search={handle_search}
                    filter_form={filter_form}
                />
            ) : filter_form === "QualityMaster" ? (
                <QualityMasterFilter
                    toolkitprops={toolkitprops}
                    columns={columns}
                    showFilter={showFilter}
                    handle_search={handle_search}
                    filter_form={filter_form}
                />
            ) : // Masters filter
                filter_form === "SpellMaster" ? (
                    <SpellMasterFilter
                        toolkitprops={toolkitprops}
                        columns={columns}
                        showFilter={showFilter}
                        handle_search={handle_search}
                        filter_form={filter_form}
                    />
                ) : filter_form === "VehicleTypeMaster" ? (
                    <>
                        <VehicleTypeMasterFilter
                            toolkitprops={toolkitprops}
                            columns={columns}
                            showFilter={showFilter}
                            handle_search={handle_search}
                            filter_form={filter_form}
                        />
                    </>
                ) : filter_form ===
                    "WeavingQualityMaster" ? (
                    <WeavingQltyMasterFilter
                        toolkitprops={toolkitprops}
                        columns={columns}
                        showFilter={showFilter}
                        handle_search={handle_search}
                        filter_form={filter_form}
                    />
                ) : filter_form === "YarnMaster" ? (
                    <YarnMasterFilter
                        toolkitprops={toolkitprops}
                        columns={columns}
                        showFilter={showFilter}
                        handle_search={handle_search}
                        filter_form={filter_form}
                    />
                ) : filter_form === "MachineMaster" ? (
                    <MachineMasterFilter
                        toolkitprops={toolkitprops}
                        columns={columns}
                        showFilter={showFilter}
                        handle_search={handle_search}
                        filter_form={filter_form}
                    />
                ) : filter_form === "DesignationMaster" ? (
                    <DesignationMasterFilter
                        toolkitprops={toolkitprops}
                        columns={columns}
                        showFilter={showFilter}
                        handle_search={handle_search}
                        filter_form={filter_form}
                    />
                ) : filter_form === "CustomerMaster" ? (
                    <>
                        <CustomerMasterFilter
                            toolkitprops={toolkitprops}
                            columns={columns}
                            showFilter={showFilter}
                            handleSearch={handle_search}
                            filter_form={filter_form}
                        />
                    </>
                ) : filter_form === "warehouseMaster" ? (
                    <WareHouseMasterFilter
                        toolkitprops={toolkitprops}
                        columns={columns}
                        showFilter={showFilter}
                        handle_search={handle_search}
                        filter_form={filter_form}
                    />
                ) : filter_form === "SupplierMaster" ? (
                    <SupplierMasterFilter
                        toolkitprops={toolkitprops}
                        columns={columns}
                        showFilter={showFilter}
                        handle_search={handle_search}
                        filter_form={filter_form}
                    />
                ) : filter_form === "itemGroupMaster" ? (
                    <ItemGroupFilter
                        toolkitprops={toolkitprops}
                        columns={columns}
                        showFilter={showFilter}
                        handle_search={handle_search}
                        filter_form={filter_form}
                    />
                ) : filter_form === "TermsMaster" ? (
                    <TermsMasterFilter
                        toolkitprops={toolkitprops}
                        columns={columns}
                        showFilter={showFilter}
                        handle_search={handle_search}
                        filter_form={filter_form}
                    />
                ) : filter_form === "LedgerGroupMaster" ? (
                    <>
                        <LedgerGroupMasterFilter
                            toolkitprops={toolkitprops}
                            columns={columns}
                            showFilter={showFilter}
                            handle_search={handle_search}
                            filter_form={filter_form}
                        />
                    </>
                ) : filter_form === "Mukam_Master" ? (
                    <MukamFilter
                        toolkitprops={toolkitprops}
                        columns={columns}
                        showFilter={showFilter}
                        handle_search={handle_search}
                        filter_form={filter_form}
                    />
                ) : filter_form === "budgetHeads" ? (
                    <BudgetHeadsFilter
                        toolkitprops={toolkitprops}
                        columns={columns}
                        showFilter={showFilter}
                        handle_search={handle_search}
                        filter_form={filter_form}
                    />
                ) : filter_form === "ledgerMaster" ? (
                    <LedgerMasterFilter
                        toolkitprops={toolkitprops}
                        columns={columns}
                        showFilter={showFilter}
                        handle_search={handle_search}
                        filter_form={filter_form}
                    />
                ) : filter_form ===
                    "SpinningQualityMaster" ? (
                    <SpinningQualityMasterFilter
                        toolkitprops={toolkitprops}
                        columns={columns}
                        showFilter={showFilter}
                        handle_search={handle_search}
                        filter_form={filter_form}
                    />
                ) : filter_form === "Cost_Center_Master" ? (
                    <CostCenterFilter
                        toolkitprops={toolkitprops}
                        columns={columns}
                        showFilter={showFilter}
                        handle_search={handle_search}
                        filter_form={filter_form}
                    />
                )
                    : filter_form === "DebitCreditNoteFilter" ? (
                        <DebitCreditNoteFilter
                            toolkitprops={toolkitprops}
                            columns={columns}
                            showFilter={showFilter}
                            handle_search={handle_search}
                            filter_form={filter_form}
                        />
                    )
                        : filter_form === "uomMaster" ? (
                            <UomMasterFilter
                                toolkitprops={toolkitprops}
                                columns={columns}
                                showFilter={showFilter}
                                handle_search={handle_search}
                                filter_form={filter_form}
                            />
                        ) : filter_form === "accounting_bill" ? (
                            <AccountingBillsFilter
                                toolkitprops={toolkitprops}
                                columns={columns}
                                showFilter={showFilter}
                                handle_search={handle_search}
                                filter_form={filter_form}
                            />
                        ): filter_form === "sales_bills" ? (
                            <AccountingSalesBillsFilter
                                toolkitprops={toolkitprops}
                                columns={columns}
                                showFilter={showFilter}
                                handle_search={handle_search}
                                filter_form={filter_form}
                            />
                        ): (
                            ""
                        ));
}

export default filterComponet;
