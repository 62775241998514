import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CheckIcon from "@mui/icons-material/Check"; // Import CheckIcon from MUI

const TextFieldGstVerify = ({
  value,
  label,
  placeholder,
  type,
  onChange,
  required,
  onVerify,
  isGstVerified
}) => {
  const [error, setError] = useState(false);
  const [isVerified, setVerified] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    onChange(value);    
    setVerified(false); // Reset verification status when the input changes
  };

  useEffect(() => {
    setVerified(isGstVerified)
  }, [isGstVerified])

  const handleVerify = async () => {    // For demonstration, let's consider verification is successful if the GST number is not empty
    const isVerificationSuccessful = value.trim() !== '';
    // Invoke the provided onVerify callback
    if (onVerify) {
      await onVerify(isVerificationSuccessful);
    }
  };

  return (
    <div className="consoleAdormentBlock">
        {label && (
          <label htmlFor="app-input-field">
            {label}{" "}
            <label className="starcolor">{required && "*"}</label>
          </label>
        )}      
        <br />   
        <TextField
          type={type}
          value={value}
          className="TextField"
          fullWidth
          placeholder={placeholder}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isVerified ? (
                  <>
                  <CheckIcon style={{ color: "green" }} /> 
                    <span style={{ margin: "0px", marginRight: "5px"}}>Verified</span>
                  </>
              ) : (
                <Button onClick={handleVerify}>Verify</Button>
              )}
              </InputAdornment>
            ),
          }}
        />
      {error && <span className="text-danger">{error.message}</span>}
    </div>
  );
};

TextFieldGstVerify.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.array,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

TextFieldGstVerify.defaultProps = {
  value: "",
  label: "",
  placeholder: "",
  type: "text",
  validators: [],
};

export default TextFieldGstVerify;
