import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import { vehicleTypesMasterList } from "../../../store/Master/VehicleTypesMaster/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      vehicleType: null,
      vehicleTypesMasterListData: [], // to display list data
      dataexp: "",
    };
  }

  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
      vehicleType: this.state.vehicleType,
    };

    this.props.vehicleTypesMasterList(
      serverApi.GET_ALL_VEHICLE_TYPES_MASTER_LIST,
      data,
      this.props.history
    ); // calling Vehicle types master list API
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.VehicleTypesMasterList) {
      this.setState({
        totalRecs: props.VehicleTypesMasterList.totalRecords,
        vehicleTypesMasterListData: props.VehicleTypesMasterList.data, // updating vehicle type list
      });
    }
  }
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const data = {
        companyId: localStorage.getItem("companyId"),
        cipher: this.state.userDit.cipher,
        startIndex: sizePerPage,
        lastIndex: currentIndex,
        vehicleType: this.state.vehicleType,
      };
      if (currentIndex >= 0) {
        this.props.vehicleTypesMasterList(
          serverApi.GET_ALL_VEHICLE_TYPES_MASTER_LIST,
          data,
          this.props.history
        );
      }
    } else {
      const data = {
        companyId: localStorage.getItem("companyId"),
        cipher: this.state.userDit.cipher,
        startIndex: currentIndex === 0 ? currentIndex + 1 : currentIndex,
        lastIndex: sizePerPage,
        vehicleType: this.state.vehicleType,
      };
      if (currentIndex >= 0) {
        this.props.vehicleTypesMasterList(
          serverApi.GET_ALL_VEHICLE_TYPES_MASTER_LIST,
          data,
          this.props.history
        );
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  handleSearch = (data1) => {
    const data = {
      vehicleType: data1.vehicleType,
      companyId: localStorage.getItem("companyId"),
      cipher: this.state.userDit.cipher,
      startIndex: 1,
      lastIndex: 10,
    };
    this.setState({
      vehicleType: data1.vehicleType,
    });
    this.props.vehicleTypesMasterList(
      serverApi.GET_ALL_VEHICLE_TYPES_MASTER_LIST,
      data,
      this.props.history
    );
  };
  render() {
    return (
      <>
        <PaginationGridView
          mdata={this.state.vehicleTypesMasterListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          handleSearch={this.handleSearch}
          filter_form={"VehicleTypeMaster"}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          url="/create_vehicle_types_master"
          apiexp={serverApi.GET_ALL_VEHICLE_TYPES_MASTER_LIST}
          payloadexp={this.state.dataexp}
          expName={"Vehicle-Type-Master.csv"}
        />
      </>
    );
  }
}
// Vehicle types master list Headers
const Columns = [
  {
    Header: "Vehicle Type",
    accessor: "vehicleType",
    hidden: false,
  },
  {
    Header: "Max Capacity",
    accessor: "weight",
    hidden: false,
  },
];
const mapStatetoProps = (state) => {
  const { VehicleTypesMasterList } = state.VehicleTypesMasterReducer; // fetching list from vehicle types reducer
  return { VehicleTypesMasterList };
};

export default withRouter(
  connect(mapStatetoProps, {
    vehicleTypesMasterList,
  })(Index)
);
