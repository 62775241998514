import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick

import { Grid, Box } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { serverApi } from "../../../helpers/Consts";
import {
  HolidayMasterList,
  holidayDataAdd,
  deleteHolidayList,
  holidayDataUpdate,
} from "../../../store/Master/HolidayCalendar/actions";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import CustomButton from "../../../components/Buttons/Buttons";
import TextArea from "../../../components/TextField/TextArea";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../../components/Buttons/Buttons";
import swal from "sweetalert";

class HolidayCalendar extends React.Component {
  calendarComponentRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      holidayMasterListData: [],
      fase: false,
      calendarWeekends: true,
      firstDay: 1,
      calendarEvents: [],
      confirmPopUp: false,
      targatedDate: "",
      selectedDayEventsList: [],
      holidayDescription: "",
      updateHoliday: false,
      itemId: "",
      descriptionError: false,
    };
  }

  componentDidMount() {
    const data = {
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      companyId: localStorage.getItem("companyId"),
      spell: "",
    };
    this.props.HolidayMasterList(
      serverApi.GET_ALL_HOLIDAY_MASTER_LIST,
      data,
      this.props.history
    ); // calling Holiday List api
  }
  componentWillReceiveProps(props) {
    const evtData = [];
    if (props.holiday_master_List) {
      if (props.holiday_master_List.data) {
        props.holiday_master_List.data.map((row) => {
          var event = {
            id: row.id,
            title: row.description,
            start: row.holiday,
            allDay: true,
            editable: false,
            clickable: true,
            overlap: false,
            backgroundColor: "#004b73",
          };

          evtData.push(event);
        });
      }

      this.setState({
        calendarEvents: evtData,
        holidayMasterListData: props.holiday_master_List.data, // updating Holuday list data
      });
    }

    if (props.holiday_delete_data) {
      this.setState({
        confirmPopUp: false,
      });
    }
  }

  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "holidayDescription") {
      this.setState({
        holidayDescription: event,
        descriptionError: false,
      });
    }
  };
  handleAddHoliday = () => {
    if (this.state.holidayDescription !== "") {
      const data = {
        companyId: localStorage.getItem("companyId"),
        holiday: this.state.targatedDate,
        description: this.state.holidayDescription,
        createdby: this.state.userDit.userName,
      };
      this.props.holidayDataAdd(
        serverApi.ADD_HOLIDAY_MASTER,
        data,
        this.props.history
      ); // calling Holiday Add api
      this.setState({
        confirmPopUp: false,
        holidayDescription: "",
        updateHoliday: false,
      });
    } else {
      // alert("please fill all the data");
      this.setState({ descriptionError: true });
    }
  };
  handleUpdateHoliday = () => {
    if (this.state.holidayDescription !== "") {
      const data = {
        companyId: localStorage.getItem("companyId"),
        holiday: this.state.targatedDate,
        description: this.state.holidayDescription,
        createdby: this.state.userDit.userName,
        id: this.state.itemId,
      };
      this.props.holidayDataUpdate(
        serverApi.UPDATE_HOLIDAY_ITEM,
        data,
        this.props.history
      ); // calling Holiday Update api
      this.setState({
        confirmPopUp: false,
        holidayDescription: "",
        updateHoliday: false,
      });
    } else {
      swal("please fill all the data");
    }
  };
  handleClose = () => {
    this.setState({ confirmPopUp: false, updateHoliday: false });
  };
  onEditClick = (item) => {
    this.setState({
      holidayDescription: item.title,
      updateHoliday: true,
      itemId: item.id,
    });
  };
  onDelete = (id) => {
    let cipher = JSON.parse(localStorage.getItem("authUser")).cipher;
    this.props.deleteHolidayList(
      serverApi.DELETE_HOLIDAY_ITEM + id + "/" + cipher,
      this.props.history
    );
  };

  onSelect = (e) => {
    const clickedDate = moment(e.startStr).format("DD-MM-YYYY");
    let data = this.state.calendarEvents.filter(
      (item) => moment(item.start).format("DD-MM-YYYY") === clickedDate
    );

    this.setState({
      confirmPopUp: true,
      targatedDate: clickedDate,
      selectedDayEventsList: data,
    });
  };
  render() {
    return (
      <div className="demo-app holidayCalendar">
        <div className="demo-app-calendar">
          <FullCalendar
            defaultView="dayGridMonth"
            header={{
              left: "prev,next today",

              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
            }}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            events={this.state.calendarEvents}
            eventMouseover={this.handleEventPositioned}
            eventClick={this.handlePopup}
            selectable
          />
        </div>
        {this.state.confirmPopUp && (
          <CommonPopup>
            <div className="holidayPopup">
              <h5>Holiday</h5>
              <div className="">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <label>Date : </label>
                    {this.state.targatedDate}
                  </Grid>
                  <Grid item xs={6}>
                    {this.state.selectedDayEventsList.length > 0 &&
                      this.state.updateHoliday === false && (
                        <Box gridColumn="span 6">
                          <Button
                            handleClick={() => {
                              this.setState({
                                updateHoliday: true,
                              });
                            }}
                            className="blueButton"
                            label="Create"
                          />
                        </Box>
                      )}
                  </Grid>
                  <Grid item xs={12}>
                    <div className="consoleTextFieldBlock">
                      {(this.state.selectedDayEventsList.length === 0 &&
                        this.state.updateHoliday === false) ||
                      this.state.updateHoliday === true ? (
                        <>
                          <TextArea
                            label="Description"
                            onChange={this.onhandlechange("holidayDescription")}
                            value={this.state.holidayDescription}
                            required
                          />
                          {this.state.descriptionError === true ? (
                            <span>This field is required</span>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {this.state.selectedDayEventsList.length !== 0 &&
                        this.state.updateHoliday === false && (
                          <div className="holidayTable">
                            <div className="gridContainer">
                              <div className="gridComponent">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Description</th>
                                      <th colSpan={2}>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.selectedDayEventsList.map(
                                      (item, index) => (
                                        <tr key={index}>
                                          <td>{item.title}</td>
                                          <td>
                                            <EditIcon
                                              onClick={() => {
                                                this.onEditClick(item);
                                              }}
                                            />
                                          </td>
                                          <td>
                                            <DeleteIcon
                                              onClick={() => {
                                                this.onDelete(item.id);
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </Grid>
                </Grid>

                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Cancel"}
                    className="greenBorderButton"
                    handleClick={this.handleClose}
                    type="sumbit"
                  />
                  {this.state.selectedDayEventsList.length !== 0 &&
                  this.state.updateHoliday === true ? (
                    ""
                  ) : (
                    <CustomButton
                      label={"Add"}
                      className="greenButton"
                      handleClick={this.handleAddHoliday}
                    />
                  )}
                  {this.state.updateHoliday === true ? (
                    <CustomButton
                      label={"Update"}
                      className="greenButton"
                      handleClick={this.handleUpdateHoliday}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </CommonPopup>
        )}
      </div>
    );
  }
}
const mapStatetoProps = (state) => {
  const { holiday_master_List, holiday_add_data, holiday_delete_data } =
    state.HolidayListReducer; // fetching list from Holiday Calendar reducer
  return { holiday_master_List, holiday_add_data, holiday_delete_data };
};

export default withRouter(
  connect(mapStatetoProps, {
    HolidayMasterList,
    holidayDataAdd,
    holidayDataUpdate,
    deleteHolidayList,
  })(HolidayCalendar)
);
