import React, { Component } from "react";
import { Grid, Step, Stepper, StepLabel } from "@mui/material";
import Link from "@mui/material/Link";
import CustomButton from "../../../components/Buttons/Buttons";
import BillsFillDetails from "./BillsFillDetails";
import BillsAddItems from "./BillsAddItems";
import BillsPreview from "./BillsPreview";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { addBill } from "../../../store/Accounting/Bills/actions";
import { serverApi } from "../../../helpers/Consts";

class CreateBills extends Component {
  constructor() {
    super();
    this.state = {
      steps: [
        {
          title: "Fill Details",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Add Items",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Preview",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive03.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
      ],
      confirm: false,
      success: false,
      message: "",
      currentStep: 1,
      date: null,
      billPass: {
        id: "",
        billDate: "",
        billNo: "",
        supplierCode: "",
        billAmount: "",
        type: "",
        docType: "",
        docNo: "",
        adjustmentType: "",
        taxId: "",
        taxPercentage: "",
        cTax: "",
        iTax: "",
        sTax: "",
        netAmount: "",
        invoiceNo: "",
        invoiceDate: "",
        invoiceAmount: "",
        invoiceRecievedDate: "",
        paymentDueDate: "",
        taxType: "",
        financialYear: "",
        createdBy: "",
        companyId: "",
        tdsPayable: "",
        tdsReason: "",
        tdsAmount: "",
        branchId: "",
        tcsPercentage: "",
        tcsAmount: "",
        roundOff: 0,
      },
      products: [
        {
          id: "0",
          no: 0,
          costCenter: "",
          description: "",
          uom: "",
          quantity: "",
          rate: 0,
          amount: 0,
          taxId: 0,
          cgstPercentage: 0,
          sgstPercentage: 0,
          igstPercentage: 0,
          cgstAmount: 0,
          sgstAmount: 0,
          igstAmount: 0,
        },
      ],
    };
  }

  onClickNext = () => {
    const { currentStep } = this.state;
    this.setState({
      billPass: {
        id: this.state.id,
        billDate: this.state.billDate,
        billNo: this.state.billNo,
        supplierCode: this.state.supplierCode,
        taxType: this.state.taxType,
        branchId: this.state.branchId,
        invoiceNo: this.state.invoiceNo,
        tdsPayable: this.state.tdsPayable,
        tcsPercentage: this.state.tcsPercentage,
        invoiceAmount: this.state.invoiceAmount,
        invoiceDate: this.state.invoiceDate,
        paymentDueDate: this.state.paymentDueDate,
        invoiceRecievedDate: this.state.invoiceRecievedDate,
        tcsAmount: this.state.tcsAmount,
        docType: "DOc",
        adjustmentType: "Adj",
        cTax: this.state.cTax,
        iTax: this.state.iTax,
        sTax: this.state.sTax,
        netAmount: this.state.netAmount,
        financialYear: "2022",
        createdBy: "support",
        companyId: "1",
        tdsReason: this.state.tdsReason,
        tdsAmount: this.state.tdsAmount,
        roundOff: 0.0,
      },
    });

    const { billNo } = this.state;

    if (billNo !== undefined) {
      this.setState({
        currentStep: currentStep + 1,
      });
      if (currentStep === 2) {
        const filteredProducts = this.state.products;
        filteredProducts.pop();
      }
    } else {
      this.setState({
        confirm: true,
      });
    }
  };

  onClickBefore = () => {
    const { currentStep } = this.state;
    if (currentStep > 1) {
      this.setState({
        currentStep: currentStep - 1,
      });
    } else {
      this.props.history.push("/accounting");
    }
  };

  QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;

    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "supplierCode") {
      this.setState({
        supplierCode: selectedValue,
      });
    }

    if (name === "taxType") {
      this.setState({
        taxType: selectedValue,
      });
    }

    if (name === "branchId") {
      this.setState({
        branchId: selectedValue,
      });
    }

    if (name === "tdsPayable") {
      this.setState({
        tdsPayable: selectedValue,
      });
    }
    if (name === "tcsPercentage") {
      this.setState({
        tcsPercentage: selectedValue,
      });
    }
  };

  onhandlechangeValue = (value, key) => {
    if (key === "billNo") {
      this.setState({
        billNo: value,
      });
    }

    if (key === "invoiceNo") {
      this.setState({
        invoiceNo: value,
      });
    }
    if (key === "invoiceAmount") {
      this.setState({
        invoiceAmount: value,
      });
    }

    if (key === "tcsAmount") {
      this.setState({
        tcsAmount: value,
      });
    }
    
  };

  handleSelectDate = (value, name) => {
    if (name === "billDate") {
      this.setState({
        billDate: value,
      });
    }
    if (name === "invoiceDate") {
      this.setState({
        invoiceDate: value,
      });
    }
    if (name === "invoiceRecievedDate") {
      this.setState({
        invoiceRecievedDate: value,
      });
    }
    if (name === "paymentDueDate") {
      this.setState({
        paymentDueDate: value,
      });
    }
  };

  onclickOk() {
    this.setState({
      confirm: false,
    });
   
  }

  onCreateBill = () => {
    const filteredProducts = this.state.products;
    filteredProducts.pop();
    var totalValue = filteredProducts
      .map((p) => p.amount)
      .reduce((total, ind) => total + ind);

    var data = {
      billPass: {
        id: "",
        billDate: this.state.billDate,
        billNo: Number(this.state.billNo),
        supplierCode: this.state.supplierCode,
        taxType: this.state.taxType,
        branchId: this.state.branchId,
        invoiceNo: this.state.invoiceNo,
        tdsPayable: this.state.tdsPayable,
        tcsPercentage: this.state.tcsPercentage,
        taxPercentage: this.state.taxPercentage,
        invoiceAmount: this.state.invoiceAmount,
        invoiceDate: this.state.invoiceDate,
        paymentDueDate: this.state.paymentDueDate,
        invoiceRecievedDate: this.state.invoiceRecievedDate,
        tcsAmount: this.state.tcsAmount,
        docType: "DOc",
        adjustmentType: "Adj",
        // cTax: this.state.cTax,
        cTax: 0,
        // iTax: this.state.iTax,
        iTax: 0,
        // sTax: this.state.sTax,
        sTax: 0,
        billAmount: totalValue,
        type: "O",
        docNo: "",
        taxId: "",
        netAmount: totalValue,
        financialYear: "2022",
        createdBy: "support",
        companyId: "1",
        // tdsReason: this.state.tdsReason,
        tdsReason: "",
        tdsAmount: this.state.tdsAmount,
        roundOff: 0.0,
      },
      billPassLineItem: filteredProducts.map((product) => ({
        id: parseFloat(product.id),
        qty: product.quantity,
        description: product.description,
        uom: product.uom,
        rate: product.rate,
        costCenterId: product.costCenter,
        amount: product.amount,
        taxId: product.taxId,
        createdBy: "2",
        companyId: "1",
        cgstPercentage: product.cgstPercentage,
        sgstPercentage: product.sgstPercentage,
        igstPercentage: product.igstPercentage,
        cgstAmount: 0,
        sgstAmount: 0,
        igstAmount: "2.88",
      })),
    };
    this.props.addBill(serverApi.ADD_BILLS, data, this.props.history);
  };

  render() {
    const { steps, currentStep } = this.state;

    return (
      <div>
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            <div className="iconsBlock">
              <Stepper alternativeLabel activeStep={currentStep}>
                {steps.map((prop) => (
                  <Step key={prop.title}>
                    <StepLabel
                      StepIconComponent={(Iconprops) => {
                        return this.QontoStepIcon(
                          Iconprops,
                          prop.icon,
                          prop.activeIcon,
                          prop.title
                        );
                      }}
                    ></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {currentStep === 1 && (
              <>
                <BillsFillDetails
                  onSelectDep={this.handleSelectChange}
                  onhandlechangeValue={this.onhandlechangeValue}
                  handleSelectDate={this.handleSelectDate}
                />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="greenButton"
                    handleClick={this.onClickNext}
                    type="sumbit"
                  />
                  {this.state.confirm && (
                    <CommonPopup>
                      <div className="delAddItem">
                        <div>Please Enter the mandatory Fields !</div>
                        <div className="delAddItemBtns">
                          <input
                            type="button"
                            onClick={this.onclickOk.bind(this)}
                            value="Ok!"
                            className="delYesBtn"
                          />
                        </div>
                      </div>
                    </CommonPopup>
                  )}
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                <BillsAddItems
                  billPass={this.state.billPass}
                  products={this.state.products}
                />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="greenButton"
                    handleClick={this.onClickNext}
                    type="sumbit"
                  />
                </div>
              </>
            )}
            {currentStep === 3 && (
              <>
                <BillsPreview />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="greenButton"
                    handleClick={this.onCreateBill}
                    type="sumbit"
                  />
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { createBills } = state.BillsReducer;
  return { createBills };
};

export default withRouter(connect(mapStatetoProps, { addBill })(CreateBills));
