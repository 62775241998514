import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import DynamicSelect from "../../Dropdown/DynamicSelect";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { serverApi } from "../../../helpers/Consts";
import TextFieldNormal from "../../TextField/TextFieldNormal";
import { getStatusList } from "../../../store/Global/DropDownApis/actions";

import { getCustomerList } from "../../../store/Global/DropDownApis/actions";
import {getSalesOrderBranch} from '../../../store/Sales/SalesOrder/actions'
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { getMukamList } from "../../../store/Global/DropDownApis/actions";
import moment from "moment";

function JuteIndentFilter(props) {
  const [open, setOpen] = useState(false);
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [companyId] = useState(localStorage.getItem("companyId"));
  const [mukamListData, setMukamListData] = useState([]);
  const [Status, setStatus] = useState([]);
  const [from_date, setFromDate] = useState("");
  const [to_date, setToDate] = useState("");
  const [status_id, setStatusId] = useState("");
  const [quotation_no, setQuotationNo] = useState("");
  const [mukam, setMukamName] = useState("");
  const[mukamValue,setmukamValue]=useState("");
  const [branchId,setbranchId]=useState("")
  const [isDropdownOpen,setisDropdownOpen]=useState(false)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "Status") {
      setStatusId(selectedValue);
    }
    if (name === "mukam") {
      console.log('skchjdchjd',selectedValue)
      setMukamName(selectedValue);
    }
    if (name === "MukamValue") {
        setmukamValue(selectedValue);
      }
    if(name==="branchId"){
        setbranchId(selectedValue)
    }
  };

  const onhandlechange = (key) => (event) => {
    if (key === "quotation_no") {
      setQuotationNo(event.target.value);
    }
  };

  const handleSelectDate = (e, name) => {
    if (name === "FromDate") {
      const date = moment(e).format();
      const Date = date.split("T", 1).toString();
      setFromDate(Date);
    }
    if (name === "ToDate") {
      const date = moment(e).format();
      const Date = date.split("T", 1).toString();
      setToDate(Date);
    }
  };

  const handleSubmit = () => {
    let from_Date = from_date;
    if (from_date !== "") {
      from_Date = moment(from_date, "YYYY-MM-DD", true).format("DD-MM-YYYY");
    }

    let to_Date = to_date;
    if (to_date !== "") {
      to_Date = moment(to_date, "YYYY-MM-DD", true).format("DD-MM-YYYY");
    }

    const data = {
      saleNo: quotation_no,
      status: status_id,
      mukam: mukam,
    //   mukamValue:mukamValue,
      branchId: branchId,
      cipher: userDit.cipher,
      companyId: companyId,
      erpType: "1",
      financialYear: localStorage.getItem("acadamicYear"),
      fromDate: from_Date,
      projectId: "",
      recordType: "SR",
      supplierId: null,
      toDate: to_Date,
    };
    props.handle_search(data);
    setOpen(false);
  };

  useEffect(() => {
    props.getStatusList(serverApi.STATUS_LISTS, props.history);
    const data = {
      companyId: companyId,
      cipher: userDit.cipher,
    };
    props.getMukamList(serverApi.MUKAM_GET_LIST + localStorage.getItem("companyId"),
    props.history);
  }, []);

  useEffect(() => {
    if (props.statusList) {
      setStatus(props.statusList.data);
    }
    if (props.mukamList) {
      console.log('sajdhjadsb',props.mukamList.data)
      setMukamListData(props.mukamList.data);
    }
  }, [props]);
  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  console.log('xhvcghcgh',mukam)
  return (
    <>
      <Box>
        <Button onClick={handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={open}
          PaperProps={{
            sx: {
              position: "fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid spacing={0}>
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
              </div>
            </div>
      
              <Grid item xs={12} className="filterBlockContainer">
                <Box gridColumn="span 4">
                  <div className="consoleTextFieldBlock">
                  <div>
                    <div className="TextFieldBlock">
                      <label>From Date</label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="dd-MM-yyyy"
                        name="FromDate"
                        value={from_date}
                        fullWidth
                        onChange={(e) => handleSelectDate(e, "FromDate")}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input ref={inputRef} {...inputProps} readOnly={true} />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                 
              </div>
                  <div className="consoleTextFieldBlock">
                  <div>
                    <div className="TextFieldBlock">
                      <label>To Date</label>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="dd-MM-yyyy"
                        name="ToDate"
                        value={to_date}
                        fullWidth
                        onChange={(e) => handleSelectDate(e, "ToDate")}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input ref={inputRef} {...inputProps} readOnly={true} />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                    </div>
                  </div>

                  </div>

                </Box>
              
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={
                        Status
                          ? Status.map((item) => ({
                              label: item.label,
                              value: item.value,
                              name: item.name,
                            }))
                          : ""
                      }
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handleSelectChange}
                      selected={status_id}
                      name="Status"
                      label="Status"
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={
                        mukamListData ?
                        mukamListData.map((item) => ({
                          label: item.name,
                          value: item.value,
                          name: item.name,
                        }))
                        :""
                      }
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handleSelectChange}
                      selected={mukam}
                      name="mukam"
                      label="Mukam"
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                    />
                  </div>
                </Box> 
                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Search"}
                    className="greenButton"
                    handleClick={handleSubmit}
                    type="sumbit"
                  />
                </div>
              </Grid>
            
          </Grid>
        </Dialog>
      </Box>
    </>
  );
}

const mapStatetoProps = (state) => {
  const { statusList, mukamList } = state.DropDownListReducer;
  const { BranchPropdata } = state.SalesOrderReducer;
  return { statusList, mukamList ,BranchPropdata};
};

export default withRouter(
  connect(mapStatetoProps, {
    getStatusList,
    getMukamList,
    getSalesOrderBranch
  })(JuteIndentFilter)
);
