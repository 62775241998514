
/*
	Created By Nagesh Medisetty On 20-01-2022
*/
import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi } from "../src/helpers/Consts";
import { getLeftSideMenu } from "../src/store/Company/BasicDetails/LeftSideBar/actions";
import { getAcadamicYears } from "../src/store/auth/FinancialYear/actions";
import { withRouter } from "react-router-dom";
// Import Routes    store/Company/BasicDetails/LeftSideBar/actions
import { authProtectedRoutes, publicRoutes, authProtectedRoutesWithOutInnerNavigation } from "./routes/";
import AppRoute from "./routes/route";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/css/style.scss";
import Layout from "./Pages/Layout";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = { header_title: 'Default Title' };
		this.getLayout = this.getLayout.bind(this);
		this.setTitle = this.setTitle.bind(this);
		this.broadcastChannel = new BroadcastChannel('session');

		const sessionId = sessionStorage.getItem('sessionId') || Math.random().toString(36).substring(2, 15);
		sessionStorage.setItem('sessionId', sessionId);
		if (window.performance && localStorage.getItem("companyId")) {
			if (performance.navigation.type == 1) {
				// alert("This page is reloaded" + localStorage.getItem("companyId"));
				this.props.getAcadamicYears(
					serverApi.GET_ACADEMIC_YEARS_LIST + localStorage.getItem("companyId"),
					this.props.history
				);
				this.props.getLeftSideMenu(
					serverApi.LEFT_SIDE_MENU + "0/" + localStorage.getItem("companyId")
				);
				this.props.history.push('/dashboard');
			} 
			
		}	
	}

	handleLogout = () => {
		localStorage.clear();
		this.props.history.push("/Logout");

		this.broadcastChannel.postMessage('logout');
	  };

	componentDidMount() {
		this.broadcastChannel.onmessage = (event) => {
		  if (event.data === 'logout') {
			this.handleLogout();
		  }
		};
	  }
	setTitle(newTitle) {
		this.setState({
			title: newTitle
		});
	}
	/**
   * Returns the layout
   */
	getLayout = () => {

	};

	render() {
		return (
			<React.Fragment>
				<Router>
					<Switch>
						{authProtectedRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={Layout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
							/>
						))}

						{authProtectedRoutesWithOutInnerNavigation.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
							/>
						))}

						{publicRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
							/>
						))}


					</Switch>
				</Router>
			</React.Fragment>
		);
	}
}



const mapStateToProps = state => {
	return {
		layout: state.Layout
	};
};


export default withRouter(
	connect(mapStateToProps, {
		getAcadamicYears,getLeftSideMenu
	})(App)
  );

