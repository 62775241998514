import {
  TRANSPORTER_MASTER_LIST,
  CREATE_TRANSPORTER_MASTER,
  UPDATE_TRANSPORTER_MASTER,
  NEW_TRANSPORT_MASTER_LIST,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { postAdd, getListurl } from "../../../helpers/Server_Helper";
import {
  transporterMasterListSuccessfull,
  createTransporterMasterSuccessfull,
  updateTransporterMasterSuccessfull,
  transporterMasterNewListApiSuccessfull,
} from "./actions";
import swal from "sweetalert";

function* TransporterMasterList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getListurl, url, data);
    yield put(transporterMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateTransporterMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createTransporterMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/transporter_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateTransporterMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateTransporterMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/transporter_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

function* newTransportMasterListSaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(transporterMasterNewListApiSuccessfull({ response }));
  } catch (error) {
    console.log(error);
    swal("error:" + error, { icon: "danger" });
  }
}

export function* watchTransporterMasterList() {
  yield takeEvery(TRANSPORTER_MASTER_LIST, TransporterMasterList);
}
export function* watchCreateTransporterMaster() {
  yield takeEvery(CREATE_TRANSPORTER_MASTER, CreateTransporterMaster);
}
export function* watchUpdateTransporterMaster() {
  yield takeEvery(UPDATE_TRANSPORTER_MASTER, UpdateTransporterMaster);
}

export function* watchNewTransporterMasterList() {
  yield takeEvery(NEW_TRANSPORT_MASTER_LIST, newTransportMasterListSaga);
}

function* TransporterSaga() {
  yield all([
    fork(watchTransporterMasterList),
    fork(watchCreateTransporterMaster),
    fork(watchUpdateTransporterMaster),
    fork(watchNewTransporterMasterList),
  ]);
}

export default TransporterSaga;
