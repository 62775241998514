import { DownloadOutlined } from "@mui/icons-material";
import {
  EMPLOYEE_LIST,
  EMPLOYEE_LIST_SUCCESSFULL,
  PERSONAL_DETAILS,
  PERSONAL_DETAILS_SUCCCESSFULL,
  EDUCATIONAL_DETAILS,
  EDUCATIONAL_DETAILS_SUCCESSFULL,
  DEPARTMENT_LIST_DATA,
  DEPARTMENT_LIST_DATA_SUCCESSFULL,
  CATEGORY_LIST,
  CATEGORY_LIST_SUCCESSFULL,
  EMP_LEVEL,
  EMP_LEVEL_SUCCESSFULL,
  REPORTING_MANAGER_LIST,
  REPORTING_MANAGER_LIST_SUCCESSFULL,
  CONTRACTOR,
  CONTRACTOR_SUCCESSFULL,
  WORK_LOCATION,
  WORK_LOCATION_SUCCESSFULL,
  DESIGNATION,
  DESIGNATION_SUCCESSFULL,
  LEAVE_POLICY_LIST,
  LEAVE_POLICY_LIST_SUCCESSFULL,
  LEAVE_LIST,
  LEAVE_LIST_SUCCESSFULL,
  PAY_SCHEME,
  PAY_SCHEME_SUCCESSFULL,
  EMPLOYEE_DETAILS_VIEW_BY_ID,
  EMPLOYEE_DETAILS_VIEW_BY_ID_SUCCESSFULL,
  EXPERIENCE_DETAILS,
  EXPERIENCE_DETAILS_SUCCESSFULL,
  PAY_SCHEME_BY_ID,
  PAY_SCHEME_BY_ID_SUCCESSFULL,
  CHECK_PAY_SCHEME,
  CHECK_PAY_SCHEME_SUCCESSFULL,
  LEAVE_POLICY_MAPPING,
  LEAVE_POLICY_MAPPING_SUCCESSFULL,
  GET_ALL_FILES_DATA,
  GET_ALL_FILES_SUCCESSFULL,
  DOWNLOAD_FILES,
  DOWNLOAD_FILES_SUCCESSFULL,
  UPDATE_FILE,
  UPDATE_FILE_SUCCESSFULL,
  DOWNLOAD_ASSET_FORM,
  DOWNLOAD_ASSET_FORM_SUCCESSFULL,
  OFFER_LETTER_EMAIL_SERVICE,
  OFFER_LETTER_EMAIL_SERVICE_SUCCESSFULL,
  EXPERIENCE_DETAILS_VIEW_BY_ID,
  EXPERIENCE_DETAILS_VIEW_BY_ID_SUCCESSFULL_,
  GET_PAYSCHEME_LIST,
  GET_PAYSCHEME_LIST_SUCCESSFULL,
  ADD_SALARY,
  ADD_SALARY_SUCCESSFULLY,
  DISPLAY_EMAIL_TEMPLATE,
  DISPLAY_EMAIL_TEMPLATE_SUCCESSFULLY,
  PAY_WAGES_MODES,
  PAY_WAGES_MODES_SUCCESSFULL,
  PAY_COMPONENTS,
  PAY_COMPONENTS_SUCCESSFULL,
  ADD_PAY_SCHEME,
  ADD_PAY_SCHEME_SUCCESSFULL,
  UPDATE_PAY_SCHEME,
  UPDATE_PAY_SCHEME_SUCCESSFULL,
  HRMS_DASHBOARD,
  HRMS_DASHBOARD_SUCCESSFULLY,
  PAYSCHEME_BY_ID,
  PAYSCHEME_BY_ID_SUCCESSFULL,
  APPROVAL_HIERARCHY,
  APPROVAL_HIERARCHY_SUCCESSFULLY ,
  UPDATE_SALARY,
  UPDATE_SALARY_SUCCESSFULLY,
  VIEW_BY_ID_APPROVAL,
  VIEW_BY_ID_APPROVAL_SUCCESSFULLY,
  UPDATE_LEAVE_POLICY,
  UPDATE_LEAVE_POLICY_SUCCESSFULL,
  WORKER_DETAILS_PROGRESS,
  WORKER_DETAILS_PROGRESS_SUCCESSFULLY,
  GET_WORKER_DETAILS_PROGRESS,
  GET_WORKER_DETAILS_PROGRESS_SUCCESSFULLY,
  GET_LIST_ROW_DATA,
  DELETE_WORKER_EDUCATIONAL_DETAILS,
  DELETE_WORKER_EDUCATIONAL_DETAILS_SUCCESSFULLY,
  UPDATE_WORKER_STATUS,
  UPDATE_WORKER_STATUS_SECCESSFULL,
  PROFILE_PIC_DETAILS,
  PROFILE_PIC_DETAILS_SUCCESSFULL,


  // new employee database apis actions 

  VIEW_BY_ID_PERSONAL_DETAILS,
  VIEW_BY_ID_PERSONAL_DETAILS_SUCCESSFULL,
  UPDATE_WORKER_DETAILS,
  UPDATE_WORKER_DETAILS_SUCCESSFULL,
  EMPLOYEE_PROGRESS_BAR_DATA,
  EMPLOYEE_PROGRESS_BAR_DATA_SUCCESSFULL,
  VIEW_BY_ID_SALARY_STRUCTURE,
  VIEW_BY_ID_SALARY_STRUCTURE_SUCCESSFULL,
 GET_EBID_SECCESSFULL,
 GET_PROGRESSBARDATA_SECCESSFULL,
 ESI_DETAILS,
 AADHAR_VALIDATION,
 AADHAR_VALIDATION_SUCCESSFULL,
 CLEAR_AADHAR_DATA,
 FILE_UPLOAD,
 FILE_UPLOAD_SUCCESSFULL,
 CLEAR_FILES,
 DELETE_FILES,
 DELETE_FILES_SUCCESSFULL,
 DELETE_PROFILE_PIC,
 DELETE_PROFILE_PIC_SUCCESSFULL,
 UPLOAD_PROFILE_PIC_SUCCESSFULL,
 UPLOAD_PROFILE_PIC,
 DELETE_MEDICAL_POLICY,
 DELETE_MEDICAL_POLICY_SUCCESSFULL,  
 VIEW_BY_ID_EMP_DATA,
 VIEW_BY_ID_LEAVE_POLICY,
 VIEW_BY_ID_LEAVE_POLICY_SUCCESSFULL,
 CLEAR_PAY_SCHEME,
 CLEAR_CHECK_SCHEME,
 GET_WORKER_DATA,
 GET_WORKER_DATA_SUCCESSFULL,
 CLEAR_PAY_SCHEME_BYID,
 DOWNLOAD_BULK_EMPLOYEE_TEMPLATE,
 DOWNLOAD_BULK_EMPLOYEE_TEMPLATE_SUCCESSFULL,
 CLEAR_PROFILE_PIC_DATA,
 CLEAR_ALL_FILES,
 CLEAR_EMPLOYEE_PROGRESS_BAR_DATA,
 ESI_DETAILS_SUCCESSFULL
} from "./actionType";

// Employee Data
export const getEmployeeList = (url, data, history) => {
  return {
    type: EMPLOYEE_LIST,
    payload: { url, data, history },
  };
};

export const employeeListSuccessfull = (data) => {
  return {
    type: EMPLOYEE_LIST_SUCCESSFULL,
    payload: data,
  };
};
// Personal Details
export const setPersonalDetails = (url, data,history, location) => {
  return {
    type: PERSONAL_DETAILS,
    payload: { url, data,history, location },
  };
};

export const PersonalDetailsSuccessfull = (data) => {
  return {
    type: PERSONAL_DETAILS_SUCCCESSFULL,
    payload: data,
  };
};




// View By Id
export const EmployeeDetailsViewById = (url, history) => {
  
  return {
    type: EMPLOYEE_DETAILS_VIEW_BY_ID,
    payload: { url, history },
  };
};

export const EmployeeDetailsViewByIdSuccessfull = (data) => {
  return {
    type: EMPLOYEE_DETAILS_VIEW_BY_ID_SUCCESSFULL,
    payload: data,
  };
};
// Educational Details
export const setEducationalList = (url, data, history,ebId) => {
  return {
    type: EDUCATIONAL_DETAILS,
    payload: { url, data, history,ebId },
  };
};
export const educationalListSuccessfull = (data) => {
  return {
    type: EDUCATIONAL_DETAILS_SUCCESSFULL,
    payload: data,
  };
};
// Experience details
export const setExperienceDetails = (url, data, history) => {
  return {
    type: EXPERIENCE_DETAILS,
    payload: { url, data, history },
  };
};
export const ExperienceDetailsSuccessfull = (data) => {
  return {
    type: EXPERIENCE_DETAILS_SUCCESSFULL,
    payload: data,
  };
};
// Experience details View By Id
export const ViewByIdExperienceDetails = (url, history) => {
  return {
    type: EXPERIENCE_DETAILS_VIEW_BY_ID,
    payload: { url, history },
  };
};
export const ViewByIdExperienceDetailsSuccessfull = (data) => {
  return {
    type: EXPERIENCE_DETAILS_VIEW_BY_ID_SUCCESSFULL_,
    payload: data,
  };
};
// Work Details

// Department
export const getDepartmentListData = (url, history) => {
  return {
    type: DEPARTMENT_LIST_DATA,
    payload: { url, history },
  };
};
export const departmentListDataSuccessfull = (data) => {
  return {
    type: DEPARTMENT_LIST_DATA_SUCCESSFULL,
    payload: data,
  };
};
// Category
export const getCategoryList = (url, history) => {
  return {
    type: CATEGORY_LIST,
    payload: { url, history },
  };
};
export const categoryListSuccessfull = (data) => {
  return {
    type: CATEGORY_LIST_SUCCESSFULL,
    payload: data,
  };
};
// Emp Level
export const getEmpLevel = (url, history) => {
  return {
    type: EMP_LEVEL,
    payload: { url, history },
  };
};
export const empLevelSuccessfull = (data) => {
  return {
    type: EMP_LEVEL_SUCCESSFULL,
    payload: data,
  };
};
// Reporting Manager
export const getReportingManagerList = (url, history) => {
  return {
    type: REPORTING_MANAGER_LIST,
    payload: { url, history },
  };
};
export const ReportingManagerListSuccessfull = (data) => {
  return {
    type: REPORTING_MANAGER_LIST_SUCCESSFULL,
    payload: data,
  };
};
// Contractor
export const getContractor = (url, history) => {
  return {
    type: CONTRACTOR,
    payload: { url, history },
  };
};
export const contractorSuccessfull = (data) => {
  return {
    type: CONTRACTOR_SUCCESSFULL,
    payload: data,
  };
};
// Work Location
export const getWorkLocation = (url, history) => {
  return {
    type: WORK_LOCATION,
    payload: { url, history },
  };
};
export const workLocationSuccessfull = (data) => {
  return {
    type: WORK_LOCATION_SUCCESSFULL,
    payload: data,
  };
};
// Designation
export const getDesignation = (url, history) => {
  return {
    type: DESIGNATION,
    payload: { url, history },
  };
};
export const designationSuccessfull = (data) => {
  return {
    type: DESIGNATION_SUCCESSFULL,
    payload: data,
  };
};

// Leave Policy
export const getLeavePolicyList = (url, history) => {
  return {
    type: LEAVE_POLICY_LIST,
    payload: { url, history },
  };
};
export const leavePolicySuccessfull = (data) => {
  return {
    type: LEAVE_POLICY_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getLeaveList = (url, history) => {
  return {
    type: LEAVE_LIST,
    payload: { url, history },
  };
};
export const leaveListSuccessfull = (data) => {
  return {
    type: LEAVE_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getPayScheme = (url, data, history) => {
  return {
    type: PAY_SCHEME,
    payload: { url, data, history },
  };
};
export const paySchemeSuccessfull = (data) => {
  return {
    type: PAY_SCHEME_SUCCESSFULL,
    payload: data,
  };
};
export const PaySchemeViewById = (url,history ) => {
  return {
    type: VIEW_BY_ID_SALARY_STRUCTURE,
    payload: { url,history },
  };
};

export const PaySchemeViewByIdSuccessfull = (data) => {
  return {
    type: VIEW_BY_ID_SALARY_STRUCTURE_SUCCESSFULL,
    payload: data,
  };
};

export const getPaySchemeById = (url, value, history) => {
  return {
    type: PAY_SCHEME_BY_ID,
    payload: { url, value, history },
  };
};

export const paySchemeIdSuccessfull = (data) => {
  return {
    type: PAY_SCHEME_BY_ID_SUCCESSFULL,
    payload: data,
  };
};

export const PaySchemeById = (url, value,viewByid, history) => {
  return {
    type: PAYSCHEME_BY_ID,
    payload: { url, value, viewByid,history },
  };
};
export const ClearPaySchemeById = (data) => {
  return {
    type: CLEAR_PAY_SCHEME_BYID,
    payload: data,
  };
};

export const paySchemeByIdSuccessfull = (data) => {
  return {
    type: PAYSCHEME_BY_ID_SUCCESSFULL,
    payload: data,
  };
};

export const checkPayScheme = (url, data, history) => {
  return {
    type: CHECK_PAY_SCHEME,
    payload: { url, data, history },
  };
};
export const checkPaySchemeSuccessfull = (data) => {
  return {
    type: CHECK_PAY_SCHEME_SUCCESSFULL,
    payload: data,
  };
};
export const AddPayScheme = (url, data, history) => {
  return {
    type: ADD_PAY_SCHEME,
    payload: { url, data, history },
  };
};
export const AddPaySchemeSuccessfull = (data) => {
  return {
    type: ADD_PAY_SCHEME_SUCCESSFULL,
    payload: data,
  };
};

export const UpdatePayScheme = (url, data, history) => {
  return {
    type: UPDATE_PAY_SCHEME,
    payload: { url, data, history },
  };
};
export const UpdatePaySchemeSuccessfull = (data) => {
  return {
    type: UPDATE_PAY_SCHEME_SUCCESSFULL,
    payload: data,
  };
};

export const PayComponents = (url, history) => {
  return {
    type: PAY_COMPONENTS,
    payload: { url, history },
  };
};
export const payComponentsSuccessfull = (data) => {
  return {
    type: PAY_COMPONENTS_SUCCESSFULL,
    payload: data,
  };
};

export const leavePolicyMapping = (url, data, history) => {
  return {
    type: LEAVE_POLICY_MAPPING,
    payload: { url, data, history },
  };
};
export const leavePolicyMappingSuccessfull = (data) => {
  return {
    type: LEAVE_POLICY_MAPPING_SUCCESSFULL,
    payload: data,
  };
};

export const UpdateleavePolicy = (url, data, history) => {
  return {
    type: UPDATE_LEAVE_POLICY,
    payload: { url, data, history },
  };
};
export const UpdateleavePolicySuccessfull = (data) => {
  return {
    type:UPDATE_LEAVE_POLICY_SUCCESSFULL,
    payload: data,
  };
};
export const getAllFiles = (url, history, type) => {
  return {
    type: GET_ALL_FILES_DATA,
    payload: { url, history, type},
  };
};
export const AllFilesSuccessfull = (data) => {
  return {
    type: GET_ALL_FILES_SUCCESSFULL,
    payload: data,
  };
};
export const ClearFilesData = (data) => {
  return {
    type: CLEAR_ALL_FILES,
    payload: data,
  };
};
export const ProfilePicFiles = (url, history, type) => {
  return {
    type: PROFILE_PIC_DETAILS,
    payload: { url, history, type},
  };
};
export const ProfilePicFilesSuccessfull = (data) => {
  return {
    type: PROFILE_PIC_DETAILS_SUCCESSFULL,
    payload: data,
  };
};
export const downLoadFiles = (url, history) => {
  return {
    type: DOWNLOAD_FILES,
    payload: { url, history },
  };
};
export const DownLoadFilesSuccessfull = (data) => {
  return {
    type: DOWNLOAD_FILES_SUCCESSFULL,
    payload: data,
  };
};
export const updateFiles = (url, data, history, fileurl) => {
  return {
    type: UPDATE_FILE,
    payload: { url, data, history, fileurl },
  };
};
export const updateFilesSuccessfull = (data) => {
  return {
    type: UPDATE_FILE_SUCCESSFULL,
    payload: data,
  };
};

export const downloadAssetForm = (url, history) => {
  return {
    type: DOWNLOAD_ASSET_FORM,
    payload: { url, history },
  };
};
export const downloadAssetFormsuccessfull = (data) => {
  return {
    type: DOWNLOAD_ASSET_FORM_SUCCESSFULL,
    payload: data,
  };
};
export const offerLetterEmail = (url, data, history) => {
  return {
    type: OFFER_LETTER_EMAIL_SERVICE,
    payload: { url, data, history },
  };
};
export const offerLetterEmailSuccessfull = (data) => {
  return {
    type: OFFER_LETTER_EMAIL_SERVICE_SUCCESSFULL,
    payload: { data },
  };
};

export const getPayschemeList = (url, data, history) => {
  
  return {
    type: GET_PAYSCHEME_LIST,
    payload: { url, data, history },
  };
};

export const getPayschemeListSuccessfull = (data) => {
  return {
    type: GET_PAYSCHEME_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const addSalary = (url, data ,history) => {
  return {
    type: ADD_SALARY,
    payload: { url, data ,history },
  };
};
export const addSalarySuccessfull = (data) => {
  return {
    type: ADD_SALARY_SUCCESSFULLY,
    payload: data,
  };
};
export const updateSalary = (url, data,history) => {
  return {
    type: UPDATE_SALARY,
    payload: { url, data ,history },
  };
};
export const updateSalarySuccessfull = (data) => {
  return {
    type: UPDATE_SALARY_SUCCESSFULLY,
    payload: data,
  };
};
export const displayEmailTemplate = (url, history) => {
  return {
    type: DISPLAY_EMAIL_TEMPLATE,
    payload: { url, history },
  };
};
export const displayEmailTemplateSuccessfull = (data) => {
  return {
    type: DISPLAY_EMAIL_TEMPLATE_SUCCESSFULLY,
    payload: data,
  };
};

export const payWagesModes = (url, history) => {
  return {
    type: PAY_WAGES_MODES,
    payload: { url, history },
  };
};
export const payWagesModesSuccessfull = (data) => {
  return {
    type: PAY_WAGES_MODES_SUCCESSFULL,
    payload: data,
  };
};




// Dashboard
export const hrmsDashboard = (url, data, history) => {
  return {
    type: HRMS_DASHBOARD,
    payload: { url, data, history },
  };
};
export const hrmsDashboardSuccessfull = (data) => {
  return {
    type: HRMS_DASHBOARD_SUCCESSFULLY,
    payload: data,
  };
};

// approval hierarchy
export const ApprovalHierarchy = (url, history) => {
  return {
    type: APPROVAL_HIERARCHY,
    payload: { url, history },
  };
};
export const ApprovalHierarchySuccessfull = (data) => {
  return {
    type: APPROVAL_HIERARCHY_SUCCESSFULLY ,
    payload: data,
  };
};

export const ViewByIdApproval = (url, history) => {
  return {
    type: VIEW_BY_ID_APPROVAL,
    payload: { url, history },
  };
};
export const ViewByIdApprovalSuccessfull = (data) => {
  return {
    type: VIEW_BY_ID_APPROVAL_SUCCESSFULLY ,
    payload: data,
  };
};

export const WorkerDetailsProgress = (url, data,history, redirect=null) => {
  return {
    type: WORKER_DETAILS_PROGRESS,
    payload: { url,data, history, redirect},
  };
};
export const WorkerDetailsProgressSuccessfull = (data) => {
  return {
    type: WORKER_DETAILS_PROGRESS_SUCCESSFULLY,
    payload: data,
  };
};

export const GetWorkerDetailsProgress = (url,history) => {
  return {
    type: GET_WORKER_DETAILS_PROGRESS,
    payload: { url, history },
  };
};
export const GetWorkerDetailsProgressSuccessfull = (data) => {
  return {
    type: GET_WORKER_DETAILS_PROGRESS_SUCCESSFULLY,
    payload: data,
  };
};

export const getListRowData = (data) => {
  return {
    type: GET_LIST_ROW_DATA,
    payload: data,
  };
};
export const clearPayScheme = (data) => {
  return {
    type: CLEAR_PAY_SCHEME,
    payload: data,
  };
};
export const clearCheckScheme = (data) => {
  return {
    type: CLEAR_CHECK_SCHEME,
    payload: data,
  };
};
export const deleteEducationalDetails = (url,history,sourceMenu,ebId) => {
  return {
    type: DELETE_WORKER_EDUCATIONAL_DETAILS,
    payload: { url, history ,sourceMenu,ebId },
  }
};

export const deleteEducationalDetailsSuccessfull = (data) => {
  return {
    type:DELETE_WORKER_EDUCATIONAL_DETAILS_SUCCESSFULLY,
    payload: data,
  };
};

export const UploadProfilePic = (url,data,history,ebId) => {
  return {
    type: UPLOAD_PROFILE_PIC,
    payload: { url, data,history,ebId},
  }
};

export const UploadProfilePicSuccessfull = (data) => {
  return {
    type:UPLOAD_PROFILE_PIC_SUCCESSFULL,
    payload: data,
  };
};
export const deleteProfilePic = (url,history,ebId,sourceMenu) => {
  return {
    type: DELETE_PROFILE_PIC,
    payload: { url, history,ebId,sourceMenu },
  }
};

export const deleteProfilePicSuccessfull = (data) => {
  return {
    type:DELETE_PROFILE_PIC_SUCCESSFULL,
    payload: data,
  };
};

export const deleteFilesData = (url,history,ebId,sourceMenu) => {
  return {
    type: DELETE_FILES,
    payload: { url, history,ebId,sourceMenu },
  }
};

export const deleteFilesDataSuccessfull = (data) => {
  return {
    type:DELETE_FILES_SUCCESSFULL,
    payload: data,
  };
};

export const updateWorkerStatus = (url,data,history) => {
  return {
    type:UPDATE_WORKER_STATUS,
    payload: { url, data, history },
  };
};

export const updateWorkerStatusSuccessfull = (data) => {
  return {
    type:UPDATE_WORKER_STATUS_SECCESSFULL,
    payload: data,
  };
};

export const deleteMedicalPolicyFile = (url,history,fileUploadId) => {
  return {
    type:DELETE_MEDICAL_POLICY,
    payload: { url, history,fileUploadId },
  };
};

export const deleteMedicalPolicyFileSuccessfull = (data) => {
  return {
    type:DELETE_MEDICAL_POLICY_SUCCESSFULL,
    payload: data,
  };
};

/********************************************** Employee Database new apis actions ***************************************************/

export const viewbyidPersonalDetails = (url, history, type) => {
  return {
    type: VIEW_BY_ID_PERSONAL_DETAILS,
    payload: { url, history, type},
  };
};

export const viewbyidPersonalDetailsSuccessfull = (data) => {
  return {
    type:VIEW_BY_ID_PERSONAL_DETAILS_SUCCESSFULL,
    payload: data,
  };
};
export const updateWorkerDetails = (url, data ,history, type,ebId) => {
  return {
    type: UPDATE_WORKER_DETAILS,
    payload: { url, data ,history, type,ebId },
  };
};

export const updateWorkerDetailsSuccessfull = (data) => {
  return {
    type: UPDATE_WORKER_DETAILS_SUCCESSFULL,
    payload: data,
  };
};

export const getEmployeeProgressBar = (url, data ,callback) => {
  return {
    type: EMPLOYEE_PROGRESS_BAR_DATA,
    payload: { url, data ,callback },
  };
};

export const getEmployeeProgressBarSuccessfull = (data) => {
  return {
    type: EMPLOYEE_PROGRESS_BAR_DATA_SUCCESSFULL,
    payload: data,
  };
};
export const ClearEmployeeProgressBar = (data) => {
  return {
    type: CLEAR_EMPLOYEE_PROGRESS_BAR_DATA,
    payload: data,
  };
};




export const getEBIdSuccessfull = (data) => {
  return {
    type: GET_EBID_SECCESSFULL,
    payload: data,
  };
};
export const getViewByIdEmpData = (data) => {
  return {
    type: VIEW_BY_ID_EMP_DATA,
    payload: data,
  };
};

export const setUpdateEsiDetails = (url, data,history,ebId) => {
  return {
    type: ESI_DETAILS,
    payload: { url, data,history,ebId },
  };
};
export const setUpdateEsiDetailsSuccessfull = (data) => {
  return {
    type: ESI_DETAILS_SUCCESSFULL,
    payload: data,
  };
};
export const AadharValidation = (url, data,callback) => {
  return {
    type: AADHAR_VALIDATION,
    payload: { url, data,callback },
  };
};

export const AadharValidationSuccessfull = (data) => {
  return {
    type: AADHAR_VALIDATION_SUCCESSFULL,
    payload: data,
  };
};

export const clearAdharDataProp = (data) => {
  return {
    type: CLEAR_AADHAR_DATA,
    payload: data,
  };
};
export const setFileUpload = (url, data,history,sourceMenu,ebId) => {
  return {
    type: FILE_UPLOAD,
    payload: { url, data,history,sourceMenu,ebId },
 
  };
};

export const setFileUploadSuccessfull = (data) => {
  return {
    type: FILE_UPLOAD_SUCCESSFULL,
    payload: data,
  };
};

export const SetClearFiles = (data) => {
  return {
    type: CLEAR_FILES,
    payload: data,
  };
};
export const getViewByIdLeavePolicy = (url,history) => {
  return {
    type: VIEW_BY_ID_LEAVE_POLICY,
    payload: { url,history},
 
  };
};

export const getViewByIdLeavePolicySuccessfull = (data) => {
  return {
    type: VIEW_BY_ID_LEAVE_POLICY_SUCCESSFULL,
    payload: data,
  };
};

export const getWorkerData = (url,history) => {
  return {
    type: GET_WORKER_DATA,
    payload: { url,history},
 
  };
};

export const getWorkerDataSuccessfull = (data) => {
  return {
    type: GET_WORKER_DATA_SUCCESSFULL,
    payload: data,
  };
};

export const clearProfileData = (data) => {
  return {
    type: CLEAR_PROFILE_PIC_DATA,
    payload: data,
  };
};

export const getDownloadBulkEmployeeTemplate = (url,history) => {
  return {
    type: DOWNLOAD_BULK_EMPLOYEE_TEMPLATE,
    payload: { url,history},
 
  };
};

export const getDownloadBulkEmployeeTemplateSuccessfull = (data) => {
  return {
    type: DOWNLOAD_BULK_EMPLOYEE_TEMPLATE_SUCCESSFULL,
    payload: data,
  };
};