import React, { Component } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import VowLogo from "../../assets/images/vow_logo.png";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import swal from "sweetalert";
import "font-awesome/css/font-awesome.min.css";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
// actions
import { checkLogin, apiError, checkToken } from "../../store/actions";

import { serverApi, showSpinner, hideSpinner } from "../../helpers/Consts";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { username: "su", password: "123456", loading: false };
    this.onHandleLogin = this.onHandleLogin.bind(this);
  }
  // http://devapi.vowerp.com/security-api/oauth/token

  onHandleLogin = (event) => {
    showSpinner();
    event.preventDefault();
    this.setState({ loading: true });
    this.props.apiError("");
    let username = event.target.email.value;
    let password = event.target.password.value;
    let reqSource = 0;

    const data = {
      username,
      password,
      reqSource,
    };

    this.setState({
      username: username,
      password: password,
    });
    /* Authentication Token generations */
    this.props.checkToken(serverApi.OAUTH_LOGIN, data, this.props.history);

    /* Login  */
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.loginError) {
      this.setState({ loading: false });
    } else {
    }

    if (nextProps.acadamicYearsList) {
      if (nextProps.acadamicYearsList.data) {
        localStorage.setItem(
          "acadamicYearsList",
          JSON.stringify(nextProps.acadamicYearsList.data)
        );
      }
    }
    hideSpinner();
  }

  componentDidMount() {
    this.props.apiError("");

    if (localStorage.getItem("authUser")) {
      this.props.history.push("/dashboard");
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          sx={{ height: "100vh" }}
          className="loginPatternOne"
        >
          <CssBaseline />
          <Grid item xs={false} sm={4} md={6} className="loginBackground">
            <Box className="loginLeftContent">
              <h2>Nice to meet you</h2>
              <h1>WELCOME</h1>
              <p>
                We have customized this product according to your needs and
                can't wait to see you using this product. This will simplify
                working of your business
              </p>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            component={Paper}
            elevation={6}
            square
            className="loginContainer"
          >
            <Box
              className="loginBlock"
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={VowLogo} alt="" />
              <Typography component="h1">Login</Typography>
              <Typography component="p">
                Welcome to VOW ERP Solutions. Start your journey with simple
                email ID login.
              </Typography>
              <form onSubmit={this.onHandleLogin}>
                <Box noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    className="loginInput"
                    placeholder="Email ID"
                    validate={{ required: true }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    className="loginInput"
                    placeholder="Password"
                  />
                  <Grid container className="keepSignedAndChechbox">
                    <Grid item xs>
                      <FormControlLabel
                        control={<Checkbox value="remember" />}
                        label="Keep me signed in"
                        className="keepSigned"
                      />
                    </Grid>
                    <Grid item>
                      <Link
                        to="/forgotPassword"
                        variant="body2"
                        className="alreadyMember"
                      >
                        {"Forgot Password?"}
                      </Link>
                    </Grid>
                  </Grid>

                  <button
                    className="loginButton"
                    color="primary"
                    type="submit"
                    disabled={loading}
                  >
                    {loading && (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    {loading && <span>Loading Data from Server...</span>}
                    {!loading && <span>Log In</span>}
                  </button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}
const theme = createTheme();
const mapStatetoProps = (state) => {
  const { loginError, resp } = state.Login;
  const { acadamicYearsList } = state.acadamicYearsList;
  return { loginError, resp, acadamicYearsList };
};

export default withRouter(
  connect(mapStatetoProps, { checkLogin, apiError, checkToken })(Login)
);
