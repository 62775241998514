import {ADVANCE_REQUEST_LIST,ADVANCE_REQUEST_LIST_SUCCESSFULL,ADVANCE_REQUEST_VIEW_BY_ID,ADVANCE_REQUEST_VIEW_BY_ID_SUCCESSFULL,CREATE_ADVANCE_REQUEST, GET_WORKER_DATA, GET_WORKER_DATA_SUCCESSFULL, WORKER_DATA_CLEAR} from './actionType'

export const getAdvanceRequestList = (url,history) =>{
    return {
        type:ADVANCE_REQUEST_LIST,
        payload:{url,history}
    }
}

export const AdvanceRequestListSuccessfull = (data)=>{
    return {
        type:ADVANCE_REQUEST_LIST_SUCCESSFULL,
        payload:data
    }
}
export const setAdvanceRequest = (url,data,history) =>{
    return {
        type:CREATE_ADVANCE_REQUEST,
        payload:{url,data,history}
    }
}

export const AdvanceRequestSuccessfull = (data)=>{
    return {
        type:ADVANCE_REQUEST_LIST_SUCCESSFULL,
        payload:data
    }
}
export const AdvanceRequestViewById = (url,data,history) =>{
    return {
        type:ADVANCE_REQUEST_VIEW_BY_ID,
        payload:{url,data,history}
    }
}

export const AdvanceRequestViewByIdSuccessfull = (data)=>{
    return {
        type:ADVANCE_REQUEST_VIEW_BY_ID_SUCCESSFULL,
        payload:data
    }
}

export const getWorkerDataByEbNO = (url,history) =>{
    return {
        type:GET_WORKER_DATA,
        payload:{url,history}
    }
}

export const getWorkerDataByEbNOSuccessfull = (data)=>{
    return {
        type:GET_WORKER_DATA_SUCCESSFULL,
        payload:data
    }
}


export const ClearWokerDataForAdvanceRequest = (data)=>{
    return {
        type:WORKER_DATA_CLEAR,
        payload:data
    }
}