import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PrintIcon from "@mui/icons-material/Print";
import CustomButton from "../../../components/Buttons/Buttons";
import EditIcon from "@mui/icons-material/Edit";
import { serverApi, getTenantId } from "../../../helpers/Consts";
import moment from "moment";

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      address1: "",
      address2: "",
      companyName: "",
      comName: "",
      phoneNo: "",
      email: "",
      products: [],
      currentDate: new Date().toLocaleString(),
      companyLogo: "",
      currentStep: 1,
      statusName: "",
      locationState: "",
      storeIssueNo: "",
    };
  }

  handleEdit = () => {
    this.props.handleCurrentStep(this.state.currentStep);
  };

  handlePdf = () => {
    const data = {
      hdrId: this.props.location.state.rowData.hdrId,
      companyId: localStorage.getItem("companyId"),
      finnacialYear: localStorage.getItem("acadamicYear"),
      userId: JSON.parse(localStorage.getItem("authUser")).userId,
    };
    var headers = {
      Authorization:
        localStorage.getItem("token_type") +
        localStorage.getItem("access_token"),

      "X-TenantId": getTenantId(window.location.hostname),
      Accept: "application/json",
      "Content-Type": "application/json",
      CompanyID: 1,
    };
    fetch(serverApi.PRINT_STORE_ISSUE, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        var file_name = "StoreIssueNo" + "_" + this.state.storeIssueNo + ".pdf";
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_name);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  componentWillReceiveProps() {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData) {
        this.setState({
          statusName: this.props.location.state.rowData.status,
        });
      }
    }

    if (this.props.storeIssueLineItem) {
      this.setState({
        products: this.props.storeIssueLineItem,
      });
    }

    if (this.props.storeIssueLineItem) {
      if (this.props.storeIssueLineItem[0]) {
        this.setState({
          storeIssueNo: this.props.storeIssueLineItem[0].hdrId,
        });
      }
    }
  }

  componentDidMount() {
    if (this.props.location) {
      this.setState({
        locationState: this.props.location,
      });
    }
    if (this.props.storeIssueLineItem) {
      this.setState({
        products: this.props.storeIssueLineItem,
      });
    }
  }

  render() {
    var companyData = JSON.parse(localStorage.getItem("authUser"));
    var companyId = localStorage.getItem("companyId");
    companyData.companyDetails.map((p) => {
      var id = p.value;
      if (companyId === id.toString()) {
        this.state.companyName = p.label;
        this.state.address1 = p.address1;
        this.state.address2 = p.address2;
        this.state.comName = p.name;
        this.state.phoneNo = p.contactNo;
        this.state.email = p.emailId;
        this.state.companyLogo = p.companyLogo;
      }
    });

    var filterData = this.state.products.filter((item) => {
      if (
        item.deptId !== 0 &&
        item.deleteStatus !== true &&
        item.isActive !== 0
      ) {
        return item;
      }
    });

    return (
      <>
        <div className="previewBlock">
          <div
            style={{ display: "flex", justifyContent: "end", padding: "10px" }}
          >
            {this.state.locationState.state !== undefined ? (
              <>
                <CustomButton
                  label="Print"
                  className="lightBlueButton"
                  muIcon={<PrintIcon />}
                  type="button"
                  handleClick={this.handlePdf}
                />
                {this.state.statusName === "APPROVED" ||
                this.state.statusName === "REJECTED" ||
                this.state.statusName === "CLOSED" ||
                this.state.statusName === "CANCELLED" ? (
                  ""
                ) : (
                  <>
                    <CustomButton
                      label="Edit"
                      className="lightBlueButton"
                      muIcon={<EditIcon />}
                      handleClick={this.handleEdit}
                      type="button"
                    />
                  </>
                )}
              </>
            ) : (
              ""
            )}
            {/* {this.props.location.state ? (
              <CustomButton
                label="Print"
                handleClick={this.handlePdf}
                type="button"
                className="lightBlueButton"
                muIcon={<PrintIcon />}
              />
            ) : (
              ""
            )} */}
          </div>
          <table
            cellpadding="0"
            cellspacing="0"
            width="90%"
            className="previewTable"
          >
            <tbody>
              <tr>
                <td
                  colspan="2"
                  style={{ borderRight: "none", borderBottom: "none" }}
                >
                  <img src={this.state.companyLogo} width="100%" alt="" />
                </td>
                <td
                  colspan="7"
                  style={{
                    borderBottom: "none",
                    borderLeft: "none",
                    textAlign: "right",
                  }}
                >
                  <h2>{this.state.companyName}</h2>
                  {this.state.address1}
                  <br></br>
                  {this.state.address2}
                  <br></br>
                  Phone No:- {this.state.phoneNo}
                  <br></br>
                  Email Address:- {this.state.email}
                  <br></br>
                </td>
              </tr>
              <tr>
                <td
                  colspan="9"
                  style={{ textAlign: "center", borderTop: "none" }}
                >
                  <h2>STORE ISSUE</h2>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>ISSUE NO :</b>
                </td>
                <td colspan="3">
                  <b>
                    {this.props.storeIssueLineItem
                      ? this.props.storeIssueLineItem[0]
                        ? this.props.storeIssueLineItem[0].hdrId
                          ? this.props.storeIssueLineItem[0].hdrId
                          : "After Creation Issue will Be Generated"
                        : ""
                      : ""}
                  </b>
                </td>

                <td colSpan="2">
                  <b>ISSUE DATE : </b>
                </td>
                <td colSpan="3">
                  <b>
                    {this.props.storeIssueLineItem
                      ? this.props.storeIssueLineItem[0]
                        ? this.props.storeIssueLineItem[0].issueDate
                          ? moment(
                              this.props.storeIssueLineItem[0].issueDate
                            ).format("DD-MM-YYYY")
                          : moment(this.props.storeHeaderDataList.date).format(
                              "DD-MM-YYYY"
                            )
                        : ""
                      : ""}
                  </b>
                </td>
              </tr>

              <tr>
                <td colspan="2">
                  <b>Expense Type:</b>
                </td>
                <td colspan="3">
                  <b>
                    {this.props.storeIssueLineItem
                      ? this.props.storeIssueLineItem[0]
                        ? this.props.storeIssueLineItem[0].expense_name
                          ? this.props.storeIssueLineItem[0].expense_name
                          : this.props.storeHeaderDataList.expense_name
                        : ""
                      : ""}
                  </b>
                </td>
              </tr>

              <tr>
                <td width="5%">
                  <b>ITEM CODE</b>
                </td>
                <td width="15%">
                  <b>DEPARTMENT</b>
                </td>
                <td width="10%">
                  <b>ITEM GROUP</b>
                </td>
                <td width="20%">
                  <b>ITEM DESCRIPTION</b>{" "}
                </td>
                <td width="10%">
                  <b>MACHINE NO</b>
                </td>
                <td width="10%">
                  <b>QUANTITY</b>
                </td>
                <td width="10%">
                  <b>UNIT</b>
                </td>
                <td width="10%">
                  <b>ISSUE VALUE</b>
                </td>
                <td width="10%">
                  <b>COST FACTOR</b>
                </td>
              </tr>
              {filterData.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{data.itemGroupId + data.itemCode}</td>
                    <td>{data.departmentName}</td>
                    <td>{data.itemGroupName}</td>
                    <td>{data.itemName}</td>
                    <td>{data.machineName}</td>
                    <td>{parseFloat(data.quantity).toFixed(3)}</td>
                    <td>{data.uom}</td>
                    <td>{parseFloat(data.issueValue).toFixed(2)}</td>
                    <td>{data.costFactorName}</td>
                  </tr>
                );
              })}

              <tr>
                <td
                  colspan="2"
                  align="left"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  <b>Status: {this.state.statusName} </b>
                </td>

                <td
                  colspan="10"
                  align="right"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    borderLeft: "none",
                  }}
                >
                  <h4> For {this.state.companyName}</h4>
                </td>
              </tr>

              <tr>
                <td
                  colspan="10"
                  align="right"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    borderLeft: "none",
                  }}
                >
                  <b>Authorised Signature</b>
                </td>
              </tr>

              <tr>
                <td
                  colspan="9"
                  style={{ borderTop: "none", borderBottom: "none" }}
                >
                  <b>Note* :</b>
                  <br />
                  This is a computer generated print, Signature is not required.
                </td>
              </tr>
              <tr>
                <td
                  colspan="5"
                  style={{ borderTop: "none", borderRight: "none" }}
                >
                  Printed on: {this.state.currentDate}
                </td>
                <td
                  colspan="4"
                  align="right"
                  style={{ borderTop: "none", borderLeft: "none" }}
                >
                  Page 1 of 2<br />
                  Printed By: 2
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { storeIssueLineItem, storeHeaderDataList, storeIssueViewByIdList } =
    state.Material_Request_List;

  return { storeIssueLineItem, storeHeaderDataList, storeIssueViewByIdList };
};

export default withRouter(connect(mapStatetoProps, {})(Preview));
