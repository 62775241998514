//import { Switch } from "react-router-dom";
import {
  API_ERRORS,
  CLEAR_REGULAR_INVOICE_HEADER,
  CLEAR_REGULAR_INVOICE_LINE_ITEMS,
  CREATE_REGULAR_INVOICE,
  CREATE_REGULAR_INVOICE_SUCCESSFULL,
  INVOICE_LIST,
  INVOICE_LIST_SUCCESSFULL,
  JUTE_GOVT_INVOICE_HEADER,
  MATERIAL_DETAILS_BY_ID,
  MATERIAL_DETAILS_BY_ID_SUCCESSFULL,
  REGULAR_INVOICE_HEADER,
  REGULAR_INVOICE_LINE_ITEMS,
  REGULAR_SALES_ORDER_VIEW_BYID_FOR_INVOICE,
  REGULAR_SALES_ORDER_VIEW_BYID_FOR_INVOICE_SUCCESSFULL,
  REGULAR_DELIVERY_ORDER_VIEW_BYID_FOR_INVOICE,
  REGULAR_DELIVERY_ORDER_VIEW_BYID_FOR_INVOICE_SUCCESSFULL,
  REGULAR_INVOICE_VIEW_BY_ID,
  REGULAR_INVOICE_VIEW_BY_ID_SUCCESSFULL,
  UPDATE_REGULAR_INVOICE,
  UPDATE_REGULAR_INVOICE_SUCCESSFULL,
  REGULAR_INVOICE_ITEMS_BY_ITEM_GROUP_ID,
  REGULAR_INVOICE_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL,
  SALES_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE,
  SALES_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE_SUCCESSFULL,
  DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE,
  DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE_SUCCESSFULL,
  JUTE_GOVT_INVOICE_LINE_ITEMS,
  CLEAR_JUTE_GOVT_INVOICE_HEADER,
  CLEAR_JUTE_GOVT_INVOICE_LINE_ITEMS,
  JUTE_GOVT_INVOICE_ITEMS_BY_ITEM_GROUP_ID,
  JUTE_GOVT_INVOICE_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL,
  CREATE_JUTE_GOVT_INVOICE,
  CREATE_JUTE_GOVT_INVOICE_SUCCESSFULL,
  JUTE_GOVT_INVOICE_VIEW_BY_ID,
  JUTE_GOVT_INVOICE_VIEW_BY_ID_SUCCESSFULL,
  UPDATE_JUTE_GOVT_INVOICE,
  UPDATE_JUTE_GOVT_INVOICE_SUCCESSFULL,
  GET_VEHICLE_TYPE_SUCCESSFULL,
  GET_VEHICLE_TYPE,
  SALES_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE,
  SALES_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE_SUCCESSFULL,
  JUTE_HESSIAN_INVOICE_HEADER,
  CLEAR_JUTE_HESSIAN_INVOICE_HEADER,
  CLEAR_JUTE_HESSIAN_INVOICE_LINE_ITEMS,
  JUTE_HESSIAN_INVOICE_LINE_ITEMS,
  DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE,
  DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE_SUCCESSFULL,
  CREATE_JUTE_HESSIAN_INVOICE,
  CREATE_JUTE_HESSIAN_INVOICE_SUCCESSFULL,
  JUTE_HESSIAN_INVOICE_VIEW_BY_ID,
  JUTE_HESSIAN_INVOICE_VIEW_BY_ID_SUCCESSFULL,
  JUTE_YARN_INVOICE_HEADER,
  JUTE_YARN_INVOICE_LINE_ITEMS,
  SALES_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE,
  SALES_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE_SUCCESSFULL,
  DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE,
  DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE_SUCCESSFULL,
  DELETE_INVOICE_FILE,
  DELETE_INVOICE_FILE_SUCCESSFULL,
  UPLOAD_INVOICE_FILES,
  UPLOAD_INVOICE_FILES_SUCCESSFULL,
  GET_INVOICE_FILES,
  INVOICE_FILES_SUCCESSFULL,
  CREATE_JUTE_YARN_INVOICE,
  CREATE_JUTE_YARN_INVOICE_SUCCESSFULL,
  CLEAR_JUTE_YARN_INVOICE_HEADER,
  CLEAR_JUTE_YARN_INVOICE_LINE_ITEMS,
  INVOICE_JUTE_YARN_GET_UOM_RELATION_VALUE,
  INVOICE_JUTE_YARN_GET_UOM_RELATION_VALUE_SUCCESSFULL,
  INVOICE_JUTE_YARN_VIEW_BY_ID,
  INVOICE_JUTE_YARN_VIEW_BY_ID_SUCCESSFULL,
  UPDATE_JUTE_YARN_INVOICE,
  UPDATE_JUTE_YARN_INVOICE_SUCCESSFULL,
  GENERATE_E_INVOICE,
  GENERATE_E_INVOICE_SUCCESSFULL,
  CLEAR_E_INVOICE_MESSAGE,
  GENERATE_E_WAY_BILL,
  GENERATE_E_WAY_BILL_SUCCESSFULL,
  INVOICE_JUTE_YARN_UOM_LIST_SUCCESSFULL,
  INVOICE_JUTE_YARN_UOM_LIST,
  REGULAR_INVOICE_ITEMS_DETAILS_BY_ITEM_ID_SUCCESSFULL,
  REGULAR_INVOICE_ITEMS_DETAILS_BY_ITEM_ID,
  CLEAR_E_WAY_BILL_MESSAGE
} from "./actionTypes";
import moment from "moment";
const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  Invoice_List: [],
  error_msg: null,
  createRegularInvoiceRecord: [],
  materialDetails: [],
  inVoiceLineitemValue: [],

  SoViewByIdForInvoice: [],
  SoViewByIdForJuteGovtInvoice: [],
  DoViewByIdForInvoice: [],
  RegularInvoiceViewById: [],

  InvoiceFiles: {},
  Uploaded_invoice_Files: [],
  delete_invoice_File: [],

  RegularInvoiceHeader: {
    invoiceNo: "",
    regInvoiceId: "",
    salesOrderId: "",
    salesOrderNo: "N/A",
    deliveryOrderId: "",
    deliveryOrderNo: "N/A",
    invoiceType: "1",
    invoiceTypeDesc: "",
    branchId: "",
    branchName: "",
    bankId: "",
    bankBranch: "",
    bankName: "",
    accountNo: "",
    ifscCode: "",
    micrCode: "",
    brokerId: "",
    brokerName: "",
    customerId: "",
    customerName: "",
    billingTo: "",
    billingFrom: "",
    shippingFrom: "",
    billingFromAddress: "",
    shippingFromAddress: "",
    transporterNumber: "",
    transporterDocNo: "",
    billingAddress: "",
    billingState: "",
    billedToContactNumber: null,
    billedToGstin: null,
    billedToContactPerson: null,
    billedToEmail: null,
    shippingTo: "",
    shippingAddress: "",
    shippingState: "",
    shippedToContactNumber: null,
    shippedToGstin: null,
    shippedToContactPerson: null,
    shippedToEmail: null,
    taxType: "",
    taxTypeDesc: "",
    invoiceDate: null,
    salesOrderDate: null,
    deliveryOrderDate: null,
    quotationDate: null,
    quotationNo: "N/A",
    vehicleNo: "",
    poNumber: "",
    customerReferenceNumber: "",
    finYear: "",
    createdBy: "",
    createdDate: null,
    companyId: "",
    customerBranchId: "",
    transporterId: "",
    transporterName: "",
    challanNo: "",
    challanDate: null,
    brokerCommision: "",
    brokerCommisionPercent: "",
    transportationMode: "",
    vehicleType: "",
    transportationDistance: "",
    transporterAddress: null,
    transporterStateCode: null,
    transporterStateName: null,
    transporterGst: "",
    footerNote: "",
    internalNote: "",
    termsConditionType: "",
    termsCondition: "",
    deliveryTerms: "",
    paymentTerms: "",
    deliveryDays: "",
    freightCharges: "",
    amountWithoutTax: "",
    amountWithTax: "",
    updatedByDesc: "",
    updatedOn: null,
    approvedBy: "",
    approvedOn: "",
    statusId: 1,
    gstSuppType: "",
    gstInvoiceStatus: "",
    gstInvoiceCategory: "",
    gstInvoiceType: "",
    gstAmtTaxable: null,
    gst_txpd_taxable_value: null,
    gstShippingBillNo: "",
    gstShippingBillDate: null,
    gstReason: "",
    gstr1ReturnPeriod: "",
    gstr3bReturnPeriod: "",
    reverseCharge: "",
    isAmended: "",
    amendedPos: "",
    amendedPeriod: "",
    totalIGST: "0.00",
    totalCGST: "0.00",
    totalSGST: "0.00",
    netTotal: "0.00",
    taxTotal: "0.00",
    TotalAmount: "0.00",
    subTotal: "0.00",
    brokerContactNumber: null,
    brokerContactPerson: "",
    consigneeContactNumber: "",
    consigneeContactPerson: "",
    ApproveButton: false,
    RejectButton: false,
    UpdateButton: false,
    createWithDirectInvoice: true,
    roundOffValue: null,
    roundingFlag: null,
    roundingPositiveFlag: null,
    roundingNegetiveFlag: null,
    roundingPositiveValue: "0.00",
    roundingNegetiveValue: "0.00",
    disableBrokerInvoice: false,
    disableconsigneeInvoice: false,
    eInvoiceAuthenticationApproval: false,
  },
  RegularInvoiceLineItems: [
    {
      id: 0,
      salesRegularInvoiceLineitemId: null,
      createdBy: null,
      createdDate: "",
      companyId: null,
      companyName: "",
      currency: "INR",
      salesId: null,
      salesLineItemId: null,
      regularDeliveryOrderID: "",
      regularDeliveryOrderLineItemID: "",
      itemGroupId: "",
      itemGroupName: "",
      itemGroupValue: "",
      itemId: null,
      itemName: "",
      itemCode: "",
      itemValue: "",
      hsncode: "",
      itemMake: "",
      quantity: "0.000",
      uom: "",
      rate: "0.000",
      invoiceQty: null,
      igstAmount: "",
      igstPercent: "",
      cgstAmount: "",
      cgstPercent: "",
      sgstAmount: "",
      sgstPercent: "",
      cessAmount: null,
      discountType: "",
      discountName: "",
      discount: "",
      discountedRate: "0.000",
      discountAmount: "0.00",
      netAmount: "",
      totalAmount: "0.00",
      remarks: "",
      taxPercent: "0.00",
      balesPacketSlNo: "",
      isActive: 1,
      status: 1,
      updatedBy: null,
      updatedOn: null,
      regInvoiceId: null,
      ItemNameData: [],
    },
  ],
  juteGovtInvoiceHeader: {
    govtInvoiceId: "",
    invoiceNo: "",
    regularInvoiceId: "",
    pcsoNo: "",
    pcsoDate: null,
    loadingPoint: "",
    destinationRailHead: "",
    ammendmentNo: null,
    ammendmentDate: null,
    updatedBy: null,
    updatedOn: null,
    isActive: 1,
    regInvoiceId: null,
    deliveryOrderId: null,
    deliveryDate: null,
    deliveryOrderNo: null,
    finYear: "",
    createdBy: "",
    createdByDesc: "",
    createdDate: "",
    invoiceDate: null,
    invoiceType: "",
    invoiceTypeDesc: "",
    companyId: "",
    companyName: "",
    branchId: "",
    branchName: "",
    bankId: "",
    bankBranch: "",
    bankName: "",
    accountNo: "",
    ifscCode: "",
    micrCode: "",
    customerReferenceNumber: null,
    regularSalesOrderId: "",
    salesOrderNumber: "",
    salesOrderDate: "",
    brokerId: null,
    brokerName: null,
    brokerContactPerson: null,
    brokerContactNumber: null,
    consigneeContactPerson: "",
    consigneeContactNumber: "",
    customerId: "",
    customerName: "",
    customerBranchId: null,
    billingTo: "",
    billingFrom: "",
    shippingFrom: "",
    billingFromAddress: "",
    shippingFromAddress: "",
    billingAddress: "",
    billingState: "",
    billedToContactNumber: "",
    billedToGstin: "",
    billedToContactPerson: "",
    billedToEmail: "",
    shippingTo: "",
    shippingAddress: "",
    shippingState: "",
    shippedToContactNumber: "",
    shippedToGstin: "",
    shippedToContactPerson: "",
    shippedToEmail: "",
    quotationDate: null,
    quotationNumber: null,
    printedOn: "",
    transporterId: null,
    transporterName: null,
    transporterAddress: null,
    transporterStateCode: null,
    transporterStateName: null,
    transporterNumber: "",
    transporterGst: "",
    vehicleNumber: null,
    termsConditionType: null,
    termsConditionName: null,
    status: 1,
    statusName: null,
    challanNo: "",
    challanDate: null,
    taxType: "",
    taxName: "",
    footerNote: "",
    internalNote: "",
    termsCondition: "",
    deliveryTerms: "",
    paymentTerms: "",
    deliveryDays: null,
    freightCharges: null,
    amountWithoutTax: "",
    amountWithTax: "",

    roundOffValue: null,
    roundingFlag: null,
    roundingPositiveFlag: null,
    roundingNegetiveFlag: null,
    roundingPositiveValue: "0.00",
    roundingNegetiveValue: "0.00",

    updatedBy: null,
    updatedByDesc: null,
    updatedOn: null,
    approvedBy: null,
    approvedByDesc: null,
    approvedOn: null,
    isActive: 1,
    gstSuppType: null,
    gstInvoiceStatus: null,
    gstInvoiceCategory: null,
    gstInvoiceType: null,
    gstAmtTaxable: null,
    gst_txpd_taxable_value: null,
    gstShippingBillNo: null,
    gstShippingBillDate: null,
    gstReason: null,
    gstr1ReturnPeriod: null,
    gstr3bReturnPeriod: null,
    reverseCharge: null,
    isAmended: null,
    amendedPos: null,
    amendedPeriod: null,
    poNumber: null,
    approveStatus: true,
    brokerCommissionPercent: null,
    containerNo: "",
    totalIGST: "0.00",
    totalCGST: "0.00",
    totalSGST: "0.00",
    netTotal: "0.00",
    taxTotal: "0.00",
    TotalAmount: "0.00",
    subTotal: "0.00",
    ApproveButton: false,
    RejectButton: false,
    UpdateButton: false,
    EditButton: false,

    transportationMode: "",
    vehicleType: "",
    vehicleTypeName: "",
    transporterNumber: "",
    transporterDocNo: "",
    transportationDistance: "",
    eInvoiceAuthenticationApproval: false,
  },
  JuteGovtInvoiceLineItems: [
    {
      id: 0,
      regularInvoiceLineitemId: null,
      govtInvoiceId: "",
      createdBy: null,
      createdDate: "",
      companyId: null,
      companyName: "",
      currency: "INR",
      regularSalesOrderId: null,
      regularSalesOrderLineItemId: null,
      regularDeliveryOrderID: "",
      regularDeliveryOrderLineItemID: "",
      itemGroupId: "",
      itemGroupName: "",
      itemGroupValue: "",
      itemId: null,
      itemName: "",
      itemCode: "",
      itemValue: "",
      hsncode: "",
      itemMake: "",
      quantity: "0.000",
      uom: "",
      rate: "0.000",
      doBalQty: null,
      invoiceQty: null,
      igstAmount: "",
      igstPercent: "",
      cgstAmount: "",
      cgstPercent: "",
      sgstAmount: "",
      sgstPercent: "",
      cessAmount: null,
      discountType: "",
      discountName: "",
      discount: "",
      discountedRate: "0.000",
      discountAmount: "",
      netAmount: "",
      totalAmount: "0.00",
      remarks: "",
      taxPercent: "0.00",
      balesPacketSlNo: "",
      isActive: 1,
      govtInvoiceIsActive: 1,
      status: 1,
      updatedBy: null,
      updatedOn: null,
      regInvoiceId: null,
      govtInvoiceLineItemId: "",
      govtInvoiceHdrId: "",
      govtInvoiceRateUom: null,
      govtInvoiceRateUom2: null,
      govtInvoiceRateUom3: null,
      govtInvoiceQuantityUom: null,
      govtInvoiceQuantityUom2: null,
      govtInvoiceQuantityUom3: null,
      govtInvoiceRelationValue: null,
      govtInvoiceRelationValue2: null,
      govtInvoiceRelationValue3: null,
      govtInvoiceQtyCalculatedValue: null,
      govtInvoiceQtyCalculatedValue2: null,
      govtInvoiceQtyCalculatedValue3: null,
      govtInvoiceRateCalculatedValue: null,
      govtInvoiceRateCalculatedValue2: null,
      govtInvoiceRateCalculatedValue3: null,
      govtInvoiceBalesPacketSlNo: null,
      ItemNameData: [],
    },
  ],
  /** Jute Hessian */
  JuteHassianInvoiceHeader: {
    deliveryOrderId: null,
    finYear: "",
    createdBy: null,
    createdDate: "",
    invoiceDate: null,
    invoiceNo: "",
    invoiceType: "",
    companyId: "",
    branchId: "",
    regularSalesOrderId: "",
    brokerId: null,
    customerId: null,
    customerBranchId: null,
    billingTo: null,
    billingToAddress: "",
    billingToGST: "",
    billingContactNo: "",
    billingContactPerson: "",
    billingToEmail: "",
    billingFrom: "",
    shippingFrom: "",
    billingFromAddress: "",
    shippingFromAddress: "",
    transporterNumber: "",
    transporterName: "",
    transporterAddress: "",
    transporterStateName: "",
    transporterGst: "",
    transporterDocNo: "",
    billingState: "",
    shippingTo: null,
    transporterId: null,
    challanNo: "",
    challanDate: "",
    taxType: null,
    brokerCommision: "",
    brokerCommisionPercent: "",
    footerNote: "",
    internalNote: "",
    termsConditionType: "",
    termsCondition: "",
    deliveryTerms: "",
    paymentTerms: "",
    deliveryDays: "",
    deliveryDate: null,
    salesOrderDate: null,
    freightCharges: "",
    amountWithoutTax: "",
    amountWithTax: "",
    statusId: null,
    gstSuppType: "",
    gstInvoiceStatus: "",
    gstInvoiceCategory: "",
    gstInvoiceType: "",
    gstAmtTaxable: null,
    gst_txpd_taxable_value: null,
    gstShippingBillNo: "",
    gstShippingBillDate: "",
    gstReason: "",
    gstr1ReturnPeriod: "",
    gstr3bReturnPeriod: "",
    reverseCharge: "",
    isAmended: "",
    amendedPos: "",
    amendedPeriod: "",
    isActive: 1,
    salesOrderId: "",
    branch: "",
    branchName: "",
    salesOrderNumber: "",
    quotationNumber: "",
    quotationDate: null,
    vehicleNo: "",
    bankId: "",
    bankBranch: "",
    bankName: "",
    accountNo: "",
    ifscCode: "",
    micrCode: "",
    company: "",
    brokerName: "",
    customerDisable: true,
    billingDisbale: true,
    shippingDisable: true,
    customerName: "",
    brokerContactNumber: null,
    brokerContactPerson: "",
    consigneeContactNumber: "",
    consigneeContactPerson: "",
    shippingToAddress: "",
    shippingToGST: "",
    shippingContactNo: "",
    shippingContactPerson: "",
    shippingToEmail: "",
    shippingState: "",
    transporter: null,
    transporterName: "",
    transporterAddress: "",
    transporterStateCode: "",
    transporterStateName: "",
    status: null,
    statusName: "",
    updatedBy: "",
    updatedOn: "",
    updatedByDesc: "",
    approvedBy: "",
    approvedOn: "",
    approvedBydesc: "",
    regDeliveryOrderId: "",
    deliveryOrderNo: "",
    createdByDesc: "",
    invoiceTypeName: "",
    billingStateName: "",
    shippingStateName: "",
    printedOn: "",
    taxName: "",
    approveStatus: "",
    ApproveButton: false,
    RejectButton: false,
    UpdateButton: false,
    EditButton: false,
    disableBroker: false,
    regInvoiceId: "",
    salesOrderNo: "N/A",
    invoiceTypeDesc: "",
    billedToContactNumber: null,
    billedToGstin: null,
    billedToContactPerson: null,
    billedToEmail: null,
    shippingAddress: "",
    shippedToContactNumber: null,
    shippedToGstin: null,
    shippedToContactPerson: null,
    shippedToEmail: null,
    taxTypeDesc: "",
    deliveryOrderDate: null,
    quotationNo: "N/A",
    poNumber: "",
    customerReferenceNumber: "",
    transportationMode: "",
    vehicleType: "",
    vehicleTypeName: "",
    transportationDistance: "",
    totalIGST: "0.00",
    totalCGST: "0.00",
    totalSGST: "0.00",
    netTotal: "0.00",
    taxTotal: "0.00",
    TotalAmount: "0.00",
    subTotal: "0.00",
    createWithDirectInvoice: true,
    roundOffValue: null,
    roundingFlag: null,
    roundingPositiveFlag: null,
    roundingNegetiveFlag: null,
    roundingPositiveValue: "0.00",
    roundingNegetiveValue: "0.00",
    disableBrokerInvoice: false,
    disableconsigneeInvoice: false,
    eInvoiceAuthenticationApproval: false,
  },
  JuteYarnInvoiceHeader: {
    yarnInvoiceId: "",
    regInvoiceId: "",
    invoiceType: "",
    deliveryOrderId: "",
    deliveryDate: null,
    deliveryOrderNo: "",
    finYear: "",
    createdBy: "",
    createdByDesc: "",
    createdDate: "",
    invoiceDate: null,
    invoiceNo: "",
    invoiceTypeDesc: "",
    companyId: "",
    companyName: "",
    branchId: "",
    branchName: "",
    customerReferenceNumber: "",
    regularSalesOrderId: "",
    salesOrderNumber: "",
    salesOrderDate: null,
    brokerId: "",
    brokerName: "",
    brokerContactPerson: "",
    brokerContactNumber: "",
    consigneeContactPerson: "",
    consigneeContactNumber: "",
    customerId: "",
    customerName: "",
    customerBranchId: "",
    billingTo: "",
    billingAddress: "",
    billingState: "",
    billingFrom: "",
    shippingFrom: "",
    billingFromAddress: "",
    shippingFromAddress: "",
    transporterNumber: "",
    transporterDocNo: "",
    billedToContactNumber: "",
    billedToGstin: "",
    billedToContactPerson: "",
    billedToEmail: "",
    shippingTo: 11,
    shippingAddress: "",
    shippingState: "",
    shippedToContactNumber: "",
    shippedToGstin: "",
    shippedToContactPerson: "",
    shippedToEmail: "",
    quotationDate: null,
    quotationNumber: "",
    printedOn: "",
    transporterId: "",
    transporterName: "",
    transporterAddress: "",
    transporterStateCode: "",
    transporterStateName: "",
    transporterGst: "",
    vehicleNumber: "",
    termsConditionType: "",
    termsConditionName: "",
    status: 1,
    statusName: "",
    challanNo: "",
    challanDate: null,
    taxType: "",
    taxName: "",
    footerNote: "",
    internalNote: "",
    termsCondition: "",
    deliveryTerms: "",
    paymentTerms: "",
    deliveryDays: "",
    freightCharges: "",
    amountWithoutTax: "",
    amountWithTax: "",
    roundOffValue: 0,
    roundingFlag: false,
    updatedBy: "",
    updatedByDesc: "",
    updatedOn: "",
    approvedBy: "",
    approvedByDesc: "",
    approvedOn: "",
    isActive: 1,
    transportationMode: "",
    vehicleType: "",
    vehicleTypeName: "",
    bankId: "",
    bankBranch: "",
    bankName: "",
    accountantName: "",
    accountNo: "",
    ifscCode: "",
    micrCode: "",
    transportationDistance: "",
    gstSuppType: "",
    gstInvoiceStatus: "",
    gstInvoiceCategory: "",
    gstInvoiceType: "",
    gstAmtTaxable: "",
    gst_txpd_taxable_value: "",
    gstShippingBillNo: "",
    gstShippingBillDate: null,
    gstReason: "",
    gstr1ReturnPeriod: "",
    gstr3bReturnPeriod: "",
    reverseCharge: "",
    isAmended: null,
    amendedPos: "",
    amendedPeriod: "",
    poNumber: "",
    approveStatus: null,
    brokerCommissionPercent: "",
    loadingPoint: "",
    destinationRailHead: "",
    updatedBy: null,
    updatedOn: null,
    isActive: 1,
    totalIGST: "0.00",
    totalCGST: "0.00",
    totalSGST: "0.00",
    netTotal: "0.00",
    taxTotal: "0.00",
    TotalAmount: "0.00",
    subTotal: "0.00",
    ApproveButton: false,
    RejectButton: false,
    UpdateButton: false,
    EditButton: false,
    eInvoiceAuthenticationApproval: false,
  },
  JuteHassianInvoiceLineitems: [
    {
      id: 0,
      createdBy: null,
      companyId: null,
      regularSalesOrderId: "",
      regularSalesOrderLineItemId: "",
      hsnCode: "",
      itemGroupId: null,
      itemGroupValue: "",
      itemId: null,
      hsncode: "",
      itemMake: "",
      uom: "",
      doBalQty: "",
      igstPercent: "",
      cgstAmount: "",
      cgstPercent: "",
      sgstAmount: "",
      sgstPercent: "",
      discountType: "",
      discount: "",
      balesPacketSlNo: "",
      isActive: 1,
      hessianIsActive: 1,
      itemGroupName: "",
      itemName: "",
      itemCode: "",
      itemValue: "",
      createdDate: "",
      cessAmount: null,
      currency: "INR",
      deliveryOrderLineItemId: "",
      salesRegularInvoiceLineitemId: null,
      companyName: "",
      salesId: null,
      salesLineItemId: null,
      regularDeliveryOrderID: "",
      regularDeliveryOrderLineItemID: "",
      quantity: "0.000",
      rate: "0.000",
      invoiceQty: null,
      igstAmount: "",
      discountName: "",
      discountedRate: "0.000",
      discountAmount: "0.00",
      netAmount: "",
      totalAmount: "0.00",
      remarks: "",
      taxPercent: "0.00",
      status: 1,
      updatedBy: null,
      updatedOn: null,
      regInvoiceId: null,
      ItemNameData: [],
      hessianQuantityCalculatedValue: "0.000",
      hessianBalesPacketSlNo: null,
      hessianDeliveryOrderId: null,
      hessianDeliveryOrderLineItemId: null,
      hessianQuantityUomId: null,
      hessianQuantityRelationValue: "",
      hessianRateUom: "",
      hessianRateUomId: null,
      hessianRateRelationValue: "",
      hessianRateCalculatedValue: "",
      hessianRateIncludingBrokerage: "",
      hessianRateExcludingBrokerage: "0.00",
      hessianUpdatedBy: null,
      hessianUpdatedOn: "",
      hessianQuantityUom: null,
    },
  ],
  JuteYarnInvoiceLineItems: [
    {
      i: 0,
      salesRegularInvoiceLineitemId: "",
      regInvoiceId: "",
      createdBy: "",
      createdDate: "",
      companyId: "",
      companyName: "",
      regularSalesOrderId: "",
      regularSalesOrderLineItemId: "",
      regularDeliveryOrderID: "",
      regularDeliveryOrderLineItemID: "",
      itemGroupId: "",
      itemGroupName: "",
      itemGroupValue: "",
      itemId: null,
      itemName: "",
      itemValue: "",
      itemCode: "",
      hsncode: "",
      itemMake: "",
      quantity: "0.000",
      uom: "",
      rate: "0.000",
      invoiceQty: null,
      igstAmount: "",
      igstPercent: "",
      cgstAmount: "",
      cgstPercent: "",
      sgstAmount: "",
      sgstPercent: "",
      cessAmount: "",
      discountType: "",
      discountName: "",
      discount: "",
      discountedRate: "0.000",
      discountAmount: "0.00",
      netAmount: "0.00",
      totalAmount: "0.00",
      taxPercent: "0.00",
      remarks: "",
      balesPacketSlNo: "",
      isActive: 1,
      yarnIsActive: 1,
      status: 1,
      updatedBy: "",
      updatedOn: "",
      govtInvoiceLineItemId: "",
      govtInvoiceHdrId: "",
      govtInvoiceRateUom: null,
      govtInvoiceRateUom2: null,
      govtInvoiceRateUom3: null,
      govtInvoiceQuantityUom: null,
      govtInvoiceQuantityUom2: null,
      govtInvoiceQuantityUom3: null,
      govtInvoiceRelationValue: null,
      govtInvoiceRelationValue2: null,
      govtInvoiceRelationValue3: null,
      govtInvoiceQtyCalculatedValue: null,
      govtInvoiceQtyCalculatedValue2: null,
      govtInvoiceQtyCalculatedValue3: null,
      govtInvoiceRateCalculatedValue: null,
      govtInvoiceRateCalculatedValue2: null,
      govtInvoiceRateCalculatedValue3: null,
      govtInvoiceIsActive: null,
      govtInvoiceBalesPacketSlNo: null,
      yarnInvoiceLineItemId: null,
      yarnInvoiceId: null,
      yarnProductId: null,
      yarnQuantity: null,
      yarnUnitPrice: null,
      yarnRateUom: null,
      yarnRateUomRelationValue: null,
      yarnRateUomId: null,
      yarnRateCalculatedValue: null,
      yarnQuantityUom: null,
      yarnQuantityUomRelationValue: null,
      yarnQuantityUomId: null,
      yarnQuantityCalculatedValue: null,
      yarnVariableRelationValue: null,
      yarnRateIncludingBrokerage: "",
      yarnRateExcludingBrokerage: "",
      yarnTotalPrice: null,
      yarnBalesPacketSlNo: null,
      hessianInvoicelineitemId: null,
      hessianInvoiceId: null,
      hessianQuantityUom: null,
      hessianQuantityUomId: null,
      hessianQuantityRelationValue: null,
      hessianQuantityCalculatedValue: null,
      hessianRateUom: null,
      hessianRateUomId: null,
      hessianRateRelationValue: null,
      hessianRateCalculatedValue: null,
      hessianRateExcludingBrokerage: null,
      hessianRateIncludingBrokerage: null,
      hessianBalesPacketSlNo: null,
      ItemNameData: [],
      uom2Data: [],
    },
    ,
  ],
  InvoiceJuteYarnUomListData: [],
  JuteGovtInvoiceViewById: [],
  createJuteGovtInvoiceRecord: [],
  createJuteYarnInvoiceRecord: [],
  updateJuteYarnInvoiceRecord: [],
  UpdateJuteGovtInvoiceRecord: [],
  updateRegularInvoiceRecord: [],
  RegularInvoiceItemBy_ItemGroupId: [],
  RegularInvoiceItemDetails_by_ItemId: [],
  DoViewByIdForJuteGovtInvoice: [],
  JuteGovtInvoiceItemBy_ItemGroupId: [],
  JuteHessianInvoiceViewById: [],
  CreateJuteHessianInvoiceRecord: [],
  SoViewByIdForJuteYarnInvoice: [],
  DoViewByIdForJuteYarnInvoice: [],
  // SoViewByIdForJuteYarnInvoice:[],
  // DoViewByIdForJuteYarnInvoice:[],
  JuteYarnInvoiceViewbyId: [],
  InvoiceJuteYarngetUomRelation: [],
  eInvoiceMesssage: "",
  eWayBillMessage: "",
};

const InvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVOICE_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case INVOICE_LIST_SUCCESSFULL:
      state = {
        ...state,
        Invoice_List: action.payload.response,
        loading: false,
      };
      break;
    case CREATE_REGULAR_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CREATE_REGULAR_INVOICE_SUCCESSFULL:
      state = {
        ...state,
        createRegularInvoiceRecord: action.payload.response,
        loading: false,
      };
      break;
    case CREATE_JUTE_GOVT_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CREATE_JUTE_GOVT_INVOICE_SUCCESSFULL:
      state = {
        ...state,
        createJuteGovtInvoiceRecord: action.payload.response,
        loading: false,
      };
      break;
    case CREATE_JUTE_YARN_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CREATE_JUTE_YARN_INVOICE_SUCCESSFULL:
      state = {
        ...state,
        createJuteYarnInvoiceRecord: action.payload.response,
        loading: false,
      };
      break;
    case UPDATE_JUTE_YARN_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_JUTE_YARN_INVOICE_SUCCESSFULL:
      state = {
        ...state,
        updateJuteYarnInvoiceRecord: action.payload.response,
        loading: false,
      };
      break;
    case UPDATE_JUTE_GOVT_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_JUTE_GOVT_INVOICE_SUCCESSFULL:
      state = {
        ...state,
        UpdateJuteGovtInvoiceRecord: action.payload.response,
        loading: false,
      };
      break;
    case MATERIAL_DETAILS_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case MATERIAL_DETAILS_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        materialDetails: action.payload.response,
        loading: false,
      };
      break;
    case JUTE_GOVT_INVOICE_HEADER:
      state = {
        ...state,
        juteGovtInvoiceHeader: action.payload,
      };
      break;
    case REGULAR_INVOICE_HEADER:
      state = {
        ...state,
        RegularInvoiceHeader: action.payload,
      };
      break;
    case CLEAR_REGULAR_INVOICE_HEADER:
      state = {
        ...state,
        RegularInvoiceHeader: initialState.RegularInvoiceHeader,
      };
      break;
    case JUTE_YARN_INVOICE_HEADER:
      state = {
        ...state,
        JuteYarnInvoiceHeader: action.payload,
      };
      break;
    case CLEAR_JUTE_GOVT_INVOICE_HEADER:
      state = {
        ...state,
        juteGovtInvoiceHeader: initialState.juteGovtInvoiceHeader,
      };
      break;
    case CLEAR_JUTE_YARN_INVOICE_HEADER:
      state = {
        ...state,
        JuteYarnInvoiceHeader: initialState.JuteYarnInvoiceHeader,
      };
      break;
    case REGULAR_INVOICE_LINE_ITEMS:
      state = {
        ...state,
        RegularInvoiceLineItems: action.payload,
      };
      break;
    case JUTE_GOVT_INVOICE_LINE_ITEMS:
      state = {
        ...state,
        JuteGovtInvoiceLineItems: action.payload,
      };
      break;
    case JUTE_YARN_INVOICE_LINE_ITEMS:
      state = {
        ...state,
        JuteYarnInvoiceLineItems: action.payload,
      };
      break;
    case CLEAR_REGULAR_INVOICE_LINE_ITEMS:
      state = {
        ...state,
        RegularInvoiceLineItems: [
          {
            id: 0,
            salesRegularInvoiceLineitemId: null,
            createdBy: null,
            createdDate: "",
            companyId: null,
            companyName: "",
            salesId: null,
            salesLineItemId: null,
            regularDeliveryOrderID: "",
            regularDeliveryOrderLineItemID: "",
            itemGroupId: "",
            itemGroupName: "",
            itemGroupValue: "",
            itemId: null,
            itemName: "",
            itemCode: "",
            itemValue: "",
            hsncode: "",
            itemMake: "",
            quantity: "0.000",
            uom: "",
            rate: "0.000",
            invoiceQty: null,
            igstAmount: "",
            igstPercent: "",
            cgstAmount: "",
            cgstPercent: "",
            sgstAmount: "",
            sgstPercent: "",
            cessAmount: null,
            discountType: "",
            discountName: "",
            discount: "",
            discountedRate: "0.000",
            discountAmount: "0.00",
            netAmount: "",
            totalAmount: "0.00",
            remarks: "",
            taxPercent: "0.00",
            balesPacketSlNo: "",
            isActive: 1,
            status: 1,
            updatedBy: null,
            updatedOn: null,
            regInvoiceId: null,
            ItemNameData: [],
            currency: "INR",
          },
        ],
      };
      break;
    case CLEAR_JUTE_GOVT_INVOICE_LINE_ITEMS:
      state = {
        ...state,
        JuteGovtInvoiceLineItems: [
          {
            id: 0,
            regularInvoiceLineitemId: null,
            govtInvoiceId: "",
            createdBy: null,
            createdDate: "",
            companyId: null,
            companyName: "",
            currency: "INR",
            regularSalesOrderId: null,
            regularSalesOrderLineItemId: null,
            regularDeliveryOrderID: "",
            regularDeliveryOrderLineItemID: "",
            itemGroupId: "",
            itemGroupName: "",
            itemGroupValue: "",
            itemId: null,
            itemName: "",
            itemCode: "",
            itemValue: "",
            hsncode: "",
            itemMake: "",
            quantity: "0.000",
            uom: "",
            rate: "0.000",
            doBalQty: null,
            invoiceQty: null,
            igstAmount: "",
            igstPercent: "",
            cgstAmount: "",
            cgstPercent: "",
            sgstAmount: "",
            sgstPercent: "",
            cessAmount: null,
            discountType: "",
            discountName: "",
            discount: "",
            discountedRate: "0.000",
            discountAmount: "0.00",
            netAmount: "",
            totalAmount: "0.00",
            remarks: "",
            taxPercent: "0.00",
            balesPacketSlNo: "",
            isActive: 1,
            govtInvoiceIsActive: 1,
            status: 1,
            updatedBy: null,
            updatedOn: null,
            regInvoiceId: null,
            govtInvoiceLineItemId: "",
            govtInvoiceHdrId: "",
            govtInvoiceRateUom: null,
            govtInvoiceRateUom2: null,
            govtInvoiceRateUom3: null,
            govtInvoiceQuantityUom: null,
            govtInvoiceQuantityUom2: null,
            govtInvoiceQuantityUom3: null,
            govtInvoiceRelationValue: null,
            govtInvoiceRelationValue2: null,
            govtInvoiceRelationValue3: null,
            govtInvoiceQtyCalculatedValue: null,
            govtInvoiceQtyCalculatedValue2: null,
            govtInvoiceQtyCalculatedValue3: null,
            govtInvoiceRateCalculatedValue: null,
            govtInvoiceRateCalculatedValue2: null,
            govtInvoiceRateCalculatedValue3: null,
            govtInvoiceBalesPacketSlNo: null,
            ItemNameData: [],
          },
        ],
      };
      break;
    case CLEAR_JUTE_YARN_INVOICE_LINE_ITEMS:
      state = {
        ...state,
        JuteYarnInvoiceLineItems: [
          {
            i: 0,
            salesRegularInvoiceLineitemId: "",
            regInvoiceId: "",
            createdBy: "",
            createdDate: "",
            companyId: "",
            companyName: "",
            regularSalesOrderId: "",
            regularSalesOrderLineItemId: "",
            regularDeliveryOrderID: "",
            regularDeliveryOrderLineItemID: "",
            itemGroupId: "",
            itemGroupName: "",
            itemGroupValue: "",
            itemId: null,
            itemName: "",
            itemValue: "",
            itemCode: "",
            hsncode: "",
            itemMake: "",
            quantity: "0.000",
            uom: "",
            rate: "0.000",
            invoiceQty: null,
            igstAmount: "",
            igstPercent: "",
            cgstAmount: "",
            cgstPercent: "",
            sgstAmount: "",
            sgstPercent: "",
            cessAmount: "",
            discountType: "",
            discountName: "",
            discount: "",
            discountedRate: "0.000",
            discountAmount: "0.00",
            netAmount: "0.00",
            totalAmount: "0.00",
            taxPercent: "0.00",
            remarks: "",
            balesPacketSlNo: "",
            isActive: 1,
            yarnIsActive: 1,
            status: 1,
            updatedBy: "",
            updatedOn: "",
            govtInvoiceLineItemId: "",
            govtInvoiceHdrId: "",
            govtInvoiceRateUom: null,
            govtInvoiceRateUom2: null,
            govtInvoiceRateUom3: null,
            govtInvoiceQuantityUom: null,
            govtInvoiceQuantityUom2: null,
            govtInvoiceQuantityUom3: null,
            govtInvoiceRelationValue: null,
            govtInvoiceRelationValue2: null,
            govtInvoiceRelationValue3: null,
            govtInvoiceQtyCalculatedValue: null,
            govtInvoiceQtyCalculatedValue2: null,
            govtInvoiceQtyCalculatedValue3: null,
            govtInvoiceRateCalculatedValue: null,
            govtInvoiceRateCalculatedValue2: null,
            govtInvoiceRateCalculatedValue3: null,
            govtInvoiceIsActive: null,
            govtInvoiceBalesPacketSlNo: null,
            yarnRateIncludingBrokerage: "",
            yarnRateExcludingBrokerage: "",
            yarnInvoiceLineItemId: null,
            yarnInvoiceId: null,
            yarnProductId: null,
            yarnQuantity: null,
            yarnUnitPrice: null,
            yarnRateUom: null,
            yarnRateUomRelationValue: null,
            yarnRateUomId: null,
            yarnRateCalculatedValue: null,
            yarnQuantityUom: null,
            yarnQuantityUomRelationValue: null,
            yarnQuantityUomId: null,
            yarnQuantityCalculatedValue: null,
            yarnVariableRelationValue: null,
            yarnTotalPrice: null,
            yarnBalesPacketSlNo: null,
            hessianInvoicelineitemId: null,
            hessianInvoiceId: null,
            hessianQuantityUom: null,
            hessianQuantityUomId: null,
            hessianQuantityRelationValue: null,
            hessianQuantityCalculatedValue: null,
            hessianRateUom: null,
            hessianRateUomId: null,
            hessianRateRelationValue: null,
            hessianRateCalculatedValue: null,
            hessianRateExcludingBrokerage: null,
            hessianRateIncludingBrokerage: null,
            hessianBalesPacketSlNo: null,
            ItemNameData: [],
            uom2Data: [],
          },
        ],
      };
      break;
    case REGULAR_SALES_ORDER_VIEW_BYID_FOR_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case REGULAR_SALES_ORDER_VIEW_BYID_FOR_INVOICE_SUCCESSFULL:
      state = {
        ...state,
        SoViewByIdForInvoice: action.payload.response,
        loading: false,
      };
      break;
    case SALES_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SALES_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE_SUCCESSFULL:
      state = {
        ...state,
        SoViewByIdForJuteGovtInvoice: action.payload.response,
        loading: false,
      };
      break;
    case SALES_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SALES_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE_SUCCESSFULL:
      state = {
        ...state,
        SoViewByIdForJuteYarnInvoice: action.payload.response,
        loading: false,
      };
      break;
    case DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_GOVT_INVOICE_SUCCESSFULL:
      state = {
        ...state,
        DoViewByIdForJuteGovtInvoice: action.payload.response,
        loading: false,
      };
      break;
    case DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_YARN_INVOICE_SUCCESSFULL:
      state = {
        ...state,
        DoViewByIdForJuteYarnInvoice: action.payload.response,
        loading: false,
      };
      break;
    case INVOICE_JUTE_YARN_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case INVOICE_JUTE_YARN_VIEW_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        JuteYarnInvoiceViewbyId: action.payload.response,
        loading: false,
      };
      break;
    case REGULAR_DELIVERY_ORDER_VIEW_BYID_FOR_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case REGULAR_DELIVERY_ORDER_VIEW_BYID_FOR_INVOICE_SUCCESSFULL:
      state = {
        ...state,
        DoViewByIdForInvoice: action.payload.response,
        loading: false,
      };
      break;
    case REGULAR_INVOICE_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case REGULAR_INVOICE_VIEW_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        RegularInvoiceViewById: action.payload.response,
        loading: false,
      };
      break;
    case JUTE_GOVT_INVOICE_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTE_GOVT_INVOICE_VIEW_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        JuteGovtInvoiceViewById: action.payload.response,
        loading: false,
      };
      break;
    case UPDATE_REGULAR_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_REGULAR_INVOICE_SUCCESSFULL:
      state = {
        ...state,
        updateRegularInvoiceRecord: action.payload.response,
        loading: false,
      };
      break;
    case REGULAR_INVOICE_ITEMS_BY_ITEM_GROUP_ID:
      state = {
        ...state,
        loading: true,
        dataLoaded: false,
      };
      break;

    case REGULAR_INVOICE_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL:
      state = {
        ...state,
        RegularInvoiceItemBy_ItemGroupId: action.payload.response,
        loading: false,
        dataLoaded: true,
      };
      break;
    case REGULAR_INVOICE_ITEMS_DETAILS_BY_ITEM_ID:
      state = {
        ...state,
        loading: true,
        dataLoaded: false,
      };
      break;

    case REGULAR_INVOICE_ITEMS_DETAILS_BY_ITEM_ID_SUCCESSFULL:
      state = {
        ...state,
        RegularInvoiceItemDetails_by_ItemId: action.payload.response,
        loading: false,
        dataLoaded: true,
      };
      break;
    case JUTE_GOVT_INVOICE_ITEMS_BY_ITEM_GROUP_ID:
      state = {
        ...state,
        loading: true,
        dataLoaded: false,
      };
      break;

    case JUTE_GOVT_INVOICE_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL:
      state = {
        ...state,
        JuteGovtInvoiceItemBy_ItemGroupId: action.payload.response,
        loading: false,
        dataLoaded: true,
      };
      break;

    case GET_VEHICLE_TYPE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_VEHICLE_TYPE_SUCCESSFULL:
      state = {
        ...state,
        vehicleType: action.payload.response.data,
        loading: true,
      };
      break;
    case SALES_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SALES_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE_SUCCESSFULL:
      state = {
        ...state,
        SoViewByIdForInvoice: action.payload.response,
        loading: false,
      };

      break;
    case JUTE_HESSIAN_INVOICE_HEADER:
      state = {
        ...state,
        JuteHassianInvoiceHeader: action.payload,
      };

      break;
    case CLEAR_JUTE_HESSIAN_INVOICE_HEADER:
      state = {
        ...state,
        JuteHassianInvoiceHeader: initialState.JuteHassianInvoiceHeader,
      };
      break;
    case JUTE_HESSIAN_INVOICE_LINE_ITEMS:
      state = {
        ...state,
        JuteHassianInvoiceLineitems: action.payload,
      };
      break;
    case CLEAR_JUTE_HESSIAN_INVOICE_LINE_ITEMS:
      state = {
        ...state,
        JuteHassianInvoiceLineitems: [
          {
            id: 0,
            createdBy: null,
            companyId: null,
            regularSalesOrderId: "",
            regularSalesOrderLineItemId: "",
            hsnCode: "",
            itemGroupId: null,
            itemGroupValue: "",
            itemId: null,
            hsncode: "",
            itemMake: "",
            uom: "",
            doBalQty: "",
            igstPercent: "",
            cgstAmount: "",
            cgstPercent: "",
            sgstAmount: "",
            sgstPercent: "",
            discountType: "",
            discount: "",
            balesPacketSlNo: "",
            isActive: 1,
            hessianIsActive: 1,
            itemGroupName: "",
            itemName: "",
            itemCode: "",
            itemValue: "",
            createdDate: "",
            cessAmount: null,
            currency: "INR",
            deliveryOrderLineItemId: "",
            salesRegularInvoiceLineitemId: null,
            companyName: "",
            salesId: null,
            salesLineItemId: null,
            regularDeliveryOrderID: "",
            regularDeliveryOrderLineItemID: "",
            quantity: "0.000",
            rate: "0.000",
            invoiceQty: null,
            igstAmount: "",
            discountName: "",
            discountedRate: "0.000",
            discountAmount: "0.00",
            netAmount: "",
            totalAmount: "0.00",
            remarks: "",
            taxPercent: "0.00",
            status: 1,
            updatedBy: null,
            updatedOn: null,
            regInvoiceId: null,
            ItemNameData: [],
            hessianQuantityCalculatedValue: "0.000",
            hessianBalesPacketSlNo: null,
            hessianDeliveryOrderId: null,
            hessianDeliveryOrderLineItemId: null,
            hessianQuantityUomId: null,
            hessianQuantityRelationValue: "",
            hessianRateUom: "",
            hessianRateUomId: null,
            hessianRateRelationValue: "",
            hessianRateCalculatedValue: "",
            hessianRateIncludingBrokerage: "",
            hessianRateExcludingBrokerage: "0.00",
            hessianUpdatedBy: null,
            hessianUpdatedOn: "",
            hessianQuantityUom: null,
          },
        ],
      };
      break;

    case DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELIVERY_ORDER_VIEW_BYID_FOR_JUTE_HESSIAN_INVOICE_SUCCESSFULL:
      state = {
        ...state,
        DoViewByIdForInvoice: action.payload.response,
        loading: false,
      };
      break;
    case CREATE_JUTE_HESSIAN_INVOICE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_JUTE_HESSIAN_INVOICE_SUCCESSFULL:
      state = {
        ...state,
        CreateJuteHessianInvoiceRecord: action.payload.response,
        loading: false,
      };
      break;
    case JUTE_HESSIAN_INVOICE_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTE_HESSIAN_INVOICE_VIEW_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        JuteHessianInvoiceViewById: action.payload.response,
        loading: false,
      };
      break;

    // case GET_VEHICLE_TYPE:
    //   state = {
    //     ...state,
    //     loading: true,
    //   };
    //   break;
    // case GET_VEHICLE_TYPE_SUCCESSFULL:
    //   state = {
    //     ...state,
    //     vehicleType: action.payload.response.data,
    //     loading: true,
    //   };
    //   break;
    case INVOICE_JUTE_YARN_GET_UOM_RELATION_VALUE:
      state = {
        ...state,
        getUomByCode: action.payload.response,
        loading: true,
        dataLoaded: false,
      };
      break;

    case INVOICE_JUTE_YARN_GET_UOM_RELATION_VALUE_SUCCESSFULL:
      state = {
        ...state,
        InvoiceJuteYarngetUomRelation: action.payload.response,
        loading: false,
        dataLoaded: true,
      };
      break;
    case API_ERRORS:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;
    //upload documents
    case UPLOAD_INVOICE_FILES:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPLOAD_INVOICE_FILES_SUCCESSFULL:
      state = {
        ...state,
        Uploaded_invoice_Files: action.payload.response,
        loading: false,
      };
      break;
    case DELETE_INVOICE_FILE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_INVOICE_FILE_SUCCESSFULL:
      state = {
        ...state,
        delete_invoice_File: action.payload.response,
        loading: false,
      };
      break;
    case GET_INVOICE_FILES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case INVOICE_FILES_SUCCESSFULL:
      state = {
        ...state,
        InvoiceFiles: action.payload.response,
        loading: false,
      };
      break;
    case "UPDATE_INVOICE_FILES":
      state = {
        ...state,
        InvoiceFiles: {
          ...state.InvoiceFiles,
          data: { ...state.InvoiceFiles.data, data: action.payload },
        },
      };
    break;
    case GENERATE_E_INVOICE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GENERATE_E_INVOICE_SUCCESSFULL:
      state = {
        ...state,
        eInvoiceMesssage: action.payload.response,
        loading: false,
      };
      break;
    case CLEAR_E_INVOICE_MESSAGE:
      state = {
        ...state,
        eInvoiceMesssage: "",
        loading: false,
      };
      break;
    case CLEAR_E_WAY_BILL_MESSAGE:
      state = {
        ...state,
        eWayBillMessage: "",
        loading: false,
      };
      break;
    case GENERATE_E_WAY_BILL:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GENERATE_E_WAY_BILL_SUCCESSFULL:
      state = {
        ...state,
        eWayBillMessage: action.payload.response,
        loading: false,
      };
      break;
    case INVOICE_JUTE_YARN_UOM_LIST:
      state = {
        ...state,
        loading: true,
        dataLoaded: false,
      };
      break;
    case INVOICE_JUTE_YARN_UOM_LIST_SUCCESSFULL:
      state = {
        ...state,
        InvoiceJuteYarnUomListData: action.payload.response,
        loading: false,
        dataLoaded: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default InvoiceReducer;
