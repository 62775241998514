import React, { useState, useEffect } from "react";
import { Grid, Step, Stepper, StepLabel, Box } from "@mui/material";
import { Link } from "react-router-dom";
import CustomButton from "../../../components/Buttons/Buttons";
import DynamicSelect from "../../../components/Dropdown/Sales/InvoiceDynamicSelect";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { serverApi } from "../../../helpers/Consts";
import moment from "moment";
import swal from "sweetalert";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import RegularFillDetails from "./RegularInvoice/RegularFillDetails";
import RegularLineItems from "./RegularInvoice/RegularLineItems";
import RegularPreview from "./RegularInvoice/RegularPreview";
import RegularTransportation from "./RegularInvoice/RegularTransportation";
import {
  CreateRegularInvoice,
  getRegularInvoiceViewById,
  getJuteGovtInvoiceViewById,
  getJuteYarnInvoiceViewById,
  UpdateRegularInvoice,
  ClearRegularInvoiceHeader,
  ClearRegularInvoiceLineItems,
  getRegularInvoiceHeader,
  CreateJuteGovtInvoice,
  UpdateJuteGovtInvoice,
  CreateJuteHessionInvoive,
  ClearJuteHessianHeader,
  ClearJuteHessianInvoiceLineItems,
  getJuteHessianInvoiceViewById,
  CreateJuteYarnInvoice,
  UpdateJuteHessianInvoice,
  UpdateJuteYarnInvoice,
  generateEInvoice,
  generateEwayBill,
  getInvoiceFiles,
  clearInvoiceMessage,
  clearEwayBillMessage
} from "../../../store/Sales/InVoice/actions";

import JuteYarnFillDetails from "./JuteYarnInvoice/JuteYarnFillDetails";
import JuteYarnLineItems from "./JuteYarnInvoice/JuteYarnLineItems";
import JuteYarnTransportation from "./JuteYarnInvoice/JuteYarnTransportation";
import JuteYarnPreview from "./JuteYarnInvoice/JuteYarnPreview";
import { isEmpty, isEmptyWithZero } from "../../../Utilities/helper";
import { limitDecimals } from "../../../Utilities/helper";
import JuteGovtFillDetails from "./JuteGovernmentInvoice/JuteGovtFillDetails";
import JuteGovtLineItems from "./JuteGovernmentInvoice/JuteGovtLineItems";
import JuteGovtTrasportation from "./JuteGovernmentInvoice/JuteGovtTransportation";
import JuteGovtPreview from "./JuteGovernmentInvoice/JuteGovtPreview";
import JuteHessianInvoceFillDetails from "./JuteHessianInvoiceFillDetails";
import JuteHessianInvoiceLineItems from "./JuteHessianInvoiceLineItems";
import JuteHessianInvoiceTransportation from "./JuteHessianInvoiceTransportation";
import JuteHessianInvoicePreview from "./JuteHessianInvoicePreview";
import GstFormPopup from "../../../components/FormlPopup/gstFormPopup";

const CreateInvoice = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();

  const [steps] = useState([
    {
      title: "Select Option",
      icon: "http://vowerp.com/MailImages/assets/stepperSelection-00.png",
      activeIcon:
        "http://vowerp.com/MailImages/assets/stepperSelectionActive-00.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Fill Details",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Add Items",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Terms",
      icon: "http://vowerp.com/MailImages/assets/billing_inactive.png",
      activeIcon: "http://vowerp.com/MailImages/assets/billing_active.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Preview",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive03.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
  ]);

  const [currentStep, setcurrentStep] = useState(1);
  const [source, setsource] = useState("");
  const [alignment, setalignment] = useState("left");
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [invoiceType, setinvoiceType] = useState("");
  const [invoiceData, setInvoiceData] = useState([]);
  const [TodayDate, setTodayDate] = useState(moment().format());
  const [InvoiceTypeSelection, setInvoiceTypeSelection] = useState(true);
  const [InvoiceMethod, setInvoiceMethod] = useState(false);
  const [SelectInvoiceType, setSelectInvoiceType] = useState(false);
  const [SelectInvoiceMethod, setSelectInvoiceMethod] = useState(false);
  const [SalesOrderMandatory, setSalesOrderMandatory] = useState(false);
  const [DeliveryOrderMandatory, setDeliveryOrderMandatory] = useState(false);
  const [createWithSalesOrder, setcreateWithSalesOrder] = useState(false);
  const [createWithDirectInvoice, setcreateWithDirectInvoice] = useState(true);
  const [locationState] = useState(useLocation());
  const [invoiceMandatory, setInvoiceMandatory] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [ewayOrEinvoice, setEwayorEInvoice] = useState("")

  const {
    RegularInvoiceLineItems,
    RegularInvoiceHeader,
    JuteGovtInvoiceLineItems,
    juteGovtInvoiceHeader,
    JuteHassianInvoiceLineitems,
    JuteHassianInvoiceHeader,

    JuteYarnInvoiceHeader,
    JuteYarnInvoiceLineItems,
    eInvoiceMesssage,
    eWayBillMessage
  } = useSelector((state) => state.InvoiceReducer);

  useEffect(() => {
    setPopupOpen(false);
    if (eInvoiceMesssage?.data?.message === "Invalid login credentials") {
      setPopupOpen(true);
    }
    if (
      eInvoiceMesssage?.data?.message ===
      "Incorrect user id/User does not exists"
    ) {
      setPopupOpen(true);
    }
    if (eInvoiceMesssage?.data?.message === "Invalid Token") {
      setPopupOpen(true);
    }
    if (eInvoiceMesssage?.data?.message === "Invalid Token") {
      setPopupOpen(true);
    } if(eInvoiceMesssage?.data?.message === "Invalid Username or Password") {
      setPopupOpen(true);
    }
  }, [eInvoiceMesssage]);

  useEffect(() => {
    setPopupOpen(false);
    if (eWayBillMessage?.data?.message === "Invalid login credentials") {
      setPopupOpen(true);
    }
    if (
      eWayBillMessage?.data?.message ===
      "Incorrect user id/User does not exists"
    ) {
      setPopupOpen(true);
    }
    if (eWayBillMessage?.data?.message === "Invalid Token") {
      setPopupOpen(true);
    }
    if(eWayBillMessage?.data?.message === "Invalid Username or Password") {
      setPopupOpen(true);
    }
  }, [eWayBillMessage]);

  useEffect(() => {
    const configList = JSON.parse(
      localStorage.getItem("Configuration")
    ).configList;

    if (configList) {
      if (configList.length !== 0) {
        const lastIndex = configList
          .slice()
          .reverse()
          .findIndex((item) => item.configParameter === "eway_bill_einvoice");
        // If there's an element with configParameter equal to 'eway_bill_einvoice'
        if (lastIndex !== -1) {
          const originalIndex = configList.length - 1 - lastIndex;
          const lastEwayBillData = configList[originalIndex].configValue;
          const isQuatationOne =
            JSON.parse(lastEwayBillData)[0].quatation === 1;
          console.log(isQuatationOne, "isQuatationOne");
          if (isQuatationOne) {
            setInvoiceMandatory(true);
          } else {
            setInvoiceMandatory(false);
          }
        }
      }
    }
  }, [localStorage.getItem("Configuration")]);

  const QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;
    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };

  const handleChange = (selectedValue, selectedName, name) => {
    if (name == "invoiceType") {
      setinvoiceType(selectedValue);
      setSelectInvoiceType(true);
    }
  };
  const ClickNext = () => {
    if (invoiceType == 1) {
      if (SalesOrderMandatory == true) {
        setInvoiceTypeSelection(false);
        setInvoiceMethod(true);
      } else {
        setcurrentStep(currentStep + 1);
      }
    } else if (invoiceType == 4) {
      setcurrentStep(currentStep + 1);
    } else {
      setcurrentStep(currentStep + 1);
    }
  };
  const handleClickNext = () => {
    if (SelectInvoiceMethod == true) {
      setcurrentStep(currentStep + 1);
    } else {
      swal("please select the Invoice Method");
    }
  };
  const handleClickBefore = () => {
    setInvoiceTypeSelection(true);
    setInvoiceMethod(false);
  };
  const onClickBefore = () => {
    if (currentStep === 2) {
      let url = locationState.pathname === "/create_invoice" ? "/invoice" : "";
      props.history.push(url);
    } else {
      setcurrentStep(currentStep - 1);
    }
    if (currentStep == 2) {
      setcurrentStep(currentStep - 1);
      dispatch(ClearRegularInvoiceHeader());
      dispatch(ClearRegularInvoiceLineItems());
      dispatch(ClearJuteHessianHeader());
      dispatch(ClearJuteHessianInvoiceLineItems());
      setSelectInvoiceMethod(false);
      setalignment("left");
    } else {
      setcurrentStep(currentStep - 1);
    }
  };

  const closePopup = () => {
    setPopupOpen(false);
    dispatch(clearInvoiceMessage())
    dispatch(clearEwayBillMessage())
  };

  const onClickNext = () => {
    if (currentStep == 2) {
      if (invoiceType === 1) {
        if (
          SalesOrderMandatory == true &&
          RegularInvoiceHeader.createWithDirectInvoice == true
        ) {
          if (isEmpty(RegularInvoiceHeader.salesOrderId)) {
            swal("Please Select the Sales Order");
          } else if (DeliveryOrderMandatory == true) {
            if (isEmpty(RegularInvoiceHeader.deliveryOrderId)) {
              swal("Please Select the Delivery Order");
            } else {
              RegularMandatoryCheck();
            }
          } else {
            RegularMandatoryCheck();
          }
        } else if (
          DeliveryOrderMandatory == true &&
          RegularInvoiceHeader.createWithDirectInvoice == true
        ) {
          if (isEmpty(RegularInvoiceHeader.deliveryOrderId)) {
            swal("Please Select the Delivery Order");
          } else {
            RegularMandatoryCheck();
          }
        } else {
          RegularMandatoryCheck();
        }
      } else if (invoiceType === 2) {
        if (isEmpty(juteGovtInvoiceHeader.regularSalesOrderId)) {
          swal("Please Select the Sales Order");
        } else if (isEmpty(juteGovtInvoiceHeader.deliveryOrderId)) {
          swal("Please Select the Delivery Order");
        } else if (isEmpty(juteGovtInvoiceHeader.salesOrderDate)) {
          swal("Please Enter Sales Order Date");
        } else if (isEmpty(juteGovtInvoiceHeader.deliveryDate)) {
          swal("Please Enter Delivery Order Date");
        } else if (isEmpty(juteGovtInvoiceHeader.invoiceDate)) {
          swal("Please Select the Invoice Date");
        } else if (isEmpty(juteGovtInvoiceHeader.branchId)) {
          swal("Please Select the Branch");
        } else if (isEmpty(juteGovtInvoiceHeader.customerId)) {
          swal("Please Select the Consignee");
        } else if (isEmpty(juteGovtInvoiceHeader.billingFrom)) {
          swal("Please Select the bill From");
        } else if (isEmpty(juteGovtInvoiceHeader.shippingFrom)) {
          swal("Please Select the ship From");
        } else if (isEmpty(juteGovtInvoiceHeader.billingTo)) {
          swal("Please Select the billing Address");
        } else if (isEmpty(juteGovtInvoiceHeader.shippingTo)) {
          swal("Please Select the shipping Address");
        } else if (isEmpty(juteGovtInvoiceHeader.taxType)) {
          swal("Please Select the Tax Type");
        } else if (isEmpty(juteGovtInvoiceHeader.bankId)) {
          swal("Please Select the Bank Name");
        } 
        else if (isEmpty(juteGovtInvoiceHeader.transporterId)) {
          swal("Please Select the transporterId");
        } else if (isEmpty(juteGovtInvoiceHeader.deliveryTerms)) {
          swal("Please Select the Delivery Terms");
        } else {
          setcurrentStep(currentStep + 1);
        }
      } else if (invoiceType === 3) {
        if (isEmpty(JuteYarnInvoiceHeader.regularSalesOrderId)) {
          swal("Please Select the Sales Order");
        } else if (isEmpty(JuteYarnInvoiceHeader.deliveryOrderId)) {
          swal("Please Select the Delivery Order");
        } else if (isEmpty(JuteYarnInvoiceHeader.salesOrderDate)) {
          swal("Please Enter Sales Order Date");
        } else if (isEmpty(JuteYarnInvoiceHeader.deliveryDate)) {
          swal("Please Enter Delivery Order Date");
        } else if (isEmpty(JuteYarnInvoiceHeader.invoiceDate)) {
          swal("Please Select the Invoice Date");
        } else if (isEmpty(JuteYarnInvoiceHeader.branchId)) {
          swal("Please Select the Branch");
        } else if (isEmpty(JuteYarnInvoiceHeader.customerId)) {
          swal("Please Select the Consignee");
        } else if (isEmpty(JuteYarnInvoiceHeader.billingFrom)) {
          swal("Please Select the bill From");
        } else if (isEmpty(JuteYarnInvoiceHeader.shippingFrom)) {
          swal("Please Select the ship From");
        } else if (isEmpty(JuteYarnInvoiceHeader.billingTo)) {
          swal("Please Select the billing Address");
        } else if (isEmpty(JuteYarnInvoiceHeader.shippingTo)) {
          swal("Please Select the shipping Address");
        } else if (isEmpty(JuteYarnInvoiceHeader.taxType)) {
          swal("Please Select the Tax Type");
        } else if (isEmpty(JuteYarnInvoiceHeader.bankId)) {
          swal("Please Select the Bank Name");
        } else if (isEmpty(JuteYarnInvoiceHeader.transporterId)) {
          swal("Please Select the Transporter Name");
        } else if (isEmpty(JuteYarnInvoiceHeader.deliveryTerms)) {
          swal("Please Select the Delivery Terms");
        } else {
          setcurrentStep(currentStep + 1);
        }
      } else if (invoiceType === 4) {
        if (isEmpty(JuteHassianInvoiceHeader.salesOrderId)) {
          swal("Please Select the Sales Order");
        } else if (isEmpty(JuteHassianInvoiceHeader.deliveryOrderId)) {
          swal("Please Select the Delivery Order");
        } else if (isEmpty(JuteHassianInvoiceHeader.billingFrom)) {
          swal("Please Select the bill From");
        } else if (isEmpty(JuteHassianInvoiceHeader.shippingFrom)) {
          swal("Please Select the ship From");
        } else if (isEmpty(JuteHassianInvoiceHeader.invoiceDate)) {
          swal("Please Enter The Invoice Date");
        } else if (isEmpty(JuteHassianInvoiceHeader.deliveryTerms)) {
          swal("Please Select the Delivery Terms");
        } else {
          setcurrentStep(currentStep + 1);
        }
      }
    } else if (currentStep == 3) {
      if (invoiceType === 1) {
        let ValidateFields = false;
        let FilteredDatas = RegularInvoiceLineItems.filter(
          (item) => item.isActive !== 0
        );

        if (FilteredDatas.length === 1) {
          FilteredDatas.map((item, index) => {
            if (item.itemGroupId === "") {
              swal("Please select the ItemGroup");
              return false;
            } else if (item.itemId == null) {
              swal("Please select the Item");
              return false;
            } else if (Number(item.quantity) <= 0) {
              swal("Please Enter the Quantity");
              return false;
            } else if (Number(item.rate) <= 0) {
              swal("Please Enter the Rate");
              return false;
            } else if (isEmptyWithZero(item.uom)) {
              swal("Please Enter the uom");
              return false;
            } else {
              ValidateFields = true;
            }
          });
        } else {
          let FilteredData = RegularInvoiceLineItems.filter(
            (item) => item.isActive !== 0 && item.itemGroupId !== ""
          );
          for (var i = 0; i <= FilteredData.length - 1; i++) {
            if (FilteredData[i].itemGroupId === "") {
              swal("Please select the ItemGroup");
              return false;
            } else if (FilteredData[i].itemId == null) {
              swal("Please select the Item");
              return false;
            } else if (Number(FilteredData[i].quantity) <= 0) {
              swal("Please Enter the Quantity");
              return false;
            } else if (isEmptyWithZero(FilteredData[i].rate)) {
              swal("Please Enter the Rate");
              return false;
            } else if (isEmptyWithZero(FilteredData[i].uom)) {
              swal("Please Enter the uom");
              return false;
            } else {
              ValidateFields = true;
            }
          }
        }
        if (ValidateFields === true) {
          setcurrentStep(currentStep + 1);
        }
      } else if (invoiceType === 2) {
        let ValidateFields = false;
        let FilteredDatas = JuteGovtInvoiceLineItems.filter(
          (item) => item.isActive !== 0
        );

        if (FilteredDatas.length === 1) {
          FilteredDatas.map((item, index) => {
            if (item.itemGroupId === "") {
              swal("Please select the ItemGroup");
              return false;
            } else if (item.itemId == null) {
              swal("Please select the Item");
              return false;
            } else if (isEmptyWithZero(item.rate)) {
              swal("Please Enter the Rate");
              return false;
            } else if (Number(item.quantity) <= 0) {
              swal("Please Enter the Quantity");
              return false;
            } else if (isEmptyWithZero(item.uom)) {
              swal("Please Enter the uom");
              return false;
            } else {
              ValidateFields = true;
            }
          });
        } else {
          let FilteredData = JuteGovtInvoiceLineItems.filter(
            (item) => item.isActive !== 0 && item.itemGroupId !== ""
          );
          for (var i = 0; i <= FilteredData.length - 1; i++) {
            if (FilteredData[i].itemGroupId === "") {
              swal("Please select the ItemGroup");
              return false;
            } else if (FilteredData[i].itemId == null) {
              swal("Please select the Item");
              return false;
            } else if (isEmptyWithZero(FilteredData[i].rate)) {
              swal("Please Enter the Rate");
              return false;
            } else if (Number(FilteredData[i].quantity) <= 0) {
              swal("Please Enter the Quantity");
              return false;
            } else if (isEmptyWithZero(FilteredData[i].uom)) {
              swal("Please Enter the uom");
              return false;
            } else {
              ValidateFields = true;
            }
          }
        }
        if (ValidateFields === true) {
          setcurrentStep(currentStep + 1);
        }
      } else if (invoiceType === 3) {
        let ValidateFields = false;
        let FilteredDatas = JuteYarnInvoiceLineItems.filter(
          (item) => item.isActive !== 0
        );

        if (FilteredDatas.length === 1) {
          FilteredDatas.map((item, index) => {
            if (item.itemGroupId === "") {
              swal("Please select the ItemGroup");
              return false;
            } else if (item.itemId == null) {
              swal("Please select the Item");
              return false;
            } else if (Number(item.quantity) <= 0) {
              swal("Please Enter the Quantity");
              return false;
            } else if (isEmpty(item.yarnQuantityUomId)) {
              swal("Please Select Variable UOM");
              return false;
            } else if (isEmpty(item.yarnQuantityUomRelationValue)) {
              swal("Please Enter Quantity of variable UOM");
              return false;
            } else if (isEmptyWithZero(item.uom)) {
              swal("Please Enter the uom");
              return false;
            } else if (isEmptyWithZero(item.rate)) {
              swal("Please Enter the Rate");
              return false;
            } else {
              ValidateFields = true;
            }
          });
        } else {
          let FilteredData = JuteYarnInvoiceLineItems.filter(
            (item) => item.isActive !== 0 && item.itemGroupId !== ""
          );
          for (var i = 0; i <= FilteredData.length - 1; i++) {
            if (FilteredData[i].itemGroupId === "") {
              swal("Please select the ItemGroup");
              return false;
            } else if (FilteredData[i].itemId == null) {
              swal("Please select the Item");
              return false;
            } else if (Number(FilteredData[i].quantity) <= 0) {
              swal("Please Enter the Quantity");
              return false;
            } else if (isEmpty(FilteredData[i].yarnQuantityUomId)) {
              swal("Please Select Variable UOM");
              return false;
            } else if (isEmpty(FilteredData[i].yarnQuantityUomRelationValue)) {
              swal("Please Enter Quantity of variable UOM");
              return false;
            } else if (isEmptyWithZero(FilteredData[i].uom)) {
              swal("Please Enter the uom");
              return false;
            } else if (isEmptyWithZero(FilteredData[i].rate)) {
              swal("Please Enter the Rate");
              return false;
            } else {
              ValidateFields = true;
            }
          }
        }
        if (ValidateFields === true) {
          setcurrentStep(currentStep + 1);
        }
      } else if (invoiceType === 4) {
        setcurrentStep(currentStep + 1);
      }
    } else if (currentStep == 4) {
      // setcurrentStep(currentStep + 1);
      if (invoiceType === 1) {
        if (RegularInvoiceHeader.transportationMode === "Road" && isEmpty(RegularInvoiceHeader.vehicleNo)) {
          swal("Please fill the Vehicle Number ");
        } else if(RegularInvoiceHeader.transportationMode === "Rail" && isEmpty(RegularInvoiceHeader.transporterDocNo)) {
          swal("Please fill the Transporter Document No.");
        }
        else {
          setcurrentStep(currentStep + 1)
        }
      } else if (invoiceType === 2) {
        if (juteGovtInvoiceHeader.transportationMode === "Road" && isEmpty(juteGovtInvoiceHeader.vehicleNumber)) {
          swal("Please fill the Vehicle Number ");
        } else if(juteGovtInvoiceHeader.transportationMode === "Rail" && isEmpty(juteGovtInvoiceHeader.transporterDocNo)) {
          swal("Please fill the Transporter Document No.");
        }
        else {
          setcurrentStep(currentStep + 1)
        }
      } else if (invoiceType === 3) {
        if (JuteYarnInvoiceHeader.transportationMode === "Road" && isEmpty(JuteYarnInvoiceHeader.vehicleNumber)) {
          swal("Please fill the Vehicle Number ");
        } else if(JuteYarnInvoiceHeader.transportationMode === "Rail" && isEmpty(JuteYarnInvoiceHeader.transporterDocNo)) {
          swal("Please fill the Transporter Document No.");
        }
        else {
          setcurrentStep(currentStep + 1)
        }
      } else if (invoiceType === 4) {
        if (JuteHassianInvoiceHeader.transportationMode === "Road" && isEmpty(JuteHassianInvoiceHeader.vehicleNo)) {
          swal("Please fill the Vehicle Number ");
        } else if(JuteHassianInvoiceHeader.transportationMode === "Rail" && isEmpty(JuteHassianInvoiceHeader.transporterDocNo)) {
          swal("Please fill the Transporter Document No.");
        }
        else {
          setcurrentStep(currentStep + 1)
        }
      }
      else {
        setcurrentStep(currentStep + 1);
      }
    } else {
      setcurrentStep(currentStep + 1);
    }
  };

  const RegularMandatoryCheck = () => {
    if (isEmpty(RegularInvoiceHeader.invoiceDate)) {
      swal("Please Enter invoice Date");
    } else if (
      DeliveryOrderMandatory == true &&
      RegularInvoiceHeader.createWithDirectInvoice == true &&
      isEmpty(RegularInvoiceHeader.deliveryOrderDate)
    ) {
      swal("Please Enter Delivery Order Date");
    } else if (isEmpty(RegularInvoiceHeader.branchId)) {
      swal("Please Select the Branch");
    } else if (isEmpty(RegularInvoiceHeader.customerId)) {
      swal("Please Select the Consignee");
    } else if (isEmpty(RegularInvoiceHeader.billingFrom)) {
      swal("Please Select the bill From");
    } else if (isEmpty(RegularInvoiceHeader.shippingFrom)) {
      swal("Please Select the ship From");
    } else if (isEmpty(RegularInvoiceHeader.billingTo)) {
      swal("Please Select the billing Address");
    } else if (isEmpty(RegularInvoiceHeader.shippingTo)) {
      swal("Please Select the shipping Address");
    } else if (isEmpty(RegularInvoiceHeader.taxType)) {
      swal("Please Select the Tax Type");
    } else if (isEmpty(RegularInvoiceHeader.bankId)) {
      swal("Please Select the Bank Name");
    } else if (
      RegularInvoiceHeader.brokerId !== "" &&
      RegularInvoiceHeader.brokerId !== 0 &&
      RegularInvoiceHeader.brokerId !== null
    ) {
      if (isEmptyWithZero(RegularInvoiceHeader.brokerCommisionPercent)) {
        swal("Please Enter the Broker Percentage");
      } else {
        let FilteredDatas = RegularInvoiceLineItems.filter(
          (item) => item.isActive !== 0
        );
        if (FilteredDatas.length == 0) {
          swal("Selected SO/DO should have atleast One lineitem");
        } else {
          setcurrentStep(currentStep + 1);
        }
      }
    } else {
      let FilteredDatas = RegularInvoiceLineItems.filter(
        (item) => item.isActive !== 0
      );
      if (FilteredDatas.length == 0) {
        swal("Selected SO/DO should have atleast One lineitem");
      } else {
        setcurrentStep(currentStep + 1);
      }
    }
  };
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("Configuration")).configList) {
      if (
        JSON.parse(localStorage.getItem("Configuration")).configList.length !==
        0
      ) {
        JSON.parse(localStorage.getItem("Configuration")).configList.map(
          (item) => {
            if (item.configParameter == "invoiceType") {
              if (JSON.parse(item.configValue)) {
                if (JSON.parse(item.configValue).length !== 0) {
                  var List = [];
                  JSON.parse(item.configValue).map((item) => {
                    if (item.juteGovt == 1) {
                      List.push({
                        value: 2,
                        label: "Jute Government",
                        name: "Jute Government",
                      });
                    }
                    if (item.juteHessian == 1) {
                      List.push({
                        value: 4,
                        label: "Jute Hessian",
                        name: "Jute Hessian",
                      });
                    }
                    if (item.juteYarn == 1) {
                      List.push({
                        value: 3,
                        label: "Jute Yarn",
                        name: "Jute yarn",
                      });
                    }
                    if (item.regInvoice == 1) {
                      List.push({
                        value: 1,
                        label: "Regular Invoice",
                        name: "Regular Invoice",
                      });
                    }
                    setInvoiceData(List);
                  });
                }
              }
            } else if (item.configParameter == "invoiceMandatory") {
              if (item.configValue) {
                if (JSON.parse(item.configValue)[0].salesorder == 1) {
                  setSalesOrderMandatory(true);
                  if (JSON.parse(item.configValue)[0].deliveryorder == 1) {
                    setDeliveryOrderMandatory(true);
                  }
                } else {
                  // setcreateWithDirectInvoice(false);
                  setDeliveryOrderMandatory(false);
                  const Header = {
                    ...RegularInvoiceHeader,
                    createWithDirectInvoice: false,
                  };
                  dispatch(getRegularInvoiceHeader(Header));
                }
              }
            }
          }
        );
      }
    }
  }, [localStorage.getItem("Configuration")]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const onClickCreate = () => {
    if (invoiceType == 1) {
      RegularInvoiceCreate();
    } else if (invoiceType == 2) {
      JuteGovtInvoiceCreate();
    } else if (invoiceType == 3) {
      JuteYarnInvoiceCreate();
    } else if (invoiceType == 4) {
      JuteHessianInvoiceCreate();
    }
  };

  const handleSubmit = (userId, password) => {
    const data = {
      eInvoiceUserName: userId,
      eInvoicePassword: password,
    };
    onGenerateEinvoiceWaybill(data, ewayOrEinvoice);
    dispatch(clearInvoiceMessage())
    dispatch(clearEwayBillMessage())
  };

  const data = {
    eInvoiceUserName: null,
    eInvoicePassword: null,
  };

  const onGenerateEinvoiceWaybill = (data, type) => {
    setEwayorEInvoice(type)
    if(type === "einvoice") {
      if (invoiceType === 1) {
        dispatch(
          generateEInvoice(
            serverApi.GENERATE_E_INVOICE + RegularInvoiceHeader.regInvoiceId,
            data,
            history
          )
        );
      } else if (invoiceType === 2) {
        dispatch(
          generateEInvoice(
            serverApi.GENERATE_E_INVOICE + juteGovtInvoiceHeader.regInvoiceId,
            data,
            history
          )
        );
      } else if (invoiceType === 3) {
        dispatch(
          generateEInvoice(
            serverApi.GENERATE_E_INVOICE + JuteYarnInvoiceHeader.regInvoiceId,
            data,
            history
          )
        );
      } else if (invoiceType === 4) {
        dispatch(
          generateEInvoice(
            serverApi.GENERATE_E_INVOICE + JuteHassianInvoiceHeader.regInvoiceId,
            data,
            history
          )
        );
      }
    } else {
      if (invoiceType === 1) {
        dispatch(
          generateEwayBill(
            serverApi.GENERATE_E_WAY_BILL + RegularInvoiceHeader.regInvoiceId,
            data,
            history
          )
        );
      } else if (invoiceType === 2) {
        dispatch(
          generateEwayBill(
            serverApi.GENERATE_E_WAY_BILL + juteGovtInvoiceHeader.regInvoiceId,
            data,
            history
          )
        );
      } else if (invoiceType === 3) {
        dispatch(
          generateEwayBill(
            serverApi.GENERATE_E_WAY_BILL + JuteYarnInvoiceHeader.regInvoiceId,
            data,
            history
          )
        );
      } else if (invoiceType === 4) {
        dispatch(
          generateEwayBill(
            serverApi.GENERATE_E_WAY_BILL + JuteHassianInvoiceHeader.regInvoiceId,
            data,
            history
          )
        );
      }
    }
  };


  const onClickApprove = (Status) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Approve",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willapprove) => {
      if (willapprove) {
        if (invoiceType == 1) {
          OnUpdate(Status);
        } else if (invoiceType == 2) {
          UpdateForSoJuteGovt(Status);
        } else if (invoiceType === 4) {
          UpdateForJuteHessian(Status);
        } else if (invoiceType === 3) {
          UpdateForSoJuteYarn(Status);
        }
      }
    });
  };

  const onClickRejected = (Status) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Reject",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReject) => {
      if (willReject) {
        if (invoiceType == 1) {
          OnUpdate(Status);
        } else if (invoiceType == 2) {
          UpdateForSoJuteGovt(Status);
        } else if (invoiceType === 4) {
          UpdateForJuteHessian(Status);
        } else if (invoiceType === 3) {
          UpdateForSoJuteYarn(Status);
        }
      }
    });
  };

  const RegularInvoiceCreate = () => {
    var LineItemDTO = [];
    var FilteredData = RegularInvoiceLineItems.filter(
      (item) =>
        item.isActive !== 0 &&
        item.itemGroupId !== null &&
        item.itemGroupId !== ""
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemDTO.push({
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          createdDate: TodayDate.split("T", 2)[0],
          companyId: localStorage.getItem("companyId"),
          salesId: item.salesId,
          salesLineItemId: item.salesLineItemId,
          hsncode: item.hsncode,
          itemGroupId: item.itemGroupId,
          itemGroupValue: item.itemGroupValue,
          itemId: item.itemId,
          itemMake: item.itemMake,
          quantity: limitDecimals(item.quantity, 3),
          uom: item.uom,
          rate: limitDecimals(item.rate, 3),
          doBalQty: item.doBalQty,
          invoiceQty: item.invoiceQty,
          igstAmount: limitDecimals(item.igstAmount, 2),
          igstPercent: limitDecimals(item.igstPercent, 2),
          cgstAmount: limitDecimals(item.cgstAmount, 2),
          cgstPercent: limitDecimals(item.cgstPercent, 2),
          sgstAmount: limitDecimals(item.sgstAmount, 2),
          sgstPercent: limitDecimals(item.sgstPercent, 2),
          discountType: item.discountType,
          discount: limitDecimals(item.discount, 2),
          discountedRate: limitDecimals(item.discountedRate, 3),
          discountAmount: limitDecimals(item.discountAmount, 2),
          netAmount: item.netAmount,
          totalAmount: limitDecimals(item.totalAmount, 2),
          remarks: item.remarks,
          balesPacketSlNo: item.balesPacketSlNo,
          status: item.status,
          regularDeliveryOrderID: item.regularDeliveryOrderID,
          regularDeliveryOrderLineItemID: item.regularDeliveryOrderLineItemID,
        });
      });
    }
    const data = {
      deliveryOrderId: RegularInvoiceHeader.deliveryOrderId,
      finYear: localStorage.getItem("acadamicYear"),
      createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
      createdDate: TodayDate.split("T", 2)[0],
      invoiceDate: RegularInvoiceHeader.invoiceDate,
      invoiceType: 1,
      companyId: localStorage.getItem("companyId"),
      branchId: RegularInvoiceHeader.branchId,
      billingFrom: RegularInvoiceHeader.billingFrom,
      shippingFrom: RegularInvoiceHeader.shippingFrom,
      billingFromAddress: RegularInvoiceHeader.billingFromAddress,
      shippingFromAddress: RegularInvoiceHeader.shippingFromAddress,
      transporterNumber: RegularInvoiceHeader.transporterNumber,
      transporterDocNo: RegularInvoiceHeader.transporterDocNo,
      salesOrderId: RegularInvoiceHeader.salesOrderId,
      brokerId: RegularInvoiceHeader.brokerId,
      customerId: RegularInvoiceHeader.customerId,
      bankId: RegularInvoiceHeader.bankId,
      bankBranch: RegularInvoiceHeader.bankBranch,
      bankName: RegularInvoiceHeader.bankName,
      ifscCode: RegularInvoiceHeader.ifscCode,
      accountNo: RegularInvoiceHeader.accountNo,
      micrCode: RegularInvoiceHeader.micrCode,
      customerBranchId: RegularInvoiceHeader.customerBranchId,
      billingTo: RegularInvoiceHeader.billingTo,
      shippingTo: RegularInvoiceHeader.shippingTo,
      transporterId: RegularInvoiceHeader.transporterId,
      challanNo: RegularInvoiceHeader.challanNo,
      challanDate: RegularInvoiceHeader.challanDate,
      taxType: RegularInvoiceHeader.taxType,
      brokerCommision: RegularInvoiceHeader.brokerCommision,
      brokerCommissionPercent: RegularInvoiceHeader.brokerCommisionPercent,
      footerNote: RegularInvoiceHeader.footerNote,
      internalNote: RegularInvoiceHeader.internalNote,
      termsConditionType: RegularInvoiceHeader.termsConditionType,
      termsCondition: RegularInvoiceHeader.termsCondition,
      deliveryTerms: RegularInvoiceHeader.deliveryTerms,
      paymentTerms: RegularInvoiceHeader.paymentTerms,
      deliveryDays: RegularInvoiceHeader.deliveryDays,
      freightCharges: RegularInvoiceHeader.freightCharges,
      amountWithoutTax: limitDecimals(RegularInvoiceHeader.netTotal, 2),
      amountWithTax: limitDecimals(RegularInvoiceHeader.TotalAmount, 2),
      customerReferenceNumber: RegularInvoiceHeader.customerReferenceNumber,
      vehicleNumber: RegularInvoiceHeader.vehicleNo,
      transportationMode: RegularInvoiceHeader.transportationMode,
      vehicleType: RegularInvoiceHeader.vehicleType,
      transportationDistance: RegularInvoiceHeader.transportationDistance,
      updatedBy: "",
      updatedOn: "",
      approvedBy: "",
      approvedOn: "",
      statusId: 1,
      gstSuppType: "",
      gstInvoiceStatus: "",
      gstInvoiceCategory: "",
      gstInvoiceType: RegularInvoiceHeader.gstInvoiceType,
      gstAmtTaxable: "",
      gst_txpd_taxable_value: "",
      gstShippingBillNo: "",
      gstShippingBillDate: "",
      gstReason: "",
      gstr1ReturnPeriod: "",
      gstr3bReturnPeriod: "",
      reverseCharge: "",
      isAmended: "",
      amendedPos: "",
      amendedPeriod: "",
      roundOffValue: RegularInvoiceHeader.roundOffValue,
      roundingFlag: RegularInvoiceHeader.roundingFlag,
      poNumber: RegularInvoiceHeader.poNumber,
      regularInvoiceLineItemsDTO: LineItemDTO,
    };
    dispatch(
      CreateRegularInvoice(serverApi.CREATE_REGULAR_INVOICE, data, history)
    );
  };
  const OnUpdate = (status) => {
    var LineItemDTO = [];
    let FilteredData = RegularInvoiceLineItems.filter(item => { return !isEmpty(item.itemGroupId) && (item.isActive === 1 || !isEmpty(item.salesRegularInvoiceLineitemId))});
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemDTO.push({
          salesRegularInvoiceLineitemId: item.salesRegularInvoiceLineitemId,
          regInvoiceId: item.regInvoiceId,
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          createdDate: TodayDate.split("T", 2)[0],
          companyId: localStorage.getItem("companyId"),
          salesId: item.salesId,
          salesLineItemId: item.salesLineItemId,
          hsncode: item.hsncode,
          itemGroupId: item.itemGroupId,
          itemGroupValue: item.itemGroupValue,
          itemId: item.itemId,
          itemMake: item.itemMake,
          quantity: limitDecimals(item.quantity, 3),
          uom: item.uom,
          rate: limitDecimals(item.rate, 3),
          doBalQty: item.doBalQty,
          invoiceQty: item.invoiceQty,
          igstAmount: limitDecimals(item.igstAmount, 2),
          igstPercent: limitDecimals(item.igstPercent, 2),
          cgstAmount: limitDecimals(item.cgstAmount, 2),
          cgstPercent: limitDecimals(item.cgstPercent, 2),
          sgstAmount: limitDecimals(item.sgstAmount, 2),
          sgstPercent: limitDecimals(item.sgstPercent, 2),
          discountType: item.discountType,
          discount: limitDecimals(item.discount, 2),
          discountedRate: limitDecimals(item.discountedRate, 3),
          discountAmount: limitDecimals(item.discountAmount, 2),
          netAmount: item.netAmount,
          totalAmount: limitDecimals(item.totalAmount, 2),
          remarks: item.remarks,
          balesPacketSlNo: item.balesPacketSlNo,
          status: item.status,
          isActive: item.isActive,
          regularDeliveryOrderID: item.regularDeliveryOrderID,
          regularDeliveryOrderLineItemID: item.regularDeliveryOrderLineItemID,
        });
      });
    }
    const data = {
      deliveryOrderId: RegularInvoiceHeader.deliveryOrderId,
      finYear: localStorage.getItem("acadamicYear"),
      createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
      createdDate: TodayDate.split("T", 2)[0],
      invoiceDate: RegularInvoiceHeader.invoiceDate,
      invoiceType: 1,
      companyId: localStorage.getItem("companyId"),
      branchId: RegularInvoiceHeader.branchId,
      billingFrom: RegularInvoiceHeader.billingFrom,
      shippingFrom: RegularInvoiceHeader.shippingFrom,
      billingFromAddress: RegularInvoiceHeader.billingFromAddress,
      shippingFromAddress: RegularInvoiceHeader.shippingFromAddress,
      transporterNumber: RegularInvoiceHeader.transporterNumber,
      transporterDocNo: RegularInvoiceHeader.transporterDocNo,
      salesOrderId: RegularInvoiceHeader.salesOrderId,
      brokerId: RegularInvoiceHeader.brokerId,
      customerId: RegularInvoiceHeader.customerId,
      customerBranchId: RegularInvoiceHeader.customerBranchId,
      bankId: RegularInvoiceHeader.bankId,
      bankBranch: RegularInvoiceHeader.bankBranch,
      bankName: RegularInvoiceHeader.bankName,
      ifscCode: RegularInvoiceHeader.ifscCode,
      accountNo: RegularInvoiceHeader.accountNo,
      micrCode: RegularInvoiceHeader.micrCode,
      billingTo: RegularInvoiceHeader.billingTo,
      shippingTo: RegularInvoiceHeader.shippingTo,
      transporterId: RegularInvoiceHeader.transporterId,
      transportationMode: RegularInvoiceHeader.transportationMode,
      vehicleType: RegularInvoiceHeader.vehicleType,
      transportationDistance: RegularInvoiceHeader.transportationDistance,
      challanNo: RegularInvoiceHeader.challanNo,
      challanDate: RegularInvoiceHeader.challanDate,
      taxType: RegularInvoiceHeader.taxType,
      brokerCommision: RegularInvoiceHeader.brokerCommision,
      brokerCommissionPercent: RegularInvoiceHeader.brokerCommisionPercent,
      footerNote: RegularInvoiceHeader.footerNote,
      internalNote: RegularInvoiceHeader.internalNote,
      termsConditionType: RegularInvoiceHeader.termsConditionType,
      termsCondition: RegularInvoiceHeader.termsCondition,
      deliveryTerms: RegularInvoiceHeader.deliveryTerms,
      paymentTerms: RegularInvoiceHeader.paymentTerms,
      deliveryDays: RegularInvoiceHeader.deliveryDays,
      freightCharges: RegularInvoiceHeader.freightCharges,
      amountWithoutTax: limitDecimals(RegularInvoiceHeader.netTotal, 2),
      amountWithTax: limitDecimals(RegularInvoiceHeader.TotalAmount, 2),
      approvedBy: "",
      approvedOn: "",
      gstSuppType: "",
      gstInvoiceStatus: "",
      gstInvoiceCategory: "",
      gstInvoiceType: RegularInvoiceHeader.gstInvoiceType,
      customerReferenceNumber: RegularInvoiceHeader.customerReferenceNumber,
      vehicleNumber: RegularInvoiceHeader.vehicleNo,
      gstAmtTaxable: "",
      gst_txpd_taxable_value: "",
      gstShippingBillNo: "",
      gstShippingBillDate: "",
      gstReason: "",
      gstr1ReturnPeriod: "",
      gstr3bReturnPeriod: "",
      reverseCharge: "",
      isAmended: "",
      amendedPos: "",
      amendedPeriod: "",
      regularInvoiceLineItemsDTO: LineItemDTO,
      regInvoiceId: RegularInvoiceHeader.regInvoiceId,
      invoiceNo: RegularInvoiceHeader.invoiceNo,
      updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
      updatedOn: TodayDate.split("T", 2)[0],
      status: status,
      poNumber: RegularInvoiceHeader.poNumber,
      roundOffValue: RegularInvoiceHeader.roundOffValue,
      roundingFlag: RegularInvoiceHeader.roundingFlag,
    };
    dispatch(
      UpdateRegularInvoice(serverApi.UPDATE_REGULAR_INVOICE, data, history)
    );
  };
  const JuteGovtInvoiceCreate = () => {
    var LineItemDTO = [];
    var FilteredData = JuteGovtInvoiceLineItems.filter(
      (item) =>
        item.isActive !== 0 &&
        item.itemGroupId !== null &&
        item.itemGroupId !== ""
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemDTO.push({
          regularSalesOrderId: item.regularSalesOrderId,
          regularSalesOrderLineItemId: item.regularSalesOrderLineItemId,
          regularDeliveryOrderID: item.regularDeliveryOrderID,
          regularDeliveryOrderLineItemID: item.regularDeliveryOrderLineItemID,
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          createdDate: TodayDate.split("T", 2)[0],
          companyId: localStorage.getItem("companyId"),
          hsncode: item.hsncode,
          itemGroupId: item.itemGroupId,
          itemGroupValue: item.itemGroupValue,
          itemId: item.itemId,
          itemMake: item.itemMake,
          quantity: limitDecimals(item.quantity, 3),
          uom: item.uom,
          rate: limitDecimals(item.rate, 3),
          doBalQty: item.doBalQty,
          invoiceBalQty: item.invoiceQty,
          invoiceQty: item.invoiceQty,
          igstAmount: limitDecimals(item.igstAmount, 2),
          igstPercent: limitDecimals(item.igstPercent, 2),
          cgstAmount: limitDecimals(item.cgstAmount, 2),
          cgstPercent: limitDecimals(item.cgstPercent, 2),
          sgstAmount: limitDecimals(item.sgstAmount, 2),
          sgstPercent: limitDecimals(item.sgstPercent, 2),
          discountType: item.discountType,
          discount: limitDecimals(item.discount, 2),
          discountedRate: limitDecimals(item.discountedRate, 3),
          discountAmount: limitDecimals(item.discountAmount, 2),
          netAmount: item.netAmount,
          totalAmount: limitDecimals(item.totalAmount, 2),
          remarks: item.remarks,

          govtInvoiceRateUom: item.govtInvoiceRateUom,
          govtInvoiceRateUom2: item.govtInvoiceRateUom2,
          govtInvoiceRateUom3: item.govtInvoiceRateUom3,

          govtInvoiceQuantityUom: item.govtInvoiceQuantityUom,
          govtInvoiceQuantityUom2: item.govtInvoiceQuantityUom2,
          govtInvoiceQuantityUom3: item.govtInvoiceQuantityUom3,

          govtInvoiceRelationValue: item.govtInvoiceRelationValue,
          govtInvoiceRelationValue2: item.govtInvoiceRelationValue2,
          govtInvoiceRelationValue3: item.govtInvoiceRelationValue3,

          govtInvoiceQtyCalculatedValue: item.govtInvoiceQtyCalculatedValue,
          govtInvoiceQtyCalculatedValue2: item.govtInvoiceQtyCalculatedValue2,
          govtInvoiceQtyCalculatedValue3: item.govtInvoiceQtyCalculatedValue3,

          govtInvoiceRateCalculatedValue: item.govtInvoiceRateCalculatedValue,
          govtInvoiceRateCalculatedValue2: item.govtInvoiceRateCalculatedValue2,
          govtInvoiceRateCalculatedValue3: item.govtInvoiceRateCalculatedValue3,
          govtInvoiceIsActive: item.govtInvoiceIsActive,
          govtInvoiceBalesPacketSlNo: item.govtInvoiceBalesPacketSlNo,
        });
      });
    }
    const data = {
      invoiceHdrDto: {
        regularSalesOrderId: juteGovtInvoiceHeader.regularSalesOrderId,
        deliveryOrderId: juteGovtInvoiceHeader.deliveryOrderId,
        salesOrderDate: juteGovtInvoiceHeader.salesOrderDate,
        invoiceDate: juteGovtInvoiceHeader.invoiceDate,
        companyId: localStorage.getItem("companyId"),
        finYear: localStorage.getItem("acadamicYear"),
        createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
        createdDate: TodayDate.split("T", 2)[0],
        branchId: juteGovtInvoiceHeader.branchId,
        billingFrom: juteGovtInvoiceHeader.billingFrom,
        shippingFrom: juteGovtInvoiceHeader.shippingFrom,
        billingFromAddress: juteGovtInvoiceHeader.billingFromAddress,
        shippingFromAddress: juteGovtInvoiceHeader.shippingFromAddress,

        customerId: juteGovtInvoiceHeader.customerId,
        customerLineItemId: "",
        brokerId: juteGovtInvoiceHeader.brokerId,
        bankId: juteGovtInvoiceHeader.bankId,
        bankBranch: juteGovtInvoiceHeader.bankBranch,
        bankName: juteGovtInvoiceHeader.bankName,
        ifscCode: juteGovtInvoiceHeader.ifscCode,
        accountNo: juteGovtInvoiceHeader.accountNo,
        micrCode: juteGovtInvoiceHeader.micrCode,
        billingTo: juteGovtInvoiceHeader.billingTo,
        shippingTo: juteGovtInvoiceHeader.shippingTo,
        salesOrderExpiryDate: "",
        challanNo: juteGovtInvoiceHeader.challanNo,
        challanDate: juteGovtInvoiceHeader.challanDate,
        taxType: juteGovtInvoiceHeader.taxType,
        brokerCommision: "",
        brokerCommisionPercent: "",
        footerNote: juteGovtInvoiceHeader.footerNote,
        internalNote: juteGovtInvoiceHeader.internalNote,
        termsConditionType: juteGovtInvoiceHeader.termsConditionType,
        termsCondition: juteGovtInvoiceHeader.termsCondition,
        transporterId: juteGovtInvoiceHeader.transporterId,
        vehicleNumber: juteGovtInvoiceHeader.vehicleNumber,

        deliveryTerms: juteGovtInvoiceHeader.deliveryTerms,
        paymentTerms: juteGovtInvoiceHeader.paymentTerms,
        deliveryDays: juteGovtInvoiceHeader.deliveryDays,
        freightCharges: juteGovtInvoiceHeader.freightCharges,
        amountWithoutTax: limitDecimals(juteGovtInvoiceHeader.netTotal, 2),
        amountWithTax: limitDecimals(juteGovtInvoiceHeader.TotalAmount, 2),
        updatedBy: "",
        updatedOn: "",
        approvedBy: "",
        approvedOn: "",
        statusId: 1,
        invoiceType: juteGovtInvoiceHeader.invoiceType,

        transportationMode: juteGovtInvoiceHeader.transportationMode,
        vehicleType: juteGovtInvoiceHeader.vehicleType,
        transportationDistance: juteGovtInvoiceHeader.transportationDistance,
        transporterNumber: juteGovtInvoiceHeader.transporterNumber,
        transporterDocNo: juteGovtInvoiceHeader.transporterDocNo,
        roundOffValue: juteGovtInvoiceHeader.roundOffValue,
        roundingFlag: juteGovtInvoiceHeader.roundingFlag,
        invoiceLineItemDto: LineItemDTO,
      },
      pcsoNo: juteGovtInvoiceHeader.pcsoNo,
      pcsoDate: juteGovtInvoiceHeader.pcsoDate,
      ammendmentNo: juteGovtInvoiceHeader.ammendmentNo,
      ammendmentDate: juteGovtInvoiceHeader.ammendmentDate,
      containerNo: juteGovtInvoiceHeader.containerNo,
      ammendmendNo2: "",
      ammendmendDate2: "",
      ammendmendNo3: "",
      ammendmendDate3: "",
      ammendmendNo4: "",
      ammendmendDate4: "",
      ammendmendNo5: "",
      ammendmendDate5: "",
      destinationRailHead: juteGovtInvoiceHeader.destinationRailHead,
      invoiceType: juteGovtInvoiceHeader.invoiceType,
      isActive: "1",
      loadingPoint: juteGovtInvoiceHeader.loadingPoint,
    };
    dispatch(
      CreateJuteGovtInvoice(serverApi.CREATE_JUTE_GOVT_INVOICE, data, history)
    );
  };
  const UpdateForSoJuteGovt = (status) => {
    var LineItemDTO = [];
    var FilteredData = JuteGovtInvoiceLineItems.filter(
      (item) => item.itemGroupId !== null && item.itemGroupId !== ""
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemDTO.push({
          salesRegularInvoiceLineitemId: item.regularInvoiceLineitemId,
          regInvoiceId: item.govtInvoiceId,
          createdBy: item.createdBy,
          createdDate: item.createdDate,
          companyId: item.companyId,
          regularSalesOrderId: item.regularSalesOrderId,
          regularSalesOrderLineItemId: item.regularSalesOrderLineItemId,
          regularDeliveryOrderID: item.regularDeliveryOrderID,
          regularDeliveryOrderLineItemID: item.regularDeliveryOrderLineItemID,
          hsncode: item.hsncode,
          itemGroupId: item.itemGroupId,
          itemGroupValue: item.itemGroupValue,
          itemId: item.itemId,
          itemMake: item.itemMake,
          quantity: limitDecimals(item.quantity, 3),
          uom: item.uom,
          rate: limitDecimals(item.rate, 2),
          doBalQty: item.doBalQty,
          invoiceQty: limitDecimals(item.invoiceQty, 2),
          igstAmount: limitDecimals(item.igstAmount, 2),
          igstPercent: limitDecimals(item.igstPercent, 2),
          cgstAmount: limitDecimals(item.cgstAmount, 2),
          cgstPercent: limitDecimals(item.cgstPercent, 2),
          sgstAmount: limitDecimals(item.sgstAmount, 2),
          sgstPercent: limitDecimals(item.sgstPercent, 2),
          discountType: limitDecimals(item.discountType, 2),
          discount: limitDecimals(item.discount, 2),
          discountedRate: limitDecimals(item.discountedRate, 3),
          discountAmount: limitDecimals(item.discountAmount, 2),
          netAmount: limitDecimals(item.netAmount, 2),
          totalAmount: limitDecimals(item.totalAmount, 2),
          remarks: item.remarks,
          balesPacketSlNo: item.balesPacketSlNo,
          status: item.status,
          govtInvoiceLineItemId: item.govtInvoiceLineItemId,

          govtInvoiceRateUom: item.govtInvoiceRateUom,
          govtInvoiceRateUom2: item.govtInvoiceRateUom2,
          govtInvoiceRateUom3: item.govtInvoiceRateUom3,

          govtInvoiceQuantityUom: item.govtInvoiceQuantityUom,
          govtInvoiceQuantityUom2: item.govtInvoiceQuantityUom2,
          govtInvoiceQuantityUom3: item.govtInvoiceQuantityUom3,

          govtInvoiceRelationValue: item.govtInvoiceRelationValue,
          govtInvoiceRelationValue2: item.govtInvoiceRelationValue2,
          govtInvoiceRelationValue3: item.govtInvoiceRelationValue3,

          govtInvoiceQtyCalculatedValue: item.govtInvoiceQtyCalculatedValue,
          govtInvoiceQtyCalculatedValue2: item.govtInvoiceQtyCalculatedValue2,
          govtInvoiceQtyCalculatedValue3: item.govtInvoiceQtyCalculatedValue3,

          govtInvoiceRateCalculatedValue: item.govtInvoiceRateCalculatedValue,
          govtInvoiceRateCalculatedValue2: item.govtInvoiceRateCalculatedValue2,
          govtInvoiceRateCalculatedValue3: item.govtInvoiceRateCalculatedValue3,

          govtInvoiceIsActive: item.govtInvoiceIsActive,
          govtInvoiceBalesPacketSlNo: item.govtInvoiceBalesPacketSlNo,
          isActive: item.isActive,
        });
      });
    }
    const Data = {
      invoiceHdrDto: {
        regularSalesOrderId: juteGovtInvoiceHeader.regularSalesOrderId,
        regInvoiceId: juteGovtInvoiceHeader.regularInvoiceId,
        deliveryOrderId: juteGovtInvoiceHeader.deliveryOrderId,
        invoiceNo: juteGovtInvoiceHeader.invoiceNo,
        finYear: localStorage.getItem("acadamicYear"),
        invoiceType: 2,
        createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
        createdDate: TodayDate.split("T", 2)[0],
        invoiceDate: juteGovtInvoiceHeader.invoiceDate,
        companyId: localStorage.getItem("companyId"),
        branchId: juteGovtInvoiceHeader.branchId,
        billingFrom: juteGovtInvoiceHeader.billingFrom,
        shippingFrom: juteGovtInvoiceHeader.shippingFrom,
        billingFromAddress: juteGovtInvoiceHeader.billingFromAddress,
        shippingFromAddress: juteGovtInvoiceHeader.shippingFromAddress,

        brokerId: juteGovtInvoiceHeader.brokerId,
        bankId: juteGovtInvoiceHeader.bankId,
        bankBranch: juteGovtInvoiceHeader.bankBranch,
        bankName: juteGovtInvoiceHeader.bankName,
        ifscCode: juteGovtInvoiceHeader.ifscCode,
        accountNo: juteGovtInvoiceHeader.accountNo,
        micrCode: juteGovtInvoiceHeader.micrCode,
        customerId: juteGovtInvoiceHeader.customerId,
        customerBranchId: juteGovtInvoiceHeader.customerBranchId,
        billingTo: juteGovtInvoiceHeader.billingTo,
        shippingTo: juteGovtInvoiceHeader.shippingTo,
        transporterId: juteGovtInvoiceHeader.transporterId,
        challanNo: juteGovtInvoiceHeader.challanNo,
        challanDate: juteGovtInvoiceHeader.challanDate,
        taxType: juteGovtInvoiceHeader.taxType,
        brokerCommision: juteGovtInvoiceHeader.brokerCommision,
        brokerCommisionPercent: juteGovtInvoiceHeader.brokerCommisionPercent,
        footerNote: juteGovtInvoiceHeader.footerNote,
        internalNote: juteGovtInvoiceHeader.internalNote,
        vehicleNumber: juteGovtInvoiceHeader.vehicleNumber,
        termsConditionType: juteGovtInvoiceHeader.termsConditionType,
        termsCondition: juteGovtInvoiceHeader.termsCondition,
        deliveryTerms: juteGovtInvoiceHeader.deliveryTerms,
        paymentTerms: juteGovtInvoiceHeader.paymentTerms,
        deliveryDays: juteGovtInvoiceHeader.deliveryDays,
        freightCharges: juteGovtInvoiceHeader.freightCharges,
        amountWithoutTax: limitDecimals(juteGovtInvoiceHeader.netTotal, 2),
        amountWithTax: limitDecimals(juteGovtInvoiceHeader.TotalAmount, 2),
        updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
        updatedOn: TodayDate.split("T", 2)[0],
        approvedBy: JSON.parse(localStorage.getItem("authUser")).userId,
        approvedOn: TodayDate.split("T", 2)[0],
        status: status,
        gstSuppType: juteGovtInvoiceHeader.gstSuppType,
        gstInvoiceStatus: juteGovtInvoiceHeader.gstInvoiceStatus,
        gstInvoiceCategory: juteGovtInvoiceHeader.gstInvoiceCategory,
        gstInvoiceType: juteGovtInvoiceHeader.gstInvoiceType,
        gstAmtTaxable: juteGovtInvoiceHeader.gstAmtTaxable,
        gst_txpd_taxable_value: juteGovtInvoiceHeader.gst_txpd_taxable_value,
        gstShippingBillNo: juteGovtInvoiceHeader.gstShippingBillNo,
        gstShippingBillDate: juteGovtInvoiceHeader.gstShippingBillDate,
        gstReason: juteGovtInvoiceHeader.gstReason,
        gstr1ReturnPeriod: juteGovtInvoiceHeader.gstr1ReturnPeriod,
        gstr3bReturnPeriod: juteGovtInvoiceHeader.gstr3bReturnPeriod,
        reverseCharge: juteGovtInvoiceHeader.reverseCharge,
        isAmended: juteGovtInvoiceHeader.isAmended,
        amendedPos: juteGovtInvoiceHeader.amendedPos,
        amendedPeriod: juteGovtInvoiceHeader.amendedPeriod,
        transportationMode: juteGovtInvoiceHeader.transportationMode,
        vehicleType: juteGovtInvoiceHeader.vehicleType,
        transporterNumber: juteGovtInvoiceHeader.transporterNumber,
        transporterDocNo: juteGovtInvoiceHeader.transporterDocNo,
        transportationDistance: juteGovtInvoiceHeader.transportationDistance,
        roundOffValue: juteGovtInvoiceHeader.roundOffValue,
        roundingFlag: juteGovtInvoiceHeader.roundingFlag,
        invoiceLineItemDto: LineItemDTO,
      },
      govtInvoiceId: juteGovtInvoiceHeader.govtInvoiceId,
      invoiceType: "2",
      pcsoNo: juteGovtInvoiceHeader.pcsoNo,
      pcsoDate: juteGovtInvoiceHeader.pcsoDate,
      loadingPoint: juteGovtInvoiceHeader.loadingPoint,
      destinationRailHead: juteGovtInvoiceHeader.destinationRailHead,
      ammendmentNo: juteGovtInvoiceHeader.ammendmentNo,
      ammendmentDate: juteGovtInvoiceHeader.ammendmentDate,
      containerNo: juteGovtInvoiceHeader.containerNo,
      ammendmentNo2: "",
      ammendmentDate2: "",
      ammendmentNo3: "",
      ammendmentDate3: "",
      ammendmentNo4: "",
      ammendmentDate4: "",
      ammendmentNo5: "",
      ammendmentDate5: "",
      updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
      updatedOn: TodayDate.split("T", 2)[0],
      isActive: juteGovtInvoiceHeader.isActive,
    };
    dispatch(
      UpdateJuteGovtInvoice(serverApi.UPDATE_JUTE_GOVT_INVOICE, Data, history)
    );
  };
  const JuteHessianInvoiceCreate = () => {
    var LineItemDTO = [];
    var FilteredData = JuteHassianInvoiceLineitems.filter(
      (item) =>
        item.isActive !== 0 &&
        item.itemGroupId !== null &&
        item.itemGroupId !== ""
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemDTO.push({
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          companyId: localStorage.getItem("companyId"),
          // createdDate: TodayDate.split("T", 2)[0],
          itemGroupId: item.itemGroupId,
          itemGroupValue: item.itemGroupValue,
          itemId: item.itemId,
          itemMake: item.itemMake,
          quantity: limitDecimals(item.quantity,3),
          uom: item.uom,
          rate: limitDecimals(item.rate, 3),
          doBalQty: limitDecimals(item.doBalQty, 3),
          invoiceQty: limitDecimals(item.invoiceQty, 3),
          igstAmount: limitDecimals(item.igstAmount, 2),
          igstPercent: limitDecimals(item.igstPercent, 2),
          cgstAmount: limitDecimals(item.cgstAmount, 2),
          cgstPercent: limitDecimals(item.cgstPercent, 2),
          sgstAmount: limitDecimals(item.sgstAmount, 2),
          sgstPercent: limitDecimals(item.sgstPercent, 2),
          hsncode: item.hsncode,
          discountType: item.discountType,
          discount: item.discount,
          discountedRate: limitDecimals(item.discountedRate, 3),
          netAmount: limitDecimals(item.netAmount, 2),
          totalAmount: limitDecimals(item.totalAmount, 2),
          remarks: item.remarks,
          balesPacketSlNo: item.balesPacketSlNo,
          status: item.status,
          isActive: item.isActive,
          hessianIsActive: item.hessianIsActive,
          regularSalesOrderId: item.regularSalesOrderId,
          regularSalesOrderLineItemId: item.regularSalesOrderLineItemId,
          regularDeliveryOrderID: item.regularDeliveryOrderID,
          regularDeliveryOrderLineItemID: item.regularDeliveryOrderLineItemID,
          hessianQuantityCalculatedValue: limitDecimals(
            item.hessianQuantityCalculatedValue,
            3
          ),
          hessianBalesPacketSlNo: item.hessianBalesPacketSlNo,
          hessianDeliveryOrderId: item.hessianDeliveryOrderId,
          hessianDeliveryOrderLineItemId: item.hessianDeliveryOrderLineItemId,
          hessianQuantityUom: item.hessianQuantityUom,
          hessianQuantityUomId: item.hessianQuantityUomId,
          hessianQuantityRelationValue: item.hessianQuantityRelationValue,
          hessianRateUom: item.hessianRateUom,
          hessianRateUomId: item.hessianRateUomId,
          hessianRateRelationValue: item.hessianRateRelationValue,
          hessianRateCalculatedValue: item.hessianRateCalculatedValue,
          hessianRateIncludingBrokerage: limitDecimals(
            item.hessianRateIncludingBrokerage,
            2
          ),
          hessianRateExcludingBrokerage: limitDecimals(
            item.hessianRateExcludingBrokerage,
            2
          ),
          hessianUpdatedBy: item.hessianUpdatedBy,
          hessianUpdatedOn: item.hessianUpdatedOn,
        });
      });
    }
    const data = {
      invoiceType: 4,
      loadingPoint: "",
      destinationRailHead: "BANGALORE",
      invoiceHdrDto: {
        deliveryOrderId: JuteHassianInvoiceHeader.deliveryOrderId,
        finYear: localStorage.getItem("acadamicYear"),
        createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
        invoiceType: JuteHassianInvoiceHeader.invoiceType,
        invoiceDate: JuteHassianInvoiceHeader.invoiceDate,
        companyId: localStorage.getItem("companyId"),
        invoiceNo: JuteHassianInvoiceHeader.invoiceNo,
        customerBranchId: "",
        // createdDate: TodayDate.split("T", 2)[0],
        bankId: JuteHassianInvoiceHeader.bankId,
        bankBranch: JuteHassianInvoiceHeader.bankBranch,
        bankName: JuteHassianInvoiceHeader.bankName,
        ifscCode: JuteHassianInvoiceHeader.ifscCode,
        accountNo: JuteHassianInvoiceHeader.accountNo,
        micrCode: JuteHassianInvoiceHeader.micrCode,
        branchId: JuteHassianInvoiceHeader.branch,
        billingFrom: JuteHassianInvoiceHeader.billingFrom,
        shippingFrom: JuteHassianInvoiceHeader.shippingFrom,
        billingFromAddress: JuteHassianInvoiceHeader.billingFromAddress,
        shippingFromAddress: JuteHassianInvoiceHeader.shippingFromAddress,
        transporterNumber: JuteHassianInvoiceHeader.transporterNumber,
        transporterDocNo: JuteHassianInvoiceHeader.transporterDocNo,
        brokerId: JuteHassianInvoiceHeader.brokerId,
        brokerCommissionPercent:
          JuteHassianInvoiceHeader.brokerCommisionPercent,
        customerId: JuteHassianInvoiceHeader.customerId,
        billingTo: JuteHassianInvoiceHeader.billingTo,
        shippingTo: JuteHassianInvoiceHeader.shippingTo,
        challanNo: JuteHassianInvoiceHeader.challanNo,
        challanDate: JuteHassianInvoiceHeader.challanDate,
        taxType: JuteHassianInvoiceHeader.taxType,
        brokerCommision: "",
        footerNote: JuteHassianInvoiceHeader.footerNote,
        internalNote: JuteHassianInvoiceHeader.internalNote,
        termsConditionType: JuteHassianInvoiceHeader.termsConditionType,
        termsCondition: JuteHassianInvoiceHeader.termsCondition,
        deliveryTerms: JuteHassianInvoiceHeader.deliveryTerms,
        paymentTerms: JuteHassianInvoiceHeader.paymentTerms,
        deliveryDays: JuteHassianInvoiceHeader.deliveryDays,
        deliveryDate: JuteHassianInvoiceHeader.deliveryDate,
        salesOrderDate: JuteHassianInvoiceHeader.salesOrderDate,
        freightCharges: JuteHassianInvoiceHeader.freightCharges,
        amountWithoutTax: limitDecimals(
          JuteHassianInvoiceHeader.amountWithoutTax,
          2
        ),
        amountWithTax: limitDecimals(JuteHassianInvoiceHeader.amountWithTax, 2),
        statusId: JuteHassianInvoiceHeader.statusId,
        gstSuppType: "",
        gstInvoiceStatus: "",
        gstInvoiceCategory: "",
        gstInvoiceType: "",
        gstAmtTaxable: "",
        gst_txpd_taxable_value: "",
        gstShippingBillNo: "",
        gstShippingBillDate: "",
        gstReason: "",
        gstr1ReturnPeriod: "",
        gstr3bReturnPeriod: "",
        reverseCharge: "",
        isAmended: "",
        amendedPos: "",
        amendedPeriod: "",
        transporterId: JuteHassianInvoiceHeader.transporterId,
        isActive: 1,
        invoiceLineItemDto: LineItemDTO,
        pcsoDate: JuteHassianInvoiceHeader.pcsoDate,
        pcsoNo: JuteHassianInvoiceHeader.pcsoNo,
        statusName: JuteHassianInvoiceHeader.statusName,
        status: JuteHassianInvoiceHeader.status,
        regularSalesOrderId: JuteHassianInvoiceHeader.salesOrderId,
        vehicleNumber: JuteHassianInvoiceHeader.vehicleNo,
        updatedBy: JuteHassianInvoiceHeader.updatedBy,
        updatedOn: JuteHassianInvoiceHeader.updatedOn,
        updatedByDesc: JuteHassianInvoiceHeader.updatedByDesc,
        transportationMode: JuteHassianInvoiceHeader.transportationMode,
        transportationDistance: JuteHassianInvoiceHeader.transportationDistance,
        vehicleType: JuteHassianInvoiceHeader.vehicleType
      },
    };
    dispatch(
      CreateJuteHessionInvoive(
        serverApi.CREATE_JUTE_HESSIAN_INVOICE,
        data,
        history
      )
    );
  };
  const UpdateForJuteHessian = (status) => {
    var LineItemDTO = [];
    var FilteredData = JuteHassianInvoiceLineitems.filter(
      (item) => item.itemGroupId !== null && item.itemGroupId !== ""
    );

    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemDTO.push({
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          companyId: localStorage.getItem("companyId"),
          createdDate: TodayDate.split("T", 2)[0],
          itemGroupId: item.itemGroupId,
          itemGroupValue: item.itemGroupValue,
          itemId: item.itemId,
          itemMake: item.itemMake,
          quantity: limitDecimals(item.quantity, 3),
          uom: item.uom,
          rate: limitDecimals(item.rate, 3),
          doBalQty: limitDecimals(item.doBalQty, 3),
          invoiceQty: limitDecimals(item.invoiceQty, 3),
          igstAmount: limitDecimals(item.igstAmount, 2),
          igstPercent: limitDecimals(item.igstPercent, 2),
          cgstAmount: limitDecimals(item.cgstAmount, 2),
          cgstPercent: limitDecimals(item.cgstPercent, 2),
          sgstAmount: limitDecimals(item.sgstAmount, 2),
          sgstPercent: limitDecimals(item.sgstPercent, 2),
          hsncode: item.hsncode,
          discountType: item.discountType,
          discount: item.discount,
          discountedRate: limitDecimals(item.discountedRate, 3),
          netAmount: limitDecimals(item.netAmount, 2),
          totalAmount: limitDecimals(item.totalAmount, 2),
          remarks: item.remarks,
          balesPacketSlNo: item.balesPacketSlNo,
          status: item.status,
          isActive: item.isActive,
          hessianIsActive: item.hessianIsActive,
          regularSalesOrderId: item.regularSalesOrderId,
          regularSalesOrderLineItemId: item.regularSalesOrderLineItemId,
          hessianQuantityCalculatedValue: limitDecimals(
            item.hessianQuantityCalculatedValue,
            3
          ),
          hessianBalesPacketSlNo: item.hessianBalesPacketSlNo,
          hessianDeliveryOrderId: item.hessianDeliveryOrderId,
          hessianDeliveryOrderLineItemId: item.hessianDeliveryOrderLineItemId,
          hessianQuantityUom: item.hessianQuantityUom,
          hessianQuantityUomId: item.hessianQuantityUomId,
          hessianQuantityRelationValue: item.hessianQuantityRelationValue,
          hessianRateUom: item.hessianRateUom,
          hessianRateUomId: item.hessianRateUomId,
          hessianRateRelationValue: item.hessianRateRelationValue,
          hessianRateCalculatedValue: item.hessianRateCalculatedValue,
          hessianRateIncludingBrokerage: limitDecimals(
            item.hessianRateIncludingBrokerage,
            2
          ),
          hessianRateExcludingBrokerage: limitDecimals(
            item.hessianRateExcludingBrokerage,
            2
          ),
          hessianUpdatedBy: item.hessianUpdatedBy,
          hessianUpdatedOn: item.hessianUpdatedOn,
          salesRegularInvoiceLineitemId: item.salesRegularInvoiceLineitemId,
          regInvoiceId: item.regInvoiceId,
          updatedBy: item.updatedBy,
          regularDeliveryOrderID: item.regularDeliveryOrderID,
          regularDeliveryOrderLineItemID: item.regularDeliveryOrderLineItemID,
          hessianInvoicelineitemId: item.hessianInvoicelineitemId,
          hessianAmountExcludingBrokerage: item.hessianAmountExcludingBrokerage,
        });
      });
    }
    const data = {
      invoiceType: 4,
      loadingPoint: JuteHassianInvoiceHeader.loadingPoint,
      destinationRailHead: JuteHassianInvoiceHeader.destinationRailHead,
      invoiceId: JuteHassianInvoiceHeader.invoiceId,
      vehicleNumber: JuteHassianInvoiceHeader.vehicleNo,
      updatedBy: JuteHassianInvoiceHeader.updatedBy,

      invoiceHdrDto: {
        regularSalesOrderId: JuteHassianInvoiceHeader.regularSalesOrderId,
        regInvoiceId: JuteHassianInvoiceHeader.regInvoiceId,
        deliveryOrderId: JuteHassianInvoiceHeader.deliveryOrderId,
        invoiceNo: JuteHassianInvoiceHeader.invoiceNo,
        finYear: localStorage.getItem("acadamicYear"),
        createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
        invoiceType: 4,
        companyId: localStorage.getItem("companyId"),
        customerBranchId: JuteHassianInvoiceHeader.customerBranchId,
        bankId: JuteHassianInvoiceHeader.bankId,
        bankBranch: JuteHassianInvoiceHeader.bankBranch,
        bankName: JuteHassianInvoiceHeader.bankName,
        ifscCode: JuteHassianInvoiceHeader.ifscCode,
        accountNo: JuteHassianInvoiceHeader.accountNo,
        micrCode: JuteHassianInvoiceHeader.micrCode,
        branchId: JuteHassianInvoiceHeader.branchId,
        billingFrom: JuteHassianInvoiceHeader.billingFrom,
        shippingFrom: JuteHassianInvoiceHeader.shippingFrom,
        billingFromAddress: JuteHassianInvoiceHeader.billingFromAddress,
        shippingFromAddress: JuteHassianInvoiceHeader.shippingFromAddress,
        transporterNumber: JuteHassianInvoiceHeader.transporterNumber,
        transporterDocNo: JuteHassianInvoiceHeader.transporterDocNo,
        brokerId: JuteHassianInvoiceHeader.brokerId,
        customerId: JuteHassianInvoiceHeader.customerId,
        billingTo: JuteHassianInvoiceHeader.billingTo,
        shippingTo: JuteHassianInvoiceHeader.shippingTo,
        challanNo: JuteHassianInvoiceHeader.challanNo,
        challanDate: JuteHassianInvoiceHeader.challanDate,
        brokerCommision: JuteHassianInvoiceHeader.brokerCommision,
        brokerCommissionPercent:
          JuteHassianInvoiceHeader.brokerCommisionPercent,
        footerNote: JuteHassianInvoiceHeader.footerNote,
        internalNote: JuteHassianInvoiceHeader.internalNote,
        termsConditionType: JuteHassianInvoiceHeader.termsConditionType,
        termsCondition: JuteHassianInvoiceHeader.termsCondition,
        deliveryTerms: JuteHassianInvoiceHeader.deliveryTerms,
        paymentTerms: JuteHassianInvoiceHeader.paymentTerms,
        deliveryDays: JuteHassianInvoiceHeader.deliveryDays,
        freightCharges: JuteHassianInvoiceHeader.freightCharges,
        amountWithoutTax: JuteHassianInvoiceHeader.amountWithoutTax,
        amountWithTax: JuteHassianInvoiceHeader.amountWithTax,
        gstSuppType: "",
        gstInvoiceStatus: "",
        gstInvoiceCategory: "",
        gstInvoiceType: "",
        gstAmtTaxable: "",
        gst_txpd_taxable_value: "",
        gstShippingBillNo: "",
        gstShippingBillDate: "",
        gstReason: "",
        gstr1ReturnPeriod: "",
        gstr3bReturnPeriod: "",
        reverseCharge: "",
        isAmended: "",
        amendedPos: "",
        amendedPeriod: "",
        transporterId: JuteHassianInvoiceHeader.transporterId,
        status: status,
        updatedBy: JuteHassianInvoiceHeader.updatedBy,
        isActive: JuteHassianInvoiceHeader.isActive,
        invoiceLineItemDto: LineItemDTO,
        invoiceDate: JuteHassianInvoiceHeader.invoiceDate,
        transportationMode: JuteHassianInvoiceHeader.transportationMode,
        transportationDistance: JuteHassianInvoiceHeader.transportationDistance,
        vehicleType: JuteHassianInvoiceHeader.vehicleType
      },
    };
    dispatch(
      UpdateJuteHessianInvoice(
        serverApi.UPDATE_JUTE_HESSIAN_INVOICE,
        data,
        history
      )
    );
  };
  const JuteYarnInvoiceCreate = () => {
    var LineItemDTO = [];
    var FilteredData = JuteYarnInvoiceLineItems.filter(
      (item) =>
        item.isActive !== 0 &&
        item.itemGroupId !== null &&
        item.itemGroupId !== ""
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemDTO.push({
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          createdDate: TodayDate.split("T", 2)[0],
          companyId: localStorage.getItem("companyId"),
          regularSalesOrderId: item.regularSalesOrderId,
          regularSalesOrderLineItemId: item.regularSalesOrderLineItemId,
          regularDeliveryOrderID: item.regularDeliveryOrderID,
          regularDeliveryOrderLineItemID: item.regularDeliveryOrderLineItemID,
          hsncode: item.hsncode,
          itemGroupId: item.itemGroupId,
          itemGroupValue: item.itemGroupValue,
          itemId: item.itemId,
          itemMake: item.itemMake,
          quantity: limitDecimals(item.quantity, 3),
          uom: item.uom,
          rate: limitDecimals(item.rate, 3),
          doBalQty: item.doBalQty,
          invoiceQty: limitDecimals(item.invoiceQty, 3),
          igstAmount: limitDecimals(item.igstAmount, 2),
          igstPercent: limitDecimals(item.igstPercent, 2),
          cgstAmount: limitDecimals(item.cgstAmount, 2),
          cgstPercent: limitDecimals(item.cgstPercent, 2),
          sgstAmount: limitDecimals(item.sgstAmount, 2),
          sgstPercent: limitDecimals(item.sgstPercent, 2),
          discountType: item.discountType,
          discount: limitDecimals(item.discount, 2),
          discountedRate: limitDecimals(item.discountedRate, 3),
          discountAmount: limitDecimals(item.discountAmount, 2),
          netAmount: limitDecimals(item.netAmount, 2),
          totalAmount: limitDecimals(item.totalAmount),
          remarks: item.remarks,
          balesPacketSlNo: "",
          status: 1,
          yarnProductId: "1",
          yarnQuantity: limitDecimals(item.quantity, 3),
          yarnUnitPrice: "1",
          yarnTotalPrice: limitDecimals(item.totalAmount),
          yarnBalesPacketSlNo: "",
          yarnRateUom: item.yarnRateUom,
          yarnRateUomId: item.yarnRateUomId,
          // yarnRateRelationValue: item.yarnRateUomRelationValue,
          yarnRateUomRelationValue: item.yarnRateUomRelationValue,
          yarnRateCalculatedValue: item.yarnRateCalculatedValue,
          yarnQuantityUom: item.yarnQuantityUom,
          yarnQuantityUomId: item.yarnQuantityUomId,
          // yarnQuantityRelationValue: item.yarnQuantityUomRelationValue,
          yarnQuantityUomRelationValue: item.yarnQuantityUomRelationValue,
          yarnQuantityCalculatedValue: item.yarnQuantityCalculatedValue,
          yarnVariableRelationValue: item.yarnVariableRelationValue,
          yarnRateIncludingBrokerage: limitDecimals(
            item.yarnRateIncludingBrokerage
          ),
          yarnRateExcludingBrokerage: limitDecimals(
            item.yarnRateExcludingBrokerage
          ),
          isActive: item.isActive,
          yarnIsActive: item.yarnIsActive,
        });
      });
    }
    const data = {
      invoiceHdrDto: {
        deliveryOrderId: JuteYarnInvoiceHeader.deliveryOrderId,
        companyId: localStorage.getItem("companyId"),
        finYear: localStorage.getItem("acadamicYear"),
        createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
        // createdDate:  TodayDate.split("T", 2)[0],
        invoiceDate: JuteYarnInvoiceHeader.invoiceDate,
        invoiceType: 3,
        branchId: JuteYarnInvoiceHeader.branchId,
        billingFrom: JuteYarnInvoiceHeader.billingFrom,
        shippingFrom: JuteYarnInvoiceHeader.shippingFrom,
        billingFromAddress: JuteYarnInvoiceHeader.billingFromAddress,
        shippingFromAddress: JuteYarnInvoiceHeader.shippingFromAddress,
        transporterNumber: JuteYarnInvoiceHeader.transporterNumber,
        transporterDocNo: JuteYarnInvoiceHeader.transporterDocNo,
        regularSalesOrderId: JuteYarnInvoiceHeader.regularSalesOrderId,
        brokerId: JuteYarnInvoiceHeader.brokerId,
        customerId: JuteYarnInvoiceHeader.customerId,
        customerBranchId: JuteYarnInvoiceHeader.customerBranchId,
        transportationMode: JuteYarnInvoiceHeader.transportationMode,
        vehicleType: JuteYarnInvoiceHeader.vehicleType,
        transportationDistance: JuteYarnInvoiceHeader.transportationDistance,
        billingTo: JuteYarnInvoiceHeader.billingTo,
        shippingTo: JuteYarnInvoiceHeader.shippingTo,
        transporterId: JuteYarnInvoiceHeader.transporterId,
        bankId: JuteYarnInvoiceHeader.bankId,
        bankBranch: JuteYarnInvoiceHeader.bankBranch,
        bankName: JuteYarnInvoiceHeader.bankName,
        ifscCode: JuteYarnInvoiceHeader.ifscCode,
        accountNo: JuteYarnInvoiceHeader.accountNo,
        micrCode: JuteYarnInvoiceHeader.micrCode,
        challanNo: JuteYarnInvoiceHeader.challanNo,
        challanDate: JuteYarnInvoiceHeader.challanDate,
        taxType: JuteYarnInvoiceHeader.taxType,
        brokerCommision: JuteYarnInvoiceHeader.brokerCommision,
        brokerCommissionPercent: JuteYarnInvoiceHeader.brokerCommissionPercent,
        vehicleNumber: JuteYarnInvoiceHeader.vehicleNumber,
        footerNote: JuteYarnInvoiceHeader.footerNote,
        internalNote: JuteYarnInvoiceHeader.internalNote,
        termsConditionType: JuteYarnInvoiceHeader.termsConditionType,
        termsCondition: JuteYarnInvoiceHeader.termsCondition,
        deliveryTerms: JuteYarnInvoiceHeader.deliveryTerms,
        paymentTerms: JuteYarnInvoiceHeader.paymentTerms,
        deliveryDays: JuteYarnInvoiceHeader.deliveryDays,
        freightCharges: JuteYarnInvoiceHeader.freightCharges,
        amountWithoutTax: limitDecimals(JuteYarnInvoiceHeader.netTotal, 2),
        amountWithTax: limitDecimals(JuteYarnInvoiceHeader.TotalAmount, 2),
        updatedBy: "",
        updatedOn: "",
        approvedBy: "",
        approvedOn: "",
        statusId: 1,
        gstSuppType: "",
        gstInvoiceStatus: "",
        gstInvoiceCategory: "",
        gstInvoiceType: "",
        gstAmtTaxable: "",
        gst_txpd_taxable_value: "",
        gstShippingBillNo: "",
        gstShippingBillDate: "",
        gstReason: "",
        gstr1ReturnPeriod: "",
        gstr3bReturnPeriod: "",
        reverseCharge: "",
        isAmended: "",
        amendedPos: "",
        amendedPeriod: "",
        invoiceLineItemDto: LineItemDTO,
      },
      invoiceType: 3,
      loadingPoint: "",
      destinationRailHead: "",
    };
    dispatch(
      CreateJuteYarnInvoice(serverApi.CREATE_JUTE_YARN_INVOICE, data, history)
    );
  };
  const UpdateForSoJuteYarn = (status) => {
    var LineItemDTO = [];
    var FilteredData = JuteYarnInvoiceLineItems.filter(
      (item) => item.itemGroupId !== null && item.itemGroupId !== ""
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemDTO.push({
          salesRegularInvoiceLineitemId: item.salesRegularInvoiceLineitemId,
          regInvoiceId: item.regInvoiceId,
          createdBy: item.createdBy,
          createdDate: item.createdDate,
          companyId: item.companyId,
          regularSalesOrderId: item.regularSalesOrderId,
          regularSalesOrderLineItemId: item.regularSalesOrderLineItemId,
          regularDeliveryOrderID: item.regularDeliveryOrderID,
          regularDeliveryOrderLineItemID: item.regularDeliveryOrderLineItemID,
          hsncode: item.hsncode,
          itemGroupId: item.itemGroupId,
          itemGroupValue: item.itemGroupValue,
          itemId: item.itemId,
          itemMake: item.itemMake,
          quantity: limitDecimals(item.quantity, 3),
          uom: item.uom,
          rate: limitDecimals(item.rate, 3),
          doBalQty: item.doBalQty,
          invoiceQty: limitDecimals(item.invoiceQty, 3),
          igstAmount: limitDecimals(item.igstAmount, 2),
          igstPercent: limitDecimals(item.igstPercent, 2),
          cgstAmount: limitDecimals(item.cgstAmount, 2),
          cgstPercent: limitDecimals(item.cgstPercent, 2),
          sgstAmount: limitDecimals(item.sgstAmount, 2),
          sgstPercent: limitDecimals(item.sgstPercent, 2),
          discountType: item.discountType,
          discount: limitDecimals(item.discount, 2),
          discountedRate: limitDecimals(item.discountedRate, 3),
          discountAmount: limitDecimals(item.discountAmount, 2),
          netAmount: limitDecimals(item.netAmount, 2),
          totalAmount: limitDecimals(item.totalAmount),
          remarks: item.remarks,
          balesPacketSlNo: "",
          status: item.status,
          yarnInvoiceLineItemId: item.yarnInvoiceLineItemId,
          yarnInvoiceId: item.yarnInvoiceId,
          yarnProductId: "1",
          yarnQuantity: limitDecimals(item.quantity, 3),
          yarnUnitPrice: "1",
          yarnTotalPrice: limitDecimals(item.totalAmount),
          yarnBalesPacketSlNo: "",
          yarnRateUom: item.yarnRateUom,
          yarnRateUomId: item.yarnRateUomId,
          // yarnRateRelationValue: item.yarnRateUomRelationValue,
          yarnRateUomRelationValue: item.yarnRateUomRelationValue,
          yarnRateCalculatedValue: item.yarnRateCalculatedValue,
          yarnQuantityUom: item.yarnQuantityUom,
          yarnQuantityUomId: item.yarnQuantityUomId,
          // yarnQuantityRelationValue: item.yarnQuantityUomRelationValue,
          yarnQuantityUomRelationValue: item.yarnQuantityUomRelationValue,
          yarnQuantityCalculatedValue: item.yarnQuantityCalculatedValue,
          yarnVariableRelationValue: item.yarnVariableRelationValue,
          isActive: item.isActive,
          yarnIsActive: item.yarnIsActive,
          yarnRateIncludingBrokerage: limitDecimals(
            item.yarnRateIncludingBrokerage
          ),
          yarnRateExcludingBrokerage: limitDecimals(
            item.yarnRateExcludingBrokerage
          ),
        });
      });
    }
    const Data = {
      invoiceHdrDto: {
        deliveryOrderId: JuteYarnInvoiceHeader.deliveryOrderId,
        companyId: localStorage.getItem("companyId"),
        finYear: JuteYarnInvoiceHeader.finYear,
        createdBy: JuteYarnInvoiceHeader.createdBy,
        createdDate: JuteYarnInvoiceHeader.createdDate,
        invoiceDate: JuteYarnInvoiceHeader.invoiceDate,
        invoiceType: 3,
        branchId: JuteYarnInvoiceHeader.branchId,
        billingFrom: JuteYarnInvoiceHeader.billingFrom,
        shippingFrom: JuteYarnInvoiceHeader.shippingFrom,
        billingFromAddress: JuteYarnInvoiceHeader.billingFromAddress,
        shippingFromAddress: JuteYarnInvoiceHeader.shippingFromAddress,
        transporterNumber: JuteYarnInvoiceHeader.transporterNumber,
        transporterDocNo: JuteYarnInvoiceHeader.transporterDocNo,
        regularSalesOrderId: JuteYarnInvoiceHeader.regularSalesOrderId,
        salesOrderNumber: JuteYarnInvoiceHeader.salesOrderNumber,
        deliveryOrderNo: JuteYarnInvoiceHeader.deliveryOrderNo,
        invoiceNo: JuteYarnInvoiceHeader.invoiceNo,
        regInvoiceId: JuteYarnInvoiceHeader.regInvoiceId,
        yarnInvoiceId: JuteYarnInvoiceHeader.yarnInvoiceId,
        brokerId: JuteYarnInvoiceHeader.brokerId,
        customerId: JuteYarnInvoiceHeader.customerId,
        customerBranchId: JuteYarnInvoiceHeader.customerBranchId,
        transportationMode: JuteYarnInvoiceHeader.transportationMode,
        vehicleType: JuteYarnInvoiceHeader.vehicleType,
        transportationDistance: JuteYarnInvoiceHeader.transportationDistance,
        billingTo: JuteYarnInvoiceHeader.billingTo,
        shippingTo: JuteYarnInvoiceHeader.shippingTo,
        transporterId: JuteYarnInvoiceHeader.transporterId,
        bankId: JuteYarnInvoiceHeader.bankId,
        bankBranch: JuteYarnInvoiceHeader.bankBranch,
        bankName: JuteYarnInvoiceHeader.bankName,
        ifscCode: JuteYarnInvoiceHeader.ifscCode,
        accountNo: JuteYarnInvoiceHeader.accountNo,
        micrCode: JuteYarnInvoiceHeader.micrCode,
        challanNo: JuteYarnInvoiceHeader.challanNo,
        challanDate: JuteYarnInvoiceHeader.challanDate,
        taxType: JuteYarnInvoiceHeader.taxType,
        brokerCommision: JuteYarnInvoiceHeader.brokerCommision,
        brokerCommissionPercent: JuteYarnInvoiceHeader.brokerCommissionPercent,
        vehicleNumber: JuteYarnInvoiceHeader.vehicleNumber,
        footerNote: JuteYarnInvoiceHeader.footerNote,
        internalNote: JuteYarnInvoiceHeader.internalNote,
        termsConditionType: JuteYarnInvoiceHeader.termsConditionType,
        termsCondition: JuteYarnInvoiceHeader.termsCondition,
        deliveryTerms: JuteYarnInvoiceHeader.deliveryTerms,
        paymentTerms: JuteYarnInvoiceHeader.paymentTerms,
        deliveryDays: JuteYarnInvoiceHeader.deliveryDays,
        freightCharges: JuteYarnInvoiceHeader.freightCharges,
        amountWithoutTax: limitDecimals(JuteYarnInvoiceHeader.netTotal, 2),
        amountWithTax: limitDecimals(JuteYarnInvoiceHeader.TotalAmount, 2),
        updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
        updatedOn: TodayDate.split("T", 2)[0],
        approvedBy: "",
        approvedOn: "",
        status: status,
        gstSuppType: "",
        gstInvoiceStatus: "",
        gstInvoiceCategory: "",
        gstInvoiceType: "",
        gstAmtTaxable: "",
        gst_txpd_taxable_value: "",
        gstShippingBillNo: "",
        gstShippingBillDate: "",
        gstReason: "",
        gstr1ReturnPeriod: "",
        gstr3bReturnPeriod: "",
        reverseCharge: "",
        isAmended: "",
        amendedPos: "",
        amendedPeriod: "",
        invoiceLineItemDto: LineItemDTO,
      },
      invoiceType: 3,
      loadingPoint: "",
      destinationRailHead: "",
      yarnInvoiceId: JuteYarnInvoiceHeader.yarnInvoiceId,
      regInvoiceId: JuteYarnInvoiceHeader.regInvoiceId,

      updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
      updatedOn: TodayDate.split("T", 2)[0],
      isActive: 1,
    };

    dispatch(
      UpdateJuteYarnInvoice(serverApi.UPDATE_JUTE_YARN_INVOICE, Data, history)
    );
  };
  const handleCurrentStep = (data) => {
    setcurrentStep(data);
  };
  const handleAlignment = (event, newAlignment) => {
    setalignment(newAlignment);
    if (newAlignment == 1) {
      setsource("WITHSALESORDER");
      setcreateWithSalesOrder(true);
      // setcreateWithDirectInvoice(true);
      setSelectInvoiceMethod(true);
      const Header = {
        ...RegularInvoiceHeader,
        createWithDirectInvoice: true,
      };
      dispatch(getRegularInvoiceHeader(Header));
    } else {
      setsource("DIRECTORDER");
      // setcreateWithDirectInvoice(false);
      setcreateWithSalesOrder(false);
      setSelectInvoiceMethod(true);
      const Header = {
        ...RegularInvoiceHeader,
        createWithDirectInvoice: false,
      };
      dispatch(getRegularInvoiceHeader(Header));
    }
  };

  useEffect(() => {
    if (locationState.state !== undefined) {
      if (locationState.state.rowData !== undefined) {
        setcurrentStep(5);
        if (locationState.state.rowData.invoiceTypeId === 1) {
          setinvoiceType(1);
          dispatch(
            getRegularInvoiceViewById(
              serverApi.INVOICE_VIEW_BY_ID +
                "1/" +
                locationState.state.rowData.invoiceId +
                "/" +
                JSON.parse(localStorage.getItem("authUser")).userId,
              history
            )
          );
          dispatch(
            getInvoiceFiles(
              serverApi.GET_FILES +
                "47/" +
                locationState.state.rowData.invoiceId +
                "/" +
                localStorage.getItem("companyId"),
              history
            )
          );
        } else if (locationState.state.rowData.invoiceTypeId === 2) {
          setinvoiceType(2);
          dispatch(
            getJuteGovtInvoiceViewById(
              serverApi.INVOICE_VIEW_BY_ID +
                "2/" +
                locationState.state.rowData.invoiceId +
                "/" +
                JSON.parse(localStorage.getItem("authUser")).userId,
              history
            )
          );
          dispatch(
            getInvoiceFiles(
              serverApi.GET_FILES +
                "47/" +
                locationState.state.rowData.invoiceId +
                "/" +
                localStorage.getItem("companyId"),
              history
            )
          );
        } else if (locationState.state.rowData.invoiceTypeId === 3) {
          setinvoiceType(3);
          dispatch(
            getJuteYarnInvoiceViewById(
              serverApi.INVOICE_VIEW_BY_ID +
                "3/" +
                locationState.state.rowData.invoiceId +
                "/" +
                JSON.parse(localStorage.getItem("authUser")).userId,
              history
            )
          );
          dispatch(
            getInvoiceFiles(
              serverApi.GET_FILES +
                "47/" +
                locationState.state.rowData.invoiceId +
                "/" +
                localStorage.getItem("companyId"),
              history
            )
          );
        } else if (locationState.state.rowData.invoiceTypeId === 4) {
          setinvoiceType(4);
          dispatch(
            getJuteHessianInvoiceViewById(
              serverApi.INVOICE_VIEW_BY_ID +
                "4/" +
                locationState.state.rowData.invoiceId +
                "/" +
                JSON.parse(localStorage.getItem("authUser")).userId,
              history
            )
          );
          dispatch(
            getInvoiceFiles(
              serverApi.GET_FILES +
                "47/" +
                locationState.state.rowData.invoiceId +
                "/" +
                localStorage.getItem("companyId"),
              history
            )
          );
        }
      }
    }
  }, []);
  return (
    <>
      <div className="createQuotationContainer">
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            <div className="iconsBlock">
              <Stepper alternativeLabel activeStep={currentStep}>
                {steps.map((prop) => (
                  <Step key={prop.title}>
                    <StepLabel
                      StepIconComponent={(Iconprops) => {
                        return QontoStepIcon(
                          Iconprops,
                          prop.icon,
                          prop.activeIcon,
                          prop.title
                        );
                      }}
                    ></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
          </Grid>
        </Grid>
      </div>
      {currentStep === 1 && (
        <>
          {InvoiceTypeSelection ? (
            <div>
              <div className="consoleFormButtonBlock">
                <h4>What is the type of Invoice?</h4>
              </div>
              <div className="consoleFormButtonBlock">
                <DynamicSelect
                  arrayOfData={invoiceData}
                  className="dropdownBlockContainer"
                  menuPosition="fixed"
                  menuPlacement="auto"
                  onSelectChange={handleChange}
                  isDropdownOpen={isDropdownOpen}
                  handleMenuOpen={handleMenuOpen}
                  selected={invoiceType}
                  name="invoiceType"
                />
              </div>
              <div>
                <div className="consoleFormButtonBlock">
                  <Link to="/invoice">
                    <CustomButton
                      label={"Back"}
                      className="greenBorderButton"
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className={invoiceType === "" ? "disabled" : "greenButton"}
                    handleClick={ClickNext}
                    type="sumbit"
                    disabled={invoiceType === "" ? true : false}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {InvoiceMethod ? (
            <>
              <div>
                <div className="consoleFormButtonBlock">
                  <h4>How do you want to create the Invoice?</h4>
                </div>

                <div className="consoleFormButtonBlock">
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                    className="btnToggleforIndent"
                  >
                    {SalesOrderMandatory && (
                      <ToggleButton value="1" aria-label="left aligned">
                        <span>With Sales Order</span>
                      </ToggleButton>
                    )}
                    {SalesOrderMandatory && (
                      <ToggleButton value="2" aria-label="left aligned">
                        <span>Direct Invoice</span>
                      </ToggleButton>
                    )}
                  </ToggleButtonGroup>
                </div>
                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Back"}
                    className="greenBorderButton"
                    handleClick={handleClickBefore}
                  />

                  <CustomButton
                    label={"Next"}
                    className={"greenButton"}
                    handleClick={handleClickNext}
                    type="sumbit"
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      )}
      {currentStep === 2 && (
        <>
          {invoiceType === 1 ? (
            <RegularFillDetails
              createWithDirectInvoice={
                RegularInvoiceHeader.createWithDirectInvoice
              }
              SalesOrderMandatory={SalesOrderMandatory}
              DeliveryOrderMandatory={DeliveryOrderMandatory}
              invoiceData={invoiceData}
              locationState={locationState}
              source={source}
            />
          ) : invoiceType === 2 ? (
            <JuteGovtFillDetails
              invoiceData={invoiceData}
              locationState={locationState}
            />
          ) : invoiceType == 3 ? (
            <JuteYarnFillDetails
              invoiceData={invoiceData}
              locationState={locationState}
            />
          ) : invoiceType === 4 ? (
            <JuteHessianInvoceFillDetails
              invoiceType={invoiceType}
              invoiceData={invoiceData}
              locationState={locationState}
            />
          ) : (
            ""
          )}
          <div className="consoleFormButtonBlock">
            <Link>
              <CustomButton
                label={"Back"}
                className="greenBorderButton"
                handleClick={onClickBefore}
              />
            </Link>
            <CustomButton
              label={"Next"}
              className="greenButton"
              handleClick={onClickNext}
              type="sumbit"
            />
          </div>
        </>
      )}
      {currentStep === 3 && (
        <>
          {invoiceType === 1 ? (
            <RegularLineItems
              createWithDirectInvoice={
                RegularInvoiceHeader.createWithDirectInvoice
              }
              locationState={locationState}
              source={source}
            />
          ) : invoiceType === 2 ? (
            <JuteGovtLineItems locationState={locationState} />
          ) : invoiceType === 3 ? (
            <JuteYarnLineItems locationState={locationState} />
          ) : invoiceType === 4 ? (
            <JuteHessianInvoiceLineItems locationState={locationState} />
          ) : (
            ""
          )}
          <div className="consoleFormButtonBlock">
            <Link>
              <CustomButton
                label={"Back"}
                className="greenBorderButton"
                handleClick={onClickBefore}
              />
            </Link>
            <CustomButton
              label={"Next"}
              className="greenButton"
              handleClick={onClickNext}
              type="sumbit"
            />
          </div>
        </>
      )}
      {currentStep === 4 && (
        <>
          {invoiceType === 1 ? (
            <RegularTransportation locationState={locationState} />
          ) : invoiceType === 2 ? (
            <JuteGovtTrasportation locationState={locationState} />
          ) : invoiceType === 3 ? (
            <JuteYarnTransportation locationState={locationState} />
          ) : invoiceType === 4 ? (
            <JuteHessianInvoiceTransportation locationState={locationState} />
          ) : (
            ""
          )}
          <div className="consoleFormButtonBlock">
            <Link>
              <CustomButton
                label={"Back"}
                className="greenBorderButton"
                handleClick={onClickBefore}
              />
            </Link>
            <CustomButton
              label={"Next"}
              className="greenButton"
              handleClick={onClickNext}
              type="sumbit"
            />
          </div>
        </>
      )}
      {currentStep === 5 && (
        <>
          {invoiceType === 1 ? (
            <RegularPreview
              handleCurrentStep={handleCurrentStep}
              invoiceType={invoiceType}
              locationState={locationState}
            />
          ) : invoiceType === 2 ? (
            <JuteGovtPreview
              handleCurrentStep={handleCurrentStep}
              invoiceType={invoiceType}
              locationState={locationState}
            />
          ) : invoiceType === 3 ? (
            <JuteYarnPreview
              handleCurrentStep={handleCurrentStep}
              invoiceType={invoiceType}
              locationState={locationState}
            />
          ) : invoiceType === 4 ? (
            <JuteHessianInvoicePreview
              handleCurrentStep={handleCurrentStep}
              invoiceType={invoiceType}
              locationState={locationState}
            />
          ) : (
            ""
          )}
          <div className="consoleFormButtonBlock">
            {locationState.state == undefined ? (
              <CustomButton
                label={"Back"}
                className="greenBorderButton"
                handleClick={onClickBefore}
              />
            ) : (
              ""
            )}
            {invoiceMandatory &&
            locationState.state &&
            RegularInvoiceHeader.statusId === 3 ? (
              <CustomButton
                label={"E-Invoice"}
                className="greenButton"
                handleClick={() => onGenerateEinvoiceWaybill(data, "einvoice")}
              />
            ) : (
              ""
            )}
            {invoiceMandatory &&
            locationState.state &&
            RegularInvoiceHeader.statusId === 3 ? (
              <Link>
                <CustomButton
                  label={"E-Way Bill"}
                  className="greenButton"
                  handleClick={() => onGenerateEinvoiceWaybill(data, "ewaybill")}
                />
              </Link>
            ) : (
              ""
            )}
            {invoiceMandatory &&
            locationState.state &&
            juteGovtInvoiceHeader.status === 3 ? (
              <CustomButton
                label={"E-Invoice"}
                className="greenButton"
                handleClick={() => onGenerateEinvoiceWaybill(data, "einvoice")}
              />
            ) : (
              ""
            )}
            {invoiceMandatory &&
            locationState.state &&
            juteGovtInvoiceHeader.status === 3 ? (
              <Link>
                <CustomButton
                  label={"E-Way Bill"}
                  className="greenButton"
                  handleClick={() => onGenerateEinvoiceWaybill(data, "ewaybill")}
                />
              </Link>
            ) : (
              ""
            )}
            {invoiceMandatory &&
            locationState.state &&
            JuteHassianInvoiceHeader.status === 3 ? (
              <CustomButton
                label={"E-Invoice"}
                className="greenButton"
                handleClick={() => onGenerateEinvoiceWaybill(data, "einvoice")}
              />
            ) : (
              ""
            )}
            {invoiceMandatory &&
            locationState.state &&
            JuteHassianInvoiceHeader.status === 3 ? (
              <Link>
                <CustomButton
                  label={"E-Way Bill"}
                  className="greenButton"
                  handleClick={() => onGenerateEinvoiceWaybill(data, "ewaybill")}
                />
              </Link>
            ) : (
              ""
            )}
            {invoiceMandatory &&
            locationState.state &&
            JuteYarnInvoiceHeader.status === 3 ? (
              <CustomButton
                label={"E-Invoice"}
                className="greenButton"
                handleClick={() => onGenerateEinvoiceWaybill(data, "einvoice")}
              />
            ) : (
              ""
            )}
            {invoiceMandatory &&
            locationState.state &&
            JuteYarnInvoiceHeader.status === 3 ? (
              <Link>
                <CustomButton
                  label={"E-Way Bill"}
                  className="greenButton"
                  handleClick={() => onGenerateEinvoiceWaybill(data, "ewaybill")}
                />
              </Link>
            ) : (
              ""
            )}
            {locationState.state == undefined ? (
              <CustomButton
                label={"Create"}
                className="greenButton"
                handleClick={onClickCreate}
                type="sumbit"
              />
            ) : (
              <>
                {RegularInvoiceHeader.UpdateButton == true ? (
                  <CustomButton
                    label={"Update"}
                    className="greenButton"
                    handleClick={() => OnUpdate("")}
                    type="sumbit"
                  />
                ) : (
                  ""
                )}
                {juteGovtInvoiceHeader.UpdateButton == true ? (
                  <CustomButton
                    label={"Update"}
                    className="greenButton"
                    handleClick={() => UpdateForSoJuteGovt("")}
                    type="sumbit"
                  />
                ) : (
                  ""
                )}
                {JuteYarnInvoiceHeader.UpdateButton == true ? (
                  <CustomButton
                    label={"Update"}
                    className="greenButton"
                    handleClick={() => UpdateForSoJuteYarn("")}
                  />
                ) : (
                  ""
                )}
                {JuteHassianInvoiceHeader.UpdateButton == true ? (
                  <CustomButton
                    label={"Update"}
                    className="greenButton"
                    handleClick={() => UpdateForJuteHessian("")}
                    type="sumbit"
                  />
                ) : (
                  ""
                )}
              </>
            )}
            {locationState.state !== undefined &&
            RegularInvoiceHeader.ApproveButton == true ? (
              <>
                <CustomButton
                  label={"Approve"}
                  className="approved"
                  handleClick={() =>
                    onClickApprove(RegularInvoiceHeader.statusId)
                  }
                  type="sumbit"
                />
              </>
            ) : (
              ""
            )}
            {locationState.state !== undefined &&
            juteGovtInvoiceHeader.ApproveButton == true ? (
              <>
                <CustomButton
                  label={"Approve"}
                  className="approved"
                  handleClick={() =>
                    onClickApprove(juteGovtInvoiceHeader.status)
                  }
                  type="sumbit"
                />
              </>
            ) : (
              ""
            )}
            {locationState.state !== undefined &&
            JuteYarnInvoiceHeader.ApproveButton == true ? (
              <>
                <CustomButton
                  label={"Approve"}
                  className="approved"
                  handleClick={() =>
                    onClickApprove(JuteYarnInvoiceHeader.status)
                  }
                  type="sumbit"
                />
              </>
            ) : (
              ""
            )}
            {locationState.state !== undefined &&
            JuteHassianInvoiceHeader.ApproveButton == true ? (
              <>
                <CustomButton
                  label={"Approve"}
                  className="approved"
                  handleClick={() =>
                    onClickApprove(JuteHassianInvoiceHeader.status)
                  }
                  type="sumbit"
                />
              </>
            ) : (
              ""
            )}
            {locationState.state !== undefined &&
            RegularInvoiceHeader.RejectButton == true ? (
              <>
                <CustomButton
                  label={"Reject"}
                  className="rejected"
                  handleClick={() => onClickRejected("4")}
                  type="sumbit"
                />
              </>
            ) : (
              ""
            )}
            {locationState.state !== undefined &&
            juteGovtInvoiceHeader.RejectButton == true ? (
              <>
                <CustomButton
                  label={"Reject"}
                  className="rejected"
                  handleClick={() => onClickRejected("4")}
                  type="sumbit"
                />
              </>
            ) : (
              ""
            )}
            {locationState.state !== undefined &&
            JuteYarnInvoiceHeader.RejectButton == true ? (
              <>
                <CustomButton
                  label={"Reject"}
                  className="rejected"
                  handleClick={() => onClickRejected("4")}
                  type="sumbit"
                />
              </>
            ) : (
              ""
            )}
            {locationState.state !== undefined &&
            JuteHassianInvoiceHeader.RejectButton == true ? (
              <>
                <CustomButton
                  label={"Reject"}
                  className="rejected"
                  handleClick={() => onClickRejected("4")}
                  type="sumbit"
                />
              </>
            ) : (
              ""
            )}
          </div>
        </>
      )}
      <GstFormPopup
        isOpen={isPopupOpen}
        onClose={closePopup}
        handleSubmit={handleSubmit}
      />
    </>
  );
};
export default CreateInvoice;
