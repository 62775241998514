import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import {
  createLedgerMaster,
  updateLedgerMaster,
} from "../../../store/Master/LedgerMaster/actions";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import moment from "moment";
import { getParentLedgerList } from "../../../store/Global/DropDownApis/actions";
import { getAllLedgerData } from "../../../store/Master/CustomerMaster/actions";
import swal from "sweetalert";
class CreateLedgerMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      parentLedger: "",
      id: "",
      openingBalance: "",
      ledgerCode: null,
      ledgerGroupsValue: "",
      parentLedgerValue: "",
      ledgerGroups: "",
      ledgerName: "",
      createdBy: null,
      createdOn: "",
      createdDate: "",
      parentLedgerList: [], // parent ledger list data
      ledgerGroupsList: [], // ledger groups list data
      locationState: "",
    };
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    this.setState({
      locationState: this.props.location,
    });
    window.addEventListener("scroll", this.handleScroll);
    this.props.getParentLedgerList(
      serverApi.GET_PARENT_LEDGER_LIST +
        this.state.companyId +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    ); // calling for parent ledger list api
    this.props.getAllLedgerData(
      serverApi.GET_LEDGER_GROUPS_LIST +
        this.state.companyId +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    ); // calling for ledger list api
    if (this.props.location.state) {
      this.setState({
        ledgerCode: this.props.location.state.rowData.ledgerCode,
        ledgerName: this.props.location.state.rowData.ledgerName,
        createdBy: this.props.location.state.rowData.createdBy,
        createdOn: this.props.location.state.rowData.createdOn,
        parentLedgerValue: this.props.location.state.rowData.parentLedger,
        openingBalance: this.props.location.state.rowData.openningBalance,
        ledgerGroupsValue: this.props.location.state.rowData.ledgerGroups,
        id: this.props.location.state.rowData.id,
        ebId: this.props.location.state.rowData.ebId,
        suppId: this.props.location.state.rowData.suppId,
        customerId: this.props.location.state.rowData.customerId,
      });
      const dateEntered = this.props.location.state.rowData.createdOn;
      const autoDateTime = moment(dateEntered).format("LLLL");
      this.setState({
        createdDate: autoDateTime,
      });
    }
  }
  componentWillReceiveProps(props) {
    if (props.parentLedgerList) {
      this.setState({
        parentLedgerList: props.parentLedgerList.data, // updating parent ledger list
      });
    }
    if (props.ledgerGroupsList) {
      this.setState({
        ledgerGroupsList: props.ledgerGroupsList.data, // updating ledgergroups list dropdown api
      });
    }
  }
  /* Calling Create and Update Api's to Actions */
  onClickCreateUpdate = () => {
    const userId = this.state.userDit.userId;

    if (
      this.state.ledgerName === "" ||
      this.state.ledgerName === undefined ||
      this.state.ledgerName === null
    ) {
      swal("Please Enter Ledger Name");
      return false;
    }

    if (this.props.location.state === undefined) {
      const data = {
        companyId: this.state.companyId,
        createdBy: userId.toString(),
        ledgerGroups: this.state.ledgerGroupsValue,
        ledgerName: this.state.ledgerName,
        openningBalance: this.state.openingBalance,
        parentLedger: this.state.parentLedgerValue,
        createdOn: moment().format(),
      };
      this.props.createLedgerMaster(
        serverApi.CREATE_LEDGER_MASTER,
        data,
        this.props.history
      ); // calling create ledger API
    } else {
      const data = {
        companyId: this.state.companyId,
        createdBy: userId.toString(),
        createdOn: this.state.createdOn,
        ledgerGroups: this.state.ledgerGroupsValue,
        ledgerName: this.state.ledgerName,
        openningBalance: this.state.openingBalance,
        parentLedger: this.state.parentLedgerValue,
        customerId: this.state.userDit.userId,
        ledgerCode: this.state.ledgerCode,
        id: this.state.id,
        ebId: "",
        suppId: "",
      };
      if (
        this.state.ledgerCode === "" ||
        this.state.ledgerCode === undefined ||
        this.state.ledgerCode === null
      ) {
        swal("Please Enter Ledger Code");
        return false;
      }

      this.props.updateLedgerMaster(
        serverApi.UPDATE_LEDGER_MASTER,
        data,
        this.props.history
      ); // Calling update ledger master api
    }
    // }
  };
  // function for previous page
  onClickCancel = () => {
    this.props.history.push("/Ledger_Master");
  };
  //function for textfileds
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "ledgerName") {
      this.setState({
        ledgerName: event,
      });
    }
    if (key === "openingBalance") {
      this.setState({
        openingBalance: event,
      });
    }
  };
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "parentLedger") {
      this.setState({
        parentLedger: selectedValue,
        parentLedgerValue: selectedValue,
      });
    }
    if (name === "ledgerGroups") {
      this.setState({
        ledgerGroups: selectedValue,
        ledgerGroupsValue: selectedValue,
      });
    }
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <>
                      <Box gridColumn="span 3">
                        <TextFieldReq
                          value={this.state.ledgerCode}
                          caps="OFF"
                          minValue="0"
                          onChange={this.onhandlechangeValue("ledgerCode")}
                          label="Ledger Code"
                        />
                      </Box>
                    </>
                  )}
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.ledgerName}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("ledgerName")}
                      label="Ledger Name"
                      disabled={this.state.ledgerCode === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.ledgerGroupsList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.ledgerGroupsValue}
                        update={this.props.location.state ? 1 : 0}
                        name="ledgerGroups"
                        label="Ledger Groups"
                        isDisabled={this.state.ledgerCode === "" ? true : false}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Opening Balance"
                        value={this.state.openingBalance}
                        caps="OFF"
                        onChange={this.onhandlechangeValue("openingBalance")}
                        disabled={this.state.ledgerCode === "" ? true : false}
                      />
                    </div>
                  </Box>
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdDate}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.state.locationState.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { ledgerGroupsList } = state.CustomerListReducer;
  const { Create_Ledger_Master, Update_Ledger_Master } = state.LedgerReducer; // fetching create and update ledger reducer
  const { parentLedgerList } = state.DropDownListReducer; // fetching global dropdown from dropdown list reducer
  return {
    parentLedgerList,
    ledgerGroupsList,
    Create_Ledger_Master,
    Update_Ledger_Master,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    createLedgerMaster,
    updateLedgerMaster,
    getParentLedgerList,
    getAllLedgerData,
  })(CreateLedgerMaster)
);
