import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import {
  getSupplierMasterList,
  SupplierFilldetailsProps,
  getSuppFilesClear,
} from "../../../store/Master/SupplierMaster/actions";
class SupplierMasterIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      supplierListData: [], // to display list data
      suppCode: null,
      suppName: null,
      suppTyp: null,
      dataexp: "",
    };
  }
  componentDidMount() {
    this.props.getSuppFilesClear(null);
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: this.state.userDit.cipher,
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
      suppCode: "",
      suppName: null,
      suppTyp: "",
      statusList: [],
    };
    this.props.getSupplierMasterList(
      serverApi.GET_ALL_SUPPLIER_MASTER_LIST,
      data,
      this.props.history
    ); // calling supplier masterlist api
    this.props.SupplierFilldetailsProps({});
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.Supplier_Master_List) {
      this.setState({
        totalRecs: props.Supplier_Master_List.totalRecords,
        supplierListData: props.Supplier_Master_List.data, // updating supplier list data
      });
    }
  }

  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const data = {
        cipher: this.state.userDit.cipher,
        companyId: localStorage.getItem("companyId"),

        startIndex: sizePerPage,
        lastIndex: currentIndex,
        suppCode: this.state.suppCode,
        suppName: this.state.suppName,
        suppTyp: this.state.suppTyp,
      };
      if (currentIndex >= 0) {
        this.props.getSupplierMasterList(
          serverApi.GET_ALL_SUPPLIER_MASTER_LIST,
          data,
          this.props.history
        ); // calling Pagination api for supplier master
      }
    } else {
      const data = {
        cipher: this.state.userDit.cipher,
        companyId: localStorage.getItem("companyId"),
        startIndex: currentIndex === 0 ? currentIndex + 1 : currentIndex,
        lastIndex: sizePerPage,
        suppCode: this.state.suppCode,
        suppName: this.state.suppName,
        suppTyp: this.state.suppTyp,
      };
      if (currentIndex >= 0) {
        this.props.getSupplierMasterList(
          serverApi.GET_ALL_SUPPLIER_MASTER_LIST,
          data,
          this.props.history
        ); // calling Pagination api for supplier master
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };

  handleSearch = (data) => {
    const searchData = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      startIndex: 1,
      lastIndex: 10,
      suppName: data.supplierName,
      suppTyp: data.supplierType,
      suppCode: data.suppCode,
    };
    this.setState({
      suppCode: data.suppCode,
      suppName: data.supplierName,
      suppTyp: data.supplierType,
    });
    this.props.getSupplierMasterList(
      serverApi.GET_ALL_SUPPLIER_MASTER_LIST,
      searchData,
      this.props.history
    ); // calling supplier masterlist api
  };
  render() {
    return (
      <>
        <PaginationGridView
          mdata={this.state.supplierListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          // status={false}
          status={true}
          actions={true}
          filter_form={"SupplierMaster"}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          url="/create_supplier_master"
          handleSearch={this.handleSearch}
          apiexp={serverApi.GET_ALL_SUPPLIER_MASTER_LIST}
          payloadexp={this.state.dataexp}
          expName={"Supplier.csv"}
        />
      </>
    );
  }
}
const Columns = [
  {
    Header: "Supplier Code",
    accessor: "suppCode",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Supplier Name",
    accessor: "suppName",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Supplier Type",
    accessor: "suppTyp",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Cell Phone",
    accessor: "phone1",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Contact Person",
    accessor: "contactPerson",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "statusDescription",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
];

const mapStatetoProps = (state) => {
  const { Supplier_Master_List, loading } = state.Supplier_List; //  fetching data from supplier_list reducer
  return { Supplier_Master_List, loading };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSupplierMasterList,
    SupplierFilldetailsProps,
    getSuppFilesClear,
  })(SupplierMasterIndex)
);
