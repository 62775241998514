/**
 * @author Pritam Shahi
 */

import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({
  label,
  selected,
  styleClass,
  onChange,
  name,
  image,
  iconSizeClass,
  disabled,
}) => {
  const handleChange = (event) => {
    const { name, checked } = event.target;
    onChange(name, checked, disabled);
  };

  return (
    <div className="checkboxBlock">
      <input
        type="checkbox"
        className={`checkbox ${styleClass}`}
        checked={selected}
        onChange={handleChange}
        name={name}
        disabled={disabled}
      />
      {image && <img src={image} alt="" className={iconSizeClass} />}
      {label}
    </div>
  );
};

Checkbox.propTypes = {
  styleClass: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  iconSizeClass: PropTypes.string,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  styleClass: 'regular-checkbox',
  image: null,
  iconSizeClass: '',
  disabled: false,
};

export default Checkbox;
