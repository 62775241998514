import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  DebitNoteLineItemsProps,
  UploadDebitFiles,
  getUplaodedDebitFiles,
  deleteUplaodedDebitFiles,
} from "../../../store/Purchase/Debit-credit-note/actions";
import swal from "sweetalert";
import ReactTooltip from "react-tooltip";
import { Box, Grid, Button, IconButton } from "@mui/material";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import DeleteIcons from "../../../assets/images/picEditIcon.png";
import DeleteIcon from "@mui/icons-material/Delete";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import cons, { serverApi ,serverVars} from "../../../helpers/Consts";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import { getFileDownload } from "../../../helpers/server.js";
import { isEmptyWithZero, limitDecimals } from "../../../Utilities/helper.js";


const DebitCreditLineItems = (props) => {
  const [rowCount, setRowCount] = useState(1);
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [lineItems, setLineItems] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [itemGroupList, setitemGroupList] = useState([]);
  const [taxList, settaxList] = useState([]);
  const [uomList, setuomList] = useState([]);
  const [itemList, setitemList] = useState([]);
  const [debitNoteLineDetails, setdebitNoteLineDetails] = useState([]);
  const [deleteLineItem, setdeleteLineItem] = useState(false);
  const [files_List, setfiles_List] = useState([]);
  const [fileName, setFileName] = useState("");
  const [TotalAmount, setTotalAmount] = useState(props.DebCreditHdr.TotalAmount);
  const [TotalValue, setTotalValue] = useState(props.DebCreditHdr.TotalValue);
  const [TotalIGST, setTotalIGST] = useState(props.DebCreditHdr.TotalIGST);
  const [TotalCGST, setTotalCGST] = useState(props.DebCreditHdr.TotalCGST);
  const [TotalSGST, setTotalSGST] = useState(props.DebCreditHdr.TotalSGST);
  const [roundOffValue, setroundOffValue] = useState(props.DebCreditHdr.roundOffValue);
  const [roundingFlag, setroundingFlag] = useState(props.DebCreditHdr.roundingFlag);
  const [roundingPositiveFlag, setroundingPositiveFlag] = useState(props.DebCreditHdr.roundingPositiveFlag);
  const [roundingNegetiveFlag, setroundingNegetiveFlag] = useState(props.DebCreditHdr.roundingNegetiveFlag);
  const [roundingPositiveValue, setroundingPositiveValue] = useState(props.DebCreditHdr.roundingPositiveValue);
  const [roundingNegetiveValue, setroundingNegetiveValue] = useState(props.DebCreditHdr.roundingNegetiveValue);

  const onDel = (row) => {
    var FilterLineItems = debitNoteLineDetails.filter(
      (item) => item.isActive !== 0
    );
    if (FilterLineItems.length == 1) {
      swal("you can't delete the last lineitem");
    } else {
      let indexItem = debitNoteLineDetails.findIndex((item) => item === row);
      let lineItems = [...debitNoteLineDetails];
      lineItems[indexItem].isActive = 0;
      setdebitNoteLineDetails(lineItems);
      props.DebitNoteLineItemsProps(lineItems);
    }
  };
  const OnhandleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;

    let mname = key.split("_");
    if (mname[0] === "quantity") {
      let Val = value.match(/^(\d*\.{0,1}\d{0,3}$)/);
      let debitNoteLineItems = debitNoteLineDetails.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (Val) {
            if (value <= row.balanceQuantity) {
              rowItem.quantity = value;
              if (rowItem.rate !== "") {
                if(rowItem.discountMode == 1){
                  let amount =limitDecimals((rowItem.rate * value))
                  let taxAmount =limitDecimals((amount *Number(rowItem.taxPercentage))/100)
                  rowItem.debitnoteValue=limitDecimals((rowItem.rate * value) - rowItem.discount,2)
                  rowItem.amount = limitDecimals(Number(amount))
                  rowItem.taxAmount = taxAmount
                } else if(rowItem.discountMode == 2){
                  let amount =limitDecimals((rowItem.rate * value),2)
                  let taxAmount = limitDecimals((amount *Number(rowItem.taxPercentage))/100)
                  rowItem.debitnoteValue=limitDecimals((rowItem.rate * value * (100 - rowItem.discount)) / 100,2)
                  rowItem.amount=limitDecimals(Number(amount))
                  rowItem.taxAmount = taxAmount
                } else {
                  let DebitVal =limitDecimals(rowItem.rate * value,2)
                  let taxAmount =limitDecimals((DebitVal *Number(rowItem.taxPercentage))/100)
                  rowItem.debitnoteValue=limitDecimals(DebitVal)
                  rowItem.amount=limitDecimals(Number(DebitVal))
                  rowItem.taxAmount = taxAmount
                }
               if(props.DebCreditHdr.taxType ==1){
                let IgstVal =(rowItem.rate * value) - rowItem.discount
                rowItem.itaxAmount = limitDecimals((IgstVal*rowItem.itaxPer)/100,2)
               }
               else if(props.DebCreditHdr.taxType ==2){
                let CgstVal =(rowItem.rate * value) - rowItem.discount
                let SgstVal =(rowItem.rate * value) - rowItem.discount
                rowItem.ctaxAmount = limitDecimals((CgstVal*rowItem.ctaxPer)/100,2)
                rowItem.staxAmount = limitDecimals((SgstVal*rowItem.staxPer)/100,2)
               }else{
                rowItem.ctaxAmount ="0.00"
                rowItem.staxAmount = "0.00"
                rowItem.itaxAmount ="0.00"
               }

              }
            } else {
              swal("Quantity should not be greater than balance quantity");
              rowItem.quantity = "";
              rowItem.debitnoteValue="0.00"
              rowItem.amount="0.00"
              rowItem.taxAmount = "0.00"
              rowItem.ctaxAmount ="0.00"
              rowItem.staxAmount = "0.00"
              rowItem.itaxAmount ="0.00"
            }
          } else {
            swal("Cannot enter more than three decimals");
            rowItem.quantity = "";
            rowItem.debitnoteValue="0.00"
            rowItem.amount="0.00"
            rowItem.taxAmount = "0.00"
            rowItem.ctaxAmount ="0.00"
            rowItem.staxAmount = "0.00"
            rowItem.itaxAmount ="0.00"
          }
        }
        return rowItem;
      });
      setdebitNoteLineDetails(debitNoteLineItems);
      props.DebitNoteLineItemsProps(debitNoteLineItems);
      setroundingNegetiveFlag(null)
      setroundingPositiveFlag(null)
      props.DebCreditHdr.roundingNegetiveFlag=null
      props.DebCreditHdr.roundingPositiveFlag=null

      setroundOffValue(null)
      props.DebCreditHdr.roundingFlag = null
    }
    if (mname[0] === "rate") {
      let Val = value.match(/^(\d*\.{0,1}\d{0,3}$)/);
      let debitNoteLineItems = debitNoteLineDetails.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (Val) {
            if(Number(value) <= Number(row.srRate)){
              rowItem.rate = value;
              if (rowItem.quantity !== "") {
                // rowItem.amount = rowItem.quantity * value;
                if(rowItem.discountMode == 1){
                  let debitVal = limitDecimals((value * rowItem.quantity) - rowItem.discount)
                  let amount = limitDecimals((value * rowItem.quantity))
                  let taxAmount =limitDecimals((amount *Number(rowItem.taxPercentage))/100)
                  rowItem.debitnoteValue=limitDecimals(debitVal)
                  rowItem.amount =limitDecimals(Number(amount))
                  rowItem.taxAmount = taxAmount
                } else if(rowItem.discountMode == 2){
                  let debitVal = limitDecimals((value *  rowItem.quantity * (100 - rowItem.discount)) / 100  )
                  let amount = limitDecimals((value * rowItem.quantity))
                  let taxAmount = limitDecimals((amount *Number(rowItem.taxPercentage))/100)
                  rowItem.debitnoteValue=limitDecimals(debitVal)
                  rowItem.amount=limitDecimals(Number(amount))
                  rowItem.taxAmount = taxAmount
                } else {
                  let debitVal=limitDecimals(value *  rowItem.quantity)
                  let taxAmount =limitDecimals((debitVal *Number(rowItem.taxPercentage))/100)
                  rowItem.debitnoteValue=limitDecimals(debitVal)
                  rowItem.amount=limitDecimals(Number(debitVal))
                  rowItem.taxAmount = taxAmount
                }

                if(props.DebCreditHdr.taxType ==1){
                  let IgstVal =(value * rowItem.quantity ) - rowItem.discount
                  rowItem.itaxAmount = limitDecimals((IgstVal*rowItem.itaxPer)/100,2)
                 }
                 else if(props.DebCreditHdr.taxType ==2){
                  let CgstVal =(value*rowItem.quantity) - rowItem.discount
                  let SgstVal =(value * rowItem.quantity) - rowItem.discount
                  rowItem.ctaxAmount = limitDecimals((CgstVal*rowItem.ctaxPer)/100,2)
                  rowItem.staxAmount = limitDecimals((SgstVal*rowItem.staxPer)/100,2)
                 }else{
                  rowItem.ctaxAmount ="0.00"
                  rowItem.staxAmount = "0.00"
                  rowItem.itaxAmount ="0.00"
                 }
              }
            }else{
              swal("Rate should not be greater than SR Rate");
              rowItem.rate = "";
              rowItem.debitnoteValue="0.00"
              rowItem.amount="0.00"
              rowItem.taxAmount = "0.00"
              rowItem.ctaxAmount ="0.00"
              rowItem.staxAmount = "0.00"
              rowItem.itaxAmount ="0.00"
            }
         
          } else {
            swal("Cannot enter more than three decimals");
            rowItem.rate = "";
            rowItem.debitnoteValue="0.00"
            rowItem.amount="0.00"
            rowItem.taxAmount = "0.00"
            rowItem.ctaxAmount ="0.00"
            rowItem.staxAmount = "0.00"
            rowItem.itaxAmount ="0.00"
          }
        }
        return rowItem;
      });
      setdebitNoteLineDetails(debitNoteLineItems);
      props.DebitNoteLineItemsProps(debitNoteLineItems);
      setroundingNegetiveFlag(null)
      setroundingPositiveFlag(null)
      props.DebCreditHdr.roundingNegetiveFlag=null
      props.DebCreditHdr.roundingPositiveFlag=null
      setroundOffValue(null)
      props.DebCreditHdr.roundingFlag = null
    }
    if (mname[0] === "reason") {
      let debitNoteLineItems = debitNoteLineDetails.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.reason = value;
        }
        return rowItem;
      });
      setdebitNoteLineDetails(debitNoteLineItems);
      props.DebitNoteLineItemsProps(debitNoteLineItems);
    }
    if (mname[0] === "amount") {
      let debitNoteLineItems = debitNoteLineDetails.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.amount = value;
        }
        return rowItem;
      });
      setdebitNoteLineDetails(debitNoteLineItems);
      props.DebitNoteLineItemsProps(debitNoteLineItems);
    }
  };
  const handleMenuOpen = () => {
    setIsDropdownOpen(false);
  };

  const handleScroll = () => {
    setIsDropdownOpen(true);
  };

  useEffect(() => {
    setdebitNoteLineDetails(props.debitNoteLineItems);
    if (props.location.state) {
      props.getUplaodedDebitFiles(
        serverApi.GET_FILES +
          "29/" +
          props.DebCreditHdr.adjustmentId +
          "/" +
          localStorage.getItem("companyId"),
        props.history
      );
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleEventPositioned = (info, id,type) => {
    var filteredLineitems = debitNoteLineDetails.filter(
      (item) => item.isActive !== 0
    );

    filteredLineitems.map((item) => {
      if (item.id == id) {
        if (info.target) {
          if(type=="quantity"){
    info.target.setAttribute(
            "data-tip",
            `
          <p>Balance Quantity = ${
            item.balanceQuantity === undefined ? 0 : item.balanceQuantity
          }</p>
          `
          );
          }else{
            info.target.setAttribute(
              "data-tip",
              `
            <p>SR Rate = ${
              item.srRate === undefined ? 0 : item.srRate
            }</p>
            `
            );
          }
      
          ReactTooltip.rebuild();
        }
      }
    });
  };
  const onDeleteFile = (fileId) => {
    props.deleteUplaodedDebitFiles(
      serverApi.DELETEFILE + fileId,
      props.DebCreditHdr.adjustmentId,
      props.history
    );
  };
  const getFilesDownload = (fileUploadId, fileName, fileExtension) => {
    let url = serverVars.SECURITY_API + "files/downloadfile/" + fileUploadId;
    getFileDownload(url, fileName, fileExtension);
  };
  const UploadDocuments = (key) => (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 29);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", props.DebCreditHdr.adjustmentId);
    formData.append("createdBy", userDit.userId);
    formData.append("fileCompanyId", localStorage.getItem("companyId"));
    props.UploadDebitFiles(
      serverApi.UPLOADFILE,
      formData,
      props.DebCreditHdr.adjustmentId,
      props.history
    );
  };
  useEffect(() => {
    if (props.getDebitFilesData) {
      if (props.getDebitFilesData.data) {
        if (props.getDebitFilesData.data.data) {
          setfiles_List(props.getDebitFilesData.data.data);
        }
      }
    }
  }, [props]);
  const handleChange = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    selectedItemCode
  ) => {
    if (name == "debitnoteType") {
      let debitNoteLineItems = debitNoteLineDetails.filter((rowItem) => {
        if (rowItem.id === selectedRow.id) {
          rowItem.debitnoteType = selectedValue;
          rowItem.debitNoteTypeDesc = selectedName;
          if (selectedValue == 1) {
            rowItem.rateDisable = false;
            rowItem.qtyDisable = true;
          } else {
            rowItem.rateDisable = true;
            rowItem.qtyDisable = false;
          }
        }
        return rowItem;
      });
      setdebitNoteLineDetails(debitNoteLineItems);
      props.DebitNoteLineItemsProps(debitNoteLineItems);
    }
  };
  useEffect(()=>{
    var filteredLineitems = debitNoteLineDetails.filter(
      (item) => item.isActive !== 0
    );
    if(filteredLineitems.length !==0){
      var totalValue="0.00"
      var totalAmount = filteredLineitems
      .filter((item) => item.isActive != 0)
      .map((p) => p.debitnoteValue)
      .reduce(
        (debitnoteValue, ind) =>
          parseFloat(debitnoteValue) + parseFloat(ind)
      );
  
    var totalIGST = filteredLineitems
      .filter((item) => item.isActive != 0)
      .map((p) => p.itaxAmount)
      .reduce(
        (itaxAmount, ind) => parseFloat(itaxAmount) + parseFloat(ind)
      );
    var totalCGST =filteredLineitems
      .filter((item) => item.isActive != 0)
      .map((p) => p.ctaxAmount)
      .reduce(
        (ctaxAmount, ind) => parseFloat(ctaxAmount) + parseFloat(ind)
      );
  
    var totalSGST = filteredLineitems
      .filter((item) => item.isActive != 0)
      .map((p) => p.staxAmount)
      .reduce(
        (staxAmount, ind) => parseFloat(staxAmount) + parseFloat(ind)
      );
     totalValue =
     limitDecimals(Number(totalAmount) +
      Number(totalIGST) +
      Number(totalCGST) +
      Number(totalSGST),2);
      if (props.location.state !== undefined) {
        if (props.location.state.rowData !== undefined) {
          if(props.location.state.rowData.roundingFlag ==true){
            setTotalValue(Number(totalValue)+Number(props.location.state.rowData.roundOffValue));
            props.DebCreditHdr.TotalValue = Number(totalValue)+Number(props.location.state.rowData.roundOffValue)
          }else if(props.location.state.rowData.roundingFlag ==false){
            setTotalValue(Number(totalValue)+Number(props.location.state.rowData.roundOffValue));
            props.DebCreditHdr.TotalValue = Number(totalValue)+Number(props.location.state.rowData.roundOffValue)
          }else{
            setTotalValue(Number(totalValue));
            props.DebCreditHdr.TotalValue = Number(totalValue)
          }
        }
      }else{   
        if(roundingPositiveFlag ==true){
          setTotalValue(Number(totalValue)+Number(roundingPositiveValue));
          props.DebCreditHdr.TotalValue =Number(totalValue)+Number(roundingPositiveValue)
        }else if(roundingNegetiveFlag ==true){
          setTotalValue(Number(totalValue)+Number(roundingNegetiveValue));
          props.DebCreditHdr.TotalValue = Number(totalValue)+Number(roundingNegetiveValue)  
        }else{
          setTotalValue(totalValue)
          props.DebCreditHdr.TotalValue = totalValue  
        }
      }
      setTotalAmount(totalAmount);
      setTotalIGST(totalIGST);
      setTotalCGST(totalCGST);
      setTotalSGST(totalSGST);
      props.DebCreditHdr.TotalAmount= totalAmount
      props.DebCreditHdr.TotalIGST = totalIGST
      props.DebCreditHdr.TotalCGST = totalCGST
      props.DebCreditHdr.TotalSGST = totalSGST
 
      let positiveRF = "";
      let NegativeRF = "";   
      if(!isEmptyWithZero(totalValue)){
        let splitTotal = totalValue && totalValue.split(".");
        if (splitTotal[1] > 0) {
          positiveRF = 100 -splitTotal[1];
          positiveRF = "0." + positiveRF;
          NegativeRF = splitTotal[1];
          NegativeRF = "-0." + NegativeRF;  
          setroundingPositiveValue(positiveRF)
          setroundingNegetiveValue(NegativeRF)
          props.DebCreditHdr.roundingPositiveValue=positiveRF
          props.DebCreditHdr.roundingNegetiveValue=NegativeRF
        }else{
          setroundingPositiveValue("0.00")
          setroundingNegetiveValue("0.00")
          props.DebCreditHdr.roundingPositiveValue="0.00"
          props.DebCreditHdr.roundingNegetiveValue="0.00"
        }
      }else{  
        setroundingPositiveValue("0.00")
        setroundingNegetiveValue("0.00")
        props.DebCreditHdr.roundingPositiveValue="0.00"
        props.DebCreditHdr.roundingNegetiveValue="0.00"
      }
  
    }
  },[debitNoteLineDetails])

  var filteredLineitems =
    debitNoteLineDetails !== null
      ? debitNoteLineDetails.filter((item) => item.isActive !== 0)
      : [];

  var filteredLineitems = debitNoteLineDetails.filter(
    (item) => item.isActive !== 0
  );
 const toggleState =(roundoffFlag,Value) =>{

  var filteredLineitems = debitNoteLineDetails.filter(
    (item) => item.isActive !== 0
  );
  if(filteredLineitems.length !==0){
    var totalValue="0.00"
    var totalAmount = filteredLineitems
    .filter((item) => item.isActive != 0)
    .map((p) => p.debitnoteValue)
    .reduce(
      (debitnoteValue, ind) =>
        parseFloat(debitnoteValue) + parseFloat(ind)
    );

  var totalIGST = filteredLineitems
    .filter((item) => item.isActive != 0)
    .map((p) => p.itaxAmount)
    .reduce(
      (itaxAmount, ind) => parseFloat(itaxAmount) + parseFloat(ind)
    );
  var totalCGST =filteredLineitems
    .filter((item) => item.isActive != 0)
    .map((p) => p.ctaxAmount)
    .reduce(
      (ctaxAmount, ind) => parseFloat(ctaxAmount) + parseFloat(ind)
    );
  var totalSGST = filteredLineitems
    .filter((item) => item.isActive != 0)
    .map((p) => p.staxAmount)
    .reduce(
      (staxAmount, ind) => parseFloat(staxAmount) + parseFloat(ind)
    );
   totalValue = limitDecimals(Number(totalAmount) + Number(totalIGST) + Number(totalCGST) + Number(totalSGST),2);
  if (roundoffFlag == 1) {
    setTotalValue(limitDecimals(Number(totalValue)+Number(Value),2) )
    props.DebCreditHdr.TotalValue=limitDecimals(Number(totalValue)+Number(Value),2) 
    setroundingNegetiveFlag(false)
    setroundingPositiveFlag(true)
    setroundOffValue(Value)
    setroundingFlag(true)
    setroundingPositiveValue(Value)
    props.DebCreditHdr.roundingNegetiveFlag=false
    props.DebCreditHdr.roundingPositiveFlag=true
    props.DebCreditHdr. roundOffValue = Value
    props.DebCreditHdr. roundingFlag =true
    props.DebCreditHdr.roundingPositiveValue=Value
  }else if(roundoffFlag == 2) {
    setTotalValue(limitDecimals(Number(totalValue)+Number(Value),2) )
    props.DebCreditHdr.TotalValue=limitDecimals(Number(totalValue)+Number(Value),2) 
    setroundingNegetiveFlag(true)
    setroundingPositiveFlag(false)
    setroundOffValue(Value)
    setroundingFlag(false)
    setroundingNegetiveValue(Value)
    props.DebCreditHdr.roundingNegetiveFlag=true
    props.DebCreditHdr.roundingPositiveFlag=false
    props.DebCreditHdr.roundOffValue = Value
    props.DebCreditHdr.roundingFlag =false
    props.DebCreditHdr.roundingNegetiveValue =Value
  }
  }
}
  return (
    <div className="editableTableContainer">
      <div className="gridContainer">
        <div className="gridComponent">
          <table cellSpacing="0px" cellPadding="0px">
            <thead>
              <tr className="tableRowBlock">
                {tableHeaders.map((column) => (
                  <>
                    {column.header == "Debit Quantity" ||
                    column.header == "Price" ||
                    column.header == "Reason" ||
                    column.header == "Amount" ||
                    column.header == "Debit Mode" ? (
                      <>
                        <th>
                          {column.header}
                          <label style={{ color: "red" }}>*</label>
                        </th>
                      </>
                    ) : (
                      <>
                        <th>{column.header}</th>
                      </>
                    )}
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredLineitems.length !== 0 &&
                filteredLineitems.map((row, i) => {
                  let rowIndex = filteredLineitems.findIndex(
                    (idd) => idd == row
                  );

                  return (
                    <>
                      <tr>
                        <>
                          {RowData &&
                            RowData.map((col) =>
                              col.component === "Editablecell" ? (
                                <>
                                  <td>
                                    <Tooltip
                                      title={
                                        col.type === "itemGroup"
                                          ? row.itemGroup
                                          : col.type === "itemName"
                                          ? row.itemName
                                          : ""
                                      }
                                    >
                                      <div className="quantityDropdownBlock">
                                        <Grid className="selectAndTextfield">
                                          <Grid
                                            xs={12}
                                            md={12}
                                            className="dynamicSelectBlock"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <input
                                              disabled={
                                                col.type === "amount" ||
                                                col.type === "itemGroup" ||
                                                col.type === "itemName" ||
                                                col.type === "debitnoteValue" ||
                                                col.type === "warehouseno" ||
                                                col.type === "taxPercentage" ||
                                                col.type === "taxAmount"
                                                  ? true
                                                  : col.type == "quantity"
                                                  ? row.qtyDisable == true
                                                    ? true
                                                    : false
                                                  : col.type == "rate"
                                                  ? row.rateDisable == true
                                                    ? true
                                                    : false
                                                  : false
                                              }
                                              type="text"
                                              name={col.type + "_" + rowIndex}
                                              value={
                                                col.type === "itemGroup"
                                                  ? row.itemGroup
                                                  : col.type === "itemName"
                                                  ? row.itemName
                                                  : col.type == "quantity"
                                                  ? row.quantity
                                                  : col.type ===
                                                    "debitnoteValue"
                                                  ? row.debitnoteValue
                                                  : col.type === "reason"
                                                  ? row.reason
                                                  : col.type === "rate"
                                                  ? row.rate
                                                  : col.type === "warehouseno"
                                                  ? row.warehouseno
                                                  : col.type === "taxPercentage"
                                                  ? row.taxPercentage
                                                  : col.type === "taxAmount"
                                                  ? row.taxAmount
                                                  : col.type === "amount"
                                                  ? row.amount
                                                  : ""
                                              }
                                              onMouseOver={(e) =>
                                                col.type === "quantity" || col.type =="rate"
                                                  ? handleEventPositioned(
                                                      e,
                                                      row.id,
                                                      col.type
                                                    )
                                                  : ""
                                              }
                                              onChange={OnhandleChange({
                                                key: col.type + "_" + rowIndex,
                                                row,
                                              })}
                                              className="inputBlockContainer"
                                              style={{
                                                backgroundColor:
                                                  col.type === "amount" ||
                                                  col.type === "itemGroup" ||
                                                  col.type === "itemName" ||
                                                  col.type ===
                                                    "debitnoteValue" ||
                                                  col.type === "warehouseno" ||
                                                  col.type ===
                                                    "taxPercentage" ||
                                                  col.type === "taxAmount"
                                                    ? "#ccd6db"
                                                    : col.type === "quantity"
                                                    ? row.qtyDisable === true
                                                      ? "#ccd6db"
                                                      : ""
                                                    : col.type === "rate"
                                                    ? row.rateDisable === true
                                                      ? "#ccd6db"
                                                      : ""
                                                    : "",
                                                textAlign:
                                                  col.type === "amount" ||
                                                  col.type === "quantity" ||
                                                  col.type === "rate" ||
                                                  col.type === "taxAmount" || col.type ===
                                                  "debitnoteValue" 
                                                    ? "right"
                                                    : "left",
                                                  paddingRight:col.type === "amount" ||
                                                  col.type === "quantity" ||
                                                  col.type === "rate" ||
                                                  col.type === "taxAmount" || col.type ===
                                                  "debitnoteValue" ?"5px":"0px"
                                              }}
                                            />
                                            <ReactTooltip
                                              effect={"solid"}
                                              html={true}
                                              place={"BottomCenter"}
                                              // backgroundColor="#ebfaff"
                                              className="tooltipClassBorder"
                                              backgroundColor="rgba(0,127,173,0.5)"
                                              textColor="#FFF"
                                              border={true}
                                              borderColor="#04749c"
                                            />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Tooltip>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    <div className="quantityDropdownBlock">
                                      <Grid className="selectAndTextfield">
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <DynamicSelect
                                            handleMenuOpen={handleMenuOpen}
                                            isDropdownOpen={isDropdownOpen}
                                            arrayOfData={DebitModeValues}
                                            className="dropdownBlockContainer"
                                            onSelectChange={handleChange}
                                            selected={row.debitnoteType}
                                            name={"debitnoteType"}
                                            row={row}
                                            update={row.debitnoteType}
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </td>
                                </>
                              )
                            )}
                        </>
                        <td className="">
                          {/* <input
                            type="button"
                            onClick={onDel({
                              key: row.inwardDetailId,
                              row,
                            })}
                            value="X"
                            className="deleteDisplayButton"
                          /> */}
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title="Delete"
                          >
                            <IconButton
                              onClick={() => {
                                onDel(row, i);
                              }}
                            >
                              <DeleteIcon className="deleteDisplayButton" />
                            </IconButton>
                          </Tooltip>
                          {/* {deleteLineItem && (
                            <CommonPopup>
                              <div className="delAddItem">
                                <div>Are you sure you want to delete?</div>
                                <div className="delAddItemBtns">
                                  <input
                                    type="button"
                                    onClick={onDel.bind(this)}
                                    value="Yes"
                                    className="delYesBtn"
                                  />
                                  <input
                                    type="button"
                                    onClick={onDel.bind(this)}
                                    value="No"
                                    className="delNoBtn"
                                  />
                                </div>
                              </div>
                            </CommonPopup>
                          )} */}
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="consoleFormContainer">
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 12">
                      <div
                        className="calculationBlockTable"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <table>
                        <tr>
                            <td>Total:</td>
                            <b>{parseFloat(TotalAmount).toFixed(2)}</b>
                          </tr>
                          <tr>
                            <td>Total IGST:</td>
                            <b>{parseFloat(TotalIGST).toFixed(2)}</b>
                          </tr>
                          <tr>
                            <td>Total CGST:</td>
                            <b>{parseFloat(TotalCGST).toFixed(2)}</b>
                          </tr>
                          <tr>
                            <td>Total SGST:</td>
                            <b>{parseFloat(TotalSGST).toFixed(2)}</b>
                          </tr>
                          <tr>
                            <td>Round Off</td>

                            <input
                              type="radio"
                              id="switch_left"
                              name="switchToggle"
                              value={roundingPositiveFlag}
                              onChange={() => {
                                toggleState(1,roundingPositiveValue);
                              }}
                              checked={roundingPositiveFlag}
                            />
                            <span>{roundingPositiveValue}</span>
                            <input
                              type="radio"
                              id="switch_right"
                              name="switchToggle"
                              value={roundingNegetiveFlag}
                              onChange={() => {
                                toggleState(2,roundingNegetiveValue);
                              }}
                              checked={roundingNegetiveFlag}
                            />
                            <span>{roundingNegetiveValue}</span>
                          </tr>
                        <tr>
                            <td>Tatal Value:</td>
                            <b>{parseFloat(TotalValue).toFixed(2)}</b>
                          </tr>
                     
                          </table>
                          </div>
                          </Box>
                          </Box>
                          </Grid>
                          </Grid>
                          </div>
                          </div>
  
      {props.location.state && (
        <>
          <div className="consoleFormContainer">
            <Grid container spacing={2}>
              <Grid item xs={12} className="personalDetailsUpload">
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                  className="ducumentUploadBlock"
                >
                 
                  <Box gridColumn="span 12">
                    <div className="consoleFormBlock filesUploadedBlock">
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={2}
                        className="ducumentUploadBlock uploadedContent"
                      >
                        
                        <Box gridColumn="span 4">
                          <label>Support Documents:</label>
                          <div className="personalDetailsUpload">
                            <div className="ducumentUploadBlock">
                              <div className="documentUploadContent">
                                <span className="btn_upload documentUpload">
                                  <img src={panUploadIcon} alt="" />
                                  <input
                                    type="file"
                                    id="imag"
                                    title=""
                                    className="input-img"
                                    onChange={UploadDocuments()}
                                  />
                                  Upload Document
                                </span>
                              </div>
                            </div>
                          </div>
                        </Box>
                      </Box>
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={2}
                        className="ducumentUploadBlock uploadedContent"
                      >
                        {files_List.length !== 0 &&
                          files_List.map((item) => (
                            <Box gridColumn="span 4">
                              <div className="documentUploadContent">
                                <div
                                  className="uploadedInfo"
                                  style={{ display: "flex" }}
                                >
                                  <img
                                    src={TickIcon}
                                    alt=""
                                    className="tickIcon"
                                    height={"20px"}
                                    width={"20px"}
                                  />
                                  <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    title="Click to download File"
                                  >
                                    <div
                                      className="uplodedFileName"
                                      onClick={() => {
                                        getFilesDownload(
                                          item.fileUploadId,
                                          item.fileName,
                                          item.fileExtension
                                        );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {item.fileName}
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 100 }}
                                    title="Click to Delete File"
                                  >
                                    <Button
                                      onClick={() => {
                                        onDeleteFile(item.fileUploadId);
                                      }}
                                      className="deleteButton"
                                    >
                                      <img
                                        src={DeleteIcons}
                                        alt=""
                                        height={"20px"}
                                        width={"20px"}
                                      />
                                    </Button>
                                  </Tooltip>
                                </div>
                              </div>
                            </Box>
                          ))}
                      </Box>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
};
const RowData = [
  {
    no: 1,
    component: "Editablecell",
    type: "itemGroup",
  },
  {
    no: 2,
    component: "Editablecell",
    type: "itemName",
  },
  {
    no: 3,
    component: "DynamicSelect",
    type: "debitnoteType",
  },
  {
    no: 4,
    component: "Editablecell",
    type: "quantity",
  },
  {
    no: 5,
    component: "Editablecell",
    type: "debitnoteValue",
  },
  {
    no: 6,
    component: "Editablecell",
    type: "reason",
  },
  {
    no: 7,
    component: "Editablecell",
    type: "rate",
  },
  {
    no: 8,
    component: "Editablecell",
    type: "warehouseno",
  },
  {
    no: 9,
    component: "Editablecell",
    type: "taxPercentage",
  },
  {
    no: 10,
    component: "Editablecell",
    type: "taxAmount",
  },
  {
    no: 11,
    component: "Editablecell",
    type: "amount",
  },
];
const tableHeaders = [
  {
    header: "Item Group",
  },
  {
    header: "Item",
  },
  {
    header: "Debit Mode",
  },
  {
    header: "Debit Quantity",
  },

  {
    header: "Debit Amount",
  },
  {
    header: "Reason",
  },
  {
    header: "Price",
  },
  {
    header: "Wareh House No",
  },
  {
    header: "Tax",
  },
  {
    header: "Tax Amount",
  },
  {
    header: "Amount",
  },
  {
    header: "Actions",
  },
];
const DebitModeValues = [
  {
    value: 1,
    label: "Rate Change",
    name: "Rate Change",
  },
  {
    value: 2,
    label: "Quantity Reject",
    name: "Quantity Reject",
  },
];
const mapStatetoProps = (state) => {
  const { debitNoteLineItems, getDebitFilesData } = state.DebitCreditReducer;
  return {
    debitNoteLineItems,
    getDebitFilesData,
  };
};
export default withRouter(
  connect(mapStatetoProps, {
    DebitNoteLineItemsProps,
    UploadDebitFiles,
    deleteUplaodedDebitFiles,
    getUplaodedDebitFiles,
  })(DebitCreditLineItems)
);
