//import { Switch } from "react-router-dom";

import {
  API_ERRORS,
  MATERIAL_REQUEST_LIST,
  MATERIAL_REQUEST_LIST_SUCCESSFULL,
  GET_CUSTOMER_LIST,
  GET_CUSTOMER_LIST_SUCCESSFULL,
  GET_PROJECT_LIST,
  GET_PROJECT_LIST_SUCCESSFULL,
  GET_ITEM_GROUP_BY_ID,
  GET_ITEM_GROUP_BY_ID_SUCCESSFULL,
  GET_SR_STOCK_LIST,
  GET_SR_STOCK_LIST_SUCCESSFULL,
  GET_SR_LIST,
  GET_SR_LIST_SUCCESSFULL,
  GET_MACHINE_NO_LIST,
  GET_MACHINE_NO_LIST_SUCCESSFULL,
  ADD_MATERIAL_REQUEST,
  ADD_MATERIAL_REQUEST_SUCCESSFULL,
  ITEM_LIST,
  ITEM_LIST_SUCCESSFULL,
  CREATE_STORE_ISSUE_LINE_ITEM,
  CREATE_STORE_ISSUE_LINE_ITEM_SUCCESSFULLY,
  STORE_ISSUE_LINE_ITEM,
  STORE_ISSUE_VIEW_BY_ID,
  STORE_ISSUE_VIEW_BY_ID_SUCCESSFULL,
  STORE_HEADER_DATA,
  BRANCH_WISE_STOCK_LIST,
  BRANCH_WISE_STOCK_LIST_SUCCESSFULL,
  CLEAR_HEADER_DATA,
  GETITEMSLISTFORCONSUMPTION_SUCCESSFULL,
  GETITEMGROUPSFORCONSUMPTION_SUCCESSFULL,
  GETSRNOFORCONSUMPTION_SUCCESSFULL,
  GETCOSTFACTORCONSUMPTION_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  MaterialRequest_List: [],
  error_msg: null,
  customerList: [],
  projectList: [],
  itemGroupList: [],
  srStockList: [],
  srList: [],
  machineList: [],
  addMaterialRequest: [],
  itemList: [],
  createStoreIssue: [],
  storeIssueViewByIdList: [],
  branchStockList: [],
  itemsListForConsumption: [],
  itemGroupsForConsumption: [],
  costFactorConsumption: [],
  srNoForConsumption: [],

  storeHeaderDataList: {
    date: null,
    expense_type: 0,
    branchId: 0,
    customer_type: 0,
    project_type: 0,
    internalNote: "",
    expense_name: "",
    customer_name: "",
    project_name: "",
  },
  storeIssueLineItem: [
    {
      id: "0",
      no: 0,
      lineitemId: 1,
      hdrId: "",
      itemGroupList: [],
      itemsList: [],
      srList: [],
      departmentList: [],
      deptId: 0,
      itemGroupId: 0,
      itemId: 0,
      srNo: "",
      srName: "",
      quantity: 0,
      costfactor: 0,
      machineNo: 0,
      centralStock: 0,
      branchStock: 0,
      srStock: 0,
      issueValue: 0,
      itemName: "",
      departmentName: "",
      itemGroupName: "",
      uom: "",
      costFactorName: "",
      srPrintNo: "",
      inwardId: 0,
      machineName: "",
      deleteStatus: false,
    },
  ],
};

const MaterialRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case MATERIAL_REQUEST_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case MATERIAL_REQUEST_LIST_SUCCESSFULL:
      state = {
        ...state,
        MaterialRequest_List: action.payload.response,
        loading: false,
      };
      break;
    case GET_CUSTOMER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_CUSTOMER_LIST_SUCCESSFULL:
      state = {
        ...state,
        customerList: action.payload.response,
        loading: true,
      };
      break;
    case GET_PROJECT_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PROJECT_LIST_SUCCESSFULL:
      state = {
        ...state,
        projectList: action.payload.response,
        loading: true,
      };
      break;
    case GET_ITEM_GROUP_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ITEM_GROUP_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        itemGroupList: action.payload.response,
        loading: true,
      };
      break;
    case GET_SR_STOCK_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SR_STOCK_LIST_SUCCESSFULL:
      state = {
        ...state,
        srStockList: action.payload.response,
        loading: true,
      };
      break;
    case BRANCH_WISE_STOCK_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case BRANCH_WISE_STOCK_LIST_SUCCESSFULL:
      state = {
        ...state,
        branchStockList: action.payload.response,
        loading: true,
      };
      break;
    case GET_SR_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SR_LIST_SUCCESSFULL:
      state = {
        ...state,
        srList: action.payload.response,
        loading: true,
      };
      break;
    case GET_MACHINE_NO_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_MACHINE_NO_LIST_SUCCESSFULL:
      state = {
        ...state,
        machineList: action.payload.response,
        loading: true,
      };
      break;
    case ADD_MATERIAL_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_MATERIAL_REQUEST_SUCCESSFULL:
      state = {
        ...state,
        addMaterialRequest: action.payload.response,
        loading: true,
      };
      break;
    case ITEM_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ITEM_LIST_SUCCESSFULL:
      state = {
        ...state,
        itemList: action.payload.response,
        loading: true,
      };
      break;
    case CREATE_STORE_ISSUE_LINE_ITEM:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_STORE_ISSUE_LINE_ITEM_SUCCESSFULLY:
      state = {
        ...state,
        createStoreIssue: action.payload.response,
        loading: true,
      };
      break;
    case STORE_ISSUE_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case STORE_ISSUE_VIEW_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        storeIssueViewByIdList: action.payload.response,
        loading: true,
      };
      break;

    case STORE_ISSUE_LINE_ITEM:
      state = {
        ...state,
        storeIssueLineItem: action.payload,
      };
      break;

    case GETITEMSLISTFORCONSUMPTION_SUCCESSFULL:
      state = {
        ...state,
        itemsListForConsumption: action.payload.response.data,
        loading: true,
      };
      break;

    case GETITEMGROUPSFORCONSUMPTION_SUCCESSFULL:
      state = {
        ...state,
        itemGroupsForConsumption: action.payload.response.data,
        loading: true,
      };

    case GETSRNOFORCONSUMPTION_SUCCESSFULL:
      state = {
        ...state,
        srNoForConsumption: action.payload.response.data,
        loading: true,
      };
      break;

    case GETCOSTFACTORCONSUMPTION_SUCCESSFULL:
      state = {
        ...state,
        costFactorConsumption: action.payload.response,
        loading: true,
      };
      break;

    case STORE_HEADER_DATA:
      state = {
        ...state,
        storeHeaderDataList: action.payload,
      };
      break;
    case CLEAR_HEADER_DATA:
      state = {
        ...state,
        storeHeaderDataList: action.payload,
      };
      break;

    case API_ERRORS:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default MaterialRequestReducer;
