export const GET_WASTAGE_ENTRY = "wastage_entry";
export const GET_WASTAGE_ENTRY_SUCCESSFULL = "wastage_entry_successfull";
export const WASTAGE_LIST_TYPE = "wastage_list_type";
export const WASTAGE_LIST_TYPE_SUCCESSFULL = "wastage_list_type_successfull";
export const STOCK_WASTAGE_LIST_TYPE = "stock_wastage_list_type";
export const STOCK_WASTAGE_LIST_TYPE_SUCCESSFULL = "stock_wastage_list_type_successfull";
export const CREATE_WASTAGE_ENTRY = "create_wastage_entry";
export const CREATE_WASTAGE_ENTRY_SUCCESSFULL = "create_wastage_entry_successfull";
export const DELETE_WASTAGE_ENTRY = "delete_wastage_entry";
export const DELETE_WASTAGE_ENTRY_SUCCESSFULL = "delete_wastage_entry_successfull";



