import React, { Component, useEffect, useState } from "react";
import {
  Grid,
  Button,
  Step,
  Stepper,
  StepLabel,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import Link from "@mui/material/Link";
import { serverApi } from "../../../helpers/Consts";
import JuteGateEntryFillDetails from "./JuteGateEntryFillDetails.jsx";
import JuteGateEntryLineItems from "./JuteGateEntryLineItems.jsx";
import JuteGateEntryPreview from "./JuteGateEntryPreview.jsx";
import CustomButton from "../../../components/Buttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  getJuteGateEntryHeader,
  juteGateEntryUpdate,
  juteGateEntryViewById,
} from "../../../store/Jute/JuteGateEntry/actions.js";
import moment from "moment";
import swal from "sweetalert";
import { isEmpty, isEmptyWithZero } from "../../../Utilities/helper.js";

function CreateJuteGateEntry(props) {
  const [steps, setSteps] = useState([
    {
      title: "Select Option",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Fill Details",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Add Items",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Preview",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive03.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
  ]);
  const [currentStep, setCurrentStep] = useState(1);
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [companyId, setCompanyId] = useState(localStorage.getItem("companyId"));
  const [alignment, setalignment] = useState("");
  const [HeaderData, setHeaderData] = useState({});
  const [LineItems, setLineItems] = useState([]);
  
  const dispatch = useDispatch();
  let history = useHistory();
  const [locationState] = useState(useLocation());

  const { JuteGateEntryHeader, JuteGateEntrylineItems } = useSelector(
    (state) => state.JuteGateEntryReducer
  );
  useEffect(() => {
    setHeaderData(JuteGateEntryHeader);
    setLineItems(JuteGateEntrylineItems);
  }, [JuteGateEntryHeader, JuteGateEntrylineItems]);
  useEffect(() => {
    if (locationState.state !== undefined) {
      if (locationState.state.rowData !== undefined) {
        setCurrentStep(4);
        dispatch(
          juteGateEntryViewById(
            serverApi.GET_JUTE_GATE_ENTRY_VIEW_BY_ID +
              locationState.state.rowData.gateEntryNum +
              "/" +
              JSON.parse(localStorage.getItem("authUser")).cipher,
            history
          )
        );
      }
    }
  }, []);
  const handleAlignment = (event, newAlignment) => {
    setalignment(newAlignment);
    let JuteGateEntryType = "";
    if (newAlignment == 1) {
      JuteGateEntryType = "WITHPO";
    } else {
      JuteGateEntryType = "WITHOUTPO";
    }
    setHeaderData((prev) => ({
      ...prev,
      JuteGateEntryType: JuteGateEntryType,
    }));
    const UpdatedRec = {
      ...HeaderData,
      JuteGateEntryType: JuteGateEntryType,
    };
    dispatch(getJuteGateEntryHeader(UpdatedRec));
  };
  const MandatoryCheck = () => {
    if (isEmpty(JuteGateEntryHeader.inDate)) {
      swal("Please Select the Date");
    } else if (isEmpty(JuteGateEntryHeader.inTime)) {
      swal("Please Select the Time");
    } else if (isEmpty(JuteGateEntryHeader.vehicleNo)) {
      swal("Please Enter the Vehicle Number");
    } else if (isEmpty(JuteGateEntryHeader.branchId)) {
      swal("Please Select the Branch");
    } else if (isEmpty(JuteGateEntryHeader.unitConversion)) {
      swal("Please Select the Unit Conversion");
    } else if (isEmpty(JuteGateEntryHeader.mukam)) {
      swal("Please Select the Mukam");
    } else if (isEmpty(JuteGateEntryHeader.suppCode)) {
      swal("Please Select the Supplier");
    } else if (isEmpty(JuteGateEntryHeader.grossWeight)) {
      swal("Please Enter the Gross Weight");
    } else if (isEmpty(JuteGateEntryHeader.challanWeight)) {
      swal("Please Enter Challan Weight");
    } else if (isEmpty(JuteGateEntryHeader.outDate)&& locationState.state !==undefined) {
      swal("Please Enter OutDate");
    }else {
      setCurrentStep(currentStep + 1);
    }
  };
  const onClickNext = () => {
    if (currentStep == 2) {
      if (JuteGateEntryHeader.JuteGateEntryType == "WITHPO") {
        if (isEmpty(JuteGateEntryHeader.poNo)) {
          swal("Please Select the PO Number");
        } else {
          MandatoryCheck();
        }
      } else {
        MandatoryCheck();
      }
    } else if (currentStep == 3) {
      var ValidateFields = false;
      var FilteredDatas = JuteGateEntrylineItems.filter(
        (item) => item.isActive !== 0
      );
      if (FilteredDatas.length === 1) {
        FilteredDatas.map((item, index) => {
          if (isEmpty(item.itemId)) {
            swal("Please select the Item");
            return false;
          } else if (isEmpty(item.qualityCode)) {
            swal("Please select the Quality");
            return false;
          } else if (isEmptyWithZero(item.actualQuantity)) {
            swal("Please Enter the Quantity");
            return false;
          } else if (isEmptyWithZero(item.actualWeight)) {
            swal("Please Enter the Weight");
            return false;
          } else {
            ValidateFields = true;
          }
        });
      } else {
        var FilteredData = JuteGateEntrylineItems.filter(
          (item) => item.isActive !== 0 && item.itemId !== ""
        );
        for (var i = 0; i <= FilteredData.length - 1; i++) {
          if (isEmpty(FilteredData[i].itemId)) {
            swal("Please select the Jute Item");
            return false;
          } else if (isEmpty(FilteredData[i].qualityCode)) {
            swal("Please select the Quality");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].actualQuantity)) {
            swal("Please Enter the Quantity");
            return false;
          } else if (isEmptyWithZero(FilteredData[i].actualWeight)) {
            swal("Please Enter the Weight");
            return false;
          } else {
            ValidateFields = true;
          }
        }

        const totalWeight = FilteredData.reduce((acc, item) => acc + Number(item.actualWeight), 0);
        
        if (Number(totalWeight) > Number(HeaderData.challanWeightQtl)) {
          swal("", "Sum of Weights should not exceed Challan Weight", "warning");
          return false;
        } else {
          ValidateFields = true;
        }
      }
      if (ValidateFields === true) {
        setCurrentStep(currentStep + 1);
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };
  const onClickBefore = () => {
    if (currentStep == 4 && locationState.state !== undefined) {
      let url = "/security_jute_gate_entry";
      props.history.push(url);
    } else if (currentStep == 2) {
      let url = "/security_jute_gate_entry";
      props.history.push(url);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const onClickCreate = () => {
    var LineItemDTO = [];
    var FilteredData = LineItems.filter(
      (item) =>
        item.isActive !== 0 && item.itemId !== null && item.itemId !== ""
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemDTO.push({
          "advisedQuality": item.qualityCode,
          "actualQuality": item.qualityCode,
          "advisedQuantity": item.actualQuantity,
          "actualQuantity": item.actualQuantity,
          "actualWeight": item.actualWeight,
          "receivedIn": HeaderData.unitConversion,
          "quantity": item.actualQuantity,
          "advisedJuteTyp": item.itemId,
          "actualJuteTyp": item.itemId,
          "qcJuteType": item.itemId,
          "qcJuteQuality": item.qualityCode,
          "qcJuteQuantity": item.actualQuantity,
          "qcJuteWeight": item.actualWeight,
          "unitId": item.unitId,
          "createdBy": JSON.parse(localStorage.getItem("authUser")).userId,
          "isPoAmment": "",
          "juteGateSrlNo":"",
          "kgs": "",
          "poLineItemNum":item.poLineItemNum,
          "isActive":item.isActive,
          "itemGroupId":item.itemGroupId
        });
      });
    }
    var chalanDate = new Date(HeaderData.chalanDate).getTime();
    var  inDate = new Date(HeaderData.inDate).getTime()
    const data = {
      entryHeader: {
        chalanNo: HeaderData.chalanNo,
        chalanDate: chalanDate,
        vehicleNo: HeaderData.vehicleNo,
        driverName: HeaderData.driverName,
        suppCode: HeaderData.suppCode,
        supplierName: HeaderData.supplierName,
        transporter: HeaderData.transporter,
        mukam: HeaderData.mukam,
        grossWeight: HeaderData.grossWeightQtl,
        actualWeight: HeaderData.actualWeightQtl,
        challanWeight: HeaderData.challanWeightQtl,
        tareWeight: HeaderData.tareWeightQtl,
        remarks: HeaderData.remarks,
        inDate: inDate,
        inTime: HeaderData.inTime,
        poNo: HeaderData.poNo,
        mrNo: HeaderData.mrNo,
        createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
        withorWithOutPo: HeaderData.JuteGateEntryType == "WITHPO" ? 1 : 2,
        unitConversion: HeaderData.unitConversion,
        companyId: localStorage.getItem("companyId"),
        finYear: localStorage.getItem("acadamicYear"),
        branchId: HeaderData.branchId,
      },
      juteGateLineItems: LineItemDTO,
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
    };
    dispatch(juteGateEntryUpdate(serverApi.ADD_JUTE_GATE_ENTRY, data, history));
  };
  const onClickUpdate = () => {
    var LineItemDTO = [];
    var FilteredData = LineItems.filter(
      (item) =>
        item.isActive !== 0 && item.itemId !== null && item.itemId !== ""
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemDTO.push({
          "advisedQuality": item.qualityCode,
          "actualQuality": item.qualityCode,
          "advisedQuantity": item.actualQuantity,
          "advisedWeight":item.actualWeight,
          "actualQuantity": item.actualQuantity,
          "actualWeight": item.actualWeight,
          "receivedIn": HeaderData.unitConversion,
          "quantity": item.actualQuantity,
          "advisedJuteTyp": item.itemId,
          "actualJuteTyp": item.itemId,
          "qcJuteType": item.itemId,
          "qcJuteQuality": item.qualityCode,
          "qcJuteQuantity": item.actualQuantity,
          "qcJuteWeight": item.actualWeight,
          "unitId": item.unitId,
          "isPoAmment": "",
          "juteGateSrlNo":"",
          "kgs": "",
          "finYear": localStorage.getItem("acadamicYear"),
          "poLineItemNum": item.poLineItemNum,
          "recId":item.recId,
          "updateBy": JSON.parse(localStorage.getItem("authUser")).userId,
          "poLineItemNum":item.poLineItemNum,
          "isActive":item.isActive,
          "itemGroupId":item.itemGroupId
        });
      });
    }
    var chalanDate = new Date(HeaderData.chalanDate).getTime();
    var  inDate = new Date(HeaderData.inDate).getTime()
    var inTime =new Date(HeaderData.inTime).getTime()
    var outDate =""
    if(!isEmpty(HeaderData.outDate)){
       outDate =new Date(HeaderData.outDate).getTime();
    }
    const data = {
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      entryHeader: {
        id:HeaderData.id,
        chalanNo: HeaderData.chalanNo,
        chalanDate: chalanDate,
        vehicleNo: HeaderData.vehicleNo,
        driverName: HeaderData.driverName,
        suppCode: HeaderData.suppCode,
        supplierName: HeaderData.supplierName,
        transporter: HeaderData.transporter,
        mukam: HeaderData.mukam,
        grossWeight: HeaderData.grossWeightQtl,
        actualWeight: HeaderData.actualWeightQtl,
        challanWeight: HeaderData.challanWeightQtl,
        tareWeight: HeaderData.tareWeightQtl,
        remarks: HeaderData.remarks,
        inDate: inDate,
        inTime:inTime,
        poNo: HeaderData.poNo,
        mrNo: HeaderData.mrNo,
        createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
        withorWithOutPo: HeaderData.JuteGateEntryType == "WITHPO" ? 1 : 2,
        unitConversion: HeaderData.unitConversion,
        companyId: localStorage.getItem("companyId"),
        finYear: localStorage.getItem("acadamicYear"),
        branchId: HeaderData.branchId,
        status: HeaderData.status == 21 ? "1" : "",
        outTime:HeaderData.outTime,
        outDate:outDate
      },
      juteGateLineItems: LineItemDTO,
    };
    dispatch(juteGateEntryUpdate(serverApi.UPDATE_JUTE_GATE_ENTRY, data, history));
  };
  const QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;
    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };
const handleEdit =() =>{
  setCurrentStep(2)
}
  return (
    <div>
      <Grid className="indentContainerBlock">
        <Grid sx={12} className="indentContainerMainBlock">
          <div className="iconsBlock">
            <Stepper alternativeLabel activeStep={currentStep}>
              {steps.map((prop) => (
                <Step key={prop.title}>
                  <StepLabel
                    StepIconComponent={(Iconprops) => {
                      return QontoStepIcon(
                        Iconprops,
                        prop.icon,
                        prop.activeIcon,
                        prop.title
                      );
                    }}
                  ></StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          {currentStep === 1 && (
            <>
              <div>
                <div className="consoleFormButtonBlock">
                  <h4>How do you want to create the Purchase order?</h4>
                </div>
                <div className="consoleFormButtonBlock">
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                    className="btnToggleforIndent"
                  >
                    <ToggleButton value="1" aria-label="centered">
                      <span>With PO</span>
                    </ToggleButton>

                    <ToggleButton value="2" aria-label="left aligned">
                      <span>Without PO</span>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Next"}
                  className={
                    HeaderData.JuteGateEntryType !== ""
                      ? "greenButton"
                      : "disabled"
                  }
                  handleClick={onClickNext}
                  type="sumbit"
                  disabled={HeaderData.JuteGateEntryType == "" ? true : false}
                />
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <JuteGateEntryFillDetails />
              <div className="consoleFormButtonBlock">
              
                <CustomButton
                  label={"Cancel"}
                  className="greenButton"
                  handleClick={onClickBefore}
                  type="sumbit"
                />
             
                <CustomButton
                  label={"Next"}
                  className="greenButton"
                  handleClick={onClickNext}
                  type="sumbit"
                />
              </div>
            </>
          )}
          {currentStep === 3 && (
            <>
              <JuteGateEntryLineItems />
              <div className="consoleFormButtonBlock">
                <Button
                  variant="contained"
                  className="saveButton"
                  onClick={onClickBefore}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  type="sumbit"
                  className="saveButton"
                  onClick={onClickNext}
                >
                  Next
                </Button>
              </div>
            </>
          )}
          {currentStep === 4 && (
            <>
              <JuteGateEntryPreview handleEdit={handleEdit}/>
              <div className="consoleFormButtonBlock">
                {locationState.state === undefined &&
                <CustomButton
                  label={"Back"}
                  className="greenButton"
                  handleClick={onClickBefore}
                  type="sumbit"
                />
                }
               {locationState.state === undefined&& <CustomButton
                  label={"IN"}
                  className="greenButton"
                  handleClick={onClickCreate}
                  type="sumbit"
                />}
                    {locationState.state !==undefined && HeaderData.UpdateButton &&<CustomButton
                        variant="contained"
                        label={"Update"}
                        type="sumbit"
                        className="greenButton"
                        handleClick={() => onClickUpdate("")}
                      />}                     
                    {locationState.state !==undefined && HeaderData.OpenButton &&<CustomButton
                        variant="contained"
                        type="sumbit"
                        className="greenButton"
                        handleClick={() => onClickUpdate("")}
                        label={"Open"}
                     />
                       }     
                    {locationState.state !==undefined && HeaderData.OutButton &&<CustomButton
                       label={"OUT"}
                        variant="contained"
                        type="sumbit"
                        className="greenButton"
                        handleClick={() => onClickUpdate("")}
                      />
                       
                      }
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
export default CreateJuteGateEntry;
