import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import CustomButton from "../../../components/Buttons/Buttons";
import { serverApi, getTenantId } from "../../../helpers/Consts";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import { getBillingShippingList } from "../../../store/Global/DropDownApis/actions";
import { getRegularDeliveryOrderHeader } from "../../../store/Sales/DeliveryOrder/actions";
import moment from "moment";
import { isEmpty } from "../../../Utilities/helper";
import { getFileDownload } from "../../../helpers/server.js";
import { serverConfig } from "../../../helpers/Consts";
import { Box, Tooltip, Grid } from "@mui/material";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import Fade from "@mui/material/Fade";

const RegularPreview = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [gstNo1, setGstNo1] = useState("");
  const [gstNo2, setGstNo2] = useState("");

  const [companyName, setcompanyName] = useState("");
  const [phoneNo, setphoneNo] = useState("");
  const [email, setemail] = useState("");
  const [companyLogo, setcompanyLogo] = useState("");
  const [currentStep, setcurrentStep] = useState(1);
  const [currentDate, setcurrentDate] = useState(new Date().toLocaleString());
  const [editButton, seteditButton] = useState(true);
  const [LineItems, setLineItems] = useState([]);
  const [HeaderData, setHeaderData] = useState({});
  const { DOFiles } = useSelector((state) => state.DeliveryOrderReducer);
  const [deliveryOrderFiles, SetDeliveryOrderFiles] = useState(
    DOFiles?.data?.data || []
  );
  useEffect(() => {
    SetDeliveryOrderFiles(DOFiles?.data?.data);
  }, [DOFiles]);

  useEffect(() => {
    let companyData = JSON.parse(localStorage.getItem("authUser"));
    let companyId = localStorage.getItem("companyId");
    companyData.companyDetails.map((p) => {
      console.log(p, "ppppppp")
      let id = p.value;
      if (companyId === id.toString()) {
        setcompanyName(p.label);
        setcompanyLogo(p.companyLogo);
        setphoneNo(p.contactNo);
        setemail(p.emailId);
        setaddress1(p.address1);
        setaddress2(p.address2);
        setGstNo1(p.gstNo);
        setGstNo2(p.gstNo2);
      }
    });
  });

  const { RegularDeliveryOrderLineItems, RegularDeliveryOrderHeader } =
    useSelector((state) => state.DeliveryOrderReducer);
  const { billingShippingList } = useSelector(
    (state) => state.DropDownListReducer
  );

  useEffect(() => {
    setLineItems(RegularDeliveryOrderLineItems);
    setHeaderData(RegularDeliveryOrderHeader);
  }, [RegularDeliveryOrderLineItems, RegularDeliveryOrderHeader]);

  const handlePdf = (printType) => {
    if (props.locationState.state !== undefined) {
      fetch(
        serverApi.REGULAR_DELIVERY_PDF_DOWNLOAD +
          "1/" +
          HeaderData.regDeliveryOrderId +
          "/" +
          JSON.parse(localStorage.getItem("authUser")).userId +
          "/" +
          printType,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            "X-TenantId": getTenantId(window.location.hostname),
            Authorization:
              localStorage.getItem("token_type") +
              localStorage.getItem("access_token"),
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", HeaderData.deliveryOrderNo + ".pdf");
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
    }
  };

  const convertNumberToWords = (amount) => {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();

    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = "";
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  };

  const handleEdit = () => {
    props.handleCurrentStep(2);
  };

  const withDecimal = (n) => {
    if (n !== undefined) {
      let totalAmount = Number(n).toFixed(2);
      var nums = totalAmount.split(".");
      var whole = convertNumberToWords(nums[0]);

      if (nums.length === 2) {
        let numberAfterDecimal = Number(nums[1]).toFixed(2);
        var decimal = convertNumberToWords(numberAfterDecimal);
        if (parseInt(nums[1]) > 0) {
          return whole + " Rupees and " + decimal + " Paise only";
        }
      }
      return whole + " Rupees only";
    }
  };

  let filterProduct = LineItems.filter((item) => {
    if (item.itemGroupValue === "997") {
      return (
        item.isActive !== 0 &&
        item.itemGroupId !== null &&
        item.itemGroupId !== "" &&
        Number(item.taxPercent) !== 0
      );
    } else {
      return (
        item.isActive !== 0 &&
        item.itemGroupId !== null &&
        item.itemGroupId !== ""
      );
    }
  });
  if (
    HeaderData.salesOrderDate !== null &&
    HeaderData.salesOrderDate !== "" &&
    HeaderData.salesOrderDate !== undefined
  ) {
    var salesOrderDate = moment(
      HeaderData.salesOrderDate,
      "YYYY-MM-DD",
      true
    ).format("DD-MM-YYYY");
  }
  if (
    HeaderData.deliveryDate !== null &&
    HeaderData.deliveryDate !== "" &&
    HeaderData.deliveryDate !== undefined
  ) {
    var deliveryDate = moment(HeaderData.deliveryDate).format("DD-MM-YYYY");
  }
  if (
    HeaderData.createdDate !== null &&
    HeaderData.createdDate !== "" &&
    HeaderData.createdDate !== undefined
  ) {
    var createdDate = moment(HeaderData.createdDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
  }
  let otherChargesItems = LineItems.filter(
    (item) =>
      item.isActive !== 0 &&
      item.itemGroupValue === "997" &&
      Number(item.taxPercent) === 0
  );


  return (
    <>
      <div className="previewBlock">
        <div
          style={{ display: "flex", justifyContent: "end", padding: "10px" }}
        >
          {props.locationState.state && !isEmpty(HeaderData.status) && (
            <CustomButton
              label="Transport Copy"
              handleClick={() => {
                handlePdf(3);
              }}
              type="button"
              className="lightBlueButton"
              muIcon={<PrintIcon />}
            />
          )}
          {props.locationState.state && !isEmpty(HeaderData.status) && (
            <CustomButton
              label="Factory Copy"
              handleClick={() => {
                handlePdf(5);
              }}
              type="button"
              className="lightBlueButton"
              muIcon={<PrintIcon />}
            />
          )}
          {props.locationState.state && !isEmpty(HeaderData.status) && (
            <CustomButton
              label="Office Copy"
              handleClick={() => {
                handlePdf(4);
              }}
              type="button"
              className="lightBlueButton"
              muIcon={<PrintIcon />}
            />
          )}
          {HeaderData.EditButton && (
            <CustomButton
              label="Edit"
              handleClick={handleEdit}
              type="button"
              className="lightBlueButton"
              muIcon={<EditIcon />}
            ></CustomButton>
          )}
        </div>
        <table
          cellpadding="0"
          cellspacing="0"
          width="90%"
          className="previewTable"
        >
          <tbody>
            <tr>
              <td
                colspan="5"
                style={{ borderRight: "none", borderBottom: "none" }}
              >
                <img src={companyLogo} width="100%" alt="" />
              </td>
              <td
                colspan="10"
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  textAlign: "right",
                }}
              >
                <h2>{companyName}</h2>
                {address1}
                <br />
                {address2}
                <br />
                <span>
                  <b>Ph.No: </b>
                  {phoneNo},{" "}
                </span>
                <span>
                  <b>Email: </b> {email}
                </span>
                <br />
                <b>GST No:</b> {gstNo1 || "n/a"}
                <br />
                <br></br>
              </td>
            </tr>
            <tr>
              <td
                colspan="15"
                style={{ textAlign: "center", borderTop: "none" }}
              >
                <h2>DELIVERY ORDER</h2>
              </td>
            </tr>

            <tr>
              <td colspan="3">
                <b>Delivery Order NO : </b>
              </td>
              <td
                colspan="4"
                className={
                  HeaderData.deliveryOrderNo == undefined ? "blur-text" : ""
                }
              >
                {HeaderData.deliveryOrderNo == undefined
                  ? "Will be generated after creation"
                  : HeaderData.deliveryOrderNo}
              </td>
              <td colspan="3">
                <b>Delivery Order Date :</b>
              </td>
              <td colspan="5">{deliveryDate}</td>
            </tr>
            <tr>
              <td colspan="3">
                <b>Sale Order No:</b>
              </td>
              <td
                colspan="4"
                className={HeaderData.salesOrderNumber == "" ? "blur-text" : ""}
              >
                {HeaderData.salesOrderNumber == ""
                  ? "Will be generated after creation"
                  : HeaderData.salesOrderNumber}
              </td>
              <td colspan="3">
                <b>Sale Order Date :</b>
              </td>

              <td colspan="5">{salesOrderDate}</td>
            </tr>
            <tr>
              <td colspan="3">
                <b>Quotation No :</b>
              </td>
              <td colspan="4">
                {HeaderData.quotationNumber ? HeaderData.quotationNumber : ""}
              </td>
              <td colspan="3">
                <b>Quotation Date : </b>
              </td>

              <td colspan="5">
                {HeaderData.quotationDate
                  ? moment(HeaderData.quotationDate).format("DD-MM-YYYY")
                  : ""}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <b>Consignee :</b>
              </td>
              <td colspan="4">{HeaderData.customerName}</td>
              <td colspan="3">
                <b>Branch : </b>
              </td>

              <td colspan="5">{HeaderData.branchName}</td>
            </tr>
            <tr>
              <td colspan="3">
                <b>Broker :</b>
              </td>
              <td colspan="15">{HeaderData.brokerName}</td>
            </tr>
            <tr>
              <td
                colspan="4"
                style={{
                  borderTop: "none",
                  borderBottom: "none",
                  padding: "none",
                }}
              >
                <br />
                <b> Billed To : </b>
                <span>
                  {HeaderData.customerName ? HeaderData.customerName : ""} ,{" "}
                  <br />
                  {HeaderData.billingToAddress}
                </span>

                <br />
                <br />
                <b>GSTIN : </b>
                <span>{HeaderData.billingToGST}</span>
                <br />
                <b>Contact Person :</b>
                <span>{HeaderData.billingContactPerson}</span>
                <br />
                <b>Contact Number :</b>
                <span>{HeaderData.billingContactNo}</span>
                <br />
                <b>Email Id : </b>
                <span>{HeaderData.billingToEmail}</span>
              </td>
              <td
                colspan="4"
                style={{
                  borderTop: "none",
                  borderBottom: "none",
                  padding: "none",
                }}
              >
                <br />
                <b>Shipped To : </b>
                <span>
                  {HeaderData.customerName ? HeaderData.customerName : ""} ,{" "}
                  <br />
                  {HeaderData.shippingToAddress}
                </span>
                <br />
                <br />
                <b>GSTIN : </b>
                <span>{HeaderData.shippingToGST}</span>
                <br />
                <b>Contact Person :</b>
                <span> {HeaderData.shippingContactPerson}</span>
                <br />
                <b>Contact Number :</b>
                <span> {HeaderData.shippingContactNo}</span>
                <br />
                <b>EMAIL ID : </b>
                <span>{HeaderData.shippingToEmail}</span>
              </td>
              <td
                colspan="7"
                style={{
                  borderTop: "none",
                  borderBottom: "none",
                  padding: "none",
                }}
              >
                <br />
                <b> Transport : </b>
                <span>
                  {HeaderData.transporterAddress},
                  {HeaderData.transporterStateName}
                </span>
                <br />
                <br />
                <br />
                <b>Transporter Name : </b>
                <span>{HeaderData.transporterName}</span>
                <br />
                <b>Transporter GSTNo : </b>
                <span>{HeaderData.transporterGst}</span>
                <br />
                <b>Vehicle No: </b>
                <span>{HeaderData.vehicleNo}</span>
                <br />
                <br />
              </td>
            </tr>
            <tr>
              <td rowspan="2" align="center" width={"4%"}>
                <b>S.No</b>
              </td>
              <td rowspan="2" align="center" width={"6%"}>
                <b>Item Code</b>
              </td>
              <td rowspan="2" align="center" width={"7%"}>
                <b>Item Group</b>
              </td>
              <td rowspan="2" align="center" width={"25%"}>
                <b>Item Name</b>
              </td>
              <td rowspan="2" align="center" width={"10%"}>
                <b>Quantity/UOM</b>
              </td>
              <td rowspan="2" align="center" width={"8%"}>
                <b>RATE</b>
              </td>
              <td rowspan="2" align="center" width={"8%"}>
                <b>Rate After Discount</b>
              </td>
              <td rowspan="2" align="center" width={"8%"}>
                <b>Amount</b>
              </td>
              <td colspan="2" align="center" width={"8%"}>
                <b>IGST</b>
              </td>
              <td colspan="2" align="center" width={"8%"}>
                <b>CGST</b>
              </td>
              <td colspan="2" align="center" width={"8%"}>
                <b>SGST</b>
              </td>
            </tr>
            <tr>
              <td>
                <b>%</b>
              </td>
              <td>
                <b>Amt</b>
              </td>
              <td>
                <b>%</b>
              </td>
              <td>
                <b>Amt</b>
              </td>
              <td>
                <b>%</b>
              </td>
              <td>
                <b>Amt</b>
              </td>
            </tr>
            {filterProduct &&
              filterProduct.map((item, i) => {
                return (
                  <>
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item.itemCode}</td>
                      <td>{item.itemGroupName}</td>
                      <td>
                        {item.itemName}
                        <br />
                        <span>
                          <b>Remarks :</b> {item.remarks}
                        </span>{" "}
                        <br />
                        <span>
                          <b>Make :</b> {item.itemMake}
                        </span>{" "}
                        <br />
                        <span>
                          <b>HSN Code :</b> {item.hsncode}
                        </span>
                      </td>
                      <td align="right">
                        {parseFloat(item.quantity).toFixed(3)}/{item.uom}
                      </td>
                      <td align="right">{parseFloat(item.rate).toFixed(3)}</td>
                      <td align="right">
                        {parseFloat(item.discountedRate).toFixed(3)}
                      </td>
                      <td align="right">
                        {parseFloat(item.netAmount).toFixed(2)}
                      </td>
                      <td align="right">
                        {parseFloat(item.igstPercent).toFixed(2)}
                      </td>
                      <td align="right">
                        {parseFloat(item.igstAmount).toFixed(2)}{" "}
                      </td>
                      <td align="right">
                        {parseFloat(item.cgstPercent).toFixed(2)}
                      </td>
                      <td align="right">
                        {parseFloat(item.cgstAmount).toFixed(2)}
                      </td>
                      <td align="right">
                        {parseFloat(item.sgstPercent).toFixed(2)}
                      </td>
                      <td align="right">
                        {parseFloat(item.sgstAmount).toFixed(2)}
                      </td>
                    </tr>
                  </>
                );
              })}
            <tr>
              <td colspan="3">
                <b>Taxable Value</b>
              </td>
              <td colspan="13">{parseFloat(HeaderData.netTotal).toFixed(2)}</td>
            </tr>
            {otherChargesItems &&
              otherChargesItems.map((item) => {
                return (
                  <tr>
                    <td colspan="3">
                      <b> {item.itemName}</b>
                    </td>
                    <td colspan="16">
                      {parseFloat(item.netAmount).toFixed(2)}
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td colspan="3">
                <b>Total IGST</b>
              </td>
              <td colspan="13">
                {parseFloat(HeaderData.totalIGST).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <b>Total CGST</b>
              </td>
              <td colspan="13">
                {parseFloat(HeaderData.totalCGST).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <b>Total SGST</b>
              </td>
              <td colspan="13">
                {parseFloat(HeaderData.totalSGST).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <b>Total Value with Tax</b>
              </td>
              <td colspan="13">
                {parseFloat(HeaderData.TotalAmount).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <b>Total Amount in words</b>
              </td>
              <td colspan="13">{withDecimal(HeaderData.TotalAmount)}</td>
            </tr>
            <tr>
              <td
                colspan="14"
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderBottom: "none",
                  marginTop: "5px",
                }}
              >
                <span>
                  <br />
                  <br />
                  <b>Bank Details:</b>
                  <br />
                  <b>{companyName}</b>
                  <br />
                  <b> Bank:{"  "}</b>
                  {HeaderData.bankName}
                  <br />
                  <b> Branch: {"  "}</b>
                  {HeaderData.bankBranch}
                  <br />
                  <b> A/c No:{"  "}</b>
                  {HeaderData.accountNo}
                  <br />
                  <b> IFSC Code:{"  "}</b>
                  {HeaderData.ifscCode}
                  <br />
                  <b> MICR No:{"  "}</b>
                  {HeaderData.micrCode}
                  <br />
                  <br />
                  <br />
                </span>
                <br />
                <span>
                  <b>Delivery Days :</b>{" "}
                  <span>
                    {HeaderData.deliveryDays}{" "}
                    {HeaderData.deliveryDays ? "Days" : ""}
                  </span>{" "}
                </span>
                <br />
                <span>
                  <b>Delivery terms :</b>{" "}
                  <span>{HeaderData.deliveryTerms}</span>
                </span>
                <br />
                <span>
                  <b>Payment Terms (In Days) : </b>{" "}
                  <span>{HeaderData.paymentTerms}</span>{" "}
                </span>
                <br />
                <span>
                  <b>Footer Notes : </b> {HeaderData.footerNote}
                </span>
                <br />
                <span>
                  <b>Internal Notes : </b>
                  {HeaderData.internalNote}{" "}
                </span>
                <br />
                <br />
                <b>Broker Percentage </b>
                {parseFloat(
                  HeaderData.brokerCommisionPercent
                    ? HeaderData.brokerCommisionPercent
                    : 0
                ).toFixed(2)}
                %
                <tr>
                    <td
                      colSpan="10"
                      style={{
                        borderTop: "none",
                        borderBottom: "none",
                        borderRight: "none",
                        borderLeft: "none",
                      }}
                    >
                {props.locationState.state !== undefined ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box gridColumn="span 12">
                            <span>
                              <b>Support Documents:</b>
                            </span>
                          </Box>
                          <Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={2}
                            className="ducumentUploadBlock uploadedContent"
                            style={{ padding: "10px" }}
                          >
                            {deliveryOrderFiles?.length > 0 &&
                              deliveryOrderFiles.map((item) => (
                                <Box gridColumn="span 6">
                                <div
                                  className="documentUploadContent"
                                  key={item.fileUploadId}
                                >
                                  <div
                                    className="uploadedInfo"
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <img
                                      src={TickIcon}
                                      alt=""
                                      className="tickIcon"
                                      style={{
                                        height: "16px",
                                        width: "16px",
                                        marginRight: "8px",
                                      }}
                                    />
                                    <Tooltip
                                      TransitionComponent={Fade}
                                      TransitionProps={{ timeout: 600 }}
                                      title="Click to download File"
                                    >
                                      <div
                                        className="uplodedFileName"
                                        onClick={() => {
                                          getFileDownload(
                                            serverConfig.SERVER_URL +
                                              "security-api/api/files/downloadfile/" +
                                              item.fileUploadId,
                                            item.fileName,
                                            item.fileExtension
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.fileName}
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                                </Box>
                              ))}
                          </Box>
                        </Grid>
                      </Grid>
                   
                ) : (
                  ""
                )}
                 </td>
                  </tr>
                <br />
                <br />
                <span>
                  <b>Terms and Conditions: </b> <br />
                  {HeaderData.termsCondition}
                </span>
                <br />
                <br />
                {props.locationState.state !== undefined ? (
                  <>
                    <b>Last Modified On: </b> {HeaderData.updatedOn}
                  </>
                ) : (
                  ""
                )}
                <br />
                {props.locationState.state !== undefined ? (
                  <>
                    <b>Last Modified By: </b> {HeaderData.updatedBy}
                  </>
                ) : (
                  ""
                )}
                <br />
                <br />
                {props.locationState.state !== undefined ? (
                  <b>Status: {HeaderData.statusName}</b>
                ) : (
                  ""
                )}
                <br />
                <br />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RegularPreview;
