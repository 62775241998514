import {LEDGER_MASTER_LIST,LEDGER_MASTER_LIST_SUCCESSFULL,
    CREATE_LEDGER_MASTER,CREATE_LEDGER_MASTER_SUCCESSFULL,
    UPDATE_LEDGER_MASTER,UPDATE_LEDGER_MASTER_SUCCESSFULL,
    API_ERRORS,} from './actionTypes';

export const ledgerMasterList = (url,data,history) =>{
    return {
        type:LEDGER_MASTER_LIST,
        payload:{url,data,history}
    }
}
export const ledgerMasterListSuccessfull = (data)=>{
    return {
        type:LEDGER_MASTER_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createLedgerMaster = (url, data, history) => {
    return {
        type: CREATE_LEDGER_MASTER,
        payload: { url, data, history }
    }
}

export const createLedgerMasterSuccessfull = (data) => {
    return {
        type: CREATE_LEDGER_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const updateLedgerMaster = (url, data, history) => {
    return {
        type: UPDATE_LEDGER_MASTER,
        payload: { url, data, history }
    }
}

export const updateLedgerMasterSuccessfull = (data) => {
    return {
        type: UPDATE_LEDGER_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const apiErrors = (error) => {
    return {
        type: API_ERRORS,
        payload: error,
    };
};