import React, { Component } from "react";
import CustomButton from "../../../components/Buttons/Buttons";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi, getTenantId,serverConfig } from "../../../helpers/Consts";
import { getSupplierList } from "../../../store/Global/DropDownApis/actions";
import { getSupplierListBySuppId } from "../../../store/Global/DropDownApis/actions";
import { BillingAdsShippingAds } from "../../../store/Global/DropDownApis/actions";
import {
  getFiles,
} from "../../../store/Purchase/Indent/actions";
import { getFileDownload } from "../../../helpers/server.js";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Grid, } from "@mui/material";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import moment from "moment";

class POPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      date: moment().format(),
      currentStep: 2,
      companyName: "",
      companyLogo: "",
      gstNo: "",
      address1: "",
      address2: "",
      comName: "",
      phoneNo: "",
      email: "",
      poId: "",
      woId: "",
      poSequenceNo: "",
      supplierName: "",
      poDetails: [],
      supplierListData: [],
      supplierBranchData: [],
      BillingShippingAdsList: [],
      editButton: true,
      locationState: "",
      files_List: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      locationState: this.props.location,
    });
    let companyData = JSON.parse(localStorage.getItem("authUser"));
    let companyId = localStorage.getItem("companyId");
    companyData.companyDetails.map((p) => {
      let id = p.value;
      if (companyId === id.toString()) {
        let companyName = p.label.split("-");
        this.setState({
          companyName: companyName[1],
          address1: p.address1,
          companyLogo: p.companyLogo,
          gstNo: p.gstNo,
          address2: p.address2,
          comName: p.name,
          phoneNo: p.contactNo,
          email: p.emailId,
        });
      }
    });

    if (this.props.location.state) {
      let id = this.props.location.state.rowData.id;
      let poSequenceNo = this.props.location.state.rowData.poSequenceNo;
      let supplierName = this.props.location.state.rowData.supplierName;
      this.setState({
        poId: id,
        poSequenceNo: poSequenceNo,
        supplierName: supplierName,
      });
      if (this.props.po_wo_lineitems) {
        this.setState({
          poDetails: this.props.po_wo_lineitems,
        });
      }
    }
    if (this.props.po_wo_lineitems) {
      this.setState({
        poDetails: this.props.po_wo_lineitems,
      });
    }

    if (this.props.PoFillDetails.Supplier_value) {
      this.props.getSupplierListBySuppId(
        serverApi.GET_SUPPLIER_BY_SUPPLIER_ID +
          this.props.PoFillDetails.Supplier_value +
          "/" +
          this.state.userDit.userId,
        this.state.history
      );
      this.props.getSupplierList(
        serverApi.GET_ALL_SUPPLIERS_LIST + localStorage.getItem("companyId"),
        this.props.history
      );
    }
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
    };
    this.props.BillingAdsShippingAds(
      serverApi.GET_BILLING_ADDRESS_SHIPPING_ADDRESS,
      data,
      this.state.history
    );
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData !== undefined) {
        if (
          this.props.location.state.rowData.status === 3 ||
          this.props.location.state.rowData.status === 4 ||
          this.props.location.state.rowData.status === 5
        ) {
          this.setState({
            editButton: false,
          });
        }
      }
    }
    if (this.props.location.state) {
      let id = this.props.location.state.rowData.id;
      this.props.getFiles(
        serverApi.GET_FILES +
          "14/" +
          id +
          "/" +
          localStorage.getItem("companyId"),
        this.props.history
      );}
  }
  componentWillReceiveProps(props) {
    if (props.supplierList) {
      this.setState({
        supplierListData: props.supplierList.data,
      });
    }

    if (props.supplierListBy_suppId.data) {
      let autoId;
      if (props.supplierListBy_suppId.data) {
        this.props.supplierBranchDetails.gstin =
          props.supplierListBy_suppId.data.gstNo;
        this.props.supplierBranchDetails.Contact_person =
          props.supplierListBy_suppId.data.contactPerson;
        this.props.supplierBranchDetails.Contact_Number =
          props.supplierListBy_suppId.data.cellPhone;
        this.props.supplierBranchDetails.email =
          props.supplierListBy_suppId.data.email;
      }

      if (this.state.Supplier_value) {
        this.setState({
          Supplier_Branch_value: autoId,
          Supplier_Branch: autoId,
        });
        this.props.PoFillDetails.Supplier_Branch_value = autoId;
      }
    }
    if (props.billing_shipping_adsList) {
      if (props.billing_shipping_adsList.data) {
        props.billing_shipping_adsList.data.map((item) => {
          if (this.props.PoFillDetails.Billing_Address_value) {
            if (this.props.PoFillDetails.Billing_Address_value === item.id) {
              //for preview
              this.props.poBilledToDetails.gstin = item.gstNo;
              this.props.poBilledToDetails.Contact_person = item.personName;
              this.props.poBilledToDetails.Contact_Number = item.contactNo;
              this.props.poBilledToDetails.email = item.email;
            }
          }
          if (this.props.PoFillDetails.shippingAddress_Value) {
            if (this.props.PoFillDetails.shippingAddress_Value === item.id) {
              //for preview
              this.props.poShippedToDetails.gstin = item.gstNo;
              this.props.poShippedToDetails.Contact_person = item.personName;
              this.props.poShippedToDetails.Contact_Number = item.contactNo;
              this.props.poShippedToDetails.email = item.email;
            }
          }
        });
      }
    }
    if (props.files_List) {
      if (props.files_List.data) {
        let filesList = props.files_List.data;
        this.setState({
          files_List: filesList.data,
        });
      }
    }
  }

  handleEdit = () => {
    this.props.handleCurrentStep(this.state.currentStep);
  };
  handlePdf = () => {
    let Id = this.state.poId;
    // this.props.getFileDownload(serverApi.DOWNLOAD_PDF+indentId+'/'+this.state.userDit.userId)
    fetch(serverApi.DOWNLOAD_PO + Id + "/" + this.state.userDit.userId, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        "X-TenantId": getTenantId(window.location.hostname),
        Authorization:
          localStorage.getItem("token_type") +
          localStorage.getItem("access_token"),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          this.state.poSequenceNo + "_" + this.state.supplierName + ".pdf"
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };
  convertNumberToWords(amount) {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = "";
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakh ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  }

  withDecimal(n) {
    if (n !== undefined) {
      var nums = n.toString().split(".");
      var whole = this.convertNumberToWords(nums[0]);

      if (nums.length == 2) {
        if (parseInt(nums[1]) > 0) {
          return whole + "Rupees only";
        }
      }
      return whole + "Rupees only";
    }
  }
  render() {
    let supplier = this.props.PoFillDetails.Supplier.split("_");

    var DateTime = this.state.date.split("T", 2);
    var TodayDate = moment(DateTime[0], "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var Time = DateTime[1].split("+")[0];
    var totalAmount = 0;

    if (this.state.poDetails) {
      var poDetailsItems = [];
      var otherChargesListWithNoTax = [];
      otherChargesListWithNoTax = this.state.poDetails.filter((item) => {
        if (Number(item.itemGroupId) === 997) {
          return item.isActive !== 0 && Number(item.taxPercentage) === 0;
        }
      });
      if (this.props.source === "WITHOPENINDENT") {
        poDetailsItems = this.state.poDetails.filter((item) => {
          if (item.departmentId !== "" && item.isActive !== 0) {
            if (Number(item.itemGroupId) === 997) {
              if (Number(item.taxPercentage) !== 0) {
                return item;
              }
            } else {
              return item;
            }
          }
        });
      } else {
        poDetailsItems = this.state.poDetails.filter((item) => {
          if (Number(item.itemGroupId) === 997) {
            if (Number(item.taxPercentage) !== 0) {
              return item.isActive !== 0;
            }
          } else {
            return (
              item.departmentId !== "" && item.isActive !== 0 && item.qty !== 0
            );
          }
        });
      }

      var toatlAmount = 0; //price item1 + price of item2
      var netAmount = 0; // price item1 + price of item2 + gst of item1 + gst of item2
      var totalGst = 0; // gst of item1 + gst of item2
      var totalCgstAmount = 0;
      var totalSgstAmount = 0;

     let filterProducts = this.state.poDetails.filter((item) => item.isActive !=0)
      filterProducts.map((data) => {
        var lineItemAmt = 0;
        lineItemAmt += data.qty * data.rate;
        var totalInstallationRate = 0;
        if (data.installationRate !== null) {
          totalInstallationRate = data.installationRate * data.qty;
        }
        lineItemAmt += totalInstallationRate;

        if (
          data.discountMode !== undefined &&
          data.discountMode !== "" &&
          data.discountMode !== null
        ) {
          if (data.discountMode === 1) {
            // fixed
            lineItemAmt -= data.discountPercentage;
          } else if (data.discountMode === 2) {
            // percentage
            var discountAmt = (lineItemAmt * data.discountPercentage) / 100;
            lineItemAmt -= discountAmt;
          }
        }

        // gst check
        var gstAmount = 0;

        if (this.props.PoFillDetails.tax_payable_value === 1) {
          if (this.props.PoFillDetails.tax_type_value === 1) {
            gstAmount = (lineItemAmt * data.taxPercentage) / 100;
            totalGst += gstAmount;
          } else {
            gstAmount = (lineItemAmt * data.taxPercentage) / 100;
            totalGst += gstAmount; // totalGst = totalGst + gstAmount
            totalCgstAmount += gstAmount / 2;
            totalSgstAmount += gstAmount / 2;
          }
        }

        toatlAmount += lineItemAmt;
      });
      netAmount += toatlAmount + totalGst;
    }

    // var netTotal = toatlAmount + totalGst + totalCgstAmount + totalSgstAmount;
    var netTotal = netAmount;
    this.props.PoFillDetails.totalAmount = totalAmount;
    this.props.PoFillDetails.netTotal = netTotal;
    if (netTotal != null || netTotal !== undefined || netTotal !== "") {
      const TotalVal = Math.ceil(Math.round(netTotal));
      var convertion = this.withDecimal(TotalVal);
    }
   
    return (
      <>
        <div className="previewBlock">
          <div
            style={{ display: "flex", justifyContent: "end", padding: "10px" }}
          >
            {this.state.locationState.state !== undefined ? (
              <>
                <CustomButton
                  label="Print"
                  className="lightBlueButton"
                  muIcon={<PrintIcon />}
                  type="button"
                  handleClick={this.handlePdf}
                />
                {this.props.PoFillDetails.statusName === "APPROVED" ||
                this.props.PoFillDetails.statusName === "REJECTED" ||
                this.props.PoFillDetails.statusName === "CLOSED" ||
                this.props.PoFillDetails.statusName === "CANCELLED" ? (
                  ""
                ) : (
                  <CustomButton
                    label="Edit"
                    className="lightBlueButton"
                    muIcon={<EditIcon />}
                    handleClick={this.handleEdit}
                    type="button"
                  />
                )}
              </>
            ) : (
              ""
            )}
          </div>
          <table
            cellpadding="0"
            cellspacing="0"
            width="90%"
            className="previewTable"
          >
            <tbody>
              <tr>
                <td
                  colspan="2"
                  style={{ borderRight: "none", borderBottom: "none" }}
                >
                  <img src={this.state.companyLogo} width="100%" alt="" />
                </td>
                <td
                  colspan="12"
                  style={{
                    borderBottom: "none",
                    borderLeft: "none",
                    textAlign: "right",
                  }}
                >
                  <h2>{this.state.companyName}</h2>
                  <p style={{ margin: "0px" }}>{this.state.address1}</p>
                  <p style={{ margin: "0px" }}>{this.state.address2}</p>
                  <span>
                    {" "}
                    <b>GST No: </b>
                    {this.state.gstNo}
                  </span>
                  <p style={{ margin: "0px" }}>
                    <b>Ph.No: </b>
                    {this.state.phoneNo}
                  </p>
                </td>
              </tr>
              <tr>
                <td
                  colspan="12"
                  style={{ textAlign: "center", borderTop: "none" }}
                >
                  <h2>
                    {this.props.location.pathname === "/create_workorder"
                      ? "Work Order"
                      : "Purchase Order"}
                  </h2>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>
                    {" "}
                    {this.props.location.pathname === "/create_workorder"
                      ? "WO NO :"
                      : "PO No :"}{" "}
                  </b>
                </td>
                <td
                  colspan="5"
                  className={
                    this.state.poSequenceNo === "" ||
                    this.state.poSequenceNo === undefined
                      ? "blur-text"
                      : ""
                  }
                >
                  {this.state.poSequenceNo === "" ||
                  this.state.poSequenceNo === undefined
                    ? "Will be generated after creation"
                    : this.state.poSequenceNo}
                </td>
                <td colspan="2">
                  <b>
                    {" "}
                    {this.props.location.pathname === "/create_workorder"
                      ? "WO DATE :"
                      : "PO DATE :"}
                  </b>
                </td>
                <td colspan="4">
                  {moment(this.props.PoFillDetails.datevalue).format(
                    "DD-MM-YYYY"
                  )}
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Delivery By: </b>
                </td>
                <td colspan="5">{this.props.PoFillDetails.expected_date}</td>
                <td colspan="2">
                  <b>
                    {" "}
                    {this.props.location.pathname === "/create_workorder"
                      ? "WO Valid Till :"
                      : "PO Valid Till :"}
                  </b>
                </td>
                <td colspan="4">
                  {moment(this.props.PoFillDetails.poTillDate).format(
                    "DD-MM-YYYY"
                  )}
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Advance(%):</b>
                </td>
                <td colspan="5">
                  {this.props.PoFillDetails.advancePercentage === null
                    ? "0"
                    : Number(this.props.PoFillDetails.advancePercentage)}
                  {this.props.PoFillDetails.advancePercentage ? "%" : ""}
                </td>
                <td colspan="2">
                  <b>Advance Amount:</b>
                </td>
                <td colspan="5">{this.props.PoFillDetails.advanceAmount}</td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Contact Person: </b>
                </td>
                <td colspan="5">{this.props.PoFillDetails.contact_person}</td>
                <td colspan="2">
                  <b>Contact no :</b>
                </td>
                <td colspan="5">{this.props.PoFillDetails.contact_no}</td>
              </tr>
              <tr>
                <td colspan="2" valign="top">
                  <b>
                    {" "}
                    {this.props.location.pathname === "/create_workorder"
                      ? "Wo Issued To :"
                      : "Po Issued To :"}
                  </b>
                  <br />
                  <span>
                    {supplier[0]}
                    <br />
                    {supplier[1]}
                  </span>
                  <br />
                  {this.props.PoFillDetails.Supplier_Branch}
                  <br />
                  <span>
                    <b>GSTIN:</b>
                    {this.props.supplierBranchDetails.gstin}
                  </span>
                  <br />
                  <span>
                    <b>Contact person:</b>
                    {this.props.supplierBranchDetails.Contact_person}
                  </span>
                  <br />
                  <span>
                    <b>Contact Number:</b>
                    {this.props.supplierBranchDetails.Contact_Number}
                  </span>
                  <br />
                  <span>
                    <b>Email:</b>
                    {this.props.supplierBranchDetails.email}
                  </span>
                </td>
                <td colspan="5" valign="top">
                  <b>
                    {" "}
                    {this.props.location.pathname === "/create_workorder"
                      ? "Wo Billed To"
                      : "Po Billed To :"}{" "}
                  </b>
                  <br />
                  {this.state.companyName}
                  <br />
                  <span>{this.props.PoFillDetails.Billing_Address}</span>
                  <br />
                  <span>
                    <b>GSTIN:</b>
                    {this.props.poBilledToDetails.gstin}
                  </span>
                  <br />
                  <span>
                    <b>Contact person:</b>
                    {this.props.poBilledToDetails.Contact_person}
                  </span>
                  <br />
                  <span>
                    <b>Contact Number:</b>
                    {this.props.poBilledToDetails.Contact_Number}
                  </span>
                  <br />
                  <span>
                    <b>Email:</b>
                    {this.props.poBilledToDetails.email}
                  </span>
                </td>
                <td
                  colspan="4"
                  valign="top"
                  align="left"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  <b>
                    {" "}
                    {this.props.location.pathname === "/create_workorder"
                      ? "Wo Shipped To"
                      : "Po Shipped To :"}
                  </b>
                  <br />
                  {this.state.companyName}
                  <br />
                  <span>{this.props.PoFillDetails.shippingAddress}</span>
                  <br />
                  {/* <span>{this.props.PoFillDetails.shippingAddress.split('-')[1]}</span><span>-{this.props.PoFillDetails.shippingAddress.split('-')[2]}</span><br/> */}
                  <span>
                    <b>GSTIN:</b>
                    {this.props.poShippedToDetails.gstin}
                  </span>
                  <br />
                  <span>
                    <b>Contact person:</b>
                    {this.props.poShippedToDetails.Contact_person}
                  </span>
                  <br />
                  <span>
                    <b>Contact Number:</b>
                    {this.props.poShippedToDetails.Contact_Number}
                  </span>
                  <br />
                  <span>
                    <b>Email:</b>
                    {this.props.poShippedToDetails.email}
                  </span>
                </td>
              </tr>
              <tr>
                <td width="6%" align="center">
                  <b>Item Code(HSN/SAC)</b>
                </td>
                <td width="25%" align="center">
                  <b>Item Description</b>
                </td>
                <td width="8%" align="center">
                  <b>Quantity</b>
                </td>
                <td width="5%" align="center">
                  <b>Uom</b>
                </td>
                <td width="8%" align="center">
                  <b>Supp Rate</b>
                </td>
                <td width="8%" align="center">
                  <b>Inst Rate</b>
                </td>
                <td width="8%" align="center">
                  <b>Supp Amount</b>
                </td>
                <td width="8%" align="center">
                  <b>Inst Amount</b>
                </td>
                <td width="8%" align="center">
                  <b>Sub Total</b>
                </td>
                <td width="6%" align="center">
                  <b>Gst%</b>
                </td>
                <td width="6%" align="center">
                  <b>Discount</b>
                </td>
                <td width="4%" align="center">
                  <b>Currency</b>
                </td>
              </tr>
              {poDetailsItems &&
                poDetailsItems.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {data.itemCode}
                        <br />
                        HSN Code: {data.hsnCode}
                      </td>
                      <td>
                        {data.itemName ? data.itemName : data.description}
                        <tr>Make:{data.make}</tr>
                        <tr>Remarks:{data.remarks}</tr>
                      </td>
                      <td align="right">
                        {Number(data.itemGroupId) === 997
                          ? 1
                          : parseFloat(data.qty).toFixed(3)}{" "}
                      </td>
                      <td align="right">
                        {data.uomCode ? data.uomCode : data.qty_uom}{" "}
                      </td>
                      <td align="right">
                        {parseFloat(Number(data.rate)).toFixed(3)}
                      </td>
                      <td align="right">
                        {" "}
                        {parseFloat(Number(data.installationRate)).toFixed(3)}
                      </td>
                      <td align="right">
                        {parseFloat(
                          data.installationRate === null ||
                            data.installationRate === 0
                            ? Number(data.qty) * Number(data.rate)
                            : data.installationRate * Number(data.qty) +
                                (Number(data.itemGroupId) === 997
                                  ? 1
                                  : Number(data.qty) * Number(data.rate))
                        ).toFixed(2)}
                      </td>
                      <td align="right">
                        {" "}
                        {parseFloat(
                          Number(
                            data.installationRate === null
                              ? 0
                              : data.installationRate * Number(data.qty)
                          )
                        ).toFixed(2)}
                      </td>
                      <td align="right">
                        {" "}
                        {data.discountMode === 1
                          ? parseFloat(
                              Number(
                                data.qty
                                // -(data.qtyPo === undefined ? 0 : data.qtyPo)
                              ) *
                                (Number(data.rate) +
                                  Number(data.installationRate)) -
                                Number(data.discountPercentage)
                            ).toFixed(2)
                          : parseFloat(
                              Number(
                                data.qty
                                // -(data.qtyPo === undefined ? 0 : data.qtyPo)
                              ) *
                                (Number(data.rate) +
                                  Number(data.installationRate)) -
                                (Number(data.discountPercentage) / 100) *
                                  (Number(data.qty) *
                                    (Number(data.rate) +
                                      Number(data.installationRate)))
                            ).toFixed(2)}
                      </td>
                      <td align="right">
                        {this.props.PoFillDetails.tax_payable_value === 2
                          ? 0 + "%"
                          : data.taxPercentage + "%"}
                      </td>
                      <td align="right">
                        {data.discountPercentage
                          ? parseFloat(data.discountPercentage).toFixed(2)
                          : "0.00"}
                        {data.discountMode && data.discountMode !== 1
                          ? "%"
                          : ""}
                      </td>
                      <td align="right">
                        {this.props.PoFillDetails.currency
                          ? this.props.PoFillDetails.currency
                          : ""}
                      </td>
                    </tr>
                  );
                })}
              {otherChargesListWithNoTax.map((data, index) => {
                return (
                  <tr key={index}>
                    <td colspan="2">
                      <b>{data.itemName}</b>
                    </td>
                    <td colSpan={10}>
                      <b>
                        {data.discountMode === 1
                          ? parseFloat(
                              Number(data.qty) *
                                (Number(data.rate) +
                                  Number(data.installationRate)) -
                                Number(data.discountPercentage)
                            ).toFixed(2)
                          : parseFloat(
                              Number(data.qty) *
                                (Number(data.rate) +
                                  Number(data.installationRate)) -
                                (Number(data.discountPercentage) / 100) *
                                  (Number(data.qty) *
                                    (Number(data.rate) +
                                      Number(data.installationRate)))
                            ).toFixed(2)}
                      </b>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td colspan="2">
                  <b>Sub Total</b>
                </td>
                <td colspan="10">
                  <b>{parseFloat(Number(toatlAmount)).toFixed(2)}</b>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b> Total IGST</b>
                </td>
                <td colspan="10">
                  <b>
                    {" "}
                    {this.props.PoFillDetails.tax_type_value === 1
                      ? parseFloat(Number(totalGst)).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                  </b>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Total CGST </b>
                </td>
                <td colspan="10">
                  <b>
                    {this.props.PoFillDetails.tax_type_value === 2
                      ? parseFloat(Number(totalCgstAmount)).toFixed(2)
                      : parseFloat(0).toFixed(2)}{" "}
                  </b>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Total SGST</b>
                </td>
                <td colspan="10">
                  <b>
                    {this.props.PoFillDetails.tax_type_value === 2
                      ? parseFloat(Number(totalSgstAmount)).toFixed(2)
                      : parseFloat(0).toFixed(2)}{" "}
                  </b>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Total Value</b>
                </td>
                <td colspan="10">
                  <b> {parseFloat(Math.round(Number(netAmount))).toFixed(2)}</b>
                  {/* <b>{parseFloat(Math.round(Number(netTotal))).toFixed(2)}</b> */}
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>Total Value(in Words)</b>
                </td>
                <td colspan="10">
                  <b>{convertion}</b>
                </td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  align="left"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  <b>Status:</b> {this.props.PoFillDetails.statusName}
                  <br />
                  <br />
                  <b>footer Notes:</b> {this.props.PoFillDetails.footerNotes}
                  <br />
                  <b>Internal Notes:</b> {this.props.PoFillDetails.remarks}
                  <br />
                </td>
              </tr>
              <tr>
                <td
                  colspan="6"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {this.state.locationState.state && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box gridColumn="span 12">
                          {this.state.files_List.length !== 0 &&(<span>
                            <b>Uploaded Documents</b>
                          </span>)}
                        </Box>
                        <Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={2}
                          className="ducumentUploadBlock"
                          style={{ padding: "10px" }} 
                        >
                          {this.state.files_List &&
                            this.state.files_List.map((item) => (
                              <Box gridColumn="span 6">
                                <div className="documentUploadContent">
                                  <div
                                    className="uploadedInfo"
                                    style={{ display: "flex" }}
                                  >
                                    <img
                                      src={TickIcon}
                                      alt=""
                                      className="tickIcon"
                                      height={"20px"}
                                      width={"20px"}
                                    />
                                    <Tooltip
                                      TransitionComponent={Fade}
                                      TransitionProps={{ timeout: 600 }}
                                      title="Click to download File"
                                    >
                                      <div
                                        className="uplodedFileName"
                                        onClick={() => {
                                          getFileDownload(
                                            serverConfig.SERVER_URL +
                                              "security-api/api/files/downloadfile/" +
                                              item.fileUploadId,
                                            item.fileName,
                                            item.fileExtension
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.fileName}
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </Box>
                            ))}
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { po_wo_lineitems } = state.PurchaseOrderReducer;
  const { supplierList, supplierListBy_suppId, billing_shipping_adsList } =
    state.DropDownListReducer;
    const {files_List} = state.IndentReducer
  return {
    ...state,
    supplierList,
    files_List,
    supplierListBy_suppId,
    billing_shipping_adsList,
    po_wo_lineitems,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSupplierList,
    getSupplierListBySuppId,
    BillingAdsShippingAds,
    getFiles,
    getFileDownload
  })(POPreview)
);
