import {
  HOLIDAY_MASTER_LIST,
  HOLIDAY_MASTER_LIST_SUCCESSFULL,
  HOLIDAY_DATA_ADD,
  HOLIDAY_DATA_ADD_SUCCESSFULL,
  DELETE_HOLIDAY,
  DELETE_HOLIDAY_SUCCESSFULL,
  HOLIDAY_DATA_UPDATE,
  HOLIDAY_DATA_UPDATE_SUCCESSFULL,
  UPDATE_HOLIDAY_LIST_FOR_EMPLOYEES,
  UPDATE_HOLIDAY_LIST_FOR_EMPLOYEES_SUCCESSFULL,
} from "./actionTypes";

export const HolidayMasterList = (url, data, history) => {
  return {
    type: HOLIDAY_MASTER_LIST,
    payload: { url, data, history },
  };
};
export const HolidayMasterListSuccessfull = (data) => {
  return {
    type: HOLIDAY_MASTER_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const holidayDataAdd = (url, data, history) => {
  return {
    type: HOLIDAY_DATA_ADD,
    payload: { url, data, history },
  };
};
export const holidayDataAddSuccessfull = (data) => {
  return {
    type: HOLIDAY_DATA_ADD_SUCCESSFULL,
    payload: data,
  };
};

export const deleteHolidayList = (url, data, history) => {
  return {
    type: DELETE_HOLIDAY,
    payload: { url, data, history },
  };
};
export const deleteHolidayListSuccessfull = (data) => {
  return {
    type: DELETE_HOLIDAY_SUCCESSFULL,
    payload: data,
  };
};

export const holidayDataUpdate = (url, data, history) => {
  return {
    type: HOLIDAY_DATA_UPDATE,
    payload: { url, data, history },
  };
};
export const holidayDataUpdateSuccessfull = (data) => {
  return {
    type: HOLIDAY_DATA_UPDATE_SUCCESSFULL,
    payload: data,
  };
};
export const UpdateholidayListForEmployees = (url, history) => {
  return {
    type:UPDATE_HOLIDAY_LIST_FOR_EMPLOYEES,
    payload: { url, history },
  };
};
export const UpdateholidayListForEmployeesSuccessfull = (data) => {
  return {
    type: UPDATE_HOLIDAY_LIST_FOR_EMPLOYEES_SUCCESSFULL,
    payload: data,
  };
};