export const SALESORDER_LIST = "salesorder_list";
export const SALESORDER_LIST_SUCCESSFULL = "salesorder_list_successfull";
//for sales order jut govt 
export const CREATE_JUTEGOVT_SALES_ORDER = "create_jutegovt_sales_order"
export const CREATE_JUTEGOVT_SALES_ORDER_SUCCESSFULL = "create_jutegovt_sales_order_successfull"
export const UPDATE_JUTEGOVT_SALES_ORDER = "Update_jutegovt_sales_order"
export const UPDATE_JUTEGOVT_SALES_ORDER_SUCCESSFULL = "update_jutegovt_sales_order_successfull"
export const SALES_ORDER_JUTEGOVT_HEADERS='sales_order_jutegovt_headers';
export const SALES_ORDER_JUTEGOVT_LINE_ITEMS='sales_order_jutegovt_line_items';
export const GET_QUOTATION_RECORDS="get_quotation_records";
export const GET_QUOTATION_RECORDS_SUCCESSFULL="get_quotation_records_successfull";
export const QUOTE_VIEW_BY_ID_FOR_JUTEGOVT ="quote_view_by_id_for_jutegovt";
export const QUOTE_VIEW_BY_ID_FOR_JUTEGOVT_SUCCESSFULL="quote_view_by_id_for_jutegovt_successfull";
export const S_O_BY_ID_FOR_JUTEGOVT ='s_o_by_id_for_jutegovt';
export const S_O_BY_ID_FOR_JUTEGOVT_SUCCESSFULL='s_o_by_id_for_jutegovt_successfull';
export const GET_ALL_BROKER = "get_all_broker";
export const GET_ALL_BROKER_SUCCESSFULL = "get_all_broker_successfull";
export const TRANSPORTER_LIST = "transporter_list";
export const TRANSPORTER_LIST_SUCCESSFULL = "transporter_list_successfull";
export const CREATE_SALES_ORDER = "create_sales_order";
export const CREATE_SALES_ORDER_SUCCESSFULL = "create_sales_order_successfull";
export const UPDATE_SALES_ORDER = "update_sales_order";
export const UPDATE_SALES_ORDER_SUCCESSFULL = "update_sales_order_successfull";
export const SALES_ORDER_BY_ID = "sales_order_by_id";
export const SALES_ORDER_BY_ID_SUCCESSFULL = "sales_order_by_id_successfull";
export const TERMS_CONDITIONS_API ='terms_conditions_api'
export const TERMS_CONDITIONS_API_SUCCESSFULL='terms_conditions_api_successfull';
export const GET_UOM_RELATION_VALUE_BY_UOM_CODE='get_uom_relation_value_by_uom_code';
export const GET_UOM_RELATION_VALUE_BY_UOM_CODE_SUCCESSFULL='get_uom_relation_value_by_uom_code_successfull';
export const GET_BRANCH = "get_branch";
export const GET_BRANCH_SUCCESSFULL = "get_branch_successfull";
export const ACCOUNT_LIST = 'account_list';
export const ACCOUNT_LIST_SUCCESSFULL = 'account_list_successfull';
export const SALES_ORDER_HEADER = "sales_order_header";
export const SALES_ORDER_LINE_ITEMS = "sales_order_line_items";
export const SALES_ORDER_JUTE_YARN_HEADER = "sales_order_jute_yarn_header";
export const SALES_ORDER_JUTE_YARN_LINEITEMS =
  "sales_order_jute_yarn_lineitems";

export const QUOTATION_VIEW_BY_ID = "quotation_view_by_id";
export const QUOTATION_VIEW_BY_ID_SUCCESSFULL =
  "quotation_view_by_id_successfull";

//For Sales Order Jute Yarn
export const CREATE_JUTEYARN_SALES_ORDER = "create_juteyarn_sales_order"
export const CREATE_JUTEYARN_SALES_ORDER_SUCCESSFULL = "create_juteyarn_sales_order_successfull"
export const UPDATE_JUTEYARN_SALES_ORDER = "Update_juteyarn_sales_order"
export const UPDATE_JUTEYARN_SALES_ORDER_SUCCESSFULL = "update_juteyarn_sales_order_successfull"
export const SALES_ORDER_JUTEYARN_HEADERS='sales_order_juteyarn_headers';
export const SALES_ORDER_JUTEYARN_LINE_ITEMS='sales_order_juteyarn_line_items';
export const QUOTE_VIEW_BY_ID_FOR_JUTEYARN ="quote_view_by_id_for_juteyarn";
export const QUOTE_VIEW_BY_ID_FOR_JUTEYARN_SUCCESSFULL="quote_view_by_id_for_juteyarn_successfull";
export const S_O_BY_ID_FOR_JUTEYARN ='s_o_by_id_for_juteyarn';
export const S_O_BY_ID_FOR_JUTEYARN_SUCCESSFULL='s_o_by_id_for_juteyarn_successfull';

//For Sales Order Jute Hessian
export const CREATE_JUTEHESSIAN_SALES_ORDER = "create_jutehessian_sales_order";
export const CREATE_JUTEHESSIAN_SALES_ORDER_SUCCESSFULL =
  "create_jutehessian_sales_order_successfull";
export const SALES_ORDER_JUTEHESSIAN_HEADERS =
  "sales_order_jutehessian_headers";
export const SALES_ORDER_JUTEHESSIAN_LINE_ITEMS =
  "sales_order_jutehessian_line_items";

export const SALES_ORDER_JUTEHESSIAN_BY_ID = "sales_order_jutehessian_by_id";
export const SALES_ORDER_JUTEHESSIAN_BY_ID_SUCCESSFULL =
  "sales_order_jutehessian_by_id_successsfull";

export const UPDATE_JUTEHESSIAN_SALES_ORDER = "update_jutehessian_sales_order";
export const UPDATE_JUTEHESSIAN_SALES_ORDER_SUCCESSFULL =
  "update_jutehessian_sales_order_successfull";

  export const SO_JUTE_HESSIAN_QUOTATION_VIEW_BY_ID = "sales_order_quotation_view_by_id";
export const SO_JUTE_HESSIAN_QUOTATION_VIEW_BY_ID_SUCCESSFULL =
  "sales_order_quotation_view_by_id_successfull";

//for clearing the props
export const CLEAR_SALES_ORDER_HEADER = "clear_sales_order_header";
export const CLEAR_SALES_ORDER_LINEITEMS = "clear_sales_order_lineitems";
export const CLEAR_SO_JUTEGOVT_HEADERS = "clear_so_jutegovt_headers";
export const CLEAR_SO_JUTEGOVT_LINE_ITEMS = "clear_so_jutegovt_line_items";
export const CLEAR_SO_JUTEHESSIAN_HEADERS = "clear_so_jutehessian_headers";
export const CLEAR_SO_JUTEHESSIAN_LINE_ITEMS = "clear_so_jutehessian_line_items";
export const GET_UOM_RELATION_VALUE_HESSIAN='get_uom_relation_value_hessian';
export const GET_UOM_RELATION_VALUE_HESSIAN_SUCCESSFULL='get_uom_relation_value_hessian_successfull';

export const SALES_ORDER_ITEMS_BY_ITEM_GROUP_ID ="sales_order_items_by_item_group_id";
export const SALES_ORDER_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL ="sales_order_items_by_item_group_id_successfull";

export const ITEMS_BY_ITEM_GROUP_ID_FOR_JUTEGOVT ="items_by_item_group_id_for_jutegovt";
export const SALES_ORDER_HESSIAN_ITEMS_BY_ITEM_GROUP_ID ="sales_order_hessian_items_by_item_group_id";
export const SALES_ORDER_HESSIAN_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL ="sales_order_hessian_items_by_item_group_id_successfull";

export const CLEAR_SO_JUTEYARN_HEADERS = "clear_so_juteyarn_headers";
export const CLEAR_SO_JUTEYARN_LINE_ITEMS = "clear_so_juteyarn_line_items";
export const ITEMS_BY_ITEM_GROUP_ID_FOR_JUTEYARN ="items_by_item_group_id_for_juteyarn";
export const  ITEMS_BY_ITEM_GROUP_ID_FOR_JUTEYARN_SUCCESSFULL ="items_by_item_group_id_for_juteyarn_successfull";
export const API_ERRORS = "api_failed";

// for upload files and delete files in sales order
export const UPLOAD_SO_FILES = "upload_so_files";
export const UPLOAD_SO_FILES_SUCCESSFULL = "upload_so_files_successfull";
export const GET_SO_FILES= "get_so_files";
export const SO_FILES_SUCCESSFULL= "so_files_successfull";
export const CLEAR_ALL_SO_FILES='clear_all_so_files';
export const DELETE_SO_FILE = "delete_so_file";
export const DELETE_SO_FILE_SUCCESSFULL = "delete_so_file_successfull";
export const UOM_LIST_BASEDON_ITEM_ID_HESSIAN = "uom_list_basedon_itemId_hessian";
export const UOM_LIST_BASEDON_ITEM_ID_HESSIAN_SUCCESS = "uom_list_basedon_itemId_hessian_success";

export const SO_JUTE_YARN_UOM_LIST="so_jute_yarn_uom_list";
export const SO_JUTE_YARN_UOM_LIST_SUCCESSFULL="so_jute_yarn_uom_list_successfull";

export const REGULAR_SO_ITEM_NAME_DATA="regular_so_item_name_data";
export const REGULAR_SO_ITEM_NAME_DATA_SUCCESSFULL="regular_so_item_name_data_successfull";

export const JUTE_GOVT_SO_ITEM_NAME_DATA="jute_govt_so_item_name_data";
export const JUTE_GOVT_SO_ITEM_NAME_DATA_SUCCESSFULL="jute_govt_so_item_name_data_successfull";

export const JUTE_YARN_SO_ITEM_NAME_DATA="jute_yarn_so_item_name_data";
export const JUTE_YARN_SO_ITEM_NAME_DATA_SUCCESSFULL="jute_yarn_so_item_name_data_successfull";

export const JUTE_HESSIAN_SO_ITEM_NAME_DATA="jute_hessian_so_item_name_data";
export const JUTE_HESSIAN_SO_ITEM_NAME_DATA_SUCCESSFULL="jute_hessian_so_item_name_data_successfull";

