//import { Switch } from "react-router-dom";

import {
  WAREHOUSELIST,
  WAREHOUSELIST_SUCCESSFULL,
  CREATE_WAREHOUSE,
  CREATE_WAREHOUSE_SUCCESSFULL,
  UPDATE_WAREHOUSE_SUCCESSFULL,
  UPDATE_WAREHOUSE,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  WareHouse_List: [],
  Create_Warehouse: [],
  Update_Warehouse: [],
  error_msg: null,
};

const WareHouseListReducer = (state = initialState, action) => {
  switch (action.type) {
    case WAREHOUSELIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case WAREHOUSELIST_SUCCESSFULL:
      state = {
        ...state,
        WareHouse_List: action.payload.response.data,
        loading: false,
      };
      break;
    case CREATE_WAREHOUSE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_WAREHOUSE_SUCCESSFULL:
      state = {
        ...state,
        Create_Warehouse: action.payload.response,
        loading: true,
      };
      break;
    case UPDATE_WAREHOUSE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_WAREHOUSE_SUCCESSFULL:
      state = {
        ...state,
        Update_Warehouse: action.payload.response,
        loading: true,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default WareHouseListReducer;
