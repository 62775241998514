import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updateWorkerStatus } from "../../../../store/HRMS/EmployeeDataBase/actions";
import { Link } from "react-router-dom";
import CustomButton from "../../../../components/Buttons/Buttons";
import {
  getEmployeeProgressBar,
  ApprovalHierarchy,
  getEBIdSuccessfull,
  getViewByIdEmpData,
  clearPayScheme,
  clearCheckScheme,
  ClearPaySchemeById,
} from "../../../../store/HRMS/EmployeeDataBase/actions";
import RightClick from "../../../../assets/images/rightclick.png";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { serverApi } from "../../../../helpers/Consts";
import swal from "sweetalert";

class AddEmployee extends Component {
  constructor() {
    super();
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      ebId: localStorage.getItem("ebId"),
      empStatus: 0,
      workerStatus: "",
      employeeListData: [],
      WorkerData: [],
      value: 0,
      width: 0,
      score: 0,
      ActiveButton: false,
      OfferLetterButton: true,
      approveButton: null,
      data: [
        {
          id: 1,
          heading: "Personal Information",
          subHeading:
            "Add General, Contact, Educational and Previous Experiences",
          link: "/personal_information",
          adharVerified: false,
          generateOfferLetter: false,
          icon: true,
          display: false,
          show: true,
          disable: false,
          iconButtonDisable: false,
        },
        {
          id: 2,
          heading: "Official Information",
          subHeading: "Add work related info,Bank details and salary structure",
          link: "/offical_information",
          adharVerified: false,
          generateOfferLetter: false,
          icon: true,
          display: false,
          show: true,
          disable: true,
          iconButtonDisable: true,
        },
        {
          id: 3,
          heading: "Upload Documents",
          subHeading: "Upload all relevant documents based on checklist",
          link: "/upload_document",
          adharVerified: false,
          generateOfferLetter: false,
          icon: true,
          display: false,
          show: true,
          disable: true,
          iconButtonDisable: true,
        },
        {
          id: 4,
          heading: "Generate Letters",
          subHeading: "Download Offer / Appointment Letter",
          link: "/offerLetter",
          adharVerified: false,
          generateOfferLetter: true,
          icon: true,
          display: false,
          show: true,
          disable: true,
          iconButtonDisable: true,
        },
        {
          id: 5,
          heading: "Onboarding",
          subHeading:
            "Generate Welcome letter, email id, assets handover form, etc.",
          link: "/onboarding",
          adharVerified: false,
          generateOfferLetter: false,
          icon: true,
          display: false,
          show: true,
          disable: true,
          iconButtonDisable: true,
        },
        {
          id: 6,
          heading: "Shift and Leave Policy",
          subHeading: "Select Employee Leave Policy and Shift Timings",
          link: "/shift_leavePolicy",
          adharVerified: false,
          generateOfferLetter: false,
          icon: true,
          display: false,
          show: true,
          disable: true,
          iconButtonDisable: true,
        },
        {
          id: 7,
          heading: "Medical Enrollments, ESI, PF",
          subHeading: "Fill in details for provident funds, insurances and ESI",
          link: "/medical_enrollment",
          adharVerified: false,
          generateOfferLetter: false,
          icon: true,
          display: false,
          show: true,
          disable: true,
          iconButtonDisable: true,
        },
      ],
      displayForms: [],
      progressBar: [],
      buttonsToDisplay: [],
    };
  }
  handleApprove = (status, lable) => {
    if (localStorage.getItem("ebId") !== null) {
      swal({
        title: "Are you sure?",
        text: "Do you want to change to " + lable,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const data = {
            taskType: 2,
            ebId: localStorage.getItem("ebId"),
            companyId: localStorage.getItem("companyId"),
            userId: this.state.userDit.userId,
            proposedStatus: status,
          };

          this.props.getEmployeeProgressBar(
            serverApi.GET_PROGRESS_BAR_EMP_DATA,
            data,
            this.handleProgressBarDataStatusChanged
          );
        } else {
          swal("Your action is canceled!");
        }
      });
    }
  };
  componentWillReceiveProps(props) {
    if (props.approval_hierarchy) {
      if (props.approval_hierarchy.data) {
        if (props.approval_hierarchy.data.approveButton) {
          this.setState({
            approveButton: props.approval_hierarchy.data.approveButton,
          });
        }
      }
    }
    if (props.getEmpData) {
    }
  }
  componentDidMount() {
    this.props.clearCheckScheme(null);
    this.props.ClearPaySchemeById(null);
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData) {
        localStorage.setItem("ebId", this.props.location.state.rowData.eb_id);
        this.props.getEBIdSuccessfull(this.props.location.state.rowData.eb_id);
        this.props.getViewByIdEmpData(this.props.location.state.rowData);

        if (this.props.getEmpData) {
        }
        const data = {
          taskType: 0,
          ebId: this.props.location.state.rowData.eb_id,
          companyId: localStorage.getItem("companyId"),
          userId: this.state.userDit.userId,
          proposedStatus: this.props.location.state.rowData.status,
        };

        this.props.getEmployeeProgressBar(
          serverApi.GET_PROGRESS_BAR_EMP_DATA,
          data,
          this.handleProgressBarData
        );
        this.setState({
          empStatus: this.props.location.state.rowData.status,
        });
      } else {
        localStorage.setItem("ebId", 0);
        this.props.getEBIdSuccessfull(null);
      }
    } else {
      localStorage.setItem("ebId", this.props.getEbId);
      if (this.props.getEbId) {
        const data = {
          taskType: 0,
          ebId: this.props.getEbId,
          companyId: localStorage.getItem("companyId"),
          userId: this.state.userDit.userId,
          proposedStatus: null,
        };

        this.props.getEmployeeProgressBar(
          serverApi.GET_PROGRESS_BAR_EMP_DATA,
          data,
          this.handleProgressBarData
        );
      } else {
        localStorage.setItem("ebId", 0);
        localStorage.setItem("toDisplayForms", JSON.stringify(null));
        localStorage.setItem("toGenerateLetters", JSON.stringify(null));
      }
    }
  }

  handleProgressBarData = (res) => {
    if (res) {
      this.setState({
        progressBar: res.progressBar,
        toDisplayForms: res.toDisplayForms,
        buttonsToDisplay: res.buttonsToDisplay,
      });

      var displayForms = res.toDisplayForms;
      var progressBar = res.progressBar;
      if (progressBar) {
        let personal =
          Number(progressBar.personalDetailsPer) +
          Number(progressBar.contactDetailsPer) +
          Number(progressBar.addressDetailsPer) +
          Number(progressBar.educationDetailsPer) +
          Number(progressBar.previousExperienceDetailsPer);
        let workdit =
          Number(progressBar.workDetailsPer) +
          Number(progressBar.salaryStructurePer) +
          Number(progressBar.bankDetailsPer);

        let generateLettersPer = Number(progressBar.generateLettersPer);
        let onBoardingPer = Number(progressBar.onBoardingPer);
        let shiftleave =
          Number(progressBar.shiftPer) + Number(progressBar.LeavePolicyPer);
        let esipf = Number(progressBar.ESIPer) + Number(progressBar.PFPer);
        if (personal == 5) {
          this.state.data[0].display = true;
          this.state.data[0].show = false;
          this.state.data[0].disable = false;
          this.state.data[0].iconButtonDisable = false;
        } else {
          this.state.data[0].display = false;
          this.state.data[0].show = true;
          this.state.data[0].disable = false;
          this.state.data[0].iconButtonDisable = false;
        }
        if (workdit == 3) {
          this.state.data[1].display = true;
          this.state.data[1].show = false;
          this.state.data[1].disable = false;
          this.state.data[1].iconButtonDisable = false;
        } else if (displayForms.workDetails) {
          this.state.data[1].display = false;
          this.state.data[1].show = true;
          this.state.data[1].disable = false;
          this.state.data[1].iconButtonDisable = false;
        }

        this.state.data[2].display = false;
        this.state.data[2].show = true;
        this.state.data[2].disable = false;
        this.state.data[2].iconButtonDisable = false;
        // }

        if (generateLettersPer) {
          this.state.data[3].display = true;
          this.state.data[3].show = false;
          this.state.data[3].disable = false;
          this.state.data[3].iconButtonDisable = false;
        } else if (displayForms.generateLetters) {
          this.state.data[3].display = false;
          this.state.data[3].show = true;
          this.state.data[3].disable = false;
          this.state.data[3].iconButtonDisable = false;
        }

        if (onBoardingPer) {
          this.state.data[4].display = true;
          this.state.data[4].show = false;
          this.state.data[4].disable = false;
          this.state.data[4].iconButtonDisable = false;
        } else if (displayForms.onBoardingCaseDisplay) {
          this.state.data[4].display = false;
          this.state.data[4].show = true;
          this.state.data[4].disable = false;
          this.state.data[4].iconButtonDisable = false;
        }
        if (shiftleave == 2) {
          this.state.data[5].display = true;
          this.state.data[5].show = false;
          this.state.data[5].disable = false;
          this.state.data[5].iconButtonDisable = false;
        } else if (displayForms.shiftAndLeavePolicy) {
          this.state.data[5].display = false;
          this.state.data[5].show = true;
          this.state.data[5].disable = false;
          this.state.data[5].iconButtonDisable = false;
        }
        if (esipf == 2) {
          this.state.data[6].display = true;
          this.state.data[6].show = false;
          this.state.data[6].disable = false;
          this.state.data[6].iconButtonDisable = false;
        } else if (displayForms.ESIAndPF) {
          this.state.data[6].display = false;
          this.state.data[6].show = true;
          this.state.data[6].disable = false;
          this.state.data[6].iconButtonDisable = false;
        }
        this.setState({
          width: progressBar.totalPercentage,
        });
      }
    } else {
    }
  };

  handleProgressBarDataStatusOne = (res) => {};
  handleProgressBarDataStatusChanged = (res) => {
    if (res) {
      if (res.data) {
        if (res.data.status) {
          swal(res.data.message, { icon: "success" });
          this.props.history.push("/listOf_employees");
        } else {
          swal(res.data.message, { icon: "error" });
        }
      }
    } else {
      if (res.status) {
        swal(res.message, { icon: "success" });
        this.history.push("/listOf_employees");
      } else {
        swal(res.message, { icon: "error" });
      }
    }
  };

  render() {
    const style = {
      width: this.state.width + "%",
    };

    return (
      <>
        <div className="headingAndProgressbar">
          <p className="organizationField">
            Complete the steps below to add employee to your organisation
          </p>
          <div className="consoleFormButtonBlock">
            {this.state.buttonsToDisplay ? (
              this.state.buttonsToDisplay.BlackList &&
              localStorage.getItem("HrmsAccess") ? (
                <>
                  <CustomButton
                    label={"BlackList"}
                    className="rejected"
                    handleClick={() =>
                      this.handleApprove(
                        this.state.buttonsToDisplay.BlackList,
                        "BlackList"
                      )
                    }
                  />
                </>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
            {this.state.buttonsToDisplay ? (
              this.state.buttonsToDisplay.Approve &&
              localStorage.getItem("HrmsAccess") ? (
                <>
                  <CustomButton
                    label={"Approve"}
                    className="approved"
                    handleClick={() =>
                      this.handleApprove(
                        this.state.buttonsToDisplay.Approve
                          ? this.state.buttonsToDisplay.Approve
                          : 3,
                        "Approve"
                      )
                    }
                  />
                </>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {this.state.buttonsToDisplay ? (
              this.state.buttonsToDisplay.Reject &&
              localStorage.getItem("HrmsAccess") ? (
                <>
                  <CustomButton
                    label={"Reject"}
                    className="rejected"
                    handleClick={() =>
                      this.handleApprove(
                        this.state.buttonsToDisplay.Reject,
                        "Reject"
                      )
                    }
                  />
                </>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {this.state.buttonsToDisplay ? (
              this.state.buttonsToDisplay.Join &&
              localStorage.getItem("HrmsAccess") ? (
                <>
                  <CustomButton
                    label={"Join"}
                    className="approved"
                    handleClick={() =>
                      this.handleApprove(
                        this.state.buttonsToDisplay.Join,
                        "Join"
                      )
                    }
                  />
                </>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {this.state.buttonsToDisplay ? (
              this.state.buttonsToDisplay.Offered &&
              localStorage.getItem("HrmsAccess") ? (
                <>
                  <CustomButton
                    label={"Offer"}
                    className="approved"
                    handleClick={() =>
                      this.handleApprove(
                        this.state.buttonsToDisplay.Offered,
                        "Offer"
                      )
                    }
                  />
                </>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {this.state.buttonsToDisplay ? (
              this.state.buttonsToDisplay.Reopen &&
              localStorage.getItem("HrmsAccess") ? (
                <>
                  <CustomButton
                    label={"Re-open"}
                    className="approved"
                    handleClick={() =>
                      this.handleApprove(
                        this.state.buttonsToDisplay.Reopen,
                        "Re-open"
                      )
                    }
                  />
                </>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {this.state.buttonsToDisplay ? (
              this.state.buttonsToDisplay.Terminate &&
              localStorage.getItem("HrmsAccess") ? (
                <>
                  <CustomButton
                    label={"Terminate"}
                    className="rejected"
                    handleClick={() =>
                      this.handleApprove(
                        this.state.buttonsToDisplay.Terminate,
                        "Terminate"
                      )
                    }
                  />
                </>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {this.state.buttonsToDisplay ? (
              this.state.buttonsToDisplay.Resign &&
              localStorage.getItem("HrmsAccess") ? (
                <>
                  <CustomButton
                    label={"Resign"}
                    className="approved"
                    handleClick={() =>
                      this.handleApprove(
                        this.state.buttonsToDisplay.Resign,
                        "Resign"
                      )
                    }
                  />
                </>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {this.state.buttonsToDisplay ? (
              this.state.buttonsToDisplay.Retire &&
              localStorage.getItem("HrmsAccess") ? (
                <>
                  <CustomButton
                    label={"Retire"}
                    className="approved"
                    handleClick={() =>
                      this.handleApprove(
                        this.state.buttonsToDisplay.Retire,
                        "Retire"
                      )
                    }
                  />
                </>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {this.state.buttonsToDisplay ? (
              this.state.buttonsToDisplay.AcceptOffer &&
              localStorage.getItem("HrmsAccess") ? (
                <>
                  <CustomButton
                    label={"Offer Accepted"}
                    className="approved"
                    handleClick={() =>
                      this.handleApprove(
                        this.state.buttonsToDisplay.AcceptOffer,
                        "Offer Accepted"
                      )
                    }
                  />
                </>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {this.state.buttonsToDisplay ? (
              this.state.buttonsToDisplay.RejectOffer &&
              localStorage.getItem("HrmsAccess") ? (
                <>
                  <CustomButton
                    label={"Offer Rejected"}
                    className="rejected"
                    handleClick={() =>
                      this.handleApprove(
                        this.state.buttonsToDisplay.RejectOffer,
                        "Offer Rejected"
                      )
                    }
                  />
                </>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {this.state.buttonsToDisplay ? (
              this.state.buttonsToDisplay.Release &&
              localStorage.getItem("HrmsAccess") ? (
                <>
                  <CustomButton
                    label={"Release"}
                    className="rejected"
                    handleClick={() =>
                      this.handleApprove(
                        this.state.buttonsToDisplay.Release,
                        "Release"
                      )
                    }
                  />
                </>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            {this.state.buttonsToDisplay ? (
              this.state.buttonsToDisplay.Revoke &&
              localStorage.getItem("HrmsAccess") ? (
                <>
                  <CustomButton
                    label={"Revoke"}
                    className="approved"
                    handleClick={() =>
                      this.handleApprove(
                        this.state.buttonsToDisplay.Revoke,
                        "Revoke"
                      )
                    }
                  />
                </>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </div>
          {/* Progress Bar  */}
          <div className="progressBarBgContainer">
            <p>Completed</p>
            <div className="d-flex">
              <div className="progressBarBg">
                <div className="bar" style={style} />
              </div>
              <p className="percentage">{this.state.width + "%"}</p>
            </div>
          </div>
        </div>
        {/* Progress Bar  */}
        <div className="addEmployeeOrganization">
          {this.state.data.map((emp_info, index) => (
            <div className={emp_info.disable && "disableList"}>
              <div className="addingNewEmployeeDisableContainer">
                <div className="personalInfo">
                  {emp_info.display && (
                    <img
                      src={RightClick}
                      alt="playstore"
                      className="rightClickIconBlock"
                    />
                  )}
                  {emp_info.show && <div className="infoId">{emp_info.id}</div>}
                  <div className="otherInfo" key={index}>
                    <h1>{emp_info.heading}</h1>
                    <p>{emp_info.subHeading}</p>
                  </div>
                </div>
                <div className="verificationBlock">
                  {emp_info.adharVerified && (
                    <Link to="/AadharVerification">
                      <CustomButton
                        label={"Verify Aadhar"}
                        className="blueBorderButton"
                      />
                    </Link>
                  )}
                  {emp_info.generateOfferLetter && (
                    <Link to="/offerLetter">
                      <CustomButton
                        label={"Issue Offer Letter"}
                        className={
                          this.state.OfferLetterButton
                            ? "buttonDisabled"
                            : "blueBorderButton"
                        }
                        disabled={this.state.OfferLetterButton}
                      />
                    </Link>
                  )}
                  <CustomButton
                    className="iconButton"
                    muIcon={<KeyboardArrowRightIcon />}
                    disabled={emp_info.iconButtonDisable}
                    handleClick={() => {
                      this.props.history.push(emp_info.link);
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    Get_worker_progress,
    update_worker_status,
    progressbar,
    getEbId,
    approval_hierarchy,
    getEmpData,
  } = state.EmployeeReducer;
  return {
    Get_worker_progress,
    update_worker_status,
    approval_hierarchy,
    progressbar,
    getEbId,
    getEmpData,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getEmployeeProgressBar,
    updateWorkerStatus,
    ApprovalHierarchy,
    getEBIdSuccessfull,
    getEmployeeProgressBar,
    getViewByIdEmpData,
    clearPayScheme,
    clearCheckScheme,
    ClearPaySchemeById,
  })(AddEmployee)
);
