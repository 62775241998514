import {TERMS_LIST,TERMS_LIST_SUCCESSFULL,
    CREATE_TERMS,CREATE_TERMS_SUCCESSFULL,
    UPDATE_TERMS,UPDATE_TERMS_SUCCESSFULL,
    API_ERRORS,} from './actionTypes';

export const termsList = (url,data,history) =>{
    return {
        type:TERMS_LIST,
        payload:{url,data,history}
    }
}
export const termsListSuccessfull = (data)=>{
    return {
        type:TERMS_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createTerms = (url, data, history) => {
    return {
        type: CREATE_TERMS,
        payload: { url, data, history }
    }
}

export const createTermsSuccessfull = (data) => {
    return {
        type: CREATE_TERMS_SUCCESSFULL,
        payload: data
    }
}
export const updateTerms = (url, data, history) => {
    return {
        type: UPDATE_TERMS,
        payload: { url, data, history }
    }
}

export const updateTermsSuccessfull = (data) => {
    return {
        type: UPDATE_TERMS_SUCCESSFULL,
        payload: data
    }
}
export const apiErrors = (error) => {
    return {
        type: API_ERRORS,
        payload: error,
    };
};