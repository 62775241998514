import {
  BRANCH_LIST,
  BRANCH_LIST_SUCCESSFULL,
  DEPARTMENT_LIST,
  DEPARTMENT_LIST_SUCCESSFULL,
  CLIENT_LIST,
  CLIENT_LIST_SUCCESSFULL,
  ITEMGROUP_LIST,
  ITEMGROUP_LIST_SUCCESSFULL,
  PROJECT_LIST,
  PROJECT_LIST_SUCCESSFULL,
  CATEGORY_TYPE_LIST,
  CATEGORY_TYPE_LIST_SUCCESSFULL,
  PARENT_LEDGER_LIST,
  PARENT_LEDGER_LIST_SUCCESSFULL,
  LEDGER_GROUPS_LIST,
  LEDGER_GROUPS_LIST_SUCCESSFULL,
  STATE_NAME_LIST,
  STATE_NAME_LIST_SUCCESSFULL,
  EMPLOYEE_CATEGORY_LIST,
  EMPLOYEE_CATEGORY_LIST_SUCCESSFULL,
  BUDGET_HEADS_LIST,
  BUDGET_HEADS_LIST_SUCCESSFULL,
  SUPPLIER_LIST,
  SUPPLIER_LIST_SUCCESSFULL,
  STATUS_LIST,
  STATUS_LIST_SUCCESSFUL,
  CUSTOMER_LIST,
  CUSTOMER_LIST_SUCCESSFUL,
  BILLING_SHIPPING_LIST,
  BILLING_SHIPPING_LIST_SUCCESSFUL,
  ITEMGROUP_MASTER_LIST,
  ITEMGROUP_MASTER_LIST_SUCCESSFUL,
  ITEMS_BY_ITEMGROUP_BY_ID,
  ITEMS_BY_ITEMGROUP_BY_ID_SUCCESSFUL,
  ITEMGRPBYBDGT_HEAD_ID,
  ITEMGRPBYBDGT_HEAD_ID_SUCCESSFULL,
  TAX_LIST,
  Tax_List_SUCCESSFULL,
  ITEMSLIST_FORINDENT,
  ITEMSLIST_FORINDENT_SUCCESSFULL,
  APPROVED_INDENTS,
  APPROVED_INDENTS_SUCCESSFUL,
  ALLITEMGROUP_MASTERLIST,
  ALLITEMGROUP_MASTERLIST_SUCCESSFULL,
  PARENT_BUDGET_HEADS_LIST,
  PARENT_BUDGET_HEADS_LIST_SUCCESSFULL,
  SUPPLIERLISTBY_SUPP_ID,
  SUPPLIERLISTBY_SUPP_ID_SUCCESSFULL,
  BILLING_ADDRESS_SHIPPING_ADDRESS,
  BILLING_ADS_SHIPPING_ADS_SUCCESSFULL,
  BUDGETHEADS,
  BUDGETHEADS_SUCCESSFULL,
  ITEMGRP_MASTER_LIST,
  ITEMGRP_MASTER_LIST_SUCCESSFULL,
  BOQ_List,
  BOQList_SUCCESSFULL,
  MUKAM_LIST,
  MUKAM_LIST_SUCCESSFULL,
  // ACCOUNT_TYPE_LIST,ACCOUNT_TYPE_LIST_SUCCESSFULL
  UOM_LIST,
  UOM_LIST_SUCCESSFULL,
  SUPPLIER_LIST_MULTISELECT,
  SUPPLIER_LIST_MULTISELECT_SUCCESSFULL,
  TYPES_OF_MACHINE,
  TYPES_OF_MACHINE_SUCCESSFULL,
  YARN_TYPE_LIST,
  YARN_TYPE_LIST_SUCCESSFULL,
  SUB_BUDGET_HEADS_LIST,
  SUB_BUDGET_HEADS_LIST_SUCCESSFULL,
  ITEMGROUPBYBUDGET_HEADS_LIST,
  ITEMGROUPBYBUDGET_HEADS_LIST_SUCCESSFULL,
  QUALITY_BY_ITEM,
  QUALITY_BY_ITEM_SUCCESSFULL,
  LAST_MARKET_RATE,
  LAST_MARKET_RATE_SUCCESSFULL,
  PROJECT_MASTER_LIST,
  PROJECT_MASTER_LIST_SUCCESSFULL,
  SUPPLIER_LIST_MULTISELECT_J,
  SUPPLIER_LIST_MULTISELECT_J_SECCESSFULL,
  TERMS_CONDITIONS,
  TERMS_CONDITIONS_SUCCESSFULL,
  COUNTRY_NAME_LIST,
  COUNTRY_NAME_LIST_SUCCESSFULL,
  DASHBOARD_GOOGLE_LINK_API,
  DASHBOARD_GOOGLE_LINK_API_SUCCESSFULL,
  CONFIGURATION_API,
  CONFIGURATION_API_SUCCESSFULL,
  EXPORT_DATA_LIST,
  EXPORT_DATA_LIST_SUCCESSFULL,
  GST_VERIFICATION,
  GST_VERIFICATION_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  dataLoaded: false,
  branchList: [],
  departmentList: [],
  clientList: [],
  ItemGroupList: [],
  projectList: [],
  CategoryTypeList: [],
  supplierList: [],
  statusList: [],
  customerList: [],
  billingShippingList: [],
  itemGroupMasterList: [],
  itemGroupByBudgetHeadsList: [],
  itemGrpByBdgtHeadId: [],
  itemsByItmGrpIdList: [],
  taxList: [],
  itemsListForIndent: [],
  parentLedgerList: [],
  ledgerGroupsList: [],
  stateNameList: [],
  countryNameList: [],
  uomList: [],
  accountTypeList: [],
  budgetHeadsList: [],
  subBudgetHeadsList: [],
  approvedIndentsList: [],
  allItemGroupMaster_List: [],
  supplierListBy_suppId: [],
  billing_shipping_adsList: [],
  itemGrpMasterList: [],
  boqListResp: [],
  parentBudgetHeadsList: [],
  mukamList: [],
  employeeCategoryList: [],
  supplierListMultiselect: [],
  supplierListJ: [],
  typesOfMachine: [],
  yarnTypeList: [],
  qualitybyItem: [],
  lastMarkRate: [],
  ProjectMastersData: [],
  TermsConditions: [],
  dashBoard_GoggleLink_list: [],
  ConfigurationApi: [],
  exportlistdata:[],
  gstVerificationStatus: false
};
const DropDownListReducer = (state = initialState, action) => {
  switch (action.type) {
    case BRANCH_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case BRANCH_LIST_SUCCESSFULL:
      state = {
        ...state,
        branchList: action.payload.response,
        loading: true,
      };
      break;
    case DEPARTMENT_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DEPARTMENT_LIST_SUCCESSFULL:
      state = {
        ...state,
        departmentList: action.payload.response,
        loading: true,
      };
      break;
    case CLIENT_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CLIENT_LIST_SUCCESSFULL:
      state = {
        ...state,
        clientList: action.payload.response,
        loading: true,
      };
      break;
    case ITEMGROUP_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ITEMGROUP_LIST_SUCCESSFULL:
      state = {
        ...state,
        ItemGroupList: action.payload.response,
        loading: true,
      };
      break;
    case PROJECT_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PROJECT_LIST_SUCCESSFULL:
      state = {
        ...state,
        projectList: action.payload.response,
        loading: true,
      };
      break;
    case STATUS_LIST:
      state = {
        ...state,
        loading: true,
        dataLoaded: false
      };
      break;
    case STATUS_LIST_SUCCESSFUL:
      state = {
        ...state,
        statusList: action.payload.response,
        loading: false,
        dataLoaded: true
      };
      break;
    case CATEGORY_TYPE_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CATEGORY_TYPE_LIST_SUCCESSFULL:
      state = {
        ...state,
        CategoryTypeList: action.payload.response,
        loading: true,
      };
      break;
    case SUPPLIER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SUPPLIER_LIST_SUCCESSFULL:
      state = {
        ...state,
        supplierList: action.payload.response,
        loading: true,
      };
      break;
    case PARENT_LEDGER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PARENT_LEDGER_LIST_SUCCESSFULL:
      state = {
        ...state,
        parentLedgerList: action.payload.response,
        loading: true,
      };
      break;
    case LEDGER_GROUPS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LEDGER_GROUPS_LIST_SUCCESSFULL:
      state = {
        ...state,
        ledgerGroupsList: action.payload.response,
        loading: true,
      };
      break;
    case STATE_NAME_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case STATE_NAME_LIST_SUCCESSFULL:
      state = {
        ...state,
        stateNameList: action.payload.response,
        loading: true,
      };
      break;
    case COUNTRY_NAME_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case COUNTRY_NAME_LIST_SUCCESSFULL:
      state = {
        ...state,
        countryNameList: action.payload.response,
        loading: true,
      };
      break;
    case CUSTOMER_LIST:
      state = {
        ...state,
        loading: true,
        dataLoaded: false
      };
      break;
    case CUSTOMER_LIST_SUCCESSFUL:
      state = {
        ...state,
        customerList: action.payload.response,
        loading: false,
        dataLoaded: false
      };
      break;
    case BILLING_SHIPPING_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case BILLING_SHIPPING_LIST_SUCCESSFUL:
      state = {
        ...state,
        billingShippingList: action.payload.response,
        loading: true,
      };
      break;
    case ITEMGROUP_MASTER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ITEMGROUP_MASTER_LIST_SUCCESSFUL:
      state = {
        ...state,
        itemGroupMasterList: action.payload.response,
        loading: true,
      };
      break;
    case ITEMS_BY_ITEMGROUP_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ITEMS_BY_ITEMGROUP_BY_ID_SUCCESSFUL:
      state = {
        ...state,
        itemsByItmGrpIdList: action.payload.response,
        loading: true,
      };
      break;
    case TAX_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case Tax_List_SUCCESSFULL:
      state = {
        ...state,
        taxList: action.payload.response,
        loading: true,
      };
      break;

    case ALLITEMGROUP_MASTERLIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ALLITEMGROUP_MASTERLIST_SUCCESSFULL:
      state = {
        ...state,
        allItemGroupMaster_List: action.payload.response,
        loading: true,
      };
      break;
    case BUDGET_HEADS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case BUDGET_HEADS_LIST_SUCCESSFULL:
      state = {
        ...state,
        budgetHeadsList: action.payload.response,
        loading: true,
      };
      break;

    case ITEMGROUPBYBUDGET_HEADS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ITEMGROUPBYBUDGET_HEADS_LIST_SUCCESSFULL:
      state = {
        ...state,
        itemGroupByBudgetHeadsList: action.payload.response,
        loading: true,
      };
      break;
    case ITEMGRPBYBDGT_HEAD_ID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ITEMGRPBYBDGT_HEAD_ID_SUCCESSFULL:
      state = {
        ...state,
        itemGrpByBdgtHeadId: action.payload.response,
        loading: true,
      };
      break;
    case SUB_BUDGET_HEADS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SUB_BUDGET_HEADS_LIST_SUCCESSFULL:
      state = {
        ...state,
        subBudgetHeadsList: action.payload.response,
        loading: true,
      };
      break;
    case ITEMSLIST_FORINDENT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ITEMSLIST_FORINDENT_SUCCESSFULL:
      state = {
        ...state,
        itemsListForIndent: action.payload.response,
        loading: true,
      };
      break;

    case APPROVED_INDENTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case APPROVED_INDENTS_SUCCESSFUL:
      state = {
        ...state,
        approvedIndentsList: action.payload.response,
        loading: true,
      };
      break;
    case SUPPLIERLISTBY_SUPP_ID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SUPPLIERLISTBY_SUPP_ID_SUCCESSFULL:
      state = {
        ...state,
        supplierListBy_suppId: action.payload.response,
        loading: true,
      };
      break;
    case BILLING_ADDRESS_SHIPPING_ADDRESS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case BILLING_ADS_SHIPPING_ADS_SUCCESSFULL:
      state = {
        ...state,
        billing_shipping_adsList: action.payload.response,
        loading: true,
      };
      break;

    case BUDGETHEADS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case BUDGETHEADS_SUCCESSFULL:
      state = {
        ...state,
        budgetHeadsList: action.payload.response,
        loading: true,
      };
      break;

    case ITEMGRP_MASTER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ITEMGRP_MASTER_LIST_SUCCESSFULL:
      state = {
        ...state,
        itemGrpMasterList: action.payload.response,
        loading: true,
      };
      break;

    case BOQ_List:
      state = {
        ...state,
        loading: true,
      };
      break;
    case BOQList_SUCCESSFULL:
      state = {
        ...state,
        boqListResp: action.payload.response,
        loading: true,
      };
      break;
    case PARENT_BUDGET_HEADS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PARENT_BUDGET_HEADS_LIST_SUCCESSFULL:
      state = {
        ...state,
        parentBudgetHeadsList: action.payload.response,
        loading: true,
      };
      break;
    case MUKAM_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case MUKAM_LIST_SUCCESSFULL:
      state = {
        ...state,
        mukamList: action.payload.response,
        loading: true,
      };
      break;
    case EMPLOYEE_CATEGORY_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EMPLOYEE_CATEGORY_LIST_SUCCESSFULL:
      state = {
        ...state,
        employeeCategoryList: action.payload.response,
        loading: true,
      };
      break;
    case SUPPLIER_LIST_MULTISELECT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SUPPLIER_LIST_MULTISELECT_SUCCESSFULL:
      state = {
        ...state,
        supplierListMultiselect: action.payload.response,
        loading: true,
      };
      break;
    case SUPPLIER_LIST_MULTISELECT_J:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SUPPLIER_LIST_MULTISELECT_J_SECCESSFULL:
      state = {
        ...state,
        supplierListJ: action.payload.response,
        loading: true,
      };
      break;
    case TYPES_OF_MACHINE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case TYPES_OF_MACHINE_SUCCESSFULL:
      state = {
        ...state,
        typesOfMachine: action.payload.response,
        loading: true,
      };
      break;
    case YARN_TYPE_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case YARN_TYPE_LIST_SUCCESSFULL:
      state = {
        ...state,
        yarnTypeList: action.payload.response,
        loading: true,
      };
      break;

    default:
      state = { ...state };
      break;
    case UOM_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UOM_LIST_SUCCESSFULL:
      state = {
        ...state,
        uomList: action.payload.response,
        loading: true,
      };
      break;
    case QUALITY_BY_ITEM:
      state = {
        ...state,
        loading: true,
      };
      break;
    case QUALITY_BY_ITEM_SUCCESSFULL:
      state = {
        ...state,
        qualitybyItem: action.payload.response.data,
        loading: true,
      };
      break;
    case LAST_MARKET_RATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LAST_MARKET_RATE_SUCCESSFULL:
      state = {
        ...state,
        lastMarkRate: action.payload.response.data,
        loading: true,
      };
      break;
    case PROJECT_MASTER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PROJECT_MASTER_LIST_SUCCESSFULL:
      state = {
        ...state,
        ProjectMastersData: action.payload.response.data,
        loading: true,
      };
      break;
    case TERMS_CONDITIONS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case TERMS_CONDITIONS_SUCCESSFULL:
      state = {
        ...state,
        TermsConditions: action.payload.response.data,
        loading: true,
      };
      break;
    case DASHBOARD_GOOGLE_LINK_API:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DASHBOARD_GOOGLE_LINK_API_SUCCESSFULL:
      state = {
        ...state,
        dashBoard_GoggleLink_list: action.payload.response.data,
        loading: true,
      };
      break;

    case CONFIGURATION_API:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONFIGURATION_API_SUCCESSFULL:
      state = {
        ...state,
        ConfigurationApi: action.payload.response.data,
        loading: true,
      };
      break;
      case EXPORT_DATA_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EXPORT_DATA_LIST_SUCCESSFULL:
      state = {
        ...state,
        exportlistdata: action.payload.response.data,
        loading: true,
      };
      break;
    case GST_VERIFICATION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GST_VERIFICATION_SUCCESSFULL:
        state = {
          ...state,
          gstVerificationStatus: action.payload.response.data.status,
          loading: false,
        };
     break;
  }
  return state;
};

export default DropDownListReducer;
