import {SUB_DEPARTMENT_LIST,SUB_DEPARTMENT_LIST_SUCCESSFULL,
    CREATE_SUB_DEPARTMENT, CREATE_SUB_DEPARTMENT_SUCCESSFULL,
    API_ERRORS,
    UPDATE_SUB_DEPARTMENT,
    UPDATE_SUB_DEPARTMENT_SUCCESSFULL} from './actionTypes';

export const subDepartmentMasterList = (url,data,history) =>{
    return {
        type:SUB_DEPARTMENT_LIST,
        payload:{url,data,history}
    }
}
export const subdepartmentMasterListSuccessfull = (data)=>{
    return {
        type:SUB_DEPARTMENT_LIST_SUCCESSFULL,
        payload:data
    }
}

export const createSubDepartment = (url, data, history) => {
    return {
        type: CREATE_SUB_DEPARTMENT,
        payload: { url, data, history }
    }
}

export const createSubDepartmentSuccessfull = (data) => {
    return {
        type: CREATE_SUB_DEPARTMENT_SUCCESSFULL,
        payload: data
    }
}
export const updateSubDepartment = (url, data, history) => {
    return {
        type: UPDATE_SUB_DEPARTMENT,
        payload: { url, data, history }
    }
}

export const updateSubDepartmentSuccessfull = (data) => {
    return {
        type: UPDATE_SUB_DEPARTMENT_SUCCESSFULL,
        payload: data
    }
}
export const apiErrors = (error) => {
    return {
        type: API_ERRORS,
        payload: error,
    };
};