import {SUB_DEPARTMENT_LIST,SUB_DEPARTMENT_LIST_SUCCESSFULL,
  API_ERRORS,
  CREATE_SUB_DEPARTMENT, CREATE_SUB_DEPARTMENT_SUCCESSFULL, UPDATE_SUB_DEPARTMENT_SUCCESSFULL, UPDATE_SUB_DEPARTMENT,} from './actionTypes';

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    SubDepartmentMasterList: [],
    Create_Sub_Department:[],
    Update_Sub_Department:[],
  };

  const SubDepartmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUB_DEPARTMENT_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case SUB_DEPARTMENT_LIST_SUCCESSFULL:
            state = {
              ...state,
              SubDepartmentMasterList: action.payload.response,
              loading: true,
            };
            break;
            case CREATE_SUB_DEPARTMENT:
              state = {
                ...state,
                loading: true,
              };
              break;
            case CREATE_SUB_DEPARTMENT_SUCCESSFULL:
              state = {
                ...state,
                Create_Sub_Department: action.payload.response,
                loading: true,
              };
              break;
              case UPDATE_SUB_DEPARTMENT:
              state = {
                ...state,
                loading: true,
              };
              break;
            case UPDATE_SUB_DEPARTMENT_SUCCESSFULL:
              state = {
                ...state,
                Update_Sub_Department: action.payload.response,
                loading: true,
              };
              break;
              case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default SubDepartmentReducer;