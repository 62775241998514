import { combineReducers  } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication Module
// import Account from './auth/register/reducer';
import Login from './auth/login/reducer';
import Indent from "./Purchase/Indent/reducer";
import IndentReducer from "./Purchase/Indent/reducer"
import CompanyList from './Company/BasicDetails/reducer';
import price_enquiry_List from './Purchase/PriceEnquiry/reducer';
import PriceEnquiryReducer from './Purchase/PriceEnquiry/reducer';
import workOrder_List from './Purchase/WorkOrder/reducer';
import CreateWorkOrder from './Purchase/WorkOrder/reducer';
import PrResponse_List from './Purchase/PR_Response/reducer';
import PurchaseOrderReducer from './Purchase/PurchaseOrder/reducer';
import InwardReducer from './Purchase/Inward/reducer';

import Debit_Credit_List from './Purchase/Debit-credit-note/reducer';
import BillPassList from './Purchase/BillPass/reducer';
import Tax_master_List from './Master/TaxMaster/reducer';
import Supplier_List from './Master/SupplierMaster/reducer'
import BankAccountListReducer from './Master/BankAccountMaster/reducer'
import DesignationListReducer from './Master/DesignationMaster/reducer'
import CostCenterListReducer from './Master/CostCenterMaster/reducer';
import LeaveTypeListReducer from './Master/LeaveTypeMaster/reducer';
import AddItem_To_Tax_List from './Master/AddItemToTaxList/reducer'
import EmployeeReducer from "./HRMS/EmployeeDataBase/reducer";
import checkScheme from "./HRMS/EmployeeDataBase/reducer";
import PayslipReducer from './HRMS/PaySlip/reducer';
import PayRegisterReducer from './HRMS/PayRegister/reducer';
import PaySchemeReducer from './HRMS/paySchemeCreation/reducer'
import  LeaveRequestReducer from './HRMS/LeaveRequest/reducer';
import AttendanceReducer from './HRMS/AttendanceCalender/reducer';

//Master Module
import SpellMasterListReducer from './Master/SpellMaster/reducer'
import DepartmentReducer from './Master/DepartmentMaster/reducer';
import SubDepartmentReducer from './Master/SubDepartmentMaster/reducer';
import LedgerReducer from './Master/LedgerMaster/reducer';
import TermsReducer from './Master/Terms/reducer';
import BudgetHeadsList from './Master/BudgetHeads/reducer';
import LedgerGroupsReducer from './Master/LedgerGroupsMaster/reducer';
import TransporterReducer from './Master/TransporterMaster/reducer';
import CustomerListReducer from './Master/CustomerMaster/reducer';
import ItemListReducer from './Master/ItemMaster/reducer';
import ItemGroupListReducer from './Master/ItemGroupMaster/reducer';
import UomListReducer from './Master/UomMaster/reducer';
import Create_Leave_Type from './Master/LeaveTypeMaster/reducer';
import WareHouseListReducer from './Master/WareHouseMaster/reducer';
import TravelCatageriesReducer from './Master/TravelCatageries/reducer';
import WorkerCategoryReducer from './Master/WorkerCategoryMaster/reducer';
import VehicleTypesMasterReducer from './Master/VehicleTypesMaster/reducer';
import WeavingQualityMasterReducer from './Master/WeavingQualityMaster/reducer';
import MukamMasterReducer from './Master/MukamMaster/reducer'
import QualityMasterReducer from './Master/QualityMaster/reducer'
import BrokerMasterReducer from './Master/BrokerMaster/reducer';
import MachineMasterReducer from './Master/MachineMaster/reducer';
import SpinningQualityReducer from './Master/SpinningQualityMaster/reducer';
import YarnMasterReducer from './Master/YarnMaster/reducer'
import CompanyMasterReducer from './Master/CompanyMaster/reducer';
import BranchMasterReducer from './Master/BranchMaster/reducer';
import AgentMasterReducer from './Master/AgentMaster/reducer';
import CarriersReducer from './Master/Carriers/reducer';

import LeftSideMenuReducer from './Company/BasicDetails/LeftSideBar/reducer';
import acadamicYearsList from './auth/FinancialYear/reducer';
import UploadFileReducer from './MyProfile/UploadFile/reducer';
import PayRollDataReducer from './HRMS/PayRollData/reducer';
import ExpenseBookingReducer  from './HRMS/ExpenseBooking/reducer';
import AdvanceRequestReducer from './HRMS/AdvanceRequest/reducer'
import DropDownListReducer from './Global/DropDownApis/reducer';
import ProjectListReducer from './Global/DropDownApis/reducer'
import SupplierListReducer from './Global/DropDownApis/reducer';
import CustomerViewById from './Master/CustomerMaster/reducer';
import ItemGruopListReducer from './Global/DropDownApis/reducer';
import CategoryTypeListReducer from './Global/DropDownApis/reducer';
import StatusListReducer from './Global/DropDownApis/reducer';
import CustomerList from './Global/DropDownApis/reducer';
import billingShippingList from './Global/DropDownApis/reducer';
import itemGroupMasterList from './Global/DropDownApis/reducer';
import itemsByItmGrpIdList from './Global/DropDownApis/reducer';
import employeeCategoryList from './Global/DropDownApis/reducer';
import taxList from './Global/DropDownApis/reducer';
import uomList from './Global/DropDownApis/reducer';
import itemsListForIndent from './Global/DropDownApis/reducer';
import approvedIndentsList from './Global/DropDownApis/reducer';
import allItemGroupMaster_List from './Global/DropDownApis/reducer';
import  ShiftMasterReducer from './Master/ShiftMaster/reducer';
import HolidayListReducer from './Master/HolidayCalendar/reducer';
// Master dropdown Reducer
import MasterDropDownListReducer from './MasterDropdownApis/reducer';

import supplierListBy_suppId from './Global/DropDownApis/reducer';
import billing_shipping_adsList from './Global/DropDownApis/reducer';
import budgetHeadsList from './Global/DropDownApis/reducer';
import itemGrpMasterList from './Global/DropDownApis/reducer';
import boqListResp from './Global/DropDownApis/reducer'
/**Store Module**/
import Material_Request_List from './StoreModule/MaterialRequest/reducer';
import Consumption_List from './StoreModule/Consumption/reducer';
import StockTransfer_List from './StoreModule/StockTransfer/reducer';
import StockReturn_List from './StoreModule/StockReturn/reducer';
//sales module//
 import QuotationReducer from './Sales/Qutation/reducers';
 import SalesOrderReducer from './Sales/SalesOrder/reducers';
 import DeliveryOrderReducer from './Sales/DeliveryOrder/reducers';
 import InvoiceReducer from './Sales/InVoice/reducers';
import ReOccuringInvoiceReducer from './Sales/ReOccuringInVoice/reducer'
//  Accounting 
import BillsReducer from './Accounting/Bills/reducer';
import PaymentReducer from './Accounting/Payments/reducer';
// Jute Module
// Jute Indent
import JuteIndentReducer from './Jute/JuteIndent/reducer';
// Jute Gate Entry
import JuteGateEntryReducer from './Jute/JuteGateEntry/reducer';
import JutePurchaseReducer from './Jute/JutePurchase/reducer';
import JutePoReducer from "./Jute/JutePurchase/reducer"
import JuteQualityCheckReducer from './Jute/JuteQualityCheck/reducer';
import JuteMaterialReceiptReducer from './Jute/JuteMaterialReceipt/reducer';
import JuteBillPassReducer from './Jute/JuteBillPass/reducer';
import JuteMarketRateReducer from './Jute/JuteMarketRate/reducer';

import JuteIssueReducer from './Jute/JuteIssue/reducer';

import JuteConversionReducer from './Jute/JuteConversion/reducer';

import JuteBatchPlanningReducer from './Jute/JuteBatchPlanning/reducer';
//Jute Production
import FinishingEntryListReducer from './JuteProduction/FinishingEntry/reducer';
import WeavingProductionListReducer from './JuteProduction/WeavingProduction/reducer';
import BeamingProductionListReducer from './JuteProduction/BeamingProduction/reducer';
import WindingDoffEntryReducer from './JuteProduction/WindingDoffEntry/reducer';
import SpinningDoffEntryReducer from './JuteProduction/SpinningDoffEntry/reducer';
import CutsAndJugarEntryReducer from './JuteProduction/CutsAndJugarEntry/reducer';
import wastageEntryListReducer from "./JuteProduction/WastageEntry/reducer";
import DebitCreditReducer from './Purchase/Debit-credit-note/reducer';
import GstReducer from './GST_module/InvoiceCredentials/reducer'

const rootReducer = combineReducers({

    // public
    Layout,

    // Authentication
    // Account,
    Login,
    Indent,
    IndentReducer,
    CompanyList,
    price_enquiry_List,
    PriceEnquiryReducer,
    workOrder_List,
    PrResponse_List,
    PurchaseOrderReducer,
    InwardReducer,
    Debit_Credit_List,
    BillPassList,
    Tax_master_List,
    Supplier_List,
    AddItem_To_Tax_List,
    EmployeeReducer,
    PayslipReducer,
    PayRegisterReducer,
    LeaveRequestReducer,
    LeftSideMenuReducer,
    acadamicYearsList,
    SpellMasterListReducer,
    UploadFileReducer,
    PayRollDataReducer,
    PaySchemeReducer,
    ExpenseBookingReducer,
    AdvanceRequestReducer,
    DropDownListReducer,
    ProjectListReducer,
    StatusListReducer,
    SupplierListReducer,
    CustomerViewById,
    ItemGruopListReducer,
    CategoryTypeListReducer,
    Material_Request_List,
    Consumption_List,
    StockTransfer_List,
    StockReturn_List,
    QuotationReducer,
    SalesOrderReducer,
    ReOccuringInvoiceReducer,
    DeliveryOrderReducer,
    InvoiceReducer,
    BillsReducer,
    PaymentReducer,
    CustomerList,
    billingShippingList,
    itemGroupMasterList,
    itemsByItmGrpIdList,
    taxList,
    uomList,
    BankAccountListReducer,
    DesignationListReducer,
    CostCenterListReducer,
    DepartmentReducer,
    SubDepartmentReducer,
    AttendanceReducer,
    itemsListForIndent,
    LedgerReducer,
    LedgerGroupsReducer,
    TermsReducer,
    BudgetHeadsList,
    TransporterReducer,
    approvedIndentsList,
    allItemGroupMaster_List,
    LeaveTypeListReducer,
    Create_Leave_Type,
    CustomerListReducer,
    ItemListReducer,
    ItemGroupListReducer,
    UomListReducer,
    supplierListBy_suppId,
    billing_shipping_adsList,
    budgetHeadsList,
    itemGrpMasterList,
    boqListResp,
    CreateWorkOrder,
    WareHouseListReducer,
    checkScheme,
    ShiftMasterReducer,
    TravelCatageriesReducer,
    employeeCategoryList,
    WorkerCategoryReducer,
    VehicleTypesMasterReducer,
    WeavingQualityMasterReducer,
    MukamMasterReducer,
    QualityMasterReducer,
    BrokerMasterReducer,
    MachineMasterReducer,
    SpinningQualityReducer,
    YarnMasterReducer,
    CompanyMasterReducer,
    BranchMasterReducer,
    AgentMasterReducer,
    MasterDropDownListReducer,
    HolidayListReducer,
    CarriersReducer,
    JuteIndentReducer,
    JuteGateEntryReducer,
    JutePurchaseReducer,
    JutePoReducer,
    JuteQualityCheckReducer,
    JuteMaterialReceiptReducer,
    JuteBillPassReducer,
    JuteMarketRateReducer,
    JuteIssueReducer,
    JuteConversionReducer,
    JuteBatchPlanningReducer,
    FinishingEntryListReducer,
    WeavingProductionListReducer,
    BeamingProductionListReducer,
    WindingDoffEntryReducer,
    SpinningDoffEntryReducer,
    CutsAndJugarEntryReducer,
    wastageEntryListReducer,
    DebitCreditReducer,
    GstReducer
});

export default rootReducer;