import {
  ITEM_LISTS,
  ITEM_LISTS_SUCCESSFULL,
  CREATE_ITEM_MASTER,
  CREATE_ITEM_MASTER_SUCCESSFULL,
  UPDATE_ITEM_MASTER,
  UPDATE_ITEM_MASTER_SUCCESSFULL,
  ITEM_CODE_BY_ITEM_GROUP,
  ITEM_CODE_BY_ITEM_GROUP_SUCCESSFULL,
  INDENT_TYPE_LIST_MASTERS,
  INDENT_TYPE_LIST_MASTERS_SUCCESSFULL,
  CREATE_LEAVE_TYPE_MASTER,
  CREATE_LEAVE_TYPE_MASTER_SUCCESSFULL,
  GET_ITEM_CODE_BY_ITEM_GROUP,
  GET_ITEM_CODE_BY_ITEM_GROUP_SUCCESSFULL,
  GET_UOM_IN_ITEM_MASTER,
  GET_UOM_IN_ITEM_MASTER_SUCCESSFULLY,
  GET_ITEM_MASTER_VIEW_BY_ID,
  GET_ITEM_MASTER_VIEW_BY_ID_SUCCESSFULL
} from "./actionTypes";

export const getItemList = (url, data, history) => {
  return {
    type: ITEM_LISTS,
    payload: { url, data, history },
  };
};

export const ItemListSuccessfull = (data) => {
  return {
    type: ITEM_LISTS_SUCCESSFULL,
    payload: data,
  };
};
export const createItemMaster = (url, data, history) => {
    return {
      type: CREATE_ITEM_MASTER,
      payload: { url, data, history },
    };
  };
  
  export const createItemMasterSuccessfull = (data) => {
    return {
      type: CREATE_ITEM_MASTER_SUCCESSFULL,
      payload: data,
    };
  };

  export const createLeaveTypeMaster = (url, data, history) => {
    return {
      type: CREATE_LEAVE_TYPE_MASTER,
      payload: { url, data, history },
    };
  };
  
  export const createLeaveTypeMasterSuccessfull = (data) => {
    return {
      type: CREATE_LEAVE_TYPE_MASTER_SUCCESSFULL,
      payload: data,
    };
  };
  export const updateItemMaster = (url, data, history) => {
    return {
      type: UPDATE_ITEM_MASTER,
      payload: { url, data, history },
    };
  };
  
  export const updateItemMasterSuccessfull = (data) => {
    return {
      type: UPDATE_ITEM_MASTER_SUCCESSFULL,
      payload: data,
    };
  };
  
  export const getItemCodeByItemGroup = (url, history) => {
    return {
      type: ITEM_CODE_BY_ITEM_GROUP,
      payload: { url, history },
    };
  };
  
  export const ItemCodeByItemGroupSuccessfull = (data) => {
    return {
      type: ITEM_CODE_BY_ITEM_GROUP_SUCCESSFULL,
      payload: data,
    };
  };

  export const getIndentListMaster = (url, history) => {
    return {
      type: INDENT_TYPE_LIST_MASTERS,
      payload: {url, history}
    }
  }

  export const getIndentListMasterSuccessfull = (data) => {
    return {
      type: INDENT_TYPE_LIST_MASTERS_SUCCESSFULL,
      payload: data
    }
  }

  export const getItemByItemCode = (url, history) => {
    return {
      type: GET_ITEM_CODE_BY_ITEM_GROUP,
      payload: {url, history}
    }
  }

  export const getItemByItemCodeSuccessfull = (data) => {
    return {
      type: GET_ITEM_CODE_BY_ITEM_GROUP_SUCCESSFULL,
      payload: data
    }
  }

  export const getUomInItemMaster = (url, history) => {
    return {
      type: GET_UOM_IN_ITEM_MASTER,
      payload: {url, history}
    }
  }

  export const getUomInItemMasterSuccessfull = (data) => {
    return {
      type: GET_UOM_IN_ITEM_MASTER_SUCCESSFULLY,
      payload: data
    }
  }

  export const getItemMasterViewById = (url, history) => {
    return {
      type: GET_ITEM_MASTER_VIEW_BY_ID,
      payload: {url, history}
    }
  }

  export const getItemMasterViewByIdSuccessfull = (data) => {
    return {
      type: GET_ITEM_MASTER_VIEW_BY_ID_SUCCESSFULL,
      payload: data
    }
  }