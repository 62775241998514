import {
  TRANSPORTER_MASTER_LIST,
  TRANSPORTER_MASTER_LIST_SUCCESSFULL,
  CREATE_TRANSPORTER_MASTER,
  CREATE_TRANSPORTER_MASTER_SUCCESSFULL,
  UPDATE_TRANSPORTER_MASTER,
  UPDATE_TRANSPORTER_MASTER_SUCCESSFULL,
  NEW_TRANSPORT_MASTER_LIST,
  NEW_TRANSPORT_MASTER_LIST_SUCCESSFULL,
  API_ERRORS,
} from "./actionTypes";

export const transporterMasterList = (url, data, history) => {
  return {
    type: TRANSPORTER_MASTER_LIST,
    payload: { url, data, history },
  };
};
export const transporterMasterListSuccessfull = (data) => {
  return {
    type: TRANSPORTER_MASTER_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const transporterMasterNewListApi = (url, data, history) => {
    return {
      type: NEW_TRANSPORT_MASTER_LIST,
      payload: { url, data, history },
    };
  };
  export const transporterMasterNewListApiSuccessfull = (data) => {
    return {
      type: NEW_TRANSPORT_MASTER_LIST_SUCCESSFULL,
      payload: data,
    };
  };

export const createTransporterMaster = (url, data, history) => {
  return {
    type: CREATE_TRANSPORTER_MASTER,
    payload: { url, data, history },
  };
};

export const createTransporterMasterSuccessfull = (data) => {
  return {
    type: CREATE_TRANSPORTER_MASTER_SUCCESSFULL,
    payload: data,
  };
};
export const updateTransporterMaster = (url, data, history) => {
  return {
    type: UPDATE_TRANSPORTER_MASTER,
    payload: { url, data, history },
  };
};

export const updateTransporterMasterSuccessfull = (data) => {
  return {
    type: UPDATE_TRANSPORTER_MASTER_SUCCESSFULL,
    payload: data,
  };
};
export const apiErrors = (error) => {
  return {
    type: API_ERRORS,
    payload: error,
  };
};
