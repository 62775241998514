import {
  BUDGETHEADS_LIST,
  BUDGET_HEADS_LIST,
  CREATE_BUDGET_HEADS,
  UPDATE_BUDGET_HEADS,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { postAdd, getListurl } from "../../../helpers/Server_Helper";
import {
  budgetHeadsListSuccessfull,
  budgetHeads_ListSuccessfull,
  createBudgetHeadsSuccessfull,
  updateBudgetHeadsSuccessfull,
} from "./actions";
import swal from "sweetalert";

function* BudgetHeadsList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(budgetHeadsListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* BudgetHeads_List({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(budgetHeads_ListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateBudgetHeads({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createBudgetHeadsSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/budget_heads");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateBudgetHeads({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateBudgetHeadsSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/budget_heads");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

export function* watchBudgetHeadsList() {
  yield takeEvery(BUDGET_HEADS_LIST, BudgetHeadsList);
  yield takeEvery(BUDGETHEADS_LIST, BudgetHeads_List);
}
export function* watchCreateBudgetHeads() {
  yield takeEvery(CREATE_BUDGET_HEADS, CreateBudgetHeads);
}
export function* watchUpdateBudgetHeads() {
  yield takeEvery(UPDATE_BUDGET_HEADS, UpdateBudgetHeads);
}

function* BudgetHeadsSaga() {
  yield all([
    fork(watchBudgetHeadsList),
    fork(watchCreateBudgetHeads),
    fork(watchUpdateBudgetHeads),
  ]);
}

export default BudgetHeadsSaga;
