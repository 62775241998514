import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import CustomButton from "../../../components/Buttons/Buttons";
import { Link } from "react-router-dom";
import TextField from "../../../components/TextField/TextField";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import moment from "moment";
import { getWorkerList } from "../../../store/HRMS/ExpenseBooking/actions";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import { getEmployeeList } from "../../../store/HRMS/EmployeeDataBase/actions";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import NewDatePicker from "../../../components/DatePicker/NewDatePicker";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import {
  setAdvanceRequest,
  AdvanceRequestViewById,
  getWorkerDataByEbNO,
  ClearWokerDataForAdvanceRequest,
} from "../../../store/HRMS/AdvanceRequest/actions";
import searchIcon from "../../../assets/images/search.png";
import InputAdormentSearch from "../../../components/InputAdorment/InputAdormentSearch";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import swal from "sweetalert";
class CreateAdvanceRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      type_of_advance: "Issue Loan",
      type_of_advance_value: 2,
      emp_code: JSON.parse(localStorage.getItem("authUser")).ebNo,
      workerName: "",
      deptIdDesc: "",
      designationIdDesc: "",
      ebId: "",
      requestDate: "",
      bank_name: "",
      applicable_year: "",
      monthly_salary: "",
      deduction_year: "",
      amount_requested: "",
      deduction_starts_from: "",
      no_of_applicable_months: "",
      deduction_up_to: "",
      remarks_of_applicant: "",
      remarks: "",
      employeeListData: [],
      empData: [],
      bankfield: false,
      EmpField: true,
      mData: [],
      AdvanceRequestViewByid: [],
      show: false,
      showData: false,
      ApprovalButton: false,
      ProcessPayment: false,
      HideUpdateButton: true,
      dateOfJoining: "",
    };
  }
  setListValues = (
    deductionStartsFrom,
    amountRequested,
    noOfApplicableMonths,
    deductionYear
  ) => {
    var list = [];

    if (
      deductionStartsFrom !== "" &&
      amountRequested !== "" &&
      noOfApplicableMonths !== "" &&
      deductionYear !== ""
    ) {
      var amount = parseFloat(amountRequested) / parseInt(noOfApplicableMonths);
      for (var i = 0; i < parseInt(noOfApplicableMonths); i++) {
        var val = parseInt(deductionStartsFrom) + i;
        var y = Number(deductionYear);
        if (val > 12) {
          var n = parseInt(val / 12);
          y = y + n;
        }
        var obj = {
          month: moment()
            .month(Number(this.state.deduction_starts_from) + i - 1)
            .format("MMMM"),
          year: y,
          amount: amount.toFixed(2),
          deductionStatus: "N",
        };
        list.push(obj);
      }
    }

    this.setState({
      mData: list,
    });
  };
  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "emp_code") {
      this.setState({
        emp_code: event,
      });
    }
    if (key === "MonthlySalary") {
      this.setState({
        monthly_salary: event,
      });
    }
    if (key === "AmountRequested") {
      this.setState({
        amount_requested: event,
      });
      this.setListValues(
        this.state.deduction_starts_from,
        event,
        this.state.no_of_applicable_months,
        this.state.deduction_year
      );
    }
    if (key === "NoofApplicableMonths") {
      this.setState({
        no_of_applicable_months: event,
      });
      // calculation
      let newDate = new Date();
      var date =
        this.state.deduction_year +
        "-" +
        this.state.deduction_starts_from +
        "-" +
        newDate.getDate();
      var newVal = date.toString();
      var currentDate = moment(newVal);
      var futureMonth = moment(currentDate).add(event - 1, "M");
      var futureMonthEnd = moment(futureMonth).endOf("month");

      if (
        currentDate.date() != futureMonth.date() &&
        futureMonth.isSame(futureMonthEnd.format("YYYY-MM-DD"))
      ) {
        futureMonth = futureMonth.add(1, "M");
      }
      var dates = this.state.deduction_year;
      var newValue = dates.toString();

      var dateYear = moment(newValue);
      var finalYear = moment(dateYear).add(event, "M").calendar();
      var Futureyear = moment(finalYear, "DD-MM-YYYY");

      if (
        this.state.deduction_starts_from !== "" &&
        this.state.deduction_starts_from !== undefined
      ) {
        this.setState({
          deduction_up_to:
            futureMonth.format("MM") + " " + Futureyear.format("YYYY"),
        });
      }
      this.setListValues(
        this.state.deduction_starts_from,
        this.state.amount_requested,
        event,
        this.state.deduction_year
      );
    }
    if (key === "DeductionUpTo") {
      this.setState({
        deduction_up_to: event,
      });
    }
    if (key === "RemarksofApplicant") {
      this.setState({
        remarks_of_applicant: event,
      });
    }
    if (key === "Remarks") {
      this.setState({
        remarks: event,
      });
    }
    if (key === "BankName") {
      this.setState({
        bank_name: event,
      });
    }
  };
  onhandleChange = (selectedValue, selectedName, name) => {
    if (name === "ApplicableYear") {
      this.setState({
        applicable_year: selectedName,
      });
    }
    if (name === "DeductionYear") {
      this.setState({
        deduction_year: selectedName,
      });
      this.setListValues(
        this.state.deduction_starts_from,
        this.state.amount_requested,
        this.state.no_of_applicable_months,
        selectedName
      );
    }
    if (name === "DeductionStartsFrom") {
      this.setState({
        deduction_starts_from: selectedValue,
      });

      let newDate = new Date();
      var date =
        this.state.deduction_year +
        "-" +
        this.state.no_of_applicable_months +
        "-" +
        newDate.getDate();
      var newVal = date.toString();
      var currentDate = moment(newVal);
      var futureMonth = moment(currentDate).add(selectedName - 1, "M");
      var futureMonthEnd = moment(futureMonth).endOf("month");

      if (
        currentDate.date() != futureMonth.date() &&
        futureMonth.isSame(futureMonthEnd.format("YYYY-MM-DD"))
      ) {
        futureMonth = futureMonth.add(1, "M");
      }
      if (
        this.state.no_of_applicable_months !== "" &&
        this.state.no_of_applicable_months !== undefined
      ) {
        this.setState({
          deduction_up_to:
            futureMonth.format("MM") + " " + currentDate.format("YYYY"),
        });
      }
      this.setListValues(
        selectedName,
        this.state.amount_requested,
        this.state.no_of_applicable_months,
        this.state.deduction_year
      );
    }
    if (name === "TypeOfAdvance") {
      this.setState({
        type_of_advance: selectedName,
        type_of_advance_value: selectedValue,
      });
      if (selectedName == "Issue Loan") {
        this.setState({
          bankfield: false,
          EmpField: true,
        });
      }
      if (selectedName == "Borrow Loan") {
        this.setState({
          EmpField: false,
          bankfield: true,
        });
      }
      if (selectedName == "Select") {
        this.setState({
          EmpField: false,
          bankfield: false,
        });
      }
    }
  };
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    if (this.props.location.state) {
      this.props.AdvanceRequestViewById(
        serverApi.ADVANCE_REQUEST_VIEW_BY_ID +
          this.props.location.state.rowData.hdrId +
          "/" +
          this.state.userDit.userId,

        this.props.history
      );
    }
  }
  componentWillReceiveProps(props) {
    if (this.props.location.state) {
      if (this.props.location.state.rowData !== undefined) {
        if (this.props.location.state.rowData.bankName !== "") {
          this.setState({
            EmpField: false,
            bankfield: true,
          });
        }
        if (this.props.location.state.rowData.statusDesc == "APPROVED") {
          this.setState({
            ProcessPayment: true,
            HideUpdateButton: false,
            ApprovalButton: false,
          });
        }
      }
      if (props.advance_request_view_byid) {
        this.setState({
          AdvanceRequestViewByid: props.advance_request_view_byid,
        });
        if (props.advance_request_view_byid) {
          if (props.advance_request_view_byid.hdrDto) {
            if (props.advance_request_view_byid.hdrDto.approveButton) {
              this.setState({
                ApprovalButton: true,
              });
            }
            if (this.state.employeeListData) {
              if (this.state.employeeListData.length !== 0) {
                this.state.empData = this.state.employeeListData.filter(
                  (data) =>
                    data.empCode ==
                    this.props.advance_request_view_byid.hdrDto.ebNo
                );
              }
            }
          }
        }
      }
      if (
        this.props.advance_request_view_byid &&
        this.props.location.state.rowData
      ) {
        if (
          this.props.advance_request_view_byid.hdrDto &&
          this.props.advance_request_view_byid.dtlDto
        ) {
          var dtLdata = [];

          this.props.advance_request_view_byid.dtlDto.map((prop) => {
            dtLdata.push({
              month: prop.month,
              year: prop.year,
              amount: prop.amount,
              deductionStatus: prop.deductionStatus,
            });
          });
          this.setState({
            mData: dtLdata,
            type_of_advance:
              this.props.advance_request_view_byid.hdrDto.typeOfAdvance,
            emp_code: this.props.advance_request_view_byid.hdrDto.ebNo,
            workerName: this.props.advance_request_view_byid.hdrDto.workerName,
            ebId: this.props.advance_request_view_byid.hdrDto.ebId,
            requestDate:
              this.props.advance_request_view_byid.hdrDto.requestDate,
            applicable_year:
              this.props.advance_request_view_byid.hdrDto.applicableYear,
            monthly_salary:
              this.props.advance_request_view_byid.hdrDto.monthlySalary,
            deduction_year:
              this.props.advance_request_view_byid.hdrDto.deductionYear.toString(),
            amount_requested:
              this.props.advance_request_view_byid.hdrDto.amountRequested,
            deduction_starts_from:
              this.props.advance_request_view_byid.hdrDto.deductionStartsFrom.toString(),
            no_of_applicable_months:
              this.props.advance_request_view_byid.hdrDto.noOfApplicableMonths,
            deduction_up_to:
              this.props.advance_request_view_byid.hdrDto.deductionUpto,
            remarks_of_applicant:
              this.props.advance_request_view_byid.hdrDto.remarksOfApplicant,
            remarks: this.props.advance_request_view_byid.hdrDto.remarks,
            bank_name: this.props.advance_request_view_byid.hdrDto.bankName,
          });
        }
      }
    }

    if (props.Get_worker_data) {
      if (props.Get_worker_data.data) {
        if (props.Get_worker_data.data.empStatus == 35) {
          this.setState({
            workerName: props.Get_worker_data.data.workerName,
            deptIdDesc: props.Get_worker_data.data.deptIdDesc,
            designationIdDesc: props.Get_worker_data.data.designationIdDesc,
            monthly_salary: props.Get_worker_data.data.grossSalary,
            ebId: props.Get_worker_data.data.ebId,
            emp_code: props.Get_worker_data.data.ebNo,
          });
          if (props.Get_worker_data.data.dateOfJoining) {
            var DateFormate = moment(
              props.Get_worker_data.data.dateOfJoining.toString(),
              "YYYY-MM-DD"
            ).valueOf();
            this.setState({
              dateOfJoining: DateFormate,
            });
          }
        } else {
          this.setState({
            showData: true,
          });
        }
      } else {
        this.setState({
          show: true,
        });
      }
    }
  }
  handleSearch = () => {
    this.props.getWorkerDataByEbNO(
      serverApi.WORKER_LIST +
        this.state.emp_code +
        "/" +
        localStorage.getItem("companyId") +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).userId,
      this.props.history
    );
  };
  onclickOk = () => {
    this.setState({
      show: false,
      showData: false,
    });
    this.props.ClearWokerDataForAdvanceRequest(null);
  };

  handleSelectDate = (e, name) => {
    if (name === "RequestDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      var dateTime = Date;

      if (e.getTime() < this.state.dateOfJoining) {
        swal("Request Date Shouldn't be Less than Employee Joining Date");
        this.setState({
          requestDate: null,
        });
      } else {
        this.setState({
          requestDate: e.getTime(),
        });
      }
    }
  };
  onClickNext = () => {
    const data = {
      dtlDto: this.state.mData,
      hdrDto: {
        companyId: localStorage.getItem("companyId"),
        ebId: this.state.ebId,
        requestDate: this.state.requestDate,
        typeOfAdvance: this.state.type_of_advance,
        applicableYear: this.state.applicable_year,
        monthlySalary: this.state.monthly_salary,
        deductionYear: this.state.deduction_year,
        amountRequested: this.state.amount_requested,
        deductionStartsFrom: this.state.deduction_starts_from,
        noOfApplicableMonths: this.state.no_of_applicable_months,
        deductionUpto: this.state.deduction_up_to,
        remarksOfApplicant: this.state.remarks_of_applicant,
        createdBy: this.state.userDit.userId,
        remarks: this.state.remarks,
        bankName: this.state.bank_name,
      },
    };

    if (this.state.type_of_advance === "Issue Loan") {
      if (this.state.emp_code == null || this.state.emp_code == "") {
        swal("Please Enter The Employee Code");
        return false;
      }
    } else if (this.state.type_of_advance === "Borrow Loan") {
      if (
        this.state.bank_name == null ||
        this.state.bank_name == "" ||
        this.state.bank_name == undefined
      ) {
        swal("Please Enter Bank Name");
        return false;
      }
    } else {
      if (this.state.type_of_advance === "") {
        swal("Please Enter The Type Of Advance");
        return false;
      }
    }

    if (this.state.requestDate === "" || this.state.requestDate === null) {
      swal("Please Enter The Request Date");
      return false;
    }
    if (this.state.amount_requested === "") {
      swal("Please Enter The Amount Requested ");
      return false;
    }
    if (this.state.deduction_starts_from === "") {
      swal("Please Enter The Deduction Starts From");
      return false;
    }
    if (this.state.deduction_year === "") {
      swal("Please Enter The Deduction Starts From");
      return false;
    }
    if (this.state.no_of_applicable_months === "") {
      swal("Please Enter The No Of Applicable Months");
      return false;
    }
    this.props.setAdvanceRequest(
      serverApi.CREATE_ADVANCE_REQUEST,
      data,
      this.props.history
    );
  };
  onClickUpdate = (status) => {
    if (this.state.type_of_advance === "Borrow Loan") {
      if (
        this.state.bank_name == null ||
        this.state.bank_name == "" ||
        this.state.bank_name == undefined
      ) {
        swal("Please Enter Bank Name");
        return false;
      }
    } else if (this.state.type_of_advance === "Issue Loan") {
      if (this.state.emp_code == null || this.state.emp_code == "") {
        swal("Please Enter The Employee Code");
        return false;
      }
    } else {
      if (this.state.type_of_advance === "") {
        swal("Please Enter The Type Of Advance");
        return false;
      }
    }
    if (this.state.requestDate === "" || this.state.requestDate === null) {
      swal("Please Enter The Request Date");
      return false;
    }
    if (this.state.amount_requested === "") {
      swal("Please Enter The Amount Requested ");
      return false;
    }
    if (this.state.deduction_starts_from === "") {
      swal("Please Enter The Deduction Starts From");
      return false;
    }
    if (this.state.deduction_year === "") {
      swal("Please Enter The Deduction Starts From");
      return false;
    }
    if (this.state.no_of_applicable_months === "") {
      swal("Please Enter The No Of Applicable Months");
      return false;
    }

    if (this.props.location.state) {
      var myDate = new Date(this.state.requestDate).getTime();
      const data = {
        dtlDto: this.state.mData,
        hdrDto: {
          hdrId: this.props.location.state.rowData.hdrId,
          companyId: localStorage.getItem("companyId"),
          ebId: this.state.ebId,
          requestDate: myDate,
          typeOfAdvance: this.state.type_of_advance,
          applicableYear: this.state.applicable_year,
          monthlySalary: this.state.monthly_salary,
          deductionYear: this.state.deduction_year,
          amountRequested: this.state.amount_requested,
          deductionStartsFrom: this.state.deduction_starts_from,
          noOfApplicableMonths: this.state.no_of_applicable_months,
          deductionUpto: this.state.deduction_up_to,
          remarksOfApplicant: this.state.remarks_of_applicant,
          updatedBy: this.state.userDit.userId,
          remarks: this.state.remarks,
          status: status,
          isActive: 1,
          userId: this.state.userDit.userId,
          bankName: this.state.bank_name,
        },
      };
      this.props.setAdvanceRequest(
        serverApi.UPDATE_ADVANCE_REQUEST,
        data,
        this.props.history
      );
    }
  };
  handleSelect = (value, name, field, id) => {
    if (name === "month") {
      this.setState({
        deduction_starts_from: value,
      });

      var list = [];
      if (
        value !== "" &&
        this.state.amount_requested !== "" &&
        this.state.no_of_applicable_months !== "" &&
        this.state.deduction_year !== ""
      ) {
        var amount =
          parseFloat(this.state.amount_requested) /
          parseInt(this.state.no_of_applicable_months);
        for (var i = 0; i < parseInt(this.state.no_of_applicable_months); i++) {
          var val = parseInt(Number(value)) + i;
          var y = Number(this.state.deduction_year);
          if (val > 12) {
            var n = parseInt(val / 12);
            y = y + n;
          }
          var obj = {
            month: moment()
              .month(Number(value) + i - 1)
              .format("MM"),
            year: y,
            amount: amount.toFixed(2),
            deductionStatus: "N",
          };
          list.push(obj);
        }
        this.setState({
          mData: list,
        });
      }

      let newDate = new Date();
      var date =
        this.state.deduction_year +
        "-" +
        this.state.no_of_applicable_months +
        "-" +
        newDate.getDate();
      var newVal = date.toString();
      var currentDate = moment(newVal);
      var futureMonth = moment(currentDate).add(value - 1, "M");
      var futureMonthEnd = moment(futureMonth).endOf("month");

      if (
        currentDate.date() != futureMonth.date() &&
        futureMonth.isSame(futureMonthEnd.format("YYYY-MM-DD"))
      ) {
        futureMonth = futureMonth.add(1, "M");
      }
      if (
        this.state.no_of_applicable_months !== "" &&
        this.state.no_of_applicable_months !== undefined
      ) {
        this.setState({
          deduction_up_to:
            futureMonth.format("MM") + " " + currentDate.format("YYYY"),
        });
      }
    }
    if (name === "year") {
      this.setState({
        deduction_year: value,
      });
      this.setListValues(
        this.state.deduction_starts_from,
        this.state.amount_requested,
        this.state.no_of_applicable_months,
        Number(value)
      );

      let newDate = new Date();
      var date =
        value +
        "-" +
        this.state.no_of_applicable_months +
        "-" +
        newDate.getDate();
      var newVal = date.toString();
      var currentDate = moment(newVal);
      var futureMonth = moment(currentDate).add(
        this.state.deduction_starts_from - 1,
        "M"
      );
      var futureMonthEnd = moment(futureMonth).endOf("month");

      if (
        currentDate.date() != futureMonth.date() &&
        futureMonth.isSame(futureMonthEnd.format("YYYY-MM-DD"))
      ) {
        futureMonth = futureMonth.add(1, "M");
      }
      if (
        this.state.no_of_applicable_months !== "" &&
        this.state.no_of_applicable_months !== undefined
      ) {
        this.setState({
          deduction_up_to:
            futureMonth.format("MMM") + " " + currentDate.format("YYYY"),
        });
      }
    }
  };
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    const mcolumns = [
      {
        id: 1,
        label: "Month",
      },
      {
        id: 2,
        label: "Year",
      },
      {
        id: 3,
        label: "Amount",
      },
      {
        id: 4,
        label: "Deduction Status",
      },
    ];

    return (
      <div className="createAdvanceRequest">
        <div className="consoleFormContainer">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectNew
                        update={this.state.type_of_advance ? 1 : 0}
                        arrayOfData={TypeOfAdvance}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.onhandleChange}
                        selected={this.state.type_of_advance}
                        name="TypeOfAdvance"
                        label="Type Of Advance"
                        required
                        handleMenuOpen={this.handleMenuOpen}
                        isDropdownOpen={this.state.isDropdownOpen}
                      />
                    </div>
                  </Box>
                  {this.state.EmpField && (
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <InputAdormentSearch
                          label={"Employee Code"}
                          placeholder="Enter here"
                          imgUrl={searchIcon}
                          handleChange={this.handleChange("emp_code")}
                          onClick={this.handleSearch}
                          value={this.state.emp_code}
                          required={true}
                        />
                      </div>
                    </Box>
                  )}
                  {this.state.bankfield && (
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          value={this.state.bank_name}
                          label="Bank Name"
                          placeholder="Enter here"
                          onChange={this.handleChange("BankName")}
                          required
                        />
                      </div>
                    </Box>
                  )}
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextField
                        label="Name"
                        placeholder="Name"
                        value={this.state.workerName}
                        disabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextField
                        label="Department"
                        placeholder="Department"
                        value={this.state.deptIdDesc}
                        disabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextField
                        label="Designation"
                        placeholder="Designation"
                        value={this.state.designationIdDesc}
                        disabled={true}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormContainer">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>
                          Request Date<span className="starcolor">*</span>
                        </label>
                      </div>

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          name="RequestDate"
                          inputFormat="dd-MM-yyyy"
                          value={this.state.requestDate}
                          fullWidth
                          onChange={(e) =>
                            this.handleSelectDate(e, "RequestDate")
                          }
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        disabled={true}
                        label="Monthly Salary"
                        placeholder="Enter here"
                        value={this.state.monthly_salary}
                        onChange={this.handleChange("MonthlySalary")}
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        type="number"
                        label="Amount Requested"
                        placeholder="Enter here"
                        value={this.state.amount_requested}
                        onChange={this.handleChange("AmountRequested")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>
                          Deduction Starts From
                          <span className="starcolor">*</span>
                        </label>
                      </div>

                      <NewDatePicker
                        handleSelect={this.handleSelect}
                        field="1"
                        month={this.state.deduction_starts_from}
                        year={this.state.deduction_year}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        type="number"
                        label="No of Applicable Months"
                        placeholder="Enter here"
                        value={this.state.no_of_applicable_months}
                        onChange={this.handleChange("NoofApplicableMonths")}
                        minValue="0"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        disabled={true}
                        label="Deduction UpTo"
                        placeholder="Enter here"
                        value={this.state.deduction_up_to}
                        onChange={this.handleChange("DeductionUpTo")}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div className="consoleFormContainer">
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          label="Remarks of Applicant"
                          placeholder="Enter Amount"
                          value={this.state.remarks_of_applicant}
                          onChange={this.handleChange("RemarksofApplicant")}
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          label="Remarks"
                          placeholder="Enter Here"
                          value={this.state.remarks}
                          onChange={this.handleChange("Remarks")}
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
            {/* Table */}
            {this.state.show && (
              <CommonPopup>
                <div className="delAddItem">
                  <div>No Employee found under the Current User!</div>
                  <div className="delAddItemBtns">
                    <input
                      type="button"
                      onClick={this.onclickOk.bind(this)}
                      value="Ok!"
                      className="delYesBtn"
                    />
                  </div>
                </div>
              </CommonPopup>
            )}
            {this.state.showData && (
              <CommonPopup>
                <div className="delAddItem">
                  <div>Only Joined User Can Create the Advance Request</div>
                  <div className="delAddItemBtns">
                    <input
                      type="button"
                      onClick={this.onclickOk.bind(this)}
                      value="Ok!"
                      className="delYesBtn"
                    />
                  </div>
                </div>
              </CommonPopup>
            )}
            <div className="gridContainer">
              <div className="gridComponentNoBorder">
                <table className="table">
                  <thead>
                    <tr>
                      {mcolumns.map((item, i) => (
                        <th scope="col" key={i}>
                          {item.label}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {this.state.mData.map((item, i) => (
                      <>
                        <tr>
                          <td>
                            <span>{item.month}</span>
                          </td>
                          <td>
                            <span>{item.year}</span>
                          </td>
                          <td>
                            <span>{item.amount}</span>
                          </td>
                          <td>
                            <span>{item.deductionStatus}</span>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="consoleFormButtonBlock">
              <Link to="/advancerequest">
                <CustomButton label={"Cancel"} className="greenBorderButton" />
              </Link>
              {this.props.location.state && this.state.HideUpdateButton ? (
                <>
                  <CustomButton
                    label={"Update"}
                    className="greenButton"
                    handleClick={() => this.onClickUpdate()}
                    type="sumbit"
                  />
                  {this.state.ApprovalButton && (
                    <CustomButton
                      label={"Approve"}
                      className="greenButton"
                      handleClick={() => this.onClickUpdate(1)}
                      type="sumbit"
                    />
                  )}
                </>
              ) : (
                <>
                  {this.props.location.state == undefined && (
                    <CustomButton
                      label={"Create Request"}
                      className="greenButton"
                      handleClick={this.onClickNext}
                      type="sumbit"
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const DeductionStartsFrom = [
  {
    value: "1",
    label: "January",
    name: "January",
  },
  {
    value: "2",
    label: "February",
    name: "February",
  },
  {
    value: "3",
    label: "March",
    name: "March",
  },
  {
    value: "4",
    label: "April",
    name: "April",
  },
  {
    value: "5",
    label: "May",
    name: "May",
  },
  {
    value: "6",
    label: "June",
    name: "June",
  },
  {
    value: "7",
    label: "July",
    name: "July",
  },
  {
    value: "8",
    label: "August",
    name: "August",
  },
  {
    value: "9",
    label: "September",
    name: "September",
  },
  {
    value: "10",
    label: "October",
    name: "October",
  },
  {
    value: "11",
    label: "November",
    name: "November",
  },
  {
    value: "12",
    label: "December",
    name: "December",
  },
];
const ApplicableYear = [
  {
    value: "2022",
    label: "2022",
    name: "2022",
  },
  {
    value: "2021",
    label: "2021",
    name: "2021",
  },
  {
    value: "2020",
    label: "2020",
    name: "2020",
  },
  {
    value: "2019",
    label: "2019",
    name: "2019",
  },
];
const TypeOfAdvance = [
  {
    value: 1,
    label: "Borrow Loan",
    name: "Borrow Loan",
  },
  {
    value: 2,
    label: "Issue Loan",
    name: "Issue Loan",
  },
];
const mapStatetoProps = (state) => {
  const { worker_List } = state.ExpenseBookingReducer;
  const { employeeList } = state.EmployeeReducer;
  const { create_advance_request, advance_request_view_byid, Get_worker_data } =
    state.AdvanceRequestReducer;
  return {
    Get_worker_data,
    worker_List,
    employeeList,
    create_advance_request,
    advance_request_view_byid,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getWorkerList,
    getEmployeeList,
    setAdvanceRequest,
    AdvanceRequestViewById,
    getWorkerDataByEbNO,
    ClearWokerDataForAdvanceRequest,
  })(CreateAdvanceRequest)
);
