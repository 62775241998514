import axios from "axios";
// import cons from "./Consts";

import { getTenantId } from "./Consts";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");

  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postRegister = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Token Method
const getToken = (url, data) => {
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: "Basic dHV0b3JpYWxzcG9pbnQ6bXktc2VjcmV0LWtleQ==",
    "Content-Type": "application/x-www-form-urlencoded",
    "X-TenantId": tenID,
  };
  /* Hear i am using formdata payload. data variable is actual payload*/
  var bodyFormData = new FormData();
  bodyFormData.append("grant_type", "password");
  bodyFormData.append("username", data.username);
  bodyFormData.append("password", data.password);

  return axios
    .post(url, bodyFormData, { headers: headers })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;

      return response.data;
    })
    .catch((err) => {
      // throw err[1];
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postLogin = (url, data) => {
  const mtoken =
    localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: 0,
  };
  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;

      return response.data;
    })
    .catch((err) => {
      // throw err[1];
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// postForgetPwd
const postForgetPwd = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

// postResetPassword
const postResetPwd = (url, data) => {
  const mtoken =
    localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: 0,
  };

  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

const getLocalJson = (url, data) => {
  return axios
    .get(url, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

// postForgetPwd
const getFormList = (url, data) => {
  return axios
    .get("./data/forms.json", data)
    .then((response) => {
      // if (response.status === 400 || response.status === 500)
      //     throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};
const getModuleList = (url, data) => {
  return axios
    .get("./data/modules.json", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

const getCreateIndentAddList = (url, data) => {
  return axios
    .get("./data/createIndentItems.json", data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.message;
    });
};

export {
  getLoggedInUser,
  isUserAuthenticated,
  postRegister,
  postLogin,
  postForgetPwd,
  getToken,
  getFormList,
  postResetPwd,
  getModuleList,
  getCreateIndentAddList,
  getLocalJson
};
