import { all, call, fork, takeEvery, put, select } from "redux-saga/effects";
import swal from "sweetalert";
import { showSpinner, hideSpinner } from "../../../helpers/Consts";

import {
  CREATE_QUOTATIONS,
  GET_ITEM_UOM_RELATION_VALUE,
  GET_QUOTATION_BROKER,
  QUOTATION_ITEM_BY_ITEM_GROUP_ID,
  QUOTATION_LIST,
  QUOTATION_UOM_LIST,
  QUOTAION_VIEW_BY_ID_IN_QUOTATION,
  QUOTATION_UPDATE,
  GET_UOM_RELATION_VALUES,
 
  GET_ITEM_DETAILS_BY_ITEM_GROUP_AND_ITEM_ID_AND_COMPANY_ID,
} from "./actionTypes";
import {
  QuotationListSuccessfull,
  QuotationListUomSuccessfull,
  QuotationItemsByItemGroupIdSuccessfull,
  getItemUomRelationSuccessfull,
  setQuotationSuccessfull,
  getQuotationBrokerSuccessfull,
  getQuotationViewByIdSuccessfull,
  getQuotationHeaders,
  getQuotationLineItems,
  updateQuotationSucceefull,
  getUomRelationValuesSuccessfull,

  getItemDetailsByItemGroupAndItemIdAndComapnyIdSuccessfull,
} from "./actions";
import { SalesOrderJuteGovtLineItems } from "../SalesOrder/actions";
import { getList, getListurl, postAdd } from "../../../helpers/Server_Helper";
import { serverApi } from "../../../helpers/Consts";
import { getBillingShippingList } from "../../../store/Global/DropDownApis/actions";
import { isEmpty, limitDecimals } from "../../../Utilities/helper";
import { SalesGstCalculations } from "../../../Utilities/SalesGstCalculation";

function* getQuotationList({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    if (response) {
      hideSpinner();
      yield put(QuotationListSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getQuotationUomList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (response) {
      yield put(QuotationListUomSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getUomRelationValueList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    const { SoJuteGovtLineItems } = yield select(
      (state) => state.SalesOrderReducer
    );
    if (response) {
      const SecondaryUOMList = response.data;
      let LineItemsRecords = [...SoJuteGovtLineItems];
      if (SecondaryUOMList.length !== 0) {
        SecondaryUOMList.map((SecondaryUOM) => {
          if (SoJuteGovtLineItems && SoJuteGovtLineItems.length !== 0) {
            LineItemsRecords = LineItemsRecords.map((item, index) => {
              if (SecondaryUOM.itemId === Number(item.itemId)) {
                if (SecondaryUOM.relatedUom === "BALE") {
                  return {
                    ...item,
                    govtRateUom: SecondaryUOM.relatedUom,
                    govtQuantityUom: SecondaryUOM.relatedUom,
                    govtRelationValue: SecondaryUOM.relationValue,
                  };
                } else if (SecondaryUOM.relatedUom === "MTS") {
                  return {
                    ...item,
                    govtRateUom2: SecondaryUOM.relatedUom,
                    govtQuantityUom2: SecondaryUOM.relatedUom,
                    govtRelationValue2: SecondaryUOM.relationValue,
                  };
                } else {
                  return {
                    ...item,
                    govtRateUom3: SecondaryUOM.relatedUom,
                    govtQuantityUom3: SecondaryUOM.relatedUom,
                    govtRelationValue3: SecondaryUOM.relationValue,
                  };
                }
              }
              return item;
            });
          }
        });
      }else{           
        LineItemsRecords = LineItemsRecords.map((item, index) => {
          return{
            ...item,
            govtRateUom: "",
            govtRateCalCulatedValue: "",
            govtRateUom2: "",
            govtRateCalculatedValue2: "",
            govtQuantityUom: "",
            govtRelationValue: 0,
            govtQtyCalculatedValue: 0,
            govtQuantityUom2: "",
            govtRelationValue2: 0,
            govtQtyCalculatedValue2: 0,
            govtQuantityUom3: "",
            govtRelationValue3: 0,
            govtQtyCalculatedValue3: 0,
          }
        })        
      }

      yield put(SalesOrderJuteGovtLineItems(LineItemsRecords));

      yield put(getUomRelationValuesSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getQuotationItemByItemGroup({
  payload: { url, data, history, rowId },
}) {
  const { quoteLineItems } = yield select((state) => state.QuotationReducer);

  try {
    const response = yield call(getList, url, data);
    if (response) {
      yield put(QuotationItemsByItemGroupIdSuccessfull({ response }));
      let ListFilter = response.filter((data) => {
        if (data.value !== "0") {
          return data;
        }
      });
      var list = [];
      ListFilter.map((prop) => {
        list.push({
          label: prop.itemDesc,
          name: prop.itemDesc,
          value: prop.itemId,
          variant: prop.itemCode,
        });
      });
    }
    if (quoteLineItems) {
      var LineItemsRecords = [];
      if (quoteLineItems.length !== 0) {
        LineItemsRecords = quoteLineItems.map((item) => {
          if (rowId == item.id) {
            item.quoteLineItems = list;
          }

          return item;
        });
        yield put(getQuotationLineItems(LineItemsRecords));
      }
    }
  } catch (error) {
    console.error('An error occurred:', error);
    hideSpinner(); // Hide spinner or loading indicator

    // Check if the error is undefined
    if (error === undefined) {
      // Handle undefined error gracefully
      // Display informative message to the user
      swal("An unexpected error occurred. Please try again later.", { icon: "error" });
    } else if (error.response) {
      // Handle other types of errors (e.g., API errors)
      hideSpinner();
      if (error?.response?.data?.status) {
        swal(error.response.data.title, { icon: "error" });
        console.error('API error:', error.response.data); // Log detailed error information
      }
    } else {
      // Handle other types of errors
      swal("An unexpected error occurred. Please try again later.", { icon: "error" });
    }
  }
}

function* getItemUomRelationByUomCode({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    if (response) {
      yield put(getItemUomRelationSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* setQuotationsData({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    if (response) {
      hideSpinner();
      yield put(setQuotationSuccessfull({ response }));
      if (response.status) {
        if (response.data.status === true) {
          swal(response.data.message, { icon: "success" });
          history.push("/quotation");
        } else {
          swal(response.data.message, { icon: "danger" });
        }
      } else {
        swal(response.data.message, { icon: "danger" });
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* getQuotationBrokersData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (response) {
      yield put(getQuotationBrokerSuccessfull({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getQuotationViewByIdDataInQuotation({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if (response.data) {
      if(!isEmpty(response.data.customerId)){
        var CustomerUrl =
        serverApi.GET_ALL_BILLING_SHIPPING +
        response.data.customerId +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher +
        "?isForSalesModule=1";
      yield put(getBillingShippingList(CustomerUrl, history));
      }

      if (response.data.salesQuotationLineItems) {
        if (response.data.salesQuotationLineItems.length !== 0) {
          let quotationLineItmeData = [];
          response.data.salesQuotationLineItems.map((item, i) => {
            quotationLineItmeData.push({
              id: i,
              cgstAmount: item.cgstAmount == null ? "0.00" : item.cgstAmount,
              cgstPercent: item.cgstPercent == null ? "0" : item.cgstPercent,
              companyId: item.companyId,
              companyName: item.companyName,
              createdBy: item.createdBy,
              createdDate: item.createdDate,
              discount: limitDecimals(item.discount, 2),
              discountedRate: limitDecimals(item.discountedRate, 3),
              discountType: item.discountType,
              discountName: item.discountName,
              discountAmount: limitDecimals(item.discountAmount, 2),
              hsnCode: item.hsnCode,
              igstAmount: item.igstAmount == null ? "0.00" : item.igstAmount,
              igstPercent: item.igstPercent == null ? "0" : item.igstPercent,
              invoiceBalQty: item.invoiceBalQty,
              itemGroupId: item.itemGroupId,
              itemGroupName: item.itemGroupName,
              itemGroupValue: item.itemGroupValue,
              itemId: item.itemId,
              itemName: item.itemName,
              itemValueId: item.itemValue,
              itemValue: item.itemValue,
              itemCode: item.itemCode,
              make: item.make,
              netAmount: limitDecimals(item.netAmount, 2),
              quantity: limitDecimals(item.quantity, 3),
              quotationId: item.quotationId,
              quotationLineitemId: item.quotationLineitemId,
              rate: limitDecimals(item.rate, 3),
              remarks: item.remarks,
              salesBalQty: item.salesBalQty,
              sgstAmount: item.sgstAmount == null ? "0.00" : item.sgstAmount,
              sgstPercent: item.sgstPercent == null ? "0" : item.sgstPercent,
              totalAmount: limitDecimals(item.totalAmount, 2),
              uom: item.uom,
              updatedBy: item.updatedName,
              isActive: 1,
              taxPercent: limitDecimals(item.itemTaxPercentage, 2),
              currency: "INR",
              updatedOn: item.updatedDate,
              ItemNameData: [],
            });
          });

          quotationLineItmeData.push({
            id: quotationLineItmeData.length + 1,
            cgstAmount: "",
            cgstPercent: "",
            companyId: "",
            companyName: "",
            createdBy: "",
            createdDate: "",
            discount: "",
            discountedRate: "",
            discountType: "",
            discountName: "",
            discountAmount: "",
            hsnCode: "",
            igstAmount: "",
            igstPercent: "",
            invoiceBalQty: "",
            itemGroupId: "",
            itemGroupName: "",
            itemGroupValue: "",
            itemId: "",
            itemName: "",
            itemValueId: "",
            itemValue: "",
            itemCode: "",
            make: "",
            netAmount: "",
            quantity: "",
            quotationId: "",
            quotationLineitemId: "",
            rate: "",
            remarks: "",
            salesBalQty: "",
            sgstAmount: "",
            sgstPercent: "",
            totalAmount: "",
            uom: "",
            updatedBy: "",
            status: "",
            isActive: 1,
            taxPercent: "0.00",
            currency: "INR",
            updatedOn: null,
            ItemNameData: [],
          });
          yield put(getQuotationLineItems(quotationLineItmeData));
        }
      }
      var TotalCGST = response.data.salesQuotationLineItems
        .filter((item) => item.isActive != 0)
        .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
        .reduce((cgstAmount, ind) => parseFloat(cgstAmount) + parseFloat(ind));
      var TotalSGST = response.data.salesQuotationLineItems
        .filter((item) => item.isActive != 0)
        .map((p) => (p.cgstAmount == null ? 0 : p.cgstAmount))
        .reduce((sgstAmount, ind) => parseFloat(sgstAmount) + parseFloat(ind));

      var TotalIGST = response.data.salesQuotationLineItems
        .filter((item) => item.isActive != 0)
        .map((p) => (p.igstAmount == null ? 0 : p.igstAmount))
        .reduce((igstAmount, ind) => parseFloat(igstAmount) + parseFloat(ind));

      var netAmount = response.data.salesQuotationLineItems
        .filter((item) => item.isActive != 0)
        .map((p) => p.netAmount)
        .reduce((netAmount, ind) => parseFloat(netAmount) + parseFloat(ind));
      var taxTotals = Number(TotalCGST) + Number(TotalSGST) + Number(TotalIGST);
      var createddate = response.data.createdDate.split("T");
      var quotationDate = response.data.quotationDate.split("T");
      var quotationExpiryDate = response.data.quotationExpiryDate.split("T");
      var printedOn = response.data.printedOn.split("T");

      var ApproveButton = false;
      var RejectButton = false;
      var UpdateButton = true;
      var EditButton = false;

      if (response.data.approveStatus == true) {
        if (
          response.data.statusId == 1 ||
          response.data.statusId == 17 ||
          response.data.statusId == 18 ||
          response.data.statusId == 19 ||
          response.data.statusId == 20
        ) {
          ApproveButton = true;
          RejectButton = true;
          UpdateButton = true;
          EditButton = true;
        }
      } else if (
        response.data.statusId == 1 ||
        response.data.statusId == 17 ||
        response.data.statusId == 18 ||
        response.data.statusId == 19 ||
        response.data.statusId == 20
      ) {
        UpdateButton = true;
        EditButton = true;
      }

      if (
        response.data.statusId == 4 ||
        response.data.statusId == 3 ||
        response.data.statusId == 5
      ) {
        ApproveButton = false;
        RejectButton = false;
        UpdateButton = false;
        EditButton = false;
      }

      const Header = {
        approvedBy: response.data.approvedBy,
        billingGstNo: response.data.billedToGstin,
        billingContactNo: response.data.billedToContactNumber,
        billingContactPerson: response.data.billedToContactPerson,
        shippingGstNo: response.data.shippedToGstin,
        shippingContactNo: response.data.shippedToContactNumber,
        shippingContactPerson: response.data.shippedToContactPerson,
        shippingToEmail: response.data.shippedToEmail,
        billingToEmail: response.data.billedToEmail,
        approveStatus: response.data.approveStatus,
        branchId: response.data.branchId,
        branchName: response.data.branchName,
        brokeragePercentage: limitDecimals(
          response.data.brokeragePercentage,
          2
        ),
        brokerId: response.data.brokerId,
        companyId: response.data.companyId,
        createdBy: response.data.createdBy,
        createdDate: createddate[0],
        customerId: response.data.customerId,
        customerName: response.data.customerName,
        deliveryDays: response.data.deliveryDays,
        deliveryTerms: response.data.deliveryTerms,
        finYear: response.data.finYear,
        footerNotes: response.data.footerNotes,
        frieghtCharges: limitDecimals(response.data.frieghtCharges, 2),
        internalNote: response.data.internalNote,
        netAmount: response.data.netAmount,
        paymentTerms: response.data.paymentTerms,
        quotationDate: quotationDate[0],
        quotationExpiryDate: quotationExpiryDate[0],
        quotationId: response.data.quotationId,
        quotationNo: response.data.quotationNo,
        shippingAddress: response.data.shippingAddress,
        shippingToAddress: response.data.shippingToAddress,
        billingAddress: response.data.billingAddress,
        billingToAddress: response.data.billingToAddress,
        brokerName: response.data.brokerName,
        statusId: response.data.statusId,
        statusName: response.data.statusName,
        taxAmount: response.data.taxAmount,
        status: response.data.status,
        taxType: response.data.taxType,
        taxName: response.data.taxName,
        termsCondition: response.data.termsCondition,
        totalAmount: parseFloat(taxTotals + netAmount).toFixed(2),
        updatedBy: response.data.updatedBy,
        updatedOn: response.data.updatedDate,
        userId: response.data.userId,
        printedOn: printedOn[0],
        totalIGST: TotalIGST == null ? "0.00" : TotalIGST,
        totalCGST: TotalCGST == null ? "0.00" : TotalCGST,
        totalSGST: TotalSGST == null ? "0.00" : TotalSGST,
        netTotal: parseFloat(netAmount).toFixed(2),
        taxTotal: taxTotals,
        TotalAmount: parseFloat(taxTotals + netAmount).toFixed(2),
        subTotal: parseFloat(netAmount).toFixed(2),
        ApproveButton: ApproveButton,
        RejectButton: RejectButton,
        UpdateButton: UpdateButton,
        EditButton: EditButton,
      };
      yield put(getQuotationHeaders(Header));
    }
    yield put(getQuotationViewByIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* salesQuotationUpdate({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(postAdd, url, data);
    if(response){
      hideSpinner();
      yield put(updateQuotationSucceefull({ response }));
      swal(response.data.message, { icon: "success" });
      history.push("/quotation");
    }
  } catch (error) {
    console.log(error);
  }
}


function* getItemDetailsByItemGroupAndItemIdAndComapnyId({ payload: { url, data,history,rowId } }) {
  const { quoteLineItems,quoteHeader } = yield select((state) => state.QuotationReducer);
  try {
    const response = yield call(getList, url, data);
    if (response) {
      yield put(getItemDetailsByItemGroupAndItemIdAndComapnyIdSuccessfull({ response }));
      if (response) {
        if (quoteLineItems) {
          let LineItemsRecords = [];
          if (quoteLineItems.length !== 0) {
            LineItemsRecords = quoteLineItems.map((item) => {
              if ( rowId === item.id) {
                item.hsnCode = response.hsnCode; 
                item.rate = limitDecimals(response.srRate, 3); 
                item.discountedRate = limitDecimals(response.srRate, 3); 
                item.uom = response.uomCode; 
                item.remarks = "";
                item.itemMake = "";
                item.discountType = "";
                item.discountName = "";
                item.discount = "0.00";
                item.discountAmount = "0.00";
                item.taxPercent = limitDecimals(response.taxPercentage, 2); 
                item.totalAmount = "0.00";
                item.netAmount = "0.00";
                item.quantity = "0.000";
              }
              return item;
            });
            let Calculations = SalesGstCalculations(
              {
                lineItems: LineItemsRecords,
                taxType: quoteHeader.taxType,
              },
              "SALES_REGULAR_FLOW"
            );
            yield put(getQuotationLineItems(LineItemsRecords));
            var Headerdata = {
              ...quoteHeader,
              totalIGST: Calculations.totalIGST,
              totalCGST: Calculations.totalCGST,
              totalSGST: Calculations.totalSGST,
              taxTotal: Calculations.taxTotal,
              netTotal: Calculations.netTotal,
              totalAmount: Calculations.TotalAmount,
            };
            yield put(getQuotationHeaders(Headerdata));
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchQuotationList() {
  yield takeEvery(QUOTATION_LIST, getQuotationList);
  yield takeEvery(QUOTATION_UOM_LIST, getQuotationUomList);
  yield takeEvery(GET_UOM_RELATION_VALUES, getUomRelationValueList);
  yield takeEvery(QUOTATION_ITEM_BY_ITEM_GROUP_ID, getQuotationItemByItemGroup);
  yield takeEvery(GET_ITEM_UOM_RELATION_VALUE, getItemUomRelationByUomCode);
  yield takeEvery(CREATE_QUOTATIONS, setQuotationsData);
  yield takeEvery(GET_QUOTATION_BROKER, getQuotationBrokersData);
  yield takeEvery(QUOTATION_UPDATE, salesQuotationUpdate);
  yield takeEvery(
    QUOTAION_VIEW_BY_ID_IN_QUOTATION,
    getQuotationViewByIdDataInQuotation
  );

yield takeEvery(GET_ITEM_DETAILS_BY_ITEM_GROUP_AND_ITEM_ID_AND_COMPANY_ID, getItemDetailsByItemGroupAndItemIdAndComapnyId);
}
function* QuotationSaga() {
  yield all([fork(watchQuotationList)]);
}

export default QuotationSaga;
