import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TextFieldNormal from "../../../../../components/TextField/TextFieldNormal";
import { Grid, Box, Button, IconButton } from "@mui/material";
import CustomButton from "../../../../../components/Buttons/Buttons";
import NewDatePicker from "../../../../../components/DatePicker/NewDatePicker";
import { Link } from "react-router-dom";
import { ViewByIdExperienceDetails } from "../../../../../store/HRMS/EmployeeDataBase/actions";
import {
  setExperienceDetails,
  downLoadFiles,
  deleteEducationalDetails,
  clearProfileData,
} from "../../../../../store/HRMS/EmployeeDataBase/actions";
import { getTenantId, serverApi } from "../../../../../helpers/Consts";
import panUploadIcon from "../../../../../assets/images/panUploadIcon.png";
import DeleteIcon from "../../../../../assets/images/picEditIcon.png";
import TickIcon from "../../../../../assets/images/fileUploadedTick.png";
import { postUploadFileList } from "../../../../../store/MyProfile/UploadFile/actions";
import swal from "sweetalert";
import ClearIcon from "@mui/icons-material/Clear";

class PreviousExperience extends Component {
  constructor(props) {
    super(props);
    let newDate = new Date();
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      ebId: this.props.getEbId,
      fromDate_month: null,
      fromDate_year: null,
      toDate_month: null,
      toDate_year: null,
      company_name: "",
      designation: "",
      ExperienceDetailsViewById: [],
      date: newDate.getDate(),
      response: [],
      uniqueID: 0,
      fileDataName: "",
      fileUploadId: 0,
      uploadFileData: [],
      WorkerDetailsProgress: [],
      personalInfo: 1,
      generateLetters: 0,
      uploadDocuments: 0,
      onboarding: 0,
      shiftPolicy: 0,
      leavePolicy: 0,
      contactDetails: 0,
      educationalDetails: 0,
      previousExperience: 1,
      workerDetails: 0,
      salaryStructure: 0,
      bankDetails: 0,
      esi: 0,
      pf: 0,
      tableData: [
        {
          id: 1,
          docName: "",
          autoId: null,
          companyId: localStorage.getItem("companyId"),
          companyName: "",
          contact: "",
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          createdDate: "",
          designation: "",
          empId: localStorage.getItem("ebId"),
          fromDate: "",
          isActive: 1,
          project: "",
          toDate: "",
          fromDate_month: null,
          fromDate_year: null,
          toDate_month: null,
          toDate_year: null,
          type: true,
          docId: "",
        },
      ],
    };
  }
  onhandleChange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "CompanyName") {
      this.setState({
        company_name: event,
      });
    }
    if (key === "Designation") {
      this.setState({
        designation: event,
      });
    }
  };
  handleSelect = (value, name, field, id) => {
    if (field === "1") {
      if (name === "month") {
        if (
          this.state.tableData[id].toDate_month !== null &&
          this.state.tableData[id].toDate_year !== null
        ) {
          if (
            this.state.tableData[id].fromDate_year ==
            this.state.tableData[id].toDate_year
          ) {
            if (value >= this.state.tableData[id].toDate_month) {
              swal("From Date Month Should not be Less Than To date");
              this.setState({
                fromDate_month: null,
              });
              this.state.tableData[id].fromDate_month = null;
            } else {
              this.setState({
                fromDate_month: value,
              });
              this.state.tableData[id].fromDate_month = value;
            }
          } else {
            this.setState({
              fromDate_month: value,
            });
            this.state.tableData[id].fromDate_month = value;
          }
        } else {
          this.setState({
            fromDate_month: value,
          });
          this.state.tableData[id].fromDate_month = value;
        }
      }
      if (name === "year") {
        if (
          this.state.tableData[id].toDate_month !== null &&
          this.state.tableData[id].toDate_year !== null
        ) {
          if (
            this.state.tableData[id].fromDate_month ==
            this.state.tableData[id].toDate_month
          ) {
            if (value >= this.state.tableData[id].toDate_year) {
              swal("From Date Year Should not be greater than to Date");
              this.setState({
                fromDate_year: null,
              });
              this.state.tableData[id].fromDate_year = null;
            } else {
              this.setState({
                fromDate_year: value,
              });
              this.state.tableData[id].fromDate_year = value;
            }
          } else if (value > this.state.tableData[id].toDate_year) {
            swal("From Date Year Should not be greater than to Date");
            this.setState({
              fromDate_year: null,
            });
            this.state.tableData[id].fromDate_year = null;
          } else {
            this.setState({
              fromDate_year: value,
            });
            this.state.tableData[id].fromDate_year = value;
          }
        } else {
          this.setState({
            fromDate_year: value,
          });
          this.state.tableData[id].fromDate_year = value;
        }
      }
    }
    if (field === "2") {
      if (name === "month") {
        if (
          this.state.tableData[id].fromDate_month !== null &&
          this.state.tableData[id].fromDate_year !== null
        ) {
          if (
            this.state.tableData[id].fromDate_year ==
            this.state.tableData[id].toDate_year
          ) {
            if (value <= this.state.tableData[id].fromDate_month) {
              swal("To Date Month should be Greater than From Date");
              this.setState({
                toDate_month: null,
              });
              this.state.tableData[id].toDate_month = null;
            } else {
              this.setState({
                toDate_month: value,
              });
              this.state.tableData[id].toDate_month = value;
            }
          } else {
            this.setState({
              toDate_month: value,
            });
            this.state.tableData[id].toDate_month = value;
          }
        } else {
          this.setState({
            toDate_month: value,
          });
          this.state.tableData[id].toDate_month = value;
        }
      }
      if (name === "year") {
        if (
          this.state.tableData[id].fromDate_month !== null &&
          this.state.tableData[id].fromDate_year !== null
        ) {
          if (
            this.state.tableData[id].fromDate_month ==
            this.state.tableData[id].toDate_month
          ) {
            if (value <= this.state.tableData[id].fromDate_year) {
              swal("To Date Year Should be Greater than From date");
              this.setState({
                toDate_year: null,
              });
              this.state.tableData[id].toDate_year = null;
            } else {
              this.setState({
                toDate_year: value,
              });
              this.state.tableData[id].toDate_year = value;
            }
          } else if (value < this.state.tableData[id].fromDate_year) {
            swal("To Date Year Should be Greater than From date");
            this.setState({
              toDate_year: null,
            });
            this.state.tableData[id].toDate_year = null;
          } else if (
            this.state.tableData[id].fromDate_month >
            this.state.tableData[id].toDate_month
          ) {
            if (value <= this.state.tableData[id].fromDate_year) {
              swal("To Date Year Should be Greater than From date");
              this.setState({
                toDate_year: null,
              });
              this.state.tableData[id].toDate_year = null;
            } else {
              this.setState({
                toDate_year: value,
              });
              this.state.tableData[id].toDate_year = value;
            }
          } else {
            this.setState({
              toDate_year: value,
            });
            this.state.tableData[id].toDate_year = value;
          }
        } else {
          this.setState({
            toDate_year: value,
          });
          this.state.tableData[id].toDate_year = value;
        }
      }
    }
  };
  onClickNext = () => {
    const mdata = [];

    this.state.tableData.map((row) => {
      if (row.type == true) {
        if (
          row.companyName &&
          row.designation &&
          row.fromDate_month &&
          row.fromDate_year &&
          row.toDate_month &&
          row.toDate_year
        ) {
          var data = {
            autoId: null,
            project: "",
            companyName: row.companyName,
            designation: row.designation,
            fromDate:
              this.state.date +
              "-" +
              row.fromDate_month +
              "-" +
              row.fromDate_year,
            toDate:
              this.state.date + "-" + row.toDate_month + "-" + row.toDate_year,
            empId: row.empId,
            isActive: 1,
            contact: "",
            companyId: row.companyId,
            createdBy: row.createdBy,
            createdDate: "",
          };
          mdata.push(data);
        }
      }
    });

    if (mdata.length > 0) {
      this.props.setExperienceDetails(
        serverApi.EXPERIENCE_DETAILS,
        mdata,
        this.props.history
      );
    } else {
      swal("Sorry! Please add Previous Experience", { icon: "error" });
      return false;
    }
  };
  handleselectedFile = (id) => (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 43);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", id);
    formData.append("createdBy", this.state.userDit.userId);
    formData.append("fileCompanyId", this.state.companyId);
    this.setState({
      fileName: file.name,
      fileExtension: file.name.split(".").pop(),
    });

    this.setState({
      selectedFile: e.target.files[0],
      selectedFileName: e.target.files[0].name,
    });
    var tableData = this.state.tableData.filter((row) => {
      if (row.autoId == id) {
        row.docName = file.name;
      }
      return row;
    });
    this.setState({ tableData: tableData });
    this.props.postUploadFileList(
      serverApi.UPLOADFILE,
      formData,
      this.props.history
    );
  };
  handleDeleteFile = (id, docId) => (e) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        var tableData = this.state.tableData.filter((item) => {
          if (item.autoId === id) {
            item.docName = "";
          }
          return item;
        });
        this.setState({
          tableData: tableData,
        });
        this.props.deleteEducationalDetails(
          serverApi.DELETE_FILES + docId,
          this.props.history
        );
      } else {
        swal("Your action is canceled!");
      }
    });
  };

  componentWillReceiveProps(props) {
    if (this.props.getEbId !== null) {
      if (props.experienceDetailsViewById.data) {
        if (props.experienceDetailsViewById.data.length !== 0) {
          // let obj = {};

          var i = 1;
          let tableDataList = props.experienceDetailsViewById.data.map(
            (row) => {
              var fdate = row.fromDate.split("-");
              var tdate = row.toDate.split("-");
              var docName = "";
              var docId = "";
              if (row.filesDto.length > 0) {
                row.filesDto.map((file) => {
                  docName = file.displayName;
                  docId = file.fileUploadId;
                });
              }
              var rowdata = {
                id: i,
                docName: docName,
                autoId: row.autoId,
                companyId: row.companyId,
                companyName: row.companyName,
                contact: "",
                createdBy: row.createdBy,
                createdDate: "",
                designation: row.designation,
                empId: row.empId,
                fromDate: row.fromDate,
                isActive: 1,
                project: row.project,
                toDate: row.toDate,
                fromDate_month: fdate[1].toString(),
                fromDate_year: fdate[2].toString(),
                toDate_month: tdate[1].toString(),
                toDate_year: tdate[2].toString(),
                type: false,
                docId: docId,
              };
              i++;
              return rowdata;
            }
          );
          this.setState({
            tableData: tableDataList,
          });
        }
      }
    }
  }
  componentDidMount() {
    if (this.props.getEbId !== null) {
      this.props.ViewByIdExperienceDetails(
        serverApi.EXPERIENCE_DETAILS_VIEW_BY_ID + this.props.getEbId,
        this.props.history
      );
    }
    this.props.clearProfileData(null);
  }
  onhandleCompany = (data) => (event) => {
    const { key, i } = data;
    this.setState({ [key]: event });
    if (data.key === data.i + 1) {
      this.state.tableData[i].companyName = event;
    }
  };
  onhandleDesignation = (data) => (event) => {
    const { key, i } = data;
    this.setState({ [key]: event });
    if (data.key === data.i + 1) {
      this.state.tableData[i].designation = event;
    }
  };
  handleChange = (data) => (event) => {
    this.state.uploadFileData.map((prop) => {
      if (data.prop.fileUploadId === prop.fileUploadId) {
      }
    });
  };

  downLoadFile = (data) => (event) => {
    this.state.uploadFileData.map((prop) => {
      if (data.prop.fileUploadId === prop.fileUploadId) {
        var fileExt = prop.fileDataName;
        var ext = fileExt.split(".");
        var fileName = ext[0];

        fetch(serverApi.DOWNLOAD_FILE + prop.fileUploadId, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-TenantId": getTenantId(window.location.hostname),
            Authorization:
              localStorage.getItem("token_type") +
              localStorage.getItem("access_token"),
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName + "." + ext[1]);
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
            // Clean up and remove the link
            link.parentNode.removeChild(link);
          });
      }
    });
  };

  handleAddRow = () => {
    var tableDataAdd = [];
    this.state.tableData.map((row) => {
      tableDataAdd.push(row);
    });
    const res = {
      id: this.state.tableData.length + 1,
      docName: "",
      autoId: null,
      companyId: localStorage.getItem("companyId"),
      companyName: "",
      contact: "",
      createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
      createdDate: "",
      designation: "",
      empId: localStorage.getItem("ebId"),
      fromDate: "",
      isActive: 1,
      project: "",
      toDate: "",
      fromDate_month: "",
      fromDate_year: "",
      toDate_month: "",
      toDate_year: "",
      type: true,
      docId: "",
    };
    tableDataAdd.push(res);
    this.setState({
      tableData: tableDataAdd,
    });
  };
  onDeletePrevExp = (row) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        var filtabledata = this.state.tableData.filter((data) => {
          if (data.autoId != row.autoId) {
            return data;
          } else {
            this.props.deleteEducationalDetails(
              serverApi.DELETE_PREVIOUS_EXP_DETAILS + data.autoId,
              this.props.history
            );
          }
        });

        this.setState({
          tableData: filtabledata,
        });
      } else {
        swal("Your action is canceled!");
      }
    });
  };

  render() {
    return (
      <>
        <div className="previousExperienceBlock">
          <div className="">
            <h1>Previous Experience</h1>
            <>
              {this.state.tableData.map((data, i) => (
                <div
                  className="consoleFormBlock"
                  style={{ marginBottom: "20px" }}
                >
                  {/* <IconButton
                      onClick={(e) => {
                        this.onDel(data);
                      }}
                      className="closeButtonForInput"
                    ><ClearIcon className="deleteDisplayButton" />
                    </IconButton> */}
                  <IconButton
                    className="closeButtonForInput"
                    onClick={() => this.onDeletePrevExp(data)}
                  >
                    <ClearIcon className="deleteDisplayButton" />
                  </IconButton>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={2}
                      >
                        <Box gridColumn="span 4">
                          <div className="consoleTextFieldBlock">
                            <TextFieldNormal
                              label="Company Name"
                              placeholder="Enter here"
                              value={data.companyName}
                              onChange={this.onhandleCompany({
                                key: data.id,
                                i,
                              })}
                            />
                          </div>
                        </Box>
                        <Box gridColumn="span 4">
                          <div className="consoleTextFieldBlock">
                            <TextFieldNormal
                              label="Designation"
                              placeholder="Work profile"
                              value={data.designation}
                              onChange={this.onhandleDesignation({
                                key: data.id,
                                i,
                              })}
                            />
                          </div>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                      className="previousBlock"
                    >
                      <Box gridColumn="span 4" className="datePicker">
                        <div className="consoleTextFieldBlock">
                          <div className="TextFieldBlock">
                            <label>From Date</label>
                          </div>
                          <NewDatePicker
                            handleSelect={this.handleSelect}
                            field="1"
                            id={i}
                            month={data.fromDate_month}
                            year={data.fromDate_year}
                            placeholder="tese4t"
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 4">
                        <div className="consoleTextFieldBlock">
                          <div className="TextFieldBlock">
                            <label>To Date</label>
                          </div>
                          <NewDatePicker
                            handleSelect={this.handleSelect}
                            id={i}
                            field="2"
                            month={data.toDate_month}
                            year={data.toDate_year}
                          />
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={1}
                    >
                      <Box
                        gridColumn="span 4"
                        style={{ justifyContent: "start" }}
                      >
                        {/* <DocumentUploadNew label="Upload Document" taskUniqueId={this.state.uniqueID} sourceMenu={43} /> */}
                        <Box gridColumn="span 3">
                          <div className="personalDetailsUpload">
                            <div className="ducumentUploadBlock">
                              <div className="documentUploadContent">
                                {data.docName === "" ? (
                                  <span
                                    className={
                                      data.type
                                        ? "btn_upload documentUpload disabled"
                                        : "btn_upload documentUpload "
                                    }
                                  >
                                    <img src={panUploadIcon} alt="" />
                                    <input
                                      type="file"
                                      id="imag"
                                      title=""
                                      disabled={data.type}
                                      className="input-img"
                                      onChange={this.handleselectedFile(
                                        data.autoId
                                      )}
                                    />
                                    Upload Experience Certificate
                                  </span>
                                ) : (
                                  ""
                                )}
                                {data.docName !== "" && (
                                  <div className="uploadedInfo">
                                    <img
                                      src={TickIcon}
                                      alt=""
                                      className="tickIcon"
                                    />
                                    {data.docName}
                                    <Button
                                      onClick={this.handleDeleteFile(
                                        data.autoId,
                                        data.docId
                                      )}
                                      className="deleteButton"
                                    >
                                      <img src={DeleteIcon} alt="" />
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={1}
                    >
                      {this.state.uploadFileData.map((prop) => {
                        return (
                          <div className="quantityDropdownBlock">
                            <CustomButton
                              label={prop.fileDataName}
                              className="greenButton"
                              handleClick={this.downLoadFile({
                                key: prop.fileUploadId,
                                prop,
                              })}
                              type="sumbit"
                            />
                          </div>
                        );
                      })}
                    </Box>
                  </Grid>
                </div>
              ))}
            </>

            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
                <Box
                  gridColumn="span 2"
                  style={{ justifyContent: "start", paddingBottom: "20px" }}
                >
                  <a className="addMoreAction" onClick={this.handleAddRow}>
                    + Add more
                  </a>
                </Box>
              </Box>
            </Grid>
          </div>

          <div className="consoleFormButtonBlock">
            <Link to="/add_employee">
              <CustomButton label={"Cancel"} className="greenBorderButton" />
            </Link>
            {localStorage.getItem("EmpData") === null ? (
              <CustomButton
                label={"Save"}
                className="greenButton"
                handleClick={this.onClickNext}
                type="sumbit"
              />
            ) : (
              <CustomButton
                label={"Update"}
                className="greenButton"
                handleClick={this.onClickNext}
                type="sumbit"
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { experienceDetails, experienceDetailsViewById, getEbId } =
    state.EmployeeReducer;
  return { experienceDetails, experienceDetailsViewById, getEbId };
};

export default withRouter(
  connect(mapStatetoProps, {
    setExperienceDetails,
    ViewByIdExperienceDetails,
    downLoadFiles,
    postUploadFileList,
    clearProfileData,
    deleteEducationalDetails,
  })(PreviousExperience)
);
