//import { Switch } from "react-router-dom";

import {
  ITEM_LISTS,
  ITEM_LISTS_SUCCESSFULL,
  CREATE_ITEM_MASTER,
  CREATE_ITEM_MASTER_SUCCESSFULL,
  UPDATE_ITEM_MASTER,
  UPDATE_ITEM_MASTER_SUCCESSFULL,
  ITEM_CODE_BY_ITEM_GROUP,
  ITEM_CODE_BY_ITEM_GROUP_SUCCESSFULL,
  INDENT_TYPE_LIST_MASTERS,
  INDENT_TYPE_LIST_MASTERS_SUCCESSFULL,
  CREATE_LEAVE_TYPE_MASTER,
  CREATE_LEAVE_TYPE_MASTER_SUCCESSFULL,
  GET_ITEM_CODE_BY_ITEM_GROUP,
  GET_ITEM_CODE_BY_ITEM_GROUP_SUCCESSFULL,
  GET_UOM_IN_ITEM_MASTER,
  GET_UOM_IN_ITEM_MASTER_SUCCESSFULLY,
  GET_ITEM_MASTER_VIEW_BY_ID,
  GET_ITEM_MASTER_VIEW_BY_ID_SUCCESSFULL
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  Item_List: [],
  Create_Item_Master: [],
  Update_Item_Master: [],
  Item_Code_By_Item_Group : [],
  error_msg: null,
  Indent_List_Master: [],
  leaveTypeMaster: [],
  itemByItemCodeList:[],
  itemMasterUomList:[],
  itemMasterViewByIdList: []
};

const ItemListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ITEM_LISTS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case ITEM_LISTS_SUCCESSFULL:
      state = {
        ...state,
        Item_List: action.payload.response,
        loading: false,
      };
      break;
    case CREATE_ITEM_MASTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_ITEM_MASTER_SUCCESSFULL:
      state = {
        ...state,
        Create_Item_Master: action.payload.response,
        loading: true,
      };
      break;
      case CREATE_LEAVE_TYPE_MASTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_LEAVE_TYPE_MASTER_SUCCESSFULL:
      state = {
        ...state,
        leaveTypeMaster: action.payload.response,
        loading: true,
      };
      break;
    case UPDATE_ITEM_MASTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_ITEM_MASTER_SUCCESSFULL:
      state = {
        ...state,
        Update_Item_Master: action.payload.response,
        loading: true,
      };
      break;
    case ITEM_CODE_BY_ITEM_GROUP:
      state = {
        ...state,
        loading: true,
      };
      break;

    case ITEM_CODE_BY_ITEM_GROUP_SUCCESSFULL:
      state = {
        ...state,
        Item_Code_By_Item_Group : action.payload.response,
        loading: false,
      };
      break;
    case INDENT_TYPE_LIST_MASTERS: 
      state = {
        ...state,
        loading: true
      }  
      break;
    case INDENT_TYPE_LIST_MASTERS_SUCCESSFULL: 
      state = {
        ...state,
        Indent_List_Master: action.payload.response,
        loading: false
      }  
      break;
      case GET_ITEM_CODE_BY_ITEM_GROUP: 
      state = {
        ...state,
        loading: true
      }  
      break;
    case GET_ITEM_CODE_BY_ITEM_GROUP_SUCCESSFULL: 
      state = {
        ...state,
        itemByItemCodeList: action.payload.response,
        loading: false
      }  
      break;
      case GET_UOM_IN_ITEM_MASTER: 
        state = {
          ...state,
          loading: true
        }  
      break;
      case GET_UOM_IN_ITEM_MASTER_SUCCESSFULLY: 
        state = {
          ...state,
          itemMasterUomList: action.payload.response,
          loading: false
        }  
      break;
      case GET_ITEM_MASTER_VIEW_BY_ID: 
        state = {
          ...state,
          loading: true
        }  
      break;
      case GET_ITEM_MASTER_VIEW_BY_ID_SUCCESSFULL: 
        state = {
          ...state,
          itemMasterViewByIdList: action.payload.response,
          loading: false
        }  
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default ItemListReducer;
