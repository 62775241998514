import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";
import { serverApi } from "../../../helpers/Consts";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  create_jute_indent,
  update_jute_indent,
  jute_indent_by_id,
} from "../../../store/Jute/JuteIndent/actions";
import { Grid, Link, Step, StepLabel, Stepper } from "@mui/material";
import FillDetails from "./FillDetails";
import JuteIndentLineItems from "./JuteIndentLineItems";
import JuteIndentPreview from "./JuteIndentPreview";
import { isEmpty } from "../../../Utilities/helper";
import { useHistory, useLocation } from "react-router-dom";
function CreateJuteIndent(props) {
  const [currentStep, setCurrentStep] = useState(1);
  const [steps] = useState([
    {
      title: "Fill Details",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Add Items",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Preview",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
  ]);

  const [HeaderData, setHeaderData] = useState({});
  const [LineItems, setLineItems] = useState([]);
  const dispatch = useDispatch();
  let history = useHistory();
  const [locationState] = useState(useLocation());
  
  const { JuteIndentHeader, JuteindentLineItems } = useSelector(
    (state) => state.JuteIndentReducer
  );
  
  const onClickNext = () => {
    if (currentStep === 1) {
      if(isEmpty(HeaderData.indentDate ) ){
        swal("Please Select the Date");
      } else if ( isEmpty(HeaderData.unitConversion )) {
        swal("Please Select the Unit Conversion");
      } else if (isEmpty(HeaderData.mukam)) {
        swal("Please Select the Mukam");
      } else if (isEmpty(HeaderData.branchId)) {
        swal("Please Select the Branch");
      }else if (isEmpty(HeaderData.vehicleQuantity)){
        swal("Please Enter Indent Quantity");
      } else {
        setCurrentStep(currentStep + 1);
      }
    } else {
      let ValidateJuteGovtFields = false;
      let FilteredDatas = JuteindentLineItems.filter(
        (item) => item.isActive !== 0
      );
      if (FilteredDatas.length === 1) {
        FilteredDatas.map((item, index) => {
          if (isEmpty(item.itemId)) {
            swal("Please select the Jute Type--1");
            return false;
          } else if (isEmpty(item.qualityCode)) {
            swal("Please select Quality");
            return false;
          } else if (isEmpty(item.noOfBales)) {
            swal("Please Enter the Quantity %");
            return false;
          } else if (isEmpty(item.noOfBales)) {
            swal("Please Enter the Quantity %");
            return false;
          } else {
            ValidateJuteGovtFields = true;
          }
        });
      } else {
        for (let i = 0; i < FilteredDatas.length - 1; i++) {
          console.log(FilteredDatas[i], i);
          if (isEmpty(FilteredDatas[i].itemId)) {
            swal("Please select the Jute Type--2");
            return false;
          } else if (isEmpty(FilteredDatas[i].qualityCode)) {
            swal("Please select Quality");
            return false;
          } else if (isEmpty(FilteredDatas[i].noOfBales)) {
            swal("Please Enter the Quantity %");
            return false;
          } else {
            ValidateJuteGovtFields = true;
          }
        }

        const totalPercentage = FilteredDatas.reduce((acc, item) => acc + Number(item.noOfBales), 0);

        if (totalPercentage > 100) {
          swal("", "You can not exceed 100 percent of total quantity", "warning");
          return false;
        } else {
          ValidateJuteGovtFields = true;
        }

      }
      if (ValidateJuteGovtFields === true) {
        setCurrentStep(currentStep + 1);
      }
    }
  };


  const onClickBefore = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    } else {
      history.push("/jute_indent");
    }
  };
  const QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;
    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };
  const onClickCreate = () => {
   var indentDate= new Date(HeaderData.indentDate).getTime()
    var Lineitems = [];
    if (JuteindentLineItems) {
      if (JuteindentLineItems.length !== 0) {
        var Filtered = JuteindentLineItems.filter(
          (item) => item.isActive !== 0 && !isEmpty(item.itemId)
        );
        if (Filtered.length !== 0) {
          Filtered.map((item, index) => {
            Lineitems.push({
              itemId: item.itemId,
              dtlItemDesc: item.dtlItemDesc,
              itemGroupId: item.itemGroupId,
              qualityCode: item.qualityCode,
              deptId: "",
              stock: item.stock,
              indentQuantity: item.indentQuantity,
              unitId: item.unitId,
              noOfBales: item.noOfBales,
              finYear: localStorage.getItem("acadamicYear"),
              isActive: 1
            });
          });
        }
      }
    }
 
    const data = {
      indentHeader: {
        type: "j",
        mukam: HeaderData.mukam,
        vehicleTypeId: HeaderData.vehicleTypeId,
        vehicleQuantity: HeaderData.vehicleQuantity,
        submitter: JSON.parse(localStorage.getItem("authUser")).userId,
        indentDate: indentDate,
        finnacialYear: localStorage.getItem("acadamicYear"),
        unitConversion: HeaderData.unitConversion,
        companyId: localStorage.getItem("companyId"),
        branchId: HeaderData.branchId,
      },
      indentItems: Lineitems,
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
    };
    dispatch(create_jute_indent(serverApi.CREATE_JUTE_INDENT, data, props.history))
  };

  const onClickUpdate = (status) => {
    var indentDate= new Date(HeaderData.indentDate).getTime()
    var Lineitems = [];
    console.log("JuteindentLineItems", JuteindentLineItems)
    if (JuteindentLineItems) {
      if (JuteindentLineItems.length !== 0) {
        var Filtered = JuteindentLineItems.filter(
          (item) => !isEmpty(item.itemId) && (item.isActive === 1 || !isEmpty(item.IndentLineItemId))
        );
        console.log("Filtered new", Filtered)

          Filtered.map((item) => {
            Lineitems.push({
              id: item.IndentLineItemId,
              indentHeaderId: item.indentHeaderId,
              itemId: item.itemId,
              dtlItemDesc: item.dtlItemDesc,
              itemGroupId: item.itemGroupId,
              qualityCode: item.qualityCode,
              deptId: "",
              stock: item.stock,
              indentQuantity: item.indentQuantity,
              unitId: item.unitId,
              noOfBales: item.noOfBales,
              finYear: localStorage.getItem("acadamicYear"),
              isActive: item.isActive
            });
          });
      }
    }
    const data = {
      indentHeader: {
        branchId: HeaderData.branchId,
        companyId:localStorage.getItem("companyId"),
        finnacialYear: localStorage.getItem("acadamicYear"),
        hdrId: HeaderData.hdrId,
        id: HeaderData.id,
        indentDate: indentDate,
        modBy: JSON.parse(localStorage.getItem("authUser")).userId,
        mukam: HeaderData.mukam,
        status: status,
        type: "J",
        // unitConversion: HeaderData.unitConversion,
        vehicleQuantity: HeaderData.vehicleQuantity,
        vehicleTypeId: HeaderData.vehicleTypeId,

     
      },
      indentItems: Lineitems,
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
    };
    dispatch(update_jute_indent(serverApi.UPDATE_JUTE_INDENT, data, props.history))
  };
  useEffect(() => {
    setHeaderData(JuteIndentHeader)
    setLineItems(JuteindentLineItems)
  }, [JuteIndentHeader,JuteindentLineItems]);
  useEffect(()=>{
    if (locationState.state !== undefined) {
      if (locationState.state.rowData !== undefined) {
        setCurrentStep(3);
        dispatch(jute_indent_by_id(serverApi.JUTE_INDENT_VIEWBY_ID+JSON.parse(localStorage.getItem("authUser")).userId+"/company/"+localStorage.getItem("companyId")+"/year/"+localStorage.getItem("acadamicYear")+'?indentId='+locationState.state.rowData.indentNo,history))
      }
    }
  },[])
  useEffect(() => {
    // if (props.indentHeaderId) {
    //   if (props.indentHeaderId.data) {
    //     setIndentHeader((prev) => ({
    //       ...prev,
    //       id: props.indentHeaderId.data.id,
    //       status: props.indentHeaderId.data.status,
    //     }));
    //     if (
    //       props.indentHeaderId.data.approveStatus === true &&
    //       props.indentHeaderId.data.status === 1
    //     ) {
    //       setIndentHeader((prev) => ({
    //         ...prev,
    //         ApproveButton: true,
    //         RejectButton: true,
    //         UpdateButton: true,
    //         editButton: true,
    //       }));
    //     } else if (
    //       props.indentHeaderId.data.approveStatus === true &&
    //       props.indentHeaderId.data.status === 3
    //     ) {
    //       setIndentHeader((prev) => ({
    //         ...prev,
    //         ApproveButton: true,
    //         RejectButton: true,
    //         UpdateButton: true,
    //         editButton: true,
    //       }));
    //     } else if (
    //       props.indentHeaderId.data.approveStatus === true &&
    //       props.indentHeaderId.data.status === 4
    //     ) {
    //       setIndentHeader((prev) => ({
    //         ...prev,
    //         ApproveButton: true,
    //         RejectButton: true,
    //         UpdateButton: true,
    //         editButton: true,
    //       }));
    //     }
    //   }
    // }
  }, []);
  const handleCurrentStep = (data) => {
    setCurrentStep(data);
  };
  return (
    <div className="createQuotationContainer">
      <Grid className="indentContainerBlock">
        <Grid sx={12} className="indentContainerMainBlock">
          <div className="iconsBlock">
            <Stepper alternativeLabel activeStep={currentStep}>
              {steps.map((prop) => {
                <Step>
                  <StepLabel
                    StepIconComponent={(Iconprops) => {
                      return QontoStepIcon(
                        Iconprops,
                        prop.icon,
                        prop.activeIcon,
                        prop.title
                      );
                    }}
                  ></StepLabel>
                </Step>;
              })}
            </Stepper>
          </div>
          {currentStep === 1 && (
            <>
              <FillDetails />
              <div className="consoleFormButtonBlock">
                <Link>
                  <CustomButton
                    label={"Cancel"}
                    className="greenBorderButton"
                    handleClick={onClickBefore}
                  />
                </Link>
                <CustomButton
                  label={"Next"}
                  className="greenButton"
                  handleClick={onClickNext}
                  type="submit"
                />
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <JuteIndentLineItems />
              <div className="consoleFormButtonBlock">
                <Link>
                  <CustomButton
                    label={"Cancel"}
                    className="greenBorderButton"
                    handleClick={onClickBefore}
                  />
                </Link>
                <CustomButton
                  label={"Next"}
                  className="greenButton"
                  handleClick={onClickNext}
                  type="submit"
                />
              </div>
            </>
          )}
          {currentStep === 3 && (
            <>
              <JuteIndentPreview
                handleCurrentStep={handleCurrentStep}
              />
              <div className="consoleFormButtonBlock">
                <>
                 {locationState.state === undefined && ( <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={onClickBefore}
                    />
                  </Link>)}
                  {locationState.state === undefined && (
                    <CustomButton
                      label={"Create"}
                      className="greenButton"
                      handleClick={onClickCreate}
                      type="sumbit"
                    />
                  )}
                  {locationState.state !== undefined &&
                    HeaderData.UpdateButton && (
                      <CustomButton
                        label={"Update"}
                        className="greenButton"
                        handleClick={() => onClickUpdate("")}
                        type="sumbit"
                      />
                    )}
                  {locationState.state !== undefined &&
                    HeaderData.ApproveButton && (
                      <CustomButton
                        label={"Approve"}
                        className="approved"
                        handleClick={() => onClickUpdate(HeaderData.status)}
                        type="sumbit"
                      />
                    )}
                  {locationState.state !== undefined &&
                    HeaderData.RejectButton && (
                      <CustomButton
                        label={"Reject"}
                        className="rejected"
                        handleClick={() => onClickUpdate(4)}
                        type="sumbit"
                      />
                    )}
                </>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}


export default CreateJuteIndent
