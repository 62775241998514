import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import { getTaxMasterList } from "../../../store/Master/TaxMaster/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId:localStorage.getItem('companyId'),
      taxListData: [], // To displaying Tax list data
      taxListColumns: [], // to displaying Tax list Columns
      taxname:"",
      taxper:"",
    };
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: this.state.companyId,
      taxname:"",
      taxper:"",
    };
    this.props.getTaxMasterList(serverApi.GET_ALL_TAXMASTERLIST, data, this.props.history); // calling Tax master list API
  }
  componentWillReceiveProps(props) {
    if (props.Tax_master_List) {
     
      
      this.setState({
        taxListData: props.Tax_master_List.data, // Updating taxlist data
        taxListColumns: props.Tax_master_List.column, // updating TaxList Columns data
      });
    }
  }
  handleSearch = (data) => {
    const searchData = {
      cipher: this.state.userDit.cipher,
      companyId: this.state.companyId,
      taxName:data.taxname,
      taxPercentage:data.taxper,
    };
    this.props.getTaxMasterList(serverApi.GET_ALL_TAXMASTERLIST, searchData, this.props.history); // calling Tax master list API
  };
  render() {
    const mdata1 = this.props.Tax_master_List.data;
    const mColumn = this.props.Tax_master_List.column
    return (
      <>
        <Gridwithcustomview
          mdata={mdata1}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={false}
          actions={true}
          url="/create_tax_master"
          filter_form={"TaxMasterFilter"}
          handleSearch={this.handleSearch}
        />
      </>
    );
  }
}
const Columns = [
  {
    Header: "Tax Name",
    accessor: "taxName",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Tax Percentage",
    accessor: "taxPercentage",
    hidden: false,
    csvExport: true,
  },
]
const mapStatetoProps = (state) => {
  const {Tax_master_List } = state.Tax_master_List // Fetching list from tax master list
  return { Tax_master_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getTaxMasterList,
  })(Index)
);
