import {PAYROLL_DATA,PAYROLL_DATA_SUCCESSFULL, ADD_ATTENDENCE, ADD_ATTENDENCE_SUCCESSFULL, PAYROLL_CREATE, PAYROLE_CREATE_SUCCESS} from './actionType'

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
   payRoll_data: [],
   addAttendence: [],
   createPayRollData:"",
   payrolldata_success:false,
  };
  const PayRollDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAYROLL_DATA:
            state = {
              ...state,
              loading: true,
            };
            break;
          case PAYROLL_DATA_SUCCESSFULL:
            state = {
              ...state,
              payRoll_data: action.payload.response,
              loading: true,
            };
            break;
          case ADD_ATTENDENCE: 
            state = {
              ...state,
              loading: true
            }  
           break;
          case ADD_ATTENDENCE_SUCCESSFULL: 
          state = {
            ...state,
            addAttendence: action.payload.response
          }  
          break;
          // case CREATE_PAY_ROLL_DATA: 
          //   state = {
          //     ...state,
          //     loading: true
          //   }  
          //  break;
          // case CREATE_PAY_ROLL_DATA_SUCCESSFULL: 
          // state = {
          //   ...state,
          //   createPayRollData: action.payload.response,
          //   payrolldata_success:true,
          // }  
          // break;
          case PAYROLL_CREATE:
            state = {
              ...state,
              loading: true
            }
            break;
          case PAYROLE_CREATE_SUCCESS:
            state = {
              ...state,
              loading:false,
              payrolldata_success:true,
            }
            break;
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default PayRollDataReducer;