import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
// import DynamicSelect from "../Dropdown/DynamicSelect";
import DynamicSelect from "../Dropdown/Master/DynamicSelectMasters";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DesktopDatePicker from "@mui/lab/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { serverApi } from "../../helpers/Consts";
import { getCategoryList } from "../../store/HRMS/EmployeeDataBase/actions";
import TextFieldNormal from "../../components/TextField/TextFieldNormal";
import {
  getDepartmentListData,
  getDesignation,
} from "../../store/HRMS/EmployeeDataBase/actions";
import {
  getSpell,
  MarkAttendance,
} from "../../store/HRMS/AttendanceCalender/actions";
class AttendanceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      fromDate: moment().day(-7).format("YYYY-MM-DD").toString(),
      toDate: moment().format("YYYY-MM-DD").toString(),
      fromDateNew: "",
      toDateNew: "",
      emp_code: "",
      attendence_type: "",
      Designation: [],
      Department_List: [],
      spell: [],
      CategoryList: [],
      Category: "",
      department_id: 0,
      designation_id: 0,
      spell_name: "",
      Category_Id: 0,
    };
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  handleSelectDate = (e, name) => {
    if (name === "FromDate") {
      let date = moment(e).format();
      let formattedFromDate = date.split("T", 1).toString();
      this.setState({
        fromDate: formattedFromDate,
      });
    }
    if (name === "ToDate") {
      let date = moment(e).format();
      let formattedToDate = date.split("T", 1).toString();
      this.setState({
        toDate: formattedToDate,
      });
    }
  };
  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "Spell") {
      this.setState({
        spell_name: selectedValue,
      });
    }
    if (name === "Department") {
      this.setState({
        department_id: selectedValue,
      });
    }
    if (name === "Designation") {
      this.setState({
        designation_id: selectedValue,
      });
    }
    if (name === "Type") {
      this.setState({
        attendence_type: selectedValue,
      });
    }
    if (name === "Category") {
      this.setState({
        Category: selectedName,
        Category_Id: selectedValue,
      });
    }
  };
  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "EmpCode") {
      this.setState({
        emp_code: event,
      });
    }
  };
  handleSubmit = () => {
    var startingDate = moment(this.state.fromDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    var toDate = moment(this.state.toDate, "YYYY-MM-DD", true).format(
      "DD-MM-YYYY"
    );
    const data = {
      cataId: this.state.Category_Id,
      companyId: this.state.companyId,
      designationId: this.state.designation_id,
      ebId: "",
      ebNo: this.state.emp_code,
      lastIndex: 0,
      spell: this.state.spell_name,
      startIndex: 1,
      startingDate: startingDate,
      subDeptId: this.state.department_id,
      toDate: toDate,
      userId: this.state.userDit.userId,
      workType: this.state.attendence_type,
    };
    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };
  componentWillReceiveProps(props) {
    if (props.employeeList) {
      this.setState({
        employeeListData: props.employeeList.data,
        // employeeListColumn: props.employeeList.column,
      });
    }
  }
  componentWillReceiveProps(props) {
    if (props.designation) {
      this.setState({
        Designation: props.designation.data,
      });
    }
    if (props.departmentList) {
      this.setState({
        Department_List: props.departmentList.data,
      });
    }
    if (props.spell) {
      this.setState({
        spell: props.spell,
      });
    }
    if (props.categoryList) {
      this.setState({
        CategoryList: props.categoryList.data,
      });
    }
  }
  componentDidMount() {
    this.props.getSpell(
      serverApi.SPELL + this.state.companyId + "/" + this.state.userDit.cipher,
      this.props.history
    );
    this.props.getDepartmentListData(
      serverApi.DEPARTMENT_LIST + this.state.companyId,
      this.props.history
    );
    this.props.getDesignation(
      serverApi.DESIGNATION + this.state.companyId + "/0",
      this.props.history
    );
    this.props.getCategoryList(
      serverApi.CATEGORY_LIST + this.state.companyId,
      this.props.history
    );
  }
  render() {
    return (
      <>
        <Box>
          <Button onClick={this.handleClickOpen}>
            <Tooltip title="Filter">
              <FilterListOutlinedIcon />
            </Tooltip>
          </Button>
          <Dialog
            open={this.state.open}
            PaperProps={{
              sx: {
                position: " fixed",
                top: 0,
                right: 0,
                margin: 0,
                bottom: 0,
                maxHeight: "100vh",
              },
            }}
            keepMounted
            onClose={this.handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <Grid spacing={0}>
              <div className="consoleTextFieldBlock">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div className="filterBlockHeader">
                    <FilterListOutlinedIcon />
                    <span>Filter</span>
                  </div>
                </div>
              </div>

              <div className="hrmsFilter">
                <Grid container spacing={1} className="filterBlockContainer">
                  <Box gridColumn="span 5">
                    <div
                      className="filterDateBlock"
                      style={{
                        padding: "8px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="consoleTextFieldBlock"
                        style={{ width: "272px" }}
                      >
                        <div>
                          <div className="TextFieldBlock">
                            <label>From Date</label>
                          </div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              inputFormat="dd-MM-yyyy"
                              name="dateOne"
                              value={this.state.fromDate}
                              fullWidth
                              onChange={(e) =>
                                this.handleSelectDate(e, "FromDate")
                              }
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="fromDatePicker"
                                >
                                  <input ref={inputRef} {...inputProps} />
                                  {InputProps?.endAdornment}
                                </Box>
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div
                        className="consoleTextFieldBlock"
                        style={{ width: "272px" }}
                      >
                        <div>
                          <div className="TextFieldBlock">
                            <label>To Date</label>
                          </div>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                              inputFormat="dd-MM-yyyy"
                              name="dateOne"
                              value={this.state.toDate}
                              fullWidth
                              onChange={(e) =>
                                this.handleSelectDate(e, "ToDate")
                              }
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="toDatePicker"
                                >
                                  <input ref={inputRef} {...inputProps} />
                                  {InputProps?.endAdornment}
                                </Box>
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                  </Box>

                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={
                          this.state.Designation
                            ? this.state.Designation.map((item) => ({
                                label: item.label,
                                value: item.value,
                                name: item.name,
                              }))
                            : ""
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectChange}
                        selected={this.state.designation_id}
                        name="Designation"
                        label="Designation"
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={
                          this.state.Department_List
                            ? this.state.Department_List.map((item) => ({
                                label: item.label,
                                value: item.value,
                                name: item.name,
                              }))
                            : ""
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectChange}
                        selected={this.state.department_id}
                        name="Department"
                        label="Department"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={
                          this.state.CategoryList
                            ? this.state.CategoryList.map((item) => ({
                                label: item.label,
                                value: item.value,
                                name: item.name,
                              }))
                            : ""
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectChange}
                        selected={this.state.Category}
                        name="Category"
                        label="Category"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={attendence_type}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectChange}
                        selected={this.state.attendence_type}
                        name="Type"
                        label="Attendance Type"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Employee Code"
                        value={this.state.emp_code}
                        onChange={this.onhandlechange("EmpCode")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={
                          this.state.spell
                            ? this.state.spell.map((item) => ({
                                label: item.label,
                                value: item.value,
                                name: item.name,
                              }))
                            : ""
                        }
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handleSelectChange}
                        selected={this.state.spell_name}
                        name="Spell"
                        label="Spell"
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Search"}
                  className="greenButton"
                  handleClick={this.handleSubmit}
                  type="sumbit"
                />
              </div>
            </Grid>
          </Dialog>
        </Box>
      </>
    );
  }
}
const attendence_type = [
  {
    value: "",
    label: "Select",
    name: "Select",
  },
  {
    value: "R",
    label: "Regular",
    name: "Regular",
  },
  {
    value: "o",
    label: "Over time",
    name: "Over time",
  },
  {
    value: "C",
    label: "Cash",
    name: "Cash",
  },
];
const mapStatetoProps = (state) => {
  const { spell } = state.AttendanceReducer;
  const { departmentList, designation, categoryList } = state.EmployeeReducer;
  return { departmentList, spell, designation, categoryList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSpell,
    getDepartmentListData,
    getDesignation,
    MarkAttendance,
    getCategoryList,
  })(AttendanceList)
);
