import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {HOLIDAY_MASTER_LIST, HOLIDAY_DATA_ADD, DELETE_HOLIDAY, HOLIDAY_DATA_UPDATE, UPDATE_HOLIDAY_LIST_FOR_EMPLOYEES} from './actionTypes';
import {HolidayMasterListSuccessfull, holidayDataAddSuccessfull,HolidayMasterList,
    deleteHolidayListSuccessfull,holidayDataUpdateSuccessfull,UpdateholidayListForEmployeesSuccessfull} from './actions';
import {getList,getListurl} from '../../../helpers/Server_Helper';
import { serverApi } from "../../../helpers/Consts";
import swal from "sweetalert";

function* getHolidayMasterList({payload:{url,data,history}}) {
    try{
        const response = yield call(getList,url,data);
        yield put(HolidayMasterListSuccessfull({response}))
    }catch(error){
        console.log(error)
    }
}
function* holidayAdding({payload:{url,data,history}}) {
    try{
        const response = yield call(getList,url,data);
        // if(response){
        //     const data = {
        //         cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
        //         companyId: localStorage.getItem("companyId"),
        //       };
        //       let url = serverApi.GET_ALL_HOLIDAY_MASTER_LIST;
        //       yield put(
        //         HolidayMasterList(
        //             url,
        //         data,
        //         history
        //       ))
        //     swal(response.data.message, { icon: "success" })
        // }
        yield put(holidayDataAddSuccessfull({response}))
        swal(response.message, { icon: "success" })
        history.push('/Holidays_List')
    }catch(error){
        console.log(error)
    }
}
function* holidayDeleting({payload:{url,history}}) {
    try{
        const response = yield call(getListurl,url);
        if(response){
            const data = {
                cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
                companyId: localStorage.getItem("companyId"),
              };
              let Url = serverApi.GET_ALL_HOLIDAY_MASTER_LIST;
              yield put(
                HolidayMasterList(
                    Url,
                data,
                history
              ))
        }
        yield put(deleteHolidayListSuccessfull({response}))
        swal(response.data.message,{icon: "success"})
    }catch(error){
        console.log(error)
    }
}
function* holidayUpdating({payload:{url,data,history}}) {
    try{
        const response = yield call(getList,url,data);
        yield put(holidayDataUpdateSuccessfull({response}))
        swal(response.message, { icon: "success" })
        history.push('/Holidays_List')
    }catch(error){
        console.log(error)
    }
}
function* getUpdateHolidayListForEmployees({payload:{url,history}}) {
    try{
        const response = yield call(getListurl,url);
        yield put(UpdateholidayListForEmployeesSuccessfull({response}))
        swal(response.data.message, { icon: "success" })
    }catch(error){
        console.log(error)
    }
}
export function* watchHolidayMasterList () {
    yield takeEvery(HOLIDAY_MASTER_LIST, getHolidayMasterList)
    yield takeEvery(HOLIDAY_DATA_ADD, holidayAdding)
    yield takeEvery(DELETE_HOLIDAY,holidayDeleting)
    yield takeEvery(HOLIDAY_DATA_UPDATE,holidayUpdating)
    yield takeEvery(UPDATE_HOLIDAY_LIST_FOR_EMPLOYEES,getUpdateHolidayListForEmployees)
}

function* HolidayListSaga() {
    yield all([
        fork(watchHolidayMasterList),
    ])
}


export default HolidayListSaga;