import React, { Component } from "react";
import { Grid, Step, Stepper, StepLabel } from "@mui/material";
import Link from "@mui/material/Link";
import swal from "sweetalert";
import CustomButton from "../../../components/Buttons/Buttons";
import MaterialRequestAddItems from "../MaterialRequest/MaterialRequestAddItems";
import MaterialRequestFillDetails from "../MaterialRequest/MaterialRequestFillDetails";
import MaterialRequestPreview from "../MaterialRequest/MaterialRequestPreview";
import {
  addMaterialRequest,
  storeIssueLineItemWise,
  getStoreIssueViewById,
  storeIssueHeaderData,
} from "../../../store/StoreModule/MaterialRequest/actions";
import { serverApi } from "../../../helpers/Consts";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { LineItemsValidtions } from "../../../Utilities/LineItemsValidtions";

class MaterialRequestCreate extends Component {
  constructor() {
    super();
    this.state = {
      steps: [
        {
          title: "Fill Details",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Add Items",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Preview",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
          activeIcon:
            "http://vowerp.com/MailImages/assets/indentDisabled03.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
      ],
      currentStep: 1,
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      statusName: "",
      locationState: "",
      header: {
        date: null,
        expense_type: 0,
        branchId: 0,
        customer_type: 0,
        project_type: 0,
        internalNote: "",
        expense_name: "",
      },
    };
  }

  onClickNext = () => {
    const { currentStep } = this.state;
    if (currentStep === 1) {
      let data = this.props.storeHeaderDataList;

      if (data.date === null) {
        swal("Please Select Issue Date");
        return false;
      }
      if (data.expense_type === 0) {
        swal("Please Select Expense Type");
        return false;
      }

      if (data.branchId === 0) {
        swal("Please Select Branch");
        return false;
      }
      if (data.project_type === 0) {
        swal("Please Select Project");
        return false;
      }

      this.setState({
        currentStep: currentStep + 1,
      });
    } else {
      if (this.props.storeIssueLineItem) {
        let validObj = false;
        let lastRow =
          this.props.storeIssueLineItem[
            this.props.storeIssueLineItem.length - 1
          ];
        // var storeLineItem = this.props.storeIssueLineItem;
        let storeLineItem = this.props.storeIssueLineItem.filter(
          (item) => item.isActive !== 0
        );

        storeLineItem.map((item) => {
          const validateFields = [
            { field: item.deptId, msg: "Department", type: 2 },
            { field: item.itemGroupId, msg: "Item Group", type: 2 },
            { field: item.itemId, msg: "Item", type: 2 },
            { field: item.srNo, msg: "SR No.", type: 2 },
            { field: item.quantity, msg: "Quantity", type: 1 },
            { field: item.costfactor, msg: "Cost Factor", type: 1 },
          ];

          if (storeLineItem.length === 1) {
            validObj = LineItemsValidtions(validateFields);
          } else {
            if (item !== lastRow) {
              validObj = LineItemsValidtions(validateFields);
            }
          }
        });

        if (validObj) {
          if (validObj.type === 1) {
            swal(`Please Enter ${validObj.message}`);
          } else {
            swal(`Please Select ${validObj.message}`);
          }
        } else {
          this.setState({
            currentStep: currentStep + 1,
          });
        }
      }
    }
  };

  onClickBefore = () => {
    const { currentStep } = this.state;

    if (currentStep > 1) {
      this.setState({
        currentStep: currentStep - 1,
      });
    } else {
      let storeHeaderDataList = {
        date: null,
        expense_type: 0,
        branchId: 0,
        customer_type: 0,
        project_type: 0,
        internalNote: "",
        expense_name: "",
        customer_name: "",
        project_name: "",
      };

      this.props.storeIssueHeaderData(storeHeaderDataList);

      this.props.match.path === "/create_material_request"
        ? this.props.history.push("/material_request")
        : this.props.history.push("/store_issue");
    }
  };
  onclickOk() {
    this.setState({
      confirm: false,
    });
  }
  handleCurrentStep = (val) => {
    this.setState({
      currentStep: val,
    });
  };
  QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;

    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "expenseType") {
      this.setState({
        expense_type: selectedValue,
        expense_name: selectedName,
      });
    }
    if (name === "branch") {
      this.setState({
        branch_type: selectedValue,
        branch_name: selectedName,
      });
    }
    if (name === "customerName") {
      this.setState({
        customer_type: selectedValue,
        customer_name: selectedName,
      });
    }
    if (name === "project") {
      this.setState({
        project_type: selectedValue,
        project_name: selectedName,
      });
    }
  };

  handleSelectDate = (value, name) => {
    if (name === "date") {
      this.setState({
        date: value,
      });
    }
  };

  componentDidMount() {
    if (this.props.location) {
      this.setState({
        locationState: this.props.location,
      });
    }
    if (this.props.location.state !== undefined) {
      this.setState({
        currentStep: 3,
      });
    } else {
      let storeIssueLineItem = [
        {
          id: "0",
          no: 0,
          lineitemId: 1,
          hdrId: "",
          itemGroupList: [],
          costFactorList: [],
          itemsList: [],
          srList: [],
          departmentList: [],
          deptId: 0,
          itemGroupId: 0,
          itemId: 0,
          srNo: "",
          quantity: 0,
          costfactor: 0,
          machineNo: 0,
          centralStock: 0,
          branchStock: 0,
          srStock: 0,
          issueValue: 0,
          itemName: "",
          departmentName: "",
          itemGroupName: "",
          uom: "",
          costFactorName: "",
          srPrintNo: "",
          inwardId: 0,
          newIssueValue: 0,
          deleteStatus: false,
        },
      ];
      this.props.storeIssueLineItemWise(storeIssueLineItem);

      let storeHeaderData = {
        date:
          this.props.storeHeaderDataList.date === null ||
          this.props.storeHeaderDataList.date === undefined
            ? moment().format("YYYY-MM-DD").toString()
            : this.props.storeHeaderDataList.date,
        expense_type: 0,
        branchId: 0,
        customer_type: 0,
        project_type: 0,
        internalNote: "",
        expense_name: "",
        customer_name: "",
        project_name: "",
      };
      this.props.storeIssueHeaderData(storeHeaderData);
    }

    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData) {
        const data = {
          hdrId: this.props.location.state.rowData.hdrId,
          companyId: localStorage.getItem("companyId"),
          finnacialYear: localStorage.getItem("acadamicYear"),
          userId: this.state.userDit.userId,
        };
        this.props.getStoreIssueViewById(
          serverApi.STORE_ISSUE_VIEW_BY_ID,
          data,
          this.props.history
        );
      }
    }
  }

  componentWillReceiveProps(props) {
    if (props.location.state !== undefined) {
      if (props.location.state.rowData) {
        this.setState({
          statusName: props.location.state.rowData.status,
        });
      }
    }
  }

  onCreateMaterialRequest = (status) => {
    if (this.props.match.path === "/create_material_request") {
      let currentDate = this.props.storeHeaderDataList.date;
      let properDte = moment(currentDate, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      );
      let filterData = this.state.products.filter((item) => {
        if (item.deptId !== 0) {
          return item;
        }
      });
      if (this.props.location.state) {
        const data = filterData.map((prop, key) => ({
          id: "",
          hdrId: this.props.location.state.rowData.hdrId,
          finnacialYear: localStorage.getItem("financialYear"),
          itemId: Number(prop.itemId),
          itemCode: prop.itemCode,
          deptId: prop.deptId,
          groupCode: prop.itemGroupId,
          indentTypeId: this.props.storeHeaderDataList.expense_type,
          branchId: this.props.storeHeaderDataList.branchId,
          projectId: this.props.storeHeaderDataList.project_type,
          materialInventoryId: prop.srNo,
          srLineId: prop.srNo,
          status: "29",
          customerId: this.props.storeHeaderDataList.customer_type,
          internalNote: this.props.storeHeaderDataList.internalNote,
          createdBy: this.state.userDit.userName,
          submitter: this.state.userDit.userId,
          inwardId: Number(prop.inwardId),
          srPrintNo: prop.srPrintNo,
          issueSrlNo: key + 1,
          issueValue: prop.issueValue,
          quantity: prop.quantity,
          deptCost: prop.costfactor,
          stock: 0,
          issueDate: properDte,

          companyId: localStorage.getItem("companyId"),
          cipher: this.state.userDit.cipher,
          machineId: prop.machineNo,
          uomCode: prop.uom,
        }));

        this.props.addMaterialRequest(
          serverApi.UPDATE_MATERIAL_REQUEST,
          data,
          this.props.history
        );
      } else {
        const data = filterData.map((prop, key) => ({
          finnacialYear: localStorage.getItem("financialYear"),
          itemId: Number(prop.itemId),
          itemCode: prop.itemCode,
          deptId: prop.deptId,
          groupCode: prop.itemGroupId,
          indentTypeId: this.props.storeHeaderDataList.expense_type,
          branchId: this.props.storeHeaderDataList.branchId,
          projectId: this.props.storeHeaderDataList.project_type,
          materialInventoryId: prop.srNo,
          srLineId: prop.srNo,
          status: "29",
          customerId: this.props.storeHeaderDataList.customer_type,
          internalNote: this.props.storeHeaderDataList.internalNote,
          createdBy: this.state.userDit.userName,
          submitter: this.state.userDit.userId,
          inwardId: Number(prop.inwardId),
          srPrintNo: prop.srPrintNo,
          issueSrlNo: key + 1,
          issueValue: prop.issueValue,
          quantity: prop.quantity,
          deptCost: prop.costfactor,
          stock: 0,
          issueDate: properDte,

          companyId: localStorage.getItem("companyId"),
          cipher: this.state.userDit.cipher,
          machineId: prop.machineNo,
          uomCode: prop.uom,
        }));
      }
    } else {
      let currentDate = this.props.storeHeaderDataList.date;
      let properDte = moment(currentDate, "YYYY-MM-DD", true).format(
        "DD-MM-YYYY"
      );
      let filterData = this.props.storeIssueLineItem.filter((item) => {
        if (item.itemId !== "" && item.itemId !== 0) {
          return item;
        }
      });

      if (this.props.location.state !== undefined) {
        let properDte = moment(currentDate, "YYYY-MM-DD", true).format(
          "DD-MM-YYYY"
        );

        const FinalData = filterData.filter(
          (data) =>
            data.deleteStatus !== null || data.deleteStatus !== undefined
        );
        const data = FinalData.map((prop, key) => ({
          id: prop.id,
          hdrId: this.props.location.state.rowData.hdrId,
          finnacialYear: localStorage.getItem("acadamicYear"),
          itemId: Number(prop.itemId),
          itemCode: prop.itemCode,
          deptId: prop.deptId,
          groupCode: prop.itemGroupId,
          indentTypeId: this.props.storeHeaderDataList.expense_type,
          branchId: prop.branchId
            ? prop.branchId
            : this.props.storeHeaderDataList.branchId,
          projectId: prop.projectId
            ? prop.projectId
            : this.props.storeHeaderDataList.project_type,
          materialInventoryId: prop.srNo,
          srLineId: prop.srNo,
          status: status,
          customerId: prop.customerId
            ? prop.customerId
            : this.props.storeHeaderDataList.customer_type,
          internalNote: this.props.storeHeaderDataList.internalNote,
          submitter: this.state.userDit.userId,
          inwardId: Number(prop.inwardId),
          srPrintNo: prop.srPrintNo,
          issueSrlNo: key + 1,
          issueValue: prop.issueValue,
          quantity: prop.quantity,
          deptCost: prop.costfactor,
          stock: prop.srStock,
          issueDate: prop.issueDate
            ? moment(prop.issueDate, "YYYY-MM-DD", true).format("DD-MM-YYYY")
            : properDte,
          lastModifiedUser: this.state.userDit.userId,
          deleteStatus: prop.deleteStatus,
          // date: currentDate,
          companyId: localStorage.getItem("companyId"),
          cipher: this.state.userDit.cipher,
          machineId: prop.machineNo,
          uomCode: prop.uom,
        }));

        this.props.addMaterialRequest(
          serverApi.UPDATE_MATERIAL_REQUEST,
          data,
          this.props.history
        );
      } else {
        const FinalData = filterData.filter(
          (data) => data.deleteStatus !== true
        );
        const data = FinalData.map((prop, key) => ({
          finnacialYear: localStorage.getItem("acadamicYear"),
          itemId: Number(prop.itemId),
          itemCode: prop.itemCode,
          deptId: prop.deptId,
          groupCode: prop.itemGroupId,
          indentTypeId: this.props.storeHeaderDataList.expense_type,
          branchId: this.props.storeHeaderDataList.branchId,
          projectId: this.props.storeHeaderDataList.project_type,
          materialInventoryId: prop.srNo,
          srLineId: prop.srNo,
          status: "1",
          customerId: this.props.storeHeaderDataList.customer_type,
          internalNote: this.props.storeHeaderDataList.internalNote,
          createdBy: this.state.userDit.userName,
          submitter: this.state.userDit.userId,
          inwardId: Number(prop.inwardId),
          srPrintNo: prop.srPrintNo,
          issueSrlNo: key + 1,
          issueValue: prop.issueValue,
          quantity: prop.quantity,
          deptCost: prop.costfactor,
          stock: prop.centralStock,
          issueDate: properDte,
          companyId: localStorage.getItem("companyId"),
          cipher: this.state.userDit.cipher,
          machineId: prop.machineNo,
          uomCode: prop.uom,
        }));

        this.props.addMaterialRequest(
          serverApi.CREATE_MATERIAL_REQUEST,
          data,
          this.props.history
        );
      }
    }
  };
  onClickApprove = (Status) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Approve",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willapprove) => {
      if (willapprove) {
        this.onCreateMaterialRequest(Status);
      }
    });
  };

  onClickReject = (Status) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Reject",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willapprove) => {
      if (willapprove) {
        this.onCreateMaterialRequest(Status);
      }
    });
  };

  render() {
    const { steps, currentStep } = this.state;
    const typePath =
      this.props.match.path === "/create_material_request" ? 1 : 2;

    return (
      <div>
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            <div className="iconsBlock">
              <Stepper alternativeLabel activeStep={currentStep}>
                {steps.map((prop) => (
                  <Step key={prop.title}>
                    <StepLabel
                      StepIconComponent={(Iconprops) => {
                        return this.QontoStepIcon(
                          Iconprops,
                          prop.icon,
                          prop.activeIcon,
                          prop.title
                        );
                      }}
                    ></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {currentStep === 1 && (
              <>
                <MaterialRequestFillDetails
                  onSelectDep={this.handleSelectChange}
                  header={this.props.storeHeaderDataList}
                  products={this.state.products}
                  handleSelectDate={this.handleSelectDate}
                  typePath={typePath}
                />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="cancelButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="saveButton"
                    handleClick={this.onClickNext}
                    type="sumbit"
                  />
                  {this.state.confirm && (
                    <CommonPopup>
                      <div className="delAddItem">
                        <div>Please Enter the mandatory Fields !</div>
                        <div className="delAddItemBtns">
                          <input
                            type="button"
                            onClick={this.onclickOk.bind(this)}
                            value="Ok!"
                            className="delYesBtn"
                          />
                        </div>
                      </div>
                    </CommonPopup>
                  )}
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                <MaterialRequestAddItems
                  header={this.props.storeHeaderDataList}
                  products={this.state.products}
                />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Back"}
                      className="cancelButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="saveButton"
                    handleClick={this.onClickNext}
                    type="sumbit"
                  />
                </div>
              </>
            )}
            {currentStep === 3 && (
              <>
                <MaterialRequestPreview
                  header={this.props.storeHeaderDataList}
                  products={this.state.products}
                  handleCurrentStep={this.handleCurrentStep}
                />
                <div className="consoleFormButtonBlock">
                  {this.state.locationState.state !== undefined ? (
                    ""
                  ) : (
                    <Link>
                      <CustomButton
                        label={"Back"}
                        className="cancelButton"
                        handleClick={this.onClickBefore}
                      />
                    </Link>
                  )}
                  {this.state.locationState.state !== undefined ? (
                    <>
                      {this.props.storeIssueLineItem[0] ? (
                        this.props.storeIssueLineItem[0].approveButton ===
                        true ? (
                          <>
                            <CustomButton
                              label={"Approve"}
                              className="approved"
                              handleClick={() => {
                                this.onClickApprove(
                                  this.props.storeIssueLineItem[0].status
                                );
                              }}
                              type="sumbit"
                            />
                            <CustomButton
                              label={"Reject"}
                              className="rejected"
                              handleClick={() => {
                                this.onClickReject("4");
                              }}
                              type="sumbit"
                            />
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                      {this.props.storeIssueLineItem[0] ? (
                        this.props.storeIssueLineItem[0].status !== "3" &&
                        this.props.storeIssueLineItem[0].status !== "4" ? (
                          <CustomButton
                            label={"Update"}
                            className="saveButton"
                            handleClick={() => {
                              this.onCreateMaterialRequest("");
                            }}
                            type="sumbit"
                          />
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      <CustomButton
                        label={"Cancel"}
                        className="greenBorderButton"
                        handleClick={() => {
                          this.props.history.push("/store_issue");
                        }}
                        type="sumbit"
                      />
                      <CustomButton
                        label={"Create"}
                        className="saveButton"
                        handleClick={this.onCreateMaterialRequest}
                        type="sumbit"
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { storeIssueLineItem, storeIssueViewByIdList, storeHeaderDataList } =
    state.Material_Request_List;
  return { storeIssueLineItem, storeIssueViewByIdList, storeHeaderDataList };
};

export default withRouter(
  connect(mapStatetoProps, {
    addMaterialRequest,
    storeIssueLineItemWise,
    getStoreIssueViewById,
    storeIssueHeaderData,
  })(MaterialRequestCreate)
);
