import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import {
  createLedgerGroupsMaster,
  updateLedgerGroupsMaster,
} from "../../../store/Master/LedgerGroupsMaster/actions";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import moment from "moment";
import { getParentLedgerList } from "../../../store/Global/DropDownApis/actions";
import { validate } from "../../../Utilities/Validations";
import swal from "sweetalert";
import { isEmpty, isEmptyWithZero } from "../../../Utilities/helper";
class CreateLedgerGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      parentLedger: "",
      id: "",
      parentGroup: "",
      ledgerGroupCode: " ",
      ledgerGroupName: "",
      parentLedgerValue: "",
      createdBy: null,
      createdOn: "",
      createdDate: "",
      parentLedgerList: [], // to display parent ledger list
      natureOfLedger: "",
      ledgerGroupType: "",
      locationstate: "",
    };
  }
  /* Calling Create and Update Api's to Actions */
  onClickCreateUpdate = () => {
    const userId = this.state.userDit.userId;
    const { ledgerGroupName } = this.state;
    const validateFields = [
      { field: Date, msg: "Holiday Date", type: 1 },
      {
        field: ledgerGroupName,
        msg: "Ledger Group Name",
        type: 1,
      },
    ];

    let validObj = validate(validateFields);
    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message}`);
      }
      if (validObj.type === 2) {
        swal(`Please Select ${validObj.message}`);
      }
    } else {
      if (this.props.location.state === undefined) {
        const data = {
          companyId: this.state.companyId,
          createdBy: userId,
          name: this.state.ledgerGroupName,
          parentGroup: this.state.parentGroup,
          createdOn: moment().local(),
          natureOfLedger: this.state.natureOfLedger,
        };
        this.props.createLedgerGroupsMaster(
          serverApi.CREATE_LEDGER_GROUPS_MASTER,
          data,
          this.props.history
        ); // calling create ledger groups api
      } else {
        const data = {
          cipher: this.state.userDit.cipher,
          companyId: this.state.companyId,
          createdBy: userId,
          name: this.state.ledgerGroupName,
          parentGroup: this.state.parentGroup,
          id: this.state.ledgerGroupCode,
          createdOn: this.state.createdOn,
          natureOfLedger: this.state.natureOfLedger,
        };
        this.props.updateLedgerGroupsMaster(
          serverApi.UPDATE_LEDGER_GROUPS_MASTER,
          data,
          this.props.history
        ); // calling update ledger groups api
      }
    }
  };
  // function for previous page
  onClickCancel = () => {
    this.props.history.push("/Ledger_Groups");
  };
  // function for textfields
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "ledgerGroupName") {
      this.setState({
        ledgerGroupName: event,
      });
    }
  };
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  // Function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "parentLedger") {
      this.setState({
        parentLedger: selectedValue,
        parentGroup: selectedValue,
      });
    }
    if (name === "ledgerGroupType") {
      this.setState({
        ledgerGroupType: selectedValue,
        parentGroup:"",
        parentLedger:"",
        natureOfLedger:""
      });
    }
    if (name === "natureOfLedger") {
      this.setState({
        natureOfLedger: selectedName,
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    this.setState({
      locationstate:this.props.location,
    })
    window.addEventListener("scroll", this.handleScroll);
    this.props.getParentLedgerList(
      serverApi.GET_PARENT_LEDGER_LIST +
        this.state.companyId +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    ); // parent ledger list api
    if (this.props.location.state) {
      if (this.props.location.state.rowData) {
        this.setState({
          ledgerGroupCode: this.props.location.state.rowData.id,
          ledgerGroupName: this.props.location.state.rowData.name,
          createdBy: this.props.location.state.rowData.createdBy,
          parentGroup: this.props.location.state.rowData.parentGroup,
          createdOn: this.props.location.state.rowData.createdOn,
          natureOfLedger: this.props.location.state.rowData.natureOfLedger,
        });
        if (!isEmpty(this.props.location.state.rowData.natureOfLedger)) {
          this.setState({
            ledgerGroupType: "1",
          });
        } else if (!isEmpty(this.props.location.state.rowData.parentGroup)) {
          this.setState({
            ledgerGroupType: "2",
          });
        }else if(isEmpty(this.props.location.state.rowData.natureOfLedger) && isEmpty(this.props.location.state.rowData.parentGroup)){
          this.setState({
            ledgerGroupType: "",
          });
        }else{
          this.setState({
            ledgerGroupType: "",
          });
        }
        const dateEntered = this.props.location.state.rowData.createdOn;
        const dateIsValid = moment(dateEntered).format("LLLL");
        this.setState({
          createdDate: dateIsValid,
        });
      }
    }
  }
  componentWillReceiveProps(props) {
    if (props.parentLedgerList) {
      this.setState({
        parentLedgerList: props.parentLedgerList.data, // updating parent ledger list
      });
    }
  }

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {this.state.locationstate.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 3">
                      <TextFieldReq
                        value={this.state.ledgerGroupCode}
                        caps="OFF"
                        minValue="0"
                        onChange={this.onhandlechangeValue("ledgerGroupCode")}
                        label="Ledger Group Code"
                      />
                    </Box>
                  )}

                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.ledgerGroupName}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("ledgerGroupName")}
                      label="Ledger Group Name"
                      disabled={
                        this.state.ledgerGroupCode === "" ? true : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={ledgerGroupTypeData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.ledgerGroupType}
                        update={this.state.ledgerGroupType ? 1 : 0}
                        name="ledgerGroupType"
                        label="Ledger Group Type"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.parentLedgerList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.parentGroup}
                        update={this.props.location.state ? 1 : 0}
                        name="parentLedger"
                        label="Parent Ledger"
                        isDisabled={
                          this.state.ledgerGroupType == "2" ? false : true
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectNew
                        arrayOfData={natureOfLedgerData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.natureOfLedger}
                        update={this.state.natureOfLedger ? 1 : 0}
                        name="natureOfLedger"
                        label="Nature Of the Ledger"
                        isDisabled={
                          this.state.ledgerGroupType == "1" ? false : true
                        }
                      />
                    </div>
                  </Box>
                  {this.state.locationstate.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdDate}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.state.locationstate.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
          {/* common popup for validation */}

          {this.state.confirm && (
            <CommonPopup>
              <div className="delAddItem">
                <div className="mandatoryFieldText">
                  Please select all the mandatory fields
                </div>
                <div className="delAddItemBtns">
                  <input
                    type="button"
                    onClick={this.onclickPopUP.bind(this)}
                    value="Ok"
                    className="delYesBtn"
                  />
                </div>
              </div>
            </CommonPopup>
          )}
        </div>
      </div>
    );
  }
}
const ledgerGroupTypeData = [
  {
    value: "",
    name: "Select...",
    label: "Select...",
  },
  {
    value: 1,
    name: "Primary ledger",
    label: "Primary ledger",
  },
  {
    value: 2,
    name: "Parent ledger",
    label: "Parent ledger",
  },
];
const natureOfLedgerData = [
  {
    value: 1,
    name: "Assets",
    label: "Assets",
  },
  {
    value: 2,
    name: "Liabilities",
    label: "Liabilities",
  },
  {
    value: 3,
    name: "Expenses",
    label: "Expenses",
  },
  {
    value: 4,
    name: "Income",
    label: "Income",
  },
];
const mapStatetoProps = (state) => {
  const { CreateLedgerGroupsMaster, UpdateLedgerGroupsMaster } =
    state.LedgerReducer; // fetching create and update from ledgergroups reducer
  const { parentLedgerList } = state.DropDownListReducer; // fetching parent ledger from global api
  return {
    parentLedgerList,
    CreateLedgerGroupsMaster,
    UpdateLedgerGroupsMaster,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    createLedgerGroupsMaster,
    updateLedgerGroupsMaster,
    getParentLedgerList,
  })(CreateLedgerGroups)
);
