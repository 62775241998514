import React, { Component } from "react";
import { Grid, Step, Stepper, StepLabel } from "@mui/material";
import Link from "@mui/material/Link";
import { serverApi } from "../../../helpers/Consts";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import FillDetails from "./FillDetails";
import IndentPreview from "./IndentPreview";
import moment from "moment";
import {
  getCreateIndent,
  UpdateIndent,
  IndentLineItemsProps,
  getItemDetailsByPoIdForIndent,
} from "../../../store/Purchase/Indent/actions";
import swal from "sweetalert";
import OpenIndentLineItems from "./OpenIndentLineItems";
import BOMBOQLineItems from "../../Projects/BOM_BOQ/BOMBOQLineItems";
import BOMBOQPreview from "../../Projects/BOM_BOQ/BOMBOQPreview";
import { LineItemsValidtions } from "../../../Utilities/LineItemsValidtions";
import { ApprovalHierarchy } from "../../../store/HRMS/EmployeeDataBase/actions";
import IndentLineItemsUpdate from "./IndentLineItemsUpdate";
import { limitDecimals } from "../../../Utilities/helper";

class CreatePage extends Component {
  constructor() {
    super();
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      steps: [
        {
          title: "Fill Details",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Add Items",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Preview",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive03.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
      ],
      currentStep: 0,
      source: "",
      date: null,
      locationState: "",
      confirmPopUp: false,
      indentListData: [],
      addListItem: [],
      customerId: "",
      indentStatus: "",
      finalApprover: "",
      approveStatus: false,
      indentStatusName: "",
      internalRemarks: "",
      remarks: "",
      make:"",
      showUpdateBtn: false,
      indentWithMultiDepartments: "",
      openIndentActive: 0,
      IndentType: 0,
      indentFillDetails: {
        dateValue: moment().format("YYYY-MM-DD"),
        project: "",
        projectValue: "",
        client: "",
        clientValue: "",
        categoryType: "",
        categoryTypeValue: "",
        branch: "",
        branchValue: "",
        itemGroup: "",
        itemGroupValue: "",
        departmentName: "",
        departmentId: "",
        budget_Head: "",
        budget_Head_Value: "",
        copy_from_BOM: "",
        title: "",
        internalRemarks: "",
        remarks: "",
        make:"",
        indentType: "",
        indentSquenceNo: "",
        totalValue: 0,
        branchListData: [],
        projectListData: [],
        categoryTypeList: [],
        ItemGroupList: [],
        ItemGroupListbyBudget: [],
        DepartmentList: [],
        ClientList: [],
        budgetHeadsList: [],
      },
      indentDetails: [],
    };
  }
  componentDidMount() {
    this.setState({
      locationState: this.props.location,
    });
    if (this.props.location.state) {
      this.setState({
        currentStep: 3,
      });
      let customerId = this.props.location.state.rowData.customerId;
      let indentLineItems = [];
      let itemGroupValue = "";
      let departmentName = "";
      let budgetHeadId = "";
      let budgetHeadName = "";
      if (this.props.location.state.rowData.indentDetails !== null) {
        this.props.location.state.rowData.indentDetails.map((prop) => {
          indentLineItems.push(prop);
          itemGroupValue = prop.itemGroupId;
          departmentName = prop.departmentName;
          budgetHeadId = prop.budgetHeadId;
          budgetHeadName = prop.budgetHeadName;
          this.setState({
            indentFillDetails: {
              dateValue: moment(
                this.props.location.state.rowData.indentDate,
                "DD-MM-YYYY",
                true
              ).format("YYYY-MM-DD"),
              project: this.props.location.state.rowData.projectId,
              projectValue: this.props.location.state.rowData.projectId,
              client: this.props.location.state.rowData.customerName,
              clientValue: this.props.location.state.rowData.customerId,
              categoryType: this.props.location.state.rowData.categoryName,
              categoryTypeValue: this.props.location.state.rowData.categoryId,
              branch: this.props.location.state.rowData.branchId,
              branchValue: this.props.location.state.rowData.branchId,
              itemGroup: this.props.location.state.rowData.itemGroup,
              itemGroupValue: prop.itemGroupId,
              departmentName: prop.departmentName,
              departmentId: prop.departmentId,
              budget_Head: prop.budgetHeadName,
              budget_Head_Value: prop.budgetHeadId,
              title: this.props.location.state.rowData.title,
              indentType: this.props.location.state.rowData.recordType,
              indentSquenceNo:
                this.props.location.state.rowData.indentSquenceNo,
              totalValue: 0,
              internalRemarks:
                this.props.location.state.rowData.internalRemarks,
              remarks: this.props.location.state.rowData.remarks,
              make: this.props.location.state.rowData.make,
              copy_from_BOM: this.props.location.state.rowData.copy_from_BOM,
            },
            customerId: customerId,
            indentStatus: this.props.location.state.rowData.indentStatus,
            finalApprover: this.props.location.state.rowData.finalApprover,
            // approveStatus: this.props.location.state.rowData.approveStatus,
            indentStatusName:
              this.props.location.state.rowData.indentStatusName,
          });
        });
        let taskId = 0;
        if (this.props.location.pathname === "/create_indent") {
          taskId = 1;
        } else if (this.props.location.pathname === "/create_bom") {
          taskId = 36;
        } else if (this.props.location.pathname === "/create_boq") {
          taskId = 37;
        } else if (this.props.location.pathname === "/create_openIndent") {
          taskId = 43;
        }
        this.props.ApprovalHierarchy(
          serverApi.APPROVAL_BUTTON +
            JSON.parse(localStorage.getItem("authUser")).userId +
            "/" +
            taskId +
            "/company/" +
            localStorage.getItem("companyId") +
            "/branch/" +
            this.props.location.state.rowData.branchId +
            "/status/" +
            this.props.location.state.rowData.indentStatus,
          this.props.history
        );
        this.props.getItemDetailsByPoIdForIndent(
          serverApi.GET_ITEM_DETAILS_BY_PO_ID_FOR_INDENT +
          this.props.location.state.rowData.id
        )
      }

      let obj = {
        id: 0,
        qty: "0.000",
        uomCode: "",
        qohCompany: "",
        qohBranch: 0,
        qohProject: 0,
        rate: "0.000",
        rateSource: 1,
        isActive: 1,
        remarks: "",
        make:"",
        itemGroup: this.state.indentFillDetails.itemGroup,
        itemGroupValue: itemGroupValue,
        departmentName: departmentName,
        departmentId: this.state.indentFillDetails.departmentId,
        budgetHeadId: budgetHeadId,
        budgetHeadName: budgetHeadName,
        subExpenseTypeName: null,
        selectedExpenseType: null,
        selectedSubExpenseType: null,
        installationRate: null,
        itemId: "",
        lastPurchaseDate: "",
        lastPurchaseRate: "0.000",
        qtyPo: "",
        amount: "0.00",
        status: 1,
        min: 0,
        max: 0,
        minAllowedQty:0,
        maxAllowedQty:0,
        itemList: [],
        reOrderQty: 0,
        departmentList: [],
        itemGroupList: [],
        departmentText: "", //this field textbox department name
        itemName: "", //
        itemText: "", // this field textbox item
        itemGroupText: "", // this field textbox item group
        displayField: 1,
        isEditMode: true,
        isUpdate: true,
      };
      let obj2 = {
        id: 0,
        qty: "0.000",
        uomCode: "",
        qohCompany: "",
        qohBranch: 0,
        qohProject: 0,
        rate: "0.000",
        rateSource: 1,
        isActive: 1,
        remarks: "",
        itemGroup: "",
        itemGroupValue: "",
        departmentName: "",
        departmentId: "",
        budgetHeadId: "",
        subExpenseTypeName: null,
        selectedExpenseType: null,
        selectedSubExpenseType: null,
        installationRate: "0.000",
        itemId: "",
        lastPurchaseDate: "",
        lastPurchaseRate: "0.000",
        qtyPo: "",
        amount: "0.00",
        make: "",
        status: 1,
        min: 0,
        max: 0,
        minAllowedQty:0,
        maxAllowedQty:0,
        itemList: [],
        reOrderQty: 0,
        departmentList: [],
        itemGroupList: [],
        departmentText: "", //this field textbox department name
        itemName: "", //
        itemText: "", // this field textbox item
        itemGroupText: "", // this field textbox item group
        displayField: 1,
        isEditMode: true,
        isUpdate: true,
      };
      if (this.state.indentWithMultiDepartments === "2") {
        indentLineItems.sort((a, b) => (a.id > b.id ? 1 : -1));
        indentLineItems.push(obj);
      } else {
        indentLineItems.sort((a, b) => (a.id > b.id ? 1 : -1));
        indentLineItems.push(obj2);
      }
      let indentLineItemsData = indentLineItems.map((lineitems) => {
        return {
          budgetHeadId: lineitems.budgetHeadId,
          budgetHeadName: lineitems.budgetHeadName,
          cancelledBy: lineitems.cancelledBy,
          cancelledByUser: lineitems.cancelledByUser,
          cancelledDate: lineitems.cancelledDate,
          cancelledQty: lineitems.cancelledQty,
          cancelledReasons: lineitems.cancelledReasons,
          categoryId: lineitems.categoryId,
          costcenterId: lineitems.costcenterId,
          costcenterName: lineitems.costcenterName,
          departmentId: lineitems.departmentId,
          departmentName: lineitems.departmentName,
          description: lineitems.description,
          expectedDeliveryDate: lineitems.expectedDeliveryDate,
          expenseTypeName: lineitems.expenseTypeName,
          id: lineitems.id,
          indentId: lineitems.indentId,
          indentName: lineitems.indentName,
          installationRate: limitDecimals(lineitems.installationRate, 3),
          isActive: lineitems.isActive,
          itemCode: lineitems.itemCode,
          itemGroup: lineitems.itemGroup,
          itemGroupId: lineitems.itemGroupId,
          itemId: lineitems.itemId,
          itemList: [],
          departmentList: [],
          itemGroupList: [],
          itemName: lineitems.itemName,
          lastPurchaseDate: lineitems.lastPurchaseDate,
          lastPurchaseRate: lineitems.lastPurchaseRate,
          make: lineitems.make,
          qohBranch: lineitems.qohBranch,
          qohCompany: lineitems.qohCompany,
          qohProject: lineitems.qohProject,
          min: lineitems.minQty,
          max: lineitems.maxQty,
          minAllowedQty: lineitems.minAllowedQty,
          maxAllowedQty: lineitems.maxAllowedQty,
          reOrderQty: lineitems.reOrderQty,
          qty: limitDecimals(lineitems.qty, 3),
          qtyPo: lineitems.qtyPo,
          qtyPoOpen: lineitems.qtyPoOpen,
          rate: lineitems.rate,
          rateSource: lineitems.rateSource,
          remainingQuantity: lineitems.remainingQuantity,
          remarks: lineitems.remarks,
          make:lineitems.make,
          selectedExpenseType: lineitems.selectedExpenseType,
          selectedSubExpenseType: lineitems.selectedSubExpenseType,
          stRemQty: lineitems.stRemQty,
          status: lineitems.status,
          statusName: lineitems.statusName,
          subExpenseTypeName: lineitems.subExpenseTypeName,
          taxId: lineitems.taxId,
          taxPercentage: lineitems.taxPercentage,
          uomCode: lineitems.uomCode,
          displayField: 1,
          isEditMode:
            lineitems.isEditMode !== undefined ? lineitems.isEditMode : false,
          isUpdate: lineitems.isUpdate === true ? lineitems.isUpdate : false,
        };
      });
      this.setState({
        indentDetails: indentLineItemsData,
      });
      this.props.IndentLineItemsProps(indentLineItemsData);
    } else {
      let IndentLineItem = [
        {
          id: 1,
          qty: "0.000",
          uomCode: "",
          qohCompany: "",
          qohBranch: 0,
          qohProject: 0,
          rate: "0.000",
          rateSource: 1,
          isActive: 1,
          remarks: "",
          make:"",
          itemGroup: this.state.indentFillDetails.itemGroup,
          itemGroupValue: this.state.indentFillDetails.itemGroupValue,
          departmentName: this.state.indentFillDetails.departmentName,
          departmentId: this.state.indentFillDetails.departmentId,
          budgetHeadId: this.state.indentFillDetails.budget_Head_Value,
          budgetHeadName: this.state.indentFillDetails.budget_Head,
          subExpenseTypeName: null,
          selectedExpenseType: null,
          selectedSubExpenseType: null,
          installationRate: "0.000",
          itemId: "",
          lastPurchaseDate: "",
          lastPurchaseRate: "0.000",
          qtyPo: "0.00",
          amount: "0.00",
          make: "",
          status: 1,
          min: 0,
          max: 0,
          minAllowedQty: 0,
          maxAllowedQty: 0,
          itemList: [],
          reOrderQty: "0.000",
          departmentList: [],
          itemGroupList: [],
          departmentText: "", //this field textbox department name
          itemName: "", //
          itemText: "", // this field textbox item
          itemGroupText: "", // this field textbox item group
          displayField: 1,
          isEditMode: true,
          isUpdate: false,
        },
      ];
      this.props.IndentLineItemsProps(IndentLineItem);
      this.setState({
        currentStep: 1,
      });
    }

    if (this.state.userDit) {
      this.state.userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              let openIndentActive = item.openIndent;
              let openIndent = {};
              if (openIndentActive !== undefined && openIndentActive !== "") {
                openIndent = JSON.parse(openIndentActive);
                if (openIndent.openIndent === 1) {
                  this.setState({
                    openIndentActive: openIndent.openIndent,
                    // currentStep: 2,
                    IndentType: 1,
                    source: "INDENT",
                  });
                }
              }
              if (item.indentWithMultiDepartments !== undefined) {
                this.setState({
                  indentWithMultiDepartments: item.indentWithMultiDepartments,
                });
              }
            });
          }
      });
    }
  }

  componentWillReceiveProps(props) {
    if (props.indentLineItems) {
      this.setState({
        indentDetails: props.indentLineItems,
      });
    }
    if (props.approval_hierarchy) {
      if (props.approval_hierarchy.data) {
        this.setState({
          approveStatus: props.approval_hierarchy.data.approveButton,
        });
      }
    }
  }

  ///function for stepper
  QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;
    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };

  //function for Date
  handleSelectDate = (value) => {
    const UpdatedindentFillDetails = {
      ...this.state.indentFillDetails,
      dateValue: value,
    };
    this.setState({
      indentFillDetails: UpdatedindentFillDetails,
    });
  };

  ///function for change the page
  onClicknextBtn = () => {
    const { currentStep } = this.state;
    let valid = this.validateForms();
    if (currentStep === 1) {
      if (valid === true) {
        this.setState({
          currentStep: currentStep + 1,
        });
      }
    } else if (currentStep === 2) {
      let validIndentLineItems = this.props.indentLineItems;
      // LineItemsValidtions(validIndentLineItems)
      let validObj = false;
      let lastRow =
        this.state.indentDetails[this.state.indentDetails.length - 1];
      let indentLineItems = this.props.indentLineItems.filter(
        (item) => item.isActive !== 0
      );

      // indentLineItems.map((item, index) => {
      for (var i = 0; i <= indentLineItems.length - 1; i++) {
        if (this.state.indentWithMultiDepartments !== "2") {
          if (this.props.location.pathname === "/create_openIndent") {
            const validateFields = [
              {
                field: indentLineItems[i].departmentId,
                msg: "Department",
                type: 2,
              },
              {
                field: indentLineItems[i].itemGroup,
                msg: "Item Group",
                type: 2,
              },
              { field: indentLineItems[i].itemId, msg: "Item", type: 2 },
            ];
            if (indentLineItems.length === 1) {
              validObj = LineItemsValidtions(validateFields);
              if (validObj) {
                break;
              }
            } else {
              if (indentLineItems[i] !== lastRow) {
                validObj = LineItemsValidtions(validateFields);
                if (validObj) {
                  break;
                }
              }
            }
          } else {
            if (
              this.props.location.pathname === "/create_bom" ||
              this.props.location.pathname === "/create_boq"
            ) {
              const validateFields = [
                {
                  field: indentLineItems[i].budgetHeadId,
                  msg: "Budget Head",
                  type: 2,
                },
                {
                  field: indentLineItems[i].itemGroup,
                  msg: "Item Group",
                  type: 2,
                },
                { field: indentLineItems[i].itemId, msg: "Item", type: 2 },
                { field: indentLineItems[i].qty, msg: "Quantity", type: 1 },
              ];
              if (indentLineItems.length === 1) {
                validObj = LineItemsValidtions(validateFields);
                if (validObj) {
                  break;
                }
              } else {
                if (indentLineItems[i] !== lastRow) {
                  validObj = LineItemsValidtions(validateFields);
                  if (validObj) {
                    break;
                  }
                }
              }
            } else {
              const validateFields = [
                {
                  field: indentLineItems[i].departmentId,
                  msg: "Department",
                  type: 2,
                },
                {
                  field: indentLineItems[i].itemGroup,
                  msg: "Item Group",
                  type: 2,
                },
                { field: indentLineItems[i].itemId, msg: "Item", type: 2 },
                { field: indentLineItems[i].qty, msg: "Quantity", type: 1 },
              ];
              if (indentLineItems.length === 1) {
                validObj = LineItemsValidtions(validateFields);
                if (validObj) {
                  break;
                }
              } else {
                if (indentLineItems[i] !== lastRow) {
                  validObj = LineItemsValidtions(validateFields);
                  if (validObj) {
                    break;
                  }
                }
              }
            }
          }
        } else {
          const validateFields = [
            { field: indentLineItems[i].itemId, msg: "Item", type: 2 },
            { field: indentLineItems[i].qty, msg: "Quantity", type: 1 },
          ];

          if (indentLineItems.length === 1) {
            validObj = LineItemsValidtions(validateFields);
            if (validObj) {
              break;
            }
          } else {
            if (indentLineItems[i] !== lastRow) {
              validObj = LineItemsValidtions(validateFields);
              if (validObj) {
                break;
              }
            }
          }
        }
      }
      // });
      if (validObj) {
        if (validObj.type === 1) {
          swal(`Please Enter ${validObj.message}`);
        }
        if (validObj.type === 2) {
          swal(`Please Select ${validObj.message}`);
        }
      } else {
        this.setState({
          currentStep: currentStep + 1,
        });
      }
    } else {
      this.setState({
        currentStep: currentStep + 1,
      });
    }
  };
  validateForms = () => {
    let data = this.state.indentFillDetails;
    if (
      this.props.location.pathname === "/create_indent" ||
      this.props.location.pathname === "/create_openIndent"
    ) {
      if (this.state.indentWithMultiDepartments === "2") {
        if (data.dateValue === "") {
          return false;
        }
        if (this.state.indentFillDetails.projectListData.length >= 2) {
          if (data.project === "") {
            swal("Please select  project");
            return false;
          }
        } else {
        }
        if (this.state.indentFillDetails.categoryTypeList.length >= 1) {
          if (data.categoryType === "") {
            swal("Please select  category Type");
            return false;
          }
        }
        if (this.state.indentFillDetails.branchListData.length >= 2) {
          if (data.branch === "" || data.branchValue === 0) {
            swal("Please select Branch");
            return false;
          }
        }
        if (this.state.indentFillDetails.DepartmentList.length >= 2) {
          if (data.departmentId === "" || data.departmentId === 0) {
            swal("Please select Department");

            return false;
          }
        }
        if (this.props.location.state !== undefined) {
          if (this.state.indentFillDetails.ItemGroupList === undefined) {
            swal("Please select Item Group");
            return false;
          }
        }
        if (
          this.state.indentFillDetails.ItemGroupList === undefined ||
          this.state.indentFillDetails.ItemGroupList.length >= 2
        ) {
          if (data.itemGroup === "" || data.itemGroupValue === "0") {
            swal("Please select Item Group");

            return false;
          }
        }
        return true;
      } else {
        if (data.dateValue === "") {
          return false;
        }
        if (this.state.indentFillDetails.projectListData.length >= 2) {
          if (data.project === "") {
            swal("Please select  project");
            return false;
          }
        }
        if (this.state.indentFillDetails.categoryTypeList.length >= 2) {
          if (data.categoryType === "") {
            swal("Please select  categoryType");
            return false;
          }
        }
        if (this.state.indentFillDetails.branchListData.length >= 2) {
          if (data.branch === "") {
            swal("Please select Branch");
            return false;
          }
        }
        return true;
      }
    }

    if (this.props.location.pathname === "/create_bom") {
      if (this.state.indentWithMultiDepartments === "2") {
        if (data.title === "") {
          swal("Please Enter  Title");
          return false;
        }
        if (data.dateValue === "") {
          return false;
        }

        if (this.state.indentFillDetails.projectListData.length >= 2) {
          if (data.project === "") {
            swal("Please select  project");
            return false;
          }
        } else {
        }
        if (this.state.indentFillDetails.categoryTypeList.length >= 2) {
          if (data.categoryType === "") {
            swal("Please select  category Type");
            return false;
          }
        }
        if (this.state.indentFillDetails.branchListData.length >= 2) {
          if (data.branch === "") {
            swal("Please select Branch");
            return false;
          }
        }
        if (this.state.indentFillDetails.budgetHeadsList.length >= 2) {
          if (data.budget_Head_Value === "" && data.budget_Head_Value === "0") {
            swal("Please select Budget Head");
            return false;
          }
        }
        if (this.state.indentFillDetails.ItemGroupList.length >= 2) {
          if (data.itemGroup === "") {
            swal("Please select Item Group");
            return false;
          }
        }
        return true;
      } else {
        if (data.dateValue === "") {
          return false;
        }
        if (data.title === "") {
          swal("Please Enter Title");
          return false;
        }

        if (this.state.indentFillDetails.projectListData.length >= 2) {
          if (data.project === "") {
            swal("Please select project");
            return false;
          }
        }
        if (this.state.indentFillDetails.categoryTypeList.length >= 2) {
          if (data.categoryType === "") {
            swal("Please select category Type");
            return false;
          }
        }
        if (this.state.indentFillDetails.branchListData.length >= 2) {
          if (data.branch === "" || data.branchValue === 0) {
            swal("Please select Branch");
            return false;
          }
        }
        return true;
      }
    }
    if (this.props.location.pathname === "/create_boq") {
      if (this.state.indentWithMultiDepartments === "2") {
        if (data.title === "") {
          swal("Please Enter  Title");
          return false;
        }
        if (data.dateValue === "") {
          return false;
        }

        if (this.state.indentFillDetails.projectListData.length >= 2) {
          if (data.project === "") {
            swal("Please select  project");
            return false;
          }
        } else {
        }
        if (this.state.indentFillDetails.categoryTypeList.length >= 2) {
          if (data.categoryType === "") {
            swal("Please select  category Type");
            return false;
          }
        }
        if (this.state.indentFillDetails.branchListData.length >= 2) {
          if (data.branch === "" || data.branchValue === 0) {
            swal("Please select Branch");
            return false;
          }
        }
        if (this.state.indentFillDetails.budgetHeadsList.length >= 2) {
          if (data.budget_Head_Value === "" || data.budget_Head_Value === "0") {
            swal("Please select Budget Head");
            return false;
          }
        }
        if (this.state.indentFillDetails.ItemGroupList.length >= 2) {
          if (data.itemGroup === "") {
            swal("Please select Item Group");
            return false;
          }
        }
        return true;
      } else {
        if (data.dateValue === "") {
          return false;
        }
        if (data.title === "") {
          swal("Please Enter Title");
          return false;
        }

        if (this.state.indentFillDetails.projectListData.length >= 2) {
          if (data.project === "") {
            swal("Please select project");
            return false;
          }
        }
        if (this.state.indentFillDetails.categoryTypeList.length >= 2) {
          if (data.categoryType === "") {
            swal("Please select categoryType");
            return false;
          }
        }
        if (this.state.indentFillDetails.branchListData.length >= 2) {
          if (data.branch === "" || data.branchValue === 0) {
            swal("Please select Branch");
            return false;
          }
        }
        return true;
      }
    }
  };

  addLineItems = () => {
    var data = this.state.indentDetails;
    data.forEach((prop, key) => {
      if (prop.itemId !== "" && prop.qty !== "" && prop.amount !== "") {
      } else {
        this.setState({
          currentStep: this.state.currentStep + 1,
        });
        // swal("Please select all the mandatory fields");
      }
    });
  };

  // function for onClick back
  onClickCancel = () => {
    const { currentStep } = this.state;
    if (currentStep > 1) {
      this.setState({
        currentStep: currentStep - 1,
      });
    } else {
      if (this.props.location.pathname === "/create_boq") {
        this.props.history.push("/boq");
      } else if (this.props.location.pathname === "/create_bom") {
        this.props.history.push("/bom");
      } else if (this.props.location.pathname === "/create_openIndent") {
        this.props.history.push("/openIndent");
      } else {
        this.props.history.push("/store_indent");
      }
    }
  };

  // function for Create API
  onConfirm = () => {
    const filteredProducts = this.state.indentDetails.filter((product) => {
      // return (
      //   product.uomCode &&
      //   product.qty &&
      //   product.itemId &&
      //   product.itemId !== "0"
      // );
      if (product.min !== 0 && product.max !== 0) {
        if (product.isActive !== 0) {
          return product;
        }
      } else {
        if (product.qty && product.isActive !== 0) {
          return product;
        }
      }
    });

    const filteredOpenindentItems = this.state.indentDetails.filter(
      (product) => {
        return (
          product.itemId && product.itemId !== "0" && product.isActive !== 0
        );
      }
    );
    const filteredProductsBOM = this.state.indentDetails.filter((product) => {
      return (
        product.uomCode &&
        product.qty &&
        product.itemId &&
        product.itemId !== "0" &&
        product.isActive !== 0
      );
    });
    var date = moment(
      this.state.indentFillDetails.dateValue,
      "YYYY-MM-DD",
      true
    ).format("DD-MM-YYYY");
    var recordType =
      this.props.location.pathname === "/create_boq"
        ? "BOQ"
        : this.props.location.pathname === "/create_bom"
        ? "BOM"
        : this.props.location.pathname === "/create_openIndent"
        ? "OPENINDENT"
        : "INDENT";
    var data = {
      indentDate: date,
      fy: localStorage.getItem("acadamicYear"),
      //for open indent
      // recordType: "OPENINDENT",
      recordType: recordType,
      totalValue:
        this.props.location.pathname === "/create_openIndent"
          ? 0
          : this.props.location.pathname === "/create_indent"
          ? parseFloat(
              filteredProducts
                .map((p) => p.amount)
                .reduce((total, each) => total + each)
            ).toFixed(2)
          : filteredProductsBOM
              .map((p) => p.amount)
              .reduce((total, each) => total + each),
      // totalvalue: 0,
      internalRemarks: this.state.indentFillDetails.internalRemarks,
      remarks: this.state.indentFillDetails.remarks,
      make:this.state.indentFillDetails.make,
      source: 0,
      companyId: localStorage.getItem("companyId"),
      branchId: this.state.indentFillDetails.branchValue,
      categoryId: this.state.indentFillDetails.categoryTypeValue,
      storeId: this.state.indentFillDetails.branchValue,
      projectId: this.state.indentFillDetails.projectValue,
      createdBy: this.state.userDit.userId.toString(),
      phaseId: "",
      customerId: this.state.indentFillDetails.clientValue,
      title: this.state.indentFillDetails.title,
      indentDetails:
        this.props.location.pathname === "/create_bom" ||
        this.props.location.pathname === "/create_boq"
          ? filteredProductsBOM.map((product) => ({
              amount: product.rate * product.qty,
              budgetHeadId:
                product.budgetHeadId !== ""
                  ? product.budgetHeadId
                  : this.state.indentFillDetails.budget_Head_Value,
              installationRate:
                product.installationRate !== undefined
                  ? product.installationRate
                  : "",
              itemId: product.itemId,
              lastPurchaseDate: "",
              lastPurchaseRate: "",
              make: product.make,
              qohBranch: product.qohBranch,
              qohCompany: product.qohCompany,
              qohProject: product.qohProject,
              qty: product.qty,
              qtyPo: product.qtyPo,
              rate: product.rate,
              rateSource: 1,
              remarks: product.remarks,
              selectedExpenseType: product.selectedExpenseType,
              selectedSubExpenseType: product.selectedSubExpenseType,
              uomCode: product.uomCode,
              isActive: 1,
            }))
          : this.props.location.pathname === "/create_openIndent"
          ? filteredOpenindentItems.map((product) => ({
              qty: 0,
              uomCode: product.uomCode,
              qohCompany: product.qohCompany,
              qohBranch: product.qohBranch,
              qohProject: product.qohProject,
              rate: parseFloat(product.rate).toFixed(3),
              rateSource: 1,
              remarks: product.remarks,
              make:product.make,
              departmentId:
                product.departmentId !== ""
                  ? product.departmentId
                  : this.state.indentFillDetails.departmentId,
              itemId: Number(product.itemId),
              lastPurchaseDate: "",
              lastPurchaseRate: 0,
            }))
          : filteredProducts.map((product) => ({
              qty: product.qty,
              uomCode: product.uomCode,
              qohCompany: product.qohCompany,
              qohBranch: product.qohBranch,
              qohProject: product.qohProject,
              rate: parseFloat(product.rate).toFixed(3),
              rateSource: 1,
              remarks: product.remarks,
              make: product.make,
              departmentId:
                product.departmentId !== ""
                  ? product.departmentId
                  : this.state.indentFillDetails.departmentId,
              itemId: Number(product.itemId),
              lastPurchaseDate: "",
              lastPurchaseRate: 0,
            })),
    };
    this.props.getCreateIndent(
      serverApi.CREATE_INDENT,
      data,
      this.props.history
    );
  };

  onUpdate = (status) => {
    let indentDetails = this.state.indentDetails.filter(
      (item) => item.isActive !== 0
    );

    let invoiceList = indentDetails.map((prop, key) =>
      this.props.location.pathname === "/create_bom" ||
      this.props.location.pathname === "/create_boq"
        ? {
            amount: prop.rate * prop.qty,
            budgetHeadId:
              prop.budgetHeadId !== ""
                ? prop.budgetHeadId
                : this.state.indentFillDetails.budget_Head_Value,
            id: prop.isUpdate === true ? "" : prop.id,
            installationRate:
              prop.installationRate !== undefined ? prop.installationRate : "",
            itemId: prop.itemId,
            make: prop.make,
            qohBranch: prop.qohBranch,
            qohCompany: prop.qohCompany,
            qohProject: prop.qohProject,
            qty: prop.qty,
            qtyPo: prop.qtyPo,
            rate: prop.rate,
            rateSource: prop.lastPurchaseRate ? 0 : 1,
            remarks: prop.remarks,
            selectedExpenseType: prop.selectedExpenseType,
            selectedSubExpenseType: prop.selectedSubExpenseType,
            uomCode: prop.uomCode,
          }
        : {
            id: prop.isUpdate === true ? "" : prop.id,
            qty: prop.qty,
            uomCode: prop.uomCode,
            qohCompany: prop.qohCompany,
            qohBranch: prop.qohBranch,
            qohProject: prop.qohProject,
            rate: prop.rate,
            rateSource: prop.lastPurchaseRate ? 0 : 1,
            remarks: prop.remarks,
            make: prop.make,
            departmentId: prop.departmentId,
            itemId: prop.itemId,
            isActive: prop.isActive,
            qtyPo: prop.qtyPo,
            status: prop.status,
          }
    );
    let totalAmount = 0;
    // let indentDetails = this.state.indentDetails.filter(
    //   (item) => item.isActive !== 0
    // );

    indentDetails.forEach((prop, key) => {
      if (prop.itemId !== "" && prop.qty !== "" && prop.amount !== "") {
        totalAmount =
          totalAmount + parseFloat(prop.rate) * parseFloat(prop.qty);
      }
    });
    totalAmount.toFixed(2);
    var date = moment(
      this.state.indentFillDetails.dateValue,
      "YYYY-MM-DD",
      true
    ).format("DD-MM-YYYY");
    let id = this.state.locationState.state.rowData.id;
    var recordType =
      this.props.location.pathname === "/create_boq"
        ? "BOQ"
        : this.props.location.pathname === "/create_bom"
        ? "BOM"
        : this.props.location.pathname === "/create_openIndent"
        ? "OPENINDENT"
        : "INDENT";
    var data = {
      indentDate: date,
      id: id,
      indentSquenceNo: this.state.locationState.state.rowData.indentSquenceNo,
      fy: localStorage.getItem("acadamicYear"),
      recordType: recordType,
      totalValue: totalAmount.toString(),
      // internalRemarks: this.state.internalRemarks,
      // remarks: this.state.remarks,
      internalRemarks: this.state.indentFillDetails.internalRemarks,
      remarks: this.state.indentFillDetails.remarks,
      make: this.state.indentFillDetails.make,
      source: 0,
      companyId: localStorage.getItem("companyId"),
      branchId: this.state.indentFillDetails.branchValue,
      indentStatus: status,
      categoryId: this.state.indentFillDetails.categoryTypeValue,
      storeId: this.state.indentFillDetails.branchValue,
      projectId: this.state.indentFillDetails.projectValue,
      customerId: this.state.indentFillDetails.clientValue,
      indentDetails: this.props.location.pathname === "/create_indent" ? invoiceList.filter(
        (item) =>
          item.id !== undefined && item.qty !== "" && item.qty !== 0 && item.qty !== "0.000"  && item.isActive !== 0
      ) : invoiceList.filter(
        (item) => item.id !== undefined && item.itemId !== "" && item.qty !== "" && item.isActive !== 0
      ),
      title: this.state.indentFillDetails.title,
      lastModifiedBy: this.state.userDit.userId.toString(),
    };
    if (
      this.state.indentType !== "0" &&
      this.state.indentDetails.length >= 1 &&
      this.state.branchId !== "" &&
      this.state.branchId !== 0 &&
      this.state.projectId !== "" &&
      this.state.projectId !== 0
    ) {
      this.props.UpdateIndent(
        serverApi.UPDATE_INDENT,
        data,
        this.props.history
      );
    } else if (this.state.indentDetails.length < 1) {
      swal("Please Add Atleast One Item..!!");
    } else {
      swal("Please Enter All Mandatory Fields..!!");
    }
  };

  onClickApprove = (indentStatus) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Approve",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willApprove) => {
      if (willApprove) {
        this.onUpdate(indentStatus);
      }
    });
  };
  onClickCancelIndent = (indentStatus) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Cancel",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willCancel) => {
      if (willCancel) {
        this.onUpdate(indentStatus);
      }
    });
  };

  onClickReject = (isRejected) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Reject",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willreject) => {
      if (willreject) {
        this.onUpdate(isRejected);
      }
    });
  };
  onClickClose = (isClosed) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Close",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willreject) => {
      if (willreject) {
        this.onUpdate(isClosed);
      }
    });
  };

  handleCurrentStep = () => {
    this.setState({
      currentStep: 1,
    });
  };
  handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    this.setState({
      alignment: newAlignment,
    });
    if (newAlignment === "2") {
      this.setState({
        source: "INDENT",
        IndentType: 1,
      });
    }
    if (newAlignment === "3") {
      this.setState({
        source: "OPEN INDENT",
        IndentType: 2,
      });
    }
  };

  onClickNext = () => {
    const { currentStep } = this.state;
    this.setState({
      currentStep: currentStep + 1,
    });
  };

  render() {
    const { steps, currentStep, locationState } = this.state;
    return (
      <div>
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            <div className="iconsBlock">
              <Stepper alternativeLabel activeStep={currentStep}>
                {steps.map((prop) => (
                  <Step key={prop.title}>
                    <StepLabel
                      StepIconComponent={(Iconprops) => {
                        return this.QontoStepIcon(
                          Iconprops,
                          prop.icon,
                          prop.activeIcon,
                          prop.title
                        );
                      }}
                    ></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {currentStep === 1 && (
              <>
                <FillDetails
                  onSelectDep={this.handleSelectChange}
                  indentFillDetails={this.state.indentFillDetails}
                  handleSelectDate={this.handleSelectDate}
                  source={this.state.source}
                  currentStep={this.state.currentStep}
                />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={this.onClickCancel}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="greenButton"
                    handleClick={this.onClicknextBtn}
                    type="sumbit"
                  />
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                {locationState.pathname === "/create_bom" ||
                locationState.pathname === "/create_boq" ? (
                  <BOMBOQLineItems
                    indentFillDetails={this.state.indentFillDetails}
                    categoryTypeValue={this.state.categoryTypeValue}
                    itemGroupValue={this.state.itemGroupValue}
                    internalRemarks={this.state.internalRemarks}
                    remarks={this.state.remarks}
                    indentWithMultiDepartments={
                      this.state.indentWithMultiDepartments
                    }
                    source={this.state.source}
                    locationState={this.state.locationState}
                  />
                ) : (
                  // this.props.location.state !== undefined ?
                  locationState.pathname === "/create_openIndent"?(
                    <>
                    <OpenIndentLineItems
                      indentFillDetails={this.state.indentFillDetails}
                      categoryTypeValue={this.state.categoryTypeValue}
                      itemGroupValue={this.state.itemGroupValue}
                      products={this.state.products}
                      handleproducts={this.handleproducts}
                      internalRemarks={this.state.internalRemarks}
                      remarks={this.state.remarks}
                      indentWithMultiDepartments={
                        this.state.indentWithMultiDepartments
                      }
                      source={this.state.source}
                      locationState={this.state.locationState}
                    />
                  </>
                  ):(
                  <>
                    <IndentLineItemsUpdate
                      indentFillDetails={this.state.indentFillDetails}
                      categoryTypeValue={this.state.categoryTypeValue}
                      itemGroupValue={this.state.itemGroupValue}
                      products={this.state.products}
                      handleproducts={this.handleproducts}
                      internalRemarks={this.state.internalRemarks}
                      remarks={this.state.remarks}
                      indentWithMultiDepartments={
                        this.state.indentWithMultiDepartments
                      }
                      source={this.state.source}
                      locationState={this.state.locationState}
                    />
                  </>)
                )}
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Back"}
                      className="greenBorderButton"
                      handleClick={this.onClickCancel}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="greenButton"
                    handleClick={this.onClicknextBtn}
                    type="sumbit"
                  />
                </div>
              </>
            )}
            {currentStep === 3 && (
              <>
                {locationState.pathname === "/create_bom" ||
                locationState.pathname === "/create_boq" ? (
                  <BOMBOQPreview
                    indentFillDetails={this.state.indentFillDetails}
                    RowData={
                      locationState.state ? locationState.state.rowData : ""
                    }
                    handleCurrentStep={this.handleCurrentStep}
                    indentWithMultiDepartments={
                      this.state.indentWithMultiDepartments
                    }
                    indentStatusName={this.state.indentStatusName}
                    source={this.state.source}
                  />
                ) : (
                  <IndentPreview
                    indentFillDetails={this.state.indentFillDetails}
                    products={this.state.products}
                    RowData={
                      locationState.state ? locationState.state.rowData : ""
                    }
                    handleCurrentStep={this.handleCurrentStep}
                    indentWithMultiDepartments={
                      this.state.indentWithMultiDepartments
                    }
                    indentStatusName={this.state.indentStatusName}
                    source={this.state.source}
                  />
                )}
                <div className="consoleFormButtonBlock">
                  {this.state.finalApprover && this.state.indentStatus === 3 ? (
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={this.onClickCancel}
                      type="sumbit"
                    />
                  ) : null}
                  {locationState.state !== null &&
                  this.state.indentStatus !== "" &&
                  this.state.indentStatus !== 3 &&
                  this.state.indentStatus !== 4 &&
                  this.state.indentStatus !== 5 &&
                  this.state.indentStatus !== 6 ? (
                    <CustomButton
                      label={"Update"}
                      className="greenButton"
                      handleClick={() => {
                        this.onUpdate("");
                      }}
                    />
                  ) : null}

                  {this.state.indentStatus !== "" &&
                  this.state.indentStatus !== 3 &&
                  this.state.indentStatus !== 4 &&
                  this.state.indentStatus !== 5 &&
                  this.state.indentStatus !== 6 &&
                  this.state.approveStatus == true ? (
                    <CustomButton
                      label={"Reject"}
                      className="rejected"
                      handleClick={() => {
                        this.onClickReject("4");
                      }}
                      type="sumbit"
                    />
                  ) : null}
                  {}
                  {this.state.indentStatus !== "" &&
                  this.state.indentStatus !== 3 &&
                  this.state.indentStatus !== 4 &&
                  this.state.indentStatus !== 5 &&
                  this.state.indentStatus !== 6 &&
                  this.state.approveStatus == true ? (
                    <CustomButton
                      label={"Close"}
                      className="close"
                      handleClick={() => {
                        this.onClickClose("5");
                      }}
                      type="sumbit"
                    />
                  ) : null}
                  {this.state.indentStatus === 3 && (
                    <CustomButton
                      label={"Close"}
                      className="close"
                      handleClick={() => {
                        this.onClickClose("5");
                      }}
                      type="sumbit"
                    />
                  )}
                  {(locationState.pathname === "/create_bom" ||
                    locationState.pathname === "/create_boq") &&
                  locationState.state !== undefined &&
                  this.state.indentStatusName !== "APPROVED" &&
                  this.state.indentStatusName !== "REJECTED" &&
                  this.state.indentStatusName !== "CLOSED" &&
                  this.state.approveStatus == true ? (
                    <CustomButton
                      label={
                        locationState.pathname === "/create_bom"
                          ? "Cancel"
                          : locationState.pathname === "/create_boq"
                          ? "Cancel"
                          : ""
                      }
                      className="greenBorderButton"
                      handleClick={() => {
                        this.onClickCancelIndent("6");
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {this.state.indentStatus !== "" &&
                  this.state.indentStatus !== 3 &&
                  this.state.indentStatus !== 4 &&
                  this.state.indentStatus !== 5 &&
                  this.state.indentStatus !== 6 &&
                  this.state.approveStatus == true ? (
                    <CustomButton
                      label={"Approve"}
                      className="approved"
                      handleClick={() => {
                        this.onClickApprove(this.state.indentStatus);
                      }}
                    />
                  ) : null}

                  {!locationState.state && (
                    <>
                      <CustomButton
                        label={"Back"}
                        className="greenBorderButton"
                        handleClick={this.onClickCancel}
                        type="sumbit"
                      />
                      <CustomButton
                        label={"Cancel"}
                        className="greenBorderButton"
                        handleClick={() => {
                          this.props.history.push("/store_indent");
                        }}
                        type="sumbit"
                      />

                      <CustomButton
                        label={"Create"}
                        className="greenButton"
                        handleClick={this.onConfirm}
                      />
                    </>
                  )}
                  {this.state.indentStatusName === "APPROVED" &&
                  this.state.approveStatus == true ? (
                    locationState.pathname === "/create_boq" ? (
                      ""
                    ) : (
                      <CustomButton
                        label={
                          locationState.pathname === "/create_bom"
                            ? "Cancel BOM"
                            : // : this.props.location.pathname === "/create_boq"
                              // ? "Cancel BOQ"
                              "Cancel Indent"
                        }
                        className="greenBorderButton"
                        handleClick={() => {
                          this.onClickCancelIndent("6");
                        }}
                      />
                    )
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { createIndent } = state.Indent;
  const { indentLineItems,ItemDetailsByPOIdForIndent } = state.IndentReducer;
  const { approval_hierarchy } = state.EmployeeReducer;

  return { createIndent, indentLineItems, approval_hierarchy,ItemDetailsByPOIdForIndent };
};

export default withRouter(
  connect(mapStatetoProps, {
    IndentLineItemsProps,
    getCreateIndent,
    UpdateIndent,
    ApprovalHierarchy,
    getItemDetailsByPoIdForIndent,
  })(CreatePage)
);
