import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import DynamicSelect from "../Dropdown/ProcurementDropdown/DynamicSelect";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';   
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';   
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'; 
import moment from "moment";
import { getBranchList } from "../../store/Global/DropDownApis/actions";
import { getProjectList } from "../../store/Global/DropDownApis/actions";
import { getStatusList } from "../../store/Global/DropDownApis/actions";
import swal from "sweetalert";

class PriceEnquiryFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      Status: null,
      StatusValue: null,
      projectListData: [],
      branchListData: [],
      statusListData: [],
      project: "",
      projctValue: "",
      branch: "",
      branchValue: "",
      fromDate: "",
      toDate: "",
    };
  }

  componentWillReceiveProps(props) {

    if (props.projectList) {
      this.setState({
        projectListData: props.projectList.data,
      });
    }
    if (props.branchList) {
      this.setState({
        branchListData: props.branchList.data,
      });
    }
    if (props.statusList) {
      this.setState({
        statusListData: props.statusList.data,
      });
    }
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "project") {
      this.setState({
        projctValue: selectedValue,
        project: selectedName,
      });
    }
    if (name === "Status") {
      this.setState({
        StatusValue: selectedValue,
        Status: selectedName,
      });
    }

    if (name === "branch") {
      this.setState({
        branchValue: selectedValue,
        branch: selectedName,
      });
    }
  };
  handleSelectDate = (e, name) => {
    if (name === "FromDate") {
      
      let date = moment(e).format()
      let formattedFromDate = date.split("T", 1).toString()

      this.setState({
        fromDate: formattedFromDate
      })
    }

    if (name === "ToDate") {
      let date = moment(e).format()
      let formattedToDate = date.split("T", 1).toString()

      this.setState({
        toDate: formattedToDate
      })
    }
  }

  handleSubmit = () => {
    swal("djkfhdhf")
    var data = {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      project: this.state.project,
      projctValue: this.state.projctValue,
      branch: this.state.branch,
      branchValue: this.state.branchValue,
      Status: this.state.Status,
      StatusValue: this.state.StatusValue,
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid
            spacing={0}
          >
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
              </div>
            </div>
            <Grid item xs={12} className="filterBlockContainer">
              <Box gridColumn="span 3">
                <div className="filterDateBlock">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>From Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.fromDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "FromDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="fromDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>To Date</label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="dateOne"
                          value={this.state.toDate}
                          fullWidth
                          onChange={(e) => this.handleSelectDate(e, "ToDate")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="toDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={this.state.statusListData}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handleSelectChange}
                    selected={this.state.StatusValue}
                      update={this.state.StatusValue ? 1 : 0}
                    name="Status"
                    label="Status"
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={
                      this.state.projectListData &&
                      this.state.projectListData.map((item) => ({
                        label: item.name,
                        value: item.projectId,
                        name: item.name,
                      }))
                    }
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handleSelectChange}
                    selected={this.state.projctValue}
                      update={this.state.projctValue ? 1 : 0}
                    name="project"
                    label="Project"
                  />
                </div>
              </Box>
              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                      arrayOfData={this.state.branchListData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      selected={this.state.branchValue}
                      update={this.state.branchValue ? 1 : 0}
                      name="branch"
                      label="Branch"
                    />
                </div>
              </Box>

              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Search"}
                  className="greenButton"
                  handleClick={this.handleSubmit}
                  type="sumbit"
                />
              </div>
            </Grid>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  const { indentList, indentListColumns } = state.Indent;

  const { branchList, projectList, statusList } = state.DropDownListReducer;
  return { indentList, indentListColumns, projectList, branchList, statusList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getBranchList,
    getProjectList,
    getStatusList,
  })(PriceEnquiryFilter)
);

