import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {
  SPELL_MASTER_LIST,
  ADDITEM_TO_SPELL_MASTER_LIST,
  UPDATE_SPELL_MASTER_LIST,
  PARENT_SPELL,
} from "./actionTypes";
import {
  SpellMasterListSuccessfull,
  addItemToSpellMasterListSuccessfull,
  updateItemToSpellMasterListSuccessfull,
  getParentSpellSuccessfull,
} from "./actions";
import { getList, getListurl, postAdd } from "../../../helpers/Server_Helper";
import swal from "sweetalert";

function* getSpellMasterList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(SpellMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* setAddItemToSpellMasterList({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(addItemToSpellMasterListSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/spell_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    console.log(error);
  }
}
function* updateSpellMasterList({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateItemToSpellMasterListSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/spell_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
      }
    }
  }
}

function* getParentSpellList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getParentSpellSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

export function* watchSpellMasterList() {
  yield takeEvery(SPELL_MASTER_LIST, getSpellMasterList);
  yield takeEvery(ADDITEM_TO_SPELL_MASTER_LIST, setAddItemToSpellMasterList);
  yield takeEvery(UPDATE_SPELL_MASTER_LIST, updateSpellMasterList);
  yield takeEvery(PARENT_SPELL, getParentSpellList);
}

function* SpellMasterListSaga() {
  yield all([fork(watchSpellMasterList)]);
}

export default SpellMasterListSaga;
