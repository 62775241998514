import { Component } from "react";
import { connect } from "react-redux";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import { withRouter } from "react-router-dom";
import { JuteConversion } from "../../../store/Jute/JuteConversion/actions";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";

class JuteConversionIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      startIndex: "",
      lastIndex: "",
      JuteConversionContent: [],
    };
  }
  componentDidMount() {
    const data = {
      fromDate: "",
      companyId: localStorage.getItem("companyId"),
      acYear: localStorage.getItem("acadamicYear"),
    };
    this.props.JuteConversion(
      serverApi.JUTE_CONVERSION_LIST,
      data,
      this.props.history
    );
    showSpinner();
  }
  componentWillReceiveProps(props) {
    if (props.JuteConversion) {
      this.setState({
        JuteConversionContent: props.JuteConversionData.data,
      });
      hideSpinner();
    }
  }
  handleSearch = (searchData) => {
    const data = {
      fromDate: searchData.fromDate,
      companyId: localStorage.getItem("companyId"),
      acYear: localStorage.getItem("acadamicYear"),
    };
    this.props.JuteConversion(
      serverApi.JUTE_CONVERSION_LIST,
      data,
      this.props.history
    );
    showSpinner();
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.JuteConversionContent}
          mcolumns={Columns}
          status={true}
          micon={GridArrowIcon}
          actions={true}
          filter_form={"JuteConversionFilter"}
          handleSearch={this.handleSearch}
          url="/create_jute_gate_entry"
        />
      </>
    );
  }
}
// machine master header
const Columns = [
  {
    Header: "JC No",
    accessor: "jcNo",
    hidden: false,
  },
  {
    Header: "JC Date",
    accessor: "jcDate",
    hidden: false,
  },
  {
    Header: "Unit Conversion",
    accessor: "unitConversion",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "status",
    hidden: false,
  },
];
const mapStatetoProps = (state) => {
  const { JuteConversionData } = state.JuteConversionReducer;
  return { JuteConversionData };
};

export default withRouter(
  connect(mapStatetoProps, {
    JuteConversion,
  })(JuteConversionIndex)
);
