import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextFieldNormal from "../../TextField/TextFieldNormal";

class WorkerCategoryMasterFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      cataDesc:null,
      cataCode:null,
    };
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };
 
  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "cataDesc") {
      this.setState({
        cataDesc: event,
      });
    }
    if (key === "cataCode") {
        this.setState({
            cataCode: event,
        });
      }
  };
  handleSubmit = () => {
    const data = {
        cataDesc:this.state.cataDesc === ""?null:this.state.cataDesc,
        cataCode:this.state.cataCode===""?null:this.state.cataCode,
    };
    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };
  render() {
    return (
      <>
        <Box>
          <Button onClick={this.handleClickOpen}>
            <Tooltip title="Filter">
              <FilterListOutlinedIcon />
            </Tooltip>
          </Button>
          <Dialog
            open={this.state.open}
            PaperProps={{
              sx: {
                position: " fixed",
                top: 0,
                right: 0,
                margin: 0,
                bottom: 0,
                maxHeight: "100vh",
              },
            }}
            keepMounted
            onClose={this.handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <Grid spacing={0}>
              <div className="consoleTextFieldBlock">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div className="filterBlockHeader">
                    <FilterListOutlinedIcon />
                    <span>Filter</span>
                  </div>
                </div>
              </div>
              <Grid item xs={12} className="filterBlockContainer">
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Category Code"
                      value={this.state.cataCode}
                      onChange={this.onhandlechange("cataCode")}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Category Name"
                      value={this.state.cataDesc}
                      onChange={this.onhandlechange("cataDesc")}
                    />
                  </div>
                </Box>
           
                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Search"}
                    className="greenButton"
                    handleClick={this.handleSubmit}
                    type="sumbit"
                  />
                </div>
              </Grid>
            </Grid>
          </Dialog>
        </Box>
      </>
    );
  }
}

const mapStatetoProps = (state) => {

};

export default withRouter(connect(mapStatetoProps, {
  
})(WorkerCategoryMasterFilter));
