import React, { Component } from "react";
import CustomButton from "../../../components/Buttons/Buttons";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi, getTenantId, serverVars } from "../../../helpers/Consts";
import { getIndentPdf, getFiles } from "../../../store/Purchase/Indent/actions";
import { getFileDownload } from "../../../helpers/server.js";
import { InwardLineItemsProps } from "../../../store/Purchase/Inward/actions";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Grid } from "@mui/material";
import moment from "moment";
import { limitDecimals } from "../../../Utilities/helper";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import { isEmpty } from "../../../Utilities/helper";

class BillPassPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      date: moment().format("DD-MM-YYYY"),
      currentStep: 1,
      companyLogo: "",
      source: "",
      Inward_LineItems: [],
      supportDocsList: [],
      challandocsList: [],
      invoicedocsList: [],
      RowData: "",
      supplierName: "",
      SequenceNo: "",
      TodayDate: moment().format(),
      locationState: "",
      roundOffValue: this.props.InwardFillDetails.roundOffValue,
      tcsTds: this.props.InwardFillDetails.tcsTds,
      vehicleNumber: this.props.InwardFillDetails.vehicleNumber,
    };
  }
  componentDidMount() {
    this.setState({
      locationState: this.props.location,
    });
    let companyData = JSON.parse(localStorage.getItem("authUser"));
    let companyId = localStorage.getItem("companyId");
    companyData.companyDetails.map((p) => {
      let id = p.value;
      if (companyId === id.toString()) {
        let companyName = p.label.split("-");
        this.setState({
          companyName: companyName[1],
          address1: p.address1,
          companyLogo: p.companyLogo,
          gstNo: p.gstNo,
          address2: p.address2,
          comName: p.name,
          phoneNo: p.contactNo,
          email: p.emailId,
        });
      }
    });

    if (this.props.location.state) {
      let id = this.props.location.state.rowData.id;
      this.props.getFiles(
        serverApi.GET_FILES +
          "18/" +
          id +
          "/" +
          localStorage.getItem("companyId"),
        this.props.history,
        "18"
      );
      this.props.getFiles(
        serverApi.GET_FILES +
          "2/" +
          id +
          "/" +
          localStorage.getItem("companyId"),
        this.props.history,
        "2"
      );
      this.props.getFiles(
        serverApi.GET_FILES +
          "3/" +
          id +
          "/" +
          localStorage.getItem("companyId"),
        this.props.history,
        "3"
      );
    }
    if (this.props.Inward_LineItems) {
      let inwardLineitems = this.props.Inward_LineItems.filter(
        (item) => item.departmentId !== ""
      );
      this.setState({
        Inward_LineItems: inwardLineitems,
      });
    }
    if (this.props.location) {
      if (this.props.location.state !== undefined) {
        if (this.props.location.state.rowData) {
          this.setState({
            RowData: this.props.location.state.rowData,
          });
          if (this.props.location.pathname === "/create_material_inspection") {
            this.setState({
              RowData: this.props.location.state.rowData,
              supplierName: this.props.location.state.rowData.supplierName,
              SequenceNo: this.props.location.state.rowData.inwardSequenceNo,
            });
          }
          if (this.props.location.pathname === "/create_WorkReport") {
            this.setState({
              RowData: this.props.location.state.rowData,
              supplierName: this.props.location.state.rowData.supplierName,
              SequenceNo: this.props.location.state.rowData.inwardSequenceNo,
            });
          }
          if (this.props.location.pathname === "/create_inward") {
            this.setState({
              RowData: this.props.location.state.rowData,
              supplierName: this.props.location.state.rowData.supplierName,
              SequenceNo: this.props.location.state.rowData.inwardSequenceNo,
            });
          }
          if (this.props.location.pathname === "/create_store_bill_pass") {
            this.setState({
              RowData: this.props.location.state.rowData,
              supplierName: this.props.location.state.rowData.supplierName,
              SequenceNo: this.props.location.state.rowData.billpassNumber,
            });
          }
          if (this.props.location.pathname === "/Create_store_receipt") {
            this.setState({
              RowData: this.props.location.state.rowData,
              supplierName: this.props.location.state.rowData.supplierName,
              SequenceNo: this.props.location.state.rowData.storeReceiptNo,
            });
          }
        }
      }
      if (this.props.location.pathname === "/create_inward") {
        this.setState({
          source: "INWARD RECEIPT",
        });
      }
    }
  }

  componentWillReceiveProps(props) {
    if (props.filesofSuppdocs) {
      if (props.filesofSuppdocs.data) {
        let filesList = props.filesofSuppdocs.data;
        this.setState({
          supportDocsList: filesList.data,
        });
      }
    }
    if (props.filesofInvoice) {
      if (props.filesofInvoice.data) {
        let filesList = props.filesofInvoice.data;
        this.setState({
          invoicedocsList: filesList.data,
        });
      }
    }
    if (props.filesofChallan) {
      if (props.filesofChallan.data) {
        let filesList = props.filesofChallan.data;
        this.setState({
          challandocsList: filesList.data,
        });
      }
    }
  }
  getFilesDownload = (fileUploadId, fileName, fileExtension) => {
    let url = serverVars.SECURITY_API + "files/downloadfile/" + fileUploadId;
    getFileDownload(url, fileName, fileExtension);
  };
  handleEdit = () => {
    this.props.handleCurrentStep(this.state.currentStep);
  };
  handlePdf = () => {
    let Id = this.state.RowData.id;
    // this.props.getFileDownload(serverApi.DOWNLOAD_PDF+indentId+'/'+this.state.userDit.userId)
    let taskId = 0;
    if (this.props.location.pathname === "/create_material_inspection") {
      taskId = 4;
    }
    if (this.props.location.pathname === "/create_WorkReport") {
      taskId = 38;
    }
    if (this.props.location.pathname === "/create_inward") {
      taskId = 34;
    }
    if (this.props.location.pathname === "/create_store_bill_pass") {
      taskId = 7;
    }
    if (this.props.location.pathname === "/Create_store_receipt") {
      taskId = 3;
    }

    fetch(
      serverApi.DOWNLOAD_PDF +
        Id +
        "/" +
        this.state.userDit.userId +
        "/" +
        taskId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          "X-TenantId": getTenantId(window.location.hostname),
          Authorization:
            localStorage.getItem("token_type") +
            localStorage.getItem("access_token"),
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          this.state.SequenceNo + "_" + this.state.supplierName + ".pdf"
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  convertNumberToWords(amount) {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = "";
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakh ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  }

  withDecimal(n) {
    if (n !== undefined) {
      var nums = n.toString().split(".");
      var whole = this.convertNumberToWords(nums[0]);

      if (nums.length == 2) {
        if (parseInt(nums[1]) > 0) {
          return whole + "Rupees only";
        } else {
          return whole + "Rupees only";
        }
      } else {
        return whole + "Rupees only";
      }
    }
  }
  render() {
    let debitedAmount = 0;
    let tcsAmount = 0;
    var totalAmount = 0;
    var totalIgst = 0;
    var totalCgst = 0;
    var totalSgst = 0;
    var IndividualAmount = 0;
    var totalCgstValue = 0;
    var roundOffValue = 0;
    var netTotalWithRoundOff = 0;

    if (this.state.Inward_LineItems) {
      var filteredproducts = this.state.Inward_LineItems.filter((item) => {
        if (item.departmentId !== "" && item.isActive !== 0) {
          return true;
        } else {
          return false;
        }
      });

      filteredproducts.forEach((data) => {
        if (data.approvedQty && data.rate) {
          totalAmount = totalAmount + data.approvedQty * data.rate;
          console.log(data.rejectedQty, data.debitnoteValue);
          if(Number(data.rejectedQty >0) && Number(data.rejectedQty !==null)||((data.debitnoteValue>=0)&&(data.debitnoteValue !=null))){
            debitedAmount = debitedAmount + data.debitnoteValue;
          }
          if (Number(data.discountMode !== null) && data.discountMode !== "") {
            let amount = Number(data.approvedQty) * Number(data.rate);
            if (Number(data.discountMode) === 1) {
              if (
                Number(data.discountPercentage) !== 0 &&
                Number(data.discountPercentage) !== undefined &&
                Number(data.discountPercentage) !== "" &&
                Number(data.discountPercentage) !== ""
              ) {
                IndividualAmount = amount - Number(data.discountPercentage);
                totalAmount = totalAmount - Number(data.discountPercentage);
              }
            } else if (data.discountMode === 2) {
              if (
                Number(data.discountPercentage) !== 0 &&
                Number(data.discountPercentage) !== undefined &&
                Number(data.discountPercentage) !== "" &&
                Number(data.discountPercentage) !== null
              ) {
                let disAmount =
                  (amount * Number(data.discountPercentage)) / 100;
                IndividualAmount = amount - disAmount;
                totalAmount = totalAmount - disAmount;
              }
            }
          }
        } 

        if (Number(this.props.InwardFillDetails.taxType) === 3) {
          totalIgst = 0;
          totalCgst = 0;
          totalSgst = 0;
        } else {
          if (this.props.InwardFillDetails.taxType === 1) {
            let amountWithDiscount = 0;
            if (
              data.discountMode !== undefined &&
              data.discountMode !== "" &&
              data.discountMode !== null
            ) {
              if (data.discountMode === 1) {
                amountWithDiscount =
                  data.approvedQty * data.rate -
                  Number(data.discountPercentage);
                totalIgst =
                  totalIgst + (amountWithDiscount * data.taxPercentage) / 100;
              } else if (data.discountMode === 2) {
                amountWithDiscount =
                  (data.approvedQty *
                    data.rate *
                    Number(data.discountPercentage)) /
                  100;
                amountWithDiscount =
                  data.approvedQty * data.rate - amountWithDiscount;
                totalIgst =
                  totalIgst + (amountWithDiscount * data.taxPercentage) / 100;
              } else {
                amountWithDiscount =
                  data.approvedQty * data.rate - amountWithDiscount;
                totalIgst =
                  totalIgst + (amountWithDiscount * data.taxPercentage) / 100;
              }
            } else {
              totalIgst =
                totalIgst +
                (data.approvedQty * data.rate * Number(data.taxPercentage)) /
                  100;
            }
          } else {
            let amountWithDiscount = 0;
            if (
              data.discountMode !== undefined &&
              data.discountMode !== "" &&
              data.discountMode !== null
            ) {
              if (data.discountMode === 1) {
                amountWithDiscount =
                  data.approvedQty * data.rate -
                  Number(data.discountPercentage);
                totalCgst =
                  totalCgst +
                  (amountWithDiscount * data.taxPercentage) / 100 / 2;
                totalSgst =
                  totalSgst +
                  (amountWithDiscount * data.taxPercentage) / 100 / 2;
              } else if (data.discountMode === 2) {
                amountWithDiscount =
                  (data.approvedQty *
                    data.rate *
                    Number(data.discountPercentage)) /
                  100;
                amountWithDiscount =
                  data.approvedQty * data.rate - amountWithDiscount;
                totalCgst =
                  totalCgst +
                  (amountWithDiscount * data.taxPercentage) / 100 / 2;
                totalSgst =
                  totalSgst +
                  (amountWithDiscount * data.taxPercentage) / 100 / 2;
              } else {
                amountWithDiscount =
                  data.approvedQty * data.rate - amountWithDiscount;
                totalCgst =
                  totalCgst +
                  (amountWithDiscount * data.taxPercentage) / 100 / 2;
                totalSgst =
                  totalSgst +
                  (amountWithDiscount * data.taxPercentage) / 100 / 2;
              }
            } else {
              amountWithDiscount = data.approvedQty * data.rate;
              totalCgst =
                totalCgst + (amountWithDiscount * data.taxPercentage) / 100 / 2;
              totalSgst =
                totalSgst + (amountWithDiscount * data.taxPercentage) / 100 / 2;
            }
          }
        }
      });
    }

    var netTotal = totalAmount + totalCgst + totalIgst + totalSgst;

    this.props.InwardFillDetails.netTotal = netTotal;
    this.props.InwardFillDetails.invoiceAmount = netTotal;

    if (this.props.InwardFillDetails.tcsPercentageValue) {
      tcsAmount =
        (totalAmount * this.props.InwardFillDetails.tcsPercentageValue) / 100;
      tcsAmount = tcsAmount.toFixed(2);
      netTotal = netTotal + Number(tcsAmount);
    }
    if (tcsAmount > 0) {
      netTotal = netTotal.toFixed(2);
      let SplittcsAmount = netTotal.split(".");
      netTotal = SplittcsAmount[0];
      if (SplittcsAmount[1] <= 49) {
        netTotal = Number(SplittcsAmount[0]) + 0;
      } else {
        netTotal = Number(SplittcsAmount[0]) + 1;
      }
    }
    if (
      this.state.roundOffValue !== null &&
      this.state.roundOffValue !== undefined &&
      this.state.roundOffValue !== ""
    ) {
      roundOffValue = this.state.roundOffValue;
    }

    if (netTotal !== null || netTotal !== undefined || netTotal !== "") {
      netTotalWithRoundOff = netTotal + roundOffValue;

      var convertion = this.withDecimal(
        Math.round(this.props.InwardFillDetails.totalAmount)
      );
    }

    var otherChargesListWithNoTax = [];
    otherChargesListWithNoTax = this.props.Inward_LineItems.filter((item) => {
      if (Number(item.itemGroupId) === 997) {
        return item.isActive !== 0 && Number(item.taxPercentage) === 0;
      }
    });

    var filteredproductsData = filteredproducts.filter((item) => {
      if (item.departmentId !== "" && item.isActive !== 0) {
        if (item.itemGroupId === "997" && item.taxPercentage === 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    });
    return (
      <>
        <div className="previewBlock">
          <div
            style={{ display: "flex", justifyContent: "end", padding: "10px" }}
          >
            {this.state.locationState.state && (
              <CustomButton
                label="Print"
                className="lightBlueButton"
                muIcon={<PrintIcon />}
                handleClick={this.handlePdf}
                type="button"
              />
            )}
            {this.props.InwardFillDetails.bpStatusName === "APPROVED" ||
            this.props.InwardFillDetails.bpStatusName === "REJECTED" ? (
              ""
            ) : (
              <CustomButton
                label="Edit"
                className="lightBlueButton"
                muIcon={<EditIcon />}
                handleClick={this.handleEdit}
                type="button"
              />
            )}
          </div>
          <table
            cellpadding="0"
            cellspacing="0"
            width="90%"
            className="previewTable"
          >
            <tbody>
              <tr>
                <td
                  colspan="3"
                  style={{ borderRight: "none", borderBottom: "none" }}
                >
                  {/* <h3>{this.state.companyName}</h3> */}
                  <img src={this.state.companyLogo} width="100%" alt="" />
                </td>
                <td
                  colspan="10"
                  style={{
                    borderBottom: "none",
                    borderLeft: "none",
                    textAlign: "right",
                  }}
                >
                  <h2 style={{ margin: "0px" }}> {this.state.companyName}</h2>
                  <p style={{ margin: "0px" }}>{this.state.address1}</p>
                  <span>GST Number:{this.state.gstNo}</span>
                </td>
              </tr>
              <tr>
                <td
                  colspan="11"
                  style={{ textAlign: "center", borderTop: "none" }}
                >
                  <h2>BILL PASS</h2>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <b>Bill Pass Number</b>
                </td>
                <td colspan="4">
                  <span>{this.props.InwardFillDetails.billPassNo}</span>
                </td>
                <td colspan="1">
                  <b>Bill Pass Date</b>
                </td>
                <td colspan="6">
                  <span>
                    {moment(this.props.InwardFillDetails.billpassDate).format(
                      "DD-MM-YYYY"
                    )}
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <b>GRN Number:</b>
                </td>
                <td colspan="4">
                  <span>{this.props.InwardFillDetails.SRPrintNO}</span>
                </td>
                <td colspan="1">
                  <b>GRN Date:</b>
                </td>
                <td colspan="6">
                  <span>
                    {moment(this.props.InwardFillDetails.srDate).format(
                      "DD-MM-YYYY"
                    )}
                  </span>
                </td>
              </tr>

              <tr>
                <td colspan="1">
                  <b>Challan Number:</b>
                </td>
                <td colspan="4">
                  <span>{this.props.InwardFillDetails.challanno}</span>
                </td>
                <td colspan="1">
                  <b>Challan Date: </b>
                </td>
                <td colspan="6">
                  <span>
                    {this.props.InwardFillDetails.challannoDate !== ""
                      ? moment(
                          this.props.InwardFillDetails.challannoDate
                        ).format("DD-MM-YYYY")
                      : ""}
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <b>Vehicle Number: </b>
                </td>
                <td colspan="4">
                  <span>{this.props.InwardFillDetails.vehicleNumber}</span>
                </td>
                <td colspan="1">
                  <b>Driver Name: </b>
                </td>
                <td colspan="6">
                  <span>{this.props.InwardFillDetails.driverName}</span>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <b>Invoice date: </b>
                </td>
                <td colspan="4">
                  <span>
                  <span>{moment(this.props.InwardFillDetails.invoiceDate).format("DD-MM-YYYY")}</span>
                  </span>
                </td>
                <td colspan="1">
                  <b>Invoice Received Date: </b>
                </td>
                <td colspan="6">
                  <span>
                    {moment(
                      this.props.InwardFillDetails.invoiceRecvdDate
                    ).format("DD-MM-YYYY")}
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <b>Invoice No: </b>
                </td>
                <td colspan="4">
                  <span>{this.props.InwardFillDetails.invoiceNumber}</span>
                </td>
                <td colspan="1">
                  <b>Payment Due Date: </b>
                </td>
                <td colspan="6">
                  <span>
                    {moment(this.props.InwardFillDetails.paymentDueDate).format(
                      "DD-MM-YYYY"
                    )}
                  </span>
                </td>
              </tr>
              {this.props.location.pathname === "/Create_store_receipt" ? (
                ""
              ) : (
                <>
                  <tr>
                    <td colspan="1">
                      <b>Consignment Number: </b>
                    </td>
                    <td colspan="4">
                      <span>
                        {this.props.InwardFillDetails.consignmentNumber}
                      </span>
                    </td>
                    <td colspan="1">
                      <b>Consignment Date: </b>
                    </td>
                    <td colspan="6">
                      <span>
                        {moment(
                          this.props.InwardFillDetails.consignmentDate
                        ).format("DD-MM-YYYY")}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="1">
                      <b>Supplier: </b>
                    </td>
                    <td colspan="4">
                      <span>{this.props.InwardFillDetails.supplierName}</span>
                    </td>
                    <td colspan="1">
                      <b>PO Number: </b>
                    </td>
                    <td colspan="6">
                      <span>{this.props.InwardFillDetails.poSequence}</span>
                    </td>
                  </tr>
                </>
              )}
              <tr align="center" valign="top">
                <td>
                  <b>Item Code (HSN/SAC)</b>
                </td>
                <td width={"20%"}>
                  <b>Item Name</b>
                </td>
                <td>
                  <b>Inward Qty</b>
                </td>
                <td>
                  <b>Approved Qty</b>
                </td>
                <td>
                  <b>Rejected Qty</b>
                </td>
                <td>
                  <b>Uom</b>
                </td>
                <td>
                  <b>Rate</b>
                </td>
                <td>
                  <b>Discount</b>
                </td>
                <td>
                  <b>Amount</b>
                </td>
                <td>
                  <b>GST Amount</b>
                </td>
              </tr>
              {filteredproductsData.map((data, index) => {
                if (data.isActive !== 0) {
                  return (
                    <tr key={index}>
                      <td>
                        {data.itemGroup ? data.itemGroup : data.itemGroupId}
                        {data.itemCode}
                        <br />
                        HSN Code: {data.hsnCode}
                      </td>
                      <td>
                        {data.itemName}
                        <br />
                        Make: {data.make}
                        <br />
                        Remarks: {data.remarks}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {" "}
                        {parseFloat(Number(data.inwardQty)).toFixed(3)}
                      </td>

                      <td align="right">
                        {parseFloat(Number(data.approvedQty)).toFixed(3)}
                      </td>
                      <td align="right">
                        {parseFloat(Number(data.rejectedQty)).toFixed(3)}
                        <br />
                        {data.reason}
                      </td>
                      <td align="center">
                        {data.uom ? data.uom : data.uomCode}
                      </td>

                      <td align="right">
                        {parseFloat(Number(data.rate)).toFixed(3)}{" "}
                      </td>
                      <td align="right">
                        {data.discountPercentage
                          ? data.discountMode === 1
                            ? parseFloat(data.discountPercentage).toFixed(2)
                            : data.discountPercentage
                          : "0.00"}
                        {data.discountMode && data.discountMode !== 1
                          ? "%"
                          : ""}
                      </td>
                      {/* <td align="right">{parseFloat(data.approvedQty * data.rate).toFixed(2)}</td> */}
                      <td align="right">
                        {parseFloat(
                          Number(data.discountMode === 1)
                            ? Number(data.approvedQty) * Number(data.rate) -
                                Number(data.discountPercentage)
                            : Number(data.approvedQty) * Number(data.rate) -
                                (Number(data.approvedQty) *
                                  Number(data.rate) *
                                  Number(data.discountPercentage)) /
                                  100
                        ).toFixed(2)}
                      </td>
                      <td align="right">
                        {this.props.InwardFillDetails.taxType !== 4
                          ? data.discountMode === 1
                            ? parseFloat(
                                ((data.approvedQty * data.rate -
                                  data.discountPercentage) *
                                  data.taxPercentage) /
                                  100
                              ).toFixed(2)
                            : parseFloat(
                                ((data.approvedQty * data.rate -
                                  data.approvedQty *
                                    data.rate *
                                    (data.discountPercentage / 100)) *
                                  data.taxPercentage) /
                                  100
                              ).toFixed(2)
                          : "0.00"}
                      </td>
                    </tr>
                  );
                }
              })}

              {otherChargesListWithNoTax.map((data, index) => {
                return (
                  <tr key={index}>
                    <td colspan="2">
                      <b>{data.itemName}</b>
                    </td>
                    <td colSpan={10}>
                      <b>
                        {data.discountMode === 0
                          ? parseFloat(
                              Number(data.inwardQty) * Number(data.rate) -
                                Number(data.discountPercentage)
                            ).toFixed(2)
                          : data.discountMode === 1
                          ? parseFloat(
                              Number(data.inwardQty) * Number(data.rate) -
                                Number(data.discountPercentage)
                            ).toFixed(2)
                          : data.discountMode === 2
                          ? parseFloat(
                              Number(data.inwardQty) * Number(data.rate) -
                                (Number(data.discountPercentage) / 100) *
                                  Number(data.inwardQty) *
                                  Number(data.rate)
                            ).toFixed(2)
                          : null}
                      </b>
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td colSpan={2}>
                  <b>Approved Amount</b>
                </td>
                <td colSpan={9}>
                  <b> {parseFloat(Number(totalAmount)).toFixed(2)}</b>
                </td>
              </tr>

              <tr>
                <td colSpan={2}>
                  <b>Debited Value</b>
                </td>
                <td colSpan={9}>
                  <b> {parseFloat(Number(debitedAmount)).toFixed(2)}</b>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <b>IGST</b>
                </td>
                <td colSpan={9}>
                  {/* <b> {parseFloat(Number(totalIgst)).toFixed(2)}</b> */}
                  <b>
                    {" "}
                    {this.props.InwardFillDetails.taxType === 1
                      ? parseFloat(totalIgst).toFixed(2)
                      : "0.00"}
                  </b>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <b>CGST</b>
                </td>
                <td colSpan={9}>
                  {/* <b> {parseFloat(Number(totalIgst)).toFixed(2)}</b> */}
                  <b>
                    {" "}
                    {this.props.InwardFillDetails.taxType === 1
                      ? "0.00"
                      : parseFloat(totalCgst).toFixed(2)}
                  </b>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <b>SGST</b>
                </td>
                <td colSpan={9}>
                  {/* <b> {parseFloat(Number(totalIgst)).toFixed(2)}</b> */}
                  <b>
                    {" "}
                    {this.props.InwardFillDetails.taxType === 1
                      ? "0.00"
                      : parseFloat(totalSgst).toFixed(2)}
                  </b>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <b>
                    {" "}
                    {isEmpty(this.state.tcsTds) || this.state.tcsTds === "N/A"
                      ? "TCS/TDS Amount:"
                      : this.state.tcsTds === "TCS"
                      ? "TCS Amount:"
                      : "TDS Amount:"}
                  </b>
                </td>
                <td colSpan={9}>
                  <b>
                    {" "}
                    {this.props.InwardFillDetails.tcsTds === null ||
                    this.props.InwardFillDetails.tcsTds === "N/A"
                      ? "0.00"
                      : this.props.InwardFillDetails.tcsTds === "TCS"
                      ? limitDecimals(this.props.InwardFillDetails.tcsAmount, 2)
                      : limitDecimals(
                          this.props.InwardFillDetails.tdsAmount,
                          2
                        )}
                  </b>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <b>Net Total</b>
                </td>
                <td colSpan={9}>
                  <b>
                    {" "}
                    {/* {Math.round(Number(totalAmount) + roundOffValue).toFixed(2)} */}
                    <b> {limitDecimals(Math.round(Number(totalAmount, 2)))}</b>
                  </b>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <b>Total</b>
                </td>
                <td colSpan={9}>
                  {/* <b> {parseFloat(Number(totalAmount)).toFixed(2)}</b> */}
                  <b>
                    {limitDecimals(
                      Math.round(this.props.InwardFillDetails.totalAmount, 2)
                    )}
                  </b>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <b>Total Value(in Words)</b>
                </td>
                <td colSpan={9}>
                  <b>{convertion}</b>
                </td>
              </tr>
              <tr>
                <td
                  colspan="12"
                  // align="left"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <b>Status:{this.props.InwardFillDetails.bpStatusName}</b>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  colspan="12"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    borderRight: "none",
                  }}
                >
                  {this.props.locationState.state && (
                    <Grid>
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={2}
                        style={{ padding: "10px" }}
                      >
                        <Box gridColumn="span 4">
                          {this.state.invoicedocsList.length !== 0 && (
                            <span>
                              <b>Invoice Documents</b>
                            </span>
                          )}
                        </Box>
                        <Box gridColumn="span 4">
                          {this.state.supportDocsList.length !== 0 && (
                            <span>
                              <b>Support Documents</b>
                            </span>
                          )}
                        </Box>
                        <Box gridColumn="span 4">
                          {this.state.challandocsList.length !== 0 && (
                            <span>
                              <b>Challan Documents</b>
                            </span>
                          )}
                        </Box>
                      </Box>
                      <Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={2}
                        className="ducumentUploadBlock uploadedContent"
                        style={{ padding: "10px" }}
                      >
                        {this.state.invoicedocsList &&
                          this.state.invoicedocsList.map((item) => (
                            <Box gridColumn="span 4">
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title="Click to download File"
                              >
                                <div
                                  className="documentUploadContent"
                                  onClick={() => {
                                    this.getFilesDownload(
                                      item.fileUploadId,
                                      item.fileName,
                                      item.fileExtension
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div
                                    className="uploadedInfo"
                                    style={{ display: "flex" }}
                                  >
                                    <img
                                      src={TickIcon}
                                      alt=""
                                      className="tickIcon"
                                      height={"20px"}
                                      width={"20px"}
                                    />

                                    <div className="uplodedFileName">
                                      {item.fileName}
                                    </div>
                                  </div>
                                </div>
                              </Tooltip>
                            </Box>
                          ))}
                        {this.state.supportDocsList &&
                          this.state.supportDocsList.map((item) => (
                            <Box gridColumn="span 4">
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title="Click to download File"
                              >
                                <div
                                  className="documentUploadContent"
                                  onClick={() => {
                                    this.getFilesDownload(
                                      item.fileUploadId,
                                      item.fileName,
                                      item.fileExtension
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div
                                    className="uploadedInfo"
                                    style={{ display: "flex" }}
                                  >
                                    <img
                                      src={TickIcon}
                                      alt=""
                                      className="tickIcon"
                                      height={"20px"}
                                      width={"20px"}
                                    />
                                    <div className="uplodedFileName">
                                      {item.fileName}
                                    </div>
                                  </div>
                                </div>
                              </Tooltip>
                            </Box>
                          ))}
                        {this.state.challandocsList &&
                          this.state.challandocsList.map((item) => (
                            <Box gridColumn="span 4">
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title="Click to download File"
                              >
                                <div
                                  className="documentUploadContent"
                                  onClick={() => {
                                    this.getFilesDownload(
                                      item.fileUploadId,
                                      item.fileName,
                                      item.fileExtension
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div
                                    className="uploadedInfo"
                                    style={{ display: "flex" }}
                                  >
                                    <img
                                      src={TickIcon}
                                      alt=""
                                      className="tickIcon"
                                      height={"20px"}
                                      width={"20px"}
                                    />

                                    <div className="uplodedFileName">
                                      {item.fileName}
                                    </div>
                                  </div>
                                </div>
                              </Tooltip>
                            </Box>
                          ))}
                      </Box>
                    </Grid>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Inward_LineItems } = state.InwardReducer;
  const { filesofInvoice, filesofChallan, filesofSuppdocs } =
    state.IndentReducer;
  return {
    Inward_LineItems,
    filesofInvoice,
    filesofChallan,
    filesofSuppdocs,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getFiles,
    getIndentPdf,
    getFileDownload,
    InwardLineItemsProps,
  })(BillPassPreview)
);
