import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import DynamicSelect from "../../../components/Dropdown/Master/DynamicSelectMasters";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { serverApi } from "../../../helpers/Consts";
import {
  getSupplierListMultiselect,
  getStatusList,
  getStateNameList,
} from "../../../store/Global/DropDownApis/actions";
import {
  getVehicleList,
  getAgentDropdownList,
} from "../../../store/MasterDropdownApis/actions";
import TextFieldNormal from "../../TextField/TextFieldNormal";

class InActiveTransporterMasterFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      supplierListMultiselectData: [],
      VehicleListData: [],
      StatusListData: [],
      agentListDropdownData: [],
      statusValue: "",
      stateNameList: [],
      stateValue: "",
      transAddress: "",
      transName: "",
      stateCode: "",
      contact: "",
      email: "",
      stateName:""
    };
  }

  componentDidMount() {
    this.props.getStateNameList(
      serverApi.GET_STATE_NAME_LIST,
      this.props.history
    );
  }
  componentWillReceiveProps(props) {
    if (props.stateNameList) {
      if (props.stateNameList.data) {
        var list = [];
        props.stateNameList.data.map((item) => {
          list.push({
            label: item.label,
            name: item.label,
            value: item.name.split("^", 2)[1],
          });
        });
        this.setState({
          stateNameList: list,
        });
      }
    }
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "Status") {
      this.setState({
        statusValue: selectedValue,
      });
    }

    if (name === "state") {
      this.setState({
        stateValue: selectedValue,
        stateName: selectedValue === undefined ? null : selectedName,
      });
    }
  };

  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "transAddress") {
      this.setState({
        transAddress: event,
      });
    }
    if (key === "transName") {
      this.setState({
        transName: event,
      });
    }

    if (key === "contact") {
      this.setState({
        contact: event,
      });
    }

    if (key === "email") {
      this.setState({
        email: event,
      });
    }

    if (key === "stateCode") {
      this.setState({
        stateCode: event,
      });
    }
  };
  handleSubmit = () => {
    const data = {
      companyId: localStorage.getItem("companyId"),
      isActive: 0,
      transporterName: this.state.transName === "" ? null : this.state.transName,
      transporterAddress: this.state.transAddress === "" ? null : this.state.transAddress,
      stateCode: this.state.stateCode === "" ? null : this.state.stateCode,
      stateName: this.state.stateName === "" ? null : this.state.stateName,
      transporterContact: this.state.contact === "" ? null : this.state.contact,
      transporterEmail: this.state.email === "" ? null : this.state.email,
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleClear = () => {
    this.setState({
      groupCode: "",
      isActive: "",
      itemCode: "",
      itemGroupName: "",
      itemName: "",
    });
  };

  render() {
    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid spacing={0}>
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
                {/* <div>Save View</div> */}
              </div>
            </div>
            <div className="procurementFilter">
              <Grid
                item
                xs={12}
                className="filterBlockContainer juteFilterBlock"
              >
                <Box gridColumn="span 3">
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          value={this.state.transName}
                          placeholder={"Enter here"}
                          label="Transporter Name"
                          caps="OFF"
                          onChange={this.onhandlechange("transName")}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          value={this.state.transAddress}
                          placeholder={"Enter here"}
                          label="Transporter Address"
                          caps="OFF"
                          onChange={this.onhandlechange("transAddress")}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.stateNameList}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handleSelectChange}
                          selected={this.state.stateValue}
                          // update={this.state.statusValue ? 1 : 0}
                          name="state"
                          label="State"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          value={this.state.stateCode}
                          placeholder={"Enter here"}
                          label="State Code"
                          caps="OFF"
                          onChange={this.onhandlechange("stateCode")}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          value={this.state.contact}
                          placeholder={"Enter here"}
                          label="Contact No."
                          caps="OFF"
                          onChange={this.onhandlechange("contact")}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormal
                          value={this.state.email}
                          placeholder={"Enter here"}
                          label="Email Address"
                          caps="OFF"
                          onChange={this.onhandlechange("email")}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                      <div className="consoleTextFieldBlock">
                      </div>
                    </Grid>
                  </Grid>
                </Box>

                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Search"}
                    className="greenButton"
                    handleClick={this.handleSubmit}
                    type="sumbit"
                  />
                </div>
              </Grid>
            </div>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  const { supplierListMultiselect, statusList, stateNameList } =
    state.DropDownListReducer;
  const { VehicleData, agentDropdownList } = state.MasterDropDownListReducer;
  return {
    supplierListMultiselect,
    VehicleData,
    statusList,
    agentDropdownList,
    stateNameList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSupplierListMultiselect,
    getVehicleList,
    getStatusList,
    getAgentDropdownList,
    getStateNameList,
  })(InActiveTransporterMasterFilter)
);
