import {QUALITY_MASTER_LIST,QUALITY_MASTER_LIST_SUCCESSFULL,
  API_ERRORS,
  CREATE_QUALITY_MASTER, CREATE_QUALITY_MASTER_SUCCESSFULL, UPDATE_QUALITY_MASTER_SUCCESSFULL, UPDATE_QUALITY_MASTER,
  QUALITY_MASTER_VIEW_BY_ID,QUALITY_MASTER_VIEW_BY_ID_SUCCESSFULL, JUTE_QUALITIES_LIST, JUTE_QUALITIES_LIST_SUCCESSFULL} from './actionTypes';

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    QualityMasterList: [],
    CreateQualityMaster:[],
    UpdateQualityMaster:[],
    getQualityMasterViewByIdresp: [],
    jute_quality_master_listData:[]
  };

  const QualityMasterReducer = (state = initialState, action) => {
    switch (action.type) {
        case QUALITY_MASTER_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case QUALITY_MASTER_LIST_SUCCESSFULL:
            state = {
              ...state,
              QualityMasterList: action.payload.response,
              loading: true,
            };
            break;
            case CREATE_QUALITY_MASTER:
              state = {
                ...state,
                loading: true,
              };
              break;
            case CREATE_QUALITY_MASTER_SUCCESSFULL:
              state = {
                ...state,
                CreateQualityMaster: action.payload.response,
                loading: true,
              };
              break;
              case UPDATE_QUALITY_MASTER:
              state = {
                ...state,
                loading: true,
              };
              break;
            case UPDATE_QUALITY_MASTER_SUCCESSFULL:
              state = {
                ...state,
                UpdateQualityMaster: action.payload.response,
                loading: true,
              };
              break;
              case QUALITY_MASTER_VIEW_BY_ID:
                state = {
                  ...state,
                  loading: true,
                };
                break;
          
              case QUALITY_MASTER_VIEW_BY_ID_SUCCESSFULL:
                state = {
                  ...state,
                  getQualityMasterViewByIdresp: action.payload.response,
                  loading: false,
                };
                break;
                case JUTE_QUALITIES_LIST:
                  state = {
                    ...state,
                    loading: true,
                  };
                  break;
            
                case JUTE_QUALITIES_LIST_SUCCESSFULL:
                  state = {
                    ...state,
                    jute_quality_master_listData: action.payload.response,
                    loading: false,
                  };
                  break;
              case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default QualityMasterReducer;