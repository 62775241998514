import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import PayRollLineItem from "./PayRollLineItem";
import { getBillNoList } from "../../../store/Accounting/Payments/actions";

class PayRollAddItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowcount: 1,
      show: false,
      checkAll: false,
      companyId: localStorage.getItem("companyId"),
    };
  }
  render() {
    const tableHeaders = [
      {
        dataField: "empCode",
        text: "Employee Code",
        hidden: false,
      },
      {
        dataField: "empName",
        text: "Employee Name",
        hidden: false,
      },
      {
        dataField: "paySchemeName",
        text: "Payscheme Name",
        hidden: false,
      },
    ];

    this.props.headerName.map((item) => {
      var head = {
        dataField: item.dataField,
        hidden: item.hidden,
        id: item.id,
        name: item.name,
        text: item.text,
      };
      return tableHeaders.push(head);
    });
    var mdata = [];
    var rowData = [];
    if (this.props.type) {
      this.props.type.map((row) => {
        var rdata = [];
        let value = 0;
        var dataField = "";
        if (this.props.headerName) {
          let value = 0;
          this.props.headerName.map((item) => {
            dataField = item.dataField;
            let value = 0;
            if (this.props.empPayschemeList) {
              this.props.empPayschemeList.map((p) => {
                if (p.empId == row.empId) {
                  if (p.pccList) {
                    p.pccList.map((val) => {
                      if (val.componentId == item.id) {
                        value = val.value;
                        dataField = item.dataField;
                      }
                    });
                  } else {
                    dataField = item.dataField;
                  }
                }
              });
            }

            var idata = {
              id: item.id,
              code: item.code,
              value: value ? value : 0,
              empId: row.empId,
              dataField_name: dataField,
              hidden: true,
            };
            rdata.push(idata);
          });
        }

        var data = {
          empCode: row.empCode,
          empName: row.empName,
          paySchemeName: row.paySchemeName,
          empId: row.empId,
          rowData: rdata,
        };
        mdata.push(data);
      });
    }
    const fieldsData = [
      { component: "text", text: "Employee Code", dataField: "empCode" },
      { component: "text", text: "Employee Name", dataField: "empName" },
      { component: "text", text: "Payscheme Name", dataField: "paySchemeName" },
    ];

    this.props.headerName.map((prop) => {
      rowData.push({
        component: "Inputadorment",
        dataField: prop.dataField,
        text: prop.dataField,
        type: prop.code,
        id: prop.id,
        code: prop.code,
      });
      return fieldsData.push(rowData);
    });

    var maptabledata = [];
    mdata.map((main) => {
      const arr1 = {
        empCode: main.empCode,
        empId: main.empId,
        empName: main.empName,
        paySchemeName: main.paySchemeName,
      };
      main.rowData.map((sub, index) => {
        return Object.assign(arr1, { [sub.dataField_name]: sub.value });
      });
      return maptabledata.push(arr1);
    });
    return (
      <div>
        <PayRollLineItem
          CalenderDate={this.props.CalenderDate}
          tableHeaders={tableHeaders}
          RowData={maptabledata}
          fieldsData={fieldsData}
          rawData={mdata}
          header={this.props.header}
          delete={this.props.delete}
          products={this.props.products}
          fromDate={this.props.fromDate}
          toDate={this.props.toDate}
          ebId={this.props.ebId}
          hideTableRows={this.props.hideTableRows}
          handleCreatePayRollData={this.props.handleCreatePayRollData}
        />
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { indentList } = state.Indent;
  return { indentList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getBillNoList,
  })(PayRollAddItem)
);
