import {
  DEBIT_CREDIT_LIST,
  DEBIT_CREDIT_LIST_SUCCESSFULL,
  API_ERRORS,
  GET_SUPPLIER_FOR_JUTE_BY_ID,
  GET_SUPPLIER_FOR_JUTE_BY_ID_SUCCESSFULL,
  GET_SR_NO,
  GET_SR_NO_SUCCESSFULL,
  SR_NO_VIEW_BY_ID,
  SR_NO_VIEW_BY_ID_SUCCESSFULL,
  GET_DEBIT_NOTE_BRANCH,
  GET_DEBIT_NOTE_BRANCH_SUCCESSFULL,
  GET_DEBIT_PROJECTS,
  GET_DEBIT_PROJECTS_SUCCESSFULL,
  CLEAR_DEBIT_NOTE_LINE_ITEM,
  DEBIT_NOTE_LINE_ITEM,
  CREATE_DEBIT_NOTE,
  CREATE_DEBIT_NOTE_SUCCESSFULL,
  BALANCE_QUANTITY,
  BALANCE_QUANTITY_SUCCESSFULL,
  SR_NO_VIEW_BY_ID_CLEAR,
  GET_DEBIT_NOTE_BY_ID,
  GET_DEBIT_NOTE_BY_ID_SUCCESSFULL,
  DEBIT_NOTE_HEADER,
  CLEAR_DEBIT_NOTE_HEADER,
  UPDATE_DEBIT_NOTE,
  UPDATE_DEBIT_NOTE_SUCCESSFULL,
  UPLOAD_DEBIT_FILES,
  UPLOAD_DEBIT_FILES_SUCCESSFULL,
  GET_DEBIT_FILES,
  GET_DEBIT_FILES_SUCCESSFULL,
  DELETE_DEBIT_FILES,
  DELETE_DEBIT_FILES_SUCCESSFULL,
} from "./actionTypes";

export const getDebitCreditList = (url, data, history) => {
  return {
    type: DEBIT_CREDIT_LIST,
    payload: { url, data, history },
  };
};
export const DebitCreditListSuccessfull = (data) => {
  return {
    type: DEBIT_CREDIT_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getSupplierForJuteById = (url, history) => {
  return {
    type: GET_SUPPLIER_FOR_JUTE_BY_ID,
    payload: { url, history },
  };
};
export const getSupplierForJuteByIdSuccessfull = (data) => {
  return {
    type: GET_SUPPLIER_FOR_JUTE_BY_ID_SUCCESSFULL,
    payload: data,
  };
};
export const getSrNumber = (url, history) => {
  return {
    type: GET_SR_NO,
    payload: { url, history },
  };
};
export const getSrNumberSuccessfull = (data) => {
  return {
    type: GET_SR_NO_SUCCESSFULL,
    payload: data,
  };
};
export const getSrNoViewById = (url, history) => {
  return {
    type: SR_NO_VIEW_BY_ID,
    payload: { url, history },
  };
};
export const getSrNoViewByIdSuccessfull = (data) => {
  return {
    type: SR_NO_VIEW_BY_ID_SUCCESSFULL,
    payload: data,
  };
};
export const getSrNoViewByIdClear = (data) => {
  return {
    type: SR_NO_VIEW_BY_ID_CLEAR,
    payload: data,
  };
};
export const getDebitNoteBranch = (url, history) => {
  return {
    type: GET_DEBIT_NOTE_BRANCH,
    payload: { url, history },
  };
};
export const getDebitNoteBranchSuccessfull = (data) => {
  return {
    type: GET_DEBIT_NOTE_BRANCH_SUCCESSFULL,
    payload: data,
  };
};
export const getDebitNoteProjects = (url, data, history) => {
  return {
    type: GET_DEBIT_PROJECTS,
    payload: { url, data, history },
  };
};
export const getDebitNoteProjectsSuccessfull = (data) => {
  return {
    type: GET_DEBIT_PROJECTS_SUCCESSFULL,
    payload: data,
  };
};
export const apiErrors = (error) => {
  return {
    type: API_ERRORS,
    payload: error,
  };
};
export const DebitNoteLineItemsProps = (data) => {
  return {
    type: DEBIT_NOTE_LINE_ITEM,
    payload: data,
  };
};
export const ClearDebitNoteLineItemsProps = (data) => {
  return {
    type: CLEAR_DEBIT_NOTE_LINE_ITEM,
    payload: data,
  };
};
export const DebitNoteHeaderProps = (data) => {
  return {
    type: DEBIT_NOTE_HEADER,
    payload: data,
  };
};
export const ClearDebitNoteHeaderProps = (data) => {
  return {
    type: CLEAR_DEBIT_NOTE_HEADER,
    payload: data,
  };
};
export const getCreateData = (url, data, history) => {
  return {
    type: CREATE_DEBIT_NOTE,
    payload: { url, data, history },
  };
};
export const getCreateDataSuccessfull = (data) => {
  return {
    type: CREATE_DEBIT_NOTE_SUCCESSFULL,
    payload: data,
  };
};
export const getBalanceQuantity = (url, data, history) => {
  return {
    type: BALANCE_QUANTITY,
    payload: { url, data, history },
  };
};
export const getBalanceQuantitySuccessfull = (data) => {
  return {
    type: BALANCE_QUANTITY_SUCCESSFULL,
    payload: data,
  };
};
export const getDebitNoteById = (url, history) => {
  return {
    type: GET_DEBIT_NOTE_BY_ID,
    payload: { url, history },
  };
};
export const getDebitNoteByIdSuccessfull = (data) => {
  return {
    type: GET_DEBIT_NOTE_BY_ID_SUCCESSFULL,
    payload: data,
  };
};
export const getUpdateData = (url, data, history) => {
  return {
    type: UPDATE_DEBIT_NOTE,
    payload: { url, data, history },
  };
};
export const getUpdateDataSuccessfull = (data) => {
  return {
    type: UPDATE_DEBIT_NOTE_SUCCESSFULL,
    payload: data,
  };
};
export const UploadDebitFiles = (url, data, inwardId, history) => {
  return {
    type: UPLOAD_DEBIT_FILES,
    payload: { url, data, inwardId, history },
  };
};
export const UploadDebitFilesSuccessfull = (data) => {
  return {
    type: UPLOAD_DEBIT_FILES_SUCCESSFULL,
    payload: data,
  };
};
export const getUplaodedDebitFiles = (url, history) => {
  return {
    type: GET_DEBIT_FILES,
    payload: { url, history },
  };
};
export const getUplaodedDebitFilesSuccessfull = (data) => {
  return {
    type: GET_DEBIT_FILES_SUCCESSFULL,
    payload: data,
  };
};
export const deleteUplaodedDebitFiles = (url, inwardId, history) => {
  return {
    type: DELETE_DEBIT_FILES,
    payload: { url, inwardId, history },
  };
};
export const deleteUplaodedDebitFilesSuccessfull = (data) => {
  return {
    type: DELETE_DEBIT_FILES_SUCCESSFULL,
    payload: data,
  };
};
