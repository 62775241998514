import {
  PAYSLIP_DATA,
  PAYSLIP_DATA_SUCCESSFULL,
  PAYSLIP_LIST,
  PAYSLIP_LIST_SUCCESSFULL,
  PAYSLIP_AVAILABLE_LIST,
  PAYSLIP_AVAILABLE_LIST_SUCCESSFULL,
  PAY_SUMMARY,
  PAY_SUMMARY_SUCCESSFULL,
  PAYSLIP_DUP_DATA,
  PAYSLIP_DUP_DATA_SUCCESSFULL,
  PAYSLIP_DATA_CLEAR
} from "./actionType";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  payslipList: [],
  payslipListColumns: [],
  payslipListItemsList: [],
  payslipData: [],
  payslipData2: [],
  payslipAvailable: [],
  paySummaryList: []
};

const PayslipReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYSLIP_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PAYSLIP_LIST_SUCCESSFULL:
      state = {
        ...state,
        payslipList: action.payload.response,
        loading: true,
      };
      break;
    case PAYSLIP_DATA:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PAYSLIP_DATA_SUCCESSFULL:
      state = {
        ...state,
        payslipData: action.payload.response.data,
        loading: true,
      };
      break;
      case PAYSLIP_DUP_DATA:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PAYSLIP_DUP_DATA_SUCCESSFULL:
      state = {
        ...state,
        payslipData2: action.payload.response.data,
        loading: true,
      };
      break;
    case PAYSLIP_AVAILABLE_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PAYSLIP_AVAILABLE_LIST_SUCCESSFULL:
      state = {
        ...state,
        payslipAvailable: action.payload.response,
        loading: true,
      };
      break;
      case PAY_SUMMARY:
        state = {
          ...state,
          loading: true,
        };
        break;
      case PAY_SUMMARY_SUCCESSFULL:
        state = {
          ...state,
          paySummaryList: action.payload.response,
          loading: true,
        };
        break;  
        case PAYSLIP_DATA_CLEAR:
          state = {
            ...state,
            payslipData: action.payload,
            loading: true,
          };
          break; 
        
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default PayslipReducer;
