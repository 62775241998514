import {
  API_ERRORS,
  CUSTOMER_PAGINATED_LIST,
  CUSTOMER_PAGINATED_LIST_SUCCESSFULL,
  CREATE_CUSTOMER_MASTER,
  CREATE_CUSTOMER_MASTER_SUCCESSFULL,
  CUSTOMER_MASTER_VIEW_BY_ID,
  CUSTOMER_MASTER_VIEW_BY_ID_SUCCESSFULL,
  CUSTOMER_LINE_ITEMS,
  CUSTOMER_MASTER_UPDATE,
  CUSTOMER_MASTER_UPDATE_SUCCESSFULL,
  GET_ALL_FILES,
  GET_ALL_FILES_SUCCESSFULL,
  DELETE_CUSTOMER_FILES,
  DELETE_CUSTOMER_FILES_SUCCESSFULL,
  UPLOAD_CUSTOMER_FILES,
  UPLOAD_CUSTOMER_FILES_SUCCESSFULL,
  GET_ALL_LEDGER_DATA,
  GET_ALL_LEDGER_DATA_SUCCESSFULL,
  ENTITY_TYPE_DATA,
  ENTITY_TYPE_DATA_SUCCESSFULL,
  CUSTOMER_GST_VERIFICATION,
  CUSTOMER_GST_VERIFICATION_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  Customer_Paginated_List: [],
  createCustomerMaster: [],
  customer_update: [],
  CustomerViewById: [],
  GetAllFiles_Data: [],
  delete_customerMaster_Files: [],
  Upload_file: [],
  ledgerGroupsList: [],
  entityTypeList: [],
  getCustometGstData:[],
  Customer_LineItems: [
    {
      customerLineId: 1,
      address: "",
      city: "",
      State: "",
      stateId: "",
      stateCode: "0",
      gstNo: "",
      pincode: "",
      actions: "",
      customerId: "",
      customerLineId: "",
    },
    {
    customerHdr: {
      alternateContactNo: "",
      cinNo: "",
      companyId: "",
      contactNo: "",
      contactPerson: "",
      createdBy: "",
      customerCode: "",
      emailId: "",
      finalStatus: "",
      id: null,
      internalCompanyId: null,
      ledgerGroup: null,
      name: "",
      openningBalance: null,
      panNo: "",
      mappedBranchIds: [],
      createdOn: "",
      PanFile: "",
      PanFileId: "",
      CinFile: "",
      CinFileId: "",
      Time: "",
      ShowInValidText: false,
      showMandatoryField: false,
      PanNumberValidation: false,
      CinNumberValidation: false,
      suppId: "",
      costCenter: "",
      tdsNo: false,
      tdsYes: true,
      entityTypeId:"",
    },
  },
  ],
  error_msg: null,
};

const CustomerListReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_PAGINATED_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CUSTOMER_PAGINATED_LIST_SUCCESSFULL:
      state = {
        ...state,
        Customer_Paginated_List: action.payload.response,
        loading: false,
      };
      break;
    case CREATE_CUSTOMER_MASTER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CREATE_CUSTOMER_MASTER_SUCCESSFULL:
      state = {
        ...state,
        createCustomerMaster: action.payload.response,
        loading: false,
      };
      break;
    case CUSTOMER_LINE_ITEMS:
      state = {
        ...state,
        Customer_LineItems: action.payload,
      };
      break;
    case CUSTOMER_MASTER_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CUSTOMER_MASTER_VIEW_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        CustomerViewById: action.payload.response,
        loading: false,
      };
      break;
    case API_ERRORS:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;
    case CUSTOMER_MASTER_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CUSTOMER_MASTER_UPDATE_SUCCESSFULL:
      state = {
        ...state,
        customer_update: action.payload.response,
        loading: false,
      };
      break;
    case GET_ALL_FILES:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_ALL_FILES_SUCCESSFULL:
      state = {
        ...state,
        GetAllFiles_Data: action.payload.response.data,
        loading: false,
      };
      break;
    case DELETE_CUSTOMER_FILES:
      state = {
        ...state,
        loading: true,
      };
      break;

    case DELETE_CUSTOMER_FILES_SUCCESSFULL:
      state = {
        ...state,
        delete_customerMaster_Files: action.payload.response,
        loading: false,
      };
      break;
    case UPLOAD_CUSTOMER_FILES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPLOAD_CUSTOMER_FILES_SUCCESSFULL:
      state = {
        ...state,
        Upload_file: action.payload.response,
        loading: false,
      };
      break;
    case GET_ALL_LEDGER_DATA:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ALL_LEDGER_DATA_SUCCESSFULL:
      state = {
        ...state,
        ledgerGroupsList: action.payload.response,
        loading: false,
      };
      break;
    case ENTITY_TYPE_DATA:
        state={
          ...state,
          loading:true,
        };
        break;
    case ENTITY_TYPE_DATA_SUCCESSFULL:
        state={
          ...state,
          entityTypeList:action.payload.response,
          loading:false,
        };
        break; 
    case CUSTOMER_GST_VERIFICATION:
      state={
        ...state,
        loading:true,
      };
      break;
    case CUSTOMER_GST_VERIFICATION_SUCCESSFULL:
      state={
        ...state,
        getCustometGstData: action.payload.response,
        loading:false,
      };
      break;      
        
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default CustomerListReducer;
