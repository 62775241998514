import React, { useState, useEffect  } from "react";
import { MultiSelect } from "react-multi-select-component";


const AutoMultiselect = (props) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected(props.selectedValues || []);
  }, [props.selectedValues]);

  const handleSelectionChange = (selectedOptions) => {
    setSelected(selectedOptions);
    props.getOpt(selectedOptions, props.name); // Call the callback function from the main component
  };
  return (
    <div className="autoCompleteDropdownContainerNew" style={{ width: '100%' }}>
        <label>{props.label}<span className="mandotary">{props.mandatory}</span></label>
        <MultiSelect
          options={props.options}
          disabled={props.disable}
          value={selected}
          selected={selected}
          onChange={handleSelectionChange}
          labelledBy="Select"
        />
    </div>
  );
};

export default AutoMultiselect;