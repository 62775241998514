import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import InputAdornment from "../../../components/InputAdorment/InputAdorment";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { serverApi } from "../../../helpers/Consts";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import moment from "moment";
import {
  getBranchList,
  getMukamList,
} from "../../../store/Global/DropDownApis/actions";
import {
  getVehicleTypeList,
  getSupplierByMukamIdList,
  getBrokerBySupplierIdList,
} from "../../../store/MasterDropdownApis/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getJutePOHeader ,GetIndentData,juteIndentViewByIdForPO,GetPoLineItems} from "../../../store/Jute/JutePurchase/actions.js";
import { isEmpty } from "../../../Utilities/helper.js";
const JutePoFillDetails = (props) => {
  const [userDit, setUserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [branchListData, setBranchListData] = useState([]);
  const [approvedIndentsListData, setapprovedIndentsListData] = useState([]);
  const [mukamListData, setMukamListData] = useState([]);
  const [vehicleTypeListData, setVehicleTypeList] = useState([]);
  const [SupplierByMukamListData, setSupplierByMukamList] = useState([]);
  const [brokerList, setBrokerList] = useState([]);
  const [askmodel, setAskModel] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [fileName, setFileName] = useState("");
  const [borkerageDispay, setBrokerageDisplay] = useState("none");

  const [HeaderData, setHeaderData] = useState({});
  const [LineItems, setLineItems] = useState([]);
  const dispatch = useDispatch();
  let history = useHistory();
  const [locationState] = useState(useLocation());
  const { JutePoHeader, JutePoLineItems, getIndentData } = useSelector(
    (state) => state.JutePurchaseReducer
  );
  const { branchList, mukamList, approvedIndentsList } = useSelector(
    (state) => state.DropDownListReducer
  );
  const { vehicleTypeList, SupplierByMukamList, BrokerBySupplierId } =
    useSelector((state) => state.MasterDropDownListReducer);
  const { Upload_File_List } = useSelector((state) => state.UploadFileReducer);
  const { files_List } = useSelector((state) => state.IndentReducer);

  useEffect(() => {
    setHeaderData(JutePoHeader);
    setLineItems(JutePoLineItems);
  }, [JutePoHeader, JutePoLineItems]);
  useEffect(() => {
    dispatch(
      getBranchList(
        serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
        history
      )
    );

    dispatch(
      getMukamList(
        serverApi.MUKAM_GET_LIST + localStorage.getItem("companyId"),
        history
      )
    );

    const data = {
      cipher: userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      userId: userDit.userId,
    };
    dispatch(
      getVehicleTypeList(serverApi.VEHICLE_TYPE_LIST_DROPDOWN, data, history)
    );
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (branchList) {
      if (branchList.data) {
        let list = branchList.data.filter((props) => {
          if (props.value !== 0) {
            return props;
          }
        });
        setBranchListData(list);
      }
    }

    if (mukamList) {
      if (mukamList.data) {
        let list = mukamList.data.filter((props) => {
          if (props.value !== 0) {
            return props;
          }
        });
        setMukamListData(list);
      }
    }
    if (files_List) {
      if (files_List.data) {
        let filesList = files_List.data;
        setFilesList(filesList.data);
      }
    }

    if (vehicleTypeList.data) {
      var list = [];
      vehicleTypeList.data.map((prop) => {
        list.push({
          value: prop.id,
          label: prop.vehicleType+"_"+ prop.weight,
          name: prop.vehicleType+"_"+ prop.weight,
        });
      });
      setVehicleTypeList(list);
    }
    if (getIndentData) {
      if (getIndentData.data) {
        if(getIndentData.data.length !==0){
          let ListData=[]
          getIndentData.data.map((item)=>{
            ListData.push({
              value:item.value,
              name:item.value,
              label:item.value
            })
          })
          let list = ListData.filter((props) => {
            if (props.value !=="0") {
              return props;
            }
          });
          setapprovedIndentsListData(list);
        }
      }
    }
    if (SupplierByMukamList) {
  
      if (SupplierByMukamList.data) {
        let FilteredList = SupplierByMukamList.data.filter((props) => {
          if (props.value !== "0") {
            return props;
          }
        });
        if(!isEmpty(HeaderData.mukam)){
          setSupplierByMukamList(FilteredList);
        }else{
          setSupplierByMukamList([]);
        }
      }
    }
    if (BrokerBySupplierId) {
      if (BrokerBySupplierId.data) {
        setBrokerList(BrokerBySupplierId.data);
      }
    }
  }, [
    branchList,
    BrokerBySupplierId,
    SupplierByMukamList,
    vehicleTypeList,
    files_List,
    mukamList,
    branchList,
    getIndentData
  ]);
  const handleScroll = () => {
    setDropdownOpen(true);
  };
  const handleMenuOpen = () => {
    setDropdownOpen(false);
  };
  const handleSelectDate = (e, name) => {
    if (name === "poDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        poDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        poDate: Date,
      };
      dispatch(getJutePOHeader(UpdatedRec));
    }
  };
  const handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "branchId") {
      setHeaderData((prev) => ({
        ...prev,
        branchId: selectedValue,
        branchname: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        branchId: selectedValue,
        branchName: selectedName,
      };
      dispatch(getJutePOHeader(UpdatedRec));
      dispatch(GetIndentData(serverApi.GET_APPROVEDINDENTS_FOR_JUTE_PO+"/j/"+localStorage.getItem("companyId")+"/"+localStorage.getItem("acadamicYear")+"/"+selectedValue+"/999", history));
    }

    if (name === "indentNum") {
      setHeaderData((prev) => ({
        ...prev,
        indentNum: selectedName,
        id: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        indentNum: selectedName,
        id: selectedName,
      };
      dispatch(getJutePOHeader(UpdatedRec));

      dispatch(juteIndentViewByIdForPO(serverApi.JUTE_INDENT_VIEWBY_ID+JSON.parse(localStorage.getItem("authUser")).userId+"/company/"+localStorage.getItem("companyId")+"/year/"+localStorage.getItem("acadamicYear")+'?indentId='+selectedValue,history))

    }
    if (name === "juteUnit") {
      if(HeaderData.JutePoType =="WITHINDENT" && HeaderData.juteUnit !==selectedName){
        setAskModel(true)
        setHeaderData((prev) => ({
          ...prev,
          juteUnit: selectedName,
        }));
        const UpdatedRec = {
          ...HeaderData,
          juteUnit: selectedName,
        };
        dispatch(getJutePOHeader(UpdatedRec));
      }else if(locationState.state !==undefined && HeaderData.juteUnit !==selectedName){
        setAskModel(true)
        setHeaderData((prev) => ({
          ...prev,
          juteUnit: selectedName,
        }));
        const UpdatedRec = {
          ...HeaderData,
          juteUnit: selectedName,
        };
        dispatch(getJutePOHeader(UpdatedRec));
      }else{
        setHeaderData((prev) => ({
          ...prev,
          juteUnit: selectedName,
        }));
        const UpdatedRec = {
          ...HeaderData,
          juteUnit: selectedName,
        };
        dispatch(getJutePOHeader(UpdatedRec));
      }
    }

    if (name === "vehicleTypeId") {
      setHeaderData((prev) => ({
        ...prev,
        vehicleTypeId: selectedValue,
        vehicleTypeName:selectedName
      }));
      const UpdatedRec = {
        ...HeaderData,
        vehicleTypeId: selectedValue,
        vehicleTypeName:selectedName

      };
      dispatch(getJutePOHeader(UpdatedRec));
    }

    if (name === "channelCode") {
      setHeaderData((prev) => ({
        ...prev,
        channelCode: selectedValue,
      }));
      const UpdatedRec = {
        ...HeaderData,
        channelCode: selectedValue,
      };
      dispatch(getJutePOHeader(UpdatedRec));
    }

    if (name === "mukam") {
      setHeaderData((prev) => ({
        ...prev,
        mukam: selectedValue,
        mukamName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        mukam: selectedValue,
        mukamName: selectedName,
      };
      dispatch(getJutePOHeader(UpdatedRec));
      dispatch(
        getSupplierByMukamIdList(
          serverApi.GET_SUPPLIER_BY_MUKAM_ID +
            selectedValue +
            "/company/" +
            localStorage.getItem("companyId"),
          props.history
        )
      );
    }

    if (name === "supplierId") {
      setHeaderData((prev) => ({
        ...prev,
        supplierId: selectedValue,
        suppName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        supplierId: selectedValue,
        suppName: selectedName,
      };
      dispatch(getJutePOHeader(UpdatedRec));

      dispatch(
        getBrokerBySupplierIdList(
          serverApi.GET_BROKER_BY_SUPPLIER + selectedValue,
          props.history
        )
      );
    }
    if (name === "brokerId") {
      setHeaderData((prev) => ({
        ...prev,
        brokerId: selectedValue,
        brokerName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        brokerId: selectedValue,
        brokerName: selectedName,
      };
      dispatch(getJutePOHeader(UpdatedRec));
    }
  };
  const OnhandleChange = (key) => (name, event) => {
    if (key === "creditTerm") {
      setHeaderData((prev) => ({
        ...prev,
        creditTerm: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        creditTerm: event,
      };
      dispatch(getJutePOHeader(UpdatedRec));
    }
    if (key === "deliveryTimeline") {
      setHeaderData((prev) => ({
        ...prev,
        deliveryTimeline: event,
        exceptedDate: moment().add(event, "days").format("DD-MM-YYYY"),
      }));
      const UpdatedRec = {
        ...HeaderData,
        deliveryTimeline: event,
        exceptedDate: moment().add(event, "days").format("DD-MM-YYYY"),
      };
      dispatch(getJutePOHeader(UpdatedRec));
    }
  };
  const handleChange = (key) => (event) => {
    if (key === "penality") {
      setHeaderData((prev) => ({
        ...prev,
        penality: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        penality: event,
      };
      dispatch(getJutePOHeader(UpdatedRec));
    }
    if (key === "vehicleQuantity") {
      const inputValue = event.trim(); 
      if (inputValue === "") {
        setHeaderData((prev) => ({
          ...prev,
          vehicleQuantity: "", 
        }));
        return; // Exit function early
      }
    
      const parsedValue = parseFloat(inputValue);
      if (isNaN(parsedValue) || parsedValue <= 0 || Math.floor(parsedValue) !== parsedValue) {
        return; 
      }
      setHeaderData((prev) => ({
        ...prev,
        vehicleQuantity: parsedValue,
      }));

      const UpdatedRec = {
        ...HeaderData,
        vehicleQuantity: parsedValue,
      };
      dispatch(getJutePOHeader(UpdatedRec));
    } 
    if (key === "brokeragePercentage") {
      setHeaderData((prev) => ({
        ...prev,
        brokeragePercentage: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        brokeragePercentage: event,
      };
      dispatch(getJutePOHeader(UpdatedRec));
    }
    if (key === "brokerageRate") {
      setHeaderData((prev) => ({
        ...prev,
        brokerageRate: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        brokerageRate: event,
      };
      dispatch(getJutePOHeader(UpdatedRec));
    }
    
  };
  const onYesNo = (event) => {
    setAskModel(false);
    if (event.target.value == "Yes") {
      var object = [
        {
          "id": 0,
          "itemId": "",
          "itemDesc": "",
          "qualityCode": "",
          "qualityCodeName":"",
          "unitId": "",
          "quantity": "",
          "rate": "",
          "allowableMoisturePercentage": "",
          "tax": 0,
          "valueWithoutTax": "",
          "cTax": 0,
          "sTax": 0,
          "iTax": 0,
          "bale": 0,
          "loose": 0,
          "poId": null,
          "deptId": "",
          "valueWithTax": 0,
          "indentId": "",
          "actualQuantity": "",
          "itemGroupId": "",
          "discount": 0,
          "taxId": 0,
          "taxPercentage": 0,
          "deptName": "",
          "juteQuality": "",
          "hsnCode": "",
          "marka": "",
          "cropYear": "",
          "gatePoPlaced": 0,
          "remaingQuantity": 0,
          "lastPurchaseRate": null,
          "lastPurchaseDate": null,
          "lastPurchaseSupp": null,
          "stRemQty": 0,
          "pendingAtRcpt": 0,
          "itemGroupName": "",
          "stock":0,
          "amount":"",
          "QuantityListData":[],
          "uom":""
        },
      ];
      dispatch(GetPoLineItems(object))
    }
  };
  const UploadDocuments = (key) => (e) => {
    props.getFiles(
      serverApi.GET_FILES +
        "12/" +
        props.headerData.hdrId +
        "/" +
        localStorage.getItem("companyId"),
      props.history
    );
    e.preventDefault();
    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 12);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", props.headerData.hdrId);
    formData.append("createdBy", userDit.userId);
    formData.append("fileCompanyId", localStorage.getItem("companyId"));
    setFileName(file.name);
    props.postUploadFileList(serverApi.UPLOADFILE, formData, props.history);
    props.getFiles(
      serverApi.GET_FILES +
        "12/" +
        props.headerData.hdrId +
        "/" +
        localStorage.getItem("companyId"),
      props.history
    );
    props.getFiles(
      serverApi.GET_FILES +
        "12/" +
        props.headerData.hdrId +
        "/" +
        localStorage.getItem("companyId"),
      props.history
    );
  };
  const onDeleteFile = (fileId) => {
    // let data = filesList.filter((item) => item.fileUploadId !== fileId);
    // props.deleteFile(serverApi.DELETEFILE + fileId);
    // setFilesList(data);
  };
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  console.log('sghfdgshfghf',HeaderData)
  return (
    <div className="juteIndent">
      <div className="consoleFormContainer">
        <h5>Fill Details</h5>
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={branchListData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.branchId}
                      update={HeaderData.branchId ? 1 : 0}
                      name="branchId"
                      label="Branch"
                      required
                      
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div>
                    <div className="TextFieldBlock">
                      <label>Date <span className="starcolor">*</span></label>
                    </div>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="dd-MM-yyyy"
                        value={HeaderData.poDate}
                        fullWidth
                        onChange={(e) => handleSelectDate(e, "poDate")}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                          >
                            <input
                              ref={inputRef}
                              {...inputProps}
                              readOnly={true}
                            />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </Box>
              { HeaderData.JutePoType=="WITHINDENT"? <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                   {locationState.state ==undefined ?( <DynamicSelectNew
                      arrayOfData={approvedIndentsListData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      handleMenuOpen={handleMenuOpen}
                      isDropdownOpen={isDropdownOpen}
                      selected={HeaderData.indentNum}
                      update={HeaderData.indentNum ? 1 : 0}
                      name="indentNum"
                      label="Indent Number"
                      placeholder="Enter here"
                      isDisabled={ 
                          HeaderData.branchId == ""
                          ? true
                          : false
                      }
                      required
                    />):(
                      <TextFieldNormal
                        label="Indent Number"
                        value={HeaderData.indentNum}
                        disabled={true}
                        required
                      />
                    )}
                  </div>
                </Box> :""}
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelectNew
                      arrayOfData={UnitConversionData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={HeaderData.isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.juteUnit}
                      name="juteUnit"
                      label="Unit Convertion"
                      update={HeaderData.juteUnit ? 1 : 0}
                      required
                      
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div className="TextFieldBlock">
                      <label>Credit Term</label>
                    </div>
                    <InputAdornment
                      label="Days"
                      type="number"
                      name="creditTerm"
                      handleChange={OnhandleChange("creditTerm")}
                      value={HeaderData.creditTerm}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div className="TextFieldBlock">
                      <label>Delivery Timeline</label>
                    </div>
                    <InputAdornment
                      label="Days"
                      type="number"
                      name="deliveryTimeline"
                      handleChange={OnhandleChange("deliveryTimeline")}
                      value={HeaderData.deliveryTimeline}
                    />
                  </div>
                </Box>

                <Box gridColumn="span 3">
                  <div>
                    <div className="TextFieldBlock"></div>
                    <TextFieldNormal
                      readOnly={true}
                      label="Expected Date"
                      value={HeaderData.exceptedDate}
                      disabled={true}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <TextFieldNormal
                    label="Penality"
                    placeholder="Enter here"
                    value={HeaderData.penality}
                    onChange={handleChange("penality")}
                  />
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={vehicleTypeListData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.vehicleTypeId}
                      name="vehicleTypeId"
                      label="Vehicle Type"
                      required
                    />
                  </div>
                </Box>

                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Vehicle Quantity"
                      type={"number"}
                      value={HeaderData.vehicleQuantity}
                      onChange={handleChange("vehicleQuantity")}
                      disabled={!isEmpty(HeaderData.vehicleTypeId)?false :true}
                      required
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={ChannelTypeData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.channelCode}
                      name="channelCode"
                      label="Channel Type"
                      required
                    />
                  </div>
                </Box>

                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={mukamListData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.mukam}
                      name="mukam"
                      label={"Mukam"}
                      update={HeaderData.mukam ? 1 : 0}
                      required
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={SupplierByMukamListData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.supplierId}
                      name="supplierId"
                      label="Supplier"
                      update={HeaderData.supplierId ? 1 : 0}
                      required
                      isDisabled={!isEmpty(HeaderData.mukam)?false :true}

                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      arrayOfData={brokerList}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.brokerId}
                      name="brokerId"
                      label="Broker"
                      update={HeaderData.brokerId ? 1 : 0}
                    />
                  </div>
                </Box>
                {HeaderData.brokerId !== "" ||null &&   <Box
                  gridColumn="span 3"
                >
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Brokerage Percentage"
                      type={"number"}
                      value={HeaderData.brokeragePercentage}
                      onChange={handleChange("brokeragePercentage")}
                    />
                  </div>
                </Box>}
             {HeaderData.brokerId !== ""||null&&   <Box
                  gridColumn="span 3"
                >
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Brokerage Rate"
                      type={"number"}
                      value={HeaderData.brokerageRate}
                      onChange={handleChange("brokerageRate")}
                    />
                  </div>
                </Box>
                }
              </Box>
            </Grid>
          </Grid>
          {askmodel && LineItems.length > 1 ? (
            <CommonPopup>
              <div className="delAddItem">
                <div>
                  Changing of Unit Conversion will remove all the line items.
                  Click yes if you want to continue..!!
                </div>
                <div className="delAddItemBtns">
                  <input
                    type="button"
                    onClick={onYesNo}
                    value="Yes"
                    className="delYesBtn"
                  />
                  <input
                    type="button"
                    onClick={onYesNo}
                    value="No"
                    className="delNoBtn"
                  />
                </div>
              </div>
            </CommonPopup>
          ) : (
            ""
          )}
        </div>
        {/* {locationState.state ? (
          <>
            <div
              className="d-flex cloumnButtonBlock"
              style={{ marginTop: "10px" }}
            >
              <Box gridColumn="span 4">
                <div>Support Documents:</div>
                <div className="personalDetailsUpload">
                  <div className="ducumentUploadBlock">
                    <div className="documentUploadContent">
                      {HeaderData.poStatus !== "3" &&
                      HeaderData.poStatus !== "4" &&
                      HeaderData.poStatus !== "5" &&
                      HeaderData.poStatus !== "6" ? (
                        <span className="btn_upload documentUpload">
                          <img src={panUploadIcon} alt="" />
                          <input
                            type="file"
                            id="imag"
                            title=""
                            className="input-img"
                            onChange={UploadDocuments()}
                          />
                          Upload Document
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      {HeaderData.files_List &&
                        HeaderData.files_List.map((item) => (
                          <>
                            <div>
                              <div
                                className="uploadedInfo"
                                style={{ display: "flex" }}
                              >
                                <img
                                  src={TickIcon}
                                  alt=""
                                  className="tickIcon"
                                  height={"20px"}
                                  width={"20px"}
                                />
                                <Tooltip
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 600 }}
                                  title="Click to download File"
                                >
                                  <div
                                    onClick={() => {
                                      getFileDownload(
                                        serverConfig.SERVER_URL +
                                          "security-api/api/files/downloadfile/" +
                                          item.fileUploadId,
                                        item.fileName,
                                        item.fileExtension
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {item.fileName}
                                  </div>
                                </Tooltip>
                                {HeaderData.poStatus !== "3" &&
                                HeaderData.poStatus !== "4" &&
                                HeaderData.poStatus !== "5" &&
                                HeaderData.poStatus !== "6" ? (
                                  <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 100 }}
                                    title="Click to Delete File"
                                  >
                                    <div
                                      onClick={() => {
                                        onDeleteFile(item.fileUploadId);
                                      }}
                                      className="deleteButton"
                                    >
                                      <img
                                        src={DeleteIcons}
                                        alt=""
                                        height={"20px"}
                                        width={"20px"}
                                      />
                                    </div>
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </>
                        ))}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </>
        ) : (
          <>
            <div></div>
          </>
        )} */}
      </div>
    </div>
  );
};
const UnitConversionData = [
  {
    value: 1,
    label: "LOOSE",
    name: "LOOSE",
  },
  {
    value: 2,
    label: "BALE",
    name: "BALE",
  },
];
const ChannelTypeData = [
  {
    value: "D",
    label: "Domestic",
    name: "Domestic",
  },
  {
    value: "I",
    label: "Import",
    name: "Import",
  },
  {
    value: "J",
    label: "JCI",
    name: "JCI",
  },
  {
    value: "P",
    label: "PTF",
    name: "PTF",
  },
];

export default JutePoFillDetails;
