import {
  MUKAM_MASTER_LIST,
  CREATE_MUKAM_MASTER,
  UPDATE_MUKAM_MASTER,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getList, postAdd } from "../../../helpers/Server_Helper";
import {
  mukamMasterListSuccessfull,
  createMukamMasterSuccessfull,
  updateMukamMasterSuccessfull,
} from "./actions";
import swal from "sweetalert";

function* MukamMasterList({ payload: { url, data } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(mukamMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateMukamMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createMukamMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/mukam_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateMukamMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateMukamMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/mukam_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
      }
    }
  }
}

export function* watchMukamMasterList() {
  yield takeEvery(MUKAM_MASTER_LIST, MukamMasterList);
}
export function* watchCreateMukamMaster() {
  yield takeEvery(CREATE_MUKAM_MASTER, CreateMukamMaster);
}
export function* watchUpdateMukamMaster() {
  yield takeEvery(UPDATE_MUKAM_MASTER, UpdateMukamMaster);
}

function* MukamMasterSaga() {
  yield all([
    fork(watchMukamMasterList),
    fork(watchCreateMukamMaster),
    fork(watchUpdateMukamMaster),
  ]);
}

export default MukamMasterSaga;
