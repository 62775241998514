import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import DynamicSelectWorking from "../../../components/Dropdown/DynamicSelect";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import moment from "moment";
import { serverApi } from "../../../helpers/Consts";
import { getBranchList } from "../../../store/Global/DropDownApis/actions";
import { getCateogryTypeList } from "../../../store/Global/DropDownApis/actions";
import { getDepartmentList } from "../../../store/Global/DropDownApis/actions";
import { getClientList } from "../../../store/Global/DropDownApis/actions";
import {
  getItemGroupList,
  BudgetHeads,
  getItemGroupByBudegtHeadsList,
  getItemGpListByBdgtHeadId,
  BOQList,
} from "../../../store/Global/DropDownApis/actions";
import { getProjectList } from "../../../store/Global/DropDownApis/actions";
import { getIndentList } from "../../../store/Purchase/Indent/actions";
import {
  getCopyOfIndents,
  IndentLineItemsProps,
} from "../../../store/Purchase/Indent/actions";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import swal from "sweetalert";

class FillDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      indentFields: this.props.indentFillDetails,
      companyId: localStorage.getItem("companyId"),
      dateValue: this.props.indentFillDetails.dateValue,
      project: this.props.indentFillDetails.project,
      projectValue: this.props.indentFillDetails.projectValue,
      client: this.props.indentFillDetails.client,
      clientValue: this.props.indentFillDetails.clientValue,
      categoryType: this.props.indentFillDetails.categoryType,
      categoryTypeValue: this.props.indentFillDetails.categoryTypeValue,
      branch: this.props.indentFillDetails.branch,
      branchValue: this.props.indentFillDetails.branchValue,
      itemGroup: this.props.indentFillDetails.itemGroup,
      itemGroupValue: this.props.indentFillDetails.itemGroupValue,
      departmentName: this.props.indentFillDetails.departmentName,
      departmentId: this.props.indentFillDetails.departmentId,
      budget_Head: this.props.indentFillDetails.budget_Head,
      budget_Head_Value: this.props.indentFillDetails.budget_Head_Value,
      copy_from_BOM: this.props.indentFillDetails.copy_from_BOM,
      indentType: this.props.indentFillDetails.indentType,
      indentSquenceNo: this.props.indentFillDetails.indentSquenceNo,
      title: this.props.indentFillDetails.title,
      budgetHeadsList: this.props.indentFillDetails.budgetHeadsList,
      futureDaysAllowable: "",
      backDaysAllowable: "",
      indentListData: [],
      branchListData: [],
      projectListData: [],
      ItemGroupList: [],
      ItemGroupListbyBudget: [],
      categoryTypeList: [],
      DepartmentList: [],
      ClientList: [],
      indentWithMultiDepartments: "",
      CopyOfIndentListDisable: false,
      CopyOfIndentListdata: [],
      isDropdownOpen: false,
      locationState: "",
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    let dateValue;
    if (this.props.location.state === undefined) {
    const currentMonth = moment().format('MM');
    const currentDate = moment().format('DD');
    const fiscalYearStart = moment(`${localStorage.getItem("acadamicYear")}-${currentMonth}-${currentDate}`);
    const today = moment();
    if (today.isBefore(fiscalYearStart)) {
      dateValue = today.format("YYYY-MM-DD");
    } else {
      dateValue = fiscalYearStart.format("YYYY-MM-DD");
    }
    this.setState({
      locationState: this.props.location,
      dateValue:dateValue
    });
  }
    window.addEventListener("scroll", this.handleScroll);
    let indentWithMultiDepartments = "";
    if (this.state.userDit) {
      this.state.userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              if (item.backDaysAllowable !== undefined) {
                let backDaysAllowable = JSON.parse(item.backDaysAllowable);
                let backDays = backDaysAllowable.bdaystype;
                if (Number(backDays) === 1) {
                  backDays = 7;
                } else if (Number(backDays) === 2) {
                  backDays = 14;
                } else if (Number(backDays) === 3) {
                  backDays = "";
                } else if (Number(backDays) === 4) {
                  backDays = backDaysAllowable.otherBackDateValue;
                } else {
                  backDays = backDaysAllowable.bdaystype;
                }
                let minDate = moment(dateValue).subtract(
                  Number(backDays),
                  "days"
                );
                let setMinDate = moment(minDate).format("YYYY-MM-DD");
                this.setState({
                  backDaysAllowable: setMinDate,
                });
              }
              if (item.futureDaysAllowable !== undefined) {
                let futureDaysAllowable = JSON.parse(item.futureDaysAllowable);
                let futureDays = futureDaysAllowable.fdaystype;
                if (Number(futureDays) === 1) {
                  futureDays = 7;
                } else if (Number(futureDays) === 2) {
                  futureDays = 14;
                } else if (Number(futureDays) === 3) {
                  futureDays = "";
                } else if (Number(futureDays) === 4) {
                  futureDays = futureDaysAllowable.othervalue;
                } else {
                  futureDays = futureDaysAllowable.fdaystype;
                }
                let maxDate = moment(dateValue).add(
                  Number(futureDays),
                  "days"
                );
                let setMaxDate = moment(maxDate).format("YYYY-MM-DD");
                this.setState({
                  futureDaysAllowable: setMaxDate,
                });
              }
              if (item.indentWithMultiDepartments !== undefined) {
                indentWithMultiDepartments = item.indentWithMultiDepartments;
                this.setState({
                  indentWithMultiDepartments: item.indentWithMultiDepartments,
                });
              }
            });
          }
      });
    }
    this.setState({
      companyId: localStorage.getItem("companyId"),
    });
   

    const data2 = {
      companyId: localStorage.getItem("companyId"),
      financialYear: localStorage.getItem("acadamicYear"),
      recordType:
        this.props.location.pathname === "/create_bom" ? "BOM" : "BOQ",
    };
    if (this.props.currentStep !== 2 || this.props.currentStep !== 3) {
      if (
        (this.props.location.pathname === "/create_bom" ||
          this.props.location.pathname === "/create_boq") &&
        indentWithMultiDepartments === "2"
      ) {
        this.props.BudgetHeads(
          serverApi.GET_BUDGET_HEADES + localStorage.getItem("companyId"),
          this.state.history
        );
        ///for setting state for update ...
        if (this.props.location.state) {
          if (this.props.location.state.rowData) {
            if (this.props.location.state.rowData.indentDetails !== null) {
              if (
                this.props.location.state.rowData.indentDetails.length !== 0
              ) {
                this.props.location.state.rowData.indentDetails.map((prop) => {
                  this.props.indentFillDetails.departmentId = prop.departmentId;
                  this.props.indentFillDetails.itemGroup = prop.itemGroup;
                  this.setState({
                    department: prop.departmentId,
                    departmentId: prop.departmentId,
                    itemGroup: prop.itemGroup,
                    itemGroupValue: prop.itemGroupId,
                  });
                  if (prop.departmentId) {
                    this.props.getItemGroupList(
                      serverApi.GET_ITEMGROUP_LIST +
                        prop.departmentId +
                        "/" +
                        localStorage.getItem("companyId") +
                        "/getAllItemGroupsByDepartmentId/" +
                        this.state.userDit.cipher,
                      this.props.history
                    );
                  }
                });
              }
            }
          }

          this.setState({
            project: this.props.location.state.rowData.projectId,
          });
        }
      }
      if (
        this.props.location.pathname === "/create_bom" ||
        this.props.location.pathname === "/create_boq"
      ) {
        if (this.props.location.state === undefined) {
          this.props.getCopyOfIndents(
            serverApi.GET_COPYOFINDNETS,
            data2,
            this.props.history
          );
        }
      }

      this.props.getCateogryTypeList(
        serverApi.GET_ALL_CATEGORY_TYPE,
        this.props.history
      );
      if (this.state.indentWithMultiDepartments === "2") {
        this.props.getDepartmentList(
          serverApi.GET_DEPARTMENT_LIST +
            localStorage.getItem("companyId") +
            "/" +
            this.state.userDit.cipher,
          this.props.history
        );
      }
      this.props.getClientList(
        serverApi.GET_CLIENT_LIST + localStorage.getItem("companyId"),
        this.props.history
      );

      if (this.props.location.state !== undefined) {
        if (
          (this.props.location.pathname === "/create_bom" ||
            this.props.location.pathname === "/create_boq") &&
          indentWithMultiDepartments === "2"
        ) {
          this.props.getItemGroupByBudegtHeadsList(
            serverApi.GET_ITEMGROUPS_BYBUDGET_HEADS +
              "/" +
              localStorage.getItem("companyId") +
              "/budgetHead/" +
              this.state.budget_Head_Value
          );
        }
      }
    }
  }

  componentWillReceiveProps(props) {
    if (props.projectList) {
      if (props.projectList.data !== undefined) {
        if (props.projectList.data.length >= 1) {
          this.setState({
            projectListData: props.projectList.data,
          });
          props.indentFillDetails.projectListData = props.projectList.data;
          if (props.projectList.data.length === 1) {
            this.setState({
              projectValue: props.projectList.data[0].projectId,
            });
            props.indentFillDetails.projectValue =
              props.projectList.data[0].projectId;
          }
        }
      }
    }
    if (props.branchList) {
      if (props.branchList !== undefined) {
        if (props.branchList.data) {
          if (props.branchList.data.length !== 0) {
            let branchList = props.branchList.data.filter((item) => {
              if (item.value !== 0 && item.value !== "0") {
                return item;
              }
            });

            this.setState({
              branchListData: branchList,
            });
            props.indentFillDetails.branchListData = branchList;
          }
        }
      }
    }

    if (props.departmentList) {
      if (props.departmentList !== undefined) {
        if (props.departmentList.data) {
          if (props.departmentList.data.length !== 0) {
            let departmentList = props.departmentList.data.filter((item) => {
              if (item.value !== 0 && item.value !== "0") {
                return item;
              }
            });
            this.setState({
              DepartmentList: departmentList,
            });
            this.props.indentFillDetails.DepartmentList = departmentList;
          }
        }
      }
    }
    if (props.ItemGroupList) {
      if (props.ItemGroupList.data !== undefined) {
        if (props.ItemGroupList.data) {
          if (props.ItemGroupList.data.length !== 0) {
            let ItemGroupList = props.ItemGroupList.data.filter((item) => {
              if (item.value !== 0 && item.value !== "0") {
                return item;
              }
            });
            this.setState({
              ItemGroupList: ItemGroupList,
            });
            this.props.indentFillDetails.ItemGroupList = ItemGroupList;
          }
        }
      }
    }
    if (props.CategoryTypeList) {
      let CategoryTypeList = [];
      if (props.CategoryTypeList.data !== undefined) {
        if (props.CategoryTypeList.data) {
          if (props.CategoryTypeList.data.length !== 0) {
            if (this.props.location.pathname === "/create_openIndent") {
              CategoryTypeList = props.CategoryTypeList.data.filter(
                (cTypeData) => {
                  if (
                    cTypeData.value !== "4" &&
                    cTypeData.value !== "2" &&
                    cTypeData.value !== "7"
                  ) {
                    return cTypeData;
                  }
                }
              );
            } else {
              CategoryTypeList = props.CategoryTypeList.data.filter(
                (cTypeData) => {
                  if (cTypeData.value !== "2") {
                    return cTypeData;
                  }
                }
              );
            }
          }
        }
      }
      // }
      this.setState({
        categoryTypeList: CategoryTypeList,
      });
      this.props.indentFillDetails.categoryTypeList = CategoryTypeList;
    }
    if (props.clientList) {
      if (props.clientList !== undefined) {
        if (props.clientList.data !== undefined) {
          if (props.clientList.data.length !== 0) {
            let clientList = props.clientList.data.filter((item) => {
              return item;
            });
            this.setState({
              ClientList: clientList,
            });
          }
        }
      }
    }
    if (props.CopyOfIndentList) {
      let indentList = [
        {
          value: "",
          name: "Select.......",
          label: "Select.......",
        },
      ];
      props.CopyOfIndentList.map((item) => {
        indentList.push({
          value: item.value,
          name: item.name,
          label: item.name,
        });
      });
      this.setState({
        CopyOfIndentListdata: indentList,
      });
    }

    if (props.budgetHeadsList.data) {
      if (props.budgetHeadsList.data !== undefined) {
        if (props.budgetHeadsList.data.length !== 0) {
          let budgetHeadsList = props.budgetHeadsList.data.filter((item) => {
            if (item.value !== 0 && item.value !== "0") {
              return item;
            }
          });
          this.setState({
            budgetHeadsList: budgetHeadsList,
          });
          this.props.indentFillDetails.budgetHeadsList = budgetHeadsList;
        }
      }
    }
    if (
      (this.props.location.pathname === "/create_bom" ||
        this.props.location.pathname === "/create_boq") &&
      this.state.indentWithMultiDepartments === "2"
    ) {
      if (props.itemGroupByBudgetHeadsList) {
        if (props.itemGroupByBudgetHeadsList.data !== undefined) {
          if (props.itemGroupByBudgetHeadsList.data.length !== 0) {
            let ItemGroupListbyBudgetList =
              props.itemGroupByBudgetHeadsList.data.filter((item) => {
                if (item.value !== 0 && item.value !== "0") {
                  return item;
                }
              });
            this.setState({
              ItemGroupListbyBudget: ItemGroupListbyBudgetList,
            });
            this.props.indentFillDetails.ItemGroupList =
              ItemGroupListbyBudgetList;
          }
        }
      }
    }
    if (
      this.state.copy_from_BOM !== "" &&
      this.state.copy_from_BOM !== undefined
    ) {
      if (this.props.location.state === undefined) {
        if (props.boqListResp) {
          if (props.boqListResp.data !== undefined) {
            if (props.boqListResp.data.indentDetails !== null) {
              if (props.boqListResp.data) {
                this.props.IndentLineItemsProps(
                  props.boqListResp.data.indentDetails
                );
              }
            } else {
              swal("No Indent Items Found");
            }
          }
        }
      }
    }
  }
  ///function for date formate
  handleSelect_Date = (e, name) => {
    if (name === "date") {
      var date = moment(e).format();
      var fromdate = date.split("T", 1).toString();
      this.setState({
        dateValue: fromdate,
      });
    }

    this.props.indentFillDetails.dateValue = fromdate;
    this.props.handleSelectDate(fromdate);
  };

  //function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "Department") {
      this.props.indentFillDetails.departmentName = selectedName;
      this.props.indentFillDetails.departmentId = selectedValue;
      this.setState({
        departmentId: selectedValue,
        departmentName: selectedName,
      });
      this.props.getItemGroupList(
        serverApi.GET_ITEMGROUP_LIST +
          selectedValue +
          "/" +
          localStorage.getItem("companyId") +
          "/getAllItemGroupsByDepartmentId/" +
          this.state.userDit.cipher,
        this.props.history
      );
      this.props.indentFillDetails.itemGroup = "";
      this.props.indentFillDetails.itemGroupValue = "";
      this.setState({
        itemGroupValue: "",
        itemGroup: "",
      });
    }
    if (name === "project") {
      this.props.indentFillDetails.project = selectedName;
      this.props.indentFillDetails.projectValue = selectedValue;
      this.setState({
        projectValue: selectedValue,
        project: selectedName,
      });
    }
    if (name === "Client") {
      if (selectedValue !== 0) {
        this.props.indentFillDetails.client = selectedName;
        this.props.indentFillDetails.clientValue = selectedValue;
        this.setState({
          clientValue: selectedValue,
          client: selectedName,
        });
      }
    }

    if (name === "Category_Type") {
      this.props.indentFillDetails.categoryType = selectedName;
      this.props.indentFillDetails.categoryTypeValue = selectedValue;
      this.setState({
        categoryTypeValue: selectedValue,
        CategoryType: selectedName,
      });
    }
    if (name === "Branch") {
      this.props.indentFillDetails.branch = selectedName;
      this.props.indentFillDetails.branchValue = selectedValue;
      this.setState({
        branchValue: selectedValue,
        branch: selectedName,
      });
    }
    if (name === "itemGroup") {
      this.props.indentFillDetails.itemGroup = selectedName;
      this.props.indentFillDetails.itemGroupValue = selectedValue;
      this.setState({
        itemGroupValue: selectedValue,
        itemGroup: selectedValue,
      });
      let IndentLineItem = [
        {
          id: 1,
          qty: "",
          uomCode: "",
          qohCompany: "",
          qohBranch: 0,
          qohProject: 0,
          rate: "",
          rateSource: 1,
          isActive: 1,
          remarks: "",
          itemGroup: selectedName,
          itemGroupValue: selectedValue,
          departmentName: this.props.indentFillDetails.departmentName,
          departmentId: this.props.indentFillDetails.departmentId,
          itemId: "",
          lastPurchaseDate: "",
          lastPurchaseRate: 0,
          installationRate: null,
          qtyPo: "",
          amount: 0,
          min: 0,
          max: 0,
          itemList: [],

          departmentList: [],
          itemGroupList: [],
          reOrderQty: "",
        },
      ];
      this.props.IndentLineItemsProps(IndentLineItem);
    }
    if (name === "Budget_Head") {
      this.setState({
        budget_Head: selectedName,
        budget_Head_Value: selectedValue,
        itemGroupValue: "",
        itemGroup: "",
        itemGroupId: "",
      });
      this.props.indentFillDetails.budget_Head = selectedName;
      this.props.indentFillDetails.budget_Head_Value = selectedValue;

      this.props.indentFillDetails.itemGroup = "";
      this.props.indentFillDetails.itemGroupValue = "";
      this.props.getItemGroupByBudegtHeadsList(
        serverApi.GET_ITEMGROUPS_BYBUDGET_HEADS +
          "/" +
          localStorage.getItem("companyId") +
          "/budgetHead/" +
          selectedValue
      );
    }
    if (name === "copy_from_BOM") {
      this.setState({
        copy_from_BOM: selectedValue,
      });
      this.props.indentFillDetails.copy_from_BOM = selectedValue;

      this.props.BOQList(
        serverApi.CREATE_INDENT +
          "/" +
          selectedValue +
          "/userid/" +
          this.state.userDit.userId,
        this.props.history
      );
    }
  };
  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
    if (key === "title") {
      this.setState({
        title: value,
      });
      this.props.indentFillDetails.title = value;
    }
  };

  handleDisableFields = (pathname, dropdownList, field) => {
    if (
      this.props.location.pathname !== "/openIndent" ||
      this.props.location.pathname !== "/store_indent" ||
      this.props.location.pathname !== "/create_bom" ||
      this.props.location.pathname !== "/create_boq"
    ) {
      if (
        this.state.projectListData.length >= 1 &&
        this.state.projectValue === ""
      ) {
        return true;
      } else {
        if (
          this.state.title === "" &&
          this.props.location.pathname === "/create_bom"
        ) {
          return true;
        }
      }
    } else {
      if (this.state.title === "") {
        return true;
      }
    }
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    this.setState({
      isDropdownOpen: true,
    });
  };

  disableBranchforBom = () => {
    if (
      this.state.locationState.state !== undefined &&
      this.state.branchValue !== ""
    ) {
      if (this.state.categoryTypeValue !== "") {
        return true;
      } else {
        return false;
      }
    }
  };
  disableBranchforIndent = () => {
    if (
      this.state.locationState.state !== undefined &&
      this.state.branchValue !== ""
    ) {
      if (this.state.categoryTypeValue !== "") {
        return true;
      } else {
        return false;
      }
    } else if (this.state.categoryTypeValue === "") {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return (
      <div className="consoleFormContainer">
        <h5>Fill Details</h5>
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <div className="TextFieldBlock">
                        <label>
                          Date<label className="starcolor">*</label>
                        </label>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          // this.state.backDaysAllowable===""&&this.state.backDaysAllowable=== undefined?
                          minDate={
                            this.state.backDaysAllowable !== "" &&
                            this.state.backDaysAllowable !== undefined
                              ? this.state.backDaysAllowable
                              : this.state.dateValue
                          }
                          maxDate={
                            this.state.futureDaysAllowable !== "" &&
                            this.state.futureDaysAllowable !== undefined
                              ? this.state.futureDaysAllowable
                              : this.state.dateValue
                          }
                          value={this.state.dateValue}
                          fullWidth
                          onChange={(e) => this.handleSelect_Date(e, "date")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="consoleDatePicker"
                            >
                              <input
                                ref={inputRef}
                                {...inputProps}
                                readOnly={true}
                              />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </Box>
                {(this.state.locationState.state !== undefined ||
                  this.state.indentSquenceNo !== "") && (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Indent No"
                        value={this.state.indentSquenceNo}
                        readOnly={true}
                      />
                    </div>
                  </Box>
                )}
                {(this.props.location.pathname === "/create_bom" ||
                  this.props.location.pathname === "/create_boq") && (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        label="Title"
                        required
                        value={this.state.title}
                        onChange={this.handleChange("title")}
                      />
                    </div>
                  </Box>
                )}
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      isDropdownOpen={this.state.isDropdownOpen}
                      arrayOfData={
                        this.state.projectListData &&
                        this.state.projectListData.map((item) => ({
                          label: item.name,
                          value: item.projectId,
                          name: item.name,
                        }))
                      }
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.projectValue}
                      update={this.state.projectValue ? 1 : 0}
                      name="project"
                      label="Project"
                      required
                      isDisabled={
                        this.state.title === "" &&
                        (this.props.location.pathname === "/create_bom" ||
                          this.props.location.pathname === "/create_boq")
                          ? true
                          : false
                      }
                    />
                  </div>
                </Box>
                {/* As per the requirement client field is hidden */}
                {/* {this.state.ClientList && this.state.ClientList.length >= 1 && (
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        isDropdownOpen={this.state.isDropdownOpen}
                        arrayOfData={this.state.ClientList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.clientValue}
                        update={this.state.clientValue ? 1 : 0}
                        name="Client"
                        label="Client"
                        isDisabled={this.handleDisableFields(
                          this.props.location.pathname,
                          this.state.projectListData,
                          this.state.projectValue
                        )}
                      />
                    </div>
                  </Box>
                )} */}

                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      isDropdownOpen={this.state.isDropdownOpen}
                      arrayOfData={this.state.categoryTypeList}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.categoryTypeValue}
                      update={this.state.categoryTypeValue ? 1 : 0}
                      name="Category_Type"
                      label="Category Type"
                      required
                      isDisabled={
                        this.state.locationState.state !== undefined &&
                        this.state.categoryTypeValue !== ""
                          ? true
                          : this.handleDisableFields(
                              this.props.location.pathname,
                              this.state.projectListData,
                              this.state.projectValue
                            )
                      }
                    />
                  </div>
                </Box>

                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      isDropdownOpen={this.state.isDropdownOpen}
                      arrayOfData={this.state.branchListData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handlePropSelectChange}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.branchValue}
                      update={this.state.branchValue ? 1 : 0}
                      name="Branch"
                      label="Branch"
                      required
                      isDisabled={
                        this.state.locationState == "" ?
                        true
                        :this.props.location.pathname === "/create_bom" ||
                        this.props.location.pathname === "/create_boq"
                          ? this.disableBranchforBom()
                          : this.props.location.pathname === "/create_indent" ||
                            this.props.location.pathname ===
                              "/create_openIndent"
                          ? this.disableBranchforIndent()
                          : false
                      }
                    />
                  </div>
                </Box>

                {(this.props.location.pathname === "/create_indent" ||
                  this.props.location.pathname === "/create_openIndent") &&
                  this.state.indentWithMultiDepartments === "2" && (
                    <>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            isDropdownOpen={this.state.isDropdownOpen}
                            arrayOfData={this.state.DepartmentList}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            handleMenuOpen={this.handleMenuOpen}
                            selected={this.state.departmentId}
                            update={this.state.departmentId ? 1 : 0}
                            name="Department"
                            label="Department"
                            required
                            isDisabled={
                              this.state.branchValue === "" ? true : false
                            }
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <DynamicSelect
                            isDropdownOpen={this.state.isDropdownOpen}
                            arrayOfData={this.state.ItemGroupList}
                            className="dropdownBlockContainer"
                            menuPosition="fixed"
                            menuPlacement="auto"
                            onSelectChange={this.handlePropSelectChange}
                            handleMenuOpen={this.handleMenuOpen}
                            selected={
                              this.state.itemGroupValue === ""
                                ? 0
                                : this.state.itemGroupValue
                            }
                            update={this.state.itemGroupValue ? 1 : 0}
                            name="itemGroup"
                            label="Item Group"
                            required
                            isDisabled={
                              this.state.departmentId === "" ? true : false
                            }
                          />
                        </div>
                      </Box>
                    </>
                  )}
                {(this.props.location.pathname === "/create_bom" ||
                  this.props.location.pathname === "/create_boq") &&
                this.state.indentWithMultiDepartments === "2" ? (
                  <>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          arrayOfData={this.state.budgetHeadsList}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          selected={this.state.budget_Head_Value}
                          update={this.state.budget_Head_Value ? 1 : 0}
                          name="Budget_Head"
                          label="Budget Head"
                          required
                          isDisabled={
                            this.props.location.state !== undefined
                              ? true
                              : this.state.branchListData &&
                                this.state.branchListData.length >= 2 &&
                                this.state.branchValue === ""
                              ? true
                              : this.state.categoryTypeValue === ""
                              ? true
                              : false
                          }
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelectWorking
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          arrayOfData={this.state.ItemGroupListbyBudget}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          selected={
                            this.state.itemGroupValue === ""
                              ? ""
                              : this.state.itemGroupValue
                          }
                          update={this.state.itemGroupValue !== "" ? 1 : 0}
                          name="itemGroup"
                          label="Item Group"
                          required
                          isDisabled={
                            this.props.location.state !== undefined
                              ? true
                              : this.state.budget_Head_Value === ""
                              ? true
                              : false
                          }
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          arrayOfData={this.state.CopyOfIndentListdata}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          selected={this.state.copy_from_BOM}
                          update={this.state.copy_from_BOM ? 1 : 0}
                          name="copy_from_BOM"
                          label={
                            this.props.location.pathname === "/create_boq"
                              ? "Copy From BOQ"
                              : "Copy From BOM"
                          }
                          isDisabled={
                            this.state.itemGroupValue === "" ? true : false
                          }
                        />
                      </div>
                    </Box>
                  </>
                ) : this.props.location.pathname !== "/create_indent" &&
                  this.props.location.pathname !== "/create_openIndent" ? (
                  <>
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          arrayOfData={this.state.CopyOfIndentListdata}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          selected={this.state.copy_from_BOM}
                          update={this.state.copy_from_BOM ? 1 : 0}
                          name="copy_from_BOM"
                          label={
                            this.props.location.pathname === "/create_boq"
                              ? "Copy From BOQ"
                              : "Copy From BOM"
                          }
                          isDisabled={
                            this.state.branchValue === "" ? true : false
                          }
                        />
                      </div>
                    </Box>
                  </>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    branchList,
    departmentList,
    clientList,
    ItemGroupList,
    projectList,
    CategoryTypeList,
    budgetHeadsList,
    itemGroupByBudgetHeadsList,
    boqListResp,
  } = state.DropDownListReducer;
  const { CopyOfIndentList } = state.IndentReducer;

  return {
    branchList,
    projectList,
    ItemGroupList,
    CategoryTypeList,
    departmentList,
    clientList,
    CopyOfIndentList,
    budgetHeadsList,
    itemGroupByBudgetHeadsList,
    boqListResp,
  };
};
export default connect(mapStatetoProps, {
  getBranchList,
  getProjectList,
  getItemGroupList,
  getCateogryTypeList,
  getDepartmentList,
  getClientList,
  getIndentList,
  getCopyOfIndents,
  BudgetHeads,
  getItemGroupByBudegtHeadsList,
  BOQList,
  IndentLineItemsProps,
  getItemGpListByBdgtHeadId,
})(withRouter(FillDetails));
