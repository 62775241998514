import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory,useLocation } from "react-router-dom";
// import DynamicSelect from "../../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import DynamicSelect from "../../../Pages/StoreModule/MaterialRequest/DynamicSelect";
import { Grid, Box, IconButton,Button } from "@mui/material";
import { allItemGroupMasterList } from "../../../store/Global/DropDownApis/actions";
import { getSalesOrderItemsByItemGroupId } from "../../../store/Sales/SalesOrder/actions";
import cons, { serverApi,serverConfig } from "../../../helpers/Consts";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import DeleteIcons from "../../../assets/images/picEditIcon.png";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import { getFileDownload } from "../../../helpers/server.js";
import {
  SalesOrderLineItems,
  getSalesOrderHeader,
  getRegularSoItemNameData
} from "../../../store/Sales/SalesOrder/actions";
import swal from "sweetalert";
import { SalesGstCalculations } from "../../../Utilities/SalesGstCalculation";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { isEmpty, limitDecimals } from "../../../Utilities/helper";
import { deleteSoFile,
  UploadSOFiles,
 } from "../../../store/Sales/SalesOrder/actions";

const LineItems = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [LineItems, setLineItems] = useState([]);
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [LineItemsDisplayTxtField, setLineItemsDisplayTxtField] = useState({});
  const [LineItemsDisplayDropField, setLineItemsDisplayDropField] = useState(
    {}
  );
  const [ItemGroupData, setItemGroupData] = useState([]);
  const [ItemNameData, setItemNameData] = useState([]);
  const [userDit, setuserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [HeaderData, setHeaderData] = useState({});

  const { allItemGroupMaster_List } = useSelector(
    (state) => state.DropDownListReducer
  );
  const { LineItemsData, SalesOrderHeader, SOFiles } = useSelector(
    (state) => state.SalesOrderReducer
  );
  const [salesOrderFiles,SetSalesOrderFiles] = useState(SOFiles?.data?.data || [])

  useEffect(()=>{
    SetSalesOrderFiles(SOFiles?.data?.data);
  },[SOFiles])


  const { quotationItemBy_ItemGroupId } = useSelector(
    (state) => state.QuotationReducer
  );
  const onDeleteFile = (fileId) => {
      let data = salesOrderFiles.filter((item) => item.fileUploadId !== fileId);
      SetSalesOrderFiles(data)
      dispatch({ type: "UPDATE_SO_FILES", payload: data });
      dispatch(deleteSoFile(serverApi.DELETEFILE + fileId));
  };

  const handleUpload = (e) => {
    e.preventDefault();
    let sourceMenu = 45;

    let file = e.target.files[0];
    let fileExt = file.name.split('.').pop();
    const formData = new FormData();
    formData.append('fileName', file.name);
    formData.append('fileUpload', file);
    formData.append('fileExtension', fileExt);
    formData.append('sourceMenu', sourceMenu);
    formData.append('displayName', file.name);
    formData.append('taskUniqueId', HeaderData.regularSalesId);
    formData.append('createdBy',JSON.parse(localStorage.getItem("authUser")).userId);
    formData.append('fileCompanyId', localStorage.getItem('companyId'));

    dispatch(
      UploadSOFiles(
        serverApi.UPLOADFILE,
        formData,
        history,
        sourceMenu,
        HeaderData.regularSalesId
      )
    );
  };
  

  useEffect(()=>{
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  },[])
  useEffect(() => {
    setLineItems(LineItemsData);
    setHeaderData(SalesOrderHeader);
  }, [
    LineItemsData,
    SalesOrderHeader,
  ]);
  useEffect(() => {
    
    if (allItemGroupMaster_List) {
      if (allItemGroupMaster_List.data) {
        let ListFilter = allItemGroupMaster_List.data.filter((props) => {
          if (props.value !== "0") {
            return props;
          }
        });
        let list = [];
        ListFilter.map((prop) => {
          list.push({
            label: prop.label,
            name: prop.name,
            value: prop.value,
            variant: prop.id,
          });
        });
        setItemGroupData(list);
      }
    }
  }, [
    allItemGroupMaster_List,
    quotationItemBy_ItemGroupId,
  ]);

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };

  const onDel = (row) => {
    var FilterLineItems = LineItems.filter((item) => item.isActive !== 0);
    if (FilterLineItems.length == 1) {
      swal("you can't delete the last lineitem");
    } else {
      let indexItem = LineItems.findIndex((item) => item === row);
      let lineItems = [...LineItems];
      lineItems[indexItem].isActive = 0;
      setLineItems(lineItems);
      dispatch(SalesOrderLineItems(lineItems));
      let Rate = SalesGstCalculations(
        {
          lineItems: lineItems,
          taxType: HeaderData.taxType,
        },
        "SALES_REGULAR_FLOW"
      );
      handleCalculation(Rate);
    }
  };

  const displayField = (row, colname, type) => {
    if (colname === "itemGroupId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type == 1) {
        dispatch(
          allItemGroupMasterList(
            serverApi.GET_ALL_ITEMGROUP_MASTER_LIST +
              localStorage.getItem("companyId") +
              "/" +
              userDit.cipher,
            history
          )
        );
      }
    } else if (colname === "itemId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type == 1) {
      const data = {
        companyId: localStorage.getItem("companyId"),
        itemGroupId: row.itemGroupValue,   
      };
      dispatch(
        getSalesOrderItemsByItemGroupId(
          serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
          data,
          history,
          row.id
        )
      );
        }
    } else if (colname === "discountType") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
    }
  };

  const OnhandleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    let mname = key.split("_");
    if (mname[0] === "remarks") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.remarks = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderLineItems(LineItemsRec));
    }
    if (mname[0] === "itemMake") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.itemMake = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderLineItems(LineItemsRec));
    }
    if (mname[0] === "hsncode") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.hsncode = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderLineItems(LineItemsRec));
    }
    if (mname[0] === "rate") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            rowItem.rate = value;            
          if (rowItem.discount !== "") {
            if (rowItem.discountType === 1 && value !== "") {
              rowItem.discountedRate = limitDecimals(
                value - rowItem.discount,
                3
              );
            } else {
              let data = (value * rowItem.discount) / 100;
              rowItem.discountedRate = limitDecimals(value - data, 3);
            }
          } else {
            rowItem.discountedRate = limitDecimals(value, 3);
          }
        }
          else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderLineItems(LineItemsRec));
      let Rate = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
        },
        "SALES_REGULAR_FLOW"
      );
      handleCalculation(Rate);
    }
    if (mname[0] === "discount") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (rowItem.discountType === 1) {
            if (Number(value) > Number(rowItem.rate)) {
              swal("Discount value Shouldn't be greater than Rate");
            } else {
              if (value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
                rowItem.discount = value;
              rowItem.discountedRate = limitDecimals(rowItem.rate - value,3); // Keep the original precision without rounding

              } else {
                swal(
                  "Please enter a positive number with up to 2 decimal places."
                );
              }
                           }
          } else {
            if (Number(value) > 100) {
              swal("Discount percentage Shouldn't be greater than 100");
              rowItem.discount = 0;
              rowItem.discountedRate = limitDecimals(rowItem.rate,3);
            } else {
              if (value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
                rowItem.discount = value;
              } else {
                swal(
                  "Please enter a positive number with up to 2 decimal places."
                );
              }
              let data = (rowItem.rate * value) / 100;
              rowItem.discountedRate = limitDecimals(rowItem.rate - data,3); // Keep the original precision without rounding
            }
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderLineItems(LineItemsRec));
      let Disc = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
        },
        "SALES_REGULAR_FLOW"
      );
      handleCalculation(Disc);
    }
    if (mname[0] === "discountedRate") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.discountedRate = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderLineItems(LineItemsRec));
    }
    if (mname[0] === "quantity") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            rowItem.quantity = value;
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderLineItems(LineItemsRec));
      let Qty = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
        },
        "SALES_REGULAR_FLOW"
      );
      handleCalculation(Qty);
    }
    if (mname[0] === "uom") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          rowItem.uom = value;
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderLineItems(LineItemsRec));
    }
    if (mname[0] === "taxPercent") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
            rowItem.taxPercent = value;
          } else {
            swal("Please enter a positive number with up to 2 decimal places.");
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderLineItems(LineItemsRec));
      let TaxPer = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
        },
        "SALES_REGULAR_FLOW"
      );
      
      handleCalculation(TaxPer);
    }
    if (mname[0] === "netAmount") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
            rowItem.netAmount = value;
          } else {
            swal("Please enter a positive number with up to 2 decimal places.");
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(SalesOrderLineItems(LineItemsRec));
    }
  };
  const HandleBlur=(data)=>(event)=>{
    const { key, row } = data;
    const { value } = event.target;
    let mname = key.split("_"); 
    if (mname[0] === "rate") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
              rowItem.rate = value;
              if (Number(value) < Number(rowItem.discount)) {
                rowItem.rate=0
                rowItem.discountedRate=0
                swal("Rate must be greater than the discount.");
                return rowItem;          
          } 
        } 
          else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        return rowItem
      })
      setLineItems(LineItemsRec);
      setLineItems(LineItemsRec);
      dispatch(SalesOrderLineItems(LineItemsRec));
      let Rate = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
        },
        "SALES_REGULAR_FLOW"
      );
      handleCalculation(Rate);

    } 
  }
  const handleSelectChange = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    selectedItemCode
  ) => {
    const countOfOtherCharges = LineItems.filter(
      (el) => el.itemGroupValue == 997
    ).length;
    const isNonOtherChargeItemOne=LineItems.length-countOfOtherCharges
    const lastRow = isNonOtherChargeItemOne == 1 ? LineItems[LineItems.length - 1 - countOfOtherCharges]: LineItems[LineItems.length-1];
    if (name === "itemGroupId") {
      if (selectedRow === lastRow) {
        let LineItem = LineItems;
        let Obj = {
          id: LineItems.length + 1,
          regularSalesOrderLineItemId: null,
          regularSalesOrderId: selectedRow.regularSalesOrderId,
          createdBy: "",
          createdDate: "",
          companyId: "",
          companyName: "",
          quotationId: "",
          quotationLineitemId: "",
          hsncode: "",
          itemGroupId: "",
          itemGroupValue: "",
          itemGroupName: "",
          itemId: null,
          itemName: "",
          itemCode: "",
          itemValue: "",
          itemMake: "",
          quantity: "0.000",
          uom: "",
          rate: "0.000",
          doBalQty: "",
          invoiceBalQty: "",
          igstAmount: "",
          igstPercent: "",
          cgstAmount: "",
          cgstPercent: "",
          sgstAmount: "",
          sgstPercent: "",
          discountType: "",
          discountName: "",
          discount: "",
          discountedRate: "",
          discountAmount:"0.00",
          netAmount: "0.00",
          totalAmount: "0.00",
          remarks: "",
          taxPercent: "",
          isActive: 1,
          currency: "INR",
          status: "",
          updatedBy: null,
          updatedOn: null,
          ItemNameData: [],
        };
        LineItem.push(Obj);
        setLineItems(LineItem);
        dispatch(SalesOrderLineItems(LineItem));
      }
  
    }
    if (name === "itemGroupId") {
      let LineItemdata = LineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          row.itemGroupId = selectedItemCode;
          row.itemGroupName = selectedName;
          row.itemGroupValue = selectedValue;
          row.itemId = null;
          row.itemName = "";
          row.itemValue = "";
          row.itemCode = "";
          row.remarks = "";
          row.itemMake = "";
          row.hsncode = "";
          row.rate = "0.000";
          row.discountType = "";
          row.discountName = "";
          row.discount = "0.00";
          row.discountedRate = "";
          row.quantity = "0.000";
          row.discountAmount="0.00";
          row.uom = "";
          row.taxPercent = "";
          row.totalAmount = "0.00";
          row.netAmount="0.00"
        }
        return row;
      });
      setLineItems(LineItemdata);
      dispatch(SalesOrderLineItems(LineItemdata));
      displayField(selectedRow, "itemGroupId", 2);
      let ItemWhenAdd = SalesGstCalculations(
        {
          lineItems: LineItems,
          taxType: HeaderData.taxType,
        },
        "SALES_REGULAR_FLOW"
      );
      handleCalculation(ItemWhenAdd);
      const data = {
        companyId: localStorage.getItem("companyId"),
        itemGroupId: selectedValue,
      };
      dispatch(
        getSalesOrderItemsByItemGroupId(
          serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
          data,
          history,
          selectedRow.id
        )
      );
    }
    if (name === "itemId") {
      let filteredLineItems = LineItems.filter((item) => item.isActive !== 0);
            //this code is to check the item duplication.......
      if (selectedRow.itemId !== selectedValue) {
        var checkItem = !!filteredLineItems.find(
          ({ itemId }) => Number(itemId) === Number(selectedValue)
        );
      }
      if (checkItem) {
        swal("You can't create the record with the duplicate item");
        let LineItemdata = LineItems.filter((row) => {
          if (row.id === selectedRow.id) {
            row.itemId = "";
            row.itemValue = "";
            row.itemCode = "";
            row.itemName = "";
            row.hsncode = "";
            row.rate = "0.000";
            row.discountedRate = "";
            row.uom = "";
            row.remarks = "";
            row.itemMake = "";
            row.discountType = "";
            row.discountName = "";
            row.discountAmount="0.00";
            row.discount = "0.00";
            row.taxPercent = "";
            row.totalAmount = "0.00";
            row.netAmount ="0.00"
            row.quantity = "0.000";
          }
          return row;
        });
        setLineItems(LineItemdata);
        dispatch(SalesOrderLineItems(LineItemdata));
        displayField(selectedRow, "itemId", 2);
      } else {
        var ItemGroup =''
        let LineItemdata = LineItems.filter((row) => { 
          if (row.id === selectedRow.id) {
             ItemGroup =row.itemGroupValue;
            row.itemName = selectedName;
            row.itemId = selectedValue;
            row.itemValue = selectedValue;
            row.itemCode = row.itemGroupValue + selectedItemCode;
            // let splitedSelectedName = selectedName.split("^");
            // row.itemName = splitedSelectedName[0];
            // row.hsncode = splitedSelectedName[4];
            // row.rate = limitDecimals(splitedSelectedName[3], 3);
            // row.discountedRate = limitDecimals(splitedSelectedName[3], 3);
            // row.uom = splitedSelectedName[1];
            // row.remarks = "";
            // row.itemMake = "";
            // row.discountType = "";
            // row.discountName = "";
            // row.discount = "0.00";
            // row.discountAmount="0.00";
            // row.taxPercent = limitDecimals(splitedSelectedName[13], 2);
            // row.totalAmount = "0.00";
            // row.netAmount="0.00"
            // row.quantity = "0.000";
          }
          return row;
        });
        setLineItems(LineItemdata);
        dispatch(SalesOrderLineItems(LineItemdata));
        displayField(selectedRow, "itemId", 2);
        const data={
          itemGroupId:ItemGroup,
          companyId: localStorage.getItem("companyId"),
          itemId:selectedValue,
          branchId:HeaderData.branchId
        }
        dispatch(
          getRegularSoItemNameData(
            serverApi.GET_ITEMDETAILS_BYITEM_ITEMGRP_COMPANYID,
            data,
            selectedRow.id
          )
        );
      }
    }
    if (name === "discountType") {
      let LineItemdata = LineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          if (selectedValue === "") {
            row.discountType = "";
            row.discountName = "";
            row.discount = "";
            row.discountAmount="0.00";
            row.discountedRate = limitDecimals(row.rate)
          } else {
            row.discountType = selectedValue;
            row.discountName = selectedName;
            if (Number(selectedValue) === 1) {
              row.discountedRate = limitDecimals(row.rate - row.discount, 3)
            } else {
              let data = (row.rate * row.discount) / 100;
              row.discountedRate = limitDecimals(row.rate - data, 3)
            }
          }
        }
        return row;
      });
      setLineItems(LineItemdata);
      dispatch(SalesOrderLineItems(LineItemdata));
      displayField(selectedRow, "discountType", 2);
    }
    let DiscType = SalesGstCalculations(
      {
        lineItems: LineItems,
        taxType: HeaderData.taxType,
      },
      "SALES_REGULAR_FLOW"
    );
    handleCalculation(DiscType);
  };
  const handleCalculation = (Calculations) => {
    setLineItems(Calculations.lineItems);
    dispatch(SalesOrderLineItems(Calculations.lineItems));

    var Headerdata = {
      ...HeaderData,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
    };
    setHeaderData((prev) => ({
      ...prev,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
    }));
    dispatch(getSalesOrderHeader(Headerdata));
  };

  const addOtherCharges = () => {
    let newline = 1;
      let filteredproducts = LineItems.map((item) => {
      newline = Number(item.id);
      return item;
    });
    let Obj = {
      id: LineItems.length + 1,
      regularSalesOrderLineItemId:"",
      regularSalesOrderId:HeaderData.regularSalesId,
      createdBy: "",
      createdDate: "",
      companyId: "",
      companyName: "",
      quotationId: "",
      quotationLineitemId: "",
      hsncode: "",
      itemGroupId: 1252,
      itemGroupValue: "997",
      itemGroupName: "Other Charges",
      itemId: null,
      itemName: "",
      itemCode: "",
      itemValue: "",
      itemMake: "",
      quantity: "0.000",
      uom: "",
      rate: "0.000",
      doBalQty: "",
      invoiceBalQty: "",
      igstAmount: "",
      igstPercent: "",
      cgstAmount: "",
      cgstPercent: "",
      sgstAmount: "",
      sgstPercent: "",
      discountType: "",
      discountName: "",
      discount: "",
      discountedRate: "",
      discountAmount:"0.00",
      netAmount: "0.00",
      totalAmount: "0.00",
      discountAmount:"0.00",
      remarks: "",
      taxPercent: "",
      isActive: 1,
      currency: "INR",
      status: 1,
      updatedBy: null,
      updatedOn: null,
      ItemNameData: [],
    };
    const spliceStartValue = filteredproducts[filteredproducts.length - 1].itemGroupId
      ? LineItems.length
      : LineItems.length - 1;
      
    if (filteredproducts.length === 1) {
      filteredproducts.push(Obj);
      setLineItems(filteredproducts);
      dispatch(SalesOrderLineItems(filteredproducts));
    } else {
      filteredproducts.splice(spliceStartValue, 0, Obj);
      setLineItems(filteredproducts);
      dispatch(SalesOrderLineItems(filteredproducts));
    }
  };

  const disableField = (row, col) => {
    if (row.regularSalesOrderLineItemId === null) {
      return false;
    } else if (
      col.type === "remarks" ||
      col.type === "itemMake" ||
      col.type === "hsncode" ||
      col.type === "rate" ||
      col.type === "discount" ||
      col.type === "discountType" ||
      col.type === "quantity" || col.type === ""
    ) {
      return false;
    }else{
       return true
    }
  };
  let filterProduct = LineItems.filter((item) => item.isActive !== 0);

  let lastLineItem = LineItems[LineItems.length - 1];
  return (
    <>
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent" onScroll={handleScroll}>
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {tableHeaders.map((column) => (
                    <>
                      {column.header === "Item Group" ||
                      column.header === "Item Name" ||
                      column.header === "Rate" ||
                      column.header === "Quantity" ||
                      column.header === "UOM" ? (
                        <>
                          <th>
                            {column.header}
                            <label style={{ color: "red" }}>*</label>
                          </th>
                        </>
                      ) : (
                        <>
                          <th>{column.header}</th>
                        </>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filterProduct.length !== 0 &&
                  filterProduct.map((row, i) => {
                    let rowIndex = filterProduct.findIndex((idd) => idd == row);

                    return (
                      <>
                        <tr>
                          <>
                            {RowData &&
                              RowData.map((col) =>
                                col.component === "Editablecell" ? (
                                  <>
                                    <td>
                                      <div className="quantityDropdownBlock">
                                        <Grid className="selectAndTextfield">
                                          <Grid
                                            xs={12}
                                            md={12}
                                            className="dynamicSelectBlock"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <input
                                             onWheel={(e) => e.target.blur()}
                                              type={
                                                col.type !== "remarks" &&
                                                col.type !== "itemMake" &&
                                                col.type !== "hsncode" &&
                                                col.type !== "uom"
                                                  ? "number"
                                                  : "text"
                                              }
                                              name={col.type + "_" + rowIndex}
                                              disabled={
                                                col.type === "uom"
                                                  ? true
                                                  : col.type === "netAmount"
                                                  ? true
                                                  : col.type === "taxPercent"
                                                  ? true
                                                  : col.type === "hsncode"
                                                  ? true
                                                  : col.type ===
                                                    "discountedRate"
                                                  ? true
                                                  : col.type === "nan"
                                                  ? row.itemGroupValue === "997" ? false:true
                                                   : col.type === "discount"
                                                  ? isEmpty(row.discountType)
                                                    ? true
                                                    : false
                                                  : HeaderData.quotationNo !==
                                                    ""
                                                  ? disableField(row, col)
                                                  : false
                                              }
                                              value={
                                                col.type === "remarks"
                                                  ? row.remarks
                                                  : col.type === "itemMake"
                                                  ? row.itemMake
                                                  : col.type === "hsncode"
                                                  ? row.hsncode
                                                  : col.type === "rate"
                                                  ? row.rate
                                                  : col.type === "discount"
                                                  ? row.discount
                                                  : col.type ===
                                                    "discountedRate"
                                                  ? row.discountedRate
                                                  : col.type === "quantity"
                                                  ? row.quantity
                                                  : col.type === "uom"
                                                  ? row.uom
                                                  : col.type === "taxPercent"
                                                  ? row.taxPercent
                                                  : col.type === "netAmount"
                                                  ? row.netAmount
                                                  : ""
                                              }
                                              onChange={OnhandleChange({
                                                key: col.type + "_" + rowIndex,
                                                row,
                                              })}
                                              onBlur={HandleBlur({
                                                key: col.type + "_" + rowIndex,
                                                row,
                                              })}
                                              className="inputBlockContainer"
                                              style={{
                                                backgroundColor:
                                                  col.type === "taxPercent" ||
                                                  col.type === "hsncode" |
                                                  col.type ===
                                                    "discountedRate" ||
                                                  (col.type === "discount" &&
                                                    row.discountType === "")
                                                    ? "#ccd6db"
                                                    : col.type === "discount"
                                                    ? isEmpty(row.discountType)
                                                      ? "#ccd6db"
                                                      : ""
                                                    : "",
                                                textAlign:
                                                  col.type !== "remarks" &&
                                                  col.type !== "itemMake" &&
                                                  col.type !== "hsncode" &&
                                                  col.type !== "uom"
                                                    ? "right"
                                                    : "",
                                                paddingRight: "8px",
                                              }}
                                              onKeyDown={
                                                col.type !== "remarks" &&
                                                col.type !== "itemMake" &&
                                                col.type !== "hsncode" &&
                                                col.type !== "uom"
                                                  ? (e) =>
                                                      [
                                                        "e",
                                                        "E",
                                                        "+",
                                                        "-",
                                                      ].includes(e.key) &&
                                                      e.preventDefault()
                                                  : () => {}
                                              }
                                            />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </td>
                                  </>
                                ) : col.component === "DynamicSelect" ? (
                                  <td>
                                    <div className="quantityDropdownBlock">
                                      <Grid className="selectAndTextfield">
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: LineItemsDisplayTxtField[
                                              col.type + row.id + "1"
                                            ]
                                              ? "none"
                                              : "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Tooltip
                                            TransitionComponent={Fade}
                                            TransitionProps={{ timeout: 600 }}
                                            title={
                                              row.itemGroupId === ""
                                                ? col.type === "itemId"
                                                  ? "Please Select Item Group"
                                                  : col.type === "itemId" &&
                                                    row.itemName
                                                : col.type === "itemId" &&
                                                  row.itemName
                                            }
                                          >
                                            <input
                                              type="text"
                                              className="inputBlockContainer"
                                          style={{
                                            paddingRight: "8px",
                                            backgroundColor:
                                                col.type === "itemId" && row.itemGroupId === "" && isEmpty(row.itemGroupValue)
                                                    ? "#ccd6db" 
                                                    : col.type === "itemGroupId" && row.itemGroupId === "997"
                                                    ? "#ccd6db" 
                                                    : row.itemGroupValue === "997" && col.type === "itemId" && col.type !== "discountType"
                                                    ? "" 
                                                    : row.itemGroupValue === "997"&& col.type !== "discountType"
                                                    ? "#ccd6db" 
                                                    : "",
                                        }}
                                              value={
                                                col.type === "itemGroupId"
                                                  ? row.itemGroupName
                                                  : col.type === "itemId"
                                                  ? row.itemName
                                                  : col.type === "discountType"
                                                  ? row.discountName
                                                  : ""
                                              }
                                              onChange={OnhandleChange({
                                                key: col.type + "_" + rowIndex,
                                                row,
                                              })}
                                              onClick={() => {
                                                displayField(row, col.type, 1);
                                              }}
                                              disabled={
                                                // HeaderData.quotationNo !== "" 
                                                // && row.itemGroupId !== 1252 
                                                // ? disableField(row, col)
                                                // : row.itemGroupId === ""
                                                //   ? col.type === "itemId"
                                                // ? true
                                                // : false
                                                // : col.type === "itemId" && isEmpty(row.itemGroupId) ? true :
                                                // col.type ==="itemGroupId"&& row.itemGroupValue === "997" ? true:  false
                                                col.type === "itemId"
                                                ? row.itemGroupId === ""
                                                  ? true
                                                  : false:
                                                   col.type === "itemId" && isEmpty(row.itemGroupId) ? true :
                                                   col.type ==="itemGroupId"&& row.itemGroupValue === "997" ? true:  false
                                              }
                                             
                                            />
                                          </Tooltip>
                                        </Grid>
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: LineItemsDisplayDropField[
                                              col.type + row.id + "2"
                                            ]
                                              ? "flex"
                                              : "none",
                                            alignItems: "center",
                                          }}
                                        >
                                          <DynamicSelect
                                            arrayOfData={
                                              col.type === "itemGroupId"
                                                ? ItemGroupData
                                                : col.type === "itemId"
                                                ? row.ItemNameData
                                                : col.type === "discountType"
                                                ? discount_Type
                                                : ""
                                            }
                                            className="dropdownBlockContainer"
                                            onSelectChange={handleSelectChange}
                                            selected={
                                              col.type === "itemGroupId"
                                                ? row.itemGroupValue
                                                : col.type === "itemId"
                                                ? row.itemValue
                                                : col.type === "discountType"
                                                ? row.discountType
                                                : ""
                                            }
                                            name={
                                              col.type === "itemGroupId"
                                                ? "itemGroupId"
                                                : col.type === "itemId"
                                                ? "itemId"
                                                : col.type === "discountType"
                                                ? "discountType"
                                                : ""
                                            }
                                            row={row}
                                            update={
                                              row.itemGroupValue ||
                                              row.itemValue ||
                                              row.discountType
                                                ? 1
                                                : 0
                                            }
                                            isDropdownOpen={isDropdownOpen}
                                            handleMenuOpen={handleMenuOpen}
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </td>
                                ) : (
                                  ""
                                )
                              )}
                          </>
                          <td>
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Delete"
                            >
                              <IconButton
                                onClick={() => {
                                  onDel(row, i);
                                }}
                                disabled={
                                  filterProduct.length === 1
                                    ? true
                                    : lastLineItem === row
                                    ? true
                                    : false
                                }
                              >
                                <DeleteIcon className="deleteDisplayButton" />
                              </IconButton>
                            </Tooltip>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div style={{ marginTop: "5px" }}>
            <span
              className="OtherCharges"
              onClick={() => {
                addOtherCharges();
              }}
            >
              Click Here To Add Printing Charges/Labour Charges/Transport
              Charges/Delivery Charges/Packing & Forwarding Charges/Other
              Charges
            </span>
          </div>
          <div className="consoleFormContainer">
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 12">
                      <div
                        className="calculationBlockTable"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <table>
                          <tr>
                            <td>Taxable Value:</td>
                            <b> {parseFloat(HeaderData.netTotal).toFixed(2)}</b>
                          </tr>
                          <tr>
                            <td>Total IGST:</td>
                            <b>
                              {" "}
                              {parseFloat(HeaderData.totalIGST).toFixed(2)}
                            </b>
                          </tr>
                        <tr>
                          <td>Total SGST:</td>
                          <td>
                            <b>
                              {HeaderData.customerId
                                ? parseFloat(HeaderData.totalCGST).toFixed(2)
                                : HeaderData.brokerId
                                ? "0.00"
                                : ""}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td>Total CGST:</td>
                          <td>
                            <b>
                              {HeaderData.customerId
                                ? parseFloat(HeaderData.totalSGST).toFixed(2)
                                : HeaderData.brokerId
                                ? "0.00"
                                : ""}
                            </b>
                          </td>
                        </tr>
                          <tr>
                            <td>
                              <b>
                                Brokerage @
                                {HeaderData.brokerCommisionPercent || "0.00"}%
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>Total Value with Tax:</td>
                            <b>
                              {parseFloat(HeaderData.TotalAmount).toFixed(2)}
                            </b>
                          </tr> 
                        </table>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
  {props.locationState.state !== undefined ? (
        <div className="consoleFormContainer">
          <div style={{ height: '15px' }}></div>
          <div className="consoleFormBlock" style={{ display: 'flex' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2} className="ducumentUploadBlock uploadedContent">
                  <Box gridColumn="span 10">
                    {salesOrderFiles?.length > 0 &&
                      salesOrderFiles.map((item) => (
                        <div key={item.fileUploadId} className="uploadedInfo" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', padding: '5px', border: '1px solid #ccc', borderRadius: '5px' }}>
                          <img src={TickIcon} alt="" className="tickIcon" height={'20px'} width={'20px'} />
                          <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} title="Click to download File">
                            <div
                              className="uplodedFileName"
                              onClick={() => {
                                getFileDownload(
                                  serverConfig.SERVER_URL +
                                  'security-api/api/files/downloadfile/' +
                                  item.fileUploadId,
                                  item.fileName,
                                  item.fileExtension
                                );
                              }}
                              style={{ cursor: 'pointer', marginLeft: '10px', marginRight: '10px' }}
                            >
                              {item.fileName}
                            </div>
                          </Tooltip>
                          <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 100 }} title="Click to Delete File">
                            <Button
                              onClick={() => {
                                onDeleteFile(item.fileUploadId);
                              }}
                              className="deleteButton"
                              style={{ marginLeft: '10px' }}
                            >
                              <img src={DeleteIcons} alt="" height={'20px'} width={'20px'} />
                            </Button>
                          </Tooltip>
                        </div>
                      ))}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                  <Box gridColumn="span 4">
                    <div style={{ marginBottom: '10px' }}>
                      <b>Support Documents:</b><span style={{ color: 'red' }}></span>
                    </div>
                    <div className="personalDetailsUpload">
                      <div className="ducumentUploadBlock">
                        <div className="documentUploadContent">
                          <span className="btn_upload documentUpload" style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={panUploadIcon} alt="" style={{ marginRight: '10px' }} />
                            <input
                              type="file"
                              id="imag"
                              title=""
                              className="input-img"
                              onChange={handleUpload}
                            />
                            Upload Document
                          </span>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  </div>
    </>
  );
};
const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "itemGroupId",
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "itemId",
  },
  {
    no: 3,
    component: "Editablecell",
    type: "remarks",
  },
  {
    no: 3,
    component: "Editablecell",
    type: "itemMake",
  },
  {
    no: 4,
    component: "Editablecell",
    type: "hsncode",
  },
  {
    no: 5,
    component: "Editablecell",
    type: "quantity",
  },
  {
    no: 6,
    component: "Editablecell",
    type: "uom",
  },
  {
    no: 7,
    component: "Editablecell",
    type: "rate",
  },
  {
    no: 8,
    component: "DynamicSelect",
    type: "discountType",
  },
  {
    no: 9,
    component: "Editablecell",
    type: "discount",
  },
  {
    no: 10,
    component: "Editablecell",
    type: "discountedRate",
  },
  {
    no: 11,
    component: "Editablecell",
    type: "taxPercent",
  },
  {
    no: 12,
    component: "Editablecell",
    type: "netAmount",
  },
];
const tableHeaders = [
  {
    header: "Item Group",
  },
  {
    header: "Item Name",
  },
  {
    header: "Remarks",
  },
  {
    header: "Make",
  },
  {
    header: "HSN",
  },
  {
    header: "Quantity",
  },
  {
    header: "UOM",
  },
  {
    header: "Rate",
  },
  {
    header: "Discount Mode",
  },
  {
    header: "Discount",
  },
  {
    header: "Rate After Discount",
  },
  {
    header: "Tax%",
  },
  {
    header: "Amount",
  },
  {
    header: "Actions",
  },
];
const discount_Type = [
  {
    value: "",
    label: "Select",
    name: "Select",
  },
  {
    value: 1,
    label: "Amount",
    name: "Amount",
  },
  {
    value: 2,
    label: "Percentage",
    name: "Percentage",
  },
];

export default LineItems;
