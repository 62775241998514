import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import { qualityMasterList } from "../../../store/Master/QualityMaster/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      qualityMasterListData: [], // to display list data
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      juteQuality: null,
      qualityCode: null,
      dataexp: "",
    };
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
      juteQuality: null,
      qualityCode: null,
    };

    this.props.qualityMasterList(
      serverApi.GET_ALL_QUALITY_MASTER_LIST,
      data,
      this.props.history
    ); // calling Quality master list API
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.QualityMasterList) {
      this.setState({
        qualityMasterListData: props.QualityMasterList.data, // updating quality master list data
        totalRecs: props.QualityMasterList.totalRecords,
      });
    }
  }
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const data = {
        cipher: this.state.userDit.cipher,
        companyId: this.state.companyId,
        startIndex: sizePerPage,
        lastIndex: currentIndex,
        juteQuality: this.state.juteQuality,
        qualityCode: this.state.qualityCode,
      };
      if (currentIndex >= 0) {
        this.props.qualityMasterList(
          serverApi.GET_ALL_QUALITY_MASTER_LIST,
          data,
          this.props.history
        ); // api for pagination
      }
    } else {
      const data = {
        cipher: this.state.userDit.cipher,
        companyId: this.state.companyId,
        startIndex: currentIndex === 0 ? currentIndex + 1 : currentIndex,
        lastIndex: sizePerPage,
        juteQuality: this.state.juteQuality,
        qualityCode: this.state.qualityCode,
      };
      if (currentIndex >= 0) {
        this.props.qualityMasterList(
          serverApi.GET_ALL_QUALITY_MASTER_LIST,
          data,
          this.props.history
        ); // api for pagination
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  handleSearch = (data) => {
    const searchdata = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      juteQuality: data.juteQuality,
      qualityCode: data.qualityCode,
      startIndex: 1,
      lastIndex: 10,
    };
    this.setState({
      juteQuality: data.juteQuality,
      qualityCode: data.qualityCode,
    });
    this.props.qualityMasterList(
      serverApi.GET_ALL_QUALITY_MASTER_LIST,
      searchdata,
      this.props.history
    ); // api for pagination
  };

  render() {
    return (
      <>
        <PaginationGridView
          mdata={this.state.qualityMasterListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          filter_form={"QualityMaster"}
          url="/create_quality_master"
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          apiexp={serverApi.GET_ALL_QUALITY_MASTER_LIST}
          payloadexp={this.state.dataexp}
          expName={"Quality-Master.csv"}
          handleSearch={this.handleSearch}
        />
      </>
    );
  }
}
// Quality master list Headers
const Columns = [
  {
    Header: "Quality Code",
    accessor: "id",
    hidden: false,
  },
  {
    Header: "Item Desc",
    accessor: "itemDesc",
    hidden: true,
  },
  {
    Header: "Jute Quality",
    accessor: "juteQuality",
    hidden: false,
  },
];

const mapStatetoProps = (state) => {
  const { QualityMasterList } = state.QualityMasterReducer; // fetching list from quality master reducer
  return { QualityMasterList };
};

export default withRouter(
  connect(mapStatetoProps, {
    qualityMasterList,
  })(Index)
);
