import { Component } from "react";
import { connect } from "react-redux";
import { serverApi, showSpinner, hideSpinner } from "../../../helpers/Consts";
import { withRouter } from "react-router-dom";
import {
  windingDoffList,
  windingDoffDeleteRow,
} from "../../../store/JuteProduction/WindingDoffEntry/actions";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridDeleteIcon from "../../../assets/images/deleteIconButton.png";

class WindingDoffEntryIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      startIndex: "",
      lastIndex: "",
      WindingDoffContent: [],
      WindingDoffDelData: [],
      fromDate: "",
      toDate: "",
    };
  }
  componentDidMount() {}

  handleSearch = (searchData) => {
    this.setState({
      fromDate: searchData.fromDate,
      toDate: searchData.toDate,
    });
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: this.state.cipher,
      fromDate: searchData.fromDate,
      toDate: searchData.toDate,
    };
    this.props.windingDoffList(
      serverApi.GET_DOFF_ENTRY_LIST,
      data,
      this.props.history
    );
  };
  componentWillReceiveProps(props) {
    // debugger;

    if (props.WindingDoffData) {
      this.setState({
        WindingDoffContent: props.WindingDoffData.data,
      });
      hideSpinner();
    }
    if (props.windingDelRowData) {
      this.setState({
        WindingDoffDelData: props.windingDelRowData.data,
      });
      hideSpinner();
    }
  }

  handledeleteRecord = (row) => {
    this.props.windingDoffDeleteRow(
      serverApi.GET_DOFF_DELETE_ROW + row.id + "/" + this.state.cipher,
      this.props.history,
      this.state.fromDate,
      this.state.toDate
    );
    showSpinner();
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.WindingDoffContent}
          mcolumns={Columns}
          status={true}
          micon={GridDeleteIcon}
          actions={false}
          deleteIcon={true}
          filter_form={"WindingDoffFilter"}
          handleSearch={this.handleSearch}
          handledeleteRecord={this.handledeleteRecord}
        />
      </>
    );
  }
}
// machine master header
const Columns = [
  {
    hidden: true,
    Header: "Doff Id",
    accessor: "id",
  },
  {
    hidden: false,
    Header: "Entry Date Time",
    accessor: "entryDateTime",
  },
  {
    hidden: false,
    Header: "Spell",
    accessor: "spell",
  },
  {
    hidden: false,
    Header: "Yarn Type",
    accessor: "yarnTypeDesc",
  },
  {
    hidden: false,
    Header: "Trolly No",
    accessor: "trollyNo",
  },
  {
    hidden: false,
    Header: "Employee Code",
    accessor: "ebNo",
  },
  {
    hidden: false,
    Header: "Employee Name",
    accessor: "empName",
  },
  {
    hidden: false,
    Header: "Net Weight",
    accessor: "netWeight",
  },
];
const mapStatetoProps = (state) => {
  const { WindingDoffData, beamingDelRowData } = state.WindingDoffEntryReducer;
  return { WindingDoffData, beamingDelRowData };
};

export default withRouter(
  connect(mapStatetoProps, {
    windingDoffList,
    windingDoffDeleteRow,
  })(WindingDoffEntryIndex)
);
