import {
  JUTE_PO_LIST,
  JUTE_PO_LIST_SUCCESSFULL,
  JUTE_PO_LINE_ITEMS,
  JUTE_PO_CREATE,
  JUTE_PO_VIEW_BY_ID,
  JUTE_PO_VIEW_BY_ID_SUCCESSFULL,
  JUTE_PO_HEADER,
  CLEAR_JUTE_PO_HEADER,
  CLEAR_JUTE_PO_LINE_ITEMS,
  GET_JUTE_QUANTITY_BY_JUTE_TYPE,
  GET_JUTE_QUANTITY_BY_JUTE_TYPE_SUCCESSFULL,
  JUTE_PO_CREATE_SUCCESSFULL,
  JUTE_PO_UPDATE,
  JUTE_PO_UPDATE_SUCCESSFULL,
  GET_INDENT_DATA,
  GET_INDENT_DATA_SUCCESSFULL,
  JUTE_INDENT_VIEW_BY_ID_FOR_PO,
  JUTE_INDENT_VIEW_BY_ID_FOR_PO_SUCCESSFULL,
  GET_LATEST_MARKET_RATE,
  GET_LATEST_MARKET_RATE_SUCCESSFULL,
  JUTEPO_UPLOAD_FILES,
  JUTEPO_UPLOAD_FILES_SUCCESSFULL,
  GET_SUPPORT_FILES,
  GET_SUPPORT_FILES_SUCCESSFULL,
  JUTE_PURCHASE_DELETE_FILE,
  JUTE_PURCHASE_DELETE_FILE_SUCCESSFULL,
} from "./actionTypes";
import moment from "moment"

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  JutePoListData: [],  
  JutePoViewByIdData:[],
  JuteQuantityByJuteType:[],
  JutePoCreate:[],
  JutePoUpdate:[],
  getIndentData:[],
  GetLatestMarkerRate:[],
  JuteIndentViewByIdData:[],
  jutePOFilesData:[],
  supportFilesList:[],
  JutePODeleteDocuments:[],
  JutePoHeader:{    
      "hdrId": "",
      "id": "",
      "type": "",
      "status": "",
      "statusName":"",
      "submitter": "",
      "finnacialYear": "",
      "poDate": moment().format().split('T')[0],
      "footerNote": "",
      "supplierId": "",
      "suppCode": "",
      "mukam": "",
      "deliveryAddress": "",
      "tax": null,
      "valueWithTax": "",
      "valueWithoutTax": "0.00",
      "discount": null,
      "frieghtCharge": 0.00,
      "deliveryTimeline": "15",
      "juteUnit": "",
      "vehicleTypeId": "",
      "vehicleTypeName":"",
      "vehicleQuantity": "",
      "brokerId": null,
      "brokerName": null,
      "modOn": null,
      "modBy": null,
      "weight": "",
      "creditTerm": "",
      "remarks": "",
      "indentTypeId": "",
      "exceptedDate": moment().add(15, "days").format("DD-MM-YYYY"),
      "indentNum": null,
      "cTax": null,
      "sTax": null,
      "iTax": null,
      "createdBy": null,
      "createDateTime": "",
      "statusName": "",
      "approveButton": true,
      "taxType": 0,
      "companyId": "",
      "withOrWithout": 0,
      "poComSeq": null,
      "channelCode": "",
      "contrctNo": "",
      "contractDate": null,
      "currencyCode": "",
      "conversationRate": null,
      "brokerageRate": null,
      "brokeragePercentage": null,
      "penality": "",
      "branchId": "",
      "projectId": null,
      "internalNote": null,
      "poValidDate": null,
      "billingBranchId": null,
      "rqName": null,
      "suppName": "",
      "contracDatedes": null,
      "poValidDateDes": null,
      "supplierAddress": "",
      "supplierPhoneNo": null,
      "supplierGst": "",
      "email": "",
      "branchname": "",
      "cellPhone": "",
      "contactPerson": "",
      "modOndes": null,
      "indentHdrId": null,
      "serviceUrl": "",
      "tenantId": "",
      "srcCompanyId": null,
      "mukamName": "",
      "JutePoType":"",
      "files_List":[],
      "weightTotal":"",
      "netTotal":"0.00",
      ApproveButton: false,
      RejectButton: false,
      UpdateButton: true,
      EditButton:false
  },
  JutePoLineItems:[
    {
      "id": 0,
      "jutepoLineItemId":"",
      "itemId": "",
      "itemDesc": "",
      "itemCode":"",
      "qualityCode": "",
      "qualityCodeName":"",
      "unitId": "",
      "quantity": 0,
      "rate": "",
      "allowableMoisturePercentage":0.00,
      "tax": 0,
      "valueWithoutTax": "",
      "cTax": 0,
      "sTax": 0,
      "iTax": 0,
      "bale": 0,
      "loose": 0,
      "poId": null,
      "deptId": "",
      "valueWithTax": 0,
      "indentId": "",
      "actualQuantity": "",
      "itemGroupId": "",
      "discount": 0,
      "taxId": 0,
      "taxPercentage": 0,
      "deptName": "",
      "juteQuality": "",
      "hsnCode": "",
      "marka": "",
      "cropYear": "",
      "gatePoPlaced": 0,
      "remaingQuantity": 0,
      "lastPurchaseRate": null,
      "lastPurchaseDate": null,
      "lastPurchaseSupp": null,
      "stRemQty": 0,
      "pendingAtRcpt": 0,
      "itemGroupName": "",
      "stock":0,
      "amount":"",
      "QuantityListData":[],
      "uom":"",
      "isActive":1
    },        
  ]
};

const JutePoReducer = (state = initialState, action) => {
  switch (action.type) {
    case JUTE_PO_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTE_PO_LIST_SUCCESSFULL:
      state = {
        ...state,
        JutePoListData: action.payload.response,
        loading: true,
      };
      break;

    case JUTE_PO_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
      case JUTE_PO_CREATE_SUCCESSFULL:
        state = {
          ...state,
          JutePoCreate: action.payload.response,
          loading: true,
        };
        break;
        case JUTE_PO_UPDATE:
          state = {
            ...state,
            loading: true,
          };
          break;
          case JUTE_PO_UPDATE_SUCCESSFULL:
            state = {
              ...state,
              JutePoUpdate: action.payload.response,
              loading: true,
            };
            break;   
      case GET_JUTE_QUANTITY_BY_JUTE_TYPE:
        state = {
          ...state,
          loading: true,
        };
        break;
      case GET_JUTE_QUANTITY_BY_JUTE_TYPE_SUCCESSFULL:
        state = {
          ...state,
         JuteQuantityByJuteType: action.payload.response,
          loading: false,
        };
        break;
        case GET_LATEST_MARKET_RATE:
          state = {
            ...state,
            loading: true,
          };
          break;
        case GET_LATEST_MARKET_RATE_SUCCESSFULL:
          state = {
            ...state,
           GetLatestMarkerRate: action.payload.response,
            loading: false,
          };
          break;
    case JUTE_PO_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;

    case JUTE_PO_VIEW_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        JutePoViewByIdData: action.payload.response,
        loading: false,
      };
      break;
      case JUTE_INDENT_VIEW_BY_ID_FOR_PO:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case JUTE_INDENT_VIEW_BY_ID_FOR_PO_SUCCESSFULL:
        state = {
          ...state,
          JuteIndentViewByIdData: action.payload.response,
          loading: false,
        };
        break;
      case GET_INDENT_DATA:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case GET_INDENT_DATA_SUCCESSFULL:
        state = {
          ...state,
          getIndentData: action.payload.response,
          loading: false,
        };
        break;
    case JUTE_PO_HEADER:
      state = {
        ...state,
        JutePoHeader: action.payload,
        loading: true,
      };
      break;
    case CLEAR_JUTE_PO_HEADER:
      state = {
        ...state,
        JutePoHeader: initialState.JutePoHeader,
        loading: true,
      };
      break;
    case JUTE_PO_LINE_ITEMS:
      state = {
        ...state,
        JutePoLineItems: action.payload,
      };
      break;
      case JUTEPO_UPLOAD_FILES:
        state = {
          ...state,
        };
        break;
  
      case JUTEPO_UPLOAD_FILES_SUCCESSFULL:
        state = {
          ...state,
          jutePOFilesData: action.payload.response,
        };
        break;
        case GET_SUPPORT_FILES:
          state = {
            ...state,
            loading: true,
          };
          break;
        case GET_SUPPORT_FILES_SUCCESSFULL:
          state = {
            ...state,
            supportFilesList: action.payload.response.data,
            loading: true,
          };
          break;
          case JUTE_PURCHASE_DELETE_FILE:
            state = {
              ...state,
              loading: true,
            };
            break;
          case JUTE_PURCHASE_DELETE_FILE_SUCCESSFULL:
            state = {
              ...state,
              JutePODeleteDocuments: action.payload.response,
              loading: false,
            };
            break;
      case CLEAR_JUTE_PO_LINE_ITEMS :
        state = {
          ...state, 
          JutePoLineItems:[
            {
              "id": 0,
              "jutepoLineItemId":"",
              "itemId": "",
              "itemDesc": "",
              "itemCode":"",
              "qualityCode": "",
              "qualityCodeName":"",
              "unitId": "",
              "quantity": "",
              "rate": "",
              "allowableMoisturePercentage": 0.00,
              "tax": 0,
              "valueWithoutTax": "",
              "cTax": 0,
              "sTax": 0,
              "iTax": 0,
              "bale": 0,
              "loose": 0,
              "poId": null,
              "deptId": "",
              "valueWithTax": 0,
              "indentId": "",
              "actualQuantity": "",
              "itemGroupId": "",
              "discount": 0,
              "taxId": 0,
              "taxPercentage": 0,
              "deptName": "",
              "juteQuality": "",
              "hsnCode": "",
              "marka": "",
              "cropYear": "",
              "gatePoPlaced": 0,
              "remaingQuantity": 0,
              "lastPurchaseRate": null,
              "lastPurchaseDate": null,
              "lastPurchaseSupp": null,
              "stRemQty": 0,
              "pendingAtRcpt": 0,
              "itemGroupName": "",
              "stock":0,
              "amount":"",
              "QuantityListData":[],
              "uom":"",
              "isActive":1

            },            
             ]
        }
        break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default JutePoReducer;
