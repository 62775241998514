import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import "chartjs-plugin-datalabels";
class DoughnutChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let data = [];
    let labels = [];
    if (this.props.paySummaryList.data) {
      this.props.paySummaryList.data.map((prop) => {
        data.push(prop.amount);
        labels.push(prop.name);
      });
    }

    let customLabels = labels.map((label, index) => ` ${label}`);
    const plugins = [
      {
        beforeDraw: function (chart) {
          var height = chart.height,
            ctx = chart.ctx;
          ctx.restore();
          var fontSize = (height / 150).toFixed(2);
          ctx.font = fontSize + "em sans-serif";

          ctx.save();
        },
      },
    ];
    const chartdata = {
      labels: customLabels,
      datasets: [
        {
          backgroundColor: [
            "#1B668E",
            "#5EA6CC",
            "#9BC3D8",
            "#D1E4EE",
            "#003B5A",
          ],
          data: data,
        },
      ],
    };

    return (
      <div className="container" style={{ height: "330px", marginTop: "35px" }}>
        <Doughnut
          data={chartdata}
          plugins={plugins}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            cutout: 80,
            plugins: {
              legend: {
                display: false,
                position: "top",
                label: {},
              },
            },
          }}
          className="donutContainer"
        />
      </div>
    );
  }
}

export default DoughnutChart;
