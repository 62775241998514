import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import TextFieldEmailReq from "../../../components/TextField/TextFieldEmailReq";
import {
  createCompanyMaster,
  updateCompanyMaster,
} from "../../../store/Master/CompanyMaster/actions";
import { getStateNameList } from "../../../store/Global/DropDownApis/actions";
import DynamicSelect from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import swal from "sweetalert";
import TextFieldMobileReq from "../../../components/TextField/TextFieldMobileReq";
import { validate } from "../../../Utilities/Validations";

class CreateCompanyMasters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      compId: "",
      companyId: "",
      createdBy: "",
      companyName: "",
      companyCode: "",
      companyAddress: "",
      companyState: "",
      companyStateValue: "",
      companyStateCode: "",
      companyGst: "",
      contactPerson: "",
      contactNumber: "",
      emailId: "",
      shippingAddress: "",
      shippingState: "",
      shippingStateValue: "",
      shippingStateCode: "",
      shippingGst: "",
      tallySync: "",
      tallySyncValue: "N",
      stateNameList: [], //displaying state Name list data
    };
  }
  /* Calling Create and Update Api's to Actions */
  onClickCreateUpdate = () => {
    // company state and state code and state GST No
    const companyGstVal = this.state.companyGst;
    const stateCodeVal = this.state.companyStateCode.toString();
    const compareBoth = companyGstVal.slice(0, 2); // "01"
    // shipping State and shipping Code and shipping GST No
    const shippingGstVal = this.state.shippingGst;
    const shippingStateCodeVal = this.state.shippingStateCode.toString();
    const shippingStateBoth = shippingGstVal.slice(0, 2); // "01"

    const {
      companyName,
      companyCode,
      companyAddress,
      companyStateValue,
      companyStateCode,
      companyGst,
      contactPerson,
      contactNumber,
      emailId,
      shippingAddress,
      shippingStateValue,
      shippingStateCode,
      shippingGst,
    } = this.state;

    const validateFields = [
      { field: companyName, msg: "Company Name", type: 1 },
      { field: companyCode, msg: "Company Code", type: 1 },
      { field: companyAddress, msg: "Company Address", type: 1 },
      { field: companyStateValue, msg: "Company State", type: 1 },
      { field: companyStateCode, msg: "Company StateCode", type: 1 },
      { field: companyGst, msg: "Company GstNo", type: 1 },
      { field: contactPerson, msg: "Contact Person", type: 1 },
      { field: contactNumber, msg: "Contact Number", type: 1 },
      { field: emailId, msg: "Email Id", type: 1 },
      { field: shippingAddress, msg: "Shipping Address", type: 1 },
      { field: shippingStateValue, msg: "Shipping State", type: 1 },
      { field: shippingStateCode, msg: "Shipping StateCode", type: 1 },
      { field: shippingGst, msg: "Shipping GstNo", type: 1 },
    ];

    const validObj = validate(validateFields);

    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message} ..!!`);
      } else {
        swal(`Please Select ${validObj.message} ..!!`);
      }
    } else {
      if (this.props.location.state === undefined) {
        const data = {
          company: {
            companyId: this.state.companyId,
            companyName: this.state.companyName,
            companyCode: this.state.companyCode,
            companyAddress1: this.state.companyAddress,
            companyAddress2: this.state.shippingAddress,
            gstNo: this.state.companyGst,
            gstNo2: this.state.shippingGst,
            stateCode1: this.state.companyStateCode,
            stateCode2: this.state.shippingStateCode,
            createdBy: this.state.userDit.userId.toString(),
            orgId: this.state.companyId,
            tallySync: this.state.tallySyncValue,
            contactPerson: this.state.contactPerson,
            contactNo: this.state.contactNumber,
            emailId: this.state.emailId,
            stateName1: this.state.companyStateValue,
            stateName2: this.state.shippingStateValue,
          },
          postAuthenticationDto: {
            cipher: this.state.userDit.cipher,
          },
        };
        if (
          stateCodeVal === compareBoth &&
          shippingStateCodeVal === shippingStateBoth
        ) {
          this.props.createCompanyMaster(
            serverApi.CREATE_COMPANY_MASTER,
            data,
            this.props.history
          ); // calling create company master api
        } else {
          swal(
            " first two letters of statecode and state GST number should be equal"
          );
        }
      } else {
        const data = {
          company: {
            companyId: this.state.compId,
            companyName: this.state.companyName,
            companyCode: this.state.companyCode,
            companyAddress1: this.state.companyAddress,
            companyAddress2: this.state.shippingAddress,
            gstNo: this.state.companyGst,
            stateCode1: this.state.companyStateCode,
            stateCode2: this.state.shippingStateCode,
            gstNo2: this.state.shippingGst,
            orgId: localStorage.getItem("companyId"),
            tallySync: this.state.tallySyncValue,
            contactPerson: this.state.contactPerson,
            stateName1: this.state.companyStateValue,
            stateName2: this.state.shippingStateValue,
            contactNo: this.state.contactNumber,
            emailId: this.state.emailId,
          },
          postAuthenticationDto: {
            cipher: this.state.userDit.cipher,
          },
        };
        if (
          stateCodeVal === compareBoth &&
          shippingStateCodeVal === shippingStateBoth
        ) {
          this.props.updateCompanyMaster(
            serverApi.UPDATE_COMPANY_MASTER,
            data,
            this.props.history
          ); // calling update company master api
        } else {
          swal(
            " first two letters of statecode and state GST number should be equal"
          );
        }
      }
    }
  };
  // function for previous page
  onClickCancel = () => {
    this.props.history.push("/company_master");
  };
  //function for TextFields
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "companyName") {
      this.setState({
        companyName: event,
      });
    }
    if (key === "companyCode") {
      this.setState({
        companyCode: event,
      });
    }
    if (key === "companyAddress") {
      this.setState({
        companyAddress: event,
      });
    }
    if (key === "companyStateCode") {
      this.setState({
        companyStateCode: event,
      });
    }
    if (key === "companyGst") {
      this.setState({
        companyGst: event,
      });
    }
    if (key === "contactPerson") {
      const Person = event.replace(/[^a-zA-Z\s]+/g, "");
      this.setState({
        contactPerson: Person,
      });
    }
    if (key === "contactNumber") {
      this.setState({
        contactNumber: event,
      });
    }
    if (key === "emailId") {
      this.setState({
        emailId: event,
      });
    }
    if (key === "shippingAddress") {
      this.setState({
        shippingAddress: event,
      });
    }
    if (key === "shippingStateCode") {
      this.setState({
        shippingStateCode: event,
      });
    }
    if (key === "shippingGst") {
      this.setState({
        shippingGst: event,
      });
    }
  };
  // function for popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "companyState") {
      let stateCode = selectedName.split("^");
      this.setState({
        companyState: stateCode[0],
        companyStateValue: selectedValue,
        companyStateCode: stateCode[1],
      });
    }
    if (name === "shippingState") {
      let stateCode = selectedName.split("^");
      this.setState({
        shippingState: stateCode[0],
        shippingStateValue: selectedValue,
        shippingStateCode: stateCode[1],
      });
    }
    if (name === "tallySync") {
      this.setState({
        tallySync: selectedValue,
        tallySyncValue: selectedValue,
      });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getStateNameList(
      serverApi.GET_STATE_NAME_LIST,
      this.props.history
    ); // Calling state Name dropdown API
    if (this.props.location.state) {
      this.setState({
        companyId: this.props.location.state.rowData.companyId,
        compId: this.props.location.state.rowData.companyId,
        companyAddress: this.props.location.state.rowData.companyAddress1,
        shippingAddress: this.props.location.state.rowData.companyAddress2,
        companyCode: this.props.location.state.rowData.companyCode,
        companyName: this.props.location.state.rowData.companyName,
        contactNumber: this.props.location.state.rowData.contactNo,
        contactPerson: this.props.location.state.rowData.contactPerson,
        createdBy: this.props.location.state.rowData.createdBy,
        emailId: this.props.location.state.rowData.emailId,
        companyGst: this.props.location.state.rowData.gstNo,
        shippingGst: this.props.location.state.rowData.gstNo2,

        companyStateCode: this.props.location.state.rowData.stateCode1,
        shippingStateCode: this.props.location.state.rowData.stateCode2,
        companyStateValue: parseInt(
          this.props.location.state.rowData.stateName1
        ),
        shippingStateValue: parseInt(
          this.props.location.state.rowData.stateName2
        ),
        tallySyncValue: this.props.location.state.rowData.tallySync,
      });
    }
  }
  componentWillReceiveProps(props) {
    if (props.stateNameList.data) {
      var list = [];
      list = props.stateNameList.data.filter((item) => {
        if (item.value !== 0) {
          return item;
        }
      });

      this.setState({
        stateNameList: list,
      });
    }
  }

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.companyName}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("companyName")}
                      label="Company Name"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.companyCode}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("companyCode")}
                      label="Company Code"
                      disabled={this.state.companyName === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.companyAddress}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("companyAddress")}
                      label="Company Address"
                      disabled={this.state.companyCode === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.stateNameList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.companyStateValue}
                        update={this.props.location.state ? 1 : 0}
                        name="companyState"
                        label="Company State"
                        required
                        isDisabled={
                          this.state.companyAddress === "" ? true : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.companyStateCode}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("stateCode")}
                      label="Company State Code"
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.companyGst}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("companyGst")}
                      label="Company GST No"
                      disabled={
                        this.state.companyStateValue === ""
                          ? true
                          : false || this.state.companyStateValue === 0
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.contactPerson}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("contactPerson")}
                      label="Contact Person"
                      disabled={this.state.companyGst === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldMobileReq
                      value={this.state.contactNumber}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("contactNumber")}
                      label="Contact Number"
                      disabled={this.state.contactPerson === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldEmailReq
                      value={this.state.emailId}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("emailId")}
                      label="Email Id"
                      disabled={this.state.contactNumber === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.shippingAddress}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("shippingAddress")}
                      label="Shipping Address"
                      disabled={this.state.emailId === "" ? true : false}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.stateNameList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.shippingStateValue}
                        update={this.props.location.state ? 1 : 0}
                        name="shippingState"
                        label="Shipping State"
                        required
                        isDisabled={
                          this.state.shippingAddress === "" ? true : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.shippingStateCode}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("shippingStateCode")}
                      label="Shipping State Code"
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.shippingGst}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("shippingGst")}
                      label="Shipping GST No"
                      disabled={
                        this.state.shippingStateValue === ""
                          ? true
                          : false || this.state.shippingStateValue === 0
                          ? true
                          : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={TallySync}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.tallySyncValue}
                        update={this.props.location.state ? 1 : 0}
                        name="tallySync"
                        label="Tally Sync"
                        isDisabled={
                          this.state.shippingStateValue === ""
                            ? true
                            : false || this.state.shippingStateValue === 0
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  {this.props.location.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdOn}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.props.location.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}
const TallySync = [
  { label: "Select", value: null, name: "Select" },
  { label: "YES", value: "Y", name: "YES" },
  { label: "No", value: "N", name: "No" },
];
const mapStatetoProps = (state) => {
  const { CreateCompanyMaster, UpdateCompanyMaster } =
    state.CompanyMasterReducer; // fetching create and update from Company master list reducer
  const { stateNameList } = state.DropDownListReducer; // fetching globle dropdown from yarn type list
  return { CreateCompanyMaster, UpdateCompanyMaster, stateNameList };
};

export default withRouter(
  connect(mapStatetoProps, {
    createCompanyMaster,
    updateCompanyMaster,
    getStateNameList,
  })(CreateCompanyMasters)
);
