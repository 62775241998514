export const JUTE_PO_LIST = "jute_po_list";
export const JUTE_PO_LIST_SUCCESSFULL = "jute_po_list_successfull";
export const JUTE_PO_CREATE = "jute_po_create";
export const JUTE_PO_CREATE_SUCCESSFULL = "jute_po_create_successfull";

export const JUTE_PO_UPDATE = "jute_po_update";
export const JUTE_PO_UPDATE_SUCCESSFULL = "jute_po_update_successfull";

export const JUTE_PO_VIEW_BY_ID = "jute_po_view_by_id";
export const JUTE_PO_VIEW_BY_ID_SUCCESSFULL = "jute_po_view_by_id_successfull";

export const JUTE_PO_HEADER = "jute_po_header";
export const CLEAR_JUTE_PO_HEADER = 'clear_jute_po_header';

export const JUTE_PO_LINE_ITEMS = "jute_po_line_items";
export const CLEAR_JUTE_PO_LINE_ITEMS = "clear_jute_po_line_items";

export const GET_JUTE_QUANTITY_BY_JUTE_TYPE = "get_jute_quantity_by_jute_type";
export const GET_JUTE_QUANTITY_BY_JUTE_TYPE_SUCCESSFULL = "get_jute_quantity_by_jute_type_sucessfull";

export const GET_INDENT_DATA ="get_indent_data";
export const GET_INDENT_DATA_SUCCESSFULL ="get_indent_data_successfull";

export const JUTE_INDENT_VIEW_BY_ID_FOR_PO = "jute_indent_view_by_id_for_po";
export const JUTE_INDENT_VIEW_BY_ID_FOR_PO_SUCCESSFULL = "jute_indent_view_by_id_for_po_successfull";

export const GET_LATEST_MARKET_RATE="get_latest_market_rate";
export const GET_LATEST_MARKET_RATE_SUCCESSFULL="get_latest_market_rate_successfull";

export const JUTEPO_UPLOAD_FILES="jutepo_upload_files";
export const JUTEPO_UPLOAD_FILES_SUCCESSFULL="jutepo_upload_files_successfull";
export const GET_SUPPORT_FILES = "get_support_files";
export const GET_SUPPORT_FILES_SUCCESSFULL = "get_support_files_successfull";
export const JUTE_PURCHASE_DELETE_FILE = "jute_purchase_delete_file";
export const JUTE_PURCHASE_DELETE_FILE_SUCCESSFULL = "jute_purchase_delete_file_successfull";
