import {LEFT_SIDE_MENU, LEFT_SIDE_MENU_SUCCESSFULL, VENDOR_LEFT_SIDE_MENU, VENDOR_LEFT_SIDE_MENU_SUCCESSFULL} from "./actionType";

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    leftsideMenu: [],
    vendorLeftsideMenu:[]

  };

  const LeftSideMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case LEFT_SIDE_MENU:
            state = {
              ...state,
              loading: true,
            };
            break;
          case LEFT_SIDE_MENU_SUCCESSFULL:
            state = {
              ...state,
              leftsideMenu: action.payload.response,
              loading: true,
            };
            break;
            case VENDOR_LEFT_SIDE_MENU:
            state = {
              ...state,
              loading: true,
            };
            break;
          case VENDOR_LEFT_SIDE_MENU_SUCCESSFULL:
            state = {
              ...state,
              vendorLeftsideMenu: action.payload.response,
              loading: true,
            };
            break;
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default LeftSideMenuReducer;