import validatorvals from "validator";

export function required(value, message) {
  if (!value || !value.toString().trim().length) {
    return { error: true, message };
  }
  return false;
}
export function special(value, message) {
  const length = value ? value.toString().length : 0;
  if (length > 0) {
    if (!validatorvals.isAlphanumeric(value)) {
      return { error: true, message };
    }
  }
  return false;
}
export function email(value, message) {
  const length = value ? value.toString().length : 0;
  if (length > 0) {
    if (!validatorvals.isEmail(value)) {
      return { error: true, message };
    }
  }
  return false;
}

export function number(value, message) {
  const length = value ? value.toString().length : 0;
  if (length > 0) {
    if (length > 10) {
      let Message = "Invalid Phone Number";
      return { error: true, Message };
    }
    const result = validatorvals.isNumeric(value);
    if (!result) {
      return { error: true, message };
    }
  }
  if (length === 0) {
    return true;
  } else {
    return false;
  }
}
export function landnumber(value, message) {
  const length = value ? value.toString().length : 0;
  if (length > 0) {
    if (length > 12) {
      let Message = "Invalid Phone Number";
      return { error: true, Message };
    }
    const result = validatorvals.isNumeric(value);
    if (!result) {
      return { error: true, message };
    }
  }
  if (length === 0) {
    return true;
  } else {
    return false;
  }
}

export function pincode(value, message) {
  const length = value ? value.toString().length : 0;
  if (length > 0) {
    if (length > 6) {
      let Message = "Invalid Pincode Number";
      return { error: true, Message };
    }
    const result = validatorvals.isNumeric(value);
    if (!result) {
      return { error: true, message };
    }
  }
  if (length === 0) {
    return true;
  } else {
    return false;
  }
}

export const validate = (fieldsList) => {
  let returnObj;
  for (var i = 0; i <= fieldsList.length - 1; i++) {
    if (fieldsList) {
      if (fieldsList[i].type === 3) {
        if (fieldsList[i].field.length !== 0) {
          for (var j = 0; j <= fieldsList[j].field.length - 1; j++) {
            //THIS IS FOR THE MULTISELECT DROPDOWN VALIDATION .....
            if (fieldsList[j].itemJuteCode === undefined) {
              returnObj = {
                type: fieldsList[i].type,
                message: fieldsList[i].msg,
              };
              break;
            }
          }
        } else {
          returnObj = { type: fieldsList[i].type, message: fieldsList[i].msg };
          break;
        }
      } else if (fieldsList[i].type === 4) {
        if (
          fieldsList[i].field === "" ||
          fieldsList[i].field === undefined ||
          fieldsList[i].field === null
        ) {
          returnObj = { type: fieldsList[i].type, message: fieldsList[i].msg };
          break;
        }
      } else {
        if (
          fieldsList[i].field === "" ||
          fieldsList[i].field === undefined ||
          fieldsList[i].field === "Invalid date" ||
          fieldsList[i].field === null ||
          fieldsList[i].field === "null" ||
          fieldsList[i].field === 0 ||
          fieldsList[i].field.length === 0 ||
          fieldsList.length === 0 ||
          fieldsList[i].field <= 0
        ) {
          returnObj = { type: fieldsList[i].type, message: fieldsList[i].msg };
          break;
        }
      }
    }
  }
  return returnObj;
};

export const hideSpinner = () => {
  document.getElementById("spinner").classList.remove("show");
};
