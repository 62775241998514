import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import moment from "moment";
import { getEmployeeCategoryList } from "../../../store/Global/DropDownApis/actions";
import {
  createTravelCatageries,
  updateTravelCatageries,
} from "../../../store/Master/TravelCatageries/actions";
import { validate } from "../../../Utilities/Validations";
import swal from "sweetalert";

class CreateTravelCatageries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      createdBy: "",
      employeeCategory: "",
      employeeCategoryVal: "",
      travelCategory: "",
      limit: "",
      id: "",
      createdOn: "",
      createdDate: "",
      employeeCatageriesListData: [], // To displaying employee Catageries list
      locationState:"",
    };
  }
  /* Calling Create and Update Api's to Actions */
  onClickCreateUpdate = () => {
    const { employeeCategory } = this.state;
    const validateFields = [
      { field: employeeCategory, msg: "Employee Category", type: 2 },
    ];

    const validObj = validate(validateFields);
    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message} ..!!`);
      } else {
        swal(`Please Select ${validObj.message} ..!!`);
      }
    } else {
      if (this.props.location.state === undefined) {
        const data = {
          companyId: this.state.companyId,
          createdBy: this.state.userDit.userId.toString(),
          employeeCategory: this.state.employeeCategoryVal,
          limit: this.state.limit,
          travelCategory: this.state.travelCategory,
        };
        this.props.createTravelCatageries(
          serverApi.CREATE_TRAVEL_CATAGERIES,
          data,
          this.props.history
        ); // calling create Travel Catageries  api
      } else {
        const data = {
          createdBy: this.state.userDit.userId.toString(),
          companyId: this.state.companyId,
          createdOn: this.props.location.state.rowData.createDateTime,
          employeeCategory: this.state.employeeCategoryVal,
          id: this.state.id,
          limit: this.state.limit,
          travelCategory: this.state.travelCategory,
        };
        this.props.updateTravelCatageries(
          serverApi.UPDATE_TRAVEL_CATAGERIES,
          data,
          this.props.history
        ); // calling update Travel catageries api
      }
    }
  };
  // function for previous page
  onClickCancel = () => {
    this.setState({
      limit: "",
      travelCatageriesName: "",
      employeeCategory: "",
    });
    this.props.history.push("/Travel_Catageries");
  };
  //function for TextFields
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "travelCategory") {
      this.setState({
        travelCategory: event,
      });
    }
    if (key === "limit") {
      this.setState({
        limit: event,
      });
    }
  };
  // function for popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "employeeCategory") {
      this.setState({
        employeeCategory: selectedValue,
        employeeCategoryVal: selectedValue,
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    this.setState({
      locationState: this.props.location,
    });
    window.addEventListener("scroll", this.handleScroll);
    this.props.getEmployeeCategoryList(
      serverApi.GET_EMPLOYEE_CATAGERIES_LIST +
        localStorage.getItem("companyId"),
      this.props.history
    ); // calling for employee catageries list api
    if (this.props.location.state) {
      this.setState({
        createdBy: this.props.location.state.rowData.createdBy,
        createdOn: this.props.location.state.rowData.createDateTime,
        companyId: this.props.location.state.rowData.companyId,
        employeeCategory: this.props.location.state.rowData.employeeCategory,
        id: this.props.location.state.rowData.id,
        limit: this.props.location.state.rowData.limit,
        travelCategory: this.props.location.state.rowData.travelCategory,
      });
      const dateEntered = this.props.location.state.rowData.createDateTime;
      const dateIsValid = moment(dateEntered).format("LLLL");
      this.setState({
        createdDate: dateIsValid,
      });
    }
  }
  componentWillReceiveProps(props) {
    if (props.employeeCategoryList) {
      if (props.employeeCategoryList.data) {
        if (props.employeeCategoryList.data.length !== 0) {
          var list = [];
          list = props.employeeCategoryList.data.filter((item) => {
            if (item.value !== 0) {
              return item;
            }
          });

          this.setState({
            employeeCatageriesListData: list,
          });
        }
      }
    }
  }
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.travelCategory}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("travelCategory")}
                      label="Travel Category Name"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.employeeCatageriesListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.employeeCategory}
                        update={this.props.location.state ? 1 : 0}
                        name="employeeCategory"
                        label="Employee Category"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.limit}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("limit")}
                      label="Limit"
                      type={"number"}
                    />
                  </Box>
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdDate}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.state.locationState.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { UpdateTravelCatageries } = state.TravelCatageriesReducer; // fetching create and update from Travel Catageries list reducer
  const { employeeCategoryList } = state.DropDownListReducer; // fetching department list from global dropdown reducer
  return { employeeCategoryList, UpdateTravelCatageries };
};

export default withRouter(
  connect(mapStatetoProps, {
    createTravelCatageries,
    updateTravelCatageries,
    getEmployeeCategoryList,
  })(CreateTravelCatageries)
);
