import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PaymentLineItem from "./PaymentLineItem";
import { getBillNoList } from "../../../store/Accounting/Payments/actions";
import { serverApi } from "../../../helpers/Consts";

class PaymentAddItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      rowcount: 1,
      show: false,
      checkAll: false,
      companyId: localStorage.getItem("companyId"),
    };
    this.state.filterText = "";
    this.state.tooltip = "item description....";
    this.state.products = [
      {
        billId: 0,
        amount: 0,
        createOn: null,
        createdBy: this.state.userDit.userId,
        invoiceId: null,
        invoiceNo: null,
        isActive: 1,
        modifiedBy: null,
        modifiedOn: null,
        receiptNo: null,
        transactionId: null,
        trnDetailId: 0,
      },
    ];
  }

  componentDidMount() {
    if (this.props.header.ledgerId === 0) {
      var data = {
        companyId: this.state.companyId,
        fromDate: "",
        suppType: "",
        toDate: "",
        tranStatus: 3,
        tranType: "B",
      };
      this.props.getBillNoList(
        serverApi.BILLS_NO_LIST,
        data,
        this.props.history
      );
    } else {
      var data = {
        companyId: this.state.companyId,
        fromDate: "",
        suppType: "",
        ledgerId: this.props.header.ledgerId,
        toDate: "",
        tranStatus: 3,
        tranType: "B",
      };
      this.props.getBillNoList(
        serverApi.BILLS_NO_LIST,
        data,
        this.props.history
      );
    }
  }

  handleUserInput(filterText) {
    this.setState({ filterText: filterText });
  }
  handleCheckbox(isChecked, id, isAllCheck) {
    let list = this.state.products;
    if (isAllCheck) {
      list = list.map((product) => {
        return { ...product, checked: isChecked };
      });
      this.setState({ products: list, allChecked: isChecked });
    } else {
      list.forEach((product, i) => {
        if (product?.id === id) {
          list[i].checked = isChecked;
        }
      });
      let allChecked = list.every((item) => item.checked);
      this.setState({ products: list, allChecked: allChecked });
    }
  }

  handleRowDel(product) {
    var index = this.state.products.indexOf(product);

    this.state.products.splice(index, 1);
    this.setState(this.state.products);
  }
  handleKeydownEvent(event) {
    const resultData = [];
    this.props.indentList.data.map((prop, i) => {
      if (event.target.value === prop.item_code) {
        resultData.push({
          id: prop.id,
          item_code: prop.item_code,
          department: prop.department,
          item_group: prop.item_group,
          item: prop.item,
          quantity: prop.quantity,
          rate: prop.rate,
          amount: prop.amount,
        });
      }
    });

    if (event.keyCode == 9) {
      if (resultData.length > 0) {
        this.state.products.map((prop) => {
          resultData.push({
            id: prop.id,
            item_code: prop.item_code,
            department: prop.department,
            item_group: prop.item_group,
            item: prop.item,
            quantity: prop.quantity,
            rate: prop.rate,
            amount: prop.amount,
          });
        });
        this.setState({
          products: resultData,
        });
      }
      let id = 0;
      this.state.products.map((prop) => (id = prop.id));
      if (event.target.id == id) this.handleAddEvent(event);
    }
  }

  handleAddEvent(evt) {
    var id = this.state.rowcount;
    var product = {
      id: id,
      item_code: "",
      department: "",
      quantity: 0,
      item: "",
      amount: 0,
      rate: 0,
      item_group: "",
    };
    var mcount = Number(this.state.rowcount) + 1;
    this.state.products.push(product);
    this.setState(this.state.products);
    this.setState({ rowcount: mcount });
  }

  handleProductTable(evt) {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    var products = this.state.products.slice();
    var newProducts = products.map(function (product) {
      for (var key in product) {
        if (key == item.name && product.id == item.id) {
          product[key] = item.value;
        }
      }
      return product;
    });
    this.setState({ products: newProducts });
  }
  handleChangeQuantity(selectedValue, selectedName, id) {
    this.setState({
      qty: selectedValue,
      uomCode: selectedName,
    });
    const resultData = [];
    if (this.state.products) {
      this.state.products.map((prop) => {
        if (prop.id == id) {
          var qty = prop.quantity;
          var vsqty = "";
          if (qty) {
            qty.map((val) => {
              if (selectedValue == val.value) {
                vsqty = val;
              }
            });
          }
          resultData.push({
            id: prop.id,
            item_code: prop.item_code,
            department: prop.department,
            item_group: prop.item_group,
            item: prop.item,
            quantity: prop.quantity,
            rate: prop.rate,
            amount: prop.amount,
            qty: vsqty.value,
            uomCode: vsqty.name,
          });
        } else {
          resultData.push({
            id: prop.id,
            item_code: prop.item_code,
            department: prop.department,
            item_group: prop.item_group,
            item: prop.item,
            quantity: prop.quantity,
            rate: prop.rate,
            amount: prop.amount,
            qty: prop.qty,
            uomCode: prop.uomCode,
          });
        }
      });

      this.setState({
        products: resultData,
      });
      this.props.onclickSave(resultData);
    }
  }

  render() {
    return (
      <div>
        <PaymentLineItem
          tableHeaders={tableHeaders}
          RowData={RowData}
          products={this.props.products}
          header={this.props.header}
          delete={this.props.delete}
        />
      </div>
    );
  }
}
const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "billNo",
    dropDown: "dropDown",
    name: "billNo",
    Items: true,
  },
  {
    no: 5,
    component: "Inputadorment",
    type: "amount",
  },
];

const tableHeaders = [
  {
    header: "Bill No",
  },
  {
    header: "Amount",
  },
  {
    header: "Actions",
  },
];

const mapStatetoProps = (state) => {
  const { indentList } = state.Indent;
  return { indentList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getBillNoList,
  })(PaymentAddItems)
);
