import {
  JUTE_QUALITY_CHECK,
  JUTE_QUALITY_CHECK_SUCCESSFULL,
  JUTE_QUALITY_CHECK_CREATE,
  JUTE_COMMIT_MATERIAL_READING,
  JUTE_QUALITYCHECK_VIEWBYID,
  JUTE_QUALITYCHECK_VIEWBYID_SUCCESSFULL,
  JUTE_QUALITY_CHECK_HEADERS,
  CLEAR_JUTE_QUALITY_CHECK_HEADERS,
  JUTE_QUALITY_CHECK_LINEITEMS,
  CLEAR_JUTE_QUALITY_CHECK_LINEITEMS,
  JUTE_QUALITY_CHECK_UPDATE,
  JUTE_QUALITY_CHECK_UPDATE_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  JuteQualityCheckData: [],
  JuteQualityCheckUpdate:{},
  juteQualityCheckViewByIdData: [],
  qualityCheckHeader: {
    id: 0,
    chalanNo: "",
    chalanDate: "",
    vehicleNo: "",
    driverName: "",
    brokerName: null,
    transporter: "",
    brokerAddress: null,
    inDate: "",
    updateBy: null,
    inTime: "",
    outTime: null,
    outDate: null,
    updateDateTime: "",
    finYear: "",
    mukam: "",
    poNo: "",
    netWeight: null,
    grossWeight: 0.00,
    actualWeight: 0.00,
    mrNo: "",
    suppCode: "",
    supplierName: "",
    challanWeight: 0.00,
    vehicleType: 0,
    qcCheck: "",
    createdBy: "",
    tareWeight: 0.00,
    remarks: "",
    withorWithOutPo: 0,
    companyId: 2,
    unitConversion: "",
    status: 1,
    entryCompSeq: 0,
    consignmentDate: null,
    consignmentNo: null,
    branchId: 0,
    poHdrId: null,
    challanDateDesc: "",
    createdDate: "",
    createdTime: "",
    outTimeDesc: "",
    outDateDesc: "",
    statusDesc: "",
    consignmentDateDesc: null,
    indentHdrId: null,
  },
  juteQualityCheckLineItems: [
    {
      lineItemId:0,
      recId: 0,
      hdrId: 0,
      advisedJuteTyp: "",
      actualJuteTyp: "",
      advisedQuality: "",
      actualQuality: "",
      advisedQuantity: "",
      actualQuantity: 0,
      receivedIn: "",
      autoDateTime: "",
      unitId: "",
      remarks: null,
      kgs: null,
      poLineItemNum: 0,
      createdBy: "",
      isActive: "1",
      qcJuteType: "",
      qcJuteQuality: "",
      qcJuteQuantity: "",
      advisedWeight: null,
      actualWeight: 0,
      qcJuteWeight: 0,
      allowableMoisture: null,
      itemCode:"",
      readings: [
        {
          id: 1,
          reading: 0,
          readingId:1,
        },
      ],
      actualQualityDesc: "",
      advisedQualityDesc: "",
      itemDesc: "",
      advisedItemDesc: "",
      avgReadings: 0,
      qcJuteTypeDesc: "",
      qcJuteQualityDesc: "",
    },
  ],
};

const JuteQualityCheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case JUTE_QUALITY_CHECK:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTE_QUALITY_CHECK_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
      case JUTE_QUALITY_CHECK_UPDATE_SUCCESSFULL:
      state = {
        ...state,
        JuteQualityCheckUpdate: action.payload.response,
        loading: true,
      };
      break;
    case JUTE_QUALITY_CHECK_SUCCESSFULL:
      state = {
        ...state,
        JuteQualityCheckData: action.payload.response,
        loading: true,
      };
      break;
    case JUTE_COMMIT_MATERIAL_READING:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTE_QUALITYCHECK_VIEWBYID:
      state = {
        ...state,
        loading: true,
      };
      break;

    case JUTE_QUALITYCHECK_VIEWBYID_SUCCESSFULL:
      state = {
        ...state,
        juteQualityCheckViewByIdData: action.payload.response,
        loading: false,
      };
      break;

    case JUTE_QUALITY_CHECK_HEADERS:
      state = {
        ...state,
        qualityCheckHeader: action.payload,
        loading: true,
      };
      break;
    case CLEAR_JUTE_QUALITY_CHECK_HEADERS:
      state = {
        ...state,
        qualityCheckHeader: initialState.JutePoHeader,
        loading: true,
      };
      break;
    case JUTE_QUALITY_CHECK_LINEITEMS:
      state = {
        ...state,
        juteQualityCheckLineItems: action.payload,
      };
      break;
    case CLEAR_JUTE_QUALITY_CHECK_LINEITEMS:
      state = {
        ...state,
        juteQualityCheckLineItems: [
          {
            lineItemId:0,
            recId: 0,
            hdrId: 0,
            advisedJuteTyp: "",
            actualJuteTyp: "",
            advisedQuality: "",
            actualQuality: "",
            advisedQuantity: "",
            actualQuantity: 0,
            receivedIn: "",
            autoDateTime: "",
            unitId: "",
            remarks: null,
            kgs: null,
            poLineItemNum: 0,
            createdBy: "",
            isActive: "1",
            qcJuteType: "",
            qcJuteQuality: "",
            qcJuteQuantity: "",
            advisedWeight: null,
            actualWeight: 0,
            qcJuteWeight: 0,
            allowableMoisture: null,
            itemCode:"",
            readings: [
              {
                id: 0,
                reading: 0,
                readingId:1,
              },
            ],
            actualQualityDesc: "",
            advisedQualityDesc: "",
            itemDesc: "",
            advisedItemDesc: "",
            avgReadings: 0,
            qcJuteTypeDesc: "",
            qcJuteQualityDesc: "",
          },
        ],
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default JuteQualityCheckReducer;
