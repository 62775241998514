//import { Switch } from "react-router-dom";
import {
  REQUESTOTP,
  REQUESTOTP_SUCCESSFULL,
  SUBMIT_GST_CREDENTIALS_OTP,
  SUBMIT_GST_CREDENTIALS_OTP_SUCCESSFULL,
  UPDATECLIENTCREDENTIALS,
  UPDATECLIENTCREDENTIALS_SUCCESSFULL,
} from "./actionTypes";
const initialState = {
  UpdateClientCredentials: "",
  requestOtp:"",
  submitGstOtpResponse:""
};

const GstReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATECLIENTCREDENTIALS:
      state = {
        ...state,
      };
      break;

    case UPDATECLIENTCREDENTIALS_SUCCESSFULL:
      state = {
        ...state,
        UpdateClientCredentials: action.payload.response,
      };
      break;
      case REQUESTOTP:
      state = {
        ...state,
      };
      break;

    case REQUESTOTP_SUCCESSFULL:
      state = {
        ...state,
        requestOtp: action.payload.response,
      };
      break;
      case SUBMIT_GST_CREDENTIALS_OTP:
      state = {
        ...state,
      };
      break;

    case SUBMIT_GST_CREDENTIALS_OTP_SUCCESSFULL:
      state = {
        ...state,
        submitGstOtpResponse: action.payload.response,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default GstReducer;
