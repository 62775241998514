import {SPELL_MASTER_LIST,
    SPELL_MASTER_LIST_SUCCESSFULL,
    ADDITEM_TO_SPELL_MASTER_LIST,
    ADDITEM_TO_SPELL_MASTER_LIST_SUCCESSFULL,
    UPDATE_SPELL_MASTER_LIST,
    UPDATE_SPELL_MASTER_LIST_SUCCESSFULL,
    PARENT_SPELL,
    PARENT_SPELL_SUCCESSFULL
} from './actionTypes';



export const getSpellMasterList = (url,data,history) =>{
    return {
        type:SPELL_MASTER_LIST,
        payload:{url,data,history}
    }
}

export const SpellMasterListSuccessfull = (data)=>{
    return {
        type:SPELL_MASTER_LIST_SUCCESSFULL,
        payload:data
    }
}

//Create Spell master
export const postAddItemToSpellMasterList = (url,data,history) =>{
    return {
        type:ADDITEM_TO_SPELL_MASTER_LIST,
        payload:{url,data,history}
    }
}

export const addItemToSpellMasterListSuccessfull = (data)=>{
    return {
        type:ADDITEM_TO_SPELL_MASTER_LIST_SUCCESSFULL,
        payload:data
    }
}
//Update Spell master

export const postUpdateItemToSpellMasterList = (url,data,history) =>{
    return {
        type:UPDATE_SPELL_MASTER_LIST,
        payload:{url,data,history}
    }
}

export const updateItemToSpellMasterListSuccessfull = (data)=>{
    return {
        type:UPDATE_SPELL_MASTER_LIST_SUCCESSFULL,
        payload:data
    }
}
export const getParentSpell = (url,history) =>{
    return {
        type:PARENT_SPELL,
        payload:{url,history}
    }
}
export const getParentSpellSuccessfull = (data) =>{
    return {
        type:PARENT_SPELL_SUCCESSFULL,
        payload:data
    }
}