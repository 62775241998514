import React, { Component } from 'react'
import Grid from '@mui/material/Grid'

import ProfileIcon from '../../assets/images/profileIcon.png'

export default class EmployeeDatabase extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <>
        <div className="row">
          {this.props.data.map((Data, i) => (
            <Grid className="EmployeeDatabaseContainer">
              <div className="userManagementContainer">
                <div className="profileContainerBlock">
                  <img src={ProfileIcon} alt="logo" className="profilePic" />
                  <h1>{Data.first_name}</h1>
                  <span className="employeeId">{Data.eb_id}</span>
                </div>
                <div className="cardViewFooter">
                  <span className="productDesigner">{Data.desig}</span>
                </div>
              </div>
            </Grid>
          ))}
        </div>
      </>
    )
  }
}
