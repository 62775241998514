export const EXPENSE_BOOKING_LIST = "expense_booking_list"; 
export const EXPENSE_BOOKING_LIST_SUCCESSFULL = "expense_booking_list_successfull"
export const WORKER_LIST = 'worker_list'
export const WORKER_LIST_SUCCESSFULL ='worker_list_successfull'
export const ADD_EXPENSE_BOOKING = 'add_expense_booking'
export const ADD_EXPENSE_BOOKING_SUCCESSFULL= 'add_expense_booking_successfull'
export const EXPENSE_BOOKING_VIEW_BY_ID ='expense_booking_view_by_id'
export const EXPENSE_BOOKING_VIEW_BY_ID_SUCCESSFULL ='expense_booking_view_by_id_successfull'
export const COST_CENTER ='cost_center'
export const COST_CENTER_SUCCESSFULL ='cost_center_successfull'
export const WORKER_DATA_CLEAR = 'worker_data_clear';
export const DELETE_FILES='delete_files';
export const DELETE_FILES_SUCCESSFULL='delete_files_successfull';
export const UPLOAD_FILES='upload_files';
export const UPLOAD_FILES_SUCCESSFULL='upload_files_successfull';