import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextFieldNormal from "../../TextField/TextFieldNormal";

class VehicleTypeMasterFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      id:"",
      vehicleType:"",
      weight:"",
    };
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "id") {
      this.setState({
        id: event,
      });
    }
    if (key === "vehicleType") {
      this.setState({
        vehicleType: event,
      });
    }
    if (key === "weight") {
        this.setState({
            weight: event,
        });
      }
  };
  handleSubmit = () => {
    const data = {
      vehicleType:this.state.vehicleType !=="" ?this.state.vehicleType :null,
    };
    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <>
        <Box>
          <Button onClick={this.handleClickOpen}>
            <Tooltip title="Filter">
              <FilterListOutlinedIcon />
            </Tooltip>
          </Button>
          <Dialog
            open={this.state.open}
            PaperProps={{
              sx: {
                position: " fixed",
                top: 0,
                right: 0,
                margin: 0,
                bottom: 0,
                maxHeight: "100vh",
              },
            }}
            keepMounted
            onClose={this.handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <Grid spacing={0}>
              <div className="consoleTextFieldBlock">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div className="filterBlockHeader">
                    <FilterListOutlinedIcon />
                    <span>Filter</span>
                  </div>
                </div>
              </div>
              <Grid item xs={12} className="filterBlockContainer">
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div>
                      <TextFieldNormal
                        label="Vehicle Type"
                        value={this.state.vehicleType}
                        onChange={this.onhandlechange("vehicleType")}
                      />
                    </div>
                  </div>
                </Box>
                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Search"}
                    className="greenButton"
                    handleClick={this.handleSubmit}
                    type="sumbit"
                  />
                </div>
              </Grid>
            </Grid>
          </Dialog>
        </Box>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
//   const { parentLedgerList } = state.DropDownListReducer;
//     return { parentLedgerList }
};

export default withRouter(connect(mapStatetoProps, {
  
})(VehicleTypeMasterFilter));
