import {
  GETQUALITY_BY_ITEMID,
  GETQUALITY_BY_ITEMID_SUCCESSFULL,
  GET_MR_FILES,
  GET_MR_FILES_SUCCESSFULL,
  JUTE_MATERIAL_RECEIPT,
  JUTE_MATERIAL_RECEIPT_SUCCESSFULL,
  JUTE_MATERIAL_VIEW_BYID,
  JUTE_MATERIAL_VIEW_BYID_SUCCESSFULL,
  JUTE_MR_HEADERDATA,
  JUTE_MR_LINEITEMS,
  JUTE_MR_UPLOAD_FILES,
  JUTE_MR_UPLOAD_FILES_SUCCESSFULL,
  MR_LINE_ITEMS,
  UPDATE_JUTE_MR,
  UPDATE_JUTE_MR_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  JuteMaterialReceiptData: [],
  juteMaterialViewById: "",
  updateJuteMrList: [],
  quantityByItemIdData: [],
  mrFilesList:[],
  juteMrHeaderData: {
    id: null,
    goodReceiptDate: "",
    supplierId: "",
    poId: "",
    poDate: "",
    gateEntryNo: null,
    chalanDate: "",
    challanNo: "",
    status: "",
    submitter: null,
    createDate: "",
    agentId: null,
    agentName: null,
    sourceId: null,
    vehicleNo: "",
    modOn: null,
    modBy: null,
    weight: 0,
    mukamId: null,
    autoDateTimeInsert: "",
    createdBy: null,
    companyId: null,
    acYear: null,
    userId: null,
    actualSupplier: "",
    advisedBroker: "",
    actualBroker: "",
    brokerName: null,
    goodReceiptDateDesc: "",
    poDateDesc: "",
    contractDateDesc: null,
    chalanDateDesc: "",
    statusDesc: "",
    createDateDesc: "",
    approveStatus: false,
    gateEntryDate: "",
    gateEntryChallanDate: "",
    juteGateNetWeight: 0,
    createStatus: true,
    unitConversion: "",
    printCount: null,
    agentAddr1: null,
    agentAddr2: null,
    juteReceiptNo: null,
    mrPrintNo: null,
    frieghtPaid: 0,
    agentMRDate: null,
    agentMrPrintNo: null,
    agentMrReceiptNo: null,
    srcHdrId: null,
    srcComId: null,
    consignmentDate: null,
    consignmentNo: null,
    remarks: null,
    branchId: null,
    mrType: "MR",
    tcsPercentage: 0,
    tcsAmount: 0,
    mukamName: "",
    companyName: "",
    transporter: "",
    supplierName: "",
    advisedSuppName: "",
    driverName: "",
    actSup: null,
    advisedBrokerName: null,
    rollbak: false,
    indentHdrId: null,
    poHdrId: null,
  },
  juteMrLineItems: [
    {
      acceptedWeight: 0, //here acceptedWeight is Approved Weight
      actualBale: "",
      actualLoose: 0,
      actualQuality: 0,
      actualWeight: 0, //here actualWeight is mill weight
      advisedItemId: "",
      advisedQuality: 0,
      advisedQuantity: 0,
      advisedWeight: 0,
      agentMRRate: 0,
      allowableMoisture: 0,
      autoDateTimeInsert: "",
      bale: "",
      balesConsumed: 0,
      claimDust: 0,
      claimQuality: "",
      claimRate: 0,
      claimsCondition: "0.0", //here QC Moisture is claims Condition
      companyId: 1,
      conItemDesc: "",
      conQltyDesc: "",
      convertedBale: 0,
      convertedDrum: 0,
      convertedJuteType: "",
      convertedQuality: "",
      convertedWeight: 0,
      createdBy: "",
      cropYear: "",
      debitNotesFlag: "",
      deviation: 0,
      drumConsumed: 0,
      id: 0,
      isActive: 1,
      itemDesc: "",
      itemId: "",
      jcStock: 0,
      juteGateEntryLineItemNo: 0,
      juteIssueRate: 0,
      loose: 0,
      marka: "",
      materialGrnHdrId: 0,
      percentage: 0,
      pota: "",
      premiumAmount: 0,
      qualityDesc: "",
      quantity: 0,
      quantityUnit: "",
      rate: 0,
      remarks: "",
      salesBale: 0,
      salesDrum: 0,
      salesWeight: 0,
      shortageKgs: 0,
      srcCompId: null,
      srcLineId: null,
      srcMrLineId: null,
      srcMrNo: null,
      srcMrPrintNo: null,
      status: "",
      stockQuantityConsumed: 0,
      totalPrice: 0,
      totalPriceWithTax: 0,
      unitConversion: 0,
      variableShortage: 0,
      wareHouseName: "",
      warehouseNo: 0,
      waterDamageAmount: 0,
    },
  ],
  juteMrFilesData:{},

};

const JuteMaterialReceiptReducer = (state = initialState, action) => {
  switch (action.type) {
    case JUTE_MATERIAL_RECEIPT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTE_MATERIAL_RECEIPT_SUCCESSFULL:
      state = {
        ...state,
        JuteMaterialReceiptData: action.payload.response,
        loading: true,
      };
      break;
    case JUTE_MATERIAL_VIEW_BYID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case JUTE_MATERIAL_VIEW_BYID_SUCCESSFULL:
      state = {
        ...state,
        juteMaterialViewById: action.payload.response,
        loading: true,
      };
      break;
    case MR_LINE_ITEMS:
      state = {
        ...state,
        mrlineItems: action.payload,
        loading: true,
      };
      break;
    case UPDATE_JUTE_MR:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_JUTE_MR_SUCCESSFULL:
      state = {
        ...state,
        updateJuteMrList: action.payload.response,
        loading: true,
      };
      break;
    case JUTE_MR_HEADERDATA:
      state = {
        ...state,
        juteMrHeaderData: action.payload,
        loading: true,
      };
      break;

    case JUTE_MR_LINEITEMS:
      state = {
        ...state,
        juteMrLineItems: action.payload,
      };
      break;
    case GETQUALITY_BY_ITEMID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GETQUALITY_BY_ITEMID_SUCCESSFULL:
      state = {
        ...state,
        quantityByItemIdData: action.payload.response,
        loading: true,
      };
      break;
    case JUTE_MR_UPLOAD_FILES:
      state = {
        ...state,
      };
      break;
    case JUTE_MR_UPLOAD_FILES_SUCCESSFULL:
      state = {
        ...state,
        juteMrFilesData: action.payload.response,
      };
      break;
      case GET_MR_FILES:
          state = {
            ...state,
            loading: true,
          };
          break;
        case GET_MR_FILES_SUCCESSFULL:
          state = {
            ...state,
            mrFilesList: action.payload.response.data,
            loading: true,
          };
          break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default JuteMaterialReceiptReducer;
