/*
 * Author: Pritam Kumar Shahi
 * Date: February 29, 2024
 */

import React, { useState, useEffect } from "react";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { Grid, Box } from "@mui/material";
import swal from "sweetalert";

export const StockForm = ({
  isEdit,
  minMax,
  branchList,
  onFieldUpdate,
  tangible,
  tax,
  status,
}) => {
  const filteredBranchList = branchList?.filter(item => !minMax?.some(({ branchId }) => branchId === item?.value)) || [];
  const mergedData = isEdit ? [...minMax, ...filteredBranchList] : branchList || [];

  const initialFormData = mergedData?.map((item) => ({
    minQty: item.minQty || 0,
    maxQty: item.maxQty || 0,
    minOrderQty: item.minOrderQty || 0,
    branchName: item.label || item.branchName,
    branchId: item.value || item.branchId,
    ...(isEdit ? { minMaxId: item.minMaxId} : {}),
  }));

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (index, field) => (value) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      [field]: Number(value)
    };

    // Validation logic
    if (
      field === "minQty" &&
      parseInt(value, 10) > parseInt(updatedFormData[index].maxQty, 10)
    ) {
      swal("Minimum Stock Can't be Greater than Max Stock Limit");
      // Reset the value to 0
      updatedFormData[index] = {
        ...updatedFormData[index],
        minQty: 0,
      };
    } else if (
      field === "maxQty" &&
      parseInt(value, 10) < parseInt(updatedFormData[index].minQty, 10)
    ) {
      swal("Maximum Stock quantity Can't be less than Min Stock Limit");
      // Reset both minQty and maxQty to 0
      updatedFormData[index] = {
        ...updatedFormData[index],
        minQty: 0,
        maxQty: 0,
      };
    }

    setFormData(updatedFormData);
  };

  useEffect(() => {
    onFieldUpdate(formData);
  }, [formData, onFieldUpdate]);

  useEffect(() => {
    setFormData(
      mergedData?.map((item) => ({
        minQty: item.minQty || 0,
        maxQty: item.maxQty || 0,
        minOrderQty: item.minOrderQty || 0,
        branchName: item.label || item.branchName,
        branchId: item.value || item.branchId,
        ...(isEdit ? { minMaxId: item.minMaxId} : {}),
      }))
    );
  }, [minMax, branchList, isEdit]);

  return (
    <>
      {formData !== null && formData && (
        <>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {formData &&
                  formData.map((item, index) => (
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                      key={index}
                    >
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <TextFieldNormal
                            type="text"
                            value={item.branchName}
                            label="Branch"
                            disabled={true}
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <TextFieldNormal
                            type="number"
                            value={item.maxQty}
                            caps="OFF"
                            minValue="0"
                            onChange={handleInputChange(index, "maxQty")}
                            label="Max Stock Limit"
                            disabled={
                              (isEdit && status === 2) ||
                              tax === "" ||
                              tangible === "N" // Disable when "Services" is selected
                            }
                            rightAlign="right"
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <TextFieldNormal
                            type="number"
                            value={item.minQty}
                            caps="OFF"
                            minValue="0"
                            onChange={handleInputChange(index, "minQty")}
                            label="Minimum Stock"
                            disabled={
                              (isEdit && status === 2) ||
                              item.maxQty === "" ||
                              item.maxQty === 0 ||
                              tangible === "N" // Disable when "Services" is selected
                            }
                            rightAlign="right"
                          />
                        </div>
                      </Box>
                      <Box gridColumn="span 3">
                        <div className="consoleTextFieldBlock">
                          <TextFieldNormal
                            type="number"
                            value={item.minOrderQty}
                            caps="OFF"
                            minValue="0"
                            onChange={handleInputChange(index, "minOrderQty")}
                            label="Reorder Quantity"
                            disabled={
                              (isEdit && status === 2) ||
                              item.maxQty === "" ||
                              item.maxQty === 0 ||
                              tangible === "N" // Disable when "Services" is selected
                            }
                          />
                        </div>
                      </Box>
                    </Box>
                  ))}
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default StockForm;