export const ADVANCE_REQUEST_LIST = "advance_request_list"; 
export const ADVANCE_REQUEST_LIST_SUCCESSFULL = "advance_request_list_successfull";
export const CREATE_ADVANCE_REQUEST = 'create_advance_request';
export const CREATE_ADVANCE_REQUEST_SUCCESSFULL ='create_advance_request_successfull';
export const ADVANCE_REQUEST_VIEW_BY_ID = "advance_request_view_by_id"; 
export const ADVANCE_REQUEST_VIEW_BY_ID_SUCCESSFULL = "advance_request_view_by_id_successfull";

export const GET_WORKER_DATA='get_worker_data';
export const GET_WORKER_DATA_SUCCESSFULL='get_worker_data_successfull';
export const WORKER_DATA_CLEAR = 'worker_data_clear';
