export const ITEMGROUP_LIST = "itemgroup_list";
export const ITEMGROUP_LIST_SUCCESSFULL = "itemgroup_list_successfull";
export const CREATE_ITEMGROUP_MASTER = "create_itemgroup_master";
export const CREATE_ITEMGROUP_MASTER_SUCCESSFULL = "create_itemgroup_master_successfull";
export const UPDATE_ITEMGROUP_MASTER = "update_itemgroup_master";
export const UPDATE_ITEMGROUP_MASTER_SUCCESSFULL = "update_itemgroup_master_successfull";
export const ITEM_GROUP_VIEW_BY_ID = "item_group_view_by_id";
export const ITEM_GROUP_VIEW_BY_ID_SUCCESSFULLY = "item_group_view_by_id_successfully";
export const ITEM_GROUP_DROPDOWN_API="item_group_dropdown_api";
export const ITEM_GROUP_DROPDOWN_API_SUCCESSFULL = "item_group_dropdown_api_successfull";

// export const API_ERRORS = 'api_failed';