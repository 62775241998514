import {
    API_ERRORS,
    FINANCIAL_YEAR,
    FINANCIAL_YEAR_LIST_SUCCESSFUL,
  } from "./actionTypes";
  
  
  
  export const getAcadamicYears = (url,history) => { 
    return {
      type: FINANCIAL_YEAR,
      payload: {url,history }
    };
  };
  
  export const AcadamicYearsListSuccessful = (acadamicYears) => {
    return {
      type: FINANCIAL_YEAR_LIST_SUCCESSFUL,
      payload: acadamicYears,
    };
  };
  export const apiErrors = (error) => {
    return {
      type: API_ERRORS,
      payload: error,
    };
  };