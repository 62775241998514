import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomButton from "../../Buttons/Buttons";
import Tooltip from "@mui/material/Tooltip";
import DynamicSelect from "../../../components/Dropdown/Master/DynamicSelectMasters";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getDepartmentList } from "./../../../store/Global/DropDownApis/actions";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { serverApi } from "../../../helpers/Consts";

class IndentFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      costDesc: null,
      costCode: null,
      departmentList: [],
      deptDesc: null,

    };
  }

  // componentDidMount() {
  //   this.props.getDepartmentList(
  //     serverApi.GET_DEPARTMENT_LIST +
  //     localStorage.getItem("companyId") +
  //     "/" +
  //     this.state.userDit.cipher,
  //     this.props.history
  //   ); // calling department dropdown list

  // }
  // componentWillReceiveProps(props) {
  //   if (props.departmentList) {
  //     this.setState({
  //       departmentList: props.departmentList.data, //  updating department list
  //     });
  //   }

  // }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handlePropSelectChange = (selectedName, name) => {
    if (name === "department") {
      if(selectedName=='Select....'){
        this.setState({
          deptDesc: null,
        });
      }else{
        this.setState({
          deptDesc: selectedName,
        });
      }
    }
  };

  handleChange = (key) => (event) => {
    if (key === "costCode") {
      this.setState({
        costCode: event
      })
    }

    if (key === "costDesc") {
      this.setState({
        costDesc: event
      })
    }

    if (key == "deptCode") {
      this.setState({
        deptCode: event
      })
    }
    if (key == "department") {
      this.setState({
        department: event
      })
    }
  }

  handleSubmit = () => {
    var data = {
      costDesc: this.state.costDesc !==''?this.state.costDesc:null,
      costCode: this.state.costCode !==""?this.state.costCode:null,
      deptDesc: this.state.deptDesc,
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleClear = () => {
    this.setState({
      costDesc: "",
      costCode: "",
      deptCode: "",
      department: "",
      departmentList: "",
    });
  };

  render() {

    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid
            spacing={0}>
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
              </div>
            </div>
            <Grid item xs={12} className="filterBlockContainer">

              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <TextFieldNormal
                    value={this.state.costCode}
                    placeholder={"Enter here"}
                    label="Cost Code"
                    onChange={this.handleChange("costCode")}
                  />
                </div>
              </Box>

              <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <TextFieldNormal
                    value={this.state.costDesc}
                    placeholder={"Enter here"}
                    label="Cost Center Name"
                    onChange={this.handleChange("costDesc")}
                  />
                </div>
              </Box>

              {/* <Box gridColumn="span 3">
                <div className="consoleTextFieldBlock">
                  <DynamicSelect
                    arrayOfData={this.state.departmentList}
                    className="dropdownBlockContainer"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    onSelectChange={this.handlePropSelectChange}
                    selected={this.state.deptDesc}
                    update={this.props.location.state ? 1 : 0}
                    name="department"
                    label="Department"
                    
                  />
                </div>
              </Box> */}

              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Search"}
                  className="greenButton"
                  handleClick={this.handleSubmit}
                  type="sumbit"
                />
              </div>
            </Grid>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  const { departmentList } = state.DropDownListReducer; // fetching department and budget heads dropdowns from global api

  return { departmentList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getDepartmentList,
  })(IndentFilter)
);
