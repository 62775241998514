import {JUTE_CREATE_MARKET_RATE, JUTE_MARKET_RATE } from './actionTypes';
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import swal from "sweetalert";

import { getList, postAdd} from '../../../helpers/Server_Helper';
import { JuteMarketRateSuccessfull,JuteCreateMarketRateSuccessfull} from './actions';

function* JuteMarketRateSagaList({ payload: { url, data, history } }) {
    try {
        const response = yield call(getList, url, data);
        yield put(JuteMarketRateSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}

function* JuteCreateMarketRateData({payload:{url,data,history}}){
try{
    const response= yield call(postAdd,url,data);
    yield put(JuteCreateMarketRateSuccessfull({response}));
    swal(response.data.message, {icon:"success"})
    history.push("/jute_market_rate")// // } 
} catch(error) {
    console.log('response', error)
}
}

export function* watchJuteMarketRateList() {
    yield takeEvery(JUTE_MARKET_RATE, JuteMarketRateSagaList)
}

export function* watchJuteCreateMarketRate(){
    yield takeEvery (JUTE_CREATE_MARKET_RATE, JuteCreateMarketRateData)
}

function* JuteMarketRate() {
    yield all([
        fork(watchJuteMarketRateList),
        fork(watchJuteCreateMarketRate),
    ])
}



export default JuteMarketRate;