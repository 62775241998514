import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import {
  CHECKVALIDSUPPID_INVOICE_CHALLAN,
  CREATE_INWARD,
  GET_FILES_OF_INWARD,
  GET_ITEMDETAILS_BYITEM_ITEMGRP_FORINWARD,
  GET_ITEMLIST_BYITEMGROUP_FORINWARD,
  INWARD_LIST,
  POLINEITEMSBYPOIDS,
  UPDATE_INWARD,
} from "./actionTypes";
import {
  CreateInwardSuccessfull,
  InwardLineItemsProps,
  InwardListSuccessfull,
  PoLineItemsListByPoIdSuccessfull,
  UpdateInwardSuccessfull,
  UpdateStoreSuccessfull,
  UpdateMaterialInspectionSuccessfull,
  getFilesofInwardSuccessfull,
  getFilesofMISuccessfull,
  getFilesofSRSuccessfull,
  checkValidSuppIdChallanInvoiceNoSuccessfull,
  getItemDetailsByItemItemGrpForInwardSuccessfull,
  getItemListByItemGroupForInwardSuccessfull
} from "./actions";
import { getList, getListurl, postCallBacklistData } from "../../../helpers/Server_Helper";
import { hideSpinner, serverApi, showSpinner } from "../../../helpers/Consts";
import {
  getBranchList,
  getProjectList,
  getStatusList,
  getSupplierList,
} from "../../Global/DropDownApis/actions";
import swal from "sweetalert";


function* getInwardList({ payload: { url, data, history } }) {
  let userDit = JSON.parse(localStorage.getItem("authUser"));
  showSpinner()
  try {
    const response = yield call(getList, url, data);
    hideSpinner()
    if (response) {
      if (response.data) {
        const data1 = {
          cipher: userDit.cipher,
          companyId: localStorage.getItem("companyId"),
        };
        const Url = serverApi.GET_ALL_PROJECTS;
        yield put(getProjectList(Url, data1, history));
        yield put(getStatusList(serverApi.GET_STATUS_LIST, history));

        const url2 = serverApi.BRANCH_LIST + localStorage.getItem("companyId");
        yield put(getBranchList(url2, history));
        yield put(
          getSupplierList(
            serverApi.GET_ALL_SUPPLIERS_LIST +
            localStorage.getItem("companyId"),
            history
          )
        );
      }
    }
    yield put(InwardListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
    hideSpinner()
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

function* postCreateInward({ payload: { url, data, history, pathname } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    if (response) {
      swal(response.message, { icon: "success" });
    }
    yield put(CreateInwardSuccessfull({ response }));
    if (pathname === "/security_store_gate_entry") {
      history.push("/security_store_gate_entry");
      hideSpinner(true);
    } else {
      history.push("/workReport");
      hideSpinner(true);
    }
  } catch (error) {
    hideSpinner(true);
    console.log(error);
    // if (error) {
    //   swal(error);
    // }
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* updateInward({ payload: { url, data, history, pathname } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    if (response) {
      swal(response.message, { icon: "success" });
    }
    yield put(UpdateInwardSuccessfull({ response }));
    // history.push('/security_store_gate_entry')
    if (pathname === "/security_store_gate_entry") {
      history.push("/security_store_gate_entry");
      hideSpinner(true);
    } else if (pathname === "/workReport") {
      history.push("/workReport");
      hideSpinner(true);
    } else if (pathname === "/store_receipt") {
      history.push("/store_receipt");
      hideSpinner(true);
    } else if (pathname === "/store_material_inspection") {
      history.push("/store_material_inspection");
      hideSpinner(true);
    } else if (pathname === "/store_bill_pass") {
      history.push("/store_bill_pass");
      hideSpinner(true);
    }
  } catch (error) {
    console.log(error);
    hideSpinner(true);

    // if (error === undefined) {
    //   swal("Internal Server Error");
    // }
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

function* updateStore({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    if (response) {
      swal(response.message, { icon: "success" });
    }
    yield put(UpdateStoreSuccessfull({ response }));
    history.push("/security_store_gate_entry");
  } catch (error) {
    console.log(error);
    if (error) {
      swal(error);
    }
  }
}
function* updateMaterialInspection({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    if (response) {
      swal(response.message, { icon: "success" });
    }
    yield put(UpdateMaterialInspectionSuccessfull({ response }));
    history.push("/security_store_gate_entry");
  } catch (error) {
    console.log(error);
    if (error) {
      swal(error);
    }
  }
}

function* InwardPolineitemsByPoIds({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    if (response) {
      let PoList = [];
      if (response) {
        let PolistData = response;
        PolistData = PolistData.filter((item)=>item.qty !== item.qtyRecvd)
        PolistData.map((item) => {
          let obj = {
            lineItemId: item.itemId,
            departmentId: item.departmentId,
            departmentName: item.departmentName,
            itemId: item.itemId,
            itemName: item.itemName,
            itemGroup: item.itemGroup,
            itemGroupId: item.itemGroupId,
            itemCode: item.itemCode,
            rate: item.rate + item.installationRate,
            budgetHeadId: item.budgetHeadId,
            budgetHeadName: item.budgetHeadName,
            inwardQty: item.remainingQuantity > 0 ?item.remainingQuantity : item.qty,
            installationRate:item.installationRate,
            installationAmount:item.installationAmount,
            uomCode: item.uomCode,
            description: item.description,
            make: item.make,
            remarks: item.remarks,
            poId: item.poId,
            poDetailsId: item.id,
            oldQty: item.oldQty,
            hdrStatus: item.status,
            isActive: item.isActive,
            tax: item.tax,
            igstPercentage:
              item.iTaxPercentage === null
                ? item.taxPercentage
                : item.iTaxPercentage,
            cgstPercentage: item.ctaxPercentage,
            sgstPercentage: item.staxPercentage,
            hsnCode: item.hsnCode,
            taxPercentage: item.taxPercentage,
            discountPercentage: item.discountPercentage,
            discountMode: item.discountMode,
            remainingQuantity:item.remainingQuantity
          };

          PoList.push(obj);
        });
      }

      yield put(InwardLineItemsProps(PoList));
    }
    yield put(PoLineItemsListByPoIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getInwardFiles({ payload: { url, history, sourceMenu, menu } }) {
  try {
    showSpinner();
    const response = yield call(getListurl, url);
    if (response) {
      if (menu === "inward") {
        yield put(getFilesofInwardSuccessfull({ response }));
      } else if (menu === "SR") {
        yield put(getFilesofSRSuccessfull({ response }));
      } else if (menu === "MI") {
        yield put(getFilesofMISuccessfull({ response }));
      }
    }

    hideSpinner();
  } catch (error) {
    console.log(error);
  }
}


function* InwardCheckValidSuppchallanInvoice({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    if (response) {
      if (response.status === false) {
        swal(response.message, { icon: "error" });
        yield put(checkValidSuppIdChallanInvoiceNoSuccessfull({ response }));
      }else{
        yield put(checkValidSuppIdChallanInvoiceNoSuccessfull({ response }));
      }
    }
  } catch (error) {
    console.log(error);
    if (error) {
      swal(error);
    }
  }
}

function* getItemListByItemGroupForInward({payload : {url, data, callback, itemgrpId}}){
  try {

    const response = yield call(postCallBacklistData,url, data, callback, itemgrpId);
    yield put(getItemListByItemGroupForInwardSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }

}

function* getItemDetailsByItemItemGroupForInward({ payload: { url, data, callback, itemId } }) {
try {
  const response = yield call(postCallBacklistData, url, data, callback, itemId);
  if (response) {
    // Handle successful response
    yield put(getItemDetailsByItemItemGrpForInwardSuccessfull({ response }));
  }
} catch (error) {
  hideSpinner(); // Hide spinner or loading indicator
  // Check if the error is undefined
  if (error === undefined) {
    // Handle undefined error gracefully
    // Display informative message to the user
    // swal("An unexpected error occurred. Please try again later.", { icon: "error" });
  } else if (error.response) {
    // Handle other types of errors (e.g., API errors)
    hideSpinner();
    if (error.response.data.status) {
      swal(error.response.data.title, { icon: "error" });
      console.error('API error:', error.response.data); // Log detailed error information
    }
  } 
}
}

export function* watchInwardList() {
  yield takeEvery(INWARD_LIST, getInwardList);
  yield takeEvery(POLINEITEMSBYPOIDS, InwardPolineitemsByPoIds);
  yield takeEvery(CREATE_INWARD, postCreateInward);
  yield takeEvery(UPDATE_INWARD, updateInward);
  yield takeEvery(GET_FILES_OF_INWARD, getInwardFiles);
  yield takeEvery(CHECKVALIDSUPPID_INVOICE_CHALLAN, InwardCheckValidSuppchallanInvoice);

  yield takeEvery(GET_ITEMLIST_BYITEMGROUP_FORINWARD, getItemListByItemGroupForInward);
  yield takeEvery(GET_ITEMDETAILS_BYITEM_ITEMGRP_FORINWARD, getItemDetailsByItemItemGroupForInward);
}

function* InwardSaga() {
  yield all([fork(watchInwardList)]);
}

export default InwardSaga;
