import moment from "moment";

import {
  API_ERRORS,
  CREATE_QUOTATIONS,
  CREATE_QUOTATIONS_SUCCESSFULL,
  GET_ITEM_UOM_RELATION_VALUE,
  GET_ITEM_UOM_RELATION_VALUE_SUCCESSFULL,
  GET_QUOTATION_BROKER,
  GET_QUOTATION_BROKER_SUCCESSFULL,
  QUOTATION_HEADERS,
  CLEAR_QUOTATION_HEADERS,
  QUOTATION_ITEM_BY_ITEM_GROUP_ID,
  QUOTATION_ITEM_BY_ITEM_GROUP_ID_SUCCESSFULL,
  QUOTATION_LINEITEMS,
  QUOTATION_LIST,
  QUOTATION_LIST_SUCCESSFULL,
  QUOTATION_UOM_LIST,
  QUOTATION_UOM_LIST_SUCCESSFULL,
  GET_UOM_RELATION_VALUES,
  GET_UOM_RELATION_VALUES_SUCCESSFULL,
  CLEAR_QUOTATION_LINEITEMS,
  QUOTAION_VIEW_BY_ID_IN_QUOTATION,
  QUOTAION_VIEW_BY_ID_IN_SUCCESSFULL,
  QUOTATION_UPDATE,
  QUOTATION_UPDATE_SUCCESSFUL,
  GET_ITEM_DETAILS_BY_ITEM_GROUP_AND_ITEM_ID_AND_COMPANY_ID,
  GET_ITEM_DETAILS_BY_ITEM_GROUP_AND_ITEM_ID_AND_COMPANY_ID_SUCCESSFULL
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  dataLoaded: false,
  Quotation_List: [],
  error_msg: null,
  QuotationUomList: [],
  quotationItemBy_ItemGroupId: [],
  UomRelationValue: [],
  UomRelationValueList:[],
  Quotations: [],
  BrokerData: [],
  ItemDetailsByItemGroupAndCompanyId_List: [],
  quoteHeader: {
    approvedBy: "",
    billingGstNo: "",
    billingContactNo: "",
    billingContactPerson: "",
    shippingGstNo: "",
    shippingContactNo: "",
    shippingContactPerson: "",
    approveStatus: false,
    branchId: "",
    branchName: "",
    brokeragePercentage: "",
    brokerId: "",
    companyId: "",
    createdBy: "",
    createdDate: "",
    customerId: "",
    customerName: "",
    brokerName: "",
    deliveryDays: "",
    deliveryTerms: "",
    finYear: "",
    footerNotes: "",
    frieghtCharges: "",
    internalNote: "",
    netAmount: "",
    paymentTerms: "",
    quotationDate: moment().format("YYYY-MM-DD"),
    quotationExpiryDate: null,
    quotationId: "",
    quotationNo: "",
    shippingAddress: "",
    shippingToAddress: "",
    billingAddress: "",
    billingToAddress: "",
    statusId: 1,
    statusName: "",
    taxAmount: "",
    taxType: "",
    taxName: "",
    termsCondition: "",
    totalAmount: "0.00",
    updatedBy: "",
    userId: "",
    totalIGST: "0.00",
    totalCGST: "0.00",
    totalSGST: "0.00",
    netTotal: "0.00",
    taxTotal: "0.00",
    TotalAmount: "0.00",
    subTotal: "0.00",
    ApproveButton: false,
    RejectButton: false,
    UpdateButton: true,
    printedOn:"",
    updatedOn: ""
  },
  quoteLineItems: [
    {
      id: 1,
      cgstAmount: "",
      cgstPercent: "",
      companyId: "",
      companyName: "",
      createdBy: "",
      createdDate: "",
      discount: "",
      discountedRate: "0.000",
      discountType: "",
      discountName: "",
      discountAmount:"0.00",
      hsnCode: "",
      igstAmount: "",
      igstPercent: "",
      invoiceBalQty: "",
      itemGroupId: "",
      itemGroupName: "",
      itemGroupValue: "",
      itemId: "",
      itemName: "",
      itemValueId: "",
      itemValue: "",
      itemCode: "",
      make: "",
      netAmount: "0.00",
      quantity: "0.000",
      quotationId: "",
      quotationLineitemId: "",
      rate: "0.000",
      remarks: "",
      salesBalQty: "",
      sgstAmount: "",
      sgstPercent: "",
      totalAmount: "0.00",
      uom: "",
      updatedBy: "",
      isActive: 1,
      taxPercent: "0.00",
      currency: "INR",
      updatedOn: null,
      quoteItemData: []
    },
  ],
};

const QuotationReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUOTATION_LIST:
      state = {
        ...state,
        loading: true,
        dataLoaded: false,
      };
      break;

    case QUOTATION_LIST_SUCCESSFULL:
      state = {
        ...state,
        Quotation_List: action.payload.response,
        loading: false,
        dataLoaded: true,
      };
      break;
    case QUOTATION_UOM_LIST:
      state = {
        ...state,
        loading: true,
        dataLoaded: false,
      };
      break;

    case QUOTATION_UOM_LIST_SUCCESSFULL:
      state = {
        ...state,
        QuotationUomList: action.payload.response,
        loading: false,
        dataLoaded: true,
      };
      break;
      
    case GET_UOM_RELATION_VALUES:
      state = {
        ...state,
        loading: true,
        dataLoaded: false,
      };
      break;

    case GET_UOM_RELATION_VALUES_SUCCESSFULL:
      state = {
        ...state,
        UomRelationValue: action.payload.response,
        loading: false,
        dataLoaded: true,
      };
      break;
    case QUOTATION_ITEM_BY_ITEM_GROUP_ID:
      state = {
        ...state,
        loading: true,
        dataLoaded: false,
      };
      break;

    case QUOTATION_ITEM_BY_ITEM_GROUP_ID_SUCCESSFULL:
      state = {
        ...state,
        quotationItemBy_ItemGroupId: action.payload.response,
        loading: false,
        dataLoaded: true,
      };
      break;
 
      
      case GET_ITEM_DETAILS_BY_ITEM_GROUP_AND_ITEM_ID_AND_COMPANY_ID:
      state = {
           ...state,
           loading: true,
     }
     break;
 
     case GET_ITEM_DETAILS_BY_ITEM_GROUP_AND_ITEM_ID_AND_COMPANY_ID_SUCCESSFULL:
      console.log(action.payload,"Reducer")
      state = {
        ...state,
        ItemDetailsByItemGroupAndCompanyId_List : action.payload.response,
        loading: false,
      }
      break;
    case GET_ITEM_UOM_RELATION_VALUE:
      state = {
        ...state,
        loading: true,
        dataLoaded: false,
      };
      break;
    case GET_ITEM_UOM_RELATION_VALUE_SUCCESSFULL:
      state = {
        ...state,
        UomRelationValueList: action.payload.response,
        loading: false,
        dataLoaded: true,
      };
      break;
    case CREATE_QUOTATIONS:
      state = {
        ...state,
        loading: true,
        dataLoaded: false,
      };
      break;
    case CREATE_QUOTATIONS_SUCCESSFULL:
      state = {
        ...state,
        Quotations: action.payload.response,
        loading: false,
        dataLoaded: true,
      };
      break;
    case GET_QUOTATION_BROKER:
      state = {
        ...state,
        loading: true,
        dataLoaded: false,
      };
      break;

    case GET_QUOTATION_BROKER_SUCCESSFULL:
      state = {
        ...state,
        BrokerData: action.payload.response,
        loading: false,
        dataLoaded: true,
      };
      break;
    case API_ERRORS:
      state = {
        ...state,
        loading: false,
        dataLoaded: true,
        error_msg: action.payload,
      };
      break;
    case QUOTATION_HEADERS:
      state = {
        ...state,
        quoteHeader: action.payload,
        loading: false,
        dataLoaded: true,
      };
      break;
    case CLEAR_QUOTATION_HEADERS:
      state = {
        ...state,
        quoteHeader: initialState.quoteHeader,
        loading: false,
        dataLoaded: true,
      };
      break;
      case CLEAR_QUOTATION_LINEITEMS:
        state = {
          ...state,
          quoteLineItems: [
            {
              id: 1,
              cgstAmount: "",
              cgstPercent: "",
              companyId: "",
              companyName: "",
              createdBy: "",
              createdDate: "",
              discount: "",
              discountedRate: "",
              discountType: "",
              discountName: "",
              discountAmount:"0.00",
              hsnCode: "",
              igstAmount: "",
              igstPercent: "",
              invoiceBalQty: "",
        
              itemGroupId: "",
              itemGroupName: "",
              itemGroupValue: "",
        
              itemId: "",
              itemName: "",
              itemValueId: "",
              itemValue: "",
              itemCode: "",
              make: "",
              netAmount: "0.00",
              quantity: "",
              quotationId: "",
              quotationLineitemId: "",
              rate: "",
              remarks: "",
              salesBalQty: "",
              sgstAmount: "",
              sgstPercent: "",
              totalAmount: "",
              uom: "",
              updatedBy: "",
              isActive: 1,
              taxPercent: 0,
              currency: "INR",
              updatedOn: null,
              quoteLineItems: []
            },
          ]
        };
        break;

    case QUOTATION_LINEITEMS:
      state = {
        ...state,
        quoteLineItems: action.payload,
        loading: false,
        dataLoaded: true,
      };
      break;
    case QUOTAION_VIEW_BY_ID_IN_QUOTATION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case QUOTAION_VIEW_BY_ID_IN_SUCCESSFULL:
      state = {
        ...state,
        salesQuotationById: action.payload.response,
        loading: false,
      };
      break;
    case QUOTATION_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case QUOTATION_UPDATE_SUCCESSFUL:
      state = {
        ...state,
        updateSalesQuotation: action.payload.response,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default QuotationReducer;
