import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/PaginationGridView";
import { termsList } from "../../../store/Master/Terms/actions";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      TermsListData: [], // to displaying Terms list data
      startIndex: 1,
      lastIndex: 10,
      autoId: null,
      termsAndConditions: null,
      totalRecs: 0,
      dataexp: "",
    };
  }
  
  componentDidMount() {
    const data = {
      orgId: this.state.userDit.orgId,
      autoId: this.state.autoId,
      termsAndConditions: this.state.termsAndConditions,
      startIndex: this.state.startIndex,
      lastIndex: this.state.lastIndex,
    };
    this.props.termsList(
      serverApi.GET_ALL_TERMS_LIST,
      data,
      this.props.history
    ); // calling terms list data api
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ dataexp: expdata });
  }

  componentWillReceiveProps(props) {
    if (props.TermsList) {
      this.setState({
        totalRecs: props.TermsList.totalRecords,
        TermsListData: props.TermsList.data, // updating terms List data
      });
    }
  }

  handleSearch = (data1) => {
    const data = {
      orgId: this.state.userDit.orgId,
      autoId: data1.autoId,
      termsAndConditions: data1.termsAndConditions,
      startIndex: 1,
      lastIndex: 10,
    };
    this.setState({
      autoId: data1.autoId,
      termsAndConditions: data1.termsAndConditions,
    });
    this.props.termsList(
      serverApi.GET_ALL_TERMS_LIST,
      data,
      this.props.history
    );
  };
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;

    const data = {
      orgId: this.state.userDit.orgId,
      autoId: this.state.autoId,
      termsAndConditions: this.state.termsAndConditions,
    };
    if (type === 1) {
      data.startIndex = sizePerPage;
      data.lastIndex = currentIndex;
      if (currentIndex >= 0) {
        this.props.termsList(
          serverApi.GET_ALL_TERMS_LIST,
          data,
          this.props.history
        );
      }
    } else {
      data.startIndex = currentIndex === 0 ? currentIndex + 1 : currentIndex;
      data.lastIndex = sizePerPage;
      if (currentIndex >= 0) {
        this.props.termsList(
          serverApi.GET_ALL_TERMS_LIST,
          data,
          this.props.history
        );
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.TermsListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          url="/create_terms_master"
          handleSearch={this.handleSearch}
          filter_form={"TermsMaster"}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          apiexp={serverApi.GET_ALL_TERMS_LIST}
          payloadexp={this.state.dataexp}
          expName={"Terms.csv"}
        />
      </>
    );
  }
}
// terms list headers
const Columns = [
  {
    Header: "Description",
    accessor: "termsAndConditions",
    hidden: false,
  },
  {
    Header: "Type",
    accessor: "termsConditionType",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "status",
    hidden: false,
  },
];
const mapStatetoProps = (state) => {
  const { TermsList } = state.TermsReducer; // fetching terms list from terms reducer
  return { TermsList };
};

export default withRouter(
  connect(mapStatetoProps, {
    termsList,
  })(Index)
);
