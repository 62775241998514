import {DELETEFILE, DELETEFILE_SUCCESSFULL, UPLOADED_FILES_OF_CHALLAN, UPLOADED_FILES_OF_CHALLAN_SUCCESSFULL, UPLOADED_FILES_OF_INVOICE, UPLOADED_FILES_OF_INVOICE_SUCCESSFULL, UPLOADED_FILES_OF_SUPPDOCS, UPLOADED_FILES_OF_SUPPDOCS_SUCCESSFULL, UPLOADFILE,UPLOADFILE_SUCCESSFULL} from './actionTypes';



export const postUploadFileList = (url,data,history,sourceMenu,ebId) =>{
    return {
        type:UPLOADFILE,
        payload:{url,data,history,sourceMenu,ebId}
    }
}

export const UploadFileListSuccessfull = (data)=>{
    return {
        type:UPLOADFILE_SUCCESSFULL,
        payload:data
    }
}
export const UploadInvoiceFileList = (url,data,history,sourceMenu,reqObj) =>{
    return {
        type:UPLOADED_FILES_OF_INVOICE,
        payload:{url,data,history,sourceMenu,reqObj}
    }
}

export const UploadInvoiceFilesSuccessfull = (data)=>{
    return {
        type:UPLOADED_FILES_OF_INVOICE_SUCCESSFULL,
        payload:data
    }
}
export const UploadchallanFileList = (url,data,history,sourceMenu) =>{
    return {
        type:UPLOADED_FILES_OF_CHALLAN,
        payload:{url,data,history,sourceMenu}
    }
}
export const UploadChallanFilesSuccessfull = (data)=>{
    return {
        type:UPLOADED_FILES_OF_CHALLAN_SUCCESSFULL,
        payload:data
    }
}
export const UploadsuppdocsFileList = (url,data,history,sourceMenu) =>{
    return {
        type:UPLOADED_FILES_OF_SUPPDOCS,
        payload:{url,data,history,sourceMenu}
    }
}
export const UploadSuppdocsFilesSuccessfull = (data)=>{
    return {
        type:UPLOADED_FILES_OF_SUPPDOCS_SUCCESSFULL,
        payload:data
    }
}

export const deleteFile = (url,history) =>{
    return {
        type:DELETEFILE,
        payload:{url,history}
    }
}

export const deleteFileSuccessfull = (data)=>{
    return {
        type:DELETEFILE_SUCCESSFULL,
        payload:data
    }
}


