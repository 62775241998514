import {
    JUTE_BATCH_PLANNING_LIST, JUTE_PLAN_ASSIGN,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getList } from "../../../helpers/Server_Helper";
import {
  JuteBatchPlanningSuccessfull,
  JuteBatchPlanAssignSuccessfull
} from "./actions";

function* JuteBatchPlanningSagaList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(JuteBatchPlanningSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* AssignPlanSagaList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(JuteBatchPlanAssignSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

export function* watchJuteBatchPlanningList() {
  yield takeEvery(JUTE_BATCH_PLANNING_LIST, JuteBatchPlanningSagaList);
  yield takeEvery(JUTE_PLAN_ASSIGN, AssignPlanSagaList);
}

function* JuteBatchPlanningSaga() {
  yield all([
    fork(watchJuteBatchPlanningList),
  ]);
}

export default JuteBatchPlanningSaga;
