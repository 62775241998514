import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import { getSpellMasterList } from "../../../store/Master/SpellMaster/actions";
import PaginationGridView from "../../../components/Grid/PaginationGridView";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      totalRecs: 0,
      startIndex: 0,
      lastIndex: 10,
      spellMasterListData: [], // updating list data
      spellName: null,
      spellCode: null,
      dataexp: "",
    };
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
      startIndex: 0,
      lastIndex: 10,
      spellName: null,
      spellCode: null,
    };

    this.props.getSpellMasterList(
      serverApi.SHIFT_SPELL_MASTER,
      data,
      this.props.history
    ); // CALLING SPELL MASTER LIST API
    const expdata = {
      ...data,
      startIndex: 0,
      lastIndex: 0,
    };
    this.setState({ loadingdata: true, dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.SpellMaster_List) {
      this.setState({
        totalRecs: props.SpellMaster_List.totalRecords,
        spellMasterListData: props.SpellMaster_List.data, // updating spell master list data
      });
    }
  }
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;
    if (type === 1) {
      const data = {
        companyId: localStorage.getItem("companyId"),
        startIndex: sizePerPage,
        lastIndex: currentIndex,
        spellName: this.state.spellName,
        spellCode: this.state.spellCode,
      };
      if (currentIndex >= 0) {
        this.props.getSpellMasterList(
          serverApi.SHIFT_SPELL_MASTER,
          data,
          this.props.history
        ); // CALLING SPELL MASTER LIST API
      }
    } else {
      const data = {
        companyId: localStorage.getItem("companyId"),
        startIndex: currentIndex,
        lastIndex: sizePerPage,
        spellName: this.state.spellName,
        spellCode: this.state.spellCode,
      };
      if (currentIndex >= 0) {
        this.props.getSpellMasterList(
          serverApi.SHIFT_SPELL_MASTER,
          data,
          this.props.history
        ); // CALLING SPELL MASTER LIST API
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  handleSearch = (data1) => {
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: this.state.userDit.cipher,
      startIndex: 0,
      lastIndex: 10,
      spellName: data1.spellName !== "" ? data1.spellName : null,
      spellCode: data1.spellCode !== "" ? data1.spellCode : null,
    };
    this.setState({
      spellName: data1.spellName,
      spellCode: data1.spellCode,
    });
    this.props.getSpellMasterList(
      serverApi.SHIFT_SPELL_MASTER,
      data,
      this.props.history
    );
  };
  render() {
    return (
      <>
        <PaginationGridView
          mdata={this.state.spellMasterListData}
          mcolumns={Columns}
          micon={GridArrowIcon}
          actions={true}
          handleSearch={this.handleSearch}
          filter_form={"SpellMaster"}
          totalRecs={this.state.totalRecs}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          url="/create_spell_master"
          apiexp={serverApi.SHIFT_SPELL_MASTER}
          payloadexp={this.state.dataexp}
          expName={"Spell-Master.csv"}
        />
      </>
    );
  }
}
// Spell master list Headers
const Columns = [
  {
    Header: "Spell Name",
    accessor: "spellName",
    hidden: false,
  },
  {
    Header: "Spell Code",
    accessor: "spellCode",
    hidden: false,
  },
  {
    Header: "Working Hours",
    accessor: "workingHours",
    hidden: false,
  },
  {
    Header: "Starting Time",
    accessor: "startingTime",
    hidden: false,
  },
  {
    Header: "End Time",
    accessor: "endTime",
    hidden: false,
  },
];
const mapStatetoProps = (state) => {
  const { SpellMaster_List } = state.SpellMasterListReducer; // fetching spell matster list
  return { SpellMaster_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getSpellMasterList,
  })(Index)
);
