export const FINISHING_ENTRY_LIST = "finishing_entry_list";
export const FINISHING_ENTRY_LIST_SUCCESSFULL = "finishing_entry_list_successfull";
export const FINISHING_ENTRY_LIST_DELETE = "finishing_entry_list_delete";
export const FINISHING_ENTRY_LIST_DELETE_SUCCESSFULL = "finishing_entry_list_delete_successfull";
export const GET_PROCESS_MASTER_LIST = "get_process_master_list";
export const GET_PROCESS_MASTER_LIST_SUCCESSFULL = "get_process_master_list_successfull";
export const GET_ALL_MACHINE_DETAILS_DD = "get_all_machine_details_list";
export const GET_ALL_MACHINE_DETAILS_DD_SUCCESSFULL = "get_all_machine_details_list_successfull";
export const ADD_FINISHING_ENTRY = "add_finishing_entry";
export const ADD_FINISHING_ENTRY_SUCCESSFULL = "add_finishing_entry_successfull";
export const DELETE_FINISHING_ENTRY_DATA = "delete_finishing_entry_data";
export const DELETE_FINISHING_ENTRY_DATA_SUCCESSFULL = "delete_finishing_entry_data_successfull";

