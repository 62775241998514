import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/Jute/DynamicSelect";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import TextFieldReq from "../../../components/TextField/TextFieldReq";

class JuteBatchPlanningLineItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
    };
  }
  handleUserInput(filterText) {
    this.setState({ filterText: filterText });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  handlePropSelectChange = (selectedValue, selectedName, name) => {};
  componentWillReceiveProps(props) {}

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    return (
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent">
            <table cellSpacing="0px" cellPadding="0px">
              <thead></thead>
              <tbody>
                <tr>
                  <>
                    <td>
                      <div className="quantityDropdownBlock">
                        <Grid className="selectAndTextfield">
                          <Grid
                            xs={12}
                            md={12}
                            className="dynamicSelectBlock"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <TextFieldReq placeholder="Enter here" />
                          </Grid>
                        </Grid>
                      </div>
                    </td>
                    <td>
                      <div className="quantityDropdownBlock">
                        <Grid className="selectAndTextfield">
                          <Grid
                            xs={12}
                            md={12}
                            className="dynamicSelectBlock"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          ></Grid>
                        </Grid>
                      </div>
                    </td>

                    <td>
                      <div className="quantityDropdownBlock">
                        <Grid className="selectAndTextfield">
                          <Grid
                            xs={12}
                            md={12}
                            className="dynamicSelectBlock"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <DynamicSelect
                              arrayOfData={UnitConversion}
                              className="dropdownBlockContainer"
                              menuPosition="fixed"
                              menuPlacement="auto"
                              onSelectChange={this.handlePropSelectChange}
                              isDropdownOpen={this.state.isDropdownOpen}
                              handleMenuOpen={this.handleMenuOpen}
                              selected={this.state.unitConversion}
                              name="unitConversion"
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </td>
                  </>
                  <td className="">
                    <input
                      type="button"
                      onClick={this.onDelEvent}
                      value="X"
                      className="deleteDisplayButton"
                    />
                    {this.state.delete && (
                      <CommonPopup>
                        <div className="delAddItem">
                          <div>Are you sure you want to delete?</div>
                          <div className="delAddItemBtns">
                            <input
                              type="button"
                              onClick={this.onDel}
                              value="Yes"
                              className="delYesBtn"
                            />
                            <input
                              type="button"
                              onClick={this.onDel}
                              value="No"
                              className="delNoBtn"
                            />
                          </div>
                        </div>
                      </CommonPopup>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const UnitConversion = [
  {
    value: "",
    label: "Select...",
    name: "Select...",
  },
  {
    value: "LOOSE",
    label: "LOOSE",
    name: "LOOSE",
  },
  {
    value: "BALE",
    label: "BALE",
    name: "BALE",
  },
  {
    value: "WASTAGE",
    label: "WASTAGE",
    name: "WASTAGE",
  },
];
const mapStatetoProps = (state) => {
  return {};
};

export default withRouter(
  connect(mapStatetoProps, {})(JuteBatchPlanningLineItems)
);
