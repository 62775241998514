import {
  GETQUALITY_BY_ITEMID,
  GETQUALITY_BY_ITEMID_SUCCESSFULL,
  GET_MR_FILES,
  GET_MR_FILES_SUCCESSFULL,
  JUTE_MATERIAL_RECEIPT,
  JUTE_MATERIAL_RECEIPT_SUCCESSFULL,
  JUTE_MATERIAL_VIEW_BYID,
  JUTE_MATERIAL_VIEW_BYID_SUCCESSFULL,
  JUTE_MR_HEADERDATA,
  JUTE_MR_LINEITEMS,
  JUTE_MR_UPLOAD_FILES,
  JUTE_MR_UPLOAD_FILES_SUCCESSFULL,
  UPDATE_JUTE_MR,
  UPDATE_JUTE_MR_SUCCESSFULL,
} from "./actionTypes";

export const JuteMaterialReceipt = (url, data, history) => {
  return {
    type: JUTE_MATERIAL_RECEIPT,
    payload: { url, data, history },
  };
};
export const JuteMaterialReceiptSuccessfull = (data) => {
  return {
    type: JUTE_MATERIAL_RECEIPT_SUCCESSFULL,
    payload: data,
  };
};

export const getMaterialGoodReceiveDetailsById = (url) => {
  return {
    type: JUTE_MATERIAL_VIEW_BYID,
    payload: { url },
  };
};
export const getMaterialGoodReceiveDetailsByIdSuccessfull = (data) => {
  return {
    type: JUTE_MATERIAL_VIEW_BYID_SUCCESSFULL,
    payload: data,
  };
};

export const juteMrHeaderAction = (data) => {
  return {
    type: JUTE_MR_HEADERDATA,
    payload: data,
  };
};

export const getMrLineItems = (data) => {
  return {
    type: JUTE_MR_LINEITEMS,
    payload: data,
  };
};

export const updateJuteMaterialReceipt = (url, data, history) => {
  return {
    type: UPDATE_JUTE_MR,
    payload: { url, data, history },
  };
};
export const updateJuteMaterialReceiptSuccessfull = (data) => {
  return {
    type: UPDATE_JUTE_MR_SUCCESSFULL,
    payload: data,
  };
};

export const getQuantity_ByItemId = (url) => {
  return {
    type: GETQUALITY_BY_ITEMID,
    payload: { url},
  };
};
export const getQuantity_ByItemIdSuccessfull = (data) => {
  return {
    type: GETQUALITY_BY_ITEMID_SUCCESSFULL,
    payload: data,
  };
};


export const UploadJuteMrFiles = (url,data,history) =>{
  return {
      type:JUTE_MR_UPLOAD_FILES,
      payload:{url,data,history}
  }
}

export const UploadJuteMrFilesSuccessfull = (data)=>{
  return {
      type:JUTE_MR_UPLOAD_FILES_SUCCESSFULL,
      payload:data
  }
}

export const getMrFiles = (url, history, sourceMenu) => {
  return {
    type: GET_MR_FILES,
    payload: { url, history, sourceMenu },
  };
};
export const getMrFilesSuccessfull = (data) => {
  return {
    type: GET_MR_FILES_SUCCESSFULL,
    payload: data,
  };
};

