import React, { Component } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";

class InputWithSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account_number: "",
      show: false,
      hide: false,
    };
  }
  componentDidMount() {
    if (this.props.value) {
      this.setState({});
    }
  }
  handleChange = (event) => {
    this.setState({});
    this.props.handleChange(event.target.value);
  };
  onClick = () => {
    this.setState({
      show: !this.state.show,
    });
  };
  render() {
    return (
      <div
        className={
          this.props.imgUrl ? "consoleAdormentImgBlock" : "consoleAdormentBlock"
        }
      >
        <div className="TextFieldBlock">
          <label>
            {this.props.label}
            {this.props.required && <span className="starcolor">*</span>}
          </label>
        </div>
        <TextField
          onChange={this.handleChange}
          onKeyPress={this.props.onKeyPress}
          value={this.props.value}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" onClick={this.props.onClick}>
                {this.props.btnLable && <Button>{this.props.btnLable}</Button>}
                <img src={this.props.imgUrl} alt="" />
              </InputAdornment>
            ),
          }}
          inputProps={{ maxLength: this.props.maxLength }}
        />
      </div>
    );
  }
}
export default InputWithSearch;
