import React, { Component } from "react";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import {
  getAdvanceRequestList,
  ClearWokerDataForAdvanceRequest,
} from "../../../store/HRMS/AdvanceRequest/actions";
class AdvanceRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      advanceRequestListData: [],
    };
  }
  componentDidMount() {
    this.props.getAdvanceRequestList(
      serverApi.ADVANCE_REQUEST_LIST +
        localStorage.getItem("companyId") +
        "/" +
        this.state.userDit.userId,
      this.props.history
    );
    this.props.ClearWokerDataForAdvanceRequest(null);
  }
  componentWillReceiveProps(props) {
    if (props.advanceRequestList) {
      this.setState({
        advanceRequestListData: props.advanceRequestList.data,
        advanceRequestListColumn: props.advanceRequestList.column,
      });
    }
  }
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.advanceRequestListData}
          mcolumns={advanceRequestListColumn}
          micon={GridArrowIcon}
          actions={true}
          url="/create_advanceRequest"
        />
      </>
    );
  }
}
const advanceRequestListColumn = [
  {
    Header: "Id",
    accessor: "hdrId",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Employee Name",
    accessor: "workerName",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Request Date Description",
    accessor: "requestDateDesc",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Description",
    accessor: "typeOfAdvance",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Amount Requested",
    accessor: "amountRequested",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Bank Name",
    accessor: "bankName",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Status",
    accessor: "statusDesc",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Request Date",
    accessor: "requestDate",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Applicable Year",
    accessor: "applicableYear",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Monthly Salary",
    accessor: "monthlySalary",
    hidden: false,
    csvExport: true,
  },
  {
    Header: "Deduction Year",
    accessor: "deductionYear",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Deduction Starts From",
    accessor: "deductionStartsFrom",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "No Of Applicable Months",
    accessor: "noOfApplicableMonths",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Deduction Upto",
    accessor: "deductionUpto",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Remarks Of Applicant",
    accessor: "remarksOfApplicant",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Updated By",
    accessor: "updatedBy",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Updated Date Time",
    accessor: "updatedDateTime",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Remarks",
    accessor: "remarks",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Is Active",
    accessor: "isActive",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Company Id",
    accessor: "companyId",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "User Id",
    accessor: "userId",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Approve Button",
    accessor: "approveButton",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Eb Number",
    accessor: "ebNo",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "EB Id",
    accessor: "ebId",
    hidden: true,
    csvExport: false,
  },
  {
    Header: "Ledger Id",
    accessor: "ledgerId",
    hidden: true,
    csvExport: false,
  },
];
const mapStatetoProps = (state) => {
  const { advanceRequestList } = state.AdvanceRequestReducer;
  return { advanceRequestList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getAdvanceRequestList,
    ClearWokerDataForAdvanceRequest,
  })(AdvanceRequest)
);
