import {
  ATTENDANCE_LIST,
  ATTENDANCE_LIST_SUCCESSFULL,
  ATTENDANCE_SUMMARY,
  ATTENDANCE_SUMMARY_SUCCESSFULL,
  ATTENDANCE_VIEW,
  ATTENDANCE_VIEW_CLEAR_PROP,
  ATTENDANCE_VIEW_SUCCESSFULL,
  LOGS,
  LOGS_SUCCESSFULL,
  MACHINE_NUMBER,
  MACHINE_NUMBER_SUCCESSFULL,
  MACHINE_NUMBER_CLEAR,
  MARK_ATTENDANCE,
  MARK_ATTENDANCE_SUCCESSFULL,
  PROCESS_LOGS,
  PROCESS_LOGS_SUCCESSFULL,
  SPELL,
  SPELL_SUCCESSFULL,
  UPDATE_ATTENDANCE,
  UPDATE_ATTENDANCE_SUCCESSFULL,
  WORKER_DATA_BY_EB_NUMBER,
  WORKER_DATA_BY_EB_NUMBER_SUCCESSFULL,
  WORKER_DATA_CLEAR,
  ATTENDANCE_DESIGNATION_LIST,
  ATTENDANCE_DESIGNATION_LIST_SUCCESSFULL,
} from "./actionType";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  attendanceList: [],
  spell: [],
  machine_number: [],
  MarkAttendance: [],
  logs: [],
  updateAttendance: [],
  attendance_Summary_List: [],
  ProcessLogs: [],
  WorkerData_ByEb_No: [],
  view_attendance:[],
  AttendanceDesignation:[]
};

const AttendanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ATTENDANCE_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ATTENDANCE_LIST_SUCCESSFULL:
      state = {
        ...state,
        attendanceList: action.payload.response,
        loading: true,
      };
      break;
      case ATTENDANCE_VIEW:
        state = {
          ...state,
          loading: true,
        };
        break;
      case ATTENDANCE_VIEW_SUCCESSFULL:
        state = {
          ...state,
          view_attendance: action.payload.response,
          loading: true,
        };
        break;
        case ATTENDANCE_VIEW_CLEAR_PROP:
          state = {
            ...state,
            view_attendance: action.payload,
            loading: true,
          };
          break;
    case SPELL:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SPELL_SUCCESSFULL:
      state = {
        ...state,
        spell: action.payload.response.data,
        loading: true,
      };
      break;
    case MACHINE_NUMBER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case MACHINE_NUMBER_SUCCESSFULL:
      state = {
        ...state,
        machine_number: action.payload.response,
        loading: true,
      };
      break;
      case MACHINE_NUMBER_CLEAR:
        state = {
          ...state,
          machine_number: action.payload,
          loading: true,
        };
        break;
      
    case MARK_ATTENDANCE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case MARK_ATTENDANCE_SUCCESSFULL:
      state = {
        ...state,
        MarkAttendance: action.payload.response,
        loading: true,
      };
      break;
    case LOGS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGS_SUCCESSFULL:
      state = {
        ...state,
        logs: action.payload.response,
        loading: true,
      };
      break;
    case UPDATE_ATTENDANCE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_ATTENDANCE_SUCCESSFULL:
      state = {
        ...state,
        updateAttendance: action.payload.response,
        loading: true,
      };
      break;
    case ATTENDANCE_SUMMARY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ATTENDANCE_SUMMARY_SUCCESSFULL:
      state = {
        ...state,
        attendance_Summary_List: action.payload.response.data,
        loading: true,
      };
      break;
    case PROCESS_LOGS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PROCESS_LOGS_SUCCESSFULL:
      state = {
        ...state,
        ProcessLogs: action.payload.response,
        loading: true,
      };
      break;
    case WORKER_DATA_BY_EB_NUMBER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case WORKER_DATA_BY_EB_NUMBER_SUCCESSFULL:
      state = {
        ...state,
        WorkerData_ByEb_No: action.payload.response.data,
        loading: true,
      };
      break;
    case WORKER_DATA_CLEAR:
      state = {
        ...state,
        WorkerData_ByEb_No: action.payload,
        loading: true,
      };
      break;
      case ATTENDANCE_DESIGNATION_LIST:
        state = {
          ...state,
          loading: true,
        };
        break;
      case ATTENDANCE_DESIGNATION_LIST_SUCCESSFULL:
        state = {
          ...state,
          AttendanceDesignation: action.payload.response.data,
          loading: true,
        };
        break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AttendanceReducer;
