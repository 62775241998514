import Cookies from "universal-cookie";
import cons, {
  serverApi,
  getTenantId,
  showSpinner,
  hideSpinner,
} from "./Consts";
import { post } from "axios";
import { getCookie, removeCookies } from "../helpers/Cookies";
import swal from "sweetalert";
import axios from "axios";

const ck = new Cookies();

export function getDataFromUrl(url, callback, i = null) {
  var headers = {
    Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
  };
  if (getTenantId(window.location.hostname) !== null) {
    headers = {
      Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
      "X-TenantId": getTenantId(window.location.hostname),
      CompanyID: getCookie(cons.COMPANY_ID),
    };
  }
  return fetch(url, {
    headers: headers,
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (
        responseJson.error === "unauthorized" ||
        responseJson.error === "invalid_token"
      ) {
        removeCookies();
        window.location.reload();
      }
      if (responseJson.status !== 400) callback(responseJson, i);
    })
    .catch((error) => {
      console.error(error);
    });
}

export function requestUomSimple(
  url,
  data,
  callback,
  groupCode,
  itemId,
  priceUom,
  quantityUom,
  itemPrice,
  itemQuantity,
  selectedUom2,
  selectedUom3
) {
  var headers = {
    Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
    Accept: "application/json",
    // "Access-Control-Allow-Origin": "localhost:3000",
    "Content-Type": "application/json",
  };
  var tenant = "";
  if (data.tenantId !== undefined) {
    tenant = data.tenantId;
  } else {
    tenant = getTenantId(window.location.hostname);
  }
  if (getTenantId(window.location.hostname) !== null) {
    headers = {
      Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
      "X-TenantId": tenant,
      Accept: "application/json",
      // "Access-Control-Allow-Origin": "localhost:3000",
      "Content-Type": "application/json",

      CompanyID: getCookie(cons.COMPANY_ID),
    };
  }
  fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((json) => {
      if (json.error === "unauthorized" || json.error === "invalid_token") {
      }
      callback(
        json,
        groupCode,
        itemId,
        priceUom,
        quantityUom,
        itemPrice,
        itemQuantity,
        selectedUom2,
        selectedUom3
      );
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getDataFromUrlSimple(
  url,
  callback,
  itemId,
  priceUom,
  quantityUom,
  itemPrice,
  itemQuantity,
  selectedUom2,
  selectedUom3
) {
  var headers = {
    Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
  };
  if (getTenantId(window.location.hostname) !== null) {
    headers = {
      Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
      "X-TenantId": getTenantId(window.location.hostname),
      CompanyID: getCookie(cons.COMPANY_ID),
    };
  }
  return await fetch(url, {
    headers: headers,
  })
    .then(async (response) => await response.json())
    .then((responseJson) => {
      if (
        responseJson.error === "unauthorized" ||
        responseJson.error === "invalid_token"
      ) {
        removeCookies();
        window.location.reload();
      }
      if (responseJson.status !== 400)
        callback(
          responseJson,
          priceUom,
          quantityUom,
          itemPrice,
          itemQuantity,
          selectedUom2,
          selectedUom3
        );
    })
    .catch((error) => {
      console.error(error);
    });
}
export function getFile(url, callback) {
  var headers = {
    Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
  };
  if (getTenantId(window.location.hostname) !== null) {
    headers = {
      Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
      "X-TenantId": getTenantId(window.location.hostname),
      CompanyID: getCookie(cons.COMPANY_ID),
    };
  }
  return fetch(url, {
    headers: headers,
  })
    .then((response) =>
      response.blob().then((blob) => {
        let Url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = Url;
        a.download = "PayPeriod.xlsx";
        a.click();
      })
    )

    .catch((error) => {
      console.error(error);
    });
}

export function postDownload(url, data, callback) {
  var headers = {
    Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
    Accept: "application/json",
    // "Access-Control-Allow-Origin": "localhost:3000",
    "Content-Type": "application/json",
  };
  if (getTenantId(window.location.hostname) !== null) {
    headers = {
      Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
      "X-TenantId": getTenantId(window.location.hostname),
      Accept: "application/json",
      // "Access-Control-Allow-Origin": "localhost:3000",
      "Content-Type": "application/json",
      CompanyID: getCookie(cons.COMPANY_ID),
    };
  }
  fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  })
    .then((response) =>
      response.blob().then((blob) => {
        let Url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = Url;
        a.download = "PayRoll.xlsx";
        a.click();
      })
    )

    .catch((error) => {
      console.error(error);
    });
}

export function oAuthList(url, data, callback) {
  // var url = serverApi.OAUTH_LOGIN;
  var values =
    "grant_type=" +
    "password" +
    "&username=" +
    data.userName +
    "&password=" +
    data.password;
  var headers = {
    Authorization: "Basic dHV0b3JpYWxzcG9pbnQ6bXktc2VjcmV0LWtleQ==",
    "Content-Type": "application/x-www-form-urlencoded",
  };
  if (getTenantId(window.location.hostname) !== null) {
    headers = {
      Authorization: "Basic dHV0b3JpYWxzcG9pbnQ6bXktc2VjcmV0LWtleQ==",
      "X-TenantId": getTenantId(window.location.hostname),
      "Content-Type": "application/x-www-form-urlencoded",
    };
  }

  fetch(url, {
    method: "POST",
    headers: headers,
    body: values,
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (
        responseJson.error === "unauthorized" ||
        responseJson.error === "invalid_token"
      ) {
        removeCookies();
        window.location.reload();
      }
      callback(responseJson);
    })
    .catch((error) => {
      console.log(error);
    });
}
export function getUsersubMenuById(id, callback) {
  var headers = {
    Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
  };
  if (getTenantId(window.location.hostname) !== null) {
    headers = {
      Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
      "X-TenantId": getTenantId(window.location.hostname),
      CompanyID: getCookie(cons.COMPANY_ID),
    };
  }
  return fetch(
    serverApi.API_USER + serverApi.GET_MENUS_SUBMENUS_BY_GROUP + id,
    {
      headers: headers,
    }
  )
    .then((response) => response.json())
    .then((json) => {
      if (json.error === "unauthorized" || json.error === "invalid_token") {
        removeCookies();
        window.location.reload();
      }

      callback(json);
    })
    .catch((error) => {
      console.error(error);
    });
}
export const uploadInvoice = (url, val, uId, file, cb, fileName) => {
  const formData = new FormData();
  formData.append("imageFile", file);
  formData.append("billId", uId);
  formData.append("docFile", val);
  formData.append("fileName", fileName);
  formData.append("createdBy", getCookie(cons.USER_NAME));
  var headers = {
    "Content-Type": "multipart/form-data",
  };
  if (getTenantId(window.location.hostname) !== null) {
    headers = {
      "X-TenantId": getTenantId(window.location.hostname),
      "Content-Type": "multipart/form-data",
      CompanyID: getCookie(cons.COMPANY_ID),
    };
  }
  const config = {
    headers: headers,
  };
  post(url, formData, config)
    .then(function (response) {
      cb(response.data);
    })
    .catch(function (err) {
      console.log(err);
    });
};
export const uploadProfilePic = (uId, file, cb) => {
  const url = serverApi.PROFILE_PIC_UPLOAD;
  const formData = new FormData();
  formData.append("profilePic", file);
  formData.append("id", uId);
  formData.append("createdBy", getCookie(cons.USER_NAME));
  formData.append("cipher", getCookie(cons.CIPHER));
  var headers = {
    Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
    "Content-Type": "multipart/form-data",
  };
  if (getTenantId(window.location.hostname) !== null) {
    headers = {
      Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
      "Content-Type": "multipart/form-data",
      "X-TenantId": getTenantId(window.location.hostname),
      CompanyID: getCookie(cons.COMPANY_ID),
    };
  }
  const config = {
    headers: headers,
  };
  post(url, formData, config)
    .then(function (response) {
      if (
        response.error === "unauthorized" ||
        response.error === "invalid_token"
      ) {
        removeCookies();
        window.location.reload();
      }

      cb(response.data);
    })
    .catch(function (err) {
      console.log(err);
    });
};

export const uploadFormdataWithImage = (url, file, cb) => {
  const formData = new FormData();
  formData.append("multipartFile", file);
  if (getTenantId(window.location.hostname) !== null) {
    var headers = {
      Authorization:
        localStorage.getItem("token_type") +
        localStorage.getItem("access_token"),
      "Content-Type": "multipartFile",
      "X-TenantId": getTenantId(window.location.hostname),
      CompanyID: getCookie(cons.COMPANY_ID),
    };
  }
  const config = {
    headers: headers,
  };
  post(url, formData, config)
    .then(function (response) {
      if (
        response.error === "unauthorized" ||
        response.error === "invalid_token"
      ) {
        removeCookies();
        window.location.reload();
      } else {
        swal(response.data.message);
      }

      cb(response.data);
      swal(response.data.message, { icon: "success" });
    })
    .catch(function (err) {
      console.log(err);
    });
};

export const uploadFormdataWithPayProcess = (url, file, cb) => {
  const formData = new FormData();
  formData.append("multipartFile", formData);
  if (getTenantId(window.location.hostname) !== null) {
    var headers = {
      Authorization:
        localStorage.getItem("token_type") +
        localStorage.getItem("access_token"),
      "Content-Type": "multipartFile",
      "X-TenantId": getTenantId(window.location.hostname),
      CompanyID: getCookie(cons.COMPANY_ID),
    };
  }
  const config = {
    headers: headers,
  };
  post(url, formData, config)
    .then(function (response) {
      if (
        response.error === "unauthorized" ||
        response.error === "invalid_token"
      ) {
        removeCookies();
        window.location.reload();
      } else {
      }
      cb(response.data);
    })
    .catch(function (err) {
      console.log(err);
    });
};

export const uploadExcelData = (url, file, cb) => {
  const formData = new FormData();
  formData.append("payrollInputSheet", file);
  if (getTenantId(window.location.hostname) !== null) {
    var headers = {
      Authorization:
        localStorage.getItem("token_type") +
        localStorage.getItem("access_token"),
      "Content-Type": "multipartFile",
      "X-TenantId": getTenantId(window.location.hostname),
      CompanyID: getCookie(cons.COMPANY_ID),
    };
  }
  const config = {
    headers: headers,
  };
  showSpinner();
  post(url, formData, config)
    .then(function (response) {
      hideSpinner();
      if (
        response.error === "unauthorized" ||
        response.error === "invalid_token"
      ) {
        removeCookies();
        window.location.reload();
      } else {
        swal(response.data.message);
      }
      cb(response.data);
    })
    .catch(function (err) {
      hideSpinner();
      swal(err);
    });
};

export const uploadFile = (
  fileName,
  file,
  fileExtension,
  sourceMenu,
  displayName,
  taskUniqueId,
  cb
) => {
  const formData = new FormData();
  formData.append("fileName", fileName);
  formData.append("fileUpload", file);
  formData.append("fileExtension", fileExtension);
  formData.append("sourceMenu", sourceMenu);
  formData.append("displayName", displayName);
  formData.append("taskUniqueId", taskUniqueId);
  formData.append("createdBy", getCookie(cons.USERID));
  formData.append("fileCompanyId", getCookie(cons.COMPANY_ID));

  var headers = {
    "Content-Type": "multipart/form-data",
    Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
  };
  if (getTenantId(window.location.hostname) !== null) {
    headers = {
      "X-TenantId": getTenantId(window.location.hostname),
      Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
      "Content-Type": "multipart/form-data",
      CompanyID: getCookie(cons.COMPANY_ID),
    };
  }
  const config = {
    headers: headers,
  };
  post(serverApi.FILE_UPLOAD, formData, config)
    .then(function (response) {
      cb(response.data);
    })
    .catch(function (err) {
      console.log(err);
    });
};
export function getProfileDownload(url, name, type, callback) {
  var headers = {
    Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
  };
  if (getTenantId(window.location.hostname) !== null) {
    headers = {
      Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
      "X-TenantId": getTenantId(window.location.hostname),
      CompanyID: getCookie(cons.COMPANY_ID),
    };
  }
  return fetch(url, {
    headers: headers,
  })
    .then((response) =>
      response.blob().then((blob) => {
        var fileType = "image/" + type;
        if (type === "pdf" || type === "xlsx") fileType = "application/" + type;
        var file = new Blob([blob], { type: fileType });
        var fileURL = URL.createObjectURL(file);
        callback(fileURL);
      })
    )

    .catch((error) => {
      console.error(error);
    });
}
export async function getFileDownload(url, name, type, callback) {
  var headers = {
    Authorization:
      localStorage.getItem("token_type") + localStorage.getItem("access_token"),
  };
  if (getTenantId(window.location.hostname) !== null) {
    headers = {
      Authorization:
        localStorage.getItem("token_type") +
        localStorage.getItem("access_token"),
      "X-TenantId": getTenantId(window.location.hostname),
      CompanyID: getCookie(cons.COMPANY_ID),
    };
  }
  try {
    const response = await fetch(url, {
      headers: headers,
    });
    const blob = await response.blob();
    // let url = window.URL.createObjectURL(blob);
    var fileType = "image/" + type;
    if (type === "pdf") fileType = "application/" + type;
    if (type === "xlsx" || type === "xls")
      fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" +
        type;
    var file = new Blob([blob], { type: fileType });
    const url_1 = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url_1;
    link.setAttribute("download", name);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error(error);
  }
}

export function getFormDownload(url, name, type, callback) {
  var headers = {
    Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
  };
  if (getTenantId(window.location.hostname) !== null) {
    headers = {
      Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
      "X-TenantId": getTenantId(window.location.hostname),
      CompanyID: getCookie(cons.COMPANY_ID),
    };
  }
  return fetch(url, {
    headers: headers,
  })
    .then((response) =>
      response.blob().then((blob) => {
        var fileType = "image/" + type;
        if (type === "pdf") fileType = "application/" + type;
        if (type === "xlsx" || type === "xls")
          fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" +
            type;
        var file = new Blob([blob], { type: fileType });

        const Url = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = Url;
        link.setAttribute("download", name);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();
        link.parentNode.removeChild(link);
      })
    )

    .catch((error) => {
      console.error(error);
    });
}
export function deleteDataFromUrl(url, callback) {
  var headers = {
    Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
  };
  if (getTenantId(window.location.hostname) !== null) {
    headers = {
      Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
      "X-TenantId": getTenantId(window.location.hostname),
      CompanyID: getCookie(cons.COMPANY_ID),
    };
  }
  return fetch(url, {
    method: "DELETE",
    headers: headers,
    body: {},
  })
    .then((response) => response)
    .then((responseJson) => {
      if (
        responseJson.error === "unauthorized" ||
        responseJson.error === "invalid_token"
      ) {
        removeCookies();
        window.location.reload();
      }
      if (responseJson.status === 200) {
        callback({ status: true, message: "Deleted Successfully..!!" });
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export function requestList(url, data, callback, CompanyId) {
  var headers = {
    Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  var tenant = "";
  if (data.tenantId !== undefined) {
    tenant = data.tenantId;
  } else {
    tenant = getTenantId(window.location.hostname);
  }
  if (getTenantId(window.location.hostname) !== null) {
    headers = {
      Authorization: getCookie(cons.TOKEN_TYPE) + getCookie(cons.ACCESS_TOKEN),
      "X-TenantId": tenant,
      Accept: "application/json",
      "Content-Type": "application/json",
      CompanyID: getCookie(cons.COMPANY_ID),
    };
  }
  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((error) => {
      console.log(error);
    });
}
