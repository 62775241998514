//import { Switch } from "react-router-dom";

import {
    API_ERRORS,
    COSTCENTER_LIST,CREATE_COSTCENTER_MASTER,UPDATE_COSTCENTER_MASTER,
    COSTCENTER_LIST_SUCCESSFULL,CREATE_COSTCENTER_MASTER_SUCCESSFULL,UPDATE_COSTCENTER_MASTER_SUCCESSFULL,
  } from "./actionTypes";
  
  const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    CostCenter_List: [],
    CreateCostCenterMaster:[],
    UpdateCostCenterMaster:[],
    error_msg: null,
  };
  
  const CostCenterListReducer = (state = initialState, action) => {
    switch (action.type) {
      case COSTCENTER_LIST:
        state = {
          ...state,
          loading: true,
        };
        break;
      case COSTCENTER_LIST_SUCCESSFULL:
        state = {
          ...state,
          CostCenter_List: action.payload.response,
          loading: false,
        };
        break;
        case CREATE_COSTCENTER_MASTER:
        state = {
          ...state,
          loading: true,
        };
        break;
      case CREATE_COSTCENTER_MASTER_SUCCESSFULL:
        state = {
          ...state,
          CreateCostCenterMaster: action.payload.response,
          loading: false,
        };
        break;
        case UPDATE_COSTCENTER_MASTER:
        state = {
          ...state,
          loading: true,
        };
        break;
      case UPDATE_COSTCENTER_MASTER_SUCCESSFULL:
        state = {
          ...state,
          UpdateCostCenterMaster: action.payload.response,
          loading: false,
        };
        break;
        case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default CostCenterListReducer;
  