import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import {
  createMukamMaster,
  updateMukamMaster,
} from "../../../store/Master/MukamMaster/actions";
import { getItemsList } from "../../../store/Global/DropDownApis/actions";
import AutoMultiselect from "../../../components/Dropdown/AutoMultiselect";
import moment from "moment";
import { validate } from "../../../Utilities/Validations";
import swal from "sweetalert";

class CreateMukamMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      createdBy: "",
      mukamName: "",
      mukamId: "",
      itemsList: [],
      itemsListData: [],
      deptlist: [],
      itemId: "",
      createdOn: "",
      createdDate: "",
      locationState: "",
    };
  }
  componentDidMount() {
    this.setState({
      locationState:this.props.location,
    })
    const data = {
      companyId: localStorage.getItem("companyId"),
      consumable: "",
      itemGroupId: "999",
      saleable: "",
    };
    this.props.getItemsList(
      serverApi.GET_ITEMS_BY_ITEMGROUP_ID,
      data,
      this.props.history
    );

    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData) {
      }

      this.setState({
        createdBy: this.props.location.state.rowData.createdBy,
        createdOn: this.props.location.state.rowData.createdOn,
        companyId: this.props.location.state.rowData.companyId,
        mukamId: this.props.location.state.rowData.id,
        mukamName: this.props.location.state.rowData.mukamName,
      });
      const dateEntered = this.props.location.state.rowData.createdOn;
      const dateIsValid = moment(dateEntered).format("LLLL");
      this.setState({
        createdDate: dateIsValid,
      });
    }
  }
  componentWillReceiveProps(props) {
    if (props.itemsByItmGrpIdList) {
      let itemsList = props.itemsByItmGrpIdList;
      const uniques = Object.values(
        itemsList.reduce((a, c) => {
          a[c.value] = c;
          return a;
        }, {})
      );

      itemsList = uniques;

      if (itemsList.length !== 0) {
        var itemsListData = itemsList.filter((item) => item.value !== "0");
        this.setState({
          itemsList: itemsListData, // updating branch dropdown api
        });
      }
    }

    if (props.location.state) {
      if (props.location.state.rowData) {
        var mukamlist = props.location.state.rowData.itemList;
        if (mukamlist !== null) {
          var listofmuksel = [];
          mukamlist.map((prop) => {
            if (props.itemsByItmGrpIdList) {
              props.itemsByItmGrpIdList.filter((muk) => {
                if (mukamlist !== null) {
                  mukamlist.map((item) => {
                    if (item.id === muk.itemId) {
                      listofmuksel.push(muk);
                    }
                  });
                }
              });
            }
            this.setState({
              itemsListData: mukamlist,
            });
          });
        }
      }
    }
  }
  /* Calling Create and Update Api's to Actions */
  onClickCreateUpdate = () => {
    const { itemsList } = this.state;

    if (this.state.locationState.state === undefined) {
      let list = [];
      this.state.itemsListData.map((item) => {
        list.push({
          itemJuteCode: item.itemCode,
          isMapped: true,
          companyId: localStorage.getItem("companyId"),
          createdBy: this.state.userDit.userId.toString(),
        });
      });

      const data = {
        mukam: {
          companyId: this.state.companyId,
          createdBy: this.state.userDit.userId.toString(),
          mukamName: this.state.mukamName,
        },
        itemList: list,
        cipher: this.state.userDit.cipher,
      };

      if (
        this.state.mukamName === null ||
        this.state.mukamName === undefined ||
        this.state.mukamName === ""
      ) {
        swal("Please Enter The Mukam Name");
        return false;
      }
      if (
        data.itemList == null ||
        data.itemList === undefined ||
        data.itemList == ""
      ) {
        swal("Please Enter The Items List");
        return false;
      }
      this.props.createMukamMaster(
        serverApi.CREATE_MUKAM_MASTER,
        data,
        this.props.history
      ); // calling create mukam master api
      // }
    } else {
      let list = [];

      this.state.itemsListData.map((item) => {
        list.push({
          itemJuteCode:
            item.value === undefined ? item.itemJuteCode : item.value,
          isMapped: true,
          companyId: localStorage.getItem("companyId"),
          createdBy: this.state.userDit.userId.toString(),
        });
      });

      const data = {
        createdBy: this.state.userDit.userId.toString(),
        companyId: localStorage.getItem("companyId"),
        createdOn: moment().format(),
        cipher: this.state.userDit.cipher,
        mukam: {
          id: this.state.mukamId,
          mukamName: this.state.mukamName,
        },
        itemList: list,
      };

      if (
        this.state.mukamName === null ||
        this.state.mukamName === undefined ||
        this.state.mukamName === ""
      ) {
        swal("Please Enter The Mukam Name");
        return false;
      }
      if (
        data.itemList == null ||
        data.itemList === undefined ||
        data.itemList == ""
      ) {
        swal("Please Enter The Items List");
        return false;
      }

      this.props.updateMukamMaster(
        serverApi.UPDATE_MUKAM_MASTER,
        data,
        this.props.history
      ); // calling update mukam master api

      this.props.updateMukamMaster(
        serverApi.UPDATE_MUKAM_MASTER,
        data,
        this.props.history
      ); // calling update mukam master api
      // }
    }
  };
  // function for previous page
  onClickCancel = () => {
    this.props.history.push("/mukam_master");
  };
  //function for TextFields
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "mukamId") {
      this.setState({
        mukamId: event,
      });
    }
    if (key === "mukamName") {
      this.setState({
        mukamName: event,
      });
    }
  };
  // function for multi slelect dropdown
  getMopt = (selectedValue, name) => {
    if (name === "itemslist") {
      if (this.props.location.rowData) {
      }

      var selectitem = selectedValue.filter((item) => {
        return item;
      });

      this.setState({
        itemsListData: selectitem,
      });
    }
  };

  // function for popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }

  render() {
    let dataList = [];
    if (this.state.locationState.state) {
      if (this.state.locationState.state.rowData) {
        if (this.state.locationState.state.rowData.itemList) {
          this.state.locationState.state.rowData.itemList.map((item) => {
            this.state.itemsList.map((p, i) => {
              if (p.itemCode === item.itemJuteCode) {
                dataList.push({
                  name: p.name,
                  label: p.label,
                  value: p.value,
                });
              }
            });
          });
        }
      }
    }
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 3">
                      <TextFieldReq
                        value={this.state.mukamId}
                        readOnly={
                          this.props.location.state !== undefined ? true : false
                        }
                        caps="OFF"
                        onChange={this.onhandlechangeValue("mukamId")}
                        label="Mukam Id"
                      />
                    </Box>
                  )}
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.mukamName}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("mukamName")}
                      label="Mukam Name"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <AutoMultiselect
                        options={this.state.itemsList} // Options to display in the dropdown
                        showCheckbox={true}
                        selectedValues={dataList}
                        label="Items List"
                        name={"itemslist"}
                        getOpt={this.getMopt}
                        mandatory="*"
                        disable={this.state.mukamName === "" ? true : false}
                      />
                    </div>
                  </Box>
                  {this.state.locationState.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdDate}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.state.locationState.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
          {/* common popup for validation */}
          {this.state.confirm && (
            <CommonPopup>
              <div className="delAddItem">
                <div className="mandatoryFieldText">
                  Please select all the mandatory fields
                </div>
                <div className="delAddItemBtns">
                  <input
                    type="button"
                    onClick={this.onclickPopUP.bind(this)}
                    value="Ok"
                    className="delYesBtn"
                  />
                </div>
              </div>
            </CommonPopup>
          )}
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { UpdateMukamMaster, MukamListData } = state.MukamMasterReducer;
  const { itemsByItmGrpIdList } = state.DropDownListReducer;
  return { UpdateMukamMaster, itemsByItmGrpIdList, MukamListData };
};

export default withRouter(
  connect(mapStatetoProps, {
    createMukamMaster,
    updateMukamMaster,
    getItemsList,
  })(CreateMukamMaster)
);
