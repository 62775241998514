import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import CustomButton from "../../../../components/Buttons/Buttons";
import { serverApi, getTenantId } from "../../../../helpers/Consts";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { getFileDownload } from "../../../../helpers/server.js";
import { serverConfig } from "../../../../helpers/Consts";
import { Box, Tooltip,Grid } from "@mui/material";
import TickIcon from "../../../../assets/images/fileUploadedTick.png";
import Fade from "@mui/material/Fade";

const JuteGovtPreview = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [locationState] = useState(useLocation());
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [gstNo1, setGstNo1] = useState("");
  const [gstNo2, setGstNo2] = useState("");

  const [companyName, setcompanyName] = useState("");
  const [phoneNo, setphoneNo] = useState("");
  const [email, setemail] = useState("");
  const [companyLogo, setcompanyLogo] = useState("");
  const [currentStep, setcurrentStep] = useState(1);
  const [currentDate, setcurrentDate] = useState(new Date().toLocaleString());
  const [editButton, seteditButton] = useState(true);
  const [LineItems, setLineItems] = useState([]);
  const [HeaderData, setHeaderData] = useState({});
  const [addressOne,setAddressOne]= useState("");
  const [addressTwo,setAddressTwo]= useState("");
  const [gstNo, setGstNo] = useState("");
  const location = useLocation();
  const { DOFiles } = useSelector((state) => state.DeliveryOrderReducer);
  const [deliveryOrderFiles, SetDeliveryOrderFiles] = useState(
    DOFiles?.data?.data || []
  );
  useEffect(() => {
    SetDeliveryOrderFiles(DOFiles?.data?.data);
  }, [DOFiles]);

  useEffect(() => {
    let companyData = JSON.parse(localStorage.getItem("authUser"));
    let companyId = localStorage.getItem("companyId");
    companyData.companyDetails.map((p) => {
      console.log(p, "ppppppp")
      let id = p.value;
      if (companyId === id.toString()) {
        setcompanyName(p.label);
        setcompanyLogo(p.companyLogo);
        setphoneNo(p.contactNo);
        setemail(p.emailId);
        setaddress1(p.address1);
        setaddress2(p.address2);
        setGstNo1(p.gstNo);
        setGstNo2(p.gstNo2);
      }
    });
  });

  const { JuteGovtDeliveryOrderHeader, JuteGovtDeliveryOrderLineItems } =
    useSelector((state) => state.DeliveryOrderReducer);

  useEffect(() => {
    setLineItems(JuteGovtDeliveryOrderLineItems);
    setHeaderData(JuteGovtDeliveryOrderHeader);
  }, [JuteGovtDeliveryOrderLineItems, JuteGovtDeliveryOrderHeader]);

  const handlePdf = (printType) => {
    if (props.locationState.state !== undefined) {
      fetch(
        serverApi.REGULAR_DELIVERY_PDF_DOWNLOAD +
          "2/" +
          HeaderData.regDeliveryOrderId +
          "/" +
          JSON.parse(localStorage.getItem("authUser")).userId +
          "/" +
          printType,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            "X-TenantId": getTenantId(window.location.hostname),
            Authorization:
              localStorage.getItem("token_type") +
              localStorage.getItem("access_token"),
          },
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", HeaderData.deliveryOrderNo + ".pdf");
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        });
    }
  };

  const convertNumberToWords = (amount) => {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();

    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = "";
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  };
  const handleEdit = () => {
    props.handleCurrentStep(2);
  };

  const withDecimal = (n) => {
    if (n !== undefined) {
      var nums = n.toString().split(".");
      var whole = convertNumberToWords(nums[0]);

      if (nums.length == 2) {
        if (parseInt(nums[1]) > 0) {
          var fraction = convertNumberToWords(parseInt(nums[1]));
          return whole + "Rupees and "+ fraction + "Paisa only";
        }
      }
      return whole + "Rupees only";
    }
  };
 
  let filterProduct = LineItems.filter(
    (item) => item.isActive !== 0 && item.itemGroupId !== null
  );
 
  if(HeaderData.ammendmentDate !==null){
  var ammendmentDate =  moment(HeaderData.ammendmentDate, "YYYY-MM-DD", true).format(
    "DD-MM-YYYY"
  );
  }

  return (
    <>
      <div className="previewBlock">
        <div
          style={{ display: "flex", justifyContent: "end", padding: "10px", marginTop: "20px"}}
        >
          {props.locationState.state && (
            <CustomButton
              label="Transport Copy"
              handleClick={() => {
                handlePdf(3);
              }}
              type="button"
              className="lightBlueButton"
              muIcon={<PrintIcon />}
            />
          )}
          {props.locationState.state && (
            <CustomButton
              label="Factory Copy"
              handleClick={() => {
                handlePdf(5);
              }}
              type="button"
              className="lightBlueButton"
              muIcon={<PrintIcon />}
            />
          )}
          {props.locationState.state && (
            <CustomButton
              label="Office Copy"
              handleClick={() => {
                handlePdf(4);
              }}
              type="button"
              className="lightBlueButton"
              muIcon={<PrintIcon />}
            />
          )}
          {/* {location.state && (
          <CustomButton
            label="Consignee Copy Print"
            handleClick={() => {
              handlePdf();
            }}
            type="button"
            className="lightBlueButton"
            muIcon={<PrintIcon />}
          />
          )}  */}
          {props.locationState.state && JuteGovtDeliveryOrderHeader.EditButton && (
            <CustomButton
              label="Edit"
              handleClick={() => {
                handleEdit();
              }}
              type="button"
              className="lightBlueButton"
              muIcon={<EditIcon />}
            />
          )}
        </div>
        <table
          cellpadding="0"
          cellspacing="0"
          width="100%"
          className="previewTable"
        >
          <tbody>
            <tr>
              <td
                colspan="4"
                style={{ borderRight: "none", borderBottom: "none" }}
              >
                <img src={companyLogo} width="100%" alt="" />
              </td>
              <td
                colspan="15"
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  textAlign: "right",
                }}
              >
                <h2>{companyName}</h2>
                  {address1}
                  <br/>
                  {address2}
                  <br/>
                  <span><b>Ph.No: </b>{phoneNo}, </span>
                  <span><b>Email: </b> {email}</span>
                  <br/>
                  <b>GST No:</b> {gstNo1 || "n/a"}
                  <br/>
                  <br></br>
              </td>
            </tr>
            <tr>
              <td
                colspan="12"
                style={{ textAlign: "center", borderTop: "none" }}
              >
                <h2>DELIVERY ORDER</h2>
              </td>
            </tr>

            <tr>
              <td colspan="2">
                <b>Delivery Order NO : </b>
              </td>
              <td
                colspan="2"
                className={
                  HeaderData.deliveryOrderNo === undefined ? "blur-text" : ""
                }
              >
                {HeaderData.deliveryOrderNo === undefined
                  ? "Will be generated after creation"
                  : HeaderData.deliveryOrderNo}
              </td>
              <td colspan="3">
                <b>Delivery Order Date :</b>
              </td>
              <td colspan="5">
                {moment(HeaderData.deliveryDate).format("DD-MM-YYYY")}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <b>Sales Order No:</b>
              </td>
              <td colspan="2">
                {HeaderData.salesNo === ""
                  ? "Will be generated after creation"
                  : HeaderData.salesOrderNumber}
              </td>
              <td colspan="3">
                <b>Sales Order Date :</b>
              </td>

              <td colspan="5">
                {moment(HeaderData.salesOrderDate).format("DD-MM-YYYY")}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <b>Branch :</b>
              </td>
              <td colspan="2">{HeaderData.branchName}</td>
              {/* <td colspan="5">
                <b>Branch : </b>
              </td> */}

              {/* <td colspan="5">{HeaderData.branchName}</td> */}
              <td colspan="3">
                <b>Consignee Name: </b>
              </td>
 
              <td colspan="5">
                {HeaderData.consigneeContactPerson}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <b>PCSO Number :</b>
              </td>
              <td colspan="2">
                {""}
                {HeaderData.pcsoNo}
              </td>
              <td colspan="3">
                <b>PCSO Date : </b>
              </td>

              <td colspan="5">
                {""}
                {moment(HeaderData.pcsoDate).format("DD-MM-YYYY")}
              </td>
            </tr>
            {/* <tr>
              <td colspan="5">
                <b>Packaging Type :</b>
              </td>
              <td colspan="5">{""}</td>
              <td colspan="5">
                <b>Tax Type : </b>
              </td>

              <td colspan="5">{""}{HeaderData.taxTypeName}</td>
            </tr> */}
            <tr>
              <td colspan="2">
                <b>Amendment No :</b>
              </td>
              <td colspan="2">{HeaderData.ammendmentNo}</td>
              <td colspan="3">
                <b>Amendment Date : </b>
              </td>

              <td colspan="5">{ammendmentDate}</td>
            </tr>
            <tr>
              <td colSpan="2">
                <b>Vehicle No :</b>
              </td>
              <td colSpan="2">{HeaderData.vehicleNo}</td>
              <td colSpan="3">
                <b>Transporter Name :</b>
              </td>
              <td colSpan="5">{HeaderData.transporterName}</td>
            </tr>
            <tr>
            <td colspan="3" style={{
                  borderTop: "none",
                  borderBottom: "none",
                  padding: "none",
                }}
              >
                <b>
                  {" "}
                  Lift From :{"  "}  </b><br />
                  {HeaderData.branchName} ,{" "}
                  {HeaderData.branchAddress}
                <br />
                <b>
                  GSTIN : {"  "}</b>
                  {HeaderData.branchGstNo}
                <br />
                <b>
                  Contact Person : {"  "} </b>
                  {HeaderData.branchContactPerson}
                <br />
                <b>
                  Contact Number : {"  "}</b>
                  {HeaderData.branchContactNo}
                <br />
                <b>
                  EMAIL ID : {"  "}</b>
                  {HeaderData.branchEmail}
              </td>
              <td colspan="4" style={{
                  borderTop: "none",
                  borderBottom: "none",
                  padding: "none",
                }}
              >
              
                <b>
                  {" "}
                  Bill To :{"  "}  </b><br />
                  {HeaderData.customerName ? HeaderData.customerName : ""} ,{" "}
                  <br />
                  {HeaderData.billingToAddress}
                <br />
                <b>
                  GSTIN : {"  "}</b>
                  {HeaderData.billingToGST}
                <br />
                <b>
                  Contact Person : {"  "} </b>
                  {HeaderData.billingContactPerson}
                <br />
                <b>
                  Contact Number : {"  "}</b>
                  {HeaderData.billingContactNo}
                <br />
                <b>
                  EMAIL ID : {"  "}</b>
                  {HeaderData.billingToEmail}
              </td>
              <td
                colspan="8"
                style={{
                  borderTop: "none",
                  borderBottom: "none",
                  padding: "none",
                }}
              >
                
                <b>
                  {" "}
                  Ship To :{"  "} </b>
                  <br />
                  <span>
                  {HeaderData.customerName ? HeaderData.customerName : ""} ,{" "}
                  <br />
                  {HeaderData.shippingToAddress}
                </span>
                <br />
                <b>GSTIN :{" "}</b> 
                {HeaderData.shippingToGST}
                <br />
                <b>
                  Contact Person : {"  "}</b>
                   {HeaderData.shippingContactPerson}{" "}                
                <br />
                <b>
                  Contact Number :{"  "}</b>
                  {HeaderData.shippingContactNo}
                <br />
                <b>EMAIL ID : {" "}</b>
                {HeaderData.shippingToEmail}
              </td>
              {/*
              <td
                colspan="9"
                style={{
                  borderTop: "none",
                  borderBottom: "none",
                  padding: "none",
                }}
              >
                <br />
                <b> Transport :{"  "}{HeaderData.transporterName}</b>
                <br/>
                <br />
                <b>Transporter Name :{" "}{HeaderData.transporterName}</b>
                <br />
                <b>Vehicle No : {"  "}{HeaderData.vehicleNo}  </b>
                <br /><b>Date :{"  "}{""}</b> 
              </td>*/}
            </tr>
            <tr>
              <td rowspan="2" width={"5%"}>
                <b>S.No</b>
              </td>
              <td rowspan="2" align="center" width={"8%"}>
                <b>Item CODE</b>
              </td>
              <td rowspan="2" align="center" width={"30%"}>
                <b>Item Name</b>
              </td>
              {/* <td rowspan="2">
                <b>Remarks</b>
              </td>
              <td rowspan="2">
                <b>Make</b>
              </td>
              <td rowspan="2">
                <b>HSN Code</b>
              </td> */}
              <td rowspan="2" align="center" width={"15%"}>
                <b>Quantity</b>
              </td>
              <td rowspan="2" align="center" width={"10%"}>
                <b>RATE</b>
              </td>
              <td rowspan="2" align="center" width={"8%"}>
                <b>Amount</b>
              </td>
              <td colspan="2" align="center" width={"8%"}>
                <b>IGST</b>
              </td>
              <td colspan="2" align="center" width={"8%"}>
                <b>CGST</b>
              </td>
              <td colspan="2" align="center" width={"8%"}>
                <b>SGST</b>
              </td>
              {/* <td rowspan="2" align="center">
                <b>Currency</b>
              </td> */}
            </tr>
            <tr>
              <td>
                <b>%</b>
              </td>
              <td>
                <b>Amt</b>
              </td>
              <td>
                <b>%</b>
              </td>
              <td>
                <b>Amt</b>
              </td>
              <td>
                <b>%</b>
              </td>
              <td>
                <b>Amt</b>
              </td>
            </tr>
            {filterProduct &&
              filterProduct.map((item, i) => {
                return (
                  <>
                    {item.itemCode.startsWith("997") ? (
                      <tr key={i}>
                        <td colspan="3">
                          <b> {item.itemName}</b>
                        </td>
                        <td colspan="13">
                          {parseFloat(item.netAmount).toFixed(2)}
                        </td>
                      </tr>
                    ) : (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item.itemCode}</td>
                        {/* <td>{item.itemName}</td> */}
                        <td>
                          {item.itemName}
                          <br />
                          <span>
                            <b>Remarks :</b> {item.remarks}
                          </span>{" "}
                          <br />
                          <span>
                            <b>Make :</b> {item.itemMake}
                          </span>{" "}
                          <br />
                          <span>
                            <b>HSN Code :</b> {item.hsncode}
                          </span>
                        </td>
                        {/* <td>{item.remarks}</td>
                        <td align="right">{item.itemMake}</td>
                        <td align="right">{item.hsncode}</td> */}
                        {/* <td align="right">
                {parseFloat(item.quantity).toFixed(3)}
              </td> */}
                        <td align="right">
                          {parseFloat(item.quantity).toFixed(3)} - {item.uom}
                          <br />
                          {item.govtDeliveryQtyCalculatedValue !== 0 && (
                            <>
                              {parseFloat(
                                item.govtDeliveryQtyCalculatedValue
                              ).toFixed(3)}{" "}
                              - {item.govtDeliveryQuantityUom}
                              <br />
                            </>
                          )}
                          {item.govtDeliveryQtyCalculatedValue2 !== 0 && (
                            <>
                              {parseFloat(
                                item.govtDeliveryQtyCalculatedValue2
                              ).toFixed(3)}{" "}
                              - {item.govtDeliveryQuantityUom2}
                              <br />
                            </>
                          )}
                            {item.govtDeliveryQtyCalculatedValue3 !== 0 && (
                            <>
                              {parseFloat(item.govtDeliveryQtyCalculatedValue3).toFixed(
                                3
                              )}{" "}
                              - {item.govtDeliveryQuantityUom3}
                              <br />
                            </>
                          )}
                        </td>
                        {/* <td align="right">
                          {parseFloat(item.rate).toFixed(3)}
                        </td> */}
                        <td align="right">
                          {item.rate} - RS/{item.uom}
                          <br />
                          {item.govtDeliveryQuantityUom !== "" && (
                            <>
                              {parseFloat(
                                item.govtDeliveryRateCalculatedValue2
                              ).toFixed(3)}{" "}
                              - RS/{item.govtDeliveryRateUom2}
                              <br />
                            </>
                          )}
                             
                        </td>
                        <td align="right">
                          {parseFloat(item.netAmount).toFixed(2)}
                        </td>
                        <td align="right">{item.igstPercent}</td>
                        <td align="right">
                          {parseFloat(item.igstAmount).toFixed(2)}
                        </td>
                        <td align="right">
                          {parseFloat(item.cgstPercent).toFixed(2)}
                        </td>
                        <td align="right">
                          {parseFloat(item.cgstAmount).toFixed(2)}
                        </td>
                        <td align="right">
                          {parseFloat(item.sgstPercent).toFixed(2)}
                        </td>
                        <td align="right">
                          {parseFloat(item.sgstAmount).toFixed(2)}
                        </td>
                        {/* <td align="right">{item.currency}</td> */}
                      </tr>
                    )}
                  </>
                );
              })}

            <tr>
              <td colspan="3">
                <b>Taxable Value</b>
              </td>
              <td colspan="13">{parseFloat(HeaderData.netTotal).toFixed(2)}</td>
            </tr>
            <tr>
              <td colspan="3">
                <b>Total IGST</b>
              </td>
              <td colspan="13">
                {parseFloat(HeaderData.totalIGST).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <b>Total CGST</b>
              </td>
              <td colspan="13">
                {parseFloat(HeaderData.totalCGST).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <b>Total SGST</b>
              </td>
              <td colspan="13">
                {parseFloat(HeaderData.totalSGST).toFixed(2)}
              </td>
            </tr>

            <tr>
              <td colspan="3">
                <b>Total Value with Tax</b>
              </td>
              <td colspan="13">
                {parseFloat(HeaderData.TotalAmount).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <b>Total Amount in words</b>
              </td>
              <td colspan="13">{withDecimal(parseFloat(HeaderData.TotalAmount).toFixed(2))}</td>
            </tr>
            <tr>
              <td
                colspan="9"
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderBottom: "none",
                  marginTop: "5px",
                }}
              >
                   <br />
                  <b>Bank Details:</b>
                  <br />
                  <b>{companyName}</b>
                  <br />
                  <b> Bank:{"  "} 
                  </b>{HeaderData.bankName}
                  <br />
                  <b> Branch: {"  "}</b>
                  {HeaderData.bankBranch}
                  <br />
                  <b> A/c No:{"  "} 
                  </b>{HeaderData.accountNo}
                  <br />
                  <b> IFSC Code:{"  "} 
                  </b>{HeaderData.ifscCode}
                  <br />
                  <b> MICR No:{"  "} 
                  </b>{HeaderData.micrCode}
                  <br />
                  <br />
                <p>On presentation of this Lorry Pass. Please deliver to the bearer,the above Goods with marks as per undernoted M.S.I. and get this Lorry Pass back duly receipt
                   after delivery</p>
                <br />
                <br />
                {props.locationState.state !== undefined ? (
                  <b>Status: {HeaderData.statusName}</b>
                ) : (
                  ""
                )}
                <br />
                <br />
                <span>
                  <b>Delivery Days :</b>{" "}
                  <span>{HeaderData.deliveryDays} Days</span>{" "}
                </span>
                <br />
                <span>
                  <b>Delivery terms :</b>{" "}
                  <span>
                    {HeaderData.deliveryTerms}
                  </span>
                </span>
                <br />
                <span>
                  <b>Payment Terms (In Days) : </b> <span>{HeaderData.paymentTerms}</span>{" "}
                </span>
                <br />
                <span>
                  <b>Footer Notes : </b> {HeaderData.footerNote}
                </span>
                <br />
                <span>
                  <b>Internal Notes : </b>
                  {HeaderData.internalNote}{" "}
                </span>
                <br />
                <br />
                <tr>
                    <td
                      colSpan="10"
                      style={{
                        borderTop: "none",
                        borderBottom: "none",
                        borderRight: "none",
                        borderLeft: "none",
                      }}
                    >
                {props.locationState.state !== undefined ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box gridColumn="span 12">
                            <span>
                              <b>Support Documents:</b>
                            </span>
                          </Box>
                          <Box
                            display="grid"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={2}
                            className="ducumentUploadBlock uploadedContent"
                            style={{ padding: "10px" }}
                          >
                            {deliveryOrderFiles?.length > 0 &&
                              deliveryOrderFiles.map((item) => (
                                <Box gridColumn="span 6">
                                <div
                                  className="documentUploadContent"
                                  key={item.fileUploadId}
                                >
                                  <div
                                    className="uploadedInfo"
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <img
                                      src={TickIcon}
                                      alt=""
                                      className="tickIcon"
                                      style={{
                                        height: "16px",
                                        width: "16px",
                                        marginRight: "8px",
                                      }}
                                    />
                                    <Tooltip
                                      TransitionComponent={Fade}
                                      TransitionProps={{ timeout: 600 }}
                                      title="Click to download File"
                                    >
                                      <div
                                        className="uplodedFileName"
                                        onClick={() => {
                                          getFileDownload(
                                            serverConfig.SERVER_URL +
                                              "security-api/api/files/downloadfile/" +
                                              item.fileUploadId,
                                            item.fileName,
                                            item.fileExtension
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {item.fileName}
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                                </Box>
                              ))}
                          </Box>
                        </Grid>
                      </Grid>
                   
                ) : (
                  ""
                )}
                 </td>
                  </tr>
                <span>
                  <b>Terms and Conditions: </b>
                  <br />
                  {HeaderData.termsCondition}
                </span>
                
                <br />
                {props.locationState.state !== undefined ? (
                  <>
                    <b>Last Modified On: </b>{HeaderData.updatedOn ? moment(HeaderData.updatedOn).format("DD-MM-YYYY"):""}  
                  </>
                ) : (
                  ""
                )}
                <br />
                {props.locationState.state !== undefined ? (
                  <>
                    <b>Last Modified By: </b> {HeaderData.updatedByDesc}
                  </>
                ) : (
                  ""
                )}

                <br />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default JuteGovtPreview;
