export const INDENT_LIST = "indent_list"; 
export const INDENT_LIST_SUCCESSFULL = "indent_list_successfull";
export const CREATE_INDNET = 'create_indent';
export const CREATE_INDNET_SUCCESSFULL ='create_indent_successfull';
export const UPDATE_INDNET = 'update_indent';
export const UPDATE_INDNET_SUCCESSFULL ='update_indent_successfull';
export const ACTIONS = 'actions';
export const ACTIONS_SUCCESSFULL = "actions_successfull";
export const SEE_THE_LOGS = "see_the_logs";
export const SEE_THE_LOGS_SUCCESSFULL = "see_the_logs_successfull";
export const PENDING_STOCK = 'pending_stock';
export const PENDING_STOCK_SUCCESSFULL = 'pending_stock_successfull';
export const PENDING_STOCKPO_AND_RECEIPT = 'pending_stockpo_and_receipt';
export const PENDING_STOCKPO_AND_RECEIPT_SUCCESSFULL = 'pending_stockpo_and_receipt_successfull';
export const GET_FILES_OF_INDENT = "get_files_of_indent";
export const GET_FILES_OF_INDENT_SUCCESSFULL = "get_files_of_indent_successfull";
export const GET_DOWNLOAD_PDF = "get_download_pdf";
export const GET_DOWNLOAD_PDF_SUCCESSFULL = "get_download_pdf_successfull";
export const GET_COPYOF_INDENTS = "get_copyof_indents"
export const GET_COPYOF_INDENTS_SUCCESSFULL = "get_copyof_indents_successfull";
export const CREATE_PROJECT = "create_project";
export const CREATE_PROJECT_SUCCESSFULL = "create_project_successfull";
export const UPDATE_PROJECT = "update_project";
export const UPDATE_PROJECT_SUCCESSFULL = "update_project_successfull";
export const INDENT_LINEITEMS = "indent_lineitems";
export const GET_FILES_OF_INVOICE = "get_files_of_invoice";
export const GET_FILES_OF_CHALLAN = "get_files_of_challan";
export const GET_FILES_OF_SUPPDOCS = "get_files_of_suppdocs";
export const VALIDATE_ITEM_IN_OPENINDENT = "validate_item_in_openindent";
export const VALIDATE_ITEM_IN_OPENINDENT_SUCCESSFULL = "validate_item_in_openindent_successfull";

export const GETITEMSLISTFORINDENT =  "getItemsListForIndent"
export const GETITEMSLISTFORINDENT_SUCCESSFULL =  "getItemsListForIndent_successfull"
export const GETITEMGROUPSFORINDENT = "getItemgroupsForIndent"
export const GETITEMGROUPSFORINDENT_SUCCESSFULL = "getItemgroupsForIndent_successfull"

export const CLEAR_INDENT_LINEITEMS = "clear_indent_lineitems"
export const GET_ITEM_DETAILS_BY_POID_FOR_INDENT = "get_Item_Details_By_PoId_for_indent";
export const GET_ITEM_DETAILS_BY_POID_FOR_INDENT_SUCCESSFULL= "get_Item_Details_By_PoId_for_indent_successfull";


export const GET_ITEMSLIST_BY_ITEMGROUPID_COMPANYID = "get_itemslist_by_itemgroupid_companyid"
export const GET_ITEMSLIST_BY_ITEMGROUPID_COMPANYID_SUCCESSFULL = "get_itemslist_by_itemgroupid_companyid_successfull"

export const GET_ITEMDETAILS_BY_ITEMID = "get_itemDetails_by_itemId"
export const GET_ITEMDETAILS_BY_ITEMID_SUCCESSFULL = "get_itemDetails_by_itemId_successfull"

export const GET_ITEMDETAILS = "get_itemdetails"
export const GET_ITEMDETAILS_SUCCESSFULL = "get_itemdetails_successfull"


export const VALIDATE_ITEM = "validate_item";
export const VALIDATE_ITEM_SUCCESSFULL = "validate_item_successfull";










