import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Box } from "@mui/material";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import CustomButton from "../../../components/Buttons/Buttons";
import { serverApi } from "../../../helpers/Consts";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { postUpdateItemToSpellMasterList } from "../../../store/Master/SpellMaster/actions";
import moment from "moment";
import Datetime from "react-datetime";
class ViewSpellMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: JSON.parse(localStorage.getItem("companyId")),
      Update_Spell_master: [
        {
          spellName: "",
          workingHours: "",
          startingTime: "",
          endTime: "",
          autoDateTime: "",
          createdBy: "",
          id: "",
          createdOn: "",
        },
      ],
    };
  }
  componentDidMount() {
    if (this.props.location.state.rowData !== undefined) {
      this.setState({
        id: this.props.location.state.rowData.id,
        spellName: this.props.location.state.rowData.spellName,
        workingHours: this.props.location.state.rowData.workingHours,
        startingTime: this.props.location.state.rowData.startingTime,
        endTime: this.props.location.state.rowData.endTime,
        createdBy: this.props.location.state.rowData.createdBy,
        autoDateTime: this.props.location.state.rowData.autoDateTime,
        cutsEligibility: this.props.location.state.rowData.cutsEligibility,
        dateDesc: this.props.location.state.rowData.dateDesc,
        endDate: this.props.location.state.rowData.endDate,
        parentSpell: this.props.location.state.rowData.parentSpell,
        spellType: this.props.location.state.rowData.spellType,
        userCode: this.props.location.state.rowData.userCode,
      });
      const dateEntered = this.props.location.state.rowData.autoDateTime;
      const dateIsValid = moment(dateEntered).format("LLLL");
      this.setState({
        createdOn: dateIsValid,
      });
    }
  }
  componentWillReceiveProps(props) {
    if (props.Update_Spell_master) {
      this.setState({
        spellMasterListData: props.Update_Spell_master.data,
      });
    }
  }
  // function for popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  // function for previous page
  onClickCancel = () => {
    this.props.history.push("/spell_master");
  };
  //Update Method
  handleUpdate = () => {
    const { spellName, workingHours, startingTime, endTime } = this.state;
    if (
      spellName !== "" &&
      spellName !== undefined &&
      workingHours !== "" &&
      workingHours !== undefined &&
      startingTime !== "" &&
      startingTime !== undefined &&
      endTime !== "" &&
      endTime !== undefined
    ) {
      if (this.props.location.state !== undefined) {
        const data = {
          spellName: this.state.spellName,
          workingHours: this.state.workingHours,
          startingTime: this.state.startingTime,
          endTime: this.state.endTime,
          id: this.state.id,
          autoDateTime: this.state.autoDateTime,
          cipher: this.state.userDit.cipher,
          companyId: this.state.companyId,
          createdBy: this.state.createdBy,
          cutsEligibility: "",
          dateDesc: "",
          endDate: "",
          parentSpell: "",
          spellType: "",
          userCode: this.state.userDit.userId.toString(),
        };
        this.props.postUpdateItemToSpellMasterList(
          serverApi.UPDATE_SPELL_MASTER,
          data,
          this.props.history
        );
      }
    } else {
      this.setState({
        confirm: true,
      });
    }
  };
  //   Input Field Handle Method
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "spellName") {
      this.setState({
        spellName: event,
      });
    }
    if (key === "workingHours") {
      this.setState({
        workingHours: event,
      });
    }
    if (key === "startingTime") {
      this.setState({
        startingTime: event.time,
      });
    }
    if (key === "endTime") {
      this.setState({
        ...this.state,
        endTime: event.format("HH-mm"),
      });
    }
  };

  time = (dateObj, id) => {
    if (typeof dateObj === "object")
      this.setState({
        [id]: dateObj.format("HH:mm"),
      });
  };
  render() {
    return (
      <>
        <div className="consoleFormContainer">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        value={this.state.spellName}
                        label="Spell Name"
                        caps="OFF"
                        onChange={this.onhandlechangeValue("spellName")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        label="Working Hours"
                        value={this.state.workingHours}
                        caps="OFF"
                        onChange={this.onhandlechangeValue("workingHours")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="TextFieldBlock">
                      <label>
                        Start Time <span className="starcolor">*</span>
                      </label>
                    </div>
                    <div className="consoleTextFieldBlock">
                      <Datetime
                        defaultValue={new Date()}
                        closeOnSelect={true}
                        timeFormat={"HH:mm"}
                        dateFormat={false}
                        onChange={(timeObj) => {
                          this.time(timeObj, "startingTime");
                        }}
                        height={"15px"}
                        formControlProps={{
                          height: "15px",
                          inlineBlock: "none",
                        }}
                        inputProps={{
                          id: "startingTime",
                          value: this.state.startingTime,
                          autoComplete: "off",
                        }}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="TextFieldBlock">
                      <label>
                        End Time <span className="starcolor">*</span>
                      </label>
                    </div>
                    <div className="consoleTextFieldBlock">
                      <Datetime
                        defaultValue={new Date()}
                        closeOnSelect={true}
                        timeFormat={"HH:mm"}
                        dateFormat={false}
                        onChange={(timeObj) => {
                          this.time(timeObj, "endTime");
                        }}
                        height={"15px"}
                        formControlProps={{
                          height: "15px",
                          inlineBlock: "none",
                        }}
                        inputProps={{
                          id: "endTime",
                          value: this.state.endTime,
                          autoComplete: "off",
                        }}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>

              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 5">
                  <div className="consoleUpdateTextBlock">
                    <div>
                      <span className="taxLabel">
                        Created By : <b>{this.state.createdBy}</b>
                      </span>
                    </div>
                  </div>
                </Box>
                <Box gridColumn="span 6">
                  <div className="consoleUpdateTextBlock">
                    <div>
                      <span className="taxLabel">
                        Created On :<b> {this.state.createdOn}</b>
                      </span>
                    </div>
                  </div>
                </Box>
              </Box>
            </Grid>
          </div>
          <div className="consoleFormButtonBlock">
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
            <CustomButton
              label={"Update"}
              className="greenButton"
              type="sumbit"
              handleClick={this.handleUpdate}
            />
            {/* common popup for validation */}
            {this.state.confirm && (
              <CommonPopup>
                <div className="delAddItem">
                  <div className="mandatoryFieldText">
                    Please select all the mandatory fields
                  </div>
                  <div className="delAddItemBtns">
                    <input
                      type="button"
                      onClick={this.onclickPopUP.bind(this)}
                      value="Ok"
                      className="delYesBtn"
                    />
                  </div>
                </div>
              </CommonPopup>
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapStatetoProps = (state) => {
  const { Update_Spell_master } = state.SpellMasterListReducer;
  return { Update_Spell_master };
};

export default withRouter(
  connect(mapStatetoProps, {
    postUpdateItemToSpellMasterList,
  })(ViewSpellMaster)
);
