import { CARRIERS_LIST, CREATE_CARRIERS, UPDATE_CARRIERS } from './actionTypes';
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getList, postAdd, } from '../../../helpers/Server_Helper';
import { carriersListSuccessfull, createCarriersSuccessfull, updateCarriersSuccessfull, } from './actions';
import swal from "sweetalert";

function* CarriersList({ payload: { url, data } }) {
    try {
        const response = yield call(getList, url, data);
        yield put(carriersListSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
function* CreateCarriers({ payload: { url, data, history } }) {
    try {
        const response = yield call(postAdd, url, data);
        yield put(createCarriersSuccessfull({ response }));
        if (response.data.status === true) {
            swal(response.data.message, { icon: "success" })
            history.push("/carriers");
        } else {
            swal(response.data.message, { icon: "error" })
        }
    } catch (error) {
        swal("error:" + error, { icon: "danger" });
        console.log(error)
    }
}
function* UpdateCarriers({ payload: { url, data, history } }) {
    try {
        const response = yield call(postAdd, url, data);
        yield put(updateCarriersSuccessfull({ response }))
        if (response.data.status === true) {
            swal(response.data.message, { icon: "success" })
            history.push("/carriers");
        } else {
            swal(response.data.message, { icon: "error" })
        }

    } catch (error) {
        console.log(error)
        swal("error:" + error, { icon: "danger" });
    }
}


export function* watchCarriersList() {
    yield takeEvery(CARRIERS_LIST, CarriersList)
}
export function* watchCreateCarriers() {
    yield takeEvery(CREATE_CARRIERS, CreateCarriers)
}
export function* watchUpdateCarriers() {
    yield takeEvery(UPDATE_CARRIERS, UpdateCarriers)
}


function* CarriersSaga() {
    yield all([
        fork(watchCarriersList),
        fork(watchCreateCarriers),
        fork(watchUpdateCarriers),
    ])
}

export default CarriersSaga;