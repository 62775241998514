import { Grid } from "@mui/material";
import React from "react";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";

export default class StockReturnAddItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowcount: 7,
      show: false,
      checkAll: false,
    };
    this.state.filterText = "";
    this.state.products = [
      {
        id: 1,
        category: "Sporting Goods",
        department: "Ux trainee",
        qty: 12,
        name: "Sachin",
        amount: 2222,
        rate: 21,
        itemName: "PAK",
        checked: false,
      },
      {
        id: 2,
        category: "Sporting Goods",
        department: "Ux converter",
        qty: 15,
        name: "Rahul",
        amount: 3333,
        rate: 22,
        itemName: "AUS",
        checked: false,
      },
      {
        id: 3,
        category: "Sporting Goods",
        department: "Ux designer",
        qty: 14,
        name: "Dravid",
        amount: 4444,
        rate: 23,
        itemName: "INDIA",
        checked: false,
      },
      {
        id: 4,
        category: "Electronics",
        department: "ux designer",
        qty: 34,
        name: "Kiran",
        amount: 555,
        rate: 24,
        itemName: "south africa",
        checked: false,
      },
      {
        id: 5,
        category: "backend",
        department: "backend",
        qty: 12,
        name: "Bikash",
        amount: 6666,
        rate: 25,
        itemName: "england",
        checked: false,
      },
    ];
  }
  handleUserInput(filterText) {
    this.setState({ filterText: filterText });
  }
  handleCheckbox(isChecked, id, isAllCheck) {
    let list = this.state.products;
    if (isAllCheck) {
      list = list.map((product) => {
        return { ...product, checked: isChecked };
      });
      this.setState({ products: list, allChecked: isChecked });
    } else {
      list.forEach((product, i) => {
        if (product?.id == id) {
          list[i].checked = isChecked;
        }
      });
      let allChecked = list.every((item) => item.checked);
      this.setState({ products: list, allChecked: allChecked });
    }
  }

  handleRowDel(product) {
    var index = this.state.products.indexOf(product);

    this.state.products.splice(index, 1);
    this.setState(this.state.products);
  }
  handleKeydownEvent(event) {
    if (event.keyCode == 9) {
      let id = 0;
      this.state.products.map((prop) => {
        id = prop.id;
      });
      if (event.target.id == id) this.handleAddEvent(event);
    }
  }

  handleAddEvent(evt) {
    var id = this.state.rowcount;
    var product = {
      id: id,
      name: "",
      department: "",
      category: "",
      qty: "",
      itemName: "",
      amount: "",
      rate: "",
    };
    var mcount = Number(this.state.rowcount) + 1;
    this.state.products.push(product);
    this.setState(this.state.products);
    this.setState({ rowcount: mcount });
  }

  handleProductTable(evt) {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    var products = this.state.products.slice();
    var newProducts = products.map(function (product) {
      for (var key in product) {
        if (key == item.name && product.id == item.id) {
          product[key] = item.value;
        }
      }
      return product;
    });
    this.setState({ products: newProducts });
  }
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <div>
        <SearchBar
          filterText={this.state.filterText}
          onUserInput={this.handleUserInput.bind(this)}
        />
        <ProductTable
          onProductTableUpdate={this.handleProductTable.bind(this)}
          onRowAdd={this.handleAddEvent.bind(this)}
          onKeyDown={this.handleKeydownEvent.bind(this)}
          onChange={this.handleCheckbox.bind(this)}
          onRowDel={this.handleRowDel.bind(this)}
          products={this.state.products}
          filterText={this.state.filterText}
          allChecked={this.state.allChecked}
        />
      </div>
    );
  }
}
class SearchBar extends React.Component {
  handleChange() {
    this.props.onUserInput(this.refs.filterTextInput.value);
  }
  render() {
    return (
      <div>
        <Grid container spacing={2} className="addItemsBlockContainer">
          <Grid item xs={6}>
            {" "}
            <div className="createIndentContainerBlock">
              <h1 className="createIndentBlock">Add Items</h1>
            </div>
          </Grid>

          <Grid item xs={6}>
            <input
              type="text"
              placeholder="Search"
              value={this.props.filterText}
              ref="filterTextInput"
              onChange={this.handleChange.bind(this)}
              className="searchBlockContainer"
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

class ProductTable extends React.Component {
  render() {
    var onProductTableUpdate = this.props.onProductTableUpdate;
    var rowDel = this.props.onRowDel;
    var filterText = this.props.filterText;
    var keyDown = this.props.onKeyDown;
    var checkboxBlock = this.props.onChange;
    var table = this.props.onChange;
    var product = this.props.products.map(function (product) {
      if (product.name.indexOf(filterText) === -1) {
        return;
      }

      return (
        <ProductRow
          onProductTableUpdate={onProductTableUpdate}
          onKeyDown={keyDown}
          onCheckbox={checkboxBlock}
          product={product}
          onDelEvent={rowDel.bind(this)}
          key={product.id}
        />
      );
    });
    return (
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent">
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {/* <th>
                    <CheckBox
                      selected={this.props.allChecked}
                      onChange={(name, checked) => {
                        checkboxBlock(checked, null, true)
                      }}
                    />
                  </th> */}
                  <th>Item Code</th>
                  <th>Department</th>
                  <th>Item Group</th>
                  <th>Item</th>
                  <th>Quantity</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>

              <tbody>{product}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

class ProductRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Countries: [
        { label: "kg", value: 355 },
        { label: "No", value: 54 },
        { label: "set", value: 43 },
      ],
      selected: null,
    };
  }
  onDelEvent() {
    this.props.onDelEvent(this.props.product);
  }

  handleChange = (value) => {
    this.setState({
      selected: value,
    });
  };
  render() {
    return (
      <tr>
        <EditableCell
          onProductTableUpdate={this.props.onProductTableUpdate}
          onKeyDown={this.props.onKeyDown}
          cellData={{
            type: "name",
            value: this.props.product.name,
            id: this.props.product.id,
          }}
        />

        <EditableCell
          onProductTableUpdate={this.props.onProductTableUpdate}
          cellData={{
            type: "department",
            value: this.props.product.department,
            id: this.props.product.id,
          }}
        />
        <EditableCell
          onProductTableUpdate={this.props.onProductTableUpdate}
          cellData={{
            type: "itemName",
            value: this.props.product.itemName,
            id: this.props.product.id,
          }}
        />
        <EditableCell
          onProductTableUpdate={this.props.onProductTableUpdate}
          cellData={{
            type: "category",
            value: this.props.product.category,
            id: this.props.product.id,
          }}
        />
        <td>
          <div className="quantityDropdownBlock">
            <Grid className="selectAndTextfield">
              <Grid
                xs={12}
                md={12}
                className="dynamicSelectBlock"
                style={{ display: "flex", alignItems: "center" }}
              >
                <DynamicSelect
                  arrayOfData={this.state.Countries}
                  className="dropdownBlockContainer"
                  onSelectChange={this.handleChange}
                  isDropdownOpen={this.state.isDropdownOpen}
                  handleMenuOpen={this.handleMenuOpen}
                  defaultValue={{
                    value: this.state.selected,
                    label: this.state.selected,
                  }}
                />

                <EditableCell
                  type="text"
                  placeholder="local"
                  onProductTableUpdate={this.props.onProductTableUpdate}
                  cellData={{
                    type: "amount",
                    value: this.state.selected,
                    id: this.props.product.id,
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </td>
        <EditableCell
          onProductTableUpdate={this.props.onProductTableUpdate}
          cellData={{
            type: "rate",
            value: this.props.product.rate,
            id: this.props.product.id,
          }}
        />

        <EditableCell
          onProductTableUpdate={this.props.onProductTableUpdate}
          cellData={{
            type: "amount",
            value: this.props.product.amount,
            id: this.props.product.id,
          }}
        />

        <td className="">
          <input
            type="button"
            onClick={this.onDelEvent.bind(this)}
            value="X"
            className="deleteDisplayButton"
          />
        </td>
      </tr>
    );
  }
}

class EditableCell extends React.Component {
  render() {
    return (
      <td style={{ padding: "0px 10px" }} className="tableDisplayBlock">
        <input
          type="text"
          name={this.props.cellData.type}
          id={this.props.cellData.id}
          value={this.props.cellData.value}
          onChange={this.props.onProductTableUpdate}
          onKeyDown={this.props.onKeyDown}
          className="inputBlockContainer"
        />
      </td>
    );
  }
}
