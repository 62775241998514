import React, { useState, useEffect } from "react";
import { Grid, Step, Stepper, StepLabel } from "@mui/material";
import Link from "@mui/material/Link";
import CustomButton from "../../../components/Buttons/Buttons";
import DynamicSelect from "../../../components/Dropdown/Sales/InvoiceDynamicSelect";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import FillDetails from "./RegularFillDetails";
import LineItems from "./RegularLineItems";
import Preview from "./RegularPreview";
import Transportation from "./RegularTransportation";
import swal from "sweetalert";
import {
  getSalesOrder,
  SalesOrderById,
  setSalesOrder,
  CreateJuteGovtSalesOrder,
  SOByIdForJuteGovt,
  UpdateJuteGovtSalesOrder,
  CreateJuteYarnSalesOrder,
  SOByIdForJuteYarn,
  UpdateJuteYarnSalesOrder,
  CreateJuteHessianSalesOrder,
  SalesOrderJuteHessianById,
  UpdateJuteHessianSalesOrder,
} from "../../../store/Sales/SalesOrder/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { serverApi } from "../../../helpers/Consts";
import moment from "moment";
import JuteGovtFillDetails from "./JuteGovernment/JuteGovtFillDetails";
import JuteGovtLineItems from "./JuteGovernment/JuteGovtLineItems";
import JuteGovtTransportation from "./JuteGovernment/JuteGovtTransportation";
import JuteGovtPreview from "./JuteGovernment/JuteGovtPreview";
import { isEmpty, isEmptyWithZero } from "../../../Utilities/helper";
import JuteYarnFillDetails from "./JuteYarnFillDetails";
import JuteYarnLineItems from "./JuteYarnLineItems";
import JuteYarnTransportation from "./JuteYarnTransportation";
import JuteYarnPreview from "./JuteYarnPreview";
import {
  clearSalesOrderHeader,
  ClearSOJuteYarnHeaders,
  ClearSOJuteYarnLineItems,
  ClearSOJuteHessianHeaders,
  getSOFiles,
} from "../../../store/Sales/SalesOrder/actions";
import JuteHessianFillDetails from "./JuteHessianFillDetails";
import JuteHessianLineItems from "./JuteHessianLineItems";
import JuteHessianTransportation from "./JuteHessianTransportation";
import JuteHessianPreview from "./JuteHessianPreview";
import { limitDecimals } from "../../../Utilities/helper";

const CreateSalesOrder = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [locationState] = useState(useLocation());
  const [steps] = useState([
    {
      title: "Select Option",
      icon: "http://vowerp.com/MailImages/assets/stepperSelection-00.png",
      activeIcon:
        "http://vowerp.com/MailImages/assets/stepperSelectionActive-00.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Fill Details",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Add Items",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Terms",
      icon: "http://vowerp.com/MailImages/assets/billing_inactive.png",
      activeIcon: "http://vowerp.com/MailImages/assets/billing_active.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      title: "Preview",
      icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
      activeIcon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
      onClick: (e) => {
        e.preventDefault();
      },
    },
  ]);

  const [currentStep, setcurrentStep] = useState(1);
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [invoiceType, setinvoiceType] = useState("");
  const [alignment, setalignment] = useState("left");
  const [quotationMandatory, setquotationMandatory] = useState(false);
  const [source, setsource] = useState("");
  const [InvoiceTypeSelection, setInvoiceTypeSelection] = useState(true);
  const [SalesOrderMethod, setSalesOrderMethod] = useState(false);
  const [SalesOrderType, setSalesOrderType] = useState(false);

  const [TodayDate, setTodayDate] = useState(moment().format());

  const [invoiceData, setInvoiceData] = useState([]);
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const {
    LineItemsData,
    SalesOrderHeader,
    SoJuteGovtLineItems,
    SoJuteGovtHeaders,
    SoJuteYarnLineItems,
    SoJuteYarnHeaders,
    SoJuteHessianLineItems,
    SoJuteHessianHeaders,
  } = useSelector((state) => state.SalesOrderReducer);
  const QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;
    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };
  const onClickBefore = () => {
    if (currentStep === 1) {
      if (SalesOrderMethod === true) {
        setSalesOrderMethod(false);
        setInvoiceTypeSelection(true);
        setalignment("left");
      } else if (SalesOrderMethod === false) {
        props.history.push("/s_order");
      }
    } else {
      setcurrentStep(currentStep - 1);
    }
  };
  const clickBefore = () => {
    if (currentStep === 2) {
      let url =
        props.location.pathname === "/create_sales_order" ? "/s_order" : "";
      props.history.push(url);
    } else {
      setcurrentStep(currentStep - 1);
    }

    if (invoiceType === 2) {
      //this condition for the juteGovtSalesOrder to skip the select invoiceType screen
      setInvoiceTypeSelection(true);
      setSalesOrderMethod(false);
      setcurrentStep(currentStep - 1);
    } else {
      setcurrentStep(currentStep - 1);
      dispatch(clearSalesOrderHeader());
      dispatch(ClearSOJuteHessianHeaders());
      dispatch(ClearSOJuteYarnHeaders());
      dispatch(ClearSOJuteYarnLineItems());
    }
    if (invoiceType === 3) {
      //this condition for the juteYarnSalesOrder to skip the select invoiceType screen
      setInvoiceTypeSelection(true);
      setSalesOrderMethod(false);
      setcurrentStep(currentStep - 1);
    } else {
      setcurrentStep(currentStep - 1);
      dispatch(clearSalesOrderHeader());
      dispatch(ClearSOJuteHessianHeaders());
      dispatch(ClearSOJuteYarnHeaders());
    }

    if (invoiceType === 4) {
      //this condition for the Jute Hessian to skip the select invoiceType screen
      setInvoiceTypeSelection(true);
      setSalesOrderMethod(false);
      setcurrentStep(currentStep - 1);
    } else {
      setcurrentStep(currentStep - 1);
      // dispatch(clearSalesOrderHeader());
      dispatch(ClearSOJuteHessianHeaders());
    }
  };
  const onClickNext = () => {
    if (currentStep === 1) {
      if (invoiceType !== "") {
        setInvoiceTypeSelection(false);
        setSalesOrderMethod(true);
      } else {
        swal("Please Select the Invoice Type");
      }
      if (invoiceType === 2 || invoiceType === 4) {
        //this condition for the juteGovtSalesOrder to skip the select invoiceType screen
        setcurrentStep(currentStep + 1);
      } else {
        if (SalesOrderMethod === true) {
          if (SalesOrderType === false) {
            swal("Please Select the Sales Order Type");
          } else {
            setcurrentStep(currentStep + 1);
          }
        }
      }
      if (invoiceType === 3) {
        //this condition for the juteYarnSalesOrder to skip the select invoiceType screen
        setcurrentStep(currentStep + 1);
      } else {
        if (SalesOrderMethod === true) {
          if (SalesOrderType === false) {
            swal("Please Select the Sales Order Type");
          } else {
            setcurrentStep(currentStep + 1);
          }
        }
      }
    } else if (currentStep === 2) {
      if (invoiceType === 1) {
        if (source === "WITHQUOTATION") {
          if (SalesOrderHeader.quotationId === "") {
            swal("Please Select the Quotation");
          } else if (RegularMandatoryCheck()) {
          }
        } else {
          RegularMandatoryCheck();
        }
      } else if (invoiceType === 2) {
        if (source === "WITHQUOTATION") {
          if (SoJuteGovtHeaders.quotationId === "") {
            swal("Please Select the Quotation");
          } else if (JuteGovtMandatoryCheck()) {
          }
        } else {
          JuteGovtMandatoryCheck();
        }
      } else if (invoiceType === 3) {
        if (SoJuteYarnHeaders.branchId === "") {
          swal("Please Select the Branch");
        } else if (isEmptyWithZero(SoJuteYarnHeaders.brokerId)) {
          if (isEmpty(SoJuteYarnHeaders.customerId)) {
            swal("Please Select the Consignee");
          } else if (SoJuteYarnHeaders.billingTo === "") {
            swal("Please Select the Billing Address");
          } else if (SoJuteYarnHeaders.shippingTo === "") {
            swal("Please Select the Shipping Address");
          } else if (SoJuteYarnHeaders.salesDate == null) {
            swal("Please Enter the Sale Date");
          } else if (SoJuteYarnHeaders.salesOrderExpiryDate == null) {
            swal("Please Enter the Expire Date");
          } else if (isEmpty(SoJuteYarnHeaders.deliveryTerms)) {
            swal("Please Select the Delivery terms");
          } else {
            setcurrentStep(currentStep + 1);
          }
        } else {
          if (isEmpty(SoJuteYarnHeaders.customerId)) {
            swal("Please Select the Consignee");
          } else if (SoJuteYarnHeaders.billingTo === "") {
            swal("Please Select the Billing Address");
          } else if (SoJuteYarnHeaders.shippingTo === "") {
            swal("Please Select the Shipping Address");
          } else if (SoJuteYarnHeaders.salesDate == null) {
            swal("Please Enter the Sale Date");
          } else if (SoJuteYarnHeaders.salesOrderExpiryDate == null) {
            swal("Please Enter the Expire Date");
          } else if (
            isEmptyWithZero(SoJuteYarnHeaders.brokerCommisionPercent)
          ) {
            swal("Please Enter the Broker%");
          } else if (isEmpty(SoJuteYarnHeaders.deliveryTerms)) {
            swal("Please Select the Delivery terms");
          } else {
            setcurrentStep(currentStep + 1);
          }
        }
      } else if (invoiceType === 4) {
        if (source === "WITHQUOTATION") {
          if (SoJuteHessianHeaders.quotationId === "") {
            swal("Please Select the Quotation");
          } else if (SOJuteHessianVal()) {
          }
        } else {
          SOJuteHessianVal();
        }
      } else {
        setcurrentStep(currentStep + 1);
      }
    } else if (currentStep === 3) {
      if (invoiceType === 1) {
        let ValidateFields = false;
        let FilteredDatas = LineItemsData.filter((item) => item.isActive !== 0);
        if (FilteredDatas.length === 1) {
          FilteredDatas.map((item, index) => {
            if (item.itemGroupId === "") {
              swal("Please select the ItemGroup");
              return false;
            } else if (item.itemId == null) {
              swal("Please select the Item");
              return false;
            } else if (Number(item.quantity) <= 0) {
              swal("Please Enter the Quantity");
              return false;
            } else if (Number(item.rate) <= 0) {
              swal("Please Enter the Rate");
              return false;
            } else if (item.uom === "") {
              swal("Please Enter the uom");
              return false;
            } else {
              ValidateFields = true;
            }
          });
        } else {
          let FilteredData = LineItemsData.filter(
            (item) => item.isActive !== 0 && item.itemGroupId !== ""
          );
          for (let i = 0; i <= FilteredData.length - 1; i++) {
            if (FilteredData[i].itemGroupId === null) {
              swal("Please select the ItemGroup");
              return false;
            } else if (FilteredData[i].itemId == null) {
              swal("Please select the Item");
              return false;
            } else if (Number(FilteredData[i].quantity) <= 0) {
              swal("Please Enter the Quantity");
              return false;
            } else if (Number(FilteredData[i].rate) <= 0) {
              swal("Please Enter the Rate");
              return false;
            } else if (FilteredData[i].uom === "") {
              swal("Please Enter the uom");
              return false;
            } else {
              ValidateFields = true;
            }
          }
        }
        if (ValidateFields === true) {
          setcurrentStep(currentStep + 1);
        }
      } else if (invoiceType === 2) {
        let ValidateJuteGovtFields = false;
        let FilteredDatas = SoJuteGovtLineItems.filter(
          (item) => item.isActive !== 0
        );
        if (FilteredDatas.length === 1) {
          FilteredDatas.map((item, index) => {
            if (item.itemGroupValue === "") {
              swal("Please select the ItemGroup");
              return false;
            } else if (item.itemId === null) {
              swal("Please select the Item");
              return false;
            } else if (item.quantity === "" || item.quantity === "0.000") {
              swal("Please Enter the Quantity");
              return false;
            } else if (item.uom === "") {
              swal("Please Enter the uom");
              return false;
            } else {
              ValidateJuteGovtFields = true;
            }
          });
        } else {
          let FilteredData = SoJuteGovtLineItems.filter(
            (item) => item.isActive !== 0
          );
          for (let i = 0; i <= FilteredData.length - 1; i++) {
            if (FilteredData[i].itemGroupValue === "") {
              swal("Please select the ItemGroup");
              return false;
            } else if (FilteredData[i].itemId === null) {
              swal("Please select the Item");
              return false;
            } else if (
              FilteredData[i].rate === "" ||
              FilteredData[i].rate === "0.000"
            ) {
              swal("Please Enter the Rate");
              return false;
            } else if (
              FilteredData[i].quantity === "" ||
              FilteredData[i].quantity === "0.000"
            ) {
              swal("Please Enter the Quantity");
              return false;
            } else if (FilteredData[i].uom === "") {
              swal("Please Enter the uom");
              return false;
            } else {
              ValidateJuteGovtFields = true;
            }
          }
        }
        if (ValidateJuteGovtFields === true) {
          setcurrentStep(currentStep + 1);
        }
      } else if (invoiceType === 3) {
        let ValidateJuteYarnFields = false;
        let FilteredDatas = SoJuteYarnLineItems.filter(
          (item) => item.isActive !== 0
        );
        if (FilteredDatas.length === 1) {
          FilteredDatas.map((item, index) => {
            if (isEmpty(item.itemGroupId)) {
              swal("Please select the ItemGroup");
              return false;
            } else if (item.itemId == null) {
              swal("Please select the ItemName");
              return false;
            } else if (Number(item.quantity) <= 0) {
              swal("Please Enter the Quantity");
              return false;
            } else if (item.uom === "") {
              swal("Please Enter the uom");
              return false;
            } else if (item.yarnQuantityUomId === null) {
              swal("Please Enter the uom2");
              return false;
            } else if (item.rate === "" || item.rate === "0.000") {
              swal("Please Enter the Rate");
              return false;
            } else if (item.yarnRateUomId === null) {
              swal("Please Enter the Rate UOM 2");
              return false;
            } else {
              ValidateJuteYarnFields = true;
            }
          });
        } else {
          let FilteredData = SoJuteYarnLineItems.filter(
            (item) => item.isActive !== 0 && item.itemGroupId !== ""
          );
          for (let i = 0; i <= FilteredData.length - 1; i++) {
            if (isEmpty(FilteredData[i].itemGroupId)) {
              swal("Please select the ItemGroup");
              return false;
            } else if (FilteredData[i].itemId == null) {
              swal("Please select the ItemName");
              return false;
            } else if (Number(FilteredData[i].quantity) <= 0) {
              swal("Please Enter the Quantity");
              return false;
            } else if (
              FilteredData[i].rate === "" ||
              FilteredData[i].rate === "0.000"
            ) {
              swal("Please Enter the Rate");
              return false;
            } else if (FilteredData[i].uom === "") {
              swal("Please Enter the uom");
              return false;
            } else if (FilteredData[i].yarnQuantityUomId === null) {
              swal("Please Enter the uom2");
              return false;
            } else if (
              FilteredData[i].rate === "" ||
              FilteredData[i].rate === "0.000"
            ) {
              swal("Please Enter the Rate");
              return false;
            } else if (FilteredData[i].yarnRateUomId === null) {
              swal("Please Enter the Rate UOM 2");
              return false;
            } else {
              ValidateJuteYarnFields = true;
            }
          }
        }
        if (ValidateJuteYarnFields === true) {
          setcurrentStep(currentStep + 1);
        }
      } else if (invoiceType == 4) {
        let ValidateFields = false;
        let FilteredData = SoJuteHessianLineItems.filter(
          (item) => item.isActive !== 0
        );
        if (FilteredData.length === 1) {
          FilteredData.map((item, index) => {
            if (item.itemGroupId === "") {
              swal("Please select the ItemGroup");
              return false;
            } else if (item.itemId === "" || item.itemId === null) {
              swal("Please select the Item");
              return false;
            } else if (Number(item.quantity) <= 0) {
              swal("Please Enter the Quantity");
              return false;
            } else if (item.uom === "") {
              swal("Please Enter the uom");
              return false;
            } else if (item.hessianQuantityUomId === null) {
              swal("Please Enter the uom2");
              return false;
            } else if (Number(item.rate) <= 0) {
              swal("Please Enter the Rate");
              return false;
            } else if (item.hessianRateUomId == "") {
              swal("Please Enter the Rate UOM 2");
              return false;
            } else {
              ValidateFields = true;
            }
          });
        } else {
          let FilteredData = SoJuteHessianLineItems.filter(
            (item) => item.isActive !== 0 && item.itemGroupId !== ""
          );
          for (let i = 0; i <= FilteredData.length - 1; i++) {
            if (FilteredData[i].itemGroupId === "") {
              swal("Please select the ItemGroup");
              return false;
            } else if (
              FilteredData[i].itemId === "" ||
              FilteredData[i].itemId === null
            ) {
              swal("Please select the Item");
              return false;
            } else if (Number(FilteredData[i].quantity) <= 0) {
              swal("Please Enter the Quantity");
              return false;
            } else if (FilteredData[i].uom === "") {
              swal("Please Enter the uom");
              return false;
            } else if (FilteredData[i].hessianQuantityUomId === null) {
              swal("Please Enter the uom2");
              return false;
            } else if (Number(FilteredData[i].rate) <= 0) {
              swal("Please Enter the Rate");
              return false;
            } else if (Number(FilteredData[i].hessianRateUomId) == "") {
              swal("Please Enter the Rate UOM 2");
              return false;
            } else {
              ValidateFields = true;
            }
          }
        }
        if (ValidateFields === true) {
          setcurrentStep(currentStep + 1);
        }
      } else {
        setcurrentStep(currentStep + 1);
      }
    } else {
      setcurrentStep(currentStep + 1);
    }
  };
  const RegularMandatoryCheck = () => {
    if (SalesOrderHeader.branchId === "") {
      swal("Please Select the Branch");
    } else if (
      (isEmpty(SalesOrderHeader.customerId) ||
        SalesOrderHeader.customerId === 0) &&
      (isEmpty(SalesOrderHeader.brokerId) || SalesOrderHeader.brokerId === 0)
    ) {
      swal("Please Select the Broker Or Consignee");
    } else if (
      SalesOrderHeader.brokerId !== "" &&
      SalesOrderHeader.brokerId !== null
    ) {
      if (SalesOrderHeader.salesDate == null) {
        swal("Please Enter the Sale Date");
      } else if (SalesOrderHeader.salesOrderExpiryDate == null) {
        swal("Please Enter the Expire Date");
      } else if (
        isEmptyWithZero(SalesOrderHeader.brokerCommisionPercent)
      ) {
        swal("Please Enter the Broker%");
      } else if (isEmpty(SalesOrderHeader.deliveryTerms)) {
        swal("Please Select the Delivery terms");
      } else {
        setcurrentStep(currentStep + 1);
      }
    } else if (SalesOrderHeader.customerId !== null) {
      if (SalesOrderHeader.billingTo == null) {
        swal("Please Select the Billing Address");
      } else if (SalesOrderHeader.shippingTo == null) {
        swal("Please Select the Shipping Address");
      } else if (SalesOrderHeader.salesDate == null) {
        swal("Please Enter the Sale Date");
      } else if (SalesOrderHeader.salesOrderExpiryDate == null) {
        swal("Please Enter the Expire Date");
      } else if (isEmpty(SalesOrderHeader.deliveryTerms)) {
        swal("Please Select the Delivery terms");
      } else {
        setcurrentStep(currentStep + 1);
      }
    }
  };
  const JuteGovtMandatoryCheck = () => {
    if (SoJuteGovtHeaders.branchId === "") {
      swal("Please Select the Branch");
    } else if (
      SoJuteGovtHeaders.customerId === null ||
      SoJuteGovtHeaders.customerId === ""
    ) {
      swal("Please Select the Consignee");
    } else if (SoJuteGovtHeaders.salesDate === "") {
      swal("Please Enter the Sale Date");
    } else if (SoJuteGovtHeaders.customerId !== null) {
      if (SoJuteGovtHeaders.billingTo == null) {
        swal("Please Select the Billing Address");
      } else if (SoJuteGovtHeaders.shippingTo == null) {
        swal("Please Select the Shipping Address");
      } else if (SoJuteGovtHeaders.salesDate == null) {
        swal("Please Enter the Sale Date");
      } else if (SoJuteGovtHeaders.salesOrderExpiryDate == null) {
        swal("Please Enter the Expire Date");
      } else if (SoJuteGovtHeaders.pcsoNo === "") {
        swal("Please Enter the PCSO No");
      } else if (SoJuteGovtHeaders.pcsoDate == null) {
        swal("Please Enter the PCSO Date");
      } else if (SoJuteGovtHeaders.deliveryTerms === "") {
        swal("Please Select Delivery Terms");
      } else {
        setcurrentStep(currentStep + 1);
      }
    } else {
      setcurrentStep(currentStep + 1);
    }
  };
  const JuteYarnMandatoryCheck = () => {
    if (SoJuteYarnHeaders.branchId === "") {
      swal("Please Select the Branch");
    } else if (isEmptyWithZero(SoJuteYarnHeaders.brokerId)) {
      if (isEmpty(SoJuteYarnHeaders.customerId)) {
        swal("Please Select the Consignee");
      } else if (SoJuteYarnHeaders.billingTo === "") {
        swal("Please Select the Billing Address");
      } else if (SoJuteYarnHeaders.shippingTo === "") {
        swal("Please Select the Shipping Address");
      } else if (SoJuteYarnHeaders.salesDate == null) {
        swal("Please Enter the Sale Date");
      } else if (SoJuteYarnHeaders.salesOrderExpiryDate == null) {
        swal("Please Enter the Expire Date");
      } else if (isEmpty(SoJuteYarnHeaders.deliveryTerms)) {
        swal("Please Select the Delivery terms");
      } else {
        setcurrentStep(currentStep + 1);
      }
    } else {
      if (isEmpty(SoJuteYarnHeaders.customerId)) {
        swal("Please Select the Consignee");
      } else if (SoJuteYarnHeaders.billingTo === "") {
        swal("Please Select the Billing Address");
      } else if (SoJuteYarnHeaders.shippingTo === "") {
        swal("Please Select the Shipping Address");
      } else if (SoJuteYarnHeaders.salesDate == null) {
        swal("Please Enter the Sale Date");
      } else if (SoJuteYarnHeaders.salesOrderExpiryDate == null) {
        swal("Please Enter the Expire Date");
      } else if (isEmpty(SoJuteYarnHeaders.brokerCommisionPercent)) {
        swal("Please Enter the Broker%");
      } else {
        setcurrentStep(currentStep + 1);
      }
    }
  };
  const SOJuteHessianVal = () => {
    if (SoJuteHessianHeaders.branchId === "") {
      swal("Please Select the Branch");
    } else if (
      SoJuteHessianHeaders.customerId === "" &&
      (SoJuteHessianHeaders.brokerId === "" ||
        SoJuteHessianHeaders.brokerId === 0)
    ) {
      swal("Please Select the Broker Or Consignee");
    } else if (
      SoJuteHessianHeaders.brokerId !== "" &&
      SoJuteHessianHeaders.brokerId !== 0
    ) {
      if (SoJuteHessianHeaders.salesDate === null) {
        swal("Please Enter the Sale Date");
      } else if (SoJuteHessianHeaders.salesOrderExpiryDate === null) {
        swal("Please Enter the Expire Date");
      } else if (
        SoJuteHessianHeaders.brokerCommisionPercent === "" ||
        SoJuteHessianHeaders.brokerCommisionPercent === "0"
      ) {
        swal("Please Enter the Broker%");
      } else if (isEmpty(SoJuteHessianHeaders.deliveryTerms)) {
        swal("Please Select the Delivery terms");
      } else {
        setcurrentStep(currentStep + 1);
      }
    } else if (
      SoJuteHessianHeaders.customerId !== "" &&
      SoJuteHessianHeaders.customerId !== 0
    ) {
      if (SoJuteHessianHeaders.billingTo === "") {
        swal("Please Select the Billing Address");
      } else if (SoJuteHessianHeaders.shippingTo === "") {
        swal("Please Select the Shipping Address");
      } else if (SoJuteHessianHeaders.salesDate == null) {
        swal("Please Enter the Sale Date");
      } else if (SoJuteHessianHeaders.salesOrderExpiryDate === null) {
        swal("Please Enter the Expire Date");
      } else if (isEmpty(SoJuteHessianHeaders.deliveryTerms)) {
        swal("Please Select the Delivery terms");
      } else {
        setcurrentStep(currentStep + 1);
      }
    }
  };

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };
  const handleChange = (selectedValue, selectedName, name) => {
    if (name === "invoiceType") {
      setinvoiceType(selectedValue);
    }
  };
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("Configuration")).configList) {
      if (
        JSON.parse(localStorage.getItem("Configuration")).configList.length !==
        0
      ) {
        JSON.parse(localStorage.getItem("Configuration")).configList.map(
          (item) => {
            if (item.configParameter === "invoiceType") {
              if (JSON.parse(item.configValue)) {
                if (JSON.parse(item.configValue).length !== 0) {
                  var List = [];
                  JSON.parse(item.configValue).map((item) => {
                    if (item.juteGovt === 1) {
                      List.push({
                        value: 2,
                        label: "Jute Government",
                        name: "Jute Government",
                      });
                    }
                    if (item.juteHessian === 1) {
                      List.push({
                        value: 4,
                        label: "Jute Hessian",
                        name: "Jute Hessian",
                      });
                    }
                    if (item.juteYarn === 1) {
                      List.push({
                        value: 3,
                        label: "Jute Yarn",
                        name: "Jute Yarn",
                      });
                    }
                    if (item.regInvoice === 1) {
                      List.push({
                        value: 1,
                        label: "Regular Invoice",
                        name: "Regular Invoice",
                      });
                    }
                    setInvoiceData(List);
                  });
                }
              }
            } else if (item.configParameter === "quetationMandatory") {
              if (item.configValue) {
                if (JSON.parse(item.configValue)[0].quatation === 1) {
                  setquotationMandatory(true);
                }
              }
            }
          }
        );
      }
    }
  }, [localStorage.getItem("Configuration")]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (locationState.state !== undefined) {
      if (locationState.state.rowData !== undefined) {
        if (locationState.state.rowData.invoiceType) {
          setinvoiceType(locationState.state.rowData.invoiceType);
        }
        setcurrentStep(5);
        if (locationState.state.rowData.invoiceType === 2) {
          dispatch(
            SOByIdForJuteGovt(
              serverApi.SALES_ORDER_VIEW_BYID +
                "2/" +
                locationState.state.rowData.salesOrderID +
                "/" +
                JSON.parse(localStorage.getItem("authUser")).userId,
              history,
              "SALES_ORDER"
            )
          );
          dispatch(
            getSOFiles(
              serverApi.GET_FILES +
                "45/" +
                locationState.state.rowData.salesOrderID +
                "/" +
                localStorage.getItem("companyId"),
              history
            )
          );
        } else if (locationState.state.rowData.invoiceType === 4) {
          dispatch(
            SalesOrderJuteHessianById(
              serverApi.SO_JUTE_HESSIAN_VIEW_BY_ID +
                "4/" +
                locationState.state.rowData.salesOrderID +
                "/" +
                JSON.parse(localStorage.getItem("authUser")).userId,
              history,
              "SALES_ORDER"
            )
          );
          dispatch(
            getSOFiles(
              serverApi.GET_FILES +
                "45/" +
                locationState.state.rowData.salesOrderID +
                "/" +
                localStorage.getItem("companyId"),
              history
            )
          );
        } else if (locationState.state.rowData.invoiceType === 3) {
          dispatch(
            SOByIdForJuteYarn(
              serverApi.SALES_ORDER_VIEW_BYID +
                "3/" +
                locationState.state.rowData.salesOrderID +
                "/" +
                JSON.parse(localStorage.getItem("authUser")).userId,
              history,
              "SALES_ORDER"
            )
          );
          dispatch(
            getSOFiles(
              serverApi.GET_FILES +
                "45/" +
                locationState.state.rowData.salesOrderID +
                "/" +
                localStorage.getItem("companyId"),
              history
            )
          );
        } else {
          dispatch(
            SalesOrderById(
              serverApi.SALES_ORDER_VIEW_BYID +
                "1/" +
                locationState.state.rowData.salesOrderID +
                "/" +
                JSON.parse(localStorage.getItem("authUser")).userId,
              history,
              "SALES_ORDER"
            )
          );
          dispatch(
            getSOFiles(
              serverApi.GET_FILES +
                "45/" +
                locationState.state.rowData.salesOrderID +
                "/" +
                localStorage.getItem("companyId"),
              history
            )
          );
        }
      }
    }
  }, []);

  const handleAlignment = (
    event,
    newAlignment
  ) => {
    //debugger;
    setalignment(newAlignment);
    if (newAlignment !== null) {
      if (Number(newAlignment) === 1) {
        setsource("WITHQUOTATION");
        setSalesOrderType(true);
      } else {
        setsource("DIRECTORDER");
        setSalesOrderType(true);
      }
    } else {
      setsource("");
      setSalesOrderType(false);
    }
  };

  const handleCurrentStep = (data) => {
    setcurrentStep(data);
  };

  const onClickApprove = (status) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Approve",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willApprove) => {
      if (willApprove) {
        OnUpdate(status);
      }
    });
  };

  const onClickReject = () => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Reject",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willreject) => {
      if (willreject) {
        OnUpdate("4");
      }
    });
  };

  // Update Method
  const OnUpdate = (status) => {
    if (invoiceType === 2) {
      UpdatePayloadForSoJuteGovt(status);
    } else if (invoiceType === 3) {
      UpdatePayloadForSoJuteYarn(status);
    } else if (invoiceType === 4) {
      UpdatePayloadForJuteHessian(status);
    } else {
      UpdatePayloadForRegular(status);
    }
  };

  const UpdatePayloadForRegular = (status) => {
    var LineItemDTO = [];
    var FilteredData = LineItemsData.filter(
      (item) => item.itemGroupId !== null && item.itemGroupId !== ""
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemDTO.push({
          regularSalesOrderLineItemId: item.regularSalesOrderLineItemId,
          regularSalesOrderId: item.regularSalesOrderId,
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          createdDate: TodayDate.split("T", 2)[0],
          companyId: localStorage.getItem("companyId"),
          quotationId: item.quotationId,
          quotationLineitemId: item.quotationLineitemId,
          hsncode: item.hsncode,
          itemGroupId: item.itemGroupId,
          itemId: item.itemId,
          itemMake: item.itemMake,
          quantity: limitDecimals(item.quantity, 3),
          uom: item.uom,
          isActive: item.isActive,
          rate: limitDecimals(item.rate, 3),
          doBalQty: "",
          invoiceBalQty: "",
          igstAmount: limitDecimals(item.igstAmount, 2),
          igstPercent: limitDecimals(item.igstPercent, 2),
          cgstAmount: limitDecimals(item.cgstAmount, 2),
          cgstPercent: limitDecimals(item.cgstPercent, 2),
          sgstAmount: limitDecimals(item.sgstAmount, 2),
          sgstPercent: limitDecimals(item.sgstPercent, 2),
          discountType: item.discountType,
          discount: limitDecimals(item.discount, 2),
          discountedRate: limitDecimals(item.discountedRate, 2),
          discountAmount: limitDecimals(item.discountAmount, 2),
          netAmount: limitDecimals(item.netAmount, 2),
          totalAmount: limitDecimals(item.totalAmount, 2),
          remarks: item.remarks,
          status: item.status,
          updatedBy: item.updatedBy,
        });
      });
    }

    const data = {
      regularSalesId: SalesOrderHeader.regularSalesId,
      finYear: localStorage.getItem("acadamicYear"),
      createdBy: SalesOrderHeader.createdBy,
      createdDate: SalesOrderHeader.createdDate,
      salesDate: SalesOrderHeader.salesDate,
      invoiceTypeId: "1",
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      branchId: SalesOrderHeader.branchId,
      quotationId: SalesOrderHeader.quotationId,
      customerId: SalesOrderHeader.customerId,
      customerLineItemId: SalesOrderHeader.billingTo,
      brokerId: SalesOrderHeader.brokerId,
      billingTo: SalesOrderHeader.billingTo,
      shippingTo: SalesOrderHeader.shippingTo,
      salesOrderExpiryDate: SalesOrderHeader.salesOrderExpiryDate,
      challanNo: "",
      challanDate: "",
      taxType: SalesOrderHeader.taxType,
      brokerCommisionPercent: SalesOrderHeader.brokerCommisionPercent,
      footerNote: SalesOrderHeader.footerNote,
      internalNote: SalesOrderHeader.internalNote,
      termsConditionType: SalesOrderHeader.termsConditionType,
      termsCondition: SalesOrderHeader.termsCondition,
      deliveryTerms: SalesOrderHeader.deliveryTerms,
      bankId: SalesOrderHeader.bankId,
      bankName: SalesOrderHeader.bankName,
      ifscCode: SalesOrderHeader.ifscCode,
      accountNo: SalesOrderHeader.accountNo,
      branch: SalesOrderHeader.branch,
      micrCode: SalesOrderHeader.micrCode,
      paymentTerms: SalesOrderHeader.paymentTerms,
      deliveryDays: SalesOrderHeader.deliveryDays,
      freightCharges: SalesOrderHeader.freightCharges,
      amountWithoutTax: SalesOrderHeader.netTotal,
      amountWithTax: SalesOrderHeader.TotalAmount,
      updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
      updatedOn: TodayDate.split("T", 2)[0],
      approvedBy: JSON.parse(localStorage.getItem("authUser")).userId,
      approvedOn: TodayDate.split("T", 2)[0],
      statusId: status,
      isActive: 1,
      salesNo: SalesOrderHeader.salesNo,
      salesOrderLineItemDTO: LineItemDTO,
    };
    dispatch(
      setSalesOrder(serverApi.UPDATE_REGULAR_SALES_ORDER, data, history)
    );
  };

  const UpdatePayloadForSoJuteGovt = (status) => {
    let LineItemsForSoJuteGovt = [];
    let FilteredData = SoJuteGovtLineItems.filter(
      (item) =>  item.itemGroupId !== null
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemsForSoJuteGovt.push({
          createdBy: userDetails.userId,
          createdDate: TodayDate.split("T", 2)[0],
          companyId: localStorage.getItem("companyId"),
          hsncode: item.hsnCode,
          itemId: item.itemId,
          itemGroupId: item.itemGroupId,
          itemMake: item.itemMake,
          quantity: item.quantity,
          uom: item.uom,
          rate: item.rate,
          doBalQty: item.doBalQty,
          invoiceBalQty: item.invoiceBalQty,
          igstAmount: item.igstAmount.toString(),
          igstPercent: item.igstPercent.toString(),
          cgstAmount: item.cgstAmount.toString(),
          cgstPercent: item.cgstPercent.toString(),
          sgstAmount: item.sgstAmount.toString(),
          sgstPercent: item.sgstPercent.toString(),
          discountType: item.discountType,
          discount: item.discount,
          discountedRate: item.discountedRate.toString(),
          discountAmount: item.discountAmount,
          netAmount: item.netAmount,
          totalAmount: item.totalAmount.toString(),
          remarks: item.remarks,
          regularSalesOrderLineItemId: item.regularSalesOrderLineItemId,
          regularSalesOrderId: item.regularSalesOrderId,
          salesOrderLineItemId: item.salesOrderLineItemId,
          isActive: item.isActive,
          govtIsActive:item.govtIsActive,
          govtSalesOrderLineItemId: item.govtSalesOrderLineItemId,
          govtRateUom: item.govtRateUom,
          govtRateUom2: item.govtRateUom2,
          govtRateUom3: item.govtRateUom3,
          govtQuantityUom: item.govtQuantityUom,
          govtQuantityUom2: item.govtQuantityUom2,
          govtQuantityUom3: item.govtQuantityUom3,
          govtRelationValue: item.govtRelationValue,
          govtRelationValue2: item.govtRelationValue2,
          govtRelationValue3: item.govtRelationValue3,
          govtQtyCalculatedValue: item.govtQtyCalculatedValue,
          govtQtyCalculatedValue2: item.govtQtyCalculatedValue2,
          govtQtyCalculatedValue3: item.govtQtyCalculatedValue3,
          govtRateCalculatedValue: item.govtRateCalculatedValue,
          govtRateCalculatedValue2: item.govtRateCalculatedValue2,
          govtRateCalculatedValue3: item.govtRateCalculatedValue3,
        });
      });
    }
    const JuteGovtUpdatePayload = {
      govtSalesOrderId: SoJuteGovtHeaders.govtSalesOrderId,
      salesOrderDTO: {
        finYear: localStorage.getItem("acadamicYear"),
        createdBy: SoJuteGovtHeaders.createdBy,
        createdDate: SoJuteGovtHeaders.createdDate,
        salesDate: SoJuteGovtHeaders.salesDate,
        companyId: localStorage.getItem("companyId"),
        branchId: SoJuteGovtHeaders.branchId,
        customerId: SoJuteGovtHeaders.customerId,
        customerLineItemId: SoJuteGovtHeaders.billingTo,
        brokerId: SoJuteGovtHeaders.brokerId,
        billingTo: SoJuteGovtHeaders.billingTo,
        shippingTo: SoJuteGovtHeaders.shippingTo,
        salesOrderExpiryDate: SoJuteGovtHeaders.salesOrderExpiryDate,
        isActive: 1,
        challanNo: SoJuteGovtHeaders.challanNo,
        challanDate: SoJuteGovtHeaders.challanDate,
        taxType: SoJuteGovtHeaders.taxType,
        brokerCommision: SoJuteGovtHeaders.brokerCommision,
        brokerCommisionPercent: SoJuteGovtHeaders.brokerCommisionPercent,
        footerNote: SoJuteGovtHeaders.footerNote,
        internalNote: SoJuteGovtHeaders.internalNote,
        termsConditionType: SoJuteGovtHeaders.termsConditionType,
        termsCondition: SoJuteGovtHeaders.termsCondition,
        transporterId: SoJuteGovtHeaders.transporterId,
        transporterName: SoJuteGovtHeaders.transporterName,
        transporterAddress: SoJuteGovtHeaders.transporterAddress,
        transporterStateName: SoJuteGovtHeaders.transporterStateName,
        transporterStateCode: SoJuteGovtHeaders.transporterStateCode,
        deliveryTerms: SoJuteGovtHeaders.deliveryTerms,
        paymentTerms: SoJuteGovtHeaders.paymentTerms,
        deliveryDays: SoJuteGovtHeaders.deliveryDays,
        freightCharges: SoJuteGovtHeaders.freightCharges,
        amountWithoutTax: SoJuteGovtHeaders.netTotal,
        amountWithTax: SoJuteGovtHeaders.TotalAmount,
        updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
        updatedOn: TodayDate.split("T", 2)[0],
        approvedBy: JSON.parse(localStorage.getItem("authUser")).userId,
        approvedOn: TodayDate.split("T", 2)[0],
        statusId: status,
        invoiceTypeId: SoJuteGovtHeaders.invoiceTypeId,
        regularSalesId: SoJuteGovtHeaders.regularSalesId,
        salesNo: SoJuteGovtHeaders.salesNo,
        salesOrderLineItemDTO: LineItemsForSoJuteGovt,
      },
      pcsoNo: SoJuteGovtHeaders.pcsoNo,
      pcsoDate: SoJuteGovtHeaders.pcsoDate,
      ammendmendNo: SoJuteGovtHeaders.ammendmentNo,
      ammendmendDate: SoJuteGovtHeaders.ammendmentDate,
    };
    dispatch(
      UpdateJuteGovtSalesOrder(
        serverApi.UPDATE_SO_JUTE_GOVT,
        JuteGovtUpdatePayload,
        history
      )
    );
  };

  const UpdatePayloadForSoJuteYarn = (status) => {
    let LineItemsForSoJuteYarn = [];
    let FilteredData = SoJuteYarnLineItems.filter(
      (item) => item.itemGroupId !== null && item.itemGroupId !== ""
    );

    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemsForSoJuteYarn.push({
          createdBy: userDetails.userId,
          createdDate: TodayDate.split("T", 2)[0],
          companyId: localStorage.getItem("companyId"),
          hsncode: item.hsncode,
          itemId: item.itemId,
          itemGroupId: item.itemGroupId,
          itemMake: item.itemMake,
          quantity: item.quantity,
          uom: item.uom,
          rate: item.rate,
          discountAmount: item.discountAmount,
          doBalQty: item.doBalQty,
          invoiceBalQty: item.invoiceBalQty,
          quotationId: item.quotationId,
          igstAmount: item.igstAmount,
          igstPercent: item.igstPercent,
          cgstAmount: item.cgstAmount,
          cgstPercent: item.cgstPercent,
          sgstAmount: item.sgstAmount,
          sgstPercent: item.sgstPercent,
          discountType: item.discountType,
          discount: item.discount,
          discountedRate: item.discountedRate,
          netAmount: item.netAmount,
          totalAmount: item.totalAmount,
          remarks: item.remarks,
          yarnRateUom: item.yarnRateUom,
          yarnRateUomId: item.yarnRateUomId,
          yarnRateUomRelationValue: item.yarnRateUomRelationValue,
          yarnRateIncludingBrokerage: item.yarnRateIncludingBrokerage,
          yarnRateExcludingBrokerage: item.yarnRateExcludingBrokerage,
          yarnRateCalculatedValue: limitDecimals(
            item.yarnRateCalculatedValue,
            3
          ),
          yarnQuantityUom: item.yarnQuantityUom,
          yarnQuantityUomId: item.yarnQuantityUomId,
          yarnQuantityUomRelationValue: item.yarnQuantityUomRelationValue,
          yarnQuantityCalculatedValue: limitDecimals(
            item.yarnQuantityCalculatedValue,
            3
          ),
          yarnVariableRelationValue: item.yarnVariableRelationValue,
          regularSalesOrderLineItemId: item.regularSalesOrderLineItemId,
          regularSalesOrderId: SoJuteYarnHeaders.regularSalesId,
          isActive: item.isActive,
          yarnIsActive:item.yarnIsActive,
          status: item.status,
          yarnSalesOrderLineItemId: item.yarnSalesOrderLineItemId,
        });
      });
    }
    const JuteYarnUpdatePayload = {
      yarnSalesOrderId: SoJuteYarnHeaders.yarnSalesOrderId,
      salesOrderDTO: {
        regularSalesId: SoJuteYarnHeaders.regularSalesId,
        salesId: SoJuteYarnHeaders.salesId,
        finYear: localStorage.getItem("acadamicYear"),
        createdBy: SoJuteYarnHeaders.createdBy,
        createdDate: SoJuteYarnHeaders.createdDate,
        salesDate: SoJuteYarnHeaders.salesDate,
        companyId: localStorage.getItem("companyId"),
        branchId: SoJuteYarnHeaders.branchId,
        customerId: SoJuteYarnHeaders.customerId,
        customerLineItemId: SoJuteYarnHeaders.billingTo,
        brokerId:
          SoJuteYarnHeaders.brokerId === 0 ? null : SoJuteYarnHeaders.brokerId,
        billingTo: SoJuteYarnHeaders.billingTo,
        shippingTo: SoJuteYarnHeaders.shippingTo,
        salesOrderExpiryDate: SoJuteYarnHeaders.salesOrderExpiryDate,
        isActive: 1,
        challanNo: SoJuteYarnHeaders.challanNo,
        challanDate: SoJuteYarnHeaders.challanDate,
        quotationId: SoJuteYarnHeaders.quotationId,
        taxType: SoJuteYarnHeaders.taxType,
        brokerCommision: SoJuteYarnHeaders.brokerCommision,
        brokerCommisionPercent: SoJuteYarnHeaders.brokerCommisionPercent,
        footerNote: SoJuteYarnHeaders.footerNote,
        internalNote: SoJuteYarnHeaders.internalNote,
        termsConditionType: SoJuteYarnHeaders.termsConditionType,
        termsCondition: SoJuteYarnHeaders.termsCondition,
        deliveryTerms: SoJuteYarnHeaders.deliveryTerms,
        paymentTerms: SoJuteYarnHeaders.paymentTerms,
        deliveryDays: SoJuteYarnHeaders.deliveryDays,
        amountWithoutTax: SoJuteYarnHeaders.netTotal,
        amountWithTax: SoJuteYarnHeaders.TotalAmount,
        freightCharges: SoJuteYarnHeaders.freightCharges,
        updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
        updatedOn: TodayDate.split("T", 2)[0],
        approvedBy: JSON.parse(localStorage.getItem("authUser")).userId,
        approvedOn: TodayDate.split("T", 2)[0],
        statusId: status,
        invoiceTypeId: SoJuteYarnHeaders.invoiceTypeId,
        salesNo: SoJuteYarnHeaders.salesNo,
        salesOrderLineItemDTO: LineItemsForSoJuteYarn,
      },
      yarnSalesId: SoJuteYarnHeaders.yarnSalesId,
      regularSalesId: SoJuteYarnHeaders.regularSalesId,
      invoiceTypeId: 3,
      brokerCommisionSource: SoJuteYarnHeaders.brokerCommisionSource,
      brokerCommisionPercent: SoJuteYarnHeaders.brokerCommisionPercent,
    };
    dispatch(
      UpdateJuteYarnSalesOrder(
        serverApi.UPDATE_SO_JUTE_YARN,
        JuteYarnUpdatePayload,
        history
      )
    );
  };

  const UpdatePayloadForJuteHessian = (status) => {
    var salesOrderLineItemDTO = [];
    var FilteredData = SoJuteHessianLineItems.filter(
      (item) => item.itemGroupId !== ""
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        salesOrderLineItemDTO.push({
          regularSalesOrderLineItemId: item.regularSalesOrderLineItemId,
          regularSalesOrderId: SoJuteHessianHeaders.regularSalesId,
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          createdDate: TodayDate.split("T", 2)[0],
          isActive: item.isActive,
          hessianIsActive:item.hessianIsActive,
          companyId: localStorage.getItem("companyId"),
          quotationId: SoJuteHessianHeaders.quotationId,
          hsncode: item.hsncode,
          itemGroupId: item.itemGroupId,
          itemGroupValue: item.itemGroupValue,
          itemId: item.itemId,
          itemMake: item.itemMake,
          quantity: limitDecimals(item.quantity, 3),
          uom: item.uom,
          rate: limitDecimals(item.rate, 3),
          doBalQty: "",
          invoiceBalQty: "",
          igstAmount: limitDecimals(item.igstAmount, 2),
          igstPercent: limitDecimals(item.igstPercent, 2),
          cgstAmount: limitDecimals(item.cgstAmount, 2),
          cgstPercent: limitDecimals(item.cgstPercent, 2),
          sgstAmount: limitDecimals(item.sgstAmount, 2),
          sgstPercent: limitDecimals(item.sgstPercent, 2),
          discountType: item.discountType,
          discount: limitDecimals(item.discount, 2),
          discountedRate: limitDecimals(item.discountedRate, 3),
          discountAmount: limitDecimals(item.discountAmount, 2),
          netAmount: limitDecimals(item.netAmount, 2),
          totalAmount: limitDecimals(item.totalAmount, 2),
          remarks: item.remarks,
          hessianSalesOrderLineItemId: item.hessianSalesOrderLineItemId,
          hessianRateIncludingBrokerage: limitDecimals(
            item.hessianRateIncludingBrokerage,
            3
          ),
          hessianRateExcludingBrokerage: limitDecimals(
            item.hessianRateExcludingBrokerage,
            3
          ),
          hessianQuantityUom: item.hessianQuantityUom,
          hessianQuantityUomId: item.hessianQuantityUomId,
          hessianQuantityRelationValue: item.hessianQuantityRelationValue,
          hessianQuantityCalculatedValue: limitDecimals(
            item.hessianQuantityCalculatedValue,
            3
          ),
          hessianRateUom: item.hessianRateUom,
          hessianRateUomId: item.hessianRateUomId,
          hessianRateRelationValue: item.hessianRateRelationValue,
          hessianRateCalculatedValue: limitDecimals(
            item.hessianRateCalculatedValue,
            3
          ),
          hessianRemarks: "",
        });
      });
    }

    const data = {
      salesOrderDTO: {
        regularSalesId: SoJuteHessianHeaders.regularSalesId,
        finYear: localStorage.getItem("acadamicYear"),
        createdBy: SoJuteHessianHeaders.createdBy,
        createdDate: SoJuteHessianHeaders.createdDate,
        salesDate: SoJuteHessianHeaders.salesDate,
        salesNo: SoJuteHessianHeaders.salesNo,
        companyId: localStorage.getItem("companyId"),
        branchId: SoJuteHessianHeaders.branchId,
        transporterId: SoJuteHessianHeaders.transporterId,
        customerId: SoJuteHessianHeaders.customerId,
        customerLineItemId: "",
        brokerId:
          SoJuteHessianHeaders.brokerId === 0
            ? ""
            : SoJuteHessianHeaders.brokerId,
        brokerName: SoJuteHessianHeaders.brokerName,
        billingTo: SoJuteHessianHeaders.billingTo,
        shippingTo: SoJuteHessianHeaders.shippingTo,
        quotationId: SoJuteHessianHeaders.quotationId,
        salesOrderExpiryDate: SoJuteHessianHeaders.salesOrderExpiryDate,
        challanNo: "",
        challanDate: "",
        taxType: SoJuteHessianHeaders.taxType,
        brokerCommision: "",
        brokerCommisionPercent: SoJuteHessianHeaders.brokerCommisionPercent,
        footerNote: SoJuteHessianHeaders.footerNote,
        internalNote: SoJuteHessianHeaders.internalNote,
        termsConditionType: SoJuteHessianHeaders.termsConditionType,
        termsCondition: SoJuteHessianHeaders.termsCondition,
        deliveryTerms: SoJuteHessianHeaders.deliveryTerms,
        paymentTerms: SoJuteHessianHeaders.paymentTerms,
        deliveryDays: SoJuteHessianHeaders.deliveryDays,
        freightCharges: SoJuteHessianHeaders.freightCharges,
        amountWithoutTax: SoJuteHessianHeaders.netTotal,
        amountWithTax: SoJuteHessianHeaders.TotalAmount,
        updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
        updatedOn: TodayDate.split("T", 2)[0],
        approvedBy: JSON.parse(localStorage.getItem("authUser")).userId,
        approvedOn: TodayDate.split("T", 2)[0],
        statusId: status,
        isActive: 1,
        invoiceTypeId: 4,
        salesOrderLineItemDTO: salesOrderLineItemDTO,
      },
      hessianSalesId: SoJuteHessianHeaders.hessianSalesId,
      finYear: localStorage.getItem("acadamicYear"),
      invoiceType: 4,
      brokerCommisionSource: SoJuteHessianHeaders.brokerCommisionSource,
    };
    dispatch(
      UpdateJuteHessianSalesOrder(
        serverApi.UPDATE_JUTE_HESSIAN_SALES_ORDER,
        data,
        history
      )
    );
  };

  //Create Method
  const onCreate = () => {
    if (invoiceType === 2) {
      CreatePayloadForSoJuteGovt();
    } else if (invoiceType === 3) {
      CreatePayloadForSoJuteYarn();
    } else if (invoiceType === 4) {
      CreatePayloadForJuteHessian();
    } else {
      CreatePayloadForSoRegular();
    }
  };

  const CreatePayloadForSoRegular = () => {
    var LineItemDTO = [];
    var FilteredData = LineItemsData.filter(
      (item) =>
        item.isActive !== 0 &&
        item.itemGroupId !== null &&
        item.itemGroupId !== ""
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemDTO.push({
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          createdDate: TodayDate.split("T", 2)[0],
          companyId: localStorage.getItem("companyId"),
          quotationId: item.quotationId,
          quotationLineitemId: item.quotationLineitemId,
          hsncode: item.hsncode,
          itemGroupId: item.itemGroupId,
          itemId: item.itemId,
          itemMake: item.itemMake,
          quantity: limitDecimals(item.quantity, 3),
          uom: item.uom,
          rate: limitDecimals(item.rate, 3),
          doBalQty: "",
          invoiceBalQty: "",
          igstAmount: limitDecimals(item.igstAmount),
          igstPercent: limitDecimals(item.igstPercent),
          cgstAmount: limitDecimals(item.cgstAmount),
          cgstPercent: limitDecimals(item.cgstPercent),
          sgstAmount: limitDecimals(item.sgstAmount),
          sgstPercent: limitDecimals(item.sgstPercent),
          discountType: item.discountType,
          discount: limitDecimals(item.discount),
          discountedRate: limitDecimals(item.discountedRate, 3),
          discountAmount: limitDecimals(item.discountAmount, 2),
          netAmount: limitDecimals(item.netAmount, 2),
          totalAmount: limitDecimals(item.totalAmount, 2),
          remarks: item.remarks,
        });
      });
    }

    const data = {
      finYear: localStorage.getItem("acadamicYear"),
      createdBy: 2,
      createdDate: TodayDate.split("T", 2)[0],
      salesDate: SalesOrderHeader.salesDate,
      invoiceTypeId: "1",
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      branchId: SalesOrderHeader.branchId,
      quotationId: SalesOrderHeader.quotationId,
      customerId: SalesOrderHeader.customerId,
      customerLineItemId: SalesOrderHeader.billingTo,
      brokerId: SalesOrderHeader.brokerId,
      billingTo: SalesOrderHeader.billingTo,
      shippingTo: SalesOrderHeader.shippingTo,
      salesOrderExpiryDate: SalesOrderHeader.salesOrderExpiryDate,
      challanNo: "",
      challanDate: "",
      taxType: SalesOrderHeader.taxType,
      bankId: SalesOrderHeader.bankId,
      ifscCode: SalesOrderHeader.ifscCode,
      bankName: SalesOrderHeader.bankName,
      branch: SalesOrderHeader.branch,
      micrCode: SalesOrderHeader.micrCode,
      accountNo: SalesOrderHeader.accountNo,
      brokerCommisionPercent: SalesOrderHeader.brokerCommisionPercent,
      footerNote: SalesOrderHeader.footerNote,
      internalNote: SalesOrderHeader.internalNote,
      termsConditionType: SalesOrderHeader.termsConditionType,
      termsCondition: SalesOrderHeader.termsCondition,
      deliveryTerms: SalesOrderHeader.deliveryTerms,
      paymentTerms: SalesOrderHeader.paymentTerms,
      deliveryDays: SalesOrderHeader.deliveryDays,
      freightCharges: limitDecimals(SalesOrderHeader.freightCharges),
      amountWithoutTax: limitDecimals(SalesOrderHeader.netTotal),
      amountWithTax: limitDecimals(SalesOrderHeader.TotalAmount),
      updatedBy: "",
      updatedOn: "",
      approvedBy: "",
      approvedOn: "",
      statusId: 1,
      salesOrderLineItemDTO: LineItemDTO,
    };
    dispatch(
      getSalesOrder(serverApi.CREATE_REGULAR_SALES_ORDER, data, history)
    );
  };
  const CreatePayloadForSoJuteGovt = () => {
    let LineItemsForSoJuteGovt = [];
    let FilteredData = SoJuteGovtLineItems.filter(
      (item) => item.isActive !== 0 && item.itemGroupId !== null
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemsForSoJuteGovt.push({
          createdBy: userDetails.userId,
          createdDate: TodayDate.split("T", 2)[0],
          companyId: localStorage.getItem("companyId"),
          hsncode: item.hsnCode,
          itemGroupId: item.itemGroupId,
          itemId: item.itemId,
          itemMake: item.itemMake,
          quantity: item.quantity,
          uom: item.uom,
          rate: item.rate,
          doBalQty: item.doBalQty,
          isActive: item.isActive,
          govtIsActive:item.govtIsActive,
          invoiceBalQty: item.invoiceBalQty,
          igstAmount: item.igstAmount.toString(),
          igstPercent: item.igstPercent.toString(),
          cgstAmount: item.cgstAmount.toString(),
          cgstPercent: item.cgstPercent.toString(),
          sgstAmount: item.sgstAmount.toString(),
          sgstPercent: item.sgstPercent.toString(),
          discountType: item.discountType.toString(),
          discount: item.discount.toString(),
          discountedRate: item.discountedRate.toString(),
          discountAmount: item.discountAmount,
          netAmount: item.netAmount,
          totalAmount: item.totalAmount.toString(),
          remarks: item.remarks,
          govtRateUom: item.govtRateUom,
          govtRateUom2: item.govtRateUom2,
          govtRateUom3: item.govtRateUom3,
          govtQuantityUom: item.govtQuantityUom,
          govtQuantityUom2: item.govtQuantityUom2,
          govtQuantityUom3: item.govtQuantityUom3,
          govtRelationValue: item.govtRelationValue,
          govtRelationValue2: item.govtRelationValue2,
          govtRelationValue3: item.govtRelationValue3,
          govtQtyCalculatedValue: item.govtQtyCalculatedValue,
          govtQtyCalculatedValue2: item.govtQtyCalculatedValue2,
          govtQtyCalculatedValue3: item.govtQtyCalculatedValue3,
          govtRateCalculatedValue: item.govtRateCalculatedValue,
          govtRateCalculatedValue2: item.govtRateCalculatedValue2,
          govtRateCalculatedValue3: item.govtRateCalculatedValue3,
        });
      });
    }
    const JuteGovtCreatePayload = {
      salesOrderDTO: {
        finYear: localStorage.getItem("acadamicYear"),
        createdBy: userDetails.userId,
        createdDate: TodayDate.split("T", 2)[0],
        salesDate: moment(SoJuteGovtHeaders.salesDate).format("YYYY-MM-DD"),
        companyId: localStorage.getItem("companyId"),
        branchId: SoJuteGovtHeaders.branchId,
        customerId: SoJuteGovtHeaders.customerId,
        customerLineItemId: SoJuteGovtHeaders.billingTo,
        brokerId: SoJuteGovtHeaders.brokerId,
        billingTo: SoJuteGovtHeaders.billingTo,
        salesOrderExpiryDate: SoJuteGovtHeaders.salesOrderExpiryDate,
        challanNo: SoJuteGovtHeaders.challanNo,
        challanDate: SoJuteGovtHeaders.challanDate,
        brokerCommision: SoJuteGovtHeaders.brokerCommision,
        brokerCommisionPercent: SoJuteGovtHeaders.brokerCommisionPercent,
        footerNote: SoJuteGovtHeaders.footerNote,
        internalNote: SoJuteGovtHeaders.internalNote,
        deliveryTerms: SoJuteGovtHeaders.deliveryTerms,
        paymentTerms: SoJuteGovtHeaders.paymentTerms,
        deliveryDays: SoJuteGovtHeaders.deliveryDays,
        freightCharges: SoJuteGovtHeaders.freightCharges,
        amountWithoutTax: SoJuteGovtHeaders.netTotal,
        amountWithTax: SoJuteGovtHeaders.TotalAmount,
        approvedBy: SoJuteGovtHeaders.approvedBy,
        approvedOn: SoJuteGovtHeaders.approvedOn,
        invoiceTypeId: 2,
        salesOrderLineItemDTO: LineItemsForSoJuteGovt,
        shippingTo: SoJuteGovtHeaders.shippingTo,
        statusId: 1,
        isActive: 1,
        updatedBy: "",
        updatedOn: "",
        termsConditionType: SoJuteGovtHeaders.termsConditionType,
        termsCondition: SoJuteGovtHeaders.termsCondition,
        taxType: SoJuteGovtHeaders.taxType,
        transporterId: SoJuteGovtHeaders.transporterId,
        transporterName: SoJuteGovtHeaders.transporterName,
        transporterAddress: SoJuteGovtHeaders.transporterAddress,
        transporterStateName: SoJuteGovtHeaders.transporterStateName,
        transporterStateCode: SoJuteGovtHeaders.transporterStateCode,
      },
      pcsoNo: SoJuteGovtHeaders.pcsoNo,
      pcsoDate: moment(SoJuteGovtHeaders.pcsoDate).format("YYYY-MM-DD"),
      ammendmendNo: SoJuteGovtHeaders.ammendmendNo,
      ammendmendDate: SoJuteGovtHeaders.ammendmendDate,
    };
    dispatch(
      CreateJuteGovtSalesOrder(
        serverApi.CREATE_SO_JUTE_GOVT,
        JuteGovtCreatePayload,
        history
      )
    );
  };
  const CreatePayloadForSoJuteYarn = () => {
    let LineItemsForSoJuteYarn = [];
    let FilteredData = SoJuteYarnLineItems.filter(
      (item) =>
        item.isActive !== 0 &&
        item.itemGroupId !== null &&
        item.itemGroupId !== ""
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemsForSoJuteYarn.push({
          salesOrderLineItemDTO: item.salesOrderLineItemDTO,
          createdBy: userDetails.userId,
          createdDate: TodayDate.split("T", 2)[0],
          companyId: localStorage.getItem("companyId"),
          hsncode: item.hsncode,
          itemGroupId: item.itemGroupId,
          itemId: item.itemId,
          itemMake: item.itemMake,
          quantity: limitDecimals(item.quantity, 3),
          uom: item.uom,
          rate: limitDecimals(item.rate, 3),
          doBalQty: item.doBalQty,
          isActive: item.isActive,
          yarnIsActive:item.yarnIsActive,
          discountAmount: limitDecimals(item.discountAmount, 3),
          customerLineItemId: item.customerLineItemId,
          invoiceBalQty: item.invoiceBalQty,
          quotationId: item.quotationId,
          igstAmount: limitDecimals(item.igstAmount, 2),
          igstPercent: limitDecimals(item.igstPercent),
          cgstAmount: limitDecimals(item.cgstAmount, 2),
          cgstPercent: limitDecimals(item.cgstPercent),
          sgstAmount: limitDecimals(item.sgstAmount, 2),
          sgstPercent: limitDecimals(item.sgstPercent),
          discountType: item.discountType,
          discount: limitDecimals(item.discount, 3),
          discountedRate: limitDecimals(item.discountedRate, 3),
          netAmount: limitDecimals(item.netAmount, 2),
          totalAmount: limitDecimals(item.totalAmount, 2),
          remarks: item.remarks,
          yarnRateUom: item.yarnRateUom,
          yarnRateIncludingBrokerage: limitDecimals(
            item.yarnRateIncludingBrokerage,
            3
          ),
          yarnRateExcludingBrokerage: limitDecimals(
            item.yarnRateExcludingBrokerage,
            3
          ),
          yarnRateUomId: item.yarnRateUomId,
          yarnRateUomRelationValue: item.yarnRateUomRelationValue,
          yarnRateCalculatedValue: limitDecimals(
            item.yarnRateCalculatedValue,
            3
          ),
          yarnQuantityUom: item.yarnQuantityUom,
          yarnQuantityUomId: item.yarnQuantityUomId,
          yarnQuantityUomRelationValue: item.yarnQuantityUomRelationValue,
          yarnQuantityCalculatedValue: limitDecimals(
            item.yarnQuantityCalculatedValue,
            3
          ),
          yarnVariableRelationValue: item.yarnVariableRelationValue,
          regularSalesOrderLineItemId: item.regularSalesOrderLineItemId,
          salesRegularInvoiceLineitemId: item.salesRegularInvoiceLineitemId,
          regularSalesOrderId: item.regularSalesOrderId,
          yarnSalesOrderLineItemId: item.yarnSalesOrderLineItemId,
        });
      });
    }
    const JuteYarnCreatePayload = {
      salesOrderDTO: {
        salesId: SoJuteYarnHeaders.salesId,
        regularSalesId: SoJuteYarnHeaders.regularSalesId,
        salesOrderLineItemDTO: SoJuteYarnHeaders.salesOrderLineItemDTO,
        finYear: localStorage.getItem("acadamicYear"),
        createdBy: userDetails.userId,
        createdDate: TodayDate.split("T", 2)[0],
        salesDate: moment(SoJuteYarnHeaders.salesDate).format("YYYY-MM-DD"),
        companyId: localStorage.getItem("companyId"),
        branchId: SoJuteYarnHeaders.branchId,
        quotationId: SoJuteYarnHeaders.quotationId,
        customerId: SoJuteYarnHeaders.customerId,
        customerLineItemId: SoJuteYarnHeaders.billingTo,
        brokerId:
          SoJuteYarnHeaders.brokerId === 0 ? null : SoJuteYarnHeaders.brokerId,
        billingTo: SoJuteYarnHeaders.billingTo,
        salesOrderExpiryDate: SoJuteYarnHeaders.salesOrderExpiryDate,
        challanNo: SoJuteYarnHeaders.challanNo,
        challanDate: SoJuteYarnHeaders.challanDate,
        brokerCommision: SoJuteYarnHeaders.brokerCommision,
        brokerCommisionPercent: SoJuteYarnHeaders.brokerCommisionPercent,
        footerNote: SoJuteYarnHeaders.footerNote,
        internalNote: SoJuteYarnHeaders.internalNote,
        deliveryTerms: SoJuteYarnHeaders.deliveryTerms,
        paymentTerms: SoJuteYarnHeaders.paymentTerms,
        deliveryDays: SoJuteYarnHeaders.deliveryDays,
        freightCharges: SoJuteYarnHeaders.freightCharges,
        amountWithoutTax: SoJuteYarnHeaders.netTotal,
        amountWithTax: SoJuteYarnHeaders.TotalAmount,
        approvedBy: SoJuteYarnHeaders.approvedBy,
        approvedOn: SoJuteYarnHeaders.approvedOn,
        invoiceTypeId: 3,
        salesOrderLineItemDTO: LineItemsForSoJuteYarn,
        shippingTo: SoJuteYarnHeaders.shippingTo,
        statusId: 1,
        isActive: 1,
        updatedBy: "",
        updatedOn: "",
        termsConditionType: SoJuteYarnHeaders.termsConditionType,
        termsCondition: SoJuteYarnHeaders.termsCondition,
        taxType: SoJuteYarnHeaders.taxType,
      },
      yarnSalesId: SoJuteYarnHeaders.yarnSalesId,
      invoiceTypeId: 3,
      brokerCommisionSource: SoJuteYarnHeaders.brokerCommisionSource,
      brokerCommisionPercent: SoJuteYarnHeaders.brokerCommisionPercent,
    };
    dispatch(
      CreateJuteYarnSalesOrder(
        serverApi.CREATE_SO_JUTE_YARN,
        JuteYarnCreatePayload,
        history
      )
    );
  };
  const CreatePayloadForJuteHessian = () => {
    let LineItemsForSoJuteHessian = [];
    let FilteredData = SoJuteHessianLineItems.filter(
      (item) => item.isActive !== 0 && item.itemGroupId !== ""
    );
    if (FilteredData.length !== 0) {
      FilteredData.map((item) => {
        LineItemsForSoJuteHessian.push({
          createdBy: userDetails.userId,
          createdDate: TodayDate.split("T", 2)[0],
          companyId: localStorage.getItem("companyId"),
          hsncode: item.hsncode,
          itemGroupId: item.itemGroupId,
          itemGroupValue: item.itemGroupValue,
          itemId: item.itemId,
          itemMake: item.itemMake,
          quantity: limitDecimals(item.quantity, 3),
          uom: item.uom,
          rate: limitDecimals(item.rate, 3),
          doBalQty: limitDecimals(item.doBalQty, 3),
          invoiceBalQty: limitDecimals(item.invoiceBalQty, 3),
          igstAmount: limitDecimals(item.igstAmount, 2),
          igstPercent: limitDecimals(item.igstPercent, 2),
          cgstAmount: limitDecimals(item.cgstAmount, 2),
          cgstPercent: limitDecimals(item.cgstPercent, 2),
          sgstAmount: limitDecimals(item.sgstAmount, 2),
          sgstPercent: limitDecimals(item.sgstPercent, 2),
          discountType: item.discountType,
          discount: limitDecimals(item.discount, 2),
          discountedRate: limitDecimals(item.discountedRate, 3),
          discountAmount: limitDecimals(item.discountAmount, 3),
          netAmount: limitDecimals(item.netAmount, 2),
          totalAmount: limitDecimals(item.totalAmount, 2),
          remarks: item.remarks,
          hessianRateIncludingBrokerage: limitDecimals(
            item.hessianRateIncludingBrokerage,
            3
          ),
          hessianRateExcludingBrokerage: limitDecimals(
            item.hessianRateExcludingBrokerage,
            3
          ),
          hessianQuantityUom: item.hessianQuantityUom,
          hessianQuantityUomId: item.hessianQuantityUomId,
          hessianQuantityRelationValue: item.hessianQuantityRelationValue,
          hessianQuantityCalculatedValue: limitDecimals(
            item.hessianQuantityCalculatedValue,
            3
          ),
          hessianRateUom: item.hessianRateUom,
          hessianRateUomId: item.hessianRateUomId,
          hessianRateRelationValue: item.hessianRateRelationValue,
          hessianRateCalculatedValue: limitDecimals(
            item.hessianRateCalculatedValue,
            3
          ),
          hessianRemarks: "",
          isActive: item.isActive,
          hessianIsActive:item.hessianIsActive
        });
      });
    }

    const JuteHessianCreatePayload = {
      salesOrderDTO: {
        finYear: localStorage.getItem("acadamicYear"),
        createdBy: userDetails.userId,
        createdDate: TodayDate.split("T", 2)[0],
        salesNo: SoJuteHessianHeaders.salesNo,
        brokerName: SoJuteHessianHeaders.brokerName,
        salesDate: SoJuteHessianHeaders.salesDate,
        companyId: localStorage.getItem("companyId"),
        branchId: SoJuteHessianHeaders.branchId,
        quotationId: SoJuteHessianHeaders.quotationId,
        transporterId: SoJuteHessianHeaders.transporterId,
        customerId: SoJuteHessianHeaders.customerId,
        customerLineItemId: "",
        brokerId:
          SoJuteHessianHeaders.brokerId === 0
            ? ""
            : SoJuteHessianHeaders.brokerId,
        billingTo: SoJuteHessianHeaders.billingTo,
        shippingTo: SoJuteHessianHeaders.shippingTo,
        salesOrderExpiryDate: SoJuteHessianHeaders.salesOrderExpiryDate,
        challanNo: SoJuteHessianHeaders.challanNo,
        challanDate: SoJuteHessianHeaders.challanDate,
        taxType: SoJuteHessianHeaders.taxType,
        brokerCommision: SoJuteHessianHeaders.brokerCommision,
        brokerCommisionPercent: SoJuteHessianHeaders.brokerCommisionPercent,
        footerNote: SoJuteHessianHeaders.footerNote,
        internalNote: SoJuteHessianHeaders.internalNote,
        termsConditionType: SoJuteHessianHeaders.termsConditionType,
        termsCondition: SoJuteHessianHeaders.termsCondition,
        deliveryTerms: SoJuteHessianHeaders.deliveryTerms,
        paymentTerms: SoJuteHessianHeaders.paymentTerms,
        deliveryDays: SoJuteHessianHeaders.deliveryDays,
        freightCharges: SoJuteHessianHeaders.freightCharges,
        amountWithoutTax: SoJuteHessianHeaders.netTotal,
        amountWithTax: SoJuteHessianHeaders.TotalAmount,
        updatedBy: "",
        updatedOn: "",
        approvedBy: SoJuteHessianHeaders.approvedBy,
        approvedOn: SoJuteHessianHeaders.approvedOn,
        statusId: 1,
        invoiceTypeId: 4,
        salesOrderLineItemDTO: LineItemsForSoJuteHessian,
      },
      finYear: localStorage.getItem("acadamicYear"),
      invoiceType: 4,
      brokerCommisionSource: "80",
    };

    dispatch(
      CreateJuteHessianSalesOrder(
        serverApi.CREATE_SO_JUTE_HESSIAN,
        JuteHessianCreatePayload,
        history
      )
    );
  };

  return (
    <>
      <div className="createQuotationContainer">
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            <div className="iconsBlock">
              <Stepper alternativeLabel activeStep={currentStep}>
                {steps.map((prop) => (
                  <Step key={prop.title}>
                    <StepLabel
                      StepIconComponent={(Iconprops) => {
                        return QontoStepIcon(
                          Iconprops,
                          prop.icon,
                          prop.activeIcon,
                          prop.title
                        );
                      }}
                    ></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {currentStep === 1 && (
              <>
                {InvoiceTypeSelection ? (
                  <div>
                    <div className="consoleFormButtonBlock">
                      <h4>What is the type of Invoice?</h4>
                    </div>
                    <div className="consoleFormButtonBlock">
                      <DynamicSelect
                        arrayOfData={invoiceData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handleChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={invoiceType}
                        name="invoiceType"
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {SalesOrderMethod ? (
                  <>
                    <div>
                      <div className="consoleFormButtonBlock">
                        <h4>How do you want to create the Sales order?</h4>
                      </div>
                      <div className="consoleFormButtonBlock">
                        <ToggleButtonGroup
                          value={alignment}
                          exclusive
                          onChange={handleAlignment}
                          aria-label="text alignment"
                          className="btnToggleforIndent"
                        >
                          {quotationMandatory && (
                            <ToggleButton value="1" aria-label="left aligned">
                              <span>With Quotation</span>
                            </ToggleButton>
                          )}
                          <ToggleButton value="2" aria-label="left aligned">
                            <span>Direct Sales Order</span>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Back"}
                      className="greenBorderButton"
                      handleClick={onClickBefore}
                    />
                  </Link>
                  {InvoiceTypeSelection && (
                    <CustomButton
                      label={"Next"}
                      className={
                        invoiceType === "" ? "disabled" : "greenButton"
                      }
                      handleClick={onClickNext}
                      type="sumbit"
                      disabled={invoiceType === "" ? true : false}
                    />
                  )}
                  {SalesOrderMethod && (
                    <CustomButton
                      label={"Next"}
                      className={
                        invoiceType === "" || source === ""
                          ? "disabled"
                          : "greenButton"
                      }
                      handleClick={onClickNext}
                      type="sumbit"
                      disabled={
                        invoiceType === "" || source === "" ? true : false
                      }
                    />
                  )}
                </div>
              </>
            )}

            {currentStep === 2 && (
              <>
                {invoiceType === 2 ? (
                  <JuteGovtFillDetails locationState={locationState} />
                ) : invoiceType === 3 ? (
                  <>
                    <JuteYarnFillDetails locationState={locationState} />
                  </>
                ) : invoiceType === 4 ? (
                  <>
                    <JuteHessianFillDetails
                      source={source}
                      locationState={locationState}
                    />
                  </>
                ) : (
                  <FillDetails source={source} locationState={locationState} />
                )}
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Back"}
                      className="greenBorderButton"
                      handleClick={clickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="greenButton"
                    handleClick={onClickNext}
                    type="sumbit"
                  />
                </div>
              </>
            )}
            {currentStep === 3 && (
              <>
                {invoiceType === 2 ? (
                  <JuteGovtLineItems
                    invoiceType={invoiceType}
                    source={source}
                    locationState={locationState}
                  />
                ) : invoiceType === 3 ? (
                  <>
                    <JuteYarnLineItems
                      source={source}
                      locationState={locationState}
                    />
                  </>
                ) : invoiceType === 4 ? (
                  <>
                    <JuteHessianLineItems
                      source={source}
                      locationState={locationState}
                    />
                  </>
                ) : (
                  <LineItems source={source} locationState={locationState} />
                )}
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Back"}
                      className="greenBorderButton"
                      handleClick={onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="greenButton"
                    handleClick={onClickNext}
                    type="sumbit"
                  />
                </div>
              </>
            )}
            {currentStep === 4 && (
              <>
                {invoiceType === 2 ? (
                  <JuteGovtTransportation locationState={locationState} />
                ) : invoiceType === 3 ? (
                  <>
                    <JuteYarnTransportation locationState={locationState} />
                  </>
                ) : invoiceType === 4 ? (
                  <>
                    <JuteHessianTransportation locationState={locationState} />
                  </>
                ) : (
                  <Transportation locationState={locationState} />
                )}
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Back"}
                      className="greenBorderButton"
                      handleClick={onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="greenButton"
                    handleClick={onClickNext}
                    type="sumbit"
                  />
                </div>
              </>
            )}
            {currentStep === 5 && (
              <>
                {invoiceType === 1 ? (
                  <>
                    <Preview
                      handleCurrentStep={handleCurrentStep}
                      source={source}
                      locationState={locationState}
                    />
                  </>
                ) : invoiceType === 4 ? (
                  <>
                    <JuteHessianPreview
                      handleCurrentStep={handleCurrentStep}
                      locationState={locationState}
                    />
                  </>
                ) : invoiceType === 2 ? (
                  <>
                    <JuteGovtPreview
                      handleCurrentStep={handleCurrentStep}
                      locationState={locationState}
                    />
                  </>
                ) : invoiceType === 3 ? (
                  <>
                    <JuteYarnPreview
                      handleCurrentStep={handleCurrentStep}
                      locationState={locationState}
                    />
                  </>
                ) : (
                  ""
                )}
                <div className="consoleFormButtonBlock">
                  {locationState.state === undefined && (
                    <Link>
                      <CustomButton
                        label={"Back"}
                        className="greenBorderButton"
                        handleClick={onClickBefore}
                      />
                    </Link>
                  )}
                  {locationState.state === undefined ? (
                    <CustomButton
                      label={"Create"}
                      className="greenButton"
                      handleClick={() => {
                        onCreate();
                      }}
                      type="sumbit"
                    />
                  ) : (
                    <>
                      {SoJuteHessianHeaders.invoiceTypeId === 4 &&
                      SoJuteHessianHeaders.UpdateButton === true ? (
                        <CustomButton
                          label={"Update"}
                          className="greenButton"
                          handleClick={() => OnUpdate("")}
                          type="sumbit"
                        />
                      ) : (
                        ""
                      )}
                      {SoJuteGovtHeaders.invoiceTypeId === 2 &&
                      SoJuteGovtHeaders.UpdateButton === true ? (
                        <CustomButton
                          label={"Update"}
                          className="greenButton"
                          handleClick={() => OnUpdate("")}
                          type="sumbit"
                        />
                      ) : (
                        ""
                      )}
                      {SoJuteYarnHeaders.invoiceTypeId === 3 &&
                      SoJuteYarnHeaders.UpdateButton === true ? (
                        <CustomButton
                          label={"Update"}
                          className="greenButton"
                          handleClick={() => OnUpdate("")}
                          type="sumbit"
                        />
                      ) : (
                        ""
                      )}
                      {SalesOrderHeader.invoiceTypeId === 1 &&
                      SalesOrderHeader.UpdateButton === true ? (
                        <CustomButton
                          label={"Update"}
                          className="greenButton"
                          handleClick={() => OnUpdate("")}
                          type="sumbit"
                        />
                      ) : (
                        ""
                      )}
                      {locationState.state !== undefined &&
                      SalesOrderHeader.ApproveButton === true ? (
                        <>
                          <CustomButton
                            label={"Approve"}
                            className="approved"
                            handleClick={() =>
                              onClickApprove(SalesOrderHeader.statusId)
                            }
                            type="sumbit"
                          />
                        </>
                      ) : (
                        ""
                      )}
                      {SoJuteGovtHeaders.ApproveButton === true ? (
                        <CustomButton
                          label={"Approve"}
                          className="approved"
                          handleClick={() =>
                            onClickApprove(SoJuteGovtHeaders.statusId)
                          }
                          type="sumbit"
                        />
                      ) : (
                        ""
                      )}
                      {SoJuteYarnHeaders.ApproveButton === true ? (
                        <CustomButton
                          label={"Approve"}
                          className="approved"
                          handleClick={() =>
                            onClickApprove(SoJuteYarnHeaders.statusId)
                          }
                          type="sumbit"
                        />
                      ) : (
                        ""
                      )}
                      {locationState.state !== undefined &&
                      SoJuteHessianHeaders.ApproveButton === true ? (
                        <CustomButton
                          label={"Approve"}
                          className="approved"
                          handleClick={() =>
                            onClickApprove(SoJuteHessianHeaders.statusId)
                          }
                          type="sumbit"
                        />
                      ) : (
                        ""
                      )}

                      {locationState.state !== undefined &&
                      SalesOrderHeader.RejectButton === true ? (
                        <>
                          <CustomButton
                            label={"Reject"}
                            className="rejected"
                            handleClick={() => onClickReject()}
                            type="sumbit"
                          />
                        </>
                      ) : (
                        ""
                      )}
                      {SoJuteGovtHeaders.RejectButton === true ? (
                        <CustomButton
                          label={"Reject"}
                          className="rejected"
                          handleClick={() => onClickReject()}
                          type="sumbit"
                        />
                      ) : (
                        ""
                      )}
                      {SoJuteYarnHeaders.RejectButton === true ? (
                        <CustomButton
                          label={"Reject"}
                          className="rejected"
                          handleClick={() => onClickReject()}
                          type="sumbit"
                        />
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  {locationState.state !== undefined &&
                  SoJuteHessianHeaders.RejectButton === true ? (
                    <>
                      <CustomButton
                        label={"Reject"}
                        className="rejected"
                        handleClick={() => onClickReject()}
                        type="sumbit"
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CreateSalesOrder;
