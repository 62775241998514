export const JUTE_GATE_ENTRY_LIST = "jute_gate_entry_list";
export const JUTE_GATE_ENTRY_LIST_SUCCESSFULL = "jute_gate_entry_list_successfull";
export const JUTE_GATE_ENTRY_LINE_ITEMS = "jute_gate_entry_line_items";
export const CLEAR_JUTE_GATE_ENTRY_LINE_ITEMS = "clear_jute_gate_entry_line_items";
export const JUTE_GATE_ENTRY_HEADER = "jute_gate_entry_header";
export const CLEAR_JUTE_GATE_ENTRY_HEADER = "clear_jute_gate_entry_header";
export const JUTE_GATE_ENTRY_CREATE = "jute_gate_entry_create";
export const JUTE_GATE_ENTRY_VIEW_BY_ID = "jute_gate_entry_view_by_id";
export const JUTE_GATE_ENTRY_VIEW_BY_ID_SCCESSFULL = "jute_gate_entry_view_by_id_successfull";
export const GET_QUALITY_BY_ITEM = "get_quality_by_item";
export const GET_QUALITY_BY_ITEM_SUCCESSFULL = "get_quality_by_item_sucessfull";
export const SET_ENTRY_HEADER_LINEITEMS = "set_entry_header_lineitems";
export const GET_ALL_APPROVED_PO="get_all_approved_po";
export const GET_ALL_APPROVED_PO_SUCCESSFULL="get_all_approved_po_sucessfull";
export const JUTE_PO_VIEW_BY_ID_FOR_GATE_ENTRY = "jute_po_view_by_id_for_gate_entry";
export const JUTE_PO_VIEW_BY_ID_FOR_GATE_ENTRY_SCCESSFULL = "jute_po_view_by_id_for_gate_entry_successfull";

export const JUTE_GATE_ENTRY_UPLOAD_FILE = "jute_gate_entry_upload_file";
export const JUTE_GATE_ENTRY_UPLOAD_FILE_SUCCESSFULL = "jute_gate_entry_upload_file_successfull";

export const JUTE_GATE_ENTRY_GET_ALL_FILES= "jute_gate_entry_get_all_files";
export const JUTE_GATE_ENTRY_GET_ALL_FILES_SUCCESSFULL= "jute_gate_entry_get_all_files_successfull";

export const JUTE_GATE_ENTRY_DELETE_FILE = "jute_gate_entry_delete_file";
export const JUTE_GATE_ENTRY_DELETE_FILE_SUCCESSFULL = "jute_gate_entry_delete_file_successfull";