import React, { useState, useEffect } from "react";
import { serverApi, serverVars ,serverConfig} from "../../../helpers/Consts";
import DynamicSelect from "../../../Pages/StoreModule/MaterialRequest/DynamicSelect";
import { getMukamByItemList } from "../../../store/MasterDropdownApis/actions";
import {
  LineItemsGateEntryPros,
  getQualityByItem,
  JuteGateEntryUploadFiles,
  JuteGateEntryGetAllFiles,
  JuteGateEntryDeleteFiles,
} from "../../../store/Jute/JuteGateEntry/actions";
import DeleteIcons from "../../../assets/images/picEditIcon.png";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import { getFileDownload } from "../../../helpers/server";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { Grid, Box, IconButton, Button } from "@mui/material";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import swal from "sweetalert";

const JuteGateEntryLineItems = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [locationState] = useState(useLocation());
  const [LineItems, setLineItems] = useState([]);
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [LineItemsDisplayTxtField, setLineItemsDisplayTxtField] = useState({});
  const [LineItemsDisplayDropField, setLineItemsDisplayDropField] = useState(
    {}
  );
  const [userDit, setuserDit] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );
  const [mukamList, setmukamList] = useState([]);
  const [qualitybyItemData, setqualitybyItemData] = useState([]);
  const [HeaderData, setHeaderData] = useState({});
  const [fileList, setfileList] = useState([]);

  const { MukamByItemList } = useSelector(
    (state) => state.MasterDropDownListReducer
  );

  const { JuteGateEntrylineItems, JuteGateEntryHeader, JuteGateEntryFiles,JuteQualityByItem } =
    useSelector((state) => state.JuteGateEntryReducer);
  useEffect(() => {
    setLineItems(JuteGateEntrylineItems);
    setHeaderData(JuteGateEntryHeader);
  }, [JuteGateEntrylineItems, JuteGateEntryHeader]);

  useEffect(() => {
    if (JuteGateEntryFiles) {
      if (JuteGateEntryFiles.data) {
        setfileList(JuteGateEntryFiles.data);
      }
    }
  }, [JuteGateEntryFiles]);
  const OnhandleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    let mname = key.split("_");
    if (mname[0] === "actualQuantity") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            rowItem.actualQuantity = value;
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(LineItemsGateEntryPros(LineItemsRec));
    }
    if (mname[0] == "actualWeight") {
      let LineItemsRec = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          if (value.match(/^(\d*\.{0,1}\d{0,3}$)/)) {
            console.log(
              "asdfvvgdshfd",
              typeof value,
              typeof HeaderData.challanWeightQtl
            );
            if (Number(value) <= Number(HeaderData.challanWeightQtl)) {
              rowItem.actualWeight = value;
            } else {
              swal("Weight should not exceed Challan Weight");
              rowItem.actualWeight = "";
            }
          } else {
            swal("Please enter a positive number with up to 3 decimal places.");
          }
        }
        return rowItem;
      });
      setLineItems(LineItemsRec);
      dispatch(LineItemsGateEntryPros(LineItemsRec));
    }
  };
  const onDel = (row) => {
    var FilterLineItems = LineItems.filter((item) => item.isActive !== 0);
    if (FilterLineItems.length == 1) {
      swal("you can't delete the last lineitem");
    } else {
      let indexItem = LineItems.findIndex((item) => item === row);
      let lineItems = [...LineItems];
      lineItems[indexItem].isActive = 0;
      setLineItems(lineItems);
      dispatch(LineItemsGateEntryPros(lineItems));
    }
  };

  const displayField = (row, colname, type) => {
    if (colname === "itemId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type == 1) {
        dispatch(
          getMukamByItemList(
            serverApi.GET_JUTE_TYPE +
              JuteGateEntryHeader.mukam +
              "/getAllItemByMukam/" +
              localStorage.getItem("companyId") +
              "/" +
              userDit.cipher,
            history
          )
        );
      }
    } else if (colname === "qualityCode") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if (type == 1) {
        dispatch(
          getQualityByItem(
            serverVars.PO_API +
              "api/v1/" +
              row.itemId +
              "/" +
              localStorage.getItem("companyId") +
              "/getQualityByItem",
            row.id
          )
        );
      }
    } else if (colname === "cropYear") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
    }
  };

  useEffect(() => {
    dispatch(
      getMukamByItemList(
        serverApi.GET_JUTE_TYPE +
          JuteGateEntryHeader.mukam +
          "/getAllItemByMukam/" +
          localStorage.getItem("companyId") +
          "/" +
          userDit.cipher,
        history
      )
    );

    dispatch(
      JuteGateEntryGetAllFiles(
        serverApi.GET_ALL_FILES +
          "20/" +
          JuteGateEntryHeader.id +
          "/" +
          localStorage.getItem("companyId")
      )
    );
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (MukamByItemList.data) {
      if (MukamByItemList.data.length !== 0) {
        let ListFilter = MukamByItemList.data.filter((item) => {
          if (item.value != 0) {
            return item;
          }
        });
        let list = [];
        ListFilter.map((prop) => {
          list.push({
            label: prop.label,
            name: prop.name,
            value: prop.value,
            variant: prop.name.split("^")[1],
          });
        });
        setmukamList(list);
      }
    };

    if (JuteQualityByItem.data) {
      if (JuteQualityByItem.data.length !== 0) {
        let ListFilter = JuteQualityByItem.data.filter((item) => {
          if (item.value != 0) {
            return item;
          }
        });
        let list = [];
        ListFilter.map((prop) => {
          list.push({
            label: prop.label,
            name: prop.name,
            value: prop.value,
            variant: prop.name.split("^")[1],
          });
        });
        setqualitybyItemData(list);
      }
    };



  }, [MukamByItemList,JuteQualityByItem]);

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };

  const handleSelectChange = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    selectedItemCode
  ) => {
    var lastRow = JuteGateEntrylineItems[JuteGateEntrylineItems.length - 1];
    if (name === "itemId") {
      if (selectedRow === lastRow) {
        let LineItem = LineItems;
        let Obj = {
          id: LineItems.length + 1,
          recId: "",
          hdrId: "",
          quantity: "",
          advisedJuteTyp: "",
          actualJuteTyp: "",
          advisedQuality: "",
          actualQuality: "",
          advisedQuantity: "",
          actualQuantity: "",
          receivedIn: "",
          autoDateTime: "",
          unitId: "",
          remarks: null,
          kgs: null,
          poLineItemNum: "",
          createdBy: "",
          isActive: "",
          qcJuteType: "",
          qcJuteQuality: "",
          qcJuteQuantity: "",
          advisedWeight: null,
          actualWeight: "",
          qcJuteWeight: "",
          allowableMoisture: null,
          readings: [],
          actualQualityDesc: "",
          advisedQualityDesc: "",
          itemDesc: "",
          itemId: "",
          qualityCode: "",
          qualityCodeName: "",
          itemCode:"",
          advisedItemDesc: "",
          avgReadings: "",
          qcJuteTypeDesc: "",
          qcJuteQualityDesc: "",
          unitId: "",
          QuantityListData: [],
        };
        LineItem.push(Obj);
        setLineItems(LineItem);
        dispatch(LineItemsGateEntryPros(LineItem));
      }
    }
    if (name === "itemId") {
      let LineItemdata = LineItems.filter((row) => {
        var selectedname = selectedName.split("^");
        if (row.id === selectedRow.id) {
          row.itemId = selectedValue;
          row.itemDesc = selectedname[0];
          row.itemGroupId = selectedItemCode;
          row.unitId = selectedname[2];
          row.hsnCode = selectedname[4];
          row.qualityCode = "";
          row.qualityCodeName = "";
          row.stock = "";
          row.rate = "";
        }
        return row;
      });
      setLineItems(LineItemdata);
      dispatch(LineItemsGateEntryPros(LineItemdata));
      displayField(selectedRow, "itemId", 2);
      dispatch(
        getQualityByItem(
          serverVars.PO_API +
            "api/v1/" +
            selectedValue +
            "/" +
            localStorage.getItem("companyId") +
            "/getQualityByItem",
          selectedRow.id
        )
      );
    }
    if (name === "qualityCode") {
      let filteredLineItems = LineItems.filter((item) => item.isActive !== 0);

      if (selectedRow.itemId !== selectedValue) {
        var checkItem = !!filteredLineItems.find(
          ({ qualityCode }) => Number(qualityCode) === Number(selectedValue)
        );
      }
      if (checkItem) {
        swal("You can't create record with duplicate Quality/Grade");
        let LineItemdata = LineItems.filter((row) => {
          if (row.id === selectedRow.id) {
            row.qualityCode = "";
            row.qualityCodeName = "";
            row.stock = "";
            row.rate = "";
          }
          return row;
        });
        setLineItems(LineItemdata);
        dispatch(LineItemsGateEntryPros(LineItemdata));
        displayField(selectedRow, "qualityCode", 2);
      } else {
        let LineItemdata = LineItems.filter((row) => {
          if (row.id === selectedRow.id) {
            let selectedNameData = selectedName.split("^");
            row.qualityCode = selectedValue;
            row.qualityCodeName = selectedNameData[0];
            row.itemCode = row.itemGroupId + selectedValue;
            row.stock = selectedNameData[1];
            row.rate = selectedNameData[2];
          }
          return row;
        });
        setLineItems(LineItemdata);
        dispatch(LineItemsGateEntryPros(LineItemdata));
        displayField(selectedRow, "qualityCode", 2);
      }
    }
  };

  const UploadDocuments = (key) => (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 20);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", HeaderData.id);
    formData.append("createdBy", userDit.userId);
    formData.append("fileCompanyId", localStorage.getItem("companyId"));
    dispatch(
      JuteGateEntryUploadFiles(serverApi.UPLOADFILE, formData, HeaderData.id)
    );
  };
  const onDeleteFile = (id) => {
    dispatch(
      JuteGateEntryDeleteFiles(serverApi.DELETEFILE + id, HeaderData.id)
    );
  };

  let filterProduct = LineItems.filter((item) => item.isActive !== 0);
  let lastLineItem = LineItems[LineItems.length - 1];
  return (
    <div className="editableTableContainer">
      <div className="gridContainer">
        <div className="gridComponent" onScroll={handleScroll}>
          <table cellSpacing="0px" cellPadding="0px">
            <thead>
              <tr className="tableRowBlock">
                {tableHeaders.map((column) => (
                  <>
                    <th>
                      {column.header}
                      <label style={{ color: "red" }}>*</label>
                    </th>
                  </>
                ))}
              </tr>
            </thead>
            <tbody>
              {filterProduct.length !== 0 &&
                filterProduct.map((row, i) => {
                  let rowIndex = filterProduct.findIndex((idd) => idd == row);

                  return (
                    <>
                      <tr>
                        <>
                          {RowData &&
                            RowData.map((col) =>
                              col.component === "DynamicSelect" ? (
                                <td>
                                  <div className="quantityDropdownBlock">
                                    <Grid className="selectAndTextfield">
                                      <Grid
                                        xs={12}
                                        md={12}
                                        className="dynamicSelectBlock"
                                        style={{
                                          display: LineItemsDisplayTxtField[
                                            col.type + row.id + "1"
                                          ]
                                            ? "none"
                                            : "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Tooltip
                                          TransitionComponent={Fade}
                                          TransitionProps={{ timeout: 600 }}
                                        >
                                          <input
                                            type="text"
                                            className="inputBlockContainer"
                                            value={
                                              col.type === "itemId"
                                                ? row.itemDesc
                                                : col.type === "qualityCode"
                                                ? row.qualityCodeName
                                                : ""
                                            }
                                            onChange={OnhandleChange({
                                              key: col.type + "_" + rowIndex,
                                              row,
                                            })}
                                            onClick={() => {
                                              displayField(row, col.type, 1);
                                            }}
                                            onWheel={(e) => e.target.blur()}
                                          />
                                        </Tooltip>
                                      </Grid>
                                      <Grid
                                        xs={12}
                                        md={12}
                                        className="dynamicSelectBlock"
                                        style={{
                                          display: LineItemsDisplayDropField[
                                            col.type + row.id + "2"
                                          ]
                                            ? "flex"
                                            : "none",
                                          alignItems: "center",
                                        }}
                                      >
                                        <DynamicSelect
                                          arrayOfData={
                                            col.type === "itemId"
                                              ? mukamList
                                              : col.type === "qualityCode"
                                              ? qualitybyItemData
                                              : ""
                                          }
                                          className="dropdownBlockContainer"
                                          onSelectChange={handleSelectChange}
                                          selected={
                                            col.type === "itemId"
                                              ? row.itemId
                                              : col.type === "qualityCode"
                                              ? row.qualityCode
                                              : ""
                                          }
                                          name={
                                            col.type === "itemId"
                                              ? "itemId"
                                              : col.type === "qualityCode"
                                              ? "qualityCode"
                                              : ""
                                          }
                                          row={row}
                                          update={
                                            row.itemId || row.qualityCode
                                              ? 1
                                              : 0
                                          }
                                          isDropdownOpen={isDropdownOpen}
                                          handleMenuOpen={handleMenuOpen}
                                        />
                                      </Grid>
                                    </Grid>
                                  </div>
                                </td>
                              ) : (
                                <td>
                                  <div className="verificationContainer">
                                    <input
                                      type="number"
                                      className="inputBlockContainer"
                                      onWheel={(e) => e.target.blur()}
                                      value={
                                        col.type === "actualQuantity"
                                          ? row.actualQuantity
                                          : col.type === "actualWeight"
                                          ? row.actualWeight
                                          : ""
                                      }
                                      onChange={OnhandleChange({
                                        key: col.type + "_" + rowIndex,
                                        row,
                                      })}
                                      name={
                                        col.type === "actualQuantity"
                                          ? row.actualQuantity
                                          : col.type === "actualWeight"
                                          ? row.actualWeight
                                          : ""
                                      }
                                      style={{
                                        textAlign: "right",
                                        paddingRight: "8px",
                                      }}
                                    />

                                    <button className="verifiedBlock">
                                      {col.type === "actualQuantity"
                                        ? HeaderData.unitConversion == "BALE"
                                          ? "BALE"
                                          : "DRUM"
                                        : col.type === "actualWeight"
                                        ? "QTL"
                                        : ""}
                                    </button>
                                  </div>
                                </td>
                              )
                            )}
                        </>
                        <td>
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title="Delete"
                          >
                            <IconButton
                              onClick={() => {
                                onDel(row, i);
                              }}
                              disabled={
                                filterProduct.length === 1
                                  ? true
                                  : lastLineItem === row
                                  ? true
                                  : false
                              }
                            >
                              <DeleteIcon className="deleteDisplayButton" />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
          {locationState.state ? (
            <>
              <div
                className="cloumnButtonBlock"
                style={{ marginTop: "10px" }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 12">
                        <div>Support Documents:</div>
                        <div className="personalDetailsUpload">
                          <div className="ducumentUploadBlock">
                            <div className="documentUploadContent">
                              <span className="btn_upload documentUpload">
                                <img src={panUploadIcon} alt="" />
                                <input
                                  type="file"
                                  id="imag"
                                  title=""
                                  className="input-img"
                                  onChange={UploadDocuments()}
                                />
                                Upload Document
                              </span>
                            </div>
                          </div>
                        </div>
                      </Box>
                      
                    </Box>
              
                  </Grid>
                </Grid>
              </div>
              <div
                className="cloumnButtonBlock"
              >
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                      className="ducumentUploadBlock"
                  
                    >
                      <Box gridColumn="span 6">
                        {fileList &&
                          fileList.map((item) => (
                            <>
                              <div>
                                <div
                                  className="uploadedInfo"
                                  style={{ display: "flex" ,marginTop:"10px"}}
                                >
                                  <img
                                    src={TickIcon}
                                    alt=""
                                    className="tickIcon"
                                    height={"20px"}
                                    width={"20px"}

                                  />
                                  <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    title="Click to download File"
                                  >
                                    <div
                                      onClick={() => {
                                        getFileDownload(
                                          serverConfig.SERVER_URL +
                                          "security-api/api/files/downloadfile/" +
                                          item.fileUploadId,
                                          item.fileName,
                                          item.fileExtension
                                        );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {item.fileName}
                                    </div>
                                  </Tooltip>

                                  <Tooltip
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 100 }}
                                    title="Click to Delete File"
                                  >
                                    <div
                                      onClick={() => {
                                        onDeleteFile(item.fileUploadId);
                                      }}
                                      className="deleteButton"
                                    >
                                      <img
                                        src={DeleteIcons}
                                        alt=""
                                        height={"20px"}
                                        width={"20px"}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>
                            </>
                          ))}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </>
          ) : (
            <>
              <div></div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "itemId",
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "qualityCode",
  },
  {
    no: 3,
    component: "InputAdornment",
    type: "actualQuantity",
  },
  {
    no: 4,
    component: "InputAdornment",
    type: "actualWeight",
  },
];
const tableHeaders = [
  {
    header: "Item",
  },
  {
    header: "Quality",
  },
  {
    header: "Quantity",
  },
  {
    header: "Weight",
  },
  {
    header: "Actions",
  },
];
export default JuteGateEntryLineItems;
