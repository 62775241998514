import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import { getPaymentList } from "../../../store/Accounting/Payments/actions";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      paymentListData: [],
      financialYear: localStorage.getItem("state"),
      total: "",
      closing: "",
      fundsout: "",
    };
  }

  componentDidMount() {
    const data = {
      acYear: "2022",
      companyId: this.state.companyId,
      fromDate: "06-12-2021",
      ledgerId: "",
      suppType: "",
      toDate: "06-12-2021",
      tranStatus: "",
      tranType: "",
    };
    this.props.getPaymentList(serverApi.PAYMENT, data, this.props.history);
  }

  componentWillReceiveProps(props) {
    if (props.PaymentList) {
      this.setState({
        paymentListData: props.PaymentList.data,
      });
    }
    if (props.PaymentList.data) {
      let totalFundsOut = 0,
        totalFundsIn = 0,
        data = props.PaymentList.data;

      if (data.length > 0) {
        data.map((prop) => {
          totalFundsIn = totalFundsIn + parseFloat(prop.fundsIn);
          totalFundsOut = totalFundsOut + parseFloat(prop.fundsOut);
        });
      }

      var closing = totalFundsIn - totalFundsOut;

      this.setState({
        total: totalFundsIn,
        closing: closing,
        fundsout: totalFundsOut,
      });
    }
  }

  handleSearch = (searchData) => {
    const data = {
      acYear: "2022",
      companyId: this.state.companyId,
      fromDate: searchData.fromDate,
      ledgerId: searchData.ledgerId,
      suppType: "",
      toDate: searchData.toDate,
      tranStatus: "",
      tranType: "",
    };
    this.props.getPaymentList(serverApi.PAYMENT, data, this.props.history);
  };

  render() {
    return (
      <>
        <div className="ledgerWiseTable">
          <Gridwithcustomview
            mdata={this.state.paymentListData}
            mcolumns={Columns}
            micon={GridArrowIcon}
            filter_form={"LedgerWiseTransaction"}
            handleSearch={this.handleSearch}
            status={true}
            actions={false}
            pagination={1}
            url=""
          />
        </div>
        {this.props.PaymentList.data ? (
          this.props.PaymentList.data.length > 1 ? (
            <div
              className="gridContainer ledgerWiseTableCalculation"
              style={{ zIndex: "-1" }}
            >
              <div className="gridComponentNoBorder">
                <table className="table">
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "left", width: "20%" }}>TOTAL</td>
                      <td style={{ textAlign: "left", width: "20%" }}>
                        &nbsp;
                      </td>
                      <td style={{ textAlign: "left", width: "20%" }}>
                        &nbsp;
                      </td>
                      <td style={{ textAlign: "left", width: "20%" }}>
                        {this.state.total}
                      </td>
                      <td style={{ textAlign: "left", width: "20%" }}>
                        {this.state.fundsout}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left", width: "20%" }}>
                        CLOSING BALANCE
                      </td>
                      <td style={{ textAlign: "left", width: "20%" }}>
                        &nbsp;
                      </td>
                      <td style={{ textAlign: "left", width: "20%" }}>
                        &nbsp;
                      </td>
                      <td style={{ textAlign: "left", width: "20%" }}>
                        &nbsp;
                      </td>
                      <td style={{ textAlign: "left", width: "20%" }}>
                        {this.state.closing}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </>
    );
  }
}
const Columns = [
  {
    Header: "TNR No",
    accessor: "transaUniqNo",
    hidden: true,
  },
  {
    Header: "Date",
    accessor: "tranDate",
    hidden: false,
    width: "20%",
  },
  {
    Header: "Ledger",
    accessor: "ledgerName",
    hidden: false,
    width: "20%",
  },
  {
    Header: "Ledger Id",
    accessor: "ledgerId",
    hidden: true,
  },
  {
    Header: "Account",
    accessor: "accountName",
    hidden: true,
  },
  {
    Header: "Bank Id",
    accessor: "bankId",
    hidden: true,
  },
  {
    Header: "Description",
    accessor: "description",
    hidden: false,
    width: "20%",
  },
  {
    Header: "Bill No",
    accessor: "billNo",
    hidden: true,
  },
  {
    Header: "Funds In",
    accessor: "fundsIn",
    hidden: false,
    width: "20%",
  },
  {
    Header: "Funds Out",
    accessor: "fundsOut",
    hidden: false,
    width: "20%",
  },
  {
    Header: "Due Amount",
    accessor: "dueAmount",
    hidden: true,
  },

  {
    Header: "Status",
    accessor: "status",
    hidden: true,
  },
  {
    Header: "Invoice No",
    accessor: "invoiceNo",
    hidden: true,
  },
  {
    Header: "Paid Amount",
    accessor: "paidAmount",
    hidden: true,
  },
  {
    Header: "Received Amount",
    accessor: "receivedAmount",
    hidden: true,
  },
  {
    Header: "Message",
    accessor: "message",
    hidden: true,
  },
  {
    Header: "Tally Sync",
    accessor: "tallySync",
    hidden: true,
  },
  {
    Header: "Tally Message",
    accessor: "tallyMessage",
    hidden: true,
  },
  {
    Header: "Transaction Id",
    accessor: "transactionId",
    hidden: true,
  },
  {
    Header: "Recept No",
    accessor: "rcptNo",
    hidden: true,
  },
];

const mapStatetoProps = (state) => {
  const { PaymentList } = state.PaymentReducer;
  return { PaymentList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getPaymentList,
  })(Index)
);
