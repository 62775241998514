import {
  SPINNING_QUALITY_LIST,
  CREATE_SPINNING_QUALITY,
  UPDATE_SPINNING_QUALITY,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getList, postAdd } from "../../../helpers/Server_Helper";
import {
  spinningQualityListSuccessfull,
  createSpinningQualitySuccessfull,
  updateSpinningQualitySuccessfull,
} from "./actions";
import swal from "sweetalert";

function* SpinningQualityList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(spinningQualityListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateSpinningQuality({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createSpinningQualitySuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/spinning_quality_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}
function* UpdateSpinningQuality({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateSpinningQualitySuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/spinning_quality_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
        console.log(error);
      }
    }
  }
}

export function* watchSpinningQualityList() {
  yield takeEvery(SPINNING_QUALITY_LIST, SpinningQualityList);
}
export function* watchCreateSpinningQuality() {
  yield takeEvery(CREATE_SPINNING_QUALITY, CreateSpinningQuality);
}
export function* watchUpdateSpinningQuality() {
  yield takeEvery(UPDATE_SPINNING_QUALITY, UpdateSpinningQuality);
}

function* SpinningQualitySaga() {
  yield all([
    fork(watchSpinningQualityList),
    fork(watchCreateSpinningQuality),
    fork(watchUpdateSpinningQuality),
  ]);
}

export default SpinningQualitySaga;
