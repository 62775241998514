import { JUTE_ISSUE_LIST } from './actionTypes';
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getList} from '../../../helpers/Server_Helper';
import { JuteIssueSuccessfull} from './actions';

function* JuteIssueListSagaList({ payload: { url, data, history } }) {
    try {
        const response = yield call(getList, url, data);
        yield put(JuteIssueSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}

export function* watchJuteIssueList() {
    yield takeEvery(JUTE_ISSUE_LIST, JuteIssueListSagaList)
}

function* JuteIssueSaga() {
    yield all([
        fork(watchJuteIssueList)
    ])
}

export default JuteIssueSaga;