import React, { Component } from "react";
export default class StockTransferPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address1: "",
      address2: "",
      companyName: "",
      comName: "",
      phoneNo: "",
      email: "",
      products: this.props.products,
      expense_name: this.props.header.expense_name,
      currentDate: new Date().toLocaleString(),
    };
  }
  render() {
    var companyData = JSON.parse(localStorage.getItem("authUser"));
    var companyId = localStorage.getItem("companyId");
    var expanseName = this.state.expense_name;
    var expName = expanseName.split("_");
    companyData.companyDetails.map((p) => {
      var id = p.value;
      if (companyId === id.toString()) {
        this.state.companyName = p.label;
        this.state.address1 = p.address1;
        this.state.address2 = p.address2;
        this.state.comName = p.name;
        this.state.phoneNo = p.contactNo;
        this.state.email = p.emailId;
      }
    });

    var filterData = this.state.products.filter((item) => {
      if (item.deptId !== 0) {
        return item;
      }
    });

    return (
      <>
        <div className="previewBlock">
          <table
            cellpadding="0"
            cellspacing="0"
            width="90%"
            className="previewTable"
          >
            <tbody>
              <tr>
                <td
                  colspan="2"
                  style={{ borderRight: "none", borderBottom: "none" }}
                >
                  this will be company logo
                </td>
                <td
                  colspan="7"
                  style={{
                    borderBottom: "none",
                    borderLeft: "none",
                    textAlign: "right",
                  }}
                >
                  <h2>{this.state.companyName}</h2>
                  {this.state.address1}
                  <br></br>
                  {this.state.address2}
                  <br></br>
                  Phone No:- {this.state.phoneNo}
                  <br></br>
                  Email Address:- {this.state.email}
                  <br></br>
                </td>
              </tr>
              <tr>
                <td
                  colspan="9"
                  style={{ textAlign: "center", borderTop: "none" }}
                >
                  <h2>STOCK TRANSFER</h2>
                </td>
              </tr>
              <tr>
                <td colspan="5">
                  <b>ISSUE NO : </b>
                </td>

                <td colSpan="4">
                  <b>ISSUE DATE : {this.props.header.date}</b>
                </td>
              </tr>
              <tr>
                <td colspan="9">
                  <b>Expense Type: {" " + expName[0]}</b>
                </td>
              </tr>

              <tr>
                <th width="5%">ITEM CODE</th>
                <th width="15%">DEPARTMENT</th>
                <th width="10%">ITEM GROUP</th>
                <th width="15%">ITEM DESCRIPTION </th>
                <th width="15%">WAREHOUSE NO</th>
                <th width="10%">QUANTITY</th>
                <th width="10%">STOCK</th>
                <th width="10%">UNIT</th>
                <th width="10%">Price</th>
              </tr>
              {filterData.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{data.itemGroupId + data.itemCode}</td>
                    <td>{data.departmentName}</td>
                    <td>{data.itemGroupName}</td>
                    <td>{data.itemName}</td>
                    <td>{data.wareHouseNo}</td>
                    <td>{data.quantity}</td>
                    <td>{data.srStock}</td>
                    <td>{data.uom}</td>
                    <td>{data.issueValue}</td>
                  </tr>
                );
              })}

              <tr>
                <td
                  colspan="9"
                  align="right"
                  style={{ borderTop: "none", borderBottom: "none" }}
                >
                  <h4> For {this.state.companyName}</h4>
                </td>
              </tr>

              <tr>
                <td
                  colspan="9"
                  align="right"
                  style={{ borderTop: "none", borderBottom: "none" }}
                >
                  Authorised Signature
                </td>
              </tr>
              <tr>
                <td
                  colspan="9"
                  style={{ borderTop: "none", borderBottom: "none" }}
                >
                  <b>Note* :</b>
                  <br />
                  This is a computer generated print, Signature is not required.
                </td>
              </tr>
              <tr>
                <td
                  colspan="5"
                  style={{ borderTop: "none", borderRight: "none" }}
                >
                  Printed on: {this.state.currentDate}
                </td>
                <td
                  colspan="4"
                  align="right"
                  style={{ borderTop: "none", borderLeft: "none" }}
                >
                  Page 1 of 2<br />
                  Printed By: 2
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
