import cons from '../../../helpers/Consts';
import {DELIVERY_ORDER_LIST,DELIVERY_ORDER_LIST_SUCCESSFULL,API_ERRORS,
     REGULAR_DELIVERY_ORDER_HEADER,
      GET_SO_DROPDOWN, GET_SO_DROPDOWN_SUCCESSFULL, REGULAR_DELIVERY_ORDER_LINE_ITEMS, CLEAR_REGULAR_DELIVERY_ORDER_HEADER, CLEAR_REGULAR_DELIVERY_ORDER_LINE_ITEMS, SALES_ORDER_VIEW_BY_ID, SALES_ORDER_VIEW_BY_ID_SUCCESSFULL, CREATE_DELIVERY_ORDER, CREATE_DELIVERY_ORDER_SUCCESSFULL, GET_ALL_TRANSPORTER, GET_ALL_TRANSPORTER_SUCCESSFULL, REGULAR_DELIVERY_ORDER_VIEWBY_ID, REGULAR_DELIVERY_ORDER_VIEWBY_ID_SUCCESSFULL, UPDATE_REGULAR_DELIVERY_ORDER, UPDATE_REGULAR_DELIVERY_ORDER_SUCCESSFULL, CREATE_JUTEHESSIAN_DELIVERY_ORDER, CREATE_JUTEHESSIAN_DELIVERY_ORDER_SUCCESSFULL, DELIVERY_ORDER_JUTEHESSIAN_HEADERS, DELIVERY_ORDER_JUTEHESSIAN_LINE_ITEMS, DELIVERY_ORDER_JUTEHESSIAN_BY_ID, DELIVERY_ORDER_JUTEHESSIAN_BY_ID_SUCCESSFULL, UPDATE_JUTEHESSIAN_DELIVERY_ORDER, UPDATE_JUTEHESSIAN_DELIVERY_ORDER_SUCCESSFULL, SALES_ORDER_VIEW_BY_ID_FOR_JUTE_HESSIAN, SALES_ORDER_VIEW_BY_ID_FOR_JUTE_HESSIAN_SUCCESSFULL, CLEAR_DELIVERY_ORDER_JUTEHESSIAN_HEADERS, CLEAR_DELIVERY_ORDER_JUTEHESSIAN_LINE_ITEMS,
      JUTEGOVT_DELIVERY_ORDER_HEADER, JUTEGOVT_DELIVERY_ORDER_LINEITEMS, CLEAR_JUTEGOVT_DELIVERY_ORDER_HEADER, CLEAR_JUTEGOVT_DELIVERY_ORDER_LINEITEMS, CREATE_JUTE_GOVT_DELIVERY_ORDER, CREATE_JUTE_GOVT_DELIVERY_ORDER_SUCCESSFULL, UPDATE_JUTE_GOVT_DELIVERY_ORDER, UPDATE_JUTE_GOVT_DELIVERY_ORDER_SUCCESSFULL, JUTE_GOVT_DELIVERY_ORDER_VIEWBY_ID, JUTE_GOVT_DELIVERY_ORDER_VIEWBY_ID_SUCCESSFULL, SALES_ORDER_VIEW_BY_ID_FOR_JUTE_GOVT, SALES_ORDER_VIEW_BY_ID_FOR_JUTE_GOVT_SUCCESSFULL, JUTE_GOVT_DELIVERY_ORDER_OTHER_CHARGES, JUTE_GOVT_DELIVERY_ORDER_OTHER_CHARGES_SUCCESSFULL,
      JUTE_YARN_DELIVERY_ORDER_HEADER,JUTE_YARN_DELIVERY_ORDER_LINE_ITEMS,CLEAR_JUTE_YARN_DELIVERY_ORDER_HEADER,CLEAR_JUTE_YARN_DELIVERY_ORDER_LINE_ITEMS,
      JUTE_YARN_DELIVERY_ORDER_VIEW_BY_ID,JUTE_YARN_DELIVERY_ORDER_VIEW_BY_ID_SUCCESSFULL,UPDATE_JUTE_YARN_DELIVERY_ORDER,UPDATE_JUTE_YARN_DELIVERY_ORDER_SUCCESSFULL,CREATE_JUTE_YARN_DELIVERY_ORDER,
      SALES_ORDER_VIEW_BY_ID_FOR_JUTE_YARN,SALES_ORDER_VIEW_BY_ID_FOR_JUTE_YARN_SUCCESSFULL,
      GET_QUOTATION_RECORDS,GET_QUOTATION_VIEW_BY_ID_FOR_JUTE_YARN,CREATE_JUTE_YARN_DELIVERY_ORDER_SUCCESSFULL, DELIVERY_ORDER_ITEMS_BY_ITEM_GROUP_ID, DELIVERY_ORDER_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL,JUTE_YARN_ITEMS_BY_ITEM_GROUP_ID,JUTE_YARN_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL, JUTE_GOVT_DELORDER_ITEMS_BY_ITEM_GROUP_ID, JUTE_GOVT_DELORDER_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL,
      JUTE_YARN_DELIVERY_ORDER_HEADER_SUCCESSFULL,CLEAR_DELIVERY_ORDER_LIST,  ACCOUNT_LIST,ACCOUNT_LIST_SUCCESSFULL,UPLOAD_DO_FILES,UPLOAD_DO_FILES_SUCCESSFULL,GET_DO_FILES,DO_FILES_SUCCESSFULL,CLEAR_ALL_DO_FILES,DELETE_DO_FILE,DELETE_DO_FILE_SUCCESSFULL,
    } from './actionTypes';




export const getDeliveryOrderList = (url,data,history) =>{
    return {
        type:DELIVERY_ORDER_LIST,
        payload:{url,data,history}
    }
}

export const DeliveryOrderListSuccessfull = (data)=>{
    return {
        type:DELIVERY_ORDER_LIST_SUCCESSFULL,
        payload:data
    }
}
export const ClearDeliveryOrderList = (url,history) =>{
  return {
      type:CLEAR_DELIVERY_ORDER_LIST,
      payload:{url,history}
  }
}

export const getRegularDeliveryOrderHeader =(data) =>{
    return {
        type:REGULAR_DELIVERY_ORDER_HEADER,
        payload:data
    }
}
export const getRegularDeliveryOrderLineItems =(data) =>{
    return {
        type:REGULAR_DELIVERY_ORDER_LINE_ITEMS,
        payload:data
    }
}
export const clearRegularDeliveryOrderLineItems =(data) =>{
    return {
        type:CLEAR_REGULAR_DELIVERY_ORDER_LINE_ITEMS,
        payload:data
    }
}
export const getBankAccountList = (url, data, history) => {
  return {
    type: ACCOUNT_LIST,
    payload: { url, data, history },
  };
};
export const getBankAccountListSuccessfull = (data) => {
  return {
    type: ACCOUNT_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getSoDropDown = (url,history) =>{
    return {
        type:GET_SO_DROPDOWN,
        payload:{url,history}
    }
}

export const getSoDropDownSuccessfull = (data)=>{
    return {
        type:GET_SO_DROPDOWN_SUCCESSFULL,
        payload:data
    }
}
export const clearRegularDeliveryOrderHeader =(data) =>{
    return {
        type:CLEAR_REGULAR_DELIVERY_ORDER_HEADER,
        payload:data
    }
}
export const SalesOrderViewById = (url,history) =>{
    return {
        type: SALES_ORDER_VIEW_BY_ID,
        payload:{url,history}
    }
}

export const SalesOrderViewByIdSuccessfull = (data)=>{
    return {
        type:SALES_ORDER_VIEW_BY_ID_SUCCESSFULL,
        payload:data
    }
}
export const createDeliveryOrder = (url,data,history) =>{
    return {
        type: CREATE_DELIVERY_ORDER,
        payload:{url,data,history}
    }
}

export const createDeliveryOrderSuccessfull = (data)=>{
    return {
        type:CREATE_DELIVERY_ORDER_SUCCESSFULL,
        payload:data
    }
}
export const getAllTransporter = (url,history) =>{
    return {
        type: GET_ALL_TRANSPORTER,
        payload:{url,history}
    }
}

export const getAllTransporterSuccessfull = (data)=>{
    return {
        type:GET_ALL_TRANSPORTER_SUCCESSFULL,
        payload:data
    }
}
export const RegularDeliveryOrderViewBYId = (url,history) =>{
    return {
        type: REGULAR_DELIVERY_ORDER_VIEWBY_ID,
        payload:{url,history}
    }
}

export const RegularDeliveryOrderViewBYIdSuccessfull = (data)=>{
    return {
        type:REGULAR_DELIVERY_ORDER_VIEWBY_ID_SUCCESSFULL,
        payload:data
    }
}
export const updateRegularDeliveryOrder = (url,data,history) =>{
    return {
        type:UPDATE_REGULAR_DELIVERY_ORDER,
        payload:{url,data,history}
    }
}

export const updateRegularDeliveryOrderSuccessfull = (data)=>{
    return {
        type:UPDATE_REGULAR_DELIVERY_ORDER_SUCCESSFULL,
        payload:data
    }
}

export const getDeliveryOrderItemsByItemGroupId = (url, data, history, rowId) => {
  return {
    type: DELIVERY_ORDER_ITEMS_BY_ITEM_GROUP_ID,
    payload: { url, data, history, rowId },
  };
};
export const getDeliveryOrderItemsByItemGroupIdSuccessfull = (data) => {
  return {
    type: DELIVERY_ORDER_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL,
    payload: data,
  };
};

export const getJuteGovtDeliveryOrderHeader=(data)=>{
    return{
        type:JUTEGOVT_DELIVERY_ORDER_HEADER,
        payload:data
    }
}

export const getJuteGovtDeliveryOrderLineItems=(data)=>{
    return{
        type:JUTEGOVT_DELIVERY_ORDER_LINEITEMS,
        payload:data
    }
 
}

export const clearJuteGovtDeliveryOrderHeader=(data)=>{
    return{
        type:CLEAR_JUTEGOVT_DELIVERY_ORDER_HEADER,
        payload:data
    }
}

export const clearJuteGovtDeliveryOrderLineItems=(data)=>{
    return{
        type:CLEAR_JUTEGOVT_DELIVERY_ORDER_LINEITEMS,
        payload:data
    }
}

export const CreateJuteGovtDeliveryOrder=(url,data,history)=>{
    return{
        type:CREATE_JUTE_GOVT_DELIVERY_ORDER,
        payload:{url,data,history}
    }
}

export const CreateJuteGovtDeliveryOrderSuccessfull=(data)=>{
    return{
        type:CREATE_JUTE_GOVT_DELIVERY_ORDER_SUCCESSFULL,
        payload:data,
    }
}

export const UpdateJuteGovtDeliveryOrder=(url,data,history) => {
    return{
        type:UPDATE_JUTE_GOVT_DELIVERY_ORDER,
        payload:{url,data,history}
    }
}

export const UpdateJuteGovtDeliveryOrderSuccessfull=(data) => {
    return{
        type:UPDATE_JUTE_GOVT_DELIVERY_ORDER_SUCCESSFULL,
        payload:data,
    }
}

export const JuteGovtDeliveryOrderViewById=(url,history)=>{
    return{
        type:JUTE_GOVT_DELIVERY_ORDER_VIEWBY_ID,
        payload:{url,history}
        
    }
}

//Jute Yarn

export const CreateJuteYarnDeliveryOrder = (url, data, history) => {
    return {
      type: CREATE_JUTE_YARN_DELIVERY_ORDER,
      payload: { url, data, history },
    };
  };
  export const CreateJuteYarnDeliveryOrderSuccessfull = (url, data, history) => {
    return {
      type: CREATE_JUTE_YARN_DELIVERY_ORDER_SUCCESSFULL,
      payload: { url, data, history },
    };
  };
  export const JuteYarnDeliveryOrderHeaderSuccessfull = (url, data, history) => {
    return {
      type: JUTE_YARN_DELIVERY_ORDER_HEADER_SUCCESSFULL,
      payload: { url, data, history },
    };
  };
  export const getQuotationRecords = (url, data, history) => {
    return {
      type: GET_QUOTATION_RECORDS,
      payload: { url, data, history },
    };
  };
  export const getQuotationViewByIdForJuteYarn = (url, data, history) => {
    return {
      type: GET_QUOTATION_VIEW_BY_ID_FOR_JUTE_YARN,
      payload: { url, data, history },
    };
  };
  
  export const getJuteYarnDeliveryOrderHeader =(data) =>{
    return {
        type:JUTE_YARN_DELIVERY_ORDER_HEADER,
        payload:data
    }
  }
  export const ClearJuteYarnDeliveryOrderHeader =(data) =>{
    return {
       type:CLEAR_JUTE_YARN_DELIVERY_ORDER_HEADER,
        payload:data
    }
  }
  export const getJuteYarnDeliveryOrderLineItems =(data) =>{
    return {
        type:JUTE_YARN_DELIVERY_ORDER_LINE_ITEMS,
        payload:data
    }
  }
  export const clearJuteYarnDeliveryOrderLineItems =(data) =>{
    return {
        type:CLEAR_JUTE_YARN_DELIVERY_ORDER_LINE_ITEMS,
        payload:data
    }
  }
  export const JuteYarnDeliveryOrderViewById = (url,history) =>{
    return {
        type: JUTE_YARN_DELIVERY_ORDER_VIEW_BY_ID,
        payload:{url,history}
    }
  }
  export const JuteYarnDeliveryOrderViewByIdSuccessfull = (data)=>{
    return {
        type:JUTE_YARN_DELIVERY_ORDER_VIEW_BY_ID_SUCCESSFULL,
        payload:data
    }
  }
  export const UpdateJuteYarnDeliveryOrder = (url,data,history) =>{
    return {
        type:UPDATE_JUTE_YARN_DELIVERY_ORDER,
        payload:{url,data,history}
    }
  }
  
  export const UpdateJuteYarnDeliveryOrderSuccessfull = (data)=>{
    return {
        type:UPDATE_JUTE_YARN_DELIVERY_ORDER_SUCCESSFULL,
        payload:data
    }
  }
  export const SalesOrderViewByIdForJuteYarn = (url,history)=>{

    return {
        type:SALES_ORDER_VIEW_BY_ID_FOR_JUTE_YARN,
        payload:{url,history}
    }
  }
  export const SalesOrderViewByIdForJuteYarnSuccessfull = (data)=>{
    return {
        type:SALES_ORDER_VIEW_BY_ID_FOR_JUTE_YARN_SUCCESSFULL,
        payload:data
    }
  }
 
export const getJuteYarnItemsByItemGroupId = (url, data, history,rowId) => {
  return {
    type: JUTE_YARN_ITEMS_BY_ITEM_GROUP_ID,
    payload: { url, data, history,rowId },
  };
};
export const getJuteYarnItemsByItemGroupIdSuccessfull = (data) => {
  return {
    type: JUTE_YARN_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL,
    payload: data,
  };
};

  
//Jute Hessian

export const CreateJuteHessianDeliveryOrder = (url, data, history) => {
    return {
      type: CREATE_JUTEHESSIAN_DELIVERY_ORDER,
      payload: { url, data, history },
    };
  };
  
  export const CreateJuteHessianDeliveryOrderSuccessfull = (data) => {
    return {
      type: CREATE_JUTEHESSIAN_DELIVERY_ORDER_SUCCESSFULL,
      payload: data,
    };
  };
  
  export const DeliveryOrderJuteHessianHeaders = (data) => {
    return {
      type: DELIVERY_ORDER_JUTEHESSIAN_HEADERS,
      payload: data,
    };
  };
  
  export const DeliveryOrderJuteHessianLineItems = (data) => {
    return {
      type: DELIVERY_ORDER_JUTEHESSIAN_LINE_ITEMS,
      payload: data,
    };
  };
  
  export const DeliveryOrderJuteHessianById = (url, history, Val) => {
    return {
      type: DELIVERY_ORDER_JUTEHESSIAN_BY_ID,
      payload: { url, history, Val },
    };
  };
   
  export const DeliveryOrderJuteHessianByIdSuccessfull = (data) => {
    return {
      type: DELIVERY_ORDER_JUTEHESSIAN_BY_ID_SUCCESSFULL,
      payload: data,
    };
  };
  
  export const UpdateJuteHessianDeliveryOrder = (url, data, history) => {
    return {
      type: UPDATE_JUTEHESSIAN_DELIVERY_ORDER,
      payload: { url, data, history },
    };
  };
  
  export const UpdateJuteHessianDeliveryOrderSuccessfull = (data) => {
    return {
      type: UPDATE_JUTEHESSIAN_DELIVERY_ORDER_SUCCESSFULL,
      payload: data,
    };
  };

  export const SalesOrderViewByIdForJuteHessian = (url,history) =>{
    return {
        type: SALES_ORDER_VIEW_BY_ID_FOR_JUTE_HESSIAN,
        payload:{url,history}
    }
}

export const JuteGovtDeliveryOrderViewByIdSuccessfull=(data)=>{
    return{
        type:JUTE_GOVT_DELIVERY_ORDER_VIEWBY_ID_SUCCESSFULL,
        payload:data,
    }
}

export const SalesOrderViewByIdForJuteHessianSuccessfull = (data)=>{
    return {
        type:SALES_ORDER_VIEW_BY_ID_FOR_JUTE_HESSIAN_SUCCESSFULL,
        payload:data
    }
}

  
  
// ----------

//For Clearing the props
export const ClearDOJuteHessianHeaders = (data) => {
    return {
      type: CLEAR_DELIVERY_ORDER_JUTEHESSIAN_HEADERS,
      payload: data,
    };
  };
  export const ClearDeliveryOrderLineItems = (data) => {
    return {
      type: CLEAR_DELIVERY_ORDER_JUTEHESSIAN_LINE_ITEMS,
      payload: data,
    };
  };

export const SalesOrderViewByIdForJuteGovt=(url,history)=>{
    return{
        type:SALES_ORDER_VIEW_BY_ID_FOR_JUTE_GOVT,
        payload:{url,history}
    };
};

export const SalesOrderViewByIdForJuteGovtSuccessfull=(data)=>{
    return{
        type:SALES_ORDER_VIEW_BY_ID_FOR_JUTE_GOVT_SUCCESSFULL,
        payload:data,
    };
};

export const JuteGovtDeliveryOrderOtherCharges=(url, data, history)=>{
    return{
        type:JUTE_GOVT_DELIVERY_ORDER_OTHER_CHARGES,
        payload:{url,data,history}

    }
}

export const JuteGovtDeliveryOrderOtherChargesSuccessfull=(data)=>{
    return{
        type:JUTE_GOVT_DELIVERY_ORDER_OTHER_CHARGES_SUCCESSFULL,
        payload:data,
    }
}

export const getJuteGovtDelOrderItemsByItemGroupId=(url, data, history,rowId)=>{
  return{
    type:JUTE_GOVT_DELORDER_ITEMS_BY_ITEM_GROUP_ID,
    payload:{url,data,history,rowId},

  };
};
export const getJuteGovtDelOrderItemsByItemGroupIdSuccessfull=(data)=>{
  return{
    type:JUTE_GOVT_DELORDER_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL,
    payload:data,
  }
}
export const apiErrors = (error) => {
    return {
      type: API_ERRORS,
      payload: error,
    };
  };


  //upload documents and download

  export const UploadDOFiles = (url,data,history,sourceMenu,regDeliveryOrderId) =>{
    return {
        type:UPLOAD_DO_FILES,
        payload:{url,data,history,sourceMenu,regDeliveryOrderId}
    }
  }
  export const UploadDOFilesSuccessfull = (data)=>{
    return {
        type:UPLOAD_DO_FILES_SUCCESSFULL,
        payload:data
    }
  }
  
  export const deleteDoFile = (url,history) =>{
    return {
        type:DELETE_DO_FILE,
        payload:{url,history}
    }
  }
  export const getDOFiles = (url, history) => {
    return {
      type: GET_DO_FILES,
      payload: { url, history},
    };
  };
  export const DOFilesSuccessfull = (data) => {
    return {
      type: DO_FILES_SUCCESSFULL,
      payload: data,
    };
  };
  
  export const deleteDoFileSuccessfull = (data)=>{
    return {
        type:DELETE_DO_FILE_SUCCESSFULL,
        payload:data
    }
  }
  
  

