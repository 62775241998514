import {TERMS_LIST,TERMS_LIST_SUCCESSFULL,
  API_ERRORS,
  CREATE_TERMS, CREATE_TERMS_SUCCESSFULL, UPDATE_TERMS_SUCCESSFULL, UPDATE_TERMS,} from './actionTypes';

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    TermsList: [],
    Create_Terms:[],
    Update_Terms:[],
  };

  const TermsReducer = (state = initialState, action) => {
    switch (action.type) {
        case TERMS_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case TERMS_LIST_SUCCESSFULL:
            state = {
              ...state,
              TermsList: action.payload.response,
              loading: true,
            };
            break;
            case CREATE_TERMS:
              state = {
                ...state,
                loading: true,
              };
              break;
            case CREATE_TERMS_SUCCESSFULL:
              state = {
                ...state,
                Create_Terms: action.payload.response,
                loading: true,
              };
              break;
              case UPDATE_TERMS:
              state = {
                ...state,
                loading: true,
              };
              break;
            case UPDATE_TERMS_SUCCESSFULL:
              state = {
                ...state,
                Update_Terms: action.payload.response,
                loading: true,
              };
              break;
              case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default TermsReducer;