import {MUKAM_MASTER_LIST,MUKAM_MASTER_LIST_SUCCESSFULL,
    CREATE_MUKAM_MASTER,CREATE_MUKAM_MASTER_SUCCESSFULL,
    UPDATE_MUKAM_MASTER,UPDATE_MUKAM_MASTER_SUCCESSFULL,
    API_ERRORS,} from './actionTypes';

export const mukamMasterList = (url,data,history) =>{
    return {
        type:MUKAM_MASTER_LIST,
        payload:{url,data,history}
    }
}
export const mukamMasterListSuccessfull = (data)=>{
    return {
        type:MUKAM_MASTER_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createMukamMaster = (url, data, history) => {
    return {
        type: CREATE_MUKAM_MASTER,
        payload: { url, data, history }
    }
}

export const createMukamMasterSuccessfull = (data) => {
    return {
        type: CREATE_MUKAM_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const updateMukamMaster = (url, data, history) => {
    return {
        type: UPDATE_MUKAM_MASTER,
        payload: { url, data, history }
    }
}

export const updateMukamMasterSuccessfull = (data) => {
    return {
        type: UPDATE_MUKAM_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const apiErrors = (error) => {
    return {
        type: API_ERRORS,
        payload: error,
    };
};