import {
  WINDING_DOFF_ENTRY_LIST,
  WINDING_DOFF_ENTRY_LIST_SUCCESSFULL,
  WINDING_DOFF_ROW_DELETE,
  WINDING_DOFF_ROW_DELETE_SUCCESSFULL,
  TROLLY_DD_LIST,
  TROLLY_DD_LIST_SUCCESSFULL,
  // BEAMING_CREATE,
  // BEAMING_CREATE_SUCCESSFULL,
  // BEAMING_DELETE_ROW,
  // BEAMING_DELETE_ROW_SUCCESSFULL,
} from "./actionTypes";

export const windingDoffList = (url, data, history) => {
  return {
    type: WINDING_DOFF_ENTRY_LIST,
    payload: { url, data, history },
  };
};
export const windingDoffListSuccessfull = (data) => {
  return {
    type: WINDING_DOFF_ENTRY_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const windingDoffDeleteRow = (url, data, history, fromDate, toDate) => {
  return {
    type: WINDING_DOFF_ROW_DELETE,
    payload: { url, data, history, fromDate, toDate },
  };
};
export const windingDoffDeleteRowSuccessfull = (data) => {
  return {
    type: WINDING_DOFF_ROW_DELETE_SUCCESSFULL,
    payload: data,
  };
};
export const getTrollyDdList = (url, data, history) => {
  return {
    type: TROLLY_DD_LIST,
    payload: { url, data, history },
  };
};
export const getTrollyDdListSuccessfull = (data) => {
  return {
    type: TROLLY_DD_LIST_SUCCESSFULL,
    payload: data,
  };
};

// export const beamingCreate = (url, data, history) => {
//   return {
//     type: BEAMING_CREATE,
//     payload: { url, data, history },
//   };
// };
// export const beamingCreateSuccessfull = (data) => {
//   return {
//     type: BEAMING_CREATE_SUCCESSFULL,
//     payload: data,
//   };
// };


