import React, { Component } from "react";
import CustomButton from "../../../components/Buttons/Buttons";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi, getTenantId } from "../../../helpers/Consts";
import { getIndentPdf } from "../../../store/Purchase/Indent/actions";
import { getFileDownload } from "../../../helpers/server.js";
import { PriceEnquiryLineitemsProps } from "../../../store/Purchase/PriceEnquiry/actions";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";

class PriceEnquiryPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      date: moment().format("DD-MM-YYYY"),
      currentStep: 1,
      companyLogo: "",
      PELineitems: [],
    };
  }
  componentDidMount() {
    let companyData = JSON.parse(localStorage.getItem("authUser"));
    let companyId = localStorage.getItem("companyId");
    companyData.companyDetails.map((p) => {
      let id = p.value;
      if (companyId === id.toString()) {
        let companyName = p.label.split("-");
        this.setState({
          companyName: companyName[1],
          address1: p.address1,
          companyLogo: p.companyLogo,
          gstNo: p.gstNo,
          address2: p.address2,
          comName: p.name,
          phoneNo: p.contactNo,
          email: p.emailId,
        });
      }
    });
    if (this.props.PELineitems && this.props.PELineitems !== undefined) {
      this.setState({
        PELineitems: this.props.PELineitems,
      });
    } else {
      let PELineitems = [
        {
          dtlId: 1,
          companyId: "",
          createdBy: "",
          indentNo: "",
          isRateActive: 1,
          isActive: 1,
          itemId: "",
          itemName: "",
          itemGroupCode: "",
          itemCode: "",
          suppCode: "",
          itemRate: "",
          suppComments: "",
          suppQuoteFile: "",
          suppResponseDateTime: "",
          itemDesc: "",
        },
      ];
      this.props.PriceEnquiryLineitemsProps(PELineitems);
    }
  }

  componentWillReceiveProps(props) {
    if (props.PELineitems) {
      this.setState({
        PELineitems: props.PELineitems,
      });
    }
  }
  handleEdit = () => {
    this.props.handleCurrentStep(this.state.currentStep);
  };
  handlePdf = () => {
    let hdrId = this.props.location.state.rowData.hdrId;

    fetch(serverApi.DOWNLOAD_PDF + hdrId + "/" + this.state.userDit.userId, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        "X-TenantId": getTenantId(window.location.hostname),
        Authorization:
          localStorage.getItem("token_type") +
          localStorage.getItem("access_token"),
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          this.props.location.state.rowData.hdrId + ".pdf"
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };
  render() {
    var PELineitems = this.state.PELineitems.filter(
      (item) => item.itemGroupCode !== ""
    );
    return (
      <>
        <div className="previewBlock">
          <div
            style={{ display: "flex", justifyContent: "end", padding: "10px" }}
          >
            {this.props.location.state && (
              <>
                <CustomButton
                  label="Print"
                  className="lightBlueButton"
                  muIcon={<PrintIcon />}
                  handleClick={this.handlePdf}
                  type="button"
                />
                <CustomButton
                  label="Edit"
                  className="lightBlueButton"
                  muIcon={<EditIcon />}
                  handleClick={this.handleEdit}
                  type="button"
                />
              </>
            )}
          </div>
          <table
            cellpadding="0"
            cellspacing="0"
            width="90%"
            className="previewTable"
          >
            <tbody>
              <tr>
                <td
                  colspan="3"
                  style={{ borderRight: "none", borderBottom: "none" }}
                >
                  <img src={this.state.companyLogo} width="100%" alt="" />
                </td>
                <td
                  colspan="6"
                  style={{
                    borderBottom: "none",
                    borderLeft: "none",
                    textAlign: "right",
                  }}
                >
                  <p style={{ margin: "0px" }}> {this.state.companyName}</p>
                  <span>{this.state.gstNo}</span>
                </td>
              </tr>
              <tr>
                <td
                  colspan="10"
                  style={{ textAlign: "center", borderTop: "none" }}
                >
                  <h2>Price Enquiry</h2>
                </td>
              </tr>
              <tr>
                <td colSpan="1">
                  <b>S.No</b>
                </td>
                <td>
                  <b>Indent No</b>
                </td>
                <td>
                  <b>Group Code</b>
                </td>
                <td>
                  <b>Group Name</b>
                </td>
                <td>
                  <b>Item Code</b>
                </td>
                <td>
                  <b>Name</b>
                </td>
                <td>
                  <b>Make</b>
                </td>
              </tr>

              {PELineitems &&
                PELineitems.map((data, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{data.indentNo}</td>
                    <td>{data.itemGroupCode}</td>
                    <td>{data.itemDesc}</td>
                    <td>{data.itemCode}</td>
                    <td>{data.itemName}</td>
                    <td>{data.suppComments}</td>
                  </tr>
                ))}
              <tr>
                <td
                  colspan="10"
                  style={{
                    borderTop: "none",
                    borderBottom: "none",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <b>Store-In-charge-Signature </b>
                    <b>Authorised Signature </b>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  colspan="10"
                  style={{ borderTop: "none", borderBottom: "none" }}
                >
                  <b>Note :</b>
                </td>
              </tr>
              <tr>
                <td
                  colspan="5"
                  style={{ borderTop: "none", borderRight: "none" }}
                >
                  Printed on: {this.state.date}
                </td>
                <td
                  colspan="5"
                  align="right"
                  style={{ borderTop: "none", borderLeft: "none" }}
                >
                  Page 1 of 2<br />
                  Printed By: {this.state.userDit.userId}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { indentLineItems } = state.IndentReducer;
  const { PELineitems } = state.PriceEnquiryReducer;

  return {
    indentLineItems,
    PELineitems,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getIndentPdf,
    getFileDownload,
    PriceEnquiryLineitemsProps,
  })(PriceEnquiryPreview)
);
