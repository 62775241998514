import {BROKER_MASTER_LIST,BROKER_MASTER_LIST_SUCCESSFULL,CREATE_BROKER_MASTER_SUCCESSFULL,CREATE_BROKER_MASTER,
    UPDATE_BROKER_MASTER,UPDATE_BROKER_MASTER_SUCCESSFULL,
    API_ERRORS,} from './actionTypes';

export const brokerMasterList = (url,data,history) =>{
    return {
        type:BROKER_MASTER_LIST,
        payload:{url,data,history}
    }
}
export const brokerMasterListSuccessfull = (data)=>{
    return {
        type:BROKER_MASTER_LIST_SUCCESSFULL,
        payload:data
    }
}
export const createBrokerMaster = (url, data, history) => {
    return {
        type: CREATE_BROKER_MASTER,
        payload: { url, data, history }
    }
}

export const createBrokerMasterSuccessfull = (data) => {
    return {
        type: CREATE_BROKER_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const updateBrokerMaster = (url, data, history) => {
    return {
        type: UPDATE_BROKER_MASTER,
        payload: { url, data, history }
    }
}

export const updateBrokerMasterSuccessfull = (data) => {
    return {
        type: UPDATE_BROKER_MASTER_SUCCESSFULL,
        payload: data
    }
}
export const apiErrors = (error) => {
    return {
        type: API_ERRORS,
        payload: error,
    };
};