import {
  ATTENDANCE_DESIGNATION_LIST,
  ATTENDANCE_LIST,
  ATTENDANCE_SUMMARY,
  ATTENDANCE_VIEW,
  LOGS,
  MACHINE_NUMBER,
  MARK_ATTENDANCE,
  PROCESS_LOGS,
  SPELL,
  UPDATE_ATTENDANCE,
  WORKER_DATA_BY_EB_NUMBER,
} from "./actionType";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getList, getListurl, postAdd } from "../../../helpers/Server_Helper";
import {
  AttendanceListSuccessfull,
  SpellSuccessfull,
  MachineNumberSuccessfull,
  MarkAttendanceSuccessfull,
  LogsSuccessfull,
  UpdateAttendanceSuccessfull,
  AttendanceSummaryListSuccessfull,
  ProcessLogsSuccessfull,
  getWorkerByEBNumberSuccessfull,
  viewAttendanceSuccessfull,
  getMachineNumber,
  getAttendanceDesignationSuccessfull,
  getAttendanceDesignation
} from "./actions";
import swal from "sweetalert";
import { showSpinner, hideSpinner ,serverApi } from "../../../helpers/Consts";

function* getAttendanceList({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    yield put(AttendanceListSuccessfull({ response }));
    if (response) {
      hideSpinner();
    }
  } catch (error) {
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
  }
}
function* ViewAttendanceData({ payload: { url, data, history } }) {
  try {
    showSpinner();
    const response = yield call(getList, url, data);
    yield put(viewAttendanceSuccessfull({ response }));
    if (response) {
      hideSpinner();
    }
  } catch (error) {
    hideSpinner();
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
  }
}

function* getSpell({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(SpellSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* getMachineNumberData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(MachineNumberSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* setMarkAttendance({ payload: { url, data, history } }) {
  try {
    showSpinner()
    const response = yield call(postAdd, url, data);
    yield put(MarkAttendanceSuccessfull({ response }));
    if (response.data.status) {
      hideSpinner()
      swal(response.data.message, { icon: "success" });
      history.push("/Attendance_list");
    } else {
      hideSpinner()
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    hideSpinner()
    console.log(error);
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
  }
}
function* logs({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(LogsSuccessfull({ response }));
  } catch (error) {
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
  }
}
function* updateAttendance({ payload: { url, data, history } }) {
  try {
    showSpinner()
    const response = yield call(postAdd, url, data);
    yield put(UpdateAttendanceSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
    if(response){
      hideSpinner()
      if (response.data.status) {
          history.push("/Attendance_list");
        }
    }
  } catch (error) {
    hideSpinner()
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
    // swal('something went wrong',{icon: "error"})
  }
}
function* AttendanceSummary({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(AttendanceSummaryListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* processLogsData({ payload: { url, data, history } }) {
  try {
    const response = yield call(getListurl, url, data);
    yield put(ProcessLogsSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
  } catch (error) {
    console.log(error);
  }
}
function* getWorkerByEBNumberData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getWorkerByEBNumberSuccessfull({ response }));
    if (response) {
      if (response.data) {
        if (response.data.data) {
          yield put(getMachineNumber(  
            serverApi.MACHINE_NUMBER +
            response.data.data.designationId +
            "/company/" +
            localStorage.getItem("companyId"),history))
            yield put(getAttendanceDesignation(
              serverApi.DESIGNATION + localStorage.getItem("companyId") + "/"+ response.data.data.deptId,
              history
            ))
        }
      }
    }
  } catch (error) {
    console.log(error);
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error,{icon:"error"});
        }
      }
    }
  }
}
function* getAttendanceDesignationdata({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getAttendanceDesignationSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
export function* watchAttendanceList() {
  yield takeEvery(ATTENDANCE_LIST, getAttendanceList);
  yield takeEvery(SPELL, getSpell);
  yield takeEvery(MACHINE_NUMBER, getMachineNumberData);
  yield takeEvery(MARK_ATTENDANCE, setMarkAttendance);
  yield takeEvery(LOGS, logs);
  yield takeEvery(UPDATE_ATTENDANCE, updateAttendance);
  yield takeEvery(ATTENDANCE_SUMMARY, AttendanceSummary);
  yield takeEvery(PROCESS_LOGS, processLogsData);
  yield takeEvery(WORKER_DATA_BY_EB_NUMBER, getWorkerByEBNumberData);
  yield takeEvery(ATTENDANCE_VIEW, ViewAttendanceData);
  yield takeEvery(ATTENDANCE_DESIGNATION_LIST,getAttendanceDesignationdata)
}

function* AttendanceSaga() {
  yield all([fork(watchAttendanceList)]);
}

export default AttendanceSaga;
