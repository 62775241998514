import React, { Component } from "react";
import { Grid, Box, Button } from "@mui/material";
import CustomButton from "../../../../../components/Buttons/Buttons";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi } from "../../../../../helpers/Consts";
import {
  setUpdateEsiDetails,
  viewbyidPersonalDetails,
  viewbyidPersonalDetailsSuccessfull,
  deleteMedicalPolicyFile,
  getAllFiles,
} from "../../../../../store/HRMS/EmployeeDataBase/actions";
import panUploadIcon from "../../../../../assets/images/panUploadIcon.png";
import DeleteIcon from "../../../../../assets/images/picEditIcon.png";
import TickIcon from "../../../../../assets/images/fileUploadedTick.png";
import swal from "sweetalert";
import TextFieldNormal from "../../../../../components/TextField/TextFieldNormal";
import { postUploadFileList } from "../../../../../store/MyProfile/UploadFile/actions";

class EmployeeStateInsurance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      tblHrmsEdEsiId: "",
      ebId: this.props.getEbId,
      isActive: 1,
      esiNo: "",
      esiValidation: false,
      medicalPolicyNo: "",
      updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
      updatedTime: "",
      medical_policy_document: "",
      medical_policy_id: "",
    };
  }

  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key == "esiNo") {
      if (event.length > 0) {
        if (event.length < 10 || event.length > 10) {
          this.setState({
            esiValidation: true,
          });
        } else {
          this.setState({
            esiNo: event,
            esiValidation: false,
          });
        }
      } else {
        this.setState({
          esiNo: event,
          esiValidation: false,
        });
      }
    }
  };
  componentWillReceiveProps(props) {
    var ebId = this.props.getEbId;
    if (ebId > 0) {
      if (props.personalDetails) {
        if (props.personalDetails.data) {
          var personal = props.personalDetails.data;
          if (personal) {
            Object.keys(personal).map((key, index) => {
              this.setState({ [key]: personal[key] });
              return true;
            });
          }
        }
      }
    }
    if (props.allFiles) {
      if (props.allFiles.data) {
        if (props.allFiles.data.length != 0) {
          props.allFiles.data.map((item) => {
            this.setState({
              medical_policy_document: item.fileName,
              medical_policy_id: item.fileUploadId,
            });
          });
        } else {
          this.setState({
            medical_policy_document: "",
            medical_policy_id: "",
          });
        }
      }
    }
  }

  componentDidMount() {
    var ebId = this.props.getEbId;
    if (ebId > 0) {
      this.props.viewbyidPersonalDetails(
        serverApi.VIEW_ESI_DETAILS_BY_ID + "/" + this.props.getEbId,
        this.props.history,
        0
      );
    } else {
      const response = { data: null };
      this.props.viewbyidPersonalDetailsSuccessfull({ response });
    }

    this.props.getAllFiles(
      serverApi.GET_ALL_FILES +
        "49/" +
        this.props.getEbId +
        "/" +
        localStorage.getItem("companyId"),
      this.props.history
    );
  }

  onClickSave = () => {
    if (this.state.esiValidation == true) {
      swal("Please Enter the Valid ESI IP Number", { icon: "error" });
    } else {
      if (this.state.esiNo !== "" && this.state.medicalPolicyNo !== "") {
        swal(
          "Either You have to enter ESI number or Medical Policy Number, You Con't Enter Both"
        );
      } else {
        const data = {
          tblHrmsEdEsiId: this.state.tblHrmsEdEsiId,
          ebId: this.props.getEbId,
          isActive: this.state.isActive,
          esiNo: this.state.esiNo,
          medicalPolicyNo: this.state.medicalPolicyNo,
          updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
        };
        this.props.setUpdateEsiDetails(
          serverApi.UPDATE_ESI_DETAILS,
          data,
          this.props.history,
          this.props.getEbId
        );
      }
    }
  };
  handleselectedFile = (key) => (e) => {
    var ebId = this.state.ebId;
    var fileName = "";
    if (key === "medical_policy_document") {
      fileName = ebId + "_medical_policy_file";
    }
    e.preventDefault();
    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", fileName);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 49);
    formData.append("displayName", fileName);
    formData.append("taskUniqueId", ebId);
    formData.append("createdBy", this.state.userDit.userId);
    formData.append("fileCompanyId", this.state.companyId);

    if (key === "medical_policy_document") {
      this.setState({
        medical_policy_document: fileName + "." + fileExt,
      });
    }
    this.props.postUploadFileList(
      serverApi.UPLOADFILE,
      formData,
      this.props.history,
      49,
      this.state.ebId
    );
  };
  handleDeleteFile = (key, fileUploadId) => (e) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (key === "medical_policy_document") {
          this.setState({
            medical_policy_document: "",
          });
        }
        this.props.deleteMedicalPolicyFile(
          serverApi.DELETE_FILE_BI_ID + fileUploadId,
          this.props.history,
          49,
          this.state.ebId
        );
      } else {
        swal("Your action is canceled!");
      }
    });
  };

  render() {
    return (
      <div className="medicalEnrollment">
        <h1>Employee's State Insurance</h1>
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      value={this.state.esiNo}
                      label="ESIC IP Number"
                      placeholder="17 digit code"
                      onChange={this.handleChange("esiNo")}
                      type="number"
                    />
                    {this.state.esiValidation && (
                      <div className="TextFieldBlock">
                        <span className="text-danger">
                          Please Enter 10 digit ESI IP Number
                        </span>
                      </div>
                    )}
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      value={this.state.medicalPolicyNo}
                      label="Medical Policy Number"
                      type="number"
                      placeholder="17 digit code"
                      onChange={this.handleChange("medicalPolicyNo")}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="personalDetailsUpload">
                    <div className="ducumentUploadBlock">
                      <div className="documentUploadContent">
                        {this.state.medical_policy_document === "" ? (
                          <span
                            className="btn_upload documentUpload  "
                            style={{ marginTop: "25px" }}
                          >
                            <img src={panUploadIcon} alt="" />
                            <input
                              type="file"
                              id="imag"
                              title=""
                              className="input-img"
                              onChange={this.handleselectedFile(
                                "medical_policy_document"
                              )}
                            />
                            Upload Document
                          </span>
                        ) : (
                          ""
                        )}
                        {this.state.medical_policy_document !== "" && (
                          <div className="uploadedInfo">
                            <img src={TickIcon} alt="" className="tickIcon" />
                            {this.state.medical_policy_document}

                            <Button
                              onClick={this.handleDeleteFile(
                                "medical_policy_document",
                                this.state.medical_policy_id
                              )}
                              className="deleteButton"
                            >
                              <img src={DeleteIcon} alt="" />
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>

        <div className="consoleFormButtonBlock">
          <Link to="/add_employee">
            <CustomButton label={"Cancel"} className="greenBorderButton" />
          </Link>

          {!this.state.tblHrmsEdEsiId ? (
            <CustomButton
              label={"Save"}
              className="greenButton"
              handleClick={this.onClickSave}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickSave}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}
const mapStatetoProps = (state) => {
  const { personalDetails, getEbId, allFiles } = state.EmployeeReducer;
  return { personalDetails, getEbId, allFiles };
};

export default withRouter(
  connect(mapStatetoProps, {
    setUpdateEsiDetails,
    viewbyidPersonalDetails,
    viewbyidPersonalDetailsSuccessfull,
    postUploadFileList,
    deleteMedicalPolicyFile,
    getAllFiles,
  })(EmployeeStateInsurance)
);
