import {
  SALESORDER_LIST,
  SALESORDER_LIST_SUCCESSFULL,
  API_ERRORS,
  GET_ALL_BROKER,
  GET_ALL_BROKER_SUCCESSFULL,
  CREATE_SALES_ORDER,
  CREATE_SALES_ORDER_SUCCESSFULL,
  SALES_ORDER_BY_ID,
  SALES_ORDER_BY_ID_SUCCESSFULL,
  UPDATE_SALES_ORDER,
  UPDATE_SALES_ORDER_SUCCESSFULL,
  GET_BRANCH,
  GET_BRANCH_SUCCESSFULL,
  SALES_ORDER_HEADER,
  SALES_ORDER_LINE_ITEMS,
  GET_QUOTATION_RECORDS,
  GET_QUOTATION_RECORDS_SUCCESSFULL,
  CLEAR_SALES_ORDER_HEADER,
  CLEAR_SO_JUTEGOVT_LINE_ITEMS,
  SALES_ORDER_JUTEGOVT_LINE_ITEMS,
  SALES_ORDER_JUTEGOVT_HEADERS,
  CLEAR_SO_JUTEGOVT_HEADERS,
  CREATE_JUTEGOVT_SALES_ORDER,
  CREATE_JUTEGOVT_SALES_ORDER_SUCCESSFULL,
  QUOTATION_VIEW_BY_ID,
  QUOTATION_VIEW_BY_ID_SUCCESSFULL,
  CLEAR_SALES_ORDER_LINEITEMS,
  QUOTE_VIEW_BY_ID_FOR_JUTEGOVT,
  QUOTE_VIEW_BY_ID_FOR_JUTEGOVT_SUCCESSFULL,
  S_O_BY_ID_FOR_JUTEGOVT,
  S_O_BY_ID_FOR_JUTEGOVT_SUCCESSFULL,
  UPDATE_JUTEGOVT_SALES_ORDER,
  UPDATE_JUTEGOVT_SALES_ORDER_SUCCESSFULL,
  CREATE_JUTEHESSIAN_SALES_ORDER,
  CREATE_JUTEHESSIAN_SALES_ORDER_SUCCESSFULL,
  SALES_ORDER_JUTEHESSIAN_HEADERS,
  SALES_ORDER_JUTEHESSIAN_LINE_ITEMS,
  CLEAR_SO_JUTEHESSIAN_LINE_ITEMS,
  CLEAR_SO_JUTEHESSIAN_HEADERS,
  SALES_ORDER_JUTEHESSIAN_BY_ID,
  SALES_ORDER_JUTEHESSIAN_BY_ID_SUCCESSFULL,
  UPDATE_JUTEHESSIAN_SALES_ORDER_SUCCESSFULL,
  UPDATE_JUTEHESSIAN_SALES_ORDER,
  SO_JUTE_HESSIAN_QUOTATION_VIEW_BY_ID,
  SO_JUTE_HESSIAN_QUOTATION_VIEW_BY_ID_SUCCESSFULL,
  SALES_ORDER_ITEMS_BY_ITEM_GROUP_ID,
  SALES_ORDER_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL,
  ITEMS_BY_ITEM_GROUP_ID_FOR_JUTEGOVT,
  TERMS_CONDITIONS_API,
  GET_UOM_RELATION_VALUE_BY_UOM_CODE,
  GET_UOM_RELATION_VALUE_BY_UOM_CODE_SUCCESSFULL,
  TERMS_CONDITIONS_API_SUCCESSFULL,
  CREATE_JUTEYARN_SALES_ORDER,
  CREATE_JUTEYARN_SALES_ORDER_SUCCESSFULL,
  UPDATE_JUTEYARN_SALES_ORDER,
  UPDATE_JUTEYARN_SALES_ORDER_SUCCESSFULL,
  SALES_ORDER_JUTEYARN_HEADERS,
  SALES_ORDER_JUTEYARN_LINE_ITEMS,
  QUOTE_VIEW_BY_ID_FOR_JUTEYARN,
  QUOTE_VIEW_BY_ID_FOR_JUTEYARN_SUCCESSFULL,
  S_O_BY_ID_FOR_JUTEYARN,
  S_O_BY_ID_FOR_JUTEYARN_SUCCESSFULL,
  CLEAR_SO_JUTEYARN_HEADERS,
  CLEAR_SO_JUTEYARN_LINE_ITEMS,
  ITEMS_BY_ITEM_GROUP_ID_FOR_JUTEYARN,
  ITEMS_BY_ITEM_GROUP_ID_FOR_JUTEYARN_SUCCESSFULL,
  SALES_ORDER_HESSIAN_ITEMS_BY_ITEM_GROUP_ID,
  SALES_ORDER_HESSIAN_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL,
  GET_UOM_RELATION_VALUE_HESSIAN,
  GET_UOM_RELATION_VALUE_HESSIAN_SUCCESSFULL,
  ACCOUNT_LIST,ACCOUNT_LIST_SUCCESSFULL,
  DELETE_SO_FILE,
  DELETE_SO_FILE_SUCCESSFULL,
  UPLOAD_SO_FILES,
  UPLOAD_SO_FILES_SUCCESSFULL,
  GET_SO_FILES,
  SO_FILES_SUCCESSFULL,
  CLEAR_ALL_SO_FILES,
  UOM_LIST_BASEDON_ITEM_ID_HESSIAN,
  UOM_LIST_BASEDON_ITEM_ID_HESSIAN_SUCCESS,
  SO_JUTE_YARN_UOM_LIST,
  SO_JUTE_YARN_UOM_LIST_SUCCESSFULL,
  REGULAR_SO_ITEM_NAME_DATA,
  REGULAR_SO_ITEM_NAME_DATA_SUCCESSFULL,
  JUTE_GOVT_SO_ITEM_NAME_DATA,
  JUTE_GOVT_SO_ITEM_NAME_DATA_SUCCESSFULL,
  JUTE_YARN_SO_ITEM_NAME_DATA,
  JUTE_HESSIAN_SO_ITEM_NAME_DATA,
  JUTE_HESSIAN_SO_ITEM_NAME_DATA_SUCCESSFULL,
} from "./actionTypes";
export const getSalesOrderList = (url, data, history) => {
  return {
    type: SALESORDER_LIST,
    payload: { url, data, history },
  };
};

export const SalesOrderListSuccessfull = (data) => {
  return {
    type: SALESORDER_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getAllBrokerList = (url, history) => {
  return {
    type: GET_ALL_BROKER,
    payload: { url, history },
  };
};
export const getBankAccountList = (url, data, history) => {
  return {
    type: ACCOUNT_LIST,
    payload: { url, data, history },
  };
};
export const getBankAccountListSuccessfull = (data) => {
  return {
    type: ACCOUNT_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const getAllBrokerListSuccessfull = (data) => {
  return {
    type: GET_ALL_BROKER_SUCCESSFULL,
    payload: data,
  };
};
export const getSalesOrder = (url, data, history) => {
  return {
    type: CREATE_SALES_ORDER,
    payload: { url, data, history },
  };
};

export const getSalesOrderSuccessfull = (data) => {
  return {
    type: CREATE_SALES_ORDER_SUCCESSFULL,
    payload: data,
  };
};

export const setSalesOrder = (url, data, history) => {
  return {
    type: UPDATE_SALES_ORDER,
    payload: { url, data, history },
  };
};

export const setSalesOrderSuccessfull = (data) => {
  return {
    type: UPDATE_SALES_ORDER_SUCCESSFULL,
    payload: data,
  };
};

export const SalesOrderById = (url, history, Val) => {
  return {
    type: SALES_ORDER_BY_ID,
    payload: { url, history, Val },
  };
};

export const SalesOrderByIdSuccessfull = (data) => {
  return {
    type: SALES_ORDER_BY_ID_SUCCESSFULL,
    payload: data,
  };
};

export const getSalesOrderBranch = (url, history) => {
  return {
    type: GET_BRANCH,
    payload: { url, history },
  };
};
export const getSalesOrderBranchSuccessfull = (data) => {
  return {
    type: GET_BRANCH_SUCCESSFULL,
    payload: data,
  };
};
export const getSalesOrderHeader = (data) => {
  return {
    type: SALES_ORDER_HEADER,
    payload: data,
  };
};

export const SalesOrderLineItems = (data) => {
  return {
    type: SALES_ORDER_LINE_ITEMS,
    payload: data,
  };
};
// for upload files and delete files in Sales order Regular

export const UploadSOFiles = (url,data,history,sourceMenu,regularSalesId) =>{
  return {
      type:UPLOAD_SO_FILES,
      payload:{url,data,history,sourceMenu,regularSalesId}
  }
}
export const UploadSOFilesSuccessfull = (data)=>{
  return {
      type:UPLOAD_SO_FILES_SUCCESSFULL,
      payload:data
  }
}

export const deleteSoFile = (url,history) =>{
  return {
      type:DELETE_SO_FILE,
      payload:{url,history}
  }
}
export const getSOFiles = (url, history) => {
  return {
    type: GET_SO_FILES,
    payload: { url, history},
  };
};
export const SOFilesSuccessfull = (data) => {
  return {
    type: SO_FILES_SUCCESSFULL,
    payload: data,
  };
};

export const deleteSoFileSuccessfull = (data)=>{
  return {
      type:DELETE_SO_FILE_SUCCESSFULL,
      payload:data
  }
}
export const ClearSoFilesData = (data) => {
  return {
    type: CLEAR_ALL_SO_FILES,
    payload: data,
  };
};


//Jute Government Sales Order
export const CreateJuteGovtSalesOrder = (url, data, history) => {
  return {
    type: CREATE_JUTEGOVT_SALES_ORDER,
    payload: { url, data, history },
  };
};

export const CreateJuteGovtSalesOrderSuccessfull = (data) => {
  return {
    type: CREATE_JUTEGOVT_SALES_ORDER_SUCCESSFULL,
    payload: data,
  };
};
export const UpdateJuteGovtSalesOrder = (url, data, history) => {
  return {
    type: UPDATE_JUTEGOVT_SALES_ORDER,
    payload: { url, data, history },
  };
};

export const UpdateJuteGovtSalesOrderSuccessfull = (data) => {
  return {
    type: UPDATE_JUTEGOVT_SALES_ORDER_SUCCESSFULL,
    payload: data,
  };
};

export const SalesOrderJuteGovtHeaders = (data) => {
  return {
    type: SALES_ORDER_JUTEGOVT_HEADERS,
    payload: data,
  };
};

export const SalesOrderJuteGovtLineItems = (data) => {
  return {
    type: SALES_ORDER_JUTEGOVT_LINE_ITEMS,
    payload: data,
  };
};

export const getQuotationRecords = (url, history) => {
  return {
    type: GET_QUOTATION_RECORDS,
    payload: { url, history },
  };
};
export const getQuotationRecordsSuccessfull = (data) => {
  return {
    type: GET_QUOTATION_RECORDS_SUCCESSFULL,
    payload: data,
  };
};

export const getQuotationViewById = (url, history) => {
  return {
    type: QUOTATION_VIEW_BY_ID,
    payload: { url, history },
  };
};

export const getQuotationViewByIdSuccessfull = (data) => {
  return {
    type: QUOTATION_VIEW_BY_ID_SUCCESSFULL,
    payload: data,
  };
};
export const getQuoteViewByIdForJuteGovt = (url, history) => {
  return {
    type: QUOTE_VIEW_BY_ID_FOR_JUTEGOVT,
    payload: { url, history },
  };
};

export const getQuoteViewByIdForJuteGovtSuccessfull = (data) => {
  return {
    type: QUOTE_VIEW_BY_ID_FOR_JUTEGOVT_SUCCESSFULL,
    payload: data,
  };
};
export const SOByIdForJuteGovt = (url, history, Val) => {
  return {
    type: S_O_BY_ID_FOR_JUTEGOVT,
    payload: { url, history, Val },
  };
};
//Jute Yarn sales Order

export const CreateJuteYarnSalesOrder = (url, data, history) => {
  return {
    type: CREATE_JUTEYARN_SALES_ORDER,
    payload: { url, data, history },
  };
};

export const CreateJuteYarnSalesOrderSuccessfull = (data) => {
  return {
    type: CREATE_JUTEYARN_SALES_ORDER_SUCCESSFULL,
    payload: data,
  };
};
export const UpdateJuteYarnSalesOrder = (url, data, history) => {
  return {
    type: UPDATE_JUTEYARN_SALES_ORDER,
    payload: { url, data, history },
  };
};

export const UpdateJuteYarnSalesOrderSuccessfull = (data) => {
  return {
    type: UPDATE_JUTEYARN_SALES_ORDER_SUCCESSFULL,
    payload: data,
  };
};

export const SalesOrderJuteYarnHeaders = (data) => {
  return {
    type: SALES_ORDER_JUTEYARN_HEADERS,
    payload: data,
  };
};

export const SalesOrderJuteYarnLineItems = (data) => {
  return {
    type: SALES_ORDER_JUTEYARN_LINE_ITEMS,
    payload: data,
  };
};

export const getQuoteViewByIdForJuteYarn = (url, history) => {
  return {
    type: QUOTE_VIEW_BY_ID_FOR_JUTEYARN,
    payload: { url, history },
  };
};

export const getQuoteViewByIdForJuteYarnSuccessfull = (data) => {
  return {
    type: QUOTE_VIEW_BY_ID_FOR_JUTEYARN_SUCCESSFULL,
    payload: data,
  };
};
export const SOByIdForJuteYarn = (url, history, Val) => {
  return {
    type: S_O_BY_ID_FOR_JUTEYARN,
    payload: { url, history, Val },
  };
};
export const SOByIdForJuteYarnSuccessfull = (data) => {
  return {
    type: S_O_BY_ID_FOR_JUTEYARN_SUCCESSFULL,
    payload: data,
  };
};

//Jute Hessian Sales Order

export const CreateJuteHessianSalesOrder = (url, data, history) => {
  return {
    type: CREATE_JUTEHESSIAN_SALES_ORDER,
    payload: { url, data, history },
  };
};

export const CreateJuteHessianSalesOrderSuccessfull = (data) => {
  return {
    type: CREATE_JUTEHESSIAN_SALES_ORDER_SUCCESSFULL,
    payload: data,
  };
};

export const SalesOrderJuteHessianHeaders = (data) => {
  return {
    type: SALES_ORDER_JUTEHESSIAN_HEADERS,
    payload: data,
  };
};

export const SalesOrderJuteHessianLineItems = (data) => {
  return {
    type: SALES_ORDER_JUTEHESSIAN_LINE_ITEMS,
    payload: data,
  };
};

export const SalesOrderJuteHessianById = (url, history, Val) => {
  return {
    type: SALES_ORDER_JUTEHESSIAN_BY_ID,
    payload: { url, history, Val },
  };
};

export const SOByIdForJuteGovtSuccessfull = (data) => {
  return {
    type: S_O_BY_ID_FOR_JUTEGOVT_SUCCESSFULL,
    payload: data,
  };
};

export const SalesOrderJuteHessianByIdSuccessfull = (data) => {
  return {
    type: SALES_ORDER_JUTEHESSIAN_BY_ID_SUCCESSFULL,
    payload: data,
  };
};

export const UpdateJuteHessianSalesOrder = (url, data, history) => {
  return {
    type: UPDATE_JUTEHESSIAN_SALES_ORDER,
    payload: { url, data, history },
  };
};

export const UpdateJuteHessianSalesOrderSuccessfull = (data) => {
  return {
    type: UPDATE_JUTEHESSIAN_SALES_ORDER_SUCCESSFULL,
    payload: data,
  };
};

export const getUomRelationHessian = (url,ItemId ,type,history) => {
  return {
    type: GET_UOM_RELATION_VALUE_HESSIAN,
    payload: { url,ItemId,type, history },
  };
};

export const getUomRelationHessianSuccessfull = (data) => {
  return {
    type: GET_UOM_RELATION_VALUE_HESSIAN_SUCCESSFULL,
    payload: data,
  };
};

export const getQuotationViewByIdForJuteHessian = (url, history) => {
  return {
    type: SO_JUTE_HESSIAN_QUOTATION_VIEW_BY_ID,
    payload: { url, history },
  };
};

export const getQuotationViewByIdForJuteHessianSuccessfull = (data) => {
  return {
    type: SO_JUTE_HESSIAN_QUOTATION_VIEW_BY_ID_SUCCESSFULL,
    payload: data,
  };
};

export const getSalesOrderHessianItemsByItemGroupId = (url, data, history, rowId) => {
  return {
    type: SALES_ORDER_HESSIAN_ITEMS_BY_ITEM_GROUP_ID,
    payload: { url, data, history, rowId },
  };
};
export const getSalesOrderHessianItemsByItemGroupIdSuccessfull = (data) => {
  return {
    type: SALES_ORDER_HESSIAN_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL,
    payload: data,
  };
};
export const getUomListBasedOnItemIdHessian = (url, history, rowId) => {
  return {
    type: UOM_LIST_BASEDON_ITEM_ID_HESSIAN,
    payload: { url, history, rowId},
  };
};

export const getUomListBasedOnItemIdHessianSuccess = (data) => {
  return {
    type: UOM_LIST_BASEDON_ITEM_ID_HESSIAN_SUCCESS,
    payload: data,
  };
};

export const getTermsConditions = (url, data,history) => {
  return {
    type: TERMS_CONDITIONS_API,
    payload: { url,data, history },
  };
};
export const getTermsConditionsSuccessfull = (data) => {
  return {
    type:TERMS_CONDITIONS_API_SUCCESSFULL,
    payload: data,
  };
};
export const getUomRelationValueByUomCode = (url,ItemId ,type,history) => {
  return {
    type:GET_UOM_RELATION_VALUE_BY_UOM_CODE,
    payload: { url,ItemId ,type,history },
  };
};
export const getUomRelationValueByUomCodeSuccessfull = (data) => {
  return {
   type:GET_UOM_RELATION_VALUE_BY_UOM_CODE_SUCCESSFULL,
   payload: data,
  };
};
//for clearing the props
export const clearSalesOrderHeader = (data) => {
  return {
    type: CLEAR_SALES_ORDER_HEADER,
    payload: data,
  };
};
export const ClearSOJuteGovtHeaders = (data) => {
  return {
    type: CLEAR_SO_JUTEGOVT_HEADERS,
    payload: data,
  };
};
export const ClearSOJuteYarnHeaders = (data) => {
  return {
    type: CLEAR_SO_JUTEYARN_HEADERS,
    payload: data,
  };
};
export const ClearSOJuteYarnLineItems = (data) => {
  return {
    type: CLEAR_SO_JUTEYARN_LINE_ITEMS,
    payload: data,
  };
};
export const ClearSOJuteHessianHeaders = (data) => {
  return {
    type: CLEAR_SO_JUTEHESSIAN_HEADERS,
    payload: data,
  };
};
export const ClearSalesOrderLineItems = (data) => {
  return {
    type: CLEAR_SALES_ORDER_LINEITEMS,
    payload: data,
  };
};
export const ClearSOJuteGovtLineItems = (data) => {
  return {
    type: CLEAR_SO_JUTEGOVT_LINE_ITEMS,
    payload: data,
  };
};
export const ClearSOJuteHessianLineItems = (data) => {
  return {
    type: CLEAR_SO_JUTEHESSIAN_LINE_ITEMS,
    payload: data,
  };
};
export const getSalesOrderItemsByItemGroupId = (url, data, history, rowId) => {
  return {
    type: SALES_ORDER_ITEMS_BY_ITEM_GROUP_ID,
    payload: { url, data, history, rowId },
  };
};
export const getSalesOrderItemsByItemGroupIdSuccessfull = (data) => {
  return {
    type: SALES_ORDER_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL,
    payload: data,
  };
};
export const getItemsByItemGroupIdForJuteYarn = (url, data, history,rowId) => {
  return {
    type: ITEMS_BY_ITEM_GROUP_ID_FOR_JUTEYARN,
    payload: { url, data, history,rowId },
  };
};
export const getItemsByItemGroupIdForJuteYarnSuccessfull = (data) => {
  return {
    type: ITEMS_BY_ITEM_GROUP_ID_FOR_JUTEYARN_SUCCESSFULL,
    payload: data,
  };
};

// for Jute Govt
export const getItemsByItemGroupIdForJuteGovt = (url, data, history,rowId) => {
  return {
    type: ITEMS_BY_ITEM_GROUP_ID_FOR_JUTEGOVT,
    payload: { url, data, history,rowId },
  };
};


export const getSoYarnUomList = (url, rowId,uom) => {
  return {
    type: SO_JUTE_YARN_UOM_LIST,
    payload: { url,rowId,uom },
  };
};
export const getSoYarnUomListSuccessfull = (data) => {
  return {
    type: SO_JUTE_YARN_UOM_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getRegularSoItemNameData = (url, data,rowId) => {
  return {
    type: REGULAR_SO_ITEM_NAME_DATA,
    payload: { url, data,rowId },
  };
};
export const getRegularSoItemNameDataSuccessfull = (data) => {
  return {
    type: REGULAR_SO_ITEM_NAME_DATA_SUCCESSFULL,
    payload: data,
  };
};

export const getJuteGovtSoItemNameData = (url, data,rowId) => {
  return {
    type: JUTE_GOVT_SO_ITEM_NAME_DATA,
    payload: { url, data,rowId },
  };
};
export const getJuteGovtSoItemNameDataSuccessfull = (data) => {
  return {
    type: JUTE_GOVT_SO_ITEM_NAME_DATA_SUCCESSFULL,
    payload: data,
  };
};
export const getJuteYarnSoItemNameData = (url, data,rowId) => {
  return {
    type: JUTE_YARN_SO_ITEM_NAME_DATA,
    payload: { url, data,rowId },
  };
};
export const getJuteYarnSoItemNameDataSuccessfull = (data) => {
  return {
    type: JUTE_GOVT_SO_ITEM_NAME_DATA_SUCCESSFULL,
    payload: data,
  };
};
export const getJuteHessianSoItemNameData = (url, data,rowId) => {
  return {
    type: JUTE_HESSIAN_SO_ITEM_NAME_DATA,
    payload: { url, data,rowId },
  };
};
export const getJuteHessianSoItemNameDataSuccessfull = (data) => {
  return {
    type: JUTE_HESSIAN_SO_ITEM_NAME_DATA_SUCCESSFULL,
    payload: data,
  };
};
export const apiErrors = (error) => {
  return {
    type: API_ERRORS,
    payload: error,
  };
};
