import {
  MACHINE_MASTER_LIST,
  MACHINE_MASTER_LIST_SUCCESSFULL,
  CREATE_MACHINE_MASTER_SUCCESSFULL,
  CREATE_MACHINE_MASTER,
  UPDATE_MACHINE_MASTER,
  UPDATE_MACHINE_MASTER_SUCCESSFULL,
  API_ERRORS,
  GET_MACHINE_VIEW_BY_ID,
  GET_MACHINE_VIEW_BY_ID_SUCCESSFULL
} from "./actionTypes";

export const machineMasterList = (url, data, history) => {
  return {
    type: MACHINE_MASTER_LIST,
    payload: { url, data, history },
  };
};
export const machineMasterListSuccessfull = (data) => {
  return {
    type: MACHINE_MASTER_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const createMachineMaster = (url, data, history) => {
  return {
    type: CREATE_MACHINE_MASTER,
    payload: { url, data, history },
  };
};

export const createMachineMasterSuccessfull = (data) => {
  return {
    type: CREATE_MACHINE_MASTER_SUCCESSFULL,
    payload: data,
  };
};
export const updateMachineMaster = (url, data, history) => {
  return {
    type: UPDATE_MACHINE_MASTER,
    payload: { url, data, history },
  };
};

export const updateMachineMasterSuccessfull = (data) => {
  return {
    type: UPDATE_MACHINE_MASTER_SUCCESSFULL,
    payload: data,
  };
};

export const getMachineViewById = (url,history) => {
    return {
      type: GET_MACHINE_VIEW_BY_ID,
      payload: { url, history },
    };
  };
  
  export const getMachineViewByIdSuccessfull = (data) => {
    return {
      type: GET_MACHINE_VIEW_BY_ID_SUCCESSFULL,
      payload: data,
    };
  };
export const apiErrors = (error) => {
  return {
    type: API_ERRORS,
    payload: error,
  };
};
