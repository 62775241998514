import { all } from 'redux-saga/effects'
import LayoutSaga from './layout/saga';
import loginSaga from './auth/login/saga';
import forgotPassword from './auth/forgetpwd/saga';

import IndentSaga from './Purchase/Indent/saga';
import BasicDetailsSaga from './Company/BasicDetails/saga';
import PriceEnquirySaga from './Purchase/PriceEnquiry/saga';
import WorkOrderSaga from './Purchase/WorkOrder/saga';
import PrResponseSaga from './Purchase/PR_Response/saga';
import PurchaseOrderSaga from './Purchase/PurchaseOrder/saga';
import InwardSaga from './Purchase/Inward/saga';
import DebitCreditSaga from './Purchase/Debit-credit-note/saga';
import BillPassSaga from './Purchase/BillPass/saga';
import TaxMasterListSaga from './Master/TaxMaster/saga';
import SupplierListSaga from './Master/SupplierMaster/saga';
import addItemToTaxListSaga from './Master/AddItemToTaxList/saga'
import DepartmentListSaga from './Master/DepartmentMaster/saga'
import SubDepartmentListSaga from './Master/SubDepartmentMaster/saga'
import LedgerListSaga from './Master/LedgerMaster/saga'
import LedgerGroupsSaga from './Master/LedgerGroupsMaster/saga'
import TermsListSaga from './Master/Terms/saga'
import TransporterListSaga from './Master/TransporterMaster/saga'
import BankAccountListSaga from './Master/BankAccountMaster/saga';
import DesignationListSaga from './Master/DesignationMaster/saga';
import CostCenterListSaga from './Master/CostCenterMaster/saga';
import LeaveTypeListSaga from './Master/LeaveTypeMaster/saga';
import WareHouseListSaga from './Master/WareHouseMaster/saga';
import CustomerMasterListSaga from './Master/CustomerMaster/saga';
import ItemMasterListSaga from './Master/ItemMaster/saga';
import ItemGroupMasterListSaga from './Master/ItemGroupMaster/saga';
import UomMasterListSaga from './Master/UomMaster/saga';
import CreateLeaveTypeSaga from './Master/LeaveTypeMaster/saga'
import TravelCatageriesSaga from './Master/TravelCatageries/saga';
import VehicleTypesMasterSaga from './Master/VehicleTypesMaster/saga';
import WeavingQualityMasterSaga from './Master/WeavingQualityMaster/saga';
import MukamMasterSaga from './Master/MukamMaster/saga'
import QualityMasterSaga from './Master/QualityMaster/saga';
import BranchMasterSaga from './Master/BranchMaster/saga';
import BrokerMasterSaga from './Master/BrokerMaster/saga';
import BudgetHeadsSaga from './Master/BudgetHeads/saga';
import WorkerCategorySaga from './Master/WorkerCategoryMaster/saga';
import MachineMasterSaga from './Master/MachineMaster/saga';
import SpinningQualitySaga from './Master/SpinningQualityMaster/saga';
import AgentMasterSaga from './Master/AgentMaster/saga';
import YarnMasterSaga from './Master/YarnMaster/saga'
import CompanyMasterSaga from './Master/CompanyMaster/saga';
import CarriersSaga from './Master/Carriers/saga';
import EmployeeSaga from "./HRMS/EmployeeDataBase/saga";
import PayslipSaga from './HRMS/PaySlip/saga';
import PayRegisterSaga from './HRMS/PayRegister/saga';
import AttendanceSaga from './HRMS/AttendanceCalender/saga';
import LeaveRequestSaga from './HRMS/LeaveRequest/saga';
import SpellMasterListSaga from './Master/SpellMaster/saga';
import LeftSideMenuSaga from './Company/BasicDetails/LeftSideBar/saga';
import AcadamicYearsSaga from './auth/FinancialYear/saga';
import UploadFileListSaga from './MyProfile/UploadFile/saga';
import PayRollDataSaga from './HRMS/PayRollData/saga';
import PaySchemeSaga from './HRMS/paySchemeCreation/saga'
import ExpenseBookingSaga from './HRMS/ExpenseBooking/saga';
import AdvanceRequestSaga from './HRMS/AdvanceRequest/saga'
import DropDownListSaga from './Global/DropDownApis/saga';
import HolidayListSaga from './Master/HolidayCalendar/saga';

// Master dropdown 
import MasterDropDownListSaga from './MasterDropdownApis/saga';

// import ProjectListSaga from './Global/DropDownApis/saga';
// import StatusListSaga from './Global/DropDownApis/saga';
// import EmployeeCategoryListSaga from './Global/DropDownApis/saga'
// import SupplierlistSaga from './Global/DropDownApis/saga';
// import ItemGroupListSaga from './Global/DropDownApis/saga';
// import CategoryTypeListSaga from './Global/DropDownApis/saga';
// import CustomerListSaga from './Global/DropDownApis/saga';
// import BillingShippingSaga from './Global/DropDownApis/saga';
// import ItemGroupMasterList from './Global/DropDownApis/saga';
// import ItemsByItmGrpIdList from './Global/DropDownApis/saga';
// import taxListSaga from './Global/DropDownApis/saga';
// import uomListSaga from './Global/DropDownApis/saga';
// import ItemsListForIndent from './Global/DropDownApis/saga';
// import PendingStockSaga from './Global/DropDownApis/saga';
// import PendingStockPoandReceipt from './Global/DropDownApis/saga';
// import SupplierListBy_suppId from './Global/DropDownApis/saga';
// import BillingaAdsShippingAds from './Global/DropDownApis/saga';
// import BudgetHeadsaga from './Global/DropDownApis/saga';
// import ItemGrpMasterListsaga from './Global/DropDownApis/saga';
// import BoqListsaga from './Global/DropDownApis/saga'
// import ApprovedIndents from './Global/DropDownApis/saga';
// import AllItemGroupMasterListSaga from './Global/DropDownApis/saga'
import  ShiftMasterSaga from './Master/ShiftMaster/saga'
/**Store Module**/
import MaterialRequestlistSaga from './StoreModule/MaterialRequest/saga';
import ConsumptionlistSaga from './StoreModule/Consumption/saga';
import StockTransferlistSaga from './StoreModule/StockTransfer/saga';
import StockReturnlistSaga from './StoreModule/StockReturn/saga';
//sales module//
import QuotationSaga from './Sales/Qutation/saga';
import SalesOrderSaga from './Sales/SalesOrder/saga';
import DeliveryOrderSaga from './Sales/DeliveryOrder/saga';
import InvoiceSaga from './Sales/InVoice/saga'
import ReOccuringInvoiceSaga from './Sales/ReOccuringInVoice/saga'
// Accounting 
import BillsSaga from './Accounting/Bills/saga';
import PaymentSaga from './Accounting/Payments/saga';
// Jute Module
import JuteIndentSaga from './Jute/JuteIndent/saga';
import JuteGateEnrtySaga from './Jute/JuteGateEntry/saga';
import JutePoSaga from './Jute/JutePurchase/saga';
import JuteQualityCheckData from './Jute/JuteQualityCheck/saga';
import JuteMaterialReceiptSaga from './Jute/JuteMaterialReceipt/saga';
import JuteBillPassSaga from './Jute/JuteBillPass/saga';
import JuteMarketRateSaga from './Jute/JuteMarketRate/saga';
import JuteIssueSaga from './Jute/JuteIssue/saga';
import JuteConversionSaga from './Jute/JuteConversion/saga';
import JuteBatchPlanningSaga from './Jute/JuteBatchPlanning/saga';
// Jute Production
import FinishingEntrySagaList from './JuteProduction/FinishingEntry/saga';
import weavingProductionListSaga from './JuteProduction/WeavingProduction/saga';
import beamingProductionListSaga from './JuteProduction/BeamingProduction/saga';
import windingDoffSaga from './JuteProduction/WindingDoffEntry/saga';
import spinningDoffSaga from './JuteProduction/SpinningDoffEntry/saga';
import CutsAndJugarSaga from './JuteProduction/CutsAndJugarEntry/saga';
import wastageEntrySaga from './JuteProduction/WastageEntry/saga';
import GstSaga from './GST_module/InvoiceCredentials/saga'

export default function* rootSaga() {
    yield all([
        
        //public
        // accountSaga(),
        LayoutSaga(),
        loginSaga(),
        forgotPassword(),
        IndentSaga(),
        BasicDetailsSaga(),
        PriceEnquirySaga(),
        WorkOrderSaga(),
        // CreateWorkOrderSaga(),
        PrResponseSaga(),
        PurchaseOrderSaga(),
        InwardSaga(),
        DebitCreditSaga(),
        BillPassSaga(),
        TaxMasterListSaga(),
        SupplierListSaga(),
        addItemToTaxListSaga(),
        EmployeeSaga(),
        PayslipSaga(),
        PayRegisterSaga(),
        LeaveRequestSaga(),
        LeftSideMenuSaga(),
        AcadamicYearsSaga(),
        SpellMasterListSaga (),
        UploadFileListSaga(),
        PayRollDataSaga(),
        PaySchemeSaga(),
        ExpenseBookingSaga(),
        AdvanceRequestSaga(),
        DropDownListSaga(),
        // ProjectListSaga(),
        // StatusListSaga(),
        // SupplierlistSaga(),
        // ItemGroupListSaga(),
        // CategoryTypeListSaga(),
        MaterialRequestlistSaga(),
        ConsumptionlistSaga(),
        StockTransferlistSaga(),
        StockReturnlistSaga(),
        QuotationSaga(),
        SalesOrderSaga(),
        DeliveryOrderSaga(),
        ReOccuringInvoiceSaga(),
        InvoiceSaga(),
        GstSaga(),
        BillsSaga(),
        PaymentSaga(),
        // CustomerListSaga(),
        // BillingShippingSaga(),
        // ItemGroupMasterList(),
        // ItemsByItmGrpIdList(),
        // taxListSaga(),
        // uomListSaga(),
        BankAccountListSaga(),
        DesignationListSaga(),
        CostCenterListSaga(),
        DepartmentListSaga(),
        SubDepartmentListSaga(),
        AttendanceSaga(),
        // ItemsListForIndent(),
        // PendingStockSaga(),
        // PendingStockPoandReceipt(),
        LedgerListSaga(),
        LedgerGroupsSaga(),
        TermsListSaga(),
        TransporterListSaga(),
        // SupplierListBy_suppId(),
        // BillingaAdsShippingAds(),
        // BudgetHeadsaga(),
        // ItemGrpMasterListsaga(),
        // BoqListsaga(),
        // ApprovedIndents(),
        // AllItemGroupMasterListSaga(),
        BudgetHeadsSaga(),
        ShiftMasterSaga(),
        LeaveTypeListSaga(),
        CustomerMasterListSaga(),
        ItemMasterListSaga(),
        ItemGroupMasterListSaga(),
        UomMasterListSaga(),
        CreateLeaveTypeSaga(),
        WareHouseListSaga(),
        TravelCatageriesSaga(),
        // EmployeeCategoryListSaga(),
        WorkerCategorySaga(),
        VehicleTypesMasterSaga(),
        WeavingQualityMasterSaga(),
        MukamMasterSaga(),
        QualityMasterSaga(),
        BrokerMasterSaga(),
        MachineMasterSaga(),
        SpinningQualitySaga(),
        YarnMasterSaga(),
        CompanyMasterSaga(),
        BranchMasterSaga(),
        AgentMasterSaga(),
        MasterDropDownListSaga(),
        HolidayListSaga(),
        CarriersSaga(),
        JuteIndentSaga(),
        JutePoSaga(),
        JuteGateEnrtySaga(),
        JuteQualityCheckData(),
        JuteMaterialReceiptSaga(),
        JuteBillPassSaga(),
        JuteMarketRateSaga(),
        JuteIssueSaga(),
        JuteConversionSaga(),
        JuteBatchPlanningSaga(),
        FinishingEntrySagaList(),
        weavingProductionListSaga(),
        beamingProductionListSaga(),
        windingDoffSaga(),
        spinningDoffSaga(),
        CutsAndJugarSaga(),
        wastageEntrySaga(),
    ])
}