import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { serverApi } from "../../../helpers/Consts";
import Gridwithcustomview from "../../../components/Grid/Gridwithcustomview";
import { getPayScheme } from "../../../store/HRMS/EmployeeDataBase/actions";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      AllPayschemeList: [],
    };
  }

  componentDidMount() {
    const data = {
      activeStatus: "",
      status: "",
    };
    this.props.getPayScheme(
      serverApi.EMPLOYEE_PAY_SCHEME,
      data,
      this.props.history
    );
  }
  componentWillReceiveProps(props) {
    if (props.payScheme_List) {
      this.setState({
        AllPayschemeList: props.payScheme_List,
      });
    }
  }

  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.AllPayschemeList}
          mcolumns={payschemeListColumn}
          micon={GridArrowIcon}
          status={true}
          actions={true}
          filter_form={"Pay Scheme List"}
          url="/create_payScheme_creation"
        />
      </>
    );
  }
}

const payschemeListColumn = [
  {
    Header: "Pay Scheme Id",
    accessor: "id",
    hidden: true,
  },
  {
    Header: "Pay Scheme Code",
    accessor: "code",
    hidden: false,
  },

  {
    Header: "Pay Scheme Name",
    accessor: "name",
    hidden: false,
  },

  {
    Header: "Wage Type",
    accessor: "wageDesc",
    hidden: false,
  },
  {
    Header: "Description",
    accessor: "description",
    hidden: false,
  },
  {
    Header: "Status",
    accessor: "statusDesc",
    hidden: false,
  },
  {
    Header: "Approver Status",
    accessor: "approverStatus",
    hidden: true,
  },
  {
    Header: "Branch Id",
    accessor: "branchid",
    hidden: true,
  },
  {
    Header: "Last Update",
    accessor: "lupdate",
    hidden: true,
  },
  {
    Header: "Laast update Desc",
    accessor: "lupdateDesc",
    hidden: true,
  },
  {
    Header: "Status Code",
    accessor: "status",
    hidden: true,
  },
  {
    Header: "Active Status",
    accessor: "activeStatus",
    hidden: true,
  },
  {
    Header: "Wage Id",
    accessor: "wageId",
    hidden: true,
  },
  {
    Header: "Business Unit Id",
    accessor: "businessunitId",
    hidden: true,
  },

  {
    Header: "Created By",
    accessor: "createdBy",
    hidden: true,
  },
  {
    Header: "Create Date",
    accessor: "createDate",
    hidden: true,
  },

  {
    Header: "Modified By",
    accessor: "modifiedBy",
    hidden: true,
  },
  {
    Header: "Modified Date",
    accessor: "modifiedDate",
    hidden: true,
  },

  {
    Header: "Designation",
    accessor: "designation",
    hidden: true,
  },
];
const mapStatetoProps = (state) => {
  const { payScheme_List } = state.EmployeeReducer;
  return { payScheme_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getPayScheme,
  })(Index)
);
