import React, { Component } from "react";
import {
  Box,
  Grid,
  Button,
  Tooltip,
  IconButton,} from "@mui/material";
import DynamicSelect from "../../StoreModule/MaterialRequest/DynamicSelect";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Fade from "@mui/material/Fade";
import CustomButton from "../../../components/Buttons/Buttons";
import panUploadIcon from "../../../assets/images/panUploadIcon.png";
import { serverApi, serverConfig,serverVars } from "../../../helpers/Consts";
import { getFileDownload } from "../../../helpers/server.js";
import {
  getPendingStockPoandReceipt,
  getPendingStock,
  actions,
  logs,
  getFiles,
  getFilesSuccessfull,
  getItemsListforIndent,
  getItemGroupListforindent,
} from "../../../store/Purchase/Indent/actions";
import {
  postUploadFileList,
  deleteFile,
} from "../../../store/MyProfile/UploadFile/actions";
import {
  BudgetHeads,
  getDepartmentList,
  allItemGroupMasterList,
  getTax_List,
  getItemsListForIndent,
  getTermsConditions,
} from "../../../store/Global/DropDownApis/actions";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import DeleteIcons from "../../../assets/images/picEditIcon.png";
import TickIcon from "../../../assets/images/fileUploadedTick.png";
import swal from "sweetalert";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import DeleteIcon from "@mui/icons-material/Delete";
import { POWOLineItemsProps } from "../../../store/Purchase/PurchaseOrder/actions";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { QtyCalculation } from "../../../Utilities/QtyCalculation";
import ReactTooltip from "react-tooltip";
import { InvoiceSection } from "../../../components/InvoiceDetailsSection";
import { limitDecimals } from "../../../Utilities/helper";
import {getItemListByItemGroupForPurchase,getItemDetailsByItemItemGrpForPurchase} from "../../../store/Purchase/PurchaseOrder/actions.js"
class POLineItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCount: 0,
      itemsList: [],
      userDit: JSON.parse(localStorage.getItem("authUser")),
      taxList: [],
      isChecked: true,
      DepartmentList: [],
      ItemGroupMasterList: [],
      advancePercentage: this.props.PoFillDetails.advancePercentage,
      advanceAmount: this.props.PoFillDetails.advanceAmount,
      termsNconditionsList: [],
      termsNconditions: this.props.PoFillDetails.termsNconditions,
      selectTermsNconditions: this.props.PoFillDetails.selectTermsNconditions,
      budgetHeads_list: [],
      poDetails: [],
      files_List: [],
      totalAmount: this.props.PoFillDetails.totalAmount,
      totalIgst: this.props.PoFillDetails.totalIgst,
      totalCgst: this.props.PoFillDetails.totalCgst,
      totalSgst: this.props.PoFillDetails.totalSgst,
      netTotal: this.props.PoFillDetails.netTotal,
      lineitemId: 1,
      uploadFile: false,
      seeLogs: false,
      othercharges: false,
      otherChargesItemList: [],
      deptDisplayTxtField: {},
      deptDisplayDropField: {},
    };
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);
    let source = "";
    if (this.props.location.pathname === "/create_purchase_order") {
      source = "/PO";
    } else {
      source = "/WO";
    }
    this.props.getTermsConditions(
      serverApi.TERMS_AND_CONDITIONS_DATA +
        localStorage.getItem("companyId") +
        source,
      this.props.history
    );

    if (this.props.po_wo_lineitems) {
      const indentTypeId = this.props.PoFillDetails.category_type_value;
    }
    if (
      this.props.po_wo_lineitems &&
      this.props.po_wo_lineitems !== undefined
    ) {
    } else {
      let poDetails = [
        {
          budgetHeadId: null,
          ctaxPercentage: 0.0,
          departmentId: "",
          description: null,
          discountMode: "",
          discountPercentage: 0.0,
          hsnCode: null,
          id: 1,
          indentDetailsId: null,
          indentId: null,
          installationAmount: 0.0,
          installationRate: 0.0,
          isActive: 1,
          iTaxPercentage: 0.0,
          itemId: "",
          make: "",
          oldQty: 0.0,
          qohCompany: null,
          qty: 0.0,
          rate: 0.0,
          remarks: "",
          staxPercentage: 0.0,
          tax: 0.0,
          taxId: 0,
          taxPercentage: null,
          uomCode: null,
          amount: 0.0,
        },
      ];
      this.props.POWOLineItemsProps(poDetails);
    }

    if (this.props.Upload_File_List) {
      if (this.props.location.state) {
        let id = this.props.location.state.rowData.id;
        this.props.getFiles(
          serverApi.GET_FILES +
            "14/" +
            id +
            "/" +
            localStorage.getItem("companyId"),
          this.props.history
        );
      }
    }
  }

  componentWillReceiveProps(props) {
    if (props.itemsListForIndent) {
      let list = [];
      if (this.state.othercharges === false) {
        let itemsList = props.itemsListForIndent.filter((data) => {
          if (data.value !== "0") {
            return data;
          }
        });
        itemsList.map((prop) => {
          list.push({
            label: prop.label,
            name: prop.name,
            value: prop.value,
            variant: prop.itemCode,
          });
        });
        this.setState({
          itemsList: list,
        });
      } else {
        let itemsList = props.itemsListForIndent.filter((data) => {
          if (data.value !== "0") {
            return data;
          }
        });
        itemsList.map((prop) => {
          list.push({
            label: prop.label,
            name: prop.name,
            value: prop.value,
            variant: prop.itemCode,
          });
        });
        this.setState({
          otherChargesItemList: list,
        });
      }
    }
    if (props.taxList) {
      if (props.taxList.data) {
        let list = props.taxList.data.filter((data) => {
          if (data.value !== 0) {
            return data;
          }
        });
        this.setState({
          taxList: list,
        });
      }
    }

    if (props.budgetHeadsList) {
      if (props.budgetHeadsList.data) {
        let list = props.budgetHeadsList.data.filter((data) => {
          if (data.value !== "0") {
            return data;
          }
        });
        this.setState({
          budgetHeads_list: list,
        });
      }
    }

    if (props.departmentList) {
      this.setState({
        DepartmentList: props.departmentList.data,
      });
    }

    if (props.allItemGroupMaster_List) {
      if (props.allItemGroupMaster_List.data) {
        let list = props.allItemGroupMaster_List.data.filter((data) => {
          if (data.value !== "0") {
            return data;
          }
        });
        this.setState({
          ItemGroupMasterList: list,
        });
      }
    }

    if (props.po_wo_lineitems) {
      this.setState({
        poDetails: props.po_wo_lineitems,
      });
    }

    if (props.TermsConditions) {
      if (props.TermsConditions.length !== 0) {
        var TandSlist = [];
        props.TermsConditions.map((item) => {
          TandSlist.push({
            value: item.autoId,
            label: item.termsAndConditions,
            name: item.termsAndConditions,
          });
        });
      }
      this.setState({
        termsNconditionsList: TandSlist,
      });
    }
    if (this.state.seeLogs === true) {
      if (props.logs_List) {
        if (props.logs_List.data.length !== 0) {
          this.setState({
            Logs: props.logs_List.data,
          });
        } else {
          swal("No logs..!!");
          this.setState({
            Logs: [],
          });
        }
      }
    }
    if (this.state.seeLogs === false) {
      if (props.files_List) {
        if (props.files_List.data) {
          let filesList = props.files_List.data;
          this.setState({
            files_List: filesList.data,
          });
        }
      }
    }
    if (this.state.uploadFile === true) {
      if (this.state.fileName !== "") {
        if (props.Upload_File_List) {
          var Filedata = props.Upload_File_List.data;
          if (props.Upload_File_List.status) {
            this.setState({
              uploadFile: false,
            });
            if (this.state.files_List !== undefined) {
              var arr = this.state.files_List;
              Filedata = {
                fileUploadId: Filedata.id,
                fileName: this.state.fileName,
                fileExtension: this.state.fileType,
                retrievalPath: Filedata.fileUrl,
                sourceMenu: 2,
              };
              arr.splice(arr.length, 0, Filedata);
              this.setState({
                files_List: arr,
              });
            }
          }
        }
      }
    }
    if (props.logs_List) {
      this.setState({
        Logs: props.logs_List.data,
      });
    }

    if (props.PendingStock) {
      if (props.PendingStock.data !== null) {
        if (props.PendingStock.id !== undefined) {
          let poDetails = this.state.poDetails.filter((rowItem) => {
            if (rowItem.id !== 0 && props.PendingStock.id == rowItem.itemId) {
              rowItem.qohBranch = props.PendingStock.data;
            }
            return rowItem;
          });
        }
      }
    }

    if (props.pendingStockPoandReceipt) {
      if (props.pendingStockPoandReceipt.data !== null) {
        let poDetails = this.state.poDetails.filter((rowItem) => {
          if (props.pendingStockPoandReceipt.id == rowItem.itemId) {
            rowItem.qohCompany = props.pendingStockPoandReceipt.data;
          }
          return rowItem;
        });
      }
    }
  }

  onhandleChange = (key) => (value) => {
    this.setState({ [key]: value });
    if (key === "advance_per") {
      let valuedata = value
      if (valuedata >= 0 && valuedata <= 99) {
        if (!valuedata.match(/^\d{1,3}\.\d{4}$/)) {
          this.setState({
            advancePercentage: valuedata,
          });
          this.props.PoFillDetails.advancePercentage = valuedata;
          let totalAmount = this.props.PoFillDetails.totalAmount;
          let advanceAmount = parseFloat((totalAmount * valuedata) / 100).toFixed(2);
          this.setState({
            advanceAmount: Number(advanceAmount),
          });
          this.props.PoFillDetails.advanceAmount = Number(advanceAmount);
        } else {
          swal("Can't Enter more than 3 Decimals!");
        }
      } else {
        swal("Advance Percentage should not be more than 100%");
      }
    }
    if (key === "advance_amt") {
      if (this.state.advanceAmount) {
        if (this.state.advanceAmount !== "") {
          this.props.PoFillDetails.advanceAmount = value;
          this.setState({
            advanceAmount: value,
          });
        }
      }
    }
    if (key === "term&Con") {
      this.props.PoFillDetails.selectTermsNconditions = value;
      this.setState({
        selectTermsNconditions: value,
      });
    }
  };
  handleBlur = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    this.setState({ [key]: event });
    let mname = key.split("_");
    if (mname[0] === "qty") {
      // if (Number(value) >= 0) {
      if (!value.match(/^\d{1,3}\.\d{4}$/)) {
        let poDetails = this.state.poDetails.filter((Val) => {
          if (Val.id === row.id) {
            let remainqty =
              row.remainingQuantity === undefined
                ? Number(
                    row.oldQty === undefined
                      ? row.remainingQuantity
                      : row.oldQty
                  )
                : Number(row.remainingQuantity);
            let indentqty = Number(
              row.oldQty === undefined ? row.remainingQuantity : row.oldQty
            );
            let qty = Number(value);
            let minqty = row.minStock === undefined ? 0 : Number(row.minStock);
            let maxqty = row.maxStock === undefined ? 0 : Number(row.maxStock);
            let branchqty = Number(row.qohBranch);
            let centralqty = Number(row.qohCompany);
            let poqty = Number(value);
            let type = "CASE2";
            let updatetype = this.props.location.state !== undefined ? 0 : 1;
            let reOrderQty =
              row.reOrderQty === undefined ? 0 : Number(row.reOrderQty);
            let resultQty = QtyCalculation({
              qty,
              branchqty,
              centralqty,
              poqty,
              updatetype,
              type,
              remainqty,
              indentqty,
              minqty,
              maxqty,
              reOrderQty,
            });
            if (resultQty.error === true) {
              swal(resultQty.message);
              row.qty = parseFloat(resultQty.value).toFixed(3);
            }
          }
          return Val;
        });
        this.setState({
          poDetails: poDetails,
        });
        this.props.POWOLineItemsProps(poDetails);
      }
      // }
    }
  };
  handleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    this.setState({ [key]: event });
    let mname = key.split("_");
    if (mname[0] === "Item Rate") {
      if (!value.match(/^\d{1,3}\.\d{4}$/)) {
        row.rate = value;
        row.amount = Number(row.rate) * Number(row.qty);
        this.setState({
          totalAmount: Number(row.rate) * Number(row.qty),
        });
        this.props.PoFillDetails.totalAmount =
          Number(row.rate) * Number(row.qty);
        let poDetails = this.state.poDetails.filter((Val) => {
          if (Val.id === row.id) {
            row.rate = value;
            row.amount = row.rate * value;
          }
          return Val;
        });
        this.setState({
          poDetails: poDetails,
        });
        this.props.POWOLineItemsProps(poDetails);
      } else {
        swal("Can't Enter more than 3 Decimals!");
      }
    }

    if (mname[0] === "qty") {
      if (Number(value) >= 0) {
        if (!value.match(/^\d{1,3}\.\d{4}$/)) {
          let poDetails = this.state.poDetails.filter((Val) => {
            if (Val.id === row.id) {
              row.qty = value;
              row.amount = row.rate * value;
            }
            return Val;
          });
          this.setState({
            poDetails: poDetails,
          });
          this.props.POWOLineItemsProps(poDetails);
        } else {
          swal("Can't Enter more than 3 Decimals!");
        }
      }
    }

    if (mname[0] === "discountPercentage") {
      if (value < 0) {
        swal("Discount value Shouldn't be less than 0");
        row.discountPercentage = 0;
      } else {
        if (row.discountMode === 1) {
          if (!value.match(/^\d{1,3}\.\d{4}$/)) {
            row.discountPercentage = value;
            let totalAmt = row.qty * row.rate;
            let discountAmt = totalAmt - Number(value);
            if (value > totalAmt) {
              swal("Discount amount can't be greater than Total Amount");
              row.amount = totalAmt;
              row.discountPercentage = 0;
            } else {
              row.amount = discountAmt;
              this.setState({
                totalAmount: row.amount,
              });
              this.props.PoFillDetails.totalAmount = row.amount;
            }
          } else {
            swal("Can't Enter more than 3 Decimals!");
          }
        } else {
          if (value > 100) {
            swal("Discount value Shouldn't be greater than 100");
            row.discountPercentage = 0;
          } else {
            if (!value.match(/^\d{1,3}\.\d{4}$/)) {
              row.discountPercentage = value;
              let totalAmt = row.qty * row.rate;
              let discountAmt = (totalAmt * Number(value)) / 100;
              row.amount = totalAmt - discountAmt;
              this.setState({
                totalAmount: row.amount,
              });
              this.props.PoFillDetails.totalAmount = row.amount;
            } else {
              swal("Can't Enter more than 3 Decimals!");
            }
          }
        }
      }
    }
    if (mname[0] === "HSN/SAC") {
      row.hsnCode = value;
    }
    if (mname[0] === "remarks") {
      row.remarks = value;
    }
    if (mname[0] === "make") {
      row.make = value;
    }
    this.props.POWOLineItemsProps(this.state.poDetails);

    if (mname[0] === "installationRate") {
      if (!value.match(/^\d{1,3}\.\d{4}$/)) {
        let poDetails = this.state.poDetails.filter((rowItem) => {
          if (rowItem.id === row.id) {
            rowItem.installationRate = value;
            rowItem.installationAmount = value * Number(rowItem.qty);
            
          }
          return rowItem;
        });
        this.setState({
          poDetails: poDetails,
        });
        this.props.POWOLineItemsProps(poDetails);
      } else {
        swal("Can't Enter more than 3 Decimals!");
      }
    }
  };


  getItemDetails = (response, itemId)=>{
    if (itemId !== undefined) {
      if (response) {
        let poDetails = this.state.poDetails.filter((row) => {
          if (row.itemId === itemId ) {
            let itemDetails = response.data
              if (itemDetails.itemId === row.itemId) {
                row.minStock = itemDetails.minQty
                row.maxStock = itemDetails.maxQty
                row.maxAllowedQty = itemDetails.maxAllowableQty
                row.minAllowedQty = itemDetails.minAllowableQty
                row.reOrderQty = itemDetails.reorderQty
                row.uom = itemDetails.uomCode
                row.rate = itemDetails.srRate
                row.remarks = "";
                row.qohCompany = row.centralStock
                // if (this.props.location.pathname === "/create_openIndent") {
                //   row.qty = 0;
                // } else {
                //   row.qty = itemDetails.minQty !== 0 && itemDetails.minQty !== 0 ? 0 : row.qty;
                // }
              }
          }
          return row;
        });
        this.setState({
          poDetails: poDetails,
        });
        this.props.POWOLineItemsProps(poDetails);
      }
      // return this.state.itemsList
    }
  }


  handleChangedropdown = (
    selectedValue,
    selectedName,
    name,
    selectedRow,
    selectedItemCode
  ) => {
    if (name === "budgetHeadId") {
      this.props.getItemGroupListforindent(
        serverApi.GET_ITEMGROUP_LIST +
          selectedValue +
          "/" +
          localStorage.getItem("companyId") +
          "/getAllItemGroupsByDepartmentId/" +
          this.state.userDit.cipher,
        this.props.history,
        this.getitemListByitemgrpId,
        selectedValue
      );
      this.displayField(selectedRow, "budgetHeadId", 2);
      var polineitems = this.state.poDetails.filter((row) => {
        if (row.id === selectedRow.id) {
          selectedRow.budgetHeadId = selectedValue;
          selectedRow.budgetHeadName = selectedName;
        }
        return row;
      });
      this.setState({
        poDetails: polineitems,
        lineItemId: selectedRow.lineItemId,
      });
      this.props.POWOLineItemsProps(polineitems);
    }
    if (name === "itemGroup") {
      this.displayField(selectedRow, "itemGroup", 2);
      selectedRow.itemGroup = selectedValue;
      this.props.PoFillDetails.itemGroup_Value = selectedValue;
      selectedRow.itemGroupId = selectedValue;
      const indentTypeId = this.props.PoFillDetails.category_type_value;
      // const data = {
      //   companyId: localStorage.getItem("companyId"),
      //   consumable: "",
      //   indentTypeId: indentTypeId,
      //   itemGroupId: selectedValue,
      //   saleable: "",
      //   tangible:
      //     this.props.location.pathname === "/create_purchase_order" ? "Y" : "N",
      //     branchId: this.props.PoFillDetails.branchValue,

      // };

     
      // this.props.getItemsListForIndent(
      //   serverApi.GET_ITEMSBY_FORPROCUREMENT,
      //   data,
      //   this.props.history
      // );
      const data = {
        companyId: localStorage.getItem("companyId"),
        itemGroupId: selectedValue,
      };
      this.props.getItemListByItemGroupForPurchase(
        serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
        data,
        this.getitemListByitemgrpId,
        selectedRow.itemGroupId
      );
    }
    if (name === "itemId") {
      const data = {
        itemGroupId: selectedRow.itemGroupId,
        companyId: localStorage.getItem("companyId"),
        itemId:selectedValue,
        branchId: this.props.PoFillDetails.shippingAddress_Value,
    }
    this.props.getItemDetailsByItemItemGrpForPurchase(serverApi.GET_ITEMDETAILS_BYITEM_ITEMGRP_COMPANYID,data,this.getItemDetails,selectedValue)
      this.displayField(selectedRow, "itemId", 2);
      if (selectedRow.itemId !== selectedValue) {
        var check = !!this.state.poDetails.find(
          ({ itemId }) => itemId === selectedValue
        );
      }
      if (check) {
        swal("You can't create the record with the duplicate item");
      } else {
        let poDetails = this.state.poDetails.filter((data) => {
          if (data.id === selectedRow.id) {
            selectedRow.itemId = selectedValue;
            let value = selectedName.split("^");
            let HSN = value[4];
            let rate = value[3];
            let uom = value[1];
            data.hsnCode = HSN;
            data.rate = limitDecimals(rate, 3);
            data.uomCode = uom;
            data.description = value[0];
            data.itemName = value[0];
            data.itemCode = selectedRow.itemGroupId + selectedItemCode;
            data.minStock = limitDecimals(value[8], 3);
            data.maxStock = limitDecimals(value[6], 3);
            data.reOrderQty = limitDecimals(value[12], 3);
            data.qty =
              data.itemGroupId === 997
                ? 1
                : value[8] !== 0 || value[8] !== ""
                ? limitDecimals(value[8], 3)
                : limitDecimals(data.qty, 3);
          }
          return data;
        });

        const data1 = {
          companyId: localStorage.getItem("companyId"),
          itemId: selectedValue,
          branchId: this.props.PoFillDetails.branchValue,
        };

        this.props.getPendingStock(
          serverApi.GET_PENDING_STOCK_BRANCH_WISE,
          data1,
          this.props.history,
          selectedValue
        );
        const data2 = {
          companyId: localStorage.getItem("companyId"),
          itemId: selectedValue,
        };
        this.props.getPendingStockPoandReceipt(
          serverApi.GET_CENTRAL_STOCK,
          data2,
          this.props.history,
          selectedValue
        );
        this.setState({
          poDetails: poDetails,
        });
        this.props.POWOLineItemsProps(poDetails);
      }
    }
    if (name === "tax") {
      this.displayField(selectedRow, "tax", 2);
      let poDetails = this.state.poDetails.filter((data) => {
        if (data.id === selectedRow.id) {
          let selTaxName = selectedName.split("^");
          selectedRow.tax = selectedValue;
          selectedRow.taxId = selectedValue;
          // selectedRow.taxPercentage = selectedValue;
          selectedRow.taxPercentage = Number(selTaxName[1]);

          if (this.props.PoFillDetails.tax_type_value === 1) {
            selectedRow.iTaxPercentage = selTaxName[1];
            // selectedRow.taxPercentage = Number(selTaxName[1]);
          } else {
            selectedRow.ctaxPercentage = Number(selTaxName[1]) / 2;
            selectedRow.staxPercentage = Number(selTaxName[1]) / 2;
          }
        }
        return data;
      });

      this.setState({
        poDetails: poDetails,
      });
      this.props.POWOLineItemsProps(poDetails);
    }
    if (name === "discountMode") {
      this.displayField(selectedRow, "discountMode", 2);
      let poDetails = this.state.poDetails.filter((data) => {
        if (data.id === selectedRow.id) {
          selectedRow.discountMode = selectedValue;
          selectedRow.discountPercentage = "";
        }
        return data;
      });
      this.setState({
        poDetails: poDetails,
      });
      this.props.POWOLineItemsProps(poDetails);
    }
    this.props.POWOLineItemsProps(this.state.poDetails);
  };

  onDelEvent = (key) => (row) => {
    this.setState({ [key]: row });

    this.setState({
      delete: true,
    });
  };
  onDel(e) {
    this.setState({
      delete: false,
    });
  }

  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "termsNconditions") {
      this.props.PoFillDetails.termsNconditions = selectedName;
      this.props.PoFillDetails.selectTermsNconditions = selectedName;
      this.setState({
        termsNconditions: selectedName,
        selectTermsNconditions: selectedName,
      });
    }
  };

  UploadDocuments = (key) => (e) => {
    let taskuqId = this.props.locationState.state.rowData.id;
    e.preventDefault();
    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 14);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", taskuqId);
    formData.append("createdBy", this.state.userDit.userId);
    formData.append("fileCompanyId", localStorage.getItem("companyId"));
    this.setState({
      fileName: file.name,
      fileType: fileExt,
      uploadFile: true,
    });
    this.props.postUploadFileList(
      serverApi.UPLOADFILE,
      formData,
      this.props.history
    );
  };
  onDeleteFile = (fileId) => {
    let data = this.state.files_List.filter(
      (item) => item.fileUploadId !== fileId
    );
    this.props.deleteFile(serverApi.DELETEFILE + fileId);
    this.setState({
      files_List: data,
    });
    this.props.getFilesSuccessfull({data})
  };

  onClickToSeeLogs = () => {
    this.setState({
      uploadFile: false,
      seeLogs: true,
    });
    if (this.props.location.state) {
      let id = this.props.location.state.rowData.id;
      this.props.logs(serverApi.SEE_THE_LOGSOF_PO + id, this.props.history);
    }
  };
  onDelLineItem = (row) => {
    if (this.state.poDetails.length == 1) {
      swal("You con't delete the data");
    } else {
      var indexitem = this.state.poDetails.findIndex((item) => item == row);
      var lineItems = this.state.poDetails;
      lineItems[indexitem].isActive = 0;
      this.setState({
        poDetails: lineItems,
      });
      this.props.POWOLineItemsProps(lineItems);
    }
    let LineItems = this.state.poDetails;
    var total = LineItems.filter(
      (item) => item.isActive != 0 && item.itemId !== ""
    )
      .map((p) => p.amount)
      .reduce((igst, ind) => parseFloat(igst) + parseFloat(ind));

    let advanceAmount = parseFloat(
      (total * this.props.PoFillDetails.advancePercentage) / 100
    ).toFixed(2);
    this.setState({
      advanceAmount: Number(advanceAmount),
    });
    this.props.PoFillDetails.advanceAmount = Number(advanceAmount);
  };
  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };
  getAmount = (
    qty,
    qtyPo,
    rate,
    discountMode,
    discountPercentage,
    installationRate
  ) => {
    let amount = 0.0;
    let instaRate = 0.0;
    if (discountMode) {
      if (discountMode === 1) {
        // amount =(Number(qty) - (qtyPo === undefined ? 0 : Number(qtyPo)))
        amount = Number(qty) * Number(rate);
        instaRate = Number(installationRate) * Number(qty);
        // (Number(qty) - (qtyPo === undefined || qtyPo === null ? 0 : Number(qtyPo)));
        amount = amount + instaRate;
        if (
          discountPercentage !== 0 &&
          discountPercentage !== undefined &&
          discountPercentage !== ""
        ) {
          amount = amount - Number(discountPercentage);
        }
        // return amount;
        return parseFloat(amount).toFixed(2);
      } else {
        // amount =(Number(qty) - (qtyPo === undefined ? 0 : Number(qtyPo))) *Number(rate);
        amount = Number(qty) * Number(rate);
        instaRate = Number(installationRate) * Number(qty);
        // (Number(qty) -
        //   (qtyPo === undefined || qtyPo === null ? 0 : Number(qtyPo)));
        amount = amount + instaRate;
        if (
          discountPercentage !== 0 &&
          discountPercentage !== undefined &&
          discountPercentage !== ""
        ) {
          let disAmount = (amount * Number(discountPercentage)) / 100;
          amount = amount - disAmount;
        }
        // return amount;
        return parseFloat(amount).toFixed(2);
      }
    } else {
      // amount =
      //   (Number(qty) - (qtyPo === undefined ? 0 : Number(qtyPo))) *
      //   Number(rate);
      amount = Number(qty) * Number(rate);

      instaRate = Number(installationRate) * Number(qty);
      // (Number(qty) -
      //   (qtyPo === undefined || qtyPo === null ? 0 : Number(qtyPo)));
      amount = amount + instaRate;
      // return amount;
      return parseFloat(amount).toFixed(2);
    }
  };

  ///to add other charges
  addOtherCharges = () => {
    const data = {
      itemGroupId: "997",
      saleable: "",
      consumable: "",
      companyId: localStorage.getItem("companyId"),
      indentTypeId: "",
      branchId: this.props.PoFillDetails.branchValue,
    };
    // this.props.getItemsListForIndent(
    //   serverApi.GET_ITEMSBY_FORPROCUREMENT,
    //   data,
    //   this.props.history
    // );
    this.props.getItemListByItemGroupForPurchase(
      serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
      data,
      this.getitemListByitemgrpId,
      997
    );
    this.setState({
      othercharges: true,
    });
    let newline = 1;
    let budgetHeadId = "";
    let budgetHeadName = "";
    let indentId = "";
    let filteredproducts = this.state.poDetails.map((item) => {
      newline = Number(item.id);
      if (item.budgetHeadId !== "" && item.budgetHeadId !== null) {
        budgetHeadId = item.budgetHeadId;
        budgetHeadName = item.budgetHeadName;
        indentId = item.indentId;
      }
      return item;
    });

    let obj = {
      budgetHeadId: budgetHeadId,
      budgetHeadName: budgetHeadName,
      cancelledBy: null,
      cancelledByUser: null,
      cancelledDate: null,
      cancelledQty: null,
      cancelledReasons: null,
      categoryId: null,
      costcenterId: null,
      costcenterName: null,
      departmentId: null,
      departmentName: "",
      description: null,
      discountMode: 0,
      discountPercentage: 0.0,
      expectedDeliveryDate: null,
      expenseTypeName: null,
      id: Number(newline) + 1,
      indentDetailsId: null,
      indentId: indentId,
      indentName: null,
      installationRate: null,
      isActive: 1,
      itemCode: "",
      itemGroup: "Other Charges",
      itemGroupId: 997,
      itemId: null,
      itemList: [],
      itemName: "",
      lastPurchaseDate: null,
      lastPurchaseRate: null,
      make: null,
      qohBranch: 0.0,
      qohCompany: 0.0,
      qohProject: 0.0,
      qty: 1.0,
      qtyPo: 0.0,
      qtyPoOpen: null,
      rate: 0.0,
      rateSource: 1,
      remainingQuantity: 0.0,
      remarks: "",
      selectedExpenseType: null,
      selectedSubExpenseType: null,
      taxId: 0,
      taxPercentage: null,
      uomCode: "",
    };

    if (this.props.location.state !== undefined) {
      if (filteredproducts.length > 1) {
        filteredproducts.push(obj);
      } else {
        filteredproducts.splice(0, 0, obj);
      }
    } else {
      if (
        this.props.source === "WITHINDENT" ||
        this.props.source === "WITHBOM" ||
        this.props.source === "WITHBOQ"
      ) {
        if (filteredproducts.length >= 1) {
          // filteredproducts.push(obj);
          filteredproducts.splice(filteredproducts.length, 0, obj);
        } else {
          filteredproducts.splice(0, 0, obj);
        }
      } else {
        if (filteredproducts.length >= 1) {
          // filteredproducts.push(obj);
          filteredproducts.splice(1, 0, obj);
        } else {
          filteredproducts.splice(0, 0, obj);
        }
      }
    }
    this.setState({
      poDetails: filteredproducts,
    });
    this.props.POWOLineItemsProps(filteredproducts);
  };

  handleEventPositioned = (info, item) => {
    if (info.target) {
      info.target.setAttribute(
        "data-tip",
        `
            <p>Central Stock = ${Number(
              item.qohCompany === "" ? 0.0 : item.qohCompany
            ).toFixed(3)}</p>
            <p>Branch Stock = ${Number(
              item.qohBranch === "" ? 0.0 : item.qohBranch
            ).toFixed(3)}</p>
          <p> Qty pending for Po = ${
            item.qtyPo == "" || item.qtyPo == null
              ? 0.0
              : Number(item.qtyPo).toFixed(3)
          }</p>
        <p>Re order quantity = ${
          item.reOrderQty == undefined ? 0 : Number(item.reOrderQty).toFixed(3)
        }</p>
          <p>Max = ${
            item.maxStock == undefined ? 0.0 : Number(item.maxStock).toFixed(3)
          }</p>
          <p>Min = ${
            item.minStock == undefined ? 0.0 : Number(item.minStock).toFixed(3)
          }</p>`
      );
      ReactTooltip.rebuild();
    }
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  isDisabledByfield = (col, row) => {
    let case1 = "WITHBOQ";
    let case2 = "WITHBOM";
    switch (true) {
      case this.props.source === case1 || this.props.source === case2:
        let returnValue = false;
        switch (true) {
          case Number(row.itemGroupId) === 997:
            // if (col.type === "budgetHeadId" || col.type === "itemGroup") {
            returnValue = false;
            // }
            break;
          case col.type === "budgetHeadId" ||
            col.type === "itemGroup" ||
            col.type === "itemId":
            returnValue = true;
            break;
          default:
            return returnValue;
        }
        return returnValue;
      default:
        return true;
    }
    // return true
  };

  displayField = (row, colname, type) => {
    if (colname === "budgetHeadId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";
      this.setState((prevState) => ({
        deptDisplayTxtField: {
          ...prevState.deptDisplayTxtField,
          [fieldKey1]: !prevState.deptDisplayTxtField[fieldKey1],
        },
        deptDisplayDropField: {
          ...prevState.deptDisplayDropField,
          [fieldKey2]: !prevState.deptDisplayDropField[fieldKey2],
        },
      }));
      //call api
      if (type !== 2) {
        this.props.BudgetHeads(
          serverApi.GET_BUDGET_HEADES + localStorage.getItem("companyId"),
          this.state.history
        );
      }
    } else if (colname === "itemGroup") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";
      this.setState((prevState) => ({
        deptDisplayTxtField: {
          ...prevState.deptDisplayTxtField,
          [fieldKey1]: !prevState.deptDisplayTxtField[fieldKey1],
        },
        deptDisplayDropField: {
          ...prevState.deptDisplayDropField,
          [fieldKey2]: !prevState.deptDisplayDropField[fieldKey2],
        },
      }));
      if (type !== 2) {
        if (row.itemGroupId !== "" && row.itemGroupId !== undefined) {
          const data = {
            companyId: localStorage.getItem("companyId"),
            consumable: "",
            indentTypeId: this.props.PoFillDetails.categoryTypeValue,
            itemGroupId: row.budgetHeadId,
            saleable: "",
            branchId: this.props.PoFillDetails.branchValue,
          };
          this.props.getItemListByItemGroupForPurchase(
            serverApi.GET_ITEMLIST_BYITEMGROUPANDCOMPANYID,
            data,
            this.getitemListByitemgrpId,
            row.itemGroupId
          );
        }
      }
    } else if (colname === "tax") {
      if (type !== 2) {
        this.props.getTax_List(
          serverApi.GET_ALL_TAXLIST +
            localStorage.getItem("companyId") +
            "/" +
            this.state.userDit.cipher,
          this.props.history
        );
      }
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";
      this.setState((prevState) => ({
        deptDisplayTxtField: {
          ...prevState.deptDisplayTxtField,
          [fieldKey1]: !prevState.deptDisplayTxtField[fieldKey1],
        },
        deptDisplayDropField: {
          ...prevState.deptDisplayDropField,
          [fieldKey2]: !prevState.deptDisplayDropField[fieldKey2],
        },
      }));
    } else if (colname === "discountMode") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";
      this.setState((prevState) => ({
        deptDisplayTxtField: {
          ...prevState.deptDisplayTxtField,
          [fieldKey1]: !prevState.deptDisplayTxtField[fieldKey1],
        },
        deptDisplayDropField: {
          ...prevState.deptDisplayDropField,
          [fieldKey2]: !prevState.deptDisplayDropField[fieldKey2],
        },
      }));
    } else if (colname === "itemId") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";
      this.setState((prevState) => ({
        deptDisplayTxtField: {
          ...prevState.deptDisplayTxtField,
          [fieldKey1]: !prevState.deptDisplayTxtField[fieldKey1],
        },
        deptDisplayDropField: {
          ...prevState.deptDisplayDropField,
          [fieldKey2]: !prevState.deptDisplayDropField[fieldKey2],
        },
      }));

      if (type !== 2) {
        if (row.itemId !== "" && row.itemId !== undefined) {
          const data1 = {
            companyId: localStorage.getItem("companyId"),
            itemId: row.itemId,
            branchId: this.props.PoFillDetails.branchValue,
          };
          this.props.getPendingStock(
            serverApi.GET_PENDING_STOCK_BRANCH_WISE,
            data1,
            this.props.history,
            row.itemId
          );
          const data2 = {
            companyId: localStorage.getItem("companyId"),
            itemId: row.itemId,
          };
          this.props.getPendingStockPoandReceipt(
            serverApi.GET_CENTRAL_STOCK,
            data2,
            this.props.history,
            row.itemId
          );
        }
      }
    }
  };

  getitemListByitemgrpId = (response, itemGrpId) => {
    if (itemGrpId !== undefined) {
      if (response) {
        let poDetails = this.state.poDetails.filter((row) => {
          if (row.itemGroup === itemGrpId || row.itemGroupId === itemGrpId) {
            let list = [];
            response.data.map((prop) => {
              list.push({
                label: prop.itemDesc+ `(${prop.itemCode})`,
                name: prop.itemDesc+ `(${prop.itemCode})`,
                value: prop.itemId,
                variant: prop.itemCode,
              });
            });
            // let itemsList = list.filter((item) => {
            //   if (item.value == row.itemId) {
            //     let value = item.name.split("^");
            //     row.min = limitDecimals(value[8], 3);
            //     row.max = limitDecimals(value[6], 3);
            //   }
            //   if (item.value !== 0 && item.value !== "0") {
            //     return item;
            //   }
            // });
            row.itemList = list;
          } else if (row.departmentId === itemGrpId) {
            let itemGRPList = [];
            response.data.map((prop) => {
              itemGRPList.push({
                label: prop.label,
                name: prop.name,
                value: prop.value,
              });
            });
            let itemsgroupList = itemGRPList.filter((item) => {
              if (item.value !== 0 && item.value !== "0") {
                return item;
              }
            });
            row.itemGroupList = itemsgroupList;
          }
          return row;
        });
        this.setState({
          poDetails: poDetails,
        });
        this.props.POWOLineItemsProps(poDetails);
      }
      // return this.state.itemsList
    }
  };
  getFilesDownload = (fileUploadId, fileName, fileExtension) => {
    let url = serverVars.SECURITY_API + "files/downloadfile/" + fileUploadId;
    getFileDownload(url, fileName, fileExtension);
  };

  render() {
    var filteredproducts = this.state.poDetails.filter(
      (item) => item.isActive !== 0
    );
    var totalAmount = 0; //price item1 + price of item2
    var netAmount = 0; // price item1 + price of item2 + gst of item1 + gst of item2
    var totalGst = 0; // gst of item1 + gst of item2
    var totalCgstAmount = 0;
    var totalSgstAmount = 0;
    if (this.state.poDetails) {
      const poDetailsItems = this.state.poDetails.filter(
        (item) => item.departmentId !== "" && item.isActive !== 0
      );
      poDetailsItems.map((data) => {
        var lineItemAmt = 0;
        lineItemAmt += data.qty * data.rate;
        var totalInstallationRate = 0;
        if (data.installationRate !== null) {
          totalInstallationRate = data.installationRate * data.qty;
        }
        lineItemAmt += totalInstallationRate;

        if (
          Number(data.discountMode) !== undefined &&
          Number(data.discountMode) !== "" &&
          Number(data.discountMode) !== null
        ) {
          if (Number(data.discountMode) === 1) {
            // fixed
            lineItemAmt -= data.discountPercentage;
          } else if (Number(data.discountMode) === 2) {
            // percentage
            var discountAmt = (Number(lineItemAmt)* Number(data.discountPercentage)) / 100;
            lineItemAmt -= Number(discountAmt);
          }
        }
        // gst check
        var gstAmount = 0;
        if (this.props.PoFillDetails.tax_payable_value === 1) {
          if (this.props.PoFillDetails.tax_type_value === 1) {
            gstAmount = (Number(lineItemAmt) * Number(data.taxPercentage)) / 100;
            totalGst += Number(gstAmount);
          } else {
            gstAmount = (Number(lineItemAmt) * Number(data.taxPercentage)) / 100;
            totalGst += Number(gstAmount); // totalGst = totalGst + gstAmount
            totalCgstAmount +=Number(gstAmount) / 2;
            totalSgstAmount += Number(gstAmount) / 2;
          }
        }
        totalAmount += Number(lineItemAmt);
      });
      netAmount += Math.round(totalAmount + totalGst);
    }
    var netTotal = Number(netAmount);
    this.props.PoFillDetails.totalAmount = Number(totalAmount);
    this.props.PoFillDetails.totalIgst = Number(totalGst);
    this.props.PoFillDetails.totalCgst = Number(totalCgstAmount);
    this.props.PoFillDetails.totalSgst = Number(totalSgstAmount);
    this.props.PoFillDetails.netTotal = Number(netTotal);
    var advanceAmount = 0;
    advanceAmount = parseFloat(
      (totalAmount * this.state.advancePercentage) / 100
    ).toFixed(2);
    this.props.PoFillDetails.advanceAmount = Number(advanceAmount);

    return (
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent" onScroll={this.handleScroll}>
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {tableHeaders.map((column) => (
                    <>
                      {column.header === "Quantity" ||
                      column.header === "Tax Percentage" ||
                      column.header === "Budget Head" ||
                      column.header === "Item" ||
                      column.header === "Item Group" ? (
                        <th>
                          {column.header}
                          <span style={{ color: "red" }}>*</span>
                        </th>
                      ) : (
                        <th>{column.header}</th>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredproducts.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <>
                      {RowData &&
                        RowData.map((col) =>
                          col.component === "EditableCell" ? (
                            <>
                              <td key={col.no}>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        type="text"
                                        name={col.type + "_" + rowIndex}
                                        value={
                                          col.type === "Unit"
                                            ? row.uomCode
                                            : col.type === "Item Rate"
                                            ? row.rate
                                            : col.type === "installationRate"
                                            ? row.installationRate
                                            : col.type === "installationAmount"
                                            ? parseFloat(
                                                row.installationRate === null
                                                  ? 0
                                                  : row.installationRate *
                                                      Number(row.qty)
                                                // * (Number(row.qty) - (row.qtyPo === undefined || row.qtyPo === null ? 0: Number(row.qtyPo)))
                                              )
                                            : // : col.type === "discountPercentage"
                                            // ? row.discountPercentage
                                            col.type === "amount"
                                            ? this.getAmount(
                                                Number(row.qty),
                                                row.qtyPo === undefined
                                                  ? 0
                                                  : Number(row.qtyPo),
                                                row.rate,
                                                row.discountMode,
                                                row.discountPercentage,
                                                row.installationRate
                                              )
                                            : col.type === "remarks"
                                            ? row.remarks
                                            : col.type === "make"
                                            ? row.make
                                            : ""
                                        }
                                        onChange={this.handleChange({
                                          key: col.type + "_" + rowIndex,
                                          row,
                                        })}
                                        onWheel={(e) => e.target.blur()}
                                        className="inputBlockContainer"
                                        style={{
                                          textAlign:
                                            col.type === "amount" ||
                                            col.type === "installationAmount" ||
                                            col.type === "installationRate" ||
                                            col.type === "Item Rate"
                                              ? "right"
                                              : "",
                                          paddingRight: "8px",
                                        }}
                                        readOnly={
                                          row.itemGroup === "Other Charges" &&
                                          col.type === "installationRate"
                                            ? true
                                            : false
                                        }
                                        onMouseOver={(e) =>
                                          col.type === "itemQty"
                                            ? this.handleEventPositioned(e, row)
                                            : ""
                                        }
                                      />
                                      <ReactTooltip
                                        effect={"solid"}
                                        html={true}
                                        place={"BottomCenter"}
                                        // backgroundColor="#ebfaff"
                                        className="tooltipClassBorder"
                                        backgroundColor="rgba(0,127,173,0.5)"
                                        textColor="#FFF"
                                        border={true}
                                        borderColor="#04749c"
                                        TransitionProps={{ timeout: 100 }}
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            </>
                          ) : col.component === "Inputadorment" ? (
                            <td>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      name={col.type + "_" + rowIndex}
                                      value={
                                        col.type === "qty"
                                          ? row.itemGroupId === 997 ||
                                            Number(row.itemGroupId) === 997
                                            ? "NA"
                                            : row.qty === ""
                                            ? ""
                                            : row.qty
                                          : ""
                                      }
                                      onChange={this.handleChange({
                                        key: col.type + "_" + rowIndex,
                                        row,
                                      })}
                                      onBlur={this.handleBlur({
                                        key: col.type + "_" + rowIndex,
                                        row,
                                      })}
                                      onWheel={(e) => e.target.blur()}
                                      className="inputBlockContainer"
                                      style={{
                                        textAlign:
                                          col.type === "qty" ? "right" : "",
                                        paddingRight: "8px",
                                      }}
                                      onMouseOver={(e) =>
                                        col.type === "qty"
                                          ? this.handleEventPositioned(e, row)
                                          : ""
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          ) : col.component === "textfield" ? (
                            <>
                              <td>
                                <Grid
                                  xs={12}
                                  md={12}
                                  className="dynamicSelectBlock"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    type={
                                      col.type === "discountPercentage"
                                        ? "number"
                                        : ""
                                    }
                                    name={col.type + "_" + rowIndex}
                                    value={
                                      col.type === "discountPercentage"
                                        ? row.discountPercentage
                                        : col.type === "HSN/SAC_code"
                                        ? row.hsnCode
                                        : ""
                                    }
                                    onChange={this.handleChange({
                                      key: col.type + "_" + rowIndex,
                                      row,
                                    })}
                                    disabled={
                                      row.discountMode === 0 ||
                                      row.discountMode === "" ||
                                      row.discountMode === undefined
                                        ? true
                                        : false
                                    }
                                    onWheel={(e) => e.target.blur()}
                                    className="inputBlockContainer"
                                    style={{
                                      textAlign:
                                        col.type === "discountPercentage"
                                          ? "right"
                                          : "",
                                      paddingRight: "8px",
                                    }}
                                  />
                                </Grid>
                              </td>
                            </>
                          ) : col.component === "DynamicSelect" ? (
                            <td>
                              <Tooltip
                                title={
                                  col.type === "budgetHeadId"
                                    ? row.budgetHeadName
                                    : col.type === "itemGroup"
                                    ? row.itemGroup
                                    : col.type === "itemId"
                                    ? this.props.source === "WITHOUTINDENT"
                                      ? row.description
                                      : row.itemName
                                    : ""
                                }
                              >
                                <div className="quantityDropdownBlock">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: this.state.deptDisplayTxtField[
                                        col.type + row.id + "1"
                                      ]
                                        ? "none"
                                        : "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      className="inputBlockContainer"
                                      style={{
                                        paddingRight: "8px",
                                        minWidth: "250px",
                                        ...(this.isDisabledByfield(col, row)
                                          ? { backgroundColor: "#ccd6db" }
                                          : {}),
                                      }}
                                      value={
                                        col.type === "budgetHeadId"
                                          ? row.budgetHeadName
                                          : col.type === "itemId"
                                          ? this.props.source ===
                                            "WITHOUTINDENT"
                                            ? row.description
                                            : row.itemName
                                          : col.type === "itemGroup"
                                          ? row.itemGroup
                                          : col.type === "discountMode"
                                          ? row.discountMode === "" ||
                                            row.discountMode === null ||
                                            row.discountMode === 0
                                            ? ""
                                            : row.discountMode === 1
                                            ? "Amount"
                                            : "Percentage"
                                          : col.type === "tax"
                                          ? row.taxPercentage
                                          : ""
                                      }
                                      onChange={this.handleChange({
                                        key: col.type + "_" + rowIndex,
                                        row,
                                      })}
                                      onClick={() => {
                                        if (
                                          this.props.source === "WITHBOQ" ||
                                          this.props.source === "WITHBOM"
                                        ) {
                                          if (
                                            col.type === "itemQty" ||
                                            col.type === "qty" ||
                                            col.type === "discountMode" ||
                                            col.type === "tax"
                                          ) {
                                            this.displayField(row, col.type, 1);
                                          } else {
                                            if (row.itemGroupId === 997) {
                                              if (col.type === "itemId") {
                                                this.displayField(
                                                  row,
                                                  col.type,
                                                  1
                                                );
                                              }
                                            }
                                          }
                                        }
                                      }}
                                      disabled={this.isDisabledByfield(
                                        col,
                                        row
                                      )}
                                    />
                                  </Grid>
                                  <Grid
                                    className="selectAndTextfield"
                                    style={{
                                      display: this.state.deptDisplayDropField[
                                        col.type + row.id + "2"
                                      ]
                                        ? "flex"
                                        : "none",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <DynamicSelect
                                        arrayOfData={
                                          col.type === "budgetHeadId"
                                            ? this.state.budgetHeads_list
                                            : col.type === "itemGroup"
                                            ? row.itemGroupList
                                            : col.type === "itemId"
                                            ? row.itemList
                                            : col.type === "tax"
                                            ? this.state.taxList
                                            : col.type === "discountMode"
                                            ? discountModeList
                                            : []
                                        }
                                        className="dropdownBlockContainer"
                                        onSelectChange={
                                          this.handleChangedropdown
                                        }
                                        isDropdownOpen={
                                          this.state.isDropdownOpen
                                        }
                                        handleMenuOpen={this.handleMenuOpen}
                                        selected={
                                          col.type === "budgetHeadId"
                                            ? row.budgetHeadId
                                            : col.type === "itemGroup"
                                            ? row.itemGroupId !== null
                                              ? row.itemGroupId
                                              : row.itemGroup
                                            : col.type === "itemId"
                                            ? row.itemId
                                            : col.type === "discountMode"
                                            ? row.discountMode
                                            : col.type === "tax"
                                            ? row.taxId
                                            : ""
                                        }
                                        update={
                                          row.budgetHeadId ||
                                          row.itemGroupId ||
                                          row.itemId ||
                                          row.taxId ||
                                          row.discountMode
                                            ? 1
                                            : 0
                                        }
                                        name={col.type}
                                        row={row}
                                        // value={this.state.item}
                                        isDisabled={this.isDisabledByfield(
                                          col,
                                          row
                                        )}
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </Tooltip>
                            </td>
                          ) : (
                            ""
                          )
                        )}
                    </>
                    <td className="">
                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 100 }}
                        title="Delete"
                      >
                        <IconButton
                          onClick={() => {
                            this.onDelLineItem(row);
                          }}
                          disabled={
                            filteredproducts.length === 1 ? true : false
                          }
                        >
                          <DeleteIcon className="deleteDisplayButton" />
                        </IconButton>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div style={{ marginTop: "5px" }}>
            <span
              className="OtherCharges"
              onClick={() => {
                this.addOtherCharges();
              }}
            >
              Click Here To Add Printing Charges/Labour Charges/Transport
              Charges/Delivery Charges/Packing & Forwarding Charges/Other
              Charges
            </span>
          </div>
        </div>

        <div className="consoleFormContainer">
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 12">
                    {/* <InvoiceSection poDetails={this.state.poDetails} poFillDetails={this.props.PoFillDetails} type={"BY_NOT_BOM"}/> */}

                    {/* commment this line and added InvoiceSection for business calculation */}
                    <div
                      className="calculationBlockTable"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <table>
                        <tr>
                          <td>Total IGST:</td>
                          {/* <td>{parseFloat(totalIgst).toFixed(2)}</td> */}
                          <b>
                            {" "}
                            {this.props.PoFillDetails.tax_type_value === 1
                              ? parseFloat(Number(totalGst)).toFixed(2)
                              : parseFloat(0).toFixed(2)}
                          </b>
                        </tr>
                        <tr>
                          <td>Total SGST:</td>
                          <td>
                            <b>
                              {this.props.PoFillDetails.tax_type_value === 2
                                ? parseFloat(totalSgstAmount).toFixed(2)
                                : parseFloat(0).toFixed(2)}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td>Total CGST:</td>
                          <td>
                            <b>
                              {this.props.PoFillDetails.tax_type_value === 2
                                ? parseFloat(totalCgstAmount).toFixed(2)
                                : parseFloat(0).toFixed(2)}
                            </b>
                          </td>
                        </tr>
                        <tr>
                          <td>Net Amount:</td>
                          {/* <td>{parseFloat(totalAmount).toFixed(2)}</td> */}
                          <b>{parseFloat(Number(totalAmount)).toFixed(2)}</b>
                        </tr>
                        <tr>
                          <td>Total Amount:</td>
                          {/* <td>{parseFloat(Math.round(netTotal)).toFixed(2)}</td> */}
                          {/* <td>{parseFloat(netTotal).toFixed(2)}</td> */}
                          <b> {parseFloat(Number(netAmount)).toFixed(2)}</b>
                        </tr>
                      </table>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Advance Percentage"
                        value={this.state.advancePercentage}
                        onChange={this.onhandleChange("advance_per")}
                        minValue={0}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Advance Amount"
                        type={"number"}
                        value={advanceAmount}
                        onChange={this.onhandleChange("advance_amt")}
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelectNew
                        arrayOfData={this.state.termsNconditionsList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.termsNconditions}
                        update={this.state.termsNconditions ? 1 : 0}
                        name="termsNconditions"
                        label="Select Terms and Conditions"
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        label="Terms and Conditions"
                        type="textarea"
                        value={this.state.selectTermsNconditions}
                        onChange={this.onhandleChange("term&Con")}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        {this.props.location.state !==undefined && (
          <div className="consoleFormContainer">
            <div style={{ height: "15px" }}></div>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 4">
                      <div>Support Documents:</div>
                      <div className="personalDetailsUpload">
                        <div className="ducumentUploadBlock">
                          <div className="documentUploadContent">
                            <span className="btn_upload documentUpload">
                              <img src={panUploadIcon} alt="" />
                              <input
                                type="file"
                                id="imag"
                                title=""
                                className="input-img"
                                onChange={this.UploadDocuments()}
                              />
                              Upload Document
                            </span>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      {this.state.files_List &&
                        this.state.files_List.map((item) => (
                          <>
                            <div>
                              {/* {item.fileName} */}
                              <div
                                className="uploadedInfo"
                                style={{ display: "flex" }}
                              >
                                <img
                                  src={TickIcon}
                                  alt=""
                                  className="tickIcon"
                                  height={"20px"}
                                  width={"20px"}
                                />
                                <Tooltip
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 600 }}
                                  title="Click to download File"
                                >
                                  <div
                                    onClick={() => {
                                      this.getFilesDownload(
                                        item.fileUploadId,
                                        item.fileName,
                                        item.fileExtension
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {item.fileName}
                                  </div>
                                </Tooltip>
                                <Tooltip
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 100 }}
                                  title="Click to Delete File"
                                >
                                  <Button
                                    onClick={() => {
                                      this.onDeleteFile(item.fileUploadId);
                                    }}
                                    className="deleteButton"
                                  >
                                    <img
                                      src={DeleteIcons}
                                      alt=""
                                      height={"20px"}
                                      width={"20px"}
                                    />
                                  </Button>
                                </Tooltip>
                              </div>
                            </div>
                          </>
                        ))}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 3">
                      <div className="consoleFormButtonBlock">
                        <CustomButton
                          label={"CLICK HERE TO SEE THE LOGS"}
                          className="greenButton"
                          handleClick={this.onClickToSeeLogs}
                          type="sumbit"
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 6">
                      {this.state.Logs &&
                        this.state.Logs.map((item) => (
                          <div>
                            {item.actionDate}
                            {item.remarks}
                          </div>
                        ))}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const discountModeList = [
  // {
  //   label: "select",
  //   name: "select",
  //   value: 0,
  // },
  {
    label: "Amount",
    name: "Amount",
    value: 1,
  },
  {
    label: "Percentage",
    name: "Percentage",
    value: 2,
  },
];
const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "budgetHeadId",
    dropDown: "dropDown",
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "itemGroup",
    dropDown: "dropDown",
  },
  {
    no: 3,
    component: "DynamicSelect",
    type: "itemId",
    dropDown: "dropDown",
  },
  {
    no: 4,
    component: "Inputadorment",
    type: "qty",
    uomCode: "Kgs",
  },

  {
    no: 5,
    component: "textfield",
    type: "HSN/SAC_code",
  },

  {
    no: 6,
    component: "EditableCell",
    type: "Item Rate",
  },
  {
    no: 7,
    component: "EditableCell",
    type: "installationRate",
  },
  {
    no: 8,
    component: "EditableCell",
    type: "installationAmount",
  },
  {
    no: 9,
    component: "DynamicSelect",
    type: "discountMode",
    dropDown: "dropDown",
  },
  {
    no: 10,
    component: "textfield",
    type: "discountPercentage",
  },
  {
    no: 11,
    component: "DynamicSelect",
    type: "tax",
    dropDown: "dropDown",
  },
  {
    no: 12,
    component: "EditableCell",
    type: "amount",
  },
  {
    no: 13,
    component: "EditableCell",
    type: "remarks",
  },
  {
    no: 14,
    component: "EditableCell",
    type: "make",
  },
];
const tableHeaders = [
  {
    header: "Budget Head",
  },
  {
    header: "Item Group",
  },
  {
    header: "Item",
  },
  {
    header: "Quantity",
  },
  {
    header: "HSN/SAC code",
  },
  {
    header: "Item Rate",
  },
  {
    header: "Installation Rate",
  },
  {
    header: "Installation Amount",
  },
  {
    header: "Discount Mode",
  },
  {
    header: "Discount/Amount",
  },
  {
    header: "Tax Percentage",
  },
  {
    header: "Amount",
  },
  {
    header: "Remarks",
  },
  {
    header: "Make",
  },
  {
    header: "Actions",
  },
];

const mapStatetoProps = (state) => {
  const { itemsListForIndent } = state.itemGroupMasterList;
  const { taxList } = state.taxList;
  const { po_wo_lineitems } = state.PurchaseOrderReducer;
  const { departmentList, allItemGroupMaster_List, budgetHeadsList } =
    state.DropDownListReducer;
  const { TermsConditions } = state.DropDownListReducer;
  const { Upload_File_List } = state.UploadFileReducer;
  const { logs_List, files_List, pendingStockPoandReceipt, pendingStock } =
    state.IndentReducer;
  return {
    logs_List,
    files_List,
    Upload_File_List,
    itemsListForIndent,
    taxList,
    departmentList,
    allItemGroupMaster_List,
    po_wo_lineitems,
    TermsConditions,
    budgetHeadsList,
    pendingStockPoandReceipt,
    pendingStock,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getPendingStockPoandReceipt,
    getPendingStock,
    getItemsListForIndent,
    getTax_List,
    BudgetHeads,
    postUploadFileList,
    deleteFile,
    actions,
    logs,
    getFiles,
    getFilesSuccessfull,
    getDepartmentList,
    allItemGroupMasterList,
    POWOLineItemsProps,
    getTermsConditions,
    // getItemsListforIndent,
    getItemGroupListforindent,
    getItemListByItemGroupForPurchase,
    getItemDetailsByItemItemGrpForPurchase,
  })(POLineItems)
);
