import { Box, Grid } from "@mui/material";
import DynamicSelect from "../../../../components/Dropdown/DynamicSelect";
import TextFieldNormal from "../../../../components/TextField/TextFieldNormal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  SalesOrderViewByIdForJuteGovt,
  getJuteGovtDeliveryOrderHeader,
  getSoDropDown,
} from "../../../../store/Sales/DeliveryOrder/actions";
import { serverApi } from "../../../../helpers/Consts";
import {
  getBillingShippingList,
  getBranchList,
  getCustomerList,
} from "../../../../store/Global/DropDownApis/actions";
import { transporterMasterList } from "../../../../store/Master/TransporterMaster/actions";
import { getBankAccountList } from "../../../../store/Sales/DeliveryOrder/actions";
import moment from "moment";
import swal from "sweetalert";
import { isEmpty } from "../../../../Utilities/helper";
import FormPopup from "../../../../components/FormlPopup/FormPopup";
import DynamicSelectNew from "../../../../components/Dropdown/DropdownSelectNew";

const JuteGovtFillDetails = (props) => {
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [HeaderData, setHeaderData] = useState({});
  const [SoDropdownVal, setSoDropdownVal] = useState([]);
  const [invoiceData, setinvoiceData] = useState(props.invoiceData);
  const [branchListData, setbranchListData] = useState([]);
  const [transporterList, settransporterList] = useState([]);
  const [bankListData, setbankListData] = useState([]);
  const [CustomerData, setCustomerData] = useState([]);
  const [billingAddressData, setbillingAddressData] = useState([]);
  const [shippingAddressData, setshippingAddressData] = useState([]);
  const [brokerData, setbrokerData] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [emailCustomer, setEmailCustomer] = useState("");
  const [backDaysAllowable, setBackDaysAllowable] = useState("");
  const [futureDaysAllowable, setFutureDaysAllowable] = useState("");
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));

  let dispatch = useDispatch();
  let history = useHistory();

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };
  const { JuteGovtDeliveryOrderHeader, SrDropDownData, bankAccountList } =
    useSelector((state) => state.DeliveryOrderReducer);
  const { branchList, customerList, billingShippingList } = useSelector(
    (state) => state.DropDownListReducer
  );
  const { BrokerData } = useSelector((state) => state.QuotationReducer);

  const { TransporterMasterList } = useSelector(
    (state) => state.TransporterReducer
  );

  const handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "deliveryTerms") {
      setHeaderData((prev) => ({
        ...prev,
        deliveryTerms: selectedValue,
      }));
      const UpdatedRec = {
        ...HeaderData,
        deliveryTerms: selectedValue,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (name === "salesOrderId") {
      setHeaderData((prev) => ({
        ...prev,
        salesOrderId: selectedValue,
        salesOrderNumber: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        salesOrderId: selectedValue,
        salesOrderNumber: selectedName,
      };

      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));

      dispatch(
        SalesOrderViewByIdForJuteGovt(
          serverApi.SALES_ORDER_VIEW_BYID +
            "2/" +
            selectedValue +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).userId,
          history
        )
      );
    }
    if (name === "invoiceType") {
      setHeaderData((prev) => ({
        ...prev,
        invoiceType: selectedValue,
      }));
      const UpdatedRec = {
        ...HeaderData,
        invoiceType: selectedValue,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (name == "branch") {
      var data = selectedName.split("_");
      setHeaderData((prev) => ({
        ...prev,
        branch: selectedValue,
        branchName: selectedName,
        branchAddress: data[1],
        branchGstNo: data[2],
        branchContactPerson: data[3],
        branchContactNo: data[4],
        branchEmail: data[5],
      }));
      const UpdatedRec = {
        ...HeaderData,
        branch: selectedValue,
        branchName: selectedName,
        branchAddress: data[1],
        branchGstNo: data[2],
        branchContactPerson: data[3],
        branchContactNo: data[4],
        branchEmail: data[5],
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (name === "bankId") {
      var Data = selectedName.split("^", 5);
      setHeaderData((prev) => ({
        ...prev,
        bankId: selectedValue,
        bankName: Data[0],
        accountNo: selectedValue === 0 ? "" : Data[1],
        ifscCode: selectedValue === 0 ? "" : Data[2],
        micrCode: selectedValue === 0 ? "" : Data[3],
        bankBranch: selectedValue === 0 ? "" : Data[4],
      }));
      const UpdatedRec = {
        ...HeaderData,
        bankId: selectedValue,
        bankName: Data[0],
        accountNo: Data[1],
        ifscCode: Data[2],
        micrCode: Data[3],
        bankBranch: Data[4],
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (name === "customerId") {
      setHeaderData((prev) => ({
        ...prev,
        customerId: selectedValue,
        customerName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        customerId: selectedValue,
        customerName: selectedName,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
      dispatch(
        getBillingShippingList(
          serverApi.GET_ALL_BILLING_SHIPPING +
            selectedValue +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).cipher +
            "?isForSalesModule=1",
          history
        )
      );
    }
    if (name === "transporterId") {
      var data = selectedName.split("^");
      setHeaderData((prev) => ({
        ...prev,
        transporter: selectedValue,
        transporterName: data[0],
        transporterAddress: data[1],
        transporterStateCode: data[3],
        transporterStateName: data[2],
      }));
      const UpdatedRec = {
        ...HeaderData,
        transporter: selectedValue,
        transporterName: data[0],
        transporterAddress: data[1],
        transporterStateCode: data[3],
        transporterStateName: data[2],
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }

    if (name == "TaxType") {
      setHeaderData((prev) => ({
        ...prev,
        taxType: selectedValue,
        taxName: selectedName,
      }));
      const UpdatedRec = {
        ...HeaderData,
        taxType: selectedValue,
        taxName: selectedName,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (name == "billingTo") {
      if (billingShippingList.data) {
        billingShippingList.data.customerLineitems.map((row) => {
          if (selectedValue == row.customerLineId) {
            setHeaderData((prev) => ({
              ...prev,
              billingTo: selectedValue,
              billingToAddress: selectedName,
              billingToGST: row.gstNo,
              billingContactNo: row.contactNo,
              billingContactPerson: row.contactPerson,
              billingToEmail: emailCustomer,
              billingState: row.state,
            }));
            const UpdatedRec = {
              ...HeaderData,
              billingTo: selectedValue,
              billingToAddress: selectedName,
              billingToGST: row.gstNo,
              billingContactNo: row.contactNo,
              billingContactPerson: row.contactPerson,
              billingToEmail: emailCustomer,
              billingState: row.state,
            };

            dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
          }
        });
      }
    }
    if (name == "shippingTo") {
      if (billingShippingList.data) {
        billingShippingList.data.customerLineitems.map((row) => {
          if (selectedValue === row.customerLineId) {
            setHeaderData((prev) => ({
              ...prev,
              shippingTo: selectedValue,
              shippingToAddress: selectedName,
              shippingToGST: row.gstNo,
              shippingContactNo: row.contactNo,
              shippingContactPerson: row.contactPerson,
              shippingToEmail: emailCustomer,
              shippingState: row.state,
            }));
            const UpdatedRec = {
              ...HeaderData,
              shippingTo: selectedValue,
              shippingToAddress: selectedName,
              shippingToGST: row.gstNo,
              shippingContactNo: row.contactNo,
              shippingContactPerson: row.contactPerson,
              shippingToEmail: emailCustomer,
              shippingState: row.state,
            };
            dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
          }
        });
      }
    }
    if (name === "brokerId") {
      setHeaderData((prev) => ({
        ...prev,
        brokerId: selectedValue,
      }));
      const UpdatedRec = {
        ...HeaderData,
        brokerId: selectedValue,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
  };

  const onhandlechange = (key) => (event) => {
    if (key === "quotationNumber") {
      setHeaderData((prev) => ({
        ...prev,
        quotationNumber: event,
      }));
      const UpdatedRec = {
        ...JuteGovtDeliveryOrderHeader,
        quotationNumber: event,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (key === "transporterId") {
      setHeaderData((prev) => ({
        ...prev,
        transporterId: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        transporterId: event,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (key === "transporterStateName") {
      setHeaderData((prev) => ({
        ...prev,
        transporterName: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        transporterStateName: event,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (key === "transporterStateCode") {
      setHeaderData((prev) => ({
        ...prev,
        transporterStateCode: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        transporterStateCode: event,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (key === "TransporterAddress") {
      setHeaderData((prev) => ({
        ...prev,
        transporterAddress: event,
      }));
      const UpdateRec = {
        ...HeaderData,
        transporterAddress: event,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdateRec));
    }
    if (key === "transporterStateName") {
      setHeaderData((prev) => ({
        ...prev,
        transporterName: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        transporterStateName: event,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (key === "transporterStateCode") {
      setHeaderData((prev) => ({
        ...prev,
        transporterStateCode: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        transporterStateCode: event,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (key === "ammendmentNo") {
      setHeaderData((prev) => ({
        ...prev,
        ammendmentNo: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        ammendmentNo: event,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (key === "PCSONumber") {
      setHeaderData((prev) => ({
        ...prev,
        pcsoNo: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        pcsoNo: event,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (key === "vehicleNo") {
      setHeaderData((prev) => ({
        ...prev,
        vehicleNo: event,
      }));
      const UpdatedRec = {
        ...HeaderData,
        vehicleNo: event,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
  };
  const handleSelect_Date = (e, name) => {
    if (name === "quotationDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        quotationDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        quotationDate: Date,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (name === "PCSODate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        pcsoDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        pcsoDate: Date,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
    if (name === "deliveryDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();

      var salesDate = moment(HeaderData.salesOrderDate).format();
      var salesDateExtract = salesDate.split("T", 1).toString();
  
      if (Date < salesDateExtract) {
        swal("Delivery Order Date Should be greater than Sales Order Date");
        setHeaderData((prev) => ({
          ...prev,
          deliveryDate: HeaderData.salesOrderDate,
        }));
        const UpdatedRec = {
          ...HeaderData,
          deliveryDate: HeaderData.salesOrderDate,
        };
        dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
      } else {
        setHeaderData((prev) => ({
          ...prev,
          deliveryDate: Date,
        }));
        const UpdatedRec = {
          ...HeaderData,
          deliveryDate: Date,
        };
        dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
      }
    }

    if (name === "ammendmentDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      setHeaderData((prev) => ({
        ...prev,
        ammendmentDate: Date,
      }));
      const UpdatedRec = {
        ...HeaderData,
        ammendmentDate: Date,
      };
      dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
    }
  };

  useEffect(() => {
    setHeaderData(JuteGovtDeliveryOrderHeader);
  }, [JuteGovtDeliveryOrderHeader]);

  useEffect(() => {
    let SoJuteGovtHeadersData = {
      ...JuteGovtDeliveryOrderHeader,
      salesDate: moment().format("MM-DD-YYYY"),
    };
    setHeaderData(SoJuteGovtHeadersData);
  }, [JuteGovtDeliveryOrderHeader]);

  useEffect(() => {
    dispatch(
      getSoDropDown(
        serverApi.SALES_ORDER_DROPDOWN +
          "2/" +
          localStorage.getItem("companyId")
      )
    );
    dispatch(
      getBranchList(
        serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
        history
      )
    );
    dispatch(
      transporterMasterList(
        serverApi.GET_ALL_TRANSPORTER_MASTER_LIST +
          localStorage.getItem("companyId"),
        history
      )
    );
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
    };
    dispatch(getCustomerList(serverApi.GET_ALL_CUSTOMERS, data, history));
    dispatch(
      getBankAccountList(serverApi.GET_ALL_BANK_ACCOUNT_LIST, data, history)
    );
    const currentMonth = moment().format('MM');
    const currentDate = moment().format('DD');
    const fiscalYearStart = moment(`${localStorage.getItem("acadamicYear")}-${currentMonth}-${currentDate}`);
    const today = moment();
    let dateValue;
    if (today.isBefore(fiscalYearStart)) {
      dateValue = today.format("YYYY-MM-DD");
    } else {
      dateValue = fiscalYearStart.format("YYYY-MM-DD");
    }

    if (userDit) {
      userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              if (item.backDaysAllowable !== undefined) {
                let backDaysAllowable = JSON.parse(item.backDaysAllowable);
                let backDays = backDaysAllowable.bdaystype;
                if (Number(backDays) === 1) {
                  backDays = 7;
                } else if (Number(backDays) === 2) {
                  backDays = 14;
                } else if (Number(backDays) === 3) {
                  backDays = "";
                } else if (Number(backDays) === 4) {
                  backDays = backDaysAllowable.otherBackDateValue;
                } else {
                  backDays = backDaysAllowable.bdaystype;
                }
                let minDate = moment(dateValue).subtract(
                  Number(backDays),
                  "days"
                );
                let setMinDate = moment(minDate).format("YYYY-MM-DD");
                if(backDays !==""){
                setBackDaysAllowable(setMinDate);
                }
              }
              if (item.futureDaysAllowable !== undefined) {
                let futureDaysAllowable = JSON.parse(item.futureDaysAllowable);
                let futureDays = futureDaysAllowable.fdaystype;
                if (Number(futureDays) === 1) {
                  futureDays = 7;
                } else if (Number(futureDays) === 2) {
                  futureDays = 14;
                } else if (Number(futureDays) === 3) {
                  futureDays = "";
                } else if (Number(futureDays) === 4) {
                  futureDays = futureDaysAllowable.othervalue;
                } else {
                  futureDays = futureDaysAllowable.fdaystype;
                }
                let maxDate = moment(dateValue).add(
                  Number(futureDays),
                  "days"
                );
                let setMaxDate = moment(maxDate).format("YYYY-MM-DD");
                if(futureDays !==""){
                  setFutureDaysAllowable(setMaxDate);
                }
              }
            });
            setHeaderData((prev) => ({
              ...prev,
              // deliveryDate: dateValue,
            }));
            const UpdatedRec = {
              ...JuteGovtDeliveryOrderHeader,
              // deliveryDate: dateValue,
            };
            dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));

          }
      });
    }
  }, []);

  useEffect(() => {
    if (SrDropDownData) {
      if (SrDropDownData.data) {
        let List = [];
        SrDropDownData.data.map((item) => {
          List.push({
            value: item.id,
            name: item.Value,
            label: item.Value,
          });
        });
        setSoDropdownVal(List);
      }
    }
    if (branchList) {
      if (branchList.data) {
        if (branchList.data.length !== 0) {
          let List = branchList.data.filter((item) => item.value !== 0);
          setbranchListData(List);
        }
      }
    }
    if (bankAccountList) {
      if (bankAccountList.data) {
        if (bankAccountList.data.length !== 0) {
          var BankList = [];
          BankList.push(bankSelect);
          bankAccountList.data.data.map((item) => {
            BankList.push({
              value: item.id,
              name:
                item.bankName +
                "^" +
                item.accountNo +
                "^" +
                item.ifscCode +
                "^" +
                item.micrCode +
                "^" +
                item.branch,
              label: item.bankName,
            });
          });

          setbankListData(BankList);
        }
      }
    }
    if (BrokerData) {
      if (BrokerData.data) {
        setbrokerData(BrokerData.data);
      }
    }
    if (TransporterMasterList) {
      if (TransporterMasterList.data) {
        if (TransporterMasterList.data.data) {
          let TransporterList = TransporterMasterList.data.data.map((item) => ({
            name:
              item.transporterName +
              "^" +
              item.transporterAddress +
              "^" +
              item.stateName +
              "^" +
              item.stateCode,
            label: item.transporterName,
            value: item.transporterId,
          }));

          settransporterList(TransporterList);
        }
      }
    }
    if (customerList) {
      if (customerList.data) {
        if (customerList.data.length !== 0) {
          var CusList = [];
          customerList.data.map((item) => {
            CusList.push({
              value: item.id,
              name: item.name,
              label: item.name,
            });
          });
          setCustomerData(CusList);
        }
      }
    }
  }, [SrDropDownData, branchList, TransporterMasterList, customerList]);

  const isEmptyObj = (myObject) => {
    // Use the 'Object.keys' method to get an array of the object's keys
    const keys = Object.keys(myObject);

    // Check if the number of keys in the array is 0
    return keys.length === 0;
  };

  useEffect(() => {
    if (billingShippingList) {
      if (billingShippingList.data) {
        setEmailCustomer(billingShippingList.data.customerHdr.emailId);
        if (billingShippingList.data.customerLineitems) {
          if (billingShippingList.data.customerLineitems.length !== 0) {
            var list = [];
            let billingAddressList = [];
            let shippingaddressList = [];
            billingShippingList.data.customerLineitems.map((prop, i) => {
              if (prop.addressType === 2 || prop.addressType === null) {
                if (
                  (prop.customerLineId ===
                    JuteGovtDeliveryOrderHeader.shippingTo &&
                    prop.isActive === 0) ||
                  prop.isActive !== 0
                ) {
                  billingAddressList.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
              list.push({
                value: prop.customerLineId,
                label: prop.address,
                name: prop.address,
              });
            }
          }
            if (prop.addressType === 1 || prop.addressType === null || prop.addressType === 2 ) {
              if (
                (prop.customerLineId === JuteGovtDeliveryOrderHeader.shippingTo &&
                  prop.isActive === 0) ||
                prop.isActive !== 0
              ) {
                shippingaddressList.push({
                  value: prop.customerLineId,
                  label: prop.address,
                  name: prop.address,
                });
                list.push({
                  value: prop.customerLineId,
                  label: prop.address,
                  name: prop.address,
                });
              }
              if (i === 0) {
                if (!isEmptyObj(HeaderData)) {
                  if (
                    HeaderData.customerId !== "" &&
                    HeaderData.customerId !== "null"
                  ) {
                    setHeaderData((prev) => ({
                      ...prev,
                    }));

                    const UpdatedRec = {
                      ...HeaderData,
                    };
                    dispatch(getJuteGovtDeliveryOrderHeader(UpdatedRec));
                  }
                }
              }
            }
          });
            if (HeaderData.customerId !== "") {
              setbillingAddressData(billingAddressList);
              setshippingAddressData(shippingaddressList);
            }
          }
        }
      }
    }
  }, [billingShippingList]);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <div>
        <div className="consoleFormContainer">
          <h5>Jute Govt Fill Details</h5>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      {props.locationState.state == undefined ? (
                        <DynamicSelect
                          update={HeaderData.salesOrderId ? 1 : 0}
                          arrayOfData={SoDropdownVal}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          name="salesOrderId"
                          label="Sales Order"
                          selected={HeaderData.salesOrderId}
                          onSelectChange={handlePropSelectChange}
                          isDropdownOpen={isDropdownOpen}
                          handleMenuOpen={handleMenuOpen}
                          required
                        />
                      ) : (
                        <TextFieldNormal
                          label="Sales Order"
                          value={HeaderData.salesOrderNumber}
                          onChange={onhandlechange("salesOrderNumber")}
                          disabled={true}
                        />
                      )}
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Sales Order Date{" "}
                            {/* <span className="starcolor"></span> */}
                          </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            disabled={true}
                            name="salesOrderDate"
                            inputFormat="dd-MM-yyyy"
                            id="salesOrderDate"
                            value={HeaderData.salesOrderDate}
                            fullWidth
                            onChange={(e) =>
                              handleSelect_Date(e, "salesOrderDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                                style={{
                                  backgroundColor: "#ccd6db",
                                }}
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  style={{
                                    backgroundColor: "#ccd6db",
                                  }}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.invoiceType ? 1 : 0}
                        arrayOfData={invoiceData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.invoiceType}
                        label="Invoice Type"
                        name="invoiceType"
                        isDisabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.branch ? 1 : 0}
                        arrayOfData={branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.branch}
                        label="Branch"
                        name="branch"
                        required
                        isDisabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Delivery Order Date{" "}
                            <span className="starcolor">*</span>
                          </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="deliveryDate"
                            inputFormat="dd-MM-yyyy"
                            id="deliveryDate"
                            minDate={
                              backDaysAllowable !== ""
                                ? backDaysAllowable
                                :HeaderData.deliveryDate
                            }
                            maxDate={
                              futureDaysAllowable !== ""
                                ? futureDaysAllowable
                                : HeaderData.deliveryDate
                            }
                            value={HeaderData.deliveryDate}
                            fullWidth
                            onChange={(e) =>
                              handleSelect_Date(e, "deliveryDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.customerId ? 1 : 0}
                        arrayOfData={CustomerData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.customerId}
                        label="Customer/Consignee"
                        name="customerId"
                        required
                        isDisabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="PCSO Number"
                      value={HeaderData.pcsoNo}
                      onChange={onhandlechange("PCSONumber")}
                      type="text"
                      caps="ON"
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>PCSO Date </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="PCSO Date"
                            inputFormat="dd-MM-yyyy"
                            id="PCSODate"
                            value={HeaderData.pcsoDate}
                            fullWidth
                            onChange={(e) => handleSelect_Date(e, "PCSODate")}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.billingTo ? 1 : 0}
                        arrayOfData={billingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.billingTo}
                        name="billingTo"
                        label="Billing To"
                        required
                        isDisabled={
                          isEmpty(HeaderData.customerId) &&
                          billingAddressData.length <= 1
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.shippingTo ? 1 : 0}
                        arrayOfData={shippingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.shippingTo}
                        name="shippingTo"
                        label="Shipping To"
                        required
                        isDisabled={
                          isEmpty(HeaderData.customerId) &&
                          shippingAddressData.length <= 1
                        }
                      />
                    </div>
                    {!isEmpty(HeaderData.customerId) &&
                      shippingAddressData.length > 1 && (
                        <span
                          onClick={openPopup}
                          style={{
                            color: "red",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 100,
                            fontSize: "15px",
                            marginTop: "5px",
                            cursor: "pointer",
                            padding: "5px",
                          }}
                        >
                          Add a new Customer Branch Address
                        </span>
                      )}
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Billing State"
                      value={HeaderData.billingState}
                      onChange={onhandlechange("billingState")}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Shipping State"
                      value={HeaderData.shippingState}
                      onChange={onhandlechange("shippingState")}
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.taxType ? 1 : 0}
                        arrayOfData={taxTypeData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.taxType}
                        name="TaxType"
                        label="Tax Type"
                        isDisabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.bankId ? 1 : 0}
                        arrayOfData={bankListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.bankId}
                        name="bankId"
                        label="Bank Name"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelectNew
                      update={HeaderData.deliveryTerms ? 1 : 0}
                      arrayOfData={DeliveryTermsData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={HeaderData.deliveryTerms}
                      name="deliveryTerms"
                      label="Delivery Terms"
                      isDisabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={HeaderData.transporterId ? 1 : 0}
                        arrayOfData={transporterList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={HeaderData.transporter}
                        name="transporterId"
                        label="Transporter Name"
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Transporter Address"
                        value={HeaderData.transporterAddress}
                        onChange={onhandlechange("TransporterAddress")}
                        type="text"
                        disabled={true}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Transporter State Name"
                      value={HeaderData.transporterStateName}
                      onChange={onhandlechange("TransporterStateName")}
                      type="text"
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Transporter State Code"
                      value={HeaderData.transporterStateCode}
                      onChange={onhandlechange("TransporterStateCode")}
                      type="text"
                      disabled={true}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label={"Vehicle Number"}
                      value={HeaderData.vehicleNo}
                      onChange={onhandlechange("vehicleNo")}
                      caps="ON"
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Amendment No"
                      value={HeaderData.ammendmentNo}
                      onChange={onhandlechange("ammendmentNo")}
                      type="text"
                      caps="ON"
                    />
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>Amendment Date {""} </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="Amendment Date"
                            inputFormat="dd-MM-yyyy"
                            id="ammendmentDate"
                            value={HeaderData.ammendmentDate}
                            onChange={(e) =>
                              handleSelect_Date(e, "ammendmentDate")
                            }
                            fullWidth
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                              >
                                <input ref={inputRef} {...inputProps} />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <FormPopup
          isOpen={isPopupOpen}
          onClose={closePopup}
          customerData={billingShippingList}
          customerId={HeaderData.customerId}
        />
      </div>
    </>
  );
};
const taxTypeData = [
  {
    value: 1,
    name: "IGST",
    label: "IGST",
  },
  {
    value: 2,
    name: "CGST & SGST",
    label: "CGST & SGST",
  },
];
const DeliveryTermsData = [
  {
    value: 1,
    label: "Ex-Works",
    name: "Ex-Works",
  },
  {
    value: 2,
    label: "Delivery to Destination",
    name: "Delivery to Destination",
  },
];
const bankSelect = {
  value: "",
  name: "Select",
  label: "Select",
};
export default JuteGovtFillDetails;
