import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../../../../../components/Dropdown/DynamicSelect";
import { Link } from "react-router-dom";
import CustomButton from "../../../../../components/Buttons/Buttons";
import InputCounter from "../../../../../components/InputCounter/InputCounter";
import TextFieldNormal from "../../../../../components/TextField/TextFieldNormal";
import TextFieldEmail from "../../../../../components/TextField/TextFieldEmailReq";
import TextFieldMobile from "../../../../../components/TextField/TextFieldMobile";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import swal from "sweetalert";
import {
  getDepartmentListData,
  getCategoryList,
  getEmpLevel,
  getReportingManagerList,
  getContractor,
  getWorkLocation,
  getDesignation,
} from "../../../../../store/HRMS/EmployeeDataBase/actions";
import { getBranchList } from "../../../../../store/Global/DropDownApis/actions";
import { serverApi } from "../../../../../helpers/Consts";
import moment from "moment";
import CommonPopup from "../../../../../components/CommonPopup/CommonPopup";
import {
  viewbyidPersonalDetails,
  viewbyidPersonalDetailsSuccessfull,
  setPersonalDetails,
  clearPayScheme,
  ClearPaySchemeById,
} from "../../../../../store/HRMS/EmployeeDataBase/actions";
import { validate } from "../../../../../Utilities/Validations";
import Tooltip from "@mui/material/Tooltip";
import { isEmpty, isEmptyWithZero } from "../../../../../Utilities/helper";

class WorkDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      empCode: "",
      Department_List: [],
      Branch_List: [],
      Category_List: [],
      Designation: [],
      EmpLevel_List: [],
      Contractor: [],
      Work_Location: [],
      Reporting_Manager_List: [],
      showPopup: false,
      // WorkLocation_id:""
      handleNext: false,
      tblHrmsEdOfficialDetailId: "",
      ebId: this.props.getEbId,
      updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
      updatedTime: "",
      isActive: 1,
      departmentId: "",
      catagoryId: "",
      designationId: "",
      branchId: "",
      dateOfJoin: "",
      probationPeriod: 0,
      minimumWorkingCommitment: 0,
      reportingEbId: "",
      empCode: "",
      legacyCode: "",
      contractorId: "",
      officeMobileNo: "",
      officeMailId: "",
      ShowInValidText: false,
      showMandatoryField: false,
      deptChange: false,
      DisableDateOfJoin: false,
    };
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    var ebId = this.props.getEbId;
    if (ebId > 0) {
      this.props.viewbyidPersonalDetails(
        serverApi.VIEW_EMPLOYEE_WORKER_DIT + "/" + this.props.getEbId,
        this.props.history,
        6
      );
    } else {
      const response = { data: null };
      this.props.viewbyidPersonalDetailsSuccessfull({ response });
    }
    this.props.getDepartmentListData(
      serverApi.DEPARTMENT_LIST + this.state.companyId,
      this.props.history
    );
    this.props.getBranchList(
      serverApi.BRANCH_LIST + this.state.companyId,
      this.props.history
    );
    this.props.getCategoryList(
      serverApi.CATEGORY_LIST + this.state.companyId,
      this.props.history
    );
    this.props.getEmpLevel(
      serverApi.EMP_LEVEL + this.state.companyId,
      this.props.history
    );
    this.props.getReportingManagerList(
      serverApi.REPORTING_MANAGER_LIST +
        this.state.companyId +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher,
      this.state.history
    );
    this.props.getContractor(
      serverApi.CONTRACTOR + this.state.companyId,
      this.props.history
    );
    this.props.getWorkLocation(
      serverApi.WORK_LOCATION + this.state.companyId,
      this.props.history
    );
    this.props.clearPayScheme(null);
    this.props.ClearPaySchemeById(null);
  }
  componentWillReceiveProps(props) {
    var ebId = this.props.getEbId;
    if (ebId > 0) {
      if (props.personalDetails) {
        if (props.personalDetails.message) {
          if (
            props.personalDetails.message ==
            "The Employee is already joined. Cannot change the Date of Joining!"
          ) {
            this.setState({
              DisableDateOfJoin: true,
            });
          }
        }

        if (props.personalDetails.data) {
          this.setState({
            tblHrmsEdOfficialDetailId: props.personalDetails.data
              .tblHrmsEdOfficialDetailId
              ? props.personalDetails.data.tblHrmsEdOfficialDetailId
              : "",
            catagoryId: props.personalDetails.data.catagoryId
              ? props.personalDetails.data.catagoryId
              : "",
            designationId: props.personalDetails.data.designationId
              ? props.personalDetails.data.designationId
              : "",
            branchId: props.personalDetails.data.branchId
              ? props.personalDetails.data.branchId
              : "",
            reportingEbId: props.personalDetails.data.reportingEbId
              ? props.personalDetails.data.reportingEbId
              : "",
            empCode: props.personalDetails.data.empCode
              ? props.personalDetails.data.empCode
              : "",
            legacyCode: props.personalDetails.data.legacyCode
              ? props.personalDetails.data.legacyCode
              : "",
            contractorId: props.personalDetails.data.contractorId
              ? props.personalDetails.data.contractorId
              : "",
            officeMobileNo: props.personalDetails.data.officeMobileNo
              ? props.personalDetails.data.officeMobileNo
              : "",
            officeMailId: props.personalDetails.data.officeMailId
              ? props.personalDetails.data.officeMailId
              : "",
            dateOfJoin: props.personalDetails.data.dateOfJoin
              ? props.personalDetails.data.dateOfJoin
              : "",
            probationPeriod: props.personalDetails.data.probationPeriod === 0 ? 0 : props.personalDetails.data.probationPeriod,
            minimumWorkingCommitment: props.personalDetails.data.minimumWorkingCommitment === 0 ? 0 : props.personalDetails.data.minimumWorkingCommitment,

          });
          if (
            this.state.departmentId == "" ||
            this.state.departmentId == undefined
          ) {
            this.setState({
              departmentId: props.personalDetails.data.departmentId,
            });
          }
        }
      }
    }
    if (props.departmentList) {
      if (props.departmentList.data) {
        if (props.departmentList.data.length !== 0) {
          var List = props.departmentList.data.filter(
            (item) => item.value !== 0
          );
          this.setState({
            Department_List: List,
          });
        }
      }
    }
    if (props.branchList) {
      if (props.branchList.data) {
        if (props.branchList.data.length !== 0) {
          var ListData = props.branchList.data.filter(
            (item) => item.value !== 0
          );
          this.setState({
            Branch_List: ListData,
          });
        }
      }
    }
    if (props.categoryList) {
      if (props.categoryList.data) {
        if (props.categoryList.data.length !== 0) {
          var CategoryList = props.categoryList.data.filter(
            (item) => item.value !== 0
          );
          this.setState({
            Category_List: CategoryList,
          });
        }
      }
    }
    if (props.empLevel) {
      this.setState({
        EmpLevel_List: props.empLevel.data,
      });
    }
    if (props.reporting_Manager_List) {
      if (props.reporting_Manager_List.data) {
        if (props.reporting_Manager_List.data.length !== 0) {
          var ReportingManager = props.reporting_Manager_List.data.filter(
            (item) => item.value !== 0
          );
          this.setState({
            Reporting_Manager_List: ReportingManager,
          });
        }
      }
    }
    if (props.contractor) {
      this.setState({
        Contractor: props.contractor.data,
      });
    }
    if (props.workLocation) {
      this.setState({
        Work_Location: props.workLocation.data,
      });
    }
    if (props.designation) {
      var designationId = "";
      if (props.designation.data) {
        if (props.designation.data.length !== 0) {
          var DesignationListData = props.designation.data.filter(
            (item) => item.value !== 0
          );
          this.setState({
            Designation: DesignationListData,
          });
        }
        if (DesignationListData.length == 1) {
          designationId = "";
        } else {
          if (!this.state.deptChange)
            if (props.personalDetails) {
              if (props.personalDetails.data) {
                if (props.personalDetails.data.designationId) {
                  designationId = props.personalDetails.data.designationId;
                } else {
                  designationId = "";
                }
              }
            }
        }
      }

      this.setState({
        // Designation: props.designation.data,
        designationId: designationId,
      });
    }
  }

  onclickOk = () => {
    this.setState({
      showPopup: false,
    });
  };
  handleSelectChange = (selectedValue, selectedName, name) => {
    if (name === "department") {
      if (selectedValue !== this.state.departmentId) {
        this.setState({
          deptIdDesc: selectedName,
          departmentId: selectedValue,
          deptChange: true,
        });
  
        this.props.getDesignation(
          serverApi.DESIGNATION + this.state.companyId + "/" + selectedValue,
          this.props.history
        );
      }    
    }
  };
  handleSelectDate = (e, name) => {
    if (name === "DOJ") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();

      this.setState({
        dateOfJoin: Date,
      });
      var DateOfBirth = moment(Date, "YYYY-MM-DD", true).format("DD-MM-YYYY");
    }
  };
  onhandlechange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "officeMailId") {
      if (event === "") {
        this.setState({
          showMandatoryField: true,
          ShowInValidText: false,
        });
      } else {
        this.setState({
          showMandatoryField: false,
        });
        const val = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (val.test(event)) {
          this.setState({
            ShowInValidText: false,
          });
        } else {
          this.setState({
            ShowInValidText: true,
          });
        }
      }
    }
  };
  handleSelect = (selectedValue, selectedName, name) => {
    if (name === "Branch") {
      this.setState({
        branchIdDesc: selectedName,
        branchId: selectedValue,
      });
    }
    if (name === "Category") {
      this.setState({
        cataIdDesc: selectedName,
        catagoryId: selectedValue,
      });
    }
    if (name === "Designation") {
      this.setState({
        designationIdDesc: selectedName,
        designationId: selectedValue,
      });
    }
    if (name === "EmpLevel") {
      swal(selectedName);
      this.setState({
        empLevelIdDesc: selectedName,
        empLevelId: selectedValue,
      });
    }
    if (name === "Contractor") {
      if (selectedValue == 0) {
        this.setState({
          contractorId: "",
        });
      } else {
        this.setState({
          contractorId: selectedValue,
        });
      }
    }
    if (name === "WorkLocation") {
      this.setState({
        // WorkLocation: selectedName,
        workLocation: selectedValue,
      });
    }
    if (name === "ReportingManager") {
      this.setState({
        reportingEbId: selectedValue,
      });
    }
  };

  onClickSave = () => {
    const {
      departmentId,
      branchId,
      catagoryId,
      designationId,
      dateOfJoin,
      probationPeriod,
      minimumWorkingCommitment,
      reportingEbId,
      Category_List,
    } = this.state;
    const validateFields = [
      { field: departmentId, msg: "Department", type: 2 },
      { field: branchId, msg: "Branch", type: 2 },
      { field: catagoryId, msg: "Employment Category", type: 2 },
      { field: designationId, msg: "Designation", type: 2 },
      { field: dateOfJoin, msg: "Date of Joining", type: 1 },
      { field: probationPeriod, msg: "Probation Period", type: 4 },
      {
        field: minimumWorkingCommitment,
        msg: "Minimum Working Years",
        type: 4,
      },
      { field: reportingEbId, msg: "Reporting Manager", type: 2 },
      { field: Category_List, msg: "Employment Category", type: 1 },
    ];
    let validObj = validate(validateFields);
    if (validObj) {
       
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message}`);
      }
      if (validObj.type === 2) {
        swal(`Please Select ${validObj.message}`);
      }
      if(validObj.type === 4){
        swal(`Please Enter ${validObj.message}`);
      }
    } else if (this.state.ShowInValidText == true) {
      swal("Please enter the valid email Id", { icon: "error" });
    } else {
      const data = {
        tblHrmsEdOfficialDetailId: this.state.tblHrmsEdOfficialDetailId,
        ebId: this.props.getEbId,
        updatedBy: JSON.parse(localStorage.getItem("authUser")).userId,
        isActive: 1,
        departmentId: this.state.departmentId,
        catagoryId: this.state.catagoryId,
        designationId: this.state.designationId,
        branchId: this.state.branchId,
        dateOfJoin: this.state.dateOfJoin,
        probationPeriod: this.state.probationPeriod,
        minimumWorkingCommitment: this.state.minimumWorkingCommitment,
        reportingEbId: this.state.reportingEbId,
        empCode: this.state.empCode,
        legacyCode: this.state.legacyCode,
        contractorId: this.state.contractorId,
        officeMobileNo: this.state.officeMobileNo,
        officeMailId: this.state.officeMailId,
      };
      // if (
      //   data.departmentId &&
      //   data.branchId &&
      //   // data.catagoryId &&
      //   data.designationId &&
      //   data.dateOfJoin &&
      //   data.probationPeriod &&
      //   data.minimumWorkingCommitment
      //   && data.reportingEbId
      // ) {
      this.props.setPersonalDetails(
        serverApi.UPDATE_WORKER_DETAILS,
        data,
        this.props.history,
        "work"
      );
      this.setState({ handleNext: true });
      // } else {
      //   swal("Enter all mandatory fields", { icon: "error" });
      // }
    }
  };
  handleChange = (value, field) => {
    if (field == 1) {
      this.setState({
        probationPeriod: value,
      });
    }
    if (field == 2) {
      this.setState({
        minimumWorkingCommitment: value,
      });
    }
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.

    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <div className="workDetailsBlock">
        <h1>Work Details</h1>
        <div className="consoleFormBlock">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={2}
                className="workDetails"
              >
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      update={this.state.departmentId ? 1 : 0}
                      arrayOfData={this.state.Department_List}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelectChange}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.departmentId}
                      name="department"
                      label="Department"
                      required
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      update={this.state.branchId ? 1 : 0}
                      arrayOfData={
                        this.state.Branch_List
                          ? this.state.Branch_List.map((item) => ({
                              label: item.label,
                              value: item.value,
                              name: item.label,
                            }))
                          : ""
                      }
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelect}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.branchId}
                      name="Branch"
                      label="Branch"
                      required
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      update={this.state.catagoryId ? 1 : 0}
                      arrayOfData={this.state.Category_List}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelect}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.catagoryId}
                      name="Category"
                      label="Employment Category"
                      required
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      update={this.state.designationId ? 1 : 0}
                      arrayOfData={this.state.Designation}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelect}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.designationId}
                      name="Designation"
                      label={"Designation"}
                      isDisabled={
                        isEmpty(this.state.departmentId) ? true : false
                      }
                      required
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <div className="TextFieldBlock">
                      <label>
                        Date of Joining<span className="mandatoryField">*</span>
                      </label>
                    </div>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        disabled={this.state.DisableDateOfJoin}
                        inputFormat="dd-MM-yyyy"
                        name="DOJ"
                        value={this.state.dateOfJoin}
                        fullWidth
                        onChange={(e) => this.handleSelectDate(e, "DOJ")}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="consoleDatePicker"
                            style={{
                              backgroundColor: this.state.DisableDateOfJoin
                                ? "#ccd6db"
                                : "#ffffff",
                            }}
                          >
                            <input
                              ref={inputRef}
                              {...inputProps}
                              readOnly={true}
                              style={{
                                backgroundColor: this.state.DisableDateOfJoin
                                  ? "#ccd6db"
                                  : "#ffffff",
                              }}
                            />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      update={this.state.contractorId ? 1 : 0}
                      arrayOfData={this.state.Contractor}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelect}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.contractorId}
                      name="Contractor"
                      label={"Contractor"}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <InputCounter
                    label="Probation Period (in months)"
                    required
                    handleChange={this.handleChange}
                    field="1"
                    value={this.state.probationPeriod}
                  />
                </Box>
                <Box gridColumn="span 3">
                  <InputCounter
                    label="Minimum Working Years"
                    required
                    handleChange={this.handleChange}
                    field="2"
                    value={this.state.minimumWorkingCommitment}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <DynamicSelect
                      update={this.state.reportingEbId ? 1 : 0}
                      arrayOfData={this.state.Reporting_Manager_List}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={this.handleSelect}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      selected={this.state.reportingEbId}
                      name="ReportingManager"
                      label={"Reporting Manager"}
                      required
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldMobile
                      label="Office Mobile"
                      placeholder="Enter Here"
                      value={this.state.officeMobileNo}
                      onChange={this.onhandlechange("officeMobileNo")}
                    />
                  </div>
                </Box>
                <Box gridColumn="span 3">
                  <div className="consoleTextFieldBlock">
                    <TextFieldNormal
                      label="Office Email"
                      placeholder="Enter Here"
                      value={this.state.officeMailId}
                      onChange={this.onhandlechange("officeMailId")}
                    />

                    {this.state.ShowInValidText && (
                      <div className="TextFieldBlock">
                        <span className="text-danger">
                          Invalid Email Address
                        </span>
                      </div>
                    )}
                  </div>
                </Box>
                <Tooltip title="Employee Code Will Auto-Created if not Entered">
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        disabled={
                          this.state.tblHrmsEdOfficialDetailId !== "" &&
                          this.state.tblHrmsEdOfficialDetailId !== undefined
                            ? true
                            : false
                        }
                        label="Emp Code"
                        placeholder="Enter Here"
                        value={this.state.empCode}
                        onChange={this.onhandlechange("empCode")}
                      />
                    </div>
                  </Box>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
          {this.state.showPopup && (
            <CommonPopup>
              <div className="delAddItem">
                <div>
                  Please Enter All Mandatory Fields Of Personal Details{" "}
                </div>
                <div className="delAddItemBtns">
                  <input
                    type="button"
                    onClick={this.onclickOk.bind(this)}
                    value="Ok"
                    className="delYesBtn"
                  />
                </div>
              </div>
            </CommonPopup>
          )}
        </div>

        <div className="consoleFormButtonBlock">
          <Link to="/listOf_employees">
            <CustomButton label={"Cancel"} className="greenBorderButton" />
          </Link>
          {localStorage.getItem("EmpData") === null ? (
            <CustomButton
              label={"Save"}
              className="greenButton"
              handleClick={this.onClickSave}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickSave}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    departmentList,
    categoryList,
    empLevel,
    reporting_Manager_List,
    contractor,
    workLocation,
    designation,
    personalDetails,
    getEbId,
  } = state.EmployeeReducer;
  const { branchList } = state.DropDownListReducer;
  return {
    departmentList,
    branchList,
    categoryList,
    empLevel,
    reporting_Manager_List,
    contractor,
    workLocation,
    designation,
    personalDetails,
    getEbId,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getDepartmentListData,
    getCategoryList,
    getBranchList,
    getEmpLevel,
    getReportingManagerList,
    getContractor,
    getWorkLocation,
    getDesignation,
    viewbyidPersonalDetailsSuccessfull,
    viewbyidPersonalDetails,
    setPersonalDetails,
    clearPayScheme,
    ClearPaySchemeById,
  })(WorkDetails)
);
