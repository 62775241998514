import React, { Component } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { supplierTypesList } from "../../../store/Master/SupplierMaster/actions";
import CustomButton from "../../Buttons/Buttons";
import { serverApi } from "../../../helpers/Consts";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextFieldNormal from "../../TextField/TextFieldNormal";
import DynamicSelect from "../../../components/Dropdown/Master/DynamicSelectMasters";
class SupplierMasterFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      open: false,
      supplierName:"",
      supplierType:null,
      suppCode:"",
      supplierTypeList:[],
      AllSupplierTypesList:[
        {
          value: "S",
          label: "Store",
          name: "Store",
        },
        {
          value: "V",
          label: "Vendor",
          name: "Vendor",
        },
        {
          value: "J",
          label: "Jute",
          name: "Jute",
        },
        {
          value: "A",
          label: "Agent",
          name: "Agent",
        },
      ],
    };
  }

  componentDidMount() {
    const data1 = {
      companyId: localStorage.getItem("companyId"),
      cipher: this.state.userDit.cipher,
    };

      this.props.supplierTypesList(
        serverApi.SUPPLIER_TYPES_LIST,
        data1,
        this.props.history
      );
    
  }

  componentWillReceiveProps(props) {
    if (props.Supplier_TypesList) {
      if (props.Supplier_TypesList.data) {
        if (props.Supplier_TypesList.data.length !== 0) {
          var listData = [];
          listData.push({
            value: "",
            label: "Select",
            name: "Select",
          });
          props.Supplier_TypesList.data.map((item) => {
            listData.push({
              label: item.suppTypeDesc,
              name: item.suppTypeDesc,
              value: item.suppTypeCode,
            });
          });
        }
      }
      this.setState({
        supplierTypeList: listData,
      });
    }
  }

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleChange = (key)=> (event)=>{ 
    if(key === "supplierName"){
      this.setState({
        supplierName:event
      })
    }
    if(key === "suppCode"){
      this.setState({
        suppCode:event
      })
    }
  }

  handleSubmit = () => {
    var data = {
      supplierName:this.state.supplierName,
      supplierType:this.state.supplierType !=="" ?this.state.supplierType :null,
      suppCode:this.state.suppCode
    };

    this.props.handle_search(data);
    this.setState({
      open: false,
    });
  };

  handleClear = () => {
    this.setState({
    });
  };
  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "supplierType") {
      if(selectedValue == ""){
        this.setState({
          supplierType: null,
        });
      }else{
        this.setState({
          supplierType: selectedValue,
        });
      }
    }
  };

  render() {
    return (
      <Box>
        <Button onClick={this.handleClickOpen}>
          <Tooltip title="Filter">
            <FilterListOutlinedIcon />
          </Tooltip>
        </Button>
        <Dialog
          open={this.state.open}
          PaperProps={{
            sx: {
              position: " fixed",
              top: 0,
              right: 0,
              margin: 0,
              bottom: 0,
              maxHeight: "100vh",
            },
          }}
          keepMounted
          onClose={this.handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Grid
            spacing={0}
          >
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <FilterListOutlinedIcon />
                  <span>Filter</span>
                </div>
              </div>
            </div>
            <Grid item xs={12} className="filterBlockContainer">
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Supplier Name"
                        value={this.state.supplierName}
                        onChange={this.handleChange("supplierName")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldNormal
                        label="Supplier Code"
                        value={this.state.suppCode}
                        onChange={this.handleChange("suppCode")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.supplierTypeList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        selected={this.state.supplierType}
                        update={this.state.supplierType ? 1 : 0}
                        name="supplierType"
                        label="Supplier Type"
                        
                      />
                    </div>
                  </Box>

              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Search"}
                  className="greenButton"
                  handleClick={this.handleSubmit}
                  type="sumbit"
                />
              </div>
            </Grid>
          </Grid>
        </Dialog>
      </Box>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    Supplier_TypesList,
  } = state.Supplier_List;
  return {
    Supplier_TypesList,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    supplierTypesList,
      })(SupplierMasterFilter)
);
