import { React, useState, useEffect } from "react";
import { serverApi } from "../../../helpers/Consts";
import { Grid, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Fade from "@mui/material/Fade";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import {
  JuteTypeByMukam,
  QualityByJuteType,
  JuteIndentLineItemsProps,
} from "../../../store/Jute/JuteIndent/actions";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { isEmpty } from "../../../Utilities/helper";

const JuteIndentLineItems = (props) => {
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [HeaderData, setHeaderData] = useState({});
  const [LineItems, setLineItems] = useState([]);
  const [juteTypeList,setJuteTypeList]=useState([])
  const [qualityByJuteList,setqualityByJuteList]=useState([])
  const [LineItemsDisplayTxtField, setLineItemsDisplayTxtField] = useState({});
  const [LineItemsDisplayDropField, setLineItemsDisplayDropField] = useState({});
  const dispatch = useDispatch();
  const { JuteTypeByMukamData, QualityByJuteTypeData,JuteindentLineItems ,JuteIndentHeader} = useSelector(
    (state) => state.JuteIndentReducer
  );

  useEffect(() => {
    setLineItems(JuteindentLineItems);
    setHeaderData(JuteIndentHeader);
  }, [JuteIndentHeader, JuteindentLineItems]);

  useEffect(() => {
    dispatch(JuteTypeByMukam(
      serverApi.SELECT_ALL_JUTE_TYPE +
      JuteIndentHeader.mukam +
        "/getAllItemByMukam/" +
        localStorage.getItem("companyId") +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher
    ));
  }, []);

  useEffect(() => {
    if (JuteTypeByMukamData) {
      setJuteTypeList(JuteTypeByMukamData.data);
    }
  }, [JuteTypeByMukamData, QualityByJuteTypeData]);

  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };

  const handleScroll = (event) => {
    setisDropdownOpen(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handlePropSelectChange = (
    selectedValue,
    selectedName,
    name,
    selectedRow
  ) => {
    var lastRow = LineItems[LineItems.length - 1];
    if (name === "jute") {
      if (selectedRow === lastRow) {
        let LineItem = LineItems;
        let Obj = {
          id: LineItems.length + 1,
          IndentLineItemId:"",
          indentHeaderId: "",
          itemId: "",
          itemIdDesc: "",
          itemGroupId: "",
          itemGroupIdDesc: "",
          qualityCode:"",
          qualityCodeDesc: "",
          deptId: "",
          deptIdDesc: null,
          stock: 0,
          indentQuantity:"",
          unitId: "",
          additionalRequirement: null,
          dtlItemDesc: "",
          noOfBales: "",
          hsnCode: "",
          rate: 0,
          moisture: 0,
          taxId: 0,
          taxPercentage: 0,
          remainingQuantity: "",
          branchStock: null,
          pendingAtPo: 0,
          indentLineItemId: "",
          indentType: "",
          isActive:1,
          qualityByJuteList:[]
        }
        LineItem.push(Obj);
        setLineItems(LineItem);
        dispatch(JuteIndentLineItemsProps(LineItem));
      }
    }
    if (name === "jute") {
      let LineItemdata = LineItems.filter((row) => {
        if (row.id === selectedRow.id) {
          var Value = selectedName.split("^")
          row.itemId=selectedValue
          row.dtlItemDesc= Value[0]
          row.itemGroupId =Value[1]
          row.unitId=Value[2]
          row.qualityCode = ""
          row.qualityCodeDesc = ""
          row.stock = ""
        }
        return row;
      })
      setLineItems(LineItemdata);
      dispatch(JuteIndentLineItemsProps(LineItemdata));
      dispatch(QualityByJuteType(
        serverApi.QUALITY_BY_JUTE_TYPE +
          selectedValue +
          "/" +
          localStorage.getItem("companyId") +
          "/getQualityByItem",
          selectedRow.id
      ));
      displayField(selectedRow, "jute", 2);

    }
    if (name === "quality") {
      let filteredLineItems = LineItems.filter((item) => item.isActive !== 0);
      console.log(selectedRow, "selectedRow", selectedValue)
      if (selectedRow.qualityCode !== selectedValue) {
        var check = !!filteredLineItems.find(
          ({ qualityCode }) => Number(qualityCode) === Number(selectedValue)
        );
      }

      if(check) {
        swal("You can't create the record with the duplicate quality");
        let LineItemdata = LineItems.filter((row) => {
          if (row.id === selectedRow.id) {
            row.qualityCode = ""
            row.qualityCodeDesc = ""
            row.stock = ""
          }
          return row;
        })
        console.log(LineItemdata, "LineItemdata");
        setLineItems(LineItemdata);
        dispatch(JuteIndentLineItemsProps(LineItemdata));
        displayField(selectedRow, "quality", 2);
      } else {
        let LineItemdata = LineItems.filter((row) => {
          if (row.id === selectedRow.id) {
            var qualityName = selectedName.split("^^")
            row.qualityCode = selectedValue
            row.qualityCodeDesc = qualityName[0]
            row.stock = qualityName[1]
          }
          return row;
        })
        setLineItems(LineItemdata);
        dispatch(JuteIndentLineItemsProps(LineItemdata));
        displayField(selectedRow, "quality", 2);
      }
      }
  };

  const OnhandleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    let mname = key.split("_");
    if (mname[0] === "noOfBales") {
      let LineItemdata = LineItems.filter((rowItem) => {
        if (row.id === rowItem.id) {
          const regex = /^(100(\.0{1,3})?|(\d{1,2})(\.\d{1,3})?)$/;
            if (!regex.test(value)) {
              swal("Value must be between 0 and 100");
              rowItem.quality = ""
              rowItem.noOfBales = ""
              rowItem.indentQuantity = ""

              return rowItem
            }

            rowItem.noOfBales = value;
            var quality=""
            if (HeaderData.unitConversion == 1) {
               quality= (HeaderData.indentWeight * Number(value)) / 100
            } else {
              quality = Math.round(parseInt((Number(value) * 150) / 100));
            }
           rowItem.indentQuantity = quality
        }
        return rowItem;
      });
      setLineItems(LineItemdata);
      dispatch(JuteIndentLineItemsProps(LineItemdata));
    }
  };
  const onDelLineItem = (row) => {
    var FilterLineItems = LineItems.filter((item) => item.isActive !== 0);
    if (FilterLineItems.length == 1) {
      swal("you can't delete the last lineitem");
    } else {
      let indexItem = LineItems.findIndex((item) => item === row);
      let lineItems = [...LineItems];
      lineItems[indexItem].isActive = 0;
      setLineItems(lineItems);
      dispatch(JuteIndentLineItemsProps(lineItems));
    }
  };

  const displayField = (row, colname, type) => {
    if (colname == "jute") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
    } else if (colname == "quality") {
      const fieldKey1 = colname + row.id + "1";
      const fieldKey2 = colname + row.id + "2";

      setLineItemsDisplayTxtField((prevState) => ({
        ...prevState,
        [fieldKey1]: !prevState[fieldKey1],
      }));

      setLineItemsDisplayDropField((prevState) => ({
        ...prevState,
        [fieldKey2]: !prevState[fieldKey2],
      }));
      if(type ==1){
        dispatch(QualityByJuteType(
          serverApi.QUALITY_BY_JUTE_TYPE +
            row.itemId +
            "/" +
            localStorage.getItem("companyId") +
            "/getQualityByItem",
            row.id
        ));
      }
    }
  };

  let filterProduct = LineItems.filter((item) => item.isActive !== 0)
  let lastLineItem = LineItems[LineItems.length - 1];

  return (
    <>
       <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent" onScroll={handleScroll}>
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {tableHeaders.map((column) => (
                    <>
                        {column.header === "Jute Type" ||
                      column.header === "Quality" ||
                      column.header === "Quantity %" ? (
                        <>
                          <th>
                            {column.header}
                            <label style={{ color: "red" }}>*</label>
                          </th>
                        </>
                      ) : (
                        <>
                          <th>{column.header}</th>
                        </>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filterProduct.length !== 0 &&
                  filterProduct.map((row, i) => {
                    let rowIndex = filterProduct.findIndex((idd) => idd == row);

                    return (
                      <>
                        <tr>
                          <>
                            {RowData &&
                              RowData.map((col) =>
                                col.component === "Inputadorment" ? (
                                  <>
                                    <td>
                                      <div className="quantityDropdownBlock">
                                        <Grid className="selectAndTextfield">
                                          <Grid
                                            xs={12}
                                            md={12}
                                            className="dynamicSelectBlock"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <input
                                             onWheel={(e) => e.target.blur()}
                                             type={"text"}
                                              name={col.type + "_" + rowIndex}
                                              disabled={
                                                col.type === "indentQuantity"
                                                  ? true
                                                  : col.type === "unitId"
                                                  ? true
                                                  : col.type === "stock"
                                                  ? true
                                                  : false
                                              }
                                              value={
                                                col.type === "noOfBales"
                                                  ? row.noOfBales
                                                  : col.type === "indentQuantity"
                                                  ? row.indentQuantity
                                                  : col.type === "unitId"
                                                  ? row.unitId
                                                  : col.type === "stock"
                                                  ? row.stock
                                                  : ""
                                              }
                                              onChange={OnhandleChange({
                                                key: col.type + "_" + rowIndex,
                                                row,
                                              })}
                                           
                                              className="inputBlockContainer"
                                              style={{
                                                backgroundColor:
                                                  col.type === "indentQuantity" ||
                                                  col.type === "unitId" ||
                                                  col.type === "stock"
                                                    ? "#ccd6db"
                                                    : "",
                                              }}
                                      
                                            />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </td>
                                  </>
                                ) : col.component === "DynamicSelect" ? (
                                  <td>
                                    <div className="quantityDropdownBlock">
                                      <Grid className="selectAndTextfield">
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: LineItemsDisplayTxtField[
                                              col.type + row.id + "1"
                                            ]
                                              ? "none"
                                              : "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                    
                                            <input
                                              type="text"
                                              className="inputBlockContainer"
                                              style={{
                                                paddingRight: "8px",
                                            
                                              }}
                                              value={
                                                col.type === "jute"
                                              ? row.dtlItemDesc
                                              : col.type === "quality"
                                              ? row.qualityCodeDesc
                                              : ""
                                          }
                                          onChange={OnhandleChange({
                                            key: col.type + "_" + rowIndex,
                                            row,
                                          })}
                                          onClick={() => {
                                            displayField(row, col.type, 1);
                                          }}       
                                            /> 
                                        </Grid>
                                        <Grid
                                          xs={12}
                                          md={12}
                                          className="dynamicSelectBlock"
                                          style={{
                                            display: LineItemsDisplayDropField[
                                              col.type + row.id + "2"
                                            ]
                                              ? "flex"
                                              : "none",
                                            alignItems: "center",
                                          }}
                                        >
                                          <DynamicSelect
                                          arrayOfData={
                                            col.type === "jute"
                                              ? juteTypeList
                                              : col.type === "quality"
                                              ? row.qualityByJuteList
                                              : []
                                          }
                                            className="dropdownBlockContainer"
                                            onSelectChange={handlePropSelectChange}
                                            selected={
                                              col.type === "jute"
                                                ? row.itemId
                                                : col.type === "quality"
                                                ? row.qualityCode
                                                : ""
                                            }
                                            name={col.name}
                                            row={row}
                                            isDropdownOpen={isDropdownOpen}
                                            handleMenuOpen={handleMenuOpen}
                                          />
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </td>
                                ) : (
                                  ""
                                )
                              )}
                          </>
                          <td>
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Delete"
                            >
                              <IconButton
                                onClick={() => {
                                  onDelLineItem(row, i);
                                }}
                                disabled={
                                  filterProduct.length === 1
                                    ? true
                                    : lastLineItem === row
                                    ? true
                                    : false
                                }
                              >
                                <DeleteIcon className="deleteDisplayButton" />
                              </IconButton>
                            </Tooltip>
                          </td>
                        </tr>
                      </>
                    );
                  })
                  }
              </tbody>
            </table>
          </div>
    </div>
  </div>
    </>
  );
};

const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "jute",
    dropDown: "dropDown",
    name: "jute",
    Items: true,
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "quality",
    dropDown: "dropDown",
    name: "quality",
    Items: true,
  },

  {
    no: 3,
    component: "Inputadorment",
    type: "noOfBales",
  },
  {
    no: 4,
    component: "Inputadorment",
    type: "indentQuantity",
  },
  {
    no: 5,
    component: "Inputadorment",
    type: "unitId",
  },
  {
    no: 6,
    component: "Inputadorment",
    type: "stock",
  },
];

const tableHeaders = [
  {
    header: "Jute Type",
  },
  {
    header: "Quality",
  },
  {
    header: "Quantity %",
  },
  {
    header: "Quantity",
  },
  {
    header: "Unit",
  },
  {
    header: "Stock",
  },
  {
    header: "Action",
  },
];

export default JuteIndentLineItems;
