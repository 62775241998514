//import { Switch } from "react-router-dom";

import {
  API_ERRORS,
  SUPPLIER_MASTER_LIST,
  SUPPLIER_MASTER_VIEW_BY_ID,
  UPDATE_SUPPLIER_MASTER,
  SUPPLIER_MASTER_LIST_SUCCESSFULL,
  UPDATE_SUPPLIER_MASTER_SUCCESSFULL,
  SUPPLIER_MASTER_VIEW_BY_ID_SUCCESSFULL,
  SUPPLIER_LINE_ITEMS,
  CREATE_SUPPLIER_MASTER,
  CREATE_SUPPLIER_MASTER_SUCCESSFULL,
  GET_ALL_FILES,
  GET_ALL_FILES_SUCCESSFULL,
  DELETE_SUPPLIER_FILES,
  DELETE_SUPPLIER_FILES_SUCCESSFULL,
  UPLOAD_SUPPLIER_FILES,
  UPLOAD_SUPPLIER_FILES_SUCCESSFULL,
  SUPPLIER_FILLDETAILS,
  SUPPLIER_TYPES_LIST,
  SUPPLIER_TYPES_LIST_SUCCESSFULL,
  CLEAR_ALL_FILES,
  GET_ALL_ENTITY_TYPES,
  GET_ALL_ENTITY_TYPES_SUCCESSFULL,
  SUPPLIER_GST_VERIFICATION,
  SUPPLIER_GST_VERIFICATION_SUCCESSFULL
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  Supplier_Master_List: [],
  UpdateSupplierMaster: [],
  SupplierViewById: [],
  Supplier_LineItems: [
    {
      id: 1,
      branchAddress: "",
      city: "",
      state: "",
      // stateId: "",
      stateCode: "0",
      stateName: "",
      gstNo: "",
      gstFilename: "",
      gstFileId: "",
      contactNo: "",
      contactPerson: "",
      rcCopy: "",
      email: "",
      autoId: "",
      suppId: "",
      branchId: '',
      accountNumber: "",
      openningBalance: "",
      ifscCode: ""
    },
    {
      SupplierFillDetails: {
        supplierType: "",
        supplierName: "",
        branch: "",
        branchValue: "",
        emailAddress: "",
        contactPerson1: "",
        contactNumber1: "",
        contactPerson2: "",
        contactNumber2: "",
        ledgerGroup: "",
        ledgerGroupValue: "",
        openingBalance: "",
        panNumber: "",
        tanNumber: "",
        accountNumber: "",
        ifscCode: "",
        createdBy: null,
        createdOn: "",
        createdDate: "",
        comments: "",
        suppId: "",
        selectedMukamList: [],
        activeYes: true,
        activeNo: false,
        activeValue: "",
        msmeCertifiedYes: false,
        msmeCertifiedNo: true,
        cin:"",
        costCenter:""
      },
    },
  ],
  GetAllFiles_Data: [],
  delete_supplierMaster_Files: [],
  Upload_file: [],
  Supplier_TypesList:[],
  getAllEntityType:[],
  error_msg: null,
  getSupplierGstStatus:[]
};

const SupplierListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUPPLIER_MASTER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SUPPLIER_MASTER_LIST_SUCCESSFULL:
      state = {
        ...state,
        Supplier_Master_List: action.payload.response,
        loading: false,
      };
      break;
    case SUPPLIER_LINE_ITEMS:
      state = {
        ...state,
        Supplier_LineItems: action.payload,
      };
      break;
      case SUPPLIER_FILLDETAILS:
      state = {
        ...state,
        SupplierFillDetails: action.payload,
      };
      break;
    case SUPPLIER_MASTER_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SUPPLIER_MASTER_VIEW_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        SupplierViewById: action.payload.response,
        loading: false,
      };
      break;
    case UPDATE_SUPPLIER_MASTER:
      state = {
        ...state,
        loading: true,
      };

      break;
    case UPDATE_SUPPLIER_MASTER_SUCCESSFULL:
      state = {
        ...state,
        UpdateSupplierMaster: action.payload.response,
        loading: false,
      };
      break;

    case CREATE_SUPPLIER_MASTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_SUPPLIER_MASTER_SUCCESSFULL:
      state = {
        ...state,
        CreateSupplierMasterList: action.payload.response,
        loading: false,
      };
      break;
    case GET_ALL_FILES:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_ALL_FILES_SUCCESSFULL:
      state = {
        ...state,
        GetAllFiles_Data: action.payload.response.data,
        loading: false,
      };
      break;
      case CLEAR_ALL_FILES:
        state = {
          ...state,
          GetAllFiles_Data: action.payload,
          loading: false,
        };
        break;
    case DELETE_SUPPLIER_FILES:
      state = {
        ...state,
        loading: true,
      };
      break;

    case DELETE_SUPPLIER_FILES_SUCCESSFULL:
      state = {
        ...state,
        delete_supplierMaster_Files: action.payload.response,
        loading: false,
      };
      break;
    case UPLOAD_SUPPLIER_FILES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPLOAD_SUPPLIER_FILES_SUCCESSFULL:
      state = {
        ...state,
        Upload_file: action.payload.response,
        loading: false,
      };
      break;

      case SUPPLIER_TYPES_LIST:
        state = {
         ...state,
          loading: true,
        };
        break;

      case SUPPLIER_TYPES_LIST_SUCCESSFULL:
        state={
          ...state,
          Supplier_TypesList:action.payload.response,
          loading: false,

        }  
        break;
        
      case GET_ALL_ENTITY_TYPES:
        state = {
         ...state,
          loading: true,
        };
        break;

      case GET_ALL_ENTITY_TYPES_SUCCESSFULL:
        state={
          ...state,
          getAllEntityType:action.payload.response,
          loading: false,

        }  
        break;


        case SUPPLIER_GST_VERIFICATION:
          state = {
           ...state,
            loading: true,
          };
          break;
  
        case SUPPLIER_GST_VERIFICATION_SUCCESSFULL:
          state={
            ...state,
            getSupplierGstStatus:action.payload.response,
            loading: false,
  
          }  
          break;
    case API_ERRORS:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }

  return state;
};

export default SupplierListReducer;
