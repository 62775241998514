import {
  ADD_LEAVE_TRANSACTION,
  ADD_LEAVE_TRANSACTION_SUCCESSFULL,
  LEAVE_LEDGERLIST,
  LEAVE_LEDGERLIST_SUCCESSFULL,
  LEAVE_REQUEST_LIST,
  LEAVE_REQUEST_LIST_SUCCESSFULL,
  UPDATE_LEAVE_REQUEST,
  UPDATE_LEAVE_REQUEST_SUCCESSFULL,
  LEAVE_REQUEST_VIEW_BY_ID,
  LEAVE_REQUEST_VIEW_BY_ID_SUCCESSFULL,
  LEAVE_LEDGERLIST_FOR_EMPLOYEE,
  LEAVE_LEDGERLIST_FOR_EMPLOYEE_SUCCESSFULL,
  VIEW_BY_ID_FOR_LEAVEREQUEST,
  VIEW_BY_ID_FOR_LEAVEREQUEST_SUCCESSFULL,
  LEAVE_TYPE_SELECTION,
  LEAVE_TYPE_SELECTION_SUCCESSFULL,
  WORKER_DATA_BY_EB_NO,
  WORKER_DATA_BY_EB_NO_SUCCESSFULL,
  WORKER_DATA_CLEAR,
} from "./actionType";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  leaveRequestList: [],
  leaveRequestListColumn: [],
  leaveRequestListData: [],
  leaveLedgerList: [],
  addLeaveTransaction: [],
  updateLeave: [],
  leaveRequestViewList: [],
  leaveLedgerList_ForEmployee: [],
  ViewById_LeaveRequest: [],
  Leave_type_selection: [],
  WorkerDataByEbNo: [],
};

const LeaveRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case LEAVE_REQUEST_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LEAVE_REQUEST_LIST_SUCCESSFULL:
      state = {
        ...state,
        leaveRequestList: action.payload.response,
        loading: true,
      };
      break;
    // Leave Ledger
    case LEAVE_LEDGERLIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LEAVE_LEDGERLIST_SUCCESSFULL:
      state = {
        ...state,
        leaveLedgerList: action.payload.response.data,
        loading: true,
      };
      break;
    // LEAVE TRANSACTION
    case ADD_LEAVE_TRANSACTION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_LEAVE_TRANSACTION_SUCCESSFULL:
      state = {
        ...state,
        addLeaveTransaction: action.payload.response.data,
        loading: true,
      };
      break;
    // Update Leave
    case UPDATE_LEAVE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_LEAVE_REQUEST_SUCCESSFULL:
      state = {
        ...state,
        updateLeave: action.payload.response.data,
        loading: true,
      };
      break;
    case LEAVE_REQUEST_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LEAVE_REQUEST_VIEW_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        leaveRequestViewList: action.payload.response.data,
        loading: true,
      };
      break;
    case LEAVE_LEDGERLIST_FOR_EMPLOYEE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LEAVE_LEDGERLIST_FOR_EMPLOYEE_SUCCESSFULL:
      state = {
        ...state,
        leaveLedgerList_ForEmployee: action.payload.response.data,
        loading: true,
      };
      break;

    case VIEW_BY_ID_FOR_LEAVEREQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case VIEW_BY_ID_FOR_LEAVEREQUEST_SUCCESSFULL:
      state = {
        ...state,
        ViewById_LeaveRequest: action.payload.response.data,
        loading: true,
      };
      break;

    case LEAVE_TYPE_SELECTION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LEAVE_TYPE_SELECTION_SUCCESSFULL:
      state = {
        ...state,
        Leave_type_selection: action.payload.response.data,
        loading: true,
      };
      break;

    case WORKER_DATA_BY_EB_NO:
      state = {
        ...state,
        loading: true,
      };
      break;
    case WORKER_DATA_BY_EB_NO_SUCCESSFULL:
      state = {
        ...state,
        WorkerDataByEbNo: action.payload.response.data,
        loading: true,
      };
      break;

    case WORKER_DATA_CLEAR:
      state = {
        ...state,
        WorkerDataByEbNo: action.payload,
        loading: true,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LeaveRequestReducer;
