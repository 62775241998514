import moment from "moment";
import {
  API_ERRORS,
  CLEAR_JUTEGOVT_DELIVERY_ORDER_HEADER,
  CLEAR_JUTEGOVT_DELIVERY_ORDER_LINEITEMS,
  CLEAR_DELIVERY_ORDER_JUTEHESSIAN_HEADERS,
  CLEAR_DELIVERY_ORDER_JUTEHESSIAN_LINE_ITEMS,
  CLEAR_REGULAR_DELIVERY_ORDER_HEADER,
  CLEAR_REGULAR_DELIVERY_ORDER_LINE_ITEMS,
  CREATE_DELIVERY_ORDER,
  CREATE_DELIVERY_ORDER_SUCCESSFULL,
  CREATE_JUTE_GOVT_DELIVERY_ORDER,
  CREATE_JUTE_GOVT_DELIVERY_ORDER_SUCCESSFULL,
  CREATE_JUTE_YARN_DELIVERY_ORDER,
  ACCOUNT_LIST,
  ACCOUNT_LIST_SUCCESSFULL,
  CREATE_JUTE_YARN_DELIVERY_ORDER_SUCCESSFULL,
  JUTE_YARN_DELIVERY_ORDER_LINE_ITEMS,
  CLEAR_JUTE_YARN_DELIVERY_ORDER_LINE_ITEMS,
  JUTE_YARN_DELIVERY_ORDER_VIEW_BY_ID,
  JUTE_YARN_DELIVERY_ORDER_VIEW_BY_ID_SUCCESSFULL,
  SALES_ORDER_VIEW_BY_ID_FOR_JUTE_YARN,
  SALES_ORDER_VIEW_BY_ID_FOR_JUTE_YARN_SUCCESSFULL,
  CLEAR_JUTE_YARN_DELIVERY_ORDER_HEADER,
  JUTE_YARN_DELIVERY_ORDER_HEADER,
  UPDATE_JUTE_YARN_DELIVERY_ORDER,
  UPDATE_JUTE_YARN_DELIVERY_ORDER_SUCCESSFULL,
  CREATE_JUTEHESSIAN_DELIVERY_ORDER,
  CREATE_JUTEHESSIAN_DELIVERY_ORDER_SUCCESSFULL,
  DELIVERY_ORDER_JUTEHESSIAN_BY_ID,
  DELIVERY_ORDER_JUTEHESSIAN_BY_ID_SUCCESSFULL,
  DELIVERY_ORDER_JUTEHESSIAN_HEADERS,
  DELIVERY_ORDER_JUTEHESSIAN_LINE_ITEMS,
  DELIVERY_ORDER_LIST,
  CLEAR_DELIVERY_ORDER_LIST,
  DELIVERY_ORDER_LIST_SUCCESSFULL,
  GET_ALL_TRANSPORTER,
  GET_ALL_TRANSPORTER_SUCCESSFULL,
  GET_SO_DROPDOWN,
  GET_SO_DROPDOWN_SUCCESSFULL,
  JUTEGOVT_DELIVERY_ORDER_HEADER,
  JUTEGOVT_DELIVERY_ORDER_LINEITEMS,
  JUTE_GOVT_DELIVERY_ORDER_VIEWBY_ID,
  JUTE_GOVT_DELIVERY_ORDER_VIEWBY_ID_SUCCESSFULL,
  REGULAR_DELIVERY_ORDER_HEADER,
  REGULAR_DELIVERY_ORDER_LINE_ITEMS,
  REGULAR_DELIVERY_ORDER_VIEWBY_ID,
  REGULAR_DELIVERY_ORDER_VIEWBY_ID_SUCCESSFULL,
  SALES_ORDER_VIEW_BY_ID,
  SALES_ORDER_VIEW_BY_ID_FOR_JUTE_HESSIAN,
  SALES_ORDER_VIEW_BY_ID_FOR_JUTE_HESSIAN_SUCCESSFULL,
  SALES_ORDER_VIEW_BY_ID_SUCCESSFULL,
  UPDATE_JUTE_GOVT_DELIVERY_ORDER,
  UPDATE_JUTE_GOVT_DELIVERY_ORDER_SUCCESSFULL,
  UPDATE_JUTEHESSIAN_DELIVERY_ORDER,
  UPDATE_JUTEHESSIAN_DELIVERY_ORDER_SUCCESSFULL,
  UPDATE_REGULAR_DELIVERY_ORDER,
  UPDATE_REGULAR_DELIVERY_ORDER_SUCCESSFULL,
  SALES_ORDER_VIEW_BY_ID_FOR_JUTE_GOVT,
  SALES_ORDER_VIEW_BY_ID_FOR_JUTE_GOVT_SUCCESSFULL,
  JUTE_GOVT_DELIVERY_ORDER_OTHER_CHARGES,
  JUTE_GOVT_DELIVERY_ORDER_OTHER_CHARGES_SUCCESSFULL,
  DELIVERY_ORDER_ITEMS_BY_ITEM_GROUP_ID,
  DELIVERY_ORDER_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL,
  JUTE_GOVT_DELORDER_ITEMS_BY_ITEM_GROUP_ID,
  JUTE_GOVT_DELORDER_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL,
  UPLOAD_DO_FILES,
  UPLOAD_DO_FILES_SUCCESSFULL,
  GET_DO_FILES,
  DO_FILES_SUCCESSFULL,
  CLEAR_ALL_DO_FILES,
  DELETE_DO_FILE,
  DELETE_DO_FILE_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  DeliveryOrder_List: [],
  error_msg: null,
  SrDropDownData: [],
  TransporterList: [],
  CreateDeliveryOrder: [],
  Create_JuteYarn: [],
  UpdateJuteYarn: [],
  bankAccountList:[],
  JuteYarnDeliveryOrderViewById: [],
  Create_JuteHessian: [],
  DeliveryOrderJuteHessianById: [],
  UpdateJuteHessian: [],
  DOFiles:{},
  Uploaded_do_Files: [],
  delete_do_File: [],
  DeliveryOrderItemBy_ItemGroupId: [],
  JuteGovtDelOrderItemBy_ItemGroupId: [],
  RegularDeliveryOrderHeader: {
    salesOrderId: "",
    salesOrderDate: null,
    invoiceType: 1,
    branch: "",
    branchName: "",
    deliveryDate: null,
    salesOrderNumber: "",
    quotationNumber: "",
    quotationDate: null,
    vehicleNo: "",
    finYear: "",
    bankId:"",
    bankName:"",
    bankBranch:"",
    accountNo:"",
    ifscCode:"",
    micrCode:"",
    createdBy: null,
    createdDate: "",
    company: "",
    brokerId: null,
    brokerName: "",
    customerDisable: true,
    billingDisbale: true,
    shippingDisable: true,
    customerId: null,
    customerName: "",
    brokerContactNumber: null,
    brokerContactPerson: "",
    consigneeContactNumber: "",
    consigneeContactPerson: "",
    billingTo: null,
    billingToAddress: "",
    billingToGST: "",
    billingContactNo: "",
    billingContactPerson: "",
    billingToEmail: "",
    billingState: "",
    shippingTo: null,
    shippingToAddress: "",
    shippingToGST: "",
    shippingContactNo: "",
    shippingContactPerson: "",
    shippingToEmail: "",
    shippingState: "",
    termsConditionType: "",
    transporter: null,
    transporterName: "",
    transporterAddress: "",
    transporterStateCode: "",
    transporterStateName: "",
    status: null,
    statusName: "",
    challanNo: "",
    challanDate: "",
    taxType: null,
    footerNote: "",
    internalNote: "",
    termsCondition: "",
    deliveryTerms: "",
    paymentTerms: "",
    deliveryDays: "",
    freightCharges: "",
    amountWithoutTax: "",
    amountWithTax: "",
    updatedBy: "",
    updatedOn: "",
    updatedByDesc: "",
    approvedBy: "",
    approvedOn: "",
    approvedBydesc: "",
    isActive: 1,
    regDeliveryOrderId: "",
    deliveryOrderNo: "",
    createdByDesc: "",
    invoiceTypeName: "",
    billingStateName: "",
    shippingStateName: "",
    printedOn: "",
    transporterName: "",
    taxName: "",
    approveStatus: "",

    // transporterAddress: "",
    // transporterStateName: "",
    // TransporterStateCode: "",
    // PCSONumber: "",
    // PCSODate: "",
    // PackagingType: "",
    // ammendmentNo1: "",
    // ammendmentNo2: "",
    // ammendmentNo3: "",
    // ammendmentNo4: "",
    // ammendmentDate1: null,
    // ammendmentDate2: null,
    // ammendmentDate3: null,
    // ammendmentDate4: null,
    totalIGST: "",
    totalCGST: "",
    totalSGST: "",
    subTotal: "",
    netTotal: "",
    taxTotal: "",
    TotalAmount: "",
    ApproveButton: false,
    RejectButton: false,
    UpdateButton: true,
    EditButton: false,
    disableBroker:false,
  },
  RegularDeliveryOrderLineItems: [
    {
      id: 0,
      itemGroupId: "",
      itemGroupName: "",
      itemGroupValue: "",
      itemId: "",
      itemName: "",
      itemCode: "",
      itemValue: "",
      remarks: "",
      itemMake: "",
      hsncode: "",
      rate: "",
      discountedRate: "",
      quantity: "",
      uom: "",
      taxPercent: "",
      createdBy: "",
      createdDate: "",
      companyId: "",
      salesId: "",
      salesLineItemId: "",
      invoiceQty: "",
      igstAmount: "",
      igstPercent: "",
      cgstAmount: "",
      cgstPercent: "",
      sgstAmount: "",
      sgstPercent: "",
      cessAmount: "",
      discountType: "",
      discountName: "",
      discount: "",
      discountedRate: "",
      discountAmount:"",
      netAmount: "",
      totalAmount: "",
      balesPacketSlNo: "",
      status: 1,
      isActive: 1,
      currency: "INR",
      doBalQty: null,
      deliveryOrderLineItemId: "",
      regDeliveryOrderId: "",
      updatedBy: null,
      updatedOn: null,
    },
  ],
  UpdateDeliveryOrder: [],
  RegularDeliveryOrderViewBy: [],
  SalesOrderViewById: [],
  SalesOrderViewByIdForJuteYarn: [],
  SalesOrderViewByIdForJuteHessian: [],
  JuteGovtDeliveryOrderHeader: {
    regDeliveryOrderId: "",
    regularDeliveryOrderLineItemId: "",
    govtDeliveryOrderId: "",
    govtDeliveryOrderLineItemId: "",
    finYear: "",
    createdBy: null,
    createdByDesc: "",
    createdDate: "",
    deliveryDate: null,
    deliveryOrderNo: "",
    invoiceType: 2,
    invoiceTypeName: null,
    company: "",
    companyName: "",
    branch: "",
    branchName: "",
    branchAddress: "",
    branchGstNo: "",
    branchContactPerson: "",
    branchContactNo: "",
    branchEmail: "",
    salesOrderId: "",
    bankId:"",
    bankName:"",
    bankBranch:"",
    accountNo:"",
    ifscCode:"",
    micrCode:"",
    salesOrderNumber: "",
    salesOrderDate: null,
    brokerId: null,
    brokerName: "",
    customerId: null,
    customerName: "",
    // billingTo: null,
    // billingToAddress: "",
    // billingStateName: "",

    // shippingTo: null,
    // shippingToAddress: "",
    // shippingStateName: "",
    billingTo: null,
    billingToAddress: "",
    billingToGST: "",
    billingContactNo: "",
    billingContactPerson: "",
    billingToEmail: "",
    billingState: "",
    shippingTo: null,
    shippingToAddress: "",
    shippingToGST: "",
    shippingContactNo: "",
    shippingContactPerson: "",
    shippingToEmail: "",
    shippingState: "",
    termsConditionType: null,
    termsConditionName: null,
    transporter: null,
    transporterName: "",
    status: null,
    challanNo: "",
    challanDate: "",
    taxType: null,
    taxName: "",
    footerNote: "",
    internalNote: "",
    termsCondition: "",
    deliveryTerms: "",
    paymentTerms: "",
    deliveryDays: "",
    freightCharges: "",
    amountWithoutTax: "",
    amountWithTax: "",
    updatedBy: "",
    updatedByDesc: "",
    updatedOn: "",
    approvedBy: "",
    approvedByDesc: "",
    approvedOn: "",
    isActive: 1,
    approveStatus: "",
    quotationNumber: "",
    quotationDate: null,
    currency: "INR",

    totalIGST: "0.00",
    totalCGST: "0.00",
    totalSGST: "0.00",
    subTotal: "0.00",
    netTotal: "0.00",
    taxTotal: "0.00",
    TotalAmount: "0.00",

    pcsoNo: "",
    pcsoDate: "",

    ammendmentNo: "",
    ammendmentDate: null,
    ammendmentNo2: "",
    ammendmentDate2: null,
    ammendmentNo3: "",
    ammendmentDate3: null,
    ammendmentNo4: "",
    ammendmentDate4: null,
    ammendmentNo5: "",
    ammendmentDate5: null,

    destinationRailHead: "",
    loadingPoint: "",

    ApproveButton: false,
    RejectButton: false,
    UpdateButton: true,
    EditButton: false,
  },
  JuteGovtDeliveryOrderLineItems: [
    {
      id: 0,
      createdBy: "",
      createdDate: "",
      companyId: "",
      salesId: "",
      salesLineItemId: "",
      regularSalesOrderId: "",
      govtSalesOrderLineItemId: "",
      hsnCode: "",
      itemGroupId: "",
      itemGroupName: "",
      itemGroupValue: "",
      itemId: "",
      itemName: "",
      itemCode: "",
      itemValue: "",
      hsncode: "",
      itemMake: "",
      quantity: "0.0",
      uom: "",
      rate: "",
      invoiceQty: "",
      igstAmount: "",
      igstPercent: "",
      cgstAmount: "",
      cgstPercent: "",
      sgstAmount: "",
      sgstPercent: "",
      cessAmount: "",
      discountType: "",
      discount: "",
      discountedRate: "",
      discountAmount:"",
      netAmount: "",
      totalAmount: "",
      remarks: "",
      balesPacketSlNo: "",
      status: 1,
      isActive: 1,
      govtIsActive:1,
      govtDeliveryBalesPacketSlNo: "",
      taxPercent: "",
      currency: "INR",
      govtDeliveryRateUom: "",
      govtDeliveryRateUom2: "",
      govtDeliveryRateUom3: "",
      govtDeliveryQuantityUom3: "",
      govtDeliveryRelationValue: "",
      govtDeliveryRelationValue2: "",
      govtDeliveryRelationValue3: "",
      govtDeliveryQtyCalculatedValue: "",
      govtDeliveryQtyCalculatedValue2: "",
      govtDeliveryQtyCalculatedValue3: "",
      govtDeliveryRateCalculatedValue: "",
      govtDeliveryRateCalculatedValue2: "",
      govtDeliveryRateCalculatedValue3: "",
    },
  ],
  CreateJuteGovt: [],
  UpdateJuteGovt: [],
  JuteGovtDeliveryOrderViewBy: [],
  SalesOrderViewByIdForJuteGovt: [],
  //Jute Hessian
  DoJuteHessianHeaders: {
    salesOrderId: "",
    salesOrderDate: null,
    invoiceType: 1,
    finYear: "",
    createdBy: null,
    createdDate: "",
    deliveryDate: null,
    company: "",
    branch: "",
    branchName: "",
    bankId:"",
    bankName:"",
    bankBranch:"",
    accountNo:"",
    ifscCode:"",
    micrCode:"",
    brokerId: null,
    brokerName: "",
    customerId: "",
    billingTo: null,
    billingToAddress: "",
    billingToGST: "",
    billingContactNo: "",
    billingContactPerson: "",
    billingToEmail: "",
    billingState: "",
    brokerCommisionPercent:"",
    shippingTo: null,
    shippingToAddress: "",
    shippingToGST: "",
    shippingContactNo: "",
    shippingContactPerson: "",
    shippingToEmail: "",
    shippingState: "",
    termsConditionType: "",
    transporter: null,
    transporterName:"",
    transporterAddress:"",
    transporterStateName:"",
    transporterGst: "",
    vehicleNo: "",
    status: null,
    challanNo: "",
    challanDate: "",
    taxType: null,
    footerNote: "",
    internalNote: "",
    termsCondition: "",
    deliveryTerms: "",
    paymentTerms: "",
    deliveryDays: "",
    freightCharges: "",
    amountWithoutTax: "",
    amountWithTax: "",
    updatedBy: "",
    updatedOn: "",
    updatedByDesc: "",
    approvedBy: "",
    approvedOn: "",
    approvedBydesc: "",
    isActive: 1,
    totalIGST: "",
    totalCGST: "",
    totalSGST: "",
    subTotal: "",
    netTotal: "",
    taxTotal: "",
    TotalAmount: "",
    ApproveButton: false,
    RejectButton: false,
    UpdateButton: true,
    EditButton:false,
    regDeliveryOrderId: null,
    deliveryOrderNo: null
  },
  DoJuteHessianLineItems: [
    {
      id: 0,
      itemGroupId: "",
      itemGroupName: "",
      itemGroupValue: "",
      itemId: "",
      itemName: "",
      itemCode: "",
      itemValue: "",
      remarks: "",
      itemMake: "",
      hsncode: "",
      rate: "",
      discountedRate: "",
      discountAmount:"",
      quantity: "",
      uom: "",
      taxPercent: "",
      createdBy: "",
      createdDate: "",
      companyId: "",
      salesId: "",
      salesLineItemId: "",
      invoiceQty: "",
      doBalQty:"",
      igstAmount: "",
      igstPercent: "",
      cgstAmount: "",
      cgstPercent: "",
      sgstAmount: "",
      sgstPercent: "",
      cessAmount: "",
      discountType: "",
      discountName: "",
      discount: "",
      discountedRate: "",
      netAmount: "",
      totalAmount: "",
      balesPacketSlNo: "",
      status: 1,
      isActive: 1,
      hessianIsActive:1,
      currency: "INR",
      deliveryOrderLineItemId: "",
      regDeliveryOrderId: "",
      updatedBy: null,
      updatedOn: null,
      hessianQuantityUom: "",
      hessianQuantityUom2: "",
      hessianRateIncludingBrokerage: "",
      hessianBalesPacketSlNo: "",
      hessianAmountExcludingBrokerage:""
    },
  ],
  //jute yarn
  JuteYarnDeliveryOrderHeader: {
    yarnDeliveryOrderId:"",
    regDeliveryOrderId: null,
    finYear: "",
    createdBy: null,
    createdByDesc: "",
    createdDate: "",
    deliveryDate: null,
    deliveryOrderNo: "",
    invoiceType: null,
    invoiceTypeName: "",
    company: null,
    companyName: "",
    branch: "",
    branchName: "",
    salesOrderId: "",
    salesOrderNumber: "",
    salesOrderDate: null,
    brokerId: null,
    brokerName: "",
    bankId:"",
    bankName:"",
    bankBranch:"",
    accountNo:"",
    ifscCode:"",
    micrCode:"",
    brokerContactPerson: "",
    brokerContactNumber: "",
    consigneeContactPerson: "",
    consigneeContactNumber: "",
    customerId: "",
    customerName: "",
    billingTo: "",
    billingToAddress: "",
    billingStateName: "",
    billedToContactNumber: "",
    billedToGstin: "",
    billedToContactPerson: "",
    billedToEmail: "",
    shippingTo: "",
    shippingToAddress: "",
    shippingStateName: "",
    shippedToContactNumber: "",
    shippedToGstin: "",
    shippedToContactPerson: "",
    shippedToEmail: "",
    quotationDate: "",
    quotationNumber: "",
    printedOn: "",
    termsConditionType: "",
    termsConditionName: "",
    transporter: "",
    transporterName: "",
    transporterAddress: "",
    transporterStateCode: "",
    transporterStateName: "",
    transporterGst: "",
    vehicleNumber: "",
    status: 1,
    statusDesc: "",
    challanNo: "",
    challanDate: null,
    taxType: "",
    taxName: "",
    footerNote: "",
    internalNote: "",
    termsCondition: "",
    deliveryTerms: "",
    paymentTerms: "",
    deliveryDays: "",
    freightCharges: "",
    amountWithoutTax: "",
    amountWithTax: "",
    roundOffValue: 0,
    roundingFlag: false,
    updatedBy: "",
    updatedByDesc: "",
    updatedOn:"",
    approvedBy: "",
    approvedByDesc: "",
    approvedOn: "",
    isActive: 1,
    approveStatus: false,
    brokerCommisionPercent: "",
    statusName: "",
    totalIGST: "",
    totalCGST: "",
    totalSGST: "",
    subTotal: "",
    netTotal: "",
    taxTotal: "",
    TotalAmount: "",
    ApproveButton: false,
    RejectButton: false,
    UpdateButton: true,
    EditButton: false,
  },

  JuteYarnDeliveryOrderLineItems: [
    {
      id: 0,
      regularDeliveryOrderLineItemId: "",
      createdBy: "",
      createdDate: "",
      regDeliveryOrderId: "",
      companyId: "",
      salesId: "",
      salesLineItemId: "",
      itemGroupId: "",
      itemGroupName: "",
      itemGroupValue: "",
      itemId: "",
      itemName: "",
      itemCode: "",
      itemValue: "",
      hsncode: "",
      itemMake: "",
      doBalQty: null,
      quantity: "",
      uom: "",
      rate: "",
      invoiceQty: "",
      taxPercent:"",
      igstAmount: "",
      igstPercent: "",
      cgstAmount: "",
      cgstPercent: "",
      sgstAmount: "",
      sgstPercent: "",
      cessAmount: "",
      discountType: "",
      discount: "",
      discountName: "",
      discountedRate: "",
      discountAmount:"",
      netAmount: "",
      totalAmount: "",
      remarks: "",
      balesPacketSlNo: "",
      isActive: 1,
      yarnIsActive:1,
      status: 1,
      updatedBy: null,
      updatedOn: null,
      yarnDeliveryOrderLineItemId: "",
      yarnDeliveryOrderId: "",
      yarnRateUom: "",
      yarnRateUomId: "",
      yarnRateUomRelationValue: "",
      yarnRateCalculatedValue: "",
      yarnQuantityUom: "",
      yarnQuantityUomId: "",
      yarnQuantityUomRelationValue: "",
      yarnQuantityCalculatedValue: "",
      yarnVariableRelationValue: "",
      yarnRateIncludingBrokerage:"",
      yarnRateExcludingBrokerage:"",
      yarnUpdatedBy: "",
      yarnUpdatedOn: "",
      yarnIsActive: 1,
      hessianDeliveryOrderLineItemId: "",
      hessianDeliveryOrderId: "",
      hessianQuantityUom: "",
      hessianQuantityUomId: "",
      hessianQuantityRelationValue: "",
      hessianQuantityCalculatedValue: "",
      hessianRateUom: "",
      hessianRateUomId: "",
      hessianRateRelationValue: "",
      hessianRateCalculatedValue: "",
      hessianRateIncludingBrokerage: "",
      hessianRateExcludingBrokerage: "",
      hessianBalesPacketSlNo: "",
      hessianUpdatedBy: "",
      hessianUpdatedOn: "",
      hessianIsActive: "",
      govtDeliveryOrderLineItemId: "",
      govtDeliveryOrderId: "",
      govtDeliveryRateUom: "",
      govtDeliveryRateUom2: "",
      govtDeliveryRateUom3: "",
      govtDeliveryQuantityUom: "",
      govtDeliveryQuantityUom2: "",
      govtDeliveryQuantityUom3: "",
      govtDeliveryRelationValue: "",
      govtDeliveryRelationValue2: "",
      govtDeliveryRelationValue3: "",
      govtDeliveryQtyCalculatedValue: "",
      govtDeliveryQtyCalculatedValue2: "",
      govtDeliveryQtyCalculatedValue3: "",
      govtDeliveryRateCalculatedValue: "",
      govtDeliveryRateCalculatedValue2: "",
      govtDeliveryRateCalculatedValue3: "",
      govtDeliveryBalesPacketSlNo: "",
      govtUpdatedBy: "",
      govtUpdatedOn: "",
      yarnSalesOrderLineItemId:"",
      ItemNameData: [],
    },
  ],
};

const DeliveryOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELIVERY_ORDER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case DELIVERY_ORDER_LIST_SUCCESSFULL:
      state = {
        ...state,
        DeliveryOrder_List: action.payload.response,
        loading: false,
      };
      break;
    case CLEAR_DELIVERY_ORDER_LIST:
      state = {
        ...state,
        DeliveryOrder_List: action.payload,
        loading: false,
      };
      break;

    case GET_ALL_TRANSPORTER:
      state = {
        ...state,
        loading: true,
      };
      break;

      case ACCOUNT_LIST:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case ACCOUNT_LIST_SUCCESSFULL:
        state = {
          ...state,
          bankAccountList: action.payload.response,
          loading: false,
        };
        break;

    case GET_ALL_TRANSPORTER_SUCCESSFULL:
      state = {
        ...state,
        TransporterList: action.payload.response,
        loading: false,
      };
      break;
    case REGULAR_DELIVERY_ORDER_HEADER:
      state = {
        ...state,
        RegularDeliveryOrderHeader: action.payload,
        loading: false,
      };
      break;
    case REGULAR_DELIVERY_ORDER_LINE_ITEMS:
      state = {
        ...state,
        RegularDeliveryOrderLineItems: action.payload,
        loading: true,
      };
      break;

    case DELIVERY_ORDER_ITEMS_BY_ITEM_GROUP_ID:
      state = {
        ...state,
        loading: true,
        dataLoaded: false,
      };
      break;

    case DELIVERY_ORDER_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL:
      state = {
        ...state,
        DeliveryOrderItemBy_ItemGroupId: action.payload.response,
        loading: false,
        dataLoaded: true,
      };
      break;
    case GET_SO_DROPDOWN:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_SO_DROPDOWN_SUCCESSFULL:
      state = {
        ...state,
        SrDropDownData: action.payload.response,
        loading: false,
      };
      break;
    case CLEAR_REGULAR_DELIVERY_ORDER_HEADER:
      state = {
        ...state,
        RegularDeliveryOrderHeader: initialState.RegularDeliveryOrderHeader,
        loading: true,
      };
      break;
      //upload documents
      case "UPDATE_DO_FILES":
      state = {
        ...state,
        DOFiles: {
          ...state.DOFiles,
          data: { ...state.DOFiles.data, data: action.payload },
        },
      };
      case UPLOAD_DO_FILES:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPLOAD_DO_FILES_SUCCESSFULL:
      state = {
        ...state,
        Uploaded_do_Files: action.payload.response,
        loading: false,
      };
      break;
    case DELETE_DO_FILE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_DO_FILE_SUCCESSFULL:
      state = {
        ...state,
        delete_do_File: action.payload.response,
        loading: false,
      };
      break;
      case GET_DO_FILES:
        state = {
          ...state,
          loading: true,
        };
        break;
      case DO_FILES_SUCCESSFULL:
        state = {
          ...state,
          DOFiles: action.payload.response,
          loading: false,
        };
        break;
    case CLEAR_REGULAR_DELIVERY_ORDER_LINE_ITEMS:
      state = {
        ...state,
        RegularDeliveryOrderLineItems: [
          {
            id: 0,
            itemGroupId: "",
            itemGroupName: "",
            itemGroupValue: "",
            itemId: "",
            itemName: "",
            itemCode: "",
            itemValue: "",
            remarks: "",
            itemMake: "",
            hsncode: "",
            rate: "",
            discountedRate: "",
            quantity: "",
            uom: "",
            taxPercent: "",
            createdBy: "",
            createdDate: "",
            companyId: "",
            salesId: "",
            salesLineItemId: "",
            invoiceQty: "",
            igstAmount: "",
            igstPercent: "",
            cgstAmount: "",
            cgstPercent: "",
            sgstAmount: "",
            sgstPercent: "",
            cessAmount: "",
            discountType: "",
            discountName: "",
            discount: "",
            discountedRate: "",
            discountAmount:"",
            netAmount: "",
            totalAmount: "",
            balesPacketSlNo: "",
            status: 1,
            isActive: 1,
            currency: "INR",
            deliveryOrderLineItemId: "",
            regDeliveryOrderId: "",
            updatedBy: null,
            updatedOn: null,
          },
        ],
        loading: true,
      };
      break;
    case SALES_ORDER_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;

    case SALES_ORDER_VIEW_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        SalesOrderViewById: action.payload.response,
        loading: false,
      };
      break;
    case CREATE_DELIVERY_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CREATE_DELIVERY_ORDER_SUCCESSFULL:
      state = {
        ...state,
        CreateDeliveryOrder: action.payload.response,
        loading: false,
      };
      break;
    case REGULAR_DELIVERY_ORDER_VIEWBY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;

    case REGULAR_DELIVERY_ORDER_VIEWBY_ID_SUCCESSFULL:
      state = {
        ...state,
        RegularDeliveryOrderViewBy: action.payload.response,
        loading: false,
      };
      break;
    case UPDATE_REGULAR_DELIVERY_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_REGULAR_DELIVERY_ORDER_SUCCESSFULL:
      state = {
        ...state,
        UpdateDeliveryOrder: action.payload.response,
        loading: false,
      };
      break;

    case JUTEGOVT_DELIVERY_ORDER_HEADER:
      state = {
        ...state,
        JuteGovtDeliveryOrderHeader: action.payload,
        loading: true,
      };
      break;
    case JUTEGOVT_DELIVERY_ORDER_LINEITEMS:
      state = {
        ...state,
        JuteGovtDeliveryOrderLineItems: action.payload,
        loading: true,
      };
      break;

    case CLEAR_JUTEGOVT_DELIVERY_ORDER_HEADER:
      state = {
        ...state,
        JuteGovtDeliveryOrderHeader: initialState.JuteGovtDeliveryOrderHeader,
        loading: true,
      };
      break;
    case CLEAR_JUTEGOVT_DELIVERY_ORDER_LINEITEMS:
      state = {
        ...state,
        JuteGovtDeliveryOrderLineItems: [
          {
            id: 0,
            createdBy: null,
            createdDate: "",
            companyId: null,
            salesId: "",
            salesLineItemId: "",
            hsnCode: "",
            itemGroupId: "",
            itemGroupName: "",
            itemGroupValue: "",
            itemId: "",
            itemName: "",
            itemCode: "",
            itemValue: "",
            hsncode: "",
            itemMake: "",
            quantity: "",
            uom: "",
            rate: "",
            invoiceQty: "",
            igstAmount: "",
            igstPercent: "",
            cgstAmount: "",
            cgstPercent: "",
            sgstAmount: "",
            sgstPercent: "",
            cessAmount: "",
            discountType: "",
            discount: "",
            discountedRate: "",
            discountAmount:"",
            netAmount: "",
            totalAmount: "",
            remarks: "",
            balesPacketSlNo: "",
            status: 1,
            govtDeliveryRateUom: "",
            govtDeliveryRateUom2: "",
            govtDeliveryRateUom3: "",
            govtDeliveryQuantityUom: "",
            govtDeliveryQuantityUom2: "",
            govtDeliveryQuantityUom3: "",
            govtDeliveryRelationValue: "",
            govtDeliveryRelationValue2: "",
            govtDeliveryRelationValue3: "",
            govtDeliveryQtyCalculatedValue: "",
            govtDeliveryQtyCalculatedValue2: "",
            govtDeliveryQtyCalculatedValue3: "",
            govtDeliveryRateCalculatedValue: "",
            govtDeliveryRateCalculatedValue2: "",
            govtDeliveryRateCalculatedValue3: "",
            isActive: 1,
            govtIsActive:1,
            govtDeliveryBalesPacketSlNo: "",
            taxPercent: "",
          },
        ],
        loading: true,
      };
      break;

    case CREATE_JUTE_GOVT_DELIVERY_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CREATE_JUTE_GOVT_DELIVERY_ORDER_SUCCESSFULL:
      state = {
        ...state,
        CreateJuteGovt: action.payload.response,
        loading: false,
      };
      break;

    case UPDATE_JUTE_GOVT_DELIVERY_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_JUTE_GOVT_DELIVERY_ORDER_SUCCESSFULL:
      state = {
        ...state,
        UpdateJuteGovt: action.payload.response,
        loading: false,
      };
      break;

    case JUTE_GOVT_DELIVERY_ORDER_VIEWBY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;

    case JUTE_GOVT_DELIVERY_ORDER_VIEWBY_ID_SUCCESSFULL:
      state = {
        ...state,
        JuteGovtDeliveryOrderViewBy: action.payload.response,
        loading: false,
      };
      break;

    //Jute Hessian
    case CREATE_JUTEHESSIAN_DELIVERY_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_JUTEHESSIAN_DELIVERY_ORDER_SUCCESSFULL:
      state = {
        ...state,
        Create_JuteHessian: action.payload.response,
        loading: false,
      };
      break;
    case DELIVERY_ORDER_JUTEHESSIAN_HEADERS:
      state = {
        ...state,
        DoJuteHessianHeaders: action.payload,
        loading: true,
      };
      break;

    case DELIVERY_ORDER_JUTEHESSIAN_LINE_ITEMS:
      state = {
        ...state,
        DoJuteHessianLineItems: action.payload,
        loading: true,
      };
      break;

    case DELIVERY_ORDER_JUTEHESSIAN_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;

    case DELIVERY_ORDER_JUTEHESSIAN_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        DeliveryOrderJuteHessianById: action.payload.response,
        loading: false,
      };
      break;
    case UPDATE_JUTEHESSIAN_DELIVERY_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_JUTEHESSIAN_DELIVERY_ORDER_SUCCESSFULL:
      state = {
        ...state,
        UpdateJuteHessian: action.payload.response,
        loading: false,
      };
      break;
    case SALES_ORDER_VIEW_BY_ID_FOR_JUTE_HESSIAN:
      state = {
        ...state,
        loading: true,
      };
      break;

    case SALES_ORDER_VIEW_BY_ID_FOR_JUTE_HESSIAN_SUCCESSFULL:
      state = {
        ...state,
        SalesOrderViewByIdForJuteHessian: action.payload.response,
        loading: false,
      };
      break;
    case CLEAR_DELIVERY_ORDER_JUTEHESSIAN_HEADERS:
      state = {
        ...state,
        DoJuteHessianHeaders: initialState.DoJuteHessianHeaders,
      };
      break;
    case CLEAR_DELIVERY_ORDER_JUTEHESSIAN_LINE_ITEMS:
      state = {
        ...state,
        DoJuteHessianLineItems: [
          {
            id: 0,
            itemGroupId: "",
            itemGroupName: "",
            itemGroupValue: "",
            itemId: "",
            itemName: "",
            itemCode: "",
            itemValue: "",
            remarks: "",
            itemMake: "",
            hsncode: "",
            rate: "",
            discountedRate: "",
            quantity: "",
            uom: "",
            taxPercent: "",
            createdBy: "",
            createdDate: "",
            companyId: "",
            salesId: "",
            salesLineItemId: "",
            invoiceQty: "",
            igstAmount: "",
            igstPercent: "",
            cgstAmount: "",
            cgstPercent: "",
            sgstAmount: "",
            sgstPercent: "",
            cessAmount: "",
            discountType: "",
            discountName: "",
            discount: "",
            discountedRate: "",
            discountAmount:"",
            netAmount: "",
            totalAmount: "",
            balesPacketSlNo: "",
            status: 1,
            isActive: 1,
            hessianIsActive:1,
            currency: "INR",
            deliveryOrderLineItemId: "",
            regDeliveryOrderId: "",
            updatedBy: null,
            updatedOn: null,
          },
        ],
        loading: true,
      };
      break;
    //Jute Yarn

    case CREATE_JUTE_YARN_DELIVERY_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_JUTE_YARN_DELIVERY_ORDER_SUCCESSFULL:
      state = {
        ...state,
        Create_JuteYarn: action.payload.response,
        loading: false,
      };
      break;
    case JUTE_YARN_DELIVERY_ORDER_HEADER:
      state = {
        ...state,
        JuteYarnDeliveryOrderHeader: action.payload,
        loading: true,
      };
      break;

    case JUTE_YARN_DELIVERY_ORDER_LINE_ITEMS:
      state = {
        ...state,
        JuteYarnDeliveryOrderLineItems: action.payload,
        loading: true,
      };
      break;

    case JUTE_YARN_DELIVERY_ORDER_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
      };
      break;

    case JUTE_YARN_DELIVERY_ORDER_VIEW_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        JuteYarnDeliveryOrderViewById: action.payload.response,
        loading: false,
      };
      break;
    case UPDATE_JUTE_YARN_DELIVERY_ORDER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_JUTE_YARN_DELIVERY_ORDER_SUCCESSFULL:
      state = {
        ...state,
        UpdateJuteYarn: action.payload.response,
        loading: false,
      };
      break;
    case SALES_ORDER_VIEW_BY_ID_FOR_JUTE_YARN:
      state = {
        ...state,
        loading: true,
      };
      break;

    case SALES_ORDER_VIEW_BY_ID_FOR_JUTE_YARN_SUCCESSFULL:
      state = {
        ...state,
        SalesOrderViewByIdForJuteYarn: action.payload.response,
        loading: false,
      };
      break;
    case CLEAR_JUTE_YARN_DELIVERY_ORDER_HEADER:
      state = {
        ...state,
        JuteYarnDeliveryOrderHeader: initialState.JuteYarnDeliveryOrderHeader,
      };
      break;
    case CLEAR_JUTE_YARN_DELIVERY_ORDER_LINE_ITEMS:
      state = {
        ...state,
        JuteYarnDeliveryOrderLineItems: [
          {
            id: 0,
            regularDeliveryOrderLineItemId: "",
            createdBy: "",
            createdDate: "",
            regDeliveryOrderId: "",
            companyId: "",
            salesId: "",
            salesLineItemId: "",
            itemGroupId: "",
            itemGroupName: "",
            itemGroupValue: "",
            itemId: "",
            itemName: "",
            itemCode: "",
            itemValue: "",
            hsncode: "",
            itemMake: "",
            doBalQty: null,
            quantity: "",
            uom: "",
            rate: "",
            invoiceQty: "",
            igstAmount: "",
            igstPercent: "",
            cgstAmount: "",
            cgstPercent: "",
            sgstAmount: "",
            sgstPercent: "",
            cessAmount: "",
            discountType: "",
            discount: "",
            discountName: "",
            discountedRate: "",
            discountAmount:"",
            netAmount: "",
            totalAmount: "",
            remarks: "",
            balesPacketSlNo: "",
            isActive: 1,
            yarnIsActive:1,
            status: 1,
            updatedBy: null,
            updatedOn: null,
            yarnDeliveryOrderLineItemId: "",
            yarnDeliveryOrderId: "",
            yarnRateUom: "",
            yarnRateUomId: "",
            yarnRateUomRelationValue: "",
            yarnRateCalculatedValue: "",
            yarnQuantityUom: "",
            yarnQuantityUomId: "",
            yarnQuantityUomRelationValue: "",
            yarnQuantityCalculatedValue: "",
            yarnVariableRelationValue: "",
            yarnRateIncludingBrokerage:"",
            yarnRateExcludingBrokerage:"",
            yarnUpdatedBy: "",
            yarnUpdatedOn: "",
            yarnIsActive: 1,
            hessianDeliveryOrderLineItemId: "",
            hessianDeliveryOrderId: "",
            hessianQuantityUom: "",
            hessianQuantityUomId: "",
            hessianQuantityRelationValue: "",
            hessianQuantityCalculatedValue: "",
            hessianRateUom: "",
            hessianRateUomId: "",
            hessianRateRelationValue: "",
            hessianRateCalculatedValue: "",
            hessianRateIncludingBrokerage: "",
            hessianRateExcludingBrokerage: "",
            hessianBalesPacketSlNo: "",
            hessianUpdatedBy: "",
            hessianUpdatedOn: "",
            hessianIsActive: "",
            govtDeliveryOrderLineItemId: "",
            govtDeliveryOrderId: "",
            govtDeliveryRateUom: "",
            govtDeliveryRateUom2: "",
            govtDeliveryRateUom3: "",
            govtDeliveryQuantityUom: "",
            govtDeliveryQuantityUom2: "",
            govtDeliveryQuantityUom3: "",
            govtDeliveryRelationValue: "",
            govtDeliveryRelationValue2: "",
            govtDeliveryRelationValue3: "",
            govtDeliveryQtyCalculatedValue: "",
            govtDeliveryQtyCalculatedValue2: "",
            govtDeliveryQtyCalculatedValue3: "",
            govtDeliveryRateCalculatedValue: "",
            govtDeliveryRateCalculatedValue2: "",
            govtDeliveryRateCalculatedValue3: "",
            govtDeliveryBalesPacketSlNo: "",
            govtUpdatedBy: "",
            govtUpdatedOn: "",
            yarnSalesOrderLineItemId:"",
            ItemNameData: [],
          },
        ],
        loading: true,
      };
      break;

    case SALES_ORDER_VIEW_BY_ID_FOR_JUTE_GOVT:
      state = {
        ...state,
        loading: true,
      };
      break;

    case SALES_ORDER_VIEW_BY_ID_FOR_JUTE_GOVT_SUCCESSFULL:
      state = {
        ...state,
        SalesOrderViewByIdForJuteGovt: action.payload.response,
        loading: false,
      };
      break;

    case JUTE_GOVT_DELIVERY_ORDER_OTHER_CHARGES:
      state = {
        ...state,
        loading: true,
      };
      break;

    case JUTE_GOVT_DELIVERY_ORDER_OTHER_CHARGES_SUCCESSFULL:
      state = {
        ...state,
        JuteGovtDelOtherCharges: action.payload.response,
        loading: false,
      };
      break;

    case JUTE_GOVT_DELORDER_ITEMS_BY_ITEM_GROUP_ID:
      state = {
        ...state,
        loading: true,
        dataLoaded: false,
      };
      break;

    case JUTE_GOVT_DELORDER_ITEMS_BY_ITEM_GROUP_ID_SUCCESSFULL:
      state = {
        ...state,
        JuteGovtDelOrderItemBy_ItemGroupId: action.payload.response,
        loading: false,
        dataLoaded: true,
      };
      break;

    case API_ERRORS:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default DeliveryOrderReducer;
