//import { Switch } from "react-router-dom";

import {
    API_ERRORS,
    PR_RESPONSE_LIST,
    PR_RESPONSE_LIST_SUCCESSFULL,
  } from "./actionTypes";
  
  const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    PrResponse_List: [],
    error_msg: null,
  };
  
  const PrResponseReducer = (state = initialState, action) => {
    switch (action.type) {
      case PR_RESPONSE_LIST:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case PR_RESPONSE_LIST_SUCCESSFULL:
        state = {
          ...state,
          PrResponse_List: action.payload.response,
          loading: false,
        };
        break;
        case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
      default:
        state = { ...state };
        break;
    }
  
    return state;
  };
  
  export default PrResponseReducer;
  