import React, { Component } from 'react';
import TextField from './TextField';





class TextFieldCharNums extends Component {
    

    render() {
      const Humbug = {
        value: this.props.value,
        label: this.props.lable,
        placeholder: this.props.placeholder,
        type:this.props.type,
        onChange:this.props.onChange,
        caps:this.props.caps
      }
        return(
          <div className="TextFieldBlock">
            {this.props.label && <label htmlFor="app-input-field">{this.props.label} </label>}
                <TextField special={true} {...Humbug}></TextField>
            </div>
        )
    }
}

export default TextFieldCharNums;
