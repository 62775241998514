import axios from "axios";

import cons, { getTenantId, serverApi } from "./Consts";

const getList = (url, data) => {
  const mtoken =
    localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: localStorage.getItem("companyId"),
  };

  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
};

const getListurl = (url, data) => {
  const mtoken =
    localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: localStorage.getItem("companyId"),
  };
  return axios
    .get(url, {
      headers: headers,
    })
    .catch((err) => {
      throw err;
    });
};

const getListWithDocsurl = (url, data) => {
  const mtoken =
    localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: localStorage.getItem("companyId"),
  };
  return axios
    .get(url, {
      headers: headers,
    })
    .then((resp) => {
      if (resp) {
        if (resp.data) {
          if (resp.data.data) {
            resp.data.data.map((row) => {
              let Url =
                serverApi.GET_ALL_FILES +
                "42/" +
                row.educationId +
                "/" +
                localStorage.getItem("companyId");
              axios.get(Url, { headers: headers }).then((response) => {
                if (response.data) {
                  if (response.data.data.length > 0) {
                    var doc_data = response.data.data;
                    row.docName = doc_data[0].fileName;
                    row.docId = doc_data[0].taskUniqueId;
                  }
                }
              });
              return row;
            });
          }
        }
      }
      return resp;
    })
    .catch((err) => {
      throw err[1];
    });
};

// postForgetPwd
const postAdd = (url, data) => {
  const mtoken =
    localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: localStorage.getItem("companyId"),
  };
  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

const postEdit = (url, data) => {
  const user = localStorage.getItem("authUser");
  let sjson = JSON.parse(user);
  return axios
    .put(url, data, { headers: { Authorization: "Token " + sjson.data.token } })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// postForgetPwd
const postAddmultipart = (url, data) => {
  const mtoken =
    localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: 0,
  };

  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const postEditmultipart = (url, data) => {
  const user = localStorage.getItem("authUser");
  let sjson = JSON.parse(user);
  return axios
    .put(url, data, {
      headers: {
        Authorization: "Token " + sjson.data.token,
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};
const postDelete = (url) => {
  const mtoken =
    localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);

  return axios
    .delete(url, {
      headers: {
        Authorization: mtoken,
        "Content-Type": "application/json",
        "X-TenantId": tenID,
        CompanyID: localStorage.getItem("companyId"),
      },
    })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const getRowData = (url) => {
  const user = localStorage.getItem("authUser");
  let sjson = JSON.parse(user);
  return axios
    .get(url, {
      headers: {
        Authorization: "Token " + sjson.data.token,
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      //throw err;
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const getPostRowData = (url, data) => {
  const mtoken =
    localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: 0,
  };
  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const postUpload = (url, csvfile) => {
  var formData = new FormData();
  formData.append("multipartFile", csvfile.name);
  return axios
    .post(url, csvfile.name, {
      headers: {
        "X-TenantId": getTenantId(window.location.hostname),
        Authorization:
          localStorage.getItem("token_type") +
          localStorage.getItem("access_token"),
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      //throw err;
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const postUploadImage = (url, data) => {
  const mtoken =
    localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: 0,
  };

  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const getFileDownload = (url, name, type, callback) => {
  const mtoken =
    localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: 0,
  };
  return fetch(url, {
    headers: headers,
  })
    .then((response) =>
      response.blob().then((blob) => {
        var fileType = "image/" + type;
        if (type === "pdf" || type === "xlsx") fileType = "application/" + type;
        var file = new Blob([blob], { type: fileType });
        var fileURL = URL.createObjectURL(file);
        callback(fileURL);
      })
    )
    .catch((error) => {
      throw error;
    });
};

const getFilesList = (url, callback) => {
  const mtoken =
    localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: localStorage.getItem("companyId"),
  };
  return axios
    .get(url, {
      headers: headers,
    })
    .then((response) => {
      if (response.data) {
        if (response.data.status) {
          if (response.data.data) {
            callback(response.data.data);
          } else {
            callback(null);
          }
        }
      }
    })

    .catch((err) => {
      throw err[1];
    });
};
const getFilesData = (url, callback) => {
  const mtoken =
    localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: localStorage.getItem("companyId"),
  };
  return axios
    .get(url, {
      headers: headers,
    })
    .then((response) => {
      if (response.data) {
        callback(response.data);
      } else {
        callback(null);
      }
    })
    .catch((err) => {
      throw err[1];
    });
};
const getCallBackData = (url, data, callback) => {
  const mtoken =
    localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: localStorage.getItem("companyId"),
  };
  return axios
    .post(url, data, {
      headers: headers,
    })
    .then((response) => {
      if (response.data) {
        if (response.data.status) {
          if (response.data.data) {
            if (data.taskType == 0) {
              callback(response.data.data);
            } else {
              callback(response);
            }
          } else {
            callback(response);
          }
          return response;
        } else {
          callback(response);
        }
      } else {
        if (data.taskType == 2) {
          callback(response);
        }
      }
    })
    .catch((err) => {
      throw err;
    });
};

const getCallBackGetData = (url, callback, dropdowndata) => {
  const mtoken =
    localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: localStorage.getItem("companyId"),
  };
  return axios
    .get(url, {
      headers: headers,
    })
    .then((response) => {
      if (response.data) {
        callback(response, dropdowndata);
      }
    })
    .catch((err) => {
      throw err[1];
    });
};
//method for call back for post method
const postCallBacklistData = (url, data, callback, itemgrpId) => {
  const mtoken =
    localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: localStorage.getItem("companyId"),
  };
  return axios
    .post(url, data, {
      headers: headers,
    })
    .then((response) => {
      if (response) {
        callback(response, itemgrpId);
        return response;
      }
    })
    .catch((err) => {
      throw err[1];
    });
};
//method for call back for get method
const getCallBacklistData = (url, history, callback, itemgrpId) => {
  const mtoken =
    localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: localStorage.getItem("companyId"),
  };
  return axios
    .get(url, {
      headers: headers,
    })
    .then((response) => {
      if (response) {
        callback(response, itemgrpId);
        return response;
      }
    })
    .catch((err) => {
      throw err[1];
    });
};

export {
  getList,
  getListurl,
  postAdd,
  postEdit,
  postDelete,
  getRowData,
  postUpload,
  postAddmultipart,
  postEditmultipart,
  postUploadImage,
  getPostRowData,
  getFileDownload,
  getListWithDocsurl,
  getFilesList,
  getFilesData,
  getCallBackData,
  getCallBackGetData,
  postCallBacklistData,
  getCallBacklistData,
};
