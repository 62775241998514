import React, { Component } from "react";
import { Grid, Step, Stepper, StepLabel } from "@mui/material";
import Link from "@mui/material/Link";
import CustomButton from "../../../components/Buttons/Buttons";
import POFillDeatils from "./FillDeatils";
import Preview from "./Preview";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { hideSpinner, serverApi, showSpinner } from "../../../helpers/Consts";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import {
  CreateWorkOrder,
  UpdatePoWo,
} from "../../../store/Purchase/WorkOrder/actions";
import {updateBranchMaster} from "../../../store/Master/BranchMaster/actions"
import { POWOLineItemsProps,getItemDetailsByPoId } from "../../../store/Purchase/PurchaseOrder/actions";
import { ApprovalHierarchy } from "../../../store/HRMS/EmployeeDataBase/actions";
import LineItems from "./LineItems";
import moment from "moment";
import swal from "sweetalert";
import BomBoqLineItems from "./BomBoqLineItem";
import { LineItemsValidtions } from "../../../Utilities/LineItemsValidtions";
import { limitDecimals } from "../../../Utilities/helper";

class CreatePage extends Component {
  constructor() {
    super();
    this.state = {
      steps: [
        {
          title: "Order Type",
          icon: "http://vowerp.com/MailImages/assets/stepperSelection-00.png",
          activeIcon:
            "http://vowerp.com/MailImages/assets/stepperSelectionActive-00.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Fill Details",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Add Items",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Preview",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled03.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive03.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
      ],
      userDit: JSON.parse(localStorage.getItem("authUser")),
      currentStep: 1,
      pathname: "",
      date: null,
      POType: 0,
      alignment: "left",
      confirm: false,
      poSourceType: 0,
      source: "",
      RejectButton: false,
      UpdateButton: false,
      ApproveButton: false,
      CancelButton: true,
      taskId: 0,
      locationState: "",
      PoFillDetails: {
        datevalue: moment().format().split("T", 1).toString(),
        budgetHeads: "null",
        budgetHead_value: "",
        phaseId: "",
        indentListItem: "",
        indentListItemValue: "",
        itemGroup: "",
        itemGroup_Value: "",
        branch: "",
        branchValue: "",
        boqList: "",
        boqList_value: "",
        WO_Source: "",
        WO_Source_value: "",
        Supplier: "",
        Supplier_value: "",
        Supplier_Branch: "",
        Supplier_Branch_value: "",
        supplierBranchName: "",
        Billing_Address: "",
        Billing_Address_value: "",
        Billing_state_code: "",
        Billing_state_name: "",
        shippingAddress: "",
        shippingAddress_Value: "",
        shipping_state_code: "",
        shipping_state_name: "",
        tax_payable: 1,
        tax_payable_value: 1,
        tax_type: "",
        tax_type_value: "",
        Credit_term: 60,
        delivery_timeLine: "",
        expected_date: "",
        poTillDate: moment().format().split("T", 1).toString(),
        indentId: "",
        project: "",
        project_value: "",
        client: "",
        client_value: "",
        category_type: "",
        companyName: "",
        category_type_value: "",
        RQN_name: "",
        contact_person: "",
        contact_no: "",
        advanceAmount: "",
        advancePercentage: "",
        advanceType: "",
        selectTermsNconditions: "",
        freightCharges: "",
        termsNconditions: "",
        totalAmount: 0,
        totalIgst: 0,
        totalCgst: 0,
        totalSgst: 0,
        netTotal: 0,
        status: "",
        statusDesc: null,
        statusName: "",
        poSequenceNo: "",
        footerNotes: "",
        remarks: "",
        currency: "",
        currencyoptions: [],
        branchListData: [],
        itemGrpMasterList: [],
        approvedIndentsList: [],
        budgetHeadsList: [],
        setIndentProps: false,
      },
      poDetails: [],
      newShippingBranchId: "",
      BranchData: [],
      supplierBranchDetails: {
        gstin: "",
        Contact_person: "",
        Contact_Number: "",
        email: "",
      },
      poBilledToDetails: {
        gstin: "",
        Contact_person: "",
        Contact_Number: "",
        email: "",
      },
      poShippedToDetails: {
        gstin: "",
        Contact_person: "",
        Contact_Number: "",
        email: "",
      },
    };
  }

  onClickNext = () => {
    const { currentStep } = this.state;
    this.setState({
      currentStep: currentStep + 1,
    });
  };
  onClicknextBtn = () => {
    const { currentStep } = this.state;
    let valid = this.validateForms();
    if (currentStep === 2) {
      if (this.props.po_wo_lineitems) {
        if (this.props.po_wo_lineitems.length !== 0) {
          if (valid === true) {
            this.setState({
              currentStep: currentStep + 1,
            });
          }
        } else {
          let displayName =
            this.state.source === "WITHINDENT"
              ? "Indent"
              : this.state.source === "WITHOPENINDENT"
              ? "OPEN INDENT"
              : this.state.source === "WITHBOM"
              ? "BOM"
              : "BOQ";
          swal(
            `Selected ${displayName} should have atleast one lineitem with qty >0`
          );
        }
      }
    } else if (currentStep === 3) {
      if (this.props.po_wo_lineitems) {
        if (this.props.po_wo_lineitems.length == 0) {
          let displayName =
            this.state.source == "WITHINDENT"
              ? "Indent"
              : this.state.source == "WITHOPENINDENT"
              ? "OPEN INDENT"
              : this.state.source == "WITHBOM"
              ? "BOM"
              : "BOQ";
          swal(
            `Selected ${displayName} should have atleast one lineitem with qty >0`
          );
        } else {
          this.validateLineItems();
        }
      }
    } else {
      this.setState({
        currentStep: currentStep + 1,
      });
    }
  };

  onClickCancel = () => {
    const { currentStep } = this.state;
    if (currentStep === 2) {
      let url =
        this.props.location.pathname === "/create_workorder"
          ? "/work_order"
          : this.props.location.pathname === "/create_purchase_order"
          ? "/purchase_purchase_order"
          : "";
      this.props.history.push(url);
    } else {
      this.setState({
        currentStep: currentStep - 1,
      });
    }
  };
  QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;
    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };

  onclickOk() {
    this.setState({
      confirm: false,
    });
  }

  handleAlignment = (
    event,
    newAlignment
  ) => {
    this.setState({
      alignment: newAlignment,
    });
    if (newAlignment !== null) {
      if (newAlignment === "1") {
        this.setState({
          source: "WITHINDENT",
          POType: 1,
          poSourceType: 1,
        });
      } else if (newAlignment === "2") {
        if (this.props.location.pathname === "/create_workorder") {
          this.setState({
            source: "WITHOUTBOQ",
            POType: 2,
            poSourceType: 2,
          });
        } else {
          this.setState({
            source: "WITHOUTINDENT",
            POType: 2,
            poSourceType: 2,
          });
        }
      } else if (newAlignment === "3") {
        this.setState({
          source: "WITHBOM",
          POType: 3,
          poSourceType: 3,
        });
      } else if (newAlignment === "4") {
        this.setState({
          source: "WITHBOQ",
          POType: 4,
          poSourceType: 4,
        });
      } else {
        this.setState({
          source: "WITHOPENINDENT",
          POType: 5,
          poSourceType: 5,
        });
      }
    } else {
      this.setState({
        POType: null,
      });
    }
  };

  componentDidMount() {
    if (this.props.location.pathname) {
      if (this.props.location.pathname === "/create_purchase_order") {
        this.setState({
          pathname: "PO",
          taskId: 2,
        });
      } else {
        this.setState({
          pathname: "WO",
          taskId: 3,
        });
      }
    }
    this.setState({
      locationState: this.props.location,
    });
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.rowData) {
        let taskId = 0;
        let statusId = this.props.location.state.rowData.status;
        if (this.props.location.pathname === "/create_purchase_order") {
          taskId = 2;
        } else {
          taskId = 3;
        }
        this.props.ApprovalHierarchy(
          serverApi.APPROVAL_BUTTON +
            JSON.parse(localStorage.getItem("authUser")).userId +
            "/" +
            taskId +
            "/company/" +
            this.props.location.state.rowData.companyId +
            "/branch/" +
            this.props.location.state.rowData.branchId +
            "/status/" +
            statusId,
          this.props.history
        );
        
    this.props.getItemDetailsByPoId(
      serverApi.GET_ITEM_DETAILS_BY_PO_ID +
       this.props.location.state.rowData.id 
    
    )
        showSpinner();
        let source = this.props.location.state.rowData.source;
        let rowData = this.props.location.state.rowData;
        let poDetails =
          this.props.location.state.rowData.poDetails === null
            ? []
            : this.props.location.state.rowData.poDetails;

        
        if (source !== "") {
          poDetails = poDetails.map((data) => {
            return {
              indentId: data.indentId,
              indentDetailsId: data.indentDetailsId,
              id: data.id,
              budgetHeadId: data.budgetHeadId,
              budgetHeadName: data.budgetHeadName,
              departmentId: data.departmentId,
              departmentName: data.departmentName,
              itemId: data.itemId,
              itemName: data.itemName,
              itemGroup: data.itemGroup,
              itemGroupId: data.itemGroupId,
              itemGroupName: data.itemGroup,
              itemCode: data.itemGroupId + data.itemCode,
              qty: limitDecimals(data.qty, 3),
              qtyPo: limitDecimals(data.qtyPo, 3),
              qohBranch: data.qohBranch,
              qohCompany: data.qohCompany,
              rate: limitDecimals(data.rate, 3),
              tax: data.tax,
              taxId: data.taxId,
              ctaxPercentage: limitDecimals(data.ctaxPercentage, 2),
              staxPercentage: limitDecimals(data.staxPercentage, 2),
              iTaxPercentage: limitDecimals(data.iTaxPercentage, 2),
              description: data.description,
              discountMode: data.discountMode,
              discountPercentage: limitDecimals(data.discountPercentage, 2),
              hsnCode: data.hsnCode,
              uomCode: data.uomCode,
              installationRate: limitDecimals(data.installationRate, 3),
              installationAmount: limitDecimals(data.installationAmount, 3),
              make: data.make,
              remarks: data.remarks,
              taxPercentage: limitDecimals(data.taxPercentage, 2),
              oldQty: limitDecimals(data.oldQty, 3),
              isActive: data.isActive,
              amount: limitDecimals(data.amount, 3),
              currentStock: limitDecimals(data.currentStock, 3),
              minStock: limitDecimals(data.minStock, 3),
              maxStock: limitDecimals(data.maxStock, 3),
              reOrderQty: limitDecimals(data.reOrderQty, 3),
              currency: limitDecimals(data.currency, 3),
              tangible: data.tangible,

            };
          });

          this.setState({
            source: source,
            currentStep: 4,
            poDetails: poDetails,
          });
        }
        if (this.props.location.state.rowData.poDetails === null) {
          swal("No Line Items Found");
        }
        this.setState({
          PoFillDetails: {
            datevalue: moment(rowData.poDate, "DD-MM-YYYY", true).format(
              "YYYY-MM-DD"
            ),
            budgetHeads: rowData.budgetHeadId,
            budgetHead_value: rowData.budgetHeadId,
            indentListItem: "",
            indentListItemValue: rowData.indentId,
            itemGroup: rowData.itemGroup,
            itemGroup_Value: rowData.itemGroup,
            itemGroupName: rowData.itemGroup,
            branch: rowData.branchName,
            branchValue: rowData.branchId,
            boqList: "",
            boqList_value: "",
            WO_Source: "",
            WO_Source_value: "",
            Supplier: rowData.supplierName,
            Supplier_value: rowData.supplierId,
            Supplier_Branch: rowData.supplierBranchName,
            Supplier_Branch_value: rowData.supplierBranchId,
            supplierBranchName: rowData.supplierBranchName,
            Billing_Address: rowData.billToAddress,
            Billing_Address_value: rowData.billingBranchId,
            Billing_state_code: rowData.billToStateCode,
            Billing_state_name: rowData.billToStateName,
            shippingAddress: rowData.shipToAddress,
            shippingAddress_Value: rowData.shippingBranchId,
            shipping_state_code: rowData.shipToStateCode,
            shipping_state_name: rowData.shipToStateName,
            tax_payable: rowData.taxPayable === null ? 1 : rowData.taxPayable,
            tax_payable_value:
              rowData.taxPayable === null ? 1 : rowData.taxPayable,
            tax_type: rowData.taxTypeName,
            tax_type_value: Number(rowData.taxTypeId),
            Credit_term: rowData.creditDays,
            delivery_timeLine: rowData.deliverTimeLine,
            expected_date: rowData.expectedDeliveryDate,
            poTillDate:
              rowData.poTillDate === null
                ? moment().format().split("T", 1).toString()
                : moment(rowData.poTillDate, "DD-MM-YYYY", true).format(
                    "YYYY-MM-DD"
                  ),
            project: rowData.projectName,
            project_value: rowData.projectId,
            phaseId: rowData.phaseId,
            client: rowData.customerName,
            client_value: rowData.customerId,
            category_type: rowData.categoryName,
            category_type_value: rowData.category,
            RQN_name: rowData.rqnName,
            contact_person: rowData.contactPerson,
            contact_no: rowData.contactNo,
            companyName: rowData.companyName,
            advanceAmount: rowData.advanceAmount,
            advancePercentage: rowData.advancePercentage,
            advanceType: rowData.advanceType,
            selectTermsNconditions: rowData.termsNconditions,
            termsNconditions: rowData.termsNconditions,
            freightCharges: rowData.freightCharges,
            footerNotes: rowData.footerNotes,
            status: rowData.status,
            statusName: rowData.statusName,
            poSequenceNo: rowData.poSequenceNo,
            valueWithTax: rowData.valueWithTax,
            valueWithoutTax: rowData.valueWithoutTax,
            // totalAmount: rowData.valueWithoutTax,
            // netTotal: rowData.valueWithTax,
            totalAmount: rowData.valueWithTax,
            netTotal: rowData.valueWithoutTax,
            remarks: rowData.remarks,
            currency: rowData.currency,
            currentStock: 0,
            minStock: 0,
            maxStock: 0,
            reOrderQty: 0,
            tangible: rowData.tangible,
          },
        });
        if (source === "WITHOUTINDENT" || source === "WITHOUTBOQ") {
          let newLineItem = {
            indentId: null,
            indentDetailsId: null,
            id: 1,
            budgetHeadId: "",
            budgetHeadName: "",
            departmentId: "",
            departmentName: "",
            itemId: "",
            itemName: "",
            itemGroup: "",
            itemGroupId: "",
            itemGroupName: "",
            itemCode: "",
            qty: "0.000",
            qtyPo: "0.000",
            qohBranch: 0,
            qohCompany: 0,
            rate: "0.000",
            tax: "0.000",
            taxId: "0.000",
            ctaxPercentage: "0.00",
            staxPercentage: "0.00",
            iTaxPercentage: "0.00",
            description: "",
            discountMode: "",
            discountPercentage: "0.00",
            hsnCode: "",
            uomCode: null,
            installationRate: "0.00",
            installationAmount: "0.00",
            make: "",
            remarks: "",
            taxPercentage: null,
            oldQty: "0.000",
            isActive: 1,
            amount: "0.00",
            currentStock: "0.000",
            minStock: "0.000",
            maxStock: "0.000",
            reOrderQty: "0.000",
            minAllowedQty : "0.000",
            maxAllowedQty: "0.000",
            tangible: "",
          };
          poDetails.push(newLineItem);

          this.props.POWOLineItemsProps(poDetails);
        } else {
          this.props.POWOLineItemsProps(poDetails);
        }
      }
    } else {
      let poDetails = [
        {
          indentId: null,
          indentDetailsId: null,
          id: 1,
          budgetHeadId: "",
          budgetHeadName: "",
          departmentId: "",
          departmentName: "",
          itemId: "",
          itemName: "",
          itemGroup: "",
          itemGroupId: "",
          itemGroupName: "",
          itemCode: "",
          qty: "0.000",
          qtyPo: "0.000",
          qohBranch: 0,
          qohCompany: 0,
          rate: "0.000",
          tax: "0.000",
          taxId: 0,
          ctaxPercentage: "0.00",
          staxPercentage: "0.00",
          iTaxPercentage: "0.00",
          description: "",
          discountMode: "",
          discountPercentage: "0.00",
          hsnCode: "",
          uomCode: null,
          installationRate: "0.000",
          installationAmount: "0.000",
          make: "",
          remarks: "",
          taxPercentage: null,
          oldQty: "0.000",
          isActive: 1,
          amount: "0.00",
          currentStock: "0.000",
          minStock: "0.000",
          maxStock: "0.000",
          reOrderQty: "0.000",
          maxAllowedQty : "0.000",
          minAllowedQty : "0.000",
          tangible:"",
        },
      ];
      this.props.POWOLineItemsProps(poDetails);
    }

    if (this.state.userDit) {
      this.state.userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              let itemCurrencyTypes = item.currencyTypes;
              let currencyTypes = {};
              if (itemCurrencyTypes !== undefined) {
                currencyTypes = JSON.parse(itemCurrencyTypes);
                this.setState({
                  currencyTypes: currencyTypes,
                });
              }
            });
          }
      });
    }
  }

  componentWillReceiveProps(props) {
    if (props.po_wo_lineitems) {
      this.setState({
        poDetails: props.po_wo_lineitems,
      });
    }
    if (props.approval_hierarchy) {
      if (props.approval_hierarchy.data) {
        hideSpinner();
        this.setState({
          ApproveButton: props.approval_hierarchy.data.approveButton,
        });
      }
    }
    if (props.ItemDetailsByPoId) {
      if (props.ItemDetailsByPoId.data) {
        this.setState({
          poDetailsId: props.ItemDetailsByPoId.data.id,
        });
      }
    }


    if (props.CreateBranchMaster) {
    
      if (props.CreateBranchMaster.data) {
        this.setState({
          newShippingBranchId: props.CreateBranchMaster.data.id,
        });
      }
 
      if (props.billing_shipping_adsList) {
        if (props.billing_shipping_adsList.data) {
          let branchData = props.billing_shipping_adsList.data.map((item) => {
            if (item.id !== 0) {
              return item;
            }
          });
          this.setState({
            BranchData: branchData,
          });
        }
      }
    }
  }

  onClickConfirm = () => {
    let url = "";
    if (this.props.location.pathname === "/create_purchase_order") {
      url = "/purchase_purchase_order";
    } else {
      url = "/work_order";
    }
    if (this.props.po_wo_lineitems) {
      this.setState({
        poDetails: this.props.po_wo_lineitems,
      });
    }
    const FilterPoDetails = this.state.poDetails.filter((item) => {
      if (item.departmentId !== "" && item.isActive !== 0) {
        return item;
      }
    });
    const PoDetails = FilterPoDetails.map((item) => {
      // Direct Order for PO and Wo
      let qohBranch = item.qohBranch
        ? parseFloat(item.qohBranch).toFixed(3)
        : 0.0;
      let qohCompany = item.qohCompany
        ? parseFloat(item.qohCompany).toFixed(3)
        : 0.0;
      if (
        this.state.source === "WITHOUTINDENT" ||
        this.state.source === "WITHOUTBOQ"
      ) {
        return {
          ctaxPercentage:
            this.state.PoFillDetails.tax_type_value === 2
              ? this.state.PoFillDetails.tax_payable_value === 2
                ? 0.0
                : item.taxPercentage / 2
              : 0.0,
          departmentId: item.departmentId,
          discountMode:
            item.discountMode === "" ? 0 : item.discountMode.toString(),
          discountPercentage:
            item.discountPercentage === ""
              ? 0.0
              : Number(item.discountPercentage).toFixed(2),
          hsnCode: item.hsnCode,
          indentDetailsId: "",
          indentId: "",
          installationAmount: Number(item.installationRate) * Number(item.qty),
          installationRate:
            item.installationRate === null ? "" : item.installationRate,
          iTaxPercentage:
            this.state.PoFillDetails.tax_type_value !== 2
              ? this.state.PoFillDetails.tax_payable_value === 2
                ? 0.0
                : Number(item.taxPercentage).toFixed(3)
              : 0.0,
          itemId: Number(item.itemId),
          make: item.make,
          qohBranch: Number(qohBranch),
          qohCompany: Number(qohCompany),
          qty: Number(item.qty).toFixed(3),
          rate: Number(item.rate).toFixed(3),
          rateLastpurchase: "",
          rateLastpurchaseDate: moment().format("DD-MM-YYYY"),
          staxPercentage:
            this.state.PoFillDetails.tax_type_value === 2
              ? this.state.PoFillDetails.tax_payable_value === 2
                ? 0.0
                : item.taxPercentage / 2
              : 0.0,
          taxPercentage:
            this.state.PoFillDetails.tax_payable_value === 2
              ? null
              : item.taxPercentage,
          remarks: item.remarks,
          // tax: item.taxId,
          taxId:
            this.state.PoFillDetails.tax_payable_value === 2
              ? null
              : item.taxId,
          uomCode: item.uomCode === null ? "" : item.uomCode,
          currency: this.state.PoFillDetails.currency,
          isActive: item.isActive,
          currentStock: 0.0,
          minStock: 0.0,
          maxStock: 0.0,
          reOrderQty: 0.0,
        };
      } else {
        // withIndent Indent,Bom and Boq
        return {
          budgetHeadId:
            item.budgetHeadId !== null
              ? item.budgetHeadId.toString()
              : item.budgetHeadId,
          ctaxPercentage:
            this.state.PoFillDetails.tax_type_value === 2
              ? this.state.PoFillDetails.tax_payable_value === 2
                ? 0.0
                : item.taxPercentage / 2
              : 0.0,
          departmentId: item.departmentId,
          discountMode: item.discountMode === "" ? 0 : item.discountMode,
          discountPercentage:
            item.discountPercentage === ""
              ? 0.0
              : Number(item.discountPercentage).toFixed(2),
          hsnCode: item.hsnCode,
          indentDetailsId: item.itemGroupId === 997 ? null : item.id,
          indentId:
            item.itemGroupId === 997
              ? null
              : item.indentId === 0
              ? ""
              : item.indentId,
          installationAmount: 0.0,
          installationRate:
            item.installationRate === null
              ? 0.0
              : Number(item.installationRate).toFixed(3),
          iTaxPercentage:
            this.state.PoFillDetails.tax_type_value !== 2
              ? this.state.PoFillDetails.tax_payable_value === 2
                ? 0.0
                : Number(item.taxPercentage).toFixed(3)
              : 0.0,
          itemId: item.itemId,
          make: item.make,
          qohBranch: Number(qohBranch),
          qohCompany: Number(qohCompany),
          qty: item.qty,
          rate: Number(item.rate),
          rateLastpurchase: item.lastPurchaseRate,
          rateLastpurchaseDate: moment().format("DD-MM-YYYY"),
          staxPercentage:
            this.state.PoFillDetails.tax_type_value === 2
              ? this.state.PoFillDetails.tax_payable_value === 2
                ? 0.0
                : item.taxPercentage / 2
              : 0.0,
          taxPercentage:
            this.state.PoFillDetails.tax_payable_value === 2
              ? null
              : item.taxPercentage,
          remarks: item.remarks,
          // tax: item.taxId,
          taxId:
            this.state.PoFillDetails.tax_payable_value === 2
              ? null
              : item.taxId,
          uomCode: item.uomCode === null ? "" : item.uomCode,
          currency: this.state.PoFillDetails.currency,
          isActive: item.isActive,
          currentStock: 0,
          minStock: item.minStock,
          maxStock: item.maxStock,
          reOrderQty: item.reorderQty,
          minAllowedQty: item.minAllowedQty,
          maxAllowedQty: item.maxAllowedQty,
          branchStock: item.branchStock,
          centralStock: item.centralStock,
          poID: item.poID,
          poLineItemID: item.poLineItemID,
         
        };
      }
    });

  
    // Direct Order for PO and Wo
    if (
      this.state.source === "WITHOUTINDENT" ||
      this.state.source === "WITHOUTBOQ"
    ) {
      const data = {
        poDate: moment(this.state.PoFillDetails.datevalue).format("DD-MM-YYYY"),
        projectId: this.state.PoFillDetails.project_value,
        source: this.state.source,
        companyId: localStorage.getItem("companyId"),
        branchId: this.state.PoFillDetails.Billing_Address_value,
        supplierId: Number(this.state.PoFillDetails.Supplier_value),
        taxTypeId: this.state.PoFillDetails.tax_type_value,
        taxTypeName:
          this.state.PoFillDetails.tax_type_value === 1 ? "IGST" : "CGST&&SGST",
        billToAddress: this.state.PoFillDetails.Billing_Address,
        billToStateCode: this.state.PoFillDetails.Billing_state_code,
        billToStateName: this.state.PoFillDetails.Billing_state_name,
        shipToAddress: this.state.PoFillDetails.shippingAddress,
        shipToStateCode: this.state.PoFillDetails.shipping_state_code,
        shipToStateName: this.state.PoFillDetails.shipping_state_name,
        freightCharges: this.state.PoFillDetails.freightCharges,
        termsNconditions: this.state.PoFillDetails.selectTermsNconditions,
        footerNotes: this.state.PoFillDetails.footerNotes,
        creditDays: 60,
        expectedDeliveryDate: this.state.PoFillDetails.expected_date,
        category: Number(this.state.PoFillDetails.category_type_value),
        remarks: this.state.PoFillDetails.remarks,
        indentId: "",
        poTillDate: moment(this.state.PoFillDetails.poTillDate).format(
          "DD-MM-YYYY"
        ),
        rqnName: this.state.PoFillDetails.RQN_name,
        phaseId: "",
        valueWithTax: Math.round(parseFloat(this.state.PoFillDetails.netTotal)).toFixed(2),
        valueWithoutTax: parseFloat(
          this.state.PoFillDetails.totalAmount
        ).toFixed(2),
        taxPayable: this.state.PoFillDetails.tax_payable_value,
        supplierBranchId: this.state.PoFillDetails.Supplier_Branch_value,
        billingBranchId: this.state.PoFillDetails.Billing_Address_value,
        shippingBranchId: this.state.PoFillDetails.shippingAddress_Value,
        deliverTimeLine: this.state.PoFillDetails.delivery_timeLine,
        budgetHeadId: "",
        itemGroup: this.state.PoFillDetails.itemGroup_Value,
        poDetails: PoDetails,
        createdBy: this.state.userDit.userId.toString(),
        advanceType: "Percentage",
        advancePercentage: this.state.PoFillDetails.advancePercentage,
        advanceAmount: this.state.PoFillDetails.advanceAmount.toString(),
        customerId: this.state.PoFillDetails.client_value,
        contactPerson: this.state.PoFillDetails.contact_person,
        contactNo: this.state.PoFillDetails.contact_no,
        currency: this.state.PoFillDetails.currency,
      };
      this.props.CreateWorkOrder(
        serverApi.CREATE_WORK_ORDER,
        data,
        this.props.history,
        url
      );
      showSpinner();
    } else {
      // withIndent Indent,Bom and Boq
      const data = {
        poDate: moment(this.state.PoFillDetails.datevalue).format("DD-MM-YYYY"),
        projectId: this.state.PoFillDetails.project_value,
        source:
          this.state.source === "WITHOPENINDENT"
            ? "OPENINDENT"
            : this.state.source,
        companyId: localStorage.getItem("companyId"),
        branchId: this.state.PoFillDetails.Billing_Address_value,
        supplierId: Number(this.state.PoFillDetails.Supplier_value),
        taxTypeId: this.state.PoFillDetails.tax_type_value,
        taxTypeName:
          this.state.PoFillDetails.tax_type_value === 1 ? "IGST" : "CGST&&SGST",
        billToAddress: this.state.PoFillDetails.Billing_Address,
        billToStateCode: this.state.PoFillDetails.Billing_state_code,
        billToStateName: this.state.PoFillDetails.Billing_state_name,
        shipToAddress: this.state.PoFillDetails.shippingAddress,
        shipToStateCode: this.state.PoFillDetails.shipping_state_code,
        shipToStateName: this.state.PoFillDetails.shipping_state_name,
        freightCharges: this.state.PoFillDetails.freightCharges,
        termsNconditions: this.state.PoFillDetails.termsNconditions,
        footerNotes: this.state.PoFillDetails.footerNotes,
        creditDays: 60,
        expectedDeliveryDate: this.state.PoFillDetails.expected_date,
        category: Number(this.state.PoFillDetails.category_type_value),
        remarks: this.state.PoFillDetails.remarks,
        indentId: Number(this.state.PoFillDetails.indentListItemValue),
        poTillDate: moment(this.state.PoFillDetails.poTillDate).format(
          "DD-MM-YYYY"
        ),
        rqnName: this.state.PoFillDetails.RQN_name,
        phaseId: this.state.PoFillDetails.phaseId,
        valueWithTax: parseFloat(this.state.PoFillDetails.netTotal).toFixed(2),
        valueWithoutTax: parseFloat(
          this.state.PoFillDetails.totalAmount
        ).toFixed(2),
        taxPayable: this.state.PoFillDetails.tax_payable_value,
        supplierBranchId: this.state.PoFillDetails.Supplier_Branch_value,
        billingBranchId: this.state.PoFillDetails.Billing_Address_value,
        shippingBranchId: this.state.PoFillDetails.shippingAddress_Value,
        deliverTimeLine: this.state.PoFillDetails.delivery_timeLine,
        budgetHeadId:
          this.state.PoFillDetails.budgetHeads !== null
            ? this.state.PoFillDetails.budgetHead_value
            : "",
        itemGroup: this.state.PoFillDetails.itemGroup_Value,
        poDetails: PoDetails,
        createdBy: this.state.userDit.userId.toString(),
        advanceType: "Percentage",
        advancePercentage: this.state.PoFillDetails.advancePercentage,
        advanceAmount: this.state.PoFillDetails.advanceAmount.toString(),
        customerId: this.state.PoFillDetails.client_value,
        contactPerson: this.state.PoFillDetails.contact_person,
        contactNo: this.state.PoFillDetails.contact_no,
        currency: this.state.PoFillDetails.currency,
      };

      this.props.CreateWorkOrder(
        serverApi.CREATE_WORK_ORDER,
        data,
        this.props.history,
        url
      );
      showSpinner();
    }
    if (this.state.BranchData) {
      let branchData = {}
      this.state.BranchData.filter((item) => {
        if (item.id !== 0) {
          if (Number(item.id) ===  Number(this.state.newShippingBranchId)) {
            branchData = {
              address: item.address,
              addressType: item.addressType,
              brname: item.brname,
              cityTown: item.cityTown,
              contactNo: item.contactNo,
              createdBy: item.createdBy,
              email: item.email,
              futureReference: item.futureReference,
              gstNo: item.gstNo,
              id: item.id,
              isActive: item.futureReference === 1 ? 1 : 0,
              isGstAvailable: item.isGstAvailable,
              isGstVerified: item.isGstVerified,
              personAddress: item.personAddress,
              personName: item.personName,
              pinCode: item.pinCode,
              stateCode: item.stateCode,
              stateName: item.stateName,
            };
          }
        }
      });
      if(Number(this.state.newShippingBranchId)){
        const data = {
          branch: branchData,
          postAuthenticationDto: {
            cipher: this.state.userDit.cipher,
            companyId: localStorage.getItem("companyId"),
          },
        };
        this.props.updateBranchMaster(
          serverApi.UPDATE_BRANCH_MASTER,
          data,
          this.props.history,
          true
        );
      }
      
    }

    // calling update branch master api
  };
  onUpdate = (Status) => {
    let url = "";
    if (this.props.location.pathname === "/create_purchase_order") {
      url = "/purchase_purchase_order";
    } else {
      url = "/work_order";
    }
    if (this.props.po_wo_lineitems) {
      this.setState({
        poDetails: this.props.po_wo_lineitems,
      });
    }
    const FilterPoDetails = this.state.poDetails.filter((item) => {
      if (item.departmentId !== "") {
        return item;
      }
    });

    const PoDetails = FilterPoDetails.map((item) => {
      return {
        indentId: item.indentId !== null ? item.indentId : "",
        indentDetailsId:
          item.indentDetailsId !== null ? item.indentDetailsId : "",
        id: item.id,
        budgetHeadId: item.budgetHeadId
          ? item.budgetHeadId
          : this.state.PoFillDetails.budgetHeads,
        departmentId: item.departmentId,
        itemId: item.itemId.toString(),
        qty: item.qty,
        // qohBranch: item.qohBranch,
        qohCompany: Number(item.qohCompany),
        rate: item.rate,
        tax: item.taxId,
        taxId:
          this.state.PoFillDetails.tax_payable_value === 2 ? null : item.taxId,
        ctaxPercentage:
          this.state.PoFillDetails.tax_type_value === 2
            ? this.state.PoFillDetails.tax_payable_value === 2
              ? 0.0
              : item.taxPercentage / 2
            : 0.0,
        iTaxPercentage:
          this.state.PoFillDetails.tax_type_value !== 2
            ? this.state.PoFillDetails.tax_payable_value === 2
              ? 0.0
              : Number(item.taxPercentage).toFixed(3)
            : 0.0,
        taxPercentage:
          this.state.PoFillDetails.tax_payable_value === 2
            ? null
            : item.taxPercentage,
        staxPercentage:
          this.state.PoFillDetails.tax_type_value === 2
            ? this.state.PoFillDetails.tax_payable_value === 2
              ? 0.0
              : item.taxPercentage / 2
            : 0.0,
        description: item.description,
        discountMode: item.discountMode === "" ? 0 : item.discountMode,
        discountPercentage:
          item.discountPercentage === ""
            ? 0.0
            : Number(item.discountPercentage).toFixed(2),
        hsnCode: item.hsnCode,
        uomCode: item.uomCode,
        installationRate: item.installationRate,
        installationAmount: Number(item.installationRate) * Number(item.qty),
        make: item.make,
        remarks: item.remarks,
        oldQty: item.oldQty !== null ? item.oldQty : 0,
        currency: this.state.PoFillDetails.currency,
        isActive: item.isActive,
        minAllowedQty: item.minAllowedQty,
        maxAllowedQty: item.maxAllowedQty,
        maxStock: item.maxStock,
        minStock: item.minStock,
        poID: item.poID,
        poLineItemID: item.poLineItemID,
        reorderQty: item.reorderQty,
      };
    });
    let data = {
      id: this.state.locationState.state.rowData.id,
      poDate: moment(this.state.PoFillDetails.datevalue).format("DD-MM-YYYY"),
      projectId: this.state.PoFillDetails.project_value,
      source: this.state.source,
      companyId: localStorage.getItem("companyId"),
      branchId: this.state.PoFillDetails.Billing_Address_value,
      supplierId: this.state.PoFillDetails.Supplier_value,
      taxTypeId: this.state.PoFillDetails.tax_type_value,
      // taxTypeName: this.state.PoFillDetails.tax_type,
      billToAddress: this.state.PoFillDetails.Billing_Address,
      billToStateCode: this.state.PoFillDetails.Billing_state_code,
      billToStateName: this.state.PoFillDetails.Billing_state_name,
      shipToAddress: this.state.PoFillDetails.shippingAddress,
      shipToStateCode: this.state.PoFillDetails.shipping_state_code,
      shipToStateName: this.state.PoFillDetails.shipping_state_name,
      freightCharges: this.state.PoFillDetails.freightCharges,
      termsNconditions: this.state.PoFillDetails.selectTermsNconditions,
      footerNotes: this.state.PoFillDetails.footerNotes,
      creditDays: 60,
      expectedDeliveryDate: this.state.PoFillDetails.expected_date,
      category: this.state.PoFillDetails.category_type_value,
      remarks: this.state.PoFillDetails.remarks,
      indentId:
        this.state.PoFillDetails.indentListItemValue !== null
          ? this.state.PoFillDetails.indentListItemValue
          : "",
      poTillDate: moment(this.state.PoFillDetails.poTillDate).format(
        "DD-MM-YYYY"
      ),
      rqnName: this.state.PoFillDetails.RQN_name,
      phaseId: this.state.PoFillDetails.phaseId,
      // valueWithTax: this.state.PoFillDetails.netTotal.toString(),
      // valueWithoutTax: this.state.PoFillDetails.totalAmount.toString(),
      valueWithTax: Math.round(parseFloat(this.state.PoFillDetails.netTotal)).toFixed(2),
      valueWithoutTax: parseFloat(this.state.PoFillDetails.totalAmount).toFixed(
        2
      ),
      taxPayable: this.state.PoFillDetails.tax_payable_value,
      supplierBranchId: this.state.PoFillDetails.Supplier_Branch_value,
      billingBranchId: this.state.PoFillDetails.Billing_Address_value,
      shippingBranchId: this.state.PoFillDetails.shippingAddress_Value,
      deliverTimeLine: this.state.PoFillDetails.delivery_timeLine,
      status: Status,
      poSequenceNo: this.state.PoFillDetails.poSequenceNo,
      budgetHeadId: this.state.PoFillDetails.budgetHeads,
      itemGroup: this.state.PoFillDetails.itemGroup,
      poDetails: PoDetails,
      lastModifiedBy: this.state.userDit.userId.toString(),
      advanceType: "Percentage",
      advancePercentage: this.state.PoFillDetails.advancePercentage,
      advanceAmount: this.state.PoFillDetails.advanceAmount,
      customerId: this.state.PoFillDetails.client_value,
      contactPerson: this.state.PoFillDetails.contact_person,
      contactNo: this.state.PoFillDetails.contact_no,
      currency: this.state.PoFillDetails.currency,
    };

    this.props.UpdatePoWo(
      serverApi.UPDATE_PO_WO,
      data,
      this.props.history,
      url
    );
  };

  onClickApprove = (Status) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Approve",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willapprove) => {
      if (willapprove) {
        this.onUpdate(Status);
      }
    });
  };

  onClickCancelButton = (Status) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Cancel",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willCancel) => {
      if (willCancel) {
        this.onUpdate(Status);
      }
    });
  };

  onClickClose = (Status) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Close",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willClose) => {
      if (willClose) {
        this.onUpdate(Status);
      }
    });
  };

  onClickReject = (Status) => {
    swal({
      title: "Are You sure?",
      text: "Do you want to Reject",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willreject) => {
      if (willreject) {
        this.onUpdate(Status);
      }
    });
  };

  validateForms = () => {
    let data = this.state.PoFillDetails;
    if (this.props.location.pathname === "/create_workorder") {
      if (this.state.source === "WITHBOQ") {
        if (this.state.source === "WITHBOQ") {
          if (this.state.PoFillDetails.budgetHeadsList !== 0) {
            if (
              this.state.PoFillDetails.budgetHead_value === "" ||
              this.state.PoFillDetails.budgetHead_value === "0"
            ) {
              swal("Please select the Budget Head");
              return false;
            }
          }
        }
        if (this.state.PoFillDetails.itemGrpMasterList !== 0) {
          if (
            this.state.PoFillDetails.itemGroup_Value === "" ||
            this.state.PoFillDetails.itemGroup_Value === "0"
          ) {
            swal("Please select the  Item Group");
            return false;
          }
        }
        if (this.state.PoFillDetails.approvedIndentsList !== 0) {
          if (this.state.PoFillDetails.indentListItemValue === "") {
            swal("Please select the BOQ list");
            return false;
          }
        }
      }

      if (data.Supplier === "" || data.Supplier_value === "0") {
        swal("Please Select Supplier");
        return false;
      }
      if (data.Billing_Address_value === "") {
        swal("Please Select Billing Address");
        return false;
      }
      if (data.shippingAddress === "") {
        swal("Please Select Shipping Address");
        return false;
      }

      if (data.tax_payable === "") {
        swal("Please Select Tax Payable");
        return false;
      }
      if (data.delivery_timeLine === "") {
        swal("Please Enter Delivery Time Line");
        return false;
      }
      if (data.category_type === "") {
        swal("Please Select Category Type");
        return false;
      }
      if (data.project === "" || data.project_value === 0) {
        swal("Please Select Project");
        return false;
      }

      if (this.state.PoFillDetails.currencyoptions.length !== 0) {
        if (this.state.PoFillDetails.currency === "") {
          swal("Please select the Currency Type");
          return false;
        }
      }

      return true;
    }
    if (this.props.location.pathname === "/create_purchase_order") {
      if (
        this.state.source === "WITHINDENT" ||
        this.state.source === "WITHOPENINDENT"
      ) {
        if (this.state.PoFillDetails.branchListData !== 0) {
          if (
            this.state.PoFillDetails.branchValue === "" ||
            this.state.PoFillDetails.branchValue === 0
          ) {
            swal("Please select the  Branch");
            return false;
          }
          if (this.state.PoFillDetails.itemGrpMasterList !== 0) {
            if (
              this.state.PoFillDetails.itemGroup_Value === "" ||
              this.state.PoFillDetails.itemGroup_Value === "0"
            ) {
              swal("Please select the  Item Group");
              return false;
            }
          }
          if (this.state.PoFillDetails.indentListItemValue === "") {
            swal("Please select the Indent List");
            return false;
          }
        }
      }
      if (this.state.source === "WITHBOM") {
        if (this.state.PoFillDetails.budgetHeadsList !== 0) {
          if (
            this.state.PoFillDetails.budgetHead_value === "" ||
            this.state.PoFillDetails.budgetHead_value === "0"
          ) {
            swal("Please select the Budget Head");
            return false;
          }
        }
        if (this.state.PoFillDetails.itemGrpMasterList !== 0) {
          if (
            this.state.PoFillDetails.itemGroup_Value === "" ||
            this.state.PoFillDetails.itemGroup_Value === "0"
          ) {
            swal("Please select the  Item Group");
            return false;
          }
        }
        if (this.state.PoFillDetails.approvedIndentsList !== 0) {
          if (this.state.PoFillDetails.indentListItemValue === "") {
            swal("Please select the BOM List");
            return false;
          }
        }
      }

      if (data.Supplier === "") {
        swal("Please Select Supplier");
        return false;
      }
      if (data.Billing_Address_value === "") {
        swal("Please Select Billing Address");
        return false;
      }
      if (data.shippingAddress === "") {
        swal("Please Select Shipping Address");
        return false;
      }

      if (data.tax_payable === "") {
        swal("Please Select Tax Payable");
        return false;
      }
      if (data.delivery_timeLine === "") {
        swal("Please Enter Delivery Time Line");
        return false;
      }
      if (data.category_type === "") {
        swal("Please Select Category Type");
        return false;
      }
      if (data.project === "") {
        swal("Please Select Project");
        return false;
      }

      if (this.state.PoFillDetails.currencyoptions.length !== 0) {
        if (this.state.PoFillDetails.currency === "") {
          swal("Please select the Currency Type");
          return false;
        }
      }

      return true;
    }
  };

  validateLineItems = () => {
    if (this.props.po_wo_lineitems) {
      let validObj = false;
      let lastRow =
        this.props.po_wo_lineitems[this.props.po_wo_lineitems.length - 1];
      let po_wo_lineitems = this.props.po_wo_lineitems.filter(
        (item) => item.isActive !== 0
        // && item.remainingQuantity !== 0
      );
      for (var i = 0; i <= po_wo_lineitems.length - 1; i++) {
        const validateFields = [
          {
            field: po_wo_lineitems[i].departmentId,
            msg: "Department",
            type: 2,
          },
          { field: po_wo_lineitems[i].itemGroupId, msg: "Item Group", type: 2 },
          { field: po_wo_lineitems[i].itemId, msg: "Item", type: 2 },
          { field: po_wo_lineitems[i].qty, msg: "Quantity", type: 1 },
          { field: po_wo_lineitems[i].rate, msg: "Item Rate", type: 1 },
          {
            field: po_wo_lineitems[i].taxPercentage,
            msg: "Tax Percentage",
            type: 2,
          },
          {
            field: this.state.PoFillDetails.termsNconditions,
            msg: "terms and conditions",
            type: 2,
          },
          {
            field: this.state.PoFillDetails.selectTermsNconditions,
            msg: "terms and conditions",
            type: 1,
          },
        ];
        // this list for validateFieldsWithOtherCharges
        const validateFieldsWithOtherCharges = [
          { field: po_wo_lineitems[i].itemGroupId, msg: "Item Group", type: 2 },
          { field: po_wo_lineitems[i].itemId, msg: "Item", type: 2 },
          { field: po_wo_lineitems[i].rate, msg: "Item Rate", type: 1 },
          {
            field: po_wo_lineitems[i].taxPercentage,
            msg: "Tax Percentage",
            type: 2,
          },
          {
            field: this.state.PoFillDetails.termsNconditions,
            msg: "terms and conditions",
            type: 2,
          },
        ];
        const validateBomBoqFields = [
          {
            field: po_wo_lineitems[i].budgetHeadId,
            msg: "Budeget Head",
            type: 2,
          },
          { field: po_wo_lineitems[i].itemGroupId, msg: "Item Group", type: 2 },
          { field: po_wo_lineitems[i].itemId, msg: "Item", type: 2 },
          { field: po_wo_lineitems[i].qty, msg: "Quantity", type: 1 },
          { field: po_wo_lineitems[i].rate, msg: "Item Rate", type: 1 },
          {
            field: po_wo_lineitems[i].taxPercentage,
            msg: "Tax Percentage",
            type: 2,
          },
          {
            field: this.state.PoFillDetails.termsNconditions,
            msg: "terms and conditions",
            type: 2,
          },
        ];
        if (po_wo_lineitems.length === 1) {
          if (
            this.state.source === "WITHBOM" ||
            this.state.source === "WITHBOQ"
          ) {
            if (po_wo_lineitems[i].itemGroupId === 997) {
              validObj = LineItemsValidtions(validateFieldsWithOtherCharges);
              if (validObj) {
                break;
              }
            } else {
              validObj = LineItemsValidtions(validateBomBoqFields);
              if (validObj) {
                break;
              }
            }
          } else {
            if (po_wo_lineitems[i].itemGroupId === 997) {
              validObj = LineItemsValidtions(validateFieldsWithOtherCharges);
              if (validObj) {
                break;
              }
            } else {
              validObj = LineItemsValidtions(validateFields);
              if (validObj) {
                break;
              }
            }
          }
        } else {
          if (po_wo_lineitems[i] !== lastRow) {
            if (
              this.state.source === "WITHBOM" ||
              this.state.source === "WITHBOQ"
            ) {
              if (po_wo_lineitems[i].itemGroupId === 997) {
                validObj = LineItemsValidtions(validateFieldsWithOtherCharges);
                if (validObj) {
                  break;
                }
              } else {
                validObj = LineItemsValidtions(validateBomBoqFields);
                if (validObj) {
                  break;
                }
              }
            } else {
              if (po_wo_lineitems[i].itemGroupId === 997) {
                validObj = LineItemsValidtions(validateFieldsWithOtherCharges);
                if (validObj) {
                  break;
                }
              } else {
                if (po_wo_lineitems[i] !== lastRow) {
                  validObj = LineItemsValidtions(validateFields);
                  if (validObj) {
                    break;
                  }
                }
              }
            }
          } else {
            if (po_wo_lineitems[i].itemGroupId === 997) {
              validObj = LineItemsValidtions(validateFieldsWithOtherCharges);
              if (validObj) {
                break;
              }
            } 
             else if (po_wo_lineitems[i] == lastRow){
              if (
                 this.state.source === "WITHINDENT" || 
                 this.state.source === "WITHOPENINDENT"
              ) {
                if (po_wo_lineitems[i] === lastRow) {
                  validObj = LineItemsValidtions(validateFields);
                if (validObj) {
                  break;
                }
                }
            }  
          }
            
            else {
              if (po_wo_lineitems[i] !== lastRow) {
                console.log(validateFields);
                validObj = LineItemsValidtions(validateFields);
                if (validObj) {
                  break;
                }
              }
            }
            }
          }
      }
      // });
      if (validObj) {
        if (validObj.type === 1) {
          swal(`Please Enter ${validObj.message}`);
        }
        if (validObj.type === 2) {
          swal(`Please Select ${validObj.message}`);
        }
      } else {
        this.setState({
          currentStep: this.state.currentStep + 1,
        });
      }
    }
  };

  handleCurrentStep = (step) => {
    this.setState({
      currentStep: step,
    });
  };
  render() {
    const { steps, currentStep } = this.state;
    return (
      <div>
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            {/* {currentStep > 1 && ( */}
            <div className="iconsPurchaseBlock">
              <Stepper alternativeLabel activeStep={currentStep}>
                {steps.map((prop) => (
                  <Step key={prop.title}>
                    <StepLabel
                      StepIconComponent={(Iconprops) => {
                        return this.QontoStepIcon(
                          Iconprops,
                          prop.icon,
                          prop.activeIcon,
                          prop.title
                        );
                      }}
                    ></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {/* )} */}
            {currentStep === 1 && (
              <>
                <div>
                  <div className="consoleFormButtonBlock">
                    <h4>
                      How do you want to create the
                      {this.state.pathname === "PO" ? " Purchase " : " Work "}
                      order?
                    </h4>
                  </div>
                  <div className="consoleFormButtonBlock">
                    <ToggleButtonGroup
                      value={this.state.alignment}
                      exclusive
                      onChange={this.handleAlignment}
                      aria-label="text alignment"
                      className="btnToggleforIndent"
                    >
                      {this.state.pathname === "PO" ? (
                        <ToggleButton value="1" aria-label="centered">
                          <span>By Indent</span>
                        </ToggleButton>
                      ) : (
                        ""
                      )}
                      <ToggleButton value="2" aria-label="left aligned">
                        <span>Direct Order</span>
                      </ToggleButton>
                      {this.state.pathname === "PO" ? (
                        <ToggleButton value="3" aria-label="left aligned">
                          <span>By BOM</span>
                        </ToggleButton>
                      ) : (
                        ""
                      )}
                      {this.state.pathname === "WO" ? (
                        <ToggleButton value="4" aria-label="left aligned">
                          <span>By BOQ</span>
                        </ToggleButton>
                      ) : (
                        ""
                      )}
                      {this.state.pathname === "PO" ? (
                        <ToggleButton value="5" aria-label="centered">
                          <span>Open Indent</span>
                        </ToggleButton>
                      ) : (
                        ""
                      )}
                    </ToggleButtonGroup>
                  </div>
                </div>
                <div className="consoleFormButtonBlock">
                  {this.state.POType === 0 || this.state.POType === null ? (
                    <CustomButton
                      label={"Next"}
                      className="disabled"
                      handleClick={this.onClickNext}
                      type="sumbit"
                      disabled={true}
                    />
                  ) : (
                    <CustomButton
                      label={"Next"}
                      className="greenButton"
                      handleClick={this.onClickNext}
                      type="sumbit"
                      disabled={false}
                    />
                  )}
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                <POFillDeatils
                  source={this.state.source}
                  onSelectDep={this.handleSelectChange}
                  PoFillDetails={this.state.PoFillDetails}
                  poSourceType={this.state.poSourceType}
                  pathname={this.state.pathname}
                  poBilledToDetails={this.state.poBilledToDetails}
                  supplierBranchDetails={this.state.supplierBranchDetails}
                  poShippedToDetails={this.state.poShippedToDetails}
                  locationState={this.state.locationState}
                />

                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={this.onClickCancel}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="greenButton"
                    handleClick={this.onClicknextBtn}
                    type="sumbit"
                  />
                  {this.state.confirm && (
                    <CommonPopup>
                      <div className="delAddItem">
                        <div>Please select all the mandatory fields</div>
                        <div className="delAddItemBtns">
                          <input
                            type="button"
                            onClick={this.onclickOk.bind(this)}
                            value="Ok!"
                            className="delYesBtn"
                          />
                        </div>
                      </div>
                    </CommonPopup>
                  )}
                </div>
              </>
            )}
            {currentStep === 3 && (
              <>
                {this.state.source === "WITHBOM" ||
                this.state.source === "WITHBOQ" ? (
                  <BomBoqLineItems
                    poDetails={this.state.poDetails}
                    PoFillDetails={this.state.PoFillDetails}
                    poSourceType={this.state.poSourceType}
                    pathname={this.state.pathname}
                    source={this.state.source}
                    supplierBranchDetails={this.state.supplierBranchDetails}
                    locationState={this.state.locationState}
                  />
                ) : (
                  <LineItems
                    poDetails={this.state.poDetails}
                    PoFillDetails={this.state.PoFillDetails}
                    poSourceType={this.state.poSourceType}
                    pathname={this.state.pathname}
                    source={this.state.source}
                    supplierBranchDetails={this.state.supplierBranchDetails}
                    locationState={this.state.locationState}
                  />
                )}
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Back"}
                      className="greenBorderButton"
                      handleClick={this.onClickCancel}
                    />
                  </Link>
                  <CustomButton
                    label={"Next"}
                    className="greenButton"
                    handleClick={this.onClicknextBtn}
                    type="sumbit"
                  />
                </div>
              </>
            )}
            {currentStep === 4 && (
              <>
                <Preview
                  poDetails={this.state.poDetails}
                  PoFillDetails={this.state.PoFillDetails}
                  poSourceType={this.state.poSourceType}
                  pathname={this.state.pathname}
                  handleCurrentStep={this.handleCurrentStep}
                  supplierBranchDetails={this.state.supplierBranchDetails}
                  poBilledToDetails={this.state.poBilledToDetails}
                  poShippedToDetails={this.state.poShippedToDetails}
                  source={this.state.source}
                />
                <div className="consoleFormButtonBlock">
                  {this.state.locationState.state !== undefined &&
                    this.state.PoFillDetails.statusName === "APPROVED" && (
                      <>
                        <CustomButton
                          label={"Cancel PO"}
                          className="greenBorderButton"
                          handleClick={() => {
                            this.onClickCancelButton("6");
                          }}
                          type="sumbit"
                        />
                        <CustomButton
                          label={"Close PO"}
                          className="greenButton"
                          handleClick={() => {
                            this.onClickClose("5");
                          }}
                        />
                      </>
                    )}
                  {this.state.locationState.state !== undefined &&
                  this.state.PoFillDetails.status !== "" &&
                  this.state.PoFillDetails.status !== 3 &&
                  this.state.PoFillDetails.status !== 4 &&
                  this.state.PoFillDetails.status !== 5 &&
                  this.state.PoFillDetails.status !== 6 ? (
                    <CustomButton
                      label={"Update"}
                      className="greenButton"
                      handleClick={() => {
                        this.onUpdate("");
                      }}
                    />
                  ) : (
                    this.state.PoFillDetails.status === "" && (
                      <CustomButton
                        label={"Back"}
                        className="greenBorderButton"
                        handleClick={this.onClickCancel}
                        type="sumbit"
                      />
                    )
                  )}
                  {this.state.locationState.state !== undefined ? (
                    ""
                  ) : (
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={() => {
                        this.props.history.push(
                          this.state.locationState.pathname ===
                            "/create_purchase_order"
                            ? "/purchase_purchase_order"
                            : "/work_order"
                        );
                      }}
                      type="sumbit"
                    />
                  )}
                  {this.state.locationState.state !== undefined ? (
                    ""
                  ) : (
                    <CustomButton
                      label={"Create"}
                      className="greenButton"
                      handleClick={this.onClickConfirm}
                    />
                  )}
                  {this.state.locationState.state !== undefined &&
                    this.state.ApproveButton === true && (
                      <>
                        <CustomButton
                          label={"Approve"}
                          className="approved"
                          handleClick={() => {
                            this.onClickApprove(
                              this.state.PoFillDetails.status
                            );
                          }}
                        />
                        <CustomButton
                          label={"Reject"}
                          className="rejected"
                          handleClick={() => {
                            this.onClickReject("4");
                          }}
                          type="sumbit"
                        />
                      </>
                    )}
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { po_wo_lineitems, ItemDetailsByPOId } = state.PurchaseOrderReducer;
  const { approval_hierarchy } = state.EmployeeReducer;
  const { CreateBranchMaster } = state.BranchMasterReducer;
  const { billing_shipping_adsList } = state.DropDownListReducer;
  return {
    po_wo_lineitems,
    approval_hierarchy,
    CreateBranchMaster,
    billing_shipping_adsList,
    ItemDetailsByPOId,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    CreateWorkOrder,
    POWOLineItemsProps,
    UpdatePoWo,
    updateBranchMaster,
    ApprovalHierarchy,
    getItemDetailsByPoId,
  })(CreatePage)
);
