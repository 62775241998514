import React, { Component } from "react";
// import Gridwithcustomview from '../../../components/Grid/Gridwithcustomview';
import Gridwithcustomview from "../../../components/Grid/PaginationGridView";

import GridArrowIcon from "../../../assets/images/gridArrowIcon.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import { getPayRegisterList } from "../../../store/HRMS/PayRegister/actions";
class PayRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alignment: "right",
      menu: "none",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      payRegisterListColumn: [],
      payRegisterListData: [],
      totalRecs: 0,
      startIndex: 1,
      lastIndex: 10,
      fromDate: "",
      toDate: "",
      status: "",
      dataexp: "",
    };
  }
  componentDidMount() {
    const data = {
      fromDate: "",
      lastIndex: this.state.lastIndex,
      startIndex: this.state.startIndex,
      status: "",
      toDate: "",
    };
    this.props.getPayRegisterList(
      serverApi.PAY_REGISTER_LIST,
      data,
      this.props.history
    );
    const expdata = {
      ...data,
      lastIndex: 0,
      startIndex: 0,
    };
    this.setState({ loadingdata: true, dataexp: expdata });
  }
  componentWillReceiveProps(props) {
    if (props.payRegisterList) {
      this.setState({
        payRegisterListData: props.payRegisterList.data,
        totalRecs: props.payRegisterList.totRecs,
      });
    }
  }
  handleSearch = (data1) => {
    const data = {
      fromDate: data1.fromDate,
      lastIndex: this.state.lastIndex,
      startIndex: this.state.startIndex,
      status: data1.status,
      toDate: data1.toDate,
    };
    this.setState({
      fromDate: data1.fromDate,
      status: data1.status,
      toDate: data1.toDate,
    });

    this.props.getPayRegisterList(
      serverApi.PAY_REGISTER_LIST,
      data,
      this.props.history
    );
  };
  handlePagination = (page, sizePerPage, type) => {
    const currentIndex = (page - 1) * sizePerPage;

    const data = {
      fromDate: this.state.fromDate,
      status: this.state.status,
      toDate: this.state.toDate,
    };

    if (type === 1) {
      data.startIndex = sizePerPage;
      data.lastIndex = currentIndex;
      if (currentIndex >= 0) {
        this.props.getPayRegisterList(
          serverApi.PAY_REGISTER_LIST,
          data,
          this.props.history
        );
      }
    } else {
      data.startIndex = currentIndex === 0 ? currentIndex + 1 : currentIndex;
      data.lastIndex = sizePerPage;
      if (currentIndex >= 0) {
        this.props.getPayRegisterList(
          serverApi.PAY_REGISTER_LIST,
          data,
          this.props.history
        );
      }
    }
    this.setState({
      startIndex: currentIndex,
      lastIndex: sizePerPage,
    });
  };
  render() {
    return (
      <>
        <Gridwithcustomview
          mdata={this.state.payRegisterListData}
          mcolumns={mcolumns}
          actions={true}
          micon={GridArrowIcon}
          url={"/ViewPayRegister"}
          filter_form={"PayRegister"}
          handleSearch={this.handleSearch}
          totalRecs={this.state.totalRecs}
          pageno={this.state.pageno}
          page={this.state.startIndex}
          sizePerPage={this.state.lastIndex}
          handlePagination={this.handlePagination}
          apiexp={serverApi.PAY_REGISTER_LIST}
          payloadexp={this.state.dataexp}
          expName={"PayRegisterList.csv"}
        />
      </>
    );
  }
}
const mcolumns = [
  {
    accessor: "id",
    Header: "Rec Id",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    accessor: "payscheme",
    Header: "PayScheme Name",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },

  {
    accessor: "fromDateDesc",
    Header: "FromDate",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    accessor: "toDateDesc",
    Header: "ToDate",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    accessor: "wageType",
    Header: "Wage Type",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    accessor: "netPay",
    Header: "Net Pay",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    accessor: "status",
    Header: "Status",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    accessor: "name",
    Header: "Name",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },

  {
    accessor: "createdBy",
    Header: "Created By",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    accessor: "branchId",
    Header: "Branch Id",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    accessor: "createDate",
    Header: "Create Date",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    accessor: "modifiedBy",
    Header: "Modified By",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    accessor: "modifiedDate",
    Header: "Modified Date",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    accessor: "fromDate",
    Header: "From Date",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    accessor: "toDate",
    Header: "To Date",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    accessor: "lupdate",
    Header: "Lup date",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    accessor: "lupdateDesc",
    Header: "Lup Date Desc",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    accessor: "userId",
    Header: "User Id",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    accessor: "companyId",
    Header: "Company Id",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    accessor: "startIndex",
    Header: "Start Index",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    accessor: "lastIndex",
    Header: "Last Index",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
  {
    accessor: "approveButton",
    Header: "Approve Button",
    headerAlign: "left",
    align: "left",
    hidden: true,
  },
];
const mapStatetoProps = (state) => {
  const { payRegisterList } = state.PayRegisterReducer;
  return { payRegisterList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getPayRegisterList,
  })(PayRegister)
);
