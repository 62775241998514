import {PAYROLL_DATA, ADD_ATTENDENCE, PAYROLL_CREATE} from './actionType'
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {PayRollDataSuccessfull, getAttendenceSuccessfull, payrollcreatesuccess} from './actions'
import {getList, getListurl, postAdd} from '../../../helpers/Server_Helper';
import swal from 'sweetalert';
import {
    hideSpinner,
    serverApi, showSpinner,
  } from "../../../helpers/Consts";
function* getPayRollDataList({payload:{url,data,history}}) {
    try{
        showSpinner()
        const response = yield call(getList,url,data);
        yield put(PayRollDataSuccessfull({response}))
        if(response){
            hideSpinner()
        }
    }catch(error){
        hideSpinner()
        if (error) {
            if (error.response) {
              if (error.response.data) {
                swal(error.response.data.error,{icon:"error"});
              }
            }
          }
        console.log(error)
    } 
}

function* payrollCreate({payload:{url,data,history}}){
    try{
        const response = yield call(postAdd,url,data);
      
        history.push("/data_collection")
        yield put(payrollcreatesuccess(response))
        swal(response.data.message, {icon:"success"})
    }catch (error){
        console.log(error)
        if (error) {
            if (error.response) {
              if (error.response.data) {
                swal(error.response.data.error,{icon:"error"});
              }
            }
          }
    }
}


function* getAttendenceList({payload:{url,data, history}}) {
    try{
        const response = yield call(getListurl,url);
        yield put(getAttendenceSuccessfull({response}))
        if(response.data.status === true ) {  
            swal(response.data.message, {icon:"success"})
            // history.push("/data_collection")

        }else {
            swal(response.data.message, {icon:"error"})
            // history.push("/data_collection")
        }
 
    }catch(error){
        if (error) {
            if (error.response) {
              if (error.response.data) {
                swal(error.response.data.error,{icon:"error"});
              }
            }
          }
        console.log(error)
    } 
}

export function* watchPayRollDataList () {
    yield takeEvery(PAYROLL_DATA, getPayRollDataList)
}

export function* watchAttendenceList() {
    yield takeEvery(ADD_ATTENDENCE, getAttendenceList)
}

// export function* watchCreatePayload() {
//     yield takeEvery(CREATE_PAY_ROLL_DATA, createPayRollDatas)
// }

export function* watchPayrollcCreate(){
    yield takeEvery(PAYROLL_CREATE, payrollCreate)
}

function* PayRollDataSaga() {
    yield all([
        fork( watchPayRollDataList ),
        fork(watchAttendenceList),
        // fork(watchCreatePayload),
        fork(watchPayrollcCreate)

    ])
}

export default PayRollDataSaga;