import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PrintIcon from "@mui/icons-material/Print";
import CustomButton from "../../../components/Buttons/Buttons";
import { serverApi, getTenantId } from "../../../helpers/Consts";

class ReceiptPreview extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      rowcount: 1,
      show: false,
      checkAll: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      companyName: '',
      address1: '',
      address2: '',
      comName: '',
      voucherNo: this.props.location.state ? this.props.location.state.rowData.transactions.voucherNo : '',
      bankName: this.props.location.state ? this.props.location.state.rowData.transactions.bankdetails.bankName : '',
      account: this.props.location.state ? this.props.location.state.rowData.transactions.bankdetails.accountNo : '',
      date: this.props.location.state ? this.props.location.state.rowData.transactions.transactionDate : this.props.header.transactionDate,
      ledgerName: this.props.location.state ? this.props.location.state.rowData.ledgerName : this.props.header.ledgerName,
      totalAmount: this.props.location.state ? this.props.location.state.rowData.fundsOut : this.props.header.fundsOut,
      descp: this.props.location.state ? this.props.location.state.rowData.description : this.props.header.description,
      bankRefNo: this.props.location.state ? this.props.location.state.rowData.transactions.bankRefnumber : this.props.header.bankRefnumber,
      createdBy: this.props.location.state ? this.props.location.state.rowData.transactions.createdBy : '',
      companyLogo:""
    };
    this.state.filterText = "";
    this.state.tooltip = "item description...."

  }
  componentWillReceiveProps(props) {

  }

  convertNumberToWords(amount) {
    var words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (let i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (let i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      var value = "";
      for (let i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Crores ";
        }
        if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Lakhs ";
        }
        if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Thousand ";
        }
        if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  }

  withDecimal(n) {
    var nums = n.toString().split(".");
    var whole = this.convertNumberToWords(nums[0]);

    if (nums.length == 2) {

      if (parseInt(nums[1]) > 0) {

        var fraction = this.convertNumberToWords(parseInt(nums[1]));

        return whole + "Rupees and " + fraction + "Paisa";

      } else {
        return whole + "Rupees";
      }
    } else {
      return whole + "Rupees";
    }

  }

  handlePdf = () => {
    
    let companyId = localStorage.getItem("companyId");
    let transactionUniqINo = this.props.location.state.rowData.transaUniqNo;
    fetch(
      serverApi.DOWNLOAD_RECEIPT +
        transactionUniqINo +
        "/" +
        companyId +
        "/" +
        this.state.userDit.userId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          "X-TenantId": getTenantId(window.location.hostname),
          Authorization:
            localStorage.getItem("token_type") +
            localStorage.getItem("access_token"),
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Receipt" + ".pdf");
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  render() {
    
      let convertion = this.withDecimal(this.props.header.fundsIn);
      var products = this.props.products.filter((product) => product.amount!=0 && product.isActive != 0);


    var companyData = JSON.parse(localStorage.getItem("authUser"))
    var companyId = localStorage.getItem('companyId')
    companyData.companyDetails.map((p) => {
      var id = p.value
      if (companyId === id.toString()) {
        this.state.companyName = p.label
        this.state.address = p.address1 + ' ' + p.address2
        this.state.comName = p.name
        this.state.companyLogo = p.companyLogo
      }

    })
  
  let month = [
      'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
    ]

    var paymentSource = '';
    var psource = this.props.header.paymentSource

    if (psource === 'I') {
      paymentSource = 'IMPS'
    }
    if (psource === 'R') {
      paymentSource = 'RTGS'
    }
    if (psource === 'C') {
      paymentSource = 'Cheque'
    }
    if (psource === 'N') {
      paymentSource = 'NEFT'
    }
    if (psource === 'CH') {
      paymentSource = 'Cash'
    }
    if (psource === 'J') {
      paymentSource = 'Journal'
    }
    if (psource === 'T') {
      paymentSource = 'TPT'
    }

    var date = new Date();
    var monthName = month[date.getMonth()];
    return (<>
      {/* <p>Create page</p> */}
      <div className="previewBlock">
      <div
            style={{ display: "flex", justifyContent: "end", padding: "10px" }}
          >
            {this.props.location.state ? (
              this.props.location.state.rowData.transactions ? (
                this.props.location.state.rowData.transactions.tranStatus ===
                3 ? (
                  <CustomButton
                    label="Print"
                    handleClick={this.handlePdf}
                    type="button"
                    className="lightBlueButton"
                    muIcon={<PrintIcon />}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        <table
          cellpadding="0"
          cellspacing="0"
          width="90%"
          className="previewTable"
        >
          
          <tbody>
            <tr>
              <td
                colspan="1"
                style={{ borderRight: 'none', borderBottom: 'none' }}
              >
                {/* this will be company logo */}
                <img src={this.state.companyLogo} width="100%" alt="" />
              </td>
              <td
                colspan="1"
                style={{
                  borderBottom: 'none',
                  borderLeft: 'none',
                  textAlign: 'right',
                }}
              >
                <b>{this.state.companyName}</b>
                <br></br>
                {this.state.address}

              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                style={{ textAlign: 'center', borderTop: 'none' }}
              >
                <h2>RECEIPT VOUCHER</h2>
              </td>
            </tr>
            <tr>
              <td colspan="2" style={{ padding: 0 }}>
                <table width="100%">
                  <tr>
                    <td
                      style={{
                        width: '70%',
                        borderTop: 'none',
                        borderBottom: 'none',
                        padding: 'none',
                        borderLeft: 'none',
                      }}
                    >
                      <b>NO :
                        {this.state.comName + '/' + monthName + '/0' + this.state.voucherNo}
                      </b>
                      <br></br>
                      <b>Through :
                        {/* {this.state.bankName + ' ' + this.state.account} */}
                      </b>

                    </td>
                    <td
                      style={{
                        width: '30%',
                        borderTop: 'none',
                        borderBottom: 'none',
                        padding: 'none',
                        borderLeft: 'none',
                        borderRight: 'none'
                      }}
                    >
                      <b style={{ float: 'right' }}>Date : {this.props.header.transactionDate} </b>
                      <br></br>

                    </td>

                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="2" style={{ padding: 0 }}>
                <table width="100%">
                  <tr>
                    <td
                      style={{
                        width: '70%',
                        borderTop: 'none',
                        borderBottom: 'none',
                        padding: 'none',
                        borderLeft: 'none',
                      }}
                    >
                      <b>Particulars</b>
                      <br></br>

                    </td>

                    <td
                      style={{
                        width: '30%',
                        borderTop: 'none',
                        borderBottom: 'none',
                        padding: 'none',
                        borderLeft: 'none',
                      }}
                    >
                      <b style={{ float: 'right' }}>Amount</b>
                      <br></br>

                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="2" style={{ padding: 0 }}>
                <table width="100%">
                  <tr>
                    <td
                      style={{
                        width: '70%',
                        borderTop: 'none',
                        borderBottom: 'none',
                        padding: 'none',
                        borderLeft: 'none',
                      }}
                    >
                      <b>Account: </b>
                      <br></br>
                      <p>{this.props.header.ledgerName}</p>

                      {products.map((prop, ind) => {
                      
                        return (
                          <>
                            <p>{'Agst Ref ' + this.state.date + ' ' + prop.invoiceNo + ' ' + parseFloat(prop.amount).toFixed(2) + ' Dr'}</p>
                          </>
                        )
                      })}
                      <br></br>
                      <b>On Account Of : </b>
                      <br></br>
                      <p>{this.props.header.description}</p>
                      <br></br>

                      <b>Bank Transaction Details: </b>
                   
                      <p>{this.props.header.ledgerName}</p>
                      <br></br>
                      <p>{paymentSource + ' ' + this.props.header.bankRefnumber+ ' ' + this.props.header.transactionDate + ' ' + parseFloat(this.props.header.fundsIn).toFixed(2)}</p>

                      <br></br>


                    </td>

                    <td
                      style={{
                        width: '30%',
                        borderTop: 'none',
                        borderBottom: 'none',
                        padding: 'none',
                        borderLeft: 'none',
                      }}
                    >
                      <b style={{ float: 'right' }}>{parseFloat(this.props.header.fundsIn).toFixed(2)}</b>
                      <br></br>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderBottom: 'none',
                        padding: 'none',
                        borderLeft: 'none',
                        borderTop: 'none',
                      }}
                    >
                      <b>Amount In Words</b>
                      <p style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{convertion}</p>
                    </td>
                    <td
                      style={{
                        width: '30%',
                        borderBottom: 'none',
                        padding: 'none',
                        borderLeft: 'none',
                      }}
                    >
                      <b style={{ float: 'right' }}>{parseFloat(this.props.header.fundsIn).toFixed(2)}</b>
                      <br></br>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                align="right"
                style={{ borderTop: 'none', borderBottom: 'none' }}
              >
                Authorised Signature
              </td>
            </tr>

            <tr>
              <td
                colspan="1"
                style={{ borderTop: 'none', borderRight: 'none' }}
              >
                Receivers Signature:
              </td>
              <td
                colspan="1"
                align="right"
                style={{ borderTop: 'none', borderLeft: 'none' }}
              >
                Created By:- {this.state.userDit.userId}<br />
                Authorized By:- {this.state.userDit.userId}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>)
  }
}

const mapStatetoProps = (state) => { }

export default withRouter(connect(mapStatetoProps, {})(ReceiptPreview))
