import React, { Component } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


export default class EmployeeCategory extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        return (
            <div className="upcomingEventsSection">
                <div className="eventContainer">
                    <h3>Upcoming Events</h3>
                    <div className="upcomingEventsMonth">
                        <p>This month</p>
                        <ArrowDropDownIcon className="dropdownIcon" />
                    </div>
                </div>
                <div className="upcomingEventsBlock" >
                    <table>
                        <th>Birthdays in This Month</th>
                        <th>&nbsp;</th>
                        <tr>
                            <td>Friday, 23 October</td>
                            <td>Tony Stark</td>
                        </tr>
                        <tr>
                            <td>Monday, 27 October</td>
                            <td>Scarlet Johanson</td>
                        </tr>
                        <tr>
                            <td>Monday, 27 October</td>
                            <td>Anna Herbert</td>
                        </tr>
                        <tr>
                            <td>Wednesday, 29 October</td>
                            <td>Drew Othicka</td>
                        </tr>
                    </table></div>
            </div>
        )
    }
}