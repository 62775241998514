import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {
  BIO_METRIc_DEVICE,
  CREATE_BIO_METRIC_DEVICE,
  CREATE_SHIFT_MASTER,
  DELETE_BIO_METRIC,
  GET_WORKERDETAILS_BYID,
  VIEW_BY_ID_SHIFT_MASTER,
} from "./actionType";
import { postAdd, getListurl } from "../../../helpers/Server_Helper";
import {
  ShiftMasterSuccessfull,
  BioMetricSuccessfull,
  createBioMetriDeviceSuccessfull,
  deleteBioMetriDeviceSuccessfull,
  getWorkerDetailsByIdSuccessfull,
  getWorkerDetailsById,
  ViewByIdShiftMasterSuccessfull,
  ViewByIdShiftMaster,
} from "./actions";
import swal from "sweetalert";
import { serverApi } from "../../../helpers/Consts";
function* setShiftMaster({ payload: { url, data, history, ebId } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(ShiftMasterSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
    if (response) {
      yield put(
        ViewByIdShiftMaster(
          serverApi.VIEW_BY_ID_EMPLOYEE_SHIFT + "/" + ebId,
          history,
          null
        )
      );
    }
  } catch (error) {
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}
function* getBioMetricDevice({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(BioMetricSuccessfull({ response }));
  } catch (error) {
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}
function* setBioMetricDevice({ payload: { url, data, history, ebId } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createBioMetriDeviceSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
    if (response) {
      yield put(
        getWorkerDetailsById(
          serverApi.EMPLOYEE_DETAILS_VIEWBYID +
            ebId +
            "/userId/" +
            JSON.parse(localStorage.getItem("authUser")).userId,
          history
        )
      );
    }
  } catch (error) {
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}
function* getWorkerDetailsByIdData({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getWorkerDetailsByIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* deleteBioMetricData({ payload: { url, history, ebId } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(deleteBioMetriDeviceSuccessfull({ response }));
    swal(response.data.message, { icon: "success" });
    if (response) {
      yield put(
        getWorkerDetailsById(
          serverApi.EMPLOYEE_DETAILS_VIEWBYID +
            ebId +
            "/userId/" +
            JSON.parse(localStorage.getItem("authUser")).userId,
          history
        )
      );
    }
  } catch (error) {
    console.log(error);
  }
}
function* GetShiftMasterByID({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(ViewByIdShiftMasterSuccessfull({ response }));
  } catch (error) {
    if (error) {
      if (error.response) {
        if (error.response.data) {
          swal(error.response.data.error, { icon: "error" });
        }
      }
    }
  }
}

export function* watchSpellMasterList() {
  yield takeEvery(CREATE_SHIFT_MASTER, setShiftMaster);
  yield takeEvery(BIO_METRIc_DEVICE, getBioMetricDevice);
  yield takeEvery(CREATE_BIO_METRIC_DEVICE, setBioMetricDevice);
  yield takeEvery(GET_WORKERDETAILS_BYID, getWorkerDetailsByIdData);
  yield takeEvery(DELETE_BIO_METRIC, deleteBioMetricData);
  yield takeEvery(VIEW_BY_ID_SHIFT_MASTER, GetShiftMasterByID);
}

function* ShiftMasterSaga() {
  yield all([fork(watchSpellMasterList)]);
}

export default ShiftMasterSaga;
