import {
  MACHINE_MASTER_LIST,
  CREATE_MACHINE_MASTER,
  UPDATE_MACHINE_MASTER,
  GET_MACHINE_VIEW_BY_ID,
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { postAdd, getList, getListurl } from "../../../helpers/Server_Helper";
import {
  machineMasterListSuccessfull,
  createMachineMasterSuccessfull,
  updateMachineMasterSuccessfull,
  getMachineViewByIdSuccessfull,
} from "./actions";
import swal from "sweetalert";

function* MachineMasterList({ payload: { url, data } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(machineMasterListSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getMachineViewByIdListSaga({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getMachineViewByIdSuccessfull({ response }));
  } catch (error) {
    console.log(error);
  }
}
function* CreateMachineMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(createMachineMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/machine_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    if (error.response) {
      if (error.response.data.status) {
        swal(error.response.data.title, { icon: "error" });
      }
    }
  }
}
function* UpdateMachineMaster({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    yield put(updateMachineMasterSuccessfull({ response }));
    if (response.data.status === true) {
      swal(response.data.message, { icon: "success" });
      history.push("/machine_master");
    } else {
      swal(response.data.message, { icon: "error" });
    }
  } catch (error) {
    swal("error:" + error, { icon: "danger" });
  }
}

export function* watchMachineMasterList() {
  yield takeEvery(MACHINE_MASTER_LIST, MachineMasterList);
}
export function* watchCreateMachineMaster() {
  yield takeEvery(CREATE_MACHINE_MASTER, CreateMachineMaster);
}
export function* watchUpdateMachineMaster() {
  yield takeEvery(UPDATE_MACHINE_MASTER, UpdateMachineMaster);
}

export function* watchMachineViewByList() {
  yield takeEvery(GET_MACHINE_VIEW_BY_ID, getMachineViewByIdListSaga);
}

function* MachineMasterSaga() {
  yield all([
    fork(watchMachineMasterList),
    fork(watchCreateMachineMaster),
    fork(watchUpdateMachineMaster),
    fork(watchMachineViewByList),
  ]);
}

export default MachineMasterSaga;
