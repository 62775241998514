import React, { Component } from "react";
import { Grid } from "@mui/material";
import DynamicSelect from "../../../components/Dropdown/ProcurementDropdown/DynamicSelect";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import { approvedIndents } from "../../../store/Global/DropDownApis/actions";
import { allItemGroupMasterList } from "../../../store/Global/DropDownApis/actions";
import { BOQList } from "../../../store/Global/DropDownApis/actions";
import { PriceEnquiryLineitemsProps } from "../../../store/Purchase/PriceEnquiry/actions";

class PriceEnquiryLineItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCount: 0,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      approvedIndents: [],
      allItemGroupMasterList: [],
      approvedIndentsList: [],
      PELineitems: [],
      dtlId: 1,
      indentItems: this.props.indentItems,
    };
  }

  componentDidMount() {
    this.props.allItemGroupMasterList(
      serverApi.GET_ALL_ITEMGROUP_MASTER_LIST +
        localStorage.getItem("companyId") +
        "/" +
        this.state.userDit.cipher,
      this.props.history
    );

    if (this.props.PELineitems && this.props.PELineitems !== undefined) {
      if (this.props.PELineitems) {
        this.props.PELineitems.map((item) => {
          let data = {
            branchId: this.props.PEFillDetails.branchId,
            companyId: this.state.companyId,
            financialYear: localStorage.getItem("acadamicYear"),
            itemGroup: item.itemGroupCode,
            recordType: "INDENT",
          };
          this.props.approvedIndents(
            serverApi.GET_APPROVEDINDENTS,
            data,
            this.props.history
          );
        });
      }
    } else {
      const PELineitems = [
        {
          dtlId: 1,
          companyId: "",
          createdBy: "",
          indentNo: "",
          isRateActive: 1,
          isActive: 1,
          itemId: "",
          itemName: "",
          itemGroupCode: "",
          itemCode: "",
          suppCode: "",
          itemRate: "",
          suppComments: "",
          suppQuoteFile: "",
          suppResponseDateTime: "",
          itemDesc: "",
        },
      ];
      this.props.PriceEnquiryLineitemsProps(PELineitems);
    }
  }

  componentWillReceiveProps(props) {
    if (props.approvedIndentsList) {
      this.setState({
        approvedIndentsList: props.approvedIndentsList,
      });
    }
    if (props.allItemGroupMaster_List) {
      this.setState({
        allItemGroupMasterList: props.allItemGroupMaster_List.data,
      });
    }

    if (props.PELineitems) {
      this.setState({
        PELineitems: props.PELineitems,
      });
    }
    if (props.boqListResp) {
      this.setState({
        indentItems: props.boqListResp.data,
      });
      if (props.boqListResp.data) {
        this.props.indentItems.indentDetails =
          props.boqListResp.data.indentDetails;
      }
    }
  }

  handleChange = (data) => (event) => {
    event.preventDefault();
    const { key, row } = data;
    const { value } = event.target;
    this.setState({ [key]: event });
    let mname = key.split("_");

    if (mname[0] === "Make") {
      row.suppComments = value;
    }
    this.props.PriceEnquiryLineitemsProps(this.state.PELineitems);
  };
  handleChangedropdown = (selectedValue, selectedName, name, selectedRow) => {
    if (name === "item_group") {
      selectedRow.itemDesc = selectedName;
      selectedRow.itemGroupCode = selectedValue;
      const data = {
        branchId: this.props.PEFillDetails.branchId,
        companyId: this.state.companyId,
        financialYear: localStorage.getItem("acadamicYear"),
        itemGroup: selectedValue,
        recordType: "INDENT",
      };
      this.props.approvedIndents(
        serverApi.GET_APPROVEDINDENTS,
        data,
        this.props.history
      );
      if (selectedValue !== "") {
        let newLineId = selectedRow.dtlId;
        let PELineitems = this.state.PELineitems;
        let obj = {
          dtlId: newLineId + 1,
          companyId: "",
          createdBy: "",
          indentNo: "",
          isRateActive: 1,
          isActive: 1,
          itemId: "",
          itemName: "",
          itemGroupCode: "",
          suppCode: "",
          itemRate: "",
          suppComments: "",
          suppQuoteFile: "",
          suppResponseDateTime: "",
          itemDesc: "",
        };
        PELineitems.push(obj);

        this.setState({
          PELineitems: PELineitems,
        });
        this.props.PriceEnquiryLineitemsProps(this.state.PELineitems);
      }
    }
    if (name === "Indent_List") {
      selectedRow.indentNo = selectedValue;
      this.props.BOQList(
        serverApi.CREATE_INDENT +
          "/" +
          selectedValue +
          "/userid/" +
          this.state.userDit.userId,
        this.props.history
      );
    }
    this.props.PriceEnquiryLineitemsProps(this.state.PELineitems);
  };

  onDelEvent = (event) => {
    this.setState({ delete: true, dtlId: event.target.name });
  };
  onDel = (event) => {
    this.setState({ delete: false });
    if (event.target.value === "Yes") {
      if (this.state.PELineitems.length > 1) {
        let dtlId = this.state.dtlId;
        let data = this.state.PELineitems;
        data.splice(
          data.findIndex((d) => d.dtlId == dtlId),
          1
        );
        setTimeout(() => {
          this.setState({
            PELineitems: data,
          });
       
          this.props.PriceEnquiryLineitemsProps(data);
        }, 0);
      }
    }
  };

  render() {
    return (
      <div className="editableTableContainer">
        <div className="gridContainer">
          <div className="gridComponent">
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {tableHeaders.map((column, index) =>
                    column.header === "Item Group" ? (
                      <th key={index}>
                        {column.header} <span style={{ color: "red" }}>*</span>
                      </th>
                    ) : (
                      <th key={index}>{column.header}</th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {this.state.PELineitems.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <>
                      {RowData &&
                        RowData.map((col, index) =>
                          col.component === "EditableCell" ? (
                            <>
                              <td key={index}>
                                <div className="quantityDropdownBlock">
                                  <Grid className="selectAndTextfield">
                                    <Grid
                                      xs={12}
                                      md={12}
                                      className="dynamicSelectBlock"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        type="text"
                                        name={col.type + "_" + rowIndex}
                                        id={col.no}
                                        value={
                                          col.type === "Make"
                                            ? row.suppComments
                                            : ""
                                        }
                                        onChange={this.handleChange({
                                          key: col.type + "_" + rowIndex,
                                          row,
                                        })}
                                        className="inputBlockContainer"
                                      />
                                    </Grid>
                                  </Grid>
                                </div>
                              </td>
                            </>
                          ) : col.type === "Indent_List" ? (
                            <td key={index}>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <DynamicSelect
                                      arrayOfData={
                                        this.state.approvedIndentsList
                                      }
                                      className="dropdownBlockContainer"
                                      onSelectChange={this.handleChangedropdown}
                                      selected={row.indentNo}
                                      name={col.type}
                                      row={row}
                                      update={row.indentNo ? 1 : 0}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          ) : (
                            <td key={index}>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <DynamicSelect
                                      arrayOfData={
                                        this.state.allItemGroupMasterList
                                      }
                                      className="dropdownBlockContainer"
                                      onSelectChange={this.handleChangedropdown}
                                      selected={row.itemGroupCode}
                                      name={col.type}
                                      row={row}
                                      update={row.itemGroupCode ? 1 : 0}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          )
                        )}
                    </>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const RowData = [
  {
    no: 1,
    component: "DynamicSelect",
    type: "item_group",
    dropDown: "dropDown",
    ItemGrp: true,
  },
  {
    no: 2,
    component: "DynamicSelect",
    type: "Indent_List",
    dropDown: "dropDown",
    // Items: true,
  },
  {
    no: 3,
    component: "EditableCell",
    type: "Make",
  },
];
const tableHeaders = [
  {
    header: "Item Group",
  },
  {
    header: "Indent List",
  },
  {
    header: "Make",
  },
];

const mapStatetoProps = (state) => {
  const { approvedIndentsList, allItemGroupMaster_List, boqListResp } =
    state.DropDownListReducer;
  const { PELineitems } = state.PriceEnquiryReducer;

  return {
    approvedIndentsList,
    allItemGroupMaster_List,
    boqListResp,
    PELineitems,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    approvedIndents,
    allItemGroupMasterList,
    BOQList,
    PriceEnquiryLineitemsProps,
  })(PriceEnquiryLineItems)
);
