import React, { Component } from "react";
import { Grid } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import TaxMasterFillDetails from "./TaxMasterFillDetails";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import { postAddItemToTaxList } from "../../../store/Master/AddItemToTaxList/actions";
import { validate } from "../../../Utilities/Validations";
import swal from "sweetalert";

class createTaxMaster extends Component {
  constructor() {
    super();
    this.state = {
      steps: [
        {
          title: "Fill Details",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
      ],
      currentStep: 1,
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      TaxMasterFill_Details: {
        taxName: "",
        taxPercentage: "",
      },
    };
  }
  // Function for Next page
  onClickNext = () => {
    const orgId = this.state.userDit.userId;
    const validateFields = [
      {
        field: this.state.TaxMasterFill_Details.taxName,
        msg: "Tax Name",
        type: 1,
      },
      {
        field: this.state.TaxMasterFill_Details.taxPercentage,
        msg: "Tax percentage",
        type: 1,
      },
    ];
    let validObj = validate(validateFields);
    if (validObj) {
      if (validObj.type === 1) {
        swal(`Please Enter ${validObj.message}`);
      }
      if (validObj.type === 2) {
        swal(`Please Select ${validObj.message}`);
      }
    } else {
      if (this.props.location.state === undefined) {
        const data = {
          tax: {
            taxName: this.state.TaxMasterFill_Details.taxName,
            taxPercentage: this.state.TaxMasterFill_Details.taxPercentage,
            createdBy: orgId.toString(),
          },
          postAuthenticationDto: {
            cipher: this.state.userDit.cipher,
            companyId: this.state.companyId,
          },
        };
        this.props.postAddItemToTaxList(
          serverApi.ADDITEM_TO_TAXLIST,
          data,
          this.props.history
        ); //  calling api for create  tax master
      } else {
        const data = {
          tax: {
            taxId: this.props.location.state.rowData.taxId,
            taxName: this.state.TaxMasterFill_Details.taxName,
            taxPercentage: this.state.TaxMasterFill_Details.taxPercentage,
            createdBy: orgId.toString(),
          },
          postAuthenticationDto: {
            cipher: this.state.userDit.cipher,
            companyId: this.state.companyId,
          },
        };
        this.props.postAddItemToTaxList(
          serverApi.UPDATE_TAXLIST,
          data,
          this.props.history
        ); // calling API for update tax master
      }
    }
  };
  // Function for previous page
  onClickBefore = () => {
    const { currentStep } = this.state;
    this.setState({
      taxName: "",
      taxPercentage: "",
    });
    if (currentStep > 1) {
      this.setState({
        currentStep: currentStep - 1,
      });
    } else {
      this.props.history.push("/tax_master");
    }
  };
  QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;

    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };

  // Function for popup
  onclickOk() {
    this.setState({
      confirm: false,
    });
  }

  render() {
    const { currentStep } = this.state;
    return (
      <div>
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            {currentStep === 1 && (
              <>
                <TaxMasterFillDetails
                  onhandlechangeValue={this.onhandlechangeValue}
                  TaxMasterFill_Details={this.state.TaxMasterFill_Details}
                />

                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Cancel"}
                      className="greenBorderButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  {this.props.location.state !== undefined ? (
                    <CustomButton
                      label={"Update"}
                      className="greenButton"
                      handleClick={this.onClickNext}
                      type="sumbit"
                    />
                  ) : (
                    <CustomButton
                      label={"Create"}
                      className="greenButton"
                      handleClick={this.onClickNext}
                      type="sumbit"
                    />
                  )}
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { AddItem_To_Tax_List } = state.AddItem_To_Tax_List; // fetching creata and update from additems to Tax list
  return { AddItem_To_Tax_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    postAddItemToTaxList,
  })(createTaxMaster)
);
