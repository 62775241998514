import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import {
  createAgentMaster,
  updateAgentMaster,
} from "../../../store/Master/AgentMaster/actions";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import { getAgentNameList } from "../../../store/MasterDropdownApis/actions";

class CreateAgentMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      agentName: "",
      agentNameId: "",
      createdBy: null,
      createdOn: "",
      agentNameList: [], // agent Name List list
    };
  }
  onClickCreateUpdate = () => {
    const userId = this.state.userDit.userId;
    const { agentName } = this.state;
    if (agentName !== "" && agentName !== undefined) {
      if (this.props.location.state === undefined) {
        const data = {
          authDtl: {
            companyId: 25,
            cipher: this.state.userDit.cipher,
          },
          agentDtl: {
            agentCompanyId: this.state.agentNameId,
            createdBy: userId.toString(),
          },
        };
        this.props.createAgentMaster(
          serverApi.CREATE_AGENT_MASTER,
          data,
          this.props.history
        ); // calling create agent master api
      } else {
        const data = {};
        this.props.updateAgentMaster(
          serverApi.UPDATE_AGENT_MASTER,
          data,
          this.props.history
        ); // calling update Agent master api
      }
    } else {
      this.setState({
        confirm: true,
      });
    }
  };
  // function for previous page
  onClickCancel = () => {
    this.props.history.push("/agent_master");
  };
  // function for popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "agentName") {
      this.setState({
        agentName: selectedValue,
        agentNameId: selectedValue,
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyId"),
    };
    this.props.getAgentNameList(
      serverApi.GET_AGENT_NAME_LIST,
      data,
      this.props.history
    ); // calling agent name list api
  }
  componentWillReceiveProps(props) {
    if (props.agentNameList) {
      this.setState({
        agentNameList: props.agentNameList, // updating for agent name list
      });
    }
  }

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.
    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.agentNameList} // updating agent name list
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.agentNameId}
                        update={this.props.location.state ? 1 : 0}
                        name="agentName"
                        label="Agent Name"
                      />
                    </div>
                  </Box>
                  {this.props.location.state === undefined ? (
                    ""
                  ) : (
                    <>
                      <Box gridColumn="span 6">
                        <div className="consoleUpdateTextBlock">
                          <div>
                            <span className="taxLabel">
                              Created By : <b>{this.state.createdBy}</b>
                            </span>
                          </div>
                          <div>
                            <span className="taxLabel">
                              Created On :<b> {this.state.createdOn}</b>
                            </span>
                          </div>
                        </div>
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.props.location.state !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
          {/* common popup for validation */}

          {this.state.confirm && (
            <CommonPopup>
              <div className="delAddItem">
                <div className="mandatoryFieldText">
                  Please select all the mandatory Fields
                </div>
                <div className="delAddItemBtns">
                  <input
                    type="button"
                    onClick={this.onclickPopUP.bind(this)}
                    value="Ok"
                    className="delYesBtn"
                  />
                </div>
              </div>
            </CommonPopup>
          )}
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { agentNameList } = state.MasterDropDownListReducer; // fetching api from global api
  return { agentNameList };
};

export default withRouter(
  connect(mapStatetoProps, {
    createAgentMaster,
    updateAgentMaster,
    getAgentNameList,
  })(CreateAgentMaster)
);
