import React, { useState } from "react";
import { Grid, Box } from "@mui/material";
import swal from "sweetalert";

import TextFieldNormal from "../TextField/TextFieldNormal";
import CustomButton from "../Buttons/Buttons";

const GstFormPopup = ({ isOpen, onClose, handleSubmit }) => {
  const [userId, setUserId] = useState("");
  const [password, serUserPassword] = useState("");

  const onhandlechange = (key) => (event) => {
    if (key === "userId") {
      setUserId(event);
    }
    if (key === "password") {
      serUserPassword(event);
    }
  };

  const headerStyle = {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 550,
    fontSize: "18px",
    color: "#004B73",
    marginBottom: "25px",
  };

  const handleFormSubmit = () => {
    if (userId === "") {
      swal("Fill in the user ID");
      return;
    }
    if (password === "") {
      swal("Fill in the password");
      return;
    }

    if (handleSubmit) {
      handleSubmit(userId, password);
    }
  };

  const handleOnCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      {isOpen && (
        <div className="popup-overlay">
            <div className="formContainer">

          <div className="gstpopup-content">
            <h1 style={headerStyle}>GST Login</h1>
            <div className="formContainer">
              <div className="consoleFormBlock">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 12">
                        <TextFieldNormal
                          label="User Id"
                          value={userId}
                          onChange={onhandlechange("userId")}
                          autoComplete="off"
                          required
                        />
                      </Box>

                      <Box gridColumn="span 12">
                        <div className="consoleTextFieldBlock">
                          <TextFieldNormal
                            type="password"
                            label="Password"
                            value={password}
                            onChange={onhandlechange("password")}
                            autoComplete="off"
                            required
                          />
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
              <div className="consoleFormButtonBlock">
                <CustomButton
                  label={"Cancel"}
                  className="greenBorderButton"
                  handleClick={handleOnCancel}
                />
                <CustomButton
                  label={"Save"}
                  className="greenButton"
                  type="submit"
                  handleClick={handleFormSubmit}
                />
              </div>
            </div>
          </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GstFormPopup;
