import {
  TRANSPORTER_MASTER_LIST,
  TRANSPORTER_MASTER_LIST_SUCCESSFULL,
  API_ERRORS,
  CREATE_TRANSPORTER_MASTER,
  CREATE_TRANSPORTER_MASTER_SUCCESSFULL,
  UPDATE_TRANSPORTER_MASTER_SUCCESSFULL,
  UPDATE_TRANSPORTER_MASTER,
  NEW_TRANSPORT_MASTER_LIST,
  NEW_TRANSPORT_MASTER_LIST_SUCCESSFULL
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  TransporterMasterList: [],
  Create_Transporter_Master: [],
  Update_Transporter_Master: [],
  newTransporterMasterList:[]
};

const TransporterReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRANSPORTER_MASTER_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case TRANSPORTER_MASTER_LIST_SUCCESSFULL:
      state = {
        ...state,
        TransporterMasterList: action.payload.response,
        loading: true,
      };
      break;
    case CREATE_TRANSPORTER_MASTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_TRANSPORTER_MASTER_SUCCESSFULL:
      state = {
        ...state,
        Create_Transporter_Master: action.payload.response,
        loading: true,
      };
      break;
    case UPDATE_TRANSPORTER_MASTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_TRANSPORTER_MASTER_SUCCESSFULL:
      state = {
        ...state,
        Update_Transporter_Master: action.payload.response,
        loading: true,
      };
      break;
      case NEW_TRANSPORT_MASTER_LIST:
        state = {
          ...state,
          loading: true,
        };
        break;
      case NEW_TRANSPORT_MASTER_LIST_SUCCESSFULL:
        state = {
          ...state,
          newTransporterMasterList: action.payload.response,
          loading: true,
        };
        break;
    case API_ERRORS:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default TransporterReducer;
