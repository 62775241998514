import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TabsNavigation from "../../../../../components/TabsNavigation/TabsNavigationEmpDb";
import PersonalDetails from "./PersonalDetails";
import EducationalDetails from "./EducationalDetails";
import ContactDetails from "./ContactDetails";
import PreviousExperience from "./PreviousExperience";
import AddressDetails from "./AddressDetails";
class PersonalInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      value: 0,
      toDisplayForms: "",
      getEbId: this.props.getEbId,
    };
  }

  handleNext = (state, value = null) => {
    this.setState({
      disabled: state,
      value: value ? value : this.state.value,
    });
  };
  onvalue = (id, ebId) => {
    this.setState({
      value: id,
      getEbId: ebId,
    });
  };

  componentDidMount() {}

  render() {
    if (this.props.progressbar_data) {
      if (this.props.progressbar_data.data) {
        if (this.props.progressbar_data.data.toDisplayForms) {
          var toDisplayForms = this.props.progressbar_data.data.toDisplayForms;
        }
      }
    }
    return (
      <>
        <TabsNavigation
          personal={false}
          contact={
            toDisplayForms
              ? toDisplayForms.contactDetails
                ? false
                : true
              : true
          }
          address={
            toDisplayForms
              ? toDisplayForms.addressDetails
                ? false
                : true
              : true
          }
          education={
            toDisplayForms
              ? toDisplayForms.educationDetails
                ? false
                : true
              : true
          }
          previous={
            toDisplayForms
              ? toDisplayForms.previousExperienceDetails
                ? false
                : true
              : true
          }
          defalutTab={this.state.value}
          tabOneLabel="Personal Details"
          tabTwoLabel=" Contact Details"
          tabFiveLabel=" Address Details"
          tabThreeLabel="Educational Details"
          tabFourLabel="Previous Experience"
          tabOneContent={
            <PersonalDetails
              onvalue={this.onvalue}
              handleNext={this.handleNext}
            />
          }
          tabTwoContent={
            <ContactDetails
              onvalue={this.onvalue}
              handleNext={this.handleNext}
            />
          }
          tabFiveContent={
            <AddressDetails
              onvalue={this.onvalue}
              handleNext={this.handleNext}
            />
          }
          tabThreeContent={
            <EducationalDetails
              onvalue={this.onvalue}
              handleNext={this.handleNext}
            />
          }
          tabFourContent={
            <PreviousExperience
              onvalue={this.onvalue}
              handleNext={this.handleNext}
            />
          }
          setValue={this.state.value}
        />
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { getEbId, progressbar_data } = state.EmployeeReducer;
  return { getEbId, progressbar_data };
};

export default withRouter(connect(mapStatetoProps, {})(PersonalInformation));
