import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory,useLocation } from "react-router-dom";
import { Grid, Box, IconButton } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import DynamicSelectNew from "../../../components/Dropdown/DropdownSelectNew";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { serverApi } from "../../../helpers/Consts";
import {
  getQuotationRecords,
  getQuotationViewById,
  getQuotationViewByIdForJuteHessian,
  SalesOrderJuteHessianHeaders,
  SalesOrderJuteHessianLineItems,
} from "../../../store/Sales/SalesOrder/actions";
import {
  getBranchList,
  getCustomerList,
  getBillingShippingList,
} from "../../../store/Global/DropDownApis/actions";
import { transporterMasterList } from "../../../store/Master/TransporterMaster/actions";
import { getQuotationBroker } from "../../../store/Sales/Qutation/actions";
import swal from "sweetalert";
import { isEmptyWithZero, limitDecimals } from "../../../Utilities/helper";
import { SalesGstCalculations } from "../../../Utilities/SalesGstCalculation";
import FormPopup from "../../../components/FormlPopup/FormPopup";
import { isEmpty } from "../../../Utilities/helper";

const JuteHessianFillDetails = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const [userDit] = useState(JSON.parse(localStorage.getItem("authUser")));
  const [LineItems, setLineItems] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const [SoJuteHessianHeadersData, setHeaderData] = useState({});
  const [branchListData, setbranchListData] = useState([]);
  const [brokerData, setbrokerData] = useState([]);
  const [CustomerData, setCustomerData] = useState([]);
  const [billingAddressData, setbillingAddressData] = useState([]);
  const [shippingAddressData, setshippingAddressData] = useState([]);
  const [QuotationData, setQuotationData] = useState([]);
  const [transporterList, settransporterList] = useState([]);
  const [customerListData, SetCustomerListData] = useState([]);
  const [emailCustomer,setEmailCustomer]=useState("");
  const [backDaysAllowable, setBackDaysAllowable] = useState("");
  const [futureDaysAllowable, setFutureDaysAllowable] = useState("");

  const { branchList, customerList, billingShippingList } = useSelector(
    (state) => state.DropDownListReducer
  );
  const { BrokerData } = useSelector((state) => state.QuotationReducer);
  const {
    QuotationApprovedData,
    SoJuteHessianHeaders,
    ViewByIdQuotation,
    SoJuteHessianLineItems,
  } = useSelector((state) => state.SalesOrderReducer);
  const { TransporterMasterList } = useSelector(
    (state) => state.TransporterReducer
  );
  const state = useSelector((state) => state.SalesOrderReducer);

  useEffect(() => {
    dispatch(
      getBranchList(
        serverApi.BRANCH_LIST + localStorage.getItem("companyId"),
        history
      )
    );
    const data = {
      companyId: localStorage.getItem("companyId"),
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
    };
    dispatch(getCustomerList(serverApi.GET_ALL_CUSTOMERS, data, history));
    dispatch(
      getQuotationBroker(
        serverApi.GET_ALL_BROKER_LIST +
          localStorage.getItem("companyId") +
          "/SALE",
        history
      )
    );
    dispatch(
      getQuotationRecords(
        serverApi.GET_QUOTATION_DROPDOWN + localStorage.getItem("companyId"),
        history
      )
    );
    dispatch(
      transporterMasterList(
        serverApi.GET_ALL_TRANSPORTER_MASTER_LIST +
          localStorage.getItem("companyId"),
        history
      )
    );
    const currentMonth = moment().format('MM');
    const currentDate = moment().format('DD');
    const fiscalYearStart = moment(`${localStorage.getItem("acadamicYear")}-${currentMonth}-${currentDate}`);
    const today = moment();
    let dateValue;
    if (today.isBefore(fiscalYearStart)) {
      dateValue = today.format("YYYY-MM-DD");
    } else {
      dateValue = fiscalYearStart.format("YYYY-MM-DD");
    }

    if (userDit) {
      userDit.companyDetails.map((data) => {
        if (data.value === parseInt(localStorage.getItem("companyId")))
          if (data.configList) {
            data.configList.map((item) => {
              if (item.backDaysAllowable !== undefined) {
                let backDaysAllowable = JSON.parse(item.backDaysAllowable);
                let backDays = backDaysAllowable.bdaystype;
                if (Number(backDays) === 1) {
                  backDays = 7;
                } else if (Number(backDays) === 2) {
                  backDays = 14;
                } else if (Number(backDays) === 3) {
                  backDays = "";
                } else if (Number(backDays) === 4) {
                  backDays = backDaysAllowable.otherBackDateValue;
                } else {
                  backDays = backDaysAllowable.bdaystype;
                }
                console.log(dateValue)
                let minDate = moment(dateValue).subtract(
                  Number(backDays),

                  "days"
                );
                let setMinDate = moment(minDate).format("YYYY-MM-DD");
                setBackDaysAllowable(setMinDate);
              }
              if (item.futureDaysAllowable !== undefined) {
                let futureDaysAllowable = JSON.parse(item.futureDaysAllowable);
                let futureDays = futureDaysAllowable.fdaystype;
                if (Number(futureDays) === 1) {
                  futureDays = 7;
                } else if (Number(futureDays) === 2) {
                  futureDays = 14;
                } else if (Number(futureDays) === 3) {
                  futureDays = "";
                } else if (Number(futureDays) === 4) {
                  futureDays = futureDaysAllowable.othervalue;
                } else {
                  futureDays = futureDaysAllowable.fdaystype;
                }
                let maxDate = moment(dateValue).add(
                  Number(futureDays),

                  "days"
                );
                let setMaxDate = moment(maxDate).format("YYYY-MM-DD");
                setFutureDaysAllowable(setMaxDate);
                console.log(setMaxDate)
              }
            });
            setHeaderData((prev) => ({
              ...prev,
              salesDate: dateValue,
              // salesOrderExpiryDate:dateValue
            }));
            const UpdatedRec = {
              ...SoJuteHessianHeaders,
              salesDate: dateValue,
              // salesOrderExpiryDate:dateValue
            };
            dispatch(SalesOrderJuteHessianHeaders(UpdatedRec));

          }
      });
    }
  }, []);

  useEffect(() => {
    setHeaderData(SoJuteHessianHeaders);
    setLineItems(SoJuteHessianLineItems);
  }, [SoJuteHessianHeaders,SoJuteHessianLineItems]);

  useEffect(() => {
    if (branchList) {
      if (branchList.data) {
        if (branchList.data.length !== 0) {
          let List = branchList.data.filter((item) => item.value !== 0);
          setbranchListData(List);
        }
      }
    }
    if (customerList) {
      if (customerList.data) {
        if (customerList.data.length !== 0) {
          var CusList = [];
          CusList.push(customerSelect);
          customerList.data.map((item) => {
            CusList.push({
              value: item.id,
              name: item.name,
              label: item.name,
            });
          });
          setCustomerData(CusList);
        }
      }
    }
    if (BrokerData) {
      if (BrokerData.data) {
        setbrokerData(BrokerData.data);
      }
    }

    if (QuotationApprovedData) {
      if (QuotationApprovedData.data) {
        if (QuotationApprovedData.data.length !== 0) {
          var QuoteData = [];
          QuotationApprovedData.data.map((item) => {
            QuoteData.push({
              value: item.Id,
              name: item.Value,
              label: item.Value,
            });
          });
          setQuotationData(QuoteData);
        }
      }
    }
    if (TransporterMasterList) {
      if (TransporterMasterList.data) {
        if (TransporterMasterList.data.data) {
          let TransporterList = TransporterMasterList.data.data.map((item) => ({
            name:
              item.transporterName +
              "^" +
              item.transporterAddress +
              "^" +
              item.stateName +
              "^" +
              item.stateCode,
            label: item.transporterName,
            value: item.transporterId,
          }));
          settransporterList(TransporterList);
        }
      }
    }
  }, [branchList, customerList, QuotationApprovedData, TransporterMasterList]);
  const isEmptyObj = (myObject) => {
    // Use the 'Object.keys' method to get an array of the object's keys
    const keys = Object.keys(myObject);

    // Check if the number of keys in the array is 0
    return keys.length === 0;

  };

  useEffect(() => {
    if (billingShippingList) {
      if (billingShippingList.data) {
        setEmailCustomer(billingShippingList.data.customerHdr.emailId)
        if (billingShippingList.data.customerLineitems) {
          if (billingShippingList.data.customerLineitems.length !== 0) {
            var list = [];
            let billingAddressList = [];
            let shippingaddressList = [];
            let SoJuteHessianHeadersObj = SoJuteHessianHeaders;
            let customerLineitems = billingShippingList.data.customerLineitems;
            SetCustomerListData(customerLineitems);
            billingShippingList.data.customerLineitems.map((prop, i) => {
              if (prop.addressType === 2 || prop.addressType === null) {
                if (
                  (prop.customerLineId === SoJuteHessianHeaders.shippingAddress &&
                    prop.isActive === 0) ||
                  prop.isActive !== 0
                ) {
                  billingAddressList.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
                  list.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
                }
              }
              if (prop.addressType === 1 || prop.addressType === null || prop.addressType === 2) {
                if (
                  (prop.customerLineId === SoJuteHessianHeaders.shippingAddress &&
                    prop.isActive === 0) ||
                  prop.isActive !== 0
                ) {
                  shippingaddressList.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
                  list.push({
                    value: prop.customerLineId,
                    label: prop.address,
                    name: prop.address,
                  });
                }
              }
            });

            billingShippingList.data.customerLineitems.map((prop, i) => {
              if (billingAddressList.length !== 0 &&  SoJuteHessianHeadersData.customerId !== "" &&
              SoJuteHessianHeadersData.customerId !== null) {
                billingAddressList.map((billingdata, ind) => {
                  if (ind === 0) {
                    if (!isEmptyObj(SoJuteHessianHeadersData)) {
                      if (
                        SoJuteHessianHeadersData.customerId !== "" ||
                        SoJuteHessianHeadersData.customerId !== null
                      ) {
                        if (prop.customerLineId === billingdata.value) {
                          setHeaderData((prev) => ({
                            ...prev,      
                            billingTo: prop.customerLineId,
                            billingToAddress: prop.address,
                            billingToGST: prop.gstNo,
                            billingContactNo: prop.contactNo,
                            billingContactPerson: prop.contactPerson,
                            billingToEmail: billingShippingList.data.customerHdr.emailId,
                            taxType: 2,
                          }));
                          const UpdatedRec = {
                            ...SoJuteHessianHeadersObj,
                            billingTo: prop.customerLineId,
                            billingToAddress: prop.address,
                            billingToGST: prop.gstNo,
                            billingContactNo: prop.contactNo,
                            billingContactPerson: prop.contactPerson,
                            billingToEmail: billingShippingList.data.customerHdr.emailId,
                            taxType: 2,
                          };
                          SoJuteHessianHeadersObj = UpdatedRec
                        }
                      }else{
                        setHeaderData((prev) => ({
                          ...prev,
                          billingTo: "",
                          billingToAddress: "",
                          billingToGST: "",
                          billingContactNo: "",
                          billingContactPerson: "",
                          billingToEmail: "",
                          taxType: "",
                        }));
                        const UpdatedRec = {
                          ...SoJuteHessianHeadersObj,
                          billingTo: "",
                          billingToAddress: "",
                          billingToGST: "",
                          billingContactNo: "",
                          billingContactPerson: "",
                          billingToEmail: "",
                          taxType: "",
                        };
                        SoJuteHessianHeadersObj = UpdatedRec
                      }
                    }
                  }
                });
              } else {
                setHeaderData((prev) => ({
                  ...prev,
                  billingTo: "",
                  billingToAddress: "",
                  billingToGST: "",
                  billingContactNo: "",
                  billingContactPerson: "",
                  billingToEmail: "",
                  taxType: "",
                }));
                const UpdatedRec = {
                  ...SoJuteHessianHeadersObj,
                  billingTo: "",
                  billingToAddress: "",
                  billingToGST: "",
                  billingContactNo: "",
                  billingContactPerson: "",
                  billingToEmail: "",
                  taxType: "",
                };
                SoJuteHessianHeadersObj = UpdatedRec

              }
            })
            billingShippingList.data.customerLineitems.map((prop, i) => {
              if (shippingaddressList.length !== 0 &&  SoJuteHessianHeadersData.customerId !== "" &&
              SoJuteHessianHeadersData.customerId !== null) {
                shippingaddressList.map((shippingdata, ind) => {
                  if (ind === 0) {
                    if (!isEmptyObj(SoJuteHessianHeadersData)) {
                      if (
                        SoJuteHessianHeadersData.customerId !== "" ||
                        SoJuteHessianHeadersData.customerId !== null
                      ) {
                        if (prop.customerLineId === shippingdata.value) {
                          setHeaderData((prev) => ({
                            ...prev,
                            shippingTo: prop.customerLineId,
                            shippingToAddress: prop.address,
                            shippingToGST: prop.gstNo,
                            shippingContactNo: prop.contactNo,
                            shippingContactPerson: prop.contactPerson,
                            shippingToEmail: billingShippingList.data.customerHdr.emailId,
                            taxType: 2,
                          }));
                          const UpdatedRec = {
                            ...SoJuteHessianHeadersObj,
                            shippingTo: prop.customerLineId,
                            shippingToAddress: prop.address,
                            shippingToGST: prop.gstNo,
                            shippingContactNo: prop.contactNo,
                            shippingContactPerson: prop.contactPerson,
                            shippingToEmail: billingShippingList.data.customerHdr.emailId,
                            taxType: 2,
                          };
                          SoJuteHessianHeadersObj = UpdatedRec
                        }
                      }else{
                        setHeaderData((prev) => ({
                          ...prev,
                          shippingTo:"",
                          shippingToAddress: "",
                          shippingToGST: "",
                          shippingContactNo: "",
                          shippingContactPerson: "",
                          shippingToEmail: "",
                          taxType: "",
                        }));
                        const UpdatedRec = {
                          ...SoJuteHessianHeadersObj,
                          shippingTo:"",
                          shippingToAddress: "",
                          shippingToGST: "",
                          shippingContactNo: "",
                          shippingContactPerson: "",
                          shippingToEmail: "",
                          taxType: "",
                        };
                        SoJuteHessianHeadersObj = UpdatedRec
                      }
                    }
                  }
                });
              }  else {
                setHeaderData((prev) => ({
                  ...prev,
                  shippingTo:"",
                  shippingToAddress: "",
                  shippingToGST: "",
                  shippingContactNo: "",
                  shippingContactPerson: "",
                  shippingToEmail: "",
                  taxType: "",
                }));
                const UpdatedRec = {
                  ...SoJuteHessianHeadersObj,
                  shippingTo:"",
                  shippingToAddress: "",
                  shippingToGST: "",
                  shippingContactNo: "",
                  shippingContactPerson: "",
                  shippingToEmail: "",
                  taxType: "",
                };
                SoJuteHessianHeadersObj = UpdatedRec
              }
            });
            if (SoJuteHessianHeadersData.customerId !== "") {
              dispatch(SalesOrderJuteHessianHeaders(SoJuteHessianHeadersObj));
              setbillingAddressData(billingAddressList);
              setshippingAddressData(shippingaddressList);
            }
          }
        }
      }
    }
  }, [billingShippingList]);

  const handlePropSelectChange = (
    selectedValue,
    selectedName,
    name,
    selectedlable
  ) => {
    if (name == "deliveryTerms") {
      setHeaderData((prev) => ({
        ...prev,
        deliveryTerms: selectedName,
      }));
      const UpdatedRec = {
        ...SoJuteHessianHeadersData,
        deliveryTerms: selectedName,
      };
      dispatch(SalesOrderJuteHessianHeaders(UpdatedRec));
    }
    if (name === "quotationId") {
      setHeaderData((prev) => ({
        ...prev,
        quotationId: selectedValue,
      }));
      const UpdatedRec = {
        ...SoJuteHessianHeadersData,
        quotationId: selectedValue,
      };
      dispatch(SalesOrderJuteHessianHeaders(UpdatedRec));
      dispatch(
        getQuotationViewByIdForJuteHessian(
          serverApi.QUOTATION_VIEW_BYID +
            selectedValue +
            "/" +
            localStorage.getItem("companyId")
        )
      );
    }
    if (name === "branchId") {
      const branchData = selectedName.split("_");
      const branchName = branchData[0];
      setHeaderData((prev) => ({
        ...prev,
        branchId: selectedValue,
        branchName: branchName,
      }));
      const UpdatedRec = {
        ...SoJuteHessianHeadersData,
        branchId: selectedValue,
        branchName: branchName,
      };
      dispatch(SalesOrderJuteHessianHeaders(UpdatedRec));
    }
    if (name === "customerId") {
      if (selectedValue === "") {
        setHeaderData((prev) => ({
          ...prev,
          customerId: null,
          customerName: "",
          ContactNumber: "",
          ContactPerson: "",
          billingTo: "",
          billingToAddress: "",
          billingToGST: "",
          billingContactNo: "",
          billingContactPerson: "",
          billingToEmail: "",
          shippingTo: "",
          shippingToAddress: "",
          shippingToGST: "",
          shippingContactNo: "",
          shippingContactPerson: "",
          shippingToEmail: "",
          taxType: "",
        }));

        const UpdatedRec = {
          ...SoJuteHessianHeadersData,
          customerId: null,
          customerName: "",
          ContactNumber: "",
          ContactPerson: "",
          billingTo: "",
          billingToAddress: "",
          billingToGST: "",
          billingContactNo: "",
          billingContactPerson: "",
          billingToEmail: "",
          shippingTo: "",
          shippingToAddress: "",
          shippingToGST: "",
          shippingContactNo: "",
          shippingContactPerson: "",
          shippingToEmail: "",

          taxType: "",
        };
        dispatch(SalesOrderJuteHessianHeaders(UpdatedRec));
        let TaxPer = SalesGstCalculations(
          {
            lineItems: SoJuteHessianLineItems,
            taxType: 0,
            brokerCommisionPercent: SoJuteHessianHeadersData.brokerCommisionPercent,
          },
          "SO_HESSIAN"
        );
        handleCalculationCustomer(TaxPer, selectedValue, selectedName);
        return;
      }
      setHeaderData((prev) => ({
        ...prev,
        customerId: selectedValue,
        customerName: selectedName,
      }));
      const UpdatedRec = {
        ...SoJuteHessianHeadersData,
        customerId: selectedValue,
        customerName: selectedName,
      };
      dispatch(SalesOrderJuteHessianHeaders(UpdatedRec));
      let TaxPer = SalesGstCalculations(
        {
          lineItems: SoJuteHessianLineItems,
          taxType: 2,
          brokerCommisionPercent: SoJuteHessianHeadersData.brokerCommisionPercent,
        },
        "SO_HESSIAN"
      );
      handleCalculationCustomer(TaxPer, selectedValue, selectedName);
      
      dispatch(
        getBillingShippingList(
          serverApi.GET_ALL_BILLING_SHIPPING +
            selectedValue +
            "/" +
            JSON.parse(localStorage.getItem("authUser")).cipher+
            "?isForSalesModule=1",
          history
        )
      );
    }
    if (name === "brokerId") {
      setHeaderData((prev) => ({
        ...prev,
        brokerId: selectedValue,
        brokerCommisionPercent: "",
        brokerName: selectedName,
      }));
      const UpdatedRec = {
        ...SoJuteHessianHeadersData,
        brokerId: selectedValue,
        brokerCommisionPercent: "",
        brokerName: selectedName,
      };
      dispatch(SalesOrderJuteHessianHeaders(UpdatedRec));

      let LineItem = SalesGstCalculations(
        {
          lineItems: SoJuteHessianLineItems,
          taxType: SoJuteHessianHeadersData.taxType,
          brokerCommisionPercent: "",
        },
        "SO_HESSIAN"
      );
      let brokerCommisionPercent = "";
      let taxType = SoJuteHessianHeadersData.taxType;
      let taxName = SoJuteHessianHeadersData.taxName;
      let brokerId = selectedValue;
      let  brokerName = selectedName;
      handleCalculation(
        LineItem,
        brokerCommisionPercent,
        taxType,
        taxName,
        brokerId,
        brokerName, 
      );
    }

    if (name == "BillingTo") {
      customerListData.filter((prop)=>{
        if (prop.customerLineId === selectedValue) {
          setHeaderData((prev) => ({
            ...prev,
            billingTo: selectedValue,
            billingToAddress: selectedName,
            billingToGST: prop.gstNo,
            billingContactNo: prop.contactNo,
            billingContactPerson: prop.contactPerson,
            billingToEmail: emailCustomer,
          }));
          const UpdatedRec = {
            ...SoJuteHessianHeadersData,
            billingTo: selectedValue,
            billingToAddress: selectedName,
            billingToGST: prop.gstNo,
            billingContactNo: prop.contactNo,
            billingContactPerson: prop.contactPerson,
            billingToEmail: emailCustomer,
          };
          dispatch(SalesOrderJuteHessianHeaders(UpdatedRec));
          return prop
        }
      })
    }
    if (name == "ShippingTo") {
      customerListData.filter((prop) => {
        if (prop.customerLineId === selectedValue) {
          setHeaderData((prev) => ({
            ...prev,
            shippingTo: selectedValue,
            shippingToAddress: selectedName,
            shippingToGST: prop.gstNo,
            shippingContactNo: prop.contactNo,
            shippingContactPerson: prop.contactPerson,
            shippingToEmail: emailCustomer,
          }));
       
        const UpdatedRec = {
          ...SoJuteHessianHeadersData,
          shippingTo: selectedValue,
          shippingToAddress: selectedName,
          shippingToGST: prop.gstNo,
          shippingContactNo: prop.contactNo,
          shippingContactPerson: prop.contactPerson,
          shippingToEmail: emailCustomer,
        };
      dispatch(SalesOrderJuteHessianHeaders(UpdatedRec));
        return prop;
      }
      });
    }
    if (name == "TaxType") {
      setHeaderData((prev) => ({
        ...prev,
        taxType: selectedValue,
        taxName: selectedName,
      }));
      const UpdatedRec = {
        ...SoJuteHessianHeadersData,
        taxType: selectedValue,
        taxName: selectedName,
      };
      dispatch(SalesOrderJuteHessianHeaders(UpdatedRec));
      let LineItem = SalesGstCalculations(
        {
          lineItems: SoJuteHessianLineItems,
          taxType: selectedValue,
          brokerCommisionPercent:
            SoJuteHessianHeadersData.brokerCommisionPercent,
        },
        "SO_HESSIAN"
      );
      let taxName = selectedName;
      let taxType = selectedValue;
      let brokerCommisionPercent =
        SoJuteHessianHeadersData.brokerCommisionPercent;
      let brokerId = SoJuteHessianHeadersData.brokerId;
      handleCalculation(
        LineItem,
        brokerCommisionPercent,
        taxType,
        taxName,
        brokerId
      );
    }

    if (name === "Transporter") {
      var data = selectedName.split("^");
      setHeaderData((prev) => ({
        ...prev,
        transporterId: selectedValue,
        transporterName: data[0],
        transporterAddressName: data[1],
        transporterStateCode: data[3],
        transporterStateName: data[2],
      }));
      const UpdatedRec = {
        ...SoJuteHessianHeadersData,
        transporterId: selectedValue,
        transporterName: data[0],
        transporterAddressName: data[1],
        transporterStateCode: data[3],
        transporterStateName: data[2],
      };
      dispatch(SalesOrderJuteHessianHeaders(UpdatedRec));
    }
  };
  const handleMenuOpen = () => {
    setisDropdownOpen(false);
  };
  const onhandlechange = (key) => (event) => {
    if (key === "deliveryDays") {
      setHeaderData((prev) => ({
        ...prev,
        deliveryDays: event,
      }));
      const UpdatedRec = {
        ...SoJuteHessianHeadersData,
        deliveryDays: event,
      };
      dispatch(SalesOrderJuteHessianHeaders(UpdatedRec));
    }
    if (key === "brokerCommisionPercent") {
      if (event <= 100) {
        if (event.match(/^(\d*\.{0,1}\d{0,2}$)/)) {
          setHeaderData((prev) => ({
            ...prev,
            brokerCommisionPercent: event,
          }));
          const UpdatedRec = {
            ...SoJuteHessianHeadersData,
            brokerCommisionPercent: event,
          };
          dispatch(SalesOrderJuteHessianHeaders(UpdatedRec));
          let LineItemsRec = SoJuteHessianLineItems.filter((rowItem) => {
            if (SoJuteHessianHeadersData.brokerCommisionPercent !== "") {
              rowItem.hessianRateIncludingBrokerage = limitDecimals(
                rowItem.discountedRate - (rowItem.discountedRate * event) / 100,
                3
              );
            } else {
              rowItem.hessianRateIncludingBrokerage = 0;
            }
            return rowItem;
          });
          dispatch(SalesOrderJuteHessianLineItems(LineItemsRec));
          let LineItem = SalesGstCalculations(
            {
              lineItems: SoJuteHessianLineItems,
              taxType: SoJuteHessianHeadersData.taxType,
              brokerCommisionPercent: event,
            },
            "SO_HESSIAN"
          );
          let brokerCommisionPercent = event;
          let taxType = SoJuteHessianHeadersData.taxType;
          let taxName = SoJuteHessianHeadersData.taxName;
          let brokerId = SoJuteHessianHeadersData.brokerId;
          let brokerName =  SoJuteHessianHeadersData.brokerName;
          handleCalculation(
            LineItem,
            brokerCommisionPercent,
            taxType,
            taxName,
            brokerId,
            brokerName,
          );

          setHeaderData((prev) => ({
            ...prev,
            brokerCommisionPercent: event,
          }));
        } else {
          swal("Please enter a positive number with up to 2 decimal places.");
        }
      } else {
        swal("Broker Percent Can't be more than 100%");
      }
    }
  };

  const handleCalculationCustomer = (Calculations, selectedValue, selectedName) => {
    setLineItems(Calculations.lineItems);
     dispatch(SalesOrderJuteHessianLineItems(Calculations.lineItems));

    if(selectedValue === "") {
      setHeaderData((prev) => ({
        ...prev,
        customerId: selectedValue,
        customerName: selectedName,
        ContactNumber: "",
        ContactPerson: "",
        billingTo: "",
        billingToAddress: "",
        billingToGST: "",
        billingContactNo: "",
        billingContactPerson: "",
        billingToEmail: "",
        shippingTo: "",
        shippingToAddress: "",
        shippingToGST: "",
        shippingContactNo: "",
        shippingContactPerson: "",
        shippingToEmail:"",
        taxType: null,
        totalIGST: "0.00",
        totalCGST: "0.00",
        totalSGST: "0.00",
        taxTotal: "0.00",
        TotalAmount: Calculations.netTotal,
        
      }));
      setbillingAddressData([])
      setshippingAddressData([])
       let Headerdata = {
        ...SoJuteHessianHeaders,
        customerId: selectedValue,
        customerName: selectedName,
        ContactNumber: "",
        ContactPerson: "",
        billingTo: "",
        billingToAddress: "",
        billingToGST: "",
        billingContactNo: "",
        billingContactPerson: "",
        billingToEmail: "",
        shippingTo: "",
        shippingToAddress: "",
        shippingToGST: "",
        shippingContactNo: "",
        shippingContactPerson: "",
        shippingToEmail: "",
        taxType: null,
        totalIGST: "0.00",
        totalCGST: "0.00",
        totalSGST: "0.00",
        taxTotal: "0.00",
        TotalAmount: Calculations.netTotal,
      };
      dispatch(SalesOrderJuteHessianHeaders(Headerdata));
    }else{
 
      let Headerdata = {
        ...SoJuteHessianHeaders,
        totalIGST: Calculations.totalIGST,
        totalCGST: Calculations.totalCGST,
        totalSGST: Calculations.totalSGST,
        taxTotal: Calculations.taxTotal,
        TotalAmount: Calculations.TotalAmount,
        netTotal: Calculations.netTotal,
        subTotal: Calculations.subTotal,
        customerId: selectedValue,
        customerName: selectedName,
      };
      setHeaderData((prev) => ({
        ...prev,
        totalIGST: Calculations.totalIGST,
        totalCGST: Calculations.totalCGST,
        totalSGST: Calculations.totalSGST,
        taxTotal: Calculations.taxTotal,
        TotalAmount: Calculations.TotalAmount,
        netTotal: Calculations.netTotal,
        subTotal: Calculations.subTotal,
        customerId: selectedValue,
        customerName: selectedName,
  
      }))
      dispatch(SalesOrderJuteHessianHeaders(Headerdata));
    }

   };
  const handleSelect_Date = (e, name) => {
    if (name === "salesDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      var Sale_date = null;
      if (SoJuteHessianHeadersData.salesOrderExpiryDate !== null) {
        if (Date > SoJuteHessianHeadersData.salesOrderExpiryDate) {
          swal("Sale Date Shouldn't be Greater than Expire Date");
          Sale_date = SoJuteHessianHeadersData.salesOrderExpiryDate;
        } else {
          Sale_date = Date;
        }
      } else {
        Sale_date = Date;
      }
      setHeaderData((prev) => ({
        ...prev,
        salesDate: Sale_date,
      }));
      const UpdatedRec = {
        ...SoJuteHessianHeadersData,
        salesDate: Sale_date,
      };
      dispatch(SalesOrderJuteHessianHeaders(UpdatedRec));
    }
    if (name === "salesOrderExpiryDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      var SaleExpDate = null;
      if (SoJuteHessianHeadersData.salesDate !== null) {
        if (Date < SoJuteHessianHeadersData.salesDate) {
          swal("Expire Date Should be Greater than Sale Date");
          SaleExpDate = SoJuteHessianHeadersData.salesDate;
        } else {
          SaleExpDate = Date;
        }
      } else {
        swal("Please Select the Sale Date First");
      }
      setHeaderData((prev) => ({
        ...prev,
        salesOrderExpiryDate: SaleExpDate,
      }));
      const UpdatedRec = {
        ...SoJuteHessianHeadersData,
        salesOrderExpiryDate: SaleExpDate,
      };
      dispatch(SalesOrderJuteHessianHeaders(UpdatedRec));
    }
  };
  const handleCalculation = (
    Calculations,
    brokerCommisionPercent,
    taxType,
    taxName,
    brokerId,
    brokerName,
  ) => {
    dispatch(SalesOrderJuteHessianLineItems(Calculations.lineItems));
    let Headerdata = {
      ...SoJuteHessianHeadersData,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
      brokerCommisionPercent: brokerCommisionPercent,
      taxType: taxType,
      taxName: taxName,
      brokerId: brokerId,
      brokerName: brokerName,
    };

    setHeaderData((prev) => ({
      ...prev,
      totalIGST: Calculations.totalIGST,
      totalCGST: Calculations.totalCGST,
      totalSGST: Calculations.totalSGST,
      taxTotal: Calculations.taxTotal,
      TotalAmount: Calculations.TotalAmount,
      netTotal: Calculations.netTotal,
      subTotal: Calculations.subTotal,
      brokerCommisionPercent: brokerCommisionPercent,
      taxType: taxType,
      taxName: taxName,
      brokerId: brokerId,
      brokerName: brokerName,
    }));
    dispatch(SalesOrderJuteHessianHeaders(Headerdata));
  };

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <div>
        <div className="consoleFormContainer">
          <h5> Jute Hessian Fill Details</h5>
          <div style={{ height: "15px" }}></div>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={SoJuteHessianHeadersData.branchId ? 1 : 0}
                        arrayOfData={branchListData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={SoJuteHessianHeadersData.branchId}
                        name="branchId"
                        label="Branch"
                        required
                        isDisabled={props.locationState.state !==undefined ? true :false}
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={SoJuteHessianHeadersData.brokerId ? 1 : 0}
                        arrayOfData={brokerData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={SoJuteHessianHeadersData.brokerId}
                        name="brokerId"
                        label="Broker"
                        ErrorOutlineIcon={true}
                        toolTipTitle={
                          "Broker or Consignee is mandetory Select At least one and Proceed"
                        }
                        isDisabled={
                          SoJuteHessianHeadersData.branchId === ""
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelect
                      update={SoJuteHessianHeadersData.customerId ? 1 : 0}
                      arrayOfData={CustomerData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      selected={SoJuteHessianHeadersData.customerId}
                      name="customerId"
                      label="Customer/Consignee"
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      ErrorOutlineIcon={true}
                      toolTipTitle={
                        "Broker or Consignee is mandetory Select At least one and Proceed"
                      }
                      isDisabled={
                        SoJuteHessianHeadersData.branchId === "" ? true : false
                      }
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={SoJuteHessianHeadersData.billingTo ? 1 : 0}
                        arrayOfData={billingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={SoJuteHessianHeadersData.billingTo}
                        name="BillingTo"
                        label="Billing To"
                        isDisabled={ isEmpty( SoJuteHessianHeadersData.customerId)}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={SoJuteHessianHeadersData.shippingTo ? 1 : 0}
                        arrayOfData={shippingAddressData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={SoJuteHessianHeadersData.shippingTo}
                        name="ShippingTo"
                        label="Shipping To"
                        isDisabled={ isEmpty( SoJuteHessianHeadersData.customerId)
                        }
                      />
                    </div>
                    {!isEmpty(SoJuteHessianHeadersData.customerId) && (
                    <span
                      onClick={openPopup}
                      style={{
                        color: "red",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 100,
                        fontSize: "15px",
                        marginTop: "5px",
                        cursor: "pointer",
                        padding: "5px",
                      }}>
                        Add a new Customer Branch Address
                    </span>
                    )}
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        update={SoJuteHessianHeadersData.taxType ? 1 : 0}
                        arrayOfData={taxTypeData}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={handlePropSelectChange}
                        isDropdownOpen={isDropdownOpen}
                        handleMenuOpen={handleMenuOpen}
                        selected={SoJuteHessianHeadersData.taxType}
                        name="TaxType"
                        label="Tax Type"
                        isDisabled={
                          isEmpty(SoJuteHessianHeadersData.customerId) ? true:false
                        }
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Sales Order Date<span className="starcolor">*</span>
                          </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="saleDate"
                            inputFormat="dd-MM-yyyy"
                            id="saleDate"
                            minDate={
                              backDaysAllowable !== ""
                                ? backDaysAllowable
                                :SoJuteHessianHeadersData.salesDate
                            }
                            maxDate={
                              futureDaysAllowable !== ""
                                ? futureDaysAllowable
                                : SoJuteHessianHeadersData.salesDate
                            }
                            value={SoJuteHessianHeadersData.salesDate}
                            disabled={
                              SoJuteHessianHeadersData.customerId !== "" ||
                              SoJuteHessianHeadersData.brokerId !== ""
                                ? false
                                : true
                            }
                            fullWidth
                            onChange={(e) => handleSelect_Date(e, "salesDate")}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                                style={{
                                  backgroundColor:
                                    SoJuteHessianHeadersData.customerId !==
                                      "" ||
                                    SoJuteHessianHeadersData.brokerId !== ""
                                      ? ""
                                      : "#ccd6db",
                                }}
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  style={{
                                    backgroundColor:
                                      SoJuteHessianHeadersData.customerId !==
                                        "" ||
                                      SoJuteHessianHeadersData.brokerId !== ""
                                        ? ""
                                        : "#ccd6db",
                                  }}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <div className="TextFieldBlock">
                          <label>
                            Expire Date<span className="starcolor">*</span>
                          </label>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            name="Expired Date"
                            inputFormat="dd-MM-yyyy"
                            id="ExpiredDate"
                            value={
                              SoJuteHessianHeadersData.salesOrderExpiryDate
                            }
                            minDate={
                              backDaysAllowable !== ""
                                ? backDaysAllowable
                                :SoJuteHessianHeadersData.salesOrderExpiryDate
                            }
                            maxDate={
                              futureDaysAllowable !== ""
                                ? futureDaysAllowable
                                : SoJuteHessianHeadersData.salesOrderExpiryDate
                            }
                            disabled={
                              SoJuteHessianHeadersData.salesDate === null
                                ? true
                                : false
                            }
                            fullWidth
                            onChange={(e) =>
                              handleSelect_Date(e, "salesOrderExpiryDate")
                            }
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="consoleDatePicker"
                                style={{
                                  backgroundColor:
                                    SoJuteHessianHeadersData.salesDate === null
                                      ? "#ccd6db"
                                      : "",
                                }}
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  style={{
                                    backgroundColor:
                                      SoJuteHessianHeadersData.salesDate ===
                                      null
                                        ? "#ccd6db"
                                        : "",
                                  }}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Delivery Days"
                      value={SoJuteHessianHeadersData.deliveryDays}
                      onChange={onhandlechange("deliveryDays")}
                      type="number"
                      disabled={isEmptyWithZero( SoJuteHessianHeadersData.customerId || SoJuteHessianHeadersData.brokerId)}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      label="Broker %"
                      value={SoJuteHessianHeadersData.brokerCommisionPercent}
                      onChange={onhandlechange("brokerCommisionPercent")}
                      type="number"
                      required={
                        SoJuteHessianHeadersData.brokerId !== "" &&
                        SoJuteHessianHeadersData.brokerId !== 0 &&
                        SoJuteHessianHeadersData.brokerId !== null
                          ? true
                          : false
                      }
                      disabled={isEmptyWithZero(SoJuteHessianHeadersData.brokerId)}
                    />
                  </Box>
                  <Box gridColumn="span 3">
                    <DynamicSelectNew
                      update={SoJuteHessianHeadersData.deliveryTerms ? 1 : 0}
                      arrayOfData={DeliveryTermsData}
                      className="dropdownBlockContainer"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      onSelectChange={handlePropSelectChange}
                      isDropdownOpen={isDropdownOpen}
                      handleMenuOpen={handleMenuOpen}
                      selected={SoJuteHessianHeadersData.deliveryTerms}
                      name="deliveryTerms"
                      label="Delivery Terms"
                      required={true}
                      isDisabled={ isEmptyWithZero( SoJuteHessianHeadersData.customerId || SoJuteHessianHeadersData.brokerId)}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div style={{ height: "15px" }}></div>
        </div>
        <FormPopup isOpen={isPopupOpen} onClose={closePopup} customerData={billingShippingList} customerId={SoJuteHessianHeadersData.customerId}/>
      </div>
    </>
  );
};
const taxTypeData = [
  {
    value: 1,
    name: "IGST",
    label: "IGST",
  },
  {
    value: 2,
    name: "CGST & SGST",
    label: "CGST & SGST",
  },
];
const DeliveryTermsData = [
  {
    value: 1,
    label: "Ex-Works",
    name: "Ex-Works",
  },
  {
    value: 2,
    label: "Delivery to Destination",
    name: "Delivery to Destination",
  },
];
const customerSelect = {
  value: "",
  name: "Select",
  label: "Select",
};
export default JuteHessianFillDetails;
